import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import * as Assets from '../../Assets'
import {IIssue, IIssueHistory, TimelineEntrySubType} from '../../../Logic/Database/DatabaseData'
// import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {getIssuesTable} from './ResponsibleGaming'
interface State {
/*
    comment?: string
    resolved?: boolean
    currentRiskLevel?: number
    newRiskLevel?: number
*/
    items?: Array<Partial<IIssue>> // Array<Partial<IIssueHistory>>
}

export default class HistoryPopup extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            // comment: '',
            // resolved: false,
            // currentRiskLevel: RiskLevel.Low,
            // newRiskLevel: RiskLevel.Low,
        }
    }

    public getIssue(rowId: number, issueSource: TimelineEntrySubType): IIssue|undefined {
        // console.log ('rowId ', rowId)
        // console.log ('issueSource ', issueSource) // to mora bit something
        if (rowId !== -1) {
            if (issueSource === TimelineEntrySubType.DashboardBackend) {
                return Main.Instance.DatabaseDataState.issuesDashboardBackend[rowId]
            } else if (issueSource === TimelineEntrySubType.RiskManagement) {
                return Main.Instance.DatabaseDataState.issues1[rowId]
            } else if (issueSource === TimelineEntrySubType.ResponsibleGaming) {
                return Main.Instance.DatabaseDataState.issues2[rowId]
            } else if (issueSource === TimelineEntrySubType.KnowYourCustomer) {
                return Main.Instance.DatabaseDataState.issues3[rowId]
            } else if (issueSource === TimelineEntrySubType.Gamification) {
                return Main.Instance.DatabaseDataState.issues4[rowId]
            } else if (issueSource === undefined) {
                return Main.Instance.DatabaseDataState.issuesAllIssuesFilter[rowId]
            }
        }
        return undefined
    }

    public render() {
        // mode true means that it was clicked from Risk/Compliance Store (issues)
        // mode false means that it was clicked from Main Dashboard (player timeline), therefore we need to find th issue rowId first

        if (this.state.items === undefined /*Main.Instance.RiskManagementState.rowId !== -1*/) {
            // console.log (Main.Instance.RiskManagementState.rowId)

            const item: IIssue|undefined = Main.Instance.RiskManagementState.mode ?
                                 // Object.assign ({}, Main.Instance.DatabaseDataState.issues[Main.Instance.RiskManagementState.rowId]) :
                                 this.getIssue(Main.Instance.RiskManagementState.rowId, Main.Instance.RiskManagementState.issueSource) :
                                 Object.assign ({}, Main.Instance.DatabaseDataState.playerTimeline[Main.Instance.RiskManagementState.rowId]) as IIssue

            // console.log (Main.Instance.RiskManagementState.history)
            // const item: Partial<IIssue> = undefined
            // if (item === undefined) {return <div/>}
            const history: IIssueHistory[] = Object.assign ([], Main.Instance.RiskManagementState.history)
            for (const Item of history) {
                // if (item.id) Item['id'] = item.id
                if (item && item.playerId) { Item['playerId'] = item.playerId }
                if (item && item.entrySubType) { Item['entrySubType'] = item.entrySubType }
                if (item && item.issueType) { Item['issueType'] = item.issueType }
            }

            Logic.Type.New (Main.Instance.RiskManagementState, {history /*, rowId: -1*/})
            this.setState({items: [item]})
        }

        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main add-setting-wide">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', marginRight: '0px', top: '-30px', right: '-10px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {history: undefined, rowId: undefined})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>
                        <h4>{Assets.CurrentIssueState}</h4>
                        {/*console.log (this.state.items)*/}
                        {getIssuesTable(undefined, this.state.items /*, TimelineEntrySubType.ResponsibleGaming*/)}
                        {/*console.log (Main.Instance.RiskManagementState.history)*/}
                        {Main.Instance.RiskManagementState.history.length > 0 ?
                        <>
                            <h4>{Assets.IssueHistory}</h4>
                            {/*console.log (Main.Instance.RiskManagementState.history)*/}
                            {getIssuesTable(true, Main.Instance.RiskManagementState.history /*, TimelineEntrySubType.ResponsibleGaming*/)}
                        </> : void 0}
                    </div>
                </div>
            </div>
        )
    }
}