import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, FormInputNumber, FormSelect, FormInputArea} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, AllowDecimalCharactersOnly, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, isPureOddyzzey} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {MT} from '../../../Logic/Database/DatabaseData'
export function getMissionsCount() {
    // if (Main.Instance.MissionState.countCalled === false) {
        Logic.Type.New(Main.Instance.MissionState, {countCalled:true})
        DbRoutes.WsGetMissions()
    // }
}

function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.WsGetMissions()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Mission.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {
            if (Main.Instance.MissionState.pNum === (Math.trunc((Main.Instance.MissionState.count - 2) / Main.Instance.MissionState.pSize) + 1)) {
                DbRoutes.removeMission (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeMission (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function updateInfo(info: string) {
    Logic.Type.New (Main.Instance.MissionState, {info})
}

function updateName(name: string) {
    Logic.Type.New (Main.Instance.MissionState, {name})
}

function handleEdit(row: any) { 
    gotoTop()
   
    if (row) {
        const task: any[] = row.original.task !== undefined && row.original.task !== null && row.original.task.length > 0 ? JSON.parse(JSON.stringify(row.original.task)) : []
        const hidden: any[] = row.original.hr !== undefined && row.original.hr !== null && row.original.hr.length > 0 ? JSON.parse(JSON.stringify(row.original.hr)) : []

        // alert (JSON.stringify(task))
        // alert (JSON.stringify(hidden))
        // Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]
/*
        for (const item of task) for (const Item of item.objectives) {
            Item.minbet = Item.minBet
        }
*/
        Logic.Type.New(Main.Instance.MissionState, {
            mode: true,
            id: row.original.id,

            name:row.original.name,
            name_saved:row.original.name,

            info:row.original.description,
            info_saved:row.original.description,

            nextmission: parseInt(row.original.order),
            nextmission_saved: parseInt(row.original.order),

            maxtimes: row.original.mt,
            maxtimes_saved: row.original.mt,

            hiddenrewards: '0',
            csv: '0',
            task_id: parseInt(row.original.task_id),
            CSV: task,
            HR: hidden,

        })
    }
}

function handleInsertUpdate() {
/*
    if (Main.Instance.MissionState.valid_from === null || Main.Instance.MissionState.valid_from === undefined) {
        alert('Mission start date needs to be set.')
        return
    }

    if (Main.Instance.MissionState.valid_to === null || Main.Instance.MissionState.valid_to === undefined) {
        alert('Mission end date needs to be set.')
        return
    }

    if (Main.Instance.MissionState.validFromTime.length === 0) {alert('Mission start time needs to be set.'); return}
    if (Main.Instance.MissionState.validToTime.length === 0) {alert('Mission end time needs to be set.'); return}

    let validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.MissionState.validFromTime)
    let validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.MissionState.validToTime)

    if (validationFromTime === false) {alert('Mission start time is not correct.'); return}
    if (validationToTime === false) {alert('Mission end time is not correct.'); return}

    if (!areDatesSuitable(Main.Instance.MissionState.valid_from, Main.Instance.MissionState.valid_to, Main.Instance.MissionState.validFromTime, Main.Instance.MissionState.validToTime)) {
        alert ('Dates are not suitable')
        return
    }

    let validFrom = ''
    let validTo = ''
    if (Main.Instance.MissionState.valid_from !== undefined && Main.Instance.MissionState.validFromTime !== null && 
        Main.Instance.MissionState.validFromTime !== undefined && Main.Instance.MissionState.validFromTime.length > 0)
        validFrom = Main.Instance.MissionState.validFrom + ' ' + Main.Instance.MissionState.validFromTime

    if (Main.Instance.MissionState.valid_to !== undefined && Main.Instance.MissionState.validToTime !== null && 
        Main.Instance.MissionState.validToTime !== undefined && Main.Instance.MissionState.validToTime.length > 0)
        validTo = Main.Instance.MissionState.validTo + ' ' + Main.Instance.MissionState.validToTime
*/
    const missionItem = {
        id : !Main.Instance.MissionState.id ? '' : Main.Instance.MissionState.id,
        name: !Main.Instance.MissionState.name ? '' : Main.Instance.MissionState.name,
/*
        validFrom,
        validTo,
*/
        tasks: Main.Instance.MissionState.CSV,
        missionOrder: Main.Instance.MissionState.nextmission,
        maxTimes: Main.Instance.MissionState.maxtimes,
        hiddenRewards: Main.Instance.MissionState.HR,
        description: !Main.Instance.MissionState.info ? '' : Main.Instance.MissionState.info,
    }
    // console.log (missionItem)
        // Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]
/*
        for (const item of missionItem.tasks) for (const Item of item.objectives) {
        if (isNaN(Item.minbet as any)) {
            alert ('Minimal bet is not formatted correctly')
            return
        }
        Item.minBet = parseFloat(Item.minbet)
        delete(Item.minbet)
    }
*/
    if (Main.Instance.MissionState.name.length === 0) {
        alert (Assets.compulsoryName)
/*
    } else if (!validationFromTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationToTime) {
        alert (Assets.compulsoryCorrectTime)
*/
    } else if (Main.Instance.MissionState.mode === false) {
         DbRoutes.WSinsertMissionOld(missionItem)
    } else {
         // console.log ('just before update')
         DbRoutes.WSupdateMissionOld(missionItem)
    }
}

function getMissions() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {missionStream : undefined})
    getMissionsCount()
}

function clearFields() {
    Logic.Type.New(Main.Instance.MissionState, {
        name : '',
        info: '',
        mode : false,
        id: undefined,
        nextmission: 0,
        maxtimes: 3,
        csv: undefined,
        task_id: 0,
        CSV: undefined,
        HR: undefined,
        hiddenrewards: '0',
        focusedInput1: null,
        focusedInput2: null,
        valid_from: null,
        valid_to: null,
        validFrom: '',
        validTo: '',
        validFromTime: '',
        validToTime: '',

        name_saved : undefined,
        info_saved: undefined,
        nextmission_saved: undefined,
        maxtimes_saved: undefined,
        // csv_saved: undefined,
        // task_id_saved: undefined,
        // CSV: undefined,
        // HR: undefined,
        // hiddenrewards_saved: undefined,
        // focusedInput1_saved: null,
        // focusedInput2_saved: null,
        // valid_from_saved: undefined,
        // valid_to_saved: undefined,
        // validFrom_saved: undefined,
        // validTo_saved: undefined,
        // validFromTime_saved: undefined,
        // validToTime_saved: undefined
    })

    // Logic.Type.New(Main.Instance.MissionState, {CSV: [], HR: []})

/*
        CSV: [],
        HR: [],

*/
/*
        valid_from: null,
        valid_to: null,
        validFrom: '',
        validTo: '',
        validFromTime: '',
        validToTime: '',
*/

}

function gotoInsertMode() {
    // clearFields()

    if (
        Main.Instance.MissionState.name === Main.Instance.MissionState.name_saved &&
        Main.Instance.MissionState.info === Main.Instance.MissionState.info_saved &&
        Main.Instance.MissionState.nextmission === Main.Instance.MissionState.nextmission_saved &&
        Main.Instance.MissionState.maxtimes === Main.Instance.MissionState.maxtimes_saved // &&
        // parseInt(Main.Instance.MissionState.hiddenrewards) === parseInt(Main.Instance.MissionState.hiddenrewards_saved) &&
        // Main.Instance.MissionState.task_id === Main.Instance.MissionState.task_id_saved &&
        // Main.Instance.MissionState.valid_from === Main.Instance.MissionState.valid_from_saved &&
        // Main.Instance.MissionState.valid_to === Main.Instance.MissionState.valid_to_saved &&
        // Main.Instance.MissionState.validFrom === Main.Instance.MissionState.validFrom_saved &&
        // Main.Instance.MissionState.validTo === Main.Instance.MissionState.validTo_saved &&
        // Main.Instance.MissionState.validFromTime === Main.Instance.MissionState.validFromTime_saved &&
        // Main.Instance.MissionState.validToTime === Main.Instance.MissionState.validToTime_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.MissionState, {offset:(0).toString()})

    getMissions()
    Logic.Type.New(Main.Instance.MissionState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.MissionState.pNum > 0) {

        Logic.Type.New(Main.Instance.MissionState, {
            offset:((Main.Instance.MissionState.pNum - 1) * 
            Main.Instance.MissionState.pSize).toString()})
        getMissions()

        Logic.Type.New(Main.Instance.MissionState,{pNum: Main.Instance.MissionState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.MissionState.pNum + 1) * Main.Instance.MissionState.pSize < 
    Main.Instance.MissionState.count) {

        Logic.Type.New(Main.Instance.MissionState, {
            offset: ((Main.Instance.MissionState.pNum + 1) * 
            Main.Instance.MissionState.pSize).toString()})
        getMissions()
        
        Logic.Type.New(Main.Instance.MissionState,{pNum: Main.Instance.MissionState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.MissionState.count <= Main.Instance.MissionState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.MissionState.count === 0) {

            Logic.Type.New(Main.Instance.MissionState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.MissionState.count - 1) / 
                    Main.Instance.MissionState.pSize)
            Logic.Type.New(Main.Instance.MissionState, {
                offset:(npNum * Main.Instance.MissionState.pSize).toString()})
        }

        getMissions()

        Logic.Type.New(Main.Instance.MissionState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.MissionState,{count:Main.Instance.MissionState.count ++})
    LastPage()
}

export function Update() {
    clearFields()
    getMissions()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.MissionState,{count:Main.Instance.MissionState.count --})
    LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.MissionState,{count:Main.Instance.MissionState.count --})
    getMissions()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.MissionState, {pSize: parseInt(text)})
        FirstPage()
    }
}

function OrderChanged(text: string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.MissionState, {nextmission: parseInt(text)})
    }
}

function MaxTimesChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.MissionState, {maxtimes: parseInt(text)})
    }
}

function NumberOfRoundsChanged(text: string) {
    if (parseInt(text) > 0) {
        const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].roundsNeeded = parseInt(text)
        Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
    }
}

function BigWinValueChanged(text: string) {
    if (parseInt(text) > 0) {
        const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].bigWinValue = parseInt(text)
        Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
    }
}

function DaysNeededChanged(text: string) {
    if (parseInt(text) > 1) {
        const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].daysNeeded = parseInt(text)
        Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
    }
}

function SetMinimumBet(i: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
        if (text.length > 1 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }        
        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['minBet'] = text
        Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getMissionsCount()
    }
}

function SelectTask(task_id: number) {
    if (task_id === 0) {
        Logic.Type.New (Main.Instance.MissionState, {task_id, hiddenrewards: '0', csv: '0'})
    } else {
        const hr: string[] = Main.Instance.MissionState.CSV[task_id - 1].taskId.split('.')
        // console.log (hr)
        const Hr: number = parseInt(hr[hr.length - 1])
        // console.log (Hr)
        Logic.Type.New (Main.Instance.MissionState, {hiddenrewards: '0', task_id: Hr, csv: '0'})
    }
}

function SelectObjective(objective: number) {
    if (objective === 0) {
        Logic.Type.New (Main.Instance.MissionState, {csv: objective.toString()})
    } else {
        // console.log (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives)
        // console.log (+Main.Instance.MissionState.csv - 1)
        // console.log (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[+Main.Instance.MissionState.csv - 1])
        const hr: string[] = Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[objective - 1].objectiveId.split('.')
        // console.log (hr)
        const Hr: number = parseInt(hr[hr.length - 1])
        // console.log (Hr)
        Logic.Type.New (Main.Instance.MissionState, {csv: Hr.toString(), /*task_id: 0,*/ hiddenrewards: '0'})
    }

    // Logic.Type.New (Main.Instance.MissionState, {csv: objective.toString()})
}

function AddObjective() {
    const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    if (tasks[Main.Instance.MissionState.task_id - 1].objectives === undefined) {
        tasks[Main.Instance.MissionState.task_id - 1].objectives = []
    }

    let objectiveId: number = 0
    for (const item of tasks[Main.Instance.MissionState.task_id - 1].objectives) {
        const hr: string[] = item.objectiveId.split('.')
        const Hr: number = parseInt(hr[hr.length - 1])
        if (Hr > objectiveId) {objectiveId = Hr}
    }

    objectiveId++
    const missionId: string = Main.Instance.MissionState.id ? Main.Instance.MissionState.id : getMissionMaxPlus1().toString()
    tasks[Main.Instance.MissionState.task_id - 1].objectives.push({
        objectiveId: missionId + '.' + Main.Instance.MissionState.task_id + '.' + objectiveId, missionType: 0,
    })
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
}

function TimesPlayingChanged(text: string) {
    if (parseInt(text) > -1) {
        const HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
        HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].timesPlaying = text
        Logic.Type.New (Main.Instance.MissionState, {HR})
    }
}

function TaskCompletedChanged(text: string) {
    if (parseInt(text) > 0) {
        const HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
        HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].taskCompleted = text
        Logic.Type.New (Main.Instance.MissionState, {HR})
    }
}

function RemoveHiddenReward() {
    const HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
    HR.splice(parseInt(Main.Instance.MissionState.hiddenrewards) - 1, 1)
    Logic.Type.New (Main.Instance.MissionState, {HR, hiddenrewards: '0'})
}

function AddHiddenReward() {
    let HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
    if (HR === undefined) {
        HR = []
    }
    // we give the id that is one more than highest ID
    let hiddenRewardId: number = 0
    for (const item of HR) {
        const hr: string[] = item.hiddenRewardId.split('.')
        const Hr: number = parseInt(hr[hr.length - 1])
        if (Hr > hiddenRewardId) {hiddenRewardId = Hr}
    }
    hiddenRewardId++
    const missionId: string = Main.Instance.MissionState.id ? Main.Instance.MissionState.id : getMissionMaxPlus1().toString()
    HR.push({hiddenRewardId: missionId + '.' + hiddenRewardId, rewardId: 0, timesPlaying: 0, taskCompleted: 0})
    Logic.Type.New (Main.Instance.MissionState, {HR})
}

function SelectHiddenReward(hiddenreward: number) {

    // console.log (hiddenreward)
    // console.log (Main.Instance.MissionState.HR)
    // console.log (Main.Instance.MissionState.HR[hiddenreward - 1].hiddenRewardId)
    if (hiddenreward === 0) {
        Logic.Type.New (Main.Instance.MissionState, {hiddenrewards: '0', task_id: 0, csv: '0'})
    } else {
        const hr: string[] = Main.Instance.MissionState.HR[hiddenreward - 1].hiddenRewardId.split('.')
        // console.log (hr)
        const Hr: number = parseInt(hr[hr.length - 1])
        // console.log (Hr)
        Logic.Type.New (Main.Instance.MissionState, {hiddenrewards: Hr.toString(), task_id: 0, csv: '0'})
    }
}

function SelectHiddenRewardsDetailed(text: string) {
    const HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
    HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewardId = parseInt(text)
    Logic.Type.New (Main.Instance.MissionState, {HR})
}

function getHiddenRewards(): JSX.Element[] {
    const hiddenRewards: JSX.Element[] = []

    hiddenRewards.push (
        <button
            title = {'Hidden rewards'}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {+Main.Instance.MissionState.hiddenrewards === 0 ? 'btn btn-danger btn-sm mr0 ml' : 'btn btn-default btn-sm mr0 ml'}
            onClick = {() => SelectHiddenReward(0)}
        >
            {!Main.Instance.MissionState.HR || Main.Instance.MissionState.HR.length === 0 ? 
            <><i className = {Assets_.faHiddenClass}/>&nbsp;&nbsp;<span>{Assets.HiddenRewards}</span></> : 
            <i className = {Assets_.faHiddenClass}/>}
        </button>,
    )
    if (Main.Instance.MissionState.HR) {
    for (let i: number = 1; i <= Main.Instance.MissionState.HR.length; i++) {
        // console.log (Main.Instance.MissionState.HR)
        // console.log (Main.Instance.MissionState.hiddenrewards)
        hiddenRewards.push (
            <button
                title = {Assets.SelectAHiddenReward}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === parseInt(Main.Instance.MissionState.hiddenrewards) ? 'btn btn-primary btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectHiddenReward(i)}
            > 
                {Main.Instance.MissionState.HR[i - 1].hiddenRewardId ? Assets.HiddenRewardShort + '. ' + Main.Instance.MissionState.HR[i - 1].hiddenRewardId : Assets.HiddenRewardShort + ' "' + i + '"'}
            </button>,
        )
    }
    }
    hiddenRewards.push (
        <button
            title = {Assets.AddANewHiddenReward}
            style = {{borderRadius: '0px'}}
            key = "-1"
            className = {'btn btn-success btn-sm mr0 ml0'}
            onClick = {() => AddHiddenReward()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    
    if (parseInt(Main.Instance.MissionState.hiddenrewards) > 0) {
    hiddenRewards.push (
        <button
            key = "-2"
            title = {Assets.RemoveTheHiddenReward}
            style = {{borderRadius: '0px'}}
            className = "btn btn-danger btn-sm"
            onClick = {() => RemoveHiddenReward()}
        >
            <i className = {Assets_.faTrashLgButtonClass}/>
        </button>,
    )
    }   
    return hiddenRewards
}
/*
function AddHiddenRewardDetailed() {
    let HR: any[] = Object.assign([], Main.Instance.MissionState.HR)
    HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards.push (0)
    Logic.Type.New (Main.Instance.MissionState, {HR})
}
*/

function getMissionMaxPlus1(): number {
    let max: number = 0
    if (Main.Instance.DatabaseDataState.missionStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.missionStreamAll) {
        if (+item['id'] > max) { max = +item['id'] }
    }
    }

    return max + 1
}

function AddTask() {
    let tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    if (tasks === undefined) {
        tasks = []
    }
    // we give the id that is one more than highest ID
    let taskId: number = 0
    for (const item of tasks) {
        const hr: string[] = item.taskId.split('.')
        const Hr: number = parseInt(hr[hr.length - 1])
        if (Hr > taskId) {taskId = Hr}
    }

    taskId++
    
    const missionId: string = Main.Instance.MissionState.id ? Main.Instance.MissionState.id : getMissionMaxPlus1().toString()
    tasks.push ({taskId: missionId + '.' + taskId, rewardId: 0, objectives: []})
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
}

function RemoveTask() {
    const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    tasks.splice(Main.Instance.MissionState.task_id - 1, 1)
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks, task_id: 0})
}

function getTasks(): JSX.Element[] {
    const tasks: JSX.Element[] = []
    tasks.push (
        <button
            title = {Assets.MissionDescriptionNoTaskSelected}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {Main.Instance.MissionState.task_id ? 'btn btn-default btn-sm mr0 ml0' : 'btn btn-danger btn-sm mr0 ml0'}
            onClick = {() => SelectTask(0)}
        >
            <i className = {Assets_.faCommentClass}/>
            {Main.Instance.MissionState.CSV && Main.Instance.MissionState.CSV.length === 0 ? <span>&nbsp;/&nbsp;{Assets.Tasks}</span> :
            <span>&nbsp;/&nbsp;<i className = {Assets_.faTaskClass}/></span>}
        </button>,
    )
    if (Main.Instance.MissionState.CSV) {
    for (let i: number = 1; i <= Main.Instance.MissionState.CSV.length; i++) {
        tasks.push (
            <button
                title = {Assets.SelectATask}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === Main.Instance.MissionState.task_id ? i === 0 ? 'btn btn-info btn-sm mr0 ml0' :
                'btn btn-info btn-sm ml0 mr0' : i === 0 ? 'btn btn-default btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectTask(i)}
            >
            {Assets.Task + ' ' + Main.Instance.MissionState.CSV[i - 1].taskId}
            </button>,
        )
    }
    }

    tasks.push (
        <button
            title = {Assets.AddANewTask}
            style = {{borderRadius: '0px'}}
            key = "-1"
            className = {'btn btn-success btn-sm mr0 ml0'}
            onClick = {() => AddTask()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.MissionState.task_id) {
        tasks.push (
            <button
                key = "-2"
                title = {Assets.RemoveTheTask}
                style = {{borderRadius: '0px'}}
                className = "btn btn-danger btn-sm mr0 ml0"
                onClick = {() => RemoveTask()}
            >
                <i className = {Assets_.faTrashLgButtonClass}/>
            </button>,
        )
    }

    return tasks
}

function getObjectives() {
    const objectives: JSX.Element[] = []
    objectives.push (
        <button
            title = {Assets.Objectives}
            style = {{borderRadius: '0px'}}
            key = "-3"
            className = {parseInt(Main.Instance.MissionState.csv) ? 'btn btn-default btn-sm mr0 ml0' : 'btn btn-danger btn-sm mr0 ml0'}
            onClick = {() => SelectObjective(0)}
        >
        <i className = {Assets_.faCheckButtonClass}/>
        </button>,
    )    

    for (let i: number = 1; i <= Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives.length; i++) {
        // console.log (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[i-1])
        objectives.push (
            <button
                title = {Assets.SelectAnObjective}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === parseInt(Main.Instance.MissionState.csv) ? 'btn btn-warning btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectObjective(i)}
            >
            {Assets.Objective + ' ' + Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[i - 1].objectiveId}
            </button>,
        )
    }

    objectives.push (
        <button
            title = {Assets.AddANewObjective}
            style = {{borderRadius: '0px'}}
            key = "-1"
            className = {'btn btn-success btn-sm mr0 ml0'}
            onClick = {() => AddObjective()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )

    objectives.push (
        <button
            key = "-2"
            title = {Assets.RemoveTheObjective}
            style = {{borderRadius: '0px'}}
            className = "btn btn-danger btn-sm mr0 ml0"
            onClick = {() => RemoveObjective()}
        >
            <i className = {Assets_.faTrashLgButtonClass}/>
        </button>,
    )
    return objectives
}

function SelectRewardInTask(reward: string) {
    const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    tasks[Main.Instance.MissionState.task_id - 1].rewardId = parseInt(reward)
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
}

function SelectObjectiveType(missionType: string) {
    const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType = parseInt(missionType)
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
}
/*
function updateTime(which: number, text: string) {
    if (text.length > 8) return
    for (let i: number = 0; i < text.length; i++)
    if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && 
        text.charAt(i) !== '5' && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== ':')
        return

    if (which === 0) Logic.Type.New(Main.Instance.MissionState, {validFromTime: text})
    else if (which === 1) Logic.Type.New(Main.Instance.MissionState, {validToTime: text})
}

function DateChange(which: number, date: moment.Moment) {

    let temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    if (which === 0) Logic.Type.New(Main.Instance.MissionState, {validFrom: temp, valid_from: date})
    else if (which === 1) Logic.Type.New(Main.Instance.MissionState, {validTo: temp, valid_to: date})
}
*/
export function Missions() {
    // console.log (IsBuildOddyzzey() + ' ' + IsBuildOddyzzey())

    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
/*
    let validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.MissionState.validFromTime)
    let validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.MissionState.validToTime)

    let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets.reactDatesGreyColor}/>    
*/
    return (<div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.AddEditMissions}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"
            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.MissionState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>{Main.Instance.MissionState.mode ? Assets.EditTheMission + ': ' + Main.Instance.MissionState.name_saved  : Assets.AddANewMission}</h4>

                        {Main.Instance.MissionState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}
                    </div>

                    <div className = "row admin-users-row">
                        <FormInputNumber
                            description = {Assets.SpecifyOrderOfTheMissions}
                            outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                            ClassName = "h30 w60"
                            value = {!Main.Instance.MissionState.nextmission ? '0' : Main.Instance.MissionState.nextmission.toString()}  
                            type = "number" 
                            min = "0"
                            icon = {Assets_.faSort[0]}
                            onChange = {(text) => OrderChanged(text)}
                        />
                        <FormInput
                            description = {Assets.MissionName}
                            value = {!Main.Instance.MissionState.name ? '' :
                                    Main.Instance.MissionState.name} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => updateName(text)} 
                            placeholder = {Assets.pName} 
                            icon = {Assets_.faTargetClass}
                        />

                        <FormInputNumber
                            description = {Assets.MaximumAmountOfTimesTheMissionCanBeTaken}
                            outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                            ClassName = "h30 w60"
                            value = {!Main.Instance.MissionState.maxtimes ? '0' : Main.Instance.MissionState.maxtimes.toString()}  
                            type = "number" 
                            min = "1"
                            iconText = "Mt x"
                            iconTextS = "14px"
                            onChange = {(text) => MaxTimesChanged(text)}
                        />
                        {isPureOddyzzey() ? getHiddenRewards() : void 0}
                    </div>

            <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>{getTasks()}</div>

            {Main.Instance.MissionState.task_id === 0 && parseInt(Main.Instance.MissionState.hiddenrewards) === 0 ?
            <div className = "row admin-users-row-area-small">
                <div className = "register-input textarea-small">
                    <FormInputArea  
                        value = {!Main.Instance.MissionState.info ? '' : Main.Instance.MissionState.info}
                        ClassName = "form-control register-input"
                        onChange = {(text) => updateInfo(text)}
                        placeholder = {Assets.MissionDescriptionOptional}
                        icon = {Assets_.faCommentClass}
                    />
                </div>
            </div> : void 0}

            {Main.Instance.MissionState.task_id !== 0 ?
            <div className = "row admin-users-row clearfix" style = {{position: 'relative'/*, marginBottom: '5px'*/}}>
                <FormInput
                    bg = "#5bc0de"
                    color = "#fff"
                    description = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives ? 
                                   Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives.length === 1 ? Assets.t1o : 
                                  ' ' + Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives + ' ' + Assets.oftt :
                                  'Task name'}

                    narrow = {true}
                    readOnly = {true}
                    value = {Assets.Task + ' ' + Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].taskId}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {() => void 0} 
                    placeholder = ""
                    icon = {Assets_.faTaskClass}
                />

                <FormSelect
                    rewardFor = {1}
                    title = "Task reward"
                    level = {true}
                    mt0 = {true}
                    value = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].rewardId.toString()}
                    data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => SelectRewardInTask(text)}
                    icon = {Assets_.faRewardClass}
                />
                {getObjectives()}
            </div> : void 0}

            {Main.Instance.MissionState.task_id !== 0 && parseInt(Main.Instance.MissionState.csv) !== 0 ?
            <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                <FormInput
                    bg = "#f0ad4e"
                    color = "#fff"
                    description = {'Objective name'}
                    narrow = {true}
                    readOnly = {true}
                    value = {Assets.Objective + ' ' + Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[+Main.Instance.MissionState.csv - 1].objectiveId}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {() => void 0} 
                    placeholder = ""
                    icon = {Assets_.faCheckButtonClass}
                />
                <FormSelect
                    title = "Objective type"
                    level = {true}
                    mt0 = {true}
                    value = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType.toString()}
                    data = {[
                        {id: '1', name: 'Bets count'},
                        {id: '2', name: 'Wins count'},
                        {id: '3', name: 'Consecutive wins'},
                        {id: '4', name: 'Consecutive days'},
                        {id: '5', name: 'Big wins count'},
                        {id: '6', name: Assets.Points},
                        {id: '7', name: 'Different days'},
                    ]}
                    ClassName = "form-control"
                    o1 = "Objective type?"
                    o2 = "Objective type?"
                    onChange = {(text) => SelectObjectiveType(text)}
                    icon = {Assets_.faRequireClass}
                />

                {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType ?
                <FormInputNumber
                    description = {
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.POINTS ?
'Number of points' : 
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BETS_COUNT ?
'Number of bets' : 
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.WINS_COUNT ?
'Number of wins' : 
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_WINS ?
'Number of consecutive wins' : 
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
'Number of big wins' :
Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
'Number of spins' : ''}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].roundsNeeded === undefined ? '1' :
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].roundsNeeded.toString()}  
                    type = "number" 
                    min = "1"
                    // icon = {Assets_.faHashtagClass}
                    iconTextS = "14px"
                    iconText = {
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.POINTS ?
                        '# points' : 
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BETS_COUNT ?
                        '# bets' : 
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.WINS_COUNT ?
                        '# wins' : 
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_WINS ?
                        '# cons. wins' : 
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
                        '# big wins' :
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
                        '# spins' : 
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.DIFFERENT_DAYS ?
                        '# diff. days' :
                        ''}
                    onChange = {(text) => NumberOfRoundsChanged(text)}
                /> : void 0}

                {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType &&
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
                <FormInputNumber
                    description = "Big win value"
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].bigWinValue === undefined ? '15' :
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].bigWinValue.toString()}  
                    type = "number" 
                    min = "1"
                    iconText = "Big win x"
                    iconTextS = "14px"
                    onChange = {(text) => BigWinValueChanged(text)}
                /> : void 0}
                {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
                <FormInputNumber
                    description = "Big win value"
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].daysNeeded === undefined ? '2' :
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].daysNeeded.toString()}  
                    type = "number" 
                    min = "1"
                    iconText = {Assets.Days}
                    iconTextS = "14px"
                    onChange = {(text) => DaysNeededChanged(text)}
                /> : void 0}
            </div> : void 0}

            {Main.Instance.MissionState.task_id !== 0 && parseInt(Main.Instance.MissionState.csv) !== 0 ?
            <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                {Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType !== MT.POINTS ?
                getMinimumBet() : void 0}
            </div> : void 0}

            {parseInt(Main.Instance.MissionState.hiddenrewards) !== 0 ?
            <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                <FormInput
                    bg = "#337ab7"
                    color = "#fff"
                    description = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards ? 
                                   Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards.length === 1 ? Assets.t1r : 
                                  Assets.ThereAre + ' ' + Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards.length + ' ' + Assets.rthr :
                                  Assets.HiddenRewardsName}
                    readOnly = {true}
                    value = {Assets.HiddenReward + ' ' + Main.Instance.MissionState.HR[+Main.Instance.MissionState.hiddenrewards -1].hiddenRewardId}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {() => void 0} 
                    placeholder = ""
                    icon = {Assets_.faHiddenClass}
                />

                <FormInputNumber
                    description = {Assets.hramt}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].timesPlaying === undefined ? '0' :
                             Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].timesPlaying.toString()}  
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faEyeClass}
                    onChange = {(text) => TimesPlayingChanged(text)}
                />

                <FormInputNumber
                    description = {Assets.hraat}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].taskCompleted === undefined ? '0' :
                             Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].taskCompleted.toString()}  
                    type = "number"
                    min = {Main.Instance.MissionState.CSV ? '1' : '0'}
                    max = {Main.Instance.MissionState.CSV ? Main.Instance.MissionState.CSV.length.toString() : '0'}
                    icon = {Assets_.faTaskClass}
                    onChange = {(text) => TaskCompletedChanged(text)}
                />

                <FormSelect
                    rewardFor = {1}
                    level = {true}
                    mt0 = {true}
                    value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewardId}
                    data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => SelectHiddenRewardsDetailed(text)}
                    icon = {Assets_.faRewardClass}
                />
                {/*getHiddenRewardsDetailed()*/}
                {/*
                <button
                    title = "Add a reward of the Hidden Reward"
                    // style = {{borderRadius: '0px'}}
                    className = "btn btn-success btn-sm fl"
                    onClick = {() => AddHiddenRewardDetailed()}
                >
                    <i className = {Assets_.faPlusClass}/>
                </button>
                */}
            </div> : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.MissionState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.MissionState.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>
            </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.MissionState.pSize ? '' : Main.Instance.MissionState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.MissionState.count ? Main.Instance.MissionState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.MissionState.count ? Main.Instance.MissionState.pNum * 
                Main.Instance.MissionState.pSize + 1 : 0}{' - '}
                {Main.Instance.MissionState.count ? 
                ((Main.Instance.MissionState.pNum + 1) * 
                Main.Instance.MissionState.pSize > Main.Instance.MissionState.count ? 
                Main.Instance.MissionState.count : 
                (Main.Instance.MissionState.pNum + 1) * Main.Instance.MissionState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.MissionState.count ? 
            Main.Instance.MissionState.pNum + 1 :0}{' / '}
            {Main.Instance.MissionState.count ? 
            Math.trunc((Main.Instance.MissionState.count - 1) /
            Main.Instance.MissionState.pSize + 1) : 0}
        </span>
        </div>
        <h3>{Assets.ListOfMissions}</h3>
        {Main.Instance.DatabaseDataState.missionStream !== null && Main.Instance.DatabaseDataState.missionStream !== undefined ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.missionStream}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                {width: 50, Header: Assets.Order, accessor: 'order', headerClassName: 'th-left'},
                {width: 250, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {minWidth: 350, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},

                {minWidth: 350, Header: Assets.Tasks, accessor: 'task', headerClassName: 'th-left', Cell: (row) => TableUtils.ConstructCategoriesTasks(row, handleCategoryButton)},
                {width: 50, Header: Assets.Task, accessor: 'task', headerClassName: 'th-left', Cell: (row) => TableUtils.ConstructCategoriesTasks2(row)},

                {width: 50, Header: Assets.HiddenRewardShortest, show: isPureOddyzzey(), accessor: 'hr', headerClassName: 'th-left', Cell: (row) => row ? row.value.length + ' hr' : '0 hr'},
                {width: 50, Header: Assets.Mt, accessor: 'mt', headerClassName: 'th-left', Cell: (row) => row.value + 'x'},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : void 0}
        {/*console.log (Main.Instance.DatabaseDataState.missionStream)*/}
        </div>
    </div>)
}

function handleCategoryButton(rowDataId: string, i: number) {
    const missionStream: any[] = Object.assign([], Main.Instance.DatabaseDataState.missionStream)
    for (const item of missionStream) {
        if (+item['id'] === +rowDataId) {
            const tasks: string[] = item['tasks'].trim().split(' ')
            item['task_id'] = parseInt(tasks[i]) - 1
            break
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {missionStream})
}

function RemoveObjective() {
    const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
    tasks[Main.Instance.MissionState.task_id - 1].objectives.splice(parseInt(Main.Instance.MissionState.csv) - 1, 1)
    Logic.Type.New (Main.Instance.MissionState, {CSV: tasks, csv: '0'})
}

function getMinimumBet(): JSX.Element[] {
    const mb:JSX.Element[] = []

    if (Main.Instance.DatabaseDataState.currenciesStream &&
        (!Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] ||
        !Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives ||
        !Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1] ||
        !Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'])) {
            // console.log ('not found')
            const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
            if (!Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1]) {
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] = {}
            }
            if (!Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives) {
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives = []
            }
            if (!Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]) {
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1] = {}
            }
            if (!Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].currencies) {
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].currencies = []
            }
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
                tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].currencies.push (
                    {code: Main.Instance.DatabaseDataState.currenciesStream[j]['code']})
            }
            // let conditions: Assets_.IConditions = Main.Instance.ChallengeState.conditions ? Object.assign({}, Main.Instance.ChallengeState.conditions) : {}
            Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
        }

    if (Main.Instance.DatabaseDataState.currenciesStream &&
        Main.Instance.MissionState.CSV &&
        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] &&
        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives &&
        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1] &&
        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies']) {
    for (let i: number = 0; i < Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'].length; i++) {
        let code: string
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            if (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['code'] === 
            Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                break
            }
        }
        if (code === undefined) {continue}
        // alert (JSON.stringify(Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1][c]))
        mb.push (
            <div key = {i.toString()} className = "row admin-users-row clearfix" style = {{position: 'relative', height: '35px'}}>
                <button 
                    className = {Main.Instance.MissionState.CSV &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1] &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] &&
                        Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i].minBet
                        ? 'btn btn-success btn-xs fl' : 'btn btn-danger btn-xs fl'}
                    style = {{width: '50px', marginRight: '10px', height: '29px', fontSize: '16px', fontWeight: 700}}
                    onClick = {() => {
                        const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
                        if (tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] === undefined ||
                        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] === undefined ||
                        tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['minBet'] === undefined) {
                            if (tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] === undefined) {
                            tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] = []
                            }
                            tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] = {code, minBet: '0'}
                        } else { tasks[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] = {code} }
                        Logic.Type.New (Main.Instance.MissionState, {CSV: tasks})
                    }}
                >
                    {code}
                </button>
                {Main.Instance.MissionState.CSV &&
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives &&
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1] && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] && 
                Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['minBet'] !== undefined ?
                    <FormInput
                        narrow = {true}
                        description = {Assets.MinimumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {
                            Main.Instance.MissionState.CSV === undefined || 
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1] === undefined ||
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives === undefined ||
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]=== undefined ||
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'] === undefined ||
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i] === undefined ||
                            Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['minBet'] === undefined
                            ? '0' : Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1]['currencies'][i]['minBet']}
                        type = "text"
                        iconText = {Assets.MinBet}
                        iconTextSize = "14px"
                        onChange = {(text) => SetMinimumBet(i, text)}
                    /> : void 0}
            </div>,
        )
    }
    }

    return mb
}