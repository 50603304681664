import {Logic} from '../Type'
import * as moment from 'moment'
import {Select, SelectCurrency2, SelectValue2} from '../Utils'
import * as Assets_ from '../../Views/Assets_'
import {Raffle, IAleaccTriggerFreeRound, Shop, WithdrawalRequest,} from '../Database/DatabaseData'


export class CurrencyRateState extends Logic.Type {
    public date: string[]
    public code: string[]
    public rate: string[]
    public crypto: boolean[]
}
// recurring
// all one big tournament - from date and to date should be specified
// true recurring tournament - it runs indefinitely
// you have to be able to set perhaps different rewards for such tournaments ?!
// two different leaderboards once that we are in the leaderboard view

export class RaffleState extends Logic.Type {
    // countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public raffle: Raffle
    public raffle_: string
    public filterCompleted: boolean
    public date: moment.Moment = null
    public time: string = '20:00'
    public focusedInput: any = null
    public date_string: string = ''
    public day_in_week: string = ''
}

export class WithdrawalRequestState extends Logic.Type {
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public withdrawal: WithdrawalRequest
    public withdrawal_: string    
}

export class ShopState extends Logic.Type {
    // countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public shop: Shop
    public shop_: string
    public filterActive: number = 0
    public filterCategory: number = 0
    public filterPlayerLevel: number = -1
 /*
    public filterCompleted: boolean
    public date: moment.Moment = null
    public time: string = '20:00'
    public focusedInput: any = null
    public date_string: string = ''
    public day_in_week: string = ''
*/
}

export class ChallengeState extends Logic.Type {
    // countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public name: string = ''
    public name_saved: string
    public date: moment.Moment = null
    public date_string_saved: string
    public date_string: string = ''
    // public updating: boolean = false
    // public updating2: boolean = false
    public challenge_type: string = '0'
    public challenge_type_saved: string
    public conditions: Assets_.IConditions
    public focusedInput: any = null
    public filter_challenge_type: string = '0'
}

export class StoreState extends Logic.Type {
    // countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public name: string = ''
    public name_saved: string
    public rewards: any[] = []
    public filters: any = {}
    public rewards_saved: any[]
    public filters_saved: any = {}
    public reward: number = 0
    // date: moment.Moment = null
    // date_string_saved: string
    // date_string: string = ''
    // public updating: boolean = false
    // public updating2: boolean = false
    // challenge_type: string = '0'
    // challenge_type_saved: string
    // conditions: any = {}
    // focusedInput: any = null
    public rewardType: string = '0'
    public freeSpins: string = '-1'
}

export class GZG extends Logic.Type {
    public raffle: boolean = false
    public gametype: boolean = true
    public chosenTicket: number = 0
    public jackpotRaffle: boolean = false

    public phone: string = ''
    public bought: number[] = []
    public rgametype: number[] = []
    public countryID: number = 0

    public mode: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public offset: string = '0'
    public count: number = 0
    public PNum: number = 0
    public PSize: number = 100
    public Offset: string = '0'
    public Count: number = 0
    public countCalled: boolean = false
    public data: any[]
    public data2: any[]
    public ID: number
    public id: number

    public name: string = ''
    public name_saved: string
    public Currency: string = ''
    public currency: string = ''
    public currency_saved: string
    public cutoffhours: number = 1
    public cutoffhours_saved: number
    public isplayable: string = '1'
    public isplayable_saved: string
    public numbers_repeatable: string = '1'
    public numbers_repeatable_saved: string
    public country: string = ''
    public country_saved: string
    public numbers: number = 0
    public numbers_saved: number
    public numbermin: number = 0
    public numbermin_saved: number
    public numbermax: number = 0
    public numbermax_saved: number

    public day_in_week: number[] = []
    public day_in_week_saved: number[]
    public week_in_month: number[] = []
    public week_in_month_saved: number[]
    public fire: string = ''
    public fire_saved: string
    public serialnumbers: number = 0
    public serialnumbers_saved: number
    public serialnumbermin: number = 0
    public serialnumbermin_saved: number
    public serialnumbermax: number = 0
    public serialnumbermax_saved: number
    public letters: number = 0
    public letters_saved: number
    public lettermin: string = 'A'
    public lettermin_saved: string
    public lettermax: string = 'A'
    public lettermax_saved: string

    public stake: number = 0
    public stake_saved: number
    public winners: number = 0
    public winners_saved: number
    public win: number = 0
    public win_saved: number
    public identification: string = ''
    public identification_saved: string
    public payout: string = ''
    public payout_saved: string

    public fixed_odds: any
    public fixed_odds_saved: any

    public fixed_odds_n: number[]
    public fixed_odds_n_saved: number[]

    public fixed_odds_n_letter: number[]
    public fixed_odds_n_letter_saved: number[]
    
    public fixed_odds_stake: number[]
    public fixed_odds_stake_saved: number[]
    
    public fixed_odds_value: number[]
    public fixed_odds_value_saved: number[]

    public winningTicket: number = 0
}

export class TournamentState extends Logic.Type {
    // public startMinute: string = ''
    // public dayOfWeek: string = ''
    // public durationMinutes: string = ''
    // public startHour: string = ''
    public setDefaultMinutes: boolean = true
    public setDefaultDurationMinutes: boolean = true
    public active: boolean = false
    // public updating2: boolean = false
    public info: string = ''
    public info_saved:string 
    public sublevel_min: number = 0
    public sublevel_max: number = 0
    public level_min:number = 0
    public level_max:number = 0
    public part_of_wager: boolean[] = [false, false, false, false, false]
    public daily: boolean = true
    public itipp: boolean = false
    public main_currency: string = Assets_.NA
    // logical variables
    public pNum: number = 0
    public pSize: number = 100
    public offset: string = '0'
    public countCalled: boolean = false
    public count: number = 0
    public mode: boolean = false
    public modeAdvanced: boolean = false
    public modeRecurring: boolean = false
    // modePrizepool: boolean = false
    public modeLeaderboard: boolean = false
    // Mode: boolean = false
    public leaderboard: any
    public sort: number[] = []
    public Sort: string[] = []
    public from_date: moment.Moment = null
    public to_date: moment.Moment = null
    public fromDate: string = ''
    public toDate: string = ''
    public fromTime: string = ''
    public toTime: string = ''
    public focusedInput: any = null
    public focusedInput2: any = null
    public Leaderboard_type: any[]
    public Leaderboard_type2: any[]
    public mainCurrency: string = '0'
    // content variables
    public id: string = '0'
    public title: string = ''
    public currencies: number[]
    public from_time: string = ''
    public to_time: string = ''
    public valid_from: string = ''
    public valid_to: string = ''
    public validFrom: string = ''
    public validTo: string = ''    
    public opt_in: boolean = false
    // real_money: boolean = false
    // recurring: boolean = false
    public DaysInAWeek: Select[]
    public days_in_a_week: number[]
    public DaysInAMonth: Select[]
    public days_in_a_month: number[]
    public Weeks: Select[]
    public weeks: number[]
    public Months: Select[]
    public months: number[]
    public Annual: Select[]
    public annual: number[]
    public RecurringInWeek: string = '0'
    public RecurringInMonth: string = '0'
    public RecurringWeek: string = '0'
    public RecurringMonth: string = '0'
    public RecurringYear: string = '0'
    public recurring_from_time: string[] = ['','','','','']
    public recurring_to_time: string[] = ['','','','','']
    public recurringFromTime: string[] = ['','','','','']
    public recurringToTime: string[] = ['','','','','']

    public time1: boolean = true
    public time2: boolean = true

    public NotifyPlayers: Select[]
    public notify_players: string = '0'
    // recurringTypeReward: string = '0'
    public recurringTypeRecurring: string = '0'
    public recurringType: string = '0'
    public RecurringType: Select[]
    public RecurringType_: Select[]
    public RecurringTypeReward: Select[] = []
    public recurring_type: string = '0'
    public recurring_option: string = '0'
    public RecurringOptions: SelectValue2[]
    public recurring_types: number[] = [1,2,3,4]
    public opt_in_cost: string[] = []
    public min_rounds: string = '0'
    public max_rounds: string = '0'
    public leaderboardType: string = '0'
    public leaderboard_type: string = '0'
    public leaderboard_type2: string = '0'
    public casino_vendors: string = '0'
    public Casino_vendors: any[]
    public casino_games: string = '0'
    public Casino_games: any[]
    public bet_amount_min: string[] = ['0']
    public bet_amount_max: string[] = ['0']
    public total_max0: string[] = []
    public total_max1: string[] = []
    public total_max2: string[] = []
    public total_max3: string[] = []
    public total_max4: string[] = []
    public tournament: any
/*
    brands: number[]
    brand: string = '0'
    Brands: Select[] // Brand sorted
    Brand: string[] = [
        'Winnie Jackpot'
    ]
*/
    public vendors: number[]
    public vendor: string = '0'
    public Vendors: Select[] // Brand sorted    
    public Vendor : string[] = [
        'Vendor 6',
        'Vendor 4',
        'Vendor 3',
        'Vendor 5',
        'Vendor 1',
        'Vendor 2',
    ]

    public games: number[]
    public game: string = '0'
    public Games: Select[] // Brand sorted
    public Game : string[] = [
        'Critters',
        'Fruitastic',
        'Goldminer',
        'MinersINC',
        'Pirate Queen',
        'Scuba View',
        'TreKronor GoldenPuck',        
        'Fortune God',
        'Treasure Of The Carribean',
    ]
    
    public currencySelected: string = '0'
    public Currencies: SelectCurrency2[] // Brand sorted
    public tournamentType: string = '0'
    public TournamentType: any[]
    public odds_type: string = '0'
    public odds_req: string = '0'
    public odds: string = ''
    public odds_type2: string = '0'
    public odds_req2: string = '0'
    public odds2: string = ''
    public Odds2: boolean = false
    public FixedLiveOdds: any[]
    public OddsRequirement: any[]
    public prizes: any[][]
    public TournamentReward: any[]
    public selection: number = 0
    // saved variables
    public title_saved: string
    public B: any[] = []
    public R: any[] = []
    public M: any = {}
    public O: any = {}
    public P: any[] = []
    public V: any[] = []
    public I: any[] = []
    public RS: any[] = []
    public modeBoosters: boolean = false
    public modeResults: boolean = false
    public modeMultipliers: boolean = false
    public modeOptin: boolean = false
    public modePrizes: boolean = false
    public modeValidGames: boolean = false
    public modeRaces: boolean = false
    public rewardType: string = '0'
    public freeSpins: string = '-1'
}

export class CategoryDetailState extends Logic.Type {
    public countCalled: boolean = false
    public CountCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public PNum: number = 0
    public PSize: number = 100
    public Count: number = 0
    public Offset: string = '0'
    public mode: boolean = false
    public selected: boolean = false
    /*selectedCategory:string = ''*/
    public Mode: boolean = false
    public offset: string = '0'
    public category_id:string = '0'
    public category_filter:string = '0'
    public category_name:string = ''
    public category_id_saved:string = '0'
    public category_name_saved:string = ''
    
    public detail_id:string = '0'
    public detail_name:string = ''
    public detail_category_id:string = '0'
    public detail_id_saved:string = '0'
    public detail_name_saved:string = ''
    public detail_table_game:string = '0'
    public detail_table_game_saved:string = ''
    public detail_deposit_option:string = '0'
    public detail_deposit_option_saved:string = ''
    public detail_odds_type:string = '0'
    public detail_odds_type_saved:string = ''
    public detail_slots:string = '0'
    public detail_slots_saved:string = ''
    public detail_login_option:string = '0'
    public detail_login_option_saved:string = ''
    public detail_registration_option:string = '0'
    public detail_registration_option_saved:string = ''
    public detail_withdrawal_option:string = '0'
    public detail_withdrawal_option_saved:string = ''
    // detail_category_id_saved:string = '0'
}

export class RSTState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public expand: boolean = false
    public Expand: number[] = []
    public Count: number = 0
    public sort:number[] = []
    public Sort:string[] = []
    public filter_category: string = '0'
    public filter_date: string = '0'
    public filter_player: string = '0'
    public filter_date2: string = '0'
    public stats: any[]

    public from_date:moment.Moment|null = null
    public to_date:moment.Moment = null
    public from__date: string = ''
    public to__date: string = ''    
    public from_time: string = ''
    public fromTime: string = ''
    public to_time: string = ''
    public toTime: string = ''
    public focusedInput: any
    public focusedInput2: any

    public playerid: any[]
    public playerId: number = 0
}

export class MissionState extends Logic.Type {
    public count: number = 0

    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 10
    public mode: boolean = false
    public offset: string = '0'
    public id:string

    public name: string = ''
    public name_saved:string

    public info: string = ''
    public info_saved:string

    public sort:number[] = []
    public Sort:string[] = []
    // category: string = ''
    // category_idx: number = -1
    // category_id: string = '1'
    public csv: string
    public CSV: any[]
    public HR: any[]

    // public updating: boolean = false
    // public updating2: boolean = false
    // public updating3: boolean = false
    // public updating4: boolean = false

    public focusedInput1: any = null
    public focusedInput2: any = null

    public valid_from: moment.Moment = null
    public valid_to: moment.Moment = null

    public validFrom: string = ''
    public validTo: string = ''

    public validFromTime: string = ''
    public validToTime: string = ''

    public nextmission: number = 0
    public nextmission_saved: number

    public maxtimes: number = 3
    public maxtimes_saved: number

    public hiddenrewards: string = '0'
    // hiddenrewards_saved: string

    public task_id: number = 0
    // task_id_saved: number
 
    // valid_from_saved: moment.Moment
    // validFrom_saved: string

    // valid_to_saved: moment.Moment
    // validTo_saved: string

    // validFromTime_saved: string
    // validToTime_saved: string

}

export class RewardShopState extends Logic.Type {
    public V1: any[] = []
    public I1: any[] = []
    public V2: any[] = []
    public I2: any[] = []
    public V3: any[] = []
    public I3: any[] = []
    public modeValidGames: boolean = false
    public modeInvalidGames: boolean = false
    public valid1: number = -1
    public invalid1: number = -1
    public valid2: number = -1
    public invalid2: number = -1
    public valid3: number = -1
    public invalid3: number = -1
    public coinsGivenOnRegistration: number = 15

    public playersSelected: boolean = false
    public textsSelected: boolean = false
    public detailsSelected: boolean = false
    public eligibleSelected: boolean = false
    public calendarSelected: boolean = false
    
    public custom: IAleaccTriggerFreeRound = undefined
    public custom_saved: IAleaccTriggerFreeRound
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 1000
    // public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id:string
    public rewardIntent: number[] = [0]
    public rewardIntentSaved: number[]
    public rewardFor_saved: number
    public price:string
    public prices:string[] = ['','','','','','','','']
    public discounts:string[] = ['','','','','','','','']
    public info:string[] = ['','','','','','','','']
    public info_saved:string[]
    public discount:string
    public image:string = Assets_.zeroPng
    public name:string[] = ['','','','','','','','']
    public choose_category:string[] = ['','','','','','','','']
    public active:string
    public active_saved:string
    public free_round:string
    public free_round_saved:string
    public only_newly_registered: string
    public only_newly_registered_saved: string
    public buyable:string
    public buyable_saved:string
    public price_saved:string
    public discount_saved:string
    public image_saved:string
    public name_saved:string[]
    public index:string = '1'
    public index_saved:string
    public banner:string  = '0'
    public banner_saved:string
    public Category:string  = '-1'
    public Category_saved:string
    public filter_banner: string = '0'
    public filter_category: string = '0'
    public filter_type: string = '0'
    public filter_free_round: string = '0'
    public filter_buyable: string = '0'
    public filter_active: string = '0'
    public filter_only_newly_registered: string = '0'  
    public filter_fsAmount: string = '0'
    public sort:number[] = []
    public Sort:string[] = []
    public category: string = ''
    public category_idx: number = -1
    public category_id: string = '1'
    public csv: string
    public CSV: any = {category1: {}, category2: {}, category3: {}}
    // public updating: boolean = false
    // public updating2: boolean = false
    public filtered: [] = []
    public fromDate: moment.Moment = null
    public toDate: moment.Moment = null
    public fromTime: string = ''
    public toTime: string = ''

    // fromDate: string = ''
    // toDate: string = ''
    // booster_common: boolean = false

    public focusedInput01: any = null
    public focusedInput02: any = null

    public focusedInput11: any = null
    public focusedInput12: any = null

    public focusedInput21: any = null
    public focusedInput22: any = null

    // players: number[] = []
    // players_saved: number[]
    public bonusCode: string = ''
    
}

export class LottoState extends Logic.Type {
    public filter_category: string = '0'
    public filter_active: string = '0'  
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public modeAdvanced: boolean = false
    public offset: string = '0'
    public id:string
    public identification: number = 0
    public identification_saved: number = 0
    // info:string[] = ['','','','','','']
    // choose_category:string[] = ['','','','','','']
    public image: string = Assets_.zeroPng
    // name: string = ''
    public active: string = '1'
    public active_saved: string
    public image_saved: string
    // name_saved: string
    public idx: string = '1'
    public idx_saved: string
    // jackpot: string = '0'
    // jackpot_saved: string
    // jackpot_currency: string = ''
    // jackpot_currency_saved: string
    public JackpotSize: number
    public JackpotCurrency: string
    public price: string = '0'
    public price_saved: string
    public price_currency: string = ''
    public price_currency_saved: string
    public sort: number[] = []
    public Sort: string[] = []
    public site_id: string
/*
    Category:string  = '0'
    Category_saved:string
    category: string = ''
    category_idx: number = 0
    category_id: string = '0'
*/
}

export class LottoTransactionsState extends Logic.Type {
    public filter_category: string = '0'
    public filter_active: string = '0'  
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100 // was 10
    public count: number = 0
    // mode: boolean = false
    public modeAdvanced: boolean = false
    public offset: string = '0'
    public playerid: number = 0
    public id: number = 0
    public ticket: number[] = []
    public custom: any
    public combo: number = 0
    public comboid: number = 0
    public sheet: number = 0
    public draw: number = 0
    // result: draw result
    // Result: any[] lsWinners MORE
    // results: any[] lsWinners ONE
    // bet: any
}

export class LanguageState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public code_id: string = '0'
    public code_id_saved:string
    public flag_id: string = '0'
    public flag_id_saved:string
    public path: string = ''
    public path_saved:string
    public code: string
    public native:string
    public name: string
    public direction: string
    // flag: string = Assets_.zeroPng
    // flag_saved: string    
    public active: string = '1'
    public active_saved: string
    public main: string = '2'
    public main_saved: string
    public filter_active: string = '0'
}

export class BannerState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public native:string
    public name: string = ''
    public name_saved: string

    public image: string = Assets_.zeroPng
    public image_saved: string

    public active: string = '1'
    public active_saved: string
    public filter_active: string = '0'
}

export class FlagsState extends Logic.Type {
    public countCalled: boolean = false
    public count: number = 0
    public filter_letter: boolean[]
    public filterLetter: string[]
}

export class PlayersState extends Logic.Type {
    public filter_letter: boolean[]
    public filterLetter: string[]
}

export class GamesState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public Count: number = 0
    public selected: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public native:string
    public name: string = ''
    public name_saved: string

    public background: string = Assets_.zeroPng
    public background_saved: string
    public thumbnail: string = Assets_.zeroPng
    public thumbnail_saved: string

    public link: string = ''
    public link_saved: string

    public banner: string = '0'
    public banner_saved: string

    public version: string = ''
    public version_saved: string

    public group: number = 0
    public group_saved: number

    public idx: number = 0
    public idx_saved: number

    public provider_id: number = 0
    public provider_id_saved: number

    public lines: number = 0
    public lines_saved: number

    public reels: number = 0
    public reels_saved: number

    public payout: string = ''
    public payout_saved: string

    public gameid: string = ''
    public gameid_saved: string

    public provider: string = ''
    public provider_saved: string

    public assets_provider: string = ''
    public assets_provider_saved: string

    // win_lines: number[] = []

    public winlines: string
    public winlines_saved: string
    
    public symbols: number = 0
    public symbols_saved: number

    public mystery: boolean = false
    public mystery_saved: boolean

    public scatter: boolean = false
    public scatter_saved: boolean

    public wild: boolean = false
    public wild_saved: boolean

    public free_spins: boolean = false
    public free_spins_saved: boolean

    public info: string = ''
    public info_saved: string

    public show_info: boolean = false
    
    public active: string = '1'
    public active_saved: string
    public filter_active: string = '0'
    public upload_game_files: boolean = false
    public files: File[] = []
    public progressFiles: number = 0
    public filter_provider: string = '0'
    public filter_freespins: string = '0'
    public filter_flexible: string = '0'
    public filter_letter: boolean[]
    public filterLetter: string[]
    public providers: number[] = []
    public gameCategories: number[] = []
    public categorize: boolean = false
    public categories: number[]
    public includedGames: number[][]
    public category: number = -1
    public Category: string = ''
    public Categories: string[]
    public contribution: string = '-1'
}

export class AchievementsState extends Logic.Type {
    public sort:number[] = []
    public Sort:string[] = []
    public target:string = '0'
    public target_saved:string = '0'
    // Mode: boolean = false
    public site_name_filter: string
    public site_name: string
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    // isOpen: boolean = false
    public name: string = ''
    public description: string = ''
    public id: string = '0'
    public operator_id: string = '0'
    public offset: string = '0'
    public name_saved: string = ''
    public description_saved: string = ''
    public custom_saved: string = ''
    public site_id: string = '0'
    public level_id: string = '0'
    public level_id_saved: string
    public sublevel_id: string = '0'
    public sublevel_id_saved: string
    public losses:string = '0'
    public losses_saved:string
    public wagering:string = '100'
    public wagering_saved:string
    // tournaments_only: boolean = false
    // tournaments_only_saved: boolean
    public holding_id: string = '0'
    public point_program_id:string = '0'
    // point_type:string = '0'
    // point_type_saved:string = '0'
    // sub_point_type:string = '0'
    // sub_point_type_saved:string = '0'
    // points_pcu:number = 1
    // points_pcu_saved:number = 1

    // site_id :string = '0' NOT NEEDED
    // search :string = ''
    // siteid: string[] = []
    // site_count :number = 0
    // selected_sites :number = 0
    // site :boolean[] = []
    // sites: string[] = [] NOT NEEDED
    // site_name :string = '' NOT NEEDED
    // custom:string = ''

    // isNormalInteger :boolean[] = []
    // currency :string[] = []
    // currencyDefault :string = '0'

    // currency_rates_codes:string[] = []
    // currency_code_id :string[] = []
    // operator_id_saved :string
    // site_id_saved:string
    // holding_id_saved:string
}

export class CRMPointState extends Logic.Type {
    // category:string = '0'
    // detail:string = '0'
    // category_saved:string = '0'
    // detail_saved:string = '0'
    // modeBetSlip:boolean = false

    public name: string = ''
    public name_saved: string
    public percentage: number = 0
    public percentage_saved: number
    public currency_id:string = '0'
    public currency:string
    public currency_id_saved:string
    public countCalled:boolean = false
    public pNum :number = 0
    public isNormalInteger :boolean[] = []
    public pSize :number = 100
    public count :number = 0
    public offset:string = '0'
    public id:string = '0'
    public type_id_filter:string = '0'
    public type2_id_filter:string = '0'
    public type_id:string = '0'
    public type_id_saved:string
    public type2_id:string = '0'
    public type2_id_saved:string
    public points_pcu:number = 1000
    public points_pcu_saved:number
    public bet_size_id:string = '0'
    public bet_size_id_saved:string
    public bet_size_c:number = 1000
    public bet_size_c_saved:number
    public multiplier_id:string = '0'
    public multiplier_id_saved:string    
    public average_id:string = '0'
    public average_id_saved:string
    public average_c:number = 1000
    public average_c_saved:number    
    // example:number
    // example:string = '10'
    // average_example:string = '10'
    public Value1:string
    public Value2:string
    public Value1P:string
    public Value2P:string
    public nlp:number = 0
    public nlp_saved:number
    // Example:string
    // Examplea:string
    // ExampleP:string
    // ExampleA:string
    public various:string = ''
    public various_saved:string
    public active:boolean = true
    public active_saved:boolean
    public losses:number = 0
    public losses_saved:number
    public wagering:number = 0
    public wagering_saved:number
    public change_gs:boolean = false
    public change_gs_saved:boolean
    public target:string = '0'
    public target_saved:string = '0'
    public bet_min:number = 0
    public bet_min_saved:number
    public bet_max:number = 0
    public bet_max_saved:number
    public chart1:boolean = false
    public chart2:boolean = false
    public chartWidth:number = 1200
    public chart1_start:number = 1
    public chart1_step:number = 3
    public chart1_stop:number = 100
    public chart2_start:number = 1
    public chart2_step:number = 3
    public chart2_stop:number = 100
    public chart1_start_saved:number
    public chart1_step_saved:number
    public chart1_stop_saved:number
    public chart2_start_saved:number
    public chart2_step_saved:number
    public chart2_stop_saved:number
    public chart1_value:number = 10
    public chart2_value:number = 10
    public chart1_value_saved:number
    public chart2_value_saved:number
    public sort:number[] = []
    public Sort:string[] = []

    public auto_include:boolean = false
    public auto_include_saved:boolean
    public mode:boolean = false

    public game_ids:string[] = []
    public game_ids_raw:string
    public game_ids_n:string
    public game_ids_saved:string[]
    public game_ids_selected:boolean[] = []
    public game_ids_selected_saved:boolean[]

    public game_ids_category:string[] = []

    public from_date:moment.Moment|null
    public from_date_saved:moment.Moment
    public to_date:moment.Moment = null
    public to_date_saved:moment.Moment = null
    public from_time: string = ''
    public from_time_saved: string
    public to_time: string = ''
    public to_time_saved: string

    public focusedInput: any
    public focusedInput2: any
    // focused:boolean = false
    // tournaments_only:boolean = false
    // tournaments_only_saved:boolean
    // points:number
    // search :string = ''
    public sublevel_min: number = 0
    public sublevel_max: number = 0
    public level_min:number = 0
    public level_max:number = 0
    public sublevel_min_saved: number
    public sublevel_max_saved: number
    public level_min_saved:number
    public level_max_saved:number

    public dataGamesSorted:any[]
    public exclude_games_id:string = '0'
    public excluded_games:number[] = []
    public exclude_games:boolean = false
    public exclude_games_saved:boolean
    public excluded_games_list:string = ''
    public excludedgames:string[] = []

    public include_games_id:string = '0'
    public included_games:number[] = []
    // included_games_saved:number[]
    public include_games:boolean = false
    public include_games_saved:boolean
    public included_games_list:string = ''
    public includedgames:string[] = []
    public playerlist:string[] = []
    // playerlist_saved:string[]
    public player_list:boolean = false
    public player_list_saved:boolean
    public player_list_id:string = '0'
    public player_list_id_saved:string
    public dataPlayerListsSorted:any[]
    public player_list_names:string = ''
}

export class LevelsState extends Logic.Type {
    public consecutive_bonus: any[] = [] // [{}]
    public consecutive_bonus_saved: any[]
    public tokens_percentage: number = 0
    public tokens_percentage_saved: number
    public editing:string = ''
    public level:string = ''
    public level_saved:string = ''
    public copy_existing_site:boolean
    // site_name : string    
    public site_name_filter : string
    public isSubLevel:boolean = false
    // level_name:string
    // sublevel_name:string
    public countCalled:boolean = false
    // site_count :number = 0
    // search :string = ''
    public pNum :number = 0
    // site_name :string = ''
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false
    public isOpen :boolean = false
    public name :string = ''
    public description :string = ''
    // NOT NEEDED site_id :string = '0'
    public id :string = '0'
    public operator_id :string = '0'
    public offset:string = '0'

    // site ids
    // siteid: string[] = []
    public custom:string = ''
    // selected_sites: number = 0

    // site name IS NOT NEEDED!
    // sites: string[] = []

    // ali je izbran ali ne
    // site :boolean[] = []

    public name_saved :string = ''
    public description_saved :string = ''
    public custom_saved:string = ''
    // operator_id_saved :string
    public hierarchy:string = '0'
    public hierarchy_saved:string
    public site_id:string = '0'
    // site_id_saved:string
    public holding_id:string = '0'
    // holding_id_saved:string
    public level_id:string = '0'
    public level_id_saved:string
    public sublevel_min:string = '0'
    public sublevel_min_saved:string
    public tokens: string = '0'
    public tokens_saved: string
    public token: number = 0
    public ticket: any = {}
    public ticket_saved: any    
    public sublevel_max:string = '0'
    public sublevel_max_saved:string
    public sublevel_decimal: string = '0'
    public sublevel_decimal_saved: string = '0'
    
    public site_clone_id:string = '0'
    public filter_site_id:string ='0'
    public filter_level_id:string ='0'
    public filter_level:string = '0'
    // levels_only:boolean = false
    // sub_levels_only:boolean = false
    public prte: string = '0'
    public prte_saved: string
    public sublevels: string = '0'
    public sublevels_saved: string
    public level_value: string = '0'
    public level_value_saved: string
    public stagnation: string = '1000'
    public stagnation_saved: string
    public toggle: boolean = false

    // this is not in the db yet
    public columns:number = 10
    public columns_saved:number
    public reward_every:number = 0
    public reward_every_saved:number
    public reward_id:string = '0'
    public reward_id_saved:string
    public reward_final:string = '0'
    public reward_final_saved:string
    public final_level:boolean = false
    public final_level_saved: boolean
    public show_total:boolean = true
    public show_totalp:boolean = true
    public show_cumul:boolean = true
    public show_cumulp:boolean = true
    public sort:number[] = []
    public Sort:string[] = []
    // runOnce:boolean = true
    // public updating: boolean = false
    // public updating2: boolean = false
}

/*
export class CRMFreeRounds extends Logic.Type {

    value: string = '10'
    link: FreeRoundsLinkValue = 'http://46.254.14.115/spincircus'
}

export class CRMWagerPlanState extends Logic.Type {
    modeBetSlip:boolean = false
    countCalled:boolean = false
    pNum :number = 0
    isNormalInteger :boolean[] = []
    pSize :number = 20
    count :number = 0
    offset:string = '0'
    id:string = '0'
    type_id:string = '0'
    type_id_saved:string
    odds_type_id:string = '0'
    odds_type_id_saved:string
    odds:string = ''
    odds_saved:string

    type_id_filter:string = '0'
    
    auto_include:boolean = false
    auto_include_saved:boolean
    mode:boolean = false
    name:string = ''
    name_saved:string
    game_ids:string[] = []
    game_ids_raw:string
    game_ids_n:string
    game_ids_saved:string[]
    game_ids_selected:boolean[] = []
    game_ids_selected_saved:boolean[]

    game_ids_category:string[] = []

    currency :string[] = []
    currency_saved:string[]
    main_currency:string = '0'
    main_currency_saved:string
    from_date:string = ''
    from_date_saved:string
    to_date:string = ''
    to_date_saved:string

    // tournaments_only:boolean = false
    // tournaments_only_saved:boolean
    // points:number
    // search :string = ''
}

export class LoyaltyFreeRoundState extends Logic.Type {

    countCalled:boolean = false
    pNum :number = 0
    pSize :number = 20
    count :number = 0
    mode :boolean = false
    offset:string = '0'
    type_id_filter:string = '0'
    game_provider_id :string = '0'
    game_provider_id_saved :string = '0'
    id :string = '0'
    name :string = ''
    site_name:string = ''
    site_name_filter:string = ''
    operator_id :string = '0'
    site_id :string = '0'
    holding_id :string = '0'
    from_datetime :string=''
    to_datetime :string=''
    type_id :string='0'
    games_ids_n :string='0'
    game_ids_raw:string
    games_ids :string[] = []
    game_ids_selected :boolean[] = []
    game_ids_category:string[] = []
    // game_ids_provider:boolean[] = []
    spins_bets :string='0'
    recurring :boolean=true
    spins_bets_awarded :string='0'
    player_cap :string='0'
    reset_game_launch :boolean=false
    recurring_from_game_launch_only :boolean=false
    spins_bets_awarded_amount :string='0'
    spins_bets_min:string = '0'
    spins_bets_min_odds:string = '1'
    name_saved :string = ''
    operator_id_saved :string 
    site_id_saved :string
    holding_id_saved :string
    from_datetime_saved :string
    to_datetime_saved :string
    type_id_saved :string
    games_ids_saved :string[]
    games_ids_selected_saved :boolean[]
    spins_bets_saved :string
    recurring_saved :boolean
    spins_bets_awarded_saved :string
    player_cap_saved :string
    reset_game_launch_saved :boolean
    recurring_from_game_launch_only_saved :boolean
    spins_bets_awarded_amount_saved :string
    spins_bets_min_saved:string
    spins_bets_min_odds_saved:string
}


export class PlayerGroupsRewardsState extends Logic.Type {
    games_ids :string[]=[]
    game_ids_selected :boolean[]=[]
    game_ids_category:string[] = []
    games_ids_raw:string
    games_ids_n :string='0'
    site_name_filter:string = '0'
    provider_id:string = '0'
    countCalled:boolean = false
    type_id_filter:string = '0'
    site_name:string
    custom:string = ''
    // data:Data = null

    search :string = ''
    pNum :number = 0
    pSize :number = 20
    count :number = 0
    mode :boolean = false
    offset:string = '0'
     
    req:boolean = false
    rew:boolean = false

    req1:boolean = false
    req2:boolean = false
    req3:boolean = false
    req4:boolean = false
    req5:boolean = false
    req6:boolean = false
    req7:boolean = false
    req8:boolean = false
    req9:boolean = false
    req10:boolean = false
    rew1:boolean = false
    rew3:boolean = false

    req1_set:boolean = false
    req2_set:boolean = false
    req3_set:boolean = false
    req4_set:boolean = false
    req5_set:boolean = false
    req6_set:boolean = false
    req7_set:boolean = false
    req8_set:boolean = false
    req9_set:boolean = false
    req10_set:boolean = false
    req11_set:boolean = false

    rew1_set:boolean = false
    rew2_set:boolean = false
    rew3_set:boolean = false
    rew4_set:boolean = false

    name :string = ''
    description :string = '' 
    name_saved :string = ''
    description_saved :string = '' 
    type_id:string
    type_id_saved:string
    
    id :string = '0'
    operator_id :string = '0'
    site_id:string = '0'
    master_id:string = '0'
     
    // game:boolean[] = []
    date_from:string|null = '' 
    date_to:string|null = ''
    date_from_saved:string|null 
    date_to_saved:string|null

    rep:boolean = false
    multipleReward:boolean = false
    delay:boolean = false
    delayDays:string = '0'
    delayHours:string = '0'
    req1_points:string = '0'
    req1_points_after_valid:boolean = false
    req1_chosen:string = '0'
    rew1_points:string = '0'
    rew1_times:string = '0'
    rew1_chosen:string = '0'
    req2_points:string = '0'
    req2_points_after_valid:boolean = false
    req2_chosen:string = '0'
    req2_chosen_sub:string = '0'
    rew3_chosen:string = '0'
    rew3_chosen_sub:string = '0'
    req3_logins:string = '0'
    req3_logins_max:string = '0'
    req3_logins_min_hours:string = '0'
    req3_logins_start_date:string = '0'
    req4_amount:string = '0'
    req4_times_max:string = '0'
    req4_start_date:string = ''
    req5_amount:string = '0'
    req5_times_max:string = '0'
    req5_start_date:string = ''
    req6_amount:string = '0'
    req6_times_max:string = '0'
    req6_start_date:string = ''
    req7_amount:string = '0'
    req7_times_max:string = '0'
    req7_start_date:string = ''
    req8_amount:string = '0'
    req8_times_max:string = '0'
    req8_start_date:string = ''
    req9_amount:string = '0'
    req9_times_max:string = '0'
    req9_start_date:string = ''
    req10_chosen:string = '0'
    req10_chosen1:string = '0'
    req10_chosen2:string = '0'
    req10_chosen3:string = '0'
    req10_chosen4:string = '0'
    req10_chosenLogic:string = ''
    req10_chosenLogic1:string = ''
    req10_chosenLogic2:string = ''
    req10_chosenLogic3:string = ''
    req10_chosenLogic4:string = ''
    req11_chosen:string = '0'
    req11_amount:string = '0'

    rew4_currency:string = ''
    rew4_numRounds:string = '0'
    rew4_totalAmount:string = '0'  
    rew4_active:boolean = false
    rew4_type:string = ''
    rew4_betLevel:string = '0'
    rew4_coinValue:string = '0'
    rew4_numLines:string = '0'
    rew4_gameIds:string = '0'
    rew4_priority:boolean = false
    rew4_wageringReqFactor:string = '0'
    rew4_wageringReqAmount:string = '0'
    rew4_gamingenius:boolean = false
    rew4_valid_triggered_days:string = '0'
    rew4_valid_triggered_hours:string = '0'
    rew4_valid_activation_days:string = '0'
    rew4_valid_activation_hours:string = '0'
    rew4_valid_firstDefer_days:string = '0'
    rew4_valid_firstDefer_hours:string = '0'
}
*/
export interface CalendarDay {
    id: number
    title: string[]
    text1: string[]
    text2: string[]
    text3: string[]
    text4: string[]
    text5: string[]
    button: string[]
    bold_text: boolean[]
    image: string
    image2: string
    idx: number
    calendar_id: string
    link: string[]
    tc: string[],
}

export interface Calendar {
    image?: string,
    background?: string,
    title?: string[],
    terms_and_conditions?:string[],
    color_header?:string,
    color_body?: string, 
    color_text_header?:string, 
    color_text_body?:string, 
    color_button_action?:string, 
    color_button_terms?:string, 
    width?: number,
    height?: number, 
    height_header?: number,
    image_width?: number,
    image_height?: number, 
    padding?: number, 
    date_start?: Date, 
    date_end?: Date,
    color_button_text?:string, 
    tc_background?:string, 
    custom?: any,
    items_in_row?: number,
    terms_title?: string[],
    action_title?: string[],
    day_width?: number,
    day_height?: number,
    right_margin?: number,
    bottom_margin?: number,
    id?: number,    
}

export class CalendarState extends Logic.Type {
    public settings: boolean = false
    public calendar_id: string = '0'
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 8
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id:string
    public link: string = ''
    public tc: string[] = ['','','','','','','','']
    public image:string = ''
    public image2:string = ''
    public index: number = 1
    public button: string[] = ['','','','','','','','']
    public title: string[] = ['','','','','','','','']
    public text: string[][] = [['','','','','',''],['','','','','','','',''],['','','','','','','',''],['','','','','','','',''],['','','','','','','','']]
    public bold_text: boolean[] = [true, false, true, false, true]
    public bold_text_saved: boolean[]    
    public title_saved:string[]
    public index_saved:number
    public button_saved:string[]
    public image_saved:string
    public image2_saved:string
    public text_saved: string[][]
    public link_saved:string
    public tc_saved:string[]
    
    public textSelected: string = '0'
    public Banner: Select[]
    public banner: string  = '0'
    public banner_saved: string
    // cloneFile: File
    public filter_future: string = '1'
    
    // calendarSelectLanguageFilter: boolean = false
    // calendarLanguage: number = Assets.LANGUAGE_SCANDIBET_EUROPE
    // calendarLanguageFilter: number = Assets.LANGUAGE_SCANDIBET_EUROPE
    public calendarData: Calendar
    public calendarLanguages: Select[]
    public calendarDay: CalendarDay[]
    public terms: boolean = false
    public from: string[] = ['','','']
    public to: string[] = ['','','']
    // from_date: moment.Moment = null
    // to_date: moment.Moment = null
    // sort:number[] = []
    // Sort:string[] = []    
}
