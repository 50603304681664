import * as React from 'react'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-toggle-switch'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'

interface State {
    years: number
    months?: number
    riskLevel: RiskLevel
    accountSuspension: boolean
}

export default class NoPlayerActivityModal extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            years: 2,
            riskLevel: RiskLevel.Low,
            accountSuspension: false,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amPlayerActivity, {
            years: this.state.years,
            months: this.state.months,
            riskLevel: this.state.riskLevel,
            accountSuspension: this.state.accountSuspension,
        })        

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddNoPlayerActivity: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddNoPlayerActivity: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddNoPlayerActivityRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.YearsInactive}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({years: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.years}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.MonthsInactive}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({months: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.months}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({accountSuspension: !this.state.accountSuspension})} on = {this.state.accountSuspension}/>
                            <div className = "fl mt2">{Assets.AccountSuspension}</div>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddNoPlayerActivity: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
