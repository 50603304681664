import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {isUserAdmin} from '../../../Logic/Utils'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {faImage} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMoneyBill, faThumbsUp, faCoins} from '@fortawesome/free-solid-svg-icons'

export function getRewardShopItems(): JSX.Element {
    const gsi:JSX.Element[] = []
    // console.log (Main.Instance.FrontendStoreState.page)
    let rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    rewardShopStreamAll = rewardShopStreamAll.sort(compare)
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    // let count: number = 0
    let items: number = 0
    for (const item of rewardShopStreamAll) {
        if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && +item['index'] !== 0) {
            if (
                items >= (Main.Instance.FrontendStoreState.page - 1) * Main.Instance.FrontendStoreState.custom['slots'] && 
                items < (Main.Instance.FrontendStoreState.page) * Main.Instance.FrontendStoreState.custom['slots']
            ) { gsi.push (getRewardShopItem(item)) }
            items++
        }
    }
    for (const item of rewardShopStreamAll) {
        if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && +item['index'] === 0) {
            if (
                items >= (Main.Instance.FrontendStoreState.page - 1) * Main.Instance.FrontendStoreState.custom['slots'] && 
                items < (Main.Instance.FrontendStoreState.page) * Main.Instance.FrontendStoreState.custom['slots']
            ) { gsi.push (getRewardShopItem(item)) }
            items++
        }
    }
    if (Main.Instance.FrontendStoreState.pages === -1) {
        // alert (gsi.length + ' ' + Main.Instance.FrontendStoreState.custom['slots'])
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            page: 1, items, pages: Math.ceil (items / Main.Instance.FrontendStoreState.custom['slots']),
        })
    }

    return (
        <table className = "table table-striped positioning" style = {{width: '496px', marginTop: '10px', border: '1px solid #bbb'}}>
            <thead>
                <tr style = {{background: '#000', color: '#fff', fontWeight: 900}}>
                    {/*<th style = {{textAlign:'center'}}>ID</th>*/}
                    <th title = {Assets.Position} style = {{textAlign:'center', width: '35px'}}><i className = {Assets_.faListOlClass}/></th>
                    <th style = {{textAlign:'left', borderLeft: '1px dashed #fff', width: '160px'}}>{Assets.ShopItem}</th>
                    <th title = {Assets.Image} style = {{textAlign:'center', width: '50px', borderRight: '1px dashed #fff'}}>
                        <FontAwesomeIcon icon = {faImage}/>
                    </th>
                    <th title = {Assets.Price} style = {{textAlign:'right', width: '60px'}}>
                        <FontAwesomeIcon icon = {faCoins}/>
                    </th>
                    <th title = {Assets.DiscountedPrice} style = {{textAlign: 'right', width: '60px'}}>
                        <FontAwesomeIcon icon = {faMoneyBill}/>
                    </th>
                    <th title = {Assets.Discount} style = {{textAlign: 'center', borderRight: '1px dashed #fff', width: '35px'}}>
                        <i className = {Assets_.faPercentageClass}/>
                    </th>
                    <th title = {Assets.Activity} style = {{textAlign: 'center', padding: '8px 0px', width: '32px'}}>
                        <FontAwesomeIcon icon = {faThumbsUp}/>
                    </th>
                    <th title = {Assets.CanBeBoughtMultipleTimes} style = {{textAlign:'center', padding: '8px 0px', width: '32px'}}>
                        <i className = {Assets_.faSelectClass}/>
                    </th>
                    <th title = {Assets.OnlyNewlyRegistered} style = {{textAlign:'center', padding: '8px 0px', width: '32px'}}>
                        <i className = {Assets_.faRegisteredClass}/>
                    </th>
                </tr>
            </thead>
            <tbody style = {{fontWeight: 700}}>
                {gsi}
            </tbody>
        </table>
    )
}

function compare(first,second) {
    if (first.index == second.index) { return 0 } 
    if (first.index < second.index) { return -1 } else { return 1 }   
}

function getRewardShopItem(item): JSX.Element {
    return (
        <tr key = {item['id']}>
            {/*<td style = {{textAlign:'center', lineHeight: '40px'}}>{item['id']}</td>*/}
            <td style = {{textAlign:'center', fontSize: '27px', padding: '0px 0px', color: item['active'].toString() === 'true' ? '#333' : '#999'}}>
                {+item['index'] === 0 ? '-' : item['index']}
            </td>
            <td style = {{textAlign:'left', lineHeight: '16px', padding: '8px 8px 0px', color: item['active'].toString() === 'true' ? '#333' : '#999'}}>
                {item['name'][Main.Instance.DatabaseDataState.language]}
            </td>
            <td style = {{textAlign:'center', width: '50px', padding: '0px'}} className = "Hoverme">
                <img style = {{cursor: item['active'].toString() === 'true' ? 'pointer' : ''}} className = {item['active'].toString() === 'true' ? '':'grayscale'} height = {50} src = {item['image']}/>
                {item['active'].toString() === 'true' ?
                <div className = "doh">
                    <img style = {{background: `url(${Assets_.checkered})`}} src = {item['image']}/>
                </div> : void 0}
             </td>
            <td style = {{textAlign:'right', lineHeight: '20px', color: item['active'].toString() === 'true' ? '#333' : '#999', padding: '8px 8px 0px'}}>
                {item['price']}
            </td>
            <td style = {{textAlign:'right', lineHeight: '20px', color: item['active'].toString() === 'true' ? '#333' : '#999', padding: '8px 8px 0px'}}>
                {Math.round(+(item['price']) * ((100 - parseInt(item['discount']))/100))}
            </td>
            <td style = {{textAlign:'center', lineHeight: '20px', color: item['active'].toString() === 'true' ? '#333' : '#999', padding: '8px 8px 0px'}}>
                {item['discount']}
            </td>
            <td style = {{textAlign:'center', lineHeight: '20px', padding: '2px 0px'}}>
                <button
                    style = {{border: '0px', background: 'transparent', padding: '0px', margin: '0px'}}
                    onClick = {() => !isUserAdmin() ? void 0 : handleToggle (item['id'], item['active'].toString())}
                >
                    <img src = {item['active'].toString() === 'true' ? Assets_.active : Assets_.inactive}/>
                </button>
            </td>
            <td style = {{textAlign:'center', lineHeight: '20px', padding: '2px 0px'}}>
                <button
                    style = {{border: '0px', background: 'transparent', padding: '0px', margin: '0px'}}
                    onClick = {() => !isUserAdmin() ? void 0 : handleToggleBuyable (item['id'], item['buyable'], item['active'])}
                >
                    <img className = {item['active'].toString() === 'true' ? '' : 'grayscale'} src = {item['buyable'].toString() === 'true'? Assets_.active : Assets_.inactive}/>
                </button>
            </td>
            <td style = {{textAlign:'center', lineHeight: '20px', padding: '2px 0px'}}>
                <button
                    style = {{border: '0px', background: 'transparent', padding: '0px', margin: '0px'}}
                    onClick = {() => !isUserAdmin() ? void 0 : handleToggleNewly (item['id'], item['only_newly_registered'], item['active'])}
                >
                    <img className = {item['active'].toString() === 'true' ? '' : 'grayscale'} src = {item['only_newly_registered'].toString() === 'true'? Assets_.active : Assets_.inactive}/>
                </button>
            </td>
        </tr>
    )
}

function handleToggle2(id: string, value: string) {
    const newValue = value === 'true' ? 'false' : 'true'
    const rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    for (const item of rewardShopStreamAll) { if (+item['id'] === +id) {item['active'] = newValue; break} }
    const rewardShopStream = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
    for (const item of rewardShopStream) { if (+item['id'] === +id) {item['active'] = newValue; break} }
    
    Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll, rewardShopStream})
}

function handleToggle(id: string, value: string) {
    DbRoutes.Toggle2('.reward_shop SET active = ', id, value, handleToggle2)
}

function handleToggleBuyable2(id: string, value: string) {
    const newValue = value.toString() === 'true' ? 'false' : 'true'
    const rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    for (const item of rewardShopStreamAll) { if (+item['id'] === +id) {item['buyable'] = newValue; break} }
    const rewardShopStream = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
    for (const item of rewardShopStream) { if (+item['id'] === +id) {item['buyable'] = newValue; break} }
    
    Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll, rewardShopStream})
}

function handleToggleBuyable(id: string, value: boolean, active: boolean) {
    if (active) { DbRoutes.Toggle2('.reward_shop SET buyable = ', id, value.toString(), handleToggleBuyable2) }
}

function handleToggleNewly2(id: string, value: string) {
    const newValue = value.toString() === 'true' ? 'false' : 'true'
    const rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    for (const item of rewardShopStreamAll) { if (+item['id'] === +id) {item['only_newly_registered'] = newValue; break} }
    const rewardShopStream = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
    for (const item of rewardShopStream) { if (+item['id'] === +id) {item['only_newly_registered'] = newValue; break} }
    
    Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll, rewardShopStream})
}

function handleToggleNewly(id: string, value: boolean, active: boolean) {
    if (active) { DbRoutes.Toggle2('.reward_shop SET only_newly_registered = ', id, value.toString(), handleToggleNewly2) }
}