export enum LeaderboardTypes {
    WinPercentage = 1,
    TotalPoints,
    HighestWinRatio,
    HighestWin,
    HighestWager,
    RoundsPlayed,
    RoundsWon,
    TotalWon,
    TotalWagered,
}

export enum TournamentTypes {
    Daily = 1,
    Special
}

export enum PointsNotifications {
    Win = 1,
    ConsecutiveWin,
    Loss,
    ConsecutiveLoss,
    BigWin,
    MegaWin
}