import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {RadioButton2, FormInput, FormSelect, FormInputNumber} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import {gotoTop, sortNumber} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import {EditButton, DeleteButton, FlagByCountryName, SelectButton, TB2, getDayInWeek, getWeekInMonth, TB} from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getGZGCount() {
    if (Main.Instance.GZG.countCalled === false) {
        Logic.Type.New(Main.Instance.GZG, {countCalled: true})

        DbRoutes.getGZG()
        DbRoutes.GetFlags()
    }
}
import {FixedOdds} from '../../../Logic/Database/DatabaseData'

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.GZG, {pSize:parseInt(text)})
        FirstPage()
    }
}
/*
function RecordsPerPageChangedTickets(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.GZG, {PSize:parseInt(text)})
        FirstPageTickets()
    }
}
*/
function Raffle(raffle: boolean) {
    Logic.Type.New (Main.Instance.GZG, {raffle, ID: 0})
    DbRoutes.getGZG()
    getGZGTickets()
}

function JackpotRaffle(jackpotRaffle: boolean) {
    Logic.Type.New (Main.Instance.GZG, {jackpotRaffle, ID: 0})
    DbRoutes.getGZG()
    getGZGTickets()
}

function Gametype(gametype: boolean) {
    Logic.Type.New (Main.Instance.GZG, {gametype, ID: 0})
    DbRoutes.getGZG()
    getGZGTickets()
}
/*
function Tickets (modeTickets: number) {
    Logic.Type.New (Main.Instance.GZG, {modeTickets})
    getGZGTickets()
}
*/
function getGZG() {
    Logic.Type.New(Main.Instance.GZG, {data : undefined})
}

function getGZGTickets() {
    DbRoutes.getGZGTickets()
}
/*
function handleSelect(rowData: string[]) {
    if (Main.Instance.GZG.gametype) {
        for (let i:number = 0; i < Main.Instance.GZG.data.length; i++) {
            if (+Main.Instance.GZG.data[i].id === +rowData[0]) {
                Logic.Type.New (Main.Instance.GZG, {ID: +Main.Instance.GZG.data[i].id, chosenTicket: i})
                break
            }
        }
        getGZGTickets()
    } else {
        for (let i:number = 0; i < Main.Instance.GZG.data.length; i++) {
            if (+Main.Instance.GZG.data[i].id === +rowData[0]) {
                Logic.Type.New (Main.Instance.GZG, {ID: +Main.Instance.GZG.data[i].id, chosenTicket: i})
                break
            }
        }
        getGZGTickets()
    }
}
*/

function handleSelect(row: any) {
    if (Main.Instance.GZG.gametype) {
        for (let i:number = 0; i < Main.Instance.GZG.data.length; i++) {
            if (+Main.Instance.GZG.data[i].id === row.original.id) {
                Logic.Type.New (Main.Instance.GZG, {ID: +Main.Instance.GZG.data[i].id, chosenTicket: i})
                break
            }
        }
        getGZGTickets()
    } else {
        for (let i:number = 0; i < Main.Instance.GZG.data.length; i++) {
            if (+Main.Instance.GZG.data[i].id === row.original.id) {
                Logic.Type.New (Main.Instance.GZG, {ID: +Main.Instance.GZG.data[i].id, chosenTicket: i})
                break
            }
        }
        getGZGTickets()
    }
}

/*
function FirstPageTickets() {
    Logic.Type.New(Main.Instance.GZG, {Offset:(0).toString()})

    getGZGTickets()
    Logic.Type.New(Main.Instance.GZG, {PNum: 0})
}

function PrevPageTickets() {
    if (Main.Instance.GZG.PNum > 0) {

        Logic.Type.New(Main.Instance.GZG, {
            Offset:((Main.Instance.GZG.PNum - 1) * 
            Main.Instance.GZG.PSize).toString()})
        getGZGTickets()

        Logic.Type.New(Main.Instance.GZG,{PNum: Main.Instance.GZG.PNum - 1})
    } else {
        LastPageTickets()
    }
}

function NextPageTickets() {
    if ((Main.Instance.GZG.PNum + 1) * Main.Instance.GZG.PSize < 
    Main.Instance.GZG.Count) {

        Logic.Type.New(Main.Instance.GZG, {
            offset: ((Main.Instance.GZG.PNum + 1) * 
            Main.Instance.GZG.PSize).toString()})
        getGZGTickets()
        
        Logic.Type.New(Main.Instance.GZG,{PNum: Main.Instance.GZG.PNum + 1})
    } else {
        FirstPageTickets()
    }
}

function LastPageTickets() {
    if (Main.Instance.GZG.Count <= Main.Instance.GZG.PSize) {
        FirstPageTickets()
    } else {
        let npNum: number = 0;

        if (Main.Instance.GZG.Count === 0) {

            Logic.Type.New(Main.Instance.GZG, {Offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.GZG.Count - 1) / 
                    Main.Instance.GZG.PSize)
            Logic.Type.New(Main.Instance.GZG, {
                Offset:(npNum * Main.Instance.GZG.PSize).toString()})
        }

        getGZGTickets()
        Logic.Type.New(Main.Instance.GZG,{PNum: npNum})
    }
}
*/
function FirstPage() {
    Logic.Type.New(Main.Instance.GZG, {offset:(0).toString()})

    getGZG()
    Logic.Type.New(Main.Instance.GZG, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.GZG.pNum > 0) {

        Logic.Type.New(Main.Instance.GZG, {
            offset:((Main.Instance.GZG.pNum - 1) * 
            Main.Instance.GZG.pSize).toString()})
        getGZG()

        Logic.Type.New(Main.Instance.GZG,{pNum: Main.Instance.GZG.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.GZG.pNum + 1) * Main.Instance.GZG.pSize < 
    Main.Instance.GZG.count) {

        Logic.Type.New(Main.Instance.GZG, {
            offset: ((Main.Instance.GZG.pNum + 1) * 
            Main.Instance.GZG.pSize).toString()})
        getGZG()
        
        Logic.Type.New(Main.Instance.GZG,{pNum: Main.Instance.GZG.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.GZG.count <= Main.Instance.GZG.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.GZG.count === 0) {

            Logic.Type.New(Main.Instance.GZG, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.GZG.count - 1) / 
                    Main.Instance.GZG.pSize)
            Logic.Type.New(Main.Instance.GZG, {
                offset:(npNum * Main.Instance.GZG.pSize).toString()})
        }

        getGZG()
        Logic.Type.New(Main.Instance.GZG,{pNum: npNum})
    }
}

function handleDelete(row: any) {
    if (row) {
        const game: string = Main.Instance.GZG.raffle ? Main.Instance.GZG.jackpotRaffle ? Assets.RaffleJackpot : Assets.Raffle : Assets.Lottery
        if (confirm(Assets.sureToDelete + ' ' + game + ' ' + Assets.GameType + ' ' + 
            Assets.withName + ' "' + row.original.name + '"?')) {

            if (Main.Instance.LottoState.pNum === 
            (Math.trunc((Main.Instance.LottoState.count - 2) / 
            Main.Instance.LottoState.pSize) + 1)) {
                DbRoutes.removeGameType(row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeGameType (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

/*
function handleDelete(rowData:string[]) {
    if (rowData) {
        let game: string = Main.Instance.GZG.raffle ? Main.Instance.GZG.jackpotRaffle ? 'Raffle Jackpot' : 'Raffle' : 'Lottery'
        if (confirm(Assets.sureToDelete + ' ' + game + ' Game Type ' + 
            Assets.withName + ' "' + rowData[1] + '"?')) {

            if (Main.Instance.LottoState.pNum === 
            (Math.trunc((Main.Instance.LottoState.count - 2) / 
            Main.Instance.LottoState.pSize) + 1)) {
                DbRoutes.removeGameType(rowData[0].toString(), DeleteLastPage)
            } else {
                DbRoutes.removeGameType (rowData[0].toString(), DeleteNotLastPage)
            }
        }
    }
}
*/
/*
function handleEdit(rowData:string[]) {
    if (Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle === true) {
        if (rowData) {        
            // console.log (rowData)
            let Currency: string = rowData[3]
            if (Currency === 'EUR') Currency = '€'
            let isplayable: string =  +rowData[4] === 1 ? '1' : '2'

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === rowData[5].toString()) {country = item['id']; break}
            }

            let stake: string = ''
            let Stake: string = rowData[6].toString()
            for (const item of Stake) {
                if (item === ',') continue
                if (item === ' ') break
                stake += item
            }

            let win: string = ''
            let Win: string = rowData[7].toString()
            for (const item of Win) {
                if (item === ',') continue
                if (item === ' ') break
                win += item
            }

            let payout: string = ''
            let Payout: string = rowData[10].toString()
            for (const item of Payout) {
                if (item === ',') continue
                if (item === ' ' || item === '%') break
                payout += item
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: +rowData[0],
                name: rowData[1],
                name_saved: rowData[1],
                Currency,
                currency: rowData[3],
                currency_saved: rowData[3],
                cutoffhours: +rowData[4].toString().split(' ')[0],
                cutoffhours_saved: +rowData[4].toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                stake: +stake,
                stake_saved: +stake,
                win: +win,
                win_saved: +win,
                winners: parseInt(rowData[8]),
                winners_saved: parseInt(rowData[8]),
                identification: rowData[9],
                identification_saved: rowData[9],
                payout: payout,
                payout_saved: payout,
            })

        }
    } else if (Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle === false) {
        if (rowData) {        
            let isplayable: string =  +rowData[5] === 1 ? '1' : '2'
            let Currency: string = rowData[3]
            if (Currency === 'EUR') Currency = '€'

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === rowData[6].toString()) {country = item['id']; break}
            }

            let stake: string = ''
            let Stake: string = rowData[7].toString()
            for (const item of Stake) {
                if (item === ',') continue
                if (item === ' ') break
                stake += item
            }

            let win: string = ''
            let Win: string = rowData[8].toString()
            for (const item of Win) {
                if (item === ',') continue
                if (item === ' ') break
                win += item
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: +rowData[0],
                name: rowData[1],
                name_saved: rowData[1],
                Currency,
                currency: rowData[3],
                currency_saved: rowData[3],
                cutoffhours: +rowData[4].toString().split(' ')[0],
                cutoffhours_saved: +rowData[4].toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                stake: +stake,
                stake_saved: +stake,
                win: +win,
                win_saved: +win,
                winners: parseInt(rowData[9]),
                winners_saved: parseInt(rowData[9]),
                identification: rowData[10],
                identification_saved: rowData[10],
                day_in_week: rowData[11].toString().split(',').map(Number),
                day_in_week_saved: rowData[11].toString().split(',').map(Number),
                week_in_month: rowData[12].toString().split(',').map(Number),
                week_in_month_saved: rowData[12].toString().split(',').map(Number),
                fire: rowData[13],
                fire_saved: rowData[13],
            })
        }
    } else {
        if (rowData) {
            let fixed_odds: FixedOdds[]
            let fixed_odds_n: number[] = []
            let fixed_odds_n_letter: number[] = []
            let fixed_odds_stake: number[] = []
            let fixed_odds_value: number[] = []
            if (rowData[20]) {
                // console.log (rowData[20])
                let fixed_odds_object: any = rowData[20] as any
                if (fixed_odds_object.fixed_odds) {
                    // let fixed_odds_object: any = JSON.parse(rowData[20].toString())
                    fixed_odds = Object.assign ([], fixed_odds_object.fixed_odds)
                    for (let i: number = 0; i < fixed_odds.length; i++) {
                        if (fixed_odds[i].n) fixed_odds_n.push (fixed_odds[i].n)
                        if (fixed_odds[i].l) fixed_odds_n_letter.push (fixed_odds[i].l)
                        if (fixed_odds[i].stake) fixed_odds_stake.push (fixed_odds[i].stake)
                        if (fixed_odds[i].fixed_odds) fixed_odds_value.push (fixed_odds[i].fixed_odds)
                        fixed_odds[i].edited = false
                    }
                }
            }

            let isplayable: string =  +rowData[5] === 1 ? '1' : '2'
            let numbers_repeatable: string =  +rowData[19] === 1 ? '1' : '2'
            let Currency: string = rowData[3]
            if (Currency === 'EUR') Currency = '€'

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === rowData[6].toString()) {country = item['id']; break}
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: +rowData[0],
                name: rowData[1],
                name_saved: rowData[1],
                Currency,
                currency: rowData[3],
                currency_saved: rowData[3],
                cutoffhours: +rowData[4].toString().split(' ')[0],
                cutoffhours_saved: +rowData[4].toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                numbers: +rowData[7],
                numbers_saved: +rowData[7],
                numbermin: +rowData[8],
                numbermin_saved: +rowData[8],
                numbermax: +rowData[9],
                numbermax_saved: +rowData[9],
                day_in_week: rowData[10].toString().split(',').map(Number),
                day_in_week_saved: rowData[10].toString().split(',').map(Number),
                week_in_month: rowData[11].toString().split(',').map(Number),
                week_in_month_saved: rowData[11].toString().split(',').map(Number),
                fire: rowData[12],
                fire_saved: rowData[12],
                serialnumbers: +rowData[13],
                serialnumbers_saved: +rowData[13],
                serialnumbermin: +rowData[14],
                serialnumbermin_saved: +rowData[14],
                serialnumbermax: +rowData[15],
                serialnumbermax_saved: +rowData[15],
                letters: +rowData[16],
                letters_saved: +rowData[16],
                lettermin: rowData[17].toString().trim(),
                lettermin_saved: rowData[17].toString().trim(),
                lettermax: rowData[18].toString().trim(),
                lettermax_saved: rowData[18].toString().trim(),
                numbers_repeatable,
                numbers_repeatable_saved: numbers_repeatable,
                fixed_odds,
                fixed_odds_n,
                fixed_odds_n_letter,
                fixed_odds_stake,
                fixed_odds_value,
                fixed_odds_saved: fixed_odds,
                fixed_odds_n_saved: fixed_odds_n,
                fixed_odds_n_letter_saved: fixed_odds_n_letter,
                fixed_odds_stake_saved: fixed_odds_stake,
                fixed_odds_value_saved: fixed_odds_value,
            })
        }
    }
}
*/

function handleEdit(row: any) { 
    gotoTop()
    if (Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle === true) {
        if (row) {        
            // console.log (rowData)
            let Currency: string = row.original.currency
            if (Currency === 'EUR') { Currency = '€' }
            const isplayable: string =  +row.original.isplayable === 1 ? '1' : '2'

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === row.original.flag_lottery_regulation) {country = item['id']; break}
            }

            let stake: string = ''
            const Stake: string = row.original.stake.toString()
            for (const item of Stake) {
                if (item === ',') { continue }
                if (item === ' ') { break }
                stake += item
            }

            let win: string = ''
            const Win: string = row.original.win.toString()
            for (const item of Win) {
                if (item === ',') { continue }
                if (item === ' ') { break }
                win += item
            }

            let payout: string = ''
            const Payout: string = row.original.payout.toString()
            for (const item of Payout) {
                if (item === ',') { continue }
                if (item === ' ' || item === '%') { break }
                payout += item
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: row.original.id,
                name: row.original.name,
                name_saved: row.original.name,
                Currency,
                currency: row.original.currency,
                currency_saved: row.original.currency,
                cutoffhours: row.original.cutoffhours.toString().split(' ')[0],
                cutoffhours_saved: row.original.cutoffhours.toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                stake: +stake,
                stake_saved: +stake,
                win: +win,
                win_saved: +win,
                winners: row.original.winners,
                winners_saved: row.original.winners,
                identification: row.original.identification,
                identification_saved: row.original.identification,
                payout,
                payout_saved: payout,
            })

        }
    } else if (Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle === false) {
        if (row) {        
            const isplayable: string =  row.original.isplayable === 1 ? '1' : '2'
            let Currency: string = row.original.currency
            if (Currency === 'EUR') { Currency = '€' }

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === row.original.country.toString()) {country = item['id']; break}
            }

            let stake: string = ''
            const Stake: string = row.original.stake.toString()
            for (const item of Stake) {
                if (item === ',') { continue }
                if (item === ' ') { break }
                stake += item
            }

            let win: string = ''
            const Win: string = row.original.win.toString()
            for (const item of Win) {
                if (item === ',') { continue }
                if (item === ' ') { break }
                win += item
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: row.original.id,
                name: row.original.name,
                name_saved: row.original.name,
                Currency,
                currency: row.original.currency,
                currency_saved: row.original.currency,
                cutoffhours: row.original.cutoffhours.toString().split(' ')[0],
                cutoffhours_saved: row.original.cutoffhours.toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                stake: +stake,
                stake_saved: +stake,
                win: +win,
                win_saved: +win,
                winners: row.original.winners,
                winners_saved: row.original.winners,
                identification: row.original.identification,
                identification_saved: row.original.identification,
                day_in_week: row.original.day_in_week.toString().split(',').map(Number),
                day_in_week_saved: row.original.day_in_week.toString().split(',').map(Number),
                week_in_month: row.original.week_in_month.toString().split(',').map(Number),
                week_in_month_saved: row.original.week_in_month.toString().split(',').map(Number),
                fire: row.original.fire,
                fire_saved: row.original.fire,
            })
        }
    } else {
        if (row) {
            let fixed_odds: FixedOdds[]
            const fixed_odds_n: number[] = []
            const fixed_odds_n_letter: number[] = []
            const fixed_odds_stake: number[] = []
            const fixed_odds_value: number[] = []
            if (row.original.fixed_odds) {
                // console.log (rowData[20])
                const fixed_odds_object: any = row.original.fixed_odds as any
                if (fixed_odds_object.fixed_odds) {
                    // let fixed_odds_object: any = JSON.parse(rowData[20].toString())
                    fixed_odds = Object.assign ([], fixed_odds_object.fixed_odds)
                    for (let i: number = 0; i < fixed_odds.length; i++) {
                        if (fixed_odds[i].n) { fixed_odds_n.push (fixed_odds[i].n) }
                        if (fixed_odds[i].l) { fixed_odds_n_letter.push (fixed_odds[i].l) }
                        if (fixed_odds[i].stake) { fixed_odds_stake.push (fixed_odds[i].stake) }
                        if (fixed_odds[i].fixed_odds) { fixed_odds_value.push (fixed_odds[i].fixed_odds) }
                        fixed_odds[i].edited = false
                    }
                }
            }

            const isplayable: string = row.original.isplayable === 1 ? '1' : '2'
            const numbers_repeatable: string =  row.original.numbers_repeatable === 1 ? '1' : '2'
            let Currency: string = row.original.currency
            if (Currency === 'EUR') { Currency = '€' }

            let country: string = '-1'
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item['name'] === row.original.country.toString()) {country = item['id']; break}
            }

            Logic.Type.New(Main.Instance.GZG, {
                mode: true,
                id: row.original.id,
                name: row.original.name,
                name_saved: row.original.name,
                Currency,
                currency: row.original.currency,
                currency_saved: row.original.currency,
                cutoffhours: row.original.cutoffhours.toString().split(' ')[0],
                cutoffhours_saved: row.original.cutoffhours.toString().split(' ')[0],
                isplayable,
                isplayable_saved: isplayable,
                country,
                country_saved: country,
                numbers: row.original.numbers,
                numbers_saved: row.original.numbers,
                numbermin: row.original.numbermin,
                numbermin_saved: row.original.numbermin,
                numbermax: row.original.numbermax,
                numbermax_saved: row.original.numbermax,
                day_in_week: row.original.day_in_week.toString().split(',').map(Number),
                day_in_week_saved: row.original.day_in_week.toString().split(',').map(Number),
                week_in_month: row.original.week_in_month.toString().split(',').map(Number),
                week_in_month_saved: row.original.week_in_month.toString().split(',').map(Number),
                fire: row.original.fire,
                fire_saved: row.original.fire,
                serialnumbers: row.original.serialnumbers,
                serialnumbers_saved: row.original.serialnumbers,
                serialnumbermin: row.original.serialnumbermin,
                serialnumbermin_saved: row.original.serialnumbermin,
                serialnumbermax: row.original.serialnumbermax,
                serialnumbermax_saved: row.original.serialnumbermax,
                letters: row.original.letters,
                letters_saved: row.original.letters[16],
                lettermin: row.original.lettermin.toString().trim(),
                lettermin_saved: row.original.lettermin.toString().trim(),
                lettermax: row.original.lettermax.toString().trim(),
                lettermax_saved: row.original.lettermax.toString().trim(),
                numbers_repeatable,
                numbers_repeatable_saved: numbers_repeatable,
                fixed_odds,
                fixed_odds_n,
                fixed_odds_n_letter,
                fixed_odds_stake,
                fixed_odds_value,
                fixed_odds_saved: fixed_odds,
                fixed_odds_n_saved: fixed_odds_n,
                fixed_odds_n_letter_saved: fixed_odds_n_letter,
                fixed_odds_stake_saved: fixed_odds_stake,
                fixed_odds_value_saved: fixed_odds_value,
            })
        }
    }
}

function SetCountryID(text: string) {
    Logic.Type.New (Main.Instance.GZG, {countryID: +text})
}

function SetJackpotRaffleType(text: string) {
    Logic.Type.New (Main.Instance.GZG, {rgametype: [+text]})
}

function SetBought(text: string) {
    Logic.Type.New (Main.Instance.GZG, {bought: [+text]})
}

function getCountryId(id: number): number {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.FlagsStreamAll.length; i++) {
        if (+Main.Instance.DatabaseDataState.FlagsStreamAll[i]['id'] === id) { return i }
    }
    return 0
}

function PlayRaffle() {
    if (Main.Instance.GZG.phone.length === 0) { alert (Assets.PleaseInsertTheTelephoneNumber + '!') } else if (Main.Instance.GZG.countryID === 0) { alert (Assets.PleaseChooseYourCountry + '!') } else if (!Main.Instance.GZG.rgametype || Main.Instance.GZG.rgametype.length === 0 || Main.Instance.GZG.rgametype[0] === 0) { alert (Assets.PleaseChooseWhichRaffleYouWantToPlay + '!') } else { DbRoutes.InsertRaffleTicket() }
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.reward_shop SET active = ', rowData[0], rowData[10], getGZGCount)
    }
}
*/

function ToggleDW(which: number) {
    const day_in_week = Object.assign([], Main.Instance.GZG.day_in_week)
    let selected: number = -1
    for (let i: number = 0; i < day_in_week.length; i++) { if (which === day_in_week[i]) {selected = i; break} }
    if (selected === -1) {
        day_in_week.push(which)
        Logic.Type.New (Main.Instance.GZG, {day_in_week: day_in_week.sort(sortNumber)})
    } else {
        day_in_week.splice(selected, 1)
        Logic.Type.New (Main.Instance.GZG, {day_in_week})
    }
}

function ToggleWM(which: number) {
    const week_in_month = Object.assign([], Main.Instance.GZG.week_in_month)
    let selected: number = -1
    for (let i: number = 0; i < week_in_month.length; i++) { if (which === week_in_month[i]) {selected = i; break} }
    if (selected === -1) {
        week_in_month.push(which)
        Logic.Type.New (Main.Instance.GZG, {week_in_month: week_in_month.sort(sortNumber)})
    } else {
        week_in_month.splice(selected, 1)
        Logic.Type.New (Main.Instance.GZG, {week_in_month})
    }
}

function getDW(): JSX.Element[] {
    const dw: JSX.Element[] = []
    for (let i: number = 0; i < Assets.DaysInWeek.length; i++) {
        let selected: boolean = false
        for (const item of Main.Instance.GZG.day_in_week) { if (item === i) {selected = true; break} }
        let style = {}
        if (i === Assets.DaysInWeek.length - 1) { style = {marginRight: '0px'} }
        dw.push (
            <button 
                title = {Assets.ToggleDayInWeek}
                style = {style} 
                onClick = {() => ToggleDW(i)}
                key = {i.toString()}
                className = {selected ? 'btn btn-primary btn-sm btn-sm-thicker' : 'btn btn-default btn-sm btn-sm-thicker'}
            >
                {Assets.DaysInWeek[i]}
            </button>,
        )
    }
    return dw
}

function getWM(): JSX.Element[] {
    const wm: JSX.Element[] = []
    for (let i: number = 0; i < 5; i++) {
        let selected: boolean = false
        for (const item of Main.Instance.GZG.week_in_month) { if (item === i) {selected = true; break} }
        let style = {}
        if (i === 4) { style = {marginRight: '0px'} }        
        wm.push (
            <button
                title = {Assets.ToggleWeekInMonth}
                style = {style}
                onClick = {() => ToggleWM(i)}
                key = {i.toString()}
                className = {selected ? 'btn btn-info btn-sm btn-sm-thicker' : 'btn btn-default btn-sm btn-sm-thicker'}
            >
                {Assets.Week + ' '}{i + 1}
            </button>,
        )
    }
    return wm
}

function gotoInsertMode() {
    let week: boolean = true
    if (Main.Instance.GZG.day_in_week.length !== Main.Instance.GZG.day_in_week_saved.length) { week = false }
    if (Main.Instance.GZG.week_in_month.length !== Main.Instance.GZG.week_in_month_saved.length) { week = false }
    for (let i: number = 0; week && i < Main.Instance.GZG.day_in_week.length; i++) {
        if (Main.Instance.GZG.day_in_week[i] !== Main.Instance.GZG.day_in_week_saved[i]) { week = false }
    }

    for (let i: number = 0; week && i < Main.Instance.GZG.week_in_month.length; i++) {
        if (Main.Instance.GZG.week_in_month[i] !== Main.Instance.GZG.week_in_month_saved[i]) { week = false }
    }

    if (
        Main.Instance.GZG.raffle === false
        && Main.Instance.GZG.name === Main.Instance.GZG.name_saved
        && Main.Instance.GZG.currency === Main.Instance.GZG.currency_saved
        && Main.Instance.GZG.cutoffhours === Main.Instance.GZG.cutoffhours_saved
        && Main.Instance.GZG.isplayable === Main.Instance.GZG.isplayable_saved
        && Main.Instance.GZG.numbers_repeatable === Main.Instance.GZG.numbers_repeatable_saved
        && Main.Instance.GZG.country === Main.Instance.GZG.country_saved
        && Main.Instance.GZG.numbers === Main.Instance.GZG.numbers_saved
        && Main.Instance.GZG.numbermin === Main.Instance.GZG.numbermin_saved
        && Main.Instance.GZG.numbermax === Main.Instance.GZG.numbermax_saved
        && Main.Instance.GZG.fire === Main.Instance.GZG.fire_saved
        && Main.Instance.GZG.serialnumbers === Main.Instance.GZG.serialnumbers_saved
        && Main.Instance.GZG.serialnumbermin === Main.Instance.GZG.serialnumbermin_saved
        && Main.Instance.GZG.serialnumbermax === Main.Instance.GZG.serialnumbermax_saved
        && Main.Instance.GZG.letters === Main.Instance.GZG.letters_saved
        && Main.Instance.GZG.lettermin === Main.Instance.GZG.lettermin_saved
        && Main.Instance.GZG.lettermax === Main.Instance.GZG.lettermax_saved
        && week
        ||
        Main.Instance.GZG.raffle === true && Main.Instance.GZG.jackpotRaffle === false
        && Main.Instance.GZG.name === Main.Instance.GZG.name_saved
        && Main.Instance.GZG.currency === Main.Instance.GZG.currency_saved
        && Main.Instance.GZG.cutoffhours === Main.Instance.GZG.cutoffhours_saved
        && Main.Instance.GZG.isplayable === Main.Instance.GZG.isplayable_saved
        && Main.Instance.GZG.country === Main.Instance.GZG.country_saved
        && Main.Instance.GZG.fire === Main.Instance.GZG.fire_saved
        && Main.Instance.GZG.stake === Main.Instance.GZG.stake_saved
        && Main.Instance.GZG.win === Main.Instance.GZG.win_saved
        && Main.Instance.GZG.winners === Main.Instance.GZG.winners_saved
        && Main.Instance.GZG.identification === Main.Instance.GZG.identification_saved
        && week
        ||
        Main.Instance.GZG.raffle === true && Main.Instance.GZG.jackpotRaffle === true
        && Main.Instance.GZG.name === Main.Instance.GZG.name_saved
        && Main.Instance.GZG.currency === Main.Instance.GZG.currency_saved
        && Main.Instance.GZG.isplayable === Main.Instance.GZG.isplayable_saved
        && Main.Instance.GZG.country === Main.Instance.GZG.country_saved
        && Main.Instance.GZG.stake === Main.Instance.GZG.stake_saved
        && Main.Instance.GZG.win === Main.Instance.GZG.win_saved
        && Main.Instance.GZG.winners === Main.Instance.GZG.winners_saved
        && Main.Instance.GZG.identification === Main.Instance.GZG.identification_saved
        && Main.Instance.GZG.payout === Main.Instance.GZG.payout_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function  clearFields() {
    Logic.Type.New(Main.Instance.GZG, {
        mode: false,
        stake: 0,
        win: 0,
        winners: 0,
        payout: '',
        name: '',
        currency: '',
        cutoffhours: 1,
        isplayable: '1',
        numbers_repeatable: '1',
        country: '',
        numbers: 0,
        numbermin: 0,
        numbermax: 0,
        day_in_week: [],
        week_in_month: [],
        fire: '',
        serialnumbers: 0,
        serialnumbermin: 0,
        serialnumbermax: 0,
        letters: 0,
        lettermin: 'A',
        lettermax: 'A',
        fixed_odds: undefined,
        fixed_odds_n: undefined,
        fixed_odds_n_letter: undefined,
        fixed_odds_stake: undefined,
        fixed_odds_value: undefined,
    })
}

function handleInsertUpdate() {
    let country: string = ''
    if (Main.Instance.GZG.country !== undefined && Main.Instance.DatabaseDataState.FlagsStreamAll) {
    for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
    if (Item['id'] === Main.Instance.GZG.country) {country = Item['name']; break;}
    }
    }
    let item: any
    // let currency = Main.Instance.GZG.currency && Main.Instance.GZG.currency === 'EUR' ? '€' : Main.Instance.GZG.currency ? Main.Instance.GZG.currency : ''

    if (Main.Instance.GZG.raffle === true && Main.Instance.GZG.jackpotRaffle === true) {
        item = {
            id: !Main.Instance.GZG.id ? '' : Main.Instance.GZG.id,
            isplayable: !Main.Instance.GZG.isplayable ? 1 : +Main.Instance.GZG.isplayable,
            name: !Main.Instance.GZG.name ? '' : Main.Instance.GZG.name,
            currency: !Main.Instance.GZG.currency ? '' : Main.Instance.GZG.currency,
            country,
            stake: Main.Instance.GZG.stake === undefined ? 0 : Main.Instance.GZG.stake,
            win: Main.Instance.GZG.win === undefined ? 0 : Main.Instance.GZG.win,
            winners: Main.Instance.GZG.winners === undefined ? 0 : Main.Instance.GZG.winners,
            identification: Main.Instance.GZG.identification === undefined ? '' : Main.Instance.GZG.identification,
            percentage: Main.Instance.GZG.payout === undefined ? '' : Main.Instance.GZG.payout,
        }
    } else if (Main.Instance.GZG.raffle === true && Main.Instance.GZG.jackpotRaffle === false) {
        item = {
            id: !Main.Instance.GZG.id ? '' : Main.Instance.GZG.id,
            isplayable: !Main.Instance.GZG.isplayable ? 1 : +Main.Instance.GZG.isplayable,
            name: !Main.Instance.GZG.name ? '' : Main.Instance.GZG.name,
            currency: !Main.Instance.GZG.currency ? '' : Main.Instance.GZG.currency,
            cutoffhours: Main.Instance.GZG.cutoffhours === undefined ? 1 : Main.Instance.GZG.cutoffhours,
            country,
            day_in_week: Main.Instance.GZG.day_in_week === undefined ? [] : Main.Instance.GZG.day_in_week,
            week_in_month: Main.Instance.GZG.week_in_month === undefined ? [] : Main.Instance.GZG.week_in_month,
            fire: Main.Instance.GZG.fire === undefined ? '00:00:00' : Main.Instance.GZG.fire,
            stake: Main.Instance.GZG.stake === undefined ? 0 : Main.Instance.GZG.stake,
            win: Main.Instance.GZG.win === undefined ? 0 : Main.Instance.GZG.win,
            winners: Main.Instance.GZG.winners === undefined ? 0 : Main.Instance.GZG.winners,
            identification: Main.Instance.GZG.identification === undefined ? '' : Main.Instance.GZG.identification,
        }
    } else if (Main.Instance.GZG.raffle === false) {
        item = {
            id: !Main.Instance.GZG.id ? '' : Main.Instance.GZG.id,
            numbers_repeatable: !Main.Instance.GZG.numbers_repeatable ? '1' : Main.Instance.GZG.numbers_repeatable,
            isplayable: !Main.Instance.GZG.isplayable ? 1 : +Main.Instance.GZG.isplayable,
            name: !Main.Instance.GZG.name ? '' : Main.Instance.GZG.name,
            currency: !Main.Instance.GZG.currency ? '' : Main.Instance.GZG.currency,
            cutoffhours: Main.Instance.GZG.cutoffhours === undefined ? 1 : Main.Instance.GZG.cutoffhours,
            country,
            numbers: Main.Instance.GZG.numbers === undefined ? 0 : Main.Instance.GZG.numbers,
            numbermin: Main.Instance.GZG.numbermin === undefined ? 0 : Main.Instance.GZG.numbermin,
            numbermax: Main.Instance.GZG.numbermax === undefined ? 0 : Main.Instance.GZG.numbermax,
            day_in_week: Main.Instance.GZG.day_in_week === undefined ? [] : Main.Instance.GZG.day_in_week,
            week_in_month: Main.Instance.GZG.week_in_month === undefined ? [] : Main.Instance.GZG.week_in_month,
            fire: Main.Instance.GZG.fire === undefined ? '00:00:00' : Main.Instance.GZG.fire,
            serialnumbers: Main.Instance.GZG.serialnumbers === undefined ? 0 : Main.Instance.GZG.serialnumbers,
            serialnumbermin: Main.Instance.GZG.serialnumbermin === undefined ? 0 : Main.Instance.GZG.serialnumbermin,
            serialnumbermax: Main.Instance.GZG.serialnumbermax === undefined ? 0 : Main.Instance.GZG.serialnumbermax,
            letters: Main.Instance.GZG.letters === undefined ? 0 : Main.Instance.GZG.letters,
            lettermin: Main.Instance.GZG.lettermin === undefined ? '' : Main.Instance.GZG.lettermin,
            lettermax: Main.Instance.GZG.lettermax === undefined ? '' : Main.Instance.GZG.lettermax,
            extranumbers: 0,
            bonusnumbers: 0,
            bonusnumbermin: 0,
            bonusnumbermax: 0,
            refundnumbers: 0,
            refundnumbermin: 0,
            refundnumbermax: 0,
            fixed_odds: Main.Instance.GZG.fixed_odds === undefined ? '{"fixed_odds": []}' : '{"fixed_odds": ' + JSON.stringify(Main.Instance.GZG.fixed_odds) + '}',
        }

        if (parseInt(item.numbers_repeatable) === 1) { item.numbers_repeatable = 'true' }
        if (parseInt(item.numbers_repeatable) === 2) { item.numbers_repeatable = 'false' }
    }

    // if (parseInt(item.isplayable) === 1) item.isplayable = 'true'
    if (item.isplayable !== 1) { item.isplayable = 0 }

    if (Main.Instance.GZG.name.length === 0) {
        alert (Assets.compulsoryName)
    } else if (Main.Instance.GZG.mode === false) {
         DbRoutes.insertGameType (item, InsertLastPage, clearFields)
    } else {
         DbRoutes.updateGameType (item, Update)
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GZG, {count:Main.Instance.GZG.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GZG, {count:Main.Instance.GZG.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GZG, {count:Main.Instance.GZG.count --})
    getGZGCount()
}

function Update() {
    clearFields()
    getGZGCount()
}

function updatePayout(text: string) {
    let proceed = true
    for (let i: number = 0; i < text.length; i++) {
        if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && text.charAt(i) !== '5'
            && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== '.') {
            proceed = false
            break
        }
    }
    if (proceed) { Logic.Type.New(Main.Instance.GZG, {payout: text}) }    
}

function updateDrawTime(text: string) {
    let proceed = true
    for (let i: number = 0; i < text.length; i++) {
        if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && text.charAt(i) !== '5'
            && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== ':') {
            proceed = false
            break
        } else if (text.charAt(i) === ':') {
            if (i !== 2 && i !== 5) {
                proceed = false
                break                
            }
        } else {
            if (i === 2 || i === 5) {
                proceed = false
                break                
            }
        }
    }
    if (proceed) { Logic.Type.New(Main.Instance.GZG, {fire: text}) }    
}

function FotDelete(which: number) {
    const fixed_odds: FixedOdds[] = Object.assign ([], Main.Instance.GZG.fixed_odds)
    fixed_odds.splice(which, 1)
    Logic.Type.New (Main.Instance.GZG, {fixed_odds})
}

function FotEdit(which: number) {
    const fixed_odds: FixedOdds[] = Object.assign ([], Main.Instance.GZG.fixed_odds)
    fixed_odds[which].edited = !fixed_odds[which].edited
    Logic.Type.New (Main.Instance.GZG, {fixed_odds})
}

function FotAdd() {
    const fixed_odds: FixedOdds[] = Object.assign ([], Main.Instance.GZG.fixed_odds)

    const New: FixedOdds = {n: 0, fixed_odds: 0, edited: false}
    if (Main.Instance.GZG.fixed_odds_n_letter.length > 0) { New.l = 0 }
    if (Main.Instance.GZG.fixed_odds_stake.length > 0) { New.stake = 0 }

    fixed_odds.push(New)
    Logic.Type.New (Main.Instance.GZG, {fixed_odds})
}

function FotEdited(event, which: number, field: number) {
    const fixed_odds: FixedOdds[] = Object.assign ([], Main.Instance.GZG.fixed_odds)
    if (field === 1) { fixed_odds[which].n = event.target.value } else if (field === 2) { fixed_odds[which].l = event.target.value } else if (field === 3) { fixed_odds[which].stake = event.target.value } else if (field === 4) { fixed_odds[which].fixed_odds = event.target.value }

    Logic.Type.New (Main.Instance.GZG, {fixed_odds})
}

function showFixedOdds(): JSX.Element {

    const columns: JSX.Element[] = []
    const Style = {background: '#000', color: '#fff', padding: '2px 10px'}
    columns.push(<th className = "th-center" title = {Assets.Fixedoddstable} key = "0" style = {Style}>{Assets.FixedoddstableShort}</th>)
    if (Main.Instance.GZG.fixed_odds_n.length > 0) { columns.push(<th className = "th-center" key = "1" style = {Style}>{'# ' + Assets.numbers}</th>) }
    if (Main.Instance.GZG.fixed_odds_n_letter.length > 0) { columns.push(<th className = "th-center" key = "2" style = {Style}>{'# ' + Assets.letters}</th>) }
    if (Main.Instance.GZG.fixed_odds_stake.length > 0) { columns.push(<th className = "th-center" key = "3" style = {Style}>{Assets.Stake}</th>) }
    if (Main.Instance.GZG.fixed_odds_value.length > 0) { columns.push(<th className = "th-center" key = "4" style = {Style}>{Assets.Fixedodds}</th>) }
    if (Main.Instance.GZG.fixed_odds_stake.length > 0 && Main.Instance.GZG.fixed_odds_value.length > 0) { columns.push(<th className = "th-center" key = "5" style = {Style}>{Assets.Winnings}</th>) }
    columns.push(
        <td className = "th-center" key = "6" style = {Style}>
            <button onClick = {() => FotAdd()} style = {{background: 'transparent', border: '0px'}} className = "btn btn-xs btn-success mr0">
                <i className = {Assets_.faPlusSquareClass}/>
            </button>
        </td>,
    )

    const rows: JSX.Element[] = []
    const style = {padding: '2px 10px'}
    for (let i: number = 0; i < Main.Instance.GZG.fixed_odds.length; i++) {
        const item: FixedOdds = Main.Instance.GZG.fixed_odds[i]
        const column: JSX.Element[] = []
        column.push(
            <td className = "th-center" key = "0" style = {style}>
                <button onClick = {() => FotEdit(i)} className = {item.edited ? 'btn btn-xs btn-info mr0' : 'btn btn-xs btn-warning mr0'}>
                    <i className = {Assets_.faEditButtonClass}/>
                </button>
            </td>,
        )

        if (Main.Instance.GZG.fixed_odds_n.length > 0) { column.push(
            <td className = "th-center bold500" key = "1" style = {style}>
                {item.edited ? <input className = {Assets.Short} value = {Main.Instance.GZG.fixed_odds[i].n} type = "number" onChange = {(e) => FotEdited(e, i, 1)} /> : item.n}
            </td>,
        )
        }
        
        if (Main.Instance.GZG.fixed_odds_n_letter.length > 0) { column.push(
            <td className = "th-center bold500" key = "2" style = {style}>
                {item.edited ? <input className = {Assets.Short} value = {Main.Instance.GZG.fixed_odds[i].l} type = "number" onChange = {(e) => FotEdited(e, i, 2)} /> : item.l}
            </td>,
        )
        }
        
        if (Main.Instance.GZG.fixed_odds_stake.length > 0) { column.push(
            <td className = "th-center bold500" key = "3" style = {style}>
                {item.edited ? <input className = {Assets.Long} value = {Main.Instance.GZG.fixed_odds[i].stake} type = "number" onChange = {(e) => FotEdited(e, i, 3)} /> 
                : item.stake + ' ' + Main.Instance.GZG.Currency}
                {item.edited ? ' ' + Main.Instance.GZG.Currency: void 0}
            </td>,
        )
        }
        
        if (Main.Instance.GZG.fixed_odds_value.length > 0) { column.push(
            <td className = "th-center bold500" key = "4" style = {style}>
                {item.edited ? <input className = {Assets.Short} value = {Main.Instance.GZG.fixed_odds[i].fixed_odds} type = "number" onChange = {(e) => FotEdited(e, i, 4)} /> : item.fixed_odds}
            </td>,
        )
        }
        
        if (Main.Instance.GZG.fixed_odds_stake.length > 0 && Main.Instance.GZG.fixed_odds_value.length > 0) { column.push(
            <td className = "th-center bold500" key = "5" style = {style}>{item.fixed_odds * item.stake + ' ' + Main.Instance.GZG.Currency}</td>,
        )
        }

        column.push(
            <td className = "th-center" key = "6" style = {style}>
                <button onClick = {() => FotDelete(i)} className = "btn btn-xs btn-danger mr0">
                    <i className = {Assets_.faTrashButtonClass}/>
                </button>
            </td>,
        )
    
        rows.push (<tr key = {item.n + item.stake}>{column}</tr>)
    }

    return (
        <table style = {{border: '1px solid #bbb'}}>
            <thead>
                <tr>
                {columns}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

export function GZGLotteries() {
    return(
        <div>
        <div style = {{position: 'relative'}} className = "clearfix">
            <div style = {{width: '20%', textAlign: 'left', float: 'left'}}>
                <button onClick = {() => Raffle(false)} className = {Main.Instance.GZG.raffle === true ? 'btn btn-default btn-sm' : 'btn btn-primary btn-sm'}>{Assets.Lottery}</button>
                <button onClick = {() => Raffle(true)} className = {Main.Instance.GZG.raffle === false ? 'btn btn-default btn-sm mr0' : 'btn btn-primary btn-sm mr0'}>{Assets.Raffle}</button>
            </div>
            {Main.Instance.GZG.raffle === true ?
            <div style = {{width: '20%', textAlign: 'left', float: 'left'}}>
                <button onClick = {() => JackpotRaffle(false)} className = {Main.Instance.GZG.jackpotRaffle === true ? 'btn btn-default btn-sm' : 'btn btn-primary btn-sm'}>{Assets.Regular}</button>
                <button
                    onClick = {() => JackpotRaffle(true)}
                    className = {Main.Instance.GZG.jackpotRaffle === false ? 'btn btn-default btn-sm mr0' : 'btn btn-warning btn-sm mr0'}
                >
                    {Assets.Jackpot}
                </button>
            </div> : void 0}

            <div style = {{width: '20%', textAlign: 'right', float: 'right'}}>
                <button onClick = {() => Gametype(true)} className = {Main.Instance.GZG.gametype === false ? 'btn btn-default btn-sm' : 'btn btn-primary btn-sm'}>{Assets.Gametypes}</button>
                <button onClick = {() => Gametype(false)} className = {Main.Instance.GZG.gametype === true ? 'btn btn-default btn-sm mr0' : 'btn btn-primary btn-sm mr0'}>{Assets.Actualdraws}</button>
            </div>

            {/*Main.Instance.GZG.gametype === false && Main.Instance.GZG.raffle === true && Main.Instance.GZG.ID ?
            <div style = {{width: '50%', textAlign: 'left', float: 'left'}}>
                <button onClick = {() => Tickets(0)} className = {Main.Instance.GZG.modeTickets === 0 ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}>Do not show tickets</button>
                <button onClick = {() => Tickets(1)} className = {Main.Instance.GZG.modeTickets === 1 ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}>All tickets</button>
                <button onClick = {() => Tickets(2)} className = {Main.Instance.GZG.modeTickets === 2 ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}>Active tickets</button>
            </div> : void 0*/}
        </div>

        {Main.Instance.GZG.raffle && Main.Instance.GZG.data2 ?
        <div className = "control-panel" style = {{/*height: '20px', */position: 'relative'}}>
            <button
                style = {{position: 'absolute', right: '-8px', top: '0px', border: '0px', background: 'transparent'}}
                className = "btn btn-default btn-xs" 
                onClick = {() => Logic.Type.New (Main.Instance.GZG, {data2: undefined, ID: 0})}
            >
                <i className = {Assets_.faCancelClass}/>
            </button>        
{/*
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.GZG.PSize ? '' : 
                Main.Instance.GZG.PSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {text => RecordsPerPageChangedTickets(text)}
            />
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPageTickets()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPageTickets()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPageTickets()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPageTickets()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
*/}

            <span style = {{position: 'absolute', right: '0px', top: '25px'}} className = "label label-success table-label">
                {Assets.tickets + ': '}
                {Main.Instance.GZG.Count ? Main.Instance.GZG.Count : 0}
                {Main.Instance.GZG.jackpotRaffle && Main.Instance.GZG.gametype ? ', ' + Assets.minrequired + ': ' + Main.Instance.GZG.data[Main.Instance.GZG.chosenTicket]['Min. Required'] + ' (' + 
                (+Main.Instance.GZG.Count * 100 / parseInt(Main.Instance.GZG.data[Main.Instance.GZG.chosenTicket]['Min. Required'])).toFixed(2) + ' %)' : void 0}
            </span>


{/*
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.GZG.Count ? Main.Instance.GZG.PNum * 
                Main.Instance.GZG.PSize + 1 : 0}{' - '}
                {Main.Instance.GZG.Count ? 
                ((Main.Instance.GZG.PNum + 1) * 
                Main.Instance.GZG.PSize > Main.Instance.GZG.Count ? 
                Main.Instance.GZG.Count : 
                (Main.Instance.GZG.PNum + 1) * Main.Instance.GZG.PSize) : 0}
            </span>

        <span className = "label label-success table-label mt5">
            {Assets.page}
            {Main.Instance.GZG.Count ? 
            Main.Instance.GZG.PNum + 1 :0}{' / '}
            {Main.Instance.GZG.Count ? 
            Math.trunc((Main.Instance.GZG.Count - 1) /
            Main.Instance.GZG.PSize + 1) : 0}
        </span>
*/}
            </div> : void 0}

        {Main.Instance.GZG.raffle && Main.Instance.GZG.data2 ?
        <div className = "clearfix" style = {{background: '#f0f0f0', width: '555px', border: '1px solid #999', position: 'relative', padding: '5px', borderRadius: '10px'}}>
            <div style = {{fontSize: '16px', height: '30px', padding: '7px', marginRight: '10px'}} className = "label label-success label-sm fl">{Assets.Newticket}</div>
            <FormInput
                value = {!Main.Instance.GZG.phone ? '' : Main.Instance.GZG.phone} 
                ClassName = "form-control register-input telephone" 
                type = "text" 
                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {phone: text})}
                placeholder = {Assets.pPhone} 
                icon = {Assets_.faPhoneClass}
                prefix = {Main.Instance.GZG.countryID ? Main.Instance.DatabaseDataState.FlagsStreamAll[getCountryId(Main.Instance.GZG.countryID)]['phone'].split(' ')[0] : ' '}
            />
            <div style = {{position: 'absolute', left: '360px', top: '5px'}}>
            <FormInputNumber
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w80"
                value = {!Main.Instance.GZG.bought[0] ? '1' : 
                Main.Instance.GZG.bought.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faGetPocket}
                onChange = {(text) => SetBought(text)}
            />
            </div>
            <button onClick = {() => PlayRaffle()} style = {{position: 'absolute', left: '502px', top: '5px', marginRight: '0px'}} className = "btn btn-warning btn-sm">{Assets.Play}</button>
            <FormSelect
                float = {true}
                value = {!Main.Instance.GZG.countryID ? '0' : 
                        Main.Instance.GZG.countryID.toString()}
                data = {Main.Instance.DatabaseDataState.FlagsStreamAll}
                ClassName = "control-panel-"
                o1 = {Assets.None}
                o2 = {Assets.None} 
                onChange = {(text) => SetCountryID(text)}
                icon = {Assets_.faCountrylgClass}
            />
            <img
                style = {{position: 'absolute', left: '10px', top: '46px'}}
                src = {Main.Instance.GZG.countryID ? Main.Instance.DatabaseDataState.FlagsStreamAll[getCountryId(Main.Instance.GZG.countryID)]['image20'] : ''}
            />
            <div style = {{position: 'absolute', left: '360px', bottom: '5px'}}>
                <FormSelect
                    value = {!Main.Instance.GZG.rgametype[0] ? '0' : 
                            Main.Instance.GZG.rgametype[0].toString()}
                    data = {Main.Instance.GZG.data}
                    ClassName = "control-panel- raffle"
                    o1 = {Assets.None}
                    o2 = {Assets.None} 
                    onChange = {(text) => SetJackpotRaffleType(text)}
                    icon = {Assets_.faGetPocket}
                />
            </div>
        </div>
        : void 0}
        {Main.Instance.GZG.raffle && Main.Instance.GZG.data2 ?
        <>
            <h3>{Main.Instance.GZG.ID ? Main.Instance.GZG.gametype ? Assets.ListUnusedRaffleTickets : Assets.ListRaffleTickets : ''}</h3>
            <ReactTable
                data =  {Main.Instance.GZG.data2}
                columns = {[
                    {Header: Assets.TicketID, accessor: 'Ticket ID', headerClassName: 'th-left'},
                    {Header: Assets.Phone, accessor: 'phone', headerClassName: 'th-left'},
                    {Header: Assets.Country, accessor: 'flag_lottery', Cell: (row) => FlagByCountryName(row.value)},
                    {Header: Assets.TicketBought, accessor: 'Ticket Bought', headerClassName: 'th-left'},
                    {Header: Assets.Bought, accessor: 'bought', headerClassName: 'th-left'},
                    {Header: Assets.Expired, accessor: 'expired', headerClassName: 'th-left'},
                    {Header: Assets.GameType, accessor: 'Game Type', headerClassName: 'th-left'},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={100}
            />
        </>
        : void 0}

        {console.log(Main.Instance.GZG.data2)}
        {/*Main.Instance.GZG.raffle && Main.Instance.GZG.data2 ?
        StremingTable(Main.Instance.GZG.data2, {
            title: Main.Instance.GZG.ID ? Main.Instance.GZG.gametype ? Assets.ListUnusedRaffleTickets : Assets.ListRaffleTickets : '',
            mode: Main.Instance.GZG.gametype ? Main.Instance.GZG.mode : false,
        }) : void 0*/}

        {Main.Instance.GZG.gametype === true ?
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {Main.Instance.GZG.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >        
                    <div className = "create-input">
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        {Main.Instance.GZG.mode ? 
                            <button
                                style = {{position:'absolute', right: '-22px', top: '-20px', background: 'transparent', border: '0px'}}
                                title = {Assets.discardChanges} 
                                onClick = {() => gotoInsertMode()} 
                                className = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                            >
                                <i className = {Assets_.faCancelButtonClass}/>
                            </button>
                        : void 0}
                            <h4>
                                {Main.Instance.GZG.raffle ?
                                Main.Instance.GZG.mode ? Assets.ergt + ': ' + Main.Instance.GZG.name_saved : Assets.argt
                                : Main.Instance.GZG.mode ? Assets.elgt + ': ' + Main.Instance.GZG.name_saved : Assets.algt}
                            </h4>
                        </div>
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>                        
                            <FormInput
                                value = {!Main.Instance.GZG.name ? '' : Main.Instance.GZG.name} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG,  {name:text})} 
                                placeholder = {Assets.pName} 
                                icon = {Assets_.faLotteryClass}
                            />

                            <FormInput
                                narrow = {true}
                                value = {!Main.Instance.GZG.currency ? '' : Main.Instance.GZG.currency} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => text.length <= 3 ? Logic.Type.New(Main.Instance.GZG, {currency:text}) : void 0} 
                                placeholder = {Assets.Currency}
                                icon = {Assets_.faPaymentClass}
                            />

                            <FormInputNumber
                                description = {Assets.CutOffhours}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.cutoffhours === undefined ? '1' : 
                                Main.Instance.GZG.cutoffhours.toString()}  
                                type = "number" 
                                min = "1"
                                icon = {Assets_.faHourglassStartClass}
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {cutoffhours: +text})}
                            />

                            <FormSelect
                                wide = {true}
                                value = {!Main.Instance.GZG.country ? '0' :
                                        Main.Instance.GZG.country}
                                data = {Main.Instance.DatabaseDataState.FlagsStreamAll}
                                ClassName = "form-control"
                                o1 = {Assets.Selectregulation}
                                o2 = {Assets.Selectregulation}
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {country: text})}
                                icon = {Assets_.faCountrylgClass}
                            />

                            <RadioButton2
                                value = {!Main.Instance.GZG.isplayable ? '1' :
                                        Main.Instance.GZG.isplayable}
                                text = {Assets.playable}
                                ClassName = "input-group-radiobutton2 margin-bottom-sm fr" 
                                title = {Assets.playableDesc}
                                i1 = {Assets_.active}
                                i2 = {Assets_.inactive}
                                onClick = {(text) => {Logic.Type.New(Main.Instance.GZG, {isplayable: text})}}
                            />
                            {Main.Instance.GZG.raffle ? void 0 :
                            <RadioButton2
                                value = {!Main.Instance.GZG.numbers_repeatable ? '1' :
                                        Main.Instance.GZG.numbers_repeatable}
                                text = {Assets.repeatablenumbers}
                                ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                                title = {Assets.repeatablenumbersDesc}
                                i1 = {Assets_.active}
                                i2 = {Assets_.inactive}
                                onClick = {(text) => {Logic.Type.New(Main.Instance.GZG, {numbers_repeatable: text})}}
                            />}
                        </div>

                        {Main.Instance.GZG.raffle ?
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <FormInputNumber
                                description = {Assets.Stake}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.stake === undefined ? '0' : Main.Instance.GZG.stake.toString()}
                                type = "number" 
                                min = "0"
                                iconText = {Assets.Stake}
                                iconTextS = "16px"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {stake: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.Win}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w100px"
                                value = {Main.Instance.GZG.win === undefined ? '0' : Main.Instance.GZG.win.toString()}
                                type = "number" 
                                min = "0"
                                iconText = {Assets.Win}
                                iconTextS = "16px"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {win: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.Winners}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.winners === undefined ? '0' : Main.Instance.GZG.winners.toString()}
                                type = "number"
                                min = "0"
                                iconText = {Assets.Winners}
                                iconTextS = "16px"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {winners: +text})}
                            />

                            <FormInput
                                // narrow = {true}
                                value = {!Main.Instance.GZG.identification ? '' : Main.Instance.GZG.identification} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {identification: text})} 
                                placeholder = {Assets.Identification}
                                icon = {Assets_.faIdCardOClass}
                            />
                            {Main.Instance.GZG.jackpotRaffle ?
                            <FormInput
                                value = {!Main.Instance.GZG.payout ? '' : Main.Instance.GZG.payout} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => updatePayout(text)}
                                placeholder = {Assets.Payoutpercentage} 
                                icon = {Assets_.faPercentageClass}
                            />
                            :
                            <FormInput
                                narrow = {true}
                                value = {!Main.Instance.GZG.fire ? '' : Main.Instance.GZG.fire} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => updateDrawTime(text)} 
                                placeholder = {Assets.Drawtime}
                                icon = {Assets_.faHourglassClass}
                            />}
                        </div>
                        :                        
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <FormInputNumber
                                description = {Assets.Numbers}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.numbers === undefined ? '0' : Main.Instance.GZG.numbers.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "N"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {numbers: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.MinNumber}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.numbermin === undefined ? '0' : Main.Instance.GZG.numbermin.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "N-"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {numbermin: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.MaxNumber}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.numbermax === undefined ? '0' : Main.Instance.GZG.numbermax.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "N+"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {numbermax: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.Serialnumbers}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.serialnumbers === undefined ? '0' : Main.Instance.GZG.serialnumbers.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "SN"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {serialnumbers: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.MinSerialNumber}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.serialnumbermin === undefined ? '0' : Main.Instance.GZG.serialnumbermin.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "S-"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {numbermin: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.MaxSerialNumber}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.serialnumbermax === undefined ? '0' : Main.Instance.GZG.serialnumbermax.toString()}
                                type = "number" 
                                min = "0"
                                iconText = "S+"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {numbermax: +text})}
                            />

                            <FormInputNumber
                                description = {Assets.NumberLetters}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.GZG.letters === undefined ? '0' : Main.Instance.GZG.letters.toString()}
                                type = "number" 
                                min = "0"
                                iconText = {Assets.Letters}
                                iconTextS = "16px"
                                onChange = {(text) => Logic.Type.New(Main.Instance.GZG, {letters: +text})}
                            />

                            <FormInput
                                veryNarrow = {true}
                                value = {!Main.Instance.GZG.lettermin ? '' : Main.Instance.GZG.lettermin} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => text.length === 0 || text.length === 1 && text[0] >= 'A' && text[0] <= 'Z' ? Logic.Type.New(Main.Instance.GZG, {lettermin: text}) : void 0} 
                                placeholder = "-"
                                icon = {Assets_.faFontClass}
                                // iconText = "L-"
                            />

                            <FormInput
                                veryNarrow = {true}
                                value = {!Main.Instance.GZG.lettermin ? '' : Main.Instance.GZG.lettermin} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => text.length === 0 || text.length === 1 && text[0] >= 'A' && text[0] <= 'Z' ? Logic.Type.New(Main.Instance.GZG, {lettermin: text}) : void 0} 
                                placeholder = "+"
                                icon = {Assets_.faFontClass}
                                // iconText = "L+"
                            />
                        </div>}
                        {Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle ? void 0 :
                        <div className = "row admin-users-row" style = {{position: 'relative', minHeight: '35px'}}>
                            {Main.Instance.GZG.raffle ? void 0 :
                            <FormInput
                                narrow = {true}
                                value = {!Main.Instance.GZG.fire ? '' : Main.Instance.GZG.fire} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => updateDrawTime(text)} 
                                placeholder = "Draw time" 
                                icon = {Assets_.faHourglassClass}
                            />}
                            {getDW()}
                            <div style = {{position: 'absolute', right: '0px', top: '0px'}}>
                            {getWM()}
                            </div>
                        </div>}

                        {Main.Instance.GZG.fixed_odds ?
                        <div className = "row admin-users-container clearfix">
                            {showFixedOdds()}
                        </div>

                        : void 0}

                        <div className = "row admin-users-container clearfix">
                            <ButtonIcon 
                                description = {Main.Instance.GZG.mode ? 
                                                Assets.saveChanges : 
                                                Assets.updateChanges} 
                                click = {() => handleInsertUpdate()}
                                ClassName = {Main.Instance.GZG.mode ? 
                                            'btn btn-default btn-sm btn-plus fr mt0' : 
                                            'btn btn-primary btn-sm btn-plus fr mt0'}
                                // icon= {Assets_.faUpdateButtonClass}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>
                    </div>
                </div>
            </div>
        : void 0}

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.GZG.pSize ? '' : 
                Main.Instance.GZG.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            {/*Main.Instance.GZG.playerid ? 
            <span className = "ml label label-info label-sm">player filter on</span> : 
            <span className = "ml label label-danger label-sm">player filter off</span>*/}
            {/*Main.Instance.GZG.playerid ?
            <button onClick = {() => {Logic.Type.New(Main.Instance.GZG, {playerid: 0}); getgetGZGData()}} className = "btn btn-default btn-xs ml">disable</button> : void 0*/}

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.GZG.count ? Main.Instance.GZG.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.GZG.count ? Main.Instance.GZG.pNum * 
                Main.Instance.GZG.pSize + 1 : 0}{' - '}
                {Main.Instance.GZG.count ? 
                ((Main.Instance.GZG.pNum + 1) * 
                Main.Instance.GZG.pSize > Main.Instance.GZG.count ? 
                Main.Instance.GZG.count : 
                (Main.Instance.GZG.pNum + 1) * Main.Instance.GZG.pSize) : 0}
            </span>
        <span className = "label label-success table-label mt5">
            {Assets.page}
            {Main.Instance.GZG.count ? 
            Main.Instance.GZG.pNum + 1 :0}{' / '}
            {Main.Instance.GZG.count ? 
            Math.trunc((Main.Instance.GZG.count - 1) /
            Main.Instance.GZG.pSize + 1) : 0}
        </span>
        </div>
        <h3>
            {Main.Instance.GZG.raffle ? Main.Instance.GZG.gametype ? Assets.ListRaffleGameTypes : Assets.ListRaffles : 
            Main.Instance.GZG.gametype ? Assets.ListLotteryGameTypes : Assets.ListLotteryDraws}
        </h3>
        {/*console.log (Main.Instance.GZG.data)*/}
        {Main.Instance.GZG.data ?
        <ReactTable
            data = {Main.Instance.GZG.data}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => EditButton(row, handleEdit)},
                {maxWidth: 50, show: Main.Instance.GZG.raffle ? true : false, Header: '', accessor: '', Cell: (row) => SelectButton(row, handleSelect)},
                {maxWidth: 120, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {maxWidth: 40, show: !Main.Instance.GZG.raffle ? true : false, Header: 'Gt', accessor: 'gt', headerClassName: 'th-left'},
                {maxWidth: 40, show: Main.Instance.GZG.raffle ? true : false, Header: 'Rgt', accessor: 'rgt', headerClassName: 'th-left'},
                {maxWidth: 40, Header: <i className = "fa fa-money"/>, accessor: 'currency', headerClassName: 'th-left'},
                {maxWidth: 70, show: !Main.Instance.GZG.jackpotRaffle ? true : false, Header: 'Cut-off', accessor: 'cut-off', headerClassName: 'th-left'},
                {maxWidth: 60, Header: <i className = "fa fa-check"/>, accessor: 'isplayable', Cell: (row) => TB2(row)},
                {maxWidth: 100, Header: Assets.Regulation, accessor: 'flag_lottery_regulation', Cell: (row) => FlagByCountryName(row.value)},
                {maxWidth: 60, show: Main.Instance.GZG.raffle ? true : false, Header: Assets.Stake, accessor: 'stake', headerClassName: 'th-left'},
                {maxWidth: 90, show: Main.Instance.GZG.raffle ? true : false, Header: Assets.Win, accessor: 'win', headerClassName: 'th-left'},
                {maxWidth: 70, show: Main.Instance.GZG.raffle ? true : false, Header: Assets.Winners, accessor: 'winners', headerClassName: 'th-left'},
                {maxWidth: 80, show: Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle ? true : false, Header: Assets.Payout + ' %', accessor: 'Payout %', headerClassName: 'th-left'},
                {maxWidth: 120, show: Main.Instance.GZG.raffle && Main.Instance.GZG.jackpotRaffle ? true : false, Header: Assets.Min_Required, accessor: 'MinRequired', headerClassName: 'th-left'},

                {maxWidth: 80, show: Main.Instance.GZG.raffle && !Main.Instance.GZG.jackpotRaffle ? true : false, Header: Assets.Ident_, accessor: 'identification', headerClassName: 'th-left'},
                {maxWidth: 40, show: !Main.Instance.GZG.raffle ? true : false, Header: Assets.NoShort, accessor: 'n', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'N-', accessor: 'n-', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'N+', accessor: 'n+', headerClassName: 'th-left'},
                {maxWidth: 130, show: !Main.Instance.GZG.jackpotRaffle ? true : false, 
                    Header: Assets.DayInWeek, accessor: 'day in week', headerClassName: 'th-left', Cell: (row) => getDayInWeek(row.value)},
                {maxWidth: 120, show: !Main.Instance.GZG.jackpotRaffle ? true : false, 
                    Header: Assets.WeekInMonth, accessor: 'week in month', headerClassName: 'th-left', Cell: (row) => getWeekInMonth(row.value)},
                {maxWidth: 60, show: !Main.Instance.GZG.jackpotRaffle ? true : false, Header: Assets.Draw, accessor: 'draw', headerClassName: 'th-left', Cell: (row) => row.value.substring(0, 5)},
                {maxWidth: 40, show: !Main.Instance.GZG.raffle ? true : false, Header: 'SN', accessor: 'SN', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'S-', accessor: 's-', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'S+', accessor: 's+', headerClassName: 'th-left'},
                {maxWidth: 40, show: !Main.Instance.GZG.raffle ? true : false, Header: 'Lett.', accessor: 'lett.', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'L-', accessor: 'l-', headerClassName: 'th-left'},
                {maxWidth: 30, show: !Main.Instance.GZG.raffle ? true : false, Header: 'L+', accessor: 'l+', headerClassName: 'th-left'},
                {maxWidth: 60, show: !Main.Instance.GZG.raffle ? true : false, Header: 
                    <i title = {Assets.repeatablenumbersDesc} className = "fa fa-repeat"/>, accessor: 'nr', Cell: (row) => TB(row)},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getGZGCount()}
        {/*Main.Instance.GZG.data ?
        StremingTable(Main.Instance.GZG.data, {
            title: Main.Instance.GZG.raffle ? Main.Instance.GZG.gametype ? Assets.ListRaffleGameTypes : Assets.ListRaffles : 
                   Main.Instance.GZG.gametype ? Assets.ListLotteryGameTypes : Assets.ListLotteryDraws,
            editingId: Main.Instance.GZG.raffle && Main.Instance.GZG.ID ? Main.Instance.GZG.ID.toString() : '0',
            selectButton: Main.Instance.GZG.raffle ? (data) => handleSelect(data) : undefined,
            selected: Main.Instance.GZG.raffle && Main.Instance.GZG.ID ? true : undefined,
            mode: Main.Instance.GZG.gametype ? Main.Instance.GZG.mode : false,
            // sort: getGZGCount,
            // Sort: Main.Instance.GZG,
            delButton: Main.Instance.GZG.gametype ? (data) => handleDelete(data) : undefined,
            editButton: Main.Instance.GZG.gametype ? (data) => handleEdit(data) : undefined,
        }) : void 0*/}
        </div>
    )
}