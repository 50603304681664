
import {Logic} from '../Type'
import {faDollarSign, faPoundSign, faEuroSign, faMale, faFemale, faRubleSign, faHryvnia, faLiraSign, faRupeeSign, faShekelSign, faWonSign, faYenSign} from '@fortawesome/free-solid-svg-icons'
import {faBtc, faEthereum} from '@fortawesome/free-brands-svg-icons'
import {IPlayersInfo} from '../Database/DatabaseData'
import * as Assets_ from '../../Views/Assets_'
export const logicCurrency: Array<{acronym: string, icon?: any, disabled?: string, enabled?: string}> = [
    {acronym: 'EUR', icon: faEuroSign},
    {acronym: 'GBP', icon: faPoundSign},
    {acronym: 'CHF', disabled: '../fr16.png', enabled: '../sfr16.png'},
    {acronym: 'RUB', icon: faRubleSign},
    {acronym: 'USD', icon: faDollarSign},
    {acronym: 'UAH', icon: faHryvnia},

    {acronym: 'TRY', icon: faLiraSign},
    {acronym: 'INR', icon: faRupeeSign},
    {acronym: 'ILS', icon: faShekelSign},
    {acronym: 'KRW', icon: faWonSign},
    {acronym: 'JPY', icon: faYenSign},
    {acronym: 'BTC', icon: faBtc},
    {acronym: 'ETH', icon: faEthereum},
]
/*
export const Continents: string[] = [
    'Africa', 'Asia', 'Australia and Oceania', 'Europe', 'North America', 'South America',
]
*/
export const Timezones:Array<{value: string, acronym: string}> = [
    {value: '-08', acronym: 'PT'},
    {value: '-07', acronym: 'MT'},
    {value: '-06', acronym: 'CT'},
    {value: '-05', acronym: 'ET'},
    {value: '-04', acronym: 'AT'},
    {value: '+00', acronym: 'WET'},
    {value: '+01', acronym: 'CET'},
    {value: '+02', acronym: 'EET'},
    {value: '+03', acronym: 'MSK'},
]

export const Currencies:Array<{acronym: string, icon?: any, text?: string, disabled?: string, enabled?: string}> = [
    {acronym: 'USD', icon: faDollarSign},
    {acronym: 'EUR', icon: faEuroSign},
    {acronym: 'GBP', icon: faPoundSign},
    {acronym: 'RUB', icon: faRubleSign},
    {acronym: 'CHF', /*text: 'Fr.',*/ disabled: Assets_.fr18, enabled: Assets_.sfr18},
]

export const AgeGroup:Array<{low?: number, high?: number, text: string}> = [
    {/*low: 18,*/ high: 24, text: '≤24'},
    {low: 25, high: 34, text: '25-34'},
    {low: 35, high: 44, text: '35-44'},
    {low: 45, high: 54, text: '45-54'},
    {low: 55, high: 64, text: '55-64'},
    {low: 65, text: '≥65'},
]

export const BalanceGroup:Array<{low?: number, high?: number, /*li?: boolean, hi?: boolean,*/ text: string}> = [
    {high: 1, /*hi: false,*/ text: '≤1'},
    {low: 1, high: 10, /*li: true, hi: false,*/ text: '1-10'},
    {low: 10, high: 100, /*li: true, hi: false,*/ text: '10-100'},
    {low: 100, /*li: true,*/ text: '>100'},
]


export const Gender:Array<{short: string, long: string, icon: any}> = [
    {short: 'f', long: 'female', icon: faFemale},
    {short: 'm', long: 'male', icon: faMale},
]

export const years: string[] = ['2015', '2016', '2017', '2018', '2019']
export const months: string[] = ['January', 'February', 'March', 'April', 'May','June', 'July', 'August', 'September', 'October', 'November', 'December']

export const logic: string[] = ['not', 'or', 'and', 'xor']
export const logicComparison: string[] = ['ignore', 'less than', 'less than or equal to', 'equal to', 'more than or equal to', 'more than']
// ≤ ≥  '>1', '>10', '>100', '≤1', '≤10', '≤100',
export const logicActionBet: string[] = ['ignore', 'total bets', 'mean (average) bet', 'median bet', 'any bet', 'no bet', 'custom # bets']
export const logicActionWin: string[] = ['ignore', 'total wins', 'mean (average) win', 'median win', 'any win', 'no win', 'custom # wins']
// '>1', '>10', '>100', '≤1', '≤10', '≤100',  'total deposits',
// '>1', '>10', '>100', '≤1', '≤10', '≤100',  'total withdrawals',
export const logicActionDeposit: string[] = ['ignore', 'total deposits', 'mean (average) deposit', 'median deposit', 'any deposit', 'no deposit', 'custom # deposits']
export const logicActionWithdrawal: string[] = ['ignore', 'total withdrawals', 'mean (average) withdrawal', 'median withdrawal', 'any withdrawal', 'no withdrawal', 'custom # withdrawals']

export const logicSession: string[] = ['ignore', 'total sessions', 'mean (average) session', 'median session', 'any session', 'no session', 'custom # sessions']

export const logicFrequency: string[] = ['ignore', 'per minute', 'per hour', 'per session', 'per day', 'per week', 'per month', 'per year'] 
export const logicFrequency0: string[] = ['ignore', 'never', 'always', 'once', 'twice', 'three times', 'four times', 'five times']
export const LogicFrequency: string[] = ['ignore', 'in a minute', 'in an hour', 'in a session', 'in a day', 'in a week', 'in a month', 'in a year'] 
export interface IPlayerGroupRule {
    countries: string[]
    countriesLogic: string
    timezones: string[]
    timezonesLogic: string
    // continents: string[]
    // continentsLogic: string
    Regions: string[]
    regions: string[]
    regionsLogic: string
    nationalities: string[]
    nationalitiesLogic: string

    // players: number[]
    games: number[]
    gamesLogic: string
    currencies: string[]
    currenciesLogic: string
    gender: string[]
    genderLogic: string
    ageGroup: string[]
    ageGroupLogic: string
    ageGroupMin: number
    ageGroupMax: number
    balanceGroup: string[]
    balanceGroupLogic: string
    balanceGroupMin: number
    balanceGroupMax: number

    levelsLogic: string
    levels: Array<{id: string, min: number, max: number}>
    
    mode: boolean

    frequencyLogic: string
    frequencyLogic0: string
    calendarYear: string[]
    calendarYearLogic: string
    calendarMonth: string[]
    calendarMonthLogic: string
    calendarWeekDays: string[]
    calendarWeekDaysLogic: string
    calendarMonthDays: string[]
    calendarMonthDaysLogic: string

    calendarYearMax: string[]
    calendarYearLogicMax: string
    calendarMonthMax: string[]
    calendarMonthLogicMax: string
    calendarWeekDaysMax: string[]
    calendarWeekDaysLogicMax: string
    calendarMonthDaysMax: string[]
    calendarMonthDaysLogicMax: string

    timesHoursMin: number
    timesHoursMax: number
    timesMinutesMin: number
    timesMinutesMax: number
    timesHoursMinMAX: number
    timesHoursMaxMAX: number
    timesMinutesMinMAX: number
    timesMinutesMaxMAX: number

    title: string

    // backgroundColor: string
    // color: string

    staticGroup: boolean
    staticTimestamp: string
    staticMembers: number[]
    allowLowerPriority: boolean
    priority: number
    interval: boolean

// ***********************************
    // DEPOSITS
    depositLogic: string
    depositLogicMin: number
    depositLogicMax: number

    withinDepositLogic: string
    withinDeposit: number

    minDepositLogic: string
    minDepositCurrencyLogic: string
    minDeposit: number

    maxDepositLogic: string
    maxDepositCurrencyLogic: string
    maxDeposit: number

    // WITHDRAWAL
    withdrawalLogic: string
    withdrawalLogicMin: number
    withdrawalLogicMax: number

    withinWithdrawalLogic: string
    withinWithdrawal: number

    minWithdrawalLogic: string
    minWithdrawalCurrencyLogic: string
    minWithdrawal: number

    maxWithdrawalLogic: string
    maxWithdrawalCurrencyLogic: string
    maxWithdrawal: number
// ***********************************
    betLogic: string
    betLogicMin: number
    betLogicMax: number

    winLogic: string
    winLogicMin: number
    winLogicMax: number

    minBetLogic: string
    minBetCurrencyLogic: string
    minBet: number

    maxBetLogic: string
    maxBetCurrencyLogic: string
    maxBet: number

    minWinLogic: string
    minWinCurrencyLogic: string
    minWin: number

    maxWinLogic: string
    maxWinCurrencyLogic: string
    maxWin: number
// ***********************************
    betsSessionLogic: string
    betsSessionLogicMin: number
    betsSessionLogicMax: number

    minBetsSessionLogic: string
    minBetsSession: number

    maxBetsSessionLogic: string
    maxBetsSession: number

    timeSessionLogic: string
    timeSessionLogicMin: number
    timeSessionLogicMax: number

    minTimeSessionLogic: string
    minTimeSession: number

    maxTimeSessionLogic: string
    maxTimeSession: number
}

export class PlayerGroupsInternal extends Logic.Type {
    public staticTimestamp: string
    public showStatic: boolean = false
    public rule: IPlayerGroupRule
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public offset: string = '0'
    public modePlayers: boolean = false
    public modeGames: boolean = false
    public modeCalendar: boolean = false
    public modeBetSizing: boolean = false
    public modeSession: boolean = false
    public modeFrequency: boolean = false
    public modeDeposit: boolean = false
    public modeWithdrawal: boolean = false
    public countCalled: boolean = false
    public mode: boolean = false
    public id: string
    public viewList: boolean = false
    public title: string = ''
    public Data: IPlayersInfo[]
    // calculatedData: PlayerData[]
}

export class PlayerGroupsState extends Logic.Type {
    // GENERAL
    public staticTimestamp: string
    public staticMembers: any
    public title: string
    public title_saved: string
    public priority: number
    public staticGroup: boolean = false
    public allowLowerPriority: boolean = true
    // backgroundColor: string
    // color: string

    // DEPOSITS
    public depositLogic: string = 'ignore'
    public depositLogicMin: number
    public depositLogicMax: number

    public withinDepositLogic: string = 'ignore'
    public withinDeposit: number

    public minDepositLogic: string = 'ignore'
    public minDepositCurrencyLogic: string = 'EUR'
    public minDeposit: number

    public maxDepositLogic: string = 'ignore'
    public maxDepositCurrencyLogic: string = 'EUR'
    public maxDeposit: number

    // WITHDRAWAL
    public withdrawalLogic: string = 'ignore'
    public withdrawalLogicMin: number
    public withdrawalLogicMax: number

    public withinWithdrawalLogic: string = 'ignore'
    public withinWithdrawal: number

    public minWithdrawalLogic: string = 'ignore'
    public minWithdrawalCurrencyLogic: string = 'EUR'
    public minWithdrawal: number

    public maxWithdrawalLogic: string = 'ignore'
    public maxWithdrawalCurrencyLogic: string = 'EUR'
    public maxWithdrawal: number
    // BET SIZING
    public betLogic: string = 'ignore'
    public betLogicMin: number
    public betLogicMax: number

    public minBetLogic: string = 'ignore'
    public minBetCurrencyLogic: string = 'EUR'
    public minBet: number

    public maxBetLogic: string = 'ignore'
    public maxBetCurrencyLogic: string = 'EUR'
    public maxBet: number

    public winLogic: string = 'ignore'
    public winLogicMin: number
    public winLogicMax: number

    public minWinLogic: string = 'ignore'
    public minWinCurrencyLogic: string = 'EUR'
    public minWin: number

    public maxWinLogic: string = 'ignore'
    public maxWinCurrencyLogic: string = 'EUR'
    public maxWin: number

    // SESSION
    public betsSessionLogic: string = 'ignore'
    public betsSessionLogicMin: number
    public betsSessionLogicMax: number

    public minBetsSessionLogic: string = 'ignore'
    public minBetsSession: number

    public maxBetsSessionLogic: string = 'ignore'
    public maxBetsSession: number

    public timeSessionLogic: string = 'ignore'
    public timeSessionLogicMin: number
    public timeSessionLogicMax: number

    public minTimeSessionLogic: string = 'ignore'
    public minTimeSession: number

    public maxTimeSessionLogic: string = 'ignore'
    public maxTimeSession: number

    // FREQUENCY
    public frequencyLogic: string = 'ignore'
    public frequencyLogic0: string = 'ignore'

    // CALENDAR
    public interval: boolean = false
    public calendarYear: string[] = []
    public calendarYearLogic: string
    public calendarMonth: string[] = []
    public calendarMonthLogic: string
    public calendarWeekDays: string[] = []
    public calendarWeekDaysLogic: string
    public calendarMonthDays: string[] = []
    public calendarMonthDaysLogic: string
    public timesHoursMin: number = 0
    public timesHoursMax: number = 0
    public timesMinutesMin: number = 0
    public timesMinutesMax: number = 0

    public calendarYearMax: string[] = []
    public calendarYearLogicMax: string
    public calendarMonthMax: string[] = []
    public calendarMonthLogicMax: string
    public calendarWeekDaysMax: string[] = []
    public calendarWeekDaysLogicMax: string
    public calendarMonthDaysMax: string[] = []
    public calendarMonthDaysLogicMax: string
    public timesHoursMinMAX: number = 0
    public timesHoursMaxMAX: number = 0
    public timesMinutesMinMAX: number = 0
    public timesMinutesMaxMAX: number = 0
    
    // GAMES
    public games: number[] = []
    public gamesLogic: string

    // PLAYERS
    public levels: Array<{id: string, min: number, max: number}> = []
    public levelsLogic: string
    public timezones: string[] = []
    public timezonesLogic: string
    // public continents: string[] = []
    // public continentsLogic: string
    public countries: string[] = []
    public countriesLogic: string
    public regions: string[] = []
    public Regions: string[] = []
    public regionsLogic: string
    public nationalities: string[] = []
    public nationalitiesLogic: string

    public currencies: string[] = []
    public currenciesLogic: string
    public gender: string[] = []
    public genderLogic: string
    public ageGroup: string[] = []
    public ageGroupLogic: string
    public ageGroupMin: number
    public ageGroupMax: number
    public balanceGroup: string[] = []
    public balanceGroupLogic: string
    public balanceGroupMin: number
    public balanceGroupMax: number

    // MANUAL
    // players: number[] = []
}