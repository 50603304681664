import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileCsv, faFilePdf, faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as jsPDF from 'jspdf'
import {getPageNumbers, getFooter, getHeader, isUserReports, isLocalhost} from '../../../Logic/Utils'
// import {IReports} from '../../../Logic/Database/DatabaseData'
import 'jspdf-autotable'
import {isLocationGameReportsTest} from './GameReportsReal'
import {IReports} from '../../../Logic/Database/DatabaseData'
interface State {
    mode?: boolean
}

interface Props {
    type: number
}

export class SelectTimePeriod extends React.Component <Props, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            // mode: false,
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public filterByDate() {
        if (Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1) {    
            const ggrReportsFiltered: IReports[] = []
            // const temp: IReports[] = Object.assign([], JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.ggrReports)))
            const temp: IReports[] = JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.ggrReports))
            // console.log (Main.Instance.DatabaseDataState.ggrReportsDates)
            // console.log (Main.Instance.GGRRevenueState.dateFrom)
            // if (Main.Instance.GGRRevenueState.dateFrom !== -1) console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom])
            // console.log (Main.Instance.GGRRevenueState.dateTo)
            // if (Main.Instance.GGRRevenueState.dateTo !== -1) console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo])
            const ggrReportsTotal: IReports = {
                WANJackpotIncrements: 0,
                bonusAwarded: 0,
                canceledBonus: 0,
                bonusEndingBalance: 0,
                bonusStartingBalance: 0,
                convertedBonus: 0,
                depositsPSP: 0,
                difference: 0,
                jackpotHitsRng: 0,
                manualAdjustments: 0,
                manualDeposits: 0,
                realEndingBalance: 0,
                realMovement: 0,
                realStartingBalance: 0,
                totalBetBonus: 0,
                totalBetRealLive: 0,
                totalBetRealRNG: 0,
                totalGGR: 0,
                totalGGR2: 0,
                totalWinBonus: 0,
                totalWinRealLive: 0,
                totalWinRealRNG: 0,
                withdrawsPending: 0,
                withdrawsProcessed: 0,
            }
            // console.log ('filterbydate')
            for (const item of temp) {
                const date: string = (new Date(item.date * 1000)).toISOString().substring(0, 10)
                // console.log (date)
                if (Main.Instance.GGRRevenueState.dateFrom !== -1 && date < Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]) {
                    // console.log ('NOT OK 1')
                    continue
                }
                if (Main.Instance.GGRRevenueState.dateTo !== -1 && date > Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]) {
                    // console.log ('NOT OK 2')
                    continue
                }
    
                // POSSIBLE BUG (FIX)
                item.date += 3600 * 10
                item.realMovement = -item.realMovement
                ggrReportsTotal.WANJackpotIncrements += item.WANJackpotIncrements
                ggrReportsTotal.bonusAwarded += item.bonusAwarded
                if (item.canceledBonus) {ggrReportsTotal.canceledBonus += item.canceledBonus}
                ggrReportsTotal.bonusEndingBalance += item.bonusEndingBalance
                ggrReportsTotal.bonusStartingBalance += item.bonusStartingBalance
                ggrReportsTotal.convertedBonus += item.convertedBonus
                ggrReportsTotal.depositsPSP += item.depositsPSP
                ggrReportsTotal.difference += item.difference
                ggrReportsTotal.jackpotHitsRng += item.jackpotHitsRng
                ggrReportsTotal.manualAdjustments += item.manualAdjustments
                ggrReportsTotal.manualDeposits += item.manualDeposits
                ggrReportsTotal.realEndingBalance += item.realEndingBalance
                ggrReportsTotal.realMovement += item.realMovement
                ggrReportsTotal.realStartingBalance += item.realStartingBalance
                ggrReportsTotal.totalBetBonus += item.totalBetBonus
                ggrReportsTotal.totalBetRealLive += item.totalBetRealLive
                ggrReportsTotal.totalBetRealRNG += item.totalBetRealRNG
                ggrReportsTotal.totalGGR += item.totalGGR
                ggrReportsTotal.totalGGR2 += item.totalGGR2
                ggrReportsTotal.totalWinBonus += item.totalWinBonus
                ggrReportsTotal.totalWinRealLive += item.totalWinRealLive
                ggrReportsTotal.totalWinRealRNG += item.totalWinRealRNG
                ggrReportsTotal.withdrawsPending += item.withdrawsPending
                ggrReportsTotal.withdrawsProcessed += item.withdrawsProcessed
    
                ggrReportsFiltered.push(item)
            }        
            Logic.Type.New (Main.Instance.DatabaseDataState, {ggrReportsFiltered, ggrReportsTotal})
            // console.log (Main.Instance.DatabaseDataState.ggrReportsFiltered)
        }
    }

    public getPeriod(): string {
        if (Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1) {
            let period: string = ''
            if (Main.Instance.GGRRevenueState.dateFrom !== -1) {
                period += Assets.From.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
                // console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom])
            }
    
            if (Main.Instance.GGRRevenueState.dateTo !== -1) {
                if (period.length > 0) {
                    period += ' '
                }
                period += Assets.To.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]
    
                // console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo])
            }
            return period
        }
        return undefined
    }

    public createPDFDocument(type: number) {
        const doc = new jsPDF('landscape', 'mm', 'a4')
        const period: string = this.getPeriod()
        const img = new Image();
        img.src = Assets_.jackpotsLogo
        
        if (type === 1) {
            
            img.onload = () => {
                const img2 = new Image()
                img2.src = Assets_.badenLogo
                
                img2.onload = () => {
                    // settings
                    doc.autoTableSetDefaults(
                        {margin: {top: 20, left: 10, right: 10, bottom: 20},
                        headStyles: {fillColor: '#c90227'},
                        footStyles: {fillColor: '#111'},
                        cellPadding: 5,
                    })

                    // page 1
                    getHeader('GGR', doc, img, img2, 1, period)
                    doc.autoTable({html: '#ggr1'})
                    getFooter('GGR', doc, true, Assets.ggr1)

                    // page 2
                    doc.addPage();
                    getHeader('GGR', doc, img, img2, 2, period)
                    doc.autoTable({html: '#ggr2'})
                    getFooter('GGR', doc, true, Assets.ggr1, true, Assets.ggr2)

                    // page 3
                    doc.addPage();
                    getHeader('GGR', doc, img, img2, 3, period)
                    doc.autoTable({html: '#ggr3'})
                    getFooter('GGR', doc)

                    // page total
                    doc.addPage();
                    getHeader('GGR', doc, img, img2, 4, period)
                    doc.autoTable({html: '#ggrt1'})
                    doc.autoTable({html: '#ggrt2'})
                    doc.autoTable({html: '#ggrt3'})
                    getFooter('GGR', doc)

                    // set page numbers
                    getPageNumbers(doc)

                    // finally
                    doc.save(Assets.GGRRevenueReporting + (period ? period : '') + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + '.pdf')
                }
            }
        }
    }

    public download(type: number) {
        let from: string = ''
        if (Main.Instance.GGRRevenueState.dateFrom !== -1) {
            from = ' ' + Assets.From.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
        }
    
        let to: string = ''
        if (Main.Instance.GGRRevenueState.dateTo !== -1) {
            to = ' ' + Assets.To.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]
        }

        if (type === 1) {
            let s: string = Assets.Date
            + ',' + Assets.TotalBetReal + ' ' + Assets.Money_RNG + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalWinReal + ' ' + Assets.Money_RNG + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalBetReal + ' ' + Assets.Money_Live + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalWinReal + ' ' + Assets.Money_Live + ' (' + Assets_.currency + ')'
            + ',' + Assets.WANJackpot + ' ' + Assets.Increments + ' (' + Assets_.currency + ')'
            + ',' + Assets.JackpotHits + ' ' + Assets.RNG + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalBet + ' ' + Assets.BonusMoney + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalWin + ' ' + Assets.BonusMoney + ' (' + Assets_.currency + ')'
            + ',' + Assets.Converted + ' ' + Assets.BonusMoney + ' ' + Assets.After.toLowerCase() + ' ' + Assets.Playthrough + ' (' + Assets_.currency + ')'
            + ',' + Assets.TotalGGR
        
            if (Main.Instance.GGRRevenueState.longReport !== undefined) {
                s += ',' + Assets.Deposits + ' ' + Assets.PSP + ' (' + Assets_.currency + ')'
                + ',' + Assets.Manual + ' ' + Assets.Deposits + ' (' + Assets_.currency + ')'
                + ',' + Assets.Withdraws + ' ' + Assets.Processed.toLowerCase() + ' (' + Assets_.currency + ')'
                + ',' + Assets.Withdraws + ' ' + Assets.Pending.toLowerCase() + ' (' + Assets_.currency + ')'
            }
        
            if (Main.Instance.GGRRevenueState.longReport === true) {
                s += ',' + Assets.Converted + ' ' + Assets.BonusMoney + ' ' + Assets.After.toLowerCase() + ' ' + Assets.Playthrough + ' (' + Assets_.currency + ')'
                + ',' + Assets.Manual + ' ' + Assets.Adjustments + ' (' + Assets_.currency + ')'
                + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Starting + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
                + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Ending + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
                + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Movement + ' (' + Assets_.currency + ')'
                + ',' + Assets.TotalGGR + ' (' + Assets_.currency + ')'
                + ',' + Assets.Difference + ' (' + Assets_.currency + ')'
                + ',' + Assets.Bonus + ' ' + Assets.Awarded + ' (' + Assets_.currency + ')'
                + ',' + Assets.Bonus + ' ' + Assets.Cancelled + ' (' + Assets_.currency + ')'
                + ',' + Assets.Bonus + ' ' + Assets.Starting + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
                + ',' + Assets.Bonus + ' ' + Assets.Ending + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
            }
            
            s += '\n'
            s += this.addDownload(Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] : Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : [], false, 1)
            s += this.addDownload([Main.Instance.DatabaseDataState.ggrReportsTotal], true, 1)
        
            const element = document.createElement('a')
            const file = new Blob([s], {type: 'text/csv'})
            element.href = URL.createObjectURL(file)
            element.download = Assets.GGRRevenueReporting + from + to + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
            // element.click()
            element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));

        } else if (type === 2 || type === 3) {
            let s: string = Assets.Date
            + ',' + Assets.GameProvider
            + ',' + Assets.Total + ' ' + Assets.number + Assets._of_ + Assets.Rounds.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.number + Assets._of_ + Assets.Sessions.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.number + Assets._of_ + Assets.Unique.toLowerCase() + ' ' + Assets.Players.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Wager.toLowerCase() + ' ' + Assets.BonusMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Win.toLowerCase() + ' ' + Assets.BonusMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Wager.toLowerCase() + ' ' + Assets.RealMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Win.toLowerCase() + ' ' + Assets.RealMoney.toLowerCase()

            s += '\n'
            s += this.addDownload(type === 3 ? Main.Instance.DatabaseDataState.gameReportsTest : Main.Instance.DatabaseDataState.gameReportsReal, false, 2)
            const element = document.createElement('a')
            const file = new Blob([s], {type: 'text/csv'})
            element.href = URL.createObjectURL(file)
            if (type === 2) {
                element.download = Assets.Real + ' ' + Assets.Game + ' ' + Assets.Reports + from + to + ' ' + Assets.GameID + ' ' + Main.Instance.DatabaseDataState.gameReportsReal[0].gameId + Assets_._csv
            } else if (type === 3) {
                element.download = Assets.Test + ' ' + Assets.Game + ' ' + Assets.Reports + from + to + ' ' + Assets.GameID + ' ' + Main.Instance.DatabaseDataState.gameReportsTest[0].gameId + Assets_._csv
            }
            element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
        } else if (type === 4) {
            let s: string = Assets.Date
            + ',' + Assets.Username
            + ',' + Assets.TestAccount
            + ',' + Assets.Deposit
            + ',' + Assets.Withdraw
            + ',' + Assets.BalanceBefore
            + ',' + Assets.BalanceAfter
            + ',' + Assets.Cancelled + ' ' + Assets.Bonus.toLowerCase()
            + ',' + Assets.ConvertedBonus
            + ',' + Assets.Received + ' ' + Assets.Bonus.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Wager.toLowerCase() + ' ' + Assets.BonusMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Win.toLowerCase() + ' ' + Assets.BonusMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Wager.toLowerCase() + ' ' + Assets.RealMoney.toLowerCase()
            + ',' + Assets.Total + ' ' + Assets.Win.toLowerCase() + ' ' + Assets.RealMoney.toLowerCase()

            s += '\n'
            s += this.addDownload(Main.Instance.DatabaseDataState.playerReports, false, 4)
            const element = document.createElement('a')
            const file = new Blob([s], {type: 'text/csv'})
            element.href = URL.createObjectURL(file)
            element.download = Assets.Player_ + ' ' + Assets.Reports + from + to + ' ' + Assets.PlayerID + ' ' + Main.Instance.DatabaseDataState.playerReports[0].playerId + Assets_._csv
            element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
        }
    }

    public addDownload(data: any[], total: boolean, type: number): string {
        let s: string = ''
        for (const item of data) {
            if (type === 1) {
                s += total ? Assets.Total : (new Date(item.date * 1000)).toISOString().substring(0, 10)
                s += ',' + item.totalBetRealRNG
                s += ',' + item.totalWinRealRNG
                s += ',' + item.totalBetRealLive
                s += ',' + item.totalWinRealLive
                s += ',' + item.WANJackpotIncrements
                s += ',' + item.jackpotHitsRng
                s += ',' + item.totalBetBonus
                s += ',' + item.totalWinBonus
                s += ',' + item.convertedBonus
                s += ',' + item.totalGGR
        
                if (Main.Instance.GGRRevenueState.longReport !== undefined) {
                    s += ',' + item.depositsPSP
                    s += ',' + item.manualDeposits
                    s += ',' + item.withdrawsProcessed
                    s += ',' + item.withdrawsPending
                }
        
                if (Main.Instance.GGRRevenueState.longReport === true) {
                    s += ',' + item.convertedBonus
                    s += ',' + item.manualAdjustments
                    s += ',' + item.realStartingBalance
                    s += ',' + item.realEndingBalance
                    s += ',' + item.realMovement
                    s += ',' + item.totalGGR2
                    s += ',' + item.difference
                    s += ',' + item.bonusAwarded
                    s += ',' + item.canceledBonus
                    s += ',' + item.bonusStartingBalance
                    s += ',' + item.bonusEndingBalance
                }
            } else if (type === 2) {
                s += item.year + '-' + item.month + '-' + item.day 
                s += ',' + item.providerName
                s += ',' + item.numOfRounds
                s += ',' + item.numOfSessions
                s += ',' + item.numOfUniquePlayers
                s += ',' + item.wagerBonus
                s += ',' + item.winBonus
                s += ',' + item.wagerReal
                s += ',' + item.winReal
            } else if (type === 4) {
                s += item.year + '-' + item.month + '-' + item.day 
                s += ',' + item.username
                s += ',' + item.isTestAccount
                s += ',' + item.deposit
                s += ',' + item.withdraw
                s += ',' + item.balanceBefore
                s += ',' + item.balanceAfter
                s += ',' + item.bonusCanceled
                s += ',' + item.bonusConverted
                s += ',' + item.bonusReceived
                s += ',' + item.wagerBonus
                s += ',' + item.winBonus
                s += ',' + item.wagerReal
                s += ',' + item.winReal
            }
            
    
            s += '\n'
        }
        return s
    }

    public getPlayerData(from?, to?) {
        let yearFrom: number 
        let yearTo: number 
        let monthFrom: number 
        let monthTo: number 
        let dateFrom: number
        let dateTo: number

        let found: boolean = false
        for (const item of Main.Instance.DatabaseDataState.playerCards) {
            if (item.playerId === Main.Instance.GGRRevenueState.playerId) {
                found = true
                break
            }
        }
        
        if (found === false) {
            alert (Assets.PlayerId + ' ' + Assets.DoesNotExist.toLowerCase())
            return
        }

        if (!from && Main.Instance.GGRRevenueState.dateFrom === -1) {
            alert (Assets.Please + ' ' + Assets.SelectDate.toLowerCase() + ' ' + Assets.From.toLowerCase())
            return
        }
        if (!to && Main.Instance.GGRRevenueState.dateTo === -1) {
            alert (Assets.Please + ' ' + Assets.SelectDate.toLowerCase() + ' ' + Assets.To.toLowerCase())
            return
        }
        if ((to ? to : Main.Instance.GGRRevenueState.dateTo) < (from ? from : Main.Instance.GGRRevenueState.dateFrom)) {
            alert (Assets.FromDate + ' ' + Assets.Must.toLowerCase() + Assets._be_ + Assets.Before.toLowerCase() + ' ' + Assets.ToDate.toLowerCase())
            return
        }
    
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {  
                if ((from ? from : Main.Instance.GGRRevenueState.dateFrom) === i) {
                    yearFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                    monthFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) 
                    dateFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                }
                if ((to ? to : Main.Instance.GGRRevenueState.dateTo) === i) {
                    yearTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                    monthTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) 
                    dateTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                }
            }
        }
        DbRoutes.getPlayerReports(Main.Instance.GGRRevenueState.playerId, yearFrom, yearTo, monthFrom, monthTo, dateFrom, dateTo)
    }

    public getGameData(from?, to?) {
        let yearFrom: number 
        let yearTo: number 
        let monthFrom: number 
        let monthTo: number 
        let dateFrom: number
        let dateTo: number
    
        if (Main.Instance.GGRRevenueState.gameId === -1) {
            alert (Assets.Please + ' ' + Assets.SelectGame.toLowerCase())
            return
        }
        if (!from && Main.Instance.GGRRevenueState.dateFrom === -1) {
            alert (Assets.Please + ' ' + Assets.SelectDate.toLowerCase() + ' ' + Assets.From.toLowerCase())
            return
        }
        if (!to && Main.Instance.GGRRevenueState.dateTo === -1) {
            alert (Assets.Please + ' ' + Assets.SelectDate.toLowerCase() + ' ' + Assets.To.toLowerCase())
            return
        }
        if ((to ? to : Main.Instance.GGRRevenueState.dateTo) < (from ? from : Main.Instance.GGRRevenueState.dateFrom)) {
            alert (Assets.FromDate + ' ' + Assets.Must.toLowerCase() + Assets._be_ + Assets.Before.toLowerCase() + ' ' + Assets.ToDate.toLowerCase())
            return
        }
    
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {  
                if ((from ? from : Main.Instance.GGRRevenueState.dateFrom) === i) {
                    yearFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                    monthFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) 
                    dateFrom = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                }
                if ((to ? to : Main.Instance.GGRRevenueState.dateTo) === i) {
                    yearTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                    monthTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) 
                    dateTo = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                }
            }
        }
        isLocationGameReportsTest() ?  DbRoutes.getGameReportsTest(Main.Instance.GGRRevenueState.gameId, yearFrom, yearTo, monthFrom, monthTo, dateFrom, dateTo) : DbRoutes.getGameReportsReal(Main.Instance.GGRRevenueState.gameId, yearFrom, yearTo, monthFrom, monthTo, dateFrom, dateTo)
    }


    public isYesterday(): boolean {
        if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
            Main.Instance.GGRRevenueState.dateTo === -1) {
            return false
        }
        const now: Date = new Date()
       
        now.setDate(now.getDate() - 1)
        if (Main.Instance.GGRRevenueState.dateFrom === Main.Instance.GGRRevenueState.dateTo &&
            now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
        ) {
            return true
        }
        return false
    }
    
    public isBeforeYesterday(): boolean {
        if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
            Main.Instance.GGRRevenueState.dateTo === -1) {
            return false
        }
        const now: Date = new Date()
     
        now.setDate(now.getDate() - 2)
        if (Main.Instance.GGRRevenueState.dateFrom === Main.Instance.GGRRevenueState.dateTo &&
            now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
        ) {
            return true
        }
        return false
    }
    
    public isAnyDate(): boolean {
        if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
            Main.Instance.GGRRevenueState.dateTo === -1) {
            return true
        }
        return false
    }
    
    public getDateOptions(from: boolean): JSX.Element[] {
        const dateOptions: JSX.Element[] = []
        dateOptions.push (<option key = "-1" value = {-1}>{Assets.SelectDate}</option>)
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
                const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                if (from && (Main.Instance.GGRRevenueState.yearFrom === -1 || Main.Instance.GGRRevenueState.yearFrom === Year)
                    || !from && (Main.Instance.GGRRevenueState.yearTo === -1 || Main.Instance.GGRRevenueState.yearTo === Year)) {
                    const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
                    if (from && (Main.Instance.GGRRevenueState.monthFrom === -1 || Main.Instance.GGRRevenueState.monthFrom === Month)
                        || !from && (Main.Instance.GGRRevenueState.monthTo === -1 || Main.Instance.GGRRevenueState.monthTo === Month)) {
                        const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                        const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3
                        if (from && (Main.Instance.GGRRevenueState.monthFromPart === -1 || Main.Instance.GGRRevenueState.monthFromPart === MonthPart)
                        || !from && (Main.Instance.GGRRevenueState.monthToPart === -1 || Main.Instance.GGRRevenueState.monthToPart === MonthPart)) {
                            dateOptions.push (<option key = {i.toString()} value = {i}>{Main.Instance.DatabaseDataState.ggrReportsDates[i]}</option>)
                        }
                    }
                }
            }
        }
        return dateOptions
    }
    
    public getYearOptions(): JSX.Element[] {
        const yearOptions: JSX.Element[] = []
        const YearOptions: number[] = []
        yearOptions.push (<option key = "-1" value = {-1}>{Assets.Year}</option>)
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
                const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                let found: boolean = false
                for (const item of YearOptions) {
                    if (item === Year) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    YearOptions.push (Year)
                    yearOptions.push (<option key = {i.toString()} value = {Year}>{Year}</option>)
                }
            }
        }
    
        if (Main.Instance.GGRRevenueState.lastYear === undefined && YearOptions.length > 0) {
            Logic.Type.New (Main.Instance.GGRRevenueState, {lastYear: YearOptions[YearOptions.length - 1]})
        }
    
        return yearOptions
    }
    
    public getMonthOptions(from: boolean): JSX.Element[] {
        const monthOptions: JSX.Element[] = []
        const MonthOptions: number[] = []
        monthOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Month}</option>)
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
                const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                if (from && Main.Instance.GGRRevenueState.yearFrom !== -1 && Year !== Main.Instance.GGRRevenueState.yearFrom ||
                    !from && Main.Instance.GGRRevenueState.yearTo !== -1 && Year !== Main.Instance.GGRRevenueState.yearTo
                ) {continue}
                
                const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
                let found: boolean = false
                for (const item of MonthOptions) {
                    if (item === Month) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    MonthOptions.push (Month)
                    monthOptions.push (<option key = {i.toString()} value = {Month}>{Assets.Months[Month + 2]}</option>)
                }
            }
        }
        return monthOptions
    }
    
    public getMonthPartOptions(from: boolean): JSX.Element[] {
        const monthPartOptions: JSX.Element[] = []
        const MonthPartOptions: number[] = []
        monthPartOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Part}</option>)
        if (Main.Instance.DatabaseDataState.ggrReportsDates) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
                const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
                const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
                if (from && Main.Instance.GGRRevenueState.monthFrom !== Month || !from && Main.Instance.GGRRevenueState.monthTo !== Month) {
                    continue
                }
                const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3 
                let found: boolean = false
                for (const item of MonthPartOptions) {
                    if (item === MonthPart) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    MonthPartOptions.push (MonthPart)
                    if (MonthPart === 3) {
                        let lastDay: string = '30'
                        if (Month === 1) {
                            lastDay = '28'
                            if (Year % 4 === 0) {
                                lastDay = '29'
                                if (Year % 100 === 0) {
                                    lastDay = '28'
                                    if (Year % 400 === 0) {
                                        lastDay = '29'
                                    }    
                                }
                            }
    
                        } else if (Month === 0 || Month === 2|| Month === 4|| Month === 6|| Month === 7|| Month === 9|| Month === 11) {
                            lastDay = '31'
                        }
                        monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1].replace('?', lastDay)}</option>)
                    } else {
                        monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1]}</option>)
                    }
                }
            }
        }
        return monthPartOptions
    }

    public render() {
        return (
            <div>
                <div className = "clearfix">
                    <h4 className = "fl mr">{Assets.SelectTimePeriod}</h4>
                    <FontAwesomeIcon style = {{fontSize: '21px', marginRight: '5px', marginTop: '9px'}} className = "dbl fl" icon = {faCalendarDay}/>
                    <button
                        key = "dateyesterday"
                        onClick = {() => {
                            
                            if (!this.isYesterday()) {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: Main.Instance.DatabaseDataState.ggrReportsDates.length - 1,
                                    dateTo: Main.Instance.DatabaseDataState.ggrReportsDates.length - 1,
                                    monthFrom: -1,
                                    monthTo: -1,
                                    yearFrom: -1,
                                    yearTo: -1,
                                    
                                })
                                if (this.props.type === 1) {this.filterByDate()}
                                // this.getGameData(Main.Instance.DatabaseDataState.ggrReportsDates.length - 1, Main.Instance.DatabaseDataState.ggrReportsDates.length - 1)
                            } else {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: -1,
                                    dateTo: -1
                                })
                            }
                        }}
                        className = {this.isYesterday() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.Yesterday}
                    </button>
                    <button
                        key = "datebeforeyesterday"
                        onClick = {() => {
                            if (!this.isBeforeYesterday()) {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: Main.Instance.DatabaseDataState.ggrReportsDates.length - 2,
                                    dateTo: Main.Instance.DatabaseDataState.ggrReportsDates.length - 2,
                                    monthFrom: -1,
                                    monthTo: -1,
                                    yearFrom: -1,
                                    yearTo: -1,
                                    
                                })
                                if (this.props.type === 1) {this.filterByDate()}
                                // this.getGameData(Main.Instance.DatabaseDataState.ggrReportsDates.length - 2, Main.Instance.DatabaseDataState.ggrReportsDates.length - 2)
                            } else {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: -1,
                                    dateTo: -1
                                })
                            }
                        }}
                        className = {this.isBeforeYesterday() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.Day.toLowerCase() + ' ' + Assets.Before.toLowerCase() + ' ' + Assets.Yesterday}
                    </button>
                    <button
                        key = "dateany"
                        onClick = {() => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {
                                dateFrom: -1,
                                dateTo: -1
                            })
                        }}
                        className = {this.isAnyDate() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.AnyDate}
                    </button>
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Year}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.yearFrom}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {yearFrom: +e.target.value})
                        }
                    >
                        {this.getYearOptions()}
                    </select>

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthFrom}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthFrom: +e.target.value})
                        }
                    >
                        {this.getMonthOptions(true)}
                    </select>

                    {Main.Instance.GGRRevenueState.monthFrom > -1 ?
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthFromPart}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthFromPart: +e.target.value})
                        }
                    >
                        {this.getMonthPartOptions(true)}
                    </select>
                    : void 0}

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Date}
                        className = "fl dbl mt form-control"
                        value = {Main.Instance.GGRRevenueState.dateFrom}
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {dateFrom: +e.target.value})
                            // this.getGameData(+e.target.value)
                            if (this.props.type === 1) {this.filterByDate()}
                        }}
                    >
                        {this.getDateOptions(true)}
                    </select>
                    {/*<h4 className = "fl dbl mt">&nbsp;&nbsp;{Assets.To.toLowerCase()}&nbsp;&nbsp;</h4>*/}
                    <div className = "fl" style = {{marginLeft: '5px', marginRight: '5px', marginTop: '9px'}}>-</div>
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Year}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.yearTo}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {yearTo: +e.target.value})
                        }
                    >
                        {this.getYearOptions()}
                    </select>

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthTo}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthTo: +e.target.value})
                        }
                    >
                        {this.getMonthOptions(false)}
                    </select>
                    {Main.Instance.GGRRevenueState.monthTo > -1 ?
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthToPart}
                        onChange = {(e) =>
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthToPart: +e.target.value})
                        }
                    >
                        {this.getMonthPartOptions(false)}
                    </select>
                    : void 0}

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Date}
                        className = "fl dbl mt form-control"
                        value = {Main.Instance.GGRRevenueState.dateTo}
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {dateTo: +e.target.value})
                            if (Main.Instance.GGRRevenueState.dateFrom !== -1) {
                                // this.getGameData(undefined, +e.target.value)
                                if (this.props.type === 1) {this.filterByDate()}
                            }
                        }}
                    >
                        {this.getDateOptions(false)}
                    </select>
                    <>
                    <button className = "btn btn-default btn-sm" disabled = {!isUserReports() && !isLocalhost()} title = {Assets.Download + ' ' + Assets.CSV} onClick = {() => this.download(this.props.type)} type = "button" style = {{padding: '0px', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '4px', float: 'right'}}>
                        <FontAwesomeIcon icon = {faFileCsv}/>
                    </button>
                    {this.props.type === 1 ?
                    <button type = "button" disabled = {!isUserReports()  && !isLocalhost()} title = {Assets.Download + ' ' + Assets_.PDF} className = "btn btn-danger btn-sm" onClick = {() => this.createPDFDocument(this.props.type)} style = {{padding: '0px', color: '#d9534f', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '4px', float: 'right'}}>
                        <FontAwesomeIcon icon = {faFilePdf}/>
                    </button> : void 0}
                    </> 
                    {this.props.type === 2 || this.props.type === 3 ?
                    <button
                        // disabled = {!isUserReports()}
                        type = "button" 
                        // disabled = {Main.Instance.GGRRevenueState.dateFrom === -1 || Main.Instance.GGRRevenueState.dateTo === -1 || Main.Instance.GGRRevenueState.gameId === -1}
                        onClick = {() => this.getGameData()}
                        className = {'fr mr br0 btn btn-xs btn-primary'}
                        style = {{marginTop: '8px'}}
                    >
                    {Assets.Get + ' ' + Assets.Game.toLowerCase() + ' ' + Assets.Reports.toLowerCase()}
                    </button>
                    : void 0}
                    {this.props.type === 4 ?
                    <button
                        // disabled = {!isUserReports()}
                        type = "button" 
                        // disabled = {Main.Instance.GGRRevenueState.dateFrom === -1 || Main.Instance.GGRRevenueState.dateTo === -1 || Main.Instance.GGRRevenueState.gameId === -1}
                        onClick = {() => this.getPlayerData()} 
                        className = {'fr mr br0 btn btn-xs btn-primary'}
                        style = {{marginTop: '8px'}}
                    >
                    {Assets.Get + ' ' + Assets.Player.toLowerCase() + ' ' + Assets.Reports.toLowerCase()}
                    </button>
                    : void 0}
                    {/* console.log(Main.Instance.DatabaseDataState.gameReportsReal) */}
                </div>

            </div>
        ) 
    }
}