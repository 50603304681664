import * as React from 'react'
import {Main} from '../../Logic/Main'
import * as Assets from '../Assets'
import {/*CustomSecurityRights,*/ SecurityRights} from '../../Logic/UserManagement/UserManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IsLocalhost, isUserGamanzaMagicHand, isUserGamanzaGamesAdmin, isUserSystem, isUserReports, isUserAdmin, isUserSupport, isUserOperatorRole, isUserMarketing, isUserRFSpecialist, isUserRGSpecialist, isUserGDPR, isUserAccounting, isUserGamanzaAdmin, isUserGamanzaAdminFromLogin, isUserGamanzaMagicHandFromLogin} from '../../Logic/Utils'
import {faBuilding, faUserCircle} from '@fortawesome/free-solid-svg-icons'
import * as Assets_ from '../Assets_'
import {Logic} from '../../Logic/Type'
import * as DbRoutes from '../../Logic/Database/DbRoutes'
import { RouterObjects } from '../../Logic/RouterObjects'
import * as Config from '../../Logic/Config'

function toggleSecurityRight(which: SecurityRights, value: boolean) {
    if (IsLocalhost()) {
        if (isUserGamanzaMagicHandFromLogin) {
            if (which === SecurityRights.GAMANZA_MAGIC_HAND) {
                Logic.Type.New (Main.Instance.LoginState, {unused7: !value})
            }
        }

        if (isUserGamanzaAdminFromLogin()) {
            if (which === SecurityRights.GAMANZA_GAMES_ADMIN) {
                Logic.Type.New (Main.Instance.LoginState, {unused2: !value})
            } else if (which === SecurityRights.GAMANZA_ADMIN) {
                Logic.Type.New (Main.Instance.LoginState, {unused1: !value})
            } else if (which === SecurityRights.GDPR) {
                Logic.Type.New (Main.Instance.LoginState, {gdpr: !value})
            } else if (which === SecurityRights.OPERATOR) {
                Logic.Type.New (Main.Instance.LoginState, {operator: !value})
            } else if (which === SecurityRights.ADMINISTRATOR) {
                Logic.Type.New (Main.Instance.LoginState, {administrator: !value})
            } else if (which === SecurityRights.MARKETING) {
                Logic.Type.New (Main.Instance.LoginState, {marketing: !value})
            } else if (which === SecurityRights.RFSPECIALIST) {
                Logic.Type.New (Main.Instance.LoginState, {rfSpecialist: !value})
            } else if (which === SecurityRights.RGSPECIALIST) {
                Logic.Type.New (Main.Instance.LoginState, {rgSpecialist: !value})
            } else if (which === SecurityRights.SUPPORT) {
                Logic.Type.New (Main.Instance.LoginState, {support: !value})
            } else if (which === SecurityRights.ACCOUNTING) {
                Logic.Type.New (Main.Instance.LoginState, {accounting: !value})
            } else if (which === SecurityRights.REPORTS) {
                Logic.Type.New (Main.Instance.LoginState, {unused3: !value})
            }
        }
    }
}

function getSecurityRights(): JSX.Element[] {
    const securityRights: JSX.Element[] = []

    for (let i: number = 0; i < Object.keys(SecurityRights).length / 2; i++) {
        if (!isUserGamanzaAdminFromLogin() && (i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN)) {continue}
        if (!isUserGamanzaMagicHandFromLogin() && i === SecurityRights.GAMANZA_MAGIC_HAND) {continue}
        const enabled: boolean = (
            i === SecurityRights.ADMINISTRATOR && isUserAdmin()
            || i === SecurityRights.SUPPORT && isUserSupport()
            || i === SecurityRights.OPERATOR && isUserOperatorRole()
            || i === SecurityRights.MARKETING && isUserMarketing()
            || i === SecurityRights.RFSPECIALIST && isUserRFSpecialist()
            || i === SecurityRights.RGSPECIALIST && isUserRGSpecialist()
            || i === SecurityRights.GDPR && isUserGDPR()
            || i === SecurityRights.ACCOUNTING && isUserAccounting()
            || i === SecurityRights.REPORTS && isUserReports()
            || i === SecurityRights.GAMANZA_ADMIN && isUserGamanzaAdmin()
            || i === SecurityRights.GAMANZA_GAMES_ADMIN && isUserGamanzaGamesAdmin()
            || i === SecurityRights.GAMANZA_MAGIC_HAND && isUserGamanzaMagicHand()
        )
        securityRights.push(
            <button
                type = "button"
                // onClick = {() => {}}
                onClick = {() => toggleSecurityRight(i, enabled)}
                key = {i.toString()}
                title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[i] + ' ' + (enabled ? Assets.Enabled : Assets.Disabled)}
                style = {{
                    marginLeft: i === SecurityRights.ADMINISTRATOR ? '0px' : '0px',
                    cursor: IsLocalhost() ? 'cursor' : 'default',
                    color: enabled ? i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN || i === SecurityRights.GAMANZA_MAGIC_HAND ?
                           '#f38b00' : '#5cb85c' : '#d9534f',
                    lineHeight: '20px',
                    fontSize: '14px',
                    fontWeight: 700,
                    width: '20px',
                    // borderRadius: '0px',
                    padding: '0px',
                    // marginRight: i === Object.keys(SecurityRights).length / 2 - 1 ? '10px' : '0px',
                    textAlign: 'center',
                    background: 'transparent',
                    border: '0px',
                    // marginTop: '-4px'
                }}
                className = "btn btn-default btn-xs dbl fl mr0 br0"
            >
                <FontAwesomeIcon icon = {Assets_.SecurityRightsIcons[i]}/>
            </button>,
        )
    }
    return securityRights
}

export function DisplayLoginEntity() {
    return (
        <div>
            <div className = "clearfix mb2">{getSecurityRights()}</div>
            <div className = "clearfix">
                <a
                    style = {{color: Main.Instance.Location === RouterObjects['userSettings'].path ? 'rgb(243,139,0)' : ''}}
                    onClick = {() => {
                        DbRoutes.insertAdminLog (
                            Config.asAdminLogView,
                            Config.asAdminLogUserSettings, 
                            {},
                        )
                        Logic.Type.New(Main.Instance, {Location: RouterObjects['userSettings'].path})}                    
                    }
                    title = {Assets.UserSettings}
                    className = "fl dbl usercircle"
                >
                    <FontAwesomeIcon icon = {faUserCircle} size = "lg"/>&nbsp;
                </a>
                <div title = {Assets.loggedInAs} className = "fl bold white">{Main.Instance.LoginState.Username}&nbsp;&nbsp;&nbsp;</div>

                <div className = "fl company">
                    <span title = {Assets.Entity} className = "white">
                        <FontAwesomeIcon icon = {faBuilding}/>&nbsp;&nbsp;
                    </span>
                    <span title = {Assets.Entity} className = "bold white">
                        {isUserSystem() ? Assets_.Gamanza : Main.Instance.LoginState.entity}
                    </span>
                </div>
                <div title = {Main.Instance.DatabaseDataState.strapiConnected === true ? Assets.Strapi + ' ' + Assets.Loaded.toLowerCase() : Main.Instance.DatabaseDataState.strapiConnected === false ? Assets.Strapi + ' ' + Assets.Failed.toLowerCase() + Assets._to_ + Assets.Load.toLowerCase() : Assets.Strapi + ' ' + Assets.NotLoaded.toLowerCase()} style = {{position: 'relative', marginRight: '18px', marginLeft: '3px'}} className = "fl">{Assets_.strapi(Main.Instance.DatabaseDataState.strapiConnected === true ? '#5cb85c' : Main.Instance.DatabaseDataState.strapiConnected === false ? '#d9534f' : '#fff', 16, 0, 4)}</div>
            </div>
        </div>
    )
}
