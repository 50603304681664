import * as React from 'react'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import { Main } from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { Logic } from '../../../Logic/Type'
import * as Assets_ from '../../Assets_'
import {FormInputNumber} from '../../Reusables/Inputs'


export function getPaginatedData(pNum?: number, pSize?: number, player?) {
    let found: boolean = false
    const search = player || Main.Instance.UsersInfoState.search_string ? player ? player.toString() : Main.Instance.UsersInfoState.search_string.toString() : ''
    let playerId: string
    const data = Object.assign([], Main.Instance.DatabaseDataState.usersInfoFiltered)
    data.sort((a, b) => (a.username > b.username) ? 1 : -1)
    if (search && search.length >= Main.Instance.SearchState.minLengthToSearch) {
        for (let i: number = 0; i < data.length; i++) {
            if (Main.Instance.UsersInfoState.username) {
                const position: number = data[i].username.toLowerCase().indexOf(search.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        found = true
                        playerId = data[i].playerId
                        break
                    }
                } else {
                    if (position !== -1) {
                        found = true
                        playerId = data[i].playerId
                        break
                    }
                }
            }

            if (playerId === undefined && Main.Instance.UsersInfoState.playerId) {
                const position: number = data[i].playerId.toLowerCase().indexOf(search.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        found = true
                        playerId = data[i].playerId
                        break
                    }
                } else {
                    if (position !== -1) {
                        found = true
                        playerId = data[i].playerId
                        break
                    }
                }
            }

            
        }
    }
    Logic.Type.New(Main.Instance.StorePurchasesState, {playerFound: found, id: playerId})
    if (playerId) {
        DbRoutes.getStorePurchases(pSize ? +pSize : +Main.Instance.StorePurchasesState.pSize, pNum ? +pNum : +Main.Instance.StorePurchasesState.pNum, playerId)
    } else {
        DbRoutes.getStorePurchases(pSize ? +pSize : +Main.Instance.StorePurchasesState.pSize, pNum ? +pNum : +Main.Instance.StorePurchasesState.pNum)  
    }

    
}

export function FirstPage() {
    Logic.Type.New(Main.Instance.StorePurchasesState, {pNum: 1})
    getPaginatedData(1)
}

export function PrevPage() {
    if (Main.Instance.StorePurchasesState.pNum > 1) {
        const page = Main.Instance.StorePurchasesState.pNum - 1
        getPaginatedData(page)
        Logic.Type.New(Main.Instance.StorePurchasesState, {pNum: page})  
    } else {
        LastPage()
    }
}

export function NextPage() {

    if ((Main.Instance.StorePurchasesState.pNum) * Main.Instance.StorePurchasesState.pSize < Main.Instance.DatabaseDataState.storePurchasesCount) {
        const page = Main.Instance.StorePurchasesState.pNum + 1
        getPaginatedData(page)
        Logic.Type.New(Main.Instance.StorePurchasesState, {pNum: page})
    } else {
        FirstPage()
    }   
}

export function LastPage() {
    if (Main.Instance.DatabaseDataState.storePurchasesCount <= Main.Instance.StorePurchasesState.pSize) {
        FirstPage()
    } else {
        const pNum = Math.trunc((Main.Instance.DatabaseDataState.storePurchasesCount - 1) / Main.Instance.StorePurchasesState.pSize) + 1
        Logic.Type.New(Main.Instance.StorePurchasesState, {pNum})
        getPaginatedData(pNum)
    }
}

export function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.StorePurchasesState, {pSize: parseInt(text), pNum: 1})
        getPaginatedData(1,parseInt(text))
    }
}

export function StorePurchases(props: React.Props<any>): JSX.Element {

    return (      
        <div>
            <div style = {{marginBottom: '20px'}}>
                <h3>
                    {Assets.Store + ' ' + Assets.Purchases}
                </h3>
            </div>
            <div>
                <div className = "control-panel">
                    <FormInputNumber
                        description = {Assets.recordsPerPageDesc}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h26 w60"
                        value = {!Main.Instance.StorePurchasesState.pSize ? '' : Main.Instance.StorePurchasesState.pSize.toString()}  
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHamburgerClass}
                        onChange = {(text) => {
                            RecordsPerPageChanged(text)
                        }}
                    />

                    <button 
                        className = "btn btn-primary btn-xs" 
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage()}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs" 
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage()}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage()}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage()}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>
        
                    <span className = "label label-success table-label">
                        {Assets.records}
                        {Main.Instance.DatabaseDataState.storePurchasesCount ? Main.Instance.DatabaseDataState.storePurchasesCount : 0}
                    </span>
                    <span className = "label label-success table-label">
                        {Assets.showing}
                        {Main.Instance.DatabaseDataState.storePurchasesCount ? (Main.Instance.StorePurchasesState.pNum - 1) * Main.Instance.StorePurchasesState.pSize + 1 : 0}
                        {' - '}
                        {Main.Instance.DatabaseDataState.storePurchasesCount ? ((Main.Instance.StorePurchasesState.pNum) * Main.Instance.StorePurchasesState.pSize > Main.Instance.DatabaseDataState.storePurchasesCount ? 
                        Main.Instance.DatabaseDataState.storePurchasesCount : (Main.Instance.StorePurchasesState.pNum) * Main.Instance.StorePurchasesState.pSize) : 0}
                    </span>
                    <span className = "label label-success table-label">
                        {Assets.page}
                        {Main.Instance.DatabaseDataState.storePurchasesCount ? Main.Instance.StorePurchasesState.pNum : 0}
                        {' / '}
                        {Main.Instance.DatabaseDataState.storePurchasesCount ? Math.trunc((Main.Instance.DatabaseDataState.storePurchasesCount - 1) / Main.Instance.StorePurchasesState.pSize + 1) : 0}
                    </span>
                    
                </div>
                <div className = "clearfix">
                <br/>
                    
                    {Main.Instance.StorePurchasesState.playerFound && Main.Instance.DatabaseDataState.storePurchasesSelectedPlayerUsername ? 
                    <h4>
                        {Assets.Selected + ' ' + Assets.PlayerId + ': ' + (Main.Instance.StorePurchasesState.id ? Main.Instance.StorePurchasesState.id : Main.Instance.SearchState.search_string)}&nbsp;&nbsp;&nbsp;
                        {Assets.Selected + ' ' + Assets.Player + ' ' + Assets.Username.toLowerCase() + ': ' + Main.Instance.DatabaseDataState.storePurchasesSelectedPlayerUsername}
                    </h4> : void 0}
                </div>
                <br/>
                <div>
                    {Main.Instance.DatabaseDataState.storePurchases ?
                    <ReactTable
                        data = {Main.Instance.DatabaseDataState.storePurchases}
                        columns = {[
                            { width: 100, headerClassName:'th-left risk-default-light', Header: Assets.Purchase + ' ' + Assets.ID, accessor: 'storePurchaseId'},
                            {show: !Main.Instance.StorePurchasesState.playerFound, width: 150, headerClassName:'th-left risk-default', Header: Assets.PlayerID, accessor: 'playerId'},
                            {show: !Main.Instance.StorePurchasesState.playerFound, width: 250, Header: Assets.Username, accessor: 'username', headerClassName: 'th-left risk-default-light' },
                            {Header: Assets.Reward, accessor: 'name', headerClassName: 'th-left risk-default'},
                            {width: 150, Header: Assets.Date + ' ' + Assets.Received.toLowerCase(), accessor: 'dateReceived', headerClassName: 'th-left risk-default-light', Cell: (row) => row.value.toString().substring(0,10)},
                            {width: 150, Header: Assets.Time + ' ' + Assets.Received.toLowerCase(), accessor: 'dateReceived', headerClassName: 'th-left risk-default', Cell: (row) => row.value.toString().substring(11,19)},

                        ]}
                        showPaginationBottom = {false}
                        pageSize = {Main.Instance.StorePurchasesState.pSize}

                    /> : void 0}
                </div>
            </div>
        </div>
    )

}