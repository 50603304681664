
import {Logic} from '../Type'
import {/*Bonus,*/ GameProviderNew, /*RgsGameSupplierNew, BonusCampaign,*/ GamesList} from '../Database/DatabaseData'
// import * as moment from 'moment'
import * as Assets_ from '../../Views/Assets_'
export class GameStoreState extends Logic.Type {
    public files: File[] = []
    public assets: File[] = []
    public gameName: string
    public version: string
    public lines: string
    public reels: string
    public payout: string
    public progressFiles: number = 0
    public progressAssets: number = 0
    public gamesPaths = []
    // selectedGames: boolean[] = []
    public selectedView: string
    public mnGamesSelected: string
    public gameId: string
    public provider: string
    public assetsProvider: string
    public url: string
    public assetsUrl: string
    public uassetsopen: boolean = false
    public ufilesopen: boolean = false
    public countCalled: boolean = false
    public gfd:boolean = false
    public gap_id:string
    public first_time: boolean = true
}

export class GameTestState extends Logic.Type {
    public frameWidth:string|number = window.innerWidth - 300
    public frameHeight:string|number = window.innerHeight - 200
    public selectedGame:string
}

export class AssignGamesState extends Logic.Type {
    public gamesToSite = []
    public gamesToSite2: Array<{sitename: string, games: string[]}>
    public siteOpen: string
}

/*
export class BonusCampaignState extends Logic.Type {
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public bonusCampaign: BonusCampaign
    public bonusCampaign_: string
    public name_saved: string = ''
    public focusedInputStart: any = null
    public focusedInputEnd: any = null
}


export class BonusState extends Logic.Type {
    // countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public bonus: Bonus
    public bonus_: string
    public name_saved: string = ''
    public selectedGames: boolean[] = []
}
*/


export class GameProviderState extends Logic.Type {
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public gameProvider: GameProviderNew
    public gameProvider_: string
    public name_saved: string
    public category: string
    public rgsgs: number
    public active: boolean
}
/*
export class RgsGameSupplierState extends Logic.Type {
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public rgsGameSupplier: RgsGameSupplierNew
    public rgsGameSupplier_: string
    public name_saved: string
}
*/
export class GamesState extends Logic.Type {
    public showAddGameModal: boolean = false
    public approvedJurisdictions: boolean = false
    public activeJurisdictions: boolean = false
    // providers: {id: number, name: string}[]
    public selectedGameId: number = 0
    public selectedGameIndex: number
    public selectedGameLabels: string[] | number[]
    public selectedGameName: string
    public selectedGameDescriptionLong: any
    public selectedGameDescriptionShort: any
    public selectedGameImage: string = Assets_.zeroPng
    public selectedGameImageWidth: number
    public selectedGameEnabled: boolean
    public selectedGameActive: boolean
    public selectedGameApproved: boolean
    public selectedGameLanguage: number = 0
    public selectedGameLanguageLong: boolean
    public filterProvider: number = 0
    public filterProviderCategory: string = '0'
    public filterCategory: number = 0
    public FilterCategory: number = -1
    public filterJurisdiction: string = '0'
    public filterCooloffCategory: number = 0
    public filterTags: string[]
    // filterSubcategory: number = 0
    // FilterProvider: number = 0
    // FilterCategory: number = 0
    // FilterSubcategory: number = 0
    // togglePlusMinusFilter: boolean = true
    // we have three states here, undefined, true and false
    // public filterExisting: boolean = true
    public filterTestGame: number = 0
    public filterApproved: boolean = true
    public filterActive: number = 0
    public filterLaunchId: boolean
    // public filterJackpot: boolean
    // public filterProgressiveJackpot: boolean
    // public filterFreeGames: boolean
    // public filterWildSymbol: boolean
    // public filterScatterSymbol: boolean
    public toggleNameLink: boolean = false
    public toggleThumbnailMobile: boolean = false
    public toggleThumbnailDesktop: boolean = false
    public toggleBackgroundMobile: boolean = false
    public toggleBackgroundDesktop: boolean = false
    public details: boolean = false
    public elementsView: boolean = false
    public jurisdictions: boolean = false
    public devices: boolean = false
    public launch: boolean = false
    // CERTT
    // public filterCertified: boolean
    public shortDescription: number = 0
    public longDescription: number = 0

    public filterJurisdictions: number = 0 // new
    public search_string: string
    public confirmActivatePopup: boolean = false
    public selectedGame: GamesList
    public categoryLabelType: number
}