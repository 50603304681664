import * as React from 'react'
import * as Assets from '../../Assets'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload, faCheck, faTimes, faDownload, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import * as base64 from 'base-64'
import ReactTable from 'react-table'
import { Main } from 'src/Logic/Main'
import {ButtonIcon} from '../../Reusables/Button'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {IUploadedDeposits} from '../../../Logic/Database/DatabaseData'
import { Logic } from 'src/Logic/Type';

interface State {
    toggleInfo: boolean
}

export class MassManualDeposit extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            toggleInfo: false
        }
        
        // DbRoutes.wsReinitialize()
    }

    public getBoolean(str: string): boolean {
        if (str === 'true' || str === 'yes') {
            return true
        } else {
            return false
        }
    }

    public getTotalDeposits(): number {
        let sum: number = 0
        if (Main.Instance.DatabaseDataState.uploadedMassManualDeposits && Main.Instance.DatabaseDataState.uploadedMassManualDeposits.length > 0) {
            for (const item of Main.Instance.DatabaseDataState.uploadedMassManualDeposits) {
                sum += item.amount
            }
        }
        return sum
    }

    public getPlayerId(username: string): string {
        let playerId: string = ''
        if (Main.Instance.DatabaseDataState.playerCards) {
            for (const item of Main.Instance.DatabaseDataState.playerCards) {
                if (item.username === username) {
                    playerId = item.playerId
                    break
                }
            }
        }
        return playerId
    }

    public executeMassManualDeposit() {

        for (const item of Main.Instance.DatabaseDataState.uploadedMassManualDeposits) {

            if (item.playerId === '') {
                alert(Assets.Missing + ' ' + Assets.PlayerId.toLowerCase() + '. ' + Assets.Please + ' ' + Assets.Check.toLowerCase() + ' ' + Assets.Players.toLowerCase() + ' ' + Assets.Username.toLowerCase())
                return
            }
            if (!item.amount) {
                alert(Assets.Missing + ' ' + Assets.Amount.toLowerCase() + '.')
                return
            }  
        }
        DbRoutes.wsInsertMassManualDeposit() 
        Logic.Type.New(Main.Instance.DatabaseDataState, {isInsertingMassManualUploads: true})

        /*

        poklicat insert manual deposit za prvi element v statu
        hkrati moras nastavit, da pricakujes da ti route mora nekaj vrnit

        ko pricakujes da ti route nekaj vrne,potem to ujames, povecas counter za 1 in ponovis vajo, dokler imas kaj za posiljat
        */

        // // for (const item of Main.Instance.DatabaseDataState.uploadedMassManualDeposits) {
        // //     this.insertManualDeposit(item)
        // // }
    }

    // public insertManualDeposit(item) {
    //     if (Main.Instance.DatabaseDataState.ManualDepositInsert === true) {
    //         setTimeout(() => this.insertManualDeposit(item), 200)
    //     } else {
    //         DbRoutes.wsInsertMassManualDeposit(item.playerId, item.amount) 
    //         return
    //     }
    // }

    public render() {
        return (
        <div >
            {Main.Instance.DatabaseDataState.isInsertingMassManualUploads ? 
            <div className = "centered message">
                {Assets.PleaseWaitThisOperationWillTakeSomeTime}
            </div> : void 0}
            <h3>{Assets.Mass + ' ' + Assets.ManualDeposit}</h3>
            <button 
                className = "dbl fr btn btn-default blackthick"
                style = {{border: 'none'}}
                title = {Assets.Mass + ' ' + Assets.ManualDeposit + ' ' + Assets.Info} 
                onClick = {() => {
                    const toggleInfo = !this.state.toggleInfo
                    this.setState({toggleInfo})
                }}
            >
                <FontAwesomeIcon icon = {faInfoCircle}/>
            </button>
            {this.state.toggleInfo ?
            <div style = {{height: 'fit-content', padding: '10px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'absolute', top: '150px', bottom: '150px', left: '400px', right: '400px'}}>
                <button
                    title={Assets.Close}
                    onClick={() => this.setState({toggleInfo: !this.state.toggleInfo})}
                    style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                    className="btn btn-default btn-sm btn-plus"
                >
                    <FontAwesomeIcon icon = {faTimes}/>
                </button>
                <p style = {{margin: '10px', fontSize: '16px'}}>
                How to use the mass manual deposit functionality: <br/><br/>
                Required file type: *.txt<br/>
                Structure of the file:<br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;Each deposit needs to be in a separated row. <br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;The structure of the file row: username, amount, comment, bypass risk <br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;The values need to be separated with a comma. <br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;If you want to leave a value empty you still need to insert commas.<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Example: PlayerUsername,50,,yes<br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;The player username and amount are mandatory. <br/>
                &nbsp;&nbsp;-&nbsp;&nbsp;Bypass risk: The accepted values are 'yes' and 'no'. If no value is inserted the value is 'no'<br/>  
                </p>
            </div> : void 0}
            <div className = "clearfix">
                <div
                    title = {Assets.Upload + ' ' + Assets.List.toLowerCase()}
                    style = {{padding: '8px', fontSize: '14px'}}
                    className = "fl label label-primary mr"
                >
                    <FontAwesomeIcon icon = {faUpload}/>
                </div>
                <input
                    accept = "text/plain"
                    type = "file"
                    key = "upload"
                    className = "uploadLogoWrapper dbl fl"
                    style = {{width: '200px', height: '30px', marginTop: '0px', marginRight: '5px'}}
                    onClick = {(event) => event.target = null}
                    onChange = {(event) => {
                        console.log(event.target.files)
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            const decodedData: string = base64.decode((reader.result as string).replace('data:text/plain;base64,',''))
                            const DecodedData: string[] = decodedData.split('\n')
                            const deposits: string[] = []
                            for (const item of DecodedData) {
                                if (item.length > 0) {
                                    deposits.push(item.replace('\r', ''))
                                }
                            }
                            
                            const arr: IUploadedDeposits[] = []
                            const date: Date = new Date()
                            for (let i: number = 0; i < deposits.length; i++) {
                                const part = deposits[i].split(',')
                                arr.push(
                                    {
                                        playerId: this.getPlayerId(part[0]),
                                        username: part[0],
                                        amount: part[1] ? parseInt(part[1]) : undefined,
                                        comment: part[2] ? part[2] + ' (' + Assets.From + ' ' + Assets.Mass.toLowerCase() + ' ' + Assets.Upload.toLowerCase() + Assets._on_  + date + ')' : ' (' + Assets.From + ' ' + Assets.Mass.toLowerCase() + ' ' + Assets.Upload.toLowerCase() + Assets._on_  + date + ')',
                                        bypassRisk: part[3] ? this.getBoolean(part[3]) : this.getBoolean('false')
                                    }
                                )
                                
                            }
                            Logic.Type.New(Main.Instance.DatabaseDataState, {uploadedMassManualDeposits: arr, insertedMassManualDeposits: [], countMassManualDeposits: 0})
                        }
                        if (cloneFile && ('text/plain').indexOf(cloneFile.type) > -1) {
                            reader.readAsDataURL(cloneFile);
                        }
                    }}
                />
            </div>
            
            <br/>
            {Main.Instance.DatabaseDataState.uploadedMassManualDeposits && Main.Instance.DatabaseDataState.uploadedMassManualDeposits.length > 0 ?
            <div className = "clearfix">
                <ReactTable
                data = {Main.Instance.DatabaseDataState.uploadedMassManualDeposits}
                columns = {[
                    {width: 300, Header: Assets.PlayerID, accessor: 'playerId', headerClassName: 'th-left', Footer: Assets.Number + Assets._of_ + Assets.Deposits.toLowerCase() + ': ' + Main.Instance.DatabaseDataState.uploadedMassManualDeposits.length},
                    {width: 150, Header: Assets.Username, accessor: 'username', headerClassName: 'th-left'},
                    {width: 150, Header: Assets.DepositAmount, accessor: 'amount', headerClassName: 'th-left', Footer: Assets.Total + ' ' + Assets.Amount.toLowerCase() + ': ' + this.getTotalDeposits()},
                    {Header: Assets.Comment, accessor: 'comment', headerClassName: 'th-left', Cell: (row) => <span title={row.value}>{row.value}</span>},
                    {width: 90, Header: Assets.Bypass + ' ' + Assets.Risk.toLowerCase(), accessor: 'bypassRisk', headerClassName: 'th-left', className: 'th-center', Cell: (row) => <FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/>},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize={10}
                />
                <br/>
                <ButtonIcon 
                    description = {Assets.Execute + Assets._all_ + Assets.Manual.toLowerCase() + ' ' + Assets.Deposits.toLowerCase()} 
                    click = {() => this.executeMassManualDeposit()}
                    ClassName = {'btn btn-danger btn-sm btn-plus fl'}
                    Icon = {faDownload as IconDefinition}
                    text = {Assets.Execute + Assets._all_ + Assets.Manual.toLowerCase() + ' ' + Assets.Deposits.toLowerCase()}
                    disabled = {Main.Instance.DatabaseDataState.isInsertingMassManualUploads}
                />
            </div>
            
            : void 0}
            {Main.Instance.DatabaseDataState.insertedMassManualDeposits && Main.Instance.DatabaseDataState.insertedMassManualDeposits.length > 0 ? 
                <ReactTable
                data = {Main.Instance.DatabaseDataState.insertedMassManualDeposits}
                columns = {[
                    {Header: Assets.PlayerID, accessor: 'playerId', headerClassName: 'th-left', Footer: Assets.Number + Assets._of_ + Assets.Completed.toLowerCase() + ' ' + Assets.Deposits.toLowerCase() + ': ' + Main.Instance.DatabaseDataState.insertedMassManualDeposits.length},
                    {width: 250, Header: Assets.Username, accessor: 'username', headerClassName: 'th-left'},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize={10}
                />

            : void 0}
        </div>
        )
    }
}

