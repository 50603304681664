import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {SelectedGamesSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
// import Switch from 'react-toggle-switch'
import {/*isUserAdmin, */injectSpaces,/*, isUserRFSpecialist, isUserRGSpecialist*/capitalizeFirstLetter} from '../../../Logic/Utils'
import {faSave, /*faPlus,*/ faDesktop, faMobileAlt, /*faTabletAlt, faTrashAlt*/} from '@fortawesome/free-solid-svg-icons'
// import ReactTable from 'react-table'
// import * as TableUtils from '../../Reusables/TableUtils'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
// import {InsideTitle} from '../RiskComplianceStore/RiskManagement'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Custom, /*GamesListCategory, GamesListLabel, Tag*/} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
// import {getStaticItems} from './PlayerSettings'
// import {isUserGamanzaGamesAdmin} from '../../../Logic/Utils'
// import {Icon, InlineIcon } from '@iconify/react'
// import strapiIcon from '@iconify/react/logos/strapi'
// import {riskLevelSlider} from '../RiskComplianceStore/ResponsibleGaming'
/*
function handleNumber(id: number, text: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) custom.responsibleGaming.selfAssessment.answersScale.min = +text
    else if (id === 2) custom.responsibleGaming.selfAssessment.answersScale.max = +text
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/
function selectNavigation(selectedGamesSetting: SelectedGamesSetting) {
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedGamesSetting})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
        for (let i: number = 0; i < Object.keys(SelectedGamesSetting).length / 2; i++) {
            if (i === SelectedGamesSetting.SupportedDevices && !(Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general)) {
                continue
            }
            navigation.push(
                <button
                    // title = {i === SelectedGamesSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGamesSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                    // disabled = {i === SelectedGamesSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGamesSetting.ResponsibleGaming && !isUserRGSpecialist()}
                    onClick = {() => selectNavigation(i)}
                    type = "button"
                    key = {i.toString()}
                    style = {{
                        marginRight: i === Object.keys(SelectedGamesSetting).length / 2 - 1 ? '0px' : '',
                        display: 'block',
                        float: 'left',
                    }}
                    className = {i === Main.Instance.RiskManagementState.selectedGamesSetting ? 'btn btn-primary btn-sm pr' : 'btn btn-default btn-sm pr'}
                >
                    {/*i === SelectedGamesSetting.GameLabelsStrapiGameStore ?
                        <>{Assets_.strapi(Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.GameLabelsStrapiGameStore ? '#fff' : '#333', 19,  5, 9)}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></> :
                        <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedGamesSettingIcons[i]}/>
                    */}
                    <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedGamesSettingIcons[i]}/>
                    {injectSpaces(SelectedGamesSetting[i])}
                </button>)
        }
    }

    navigation.push(
        <button 
            disabled = {true}
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}

export function GamesSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
/*
    if (Main.Instance.DatabaseDataState.gameLabel_ === undefined && Main.Instance.DatabaseDataState.tags) {
        const gameLabel_: string = JSON.stringify(Main.Instance.DatabaseDataState.tags)
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel_})
    }
*/
    if (Main.Instance.DatabaseDataState.category_ === undefined && Main.Instance.DatabaseDataState.gamesListCategories) {
        const category_: string[] = []
        for (const {} of Main.Instance.DatabaseDataState.gamesListCategories) {
            category_.push(undefined)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
     }
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.GamesSettings}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            {/*Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?*/}
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {/*InsideTitle(undefined, injectSpaces(SelectedGamesSetting[Main.Instance.RiskManagementState.selectedGamesSetting]), Assets.SelectedGamesSettingIcons[Main.Instance.RiskManagementState.selectedGamesSetting])*/}
                        {/*<br/>*/}

                        {Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.GameCategories ?
                        <>
                            <div>
                                <h4>{Assets.GamesCanBeCategorizedInTheFollowingCategories}:</h4>
                                {getCategories()}
                            </div>
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.GameLabels ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeGivenOneOrMoreLabelsBelow}:</h4>
                                {getLabelsCentral()}
                            </div>
                        </> : void 0}


                        {/*Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.GameLabelsStrapiGameStore ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeGivenOneOrMoreLabelsBelow}:</h4>
                                {getLabelsClient()}
                            </div>
                        </> : void 0*/}

                        {/* {Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.Jurisdictions ?
                            <>
                                <h4>{Assets.AllowedJurisdictions}</h4>
                                <div className="clearfix">{getStaticItems(3, Main.Instance.DatabaseDataState.jurisdictionsList)}</div>
                            </> : void 0} */}
                        {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?
                        Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.SupportedDevices ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeSupportedonOneOrMoreDevicesBelow}:</h4>
                                {getDevices()}
                            </div>
                        </> : void 0 : void 0}

                        {/*Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.LimitTypes ?
                        <>
                              <div>
                                {getItems(6, Main.Instance.DatabaseDataState.custom.general.limitTypes)}
                            </div>
                        </> : void 0*/}
                    </div>
                </div>
            </div> {/*: void 0*/}
        </div>
    )
}

export function ToggleSwitch(i: number, j: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    const what: string = j === 1 ? 'isEmailVerified' : j === 2 ? 'isDocumentVerified' : j === 3 ? 'isUtilityBillVerified' : j === 4 ? 'isSuperVerified' :
        j === 5 ? 'sentUser' : j === 6 ? 'viewUser' : j === 7 ? 'modifyUser' : j === 8 ? 'viewAdmin' : j === 9 ? 'modifyAdmin' : ''

    if (j < 5) {
        if (custom.general.players.accountStatus[i].requirements && custom.general.players.accountStatus[i].requirements[what]) {
            delete (custom.general.players.accountStatus[i].requirements[what])
        } else {
            if (custom.general.players.accountStatus[i].requirements === undefined) {
                custom.general.players.accountStatus[i].requirements = {}
            }
            custom.general.players.accountStatus[i].requirements[what] = true
        }
    } else {
        if (custom.general.players.playerCard[i].flags && custom.general.players.playerCard[i].flags[what]) {
            delete (custom.general.players.playerCard[i].flags[what])
        } else {
            if (custom.general.players.playerCard[i].flags === undefined) {
                custom.general.players.playerCard[i].flags = {}
            }
            custom.general.players.playerCard[i].flags[what] = true
        }
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}

function getDevices(): JSX.Element[] {
    const devices: JSX.Element[] = []
    // if (Main.Instance.DatabaseDataState.custom.games.devices) {
    //     // console.log (Main.Instance.DatabaseDataState.custom.games.labels)
    //     for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.games.devices.length; i++) {
    //         devices.push(<button disabled = {!isUserGamanzaGamesAdmin()} style = {{cursor: 'default', marginBottom: '2px'}} key = {i.toString()} title = {Main.Instance.DatabaseDataState.custom.games.devices[i] === Assets.VideoLotteryTerminalAbbrev ? Assets.VideoLotteryTerminal : capitalizeFirstLetter(Main.Instance.DatabaseDataState.custom.games.devices[i])} className = "btn btn-xs btn-info">{Main.Instance.DatabaseDataState.custom.games.devices[i] === 'mobile' || Main.Instance.DatabaseDataState.custom.games.devices[i] === 'desktop' || Main.Instance.DatabaseDataState.custom.games.devices[i] === 'tablet' ? <FontAwesomeIcon style = {{marginRight: '5px'}} icon = {Main.Instance.DatabaseDataState.custom.games.devices[i] === 'mobile' ? faMobileAlt : Main.Instance.DatabaseDataState.custom.games.devices[i] === 'desktop' ? faDesktop : Main.Instance.DatabaseDataState.custom.games.devices[i] === 'tablet' ? faTabletAlt : faTabletAlt}/> : void 0}{Main.Instance.DatabaseDataState.custom.games.devices[i]}</button>)
    //     }
    // }
    if (Main.Instance.DatabaseDataState.devices) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.devices.length; i++) {
            devices.push(
            <button 
            disabled = {true} 
            style = {{cursor: 'default', marginBottom: '2px'}} 
            key = {i.toString()} 
            title = {capitalizeFirstLetter(Main.Instance.DatabaseDataState.devices[i].device)} 
            className = "btn btn-xs btn-info"
            >
                {Main.Instance.DatabaseDataState.devices[i].deviceId === 1 || Main.Instance.DatabaseDataState.devices[i].deviceId === 2 ? <FontAwesomeIcon style = {{marginRight: '5px'}} icon = {Main.Instance.DatabaseDataState.devices[i].deviceId === 1 ? faDesktop : faMobileAlt}/> : void 0}
                {Main.Instance.DatabaseDataState.devices[i].device}
            </button>)
        }
    }
    // devices.push(<input key = "-2" style = {{height: '22px', marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState ? Main.Instance.DatabaseDataState.gameLabel : ''}/>)
    // devices.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabel === undefined || Main.Instance.DatabaseDataState.gameLabel.length === 0 || itemExists(Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels ? Main.Instance.DatabaseDataState.custom.games.labels : [], Main.Instance.DatabaseDataState.gameLabel)} onClick = {() => addLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // devices.push(<button disabled = {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.custom.games.labels)} onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return devices
}

/*
function getLabelsClient(): JSX.Element[] {
    const labels: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.tags) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.tags.length; i++) {
            labels.push(<button title = {Assets.RemoveLabel + ' ' + Main.Instance.DatabaseDataState.tags[i].name} onClick = {() => removeLabel(Main.Instance.DatabaseDataState.tags[i].name)} style = {{marginBottom: '2px'}} key = {i.toString()} className = "btn btn-xs btn-default btn-dark">{Main.Instance.DatabaseDataState.tags[i].name}</button>)
        }
    }
    labels.push(<input key = "-2" style = {{height: '22px', marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState.gameLabel ? Main.Instance.DatabaseDataState.gameLabel : ''}/>)
    labels.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabel === undefined || Main.Instance.DatabaseDataState.gameLabel.length === 0 || itemExists(Main.Instance.DatabaseDataState.tags ? Main.Instance.DatabaseDataState.tags : [], Main.Instance.DatabaseDataState.gameLabel)} onClick = {() => addLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // labels.push(<button disabled = {Main.Instance.DatabaseDataState.tags && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.tags)} onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return labels
}
*/
function getLabelsCentral(): JSX.Element[] {
    const labels: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.gamesListLabels) {
        // console.log (Main.Instance.DatabaseDataState.gameLabel)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesListLabels.length; i++) {
            labels.push(<button disabled = {/*!isUserGamanzaGamesAdmin()*/ true} title = {Assets.RemoveLabel + ' ' + Main.Instance.DatabaseDataState.gamesListLabels[i].name} /*onClick = {() => RemoveLabel(Main.Instance.DatabaseDataState.gamesListLabels[i].label_id, i)}*/ style = {{marginBottom: '2px'}} key = {i.toString()} className = "btn btn-xs btn-primary">{Main.Instance.DatabaseDataState.gamesListLabels[i].name}</button>)
        }
    }
    // labels.push(<input disabled = {!isUserGamanzaGamesAdmin()} key = "-2" style = {{height: '22px', /*width: '100%',*/ marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {GameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState.GameLabel ? Main.Instance.DatabaseDataState.GameLabel : ''}/>)
    // labels.push(<button disabled = {!isUserGamanzaGamesAdmin() || Main.Instance.DatabaseDataState.GameLabel === undefined || Main.Instance.DatabaseDataState.GameLabel.length === 0 || ItemExists(Main.Instance.DatabaseDataState.gamesListLabels ? Main.Instance.DatabaseDataState.gamesListLabels : [], Main.Instance.DatabaseDataState.GameLabel)} onClick = {() => AddLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // labels.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabels && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.gameLabels)} onClick = {() => void 0/*update all labels*/} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return labels
}
/*
function removeLabel (i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.games.labels.splice(i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom, gameLabel: ''})
}
*/
/*
function itemExists(array: Tag[], item: string): boolean {
    for (const Item of array) if (Item.name === item) return true
    return false
}
*/
/*
function ItemExists(array: GamesListLabel[], item: string): boolean {
    for (const Item of array) { if (Item.name === item) { return true } }
    return false
}
*/
// DbRoutes.updateGameTags(string[])
/*
function addLabel() {
    let found: boolean = false
    
    if (Main.Instance.DatabaseDataState.tags) {
        for (const item of Main.Instance.DatabaseDataState.tags) {
            if (item.name === Main.Instance.DatabaseDataState.gameLabel) {
                found = true
                break
            }
        }
    }
    
    if (!found) {
        const newTags: string[] =  []
        const tags: Tag[] = Object.assign([], Main.Instance.DatabaseDataState.tags)
        if (Main.Instance.DatabaseDataState.tags)
        for (const item of Main.Instance.DatabaseDataState.tags) {
            newTags.push(item.name)
        }
        tags.push({name: Main.Instance.DatabaseDataState.gameLabel})
        newTags.push(Main.Instance.DatabaseDataState.gameLabel)
        DbRoutes.updateGameTags(newTags)
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: '', tags, gameLabel_: JSON.stringify(tags)})
    }
}

function removeLabel(tag: string) {
    const newTags: string[] = []
    const tags: Tag[] = []
    if (Main.Instance.DatabaseDataState.tags)
    for (const item of Main.Instance.DatabaseDataState.tags) {
        if (item.name !== tag) {
            tags.push({name: item.name})
            newTags.push(item.name)
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {tags, gameLabel_: JSON.stringify(tags)})
    DbRoutes.updateGameTags(newTags)
}
*/
/*
function addLabel() {
    let found: boolean = false
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels)
    for (const item of Main.Instance.DatabaseDataState.custom.games.labels) {
        if (item === Main.Instance.DatabaseDataState.gameLabel) {
            found = true
            break
        }
    }

    if (!found) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        if (custom.games === undefined) custom.games = {}
        if (custom.games.labels === undefined) custom.games.labels = []
        custom.games.labels.push(Main.Instance.DatabaseDataState.gameLabel)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom, gameLabel: ''})
    }
}
*/
/*
function AddLabel() {
    let found: boolean = false
    for (const item of Main.Instance.DatabaseDataState.gamesListLabels) {
        if (item.name === Main.Instance.DatabaseDataState.GameLabel) {
            found = true
            break
        }
    }

    if (!found) { DbRoutes.insertLabel(Main.Instance.DatabaseDataState.GameLabel) }
    Logic.Type.New (Main.Instance.DatabaseDataState, {GameLabel: ''})
}


function RemoveLabel(i: number, index: number) {
    DbRoutes.deleteLabel(i)
    const gamesListLabels: GamesListLabel[] = Object.assign([], Main.Instance.DatabaseDataState.gamesListLabels)
    gamesListLabels.splice (index, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {gamesListLabels})
}
*/

function getCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []
    // console.log (Main.Instance.DatabaseDataState.categories)
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesListCategories.length; i++) {
/*
        const cItems: JSX.Element[] = []
        if (Main.Instance.DatabaseDataState.categories[i].data.subs) {
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.categories[i].data.subs.length; j++) {
                cItems.push(
                    <button key = {i.toString() + '_' + j.toString()} title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? Assets.Active : Assets.Inactive : 'Category is inactive (therefore subcategory disabled)'} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active} onClick = {() => toggleSubcategory(i, j)} className = {Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? 'btn btn-info btn-xs' : 'btn btn-default btn-xs'}>
                        {i+1}.{j+1}.
                    </button>
                )
                cItems.push(
                    <input title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? Assets.Active : Assets.Inactive : 'Category is inactive (therefore subcategory disabled)'} key = {i.toString() + '__' + j.toString()} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.subs[j].active} style = {{height: '22px', width: 'auto', marginRight: '10px'}} type = "text" onChange = {(e) => updateName(i, e.target.value, j)} value = {Main.Instance.DatabaseDataState.categories[i].data.subs[j].name}/>
                )
            }
        }
*/
        categories.push (
            <div key = {i.toString()} className = "mt">
                {/*
                <button title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Assets.Active : Assets.Inactive} onClick = {() => toggleCategory(i)} className = {Main.Instance.DatabaseDataState.categories[i].data.active ?'btn btn-primary btn-xs': 'btn btn-default btn-xs'}>
                    {i + 1}.
                </button>
                */}
                <span className = "mr">{i + 1}.</span>
                <input disabled = {true/*!isUserGamanzaGamesAdmin()*/}/*title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Assets.Active : Assets.Inactive}*/ /*disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active}*/ style = {{height: '22px', width: '300px', marginRight: '10px'}} type = "text" /*onChange = {(e) => updateName(i, e.target.value)}*/ value = {Main.Instance.DatabaseDataState.gamesListCategories[i].name}/>
{/*
                <button disabled = {Main.Instance.DatabaseDataState.category_ === undefined || Main.Instance.DatabaseDataState.category_[i] === undefined} onClick = {() => addSubCategory(i)} className = "btn btn-danger btn-xs">
                    {Assets.Add + ' a new subcategory'}
                </button>

                <input title = {Assets.Add + ' a new subcategory'} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active} style = {{height: '22px', width: 'auto', marginRight: '10px'}} type = "text" onChange = {(e) => UpdateName(i, e.target.value)} value = {Main.Instance.DatabaseDataState.category_[i] ? Main.Instance.DatabaseDataState.category_[i] : ''}/>             
*/}

                {/* <button onClick = {() => DbRoutes.UpdateCategory(Main.Instance.DatabaseDataState.gamesListCategories[i])} disabled = {!isUserGamanzaGamesAdmin() || JSON.stringify(Main.Instance.DatabaseDataState.gamesListCategories[i]) === Main.Instance.DatabaseDataState.gamesListCategories_[i]} className = "btn btn-warning btn-xs mr0">
                    {Assets.Update}
                </button> */}
            </div>,
        )
/*
        if (cItems.length > 0) {
            categories.push (
                <div key = {i.toString() + '_'} className = "mt">            
                    {cItems}
                </div>
            )
        } else {
            categories.push (
                <div key = {i.toString() + '_'} className = "mt">            
                    Subcategories are not yet defined for category {Main.Instance.DatabaseDataState.categories[i].data.name}.
                </div>
            )
        }
*/
        categories.push (
            <hr key = {i.toString() + '__'} style = {{marginTop: '10px', marginBottom: '2px', borderTop: '1px solid #bbb'}}/>,
        )
    }
    }

    // categories.push(
    //     <div key = "-1" className = "mt">
    //         <input placeholder = {Assets.AddCategory} title = {Assets.AddCategory} style = {{width: '361px', height: '22px'}} type = "text" value = {Main.Instance.DatabaseDataState.category ? Main.Instance.DatabaseDataState.category : ''} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {category: e.target.value})}/>
    //         <button disabled = {Main.Instance.DatabaseDataState.category === undefined} title = {Assets.AddCategory} onClick = {() => addCategory()} className = "btn btn-danger btn-xs mr0 br0">
    //             <FontAwesomeIcon icon = {faPlus}/>
    //         </button>
    //     </div>,
    // )
    return categories
}
/*
function updateName(i: number, value: string/*, j?: number) {
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        const gamesListCategories: GamesListCategory[] = Object.assign([], Main.Instance.DatabaseDataState.gamesListCategories)
        // if (j === undefined) {
        gamesListCategories[i].name = value
        // } else categories[i].data.subs[j].name = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {gamesListCategories, category: undefined})
    }
}
*/
/*
function UpdateName(i: number, value: string) {
    if (Main.Instance.DatabaseDataState.categories) {
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[i] = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
    }
}


function updateAllCategoriesDisabled(): boolean {
    let disabled: boolean = true
    if (Main.Instance.DatabaseDataState.categories)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if (JSON.stringify(Main.Instance.DatabaseDataState.categories[i]) !== Main.Instance.DatabaseDataState.categories_[i]) {
            disabled = false
            break
        }
    }
    return disabled    
}

function updateAllCategories() {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if ()
        DbRoutes.wsBasicUpdate(Config.amCategories, Main.Instance.DatabaseDataState.categories[i].id, Main.Instance.DatabaseDataState.categories[i].data)
    }
}
*/
/*
function addCategory() {
    // if (Main.Instance.DatabaseDataState.categories) {
/*
        const categories: GameCategory[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        let greatestID: number = 0
        for (const item of categories) {
            if (item.id > greatestID) {
                greatestID = item.id
            }
        }
        greatestID++
        categories.push({id: greatestID, name: Main.Instance.DatabaseDataState.category})
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined})

    DbRoutes.InsertCategory(Main.Instance.DatabaseDataState.category)
    Logic.Type.New (Main.Instance.DatabaseDataState, {category: undefined})
    // }
}
*/
/*
function addSubCategory(category: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: Category2[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        
        let greatestID: number = 0
        if (categories[category].data.subs) {
            for (const item of categories[category].data.subs) {
                if (item.id > greatestID) {
                    greatestID = item.id
                }
            }
        } else {
            categories[category].data.subs = []
        }
        greatestID++

        categories[category].data.subs.push({id: greatestID, name: Main.Instance.DatabaseDataState.category_[category], active: true})
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[category] = undefined
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined, category_})
    }
}

function toggleCategory(i: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.active = !categories[i].data.active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}

function toggleSubcategory(i: number, j: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.subs[j].active = !categories[i].data.subs[j].active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}
*/