
import {Route} from './route-classes'

export default class Router {
    // [Route: string]: Route
    set New(data: {[path: string]: Route}) {
        Object.assign(this, data)
    }
    public exists(path: string) {
        return this[path] ? true : false
    }
    public Get(route: string) {
        return this[route] ? this[route] : new Error('Route does not exist!')
    }
}