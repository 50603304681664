import * as React from 'react'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type';
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import ReactTable from 'react-table'
import {numberWithCommas/*, isLocalhost*/} from '../../../Logic/Utils'
import * as Assets_ from '../../Assets_'
import * as Assets from '../../Assets'
import {IReports} from '../../../Logic/Database/DatabaseData'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faFileCsv, faCalendarDay} from '@fortawesome/free-solid-svg-icons'
// import {faFilePdf} from '@fortawesome/free-regular-svg-icons'

// import 'jspdf-autotable'
// import * as Config from '../../../Logic/Config'
import {SelectTimePeriod} from './SelectTimePeriod'
/*
function filterByDate() {
    if (Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1) {    
        const ggrReportsFiltered: IReports[] = []
        // const temp: IReports[] = Object.assign([], JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.ggrReports)))
        const temp: IReports[] = JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.ggrReports))
        // console.log (Main.Instance.DatabaseDataState.ggrReportsDates)
        // console.log (Main.Instance.GGRRevenueState.dateFrom)
        // if (Main.Instance.GGRRevenueState.dateFrom !== -1) console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom])
        // console.log (Main.Instance.GGRRevenueState.dateTo)
        // if (Main.Instance.GGRRevenueState.dateTo !== -1) console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo])
        const ggrReportsTotal: IReports = {
            WANJackpotIncrements: 0,
            bonusAwarded: 0,
            canceledBonus: 0,
            bonusEndingBalance: 0,
            bonusStartingBalance: 0,
            convertedBonus: 0,
            depositsPSP: 0,
            difference: 0,
            jackpotHitsRng: 0,
            manualAdjustments: 0,
            manualDeposits: 0,
            realEndingBalance: 0,
            realMovement: 0,
            realStartingBalance: 0,
            totalBetBonus: 0,
            totalBetRealLive: 0,
            totalBetRealRNG: 0,
            totalGGR: 0,
            totalGGR2: 0,
            totalWinBonus: 0,
            totalWinRealLive: 0,
            totalWinRealRNG: 0,
            withdrawsPending: 0,
            withdrawsProcessed: 0,
        }
        // console.log ('filterbydate')
        for (const item of temp) {
            const date: string = (new Date(item.date * 1000)).toISOString().substring(0, 10)
            // console.log (date)
            if (Main.Instance.GGRRevenueState.dateFrom !== -1 && date < Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]) {
                // console.log ('NOT OK 1')
                continue
            }
            if (Main.Instance.GGRRevenueState.dateTo !== -1 && date > Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]) {
                // console.log ('NOT OK 2')
                continue
            }                

            // POSSIBLE BUG (FIX)
            item.date += 3600 * 10
            item.realMovement = -item.realMovement
            ggrReportsTotal.WANJackpotIncrements += item.WANJackpotIncrements
            ggrReportsTotal.bonusAwarded += item.bonusAwarded
            if (item.canceledBonus) {ggrReportsTotal.canceledBonus += item.canceledBonus}
            ggrReportsTotal.bonusEndingBalance += item.bonusEndingBalance
            ggrReportsTotal.bonusStartingBalance += item.bonusStartingBalance
            ggrReportsTotal.convertedBonus += item.convertedBonus
            ggrReportsTotal.depositsPSP += item.depositsPSP
            ggrReportsTotal.difference += item.difference
            ggrReportsTotal.jackpotHitsRng += item.jackpotHitsRng
            ggrReportsTotal.manualAdjustments += item.manualAdjustments
            ggrReportsTotal.manualDeposits += item.manualDeposits
            ggrReportsTotal.realEndingBalance += item.realEndingBalance
            ggrReportsTotal.realMovement += item.realMovement
            ggrReportsTotal.realStartingBalance += item.realStartingBalance
            ggrReportsTotal.totalBetBonus += item.totalBetBonus
            ggrReportsTotal.totalBetRealLive += item.totalBetRealLive
            ggrReportsTotal.totalBetRealRNG += item.totalBetRealRNG
            ggrReportsTotal.totalGGR += item.totalGGR
            ggrReportsTotal.totalGGR2 += item.totalGGR2
            ggrReportsTotal.totalWinBonus += item.totalWinBonus
            ggrReportsTotal.totalWinRealLive += item.totalWinRealLive
            ggrReportsTotal.totalWinRealRNG += item.totalWinRealRNG
            ggrReportsTotal.withdrawsPending += item.withdrawsPending
            ggrReportsTotal.withdrawsProcessed += item.withdrawsProcessed

            ggrReportsFiltered.push(item)
        }        
        Logic.Type.New (Main.Instance.DatabaseDataState, {ggrReportsFiltered, ggrReportsTotal})
        // console.log (Main.Instance.DatabaseDataState.ggrReportsFiltered)
    }
}
*/
/*
function addDownload(data: IReports[], total: boolean): string {
    let s: string = ''
    for (const item of data) {
        s += total ? Assets.Total : (new Date(item.date * 1000)).toISOString().substring(0, 10)
        s += ',' + item.totalBetRealRNG
        s += ',' + item.totalWinRealRNG
        s += ',' + item.totalBetRealLive
        s += ',' + item.totalWinRealLive
        s += ',' + item.WANJackpotIncrements
        s += ',' + item.jackpotHitsRng
        s += ',' + item.totalBetBonus
        s += ',' + item.totalWinBonus
        s += ',' + item.convertedBonus
        s += ',' + item.totalGGR

        if (Main.Instance.GGRRevenueState.longReport !== undefined) {
            s += ',' + item.depositsPSP
            s += ',' + item.manualDeposits
            s += ',' + item.withdrawsProcessed
            s += ',' + item.withdrawsPending
        }

        if (Main.Instance.GGRRevenueState.longReport === true) {
            s += ',' + item.convertedBonus
            s += ',' + item.manualAdjustments
            s += ',' + item.realStartingBalance
            s += ',' + item.realEndingBalance
            s += ',' + item.realMovement
            s += ',' + item.totalGGR2
            s += ',' + item.difference
            s += ',' + item.bonusAwarded
            s += ',' + item.canceledBonus
            s += ',' + item.bonusStartingBalance
            s += ',' + item.bonusEndingBalance
        }

        s += '\n'
    }
    return s
}
*/
/*
function download() {
    let from: string = ''
    if (Main.Instance.GGRRevenueState.dateFrom !== -1) {
        from = ' ' + Assets.From.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
    }

    let to: string = ''
    if (Main.Instance.GGRRevenueState.dateTo !== -1) {
        to = ' ' + Assets.To.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]
    }

    let s: string = Assets.Date
    + ',' + Assets.TotalBetReal + ' ' + Assets.Money_RNG + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalWinReal + ' ' + Assets.Money_RNG + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalBetReal + ' ' + Assets.Money_Live + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalWinReal + ' ' + Assets.Money_Live + ' (' + Assets_.currency + ')'
    + ',' + Assets.WANJackpot + ' ' + Assets.Increments + ' (' + Assets_.currency + ')'
    + ',' + Assets.JackpotHits + ' ' + Assets.RNG + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalBet + ' ' + Assets.BonusMoney + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalWin + ' ' + Assets.BonusMoney + ' (' + Assets_.currency + ')'
    + ',' + Assets.Converted + ' ' + Assets.BonusMoney + ' ' + Assets.After.toLowerCase() + ' ' + Assets.Playthrough + ' (' + Assets_.currency + ')'
    + ',' + Assets.TotalGGR

    if (Main.Instance.GGRRevenueState.longReport !== undefined) {
        s += ',' + Assets.Deposits + ' ' + Assets.PSP + ' (' + Assets_.currency + ')'
        + ',' + Assets.Manual + ' ' + Assets.Deposits + ' (' + Assets_.currency + ')'
        + ',' + Assets.Withdraws + ' ' + Assets.Processed.toLowerCase() + ' (' + Assets_.currency + ')'
        + ',' + Assets.Withdraws + ' ' + Assets.Pending.toLowerCase() + ' (' + Assets_.currency + ')'
    }

    if (Main.Instance.GGRRevenueState.longReport === true) {
        s += ',' + Assets.Converted + ' ' + Assets.BonusMoney + ' ' + Assets.After.toLowerCase() + ' ' + Assets.Playthrough + ' (' + Assets_.currency + ')'
        + ',' + Assets.Manual + ' ' + Assets.Adjustments + ' (' + Assets_.currency + ')'
        + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Starting + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
        + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Ending + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
        + ',' + Assets.RealMoney + ' ' + Assets.Wallet + ' ' + Assets.Movement + ' (' + Assets_.currency + ')'
        + ',' + Assets.TotalGGR + ' (' + Assets_.currency + ')'
        + ',' + Assets.Difference + ' (' + Assets_.currency + ')'
        + ',' + Assets.Bonus + ' ' + Assets.Awarded + ' (' + Assets_.currency + ')'
        + ',' + Assets.Bonus + ' ' + Assets.Cancelled + ' (' + Assets_.currency + ')'
        + ',' + Assets.Bonus + ' ' + Assets.Starting + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
        + ',' + Assets.Bonus + ' ' + Assets.Ending + ' ' + Assets.Balance + ' (' + Assets_.currency + ')'
    }
    
    s += '\n'
    s += addDownload(Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] : Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : [], false)
    s += addDownload([Main.Instance.DatabaseDataState.ggrReportsTotal], true)

    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.GGRRevenueReporting + from + to + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}
*/
/*
function centeredText (text, y) {
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
}


function getPeriod(): string {
    if (Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1) {
        let period: string = ''
        if (Main.Instance.GGRRevenueState.dateFrom !== -1) {
            period += Assets.From.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
            // console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom])
        }

        if (Main.Instance.GGRRevenueState.dateTo !== -1) {
            if (period.length > 0) {
                period += ' '
            }
            period += Assets.To.toLowerCase() + ' ' + Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo]

            // console.log (Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateTo])
        }
        return period
    }
    return undefined
}

function footer (currentpage, doc): number {
    doc.setFontSize(10)
    currentpage = doc.internal.getNumberOfPages()
    console.log (currentpage)
    return currentpage + 1
*/
/*
    if (currentpage < doc.internal.getNumberOfPages()) {

        doc.setFontSize(10);
        doc.setFontStyle('normal');

        var str = "Page " + doc.internal.getNumberOfPages();
        str = str + " of " + totalPagesExp;
        doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 30);
        var today = new moment().format("YYYY-MM-DD");
        doc.text(today, right, doc.internal.pageSize.height - 30, 'right');
        currentpage = doc.internal.getNumberOfPages();
    }
*/
// };
/*
function isYesterday(): boolean {
    if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
        Main.Instance.GGRRevenueState.dateTo === -1) {
        return false
    }
    const now: Date = new Date()
   
    now.setDate(now.getDate() - 1)
    // console.log(now.toISOString().substring(0, 10))
    if (Main.Instance.GGRRevenueState.dateFrom === Main.Instance.GGRRevenueState.dateTo &&
        now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
    ) {
        return true
    }
    return false
}

function isBeforeYesterday(): boolean {
    if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
        Main.Instance.GGRRevenueState.dateTo === -1) {
        return false
    }
    const now: Date = new Date()
 
    now.setDate(now.getDate() - 2)
    // console.log(now.toISOString().substring(0, 10))
    if (Main.Instance.GGRRevenueState.dateFrom === Main.Instance.GGRRevenueState.dateTo &&
        now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.ggrReportsDates[Main.Instance.GGRRevenueState.dateFrom]
    ) {
        return true
    }
    return false
}

function isAnyDate(): boolean {
    if (Main.Instance.GGRRevenueState.dateFrom === -1 ||
        Main.Instance.GGRRevenueState.dateTo === -1) {
        return true
    }
    return false
}
*/
/*
export function CreatePDFDocument() {
    const doc = new jsPDF('landscape', 'mm', 'a4')
    const period: string = getPeriod()
    const img = new Image();
    img.src = Assets_.jackpotsLogo
    img.onload = () => {
        const img2 = new Image()
        img2.src = Assets_.badenLogo
        
        img2.onload = () => {
            // settings
            doc.autoTableSetDefaults(
                {margin: {top: 20, left: 10, right: 10, bottom: 20},
                headStyles: {fillColor: '#c90227'},
                footStyles: {fillColor: '#111'},
                cellPadding: 5,
            })

            // page 1
            getHeader('GGR', doc, img, img2, 1, period)
            doc.autoTable({html: '#ggr1'})
            getFooter('GGR', doc, true, Assets.ggr1)

            // page 2
            doc.addPage();
            getHeader('GGR', doc, img, img2, 2, period)
            doc.autoTable({html: '#ggr2'})
            getFooter('GGR', doc, true, Assets.ggr1, true, Assets.ggr2)

            // page 3
            doc.addPage();
            getHeader('GGR', doc, img, img2, 3, period)
            doc.autoTable({html: '#ggr3'})
            getFooter('GGR', doc)

            // page total
            doc.addPage();
            getHeader('GGR', doc, img, img2, 4, period)
            doc.autoTable({html: '#ggrt1'})
            doc.autoTable({html: '#ggrt2'})
            doc.autoTable({html: '#ggrt3'})
            getFooter('GGR', doc)

            // set page numbers
            getPageNumbers(doc)

            // finally
            doc.save(Assets.GGRRevenueReporting + (period ? period : '') + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + '.pdf')
        }
    }
}
*/
/*
    const title: string = Assets.GGRRevenueReporting
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleOffset = (doc.internal.pageSize.width - titleWidth) / 2;
    doc.text(titleOffset, 20, title);
    doc.setFontSize(14);
*/

/*
    const elementHandler = {
        '#ignorePDF': function (element, renderer) {
        return true;
        }
    };
*/
/*
    const source = window.document.getElementById('ggr')
    doc.fromHTML(
        source,
        15,
        15,
        {
        'width': 267// 180 ,'elementHandlers': elementHandler
        });
*/
    

    // doc.autoTable({html: '#ggr1'})
/*
    doc.setFontSize(16);
    doc.text(20, 30, 'This is some normal sized text underneath.');	
    doc.text(20, 20, 'Hello world!');
    doc.text(20, 30, 'This is client-side Javascript, pumping out a PDF.');
    doc.addPage();
    doc.text(20, 20, 'Do you like that?');
    doc.rect(20, 20, 10, 10); // empty square

    doc.rect(40, 20, 10, 10, 'F'); // filled square
        
    doc.setDrawColor(255,0,0);
    doc.rect(60, 20, 10, 10); // empty red square
        
    doc.setDrawColor(255,0,0);
    doc.rect(80, 20, 10, 10, 'FD'); // filled square with red borders
        
    doc.setDrawColor(0);
    doc.setFillColor(255,0,0);
    doc.rect(100, 20, 10, 10, 'F'); // filled red square
        
    doc.setDrawColor(0);
    doc.setFillColor(255,0,0);
    doc.rect(120, 20, 10, 10, 'FD'); // filled red square with black borders    

    doc.line(20, 20, 60, 20); // horizontal line
    doc.setLineWidth(0.5);
    doc.line(20, 25, 60, 25);
        
    doc.setLineWidth(1);
    doc.line(20, 30, 60, 30);
        
    doc.setLineWidth(1.5);
    doc.line(20, 35, 60, 35);
        
    doc.setDrawColor(255,0,0); // draw red lines
        
    doc.setLineWidth(0.1);
    doc.line(100, 20, 100, 60); // vertical line
        
    doc.setLineWidth(0.5);
    doc.line(105, 20, 105, 60);
        
    doc.setLineWidth(1);
    doc.line(110, 20, 110, 60);
        
    doc.setLineWidth(1.5);
    doc.line(115, 20, 115, 60);
*/

function getHead(part: number): JSX.Element {
    return (
    <tr>
        <th style = {{width: '95px'}}>{Assets.Date} ({Assets_.UTC})</th>
        {part === 1 ?
        <>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalBetReal}</div><div>{Assets.Money_RNG}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalWinReal}</div><div>{Assets.Money_RNG}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalBet}</div><div>{Assets.RealMoney}</div><div>({Assets.Live})</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalWin}</div><div>{Assets.RealMoney}</div><div>({Assets.Live})</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.WAN}</div><div>{Assets.Jackpot}</div><div>{Assets.Increments}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Jackpot}</div><div>{Assets.Hits + ' ' + Assets.RNG}&nbsp;&nbsp;</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalBet}</div><div>{Assets.Bonus}</div><div>{Assets.Money}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.TotalWin}</div><div>{Assets.Bonus}</div><div>{Assets.Money}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Converted}</div><div>{Assets.Bonus} (1)</div><div>{Assets.MoneyAfter}</div><div>{Assets.Playthrough}</div></div></th>
        <th style = {{width: '95px'}}>{Assets.TotalGGR}</th>
        </> : void 0}
        {part === 2 ?
        <>
        <th style = {{width: '120px'}}><div><div>{Assets.Deposits}</div><div>{Assets.PSP}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Manual}</div><div>{Assets.Deposits}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Withdraws}</div><div>{Assets.Processed}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Withdraws}</div><div>{Assets.Pending}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Converted}</div><div>{Assets.Bonus} (1)</div><div>{Assets.MoneyAfter}</div><div>{Assets.Playthrough}</div></div></th>
        <th style = {{width: '120px'}}><div><div>{Assets.Manual} (2)</div><div>{Assets.Adjustments}</div></div></th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.RealMoney}</div>
                <div>{Assets.Wallet}</div>
                <div>{Assets.Starting}</div>
                <div>{Assets.Balance}</div>
            </div>
        </th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.RealMoney}</div>
                <div>{Assets.Wallet}</div>
                <div>{Assets.Ending}</div>
                <div>{Assets.Balance}</div>
            </div>
        </th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.RealMoney}</div>
                <div>{Assets.Wallet}</div>
                <div>{Assets.Movement}</div>
            </div>
        </th>
        </> : void 0}
        {part === 3 ?
        <>
        <th style = {{width: '120px'}}>{Assets.TotalGGR}</th>
        <th style = {{width: '120px'}}>{Assets.Difference}</th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.Bonus}</div>
                <div>{Assets.Awarded}</div>
            </div>
        </th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.Bonus}</div>
                <div>{Assets.Cancelled}</div>
            </div>
        </th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.Bonus}</div>
                <div>{Assets.Starting}</div>
                <div>{Assets.Balance}</div>
            </div>
        </th>
        <th style = {{width: '120px'}}>
            <div>
                <div>{Assets.Bonus}</div>
                <div>{Assets.Ending}</div>
                <div>{Assets.Balance}</div>
            </div>
        </th>
        </> : void 0}
    </tr>
    )
}

function getBody(part: number, ggr: IReports[]): JSX.Element[] {
    const body: JSX.Element[] = []
    for (let i: number = 0; i < ggr.length; i++) {
        const row: JSX.Element[] = getRow(part, ggr[i])
        body.push(<tr key = {i.toString()}>{row}</tr>)
    }
    return body
}

function getFoot(part: number): JSX.Element[] {
    const foot: JSX.Element[] = []
    const row: JSX.Element[] = getRow(part, Main.Instance.DatabaseDataState.ggrReportsTotal, true)
    foot.push(<tr key = "total">{row}</tr>)
    return foot
}

function getRow(part: number, item: IReports, total?: boolean): JSX.Element[] {
    const row: JSX.Element[] = []
    if (item) {
        const fontWeight: number = total ? 700 : 400
        row.push(<td key = "0" style = {{fontWeight, width: '95px'}}>{total ? Assets.Total : (new Date(item.date * 1000)).toISOString().substring(0, 10)}</td>)
        if (part === 1) {
            row.push(<td key = "1" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalBetRealRNG.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "2" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalWinRealRNG.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "3" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalBetRealLive.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "4" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalWinRealLive.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "5" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.WANJackpotIncrements.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "6" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.jackpotHitsRng.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "7" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalBetBonus.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "8" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.totalWinBonus.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "9" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.convertedBonus.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "10" style = {{fontWeight, width: '120px'}} className = "ggr-total">{numberWithCommas(item.totalGGR.toFixed(2)) + ' ' + Assets_.currency}</td>)
        } else if (part === 2) {
            row.push(<td key = "1" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.depositsPSP.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "2" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.manualDeposits.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "3" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.withdrawsProcessed.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "4" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.withdrawsPending.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "5" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.convertedBonus.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "6" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.manualAdjustments.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "7" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.realStartingBalance.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "8" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.realEndingBalance.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "9" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.realMovement.toFixed(2)) + ' ' + Assets_.currency}</td>)
        } else if (part === 3) {
            row.push(<td key = "1" style = {{fontWeight, width: '120px'}} className = "ggr-total">{numberWithCommas(item.totalGGR2.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "2" style = {{fontWeight, width: '120px'}} className = "ggr-total-2">{numberWithCommas(item.difference.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "3" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.bonusAwarded.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "4" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.canceledBonus.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "5" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.bonusStartingBalance.toFixed(2)) + ' ' + Assets_.currency}</td>)
            row.push(<td key = "6" style = {{fontWeight, width: '120px'}}>{numberWithCommas(item.bonusEndingBalance.toFixed(2)) + ' ' + Assets_.currency}</td>)
        }
    }
    return row
}

export function GGRRevenueReport(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    // console.log (Main.Instance.DatabaseDataState.ggrReports)
    // console.log (Main.Instance.GGRRevenueState.dateFrom)
    // console.log (Main.Instance.GGRRevenueState.dateTo)
    if (Main.Instance.GGRRevenueState.initial === true && Main.Instance.GGRRevenueState.lastYear) {
        // console.log (Main.Instance.GGRRevenueState.lastYear)
        Logic.Type.New (Main.Instance.GGRRevenueState, {
            yearFrom: Main.Instance.GGRRevenueState.lastYear,
            yearTo: Main.Instance.GGRRevenueState.lastYear,
            initial: false
        })
    }
    // console.log(isLocalhost())
/*
    if (Main.Instance.LoginState.unused3 === false && isLocalhost() === false) {
        return (
        <div>
            <h2>{Assets.YouDontHaveSecurityRightsToAccessData}</h2>
        </div>
        )
    } else {
*/
    return (
        <div>
            <div>
                <h2>{Assets.GGRRevenueReporting}</h2>
            </div>
            <div>
                <SelectTimePeriod type = {1}/>
                {/*
                <div className = "clearfix">
                    <h4 className = "fl mr">{Assets.AccountingSummary}</h4>
                    <FontAwesomeIcon style = {{fontSize: '21px', marginRight: '5px', marginTop: '9px'}} className = "dbl fl" icon = {faCalendarDay}/>
                    <button
                        key = "dateyesterday"
                        onClick = {() => {
                            
                            if (!isYesterday()) {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: Main.Instance.DatabaseDataState.ggrReportsDates.length - 1,
                                    dateTo: Main.Instance.DatabaseDataState.ggrReportsDates.length - 1,
                                    monthFrom: -1,
                                    monthTo: -1,
                                    yearFrom: -1,
                                    yearTo: -1,
                                })
                            } else {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: -1,
                                    dateTo: -1
                                })
                            }
                            filterByDate()
                        }}
                        className = {isYesterday() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.Yesterday}
                    </button>
                    <button
                        key = "datebeforeyesterday"
                        onClick = {() => {
                            if (!isBeforeYesterday()) {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: Main.Instance.DatabaseDataState.ggrReportsDates.length - 2,
                                    dateTo: Main.Instance.DatabaseDataState.ggrReportsDates.length - 2,
                                    monthFrom: -1,
                                    monthTo: -1,
                                    yearFrom: -1,
                                    yearTo: -1,
                                })
                            } else {
                                Logic.Type.New (Main.Instance.GGRRevenueState, {
                                    dateFrom: -1,
                                    dateTo: -1
                                })
                            }
                            filterByDate()
                        }}
                        className = {isBeforeYesterday() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.Day.toLowerCase() + ' ' + Assets.Before.toLowerCase() + ' ' + Assets.Yesterday}
                    </button>
                    <button
                        key = "dateany"
                        onClick = {() => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {
                                dateFrom: -1,
                                dateTo: -1
                            })
                        }}
                        className = {isAnyDate() ? 'mt btn btn-primary dbl fl btn-xs' : 'mt btn btn-default dbl fl btn-xs'}
                    >
                        {Assets.Set + ' ' + Assets.AnyDate}
                    </button>
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Year}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.yearFrom}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {yearFrom: +e.target.value})
                        }
                    >
                        {getYearOptions()}
                    </select>

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthFrom}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthFrom: +e.target.value})
                        }
                    >
                        {getMonthOptions(true)}
                    </select>

                    {Main.Instance.GGRRevenueState.monthFrom > -1 ?
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthFromPart}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthFromPart: +e.target.value})
                        }
                    >
                        {getMonthPartOptions(true)}
                    </select>
                    : void 0}

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Date}
                        className = "fl dbl mt form-control"
                        value = {Main.Instance.GGRRevenueState.dateFrom}
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {dateFrom: +e.target.value})
                            filterByDate()
                        }}
                    >
                        {getDateOptions(true)}
                    </select>
                    <div className = "fl" style = {{marginLeft: '5px', marginRight: '5px', marginTop: '9px'}}>-</div>
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Year}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.yearTo}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {yearTo: +e.target.value})
                        }
                    >
                        {getYearOptions()}
                    </select>

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthTo}
                        onChange = {(e) => 
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthTo: +e.target.value})
                        }
                    >
                        {getMonthOptions(false)}
                    </select>
                    {Main.Instance.GGRRevenueState.monthTo > -1 ?
                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                        className = "fl dbl mt mr form-control"
                        value = {Main.Instance.GGRRevenueState.monthToPart}
                        onChange = {(e) =>
                            Logic.Type.New (Main.Instance.GGRRevenueState, {monthToPart: +e.target.value})
                        }
                    >
                        {getMonthPartOptions(false)}
                    </select>
                    : void 0}

                    <select
                        style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                        title = {Assets.Select + ' ' + Assets.Date}
                        className = "fl dbl mt form-control"
                        value = {Main.Instance.GGRRevenueState.dateTo}
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.GGRRevenueState, {dateTo: +e.target.value})
                            filterByDate()
                        }}
                    >
                        {getDateOptions(false)}
                    </select>
                    
                    <a title = {Assets.Download + ' ' + Assets.CSV} onClick = {() => download()} type = "button" style = {{color: '#333', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '5px', float: 'right', marginLeft: '5px'}}>
                        <FontAwesomeIcon icon = {faFileCsv}/>
                    </a>

                    <a title = {Assets.Download + ' ' + Assets_.PDF} className = "btn btn-danger btn-sm mr0" onClick = {() => CreatePDFDocument()} style = {{padding: '0px', color: '#d9534f', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '4px', float: 'right'}}>
                        <FontAwesomeIcon icon = {faFilePdf}/>
                    </a>
                </div>*/}
                <div>
                    <table id = "ggr1" className = "table">
                        <thead>
                            {getHead(1)}
                        </thead>
                        <tbody>
                            {getBody(1, Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? 
                                Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] :
                                Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : [])}
                        </tbody>
                        <tfoot>
                            {getFoot(1)}
                        </tfoot>
                    </table>
                    <h5>{Assets.ggr1}</h5>

                    <table id = "ggr2" className = "table">
                        <thead>
                            {getHead(2)}
                        </thead>
                        <tbody>
                            {getBody(2, Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? 
                                Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] :
                                Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : [])}
                        </tbody>
                        <tfoot>
                            {getFoot(2)}
                        </tfoot>
                    </table>
                    <h5>{Assets.ggr1}</h5>
                    {Main.Instance.GGRRevenueState.longReport === true ? <h5>{Assets.ggr2}</h5> : void 0}

                    <table id = "ggr3" className = "table">
                        <thead>
                            {getHead(3)}
                        </thead>
                        <tbody>
                            {getBody(3, Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? 
                                Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] :
                                Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : [])}
                        </tbody>
                        <tfoot>
                            {getFoot(3)}
                        </tfoot>
                    </table>

                    {/*console.log (Main.Instance.DatabaseDataState.ggrReports)*/}

                    {/*Main.Instance.DatabaseDataState.ggrReports && Main.Instance.DatabaseDataState.ggrReportsDates ?
                    <ReactTable
                        data = {Main.Instance.GGRRevenueState.dateFrom !== -1 || Main.Instance.GGRRevenueState.dateTo !== -1 ? 
                                Main.Instance.DatabaseDataState.ggrReportsFiltered ? Main.Instance.DatabaseDataState.ggrReportsFiltered : [] :
                                Main.Instance.DatabaseDataState.ggrReports ? Main.Instance.DatabaseDataState.ggrReports : []}
                        // filtered = {Main.Instance.BonusState.filterCompleted === undefined ? [] : Main.Instance.BonusState.filterCompleted === true ? [{id: 'data.completed', value: 'false'}] : [{id: 'data.completed', value: 'true'}]}
                        columns = {[
                            {width: 95, Header: <div style = {{paddingTop: '27px'}}>{Assets.Date} ({Assets_.UTC})</div>, accessor: 'date', Cell: (row) => (new Date(row.value * 1000)).toISOString().substring(0, 10)},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalBetReal}</div><div>{Assets.Money_RNG}</div></div>, accessor: 'totalBetRealRNG', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalWinReal}</div><div>{Assets.Money_RNG}</div></div>, accessor: 'totalWinRealRNG', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalBetReal}</div><div>{Assets.Money_Live}</div></div>, accessor: 'totalBetRealLive', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalWinReal}</div><div>{Assets.Money_Live}</div></div>, accessor: 'totalWinRealLive', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.WANJackpot}</div><div>{Assets.Increments}</div></div>, accessor: 'WANJackpotIncrements', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.JackpotHits}</div><div>{Assets.RNG}</div></div>, accessor: 'jackpotHitsRng', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalBet}</div><div>{Assets.BonusMoney}</div></div>, accessor: 'totalBetBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.TotalWin}</div><div>{Assets.BonusMoney}</div></div>, accessor: 'totalWinBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: <div><div style = {{marginTop:            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    '3px'}}>{Assets.Converted}</div><div>{Assets.BonusMoney}</div><div>{Assets.After.toLowerCase()}</div><div>{Assets.Playthrough} (1)</div></div>, accessor: 'convertedBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, headerClassName: 'ggr-total-', Header:            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    <div style = {{paddingTop: '32px'}}>{Assets.TotalGGR}</div>, accessor: 'totalGGR', Cell: (row) => <div className = "ggr-total">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport !==            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    undefined, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Deposits}</div><div>{Assets.PSP}</div></div>, accessor: 'depositsPSP', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !==            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    undefined, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Manual}</div><div>{Assets.Deposits}</div></div>, accessor: 'manualDeposits', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !==            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    undefined, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Withdraws}</div><div>{Assets.Processed.toLowerCase()}*</div></div>, accessor: 'withdrawsProcessed', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !==            {JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal)}
    undefined, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Withdraws}</div><div>{Assets.Pending.toLowerCase()}</div></div>, accessor: 'withdrawsPending', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '3px'}}>{Assets.Converted}</div><div>{Assets.BonusMoney}</div><div>{Assets.After.toLowerCase()}</div><div>{Assets.Playthrough} (1)</div></div>, accessor: 'convertedBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Manual}</div><div>{Assets.Adjustments} (2)</div></div>, accessor: 'manualAdjustments', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '3px'}}>{Assets.RealMoney}</div><div>{Assets.Wallet}</div><div>{Assets.Starting}</div><div>{Assets.Balance}</div></div>, accessor: 'realStartingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '3px'}}>{Assets.RealMoney}</div><div>{Assets.Wallet}</div><div>{Assets.Ending}</div><div>{Assets.Balance}</div></div>, accessor: 'realEndingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '11px'}}>{Assets.RealMoney}</div><div>{Assets.Wallet}</div><div>{Assets.Movement}</div></div>, accessor: 'realMovement', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, headerClassName: 'ggr-total-', Header: <div style = {{paddingTop: '32px'}}>{Assets.TotalGGR}</div>, accessor: 'totalGGR2', Cell: (row) => <div className = "ggr-total">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, headerClassName: 'ggr-total-2-', Header: <div style = {{paddingTop: '32px'}}>{Assets.Difference}</div>, accessor: 'difference', Cell: (row) => <div className = "ggr-total-2">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Bonus}</div><div>{Assets.Awarded}</div></div>, accessor: 'bonusAwarded', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '19px'}}>{Assets.Bonus}</div><div>{Assets.Cancelled}</div></div>, accessor: 'canceledBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '11px'}}>{Assets.Bonus}</div><div>{Assets.Starting}</div><div>{Assets.Balance}</div></div>, accessor: 'bonusStartingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: <div><div style = {{marginTop: '11px'}}>{Assets.Bonus}</div><div>{Assets.Ending}</div><div>{Assets.Balance}</div></div>, accessor: 'bonusEndingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                        ]}
                        pageSize = {10}
                        showPaginationBottom = {false}
                        showPaginationTop = {true}
                    /> : void 0*/}

                    {/*Main.Instance.DatabaseDataState.ggrReportsTotal ?
                    <ReactTable
                        data = {[Main.Instance.DatabaseDataState.ggrReportsTotal]}
                        // filtered = {Main.Instance.BonusState.filterCompleted === undefined ? [] : Main.Instance.BonusState.filterCompleted === true ? [{id: 'data.completed', value: 'false'}] : [{id: 'data.completed', value: 'true'}]}
                        columns = {[
                            {width: 95, Header: undefined, accessor: 'date', Cell: (row) => <strong>{Assets.Total}</strong>},
                            {width: 120, Header: undefined, accessor: 'totalBetRealRNG', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'totalWinRealRNG', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'totalBetRealLive', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'totalWinRealLive', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'WANJackpotIncrements', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'jackpotHitsRng', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'totalBetBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'totalWinBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, Header: undefined, accessor: 'convertedBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {width: 120, headerClassName: 'ggr-total-', Header: undefined, accessor: 'totalGGR', Cell: (row) => <div className = "ggr-total">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport !== undefined, width: 120, Header: undefined, accessor: 'depositsPSP', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !== undefined, width: 120, Header: undefined, accessor: 'manualDeposits', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !== undefined, width: 120, Header: undefined, accessor: 'withdrawsProcessed', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport !== undefined, width: 120, Header: undefined, accessor: 'withdrawsPending', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'convertedBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'manualAdjustments', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'realStartingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'realEndingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'realMovement', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, headerClassName: 'ggr-total-', Header: undefined, accessor: 'totalGGR2', Cell: (row) => <div className = "ggr-total">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, headerClassName: 'ggr-total-2-', Header: undefined, accessor: 'difference', Cell: (row) => <div className = "ggr-total-2">{numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency}</div>},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'bonusAwarded', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'canceledBonus', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'bonusStartingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                            {show: Main.Instance.GGRRevenueState.longReport === true, width: 120, Header: undefined, accessor: 'bonusEndingBalance', Cell: (row) => numberWithCommas(row.value.toFixed(2)) + ' ' + Assets_.currency},
                        ]}
                        pageSize = {1}
                        showPaginationBottom = {false}
                    /> : void 0*/}
                </div>
                {Main.Instance.DatabaseDataState.ggrReportsTotal ?
                <>
                <br/>
                <table id = "ggrt1" className = "table">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{Assets.Start}</th>
                            <th>{Assets.End}</th>
                            <th>{Assets.Delta}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{Assets.RealMoneyWallet}</td>
                            <td>-</td>
                            <td>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement.toFixed(2)) + ' ' + Assets_.currency}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.Deposits}</td>
                            <td>-</td>
                            <td>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits).toFixed(2)) + ' ' + Assets_.currency}</td>
                            <td>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits).toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.WithdrawsPending}</td>
                            <td>-</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending.toFixed(2)) + ' ' + Assets_.currency}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.WithdrawsProcessed}</td>
                            <td>-</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed.toFixed(2)) + ' ' + Assets_.currency}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>

                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{Assets.TotalCasinoResult}</th>
                            <th>&nbsp;</th>
                            <th>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending).toFixed(2)) + ' ' + Assets_.currency}</th>
                            <th>{numberWithCommas((-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending).toFixed(2)) + ' ' + Assets_.currency}</th>
                        </tr>
                    </tfoot>
                </table>
                <br/>
                <table id = "ggrt2" className = "table">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{Assets.Delta}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{Assets.BetsRNG}</td>
                            <td>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.WinRNG}</td>
                            <td>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.ConvertedBonusMoney}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.WANJackpotIncrements}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                        <tr>
                            <td>{Assets.JackpotHits}</td>
                            <td>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng.toFixed(2)) + ' ' + Assets_.currency}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{Assets.TotalCasinoResult}</th>
                            <th>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng).toFixed(2)) + ' ' + Assets_.currency}</th>
                        </tr>
                    </tfoot>
                </table>
                <br/>
                <table id = "ggrt3" className = "table">
                    {/*
                    <thead>
                    </thead>
                    */}
                    <tbody>
                        <tr>
                            <th>{Assets.Difference}</th>
                            <th>{numberWithCommas(((-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending)-(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng)).toFixed(2)) + ' ' + Assets_.currency}</th>
                        </tr>

                        <tr>
                            <th>{Assets.ManualAdjustmentsAsPerSeparateDocumentation}</th>
                            <th>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.manualAdjustments.toFixed(2)) + ' ' + Assets_.currency}</th>
                        </tr>
                        <tr>
                            <th>{Assets.Check}</th>
                            <th>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.manualAdjustments + (-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending)-(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng)).toFixed(2)) + ' ' + Assets_.currency}</th>
                        </tr>
                    </tbody>
                    {/*
                    <tfoot>
                        <tr>
                            <th>{}</th>
                        </tr>
                    </tfoot>
                    */}
                </table>
                </> : void 0}
                {/*console.log (JSON.stringify(Main.Instance.DatabaseDataState.ggrReports))*/}
                {/*console.log (JSON.stringify(Main.Instance.DatabaseDataState.ggrReportsTotal))*/}


                {/*Main.Instance.DatabaseDataState.ggrReportsTotal ?
                <div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}>&nbsp;</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{Assets.Start}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{Assets.End}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{Assets.Delta}</strong></div>
                    </div>

                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}>{Assets.RealMoneyWallet}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>-</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement.toFixed(2)) + ' ' + Assets_.currency}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>

                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}>{Assets.Deposits}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>-</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits.toFixed(2)) + ' ' + Assets_.currency}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>

                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}>{Assets.WithdrawsPending}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>-</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending.toFixed(2)) + ' ' + Assets_.currency}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>

                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}>{Assets.WithdrawsProcessed}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>-</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed.toFixed(2)) + ' ' + Assets_.currency}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <hr style = {{marginTop: '0px', marginBottom: '0px'}}/>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '200px'}}><strong>{Assets.TotalCasinoResult}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>&nbsp;</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending).toFixed(2)) + ' ' + Assets_.currency}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{numberWithCommas((-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending).toFixed(2)) + ' ' + Assets_.currency}</strong></div>
                    </div>
                    <br/>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>&nbsp;</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{Assets.Delta}</strong></div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.BetsRNG}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.WinRNG}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.BetsLive}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.WinLive}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.ConvertedBonusMoney}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.WANJackpotIncrements}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}>{Assets.JackpotHits}</div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas(-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng.toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>
                    <hr style = {{marginTop: '0px', marginBottom: '0px'}}/>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}><strong>{Assets.TotalCasinoResult}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng).toFixed(2)) + ' ' + Assets_.currency}</strong></div>
                    </div>
                    <br/>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}><strong>{Assets.Difference}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{numberWithCommas(((-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending)-(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng)).toFixed(2)) + ' ' + Assets_.currency}</strong></div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}><strong>{Assets.ManualAdjustmentsAsPerSeparateDocumentation}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}><strong>{numberWithCommas(Main.Instance.DatabaseDataState.ggrReportsTotal.manualAdjustments.toFixed(2)) + ' ' + Assets_.currency}</strong></div>
                    </div>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '600px'}}><strong>{Assets.Check}</strong></div>
                        <div className = "fl" style = {{width: '200px', textAlign: 'right'}}>{numberWithCommas((Main.Instance.DatabaseDataState.ggrReportsTotal.manualAdjustments + (-Main.Instance.DatabaseDataState.ggrReportsTotal.realMovement+Main.Instance.DatabaseDataState.ggrReportsTotal.depositsPSP + Main.Instance.DatabaseDataState.ggrReportsTotal.manualDeposits-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsProcessed-Main.Instance.DatabaseDataState.ggrReportsTotal.withdrawsPending)-(Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealRNG-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealRNG+Main.Instance.DatabaseDataState.ggrReportsTotal.totalBetRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.totalWinRealLive-Main.Instance.DatabaseDataState.ggrReportsTotal.convertedBonus-Main.Instance.DatabaseDataState.ggrReportsTotal.WANJackpotIncrements-Main.Instance.DatabaseDataState.ggrReportsTotal.jackpotHitsRng)).toFixed(2)) + ' ' + Assets_.currency}</div>
                    </div>

                </div> : void 0*/}
            </div>
        </div>
        )
    /*}*/
}
/*
function getDateOptions(from: boolean): JSX.Element[] {
    const dateOptions: JSX.Element[] = []
    dateOptions.push (<option key = "-1" value = {-1}>{Assets.SelectDate}</option>)
    if (Main.Instance.DatabaseDataState.ggrReportsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
            if (from && (Main.Instance.GGRRevenueState.yearFrom === -1 || Main.Instance.GGRRevenueState.yearFrom === Year)
                || !from && (Main.Instance.GGRRevenueState.yearTo === -1 || Main.Instance.GGRRevenueState.yearTo === Year)) {

                const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
                if (from && (Main.Instance.GGRRevenueState.monthFrom === -1 || Main.Instance.GGRRevenueState.monthFrom === Month)
                    || !from && (Main.Instance.GGRRevenueState.monthTo === -1 || Main.Instance.GGRRevenueState.monthTo === Month)) {
                    const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
                    const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3
                    if (from && (Main.Instance.GGRRevenueState.monthFromPart === -1 || Main.Instance.GGRRevenueState.monthFromPart === MonthPart)
                    || !from && (Main.Instance.GGRRevenueState.monthToPart === -1 || Main.Instance.GGRRevenueState.monthToPart === MonthPart)) {
                        dateOptions.push (<option key = {i.toString()} value = {i}>{Main.Instance.DatabaseDataState.ggrReportsDates[i]}</option>)
                    }
                }
            }
        }
    }
    return dateOptions
}

function getYearOptions(): JSX.Element[] {
    const yearOptions: JSX.Element[] = []
    const YearOptions: number[] = []
    yearOptions.push (<option key = "-1" value = {-1}>{Assets.Year}</option>)
    if (Main.Instance.DatabaseDataState.ggrReportsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
            let found: boolean = false
            for (const item of YearOptions) {
                if (item === Year) {
                    found = true
                    break
                }
            }
            if (!found) {
                YearOptions.push (Year)
                yearOptions.push (<option key = {i.toString()} value = {Year}>{Year}</option>)
            }
        }
    }

    if (Main.Instance.GGRRevenueState.lastYear === undefined && YearOptions.length > 0) {
        // console.log (YearOptions[YearOptions.length - 1])
        Logic.Type.New (Main.Instance.GGRRevenueState, {lastYear: YearOptions[YearOptions.length - 1]})
    }

    return yearOptions
}

function getMonthOptions(from: boolean): JSX.Element[] {
    const monthOptions: JSX.Element[] = []
    const MonthOptions: number[] = []
    monthOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Month}</option>)
    if (Main.Instance.DatabaseDataState.ggrReportsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
            if (from && Main.Instance.GGRRevenueState.yearFrom !== -1 && Year !== Main.Instance.GGRRevenueState.yearFrom ||
                !from && Main.Instance.GGRRevenueState.yearTo !== -1 && Year !== Main.Instance.GGRRevenueState.yearTo
            ) {continue}
            
            const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
            let found: boolean = false
            for (const item of MonthOptions) {
                if (item === Month) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthOptions.push (Month)
                monthOptions.push (<option key = {i.toString()} value = {Month}>{Assets.Months[Month + 2]}</option>)
            }
        }
    }
    return monthOptions
}

function getMonthPartOptions(from: boolean): JSX.Element[] {
    const monthPartOptions: JSX.Element[] = []
    const MonthPartOptions: number[] = []
    monthPartOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Part}</option>)
    if (Main.Instance.DatabaseDataState.ggrReportsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.ggrReportsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].substring(0,4))
            const Month: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(6)) - 1
            if (from && Main.Instance.GGRRevenueState.monthFrom !== Month || !from && Main.Instance.GGRRevenueState.monthTo !== Month) {
                continue
            }
            const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.ggrReportsDates[i].charAt(9))
            const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3 
            let found: boolean = false
            for (const item of MonthPartOptions) {
                if (item === MonthPart) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthPartOptions.push (MonthPart)
                if (MonthPart === 3) {
                    let lastDay: string = '30'
                    if (Month === 1) {
                        lastDay = '28'
                        if (Year % 4 === 0) {
                            lastDay = '29'
                            if (Year % 100 === 0) {
                                lastDay = '28'
                                if (Year % 400 === 0) {
                                    lastDay = '29'
                                }    
                            }
                        }

                    } else if (Month === 0 || Month === 2|| Month === 4|| Month === 6|| Month === 7|| Month === 9|| Month === 11) {
                        lastDay = '31'
                    }
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1].replace('?', lastDay)}</option>)
                } else {
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1]}</option>)
                }
            }
        }
    }
    return monthPartOptions
}
*/