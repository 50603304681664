import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput/*, RadioButton2*/, FormInputNumber, FormSelect, FormInputArea} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {injectSpaces, gotoTop, GetValidGamesNames, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, utilsTimeValidEmptyNotValid, areDatesSuitable, isPureOddyzzey, IsBuildMyChance} from '../../../Logic/Utils' 
import {SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import {Modal} from '../../Reusables/Modal'
import {GamesSelection} from './GamesSelection'
// import {PlayersSelection} from './PlayersSelection'
import {RewardTypes} from '../../../Logic/Database/rewards/rewardsEnums'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faFilter, faSave, faCode, faMoneyBillWaveAlt} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
enum P {/*SPINS_VALUE = 0,*/ SPINS_AMOUNT, EXPIRATION_DAYS, WAGERING_REQUIREMENT, DURATION_HOURS, BOOST_MISSIONS_PERCENTAGE, BOOST_POINTS_PERCENTAGE, FLEXIBLE,
        DAYS, CASH_BACK_PERCENTAGE, CASH_BONUS_AMOUNT, DEPOSIT_BONUS_AMOUNT, DEPOSIT_BONUS_PERCENT, TOKENS, DURATION_MINUTES, DURATION_SECONDS/*, BONUS_CODE*//*, TOKEN_VALUE*/}
// import {RT} from '../../../Logic/Database/DatabaseData'
export function getRewardsCount() {
    // if (Main.Instance.RewardShopState.countCalled === false) {
        Logic.Type.New(Main.Instance.RewardShopState, {countCalled:true})

        // DbRoutes.GetBanner()
        DbRoutes.WsGetRewards()
        // DbRoutes.GetAllLanguages()
        // DbRoutes.GetFlags()
    // }
}
/*
function SelectBanner(language: number) {
    if (!Main.Instance.DatabaseDataState.selectLanguage) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
    } else {
        Logic.Type.New(Main.Instance.DatabaseDataState, {language, 
            selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
        Logic.Type.New (Main.Instance.RewardShopState, {category: '', category_id: '0'})
    }
}
*/
function SelectSite(stream: any[]) {
    // console.log (stream)
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.WsGetRewards()
        // console.log ('before load data')
        DbRoutes.loadData()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.RewardShopItem + ' ' + 
            Assets.withName + ' "' + row.original.Reward + '" and type "' + row.original['Reward type'] + '"?')) {

            if (Main.Instance.RewardShopState.pNum === 
            (Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 2) / 
            Main.Instance.RewardShopState.pSize) + 1)) {
                DbRoutes.removeRewardShopItem (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeRewardShopItem (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function isDisabled(row: any) {
    if (Main.Instance.DatabaseDataState.levelsStream) {
        const levels = Object.assign([], Main.Instance.DatabaseDataState.levelsStream)
        for (const item of levels) {
            if (item.levelsDetails && item.levelsDetails.rewardId === row.original.id) {
                return true
                
            }
        }
    }
    if (Main.Instance.DatabaseDataState.storeStream) {
        for (const item of Main.Instance.DatabaseDataState.storeStream) {
            const shop = Object.assign([], item)
            for (const Item of shop.rewards) {
                if (Item.rewardId === row.original.id) {
                    return true
                }
            }
        }
    }
    if (Main.Instance.DatabaseDataState.missionsNew) {
        for (const item of Main.Instance.DatabaseDataState.missionsNew) {
            const mission = Object.assign([], item)
            for (const Item of mission.tasks) {
                if (Item.rewardId === row.original.id) {
                    return true
                }
            }
        }
    }
    if (Main.Instance.DatabaseDataState.tournamentStream) {
        for (const item of Main.Instance.DatabaseDataState.tournamentStream) {
            const race = Object.assign([], item)
            for (const Item of race.prizes) {
                if (Item.rewardId === row.original.id) {
                    return true
                }
            }
        }
    }
    
    
    return false
}


/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.reward_shop SET active = ', rowData[0], rowData[10], getRewardsCount)
    }
}

function handleToggleFreeRound (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.reward_shop SET free_round = ', rowData[0], rowData[11], getRewardsCount)
    }
}

function handleToggleBuyable (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.reward_shop SET buyable = ', rowData[0], rowData[12], getRewardsCount)
    }
}

function handleToggleOnlyNewlyRegistered (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.reward_shop SET only_newly_registered = ', rowData[0], rowData[13], getRewardsCount)
    }
}

function handleRemoveImage (rowData:string[]) {
    if (rowData && confirm (Assets.confirmRemoveImage)) {
        DbRoutes.Action('.reward_shop SET image = \'\'', rowData[0], getRewardsCount)
    }
}

function removeImage () {
    if (confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.RewardShopState, {image: ''})
        DbRoutes.Action('.reward_shop SET image = \'\'', 
                        Main.Instance.RewardShopState.id, getRewardsCount)
    }
}
*/
function updateInfo(text: string) {
    const info = Object.assign([], Main.Instance.RewardShopState.info)
    info[/*Main.Instance.DatabaseDataState.language*/0] = text
    Logic.Type.New (Main.Instance.RewardShopState, {info})
}

function updateName(text: string) {
    const name = Object.assign([], Main.Instance.RewardShopState.name)
    name[/*Main.Instance.DatabaseDataState.language*/0] = text
    Logic.Type.New (Main.Instance.RewardShopState, {name})
}

function updateBonusCode(text: string) {
    if (IsBuildMyChance()) {
        Logic.Type.New (Main.Instance.RewardShopState, {bonusCode: text})



    } else {
    const name = Object.assign([], Main.Instance.RewardShopState.name)
    name[1] = text
    Logic.Type.New (Main.Instance.RewardShopState, {name})
    }
    
}

function updatePrice(text: string) {
    const name = Object.assign([], Main.Instance.RewardShopState.name)
    name[2] = text
    Logic.Type.New (Main.Instance.RewardShopState, {name})
}

function handleCategoryButton(rowDataId: string, i: number) {
    // console.log (i)
    const rewardShopStream: any[] = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
    for (const item of rewardShopStream) {
        if (+item['id'] === +rowDataId) {
            // console.log (item)
            const categories: string[] = item['categories'].trim().split(' ')
            // console.log (categories)
            item['category'] = parseInt(categories[i]) - 1
            // console.log ('new value:' + item['category'])
            break
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStream})
}

function handleEdit(row: any) {
    if (row) {
        const rewardintent: string[] = row.original.rewards ? row.original.rewards.split(',') : []
        const rewardIntent: number[] = []
        for (const item of rewardintent) { rewardIntent.push (+item) }
        // alert (rewardIntent)
        const reward: any = JSON.parse(JSON.stringify(row.original.reward))
        // console.log (reward)
        let Category: number = -1
        // console.log (rowData)

        if (IsBuildMyChance()) {
            Category = row.original['Reward type']
        } else {
            if (row.original['Reward type'] === 'Boost global') {
                Category = Assets_.RT.BOOST_GLOBAL
            } else if (row.original['Reward type'] === 'Boost player specific') {
                Category = Assets_.RT.BOOST_PLAYER_SPECIFIC
            } else if (row.original['Reward type'] === 'Deposit bonus') {
                Category = Assets_.RT.DEPOSIT_BONUS
            } else if (row.original['Reward type'] === 'Cash back') {
                Category = Assets_.RT.CASH_BACK
            } else if (row.original['Reward type'] === 'Cash bonus' || row.original['Reward type'] === 'Bonus money') {
                Category = Assets_.RT.CASH_BONUS
            } else if (row.original['Reward type'] === 'Free spins') {
                Category = Assets_.RT.FREE_SPINS
            } else if (row.original['Reward type'] === 'Mini game') {
                Category = Assets_.RT.MINI_GAME
            } else if (row.original['Reward type'] === 'Tokens') {
                Category = Assets_.RT.TOKENS
            } else if (row.original['Reward type'] === 'Mega spins') {
                Category = Assets_.RT.MEGA_SPINS
            } else if (row.original['Reward type'] === 'Items') {
                Category = Assets_.RT.ITEMS
            }
    /*
        export const RTCategories: string[] = ['Free spins', 'Cash bonus', 'Tokens', 'Cash back', 'Mini game', 'Deposit bonus', 'Boost global', 'Boost player specific']
    export const RTCategoriesBackend: string[] = ['Free spins', 'Cash bonus', 'Tokens', 'Deposit bonus', 'Boost global', 'Boost player specific']

            if (isPureOddyzzey()) {
                for (let i:number = 0; i < Assets_.RTCategories.length; i++) {
                    if (Assets_.RTCategories[i] ===)
                }
            }
    */
            if (Category === 0) {
                if (reward && reward.validGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {V1: reward.validGames, valid1: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {valid1: -1})}

                if (reward && reward.invalidGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {I1: reward.invalidGames, invalid1: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {invalid1: -1})}

                if (reward.category1) {
                    if (reward.category1.dateFrom) {
                        reward.category1.fromDate = reward.category1.dateFrom.substring(0,10)
                        reward.category1.from_date = moment.default(reward.category1.fromDate)
                        reward.category1.fromTime = reward.category1.dateFrom.substring(11,19)
                    }

                    if (reward.category1.dateTo) {
                        reward.category1.toDate = reward.category1.dateTo.substring(0,10)
                        reward.category1.to_date = moment.default(reward.category1.toDate)
                        reward.category1.toTime = reward.category1.dateTo.substring(11,19)
                    }

                    if (reward.category1.boostMissionsPercentage) { reward.category1.boostMissionsPercentage /= 100 }
                    if (reward.category1.boostPointsPercentage) { reward.category1.boostPointsPercentage /= 100 }
                }
                
                if (reward.category2) {
                    if (reward.category2.dateFrom) {
                        reward.category2.fromDate = reward.category2.dateFrom.substring(0,10)
                        reward.category2.from_date = moment.default(reward.category2.fromDate)
                        reward.category2.fromTime = reward.category2.dateFrom.substring(11,19)
                    }

                    if (reward.category2.dateTo) {
                        reward.category2.toDate = reward.category2.dateTo.substring(0,10)
                        reward.category2.to_date = moment.default(reward.category2.toDate)
                        reward.category2.toTime = reward.category2.dateTo.substring(11,19)
                    }

                    if (reward.category2.boostMissionsPercentage) { reward.category2.boostMissionsPercentage /= 100 }
                    if (reward.category2.boostPointsPercentage) { reward.category2.boostPointsPercentage /= 100 }
                }
                
                if (reward.category3) {
                    if (reward.category3.dateFrom) {
                        reward.category3.fromDate = reward.category3.dateFrom.substring(0,10)
                        reward.category3.from_date = moment.default(reward.category3.fromDate)
                        reward.category3.fromTime = reward.category3.dateFrom.substring(11,19)
                    }

                    if (reward.category3.dateTo) {
                        reward.category3.toDate = reward.category3.dateTo.substring(0,10)
                        reward.category3.to_date = moment.default(reward.category3.toDate)
                        reward.category3.toTime = reward.category3.dateTo.substring(11,19)
                    }

                    if (reward.category3.boostMissionsPercentage) { reward.category3.boostMissionsPercentage /= 100 }
                    if (reward.category3.boostPointsPercentage) { reward.category3.boostPointsPercentage /= 100 }
                }
            } else if (Category === 1) {
                // console.log (reward.category1)
                // console.log (reward.category2)
                // console.log (reward.category3)
                if (reward.category1 && reward.category1.validGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {V1: reward.category1.validGames, valid1: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {valid1: -1})}

                if (reward.category2 && reward.category2.validGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {V2: reward.category2.validGames, valid2: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {valid2: -1})}

                if (reward.category3 && reward.category3.validGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {V3: reward.category3.validGames, valid3: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {valid3: -1})}

                if (reward.category1 && reward.category1.invalidGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {I1: reward.category1.invalidGames, invalid1: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {invalid1: -1})}

                if (reward.category2 && reward.category2.invalidGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {I2: reward.category2.invalidGames, invalid2: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {invalid2: -1})}

                if (reward.category3 && reward.category3.invalidGames) {
                    Logic.Type.New (Main.Instance.RewardShopState, {I3: reward.category3.invalidGames, invalid3: 0})
                } else {Logic.Type.New (Main.Instance.RewardShopState, {invalid3: -1})}

                if (reward.category1 && reward.category1.duration) {
                    reward.category1.durationHours = getDuration (reward.category1.duration, 0)
                    reward.category1.durationMinutes = getDuration (reward.category1.duration, 1)
                    reward.category1.durationSeconds = getDuration (reward.category1.duration, 2)
                    if (reward.category1.boostMissionsPercentage) { reward.category1.boostMissionsPercentage /= 100 }
                    if (reward.category1.boostPointsPercentage) { reward.category1.boostPointsPercentage /= 100 }                
                }

                if (reward.category2 && reward.category2.duration) {
                    reward.category2.durationHours = getDuration (reward.category2.duration, 0)
                    reward.category2.durationMinutes = getDuration (reward.category2.duration, 1)
                    reward.category2.durationSeconds = getDuration (reward.category2.duration, 2)
                    if (reward.category2.boostMissionsPercentage) { reward.category2.boostMissionsPercentage /= 100 }
                    if (reward.category2.boostPointsPercentage) { reward.category2.boostPointsPercentage /= 100 }
                }

                if (reward.category3 && reward.category3.duration) {
                    reward.category3.durationHours = getDuration (reward.category3.duration, 0)
                    reward.category3.durationMinutes = getDuration (reward.category3.duration, 1)
                    reward.category3.durationSeconds = getDuration (reward.category3.duration, 2)
                    if (reward.category3.boostMissionsPercentage) { reward.category3.boostMissionsPercentage /= 100 }
                    if (reward.category3.boostPointsPercentage) { reward.category3.boostPointsPercentage /= 100 }
                }
            } else if (Category === 5) {
                // alert (JSON.stringify(reward.category1))

                const GamesStreamAllSelected: boolean[] = []
                const GamesStreamAllSelected2: boolean[] = []
                const GamesStreamAllSelected3: boolean[] = []

                if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                if (reward.category1 && reward.category1.gameIds) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    let value: boolean = false
                    for (let j: number = 0; j < reward.category1.gameIds.length; j++) {
                        if (reward.category1.gameIds[j] === parseInt(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])) {
                            value = true
                            break
                        }
                    }
                    GamesStreamAllSelected.push(value) 
                }
                } else { for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) { GamesStreamAllSelected.push(false) } }            
                if (reward.category2 && reward.category2.gameIds) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    let value: boolean = false
                    for (let j: number = 0; j < reward.category2.gameIds.length; j++) {
                        if (reward.category2.gameIds[j] === parseInt(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])) {
                            value = true
                            break
                        }
                    }
                    GamesStreamAllSelected2.push(value) 
                }
                } else { for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) { GamesStreamAllSelected2.push(false) } }

                if (reward.category3 && reward.category3.gameIds) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    let value: boolean = false
                    for (let j: number = 0; j < reward.category3.gameIds.length; j++) {
                        if (reward.category3.gameIds[j] === parseInt(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])) {
                            value = true
                            break
                        }
                    }
                    GamesStreamAllSelected3.push(value) 
                }
                } else { for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) { GamesStreamAllSelected3.push(false) } }
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected, GamesStreamAllSelected2, GamesStreamAllSelected3})


                if (reward.category1 && reward.category1.spinBetLevel) {
                    if (reward.category1.gameProviders && TableUtils.gameProviders(reward.category1.gameProviders) === 'NetEnt') {
                        // alert ('yes')
                        reward.category1.spinValue = TableUtils.convertNetEnt(reward.category1.spinBetLevel)
                    } else { reward.category1.spinValue = reward.category1.spinBetLevel } 
                }

                if (reward.category2 && reward.category2.spinBetLevel) {
                    if (reward.category2.gameProviders && TableUtils.gameProviders(reward.category2.gameProviders) === 'NetEnt') {
                        reward.category2.spinValue = TableUtils.convertNetEnt(reward.category2.spinBetLevel)
                    } else { reward.category2.spinValue = reward.category2.spinBetLevel } 
                }

                if (reward.category3 && reward.category3.spinBetLevel) {
                    if (reward.category3.gameProviders && TableUtils.gameProviders(reward.category3.gameProviders) === 'NetEnt') {
                        reward.category3.spinValue = TableUtils.convertNetEnt(reward.category3.spinBetLevel)
                    } else { reward.category3.spinValue = reward.category3.spinBetLevel } 
                }
            }
        }

        
        Logic.Type.New(Main.Instance.RewardShopState, {
            mode: true,
            id: row.original.id,
            name:[row.original.Reward],
            name_saved:[row.original.Reward],
            rewardIntent,
            rewardIntentSaved: rewardIntent,
            csv: row.original['Reward intent'] ? row.original['Reward intent'] : '',
            info:[reward.description ? reward.description : ''],
            info_saved:[reward.description ? reward.description : ''],
            category: row.original.categories,
            Category: Category.toString(),
            category_idx: +row.original.category,
            CSV: reward,
            bonusCode: row.original.reward.category1.bonusCode
        })
        gotoTop()
    }
}

function handleInsertUpdate() {
    const reward: any = {
        description: !Main.Instance.RewardShopState.info ? '' : Main.Instance.RewardShopState.info[0],
        // category: Main.Instance.FrontendStoreState.Category[parseInt(Main.Instance.RewardShopState.Category) + 1]['name']
    }
/*
    if (Main.Instance.RewardShopState.rewardIntent.length === 0) {
        alert ('Please select the reward intent.')
        return
    }
*/
    if (Main.Instance.RewardShopState.name[0] === undefined || Main.Instance.RewardShopState.name[0].length === 0) {
        alert (Assets.PleaseDefineNameOfReward)
        return
    }

    let rewardType: number = 0
    

    if (IsBuildMyChance()) {
        reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
        rewardType = parseInt(Main.Instance.RewardShopState.Category)
        const regex = new RegExp(/\D+/)
        if (+rewardType === 3 && regex.test(Main.Instance.RewardShopState.bonusCode) === true) {
            alert (Assets.BonusCodeForTokensHasToBeNumber)
            return
        }
        reward.category1.bonusCode = Main.Instance.RewardShopState.bonusCode
        
    } else {
        if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC) {
            rewardType = 8
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.durationHours ||
                Main.Instance.RewardShopState.CSV.category1.durationMinutes || 
                Main.Instance.RewardShopState.CSV.category1.durationSeconds) && ( 
                Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage ||
                Main.Instance.RewardShopState.CSV.category1.expirationDays)) {
                    reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                    const properties = Object.keys(reward.category1)
                    for (const item of properties) {
                        if (item !== 'durationHours' && item !== 'durationMinutes' && item !== 'durationSeconds' && item !== 'boostMissionsPercentage' && 
                        item !== 'boostPointsPercentage' && item !== 'expirationDays') {
                            delete (reward.category1[item])
                        }
                    }
                    reward.category1.duration = 0
                    if (Main.Instance.RewardShopState.CSV.category1.durationHours) {
                        reward.category1.duration += +Main.Instance.RewardShopState.CSV.category1.durationHours * 3600
                    }
                    if (Main.Instance.RewardShopState.CSV.category1.durationMinutes) {
                        reward.category1.duration += +Main.Instance.RewardShopState.CSV.category1.durationMinutes * 60
                    }
                    if (Main.Instance.RewardShopState.CSV.category1.durationSeconds) {
                        reward.category1.duration += +Main.Instance.RewardShopState.CSV.category1.durationSeconds
                    }
                    delete(reward.category1.durationHours)
                    delete(reward.category1.durationMinutes)
                    delete(reward.category1.durationSeconds)

                    if (reward.category1 && reward.category1.validGames) { delete reward.category1.validGames }
                    if (reward.category1 && reward.category1.invalidGames) { delete reward.category1.invalidGames }

                    if (reward.category1.boostMissionsPercentage) { reward.category1.boostMissionsPercentage *= 100 }
                    if (reward.category1.boostPointsPercentage) { reward.category1.boostPointsPercentage *= 100 }
                    if (Main.Instance.RewardShopState.valid1 === 0) {
                        if (reward.category1 === undefined) {reward.category1 = {}}
                        // console.log (Main.Instance.RewardShopState.V1)
                        reward.category1.validGames = Main.Instance.RewardShopState.V1
                    } // else {console.log ('valid1 not set')}

                    if (Main.Instance.RewardShopState.invalid1 === 0) {
                        if (reward.category1 === undefined) {reward.category1 = {}}
                        reward.category1.invalidGames = Main.Instance.RewardShopState.I1
                        // console.log (Main.Instance.RewardShopState.I1)
                    } // else {console.log ('invalid1 not set')}
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.durationHours ||
                Main.Instance.RewardShopState.CSV.category2.durationMinutes || 
                Main.Instance.RewardShopState.CSV.category2.durationSeconds) && ( 
                Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage)) {
                    reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                    const properties = Object.keys(reward.category2)
                    for (const item of properties) {
                        if (item !== 'durationHours' && item !== 'durationMinutes' && item !== 'durationSeconds' && item !== 'boostMissionsPercentage' && 
                        item !== 'boostPointsPercentage' && item !== 'expirationDays') {
                            delete (reward.category2[item])
                        }
                    }
                    reward.category2.duration = 0
                    if (Main.Instance.RewardShopState.CSV.category2.durationHours) {
                        reward.category2.duration += +Main.Instance.RewardShopState.CSV.category2.durationHours * 3600
                    }
                    if (Main.Instance.RewardShopState.CSV.category2.durationMinutes) {
                        reward.category2.duration += +Main.Instance.RewardShopState.CSV.category2.durationMinutes * 60
                    }
                    if (Main.Instance.RewardShopState.CSV.category2.durationSeconds) {
                        reward.category2.duration += +Main.Instance.RewardShopState.CSV.category2.durationSeconds
                    }
                    delete(reward.category2.durationHours)
                    delete(reward.category2.durationMinutes)
                    delete(reward.category2.durationSeconds)
                    if (reward.category2.boostMissionsPercentage) { reward.category2.boostMissionsPercentage *= 100 }
                    if (reward.category2.boostPointsPercentage) { reward.category2.boostPointsPercentage *= 100 }

                    if (reward.category2 && reward.category2.validGames) { delete reward.category2.validGames }
                    if (reward.category2 && reward.category2.invalidGames) { delete reward.category2.invalidGames }

                    if (Main.Instance.RewardShopState.valid2 === 0) {
                        if (reward.category2 === undefined) {reward.category2 = {}}
                        // console.log (Main.Instance.RewardShopState.V2)
                        reward.category2.validGames = Main.Instance.RewardShopState.V2
                    } // else {console.log ('valid2 not set')}
                    
                    if (Main.Instance.RewardShopState.invalid2 === 0) {
                        if (reward.category2 === undefined) {reward.category2 = {}}
                        reward.category2.invalidGames = Main.Instance.RewardShopState.I2
                        // console.log (Main.Instance.RewardShopState.I2)
                    } // else {console.log ('invalid2 not set')}
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.durationHours ||
                Main.Instance.RewardShopState.CSV.category3.durationMinutes || 
                Main.Instance.RewardShopState.CSV.category3.durationSeconds) && ( 
                Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage)) {
                    reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                    const properties = Object.keys(reward.category3)
                    for (const item of properties) {
                        if (item !== 'durationHours' && item !== 'durationMinutes' && item !== 'durationSeconds' && item !== 'boostMissionsPercentage' && 
                        item !== 'boostPointsPercentage' && item !== 'expirationDays') {
                            delete (reward.category3[item])
                        }
                    }
                    reward.category3.duration = 0
                    if (Main.Instance.RewardShopState.CSV.category3.durationHours) {
                        reward.category3.duration += +Main.Instance.RewardShopState.CSV.category3.durationHours * 3600
                    }
                    if (Main.Instance.RewardShopState.CSV.category3.durationMinutes) {
                        reward.category3.duration += +Main.Instance.RewardShopState.CSV.category3.durationMinutes * 60
                    }
                    if (Main.Instance.RewardShopState.CSV.category3.durationSeconds) {
                        reward.category3.duration += +Main.Instance.RewardShopState.CSV.category3.durationSeconds
                    }
                    delete(reward.category3.durationHours)
                    delete(reward.category3.durationMinutes)
                    delete(reward.category3.durationSeconds)
                    if (reward.category3.boostMissionsPercentage) { reward.category3.boostMissionsPercentage *= 100 }
                    if (reward.category3.boostPointsPercentage) { reward.category3.boostPointsPercentage *= 100 }

                    if (reward.category3 && reward.category3.validGames) { delete reward.category3.validGames }
                    if (reward.category3 && reward.category3.invalidGames) { delete reward.category3.invalidGames }

                    if (Main.Instance.RewardShopState.valid3 === 0) {
                        if (reward.category3 === undefined) {reward.category3 = {}}
                        // console.log (Main.Instance.RewardShopState.V2)
                        reward.category3.validGames = Main.Instance.RewardShopState.V3
                    } // else {console.log ('valid2 not set')}

                    if (Main.Instance.RewardShopState.invalid3 === 0) {
                        if (reward.category3 === undefined) {reward.category3 = {}}
                        reward.category3.invalidGames = Main.Instance.RewardShopState.I3
                        // console.log (Main.Instance.RewardShopState.I3)
                    } // else {console.log ('invalid3 not set')}
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL) {
            rewardType = 7
            // console.log (Main.Instance.RewardShopState.CSV.category1)
            // console.log (Main.Instance.RewardShopState.CSV.category2)
            // console.log (Main.Instance.RewardShopState.CSV.category3)

            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.from_date ||
                Main.Instance.RewardShopState.CSV.category1.to_date) && ( 
                Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage)) {
                    if (!areDatesSuitable(Main.Instance.RewardShopState.CSV.category1.from_date, Main.Instance.RewardShopState.CSV.category1.to_date, Main.Instance.RewardShopState.CSV.category1.fromTime, Main.Instance.RewardShopState.CSV.category1.toTime)) {
                        alert ('Dates for category 1 are not suitable'); return}
                    } else if (Main.Instance.RewardShopState.CSV.category1) { alert ('Settings for category 1 are not suitable'); return}
    /*        
            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.from_date ||
                Main.Instance.RewardShopState.CSV.category2.to_date) && (
                Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage)) {
                    if (!areDatesSuitable(Main.Instance.RewardShopState.CSV.category2.from_date, Main.Instance.RewardShopState.CSV.category2.to_date,
                                        Main.Instance.RewardShopState.CSV.category2.fromTime, Main.Instance.RewardShopState.CSV.category2.toTime)) {
                    alert ('Dates for category 2 are not suitable'); return}
            } else if (Main.Instance.RewardShopState.CSV.category2) { alert ('Settings for category 2 are not suitable'); return}

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.from_date ||
                Main.Instance.RewardShopState.CSV.category3.to_date) && (
                Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage ||
                Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage)) {
                    if (!areDatesSuitable(Main.Instance.RewardShopState.CSV.category3.from_date, Main.Instance.RewardShopState.CSV.category3.to_date,
                                        Main.Instance.RewardShopState.CSV.category3.fromTime, Main.Instance.RewardShopState.CSV.category3.toTime)) {
                    alert ('Dates for category 3 are not suitable'); return}
                } else if (Main.Instance.RewardShopState.CSV.category3) { alert ('Settings for category 3 are not suitable'); return}
    */
            if (Main.Instance.RewardShopState.CSV.category1) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'fromDate' && item !== 'fromTime' && item !== 'toDate' && item !== 'toTime' 
                    && item !== 'boostMissionsPercentage' && item !== 'boostPointsPercentage') {
                        delete (reward.category1[item])
                    }
                }

                if (reward.category1.boostMissionsPercentage) { reward.category1.boostMissionsPercentage *= 100 }
                if (reward.category1.boostPointsPercentage) { reward.category1.boostPointsPercentage *= 100 }
                if (reward.category1.fromTime.length === 5) { reward.category1.fromTime += ':00' }
                if (reward.category1.toTime.length === 5) { reward.category1.toTime += ':00' }    
                reward.category1.dateFrom = reward.category1.fromDate + ' ' + reward.category1.fromTime
                reward.category1.dateTo = reward.category1.toDate + ' ' + reward.category1.toTime
                delete(reward.category1.fromDate)
                // delete(reward.category1.from_date)
                delete(reward.category1.fromTime)
                delete(reward.category1.toDate)
                // delete(reward.category1.to_date)
                delete(reward.category1.toTime)

                if (reward && reward.validGames) { delete reward.validGames }
                if (reward && reward.invalidGames) { delete reward.invalidGames }

                if (Main.Instance.RewardShopState.valid1 === 0) {
                    // console.log (Main.Instance.RewardShopState.V3)
                    reward.validGames = Main.Instance.RewardShopState.V1
                } // else {console.log ('valid3 not set')}

                if (Main.Instance.RewardShopState.invalid1 === 0) {
                    if (reward.category1 === undefined) {reward.category1 = {}}
                    reward.invalidGames = Main.Instance.RewardShopState.I1
                    // console.log (Main.Instance.RewardShopState.I1)
                } // else {console.log ('invalid1 not set')}
            }
    /*
            if (Main.Instance.RewardShopState.CSV.category2) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                let properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'fromDate' && item !== 'fromTime' && item !== 'toDate' && item !== 'toTime' 
                    && item !== 'boostMissionsPercentage' && item !== 'boostPointsPercentage') {
                        delete (reward.category2[item])
                    }
                }

                if (reward.category2.boostMissionsPercentage) reward.category2.boostMissionsPercentage *= 100
                if (reward.category2.boostPointsPercentage) reward.category2.boostPointsPercentage *= 100
                if (reward.category2.fromTime.length === 5) reward.category2.fromTime += ':00'
                if (reward.category2.toTime.length === 5) reward.category2.toTime += ':00'
                reward.category2.dateFrom = reward.category2.fromDate + ' ' + reward.category2.fromTime
                reward.category2.dateTo = reward.category2.toDate + ' ' + reward.category2.toTime
                delete(reward.category2.fromDate)
                delete(reward.category2.from_date)
                delete(reward.category2.fromTime)
                delete(reward.category2.toDate)
                delete(reward.category2.to_date)
                delete(reward.category2.toTime)
            }

            if (Main.Instance.RewardShopState.CSV.category3) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                let properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'fromDate' && item !== 'fromTime' && item !== 'toDate' && item !== 'toTime' 
                    && item !== 'boostMissionsPercentage' && item !== 'boostPointsPercentage') {
                        delete (reward.category3[item])
                    }
                }

                if (reward.category3.boostMissionsPercentage) reward.category3.boostMissionsPercentage *= 100
                if (reward.category3.boostPointsPercentage) reward.category3.boostPointsPercentage *= 100
                if (reward.category3.fromTime.length === 5) reward.category3.fromTime += ':00'
                if (reward.category3.toTime.length === 5) reward.category3.toTime += ':00'
                reward.category3.dateFrom = reward.category3.fromDate + ' ' + reward.category3.fromTime
                reward.category3.dateTo = reward.category3.toDate + ' ' + reward.category3.toTime
                delete(reward.category3.fromDate)
                delete(reward.category3.from_date)
                delete(reward.category3.fromTime)
                delete(reward.category3.toDate)
                delete(reward.category3.to_date)
                delete(reward.category3.toTime)
            }
    */
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.CASH_BACK) {
            rewardType = 4
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.days ||
                Main.Instance.RewardShopState.CSV.category1.cashBackPercentage)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'days' && item !== 'cashBackPercentage') {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.days ||
                Main.Instance.RewardShopState.CSV.category2.cashBackPercentage)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'days' && item !== 'cashBackPercentage') {
                        delete (reward.category2[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.days ||
                Main.Instance.RewardShopState.CSV.category3.cashBackPercentage)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'days' && item !== 'cashBackPercentage') {
                        delete (reward.category3[item])
                    }
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.CASH_BONUS) {
            rewardType = 2
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.expirationDays ||
                Main.Instance.RewardShopState.CSV.category1.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category1.bonusCode ||
                Main.Instance.RewardShopState.CSV.category1.cashBonusAmount)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'cashBonusAmount' && item !== 'bonusCode') {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.expirationDays ||
                Main.Instance.RewardShopState.CSV.category2.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category2.bonusCode ||
                Main.Instance.RewardShopState.CSV.category2.cashBonusAmount)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'cashBonusAmount' && item !== 'bonusCode') {
                        delete (reward.category2[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.expirationDays ||
                Main.Instance.RewardShopState.CSV.category3.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category3.bonusCode ||
                Main.Instance.RewardShopState.CSV.category3.cashBonusAmount)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'cashBonusAmount' && item !== 'bonusCode') {
                        delete (reward.category3[item])
                    }
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.DEPOSIT_BONUS) {
            rewardType = 6
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.expirationDays ||
                Main.Instance.RewardShopState.CSV.category1.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category1.bonusPercent ||
                Main.Instance.RewardShopState.CSV.category1.bonusCode ||
                Main.Instance.RewardShopState.CSV.category1.bonusAmount)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'bonusPercent' && item !== 'bonusAmount' && item !== 'bonusCode') {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.expirationDays ||
                Main.Instance.RewardShopState.CSV.category2.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category2.bonusPercent ||
                Main.Instance.RewardShopState.CSV.category2.bonusCode ||
                Main.Instance.RewardShopState.CSV.category2.bonusAmount)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'bonusPercent' && item !== 'bonusAmount' && item !== 'bonusCode') {
                        delete (reward.category2[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.expirationDays ||
                Main.Instance.RewardShopState.CSV.category3.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category3.bonusPercent ||
                Main.Instance.RewardShopState.CSV.category3.bonusCode ||
                Main.Instance.RewardShopState.CSV.category3.bonusAmount)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'bonusPercent' && item !== 'bonusAmount' && item !== 'bonusCode') {
                        delete (reward.category3[item])
                    }
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.FREE_SPINS) {
            rewardType = 1
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.expirationDays ||
                Main.Instance.RewardShopState.CSV.category1.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category1.spinsAmount ||
                Main.Instance.RewardShopState.CSV.category1.spinValue ||
                Main.Instance.RewardShopState.CSV.category1.bonusCode ||
                Main.Instance.RewardShopState.CSV.category1.gameIds)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'spinsAmount' && item !== 'spinValue' && item !== 'gameIds'
                    && item !== 'gameNames' && item !== 'gameProviders' && item !== 'spinBetLevel' && item !== 'spinsCoinValues' && item !== 'bonusCode'
                    ) {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.expirationDays ||
                Main.Instance.RewardShopState.CSV.category2.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category2.spinsAmount ||
                Main.Instance.RewardShopState.CSV.category2.spinValue ||
                Main.Instance.RewardShopState.CSV.category2.bonusCode ||
                Main.Instance.RewardShopState.CSV.category2.gameIds)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'spinsAmount' && item !== 'spinValue' && item !== 'gameIds'
                    && item !== 'gameNames' && item !== 'gameProviders' && item !== 'spinBetLevel' && item !== 'spinsCoinValues' && item !== 'bonusCode'
                    ) {
                        delete (reward.category2[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.expirationDays ||
                Main.Instance.RewardShopState.CSV.category3.wageringRequirement ||
                Main.Instance.RewardShopState.CSV.category3.spinsAmount ||
                Main.Instance.RewardShopState.CSV.category3.spinValue ||
                Main.Instance.RewardShopState.CSV.category3.bonusCode ||
                Main.Instance.RewardShopState.CSV.category3.gameIds)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'wageringRequirement' && item !== 'spinsAmount' && item !== 'spinValue' && item !== 'gameIds'
                    && item !== 'gameNames' && item !== 'gameProviders' && item !== 'spinBetLevel' && item !== 'spinsCoinValues' && item !== 'bonusCode'
                    ) {
                        delete (reward.category3[item])
                    }
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME) {
            rewardType = 5
            // console.log (Main.Instance.RewardShopState.CSV.category1)
            if (Main.Instance.RewardShopState.CSV.category1 && (
                Main.Instance.RewardShopState.CSV.category1.expirationDays ||
                Main.Instance.RewardShopState.CSV.category1.rewards)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'rewards') {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                Main.Instance.RewardShopState.CSV.category2.expirationDays ||
                Main.Instance.RewardShopState.CSV.category2.rewards)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'rewards') {
                        delete (reward.category2[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                Main.Instance.RewardShopState.CSV.category3.expirationDays ||
                Main.Instance.RewardShopState.CSV.category3.rewards)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'expirationDays' && item !== 'rewards') {
                        delete (reward.category3[item])
                    }
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.TOKENS) {
            rewardType = 3
            if (Main.Instance.RewardShopState.CSV.category1 && (
                // Main.Instance.RewardShopState.CSV.category1.expirationDays ||
                Main.Instance.RewardShopState.CSV.category1.tokens)) {
                reward.category1 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category1)
                const properties = Object.keys(reward.category1)
                for (const item of properties) {
                    if (item !== 'tokens') {
                        delete (reward.category1[item])
                    }
                }
            }

            if (Main.Instance.RewardShopState.CSV.category2 && (
                // Main.Instance.RewardShopState.CSV.category2.expirationDays ||
                Main.Instance.RewardShopState.CSV.category2.tokens)) {
                reward.category2 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category2)
                const properties = Object.keys(reward.category2)
                for (const item of properties) {
                    if (item !== 'tokens') {
                        delete (reward.category2[item])
                    }
                }

            }

            if (Main.Instance.RewardShopState.CSV.category3 && (
                // Main.Instance.RewardShopState.CSV.category3.expirationDays ||
                Main.Instance.RewardShopState.CSV.category3.tokens)) {
                reward.category3 = Object.assign ({}, Main.Instance.RewardShopState.CSV.category3)
                const properties = Object.keys(reward.category3)
                for (const item of properties) {
                    if (item !== 'tokens') {
                        delete (reward.category3[item])
                    }
                }
            }
        }
    }
    // console.log(rewardType)
    // alert (Main.Instance.RewardShopState.rewardIntent)
    const rewardItem = {
        id : !Main.Instance.RewardShopState.id ? '' : Main.Instance.RewardShopState.id,
        name: !Main.Instance.RewardShopState.name ? '' : Main.Instance.RewardShopState.name[0],
        rewardIntent: Main.Instance.RewardShopState.rewardIntent,
        rewardType,
        reward,
    }
    // console.log (rewardItem)
/*
    if (parseInt(rewardItem.active) === 1) rewardShopItem.active = 'true'
    if (parseInt(rewardItem.active) === 2) rewardShopItem.active = 'false'
*/
    // console.log(rewardItem) 
    if (Main.Instance.RewardShopState.name.length === 0) {
        alert (Assets.compulsoryName)
    } else if (parseInt(Main.Instance.RewardShopState.Category) === -1) {
        alert (Assets.TheRewardTypeFieldIsCompulsory)
    
/*  }
    if (Main.Instance.RewardShopState.price.length === 0) {
        alert (Assets.compulsoryPrice)
    } else */ 
/*
    if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
*/
    } else if (Main.Instance.RewardShopState.mode === false) {
         // console.log (rewardItem)
         
         DbRoutes.WSinsertReward(rewardItem/*, InsertLastPage, clearFields*/)
    } else {
         // console.log ('just before update')
         DbRoutes.WSupdateReward(rewardItem/*, Update*/)
         
    }
}
/*
function ApplyFilterOnlyNewlyRegistered(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_only_newly_registered:text})
    FirstPage()
}

function ApplyFilterBuyable(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_buyable:text})
    FirstPage()
}

function ApplyFilterFreeRound(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_free_round:text})
    FirstPage()
}

function ApplyFilterActive(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_active:text})
    FirstPage()
}

function ApplyFilterBanner(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_banner:text})
    FirstPage()
}
*/
function ApplyFilterCategory(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_category: text})
    FirstPage()
}

function ApplyFilterType(text:string) {
    
    Logic.Type.New(Main.Instance.RewardShopState, {filter_type: text, filter_fsAmount: '0'})
    if (IsBuildMyChance()) {
        tableFilters(text, 'Reward type');
        tableFilters('0', 'numOfFreeSpins');
    } else {
        FirstPage()
    }
}

function ApplyFilterFs(text: string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_fsAmount: text})
    tableFilters(text, 'numOfFreeSpins');
}

function getFsAmountData(data: string[]): Array<{id: string, name: string}> {
    const options: Array<{id: string, name: string}> = []
    for (let i: number = 0; i < data.length; i++) {
        options.push({id: (i+1).toString(), name: data[i]})
    }
    return options
}

function getRewards() {
    if (!IsBuildMyChance()) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rewardShopStream : undefined})
        getRewardsCount()
    }
    // console.log ('stream is undefined now')
}

function clearFields() {
    Logic.Type.New(Main.Instance.RewardShopState, {
        name : [''],
        info: [''/*,'','','','',''*/],
        // choose_category: ['','','','','',''],
        // image : Assets_.zeroPng,
        mode : false,
        // price: '',
        banner: '0',
        Category: '-1',
        category_idx: -1,
        CSV: {category1: {}, category2: {}, category3: {}},
        filter_category: '0',
        rewardIntent: [0],
        V1: [],
        V2: [],
        V3: [],
        I1: [],
        I2: [],
        I3: [],
        valid1: -1,
        valid2: -1,
        valid3: -1,
        invalid1: -1,
        invalid2: -1,
        invalid3: -1,
        bonusCode: ''
        // active: '1',
        // free_round: '2',
        // buyable: '1',
        // only_newly_registered: '1',
        // discount: '',
        //  index: '1'/*(Main.Instance.RewardShopState.count + 1).toString()*/
    })
/*
    if (Main.Instance.DatabaseDataState.GamesStreamAll) {
        const GamesStreamAllSelected: boolean[] = []
        const GamesStreamAllSelected2: boolean[] = []
        const GamesStreamAllSelected3: boolean[] = []

        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected.push(false)
            GamesStreamAllSelected2.push(false)
            GamesStreamAllSelected3.push(false)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected, GamesStreamAllSelected2, GamesStreamAllSelected3})
    }
*/
}

function gotoInsertMode() {
    if (// Main.Instance.RewardShopState.price === Main.Instance.RewardShopState.price_saved &&
        Main.Instance.RewardShopState.name[0] === Main.Instance.RewardShopState.name_saved[0] &&
        Main.Instance.RewardShopState.info[0] === Main.Instance.RewardShopState.info_saved[0] // &&
        // Main.Instance.RewardShopState.rewardFor === Main.Instance.RewardShopState.rewardFor_saved
        // Main.Instance.RewardShopState.banner === Main.Instance.RewardShopState.banner_saved &&
        // Main.Instance.RewardShopState.Category === Main.Instance.RewardShopState.Category_saved &&
        // Main.Instance.RewardShopState.active === Main.Instance.RewardShopState.active_saved &&
        // Main.Instance.RewardShopState.free_round === Main.Instance.RewardShopState.free_round_saved &&
        // Main.Instance.RewardShopState.buyable === Main.Instance.RewardShopState.buyable_saved &&
        // Main.Instance.RewardShopState.only_newly_registered === Main.Instance.RewardShopState.only_newly_registered_saved &&
        // Main.Instance.RewardShopState.discount === Main.Instance.RewardShopState.discount_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.RewardShopState, {offset:(0).toString()})

    getRewards()
    Logic.Type.New(Main.Instance.RewardShopState, {pNum: 0})

}

function PrevPage() {
    if (Main.Instance.RewardShopState.pNum > 0) {

        Logic.Type.New(Main.Instance.RewardShopState, {
            offset:((Main.Instance.RewardShopState.pNum - 1) * 
            Main.Instance.RewardShopState.pSize).toString()})
        getRewards()

        Logic.Type.New(Main.Instance.RewardShopState,{pNum: Main.Instance.RewardShopState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.RewardShopState.pNum + 1) * Main.Instance.RewardShopState.pSize < 
    Main.Instance.DatabaseDataState.rewardShopCount) {

        Logic.Type.New(Main.Instance.RewardShopState, {
            offset: ((Main.Instance.RewardShopState.pNum + 1) * 
            Main.Instance.RewardShopState.pSize).toString()})
        getRewards()
        
        Logic.Type.New(Main.Instance.RewardShopState,{pNum: Main.Instance.RewardShopState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.DatabaseDataState.rewardShopCount <= Main.Instance.RewardShopState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.DatabaseDataState.rewardShopCount === 0) {

            Logic.Type.New(Main.Instance.RewardShopState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 1) / 
                    Main.Instance.RewardShopState.pSize)
            Logic.Type.New(Main.Instance.RewardShopState, {
                offset:(npNum * Main.Instance.RewardShopState.pSize).toString()})
        }

        getRewards()

        Logic.Type.New(Main.Instance.RewardShopState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount ++})
    LastPage()
}

export function Update() {
    clearFields()
    getRewards()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount --})
    getRewards()
}

/*
function ApplyFilterRole(text:string) {

    Logic.Type.New(Main.Instance.RewardShopState,{filter_role:text})
    FirstPage()
}
*/

function ChangeRewardParameter(text: string, which: number) {
    // console.log (Main.Instance.RewardShopState.CSV)
    // console.log (text)
    // console.log (which)
    // console.log (Main.Instance.RewardShopState.category_idx)
    let newValue
    if (text.length === 0) { newValue = 0 }
    if (text[text.length - 1] === '.') { newValue = parseInt(text.substring(0, text.length - 1)) } else { newValue = parseFloat(text) }
    if (isNaN(newValue)) { return }
    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (newValue > -1) {
        if (Main.Instance.RewardShopState.category_idx === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            if (which === P.DURATION_MINUTES && CSV.category1.durationMinutes !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category1.durationHours !== undefined) { CSV.category1.durationHours++ } else { CSV.category1.durationHours = 1 }
            } else if (which === P.DURATION_SECONDS && CSV.category1.durationSeconds !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category1.durationMinutes !== undefined) { CSV.category1.durationMinutes++ } else { CSV.category1.durationMinutes = 1 }

                if (CSV.category1.durationMinutes === 60) {
                    CSV.category1.durationMinutes = 0
                    if (CSV.category1.durationHours !== undefined) { CSV.category1.durationHours++ } else { CSV.category1.durationHours = 1 }                        
                }
            }

            if (which === P.DURATION_HOURS) { CSV.category1.durationHours = newValue } else if (which === P.DURATION_MINUTES) { CSV.category1.durationMinutes = newValue } else if (which === P.DURATION_SECONDS) { CSV.category1.durationSeconds = newValue } else if (which === P.BOOST_MISSIONS_PERCENTAGE) {
                CSV.category1.boostMissionsPercentage = newValue
                // CSV.category1.BoostMissionsPercentage = text
            } else if (which === P.BOOST_POINTS_PERCENTAGE) {
                CSV.category1.boostPointsPercentage = newValue
                // CSV.category1.BoostPointsPercentage = text
            } else if (which === P.DAYS) { CSV.category1.days = newValue } else if (which === P.CASH_BACK_PERCENTAGE) { CSV.category1.cashBackPercentage = newValue } else if (which === P.CASH_BONUS_AMOUNT) { CSV.category1.cashBonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_AMOUNT) { CSV.category1.bonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_PERCENT) { CSV.category1.bonusPercent = newValue } else if (which === P.FLEXIBLE) { CSV.category1.isFlexible = !CSV.category1.isFlexible } else if (which === P.SPINS_AMOUNT) { CSV.category1.spinsAmount = newValue } else if (which === P.EXPIRATION_DAYS) { CSV.category1.expirationDays = newValue } else if (which === P.WAGERING_REQUIREMENT) { CSV.category1.wageringRequirement = newValue } else if (which === P.TOKENS) { CSV.category1.tokens = newValue }
        } else if (Main.Instance.RewardShopState.category_idx === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            if (which === P.DURATION_MINUTES && CSV.category2.durationMinutes !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category2.durationHours !== undefined) { CSV.category2.durationHours++ } else { CSV.category2.durationHours = 1 }
            } else if (which === P.DURATION_SECONDS && CSV.category2.durationSeconds !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category2.durationMinutes !== undefined) { CSV.category2.durationMinutes++ } else { CSV.category2.durationMinutes = 1 }

                if (CSV.category2.durationMinutes === 60) {
                    CSV.category2.durationMinutes = 0
                    if (CSV.category2.durationHours !== undefined) { CSV.category2.durationHours++ } else { CSV.category2.durationHours = 1 }                        
                }
            }

            if (which === P.DURATION_HOURS) { CSV.category2.durationHours = newValue } else if (which === P.DURATION_MINUTES) { CSV.category2.durationMinutes = newValue } else if (which === P.DURATION_SECONDS) { CSV.category2.durationSeconds = newValue } else if (which === P.BOOST_MISSIONS_PERCENTAGE) {
                CSV.category2.boostMissionsPercentage = newValue
                // CSV.category2.BoostMissionsPercentage = text
            } else if (which === P.BOOST_POINTS_PERCENTAGE) {
                CSV.category2.boostPointsPercentage = newValue
                // CSV.category2.BoostPointsPercentage = text
            } else if (which === P.DAYS) { CSV.category2.days = newValue } else if (which === P.CASH_BACK_PERCENTAGE) { CSV.category2.cashBackPercentage = newValue } else if (which === P.CASH_BONUS_AMOUNT) { CSV.category2.cashBonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_AMOUNT) { CSV.category2.bonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_PERCENT) { CSV.category2.bonusPercent = newValue } else if (which === P.FLEXIBLE) { CSV.category2.isFlexible = !CSV.category2.isFlexible } else if (which === P.SPINS_AMOUNT) { CSV.category2.spinsAmount = newValue } else if (which === P.EXPIRATION_DAYS) { CSV.category2.expirationDays = newValue } else if (which === P.WAGERING_REQUIREMENT) { CSV.category2.wageringRequirement = newValue } else if (which === P.TOKENS) { CSV.category2.tokens = newValue }
        } else if (Main.Instance.RewardShopState.category_idx === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            if (which === P.DURATION_MINUTES && CSV.category3.durationMinutes !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category3.durationHours !== undefined) { CSV.category3.durationHours++ } else { CSV.category3.durationHours = 1 }
            } else if (which === P.DURATION_SECONDS && CSV.category3.durationSeconds !== undefined && newValue === 60) {
                newValue = 0
                if (CSV.category3.durationMinutes !== undefined) { CSV.category3.durationMinutes++ } else { CSV.category3.durationMinutes = 1 }

                if (CSV.category3.durationMinutes === 60) {
                    CSV.category3.durationMinutes = 0
                    if (CSV.category3.durationHours !== undefined) { CSV.category3.durationHours++ } else { CSV.category3.durationHours = 1 }                        
                }
            }

            if (which === P.DURATION_HOURS) { CSV.category3.durationHours = newValue } else if (which === P.DURATION_MINUTES) { CSV.category3.durationMinutes = newValue } else if (which === P.DURATION_SECONDS) { CSV.category3.durationSeconds = newValue } else if (which === P.BOOST_MISSIONS_PERCENTAGE) {
                CSV.category3.boostMissionsPercentage = newValue
                // CSV.category3.BoostMissionsPercentage = text
            } else if (which === P.BOOST_POINTS_PERCENTAGE) {
                CSV.category3.boostPointsPercentage = newValue
                // CSV.category3.BoostPointsPercentage = text
            } else if (which === P.DAYS) { CSV.category3.days = newValue } else if (which === P.CASH_BACK_PERCENTAGE) { CSV.category3.cashBackPercentage = newValue } else if (which === P.CASH_BONUS_AMOUNT) { CSV.category3.cashBonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_AMOUNT) { CSV.category3.bonusAmount = newValue } else if (which === P.DEPOSIT_BONUS_PERCENT) { CSV.category3.bonusPercent = newValue } else if (which === P.FLEXIBLE) { CSV.category3.isFlexible = !CSV.category3.isFlexible } else if (which === P.SPINS_AMOUNT) { CSV.category3.spinsAmount = newValue } else if (which === P.EXPIRATION_DAYS) { CSV.category3.expirationDays = newValue } else if (which === P.WAGERING_REQUIREMENT) { CSV.category3.wageringRequirement = newValue } else if (which === P.TOKENS) { CSV.category3.tokens = newValue }
        }
        Logic.Type.New (Main.Instance.RewardShopState, {CSV})
    }
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RewardShopState, {pSize:parseInt(text)})
        FirstPage()
    }
}
/*
function handleEditIndex(rowData: string[], text: string) {
    let new_value: number = parseInt(text)
    let old_value: number = parseInt(rowData[1])

    if (new_value === old_value + 1 || new_value === old_value - 1) {
        
        if (new_value === old_value + 1) {
            new_value = old_value - 1
        } else {
            new_value = old_value + 1
        }

        if (new_value !== 0) DbRoutes.patchRewardShopItem (rowData[0], new_value.toString(), rowData[1], FirstPage)
    } else if (new_value !== 0) {
        DbRoutes.patchRewardShopItem (rowData[0], text, rowData[1], FirstPage)
    }
}
*/
/*
function IndexChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RewardShopState, {index: text})
    }
}
*/

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.RewardShopState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getRewardsCount()
    }
}

/*
function DeleteCategory() {
    if (Main.Instance.DatabaseDataState.languages) {
        let category: string[] = Object.assign ([], Main.Instance.FrontendStoreState.category)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++)
        category[(parseInt(Main.Instance.RewardShopState.category_id) - 1) * 
            Main.Instance.DatabaseDataState.languages.length + i] = ''
        Logic.Type.New(Main.Instance.FrontendStoreState, {category})
        Logic.Type.New(Main.Instance.RewardShopState, {category: ''})
        updateCategory()
    } else alert (Assets.pleaseDefineLanguages)
}
*/
/*
function LanguageContentShopColor(which: number): string {
    if (Main.Instance.RewardShopState.name && Main.Instance.RewardShopState.info &&
        Main.Instance.RewardShopState.name[which] !== null &&
        Main.Instance.RewardShopState.name[which] !== undefined &&
            Main.Instance.RewardShopState.name[which].length > 0 &&
        Main.Instance.RewardShopState.info[which] !== null &&
        Main.Instance.RewardShopState.info[which] !== undefined &&
            Main.Instance.RewardShopState.info[which].length > 0)
    return '#5cb85c'

    if (
        (Main.Instance.RewardShopState.name[which] === null ||
        Main.Instance.RewardShopState.name[which] === undefined ||
        Main.Instance.RewardShopState.name[which].length === 0)
        &&
        (Main.Instance.RewardShopState.info[which] === null ||
        Main.Instance.RewardShopState.info[which] === undefined ||
        Main.Instance.RewardShopState.info[which].length === 0)
    )
    return '#d9534f'

    return '#ec971f'
}
*/
/*
function LanguageContentColor(which: number): string {
    if (Main.Instance.FrontendStoreState.choose_category !== undefined &&
        Main.Instance.FrontendStoreState.choose_category !== null &&
        
        Main.Instance.FrontendStoreState.tb_title !== undefined &&
        Main.Instance.FrontendStoreState.tb_title !== null &&
        
        Main.Instance.FrontendStoreState.tb_content !== undefined &&
        Main.Instance.FrontendStoreState.tb_content !== null &&
        
        Main.Instance.FrontendStoreState.main_title !== undefined &&
        Main.Instance.FrontendStoreState.main_title !== null &&
        
        Main.Instance.FrontendStoreState.choose_category[which] !== undefined && 
        Main.Instance.FrontendStoreState.choose_category[which] !== null && 
        Main.Instance.FrontendStoreState.choose_category[which].length > 0 &&

        Main.Instance.FrontendStoreState.tb_title[which] !== undefined &&
        Main.Instance.FrontendStoreState.tb_title[which] !== null && 
        Main.Instance.FrontendStoreState.tb_title[which].length > 0 &&
        
        Main.Instance.FrontendStoreState.tb_content[which] !== null && 
        Main.Instance.FrontendStoreState.tb_content[which] !== undefined && 
        Main.Instance.FrontendStoreState.tb_content[which].length > 0 &&
        
        Main.Instance.FrontendStoreState.main_title[which] !== null && 
        Main.Instance.FrontendStoreState.main_title[which] !== undefined && 
        Main.Instance.FrontendStoreState.main_title[which].length > 0    
    )
    return '#5cb85c'

    if (Main.Instance.FrontendStoreState.choose_category !== undefined &&
        Main.Instance.FrontendStoreState.choose_category !== null &&

        Main.Instance.FrontendStoreState.tb_title !== undefined &&
        Main.Instance.FrontendStoreState.tb_title !== null &&

        Main.Instance.FrontendStoreState.tb_content !== undefined &&
        Main.Instance.FrontendStoreState.tb_content !== null &&

        Main.Instance.FrontendStoreState.main_title !== undefined &&
        Main.Instance.FrontendStoreState.main_title !== null &&

        (Main.Instance.FrontendStoreState.choose_category[which] === null ||
        Main.Instance.FrontendStoreState.choose_category[which] === undefined || 
        Main.Instance.FrontendStoreState.choose_category[which].length === 0)
        &&        
        (Main.Instance.FrontendStoreState.tb_title[which] === null ||
        Main.Instance.FrontendStoreState.tb_title[which] === undefined || 
        Main.Instance.FrontendStoreState.tb_title[which].length === 0)
        &&
        (Main.Instance.FrontendStoreState.tb_content[which] === null ||
        Main.Instance.FrontendStoreState.tb_content[which] === undefined || 
        Main.Instance.FrontendStoreState.tb_content[which].length === 0)
        &&
        (Main.Instance.FrontendStoreState.main_title[which] === null ||
        Main.Instance.FrontendStoreState.main_title[which] === undefined || 
        Main.Instance.FrontendStoreState.main_title[which].length === 0)
    )
    return '#d9534f'

    return '#ec971f'
}
*/


function SelectCategory(category_idx: number) {
    Logic.Type.New (Main.Instance.RewardShopState, {category_idx})
/*
    let GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    for (let i: number = 0; i < GamesStreamAllSelected.length; i++) {
        GamesStreamAllSelected[i] = false
        if (category)
        if (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'] === Item) {

        }
    }
    GamesStreamAllSelected[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected[which]

    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
*/
}

function getCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []

    if (Main.Instance.FrontendStoreState.Category2) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.Category2.length; i++) {
        // alert (i + ' ' + Main.Instance.FrontendStoreState.Category2[i])
        categories.push (
            <button
                title = {i === 0 ? Assets.RewardDescription : Assets.SelectCategory}
                style = {{borderRadius: '0px', fontSize: i === 0 ? '' : '16px', lineHeight: '18px'}}
                key = {i.toString()}
                className = {i - 1 === Main.Instance.RewardShopState.category_idx ? 
                             i === 0 ? 'btn btn-info btn-sm mr0 ml' : 'btn btn-info btn-sm mr0' :
                             i === 0 ? 'btn btn-default btn-sm mr0 ml' : 'btn btn-default btn-sm mr0'}
                onClick = {() => SelectCategory(i - 1)}
            >
                {Main.Instance.FrontendStoreState.Category2[i]['name'] === Assets.Description
                ? <i className = {Assets_.faReportLightClass}/>
                : Main.Instance.FrontendStoreState.Category2[i]['name']}
            </button>,
        )
    }
    }
    return categories
}

function SelectRewardType(Category: number) {
    // console.log(Category)
    if (Category === 0) { Logic.Type.New (Main.Instance.RewardShopState, {rewardIntent: [0], Category: Category.toString()}) } else { Logic.Type.New (Main.Instance.RewardShopState, {Category: Category.toString()}) }
}

function SelectRewardIntent(rewardIntentSingle: number) {
    let rewardIntent: number[] = Object.assign([], Main.Instance.RewardShopState.rewardIntent)
    let foundZero: number = -1
    for (let i: number = 0; i < rewardIntent.length; i++) {
        if (rewardIntent[i] === Assets_.RewardIntent.Generic || rewardIntent[i] === Assets_.RewardIntent.Store) {
            foundZero = i
            break
        }
    } 

    if (foundZero > -1 && (rewardIntentSingle === Assets_.RewardIntent.Generic || rewardIntentSingle === Assets_.RewardIntent.Store)) {
        rewardIntent = []
    } else if (rewardIntentSingle === Assets_.RewardIntent.Generic) {
        rewardIntent = [Assets_.RewardIntent.Generic]
    } else if (rewardIntentSingle === Assets_.RewardIntent.Store) {
        rewardIntent = [Assets_.RewardIntent.Store]
    } else {
        let found: number = -1
        for (let i: number = 0; i < rewardIntent.length; i++) { if (rewardIntent[i] === rewardIntentSingle) {found = i; break} }

        if (found === -1) {
            if (foundZero > -1) { rewardIntent.splice(foundZero, 1) }
            rewardIntent.push(rewardIntentSingle)
        } else {
            rewardIntent.splice(found, 1)
            if (rewardIntent.length === 0) { rewardIntent = [Assets_.RewardIntent.Generic] }
        }
        
    }
    Logic.Type.New (Main.Instance.RewardShopState, {rewardIntent})
}

function getRewardIntent(): JSX.Element[] {
    // const rewardintent: string[] = ['Select Reward Intent', 'Missions', Assets.Levels, 'Tournaments', 'Challenges', 'Store', 'Generic']
    const rewardIntent: JSX.Element[] = []
    // alert ('here')
    // alert(Main.Instance.RewardShopState.Category)
    for (let i: number = 0; i < Assets_.RewardIntentIcons.length; i++) {
        if (+Main.Instance.RewardShopState.Category === 0 && i > 0) { continue }
        let found = false
        for (const item of Main.Instance.RewardShopState.rewardIntent) {
            if (item === i) {
                found = true
                break
            }
        }
        rewardIntent.push (
            <button
                title = {Assets.SelectRewardIntent + ': ' + Assets_.RewardIntent[i]}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === 0 && Main.Instance.RewardShopState.rewardIntent.length === 0 || found ?
                i === 0 ? 'btn btn-primary btn-sm mr0' : i === Assets_.RewardIntent.Store ? 'btn btn-danger btn-sm mr0' : 'btn btn-success btn-sm mr0' : 'btn btn-default btn-sm mr0'}
                onClick = {() => SelectRewardIntent(i)}
            >
                <i className = {Assets_.RewardIntentIcons[i]}/>&nbsp;&nbsp;{Assets.RewardIntentStrings[i]}
            </button>,
        )
    }
    
    return rewardIntent
}

function getRewardTypes(): JSX.Element[] {
    const rewardTypes: JSX.Element[] = []

    rewardTypes.push (
        <button
            title = {Assets.NoRewardTypeIsSelected}
            style = {{borderRadius: '0px', paddingLeft: '8px', paddingRight: '8px'}}
            key = "-1"
            className = {-1 === parseInt(Main.Instance.RewardShopState.Category, 10) ? 'btn btn-danger btn-sm mr0' : 'btn btn-default btn-sm mr0'}
            onClick = {() => SelectRewardType(-1)}
        >
            <i className = {Assets_.faRewardLgClass}/>
        </button>,
    )
    
    if (Main.Instance.FrontendStoreState.Category) {
        
        // console.log(Main.Instance.RewardShopState.Category)
        for (let i: number = 1; i < Main.Instance.FrontendStoreState.Category.length; i++) {
            if (!isPureOddyzzey() && !IsBuildMyChance() && (i === 3 || i === 7)) { continue }
            if (IsBuildMyChance()) {
                // console.log(Main.Instance.FrontendStoreState.Category[i].id)
                
                rewardTypes.push (
                    <button
                        title = {Assets.SelectRewardType}
                        style = {{borderRadius: '0px', paddingLeft: '8px', paddingRight: '8px'}}
                        key = {Main.Instance.FrontendStoreState.Category[i].id}
                        className = {+Main.Instance.FrontendStoreState.Category[i].id === parseInt(Main.Instance.RewardShopState.Category) ? 'btn btn-primary btn-sm mr0' : 'btn btn-default btn-sm mr0'}
                        onClick = {() => SelectRewardType(Main.Instance.FrontendStoreState.Category[i].id)}
                    >
                        {Main.Instance.FrontendStoreState.Category[i]['name']}
                    </button>,
                )
            } else {
                rewardTypes.push (
                    <button
                        title = {Assets.SelectRewardType}
                        style = {{borderRadius: '0px', paddingLeft: '8px', paddingRight: '8px'}}
                        key = {i.toString()}
                        className = {i - 1 === parseInt(Main.Instance.RewardShopState.Category, 10) ? 'btn btn-primary btn-sm mr0' : 'btn btn-default btn-sm mr0'}
                        onClick = {() => SelectRewardType(i - 1)}
                    >
                        {Main.Instance.FrontendStoreState.Category[i]['name']}
                    </button>,
                )
            }
        
        }
    } 
    return rewardTypes
}

function updateTime(which: number, text: string) {
    if (text.length > 8) { return }
    for (let i: number = 0; i < text.length; i++) {
        if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && 
            text.charAt(i) !== '5' && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== ':') {
            return
        }
    }

    const CSV = Object.assign({}, Main.Instance.RewardShopState.CSV)

    if (which === 0) {
        if (Main.Instance.RewardShopState.category_idx === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            CSV.category1.fromTime = text
        } else if (Main.Instance.RewardShopState.category_idx === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            CSV.category2.fromTime = text
        } else if (Main.Instance.RewardShopState.category_idx === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            CSV.category3.fromTime = text
        }
    } else if (which === 1) {
        if (Main.Instance.RewardShopState.category_idx === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            CSV.category1.toTime = text
        } else if (Main.Instance.RewardShopState.category_idx === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            CSV.category2.toTime = text
        } else if (Main.Instance.RewardShopState.category_idx === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            CSV.category3.toTime = text
        }
    }
    Logic.Type.New(Main.Instance.RewardShopState, {CSV})
}

function DateChange(which: number, date: moment.Moment) {
    // category_idx
    const temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    const CSV = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) {
        if (Main.Instance.RewardShopState.category_idx === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            CSV.category1.from_date = date
            CSV.category1.fromDate = temp
        } else if (Main.Instance.RewardShopState.category_idx === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            CSV.category2.from_date = date
            CSV.category2.fromDate = temp
        } else if (Main.Instance.RewardShopState.category_idx === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            CSV.category3.from_date = date
            CSV.category3.fromDate = temp
        }
    } else if (which === 1) {
        if (Main.Instance.RewardShopState.category_idx === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            CSV.category1.to_date = date
            CSV.category1.toDate = temp
        } else if (Main.Instance.RewardShopState.category_idx === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            CSV.category2.to_date = date
            CSV.category2.toDate = temp
        } else if (Main.Instance.RewardShopState.category_idx === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            CSV.category3.to_date = date
            CSV.category3.toDate = temp
        }
    }
    Logic.Type.New(Main.Instance.RewardShopState, {CSV})
}

function getDuration(boost_s: number, which: number) {
    let boost_h: number = 0
    let boost_m: number = 0
    if (boost_s >= 3600) {
        boost_h = Math.floor (boost_s / 3600)
        boost_s -= boost_h * 3600
    }

    if (boost_s >= 60) {
        boost_m = Math.floor (boost_s / 60)
        boost_s -= boost_m * 60
    }

    if (which === 0) { return boost_h } else if (which === 1) { return boost_m } else if (which === 2) { return boost_s }
}
/*
function OpenGS2() {
    Logic.Type.New (Main.Instance.DatabaseDataState, {FlagsStreamAllSelected: undefined})
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: true})
    Logic.Type.New(Main.Instance.GamesState, {categorize: true})
}
*/

function tableFilters (value, accessor) {
    const filtered = Object.assign ([], Main.Instance.RewardShopState.filtered)  
    let insertNewFilter: boolean = true

    if (filtered.length) {
        filtered.forEach((filter, i) => {
            if(filter.id === accessor) {
                if (value === '' || !value.length) {
                    filtered.splice(i, 1)
                } else {
                    filter.value = value
                }
                insertNewFilter = false
            }
        })
    }

    if (insertNewFilter) {
        filtered.push({id: accessor, value})
    }
    Logic.Type.New(Main.Instance.RewardShopState, {filtered})
}

export function Rewards() {

    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        }
    }

    // const filtered = []
    // if (IsBuildMyChance()) {
    //     // RewardTypes
    //     // console.log (Main.Instance.RewardShopState.filter_type)
    //     if (Main.Instance.RewardShopState.filter_type !== '0') {
    //         filtered.push({id: 'Reward type', value: Main.Instance.RewardShopState.filter_type})
    //     }
    // }
    // console.log(filtered)
    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    // letcustomCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
    let from: string = ''
    let to: string = ''
    if (Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
    Main.Instance.RewardShopState.CSV.category1.fromTime) { from = Main.Instance.RewardShopState.CSV.category1.fromTime }

    if (Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
    Main.Instance.RewardShopState.CSV.category2.fromTime) { from = Main.Instance.RewardShopState.CSV.category2.fromTime }
    
    if (Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
    Main.Instance.RewardShopState.CSV.category3.fromTime) { from = Main.Instance.RewardShopState.CSV.category3.fromTime }

    if (Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
    Main.Instance.RewardShopState.CSV.category1.toTime) { to = Main.Instance.RewardShopState.CSV.category1.toTime }

    if (Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
    Main.Instance.RewardShopState.CSV.category2.toTime) { to = Main.Instance.RewardShopState.CSV.category2.toTime }
    
    if (Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
    Main.Instance.RewardShopState.CSV.category3.toTime) { to = Main.Instance.RewardShopState.CSV.category3.toTime }    

    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (from)
    const validationToTime: boolean = utilsTimeValidEmptyNotValid (to)

    return (<div>
    {<Modal isOpen = {Main.Instance.Modal.ModalWagerPlanGames} close = {() => CloseGS()}>
        {GamesSelection(false, Main.Instance.RewardShopState.category_idx)}
    </Modal>}
    <div className = "clearfix">
        <h3 className = "level fl">
            {Assets.AddEditRewards}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
        {/*<div className = "calendar-header">{flags}</div>*/}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.RewardShopState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>
                            {Main.Instance.RewardShopState.mode ? 
                            Assets.EditTheReward + ': ' + Main.Instance.RewardShopState.name_saved[0] :
                            Assets.AddANewReward}
                            {/*FlagsShop*/}
                        </h4>
{/*
                        <RadioButton2
                            value = {!Main.Instance.RewardShopState.active ? '1' :
                                    Main.Instance.RewardShopState.active} 
                            text = {Assets.Activity}
                            ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                            title = {Assets.ActivityDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => {
                                Logic.Type.New(Main.Instance.RewardShopState, {active:text})}} 
                        />
*/}
                        {IsBuildMyChance() ? void 0 : 
                        <div style = {{position: 'absolute', right: '0px', top: '-5px'}}>
                            {getRewardIntent()}
                        </div>}
                        {Main.Instance.RewardShopState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}

                    </div>

                    <div className = "row admin-users-row">
                {/*console.log (Main.Instance.RewardShopState.name)*/}
                <FormInput
                    // thick = {true}
                    description = {Assets.RewardName}
                    value = {!Main.Instance.RewardShopState.name[/*Main.Instance.DatabaseDataState.language*/0] ? '' :
                            Main.Instance.RewardShopState.name[/*Main.Instance.DatabaseDataState.language*/0]} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateName(text)} 
                    placeholder = {Assets.Reward + ' ' + Assets.pName.toLowerCase()}
                    icon = {Assets_.faRewardLgClass}
                />

                 
                <FormInput
                    // thick = {true}
                    narrow = {true}
                    description = {Assets.BonusCode}
                    value = {IsBuildMyChance() ? Main.Instance.RewardShopState.bonusCode : !Main.Instance.RewardShopState.name[1] ? '' :
                            Main.Instance.RewardShopState.name[1]} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateBonusCode(text)} 
                    placeholder = {Assets.Reward + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.Code.toLowerCase()}
                    Icon = {faCode}
                />
                
               
                {IsBuildMyChance() ? void 0 :
                    <FormInput
                    // thick = {true}
                    narrow = {true}
                    description = {Assets.RewardPrice}
                    value = {!Main.Instance.RewardShopState.name[2] ? '' :
                            Main.Instance.RewardShopState.name[2]} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updatePrice(text)} 
                    placeholder = {Assets.Reward + ' ' + Assets.Price.toLowerCase()}
                    Icon = {faMoneyBillWaveAlt}
                />
                }
                

                {getRewardTypes()}
                {IsBuildMyChance() ? void 0 : parseInt(Main.Instance.RewardShopState.Category) === -1 
                || parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME || parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL
                || Main.Instance.RewardShopState.rewardIntent[0] === Assets_.RewardIntent.Store
                ? (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME || parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL
                || Main.Instance.RewardShopState.rewardIntent[0] === Assets_.RewardIntent.Store) 
                && Main.Instance.RewardShopState.category_idx !== 0 ? SelectCategory(0) : void 0 : getCategories()}
            </div>

            {/* BOOST_GLOBAL */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                    <span style = {{fontSize: '14px', paddingTop: '6px', paddingBottom: '6px', color: '#333', background: '#fff'}} className = "label label-default label-sm">{Assets.Start}</span>
                </div>
                <div className = "fl mr singledatepicker">
                    <SingleDatePicker
                        id = "date1"
                        placeholder = {Assets.Date}
                        date = {
                            Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                            Main.Instance.RewardShopState.CSV.category1.from_date ? Main.Instance.RewardShopState.CSV.category1.from_date :
                            Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                            Main.Instance.RewardShopState.CSV.category2.from_date ? Main.Instance.RewardShopState.CSV.category2.from_date :
                            Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                            Main.Instance.RewardShopState.CSV.category3.from_date ? Main.Instance.RewardShopState.CSV.category3.from_date : null
                        } 
                        onDateChange = {(date) => DateChange(0, date)}
                        focused = {
                            Main.Instance.RewardShopState.category_idx === 0 ? Main.Instance.RewardShopState.focusedInput01 :
                            Main.Instance.RewardShopState.category_idx === 1 ? Main.Instance.RewardShopState.focusedInput11 :
                            Main.Instance.RewardShopState.category_idx === 2 ? Main.Instance.RewardShopState.focusedInput21 : null
                        }
                        onFocusChange = {
                            (focused) => {
                                if (Main.Instance.RewardShopState.category_idx === 0) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput01: focused.focused})
                                } else if (Main.Instance.RewardShopState.category_idx === 1) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput11: focused.focused})
                                } else if (Main.Instance.RewardShopState.category_idx === 2) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput21: focused.focused})
                                }
                            }
                        }
                        showDefaultInputIcon = {true}
                        showClearDate = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets_.dateDisplayFormatdmy}
                        // customArrowIcon = {Assets_.customArrowIcon}
                        customCloseIcon = {Assets_.customCloseIcon}
                        numberOfMonths = {1}
                    />
                </div>
                <FormInput
                    validation = {validationFromTime}
                    date = {true}
                    value = {
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.fromTime ? Main.Instance.RewardShopState.CSV.category1.fromTime :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.fromTime ? Main.Instance.RewardShopState.CSV.category2.fromTime :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.fromTime ? Main.Instance.RewardShopState.CSV.category3.fromTime : ''
                    } 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(0, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
                <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700, marginLeft: '10px'}} className = "fl">
                    <span style = {{fontSize: '14px', paddingTop: '6px', paddingBottom: '6px', color: '#333', background: '#fff'}} className = "label label-default label-sm">{Assets.End}</span>
                </div>
                <div className = "fl mr singledatepicker">
                    <SingleDatePicker
                        id = "date2"
                        placeholder = {Assets.Date}
                        date = {
                            Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                            Main.Instance.RewardShopState.CSV.category1.to_date ? Main.Instance.RewardShopState.CSV.category1.to_date :
                            Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                            Main.Instance.RewardShopState.CSV.category2.to_date ? Main.Instance.RewardShopState.CSV.category2.to_date :
                            Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                            Main.Instance.RewardShopState.CSV.category3.to_date ? Main.Instance.RewardShopState.CSV.category3.to_date : null
                        } 
                        onDateChange = {(date) => DateChange(1, date)}
                        focused = {
                            Main.Instance.RewardShopState.category_idx === 0 ? Main.Instance.RewardShopState.focusedInput02 :
                            Main.Instance.RewardShopState.category_idx === 1 ? Main.Instance.RewardShopState.focusedInput12 :
                            Main.Instance.RewardShopState.category_idx === 2 ? Main.Instance.RewardShopState.focusedInput22 : null
                        }
                        onFocusChange = {
                            (focused) => {
                                if (Main.Instance.RewardShopState.category_idx === 0) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput02: focused.focused})
                                } else if (Main.Instance.RewardShopState.category_idx === 1) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput12: focused.focused})
                                } else if (Main.Instance.RewardShopState.category_idx === 2) {
                                    Logic.Type.New(Main.Instance.RewardShopState, {focusedInput22: focused.focused})
                                }
                            }
                        }
                        showDefaultInputIcon = {true}
                        showClearDate = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets_.dateDisplayFormatdmy}
                        // customArrowIcon = {Assets_.customArrowIcon}
                        customCloseIcon = {Assets_.customCloseIcon}
                        numberOfMonths = {1}
                    />
                </div>
                <FormInput
                    validation = {validationToTime}
                    date = {true}
                    value = {
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.toTime ? Main.Instance.RewardShopState.CSV.category1.toTime :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.toTime ? Main.Instance.RewardShopState.CSV.category2.toTime :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.toTime ? Main.Instance.RewardShopState.CSV.category3.toTime : ''
                    } 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(1, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
                <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">&nbsp;&nbsp;&nbsp;</div>
                <FormInputNumber
                    description = {Assets.BoostMissionsMultiplier}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.BoostMissions + ' x'}
                    iconTextS = "14px"
                    // icon = {Assets_.faPercentageClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.BOOST_MISSIONS_PERCENTAGE)}
                />

                <FormInputNumber
                    description = {Assets.BoostPointsMultiplier}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.BoostPoints + ' x'}
                    iconTextS = "14px"
                    // icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.BOOST_POINTS_PERCENTAGE)}
                />
            </div> : void 0}

            {/* BOOST_PLAYER_SPECIFIC */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                {/*<i className = {Assets_.faHourglassClass + ' fl dbl mr'} style = {{fontSize: '24px', marginTop: '2px'}}/>*/}
                <span style = {{fontSize: '16px', fontWeight: 700}} className = "fl dbl mr mt5">{Assets.BoostTime}</span>
                <FormInputNumber
                    description = {Assets.DurationHours}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w50px"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.durationHours ? Main.Instance.RewardShopState.CSV.category1.durationHours :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.durationHours ? Main.Instance.RewardShopState.CSV.category2.durationHours :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.durationHours ? Main.Instance.RewardShopState.CSV.category3.durationHours : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    // icon = {Assets_.faTimeLgClass}
                    iconText = "hrs"
                    onChange = {(text) => ChangeRewardParameter(text, P.DURATION_HOURS)}
                />

                {/*<div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">hours</div>*/}

                <FormInputNumber
                    description = {Assets.DurationMinutes}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w50px"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.durationMinutes ? Main.Instance.RewardShopState.CSV.category1.durationMinutes :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.durationMinutes ? Main.Instance.RewardShopState.CSV.category2.durationMinutes:
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.durationMinutes ? Main.Instance.RewardShopState.CSV.category3.durationMinutes : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    max = "60"
                    // icon = {Assets_.faTimeLgClass}
                    iconText = "min"
                    onChange = {(text) => ChangeRewardParameter(text, P.DURATION_MINUTES)}
                />

                {/*<div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">minutes</div>*/}

                <FormInputNumber
                    description = {Assets.DurationSeconds}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w50px"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.durationSeconds ? Main.Instance.RewardShopState.CSV.category1.durationSeconds :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.durationSeconds ? Main.Instance.RewardShopState.CSV.category2.durationSeconds :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.durationSeconds ? Main.Instance.RewardShopState.CSV.category3.durationSeconds : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    max = "60"
                    // icon = {Assets_.faTimeLgClass}
                    iconText = "sec"
                    onChange = {(text) => ChangeRewardParameter(text, P.DURATION_SECONDS)}
                />

                {/*<div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">seconds</div>*/}

                <FormInputNumber
                    description = {Assets.BoostMissionsMultiplier}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category1.boostMissionsPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category2.boostMissionsPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage ? Main.Instance.RewardShopState.CSV.category3.boostMissionsPercentage.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.BoostMissions + ' x'}
                    iconTextS = "14px"
                    // icon = {Assets_.faPercentageClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.BOOST_MISSIONS_PERCENTAGE)}
                />

                <FormInputNumber
                    description = {Assets.BoostPointsMultiplier}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category1.boostPointsPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category2.boostPointsPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage ? Main.Instance.RewardShopState.CSV.category3.boostPointsPercentage.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.BoostPoints + ' x'}
                    iconTextS = "14px"
                    // icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.BOOST_POINTS_PERCENTAGE)}
                />

                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.ExpirationTime_Days}
                    iconTextS = "14px"
                    /*icon = {Assets_.faTimeLgClass}*/
                    onChange = {(text) => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
            </div> : void 0}

            {/* CASH BACK */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.CASH_BACK ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.days ? Main.Instance.RewardShopState.CSV.category1.days.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.days ? Main.Instance.RewardShopState.CSV.category2.days.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.days ? Main.Instance.RewardShopState.CSV.category3.days.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconTextS = "14px"
                    iconText = {Assets.Days}
                    // icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.DAYS)}
                />
                {/*<div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>*/}
                <FormInputNumber
                    description = {Assets.CashBackPercentage}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.cashBackPercentage ? Main.Instance.RewardShopState.CSV.category1.cashBackPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.cashBackPercentage ? Main.Instance.RewardShopState.CSV.category2.cashBackPercentage.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.cashBackPercentage ? Main.Instance.RewardShopState.CSV.category3.cashBackPercentage.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.CashBack + ' %'}
                    iconTextS = "14px"
                    // icon = {Assets_.faPercentageClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.CASH_BACK_PERCENTAGE)}
                />
                {/*<div style = {{marginTop: '5px', marginRight: '0px'}} className = "fl">%</div>*/}
            </div> : void 0}

            {/* CASH BONUS */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.CASH_BONUS ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>
                <FormInputNumber
                    description = {Assets.CashBonusAmount}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.cashBonusAmount ? Main.Instance.RewardShopState.CSV.category1.cashBonusAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.cashBonusAmount ? Main.Instance.RewardShopState.CSV.category2.cashBonusAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.cashBonusAmount ? Main.Instance.RewardShopState.CSV.category3.cashBonusAmount.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.CashBonusAmount}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.CASH_BONUS_AMOUNT)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    description = {Assets.WageringRequirement}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.wageringRequirement ? Main.Instance.RewardShopState.CSV.category1.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.wageringRequirement ? Main.Instance.RewardShopState.CSV.category2.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.wageringRequirement ? Main.Instance.RewardShopState.CSV.category3.wageringRequirement.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.WageringRequirement + ' x'}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.WAGERING_REQUIREMENT)}
                />

                <FormInput
                    description = {Assets.BonusCode}
                    ClassName = "h30"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.bonusCode ? Main.Instance.RewardShopState.CSV.category1.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.bonusCode ? Main.Instance.RewardShopState.CSV.category2.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.bonusCode ? Main.Instance.RewardShopState.CSV.category3.bonusCode : '' : ''
                    }
                    type = "text" 
                    iconText = {Assets.BonusCode}
                    iconTextSize = "14px"
                    onChange = {(text) => {
                        const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)   
                        if (Main.Instance.RewardShopState.category_idx === 0) { CSV.category1.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 1) { CSV.category2.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 2) { CSV.category3.bonusCode = text }
                        Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                    }}
                />

            </div> : void 0}

            {/* DEPOSIT BONUS */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.DEPOSIT_BONUS ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>
                <FormInputNumber
                    description = {Assets.DepositBonusAmount}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.bonusAmount ? Main.Instance.RewardShopState.CSV.category1.bonusAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.bonusAmount ? Main.Instance.RewardShopState.CSV.category2.bonusAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.bonusAmount ? Main.Instance.RewardShopState.CSV.category3.bonusAmount.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.DepositBonusAmount}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.DEPOSIT_BONUS_AMOUNT)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    description = {Assets.DepositBonusPercentage}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.bonusPercent ? Main.Instance.RewardShopState.CSV.category1.bonusPercent.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.bonusPercent ? Main.Instance.RewardShopState.CSV.category2.bonusPercent.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.bonusPercent ? Main.Instance.RewardShopState.CSV.category3.bonusPercent.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.DepositBonus + ' %'}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.DEPOSIT_BONUS_PERCENT)}
                />
                {/*<div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">%</div>*/}

                <FormInputNumber
                    description = {Assets.WageringRequirement}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.wageringRequirement ? Main.Instance.RewardShopState.CSV.category1.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.wageringRequirement ? Main.Instance.RewardShopState.CSV.category2.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.wageringRequirement ? Main.Instance.RewardShopState.CSV.category3.wageringRequirement.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.WageringRequirement + ' x'}
                    iconTextS = "14px"
                    // icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.WAGERING_REQUIREMENT)}
                />

                <FormInput
                    description = {Assets.BonusCode}
                    ClassName = "h30"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.bonusCode ? Main.Instance.RewardShopState.CSV.category1.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.bonusCode ? Main.Instance.RewardShopState.CSV.category2.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.bonusCode ? Main.Instance.RewardShopState.CSV.category3.bonusCode : '' : ''
                    }
                    type = "text" 
                    iconText = {Assets.BonusCode}
                    iconTextSize = "14px"
                    onChange = {(text) => {
                        const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)   
                        if (Main.Instance.RewardShopState.category_idx === 0) { CSV.category1.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 1) { CSV.category2.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 2) { CSV.category3.bonusCode = text }
                        Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                    }}
                />

            </div> : void 0}

            {/* FREE SPINS */}
            {/*Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.FREE_SPINS ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {text => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>

                <button
                    title = "Click to toggle between freespin and flexible freespin"
                    onClick = {() => ChangeRewardParameter('0', P.FLEXIBLE)}
                    className = {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                                Main.Instance.RewardShopState.CSV.category1.isFlexible ? 'btn btn-sm btn-success' :
                                Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                                Main.Instance.RewardShopState.CSV.category2.isFlexible ? 'btn btn-sm btn-success' :
                                Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                                Main.Instance.RewardShopState.CSV.category3.isFlexible ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'}
                >
                    Flexible
                </button>

                {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                 Main.Instance.RewardShopState.CSV.category1.game ?
                <button
                    title = {Assets.ClickToChangeTheSelection}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-success"
                >
                    {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category1.gameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 0 ?
                <button
                    title = "Click to select a game"
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Game'}
                </button> : void 0}

                {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && 
                 Main.Instance.RewardShopState.CSV.category2.game ?
                <button
                    title = "Click to select any other game"
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-success"
                >
                   {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category2.gameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 1 ?
                <button
                    title = "Click to select a game"
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Game'}
                </button> : void 0}

                {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 && 
                 Main.Instance.RewardShopState.CSV.category3.game ?
                <button
                    title = "Click to select any other game"
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-success"
                >
                    {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category3.gameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 2 ?
                <button
                    title = "Click to select a game"
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Game'}
                </button> : void 0}

                <FormInputNumber
                    description = {Assets.FreeSpinsValue}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.spinValue ? Main.Instance.RewardShopState.CSV.category1.spinValue.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && 
                        Main.Instance.RewardShopState.CSV.category2.spinValue ? Main.Instance.RewardShopState.CSV.category2.spinValue.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.spinValue ? Main.Instance.RewardShopState.CSV.category3.spinValue.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = "Value"
                    iconTextS = "14px"
                    onChange = {text => ChangeRewardParameter(text, P.SPINS_VALUE)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>

                <FormInputNumber
                    description = "Free spins amount"
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1
                        && Main.Instance.RewardShopState.CSV.category1.spinsAmount ? Main.Instance.RewardShopState.CSV.category1.spinsAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2
                        && Main.Instance.RewardShopState.CSV.category2.spinsAmount ? Main.Instance.RewardShopState.CSV.category2.spinsAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3
                        && Main.Instance.RewardShopState.CSV.category3.spinsAmount ? Main.Instance.RewardShopState.CSV.category3.spinsAmount.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.Amount}
                    iconTextS = "14px"
                    onChange = {text => ChangeRewardParameter(text, P.SPINS_AMOUNT)}
                />

                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    description = {Assets.WageringRequirement}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.wageringRequirement ? Main.Instance.RewardShopState.CSV.category1.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.wageringRequirement ? Main.Instance.RewardShopState.CSV.category2.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.wageringRequirement ? Main.Instance.RewardShopState.CSV.category3.wageringRequirement.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = "Wagering requirement x"
                    iconTextS = "14px"
                    onChange = {text => ChangeRewardParameter(text, P.WAGERING_REQUIREMENT)}
                />
            </div> : void 0*/}

            {/* FREE SPINS */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.FREE_SPINS ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>

                <FormInputNumber
                    description = {Assets.FreeSpinsAmount}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1
                        && Main.Instance.RewardShopState.CSV.category1.spinsAmount ? Main.Instance.RewardShopState.CSV.category1.spinsAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2
                        && Main.Instance.RewardShopState.CSV.category2.spinsAmount ? Main.Instance.RewardShopState.CSV.category2.spinsAmount.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3
                        && Main.Instance.RewardShopState.CSV.category3.spinsAmount ? Main.Instance.RewardShopState.CSV.category3.spinsAmount.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.Amount}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.SPINS_AMOUNT)}
                />
                <FormInputNumber
                    description = {Assets.WageringRequirement}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.wageringRequirement ? Main.Instance.RewardShopState.CSV.category1.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.wageringRequirement ? Main.Instance.RewardShopState.CSV.category2.wageringRequirement.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.wageringRequirement ? Main.Instance.RewardShopState.CSV.category3.wageringRequirement.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = {Assets.WageringRequirement + ' x'}
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.WAGERING_REQUIREMENT)}
                />

                <FormInput
                    description = {Assets.BonusCode}
                    ClassName = "h30"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.bonusCode ? Main.Instance.RewardShopState.CSV.category1.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.bonusCode ? Main.Instance.RewardShopState.CSV.category2.bonusCode :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.bonusCode ? Main.Instance.RewardShopState.CSV.category3.bonusCode : '' : ''
                    }
                    type = "text" 
                    iconText = {Assets.BonusCode}
                    iconTextSize = "14px"
                    onChange = {(text) => {
                        const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)   
                        if (Main.Instance.RewardShopState.category_idx === 0) { CSV.category1.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 1) { CSV.category2.bonusCode = text } else if (Main.Instance.RewardShopState.category_idx === 2) { CSV.category3.bonusCode = text }
                        Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                    }}
                />

                <button
                    title = {Assets.ClickToToggleBetweenFreespinAndFlexibleFreespin}
                    onClick = {() => ChangeRewardParameter('0', P.FLEXIBLE)}
                    className = {
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 &&
                        Main.Instance.RewardShopState.CSV.category1.isFlexible ? 'btn btn-sm btn-success fl' :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.isFlexible ? 'btn btn-sm btn-success fl' :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.isFlexible ? 'btn btn-sm btn-success fl' : 'btn btn-sm btn-danger fl'
                    }
                >
                    {Assets.Flexible}
                </button>

                {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                 Main.Instance.RewardShopState.CSV.category1.gameIds && Main.Instance.RewardShopState.CSV.category1.gameIds.length > 0 ?
                <>
                <button
                    title = {Assets.ClickToChangeTheSelection}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-success fl"
                >
                    {Main.Instance.RewardShopState.CSV.category1.gameIds.length > 1 ? Main.Instance.RewardShopState.CSV.category1.gameIds.length + ' games selected'
                    + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category1.gameProviders, true)
                    : Main.Instance.RewardShopState.CSV.category1.gameNames[0] + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category1.gameProviders, true)}
                </button>

                {Main.Instance.RewardShopState.CSV.category1.spinsCoinValues ?
                <FormSelect
                    verynarrow = {true}
                    verynarrow2 = {true}
                    value = {!Main.Instance.RewardShopState.CSV.category1.spinBetLevel ? '0' : Main.Instance.RewardShopState.CSV.category1.spinBetLevel.toString()}
                    data = {getOptions(Main.Instance.RewardShopState.CSV.category1.spinsCoinValues)}
                    ClassName = "form-control"
                    o1 = {Assets.BetLevel + '?'} 
                    o2 = {Assets.BetLevel + '?'}
                    onChange = {(text) => {
                        const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)
                        CSV.category1.spinBetLevel = parseFloat(text)
                        if (CSV.category1.gameProviders && TableUtils.gameProviders(CSV.category1.gameProviders) === 'NetEnt') {
                            CSV.category1.spinValue = TableUtils.convertNetEnt(CSV.category1.spinBetLevel)
                        } else { CSV.category1.spinValue = CSV.category1.spinBetLevel }
                        Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                    }}
                    iconText = {Assets.BetLevelsAbbrev}
                    lineHeight = "14px"
                    iconTextS = "14px"
                    boldspan = {true}
                /> : void 0}

                {Main.Instance.RewardShopState.CSV.category1.spinsCoinValues && Main.Instance.RewardShopState.CSV.category1.spinBetLevel ?
                <>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category1 && 
                              Main.Instance.RewardShopState.CSV.category1.spinValue ? 
                              Main.Instance.RewardShopState.CSV.category1.spinValue.toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Spin"
                    iconTextS = "14px"
                    onChange = {(text) => void 0}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsTotalValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.spinValue ? 
                              (Main.Instance.RewardShopState.CSV.category1.spinValue * Main.Instance.RewardShopState.CSV.category1.spinsAmount).toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Total"
                    iconTextS = "14px"
                    onChange = {(text) => void 0}
                />

                <div style = {{marginTop: '5px', marginRight: '10px', fontSize: '16px'}} className = "fl">€</div>
                </> : void 0}
                </>
                : Main.Instance.RewardShopState.category_idx === 0 ?
                <button
                    title = {Assets.ClickToSelectGame_s}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger"
                >
                    {Assets.SelectGame_s}
                </button> : void 0}

                {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && 
                 Main.Instance.RewardShopState.CSV.category2.gameIds && Main.Instance.RewardShopState.CSV.category2.gameIds.length > 0 ?
                 <>
                 <button
                     title = {Assets.ClickToChangeTheSelection}
                     onClick = {() => OpenGS()}
                     className = "btn btn-sm btn-success fl"
                 >
                    {Main.Instance.RewardShopState.CSV.category2.gameIds.length > 1 ? Main.Instance.RewardShopState.CSV.category2.gameIds.length + ' games selected'
                    + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category2.gameProviders, true)
                    : Main.Instance.RewardShopState.CSV.category2.gameNames[0] + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category2.gameProviders, true)}
                 </button>
                 {Main.Instance.RewardShopState.CSV.category2.spinsCoinValues ?
                 <FormSelect
                     verynarrow = {true}
                     verynarrow2 = {true}                
                     value = {!Main.Instance.RewardShopState.CSV.category2.spinBetLevel ? '0' : Main.Instance.RewardShopState.CSV.category2.spinBetLevel.toString()}
                     data = {getOptions(Main.Instance.RewardShopState.CSV.category2.spinsCoinValues)}
                     ClassName = "form-control"
                     o1 = {Assets.BetLevel + '?'} 
                     o2 = {Assets.BetLevel + '?'}
                     onChange = {(text) => {
                         const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)
                         CSV.category2.spinBetLevel = parseFloat(text)
                         if (CSV.category2.gameProviders && TableUtils.gameProviders(CSV.category2.gameProviders) === 'NetEnt') {
                             CSV.category2.spinValue = TableUtils.convertNetEnt(CSV.category2.spinBetLevel)
                         } else { CSV.category2.spinValue = CSV.category2.spinBetLevel }
 
                         Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                     }}
                     iconText = {Assets.BetLevelsAbbrev}
                     lineHeight = "14px"
                     iconTextS = "14px"
                     boldspan = {true}
                 /> : void 0}

                {Main.Instance.RewardShopState.CSV.category2.spinsCoinValues && Main.Instance.RewardShopState.CSV.category2.spinBetLevel ?
                <>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category2 && 
                        Main.Instance.RewardShopState.CSV.category2.spinValue ? Main.Instance.RewardShopState.CSV.category2.spinValue.toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Spin"
                    iconTextS = "14px"
                    onChange = {(text) => void 0/*ChangeRewardParameter(text, P.SPINS_VALUE)*/}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsTotalValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.spinValue ? 
                              (Main.Instance.RewardShopState.CSV.category2.spinValue * Main.Instance.RewardShopState.CSV.category2.spinsAmount).toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Total"
                    iconTextS = "14px"
                    onChange = {(text) => void 0/*ChangeRewardParameter(text, P.SPINS_VALUE)*/}
                />

                <div style = {{marginTop: '5px', marginRight: '10px', fontSize: '16px'}} className = "fl">€</div>
                </> : void 0}
                </>
                : Main.Instance.RewardShopState.category_idx === 1 ?
                <button
                    title = {Assets.ClickToSelectGame_s}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger fl"
                >
                    {Assets.SelectGame_s}
                </button> : void 0}

                {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 && 
                Main.Instance.RewardShopState.CSV.category3.gameIds && Main.Instance.RewardShopState.CSV.category3.gameIds.length > 0 ?
                <>
                <button
                    title = {Assets.ClickToChangeTheSelection}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-success fl"
                >
                    {Main.Instance.RewardShopState.CSV.category3.gameIds.length > 1 ? Main.Instance.RewardShopState.CSV.category3.gameIds.length + ' games selected'
                    + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category3.gameProviders, true)
                    : Main.Instance.RewardShopState.CSV.category3.gameNames[0] + TableUtils.gameProviders(Main.Instance.RewardShopState.CSV.category3.gameProviders, true)}
                </button>
                {Main.Instance.RewardShopState.CSV.category3.spinsCoinValues ?
                <FormSelect
                     verynarrow = {true}
                     verynarrow2 = {true}                
                     value = {!Main.Instance.RewardShopState.CSV.category3.spinBetLevel ? '0' : Main.Instance.RewardShopState.CSV.category3.spinBetLevel.toString()}
                     data = {getOptions(Main.Instance.RewardShopState.CSV.category3.spinsCoinValues)}
                     ClassName = "form-control"
                     o1 = {Assets.BetLevel + '?'} 
                     o2 = {Assets.BetLevel + '?'}
                     onChange = {(text) => {
                         const CSV: any = Object.assign ({}, Main.Instance.RewardShopState.CSV)
                         CSV.category3.spinBetLevel = parseFloat(text)
                         if (CSV.category3.gameProviders && TableUtils.gameProviders(CSV.category3.gameProviders) === 'NetEnt') {
                             CSV.category3.spinValue = TableUtils.convertNetEnt(CSV.category3.spinBetLevel)
                         } else { CSV.category3.spinValue = CSV.category3.spinBetLevel } 
                         Logic.Type.New (Main.Instance.RewardShopState, {CSV})
                     }}
                     iconText = {Assets.BetLevelsAbbrev}
                     lineHeight = "14px"
                     iconTextS = "14px"
                     boldspan = {true}
                /> : void 0}

                {Main.Instance.RewardShopState.CSV.category3.spinsCoinValues && Main.Instance.RewardShopState.CSV.category3.spinBetLevel ?
                <>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category3 && 
                        Main.Instance.RewardShopState.CSV.category3.spinValue ? Main.Instance.RewardShopState.CSV.category3.spinValue.toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Spin"
                    iconTextS = "14px"
                    onChange = {(text) => void 0/*ChangeRewardParameter(text, P.SPINS_VALUE)*/}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">€</div>
                <FormInputNumber
                    disabled = {true}
                    description = {Assets.FreeSpinsTotalValue}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV && Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.spinValue ? 
                              (Main.Instance.RewardShopState.CSV.category3.spinValue * Main.Instance.RewardShopState.CSV.category3.spinsAmount).toString() : '0'}
                    type = "number" 
                    min = "0"
                    iconText = "Total"
                    iconTextS = "14px"
                    onChange = {(text) => void 0/*ChangeRewardParameter(text, P.SPINS_VALUE)*/}
                />

                <div style = {{marginTop: '5px', marginRight: '10px', fontSize: '16px'}} className = "fl">€</div>
                </> :  void 0}
                </>
                : Main.Instance.RewardShopState.category_idx === 2 ?
                <button
                    title = {Assets.ClickToSelectGame_s}
                    onClick = {() => OpenGS()}
                    className = "btn btn-sm btn-danger fl"
                >
                    {Assets.SelectGame_s}
                </button> : void 0}

            </div> : void 0}

            {/* MINI GAME */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {(text) => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>

                {/*Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                 Main.Instance.RewardShopState.CSV.category1.minigameId && Main.Instance.RewardShopState.CSV.category1.minigameId.id ?
                <button
                    title = "Click to select any other mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-success"
                >
                    {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category1.minigameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 0 ?
                <button
                    title = "Click to select a mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Mini game'}
                </button> : void 0*/}

                {/*Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && 
                 Main.Instance.RewardShopState.CSV.category2.minigameId && Main.Instance.RewardShopState.CSV.category2.minigameId.id ?
                <button
                    title = "Click to select any other mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-success"
                >
                    {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category2.minigameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 1 ?
                <button
                    title = "Click to select a mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Mini game'}
                </button> : void 0*/}

                {/*Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 && 
                 Main.Instance.RewardShopState.CSV.category3.minigameId && Main.Instance.RewardShopState.CSV.category3.minigameId.id ?
                <button
                    title = "Click to select any other mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-success"
                >
                    {'Select Game (curr.: ' + Main.Instance.RewardShopState.CSV.category3.minigameName + ')'}
                </button>
                : Main.Instance.RewardShopState.category_idx === 2 ?
                <button
                    title = "Click to select a mini game"
                    onClick = {() => OpenGS2()}
                    className = "btn btn-sm btn-danger"
                >
                    {'Select Mini game'}
                </button> : void 0*/}

                {GetRewards(Main.Instance.RewardShopState.category_idx)}
            </div> : void 0}
            {/*Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME && 
            +Main.Instance.RewardShopState.banner !== undefined && +Main.Instance.RewardShopState.banner > 0 ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                <span style = {{marginRight: '45px', display: 'inline-block', fontSize: '16px', fontWeight: 700}}>{'Select Reward ' + Main.Instance.RewardShopState.banner}</span>
                <FormSelect
                    rewardFor = {6}
                    span = {true}
                    value = {
                             Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.rewards ?
                             Main.Instance.RewardShopState.CSV.category1.rewards[+Main.Instance.RewardShopState.banner - 1] :
                             Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.rewards ?
                             Main.Instance.RewardShopState.CSV.category2.rewards[+Main.Instance.RewardShopState.banner - 1] :
                             Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.rewards ?
                             Main.Instance.RewardShopState.CSV.category3.rewards[+Main.Instance.RewardShopState.banner - 1] :
                             '0'}
                    data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "control-panel-select-narrow control-panel-select-narrow2"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {text => EditReward(text, Main.Instance.RewardShopState.category_idx, +Main.Instance.RewardShopState.banner - 1)}
                    icon = {Assets_.faRewardClass}
                />
            </div>

            : void 0*/}
            {/*GetEditRewards(Main.Instance.RewardShopState.category_idx)*/}

            {/* TOKENS */}
            {IsBuildMyChance() ? void 0 : Main.Instance.RewardShopState.category_idx !== -1 && parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.TOKENS ?
            <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
                {/*
                <FormInputNumber
                    description = {Assets.ExpirationTime_Days}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && 
                        Main.Instance.RewardShopState.CSV.category1.expirationDays ? Main.Instance.RewardShopState.CSV.category1.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 &&
                        Main.Instance.RewardShopState.CSV.category2.expirationDays ? Main.Instance.RewardShopState.CSV.category2.expirationDays.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 &&
                        Main.Instance.RewardShopState.CSV.category3.expirationDays ? Main.Instance.RewardShopState.CSV.category3.expirationDays.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faTimeLgClass}
                    onChange = {text => ChangeRewardParameter(text, P.EXPIRATION_DAYS)}
                />
                <div style = {{marginTop: '5px', marginRight: '10px'}} className = "fl">{Assets.Days.toLowerCase()}</div>
                */}
                <FormInputNumber
                    description = "Tokens"
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = { Main.Instance.RewardShopState.CSV ?
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1
                        && Main.Instance.RewardShopState.CSV.category1.tokens ? Main.Instance.RewardShopState.CSV.category1.tokens.toString() :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2
                        && Main.Instance.RewardShopState.CSV.category2.tokens ? Main.Instance.RewardShopState.CSV.category2.tokens.toString() :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3
                        && Main.Instance.RewardShopState.CSV.category3.tokens ? Main.Instance.RewardShopState.CSV.category3.tokens.toString() : '0' : '0'
                    }
                    type = "number" 
                    min = "0"
                    iconText = "Tokens"
                    iconTextS = "14px"
                    onChange = {(text) => ChangeRewardParameter(text, P.TOKENS)}
                />
                {/*<div style = {{marginTop: '5px', marginRight: '0px'}} className = "fl">tokens</div>*/}

            </div> : void 0}
{/*
            <div className = "row admin-users-container clearfix">

                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.RewardShopState.index ? '' : 
                    Main.Instance.RewardShopState.index}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHashtagClass}
                    onChange = {text => IndexChanged(text)}
                />

                <FormSelect
                    value = {!Main.Instance.RewardShopState.banner ? '0' : 
                            Main.Instance.RewardShopState.banner}
                    data = {Main.Instance.RewardShopState.Banner}
                    ClassName = "form-control"
                    o1 = {Assets.SelectBanner} 
                    o2 = {Assets.SelectBanner}
                    onChange = {text => Logic.Type.New (Main.Instance.RewardShopState, {
                        banner: text})}
                    icon = {Assets_.faPuzzleFixedPieceClass}
                />

            </div>
*/}

            {IsBuildMyChance() || Main.Instance.RewardShopState.category_idx === -1 ||
            parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.MINI_GAME ||
            parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL ||
            Main.Instance.RewardShopState.rewardIntent[0] === Assets_.RewardIntent.Store            
            ?
            <div className = "row admin-users-row-area-small">
                <div className = "register-input textarea-small">
                    <FormInputArea
                        value = {!Main.Instance.RewardShopState.info[0] ? '' :
                                Main.Instance.RewardShopState.info[0]}
                        ClassName = "form-control register-input"
                        onChange = {(text) => updateInfo(text)}
                        placeholder = {Assets.RewardDescriptionOptional}
                        icon = {Assets_.faCommentClass}
                    />
                </div>
            </div> : void 0}
{/* VALID GAMES */}
            {/*parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL ?
            <div>
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New(Main.Instance.RewardShopState, {modeValidGames: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus" 
                            icon = {Assets_.faCancelButtonClass}
                        />
                        <span
                            title = {Assets.ValidGames}
                            style = {{
                                width: '50px',
                                textAlign: 'center',
                                marginRight:'10px',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px'
                            }}
                            className = "label label-default"
                        >   <i className = {Assets_.faGamingClass} style = {{marginRight: '0px'}}/>
                        </span>
                    </div>
                </div>
            </div>
            : void 0*/}
            {IsBuildMyChance() ? void 0 : parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL || 
            parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC && Main.Instance.RewardShopState.category_idx > -1 ?
                <div>
                    <div className = "register-input clearfix">
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => Logic.Type.New(Main.Instance.RewardShopState, {modeValidGames: false})}
                                ClassName = "btn btn-nothing-absolute btn-sm btn-plus" 
                                icon = {Assets_.faCancelButtonClass}
                            />
                            <span
                                title = {Assets.ValidGames}
                                style = {{
                                    width: '50px',
                                    textAlign: 'center',
                                    marginRight:'10px',
                                    float: 'left',
                                    display: 'block',
                                    height: '30px',
                                    fontSize: '16px',
                                    paddingTop:'7px',
                                }}
                                className = "label label-default"
                            >   <i className = {Assets_.faGamingClass} style = {{marginRight: '0px'}}/>
                            </span>
                            {
                            Main.Instance.RewardShopState.category_idx === 0 ?
                            <button
                                style = {{borderRadius: '0px', width: '107px'}}
                                title = "Toggle Include Games"
                                className = {Main.Instance.RewardShopState.valid1 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.valid1 === undefined || Main.Instance.RewardShopState.valid1 === -1 ?
                                                 Logic.Type.New (Main.Instance.RewardShopState, {valid1: 0, invalid1: -1})
                                                 : Logic.Type.New (Main.Instance.RewardShopState, {valid1: -1}))}
                            >
                                {Assets.IncludeGames}
                            </button>
                            :
                            Main.Instance.RewardShopState.category_idx === 1 ?
                            <button
                                style = {{borderRadius: '0px', width: '107px'}}
                                title = {Assets.Toggle + ' ' + Assets.IncludeGames}
                                className = {Main.Instance.RewardShopState.valid2 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.valid2 === undefined || Main.Instance.RewardShopState.valid2 === -1 ?
                                                 Logic.Type.New (Main.Instance.RewardShopState, {valid2: 0, invalid2: -1})
                                                 : Logic.Type.New (Main.Instance.RewardShopState, {valid2: -1}))}
                            >
                                {Assets.IncludeGames}
                            </button>
                            :
                            Main.Instance.RewardShopState.category_idx === 2 ?
                            <button
                                style = {{borderRadius: '0px', width: '107px'}}
                                title = {Assets.Toggle + ' ' + Assets.IncludeGames}
                                className = {Main.Instance.RewardShopState.valid3 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.valid3 === undefined || Main.Instance.RewardShopState.valid3 === -1 ?
                                                 Logic.Type.New (Main.Instance.RewardShopState, {valid3: 0, invalid3: -1})
                                                 : Logic.Type.New (Main.Instance.RewardShopState, {valid3: -1}))}
                            >
                                {Assets.IncludeGames}
                            </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.V1 && 
                            Main.Instance.RewardShopState.valid1 !== undefined && Main.Instance.RewardShopState.valid1 !== -1 ?
                                <button
                                    onClick = {() => openGS(true)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.V1.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.V1.length === 0 ? Assets.ClickToIncludeGames :
                                    Main.Instance.RewardShopState.V1.length === 1 ? Assets.ThereIs1IncludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.V1.length + ' '  + Assets.includedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.V1 && 
                            Main.Instance.RewardShopState.valid1 !== -1 && Main.Instance.RewardShopState.V1.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.V1)}</div> : void 0}

                            {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.V2 && 
                            Main.Instance.RewardShopState.valid2 !== undefined && Main.Instance.RewardShopState.valid2 !== -1 ?
                                <button
                                    onClick = {() => openGS(true)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.V2.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.V2.length === 0 ? Assets.ClickToIncludeGames :
                                    Main.Instance.RewardShopState.V2.length === 1 ? Assets.ThereIs1IncludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.V2.length + ' ' + Assets.includedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.V2 && 
                            Main.Instance.RewardShopState.valid2 !== -1 && Main.Instance.RewardShopState.V2.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.V2)}</div> : void 0}

                            {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.V3 && 
                            Main.Instance.RewardShopState.valid3 !== undefined && Main.Instance.RewardShopState.valid3 !== -1 ?
                                <button
                                    onClick = {() => openGS(true)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.V3.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.V3.length === 0 ? Assets.ClickToIncludeGames :
                                    Main.Instance.RewardShopState.V3.length === 1 ? Assets.ThereIs1IncludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.V3.length + ' ' + Assets.includedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.V3 && 
                            Main.Instance.RewardShopState.valid3 !== -1 && Main.Instance.RewardShopState.V3.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.V3)}</div> : void 0}

                        </div>
                        <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                            {
                            Main.Instance.RewardShopState.category_idx === 0 ?
                            <button
                                style = {{borderRadius: '0px', marginLeft: '60px', width: '107px'}}
                                title = "Toggle Exclude Games"
                                className = {Main.Instance.RewardShopState.invalid1 !== undefined && Main.Instance.RewardShopState.invalid1 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.invalid1 === undefined || Main.Instance.RewardShopState.invalid1 === -1 ?
                                    Logic.Type.New (Main.Instance.RewardShopState, {invalid1: 0, valid1: -1})
                                    : Logic.Type.New (Main.Instance.RewardShopState, {invalid1: -1}))}
                            >
                                {Assets.ExcludeGames}
                            </button>
                            :
                            Main.Instance.RewardShopState.category_idx === 1 ?
                            <button
                                style = {{borderRadius: '0px', marginLeft: '60px', width: '107px'}}
                                title = {Assets.Toggle + ' ' + Assets.ExcludeGames}
                                className = {Main.Instance.RewardShopState.invalid2 !== undefined && Main.Instance.RewardShopState.invalid2 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.invalid2 === undefined || Main.Instance.RewardShopState.invalid2 === -1 ?
                                    Logic.Type.New (Main.Instance.RewardShopState, {invalid2: 0, valid2: -1})
                                    : Logic.Type.New (Main.Instance.RewardShopState, {invalid2: -1}))}
                            >
                                {Assets.ExcludeGames}
                            </button>
                            :
                            Main.Instance.RewardShopState.category_idx === 2 ?
                            <button
                                style = {{borderRadius: '0px', marginLeft: '60px', width: '107px'}}
                                title = {Assets.Toggle + ' ' + Assets.ExcludeGames}
                                className = {Main.Instance.RewardShopState.invalid3 !== undefined && Main.Instance.RewardShopState.invalid3 !== -1 ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => (Main.Instance.RewardShopState.invalid3 === undefined || Main.Instance.RewardShopState.invalid3 === -1 ?
                                    Logic.Type.New (Main.Instance.RewardShopState, {invalid3: 0, valid3: -1})
                                    : Logic.Type.New (Main.Instance.RewardShopState, {invalid3: -1}))}
                            >
                                Exclude games
                            </button>
                            : void 0}
                            {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.I1 &&
                            Main.Instance.RewardShopState.invalid1 !== undefined && Main.Instance.RewardShopState.invalid1 !== -1 ?
                                <button
                                    onClick = {() => openGS(false)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.I1.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.I1.length === 0 ? Assets.ClickToExcludeGames :
                                    Main.Instance.RewardShopState.I1.length === 1 ? Assets.ThereIs1ExcludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.I1.length + ' ' + Assets.excludedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.I1 && Main.Instance.RewardShopState.valid1 !== undefined &&
                            Main.Instance.RewardShopState.valid1 !== -1 && Main.Instance.RewardShopState.I1.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.I1)}</div> : void 0}

                            {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.I2 &&
                            Main.Instance.RewardShopState.invalid2 !== undefined && Main.Instance.RewardShopState.invalid2 !== -1 ?
                                <button
                                    onClick = {() => openGS(false)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.I2.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.I2.length === 0 ? Assets.ClickToExcludeGames :
                                    Main.Instance.RewardShopState.I2.length === 1 ? Assets.ThereIs1ExcludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.I2.length + ' ' + Assets.excludedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.I2 && Main.Instance.RewardShopState.valid2 !== undefined &&
                            Main.Instance.RewardShopState.valid2 !== -1 && Main.Instance.RewardShopState.I2.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.I2)}</div> : void 0}

                            {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.I3 &&
                            Main.Instance.RewardShopState.invalid3 !== undefined && Main.Instance.RewardShopState.invalid3 !== -1 ?
                                <button
                                    onClick = {() => openGS(false)}
                                    style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                    className = {Main.Instance.RewardShopState.I3.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                                >
                                    {Main.Instance.RewardShopState.I3.length === 0 ? Assets.ClickToExcludeGames :
                                    Main.Instance.RewardShopState.I3.length === 1 ? Assets.ThereIs1ExcludedGame + '.' :
                                    Assets.ThereAre + ' ' + Main.Instance.RewardShopState.I3.length + ' ' + Assets.excludedGames + '.'}
                                </button>
                            : void 0}

                            {Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.I3 && Main.Instance.RewardShopState.valid3 !== undefined &&
                            Main.Instance.RewardShopState.valid3 !== -1 && Main.Instance.RewardShopState.I3.length > 0 ?
                            <div className = "hhh">{GetValidGamesNames(Main.Instance.RewardShopState.I3)}</div> : void 0}

{/*
                            <ButtonIcon 
                                description = {!Main.Instance.RewardShopState.mode ? Assets.saveChanges : Assets.updateChanges}
                                click = {() => handleInsertUpdate()}
                                ClassName = {!Main.Instance.RewardShopState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                                Icon = {faSave as IconDefinition}
                                // text = {Assets.Save}
                            />
*/}
                        </div>
                    </div>
                </div> : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.mode ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>

{/*      
            <div className = "row admin-users-container clearfix">
                {Main.Instance.RewardShopState.image && Main.Instance.RewardShopState.image.length > 0 ?
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.mode ? 
                                'btn btn-default btn-sm btn-plus fr mt-30' : 
                                'btn btn-primary btn-sm btn-plus fr mt-30'}
                    icon= {Assets_.faUpdateButtonClass} 
                    text = {Assets.Save}
                />
                :
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.mode ? 
                                'btn btn-default btn-sm btn-plus fr mt0' : 
                                'btn btn-primary btn-sm btn-plus fr mt0'}
                    icon= {Assets_.faUpdateButtonClass} 
                    text = {Assets.Save}
                />
                }
            </div>
*/}
            </div>
            </div>
            {IsBuildMyChance() ? 
            
            <div className = "row admin-users-row clearfix">
                <FormSelect
                    // span = {true}
                    float = {true}
                    wide = {true}
                    value = {!Main.Instance.RewardShopState.filter_type ? '0' : 
                            Main.Instance.RewardShopState.filter_type}
                    data = {Main.Instance.FrontendStoreState.Category ? Main.Instance.FrontendStoreState.Category.slice(1, Main.Instance.FrontendStoreState.Category.length) : []}
                    ClassName = "form-control"
                    o1 = {Assets.Any}
                    o2 = {Assets.Any + ' ' + Assets.Reward.toLowerCase()}
                    onChange = {(text) => ApplyFilterType(text)}
                    Icon = {faFilter}
                />
                <div className = "fl">&nbsp;&nbsp;</div>
                <FormSelect
                    // span = {true}
                    wide = {true}
                    float = {true}
                    
                    disabled = {Main.Instance.DatabaseDataState.strapiConnected === false || Main.Instance.RewardShopState.filter_type !== '1' && Main.Instance.RewardShopState.filter_type !== '9' ? true : false}
                    value = {!Main.Instance.RewardShopState.filter_fsAmount ? '0' : 
                            Main.Instance.RewardShopState.filter_fsAmount}
                    data = {getFsAmountData(Main.Instance.RewardShopState.filter_type === '1' ? Assets_.RTFreeSpinsAmount : Assets_.RTMegaSpinsAmount)}
                    ClassName = "form-control"
                    o1 = {Assets.Any}
                    o2 = {Assets.Any + ' ' + Assets.FreespinAmount.toLowerCase()}
                    onChange = {(text) => ApplyFilterFs(text)}
                    Icon = {faFilter}
                />  
            </div> :
            <div className = "control-panel">
                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.RewardShopState.pSize ? '' : 
                    Main.Instance.RewardShopState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
                />

                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>
                {IsBuildMyChance() ? void 0 :
                <FormSelect 
                    span = {true}
                    value = {!Main.Instance.RewardShopState.filter_category ? '0' : 
                            Main.Instance.RewardShopState.filter_category}
                    data = {Main.Instance.FrontendStoreState.Category3}
                    ClassName = "control-panel-select-narrow"
                    o1 = {Assets.Any} 
                    onChange = {(text) => ApplyFilterCategory(text)} 
                />}
                {/* console.log(Main.Instance.FrontendStoreState.Category) */}
                

                <span className = "label label-success table-label">
                    {Assets.records}
                    {Main.Instance.DatabaseDataState.rewardShopCount ? Main.Instance.DatabaseDataState.rewardShopCount : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.showing}
                    {Main.Instance.DatabaseDataState.rewardShopCount ? Main.Instance.RewardShopState.pNum * 
                    Main.Instance.RewardShopState.pSize + 1 : 0}{' - '}
                    {Main.Instance.DatabaseDataState.rewardShopCount ? 
                    ((Main.Instance.RewardShopState.pNum + 1) * 
                    Main.Instance.RewardShopState.pSize > Main.Instance.DatabaseDataState.rewardShopCount ? 
                    Main.Instance.DatabaseDataState.rewardShopCount : 
                    (Main.Instance.RewardShopState.pNum + 1) * Main.Instance.RewardShopState.pSize) : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.page}
                    {Main.Instance.DatabaseDataState.rewardShopCount ? 
                    Main.Instance.RewardShopState.pNum + 1 :0}{' / '}
                    {Main.Instance.DatabaseDataState.rewardShopCount ? 
                    Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 1) /
                    Main.Instance.RewardShopState.pSize + 1) : 0}
                </span>
            </div> }
        <h3>{Assets.ListOfRewards}</h3>
        {/* console.log (Main.Instance.DatabaseDataState.rewardShopStream) */}
        {Main.Instance.DatabaseDataState.rewardShopStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.rewardShopStream}
            filtered = {Main.Instance.RewardShopState.filtered}
            onFilteredChange = {(filtered, column, value) => {
                tableFilters(value, column.accessor)
            }}
            defaultFilterMethod = {(filter, row, column) => {
                
                const id = filter.id
                if (+filter.value === 0) {
                    return true
                }
                
                return row[id] !== undefined ? +(row[id]) === +filter.value : true
            }}
            columns = {
                IsBuildMyChance() ? 
                [
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {
                        Header: Assets.Reward,
                        headerClassName: 'risk-default-light',
                        columns: [
                            {width: 45, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left' },
                            {width: 200, Header: Assets.Name, accessor: 'Reward', headerClassName: 'th-left'},
                            {width: 150, Header: Assets.Type, accessor: 'Reward type', headerClassName: 'th-left', Cell: row => injectSpaces(RewardTypes[row.value])},
                            {headerClassName: 'th-left', width: 200, Header: Assets.BonusCode, accessor: 'reward.category1.bonusCode'},
                            {Header: Assets.Description, accessor: 'reward.description', headerClassName: 'th-left'},
                            {width: 50, Header: <span title={Assets.FreeSpinsAmount}>#FS</span>, accessor: 'numOfFreeSpins', headerClassName: 'th-left', Cell: row => numOfFreeSpinsDisplay(row.value), filterMethod: (filter, row) => {
                                
                                const id = filter.id
                                if (+filter.value === 0) {
                                    return true
                                }
                                return row[id] !== undefined ? (row[id]) === (Main.Instance.RewardShopState.filter_type === '1' ? Assets_.RTFreeSpinsAmount[+filter.value - 1] : Assets_.RTMegaSpinsAmount[+filter.value - 1]) : true
                            }},
                        ]
                    },
                    {
                        Header: Assets.SpinValue,
                        headerClassName: 'risk-default',
                        columns: getCurrencyColumns()
                    },
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete, isDisabled(row))},
                ]
                :
                [
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {width: 30, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left' }, 
                    {width: 180, Header: Assets.RewardName, accessor: 'Reward', headerClassName: 'th-left'},
                    {width: 250, Header: Assets.RewardIntent, accessor: 'Reward intent', headerClassName: 'th-left'},
                    {width: 150, Header: Assets.RewardType, accessor: 'Reward type', headerClassName: 'th-left'},
                    {width: 90, Header: Assets.Categories, accessor: 'categories', headerClassName: 'th-left', 
                    Cell: (row) => row.original['Reward type'] === 'Boost global' || row.original['Reward type'] === 'Mini game' ? '' : TableUtils.ConstructCategoriesRewards(row, handleCategoryButton)},
                    {Header: Assets.RewardDescription, accessor: 'reward', headerClassName: 'th-left', Cell: (row) => TableUtils.ConstructCategoriesRewards2(row)},
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationTop = {IsBuildMyChance() ? true : false}
            showPaginationBottom = {false}
            defaultPageSize = {IsBuildMyChance() ? 10 : void 0}
            pageSize={IsBuildMyChance() ? void 0 : Main.Instance.DatabaseDataState.rewardShopStream.length < Main.Instance.RewardShopState.pSize ? Main.Instance.DatabaseDataState.rewardShopStream.length  : Main.Instance.RewardShopState.pSize}
        /> : void 0}
        {/*console.log(Main.Instance.DatabaseDataState.rewardShopStream)*/}
        {/*Main.Instance.DatabaseDataState.rewardShopStream ?
        StremingTable(Main.Instance.DatabaseDataState.rewardShopStream, {
            title: Assets.ListOfRewards, 
            mode: Main.Instance.RewardShopState.mode,
            editingId: Main.Instance.RewardShopState.id,
            sort: getRewardsCount,
            Sort: Main.Instance.RewardShopState,
            // rs_category: true,
            rs_category: false,
            // rs_banner: false,
            filterLanguage: 0,
            categoryButton: (data, i) => handleCategoryButton(data, i),
            // toggleButton: (data) => handleToggle(data),
            // toggleButton4: (data) => handleToggleOnlyNewlyRegistered(data),
            // toggleButton3: (data) => handleToggleBuyable(data),
            // removeImageButton:(data) => handleRemoveImage(data),
            borderRadius: Main.Instance.FrontendStoreState.borderRadius,
            delButton:(data) => handleDelete(data),
            editButton:(data) => handleEdit(data),
        // editIndex:(data, text) => handleEditIndex(data, text)})
        })
        : Main.Instance.FrontendStoreState.site_id ? void 0 : void 0*/}
        </div>
    </div>)
}


/*
function EditInfo(event, which: number) {
    let info: string[] = Object.assign ([], Main.Instance.RewardShopState.info)
    info[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {info})
}

function EditName(event, which: number) {
    let name: string[] = Object.assign ([], Main.Instance.RewardShopState.name)
    name[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {name})
}

function getBody(): JSX.Element[] {
    // console.log (Main.Instance.RewardShopState.info)
    // console.log (Main.Instance.RewardShopState.name)
    
    let tr: JSX.Element[] = []
    let tdn: JSX.Element[] = []
    let tdi: JSX.Element[] = []
    for (let i: number = 0; i < Main.Instance.RewardShopState.name.length; i++) {
        let background: string = i === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        tdn.push (
            <td key = {i.toString()} style = {{background}}>
                <input
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.name[i]}
                    type = "text"
                    onChange = {(e) => EditName(e, i)}
                />
            </td>
        )

        tdi.push (
            <td key = {i.toString()} style = {{background}}>
                <textarea
                    style = {{height: '100px', paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.info[i]}
                    onChange = {(e) => EditInfo(e, i)}
                />
            </td>
        )
    }

    tr.push(<tr key = "tdn">{tdn}</tr>)
    tr.push(<tr key = "tdi">{tdi}</tr>)
    return tr
}

function getHeader(): JSX.Element[] {
    let th: JSX.Element[] = []

    if (Main.Instance.DatabaseDataState.languagesStreamAll)
    for (let J: number = 0; J < Main.Instance.DatabaseDataState.languagesStreamAll.length; J++) {
        let background: string = J === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                                    Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
                                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag']) - 1
        const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                                    !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
        th.push(
            <th
                style = {{background}}
                className = "th-center"
                title = {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['native']}
                key = {J.toString()}
            >
                <img src = {flagsource1} height = "20"/>
                <span style = {{fontSize: '14px', marginLeft: '10px'}}>
                    {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                </span>
            </th>
        )
    }
    // th.push(<th className = "th-center" key = {Main.Instance.DatabaseDataState.languagesStreamAll.length}/>)

    return th
}
*/
/*
function GetEditRewards(category: number): JSX.Element {
    let current: any = []
    if (category === 0 && Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.rewards) 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category1.rewards)

    else if (category === 1 && Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.rewards) 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category2.rewards)

    else if (category === 2 && Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.rewards) 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category3.rewards)

    return (
    <div className = "row admin-users-row" style = {{marginBottom: '5px'}}>
        <span style = {{marginRight: '45px', display: 'inline-block', fontSize: '16px', fontWeight: 700}}>{'Select Reward ' + Main.Instance.RewardShopState.banner}</span>
        <FormSelect
            rewardFor = {6}
            span = {true}
            value = {current[+Main.Instance.RewardShopState.banner - 1]}
            data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
            ClassName = "control-panel-select-narrow control-panel-select-narrow2"
            o1 = {Assets.SelectReward}
            o2 = {Assets.SelectReward}
            onChange = {text => EditReward(text, category, +Main.Instance.RewardShopState.banner - 1)}
            icon = {Assets_.faRewardClass}
        />
    </div>
    )
}
*/

function numOfFreeSpinsDisplay(value: string) {
    const reg = new RegExp(/\d+/i)
    return reg.test(value) ? value.match(reg) : ''

}

function getCurrencyColumns() {
    
    const columns: any[] = []
    if (Main.Instance.DatabaseDataState.currenciesStream) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.currenciesStream.length; i++) {
            columns.push({show: IsBuildMyChance() ? true : false,
                headerClassName: 'th-left',
                width: 50,
                Header: <span title={Main.Instance.DatabaseDataState.currenciesStream[i].currencydescription}>{Main.Instance.DatabaseDataState.currenciesStream[i].code}</span>,
                accessor: 'valueSpin.' + Main.Instance.DatabaseDataState.currenciesStream[i].code
            })
        }
       
    }
    
    return columns
}

function GetRewards(category: number): JSX.Element[] {
    const rewards: JSX.Element[] = []
    let current: any = []
    if (category === 0 && Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.rewards) { 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category1.rewards)
    } else if (category === 1 && Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.rewards) { 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category2.rewards)
 } else if (category === 2 && Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.rewards) { 
        current = Object.assign([], Main.Instance.RewardShopState.CSV.category3.rewards)
 }
    // alert (JSON.stringify(Main.Instance.RewardShopState.CSV.category1.rewards))
    rewards.push (
        <button
            title = {Assets.Rewards}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {+Main.Instance.RewardShopState.banner ? 'btn btn-default btn-sm mr0 ml' : 'btn btn-primary btn-sm mr0 ml'}
            onClick = {() => SelectReward('0')}
        >
            {current.length === 0 ? <span>Rewards</span> : <i className = {Assets_.faRewardLgClass}/>}
        </button>,
    )

    for (let i: number = 1; i <= current.length; i++) {
        if (+Main.Instance.RewardShopState.banner === i) { rewards.push (
            <FormSelect
                rewardFor = {6}
                span = {true}
/*
                value = {
                        Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.rewards ?
                        Main.Instance.RewardShopState.CSV.category1.rewards[+Main.Instance.RewardShopState.banner - 1] :
                        Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.rewards ?
                        Main.Instance.RewardShopState.CSV.category2.rewards[+Main.Instance.RewardShopState.banner - 1] :
                        Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.rewards ?
                        Main.Instance.RewardShopState.CSV.category3.rewards[+Main.Instance.RewardShopState.banner - 1] :
                        '0'}
*/              value = {current[+Main.Instance.RewardShopState.banner - 1]}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "control-panel-select-narrow control-panel-select-narrow2"
                o1 = {Assets.SelectReward}
                o2 = {Assets.SelectReward}
                onChange = {(text) => EditReward(text, Main.Instance.RewardShopState.category_idx, +Main.Instance.RewardShopState.banner - 1)}
                icon = {Assets_.faRewardClass}
            />,
        )
        } else { rewards.push (
            <button
                // title = {current[i - 1] ? current[i - 1].name : 'Select a Reward'}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === +Main.Instance.RewardShopState.banner ? i === 0 ? 'btn btn-primary btn-sm mr0 ml' :
                'btn btn-primary btn-sm ml0 mr0' : i === 0 ? 'btn btn-default btn-sm mr0 ml' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectReward(i.toString())}
            >
            {Assets.Reward + ' ' + i}
            </button>,
        )
        }
    }
/*
    for (let i: number = 1; i <= current.length; i++)
        if (+Main.Instance.RewardShopState.banner === i)
            rewards.push (
                <FormSelect
                    span = {true}
                    value = {current[i - 1]}
                    data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "control-panel-select-narrow control-panel-select-narrow2"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {text => EditReward(text, category, i - 1)}
                    icon = {Assets_.faRewardClass}
                />
            )

        else rewards.push (
            <button
                title = {current[i - 1] ? current[i - 1] : 'Select a Reward'}
                style = {{borderRadius: '0px'}}
                key = {i.toString()}
                className = {i === +Main.Instance.RewardShopState.banner ? i === 0 ? 'btn btn-primary btn-sm mr0 ml' :
                'btn btn-primary btn-sm ml0 mr0' : i === 0 ? 'btn btn-default btn-sm mr0 ml' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectReward(i.toString())}
            >
            {'Reward ' + i}
            </button>
        )
*/
    rewards.push (
        <button
            title = {Assets.AddANewReward}
            style = {{borderRadius: '0px'}}
            key = "-1"
            className = {'btn btn-success btn-sm mr0 ml0'}
            onClick = {() => AddReward(category)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    
    if (+Main.Instance.RewardShopState.banner) {
        rewards.push (
            <button
                key = "-2"
                title = {Assets.RemoveTheReward}
                style = {{borderRadius: '0px'}}
                className = "btn btn-danger btn-sm"
                onClick = {() => RemoveReward(category)}
            >
                <i className = {Assets_.faTrashLgButtonClass}/>
            </button>,
        )
    }
    return rewards
}

function RemoveReward(which: number) {
    const CSV = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) { CSV.category1.rewards.splice(parseInt(Main.Instance.RewardShopState.banner) - 1, 1) } else if (which === 1) { CSV.category2.rewards.splice(parseInt(Main.Instance.RewardShopState.banner) - 1, 1) } else if (which === 2) { CSV.category3.rewards.splice(parseInt(Main.Instance.RewardShopState.banner) - 1, 1) }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV, banner: '0'})
}

function AddReward(which: number) {
    const CSV = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) {
        if (CSV.category1 === undefined) { CSV.category1 = {} }
        // if (CSV.category1.minigameId === undefined) CSV.category1.minigameId = {}
        if (CSV.category1.rewards === undefined) { CSV.category1.rewards = [] }
        CSV.category1.rewards.push(0)
    } else if (which === 1) {
        if (CSV.category2 === undefined) { CSV.category2 = {} }
        // if (CSV.category2.minigameId === undefined) CSV.category2.minigameId = {}
        if (CSV.category2.rewards === undefined) { CSV.category2.rewards = [] }
        CSV.category2.rewards.push(0)
    } else if (which === 2) {
        if (CSV.category3 === undefined) { CSV.category3 = {} }
        // if (CSV.category3.minigameId === undefined) CSV.category3.minigameId = {}
        if (CSV.category3.rewards === undefined) { CSV.category3.rewards = [] }
        CSV.category3.rewards.push(0)
    }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
}

function SelectReward(banner: string) {
    Logic.Type.New (Main.Instance.RewardShopState, {banner})
}

export function EditReward(text: string, which: number, Which: number) {
    const CSV = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) { CSV.category1.rewards[Which] = +text } else if (which === 1) { CSV.category2.rewards[Which] = +text } else if (which === 2) { CSV.category3.rewards[Which] = +text }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
}

function getOptions(array: number[]): Array<{id: string, name: string}> {
    const a:Array<{id: string, name: string}> = []
    for (const item of array) { a.push({id: item.toString(), name: item.toString()}) }
    return a
}

function OpenGS() {
    // Logic.Type.New (Main.Instance.DatabaseDataState, {FlagsStreamAllSelected: undefined})
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: true, ModalLoyaltyFreeRoundsGames: undefined})
    Logic.Type.New(Main.Instance.GamesState, {categorize: false})
}

function CloseGS() {
    if (Main.Instance.Modal.ModalLoyaltyFreeRoundsGames !== undefined) { closeGS() }
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: false})
}

function openGS(valid: boolean) {
    const array: number[] = valid ? 
    Main.Instance.RewardShopState.category_idx === 0 ?
    Main.Instance.RewardShopState.V1 :
    Main.Instance.RewardShopState.category_idx === 1 ?
    Main.Instance.RewardShopState.V2 :
    Main.Instance.RewardShopState.category_idx === 2 ?
    Main.Instance.RewardShopState.V3 : []
    : 
    Main.Instance.RewardShopState.category_idx === 0 ?
    Main.Instance.RewardShopState.I1 :
    Main.Instance.RewardShopState.category_idx === 1 ?
    Main.Instance.RewardShopState.I2 :
    Main.Instance.RewardShopState.category_idx === 2 ?
    Main.Instance.RewardShopState.I3 : []

    const GamesStreamAllSelected: boolean[] = []

    if (array && array.length > 0) {
        // console.log ('already there')
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            let proceed: boolean = false
            for (const item of array) {                    
                if (item === Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']) {
                    proceed = true
                    break
                }
            }
            GamesStreamAllSelected.push(proceed)
        }

    } else {
        // console.log ('fresh')
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                GamesStreamAllSelected.push(false)
            }
        }

/*
        if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_GLOBAL || 
        parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC && Main.Instance.RewardShopState.category_idx === 0) {
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    GamesStreamAllSelected.push(false)
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC && Main.Instance.RewardShopState.category_idx === 1) {
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    GamesStreamAllSelected2.push(false)
                }
            }
        } else if (parseInt(Main.Instance.RewardShopState.Category) === Assets_.RT.BOOST_PLAYER_SPECIFIC && Main.Instance.RewardShopState.category_idx === 2) {
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    GamesStreamAllSelected3.push(false)
                }
            }
        }
*/
    }
    if (Main.Instance.RewardShopState.category_idx === 0) { Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected}) } else if (Main.Instance.RewardShopState.category_idx === 1) { Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2: GamesStreamAllSelected}) } else if (Main.Instance.RewardShopState.category_idx === 2) { Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3: GamesStreamAllSelected}) }
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: true, ModalLoyaltyFreeRoundsGames: valid})
    Logic.Type.New(Main.Instance.GamesState, {categorize: false})
}

function closeGS() {
    if (Main.Instance.Modal.ModalLoyaltyFreeRoundsGames === true) {
        const V1: number[] = []
        const V2: number[] = []
        const V3: number[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
            if (Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
                V1.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            } else if (Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.DatabaseDataState.GamesStreamAllSelected2[i] === true) {
                V2.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
 } else if (Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.DatabaseDataState.GamesStreamAllSelected3[i] === true) {
                V3.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
 }
        }
        if (Main.Instance.RewardShopState.category_idx === 0) { Logic.Type.New(Main.Instance.RewardShopState, {V1}) } else if (Main.Instance.RewardShopState.category_idx === 1) { Logic.Type.New(Main.Instance.RewardShopState, {V2}) } else if (Main.Instance.RewardShopState.category_idx === 2) { Logic.Type.New(Main.Instance.RewardShopState, {V3}) }
    } else if (Main.Instance.Modal.ModalLoyaltyFreeRoundsGames === false) {
        const I1: number[] = []
        const I2: number[] = []
        const I3: number[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
            if (Main.Instance.RewardShopState.category_idx === 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
                I1.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
            if (Main.Instance.RewardShopState.category_idx === 1 && Main.Instance.DatabaseDataState.GamesStreamAllSelected2[i] === true) {
                I2.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
            if (Main.Instance.RewardShopState.category_idx === 2 && Main.Instance.DatabaseDataState.GamesStreamAllSelected3[i] === true) {
                I3.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
        }
        if (Main.Instance.RewardShopState.category_idx === 0) { Logic.Type.New(Main.Instance.RewardShopState, {I1}) } else if (Main.Instance.RewardShopState.category_idx === 1) { Logic.Type.New(Main.Instance.RewardShopState, {I2}) } else if (Main.Instance.RewardShopState.category_idx === 2) { Logic.Type.New(Main.Instance.RewardShopState, {I3}) }
    }
    // Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: false})
}

/*
function convertNetEnt(value: number): number {
    if (value === 1) return 0.01
    else if (value === 2) return 0.02
    else if (value === 3) return 0.05
    else if (value === 4) return 0.1
    else if (value === 5) return 0.2
    else if (value === 6) return 0.5
    else if (value === 7) return 1
    else if (value === 8) return 2
    // else if (value === 9) return 5
    // else if (value === 10) return 10
}

function gameProviders(a: string[]) {
    let b: string
    for (const item of a) {
        if (b === undefined) b = item
        else if (b !== item) return ''
    }
    // alert (b)
    return b
}
*/