import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import ReactTable from 'react-table'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets_ from '../../Assets_'
import {TransactionStatusNoPending, IWithdrawalEvent, TransactionStatus, WithdrawalRequest, WithdrawalRequestPaymentFile, PaymentProcessed, PaymentProcessedStatus, AccountStatus, Level} from '../../../Logic/Database/DatabaseData'
import {/*isUserGamanzaMagicHand, */numberWithSpaces, isProduction, isUserGamanzaAdmin, injectSpaces, copyStringToClipboard, isUserGDPR, isUserAccounting, capitalizeFirstLetter, isUserSupport, isUserOperatorRole} from '../../../Logic/Utils'
import { Logic } from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as bfi from '@gamingenius/bicfromiban'
import {faCopy} from '@fortawesome/free-regular-svg-icons'
import {faTrashAlt, faEye, faTimes, faCheck, faCheckSquare, faInfoCircle, faSyncAlt, faFileDownload, faUser, faMoneyBillWave, faFileCsv, faHashtag} from '@fortawesome/free-solid-svg-icons'
import {getRiskManager} from '../RiskComplianceStore/ResponsibleGaming'
import {SelectPlayer} from '../PlayerManagement/PlayerCard'
import {PlayerCardButtons} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import {GetFraudAlerts} from '../Settings/RiskComplianceSettings'
import * as Config from '../../../Logic/Config'
import Socket1 from '../../../Socket/socket-init'
import {GetDuplicates} from './WithdrawalDuplicates'
import * as RouteClasses from '../../../Socket/route-classes'

// import { onEnd2, onProgress2, onError2 } from 'src/Views/SideBar/SideBar'
// import {getStream} from '../../../Logic/Database/getStream'
/*
function updateWithdrawalRequest() { // TODO

    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1: [], withdrawalRequest2: []})
    const withdrawalRequest1: WithdrawalRequest[] = []
    const withdrawalRequest2: WithdrawalRequest[] = []
    for (const item of Main.Instance.DatabaseDataState.withdrawalRequest) {
        if (item.status === TransactionStatus.PENDING) {
            withdrawalRequest1.push(item)
        } else {
            withdrawalRequest2.push(item)
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1, withdrawalRequest2})

}
*/
/*
function getApprovedWithdrawalRequests(): number {
    let awr: number = 0
    if (Main.Instance.DatabaseDataState.withdrawalRequest2) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (item.status === TransactionStatus.APPROVED && !item.markedForPayment) {
                awr++
            }
        }
    }
    return awr
}
*/

function getPendingWithdrawalRequests(): number[] {
    const awr: number[] = []
    if (Main.Instance.DatabaseDataState.withdrawalRequest1) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest1) {
            if (item.status === TransactionStatus.PENDING && item.changeStatus) {
                awr.push(item.transactionId)
            }
        }
    }
    return awr
}

function getApprovedWithdrawalRequests(): number[] {
    const awr: number[] = []
    if (Main.Instance.DatabaseDataState.withdrawalRequest2) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (item.status === TransactionStatus.APPROVED && !item.markedForPayment && item.changeStatus) {
                awr.push(item.transactionId)
            }
        }
    }
    return awr
}

function getWaitingWithdrawalRequests(): number[] {
    const awr: number[] = []
    if (Main.Instance.DatabaseDataState.withdrawalRequest2) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (item.status === TransactionStatus.APPROVED && item.markedForPayment) {
                awr.push(item.transactionId)
            }
        }
    }
    return awr
}

function getExecutedWithdrawalRequests(): number[] {
    const awr: number[] = []
    if (Main.Instance.DatabaseDataState.withdrawalRequest2) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (item.status === TransactionStatus.EXECUTED && item.changeStatus) {
                awr.push(item.transactionId)
            }
        }
    }
    return awr
}

function getRejectedWithdrawalRequests(): number[] {
    const awr: number[] = []
    // console.log (Main.Instance.DatabaseDataState.withdrawalRequest2)
    if (Main.Instance.DatabaseDataState.withdrawalRequest2) {
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (item.status === TransactionStatus.REJECTED && item.changeStatus) {
                awr.push(item.transactionId)
            }
        }
    }
    // console.log (awr)
    return awr
}

function getRealDateString(): string {
    const curDate: Date = new Date()
    const HoursNumber = curDate.getHours()

    let Hours = HoursNumber.toString()
    // Hours += ''
    if (Hours.length == 1) {Hours = '0' + Hours}
    
    let Minutes = curDate.getMinutes().toString();
    if (Minutes.length == 1) {Minutes = '0' + Minutes;}

    let Seconds = curDate.getSeconds().toString();
    if (Seconds.length == 1) {Seconds = '0' + Seconds;}
    // console.log(hours, minutes, seconds)

    let DD:string = curDate.getDate().toString();
    let MM:string = (curDate.getMonth() + 1).toString();
    const YYYY:string = curDate.getFullYear().toString();
    if (DD.length == 1) {DD = '0' + DD;}
    if (MM.length == 1) {MM = '0' + MM;}

    // const DayInWeek: string = Assets.DaysInWeek[curDate.getDay()]
    // const date2: string = DayInWeek + ', ' + 
    return DD + '.' + MM + '.' + YYYY + 'T' + Hours + ':' + Minutes + ':' + Seconds
}
/*
function doesTheFileAlreadyExist(date: string): boolean {
    if (Main.Instance.DatabaseDataState.paymentProcessed) {
        for (const item of Main.Instance.DatabaseDataState.paymentProcessed) {
            if (item.date === date) {
                return true
            }
        }
    }
    return false
}
*/

function getSecurityRight(r: string) {
    // return Main.Instance.LoginState.custom.securityRights[r]
    return Main.Instance.LoginState ? Main.Instance.LoginState[r] : false
}

export function getIcon(s: SecurityRights): JSX.Element {
    const r: string = s === SecurityRights.GAMANZA_GAMES_ADMIN ? 'unused2' : s === SecurityRights.GAMANZA_ADMIN ? 'unused1' : s === SecurityRights.ADMINISTRATOR ? 'administrator' : s === SecurityRights.SUPPORT ? 'support' : s === SecurityRights.OPERATOR ? 'operator' : s === SecurityRights.MARKETING ? 'marketing' : s === SecurityRights.RFSPECIALIST ? 'rfSpecialist' : s === SecurityRights.RGSPECIALIST ? 'rgSpecialist' : s === SecurityRights.GDPR ? 'gdpr' : s === SecurityRights.ACCOUNTING ? 'accounting' : ''
    // console.log (Main.Instance.LoginState.custom.securityRights)
    return (
        <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[s] + ' ' + getSecurityRight(r) ? Assets.Enabled : Assets.Disabled} style = {{fontWeight: 400, marginLeft: '5px', color: getSecurityRight(r) ? '#5cb85c' : '#d9534f', fontSize: '24px'}} icon = {Assets_.SecurityRightsIcons[s]}/>
    )
}

function getFraudAlerts(playerId: string): JSX.Element[] {
    // return playerId
    const fraudAlerts: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.issuesFraudAlerts && Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.riskManagement && Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts) {
        for (const item of Main.Instance.DatabaseDataState.issuesFraudAlerts) {
            if (item.playerId === playerId) {
                for (const Item of Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts) {
                    if (Item.issueType === item.issueType) {
                        fraudAlerts.push(<span style = {{marginRight: '2px', fontWeight: 700}} title = {Item.description} key = {item.id}>{Item.shortCode}</span>)
                        break
                    }
                }
            }
        }
    }
    return fraudAlerts
}
/*
function RejectWithdrawals() {
    // changeStatus set to false
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    for (const item of withdrawalRequest1) {
        item.changeStatus = undefined
    }

    Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1, rejectWithdrawals: !Main.Instance.DatabaseDataState.rejectWithdrawals})   
}
*/
/*
export function rejectWithdrawals() {
    // changeStatus set to false
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    for (const item of withdrawalRequest1) {
        item.changeStatus = undefined
    }

    Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1})
}
*/

export function WithdrawalRequests(props: React.Props<any>): JSX.Element {
    // console.log ('1')
    // isUserGDPR

    if (!Main.Instance.DatabaseDataState.withdrawalRequest1 || !Main.Instance.DatabaseDataState.withdrawalRequest2 || Main.Instance.DatabaseDataState.PaymentProcessedGet) {
        return <div>{Assets.LoadingData}&nbsp;{Assets.ThisMayTakeAWhile}</div>
    }

    if (!isUserAccounting()/* || !isUserGDPR()*/) {
        return (
            <div>
                <h2>{Assets.restrictedmessage}</h2>
                <div style = {{lineHeight: '24px', fontSize: '16px', fontWeight: 700}}>
                    {Assets.Required + ' ' + Assets.SecurityRights + ':'}
                    {getIcon(SecurityRights.ACCOUNTING)}
                    {/*getIcon(SecurityRights.GDPR)*/}
                </div>
            </div>
        )
    }
    if (Main.Instance.DatabaseDataState.markedForPayment === false && Main.Instance.DatabaseDataState.withdrawalRequest2 !== null && Main.Instance.DatabaseDataState.withdrawalRequest2 !== undefined && Main.Instance.DatabaseDataState.paymentProcessed !== undefined && Main.Instance.DatabaseDataState.paymentProcessed !== null) {
        // here we need to set markedForPayment if refreshed
        // console.log (Main.Instance.DatabaseDataState.paymentProcessed)
        // console.log (Main.Instance.DatabaseDataState.withdrawalRequest2)

        const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
        if (Main.Instance.DatabaseDataState.paymentProcessed.length) {
            for (const item of withdrawalRequest2) {
                // let found: boolean = false
                
                // for (const Item of Main.Instance.DatabaseDataState.paymentProcessed) {
                // if (Item.data.status !== PaymentProcessedStatus.Generated) {continue}
                // console.log (Item)
                const Item = Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.paymentProcessed.length - 1]
                if (Item.data.status === PaymentProcessedStatus.Generated && Item.data.transactionIds) {
                    for (const ITEM of Item.data.transactionIds) {
                        if (ITEM === item.transactionId) {
                            // console.log ('found')
                            // found = true
                            item.markedForPayment = true
                            break
                        }
                    }
                }
                // if (found) {break}
            }
        }

        Logic.Type.New (Main.Instance.DatabaseDataState, {markedForPayment: true, withdrawalRequest2})
    }

    // console.log ('2')
    DbRoutes.wsReinitialize()

    // const numberOfApprovedWithdrawalRequests: number = getApprovedWithdrawalRequests()
    const pendingWithdrawalRequests: number[] = getPendingWithdrawalRequests()
    const approvedWithdrawalRequests: number[] = getApprovedWithdrawalRequests()
    const rejectedWithdrawalRequests: number[] = getRejectedWithdrawalRequests()
    const waitingWithdrawalRequests: number[] = getWaitingWithdrawalRequests()
    const executedWithdrawalRequests: number[] = getExecutedWithdrawalRequests()
    const date: string = getRealDateString() // (new Date()).toISOString()

    // console.log ('4')
    if (Main.Instance.DatabaseDataState.custom === undefined) {return(<div/>)}

    // console.log ('5')
    const filtered: Array<{id: string, value: any}> = [{id: 'status', value: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus}]

    if (Main.Instance.DatabaseDataState.wfPlayerId && Main.Instance.DatabaseDataState.wfPlayerId !== '0') {
        filtered.push ({id: 'playerId', value: Main.Instance.DatabaseDataState.wfPlayerId})
    } else if (Main.Instance.DatabaseDataState.wfPlayerIdSearch.length > 0) {
        filtered.push ({id: 'playerId', value: Main.Instance.DatabaseDataState.wfPlayerIdSearch})
    }
    // console.log (filtered)
    const filtered1: Array<{id: string, value: any}> = [{id: 'status', value: TransactionStatus.PENDING}]
    if (Main.Instance.DatabaseDataState.wfPlayerId1 && Main.Instance.DatabaseDataState.wfPlayerId1 !== '0') {
        filtered1.push ({id: 'playerId', value: Main.Instance.DatabaseDataState.wfPlayerId1})
    } else if (Main.Instance.DatabaseDataState.wfPlayerIdSearch1.length > 0) {
        filtered1.push ({id: 'playerId', value: Main.Instance.DatabaseDataState.wfPlayerIdSearch1})
    }
    if (Main.Instance.DatabaseDataState.withdrawalRequest2 && Main.Instance.DatabaseDataState.withdrawalRequest2.length > 0 && Main.Instance.DatabaseDataState.wtithdrawalRequest2DuplicatesInTable === undefined && fullyLoaded(false) && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED) {
        
        const withdrawalRequest: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
        withdrawalRequest.sort((a,b) => b.transactionId - a.transactionId )
        const wtithdrawalRequest2DuplicatesInTable: WithdrawalRequest[] = []
        for (let i: number = 0; i < withdrawalRequest.length; i++) {
            if (withdrawalRequest[i+1] && withdrawalRequest[i+1].transactionId === withdrawalRequest[i].transactionId) {
                wtithdrawalRequest2DuplicatesInTable.push(withdrawalRequest[i])
            }

        }

        Logic.Type.New(Main.Instance.DatabaseDataState, {wtithdrawalRequest2DuplicatesInTable})
    }

    // console.log ('paymentProcessed2')
    // console.log (Main.Instance.DatabaseDataState.paymentProcessed)
    return (
        <div className = "pr">
            {/*
            <div className = "clearfix">
                <a
                    title = {Assets.GetFullReport}
                    style = {{padding: '0px', border: '0px', background: 'transparent'}}
                    className = "btn btn-default btn-xs dbl fl mr0"
                    onClick = {() => DbRoutes.getWallet(null)}
                >
                    <FontAwesomeIcon className = "mr dbl fl" style = {{fontSize: '20px',marginTop: '1px'}} icon = {faFileCsv}/>
                </a>
                <div className = "dbl fl">
                    <button className = "btn btn-default btn-xs" onClick = {() => DbRoutes.getWallet(undefined)}>{Assets.All}</button>
                    <button className = "btn btn-primary btn-xs" onClick = {() => DbRoutes.getWallet(TransactionStatus.APPROVED)}>{Assets.Approved}</button>
                    <button className = "btn btn-danger btn-xs" onClick = {() => DbRoutes.getWallet(TransactionStatus.REJECTED)}>{Assets.Rejected}</button>
                    <button className = "btn btn-warning btn-xs" onClick = {() => DbRoutes.getWallet(TransactionStatus.PENDING)}>{Assets.Pending}</button>
                    <button className = "btn btn-danger btn-xs label-cancelled" onClick = {() => DbRoutes.getWallet(TransactionStatus.CANCELED)}>{Assets.Cancelled}</button>
                    <button className = "btn btn-info btn-xs" onClick = {() => DbRoutes.getWallet(TransactionStatus.EXECUTED)}>{Assets.Executed}</button>
                    <button className = "btn btn-success btn-xs" onClick = {() => DbRoutes.getWallet(TransactionStatus.COMPLETED)}>{Assets.Completed}</button>
                    <button className = "btn btn-danger btn-xs label-denied-by-bank mr0" onClick = {() => DbRoutes.getWallet(TransactionStatus.DENIEDBYBANK)}>{Assets.DeniedByBank}</button>
                </div>
            </div>
            */}
            {/*: void 0*/}
            <div style = {{position: 'absolute', border: '1px solid #999', top: '-27px', right: '0px', background: '#eee', padding: '5px 10px', width: '400px'}}>
                <div style = {{lineHeight: '22px'}}>
                    <strong>{Assets.Payment + ' ' + Assets.Entity}:</strong>&nbsp;{Main.Instance.DatabaseDataState.custom.paymentInformation.beneficiary + ' (' + Main.Instance.DatabaseDataState.custom.paymentInformation.abbreviation + ')'}
                </div>
                <div style = {{lineHeight: '22px'}}>
                    <strong>{Assets.Address}:</strong>&nbsp;{Main.Instance.DatabaseDataState.custom.paymentInformation.address + ' ' + Main.Instance.DatabaseDataState.custom.paymentInformation.streetNumber},&nbsp;{Main.Instance.DatabaseDataState.custom.paymentInformation.postalCode + ' ' + Main.Instance.DatabaseDataState.custom.paymentInformation.city + ', ' + Main.Instance.DatabaseDataState.custom.paymentInformation.country + ' (' + Main.Instance.DatabaseDataState.custom.paymentInformation.countryAbbreviation + ')'}
                </div>
                <div style = {{lineHeight: '22px'}}>
                    <strong>{Assets_.IBAN}:</strong>&nbsp;{Main.Instance.DatabaseDataState.custom.paymentInformation.iban.toUpperCase()},&nbsp;<strong>{Assets_.BIC}:</strong>&nbsp;{Main.Instance.DatabaseDataState.custom.paymentInformation.bic.toUpperCase()}
                </div>
            </div>
            {Main.Instance.DatabaseDataState.showFraudAlertsDescriptions ?
            <div style = {{position: 'absolute', top: '-30px', left: '320px'}}>
                {GetFraudAlerts(true)}
            </div> : void 0}
            <FontAwesomeIcon icon = {faInfoCircle} style = {{position: 'absolute', top: '-23px', right: '5px', fontSize: '20px'}}/>
            <h2>
                {Assets.WithdrawalRequests}
                <button 
                    style = {{border: '0px', background: 'transparent', fontSize:'12px', padding: '0px', top: '0px', left: '303px', position: 'absolute'}}
                    onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {infoWithdrawals: !Main.Instance.DatabaseDataState.infoWithdrawals})}
                    type = "button"
                    className = "btn btn-default btn-xs"
                >
                    <FontAwesomeIcon icon = {faInfoCircle}/>
                </button>
                <button
                    disabled = {RefreshButtonDisabled()}
                    style = {{border: '0px', background: 'transparent', fontSize:'12px', padding: '0px', top: '14px', left: '303px', position: 'absolute'}}
                    onClick = {() => LoadWithdrawalsAgain()}
                    type = "button"
                    className = "btn btn-default btn-xs"
                >
                    <FontAwesomeIcon icon = {faSyncAlt}/>
                </button>
            </h2>

            <div className = "withdrawal-requests">
                <div className = "pr">
                    {Main.Instance.DatabaseDataState.infoWithdrawals ?
                    <div className = "pr" style = {{border: '1px solid #999', position: 'absolute', left: '700px', right: '402px', top: '-70px', background: '#fff'}}>
                        <button onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {infoWithdrawals: !Main.Instance.DatabaseDataState.infoWithdrawals})} type = "button" className = "btn btn-default btn-xs" style = {{position: 'absolute', top: '-1px', right: '-7px', padding: '0px',border: '0px', background: 'transparent', fontSize:'12px'}}><FontAwesomeIcon icon = {faTimes}/></button>
                        {getInfoWithdrawals()}
                    </div> : void 0}
                    <div style = {{position: 'absolute', top: '25px', right: '0px'}}>
                        <div className = "clearfix">
                            <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.showFraudAlertsDescriptions} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {showFraudAlertsDescriptions: !Main.Instance.DatabaseDataState.showFraudAlertsDescriptions})}/>
                            <div style = {{marginTop: '2px',marginLeft: '2px', marginRight: '10px'}} className = "fl label label-default label-sm">{Assets.FraudAlerts.toLowerCase() + ' ' + Assets.Descriptions.toLowerCase()}</div>

                            {/*<div style = {{marginLeft: '10px', marginRight: '2px'}} className = "fl">{Assets.FilterWithdrawalRequestStatus + ':'}</div>*/}
                            <input disabled = {true} className = "dbl fl" type = "checkbox" checked = {true} /*onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyWithdrawalRequestStatusApproved: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved})}*//>
                            <div style = {{marginLeft: '2px', marginTop: '2px'}} className="label label-warning label-sm fl">{Assets.Pending}</div>
                        </div>
                    </div>

                    <div className = "clearfix">
                        <select
                            disabled = {Main.Instance.DatabaseDataState.wfPlayerIdSearch1.length > 0}
                            className = "fl dbl mr"
                            style = {{height: '22px'/*, width: '200px'*/}}
                            value = {Main.Instance.DatabaseDataState.wfPlayerId1}
                            onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {wfPlayerId1: e.target.value})}
                        >
                            {getPlayerIdOptions(Main.Instance.DatabaseDataState.withdrawalRequest1)}
                        </select>
                        {Main.Instance.DatabaseDataState.wfPlayerId1 === '0' ?
                        <input style = {{height: '22px', width: '300px', paddingLeft: '5px', paddingRight: '5px'}} placeholder = {Assets.Filter + Assets._by_ + Assets.PlayerId} className = "dbl fl mr" onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {wfPlayerIdSearch1: e.target.value})} type = "text" value = {Main.Instance.DatabaseDataState.wfPlayerIdSearch1}/>
                        : void 0}
                    </div>
                    {Main.Instance.DatabaseDataState.withdrawalRequest1 ? loadMore(true, pendingWithdrawalRequests) : void 0}
                    {isUserGamanzaAdmin() ? <button disabled = {Main.Instance.DatabaseDataState.withdrawalRequest1 === undefined || Main.Instance.DatabaseDataState.withdrawalRequest1 === null || Main.Instance.DatabaseDataState.withdrawalRequest1.length === 0} title = {Assets.Download + ' ' + Assets.CSV + ' (' + Assets.Missing.toLowerCase() + ' ' + Assets_.BIC + ' ' + Assets.Code.toLowerCase() + ')'} type = "button" className = "ml btn btn-default btn-sm mr0" style = {{color: '#6f9c67', background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px'}} onClick = {() => Download(4)}><FontAwesomeIcon icon = {faFileCsv}/></button> : null}
                    {/*
                    <input style = {{marginLeft: '15px'}} type = "checkbox" checked = {Main.Instance.DatabaseDataState.rejectWithdrawals} onChange = {() => RejectWithdrawals()}/>
                    <span style = {{marginLeft: '5px'}}>{Assets.RejectWithdrawals}</span>*/}
                    {/*console.log (Main.Instance.DatabaseDataState.withdrawalRequest1)*/}
                    <ReactTable
                        data = {Main.Instance.DatabaseDataState.withdrawalRequest1 ? Main.Instance.DatabaseDataState.withdrawalRequest1 : []}
                        filtered = {filtered1}
                        columns = {[
                            {show: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED, width: 30, headerClassName: 'th-center', className: 'td-left change-status-checkbox', Header: '', accessor: 'changeStatus', Cell: row => getChangeStatus(row.original.transactionId, true, row.value, row.index, row.original.iban ? row.original.iban.toUpperCase() : undefined, row.original.bic ? row.original.bic.toUpperCase(): undefined, row.page)},
                            // {show: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED, width: 50, headerClassName: 'th-center', className: 'td-center', Header: 'Reject', accessor: 'transactionId', Cell: row => Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && (row.original.iban === undefined || row.original.iban === null || row.original.bic === undefined || row.original.bic === null || !(row.original.bic.length === 8 || row.original.bic.length === 11)) ? '-' : <button title = "Reject withdrawal" type = "button" onClick = {() => rejectWithdrawal(row.value, row.original)} className = "btn btn-danger btn-xs mr0">Reject</button>},
                            
                            {width: 80, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.Transaction + ' ' + Assets.ID}>{Assets.TransactionShort + '. ' + Assets.ID}</span>, accessor: 'transactionId', Cell: row => <span title = {Assets.Transaction + ' ' + Assets.ID} /*title = {Assets.SelectPlayer + ' ' + row.original.username} style = {{marginTop: '-2px'}} onClick = {() => SelectPlayer(row.original.playerId, PlayerCardButtons.Transactions)} className = "btn btn-default btn-xs mr0"*/>{row.value}</span>},
                            // {width: 80, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.ID}</span>, accessor: 'transactionId', Cell: row => <button disabled = {true} title = {Assets.Transaction + ' ' + Assets.ID} style = {{marginTop: '-2px'}} onClick = {() => ShowTransactionHistory(row.value, true)} className = {row.value === Main.Instance.DatabaseDataState.withdrawalHistorySelectedPending ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}>{row.value}</button>},
                            {show: !isUserGDPR(), width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Username}</span>, accessor: 'username', Cell: row => row.value ? <button title = {Assets.Show + ' ' + Assets.PlayerCard.toLowerCase()} style = {{marginTop: '-2px'}} onClick = {() => SelectPlayer(row.original.playerId, PlayerCardButtons.Transactions /*PlayerCardButtons.EventsTimeline*/)} className = "btn btn-default btn-xs mr0 ml">{row.value}</button> : '-'},
                            {show: !isUserGDPR(), width: 300, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.PlayerID}</span>, accessor: 'playerId', Cell: row => row.value ? <span title = {row.original.username ? row.original.username : ''}>{row.value}</span> : '-'},
                            {show: isUserGDPR(), width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.First + ' / ' + Assets.LastName}</span>, accessor: 'firstName', Cell: row => <button title = {Assets.Show + ' ' + Assets.PlayerCard.toLowerCase() + Assets._for_ + row.original.username + ' (' + row.value + ' ' + row.original.lastName + ')'} style = {{marginTop: '-2px'}} onClick = {() => SelectPlayer(row.original.playerId, PlayerCardButtons.Transactions /*PlayerCardButtons.EventsTimeline*/)} className = "btn btn-default btn-xs mr0 ml">{row.value + ' ' + row.original.lastName}</button>},
                            {show: isUserGDPR(), width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.BeneficiaryName}</span>, accessor: 'beneficiaryName', Cell: row => <span title = {row.value} className = "ml">{row.value}</span>},
                            {width: 25, headerClassName: 'th-left', className: 'td-left', Header: <FontAwesomeIcon title = {Assets.Name + ' ' + Assets.Identity.toLowerCase()} icon = {faCheckSquare}/>, accessor: 'lastName', Cell: row => <div style = {{textAlign: 'center'}}>{isNameValid(row.original.firstName, row.value, row.original.beneficiaryName)}</div>},
                            {width: 100, headerClassName: 'th-center', className: 'td-left', Header: <span title = {Assets.AccountStatus}>{Assets.Account}</span>, accessor: 'accountStatus', Cell: row => getAS(row.value)},
                            {width: 100, headerClassName: 'th-center', className: 'td-left', Header: <span>{Assets.RiskLevel}</span>, accessor: 'riskLevel', Cell: row => getRL(row.value)},
                            {width: 100, headerClassName: 'th-center', className: 'td-left', Header: <span title = {Assets.GamificationLevel}>{Assets.Gamification}</span>, accessor: 'level', Cell: row => getGL(row.value)},
                            {headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.FraudAlerts}</span>, accessor: 'playerId', Cell: row => <span className = "ml">{row.value ? getFraudAlerts(row.value) : '-'}</span>},
                            {width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.Requested + ' ' + Assets_.UTC}</span>, accessor: 'created', Cell: row => <span>{GetDate(row.value)}</span>},
                            {width: 120, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Amount}</span>, accessor: 'amount', Cell: row => <span className = "ml">{numberWithSpaces(row.value) + ' ' + row.original.currency}</span>},
                            {width: 25, headerClassName: 'th-left', className: 'td-left', Header: <FontAwesomeIcon title = {Assets_.IBAN + ' ' + Assets.Validity} icon = {faCheckSquare}/>, accessor: 'iban', Cell: row => <div style = {{textAlign: 'center'}}>{isIbanValid(row.value)}</div>},
                            {show: isUserGDPR(), width: 220, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.IBANExplained} className = "ml">{Assets_.IBAN}</span>, accessor: 'lastName', Cell: row => <span className = "ml">{row.original.iban ? row.original.iban.toUpperCase() : Assets.MissingIBAN}</span>},
                            // {width: 140, headerClassName: 'th-left', className: 'td-center', Header: <span className = "ml" title = {Assets.BICExplained}>{Assets_.BIC} ({Assets_.SWIFT}){' ' + Assets.Edit + '?'}</span>, accessor: 'bic', Cell: row => <input title = {Assets.bicmessage} value = {row.value} onChange = {(e) => updateBIC(row.index, e.target.value)} style = {{width: 'calc(100% + 10px)', marginLeft: '-5px', height: '24px'}} placeholder = {row.original.iban ? bfi.getBIC((row.original.iban).replace(/ /g,'')) : Assets.MissingIBAN}/>},
                            {show: isUserGDPR(), width: 140, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml" title = {Assets.BICExplained}>{Assets_.BIC} ({Assets_.SWIFT}){/*' ' + Assets.Edit + '?'*/}</span>, accessor: 'bic', Cell: row => <span title = {Assets.bicmessage} /*style = {{width: '100%', marginLeft: '-24px', height: '24px'}}*/>{row.value && row.value.length > 0 ? row.value : row.original.iban ? bfi.getBIC((row.original.iban.toUpperCase()).replace(/ /g,'')).toUpperCase() : Assets.MissingIBAN}</span>},
                            // {width: 85, headerClassName: 'th-center', className: 'td-left label-status', Header: <span>{Assets.Status}</span>, accessor: 'status', Cell: row => getStatus(row.value)},
                        ]}
                        showPaginationTop = {true}
                        showPaginationBottom = {false}
                        pageSizeOptions = {[5, 10, 20, 50, 100, 200, 500, 1000]}
                        defaultPageSize={50}
                        className="-striped -highlight"
                        page={Main.Instance.DatabaseDataState.withdrawalRequest1selectedPage}
                        pageSize={Main.Instance.DatabaseDataState.withdrawalRequest1PageSize}
                        onPageChange={(pageIndex) => { 
                            Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1selectedPage: pageIndex})
                        }}
                        onPageSizeChange={(pageSize, pageIndex) => {
                            if (pageSize !== Main.Instance.DatabaseDataState.withdrawalRequest1PageSize) {
                                Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1PageSize: pageSize})
                            }
                        }}
                        defaultSorted={[
                            {
                              id: 'beneficiaryName',
                              desc: false
                            }
                          ]}
                />
                </div>
                {controlPanel(true)}
                {/*Main.Instance.DatabaseDataState.withdrawalHistorySelectedPending && Main.Instance.DatabaseDataState.withdrawalHistoryPending ?
                <>
                <br/>
                <h4>{Assets.Transaction + ' ' + Assets.History}</h4>
                {getTransactionHistory(Main.Instance.DatabaseDataState.withdrawalHistoryPending, 1)}
                </> : void 0*/}
                <br/>
                <div className = "pr">
                    <div style = {{position: 'absolute', top: '25px', right: '0px'}}>
                        {getFilterOtherWithdrawals()}
                    </div>

                    <div className = "clearfix">
                        <select
                            disabled = {Main.Instance.DatabaseDataState.wfPlayerIdSearch.length > 0}
                            className = "fl dbl mr"
                            style = {{height: '22px'/*, width: '200px'*/}}
                            value = {Main.Instance.DatabaseDataState.wfPlayerId}
                            onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {wfPlayerId: e.target.value})}
                        >
                            {getPlayerIdOptions(Main.Instance.DatabaseDataState.withdrawalRequest2)}
                        </select>
                        {Main.Instance.DatabaseDataState.wfPlayerId === '0' ?
                        <input style = {{height: '22px', width: '300px', paddingLeft: '5px', paddingRight: '5px'}} placeholder = {Assets.Filter + Assets._by_ + Assets.PlayerId} className = "dbl fl mr" onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {wfPlayerIdSearch: e.target.value})} type = "text" value = {Main.Instance.DatabaseDataState.wfPlayerIdSearch}/>
                        : void 0}
                    </div>

                    {Main.Instance.DatabaseDataState.withdrawalRequest2 && approvedWithdrawalRequests ? loadMore(false, approvedWithdrawalRequests, waitingWithdrawalRequests, rejectedWithdrawalRequests, executedWithdrawalRequests) : void 0}
                    <ReactTable
                        data = {Main.Instance.DatabaseDataState.withdrawalRequest2 ? Main.Instance.DatabaseDataState.withdrawalRequest2 : []}
                        filtered = {filtered}
                        columns = {[
                            {show: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && DbRoutes.thereIsNoneGeneratedPaymentFiles() || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED, /*show: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved,*/width: 25, headerClassName: 'th-left', className: 'td-left change-status-checkbox', Header: ''/*<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-5px'}} className = "btn btn-default btn-sm mr0" onClick = {() => Download(2)}><FontAwesomeIcon icon = {faFileCsv}/></button>*/, accessor: 'changeStatus', Cell: row => /*(row.original.status === TransactionStatus.APPROVED && DbRoutes.thereIsNoneGeneratedPaymentFiles() || row.original.status === TransactionStatus.REJECTED || row.original.status === TransactionStatus.EXECUTED) ?*/ getChangeStatus(row.original.transactionId, false, row.value, row.index, undefined, undefined, row.page) /*: ''*/},
                            {width: 80, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.Transaction + ' ' + Assets.ID}>{Assets.TransactionShort + '. ' + Assets.ID}</span>, accessor: 'transactionId', Cell: row => <button title = {row.original.username + (isUserGDPR() ? ' (' + row.original.firstName + ' ' + row.original.lastName + ')' : '')} style = {{marginTop: '-2px'}} onClick = {() => ShowTransactionHistory(row.value)} className = {row.value === Main.Instance.DatabaseDataState.withdrawalHistorySelected ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}>{row.value}</button>},
                            {show: !isUserGDPR(), width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Username}</span>, accessor: 'username', Cell: row => row.value ? <button title = {Assets.Show + ' ' + Assets.PlayerCard.toLowerCase()} style = {{marginTop: '-2px'}} onClick = {() => SelectPlayer(row.original.playerId, PlayerCardButtons.Transactions /*PlayerCardButtons.EventsTimeline*/)} className = "btn btn-default btn-xs mr0 ml">{row.value}</button> : '-'},
                            {show: !isUserGDPR(), width: 300, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.PlayerID}</span>, accessor: 'playerId', Cell: row => row.value ? <span title = {row.original.username ? row.original.username : ''}>{row.value}</span> : '-'},
                            {show: isUserGDPR(), width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.BeneficiaryName}</span>, accessor: 'beneficiaryName', Cell: row => <span title = {row.value} className = "ml">{row.value}</span>},
                            // {width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Requested}</span>, accessor: 'timestampRequested', Cell: row => <span className = "ml">{row.value}</span>},
                            {width: 120, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Amount}</span>, accessor: 'amount', Cell: row => <span title = {Assets.Requested + Assets._at_ + row.original.timestampRequested} className = "ml">{numberWithSpaces(row.value) + ' ' + row.original.currency}</span>},
                            {width: 25, headerClassName: 'th-left', className: 'td-left', Header: <FontAwesomeIcon title = {Assets_.IBAN + ' ' + Assets.Validity} icon = {faCheckSquare}/>, accessor: 'iban', Cell: row => <div style = {{textAlign: 'center'}}>{isIbanValid(row.value)}</div>},
                            {show: isUserGDPR(), width: 220, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.IBANExplained} className = "ml">{Assets_.IBAN}</span>, accessor: 'playerId', Cell: row => <span className = "ml">{row.original.iban !== undefined && row.original.iban !== null ? row.original.iban.toUpperCase() : Assets.MissingIBAN}</span>},
                            {show: isUserGDPR(), width: 120, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.BICExplained}>{Assets_.BIC} ({Assets_.SWIFT})</span>, accessor: 'bic', Cell: row => <span>{row.value && row.value.length > 0 ? row.value : row.original.iban ? bfi.getBIC((row.original.iban.toUpperCase()).replace(/ /g,'')).toUpperCase() : Assets.MissingIBAN}</span>},
                            // {width: 200, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Approved + Assets._by_ + Assets.Operator.toLowerCase()}</span>, accessor: 'operatorId', Cell: row => row.value === undefined ? '-' : <span className = "ml"><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManager(row.value)}</span>},
                            // {width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved ? Assets.Approved : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied ? Assets.Rejected : (Assets.Approved + '/' + Assets.Rejected)}</span>, accessor: 'timestampApprovedDenied', Cell: row => <span className = "ml">{row.value}</span>},
                            // {show: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied, width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted ? Assets.Executed : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCancelled ? Assets.Cancelled : Assets.Executed + '/' + Assets.Cancel}</span>, accessor: 'timestampExecutedCancelled', Cell: row => <span className = "ml">{row.value}</span>},
                            // {show: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCancelled, width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCompleted ? Assets.Completed : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDeniedByBank ? Assets.DeniedByBank : Assets.Completed + '/' + Assets.DeniedByBankAbbrev}</span>, accessor: 'timestampCompletedDeniedByBank', Cell: row => <span className = "ml">{row.value}</span>},
                            {width: 115, headerClassName: 'th-left', className: 'td-left label-status', Header: <span className = "ml">{Assets.Status}</span>, accessor: 'status', Cell: row => row.value ? getStatus(row.value) : '-'},
                            {width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.Requested + ' ' + Assets_.UTC}</span>, accessor: 'created', Cell: row => row.value ? <span>{GetDate(row.value)}</span> : '-'},

                            {/*show: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.COMPLETED && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.DENIEDBYBANK && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.CANCELED, */headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.Set + Assets._as_ + Assets.Completed} className = "ml">{Assets.Action}</span>, accessor: 'status', Cell: row => row.value === TransactionStatus.EXECUTED ? <><button style = {{marginTop: '1px'}} onClick = {() => changeExecutedStatusTo(TransactionStatus.COMPLETED, row.original.transactionId)} className = "btn btn-success btn-xs dbl fl ml mr5px">{Assets.Set + Assets._as_ + Assets.Completed}</button><button title = {Assets.Set + Assets._as_ + Assets.DeniedByBank} style = {{marginTop: '1px'}} onClick = {() => changeExecutedStatusTo(TransactionStatus.DENIEDBYBANK, row.original.transactionId)} className = "btn btn-success label-denied-by-bank btn-xs dbl fl mr0">{(/*row.value === TransactionStatus.EXECUTED ||*/ Main.Instance.MenuState.Open || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED ? Assets.Set + Assets._as_ + Assets.DeniedByBank : Assets.DeniedByBankAbbrev)}</button></> : ''},
                            {show: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED, width: 200, headerClassName: 'th-left', className: 'td-left label-status', Header: <span className = "ml">{Assets.Marked + Assets._for_ + Assets.Execution.toLowerCase()}</span>, accessor: 'markedForPayment', Cell: row => <span className = {row.value ? 'ml label label-success' : 'ml label label-default'}>{row.value ? Assets.Yes : Assets.No}</span>},
                            // {show: isUserGamanzaMagicHand() && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED, width: 200, headerClassName: 'th-left', className: 'td-left label-status', Header: <span className = "ml">{Assets.Execute}</span>, accessor: 'transactionId', Cell: row => <button onClick = {() => Execute(row.value)} type = "button">{Assets.Execute}</button>}
                        ]}
                        showPaginationTop = {true}
                        showPaginationBottom = {false}
                        pageSizeOptions = {[5, 10, 20, 50, 100, 200, 500]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        page={Main.Instance.DatabaseDataState.withdrawalRequest2selectedPage}
                        onPageChange={(pageIndex) => { 
                            Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest2selectedPage: pageIndex})
                        }}
                        onPageSizeChange={(pageSize, pageIndex) => {
                            if (pageSize !== Main.Instance.DatabaseDataState.withdrawalRequest2PageSize) {
                                Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest2PageSize: pageSize})
                            }
                        }}
                    />
                </div>
                {fullyLoaded(false) && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && Main.Instance.DatabaseDataState.wtithdrawalRequest2DuplicatesInTable !== undefined ? Main.Instance.DatabaseDataState.wtithdrawalRequest2DuplicatesInTable.length === 0 ? <span style = {{fontSize: '15px'}} className = "label label-success mb mt dibl">{Assets.ThereAreNoDuplicatesFoundInProcessedWithdrawals + ' ' + Assets.Table.toLowerCase() + '.'}</span> : <span style = {{fontSize: '15px'}} className = "label label-danger mb mt dibl">{Assets.Duplicates + Assets._in_ + Assets.Processed.toLowerCase() + ' ' + Assets.Withdrawals.toLowerCase() + ' ' + Assets.Table.toLowerCase() + '. ' + Assets.Please + ' ' + Assets.Refresh.toLowerCase() + Assets._the_ + Assets.Page.toLowerCase() + Assets._and_ + Assets.Load.toLowerCase() + Assets._the_ + Assets.Data.toLowerCase() + ' ' + Assets.Again.toLowerCase() + '.'}</span> : void 0}
                {controlPanel(false, date, approvedWithdrawalRequests, rejectedWithdrawalRequests)}

                {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED ? GetDuplicates(false) : void 0}

                {Main.Instance.DatabaseDataState.withdrawalHistorySelected && Main.Instance.DatabaseDataState.withdrawalHistory ?
                <>
                <br/>
                {/*console.log (Main.Instance.DatabaseDataState.withdrawalHistory)*/}
                <h4>{Assets.Transaction + ' ' + Assets.History}</h4>
                {getTransactionHistory(Main.Instance.DatabaseDataState.withdrawalHistory, 5)}
                </> : void 0}
                <br/>
                {fullyLoaded(false) || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus !== TransactionStatusNoPending.APPROVED ?
                <div className = "pr clearfix">
                    <div className = "pr" style = {{width: '80%', float: 'left'}}>
                        <div style = {{position: 'absolute', top: '5px', right: '0px'}}>
                            <div className = "clearfix">
                                <div style = {{marginLeft: '0px', marginRight: '10px'}} className = "fl">{Assets.FilterPaymentProcessedStatus + ':'}</div>

                                <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusAll} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyPaymentProcessedStatusAll: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusAll, onlyPaymentProcessedStatusGenerated: false, onlyPaymentProcessedStatusPending: false, onlyPaymentProcessedStatusCompleted: false, onlyPaymentProcessedStatusInquiry: false})}/>
                                <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-default label-sm fl">{Assets.All}</div>

                                <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyPaymentProcessedStatusGenerated: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated, onlyPaymentProcessedStatusAll: false, onlyPaymentProcessedStatusPending: false, onlyPaymentProcessedStatusCompleted: false, onlyPaymentProcessedStatusInquiry: false})}/>
                                <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-danger label-sm fl">{Assets.Generated}</div>

                                <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyPaymentProcessedStatusPending: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending, onlyPaymentProcessedStatusAll: false, onlyPaymentProcessedStatusGenerated: false, onlyPaymentProcessedStatusCompleted: false, onlyPaymentProcessedStatusInquiry: false})}/>
                                <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-warning label-sm fl">{Assets.Pending}</div>

                                <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyPaymentProcessedStatusCompleted: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted, onlyPaymentProcessedStatusAll: false, onlyPaymentProcessedStatusGenerated: false, onlyPaymentProcessedStatusPending: false, onlyPaymentProcessedStatusInquiry: false})}/>
                                <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '5px'}} className="label label-success label-sm fl">{Assets.Completed}</div>

                                <input className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusInquiry} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyPaymentProcessedStatusInquiry: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusInquiry, onlyPaymentProcessedStatusAll: false, onlyPaymentProcessedStatusGenerated: false, onlyPaymentProcessedStatusPending: false, onlyPaymentProcessedStatusCompleted: false})}/>
                                <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '5px'}} className="label label-info label-sm fl">{Assets.Inquiry}</div>
                            </div>
                        </div>
                        <strong>{Assets.Processed + ' ' + Assets.PaymentFiles}</strong>
                        <button
                            disabled = {!Main.Instance.DatabaseDataState.paymentProcessed || Main.Instance.DatabaseDataState.paymentProcessed.length === 0}
                            title = {Assets.Download + ' ' + Assets.CSV}
                            type = "button"
                            className = "ml btn btn-default btn-sm mr0"
                            style = {{background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px'}}
                            onClick = {() => Download(3)}
                        >
                            <FontAwesomeIcon icon = {faFileCsv}/>
                        </button>
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.paymentProcessed ? Main.Instance.DatabaseDataState.paymentProcessed : []}
                            filtered = {
                                        Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated ?
                                        [{id: 'data.status', value: PaymentProcessedStatus.Generated}] :
                                        Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending ?
                                        [{id: 'data.status', value: PaymentProcessedStatus.Pending}] :
                                        Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted ?
                                        [{id: 'data.status', value: PaymentProcessedStatus.Completed}] :
                                        Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusInquiry ?
                                        [{id: 'data.status', value: PaymentProcessedStatus.Inquiry}] :
                                        []}
                            columns = {[
                                {width: 50, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.ID}</span>, accessor: 'id', Cell: row => <span>&nbsp;{row.value}</span>},
                                {width: 40, headerClassName: 'th-center  pl0 pr0', className: 'td-center', Header: Assets_.XML, accessor: 'data.xmlPaymentFile', Cell: row => <button onClick = {() => selectXML(row.original.id)} title = {Assets.Show + ' ' + Assets_.XML + ' - ' + Assets.Size + ': ' + ((row && row.value ? row.value.length : 0)/1024).toFixed(1) + ' ' + Assets_.kilobytesShort} disabled = {/*!isUserGDPR() || */row.original.id === Main.Instance.DatabaseDataState.selectedPaymentFile} className = {row.original.id === Main.Instance.DatabaseDataState.selectedPaymentFile ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faEye}/></button>},
                                {width: 106, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.DayInWeek}</span>, accessor: 'data.date', Cell: row => <span>{Assets.DaysInWeek[new Date(row.value.substring(6,10) + '-' + row.value.substring(3,5) + '-' + row.value.substring(0,2)).getDay()]}</span>},
                                {width: 90, headerClassName: 'th-left', className: 'td-left', Header: <span title = {Assets.Payment + ' ' + Assets.Date.toLowerCase()} className = "ml">{Assets.Date}</span>, accessor: 'data.date', Cell: row => <span className = "ml">{row.value}</span>},
                                {width: 25, headerClassName: 'th-center pl0 pr0', className: 'td-center', Header: <span title = {'#' + Assets.Payment + Assets._on_ + Assets.Date}><FontAwesomeIcon icon = {faHashtag}/></span>, accessor: 'data.fileInDate', Cell: row => <span>{row.value}</span>},
                                {width: 180, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.Generated + Assets._by_ + Assets.Operator.toLowerCase()}</span>, accessor: 'data.operatorId', Cell: row => row.value === undefined ? '-' : <span className = "ml"><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManager(row.value)}</span>},
                                {width: 35, headerClassName: 'th-center pl0 pr0', className: 'td-center', Header: <span title = {Assets.Total + ' ' + Assets.Payments}><FontAwesomeIcon icon = {faMoneyBillWave}/></span>, accessor: 'data.totalPayments', Cell: row => <span title = {row.original.transactionIds ? JSON.stringify(row.original.transactionIds).replace('[','').replace(']','').replace(/,/g, '|').replace(/"/g, '') : undefined}>{row.value}</span>},
                                {width: 110, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml">{Assets.TotalAmount}</span>, accessor: 'data.totalAmount', Cell: row => <span className = "ml">{numberWithSpaces(row.value) + ' ' + (row.original.currency ? row.original.currency : 'CHF')}</span>},
                                {width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml5px">{Assets.Generated}</span>, accessor: 'data.timestampGenerated', Cell: row => <span className = "ml5px">{row.value}</span>},
                                {show: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated, width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml5px">{Assets.Pending}</span>, accessor: 'data.timestampPending', Cell: row => <span className = "ml5px">{row.value}</span>},
                                {show: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending, width: 150, headerClassName: 'th-left', className: 'td-left', Header: <span className = "ml5px">{Assets.Completed}</span>, accessor: 'data.timestampCompleted', Cell: row => <span className = "ml5px">{row.value}</span>},
                                {width: 84, headerClassName: 'th-center', className: 'td-center label-status', Header: <span>{Assets.Status}</span>, accessor: 'data.status', Cell: row => getPaymentStatus(row.value)},
                                {show: !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted, headerClassName: 'th-left', className: 'td-left', Header: <span>{Assets.Action}</span>, accessor: 'data.status', Cell: row => row.value === PaymentProcessedStatus.Pending ? <button style = {{marginTop: '1px'}} onClick = {() => setPaymentFileCompleted(row.original.id)} className = "btn btn-success btn-xs dbl fl mr0">{Assets.Set + Assets._as_ + Assets.Completed}</button> : row.value === PaymentProcessedStatus.Generated ? <><button disabled = {Main.Instance.DatabaseDataState.processButtonDisabled || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus !== TransactionStatusNoPending.APPROVED || !fullyLoaded(false)} title = {Assets.Process + ' ' + Assets_.XML + ' ' + Assets.PaymentFile} style = {{marginTop: '1px'}} onClick = {() => processPaymentFile(row.original.id)} className = "btn btn-primary btn-xs dbl fl mr0">{Assets.Process}</button><button title = {Assets.Delete + ' ' + Assets_.XML + ' ' + Assets.PaymentFile} style = {{marginTop: '1px'}} onClick = {() => deletePaymentFile(row.original.id)} className = "btn btn-danger btn-xs dbl fl ml mr0"><FontAwesomeIcon icon = {faTrashAlt}/></button></> : ''},
                                // {show: isUserGamanzaMagicHand(), width: 100, headerClassName: 'th-center', className: 'td-center', Header: <span>{Assets.Action}</span>, Cell: row => <button onClick = {() => DbRoutes.wsBasicDelete (Config.amProcessedPayments, row.original.id)}>{Assets.Delete}</button>}
                                // {show: isUserGamanzaMagicHand(), width: 150, headerClassName: 'th-center', classname: 'td-center', Header: Assets.Action, Cell: (row) => <button style = {{marginTop: '1px'}} onClick = {() => setPaymentFileInquiry(row.original.id)} className = "btn btn-info btn-xs dbl fl mr0">{Assets.Set + Assets._as_ + Assets.Inquiry}</button>}
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            pageSizeOptions = {[5, 10, 20, 50, 100, 200, 500]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </div>
                    {Main.Instance.DatabaseDataState.selectedPaymentFileIndex !== undefined && Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex]?
                    <div style = {{width: '20%', float: 'left'/*, height: '200px', overflowX: 'scroll'*/}} className = "pr">
                        <button title = {Assets.Close + ' ' + (Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.status === PaymentProcessedStatus.Generated ? Assets.PaymentFilePreview : Assets.PaymentFileView)} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedPaymentFile: undefined, selectedPaymentFileIndex: undefined})} style = {{position: 'absolute', top: '0px', right: '3px', padding: '0px', background: 'transparent', border: '0px', marginRight: '0px'}}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                        <button title = {Assets.Download + ' ' + Assets_.XML} onClick = {() => download(date)} style = {{position: 'absolute', top: '0px', right: '18px', padding: '0px', background: 'transparent', border: '0px', marginRight: '0px'}}>
                            <FontAwesomeIcon icon = {faFileDownload}/>
                        </button>
                        <button title = {Assets.CopyContentToClipboard} onClick = {() => copyStringToClipboard(Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.xmlPaymentFile)} style = {{position: 'absolute', top: '0px', right: '35px', padding: '0px', background: 'transparent', border: '0px', marginRight: '0px'}}>
                            <FontAwesomeIcon icon = {faCopy}/>
                        </button>

                        <div className = "clearfix">
                            {Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.status === PaymentProcessedStatus.Generated ?
                            <>
                            <div className = "fl ml5px" style = {{fontWeight: 700}}>{Assets.PaymentFilePreview}</div>
                            <button disabled = {Main.Instance.DatabaseDataState.processButtonDisabled || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus !== TransactionStatusNoPending.APPROVED || !fullyLoaded(false)} title = {Assets.Process + ' ' + Assets_.XML + ' ' + Assets.PaymentFile} style = {{marginTop: '-4px'}} onClick = {() => processPaymentFile()} className = "btn btn-primary btn-xs dbl fl ml mr0">{Assets.Process}</button>
                            <button title = {Assets.Delete + ' ' + Assets_.XML + ' ' + Assets.PaymentFile} style = {{marginTop: '-4px'}} onClick = {() => deletePaymentFile(Main.Instance.DatabaseDataState.selectedPaymentFile)} className = "btn btn-danger btn-xs dbl fl ml mr0"><FontAwesomeIcon icon = {faTrashAlt}/></button>
                            </>
                            :
                            <div className = "fl ml5px" style = {{fontWeight: 700}}>{Assets.PaymentFileView}</div>}
                            {/*<button disabled = {Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus !== TransactionStatusNoPending.APPROVED} style = {{marginTop: '-4px'}} onClick = {() => processPaymentFile()} className = "btn btn-success btn-xs dbl fl ml">{Assets.Process + ' ' + Assets.PaymentFile}</button>*/}
                        </div>
                        <p style = {{height: '323px', overflowY: 'scroll', fontFamily: 'Source Code Pro', whiteSpace: 'pre-wrap', padding: '5px', fontSize: '12px', background: '#eee', border: '1px solid #999'}}>
                            {isUserGDPR() ? Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.xmlPaymentFile : Assets.restrictedmessage}
                        </p>
                    </div> : void 0}
                </div> : void 0}
            </div>
        </div>
    )
}
/*
function getTimestampOptions(): JSX.Element[] {
    // console.log (Object.keys(TransactionStatus))
    const to: JSX.Element[] = []
    to.push(<option key = "0" value = "0">{Assets.Filter + Assets._by_ + Assets.Timestamp}</option>)
    for (let i: number = 0; i < Object.keys(TransactionStatus).length / 2; i++) {
        to.push(<option key = {i.toString()} value = {i.toString()}>{injectSpaces((Object.keys(TransactionStatus)[i + Object.keys(TransactionStatus).length / 2]))}</option>)

    }
    return to
}
*/

function isAnyWithdrawalRequestActive(status: TransactionStatusNoPending): boolean {
    return (
        status === Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus
        || Main.Instance.DatabaseDataState.WithdrawalRequest1
        || Main.Instance.DatabaseDataState.WithdrawalRequest2
        || Main.Instance.DatabaseDataState.WithdrawalRequest1_
        || Main.Instance.DatabaseDataState.WithdrawalRequest2_
    )
}

function getPlayerIdOptions(w: WithdrawalRequest[]): JSX.Element[] {
    if (w === undefined || w === null) {return []}
    const pio: JSX.Element[] = []
    const p: string[] = []
    for (const item of w) {
        let found: boolean = false
        for (const Item of p) {
            if (item.playerId === Item) {
                found = true
                break
            }
        }
        if (!found) {p.push(item.playerId)}
    }
    p.sort()
    pio.push(<option key = "0" value = "0">{Assets.FilterByPlayer}</option>)
    for (const item of p) {
        pio.push(<option key = {item} value = {item}>{item}</option>)
    }
    return pio
}
/*
function updateBIC(index: number, bic: string) {
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    // console.log (index, bic)
    if (withdrawalRequest1[index].bic && bic.length === 0) {
        // console.log (1)
        delete(withdrawalRequest1[index].bic)
    } else {
        // console.log (2)
        withdrawalRequest1[index].bic = bic
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1})
}
*/
function setPaymentFileCompleted(id: number) {
    const paymentProcessed: PaymentProcessed[] = Object.assign([], Main.Instance.DatabaseDataState.paymentProcessed)
    let index: number = -1
    for (let i: number = 0; i < paymentProcessed.length; i++) {
        if (paymentProcessed[i].id === id) {
            index = i
            paymentProcessed[i].data.status = PaymentProcessedStatus.Completed
            paymentProcessed[i].data.timestampCompleted = (new Date()).toISOString().replace('T', ' ').substring(0,19)
            break
        }
    }
    if (index > -1) {
        DbRoutes.wsBasicUpdate(Config.amProcessedPayments, paymentProcessed[index].id, paymentProcessed[index].data)
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {paymentProcessed})
}
/*
function setPaymentFileInquiry(id: number) {
    const paymentProcessed: PaymentProcessed[] = Object.assign([], Main.Instance.DatabaseDataState.paymentProcessed)
    let index: number = -1
    for (let i: number = 0; i < paymentProcessed.length; i++) {
        if (paymentProcessed[i].id === id) {
            index = i
            paymentProcessed[i].data.status = PaymentProcessedStatus.Inquiry
            paymentProcessed[i].data.timestampCompleted = (new Date()).toISOString().replace('T', ' ').substring(0,19)
            break
        }
    }
    if (index > -1) {
        DbRoutes.wsBasicUpdate(Config.amProcessedPayments, paymentProcessed[index].id, paymentProcessed[index].data)
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {paymentProcessed})
}
*/

function changeExecutedStatusTo(newStatus: TransactionStatus, id: number) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const transactionIds: number[] = []
    for (const item of withdrawalRequest2) {
        if (item.transactionId === id && item.status === TransactionStatus.EXECUTED) {
            item.status = newStatus
            transactionIds.push (item.transactionId)
            // item.timestampCompletedDeniedByBank = (new Date()).toISOString().replace('T', ' ').substring(0,19)
            break
        }
    }
    DbRoutes.changeWithdrawalStatus(newStatus, transactionIds, Main.Instance.LoginState.id)
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}

function ChangeStatusTo2(newStatus: TransactionStatus) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const transactionIds: number[] = []
    for (const item of withdrawalRequest2) {
        if (item.changeStatus && item.status === TransactionStatus.EXECUTED) {
            item.status = newStatus
            transactionIds.push (item.transactionId)
            // item.timestampCompletedDeniedByBank = (new Date()).toISOString().replace('T', ' ').substring(0,19)
        }
    }
    DbRoutes.changeWithdrawalStatus(newStatus, transactionIds, Main.Instance.LoginState.id)
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}
/*
function copyClipboard() {
    copyStringToClipboard(Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].xmlPaymentFile)
}
*/
function selectXML(id: number) {
    // console.log (paymentProcessed)
    let index: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.paymentProcessed.length; i++) {
        if (Main.Instance.DatabaseDataState.paymentProcessed[i].id === (id ? id : Main.Instance.DatabaseDataState.selectedPaymentFile)) {
            index = i
            break
        }
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {selectedPaymentFileIndex: index, selectedPaymentFile: id})
}

function deletePaymentFile(id: number) {
    // console.log (Main.Instance.DatabaseDataState.selectedPaymentFile)
    if (id > 0) {
        DbRoutes.wsBasicDelete(Config.amProcessedPayments, id)
        revertPaymentFile()
    }
}

function transactionIdExists(transactionId: number, TransactionIds: number[]): boolean {
    for(const item of TransactionIds) {
        if (item === transactionId) {
            return true
        }
    }

    return false
}
// Main.Instance.DatabaseDataState.processButtonDisabled ||
function processPaymentFile(id?: number) {
    Logic.Type.New (Main.Instance.DatabaseDataState, {processButtonDisabled: true})
    let index: number = -1
    let TransactionIds: number[]
    for (let i: number = Main.Instance.DatabaseDataState.paymentProcessed.length - 1; i > -1; i--) {
        if (Main.Instance.DatabaseDataState.paymentProcessed[i].id === (id ? id : Main.Instance.DatabaseDataState.selectedPaymentFile)) {
            index = i
            TransactionIds = Main.Instance.DatabaseDataState.paymentProcessed[i].data.transactionIds
            break
        }
    }
    if (index === -1) {
        alert(Assets.CouldNotFindThePaymentsFile)
        return
    }

    const paymentProcessed: PaymentProcessed[] = Object.assign([], Main.Instance.DatabaseDataState.paymentProcessed)
    paymentProcessed[index].data.timestampPending = (new Date()).toISOString().replace('T', ' ').substring(0,19)
    paymentProcessed[index].data.status = PaymentProcessedStatus.Pending
    // DbRoutes.wsBasicUpdate(Config.amProcessedPayments, paymentProcessed[index].id, paymentProcessed[index].data)

    // change withdrawal status of transactions in pending payment file
    const transactionIds: number[] = []
    const WithdrawalRequest2: WithdrawalRequest[] = []
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (let i: number = 0; i < withdrawalRequest2.length; i++) {
        if (withdrawalRequest2[i].status === TransactionStatus.APPROVED && (withdrawalRequest2[i].markedForPayment || transactionIdExists(withdrawalRequest2[i].transactionId, TransactionIds))) {
            transactionIds.push (withdrawalRequest2[i].transactionId)
        } else {
            WithdrawalRequest2.push(withdrawalRequest2[i])
        }
    }

    // console.log(transactionIds)
    if (transactionIds.length > 0) {
        // console.log(transactionIds)
        DbRoutes.changeWithdrawalStatus(TransactionStatus.EXECUTED, transactionIds, Main.Instance.LoginState.id)
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {
        selectedPaymentFileIndex: undefined,
        selectedPaymentFile: undefined,
        paymentProcessed,
        withdrawalRequest2: WithdrawalRequest2,
        withdrawalRequest2Count: WithdrawalRequest2.length,
        paymentProcessedIndex: index,
        WithdrawalStatusChange: true,
        WithdrawalStatusChangeExecuted: true,
        processButtonDisabled: false
    })
}

function revertPaymentFile() {
    // const transactionIds: number[] = []
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (const item of withdrawalRequest2) {
        if (item.status === TransactionStatus.APPROVED && item.markedForPayment) {
            // transactionIds.push (item.transactionId)
            // item.status = TransactionStatus.EXECUTED
            item.markedForPayment = undefined
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}

function Download(which: number) {
    // which 1, pending
    // which 2, withdrawal requests // filterable
    // which 3, processed payments // filterable
    let s: string = ''
    if (which === 1) {
        // if (Main.Instance.DatabaseDataState.withdrawalRequest1 === undefined) {return}
        s += Assets.Transaction + Assets.ID + ',' + Assets.First + '/' + Assets.LastName + ',' + Assets.BeneficiaryName /*+ ',' + Assets.FraudAlerts + */ + ',' + Assets.Requested + ' ' + Assets.Timestamp + ' ' + Assets_.UTC + ',' + Assets.Amount + ',' + Assets.Currency + ',' + Assets_.IBAN + ' ' + Assets.Validity + ',' + Assets_.IBAN + ',' + Assets_.BIC + ' (' + Assets_.SWIFT + ')' + '\n'
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest1) {

            if (Main.Instance.DatabaseDataState.wfPlayerId1 && Main.Instance.DatabaseDataState.wfPlayerId1 !== '0') {
                if (item.playerId !== Main.Instance.DatabaseDataState.wfPlayerId1) {continue}
            } else if (Main.Instance.DatabaseDataState.wfPlayerIdSearch1.length > 0) {
                if (item.playerId !== Main.Instance.DatabaseDataState.wfPlayerIdSearch1) {continue}
            }
            s += item.transactionId + ',' + item.firstName + ' ' + item.lastName + ',' + item.beneficiaryName + /*',' + '-' + */',' + GetDate(item.created) + ',' + item.amount + ',' + item.currency + ',' + IsIbanValid(item.iban ? item.iban.toUpperCase() : '') + ',' + (item.iban ? item.iban.toUpperCase() : '') + ',' + (item.bic ? item.bic.toUpperCase() : item.iban ? bfi.getBIC((item.iban.toUpperCase()).replace(/ /g,'')).toUpperCase() : '') + '\n'
        }
    } else if (which === 2) {
        // if (Main.Instance.DatabaseDataState.withdrawalRequest2 === undefined) {return}
        s += Assets.Transaction + Assets.ID + ',' + Assets.BeneficiaryName /*+ ',' + Assets.Requested + ' ' + Assets.Timestamp */+ ',' + Assets.Amount + ',' + Assets.Currency + ',' + Assets_.IBAN + ' ' + Assets.Validity + ',' + Assets_.IBAN + ',' + Assets_.BIC + ' (' + Assets_.SWIFT + ')' /*+ ',' + Assets.Operator /*+ ',' + (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved ? Assets.Approved : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied ? Assets.Rejected : Assets.Approved + '/' + Assets.Rejected) + ' ' + Assets.Timestamp + (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCompleted || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDeniedByBank || !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied ? ',' + Assets.Executed + ' ' + Assets.Timestamp : '') + (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCompleted ? ',' + Assets.Completed + ' ' + Assets.Timestamp : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDeniedByBank ? ',' + Assets.DeniedByBank + ' ' + Assets.Timestamp : !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted ? ',' + Assets.Completed + '/' + Assets.DeniedByBank + ' ' + Assets.Timestamp : '') */+ ',' + Assets.Status + '\n'
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest2) {
            if (
                Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && item.status !== TransactionStatus.APPROVED
                || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED && item.status !== TransactionStatus.REJECTED
                || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED && item.status !== TransactionStatus.EXECUTED
                || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.COMPLETED && item.status !== TransactionStatus.COMPLETED
                || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.DENIEDBYBANK && item.status !== TransactionStatus.DENIEDBYBANK
            ) {continue}

            if (Main.Instance.DatabaseDataState.wfPlayerId && Main.Instance.DatabaseDataState.wfPlayerId !== '0') {
                if (item.playerId !== Main.Instance.DatabaseDataState.wfPlayerId) {continue}
            } else if (Main.Instance.DatabaseDataState.wfPlayerIdSearch.length > 0) {
                if (item.playerId !== Main.Instance.DatabaseDataState.wfPlayerIdSearch) {continue}
            }

            s += item.transactionId + ',' + item.beneficiaryName /*+ ',' + item.timestampRequested */+ ',' + item.amount + ',' + item.currency + ',' + IsIbanValid(item.iban) + ',' + item.iban.toUpperCase() + ',' + (item.bic ? item.bic.toUpperCase() : bfi.getBIC((item.iban.toUpperCase()).replace(/ /g,''))).toUpperCase() /*+',' + getRiskManager(item.operatorId) *//*+ ',' + item.timestampApprovedDenied + (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCompleted || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDeniedByBank || !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied ? ',' + (item.timestampExecutedCancelled ? item.timestampExecutedCancelled : '-') : '') + (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusCompleted || Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDeniedByBank || !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusApproved && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusDenied && !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusExecuted ? ',' + (item.timestampCompletedDeniedByBank ? item.timestampCompletedDeniedByBank : '-') : '') */+ ',' + TransactionStatusNoPending[item.status] + '\n'        
        }
    } else if (which === 3) {
        // if (Main.Instance.DatabaseDataState.paymentProcessed === undefined) {return}
        s += Assets.Payment + Assets.ID + ',' + Assets_.XML + ' ' + Assets.Size + ',' + Assets.DayInWeek + ',' + Assets.Date + ',' + Assets.Payment + ' ' + Assets.Number + Assets._on_ + Assets.Date + ',' + Assets.Operator + ',' + Assets.Total + ' ' + Assets.Payments + ',' + Assets.Transactions + ',' + Assets.TotalAmount + ',' + Assets.Currency + ',' + Assets.Generated + ' ' + Assets.Timestamp + (Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted || !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated ? ',' + Assets.Pending + ' ' + Assets.Timestamp : '') + (Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted || !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending ? ',' + Assets.Completed + ' ' + Assets.Timestamp : '') + ',' + Assets.Status + '\n' 
        for (const item of Main.Instance.DatabaseDataState.paymentProcessed) {
            if (
                Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && item.data.status !== PaymentProcessedStatus.Generated
                || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending && item.data.status !== PaymentProcessedStatus.Pending
                || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted && item.data.status !== PaymentProcessedStatus.Completed
                || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusInquiry && item.data.status !== PaymentProcessedStatus.Inquiry
            ) {continue}

            s += item.id + ',' + (((item.data.xmlPaymentFile ? item.data.xmlPaymentFile.length : 0) / 1024).toFixed(1) + ' ' + Assets_.kilobytesShort) + ',' + Assets.DaysInWeek[new Date(item.data.date.substring(6,10) + '-' + item.data.date.substring(3,5) + '-' + item.data.date.substring(0,2)).getDay()] + ',' + item.data.date + ',' + item.data.fileInDate + ',' + getRiskManager(item.data.operatorId) + ',' + item.data.totalPayments + ',' + (item.data.transactionIds ? JSON.stringify(item.data.transactionIds).replace('[','').replace(']','').replace(/,/g, '|').replace(/"/g, '') : '')  + ',' + item.data.totalAmount + ',' + item.data.currency + ',' + item.data.timestampGenerated + (Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending || Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted || !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated ? ',' + (item.data.timestampPending ? item.data.timestampPending : '-') : '') + (Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted || !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated && !Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending ? ',' + (item.data.timestampCompleted ? item.data.timestampCompleted : '-') : '') + ',' + injectSpaces(PaymentProcessedStatus[item.data.status]) + '\n'
        }
    } else if (which === 4) {
        s += Assets_.BIC + ' (' + Assets_.SWIFT + ')' + ',' + Assets.Code + '\n'
        for (const item of Main.Instance.DatabaseDataState.withdrawalRequest1) {
            if (IsIbanValid(item.iban) === true && item.iban !== undefined && item.iban !== '' && (bfi.getBIC((item.iban.toUpperCase()).replace(/ /g,'')) === '')) {
                s += item.iban.toUpperCase().replace(/ /g,'') + ',' + item.iban.toUpperCase().replace(/ /g,'').substring(4,9) + '\n'
            }
        } 
    }

    const filename: string = which === 4 ? Assets.Accounts + Assets._with_ + Assets.Missing.toLowerCase() + ' ' + Assets_.BIC + ' ' + Assets.Code.toLowerCase() : which === 1 ? Assets.Pending + ' ' + Assets.WithdrawalRequests : which === 2 ? (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED ? Assets.Approved + ' ' : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED ? Assets.Rejected + ' ' : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED ? Assets.Executed + ' ' : Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.COMPLETED ? Assets.Completed + ' ' : '') + Assets.WithdrawalRequests : which === 3 ? (Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusGenerated ? Assets.Generated + ' ' : Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusPending ? Assets.Pending + ' ' : Main.Instance.DatabaseDataState.onlyPaymentProcessedStatusCompleted ? Assets.Completed + ' ' : '') + Assets.Processed + ' ' + Assets.PaymentFiles : ''
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = filename + ' ' + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

function download(date: string) {
    const element = document.createElement('a')
    const file = new Blob([Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.xmlPaymentFile], {type: 'text/xml'})
    element.href = URL.createObjectURL(file)
    element.download = (Main.Instance.DatabaseDataState.paymentProcessed[Main.Instance.DatabaseDataState.selectedPaymentFileIndex].data.status === PaymentProcessedStatus.Generated ? Assets.PaymentFilePreview : Assets.PaymentFileView) + Assets._for_ + date.replace(':','-').replace(':','-').replace('T',' ') + Assets_._xml
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

function IsIbanValid(iban: string): boolean {
    return iban /*&& iban.length > 0*/ ? bfi.validateIBAN(iban.replace(/ /g,'')) : false
}

/*
function IsIbanValid (iban: string): boolean {
    return bfi.validateIBAN(iban.replace(/ /g,''))
}
*/

function isIbanValid(iban: string): JSX.Element {
    const validity: boolean = IsIbanValid(iban) 
    return <FontAwesomeIcon style = {{color: validity ? '#5cb85c' : '#d9534f'}} title = {validity ? Assets.IBANIsValid : Assets.IBANIsNotValid} icon = {validity ? faCheck : faTimes}/>
}

function isNameValid(firstName: string, lastName: string, benficiaryName: string): JSX.Element {
    const validity: boolean = ((firstName ? firstName.trim() : '') + ' ' + (lastName ? lastName.trim() : '')).toLowerCase() === (benficiaryName ? benficiaryName.toLowerCase().trim() : '')
    return <FontAwesomeIcon style = {{color: validity ? '#5cb85c' : '#d9534f'}} icon = {validity ? faCheck : faTimes}/>
}

function GenerateAPaymentFile(date: string) {
    // console.log (1)
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const withdrawalRequestPaymentFile: WithdrawalRequestPaymentFile[] = []
    // console.log (1)
    for (let i: number = 0; i < withdrawalRequest2.length; i++) {
        if (withdrawalRequest2[i].status === TransactionStatus.APPROVED && !withdrawalRequest2[i].markedForPayment && withdrawalRequest2[i].changeStatus) {
            withdrawalRequest2[i].markedForPayment = true
            withdrawalRequest2[i].changeStatus = undefined
            let found: number = -1
            for (let j: number = 0; j < withdrawalRequestPaymentFile.length; j++) {
                if (withdrawalRequestPaymentFile[j].data[0].bic ? withdrawalRequestPaymentFile[j].data[0].bic.toUpperCase() : (withdrawalRequestPaymentFile[j].data[0].iban.toUpperCase() ? bfi.getBIC((withdrawalRequestPaymentFile[j].data[0].iban.toUpperCase()).replace(/ /g,'')).toUpperCase() : Assets.Unknown) === withdrawalRequest2[i].bic ? withdrawalRequest2[i].bic.toUpperCase() : (withdrawalRequest2[i].iban ? bfi.getBIC((withdrawalRequest2[i].iban.toUpperCase()).replace(/ /g,'')).toUpperCase() : Assets.Unknown + ' ')) {
                    found = j
                    break
                }
            }
            if (found > -1) {
                withdrawalRequestPaymentFile[found].data.push(JSON.parse(JSON.stringify(withdrawalRequest2[i])))
            } else {
                withdrawalRequestPaymentFile.push({data:[JSON.parse(JSON.stringify(withdrawalRequest2[i]))]})
            }
            
        } else {
            withdrawalRequest2[i].changeStatus = undefined
        }
    }
    // console.log (2)
    // here we would ideally test xml for any errors, but otherwise we would like to insert a new row into payments fiel
    const paymentProcessed: PaymentProcessed[] = Object.assign ([], Main.Instance.DatabaseDataState.paymentProcessed)

    let nboftxs: number = 0
    let controlSum: number = 0
    const transactionIds: number[] = []
    for (const item of withdrawalRequestPaymentFile) {
        for (const Item of item.data) {
            controlSum += +Item.amount
            nboftxs++
            transactionIds.push(Item.transactionId)
        }
    }
    // console.log (controlSum, nboftxs, transactionIds.length)
    const xmlPaymentFile: string = getPaymentsFileXML(withdrawalRequestPaymentFile, date, nboftxs, controlSum)
    // console.log (xmlPaymentFile)
    // let files: number = 0
    let fileInDate: number = 0
    for (const item of paymentProcessed) {
        // files++
        if (item.data.date === date.substring(0, 10)) {
            fileInDate = item.data.fileInDate
        }
    }
    fileInDate++
    // console.log (fileInDate)
    // console.log (transactionIds)
    const data = { 
        operatorId: Main.Instance.LoginState.id,
        date: date.substring(0, 10),
        status: PaymentProcessedStatus.Generated,
        totalAmount: controlSum,
        totalPayments: nboftxs,
        fileInDate,
        timestampGenerated: (new Date()).toISOString().replace('T', ' ').substring(0,19),
        // date.substring(6,10) + '-' + date.substring(3,5) + '-' + date.substring(0,2) + ' ' + date.substring(11,19),
        currency: withdrawalRequestPaymentFile[0].data[0].currency,
        xmlPaymentFile,
        transactionIds
    }
    // console.log (data)
    // Logic.Type.New (Main.Instance.DatabaseDataState, {PaymentProcessedInsert: true})
    // console.log (data)
    DbRoutes.wsBasicInsert(Config.amProcessedPayments, data)

    // now we need to cut all this transactionIds from WithdrawalRequest2 arrray

    Logic.Type.New (Main.Instance.DatabaseDataState, {
        // paymentProcessed,
        onlyPaymentProcessedStatusGenerated: true,
        onlyPaymentProcessedStatusAll: false,
        onlyPaymentProcessedStatusPending: false,
        onlyPaymentProcessedStatusCompleted: false,
        withdrawalRequest2,
        withdrawalRequestPaymentFile,
        // selectedPaymentFile: files + 1,
        // selectedPaymentFileIndex: files
        // xmlPaymentFile
    })
}

function Tab(numberOfTabs: number): string {
    let x: string = ''
    for (let i: number = 0; i < numberOfTabs; i++) {
        // x += '\t'
        x += '  '
    }
    return x
}
/*
function getOrganization(long: boolean): string {

    return (
        Main.Instance.LoginState.entity === 'jackpots.ch' ? long ? Assets_.GCBLong : Assets_.GCB :
        long ? Assets.Unknown : Assets.Unknown.charAt(1)
    )
}

function getPostalAddress(country: boolean): string {
    return (
        Main.Instance.LoginState.entity === 'jackpots.ch' ? country ? Assets_.GCBCountry : Assets_.GCBAddress :
        country ? Assets.Unknown.charAt(2) : Assets.Unknown
    )
}

function getBankInfo(swift: boolean): string {
    return (
        Main.Instance.LoginState.entity === 'jackpots.ch' ? swift ? bfi.getBIC((Assets_.GCBIBAN).replace(/ /g,'')).toUpperCase() : Assets_.GCBIBAN :
        swift ? 'SWIFT' : 'IBAN'
    )
}
*/
function get4DigitsID(i: number): string {
    return i < 10 ? '000' + i : i < 100 ? '00' + i : i < 10 ? '0' + i : i.toString()
}

function get3DigitsID(i: number): string {
    return i < 10 ? '00' + i : i < 100 ? '0' + i : i.toString()
}

function get2DigitsID(i: number): string {
    return i < 10 ? '0' + i : i.toString()
}

function GetPaymentsFileXML(w: WithdrawalRequestPaymentFile[], date: string): string {
    let x: string = ''
    let counter: number = 0
    for (let i: number = 0; i < w.length; i++) {
        x += Tab(2) + '<PmtInf>' + '\n'
        x += Tab(3) + '<PmtInfId>' + 'PMTINF-' + get4DigitsID(i + 1) + '</PmtInfId>' + '\n'
        x += Tab(3) + '<PmtMtd>' + 'TRF' + '</PmtMtd>' + '\n'
        x += Tab(3) + '<BtchBookg>' + 'true' + '</BtchBookg>' + '\n'
        x += Tab(3) + '<ReqdExctnDt>' + date.substring(6,10) + '-' + date.substring(3,5) + '-' + date.substring(0,2) + '</ReqdExctnDt>' + '\n'
        x += Tab(3) + '<Dbtr>' + '\n'  
        x += Tab(4) + '<Nm>' + Main.Instance.DatabaseDataState.custom.paymentInformation.beneficiary + '</Nm>' + '\n'  
        x += Tab(4) + '<PstlAdr>'+ '\n'
/*
        x += Tab(5) + '<StrtNm>' + Main.Instance.DatabaseDataState.custom.paymentInformation.address + '</StrtNm>' +'\n'
        x += Tab(5) + '<BldgNb>' + Main.Instance.DatabaseDataState.custom.paymentInformation.streetNumber + '</BldgNb>' +'\n'
        x += Tab(5) + '<PstCd>' + Main.Instance.DatabaseDataState.custom.paymentInformation.postalCode + '</PstCd>' +'\n'
        x += Tab(5) + '<TwnNm>' + Main.Instance.DatabaseDataState.custom.paymentInformation.city + '</TwnNm>' +'\n'
        x += Tab(5) + '<Ctry>' + Main.Instance.DatabaseDataState.custom.paymentInformation.countryAbbreviation + '</Ctry>' + '\n'
*/
        x += Tab(5) + '<AdrLine>' + Main.Instance.DatabaseDataState.custom.paymentInformation.city + '</AdrLine>' + '\n'

        x += Tab(4) + '</PstlAdr>' + '\n'
        x += Tab(3) + '</Dbtr>' + '\n'  
        x += Tab(3) + '<DbtrAcct>' + '\n'
        x += Tab(4) + '<Id>' + '\n'
        x += Tab(5) + '<IBAN>' + (Main.Instance.DatabaseDataState.custom.paymentInformation.iban).replace(/ /g,'') + '</IBAN>' + '\n'
        x += Tab(4) + '</Id>' + '\n'
        x += Tab(3) + '</DbtrAcct>' + '\n'
        x += Tab(3) + '<DbtrAgt>' + '\n'
        x += Tab(4) + '<FinInstnId>' + '\n'
        x += Tab(5) + '<BIC>' + Main.Instance.DatabaseDataState.custom.paymentInformation.bic.toUpperCase() + '</BIC>' + '\n'
        x += Tab(4) + '</FinInstnId>' + '\n'
        x += Tab(3) + '</DbtrAgt>' + '\n'
        for (let j: number = 0; j < w[i].data.length; j++) {
            if (w[i].data[j].iban === null || w[i].data[j].iban === undefined) {continue}
            counter++
            x += Tab(3) + '<CdtTrfTxInf>' + '\n'
            x += Tab(4) + '<PmtId>' + '\n'
            x += Tab(5) + '<InstrId>' + 'INSTRID-' + get3DigitsID(i + 1) + '-' + get2DigitsID(j + 1) + '</InstrId>' + '\n'
            x += Tab(5) + '<EndToEndId>' + 'ENDTOENDID-' + get4DigitsID(counter) + '</EndToEndId>' + '\n'
            x += Tab(4) + '</PmtId>' + '\n'
            x += Tab(4) + '<Amt>' + '\n'
            x += Tab(5) + '<InstdAmt Ccy="' + w[i].data[j].currency + '">' + w[i].data[j].amount + '</InstdAmt>' + '\n'
            x += Tab(4) + '</Amt>' + '\n'
            x += Tab(4) + '<CdtrAgt>' + '\n'
            x += Tab(5) + '<FinInstnId>' + '\n'
            x += Tab(6) + '<BIC>' + (w[i].data[j].bic ? w[i].data[j].bic.toUpperCase() : (w[i].data[j].iban ? bfi.getBIC(w[i].data[j].iban.replace(/ /g, '')) : '')).toUpperCase()  + '</BIC>' + '\n'
            x += Tab(5) + '</FinInstnId>' + '\n'
            x += Tab(4) + '</CdtrAgt>' + '\n'
            x += Tab(4) + '<Cdtr>' + '\n'
            x += Tab(5) + '<Nm>' + w[i].data[j].beneficiaryName + '</Nm>' +'\n'
            x += Tab(5) + '<PstlAdr>' + '\n'
/*
            x += Tab(6) + '<AdrLine>' + w[i].data[j].address + ' ' + w[i].data[j].streetNumber + '</AdrLine>' +'\n'
            x += Tab(6) + '<AdrLine>' + w[i].data[j].countryAbbreviation + '-' + w[i].data[j].postalCode + ' ' + w[i].data[j].city + '</AdrLine>' +'\n'
*/

            if (w[i].data[j].address) {x += Tab(6) + '<StrtNm>' + w[i].data[j].address + '</StrtNm>' +'\n'}
            if (w[i].data[j].streetNumber) {x += Tab(6) + '<BldgNb>' + w[i].data[j].streetNumber + '</BldgNb>' +'\n'}
            x += Tab(6) + '<PstCd>' + w[i].data[j].postalCode + '</PstCd>' +'\n'
            x += Tab(6) + '<TwnNm>' + w[i].data[j].city + '</TwnNm>' +'\n'
            x += Tab(6) + '<Ctry>' + w[i].data[j].countryAbbreviation + '</Ctry>' +'\n'

            x += Tab(5) + '</PstlAdr>' + '\n'
            x += Tab(4) + '</Cdtr>' + '\n'
            x += Tab(4) + '<CdtrAcct>' + '\n'
            x += Tab(5) + '<Id>' + '\n'
            x += Tab(6) + '<IBAN>' + (w[i].data[j].iban.replace(/ /g, '').toUpperCase()) + '</IBAN>' + '\n'
            x += Tab(5) + '</Id>' + '\n'
            x += Tab(4) + '</CdtrAcct>' + '\n'
            x += Tab(3) + '</CdtTrfTxInf>' + '\n'
        }
        x += Tab(2) + '</PmtInf>' + '\n'
    }
    return x
}

function getPaymentsFileXML(w: WithdrawalRequestPaymentFile[], date: string, nboftxs: number, controlSum: number): string {
    let x: string = ''
    x += '<Document xmlns="http://www.six-interbank-clearing.com/de/pain.001.001.03.ch.02.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.six-interbank-clearing.com/de/pain.001.001.03.ch.02.xsd  pain.001.001.03.ch.02.xsd">' + '\n'
    x += Tab(1) + '<CstmrCdtTrfInitn>' + '\n'
    x += Tab(2) + '<GrpHdr>' + '\n'
    x += Tab(3) + '<MsgId>' + Main.Instance.DatabaseDataState.custom.paymentInformation.abbreviation + date.substring(0,2) + date.substring(3,5) + date.substring(6,10) + '</MsgId>' + '\n'
    x += Tab(3) + '<CreDtTm>' + date.substring(6,10) + '-' + date.substring(3,5) + '-' + date.substring(0,2) + date.substring(10,19) + '</CreDtTm>' + '\n'
    x += Tab(3) + '<NbOfTxs>' + nboftxs + '</NbOfTxs>' + '\n'
    x += Tab(3) + '<CtrlSum>' + controlSum.toFixed(2) + '</CtrlSum>' + '\n'
    x += Tab(3) + '<InitgPty>' + '\n'
    x += Tab(4) + '<Nm>' + Main.Instance.DatabaseDataState.custom.paymentInformation.beneficiary + '</Nm>' + '\n'
    x += Tab(3) + '</InitgPty>' + '\n'
    x += Tab(2) + '</GrpHdr>' + '\n'
    x += GetPaymentsFileXML(w, date)
    x += Tab(1) + '</CstmrCdtTrfInitn>' + '\n'
    x += '</Document>'
    return x
}

function atLeastOneSelected(withdrawalRequest: WithdrawalRequest[], status: TransactionStatus): boolean {
    if (withdrawalRequest) {
        for (const item of withdrawalRequest) {
            if (item.changeStatus && item.status === status) {
                return true
            }
        }
    }
    return false
}

function doesBicExist(bic: string, iban: string): boolean {
    // console.log (bic, iban)
    if (iban) {console.log (bfi.getBIC((iban.toUpperCase()).replace(/ /g,'')))}
    if (bic && bic.length > 0 || iban && bfi.getBIC((iban.toUpperCase()).replace(/ /g,'')).length >= 8) {return true}
    return false
}

// RejectWithdrawals faCheckSquare
function selectAllPending(/*newStatus: TransactionStatus*/) {
    // console.log (Main.Instance.DatabaseDataState.withdrawalRequest1)
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    // console.log (withdrawalRequest1.length)
    for (const item of withdrawalRequest1) {
        if (item.status === TransactionStatus.PENDING && (/*Main.Instance.DatabaseDataState.rejectWithdrawals*/Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED || IsIbanValid(item.iban) && doesBicExist(item.bic, item.iban))) {
            item.changeStatus = true
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1})
}

function deselectAllPending(/*newStatus: TransactionStatus*/) {
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    for (const item of withdrawalRequest1) {
        if (item.status === TransactionStatus.PENDING && item.changeStatus) {
            delete(item.changeStatus)
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1})
}

function selectAll2(newStatus: TransactionStatus) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (const item of withdrawalRequest2) {
        if (item.status === newStatus) {
            item.changeStatus = true
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}

function deselectAll2(newStatus: TransactionStatus) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (const item of withdrawalRequest2) {
        if (item.status === newStatus && item.changeStatus) {
            delete(item.changeStatus)
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}

function SelectAll(newStatus: TransactionStatus, newStatus2?: TransactionStatus) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (const item of withdrawalRequest2) {
        if (item.status === newStatus || newStatus2 && item.status === newStatus2) {
            item.changeStatus = true
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}

function DeselectAll(newStatus: TransactionStatus, newStatus2?: TransactionStatus) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    for (const item of withdrawalRequest2) {
        if ((item.status === newStatus || newStatus2 && item.status === newStatus2) && item.changeStatus) {delete(item.changeStatus)}
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
}
/*
function Execute(transactionId: number) {
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    let foundItemIndex: number = -1
    for(let i: number = 0; i < withdrawalRequest2.length; i++) {
        if (withdrawalRequest2[i].transactionId === transactionId && withdrawalRequest2[i].status === TransactionStatus.APPROVED) {
            withdrawalRequest2[i].status = TransactionStatus.EXECUTED
            foundItemIndex = i
            break
        }
    }
    if (foundItemIndex !== -1) {
        DbRoutes.changeWithdrawalStatus(TransactionStatus.EXECUTED, [transactionId], Main.Instance.LoginState.id)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
    }
}
*/
function changeStatusTo(newStatus: TransactionStatus) {
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const transactionIds: number[] = []
    let count: number = 0
    let Count: number = 0
    for (let i: number = 0; i < withdrawalRequest1.length; ) {
        if (withdrawalRequest1[i].changeStatus) {
            withdrawalRequest1[i].status = newStatus
            withdrawalRequest1[i].changeStatus = undefined
            transactionIds.push (withdrawalRequest1[i].transactionId)
            if (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === newStatus as unknown as TransactionStatusNoPending) {
                count++
                withdrawalRequest2.push(withdrawalRequest1[i])
            }
            Count++
            withdrawalRequest1.splice(i, 1)
        } else {
            i++
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        withdrawalRequest1,
        withdrawalRequest2,
        withdrawalRequest1Count: Main.Instance.DatabaseDataState.withdrawalRequest1Count - Count,
        withdrawalRequest2Count: Main.Instance.DatabaseDataState.withdrawalRequest2Count + count,
    })
    if (transactionIds.length > 0) {
        // console.log(transactionIds)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1, withdrawalRequest2, WithdrawalStatusChange: true})
        DbRoutes.changeWithdrawalStatus(newStatus, transactionIds, Main.Instance.LoginState.id)
    }
}


function ChangeStatusTo(newStatus: TransactionStatus) {
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const transactionIds: number[] = []
    let count: number = 0
    for (let i: number = 0; i < withdrawalRequest2.length;) {
        if (withdrawalRequest2[i].changeStatus) {
            withdrawalRequest2[i].status = newStatus
            withdrawalRequest2[i].changeStatus = undefined
            transactionIds.push (withdrawalRequest2[i].transactionId)
            count++
            withdrawalRequest1.push(withdrawalRequest2[i])
            withdrawalRequest2.splice(i, 1)
        } else {
            i++
        }
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {
        withdrawalRequest1,
        withdrawalRequest2,
        withdrawalRequest1Count: Main.Instance.DatabaseDataState.withdrawalRequest1Count + count,
        withdrawalRequest2Count: Main.Instance.DatabaseDataState.withdrawalRequest2Count - count,
    })

    if (transactionIds.length > 0) {
        DbRoutes.changeWithdrawalStatus(newStatus, transactionIds, Main.Instance.LoginState.id)
    }
}

function rejectWithdrawal(transactionId: number, Index: number) {
    // console.log (transactionId)
    // change status to rejected
    if (confirm('Are you sure, that you would like to reject the withdrawal with transaction ID ' + transactionId)) {
        changeStatusToRejected(transactionId)
    }
}

function changeStatusToRejected(transactionId: number) {
    const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    // const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    const transactionIds: number[] = []
    // let count: number = 0
    let Count: number = 0

    for (let i: number = 0; i < withdrawalRequest1.length; i++) {
        if (withdrawalRequest1[i].transactionId === transactionId) {
            withdrawalRequest1[i].status = TransactionStatus.REJECTED
            // withdrawalRequest1[i].changeStatus = undefined
            transactionIds.push (withdrawalRequest1[i].transactionId)
/*
            if (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === newStatus as unknown as TransactionStatusNoPending) {
                count++
                withdrawalRequest2.push(withdrawalRequest1[i])
            }
*/
            Count++
            withdrawalRequest1.splice(i, 1)
            break
        } /* else {
            i++
        }*/
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        withdrawalRequest1,
        // withdrawalRequest2,
        withdrawalRequest1Count: Main.Instance.DatabaseDataState.withdrawalRequest1Count - Count,
        // withdrawalRequest2Count: Main.Instance.DatabaseDataState.withdrawalRequest2Count + count,
    })
    if (transactionIds.length > 0) {
        // console.log(transactionIds)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1, /*withdrawalRequest2,*/ WithdrawalStatusChange: true})
        DbRoutes.changeWithdrawalStatus(TransactionStatus.REJECTED, transactionIds, Main.Instance.LoginState.id)
    }
}

function getChangeStatus(transactionId: number, pending: boolean, changeStatus: boolean, index: number, iban?: string, swift?: string, page?: number): JSX.Element {
    // console.log (row)
    // console.log (transactionId)
    const bic: string = swift ? swift.trim() : iban ? bfi.getBIC((iban).replace(/ /g,'')).toUpperCase() : undefined
    return (
        pending && Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && (iban === undefined || iban === null || bic === undefined || bic === null || !(bic.length === 8 || bic.length === 11)) ?
        <button style = {{marginLeft: '-3px', marginRight: '3px', marginTop: '-4px', padding: '0px 4px', lineHeight: 1.4}} title = "Reject withdrawal" type = "button" onClick = {() => rejectWithdrawal(transactionId, index)} className = "btn btn-danger btn-xs mr0"><FontAwesomeIcon icon = {faTimes}/></button>
        : <input disabled = {/*!Main.Instance.DatabaseDataState.rejectWithdrawals*/pending && !(Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED) && (iban === undefined || iban === null || bic === undefined || bic === null || !(bic.length === 8 || bic.length === 11))} type = "checkbox" checked = {changeStatus ? changeStatus : false} onChange = {() => toggleChangeStatus(pending, index, page)}/>
    )
}

function toggleChangeStatus(pending: boolean, index: number, page?: number) {
    if (pending) {
        const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
        withdrawalRequest1[index].changeStatus = !withdrawalRequest1[index].changeStatus
        // console.log(withdrawalRequest1)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1, withdrawalRequest1selectedPage: page})
    } else {
        const withdrawalRequest2: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
        withdrawalRequest2[index].changeStatus = !withdrawalRequest2[index].changeStatus
        // console.log(withdrawalRequest2)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2, withdrawalRequest2selectedPage: page})
    }
}

function getStatus(status: TransactionStatus, noMarginLeft?: boolean): JSX.Element {
// Pending, Approved, Denied, Executed, Completed, DeniedByBank
    // console.log (status)
    // console.log (TransactionStatus[status])
    return (
        <span
            style = {{marginLeft: noMarginLeft ? '0px' : '10px'}}
            className = {
                status === TransactionStatus.PENDING ? 'label label-warning label-sm' :
                status === TransactionStatus.APPROVED ? 'label label-primary label-sm' :
                status === TransactionStatus.REJECTED ? 'label label-danger label-sm' :
                status === TransactionStatus.EXECUTED ? 'label label-info label-sm' :
                status === TransactionStatus.COMPLETED ? 'label label-success label-sm' :
                status === TransactionStatus.DENIEDBYBANK ? 'label label-danger label-denied-by-bank label-sm' :
                status === TransactionStatus.CANCELED ? 'label label-danger label-cancelled label-sm' :
                'label label-default label-sm'}

        >
            {capitalizeFirstLetter(TransactionStatus[status].toLowerCase())}
        </span>
    )
}

function getPaymentStatus(status: PaymentProcessedStatus): JSX.Element {
    // Generated, Pending, Completed
        return (
            <span
                // style = {{marginLeft: '10px'}}
                className = {
                    status === PaymentProcessedStatus.Generated ? 'label label-danger label-sm' :
                    status === PaymentProcessedStatus.Pending ? 'label label-warning label-sm' :
                    status === PaymentProcessedStatus.Completed ? 'label label-success label-sm' :
                    status === PaymentProcessedStatus.Inquiry ? 'label label-info label-sm' :
                    'label label-default label-sm'}
    
            >
                {injectSpaces(PaymentProcessedStatus[status])}
            </span>
        )
    }

/*
{width: 50, headerClassName: 'th-left', className: 'td-left', Header: Assets.ID, accessor: 'data.statusType', Cell: row => <span className = "ml">{row.original.id}</span>},
{width: 200, headerClassName: 'th-left', className: 'td-left', Header: Assets.PlayerId, accessor: 'data.playerId', Cell: row => <span className = "ml">{row.value}</span>},
{width: 200, headerClassName: 'th-left', className: 'td-left', Header: Assets.Requested, accessor: 'data.timestampRequested', Cell: row => <span className = "ml">{row.value}</span>},
{width: 100, headerClassName: 'th-left', className: 'td-left', Header: Assets.Amount, accessor: 'data.amount', Cell: row => <span className = "ml">{row.value}</span>},
{width: 100, headerClassName: 'th-left', className: 'td-left', Header: Assets.Currency, accessor: 'data.currency', Cell: row => <span className = "ml">{row.value}</span>},
{width: 300, headerClassName: 'th-left', className: 'td-left', Header: Assets_.IBAN, accessor: 'data.iban', Cell: row => <span className = "ml">{row.value}</span>},
{width: 100, headerClassName: 'th-left', className: 'td-left', Header: Assets.Status, accessor: 'data.status', Cell: row => <span className = "ml">{row.value}</span>},
*/
function getDate(unix: number): string {
    const date: string = new Date(unix).toISOString()
    
    return (date.substring(0,10) + ' ' + date.substring(11,19))
}

export function GetDate0(unix: number): string {
    if (unix === undefined) { return '-'; }
    const date: string = new Date(unix * 1000).toISOString()
    return (date.substring(0,10) + ' ' + date.substring(11,19))
}

export function GetDate(unix: number): string {
    if (unix === 0) { return '-'; }
    const date: string = new Date(unix * 1000).toISOString()
    return (date.substring(0,10) + ' ' + date.substring(11,19))
}

function getRL(rl: RiskLevel): JSX.Element {
    return (
        <button
            style = {{width: '90px', lineHeight: 1.1}}
            className = {'mr0 btn btn-xs risk risk-' + RiskLevel[rl]}
        >
            {RiskLevel[rl]}
            <input
                style = {{cursor: 'default'}}
                disabled = {true} 
                type = "range"
                list = "test"
                min = {RiskLevel.Low}
                max = {RiskLevel.Severe}
                step = "1"
                value = {rl}
                className = "slider"
            />
        </button>
    )
}

function getGL(gl: Level) {
    return (
        <button
            style = {{width: '90px', lineHeight: 1.1, background: Assets_.jackpotschLevelColor[gl], color: Assets_.jackpotschLevelText[gl]}}
            className = {'mr0 btn btn-xs risk'}
            // className = {'mr0 btn btn-xs risk risk-' + Level[gl]}
        >
            {Level[gl]}
            <input
                style = {{cursor: 'default'}}
                disabled = {true} 
                type = "range"
                list = "test"
                min = {Level.Member}
                max = {Level.BlackCircle}
                step = "1"
                value = {gl}
                className = "slider"
            />
        </button>
    )
}

function getAS(as: AccountStatus): JSX.Element {
    return (
        <button
            title = {Assets.accountStatus[as]}
            style = {{cursor: 'default', width: '90px', lineHeight: 1.1}}
            className = {'mr0 btn btn-xs risk risk-' + AccountStatus[as]}
        >
            {Assets.accountStatus[as]}
            <input
                style = {{cursor: 'default'}}
                // disabled = {!canBeModifiedByAdmin('accountStatus')}
                // onChange = {(e) => changeNumber('accountStatus', e.target.value)}
                disabled = {true}
                onChange = {(e) => {}}
                type = "range"
                list = "test"
                min = {AccountStatus.Initial}
                max = {AccountStatus.ClosedActive}
                step = "1"
                value = {as}
                className = "slider"
            />
        </button>
    )
}

function ShowTransactionHistory(transactionId: number/*, pending?: boolean*/) {
    DbRoutes.getTransactionHistory(transactionId/*, pending*/)
}

function getTransactionHistory(history: IWithdrawalEvent[], pageSize: number): JSX.Element {
    return (
    <ReactTable
        data = {history}
        columns = {[
            {width: 100, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.Transaction}</span>, accessor: 'transactionId', Cell: row => <span>&nbsp;{row.value}</span>},
            {width: 105, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.Status}</span>, accessor: 'status', Cell: row => <span>&nbsp;{getStatus(row.value, true)}</span>},
            {width: 200, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.Operator}</span>, accessor: 'operatorId', Cell: row => <span>&nbsp;<FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManager(row.value)}</span>},
            {width: 160, headerClassName: 'th-left', className: 'td-left', Header: <span>&nbsp;{Assets.Created + ' ' + Assets.Timestamp.toLowerCase()}</span>, accessor: 'created', Cell: row => <span>&nbsp;{getDate(row.value)}</span>},
        ]}
        showPaginationTop = {true}
        showPaginationBottom = {false}
        pageSize={pageSize}
        className="-striped -highlight"
    />
    )
}

export function changeFilterWithdrawalRequestStatus(onlyWithdrawalRequestStatus: TransactionStatusNoPending) {
        Socket1.socket.Send(Config.walletGameWithdrawGet, {
            transactionStatus: onlyWithdrawalRequestStatus,
            page: 1,
            pageSize: isProduction() ? 50 : 10
            // pageSize: isProduction() ? undefined : 100
        })
        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'
        // rejectWithdrawals()
        const withdrawalRequest1: WithdrawalRequest[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
        for (const item of withdrawalRequest1) {
            item.changeStatus = undefined
        }
    
        // Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1})
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            withdrawalRequest1,
            onlyWithdrawalRequestStatus,
            WithdrawalRequest2: true,
            withdrawalRequest2Page: 1,
            withdrawalRequest2: [],
            withdrawalRequest2Count: 0,
            WithdrawalRequest2Time: Math.floor((new Date()).getTime()/1000)
        })
        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
        // Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'loading'
        // console.log (Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading)
    // }
}

function fullyLoaded(pending: boolean): boolean {
    return (pending ?
        Main.Instance.DatabaseDataState.withdrawalRequest1Count === Main.Instance.DatabaseDataState.withdrawalRequest1.length
        : Main.Instance.DatabaseDataState.withdrawalRequest2Count === Main.Instance.DatabaseDataState.withdrawalRequest2.length
    )
}

export function loadMore(pending: boolean, pendingapprovedWithdrawalRequests?: number[], waitingWithdrawalRequests?: number[], rejectedWithdrawalRequests?: number[], executedWithdrawalRequests?: number[]): JSX.Element {

    return (pending ?
        <>
        <strong>
            {Assets.Pending + ' ' + Assets.WithdrawalRequests}&nbsp;
            {/*[{(Main.Instance.DatabaseDataState.withdrawalRequest1 ? Main.Instance.DatabaseDataState.withdrawalRequest1.length === 1 ? '1 ' + Assets.Payment : Main.Instance.DatabaseDataState.withdrawalRequest1.length + ' ' + Assets.Withdrawal : '0 ' + Assets.Withdrawals) + ' ' + Assets.Waiting + Assets._for_ + Assets.Approval}]*/}
            {pendingapprovedWithdrawalRequests !== undefined ? '[' + (pendingapprovedWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : pendingapprovedWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Selected + ']' : void 0}&nbsp;
            [{Main.Instance.DatabaseDataState.withdrawalRequest1.length}/{Main.Instance.DatabaseDataState.withdrawalRequest1Count + ' ' + Assets.Loaded}]
        </strong>
        <button onClick = {() => LoadMore(true)} disabled = {fullyLoaded(true) || Main.Instance.DatabaseDataState.WithdrawalRequest1_ || Main.Instance.DatabaseDataState.WithdrawalRequest2_ || Main.Instance.DatabaseDataState.WithdrawalRequest2 || Main.Instance.DatabaseDataState.WithdrawalRequest1} className = "btn btn-warning btn-xs mr0 ml">{Assets.LoadMore}</button>
        <button onClick = {() => TryAgain(true)} disabled = {!(Main.Instance.DatabaseDataState.WithdrawalRequest1_ || Main.Instance.DatabaseDataState.WithdrawalRequest1) || Main.Instance.DatabaseDataState.WithdrawalRequest2 || Main.Instance.DatabaseDataState.WithdrawalRequest2_ || requestTooSoon(true)} className = "btn btn-danger btn-xs mr0 ml">{Assets.TryAgain}</button>
        <button disabled = {Main.Instance.DatabaseDataState.withdrawalRequest1 === undefined || Main.Instance.DatabaseDataState.withdrawalRequest1 === null || Main.Instance.DatabaseDataState.withdrawalRequest1.length === 0 || Main.Instance.Location.endsWith('withdrawal-duplicates') && (!isUserGDPR() && !isUserSupport() && !isUserAccounting() && !isUserOperatorRole())} title = {Assets.Download + ' ' + Assets.CSV} type = "button" className = "ml btn btn-default btn-sm mr0" style = {{background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px'}} onClick = {() => Download(1)}><FontAwesomeIcon icon = {faFileCsv}/></button>
        </>
        :
        <>
        <strong>
            {Assets.Processed + ' ' + Assets.WithdrawalRequests}&nbsp;
            
            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED &&
            (pendingapprovedWithdrawalRequests !== undefined || waitingWithdrawalRequests !== undefined) ?
            <>
            {'[' + (pendingapprovedWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : pendingapprovedWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Selected + ']'}&nbsp;
            {'[' + (waitingWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : waitingWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Waiting + Assets._for_ + Assets.Execution + ']'}
            </>
            : void 0}

            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED &&
            rejectedWithdrawalRequests !== undefined ?
            <>
            {'[' + (rejectedWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : rejectedWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Selected + ']'}&nbsp;
            </>
            : void 0}

            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED &&
            executedWithdrawalRequests !== undefined ?
            <>
            {'[' + (executedWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : executedWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Selected + ']'}&nbsp;
            </>
            : void 0}

            &nbsp;[{Main.Instance.DatabaseDataState.withdrawalRequest2.length}/{Main.Instance.DatabaseDataState.withdrawalRequest2Count + ' ' + Assets.Loaded}]
        </strong>
        <button onClick = {() => LoadMore(false)} disabled = {fullyLoaded(false) || Main.Instance.DatabaseDataState.WithdrawalRequest2_ || Main.Instance.DatabaseDataState.WithdrawalRequest1_|| Main.Instance.DatabaseDataState.WithdrawalRequest2 || Main.Instance.DatabaseDataState.WithdrawalRequest1} className = "btn btn-warning btn-xs mr0 ml">{Assets.LoadMore}</button>
        <button onClick = {() => TryAgain(false)} disabled = {!(Main.Instance.DatabaseDataState.WithdrawalRequest2_ || Main.Instance.DatabaseDataState.WithdrawalRequest2) || Main.Instance.DatabaseDataState.WithdrawalRequest1 || Main.Instance.DatabaseDataState.WithdrawalRequest1_ || requestTooSoon(false)} className = "btn btn-danger btn-xs mr0 ml">{Assets.TryAgain}</button>
        <button disabled = {!Main.Instance.DatabaseDataState.withdrawalRequest2 || Main.Instance.DatabaseDataState.withdrawalRequest2.length === 0|| Main.Instance.Location.endsWith('withdrawal-duplicates') && (!isUserGDPR() && !isUserSupport() && !isUserAccounting() && !isUserOperatorRole())} title = {Assets.Download + ' ' + Assets.CSV} type = "button" className = "ml btn btn-default btn-sm mr0" style = {{background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px'}} onClick = {() => Download(2)}><FontAwesomeIcon icon = {faFileCsv}/></button>
        </>
    )
}

function requestTooSoon(pending: boolean): boolean {
    // const time: number = Math.floor((new Date()).getTime()/1000)
    if (pending) {
        if (Main.Instance.DatabaseDataState.WithdrawalRequest1Time === 0/* || time - Main.Instance.DatabaseDataState.WithdrawalRequest1Time < tooSoonSeconds*/) {
            return true
        }

        return Main.Instance.DatabaseDataState.withdrawalRequest1Time
    } else {
        if (Main.Instance.DatabaseDataState.WithdrawalRequest2Time === 0/* || time - Main.Instance.DatabaseDataState.WithdrawalRequest2Time < tooSoonSeconds*/) {
            return true
        }

        return Main.Instance.DatabaseDataState.withdrawalRequest2Time
    }
}

export function TryAgain(pending: boolean) {
    if (pending) {
        Socket1.socket.Send(Config.walletGameWithdrawGet, {
            transactionStatus: TransactionStatus.PENDING,
            page: Main.Instance.DatabaseDataState.withdrawalRequest1Page,
            // page: Math.floor(Main.Instance.DatabaseDataState.withdrawalRequest1Count / 100) + 1,
            // page: Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1,
            pageSize: isProduction() ? 50 : 10
            // pageSize: isProduction() ? undefined : 100
        })
        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'

        // console.log ('LoadMore page ', Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            WithdrawalRequest1_: true,
            WithdrawalRequest1Time: Math.floor((new Date()).getTime() / 1000)
            // withdrawalRequest1_: [],
            // withdrawalRequest1Page: Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1
        })
        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
    } else {
        Socket1.socket.Send(Config.walletGameWithdrawGet, {
            transactionStatus: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus,
            page: Main.Instance.DatabaseDataState.withdrawalRequest2Page,
            // page: Math.floor(Main.Instance.DatabaseDataState.withdrawalRequest2Count / 100) + 1,
            // page: Main.Instance.DatabaseDataState.withdrawalRequest2Page + 1,
            pageSize: isProduction() ? 50 : 10
            // pageSize: isProduction() ? undefined : 100
        })
        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'

        Logic.Type.New(Main.Instance.DatabaseDataState, {
            WithdrawalRequest2Time: Math.floor((new Date()).getTime()/1000),
            WithdrawalRequest2_: true,
            // withdrawalRequest2_: [],
            // withdrawalRequest2Page: Main.Instance.DatabaseDataState.withdrawalRequest2Page + 1
        })
        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
    }
}


export function LoadMore(pending: boolean) {
    if (pending) {
        Socket1.socket.Send(Config.walletGameWithdrawGet, {
            transactionStatus: TransactionStatus.PENDING,
            page: Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1,
            pageSize: isProduction() ? 50 : 10
            // pageSize: isProduction() ? undefined : 100
        })
        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'

        // console.log ('LoadMore page ', Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            WithdrawalRequest1Time: Math.floor((new Date()).getTime()/1000),
            WithdrawalRequest1_: true,
            // withdrawalRequest1_: [],
            withdrawalRequest1Page: Main.Instance.DatabaseDataState.withdrawalRequest1Page + 1
        })
        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
    } else {  
        Socket1.socket.Send(Config.walletGameWithdrawGet, {
            transactionStatus: Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus,
            page: Main.Instance.DatabaseDataState.withdrawalRequest2Page + 1,
            pageSize: isProduction() ? 50 : 10
            // pageSize: isProduction() ? undefined : 100
        })
        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'

        Logic.Type.New(Main.Instance.DatabaseDataState, {
            WithdrawalRequest2Time: Math.floor((new Date()).getTime()/1000),
            WithdrawalRequest2_: true,
            // withdrawalRequest2_: [],
            withdrawalRequest2Page: Main.Instance.DatabaseDataState.withdrawalRequest2Page + 1
        })
        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
    }
}

export function getFilterOtherWithdrawals(showJustApproved?: boolean) {
    return (
    <div className = "clearfix">
        {/* <div style = {{marginLeft: '0px', marginRight: '10px'}} className = "fl">{Assets.FilterWithdrawalRequestStatus + ':'}</div>*/}
        {/*
        <input disabled = {true} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusAll} onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {onlyWithdrawalRequestStatusAll: !Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatusAll, onlyWithdrawalRequestStatusApproved: false, onlyWithdrawalRequestStatusDenied: false, onlyWithdrawalRequestStatusExecuted: false, onlyWithdrawalRequestStatusCompleted: false, onlyWithdrawalRequestStatusDeniedByBank: false, onlyWithdrawalRequestStatusCancelled: false})}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-default label-sm fl">{Assets.All}</div>
        */}
        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.APPROVED)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.APPROVED)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-primary label-sm fl">{Assets.Approved}</div>
        {showJustApproved ? void 0 :
        <>
        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.REJECTED)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.REJECTED)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-danger label-sm fl">{Assets.Rejected}</div>
{/*
        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.CANCELED)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.CANCELED} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.CANCELED)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-danger label-cancelled label-sm fl">{Assets.Cancelled}</div>
*/}
        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.EXECUTED)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.EXECUTED)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-info label-sm fl">{Assets.Executed}</div>

        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.COMPLETED)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.COMPLETED} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.COMPLETED)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '10px'}} className="label label-success label-sm fl">{Assets.Completed}</div>

        <input disabled = {isAnyWithdrawalRequestActive(TransactionStatusNoPending.DENIEDBYBANK)} className = "dbl fl" type = "checkbox" checked = {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.DENIEDBYBANK} onChange = {() => changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.DENIEDBYBANK)}/>
        <div style = {{marginLeft: '2px', marginTop: '2px', marginRight: '0px'}} className="label label-danger label-denied-by-bank label-sm fl">{Assets.DeniedByBank}</div>
        </>}
    </div>
    )
}

function controlPanel(pending: boolean, date?: string, approvedWithdrawalRequests?: number[], rejectedWithdrawalRequests?: number[]): JSX.Element {
    return (
        pending ? 
        Main.Instance.DatabaseDataState.withdrawalRequest1 === undefined || !fullyLoaded(true) || !fullyLoaded(false) ? void 0 :
            Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED ||
            Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED ?
            <div className = "mt5">
                <button onClick = {() => selectAllPending()} type = "button" className = "btn btn-xs btn-success">{Assets.SelectAll}</button>
                <button onClick = {() => deselectAllPending()} type = "button" className = "btn btn-xs btn-default">{Assets.DeselectAll}</button>
                {/*Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED ?*/}
                <button
                    type = "button"
                    disabled = {!atLeastOneSelected(Main.Instance.DatabaseDataState.withdrawalRequest1, TransactionStatus.PENDING)}
                    onClick = {() => changeStatusTo(TransactionStatus.REJECTED)}
                    className = "btn btn-xs btn-danger"
                >
                    {Assets.ChangeStatusTo + ' ' + Assets.Rejected}
                </button>
                {/*: void 0*/}
                {/*Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED ?*/}
                <button
                    type = "button"
                    disabled = {/*Main.Instance.DatabaseDataState.rejectWithdrawals*//*Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED ||*/ !atLeastOneSelected(Main.Instance.DatabaseDataState.withdrawalRequest1, TransactionStatus.PENDING)}
                    onClick = {() => changeStatusTo(TransactionStatus.APPROVED)}
                    className = "btn btn-xs btn-primary mr0"
                >
                    {Assets.ChangeStatusTo + ' ' + Assets.Approved}
                </button>
                {/*: void 0*/}
            </div> : void 0
        : date && approvedWithdrawalRequests && rejectedWithdrawalRequests && fullyLoaded (true) && fullyLoaded (false) ?
        <div className = "mt5">
            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.EXECUTED ?
            <>
            <button onClick = {() => SelectAll(TransactionStatus.EXECUTED)} type = "button" className = "btn btn-xs btn-success">{Assets.SelectAll + ' ' + Assets.Executed}</button>
            <button onClick = {() => DeselectAll(TransactionStatus.EXECUTED)} type = "button" className = "btn btn-xs btn-default">{Assets.DeselectAll + ' ' + Assets.Executed}</button>
            <button disabled = {!atLeastOneSelected(Main.Instance.DatabaseDataState.withdrawalRequest2, TransactionStatus.EXECUTED)} onClick = {() => ChangeStatusTo2(TransactionStatus.COMPLETED)} type = "button" className = "btn btn-xs btn-success">{Assets.ChangeStatusTo + ' ' + Assets.Completed}</button>
            <button disabled = {!atLeastOneSelected(Main.Instance.DatabaseDataState.withdrawalRequest2, TransactionStatus.EXECUTED)} onClick = {() => ChangeStatusTo2(TransactionStatus.DENIEDBYBANK)} type = "button" className = "btn btn-xs btn-success label-denied-by-bank">{Assets.ChangeStatusTo + ' ' + Assets.DeniedByBank}</button>
            </> : void 0}

            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.APPROVED && fullyLoaded(false) ?
            <>
            <button disabled = {!DbRoutes.thereIsNoneGeneratedPaymentFiles()} onClick = {() => selectAll2(TransactionStatus.APPROVED)} type = "button" className = "btn btn-xs btn-success">{Assets.SelectAll + ' ' + Assets.Approved}</button>
            <button onClick = {() => deselectAll2(TransactionStatus.APPROVED)} type = "button" className = "btn btn-xs btn-default">{Assets.DeselectAll + ' ' + Assets.Approved}</button>
            <button disabled = {approvedWithdrawalRequests.length === 0} type = "button" className = "btn btn-xs btn-danger" onClick = {() => ChangeStatusTo(TransactionStatus.PENDING)}>{Assets.Revert + Assets._to_ + Assets.Pending}</button>
            <button disabled = {approvedWithdrawalRequests.length === 0 || Main.Instance.DatabaseDataState.duplicatesOther === undefined || Main.Instance.DatabaseDataState.duplicatesOther.length > 0 || Main.Instance.DatabaseDataState.wtithdrawalRequest2DuplicatesInTable.length > 0} title = {(approvedWithdrawalRequests.length === 1 ? '1 ' + Assets.Withdrawal : approvedWithdrawalRequests.length + ' ' + Assets.Withdrawals) + ' ' + Assets.Waiting + Assets._for_ + Assets.Execution} onClick = {() => GenerateAPaymentFile(date)} type = "button" className = "btn btn-primary btn-xs mr0">{Assets.GenerateAPaymentFile}</button>
            </> : void 0}
            {Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus === TransactionStatusNoPending.REJECTED ?
            <button disabled = {rejectedWithdrawalRequests.length === 0} type = "button" className = "btn btn-xs btn-danger" onClick = {() => ChangeStatusTo(TransactionStatus.PENDING)}>{Assets.Revert + Assets._to_ + Assets.Pending}</button>
            : void 0}
        </div>
        : void 0
    )
}

function getInfoWithdrawals(): JSX.Element {
    const infoWithdrawals: JSX.Element[] = []
    for (const item of Assets.WithdrawalsHowTo) {
        infoWithdrawals.push(<li style = {{lineHeight: '14px'}}>{item}</li>)
    }
    return <ol style = {{marginBottom: '00px', padding: '5px', paddingInlineStart: '20px'}}>{infoWithdrawals}</ol>
}

function LoadWithdrawalsAgain() {
    Socket1.socket.Send(Config.walletGameWithdrawGet, {
        transactionStatus: TransactionStatus.PENDING,
        page: 1,
        pageSize: isProduction() ? 50 : 10
        // pageSize: isProduction() ? undefined : 100
    })
    Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'
    Logic.Type.New(Main.Instance.DatabaseDataState, {
        withdrawalRequest1: [],
        withdrawalRequest1Count: 0,
        withdrawalRequest2: [],
        withdrawalRequest2Count: 0,
        WithdrawalRequest1: true,
        withdrawalRequest1Page: 1,
        withdrawalRequest2Page: 1,
        WithdrawalRequest1Time: Math.floor((new Date()).getTime()/1000)
    })
    DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
}

function RefreshButtonDisabled(): boolean {
/*
    console.log ('requestTooSoon ********')
    console.log (Main.Instance.DatabaseDataState.WithdrawalRequest1Time)
    console.log (Main.Instance.DatabaseDataState.WithdrawalRequest2Time)
    console.log (requestTooSoon(true))
    console.log (requestTooSoon(false))
    console.log ('***********************')
*/
    return (Main.Instance.DatabaseDataState.WithdrawalRequest1_
        || Main.Instance.DatabaseDataState.WithdrawalRequest2_
        || Main.Instance.DatabaseDataState.WithdrawalRequest2
        || Main.Instance.DatabaseDataState.WithdrawalRequest1
        || requestTooSoon(true)
        || requestTooSoon(false)
    )
}