import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function PlayerManagementWrapper(props: React.Props<any> ) {
    if (RouterObjects['account_Store'] && Main.Instance.Location === RouterObjects['account_Store'].path && Main.Instance.Back === false) {
        // if (RouterObjects['compliance_Store'].children['Risk_Management'])
        const path: string = RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Player_Card'].path

        let Path: string = RouterObjects['account_Store'].path.substring (1, RouterObjects['account_Store'].path.length) + ' -> ' +
        RouterObjects['account_Store'].children['Player_Card'].path.substring(1, RouterObjects['account_Store'].children['Player_Card'].path.length)
        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )        
        Logic.Type.New(Main.Instance, {Location: path})
    }
    return (<div>{props.children}</div>)
}
