import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Main} from '../../../Logic/Main'
import ReactTable from 'react-table'

interface State {
    regionType: string
}

export class TableOfRegions extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            regionType: ''
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getFlag(flag: string): JSX.Element|string {
        if (flag) {
            return <img src = {flag}/>
        }
        return '-'
    }

    public getTotalCount(): string {
        let tc: number = 0
        if (Main.Instance.OddsState.countriesTypes) {
            for (const item of Main.Instance.OddsState.countriesTypes) {
                tc += +item.count
            }
        }
        return tc.toString()
    }

    public getRegionTypes(): JSX.Element[] {
        const regionTypes: JSX.Element[] = []
        regionTypes.push(<option key = "_" value = "">{Assets.Any + ' ' + Assets.Region.toLowerCase() + ' ' + Assets.Type.toLowerCase()} [{this.getTotalCount()}]</option>)
        if (Main.Instance.OddsState.countriesTypes) {
            for (const item of Main.Instance.OddsState.countriesTypes) {
                regionTypes.push(<option key = {item.name} value = {item.name}>{item.name} [{item.count}]</option>)
            }
        }
        return regionTypes
    }

    public render() {
        // console.log (Main.Instance.OddsState.countries)
        // console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)
        const filtered = []
        if (this.state.regionType.length > 0) {
            filtered.push({id: 'countrytype', value: this.state.regionType})
        }
        return (
            <div>
                {/*console.log (Main.Instance.OddsState.countriesTypes)*/}
                <h3>{Assets.Regions}</h3>
                <div>
                    <select
                        value = {this.state.regionType}
                        onChange = {(e) => this.setState({regionType: e.target.value})}
                        style = {{width: 'auto'}}
                        className = "form-control"
                    >
                        {this.getRegionTypes()}
                    </select>
                </div>
                <br/>
                {Main.Instance.OddsState.countries ?
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.OddsState.countries}
                    columns = {[
                        {width: 50, Header: Assets.ID, headerClassName: 'th-left', accessor: 'id'},
                        {width: 200, Header: Assets.Name, headerClassName: 'th-left', accessor: 'name'},
                        {width: 60, Header: Assets.Flag, headerClassName: 'th-left', accessor: 'flag', Cell: (row) => this.getFlag(row.value)},
                        {width: 200, Header: Assets_.URL, headerClassName: 'th-left', accessor: 'url'},
                        {width: 50, Header: Assets.Code, headerClassName: 'th-left', accessor: 'code'},
                        {width: 60, Header: Assets.Code + '2', headerClassName: 'th-left', accessor: 'code2'},
                        {Header: Assets.Region + ' ' + Assets.Type.toLowerCase(), headerClassName: 'th-left', accessor: 'countrytype'},
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                /> : void 0}
            </div>
        )
    }
}