import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
// import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {toggleTimeUnit} from './reusables'

interface StateSettings {
    allowPlay: boolean
    allowDeposit: boolean
    allowWithdraw: boolean
    categoriesSelected: boolean           
    manualExclusionFinish: boolean 
    minimalExclusionTime?: number
    minimalExclusionTimeUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year'
}

export class TempExcludeSettingsPopup extends React.Component<{}, StateSettings> {
    constructor(props) {
        super(props)
        this.state = {
            allowPlay: false,
            allowDeposit: false,
            allowWithdraw: false,
            categoriesSelected: false,                
            manualExclusionFinish: false,
            minimalExclusionTimeUnit: 'N/A',
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amTemporaryExclude, {
            allowPlay: this.state.allowPlay,
            allowDeposit: this.state.allowDeposit,
            allowWithdraw: this.state.allowWithdraw,
            categoriesSelected: this.state.categoriesSelected,
            manualExclusionFinish: this.state.manualExclusionFinish,
            minimalExclusionTime: this.state.minimalExclusionTime,
            minimalExclusionTimeUnit: this.state.minimalExclusionTimeUnit,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddTemporaryExclusionSettingsRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowPlay: !this.state.allowPlay})} on = {this.state.allowPlay} />
                            <div className = "fl mt2">{Assets.AllowPlay}</div>
                        </div>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowDeposit: !this.state.allowDeposit})} on = {this.state.allowDeposit} />
                            <div className = "fl mt2">{Assets.AllowDeposit}</div>
                        </div>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowWithdraw: !this.state.allowWithdraw})} on = {this.state.allowWithdraw} />
                            <div className = "fl mt2">{Assets.AllowWithdraw}</div>
                        </div>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({categoriesSelected: !this.state.categoriesSelected})} on = {this.state.categoriesSelected} />
                            <div className = "fl mt2">{Assets.CategoriesSelected}</div>
                        </div>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({manualExclusionFinish: !this.state.manualExclusionFinish})} on = {this.state.manualExclusionFinish} />
                            <div className = "fl mt2">{Assets.ManualExclusionFinish}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.MinimalExclusionTime}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({minimalExclusionTime: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.minimalExclusionTime}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.MinimalExclusionTimeUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({minimalExclusionTimeUnit: toggleTimeUnit(this.state.minimalExclusionTimeUnit).NewUnitMHDM})}
                            >
                                {this.state.minimalExclusionTimeUnit}
                            </button>
                        </div>

                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateOptions {
    duration?: number
    durationUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    riskLevel: RiskLevel
}

export class TempExcludeOptionsPopup extends React.Component<{}, StateOptions> {
    constructor(props) {
        super(props)
        this.state = {
            durationUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amTemporaryExcludeOptions, {
            duration: this.state.duration,
            durationUnit: this.state.durationUnit,
            riskLevel: this.state.riskLevel,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddTemporaryExclusionSettingsRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Duration}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({duration: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.duration}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.DurationUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({durationUnit: toggleTimeUnit(this.state.durationUnit).NewUnitMHDM})}
                            >
                                {this.state.durationUnit}
                            </button>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempExclude: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
