import * as React from 'react'
// import {Modal} from "../../Reusables/Modal"
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import {isUserAdmin/*, isUserSystem, isUserOperator*/} from '../../../Logic/Utils' 
import {/*FormSelect, */FormInput, FormInputNumber, FormCheckbox/*RadioButton2*/} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave, faUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as Assets_ from '../../Assets_'

export function getHoldingCount() {

    if (Main.Instance.RegisterHoldingState.countCalled === false) {

        Logic.Type.New(Main.Instance.RegisterHoldingState, {countCalled:true})

        DbRoutes.countContractualPartnersAllByType(     opsCheckboxes, 
                                                        gpsCheckboxes,
                                                        ppsCheckboxes,
                                                        cpsCheckboxes,
                                                        bosCheckboxes)     
        // DbRoutes.countHoldings()
        DbRoutes.getHoldings()
    }
}



function getHolding() {

    Logic.Type.New (Main.Instance.DatabaseDataState,{masterStream : undefined})
}

function clearFields() {

    Logic.Type.New(Main.Instance.RegisterHoldingState, {
            id: '',
            name : '',
            email : '',
            phone : '',
            // custom : {},
            // data : {},
            // type_id: '0',
            person : '',
            /*gg_rm_wallet : '2',*/
            mode: false,
            temp: false,
            type_temp_id: '0',
    })
    
    DbRoutes.countContractualPartnersAllByType( opsCheckboxes,
                                                gpsCheckboxes,
                                                ppsCheckboxes,
                                                cpsCheckboxes,
                                                bosCheckboxes)
}

function handleInsertUpdate() {

        const items = {

        name:           !Main.Instance.RegisterHoldingState.name        ? '' : Main.Instance.RegisterHoldingState.name,
        email:          !Main.Instance.RegisterHoldingState.email       ? '' : Main.Instance.RegisterHoldingState.email,
        telephone:      !Main.Instance.RegisterHoldingState.phone   ? '' : Main.Instance.RegisterHoldingState.phone,
        id:             !Main.Instance.RegisterHoldingState.id          ? '' : Main.Instance.RegisterHoldingState.id,
        // custom:!Main.Instance.RegisterHoldingState.custom ? '' : Main.Instance.RegisterHoldingState.custom,
        // data:!Main.Instance.RegisterHoldingState.data ? '' : Main.Instance.RegisterHoldingState.data,
        person:        !Main.Instance.RegisterHoldingState.person     ? '' : Main.Instance.RegisterHoldingState.person,
/*
        type_id: !Main.Instance.RegisterHoldingState.type_id        ? '' : Main.Instance.RegisterHoldingState.type_id,

        gg_rm_wallet: !Main.Instance.RegisterHoldingState.gg_rm_wallet ? '' : 
        Main.Instance.RegisterHoldingState.gg_rm_wallet,

        gg_rm_wallet_id: !Main.Instance.RegisterHoldingState.gg_rm_wallet ? false : 
        Main.Instance.RegisterHoldingState.gg_rm_wallet === '1',
*/
        }

        // if (parseInt(items.type_id) !== 1) { items.gg_rm_wallet = '2';items.gg_rm_wallet_id = false}

        if (items.name.length === 0) {
                alert (Assets.compulsoryMaster)
                return
        } else if (items.person.length === 0) {
                alert (Assets.compulsoryPerson)
                return
        } else if (items.email.length === 0) {
                alert (Assets.compulsoryEmail)
                return
/*
        } else if (items.telephone.length === 0) {
                alert (Assets.compulsoryPhone)
                return
        } else if (items.telephone.charAt(0) !== '+') {
                alert (Assets.compulsoryPhoneStartWithPlus)
                return
*/
        } else if (Main.Instance.RegisterHoldingState.mode === false) {
        // means that we are in false mode = INSERT
            DbRoutes.insertHolding (items, InsertLastPage)
            Logic.Type.New(Main.Instance.RegisterHoldingState, {temp:true})
        } else {
        // MODE UPDATE
            DbRoutes.updateHolding (items, Update)
        }
}
function handleEdit(row: any) {
    // console.log (row.original)
    if (row) {
        Logic.Type.New(Main.Instance.RegisterHoldingState, {
            id: row.original.id.toString(), 
            name: row.original.master !== null ? row.original.master : '',
            person: row.original.person !== null ? row.original.person : '',
            email: row.original.email !== null ? row.original.email : '',
            phone: row.original.telephone !== null ? row.original.telephone : '',
            name_saved :row.original.master !== null ? row.original.master : '',
            person_saved :row.original.person !== null ? row.original.person : '',
            email_saved :row.original.email !== null ? row.original.email : '',
            phone_saved :row.original.telephone !== null ? row.original.telephone : '',
            temp: true,
            mode: true,
        })

        ResetTempType(1)
        DbRoutes.countContractualPartnersAllByType(     opsCheckboxes,
                                                        gpsCheckboxes,
                                                        ppsCheckboxes,
                                                        cpsCheckboxes,
                                                        bosCheckboxes)
    }
}
/*
function handleEdit(rowData:string[]) {

if (rowData) { 
        Logic.Type.New(Main.Instance.RegisterHoldingState, {
                id:rowData[0], 
                name: rowData[1],
                person: rowData[17], 
                email: rowData[18],
                phone: rowData[19], 
                name_saved :rowData[1],
                person_saved :rowData[17],
                email_saved :rowData[18],
                phone_saved :rowData[19],
                temp:true,
                mode:true})

        ResetTempType(1)
        DbRoutes.countContractualPartnersAllByType(     opsCheckboxes,
                                                        gpsCheckboxes,
                                                        ppsCheckboxes,
                                                        cpsCheckboxes,
                                                        bosCheckboxes)
        }
}
*/
/*
function handleDelete(rowData:string[]) {
        if (rowData) {   
                if (confirm(    Assets.sureToDelete + 
                                ' ' +
                                Assets.Master + ' "' + rowData[1] + '"?')) {


                        if (Main.Instance.RegisterHoldingState.pNum === 
                        (Math.trunc((Main.Instance.RegisterHoldingState.count - 2) / 
                        Main.Instance.RegisterHoldingState.pSize) + 1)) {
                        // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                        // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                                
                                DbRoutes.removeHolding(rowData[0].toString(),DeleteLastPage)
                        } else {

                                DbRoutes.removeHolding(rowData[0].toString(),DeleteNotLastPage)
                        }
                }
        }
}
*/
function handleDelete(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Master + ' "' + row.original.master + '"?')) {
        if (Main.Instance.RegisterHoldingState.pNum === 
            (Math.trunc((Main.Instance.RegisterHoldingState.count - 2) / 
            Main.Instance.RegisterHoldingState.pSize) + 1))  {
            DbRoutes.removeHolding (row.original.id.toString(), DeleteLastPage)
        } else {
            DbRoutes.removeHolding (row.original.id.toString(), DeleteNotLastPage)
        }
    }
}

function ResetTempType(arg:number) {
    Logic.Type.New(Main.Instance.RegisterHoldingState,{type_temp_id:arg.toString()})
}

function InsertLastPage() {
    Logic.Type.New(Main.Instance.RegisterHoldingState,{count:Main.Instance.RegisterHoldingState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.RegisterHoldingState,{count:Main.Instance.RegisterHoldingState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.RegisterHoldingState,{count:Main.Instance.RegisterHoldingState.count --})
    getHolding()
}

function Update() {
    clearFields()
    getHolding()
}

function gotoInsertMode() {

        if (Main.Instance.RegisterHoldingState.temp && !Main.Instance.RegisterHoldingState.mode) {
                clearFields()
                return
        }

        if (Main.Instance.RegisterHoldingState.name === Main.Instance.RegisterHoldingState.name_saved &&
        Main.Instance.RegisterHoldingState.person === Main.Instance.RegisterHoldingState.person_saved &&
        Main.Instance.RegisterHoldingState.email === Main.Instance.RegisterHoldingState.email_saved &&
        Main.Instance.RegisterHoldingState.phone === Main.Instance.RegisterHoldingState.phone_saved
        ) {

        clearFields()
        } else {

                if (confirm(Assets.UnsavedChanges)) {clearFields() }                
        }
}

function FirstPage() {

        Logic.Type.New(Main.Instance.RegisterHoldingState, {offset:(0).toString()})
        getHolding()
        Logic.Type.New(Main.Instance.RegisterHoldingState,{pNum: 0})
}

function PrevPage() {

        if (Main.Instance.RegisterHoldingState.pNum > 0) {

                Logic.Type.New(Main.Instance.RegisterHoldingState, {
                        offset: ((Main.Instance.RegisterHoldingState.pNum - 1) *  
                        Main.Instance.RegisterHoldingState.pSize).toString()})
                getHolding()
                Logic.Type.New(Main.Instance.RegisterHoldingState,{pNum: Main.Instance.RegisterHoldingState.pNum - 1})
        } else {
                LastPage()
        }

}

function NextPage() {

        if ((Main.Instance.RegisterHoldingState.pNum + 1) * Main.Instance.RegisterHoldingState.pSize < 
        Main.Instance.RegisterHoldingState.count) {
                Logic.Type.New(Main.Instance.RegisterHoldingState, {
                        offset:(Main.Instance.RegisterHoldingState.pSize * 
                        (Main.Instance.RegisterHoldingState.pNum + 1)).toString()})
                getHolding()                
                Logic.Type.New(Main.Instance.RegisterHoldingState, {pNum: Main.Instance.RegisterHoldingState.pNum + 1})
        } else {
                FirstPage()
        }
}

function LastPage() {

        if (Main.Instance.RegisterHoldingState.count <= Main.Instance.RegisterHoldingState.pSize) {
                FirstPage()
        } else {
                let npNum: number = 0;
                if (Main.Instance.RegisterHoldingState.count === 0) {
                        Logic.Type.New(Main.Instance.RegisterHoldingState, {offset: (0).toString()})
        } else {
                npNum = Math.trunc((Main.Instance.RegisterHoldingState.count - 1) /
                Main.Instance.RegisterHoldingState.pSize)
                Logic.Type.New(Main.Instance.RegisterHoldingState, {
                        offset: (npNum * Main.Instance.RegisterHoldingState.pSize).toString(),
                })
        }
                getHolding()
                Logic.Type.New(Main.Instance.RegisterHoldingState, {pNum: npNum})
        }

}

function RecordsPerPageChanged(text:string) {

        Logic.Type.New(Main.Instance.RegisterHoldingState,{pSize:parseInt(text)})
        FirstPage()
}
/*
function ApplyFilter(text:string) {

        Logic.Type.New(Main.Instance.RegisterHoldingState,{type_id_filter:text})
        FirstPage()
}
*/

function setSelectedContractualPartners(type:number,key:string, checked:boolean) {

        if (type === 0) {            
                const stateCopy = Object.assign({}, Main.Instance.RegisterHoldingState)

                if (stateCopy.ops) {

                        stateCopy.ops[parseInt(key)] = checked

                        // console.log('checked'+checked)

                        if (checked) {
                                DbRoutes.selectContractualPartner (stateCopy.ops_id[parseInt(key)], LastPage)
                        } else {
                                DbRoutes.deselectContractualPartner (stateCopy.ops_id[parseInt(key)], LastPage)
                        }

                        let selected:number = 0
                        for (let i:number = 0; i < stateCopy.ops.length; i++) {
                                if (stateCopy.ops[i]) {
                                        selected++
                                }
                        }

                        stateCopy.ops_selected = selected.toString()

                        // console.log('selected'+selected)

                        Logic.Type.New(Main.Instance.RegisterHoldingState,stateCopy)
                }
        }

        if (type === 1) {            
                const stateCopy = Object.assign({}, Main.Instance.RegisterHoldingState)

                if (stateCopy.gps) {

                        stateCopy.gps[parseInt(key)] = checked

                        if (checked) {
                                DbRoutes.selectContractualPartner (stateCopy.gps_id[parseInt(key)], LastPage)
                        } else {
                                DbRoutes.deselectContractualPartner (stateCopy.gps_id[parseInt(key)], LastPage)
                        }

                        let selected:number = 0
                        for (let i:number = 0; i < stateCopy.gps.length; i++) {
                                if (stateCopy.gps[i]) {
                                        selected++
                                }
                        }

                        stateCopy.gps_selected = selected.toString()
                        Logic.Type.New(Main.Instance.RegisterHoldingState,stateCopy)
                }
        }

        if (type === 2) {            
                const stateCopy = Object.assign({}, Main.Instance.RegisterHoldingState)

                if (stateCopy.pps) {

                        stateCopy.pps[parseInt(key)] = checked

                        if (checked) {
                                DbRoutes.selectContractualPartner (stateCopy.pps_id[parseInt(key)], LastPage)
                        } else {
                                DbRoutes.deselectContractualPartner (stateCopy.pps_id[parseInt(key)], LastPage)
                        }

                        let selected:number = 0
                        for (let i:number = 0; i < stateCopy.pps.length; i++) {
                                if (stateCopy.pps[i]) {
                                        selected++
                                }
                        }

                        stateCopy.pps_selected = selected.toString()

                        Logic.Type.New(Main.Instance.RegisterHoldingState,stateCopy)
                }
        }

        if (type === 3) {            
                const stateCopy = Object.assign({}, Main.Instance.RegisterHoldingState)

                if (stateCopy.cps) {

                        stateCopy.cps[parseInt(key)] = checked

                        if (checked) {
                                DbRoutes.selectContractualPartner (stateCopy.cps_id[parseInt(key)], LastPage)
                        } else {
                                DbRoutes.deselectContractualPartner (stateCopy.cps_id[parseInt(key)], LastPage)
                        }

                        let selected:number = 0
                        for (let i:number = 0; i < stateCopy.cps.length; i++) {
                                if (stateCopy.cps[i]) {
                                        selected++
                                }
                        }

                        stateCopy.cps_selected = selected.toString()

                        Logic.Type.New(Main.Instance.RegisterHoldingState,stateCopy)
                }
        }

        if (type === 4) {            
                const stateCopy = Object.assign({}, Main.Instance.RegisterHoldingState)

                if (stateCopy.bos) {

                        stateCopy.bos[parseInt(key)] = checked

                        if (checked) {
                                DbRoutes.selectContractualPartner (stateCopy.bos_id[parseInt(key)], LastPage)
                        } else {
                                DbRoutes.deselectContractualPartner (stateCopy.bos_id[parseInt(key)], LastPage)
                        }

                        let selected:number = 0
                        for (let i:number = 0; i < stateCopy.bos.length; i++) {
                                if (stateCopy.bos[i]) {
                                        selected++
                                }
                        }
                        stateCopy.bos_selected = selected.toString()

                        Logic.Type.New(Main.Instance.RegisterHoldingState,stateCopy)
                }
        }
}

function bosCheckboxes() {

        const cb:JSX.Element[] = []
        let count:number = 0 
        let countSelected:number = 0 
        const bos : boolean [] = []
        const bos_id : string [] = []
    
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.forEach((item:{id:string, name:string, is_selected?:boolean}) => {
                let current:boolean

                if (item.is_selected && item.is_selected.toString() == 'true') {
                        current = true
                } else {
                        current = false
                }
                
                bos_id.push (item.id)

                if (current === true) {
                        countSelected ++
                        cb.push (
                                <FormCheckbox  
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {true}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()}
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(4,name, checked)}}
                                />)
                } else {
                        cb.push (
                                <FormCheckbox   
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {false}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()} 
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(4, name, checked)}}
                                />)
                }

                count++
        })

        Logic.Type.New (Main.Instance.RegisterHoldingState, {
                        bos,
                        bos_id,
                        bos_cb:cb,
                        bos_total:count.toString(),
                        bos_selected:countSelected.toString(),
        })
}

function gpsCheckboxes() {

        const cb:JSX.Element[] = []
        let count:number = 0 
        let countSelected:number = 0 
        const gps : boolean [] = []
        const gps_id : string [] = []
    
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.forEach(
        (item:{id:string,name:string,is_selected?:boolean}) => {
                let current:boolean

                if (item.is_selected && item.is_selected.toString() == 'true') {
                        current = true
                } else {
                        current = false
                }
                    
                gps_id.push (item.id)

                if (current === true) {
                        countSelected ++
                        cb.push (
                                <FormCheckbox  
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {true}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()}
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(1, name, checked)}}
                                />)
                } else {
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {false}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()} 
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(1, name, checked)}}
                                />)
                }

                count++
        })

        Logic.Type.New (Main.Instance.RegisterHoldingState, {
                gps,
                gps_id,
                gps_cb:cb,
                gps_total:count.toString(),
                gps_selected:countSelected.toString()})
}

function ppsCheckboxes() {

        const cb:JSX.Element[] = []
        let count:number = 0 
        let countSelected:number = 0 
        const pps : boolean [] = []
        const pps_id : string [] = []
    
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.forEach(
        (item:{id:string,name:string,is_selected?:boolean}) => {
                let current:boolean

                if (item.is_selected && item.is_selected.toString() == 'true') {
                        current = true
                } else {
                        current = false
                }
                    
                pps_id.push (item.id)

                if (current === true) {
                        countSelected ++
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {true}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()}
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(2, name, checked)}}
                                />)
                } else {
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {false}
                                        ClassName = "checkbox-inline mr2" 
                                        value = {count.toString()} 
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(2, name, checked)}}
                                />)
                }

                count++
        })

        Logic.Type.New (Main.Instance.RegisterHoldingState, {
                pps,pps_id,pps_cb:cb,pps_total:count.toString(),pps_selected:countSelected.toString()})
}


function cpsCheckboxes() {

        const cb:JSX.Element[] = []
        let count:number = 0 
        let countSelected:number = 0 
        const cps : boolean [] = []
        const cps_id : string [] = []
    
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.forEach(
        (item:{id:string,name:string,is_selected?:boolean}) => {
                let current:boolean

                if (item.is_selected && item.is_selected.toString() == 'true') {
                        current = true
                } else {
                        current = false
                }
                    
                cps_id.push (item.id)

                if (current === true) {
                        countSelected ++
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {true}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()}
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(3, name, checked)}}
                                />)
                } else {
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {false}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()} 
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(3, name, checked)}}
                                />)
                }

                count++
        })

        Logic.Type.New (Main.Instance.RegisterHoldingState, {
                cps,cps_id,cps_cb:cb,cps_total:count.toString(),cps_selected:countSelected.toString()})
}

function opsCheckboxes() {

        const cb:JSX.Element[] = []
        let count:number = 0 
        let countSelected:number = 0 
        const ops : boolean [] = []
        const ops_id : string [] = []
    
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.forEach(
        (item:{id:string,name:string,is_selected?:boolean}) => {
                let current:boolean

                if (item.is_selected && item.is_selected.toString() == 'true') {
                        current = true
                } else {
                        current = false
                }
                    
                ops_id.push (item.id)

                if (current === true) {
                        countSelected ++
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {true}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()}
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(0,name, checked)}}
                                />)
                } else {
                        cb.push (
                                <FormCheckbox 
                                        key_ = {true}
                                        key = {count.toString()}
                                        dc = {false}
                                        ClassName = "checkbox-inline mr2"
                                        value = {count.toString()} 
                                        name = {item.name}
                                        onChange = {(checked, name) => {
                                                setSelectedContractualPartners(0, name, checked)}}
                                />)
                }

                count++
        })

        Logic.Type.New (Main.Instance.RegisterHoldingState, {
                type_temp_id: '0',
                ops,
                ops_id,
                ops_cb:cb,
                ops_total:count.toString(),
                ops_selected:countSelected.toString()})
}


export function ManageHolding() {


        return (

        <div>
        {isUserAdmin() ? <h3>{Assets.rMaster}</h3> : <h3>{Assets.vMaster}</h3>}
        <br/>
        {isUserAdmin() ? 
        <div className="row admin-users-container">
                <div 
                        className = {Main.Instance.RegisterHoldingState.mode ? 
                        Main.Instance.RegisterHoldingState.temp ? 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit-bottom' : 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                        Main.Instance.RegisterHoldingState.temp ? 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel-bottom' :
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
                >

                <div className = "register-input">
                        <div className = "row admin-users-row"><h4>
                        {Main.Instance.RegisterHoldingState.mode ? 
                        Assets.EditMaster + 
                        ': ' + 
                        Main.Instance.RegisterHoldingState.name_saved : Assets.AddMaster}</h4>

                        {Main.Instance.RegisterHoldingState.mode || Main.Instance.RegisterHoldingState.temp ? 
                        <ButtonIcon
                            description = {Assets.discardChanges}
                            click = {() => gotoInsertMode()} 
                            ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : void 0}

                        </div>

                        <div className = "row admin-users-row">
                        <div className = "row-info">
                        <span className="register-input">{Assets.Master}</span>
                        </div>
                        <FormInput 
                                value = {!Main.Instance.RegisterHoldingState.name ? '' :
                                        Main.Instance.RegisterHoldingState.name}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterHoldingState,{name:text})}}
                                placeholder = {Assets.pName}
                                icon = {Assets_.faMasterClass}
                        />

                {/*<FormSelect
                        narrow = {true}
                        value = {!Main.Instance.RegisterHoldingState.type_id?'0':
                        Main.Instance.RegisterHoldingState.type_id}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.MasterTypeQ} 
                        o2 = {Assets.Operator}
                        o3 = {Assets.GameProvider}
                        o4 = {Assets.PaymentProvider}
                        o5 = {Assets.ComplianceProvider} 
                        onChange = {text => {Logic.Type.New(Main.Instance.RegisterHoldingState,{type_id:text})}} 
                        icon = {Assets_.faCompanyClass}/>*/}
                        {/*o6 = {Assets.Affiliate}*/}

                {/*parseInt(Main.Instance.RegisterHoldingState.type_id) === 1 ?
                    <RadioButton2
                        value = {!Main.Instance.RegisterHoldingState.gg_rm_wallet?'2':
                        Main.Instance.RegisterHoldingState.gg_rm_wallet} 

                        ClassName="input-group-radiobutton2 margin-bottom-sm" 
                        title={Assets.GGRMWalletExplained}
                        text = {Assets.GGRMWalletText}
                        i1={Assets_.active}
                        i2={Assets_.inactive}
                        onClick={text=>{Logic.Type.New(Main.Instance.RegisterHoldingState, {gg_rm_wallet:text})} 
                        
                    /> : null }*/}


                
                </div>
                <div className="row admin-users-row">
                        <div className = "row-info">
                        <span className="register-input">{Assets.MainContactPerson}</span>
                        </div>
                        <FormInput 
                                value = {!Main.Instance.RegisterHoldingState.person ? '' :
                                        Main.Instance.RegisterHoldingState.person}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterHoldingState,{person:text})}}
                                placeholder = {Assets.pName}
                                icon =  {Assets_.faUserClass}
                        />
                        <FormInput 
                                value = {!Main.Instance.RegisterHoldingState.email ? '' :
                                        Main.Instance.RegisterHoldingState.email}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterHoldingState,{email:text})}}
                                placeholder = {Assets.pEmail}
                                icon =  {Assets_.faEmailClass}
                        />

                        <FormInput 
                                value = {!Main.Instance.RegisterHoldingState.phone ? '' :
                                        Main.Instance.RegisterHoldingState.phone}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterHoldingState,{phone:text})}}
                                placeholder = {Assets.pPhone}
                                icon =  {Assets_.faPhoneClass}
                        />

                        {!Main.Instance.RegisterHoldingState.mode && Main.Instance.RegisterHoldingState.temp ? null : 
                        <ButtonIcon 
                            description = {Main.Instance.RegisterHoldingState.mode ? 
                                            Assets.saveChanges : Assets.rMaster}
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.RegisterHoldingState.mode ? 
                                    'btn btn-default btn-sm btn-plus fr mr' : 
                                    'btn btn-primary btn-sm btn-plus fr'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />}
                        </div>

{Main.Instance.RegisterHoldingState.temp?
                        <div className="row admin-users-row">
                        <div className = "row-info-wide">

                        <span> | </span>
                        <span 
                                onClick = {() => Logic.Type.New(Main.Instance.RegisterHoldingState, {type_temp_id:'0'})}
                                className = {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) 
                                === 0 ? 'bold choose' : 'choose'}
                        >
                                OPs ({Main.Instance.RegisterHoldingState.ops_selected},
                                {Main.Instance.RegisterHoldingState.ops_total})
                        </span>
                        <span> | </span>
                        <span 
                                onClick = {() => Logic.Type.New(Main.Instance.RegisterHoldingState, {
                                                type_temp_id:'1'})}
                                className = {parseInt(Main.Instance.RegisterHoldingState.type_temp_id)
                                                === 1 ? 'bold choose' : 'choose'}
                        >
                                GPs ({Main.Instance.RegisterHoldingState.gps_selected},
                                {Main.Instance.RegisterHoldingState.gps_total})
                        </span>
                        <span> | </span>
                        <span 
                                onClick = {() => Logic.Type.New(Main.Instance.RegisterHoldingState, {
                                                type_temp_id:'2'})}
                                className = {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) 
                                                === 2 ? 'bold choose' : 'choose'}
                        >
                                PPs ({Main.Instance.RegisterHoldingState.pps_selected},
                                {Main.Instance.RegisterHoldingState.pps_total})
                        </span>
                        <span> | </span>
                        <span 
                                onClick = {() => Logic.Type.New(Main.Instance.RegisterHoldingState, {
                                type_temp_id:'3'})}
                                className = {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) 
                                                === 3 ? 'bold choose' : 'choose'}
                        >
                                CPs ({Main.Instance.RegisterHoldingState.cps_selected},
                                {Main.Instance.RegisterHoldingState.cps_total})
                        </span>
                        <span> | </span>
                        <span 
                                onClick = {() => Logic.Type.New(Main.Instance.RegisterHoldingState, {
                                type_temp_id:'4'})}
                                className = {parseInt(Main.Instance.RegisterHoldingState.type_temp_id)
                                                === 4 ? 'bold choose' : 'choose'}
                        >
                                SOs ({Main.Instance.RegisterHoldingState.bos_selected},
                                {Main.Instance.RegisterHoldingState.bos_total})
                        </span>
                        <span> |</span>
                        </div>
                        </div>:null}
{Main.Instance.RegisterHoldingState.temp?                        
                        <div className="row admin-users-row">
                        {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) === 0 ? 
                        Main.Instance.RegisterHoldingState.ops_cb : null}
                        {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) === 1 ? 
                        Main.Instance.RegisterHoldingState.gps_cb : null}
                        {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) === 2 ? 
                        Main.Instance.RegisterHoldingState.pps_cb : null}
                        {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) === 3 ? 
                        Main.Instance.RegisterHoldingState.cps_cb : null}
                        {parseInt(Main.Instance.RegisterHoldingState.type_temp_id) === 4 ? 
                        Main.Instance.RegisterHoldingState.bos_cb : null}
                        
                        </div>:null}
                        
                        {Main.Instance.RegisterHoldingState.temp ?
                        <div className = "row admin-users-row cf">
                                <p className = "h20"/>
                        </div>
                        : null}
                        </div>

                </div>


               
        <br/>
        </div>
        : void 0}
        <div className = "control-panel">

                <FormInputNumber
                        description = {Assets.recordsPerPageDesc}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h26 w60"
                        value = {!Main.Instance.RegisterHoldingState.pSize ? '' : 
                        Main.Instance.RegisterHoldingState.pSize.toString()}  
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHamburgerClass}
                        onChange = {(text) => RecordsPerPageChanged(text)}
                />

                <button 
                        className = "ml20 btn btn-primary btn-xs"
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage()}
                >
                        <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs"
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage()}
                >
                        <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage()}
                >
                        <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage()}
                >
                        <i className = {Assets_.faLastButtonClass}/>
                </button>

                {/*<FormSelect span = {true}
                        value = {!Main.Instance.RegisterHoldingState.type_id_filter ? '0' : 
                        Main.Instance.RegisterHoldingState.type_id_filter}
                        data = {[0]}
                        ClassName = "control-panel-select"
                        o1 = {Assets.MasterTypeAny} 
                        o2 = {Assets.Operator}
                        o3 = {Assets.GameProvider}
                        o4 = {Assets.PaymentProvider}
                        o5 = {Assets.ComplianceProvider} 
                        onChange = {text => ApplyFilter(text)} />*/}
                        {/*o6 = {Assets.Affiliate}*/}

                <span className = "label label-success table-label mt5">
                        {Assets.records}
                        {Main.Instance.RegisterHoldingState.count ? Main.Instance.RegisterHoldingState.count : 0}
                </span>
                <span className = "label label-success table-label mt5">
                        {Assets.showing}
                        {Main.Instance.RegisterHoldingState.count ? Main.Instance.RegisterHoldingState.pNum * 
                        Main.Instance.RegisterHoldingState.pSize + 1 : 0}
                        {' - '}
                        {Main.Instance.RegisterHoldingState.count ?
                        ((Main.Instance.RegisterHoldingState.pNum + 1) * Main.Instance.RegisterHoldingState.pSize > 
                        Main.Instance.RegisterHoldingState.count ? Main.Instance.RegisterHoldingState.count : 
                        (Main.Instance.RegisterHoldingState.pNum + 1) * Main.Instance.RegisterHoldingState.pSize) : 0}
                </span>
                <span className = "label label-success table-label mt5">
                        {Assets.page}
                        {Main.Instance.RegisterHoldingState.count ? Main.Instance.RegisterHoldingState.pNum + 1 :0}{' / '}
                        {Main.Instance.RegisterHoldingState.count ? 
                        Math.trunc((Main.Instance.RegisterHoldingState.count - 1)/
                        Main.Instance.RegisterHoldingState.pSize+1) : 0}
                </span>

        </div>
        
        <h3>{Assets.tMasters}</h3>
        {Main.Instance.DatabaseDataState.masterStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.masterStream}
            columns = {[
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {minWidth: 150, Header: Assets.Master, accessor: 'master', headerClassName: 'th-left'},
                {Header: 'OPs (#)', accessor: 'get_ops'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'GPs (#)', accessor: 'get_gps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'PPs (#)', accessor: 'get_pps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'CPs (#)', accessor: 'get_cps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'SOs (#)', accessor: 'get_bos'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'GSTs (#)', accessor: 'get_sits'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'GAPs (#)', accessor: 'get_gaps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'PSPs (#)', accessor: 'get_pps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'CSPs (#)', accessor: 'get_csps'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
                {Header: 'GSTs (#)', accessor: 'get_strs'/*, headerClassName: 'th-left'*/, Cell: (row) => row.value === null ? 0 : row.value.length},
               
                {Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                <div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faUser} />
                        {row.original.person !== null && row.original.person.length > 0 ? row.original.person : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faEnvelope}/>
                        {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faPhone}/>
                        {row.original.telephone !== null && row.original.telephone.length > 0 ? row.original.telephone : Assets.notDefined}
                    </div>
                </div>},
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getHoldingCount()}

        {/*console.log (Main.Instance.DatabaseDataState.masterStream)*/}
        {/*Main.Instance.DatabaseDataState.masterStream ? StremingTable
        (Main.Instance.DatabaseDataState.masterStream, {
            title: Assets.tMasters,
            mode: Main.Instance.RegisterHoldingState.mode,
            editingId: Main.Instance.RegisterHoldingState.id,
            sort: getHoldingCount,
            Sort:Main.Instance.RegisterHoldingState,
            isMasterTable: true,
            delButton:(data) => handleDelete(data),
        editButton:(data) => handleEdit(data)}) : void 0*/}
        </div>
        )
}