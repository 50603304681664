import {Logic} from './Type'
import {RiskManagementState, PlayerCardState} from './RiskComplianceStore/RiskManagement'
import {DashboardState} from './Various/Dashboard'
import {API} from './Various/API'
import {LoginState} from './Various/Login'
import {ReusablesState} from './Various/Reusables'
import {ModalState} from './Various/Modal'
import {MenuState} from './Various/Menu'
import {SearchState} from './Various/Search'
import {SettingsState} from './Settings/Settings'
import {DatabaseDataState} from './Database/DatabaseData'
// import {PlayerManagementState} from './PlayerManagement'
import {CurrencyState,GeneralState} from './GeneralSettings/General'
import {FrontendGamesState, FrontendStoreState} from './FrontendStore/FrontendStoreState'
import {PlayerGroupsInternal, PlayerGroupsState} from './DataStore/DataStore'
import {LogState, GGRRevenueState, PlayerInfo, StorePurchasesState, UsersInfoState, UserInfoState, MissionLeaderboardState} from './Reports/Reports'
import {LoadingResourcesState} from './Database/loadingResources'
import {OddsState} from './OddsStore/OddsState'
import {
    AchievementsState,
    GZG,
    TournamentState,
    LevelsState,
    RSTState,
    RewardShopState,
    
    LanguageState,
    BannerState,
    GamesState,
    PlayersState,
    FlagsState,
    CurrencyRateState,
    CategoryDetailState,
    LottoState,
    LottoTransactionsState,
    CRMPointState,
    MissionState,
    ChallengeState,
    StoreState,
    CalendarState,
    RaffleState,
    ShopState,
    // CRMFreeRounds,
    // PlayerGroupsRewardsState,
    // LoyaltyFreeRoundState
} from './PromoStore/PromoStore'

import {
    ManageState,
    CreateSystemUserState,
    CreateGameProviderUserState,
    CreateOperatorUserState,
    CreateSiteUserState,
    CreateGameAssetProviderUserState,
    CreateComplianceProviderUserState,
    CreateComplianceSubProviderUserState,
    CreatePaymentProviderUserState,
    CreatePaymentSubProviderUserState,
    CreateMasterUserState,
    CreateBrandOwnerUserState,
    CreateSite2UserState,
    RegisterHoldingState, RegisterContractualPartnerState, RegisterSubEntityState,
} from './UserManagement/UserManagement'

import {
    ReportState,
    ReportPlayersPointsState,
    ReportLevelsSublevelsState,
    ReportLevelsPointsState1,
    ReportLevelsPointsState2,
    ReportLevelsPointsState3,
} from './Reports/Reports'

import {/*BonusCampaignState,*/ GameStoreState, GameTestState, AssignGamesState, /*BonusState,*/ GamesState as GamesCentralState, GameProviderState, /*RgsGameSupplierState*/} from './GameStore/GameStore'

import {FreeGamesState, BonusCampaignState as BonusCampaignStateNew, BonusState as BonusStateNew} from './BonusStore/BonusStore'

export class Main extends Logic.Type {
    public static Instance = new Main(new class {
        public Update(state: Main): void {Main.Instance = state}
    }())
    public Location: string
    public Back: boolean = false
    public Health: boolean = true
    public HealthInfo: string
    public GamesInfo: string
    public LoginState = Logic.Type.Add(this, new LoginState())
    public Modal = Logic.Type.Add(this, new ModalState())
    public MenuState = Logic.Type.Add(this, new MenuState())
    public SearchState = Logic.Type.Add(this, new SearchState())
    public OddsState = Logic.Type.Add(this, new OddsState())
    public DatabaseDataState = Logic.Type.Add(this, new DatabaseDataState())
    public LoadingResourcesState = Logic.Type.Add(this, new LoadingResourcesState())
    public StorePurchasesState = Logic.Type.Add(this, new StorePurchasesState())
    public UsersInfoState = Logic.Type.Add(this, new UsersInfoState())
    public MissionLeaderboardState = Logic.Type.Add(this, new MissionLeaderboardState())
    public UserInfoState = Logic.Type.Add(this, new UserInfoState())
    public SettingsState = Logic.Type.Add(this, new SettingsState())
    public ReusableState = Logic.Type.Add(this, new ReusablesState())
    public APIState = Logic.Type.Add(this, new API())
    public DashboardState = Logic.Type.Add(this, new DashboardState())
    public FrontendStoreState = Logic.Type.Add(this,new FrontendStoreState())
    public FrontendGamesState = Logic.Type.Add(this, new FrontendGamesState())
    public ChallengeState = Logic.Type.Add(this, new ChallengeState())
    public RaffleState = Logic.Type.Add(this, new RaffleState())
    public ShopState = Logic.Type.Add(this, new ShopState())
    public StoreState = Logic.Type.Add(this, new StoreState())
    public MissionState = Logic.Type.Add(this, new MissionState())
    public GameStoreState = Logic.Type.Add(this, new GameStoreState())
    public GamesCentralState = Logic.Type.Add(this, new GamesCentralState())
    public RSTState = Logic.Type.Add(this, new RSTState())
    public RewardShopState = Logic.Type.Add(this, new RewardShopState())
    public LottoState = Logic.Type.Add(this, new LottoState())
    public LottoTransactionsState = Logic.Type.Add(this, new LottoTransactionsState())
    // public BonusState = Logic.Type.Add(this, new BonusState())
    public BonusStateNew = Logic.Type.Add(this, new BonusStateNew())
    public FreeGamesState = Logic.Type.Add(this, new FreeGamesState())
    public BonusCampaignStateNew = Logic.Type.Add(this, new BonusCampaignStateNew())
    // public BonusCampaignState = Logic.Type.Add(this, new BonusCampaignState())
    public GameProviderState = Logic.Type.Add(this, new GameProviderState())
    // public RgsGameSupplierState = Logic.Type.Add(this, new RgsGameSupplierState())
    public CurrencyState = Logic.Type.Add(this, new CurrencyState())
    public LanguageState = Logic.Type.Add(this, new LanguageState())
    public BannerState = Logic.Type.Add(this, new BannerState())
    public GamesState = Logic.Type.Add(this, new GamesState())
    public PlayersState = Logic.Type.Add(this, new PlayersState())
    public FlagsState = Logic.Type.Add(this, new FlagsState())
    public CategoryDetailState = Logic.Type.Add(this, new CategoryDetailState())
    public CRMAchievementsState = Logic.Type.Add(this, new AchievementsState())
    public CRMLevelsState = Logic.Type.Add(this, new LevelsState())
    public CurrencyRatesState = Logic.Type.Add(this, new CurrencyRateState())
    public CRMPointState = Logic.Type.Add(this, new CRMPointState())    
    public TournamentState = Logic.Type.Add(this, new TournamentState())
    public GeneralState = Logic.Type.Add(this, new GeneralState())
    public GZG = Logic.Type.Add(this, new GZG())
    // CRMFreeRounds = Logic.Type.Add(this, new CRMFreeRounds())
    // CRMPlayerGroupsRewardsState = Logic.Type.Add(this, new PlayerGroupsRewardsState())
    // CRMLoyaltyFreeRoundState = Logic.Type.Add(this, new LoyaltyFreeRoundState())
    
    public ManageState = Logic.Type.Add(this, new ManageState())
    public RegisterHoldingState = Logic.Type.Add(this, new RegisterHoldingState())
    public RegisterContractualPartnerState = Logic.Type.Add(this, new RegisterContractualPartnerState())
    public RegisterSubEntityState = Logic.Type.Add(this, new RegisterSubEntityState())

    public CreateSystemUserState = Logic.Type.Add(this, new CreateSystemUserState())
    public CreateGameProviderUserState = Logic.Type.Add(this, new CreateGameProviderUserState())
    public CreateOperatorUserState = Logic.Type.Add(this, new CreateOperatorUserState())
    public CreateSiteUserState = Logic.Type.Add(this, new CreateSiteUserState())
    public CreateBrandOwnerUserState = Logic.Type.Add(this, new CreateBrandOwnerUserState())
    public CreateSite2UserState = Logic.Type.Add(this, new CreateSite2UserState())
  
    public CreateGameAssetProviderUserState = Logic.Type.Add(this, new CreateGameAssetProviderUserState())
    public CreateComplianceProviderUserState = Logic.Type.Add(this, new CreateComplianceProviderUserState())
    public CreateComplianceSubProviderUserState = Logic.Type.Add(this, new CreateComplianceSubProviderUserState())
    public CreatePaymentProviderUserState = Logic.Type.Add(this, new CreatePaymentProviderUserState())
    public CreatePaymentSubProviderUserState = Logic.Type.Add(this, new CreatePaymentSubProviderUserState())
    public CreateMasterUserState = Logic.Type.Add(this, new CreateMasterUserState())

    public ReportState = Logic.Type.Add(this, new ReportState())
    public ReportLSState = Logic.Type.Add(this, new ReportLevelsSublevelsState())
    public ReportPState = Logic.Type.Add(this, new ReportPlayersPointsState())

    public ReportPState1 = Logic.Type.Add(this, new ReportLevelsPointsState1())
    public ReportPState2 = Logic.Type.Add(this, new ReportLevelsPointsState2())
    public ReportPState3 = Logic.Type.Add(this, new ReportLevelsPointsState3())

    public GameTestState = Logic.Type.Add(this, new GameTestState())
    public AssignGamesState = Logic.Type.Add(this, new AssignGamesState())
    public PlayerGroupsState = Logic.Type.Add(this, new PlayerGroupsState())
    public PlayerGroupsInternal = Logic.Type.Add(this, new PlayerGroupsInternal())
    public CalendarState = Logic.Type.Add(this, new CalendarState())
    public RiskManagementState = Logic.Type.Add(this, new RiskManagementState())
    public PlayerCardState = Logic.Type.Add(this, new PlayerCardState())
    public LogState = Logic.Type.Add(this, new LogState())
    public GGRRevenueState = Logic.Type.Add(this, new GGRRevenueState())
    public PlayerInfo = Logic.Type.Add(this, new PlayerInfo())
}
