// import {CreateWrapper} from '../Views/Main/Create/CreateWrapper'
// import {GameWrapper} from '../Views/Main/Game/GameWrapper'

// 0. Dashboard
// import {Dashboard} from '../Views/Main/Dashboard/Dashboard'
/*
    dashboard: {
        key: '_0',
        path: '/dashboard',
        menuicon: 'gfont-dashboard gfont-big',
        component: Dashboard,
        AllowedRoles: [131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,98],
        children: {}
    },
*/
/*
IMPORTANT INFORMATION:
setting menuicon to faAllergies => strapi icon. funny shit!
*/
/* import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'*/
import {/*faArrowCircleUp,*/ /*faHockeyPuck, */faFileImport, faGlobeEurope, faFutbol, faRocket, faUserCog, faStopwatch, faGamepad, faWrench, faExclamationCircle, faTasks, faMoneyBillAlt, faUserPlus, faKey, faDiceD20, faTv, faCandyCane, faAd,/*faLanguage,*//*faSitemap,faFileExport*/faAllergies, faUniversity, /*faCheckDouble, faGamepad,*/ faPaintRoller, faArrowCircleDown, faUserTie, faUserTag, faGlobe, faGifts, faShoppingBasket, faCoins, faCalendarAlt, /*faGlobeEurope,*/ faChartPie, faProjectDiagram, faGift, faShoppingCart, faTrophy, faBolt, faListOl, faCog, faCogs, faExclamationTriangle, faHandsHelping, faAddressBook, faChartBar, faChartArea, faTachometerAlt, faUsers, faChartLine, faGavel, faCashRegister, faDice, faDesktop, faUsersCog, faDatabase, faAddressCard/*, faBullhorn, faTags*/, faBullseye, faMoneyBillWaveAlt, faCode, faDiceOne, faDiceTwo, faDiceThree, faPuzzlePiece, faStore, faBuilding, faMoneyBillWave, faFlagCheckered, faCartArrowDown, faFootballBall, faVolleyballBall, faBasketballBall, faTableTennis, faBullhorn} from '@fortawesome/free-solid-svg-icons'
import {faBuilding as faBuilding2, faCreditCard, faCalendarCheck} from '@fortawesome/free-regular-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {Empty} from '../Views/Main/Empty'
// -1. Main Dashboard
// import {DashboardWrapper} from '../Views/Main/GeneralSettings/GeneralWrapper'
import {Dashboard} from '../Views/Main/MainDashboard/Dashboard'

// 0. Players
import {PlayerManagementWrapper} from '../Views/Main/PlayerManagement/PlayerManagementWrapper'
import {PlayerCard} from '../Views/Main/PlayerManagement/PlayerCard'
// import {PlayerCardNew} from '../Views/Main/PlayerManagement/PlayerCardNew'
import {WithdrawalRequests} from '../Views/Main/PlayerManagement/WithdrawalRequests'
import {WithdrawalDuplicates} from '../Views/Main/PlayerManagement/WithdrawalDuplicates'
import {AccountStoreRiskAlerts} from '../Views/Main/PlayerManagement/AccountStoreRiskAlerts'
import {AccountStoreRGIssues} from '../Views/Main/PlayerManagement/AccountStoreRGIssues'
import {AccountStoreKYCIssues} from '../Views/Main/PlayerManagement/AccountStoreKYCIssues'
import {AccountStoreGMIssues} from '../Views/Main/PlayerManagement/AccountStoreGMIssues'
import {AccountStoreAllIssues} from '../Views/Main/PlayerManagement/AccountStoreAllIssues'
import {MassManualDeposit} from '../Views/Main/PlayerManagement/MassManualDeposit'
// import {PlayerTags} from '../Views/Main/PlayerManagement/PlayerTags'

// 1. Reports
import {ReportsWrapper} from '../Views/Main/Reports/ReportsWrapper'
// import {Hidden} from '../Views/Main/Reports/Hidden'
import {LevelsSublevels} from '../Views/Main/Reports/LevelsSublevels'
import {PlayersPoints} from '../Views/Main/Reports/PlayersPoints'
import {Settlement} from '../Views/Main/Reports/SettlementReport'
import {SettlementReport} from '../Views/Main/Reports/CoreTransactionsSettleMentReport'
import {GGRRevenueReport} from '../Views/Main/Reports/GGRRevenueReport'
import {GameReportsReal} from '../Views/Main/Reports/GameReportsReal'
// import {GameReportsTest} from '../Views/Main/Reports/GameReportsTest'
import {PlayerReports} from '../Views/Main/Reports/PlayerReports'
import {PlayersReport} from '../Views/Main/Reports/PlayersReport'
import {PlayersRegistrations} from '../Views/Main/Reports/PlayersRegistrations'
import {BonusCampaignReports} from '../Views/Main/Reports/BonusCampaignReports'
import {StorePurchases} from '../Views/Main/Reports/StorePurchases'

import {UsersInformation} from '../Views/Main/Reports/UsersInfo'
import {CashbackMondayOptins} from '../Views/Main/Reports/CashbackMondayOptin'
import {TournamentLeaderboard} from '../Views/Main/Reports/TournamentLeaderboard'
// import {ManualDepositList} from '../Views/Main/Reports/ManualDepositList'
// import {MapSwitzerland} from '../Views/Main/Reports/MapSwitzerland'

// 2. Data Store
import {DataStoreWrapper} from '../Views/Main/DataStore/DataStoreWrapper'
import {PlayerGroups} from '../Views/Main/DataStore/PlayerGroups'

// 3. Game Store
import {GameStoreWrapper} from '../Views/Main/GameStore/GameStoreWrapper'
// import {Bonus} from '../Views/Main/GameStore/Bonus'
// import {BonusCampaign} from '../Views/Main/GameStore/BonusCampaign'
import {BonusWageringRequirement} from '../Views/Main/GameStore/BonusWageringRequirement'
import {GameProviders} from '../Views/Main/GameStore/GameProvidersNew'
// import {RgsGameSuppliers} from '../Views/Main/GameStore/RgsGameSuppliersNew'
// import {AllGames} from '../Views/Main/GameStore/AllGamesNew'
import {GameList} from '../Views/Main/GameStore/GameList'
// import {AllowedGames} from '../Views/Main/GameStore/AllowedGames'
import {LottoBet} from '../Views/Main/GameStore/Lottobet'
import {LottobetReport} from '../Views/Main/GameStore/LottobetReport'
import {GZGLotteries} from '../Views/Main/GameStore/GZGLotteries'
// 4. Odds Store - EMPTY
import {OddsportalWrapper} from '../Views/Main/OddsStore/OddsportalWrapper'
// import {Matches} from '../Views/Main/OddsStore/Matches'
import {TableOfMatches} from '../Views/Main/OddsStore/TableOfMatches'
import {TableOfLeagues} from '../Views/Main/OddsStore/TableOfLeagues'
import {TableOfBookmakers} from '../Views/Main/OddsStore/TableOfBookmakers'
import {TableOfRegions} from '../Views/Main/OddsStore/TableOfRegions'
import {TableOfBetTypes} from '../Views/Main/OddsStore/TableOfBetTypes'
// import {TableOfCompetitors} from '../Views/Main/OddsStore/TableOfCompetitors'
import {TableOfSports} from '../Views/Main/OddsStore/TableOfSports'
import {OddsportalSettings} from '../Views/Main/OddsStore/OddsportalSettings'
// 5. Promo Store
import {PromoStoreWrapper} from '../Views/Main/PromoStore/PromoStoreWrapper'
import {Languages} from '../Views/Main/PromoStore/Languages'
import {Raffles} from '../Views/Main/PromoStore/Raffles'
// import {Shop} from '../Views/Main/PromoStore/Shop'
import {CategoryDetail} from '../Views/Main/PromoStore/CategoryDetail'
import {Achievements} from '../Views/Main/PromoStore/Achievements'
import {Levels} from '../Views/Main/PromoStore/Levels'
import {LevelsNew} from '../Views/Main/PromoStore/LevelsNew'
import {ChangePlayerLevel} from '../Views/Main/PromoStore/ChangePlayerLevel'
// import {RewardsNew} from '../Views/Main/PromoStore/RewardsNew'

import {Missions} from '../Views/Main/PromoStore/Missions'
import {MissionsNew} from '../Views/Main/PromoStore/MissionsNew'
import {Challenges} from '../Views/Main/PromoStore/Challenges'
import {Store} from '../Views/Main/PromoStore/Store'
import {Tournaments} from '../Views/Main/PromoStore/Tournaments'
import {TournamentsOddyzzey} from '../Views/Main/PromoStore/TournamentsOddyzzey'
import {RacesSchedule} from '../Views/Main/PromoStore/RacesSchedule'

import {RewardShop} from '../Views/Main/PromoStore/RewardShop'
import {Rewards} from '../Views/Main/PromoStore/Rewards'
import {RewardShopTransactions} from '../Views/Main/PromoStore/RewardShopTransactions'
import {RewardShopGiveCoins} from '../Views/Main/PromoStore/RewardShopGiveCoins'
import {Banner} from '../Views/Main/PromoStore/Banner'
import {Calendar} from '../Views/Main/PromoStore/Calendar'

import {GamesSelection} from '../Views/Main/PromoStore/GamesSelection'
// import {PlayersSelection} from '../Views/Main/PromoStore/PlayersSelection'

// import {PlayerGroupsRewards} from '../Views/Main/PromoStore/PlayerGroupsRewards'
// import {TournamentsLeaderboards} from '../Views/Main/PromoStore/TournamentsLeaderboards'
// import {InGamePromotion} from '../Views/Main/PromoStore/InGamePromotion'
// import {FreeRoundsBets} from '../Views/Main/PromoStore/FreeRoundsBets'
// import {LoyaltyFreeRoundsBets} from '../Views/Main/PromoStore/LoyaltyFreeRoundsBets'

// 6. Front End Store
import {FrontendStoreWrapper} from '../Views/Main/FrontendStore/FrontendStoreWrapper'
import {SiteSettingsBetToday} from '../Views/Main/FrontendStore/SiteSettingsBetToday'
import {SiteSettings} from '../Views/Main/FrontendStore/SiteSettings'
import {Games} from '../Views/Main/FrontendStore/Games'
// import {FileExport} from '../Views/Main/GameStore/FileExport'
import {FileExport} from '../Views/Main/GameStore/GameListCmsExport'
// 7. Payment Store - EMPTY

// 8. Affiliate/Agent Store - EMPTY

// 9. Risk/Compliance Store
/*
import {RiskComplianceStoreWrapper} from '../Views/Main/RiskComplianceStore/RiskComplianceWrapper'
import {RiskManagement} from '../Views/Main/RiskComplianceStore/RiskManagement'
import {ResponsibleGaming} from '../Views/Main/RiskComplianceStore/ResponsibleGaming'
import {KnowYourCustomer} from '../Views/Main/RiskComplianceStore/KnowYourCustomer'
*/
// 10. Data Store - EMPTY

// 11. User Management
import {UserManagementWrapper} from '../Views/Main/UserManagement/UserManagementWrapper'
import {Log} from '../Views/Main/UserManagement/Log'
import {ManageHolding} from '../Views/Main/UserManagement/ManageHolding'
import {ManageContractualPartners} from '../Views/Main/UserManagement/ManageContractualPartners'
import {ManageSubEntity} from '../Views/Main/UserManagement/ManageSubEntity'
import {ManageAdminUsers} from '../Views/Main/UserManagement/ManageAdminUsers'
import {AssignAdminUsers} from '../Views/Main/UserManagement/AssignAdminUsers'
// 12. Discarded
// import {OperatorOwnGames} from '../Views/Main/FrontendStore/OperatorOwnGames'
// import {TestSitePlayers} from '../Views/Main/FrontendStore/TestSitePlayers'

// 13. Other
import {API} from '../Views/Main/API/APIKeys'

// 14. General Settings
import {GeneralWrapper} from '../Views/Main/GeneralSettings/GeneralWrapper'
import {General} from '../Views/Main/GeneralSettings/General'
import {Currencies} from '../Views/Main/GeneralSettings/Currencies'
import {DeployedProjects} from '../Views/Main/GeneralSettings/DeployedProjects'
// 15. Settings
import {SettingsWrapper} from '../Views/Main/Settings/SettingsWrapper'
import {PlayerSettings} from '../Views/Main/Settings/PlayerSettings'
import {GenericSettings} from '../Views/Main/Settings/GenericSettings'
import {AdminSettings} from '../Views/Main/Settings/AdminSettings'
import {RiskComplianceSettings} from '../Views/Main/Settings/RiskComplianceSettings'
import {GamesSettings} from '../Views/Main/Settings/GamesSettings'
import {PaymentSettings} from '../Views/Main/Settings/PaymentSettings'
import {FrontEndSettings} from '../Views/Main/Settings/FrontEndSettings'
import {GamanzaErrorMessages} from '../Views/Main/Settings/GamanzaErrorMessages'
import {IssuesList} from '../Views/Main/Settings/IssuesList'
import {LoadedResources} from '../Views/Main/Settings/LoadedResources'
import {BonusSettings} from '../Views/Main/Settings/BonusSettings'

// 16. Unlisted components
import {LoginLogin} from '../Views/Login/Login'
import {ForgotPassword} from '../Views/Login/ForgotPassword'
import {RegisterLogin} from '../Views/Login/RegisterLogin'
import {UserSettings} from '../Views/Main/Settings/UserSettings'

// BUILDS
import {IsBuildPasino, IsBuildOdds, IsBuildMyChance, IsBuildScandibet, IsBuildOddyzzey, IsBuildTrekronor, IsBuildBettoday, IsBuildBackend, IsBuildFrontend, IsBuildJackpotsCH, IsBuildDefault} from './Utils'
import { UserInfo } from 'src/Views/Main/Reports/UserInfo'

// PASINO  new section Bonus
import {BonusWrapper} from '../Views/Main/BonusStore/BonusWrapper'
import { FreeGames } from 'src/Views/Main/BonusStore/FreeGames'
import {BonusNew} from 'src/Views/Main/BonusStore/BonusNew'
import {BonusCampaignNew} from 'src/Views/Main/BonusStore/BonusCampaignNew'



/********************************************** *//********************************************** */
/* ROUTER OBJECTS: The object name will be set as menu name, path is the Router Path,
Import the Component.tsx and insert into component, if no menuicon, default will be used,
children will be nested inside main component and also as Sub Menu Objects to the
main item in the menu...same for children of children<-----NOT YET IMPLEMENTED:::DO WE EVEN NEED IT??
*/
// unused1 = Gamanza Admin, unused2 = Gamanza Games Admin, unused7 = Gamanza Magic Hand
type AllowedRolesType = 131|132|11|12|21|22|31|32|33|34|41|42|51|52|61|62|71|72|81|82|91|92|98|99|111|112|113|114
type AllowedRolesType2 = 'unused7'|'unused2'|'unused1'|'administrator'|'gdpr'|'accounting'|'operator'|'support'|'rfSpecialist'|'rgSpecialist'|'marketing'|'unused3'
// 'unused4'|'unused5'|'unused6'|'unused7'|
/* FOR DEVELOPMENT PURPOSES ALWAYS ADD 98 TO THE ALLOWED ROLES ARRAY WHERE YOU ARE ADDING IT,
 * THIS WAY YOU CAN SEE ALL COMPONENTS WHEN YOU LOG IN AS USER 98, PASSWORD 98
*/

function getDataStoreChildren(): any {
    return {
        Player_Groups: {
            key: '_2_1',
            path: '/player-groups',
            menuicon: faUserTag,
            component: PlayerGroups,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
}
/*
function getRiskComplianceChildren(): any {
    return {
        Risk_Management: {
            key: '_11_1',
            path: '/risk-management',
            menuicon: faExclamationTriangle,
            component: RiskManagement,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
        Responsible_Gaming: {
            key: '_11_2',
            path: '/responsible-gaming',
            menuicon: faHandsHelping,
            component: ResponsibleGaming,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        }, 
        Know_Your_Customer: {
            key: '_11_3',
            menuicon: faAddressBook,
            path: '/know-your-customer',
            component: KnowYourCustomer,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
}
*/
function getReportsChildren(): any {
    return IsBuildJackpotsCH() || IsBuildPasino() ?
    {
        GGR_Revenue_Report: {
            key: '_1_1',
            path: '/ggr-revenue-report',
            component: GGRRevenueReport,
            menuicon: faChartBar,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Game_reports___Real: {
            key: '_1_12',
            path: '/game-reports-real',
            component: GameReportsReal,
            menuicon: faChartBar,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Game_reports___Test: {
            key: '_1_13',
            path: '/game-reports-test',
            component: GameReportsReal,
            menuicon: faChartBar,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Player_reports: {
            key: '_1_14',
            path: '/player-reports',
            component: PlayerReports,
            menuicon: faChartBar,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Players: {
            key: '_1_2',
            path: '/players',
            component: PlayersReport,
            menuicon: faUsers,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Players_Registrations: {
            key: '_1_12',
            path: '/players-registrations',
            component: PlayersRegistrations,
            menuicon: faUserPlus,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        KPIs: {
            key: '_1_6',
            path: '/kpis',
            component: Empty,
            menuicon: faKey,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Game_Reports: {
            key: '_1_7',
            path: '/game',
            component: Empty,
            menuicon: faDice,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Gamification_Reports: {
            key: '_1_8',
            path: '/gamification',
            component: Empty,
            menuicon: faDiceD20,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Payment_Reports: {
            key: '_1_9',
            path: '/payment',
            component: Empty,
            menuicon: faCashRegister,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Bonus_Campaign_Reports: {
            key: '_1_10',
            path: '/bonus-campaigns',
            component: BonusCampaignReports,
            menuicon: faAd,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Compliance_Reports: {
            key: '_1_11',
            path: '/compliance',
            component: Empty,
            menuicon: faGavel,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Settlement_Reports: {
            key: '_1_3',
            path: '/settlement',
            component: Empty,
            menuicon: faChartArea,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
/*
        Manual_Deposit_List: {
            key: '_1_12',
            path: '/manual-deposit-list',
            menuicon: faArrowCircleUp,
            component: ManualDepositList,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
*/
/*
        Settlement_Report: {
            key: '_1_3',
            path: '/settlement-report',
            component: SettlementReport,
            menuicon: faChartBar,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
*/
/*
        Core_Transactions: {
            key: '_1_4',
            path: '/core-transactions',
            menuicon: faChartArea,
            component: SettlementReport,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
*/        
/*        
        Map: {
            key: '_1_5',
            path: '/map',
            component: MapSwitzerland,
            menuicon: faGlobeEurope,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        }
*/
    }
    :
    IsBuildBackend() ?
    {
        Report_Levels__Sublevels: {
            key: '_1_0',
            path:'/levels-sublevels',
            menuicon: faChartPie,
            component:LevelsSublevels,
            AllowedRoles: [111,112,113,114,98],
        },

        Report_Players__Points: {
            key: '_1_1',
            path: '/players-points',
            menuicon: faUsers,
            component: PlayersPoints,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Settlement_Report: {
            key: '_1_2',
            path: '/settlement-report',
            menuicon: faChartBar,
            component: SettlementReport,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Core_Transactions: {
            key: '_1_3',
            path: '/core-transactions',
            menuicon: faChartArea,
            component: SettlementReport,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    IsBuildFrontend() ?
    {
/*
        Report_Hidden: {
            key: '_1_0',
            path:'/hidden',
            component:Hidden,
            AllowedRoles: [111,112,113,114,98]
        },
*/
        Report_Levels__Sublevels: {
            key: '_1_0',
            path:'/levels-sublevels',
            component:LevelsSublevels,
            menuicon: faCog,
            AllowedRoles: [111,112,113,114,98],
        },

        Report_Players__Points: {
            key: '_1_1',
            path: '/players-points',
            menuicon: faUsers,
            component: PlayersPoints,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    IsBuildScandibet() ?
    {
/*
        Report_Hidden: {
            key: '_1_0',
            path:'/hidden',
            component:Hidden,
            AllowedRoles: [111,112,113,114,98]
        },
*/

        Report_Levels__Sublevels: {
            key: '_1_0',
            path:'/levels-sublevels',
            component:LevelsSublevels,
            menuicon: faCog,
            AllowedRoles: [111,112,113,114,98],
        },

        Report_Players__Points: {
            key: '_1_1',
            path: '/players-points',
            menuicon: faUsers,
            component: PlayersPoints,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
        Settlement: {
            key: '_1_2',
            path: '/settlement',
            menuicon: faChartBar,
            component: Settlement,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    IsBuildMyChance() ?
    {
        Users_Info: {
            key: '_1_1',
            path:'/users-info',
            component: UsersInformation,
            menuicon: faAddressBook,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
        User_Info: {
            key: '_1_2',
            path:'/user-info',
            component: UserInfo,
            menuicon: faAddressCard,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
        Chances_Leaderboard: {
            key: '_1_3',
            path: '/chances-leaderboard',
            menuicon: faTrophy,
            component: TournamentLeaderboard,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Cashback_Monday_Optins: {
            key: '_1_4',
            path: '/cashback-monday-optins',
            menuicon: faCalendarCheck,
            component: CashbackMondayOptins,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Store_Purchases: {
            key: '_1_5',
            path:'/store-purchases',
            component: StorePurchases,
            menuicon: faCartArrowDown,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
        

        // Report_Levels__Sublevels: {
        //     key: '_1_0',
        //     path:'/levels-sublevels',
        //     component:LevelsSublevels,
        //     menuicon: faCog,
        //     AllowedRoles: [111,112,113,114,98],
        // },

        // Report_Players__Points: {
        //     key: '_1_1',
        //     path: '/players-points',
        //     menuicon: faUsers,
        //     component: PlayersPoints,
        //     AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        // },
    }
    :
    IsBuildOddyzzey() ?
    {
        Report_Levels__Sublevels: {
            key: '_1_0',
            path:'/levels-sublevels',
            component:LevelsSublevels,
            menuicon: faCog,
            AllowedRoles: [111,112,113,114,98],
        },

        Report_Players__Points: {
            key: '_1_1',
            path: '/players-points',
            menuicon: faUsers,
            component: PlayersPoints,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    {
        Report_Levels__Sublevels: {
            key: '_1_0',
            path:'/levels-sublevels',
            component:LevelsSublevels,
            menuicon: faCog,
            AllowedRoles: [111,112,113,114,98],
        },

        Report_Players__Points: {
            key: '_1_1',
            path: '/players-points',
            menuicon: faUsers,
            component: PlayersPoints,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
}

function getGameStoreChildren(): any {
    return IsBuildJackpotsCH() ? {
            Gamingenius_Game_List: {
                key: '_3_1',
                path: '/gamingenius-game-list',
                menuicon: faDatabase,
                component: GameList, // AllGames,
                // AllowedRoles2: ['unused1', 'unused2'],
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },

            // Approved_Games_Store: {
            //     key: '_3_2',
            //     path: '/approved-games-store',
            //     menuicon: faAllergies,
            //     component: GameList, // AllGames,
            //     AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            // },

            /*
            Bonus_Campaign: {
                key: '_3_3',
                path: '/bonus-campaign',
                menuicon: faAd,
                component: BonusCampaign,
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },
            Bonus: {
                key: '_3_4',
                path: '/bonus',
                menuicon: faMoneyBillWave,
                component: Bonus,
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },
            */

            Bonus_Wagering_Req____: {
                key: '_3_7',
                path: '/bonus-wagering-requirement',
                menuicon: faMoneyBillAlt,
                component: BonusWageringRequirement,
                AllowedRoles2: ['unused7'],
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },

            Game_Providers: {
                key: '_3_5',
                path: '/game-providers',
                menuicon: faPaintRoller,
                component: GameProviders,
                // AllowedRoles2: ['unused2', 'unused1'],
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },

            /* RGS_Game_Suppliers: {
                key: '_3_6',
                path: '/rgs-game-suppliers',
                menuicon: faCode,
                component: RgsGameSuppliers,
                // AllowedRoles2: ['unused2', 'unused1'],
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            }, */
        }
    : IsBuildPasino() ? 
    {
        Gamingenius_Game_List: {
            key: '_3_1',
            path: '/gamingenius-game-list',
            menuicon: faDatabase,
            component: GameList,
            // AllowedRoles2: ['unused1', 'unused2'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        // Approved_Games_Store: {
        //     key: '_3_2',
        //     path: '/approved-games-store',
        //     menuicon: faAllergies,
        //     component: GameList,
        //     AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        // },
        /*
        Bonus_Campaign: {
            key: '_3_3',
            path: '/bonus-campaign',
            menuicon: faAd,
            component: BonusCampaign,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Bonus: {
            key: '_3_4',
            path: '/bonus',
            menuicon: faMoneyBillWave,
            component: Bonus,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        */

        Bonus_Wagering_Req____: {
            key: '_3_7',
            path: '/bonus-wagering-requirement',
            menuicon: faMoneyBillAlt,
            component: BonusWageringRequirement,
            AllowedRoles2: ['unused7'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        }, 
        
        Game_Providers: {
            key: '_3_5',
            path: '/game-providers',
            menuicon: faPaintRoller,
            component: GameProviders,
            // AllowedRoles2: ['unused2', 'unused1'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        /*
        RGS_Game_Suppliers: {
            key: '_3_6',
            path: '/rgs-game-suppliers',
            menuicon: faCode,
            component: RgsGameSuppliers,
            // AllowedRoles2: ['unused2', 'unused1'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        */
    } : IsBuildMyChance() ?
        {
            Games_Selection: {
                key: '_3_1',
                path: '/games-selection',
                component: GamesSelection,
                menuicon: faGamepad,
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            }
        }
    :
        {
        // Gamingenius_Game_List: {
        //     key: '_3_1',
        //     path: '/gamingenius-game-list',
        //     menuicon: faDatabase,
        //     component: GameList, // AllGames,
        //     // AllowedRoles2: ['unused1', 'unused2'],
        //     AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        // },

        // Game_Providers: {
        //     key: '_3_5',
        //     path: '/game-providers',
        //     menuicon: faPaintRoller,
        //     component: GameProviders,
        //     AllowedRoles2: ['unused2', 'unused1'],
        //     AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        // },

        /* RGS_Game_Suppliers: {
            key: '_3_6',
            path: '/rgs-game-suppliers',
            menuicon: faCode,
            component: RgsGameSuppliers,

            AllowedRoles2: ['unused2', 'unused1'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        }, */

        LottoBet: {
            key: '_3_21',
            path:'/lottobet',
            component: LottoBet,
            menuicon: faDiceOne,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        LottoBet_Report: {
            key: '_3_22',
            path:'/lottobet-report',
            component: LottobetReport,
            menuicon: faDiceTwo,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        GzG_Lotteries: {
            key: '_3_23',
            path: '/gzg-lotteries',
            component: GZGLotteries,
            menuicon: faDiceThree,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
}


function getBonusChildren(): any {
    return {
        Bonus_Campaign: {
            key: '_14_1',
            path: '/bonus-campaign',
            menuicon: faAd,
            component: BonusCampaignNew,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Bonus_Offers: {
            key: '_14_2',
            path: '/bonus-offers',
            menuicon: faMoneyBillWave,
            component: BonusNew,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Free_Games: {
            key: '_14_3',
            path: '/free-games',
            menuicon: faBullhorn,
            component: FreeGames,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        
    }
}



function getGeneralChildren(): any {
/* IsBuildJackpotsCH() || IsBuildPasino() ? {
        main_Dashboard: {
            key: '_0_2',
            path:'/dashboard',
            component: Dashboard,
            AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,98],
        },
    }
    : */


    return IsBuildOddyzzey() || IsBuildBackend() || IsBuildMyChance() ? {
        General: {
            key: '_0_0',
            path: '/general',
            menuicon: faCogs,
            component: General,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Currencies_Management: {
            key: '_0_1',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component: Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    {
        Currencies_Management: {
            key: '_0_1',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component: Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    }
}

function getPromoStoreChildren(): any {
    // console.log (IsBuildOddyzzey())
    // console.log (IsBuildBackend())
    return IsBuildJackpotsCH() || IsBuildPasino() ?
    {
        Raffles: {
            key: '_5_16',
            path: '/raffles',
            menuicon: faGift,
            component: Raffles,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
/*
        Shop: {
            key: '_5_14',
            path: '/shop',
            menuicon: faShoppingCart, // faStore
            component: Shop,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
*/

/*
        Rewards: {
            key: '_5_7',
            path: '/rewards',
            menuicon: faGifts,
            component: Rewards,
            AllowedRoles2: ['unused7'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Store: {
            key: '_5_13',
            path: '/store',
            menuicon: faShoppingCart, // faStore
            component: Store,
            AllowedRoles2: ['unused7'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Levels: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component:Levels,
            AllowedRoles2: ['unused7'],
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },

        Missions: {
            key: '_5_5',
            path:'/missions',
            component:Missions,
            menuicon: faBullseye,
            AllowedRoles2: ['unused7'],
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },

        Tournaments: {
            key: '_5_6',
            path: '/tournaments',
            menuicon: faTrophy,
            AllowedRoles2: ['unused7'],
            component: TournamentsOddyzzey,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },



        Reward_Shop_Trans____: {
            key: '_5_8',
            path: '/reward-shop-transactions',
            menuicon: faShoppingBasket,
            component: RewardShopTransactions,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Challenges: {
            key: '_5_10',
            path: '/challenges',
            component: Challenges,
            menuicon: faBolt,
            AllowedRoles2: ['unused7'],
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
*/
    }
    : IsBuildMyChance() ?
    {
        Rewards: {
            key: '_5_7',
            path: '/rewards',
            menuicon: faGifts,
            component: Rewards,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Levels: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component: LevelsNew,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Change_Player_Level: {
            key: '_5_4',
            path:'/change-player-level',
            menuicon: faUserCog,
            component: ChangePlayerLevel,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Shop: {
            key: '_5_13',
            path: '/store',
            menuicon: faStore, // faStore
            component: Store,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Chances: {
            key: '_5_5',
            path:'/missions',
            component: MissionsNew,
            menuicon: faCandyCane,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Tournaments: {
            key: '_5_6',
            path: '/tournaments',
            menuicon: faTrophy,
            component: TournamentsOddyzzey,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Races: {
            key: '_5_8',
            path: '/races',
            menuicon: faFlagCheckered,
            component: TournamentsOddyzzey,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        Races_Schedule: {
            key: '_5_17',
            path: '/races-schedule',
            menuicon: faStopwatch,
            component: RacesSchedule,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        
        // Challenges: {
        //     key: '_5_10',
        //     path: '/challenges',
        //     component: Challenges,
        //     menuicon: faBolt,
        //     AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        // },
    }
    : IsBuildOddyzzey() ?
    {
        Rewards: {
            key: '_5_7',
            path: '/rewards',
            menuicon: faGifts,
            component: Rewards,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Store: {
            key: '_5_13',
            path: '/store',
            menuicon: faShoppingCart, // faStore
            component: Store,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Levels: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component:Levels,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Missions: {
            key: '_5_5',
            path:'/missions',
            component:Missions,
            menuicon: faBullseye,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Tournaments: {
            key: '_5_6',
            path: '/tournaments',
            menuicon: faTrophy,
            component: TournamentsOddyzzey,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

/*
        Reward_Shop_Trans____: {
            key: '_5_8',
            path: '/reward-shop-transactions',
            menuicon: faShoppingBasket,
            component: RewardShopTransactions,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
*/
        Challenges: {
            key: '_5_10',
            path: '/challenges',
            component: Challenges,
            menuicon: faBolt,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
/*
        Games_Selection : {
            key: '_5_11',
            path: '/games-selection',
            component: GamesSelection,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Players_Selection : {
            key: '_5_12',
            path: '/players-selection',
            component: PlayersSelection,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            
        },
*/
    }
    : 
    IsBuildBettoday() ?
    {
/*
        Currencies_Management: {
            key: '_5_0',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component:Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Languages_Management: {
            key: '_5_1',
            path:'/languages',
            menuicon: faGlobe,
            component: Languages,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    IsBuildFrontend() ?
    {
/*
        Currencies_Management: {
            key: '_5_0',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component:Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Languages_Management: {
            key: '_5_1',
            path:'/languages',
            menuicon: faGlobe,
            component: Languages,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
  
        Points_Management: {
            key: '_5_3',
            path:'/points',
            menuicon: faPuzzlePiece,
            component: Achievements,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*41,42,*/98],
        },
        
        Levels_Management: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component: Levels,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Calendar: {
            key: '_5_15',
            path: '/calendar',
            menuicon: faCalendarAlt,
            component: Calendar,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Tournaments: {
            key: '_5_6',
            path: '/tournaments',
            component: Tournaments,
            menuicon: faTrophy,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Reward_Shop: {
            key: '_5_7',
            path: '/reward-shop',
            menuicon: faGifts,
            component: RewardShop,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        
        Reward_Shop_Trans____: {
            key: '_5_8',
            path: '/reward-shop-transactions',
            menuicon: faShoppingBasket,
            component: RewardShopTransactions,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
/*
        Reward_Shop_Transfer_Coins: {
            key: '_5_14',
            menuicon: faCoins,
            path: '/reward-shop-transfer-coins',
            component: RewardShopGiveCoins,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
*/
    }
    :
    IsBuildScandibet() ?
    {
/*
        Currencies_Management: {
            key: '_5_0',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component:Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Languages_Management: {
            key: '_5_1',
            path:'/languages',
            menuicon: faGlobe,
            component: Languages,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
  
        Points_Management: {
            key: '_5_3',
            path:'/points',
            menuicon: faPuzzlePiece,
            component: Achievements,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*41,42,*/98],
        },
        
        Levels_Management: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component: Levels,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Calendar: {
            key: '_5_15',
            menuicon: faCalendarAlt,
            path: '/calendar',
            component: Calendar,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Reward_Shop: {
            key: '_5_7',
            path: '/reward-shop',
            menuicon: faGifts,
            component: RewardShop,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },
        
        Reward_Shop_Trans____: {
            key: '_5_8',
            path: '/reward-shop-transactions',
            menuicon: faShoppingBasket,
            component: RewardShopTransactions,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Reward_Shop_Transfer_Coins: {
            key: '_5_14',
            path: '/reward-shop-transfer-coins',
            menuicon: faCoins,
            component: RewardShopGiveCoins,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    {
/*
        Currencies_Management: {
            key: '_5_0',
            path:'/currencies',
            menuicon: faMoneyBillWaveAlt,
            component:Currencies,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Languages_Management: {
            key: '_5_1',
            path:'/languages',
            menuicon: faGlobe,
            component: Languages,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },

        Categories_and_Types: {
            key: '_5_2',
            path:'/category',
            component: CategoryDetail,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },

        Points_Management: {
            key: '_5_3',
            path:'/points',
            menuicon: faPuzzlePiece,
            component: Achievements,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*41,42,*/98],
        },
        
        Levels_Management: {
            key: '_5_4',
            path:'/levels',
            menuicon: faCog,
            component: Levels,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,/*31,32,33,34,41,42,*/98],
        },

        Tournaments: {
            key: '_5_6',
            path: '/tournaments',
            component: Tournaments,
            menuicon: faTrophy,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Reward_Shop: {
            key: '_5_7',
            path: '/reward-shop',
            component: RewardShop,
            AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
        },

        Reward_Shop_Trans____: {
            key: '_5_8',
            path: '/reward-shop-transactions',
            menuicon: faShoppingBasket,
            component: RewardShopTransactions,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },

        Banner_Management: {
            key: '_5_9',
            path:'/banner',
            component: Banner,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    }
}

function getFrontendStoreChildren(): any {
    return IsBuildMyChance() ? {
        Languages_Management: {
            key: '_5_1',
            path:'/languages',
            menuicon: faGlobe,
            component: Languages,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        }
    } : IsBuildBettoday() ? {
        Site_Settings: {
            key: '_6_1',
            path:'/site-settings',
            component: SiteSettingsBetToday,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    } : IsBuildScandibet() ? {
/*
        Games: { // this is only temporary here to debug
            key: '_6_0',
            path:'/games',
            component: Games,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Shop_Settings: {
            key: '_6_1',
            path:'/shop-settings',
            menuicon: faStore,
            component: SiteSettings,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    } : IsBuildTrekronor() ? {
        Site_Settings: {
            key: '_6_1',
            path:'/site-settings',
            component: SiteSettings,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },

        Games: {
            key: '_6_0',
            path:'/games',
            component: Games,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    } :
        {
/*
        Games: {
            key: '_6_0',
            path:'/games',
            component: Games,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
*/
        Shop_Settings: {
            key: '_6_1',
            path:'/shop-settings',
            menuicon: faStore,
            component: SiteSettings,
            AllowedRoles :[11,12,31,32,33,34,111,112,113,114,98],
        },
    }
}

function getUserManagementChildren(): any {
    return IsBuildDefault() ?
    {
        Assign_Admin_Users: {
            key: '_11_5',
            path:'/assign-admin-users',
            menuicon: faUsers,
            component:AssignAdminUsers,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        },

        // Manage_Admin_Users: {
        //     key: '_11_3',
        //     path:'/manage-admin-users',
        //     menuicon: faUsers,
        //     component:ManageAdminUsers,
        // AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        // },

        Log: {
            key: '_11_4',
            menuicon: faListOl,
            path: '/log',
            component: Log,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
    :
    {
        Manage_Masters: {
            key: '_11_0',
            path:'/manage-master',
            menuicon: faUniversity,
            component:ManageHolding,
            // AllowedRoles2: ['unused1'],
            AllowedRoles :[11,12,98],
        },

        Manage_Contractual_P____: {
            key: '_11_1',
            path:'/manage-contractual-partners',
            menuicon: faBuilding,
            component:ManageContractualPartners,
            // AllowedRoles2: ['unused1'],
            AllowedRoles :[131,132,11,12,98],
        },

        Manage_Sub_Entities: {
            key: '_11_2',
            path:'/manage-sub-entities',
            menuicon: faBuilding2,
            component:ManageSubEntity,
            AllowedRoles2: ['unused1'],
            AllowedRoles :[131,132,11,12,21,22,31,32,33,34,61,62,81,82,111,112,113,114,98],
        },

        Manage_Admin_Users: {
            key: '_11_3',
            path:'/manage-admin-users',
            menuicon: faUsers,
            component:ManageAdminUsers,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        },

        Assign_Admin_Users: {
            key: '_11_5',
            path:'/assign-admin-users',
            menuicon: faUsers,
            component:AssignAdminUsers,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        },

        Log: {
            key: '_11_4',
            menuicon: faListOl,
            path: '/log',
            component: Log,
            AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        },
    }
}

export let RouterObjects: {
    [item: string]: {
        path: string,
        component: any,
        AllowedRoles: AllowedRolesType[],
        AllowedRoles2?: AllowedRolesType2[],
        menuicon?: IconDefinition,
        key: string,
        children: { 
            [item :string]: {
                key: string,
                path: string,
                menuicon?: IconDefinition,
                component: any,
                AllowedRoles: AllowedRolesType[],
                AllowedRoles2?: AllowedRolesType2[],
                children? : { 
                    [item:string]: {
                        key: string,
                        path: string,
                        component: any,
                    },
                },
            },
        },
    },
} 
=
// '_' => " ", '__' => "/",  '___' => "-",  '____' => "."
IsBuildJackpotsCH() ? {
    main_Dashboard: {
        key: '_0',
        menuicon: faTachometerAlt,
        path: '/dashboard',
        component: Dashboard,
        AllowedRoles: [131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,98],
        children: {},
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper, // ReportsWrapper,
        // AllowedRoles2: ['unused3'],
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },

    account_Store: {
        key: '_13',
        path: '/account-store',
        menuicon: faUsers,
        component: PlayerManagementWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {
            Player_Card: {
                key: '_13_1',
                path: '/player-card',
                menuicon: faAddressCard,
                component: PlayerCard,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
/*
            Playercard: {
                key: '_13_7',
                path: '/playercard',
                menuicon: faAddressCard,
                component: PlayerCardNew,
                AllowedRoles2: ['unused7'],
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
*/
            Withdrawal_Requests: {
                key: '_13_2',
                path: '/withdrawal-requests',
                menuicon: faArrowCircleDown,
                component: WithdrawalRequests,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Withdrawal_Duplicates: {
                key: '_13_8',
                path: '/withdrawal-duplicates',
                menuicon: faArrowCircleDown,
                component: WithdrawalDuplicates,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Mass_Manual_Deposit: {
                key: '_13_89',
                path: '/mass-manual-deposit',
                menuicon: faFileImport,
                component: MassManualDeposit,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Risk_Alerts: {
                key: '_13_3',
                path: '/risk-alerts',
                menuicon: faExclamationTriangle,
                component: AccountStoreRiskAlerts,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            RG_Issues: {
                key: '_13_4',
                path: '/rg-issues',
                menuicon: faHandsHelping,
                component: AccountStoreRGIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            KYC_Issues: {
                key: '_13_5',
                path: '/kyc-issues',
                menuicon: faAddressBook,
                component: AccountStoreKYCIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            GM_Issues: {
                key: '_13_6',
                path: '/gm-issues',
                menuicon: faDiceD20,
                component: AccountStoreGMIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            All_Issues: {
                key: '_13_7',
                path: '/all-issues',
                menuicon: faTasks,
                component: AccountStoreAllIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
        },
    },

    cRM: {
        key: '_2',
        path: '/crm',
        menuicon: faDatabase,
        component: DataStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        AllowedRoles2: ['unused1'],
        children: getDataStoreChildren()
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {
            Export_to_CMS: {
                key: '_6_1',
                path: '/export-to-cms',
                menuicon: faAllergies,
                component: FileExport,
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },            
        },
    },

    bonus_Section: {
        key: '_14',
        path: '/bonus-section',
        menuicon: faMoneyBillWave,
        component: BonusWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getBonusChildren(),
    },

    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGameStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },
/*
    compliance_Store: {
        key: '_9',
        path: '/compliance-store',
        menuicon: faGavel,
        component: RiskComplianceStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getRiskComplianceChildren(),
    },
*/
/*_Store*/
    payments: {
        key: '_7',
        path: '/payment-store',
        menuicon: faCashRegister,
        component: Empty,
        AllowedRoles2: ['unused1'],
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
/*
    cRM: {
        key: '_2',
        path: '/crm',
        menuicon: faDatabase,
        component: Empty,
        AllowedRoles2: ['unused1'],
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {}, // getDataStoreChildren()
    },
*/
/*
    agent__Affiliate_Store: {
        key: '_8',
        path: '/agent-affiliate-store',
        menuicon: faProjectDiagram,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
*/
    settings: {
        key: '_12',
        path: '/settings',
        menuicon: faCog,
        component: SettingsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {
            Generic_Settings: {
                key: '_12_1',
                menuicon: faCogs,
                path: '/generic-settings',
                // AllowedRoles2: ['unused1'],
                component: GenericSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Admin_Settings: {
                key: '_12_6',
                menuicon: faTv,
                path: '/admin-settings',
                component: AdminSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Player_Settings: {
                key: '_12_2',
                path: '/player-settings',
                menuicon: faUsersCog,
                component: PlayerSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            /*Risk__*/Compliance_Settings: {
                key: '_12_3',
                menuicon: faGavel,
                path: '/risk-compliance-settings',
                component: RiskComplianceSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Games_Settings: {
                key: '_12_4',
                path: '/games-settings',
                menuicon: faDice,
                component: GamesSettings,
                // AllowedRoles2: ['unused2', 'unused1'],
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Site_Settings: {
                key: '_12_5',
                path: '/site-settings',
                menuicon: faDesktop,
                component: FrontEndSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Payment_Settings: {
                key: '_12_6',
                path: '/payment-settings',
                menuicon: faCreditCard,
                component: PaymentSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Bonus_Settings: {
                key: '_12_10',
                path: '/bonus-settings',
                menuicon: faMoneyBillWave,
                component: BonusSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Gamanza_Error_Messages: {
                key: '_12_7',
                path: '/gamanza-error-messages',
                menuicon: faExclamationCircle,
                component: GamanzaErrorMessages,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Issues_List: {
                key: '_12_8',
                path: '/issues-list',
                menuicon: faTasks,
                component: IssuesList,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Loaded_Resources: {
                key: '_12_9',
                path: '/loaded-resources',
                menuicon: faTasks,
                component: LoadedResources,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
                AllowedRoles2: ['unused1'],
            },

        }, 
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
: 
IsBuildPasino() ? {
    main_Dashboard: {
        key: '_0',
        menuicon: faTachometerAlt,
        path: '/dashboard',
        component: Dashboard,
        AllowedRoles: [131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,98],
        children: {},
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper, // ReportsWrapper,
        // AllowedRoles2: ['unused3'],
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },

    account_Store: {
        key: '_13',
        path: '/account-store',
        menuicon: faUsers,
        component: PlayerManagementWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {
            Player_Card: {
                key: '_13_1',
                path: '/player-card',
                menuicon: faAddressCard,
                component: PlayerCard,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
/*
            Playercard: {
                key: '_13_7',
                path: '/playercard',
                menuicon: faAddressCard,
                component: PlayerCardNew,
                AllowedRoles2: ['unused7'],
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
*/
            Withdrawal_Requests: {
                key: '_13_2',
                path: '/withdrawal-requests',
                menuicon: faArrowCircleDown,
                component: WithdrawalRequests,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Withdrawal_Duplicates: {
                key: '_13_8',
                path: '/withdrawal-duplicates',
                menuicon: faArrowCircleDown,
                component: WithdrawalDuplicates,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Mass_Manual_Deposit: {
                key: '_13_89',
                path: '/mass-manual-deposit',
                menuicon: faFileImport,
                component: MassManualDeposit,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Risk_Alerts: {
                key: '_13_3',
                path: '/risk-alerts',
                menuicon: faExclamationTriangle,
                component: AccountStoreRiskAlerts,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            RG_Issues: {
                key: '_13_4',
                path: '/rg-issues',
                menuicon: faHandsHelping,
                component: AccountStoreRGIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            KYC_Issues: {
                key: '_13_5',
                path: '/kyc-issues',
                menuicon: faAddressBook,
                component: AccountStoreKYCIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            GM_Issues: {
                key: '_13_6',
                path: '/gm-issues',
                menuicon: faDiceD20,
                component: AccountStoreGMIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            All_Issues: {
                key: '_13_7',
                path: '/all-issues',
                menuicon: faTasks,
                component: AccountStoreAllIssues,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
        },
    },

    cRM: {
        key: '_2',
        path: '/crm',
        menuicon: faDatabase,
        component: DataStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        AllowedRoles2: ['unused1'],
        children: getDataStoreChildren()
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {
            Export_to_CMS: {
                key: '_6_1',
                path: '/export-to-cms',
                menuicon: faAllergies,
                component: FileExport,
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            },            
        },
    },

    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGameStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },
/*
    compliance_Store: {
        key: '_9',
        path: '/compliance-store',
        menuicon: faGavel,
        component: RiskComplianceStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getRiskComplianceChildren(),
    },
*/
/*_Store*/
    payments: {
        key: '_7',
        path: '/payment-store',
        menuicon: faCashRegister,
        component: Empty,
        AllowedRoles2: ['unused1'],
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
/*
    cRM: {
        key: '_2',
        path: '/crm',
        menuicon: faDatabase,
        component: Empty,
        AllowedRoles2: ['unused1'],
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {}, // getDataStoreChildren()
    },
*/
/*
    agent__Affiliate_Store: {
        key: '_8',
        path: '/agent-affiliate-store',
        menuicon: faProjectDiagram,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
*/
    settings: {
        key: '_12',
        path: '/settings',
        menuicon: faCog,
        component: SettingsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {
            Generic_Settings: {
                key: '_12_1',
                menuicon: faCogs,
                path: '/generic-settings',
                // AllowedRoles2: ['unused1'],
                component: GenericSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Admin_Settings: {
                key: '_12_6',
                menuicon: faTv,
                path: '/admin-settings',
                component: AdminSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Player_Settings: {
                key: '_12_2',
                path: '/player-settings',
                menuicon: faUsersCog,
                component: PlayerSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            /*Risk__*/Compliance_Settings: {
                key: '_12_3',
                menuicon: faGavel,
                path: '/risk-compliance-settings',
                component: RiskComplianceSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Games_Settings: {
                key: '_12_4',
                path: '/games-settings',
                menuicon: faDice,
                component: GamesSettings,
                // AllowedRoles2: ['unused2', 'unused1'],
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Site_Settings: {
                key: '_12_5',
                path: '/site-settings',
                menuicon: faDesktop,
                component: FrontEndSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Payment_Settings: {
                key: '_12_6',
                path: '/payment-settings',
                menuicon: faCreditCard,
                component: PaymentSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Bonus_Settings: {
                key: '_12_10',
                path: '/bonus-settings',
                menuicon: faMoneyBillWave,
                component: BonusSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Gamanza_Error_Messages: {
                key: '_12_7',
                path: '/gamanza-error-messages',
                menuicon: faExclamationCircle,
                component: GamanzaErrorMessages,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
            Issues_List: {
                key: '_12_8',
                path: '/issues-list',
                menuicon: faTasks,
                component: IssuesList,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Loaded_Resources: {
                key: '_12_9',
                path: '/loaded-resources',
                menuicon: faTasks,
                component: LoadedResources,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
                AllowedRoles2: ['unused1'],
            },

        }, 
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
} :
IsBuildBackend() ? {
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },

    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGameStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    cRM: {
        key: '_2',
        path: '/crm',
        menuicon: faDatabase,
        component: DataStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getDataStoreChildren(),
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
        // children: getFrontendStoreChildren(),
    },

    payment_Store: {
        key: '_7',
        path: '/payment-store',
        menuicon: faCashRegister,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    agent__Affiliate_Store: {
        key: '_8',
        path: '/agent-affiliate-store',
        menuicon: faProjectDiagram,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    compliance_Store: {
        key: '_9',
        path: '/compliance-store',
        menuicon: faGavel,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
/*
    cRM: {
        key: '_10',
        path: '/data-store',
        menuicon: 'gfont-data-store gfont-big',
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },
*/
    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '_96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '_98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '_99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
:
IsBuildFrontend() ? {
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },

    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children : getGameStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    payment_Store: {
        key: '_7',
        path: '/payment-store',
        menuicon: faCashRegister,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    agent__Affiliate_Store: {
        key: '_8',
        path: '/agent-affiliate-store',
        menuicon: faProjectDiagram,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    compliance_Store: {
        key: '_9',
        path: '/compliance-store',
        menuicon: faGavel,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    cRM: {
        key: '_10',
        path: '/data-store',
        menuicon: faDatabase,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: {},
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
:
IsBuildMyChance() ? {
/*
        reports: {
            key: '_1',
            path: '/reports',
            menuicon: faChartLine,
            component: ReportsWrapper,
            AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
            children: getReportsChildren()
        },
*/
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    game_Store : {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGameStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles2: ['unused3'],
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
:
IsBuildOddyzzey() ? {
/*
        reports: {
            key: '_1',
            path: '/reports',
            menuicon: faChartLine,
            component: ReportsWrapper,
            AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
            children: getReportsChildren()
        },
*/
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
:
IsBuildTrekronor() ? {
/*
    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren()
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },
*/
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },

}
:
IsBuildScandibet() ? {
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(),
    },
/*
    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children : getGameStoreChildren()
    },
*/
    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },

}
:
IsBuildBettoday() ? {
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },

    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    gamification: {
        key: '_5',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    API: {
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
:
IsBuildOdds() ? {
    oddsportal: {
        key: '_13',
        path: '/oddsportal',
        menuicon: faBullseye,
        component: OddsportalWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: {
            Settings: {
                key: '_13_6',
                path:'/settings',
                menuicon: faCog,
                component:OddsportalSettings,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },

            Sports_preview: {
                key: '_13_5',
                path:'/table-of-sports',
                menuicon: faTableTennis,
                component:TableOfSports,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
            Regions_preview: {
                key: '_13_0',
                path:'/table-of-regions',
                menuicon: faGlobeEurope,
                component:TableOfRegions,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
            Leagues__Import: {
                key: '_13_1',
                path:'/table-of-leagues',
                menuicon: faFootballBall,
                component:TableOfLeagues,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
            Matches__Results: {
                key: '_13_2',
                path:'/table-of-matches',
                menuicon: faFutbol,
                component:TableOfMatches,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
            Bookmakers_preview: {
                key: '_13_3',
                path:'/table-of-bookmakers',
                menuicon: faVolleyballBall,
                component:TableOfBookmakers,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
            Bet_types_preview: {
                key: '_13_4',
                path:'/table-of-bet-types',
                menuicon: faBasketballBall,
                component:TableOfBetTypes,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
            },
/*
            Competitor__History: {
                key: '_13_6',
                path:'/table-of-competitors',
                menuicon: faHockeyPuck,
                component:TableOfCompetitors,
                // AllowedRoles2: ['unused1'],
                AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,98],
            },
*/
        },
    },

    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },

    /* UNLISTED COMPONENTS */
    login: {
        key: '96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
} :
{
/*
    general_Settings: {
        key: '_0',
        path: '/general',
        menuicon: faWrench,
        component: GeneralWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getGeneralChildren(), 
    },
*/
/*
    reports: {
        key: '_1',
        path: '/reports',
        menuicon: faChartLine,
        component: ReportsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: getReportsChildren(), 
    },
*/
    deployed_projects: {
        key: '_95',
        path: '/deployed-projects',
        menuicon: faRocket,
        component: DeployedProjects,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {}
/*
        children : {
            Gamingenius_Game_List: {
                key: '_3_1',
                path: '/gamingenius-game-list',
                menuicon: faDatabase,
                component: AllGames,
                AllowedRoles2: ['unused1', 'unused2'],
                AllowedRoles: [21,22,31,32,33,34,111,112,113,114,98],
            }
        }
*/
    },

    game_Store: {
        key: '_3',
        path: '/game-store',
        menuicon: faDice,
        component: GameStoreWrapper,
        AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
        children : getGameStoreChildren(),
    },

/*
    gamification: {
        key: '_3',
        path: '/promo-store',
        menuicon: faDiceD20,
        component: PromoStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getPromoStoreChildren(),
    },
*/
/*
    front___End_Store: {
        key: '_6',
        path: '/front-end-store',
        menuicon: faDesktop,
        component: FrontendStoreWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,41,42,98,111,112,113,114],
        children: getFrontendStoreChildren(),
    },

    settings: {
        key: '_12',
        path: '/settings',
        menuicon: faCog,
        component: SettingsWrapper,
        AllowedRoles :[11,12,21,22,31,32,33,34,111,112,113,114,98],
        children: {

            Generic_Settings: {
                key: '_12_1',
                menuicon: faCogs,
                path: '/generic-settings',
                // AllowedRoles2: ['unused1'],
                component: GenericSettings,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },
*/
/*
            Games_Settings: {
                key: '_12_4',
                path: '/games-settings',
                menuicon: faDice,
                component: GamesSettings,
                // AllowedRoles2: ['unused2', 'unused1'],
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Gamanza_Error_Messages: {
                key: '_12_7',
                path: '/gamanza-error-messages',
                menuicon: faExclamationCircle,
                component: GamanzaErrorMessages,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

            Issues_List: {
                key: '_12_8',
                path: '/issues-list',
                menuicon: faTasks,
                component: IssuesList,
                AllowedRoles: [11,12,21,22,31,32,33,34,111,112,113,114,98],
            },

        }
    },
*/
    user_Management: {
        key: '_11',
        path: '/user-management',
        menuicon: faUserTie,
        component: UserManagementWrapper,
        AllowedRoles :[131,132,11,12,21,22,31,32,33,34,41,42,51,52,61,62,71,72,81,82,91,92,111,112,113,114,/*121,122,*/98],
        children: getUserManagementChildren(),
    },
/*
    API:{
        key:'_100_',
        path:'/API',
        component:API,
        menuicon: faCode,
        AllowedRoles:[11],
        children:{},
    },
*/
    /* UNLISTED COMPONENTS */
    login: {
        key: '_96',
        path: '/login',
        component: LoginLogin,
        children: {},
        AllowedRoles: [99],
    },

    userSettings: {
        key: '_97',
        path: '/user-settings',
        component: UserSettings,
        children: {},
        AllowedRoles: [99],
    },

    registerLogin: {
        key: '_98',
        path: '/register-login',
        component: RegisterLogin,
        children: {},
        AllowedRoles: [99],
    },

    forgotPassword: {
        key: '_99',
        path:'/forgot-password',
        component: ForgotPassword,
        children:{},
        AllowedRoles:[99],
    },
}
