import * as React from 'react'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import * as Assets_ from '../../Assets_'
import {ButtonIcon} from '../../Reusables/Button'
import {FormInput, FormInputNumber} from '../../Reusables/Inputs'
import {BonusNew, CategoryContribution, BonusRules} from 'src/Logic/Database/DatabaseData'
import {Logic} from '../../../Logic/Type'
import { faMoneyBillWave, faTimes, faRedo, faListOl, faCheck, faPercentage, faCode, faDice, faUserCheck, faGamepad, faInfinity, faSave, faCalendarCheck, faCalendarTimes, faClock, faAsterisk, faTag } from '@fortawesome/free-solid-svg-icons'
import {bonusType, triggerType, conditionRule, conditionOperator, wageringType, timeUnits, verificationType} from '../../../Logic/BonusStore/BonusStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-toggle-switch'
import * as base64 from 'base-64'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import ManageGamesPopup from './ManageGamesPopup'
import ManageBonusesPopup from './ManageBonusesPopup'
import ReactTable from 'react-table'
import {gotoTop} from '../../../Logic/Utils'
import * as TableUtils from '../../Reusables/TableUtils'

export function BonusNew() {
    // DbRoutes.wsReinitialize()

    if (!Main.Instance.BonusStateNew.bonus) {
        setInitialValues()
    }
    // console.log(Main.Instance.BonusStateNew.bonus)
    return (
        <div>
            <div className = "clearfix">
                <h3 className = "level fl">
                    {Assets.AddEditBonus}
                </h3>
            </div>
            <div className = "clearfix"/>
            {Main.Instance.BonusStateNew.selectGames && Main.Instance.BonusStateNew.selectGames !== '-1' ? <ManageGamesPopup bonusType={Main.Instance.BonusStateNew.selectGames}/>: void 0}
            {Main.Instance.BonusStateNew.manageDependantBonuses ? <ManageBonusesPopup />: void 0}
            {Main.Instance.BonusStateNew.termsConditionsPopup ? termsConditionsPopup() : void 0}
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {Main.Instance.BonusStateNew.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >
                    <div className = "create-input">
                        <div className = "row admin-users-row" style = {{position: 'relative', display: 'flex'}}>
                            <h4>{Main.Instance.BonusStateNew.mode ? Assets.EditTheBonus + ': ' + Main.Instance.BonusStateNew.bonus.bonusName  : Assets.AddANewBonus}</h4>

                            {Main.Instance.BonusStateNew.mode ? 
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => gotoInsertMode()}
                                ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                                icon = {Assets_.faCancelButtonClass}
                            /> : <span/>}
                            <button
                                style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.manageWageringContribution && Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.gameCategoryContribution && Main.Instance.BonusStateNew.bonus.gameCategoryContribution.length > 0 ? '#d6edd6' : ''}}
                                title = {Assets.Manage + ' ' + Assets.WageringContribution.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.manageWageringContribution ? 'dbl fr btn btn-sm btn-success mr mb5' : 'dbl fr btn btn-sm btn-default mr mb5'}
                                onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageWageringContribution: !Main.Instance.BonusStateNew.manageWageringContribution})}
                            >
                                {Assets.WageringContribution}
                            </button>
                            <button
                                disabled = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.triggerType && Main.Instance.BonusStateNew.bonus.triggerType === triggerType[6] || !Main.Instance.BonusStateNew.bonus.triggerType}
                                style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.manageBonusCodes && Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes && Main.Instance.BonusStateNew.bonus.bonusCodes.length > 0 ? '#d6edd6' : ''}}
                                title = {Assets.Manage + ' ' + Assets.BonusCode_s.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.manageBonusCodes ? 'dbl fr btn btn-sm btn-success mr mb5' : 'dbl fr btn btn-sm btn-default mr mb5'}
                                onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageBonusCodes: !Main.Instance.BonusStateNew.manageBonusCodes})}
                            >
                                {Assets.BonusCode_s}
                            </button>
                            <button
                                style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.setBonusValidity && Main.Instance.BonusStateNew.bonus && isValiditySet(Main.Instance.BonusStateNew.bonus) ? '#d6edd6' : ''}}
                                title = {Assets.Manage + ' ' + Assets.BonusCode_s.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.setBonusValidity ? 'dbl fr btn btn-sm btn-success mr mb5' : 'dbl fr btn btn-sm btn-default mr mb5'}
                                onClick = {() => {
                                    const bonus = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                                    bonus.timeFromEventUnit = !bonus.timeFromEventUnit ? timeUnits[0] : bonus.timeFromEventUnit
                                    bonus.timeToConsumeUnit = !bonus.timeToConsumeUnit ? timeUnits[0] : bonus.timeToConsumeUnit
                                    bonus.timeFromEventValue = !bonus.timeFromEventValue ? 0 : bonus.timeFromEventValue
                                    bonus.timeToConsumeValue = !bonus.timeToConsumeValue ? 0 : bonus.timeToConsumeValue
                                    bonus.timeToConsumeFreeGamesUnit = !bonus.timeToConsumeFreeGamesUnit ? timeUnits[0] : bonus.timeToConsumeFreeGamesUnit
                                    bonus.timeToConsumeFreeGamesValue = !bonus.timeToConsumeFreeGamesValue ? 0 : bonus.timeToConsumeFreeGamesValue
                                    bonus.timeToActivateUnit = !bonus.timeToActivateUnit ? timeUnits[0] : bonus.timeToActivateUnit
                                    bonus.timeToActivateValue = !bonus.timeToActivateValue ? 0 : bonus.timeToActivateValue
                                    Logic.Type.New(Main.Instance.BonusStateNew, {setBonusValidity: !Main.Instance.BonusStateNew.setBonusValidity, bonus})
                                }}
                            >
                                {Assets.Validity}
                            </button>
                            <button
                                style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.manageTags && Main.Instance.BonusStateNew.bonus && (Main.Instance.BonusStateNew.bonus.includedTags && Main.Instance.BonusStateNew.bonus.includedTags.length > 0 || Main.Instance.BonusStateNew.bonus.excludedTags && Main.Instance.BonusStateNew.bonus.excludedTags.length > 0) ? '#d6edd6' : ''}}
                                title = {Assets.Manage + ' ' + Assets.Tags.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.manageTags ? 'dbl fr btn btn-sm btn-success mr0 mb5' : 'dbl fr btn btn-sm btn-default mr0 mb5'}
                                onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageTags: !Main.Instance.BonusStateNew.manageTags})}
                            >
                                {Assets.Tags}
                            </button>
                        </div>
                        <div className = "row admin-users-row" style = {{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                            <FormInput
                                description = {Assets.Bonus + ' ' + Assets.Name.toLowerCase()}
                                narrow = {true}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusName ? Main.Instance.BonusStateNew.bonus.bonusName : ''} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => updateText(text, 'bonusName')} 
                                placeholder = {Assets.Bonus + ' ' + Assets.Name.toLowerCase()}
                                Icon = {faMoneyBillWave}
                            />
                            <select
                                title = {Assets.Select + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.Type.toLowerCase()}
                                style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                                className = "fl dbl form-control"
                                onChange = {e => {updateText(e.target.value, 'bonusType')}}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusType ? Main.Instance.BonusStateNew.bonus.bonusType : '-1'}
                            >
                                {getOptions('bonusType')}
                            </select>
                            <select
                                disabled = {true}
                                title = {Assets.Select + ' ' + Assets.Currency.toLowerCase()}
                                style = {{width: '70px', height: '30px', marginRight: '10px'}}
                                className = "fl dbl form-control"
                                onChange = {e => {updateText(e.target.value, 'defaultCurrency')}}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.defaultCurrency ? Main.Instance.BonusStateNew.bonus.defaultCurrency : 'CHF'}
                            >
                                {getCurrencyOptions()}
                            </select>
                            <FormInputNumber
                                description = {Assets.Bonus + ' ' + Assets.Priority.toLowerCase()}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.priority ? Main.Instance.BonusStateNew.bonus.priority.toString() : ''} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => updateText(+text, 'priority')} 
                                min = "0"
                                iconText = {Assets.Priority}
                                iconTextS  = "12px"
                            />
                            {Main.Instance.BonusStateNew.bonus.bonusType === bonusType[0] || Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] ?
                            <div className = "fl dbl mb5">
                                <ButtonIcon 
                                    disabled = {!Main.Instance.BonusStateNew.bonus || !Main.Instance.BonusStateNew.bonus.bonusType || Main.Instance.BonusStateNew.bonus.bonusType === '-1'}
                                    description = {Assets.IncludeGames} 
                                    click = {() => {
                                        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                                        if (bonus.bonusGameExclude && bonus.bonusGameExclude.length > 0) {
                                            if (confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.excludedGames)) {
                                                bonus.bonusGameExclude = []
                                                Logic.Type.New(Main.Instance.BonusStateNew, {selectGames: 'include', bonus})
                                            }
                                        } else {
                                            Logic.Type.New(Main.Instance.BonusStateNew, {selectGames: 'include', bonus})
                                        }
                                        
                                    }}
                                    ClassName = {Main.Instance.BonusStateNew.bonus.bonusGameInclude && Main.Instance.BonusStateNew.bonus.bonusGameInclude.length > 0 ? 'btn btn-success btn-sm btn-plus mr0' : 'btn btn-default btn-sm btn-plus mr0'}
                                    Icon = {faDice as IconDefinition}
                                    text = {Assets.IncludeGames}
                                />
                                <ButtonIcon 
                                    disabled = {!Main.Instance.BonusStateNew.bonus || !Main.Instance.BonusStateNew.bonus.bonusType || Main.Instance.BonusStateNew.bonus.bonusType === '-1'}
                                    description = {Assets.ExcludeGames} 
                                    click = {() => {
                                        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                                        if (bonus.bonusGameInclude && bonus.bonusGameInclude.length > 0) {
                                            if (confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.includedGames)) {
                                                bonus.bonusGameInclude = []
                                                Logic.Type.New(Main.Instance.BonusStateNew, {selectGames: 'exclude', bonus})
                                            }
                                        } else {
                                            Logic.Type.New(Main.Instance.BonusStateNew, {selectGames: 'exclude', bonus})
                                        }
                                        
                                    }}
                                    ClassName = {Main.Instance.BonusStateNew.bonus.bonusGameExclude && Main.Instance.BonusStateNew.bonus.bonusGameExclude.length > 0 ? 'btn btn-success btn-sm btn-plus mr' : 'btn btn-default btn-sm btn-plus mr'}
                                    Icon = {faDice as IconDefinition}
                                    text = {Assets.ExcludeGames}
                                />
                            </div> : void 0}
                            {Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] ?
                            <div className = "fl dbl mb5">
                                <ButtonIcon 
                                    disabled = {!Main.Instance.BonusStateNew.bonus || !Main.Instance.BonusStateNew.bonus.bonusType || Main.Instance.BonusStateNew.bonus.bonusType === '-1'}
                                    description = {Assets.SelectGame_s} 
                                    click = {() => Logic.Type.New(Main.Instance.BonusStateNew, {selectGames: 'free'})}
                                    ClassName = {Main.Instance.BonusStateNew.selectGames === 'free' ? 'btn btn-success btn-sm btn-plus mr' : 'btn btn-default btn-sm btn-plus mr'}
                                    Icon = {faDice as IconDefinition}
                                    text = {Assets.SelectGame_s}
                                />
                            </div> : void 0}
                            
                            <select
                                disabled = {!Main.Instance.BonusStateNew.bonus || !Main.Instance.BonusStateNew.bonus.bonusType || Main.Instance.BonusStateNew.bonus.bonusType === '-1'}
                                title = {Assets.Select + ' ' + Assets.TriggerType.toLowerCase()}
                                style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                                className = "fl dbl form-control"
                                onChange = {e => {updateText(e.target.value, 'triggerType')}}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.triggerType ? Main.Instance.BonusStateNew.bonus.triggerType : '-1'}
                            >
                                {getOptions('triggerType')}
                            </select>
                            
                            {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.triggerType && Main.Instance.BonusStateNew.bonus.triggerType !== '-1' ? getConditionSettings()
                             : void 0}
                        </div>
                        
                        <div className = "row admin-users-row" style = {{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                            {Main.Instance.BonusStateNew.bonus.triggerType && Main.Instance.BonusStateNew.bonus.triggerType !== triggerType[6] ?
                                <div className = "fl dbl mb5" title={Assets.Manage + ' ' + Assets.Dependant + ' ' + Assets.Bonus_es}>
                                    <button 
                                        onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageDependantBonuses: true})}
                                        className = {Main.Instance.BonusStateNew.manageDependantBonuses ? 'btn btn-primary btn-sm mr mb5 fl dibl' : 'btn btn-default btn-sm mr mb5 fl dibl'}
                                    >
                                        {Assets.Manage + ' ' + Assets.Bonus_es}
                                    </button>
                                </div> : void 0}
                            <select
                                key = "-1"
                                title = {Assets.Select + ' ' + Assets.WageringType.toLowerCase()}
                                style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                                className = "fl dbl form-control"
                                onChange = {e => {updateText(e.target.value, 'wageringType')}}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.wageringType ? Main.Instance.BonusStateNew.bonus.wageringType : '-1'}
                            >
                                {getOptions('wageringType')}
                            </select>
                            {Main.Instance.BonusStateNew.bonus.bonusType === bonusType[0] ?
                            <FormInputNumber
                                placeholder = {Assets.Max + ' ' + Assets.Amount}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.maxGrantedAmount ? Main.Instance.BonusStateNew.bonus.maxGrantedAmount.toString() : ''} 
                                outerDiv = "rows-per-table input-group fl mr pl0 mb5"
                                ClassName = "h30 w100px pl7 form-control"
                                type = "number" 
                                onChange = {(text) => {updateText(+text, 'maxGrantedAmount')}}
                                min = "0"
                                Icon = {faMoneyBillWave}
                                description = {Assets.Max + ' ' + Assets.Granted + ' ' + Assets.Amount}
                            /> : void 0}
                            <FormInputNumber
                                placeholder = {Assets.Max + ' ' + Assets.Count}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.maxGrantedCount ? Main.Instance.BonusStateNew.bonus.maxGrantedCount.toString() : ''} 
                                outerDiv = "rows-per-table input-group fl mr pl0 mb5"
                                ClassName = "h30 w100px pl7 form-control"
                                type = "number" 
                                onChange = {(text) => {updateText(+text, 'maxGrantedCount')}}
                                min = "0"
                                Icon = {faListOl}
                                description = {Assets.Max + ' ' + Assets.Granted + ' ' + Assets.Count}
                            />
                            <FormInputNumber
                                placeholder = {Assets.Times + ' ' + Assets.AssignedTo.toLowerCase()}
                                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.repeat ? Main.Instance.BonusStateNew.bonus.repeat.toString() : ''} 
                                outerDiv = "rows-per-table input-group fl mr pl0 mb5"
                                ClassName = "h30 w100px pl7 form-control"
                                type = "number" 
                                onChange = {(text) => {updateText(+text, 'repeat')}}
                                min = "1"
                                Icon = {faRedo}
                                description = {Assets.Times + ' ' + Assets.AssignedTo.toLowerCase() + ' ' + Assets.Player.toLowerCase()}
                                disabled = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.triggerType && (Main.Instance.BonusStateNew.bonus.triggerType === triggerType[0] || Main.Instance.BonusStateNew.bonus.triggerType === triggerType[1] || Main.Instance.BonusStateNew.bonus.triggerType === triggerType[2])}

                            />
                            
                        </div>
                        <div className = "row admin-users-row" style = {{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                            <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Active}:</div>
                            </div>
                            <Switch
                                className = "fl dbl mt2 mr mb5"
                                onClick = {() => {updateBoolean('active')}}
                                on = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.active}
                            />
                            <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.OptIn + ' ' + Assets.Required.toLowerCase()}:</div>
                            </div>
                            <Switch
                                className = "fl dbl mt2 mr mb5"
                                onClick = {() => {updateBoolean('optIn')}}
                                on = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.optIn}
                            />
                            <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.All + ' ' + Assets.Players.toLowerCase() + ' ' + Assets.Eligible}:</div>
                            </div>
                            <Switch
                                className = "fl dbl mt2 mr mb5"
                                onClick = {() => {updateBoolean('allPlayersEligible')}}
                                on = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.allPlayersEligible}
                            />
                            <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Reusable + ' ' + Assets.BonusCode_s}:</div>
                            </div>
                            <Switch
                                className = "fl dbl mt2 mr mb5"
                                onClick = {() => {updateBoolean('reusableCodes')}}
                                on = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.reusableCodes}
                            />
                            <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Show + ' ' + Assets.TermsAndConditions }:</div>
                            </div>
                            <Switch
                                className = "fl dbl mt2 mr mb5"
                                onClick = {() => {updateBoolean('showTc')}}
                                on = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.showTc}
                            />
                            
                            {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.showTc ?
                            <ButtonIcon 
                                description = {Assets.Edit + ' ' + Assets.TermsAndConditions.toLowerCase()} 
                                click = {() => Logic.Type.New(Main.Instance.BonusStateNew, {termsConditionsPopup: true})}
                                ClassName = {'btn btn-default btn-sm btn-plus mr pl0 fl'}
                                text = {Assets.Edit + ' ' + Assets.TC}
                            /> : void 0}
                        </div>
                       
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <ButtonIcon 
                                description = {Main.Instance.BonusStateNew.mode ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => handleInsertUpdate()}
                                ClassName = {Main.Instance.BonusStateNew.mode ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>
                        
                    </div>
                </div>
                {Main.Instance.BonusStateNew.manageWageringContribution ?
                <div className="mt5">
                    <div className = "row admin-users-container" >
                        <div
                            className={Main.Instance.BonusStateNew.mode ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
                            }
                            style={{paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexWrap: 'wrap'}}
                        >
                            <button
                                style = {{fontSize: '13px'}}
                                title = {Assets.Manage + ' ' + Assets.WageringContribution.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.manageWageringContribution ? 'dbl fl btn btn-sm btn-success mr mb5' : 'dbl fl btn btn-sm btn-default mr mb5'}
                                onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageWageringContribution: !Main.Instance.BonusStateNew.manageWageringContribution})}
                            >
                                {Assets.WageringContribution}
                            </button>
                            {getWageringRequirements()}
                        </div>
                    </div>
                </div> : void 0}
                {Main.Instance.BonusStateNew.manageBonusCodes && Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.triggerType && Main.Instance.BonusStateNew.bonus.triggerType !== triggerType[6] ?
                <div className="mt5">
                    <div className = "row admin-users-container clearfix" >
                        <div
                            className={Main.Instance.BonusStateNew.mode ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
                            }
                            style={{paddingTop: '10px', paddingBottom: '10px'}}
                        >
                            <div className = "row admin-users-row clearfix" style = {{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                            <button
                                style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.manageBonusCodes && Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes && Main.Instance.BonusStateNew.bonus.bonusCodes.length > 0 ? '#d6edd6' : ''}}
                                title = {Assets.Manage + ' ' + Assets.BonusCode_s.toLowerCase()}
                                className = {Main.Instance.BonusStateNew.manageBonusCodes ? 'dbl fl btn btn-sm btn-success mr mb5' : 'dbl fl btn btn-sm btn-default mr mb5'}
                                onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageBonusCodes: !Main.Instance.BonusStateNew.manageBonusCodes})}
                            >
                                {Assets.BonusCode_s}
                            </button>
                            {getBonusCodes()}
                            </div>
                        </div>
                    </div>
                </div> : void 0}
                {Main.Instance.BonusStateNew.setBonusValidity ?
                <div className="mt5">
                    <div className = "row admin-users-container clearfix" >
                        <div
                            className={Main.Instance.BonusStateNew.mode ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
                            }
                            style={{paddingTop: '10px', paddingBottom: '10px'}}
                        >
                            <div className = "row admin-users-row clearfix" style = {{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                                <button
                                    style = {{fontSize: '13px'}}
                                    title = {Assets.Manage + ' ' + Assets.BonusCode_s.toLowerCase()}
                                    className = {Main.Instance.BonusStateNew.setBonusValidity ? 'dbl fl btn btn-sm btn-success mr mb5' : 'dbl fl btn btn-sm btn-default mr mb5'}
                                    onClick = {() => {
                                        const bonus = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                                        bonus.timeFromEventUnit = !bonus.timeFromEventUnit ? timeUnits[0] : bonus.timeFromEventUnit
                                        bonus.timeToConsumeUnit = !bonus.timeToConsumeUnit ? timeUnits[0] : bonus.timeToConsumeUnit
                                        bonus.timeFromEventValue = !bonus.timeFromEventValue ? 0 : bonus.timeFromEventValue
                                        bonus.timeToConsumeValue = !bonus.timeToConsumeValue ? 0 : bonus.timeToConsumeValue
                                        bonus.timeToConsumeFreeGamesUnit = !bonus.timeToConsumeFreeGamesUnit ? timeUnits[0] : bonus.timeToConsumeFreeGamesUnit
                                        bonus.timeToConsumeFreeGamesValue = !bonus.timeToConsumeFreeGamesValue ? 0 : bonus.timeToConsumeFreeGamesValue
                                        bonus.timeToActivateUnit = !bonus.timeToActivateUnit ? timeUnits[0] : bonus.timeToActivateUnit
                                        bonus.timeToActivateValue = !bonus.timeToActivateValue ? 0 : bonus.timeToActivateValue
                                        Logic.Type.New(Main.Instance.BonusStateNew, {setBonusValidity: !Main.Instance.BonusStateNew.setBonusValidity, bonus})
                                    }}
                                >
                                    {Assets.Validity}
                                </button>
                                <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                    <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Set + ' ' + Assets.Time + Assets._for_ + Assets.Player.toLowerCase() + Assets._to_ + Assets.Accept.toLowerCase() + ' ' + Assets.Bonus.toLowerCase()}:</div>
                                </div>
                                <FormInputNumber
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeFromEventValue ? Main.Instance.BonusStateNew.bonus.timeFromEventValue.toString() : ''} 
                                    outerDiv = "rows-per-table input-group fl mr pl0"
                                    ClassName = "h30 w100px pl7 form-control mb5"
                                    type = "number" 
                                    onChange = {(text) => {updateText(+text, 'timeFromEventValue')}}
                                    min = "0"
                                    Icon = {faCalendarCheck}
                                    description = {Assets.Time + Assets._to_ + Assets.Accept.toLowerCase()}
                                />
                                <select
                                    title = {Assets.Select + ' ' + Assets.TimePeriod.toLowerCase()}
                                    style = {{width: '70px', height: '30px', marginRight: '10px'}}
                                    className = "fl dbl form-control mb5"
                                    onChange = {e => {updateText(e.target.value, 'timeFromEventUnit')}}
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeFromEventUnit ? Main.Instance.BonusStateNew.bonus.timeFromEventUnit : ''}
                                >
                                    {getOptions('timeUnits')}
                                </select>
                                <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                    <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Set + ' ' + Assets.Time + Assets._for_ + Assets.Player.toLowerCase() + Assets._to_ + Assets.Consume.toLowerCase() + ' ' + Assets.Bonus.toLowerCase()}:</div>
                                </div>
                                <FormInputNumber
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToConsumeValue ? Main.Instance.BonusStateNew.bonus.timeToConsumeValue.toString() : ''} 
                                    outerDiv = "rows-per-table input-group fl mr pl0"
                                    ClassName = "h30 w100px pl7 form-control mb5"
                                    type = "number" 
                                    onChange = {(text) => {updateText(+text, 'timeToConsumeValue')}}
                                    min = "0"
                                    Icon = {faCalendarTimes}
                                    description = {Assets.Time + Assets._to_ + Assets.Consume.toLowerCase()}
                                />
                                <select
                                    title = {Assets.Select + ' ' + Assets.TimePeriod.toLowerCase()}
                                    style = {{width: '70px', height: '30px', marginRight: '10px'}}
                                    className = "fl dbl form-control mb5"
                                    onChange = {e => {updateText(e.target.value, 'timeToConsumeUnit')}}
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToConsumeUnit ? Main.Instance.BonusStateNew.bonus.timeToConsumeUnit : ''}
                                >
                                    {getOptions('timeUnits')}
                                </select>
                                <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                    <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Set + ' ' + Assets.Time.toLowerCase() + Assets._for_ + Assets.Player.toLowerCase() + Assets._to_ + Assets.Activate.toLowerCase() + ' ' + Assets.Bonus.toLowerCase()}:</div>
                                </div>
                                <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px', display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" style = {{margin: '0px'}} checked={Main.Instance.BonusStateNew.timeToActivateBonusImmediate} onClick={() => {
                                    const bonus = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                                    bonus.timeToActivateValue = !Main.Instance.BonusStateNew.timeToActivateBonusImmediate ? 0 : +bonus.timeToActivateValue
                                    Logic.Type.New(Main.Instance.BonusStateNew, {timeToActivateBonusImmediate: !Main.Instance.BonusStateNew.timeToActivateBonusImmediate, bonus})
                                }}/>
                                <span style = {{lineHeight: '14px', marginLeft: '5px'}}>{Assets.Immediate}</span>
                                </div>
                                {!Main.Instance.BonusStateNew.timeToActivateBonusImmediate &&
                                <FormInputNumber
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToActivateValue ? Main.Instance.BonusStateNew.bonus.timeToActivateValue.toString() : ''} 
                                    outerDiv = "rows-per-table input-group fl mr pl0"
                                    ClassName = "h30 w100px pl7 form-control"
                                    type = "number" 
                                    onChange = {(text) => {updateText(+text, 'timeToActivateValue')}}
                                    min = "0"
                                    Icon = {faCalendarCheck}
                                    description = {Assets.Time + Assets._to_ + Assets.Activate.toLowerCase()}
                                />}
                                {!Main.Instance.BonusStateNew.timeToActivateBonusImmediate &&
                                <select
                                    title = {Assets.Select + ' ' + Assets.TimePeriod.toLowerCase()}
                                    style = {{width: '70px', height: '30px', marginRight: '10px'}}
                                    className = "fl dbl form-control"
                                    onChange = {e => {updateText(e.target.value, 'timeToActivateUnit')}}
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToActivateUnit ? Main.Instance.BonusStateNew.bonus.timeToActivateUnit : ''}
                                >
                                    {getOptions('timeUnits')}
                                </select>}
                                {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] ? 
                                <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                                    <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Set + ' ' + Assets.Time + Assets._for_ + Assets.Player.toLowerCase() + Assets._to_ + Assets.Consume.toLowerCase() + ' ' + Assets.FreeGames.toLowerCase()}:</div>
                                </div> : void 0}
                                {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] ? 
                                <FormInputNumber
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToConsumeFreeGamesValue ? Main.Instance.BonusStateNew.bonus.timeToConsumeFreeGamesValue.toString() : ''} 
                                    outerDiv = "rows-per-table input-group fl mr pl0"
                                    ClassName = "h30 w100px pl7 form-control mb5"
                                    type = "number" 
                                    onChange = {(text) => {updateText(+text, 'timeToConsumeFreeGamesValue')}}
                                    min = "0"
                                    Icon = {faCalendarTimes}
                                    description = {Assets.Time + Assets._to_ + Assets.Consume.toLowerCase()}
                                /> : void 0}
                                {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] ? 
                                <select
                                    title = {Assets.Select + ' ' + Assets.TimePeriod.toLowerCase()}
                                    style = {{width: '70px', height: '30px', marginRight: '10px'}}
                                    className = "fl dbl form-control mb5"
                                    onChange = {e => {updateText(e.target.value, 'timeToConsumeFreeGamesUnit')}}
                                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.timeToConsumeFreeGamesUnit ? Main.Instance.BonusStateNew.bonus.timeToConsumeFreeGamesUnit : ''}
                                >
                                    {getOptions('timeUnits')}
                                </select> : void 0}
                            </div>
    
                        </div>
                    </div>
                </div> : void 0}
                {Main.Instance.BonusStateNew.manageTags ?
                <div className="mt5">
                    <div className = "row admin-users-container clearfix" >
                        <div
                            className={Main.Instance.BonusStateNew.mode ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
                            }
                            style={{paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexWrap: 'wrap'}}
                        >
                            <div className = "row admin-users-row" style = {{position: 'relative'}}>
                                <button
                                    style = {{fontSize: '13px', background: !Main.Instance.BonusStateNew.manageTags && Main.Instance.BonusStateNew.bonus && (Main.Instance.BonusStateNew.bonus.includedTags && Main.Instance.BonusStateNew.bonus.includedTags.length > 0 || Main.Instance.BonusStateNew.bonus.excludedTags && Main.Instance.BonusStateNew.bonus.excludedTags.length > 0) ? '#d6edd6' : ''}}
                                    title = {Assets.Manage + ' ' + Assets.Tags.toLowerCase()}
                                    className = {Main.Instance.BonusStateNew.manageTags ? 'dbl fl btn btn-sm btn-success mr mb5 dbl' : 'dbl fl btn btn-sm btn-default mr mb5 dbl'}
                                    onClick = {() => Logic.Type.New(Main.Instance.BonusStateNew, {manageTags: !Main.Instance.BonusStateNew.manageTags})}
                                >
                                    {Assets.Tags}
                                </button>
                                <div className="player-card-tags">
                                {includeTags()}
                                </div>
                                <div className="player-card-tags">
                                {excludeTags()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : void 0}
                <h3>{Assets.ListOfBonus}</h3>
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.bonusNew ? Main.Instance.DatabaseDataState.bonusNew : []}
                    columns = {[
                        {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                        {width: 35, Header: Assets.ID, accessor: 'bonusId', headerClassName: 'th-left'},
                        {width: 150, Header: Assets.Bonus + ' ' + Assets.Name.toLowerCase(), accessor: 'bonusName', headerClassName: 'th-left'},
                        {width: 100, Header: Assets.Bonus + ' ' + Assets.Type.toLowerCase(), accessor: 'bonusType', headerClassName: 'th-left', Cell: (row) => row.value && row.value.charAt(0) + row.value.replace(/_/g,' ').toLowerCase().substring(1)},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Priority + '?'} icon = {faAsterisk}/>, accessor: 'priority', headerClassName: 'th-center'}, 
                        {width: 60, Header: Assets.OptIn, accessor: 'optIn', headerClassName: 'th-center', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>}, 
                        {width: 150, Header: Assets.TriggerType, accessor: 'triggerType', headerClassName: 'th-left', Cell: (row) => row.value.charAt(0) + row.value.replace(/_/g,' ').toLowerCase().substring(1)},
                        {Header: Assets.Conditions, accessor: 'bonusRules', headerClassName: 'th-left', Cell: (row) => triggerConditions(row.value)},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Validity + ' ' + Assets.Set.toLowerCase() + '?'} icon = {faClock}/>, headerClassName: 'th-center', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: isValiditySet(row.original) ? '#5cb85c' : '#d9534f'}} icon = {isValiditySet(row.original) ? faCheck : faTimes}/></div>},
                        {width: 70, Header: <span title = {Assets.Max + ' ' + Assets.Amount.toLowerCase()}>{Assets.Amount}</span>, accessor: 'maxGrantedAmount', headerClassName: 'th-left'}, 
                        {width: 70, Header: <span title = {Assets.Max + ' ' + Assets.Count.toLowerCase()}>{Assets.Count}</span>, accessor: 'maxGrantedCount', headerClassName: 'th-left'}, 
                        {width: 45, Header: <span title = {Assets.TermsAndConditions}>{Assets.TC}</span>, accessor: 'showTc', headerClassName: 'th-center', Cell: (row) => <div className = "tac" title={row.original.tcId}><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>}, 
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.All + ' ' + Assets.Players.toLowerCase() + ' ' + Assets.Eligible} icon = {faUserCheck}/>, accessor: 'allPlayersEligible', headerClassName: 'th-center', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Times + ' ' + Assets.AssignedTo.toLowerCase() + ' ' + Assets.Player.toLowerCase()} icon = {faRedo}/>, headerClassName: 'th-center', accessor: 'repeat'}, 
                        {width: 120, Header: Assets.WageringType, accessor: 'wageringType', headerClassName: 'th-left', Cell: (row) => row.value && row.value.charAt(0) + row.value.replace(/_/g,' ').toLowerCase().substring(1)},
                        {width: 255, Header: Assets.WageringRequirement + Assets._by_ + Assets.Category.toLowerCase(), accessor: 'gameCategoryContribution', headerClassName: 'th-left', Cell: (row) => GetWageringRequirement(row.original.wageringRequirement, row.value)},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Games} icon = {faGamepad}/>, headerClassName: 'th-center', Cell: (row) => getGames(row.original)}, 
                        {width: 120, Header: Assets.BonusCode_s, accessor: 'bonusCodes', headerClassName: 'th-left', Cell: (row) => GetBonusCode (row.value, true)},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Reusable + ' ' + Assets.BonusCode_s} icon = {faCode}/>, accessor: 'reusableCodes', headerClassName: 'th-center', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                        {width: 60, Header: Assets.Active, accessor: 'active', headerClassName: 'th-center', Cell: (row) => <Switch className = "fl mr mt5" onClick = {() => setBonusActive(row.original)} on = {row.value ? row.value : false}/>},
                        {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    defaultPageSize={50}
                /> 
            </div> 
        </div>
    )
}

function isValiditySet(bonus: BonusNew): boolean {
    return bonus.timeFromEventValue || bonus.timeToConsumeValue || bonus.timeToConsumeFreeGamesValue || bonus.timeToActivateValue ? true : false
}

function handleDelete(row: any) {
    
    if (row) {
        if (Main.Instance.DatabaseDataState.bonusCampaignNew) {
            for (const item of Main.Instance.DatabaseDataState.bonusCampaignNew) {
                for (const Item of item.bonusIds) {
                    if (Item === row.original.bonusId) {
                        alert(Assets.Bonus.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.bonusName + ' ' + Assets.is + ' ' + Assets.AssignedTo.toLowerCase() + ' ' + Assets.BonusCampaign.toLowerCase() + ' ' + item.campaignName + Assets._and_ + Assets.CannotBeDeleted)
                        return
                    }
                }
            }

        }
        if (confirm(Assets.sureToDelete + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.bonusName + '"?')) {
            DbRoutes.deleteBonus(row.original.bonusId) 
        }
    }
}

function termsConditionsPopup(): JSX.Element {
    return (
        <div style = {{height: 'fit-content', padding: '10px 30px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'absolute', top: '300px', left: '150px', right: '150px'}}>
            <button
                title={Assets.Close}
                onClick={() => Logic.Type.New(Main.Instance.BonusStateNew, {termsConditionsPopup: !Main.Instance.BonusStateNew.termsConditionsPopup})}
                style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                className="btn btn-default btn-sm btn-plus"
            >
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h4 >{Assets.Edit + ' ' + Assets.TermsAndConditions.toLowerCase()}</h4>
            <textarea
                title = {Assets.TermsAndConditions}
                className = "fl dbl form-control"
                style = {{paddingLeft: '5px', paddingRight: '5px', height: '150px', width: '100%', margin: '15px 0'}}
                value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.tcId ? Main.Instance.BonusStateNew.bonus.tcId : ''}
                onChange = {(e) => {updateText(e.target.value, 'tcId')}}
            />
        </div>
    )
}

function gotoInsertMode() {
    if (JSON.stringify(Main.Instance.BonusStateNew.bonus) === Main.Instance.BonusStateNew.bonus_) {
        setInitialValues()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            setInitialValues()
        }                
    }        
}


function handleInsertUpdate() {
    if (Main.Instance.BonusStateNew.bonus ) {
        // console.log(Main.Instance.BonusStateNew.bonus)
        if (Main.Instance.BonusStateNew.bonus.bonusName === undefined ||
            Main.Instance.BonusStateNew.bonus.bonusName.length === 0) {
                alert(Assets.Please + ' ' + Assets.Insert.toLowerCase() + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.Name.toLowerCase())
                return
            }
        if (Main.Instance.BonusStateNew.bonus.gameCategoryContribution) {
            if (Main.Instance.BonusStateNew.bonus.gameCategoryContribution.length !== Main.Instance.DatabaseDataState.gamesListCategories.length - 1) {
                alert (Assets.InsertWageringContributionForAllCategories)
                return
            } else {
                for (const item of Main.Instance.BonusStateNew.bonus.gameCategoryContribution) {

                    if (item === undefined || item.categoryId === 0 || item.categoryId === -1 || !item.coefficient) {
                        alert (Assets.InsertOrDeleteWageringContribution)
                        return
                    }
                }
            }
        } else {
            alert (Assets.InsertWageringContributionForAllCategories)
            return
        }
        if (Main.Instance.BonusStateNew.bonus.bonusCodes) {
            for (const item of Main.Instance.BonusStateNew.bonus.bonusCodes) {
                if (Main.Instance.DatabaseDataState.bonusNew) {
                    for (const Item of Main.Instance.DatabaseDataState.bonusNew) {
                        if (Main.Instance.BonusStateNew.bonus.bonusId === Item.bonusId) {continue}
                        if (Item.bonusCodes) {
                            for (const ITEM of Item.bonusCodes) {
                                if (item === ITEM) {
                                    alert (Assets.Duplicate + ' ' + Assets.BonusCode_s)
                                    return
                                }
                            }
                        }
                    }
                }
            }
            const arr = Object.assign([], Main.Instance.BonusStateNew.bonus.bonusCodes)
            arr.sort()
            for (let i: number = 0; i < arr.length; i++) {
                if (arr[i] === arr[i-1]) {
                    alert (Assets.Duplicate + ' ' + Assets.BonusCode_s)
                    return
                }
            }
        }

        if (!Main.Instance.BonusStateNew.bonus.bonusType || Main.Instance.BonusStateNew.bonus.bonusType === '') {
            alert(Assets.Please + ' ' + Assets.select + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.Type.toLowerCase())
            return 
        }

        if (!Main.Instance.BonusStateNew.bonus.triggerType || Main.Instance.BonusStateNew.bonus.triggerType === '') {
            alert(Assets.Please + ' ' + Assets.select + ' ' + Assets.TriggerType.toLowerCase())
            return
        }

        if (!Main.Instance.BonusStateNew.bonus.wageringType || Main.Instance.BonusStateNew.bonus.wageringType === '') {
            alert(Assets.Please + ' ' + Assets.select + ' ' + Assets.WageringType.toLowerCase())
            return
        }

        if (Main.Instance.BonusStateNew.bonus.triggerType === triggerType[0]) {
            if (Main.Instance.BonusStateNew.bonus.bonusRules && Main.Instance.BonusStateNew.bonus.bonusRules.length > 0) {
                const count = Main.Instance.BonusStateNew.bonus.bonusRules.find(item => item.rule === conditionRule[0])
                // const min_amount = Main.Instance.BonusStateNew.bonus.bonusRules.find(item => item.rule === conditionRule[1])
                const factor = Main.Instance.BonusStateNew.bonus.bonusRules.find(item => item.rule === conditionRule[3])
                // const max_reward_amount = Main.Instance.BonusStateNew.bonus.bonusRules.find(item => item.rule === conditionRule[4])
                if (Main.Instance.BonusStateNew.bonus.bonusType === bonusType[0] && (!count || !factor) || Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] && (!count) ) {
                    alert(Assets.Missing + ' ' + Assets.Conditions.toLowerCase())
                    return
                }
            } else  {
                alert(Assets.Missing + ' ' + Assets.Conditions.toLowerCase())
                return
            }
        }

        if (Main.Instance.BonusStateNew.bonus.triggerType === triggerType[6]) {
            if (!Main.Instance.BonusStateNew.bonus.timeToActivateUnit || Main.Instance.BonusStateNew.bonus.timeToActivateValue === null) {
                alert(Assets.Time + Assets._to_ + Assets.Activate.toLowerCase() + ' ' + Assets.Must.toLowerCase() + Assets._be_ + Assets.Set.toLowerCase())
                return
            }
        }

    } else {
        alert (Assets.sfh)
        return
    }

    const bonus: BonusNew = Object.assign ({}, Main.Instance.BonusStateNew.bonus)
    // if (isValiditySet(bonus) === false && Main.Instance.BonusStateNew.setBonusValidity) {
    //     if (confirm(Assets.Validity + ' ' + Assets.is + ' ' + Assets.notDefined.toLowerCase() + '. ' + Assets.DoYouWantToContinue + '?')) {
    //         bonus.timeFromEventValue = null
    //         bonus.timeFromEventUnit = null
    //         bonus.timeToConsumeValue = null
    //         bonus.timeToConsumeUnit = null
    //         bonus.timeToConsumeFreeGamesValue = null
    //         bonus.timeToConsumeFreeGamesUnit = null
    //         bonus.timeToActivateValue = null
    //         bonus.timeToActivateUnit = null
    //     } else {
    //         return
    //     }
    // }
    if (!bonus.timeToConsumeValue) {
        bonus.timeToConsumeValue = null
        bonus.timeToConsumeUnit = null
    }

    if (!bonus.timeFromEventValue) {
        bonus.timeFromEventValue = null
        bonus.timeFromEventUnit = null
    }

    if (!bonus.timeToConsumeFreeGamesValue) {
        bonus.timeToConsumeFreeGamesValue = null
        bonus.timeToConsumeFreeGamesUnit = null
    }

    if ((!bonus.timeToActivateValue) && !Main.Instance.BonusStateNew.timeToActivateBonusImmediate) {
        bonus.timeToActivateValue = null
        bonus.timeToActivateUnit = null
    }

    if (Main.Instance.BonusStateNew.timeToActivateBonusImmediate) {
        bonus.timeToActivateValue = 0
        bonus.timeToActivateUnit = timeUnits[0]
    }


    bonus.eventId = 'event_id'
    // if (bonus.bonusGameInclude && bonus.bonusGameInclude.length > 0) {delete(bonus.bonusGameExclude)}
    // if (bonus.bonusGameExclude && bonus.bonusGameExclude.length > 0) {delete(bonus.bonusGameInclude)}
    if (bonus.bonusType === bonusType[0]) {delete(bonus.freeGames)}

    for (const item of bonus.gameCategoryContribution) {
        if (item.coefficient) {
            item.coefficient = item.coefficient
        }

    }
    console.log(bonus)
    DbRoutes.insertUpdateBonus(bonus)
    Logic.Type.New(Main.Instance.BonusStateNew, {mode: false})
    // setInitialValues()
    
}

function getGames(bonus: BonusNew) {
    if (bonus.bonusType === bonusType[1]) {
        return bonus.freeGames ? bonus.freeGames.length : 0
    } else {
        if (bonus.bonusGameInclude && bonus.bonusGameInclude.length > 0) {
            return bonus.bonusGameInclude.length
        } else if (bonus.bonusGameExclude && bonus.bonusGameExclude.length > 0) {
            return bonus.bonusGameExclude.length
        } else {
            return <FontAwesomeIcon icon = {faInfinity}/>
        }
    }

}

function mode(array) {
    if(array.length == 0) {
        return null;
    }
    const modeMap = {};
    let maxEl = array[0]
    let maxCount = 1;
    for(let i = 0; i < array.length; i++) {
        const el = array[i];
        if(modeMap[el] == null) {
            modeMap[el] = 1;
        } else {
            modeMap[el]++;
        }  
        if(modeMap[el] > maxCount) {
            maxEl = el;
            maxCount = modeMap[el];
        }
    }
    return maxEl;
}

function triggerConditions(bonusRule: BonusRules[]) {
    // console.log(bonusRule)
    const str: JSX.Element[] = []
    let title: string = ''
    
    for (let i: number = 0; i < bonusRule.length; i++) {
        const text: string = (bonusRule[i].rule === conditionRule[5] ? Assets.VerifiedBy : bonusRule[i].rule.charAt(0) + bonusRule[i].rule.replace(/_/g,' ').toLowerCase().substring(1)) + ' ' + (bonusRule[i].rule !== conditionRule[5] ? getOperator(bonusRule[i].operator) + ' ' : '') + (bonusRule[i].rule === conditionRule[5] ? verificationType[bonusRule[i].value] && verificationType[bonusRule[i].value].toLowerCase().replace(/_/g, Assets._or_) : bonusRule[i].value)
        title += text
        if (i < bonusRule.length - 1) {
            title += ', '
        }
        if (i > 1) {
            continue
        }
        
        str.push(<span className = "label label-default label-sm mr5px" key = {i.toString() + 'bonusRule'} >{text}</span>) 
    }
    if (bonusRule.length > 2) {
        str.push(<span>...</span>)
    }

    return <div title = {title}>{str}</div>

}

function getOperator(operator: string): string {

    if (operator === conditionOperator[0]) {
        return '='
    } else if (operator === conditionOperator[1]) {
        return '<'
    } else if (operator === conditionOperator[2]) {
        return '>'
    } else if (operator === conditionOperator[3]) {
        return '<='
    } else if (operator === conditionOperator[4]) {
        return '>='
    }
}

function GetWageringRequirement(WR: number, wrc: CategoryContribution[]): JSX.Element {
    const wr: JSX.Element[] = []
    let title: string = ''
    if (Main.Instance.DatabaseDataState.gamesListCategories && wrc ) {
        
        const array: number[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesListCategories.length; i++) {
            for (let j: number = 0; j < wrc.length; j++) {
                if (wrc[j] && Main.Instance.DatabaseDataState.gamesListCategories[i].categoryId === wrc[j].categoryId) {
                    array.push(wrc[j].coefficient)
                    break
                }
            }
        }
        let mostCommon: number 
        if (array.length === Main.Instance.DatabaseDataState.gamesListCategories.length - 1) {
            mostCommon = mode(array)
        }
        
        if (mostCommon) {
            wr.push (<span key = "base" title = {Assets.Base + ' ' + Assets.WageringRequirement} style = {{marginRight: '5px'}} className = "label label-info label-sm" >{WR + 'x'}</span>)

            for (let i: number = 0; i < wrc.length; i++) { 
                if (wrc[i]) {
                    
                    if (wrc[i].coefficient === mostCommon) {continue}
                    title += getCategoryName(wrc[i].categoryId) + ': ' + wrc[i].coefficient + '%, '

                    wr.push (<span key = {i.toString() + 'wrc'} style = {{marginRight: '5px'}} className = "label label-default label-sm" >{getCategoryName(wrc[i].categoryId) + ': ' + wrc[i].coefficient + '%'}</span>)
                }
            }
            title += Assets.Else + ': ' + mostCommon + '%'
            wr.push (<span key = "others" style = {{marginRight: '0px'}} className = "label label-default label-sm" >{Assets.Else + ': ' + mostCommon + '%'}</span>)
            
        } else {
            wr.push (<span key = "base" title = {Assets.Base + ' ' + Assets.WageringRequirement} style = {{marginRight: '5px'}} className = "label label-info label-sm" >{WR + 'x'}</span>)

            for (let i: number = 0; i < wrc.length; i++) { 
                
                if (wrc[i]) {
                    title += getCategoryName(wrc[i].categoryId) + ': ' + wrc[i].coefficient + '%'
                    if (i < wrc.length - 1) {
                        title += ', '
                    }
                    if (i < 2) {
                        wr.push (<span key = {i.toString() + 'wrc'} style = {{marginRight: '5px'}} className = "label label-default label-sm" >{getCategoryName(wrc[i].categoryId) + ': ' + wrc[i].coefficient + '%'}</span>)

                    } else {
                        wr.push(<span>...</span>)
                        break
                    }
                }
            }
        }
    }
    return (<div title = {title}>{wr}</div>)
} 

function getCategoryName(categoryId: number): string {
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        for (const item of Main.Instance.DatabaseDataState.gamesListCategories) {
            if (item.categoryId === categoryId) {
                return item.name
            }
        }
    }
}

function GetBonusCode(code: string[], nicer?: boolean): string|JSX.Element {
    let s: string
    let m: number
    let a: string = ''
    if (code === undefined || code.length === 0) {
        s = Assets.DoesNotExist
        m = 0
    } else if (code.length === 1) {
        s = code[0]
        m = 1
        a = code[0]
    } else {
        s = code.length + ' ' + Assets.Code_s.toLowerCase()
        for (const item of code) {
            if (a.length > 0) {a+=', '}
            a += item
        }
        m = 2
    }
    if (nicer) {
        return (<span title = {a} style = {{fontSize: '12px'}} className = {m === 0 ? 'label label-danger' : m === 1 ? 'label label-primary' : m === 2 ? 'label label-success' : 'label label-default'}>{s}</span>)
    }
    return s
}

function handleEdit(row: any) {
    gotoTop()
    const bonus: BonusNew = Object.assign ({}, JSON.parse(JSON.stringify(row.original)))
    if (row) {
        Logic.Type.New(Main.Instance.BonusStateNew, {
            mode: true,
            bonus,
            bonus_: JSON.stringify(bonus),
            timeToActivateBonusImmediate: +bonus.timeToActivateValue === 0 ? true : false
        })
    }
}

export function setInitialValues() {

    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    bonus.bonusName = ''
    bonus.bonusId = null
    bonus.bonusType = ''
    bonus.defaultCurrency = 'CHF'
    bonus.supportedCurrencies = []
    bonus.maxGrantedAmount = 0
    bonus.maxGrantedCount = 0
    bonus.triggerType = ''
    bonus.wageringType = '' 
    bonus.wageringRequirement = 30
    bonus.optIn = false
    bonus.repeat = 1
    bonus.bonusRules = []
    bonus.bonusGameInclude = []
    bonus.bonusGameExclude = []
    bonus.gameCategoryContribution = [
        {categoryId: 1, coefficient: 100},
        {categoryId: 2, coefficient: 33.33},
        {categoryId: 3, coefficient: 33.33},
        {categoryId: 4, coefficient: 33.33},
        {categoryId: 5, coefficient: 33.33},
        {categoryId: 6, coefficient: 33.33},
        {categoryId: 7, coefficient: 33.33},
    ]
    bonus.bonusCodes = []
    bonus.showTc = false
    bonus.tcId = 'With accepting this bonus you accept our terms and conditions.' 
    bonus.allPlayersEligible = false
    bonus.freeGames = []
    bonus.reusableCodes = false
    bonus.priority = 0
    bonus.active = false
    bonus.includedTags = []
    bonus.excludedTags = []
    bonus.dependentBonusIds = []
    bonus.freeGamesAllowedGames = []
    bonus.timeFromEventUnit = null
    bonus.timeFromEventValue = null
    bonus.timeToActivateUnit = null
    bonus.timeToActivateValue = null
    bonus.timeToConsumeFreeGamesUnit = null
    bonus.timeToConsumeFreeGamesValue = null
    bonus.timeToConsumeUnit = null
    bonus.timeToConsumeValue = null

    Logic.Type.New(Main.Instance.BonusStateNew, {bonus, bonus_: undefined, mode: false, setBonusValidity: false, manageBonusCodes: false})
}


function getBonusCodes(): JSX.Element[] {
    const bc: JSX.Element[] = []
    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes && Main.Instance.BonusStateNew.bonus.bonusCodes.length > 0) {
        bc.push (<button style = {{cursor: 'default', width: '200px', marginRight: '10px', fontWeight: 700, fontSize: '14px', marginBottom: '2px'}} key = "-5" onClick = {() => {}} className = "btn btn-default btn-dark btn-sm dbl fl">{Assets.BonusCode_s} [{Main.Instance.BonusStateNew.bonus.bonusCodes.length}]</button>)
    }


    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes) {
        for (let i: number = 0; i < Main.Instance.BonusStateNew.bonus.bonusCodes.length; i++) {
            bc.push(
                <div key={i} className="dibl fl" style = {{width: '160px', marginRight: '5px', marginBottom: '2px'}}>
                    <FormInput
                        key = {i.toString()}
                        description = {Assets.BonusCode}
                        narrow = {true}
                        value = {Main.Instance.BonusStateNew.bonus.bonusCodes[i] ? Main.Instance.BonusStateNew.bonus.bonusCodes[i] : ''} 
                        ClassName = "form-control register-input dibl" 
                        type = "text"
                        onChange = {(text) => updateBonusCodes(i, text)} 
                        placeholder = {Assets.BonusCode}
                        Icon = {faCode}
                    /> 
                    <div title = {Assets.RemoveABonusCode} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                        <button 
                        onClick = {() => updateBonusCodes(i, undefined, true)} 
                        style = {{position: 'absolute', right: '8px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    </div>
                </div>                    
            )
        }
    }
   
    bc.push (
        <button 
        title = {Assets.AddANewBonus + ' ' + Assets.Code.toLowerCase()} 
        key = "-1" 
        onClick = {() => updateBonusCodes(undefined)} 
        className = "btn btn-primary btn-sm mr mb5 dbl fl">
            {Assets.AddANewBonus+ ' ' + Assets.Code.toLowerCase()}
        </button>
    )
    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes && Main.Instance.BonusStateNew.bonus.bonusCodes.length > 0) {
        bc.push (
            <button 
            title = {Assets.Delete + ' ' + Assets.All.toLowerCase() + ' ' + Assets.BonusCode_s.toLowerCase()} 
            key = "-4" 
            onClick = {() => confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.BonusCodes) ? updateBonusCodes(undefined, undefined, true) : void 0} 
            className = "btn btn-danger btn-sm mr0 mb5 dbl fl">
                {Assets.Delete + ' ' + Assets.All.toLowerCase() + ' ' + Assets.BonusCode_s.toLowerCase()}
            </button>
        )
    }
    if (!(Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusCodes) || Main.Instance.BonusStateNew.bonus.bonusCodes.length === 0) {
        bc.push (<div style = {{fontWeight: 700}} className = "fl ml mr mt5 mb5" key = "-3">{Assets.UploadBonusCodesFile + ': (' + Assets_.txt + ')'}</div>)    
        bc.push (
            <input
                key = "-2"
                type = "file"
                className = "uploadLogoWrapper dbl fl ml mb5"
                style = {{width: '300px', marginTop: '-3px'}}
                accept = "text/plain"
                onClick = {(event) => event.target = null}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        const decodedData: string = base64.decode((reader.result as string).replace('data:text/plain;base64,',''))
                        const DecodedData: string[] = decodedData.split('\n')
                        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
                        bonus.bonusCodes = []
                        for (const item of DecodedData) {
                            if (item.length > 0) {
                                bonus.bonusCodes.push(item.replace('\r', ''))
                            }
                        }
                        Logic.Type.New (Main.Instance.BonusStateNew, {bonus})
                    }
                    reader.readAsDataURL(cloneFile);
                }}
            />)
    }
    return bc
}

function includeTags(): JSX.Element[] {
    const tags: JSX.Element[] = []
    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.includedTags && Main.Instance.BonusStateNew.bonus.includedTags.length > 0) {
        tags.push (<button style = {{cursor: 'default', width: '200px', marginRight: '10px', fontWeight: 700, fontSize: '14px', marginBottom: '2px'}} key = "-5" onClick = {() => {}} className = "btn btn-default btn-dark btn-sm dbl fl">{Assets.Included + ' ' + Assets.Tags.toLowerCase()} [{Main.Instance.BonusStateNew.bonus.includedTags.length}]</button>)
    }


    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.includedTags) {
        for (let i: number = 0; i < Main.Instance.BonusStateNew.bonus.includedTags.length; i++) {
            tags.push(
                <div key={i} className="dibl fl" style = {{width: '160px', marginRight: '5px', marginBottom: '2px'}}>
                    <FormInput
                        key = {i.toString()}
                        description = {Assets.Tag}
                        narrow = {true}
                        value = {Main.Instance.BonusStateNew.bonus.includedTags[i] ? Main.Instance.BonusStateNew.bonus.includedTags[i] : ''} 
                        ClassName = "form-control register-input dibl" 
                        type = "text"
                        onChange = {(text) => updateIncludedTags(i, text)} 
                        placeholder = {Assets.Tag}
                        Icon = {faTag}
                    /> 
                    <div title = {Assets.Remove + Assets._a_ + Assets.Tag.toLowerCase()} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                        <button 
                        onClick = {() => updateIncludedTags(i, undefined, true)} 
                        style = {{position: 'absolute', right: '8px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    </div>
                </div>                    
            )
        }
    }
   
    tags.push (
        <button 
        title = {Assets.Include + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()} 
        key = "-1" 
        onClick = {() => updateIncludedTags(undefined)} 
        className = "btn btn-primary btn-sm mr mb5 dbl fl">
            {Assets.Include + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()}
        </button>
    )
    return tags
}

function excludeTags(): JSX.Element[] {
    const tags: JSX.Element[] = []
    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.excludedTags && Main.Instance.BonusStateNew.bonus.excludedTags.length > 0) {
        tags.push (<button style = {{cursor: 'default', width: '200px', marginRight: '10px', fontWeight: 700, fontSize: '14px', marginBottom: '2px'}} key = "-5" onClick = {() => {}} className = "btn btn-default btn-dark btn-sm dbl fl">{Assets.Excluded + ' ' + Assets.Tags.toLowerCase()} [{Main.Instance.BonusStateNew.bonus.excludedTags.length}]</button>)
    }


    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.excludedTags) {
        for (let i: number = 0; i < Main.Instance.BonusStateNew.bonus.excludedTags.length; i++) {
            tags.push(
                <div key={i} className="dibl fl" style = {{width: '160px', marginRight: '5px', marginBottom: '2px'}}>
                    <FormInput
                        key = {i.toString()}
                        description = {Assets.Tag}
                        narrow = {true}
                        value = {Main.Instance.BonusStateNew.bonus.excludedTags[i] ? Main.Instance.BonusStateNew.bonus.excludedTags[i] : ''} 
                        ClassName = "form-control register-input dibl" 
                        type = "text"
                        onChange = {(text) => updateExcludedTags(i, text)} 
                        placeholder = {Assets.Tag}
                        Icon = {faTag}
                    /> 
                    <div title = {Assets.Remove + Assets._a_ + Assets.Tag.toLowerCase()} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                        <button 
                        onClick = {() => updateExcludedTags(i, undefined, true)} 
                        style = {{position: 'absolute', right: '8px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    </div>
                </div>                    
            )
        }
    }
   
    tags.push (
        <button 
        title = {Assets.Exclude + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()} 
        key = "-1" 
        onClick = {() => updateExcludedTags(undefined)} 
        className = "btn btn-primary btn-sm mr mb5 dbl fl">
            {Assets.Exclude + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()}
        </button>
    )
    return tags
}

function getWageringRequirements(): JSX.Element[] {
    const wr: JSX.Element[] = []

    wr.push (
        <FormInputNumber
            key = "-2"
            description = {Assets.Base + ' ' + Assets.WageringRequirement}
            placeholder = {Assets.Base + ' ' + Assets.WageringRequirementShort}
            outerDiv = "rows-per-table input-group fl mr pl0"
            ClassName = "h30 w100px pl7"
            value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.wageringRequirement ? Main.Instance.BonusStateNew.bonus.wageringRequirement.toString() : ''}
            type = "number" 
            min = "0"
            iconText = {Assets.Base + ' ' + Assets.WageringRequirementShort}
            iconTextS  = "12px"
            onChange = {(text) => {updateText(+text, 'wageringRequirement')}}
        />
    )
    
    if (Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.gameCategoryContribution) {
        for (let i: number = 0; i < Main.Instance.BonusStateNew.bonus.gameCategoryContribution.length; i++) {
            wr.push(
            <div className="dbl fl mb5" key={i.toString()}>
                <select
                    key = {i.toString() + '___'}
                    style = {{width: 'auto', marginRight: '5px', height: '30px'}}
                    className = "fl form-control"
                    onChange = {(e) => {
                        updateCategoryContributions(i, +e.target.value, undefined)
                    }}
                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.gameCategoryContribution && Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i] && Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i].categoryId ? Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i].categoryId : 0}
                >
                    {getCategoryOptions(false)}
                </select>
                <FormInputNumber
                    key = {i.toString() + '__'}
                    description = {Assets.WageringContribution + Assets._for_ + Assets.Category.toLowerCase()}
                    outerDiv = "rows-per-table input-group fl pl0"
                    ClassName = "h30 w100px pl7 mr2"
                    value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.gameCategoryContribution && Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i] && Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i].coefficient ? Main.Instance.BonusStateNew.bonus.gameCategoryContribution[i].coefficient.toString() : '0'}
                    max = "100"
                    type = "number" 
                    min = "0"
                    Icon = {faPercentage}
                    onChange = {(text) => updateCategoryContributions(i, undefined, +text)}
                />
                <div title = {Assets.RemoveAWageringRequirement} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                    <button 
                    onClick = {() => updateCategoryContributions(i, undefined, undefined, true)} 
                    style = {{position: 'absolute', right: '14px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}>
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                </div>

            </div>)

        }
    }
  
    wr.push (
    <button 
    key = "-3" 
    onClick = {() => updateCategoryContributions(undefined)} 
    className = "btn btn-primary btn-sm mr0 mb5 dbl fl">
        {Assets.AddANewWageringContribution}
    </button>
    )
    return wr
}

export function getCategoryOptions(Any: boolean): JSX.Element[] {
    const categories: JSX.Element[] = []
    categories.push(<option key = "-1" value = {-1}>{Any ? Assets.AnyCategory : Assets.Category + '?'}</option>)
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        for (const item of Main.Instance.DatabaseDataState.gamesListCategories) {
            // if (item.data.active)
            if (item.categoryId === 0) {continue}
            categories.push(<option key = {item.categoryId.toString()} value = {item.categoryId}>{item.name}</option>)
        }
    }
    return categories
}

function getConditionSettings(): JSX.Element[] {
    const settings: JSX.Element[] = []

    if (Main.Instance.BonusStateNew.bonus.bonusType && Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1] && Main.Instance.BonusStateNew.bonus.triggerType !== triggerType[0].toString()) {
        const bonus = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        if (!bonus.bonusRules.find(item => item.rule === conditionRule[4])) {
            updateBonusRules(undefined, conditionRule[4].toString(), conditionOperator[1].toString(), 0)
        }
    }

    if (Main.Instance.BonusStateNew.bonus.triggerType !== triggerType[0].toString() && Main.Instance.BonusStateNew.bonus.triggerType !== triggerType[4].toString()) {
        const bonus = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        if (!bonus.bonusRules.find(item => item.rule === conditionRule[2]) && Main.Instance.BonusStateNew.bonus.bonusType === bonusType[0].toString()) {
            updateBonusRules(undefined, conditionRule[2].toString(), conditionOperator[0].toString(), undefined)
        }

        if (Main.Instance.BonusStateNew.bonus.triggerType === triggerType[2].toString()) {
            if (!bonus.bonusRules.find(item => item.rule === conditionRule[5])) {
                updateBonusRules(undefined, conditionRule[5].toString(), conditionOperator[0].toString(), 0)
            }
        }
        
        for (let i : number = 0; i < bonus.bonusRules.length; i++) {

            if (bonus.bonusRules[i].rule === conditionRule[2]) {
                settings.push(
                    <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                        <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.RewardAmount}:</div>
                    </div>
                )
                settings.push(
                    <div className="dbl fl mb5 mr">
                        <FormInputNumber
                            value = {bonus.bonusRules[i] && bonus.bonusRules[i].value ? bonus.bonusRules[i].value.toString() : ''} 
                            outerDiv = "rows-per-table input-group fl mr pl0"
                            ClassName = "h30 w100px pl7 form-control"
                            type = "number" 
                            onChange = {(text) => {
                                updateBonusRules(i, conditionRule[2].toString(), conditionOperator[0].toString(), +text)
                            }}
                            min = "0"
                        />
                    </div>
                )
            }
            if (bonus.bonusRules[i].rule === conditionRule[5]) {

                settings.push(
                    <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                        <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.VerifiedBy}:</div>
                    </div>
                )
                settings.push(
                    <div className="dbl fl mb5">
                        <select
                            
                            style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                            className = "fl dbl form-control"
                            onChange = {e => {
                                updateBonusRules(i, conditionRule[5].toString(), conditionOperator[0].toString(), +e.target.value)
                            }}
                            value = {/*bonus.bonusRules[i] && bonus.bonusRules[i].value ?*/ bonus.bonusRules[i].value.toString() /*: ''*/} 
                        >
                            {getOptions('verificationType')}
                        </select>
                    </div>
        
                )
                
            }

            if (bonus.bonusRules[i].rule === conditionRule[4]) {
                settings.push(
                    <div className = "fl dbl mb5" style = {{marginLeft: '5px', marginRight: '10px'}}>
                        <div style = {{lineHeight: '14px', marginTop: '8px'}}>{conditionRule[4].charAt(0) + conditionRule[4].replace(/_/g, ' ').toLowerCase().substring(1)}:</div>
                    </div>
                )
                settings.push(
                    <div className="dbl fl mb5">
                        <select
                            style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                            className = "fl dbl form-control"
                            onChange = {e => {updateBonusRules(i, undefined, e.target.value, undefined)}}
                            value = {bonus.bonusRules[i].operator.toString()}
                        >
                            {getOptions('conditionOperator', bonus.bonusRules[i].rule)}
                        </select>
                        <FormInputNumber
                        key = {i.toString() + '__'}
                        value = {bonus.bonusRules[i].value.toString()} 
                        outerDiv = "rows-per-table input-group fl pl0"
                        ClassName = "h30 w100px pl7 mr2 fl"
                        type = "number" 
                        onChange = {text => {updateBonusRules(i, undefined, undefined, +text)}}
                        min = "0"
                        // Icon = {Main.Instance.BonusStateNew.bonus.bonusRules[i].rule === conditionRule[3] ? faPercentage : undefined}
                    />
                    </div>
                )
        
                
            }
        }
        
    } 

    

    if (Main.Instance.BonusStateNew.bonus.triggerType === triggerType[0]) {
        if (Main.Instance.BonusStateNew.bonus.bonusRules) {
            settings.push(
                <div className = "fl dbl" style = {{marginLeft: '5px', marginRight: '10px', marginBottom: '15px'}}>
                    <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Set + ' ' + Assets.Conditions.toLowerCase()}:</div>
                </div>
            )
            for (let i : number = 0; i < Main.Instance.BonusStateNew.bonus.bonusRules.length; i++) {
                settings.push(
                <div className="dbl fl mb" key={i}>
                    <select
                        key = {i.toString() + '___'}
                        style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                        className = "fl dbl form-control"
                        onChange = {e => {
                            const operator = e.target.value === conditionRule[4] ? conditionOperator[1] : e.target.value === conditionRule[1] ? conditionOperator[2] : conditionOperator[0]
                            updateBonusRules(i, e.target.value, operator, undefined)
                        }}
                        value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusRules && Main.Instance.BonusStateNew.bonus.bonusRules[i].rule ? Main.Instance.BonusStateNew.bonus.bonusRules[i].rule : conditionRule[0]}
                    >
                        {getOptions('conditionRule', Main.Instance.BonusStateNew.bonus.triggerType)}
                    </select>

                    
                    {Main.Instance.BonusStateNew.bonus.bonusRules[i].rule === conditionRule[1] || Main.Instance.BonusStateNew.bonus.bonusRules[i].rule === conditionRule[4] || Main.Instance.BonusStateNew.bonus.bonusRules[i].rule === conditionRule[0] ?
                    <>
                    <select
                        style = {{width: 'auto', height: '30px', marginRight: '10px'}}
                        className = "fl dbl form-control"
                        onChange = {e => {updateBonusRules(i, undefined, e.target.value, undefined)}}
                        value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusRules && Main.Instance.BonusStateNew.bonus.bonusRules[i].operator ? Main.Instance.BonusStateNew.bonus.bonusRules[i].operator : conditionOperator[0]}
                    >
                        {getOptions('conditionOperator', Main.Instance.BonusStateNew.bonus.bonusRules[i].rule)}
                    </select>
                    <div className = "fl dbl" style = {{marginRight: '10px'}}>
                        <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.to}</div>
                    </div>  
                    </> :
                    <div className = "fl dbl" style = {{marginRight: '10px'}}>
                        <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.is}</div>
                    </div>}
                    

                    <FormInputNumber
                        key = {i.toString() + '__'}
                        value = {Main.Instance.BonusStateNew.bonus && Main.Instance.BonusStateNew.bonus.bonusRules && Main.Instance.BonusStateNew.bonus.bonusRules[i].value ? Main.Instance.BonusStateNew.bonus.bonusRules[i].value.toString() : ''} 
                        outerDiv = "rows-per-table input-group fl pl0"
                        ClassName = "h30 w100px pl7 mr2 fl"
                        type = "number" 
                        onChange = {text => {updateBonusRules(i, undefined, undefined, +text)}}
                        min = "0"
                        Icon = {Main.Instance.BonusStateNew.bonus.bonusRules[i].rule === conditionRule[3] ? faPercentage : undefined}
                    />
                    <div title = {Assets.Remove + ' ' + Assets.Condition.toLowerCase()} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                    <button 
                    onClick = {() => updateBonusRules(i, undefined, undefined, undefined, true)} 
                    style = {{position: 'absolute', right: '14px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}>
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                </div>
                </div>
                )
            }
        }
        settings.push(
            <button 
                onClick = {() => updateBonusRules(undefined, conditionRule[0].toString(), conditionOperator[0].toString(), undefined)}
                className = "btn btn-primary btn-sm mr0 mb5 dbl fl"
            > 
                {Assets.Add + Assets._a_ + Assets.Condition.toLowerCase()}
            </button>
            
        )
        
    }
    return settings

}

function updateBonusRules(index: number, Rule?: string, Operator?: string, Value?: number | string, remove?: boolean) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)

    if (remove) {
        bonus.bonusRules.splice(index, 1)
    } else {
        if (!bonus.bonusRules) {
            bonus.bonusRules = []
        }
    
        if (index === undefined || !bonus.bonusRules[index]) {
            bonus.bonusRules.push({rule: Rule, operator: Operator, value: Value})
        } else {
            const rule = Rule ? Rule : Main.Instance.BonusStateNew.bonus.bonusRules[index].rule
            const operator = Operator ? Operator : Main.Instance.BonusStateNew.bonus.bonusRules[index].operator
            const value = Value !== undefined ? Value : Main.Instance.BonusStateNew.bonus.bonusRules[index].value
            bonus.bonusRules[index] = {rule, operator, value}
        }
    }

    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function updateCategoryContributions(index: number, CategoryId?: number, Coefficient?: number, remove?: boolean) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)

    if (remove) {
        bonus.gameCategoryContribution.splice(index, 1)
    } else {
        if (!bonus.gameCategoryContribution) {
            bonus.gameCategoryContribution = []
        }
    
        if (index === undefined || !bonus.gameCategoryContribution[index]) {
            bonus.gameCategoryContribution.push({categoryId: CategoryId, coefficient: Coefficient})
        } else {
            const coefficient = Coefficient ? Coefficient : Main.Instance.BonusStateNew.bonus.gameCategoryContribution[index].coefficient
            const categoryId = CategoryId ? CategoryId : Main.Instance.BonusStateNew.bonus.gameCategoryContribution[index].categoryId

            bonus.gameCategoryContribution[index] = {categoryId, coefficient}
        }
    }
    
    
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function updateBonusCodes(index: number, code?: string, remove?: boolean) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    if (remove) {
        if (index === undefined) {
            bonus.bonusCodes = []
        } else {
            bonus.bonusCodes.splice(index, 1) 
        }
    } else {
        if (!bonus.bonusCodes) {
            bonus.bonusCodes = []
        } 
        if (index === undefined || bonus.bonusCodes[index] === undefined) {
            bonus.bonusCodes.push('')
        } else {
            bonus.bonusCodes[index] = code ? code : Main.Instance.BonusStateNew.bonus.bonusCodes[index]
        }
    }  
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function updateIncludedTags(index: number, tag?: string, remove?: boolean) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    if (remove) {
        if (index === undefined) {
            bonus.includedTags = []
        } else {
            bonus.includedTags.splice(index, 1) 
        }
    } else {
        if (!bonus.includedTags) {
            bonus.includedTags = []
        } 
        if (index === undefined || bonus.includedTags[index] === undefined) {
            bonus.includedTags.push('')
        } else {
            bonus.includedTags[index] = tag ? tag : Main.Instance.BonusStateNew.bonus.includedTags[index]
        }
    }  
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function updateExcludedTags(index: number, tag?: string, remove?: boolean) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    if (remove) {
        if (index === undefined) {
            bonus.excludedTags = []
        } else {
            bonus.excludedTags.splice(index, 1) 
        }
    } else {
        if (!bonus.excludedTags) {
            bonus.excludedTags = []
        } 
        if (index === undefined || bonus.excludedTags[index] === undefined) {
            bonus.excludedTags.push('')
        } else {
            bonus.excludedTags[index] = tag ? tag : Main.Instance.BonusStateNew.bonus.excludedTags[index]
        }
    }  
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function updateText(value: any, property: string) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    let manageBonusCodes = Main.Instance.BonusStateNew.manageBonusCodes
    bonus[property] = value
    if (property === 'triggerType') {
        bonus.bonusRules = []
        bonus.repeat = 1

        if (value === triggerType[6]) {
            bonus.bonusCodes = []
            manageBonusCodes = false
            bonus.dependentBonusIds = []
        }
    }
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus, manageBonusCodes})
}

function updateBoolean(property: string) {
    const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
    bonus[property] = !bonus[property]
    Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
}

function setBonusActive(bonus: BonusNew) {
    bonus.active = !bonus.active
    DbRoutes.activateBonus(bonus.bonusId, bonus.active)
}

export function getCurrencyOptions(): JSX.Element[] {
    const currencies: JSX.Element[] = []
    currencies.push(<option key = "-1" value = "-1">{Assets.SelectCurrency}</option>)
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.currencies) {
        for (const item of Main.Instance.DatabaseDataState.custom.general.currencies) {   
            currencies.push(<option key = {item} value = {item}>{item}</option>)    
        }
    }
    return currencies
}

function getOptions(which: string, filter?: string) {
    let values
    let name: string
    if (which === 'bonusType') {
        values = Object.keys(bonusType)
        name = ' ' + Assets.Bonus + ' ' + Assets.Type.toLowerCase()
    } else if (which === 'triggerType') {
        values = Object.keys(triggerType)
        name = ' ' + Assets.TriggerType.toLowerCase()
    } else if (which === 'conditionRule') {
        values = Object.keys(conditionRule)
    } else if (which === 'conditionOperator') {
        values = Object.keys(conditionOperator)
    } else if (which === 'wageringType') {
        values = Object.keys(wageringType)
        name = ' ' + Assets.WageringType.toLowerCase()
    } else if (which === 'timeUnits') {
        values = Object.keys(timeUnits)
    } else if (which === 'verificationType') {
        values = Object.keys(verificationType)
    }
    const options: JSX.Element[] = []
    if (name) {options.push(<option key = "-1" value = "-1">{Assets.Select + name}</option>)}
    
    if (values) {
        for (let i: number = 0; i < values.length / 2; i++) {
            if (which === 'conditionOperator' && filter) {
                if (filter === conditionRule[4]) {
                    if (values[i] !== conditionOperator.LESS_THAN.toString() && values[i] !== conditionOperator.LESS_THAN_OR_EQUAL.toString()) {
                        continue
                    }
                }
                if (filter === conditionRule[1]) {
                    if (values[i] !== conditionOperator.MORE_THAN.toString() && values[i] !== conditionOperator.MORE_THAN_OR_EQUAL.toString()) {
                        continue
                    }
                }
                if (filter === conditionRule[0]) {
                    if (values[i] !== conditionOperator.EQUAL.toString() && values[i] !== conditionOperator.MORE_THAN.toString()) {
                        continue
                    }
                }
            }
            if (which === 'conditionRule' && filter) {
                if (filter === triggerType[0]) {
                    if (values[i] === conditionRule.REWARD_AMOUNT.toString()) {
                        continue
                    }
                    if (values[i] === conditionRule.VERIFICATION_TYPE.toString()) {
                        continue
                    }
                    if (Main.Instance.BonusStateNew.bonus.bonusType === bonusType[1].toString() && values[i] === conditionRule.FACTOR.toString()) {
                        continue
                    }
                }
            }
            options.push(<option key = {values[i]} value = {which === 'verificationType' ? values[i] : values[values.length / 2 + i]}>{values[values.length / 2 + i].charAt(0) + values[values.length / 2 + i].replace(/_/g,which === 'verificationType' ? Assets._or_ : ' ').toLowerCase().substring(1)}</option>)
        }
    }
    
    return options

}
