import * as React from 'react'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons'

export class Input extends React.Component<{onChange: (text: string) => void, type:string}, {Text: string}> {
  public state = {
    Text: '',
  }

  public render(): JSX.Element {
    return (<input type={this.props.type} onChange={(event) => this.onChange(event)}/>)
  }

  private onChange(event: React.FormEvent<HTMLInputElement>): void {
    this.state.Text = event.currentTarget.value
    this.props.onChange(event.currentTarget.value)
  }
}

export class InputClassname extends React.Component<{onChange: (text: string) => void, 
type:string, classname:string}, {Text: string}> {
  public state = {
    Text: '',
  }

  public render(): JSX.Element {
    return (<input type={this.props.type} onChange={(event) => this.onChange(event)} className={this.props.classname}/>)
  }

  private onChange(event: React.FormEvent<HTMLInputElement>): void {
    this.state.Text = event.currentTarget.value
    this.props.onChange(event.currentTarget.value)
  }
}


export class LoginInput extends React.Component<{onChange: (text: string) => void, border?: string;
type:string, placeholder:string, icon?:string, Icon?: IconDefinition}, {Text: string}> {

    public state = {
      Text: '',
    }

    public render(): JSX.Element {
      return (
        <div className="input-group margin-bottom-sm">
          <span className="input-group-addon">
              {this.props.icon ? <i className={this.props.icon}/> : <FontAwesomeIcon icon = {this.props.Icon}/>}
          </span>
          <input
            style = {{border: this.props.border ? this.props.border : ''}}
            className = "form-control"
            type = {this.props.type}
            placeholder = {this.props.placeholder}
            onChange = {(event) => this.onChange(event)}
          />
        </div>
      )
    }

    private onChange(event: React.FormEvent<HTMLInputElement>): void {
      this.state.Text = event.currentTarget.value
      this.props.onChange(event.currentTarget.value)
    }
  }

// onChange
export class FormInput extends React.Component<{onChange?: (text: string) => void, selectverywidehalf?:boolean, prefix?: string,
selectverywide?:boolean,description?:string, float?:boolean,raw?:boolean, date?:boolean, titleFix?:boolean,list?:string,
date2?:boolean, odds?:boolean, value:string, type:string, divClass?:string, narrow?:boolean, Whole50?: boolean, Icon?:IconDefinition,
narrow_?:boolean, narrow_date?:boolean, verynarrow?:boolean, placeholder?:string, icon?:string, ClassName:string,
validation?:boolean, readOnly?: boolean, iconNumber?:string, handleClick?:() => void, iconText?:string, bg?:string,
fix?:boolean, color?:string, thick?: boolean, fix2?:boolean, width?:string, veryNarrow?:boolean, floatRight?:boolean,
full?:boolean,iconTextSize?:string,half?:boolean, whole?: boolean, Whole?: boolean, whole35?:boolean, whole50?: boolean, iconSvg?:string, autoFill?:string}
,{Text: string}> {

    public state = {
      Text: '',
    }

    public render(): JSX.Element {

      let className:string = 'input-group margin-bottom-sm'
      if (this.props.width) {
        className += ' w' + this.props.width
      }

      if (this.props.float && this.props.float === true) {
        className += ' fl'
      }

      if (this.props.selectverywide && this.props.selectverywide === true) {
        className += ' select-verywide'
      }

      if (this.props.selectverywidehalf && this.props.selectverywidehalf === true) {
        className += ' select-verywidehalf'
      }

      if (this.props.titleFix && this.props.titleFix === true) {
        className += ' mt20'
      }

      if (this.props.date && this.props.date === true) {
        className += ' date-field'
      }

      if (this.props.full && this.props.full === true) {
        className += ' full-field'
      }

      if (this.props.half && this.props.half === true) {
        className += ' half-field'
      }

      if (this.props.date2 && this.props.date2 === true) {
        className += ' date-field2'
      }

      if (this.props.whole && this.props.whole === true) {
        className += ' whole'
      }

      if (this.props.whole35 && this.props.whole35 === true) {
        className += ' whole35'
      }      

      if (this.props.Whole && this.props.Whole === true) {
        className += ' Whole'
      }

      if (this.props.Whole50 && this.props.Whole50 === true) {
        className += ' Whole50'
      }

      if (this.props.whole50 && this.props.whole50 === true) {
        className += ' whole50'
      }

      if (this.props.odds && this.props.odds === true) {
        className += ' odds'
      }

      

      if (this.props.narrow && this.props.narrow === true && this.props.floatRight && this.props.floatRight === true) {
        className += ' force-narrow fr'
      } else if (this.props.narrow && this.props.narrow === true) {
        className += ' force-narrow fl'
      } else if (this.props.narrow_ && this.props.narrow_ === true) {
        className += ' force-narrow_ fl'
      } else if (this.props.veryNarrow && this.props.veryNarrow === true && 
        this.props.floatRight && this.props.floatRight === true) {
        className += ' force-veryNarrow fr'
      } else if (this.props.veryNarrow && this.props.veryNarrow === true) {
        className += ' force-veryNarrow fl'
      } else if (this.props.narrow_date && this.props.narrow_date === true) {
        className += ' force-narrow_date fl'
      } else if (this.props.verynarrow && this.props.verynarrow === true && 
        this.props.floatRight && this.props.floatRight === true) {
        className += ' force-verynarrow fr'
      } else if (this.props.verynarrow && this.props.verynarrow === true) {
        className += ' force-verynarrow fl'
      }

      if (this.props.divClass) { className += ' ' + this.props.divClass }

      if (this.props.raw && this.props.description) {
        if (this.props.readOnly) {
        return (
          <input  
            readOnly = {true}
            style = {{background: '#eee', fontWeight: this.props.thick ? 700 : 400}}
            title = {this.props.description}
            value = {this.props.value}
            list = {this.props.list}
            className = {this.props.ClassName}
            type = {this.props.type} 
            placeholder = {this.props.placeholder}
            // onChange = {event => this.onChange(event)}
          />
        )
        } else {
        return (
          <input
            style = {{fontWeight:this.props.thick ? 700 : 400}}
            title = {this.props.description}
            value = {this.props.value}
            list = {this.props.list}
            className = {this.props.ClassName}
            type = {this.props.type} 
            placeholder = {this.props.placeholder}
            onChange = {(event) => this.onChange(event)}
          />
        )
        }
      }

      if (this.props.raw) {
        if (this.props.readOnly) {
        return (
          <input
            readOnly = {true}
            style = {{background: '#eee', fontWeight:this.props.thick ? 700 : 400}}
            value = {this.props.value}
            list = {this.props.list}
            className = {this.props.ClassName} 
            type = {this.props.type}
            placeholder = {this.props.placeholder}
            // onChange = {event => this.onChange(event)}
          />                  
        )
        } else {
        return (
          <input  
            style = {{fontWeight:this.props.thick ? 700 : 400}}
            value = {this.props.value}
            list = {this.props.list}
            className = {this.props.ClassName} 
            type = {this.props.type}
            placeholder = {this.props.placeholder}
            onChange = {(event) => this.onChange(event)}
          />
        )
        }
      }

      let input_classname = this.props.ClassName
      if (this.props.validation !== undefined) {
        // console.log (this.props.validation.toString())
        if (this.props.validation.toString() === 'true') { 
          input_classname += ' border-green'
        } else {
          input_classname += ' border-red'
        }
      }

      if (this.props.autoFill) {
        return (
          <div className = {className}>
            {this.props.verynarrow && this.props.verynarrow === true ? void 0 :
            <span 
                className = {
                    this.props.handleClick && this.props.iconNumber ? 
                    'input-group-addon icon-number-addon' : 
                    this.props.handleClick && this.props.iconText ?
                    'input-group-addon icon-text-addon' :
                    this.props.fix ?
                    'input-group-addon input-group-addon-fix'
                    :
                    this.props.fix2 ?
                    'input-group-addon input-group-addon-fix2'
                    :
                    this.props.half || this.props.full ?
                    'input-group-addon input-group-addon-fix3'
                    :
                    'input-group-addon'
                }
                style = {{fontSize: this.props.iconTextSize ? this.props.iconTextSize: '', background: this.props.bg ? this.props.bg : '', color: this.props.color ? this.props.color : ''}}
                onClick = {this.props.handleClick && (this.props.iconNumber || this.props.iconText) ? this.props.handleClick : null}
            >
                {this.props.handleClick && this.props.iconNumber ? 
                    <i className = {Assets_.faInfoClass + ' fa-fw'}/>
                : this.props.handleClick && this.props.iconText ? <i className = {Assets_.faPlusButtonClass}/> : void 0}
                {this.props.iconNumber ? 
                <span className = "icon-number">
                    {this.props.iconNumber}
                </span> : this.props.iconText ? 
                <span className = "icon-text" style = {this.props.iconTextSize ? {fontSize: this.props.iconTextSize} : {}}>
                {this.props.iconText}</span> :
                this.props.icon ? <i className = {this.props.icon}/> : this.props.iconSvg ? <i style = {{color: 'inherit', position: 'relative'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Assets_[this.props.iconSvg]('#555', 16, 0, 4)}</i> 
                : <FontAwesomeIcon icon = {this.props.Icon}/>}
            </span>}
            {this.props.prefix ?<span className = "prefix">+{this.props.prefix}</span>: void 0}
            {this.props.description ? 
            <input 
              title = {this.props.description}
              style = {{fontWeight:this.props.thick ? 700 : 400}}
              list = {this.props.list}
              value = {this.props.value}
              className = {input_classname} 
              type = {this.props.type}
              placeholder = {this.props.placeholder} 
              onChange = {(event) => this.onChange(event)}
              autoComplete = {this.props.autoFill}
            />
            :
            <input 
              value = {this.props.value}
              style = {{fontWeight:this.props.thick ? 700 : 400}}
              className = {input_classname}
              list = {this.props.list}
              type = {this.props.type}
              placeholder = {this.props.placeholder}
              onChange = {(event) => this.onChange(event)}
              autoComplete = {this.props.autoFill}
            />
            }
            {this.props.children}
          </div>
          )
      }

      if (this.props.readOnly) {
      return (
      <div className = {className}>
        {this.props.verynarrow && this.props.verynarrow === true ? void 0 :
        <span 
            className = {
                this.props.handleClick && this.props.iconNumber ? 
                'input-group-addon icon-number-addon' : 
                this.props.handleClick && this.props.iconText ?
                'input-group-addon icon-text-addon' :
                this.props.fix ?
                'input-group-addon input-group-addon-fix'
                :
                this.props.fix2 ?
                'input-group-addon input-group-addon-fix2'
                :
                'input-group-addon'
            }
            style = {this.props.bg ? {background: this.props.bg, color: this.props.color ? this.props.color : ''} : {}}
            onClick = {this.props.handleClick&&(this.props.iconNumber||this.props.iconText) ? this.props.handleClick:null}
        >
            {this.props.handleClick && this.props.iconNumber ? 
            <i className = {Assets_.faInfoClass + ' fa-fw'}/>
            : this.props.handleClick && this.props.iconText ? <i className = {Assets_.faPlusButtonClass}/>
            : void 0}
            {this.props.iconNumber ? 
            <span className = "icon-number">{this.props.iconNumber}</span> : 
            this.props.iconText ? <span className = "icon-text">{this.props.iconText}</span> :
            this.props.icon ? <i className = {this.props.icon}/> : <FontAwesomeIcon icon = {this.props.Icon}/>}
        </span>}
        {this.props.prefix ?<span className = "prefix">+{this.props.prefix}</span>: void 0}
        {this.props.description ? 
        <input 
          readOnly = {true}
          style = {{background: '#eee', fontWeight:this.props.thick ? 700 : 400}}
          title = {this.props.description}
          list = {this.props.list}
          value = {this.props.value}
          className = {input_classname} 
          type = {this.props.type}
          placeholder = {this.props.placeholder} 
          // onChange = {event => this.onChange(event)}
        />
        :
        <input 
          readOnly = {true}
          style = {{background: '#eee', fontWeight:this.props.thick ? 700 : 400}}
          value = {this.props.value}
          className = {input_classname}
          list = {this.props.list}
          type = {this.props.type}
          placeholder = {this.props.placeholder}
          // onChange = {event => this.onChange(event)}
        />
        }
        {this.props.children}
      </div>
      )
      } else {
      return (
      <div className = {className}>
        {this.props.verynarrow && this.props.verynarrow === true ? void 0 :
        <span 
            className = {
                this.props.handleClick && this.props.iconNumber ? 
                'input-group-addon icon-number-addon' : 
                this.props.handleClick && this.props.iconText ?
                'input-group-addon icon-text-addon' :
                this.props.fix ?
                'input-group-addon input-group-addon-fix'
                :
                this.props.fix2 ?
                'input-group-addon input-group-addon-fix2'
                :
                this.props.half || this.props.full ?
                'input-group-addon input-group-addon-fix3'
                :
                'input-group-addon'
            }
            style = {{fontSize: this.props.iconTextSize ? this.props.iconTextSize: '', background: this.props.bg ? this.props.bg : '', color: this.props.color ? this.props.color : ''}}
            onClick = {this.props.handleClick && (this.props.iconNumber || this.props.iconText) ? this.props.handleClick : null}
        >
            {this.props.handleClick && this.props.iconNumber ? 
                <i className = {Assets_.faInfoClass + ' fa-fw'}/>
            : this.props.handleClick && this.props.iconText ? <i className = {Assets_.faPlusButtonClass}/> : void 0}
            {this.props.iconNumber ? 
            <span className = "icon-number">
                {this.props.iconNumber}
            </span> : this.props.iconText ? 
            <span className = "icon-text" style = {this.props.iconTextSize ? {fontSize: this.props.iconTextSize} : {}}>
            {this.props.iconText}</span> :
            this.props.icon ? <i className = {this.props.icon}/> : this.props.iconSvg ? <i style = {{color: 'inherit', position: 'relative'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Assets_[this.props.iconSvg]('#555', 16, 0, 4)}</i> 
            : <FontAwesomeIcon icon = {this.props.Icon}/>}
        </span>}
        {this.props.prefix ?<span className = "prefix">+{this.props.prefix}</span>: void 0}
        {this.props.description ? 
        <input 
          title = {this.props.description}
          style = {{fontWeight:this.props.thick ? 700 : 400}}
          list = {this.props.list}
          value = {this.props.value}
          className = {input_classname} 
          type = {this.props.type}
          placeholder = {this.props.placeholder} 
          onChange = {(event) => this.onChange(event)}
        />
        :
        <input 
          value = {this.props.value}
          style = {{fontWeight:this.props.thick ? 700 : 400}}
          className = {input_classname}
          list = {this.props.list}
          type = {this.props.type}
          placeholder = {this.props.placeholder}
          onChange = {(event) => this.onChange(event)}

        />
        }
        {this.props.children}
      </div>
      )
      }
    }

    private onChange(event: React.FormEvent<HTMLInputElement>): void {
        this.state.Text = ''
        this.props.onChange(event.currentTarget.value)
    }
  }

export class FormInputNumber extends React.Component<{onChange:(text:string)=>void,description?:string,readOnly?:boolean
,value: string,type:string, ClassName: string, min:string, max?:string, icon?:string, outerDiv?:string,iconText?:string,
placeholder?:string,handleClick?:()=>void,background?:string,iconDescription?:string,inTitle?:boolean,iconTextS?:string,
fix2?: boolean, iconTextC?: string, noIconTextFix?: boolean, disabled?: boolean, Icon?: IconDefinition},{Text: string}> {

  public state = {
    Text: '',
  }

/* min props se lahko kasneje tudi umakne */
    public render(): JSX.Element {

      let className: string = ''
      if (this.props.ClassName.indexOf('h26') !== -1) { className += ' h26' }

      if (this.props.noIconTextFix === undefined && this.props.iconText && this.props.iconText === 'W') { className += ' icon-text icon-text-fix-w' } else if (this.props.noIconTextFix === undefined && this.props.iconText) { className += ' icon-text icon-text-fix' } else if (this.props.noIconTextFix) { className += ' icon-text no-icon-text-fix' }

      if (this.props.handleClick) { className += ' choose' }

      if (this.props.fix2) { className += ' input-group-addon-fix2' }

      if ((this.props.icon || this.props.iconText) && this.props.outerDiv || this.props.Icon) {
        const style: any = {}
        if (this.props.background) { style['background'] = this.props.background }
        if (this.props.iconTextS) { style['fontSize'] = this.props.iconTextS }
        if (this.props.iconTextC) { style['color'] = this.props.iconTextC }

        return (
        <div
            style = {this.props.inTitle ? {marginTop:'25px'} : {}}
            className = {this.props.outerDiv}
        >
            <span
                title = {this.props.iconDescription ? this.props.iconDescription : null}
                style = {style}
                onClick = {this.props.handleClick ? this.props.handleClick : null}
                className = {'input-group-addon' + className}
            >
            {this.props.iconText ? this.props.iconText : this.props.icon ? <i className = {this.props.icon}/> : <FontAwesomeIcon icon = {this.props.Icon}/>}
            </span>
            <input
                disabled = {this.props.disabled !== undefined ? this.props.disabled : void 0}
                placeholder = {this.props.placeholder ? this.props.placeholder : ''}
                readOnly = {this.props.readOnly ? this.props.readOnly : false}
                style = {this.props.readOnly && this.props.readOnly.toString() === 'true' ? {background: '#eee'}: {}}
                title = {this.props.description}
                value = {this.props.value}
                className = {this.props.ClassName}
                type = {this.props.type}
                min = {this.props.min}
                max = {this.props.max ? this.props.max : ''}
                onChange = {(event) => this.onChange(event)}
            />
        </div>
        )
      }

      return (
        <input
            disabled = {this.props.disabled !== undefined ? this.props.disabled : void 0}
            placeholder = {this.props.placeholder ? this.props.placeholder : ''}
            readOnly = {this.props.readOnly ? this.props.readOnly : false}
            style = {this.props.readOnly && this.props.readOnly.toString() === 'true' ? this.props.inTitle ?
            {float: 'left', display: 'block', width:'30%', background: '#eee'} : {background: '#eee'}: {}}
            title = {this.props.description}
            value = {this.props.value}
            className = {this.props.ClassName}
            type = {this.props.type}
            min = {this.props.min}
            max = {this.props.max ? this.props.max : ''}
            onChange = {(event) => this.onChange(event)}
        />
        )
    }

  private onChange(event: React.FormEvent<HTMLInputElement>): void {
      this.state.Text = ''
      this.props.onChange(event.currentTarget.value)
  }
}

export class FormInputArea extends React.Component<{onChange: (text: string) => void, value:string, inputGroupAddonBottom?: boolean,
placeholder:string, icon?:string, ClassName:string, iconText?: string, bold?: boolean, boldText?: boolean}, {Text: string}> {

  public state = {
    Text: '',
  }

    public render(): JSX.Element {
      return (
        <div className = "input-group margin-bottom-sm">
          <span
            className = {this.props.inputGroupAddonBottom ? 'input-group-addon input-group-addon-bottom' : 'input-group-addon'}
            style = {this.props.boldText !== undefined && this.props.boldText === true ? {fontWeight: 700} : {fontWeight: 400}}
          >
            {this.props.iconText ? this.props.iconText : <i className = {this.props.icon}/>}
          </span>
          <textarea
            style = {this.props.bold !== undefined && this.props.bold === true ? {fontWeight: 700} : {fontWeight: 400}}
            value = {this.props.value}
            className = {this.props.ClassName}
            placeholder = {this.props.placeholder}
            onChange = {(event) => this.onChange(event)}
          />
        </div>
        )
    }

  private onChange(event: React.FormEvent<HTMLTextAreaElement>): void {
    this.state.Text = ''
    this.props.onChange(event.currentTarget.value)
  }
}

export class InputSearch extends React.Component<{onChange: (text: string) => void, value:string, type:string,
placeholder:string, ClassName:string}, {Text: string}> {

    public state = {
      Text: '',
    }

  public render(): JSX.Element {

    return (
      <div className = "input-group margin-bottom-sm fl">
        <input 
          value = {this.props.value}
          className = {this.props.ClassName}
          type = {this.props.type}
          placeholder = {this.props.placeholder}
          onChange = {(event) => this.onChange(event)}
        />
      </div>
    )
  }

  private onChange(event: React.FormEvent<HTMLInputElement>): void {
    this.state.Text=''
    this.props.onChange(event.currentTarget.value)
  }
  }

export class FormInputOld extends React.Component<{onChange: (text: string) => void, type:string, placeholder:string,
icon:string,ClassName:string}, {Text: string}> {

  public state = {
    Text: '',
  }

  public render(): JSX.Element {
    return (
        <div className = "input-group margin-bottom-sm">
            <span className = "input-group-addon">
            <i className={this.props.icon}/>
            </span>
            <input 
                className = {this.props.ClassName}
                type = {this.props.type}
                placeholder = {this.props.placeholder}
                onChange = {(event) => this.onChange(event)}
            />
        </div>
    )
  }

  private onChange(event: React.FormEvent<HTMLInputElement>): void {
    this.state.Text = event.currentTarget.value
    this.props.onChange(event.currentTarget.value)
  }
}

export class FormCheckbox extends React.Component<{onChange: (checked: boolean,value:string) => void, dc:boolean,
value:string, ClassName:string, name:string,key_?:boolean}, {checked: boolean,value:string}> {

    public componentDidMount() {
      this.setState({value:this.props.value})
    }

    public render(): JSX.Element {

      return (
        <label className = {this.props.ClassName}>
          <input 
            defaultChecked = {this.props.dc}
            type = "checkbox"
            onChange = {(event) => this.onChange(event)}
            value = ""
          />
          {this.props.name}
        </label>
      )
    }

    private onChange(event: React.FormEvent<HTMLInputElement>): void {

      this.setState({checked:event.currentTarget.checked})
      
//      this.state.checked = event.currentTarget.checked
      this.props.onChange(event.currentTarget.checked,this.props.value)
    }
/*
    state = {
      checked: false
    }
*/
    
}
// input-group-addon
export class FormSelect extends React.Component<{span?:boolean,verywide?:boolean, logic?:boolean, float?:boolean,
narrow?:boolean, wide?:boolean, o0?:string, floatRight?: boolean,w50?:boolean,w25?:boolean,w25p?:boolean,right?:boolean,
level225?:boolean, w23p?:boolean,w24p?:boolean,onChange: (text: string) => void,code?:boolean,value:string,icon?:string,
icon0?:string, icons?:string[], ClassName:string, o1?:string, o2?:string, o3?:string, o4?:string, o5?:string,o6?:string,
o7?:string, o8?:string, o9?:string, o10?:string,o12?:string,o13?:string,o11?:string,data:any[], floatright?:boolean, 
other?:boolean, level?:boolean, level_?:boolean, level_narrow?:boolean,data2?:any[], label1?:string,label2?:string,
titleFix?:boolean,titleFixTop?:boolean,o14?:string,o15?:string,o16?:string, level20?: boolean, title?:string, Icon?: IconDefinition,
background?: string, color?: string, iconText?:string|string[],boldspan?:boolean,level165?:boolean, verynarrow?:boolean,
bigspan?:boolean, handleClick?:() => void,Background?:string,selectedByID?:number[],names?:boolean,boldyspan?:boolean,
mt20?: boolean, mt0?: boolean, mainCurrency?: number, clear?: boolean, verynarrow2?:boolean,mt_5?:boolean,mt5?: boolean,
rewardFor?: number, iconTextC?: string, iconTextS?: string, lineHeight?: string, validation?: boolean, disabled?: boolean}, {Text:string}> {

  public state = {
    Text: '',
  }

  public render(): JSX.Element {

    let input_classname = ''
    if (this.props.validation !== undefined) {
        if (this.props.validation.toString() === 'true') { 
        input_classname += ' border-green'
        } else {
        input_classname += ' border-red'
        }
    }

    const icon:JSX.Element[] = []
    // console.log (this.state.Text)
    if (this.props.icon0 && parseInt(this.props.value) === 0) {
        icon.push(<i key = "0" className = {this.props.icon0}/>)
    } else if (this.props.Icon) {
        icon.push(<FontAwesomeIcon key = "0" icon = {this.props.Icon}/>)
    } else if (this.props.icon) {
        icon.push(<i key = "0" className = {this.props.icon}/>)
    } else if (this.props.icons) {
        for (let i:number = 0; i < this.props.icons.length; i++) { icon.push(<i key = {i.toString()} className = {this.props.icons[i]}/>) }
    } else {
      icon.push(<i key = "0" className={Assets_.faQuestionButtonClass}/>)
    }

    const Rows:JSX.Element[] = []
    if (this.props.data !== undefined && this.props.data.length === 1 && this.props.data[0] === 0) {

      if (this.props.o1 === 'dynamic-number-generator') {
        if (this.props.o2 !== undefined) { 
        for (let i:number = 0; i <= parseInt(this.props.o2); i++) {
            const I = i.toString()
            Rows.push(<option key = {I} value = {I}>{I}</option>)
        }
        }

      } else {

        if (this.props.o1 !== undefined) { Rows.push(<option key = "0" value = "0">{this.props.o1}</option>) }
        if (this.props.o2 !== undefined) { Rows.push(<option key = "1" value = "1">{this.props.o2}</option>) }
        if (this.props.o3 !== undefined) { Rows.push(<option key = "2" value = "2">{this.props.o3}</option>) }
        if (this.props.o4 !== undefined) { Rows.push(<option key = "3" value = "3">{this.props.o4}</option>) }
        if (this.props.o5 !== undefined) { Rows.push(<option key = "4" value = "4">{this.props.o5}</option>) }
        if (this.props.o6 !== undefined) { Rows.push(<option key = "5" value = "5">{this.props.o6}</option>) }
        if (this.props.o7 !== undefined) { Rows.push(<option key = "6" value = "6">{this.props.o7}</option>) }
        if (this.props.o8 !== undefined) { Rows.push(<option key = "7" value = "7">{this.props.o8}</option>) }
        if (this.props.o9 !== undefined) { Rows.push(<option key = "8" value = "8">{this.props.o9}</option>) }
        if (this.props.o10 !== undefined) { Rows.push(<option key = "9" value = "9">{this.props.o10}</option>) }
        if (this.props.o11 !== undefined) { Rows.push(<option key = "10" value = "10">{this.props.o11}</option>) }
        if (this.props.o12 !== undefined) { Rows.push(<option key = "11" value = "11">{this.props.o12}</option>) }
        if (this.props.o13 !== undefined) { Rows.push(<option key = "12" value = "12">{this.props.o13}</option>) }
        if (this.props.o14 !== undefined) { Rows.push(<option key = "13" value = "13">{this.props.o14}</option>) }
        if (this.props.o15 !== undefined) { Rows.push(<option key = "14" value = "14">{this.props.o15}</option>) }
        if (this.props.o16 !== undefined) { Rows.push(<option key = "15" value = "15">{this.props.o16}</option>) }
      }
    } else if (this.props.data !== undefined) {
 
        if (this.props.data.length === 0) {

          if (this.props.o0) {
            if (parseInt(this.props.o0) === 0) {
              Rows.push(<option key="0" value="0">{Assets.pleaseSelectSubEntityType}</option>)
            } else if (parseInt(this.props.o0) === 1 && this.props.o3) {
              Rows.push(<option key="0" value="0">{this.props.o3}</option>)
            } else if (parseInt(this.props.o0) === 2 && this.props.o4) {
              Rows.push(<option key="0" value="0">{this.props.o4}</option>)
            } else if (parseInt(this.props.o0) === 3 && this.props.o5) {
              Rows.push(<option key="0" value="0">{this.props.o5}</option>)
            } else if (parseInt(this.props.o0) === 4 && this.props.o6) {
              Rows.push(<option key="0" value="0">{this.props.o6}</option>)
            } else if (parseInt(this.props.o0) === 5 && this.props.o7) {
              Rows.push(<option key="0" value="0">{this.props.o7}</option>)
            } else {
              if (this.props.o2 !== undefined) { Rows.push(<option key="0" value="0">{this.props.o2}</option>) }
            }
            
          } else {
            if (this.props.o1 !== undefined) { Rows.push(<option key = "0" value = "0">{this.props.o1}</option>) }
          }
        } else {

          if (this.props.o0) {
            if (parseInt(this.props.o0) === 1 && this.props.o3) {
              Rows.push(<option key="0" value="0">{this.props.o3}</option>)
            } else if (parseInt(this.props.o0) === 2 && this.props.o4) {
              Rows.push(<option key="0" value="0">{this.props.o4}</option>)
            } else if (parseInt(this.props.o0) === 3 && this.props.o5) {
              Rows.push(<option key="0" value="0">{this.props.o5}</option>)
            } else if (parseInt(this.props.o0) === 4 && this.props.o6) {
              Rows.push(<option key="0" value="0">{this.props.o6}</option>)
            } else if (parseInt(this.props.o0) === 5 && this.props.o7) {
              Rows.push(<option key="0" value="0">{this.props.o7}</option>)
            } else {
              if (this.props.o2 !== undefined) { Rows.push(<option key="0" value="0">{this.props.o2}</option>) }
            }
          } else {
            if (this.props.selectedByID) {
              if (this.props.o2 !== undefined) {
                Rows.push(<option disabled = {true} key = "0" value = "0">{this.props.o2}</option>)
              }

              Rows.push(
                <option
                  disabled = {this.props.selectedByID.length === this.props.data.length}
                  key = {Assets_.SELECT_ALL.toString()}
                  value = {Assets_.SELECT_ALL.toString()}
                >{Assets.SelectAll}
                </option>)
              Rows.push(
                <option
                  disabled = {this.props.selectedByID.length === 0}
                  key = {Assets_.DESELECT_ALL.toString()}
                  value = {Assets_.DESELECT_ALL.toString()}
                >{Assets.DeselectAll}
                </option>)
            } else {
              if (this.props.o2 !== undefined) { Rows.push(<option key = "0" value = "0">{this.props.o2}</option>) }
            }
          }

          let i :number = 0

          if (this.props.code && this.props.code === true) {

            this.props.data.forEach((item: {code:string, id:string, name?:string, Name?:string, disabled?: boolean}) => {
              i++
              Rows.push(<option key = {i.toString()} value = {i.toString()}>
              {item.code} - {item.name?item.name:item.Name}</option>)
            })

          } else {
            if (!this.props.data2) {
              if (this.props.names) {
                this.props.data.forEach(
                  (item: {id: string, main: boolean, names: string[], Name?: string, name?: string}) => {
                  i++
                  if (this.props.selectedByID) {

                    const isMain: boolean = this.props.mainCurrency !== undefined && this.props.mainCurrency > 0 ?
                    parseInt(item.id) === this.props.mainCurrency : item.main === true
                    let selected: boolean = false
                    for (const select of this.props.selectedByID) {
                      if (select === parseInt(item.id)) {
                        selected = true
                        break
                      }
                    }
                    const sel: string = '+'
                    Rows.push(
                      <option
                        key = {i.toString()}
                        value = {item.id}
                        className = {selected ? isMain ? 'option-selected-main' : 'option-selected' : ''}
                      >
                        {/*isMain ? item.name + '!' : item.name*/}
                        {item.name ? selected ? sel + item.name + sel: item.name : selected ? sel + item.Name  + sel : item.Name}
                      </option>)
                  } else { Rows.push(
                    <option 
                      key = {i.toString()} 
                      value = {item.id}
                    >
                      {item.name ? item.name : item.Name}&nbsp;&nbsp;({item.names.length})
                    </option>,
                    )
                  }
                })
              
              } else if (this.props.data) {
                // console.log(this.props.data)
                this.props.data.forEach((item: {id: string, main: boolean, rewardIntent?: number[], Name?: string, name?: string, disabled?: boolean}) => {
                    let proceed: boolean = false

                    if (this.props.rewardFor === undefined || item.rewardIntent === undefined || item.rewardIntent.length === 0 || item.rewardIntent.length === 1 && item.rewardIntent[0] === 0) {
                        proceed = true
                    } else {
                        for (const Item of item.rewardIntent) {
                            if (Item === this.props.rewardFor) {
                                proceed = true
                                break
                            }
                        }
                    }

                    if (proceed) {
                    i++
                    if (this.props.selectedByID) {
                        // console.log (JSON.stringify(item))
                        const isMain: boolean = this.props.mainCurrency !== undefined && this.props.mainCurrency > 0 ?
                                            parseInt(item.id) === this.props.mainCurrency :
                                            item !== undefined && item !== null ? item.main === true : false
                        let selected: boolean = false
                        for (const select of this.props.selectedByID) {
                        if (select === parseInt(item.id)) {
                            selected = true
                            break
                        }
                        }
                        const sel: string = '+'
                        if (item !== undefined && item !== null) {
                        Rows.push(
                        <option
                            key = {i.toString()}
                            value = {item.id}
                            className = {selected ? isMain ? 'option-selected-main' : 'option-selected' : ''}
                        >
                            {/*isMain ? item.name + '!' : item.name*/}
                            {item.name ? selected ? sel + item.name + sel: item.name : selected ? sel + item.Name  + sel : item.Name}
                        </option>)
                        }
                    } else { Rows.push(<option key = {i.toString()} disabled = {item.disabled} value = {item.id}>{item.name ? item.name : item.Name}</option>) }
                  }
                })                
              }
            } else {

              this.props.data.forEach((item:{id:string, name?:string, Name?:string}) => {

                  i++
                  Rows.push(<option key = {i.toString()} value = {item.id}>
                  {this.props.label1}{item.name?item.name:item.Name}</option>)
              })

              this.props.data2.forEach((item:{id:string, name?:string, Name?:string}) => {

                  i++
                  Rows.push(<option key = {i.toString()} value = {item.id}>
                  {this.props.label2}{item.name?item.name:item.Name}</option>)
              })
            }
          }
        }
      }

    let classname: string = 'input-group margin-bottom-sm select'

    if (this.props.right && this.props.right === true) {
      classname += '-right'
    } else if(this.props.narrow && this.props.narrow === true) {
      classname += '-narrow'      
    } else if (this.props.verynarrow && this.props.verynarrow === true && (this.props.selectedByID ||this.props.mt20)) {
      classname += '-extremelynarrow'
      if (this.props.mt20) { classname += ' mt20' }
    } else if (this.props.verynarrow && this.props.verynarrow === true && this.props.verynarrow2) {
      classname += '-verynarrow2'
    } else if (this.props.verynarrow && this.props.verynarrow === true) {
      classname += '-verynarrow'
    } else if (this.props.wide && this.props.wide === true) {
      classname += '-wide'
    } else if (this.props.verywide && this.props.verywide === true) {
      classname += '-verywide'
    } else if (this.props.logic && this.props.logic === true) {
      classname += '-logic ml'
    }
    
    if (this.props.mt0) { classname += ' mt0' }
    if (this.props.clear) { classname += ' clear-both' }

    if (this.props.float && this.props.float === true) {
      classname += ' fl'
    }

    if (this.props.floatRight && this.props.floatRight === true) {
      classname += ' fr'
    }

    if (this.props.boldspan) {
      classname += ' boldspan'
    }

    if (this.props.boldyspan) {
      classname += ' boldyspan'
    }

    if (this.props.bigspan) {
      classname += ' bigspan'
    }
    
    if (this.props.level && this.props.level === true) {
      classname += ' level fl'
    }

    if (this.props.level20 && this.props.level20 === true) {
      classname += ' level20 fl'
    }
   
    if (this.props.level165 && this.props.level165 === true) {
      classname += ' level165 fl'
    }

    if (this.props.mt_5 && this.props.mt_5 === true) {
      classname += ' mt-5'
    }

    if (this.props.mt5 && this.props.mt5 === true) {
      classname += ' mt5'
    }

    if (this.props.level_ && this.props.level_ === true) {
      classname += ' level fr'
    }

    if (this.props.level_narrow && this.props.level_narrow === true) {
      classname += ' level-narrow fl'
    }

    if (this.props.other && this.props.other === true) {
      classname += ' other fl'
    }

    if (this.props.titleFix && this.props.titleFix === true) {
      classname += ' mt20 ml5'
    }

    if (this.props.titleFixTop && this.props.titleFixTop === true) {
      classname += ' mt20'
    }

    if (this.props.w50 && this.props.w50 === true) {
      classname += ' w50'
    }

    if (this.props.w25 && this.props.w25 === true) {
      classname += ' w25'
    }

    if (this.props.w25p && this.props.w25p === true) {
      classname += ' w25p'
    }

    if (this.props.level225 && this.props.level225 === true) {
      classname += ' level225'
    }

    if (this.props.w24p && this.props.w24p === true) {
      classname += ' w24p'
    }

    if (this.props.w23p && this.props.w23p === true) {
      classname += ' w23p'
    }

    let classnameSpan :string = 'input-group-addon'

    if (this.props.icon && this.props.icon === Assets_.faPercentageFwClass) {
      classnameSpan += ' input-group-addon-percent'
    }

    if (this.props.wide && this.props.wide === true) {
      classnameSpan += ' input-group-addon-select-wide'
    }
    if (this.props.handleClick) {
      classnameSpan += ' choose'
    }

    let selectClassName = this.props.ClassName
    if (this.props.wide && this.props.wide === true) {
      selectClassName += ' select-wide'
    }

    if (this.props.w25 && this.props.w25 === true) {
      selectClassName += ' w25'
    }

    if (this.props.floatright && this.props.floatright === true) {
      selectClassName += ' fr'
    }

    if (this.props.span) {

      if (this.props.background && this.props.color) {

      return (
        <select
          disabled = {this.props.disabled}
          style = {{background:this.props.background, color:this.props.color}}
          title = {this.props.title}
          value = {this.props.value}
          className = {selectClassName + input_classname}
          onChange = {(event) => this.onChange(event)}
        >
          {Rows}
        </select>)
      } else {
      return (
        <select
          disabled = {this.props.disabled}
          title = {this.props.title}
          value = {this.props.value}
          className = {selectClassName + input_classname}
          onChange = {(event) => this.onChange(event)}
        >
          {Rows}
        </select>
    )
      }} else {
      const style: any = {}
      if (this.props.Background) { style['background'] = this.props.Background }
      if (this.props.iconTextC) { style['color'] = this.props.iconTextC }
      if (this.props.iconTextS) { style['fontSize'] = this.props.iconTextS }
      if (this.props.lineHeight) { style['lineHeight'] = this.props.lineHeight }
      if (this.props.background && this.props.color) {
      return (
        <div className = {classname}>
          <span 
            onClick = {this.props.handleClick ? this.props.handleClick : null}
            style = {style}
            className = {classnameSpan}
          >
            {this.props.iconText ? !(this.props.iconText instanceof Array) ? this.props.iconText : void 0 : icon}

            {this.props.iconText && this.props.iconText instanceof Array ?
              this.props.iconText[0] : void 0}
            {this.props.iconText && this.props.iconText instanceof Array ?
              <sup>{this.props.iconText[1]}</sup> : void 0}
          </span>
          <select
            disabled = {this.props.disabled}
            style = {{background:this.props.background, color:this.props.color}}
            value = {this.props.value} 
            className = {selectClassName + input_classname}
            title = {this.props.title}
            onChange = {(event) => this.onChange(event)}
          >
            {Rows}
          </select>
        </div>)
      } else {
      return (
        <div className = {classname}>
          <span
            style = {style}
            onClick = {this.props.handleClick ? this.props.handleClick : null}
            className = {classnameSpan}
          >
            {this.props.iconText ? !(this.props.iconText instanceof Array) ? this.props.iconText : void 0 : icon}
            {this.props.iconText && this.props.iconText instanceof Array ?
              this.props.iconText[0] : void 0}
            {this.props.iconText && this.props.iconText instanceof Array ?
              <sup>{this.props.iconText[1]}</sup> : void 0}
          </span>
          <select
            disabled = {this.props.disabled}
            value = {this.props.value} 
            className = {selectClassName + input_classname}
            title = {this.props.title}
            onChange = {(event) => this.onChange(event)}
          >
            {Rows}
          </select>
        </div>
    )
      }}
  }

  private onChange(event: React.FormEvent<HTMLSelectElement>): void {
    this.state.Text = event.currentTarget.value
    this.props.onChange(event.currentTarget.value)
  }
}

export class FormCurrency extends React.Component<{onChange: (quantity: string,value2:string) => void, 
isNormalInteger:boolean, value:string, value2:string, ClassName:string, name:string, description:string}, 
{value2:string,quantity:string}> {

    public state = {
      quantity: '',
      value2: this.props.value2,
    }

    public componentDidMount() {
      this.setState({value2:this.props.value2})
    }

    public render(): JSX.Element {

      return (
        <div className="currency">
          <span className = "currency">
            {this.props.name}
          </span>
          {this.props.isNormalInteger ?
          <i className = {Assets_.faCheckClass}/> : 
          <i className = {Assets_.faCancelClass}/>}
          <input 
            value = {this.props.value}
            className = "currency"
            type = "text"
            onChange = {(event) => this.onChange(event)}
            placeholder = {this.props.description}
          />
        </div>
      )
    }

    private onChange(event: React.FormEvent<HTMLInputElement>): void {
      
      this.state.quantity = ''
      this.props.onChange(event.currentTarget.value,this.props.value2)
    }
}

export class RadioButton2 extends React.Component<{onClick: (text: string) => void, value:string, ClassName:string, 
title:string, i1:string, i2:string,text?:string}, {Text: string}> {

    public state = {
      Text: '',
    }

    public componentDidMount() {
      this.state.Text = this.props.value
    }

    public render(): JSX.Element {

      return (
              <div className={this.props.ClassName}>              
              {this.props.text?
              <button 
                title = {this.props.title} 
                onClick = {(event) => this.onClick(event)}
                className = "radiobutton2"
              >
                {this.props.text}&nbsp;
                <img 
                  className = "activity-image"
                  src = {this.props.value === '1' ? this.props.i1 : this.props.i2}
                />
              </button>
              :
              <button 
                title = {this.props.title}
                onClick = {(event) => this.onClick(event)}
                className = "radiobutton2"
              >
                  <img 
                    className = "activity-image"
                    src = {this.props.value === '1' ? this.props.i1 : this.props.i2}
                  />
              </button>
              }
              </div>
        )
    }

    private onClick(event: React.FormEvent<HTMLElement>): void {

      if (this.state.Text == '2') {
        this.state.Text = '1'
        this.props.onClick('1')
      } else if (this.state.Text == '1') {
        this.state.Text = '2'
        this.props.onClick('2')
      }      
    }
  }

export class RadioButton1 extends React.Component<{onClick: (text: string) => void, value:boolean, ClassName:string,
title:string, i1:string, i2:string,text?:string}, {Text: string}> {

  public state = {
    Text: '',
  }

  public componentDidMount() {
    this.state.Text = this.props.value.toString()
  }

  public render(): JSX.Element {

    return ( 
        <div className={this.props.ClassName}>              
            {this.props.text?
            <button 
              title = {this.props.title} 
              onClick = {(event) => this.onClick(event)}
              className = "radiobutton1"
            >
              {this.props.text}&nbsp;
              <img 
                className = "activity-image"
                src = {this.props.value == true ? this.props.i1 : this.props.i2}
              />
            </button>
            :
            <button 
              title = {this.props.title}
              onClick = {(event) => this.onClick(event)}
              className = "radiobutton1"
            >
              <img 
                className = "activity-image"
                src = {this.props.value == true ? this.props.i1 : this.props.i2}
              />
            </button>
            }
        </div>
      )
  }

  private onClick(event: React.FormEvent<HTMLElement>): void {

    if (this.state.Text == 'false') {
      this.state.Text = 'true'
      this.props.onClick('true')
    } else if (this.state.Text == 'true') {
      this.state.Text = 'false'
      this.props.onClick('false')
    }
  }
}

export class RadioButton3 extends React.Component<{ onClick: (text: string) => void, value:boolean, ClassName:string,
title?:string, i1:string, i2:string, text?:string, width?:string}, {Text: string}> {

  public state = {
    Text: '',
  }

  public componentDidMount() {
    this.state.Text = this.props.value.toString()
  }

  public render(): JSX.Element {

    if (this.props.ClassName.startsWith('input-group-radiobutton3b') || 
        this.props.ClassName.startsWith('input-group-radiobutton3c')) {

      return (
        <button 
          title = {!this.props.title ? '' : this.props.title}
          // style = {this.props.width ? {width:this.props.width} : {}}
          onClick = {(event) => this.onClick(event)}
          className = {this.props.ClassName}
        >
          {this.props.text}&nbsp;
          <img 
            className = "activity-image"
            src = {this.props.value == true ? this.props.i1 : this.props.i2}
          />
        </button>)
    } else {
      return (
        <div 
          className = {this.props.ClassName} 
          style = {this.props.width ? {width:this.props.width} : {}}
        >
          {this.props.text ?
          <button 
            title = {!this.props.title ? '' :this.props.title}
            style = {this.props.width ? {width:this.props.width} : {}}
            onClick = {(event) => this.onClick(event)}
            className = "radiobutton3"
          >
            {this.props.text}&nbsp;
            <img 
              className = "activity-image"
              src = {this.props.value == true ? this.props.i1 : this.props.i2}
            />
          </button>
          :
          <button 
            title = {!this.props.title ? '' : this.props.title}
            // style = {this.props.width ? {width:this.props.width} : {}}
            onClick = {(event) => this.onClick(event)}
            className = "radiobutton3"
          >
            <img 
              className = "activity-image"
              src = {this.props.value == true ? this.props.i1 : this.props.i2}
            />
          </button>
          }
        </div>
      )
    }
  }

  private onClick(event: React.FormEvent<HTMLElement>): void {

    if (this.state.Text == 'false') {
        this.state.Text = 'true'
        this.props.onClick('true')
    } else if (this.state.Text == 'true') {
        this.state.Text = 'false'
        this.props.onClick('false')
    }
  }
}