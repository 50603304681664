import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from 'src/Logic/Main'
import { Logic } from 'src/Logic/Type'
import ReactTable from 'react-table'
import {gotoTop, injectSpaces} from '../../../Logic/Utils'
import * as TableUtils from '../../Reusables/TableUtils'
import {FormInputNumber, FormInput} from '../../Reusables/Inputs'
import {faCheck, faTimes, faSave, faMoneyBillAlt} from '@fortawesome/free-solid-svg-icons'
import {IBonusWageringRequirement, GameCategories, BonusDataWageringRequirementNew} from '../../../Logic/Database/DatabaseData'
import {ButtonIcon} from '../../Reusables/Button'
import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as Config from '../../../Logic/Config'

interface State {
    mode: boolean
    bonusWageringState: IBonusWageringRequirement
    bonusWageringState_: IBonusWageringRequirement
    name: string,
}

export class BonusWageringRequirement extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            mode: false,
            bonusWageringState: {
                id: 0,
                data: {
                    baseWageringRequirement: 0,
                    wageringRequirementsByContribution: []
                }
            },
            bonusWageringState_: {
                id: 0,
                data: {
                    baseWageringRequirement: 0,
                    wageringRequirementsByContribution: []
                }
            },

            name: ''
        }
        this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }
    
    public handleEdit(row: any) {
        gotoTop()

        const bonusWageringState: IBonusWageringRequirement = Object.assign ({}, JSON.parse(JSON.stringify(row.original)))

        if (row) {
            this.setState( {
                mode: true,
                bonusWageringState,
                bonusWageringState_: bonusWageringState,
                name: bonusWageringState && bonusWageringState.data.name && bonusWageringState.data.name ? bonusWageringState.data.name : '',
            })
        }

    }

    public handleDelete(row: any) {
        if (row) {
            if (confirm(Assets.sureToDelete + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.data.name + '"?')) {
                
                    DbRoutes.wsBasicDelete(Config.amBonusWageringRequirement, row.original.id) 
         
                
            }
        }
        
    }

    public handleInsertUpdate() {
/*
        if (this.state.bonusWageringState.data.baseWageringRequirement && this.state.bonusWageringState.data.wageringRequirementsByContribution) {
            if (this.state.bonusWageringState.data.wageringRequirementsByContribution[Assets.category] === [] ||
                this.state.bonusWageringState.data.baseWageringRequirement === 0) {
                    alert (Assets.sfh)
                    return
                }
        } else {
            alert (Assets.sfh)
            return
        }
*/  
        const bonusWageringState: IBonusWageringRequirement = Object.assign({}, this.state.bonusWageringState)
    
        // bonusWageringState.id, bonusWageringState.data.default, Main.Instance.DatabaseDataState.bonusWageringRequirement
        let indexDefaultOccuring: number = -1
        if (bonusWageringState.data.default) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusWageringRequirement.length; i++) {
                if (this.state.mode && Main.Instance.DatabaseDataState.bonusWageringRequirement[i].id === bonusWageringState.id) {continue}
                if (Main.Instance.DatabaseDataState.bonusWageringRequirement[i].data.default) {
                    indexDefaultOccuring = i
                    break
                }
            }
    }
        if (indexDefaultOccuring > -1) {
            const toUpdateIBonusWageringRequirement: IBonusWageringRequirement[] = Object.assign([], Main.Instance.DatabaseDataState.bonusWageringRequirement)
            toUpdateIBonusWageringRequirement[indexDefaultOccuring].data.default = false
            Logic.Type.New (Main.Instance.DatabaseDataState, {ToUpdateIBonusWageringRequirement: true, toUpdateIBonusWageringRequirement: toUpdateIBonusWageringRequirement[indexDefaultOccuring]})
        } else {
            Logic.Type.New (Main.Instance.DatabaseDataState, {ToUpdateIBonusWageringRequirement: false, toUpdateIBonusWageringRequirement: undefined})
        }
        for (const item of Main.Instance.DatabaseDataState.bonusWageringRequirement) {

            if (bonusWageringState.id === item.id) {
                continue
            }

            if (bonusWageringState.data.name.toLowerCase() === item.data.name.toLowerCase()) {
                alert(Assets.Bonus + ' ' + Assets.WageringRequirement.toLowerCase() + ' ' + Assets.alreadyInDatabase)
                return
            }
        
            if (bonusWageringState.data.baseWageringRequirement === item.data.baseWageringRequirement && 
                JSON.stringify(bonusWageringState.data.wageringRequirementsByContribution) === JSON.stringify(item.data.wageringRequirementsByContribution)) {
                
                        alert(Assets.Bonus + ' ' + Assets.WageringRequirement.toLowerCase() + Assets._with_ + Assets.sameCharacteristics + ' ' + Assets.alreadyInDB)
                        return
                    
                
               
            }
        }
        

        if (this.state.mode === false) {
          
            DbRoutes.wsBasicInsert(Config.amBonusWageringRequirement, bonusWageringState.data)
        } else {
            DbRoutes.wsBasicUpdate(Config.amBonusWageringRequirement, bonusWageringState.id, bonusWageringState.data)
            

            this.clearFields()
        }
    }

   

    public addPropertyMember(property: string) {
        let bonusWageringState: IBonusWageringRequirement = Object.assign({}, this.state.bonusWageringState)
        if (bonusWageringState === undefined) { bonusWageringState = {} }
        if (bonusWageringState.data === undefined) {bonusWageringState.data = {}}   
        if (bonusWageringState.data[property] === undefined) { bonusWageringState.data[property] = [] }
        if (property === 'wageringRequirementsByContribution') {
            bonusWageringState.data[property].push({category: 0, contribution: 0})
        } else {bonusWageringState.data[property].push(undefined)}
        this.setState({bonusWageringState})
        
    }

    public updateNumber(value: number, property: string, index?: number, property2?: string) {
        let bonusWageringState: IBonusWageringRequirement = Object.assign ({}, this.state.bonusWageringState)
        if (bonusWageringState === undefined) { bonusWageringState = {} }
        if (bonusWageringState.data === undefined) {bonusWageringState.data = {}}    
        if (property2) {
            bonusWageringState.data[property][index][property2] = value
        } else {bonusWageringState.data[property] = value}   
        this.setState({bonusWageringState})     
    } 

    public updateText(value: string, property: string) {
        let bonusWageringState: IBonusWageringRequirement = Object.assign({}, this.state.bonusWageringState)
        if (bonusWageringState === undefined) { bonusWageringState = {} }
        if (bonusWageringState.data === undefined) {bonusWageringState.data = {}}
        bonusWageringState.data[property] = value
        this.setState({bonusWageringState})
    }

    public updateBoolean(property: string) {
        let bonusWageringState: IBonusWageringRequirement = Object.assign({}, this.state.bonusWageringState)
        
        if (bonusWageringState === undefined) { bonusWageringState = {} }
        if (bonusWageringState.data === undefined) {bonusWageringState.data = {}}
       
        bonusWageringState.data[property] = !bonusWageringState.data[property]
        /* if (bonusWageringState.data[property] === true) {
            this.setState({
                bonusWageringState,
                
            
            })
        } else {
            this.setState({
                bonusWageringState
            
            })
        } */
        this.setState({bonusWageringState})
    }

    public getCategoryOptions(): JSX.Element[] {
        const categories: JSX.Element[] = []
        categories.push(<option key = "-1" value = {-1}>{Assets.Category + '?'}</option>)
        // console.log(Main.Instance.DatabaseDataState.scategories)
        if (Main.Instance.DatabaseDataState.gamesListCategories /*scategories*/) {
            for (const item of Main.Instance.DatabaseDataState.gamesListCategories /*scategories*/) {
                // if (item.data.active)
                if (item.categoryId === 0) {continue}
                categories.push(<option key = {item.categoryId.toString()} value = {item.categoryId}>{item.name}</option>)
            }
        }
        return categories
    }

    public removePropertyMember(property: string, i: number) {
        const bonusWageringState: IBonusWageringRequirement = Object.assign({}, this.state.bonusWageringState)
        bonusWageringState.data[property].splice (i, 1)
        this.setState({bonusWageringState}) 
    }

    public getWageringRequirements(): JSX.Element[] {
        const wr: JSX.Element[] = []
      
        wr.push (

            <FormInput
                description = {Assets.Base + ' ' + Assets.WageringRequirement + ' ' + Assets.Name.toLowerCase()}
                placeholder = {Assets.Name}
                narrow = {true}
                value = {this.state.bonusWageringState.data.name ? this.state.bonusWageringState.data.name : ''} 
                ClassName = "form-control register-input" 
                type = "text" 
                onChange = {(text) => this.updateText(text, 'name')} 
                Icon = {faMoneyBillAlt}
            />

        )

        wr.push (
            
            <FormInputNumber
                key = "-4"
                description = {Assets.Base + ' ' + Assets.WageringRequirement}
                placeholder = {Assets.Base + ' ' + Assets.WageringRequirementShort}
                outerDiv = "rows-per-table input-group fl mr pl0 ml"
                ClassName = "h30 w100px pl7"
                value = { this.state.bonusWageringState.data.baseWageringRequirement !== undefined ? this.state.bonusWageringState.data.baseWageringRequirement.toString(): ''}
                type = "number" 
                min = "0"
                Icon = {faCheck}
                onChange = {(text) => this.updateNumber(+text, 'baseWageringRequirement')}
            />
        )

        wr.push (
            <div  >
                <input id = {Assets.Default}  className = "dbl fl" style = {{marginTop: '8px', marginLeft: '10px'}} onChange = {() => this.updateBoolean('default')} type = "checkbox"  checked = {this.state.bonusWageringState.data.default ? this.state.bonusWageringState.data.default : false}/>
                <label htmlFor={Assets.Default} className = "fl mr pl0 ml" style = {{marginTop: '4px'}}>{Assets.Set.toLowerCase()}{Assets._as_}{Assets.Default.toLowerCase()}</label>
                
            </div>
            )

        return wr
    }

    
    public getContributionData(): JSX.Element[] {
        const wr: JSX.Element[] = []

        if (this.state.bonusWageringState.data.wageringRequirementsByContribution) {
            
            for (let i: number = 0; i < this.state.bonusWageringState.data.wageringRequirementsByContribution.length; i++) {
                wr.push (
                    <select
                        key = {i.toString()}
                        style = {{width: '115px', marginRight: '1px', height: '30px'}}
                        className = "fl form-control margin-bottom-sm "
                        onChange = {(e) => {
                            let bonusWageringState: IBonusWageringRequirement = Object.assign ({}, this.state.bonusWageringState)
                            if (bonusWageringState === undefined) { bonusWageringState = {} }
                            if (bonusWageringState.data === undefined) {bonusWageringState.data = {}} 
                            
                            
                            console.log(bonusWageringState.data.wageringRequirementsByContribution[i].category)
                            console.log(this.state.bonusWageringState.data.wageringRequirementsByContribution[i].category)
                            console.log(Main.Instance.DatabaseDataState.bonusWageringRequirement)
                            let found: boolean = false
                            for (const item of this.state.bonusWageringState.data.wageringRequirementsByContribution) {
                                if (+e.target.value === item.category) {
                                    found = true
                                    break
                                }
                            }
                            if (found) {
                                alert (Assets.DuplicateCategory)
                            } else {
                                bonusWageringState.data.wageringRequirementsByContribution[i].category = +e.target.value
                                this.setState({bonusWageringState})
                            }
                        }}
                        value = { this.state.bonusWageringState.data.wageringRequirementsByContribution[i] && this.state.bonusWageringState.data.wageringRequirementsByContribution[i].category ? this.state.bonusWageringState.data.wageringRequirementsByContribution[i].category : 0}
                    >
                        {this.getCategoryOptions()}

                    </select>
                )

                wr.push (
                    <FormInputNumber
                        key = {i.toString() + '__'}
                        description = {Assets.WageringContribution + Assets._for_ + Assets.Category.toLowerCase()}
                        placeholder = {Assets.WageringContributionShort}
                        outerDiv = "rows-per-table input-group fl mr pl0 margin-bottom-sm "
                        ClassName = "h30 w60 pl7 mr"
                        value = {
                            this.state.bonusWageringState.data.wageringRequirementsByContribution[i] && this.state.bonusWageringState.data.wageringRequirementsByContribution[i].contribution ? this.state.bonusWageringState.data.wageringRequirementsByContribution[i].contribution.toString() : '0'
                        }
                        max = {this.state.bonusWageringState.data.wageringRequirementsByContribution[i] && this.state.bonusWageringState.data.wageringRequirementsByContribution[i].contribution ? '100' : undefined}
                        type = "number" 
                        min = "0"
                        Icon = {faCheck}
                        onChange = {(text) => this.updateNumber(+text, 'wageringRequirementsByContribution', i, 'contribution')}
                    />
                )

                wr.push(
                    <div title = {Assets.RemoveAWageringRequirement} style = {{ marginLeft: '1%', zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                        <button onClick = {() => this.removePropertyMember('wageringRequirementsByContribution', i)} style = {{position: 'absolute', right: '14px', top: '0px', paddingRight: '5px', border: '0px', background: 'transparent', padding: '0px'}}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    </div>
                )
            }
        }

        wr.push (<button title = {Assets.AddANewWageringContribution} key = "-1" onClick = {() => this.addPropertyMember('wageringRequirementsByContribution')} className = "btn btn-success btn-sm mr0 margin-bottom-sm dbl fl">{Assets.AddANewWageringContribution}</button>) 
        
        return wr
                
    }
/*
[
                        {width: 100, Header: Assets.Slots, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.LiveCasino, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.BlackJack, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.Roulette, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.Slots, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.Poker, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},
                        {width: 100, Header: Assets.Slots, accessor: 'data.wageringRequirementsByContribution', Cell: (row) => 'a'},

                    ]

*/
/*
    public getCategoryColumns (input: BonusDataWageringRequirementNew[]): any[] {
        console.log (input)
        const cc: any[] = []
        for (const item of input) {
            cc.push({width: 100, Header: item.category, Cell: item.contribution})
        }
        return cc
    }
*/
    public getContribution (input: BonusDataWageringRequirementNew[], category: GameCategories): JSX.Element {
        if (input) {
            for (const item of input) {
                if (item.category === category) {
                    return <span>{item.contribution + '%'}</span>
                }
            }
        }
        return <span style = {{color: '#999'}}>100%</span>
    }
   
    public gotoInsertMode() {
        if (JSON.stringify(this.state.bonusWageringState) === this.state.bonusWageringState_) {
            this.clearFields()
        } else {
            if (confirm(Assets.UnsavedChanges)) {
                this.clearFields() 
            }                
        } 
    }
    public clearFields() {
        this.setState({
            mode: false,
            // bonusCode: false,
            bonusWageringState:                 {id: 0,
            data: {
                baseWageringRequirement: 0,
                wageringRequirementsByContribution: []
            }},
            bonusWageringState_:                 {id: 0,
            data: {
                baseWageringRequirement: 0,
                wageringRequirementsByContribution: []
            }},
            // max: false
        })
    }
    public render() {
        // if (Main.Instance.DatabaseDataState.bonusWageringRequirement) {
        //     let bonusWageringState: IBonusWageringRequirement = Object.assign ({}, this.state.bonusWageringState)
        //     if (bonusWageringState === undefined) { bonusWageringState = {} }
        //     if (bonusWageringState.data === undefined) { bonusWageringState.data = {} }        
            
        //     this.setState({bonusWageringState})
        // } 
        
        return (
        <div>
            
            <h2>{Assets.Bonus + ' ' + Assets.WageringRequirement}</h2>
            <br/>
            <div className = "clearfix"/>
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {this.state.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >
                    <div className = "create-input">
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <h4>{this.state.mode ? Assets.EditTheBonus + Assets.WageringRequirement + ': ' + this.state.name  : Assets.AddANewBonus + ' ' + Assets.WageringRequirement}</h4>

                            {this.state.mode ? 
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => this.gotoInsertMode()}
                                ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                                icon = {Assets_.faCancelButtonClass}
                            /> : <span/>}
                        </div>

                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            {this.getWageringRequirements()}
                        </div>
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            {this.getContributionData()}
                        </div>
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <ButtonIcon 
                                description = {this.state.mode ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => this.handleInsertUpdate()}
                                ClassName = {this.state.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                         </div>
                   
                    </div>
                </div>
            </div>
            
            
            <ReactTable
                data = {Main.Instance.DatabaseDataState.bonusWageringRequirement ? Main.Instance.DatabaseDataState.bonusWageringRequirement : []}
                columns = {[
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, this.handleEdit)},
                    {width: 35, headerClassName: 'th-left', Header: Assets.ID, accessor: 'id'},
                    {headerClassName: 'th-left', Header: <span title = {Assets.Base + ' ' + Assets.WageringRequirement + ' ' + Assets.Name.toLowerCase()}>{Assets.Name}</span>, accessor: 'data.name'},
                    {width: 250, headerClassName: 'th-left', Header: Assets.Base + ' ' + Assets.WageringRequirement, accessor: 'data.baseWageringRequirement', Cell: (row) => row.value + 'x ' + '(' + Assets.Times.toLowerCase() + ' ' + Assets.BonusAmount.toLowerCase() + ')'},
                    {width: 70, headerClassName: 'th-left', Header: Assets.Default, accessor: 'data.default', Cell: (row) =>  <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                    {Header: Assets.WageringRequirement + Assets._by_ + Assets.category, /*accessor: 'data.wageringRequirementsByContribution',*/ headerClassName: 'th-center risk-default', columns: [
                        {width: 120, headerClassName: 'th-left', Header: GameCategories[GameCategories.Slots], accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.Slots)},
                        {width: 120, headerClassName: 'th-left', Header: injectSpaces(GameCategories[GameCategories.LiveCasino]), accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.LiveCasino)},
                        {width: 120, headerClassName: 'th-left', Header: GameCategories[GameCategories.Blackjack], accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.Blackjack)},
                        {width: 120, headerClassName: 'th-left', Header: GameCategories[GameCategories.Roulette], accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.Roulette)},
                        {width: 120, headerClassName: 'th-left', Header: GameCategories[GameCategories.Baccarat], accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.Baccarat)},
                        {width: 120, headerClassName: 'th-left', Header: GameCategories[GameCategories.Poker], accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.Poker)},
                        {width: 120, headerClassName: 'th-left', Header: injectSpaces(GameCategories[GameCategories.VirtualSports]), accessor: 'data.wageringRequirementsByContribution', Cell: (row) => this.getContribution(row.value, GameCategories.VirtualSports)},
                    ]},

                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, this.handleDelete)},
                ]}
                showPaginationBottom = {false}
                pageSize = {Main.Instance.DatabaseDataState.bonusWageringRequirement ? Main.Instance.DatabaseDataState.bonusWageringRequirement.length : 0}
            />
        </div>
        )
    }
}
