import * as React from 'react'
import * as Assets from '../../Assets'
import * as Config from '../../../Logic/Config'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import { DashboardBarChart, DashboardPieChart, DashboardAreaChart, SpecialLineChart/*, DataChart, PieDataChart, BarDistribution*/} from '../../Reusables/Chart'
import ScaleSwitzerland from '../Reports/ScaleSwitzerland'
import ExpandSwitzerland from '../Reports/ExpandSwitzerland'
// import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {XButton, GetUnresolvedAll, GetResolved, GetUnresolved/*, getLegend*/} from '../RiskComplianceStore/RiskManagement'
import {/*faUserAlt, */faExclamationCircle, faTasks, faCheckCircle,/*faArrowCircleRight, faArrowCircleLeft, faUsers, */IconDefinition, faUserCircle, faCompress, faExpand, faTimes, faCaretLeft, faCaretRight/*, faCheck*/, faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import {IIssuesCount, TimelineEntrySubType, RaffleParticipantsData, IssueTypes, IIssueCount, /*IIssue/*, CountryData, Region, NationalityData, AgeGroup, AccountStatus*/} from '../../../Logic/Database/DatabaseData'
import {getIssuesTable, /* getBackground, getNames, getIssueType */} from '../RiskComplianceStore/ResponsibleGaming'
import CSCallbackResolvePopup from '../RiskComplianceStore/callbackResolvePopup'
import {SelectedView} from '../../../Logic/Various/Dashboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ToP} from '../Reports/SwitzerlandResources'
import {getChartLegend, /*getMapLegendRight,*/ getMapLegendBottom, getMapLegendTop, getMapLegendLeft} from './Legend'
import {getRaffleComponent, getRaffleResults, getRaffleNext, getRafflePrevious, getRaffleSelected} from './Raffle'
import {getTournamentResults, getPreviousTournaments, getNextTournaments} from './Tournament'
import {getLatest} from './Latest'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import History from '../RiskComplianceStore/history'
import {isUserGDPR/*, isUserRFSpecialist, isUserRGSpecialist*/, StringIsNotNumber, isUserReports, getIssueTypes/* isProduction/*, injectSpaces*/} from '../../../Logic/Utils'
export interface PlayerVisualize {id: number, firstName?: string, lastName?: string, plz?: number, city?: string, locationN?: number, locationE?: number, win?: string, rafflePrimary?: boolean, bet?: number, data?: RaffleParticipantsData}
export interface PlayersCantonal {cantons?: number[], cantonsHighlight?: number[]}
export interface TournamentResult {position: number, position2?: number, playerId: number, prize?: string}
export interface Tournament {timestamp: string, leaderboard?: TournamentResult[]}
interface Raffle {timestamp: string}
export interface Prizes {position: number, value: number}
export interface Starting {days?: number, hours?: number, minutes?: number}
export interface Tournaments {timestamp: string, name?: string, currency?: string, registeredPlayers?: number, prizes: Prizes[], minPlayers?: number, maxPlayers?: number, buyIn: number, buyInCurrency?: string, level?: 0|1|2|3|4, starting?: Starting}
interface Content {text?: string, bold?: boolean, size?: number, lineHeight?: number, tac?: boolean, el?: JSX.Element}
export interface Players {cantonal?: PlayersCantonal, players: PlayerVisualize[]}
export interface ChartContent {changeOrientation?: boolean, label?: boolean, data: any[], title?: string, palette?: string[], dataKey?: string, dataValue?: string, dataValue2?: string, dataValueIcon?: IconDefinition, dataValue2Icon?: IconDefinition, dataValue3?: string, dataValue4?: string, dataValue5?: string, dataValue6?: string}
import {PlayerCardButtonsKYC, PlayerCardButtonsRG, PlayerCardButtonsRM, PlayerCardButtonsGM} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {FormInputNumber} from '../../Reusables/Inputs'
import Socket1 from '../../../Socket/socket-init'
interface DashboardItem {
    type?: string
    subtype?: string
    subType?: number
    title?: string
    footer?: string
    footerExplanation?: string
    footerBackground?: string
    content?: Content[]
    chartContent?: ChartContent
    players?: Players
    tournament?: Tournament
    tournaments?: Tournaments[]
    raffle?: Raffle
    group: SelectedView
    index: number
    expandable?: boolean
    moveable?: number
    data?: any
    close?: () => void
    leftout?: boolean
    size?: number
}

// export const mockupRafflePlayers: number = 143
// export const raffleTicketsRandom: number = 20
export const mockupCurrentPlayers: number = 1071
export const dashboardSmallTableRows: number = 7
// export const dashboardBigTableRows: number = 30
export const dashboardBigTableRows: number = 28
/*
export const playersWinners: PlayerVisualize[] = [
    {id: 1, firstName: 'Roger', lastName: 'Federer', plz: 4031, city: 'Basel', locationN: 47562, locationE: 7576, win: '10000 CHF'},
    {id: 2, firstName: 'Stanislas', lastName: 'Wawrinka', plz: 1003, city: 'Lausanne', locationN: 46520, locationE: 6630, win: '6000 CHF'},
    {id: 3, firstName: 'Alenka', lastName: 'Veselko', plz: 6900,city: 'Lugano', locationN: 46005, locationE: 8947, win: '7000 CHF'},
    {id: 4, firstName: 'X', lastName: Assets.YesShort, plz: 6900,city: 'Zürich', locationN: 47367, locationE: 8550, win: '5000 CHF'},
    {id: 5, firstName: 'A', lastName: 'B', plz: 6900,city: 'Genève', locationN: 46202, locationE: 6145, win: '8000 CHF'},
]

export const playersRaffle: PlayerVisualize[] = [
    {id: 1, firstName: 'Roger', lastName: 'Federer', plz: 4031, city: 'Basel', locationN: 47562, locationE: 7576, win: Assets.MainRafflePrize, rafflePrimary: true},
    {id: 2, firstName: 'Stanislas', lastName: 'Wawrinka', plz: 1003, city: 'Lausanne', locationN: 46520, locationE: 6630, win: Assets.SecondaryRafflePrize},
    {id: 3, firstName: 'Alenka', lastName: 'Veselko', plz: 6900,city: 'Lugano', locationN: 46005, locationE: 8947, win: Assets.SecondaryRafflePrize},
]
*/
/*
function getCanton(canton: string): number {
    for (let i: number = 0; i < SwitzerlandCantonsData.length; i++) {
        if (SwitzerlandCantonsData[i].code.substring(3,5) === canton) {
            return i
        }
    }
    return -1
}

export function getCantonsHighlight(p: PlayerVisualize[], group?: SelectedView, index?: number): number[] {
    const highlight: number[] = []
    for (const {} of SwitzerlandCantonsData) highlight.push(0)
    let players: PlayerVisualize[] = Object.assign ([], p)
    if (group === SelectedView.CurrentOverview && index === 2 && Main.Instance.DashboardState.click[group][index] > -1) {
        const top: number = ToP[Main.Instance.DashboardState.click[group][index]]
        players.sort(winStringValue)
        players = players.slice (0, top)
    }
    
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.players)
    for (const item of players) {
        // for (const Item of Main.Instance.FrontendStoreState.custom.general.players) {
            // if (+item.id === +Item.id) {
                for (const ITEM of SwitzerlandPostalData) {
                    if (item['city'] === ITEM.name) {
                        for (let i: number = 0; i < SwitzerlandCantonsData.length; i++) {
                            if (ITEM.c === SwitzerlandCantonsData[i].code.substring(3,5)) {
                                highlight[i]++
                                break
                            }
                        }
                        break
                    }
                }
                // break
            // }
        // }
    }
    return highlight
}
*/
export function getBiggestWinners(p: PlayerVisualize[]): PlayerVisualize[] {
    if (Main.Instance.DashboardState.click[SelectedView.CurrentOverview][2] > -1) {
        let players: PlayerVisualize[] = Object.assign ([], p)
        const top: number = ToP[Main.Instance.DashboardState.click[SelectedView.CurrentOverview][2]]
        players.sort(winStringValue)
        players = players.slice (0, top)
        return players
        }
    return p
}
/*
export function getConnectedPlayers(): {cantonal: PlayersCantonal, players: PlayerVisualize[]} {
    let connectedPlayers: PlayerVisualize[] = []
    const cantons: number[] = []
    for (const {} of SwitzerlandCantonsData) cantons.push(0)
    for (let i: number = 0; i < mockupCurrentPlayers; i++) {
        const randomPLZ: number = Math.floor(Math.random() * SwitzerlandPostalData.length)
        const randomBet: number = Math.floor(Math.random() * 1000)
        connectedPlayers.push ({id: (i + 1), plz: SwitzerlandPostalData[randomPLZ].plz, city: SwitzerlandPostalData[randomPLZ].name, locationN: SwitzerlandPostalData[randomPLZ].n, locationE: SwitzerlandPostalData[randomPLZ].e, bet: randomBet})
        const canton = getCanton (SwitzerlandPostalData[randomPLZ].c)
        if (canton !== -1) cantons[canton]++
    }
    if (Main.Instance.DashboardState.click[SelectedView.CurrentOverview][0] > -1) {
        const top: number = TOP[Main.Instance.DashboardState.click[SelectedView.CurrentOverview][0]]
        connectedPlayers.sort(betValue)
        connectedPlayers = connectedPlayers.slice (0, top)
    }

    return {cantonal: {cantons}, players: connectedPlayers}
}
*/
/*
function getCrifSettings(): Content[] {
    if (Main.Instance.DatabaseDataState.custom === undefined || Main.Instance.DatabaseDataState.custom.KYC === undefined || Main.Instance.DatabaseDataState.custom.KYC.crif === undefined) {return []}
    const content: Content[] = []
    const vars: boolean[] = [
        Main.Instance.DatabaseDataState.custom.KYC.crif.checkAtRegistration,
        Main.Instance.DatabaseDataState.custom.KYC.crif.checkAtLogin,
        Main.Instance.DatabaseDataState.custom.KYC.crif.canPlayerChangeAddress,
        Main.Instance.DatabaseDataState.custom.KYC.crif.checkOperatorChangeAddress,
        Main.Instance.DatabaseDataState.custom.KYC.crif.isEquivalentToUtilityBillUpload
    ]  

    const vars2: string[] = [
        Main.Instance.DatabaseDataState.custom.KYC.crif.provider,
        Main.Instance.DatabaseDataState.custom.KYC.crif.product,
        Main.Instance.DatabaseDataState.custom.KYC.crif.interfaceVersion,
        Main.Instance.DatabaseDataState.custom.KYC.crif.supportEmail,
        Main.Instance.DatabaseDataState.custom.KYC.crif.telephoneSwitzerland,
        Main.Instance.DatabaseDataState.custom.KYC.crif.telephoneInternational,
        Main.Instance.DatabaseDataState.custom.KYC.crif.documentation,
    ]

    for (let i: number = 0; i < vars.length; i++) {
        content.push ({el:
            <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px'}}>
                <div className = "fl">
                    {Assets.CrifStrings[i] + ': '}
                </div>
                <FontAwesomeIcon className = "fl dbl" style = {{marginLeft: '3px', fontSize: '13px', marginTop: '-1px', color: vars[i] ? '#28a745' : '#dc3545'}} icon = {vars[i] ? faCheck : faTimes}/>
            </div>
        })
    }    

    for (let i: number = vars.length; i < vars.length + vars2.length; i++) {
        content.push ({el:
            <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px', marginTop: i === vars.length ? '10px' : ''}}>
                <div className = "fl">
                    {Assets.CrifStrings[i] + ': '}<strong>{vars2[i - vars.length]}</strong>
                </div>
            </div>
        })
    }

    return content
}

function getPepSettings(): Content[] {
    if (Main.Instance.DatabaseDataState.custom === undefined || Main.Instance.DatabaseDataState.custom.KYC === undefined || Main.Instance.DatabaseDataState.custom.KYC.pep === undefined) {return []}
    const content: Content[] = []
    const vars: boolean[] = [
        Main.Instance.DatabaseDataState.custom.KYC.pep.checkAtRegistration,
        Main.Instance.DatabaseDataState.custom.KYC.pep.checkAtLogin,
        Main.Instance.DatabaseDataState.custom.KYC.pep.checkAtNameChanged,
        Main.Instance.DatabaseDataState.custom.KYC.pep.checkBeneficiaryName,
        Main.Instance.DatabaseDataState.custom.KYC.pep.doubleCheckOnPepMatch,
        Main.Instance.DatabaseDataState.custom.KYC.pep.markThePlayerManually,
    ]  

    const vars2: string[] = [
        Main.Instance.DatabaseDataState.custom.KYC.pep.provider,
        Main.Instance.DatabaseDataState.custom.KYC.pep.product,
        Main.Instance.DatabaseDataState.custom.KYC.pep.supportEmail,
        Main.Instance.DatabaseDataState.custom.KYC.pep.telephoneSwitzerland,
        Main.Instance.DatabaseDataState.custom.KYC.pep.telephoneInternational,
        Main.Instance.DatabaseDataState.custom.KYC.pep.documentation,
    ]
    for (let i: number = 0; i < vars.length; i++) {
        content.push ({el:
            <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px'}}>
                <div className = "fl">
                    {Assets.PepStrings[i] + ': '}
                </div>
                <FontAwesomeIcon className = "fl dbl" style = {{marginLeft: '3px', fontSize: '13px', marginTop: '-1px', color: vars[i] ? '#28a745' : '#dc3545'}} icon = {vars[i] ? faCheck : faTimes}/>
            </div>
        })
    }

    for (let i: number = vars.length; i < vars.length + vars2.length; i++) {
        content.push ({el:
            <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px', marginTop: i === vars.length ? '10px' : ''}}>
                <div className = "fl">
                    {Assets.PepStrings[i] + ': '}<strong>{vars2[i - vars.length]}</strong>
                </div>
            </div>
        })
    }
    return content
}

function getVetoSettings(): Content[] {
    // console.log (Main.Instance.DatabaseDataState.issues)
    if (Main.Instance.DatabaseDataState.blacklist === undefined || Main.Instance.DatabaseDataState.blacklist[0] === undefined) {return []}
    const vars: boolean[] = [
        Main.Instance.DatabaseDataState.blacklist[0].data.checkAtRegistration,
        Main.Instance.DatabaseDataState.blacklist[0].data.checkAtLogin,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowCreateAccount,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowPlay,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowDeposit,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowWithdraw,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowLogin,
        Main.Instance.DatabaseDataState.blacklist[0].data.recieveMarketingPromotion,
        Main.Instance.DatabaseDataState.blacklist[0].data.checkBlackList,
        Main.Instance.DatabaseDataState.blacklist[0].data.checkNightJob,
        Main.Instance.DatabaseDataState.blacklist[0].data.allowAccountSuspension,
    ]
    const content: Content[] = []
    for (let i: number = 0; i < vars.length; i++) {
        content.push ({el:
            <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px'}}>
                <div className = "fl">
                    {Assets.BlackListStrings[i] + ': '}
                </div>
                <FontAwesomeIcon className = "fl dbl" style = {{marginLeft: '3px', fontSize: '13px', marginTop: '-1px', color: vars[i] ? '#28a745' : '#dc3545'}} icon = {vars[i] ? faCheck : faTimes}/>
            </div>
        })

        if (i === 9 && vars[i] && vars[i - 1]) {
            content.push ({el:
                <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px'}}>
                    <div className = "fl">
                        {Assets.BlacklistCheck + ':' + Assets._on_ + Assets.DaysInWeek[Main.Instance.DatabaseDataState.blacklist[0].data.check.dayOfWeek] + Assets._at_ + Main.Instance.DatabaseDataState.blacklist[0].data.check.timeUtc}
                    </div>
                    <FontAwesomeIcon className = "fl dbl" style = {{marginLeft: '3px', fontSize: '13px', marginTop: '-1px', color: vars[i] ? '#28a745' : '#dc3545'}} icon = {vars[i] ? faCheck : faTimes}/>
                </div>
            })
        }
    }

    content.push ({el:
        <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px'}}>
            <div className = "fl">
                {Assets.AccountSuspension + ': '}
            </div>
            <FontAwesomeIcon className = "fl dbl" style = {{marginLeft: '3px', fontSize: '13px', marginTop: '-1px', color: Main.Instance.DatabaseDataState.blacklist[0].data.accountSuspension ? '#28a745' : '#dc3545'}} icon = {Main.Instance.DatabaseDataState.blacklist[0].data.accountSuspension ? faCheck : faTimes}/>
        </div>
    })

    content.push ({el:
        <div className = "clearfix" style = {{fontSize: '12px', lineHeight: '10px', marginTop: '0px'}}>
            <div className = "fl" >
                {Assets.New + ' ' + Assets.RiskLevel.toLowerCase() + ' (' + Assets.ifPlayerFoundSuspicious + '):'}
            </div>
            <button
                style = {{marginTop: '-12px', marginLeft: '5px'}}
                disabled = {true}
                className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.blacklist[0].data.riskLevel]}
            >
                {RiskLevel[Main.Instance.DatabaseDataState.blacklist[0].data.riskLevel]}
                <input
                    disabled = {true} 
                    type = "range"
                    list = "test"
                    min = {RiskLevel.Low}
                    max = {RiskLevel.Severe}
                    step = "1"
                    value = {Main.Instance.DatabaseDataState.blacklist[0].data.riskLevel}
                    onChange = {(e) => {}}
                    className = "slider"
                />
            </button>

        </div>
    })
    return content
}
*/
function winStringValue(a, b) {
    if (+a.win.split(' ')[0] < +b.win.split(' ')[0]) { return 1 }
    if (+a.win.split(' ')[0] > +b.win.split(' ')[0]) { return -1 }
    return 0
}
/*
function betValue(a, b) {
    if (+a.bet < +b.bet) return 1
    if (+a.bet > +b.bet) return -1
    return 0
}
*/
function closeRaffleSelected() {
    Logic.Type.New (Main.Instance.DatabaseDataState, {raffleSelected: undefined})
}

function getItems() {
    const items: JSX.Element[] = []
    const ItemsRight: DashboardItem[] = []
/*
    if (Main.Instance.DashboardState.selectedView === SelectedView.Last24Hours) {
        ItemsRight.push ({
            group: SelectedView.Last24Hours,
            index: ItemsRight.length,
            type: Assets.Chart,
            subtype: Assets.BarChart,
            footer: 'New player registrations',
            footerExplanation: 'hours ago',
            footerBackground: '#777',
            expandable: true,
            // content: [{text: 'bla1', bold: true, size: 16}, {text: 'bla2'}],
            chartContent: {label: true, data: [{name: '24', players: 5}, {name: '23', players: 3}, {name: '22', players: 1}, {name: '21', players: 0}, {name: '20', players: 2}, {name: '19', players: 1}, {name: '18', players: 2}, {name: '17', players: 0}, {name: '16', players: 3}, {name: '15', players: 4}, {name: '14', players: 0}, {name: '13', players: 1}, {name: '12', players: 2}, {name: '11', players: 0}, {name: '10', players: 3}, {name: '9', players: 2}, {name: '8', players: 2}, {name: '7', players: 1}, {name: '6', players: 2}, {name: '5', players: 3}, {name: '4', players: 1}, {name: '3', players: 0}, {name: '2', players: 2}, {name: '1', players: 3}], palette: ['#AF601A'], dataKey: 'name', dataValue: 'players'}
        })

        ItemsRight.push ({
            group: SelectedView.Last24Hours,
            index: ItemsRight.length,
            type: Assets.Chart,
            subtype: Assets.PieChart,
            footer: 'New players registrations',
            footerExplanation: 'by age',
            footerBackground: '#777',
            chartContent: {data: [{age: '18-24', players: 10}, {age: '25-34', players: 12}, {age: '35-44', players: 17}, {age: '45-54', players: 9}, {age: '55-64', players: 4}, {age: '65+', players: 7}], palette: ['#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B276B2', '#DECF3F '], dataKey: 'age', dataValue: 'players', dataValueIcon: faUsers}
        })

        ItemsRight.push ({
            group: SelectedView.Last24Hours,
            index: ItemsRight.length,
            type: Assets.Chart,
            subtype: Assets.HalfPieChart,
            footer: 'New players registrations',
            footerExplanation: 'by gender',
            footerBackground: '#777',
            chartContent: {label: true, data: [{name: 'male', players: 65}, {name: 'female', players: 12}], palette: ['#F15854', '#B2912F'], dataKey: 'name', dataValue: 'players'}
        })
    } else */
    if (Main.Instance.DashboardState.selectedView === SelectedView.CurrentOverview) {
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LoggedInPlayers,
            subtype: 'logged players',
            expandable: true,
            moveable: 0, 
            data: Main.Instance.DatabaseDataState.loggedPlayers ? [...Main.Instance.DatabaseDataState.loggedPlayers.list] : [],
            footer: Main.Instance.DatabaseDataState.loggedPlayersTimestamp,
            size: Main.Instance.DatabaseDataState.loggedPlayers && Main.Instance.DatabaseDataState.loggedPlayers.pagination ? Main.Instance.DatabaseDataState.loggedPlayers.pagination.total : 0
            // footer: 'Latest ' + dashboardSmallTableRows + ' deposits',
        })

        if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.getExpiredAccounts) {
            ItemsRight.push ({
                group: SelectedView.CurrentOverview,
                index: ItemsRight.length,
                type: Assets.Recent,
                title: Assets.ExpiredProvisionalAccounts,
                subtype: 'expired provisional accounts',
                expandable: true,
                moveable: 1,
                data: Main.Instance.DatabaseDataState.expiredAccounts,
                footer: Main.Instance.DatabaseDataState.expiredAccountsTimestamp,
            })
        }
        
        if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.getAllExpiringAccounts) {
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.ProvisionalAccounts,
            subtype: 'expiring accounts',
            expandable: true,
            moveable: 2,
            data: Main.Instance.DatabaseDataState.unverifiedAccountsAll,
            footer: Main.Instance.DatabaseDataState.unverifiedAccountsAllTimestamp,
        })
/*
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            // leftout: true,
            title: Assets.LatestFirstDeposits,
            subtype: 'first deposits',
            expandable: true,
            moveable: 3,
            data: Main.Instance.DatabaseDataState.recentEventsFirstDeposits,
            footer: Main.Instance.DatabaseDataState.recentEventsFirstDepositsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' first deposits',
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            leftout: true,
            title: Assets.LatestDeposits,
            subtype: 'deposits',
            expandable: true,
            moveable: 4,
            data: Main.Instance.DatabaseDataState.recentEventsDeposits,
            footer: Main.Instance.DatabaseDataState.recentEventsDepositsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' deposits',
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            leftout: true,
            title: Assets.LatestWithdrawals,
            subtype: 'withdrawals',
            expandable: true,
            moveable: 5,
            data: Main.Instance.DatabaseDataState.recentEventsWithdrawals,
            footer: Main.Instance.DatabaseDataState.recentEventsWithdrawalsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' withdrawals',
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestRegistrations,
            subtype: 'registrations',
            expandable: true,
            moveable: 6,
            data: Main.Instance.DatabaseDataState.recentEventsRegistrations,
            footer: Main.Instance.DatabaseDataState.recentEventsRegistrationsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' registrations',
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestLogins,
            subtype: 'logins',
            expandable: true,
            moveable: 7,
            data: Main.Instance.DatabaseDataState.recentEventsLogins,
            footer: Main.Instance.DatabaseDataState.recentEventsLoginsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' logins',
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestLogouts,
            subtype: 'logouts',
            expandable: true,
            moveable: 8,
            data: Main.Instance.DatabaseDataState.recentEventsLogouts,
            footer: Main.Instance.DatabaseDataState.recentEventsLogoutsTimestamp,
            // footer: 'Latest ' + dashboardSmallTableRows + ' issues',
        })

        // console.log (Main.Instance.DatabaseDataState.recentEventsIssues)
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestIssues,
            subtype: 'issues',
            expandable: true,
            moveable: 9,
            data: Main.Instance.DatabaseDataState.recentEventsIssues,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestUnresolvedIssues,
            subtype: 'unresolved issues',
            expandable: true,
            moveable: 10,
            data: Main.Instance.DatabaseDataState.recentEventsIssuesUnresolved,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesUnresolvedTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.LatestResolvedIssues,
            subtype: 'resolved issues',
            expandable: true,
            moveable: 11,
            data: Main.Instance.DatabaseDataState.recentEventsIssuesResolved,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesResolvedTimestamp,
        })
*/

        }
/*
        // let maxMoveable: number = 1
        // console.log (Main.Instance.DatabaseDataState.unverifiedAccounts)
        // console.log (Main.Instance.DatabaseDataState.unverifiedAccounts)
        if (Main.Instance.DatabaseDataState.unverifiedAccounts ) {
            for (let unverifiedAccount: number = 0; unverifiedAccount < Main.Instance.DatabaseDataState.unverifiedAccounts.length; unverifiedAccount++) {
                const item = Main.Instance.DatabaseDataState.unverifiedAccounts[unverifiedAccount]
                if (item.name === Assets.Expired) { continue }
                // console.log (item.name)
                ItemsRight.push ({
                    group: SelectedView.CurrentOverview,
                    index: ItemsRight.length,
                    type: Assets.Recent,
                    title: item.name,
                    subtype: 'expiring accounts',
                    expandable: true,
                    moveable: unverifiedAccount + 12,
                    data: item.accounts,
                    footer: Main.Instance.DatabaseDataState.unverifiedAccountsTimestamp,
                })
                // maxMoveable = unverifiedAccount + 2
            }
        }
*/
/*
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: 'Latest games started',
            subtype: 'games started',
            expandable: false,
            data: [],
        })
*/        
/*
        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Map,
            subtype: 'players per canton',
            expandable: true,
            footer: 'Location of connected players',
            players: getConnectedPlayers()
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Chart,
            subtype: Assets.AreaChart,
            footer: 'Total players active/connected',
            footerExplanation: 'minutes ago',
            chartContent: {dataKey: 'name', dataValue: 'playersConnected', dataValue2: 'playersActive', data: [{name: '10', playersConnected: 2300, playersActive: 500}, {name: '9', playersConnected: 2200, playersActive: 300}, {name: '8', playersConnected: 2450, playersActive: 600}, {name: '7', playersConnected: 2075, playersActive: 800}, {name: '6', playersConnected: 2180, playersActive: 500}, {name: '5', playersConnected: 2285, playersActive: 400}, {name: '4', playersConnected: 2190, playersActive: 300}, {name: '3', playersConnected: 2290, playersActive: 700}, {name: '2', playersConnected: 2515, playersActive: 550}, {name: '1', playersConnected: 2320, playersActive: 400}]}
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Map,
            expandable: true,
            footer: 'Location of biggest winners',
            footerExplanation: 'last 10 minutes',
            players: {players: getBiggestWinners(playersWinners), cantonal: {cantonsHighlight: getCantonsHighlight(playersWinners, SelectedView.CurrentOverview, ItemsRight.length)}}
        })

        ItemsRight.push ({
            group: SelectedView.CurrentOverview,
            index: ItemsRight.length,
            type: Assets.Chart,
            subtype: Assets.AreaChart2,
            footer: 'Net settlement',
            footerExplanation: 'CHF, minutes ago',
            chartContent: {label: true, data: [{name: '10', net: 100}, {name: '9', net: 80}, {name: '8', net: 40}, {name: '7', net: -200}, {name: '6', net: 200}, {name: '5', net: -100}, {name: '4', net: 100}, {name: '3', net: 30}, {name: '2', net: 0}, {name: '1', net: 20}], dataKey: 'name', dataValue: 'net'}
        })
*/
/*
        } else if (Main.Instance.DashboardState.selectedView === ) {
            if (!Main.Instance.DatabaseDataState.allAccountsStarted) {
                DbRoutes.getAllAccounts()
            }
*/

/*
    } else if (Main.Instance.DashboardState.selectedView === SelectedView.Pep) {
        // const date: string = (new Date()).toISOString().substring(0,19).replace('T', ' ')
        const date: string = (new Date()).toISOString().substring(0,19).replace('T', ' ')
        ItemsRight.push ({
            group: SelectedView.Pep,
            index: ItemsRight.length,
            type: Assets_.PEP,
            title: Assets_.PEP + ' ' + Assets.Settings,
            expandable: false,
            footer: Assets.ShownSettings + Assets._on_ + date.substring(8,10) + date.substring (4,8) + date.substring (0,4) + Assets._at_ + date.substring (10,19),
            content: getPepSettings(),
        })

        ItemsRight.push ({
            group: SelectedView.Pep,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets_.PEP + ' ' + Assets.Check + Assets._at_ + Assets.Registration + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.PEPCheckRegistration,
            subtype: 'issues',
            expandable: true,
            moveable: 25,
            data: Main.Instance.DatabaseDataState.issuesPEPCheckRegistration,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Pep,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets_.PEP + ' ' + Assets.Check + Assets._at_ + Assets.Name + ' ' + Assets.Change + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.PEPCheckNameChanged,
            subtype: 'issues',
            expandable: true,
            moveable: 26,
            data: Main.Instance.DatabaseDataState.issuesPEPCheckNameChanged,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Pep,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets_.PEP + ' ' + Assets.Changed + Assets._by_ + Assets.Operator + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.PEPChangedByOperator,
            subtype: 'issues',
            expandable: true,
            moveable: 27,
            data: Main.Instance.DatabaseDataState.issuesPEPCheckByOperator,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })
*/
/*
    } else if (Main.Instance.DashboardState.selectedView === SelectedView.Crif) {
        const date: string = (new Date()).toISOString().substring(0,19).replace('T', ' ')
        ItemsRight.push ({
            group: SelectedView.Crif,
            index: ItemsRight.length,
            type: Assets_.CRIF,
            title: Assets_.CRIF + ' ' + Assets.Settings,
            expandable: false,
            footer: Assets.ShownSettings + Assets._on_ + date.substring(8,10) + date.substring (4,8) + date.substring (0,4) + Assets._at_ + date.substring (10,19),
            content: getCrifSettings(),
        })

        ItemsRight.push ({
            group: SelectedView.Crif,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets_.CRIF + ' ' + Assets.Check + Assets._at_ + Assets.Registration + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.CRIFCheckRegistration,
            subtype: 'issues',
            expandable: true,
            moveable: 28,
            data: Main.Instance.DatabaseDataState.issuesCRIFCheckRegistration,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Crif,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.Address + ' ' + Assets.Change + Assets._by_ + Assets.Operator + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.CRIFAddressChangedOperator,
            subtype: 'issues',
            expandable: true,
            moveable: 29,
            data: Main.Instance.DatabaseDataState.issuesCRIFCheckRegistration,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Crif,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets_.CRIF + ' ' + Assets.Check + Assets._at_ + Assets.Mismatch,
            subType: IssueTypes.CRIFCheckMismatch,
            subtype: 'issues',
            expandable: true,
            moveable: 30,
            data: Main.Instance.DatabaseDataState.issuesCRIFCheckMismatch,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })
*/
/*
    } else if (Main.Instance.DashboardState.selectedView === SelectedView.Veto) {
        const date: string = (new Date()).toISOString().substring(0,19).replace('T', ' ')
        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets_.VETO,
            title: Assets.Blacklist + ' ' + Assets.VetoSettings,
            expandable: false,
            footer: Assets.ShownSettings + Assets._on_ + date.substring(8,10) + date.substring (4,8) + date.substring (0,4) + Assets._at_ + date.substring (10,19),
            content: getVetoSettings(),
        })

        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.BlacklistLogin + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.BlacklistLogin,
            subtype: 'issues',
            expandable: true,
            moveable: 20,
            data: Main.Instance.DatabaseDataState.issuesBlacklistLogin,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })  

        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.BlacklistRegistration + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.BlacklistRegistration,
            subtype: 'issues',
            expandable: true,
            moveable: 21,
            data: Main.Instance.DatabaseDataState.issuesBlacklistRegistration,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.BlacklistExclusion + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.BlacklistExclusion,
            subtype: 'issues',
            expandable: true,
            moveable: 22,
            data: Main.Instance.DatabaseDataState.issuesBlacklistExclusion,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.BlacklistCronJob + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.BlacklistCronJob,
            subtype: 'issues',
            expandable: true,
            moveable: 23,
            data: Main.Instance.DatabaseDataState.issuesBlacklistCronJob,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })

        ItemsRight.push ({
            group: SelectedView.Veto,
            index: ItemsRight.length,
            type: Assets.Recent,
            title: Assets.BlacklistManual + ' ' + Assets.Issues.toLowerCase(),
            subType: IssueTypes.BlacklistManual,
            subtype: 'issues',
            expandable: true,
            moveable: 24,
            data: Main.Instance.DatabaseDataState.issuesBlacklistManual,
            footer: Main.Instance.DatabaseDataState.recentEventsIssuesTimestamp,
        })
*/
    } else if (Main.Instance.DashboardState.selectedView === SelectedView.Gamification) {
        ItemsRight.push ({
            group: SelectedView.Gamification,
            index: ItemsRight.length,
            type: Assets.Raffle,
            title: Assets.MonthlyRaffle,
            expandable: false,
            footer: Assets.TermsAndConditions,
            content: [
                {text: Assets.TermsAndConditions, bold: true, size: 18, tac: true},
                {text: Assets.jackpotsRaffle[0], bold: true, size: 12, lineHeight: 14},
                {text: Assets.jackpotsRaffle[1], size: 12, lineHeight: 14},
                {text: Assets.jackpotsRaffle[2], bold: true, size: 12, lineHeight: 14},
                {text: Assets.jackpotsRaffle[3], size: 12, lineHeight: 14},
            ],
        })
/*
        ItemsRight.push ({
            group: SelectedView.Raffles,
            index: ItemsRight.length,
            type: Assets.Raffle,
            subtype: Assets.RaffleWinners,
            expandable: true,
            title: 'Latest raffle winners',
            footer: 'Latest raffle winners',
            footerExplanation: 'December 2018',
            players: {players: playersRaffle}
        })
*/
/*
        ItemsRight.push ({
            group: SelectedView.Raffles,
            index: ItemsRight.length,
            type: Assets.Map,
            expandable: true,
            subtype: Assets.RaffleWinners,
            footer: 'Map of latest raffle winners',
            footerExplanation: 'December 2018',
            players: {players: playersRaffle, cantonal: {cantonsHighlight: getCantonsHighlight(playersRaffle)}}
        })
*/
        ItemsRight.push ({
            group: SelectedView.Gamification,
            index: ItemsRight.length,
            type: Assets.Raffle,
            title: Assets.NextRaffles,
            expandable: true,
            subtype: Assets.Next,
        })

        ItemsRight.push ({
            group: SelectedView.Gamification,
            index: ItemsRight.length,
            type: Assets.Raffle,
            title: Assets.ExecuteMonthlyRaffle,
            // expandable: true,
            subtype: Assets.RaffleComponent,
            footer: Assets.ExecuteMonthlyRaffle,
            // footerExplanation: '',
            players: {players: Main.Instance.DatabaseDataState.raffleParticipants ? Main.Instance.DatabaseDataState.raffleParticipants : []},
        })

        ItemsRight.push ({
            group: SelectedView.Gamification,
            index: ItemsRight.length,
            type: Assets.Raffle,
            title: Assets.PreviousRaffles,
            expandable: true,
            subtype: Assets.Previous,
        })

        if (Main.Instance.DatabaseDataState.raffleSelected) {
            ItemsRight.push ({
                group: SelectedView.Gamification,
                index: ItemsRight.length,
                type: Assets.Raffle,
                title: Assets.SelectedRaffle,
                expandable: true,
                close: closeRaffleSelected,
                subtype: Assets.Selected,
            })
        }
    } 

    // console.log (Main.Instance.DashboardState.expandButton)

    let Items: DashboardItem[] = []
    let ItemEnlarged: DashboardItem
    if (ItemsRight.length) {
        ItemEnlarged = Main.Instance.DashboardState.expandButton[Main.Instance.DashboardState.selectedView] !== -1 ? Object.assign ({}, ItemsRight[Main.Instance.DashboardState.expandButton[Main.Instance.DashboardState.selectedView]]) : undefined

        if (ItemEnlarged) {
            Items.push (ItemEnlarged)
            ItemsRight.splice(Main.Instance.DashboardState.expandButton[Main.Instance.DashboardState.selectedView], 1)
            Items.push(...ItemsRight)
        } else {
            Items = Object.assign([], ItemsRight)
        }
    }
    // console.log (Items)
    for (let i: number = 0; i < Items.length; i++) {
        const item: DashboardItem = Items[i]
        // console.log(item)
        const Misc: string = item.type && item.type === Assets.Miscellaneous ? ' misc' : ''
        const background: string =  item.type && (item.type === Assets.Tournament || item.type === Assets.Tournaments) ? 'rgb(0, 84, 135)' : // #0275d8
                                    item.type && (item.type === Assets.Raffle) ? 'rgb(243,139,0)' : // #f0ad4e
                                    item.type && (item.type === Assets.Recent && item.subtype === 'logged players') ? '#5cb85c' :
                                    item.type && (item.type === Assets.Recent && item.subtype === 'expired provisional accounts') ? '#dc3545' :
                                    item.type && (item.type === Assets.Recent && item.subtype === 'expiring accounts' && item.title === Assets.ProvisionalAccounts) ? '#f0ad4e' : // '#ffc107'
                                    item.type && (item.type === Assets.Recent && item.leftout) ? '#bbb' :
                                    item.type && (item.type === Assets.Recent) ? '#777' :
                                    /* DEFAULT = INFO */ '#5bc0de'
                                    // #d9534f, #292b2c, #f7f7f7
        
        if (item.type === Assets.Tournament && item.tournament && item.tournament.timestamp) {
            const t: Date = new Date (item.tournament.timestamp)
            const now: string = (new Date()).toISOString()
            if (now > item.tournament.timestamp) {
                if (item.tournament.leaderboard) {
                    item.footer = Assets.TournamentFinished + ' (' + getNPrizes(item.tournament.leaderboard) + ' ' + Assets.Prizes.toLowerCase() +', ' + item.tournament.leaderboard.length + ' ' + Assets.Players.toLowerCase() + ')'
                } else { item.footer = Assets.TournamentInProgress } // here we should add how many players are currently active
            } else { item.footer = Assets.TournamentNotStartedYet + ' - ' + getDayFooter(item.tournament.timestamp, t.getUTCDay()) }
        } else if (item.type === Assets.Tournaments) {
            if (item.subtype === Assets.Next) { item.footer = Assets.TournamentsNotStartedYet } else if (item.subtype === Assets.Previous) { item.footer = Assets.TournamentsFinished } else if (item.subtype === Assets.Next) { item.footer = Assets.TournamentsInProgress }
        } else if (item.type === Assets.Raffle && item.subtype === Assets.Next) {
            const now: string = (new Date()).toISOString()
            const Now: string = now.substring(0,10) + ' ' + now.substring(11,16)
            let raffleActive: number = -1
            let raffleActiveTimestamp: string
            if (Main.Instance.DatabaseDataState.raffle) {
                for (let j: number = 0; j < Main.Instance.DatabaseDataState.raffle.length; j++) {
                    if (Main.Instance.DatabaseDataState.raffle[j].data.timestamp > Now && Main.Instance.DatabaseDataState.raffle[j].data.completed === 'false' && 
                        (raffleActiveTimestamp === undefined || Main.Instance.DatabaseDataState.raffle[j].data.timestamp < raffleActiveTimestamp)) {
                            raffleActiveTimestamp = Main.Instance.DatabaseDataState.raffle[j].data.timestamp
                            raffleActive = j
                    }
                }
            }

            if (raffleActiveTimestamp && raffleActive > -1) {
                const d: Date = new Date (raffleActiveTimestamp)
                item.footer = Assets.NextRaffle + ' ' + Assets.isScheduledOn + ' ' + Assets.DaysInWeek[d.getUTCDay()] + ', ' + raffleActiveTimestamp.substring(0,10) + ' @ ' + raffleActiveTimestamp.substring(11,16)
                if (Main.Instance.DatabaseDataState.raffleActive === -1 || raffleActiveTimestamp !== Main.Instance.DatabaseDataState.raffleActiveTimestamp) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {raffleActive, raffleActiveTimestamp})
                    // console.log (raffleActive, raffleActiveTimestamp)
                }
            }
        } else if (item.type === Assets.Raffle && item.subtype === Assets.RaffleComponent) {
            item.footerExplanation = (Main.Instance.DatabaseDataState.raffleActiveTimestamp ? getMonthYear(Main.Instance.DatabaseDataState.raffleActiveTimestamp) : '')
        } else if (item.type === Assets.Raffle && item.subtype === Assets.Selected) {
            item.footer = Assets.SelectedRaffle + ': ' + (Main.Instance.DatabaseDataState.raffleSelected ? Main.Instance.DatabaseDataState.raffleSelected.data.timestamp : Assets.None)
        } else if (item.type === Assets.Raffle && item.subtype === Assets.Previous) {
            // const now: string = (new Date()).toISOString()
            // const Now: string = now.substring(0,10) + ' ' + now.substring(11,16)
            let raffleLatest: number = -1
            let raffleLatestTimestamp: string
            if (Main.Instance.DatabaseDataState.raffle) {
                for (let j: number = 0; j < Main.Instance.DatabaseDataState.raffle.length; j++) {
                    if (/*Main.Instance.DatabaseDataState.raffle[j].data.timestamp > Now &&*/ Main.Instance.DatabaseDataState.raffle[j].data.completed === 'true' && 
                        (raffleLatestTimestamp === undefined || Main.Instance.DatabaseDataState.raffle[j].data.timestamp > raffleLatestTimestamp)) {
                            raffleLatestTimestamp = Main.Instance.DatabaseDataState.raffle[j].data.timestamp
                            raffleLatest = j
                    }
                }
            }

            if (raffleLatestTimestamp && raffleLatest > -1) {
                const d: Date = new Date (raffleLatestTimestamp)
                item.footer = Assets.LatestRaffle + ' ' + Assets.wasExecutedOn + ' ' + Assets.DaysInWeek[d.getUTCDay()] + ', ' + raffleLatestTimestamp.substring(0,10) + ' @ ' + raffleLatestTimestamp.substring(11,16)
                if (Main.Instance.DatabaseDataState.raffleLatest === -1 || raffleLatestTimestamp !== Main.Instance.DatabaseDataState.raffleLatestTimestamp) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {raffleLatest, raffleLatestTimestamp})
                    // console.log (raffleActive, raffleActiveTimestamp)
                }
            }

            
            // item.footer = Assets.LatestRaffle
/*
            if (now > item.raffle.timestamp) {

                if (item.raffle.leaderboard) {
                    item.footer = Assets.RaffleFinished + ' (' + getNPrizes(item.raffle.leaderboard) + '  prizes, ' + item.raffle.leaderboard.length + ' players)'
                } else item.footer = Assets.RaffleInProgress // here we should add how many players are currently active

            } else item.footer = Assets.RaffleNotStartedYet + ' - ' + getDayFooter(item.raffle.timestamp, t.getUTCDay())
*/
/*
        } else if (item.type === Assets.Raffle) {
            if (item.subtype === Assets.Previous) item.footer = Assets.RaffleFinished
            else if (item.subtype === Assets.InProgress) item.footer = Assets.RaffleInProgress
            else if (item.subtype === Assets.Next) item.footer = Assets.RaffleNotStartedYet
*/
        } else if (item.type === Assets.Recent) {
            item.footer = item.footer === undefined ? item.subtype === 'automatic logouts' || item.subtype === 'games started' ? Assets.NotReadyYet : '' : Assets.UpdatedOn + ' ' + item.footer.substring(8,10) + item.footer.substring (4,8) + item.footer.substring (0,4) + Assets._at_ + item.footer.substring (11, 19) 
        } 

        const footerBackground: string = item.footer && (item.footer === Assets.Loaded || item.footer.startsWith(Assets.TournamentFinished) || item.footer === Assets.TournamentsFinished || item.footer.startsWith(Assets.LatestRaffle) || item.subtype === Assets.RaffleWinners) ? '#5cb85c' :
                                         item.footer && (item.footer === Assets.NotLoaded || item.footer.startsWith(Assets.TournamentNotStartedYet) || item.footer === Assets.TournamentsNotStartedYet || item.footer.startsWith(Assets.NextRaffle) || item.footer.startsWith(Assets.SelectedRaffle)) ? '#d9534f' :
                                         item.footerBackground ? item.footerBackground : '#0275d8' // '#007bff'
        // console.log (Main.Instance.DashboardState.expandButton)
        // console.log(Main.Instance.DatabaseDataState.recentEventsFirstDeposits)
        items.push (
            <div
                key = {items.length.toString()}
                className = {Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable ? 'dashboard-item dashboard-item-large' : 'dashboard-item'}
/*
                style = {
                    Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable ?
                    {width: '1203px', height: '756px'} : {}
                }
*/
            >
                {Misc.length > 0 ? <div className = "misc-left">&nbsp;</div> : void 0}
                {Misc.length > 0 ? <div className = "misc-right">&nbsp;</div> : void 0}
                {Misc.length > 0 ? <div className = "misc-left-triangle">&nbsp;</div> : void 0}
                {Misc.length > 0 ? <div className = "misc-right-triangle">&nbsp;</div> : void 0}
                {!item.title ? void 0 : <div title = {item.type} style = {{background}} className = "dashboard-item-title">
                    {item.title}
                    {item.type === Assets.Recent && item.subtype === 'logged players' && item.size ? <span style = {{background: '#f0f0f0', color: '#333'}} className = "badge badge-light ml">{item.size}</span> : ''}
                </div>}
                {!item.footer ? void 0 : <div style = {{background: footerBackground}} className = "dashboard-item-sub">{item.footer + (item.footerExplanation ? ' (' + item.footerExplanation + ')' : '')}</div>}
                {item.content ? getContent(item.content, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                
                {/* CHARTS */}
                {item.chartContent && item.type === Assets.Chart && item.subtype === Assets.BarChart ? <DashboardBarChart cc = {item.chartContent} expanded = {Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable}/> : void 0}
                {item.chartContent && item.type === Assets.Chart && item.subtype === Assets.PieChart ? <DashboardPieChart cc = {item.chartContent}/> : void 0}
                {item.chartContent && item.type === Assets.Chart && item.subtype === Assets.PieChart ? getChartLegend(item.chartContent) : void 0}
                {item.chartContent && item.type === Assets.Chart && item.subtype === Assets.HalfPieChart ? <DashboardPieChart cc = {item.chartContent} startAngle = {180} endAngle = {0}/> : void 0} 
                {item.chartContent && item.type === Assets.Chart && (item.subtype === Assets.AreaChart || item.subtype === Assets.AreaChart2) ? <DashboardAreaChart cc = {item.chartContent} subtype = {item.subtype}/> : void 0}
                {item.chartContent && item.type === Assets.Chart && item.subtype === Assets.SpecialLineChart ? <SpecialLineChart cc = {item.chartContent} expanded = {Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable}/> : void 0}
                
                {/* MAPS */}
                {item.type === Assets.Map ? Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable ? <ExpandSwitzerland players = {item.players.players} cantonal = {item.players.cantonal} group = {item.group} index = {item.index}/> : <ScaleSwitzerland players = {item.players.players} cantonal = {item.players.cantonal} group = {item.group} index = {item.index}/> : void 0}
                {/*item.type === Assets.Map ? getMapLegendRight(item.players, item.group, item.index, item.subtype, item.expandable) : void 0*/}
                {item.type === Assets.Map ? getMapLegendBottom(item.players, item.group, item.index, item.subtype, item.expandable) : void 0}
                {item.type === Assets.Map ? getMapLegendLeft(item.players, item.group, item.index, item.subtype, item.expandable) : void 0}
                {item.type === Assets.Map ? getMapLegendTop(item.players, item.group, item.index, item.subtype, item.expandable) : void 0}

                {/* TOURNAMENT */}
                {item.type === Assets.Tournament && item.tournament && item.tournament.leaderboard && item.tournament.leaderboard.length ? getTournamentResults(item.tournament, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {/* TOURNAMENTS */}
                {item.type === Assets.Tournaments && item.subtype === Assets.Next && item.tournaments ? getNextTournaments(item.tournaments, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {item.type === Assets.Tournaments && item.subtype === Assets.Previous && item.tournaments ? getPreviousTournaments(item.tournaments, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {/* RAFFLE */}
                {item.type === Assets.Raffle && item.subtype === Assets.RaffleWinners ? getRaffleResults(item.players.players, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {item.type === Assets.Raffle && item.subtype === Assets.RaffleComponent ? getRaffleComponent(item.players.players, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {item.type === Assets.Raffle && item.subtype === Assets.Next ? getRaffleNext(Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {item.type === Assets.Raffle && item.subtype === Assets.Previous ? getRafflePrevious(Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {item.type === Assets.Raffle && item.subtype === Assets.Selected ? getRaffleSelected(Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable) : void 0}
                {/* RECENT */}
                {item.type === Assets.Recent ? getLatest(item.subtype, item.title, item.data, Main.Instance.DashboardState.expandButton[item.group] === item.index && item.expandable, item.subType, item.moveable, item.moveable !== undefined ? Main.Instance.DashboardState.position[item.moveable] : item.moveable) : void 0}
                {/* CONTROLS */}
                {/*item.type === Assets.Recent && item.moveable === 8 && item.expandable ? getAssignToButton(item.group, item.index) : void 0*/}
                {item.expandable ? getExpandButton(item.group, item.index) : void 0}
                {item.moveable !== undefined ? getMoveButtonRight(item.group, item.index, item.moveable, item.size ? item.size : item.data ? item.data.length : 0) : void 0}
                {item.moveable !== undefined ? getMoveButtonLeft(item.group, item.index, item.moveable) : void 0}
                {item.close ? <a onClick = {item.close} className = "btn btn-default btn-xs" style = {{color: '#fff', marginRight: '0px', border: '0px', background: 'transparent', position: 'absolute', top: '0px', right: '0px'}}><FontAwesomeIcon icon = {faTimes}/></a>: void 0}
            </div>,
        )
    }
    return <div className = "clearfix"><div className = "fl">{items}</div></div>
}

function getDayFooter(timestamp: string, day: number): string {
    return Assets.DaysInWeek[day].substring(0,3) + ', ' + timestamp.substring(8,10) + timestamp.substring(4,8) + timestamp.substring(0,4) + ' @ ' + timestamp.substring(11,16)
}

function getNPrizes(leaderboard: TournamentResult[]): number {
    let n: number = 0
    for (const item of leaderboard) {
        if (item.prize) { n++ }
    }
    return n
}

export function getPlayerNickname(playerId: number): string {
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general.players) {
    for (const item of Main.Instance.FrontendStoreState.custom.general.players) {
        if (item.id === playerId) {
            return item.nickname
        }
    }
    }
    return '?'
}

export function getPlayerCity(playerId: number): string {
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general.players) {
    for (const item of Main.Instance.FrontendStoreState.custom.general.players) {
        if (item.id === playerId) {
            return item.city
        }
    }
    }
    return '?'
}

export function getPlayerCountry(playerId: number): string {
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder && Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general.players) {
    for (const item of Main.Instance.FrontendStoreState.custom.general.players) {
        if (item.id === playerId) {
            for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
                if (Item['name'] === item.country) {
                    return Item['image']
                }
            }
            break
        }
    }
    }
    return ''
}

function clickExpandButton(selectedView: SelectedView, which: number) {
    if (selectedView === SelectedView.CurrentOverview && which >= 1 && which <= 12 && Main.Instance.DashboardState.expandButton[selectedView] !== which) {

        if (which === 1) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {/*recentEventsActive: true,*//*RecentEventsGetExpiredAccounts*/ExpiredAccountsGet: true})
        } else if (which === 2) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {/*recentEventsActive: true,*//*RecentEventsGetUnverifiedAccountsAll*/UnverifiedAccountsAllGet: true})
        } else if (which === 3) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetDeposits: true})
        } else if (which === 4) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetFirstDeposits: true})
        } else if (which === 5) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetWithdrawals: true})
        } else if (which === 6) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetRegistrations: true})
        } else if (which === 7) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetLogins: true})
        } else if (which === 8) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetLogouts: true})
        } else if (which === 9) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetIssues: true})
        } else if (which === 10) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetIssuesUnresolved: true})
        } else if (which === 11) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetIssuesResolved: true})

        } else if (which === 12) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {/*recentEventsActive: true,*//*ecentEventsGetUnverifiedAccounts*/UnverifiedAccountsGet: true})
        }
        
        DbRoutes.getRecentEvents(which, which >= 3 && which <= 11 ? true : false)
    } else if (selectedView === SelectedView.CurrentOverview && which === 0) {
        DbRoutes.wsGetLoggedInPlayers(1, Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardSmallTableRows : dashboardBigTableRows)
    }
    
    const expandButton: number[] = Object.assign([], Main.Instance.DashboardState.expandButton)
    expandButton[selectedView] = expandButton[selectedView] === which ? -1 : which
    Logic.Type.New (Main.Instance.DashboardState, {expandButton})
}

function clickMoveButtonLeft(selectedView: SelectedView, which: number, moveable: number) {
    const position: number[] = Object.assign([], Main.Instance.DashboardState.position)
    const rows = Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : dashboardSmallTableRows
    position[moveable] = position[moveable] - rows
    if (position[moveable] < 0) {
        position[moveable] = 0
    }
    if (selectedView === 0 && which === 0) {
        DbRoutes.wsGetLoggedInPlayers(Main.Instance.DatabaseDataState.loggedPlayers.pagination.page - 1, rows)
        position[moveable] = 0
    }
    Logic.Type.New (Main.Instance.DashboardState, {position})
}

function clickMoveButtonRight(selectedView: SelectedView, which: number, moveable: number, size: number) {
    const position: number[] = Object.assign([], Main.Instance.DashboardState.position)
    const rows = Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : dashboardSmallTableRows
    position[moveable] = position[moveable] + rows
    if (position[moveable] > size - rows) {
        position[moveable] = size - rows
    } 
    if (selectedView === 0 && which === 0) {
        DbRoutes.wsGetLoggedInPlayers(Main.Instance.DatabaseDataState.loggedPlayers.pagination.page + 1, rows)
        position[moveable] = 0
    }
    Logic.Type.New (Main.Instance.DashboardState, {position})
}

function getExpandButton(selectedView: SelectedView, which: number): JSX.Element {
    // console.log (selectedView, which)
    return (
        <button
            onClick = {() => clickExpandButton(selectedView, which)}
            style = {{position: 'absolute', right: '4px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}}
            className = "btn btn-default btn-sm"
            type = "button"
        >
            <FontAwesomeIcon icon = {Main.Instance.DashboardState.expandButton[selectedView] === which ? faCompress : faExpand}/>
        </button>
    )
}

/*
function getAssignToButton(selectedView: SelectedView, which: number): JSX.Element {
    return (
        <button title = {Main.Instance.DatabaseDataState.showJustAssignedToIssues ? Assets.ShowAllIssues : Assets.ShowJustIssuesThatAreAssignedToYou} style = {{position: 'absolute', right: '25px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}} className = {Main.Instance.DatabaseDataState.showJustAssignedToIssues ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'} type = "button" onClick = {() => clickAssignToButton(selectedView, which)}>
            <FontAwesomeIcon icon = {faUserAlt}/>
        </button>
    )
}

function clickAssignToButton(selectedView: SelectedView, which: number) {
    // if (selectedView === SelectedView.CurrentOverview && which >= 1 && which <= 9 && Main.Instance.DashboardState.expandButton[selectedView] === which) {
        // console.log (which)
    // if (!Main.Instance.DatabaseDataState.showJustAssignedToIssues) {
    // if (which === 1) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetDeposits: true})}
    // if (which === 2) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetFirstDeposits: true})}
    // if (which === 3) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetWithdrawals: true})}
    // if (which === 4) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetRegistrations: true})}
    // if (which === 5) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetLogins: true})}
    // if (which === 6) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetLogouts: true})}
    // if (which === 7) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetIssues: true})}
    // if (which === 8) {
    Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetIssuesUnresolved: true})
    // }
    // if (which === 9) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues, recentEventsActive: true, RecentEventsGetIssuesResolved: true})}
    DbRoutes.getRecentEvents(which, true)
    // }
    // Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, showJustAssignedToIssues: !Main.Instance.DatabaseDataState.showJustAssignedToIssues})

    const expandButton: number[] = Object.assign([], Main.Instance.DashboardState.expandButton)
    expandButton[selectedView] = which // expandButton[selectedView] === which ? -1 : which
    Logic.Type.New (Main.Instance.DashboardState, {expandButton})
}
*/
function getMoveButtonRight(selectedView: SelectedView, which: number, moveable: number, size: number): JSX.Element {
    let disabledButton = false
    const rows = Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : dashboardSmallTableRows
    if (selectedView === SelectedView.CurrentOverview && which === 0) {
        if (Main.Instance.DatabaseDataState.loggedPlayers && Main.Instance.DatabaseDataState.loggedPlayers.pagination && Main.Instance.DatabaseDataState.loggedPlayers.pagination.page * rows >= size) {
            disabledButton = true
        }
    } else {
        if ((Main.Instance.DashboardState.position[moveable] >= size - rows)) {
            disabledButton = true
        }
    }
    return (
        <button disabled = {disabledButton} style = {{position: 'absolute', left: '18px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}} className = "btn btn-default btn-sm" type = "button" onClick = {() => clickMoveButtonRight(selectedView, which, moveable, size)}>
            <FontAwesomeIcon icon = {faCaretRight}/>
        </button>
    )
}

function getMoveButtonLeft(selectedView: SelectedView, which: number, moveable: number): JSX.Element {
    let disabledButton = false

    if (selectedView === SelectedView.CurrentOverview && which === 0) {
        if (Main.Instance.DatabaseDataState.loggedPlayers && Main.Instance.DatabaseDataState.loggedPlayers.pagination && Main.Instance.DatabaseDataState.loggedPlayers.pagination.page === 1) {
            disabledButton = true
        }
    } else {
        if (Main.Instance.DashboardState.position[moveable] === 0) {
            disabledButton = true
        }
    }
    return (
        <button disabled = {disabledButton} style = {{position: 'absolute', left: '4px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}} className = "btn btn-default btn-sm" type = "button" onClick = {() => clickMoveButtonLeft(selectedView, which, moveable)}>
            <FontAwesomeIcon icon = {faCaretLeft}/>
        </button>
    )
}

function getContent(content: Content[], expand?: boolean): JSX.Element[] {
    const cont: JSX.Element[] = []
    const addSize: number = expand ? 10 : 0
    if (content) {
        for (const item of content) {
            // if (item.el) {
            cont.push(
                <p key = {cont.length.toString()} className = {item.el ? 'dashboard-item-content-element' : 'dashboard-item-content'} style = {{textAlign: item.tac !== undefined ? item.tac ? 'center' : 'right' : 'left', lineHeight: item.lineHeight ? (item.lineHeight + addSize) + 'px' : '', fontSize: item.size ? (item.size + addSize) + 'px' : '', fontWeight: item.bold ? 700 : 400}}>
                    {item.el ? item.el : item.text ? item.text : ''}
                </p>
            )
/*
            } else {
                cont.push (
                    <p key = {cont.length.toString()} className = "dashboard-item-content" style = {{textAlign: item.tac !== undefined ? item.tac ? 'center' : 'right' : 'left', lineHeight: item.lineHeight ? (item.lineHeight + addSize) + 'px' : '', fontSize: item.size ? (item.size + addSize) + 'px' : '', fontWeight: item.bold ? 700 : 400}}>
                        {item.text}
                    </p>,
                )
            }
*/
        }
    }
    return cont
}

function selectNavigation(selectedView: SelectedView) {
    let selectedResolvedType = Main.Instance.DashboardState.selectedResolvedType

    if (selectedView === SelectedView.Gamification && Main.Instance.DatabaseDataState.raffle === undefined) {
        DbRoutes.wsBasicGet(Config.amRaffle)
/*
    } else if (selectedView === SelectedView.Veto || selectedView === SelectedView.Crif || selectedView === SelectedView.Pep) {
        if (selectedView === SelectedView.Veto && Main.Instance.DatabaseDataState.blacklist === undefined) {
            // Logic.Type.New (Main.Instance.DatabaseDataState, {BlacklistGet: true, blacklist: []})
            DbRoutes.wsBasicGet(Config.amBlacklist)
        }

        // Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetIssues: true})
        // DbRoutes.getRecentEvents()
        // Logic.Type.New (Main.Instance.DatabaseDataState, {recentEventsActive: true, RecentEventsGetIssues: true})
        // DbRoutes.wsGetIssues()
*/
    } else if (selectedView === SelectedView.DashboardBackend && Main.Instance.DatabaseDataState.dashboardBackendOptionsDates === undefined) {
        // Socket1.socket.Send(Config.amGetIssues, {
        //     filters: {
        //         resolved: false,
        //         minIssueType: IssueTypes.DashboardBackend,
        //         maxIssueType: IssueTypes.Other2 - 1,
        //     },
        //     page: Main.Instance.DatabaseDataState.issues0Page,
        //     limit: Main.Instance.DatabaseDataState.issues0Limit
        // })
        const date: Date = new Date()

        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
        DbRoutes.getDashboardBackendIssues({
            resolved: false,
            minIssueType: IssueTypes.DashboardBackend,
            maxIssueType: IssueTypes.Other2 - 1,
            dateFrom: Math.floor((new Date(date)).getTime()/1000),
            dateTo: Math.floor((new Date(date)).getTime()/1000) + 24 * 60 * 60 - 1,
        
        })
        // console.log ('in')
        selectedResolvedType = Assets.Unresolved
        // DbRoutes.dashboardBackendCount()
        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssues)
    } else if (selectedView === SelectedView.RiskAlerts) {
        Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
        selectedResolvedType = Assets.Unresolved
        FirstPage1(true, 999)
        Logic.Type.New (Main.Instance.DatabaseDataState, {issues1Page: 1, issuesFilterIssueTypeRM: 999})

    } else if (selectedView === SelectedView.CustomerSupportCallback) {
        Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
        selectedResolvedType = Assets.Unresolved
        FirstPage2(true, 999)
        Logic.Type.New (Main.Instance.DatabaseDataState, {issues2Page: 1, issuesFilterIssueTypeRG: 999})
    } else if (selectedView === SelectedView.RecentUploads) {
        Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
        selectedResolvedType = Assets.Unresolved
        FirstPage3(true, 999)
        Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1, issuesFilterIssueTypeKYC: 999})
    } else if (selectedView === SelectedView.GamificationIssues) {
        Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
        selectedResolvedType = Assets.Unresolved
        FirstPage4(true, 999)
        Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1, issuesFilterIssueTypeGM: 999})
    }
    Logic.Type.New (Main.Instance.DashboardState, {selectedView, selectedResolvedType})
    Logic.Type.New (Main.Instance.RiskManagementState, {showAddCSCallbackModal: false/*, showAddRiskAlertsModal: false, showAddKYCModal: false*/})
} 


function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    const unresolved: number[] = GetUnresolvedAll (TimelineEntrySubType.RiskManagement, TimelineEntrySubType.ResponsibleGaming, TimelineEntrySubType.KnowYourCustomer, TimelineEntrySubType.Gamification)

    for (let i: number = 0; i < Assets.SelectedView.length; i++) {
        // if (i === SelectedView.GamificationIssues) { continue }
        // if (i === SelectedView.Settlement) { continue }
        // if (i === SelectedView.Settlement || i === SelectedView.GiftShop || i === SelectedView.Raffles || i === SelectedView.Tournaments) continue
/*
        if (i === SelectedView.RiskAlerts) {
            navigation.push(
                <button
                    title = {Assets.AllIssues}
                    // disabled = {true}
                    type = "button"
                    key = "-1"
                    style = {{marginRight: '0px', display: 'block', float: 'right', cursor: 'default', paddingLeft: '5px', paddingRight: '5px'}}
                    className = "btn btn-default btn-sm pr"
                    onClick = {() => {}}
                >
                    <span style = {{position: 'absolute', top: '-1px', left: '4px', fontSize: '21px'}}>{Assets_.sum}</span>&nbsp;&nbsp;
                    <span style = {unresolved[0] + unresolved[1] + unresolved[2] + unresolved[3] >= 10 ? {backgroundColor: '#d9534f', color: '#fff', marginLeft: '10px'} : {marginLeft: '10px'}} className="badge badge-light">{unresolved[0] + unresolved[1] + unresolved[2] + unresolved[3]}</span>
                </button>,
            )    
        }
*/      
        const dbUnresolved: number = getDashboardBackendIssuesCountUnresolved()
        navigation.push(
            <button
                title = {i === SelectedView.IssueFilters ? Assets.IssuesFilterBy : i >= SelectedView.RiskAlerts ? Assets.Unresolved + ' ' + Assets.SelectedViewDescriptions[i].toLowerCase() : /*i >= SelectedView.Veto ? Assets.SelectedViewDescriptions[i] :*/ undefined}
                disabled = {i === SelectedView.AccountsData && !isUserReports()/* || i === SelectedView.DashboardBackend && !isUserRGSpecialist() || i === SelectedView.RiskAlerts && !isUserRFSpecialist() || i === SelectedView.CustomerSupportCallback && !isUserRGSpecialist() || i === SelectedView.RecentUploads && !isUserGDPR()*/}
                onClick = {() => i === SelectedView.AccountsData ? Main.Instance.DatabaseDataState.allAccountsStarted ? {} : DbRoutes.getAllAccounts() : selectNavigation(i)}
                type = "button"
                key = {i.toString()}
                style = {{marginRight: i === SelectedView.RiskAlerts || i === SelectedView.AccountsData ? '0px' : '', display: 'block', float: i === SelectedView.RecentUploads || i === SelectedView.RiskAlerts || i === SelectedView.CustomerSupportCallback || i === SelectedView.GamificationIssues || i === SelectedView.IssueFilters ? 'right' : 'left'}}
                className = {i === SelectedView.AccountsData && Main.Instance.DatabaseDataState.allAccountsStarted ? 'btn btn-warning btn-sm' : /*i === SelectedView.Refresh ? 'btn btn-danger btn-sm' :*/i === Main.Instance.DashboardState.selectedView ? /* i === SelectedView.CurrentOverview ? 'btn btn-success btn-sm' :*/ /* i === SelectedView.Last24Hours ? 'btn btn-info btn-sm' : */ 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
            >
                <FontAwesomeIcon style = {{color: i === SelectedView.AccountsData ? '#dc3545' : '', marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedViewIcons[i]}/>
                {i === SelectedView.AccountsData ? 
                <FontAwesomeIcon style = {{color: '#dc3545', marginRight: '0px', float: 'left', fontSize: '17px'}} icon = {faUserCircle}/>
                :
                <>
                {Assets.SelectedView[i]}
                { i === SelectedView.GamificationIssues ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {unresolved[3] > 0 ? unresolved[3] >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{unresolved[3]}</span></>
                : i === SelectedView.RecentUploads ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {unresolved[2] > 0 ? unresolved[2] >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{unresolved[2]}</span></>
                : i === SelectedView.RiskAlerts ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {unresolved[0] > 0 ? {backgroundColor: '#d9534f', color: '#fff'}: {}}>{unresolved[0]}</span></>
                : i === SelectedView.CustomerSupportCallback ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {unresolved[1] > 0 ? unresolved[1] >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{unresolved[1]}</span></>
                : i === SelectedView.IssueFilters ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {Main.Instance.DatabaseDataState.totalIssuesCount > 0 ? Main.Instance.DatabaseDataState.totalIssuesCount >= 10? {backgroundColor: '#6c757d', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{Main.Instance.DatabaseDataState.totalIssuesCount}</span></>
                : i === SelectedView.DashboardBackend ? <>&nbsp;&nbsp;<span className="badge badge-light" style = {dbUnresolved > 0 ? {backgroundColor: '#d9534f', color: '#fff'}: {}}>{dbUnresolved}</span></>
                : void 0}
                </>}
            </button>)
    }
    return navigation
}

function isExpanded(): boolean {
    for (const item of Main.Instance.DashboardState.expandButton) {
        if (item !== -1) {
            return true
        }
    }
    return false
}

export function Dashboard(props: React.Props<any>): JSX.Element {
    // console.log ('Dashboard')
    DbRoutes.wsReinitialize()
    // console.log (Main.Instance.DatabaseDataState.issues)
    // Main.Instance.DashboardState.expandButton
    return (
        <div>
            <div style = {{marginRight: Main.Instance.DashboardState.selectedView === SelectedView.CurrentOverview /* || Main.Instance.DashboardState.selectedView === SelectedView.Veto || Main.Instance.DashboardState.selectedView === SelectedView.Crif || Main.Instance.DashboardState.selectedView === SelectedView.Pep*/ || Main.Instance.DashboardState.selectedView === SelectedView.Gamification ? isExpanded() ? '17px' : '28px' : '0px'}} className = "clearfix">
                {getNavigation()}
            </div> {/*used to be marginRight: 10px, 0px*/}
            <br/>
            <div>
                {/*Main.Instance.RiskManagementState.showAddKYCModal ? <ResolveKYCUploadPopup/> : void 0*/}
                {/*Main.Instance.RiskManagementState.showAddRiskAlertsModal ? <ChangePlayerRiskLevelPopup what = {Assets.ChangePlayerRiskLevel}/> : void 0*/}
                {Main.Instance.RiskManagementState.history ? <History/> : void 0}
                {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/> : void 0}
                {Main.Instance.DashboardState.selectedView === SelectedView.RiskAlerts ?
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.DashboardState,  {selectedView: SelectedView.CurrentOverview})}
                        {getIssuesShow(false)}
                        {getIssuesFilter(1, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
                        {/*console.log (Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM)*/}
                        {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
                        getUnresolvedSubCategoryButtons(1, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
                        {getControlPanel1(true)}
                        {getIssuesTable(false, Main.Instance.DatabaseDataState.issues1, TimelineEntrySubType.RiskManagement, true)}
                    </div>
                </div>
                : Main.Instance.DashboardState.selectedView === SelectedView.CustomerSupportCallback ?
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.DashboardState,  {selectedView: SelectedView.CurrentOverview})}
                        {getIssuesShow(false)}
                        {getIssuesFilter(2, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
                        {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
                        getUnresolvedSubCategoryButtons(2, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
                        {getControlPanel2(true)}
                        {getIssuesTable(false, Main.Instance.DatabaseDataState.issues2, TimelineEntrySubType.ResponsibleGaming, true)}
                    </div>
                </div>
                : Main.Instance.DashboardState.selectedView === SelectedView.RecentUploads ?
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.DashboardState, {selectedView: SelectedView.CurrentOverview})}
                        {getIssuesShow(false)}
                        {getIssuesFilter(3, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
                        {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
                        getUnresolvedSubCategoryButtons(3, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
                        {getControlPanel3(true)}
                        {getIssuesTable(false, Main.Instance.DatabaseDataState.issues3, TimelineEntrySubType.KnowYourCustomer, true)}
                    </div>
                </div>
                : Main.Instance.DashboardState.selectedView === SelectedView.GamificationIssues ?
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.DashboardState, {selectedView: SelectedView.CurrentOverview})}
                        {getIssuesShow(false)}
                        {getIssuesFilter(4, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
                        {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
                        getUnresolvedSubCategoryButtons(4, FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
                        {getControlPanel4(true)}
                        {getIssuesTable(false, Main.Instance.DatabaseDataState.issues4, TimelineEntrySubType.Gamification, true)}
                    </div>
                </div>
                : Main.Instance.DashboardState.selectedView === SelectedView.DashboardBackend ?
                <div>
                    <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                        <div 
                            className = "clearfix test-site-data2 light_grey" 
                            style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
                        >
                            {getIssuesShow(false)}
                            {/*console.log (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom !== -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo !== -1 ? Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom : Main.Instance.DatabaseDataState.unresolvedIssuesCount)*/}
                            {getIssuesFilter(0, Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom !== -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo !== -1 ? Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
                            {/*    FourIssues() && Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : FourIssues() && Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)*/}
                            {getIssuesShortcut(0)}
                            {getControlPanel0()}
                            {Main.Instance.DatabaseDataState.issuesDashboardBackend ?
                            getIssuesTable(false, Main.Instance.DatabaseDataState.issuesDashboardBackend, TimelineEntrySubType.DashboardBackend, false, true) : void 0}
                        </div>
                    </div>
                </div>
                : Main.Instance.DashboardState.selectedView === SelectedView.IssueFilters ?
                  getIssueFilters()
                : getItems()}
            </div>
        </div>
    )
}

/*
                : Main.Instance.DashboardState.selectedView === SelectedView.Players ?
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    {DashboardPlayers()}
                </div>


*/

export function getIssueFilters(): JSX.Element {
    return (
        <div
            className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix"
            style = {{padding: '10px', position: 'relative', minHeight: '50px'}}
        >
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'0px', paddingBottom:'10px'}}
            >
                <div className = "clearfix mb5">
                    {getAllIssuesCategoryButtons()}
                    {Main.Instance.DashboardState.selectedIssueCategory === 'Risk' ||
                     Main.Instance.DashboardState.selectedIssueCategory === 'RG' ||
                     Main.Instance.DashboardState.selectedIssueCategory === 'KYC' ||
                     Main.Instance.DashboardState.selectedIssueCategory === 'GM' ?
                     <>
                    {getAllIssuesSubCategory()}
                    {Main.Instance.DashboardState.selectedIssueSubCategory === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategory) ?
                    getSubCategoryButtons() : void 0}
                    {/*getAllIssuesFilter()*/}
                    {getAllIssuesDateFilter()}
                    {getControlPanel()}
                    {Main.Instance.DatabaseDataState.issuesAllIssuesFilter ?
                    getIssuesTable(false, Main.Instance.DatabaseDataState.issuesAllIssuesFilter, undefined, false, true, Main.Instance.DashboardState.selectedIssueType, Main.Instance.DashboardState.selectedIssueCategory) : void 0}
                    </>: void 0}
                </div>
            </div>
        </div>
    )
} 

function GetCount(): number {

    let which = -1
    if (Main.Instance.DashboardState.selectedIssueCategory === 'Risk') {
        which = 0
    } else if (Main.Instance.DashboardState.selectedIssueCategory === 'RG') {
        which = 1
    } else if (Main.Instance.DashboardState.selectedIssueCategory === 'KYC') {
        which = 2
    } else if (Main.Instance.DashboardState.selectedIssueCategory === 'GM') {
        which = 3
    }

    let issuesCount: IIssuesCount[]
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom !== -1 || Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo !== -1) {
        issuesCount =
            Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                Main.Instance.DatabaseDataState.issuesCountCustom :
            Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom :
                Main.Instance.DatabaseDataState.resolvedIssuesCountCustom
    } else {
        issuesCount =
            Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                Main.Instance.DatabaseDataState.issuesCount :
            Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                Main.Instance.DatabaseDataState.unresolvedIssuesCount :
                Main.Instance.DatabaseDataState.resolvedIssuesCount
    }

    if ( Main.Instance.DashboardState.selectedIssueType === 777) {
        return getIssuesCategoryCount(issuesCount[which].issuesCounts, Main.Instance.DashboardState.selectedIssueSubCategory)
    } else if (Main.Instance.DashboardState.selectedIssueType === 999) {
        return issuesCount[which].totalIssues
    } else {
        for (const item of issuesCount[which].issuesCounts) {
            if (item.issueType === Main.Instance.DashboardState.selectedIssueType) {
                return item.count
            }
        }
    }

    return 0
}

function getControlPanel(): JSX.Element {
    const count: number = GetCount()
    const mt7: string = ' mt3'
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issuesLimit ? '' : Main.Instance.DatabaseDataState.issuesLimit.toString()}
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            {GetIssuesShow(true)}

            <span className = {'label label-success table-label' + mt7}>
                {Assets.records}
                {count}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issuesPage - 1) * 
                Main.Instance.DatabaseDataState.issuesLimit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issuesPage) * 
                Main.Instance.DatabaseDataState.issuesLimit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issuesPage) * Main.Instance.DatabaseDataState.issuesLimit) : 0}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issuesPage : 0}{' / '}
                {count ? Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issuesLimit + 1) : 0}
            </span>
        </div>
    )
}

function getIssuesShortcut(which: number): JSX.Element {
    const buttons: JSX.Element[] = []
    if (which === 0) {

        buttons.push(
            <button
                key = "datetoday"
                onClick = {() => {
                    // console.log (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates)
                    if (!isToday() && Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 1, true),
                            dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 1, false),
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            // dashboardBackendOptionsToday: !Main.Instance.DatabaseDataState.dashboardBackendOptionsToday,
                            dashboardBackendOptionsDatesFrom: Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 1,
                            dashboardBackendOptionsDatesTo: Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                        })
                        DbRoutes.dashboardBackendCount()
                    } else {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            // dashboardBackendOptionsToday: !Main.Instance.DatabaseDataState.dashboardBackendOptionsToday,
                            dashboardBackendOptionsDatesFrom: -1,
                            dashboardBackendOptionsDatesTo: -1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                        })
                    }
                }}
                className = {isToday() ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.Today}
            </button>
        )
        
        buttons.push(
            <button
                key = "dateyesterday"
                onClick = {() => {
                    if (!isYesterday() && Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 2, true),
                            dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 2, false),
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            dashboardBackendOptionsDatesFrom: Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 2,
                            dashboardBackendOptionsDatesTo: Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length - 2
                        })
                        
                        DbRoutes.dashboardBackendCount()

                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                        })
                    } else {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            dashboardBackendOptionsDatesFrom: -1,
                            dashboardBackendOptionsDatesTo: -1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                        })
                    }
                }}
                className = {isYesterday() ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.Yesterday}
            </button>
        )

        buttons.push(
            <button
                key = "dateany"
                onClick = {() => {
                    if (!isAnyDate()) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            dashboardBackendOptionsDatesFrom: -1,
                            dashboardBackendOptionsDatesTo: -1
                        })
                        // DbRoutes.dashboardBackendCount()
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                        })
                    }
                }}
                className = {isAnyDate() ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.AnyDate}
            </button>
        )

        buttons.push(
            <button
                key = "clearfilters"
                onClick = {() => {
                    // if (!isAnyDate()) {
                    DbRoutes.getDashboardBackendIssues({
                        resolved: false,
                        minIssueType: IssueTypes.DashboardBackend,
                        maxIssueType: IssueTypes.Other2 - 1,
                    })
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        dashboardBackendOptionsDatesFrom: -1,
                        dashboardBackendOptionsDatesTo: -1
                    })
                    Logic.Type.New (Main.Instance.DashboardState, {
                        monthFrom: -1,
                        monthTo: -1,
                        yearFrom: -1,
                        yearTo: -1,
                    })
                    // }
                }}
                className = "btn btn-danger dbl fl btn-xs mr0"
            >
                {Assets.Clear + ' ' + Assets.Date.toLowerCase() + ' ' + Assets.Filters.toLowerCase()}
            </button>
        )

        const Buttons: JSX.Element[] = []
        Buttons.push (<FontAwesomeIcon style = {{fontSize: '21px'}} key = "dateCalendar" className = "dbl fl" icon = {faCalendarDay}/>)

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Year}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.yearFrom}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {yearFrom: +e.target.value})
                } 
            >
                {getYearOptions()}
            </select>
        )
        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Month}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.monthFrom}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {monthFrom: +e.target.value})
                } 
            >
                {getMonthOptions(true)}
            </select>
        )

        if (Main.Instance.DashboardState.monthFrom > -1) {
            Buttons.push(
                <select
                    style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                    className = "fl dbl form-control"
                    value = {Main.Instance.DashboardState.monthFromPart}
                    onChange = {(e) => 
                        Logic.Type.New (Main.Instance.DashboardState, {monthFromPart: +e.target.value})
                    }
                >
                    {getMonthPartOptions(true)}
                </select>
            )
        }

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Date}
                className = "fl dbl form-control"
                value = {Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom}
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo >= +e.target.value) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(+e.target.value, true),
                            dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {dashboardBackendOptionsDatesFrom: +e.target.value})
                        DbRoutes.dashboardBackendCount()
                    } /*else {
                        console.log (Assets.notPossible)
                    }*/
                }}
            >
                {getDateOptions(true)}
            </select>
        ) 

        Buttons.push(<div key = "datehyphen" className = "fl" style = {{marginLeft: '5px'}}>-</div>)

        Buttons.push(
                
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Year}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.yearTo}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {yearTo: +e.target.value})
                }
            >
                {getYearOptions()}
            </select>
        )

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Month}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.monthTo}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {monthTo: +e.target.value})
                }
            >
                {getMonthOptions(false)}
            </select>
        )

        if (Main.Instance.DashboardState.monthTo > -1) {
            Buttons.push(
                <select
                    style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                    className = "fl dbl form-control"
                    value = {Main.Instance.DashboardState.monthToPart}
                    onChange = {(e) =>
                        Logic.Type.New (Main.Instance.DashboardState, {monthToPart: +e.target.value})
                    }
                >
                    {getMonthPartOptions(false)}
                </select>
            )
        }

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Date}
                className = "fl dbl form-control"
                value = {Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo}
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom <= +e.target.value) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
                            dateTo: convertToUnix(+e.target.value, false),
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {dashboardBackendOptionsDatesTo: +e.target.value})
                        DbRoutes.dashboardBackendCount()
                    }
                }}
            >
                {getDateOptions(false)}
            </select>
        )
        buttons.push(<div className = "fr clearfix">{Buttons}</div>)
    } 
    return (
        <div className = "clearfix mb5">
            {buttons}
        </div>
    )
}

function handleClick(issueCategory, issueType, resolved, dateSelection, dateFrom, dateTo, issuesPage: number) {
    const filters = {
        IssueCategory: issueCategory !== undefined ? issueCategory : Main.Instance.DashboardState.selectedIssueCategory,
        IssueType: issueType !== undefined ? issueType : Main.Instance.DashboardState.selectedIssueType,
        Resolved: resolved !== undefined ? resolved : Main.Instance.DashboardState.selectedResolvedType,
        DateSelection : dateSelection !== undefined ? dateSelection : Main.Instance.DashboardState.selectedDay,
        DateFrom: dateFrom !== undefined ? dateFrom : Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom,
        DateTo: dateTo !== undefined ? dateTo : Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo,
    }
    // issuesPage = issuesPage ? issuesPage : 1

    if (issuesPage !== Main.Instance.DatabaseDataState.issuesPage) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {issuesPage})
    }

    // console.log('dateFrom sent', dateFrom)
    // console.log('dateTo sent', dateTo)

    // console.log('dateFrom state', Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom)
    // console.log('dateTo state', Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo)

    let MinIssueType: number
    let MaxIssueType: number
    let Resolved: boolean
    let DateFrom: number
    let DateTo: number

    if (filters.IssueType === 999) {
        if (filters.IssueCategory === 'Risk') {
            MinIssueType = IssueTypes.RiskManagement,
            MaxIssueType = IssueTypes.Other1
        } else if (filters.IssueCategory === 'RG') {
            MinIssueType = IssueTypes.ResponsibleGaming,
            MaxIssueType = IssueTypes.Other2
        } else if (filters.IssueCategory === 'KYC') {
            MinIssueType = IssueTypes.KnowYourCustomer,
            MaxIssueType = IssueTypes.Other3
        } else if (filters.IssueCategory === 'GM') {
            MinIssueType = IssueTypes.Gamification,
            MaxIssueType = IssueTypes.Other4
        } 
    } else if(filters.IssueType === 777) {
        
        MinIssueType = getMinMaxIssueType(Main.Instance.DashboardState.selectedIssueSubCategory, 0)
        MaxIssueType = getMinMaxIssueType(Main.Instance.DashboardState.selectedIssueSubCategory, 1)


    } else {
        MinIssueType = filters.IssueType,
        MaxIssueType = filters.IssueType
    }
    
    
    // console.log('DateFrom before', filters.DateFrom)
    // console.log('DateTo before', filters.DateFrom)
    
    if (filters.Resolved === Assets.Resolved) {
        Resolved = true
    } else if (filters.Resolved === Assets.Unresolved) {
        Resolved = false
    } else if (filters.Resolved === Assets.All) {
        Resolved = undefined
    }

    if (dateSelection && filters.DateSelection === Assets.Today) {
        DateFrom = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1
        DateTo = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1
    } else if (dateSelection && filters.DateSelection === Assets.Yesterday) {
        DateFrom = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2
        DateTo = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2
    } else if (dateSelection && filters.DateSelection === Assets.All) {
        DateFrom = -1
        DateTo = -1
    } else {
        DateFrom = filters.DateFrom
        DateTo = filters.DateTo
    }

    // console.log('sent to route')
    const issueTypeArr = getIssueTypes(MinIssueType, MaxIssueType)
    DbRoutes.getAllIssues(
        {
            // minIssueType: MinIssueType,
            // maxIssueType: MaxIssueType,
            issueType: issueTypeArr,
            dateFrom: convertToUnixAllIssues(DateFrom, true),
            dateTo: convertToUnixAllIssues(DateTo, false),
            resolved: Resolved
        },
        issuesPage,
        Main.Instance.DatabaseDataState.issuesLimit
    )
} 

function getMinMaxIssueType(category, which) {
    let MinIssueType
    let MaxIssueType
    if (category === 9999) {
        MinIssueType = IssueTypes.UploadDocument,
        MaxIssueType = IssueTypes.ChangedUsername
    } else if (category === 410) {
        MinIssueType = IssueTypes.BonusAdd,
        MaxIssueType = IssueTypes.BonusCancelAllManual
    } else if (category === 210) {
        MinIssueType = IssueTypes.BlacklistRegistration,
        MaxIssueType = IssueTypes.BlacklistWhitelisted
    } else if (category === 250) {
        MinIssueType = IssueTypes.DepositMethods,
        MaxIssueType = IssueTypes.IncreasedNetloss
    } else if (category === 110) {
        MinIssueType = IssueTypes.ThreeOrMoreDepositsBelowHundredWithinTenMinutes,
        MaxIssueType = IssueTypes.UnusualBonusDepositRatio101
    } else if (category === 340) {
        MinIssueType = IssueTypes.LargeDeposit,
        MaxIssueType = IssueTypes.ManualDeposit
    } else if (category === 360) {
        MinIssueType = IssueTypes.AccountClosedProvisional,
        MaxIssueType = IssueTypes.AccountClosedActive
    } else if (category === 369) {
        MinIssueType = IssueTypes.CRIFCheckMismatch,
        MaxIssueType = IssueTypes.PEPChangedByOperator
    } else if (category === 380) {
        MinIssueType = IssueTypes.BallyRegistrationSuccess,
        MaxIssueType = IssueTypes.BallyRegistrationFailed
    }

    if (which === 0) {
        return MinIssueType
    } else if (which === 1) {
        return MaxIssueType
    }
}

// function handleClickRisk(issueType, resolved, dateSelection, dateFrom, dateTo, issues1Page: number) {
//     const filters = {
//         IssueType: issueType !== undefined ? issueType : Main.Instance.DashboardState.selectedIssueType,
//         Resolved: resolved !== undefined ? resolved : Main.Instance.DashboardState.selectedResolvedType,
//         DateSelection : dateSelection !== undefined ? dateSelection : Main.Instance.DashboardState.selectedDay,
//         DateFrom: dateFrom !== undefined ? dateFrom : Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom,
//         DateTo: dateTo !== undefined ? dateTo : Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo,
//     }

//     if (issues1Page !== Main.Instance.DatabaseDataState.issues1Page) {
//         Logic.Type.New (Main.Instance.DatabaseDataState, {issues1Page})
//     }

//     let MinIssueType: number
//     let MaxIssueType: number
//     let Resolved: boolean
//     let DateFrom: number
//     let DateTo: number

//     if (filters.IssueType === 999) {
//         MinIssueType = IssueTypes.RiskManagement,
//         MaxIssueType = IssueTypes.Other1
//     } else if(filters.IssueType === 777) {
//         if (filters.IssueType === 110) {
//             MinIssueType = IssueTypes.ThreeOrMoreDepositsBelowHundredWithinTenMinutes,
//             MaxIssueType = IssueTypes.UnusualBonusDepositRatio101
//         } 

//     } else {
//         MinIssueType = filters.IssueType,
//         MaxIssueType = filters.IssueType
//     }
    
//     if (filters.Resolved === Assets.Resolved) {
//         Resolved = true
//     } else if (filters.Resolved === Assets.Unresolved) {
//         Resolved = false
//     } else if (filters.Resolved === Assets.All) {
//         Resolved = undefined
//     }
    

//     if (dateSelection && filters.DateSelection === Assets.Today) {
//         DateFrom = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1
//         DateTo = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1
//     } else if (dateSelection && filters.DateSelection === Assets.Yesterday) {
//         DateFrom = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2
//         DateTo = Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2
//     } else if (dateSelection && filters.DateSelection === Assets.All) {
//         DateFrom = -1
//         DateTo = -1
//     } else {
//         DateFrom = filters.DateFrom
//         DateTo = filters.DateTo
//     }

//     // console.log('sent to route')
//     // console.log('Min', MinIssueType)
//     // console.log('Max', MaxIssueType)
//     // console.log('DateFrom', convertToUnixAllIssues(DateFrom, true))
//     // console.log('DateTo', convertToUnixAllIssues(DateTo, false))
//     // console.log('Resolved', Resolved)
//     // console.log(issuesPage)
//     // console.log(Main.Instance.DatabaseDataState.issuesLimit)

//     DbRoutes.getPaginatedIssues(
//         {
//             resolved: FourIssues() ? Resolved : false,
//             minIssueType: MinIssueType,
//             maxIssueType: MaxIssueType,
//             dateFrom: convertToUnixAllIssues(DateFrom, true),
//             dateTo: convertToUnixAllIssues(DateTo, false)
//         },
//         issues1Page,
//         Main.Instance.DatabaseDataState.issues1Limit,
//         TimelineEntrySubType.RiskManagement,
//         Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
//     )
// } 

function getAllIssuesCategoryButtons(): JSX.Element {
    const buttons: JSX.Element[] = []
    // console.log (Main.Instance.DashboardState.selectedResolvedType)
    // console.log(Main.Instance.DatabaseDataState.issuesCount)
    
    for (let i: number = 0; i < Assets.SelectedView.length; i++) {
        if (Assets.SelectedView[i] === 'Risk' || Assets.SelectedView[i] === 'RG' || Assets.SelectedView[i] === 'KYC' || Assets.SelectedView[i] === 'GM') {
            buttons.push(
                <button
                    // disabled = {Assets.SelectedView[i] === 'Risk' && !isUserRFSpecialist() || Assets.SelectedView[i] === 'RG' && !isUserRGSpecialist()  || Assets.SelectedView[i] === 'KYC' && !isUserGDPR()}
                    value = {Assets.SelectedView[i]}
                    onClick = {() => {
                        handleClick(Assets.SelectedView[i], 999, undefined, undefined, undefined, undefined, 1)
                            
                        Logic.Type.New (Main.Instance.DashboardState, {
                            selectedIssueCategory: Assets.SelectedView[i], 
                            selectedIssueType: 999, 
                            // selectedIssueTypeAllIssues: true, 
                            selectedResolvedType: /*Main.Instance.DashboardState.selectedResolvedType ? Main.Instance.DashboardState.selectedResolvedType : */Assets.All,
                            selectedIssueSubCategory: -1 
                        })
                    }}
                    type = "button"
                    key = {i.toString()}
                    style = {{display: 'block', float: 'left', position: 'relative'}}
                    className = {Main.Instance.DashboardState.selectedIssueCategory ===  Assets.SelectedView[i] ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
                >
                    {Assets.SelectedView[i] === 'RG' ?
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> :
                    Assets.SelectedView[i] === 'KYC' ?
                    <>&nbsp;&nbsp;&nbsp;&nbsp;</> :
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <FontAwesomeIcon style = {{fontSize: '16px', position: 'absolute', top: '6px', left: '8px'}} icon = {Assets_.SelectedViewIcons[i]}/>&nbsp;&nbsp;
                    {Assets.SelectedViewDescriptions[i]}
                    <>&nbsp;&nbsp;
                        <span
                            className="badge badge-light"
                            style = {
                                Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                                    Main.Instance.DatabaseDataState.issuesCount && Main.Instance.DatabaseDataState.issuesCount[i-5] ? Main.Instance.DatabaseDataState.issuesCount[i-5].totalIssues > 0 ? Main.Instance.DatabaseDataState.issuesCount[i-5].totalIssues >= 10 ? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}: {}
                                : Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                                    Main.Instance.DatabaseDataState.unresolvedIssuesCount && Main.Instance.DatabaseDataState.unresolvedIssuesCount[i-5] ? Main.Instance.DatabaseDataState.unresolvedIssuesCount[i-5].totalIssues > 0 ? Main.Instance.DatabaseDataState.unresolvedIssuesCount[i-5].totalIssues >= 10 ? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}: {}                                
                                :
                                    Main.Instance.DatabaseDataState.resolvedIssuesCount && Main.Instance.DatabaseDataState.resolvedIssuesCount[i-5] ? Main.Instance.DatabaseDataState.resolvedIssuesCount[i-5].totalIssues > 0 ? Main.Instance.DatabaseDataState.resolvedIssuesCount[i-5].totalIssues >= 10 ? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}: {}
                            }
                        >
                            {Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                                Main.Instance.DatabaseDataState.issuesCount && Main.Instance.DatabaseDataState.issuesCount[i-5] ? Main.Instance.DatabaseDataState.issuesCount[i-5].totalIssues : 0
                            : Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                                Main.Instance.DatabaseDataState.unresolvedIssuesCount && Main.Instance.DatabaseDataState.unresolvedIssuesCount[i-5] ? Main.Instance.DatabaseDataState.unresolvedIssuesCount[i-5].totalIssues : 0

                            :
                                Main.Instance.DatabaseDataState.resolvedIssuesCount && Main.Instance.DatabaseDataState.resolvedIssuesCount[i-5] ? Main.Instance.DatabaseDataState.resolvedIssuesCount[i-5].totalIssues : 0
                            }
                        </span>
                    </>
                </button>
            )
        }
    }

    if (Main.Instance.DashboardState.selectedIssueCategory === undefined) {
        buttons.push(<span key = "issue-category" className = "label label-default label-sm" style = {{fontSize: '14px', display: 'block', float: 'right', marginTop: '5px', fontWeight: 600}}>{Assets.PleaseSelectIssueCategory}</span>)
    }
    const Buttons: JSX.Element[] = []
    if(Main.Instance.DashboardState.selectedIssueCategory) {
        const timelineEntrySubType: TimelineEntrySubType = TimelineEntrySubType.Issues + 1 + whichTimelineEntrySubType(Main.Instance.DashboardState.selectedIssueCategory)
        const resolved: number = GetResolved(timelineEntrySubType)
        const unresolved: number = GetUnresolved(timelineEntrySubType)

        Buttons.push(
            <button 
                key = {Assets.All} 
                value = {Assets.All} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.All ? 'btn btn-warning dbl fl btn-sm' : 'btn btn-default dbl fl btn-sm'}
                style = {{marginRight: '5px', marginBottom: '5px', position: 'relative'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, undefined, Assets.All, undefined, undefined, undefined, 1)
                    Logic.Type.New (Main.Instance.DashboardState, {selectedResolvedType: Assets.All})
                }}
            >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon style = {{fontSize: '16px', position: 'absolute', top: '6px', left: '8px'}} icon = {faTasks}/>&nbsp;&nbsp;
                {Assets.All} {Assets.Issues} <span className="badge badge-light" style = {resolved + unresolved > 0 ? {backgroundColor: '#d9534f', color: '#fff'}: {}}>{resolved + unresolved}</span>
            </button>
        )
        Buttons.push(
            <button 
                key = {Assets.Unresolved} 
                value = {Assets.Unresolved} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ? 'btn btn-danger dbl fl btn-sm' : 'btn btn-default dbl fl btn-sm'}
                style = {{marginRight: '5px', marginBottom: '5px', position: 'relative'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, undefined, Assets.Unresolved, undefined, undefined, undefined, 1)
                    Logic.Type.New (Main.Instance.DashboardState, {selectedResolvedType: Assets.Unresolved})
                }}
            >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon style = {{fontSize: '16px', position: 'absolute', top: '6px', left: '8px'}} icon = {faExclamationCircle}/>&nbsp;&nbsp;
                {Assets.Unresolved} {Assets.Issues} <span className="badge badge-light" style = {unresolved > 0 ? {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{unresolved}</span>
            </button>
        )

        Buttons.push(
            <button 
                key = {Assets.Resolved} 
                value = {Assets.Resolved} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved ? 'btn btn-success dbl fl btn-sm' : 'btn btn-default dbl fl btn-sm'}
                style = {{marginRight: '5px', marginBottom: '5px', position: 'relative'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, undefined, Assets.Resolved, undefined, undefined, undefined, 1)
                    Logic.Type.New (Main.Instance.DashboardState, {selectedResolvedType: Assets.Resolved})
                }}
            >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon style = {{fontSize: '16px', position: 'absolute', top: '6px', left: '8px'}} icon = {faCheckCircle}/>&nbsp;&nbsp;
                {Assets.Resolved} {Assets.Issues} <span className="badge badge-light">{resolved}</span>
            </button>
        )
    }

    return (
    <div className = "clearfix mb5">
        {buttons}
        <div className = "fr">{Buttons}</div>
    </div>
    )
}

// function getIssuesCount(issueCountByType: IIssueCount[], type: number): number {
//     for (const item of issueCountByType) {
        
//         if (item.issueType === type) {
//             return +item.count
//         }
//     }
//     return -1
// }

function getIssuesCategoryCount(issueCountByType: IIssueCount[], type: number): number {

    let count: number = 0
    for (const item of issueCountByType) {
        if (type === 9999) {
            if (+item.issueType >= 301 && +item.issueType <= 316) {
                count += (+item.count)
                
            }
        } else if (type === 410) {
            if (+item.issueType >= 411 && +item.issueType <= 419) {
                count += (+item.count)
                
            }
        } else if (type === 210) {
            if (+item.issueType >= 211 && +item.issueType <= 219) {
                count += (+item.count)
                
            }
        } else if (type === 250) {
            if (+item.issueType >= 251 && +item.issueType <= 259) {
                count += (+item.count)
                
            }
        } else if (type === 110) {
            if (+item.issueType >= 111 && +item.issueType <= 120) {
                count += (+item.count)
                
            }
        } else if (type === 340) {
            if (+item.issueType >= 341 && +item.issueType <= 345) {
                count += (+item.count)
                
            }
        } else if (type === 360) {
            if (+item.issueType >= 361 && +item.issueType <= 368) {
                count += (+item.count)
                
            }
        } else if (type === 369) {
            if (+item.issueType >= 370 && +item.issueType <= 379) {
                count += (+item.count)
                
            }
        } else if (type === 380) {
            if (+item.issueType >= 381 && +item.issueType <= 389) {
                count += (+item.count)
                
            }
        } else if (+item.issueType === +type) {
            count = (+item.count)
            break
        } else {
            count = -1
        }
    }
    return count

}

export function disabledIssueType(issueType: number): boolean {
    if (issueType === 380 || issueType === 369 || issueType === 360 || issueType === 340 || issueType === 250 || issueType === 210 || issueType === 110 || issueType === 410) {
        return true
    }
    return false
}

function isSubcategoryIssueType(issueType: number): boolean {
    if (issueType === 380 || issueType === 369 || issueType === 360 || issueType === 340 || issueType === 350 || issueType === 250 || issueType === 210 || issueType === 110 || issueType === 410 || issueType === 199 || issueType === 299 || issueType === 399 || issueType === 499 || issueType >= 201 && issueType <= 205 || issueType === 9999) {
        return true
    }
    return false
}



// function higlightIssueType(issueType: number, selectedIssueType: number): boolean {
//     if (
//         issueType === 380 && selectedIssueType >= 381 && selectedIssueType <= 389
//         || issueType === 369 && selectedIssueType >= 370 && selectedIssueType <= 379
//         || issueType === 360 && selectedIssueType >= 361 && selectedIssueType <= 368
//         || issueType === 340 && selectedIssueType >= 341 && selectedIssueType <= 349
//         || issueType === 250 && selectedIssueType >= 251 && selectedIssueType <= 259
//         || issueType === 210 && selectedIssueType >= 211 && selectedIssueType <= 219
//         || issueType === 110 && selectedIssueType >= 111 && selectedIssueType <= 120
//         || issueType === 410 && selectedIssueType >= 411 && selectedIssueType <= 419
//     ) {
//         return true
//     }

//     return false
// }

function whichSubCategoryButtonsToGet(issueType: number, selectedIssueType: number): boolean {

    if (
        issueType === 380 && selectedIssueType >= 381 && selectedIssueType <= 389
        || issueType === 369 && selectedIssueType >= 370 && selectedIssueType <= 379
        || issueType === 360 && selectedIssueType >= 361 && selectedIssueType <= 368
        || issueType === 340 && selectedIssueType >= 341 && selectedIssueType <= 349
        || issueType === 250 && selectedIssueType >= 251 && selectedIssueType <= 259
        || issueType === 210 && selectedIssueType >= 211 && selectedIssueType <= 219
        || issueType === 110 && selectedIssueType >= 111 && selectedIssueType <= 120
        || issueType === 410 && selectedIssueType >= 411 && selectedIssueType <= 419
        || issueType === 9999 && selectedIssueType >= 301 && selectedIssueType <= 316
    ) {

        return true
    }

    return false
}

function whichTimelineEntrySubType(Which: string): number {
    if (Which === 'Risk') {
        return 0
    } else if (Which === 'RG') {
        return 1
    } else if (Which === 'KYC') {
        return 2
    } else if (Which === 'GM') {
        return 3
    }
    return - 1
}

// function GetIssuesCount(issuesCount: IIssuesCount[], which: number): number {
//     // console.log (issuesCount, which)
//     return issuesCount[which].totalIssues
// }

function getAllIssuesSubCategory(): JSX.Element {
    let array: number[]
    let stringArray: string[]
    const options: JSX.Element[] = []
    if(Main.Instance.DashboardState.selectedIssueCategory) {
        const which = whichTimelineEntrySubType(Main.Instance.DashboardState.selectedIssueCategory)

        if (which > -1) {
            if (which === 0) {
                array = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
                array.splice(0,2)
                stringArray = Assets.PlayerCardButtonsRM.slice (1, Assets.PlayerCardButtonsRM.length)
            } else if (which === 1) {
                array = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
                array.splice(0,3)
                stringArray = Assets.PlayerCardButtonsRG.slice (2, Assets.PlayerCardButtonsRG.length)
            } else if (which === 2) {
                array = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
                array.splice(0,3)
                stringArray = Assets.PlayerCardButtonsKYC.slice (2, Assets.PlayerCardButtonsKYC.length)
                array.unshift(9999)
                stringArray.unshift('Player Identification')
            } else if (which === 3) {
                array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
                array.splice(0,2)
                stringArray = Assets.PlayerCardButtonsGM.slice (1, Assets.PlayerCardButtonsGM.length)
            }


            for (let i: number = 0; i < array.length; i++) {
                const issuesCount: IIssuesCount[] =
                    Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom !== -1 ||
                    Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo !== -1 ?
                    Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                        Main.Instance.DatabaseDataState.issuesCountCustom :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                        Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom :
                    Main.Instance.DatabaseDataState.resolvedIssuesCountCustom
                    :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                        Main.Instance.DatabaseDataState.issuesCount :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                        Main.Instance.DatabaseDataState.unresolvedIssuesCount :
                    Main.Instance.DatabaseDataState.resolvedIssuesCount

                const count: number = getIssuesCategoryCount(issuesCount[which].issuesCounts, array[i])
                if (count <= 0) {continue}
                if (isSubcategoryIssueType(array[i]) === true) {
                    options.push(<button 
                        key = {array[i]} 
                        value = {array[i]}
                        className = {Main.Instance.DashboardState.selectedIssueType === array[i] || Main.Instance.DashboardState.selectedIssueSubCategory === array[i] ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default fl dbl btn-xs'}
                        style = {{marginRight: '5px', marginBottom: '5px'}}
                        type = "button"
                        onClick = {(e) => {
                            
                            if (array[i] === 9999 || disabledIssueType(array[i])) {

                                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategory: array[i], selectedIssueType: 777})
                                handleClick(undefined, 777, undefined, undefined, undefined, undefined, 1)
                                
                            } else {
                                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueType: array[i], selectedIssueSubCategory: -1})
                                handleClick(undefined,+e.currentTarget.value, undefined, undefined, undefined, undefined, 1)
                                
                            }
                            
                        }}>
                        {stringArray[i]}
                        {
                            count === -1 || count === 0 ? null : <>&nbsp;&nbsp;<span className="badge badge-light" style = {count > 0 ? count >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {backgroundColor: '#000', color: '#fff'}}>{count}</span></>
                        }
                        </button>
                    )
                }
            }
        }
    }
    return (
        <div className = "clearfix mt5 mb5">
            {options}
        </div>
    )
}

function getSubCategoryButtons(): JSX.Element {
    const options: JSX.Element[] = []
    let array: number[]
    let stringArray: string[]
    if(Main.Instance.DashboardState.selectedIssueSubCategory !== -1) {
        
        const which = whichTimelineEntrySubType(Main.Instance.DashboardState.selectedIssueCategory)

        if (which > -1) {
            if (which === 0) {
                array = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
                stringArray = Assets.PlayerCardButtonsRM
            } else if (which === 1) {
                array = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
                stringArray = Assets.PlayerCardButtonsRG.slice (1, Assets.PlayerCardButtonsRG.length)
            } else if (which === 2) {
                array = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
                stringArray = Assets.PlayerCardButtonsKYC.slice (1, Assets.PlayerCardButtonsKYC.length)
            } else if (which === 3) {
                array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
                stringArray = Assets.PlayerCardButtonsGM
            }
            array.splice(0, which === 0 || which === 3 ? 1 : 2)

            for (let i: number = 0; i < array.length; i++) {

                const issuesCount: IIssuesCount[] =
                    Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom !== -1 ||
                    Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo !== -1 ?
                    Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                        Main.Instance.DatabaseDataState.issuesCountCustom :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                        Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom :
                    Main.Instance.DatabaseDataState.resolvedIssuesCountCustom
                    :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
                        Main.Instance.DatabaseDataState.issuesCount :
                    Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
                        Main.Instance.DatabaseDataState.unresolvedIssuesCount :
                    Main.Instance.DatabaseDataState.resolvedIssuesCount

                const count: number =  getIssuesCategoryCount(issuesCount[which].issuesCounts, array[i])

                if (count <= 0) {continue}

                if (whichSubCategoryButtonsToGet(Main.Instance.DashboardState.selectedIssueSubCategory, array[i]) === true) {
                    options.push(<button 
                        key = {array[i]} 
                        value = {array[i]}
                        className = {Main.Instance.DashboardState.selectedIssueType === array[i] ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default fl dbl btn-xs'}
                        style = {{marginRight: '5px', marginBottom: '5px'}}
                        type = "button"
                        onClick = {(e) => {
 
                            handleClick(undefined, +e.currentTarget.value, undefined, undefined, undefined, undefined, 1)
                            Logic.Type.New (Main.Instance.DashboardState, {selectedIssueType: array[i]})
                        }}>
                        {stringArray[i]}
                        {
                            // i === 0 ? 
                            // void 0
                            // <>&nbsp;&nbsp;<span className="badge badge-light" style = {Main.Instance.DatabaseDataState.issuesCount[which].totalIssues > 0 ? Main.Instance.DatabaseDataState.issuesCount[which].totalIssues >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {backgroundColor: '#000', color: '#fff'}}>{Main.Instance.DatabaseDataState.issuesCount[which].totalIssues}</span></>
                            // : 
                            count === -1 || count === 0 ? null : <>&nbsp;&nbsp;<span className="badge badge-light" style = {count > 0 ? count >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {backgroundColor: '#000', color: '#fff'}}>{count}</span></>
                        }
                        </button>
                    )
                }
            }
        }
    }
    return (
        <div className = "clearfix mt5 mb5">
            {options}
        </div>
    )
}

// function getAllIssuesFilter(): JSX.Element {
//     // console.log(Main.Instance.DatabaseDataState.issuesAllIssuesFilter)
//     let array: number[]
//     let stringArray: string[]
//     const options: JSX.Element[] = []
//     if(Main.Instance.DashboardState.selectedIssueCategory) {
//         const which = whichTimelineEntrySubType(Main.Instance.DashboardState.selectedIssueCategory)

//         if (which > -1) {
//             if (which === 0) {
//                 array = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
//                 stringArray = Assets.PlayerCardButtonsRM
//             } else if (which === 1) {
//                 array = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
//                 stringArray = Assets.PlayerCardButtonsRG.slice (1, Assets.PlayerCardButtonsRG.length)
//             } else if (which === 2) {
//                 array = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
//                 stringArray = Assets.PlayerCardButtonsKYC.slice (1, Assets.PlayerCardButtonsKYC.length)
//             } else if (which === 3) {
//                 array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
//                 stringArray = Assets.PlayerCardButtonsGM
//             }
//             array.splice(0, which === 0 || which === 3 ? 1 : 2)
//             // array[0] = 999
//             // console.log(array)
//             for (let i: number = 0; i < array.length; i++) {
//                 // let count: number
//                 // let disabled: boolean
//                 // if (i > 0) {

//                 // here it needs to be added that when date is filtered it looks into custom
//                 // 
//                 const issuesCount: IIssuesCount[] =
//                     Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom !== -1 ||
//                     Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo !== -1 ?
//                     Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
//                         Main.Instance.DatabaseDataState.issuesCountCustom :
//                     Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
//                         Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom :
//                     Main.Instance.DatabaseDataState.resolvedIssuesCountCustom
//                     :
//                     Main.Instance.DashboardState.selectedResolvedType === Assets.All ?
//                         Main.Instance.DatabaseDataState.issuesCount :
//                     Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ?
//                         Main.Instance.DatabaseDataState.unresolvedIssuesCount :
//                     Main.Instance.DatabaseDataState.resolvedIssuesCount

//                 let count: number = i === 0 ? GetIssuesCount(issuesCount, which) : getIssuesCount(issuesCount[which].issuesCounts, array[i])
//                 // console.log (array[i], count)
//                 const disabled = disabledIssueType(array[i])
//                 if (disabled) {count = 0}
//                 if (count === -1 || !disabled && (count <= 0)) {continue}
//                 // }
//                 options.push(<button 
//                     key = {array[i]} 
//                     value = {array[i]}
//                     disabled = {disabled}
//                     className = {Main.Instance.DashboardState.selectedIssueType === array[i] ? 'btn btn-primary dbl fl btn-xs' : higlightIssueType(array[i], Main.Instance.DashboardState.selectedIssueType) ? 'btn btn-primary fl dbl btn-xs' : 'btn btn-default fl dbl btn-xs'}
//                     style = {{marginRight: '5px', marginBottom: '5px'}}
//                     type = "button"
//                     onClick = {(e) => {
// /*
//                         if (i === 0) {
//                             handleClick(Main.Instance.DashboardState.selectedIssueCategory, 999, undefined, undefined, undefined, undefined)
//                             Logic.Type.New (Main.Instance.DashboardState, {selectedIssueType: array[i]})
//                         } else {
//                             handleClick(undefined, +e.currentTarget.value, undefined, undefined, undefined, undefined)
//                             Logic.Type.New (Main.Instance.DashboardState, {selectedIssueType: array[i]})
//                         }
// */
//                         handleClick(i === 0 ? Main.Instance.DashboardState.selectedIssueCategory : undefined, i === 0 ? 999 : +e.currentTarget.value, undefined, undefined, undefined, undefined, 1)
//                         Logic.Type.New (Main.Instance.DashboardState, {selectedIssueType: array[i]})
//                     }}>
//                     {stringArray[i]}
//                     {
//                         // i === 0 ? 
//                         // void 0
//                         // <>&nbsp;&nbsp;<span className="badge badge-light" style = {Main.Instance.DatabaseDataState.issuesCount[which].totalIssues > 0 ? Main.Instance.DatabaseDataState.issuesCount[which].totalIssues >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {backgroundColor: '#000', color: '#fff'}}>{Main.Instance.DatabaseDataState.issuesCount[which].totalIssues}</span></>
//                         // : 
//                         count === -1 || count === 0 ? null : <>&nbsp;&nbsp;<span className="badge badge-light" style = {count > 0 ? count >= 10? {backgroundColor: '#d9534f', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {backgroundColor: '#000', color: '#fff'}}>{count}</span></>
//                     }
//                     </button>
//                 )
//             }
//         }
//     }

//     return (
//         <div className = "clearfix mt5 mb5">
//             {options}
//         </div>
//     )
// }
/*
function getAllIssuesResolvedFilter(): JSX.Element {
    const buttons: JSX.Element[] = []
    if(Main.Instance.DashboardState.selectedIssueCategory) {
        const timelineEntrySubType: TimelineEntrySubType = TimelineEntrySubType.Issues + 1 + whichTimelineEntrySubType(Main.Instance.DashboardState.selectedIssueCategory)
        const resolved: number = GetResolved(timelineEntrySubType)
        const unresolved: number = GetUnresolved(timelineEntrySubType)

        buttons.push(
            <button 
                key = {Assets.All} 
                value = {Assets.All} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.All ? 'btn btn-warning dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
                style = {{marginRight: '5px', marginBottom: '5px'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, 999, Assets.All, undefined, undefined, undefined)
                    Logic.Type.New (Main.Instance.DashboardState, {
                        selectedResolvedType: Assets.All,
                        selectedIssueType: 999,
                        // selectedIssueTypeAllIssues: true
                    })
                }}
            >
                {Assets.All} {Assets.Issues} <span className="badge badge-light" style = {resolved + unresolved > 0 ? {backgroundColor: '#d9534f', color: '#fff'}: {}}>{resolved + unresolved}</span>
            </button>
        )
        buttons.push(
            <button 
                key = {Assets.Unresolved} 
                value = {Assets.Unresolved} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.Unresolved ? 'btn btn-danger dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
                style = {{marginRight: '5px', marginBottom: '5px'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, 999, Assets.Unresolved, undefined, undefined, undefined)
                    Logic.Type.New (Main.Instance.DashboardState, {
                        selectedResolvedType: Assets.Unresolved,
                        selectedIssueType: 999,
                        // selectedIssueTypeAllIssues: true
                    })
                }}
            >
                {Assets.Unresolved} {Assets.Issues} <span className="badge badge-light" style = {unresolved > 0 ? {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{unresolved}</span>
            </button>
        )

        buttons.push(
            <button 
                key = {Assets.Resolved} 
                value = {Assets.Resolved} 
                className = {Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved ? 'btn btn-success dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
                style = {{marginRight: '5px', marginBottom: '5px'}}
                type = "button"
                
                onClick = {() => {
                    handleClick(Main.Instance.DashboardState.selectedIssueCategory, 999, Assets.Resolved, undefined, undefined, undefined)
                    Logic.Type.New (Main.Instance.DashboardState, {
                        selectedResolvedType: Assets.Resolved,
                        selectedIssueType: 999,
                        // selectedIssueTypeAllIssues: true
                    })
                }}
            >
                {Assets.Resolved} {Assets.Issues} <span className="badge badge-light" style = {resolved > 0 ? {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{resolved}</span>
            </button>
        )
    }

    return (
        <div className = "clearfix mt5 mb5">
            {buttons}
        </div>
    )
}
*/
function getAllIssuesDateFilter(): JSX.Element {
    const buttons: JSX.Element[] = []
    if (Main.Instance.DashboardState.selectedIssueCategory) {
        buttons.push(
            <button
                key = "datetoday"
                onClick = {() => {
                    if (!isTodayAllIssues()) {
                        handleClick(undefined, undefined, undefined, Assets.Today, undefined, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            allIssuesOptionsDatesFrom: Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1,
                            allIssuesOptionsDatesTo: Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 1,
                            IssuesCount: 1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            monthFromPart: -1,
                            monthToPart: -1,
                            yearFrom: -1,
                            yearTo: -1,
                            selectedDay: Assets.Today
                        })
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1})
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    } else {
                        handleClick(undefined, undefined, undefined, Assets.All, undefined, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            allIssuesOptionsDatesFrom: -1,
                            allIssuesOptionsDatesTo: -1,
                            IssuesCount: 1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                            monthFromPart: -1,
                            monthToPart: -1,
                            selectedDay: Assets.All
                        })
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    }
                }}
                className = {isTodayAllIssues() /*|| Main.Instance.DashboardState.selectedDay === Assets.Today*/ ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.Today}
            </button>
        )
        
        buttons.push(
            <button
                key = "dateyesterday"
                onClick = {() => {
                    if (!isYesterdayAllIssues()) {
                      
                        handleClick(undefined, undefined, undefined, Assets.Yesterday, undefined, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            allIssuesOptionsDatesFrom: Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2,
                            allIssuesOptionsDatesTo: Main.Instance.DatabaseDataState.allIssuesOptionsDates.length - 2,
                            IssuesCount: 1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                            monthFromPart: -1,
                            monthToPart: -1,
                            selectedDay: Assets.Yesterday
                        })
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1})
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    } else {
                       
                        handleClick(undefined, undefined, undefined, Assets.All, undefined, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            allIssuesOptionsDatesFrom: -1,
                            allIssuesOptionsDatesTo: -1,
                            IssuesCount: 1
                        })
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                            monthFromPart: -1,
                            monthToPart: -1,
                            selectedDay: Assets.All
                        })
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    }
                }}
                className = {isYesterdayAllIssues() ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.Yesterday}
            </button>
        )

        buttons.push(
            <button
                key = "dateany"
                onClick = {() => {
                    if (!(!isTodayAllIssues() && !isYesterdayAllIssues())) {
                        handleClick(undefined, undefined, undefined, Assets.All, undefined, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            allIssuesOptionsDatesFrom: -1,
                            allIssuesOptionsDatesTo: -1,
                            IssuesCount: 1
                        }) 
                        
                        Logic.Type.New (Main.Instance.DashboardState, {
                            monthFrom: -1,
                            monthTo: -1,
                            yearFrom: -1,
                            yearTo: -1,
                            monthFromPart: -1,
                            monthToPart: -1,
                            selectedDay: Assets.All
                        })
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1})
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                        
                    } 
                }}
                className = {!isTodayAllIssues() && !isYesterdayAllIssues() ? 'btn btn-primary dbl fl btn-xs' : 'btn btn-default dbl fl btn-xs'}
            >
                {Assets.Set + ' ' + Assets.AnyDate}
            </button>
        )

        buttons.push(
            <button
                key = "clearfilters"
                onClick = {() => {
                    handleClick(undefined, undefined, undefined, Assets.All, undefined, undefined, 1)
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        allIssuesOptionsDatesFrom: -1,
                        allIssuesOptionsDatesTo: -1,
                        IssuesCount: 1
                    }) 
                    
                    Logic.Type.New (Main.Instance.DashboardState, {
                        monthFrom: -1,
                        monthTo: -1,
                        yearFrom: -1,
                        yearTo: -1,
                        monthFromPart: -1,
                        monthToPart: -1,
                        selectedDay: Assets.All
                    })
                    Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                }}
                className = "btn btn-danger dbl fl btn-xs mr0"
            >
                {Assets.Clear + ' ' + Assets.Date.toLowerCase() + ' ' + Assets.Filters.toLowerCase()}
            </button>
        )
        const Buttons: JSX.Element[] = []
        Buttons.push (<FontAwesomeIcon style = {{fontSize: '21px'}} key = "dateCalendar" className = "dbl fl" icon = {faCalendarDay}/>)
        
        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Year}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.yearFrom}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {yearFrom: +e.target.value})
                } 
            >
                {getYearOptionsAllIssues()}
            </select>
        )
        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Month}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.monthFrom}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {monthFrom: +e.target.value})
                } 
            >
                {getMonthOptionsAllIssues(true)}
            </select>
        )

        if (Main.Instance.DashboardState.monthFrom > -1) {
            Buttons.push(
                <select
                    style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                    className = "fl dbl form-control"
                    value = {Main.Instance.DashboardState.monthFromPart}
                    onChange = {(e) => 
                        Logic.Type.New (Main.Instance.DashboardState, {monthFromPart: +e.target.value})
                    }
                >
                    {getMonthPartOptionsAllIssues(true)}
                </select>
            )
        }
            

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Date}
                className = "fl dbl form-control"
                value = {Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom}
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo === -1 || Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo >= +e.target.value) {
                      
                        handleClick(undefined, undefined, undefined, undefined, +e.target.value, undefined, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1, allIssuesOptionsDatesFrom: +e.target.value})
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1})
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    }
                }}
            >
                {getDateOptionsAllIssues(true)}
            </select>
        ) 

        Buttons.push(<div key = "datehyphen" className = "fl" style = {{marginLeft: '5px'}}>-</div>)

        Buttons.push(
                
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Year}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.yearTo}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {yearTo: +e.target.value})
                }
            >
                {getYearOptionsAllIssues()}
            </select>
        )

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Month}
                className = "fl dbl form-control"
                value = {Main.Instance.DashboardState.monthTo}
                onChange = {(e) => 
                    Logic.Type.New (Main.Instance.DashboardState, {monthTo: +e.target.value})
                }
            >
                {getMonthOptionsAllIssues(false)}
            </select>
        )

        if (Main.Instance.DashboardState.monthTo > -1) {
            Buttons.push(
                <select
                    style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Part + Assets._of_ + Assets.Month}
                    className = "fl dbl form-control"
                    value = {Main.Instance.DashboardState.monthToPart}
                    onChange = {(e) =>
                        Logic.Type.New (Main.Instance.DashboardState, {monthToPart: +e.target.value})
                    }
                >
                    {getMonthPartOptionsAllIssues(false)}
                </select>
            )
        }
            

        Buttons.push(
            <select
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                title = {Assets.Select + ' ' + Assets.Date}
                className = "fl dbl form-control"
                value = {Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo}
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom === -1 || Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom <= +e.target.value) {
                        handleClick(undefined, undefined, undefined, undefined, undefined, +e.target.value, 1)
                        Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1, allIssuesOptionsDatesTo: +e.target.value})
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {IssuesCount: 1})
                        Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                    }
                }}
            >
                {getDateOptionsAllIssues(false)}
            </select>
        )
        buttons.push(<div className = "fr clearfix">{Buttons}</div>)
    }

    return (
        <div className = "clearfix mt5 mb5">
            {buttons}
        </div>
    )
}

function RecordsPerPageChanged0(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues0Limit:parseInt(text)})
        FirstPage0()
    }
}

function FirstPage0(skipSetState?: boolean) {

    DbRoutes.getPaginatedIssues(
        {
            resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
            minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                          IssueTypes.DashboardBackend :
                          Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
            maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                          IssueTypes.Other2 - 1 :
                          Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
            dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
            dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
        },
        1,
        Main.Instance.DatabaseDataState.issues0Limit,
        TimelineEntrySubType.DashboardBackend,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
    )
    if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issues0Page: 1})}
}

function PrevPage0() {
    if (Main.Instance.DatabaseDataState.issues0Page > 1) {
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.DashboardBackend :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.Other2 - 1 :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
                dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
            },
            Main.Instance.DatabaseDataState.issues0Page - 1,
            Main.Instance.DatabaseDataState.issues0Limit,
            TimelineEntrySubType.DashboardBackend,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
        )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues0Page: Main.Instance.DatabaseDataState.issues0Page - 1})

    } else {
        LastPage0()
    }
}

function NextPage0() {
    if ((Main.Instance.DatabaseDataState.issues0Page + 0) * Main.Instance.DatabaseDataState.issues0Limit < getCount0()) {
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.DashboardBackend :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.Other2 - 1 :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
                dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
            },
            Main.Instance.DatabaseDataState.issues0Page + 1,
            Main.Instance.DatabaseDataState.issues0Limit,
            TimelineEntrySubType.DashboardBackend,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
        )
        Logic.Type.New(Main.Instance.DatabaseDataState,{issues0Page: Main.Instance.DatabaseDataState.issues0Page + 1})
        
    } else {
        FirstPage0()
    }
}

function LastPage0() {
    const count: number = getCount0()
    if (count <= Main.Instance.DatabaseDataState.issues0Limit) {
        FirstPage0()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {

            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issues0Limit) + 1
        }
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.DashboardBackend :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB === 999 ?
                              IssueTypes.Other2 - 1 :
                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as any,
                dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
                dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
            },
            npNum,
            Main.Instance.DatabaseDataState.issues0Limit,
            TimelineEntrySubType.DashboardBackend,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
        )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues0Page: npNum})
    }
}

function RecordsPerPageChanged4(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues4Limit:parseInt(text)})
        FirstPage4()
    }
}

function FirstPage4(skipSetState?: boolean, issueType?) {
    const IssueType = issueType ? issueType : Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
    getUnresolvedPaginatedIssues(IssueType, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, 1)
    // DbRoutes.getPaginatedIssues(
    //     {
    //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
    //         minIssueType: IssueType === 999 ?
    //                       IssueTypes.Gamification :
    //                       IssueType as any,
    //         maxIssueType: IssueType === 999 ?
    //                       IssueTypes.Other4 :
    //                       IssueType as any
    //     },
    //     1,
    //     Main.Instance.DatabaseDataState.issues4Limit,
    //     TimelineEntrySubType.Gamification,
    //     IssueType
    // )
    if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issues4Page: 1})}
}

function PrevPage4() {
    if (Main.Instance.DatabaseDataState.issues4Page > 1) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, Main.Instance.DatabaseDataState.issues4Page - 1)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Gamification :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Other4 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
        //     },
        //     Main.Instance.DatabaseDataState.issues4Page - 1,
        //     Main.Instance.DatabaseDataState.issues4Limit,
        //     TimelineEntrySubType.Gamification,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
        // )

        Logic.Type.New(Main.Instance.DatabaseDataState, {issues4Page: Main.Instance.DatabaseDataState.issues4Page - 1})

    } else {
        LastPage4()
    }
}

function NextPage4() {
    if ((Main.Instance.DatabaseDataState.issues4Page + 0) * Main.Instance.DatabaseDataState.issues4Limit < getCount4()) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, Main.Instance.DatabaseDataState.issues4Page + 1)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Gamification :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Other4 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
        //     },
        //     Main.Instance.DatabaseDataState.issues4Page + 1,
        //     Main.Instance.DatabaseDataState.issues4Limit,
        //     TimelineEntrySubType.Gamification,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState,{issues4Page: Main.Instance.DatabaseDataState.issues4Page + 1})
        
    } else {
        FirstPage4()
    }
}

function LastPage4() {
    const count: number = getCount4()
    if (count <= Main.Instance.DatabaseDataState.issues4Limit) {
        FirstPage4()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {

            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issues4Limit) + 1
        }
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, npNum)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Gamification :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
        //                       IssueTypes.Other4 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
        //     },
        //     npNum,
        //     Main.Instance.DatabaseDataState.issues4Limit,
        //     TimelineEntrySubType.Gamification,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues4Page: npNum})
    }
}

function RecordsPerPageChanged3(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues3Limit:parseInt(text)})
        FirstPage3()
    }
}

function FirstPage3(skipSetState?: boolean, issueType?) {
    const IssueType = issueType ? issueType : Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
    getUnresolvedPaginatedIssues(IssueType, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, 1)
    // DbRoutes.getPaginatedIssues(
    //     {
    //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
    //         minIssueType: IssueType === 999 ?
    //                       IssueTypes.KnowYourCustomer :
    //                       IssueType as any,
    //         maxIssueType: IssueType === 999 ?
    //                       IssueTypes.Other3 :
    //                       IssueType as any
    //     },
    //     1,
    //     Main.Instance.DatabaseDataState.issues3Limit,
    //     TimelineEntrySubType.KnowYourCustomer,
    //     IssueType
    // )
    if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issues3Page: 1})}
}

function PrevPage3() {
    if (Main.Instance.DatabaseDataState.issues3Page > 1) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, Main.Instance.DatabaseDataState.issues3Page - 1)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.KnowYourCustomer :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.Other3 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
        //     },
        //     Main.Instance.DatabaseDataState.issues3Page - 1,
        //     Main.Instance.DatabaseDataState.issues3Limit,
        //     TimelineEntrySubType.KnowYourCustomer,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues3Page: Main.Instance.DatabaseDataState.issues3Page - 1})

    } else {
        LastPage3()
    }
}

function NextPage3() {
    if ((Main.Instance.DatabaseDataState.issues3Page + 0) * Main.Instance.DatabaseDataState.issues3Limit < getCount3()) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, Main.Instance.DatabaseDataState.issues3Page + 1)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.KnowYourCustomer :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.Other3 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
        //     },
        //     Main.Instance.DatabaseDataState.issues3Page + 1,
        //     Main.Instance.DatabaseDataState.issues3Limit,
        //     TimelineEntrySubType.KnowYourCustomer,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState,{issues3Page: Main.Instance.DatabaseDataState.issues3Page + 1})
        
    } else {
        FirstPage3()
    }
}

function LastPage3() {
    const count: number = getCount3()
    if (count <= Main.Instance.DatabaseDataState.issues3Limit) {
        FirstPage3()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {

            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issues3Limit) + 1
        }
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, npNum)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.KnowYourCustomer :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
        //                       IssueTypes.Other3 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
        //     },
        //     npNum,
        //     Main.Instance.DatabaseDataState.issues3Limit,
        //     TimelineEntrySubType.KnowYourCustomer,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues3Page: npNum})
    }
}

function RecordsPerPageChanged2(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues2Limit:parseInt(text)})
        FirstPage2()
    }
}

function FirstPage2(skipSetState?: boolean, issueType?) {
    const IssueType = issueType ? issueType : Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG    
    getUnresolvedPaginatedIssues(IssueType, TimelineEntrySubType.ResponsibleGaming, Main.Instance.DatabaseDataState.issues2Limit, 1)
    // DbRoutes.getPaginatedIssues(
    //     {
    //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
    //         minIssueType: IssueType === 999 ?
    //                       IssueTypes.ResponsibleGaming :
    //                       IssueType as any,
    //         maxIssueType: IssueType === 999 ?
    //                       IssueTypes.Other2 :
    //                       IssueType as any
    //     },
    //     1,
    //     Main.Instance.DatabaseDataState.issues2Limit,
    //     TimelineEntrySubType.ResponsibleGaming,
    //     IssueType
    // )
    if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issues2Page: 1})}
}

function PrevPage2() {
    if (Main.Instance.DatabaseDataState.issues2Page > 1) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG, TimelineEntrySubType.ResponsibleGaming, Main.Instance.DatabaseDataState.issues2Limit, Main.Instance.DatabaseDataState.issues2Page - 1)
        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.ResponsibleGaming :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.Other2 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
        //     },
        //     Main.Instance.DatabaseDataState.issues2Page - 1,
        //     Main.Instance.DatabaseDataState.issues2Limit,
        //     TimelineEntrySubType.ResponsibleGaming,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues2Page: Main.Instance.DatabaseDataState.issues2Page - 1})

    } else {
        LastPage2()
    }
}

function NextPage2() {
    if ((Main.Instance.DatabaseDataState.issues2Page + 0) * Main.Instance.DatabaseDataState.issues2Limit < getCount2()) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG, TimelineEntrySubType.ResponsibleGaming, Main.Instance.DatabaseDataState.issues2Limit, Main.Instance.DatabaseDataState.issues2Page + 1)
        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.ResponsibleGaming :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.Other2 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
        //     },
        //     Main.Instance.DatabaseDataState.issues2Page + 1,
        //     Main.Instance.DatabaseDataState.issues2Limit,
        //     TimelineEntrySubType.ResponsibleGaming,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState,{issues2Page: Main.Instance.DatabaseDataState.issues2Page + 1})
        
    } else {
        FirstPage2()
    }
}

function LastPage2() {
    const count: number = getCount2()
    if (count <= Main.Instance.DatabaseDataState.issues2Limit) {
        FirstPage2()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {
            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issues2Limit) + 1
        }
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG, TimelineEntrySubType.ResponsibleGaming, Main.Instance.DatabaseDataState.issues2Limit, npNum)

        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.ResponsibleGaming :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
        //                       IssueTypes.Other2 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
        //     },
        //     npNum,
        //     Main.Instance.DatabaseDataState.issues2Limit,
        //     TimelineEntrySubType.ResponsibleGaming,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues2Page: npNum})
    }
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issuesLimit: parseInt(text)})
        FirstPage()
    }
}

function FirstPage(skipSetState?: boolean) {
/*
    DbRoutes.getPaginatedIssues(
        {
            resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
            minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                            IssueTypes.ResponsibleGaming :
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
            maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                            IssueTypes.Other2 :
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
        },
        1,
        Main.Instance.DatabaseDataState.issuesLimit,
        TimelineEntrySubType.ResponsibleGaming,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
    )
*/
    handleClick(undefined,undefined,undefined,undefined,undefined,undefined, 1)
/*    
    DbRoutes.getAllIssues(
        {
            minIssueType: MinIssueType,
            maxIssueType: MaxIssueType,
            dateFrom: convertToUnixAllIssues(DateFrom, true),
            dateTo: convertToUnixAllIssues(DateTo, false),
            resolved: Resolved
        },
        1,
        Main.Instance.DatabaseDataState.issuesLimit
    )
*/
    // if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issuesPage: 1})}
}

function PrevPage() {
    if (Main.Instance.DatabaseDataState.issuesPage > 1) {
/*
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.ResponsibleGaming :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.Other2 :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
            },
            Main.Instance.DatabaseDataState.issuesPage - 1,
            Main.Instance.DatabaseDataState.issuesLimit,
            TimelineEntrySubType.ResponsibleGaming,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        )
*/
        handleClick(undefined,undefined,undefined,undefined,undefined,undefined, Main.Instance.DatabaseDataState.issuesPage - 1)
        // Logic.Type.New(Main.Instance.DatabaseDataState, {issuesPage: Main.Instance.DatabaseDataState.issuesPage - 1})

    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.DatabaseDataState.issuesPage + 0) * Main.Instance.DatabaseDataState.issuesLimit < GetCount()) {
/*
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.ResponsibleGaming :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.Other2 :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
            },
            Main.Instance.DatabaseDataState.issuesPage + 1,
            Main.Instance.DatabaseDataState.issuesLimit,
            TimelineEntrySubType.ResponsibleGaming,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        )
*/
        handleClick(undefined,undefined,undefined,undefined,undefined,undefined, Main.Instance.DatabaseDataState.issuesPage + 1)
/*
        DbRoutes.getAllIssues(
            {
                minIssueType: MinIssueType,
                maxIssueType: MaxIssueType,
                dateFrom: convertToUnixAllIssues(DateFrom, true),
                dateTo: convertToUnixAllIssues(DateTo, false),
                resolved: Resolved
            },
            Main.Instance.DatabaseDataState.issuesPage + 1,
            Main.Instance.DatabaseDataState.issuesLimit
        )
*/
        // Logic.Type.New(Main.Instance.DatabaseDataState, {issuesPage: Main.Instance.DatabaseDataState.issuesPage + 1})
        
    } else {
        FirstPage()
    }
}

function LastPage() {
    const count: number = GetCount()
    if (count <= Main.Instance.DatabaseDataState.issuesLimit) {
        FirstPage()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {

            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issuesLimit) + 1
        }
/*
        DbRoutes.getPaginatedIssues(
            {
                resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.ResponsibleGaming :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                IssueTypes.Other2 :
                                Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
            },
            npNum,
            Main.Instance.DatabaseDataState.issuesLimit,
            TimelineEntrySubType.ResponsibleGaming,
            Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        )
*/
        handleClick(undefined,undefined,undefined,undefined,undefined,undefined, npNum)
        // Logic.Type.New(Main.Instance.DatabaseDataState, {issuesPage: npNum})
    }
}

function RecordsPerPageChanged1(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues1Limit: parseInt(text)})
        FirstPage1()
    }
}

export function getUnresolvedPaginatedIssues(issueType, timelineEntrySubType, limit, page) {

    let MinIssueType
    let MaxIssueType
    if (Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === -1) {
        if (timelineEntrySubType === TimelineEntrySubType.RiskManagement) {
            MinIssueType = issueType === 999 ? IssueTypes.RiskManagement : issueType as any
            MaxIssueType = issueType === 999 ? IssueTypes.Other1 : issueType as any
        } else if (timelineEntrySubType === TimelineEntrySubType.ResponsibleGaming) {
            MinIssueType = issueType === 999 ? IssueTypes.ResponsibleGaming : issueType as any
            MaxIssueType = issueType === 999 ? IssueTypes.Other2 : issueType as any
        } else if (timelineEntrySubType === TimelineEntrySubType.KnowYourCustomer) {
            MinIssueType = issueType === 999 ? IssueTypes.KnowYourCustomer : issueType as any
            MaxIssueType = issueType === 999 ? IssueTypes.Other3 : issueType as any
        } else if (timelineEntrySubType === TimelineEntrySubType.Gamification) {
            MinIssueType = issueType === 999 ? IssueTypes.Gamification : issueType as any
            MaxIssueType = issueType === 999 ? IssueTypes.Other4 : issueType as any
        }
    } else if (issueType === 9999 || disabledIssueType(issueType)) {
        MinIssueType = getMinMaxIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved, 0)
        MaxIssueType = getMinMaxIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved, 1)
    } else {
        MinIssueType = issueType
        MaxIssueType = issueType
    }
    DbRoutes.getPaginatedIssues(
        {
            resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
            minIssueType: MinIssueType,
            maxIssueType: MaxIssueType
        },
        page,
        limit,
        timelineEntrySubType,
        issueType
    )
}

function FirstPage1(skipSetState?: boolean, issueType?) {
    
    const IssueType = issueType ? issueType : Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM

    getUnresolvedPaginatedIssues(IssueType, TimelineEntrySubType.RiskManagement, Main.Instance.DatabaseDataState.issues1Limit, 1)
    if (!skipSetState) {Logic.Type.New(Main.Instance.DatabaseDataState, {issues1Page: 1})}
}

function PrevPage1() {
    if (Main.Instance.DatabaseDataState.issues1Page > 1) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM, TimelineEntrySubType.RiskManagement, Main.Instance.DatabaseDataState.issues1Limit, Main.Instance.DatabaseDataState.issues1Page - 1)
        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.RiskManagement :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.Other1 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any
        //     },
        //     Main.Instance.DatabaseDataState.issues1Page - 1,
        //     Main.Instance.DatabaseDataState.issues1Limit,
        //     TimelineEntrySubType.RiskManagement,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues1Page: Main.Instance.DatabaseDataState.issues1Page - 1})

    } else {
        LastPage1()
    }
}

function NextPage1() {
    if ((Main.Instance.DatabaseDataState.issues1Page + 0) * Main.Instance.DatabaseDataState.issues1Limit < getCount1()) {
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM, TimelineEntrySubType.RiskManagement, Main.Instance.DatabaseDataState.issues1Limit, Main.Instance.DatabaseDataState.issues1Page + 1)
        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.RiskManagement :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.Other1 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any
        //     },
        //     Main.Instance.DatabaseDataState.issues1Page + 1,
        //     Main.Instance.DatabaseDataState.issues1Limit,
        //     TimelineEntrySubType.RiskManagement,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues1Page: Main.Instance.DatabaseDataState.issues1Page + 1})
    } else {
        FirstPage1()
    }
}

export function FourIssues(): boolean {
    return Main.Instance.Location.startsWith('/account-store') && Main.Instance.Location !== '/account-store/all-issues'
}

function LastPage1() {
    const count: number = getCount1()
    if (count <= Main.Instance.DatabaseDataState.issues1Limit) {
        FirstPage1()
    } else {
        let npNum: number = 1;
        if (count === 0) {
        } else {
            npNum = Math.trunc((count - 1) / 
                    Main.Instance.DatabaseDataState.issues1Limit) + 1
        }
        // console.log (Main.Instance.Location)
        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM, TimelineEntrySubType.RiskManagement, Main.Instance.DatabaseDataState.issues1Limit, npNum)
        // DbRoutes.getPaginatedIssues(
        //     {
        //         resolved: FourIssues() ? Main.Instance.RiskManagementState.filterResolved : false,
        //         minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.RiskManagement :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any,
        //         maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
        //                       IssueTypes.Other1 :
        //                       Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any
        //     },
        //     npNum,
        //     Main.Instance.DatabaseDataState.issues1Limit,
        //     TimelineEntrySubType.RiskManagement,
        //     Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
        // )
        Logic.Type.New(Main.Instance.DatabaseDataState, {issues1Page: npNum})
    }
}

function getCount1(): number {

    let issuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    // console.log (FourIssues())
    if (FourIssues()) {
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            // console.log ('a')
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            // console.log ('b')
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    } else {
        if (Main.Instance.DashboardState.selectedResolvedType === Assets.All) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    }

    return issuesCount ? getCount(
        issuesCount[0],
        TimelineEntrySubType.RiskManagement,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as number) : 0
}

function getCount2(): number {
    let issuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    if (FourIssues()) {
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    } else {
        if (Main.Instance.DashboardState.selectedResolvedType === Assets.All) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    }

    return issuesCount ? getCount(
        issuesCount[1],
        TimelineEntrySubType.ResponsibleGaming,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as number) : 0
}

function getCount3(): number {
    let issuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    if (FourIssues()) {
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    } else {
        if (Main.Instance.DashboardState.selectedResolvedType === Assets.All) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    }

    return issuesCount ? getCount(
        issuesCount[2],
        TimelineEntrySubType.KnowYourCustomer,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as number) : 0
}

function getCount4(): number {
    let issuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    if (FourIssues()) {
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    } else {
        if (Main.Instance.DashboardState.selectedResolvedType === Assets.All) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.DashboardState.selectedResolvedType === Assets.Resolved) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    }

    return issuesCount ? getCount(
        issuesCount[3],
        TimelineEntrySubType.Gamification,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as number) : 0
}

function getCount0(): number {
    let issuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    // if (FourIssues()) {
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom !== -1 || 
        Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo !== -1) {
/*
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCountCustom)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCountCustom)
        } else if (Main.Instance.RiskManagementState.filterResolved === false) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom)
        }
*/
        issuesCount = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom)
        // console.log(Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom)
        // console.log ('custom unresolved issues count')
    } /* else {
        console.log ('ordinary unresolved issues count')
    }*/
/*
    console.log (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom)
    console.log (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo)
    console.log (Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom)
    console.log (Main.Instance.DatabaseDataState.unresolvedIssuesCount)
*/
/*
    else {
        if (Main.Instance.RiskManagementState.filterResolved === undefined) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        } else if (Main.Instance.RiskManagementState.filterResolved === true) {
            issuesCount = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        }
    }
*/
/*
    console.log (Main.Instance.DatabaseDataState.issuesCount)
    console.log (Main.Instance.DatabaseDataState.unresolvedIssuesCount)
    console.log (Main.Instance.DatabaseDataState.resolvedIssuesCount)

    console.log ('from ', Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom])
    console.log ('to ', Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo])
    console.log (Main.Instance.DatabaseDataState.issuesCountCustom)
    console.log (Main.Instance.DatabaseDataState.unresolvedIssuesCountCustom)
    console.log (Main.Instance.DatabaseDataState.resolvedIssuesCountCustom)
*/
    // change based on today, yesterday
    // console.log ('isToday ', isToday())
    // console.log ('isYesterday ', isYesterday())
    // }
    // console.log (issuesCount)
    return issuesCount ? getCount(
        issuesCount[1],
        TimelineEntrySubType.DashboardBackend,
        Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB as number) : 0
}

function getCount(item: IIssuesCount, type: TimelineEntrySubType, issueType: number): number {
    // console.log(item)
    // here we return just the unresolved issues
    // if (item) {
    // for (const item of array) {
    if (issueType !== 9999 && !disabledIssueType(issueType)) { 
        if (item && (item.type === type || item.type === TimelineEntrySubType.ResponsibleGaming && type === TimelineEntrySubType.DashboardBackend)) {
            if (issueType === 999 && type === TimelineEntrySubType.DashboardBackend) {
                // console.log ('DashboardBackend')
                // console.log (item)
                // console.log (item.issuesCounts)
                let count: number = 0
                for (const Item of item.issuesCounts) {
                    if (Item.issueType > IssueTypes.DashboardBackend && Item.issueType < IssueTypes.Other2) {
                        count += Item.count
                    }
                }
                return count
                // return 0
            } else if (issueType === 999) {
                return item.totalIssues
            } else {
                for (const Item of item.issuesCounts) {
                    if ((Item.issueType as number) === issueType) {
                        return Item.count
                    }
                }
            }
        }
    } else {
        return getIssuesCategoryCount(item.issuesCounts, Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved)
    }
    // }
    // }
    return 0
}

export function getControlPanel0(): JSX.Element {
    const count: number = getCount0()
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issues0Limit ? '' : Main.Instance.DatabaseDataState.issues0Limit.toString()}  
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged0(text)}
            />
            <button 
                className = "btn btn-primary btn-xs mt7" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage0()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs mt7" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage0()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs mt7"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage0()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs mt7"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage0()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
    
            <span className = "label label-success table-label mt7">
                {Assets.records}
                {count}
            </span>
            <span className = "label label-success table-label mt7">
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issues0Page - 1) * 
                Main.Instance.DatabaseDataState.issues0Limit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issues0Page) * 
                Main.Instance.DatabaseDataState.issues0Limit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issues0Page) * Main.Instance.DatabaseDataState.issues0Limit) : 0}
            </span>
            <span className = "label label-success table-label mt7">
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issues0Page : 0}{' / '}
                {count ? Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issues0Limit + 1) : 0}
            </span>
        </div>
    )
}

export function getControlPanel4(Mt7: boolean): JSX.Element {
    const count: number = getCount4()
    const mt7: string = Mt7 ? ' mt7' : ' mt2'
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issues4Limit ? '' : Main.Instance.DatabaseDataState.issues4Limit.toString()}  
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged4(text)}
            />
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage4()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage4()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage4()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage4()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
    
            <span className = {'label label-success table-label' + mt7}>
                {Assets.records}
                {count}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issues4Page - 1) * 
                Main.Instance.DatabaseDataState.issues4Limit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issues4Page) * 
                Main.Instance.DatabaseDataState.issues4Limit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issues4Page) * Main.Instance.DatabaseDataState.issues4Limit) : 0}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issues4Page : 0}{' / '}
                {count ? Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issues4Limit + 1) : 0}
            </span>
        </div>
    )
}

export function getControlPanel3(Mt7: boolean): JSX.Element {
    const count: number = getCount3()
    const mt7: string = Mt7 ? ' mt7' : ' mt2'
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issues3Limit ? '' : Main.Instance.DatabaseDataState.issues3Limit.toString()}  
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged3(text)}
            />
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage3()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage3()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage3()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage3()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
    
            <span className = {'label label-success table-label' + mt7}>
                {Assets.records}
                {count}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issues3Page - 1) * 
                Main.Instance.DatabaseDataState.issues3Limit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issues3Page) * 
                Main.Instance.DatabaseDataState.issues3Limit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issues3Page) * Main.Instance.DatabaseDataState.issues3Limit) : 0}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issues3Page : 0}{' / '}
                {count ? 
                Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issues3Limit + 1) : 0}
            </span>
        </div>
    )
}

export function getControlPanel2(Mt7: boolean): JSX.Element {
    const count: number = getCount2()
    const mt7: string = Mt7 ? ' mt7' : ' mt2'
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issues2Limit ? '' : Main.Instance.DatabaseDataState.issues2Limit.toString()}  
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged2(text)}
            />
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage2()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage2()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage2()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage2()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
    
            <span className = {'label label-success table-label' + mt7}>
                {Assets.records}
                {count}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issues2Page - 1) * 
                Main.Instance.DatabaseDataState.issues2Limit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issues2Page) * 
                Main.Instance.DatabaseDataState.issues2Limit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issues2Page) * Main.Instance.DatabaseDataState.issues2Limit) : 0}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issues2Page : 0}{' / '}
                {count ? Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issues2Limit + 1) : 0}
            </span>
        </div>
    )
}

export function getControlPanel1(Mt7: boolean): JSX.Element {
    const count: number = getCount1()
    const mt7: string = Mt7 ? ' mt7' : ' mt2'
    return (
        <div className = "control-panel" style = {{height: '35px', marginBottom: '0px'}}>
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.issues1Limit ? '' : Main.Instance.DatabaseDataState.issues1Limit.toString()}  
                type = "number" 
                min = "1"
                max = "100"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged1(text)}
            />
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage1()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage1()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage1()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = {'btn btn-primary btn-xs' + mt7}
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage1()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = {'label label-success table-label' + mt7}>
                {Assets.records}
                {count}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.showing}
                {count ? (Main.Instance.DatabaseDataState.issues1Page - 1) * 
                Main.Instance.DatabaseDataState.issues1Limit + 1 : 0}{' - '}
                {count ? 
                ((Main.Instance.DatabaseDataState.issues1Page) * 
                Main.Instance.DatabaseDataState.issues1Limit > count ? 
                count : 
                (Main.Instance.DatabaseDataState.issues1Page) * Main.Instance.DatabaseDataState.issues1Limit) : 0}
            </span>
            <span className = {'label label-success table-label' + mt7}>
                {Assets.page}
                {count ? 
                Main.Instance.DatabaseDataState.issues1Page : 0}{' / '}
                {count ? Math.trunc((count - 1) /
                Main.Instance.DatabaseDataState.issues1Limit + 1) : 0}
            </span>
        </div>
    )
}

export function getIssuesFilter(which: number, issuesCount: IIssuesCount[]): JSX.Element {
    let array: number[]
    let stringArray: string[]
    // let start: number = 3
    let value: number
    if (which === 1) {
        array = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
        stringArray = Assets.PlayerCardButtonsRM.slice (1, Assets.PlayerCardButtonsRM.length)
        // start = 2
        array.splice(0,2)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
    } else if (which === 2 || which === 0) {
        array = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
        if (which === 2) {
            stringArray = Assets.PlayerCardButtonsRG.slice (2, Assets.PlayerCardButtonsRG.length)
            array.splice(0,3)
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        } else if (which === 0) {

            stringArray = Assets.PlayerCardButtonsRG.slice (1, Assets.PlayerCardButtonsRG.length)
            const stringArray_: string[] = []
            stringArray_.push(stringArray[0])
            stringArray_.push(...stringArray.slice(14,23))
            stringArray = stringArray_

            array.splice(0,2)
            const array_: number[] = []
            array_.push(array[0])
            array_.push(...array.slice(14,23))
            array = array_
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
            array[0] = 999
        }

        
    } else if (which === 3) {
        array = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
        stringArray = Assets.PlayerCardButtonsKYC.slice (2, Assets.PlayerCardButtonsKYC.length)
        array.splice(0,3)
        array.unshift(9999)
        stringArray.unshift('Player Identification')
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
    } else if (which === 4) {
        
/*
        array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
        stringArray = Assets.PlayerCardButtonsGM.slice (1, Assets.PlayerCardButtonsGM.length)
        array.splice(0,2)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
*/
        array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
        stringArray = Assets.PlayerCardButtonsGM.slice(1, Assets.PlayerCardButtonsGM.length)
        // start = 2
        array.splice(0,2)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM

    }
    // array[0] = 999
    // console.log (array)
    // console.log (stringArray)
    // console.log (issuesCount)
    const unresolved: number[] = []
    // const allIssues: number[] = []
    // console.log (stringArray)
    for (let i: number = 0; i < array.length; i++) {
        let Value: number = i === 0 ? 0 : -1
        if (issuesCount) {
            if (which === 0) {
                for (const item of issuesCount) {
                    if (i === 0) {
                        // console.log (which)
                        // console.log (item.type)
    //                     if (which === 1 && item.type === TimelineEntrySubType.RiskManagement
    //                         || which === 2 && item.type === TimelineEntrySubType.ResponsibleGaming
    //                         || which === 3 && item.type === TimelineEntrySubType.KnowYourCustomer
    //                         || which === 4 && item.type === TimelineEntrySubType.Gamification) {
    // /*
    //                         for (const Item of item.issuesCounts) {
    //                             Value += parseInt(Item.count) 
    //                         }
    // */
    //                         Value = item.totalIssues
    //                         // console.log(Value)
    //                     } else 
                        if (which === 0 && item.type === TimelineEntrySubType.ResponsibleGaming) {
                            // console.log ('getIssuesFilter0 all')
                            // console.log (item.issuesCounts)
                            // Value = 1
                            for (const Item of item.issuesCounts) {
                                if (Item.issueType > IssueTypes.DashboardBackend && Item.issueType < IssueTypes.Other2) {
                                    Value += Item.count
                                }
                            }
                            
                        } else {
                            continue
                        }
                    } else {
                        for (const Item of item.issuesCounts) {
                            if (Item.issueType === array[i]) {
                                Value = Item.count
                                break
                            }
                        }
                        
                    }
                    if (Value > -1/* && i > 0*/) {break}
                }

            } else {
                if (which === 1) {
                    Value = getIssuesCategoryCount(issuesCount[0].issuesCounts, array[i])
                } else if (which === 2) {
                    Value = getIssuesCategoryCount(issuesCount[1].issuesCounts, array[i])
                } else if (which === 3) {
                    Value = getIssuesCategoryCount(issuesCount[2].issuesCounts, array[i])
                } else if (which === 4) {
                    Value = getIssuesCategoryCount(issuesCount[3].issuesCounts, array[i])
                }
                
            }

            unresolved.push(Value === -1 ? 0 : Value)
            // allIssues.push(0)
        }
    }

    

/*
    if (which === 0) {

        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.issuesDashboardBackend ? Main.Instance.DatabaseDataState.issuesDashboardBackend.length : 0 ); i++) {
            unresolved[0]++
            for (let j: number = 1; j < array.length; j++) {
                if (Main.Instance.DatabaseDataState.issuesDashboardBackend[i].issueType === array[j]) {
                    unresolved[j]++
                    break
                }
            }
        }

    } else {

        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.issues ? Main.Instance.DatabaseDataState.issues.length : 0); i++) {
            // if (Main.Instance.DatabaseDataState.issues[i].entrySubType === TimelineEntrySubType.RiskManagement) {
            
            if (Main.Instance.DatabaseDataState.issues[i].entrySubType === TimelineEntrySubType.RiskManagement && which !== 1) {
                continue
            }
            if (Main.Instance.DatabaseDataState.issues[i].entrySubType === TimelineEntrySubType.ResponsibleGaming && which !== 2) {
                continue
            }
            if (Main.Instance.DatabaseDataState.issues[i].entrySubType === TimelineEntrySubType.KnowYourCustomer && which !== 3) {
                continue
            }

            // const item: IIssue = Main.Instance.DatabaseDataState.issues[i] as IIssue

            unresolved[0]++
            for (let j: number = 1; j < array.length; j++) {
                if (Main.Instance.DatabaseDataState.issues[i].issueType === array[j]) {
                    unresolved[j]++
                    break
                }
            }
        }

    }
*/
    // console.log (Main.Instance.DatabaseDataState.playerTimeline)

    const buttons: JSX.Element[] = []
    for (let i: number = 0; i < stringArray.length; i++) {
        // console.log (Assets.PlayerCardButtonsRM[i])
            
            if (which === 0 ? unresolved[i] > 0: isSubcategoryIssueType(array[i]) && unresolved[i] > 0) {
                // console.log(unresolved[i])
                buttons.push (
                    <button
                        // disabled = {unresolved[i] === 0}
                        style = {i === stringArray.length - 1 ? {marginRight: '0px', marginBottom: '5px'} : {marginBottom: '5px'}}
                        type = "button"
                        className = {array[i] === value || Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === array[i] ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs' }
                        onClick = {() => {
                            if (array[i] === 9999 || disabledIssueType(array[i])) {
                                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: array[i]})
 
                            } else {
                                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
                                
                            }
                            which === 1 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues1Page: 1, issuesFilterIssueTypeRM: array[i]}) :
                            which === 2 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues2Page: 1, issuesFilterIssueTypeRG: array[i]}) :
                            which === 3 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1, issuesFilterIssueTypeKYC: array[i]}) :
                            which === 4 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1, issuesFilterIssueTypeGM: array[i]}) :
                            Logic.Type.New (Main.Instance.DatabaseDataState, {issues0Page: 1, issuesFilterIssueTypeDB: array[i]})
                            
                            // now we need to load this new issues
                            which === 1 ? FirstPage1(true) :
                            which === 2 ? FirstPage2(true) :
                            which === 3 ? FirstPage3(true) :
                            which === 4 ? FirstPage4(true) :
                            FirstPage0(true)
                            
                        }}
                        key = {i.toString()}
                    >
                        {stringArray[i]}
                        
                        <span className = {unresolved[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span>
                    </button>,
                )
            }
        
    }
/*
    // we need to add also filters for date here getDashboardBackendIssues
    if (which === 0) {
        // add min date and time, max date and time, default should be today, first date is 22.07
        // dashboardBackendOptionsDates
        const Buttons: JSX.Element[] = []
        Buttons.push (<FontAwesomeIcon style = {{fontSize: '21px'}} key = "dateCalendar" className = "dbl fl" icon = {faCalendarDay}/>)
        Buttons.push (
            <select
                key = "dateFrom"
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '5px', paddingTop: '0px', paddingBottom: '0px'}}
                className = "fl dbl form-control"
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo >= +e.target.value) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(+e.target.value, true),
                            dateTo: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo, false),
                        })
                        Logic.Type.New (Main.Instance.DatabaseDataState, {dashboardBackendOptionsDatesFrom: +e.target.value})
                    }
                }}
                value = {Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom}
            >
                {getOptionsDates()}
            </select>
        )
        Buttons.push(<div key = "datehyphen" className = "fl">-</div>)
        Buttons.push(
            <select
                key = "dateTo"
                style = {{width: 'auto', height: '22px', marginLeft: '5px', marginRight: '0px', paddingTop: '0px', paddingBottom: '0px'}}
                className = "fl dbl form-control"
                onChange = {(e) => {
                    if (+e.target.value === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === -1 || Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom <= +e.target.value) {
                        DbRoutes.getDashboardBackendIssues({
                            resolved: false,
                            minIssueType: IssueTypes.DashboardBackend,
                            maxIssueType: IssueTypes.Other2 - 1,
                            dateFrom: convertToUnix(Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom, true),
                            dateTo: convertToUnix(+e.target.value, false),
                        })

                        Logic.Type.New (Main.Instance.DatabaseDataState, {dashboardBackendOptionsDatesTo: +e.target.value})
                    }
                }}
                value = {Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo}
            >
                {getOptionsDates()}
            </select>
        )
        buttons.push(<div className = "fr clearfix">{Buttons}</div>)
    }
*/
    return (
        <div className = "clearfix">
            {buttons}
        </div>
    )
}

export function getUnresolvedSubCategoryButtons(which: number, issuesCount: IIssuesCount[]) {
    let array: number[]
    let stringArray: string[]
    let value: number

    if (which === 1) {
        array = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
        stringArray = Assets.PlayerCardButtonsRM.slice (1, Assets.PlayerCardButtonsRM.length)
        array.splice(0,2)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
    } else if (which === 2) {
        array = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
        stringArray = Assets.PlayerCardButtonsRG.slice (2, Assets.PlayerCardButtonsRG.length)
        array.splice(0,3)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG 
    } else if (which === 3) {
        array = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
        stringArray = Assets.PlayerCardButtonsKYC.slice (2, Assets.PlayerCardButtonsKYC.length)
        array.splice(0,3)
        array.unshift(9999)
        stringArray.unshift('Player Identification')
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
    } else if (which === 4) {
        array = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
        stringArray = Assets.PlayerCardButtonsGM.slice(1, Assets.PlayerCardButtonsGM.length)
        array.splice(0,2)
        value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
    }

    const unresolved: number[] = []

    for (let i: number = 0; i < array.length; i++) {
        let Value: number = i === 0 ? 0 : -1
        if (issuesCount) {
            
            if (which === 1) {
                Value = getIssuesCategoryCount(issuesCount[0].issuesCounts, array[i])
            } else if (which === 2) {
                Value = getIssuesCategoryCount(issuesCount[1].issuesCounts, array[i])
            } else if (which === 3) {
                Value = getIssuesCategoryCount(issuesCount[2].issuesCounts, array[i])
            } else if (which === 4) {
                Value = getIssuesCategoryCount(issuesCount[3].issuesCounts, array[i])
            }
            unresolved.push(Value === -1 ? 0 : Value)

        }
    }

    
    const buttons: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        
            if (whichSubCategoryButtonsToGet(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved, array[i]) === true && unresolved[i] > 0) { 
                buttons.push (
                    <button
                        // disabled = {unresolved[i] === 0}
                        style = {i === stringArray.length - 1 ? {marginRight: '0px', marginBottom: '5px'} : {marginBottom: '5px'}}
                        type = "button"
                        className = {array[i] === value ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs' }
                        onClick = {() => {
                            
                            which === 1 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues1Page: 1, issuesFilterIssueTypeRM: array[i]}) :
                            which === 2 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues2Page: 1, issuesFilterIssueTypeRG: array[i]}) :
                            which === 3 ? Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1, issuesFilterIssueTypeKYC: array[i]}) :
                            Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1, issuesFilterIssueTypeGM: array[i]})

                            which === 1 ? FirstPage1(true) :
                            which === 2 ? FirstPage2(true) :
                            which === 3 ? FirstPage3(true) :
                            FirstPage4(true)
                            
                        }}
                        key = {i.toString()}
                    >
                        {stringArray[i]}
                        
                        <span className = {unresolved[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span>
                    </button>,
                )
            }
        
    }

    return (
        <div className = "clearfix">
            {buttons}
        </div>
    )
}

function isToday(): boolean {
    
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === -1) {return false}
    const now: Date = new Date()
    now.setHours(12)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)

    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom !== -1 &&
        (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom ===
        Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo || 
        Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo === -1) && 
        now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom]
    ) {
        return true
    }
    return false
}

function isYesterday(): boolean {
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === -1 ||
        Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo === -1) {
        return false
    }
    const now: Date = new Date()
    now.setHours(12)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    now.setDate(now.getDate() - 1)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo &&
        now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom]
    ) {
        return true
    }
    return false
}

function isAnyDate(): boolean {
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesFrom === -1 ||
        Main.Instance.DatabaseDataState.dashboardBackendOptionsDatesTo === -1) {
        return true
    }
    return false
}

function convertToUnix(which: number, from: boolean): number {
    if (which === -1) {return undefined}
    const time: number = Math.floor((new Date(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[which])).getTime()/1000)
    return from ? time : time + 24 * 60 * 60 - 1
}

function isTodayAllIssues(): boolean {
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom === -1) {return false}
    const now: Date = new Date()
    now.setHours(12)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    const Now = now.toISOString().substring(0, 10)
    // console.log ('comparision')
    // console.log (Now)
    // if (Main.Instance.DatabaseDataState.allIssuesOptionsDates) {
    //     console.log (Main.Instance.DatabaseDataState.allIssuesOptionsDates[Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom])
    // }
    // console.log (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom)
    // console.log (Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo)

    if (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom !== -1 &&
        (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom ===
        Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo || 
        Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo === -1) && Main.Instance.DatabaseDataState.allIssuesOptionsDates &&
        Now === Main.Instance.DatabaseDataState.allIssuesOptionsDates[Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom]
    ) {
        return true
    }
    return false
}

function isYesterdayAllIssues(): boolean {
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom === -1 ||
        Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo === -1) {
        return false
    }
    const now: Date = new Date()
    now.setHours(12)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    now.setDate(now.getDate() - 1)
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDates && Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom === Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo &&
        now.toISOString().substring(0, 10) === Main.Instance.DatabaseDataState.allIssuesOptionsDates[Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom]
    ) {
        return true
    }
    return false
}
/*
function isAnyDateAllIssues(): boolean {
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDatesFrom === -1 ||
        Main.Instance.DatabaseDataState.allIssuesOptionsDatesTo === -1) {
        return true
    }
    return false
}
*/
function convertToUnixAllIssues(which: number, from: boolean): number {
    if (which === -1) {return undefined}
    const time: number = Math.floor((new Date(Main.Instance.DatabaseDataState.allIssuesOptionsDates[which])).getTime()/1000)
    return from ? time : time + 24 * 60 * 60 - 1
}

function getYearOptions(): JSX.Element[] {
    const yearOptions: JSX.Element[] = []
    const YearOptions: number[] = []
    yearOptions.push (<option key = "-1" value = {-1}>{Assets.Year}</option>)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].substring(0,4))
            let found: boolean = false
            for (const item of YearOptions) {
                if (item === Year) {
                    found = true
                    break
                }
            }
            if (!found) {
                YearOptions.push (Year)
                yearOptions.push (<option key = {i.toString()} value = {Year}>{Year}</option>)
            }
        }
    }

    if (Main.Instance.DashboardState.lastYear === undefined && YearOptions.length > 0) {
        // console.log (YearOptions[YearOptions.length - 1])
        Logic.Type.New (Main.Instance.DashboardState, {lastYear: YearOptions[YearOptions.length - 1]})
    }

    return yearOptions
}

function getYearOptionsAllIssues(): JSX.Element[] {
    const yearOptions: JSX.Element[] = []
    const YearOptions: number[] = []
    yearOptions.push (<option key = "-1" value = {-1}>{Assets.Year}</option>)
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.allIssuesOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].substring(0,4))
            let found: boolean = false
            for (const item of YearOptions) {
                if (item === Year) {
                    found = true
                    break
                }
            }
            if (!found) {
                YearOptions.push (Year)
                yearOptions.push (<option key = {i.toString()} value = {Year}>{Year}</option>)
            }
        }
    }

    if (Main.Instance.DashboardState.lastYear === undefined && YearOptions.length > 0) {
        // console.log (YearOptions[YearOptions.length - 1])
        Logic.Type.New (Main.Instance.DashboardState, {lastYear: YearOptions[YearOptions.length - 1]})
    }

    return yearOptions
}

function getMonthOptions(from: boolean): JSX.Element[] {
    const monthOptions: JSX.Element[] = []
    const MonthOptions: number[] = []
    monthOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Month}</option>)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].substring(0,4))
            if (from && Main.Instance.DashboardState.yearFrom !== -1 && Year !== Main.Instance.DashboardState.yearFrom ||
                !from && Main.Instance.DashboardState.yearTo !== -1 && Year !== Main.Instance.DashboardState.yearTo
            ) {continue} 
            
            const Month: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(6)) - 1
            let found: boolean = false
            for (const item of MonthOptions) {
                if (item === Month) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthOptions.push (Month)
                monthOptions.push (<option key = {i.toString()} value = {Month}>{Assets.Months[Month + 2]}</option>)
            }
        }
    }
    return monthOptions
}

function getMonthOptionsAllIssues(from: boolean): JSX.Element[] {
    const monthOptions: JSX.Element[] = []
    const MonthOptions: number[] = []
    monthOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Month}</option>)
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.allIssuesOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].substring(0,4))
            if (from && Main.Instance.DashboardState.yearFrom !== -1 && Year !== Main.Instance.DashboardState.yearFrom ||
                !from && Main.Instance.DashboardState.yearTo !== -1 && Year !== Main.Instance.DashboardState.yearTo
            ) {continue} 
            
            const Month: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(6)) - 1
            let found: boolean = false
            for (const item of MonthOptions) {
                if (item === Month) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthOptions.push (Month)
                monthOptions.push (<option key = {i.toString()} value = {Month}>{Assets.Months[Month + 2]}</option>)
            }
        }
    }
    return monthOptions
}

function getMonthPartOptions(from: boolean): JSX.Element[] {
    const monthPartOptions: JSX.Element[] = []
    const MonthPartOptions: number[] = []
    monthPartOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Part}</option>)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].substring(0,4))
            const Month: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(6)) - 1
            if (from && Main.Instance.DashboardState.monthFrom !== Month || !from && Main.Instance.DashboardState.monthTo !== Month) {
                continue
            } 
            const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(9))
            const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3 
            let found: boolean = false
            for (const item of MonthPartOptions) {
                if (item === MonthPart) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthPartOptions.push (MonthPart)
                if (MonthPart === 3) {
                    let lastDay: string = '30'
                    if (Month === 1) {
                        lastDay = '28'
                        if (Year % 4 === 0) {
                            lastDay = '29'
                            if (Year % 100 === 0) {
                                lastDay = '28'
                                if (Year % 400 === 0) {
                                    lastDay = '29'
                                }    
                            }
                        }

                    } else if (Month === 0 || Month === 2|| Month === 4|| Month === 6|| Month === 7|| Month === 9|| Month === 11) {
                        lastDay = '31'
                    }
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1].replace('?', lastDay)}</option>)
                } else {
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1]}</option>)
                }
            }
        }
    }
    return monthPartOptions
}

function getMonthPartOptionsAllIssues(from: boolean): JSX.Element[] {
    const monthPartOptions: JSX.Element[] = []
    const MonthPartOptions: number[] = []
    monthPartOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Part}</option>)
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.allIssuesOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].substring(0,4))
            const Month: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(6)) - 1
            if (from && Main.Instance.DashboardState.monthFrom !== Month || !from && Main.Instance.DashboardState.monthTo !== Month) {
                continue
            } 
            const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(9))
            const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3 
            let found: boolean = false
            for (const item of MonthPartOptions) {
                if (item === MonthPart) {
                    found = true
                    break
                }
            }
            if (!found) {
                MonthPartOptions.push (MonthPart)
                if (MonthPart === 3) {
                    let lastDay: string = '30'
                    if (Month === 1) {
                        lastDay = '28'
                        if (Year % 4 === 0) {
                            lastDay = '29'
                            if (Year % 100 === 0) {
                                lastDay = '28'
                                if (Year % 400 === 0) {
                                    lastDay = '29'
                                }    
                            }
                        }

                    } else if (Month === 0 || Month === 2|| Month === 4|| Month === 6|| Month === 7|| Month === 9|| Month === 11) {
                        lastDay = '31'
                    }
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1].replace('?', lastDay)}</option>)
                } else {
                    monthPartOptions.push (<option key = {i.toString()} value = {MonthPart}>{Assets.MonthParts[MonthPart - 1]}</option>)
                }
            }
        }
    }
    return monthPartOptions
}

function getDateOptions(from: boolean): JSX.Element[] {
    const dateOptions: JSX.Element[] = []
    dateOptions.push (<option key = "-1" value = {-1}>{Assets.SelectDate}</option>)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].substring(0,4))
            if (from && (Main.Instance.DashboardState.yearFrom === -1 || Main.Instance.DashboardState.yearFrom === Year)
                || !from && (Main.Instance.DashboardState.yearTo === -1 || Main.Instance.DashboardState.yearTo === Year)) {

                const Month: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(6)) - 1
                if (from && (Main.Instance.DashboardState.monthFrom === -1 || Main.Instance.DashboardState.monthFrom === Month)
                    || !from && (Main.Instance.DashboardState.monthTo === -1 || Main.Instance.DashboardState.monthTo === Month)) {
                    const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i].charAt(9))
                    const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3
                    if (from && (Main.Instance.DashboardState.monthFromPart === -1 || Main.Instance.DashboardState.monthFromPart === MonthPart)
                    || !from && (Main.Instance.DashboardState.monthToPart === -1 || Main.Instance.DashboardState.monthToPart === MonthPart)) {
                        dateOptions.push (<option key = {i.toString()} value = {i}>{Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i]}</option>)
                    }
                }
            }
        }
    }
    return dateOptions
}

function getDateOptionsAllIssues(from: boolean): JSX.Element[] {
    const dateOptions: JSX.Element[] = []
    dateOptions.push (<option key = "-1" value = {-1}>{Assets.SelectDate}</option>)
    if (Main.Instance.DatabaseDataState.allIssuesOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.allIssuesOptionsDates.length; i++) {
            const Year: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].substring(0,4))
            if (from && (Main.Instance.DashboardState.yearFrom === -1 || Main.Instance.DashboardState.yearFrom === Year)
                || !from && (Main.Instance.DashboardState.yearTo === -1 || Main.Instance.DashboardState.yearTo === Year)) {

                const Month: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(5)) * 10 + parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(6)) - 1
                if (from && (Main.Instance.DashboardState.monthFrom === -1 || Main.Instance.DashboardState.monthFrom === Month)
                    || !from && (Main.Instance.DashboardState.monthTo === -1 || Main.Instance.DashboardState.monthTo === Month)) {
                    const DayInMonth: number = parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(8)) * 10 + parseInt(Main.Instance.DatabaseDataState.allIssuesOptionsDates[i].charAt(9))
                    const MonthPart: number = DayInMonth < 11 ? 1 : DayInMonth < 21 ? 2 : 3
                    if (from && (Main.Instance.DashboardState.monthFromPart === -1 || Main.Instance.DashboardState.monthFromPart === MonthPart)
                    || !from && (Main.Instance.DashboardState.monthToPart === -1 || Main.Instance.DashboardState.monthToPart === MonthPart)) {
                        dateOptions.push (<option key = {i.toString()} value = {i}>{Main.Instance.DatabaseDataState.allIssuesOptionsDates[i]}</option>)
                    }
                }
            }
        }
    }
    return dateOptions
}

/* function getOptionsDates(): JSX.Element[] {
    const od: JSX.Element[] = []
    od.push(<option key = "-1" value = {-1}>{Assets.AnyDate}</option>)
    if (Main.Instance.DatabaseDataState.dashboardBackendOptionsDates) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.dashboardBackendOptionsDates.length; i++) {
            od.push(<option key = {i.toString()} value = {i}>{Main.Instance.DatabaseDataState.dashboardBackendOptionsDates[i]}</option>)
        }
    }
    return od
} /*



/*
function changeIssuesPosition(left: boolean) {
    if (left) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {
            issuesPage: Main.Instance.DatabaseDataState.issuesPage - 1})
    } else {
        Logic.Type.New (Main.Instance.DatabaseDataState, {
            issuesPage: Main.Instance.DatabaseDataState.issuesPage + 1})
    }
    DbRoutes.wsGetIssues()
}

export function getIssuesNavigation(): JSX.Element {
    return (
        <div className = "mb5">
            <button
                disabled = {Main.Instance.DatabaseDataState.issuesPage === 0}
                onClick = {() => changeIssuesPosition(true)}
                className = "btn btn-default btn-xs mr0"
            >
                <FontAwesomeIcon icon = {faArrowCircleLeft}/>
            </button>
            <button
                style = {{marginLeft: '3px'}}
                // disabled = {}
                onClick = {() => changeIssuesPosition(false)}
                className = "btn btn-default btn-xs mr0"
            >
                <FontAwesomeIcon icon = {faArrowCircleRight}/>
            </button>
        </div>
    )
}
*/

export function GetIssuesShow(Mt7: boolean): JSX.Element {
    const mt7: string = Mt7 ? ' mt7' : ''
    return (
        <>
            <button className = {Main.Instance.DatabaseDataState.issuesShowDetails ? 'btn btn-success btn-xs mb5' + mt7 : 'btn btn-danger btn-xs mb5' + mt7} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {issuesShowDetails: !Main.Instance.DatabaseDataState.issuesShowDetails})}>{Assets.Show + ' ' + Assets.Details.toLowerCase()}</button>
            <button className = {Main.Instance.DatabaseDataState.issuesShowAssignedTo ? 'btn btn-success btn-xs mb5' + mt7 : 'btn btn-danger btn-xs mb5' + mt7} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {issuesShowAssignedTo: !Main.Instance.DatabaseDataState.issuesShowAssignedTo})}>{Assets.Show + ' ' + Assets.Assignees.toLowerCase()}</button>
            <button className = {Main.Instance.DatabaseDataState.issuesShowRiskLevel ? 'btn btn-success btn-xs mb5' + mt7 : 'btn btn-danger btn-xs mb5' + mt7} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {issuesShowRiskLevel: !Main.Instance.DatabaseDataState.issuesShowRiskLevel})}>{Assets.Show + ' ' + Assets.RiskLevel.toLowerCase()}</button>
            <button disabled = {!isUserGDPR()} className = {isUserGDPR() && Main.Instance.DatabaseDataState.issuesShowNames ? 'btn btn-success btn-xs mb5 mr0' + mt7 : 'btn btn-danger btn-xs mb5 mr0' + mt7} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {issuesShowNames: !Main.Instance.DatabaseDataState.issuesShowNames})}>{Assets.Show + ' ' + Assets.Username.toLowerCase() + ', ' + Assets.FirstName.toLowerCase()+ ', ' + Assets.LastName.toLowerCase()}</button>
        </>
    )
}

export function getIssuesShow(Mt7: boolean): JSX.Element {
    return (
        <div>
            {GetIssuesShow(Mt7)}
        </div>
    )
}

function getMonthYear(timestamp: string): string {
    const d: Date = new Date (timestamp)
    let s: string = ''
    s += Assets.Months[2 + d.getUTCMonth()] + ' ' + d.getUTCFullYear()
    return s
}

// function getAllIssues(): number {
//     let issues: number = 0
//     if (Main.Instance.DatabaseDataState.issues) {
//         for (const {} of Main.Instance.DatabaseDataState.issues) {
//             issues++ 
//         }
//     }
//     return issues
// }

function getDashboardBackendIssuesCountUnresolved(): number {
    let Value: number = 0
    // console.log (Main.Instance.DatabaseDataState.unresolvedIssuesCount[1])

    if (Main.Instance.DatabaseDataState.unresolvedIssuesCount && Main.Instance.DatabaseDataState.unresolvedIssuesCount[1]) {
        for (const Item of Main.Instance.DatabaseDataState.unresolvedIssuesCount[1].issuesCounts) {
            if (Item.issueType > IssueTypes.DashboardBackend && Item.issueType < IssueTypes.Other2) {
                Value += Item.count
            }
        }
    }

    return Value
}