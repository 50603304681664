import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {GamesList, Device} from '../../../Logic/Database/DatabaseData'
import {Logic} from '../../../Logic/Type'
import ReactTable from 'react-table'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {gotoTop, /*isUserGamanzaGamesAdmin, isUserGamanzaAdmin*/} from '../../../Logic/Utils'
import Switch from 'react-toggle-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDesktop, faMobileAlt, /*faTabletAlt,*/ faSyncAlt, faTimes} from '@fortawesome/free-solid-svg-icons'
// import AddGamePopup from './addGamePopup'


export function GameList() {
    DbRoutes.wsReinitialize()

    if (Main.Instance.DatabaseDataState.gamesList && 
        Main.Instance.DatabaseDataState.gameProviderNew) {
        if (!Main.Instance.DatabaseDataState.gamesListCentral && !ApprovedGames()) {
            setCentralGames()
        }
        // if (!Main.Instance.DatabaseDataState.gamesListApproved && ApprovedGames()) {
        //     setApprovedGames()
        // }
    }
    // if (Main.Instance.GamesCentralState.categoryLabelType === undefined) {
    //     if (isUserGamanzaGamesAdmin() || isUserGamanzaAdmin()) {
    //         Logic.Type.New(Main.Instance.GamesCentralState, {categoryLabelType: 0})
    //     } else {
    //         Logic.Type.New(Main.Instance.GamesCentralState, {categoryLabelType: 1})
    //     }
    // }
    const gamesNumber: number = ApprovedGames() && Main.Instance.DatabaseDataState.gamesListApproved ? Main.Instance.DatabaseDataState.gamesListApproved.length : Main.Instance.DatabaseDataState.gamesListCentral ? Main.Instance.DatabaseDataState.gamesListCentral.length : 0
    return (
        <div className = "pr">
            {/* {Main.Instance.GamesCentralState.showAddGameModal ? <AddGamePopup/> : void 0} */}
            <h2 style = {{width: '475px'}}>
                {ApprovedGames() ? Assets.ApprovedGamesStore : Assets.GamingeniusGameList}
                &nbsp;
                <span 
                style = {{background: Main.Instance.GamesCentralState.search_string && Main.Instance.GamesCentralState.search_string.length > 0 ? '#d9534f' : '' , fontSize: '16px'}} 
                title = {(ApprovedGames() ? Assets.NumberOfApprovedGames : Assets.NumberOfAllGames)} 
                className = "badge badge-success">
                    {gamesNumber + ' ' + (gamesNumber === 1 ? Assets.game : Assets.games)}
                </span>
                &nbsp;
                <button 
                title = {Assets.ReloadGameStore} 
                type = "button" 
                onClick = {() => DbRoutes.WsJackpotsReinitializeGames()} 
                className = "btn btn-warning btn-xs mr0">
                    <FontAwesomeIcon icon = {faSyncAlt}/>
                </button>
            </h2>
            {Main.Instance.GamesCentralState.selectedGameId && Main.Instance.GamesCentralState.selectedGameId !== 0 ? selectedGame() : void 0 }
            {Main.Instance.GamesCentralState.confirmActivatePopup ? activatePopup() : void 0}
            {getFilters()}
            <ReactTable
                sortable = {false}
                data = {ApprovedGames() ? Main.Instance.DatabaseDataState.gamesListApproved : Main.Instance.DatabaseDataState.gamesListCentral}
                columns = {[
                    {
                        width: 50, 
                        Header: <div style = {{marginTop: '10px'}}>{Assets.ID}</div>, 
                        accessor: 'gameId', 
                        headerClassName: 'th-center', 
                        Cell: (row) => 
                        <div 
                        style = {{textAlign: 'center'}} 
                        className = {getBackground(row.original)}
                        >
                            <button 
                            key={row.original.gameId}
                            onClick = {() => {
                                toggleGame(row.original.gameId, row.original.gameName, row.original.active, row.original.labels);
                                gotoTop()
                            }} 
                            title = {Main.Instance.GamesCentralState.selectedGameId === row.original.gameId ? Assets.DeselectGame : Assets.SelectGame} 
                            className = {Main.Instance.GamesCentralState.selectedGameId === row.original.gameId ? 'btn btn-primary btn-sm mr0' : 'btn btn-default btn-sm mr0'} 
                            style = {{paddingLeft: '0px', paddingRight: '0px', fontSize: '14px', lineHeight: 1.4, width: '100%'}}
                            >
                                {row.original.gameId}
                            </button>
                        </div>
                    },
                    {
                        Header: <div style = {{marginTop: '10px'}}>{Assets.Name}</div>, 
                        accessor: 'gameName', 
                        headerClassName: 'th-left',
                        Cell: (row) => 
                            <div 
                            className = {getBackground(row.original) + ' pr'}
                            >
                                {/*ApprovedGames() ?*/ <strong>{row.original.gameName}</strong> 
                                /*: 
                                <input 
                                disabled = {!isUserGamanzaGamesAdmin()} 
                                type = "text" 
                                style = {{height: '27px', width: 'calc(100%)'}} 
                                value = {row.value} 
                                onChange = {(e) => updateGame('name', row.original, e.target.value)}/>*/}
                            </div> 
                    },
                    // {
                    //     width: /*!ApprovedGames() && !Main.Instance.GamesCentralState.approvedJurisdictions ? 350 :*/ 110,
                    //     Header: <div style = {{marginTop: '10px'}}>{Assets.Jurisdictions}{/*&nbsp;&nbsp;{getJurisdictionsHeader()}*/}</div>, 
                    //     accessor: 'gameid', 
                    //     headerClassName: 'th-center', 
                    //     Cell: (row) => 
                    //     <div 
                    //     style = {{textAlign: 'center', height : '48px'}} 
                    //     className = {getBackground(row.original)}>
                    //         {getJurisdiction(row.original.gameid, row.original.jurisdictions)}
                    //     </div>
                    // },
                    {
                        width: 150, 
                        Header: <div style = {{marginTop: '10px'}}>{Assets.Game  + ' ' + Assets.Category.toLowerCase()}</div>, 
                        accessor: 'categoryId', 
                        headerClassName: 'th-left', 
                        Cell: (row) =>
                        <div 
                        className = {getBackground(row.original)}>
                            <div>{/*ApprovedGames() ?*/ row.value !== undefined ? <span style = {{fontWeight: 600}}>{getCategoryName(row.original)}</span> : '-' 
                            /*: 
                                <select
                                    disabled = {!isUserGamanzaGamesAdmin()}
                                    style = {{background: !isUserGamanzaGamesAdmin() ? '' : '#fff'}}
                                    onChange = {(e) => updateGame('category_id', row.original.gameid, +e.target.value)}
                                    value = {row.value ? +row.value : 0}
                                >
                                    {getOptionsCategory()}
                    </select>*/}
                            </div>
                        </div>
                    },
                    {
                        width: 60, 
                        Header: <div style = {{marginTop: '10px'}}>{Assets.Demo}</div>, 
                        accessor: 'demo', 
                        headerClassName: 'th-left', 
                        Cell: (row) =>
                        <div 
                        className = {getBackground(row.original)}>
                            <Switch
                                className = {/*ApprovedGames() || !isUserGamanzaGamesAdmin() ? 'mr0 mt5 mb-9 switch-disabled2' :*/ 'mr0 mt5 mb-9'}
                                onClick = {() => {} /* ApprovedGames() || !isUserGamanzaGamesAdmin() ? {} : updateBoolean(row.original.gameid, 'demo')*/}
                                on = {row.original.demo ? row.original.demo : false}
                                enabled = {false}
                            />
                        </div>
                    },
                    {
                        width: 180, 
                        Header: <div style = {{marginTop: '10px'}}>{Assets.GameProviderSupplier}</div>, 
                        accessor: 'providerId', 
                        headerClassName: 'th-left', 
                        Cell: (row) => 
                        <div 
                        className = {getBackground(row.original)}>
                            <div>
                                {/*ApprovedGames() ? */
                                <span style = {{fontWeight: 600}}>{getProvider(+row.value)}</span> 
                                /*:
                                <select
                                    style = {{background: !isUserGamanzaGamesAdmin() ? '' : '#fff'}}
                                    disabled = {!isUserGamanzaGamesAdmin()}
                                    onChange = {(e) => updateGame('provider_id', row.original.gameid, +e.target.value)}
                                    value = {row.value ? +row.value : 0}
                                >
                                    {getOptionsProvider()}
                                </select>*/}
                            </div>
                        </div>
                        },
                        {
                            width: 100,
                            Header: <div style = {{marginTop: '10px'}}>{Assets.MinBetSize}</div>, 
                            accessor: 'betMin', 
                            headerClassName: 'th-left',
                            Cell: (row) => 
                                <div 
                                className = {getBackground(row.original) + ' pr'}
                                >
                                    {ApprovedGames() ? <strong>{row.value ? row.value : '-'}</strong> 
                                    : 
                                    <input 
                                    style={{width: 'calc(100%)', height: '27px', borderWidth: '1px'}}
                                    key={row.original.gameId + 'betMin'}
                                    // disabled = {!isUserGamanzaGamesAdmin()} 
                                    type = "number" 
                                    value = {row.value} 
                                    onChange = {(e) => updateGame('betMin', row.original.gameId, +e.target.value)}/>}
                                </div> 
                        },
                        {
                            width: 100,
                            Header: <div style = {{marginTop: '10px'}}>{Assets.MaxBetSize}</div>, 
                            accessor: 'betMax', 
                            headerClassName: 'th-left',
                            Cell: (row) => 
                                <div 
                                className = {getBackground(row.original) + ' pr'}
                                >
                                    {ApprovedGames() ? <strong>{row.value ? row.value : '-'}</strong> 
                                    : 
                                    <input 
                                    key={row.original.gameId + 'betMax'}
                                    // disabled = {!isUserGamanzaGamesAdmin()} 
                                    type = "number" 
                                    style={{width: 'calc(100%)', height: '27px', borderWidth: '1px'}}
                                    value = {row.value} 
                                    onChange = {(e) => updateGame('betMax', row.original.gameId, +e.target.value)}/>}
                                </div> 
                        },
                        {
                            width: 120, 
                            Header: <div><div style = {{marginTop: '4px'}}>{Assets.Supported}</div><div>{Assets.Devices}</div></div>, 
                            accessor: 'devices', 
                            headerClassName: 'th-center', 
                            Cell: (row) => 
                            <div 
                            className = {getBackground(row.original)}>
                                {getDevices(row.value, row.original.gameId)}
                            </div>
                        },
                        {
                            accessor: 'active', 
                            width: 70, 
                            Header: <div><div style = {{marginTop: '4px'}}>{Assets.Active}</div><div>{Assets.Games.toLowerCase()}</div></div>, 
                            headerClassName: 'th-center', 
                            Cell: (row) =>
                            <div
                                className = {getBackground(row.original)}
                                style = {{textAlign: 'center'}}
                            >
                                <Switch
                                    className = "mr0 mt5 mb-9"
                                    onClick = {() => Logic.Type.New(Main.Instance.GamesCentralState, {confirmActivatePopup: true, selectedGame: row.original})}
                                    on = {row.value ? row.value : false}
                                />
                            </div>,
                        },
                        {
                            show: !ApprovedGames(), 
                            accessor: 'tested', 
                            width: 70, 
                            Header: <div><div style = {{marginTop: '4px'}}>{Assets.Tested}</div><div>{Assets.Game.toLowerCase()}</div></div>, 
                            headerClassName: 'th-center', 
                            Cell: (row) =>
                            <div
                                className = {getBackground(row.original)}
                                style = {{textAlign: 'center'}}
                            >
                                <Switch
                                    enabled = {false}
                                    className = "mr0 mt5 mb-9"
                                    // onClick = {() => {
                                    //     updateBoolean(row.original.gameId, 'tested')
                                    // }}
                                    on = {row.original.tested ? row.original.tested : false}
                                />
                            </div>,
                        },

                ]}
                pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize={50}
            />
        </div>
    )
}

function ApprovedGames(): boolean {
    return Main.Instance.Location.endsWith('approved-games-store')
}

function getBackground(game: GamesList): string {
    return game.active ? 'background-light' : 'background-light-grey2'
}


function activatePopup(): JSX.Element {
    return (
        <div style = {{padding: '10px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'fixed', top: '150px', height: '150px', left: '400px', right: '400px'}}>
            <button
                title={Assets.Close}
                onClick={() => Logic.Type.New(Main.Instance.GamesCentralState, {confirmActivatePopup: false})}
                style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                className="btn btn-default btn-sm btn-plus"
            >
                <i className={Assets_.faCancelButtonClass} />
            </button>
            <div style = {{margin: '10px', fontSize: '16px', textAlign: 'center'}}>
                {Main.Instance.GamesCentralState.selectedGame.active ? Assets.ConfirmDeactivateGame : Assets.ConfirmActivateGame}&nbsp;{Main.Instance.GamesCentralState.selectedGame.gameName}?
            </div>
            <button
                onClick={() => {
                    updateBoolean(Main.Instance.GamesCentralState.selectedGame.gameId, 'active')
                    Logic.Type.New(Main.Instance.GamesCentralState, {confirmActivatePopup: false})
                    
                }}
                style={{position: 'relative', margin: '20px 0 0 200px', width: '100px' }}
                className="btn btn-default btn-sm dbl fl"
            >
                {Assets.Yes}
            </button>
            <button
                onClick={() => {
                    Logic.Type.New(Main.Instance.GamesCentralState, {confirmActivatePopup: false})
                }}
                style={{position: 'relative', margin: '20px 200px 0 0', width: '100px' }}
                className="btn btn-default btn-sm dbl fr"
            >
                {Assets.No}
            </button>
        </div> 
    )
}

function getFilters(): JSX.Element {
    return (
        <div>
            <div className = "mb5">
                <button
                    style = {{marginRight: '0px'}}
                    onClick = {() => {
                        Logic.Type.New (Main.Instance.DatabaseDataState, {gamesCentralSort: false})
                        sortGames(false)
                    }}
                    className = {Main.Instance.DatabaseDataState.gamesCentralSort ? 'btn btn-default btn-xs br0' : 'btn btn-primary btn-xs br0'}
                >
                    {Assets.Sort.toLowerCase() + Assets._by_ + Assets.Name.toLowerCase()}
                </button>

                <button
                    style = {{marginRight: '0px'}}
                    onClick = {() => {
                        Logic.Type.New (Main.Instance.DatabaseDataState, {gamesCentralSort: true})
                        sortGames(true)
                    }}
                    className = {!Main.Instance.DatabaseDataState.gamesCentralSort ? 'btn btn-default btn-xs br0' : 'btn btn-primary btn-xs br0'}
                >
                    {Assets.Sort.toLowerCase() + Assets._by_ + Assets.ID}
                </button>
                {/*!ApprovedGames() ? 
                <button 
                disabled = {!isUserGamanzaGamesAdmin()} 
                onClick = {() => Logic.Type.New(Main.Instance.GamesCentralState, {showAddGameModal: true})} 
                className = "btn btn-primary btn-xs ml5px" 
                title = {Assets.InsertANewGameIntoDatabase}
                >
                    {Assets.AddANewGame}
                </button> : void 0
                // <select
                //     value = {Main.Instance.GamesCentralState.categoryLabelType}
                //     style = {{height: '21px', background: '#fff', paddingBottom: '2px'}}
                //     onChange = {(e) => {Logic.Type.New(Main.Instance.GamesCentralState, {categoryLabelType: +e.target.value, FilterCategory: -1, selectedGameLabels: undefined, selectedGameId: 0}); setApprovedGames()}}
                //     className = "ml5px"
                // >
                //     [<option key = "0" value = "0">{Assets_.Gamanza + ' ' + Assets.Default.toLowerCase() + ' ' + Assets.Categories.toLowerCase() + ' / ' + Assets.Labels.toLowerCase()}</option>]
                //     [<option key = "1" value = "1">{Main.Instance.LoginState.entity + ' ' + Assets.Categories.toLowerCase() + ' / ' + Assets.Labels.toLowerCase()}</option>]
                // </select>
                */}
            </div>
            <div className = "margin-bottom-sm">
                <select
                    value = {/*ApprovedGames() ? Main.Instance.GamesCentralState.FilterCategory ? Main.Instance.GamesCentralState.FilterCategory : 0 : */ Main.Instance.GamesCentralState.filterCategory ? Main.Instance.GamesCentralState.filterCategory : 0}
                    style = {{background: '#fff'}}
                    title = {Assets.Filter + ': ' + Assets.Category}
                    onChange = {(e) => filterCategory(+e.target.value)}
                >
                    {getOptionsCategory()}
                </select>
                <select
                    value = {Main.Instance.GamesCentralState.filterProvider}
                    style = {{background: '#fff'}}
                    title = {Assets.Filter + ': ' + Assets.GameProvider}
                    className = "ml5px"
                    onChange = {(e) => filterProvider(+e.target.value)}
                    >
                    {getOptionsProvider()}
                </select>
            </div>
            
            <div className = "mb5">
                <select
                    value = {Main.Instance.GamesCentralState.filterActive}
                    style = {{background: '#fff'}}
                    title = {Assets.Filter + ': ' + Assets.Active}
                    onChange = {(e) => filterGamesActive(+e.target.value)}
                    >
                    {getOptionsActive()}
                </select>

                <select
                    value = {Main.Instance.GamesCentralState.filterTestGame}
                    className = "ml5px "
                    style = {{background: '#fff'}}
                    title = {Assets.Filter + ': ' + Assets.Test}
                    onChange = {(e) => filterGamesTest(+e.target.value)}
                    >
                    {getOptionsTest()}
                </select>
                {/* <select
                    value = {Main.Instance.GamesCentralState.filterJurisdictions ? Main.Instance.GamesCentralState.filterJurisdictions : 0}
                    style = {{background: '#fff'}}
                    title = {Assets.Filter + ': ' + Assets.Jurisdiction}
                    className = "ml5px "
                    onChange = {(e) => filterJurisdiction(+e.target.value)}>
                    {getOptionsJurisdiction()}
                </select> */}
                
            </div>
            <div className = "mb5">
                {getLabelFilters()}
            </div>
        </div>
    )
}

function getLabelFilters() {
    const labels: JSX.Element[] = []
    labels.push(
        <span key = "labels" title = {ApprovedGames() ? Assets.ApprovedGamesStore : Assets.GamingeniusGameList } style = {{fontWeight: 700, marginRight: '10px'}}>
            {Assets.Filter + ': ' + Assets.Labels}
        </span>,
    )
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     if (Main.Instance.DatabaseDataState.tags) {
    //         for (let i: number = 0; i < Main.Instance.DatabaseDataState.tags.length; i++) {
    //             labels.push (
    //                 <button
    //                     key = {i.toString()}
    //                     onClick = {() => {filterLabels(i); setApprovedGames()}}
    //                     className = {Main.Instance.DatabaseDataState.toggleTags && Main.Instance.DatabaseDataState.toggleTags[i] ? 'btn btn-xs btn-success' : 'btn btn-xs btn-default'}
    //                 >
    //                     {Main.Instance.DatabaseDataState.tags[i].name}
    //                 </button>,
    //             )
    //         }
    //     }
    // } else {
    if (Main.Instance.DatabaseDataState.gamesListLabels) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesListLabels.length; i++) {
            labels.push (
                <button
                    key = {i.toString()}
                    onClick = {() => {filterLabels(i); /*ApprovedGames() ? setApprovedGames() :*/ setCentralGames()}}
                    className = {Main.Instance.DatabaseDataState.toggleGameLabels && Main.Instance.DatabaseDataState.toggleGameLabels[i] ? 'btn btn-xs btn-success' : 'btn btn-xs btn-default'}
                >
                    {Main.Instance.DatabaseDataState.gamesListLabels[i].name}
                </button>,
            )
        }
    }
    // }
    return labels
}

function filterLabels(i: number) {
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     const toggleTags = Object.assign([], Main.Instance.DatabaseDataState.toggleTags)
    //     toggleTags[i] = !toggleTags[i]
    //     Logic.Type.New (Main.Instance.DatabaseDataState, {toggleTags})
    // } else {
    const toggleGameLabels = Object.assign([], Main.Instance.DatabaseDataState.toggleGameLabels)
    toggleGameLabels[i] = !toggleGameLabels[i]
    Logic.Type.New (Main.Instance.DatabaseDataState, {toggleGameLabels})
    // }
    
}
/*
function getOptionsJurisdiction(): JSX.Element[] {
    const oj: JSX.Element[] = []
    oj.push(<option key = "0" value = "0">{Assets.Any + ' ' + Assets.Jurisdiction.toLowerCase()}</option>)
    if (Main.Instance.DatabaseDataState.jurisdictionsList) {
        for (const item of Main.Instance.DatabaseDataState.jurisdictionsList) {
            if (item.active) {
                oj.push(<option key = {item.id.toString()} value = {item.id}>{item.name} ({item.code})</option>)      
            }
        }
    }
    return oj
}

function filterJurisdiction(jurisdiction) {
    Logic.Type.New (Main.Instance.GamesCentralState, {filterJurisdictions: jurisdiction})
    if (ApprovedGames()) {
        setApprovedGames()
    } else {
        setCentralGames()
    }
}
*/
export function sortGames(byId: boolean) {
    const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
    gamesList.sort(byId ? bygameid : byname)

    const gamesListCentral: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesListCentral)
    gamesListCentral.sort(byId ? bygameid : byname)

    const gamesListApproved: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesListApproved)
    gamesListApproved.sort(byId ? bygameid : byname)

    Logic.Type.New(Main.Instance.DatabaseDataState, {
        gamesList,
        gamesListCentral,
        gamesListApproved
    })
}

function bygameid(a, b): number {
    return a.gameId > b.gameId ? 1 : -1
}

function byname(a, b): number {
    return a.gameName.toLowerCase() > b.gameName.toLowerCase() ? 1 : -1
}

function filterGamesActive(active: number) {
    /*
    if (ApprovedGames()) {
        Logic.Type.New (Main.Instance.GamesCentralState, {filterActive: active})
        setApprovedGames()
    } else {
    */
        Logic.Type.New (Main.Instance.GamesCentralState, {filterActive: active})
        setCentralGames()
    
}

function getOptionsActive(): JSX.Element[]  {
    const options: JSX.Element[] = []
    options.push(<option key = "0" value = {0}>{Assets.Active}?</option>)
    options.push(<option key = "1" value = {1}>{Assets.Active + ' ' + Assets.games}</option>)
    options.push(<option key = "2" value = {2}>{Assets.Not + ' ' + Assets.Active.toLowerCase() + ' ' + Assets.games}</option>)
    
    return options
}

function getOptionsTest(): JSX.Element[]  {
    const options: JSX.Element[] = []
    options.push(<option key = "0" value = {0}>{Assets.Tested}?</option>)
    options.push(<option key = "1" value = {1}>{Assets.Tested + ' ' + Assets.games}</option>)
    options.push(<option key = "2" value = {2}>{Assets.Not + ' ' + Assets.Tested.toLowerCase() + ' ' + Assets.games}</option>)
    
    return options
}

function filterGamesTest(test: number) {
    /*
    if (ApprovedGames()) {
        Logic.Type.New (Main.Instance.GamesCentralState, {filterTestGame: test})
        setApprovedGames()
    } else {
        Logic.Type.New (Main.Instance.GamesCentralState, {filterTestGame: test})
        setCentralGames()
    }
    */
    Logic.Type.New (Main.Instance.GamesCentralState, {filterTestGame: test})
        setCentralGames()
}

function filterCategory(category: number) {
    /*
    if (ApprovedGames()) {
        Logic.Type.New (Main.Instance.GamesCentralState, {filterCategory: category})
        setApprovedGames()
    } else {
        Logic.Type.New (Main.Instance.GamesCentralState, {filterCategory: category})
        setCentralGames()
    }
    */
    Logic.Type.New (Main.Instance.GamesCentralState, {filterCategory: category})
        setCentralGames()
}

function filterProvider(provider: number) {
    Logic.Type.New (Main.Instance.GamesCentralState, {filterProvider: provider})
    /*
    if (ApprovedGames()) {
        setApprovedGames()
    } else {
        setCentralGames()
    }
    */
    setCentralGames()
}

function getDevices(devices: number[], gameid): JSX.Element[] {
    // const index: number = getGameIndex(gameid)
    const buttons: JSX.Element[] = []
    const allDevices: Device[] = Object.assign([], Main.Instance.DatabaseDataState.devices)

    // for (let i: number = 0; i < allDevices.length / 2; i++) {
    //     buttons.push(
    //         <button
    //             key = {i.toString() + gameid}
    //             disabled = {/*ApprovedGames() || !isUserGamanzaGamesAdmin()*/ true}
    //             style = {{marginRight: i === allDevices.length / 2 - 1 ? '0px' : '3px'}}
    //             onClick = {() => toggleDevice(index, +allDevices[i])}
    //             title = {+allDevices[i] === Devices.VLT ? Assets.VideoLotteryTerminal : Devices[i+1]}
    //             className = {deviceOn(+allDevices[i], devices) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
    //         >
    //             {allDevices[i] === Devices.VLT.toString() ?
    //             Devices[i+1] :
    //             <FontAwesomeIcon 
    //                 icon = {
    //                     +allDevices[i] === Devices.Mobile ? faMobileAlt :
    //                     +allDevices[i] === Devices.Desktop ? faDesktop : 
    //                     +allDevices[i] === Devices.Tablet ? faTabletAlt :
    //                     faTabletAlt
    //                 }
    //             /> }
    //         </button>)
    // }

    for (let i: number = 0; i < allDevices.length; i++) {
        buttons.push(
            <button
                key={allDevices[i].deviceId + gameid}
                disabled={true}
                style = {{marginRight: i === allDevices.length - 1 ? '0px' : '3px'}}
                title = {allDevices[i].device}
                className = {deviceOn(allDevices[i].deviceId, devices) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
            >
                { allDevices[i].deviceId === 1 ||  allDevices[i].deviceId === 2 ? 
                <FontAwesomeIcon 
                    icon = {
                        allDevices[i].deviceId === 1 ? faDesktop : faMobileAlt
                    }
                /> : allDevices[i].device}
            </button>
        )
    }
    return buttons
}

function selectedGame(): JSX.Element {
    const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
    const index: number = getGameIndex(Main.Instance.GamesCentralState.selectedGameId)
    return (
        <div 
            className = "clearfix"
            style = {{
                position: 'absolute',
                height: '120px',
                width: 'calc(100% - 620px)',
                border: '1px solid #bbb',
                background: '#f0f0f0',
                right: '0px',
                top: '0px'
            }}
        >
            {Main.Instance.GamesCentralState.selectedGameImage === Assets_.zeroPng ? void 0 :
            <img className = "dbl img img-responsive fr" style = {{height: '120px'}} src = {Main.Instance.GamesCentralState.selectedGameImage}/>}
            <button onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameId: 0})} style = {{background: 'transparent', border: '0px', position: 'absolute', right: '-5px', top: '-5px', marginRight: '0px'}} className = "btn btn-default btn-sm">
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            {Main.Instance.GamesCentralState.selectedGameImage && Main.Instance.GamesCentralState.selectedGameImage.trim().length > 0 && Main.Instance.GamesCentralState.selectedGameImage !== Assets_.zeroPng ?
            <button onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameId: 0})} style = {{background: 'transparent', border: '0px', position: 'absolute', right: '-5px', bottom: '-5px', marginRight: '0px', color: '#fff'}} className = "btn btn-default btn-sm">
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            : void 0} 

            <div
                style = {{
                    position: 'absolute',
                    left: '-45px',
                    height: '120px',
                    top: '-1px',
                    width: '45px'
                }}>
                {getLabelsLanguages()}
            </div>
   
            {index !== undefined && index !== -1 && gamesList[index] ?
            <div
                className = {getBackground(gamesList[index])}
                style = {{
                 
                    lineHeight: '16px',
                    height: '118px',
                    padding: '5px',
                    overflowY: 'scroll',
                    width: 'calc(100% - ' + Main.Instance.GamesCentralState.selectedGameImageWidth + 'px)',
                    margin: '0px'
                }}
            >
                {getGameTags()}
            </div> : void 0}
        </div>
    )
}

function getGameTags(): JSX.Element[] {
    // const index: number = getGameIndex(Main.Instance.GamesCentralState.selectedGameId)
    const selectedGameLabels: number[] = Object.assign([], Main.Instance.GamesCentralState.selectedGameLabels)
    const tags: JSX.Element[] = []
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.tags) {
    //         for (let i: number = 0; i < Main.Instance.DatabaseDataState.tags.length; i++) {
    //             tags.push(
    //             <button 
    //             key = {i.toString()} 
    //             // onClick = {() => void 0} 
    //             className = {tagOn(selectedGameLabels, Main.Instance.DatabaseDataState.tags[i].id) ? 'btn btn-default btn-dark btn-xs' : 'btn btn-default btn-xs'} 
    //             style = {{marginBottom: '2px', cursor: 'not-allowed'}}
    //             >
    //                 {Main.Instance.DatabaseDataState.tags[i].name}
    //             </button>)
    //         }
    //     }
    // } else {
    if (Main.Instance.DatabaseDataState.gamesListLabels) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesListLabels.length; i++) {
            tags.push(
            <button 
            key = {i.toString()} 
            // onClick = {() => toggleTag(index, Main.Instance.DatabaseDataState.gamesListLabels[i].label_id)} 
            className = {tagOn(selectedGameLabels, Main.Instance.DatabaseDataState.gamesListLabels[i].labelId) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
            style = {{marginBottom: '2px', cursor: 'not-allowed'}}
            >
                {Main.Instance.DatabaseDataState.gamesListLabels[i].name}
            </button>)
        }
    }   
    // }    
    return tags
}

/*
function toggleTag(index: number, tag: number) {
    const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)

    if (gamesList[index].labels === null || gamesList[index].labels === undefined) {
        gamesList[index].labels = []
    }

    let found: boolean = false
    for (let l: number = 0; l < gamesList[index].labels.length; l++) {
        if (gamesList[index].labels[l] === tag) {
            found = true
            gamesList[index].labels.splice(l, 1)
            break
        }
    }
    if (!found) {
        gamesList[index].labels.push(tag)
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList})
    DbRoutes.updateGameList(gamesList[index])

}
*/

function tagOn(labels: number[], tag: number): boolean {
    if (labels) {
        for (const item of labels) {
            if (item === tag) { return true }
        }
    }
    return false
}

function getLabelsLanguages(): JSX.Element[] {
    const languages: JSX.Element[] = []
    languages.push(<button title = {Assets.SelectGameLabels} key = "-4" onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameLanguageLong: undefined})} className = {Main.Instance.GamesCentralState.selectedGameLanguageLong === undefined ? 'btn btn-warning br0 mr0' : 'btn btn-default br0 mr0'} style = {{fontWeight: 700, fontSize: '12px', display: 'block', width: '100%', height: '20px', lineHeight: '18px', padding: '0px', marginTop: '0px'}}>{Assets.Labels.toLowerCase()}</button>)
    // languages.push(<button title = {Assets.SelectGameShortDescription} key = "-2" onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameLanguageLong: false})} className = {Main.Instance.GamesCentralState.selectedGameLanguageLong === false ? 'btn btn-info br0 mr0' : 'btn btn-default br0 mr0'} style = {{display: 'block', width: '100%', height: '20px', lineHeight: '18px', padding: '0px', marginTop: '0px'}}>{Assets.Short.toLowerCase()}</button>)
    // languages.push(<button title = {Assets.SelectGameLongDescription} key = "-3" onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameLanguageLong: true})} className = {Main.Instance.GamesCentralState.selectedGameLanguageLong === true ? 'btn btn-info br0 mr0' : 'btn btn-default br0 mr0'} style = {{display: 'block', width: '100%', height: '20px', lineHeight: '18px', padding: '0px', marginTop: '0px'}}>{Assets.Long.toLowerCase()}</button>)
    
    if (Main.Instance.DatabaseDataState.custom.general.languages && Main.Instance.GamesCentralState.selectedGameLanguageLong !== undefined) {
        const h: number = (110 - 30) / Main.Instance.DatabaseDataState.custom.general.languages.length
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
            languages.push(<button title = {Assets.SelectLanguage + ': ' + Main.Instance.DatabaseDataState.custom.general.languages[i].name + ' (' + Assets.locale + ': ' + Main.Instance.DatabaseDataState.custom.general.languages[i].locale + ')'} onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameLanguage: i})} key = {i.toString()} className = {Main.Instance.GamesCentralState.selectedGameLanguage === i ? 'btn btn-primary br0 mr0' : 'btn btn-default br0 mr0'} style = {{fontWeight: 700, fontSize: (h - 8) + 'px', display: 'block', width: '100%', height: h + 'px', lineHeight: (h-2) + 'px', padding: '0px', marginTop: '0px'}}>{Main.Instance.DatabaseDataState.custom.general.languages[i].locale}</button>)
        }
    }
    return languages
}

// function toggleDevice(index: number, device: number) {
//     const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)

//     if (gamesList[index].devices === undefined) {
//         gamesList[index].devices = []
//     }

//     let found: number = -1
//     for (let i: number = 0; i < gamesList[index].devices.length; i++) {
//         if (gamesList[index].devices[i] === device) {
//             found = i
//             break
//         }
//     }
//     if (found === -1) {
//         gamesList[index].devices.push(device)
//     } else {
//         gamesList[index].devices.splice(found, 1)
//     }

//     DbRoutes.updateGameList(gamesList[index])
//     Logic.Type.New(Main.Instance.DatabaseDataState, {gamesList})

// }

function deviceOn(i: number, devices: number[]): boolean {
    for (const item of devices) {
        if (item === i) {
            return true
        }
    }
    return false
}

export function getProvider(value: number): string {
    if (Main.Instance.DatabaseDataState.gameProviderNew) {
        for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
            if (item.providerId === value) {
                return item.name
            }
        }
    }
    return Assets.notDefined
}


export function getOptionsProvider(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push(<option key = "-1" value = {0}>{Assets.AnyGameProvider}</option>)
    if (Main.Instance.DatabaseDataState.gameProviderNew) {
        for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
            options.push(<option disabled = {!item.active} key = {item.providerId.toString()} value = {item.providerId}>{item.name}</option>)
        }
    }
    return options
}

export function getStrapiCategoryId(gameid: number): number {
    if (Main.Instance.DatabaseDataState.casinoGames) {
        for (const item of Main.Instance.DatabaseDataState.casinoGames) {
            if (item.gameId === gameid) {
                if (item.category) {
                    return item.category.categoryId
                } 
            }
        }
    }
    return 0
}

function getCategoryName(game: GamesList): string {

    let category: string
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     if (Main.Instance.DatabaseDataState.casinoGames) {
    //         for (const item of Main.Instance.DatabaseDataState.casinoGames) {
    //             if (item.gameId === game.gameid) {
    //                 if (item.category) {
    //                     category = item.category.name
    //                 } 
    //             }
    //         }
    //     }
    // } else {

    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        for (const item of Main.Instance.DatabaseDataState.gamesListCategories) {
            if (item.categoryId === game.categoryId) {
                category = item.name
            }
        }
    }
    // }

    if (!category) {
        return Assets.Unknown
    } else {
        return category
    }
}


export function getOptionsCategory(): JSX.Element[] {
    const options: JSX.Element[] = []  
    // options.push(<option key = "-1" value = {-1}>{Assets.SelectCategory}</option>)
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     options.push(<option key = "-1" value = {-1}>{Assets.AnyCategory}</option>)
    //     if (Main.Instance.DatabaseDataState.scategories) {
    //         for (const item of Main.Instance.DatabaseDataState.scategories) {
    //             options.push(<option key = {item.id.toString()} value = {item.categoryId}>{item.name}</option>)
    //         }
    //     }
    // } else { 
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        for (const item of Main.Instance.DatabaseDataState.gamesListCategories) {
            options.push(<option key = {item.categoryId.toString()} value = {item.categoryId}>{item.categoryId === 0 ? Assets.AnyCategory : item.name.charAt(0).toUpperCase() + item.name.substring(1)}</option>)
        }
    }
    // }
    return options
}
/*
function getJurisdictionName(id: number): string {
    const jurisdictionsList: Jurisdiction[] = Object.assign([], Main.Instance.DatabaseDataState.jurisdictionsList)
    let name: string
    for (const item of jurisdictionsList) {
        if (item.id === id) {
            name = item.name
        }
    }
    return name
} 

*/
// function getJurisdictionsHeader(): JSX.Element {
//     const jh: JSX.Element[] = []
//     /*if (ApprovedGames()) {*/
//     jh.push(<button title = {Assets.AllJurisdictions} key = "0" type = "button" className = {'btn btn-success btn-xs mr0 br0'}>{Assets.Approved}</button>)
//     /*} else {
//         jh.push(<button title = {Assets.AllJurisdictions} key = "0" onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {approvedJurisdictions: false})} type = "button" className = {Main.Instance.GamesCentralState.approvedJurisdictions === false ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>{Assets.All}</button>)
//         jh.push(<button title = {Assets.ApprovedJurisdictions} key = "1" onClick = {() => Logic.Type.New (Main.Instance.GamesCentralState, {approvedJurisdictions: true})} type = "button" className = {Main.Instance.GamesCentralState.approvedJurisdictions === true ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>{Assets.Approved}</button>)
//     }*/
//     return <div className = "dbl mt5">{jh}</div>
// }
/*
function getJurisdiction(gameid: number, jurisdictions: number[]): JSX.Element[]|JSX.Element {
    const index: number = getGameIndex(gameid)
    // const jurisdictionsList: Jurisdiction[] = Object.assign([], Main.Instance.DatabaseDataState.jurisdictionsList)
    if (index !== -1) {    
        const j: JSX.Element[] = []
        const jj: JSX.Element[] = []

        /*
        if (!ApprovedGames()) {
            for (let i: number = 0; i < jurisdictionsList.length; i++) {
                const approved: boolean = getApprovable(jurisdictionsList[i].id, jurisdictions)
                if (Main.Instance.GamesCentralState.approvedJurisdictions === false || approved) {
                    const l: JSX.Element = 
                        <button
                            disabled = {!isUserGamanzaGamesAdmin()}
                            onClick = {() => changeJurisdictionStatus(gameid, jurisdictionsList[i].id)}
                            title = {Main.Instance.DatabaseDataState.jurisdictions[i].code + ' (' + Main.Instance.DatabaseDataState.jurisdictions[i].name + ').'}
                            key = {i.toString()}
                            className = {approved ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}
                            style = {{paddingTop: '0px'}}
                        >
                            {Main.Instance.DatabaseDataState.jurisdictions[i].code}
                        </button>
                    
                    i < Main.Instance.DatabaseDataState.jurisdictions.length / 2 ? j.push(l) : jj.push(l)
                }
            }
        } else {
            
        if (jurisdictions && jurisdictions.length > 0 && jurisdictions[0] !== null) {
            for (let i: number = 0; i < jurisdictions.length; i++) {
                const name: string = getJurisdictionName(jurisdictions[i])
                j.push(
                    <button
                        disabled = {true}
                        title = {Assets.TheGameIsApprovedInJurisdiction + ' ' + name + '.'}
                        key = {i.toString() + 'jurisdiction'}
                        className = {'btn btn-success btn-xs mr0 br0'}
                    >
                        {name}
                    </button>,
                )
            }
        } else {
            j.push(
                <div style = {{position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className = "label label-default" style = {{width: '100px'}}>
                        {Assets.NoApprovable}
                    </div>
                    <div className = "label label-default" style = {{width: '100px', marginTop: '2px'}}>
                        {Assets.Jurisdiction.toLowerCase()}
                    </div>
                </div>
            )
        }
        // }
        return jj.length > 0 ?
        <div style = {{position: 'relative'}}>
            <div style = {{position: 'absolute', top: '-12px', left: '0px', right: '0px', marginLeft: 'auto', marginRight: 'auto'}}>
                {j}
            </div>
            <div style = {{position: 'absolute', top: '10px', left: '0px', right: '0px', marginLeft: 'auto', marginRight: 'auto'}}>
                {jj}
            </div>
        </div> : j
    } else {
        return <div/>
    }
}
*/
/*
function changeJurisdictionStatus(gameid: number, jurisdiction_id: number) {
    const index: number = getGameIndex(gameid)
    const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
    let found: number = -1
    if (gamesList[index].jurisdictions) {
        for (let i: number = 0; i < gamesList[index].jurisdictions.length; i++) {
            if (gamesList[index].jurisdictions[i] === jurisdiction_id) {
                found = i
                break
            }
        }
    }
    if (found === -1) {
        gamesList[index].jurisdictions.push(jurisdiction_id)
    } else {
        gamesList[index].jurisdictions.splice(found, 1)
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList})
    DbRoutes.updateGameList(gamesList[index])

}

function getApprovable(id: number, jurisdictions: number[]): boolean {
    for (const item of jurisdictions) {
        if (item && item === id) {
            return true
        }
    }
    return false
}
*/


function toggleGame(gameid: number, name: string, active: boolean, labels: number[]) {
    const selectedGameLabels: number[] = /* Main.Instance.GamesCentralState.categoryLabelType === 1 ? getTags(gameid) : */ labels
    Logic.Type.New (Main.Instance.GamesCentralState, {
        selectedGameId: gameid === Main.Instance.GamesCentralState.selectedGameId ? 0 : gameid,
        selectedGameName: name,
        selectedGameLabels,
        selectedGameActive: active,
    }) 
}

function getGameIndex(gameid: number) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesList.length; i++) {
        if (Main.Instance.DatabaseDataState.gamesList[i].gameId === gameid) {
            return i
        }
    }
    return -1
}


function updateGame(property: string, gameid: number, value: string | number) {
    const index: number = getGameIndex(gameid)
    if (index !== -1) {
        const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
        gamesList[index][property] = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList})
        DbRoutes.updateGameList(gamesList[index])
    }

}


function updateBoolean(gameid: number, property: string) {
    const index: number = getGameIndex(gameid)
    if (index !== -1) {   
        const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
        if (gamesList[index][property] === undefined) {
            if (property === 'active') {
                gamesList[index][property] = false
            }        
        } else {
            gamesList[index][property] = !gamesList[index][property]
        }

        Logic.Type.New(Main.Instance.DatabaseDataState, {gamesList})
        DbRoutes.updateGameList(gamesList[index])
        
    }
}

// function getTags(gameid: number): number[] {
//     const tags: number[] = []
//     if (Main.Instance.DatabaseDataState.casinoGames) {
//         for (const item of Main.Instance.DatabaseDataState.casinoGames) {
//             if (item.gameId === gameid) {
//                 if (item.tags && item.tags.length > 0) {
//                     for (const Item of item.tags) {
//                     tags.push(Item.id)
//                     }
//                 }
//                 break
//             }
//         }
//     }
//     return tags
// }


function filterDisabledProviders(): GamesList[] {
    const gamesList: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
    const gamesListFiltered: GamesList[] = []
    // Filter the games for disabled game providers
    for (const item of gamesList) {
        if (Main.Instance.DatabaseDataState.gameProviderNew) {
            let Continue = false
            for (const Item of Main.Instance.DatabaseDataState.gameProviderNew) {
                if (Item.providerId === item.providerId) {
                    if (Item.active === false) {
                        Continue = true
                    }
                    break
                }
            }
            if (Continue) {continue}
        }
        gamesListFiltered.push(item)
    }
    return gamesListFiltered
}

export function setCentralGames() {
    const data: GamesList[] = filterDisabledProviders()
    const gamesListCentral: GamesList[] = []

    let tagFilter: boolean = false
    if (Main.Instance.DatabaseDataState.toggleGameLabels) {
        for (const item of Main.Instance.DatabaseDataState.toggleGameLabels) {
            if (item === true) {
                tagFilter = true
                break
            }
        }
    }

    if (data) {
        for (const item of data) {   
            let found: boolean = true     
            if (tagFilter) {        
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.toggleGameLabels.length; i++) {
                    if (Main.Instance.DatabaseDataState.toggleGameLabels[i] === true) {
                        found = false
                        if (item.labels) {
                            for (const Item of item.labels) {
                                if (Item === Main.Instance.DatabaseDataState.gamesListLabels[i].labelId) {
                                    found = true
                                    break
                                }
                            }
                        }
                        if (found === false) {
                            break
                        }
                    }
                }
            }

            if (Main.Instance.GamesCentralState.filterCategory !== 0 && item.categoryId !== Main.Instance.GamesCentralState.filterCategory) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterProvider !== 0 && item.providerId !== Main.Instance.GamesCentralState.filterProvider) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterTestGame === 1 && item.tested !== true) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterTestGame === 2 && item.tested !== false) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterActive === 1 && item.active !== true) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterActive === 2 && item.active !== false) {
                continue
            }

            if (Main.Instance.GamesCentralState.search_string && Main.Instance.GamesCentralState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                const positionId: number = item.gameId ? item.gameId.toString().indexOf(Main.Instance.GamesCentralState.search_string) : -1
                const positionName: number = item.gameName.toLowerCase().indexOf(Main.Instance.GamesCentralState.search_string.toLowerCase())
                if (Main.Instance.SearchState.gameStoreGameId && Main.Instance.SearchState.gameStoreGameName) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionId !== 0 && positionName !== 0) {
                            continue
                        }
                    } else {
                        if (positionId === -1 && positionName === -1) {
                            continue
                        }
                    }
                } else if (Main.Instance.SearchState.gameStoreGameId) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionId !== 0) {
                            continue
                        }
                    } else {
                        if (positionId === -1) {
                            continue
                        }
                    }
                } else if (Main.Instance.SearchState.gameStoreGameName) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionName !== 0) {
                            continue
                        }
                    } else {
                        if (positionName === -1) {
                            continue
                        }
                    }
                }
            }

            if (found) {gamesListCentral.push(item)}
        }
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesListCentral})
}
/*
export function setApprovedGames() {

    const data: GamesList[] = filterDisabledProviders()
    const gamesListApproved: GamesList[] = []

    let tagFilter: boolean = false
    // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
    //     if (Main.Instance.DatabaseDataState.toggleTags) {
    //         for (const item of Main.Instance.DatabaseDataState.toggleTags) {
    //             if (item === true) {
    //                 tagFilter = true
    //                 break
    //             }
    //         }
    //     }
    // } else {
    if (Main.Instance.DatabaseDataState.toggleGameLabels) {
        for (const item of Main.Instance.DatabaseDataState.toggleGameLabels) {
            if (item === true) {
                tagFilter = true
                break
            }
        }
    }
    // }
    

    if (data) {
        for (const item of data) {   

            if (item.tested || !item.betMin || !item.betMax) {
                continue
            }

            let found: boolean = true
            // if (Main.Instance.GamesCentralState.categoryLabelType === 1) {
            //     if (tagFilter) {        
            //         for (let i: number = 0; i < Main.Instance.DatabaseDataState.toggleTags.length; i++) {
            //             if (Main.Instance.DatabaseDataState.toggleTags[i] === true) {
            //                 found = false
            //                 const tags = getTags(item.gameid)
            //                 if (tags) {
            //                     for (const Item of tags) {
            //                         if (Item === Main.Instance.DatabaseDataState.tags[i].id) {
            //                             found = true
            //                             break
            //                         }
            //                     }
            //                 }
            //                 if (found === false) {
            //                     break
            //                 }
            //             }
            //         }
            //     }
            // } else {
            if (tagFilter) {        
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.toggleGameLabels.length; i++) {
                    if (Main.Instance.DatabaseDataState.toggleGameLabels[i] === true) {
                        found = false
                        if (item.labels) {
                            for (const Item of item.labels) {
                                if (Item === Main.Instance.DatabaseDataState.gamesListLabels[i].labelId) {
                                    found = true
                                    break
                                }
                            }
                        }
                        if (found === false) {
                            break
                        }
                    }
                }
            }
            // }
            

            if (Main.Instance.GamesCentralState.filterCategory !== 0 && /*Main.Instance.GamesCentralState.categoryLabelType === 1 ? getStrapiCategoryId(item.gameid) : item.categoryId !== Main.Instance.GamesCentralState.filterCategory) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterProvider !== 0 && item.providerId !== Main.Instance.GamesCentralState.filterProvider) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterActive === 1 && item.active !== true) {
                continue
            }

            if (Main.Instance.GamesCentralState.filterActive === 2 && item.active !== false) {
                continue
            }

            if (Main.Instance.GamesCentralState.search_string && Main.Instance.GamesCentralState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                const positionId: number = item.gameId ? item.gameId.toString().indexOf(Main.Instance.GamesCentralState.search_string) : -1
                const positionName: number = item.gameName.toLowerCase().indexOf(Main.Instance.GamesCentralState.search_string.toLowerCase())
                if (Main.Instance.SearchState.gameStoreGameId && Main.Instance.SearchState.gameStoreGameName) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionId !== 0 && positionName !== 0) {
                            continue
                        }
                    } else {
                        if (positionId === -1 && positionName === -1) {
                            continue
                        }
                    }
                } else if (Main.Instance.SearchState.gameStoreGameId) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionId !== 0) {
                            continue
                        }
                    } else {
                        if (positionId === -1) {
                            continue
                        }
                    }
                } else if (Main.Instance.SearchState.gameStoreGameName) {
                    if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                        if (positionName !== 0) {
                            continue
                        }
                    } else {
                        if (positionName === -1) {
                            continue
                        }
                    }
                }
            }

            if (found) {gamesListApproved.push(item)}
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesListApproved})
}
*/

