import * as React from 'react'
import ReactTable from 'react-table'
import { Main } from 'src/Logic/Main'
import * as Assets from '../../Assets'

interface State {
    mode?: boolean
}

export class LoadedResources extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
        }
    }
    public render() {
        return ( 
        <div>
            {Main.Instance.LoadingResourcesState.loadedResources && Main.Instance.LoadingResourcesState.loadedResources !== [] ?
            <ReactTable
                data = {Main.Instance.LoadingResourcesState.loadedResources}
                columns = {[
                    {width: 100, headerClassName: 'th-left', Header: Assets.Date, accessor: 'timestamp', Cell: (row) => row.value.substring(0,9)},
                    {width: 100, headerClassName: 'th-left', Header: Assets.Time, accessor: 'timestamp', Cell: (row) => row.value.substring(11,19)},
                    {width: 150, headerClassName: 'th-left', Header: Assets.Environment, accessor: 'environment'},
                    {width: 150, headerClassName: 'th-left', Header: 'Build', accessor: 'build'},
                    {width: 200, headerClassName: 'th-left', Header: 'Socket', accessor: 'socket'},
                    {headerClassName: 'th-left', Header: Assets.Location, accessor: 'location'},
                    {width: 200, headerClassName: 'th-left', Header: 'Route', accessor: 'route'},
                    {width: 200, headerClassName: 'th-left', Header: 'Input', accessor: 'input'},
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                defaultPageSize = {10}
                defaultSorted={[
                    {
                      id: 'timestamp',
                      desc: true
                    }
                  ]}
            />
            : <div>No data</div>}
        </div>
        )
    }
}