import * as React from 'react'
import { RouterObjects } from '../RouterObjects'
import { MainView } from '../../Views/Main'
import { Main } from '../Main'
// import {PageNotFound} from './PageNotFound'

export function Navigated(props: any): JSX.Element {

  let childs: any[]
  // console.log (Main.Instance.LoginState)
  // console.log (Main.Instance.Location)
  // console.log (Main.Instance.Location)
  if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined && Main.Instance.LoginState.Verified && Main.Instance.Location !== '/login') {
    childs = Object.keys(RouterObjects).map((key) => {
      if(Main.Instance.Location.split('/')[1] == RouterObjects[key].path.replace('/','')) {
        const SelectedChilds = Object.keys(RouterObjects[key].children).map((childKey) => {
          if(Main.Instance.Location.split('/')[2] == RouterObjects[key].children[childKey].path.replace('/','')) {
            return React.createElement(RouterObjects[key].children[childKey].component)
          } else {
            return null
          }
        })
        return React.createElement(RouterObjects[key].component, props, SelectedChilds)
      } else {
        return null
      }
    })

    childs = childs.filter(Boolean)

    // console.log (childs)
    return React.createElement(MainView, props, childs)
  } else {
    return React.createElement(RouterObjects['login'].component, props)
  }
}
