import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
// import {getRewardShopItems} from './SiteSettingsTable'
import {isUserSiteOwner, isUserOperator, isUserContractualPartner, isUserGameProvider, isUserAdmin, getTranslationBody, getTranslationHeader} from '../../../Logic/Utils'
import {/*FormInput, , RadioButton3,*/ FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {ButtonIcon} from '../../Reusables/Button'
import {downScaleCanvas} from '../../../Logic/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-toggle-switch'
import {faSave, faTimes, faRibbon} from '@fortawesome/free-solid-svg-icons'
import {Flag} from '../../Reusables/Flag'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {Option} from '../../../Logic/Database/DatabaseData'
// import * as Picker from 'react-color'
// 
// import fontawesome from '@fortawesome/fontawesome'
// import pack from '@fortawesome/fontawesome-free-solid'
// fontawesome.library.add(pack)
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
/*
function ModeRewardShop() {Logic.Type.New (Main.Instance.FrontendStoreState, {modeRewardShop: !Main.Instance.FrontendStoreState.modeRewardShop})}
function ModeMinLevel() {Logic.Type.New (Main.Instance.FrontendStoreState, {modeMinLevel: !Main.Instance.FrontendStoreState.modeMinLevel})}
function ModeNotify() {Logic.Type.New (Main.Instance.FrontendStoreState, {modeNotify: !Main.Instance.FrontendStoreState.modeNotify})}
function ModeSportsBetting() {Logic.Type.New (Main.Instance.FrontendStoreState, {modeSportsBetting: !Main.Instance.FrontendStoreState.modeSportsBetting})}
*/
function ModeImages() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeImages: !Main.Instance.FrontendStoreState.modeImages,
        modeTranslation: false,
        modePromotions: false,
        modeMariasCorner: false,
        modeBanner: false,
        modeFont: false,
        modeGames: false,
    })
}

function ModeTranslation() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeTranslation: !Main.Instance.FrontendStoreState.modeTranslation,
        modeImages: false,
        modePromotions: false,
        modeMariasCorner: false,
        modeBanner: false,
        modeFont: false,
        modeGames: false,
    })
}

function ModePromotions() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modePromotions: !Main.Instance.FrontendStoreState.modePromotions,
        modeImages: false,
        modeTranslation: false,
        modeMariasCorner: false,
        modeBanner: false,
        modeFont: false,
        modeGames: false,
    })
}

function ModeMariasCorner() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeMariasCorner: !Main.Instance.FrontendStoreState.modeMariasCorner,
        modeImages: false,
        modeTranslation: false,
        modePromotions: false,
        modeBanner: false,
        modeFont: false,
        modeGames: false,
    })
}

function ModeBanner() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeBanner: !Main.Instance.FrontendStoreState.modeBanner,
        modeImages: false,
        modeTranslation: false,
        modePromotions: false,
        modeMariasCorner: false,
        modeFont: false,
        modeGames: false,
    })
}

function ModeFont() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeFont: !Main.Instance.FrontendStoreState.modeFont,
        modeImages: false,
        modeTranslation: false,
        modePromotions: false,
        modeMariasCorner: false,
        modeBanner: false,
        modeGames: false,
    })
}

function ModeGames() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeGames: !Main.Instance.FrontendStoreState.modeGames,
        modeImages: false,
        modeTranslation: false,
        modePromotions: false,
        modeMariasCorner: false,
        modeFont: false,
        modeBanner: false,
    })
}

function SaveData() {
    let proceed: boolean = true

    if (Main.Instance.FrontendStoreState.menus) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.menus.length; i++) {
        if (Main.Instance.FrontendStoreState.menus[i].length === 0) {
            proceed = false
            alert(Assets.menusWithoutTitle)
        }
    }
    }

    if (Main.Instance.FrontendStoreState.groups) {
    for (let i: number = 0; proceed && i < Main.Instance.FrontendStoreState.groups.length; i++) {
        if (Main.Instance.FrontendStoreState.groups[i].length === 0) {
            proceed = false
            alert(Assets.groupsWithoutTitle)
        }
    }
    }

    if (proceed) {DbRoutes.insertData()}
}

function LoadData(callback?:() => void) {
    DbRoutes.loadData(callback)        
}

export function ApplyMenuTypeSelect(i: number, text: string) {
    const array:string[] = Object.assign([], Main.Instance.FrontendStoreState.menus_type)
    array[i] = text
    Logic.Type.New (Main.Instance.FrontendStoreState, {menus_type: array})
}

function ApplyOperatorSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) !== 0) {
        DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(text))
    } else {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: '0'}) 
    }
}

function AddNewImage() {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    images.push (Assets_.zeroPng)
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}

function RemoveImage(which: number) {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    images.splice (which, 1)
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}

function ReplaceImage(image: string, which: number) {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    images[which] = image
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}
/*
function Edit(event, which: number) {
    if (Main.Instance.FrontendStoreState.categories) {
        let category: string[] = Object.assign ([], Main.Instance.FrontendStoreState.category)
        category[which] = event.target.value
        Logic.Type.New (Main.Instance.FrontendStoreState, {category})
    } else {
        let translation: string[] = Object.assign ([], Main.Instance.FrontendStoreState.translation)
        translation[which] = event.target.value
        Logic.Type.New (Main.Instance.FrontendStoreState, {translation})
    }
}
*/
function getImages(): JSX.Element[] {
    let length: number = 0
    if (Main.Instance.FrontendStoreState.images) { length = Math.ceil((Main.Instance.FrontendStoreState.images.length) / 4) }
    if (length < 1) { length = 1 }

    const images: JSX.Element[] = []

    if (Main.Instance.FrontendStoreState.images) {
    for (let i: number = 0; i < length; i++) {
/*        
    let lang: number = Math.floor (i / 3)

    if (Main.Instance.DatabaseDataState.language !== lang) continue 
    const flagSource: number = !Main.Instance.DatabaseDataState.languagesStreamAll 
        || Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined :
        parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[lang]['flag']) - 1
    const flags: string = flagSource === undefined || isNaN(flagSource) || 
        !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
        Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource]['image']
*/
    images.push(
    <div
        key = {i.toString()}
        className = "clearfix light_grey"
        style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative', minHeight: i === 0 ? '90px' : undefined}}
    >
        {/*<img className = "img img responsive" style = {{position: 'absolute', left: '145px', top: '15px', height: '20px', border: '1px solid #333'}} src = {flags}/>*/}
        <h3 className = "fl" style = {{width: '50%'}}>
            {/*lang > 0 && i % 3 === 0 ? 
            <button
                disabled = {!isUserAdmin()}
                title = {'Copy images from default language (English)'}
                onClick = {() => CopyImages(lang)}
                style = {{position: 'absolute', marginLeft: '0px', marginRight: '0px', left: '145px', top: '55px'}}
                className = "btn btn-danger btn-xs"
            >
                {Assets.Copy}
            </button>
            : void 0*/}
            {i === 1 /*&& IsBuildScandibet() || i === 0 && !IsBuildScandibet()*/ ?
            <button
                disabled = {!isUserAdmin()}
                title = {'Add/Delete images only if you know what you are doing!'}
                onClick = {() => AddNewImage()}
                style = {{position: 'absolute', marginLeft: '10px', marginRight: '0px', left: '0px', top: '15px'}}
                className = "btn btn-primary btn-sm"
            >
                {Assets.AddNewImage}
            </button> : 
            i === 0 /*&& IsBuildScandibet()*/ ?
            <span
                style = {{position: 'absolute', top: '15px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                className = "label label-success"
            >
                <i className = {Assets_.faImageClass} style = {{marginRight: '7px'}}/>        
                {Assets.Images}
            </span>
            : void 0}
            {/*i === 2 && IsBuildScandibet() && !isPureScandibet() ? 
            <button
                disabled = {!isUserAdmin()}
                title = {'Deletes all other images except default language. Please use with caution!'}
                onClick = {() => RetainDefaultLanguage()}
                style = {{position: 'absolute', marginLeft: '10px', marginRight: '0px', left: '0px', top: '15px'}}
                className = "btn btn-danger btn-sm"
            >
                {Assets.RetainDefaultLang_}
            </button>
            : void 0 */}
            {/*
            <span className = "fl" style = {{width: '25%', marginTop: i === 0 || i === 1 ? '20px' : '0px'}}>
                {Assets.Images + ' ' + (i + 1) + '/' + length}
            </span>
            */}
            {Main.Instance.FrontendStoreState.images[4 * i] && Main.Instance.FrontendStoreState.images[4 * i].length > 0 ?
            <div
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 1 ? '' : i === 4 ? 'comingsoon' : ''}
                // onClick = {() => i === 4 ? comingSoon(1) : void 0}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 1 ? '3px solid #a00' :*/ '1px solid #999',
                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 0] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 0].length < 150 ? 'now empty'
                    :(Main.Instance.FrontendStoreState.images[4 * i + 0].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}
                <button
                    onClick = {() => RemoveImage(4 * i + 0)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 ?
            <div
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 2 ? '' : i === 4 ? 'comingsoon' : ''}
                // onClick = {() => i === 4 ? comingSoon(2) : void 0}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 2 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',

                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 1] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 1].length < 150 ? 'now empty'
                    :(Main.Instance.FrontendStoreState.images[4 * i + 1].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}

                <button
                    onClick = {() => RemoveImage(4 * i + 1)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 1]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 ?
            <div
                // onClick = {() => i === 4 ? comingSoon(3) : void 0}
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 3 ? '' : i === 4 ? 'comingsoon' : ''}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    float: 'left',
                    marginTop: '-15px',
                    border: /* i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 3 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',
                    padding: '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 2] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 2].length < 150 ? 'now empty'
                    : (Main.Instance.FrontendStoreState.images[4 * i + 2].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}

                <button
                    onClick = {() => RemoveImage(4 * i + 2)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 2]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 ?
            <div
                // onClick = {() => i === 4 ? comingSoon(4) : void 0}
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 4 ? '' : i === 4 ? 'comingsoon' : ''}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 4 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',
                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 3] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 3].length < 150 ? 'now empty'
                    : (Main.Instance.FrontendStoreState.images[4 * i + 3].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}

                <button
                    onClick = {() => RemoveImage(4 * i + 3)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 3]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
        </h3>
        {Main.Instance.FrontendStoreState.images[4 * i + 0] && Main.Instance.FrontendStoreState.images[4 * i + 0].length > 0 ?
        <span style = {{position: 'absolute', left: '50%', top: '12px'}}>
            {Assets.Image + ' ' + (4 * i + 1)}
        </span> : void 0}
        {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 ?
        <span style = {{position: 'absolute', left: '62.5%', top: '12px'}}>
            {Assets.Image + ' ' + (4 * i + 2)}
        </span> : void 0}
        {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 ?
        <span style = {{position: 'absolute', left: '75%', top: '12px'}}>
            {Assets.Image + ' ' + (4 * i + 3)}
        </span> : void 0}
        {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 ?
        <span style = {{position: 'absolute', left: '87.5%', top: '12px'}}>
            {Assets.Image + ' ' + (4 * i + 4)}
        </span> : void 0}
        {/*<img id = "imagetemporary" style = {{display: 'none'}}/>
        <canvas id = "canvastemporary" style = {{display: 'none'}}/>*/}

        {Main.Instance.FrontendStoreState.images[4 * i + 0] && Main.Instance.FrontendStoreState.images[4 * i + 0].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            className = "uploadLogoWrapper"
            style = {{width: '12%', marginRight:'0.6666667%'}}
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const scale: number = 1
                    if (scale < 1) { replaceImage(reader.result as string, 4 * i + 0, scale) } else { ReplaceImage(reader.result as string, 4 * i + 0) }
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight:'0.6666667%'}}
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const scale: number = 1                    
                    if (scale < 1) { replaceImage(reader.result as string, 4 * i + 1, scale) } else { ReplaceImage(reader.result as string, 4 * i + 1) }
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => {event.target = null}}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight:'0.6666667%'}}
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const scale: number = 1
                    if (scale < 1) { replaceImage(reader.result as string, 4 * i + 2, scale) } else { ReplaceImage(reader.result as string, 4 * i + 2) }
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight: '0px'}}
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const scale: number = 1
                    if (scale < 1) { replaceImage(reader.result as string, 4 * i + 3, scale) } else { ReplaceImage(reader.result as string, 4 * i + 3) }
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

    </div>)
    }
    }
    return images
}

export function ApplySiteSelect(text:string) {
        
    Logic.Type.New(Main.Instance.FrontendStoreState, {
            site_id:text,
    })

    if (parseInt(text) !== 0) {
            LoadData()
    }
}

function SelectSite(stream: any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetAllLanguages()
        DbRoutes.getLevelsAllBySite(3, stream[0]['id'], false)
        LoadData()
    }
}

export function getTranslations() {
    Logic.Type.New(Main.Instance.FrontendStoreState, {offset: '0', pNum: 0})
}

function FirstPage() {
    Logic.Type.New(Main.Instance.FrontendStoreState,{offset:(0).toString()})
    Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: 0})
}

function PrevPage() {
    if (Main.Instance.FrontendStoreState.pNum > 0) {

        Logic.Type.New(Main.Instance.FrontendStoreState, {
            offset:((Main.Instance.FrontendStoreState.pNum - 1) * 
            Main.Instance.FrontendStoreState.pSize).toString()})

        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: Main.Instance.FrontendStoreState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.FrontendStoreState.pNum + 1) * Main.Instance.FrontendStoreState.pSize < Main.Instance.FrontendStoreState.count) {

        Logic.Type.New(Main.Instance.FrontendStoreState, {
            offset: ((Main.Instance.FrontendStoreState.pNum + 1) * 
            Main.Instance.FrontendStoreState.pSize).toString()})
        
        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: Main.Instance.FrontendStoreState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.FrontendStoreState.count <= Main.Instance.FrontendStoreState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.FrontendStoreState.count === 0) {

            Logic.Type.New(Main.Instance.FrontendStoreState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.FrontendStoreState.count - 1) / Main.Instance.FrontendStoreState.pSize)
            Logic.Type.New(Main.Instance.FrontendStoreState, {
                offset:(npNum * Main.Instance.FrontendStoreState.pSize).toString()})
        }

        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: npNum})
    }
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.FrontendStoreState,{pSize:parseInt(text)})
        FirstPage()
    }
}

export function SiteSettingsBetToday() {
    if (Main.Instance.FrontendStoreState.categories === true) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {categories: false})
    }
    if (!Main.Instance.FrontendStoreState.menu_type) {
        // console.log (Assets.MenuType)
        const menu_type: Option[] = []
        for (const item of Assets.MenuType) { menu_type.push({id:menu_type.length.toString(), name: item}) }
        Logic.Type.New (Main.Instance.FrontendStoreState, {menu_type})
    }
    // console.log ('here')
    // console.log (Main.Instance.FrontendStoreState.logo)
    if (Main.Instance.FrontendStoreState.first_time/* || Main.Instance.FrontendStoreState.subdomain === undefined*/) {
        // console.log ('here1')
        DbRoutes.GetFlags()
        // DbRoutes.getRewardShop()
        // DbRoutes.GetBanner()
        DbRoutes.GetAllLanguages()
        if (isUserGameProvider()) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {first_time: false, group_filter_id: '0'})
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            Logic.Type.New(Main.Instance.FrontendStoreState, {first_time: false, operator_id: Main.Instance.LoginState.entityId})
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        } else { Logic.Type.New(Main.Instance.FrontendStoreState, {first_time: false}) }
    }

    // if (!Main.Instance.DatabaseDataState.FlagsStreamAll) console.log ('no flags')
    // if (!Main.Instance.DatabaseDataState.languagesStreamAll) console.log ('no langs')

    if (!Main.Instance.DatabaseDataState.FlagsStreamAll || !Main.Instance.DatabaseDataState.languagesStreamAll) { return <div/> }
    // console.log (Main.Instance.FrontendStoreState.custom)
/*
    if (Main.Instance.FrontendStoreState.custom !== undefined) {
 
        // Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
        // console.log (Main.Instance.FrontendStoreState.custom)
    }
*/
    return (
    <div className = "clearfix">
        <div className = "clearfix" style = {{marginBottom:'10px'}}>
            <h3 style = {{width: '16.24%'}} className = "level fl ml">{Assets.SiteSettings}</h3>
            {isUserGameProvider() ? 
            <FormSelect
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' : Main.Instance.FrontendStoreState.operator_id}
                level = {true}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                ClassName = "form-control"
                o0 = "1"
                o1 = {Assets.SelectOperator}
                o2 = {Assets.OperatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            />
            : void 0}

            {/*isUserGameProvider() || isUserOperator() || isUserSiteOwner()*/
            isUserContractualPartner() ? 
            <FormSelect
                value = {!Main.Instance.FrontendStoreState.site_id ?
                '0' : 
                Main.Instance.FrontendStoreState.site_id}
                level = {true}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o0 = "1"
                o1 = {Assets.SelectSite}
                o2 = {Assets.SiteQ}

                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0}

            <ButtonIcon
                click = {() => ModeFont()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeFont ?
                    'btn btn-success btn-sm btn-plus fr mr60 mt20' :
                    'btn btn-default btn-sm btn-plus fr mr60 mt20'
                }
                icon = "fa fa-font"
                text = "Fonts"
                description = "Fonts"
            />

            <ButtonIcon
                click = {() => ModeBanner()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeBanner ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                Icon = {faRibbon as IconDefinition}
                text = "Banners"
                description = "Banners"
            />

            <ButtonIcon
                click = {() => ModeGames()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeGames ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = "fa fa-gamepad"
                text = "Games"
                description = "Games"
            />

            <ButtonIcon
                click = {() => ModeMariasCorner()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeMariasCorner ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = "fa fa-female"
                text = "Maria's Corner"
                description = "Maria's Corner"
            />

            <ButtonIcon
                click = {() => ModePromotions()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modePromotions ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = "fa fa-gift"
                text = "Promotions"
                description = "Promotions"
            />


            <ButtonIcon
                click = {() => ModeTranslation()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeTranslation ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = {Assets_.faLanguageClass}
                text = {Assets.Translations}
                description = {Assets.Translations}
            />

            <ButtonIcon
                click = {() => ModeImages()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeImages ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = {Assets_.faImageClass}
                text = {Assets.Images}
                description = {Assets.Images}
            />
            <Flag/>
        </div>

        {(Main.Instance.FrontendStoreState.modeRewardShop || Main.Instance.FrontendStoreState.modeImages || 
        Main.Instance.FrontendStoreState.modeMinLevel || Main.Instance.FrontendStoreState.modeNotify || 
        Main.Instance.FrontendStoreState.modeTranslation || Main.Instance.FrontendStoreState.modeSportsBetting) && isUserAdmin() ?
        <div 
            className = "clearfix test-site-data2 light_grey" 
            style = {{marginTop:'0px !important', paddingTop:'10px'/*, paddingBottom:'10px'*/}}
        >
                <button 
                    className = "fr btn btn-warning btn-sm"
                    style = {{marginBottom: '5px', width: '110px', marginRight: '10px'}}
                    onClick = {() => SaveData()}
                >
                    <FontAwesomeIcon icon = {faSave}/>
                    &nbsp;{Assets.updateData}
                </button>
            
        </div> : void 0}
        {Main.Instance.FrontendStoreState.site_id && parseInt(Main.Instance.FrontendStoreState.site_id) !== 0 ?
        <div className = "test-site-data">
        {Main.Instance.FrontendStoreState.modeImages ?

        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}    
        >
            <button
                onClick = {() => ModeImages()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '10px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

        {getImages()}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modeMariasCorner ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeMariasCorner()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div>
                <div style = {{width: '16.24%', display: 'block', float: 'left', marginTop: '10px'}}>
                    <span
                        style = {{fontSize:'16px', paddingTop:'7px'}}
                        className = "label label-success"
                    >
                        <i className = "fa fa-female" style = {{marginRight: '7px'}}/>        
                        {Assets.MariasCorner}
                    </span>
                </div>
                {getMariaControl()}
            </div>
            {Main.Instance.FrontendStoreState.maria !== -1 ? getMaria() : void 0}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modeBanner ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeBanner()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div className = "clearfix">
                <div style = {{width: '16.24%', display: 'block', float: 'left'}}>
                    <span
                        style = {{fontSize:'16px', paddingTop:'7px'}}
                        className = "label label-success"
                    >
                        <FontAwesomeIcon icon = {faRibbon} style = {{marginRight: '7px'}}/>
                        {Assets.Banners}
                    </span>
                </div>
                {getBannerControl()}
            </div>
            {Main.Instance.FrontendStoreState.banner !== -1 ? getBanner() : void 0}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modeFont ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeFont()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '0px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div className = "clearfix">
                <div style = {{width: '16.24%', display: 'block', float: 'left'}}>
                    <span
                        style = {{fontSize:'16px', paddingTop:'7px'}}
                        className = "label label-success"
                    >
                        <i className = "fa fa-font" style = {{marginRight: '7px'}}/>        
                        {Assets.Fonts}
                    </span>
                </div>
                {getFontControl()}
            </div>
            {Main.Instance.FrontendStoreState.font !== -1 ? getFont() : void 0}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modePromotions ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModePromotions()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div className = "clearfix">
                <div style = {{width: '16.24%', display: 'block', float: 'left'}}>
                    <span
                        style = {{fontSize:'16px', paddingTop:'7px'}}
                        className = "label label-success"
                    >
                        <i className = "fa fa-gift" style = {{marginRight: '7px'}}/>        
                        {'Promotions'}
                    </span>
                </div>
                {getPromotionControl()}    
            </div>
            {Main.Instance.FrontendStoreState.promotion !== -1 ? getPromotion() : void 0}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modeTranslation ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeTranslation()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div>

            <div>
                <div className = "control-panel" style = {{marginBottom: '20px'}}>
                    <div style = {{width: '16.24%', display: 'block', float: 'left'}}>
                        <span
                            style = {{fontSize:'16px', paddingTop:'7px'}}
                            className = "label label-success"
                        >
                            <i className = {Assets_.faLanguageClass} style = {{marginRight: '7px'}}/>        
                            {Assets.Translations}
                        </span>
                    </div>
                    <FormInputNumber
                        description = {Assets.recordsPerPageDesc}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0 mt-5"
                        ClassName = "h26 w60"
                        value = {!Main.Instance.FrontendStoreState.pSize ? '' : Main.Instance.FrontendStoreState.pSize.toString()}  
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHamburgerClass}
                        onChange = {(text) => RecordsPerPageChanged(text)}
                    />

                    <button
                        className = "ml btn btn-primary btn-xs mt-3" 
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage()}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage()}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage()}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage()}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>

                    <button
                        onClick = {() => setCategories(true)} 
                        className = {Main.Instance.FrontendStoreState.categories ? 'btn btn-info btn-xs ml mt-3' : 'btn btn-default btn-xs ml mt-3'}
                    >
                        {Assets.Categories}
                    </button>

                    <button
                        onClick = {() => setCategories(false)}
                        className = {Main.Instance.FrontendStoreState.categories ? 'btn btn-default btn-xs mr0 mt-3' : 'btn btn-info btn-xs mr0 mt-3'}
                    >
                        {Assets.OtherTranslations}
                    </button>

                    {Main.Instance.FrontendStoreState.categories ? void 0 : 
                    <button
                        onClick = {() => addNewTranslation()}
                        className = "btn btn-success btn-xs ml mt-3 mr0"
                    >
                        Add new translation
                    </button>}

                    <span className = "label label-success table-label mt-3">
                        {Assets.records}
                        {Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.count : 0}
                    </span>
                    <span className = "label label-success table-label mt-3">
                        {Assets.showing}
                        {Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.pNum * Main.Instance.FrontendStoreState.pSize + 1 : 0}{' - '}
                        {Main.Instance.FrontendStoreState.count ? ((Main.Instance.FrontendStoreState.pNum + 1) * 
                        Main.Instance.FrontendStoreState.pSize > Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.count :
                        (Main.Instance.FrontendStoreState.pNum + 1) * Main.Instance.FrontendStoreState.pSize) : 0}
                    </span>
                    <span className = "label label-success table-label mt-3">
                        {Assets.page}
                        {Main.Instance.FrontendStoreState.count ? 
                        Main.Instance.FrontendStoreState.pNum + 1 : 0}{' / '}
                        {Main.Instance.FrontendStoreState.count ? 
                        Math.trunc((Main.Instance.FrontendStoreState.count - 1) / Main.Instance.FrontendStoreState.pSize + 1) : 0}                        
                    </span>
                </div>
            </div>
            <table className = "table table-hover" style = {{marginTop: '10px', width: '100%'}}>
                <thead>
                    <tr>
                        {getTranslationHeader(true, true)}
                    </tr>
                </thead>
                <tbody>
                    {getTranslationBody()}
                </tbody>
            </table>
        </div>
        </div> : void 0}   

        </div> : void 0}
    </div>)
}

export function ApplyMenuChange(text:string, i: number) {
    const array:string[] = Object.assign([], Main.Instance.FrontendStoreState.menus)
    array[i] = text        
    Logic.Type.New (Main.Instance.FrontendStoreState, {menus: array})
}

export function enableMenuSettings(i: number) {
    Logic.Type.New (Main.Instance.FrontendStoreState, {menuOpen: i})
}

export function disableMenuSettings() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {menuOpen: 0})
}

export function AddMenu() {
    DbRoutes.addMenu(LoadData)   
}

export function DeleteMenu(i:number) {
    if (i === 0 && Main.Instance.FrontendStoreState.menus.length === 1) {
        alert (Assets.atLeastOneMenuItem)
    } else if (confirm(Assets.sureDeleteMenu + ' "' + Main.Instance.FrontendStoreState.menus[i] + '"?')) {
        DbRoutes.deleteMenu(i, LoadData)                
    }
}
/*
function SelectBanner(language: number) {
    
    if (!Main.Instance.DatabaseDataState.selectLanguage) {
            Logic.Type.New(Main.Instance.DatabaseDataState, {
            selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
    } else {
            Logic.Type.New(Main.Instance.DatabaseDataState, {language, 
            selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
            Logic.Type.New (Main.Instance.FrontendStoreState, {translation_: '', translation_default: '', translation_id: '0'})
            updateTranslation()
            updateCategory()
    }
}
*/
/*
function updateCategory() {
    let category: any[] = []
    let Category2: any[] = []
    category.push ({id: '0', name: Assets.SelectCategory})
    Category2.push ({id: '0', name: Assets.AnyCategory})
    if (Main.Instance.FrontendStoreState.category) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.category.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
                    Main.Instance.FrontendStoreState.category[i].length > 0) {
                    let id: string = (1+Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                    category.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
                    Category2.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Category: category, Category2})
    }
}
*/
function setCategories(categories: boolean) {
    let count: number = categories ? Main.Instance.FrontendStoreState.category ? Main.Instance.FrontendStoreState.category.length : 0 : 
                        Main.Instance.FrontendStoreState.translation ? Main.Instance.FrontendStoreState.translation.length : 0
    count /= Main.Instance.DatabaseDataState.languagesStreamAll.length
    if (categories) { count-- }
    Logic.Type.New (Main.Instance.FrontendStoreState, {categories, offset: '0', pNum: 0, count})
}

function addNewTranslation() {
    if (Main.Instance.DatabaseDataState.languages) {
        const translation = Object.assign([], Main.Instance.FrontendStoreState.translation)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++) { translation.push('') }
        Logic.Type.New (Main.Instance.FrontendStoreState, {translation})
    }
}
/*
function RetainDefaultLanguage() {
    if (confirm('Do you really want to retain just images of the default language (English)?')) {
        const Images: number = 12
        let images_source: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
        const images: string[] = []
        for (let i: number = 0; i < Images; i++) images.push(images_source[i])
        Logic.Type.New (Main.Instance.FrontendStoreState, {images})
    }
}
*/
function replaceImage(reader, which, scale) {
    const img = new Image()
    img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext('2d').drawImage(img, 0, 0)
        const resulting_canvas = downScaleCanvas(canvas, scale)
        ReplaceImage(resulting_canvas.toDataURL(), which)
    }
    img.src = reader.result as string
}

function getFontControl(): JSX.Element {
    const fc: JSX.Element[] = []
    fc.push(
        <button
            title = {'Select the Font'}
            style = {{borderRadius: '0px', fontWeight: 700, fontSize: '14px', paddingTop: '3px', paddingBottom: '3px'}}
            key = "-1"
            className = {-1 === Main.Instance.FrontendStoreState.font ? 'btn btn-warning btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {font: -1})}
        >
            <i className = "fa fa-font"/>&nbsp;&nbsp;
            {'Fonts (' + 
            (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.fonts ? Main.Instance.FrontendStoreState.custom.fonts.length : 0) 
            + ')'}
        </button>,
    )
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.fonts) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.fonts.length; i++) {
    fc.push(
        <button
            title = {'Select the Font: ' + Main.Instance.FrontendStoreState.custom.fonts[i].title}            
            style = {{borderRadius: '0px', height: '29px', minWidth: '29px'}}
            key = {i.toString()}
            // Main.Instance.FrontendStoreState.custom.fonts[i].active ? 'btn btn-success btn-sm mr0'
            className = {i === Main.Instance.FrontendStoreState.font ? 'btn btn-primary btn-sm mr0' : 'btn btn-default btn-sm mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {font: i})}
        >
            {Main.Instance.FrontendStoreState.custom.fonts[i].title + 
            (Main.Instance.FrontendStoreState.custom.fonts[i].google && Main.Instance.FrontendStoreState.custom.fonts[i].weight ?
            ' ' + Main.Instance.FrontendStoreState.custom.fonts[i].weight : '')}
        </button>,
        )
    }
    }
        
    fc.push(
        <button
            title = {'Add a new Font'}
            style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
            key = "-2"
            className = "btn btn-success btn-sm mr0"
            onClick = {() => Add('fonts')}
        >
            <i className = "fa fa-plus"/>
        </button>,
    )

    if (Main.Instance.FrontendStoreState.font !== -1) {
        fc.push(
            <button
                title = {'Delete Font'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-3"
                className = "btn btn-danger btn-sm mr0"
                onClick = {() => Delete('fonts', Main.Instance.FrontendStoreState.font)}
            >
                <i className = "fa fa-trash"/>
            </button>,
        )

        fc.push(
            <button
                title = {'Activate/Deactivate Font'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-4"
                className = "btn btn-warning btn-sm mr0"
                onClick = {() => EditSwitch('fonts', Main.Instance.FrontendStoreState.font)}
            >
                <i className = "fa fa-bolt"/>
            </button>,
        )

    }

    return (<div className = "mt-5">{fc}</div>)
}

function getBannerControl(): JSX.Element {
    const bc: JSX.Element[] = []
    bc.push(
        <button
            title = {'Select the Banner'}
            style = {{borderRadius: '0px', fontWeight: 700, fontSize: '14px', paddingTop: '3px', paddingBottom: '3px'}}
            key = "-1"
            className = {-1 === Main.Instance.FrontendStoreState.banner ? 'btn btn-warning btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {banner: -1})}
        >
            <i className = "fa fa-ribbon"/>
            &nbsp;&nbsp;
            {'Banners (' + 
            (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.banners ? Main.Instance.FrontendStoreState.custom.banners.length : 0) 
            + ')'}
        </button>,
    )
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.banners) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.banners.length; i++) {
    bc.push(
        <button
            title = {'Select the Banner: ' + Main.Instance.FrontendStoreState.custom.banners[i].title[Main.Instance.DatabaseDataState.language]}            
            style = {{borderRadius: '0px', height: '29px', minWidth: '29px'}}
            key = {i.toString()}
            className = {i === Main.Instance.FrontendStoreState.banner ? Main.Instance.FrontendStoreState.custom.banners[i].active ? 'btn btn-success btn-sm mr0': 'btn btn-danger btn-sm mr0' : 
                        Main.Instance.FrontendStoreState.custom.banners[i].active ? 'btn btn-default btn-success-border btn-sm mr0' : 'btn btn-default btn-danger-border btn-sm mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {banner: i})}
        >
            {Main.Instance.FrontendStoreState.custom.banners[i].title[Main.Instance.DatabaseDataState.language]}
        </button>,
        )
    }
    }
        
    bc.push(
        <button
            title = {'Add a new Banner'}
            style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
            key = "-2"
            className = "btn btn-success btn-sm mr0"
            onClick = {() => Add('banners')}
        >
            <i className = "fa fa-plus"/>
        </button>,
    )

    if (Main.Instance.FrontendStoreState.banner !== -1) {
        bc.push(
            <button
                title = {'Delete Banner'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-3"
                className = "btn btn-danger btn-sm mr0"
                onClick = {() => Delete('banners', Main.Instance.FrontendStoreState.banner)}
            >
                <i className = "fa fa-trash"/>
            </button>,
        )

        bc.push(
            <button
                title = {'Activate/Deactivate Banner'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-4"
                className = "btn btn-warning btn-sm mr0"
                onClick = {() => EditSwitch('banners', Main.Instance.FrontendStoreState.banner)}
            >
                <i className = "fa fa-bolt"/>
            </button>,
        )

    }

    return (<div className = "mt-5 Control">{bc}</div>)
}

function getMariaControl(): JSX.Element {
    const mc: JSX.Element[] = []
    mc.push(
        <button
            title = {'Select the Maria'}
            style = {{borderRadius: '0px', fontWeight: 700, fontSize: '14px', paddingTop: '3px', paddingBottom: '3px'}}
            key = "-1"
            className = {-1 === Main.Instance.FrontendStoreState.maria ? 'btn btn-warning btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {maria: -1})}
        >
            <i className = "fa fa-female"/>
            &nbsp;&nbsp;
            {'Maria\'s Corner (' + 
            (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.marias ? Main.Instance.FrontendStoreState.custom.marias.length : 0) 
            + ')'}
        </button>,
    )
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.marias) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.marias.length; i++) {
    mc.push(
        <button
            title = {'Select the Maria\'s Corner item: ' + Main.Instance.FrontendStoreState.custom.marias[i].title[Main.Instance.DatabaseDataState.language]}            
            style = {{borderRadius: '0px', height: '29px', minWidth: '29px'}}
            key = {i.toString()}
            className = {i === Main.Instance.FrontendStoreState.maria ? Main.Instance.FrontendStoreState.custom.marias[i].active ? 'btn btn-success btn-sm mr0': 'btn btn-danger btn-sm mr0' : 
                        Main.Instance.FrontendStoreState.custom.marias[i].active ? 'btn btn-default btn-success-border btn-sm mr0' : 'btn btn-default btn-danger-border btn-sm mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {maria: i})}
        >
            {Main.Instance.FrontendStoreState.custom.marias[i].title[Main.Instance.DatabaseDataState.language]}
        </button>,
        )
    }
    }
        
    mc.push(
        <button
            title = {'Add a new Maria\'s Corner item'}
            style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
            key = "-2"
            className = "btn btn-success btn-sm mr0"
            onClick = {() => Add('marias')}
        >
            <i className = "fa fa-plus"/>
        </button>,
    )

    if (Main.Instance.FrontendStoreState.maria !== -1) {
        mc.push(
            <button
                title = {'Delete Maria\'s Corner item'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-3"
                className = "btn btn-danger btn-sm mr0"
                onClick = {() => Delete('marias', Main.Instance.FrontendStoreState.maria)}
            >
                <i className = "fa fa-trash"/>
            </button>,
        )

        mc.push(
            <button
                title = {'Activate/Deactivate Maria\'s Corner item'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-4"
                className = "btn btn-warning btn-sm mr0"
                onClick = {() => EditSwitch('marias', Main.Instance.FrontendStoreState.maria)}
            >
                <i className = "fa fa-bolt"/>
            </button>,
        )
    }

    return (<div className = "mt-5 Control">{mc}</div>)
}


function getPromotionControl(): JSX.Element {
    const pc: JSX.Element[] = []
    pc.push(
        <button
            title = {'Select the Promotion'}
            style = {{borderRadius: '0px', fontWeight: 700, fontSize: '14px', paddingTop: '3px', paddingBottom: '3px'}}
            key = "-1"
            className = {-1 === Main.Instance.FrontendStoreState.promotion ? 'btn btn-warning btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {promotion: -1})}
        >
            <i className = "fa fa-gift"/>
            &nbsp;&nbsp;
            {'Promotions (' + 
            (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.promotions ? Main.Instance.FrontendStoreState.custom.promotions.length : 0) 
            + ')'}
        </button>,
    )
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.promotions) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.promotions.length; i++) {
    pc.push(
        <button
            title = {'Select the Promotion: ' + Main.Instance.FrontendStoreState.custom.promotions[i].title[Main.Instance.DatabaseDataState.language]}            
            style = {{borderRadius: '0px', height: '29px', minWidth: '29px'}}
            key = {i.toString()}
            className = {i === Main.Instance.FrontendStoreState.promotion ? Main.Instance.FrontendStoreState.custom.promotions[i].active ? 'btn btn-success btn-sm mr0': 'btn btn-danger btn-sm mr0' : 
                        Main.Instance.FrontendStoreState.custom.promotions[i].active ? 'btn btn-default btn-success-border btn-sm mr0' : 'btn btn-default btn-danger-border btn-sm mr0'}
            onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {promotion: i})}
        >
            {Main.Instance.FrontendStoreState.custom.promotions[i].title[Main.Instance.DatabaseDataState.language]}
        </button>,
        )
    }
    }

/*
let custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
custom.promotions[i].bannerPosition = 'left-top'
Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
*/
    pc.push(
        <button
            title = {'Add a new Promotion'}
            style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
            key = "-2"
            className = "btn btn-success btn-sm mr0"
            onClick = {() => Add('promotions')}
        >
            <i className = "fa fa-plus"/>
        </button>,
    )

    if (Main.Instance.FrontendStoreState.promotion !== -1) {
        pc.push(
            <button
                title = {'Delete Promotion'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-3"
                className = "btn btn-danger btn-sm mr0"
                onClick = {() => Delete('promotions', Main.Instance.FrontendStoreState.promotion)}
            >
                <i className = "fa fa-trash"/>
            </button>,
        )

        pc.push(
            <button
                title = {'Activate/Deactivate Promotion'}
                style = {{borderRadius: '0px', padding: '0px 7px', fontSize: '18px'}}
                key = "-4"
                className = "btn btn-warning btn-sm mr0"
                onClick = {() => EditSwitch('promotions', Main.Instance.FrontendStoreState.promotion)}
            >
                <i className = "fa fa-bolt"/>
            </button>,
        )

    }

    return (<div className = "mt-5 Control">{pc}</div>)
}

function getFont(): JSX.Element {
    return (
        <div className = "pr" style = {{width: '100%', height: '30px'}}>
            <input
                className = "font0"
                readOnly = {!isUserAdmin()}
                placeholder = "Font name"
                value = {Main.Instance.FrontendStoreState.custom.fonts[Main.Instance.FrontendStoreState.font].title}
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'fonts', Main.Instance.FrontendStoreState.font, 'title')}
            />
            <span className = "font1">google font</span>
            <Switch
                className = "font2"
                onClick = {() => EditSwitch('fonts', Main.Instance.FrontendStoreState.font, 'google')}
                on = {Main.Instance.FrontendStoreState.custom.fonts[Main.Instance.FrontendStoreState.font].google}            
            />
            {Main.Instance.FrontendStoreState.custom.fonts[Main.Instance.FrontendStoreState.font].google ?
            <select
                className = "form-control font3" 
                onChange = {(event) => Edit2(event.target.value, 'fonts', Main.Instance.FrontendStoreState.font, 'weight')}
                value = {Main.Instance.FrontendStoreState.custom.fonts[Main.Instance.FrontendStoreState.font].weight}
            >
                {getFontWeightOptions()}
            </select> : void 0}
        </div>
    )
}


function getBanner(): JSX.Element {
    return (
        <div style = {{minHeight: '100px', position: 'relative'}}>
            <Switch
                className = "table-switch"
                onClick = {() => EditSwitch('banners', Main.Instance.FrontendStoreState.banner)}
                on = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].active}
            />

            <input
                title = "Top offset (px or %)"
                className = "table-offset table-offset-top"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '0px'}}
                value = {Main.Instance.FrontendStoreState.custom.banners ? Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].top : ''}
                placeholder = "T"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'top')}
            />

            <input
                title = "Right offset (px or %)"
                className = "table-offset table-offset-right"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '0px'}}
                value = {Main.Instance.FrontendStoreState.custom.banners ? Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].right : ''}
                placeholder = "R"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'right')}
            />

            <input
                title = "Bottom offset (px or %)"
                className = "table-offset table-offset-bottom"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '0px'}}
                value = {Main.Instance.FrontendStoreState.custom.banners ? Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].bottom : ''}
                placeholder = "B"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'bottom')}
            />

            <input
                title = "Left offset (px or %)"
                className = "table-offset table-offset-left"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '0px'}}
                value = {Main.Instance.FrontendStoreState.custom.banners ? Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].left : ''}
                placeholder = "L"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'left')}
            />

            <table className = "table table-hover" style = {{marginTop: '10px', marginBottom: '0px', width: '100%'}}>
                <thead>
                    <tr>
                        {getTranslationHeader(true, true)}
                    </tr>
                </thead>
                <tbody>
                    {getBannerBody()}
                </tbody>
            </table>
        </div>
    )
}

function getMaria(): JSX.Element {
    return (
        <div style = {{minHeight: '200px', position: 'relative'}}>
            <Switch
                className = "table-switch"
                onClick = {() => EditSwitch('marias', Main.Instance.FrontendStoreState.maria)}
                on = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].active}
            />
            <input
                title = "Path/Link"
                className = "table-input"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '5px'}}
                value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].link}
                placeholder = "Path/Link"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'link')}
            />


            <table className = "table table-hover" style = {{marginTop: '10px', marginBottom: '0px', width: '100%'}}>
                <thead>
                    <tr>
                        {getTranslationHeader(true)}
                    </tr>
                </thead>
                <tbody>
                    {getMariaBody()}
                </tbody>
            </table>
        </div>
    )
}


function getPromotion(): JSX.Element {
    return (
        <div style = {{minHeight: '200px', position: 'relative'}}>
            <Switch
                title = {Assets.Activity}
                className = "table-switch"
                onClick = {() => EditSwitch('promotions', Main.Instance.FrontendStoreState.promotion)}
                on = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].active}
            />
            <input
                title = "Path/Link"
                className = "table-input"
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '5px'}}
                value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].link}
                placeholder = "Path/Link"
                type = "text"
                onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'link')}
            />
            <table className = "table table-hover" style = {{marginTop: '10px', width: '100%', marginBottom: '0px'}}>
                <thead>
                    <tr>
                        {getTranslationHeader(true)}
                    </tr>
                </thead>
                <tbody>
                    {getPromotionBody()}
                </tbody>
            </table>
        </div>
    )
}

function Edit2(value: string, what: string, Which: number, state: string, which?: number, width?: number, height?: number) {
    const custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    if (which !== undefined && width !== undefined && height !== undefined) {
        if (custom[what] === undefined) { custom[what] = [] }
        if (custom[what][Which] === undefined) { custom[what][Which] = {} }
        custom[what][Which][state] = value
        custom[what][Which].size = which.toFixed(1)
        custom[what][Which].width = width
        custom[what][Which].height = height
    } else if (which === undefined) {
        if (custom[what] === undefined) { custom[what] = [] }
        if (custom[what][Which] === undefined) { custom[what][Which] = {} }
        custom[what][Which][state] = value
    } else if (which === -1) {
        if (custom[what] === undefined) { custom[what] = [] }
        if (custom[what][Which] === undefined) { custom[what][Which] = {} }
        custom[what][Which][state] = +value
    } else {
        if (custom[what] === undefined) { custom[what] = [] }
        if (custom[what][Which] === undefined) { custom[what][Which] = {} }
        if (custom[what][Which][state] === undefined) { custom[what][Which][state] = {} }
        custom[what][Which][state][which] = value
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function EditSwitch(what: string, Which: number, state?: string) {
    const custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    if (state === undefined) { custom[what][Which]['active'] = !custom[what][Which]['active'] } else { custom[what][Which][state] = !custom[what][Which][state] }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function Delete(what: string, Which: number) {
    const custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    custom[what].splice(Which, 1)
    if (what === 'banners') { Logic.Type.New (Main.Instance.FrontendStoreState, {custom, banner: -1}) } else if (what === 'promotions') { Logic.Type.New (Main.Instance.FrontendStoreState, {custom, promotion: -1}) } else if (what === 'fonts') { Logic.Type.New (Main.Instance.FrontendStoreState, {custom, font: -1}) } else if (what === 'marias') { Logic.Type.New (Main.Instance.FrontendStoreState, {custom, maria: -1}) }
}

function Add(what: string) {
    const ea: string[] = []
    const ea2: string[] = []
    const ea3: string[] = []

    for (const {} of Main.Instance.DatabaseDataState.languages) {
        ea.push('')
        ea2.push('')
        ea3.push('')
    }

    const custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    if (custom[what] === undefined) { custom[what] = [] }
    if (what === 'banners') { custom[what].push({active: true, image: '', title: ea, font: -1, color: '', rotation: 0, left: '', top: '', right: '', bottom: ''}) } else if (what === 'marias') { custom[what].push({
        active: true,
        image: '',
        maria: -1,
        link: '/',

        title: ea,
        titleColor: '',
        titleFont: '',
        titleBackground: 'transparent',
        titleBorder: 'transparent',

        subtitle: ea2,
        subtitleColor: '',
        subtitleFont: '',
        subtitleBackground: 'transparent',
        subtitleBorder: 'transparent',

        buttonText: ea3,
        buttonTextColor: '',
        buttonTextFont: '',
        buttonBackground: '',
        buttonBorder: '',
    })
 } else if (what === 'fonts') { custom[what].push({italic: true, title: '', weight: 0, google: true}) } else if (what === 'promotions') { custom[what].push({
        active: true,
        banner: -1,
        image: '',
        link: '/',
        bannerPosition: 'left-top',

        title: ea,
        titleColor: '',
        titleFont: '',
        titleBackground: 'transparent',
        titleBorder: 'transparent',

        subtitle: ea2,
        subtitleColor: '',
        subtitleFont: '',
        subtitleBackground: 'transparent',
        subtitleBorder: 'transparent',

        buttonText: ea3,
        buttonTextColor: '',
        buttonTextFont: '',
        buttonBackground: '',
        buttonBorder: '',
    })
 }

    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function getOptions(what: string, state: string, language?: boolean): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push(<option value = "-1" key = "-1">{'Select a ' + what.substring(0, what.length - 1)}</option>)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom[what].length; i++) {
        options.push(
            <option value = {i} key = {i.toString()}>
                {language ? Main.Instance.FrontendStoreState.custom[what][i][state][Main.Instance.DatabaseDataState.language] : 
                what === 'fonts' ?
                Main.Instance.FrontendStoreState.custom[what][i][state] + 
                (Main.Instance.FrontendStoreState.custom[what][i].google && Main.Instance.FrontendStoreState.custom[what][i].weight ? 
                ' ' + Main.Instance.FrontendStoreState.custom[what][i].weight : '') : Main.Instance.FrontendStoreState.custom[what][i][state]}
            </option>,
        )
    }
    return options
}

function getPositioningOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push(<option value = "undefined" key = "0">{'Select position of banner'}</option>)
    options.push(<option value = "left-top" key = "1">{'left top'}</option>)
    options.push(<option value = "right-top" key = "2">{'right top'}</option>)
    options.push(<option value = "left-bottom" key = "3">{'left bottom'}</option>)
    options.push(<option value = "right-bottom" key = "4">{'right bottom'}</option>)
    return options
}

function getFontWeightOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push(<option value = "default" key = "0">{'Font weight?'}</option>)
    if (Main.Instance.FrontendStoreState.custom.fonts[Main.Instance.FrontendStoreState.font].title.toLowerCase() === 'khand') {
        options.push(<option value = "300" key = "3">{'Light - 300'}</option>)
        options.push(<option value = "400" key = "4">{'Regular - 400'}</option>)
        options.push(<option value = "500" key = "5">{'Medium - 500'}</option>)
        options.push(<option value = "600" key = "6">{'Semi-Bold - 600'}</option>)
        options.push(<option value = "700" key = "7">{'Bold - 700'}</option>)    
    } else {
        options.push(<option value = "100" key = "1">{'Thin - 100'}</option>)
        options.push(<option value = "200" key = "2">{'Extra-Light - 200'}</option>)
        options.push(<option value = "300" key = "3">{'Light - 300'}</option>)
        options.push(<option value = "400" key = "4">{'Regular - 400'}</option>)
        options.push(<option value = "500" key = "5">{'Medium - 500'}</option>)
        options.push(<option value = "600" key = "6">{'Semi-Bold - 600'}</option>)
        options.push(<option value = "700" key = "7">{'Bold - 700'}</option>)
        options.push(<option value = "800" key = "8">{'Extra-Bold - 800'}</option>)
        options.push(<option value = "900" key = "9">{'Black - 900'}</option>)
    }
    return options
}

function getBannerBody(): JSX.Element[] {
    let activeLanguages: number = 0
    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.languagesStreamAll) { if (item['active'] === true) { activeLanguages++ } }
    }
    const percentage: string = activeLanguages === 0 ? '100%' : (100 / (activeLanguages + 1)).toFixed(6) + '%'
    // let Percentage: string = activeLanguages === 0 ? '100%' : (100 - 100 / activeLanguages).toFixed(6) + '%'

    const tr: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.custom === undefined || Main.Instance.FrontendStoreState.custom.banners === undefined) { return tr }
    let td: JSX.Element[] = []

    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage, fontWeight: 700, lineHeight: '30px', fontSize: '20px'}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {bannerItem: 0})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.bannerItem === 0 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                TITLE
            </button>
        </td>,
    )

    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].title[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'title', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []
    td.push (
        <td
            style = {{padding: '5px', textAlign: 'center', width: percentage, position: 'relative'}} 
            key = "0"
        >
            <input
                title = {Assets.UploadImage}
                accept = "image/png, image/jpg, image/jpeg, image/gif"
                type = "file" 
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        const img = new Image()
                        img.onload = () => {
                            Edit2(img.src, 'banners', Main.Instance.FrontendStoreState.banner, 'image', img.src.length / 1024, img.width, img.height)
                        }
                        img.src = reader.result as string
                    }
                    reader.readAsDataURL(cloneFile);
                }}
            />
            <div style = {{marginTop: '5px'}}>
                <select
                    title = {Assets.Font}
                    style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                    className = "form-control" 
                    onChange = {(event) => Edit2(event.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'font')}
                    value = {
                        Main.Instance.FrontendStoreState.custom &&
                        Main.Instance.FrontendStoreState.custom.banners &&
                        Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner] &&
                        Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].font ?
                        Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].font : -1
                    }
                >
                    {getOptions('fonts', 'title')}
                </select>
            </div>
            <div style = {{marginTop: '5px'}}>
                <input
                    title = {Assets.FontColor}
                    placeholder = "font color hex code or rgb(a)"
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].color ? 
                            Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].color : ''}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'color')}
                />
            </div>

            <div style = {{marginTop: '5px'}}>
                <input
                    title = "Rotation (in degrees)"
                    placeholder = "Rotation (in degrees)"
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '0px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].rotation ? 
                            Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].rotation : '0'}
                    type = "number"
                    onChange = {(e) => Edit2(e.target.value, 'banners', Main.Instance.FrontendStoreState.banner, 'rotation', -1)}
                />
            </div>
        </td>,
    )
    
    td.push (
        <td
            colSpan = {Main.Instance.DatabaseDataState.languagesStreamAll.length}
            style = {{paddingTop: '8px',paddingBottom: '0px',paddingRight: '8px',paddingLeft: '8px', textAlign: 'center', height: '100px'}}
            key = "1"
        >
            {/*<img className = "img img-responsive" src = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].image}/>*/}

            <div style = {{position: 'relative'}}>
                {Main.Instance.FrontendStoreState.custom &&
                Main.Instance.FrontendStoreState.custom.banners &&
                Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner] &&
                Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].image ?
                <>
                    <img 
                        className = "img img-responsive"
                        style = {{height: '100px', position: 'absolute', left: '0px', top: '20px'}}
                        src = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].image}
                    />

                    <span
                        style = {{
                            position: 'absolute',
                            left: (Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].width / 
                                   Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].height * 100) + 'px',
                            top: '0px',
                        }}
                    >
                        {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].size + ' ' + Assets_.kilobytesShort}
                    </span>

                    <span style = {{position: 'absolute', left: '0px', top: '0px'}}>
                    {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].width}
                        <small>px</small>{' '}<FontAwesomeIcon style = {{fontSize: '10px'}} icon = {faTimes}/>{' '}
                        {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.banner].height}
                        <small>px</small>
                    </span>
                </>
                : void 0}
            </div>            
        </td>,
    )

    tr.push(<tr key = "input">{td}</tr>)

    return tr
}

function getPromotionBody(): JSX.Element[] {
    let activeLanguages: number = 0
    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.languagesStreamAll) { if (item['active'] === true) { activeLanguages++ } }
    }
    const percentage: string = activeLanguages === 0 ? '100%' : (100 / (activeLanguages + 1)).toFixed(6) + '%'
    // let Percentage: string = activeLanguages === 0 ? '100%' : (100 - 100 / activeLanguages).toFixed(6) + '%'

    const tr: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.custom === undefined || Main.Instance.FrontendStoreState.custom.promotions === undefined) { return tr }
    let td: JSX.Element[] = []
    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage, fontWeight: 700, lineHeight: '30px', fontSize: '20px'}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {promotionItem: 0})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.promotionItem === 0 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                TITLE
            </button>
        </td>,
    )
    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].title[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'title', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []
    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage, fontWeight: 700, lineHeight: '30px', fontSize: '20px'}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {promotionItem: 1})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.promotionItem === 1 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                SUBTITLE
            </button>
        </td>,
    )
    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitle[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'subtitle', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []

    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {promotionItem: 2})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.promotionItem === 2 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                BUTTON TEXT
            </button>
        </td>,
    )

    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonText[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'buttonText', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []

    td.push (
        <td
            style = {{padding: '5px', textAlign: 'center', /*background,*/ width: percentage, position: 'relative'}} 
            key = "0"
        >
            <input
                title = "Image upload"
                accept = "image/png, image/jpg, image/jpeg, image/gif"
                type = "file" 
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        const img = new Image()
                        img.onload = () => {
                            Edit2(img.src, 'promotions', Main.Instance.FrontendStoreState.promotion, 'image', img.src.length / 1024, img.width, img.height)
                        }
                        img.src = reader.result as string
                    }
                    reader.readAsDataURL(cloneFile);
                }}
            />
            <div style = {{marginTop: '5px'}}>
                <select
                    title = "Banner selection"
                    style = {{height: '24px', paddingTop: '1px'}}
                    className = "form-control" // bet-today-input
                    onChange = {(event) => Edit2(event.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'banner', -1)}
                    value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].banner}
                >
                    {getOptions('banners', 'title', true)}
                </select>
            </div>
            <div style = {{marginTop: '5px'}}>
                <select
                    title = "Banner position"
                    style = {{height: '24px', paddingTop: '1px'}}
                    className = "form-control" // bet-today-input2 
                    onChange = {(event) => Edit2(event.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'bannerPosition')}
                    value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].bannerPosition}
                >
                    {getPositioningOptions()}
                </select>
            </div>
            {Main.Instance.FrontendStoreState.promotionItem === 0 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>TITLE</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'titleFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.promotions &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion] &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleFont ?
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleColor ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'titleColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Background color"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleBackground ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'titleBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleBorder ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].titleBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'titleBorder')}
                    />
                </div>
            </>
            :
            Main.Instance.FrontendStoreState.promotionItem === 1 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>SUBTITLE</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'subtitleFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.promotions &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion] &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleFont ?
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleColor ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'subtitleColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Font background"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleBackground ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'subtitleBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleBorder ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].subtitleBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'subtitleBorder')}
                    />
                </div>
            </>
            :
            Main.Instance.FrontendStoreState.promotionItem === 2 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>BUTTON TEXT</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'buttonTextFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.promotions &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion] &&
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonTextFont ?
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonTextFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonTextColor ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonTextColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'buttonTextColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Font background"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonBackground ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'buttonBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonBorder ? 
                                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].buttonBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'promotions', Main.Instance.FrontendStoreState.promotion, 'buttonBorder')}
                    />
                </div>
            </>
            : void 0}
        </td>,
    )

    td.push (
        <td
            colSpan = {Main.Instance.DatabaseDataState.languagesStreamAll.length}
            style = {{paddingTop: '8px',paddingBottom: '0px',paddingRight: '8px',paddingLeft: '8px', textAlign: 'center', height: '200px'}} 
            key = "1"
        >
            <div
                style = {{
                    position: 'relative',
                    height: '200px',
                    width: (Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].width / 
                            Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].height * 200) + 'px',
                }}
            >
                {Main.Instance.FrontendStoreState.custom &&
                Main.Instance.FrontendStoreState.custom.promotions &&
                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion] &&
                Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].image ?
                <>
                    <img 
                        className = "img img-responsive"
                        style = {{height: '200px', position: 'absolute', left: '0px', top: '20px'}}
                        src = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].image}
                    />

                    <span
                        style = {{
                            position: 'absolute',
                            left: (Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].width / 
                                   Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].height * 200 - 60) + 'px',
                            top: '0px',
                        }}
                    >
                        {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].size + ' ' + Assets_.kilobytesShort}
                    </span>

                    <span style = {{position: 'absolute', left: '0px', top: '0px'}}>
                        {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].width}
                        <small>px</small>{' '}<FontAwesomeIcon style = {{fontSize: '10px'}} icon = "times"/>{' '}
                        {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].height}
                        <small>px</small>
                    </span>

                </>
                : void 0}
                {Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.banners &&
                Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].banner] 
                ?
                <img
                    style = {{position: 'absolute'}}
                    className = {Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].bannerPosition}
                    src = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.custom.promotions[Main.Instance.FrontendStoreState.promotion].banner].image}
                /> : void 0}
            </div>
        </td>,
    )

    tr.push(<tr key = "input">{td}</tr>)

    return tr
}

function getMariaBody(): JSX.Element[] {
    let activeLanguages: number = 0
    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.languagesStreamAll) { if (item['active'] === true) { activeLanguages++ } }
    }
    const percentage: string = activeLanguages === 0 ? '100%' : (100 / (activeLanguages + 1)).toFixed(6) + '%'
    // let Percentage: string = activeLanguages === 0 ? '100%' : (100 - 100 / activeLanguages).toFixed(6) + '%'

    const tr: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.custom === undefined || Main.Instance.FrontendStoreState.custom.marias === undefined) { return tr }
    let td: JSX.Element[] = []
    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage, fontWeight: 700, lineHeight: '30px', fontSize: '20px'}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {mariaItem: 0})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.mariaItem === 0 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                TITLE
            </button>
        </td>,
    )
    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].title[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'title', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []
    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage, fontWeight: 700, lineHeight: '30px', fontSize: '20px'}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {mariaItem: 1})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.mariaItem === 1 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                SUBTITLE
            </button>
        </td>,
    )
    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitle[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'subtitle', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []

    td.push (
        <td style = {{padding: '5px', textAlign: 'left', width: percentage}} key = "-1">
            <button
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {mariaItem: 2})}
                style = {{fontWeight: 700, lineHeight: '16px', fontSize: '16px'}}
                className = {Main.Instance.FrontendStoreState.mariaItem === 2 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
            >
                BUTTON TEXT
            </button>
        </td>,
    )

    for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
        const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        td.push (
            <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                <input
                    readOnly = {!isUserAdmin()}
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonText[j]}
                    type = "text"
                    onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'buttonText', j)}
                />
            </td>,
        )
    }
    tr.push(<tr key = "title">{td}</tr>)

    td = []

    td.push (
        <td
            style = {{padding: '5px', textAlign: 'center', /*background,*/ width: percentage, position: 'relative'}} 
            key = "0"
        >
            <input
                title = "Image upload"
                accept = "image/png, image/jpg, image/jpeg, image/gif"
                type = "file" 
                readOnly = {!isUserAdmin()}
                style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        const img = new Image()
                        img.onload = () => {
                            Edit2(img.src, 'marias', Main.Instance.FrontendStoreState.maria, 'image', img.src.length / 1024, img.width, img.height)
                        }
                        img.src = reader.result as string
                    }
                    reader.readAsDataURL(cloneFile);
                }}
            />
{/*
            <div style = {{marginTop: '5px'}}>
                <select
                    title = "Banner selection"
                    style = {{height: '24px', paddingTop: '1px'}}
                    className = "form-control" // bet-today-input
                    onChange = {(event) => Edit2(event.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'banner', -1)}
                    value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].banner}
                >
                    {getOptions('banners', 'title', true)}
                </select>
            </div>

            <div style = {{marginTop: '5px'}}>
                <select
                    title = "Banner position"
                    style = {{height: '24px', paddingTop: '1px'}}
                    className = "form-control" // bet-today-input2 
                    onChange = {(event) => Edit2(event.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'bannerPosition')}
                    value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].bannerPosition}
                >
                    {getPositioningOptions()}
                </select>
            </div>
*/}
            {Main.Instance.FrontendStoreState.mariaItem === 0 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>TITLE</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'titleFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.marias &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria] &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleFont ?
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleColor ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'titleColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Background color"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleBackground ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'titleBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleBorder ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].titleBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'titleBorder')}
                    />
                </div>
            </>
            :
            Main.Instance.FrontendStoreState.mariaItem === 1 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>SUBTITLE</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'subtitleFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.marias &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria] &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleFont ?
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleColor ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'subtitleColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Font background"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleBackground ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'subtitleBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleBorder ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].subtitleBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'subtitleBorder')}
                    />
                </div>
            </>
            :
            Main.Instance.FrontendStoreState.mariaItem === 2 ?
            <>
                <div style = {{marginTop: '5px', textAlign: 'left', fontSize: '20px', lineHeight: '20px', fontWeight: 700, marginLeft: '5px'}}>BUTTON TEXT</div>
                <div style = {{marginTop: '5px'}}>
                    <select
                        title = {Assets.Font}
                        style = {{height: '24px', paddingTop: '1px', width: '100%'}}
                        className = "form-control" 
                        onChange = {(event) => Edit2(event.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'buttonTextFont')}
                        value = {
                            Main.Instance.FrontendStoreState.custom &&
                            Main.Instance.FrontendStoreState.custom.marias &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria] &&
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonTextFont ?
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonTextFont : -1
                        }
                    >
                        {getOptions('fonts', 'title')}
                    </select>
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = {Assets.FontColor}
                        placeholder = "font color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonTextColor ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonTextColor : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'buttonTextColor')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Font background"
                        placeholder = "bg color hex code or rgb(a)"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonBackground ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonBackground : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'buttonBackground')}
                    />
                </div>
                <div style = {{marginTop: '5px'}}>
                    <input
                        title = "Border short setting"
                        placeholder = "border short CSS setting"
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonBorder ? 
                                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].buttonBorder : ''}
                        type = "text"
                        onChange = {(e) => Edit2(e.target.value, 'marias', Main.Instance.FrontendStoreState.maria, 'buttonBorder')}
                    />
                </div>
            </>
            : void 0}
        </td>,
    )

    td.push (
        <td
            colSpan = {Main.Instance.DatabaseDataState.languagesStreamAll.length}
            style = {{paddingTop: '8px',paddingBottom: '0px',paddingRight: '8px',paddingLeft: '8px', textAlign: 'center', height: '220px'}} 
            key = "1"
        >
            <div
                style = {{
                    position: 'relative',
                    height: '200px',
                    width: (Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].width / 
                            Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].height * 200) + 'px',
                }}
            >
                {Main.Instance.FrontendStoreState.custom &&
                Main.Instance.FrontendStoreState.custom.marias &&
                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria] &&
                Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].image ?
                <>
                    <img 
                        className = "img img-responsive"
                        style = {{height: '200px', position: 'absolute', left: '0px', top: '20px'}}
                        src = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].image}
                    />

                    <span
                        style = {{
                            position: 'absolute',
                            left: (Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].width / 
                                   Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].height * 200 - 60) + 'px',
                            top: '0px',
                        }}
                    >
                        {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].size + ' ' + Assets_.kilobytesShort}
                    </span>

                    <span style = {{position: 'absolute', left: '0px', top: '0px'}}>
                        {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].width}
                        <small>px</small>{' '}<FontAwesomeIcon style = {{fontSize: '10px'}} icon = "times"/>{' '}
                        {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].height}
                        <small>px</small>
                    </span>

                </>
                : void 0}
                {Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.banners &&
                Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].banner] 
                ?
                <img
                    style = {{position: 'absolute'}}
                    className = {Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].bannerPosition}
                    src = {Main.Instance.FrontendStoreState.custom.banners[Main.Instance.FrontendStoreState.custom.marias[Main.Instance.FrontendStoreState.maria].banner].image}
                /> : void 0}
            </div>
        </td>,
    )

    tr.push(<tr key = "input">{td}</tr>)

    return tr
}