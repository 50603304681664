import * as React from 'react'
import {Logic} from '../../Logic/Type'
import {Main} from '../../Logic/Main'
import {RouterObjects} from '../../Logic/RouterObjects'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
// import * as Config from '../../Logic/Config'
// import axios from 'axios'
import * as DbRoutes from '../../Logic/Database/DbRoutes'
import {LoginInput} from '../Reusables/Inputs'
import {faUnlockAlt} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

export function ForgotPassword() {
    const href: string = window.location.href
    const searchFor: string = '/forgot-password?'
    const searchFor2: string = '&hash='
    const searchFor3: string = 'user='
    if (/* Main.Instance.LoginState === null || */ !Main.Instance.LoginState.Verified && !Main.Instance.LoginState.wrongPassword) {
        const forgotPassword: number = href.indexOf(searchFor)
        const forgotPassword2: number = href.indexOf(searchFor2)
        const Username: string = href.substring(forgotPassword + searchFor.length + searchFor3.length, forgotPassword2)
        if (forgotPassword === -1 || forgotPassword2 === -1) {return <div className = "mt ml">{Assets.l6}</div>}

        // console.log(href.substring (forgotPassword2 + searchFor2.length, href.length))
        Logic.Type.New (Main.Instance.LoginState, {
            wrongPassword: true,
            Username,
            Password: href.substring (forgotPassword2 + searchFor2.length, href.length),
            oldPassword: href.substring (forgotPassword2 + searchFor2.length, href.length)
        })
        DbRoutes.authorizeHash(href.substring (forgotPassword + searchFor.length - 1, href.length))
    }

    // console.log (Main.Instance.LoginState)
    if (Main.Instance.LoginState === null || !Main.Instance.LoginState.Verified) {
        return <div className = "mt ml">{Assets.WaitingForAuthorization}...</div>
    } else {return (
        <div className = "container fluid">
            <div className = "row">
                <div className = "col-sm-4 col-sm-offset-4 login-forgot-password col-xs-12">
                    <img height = "25" className = "mr" src = {Assets_.logo}/>
                    <span style = {{fontSize: '16px'}} >{Assets_.productName[0]}<sup style ={{top: '-1em', fontSize: '13px'}}>{Assets_.productName[1]}</sup>{Assets_.productName[2] + ' Password Reset'}</span>
                    <div className = "mt">
                        <LoginInput
                            type = "password"
                            onChange = {(text) => Logic.Type.New (Main.Instance.LoginState, {Password1: text})}
                            placeholder = {Assets.pNewPassword}
                            Icon = {faUnlockAlt as IconDefinition}
                            border = {Main.Instance.LoginState.Password1.length > 0 ? '2px solid green' : '2px solid red'}
                        />

                        <LoginInput
                            type = "password"
                            onChange = {(text) => Logic.Type.New (Main.Instance.LoginState, {Password2: text})}
                            placeholder = {Assets.pRepeatPassword}
                            Icon = {faUnlockAlt as IconDefinition}
                            border = {Main.Instance.LoginState.Password1.length > 0 && Main.Instance.LoginState.Password2.length > 0 && 
                                Main.Instance.LoginState.Password1 === Main.Instance.LoginState.Password2 ? '2px solid green' : '2px solid red'}
                        />
                    </div>

                    <button
                        disabled = {!(Main.Instance.LoginState.Password1.length > 0 && Main.Instance.LoginState.Password2.length > 0 && 
                            Main.Instance.LoginState.Password1 === Main.Instance.LoginState.Password2)} 
                        onClick = {() => DbRoutes.SetNewPassword(Main.Instance.LoginState.Username, Main.Instance.LoginState.Password1, Main.Instance.LoginState.oldPassword)}
                        className = "btn btn-primary btn-block"
                    >
                        {Assets.SetNewPassword}
                    </button>
                    <button
                        className = "btn btn-warning btn-block"
                        onClick={() => Logic.Type.New(Main.Instance, {Location:RouterObjects['login'].path})}
                    >
                        {Assets.BackToLogin}
                    </button>
                </div>
            </div>
        </div>
    )}
}