import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import axios from 'axios'
import * as Config from '../../../Logic/Config'
import { Logic } from 'src/Logic/Type'
import {Main} from '../../../Logic/Main'

interface State {
    settings: boolean
    global: 'countries'
    league: 'all'|'top'
}

export class OddsportalSettings extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            global: 'countries',
            league: 'top',
            settings: false
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getSettings() {
/*
        axios.get(oddsportal).then((response) => {
            const searchFor: string = '<script type="text/javascript" src="'
            const searchFor1: string = '/res/x/global-'
            const searchFor2: string = '/res/x/bookies-'
            console.log (response.data)
            const globals: number = response.data.indexOf(searchFor + searchFor1)
            const bookies: number = response.data.indexOf(searchFor + searchFor2)
            console.log (globals, bookies)
        })
*/
        axios.get(Config.oddsServerSettings).then((response) => {
            // console.log (response.data)
            if (!response.data) {
                alert('Request for settings failed')
                return
            }
            console.log (response.data.currentLeagues)
            console.log (response.data.currentGlobals)
            Logic.Type.New (Main.Instance.OddsState, {
                clDistinctSport: response.data.currentLeagues && response.data.currentLeagues.distinctSport ? response.data.currentLeagues.distinctSport : [],
                clDistinctRegion: response.data.currentLeagues && response.data.currentLeagues.distinctRegion ? response.data.currentLeagues.distinctRegion : [],
                clDistinctRegion2: response.data.currentLeagues && response.data.currentLeagues.distinctRegion2 ? response.data.currentLeagues.distinctRegion2 : [],
                clDistinctLeague: response.data.currentLeagues && response.data.currentLeagues.distinctLeague ? response.data.currentLeagues.distinctLeague : [],
                clDistinctLeague2: response.data.currentLeagues && response.data.currentLeagues.distinctLeague2 ? response.data.currentLeagues.distinctLeague2 : [],

                currentLeague: response.data.currentLeagues && response.data.currentLeagues.currentLeague ? response.data.currentLeagues.currentLeague : [],
                topLeague: response.data.currentLeagues && response.data.currentLeagues.topLeague ? response.data.currentLeagues.topLeague : [],

                currentCountry: response.data.currentGlobals && response.data.currentGlobals.currentCountry ? response.data.currentGlobals.currentCountry : [],
            })
        })
        this.setState({settings: true})
    }

    public getButtonsGlobal(): JSX.Element[] {
        const bg: JSX.Element[] =[]
        bg.push(<button onClick = {() => this.setState({global: 'countries'})} key = {Assets.Countries} type = "button" className = {this.state.global === 'countries' ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>{Assets.Countries}&nbsp;&nbsp;<span className = "badge badge-light">{Main.Instance.OddsState.currentCountry ? Main.Instance.OddsState.currentCountry.length : '?'}</span></button>)
        return bg
    }

    public getButtonsLeague(): JSX.Element[] {
        const bl: JSX.Element[] =[]
        bl.push(<button onClick = {() => this.setState({league: 'top'})} key = {Assets.Top} type = "button" className = {this.state.league === 'top' ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.Top + ' ' + Assets.Leagues.toLowerCase()}&nbsp;&nbsp;<span className = "badge badge-light">{Main.Instance.OddsState.topLeague ? Main.Instance.OddsState.topLeague.length : '?'}</span></button>)
        bl.push(<button onClick = {() => this.setState({league: 'all'})} key = {Assets.All} type = "button" className = {this.state.league === 'all' ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.All + ' ' + Assets.Leagues.toLowerCase()}&nbsp;&nbsp;<span className = "badge badge-light">{Main.Instance.OddsState.currentLeague ? Main.Instance.OddsState.currentLeague.length : '?'}</span></button>)
        return bl
    }

    public render() {
        return (
            <div>
                <h3>
                    {Assets.OddsPortal + ' ' + Assets.Settings.toLowerCase()}&nbsp;&nbsp;
                    <button className = {this.state.settings ? 'btn btn-danger btn-xs' : 'btn btn-warning btn-xs'} type = "button" onClick = {() => this.getSettings()} disabled = {this.state.settings}>
                        {Assets.Get + ' ' + Assets.Settings.toLowerCase()}
                    </button>
                    {this.getButtonsLeague()}
                </h3>

                <div>
                    {this.getButtonsGlobal()}
                </div>
                <br/>
                {this.state.league === 'top' && Main.Instance.OddsState.topLeague ? <div>{Assets.Top}</div> : void 0}
                {this.state.league === 'all' && Main.Instance.OddsState.currentLeague ? <div>{Assets.All}</div> : void 0}
                <br/>
                {this.state.global === 'countries' && Main.Instance.OddsState.currentCountry ? <div>{Assets.Countries}</div> : void 0}
            </div>
        ) 
    }
}