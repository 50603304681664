import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import ReactTable from 'react-table'
import {FormInput, FormInputNumber, FormInputArea, FormSelect} from '../../Reusables/Inputs'
import * as Assets_ from '../../Assets_'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as TableUtils from '../../Reusables/TableUtils'
import {gotoTop, utilsTimeValidEmptyNotValid, injectSpaces} from '../../../Logic/Utils'
import {GetDate0} from '../PlayerManagement/WithdrawalRequests'
import {/*faCalendarDay, faRuler, */faTrashAlt, faPlus, faHashtag, faSave, faCopy,/*  faBullseye,*/ faSyncAlt, faSort, faClipboardCheck, faForward, faCheck, faTimes, faLightbulb, faMoneyBillWaveAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ButtonIcon} from '../../Reusables/Button'
import {IMissionDefinition2, ObjectiveType} from '../../../Logic/Database/missions/missionModelsNew'
// import {MT} from '../../../Logic/Database/DatabaseData'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
// import { Logic } from '../../../Logic/Type'
import {SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import Switch from 'react-toggle-switch'
import { Logic } from 'src/Logic/Type'
// import { Logic } from 'src/Logic/Type'

interface State {
    pSize: number
    pNum: number
    mission: IMissionDefinition2
    index: number
    selectedFromDate: moment.Moment
    selectedToDate: moment.Moment
    focusedFromDate: boolean
    focusedToDate: boolean
    selectedFromTime: string
    selectedToTime: string
    validationFromTime: boolean
    validationToTime: boolean
    validationTime: boolean
    tasks: boolean
    optIn: boolean
    hiddenRewards: boolean
    taskId: number
    hiddenRewardId: number
    objectiveId: number
    taskName: string
    objectiveName: string
    minAmounts: boolean
    gameFilter: boolean
    includedGameIds: boolean
    includedGameCategories: boolean
}

export class MissionsNew extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            pSize: 10,
            pNum: 1,
            index: -1,
            taskId: 0,
            minAmounts: false,
            gameFilter: false,
            includedGameIds: true,
            includedGameCategories: true,
            hiddenRewardId: 0,
            objectiveId: 0,
            taskName: '',
            objectiveName: '',
            validationFromTime: true,
            validationToTime: true,
            validationTime: true,
            selectedFromDate: null,
            selectedToDate: null,
            focusedFromDate: undefined,
            focusedToDate: undefined,
            selectedFromTime: '00:00:00',
            selectedToTime: '00:00:00',
            tasks: false,
            optIn: false,
            hiddenRewards: false,
            mission: {
                id: 0,
                name: '',
                description: '',
                missionOrder: 0,
                activeFrom: 0,
                activeTo: 0,
                repeatEvery: {
                    amount: 0,
                    unit: 'hours'
                },
                maxCompletionCount: 0,
                isConsecutiveMission: false,
                isTaskCompletionConsecutive: false,
                tasks: [],
                hiddenRewards: [],
                optIn: {
                    required: false,
                }
            }
        }
        this.handleEdit = this.handleEdit.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        DbRoutes.wsReinitialize()
    }
    
    public toggleIsConsecutiveMission() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.isConsecutiveMission = !mission.isConsecutiveMission
        this.setState({mission})
    }

    public toggleIsTaskCompletionConsecutive() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.isTaskCompletionConsecutive = !mission.isTaskCompletionConsecutive
        this.setState({mission})
    }

    public isInsert(): boolean {
        return this.state.index === -1
    }

    public SelectObjective(objectiveId: number) {
        if (objectiveId === 0) {
            this.setState({objectiveId, includedGameCategories: true, includedGameIds: true})
            // Logic.Type.New (Main.Instance.MissionState, {csv: objective.toString()})
        } else {
            // console.log (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives)
            // console.log (+Main.Instance.MissionState.csv - 1)
            // console.log (Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[+Main.Instance.MissionState.csv - 1])
            // const hr: string[] = Main.Instance.MissionState.CSV[Main.Instance.MissionState.task_id - 1].objectives[objective - 1].objectiveId.split('.')
            // console.log (hr)
            // const Hr: number = parseInt(hr[hr.length - 1])
            // console.log (Hr)
            // Logic.Type.New (Main.Instance.MissionState, {csv: Hr.toString(), /*task_id: 0,*/ hiddenrewards: '0'})
            let includedGameIds: boolean = true
            let includedGameCategories: boolean = true
            if (this.state.mission.tasks[this.state.taskId - 1].objectives[objectiveId - 1].gameFilter) {
                if (this.state.mission.tasks[this.state.taskId - 1].objectives[objectiveId - 1].gameFilter.excludedGameIds) {
                    if (this.state.mission.tasks[this.state.taskId - 1].objectives[objectiveId - 1].gameFilter.excludedGameIds.length) {
                        includedGameIds = false
                    }
                }
                if (this.state.mission.tasks[this.state.taskId - 1].objectives[objectiveId - 1].gameFilter.excludedGameCategories) {
                    if (this.state.mission.tasks[this.state.taskId - 1].objectives[objectiveId - 1].gameFilter.excludedGameCategories.length) {
                        includedGameCategories = false
                    }
                }
            }
            this.setState({objectiveId, hiddenRewardId: 0, includedGameCategories, includedGameIds})
        }
    
        // Logic.Type.New (Main.Instance.MissionState, {csv: objective.toString()})
    }

    public AddObjective() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.tasks[this.state.taskId - 1].objectives === undefined) {
            mission.tasks[this.state.taskId - 1].objectives = []
        }
/*
        let objectiveId: number = 0
        for (const item of mission.tasks[this.state.taskId - 1].objectives) {
            const hr: string[] = item.objectiveId.split('.')
            const Hr: number = parseInt(hr[hr.length - 1])
            if (Hr > objectiveId) {objectiveId = Hr}
        }
    
        objectiveId++

        const missionId: string = Main.Instance.MissionState.id ? Main.Instance.MissionState.id : getMissionMaxPlus1().toString()
*/
        // IObjectiveDefinition
        mission.tasks[this.state.taskId - 1].objectives.push({
            // objectiveId: missionId + '.' + this.state.taskId + '.' + objectiveId,
            // missionType: 0,
            name: '',
            objectiveType: ObjectiveType.BetsCount,
            roundsNeeded: 0
        })
        this.setState({mission})
    }

    public RemoveObjective() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        // const tasks: any[] = Object.assign([], Main.Instance.MissionState.CSV)
        mission.tasks[this.state.taskId - 1].objectives.splice(this.state.objectiveId - 1, 1)
        this.setState({mission, objectiveId: 0})
    }

    public getObjectives(): JSX.Element[] {
        const objectives: JSX.Element[] = []
        objectives.push (
            <button
                title = {Assets.Objectives}
                key = "-3"
                className = {this.state.objectiveId ? 'btn btn-default btn-sm mr0 ml0 br0' : 'btn btn-success btn-sm mr0 ml0 br0'}
                onClick = {() => this.SelectObjective(0)}
            >
                <i className = {Assets_.faCheckButtonClass}/>&nbsp;{Assets.Objectives}&nbsp;({this.state.mission.tasks[this.state.taskId - 1].objectives.length})
            </button>,
        )    
    
        for (let i: number = 1; i <= this.state.mission.tasks[this.state.taskId - 1].objectives.length; i++) {
            objectives.push (
                <button
                    title = {Assets.SelectAnObjective}
                    key = {i.toString()}
                    className = {i === this.state.objectiveId ? 'btn btn-warning btn-sm mr0 ml0 br0' : 'btn btn-default btn-sm ml0 mr0 br0'}
                    onClick = {() => this.SelectObjective(i)}
                >
                    {this.state.mission.id}.{this.state.taskId}.{i}&nbsp;<small>{this.state.mission.tasks[this.state.taskId - 1].objectives[i - 1].name}</small>
                </button>,
            )

            if (i === this.state.objectiveId) {
                objectives.push (
                    <button
                        key = "-2"
                        title = {Assets.RemoveTheObjective}
                        className = "btn btn-danger btn-sm mr0 ml0 br0"
                        onClick = {() => this.RemoveObjective()}
                    >
                        <i className = {Assets_.faTrashLgButtonClass}/>
                    </button>,
                )
            }
        }
    
        objectives.push (
            <button
                disabled = {this.state.objectiveName.length === 0}
                title = {Assets.AddANewObjective}
                key = "-1"
                className = "btn btn-success btn-sm mr0 ml0 br0"
                onClick = {() => this.AddObjective()}
            >
                <i className = {Assets_.faPlusClass}/>
            </button>,
        )

        objectives.push (
            <input
                style = {{height: '30px'}}
                type = "text"
                title = {Assets.New + ' ' + Assets.Objective.toLowerCase() + ' ' + Assets.Name.toLowerCase()}
                key = "-4"
                value = {this.state.objectiveName}
                onChange = {(e) => this.setState({objectiveName: e.target.value})}
            />
        )
        return objectives
    }
/*
    public SelectRewardInTask(text: string) {
    }

    public SelectObjectiveType(text: string) {
    }

    public NumberOfRoundsChanged(text: string) {
    }

    public BigWinValueChanged(text: string) {
    }

    public TaskCompletedChanged(text: string) {
    }

    public TimesPlayingChanged(text: string) {
    }

    public DaysNeededChanged(text: string) {
    }

    public getMinimumBet(): JSX.Element[] {
        const mb: JSX.Element[] = []
        return mb
    }
*/
    public SelectTask(taskId: number) {
        if (taskId === 0) {
            this.setState ({taskId, hiddenRewardId: 0, objectiveId: 0})
        } else {
/*
            const hr: string[] = Main.Instance.MissionState.CSV[task_id - 1].taskId.split('.')
            // console.log (hr)
            const Hr: number = parseInt(hr[hr.length - 1])
            // console.log (Hr)
            Logic.Type.New (Main.Instance.MissionState, {hiddenrewards: '0', task_id: Hr, csv: '0'})
*/
            this.setState ({taskId, hiddenRewardId: 0, objectiveId: 0})
        }
    }

    public AddTask() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.tasks === undefined) {
            mission.tasks = []
        }
        // we give the id that is one more than highest ID
/*
        let taskId: number = 0
        for (const item of mission.tasls) {
            const hr: string[] = item.taskId.split('.')
            const Hr: number = parseInt(hr[hr.length - 1])
            if (Hr > taskId) {taskId = Hr}
        }
    
        taskId++
*/
        // const missionId: string = Main.Instance.MissionState.id ? Main.Instance.MissionState.id : getMissionMaxPlus1().toString()
        mission.tasks.push ({
            name: this.state.taskName,
            rewardId: 0,
            objectives: []
        })
        this.setState({mission, taskName: ''})
    }

    public RemoveTask() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks.splice(this.state.taskId - 1, 1)
        this.setState({mission, taskId: 0})
    }

    public ChangeRewardInTask(reward: string) {
        // console.log(reward)
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (parseInt(reward) === 0) {
            mission.tasks[this.state.taskId - 1].rewardId = undefined
        } else {
            mission.tasks[this.state.taskId - 1].rewardId = parseInt(reward)
        }
        
        this.setState({mission})
    }

    public ChangeNameInTask(name: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].name = name
        this.setState({mission})
    }

    public getTask(): JSX.Element {
        // mt5
        return (
            <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                <FormInput
                    bg = "#5bc0de"
                    color = "#fff"
                    description = {this.state.mission.tasks[this.state.taskId - 1].objectives ? 
                                   this.state.mission.tasks[this.state.taskId - 1].objectives.length === 1 ? Assets.t1o : 
                                  ' ' + this.state.mission.tasks[this.state.taskId - 1].objectives + ' ' + Assets.oftt :
                                  Assets.Task + ' ' + Assets.Name.toLowerCase()}

                    narrow = {true}
                    // readOnly = {true}
                    value = {/*Assets.Task + ' ' + */this.state.mission.tasks[this.state.taskId - 1].name}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(e) => this.ChangeNameInTask(e)} 
                    placeholder = {Assets.Task + ' ' + Assets.Name.toLowerCase()}
                    icon = {Assets_.faTaskClass}
                />

                <FormSelect
                    rewardFor = {1}
                    title = {Assets.Select + ' ' + Assets.Task.toLowerCase()+ ' ' + Assets.Reward.toLowerCase()}
                    level = {true}
                    mt0 = {true}
                    value = {this.state.mission.tasks[this.state.taskId - 1].rewardId ? this.state.mission.tasks[this.state.taskId - 1].rewardId.toString() : ''}
                    data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => this.ChangeRewardInTask(text)}
                    icon = {Assets_.faRewardClass}
                />
                {this.getObjectives()}
            </div>
        )
    }

    public getTasks(): JSX.Element {
        const tasks: JSX.Element[] = []
        tasks.push(
            <button
                title = {Assets.NoTaskSelected}
                key = "0"
                onClick = {() => this.SelectTask(0)}
                className = {this.state.taskId ? 'btn btn-default btn-sm mr0 ml0 br0 mb5' : 'btn btn-success btn-sm mr0 ml0 br0 mb5'}
            >
                <i className = {Assets_.faTaskClass}/>&nbsp;{Assets.Tasks}&nbsp;({this.state.mission.tasks.length})
            </button>
        )

        if (this.state.mission.tasks) {
            for (let i: number = 1; i <= this.state.mission.tasks.length; i++) {
                tasks.push (
                    <button
                        title = {Assets.SelectATask}
                        key = {i.toString()}
                        className = {i === this.state.taskId ? i === 0 ? 'btn btn-info btn-sm mr0 ml0 br0 mb5' :
                        'btn btn-info btn-sm ml0 mr0 br0 mb5' : i === 0 ? 'btn btn-default btn-sm mr0 ml0 br0 mb5' : 'btn btn-default btn-sm ml0 mr0 br0 mb5'}
                        onClick = {() => this.SelectTask(i)}
                    >
                        {this.state.mission.id}.{i}&nbsp;<small>{this.state.mission.tasks[i-1].name}</small>
                    </button>,
                )

                if (i === this.state.taskId) {
                    tasks.push (
                        <button
                            key = "-2"
                            title = {Assets.RemoveTheTask}
                            className = "btn btn-danger btn-sm mr0 ml0 br0 mb5"
                            onClick = {() => this.RemoveTask()}
                        >
                            <i className = {Assets_.faTrashLgButtonClass}/>
                        </button>,
                    )
                }
            }
        }

        tasks.push (
            <button
                disabled = {this.state.taskName.length === 0}
                title = {Assets.AddANewTask}
                key = "-1"
                className = "btn btn-success btn-sm mr0 ml0 br0 mb5"
                onClick = {() => this.AddTask()}
            >
                <i className = {Assets_.faPlusClass}/>
            </button>,
        )

        tasks.push (
            <input
                className = "mb5"
                style = {{height: '30px'}}
                type = "text"
                title = {Assets.New + ' ' + Assets.Task.toLowerCase() + ' ' + Assets.Name.toLowerCase()}
                key = "-3"
                value = {this.state.taskName}
                onChange = {(e) => this.setState({taskName: e.target.value})}
            />
        )

        tasks.push (
            <div className = "fr dbl ml5px mt2" title = {Assets.is + ' ' + Assets.Task.toLowerCase() + ' ' + Assets.Completion.toLowerCase() + ' ' + Assets.Consecutive.toLowerCase()}>
                <Switch
                    key = "-4"
                    // className = "switch-disabled3"
                    on = {this.state.mission.isTaskCompletionConsecutive}
                    onClick = {() => this.toggleIsTaskCompletionConsecutive()}
                />
            </div>
        )

        tasks.push (
            <FontAwesomeIcon title = {Assets.is + ' ' + Assets.Task.toLowerCase() + ' ' + Assets.Completion.toLowerCase() + ' ' + Assets.Consecutive.toLowerCase()} style = {{fontSize: '20px', margin: '5px 0px'}} className = "fr dbl ml" icon = {faForward}/>
        )
/*
        if (this.state.taskId) {
            tasks.push (
                <button
                    key = "-2"
                    title = {Assets.RemoveTheTask}
                    style = {{borderRadius: '0px'}}
                    className = "btn btn-danger btn-sm mr0 ml0"
                    onClick = {() => this.RemoveTask()}
                >
                    <i className = {Assets_.faTrashLgButtonClass}/>
                </button>,
            )
        }
*/
        return <div className = "clearfix">{tasks}</div>
    }

    public getHiddenRewards(): JSX.Element {
        const hr: JSX.Element[] = []
/*
        hr.push(
            <div key = "firstline" className = "clearfix">
                <div style = {{fontSize: '16px'}} className = "fl label label-success">
                    <i className = {Assets_.faHiddenClass}/>&nbsp;{Assets.HiddenRewards}
                </div>
            </div>
        )
*/
        hr.push(
            <button
                title = {Assets.NoTaskSelected}
                key = "0"
                onClick = {() => this.SelectTask(0)}
                className = {this.state.hiddenRewardId ? 'btn btn-default btn-sm mr0 ml0 br0' : 'btn btn-success btn-sm mr0 ml0 br0'}
            >
                <i className = {Assets_.faHiddenClass}/>&nbsp;{Assets.HiddenRewards}
            </button>
        )
        return <div>{hr}</div>
    }

    public updateName(name: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.name = name
        this.setState({mission})
    }

    public updateOrder(missionOrder: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.missionOrder = missionOrder
        this.setState({mission})
    }

    public updateRepeatEvery(repeatEveryAmount: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.repeatEvery.amount = repeatEveryAmount
        this.setState({mission})
    }

    public updateRepeatEveryUnit(repeatEveryUnit: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.repeatEvery.unit = repeatEveryUnit
        this.setState({mission})
    }

    public updateMaxCompletionCount(maxCompletionCount: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.maxCompletionCount = maxCompletionCount
        this.setState({mission})
    }

    public updateInfo(description: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.description = description
        this.setState({mission})
    }

    public validationTime(selectedFromDate: moment.Moment, selectedToDate: moment.Moment, selectedFromTime: string, selectedToTime: string): boolean {
        let validationTime: boolean = true
        if (selectedFromDate !== null && selectedToDate !== null && selectedFromTime > selectedToTime && selectedFromDate.format(Assets_.dateDisplayFormat) === selectedToDate.format(Assets_.dateDisplayFormat)) {
            validationTime = false
        }
        return validationTime
    }

    public updateFromTime(selectedFromTime: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        const validationFromTime: boolean = utilsTimeValidEmptyNotValid (selectedFromTime)
        const validationTime: boolean = this.validationTime(this.state.selectedFromDate, this.state.selectedToDate, selectedFromTime, this.state.selectedToTime)
        this.setState({mission, selectedFromTime, validationFromTime, validationTime})
    }

    public updateToTime(selectedToTime: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        const validationToTime: boolean = utilsTimeValidEmptyNotValid (selectedToTime)
        const validationTime: boolean = this.validationTime(this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedFromTime, selectedToTime)
        this.setState({mission, selectedToTime, validationToTime, validationTime})
    }

    public setDateFrom(selectedFromDate: moment.Moment) {
        if (selectedFromDate === null) {
            const validationTime: boolean = this.validationTime(null, this.state.selectedToDate, this.state.selectedFromTime, this.state.selectedToTime)
            this.setState({selectedFromDate, validationTime})
        } else if (this.state.selectedToDate === null || this.state.selectedToDate.format(Assets_.dateDisplayFormat) >= selectedFromDate.format(Assets_.dateDisplayFormat)) {
            const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
            const validationTime: boolean = this.validationTime(selectedFromDate, this.state.selectedToDate, this.state.selectedFromTime, this.state.selectedToTime)
            this.setState({mission, selectedFromDate, validationTime})
        }
    }

    public setDateTo(selectedToDate: moment.Moment) {
        if (selectedToDate === null) {
            const validationTime: boolean = this.validationTime(this.state.selectedFromDate, null, this.state.selectedFromTime, this.state.selectedToTime)
            this.setState({selectedToDate, validationTime})
        } else if (this.state.selectedFromDate === null || selectedToDate.format(Assets_.dateDisplayFormat) >= this.state.selectedFromDate.format(Assets_.dateDisplayFormat)) {
            const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
            const validationTime: boolean = this.validationTime(this.state.selectedFromDate, selectedToDate, this.state.selectedFromTime, this.state.selectedToTime)
            this.setState({mission, selectedToDate, validationTime})
        }
    }

    public copyMission(row) {
        const mission: IMissionDefinition2 = Object.assign({}, row.original)
        mission.id = 0
        mission.tasks = []
        mission.hiddenRewards = []
        const selectedFrom: string = mission.activeFrom !== null ? GetDate0(mission.activeFrom) : undefined
        const selectedFromDate: moment.Moment = selectedFrom && selectedFrom.length >= 10 ? moment.default(selectedFrom.substring(0, 10)) : null
        const selectedFromTime: string = selectedFrom && selectedFrom.length >= 19 ? selectedFrom.substring(11, 19) : '00:00:00'

        const selectedTo: string = mission.activeTo !== null ? GetDate0(mission.activeTo) : undefined
        const selectedToDate: moment.Moment = selectedTo && selectedTo.length >= 10 ? moment.default(selectedTo.substring(0, 10)) : null
        const selectedToTime: string = selectedTo && selectedTo.length >= 19 ? selectedTo.substring(11, 19) : '00:00:00'

        this.setState({mission, selectedFromDate, selectedFromTime, selectedToDate, selectedToTime})
        Logic.Type.New(Main.Instance.DatabaseDataState, {missionIdDuplicate: row.original.id})
        

    }

    public handleInsertUpdate() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.name.length === 0) {
            alert (Assets.compulsoryName)
        } else if (mission.missionOrder <= 0) {
            alert (Assets.SpecifyOrderOfTheChances)
        } else if (this.isInsert() && mission.tasks.length > 0) {
            alert (Assets.PleaseSaveChanceFirstThenEditAndAddTasks)
        } else if (!this.state.validationFromTime) {
            alert (Assets.TimestampFromIsMalformed)
        } else if (!this.state.validationToTime) {
            alert (Assets.TimestampToIsMalformed)
        } else if (!this.state.validationTime) {
            alert (Assets.TimestampToShouldBeAfterTimestampFrom)
        } else {
            // we need to calculate activeFrom and activeTo
            // if it doesn't exist, then it is zero
            // console.log(this.state.selectedFromDate)

            if (this.state.selectedFromDate !== null) {
                const m: moment.Moment = moment.default(this.state.selectedFromDate.format(Assets_.dateDisplayFormat) + 'T' + this.state.selectedFromTime + 'Z')
                mission.activeFrom = m.unix()
            } else {
                mission.activeFrom = null
                alert (Assets.compulsory + ' ' + Assets.StartDate)
            }

            if (this.state.selectedToDate !== null) {
                const m: moment.Moment = moment.default(this.state.selectedToDate.format(Assets_.dateDisplayFormat) + 'T' + this.state.selectedToTime + 'Z')
                mission.activeTo = m.unix()
            } else {
                mission.activeTo = null
            }
//          HERE WE NEED TO SPECIFY WHAT WE WANT TO DELETE...
/*
            if (this.state.includedGameIds) {
                mission
            }
            
*/


            this.isInsert() ? DbRoutes.WSinsertMission(mission) : DbRoutes.WSupdateMission(mission)
            this.clearFields()
        }
    }
    

    // public getMissions() {}
/*
    public RecordsPerPageChanged(text: string) {
        if (parseInt(text) > 0) {
            this.setState({pSize: parseInt(text)})
            this.FirstPage()
        }
    }

    public FirstPage() {
        // Logic.Type.New(Main.Instance.MissionState, {offset:(0).toString()})
    
        this.getMissions()
        this.setState({pNum: 1})
    }
    
    public PrevPage() {
        if (this.state.pNum > 1) {

            // Logic.Type.New(Main.Instance.MissionState, {offset:((this.state.pNum) * this.state.pSize).toString()})

            this.getMissions()
            this.setState({pNum: this.state.pNum - 1})
        } else {
            this.LastPage()
        }
    }
    
    public NextPage() {
        if ((this.state.pNum) * this.state.pSize < Main.Instance.DatabaseDataState.missionsNewCount) {
            // Logic.Type.New(Main.Instance.MissionState, {offset: ((this.state.pNum) * this.state.pSize).toString()})
            this.getMissions()
            this.setState({pNum: this.state.pNum + 1})
        } else {
            this.FirstPage()
        }
    }
    
    public LastPage() {
        if (Main.Instance.DatabaseDataState.missionsNewCount <= this.state.pSize) {
            this.FirstPage()
        } else {
            let pNum: number = 0;
    
            if (Main.Instance.DatabaseDataState.missionsNewCount === 0) {
                // Logic.Type.New(Main.Instance.MissionState, {offset: (0).toString()})
            } else {
                pNum = Math.trunc((Main.Instance.DatabaseDataState.missionsNewCount - 1) / this.state.pSize) + 1
                // Logic.Type.New(Main.Instance.MissionState, {offset:(npNum * this.state.pSize).toString()})
            }
    
            this.getMissions()
    
            this.setState({pNum})
        }
    }
*/
    public handleEdit(row: any) { 
        gotoTop()
        let index: number = -1
        if (Main.Instance.DatabaseDataState.missionsNew) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.missionsNew.length; i++) {
                if (Main.Instance.DatabaseDataState.missionsNew[i].id === row.original.id) {
                    index = i
                    break
                }
            }
        }

        if (index !== -1) {
            const mission: IMissionDefinition2 = JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.missionsNew[index]))
            // console.log (mission)
            const selectedFrom: string = mission.activeFrom !== null /* && mission.activeFrom.toString() !== null*/ ? GetDate0(mission.activeFrom) : undefined
            const selectedFromDate: moment.Moment = selectedFrom && selectedFrom.length >= 10 ? moment.default(selectedFrom.substring(0, 10)) : null
            const selectedFromTime: string = selectedFrom && selectedFrom.length >= 19 ? selectedFrom.substring(11, 19) : '00:00:00'

            const selectedTo: string = mission.activeTo !== null /*&& mission.activeTo.toString() !== null*/ ? GetDate0(mission.activeTo) : undefined
            const selectedToDate: moment.Moment = selectedTo && selectedTo.length >= 10 ? moment.default(selectedTo.substring(0, 10)) : null
            const selectedToTime: string = selectedTo && selectedTo.length >= 19 ? selectedTo.substring(11, 19) : '00:00:00'

            this.setState ({index, mission, selectedFromDate, selectedFromTime, selectedToDate, selectedToTime})
        }
    }

    public handleDelete(row: any) {
        if (row) {
            if (confirm(Assets.sureToDelete + ' ' + Assets.Mission.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {
                if (this.state.pNum === (Math.trunc((Main.Instance.DatabaseDataState.missionsNewCount - 2) / this.state.pSize) + 1)) {
                    DbRoutes.removeMission (row.original.id.toString())
                } else {
                    DbRoutes.removeMission (row.original.id.toString())
                }
            }
        }
    }
/*
    public handleCategoryButton(rowDataId: string, i: number) {
        const missionsNew: IMissionDefinition2[] = Object.assign([], Main.Instance.DatabaseDataState.missionsNew)
        for (const item of missionsNew) {
            if (+item.id === +rowDataId) {
                // console.log ('found')
                const tasks: string[] = [] // item.tasks.trim().split(' ')
                for (const Item of item.tasks) {
                    tasks.push(Item.name)
                }
                item['task_id'] = 0 // parseInt(tasks[i]) - 1
                break
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {missionsNew})
    }
*/
/*
    public controlPanel(): JSX.Element {
        return (
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!this.state.pSize ? '' : this.state.pSize.toString()}
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => this.RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => this.FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => this.PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => this.NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => this.LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.DatabaseDataState.missionsNewCount ? Main.Instance.DatabaseDataState.missionsNewCount : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.DatabaseDataState.missionsNewCount ? (this.state.pNum - 1) * 
                this.state.pSize + 1 : 0}{' - '}
                {Main.Instance.DatabaseDataState.missionsNewCount ? 
                ((this.state.pNum) * 
                this.state.pSize > Main.Instance.DatabaseDataState.missionsNewCount ? 
                Main.Instance.DatabaseDataState.missionsNewCount : 
                (this.state.pNum) * this.state.pSize) : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.page}
                {Main.Instance.DatabaseDataState.missionsNewCount ? 
                this.state.pNum : 1}{' / '}
                {Main.Instance.DatabaseDataState.missionsNewCount ? 
                Math.trunc((Main.Instance.DatabaseDataState.missionsNewCount - 1) /
                this.state.pSize + 1) : 0}
            </span>
        </div>
        )
    }
*/

    public firstRow(): JSX.Element {
        return (
            <div className = "clearfix">
            <h3 className = "level fl">{Assets.AddEditChances}</h3>
            {/*isUserSystem() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
                data = {Main.Instance.DatabaseDataState.masterAllStream}
                ClassName = "form-control"
                o1 = {Assets.SelectMaster}
                o2 = {Assets.MasterQ}
                onChange = {(text) => ApplyMasterSelect(text)}
                icon = {Assets_.faMasterClass}
            /> : void 0*/}
    
            {/*isUserSystem() || isUserMaster() || isUserGameProvider() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                        Main.Instance.FrontendStoreState.operator_id}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                label1 = {Assets.OP}
                label2 = {Assets.BO}
                ClassName = "form-control"
                o1 = {selectOperator}
                o2 = {operatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            />
            : void 0*/}
    
            {/*!isUserSubEntity() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o1 = {selectSite}
                o2 = {siteQ}
                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0*/}
        </div>
        )
    }

    public gotoInsertMode() {

        /* if (Main.Instance.MissionState.name === Main.Instance.MissionState.name_saved) {
            this.clearFields() */

        if (JSON.stringify(this.state.mission) === JSON.stringify(Main.Instance.DatabaseDataState.missionsNew[this.state.index])) {
                this.clearFields()
        } else {
            if (confirm(Assets.UnsavedChanges)) {
                this.clearFields() 
            }
        }
    }

    public clearFields() {
        this.setState({
            index: -1,
            taskId: 0,
            minAmounts: false,
            gameFilter: false,
            includedGameIds: true,
            includedGameCategories: true,
            hiddenRewardId: 0,
            objectiveId: 0,
            taskName: '',
            objectiveName: '',
            validationFromTime: true,
            validationToTime: true,
            validationTime: true,
            selectedFromDate: null,
            selectedToDate: null,
            focusedFromDate: undefined,
            focusedToDate: undefined,
            selectedFromTime: '00:00:00',
            selectedToTime: '00:00:00',
            tasks: false,
            hiddenRewards: false,
            mission: {
                id: 0,
                name: '',
                description: '',
                missionOrder: 0,
                activeFrom: 0,
                activeTo: 0,
                repeatEvery: {
                    amount: 0,
                    unit: 'hours'
                },
                maxCompletionCount: 0,
                isConsecutiveMission: false,
                isTaskCompletionConsecutive: false,
                tasks: [],
                hiddenRewards: [],
                optIn: {
                    required: false,
                }
            }
        })
    }

    public getRepeatEveryUnitOptions(): JSX.Element[] {
        const options: JSX.Element[] = []
        const Options: string[] = ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']

        for (const item of Options) {
            options.push(<option key = {item} value = {item}>{item}</option>)
        }

        return options
    }

    public ChangeObjectiveName(name: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].name = name
        this.setState({mission})
    }

    public ChangeObjectiveType(objectiveType: ObjectiveType) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].objectiveType = objectiveType
        this.setState({mission})
    }

    public ChangeObjectiveRoundsNeeded(roundsNeeded: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].roundsNeeded = roundsNeeded
        this.setState({mission})
    }

    public ChangeObjectiveBigWinValue(bigWinValue: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (bigWinValue === 0) {bigWinValue = undefined}
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].bigWinValue = bigWinValue
        this.setState({mission})
    }


    public getObjectiveTypes(): Array<{id: string, name: string}> {
        const ot: Array<{id: string, name: string}> = []
        const keys: string[] = Object.keys(ObjectiveType)
        // console.log (keys)
        for (let i: number = 0; i < keys.length / 2; i++) {
            ot.push({id: keys[i], name: injectSpaces(keys[i + keys.length / 2])})
        }
        return ot
    }

    public AddMinMaxConstraints() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts === undefined) {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts = []
        }
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts.push({code: ''})
        this.setState({mission, minAmounts: true})
    }

    public RemoveMinMaxConstraint(i: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts.splice(i, 1)
        if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts.length === 0) {
            delete (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts)
            this.setState({mission, minAmounts: false})
        } else {
            this.setState({mission, minAmounts: true})
        }
    }

    public ChangeMinMaxConstraintCode(i: number, code: string) {
        let exists: boolean = false
        for (let j: number = 0; j < this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts.length; j++) {
            if (i !== j && this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[j].code === code) {
                exists = true
                break
            }
        }
        if (!exists) {
            const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].code = code
            this.setState({mission})
        }
    }
    
    public ChangeMinMaxConstraintMin(i: number, minValue: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].minValue = minValue === '0' ? undefined : +minValue
        this.setState({mission})
    }

    public ChangeMinMaxConstraintMax(i: number, maxValue: string) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].maxValue = maxValue === '0' ? undefined : +maxValue
        this.setState({mission})
    }

    public getCurrencyOptions(): JSX.Element[] {
        const co: JSX.Element[] = []
        co.push(<option title = {Assets.Select + ' ' + Assets.Currency.toLowerCase()} key = {' '} value = {''}>{Assets.CurrencyShort}?</option>)

        if (Main.Instance.DatabaseDataState.currenciesStream) {
            for (const item of Main.Instance.DatabaseDataState.currenciesStream) {
                co.push(<option title = {item.currencydescription} key = {item.id.toString()} value = {item.code}>{item.code}</option>)
            }
        }
        // console.log (Main.Instance.DatabaseDataState.currencies)
        return co
    }

    public getMinMaxConstraints() {
        const mmc: JSX.Element[] = []
        if (this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts) {
            for (let i: number = 0; i < this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts.length; i++) {
                mmc.push (
                    <select
                        className = "fl dbl ml5px mb3"
                        style = {{height: '21px', background: '#fff', paddingLeft: '2px'}}
                        key = {i + '_0'}
                        value = {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].code}
                        onChange = {(e) => this.ChangeMinMaxConstraintCode(i, e.target.value)}
                    >
                        {this.getCurrencyOptions()}
                    </select>
                )

                mmc.push (<input key = {i + '_1'} title = {Assets.Minimum + ' ' + Assets.Constraint.toLowerCase()} placeholder = {Assets.Min + '.'} className = "fl dbl mb3" style = {{width: '60px', paddingRight: '0px', height: '21px'}} onChange = {(e) => this.ChangeMinMaxConstraintMin(i, e.target.value)} type = "number" value = {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].minValue ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].minValue.toString() : ''} />)
                mmc.push (<input key = {i + '_2'} title = {Assets.Maximum + ' ' + Assets.Constraint.toLowerCase()} placeholder = {Assets.Max + '.'} className = "fl dbl mb3" style = {{width: '60px', paddingRight: '0px', height: '21px'}} onChange = {(e) => this.ChangeMinMaxConstraintMax(i, e.target.value)} type = "number" value = {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].maxValue ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts[i].maxValue.toString() : ''} />)
                mmc.push (<button key = {i + '_3'} title = {Assets.Remove + ' ' + Assets.Constraint.toLowerCase()} style = {{paddingTop: '0px'}} type = "button" onClick = {() => this.RemoveMinMaxConstraint(i)} className = "btn btn-danger btn-xs mr0 fl dbl br0 mb3"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
            }
        }
        return mmc
    }

    public getObjective(): JSX.Element {
        // mt5
        return (
            <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                <div>
                    <FormInput
                        bg = "#f0ad4e"
                        color = "#fff"
                        description = {Assets.Objective + ' ' + Assets.Name.toLowerCase()}
                        narrow = {true}
                        // readOnly = {true}
                        value = {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].name}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(e) => this.ChangeObjectiveName(e)}
                        placeholder = {Assets.Objective + ' ' + Assets.Name.toLowerCase()}
                        icon = {Assets_.faCheckButtonClass}
                    />

                    <FormSelect
                        // rewardFor = {1}
                        title = {Assets.Objective + ' ' + Assets.Type.toLowerCase()}
                        level = {true}
                        mt0 = {true}
                        value = {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].objectiveType.toString()}
                        data = {this.getObjectiveTypes()}
                        ClassName = "form-control"
                        // o1 = {Assets.SelectAnObjective}
                        // o2 = {Assets.SelectAnObjective}
                        onChange = {(text) => this.ChangeObjectiveType(+text)}
                        icon = {Assets_.faSiteClass}
                    />

                    <FormInputNumber
                        description = {Assets.Rounds + ' ' + Assets.Needed.toLowerCase()}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w80"
                        value = {!this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].roundsNeeded ? '0' : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].roundsNeeded.toString()}
                        type = "number" 
                        min = "0"
                        Icon = {faHashtag}
                        onChange = {(text) => this.ChangeObjectiveRoundsNeeded(+text)}
                    />

                    <FormInputNumber
                        description = {Assets.BigWin + ' ' + Assets.Value.toLowerCase()}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w80"
                        value = {!this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].bigWinValue ? '' : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].bigWinValue.toString()}
                        type = "number" 
                        min = "0"
                        Icon = {faMoneyBillWaveAlt}
                        onChange = {(text) => this.ChangeObjectiveBigWinValue(+text)}
                    />

                    <button
                        type = "button"
                        onClick = {() => this.setState({minAmounts: !this.state.minAmounts})}
                        className = {this.state.minAmounts ? 'btn btn-primary btn-sm mr0 br0' : 'btn btn-default btn-sm mr0 br0'}
                    >
                        {Assets.MinMaxConstraints}&nbsp;({this.getNonBlanks(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts)})
                    </button>

                    <button
                        title = {Assets.Add}
                        type = "button"
                        onClick = {() => this.AddMinMaxConstraints()}
                        className = "btn btn-success btn-sm br0"
                    >
                        <FontAwesomeIcon icon = {faPlus}/>
                    </button>

                    <button
                        type = "button"
                        onClick = {() => this.setState({gameFilter: !this.state.gameFilter})}
                        className = {this.state.gameFilter ? 'btn btn-primary btn-sm mr0 br0' : 'btn btn-default btn-sm mr0 br0'}
                    >
                        {Assets.Game + ' ' + Assets.Filter.toLowerCase() + ' ' + Assets.Definition.toLowerCase()}
                    </button>
                    {this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter ?
                    <>
                    {this.state.includedGameIds ?
                    this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds) ?
                    <span style = {{paddingTop: '6px', paddingBottom: '8px', fontSize: '14px'}} className = "label label-success ml5px">{Assets.Included + ' ' + Assets.Game + ' ' + Assets.IDs}&nbsp;({this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds)})</span> : void 0
                    :
                    this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds) ?
                    <span style = {{paddingTop: '6px', paddingBottom: '8px', fontSize: '14px'}} className = "label label-success ml5px">{Assets.Excluded + ' ' + Assets.Game + ' ' + Assets.IDs}&nbsp;({this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds)})</span> : void 0}
                    
                    {this.state.includedGameCategories ?
                    this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories) ?
                    <span style = {{paddingTop: '6px', paddingBottom: '8px', fontSize: '14px'}} className = "label label-success ml5px">{Assets.Included + ' ' + Assets.Game + ' ' + Assets.Categories}&nbsp;({this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories)})</span> : void 0
                    :
                    this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories) ?
                    <span style = {{paddingTop: '6px', paddingBottom: '8px', fontSize: '14px'}} className = "label label-success ml5px">{Assets.Excluded + ' ' + Assets.Game + ' ' + Assets.Categories}&nbsp;({this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories)})</span> : void 0}
                    </>
                    : void 0}

                </div>

                {this.state.minAmounts ?
                <div className = "clearfix mt5">
                    <div className = "label label-primary fl br0" style = {{fontSize: '14px'}}>
                        {Assets.MinMaxConstraints}&nbsp;({this.getNonBlanks(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].minAmounts)})
                    </div>
                    <button
                        title = {Assets.Add}
                        style = {{paddingTop: '0px'}}
                        type = "button"
                        onClick = {() => this.AddMinMaxConstraints()}
                        className = "btn btn-success btn-xs br0 fl dbl mr0"
                    >
                        <FontAwesomeIcon icon = {faPlus}/>
                    </button>
                    {this.getMinMaxConstraints()}
                </div> : void 0}

                {this.state.gameFilter ?
                <div className = "clearfix mt5">
                    <div className = "label label-primary fl mr br0" style = {{fontSize: '14px'}}>
                        {Assets.Game + ' ' + Assets.Filter.toLowerCase() + ' ' + Assets.Definition.toLowerCase()}
                    </div>
                    <button
                        type = "button"
                        style = {{paddingTop: '0px'}}
                        onClick = {() => {
                            if (this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter) {
                                if (this.state.includedGameIds) {
                                    this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds = undefined
                                } else {
                                    this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds = undefined
                                }
                            } 

                            this.setState({includedGameIds: !this.state.includedGameIds})}
                        }
                        className = "btn btn-info btn-xs fl dbl br0 mr0"
                    >
                        {this.state.includedGameIds ? Assets.Included + ' ' + Assets.Game + ' ' + Assets.IDs : Assets.Excluded + ' ' + Assets.Game + ' ' + Assets.IDs}
                        &nbsp;({this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter ? this.state.includedGameIds ? this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds) : this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds) : 0})
                    </button>
                    <button
                        title = {Assets.Add}
                        style = {{paddingTop: '0px'}}
                        type = "button"
                        onClick = {() => this.AddGameIds()}
                        className = "btn btn-success btn-xs br0 fl dbl mr0"
                    >
                        <FontAwesomeIcon icon = {faPlus}/>
                    </button>
                    {this.getGameIds()}
                    <button
                        type = "button"
                        style = {{paddingTop: '0px'}}
                        onClick = {() => {
                            if (this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter) {
                                if (this.state.includedGameCategories) {
                                    this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories = undefined
                                } else {
                                    this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories = undefined
                                }
                            } 
                            this.setState({includedGameCategories: !this.state.includedGameCategories})}
                        }
                        className = "btn btn-info btn-xs fl dbl br0 ml mr0"
                    >
                        {this.state.includedGameCategories ? Assets.Included + ' ' + Assets.Game + ' ' + Assets.Categories : Assets.Excluded + ' ' + Assets.Game + ' ' + Assets.Categories}
                        &nbsp;({this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter ? this.state.includedGameCategories ? this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories) : this.getNonZeros(this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories) : 0})
                    </button>
                    <button
                        title = {Assets.Add}
                        style = {{paddingTop: '0px'}}
                        type = "button"
                        onClick = {() => this.AddGameCategories()}
                        className = "btn btn-success btn-xs br0 fl dbl mr0"
                    >
                        <FontAwesomeIcon icon = {faPlus}/>
                    </button>
                    {this.getGameCategories()}
                </div> : void 0}
            </div>
        )
    }

    public AddGameIds() {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter === undefined) {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter = {}
        }
        if (this.state.includedGameIds) {
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds === undefined) {
                mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds = []
            }
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds.push('0')
        } else {
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds === undefined) {
                mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds = []
            }
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds.push('0')
        }
        this.setState({mission})
    }

    public ChangeGameId(i: number, value: string) {
        const s: string[] = this.state.includedGameIds ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds

        let exists: boolean = false
        for (let j: number = 0; j < s.length; j++) {
            if (i !== j && s[j] === value) {
                exists = true
                break
            }
        }
        if (!exists) {
            const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
            if (this.state.includedGameIds) {
                this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds[i] = value
            } else {
                this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds[i] = value
            }
            this.setState({mission})
        }
    }

    public RemoveGameId(i: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (this.state.includedGameIds) {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds.splice(i, 1)
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds.length === 0) {
                delete (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds)
                this.setState({mission})
            } else {
                this.setState({mission})
            }
        } else {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds.splice(i, 1)
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds.length === 0) {
                delete (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds)
                this.setState({mission})
            } else {
                this.setState({mission})
            }
        }
    }

    public getGameId(): JSX.Element[] {
        const o: JSX.Element[] = []
        o.push (<option key = {Assets.SelectGame} value = {0}>{Assets.Select + ' ' + Assets.Game.toLowerCase()}</option>)
        if (Main.Instance.DatabaseDataState.GamesStream) {
            for (const item of Main.Instance.DatabaseDataState.GamesStream) {
                o.push(<option key = {item.Id.toString()} value = {item.Id.toString()}>{item.Name}</option>)
            }
        }
        return o
    }

    public getGameIds(): JSX.Element[] {
        // console.log (Main.Instance.DatabaseDataState.GamesStream)
        const g: JSX.Element[] = []
        if (this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter) {
            const s: string[] = this.state.includedGameIds ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameIds : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameIds
            if (s) {
                for (let i: number = 0; i < s.length; i++) {
                    g.push (
                        <select
                            className = "fl dbl mb3"
                            style = {{height: '21px', background: '#fff', paddingLeft: '2px'}}
                            key = {i + '_0'}
                            value = {s[i]}
                            onChange = {(e) => this.ChangeGameId(i, e.target.value)}
                        >
                            {this.getGameId()}
                        </select>
                    )
                    g.push (<button key = {i + '_1'} style = {{paddingTop: '0px'}} title = {Assets.Remove} type = "button" onClick = {() => this.RemoveGameId(i)} className = "btn btn-danger btn-xs mr0 fl dbl br0 mb3"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
                }
            }
        }
        return g
    }

    public ChangeGameCategory(i: number, value: string) {
        const s: string[] = this.state.includedGameCategories ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories

        let exists: boolean = false
        for (let j: number = 0; j < s.length; j++) {
            if (i !== j && s[j] === value) {
                exists = true
                break
            }
        }
        if (!exists) {
            const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
            if (this.state.includedGameCategories) {
                this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories[i] = value
            } else {
                this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories[i] = value
            }
            this.setState({mission})
        }
    }

    public getGameCategory(): JSX.Element[] {
        // console.log (Main.Instance.DatabaseDataState.Categories)
        // console.log (Main.Instance.DatabaseDataState._categories)
        const o: JSX.Element[] = []
        o.push (<option key = {Assets.SelectGame} value = {0}>{Assets.Select + ' ' + Assets.Category.toLowerCase()}</option>)
        if (Main.Instance.DatabaseDataState._categories) {
            for (const item of Main.Instance.DatabaseDataState._categories) {
                o.push(<option key = {item['id'].toString()} value = {item['id'].toString()}>{item['name']}&nbsp;({Main.Instance.DatabaseDataState._categories_[+item['id']]})</option>)
            }
        }
        return o
    }
    
    public AddGameCategories() {
        // this.state.includedGameCategories
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter === undefined) {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter = {}
        }
        if (this.state.includedGameCategories) {
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories === undefined) {
                mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories = []
            }
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories.push('0')
        } else {
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories === undefined) {
                mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories = []
            }
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories.push('0')
        }
        this.setState({mission})
    }

    public RemoveGameCategory(i: number) {
        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
        if (this.state.includedGameCategories) {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories.splice(i, 1)
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories.length === 0) {
                delete (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories)
                this.setState({mission})
            } else {
                this.setState({mission})
            }
        } else {
            mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories.splice(i, 1)
            if (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories.length === 0) {
                delete (mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories)
                this.setState({mission})
            } else {
                this.setState({mission})
            }
        }
    }

    public getGameCategories(): JSX.Element[] {
        const g: JSX.Element[] = []
        if (this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter) {
            const s: string[] = this.state.includedGameCategories ? this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.includedGameCategories : this.state.mission.tasks[this.state.taskId - 1].objectives[this.state.objectiveId - 1].gameFilter.excludedGameCategories
            if (s) {
                for (let i: number = 0; i < s.length; i++) {
                    g.push (
                        <select
                            className = "fl dbl mb3"
                            style = {{height: '21px', background: '#fff', paddingLeft: '2px'}}
                            key = {i + '_0'}
                            value = {s[i]}
                            onChange = {(e) => this.ChangeGameCategory(i, e.target.value)}
                        >
                            {this.getGameCategory()}
                        </select>
                    )
                    g.push (<button key = {i + '_1'} style = {{paddingTop: '0px'}} title = {Assets.Remove} type = "button" onClick = {() => this.RemoveGameCategory(i)} className = "btn btn-danger btn-xs mr0 fl dbl br0 mb3"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
                }
            }
        }
        return g
    }

    public top(): JSX.Element {
        // <div className = "clearfix"/>
        return (
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {!this.isInsert() ? 
                                 'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                 'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >        
                    <div className = "create-input">
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <h4>{Main.Instance.DatabaseDataState.missionsNew && !this.isInsert() ? Assets.EditTheChance + ': ' + Main.Instance.DatabaseDataState.missionsNew[this.state.index].name : Assets.AddANewChance}</h4>
    
                            {!this.isInsert() ? 
                            <button
                                title = {Assets.discardChanges} 
                                onClick = {() => this.gotoInsertMode()}
                                className = "btn btn-default btn-sm btn-anything"
                                style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>
                            : void 0}
                        </div>
    
                        <div className = "row admin-users-row">
                            <FormInputNumber
                                description = {Assets.SpecifyOrderOfTheChances}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {!this.state.mission.missionOrder ? '0' : this.state.mission.missionOrder.toString()}  
                                type = "number" 
                                min = "0"
                                Icon = {faSort}
                                onChange = {(text) => this.updateOrder(+text)}
                            />

                            <FormInput
                                description = {Assets.Chance + ' ' + Assets.Name.toLowerCase()}
                                value = {!this.state.mission.name ? '' : this.state.mission.name} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => this.updateName(text)} 
                                placeholder = {Assets.pName} 
                                iconSvg = "clover"
                            />
                            
                            <FormInputNumber
                                description = {Assets.Repeat + ' ' + Assets.Every.toLowerCase()}
                                outerDiv = "rows-per-table input-group fl ml0 pl0"
                                ClassName = "h30 w60"
                                value = {!this.state.mission.repeatEvery || !this.state.mission.repeatEvery.amount ? '0' : this.state.mission.repeatEvery.amount.toString()}  
                                type = "number" 
                                min = "0"
                                Icon = {faSyncAlt}
                                onChange = {(text) => this.updateRepeatEvery(+text)}
                            />

                            <select
                                style = {{width: '85px', borderRadius: '0px', height: '30px'}}
                                title = {Assets.Repeat + ' ' + Assets.Every.toLowerCase() + ' ' + Assets.Unit.toLowerCase()}
                                className = "dbl fl form-control"
                                onChange = {(e) => this.updateRepeatEveryUnit(e.target.value)}
                                value = {!this.state.mission.repeatEvery || !this.state.mission.repeatEvery.unit ? 'hours' : this.state.mission.repeatEvery.unit}
                            >
                                {this.getRepeatEveryUnitOptions()}
                            </select>

                            <FormInputNumber
                                description = {Assets.Max + '. ' + Assets.Completion.toLowerCase() + ' ' + Assets.Count.toLowerCase()}
                                outerDiv = "rows-per-table input-group fl mr ml pl0"
                                ClassName = "h30 w60"
                                value = {!this.state.mission.maxCompletionCount ? '0' : this.state.mission.maxCompletionCount.toString()}  
                                type = "number" 
                                min = "0"
                                Icon = {faClipboardCheck}
                                onChange = {(text) => this.updateMaxCompletionCount(+text)}
                            />
                            <FontAwesomeIcon title = {Assets.is + ' ' + Assets.Consecutive.toLowerCase() + ' ' + Assets.Mission.toLowerCase()} style = {{fontSize: '20px', marginTop: '5px'}} className = "fl dbl ml" icon = {faForward}/>
                            <div className = "fl dbl ml5px mt2" title = {Assets.is + ' ' + Assets.Consecutive.toLowerCase() + ' ' + Assets.Chance.toLowerCase()}>
                                <Switch
                                    className = "switch-disabled3 off-background-grey"
                                    on = {this.state.mission.isConsecutiveMission}
                                    onClick = {() => this.toggleIsConsecutiveMission()}
                                />
                            </div>
                            <FontAwesomeIcon title = {Assets.Active} style = {{fontSize: '20px', marginTop: '5px'}} className = "fl dbl ml" icon = {faLightbulb}/>
                            <div /*id = "datadate0"*/ className = "fl ml5px">
                                <SingleDatePicker
                                    id = "missionsActiveFrom"
                                    placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                                    date = {this.state.selectedFromDate}
                                    onDateChange = {(date) => this.setDateFrom(date)}
                                    focused = {this.state.focusedFromDate}
                                    onFocusChange = {(focused) => this.setState({focusedFromDate: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                />
                            </div>
                            {this.state.selectedFromDate !== null ?
                            <div className = "fl ml">
                                <input
                                    style = {{border: this.state.validationFromTime ? this.state.validationTime ? '' : '#ffa500' : '#f00', background: this.state.validationFromTime ? this.state.validationTime ? '' : '#ffedcc' : '#ffdddd', width: '80px', height: '30px', padding: '1px 5px'}}
                                    type = "text"
                                    maxLength = {8}
                                    value = {this.state.selectedFromTime}
                                    onChange = {(e) => this.updateFromTime(e.target.value)}
                                />
                            </div> : void 0}
                            <div className = "ml fl mt5">-</div>
                            <div /*id = "datadate00"*/ className = "fl ml">
                                <SingleDatePicker
                                    id = "missionsActiveTo"
                                    placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                                    date = {this.state.selectedToDate}
                                    onDateChange = {(date) => this.setDateTo(date)}
                                    focused = {this.state.focusedToDate}
                                    onFocusChange = {(focused) => this.setState({focusedToDate: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                />
                            </div>
                            {this.state.selectedToDate !== null ?
                            <div className = "fl ml">
                                <input
                                    style = {{border: this.state.validationToTime ? this.state.validationTime ? '' : '#ffa500' : '#f00', background: this.state.validationToTime ? this.state.validationTime ? '' : '#ffedcc' : '#ffdddd', width: '80px', height: '30px', padding: '1px 5px'}}
                                    type = "text"
                                    maxLength = {8}
                                    value = {this.state.selectedToTime}
                                    onChange = {(e) => this.updateToTime(e.target.value)}
                                />
                            </div> : void 0}
                            {/*
                            <button
                                style = {{fontSize: '13px', background: !this.state.hiddenRewards && this.state.mission.hiddenRewards && this.state.mission.hiddenRewards.length > 0 ? '#d6edd6' : ''}}
                                title = {Assets.HiddenRewards}
                                className = {this.state.hiddenRewards ? 'dbl fr mr0 btn btn-sm btn-success' : 'dbl fr mr0 btn btn-sm btn-default'}
                                onClick = {() => this.setState({hiddenRewards: !this.state.hiddenRewards})}
                            >
                                <i className = {Assets_.faHiddenClass}/>
                            </button>
                            */}
                            <button
                                style = {{fontSize: '13px', background: !this.state.tasks && this.state.mission.tasks && this.state.mission.tasks.length > 0 ? '#d6edd6' : ''}}
                                title = {Assets.Tasks}
                                className = {this.state.tasks ? 'dbl fr btn btn-sm btn-success mr0' : 'dbl fr btn btn-sm btn-default mr0'}
                                onClick = {() => this.setState({tasks: !this.state.tasks})}
                            >
                                <i className = {Assets_.faTaskClass}/>&nbsp;{Assets.Tasks}
                            </button>
                            <button
                                style = {{fontSize: '13px', background: !this.state.optIn && this.state.mission.optIn && this.state.mission.optIn.required ? '#d6edd6' : ''}}
                                title = {Assets.OptIn}
                                className = {this.state.optIn ? 'dbl fr btn btn-sm btn-success mr' : 'dbl fr btn btn-sm btn-default mr'}
                                onClick = {() => {
                                    const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                    if (!mission.optIn) {
                                        mission.optIn = {required: false}
                                    }
                                    this.setState({optIn: !this.state.optIn, mission})
                                }}
                            >
                                <i className = {Assets_.faChallengeClass}/>&nbsp;{Assets.OptIn}
                            </button>

                        </div>
                        {/*this.state.taskId === 0 && parseInt(Main.Instance.MissionState.hiddenrewards) === 0 ?*/}
                        <div className = "row admin-users-row-area-small">
                            <div className = "register-input textarea-small">
                                <FormInputArea  
                                    value = {!this.state.mission.description ? '' : this.state.mission.description}
                                    ClassName = "form-control register-input"
                                    onChange = {(text) => this.updateInfo(text)}
                                    placeholder = {Assets.ChanceDescriptionOptional}
                                    icon = {Assets_.faCommentingClass}
                                />
                            </div>
                        </div> {/*: void 0*/}

                        {this.state.tasks ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px', padding: '10px', background: '#eee', border: '1px solid #bbb'}}>
                            {this.getTasks()}
                            {this.state.taskId > 0 ?
                            <>
                                {this.getTask()}
                                {this.state.objectiveId > 0 ? this.getObjective() : void 0}
                            </>
                            : void 0}
                        </div> : void 0}
                        {this.state.optIn ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px', padding: '10px', background: '#eee', border: '1px solid #bbb'}}>
                            <span className = "fl dbl mr mt5">  
                                {Assets.OptIn + ' ' + Assets.required}
                            </span>
                            <Switch 
                                className = "fl dbl mr mt2"                               
                                onClick = {() => {
                                    const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                    if (mission.optIn && mission.optIn.required) {
                                        mission.optIn.minLevel = undefined
                                        mission.optIn.maxLevel = undefined
                                        mission.optIn.optinCost = undefined
                                        mission.optIn.players = undefined
                                    } 
                                    
                                    mission.optIn.required = !mission.optIn.required
                                    this.setState({mission})
                                    
                                }}
                                on = {this.state.mission.optIn && this.state.mission.optIn.required ? this.state.mission.optIn.required : false}
                            />
                            {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.required ? 
                            <>
                            <button
                                style = {{borderRadius: '0px'}}
                                title = {Assets.ToggleOptInCost}
                                className = {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.optinCost !== undefined ? 
                                            'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                                onClick = {() => {
                                    const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                    
                                    if (mission.optIn.optinCost === undefined) {
                                        mission.optIn.optinCost = 0
                                    } else {
                                        mission.optIn.optinCost = undefined
                                    }
                                    
                                    this.setState({mission})
                                }}
                            >
                                {Assets.Cost}
                            </button>
                            {/*console.log(this.state.mission)*/}
                            {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.optinCost !== undefined ?
                            <div className= "fl">
                                <FormInputNumber
                                    description = {Assets.optInCost}
                                    ClassName = "h30 w60 pl3 pr0"
                                    value = {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.optinCost ? this.state.mission.optIn.optinCost.toString() : '0'}
                                    type = "number"
                                    min = "0"
                                    onChange = {(text) => {
                                        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                        mission.optIn.optinCost = +text
                                        this.setState({mission})
                                    }}
                                    noIconTextFix = {true}
                                />
                            </div>     
                            : void 0}
                            <button
                                style = {{borderRadius: '0px'}}
                                title = {Assets.Toggle + ' ' + Assets.MinimumLevel}
                                className = {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.minLevel ? 'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                                onClick = {() => {
                                    const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                    if (!mission.optIn.minLevel) {
                                        mission.optIn.minLevel = {level: 1}
                                    } else {
                                        mission.optIn.minLevel = undefined
                                    }
                                    this.setState({mission})
                                }}
                            >
                                {Assets.MinLevel}
                            </button>
                            {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.minLevel && this.state.mission.optIn.minLevel.level !== undefined ?
                            <div className= "fl">    
                                <FormInputNumber
                                    description = {Assets.MinimumLevel}
                                    ClassName = "h30 w60 pl3 pr0"
                                    value = {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.minLevel && this.state.mission.optIn.minLevel.level ?
                                        this.state.mission.optIn.minLevel.level.toString() : '1'}
                                    type = "number"
                                    min = "1"
                                    onChange = {(text) => {
                                        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                        if (+text === 0) {
                                            mission.optIn.minLevel = undefined
                                        } else {
                                            mission.optIn.minLevel.level = +text
                                            if (mission.optIn.maxLevel !== undefined && mission.optIn.maxLevel.level < +text) {
                                                mission.optIn.maxLevel.level = +text
                                            } 
                                        }
                                        
                                        this.setState({mission})
                                    }}
                                    noIconTextFix = {true}
                                />
                            </div>     
                            : void 0}
                            <button
                                style = {{borderRadius: '0px'}}
                                title = {Assets.Toggle + ' ' + Assets.MaximumLevel}
                                className = {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.maxLevel ? 'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                                onClick = {() => {
                                    const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                    if (!mission.optIn.maxLevel) {
                                        mission.optIn.maxLevel = {level: 1}
                                    } else {
                                        mission.optIn.maxLevel = undefined
                                    }
                                    this.setState({mission})
                                }}
                            >
                                {Assets.MaxLevel}
                            </button>
                            {this.state.mission && this.state.mission.optIn && this.state.mission.optIn.maxLevel && this.state.mission.optIn.maxLevel.level !== undefined ?
                            <div className= "fl">    
                                <FormInputNumber
                                    description = {Assets.MaximumLevel}
                                    ClassName = "h30 w60 pl3 pr0"
                                    value = {/* this.state.mission && this.state.mission.optIn && this.state.mission.optIn.maxLevel && this.state.mission.optIn.maxLevel.level ? 
                                        this.state.mission.optIn.minLevel && this.state.mission.optIn.minLevel.level && this.state.mission.optIn.maxLevel.level < this.state.mission.optIn.minLevel.level ?
                                        this.state.mission.optIn.minLevel.level.toString() : this.state.mission.optIn.maxLevel.level.toString() : '1' */
                                        this.state.mission && this.state.mission.optIn && this.state.mission.optIn.maxLevel && this.state.mission.optIn.maxLevel.level ? this.state.mission.optIn.maxLevel.level.toString() : '1'}
                                    type = "number"
                                    min = {this.state.mission.optIn && this.state.mission.optIn.minLevel ? this.state.mission.optIn.minLevel.level.toString() : '1'}
                                    onChange = {(text) => {
                                        const mission: IMissionDefinition2 = Object.assign({}, this.state.mission)
                                        if (+text === 0) {
                                            mission.optIn.maxLevel = undefined
                                        } else {
                                            if (mission.optIn.minLevel !== undefined && +text < mission.optIn.minLevel.level) {
                                                mission.optIn.minLevel.level = +text
                                            } 
                                            mission.optIn.maxLevel.level = +text  
                                        }
                                        
                                        this.setState({mission})
                                    }}
                                    noIconTextFix = {true}
                                />
                            </div>     
                            : void 0}
                            </> : void 0}
                        </div> : void 0}
                        {/*this.state.mission && this.state.mission.optIn && this.state.mission.optIn.maxLevel && this.state.mission.optIn.maxLevel.level ? console.log(this.state.mission.optIn.maxLevel.level) : void 0*/}
                        {/*this.state.mission && this.state.mission.optIn && this.state.mission.optIn.minLevel && this.state.mission.optIn.minLevel.level ? console.log(this.state.mission.optIn.minLevel.level) : void 0*/}
                        {this.state.hiddenRewards ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px', padding: '10px', background: '#eee', border: '1px solid #bbb'}}>
                            {this.getHiddenRewards()}
                        </div> : void 0}

                        {/*this.state.taskId !== 0 ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <FormInput
                                bg = "#5bc0de"
                                color = "#fff"
                                description = {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives ? 
                                            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives.length === 1 ? Assets.t1o : 
                                            ' ' + Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives + ' ' + Assets.oftt :
                                            Assets.Task + ' ' + Assets.Name.toLowerCase()}
            
                                narrow = {true}
                                readOnly = {true}
                                value = {Assets.Task + ' ' + Main.Instance.MissionState.CSV[this.state.taskId - 1].taskId}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {() => void 0} 
                                placeholder = ""
                                icon = {Assets_.faTaskClass}
                            />
            
                            <FormSelect
                                rewardFor = {1}
                                title = "Task reward"
                                level = {true}
                                mt0 = {true}
                                value = {Main.Instance.MissionState.CSV[this.state.taskId - 1].rewardId.toString()}
                                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                                ClassName = "form-control"
                                o1 = {Assets.SelectReward}
                                o2 = {Assets.SelectReward}
                                onChange = {(text) => this.SelectRewardInTask(text)}
                                icon = {Assets_.faRewardClass}
                            />
                            {this.getObjectives()}
                        </div> : void 0*/}
            
                        {/*this.state.taskId !== 0 && parseInt(Main.Instance.MissionState.csv) !== 0 ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                            <FormInput
                                bg = "#f0ad4e"
                                color = "#fff"
                                description = {'Objective name'}
                                narrow = {true}
                                readOnly = {true}
                                value = {Assets.Objective + ' ' + Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[+Main.Instance.MissionState.csv - 1].objectiveId}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {() => void 0} 
                                placeholder = ""
                                icon = {Assets_.faCheckButtonClass}
                            />
                            <FormSelect
                                title = "Objective type"
                                level = {true}
                                mt0 = {true}
                                value = {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType.toString()}
                                data = {[
                                    {id: '1', name: 'Bets count'},
                                    {id: '2', name: 'Wins count'},
                                    {id: '3', name: 'Consecutive wins'},
                                    {id: '4', name: 'Consecutive days'},
                                    {id: '5', name: 'Big wins count'},
                                    {id: '6', name: Assets.Points},
                                    {id: '7', name: 'Different days'},
                                ]}
                                ClassName = "form-control"
                                o1 = "Objective type?"
                                o2 = "Objective type?"
                                onChange = {(text) => this.SelectObjectiveType(text)}
                                icon = {Assets_.faRequireClass}
                            />
            
                            {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType ?
                            <FormInputNumber
                                description = {
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.POINTS ?
            'Number of points' : 
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BETS_COUNT ?
            'Number of bets' : 
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.WINS_COUNT ?
            'Number of wins' : 
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_WINS ?
            'Number of consecutive wins' : 
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
            'Number of big wins' :
            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
            'Number of spins' : ''}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].roundsNeeded === undefined ? '1' :
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].roundsNeeded.toString()}  
                                type = "number" 
                                min = "1"
                                // icon = {Assets_.faHashtagClass}
                                iconTextS = "14px"
                                iconText = {
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.POINTS ?
                                    '# points' : 
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BETS_COUNT ?
                                    '# bets' : 
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.WINS_COUNT ?
                                    '# wins' : 
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_WINS ?
                                    '# cons. wins' : 
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
                                    '# big wins' :
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
                                    '# spins' : 
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.DIFFERENT_DAYS ?
                                    '# diff. days' :                         
                                    ''}
                                onChange = {(text) => this.NumberOfRoundsChanged(text)}
                            /> : void 0}
            
                            {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType &&
                            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.BIG_WINS_COUNT ?
                            <FormInputNumber
                                description = "Big win value"
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].bigWinValue === undefined ? '15' :
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].bigWinValue.toString()}  
                                type = "number" 
                                min = "1"
                                iconText = "Big win x"
                                iconTextS = "14px"
                                onChange = {(text) => this.BigWinValueChanged(text)}
                            /> : void 0}
                            {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType && 
                            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType === MT.CONSECUTIVE_DAYS ?
                            <FormInputNumber
                                description = "Big win value"
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].daysNeeded === undefined ? '2' :
                                    Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].daysNeeded.toString()}  
                                type = "number" 
                                min = "1"
                                iconText = {Assets.Days}
                                iconTextS = "14px"
                                onChange = {(text) => this.DaysNeededChanged(text)}
                            /> : void 0}
                        </div> : void 0*/}
            
                        {/*this.state.taskId !== 0 && parseInt(Main.Instance.MissionState.csv) !== 0 ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                            {Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType && 
                            Main.Instance.MissionState.CSV[this.state.taskId - 1].objectives[parseInt(Main.Instance.MissionState.csv) - 1].missionType !== MT.POINTS ?
                            this.getMinimumBet() : void 0}
                        </div> : void 0*/}
            
                        {/*parseInt(Main.Instance.MissionState.hiddenrewards) !== 0 ?
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                            <FormInput
                                bg = "#337ab7"
                                color = "#fff"
                                description = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards ? 
                                            Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards.length === 1 ? Assets.t1r : 
                                            Assets.ThereAre + ' ' + Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewards.length + ' ' + Assets.rthr :
                                            Assets.HiddenRewardsName}
                                readOnly = {true}
                                value = {Assets.HiddenReward + ' ' + Main.Instance.MissionState.HR[+Main.Instance.MissionState.hiddenrewards -1].hiddenRewardId}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {() => void 0} 
                                placeholder = ""
                                icon = {Assets_.faHiddenClass}
                            />
            
                            <FormInputNumber
                                description = {Assets.hramt}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].timesPlaying === undefined ? '0' :
                                        Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].timesPlaying.toString()}  
                                type = "number" 
                                min = "0"
                                icon = {Assets_.faEyeClass}
                                onChange = {(text) => this.TimesPlayingChanged(text)}
                            />
            
                            <FormInputNumber
                                description = {Assets.hraat}
                                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                ClassName = "h30 w60"
                                value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].taskCompleted === undefined ? '0' :
                                        Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].taskCompleted.toString()}  
                                type = "number"
                                min = {Main.Instance.MissionState.CSV ? '1' : '0'}
                                max = {Main.Instance.MissionState.CSV ? Main.Instance.MissionState.CSV.length.toString() : '0'}
                                icon = {Assets_.faTaskClass}
                                onChange = {(text) => this.TaskCompletedChanged(text)}
                            />
            
                            <FormSelect
                                rewardFor = {1}
                                level = {true}
                                mt0 = {true}
                                value = {Main.Instance.MissionState.HR[parseInt(Main.Instance.MissionState.hiddenrewards) - 1].rewardId}
                                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                                ClassName = "form-control"
                                o1 = {Assets.SelectReward}
                                o2 = {Assets.SelectReward}
                                onChange = {(text) => this.SelectHiddenRewardsDetailed(text)}
                                icon = {Assets_.faRewardClass}
                            />
                        </div> : void 0*/}
            
                        <div className = "row admin-users-container clearfix">
                            <ButtonIcon 
                                // disabled = {this.isInsert() && this.state.mission.tasks.length > 0}
                                description = {!this.isInsert() ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => this.handleInsertUpdate()}
                                ClassName = {!this.isInsert() ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                                // icon= {Assets_.faUpdateButtonClass}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    

    public getNonBlanks(array: Array<{code: string}>): number {
        if (array === undefined) {return 0}
        let nonBlanks: number = 0
        for (const item of array) {
            if (item.code !== '') {
                nonBlanks++
            }
        }
        return nonBlanks
    }

    public getNonZeros(array: string[]): number {
        if (array === undefined) {return 0}
        let nonZeros: number = 0
        for (const item of array) {
            if (item !== '0') {
                nonZeros++
            }
        }
        return nonZeros
    }
   
    public render() {
        // console.log (this.state.mission)

        return (
            <div>
                {this.firstRow()}
                {this.top()}
                {/*this.controlPanel()*/}
                <h3>{Assets.ListOfChances}</h3>
                {/* console.log (Main.Instance.DatabaseDataState.missionsNew) */}
                {Main.Instance.DatabaseDataState.missionsNew !== null && Main.Instance.DatabaseDataState.missionsNew !== undefined ?
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.missionsNew}
                    columns = {[
                        {maxWidth: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, this.handleEdit)},
                        {
                            Header: <><span style = {{color: 'inherit', position: 'relative', marginLeft: '5px', marginRight: '5px'}}>{Assets_.clover('#fff', 16, 0, 4)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Assets.Chance}</>, headerClassName: 'th-center risk-default', columns: [
                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                                {width: 30, Header: <FontAwesomeIcon title = {Assets.Order} icon = {faSort}/>, accessor: 'missionOrder', headerClassName: 'th-left'},
                                {width: 250, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                                {Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},
                                {width: 30, Header: <FontAwesomeIcon title = {Assets.Repeat + ' ' + Assets.Every.toLowerCase()} icon = {faSyncAlt}/>, accessor: 'repeatEvery.amount', headerClassName: 'th-left'},
                                {width: 60, Header: Assets.Unit, accessor: 'repeatEvery.unit', headerClassName: 'th-left'},
                                {width: 30, Header: <FontAwesomeIcon title = {Assets.Max + '. ' + Assets.Completion.toLowerCase() + ' ' + Assets.Count.toLowerCase()} icon = {faClipboardCheck}/>, accessor: 'maxCompletionCount', headerClassName: 'th-left'},
                                {width: 30, Header: <FontAwesomeIcon title = {Assets.is + ' ' + Assets.Consecutive.toLowerCase() + ' ' + Assets.Mission.toLowerCase()} icon = {faForward}/>, accessor: 'isConsecutiveMission', headerClassName: 'th-center', Cell: row => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                            ]
                        },
                        {
                            Header: <><FontAwesomeIcon icon = {faLightbulb}/>&nbsp;{Assets.Timestamps + ' (' + Assets_.UTC + ')'}</>, headerClassName: 'th-center risk-default-light', columns: [
                                {width: 90, Header: Assets.Active + ' ' + Assets.From.toLowerCase(), accessor: 'activeFrom', headerClassName: 'th-left', Cell: row => row.value === null ? '-' : <div><div className = {GetDate0(row.value).substring(0,10).trim() === '-' ? '' : 'lh17'}>{GetDate0(row.value).substring(0,10)}</div><div className = "lh17">{GetDate0(row.value).substring(11,19)}</div></div>},
                                {width: 90, Header: Assets.Active + ' ' + Assets.To.toLowerCase(), accessor: 'activeTo', headerClassName: 'th-left', Cell: row => row.value === null ? '-' : <div><div className = {GetDate0(row.value).substring(0,10).trim() === '-' ? '' : 'lh17'}>{GetDate0(row.value).substring(0,10)}</div><div className = "lh17">{GetDate0(row.value).substring(11,19)}</div></div>},
                            ]
                        },
                        {
                            Header: <><i className = {Assets_.faTaskClass}/>&nbsp;{Assets.Tasks}</>, headerClassName: 'th-center risk-default', columns: [
                                {width: 30, Header: <FontAwesomeIcon title = {Assets.is + ' ' + Assets.Task.toLowerCase() + ' ' + Assets.Completion.toLowerCase() + ' ' + Assets.Consecutive.toLowerCase()} icon = {faForward}/>, accessor: 'isTaskCompletionConsecutive', headerClassName: 'th-center', Cell: row => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                                {width: 150, Header: Assets.NumberOfTasks, accessor: 'tasks', headerClassName: 'th-left', Cell: row => <span style = {{fontSize: '14px'}} className = {row.value ? 'label label-info' : 'label label-danger'} >{row.value ? row.value.length + ' ' + Assets.Tasks.toLowerCase() : Assets.NoTasksDefined}</span>},
                            ]
                        },
/*
                        {
                            Header: <><i className = {Assets_.faHiddenClass}/>&nbsp;{Assets.HiddenRewards}</>, headerClassName: 'th-center risk-default-light', columns: [
                                {width: 150, Header: Assets.NumberOfHiddenRewards, accessor: 'hiddenRewards', headerClassName: 'th-left', Cell: row => <span style = {{fontSize: '12px'}} className = {row.value ? 'label label-info' : 'label label-danger'} >{row.value ? row.value.length + ' ' + Assets.HiddenRewards.toLowerCase() : Assets.NoHiddenRewardsDefined}</span>},
                            ]
                        },
*/

        /*
                        {
                            Header: Assets.Active, headerClassName: 'th-center risk-default-light', columns: [
                                {width: 100, Header: <><FontAwesomeIcon icon = {faCalendarDay}/>&nbsp;{Assets.From}</>, accessor: 'activeFrom', headerClassName: 'th-left', Cell: row => <div><div>{GetDate0(row.value).substring(0,10)}</div><div>{GetDate0(row.value).substring(11,19)}</div></div>},
                                {width: 100, Header: <><FontAwesomeIcon icon = {faCalendarDay}/>&nbsp;{Assets.To}</>, accessor: 'activeTo', headerClassName: 'th-left', Cell: row => <div><div>{GetDate0(row.value).substring(0,10)}</div><div>{GetDate0(row.value).substring(11,19)}</div></div>},
                            ]
                        },
        */
                        // {minWidth: 350, Header: Assets.Tasks, accessor: 'task', headerClassName: 'th-left', Cell: (row) => TableUtils.ConstructCategoriesTasks(row, handleCategoryButton)},
                        // {width: 50, Header: Assets.Task, accessor: 'task', headerClassName: 'th-left', Cell: (row) => TableUtils.ConstructCategoriesTasks2(row)},

                        // {width: 50, Header: Assets.HiddenRewardShortest, show: isPureOddyzzey(), accessor: 'hr', headerClassName: 'th-left', Cell: (row) => row ? row.value.length + ' hr' : '0 hr'},
                        // {width: 50, Header: Assets.Mt, accessor: 'mt', headerClassName: 'th-left', Cell: (row) => row.value + 'x'},
                        {maxWidth: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, this.handleDelete)},
                        {maxWidth: 35, Header: '', accessor: '', Cell: (row) => 
                            <div className = "td-center">
                                <button
                                    className = "btn btn-xs btn-primary mr0"
                                    title = {Assets.Copy + ' ' + Assets.Mission.toLowerCase()}
                                    onClick = {() => this.copyMission(row)}
                                >
                                    <FontAwesomeIcon icon = {faCopy}/>
                                </button>
                            </div>}
                    ]}
                    showPaginationBottom = {false}
                    pageSize={Main.Instance.DatabaseDataState.missionsNewCount}
                /> : void 0}
            </div>
        )
    }
}