import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {isUserAdmin, 
        isUserSystem,
        isUserMaster,
        isUserContractualPartner,
        isUserOperator,
        isUserGamingSite,
        isUserGameProvider,
        isUserComplianceProvider,
        isUserPaymentProvider,
        isUserGameAssetProvider,
        isUserComplianceSubProvider,
        isUserPaymentSubProvider,
        gotoTop,
        isUserSiteOwner} from '../../../Logic/Utils';
import {FormSelect, FormInput, FormInputNumber, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave, faUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'


// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD

export function getSubEntityCount() {

    if (Main.Instance.RegisterSubEntityState.countCalled === false) {

        Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                countCalled:true, 
                contractual_partner_id:'0', 
                contractual_partner_2:'0'})
        // !!! bug here doesnt show all operators
        // if (!isUserSubEntity()) {
        if (isUserSystem() || isUserMaster()) {
                DbRoutes.getContractualPartnersAllByType()
        }

        DbRoutes.countSubEntity()
        
        if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner()) {
                DbRoutes.getContractualPartnersByTypeAll(Assets_.OPERATOR)
                DbRoutes.getSubEntity2()
        } else {
                DbRoutes.getContractualPartnersByTypeAll(Assets_.SITE_OWNER)
                DbRoutes.getSubEntity()                
        }
    }
}

function getSubEntity() {

    Logic.Type.New (Main.Instance.DatabaseDataState, {subEntityStream : undefined})
}

function clearFields() {

    Logic.Type.New(Main.Instance.RegisterSubEntityState, {   
        id: '',
        name : '',
        email : '',
        phone : '',
        // custom : {},
        // data : {},
        person : '',
        gg_affiliate_system : '2',
        contractual_partner_id: '0',
        contractual_partner_2: '0',
        // contractual_partner : '',
        ID : '',
        mode : false})
}

function handleInsertUpdate() {
        // console.log ('insertupdate!')
        const items = {
                name: !Main.Instance.RegisterSubEntityState.name ? '' : Main.Instance.RegisterSubEntityState.name,
                email: !Main.Instance.RegisterSubEntityState.email ? '' : Main.Instance.RegisterSubEntityState.email,
                telephone: !Main.Instance.RegisterSubEntityState.phone ? '' : 
                Main.Instance.RegisterSubEntityState.phone,
                id: !Main.Instance.RegisterSubEntityState.id          ? '' : Main.Instance.RegisterSubEntityState.id,
                person: !Main.Instance.RegisterSubEntityState.person ? '' :
                Main.Instance.RegisterSubEntityState.person,
                type_id: !Main.Instance.RegisterSubEntityState.type_id ? '1' :
                Main.Instance.RegisterSubEntityState.type_id,
                gg_affiliate_system: !Main.Instance.RegisterSubEntityState.gg_affiliate_system ? '' : 
                Main.Instance.RegisterSubEntityState.gg_affiliate_system,
                gg_affiliate_system_id: !Main.Instance.RegisterSubEntityState.gg_affiliate_system ? false : 
                Main.Instance.RegisterSubEntityState.gg_affiliate_system === '1',
                contractual_partner_id: !Main.Instance.RegisterSubEntityState.contractual_partner_id ? '' :
                Main.Instance.RegisterSubEntityState.contractual_partner_id,
                id_: !Main.Instance.RegisterSubEntityState.ID ? '' : Main.Instance.RegisterSubEntityState.ID,
                contractual_partner_2: !Main.Instance.RegisterSubEntityState.contractual_partner_2 ? '' :
                Main.Instance.RegisterSubEntityState.contractual_partner_2,
        }

        if (parseInt(items.type_id) !== 1 && parseInt(items.type_id) !== 5) { 
                items.gg_affiliate_system = '2'
                items.gg_affiliate_system_id = false 
                items.id_ = ''
                items.contractual_partner_2 = '0'
        }

        if (isUserContractualPartner()) {
                items.contractual_partner_id = Main.Instance.LoginState.entityId
        }
/*
        if (isUserMaster()) {
                // tu moramo dobiti contractual partner id
        }
*/
        if (items.name.length === 0) {
                alert (Assets.compulsorySubEntity)
                return
        } else if (parseInt(items.type_id) === 0 && !isUserContractualPartner()) {
                alert (Assets.pleaseSelectSubEntity)
                return
        } else if (items.person.length === 0) {
                alert (Assets.compulsoryPerson)
                return
        } else if (items.email.length === 0) {
                alert (Assets.compulsoryEmail)
                return
/*
        } else if (items.telephone.length === 0) {
                alert (Assets.compulsoryPhone)
                return
        } else if (items.telephone.charAt(0) !== '+') {
                alert (Assets.compulsoryPhoneStartWithPlus)
                return
*/
        } else if (parseInt(items.contractual_partner_id) === 0 && parseInt(items.contractual_partner_2) === 0) {
                alert (Assets.compulsoryContractualPartnerID)
                return
        } else if (items.id_.length > 0 && (items.id_.length < 8)) {
                alert (Assets.compulsorySiteIDMinLength)
                return
        } else if (items.id_.length > 0 && (items.id_.length > 12)) {
                alert (Assets.compulsorySiteIDMaxLength)
                return
        } else if (Main.Instance.RegisterSubEntityState.mode === false) {
        // means that we are in false mode = INSERT
            DbRoutes.insertAdminLog (
                Config.asAdminLogSaveInsert,
                'User Management -> Manage Sub Entities',
                items,
            )
            DbRoutes.insertSubEntity (items, InsertLastPage)
        } else {
        // MODE UPDATE
        // console.log ('update!')
            DbRoutes.insertAdminLog (
                Config.asAdminLogSaveInsert,
                'User Management -> Manage Sub Entities',
                items,
            )

            DbRoutes.updateSubEntity (items, Update)
        }
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.sub_entity SET gg_affiliate_system = ', rowData[0], rowData[6], getSubEntityCount)
    }
}
*/
function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('UPDATE admin.sub_entity SET gg_affiliate_system = ', id.toString(), value, getSubEntityCount) }
}
/*
function handleEditOld(rowData:string[]) {
        if (rowData) {

                // let gg_affiliate_system:string
                // rowData[6].toString() === 'true' ? gg_affiliate_system = '1' : gg_affiliate_system = '2'

                let select_type_id: string
                if (parseInt(rowData[3]) === 2) {
                    select_type_id = '1'
                } else {
                    select_type_id = '0'
                }

                Logic.Type.New(Main.Instance.RegisterSubEntityState,{
                        id:rowData[0], 
                        name: rowData[1],
                        name_saved :rowData[1],
                        ID: rowData[2],
                        ID_saved: rowData[2],
                        select_type_id : select_type_id,
                        type_id: rowData[3],
                        type_id_saved: rowData[3],
                        contractual_partner_id: 
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? rowData[10] : rowData[4],
                        contractual_partner_id_saved:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? rowData[10] : rowData[4],
                        // contractual_partner: rowData[5], 
                        gg_affiliate_system_id: rowData[6].toString() === 'true',
                        gg_affiliate_system:rowData[6].toString() === 'true' ? '1' : '2',
                        gg_affiliate_system_saved :rowData[6].toString() === 'true' ? '1' : '2',
                        person: rowData[7], 
                        person_saved :rowData[7],
                        email: rowData[8],
                        email_saved :rowData[8],
                        phone: rowData[9], 
                        phone_saved :rowData[9],
                        contractual_partner_2:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? rowData[4] : rowData[10],
                         
                        contractual_partner_2_saved:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? rowData[4] : rowData[10],
                        mode:true})

                if (isUserSystem() || isUserMaster()) {
                        DbRoutes.getContractualPartnersAllByType()
                }
        }
}

function handleDeleteOld(rowData:string[]) {
        if (rowData) {
                if (confirm(Assets.sureToDelete + ' ' + 
                Assets.SubEntity+ ' "' + rowData[1] + '"?')) {
                        if (    Main.Instance.RegisterSubEntityState.pNum === 
                                (Math.trunc((Main.Instance.RegisterSubEntityState.count - 2) / 
                                Main.Instance.RegisterSubEntityState.pSize) + 1)) {
                                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                                // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo 1, trunc((6-2)/5)=0
                                
                                DbRoutes.removeSubEntity(rowData[0].toString(), DeleteLastPage)
                        } else {

                                DbRoutes.removeSubEntity(rowData[0].toString(), DeleteNotLastPage)
                        }
                }
        }
}
*/
function handleEdit(row: any) {
    DbRoutes.insertAdminLog (
        Config.asAdminLogEdit,
        'User Management -> Manage Sub Entities',
        {id: row.original.id, name: row.original.subentity, siteId: row.original.siteid},
    )
    gotoTop()
    if (row) {
        // let gg_affiliate_system:string
        // rowData[6].toString() === 'true' ? gg_affiliate_system = '1' : gg_affiliate_system = '2'

        let select_type_id: string
        if (row.original.stype_id === 2) {
            select_type_id = '1'
        } else {
            select_type_id = '0'
        }

        Logic.Type.New(Main.Instance.RegisterSubEntityState,{
                id: row.original.id, 
                name: row.original.subentity,
                name_saved: row.original.subentity,
                ID: row.original.siteid,
                ID_saved: row.original.siteid,
                select_type_id,
                type_id: row.original.stype_id,
                type_id_saved: row.original.stype_id,
                contractual_partner_id: 
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? row.original.contractual_partner_2 : row.original.contractual_partner_id,
                contractual_partner_id_saved:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? row.original.contractual_partner_2 : row.original.contractual_partner_id,
                // contractual_partner: rowData[5], 
                gg_affiliate_system_id: row.original.ggaffiliatesystem.toString() === 'true',
                gg_affiliate_system:row.original.ggaffiliatesystem.toString() === 'true' ? '1' : '2',
                gg_affiliate_system_saved :row.original.ggaffiliatesystem.toString() === 'true' ? '1' : '2',
/*
                person: row.original.person, 
                person_saved :row.original.person,
                email: row.original.email,
                email_saved :row.original.email,
                phone: row.original.telephone,
                phone_saved :row.original.telephone,
*/
                email:row.original.email !== null ? row.original.email : '',
                email_saved:row.original.email !== null ? row.original.email : '',
                phone:row.original.telephone !== null ? row.original.telephone : '',
                phone_saved:row.original.telephone !== null ? row.original.telephone : '',
                person:row.original.person !== null ? row.original.person : '',
                person_saved:row.original.person !== null ? row.original.person : '',
                contractual_partner_2:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? row.original.contractual_partner_id : row.original.contractual_partner_2,
                    
                contractual_partner_2_saved:
parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 || isUserSiteOwner() ? row.original.contractual_partner_id : row.original.contractual_partner_2,
                mode:true})

        if (isUserSystem() || isUserMaster()) {
                DbRoutes.getContractualPartnersAllByType()
        }
    }
}

function handleDelete(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.SubEntity + ' "' + row.original.subentity + '"?')) {
        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            'User Management -> Manage Sub Entities',
            {id: row.original.id, name: row.original.name},
        )

        if (Main.Instance.RegisterSubEntityState.pNum === 
            (Math.trunc((Main.Instance.RegisterSubEntityState.count - 2) / 
            Main.Instance.RegisterSubEntityState.pSize) + 1))  {
            DbRoutes.removeSubEntity (row.original.id.toString(), DeleteLastPage)
        } else {
            DbRoutes.removeSubEntity (row.original.id.toString(), DeleteNotLastPage)
        }
    }
}

function InsertLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterSubEntityState,{count:Main.Instance.RegisterSubEntityState.count ++})
    LastPage()
}

function DeleteLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterSubEntityState,{count:Main.Instance.RegisterSubEntityState.count --})
    LastPage()
}

function DeleteNotLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterSubEntityState,{count:Main.Instance.RegisterSubEntityState.count --})

    getSubEntity()
}

function Update() {

    clearFields()

    getSubEntity()
}

function gotoInsertMode() {

    if (Main.Instance.RegisterSubEntityState.name === Main.Instance.RegisterSubEntityState.name_saved &&
        (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 
        parseInt(Main.Instance.RegisterSubEntityState.type_id_saved)) &&
        ((parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1 ||
        parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5) &&
        Main.Instance.RegisterSubEntityState.gg_affiliate_system === 
        Main.Instance.RegisterSubEntityState.gg_affiliate_system_saved || 
        (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 1 && 
        parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 5)) &&
        Main.Instance.RegisterSubEntityState.person === Main.Instance.RegisterSubEntityState.person_saved &&
        Main.Instance.RegisterSubEntityState.email === Main.Instance.RegisterSubEntityState.email_saved &&
        Main.Instance.RegisterSubEntityState.phone === Main.Instance.RegisterSubEntityState.phone_saved &&
        Main.Instance.RegisterSubEntityState.ID === Main.Instance.RegisterSubEntityState.ID_saved &&
        Main.Instance.RegisterSubEntityState.contractual_partner_id === 
        Main.Instance.RegisterSubEntityState.contractual_partner_id_saved &&
        Main.Instance.RegisterSubEntityState.contractual_partner_2 === 
        Main.Instance.RegisterSubEntityState.contractual_partner_2_saved
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {clearFields()}                
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.RegisterSubEntityState, {offset:(0).toString()})
    getSubEntity()
    Logic.Type.New(Main.Instance.RegisterSubEntityState,{pNum: 0})
}

function PrevPage() {
    if (Main.Instance.RegisterSubEntityState.pNum > 0) {

        Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                offset: ((Main.Instance.RegisterSubEntityState.pNum - 1) *  
        Main.Instance.RegisterSubEntityState.pSize).toString()})
        getSubEntity()
        Logic.Type.New(Main.Instance.RegisterSubEntityState,{
                pNum: Main.Instance.RegisterSubEntityState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.RegisterSubEntityState.pNum + 1) * Main.Instance.RegisterSubEntityState.pSize < 
    Main.Instance.RegisterSubEntityState.count) {
        Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                offset:(Main.Instance.RegisterSubEntityState.pSize *
                (Main.Instance.RegisterSubEntityState.pNum + 1)).toString()})
        getSubEntity()                
        Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                pNum: Main.Instance.RegisterSubEntityState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.RegisterSubEntityState.count <= Main.Instance.RegisterSubEntityState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;
        if (Main.Instance.RegisterSubEntityState.count === 0) {
                Logic.Type.New(Main.Instance.RegisterSubEntityState, {offset: (0).toString()})
        } else {
                npNum = Math.trunc((Main.Instance.RegisterSubEntityState.count - 1) / 
                        Main.Instance.RegisterSubEntityState.pSize)
                Logic.Type.New( Main.Instance.RegisterSubEntityState, 
                                {offset: (npNum * Main.Instance.RegisterSubEntityState.pSize).toString()})
        
        }

        getSubEntity()

        Logic.Type.New(Main.Instance.RegisterSubEntityState,{pNum: npNum})
    }
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RegisterSubEntityState,{pSize:parseInt(text)})
        FirstPage()
    }
}

function ApplyFilter(text:string) {
    Logic.Type.New(Main.Instance.RegisterSubEntityState, {type_id_filter:text})
    FirstPage()
}

function ApplySelection(text:string) {
    if (isUserSystem() || isUserMaster()) {
        Logic.Type.New(Main.Instance.RegisterSubEntityState, {
            type_id: (parseInt(text) + 1).toString(),
            select_type_id: text,
            contractual_partner_2: '0',
            contractual_partner_id: '0',
        })
        DbRoutes.getContractualPartnersAllByType()
    } else if (isUserContractualPartner()) {
        Logic.Type.New(Main.Instance.RegisterSubEntityState, {select_type_id: text})
    }
}

export function ManageSubEntity() {

        if (!isUserSystem() && !isUserMaster()) {
            if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) !== 0) {
                Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id_filter : '0'})
            }
        }

        if (isUserOperator() || isUserGamingSite()) {
            if (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 1) {
                Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id : '1'})
            }
        } else if (isUserGameProvider() || isUserGameAssetProvider()) {
            if (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 2) {
                Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id : '2'})
            }
        } else if (isUserPaymentProvider() || isUserPaymentSubProvider()) {
                if (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 3) {
                        Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id : '3'})
                }
        } else if (isUserComplianceProvider() || isUserComplianceSubProvider()) {
                if (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 4) {
                        Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id : '4'})
                }
        } else if (isUserSiteOwner()) {
                if (parseInt(Main.Instance.RegisterSubEntityState.type_id) !== 1) {
                        Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id : '1'})
                }
        }
        let title: string = ''
        let add: string = ''
        let edit: string = ''

        if (isUserAdmin()) {
                if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1) {
                        title = Assets.aeGamingSite
                        add = Assets.AddGamingSite
                        edit = Assets.EditGamingSite
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 2) {
                        title = Assets.aeGameAssetProvider
                        add = Assets.AddGameAssetProvider
                        edit = Assets.EditGameAssetProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 3) {
                        title = Assets.aePaymentSubProvider
                        add = Assets.AddPaymentSubProvider
                        edit = Assets.EditPaymentSubProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 4) {
                        title = Assets.aeComplianceSubProvider
                        add = Assets.AddComplianceSubProvider
                        edit = Assets.EditComplianceSubProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5) {
                        title = Assets.aeSite
                        add = Assets.AddSite
                        edit = Assets.EditSite
                }

        } else {
                if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1) {
                        title = Assets.vGamingSite
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 2) {
                        title = Assets.vGameAssetProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 3) {
                        title = Assets.vPaymentSubProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 4) {
                        title = Assets.vComplianceSubProvider
                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5) {
                        title = Assets.vSite
                }
        }

        let subentity: string

        if (isUserSystem() || isUserMaster()) {

                if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 0) {
                        subentity = Assets.tSubEntity

                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 1) {
                        subentity = Assets.tSites

                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 2) {
                        subentity = Assets.tGameAssetProviders

                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 3) {
                        subentity = Assets.tPaymentSubProviders

                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 4) {
                        subentity = Assets.tComplianceSubProviders

                } else if (parseInt(Main.Instance.RegisterSubEntityState.type_id_filter) === 5) {
                        subentity = Assets.tSites
                }

        } else if (isUserOperator()) {
                subentity = Assets.tSites + Assets._for_ + 
                Assets.operator + ' "' + Main.Instance.LoginState.entity + '"'  
        } else if (isUserGameProvider()) {
                subentity = Assets.tGameAssetProviders + Assets._for_ +
                Assets.gameProvider + ' "' + Main.Instance.LoginState.entity + '"'  
        } else if (isUserPaymentProvider()) {
                subentity = Assets.tPaymentSubProviders + Assets._for_ + 
                Assets.paymentProvider + ' "' + Main.Instance.LoginState.entity + '"'  
        } else if (isUserComplianceProvider()) {
                subentity = Assets.tComplianceSubProviders + Assets._for_ + 
                Assets.complianceProvider + ' "' + Main.Instance.LoginState.entity + '"'  
        } else if (isUserSiteOwner()) {
                subentity = Assets.tSites + Assets._for_ + 
                Assets.operator2 + ' "' + Main.Instance.LoginState.entity + '"'  
        }

        return (
        <div>
        {/*isUserAdmin() ? <h3>{Assets.rSubEntity}</h3> : <h3>{Assets.vSubEntity}</h3>*/}
        <div>
                {/*<h3 className = "title-left">{title}</h3>*/}
                {isUserSystem() || isUserMaster() ?
                <FormSelect
                        value = {Main.Instance.RegisterSubEntityState.select_type_id} 
                        data = {[0]}
                        ClassName = "form-control select-menu" 
                        // floatRight = {true}
                        w50 = {true}                        
                        // o1 = {Assets.aeSubEntity} 
                        o1 = {isUserAdmin() ? Assets.aeGamingSite : Assets.vGamingSite} 
                        o2 = {isUserAdmin() ? Assets.aeGameAssetProvider : 
                        Assets.vGameAssetProvider}
                        // o3 = {isUserAdmin() ? Assets.aePaymentSubProvider : 
                        // Assets.vPaymentSubProvider}
                        // o4 = {isUserAdmin() ? Assets.aeComplianceSubProvider : 
                        // Assets.vComplianceSubProvider} 
                        icon = {Assets_.faSubEntityLgClass}
                        onChange = {(text) => ApplySelection(text)}
                />  : void 0}
                        {/*o6 = {isUserAdmin() ? Assets.aeStore : Assets.vStore*/} 

                {/*isUserGameProvider() ?
                <FormSelect
                        value = {Main.Instance.RegisterSubEntityState.select_type_id} 
                        data = {[0]}
                        ClassName="form-control select-menu" 
                        floatRight = {true}
                                w50 = {true}                        
                        o1 = {Assets.aeSubEntity} 
                        o2 = {isUserAdmin() ? Assets.aeGameAssetProvider : 
                        Assets.vGameAssetProvider}
                        icon = {Assets_.faSubEntityLgClass}
                        onChange = {text => ApplySelection(text)} />  : void 0*/}


                <div className = "clearfix"/>
        </div>
        <h3>{Assets.Manage + ' ' + Assets.SubEntities}</h3>
        <br/>
        {isUserAdmin() ? 
        <div className="row admin-users-container">
                <div 
                        className = {Main.Instance.RegisterSubEntityState.mode ? 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
                >
                <div className = "register-input">
                        <div className = "row admin-users-row">
                                {/*<h4 className = "h450">*/}
                                <h4>
                                        {isUserAdmin() && Main.Instance.RegisterSubEntityState.mode ? edit + ': ' + 
                                        Main.Instance.RegisterSubEntityState.name_saved : null}
                                        {isUserAdmin() && !Main.Instance.RegisterSubEntityState.mode ? add : null}
                                        {!isUserAdmin() ? title : null}                        
                                </h4>
                        
                        {Main.Instance.RegisterSubEntityState.mode ? 
                        <ButtonIcon
                            description = {Assets.discardChanges}
                            click = {() => gotoInsertMode()} 
                            ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : void 0}

                        </div>

                        <div className = "row admin-users-row">
                        {/*
                        <div className = "row-info">
                                <span className="register-input">{Assets.SubEntity}</span>
                        </div>
                        */}
                        <FormInput 
                                value = {!Main.Instance.RegisterSubEntityState.name ? '' : 
                                        Main.Instance.RegisterSubEntityState.name}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterSubEntityState,{name:text})}}
                                placeholder = {Assets.pName}
                                icon = {Assets_.faSubEntityClass}
                        />

                        {parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1 || 
                        parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 ?
                        <FormInput 
                                value = {!Main.Instance.RegisterSubEntityState.ID ? '' : 
                                        Main.Instance.RegisterSubEntityState.ID}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {Logic.Type.New(Main.Instance.RegisterSubEntityState,{ID:text})}}
                                placeholder = {Assets.pSiteID}
                                icon =  {Assets_.faSiteClass}
                        />
                        : null}

                {(isUserSystem() || isUserMaster()) 
/*
                &&
                (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1 || 
                parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5) 
*/
                || isUserSiteOwner() ?
                <FormSelect
                        narrow = {true}
                        value = {!Main.Instance.RegisterSubEntityState.contractual_partner_id ? '0' : 
                                Main.Instance.RegisterSubEntityState.contractual_partner_id}
                        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                        ClassName = "form-control"
                        o0 = {Main.Instance.RegisterSubEntityState.type_id}
                        o1 = {Assets.EmptyDatabase}
                        o2 = {Assets.ContractualPartnerQ}
                        o3 = {Assets.OperatorQ}
                        o4 = {Assets.GameProviderQ}
                        o5 = {Assets.PaymentProviderQ}
                        o6 = {Assets.ComplianceProviderQ}
                        o7 = {Assets.BrandOwnerQ}

                        onChange = {(text) => {Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                                contractual_partner_id:text})}}
                        icon = {Assets_.faCompanyClass}
                /> : void 0}


                {(isUserSystem() || isUserMaster()) && 
                (parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1 || 
                parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5) 
                || isUserOperator() ?
                <FormSelect
                        narrow = {true}
                        value = {!Main.Instance.RegisterSubEntityState.contractual_partner_2 ? '0' : 
                                Main.Instance.RegisterSubEntityState.contractual_partner_2}
                        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                        ClassName = "form-control"
                        o0 = {Main.Instance.RegisterSubEntityState.type_id}
                        o1 = {Assets.EmptyDatabase}
                        o2 = {Assets.ContractualPartnerQ}
                        o3 = {Assets.BrandOwnerQ}
                        o4 = {''}
                        o5 = {''}
                        o6 = {''}
                        o7 = {Assets.OperatorQ}

                        onChange = {(text) => {Logic.Type.New(Main.Instance.RegisterSubEntityState, {
                                contractual_partner_2:text})}}
                        icon = {Assets_.faCompanyClass}
                /> : void 0}

                {isUserOperator() ?
                <span className = "label label-primary fl label-table mr">
                        {Main.Instance.LoginState.entity}
                </span>
                : void 0}

                {isUserSiteOwner() ?
                <span className = "label label-primary fl label-table mr">
                        {Main.Instance.LoginState.entity}
                </span>
                : void 0}

                </div>
                <div className="row admin-users-row">
                        <FormInput 
                                value = {!Main.Instance.RegisterSubEntityState.person ? '' : 
                                        Main.Instance.RegisterSubEntityState.person}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterSubEntityState,{person:text})}}
                                placeholder = {Assets.pName}
                                icon =  {Assets_.faUserClass}
                        />

                        <FormInput 
                                value = {!Main.Instance.RegisterSubEntityState.email ? '' : 
                                        Main.Instance.RegisterSubEntityState.email}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterSubEntityState,{email:text})}}
                                placeholder = {Assets.pEmail}
                                icon =  {Assets_.faEmailClass}
                        />

                        <FormInput 
                                value = {!Main.Instance.RegisterSubEntityState.phone ? '' :
                                        Main.Instance.RegisterSubEntityState.phone}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterSubEntityState,{phone:text})}}
                                placeholder = {Assets.pPhone}
                                icon =  {Assets_.faPhoneClass}
                        />
{/*
                        <div className = "row-info">
                        <span className = "register-input">{Assets.MainContactPerson}</span>
                        </div>
*/}
                {parseInt(Main.Instance.RegisterSubEntityState.type_id) === 1 || 
                parseInt(Main.Instance.RegisterSubEntityState.type_id) === 5 ?
                <RadioButton2 
                        value = {!Main.Instance.RegisterSubEntityState.gg_affiliate_system ? '2' : 
                                        Main.Instance.RegisterSubEntityState.gg_affiliate_system}

                        ClassName = "input-group-radiobutton3 margin-bottom-sm" 
                        title = {Assets.GGAffiliateSystemExplained}
                        text = {Assets.GGAffiliateSystemText}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => {
                        Logic.Type.New(Main.Instance.RegisterSubEntityState, {gg_affiliate_system:text})}}
                /> 
                : void 0 }


                <ButtonIcon 
                    description = {Main.Instance.RegisterSubEntityState.mode ? 
                                    Assets.saveChanges : Assets.rSubEntity}
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RegisterSubEntityState.mode ? 
                            'btn btn-default btn-sm btn-plus fr mr' : 
                            'btn btn-primary btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
                        </div>

                        </div>
                </div>
        <br/>
        </div>
        : void 0}
        <div className = "control-panel clearfix">
            {/*<span>&nbsp;{Assets.Show}&nbsp;</span>*/}
            <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.RegisterSubEntityState.pSize ? '' : 
                    Main.Instance.RegisterSubEntityState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
            />
            {/*<span>&nbsp;{Assets.recordsPerPage}&nbsp;&nbsp;&nbsp;</span>*/}

            <button 
                    className = "ml20 btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
            >
                    <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
            >
                    <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
            >
                    <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
            >
                    <i className = {Assets_.faLastButtonClass}/>
            </button>

            {isUserSystem() || isUserMaster() ? 
            <FormSelect 
                    span = {true}
                    value = {!Main.Instance.RegisterSubEntityState.type_id_filter ? '0' : 
                            Main.Instance.RegisterSubEntityState.type_id_filter}
                    data = {[0]}
                    ClassName = "control-panel-select"
                    o1 = {Assets.SubEntityTypeAny} 
                    o2 = {Assets.GamingSite}
                    o3 = {Assets.GameAssetProvider}
                    // o4 = {Assets.PaymentSubProvider}
                    // o5 = {Assets.ComplianceSubProvider} 
                    onChange = {(text) => ApplyFilter(text)}
            /> : void 0}
                    {/*o6 = {Assets.Store}*/}                        

            <span className = "label label-success table-label mt5">
                    {Assets.records}
                    {Main.Instance.RegisterSubEntityState.count ? Main.Instance.RegisterSubEntityState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                    {Assets.showing}
                    {Main.Instance.RegisterSubEntityState.count ? 
                    Main.Instance.RegisterSubEntityState.pNum * Main.Instance.RegisterSubEntityState.pSize + 1 : 0}
                    {' - '}
                    {Main.Instance.RegisterSubEntityState.count ? 
                    ((Main.Instance.RegisterSubEntityState.pNum + 1) * Main.Instance.RegisterSubEntityState.pSize >
                    Main.Instance.RegisterSubEntityState.count ? 
                    Main.Instance.RegisterSubEntityState.count : 
                    (Main.Instance.RegisterSubEntityState.pNum + 1) * 
                    Main.Instance.RegisterSubEntityState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.RegisterSubEntityState.count ? 
                    Main.Instance.RegisterSubEntityState.pNum + 1 :0}{' / '}
                    {Main.Instance.RegisterSubEntityState.count ?
                    Math.trunc((Main.Instance.RegisterSubEntityState.count - 1) /
                    Main.Instance.RegisterSubEntityState.pSize + 1) : 0}
            </span>
        </div>
        <h3>{subentity}</h3>
        {Main.Instance.DatabaseDataState.subEntityStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.subEntityStream}
            columns = {[
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {Header: Assets.SubEntity, accessor: 'subentity', headerClassName: 'th-left'},
                {Header: Assets.SubEntityType, accessor: 'stype_id', headerClassName: 'th-left', Cell: (row) => TableUtils.getSubentityType(row.value)},
                {Header: Assets.SiteID, accessor: 'siteid', headerClassName: 'th-left'},
                {Header: Assets.ContractualPartner, accessor: 'contractualpartner', headerClassName: 'th-left'},
                {Header: Assets.GGAffiliateSystemTableTitle, accessor: 'ggaffiliatesystem', Cell: (row) => TableUtils.TB(row, toggleActive)},
                {Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                <div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faUser} />
                        {row.original.person !== null && row.original.person.length > 0 ? row.original.person : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faEnvelope}/>
                        {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faPhone}/>
                        {row.original.telephone !== null && row.original.telephone.length > 0 ? row.original.telephone : Assets.notDefined}
                    </div>
                </div>},
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getSubEntityCount()}
        </div>
        )
}