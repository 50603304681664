import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMinusSquare, faPlusSquare, faAdjust, faTimes, faPhoneSquare, faCompress, faExpand, faSyncAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {faSun} from '@fortawesome/free-regular-svg-icons'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {/*UploadDocumentsFiles, */IIssue, IssueTypes, Player, AccountStatus, TimelineEntrySubType} from '../../../Logic/Database/DatabaseData'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'
import ReactTable from 'react-table'
import { getRiskManager } from './ResponsibleGaming'
import { Document, Page } from 'react-pdf'
import {injectSpaces, isUserGDPR} from '../../../Logic/Utils'
import {getOptionsKYC, getFilename} from '../PlayerManagement/PlayerCard'
import axios, {AxiosRequestConfig} from 'axios'

interface State {
    isPEP: boolean
    isAddressVerified: boolean
    unblock?: boolean
    comment?: string
    resolved?: boolean
    id?: number

    attachedSelected: number
    uploadSelected: number
    uploadExisting?: any/*UploadDocumentsFiles*/[]
    uploadContrast: number
    uploadBrightness: number
    uploadExpanded: boolean
    uploadRotated: number
    upload?: string
    uploadIsImage?: boolean
    uploadShowAvailableFiles: boolean
    attachedUploads?: string[]

    utilityContrast: number
    utilityBrightness: number
    utility?: string
    utilityExpanded: boolean
    utilityRotated: number
    Utility?: string
    front?: string
    frontExpanded: boolean
    frontRotated: number
    frontContrast: number
    frontBrightness: number
    back?: string
    backExpanded: boolean
    backRotated: number
    backContrast: number
    backBrightness: number
    Front?: string
    Back?: string
    verified?: boolean
    documentNumber?: string
    // documentType?: string
    DocumentId?: string
    DocumentType: string
    issuingAuthority?: string
    documentExpiry?: string
    issueDate?: string
    assignTo?: number[]
    assignee: number
    issueType?: IssueTypes
    fileNames?: string[]
    data?: any
    demandGetFullyIdentified: boolean
    closed?: boolean
    name: string
    username: string
    phoneNumber: string
    pageNumber3: number
    pageNumber4: number
    pageNumber5: number
    pageNumber6: number
    numPages3: number
    numPages4: number
    numPages5: number
    numPages6: number
}
/*
export const documentType: string[] = ['Select identification', 'National ID card', 'Passport', 'Driving licence']
export const DocumentType: string[] = ['0', 'id', 'passport', 'drivers_license']
*/
export default class CSCallbackResolvePopup extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            attachedSelected: -1, uploadSelected: -1, uploadShowAvailableFiles: false,
            demandGetFullyIdentified: false,
            isAddressVerified: (Main.Instance.DatabaseDataState.playerCard ? Main.Instance.DatabaseDataState.playerCard.isAddressVerified : false),
            isPEP: (Main.Instance.DatabaseDataState.playerCard ? Main.Instance.DatabaseDataState.playerCard.isPEP : false),
            uploadRotated: 0, utilityRotated: 0, frontRotated: 0, backRotated: 0,
            uploadContrast: 10, utilityContrast: 10, frontContrast: 10, backContrast: 10,
            uploadBrightness: 10, utilityBrightness: 10, frontBrightness: 10, backBrightness: 10,
            uploadExpanded: false, utilityExpanded: false, frontExpanded: false, backExpanded: false,
            // uploadIsImage: false,
            assignee: 0, DocumentType: '0',
            numPages3: null, pageNumber3: 1,
            numPages4: null, pageNumber4: 1,
            numPages5: null, pageNumber5: 1,
            numPages6: null, pageNumber6: 1,
            username: '?', name: '?', phoneNumber: '?'
        }
    }

    // to naj klice tudi po tem ko naredi nov upload
    public getUploads = (playerId: string, uploadSelected: number) => {
        // console.log ('getUploads')
/*
        const data = new FormData()
        data.append('folderName', playerId ? playerId : Main.Instance.DatabaseDataState.playerCard.playerId)
        const config: AxiosRequestConfig = {
            method: 'post',
            url: Config.uFiles,
            headers: {
                'api-key': 'burek',
                'Content-Type' : 'multipart/form-data'
            },
            data
        }
        // console.log ('Config.uFiles')
        // console.log (Config.documentsDirectory[Config.environment])
        // console.log (playerId)
        axios(config).then((response) => {
*/
        axios.post(
            Config.uFiles,
            {
                folderName: playerId ? playerId : Main.Instance.DatabaseDataState.playerCard.playerId
            },
            {
                headers: {
                    'api-key': 'burek',
                    'Content-Type' : 'application/json'
                }
            }
        ).then((response) => {
            // console.log (response.data)
            // const uploadExisting: UploadDocumentsFiles[] = []
            if (response.data && response.data[0]) {

                // for (const item of response.data[0]) {
                //    item.ContentType = item.id.endsWith('pdf') ? 'pdf' : 'image'
                    // if (item.id.startsWith(/*Config.documentsDirectory[Config.environment] + '/' + */(playerId ? playerId : Main.Instance.DatabaseDataState.playerCard.playerId))) {
                    //     uploadExisting.push(item)
                    // }
                // }
                this.setState({
                    uploadExisting : response.data[0],
                    uploadSelected: uploadSelected !== undefined ? uploadSelected : -1
                })
            }

        }).catch((error) => console.log (error))
    }

    public onDocumentLoadSuccess3 = (PDFDocumentProxy) => {
        this.setState({
            numPages3: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
            pageNumber3: 1
        })
    }

    public onDocumentLoadSuccess4 = (PDFDocumentProxy) => {
        this.setState({
            numPages4: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
            pageNumber4: 1
        })
    }

    public onDocumentLoadSuccess5 = (PDFDocumentProxy) => {
        this.setState({
            numPages5: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
            pageNumber5: 1
        })
    }

    public onDocumentLoadSuccess6 = (PDFDocumentProxy) => {
        this.setState({
            numPages6: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
            pageNumber6: 1
        })
    }

    public isAlreadyThere(user_id: number): boolean {
        if (this.state.assignTo) { for (const item of this.state.assignTo) { if (item === user_id) { return true } } }
        return false
    }

    public defaultComment(): string {
        return Assets.ThisIssueWasNotResolvedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public DefaultComment(): string {
        return Assets.ThisIssueWasResolvedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public DefaultComment2(): string {
        return Assets.ThisPlayerAccountWasUnblockedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public defaultComment2(): string {
        return Assets.ThisPlayerAccountWasNotUnblockedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public DefaultComment3(): string {
        return Assets.ThisPlayerAccountWasClosedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public defaultComment3(): string {
        return Assets.ThisPlayerAccountWasNotClosedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.'
    }

    public getOptionsAssignee(): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "0" value = "0">{Assets.SelectAssignee}</option>)
        for (const item of Main.Instance.LogState.distinctUserId) {
            options.push (<option disabled = {this.isAlreadyThere(item.user_id)} key = {item.user_id} value = {item.user_id}>{item.username}</option>)
        }
        return options
    }

    public getOptionsKYC(type: string): JSX.Element[] {
        return getOptionsKYC(type)
    }

    public select(assignee: number) {
        this.setState({assignee})
    }

    public Select(DocType: string) {
        this.setState({DocumentType: DocType})
    }

    public assignTo(): JSX.Element[] {
        const ass: JSX.Element[] = []
        if (this.state.assignTo && this.state.assignTo.length > 0) {
            for (let i: number = 0; i < this.state.assignTo.length; i++) {
                ass.push (<button onClick = {() => this.deselect(i)} key = {this.state.assignTo[i].toString()} className = "btn btn-default btn-xs">{getRiskManager(this.state.assignTo[i])}</button>)
            }
        } else {
            ass.push (<span className = "mr">{Assets.Nobody}</span>)
        }
        ass.push (<span className = "mr" key = "span">{Assets.AssignTo}</span>)
        ass.push (<select key = "select" onChange = {(e) => this.select(+e.target.value)} value = {this.state.assignee} className = "mr">{this.getOptionsAssignee()}</select>)
        ass.push (<button disabled = {this.state.assignee === 0} key = "button" onClick = {() => this.add()} className = "btn btn-primary btn-xs">{Assets.AddAssignee}</button>)
        return ass
    }

    public getDate(unix: number): string {
        const date = new Date(unix)
        return date.toISOString().substring(0,10) + ' ' + date.toISOString().substring(11,19)
    }

    public deselect(i: number) {
        const assignTo = Object.assign ([], this.state.assignTo)
        assignTo.splice (i, 1)
        this.setState({assignTo, assignee: 0})
    }
    
    public add() {
        const assignTo = Object.assign ([], this.state.assignTo)
        assignTo.push (this.state.assignee)
        this.setState({assignTo, assignee: 0})
    }

    public togglePEP() {
        this.setState({isPEP: !this.state.isPEP})
        // const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard) 
        // playerCard.isPEP = !playerCard.isPEP
        // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
    }

    public isThisFileAlreadyAttached(): boolean {
        const split = this.state.uploadExisting[this.state.uploadSelected].name.split('/')
        const name = split[1] ? split[1] : '?'
        if (this.state.attachedUploads) {
            for (const item of this.state.attachedUploads) {
                if (item === name) {return true}
            }
        }
        return false
    }

    public attachThisFile() {
        const split = this.state.uploadExisting[this.state.uploadSelected].name.split('/')
        const name = split[1] ? split[1] : '?'
        if (this.state.attachedUploads === undefined) {
            this.setState({attachedUploads: [name]})
        } else {
            const attachedUploads: string[] = Object.assign([], this.state.attachedUploads)
            attachedUploads.push (name)
            this.setState({attachedUploads})
        }
    }

    public toggleVerifyAddressManually() {
        
        this.setState({isAddressVerified: !this.state.isAddressVerified})
        // const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard) 
        // playerCard.isAddressVerified = !playerCard.isAddressVerified
        // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
    }

    public reEvaluatePlayerCard(playerCard: Player): Player {
        const rules:Array<{name: string, requirements?: {isEmailVerified?: true, isUtilityBillVerified?: true, isDocumentVerified?: true, isSuperVerified?: true}, allow?: {login?: true, play?: true, deposit?: true, withdraw?: true, temporary?: true}}> = Object.assign([], Main.Instance.DatabaseDataState.custom.general.players.accountStatus)
        // console.log (rules)
        
        // for (let i: number = AccountStatus.Initial; i <= AccountStatus.ActiveFullyIdentified; i++) {
        for (let i: number = AccountStatus.ActiveFullyIdentified; i >= AccountStatus.Initial; i--) {
            // console.log (i)
            // console.log (rules[i])
            if (rules[i].requirements.isEmailVerified && playerCard.isEmailVerified !== rules[i].requirements.isEmailVerified) { continue }
            if (rules[i].requirements.isDocumentVerified && playerCard.isDocumentVerified !== rules[i].requirements.isDocumentVerified) { continue }
            if (rules[i].requirements.isUtilityBillVerified && playerCard.isUtilityVerified !== rules[i].requirements.isUtilityBillVerified && playerCard.isAddressVerified !== rules[i].requirements.isUtilityBillVerified) { continue }
            if (rules[i].requirements.isSuperVerified && playerCard.isSuperVerified !== rules[i].requirements.isSuperVerified) { continue }
            // console.log ('this is ok')
            playerCard.allowLogin = rules[i].allow.login
            playerCard.allowDeposit = rules[i].allow.deposit
            playerCard.allowWithdraw = rules[i].allow.withdraw
            playerCard.allowPlay = rules[i].allow.play
            playerCard.accountStatus = i
            // console.log ('set player account status to ' + playerCard.accountStatus)
            break
        }
        return playerCard
    }

    public resolve() {
        const data = Object.assign({}, this.state.data)
        // here we need to update the player card
        // what we need to add BUG is when the utility/document gets rejected. in this case there has to be uploaded utility and uploaded document flag set to false
        if (this.state.issueType === IssueTypes.SamePlayer || this.state.issueType === IssueTypes.BlacklistRegistration || this.state.issueType === IssueTypes.BlacklistLogin || this.state.issueType === IssueTypes.BlacklistCronJob || this.state.issueType === IssueTypes.BlacklistManual || this.state.issueType === IssueTypes.Retail) {
            data.unblock = this.state.unblock
        } else if (this.state.issueType === IssueTypes.AccountClosedActive || this.state.issueType === IssueTypes.AccountClosedProvisional) {
            data.closed = this.state.closed
        }
        
        if ((this.state.front || this.state.back || this.state.Front || this.state.Back)) {
            data.verified = this.state.verified
            if (this.state.verified && this.state.documentExpiry && this.state.documentNumber && this.state.DocumentType && this.state.issueDate && this.state.issuingAuthority) {
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.issuingAuthority = this.state.issuingAuthority
                playerCard.documentExpiry = this.state.documentExpiry
                playerCard.documentNumber = this.state.documentNumber
                playerCard.documentType = this.state.DocumentType
                playerCard.issueDate = this.state.issueDate
                playerCard.isDocumentVerified = true
                data.issuingAuthority = this.state.issuingAuthority
                data.documentExpiry = this.state.documentExpiry
                data.documentNumber = this.state.documentNumber
                data.documentType = this.state.DocumentType
                data.issueDate = this.state.issueDate
               
                playerCard = this.reEvaluatePlayerCard(playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard/*, issuePlayerId: playerCard.playerId*/})
                // DbRoutes.updatePlayerCard(playerCard)

                if (Main.Instance.Location !== '/account-store/player-card') {
                    Logic.Type.New (Main.Instance.PlayerCardState, {id: playerCard.playerId})
                    Logic.Type.New (Main.Instance, {Location: '/account-store/player-card'})
                }
            } else if (!this.state.verified) {
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.isDocumentUploaded = false
/*
                const issue: IIssue = {
                    id: 0,
                    playerId: playerCard.playerId,
                    issueType: IssueTypes.VerifiedUtility,
                    entrySubType: TimelineEntrySubType.KnowYourCustomer,
                    riskLevel: RiskLevel.Low,
                    comment: 'Utility bill was verified.',
                    assignTo: [],
                    resolved: true,
                    riskManagerId: Main.Instance.LoginState.id,
                    data: {
                        utilityId: this.state.DocumentId,
                        fileNames: this.state.fileNames,
                        riskLevel: RiskLevel.Low,
                    }
                }
                DbRoutes.wsUpdateIssue(issue)
*/
                playerCard = this.reEvaluatePlayerCard(playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard/*, issuePlayerId: undefined*/})
                // DbRoutes.updatePlayerCard(playerCard)
            }
        }

        if ((this.state.utility || this.state.Utility)) {
            data.verified = this.state.verified
            if (this.state.verified) {
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.isUtilityVerified = true
                playerCard = this.reEvaluatePlayerCard(playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard/*, issuePlayerId: playerCard.playerId*/})
                // DbRoutes.updatePlayerCard(playerCard)

                if (Main.Instance.Location !== '/account-store/player-card') {
                    Logic.Type.New (Main.Instance.PlayerCardState, {id: playerCard.playerId})
                    Logic.Type.New (Main.Instance, {Location: '/account-store/player-card'})
                }
            } else if (!this.state.verified) {
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.isUtilityUploaded = false
                playerCard = this.reEvaluatePlayerCard(playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard/*, issuePlayerId: undefined*/})
                // DbRoutes.updatePlayerCard(playerCard)
            }
        }
        // console.log ('1')
        if (this.state.issueType > IssueTypes.CRIFCheckMismatch && this.state.issueType < IssueTypes.CRIFCheckMismatch + 10) {
            // console.log (Main.Instance.DatabaseDataState.playerCard.isPEP)
            // DbRoutes.updatePlayerCard(Main.Instance.DatabaseDataState.playerCard)
        }
        
        if (this.state.issueType === IssueTypes.CRIFCheckMismatch || this.state.issueType === IssueTypes.CRIFAddressChangedOperator ||
            this.state.issueType === IssueTypes.CRIFCheckLogin || this.state.issueType === IssueTypes.CRIFCheckRegistration) {
            data.isAddressVerified = this.state.isAddressVerified
        }

        if (this.state.issueType === IssueTypes.PEPChangedByOperator || this.state.issueType === IssueTypes.PEPCheckLogin ||
            this.state.issueType === IssueTypes.PEPCheckRegistration || this.state.issueType === IssueTypes.PEPCheckNameChanged) {
            data.isPEP = this.state.isPEP
        }

        if (this.state.demandGetFullyIdentified) {
            // console.log ('demandFullyIdentified')
            data.verified = this.state.verified
            if (this.state.verified) {
                data.isSuperVerified = true
                // console.log ('3a')
/*
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.isSuperVerified = true
                // console.log ('isSuperVerified: true')
                playerCard = this.reEvaluatePlayerCard(playerCard)
                // console.log (playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
                DbRoutes.updatePlayerCard(playerCard)
*/
                this.setState({demandGetFullyIdentified: false})
            } else {
                data.isSuperVerified = false
                // console.log ('3b')
/*
                let playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                playerCard.isGettingSuperVerified = false
                // console.log ('isGettingSuperVerified: false')
                // console.log (playerCard)
                playerCard = this.reEvaluatePlayerCard(playerCard)
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
                DbRoutes.updatePlayerCard(playerCard)
*/
                this.setState({demandGetFullyIdentified: false})
            }
        }
        data.attachedUploads = this.state.attachedUploads

        const issueData = {
            id: this.state.id,
            resolved: this.state.resolved,
            riskLevel: RiskLevel.Undefined,
            comment: this.state.comment,
            riskManagerId: Main.Instance.LoginState.id,
            assignTo: this.state.assignTo,
            data,
        }
        
        // console.log (issueData)
        DbRoutes.wsUpdateIssue(issueData)

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddCSCallbackModal: false, rowId: undefined, mode: undefined, history: undefined})
    }

    public getCountry(country: string): string {
        if (Main.Instance.DatabaseDataState.countries) {
            for (const item of Main.Instance.DatabaseDataState.countries) {
                if (item.data.code === country) {return item.data.name}
            }
        }
        return '?'
    }

    public getNationality(nationality: string): string {
        if (Main.Instance.DatabaseDataState.nationalities) {
            for (const item of Main.Instance.DatabaseDataState.nationalities) {
                if (item.data.code3 === nationality) {return item.data.nationality}
            }
        }
        return '?'
    }

    public getSamePlayers(a: any): JSX.Element {
        const sp: JSX.Element[] = []
        if (a) {
            for (let i: number = 0; i < a.length; i++) {
                sp.push(this.getSamePlayer(a[i], i)) 
            }
        }
        return <div>{sp}</div>
    }

    public getSamePlayer(a: any, index?: number): JSX.Element {
        return (a ? 
        <div style = {{marginBottom: index !== undefined ? '5px' : '0px'}} key = {index !== undefined ? index.toString() : undefined}>
            {index === undefined ? void 0 : <div style = {{fontWeight: 700, fontSize: '16px'}}>{Assets.Player_} #{index+1}:</div>}
            <div>
                {a.username ? <span>{Assets.Username}:&nbsp;<strong>{a.username}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.firstName ? <span>{Assets.FirstName}:&nbsp;<strong>{a.firstName}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.lastName ? <span>{Assets.LastName}:&nbsp;<strong>{a.lastName}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.birthDate ? <span>{Assets.Birthday}:&nbsp;<strong>{a.birthDate}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.gender ? <span>{Assets.Gender}:&nbsp;<strong>{a.gender}</strong></span> : void 0}
            </div>
            <div>
                {a.email ? <span>{Assets.Email}:&nbsp;<strong>{a.email}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.phoneNumber ? <span>{Assets.MobilePhone}:&nbsp;<strong>{a.phoneNumber}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.currency ? <span>{Assets.Currency}:&nbsp;<strong>{a.currency}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.nationality ? <span>{Assets.Nationality}:&nbsp;<strong>{this.getNationality(a.nationality)}</strong></span> : void 0}
            </div>
            <div>
                {a.streetName ? <span>{Assets.Address}:&nbsp;<strong>{a.address.streetName}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.streetNumber ? <span>{Assets.StreetNumberAbbrev}:&nbsp;<strong>{a.address.streetNumber}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.postcode ? <span>{Assets.PostalCode}:&nbsp;<strong>{a.postcode}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.city ? <span>{Assets.City}:&nbsp;<strong>{a.city}</strong>&nbsp;&nbsp;</span> : void 0}
                {a.country ? <span>{Assets.Country}:&nbsp;<strong>{this.getCountry(a.country)}</strong></span> : void 0}
            </div>
        </div>
        : void 0
        )
    }

    // download
    public selectUpload(uploadSelected: number) {
        const split: string[] = this.state.uploadExisting[uploadSelected].name.split('/')
        // console.log (split)
        const uploadIsImage: boolean = !this.state.uploadExisting[uploadSelected].name.endsWith('.pdf')
        const args = {
            method: 'POST',
            body: JSON.stringify({file: split[1], folderName: split[0]}),
            headers: {'Content-Type' : 'application/json', 'api-key': 'burek'
        }}
        // console.log ('Config.uDownload')
        // console.log (args)
        fetch(Config.uDownload, args).then((response) => {
            // console.log (response)
            response.blob().then((response2) => {
                const upload: string = URL.createObjectURL(response2)
                // console.log (selectedUpload)
                // Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUpload})
                // console.log (upload)
                // console.log (uploadSelected)
                this.setState({upload, uploadSelected, uploadIsImage, attachedSelected: -1})
            })
        })
    }

    public uploadFile(playerId: string, id: string) {
        // console.log (playerId)
        const upload = document.getElementById(id) as HTMLInputElement
        if (upload.files && upload.files.length) {
            const data = new FormData()
            data.append('file', upload.files[0])
            data.append('folderName', playerId)
            const config: AxiosRequestConfig = {
                method: 'post',
                url: Config.uUpload,
                headers: {
                    'api-key': 'burek',
                    'Content-Type' : 'multipart/form-data'
                },
                data
            };
            axios(config).then((response) => {
                this.setState({uploadShowAvailableFiles: true})
                this.getUploads(playerId, this.state.uploadExisting.length + 1)
                // console.log ('Config.uUpload')
                // console.log (response)
                alert(response.data.msg + ' ' + (response.data.storageResponse && response.data.storageResponse[0] && response.data.storageResponse[0].originalName ? response.data.storageResponse[0].originalName : '') + ' (' + (response.data.storageResponse && response.data.storageResponse[0] && response.data.storageResponse[0].mimetype ? response.data.storageResponse[0].mimetype : '')  +')')
            }).catch((error) => console.log (error))
        }
    }

    public getAlreadyUploaded(): JSX.Element {
        const gau: JSX.Element[] = []
        if (this.state.uploadExisting) {
            for (let i: number = 0; i < this.state.uploadExisting.length; i++) {
                gau.push(<button onClick = {() => this.selectUpload(i)} key = {i.toString()} className = {i === this.state.uploadSelected ? 'btn btn-primary btn-xs mb5' : 'btn btn-default btn-xs mb5'}>{this.state.uploadExisting[i].name ? getFilename(this.state.uploadExisting[i].name.split('/')[1]) : '?'}</button>)
            }
        } else {
            // this.setState({uploadExisting: []})
        }
        return (<div style = {{textAlign: 'left', marginBottom: '5px'}} /*style = {{position: 'absolute', top: '0px', right: '0px'}}*/>{gau}</div>)
    }

    public deleteAttached(i: number) {
        // console.log (this.state.attachedUploads[i])
        if (confirm(Assets.areYouSureThatYouWantToDeleteAnAttachedFile + ' ' + this.state.attachedUploads[i] + '?')) {
            const attachedUploads: string[] = Object.assign([], this.state.attachedUploads)
            attachedUploads.splice (i, 1)
            this.setState({attachedUploads})
        }
    }

    public selectAttached(attachedSelected: number) {
        // const split: string[] = this.state.uploadExisting[uploadSelected].name.split('/')
        // console.log (split)
        const name: string = this.state.attachedUploads[attachedSelected]
        const uploadIsImage: boolean = !name.endsWith('.pdf')
        const args = {
            method: 'POST',
            body: JSON.stringify({file: name, folderName: Main.Instance.DatabaseDataState.playerCard.playerId}),
            headers: {'Content-Type' : 'application/json', 'api-key': 'burek'
        }}
        // console.log (Config.uDownload)
        // console.log (args)
        fetch(Config.uDownload, args).then((response) => {
            // console.log (response)
            response.blob().then((response2) => {
                const upload: string = URL.createObjectURL(response2)
                // console.log (selectedUpload)
                // Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUpload})
                this.setState({upload, uploadSelected: -1, attachedSelected, uploadIsImage})
            })
        })
    }

    public getAttached(): JSX.Element {
        const ga: JSX.Element[] = []
        if (this.state.attachedUploads && this.state.attachedUploads.length && this.state.attachedSelected === -1 && this.state.uploadSelected === -1) {
            this.setState({attachedSelected: 0})
            this.selectAttached(0)
        }
        if (this.state.attachedUploads) {
            for (let i: number = 0; i < this.state.attachedUploads.length; i++) {
                ga.push(
                    <button
                        onClick = {() => this.selectAttached(i)}
                        key = {i.toString()}
                        className = {i === this.state.attachedSelected ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}
                    >
                        {getFilename(this.state.attachedUploads[i])}
                    </button>
                )
                ga.push(
                    <button
                        onClick = {() => this.deleteAttached(i)}
                        key = {i.toString() + '_'}
                        className = "btn btn-danger btn-xs br0"
                    >
                        <FontAwesomeIcon icon = {faTrashAlt}/>
                    </button>
                )
            }
        } else {
            // this.setState({uploadExisting: []})
        }
        return (<div style = {{textAlign: 'left', marginBottom: '5px'}} /*style = {{position: 'absolute', top: '0px', right: '0px'}}*/>{ga}</div>)
    }

    public getIssue(rowId: number, issueSource: TimelineEntrySubType): IIssue|undefined {
        // console.log ('rowId ', rowId)
        // console.log ('issueSource ', issueSource) // to mora bit something
        if (rowId !== -1) {
            if (issueSource === TimelineEntrySubType.DashboardBackend) {
                return Main.Instance.DatabaseDataState.issuesDashboardBackend[rowId]
            } else if (issueSource === TimelineEntrySubType.RiskManagement) {
                return Main.Instance.DatabaseDataState.issues1[rowId]
            } else if (issueSource === TimelineEntrySubType.ResponsibleGaming) {
                return Main.Instance.DatabaseDataState.issues2[rowId]
            } else if (issueSource === TimelineEntrySubType.KnowYourCustomer) {
                return Main.Instance.DatabaseDataState.issues3[rowId]
            } else if (issueSource === TimelineEntrySubType.Gamification) {
                return Main.Instance.DatabaseDataState.issues4[rowId]
            } else if (issueSource === undefined) {
                return Main.Instance.DatabaseDataState.issuesAllIssuesFilter[rowId]
            }
        }
        return undefined
    }

    public render() {
        // mode true means that it was clicked from Risk/Compliance Store (issues)
        // mode false means that it was clicked from Main Dashboard (player timeline), therefore we need to find th issue rowId first
        // console.log ('render', Main.Instance.RiskManagementState.rowId, Main.Instance.RiskManagementState.mode, Main.Instance.RiskManagementState.playerId)
        // console.log (Main.Instance.DatabaseDataState.playerCard)
        if (Main.Instance.RiskManagementState.rowId !== -1) {
//          BUG HERE WE NEED TO LOAD THE APPROPRIATE ISSUE

            const item: IIssue|undefined = Main.Instance.RiskManagementState.mode ?
                                 this.getIssue(Main.Instance.RiskManagementState.rowId, Main.Instance.RiskManagementState.issueSource) :
                                 // Object.assign ({}, Main.Instance.DatabaseDataState.issues[Main.Instance.RiskManagementState.rowId]) :
                                 Object.assign ({}, Main.Instance.DatabaseDataState.playerTimeline[Main.Instance.RiskManagementState.rowId]) as IIssue

            
            // console.log (Main.Instance.DatabaseDataState.issue) // to mora bit undefined
            // const item: Partial<IIssue> = undefined
            if (item === undefined) {return <div/>}
            // console.log (item)
            for (const Item of Main.Instance.DatabaseDataState.playerCards) {
                if (item.playerId === Item.playerId) {
                    this.setState({username: Item.username, name: Item.lastName + ' ' + Item.firstName})
                    break
                }
            }
            // console.log ('callbackResolvePopup 365')
            // console.log (item)
            Logic.Type.New (Main.Instance.RiskManagementState, {rowId: -1})
            
            
            // this.setState({issueType: item.issueType, data: item.data})
            if (item.issueType === IssueTypes.CallbackRequest) {
                // console.log (item)
                this.setState({phoneNumber: item.data.phoneNumber})
            }
            Logic.Type.New(Main.Instance.DatabaseDataState, {questionnaire: undefined})
            // console.log (Main.Instance.RiskManagementState.playerId)
            // console.log (Main.Instance.RiskManagementState.mode)
            if (Main.Instance.RiskManagementState.mode && Main.Instance.RiskManagementState.playerId !== undefined) {
                DbRoutes.wsGetPlayerCard(Main.Instance.RiskManagementState.playerId)
            }
            this.getUploads(Main.Instance.RiskManagementState.playerId ? Main.Instance.RiskManagementState.playerId : Main.Instance.DatabaseDataState.playerCard.playerId, undefined)
            // console.log (Main.Instance.DatabaseDataState.issues[Main.Instance.RiskManagementState.rowId])
            // console.log (item.data)
            if (item.data && (item.issueType === IssueTypes.SamePlayer || item.issueType === IssueTypes.BlacklistRegistration || item.issueType === IssueTypes.BlacklistLogin || item.issueType === IssueTypes.BlacklistCronJob || item.issueType === IssueTypes.BlacklistManual || item.issueType === IssueTypes.Retail)) {
                this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false, unblock: false})
            } else if (item.data && (item.issueType === IssueTypes.SamePlayer || item.issueType === IssueTypes.BlacklistRegistration  || item.issueType === IssueTypes.BlacklistLogin || item.issueType === IssueTypes.BlacklistCronJob || item.issueType === IssueTypes.BlacklistManual || item.issueType === IssueTypes.Retail)) {
                this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false, closed: false})
            } else if (item.data && (item.issueType === IssueTypes.LargeDeposit || item.issueType === IssueTypes.LargeWithdrawal)) {
                // console.log ('largeshit')
                if (item.history && item.history.length >= 0 && item.history[0] && item.history[0].riskLevel >= RiskLevel.High || item.riskLevel >= RiskLevel.High) { // BUG WE SHOULD CHECK FOR ISSUE ORIGINAL RiskLevel
                    // console.log ('demandGetFullyIdentified: true')
                    this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, demandGetFullyIdentified: true, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false})
                } else {
                    this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false})
                }
                // demandGetFullyIdentified
            } else if (item.data && item.data.documentId && (item.issueType === IssueTypes.UploadDocument || item.issueType === IssueTypes.VerifiedDocument)) {
                // console.log ('UPLOAD DOCUMENT')
                // console.log ('documentId:' + item.data.documentId)
                fetch(Config.dsDownloadDocument, {method: 'POST', body: JSON.stringify({playerId: item.playerId, documentId: item.data.documentId, fileName: Assets_.front}), headers: {'Content-Type' : 'application/json'}})
                .then((response) => {
                    // console.log (response)
                    response.blob().then((response2) => {
                        const front: string = URL.createObjectURL(response2)
                        let frontExt: string
                        if (item.data && item.data.fileNames) {
                            for (const Item of item.data.fileNames) {
                                const ITEM = Item.split('.')
                                if (ITEM[0].toLowerCase() === Assets_.front) {
                                    frontExt = ITEM[1]
                                    // console.log ('frontext:' + frontExt + '|' + front)
                                    break
                                }
                            }
                        }

                        // this.setState({front})
                        fetch(Config.dsDownloadDocument, {method: 'POST', body: JSON.stringify({playerId: item.playerId, documentId: item.data.documentId, fileName: Assets_.back}), headers: {'Content-Type' : 'application/json'}})
                        .then((Response) => {
                            Response.blob().then((Response2) => {
                                const back: string = URL.createObjectURL(Response2)
                                let backExt: string
        
                                if (item.data && item.data.fileNames) {
                                    for (const Item of item.data.fileNames) {
                                        const ITEM = Item.split('.')
                                        if (ITEM[0].toLowerCase() === Assets_.back) {
                                            backExt = ITEM[1]
                                            // console.log ('backext:' + backExt + '|' + back)
                                            break
                                        }
                                    }
                                }

                                if (response.status === 200) {
                                    if (frontExt === Assets_.pdf) {
                                        this.setState({Front: front, front: undefined})
                                    } else {
                                        this.setState({front, Front: undefined})
                                    }
                                }
                                
                                if (Response.status === 200) {
                                    if (backExt === Assets_.pdf) {
                                        this.setState({Back: back, back: undefined})
                                    } else {
                                        this.setState({back, Back: undefined})
                                    }
                                }
        
                                if (Response.status === 200 || response.status === 200) {
                                    this.setState({
                                        attachedUploads: item.data.attachedUploads,
                                        issueType: item.issueType,
                                        data: item.data,
                                        assignTo: item.assignTo,
                                        id: item.id,
                                        comment: this.defaultComment(),
                                        resolved: false,
                                        verified: false,
                                        DocumentType: item.data.type,
                                        DocumentId: item.data.documentId,
                                        issuingAuthority: item.data.issuingAuthority,
                                        documentNumber: item.data.documentNumber,
                                        documentExpiry: item.data.documentExpiry,
                                        issueDate: item.data.issueDate,
                                        fileNames: item.data.fileNames,
                                    })
                                    Logic.Type.New (Main.Instance.RiskManagementState, {mode: false})
                                } else {
                                    this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, comment: this.defaultComment(), resolved: false, verified: false})
                                    Logic.Type.New (Main.Instance.RiskManagementState, {mode: false})
                                }
                            })
                        }).catch((e) => console.log (e))
                    })
                }).catch((e) => console.log (e))
            } else if (item.data && item.data.utilityId && (item.issueType === IssueTypes.UploadUtility || item.issueType === IssueTypes.VerifiedUtility)) {
                // console.log ('UPLOAD UTILITY')
                fetch(Config.dsDownloadUtility, {method: 'POST', body: JSON.stringify({playerId: item.playerId, utilityId: item.data.utilityId, fileName: Assets_.front}), headers: {'Content-Type' : 'application/json'}})
                .then((response) => {
                    // console.log (response)
                    response.blob().then((response2) => {
                        const utility: string = URL.createObjectURL(response2)
                        let ext: string
                        if (item.data && item.data.fileNames) {
                            for (const Item of item.data.fileNames) {
                                const ITEM = Item.split('.')
                                if (ITEM[0].toLowerCase() === Assets_.front) {
                                    ext = ITEM[1]
                                    break
                                }
                            }
                        }

                        if (response.status === 200) {
                            if (ext === Assets_.pdf) {
                                this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, Utility: utility, utility: undefined, comment: this.defaultComment(), resolved: false, verified: false, DocumentId: item.data.documentId, fileNames: item.data.fileNames})
                            } else {
                                this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, utility, Utility: undefined, comment: this.defaultComment(), resolved: false, verified: false, DocumentId: item.data.documentId, fileNames: item.data.fileNames})
                            } 

                            Logic.Type.New (Main.Instance.RiskManagementState, {mode: false})
                        } else {
                            this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data,assignTo: item.assignTo, id: item.id, utility: undefined, comment: this.defaultComment(), resolved: false, verified: false})
                            Logic.Type.New (Main.Instance.RiskManagementState, { mode: false})
                        }
                    })
                }).catch((e) => console.log (e))

            } else if (item.data && item.data.date && item.data.riskLevel !== undefined && item.data.totalPoints !== undefined && item.issueType === IssueTypes.SelfAssessment) {
                // console.log ('ISSUE TYPE SELF ASSESSMENT')

                if (item.data && item.data.date && item.data.riskLevel !== undefined && item.data.totalPoints !== undefined) {
                    this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data, assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false})
                    Logic.Type.New (Main.Instance.RiskManagementState, {mode: false})
                    DbRoutes.wsGetQuestionnaire(item.playerId, item.data.date as string)
                }
            } else {
                this.setState({attachedUploads: item.data.attachedUploads, issueType: item.issueType, data: item.data,assignTo: item.assignTo, id: item.id, front: undefined, back: undefined, Front: undefined, Back: undefined, utility: undefined, Utility: undefined, comment: this.defaultComment(), resolved: false})
            }
        }
        // console.log (this.state.attachedUploads)
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main add-setting-wide">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', marginRight: '0px', top: '-30px', right: '-10px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddCSCallbackModal: false, rowId: undefined, history: undefined})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        {/*<h1>Add a comment</h1>*/}
                        <h2>
                            {this.state.issueType === IssueTypes.VerifiedUtility || this.state.issueType === IssueTypes.VerifiedDocument ? Assets.ViewTheIssue : Assets.ResolveTheIssue}&nbsp;&nbsp;
                            <small>{injectSpaces(IssueTypes[this.state.issueType])}</small>
                            <small style = {{fontWeight: 700}}>&nbsp;&nbsp;({this.state.username}{isUserGDPR() ? ' - ' +  this.state.name : ''})</small>
                            {this.state.issueType === IssueTypes.CallbackRequest ? <small>&nbsp;&nbsp;<FontAwesomeIcon icon = {faPhoneSquare}/>&nbsp;{this.state.phoneNumber}</small>: void 0}
                        </h2>
                        
                        {/* MAIN SECTION */}
                        {/*console.log (Main.Instance.LogState.distinctUserId)*/}
                        {this.state.issueType === IssueTypes.VerifiedUtility || this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <div><span className = "mr">{Assets.AssignedTo}:</span>{this.assignTo()}</div>}
                        
                        {/* QUESTIONNAIRE SECTION */}
                        {Main.Instance.DatabaseDataState.questionnaire ?
                        <>
                            <h4>{Assets.QuestionnaireDate}: {isNaN(Main.Instance.DatabaseDataState.questionnaire.date as any) ? Main.Instance.DatabaseDataState.questionnaire.date : this.getDate(1000 * parseInt(Main.Instance.DatabaseDataState.questionnaire.date))}</h4>
                            <h5>{Assets.TotalPoints}: {Main.Instance.DatabaseDataState.questionnaire.totalPoints}</h5>
                            {/*<h6>Risk level: {RiskLevel[Main.Instance.DatabaseDataState.questionnaire.riskLevel]}</h6>*/}
                            <div className = "mt5">
                                <span style = {{marginRight: '20px'}}>{Assets.Questionnaire + ' ' + Assets.RiskLevel}</span>
                                <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.questionnaire.riskLevel]}>
                                    {RiskLevel[Main.Instance.DatabaseDataState.questionnaire.riskLevel]}
                                    <input
                                        disabled = {true}
                                        value = {Main.Instance.DatabaseDataState.questionnaire.riskLevel}
                                        type = "range"
                                        list = "test"
                                        min = "1"
                                        max = "5"
                                        step = "1"
                                        // onChange = {(e) => this.setState({Main.Instance.DatabaseDataState.questionnaire.riskLevel})}
                                        className = "slider"
                                    />
                                </button>
                            </div>                            
                            <ReactTable
                                className = "dashboard-item-tournament"
                                data = {Main.Instance.DatabaseDataState.questionnaire.questions}
                                columns = {[
                                    {width: 90, Header: Assets.Question + ' ' + Assets.ID, accessor: 'questionId', headerClassName: 'th-left'/*, className: 'dashboard-item-tournament'*/}, 
                                    {Header: Assets.Question, accessor: 'questionId', headerClassName: 'th-left', Cell: (row) => getQuestion (row.value)},
                                    {width: 80, Header: Assets.Points, accessor: 'points', headerClassName: 'th-left'/*, className: 'dashboard-item-tournament'*/},
                                    {width: 120, Header: Assets.RatingPoints, accessor: 'ratingPoints', headerClassName: 'th-left'/*, className: 'dashboard-item-tournament'*/}, 
                                ]}
                                showPaginationTop = {false}
                                showPaginationBottom = {false}
                                pageSize={Main.Instance.DatabaseDataState.questionnaire.questions.length}
                            />
                        </> : void 0}

                        {/* GETTING FULLY IDENTIFIED SECTION */}
                        {this.state.demandGetFullyIdentified ? 
                        <>
                        <h4>{Assets.crp1}</h4>
                        <div>
                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>
                                {Assets.crp2}
                            </span>
                            <button onClick = {()=> this.setState({verified: true})} type = "button" className = {this.state.verified ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({verified: false})} type = "button" className = {!this.state.verified ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>
                        </> : void 0}

                        {/* DOCUMENT SECTION */}
                        {this.state.Front || this.state.Back || this.state.front || this.state.back ?
                        <>
                        <div>
                            {this.state.Front || this.state.front ?
                                <div style = {{width: '100%', textAlign: 'center'}}>
                                    <div className = "pr">
                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {this.state.frontExpanded ? Assets.Compress : Assets.Expand}
                                            style = {{position: 'absolute', top: '-5px', left: '0px', zIndex: 2}}
                                            onClick = {() => this.setState({frontExpanded: !this.state.frontExpanded})}
                                        >
                                            <FontAwesomeIcon icon = {this.state.frontExpanded ? faCompress : faExpand}/>
                                        </button>

                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            title = {Assets.Brightness}
                                            value = {this.state.frontBrightness}
                                            style = {{width: '200px', position: 'absolute', top: '0px', left: '70px', zIndex: 2}}
                                            onChange = {(e) => this.setState({frontBrightness: +e.target.value})}
                                        />
                                        <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '0px', left: '45px', zIndex: 2}}/>

                                        <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '0px', right: '90px', zIndex: 2}}/>
                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            value = {this.state.frontContrast}
                                            title = {Assets.Contrast}
                                            style = {{width: '200px', position: 'absolute', top: '0px', right: '115px', zIndex: 2}}
                                            onChange = {(e) => this.setState({frontContrast: +e.target.value})}
                                        />

                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {Assets.Rotate}
                                            style = {{position: 'absolute', top: '-5px', right: '0px', zIndex: 2}}
                                            onClick = {() => this.setState({frontRotated: this.state.frontRotated === 3 ? 0 : this.state.frontRotated + 1})}
                                        >
                                            <FontAwesomeIcon icon = {faSyncAlt}/>
                                        </button>
                                        <span style = {{position: 'absolute', top: '-4px', right: '40px', zIndex: 2, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                                            {this.state.frontRotated * 90}°
                                        </span>
                                        <h4>{Assets.UploadedDocument}&nbsp;{Assets.FrontPage}&nbsp;<small>{this.state.Front ? Assets_.PDF : ''}</small></h4>
                                    </div>
                                    {this.state.Front ?
                                    <div className = "pr" style = {{paddingLeft: this.state.frontExpanded ? '0%' : '0%', maxWidth: this.state.frontExpanded ? '100%' : '100%'}}>
                                        <Document
                                            className = {this.state.frontRotated ? 'rotate' + this.state.frontRotated : ''}
                                            style = {{textAlign: 'center', filter: 'brightness(' + this.state.frontBrightness + '0%) contrast(' + this.state.frontContrast + '0%)'}}
                                            file={this.state.Front}
                                            onLoadSuccess={this.onDocumentLoadSuccess3}
                                        >
                                            <Page pageNumber={this.state.pageNumber3}/>
                                        </Document>
                                        <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                                            <div className = "fl">{Assets.Page}:&nbsp;</div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber3 === 1 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber3: this.state.pageNumber3 - 1})}
                                                disabled = {this.state.pageNumber3 === 1}
                                            >
                                                <FontAwesomeIcon icon = {faMinusSquare}/>
                                            </button>
                                            <div
                                                style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                                className = "fl label label-success label-sm"
                                            >
                                                {this.state.pageNumber3}
                                            </div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber3 === this.state.numPages3 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber3: this.state.pageNumber3 + 1})}
                                                disabled = {this.state.pageNumber3 === this.state.numPages3}
                                            >
                                                <FontAwesomeIcon icon = {faPlusSquare}/>
                                            </button>

                                            <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                                            <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                                {this.state.numPages3}
                                            </div>
                                        </div>
                                    </div>: void 0}
                                    {this.state.front ? <img className = {this.state.frontRotated ? 'rotate' + this.state.frontRotated : ''} style = {{filter: 'brightness(' + this.state.frontBrightness + '0%) contrast(' + this.state.frontContrast + '0%)', maxWidth: this.state.frontExpanded ? '100%' : '30%', padding: '5px', border: '2px solid #999', borderRadius: '0px'}} src = {this.state.front}/> : void 0}
                                </div>
                            : void 0}
                            {this.state.Back || this.state.back ?
                                <div style = {{width: '100%', textAlign: 'center'/*, float: 'left'*/}}>
                                    <br/>
                                    <div className = "pr">
                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {this.state.backExpanded ? Assets.Compress : Assets.Expand}
                                            style = {{position: 'absolute', top: '-5px', left: '0px', zIndex: 9999}}
                                            onClick = {() => this.setState({backExpanded: !this.state.backExpanded})}
                                        >
                                            <FontAwesomeIcon icon = {this.state.backExpanded ? faCompress : faExpand}/>
                                        </button>

                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            value = {this.state.backBrightness}
                                            title = {Assets.Brightness}
                                            style = {{width: '200px', position: 'absolute', top: '0px', left: '70px', zIndex: 2}}
                                            onChange = {(e) => this.setState({backBrightness: +e.target.value})}
                                        />
                                        <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '0px', left: '45px', zIndex: 2}}/>

                                        <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '0px', right: '90px', zIndex: 2}}/>
                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            value = {this.state.backContrast}
                                            title = {Assets.Contrast}
                                            style = {{width: '200px', position: 'absolute', top: '0px', right: '115px', zIndex: 2}}
                                            onChange = {(e) => this.setState({backContrast: +e.target.value})}
                                        />

                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {Assets.Rotate}
                                            style = {{position: 'absolute', top: '-5px', right: '0px', zIndex: 9999}}
                                            onClick = {() => this.setState({backRotated: this.state.backRotated === 3 ? 0 : this.state.backRotated + 1})}
                                        >
                                            <FontAwesomeIcon icon = {faSyncAlt}/>
                                        </button>
                                        <span style = {{position: 'absolute', top: '-4px', right: '40px', zIndex: 9999, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                                            {this.state.backRotated * 90}°
                                        </span>                             
                                        <h4>{Assets.UploadedDocument}&nbsp;{Assets.BackPage}&nbsp;<small>{this.state.Back ? Assets_.PDF : ''}</small></h4>
                                    </div>
                                    {this.state.Back ?
                                    <div className = "pr" style = {{paddingLeft: this.state.backExpanded ? '0%' : '0%', maxWidth: this.state.backExpanded ? '100%' : '100%'}}>
                                        <Document
                                            className = {this.state.backRotated ? 'rotate' + this.state.backRotated : ''}
                                            style = {{textAlign: 'center', filter: 'brightness(' + this.state.backBrightness + '0%) contrast(' + this.state.backContrast + '0%)'}}
                                            file={this.state.Back}
                                            onLoadSuccess={this.onDocumentLoadSuccess4}
                                        >
                                            <Page pageNumber={this.state.pageNumber4}/>
                                        </Document>
                                        <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                                            <div className = "fl">{Assets.Page}:&nbsp;</div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber4 === 1 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber4: this.state.pageNumber4 - 1})}
                                                disabled = {this.state.pageNumber4 === 1}
                                            >
                                                <FontAwesomeIcon icon = {faMinusSquare}/>
                                            </button>
                                            <div
                                                style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                                className = "fl label label-success label-sm"
                                            >
                                                {this.state.pageNumber4}
                                            </div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber4 === this.state.numPages4 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber4: this.state.pageNumber4 + 1})}
                                                disabled = {this.state.pageNumber4 === this.state.numPages4}
                                            >
                                                <FontAwesomeIcon icon = {faPlusSquare}/>
                                            </button>

                                            <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                                            <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                                {this.state.numPages4}
                                            </div>
                                        </div>
                                    </div> : void 0}
                                    {this.state.back ? <img className = {this.state.backRotated ? 'rotate' + this.state.backRotated : ''} style = {{filter: 'brightness(' + this.state.backBrightness + '0%) contrast(' + this.state.backContrast + '0%)', maxWidth: this.state.backExpanded ? '100%' : '30%', padding: '5px', border: '2px solid #999', borderRadius: '0px'}} src = {this.state.back}/> : void 0}
                                </div>
                            : void 0}
                        </div>
                        <br/>
                        <div>
                            <span>{Assets.DocumentType}:</span>&nbsp;&nbsp;&nbsp;
                            <select disabled = {this.state.issueType === IssueTypes.VerifiedDocument} key = {Assets.Select} onChange = {(e) => this.Select(e.target.value)} value = {this.state.DocumentType} className = "mr">{this.getOptionsKYC('identification')}</select>
                            {/*<input style = {{marginRight: '10px'}} type = "text" placeholder = {Assets.DocumentType} value = {this.state.documentType ? this.state.documentType : ''} onChange={(e) => this.setState({documentType: e.target.value})}/>*/}
                            <span>{Assets.IssuingAuthority}:</span>&nbsp;&nbsp;&nbsp;
                            <input disabled = {this.state.issueType === IssueTypes.VerifiedDocument} style = {{marginRight: '10px'}} type = "text" placeholder = {Assets.IssuingAuthority} value = {this.state.issuingAuthority ? this.state.issuingAuthority : ''} onChange={(e) => this.setState({issuingAuthority: e.target.value})}/>
                            <span>{Assets.DocumentNumber}:</span>&nbsp;&nbsp;&nbsp;
                            <input disabled = {this.state.issueType === IssueTypes.VerifiedDocument} style = {{marginRight: '10px'}} type = "text" placeholder = {Assets.DocumentNumber} value = {this.state.documentNumber ? this.state.documentNumber : ''} onChange={(e) => this.setState({documentNumber: e.target.value})}/>
                            <span>{Assets.DocumentExpiryDate}:</span>&nbsp;&nbsp;&nbsp;
                            <input disabled = {this.state.issueType === IssueTypes.VerifiedDocument} style = {{marginRight: '10px'}} type = "text" placeholder = {Assets.DocumentExpiryDate} value = {this.state.documentExpiry ? this.state.documentExpiry : ''} onChange={(e) => this.setState({documentExpiry: e.target.value})}/>
                            <span>{Assets.DocumentIssueDate}:</span>&nbsp;&nbsp;&nbsp;
                            <input disabled = {this.state.issueType === IssueTypes.VerifiedDocument} style = {{marginRight: '10px'}} type = "text" placeholder = {Assets.DocumentIssueDate} value = {this.state.issueDate ? this.state.issueDate : ''} onChange={(e) => this.setState({issueDate: e.target.value})}/>
                        </div>
                        {this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <br/>}
                        {this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <div>
                            {Assets.crp3}
                        </div>}
                        {this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <>
                        <div>
                            <br/>
                            <div className = "mb5" style = {{padding: '5px', background: '#f0f0f0'}}>
                                <div style = {{fontWeight: 700}} className = "mb3">{Assets.BasicDataOfThisPlayerInThePlayerCard}</div>
                                <div>{Assets.FirstName}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.firstName}</div>
                                <div>{Assets.LastName}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.lastName}</div>
                                <div>{Assets.Birthday}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.birthday}</div>
                                <div>{Assets.Gender}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.gender === 'm' ? Assets.male : Assets.female}</div>
                                <div>{Assets.Nationality}:&nbsp;{this.getNationality(Main.Instance.DatabaseDataState.playerCard.nationality)}</div>
                            </div>                        

                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.crp4}</span>
                            {/*console.log (this.state.documentType, this.state.issuingAuthority, this.state.documentNumber, this.state.documentExpiry, this.state.issueDate)*/}
                            <button disabled = {this.state.DocumentType === undefined || this.state.issuingAuthority === undefined || this.state.documentNumber === undefined || this.state.documentExpiry === undefined || this.state.issueDate === undefined} onClick = {()=> this.setState({verified: true})} type = "button" className = {this.state.verified ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({verified: false})} type = "button" className = {!this.state.verified ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>
                        </>}
                        </> : void 0}

                        {/* UTILITY SECTION */}
                        {this.state.utility || this.state.Utility ?
                        <>
                        <div className = "clearfix">
                            <div style = {{width: '100%', textAlign: 'center'}}>
                                {/* faCompress faExpand faSyncAlt*/}
                                <h4>
                                    <div className = "pr">
                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {this.state.utilityExpanded ? Assets.Compress : Assets.Expand}
                                            style = {{position: 'absolute', top: '-5px', left: '0px', zIndex: 2}}
                                            onClick = {() => this.setState({utilityExpanded: !this.state.utilityExpanded})}
                                        >
                                            <FontAwesomeIcon icon = {this.state.utilityExpanded ? faCompress : faExpand}/>
                                        </button>

                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            value = {this.state.utilityBrightness}
                                            title = {Assets.Brightness}
                                            style = {{width: '200px', position: 'absolute', top: '0px', left: '70px', zIndex: 2}}
                                            onChange = {(e) => this.setState({utilityBrightness: +e.target.value})}
                                        />
                                        <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '0px', left: '45px', zIndex: 2}}/>

                                        <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '0px', right: '90px', zIndex: 2}}/>
                                        <input
                                            type = "range"
                                            min = "1"
                                            max = "20"
                                            value = {this.state.utilityContrast}
                                            title = {Assets.Contrast}
                                            style = {{width: '200px', position: 'absolute', top: '0px', right: '115px', zIndex: 2}}
                                            onChange = {(e) => this.setState({utilityContrast: +e.target.value})}
                                        />

                                        <button
                                            className = "btn btn-sm btn-default mr0"
                                            title = {Assets.Rotate}
                                            style = {{position: 'absolute', top: '-5px', right: '0px', zIndex: 2}}
                                            onClick = {() => this.setState({utilityRotated: this.state.utilityRotated === 3 ? 0 : this.state.utilityRotated + 1})}
                                        >
                                            <FontAwesomeIcon icon = {faSyncAlt}/>
                                        </button>
                                        <span style = {{position: 'absolute', top: '-4px', right: '40px', zIndex: 9999, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                                            {this.state.utilityRotated * 90}°
                                        </span>
                                        {Assets.UploadedUtility}&nbsp;<small>{this.state.Utility ? Assets_.PDF : ''}</small>
                                    </div>
                                </h4>
                                {this.state.Utility ?
                                <div className = "pr" style = {{paddingLeft: this.state.utilityExpanded ? '0%' : '0%', maxWidth: this.state.utilityExpanded ? '100%' : '100%'}}>
                                    <Document
                                        className = {this.state.utilityRotated ? 'rotate' + this.state.utilityRotated : ''}
                                        style = {{textAlign: 'center', filter: 'brightness(' + this.state.utilityBrightness + '0%) contrast(' + this.state.utilityContrast + '0%)'}}
                                        file={this.state.Utility}
                                        onLoadSuccess={this.onDocumentLoadSuccess5}
                                    >
                                        <Page pageNumber={this.state.pageNumber5}/>
                                    </Document>
                                    <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                                            <div className = "fl">{Assets.Page}:&nbsp;</div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber5 === 1 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber5: this.state.pageNumber5 - 1})}
                                                disabled = {this.state.pageNumber5 === 1}
                                            >
                                                <FontAwesomeIcon icon = {faMinusSquare}/>
                                            </button>
                                            <div
                                                style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                                className = "fl label label-success label-sm"
                                            >
                                                {this.state.pageNumber5}
                                            </div>
                                            <button
                                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber5 === this.state.numPages5 ? '' : '#5cb85c'}}
                                                type = "button"
                                                className = "fl btn btn-default btn-xs mr0"
                                                onClick = {() => this.setState({pageNumber5: this.state.pageNumber5 + 1})}
                                                disabled = {this.state.pageNumber5 === this.state.numPages5}
                                            >
                                                <FontAwesomeIcon icon = {faPlusSquare}/>
                                            </button>

                                            <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                                            <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                                {this.state.numPages5}
                                            </div>
                                        </div>
                                </div>: void 0}
                                {this.state.utility ? <img className = {this.state.utilityRotated ? 'rotate' + this.state.utilityRotated : ''} style = {{filter: 'brightness(' + this.state.utilityBrightness + '0%) contrast(' + this.state.utilityContrast + '0%)', maxWidth: this.state.utilityExpanded ? '100%' : '30%', padding: '5px', border: '2px solid #999', borderRadius: '0px'}} src = {this.state.utility}/> : void 0}
                            </div>
                        </div>
                        {this.state.issueType === IssueTypes.VerifiedUtility ? void 0 :
                        <div>
                            <br/>
                            <div className = "mb5" style = {{padding: '5px', background: '#f0f0f0'}}>
                                <div style = {{fontWeight: 700}} className = "mb3">{Assets.AddressOfThisPlayerInThePlayerCard}</div>
                                <div>{Assets.Address}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.address}</div>
                                <div>{Assets.StreetNumber}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.streetNumber}</div>
                                <div>{Assets.PostalCode}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.postalCode}</div>
                                <div>{Assets.City}:&nbsp;{Main.Instance.DatabaseDataState.playerCard.city}</div>
                                <div>{Assets.Country}:&nbsp;{this.getCountry(Main.Instance.DatabaseDataState.playerCard.country)} ({Main.Instance.DatabaseDataState.playerCard.country})</div>
                            </div>
                            
                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.crp5}</span>
                            <button onClick = {()=> this.setState({verified: true})} type = "button" className = {this.state.verified ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({verified: false})} type = "button" className = {!this.state.verified ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>}
                        </> : void 0}
                        {this.state.issueType === IssueTypes.SamePlayer || this.state.issueType === IssueTypes.BlacklistRegistration || this.state.issueType === IssueTypes.BlacklistLogin || this.state.issueType === IssueTypes.BlacklistCronJob || this.state.issueType === IssueTypes.BlacklistManual || this.state.issueType === IssueTypes.Retail ?
                        <>
                        <br/>
                        <div>
                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.UnblockPlayer}</span>
                            <button onClick = {()=> this.setState({unblock: true, comment: this.DefaultComment2()})} type = "button" className = {this.state.unblock ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({unblock: false, comment: this.defaultComment2()})} type = "button" className = {!this.state.unblock ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>
                        </> : void 0}
                        {this.state.issueType === IssueTypes.SamePlayer ? 
                        <>
                        {this.state.data.newPlayerInfo ?
                        <>
                        <br/>
                        <div>
                            <h4>{Assets.New + ' ' + Assets.Player}:</h4>
                            <div>
                                {this.getSamePlayer(this.state.data.newPlayerInfo)}
                            </div>
                        </div>
                        </> : void 0}
                        {this.state.data.otherPlayersInfo ?
                        <>
                        <br/>
                        <div>
                            <h4>{Assets.Other + ' ' + Assets.Players}:</h4>
                            <div>
                                {this.getSamePlayers(this.state.data.otherPlayersInfo)}
                            </div>
                        </div>
                        </> : void 0}
                        </> : void 0}


                        {this.state.issueType === IssueTypes.AccountClosedActive || this.state.issueType === IssueTypes.AccountClosedProvisional ?
                        <>
                        <br/>
                        <div>
                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.ClosePlayerAccount}</span>
                            <button onClick = {()=> this.setState({closed: true, comment: this.DefaultComment3()})} type = "button" className = {this.state.closed ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({closed: false, comment: this.defaultComment3()})} type = "button" className = {!this.state.closed ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>
                        </> : void 0}
                        {this.state.data && this.state.data.pepCheckResult && this.state.data.pepCheckResult.decision && (this.state.issueType === IssueTypes.PEPCheckRegistration || this.state.issueType === IssueTypes.PEPCheckLogin || this.state.issueType === IssueTypes.PEPChangedByOperator || /*this.state.issueType === IssueTypes.PEPCheckBeneficiaryName ||*/ this.state.issueType === IssueTypes.PEPCheckNameChanged) ?
                        <>
                            <br/>
                            <div style = {{fontSize: '16px'}} className = {
                                this.state.data.pepCheckResult.decision === 'GREEN' ?
                                'label label-success label-sm' :
                                this.state.data.pepCheckResult.decision === 'RED' || this.state.data.pepCheckResult.decision === 'DARK_RED' ?
                                'label label-danger label-sm' :
                                'label label-warning label-sm'}
                            >
                                {Assets.Decision}:&nbsp;{this.state.data.pepCheckResult.decision}
                            </div>
                        </>
                        :
                        this.state.data && this.state.data.addressCheckResult && this.state.data.addressCheckResult.character && (this.state.issueType === IssueTypes.CRIFAddressChangedOperator || /*this.state.issueType === IssueTypes.CRIFAddressChangedPlayer ||*/ this.state.issueType === IssueTypes.CRIFCheckMismatch || this.state.issueType === IssueTypes.CRIFCheckLogin || this.state.issueType === IssueTypes.CRIFCheckRegistration) ?
                        <>
                            <br/>
                            <div style = {{fontSize: '16px'}} className = {
                                this.state.data.addressCheckResult.character === 'IDENTITY' ?
                                'label label-success label-sm' :
                                this.state.data.addressCheckResult.character === 'NOT_CONFIRMED' ?
                                'label label-danger label-sm' :
                                'label label-warning label-sm'}
                            >
                                {Assets.Character}:&nbsp;{this.state.data.addressCheckResult.character}
                            </div> 
                        </>
                        :
                        void 0}

                        {this.state.issueType === IssueTypes.CRIFAddressChangedOperator ||
                        // this.state.issueType === IssueTypes.CRIFAddressChangedPlayer ||
                        this.state.issueType === IssueTypes.CRIFCheckMismatch ||
                        this.state.issueType === IssueTypes.CRIFCheckLogin ||
                        this.state.issueType === IssueTypes.CRIFCheckRegistration || 
                        this.state.issueType === IssueTypes.PEPCheckRegistration || 
                        this.state.issueType === IssueTypes.PEPCheckLogin || 
                        this.state.issueType === IssueTypes.PEPChangedByOperator || 
                        // this.state.issueType === IssueTypes.PEPCheckBeneficiaryName || 
                        this.state.issueType === IssueTypes.PEPCheckNameChanged
                        ?
                        <>
                            <br/>
                            <br/>
                            <p style = {{padding: '5px', border: '#999', background: '#ddd', color: '#333'}}>
                                {JSON.stringify(this.state.data)}
                            </p>
                        </>
                        : void 0}
                        {
                        this.state.issueType === IssueTypes.PEPCheckRegistration || 
                        this.state.issueType === IssueTypes.PEPCheckLogin || 
                        this.state.issueType === IssueTypes.PEPChangedByOperator || 
                        // this.state.issueType === IssueTypes.PEPCheckBeneficiaryName || 
                        this.state.issueType === IssueTypes.PEPCheckNameChanged ?
                        <div className = "clearfix mt">
                            <div className = "fl" style = {{width: '260px', background: /*Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isPEP ||*/ this.state.isPEP ? '#d9534f' : '#5cb85c', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                                {this.state.isPEP ? Assets.PoliticallyExposedPerson : Assets.NotAPoliticallyExposedPerson}
                            </div>
                            <button style = {{marginLeft: '4px'}} onClick = {() => this.togglePEP()} title = {Assets.Set + Assets._as_ + (Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isPEP ? Assets.NotAPoliticallyExposedPerson : Assets.PoliticallyExposedPerson)} className = "btn btn-default btn-dark btn-xs fl dbl mr0">{Assets.Change}</button>
                        </div>
                        : void 0}
                        {this.state.issueType === IssueTypes.CRIFAddressChangedOperator ||
                        // this.state.issueType === IssueTypes.CRIFAddressChangedPlayer ||
                        this.state.issueType === IssueTypes.CRIFCheckLogin ||
                        this.state.issueType === IssueTypes.CRIFCheckMismatch ||
                        this.state.issueType === IssueTypes.CRIFCheckRegistration ?
                        <div className = "clearfix mt">
                            <div className = "fl" style = {{width: /* Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? '' :*/ '260px', background: /*Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isAddressVerified ||*/ this.state.isAddressVerified ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                                {this.state.isAddressVerified ? Assets.AddressVerified : Assets.AddressNotVerified}
                            </div>
                            {/*Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? void 0 :*/}
                            <button style = {{marginLeft: '4px'}} onClick = {() => this.toggleVerifyAddressManually()} title = {Assets.Set + Assets._as_ + (Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? Assets.AddressVerified : Assets.AddressNotVerified)} className = "btn btn-default btn-dark btn-xs fl dbl mr0">{Assets.Change}</button>
                        </div>
                        : void 0}
                        <br/>
                        {this.state.issueType === IssueTypes.VerifiedUtility || this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <div>
                            <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.MarkAsResolved}</span>
                            <button onClick = {()=> this.setState({resolved: true, comment: this.DefaultComment()})} type = "button" className = {this.state.resolved ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.YES}
                            </button>
                            <button onClick = {()=> this.setState({resolved: false, comment: this.defaultComment()})} type = "button" className = {!this.state.resolved ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                                {Assets.NO}
                            </button>
                        </div>}

{/*
                        <div className = "mt5">
                            <span style = {{marginRight: '20px'}}>{Assets.CurrentRiskLevel}</span>
                            <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[this.state.currentRiskLevel]}>
                                {RiskLevel[this.state.currentRiskLevel]}
                                <input
                                    disabled = {true}
                                    value = {this.state.currentRiskLevel}
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    onChange = {(e) => this.setState({newRiskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                        <div className = "mt5">
                            <span className = "mr">{Assets.NewRiskLevel}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <button 
                                className = {'btn btn-xs mr0 risk risk-' + RiskLevel[this.state.newRiskLevel]}
                            >
                                {RiskLevel[this.state.newRiskLevel]}
                                <input
                                    value = {this.state.newRiskLevel}
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    onChange = {(e) => this.setState({newRiskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
*/}                     {this.state.issueType === IssueTypes.VerifiedUtility || this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                        <>
                        <br/>
                        <div>
                            <div className = "clearfix">
                                <div className = "fl">{Assets.LeaveAComment}</div>
                                <div className = "fr">
                                    {Assets.ThisIssueIsBeingResolvedBy + ' '}
                                    <strong>{Main.Instance.LoginState.Username}</strong>
                                    {' (ID ' + Main.Instance.LoginState.id + ')'}
                                </div>
                            </div>
                            <textarea
                                style = {{height: '68px', width: '100%', marginTop: '4px', lineHeight:'14px', padding: '5px', border: '1px solid #999', background: '#f8f8f8'}}
                                onChange = {(e) => this.setState({comment: e.target.value})}
                                value = {this.state.comment}
                            />
                        </div>
                        </>}
                    </div>
                    <div style = {{width: '100%', textAlign: 'center'}}>
                        <br/>
                        <div className = "pr">
                            <h4 className = "pr">
                                <input
                                    style = {{fontSize: '12px', position: 'absolute', left: '0px', top: '0px'}}
                                    type = "checkbox"
                                    checked = {this.state.uploadShowAvailableFiles}
                                    onChange = {() => this.setState({uploadShowAvailableFiles: !this.state.uploadShowAvailableFiles})}
                                />
                                <span style = {{fontSize: '12px', position: 'absolute', left: '17px', top: '5px'}}>
                                    {Assets.ShowAvailableFilesForPlayer}
                                </span>
                                {Assets.UploadFile}
                                <input
                                    style = {{fontSize: '12px', position: 'absolute', right: '0px', top: '-10px', width: '300px', marginTop: '-7px', marginLeft: '10px', marginRight: '0px'}}
                                    title = {Main.Instance.DatabaseDataState.playerCard.playerId}
                                    id = "fileUpload2"
                                    type = "file"
                                    className = "uploadLogoWrapper dbl"
                                    onClick = {(event) => event.target = null}
                                    // disabled = {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.SelectIssueType as number}
                                    onChange = {(event) => { 
                                        this.uploadFile (Main.Instance.DatabaseDataState.playerCard.playerId, 'fileUpload2')
                                    }}
                                />
                                <span style = {{fontSize: '12px', position: 'absolute', right: '305px', top: '4px'}}>
                                    {Assets.UploadANewFile}:
                                </span>
                            </h4>
                            {this.state.uploadShowAvailableFiles ? this.getAlreadyUploaded() : void 0}

                            {this.state.upload ?
                            <>
                            <div className = "pr">
                            <button
                                className = "btn btn-sm btn-default mr0"
                                title = {this.state.uploadExpanded ? Assets.Compress : Assets.Expand}
                                style = {{position: 'absolute', top: '-5px', left: '0px', zIndex: 9999}}
                                onClick = {() => this.setState({uploadExpanded: !this.state.uploadExpanded})}
                            >
                                <FontAwesomeIcon icon = {this.state.uploadExpanded ? faCompress : faExpand}/>
                            </button>

                            <input
                                type = "range"
                                min = "1"
                                max = "20"
                                value = {this.state.uploadBrightness}
                                title = {Assets.Brightness}
                                style = {{width: '200px', position: 'absolute', top: '0px', left: '70px', zIndex: 2}}
                                onChange = {(e) => this.setState({uploadBrightness: +e.target.value})}
                            />
                            <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '3px', left: '45px', zIndex: 2}}/>

                            <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '3px', right: '90px', zIndex: 2}}/>
                            <input
                                type = "range"
                                min = "1"
                                max = "20"
                                value = {this.state.uploadContrast}
                                title = {Assets.Contrast}
                                style = {{width: '200px', position: 'absolute', top: '0px', right: '115px', zIndex: 2}}
                                onChange = {(e) => this.setState({uploadContrast: +e.target.value})}
                            />

                            <button
                                className = "btn btn-sm btn-default mr0"
                                title = {Assets.Rotate}
                                style = {{position: 'absolute', top: '-5px', right: '0px', zIndex: 9999}}
                                onClick = {() => this.setState({uploadRotated: this.state.uploadRotated === 3 ? 0 : this.state.uploadRotated + 1})}
                            >
                                <FontAwesomeIcon icon = {faSyncAlt}/>
                            </button>
                            <span style = {{position: 'absolute', top: '-4px', right: '40px', zIndex: 9999, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                                {this.state.uploadRotated * 90}°
                            </span>

                            </div>
                            
                            {!this.state.uploadIsImage ?
                            <div className = "pr" style = {{paddingLeft: this.state.uploadExpanded ? '0%' : '0%', maxWidth: this.state.uploadExpanded ? '100%' : '100%'}}>
                                <Document
                                    className = {this.state.uploadRotated ? 'rotate' + this.state.uploadRotated : ''}
                                    style = {{textAlign: 'center', filter: 'brightness(' + this.state.uploadBrightness + '0%) contrast(' + this.state.uploadContrast + '0%)'}}
                                    file={this.state.upload}
                                    onLoadSuccess={this.onDocumentLoadSuccess6}
                                >
                                    <Page pageNumber={this.state.pageNumber6}/>
                                </Document>
                                <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                                    <div className = "fl">{Assets.Page}:&nbsp;</div>
                                    <button
                                        style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber6 === 1 ? '' : '#5cb85c'}}
                                        type = "button"
                                        className = "fl btn btn-default btn-xs mr0"
                                        onClick = {() => this.setState({pageNumber6: this.state.pageNumber6 - 1})}
                                        disabled = {this.state.pageNumber6 === 1}
                                    >
                                        <FontAwesomeIcon icon = {faMinusSquare}/>
                                    </button>
                                    <div
                                        style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                        className = "fl label label-success label-sm"
                                    >
                                        {this.state.pageNumber6}
                                    </div>
                                    <button
                                        style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: this.state.pageNumber6 === this.state.numPages6 ? '' : '#5cb85c'}}
                                        type = "button"
                                        className = "fl btn btn-default btn-xs mr0"
                                        onClick = {() => this.setState({pageNumber6: this.state.pageNumber6 + 1})}
                                        disabled = {this.state.pageNumber6 === this.state.numPages6}
                                    >
                                        <FontAwesomeIcon icon = {faPlusSquare}/>
                                    </button>

                                    <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                                    <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                        {this.state.numPages6}
                                    </div>
                                </div>
                            </div>
                            :
                            <img
                                className = {this.state.uploadRotated ? 'rotate' + this.state.uploadRotated : ''}
                                style = {{
                                    filter: 'brightness(' + this.state.uploadBrightness + '0%) contrast(' + this.state.uploadContrast + '0%)',
                                    maxWidth: this.state.uploadExpanded ? '100%' : '30%',
                                    padding: '5px',
                                    border: '2px solid #999',
                                    borderRadius: '0px'
                                }}
                                src = {this.state.upload}
                            />}
                            {this.state.uploadSelected !== -1 ? 
                            <div>
                                <button
                                    style = {{marginTop: '5px', marginBottom: '5px'}}
                                    disabled = {this.isThisFileAlreadyAttached()}
                                    className = "btn btn-primary btn-sm"
                                    onClick = {() => this.attachThisFile()}
                                >
                                    {Assets.AttachThisFile}
                                </button>
                            </div> : void 0}
                            </> : void 0}
                            {this.getAttached()}
                        </div>
                    </div>
                    {this.state.issueType === IssueTypes.VerifiedUtility || this.state.issueType === IssueTypes.VerifiedDocument ? void 0 :
                    <div>
                        <button className = "btn btn-xs btn-danger" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddCSCallbackModal: false, rowId: undefined})}>
                            {Assets.Cancel}
                        </button>
                        <button disabled = {this.state.comment === undefined || this.state.comment.length === 0 /*|| this.state.issueType === IssueTypes.UploadDocument && (this.state.issueDate === undefined || this.state.issueDate.length === 0 || this.state.issuingAuthority === undefined || this.state.issuingAuthority.length === 0 || this.state.DocumentType === undefined || this.state.DocumentType === '0' || this.state.documentExpiry === undefined || this.state.documentExpiry.length === 0 || this.state.documentNumber === undefined || this.state.documentNumber.length === 0)*/} className = "btn btn-xs btn-success" onClick = {() => this.resolve()}>
                            {Assets.Confirm}
                        </button>
                    </div>}
                </div>
            </div>
        )
    }
}

function getQuestion(id: number): string {
    if (Main.Instance.DatabaseDataState.saQuestions) {
        for (const item of Main.Instance.DatabaseDataState.saQuestions) {
            if (item.id === id) {
                for (const key in item.data.text) {
                    if (key.startsWith('en')) {
                        return item.data.text[key]
                    }
                }
            }
        }
    }
    return Assets.ThisQuestionIsUnknown
}

/*
interface SAQuestionsData {
    text: string[],
    points: number[],
    ratingPoints: number[],
    active: boolean    
}

export interface SAQuestions {
    id: number,
    data: SAQuestionsData
}
*/