import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {getRewardShopItems} from './SiteSettingsTable'
import {isUserSiteOwner, isUserOperator, isUserContractualPartner, isUserGameProvider, IsBuildScandibet, IsBuildTrekronor,AllowDecimalCharactersOnly,isUserAdmin,isPureScandibet,updateTranslation,updateCategory,
        getTranslationBody, getTranslationHeader} from '../../../Logic/Utils'
import {FormInput, RadioButton3, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {ButtonIcon} from '../../Reusables/Button'
import * as Picker from 'react-color'
import Switch from 'react-toggle-switch'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Flag} from '../../Reusables/Flag'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faSave, faEye, faTimes, faListOl, faTrashAlt, faImages, faImage, faSquare, faCircle, faEnvelope, faCheck, faCogs, faFont,faArrowsAltH, faArrowsAltV,
faCog, faFootballBall, faBasketballBall, faVolleyballBall, faDumbbell, faHeart, faSync, faExpand, faCompress} from '@fortawesome/free-solid-svg-icons'
import {Option} from '../../../Logic/Database/DatabaseData'

enum IAlignment {
    center = 0,
    left,
    right,
}

enum IBorder {
    none = 0,
    solid,
    dashed,
    dotted,
    double,
}

interface IDimensions {
    width: number
    height: number
}

interface IUserEntryItem {
    paddingTop: number
    paddingBottom: number
    paddingLeft: number
    paddingRight: number
    margin: number

    backgroundImage?: string
    backgroundRepeat?: number
    backgroundSize?: string
    backgroundDimensions: IDimensions

    backgroundColor?: string
    backgroundColor1?: string
    backgroundColor2?: string
    backgroundOrientation?: number

    backgroundOpacity?: string
    background: boolean
    
    TitleFamily?: string
    TitleColor?: string
    TitleSize?: number
    Title?: boolean
    TitleIcon?: string
    Titleicon?: string
    TitleWeight?: 100|200|300|400|500|600|700|800|900
    TitleItalic: boolean
    TitleAlignment: IAlignment

    titleFamily?: string
    titleColor?: string
    titleSize?: number
    titleIconSport?: boolean
    titleIconCategory?: boolean
    titleIconTournament?: boolean
    TitleIconSport?: boolean
    TitleIconCategory?: boolean
    TitleIconTournament?: boolean
    
    title?: boolean
    titleWeight?: 100|200|300|400|500|600|700|800|900
    titleItalic: boolean
    titleAlignment: IAlignment

    textFamily?: string
    textColor?: string
    textColorChosen?: string
    textColorHover?: string
    textSize?: number
    textWeight?: 100|200|300|400|500|600|700|800|900
    textItalic: boolean
    textAlignment: IAlignment

    borderWidth?: number
    borderColor?: string
    borderStyle?: IBorder
    borderRadius?: string
    borderLeft?: boolean
    borderRight?: boolean
    borderTop?: boolean
    borderBottom?: boolean
    border: boolean
}

enum IFavouriteType {
    sport = 1,
    category,
    tournament,
}

enum IMarketPresentation {
}

interface IUserEntry {
    favourites?: IUserEntryItem
    data?: IEntryData
}

interface ISport {
    id?: number
    english?: string
    show?: boolean
    showName?: boolean
    showImage?: boolean
    Show?: boolean
    showFavourites?: boolean
    ShowName?: boolean
    ShowImage?: boolean
    image?: string
    imageFilename?: string
    newName?: string
    order?: number
    Order?: number
    favourites?: boolean
    dimensions?: IDimensions
}

interface ICategory {
    id: number
    english: string
    show?: boolean
    showName?: boolean
    image: string
    imageFilename?: string
    newName?: string
    showImage?: boolean
    order?: number
    Order?: number
    favourites?: boolean
    Show?: boolean
    showFavourites?: boolean
    ShowName?: boolean
    ShowImage?: boolean
    dimensions?: IDimensions
}

interface ITournament {
    id: number
    english: string
    show?: boolean
    showName?: boolean
    image: string
    imageFilename?: string
    newName?: string
    showImage?: boolean
    order?: number
    Order?: number
    favourites?: boolean
    Show?: boolean
    showFavourites?: boolean
    ShowName?: boolean
    ShowImage?: boolean
    dimensions?: IDimensions
}

interface IFavourite {
    type?: IFavouriteType
    sport?: string
    category?: string
    id?: number
    Show?: boolean
    ShowName?: boolean
    name?: string
    image?: string
    imageFilename?: string
    Order?: number
    ShowImage?: boolean
    ShowFavourites?: boolean
    dimensions?: IDimensions
    defaultName?: string
}

interface IEntryData {
    sports?: ISport[]
    tournaments?: ITournament[]
    categories?: ICategory[]
    matches?: any[]
    favourites?: IFavourite[]
}

interface IPrimaryEvent {
    match?: IMatch
    image?: string
    leftHeading?: string
    rightHeading?: string
    text?: string
    link?: string
}

interface IMatch {
    id?: number
    primaryMarket?: number
}

interface ISecondaryEvent {
    matchv: IMatch
    markets?: IMarkets[]
}

interface IMarkets {
    type?: number
    presentation?: IMarketPresentation
}

interface IDatabase {
    sports?: ISport[]
    categories?: ICategory[]
    tournaments?: ITournament[]
    matches: any[]
}

interface IAdmin {
    modeGeneral?: boolean
    modeDaily?: boolean
    modeSports?: boolean
    modeTournaments?: boolean
    modeCategories?: boolean
    modeFavourites?: boolean
    modeMatches?: boolean
    modeDaily2?: boolean
    modeSports2?: boolean
    modeTournaments2?: boolean
    modeCategories2?: boolean
    modeFavourites2?: boolean
    modeMatches2?: boolean
}

interface ISportsBettingGeneral {
    admin: IAdmin
    database?: IDatabase
    userEntry?: IUserEntry
}

interface IDaily {
    primaryEvents?: IPrimaryEvent[]
    secondaryEvents?: ISecondaryEvent[]
}

interface ICustom {
    general: ISportsBettingGeneral
    daily: IDaily[]
}

function ModeRewardShop() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeRewardShop: !Main.Instance.FrontendStoreState.modeRewardShop, modeMinLevel: false, modeNotify: false, modeGeneral: false, modeTranslation: false,// , modeWidget: false
    },
)}

function ModeMinLevel() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeMinLevel: !Main.Instance.FrontendStoreState.modeMinLevel, modeRewardShop: false, modeNotify: false, modeGeneral: false, modeTranslation: false,// , modeWidget: false
    },
)}

function ModeNotify() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeNotify: !Main.Instance.FrontendStoreState.modeNotify, modeMinLevel: false, modeRewardShop: false, modeGeneral: false, modeTranslation: false,// , modeWidget: false
    },
)}

function ModeGeneral() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeGeneral: !Main.Instance.FrontendStoreState.modeGeneral, modeMinLevel: false, modeNotify: false, modeRewardShop: false, modeTranslation: false,// , modeWidget: false
    },
)}

function ModeTranslation() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {
        modeTranslation: !Main.Instance.FrontendStoreState.modeTranslation, modeMinLevel: false, modeNotify: false, modeGeneral: false, modeRewardShop: false,// , modeWidget: false
    },
)}

function ModeSportsBetting() {Logic.Type.New (Main.Instance.FrontendStoreState, {modeSportsBetting: !Main.Instance.FrontendStoreState.modeSportsBetting})}

function ModeGeneralSports() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeSports2 = !custom.general.admin.modeSports2
    if (custom.general.admin.modeSports2 === true) {
        custom.general.admin.modeFavourites2 = false
        custom.general.admin.modeMatches2 = false
        custom.general.admin.modeTournaments2 = false
        custom.general.admin.modeCategories2 = false
        custom.general.admin.modeDaily2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeGeneralCategories() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeCategories2 = !custom.general.admin.modeCategories2
    if (custom.general.admin.modeCategories2 === true) {
        custom.general.admin.modeFavourites2 = false
        custom.general.admin.modeMatches2 = false
        custom.general.admin.modeTournaments2 = false
        custom.general.admin.modeSports2 = false
        custom.general.admin.modeDaily2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeGeneralTournaments() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeTournaments2 = !custom.general.admin.modeTournaments2
    if (custom.general.admin.modeTournaments2 === true) {
        custom.general.admin.modeFavourites2 = false
        custom.general.admin.modeMatches2 = false
        custom.general.admin.modeCategories2 = false
        custom.general.admin.modeSports2 = false
        custom.general.admin.modeDaily2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeGeneralMatches() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeMatches2 = !custom.general.admin.modeMatches2
    if (custom.general.admin.modeMatches2 === true) {
        custom.general.admin.modeFavourites2 = false
        custom.general.admin.modeTournaments2 = false
        custom.general.admin.modeCategories2 = false
        custom.general.admin.modeSports2 = false
        custom.general.admin.modeDaily2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeGeneralFavourites() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeFavourites2 = !custom.general.admin.modeFavourites2
    if (custom.general.admin.modeFavourites2 === true) {
        custom.general.admin.modeMatches2 = false
        custom.general.admin.modeTournaments2 = false
        custom.general.admin.modeCategories2 = false
        custom.general.admin.modeSports2 = false
        custom.general.admin.modeDaily2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeGeneralDaily() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom.general.admin.modeDaily2 = !custom.general.admin.modeDaily2
    if (custom.general.admin.modeDaily2 === true) {
        custom.general.admin.modeMatches2 = false
        custom.general.admin.modeTournaments2 = false
        custom.general.admin.modeCategories2 = false
        custom.general.admin.modeSports2 = false
        custom.general.admin.modeFavourites2 = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingGeneral() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeGeneral'] = !custom['general']['admin']['modeGeneral']
    if (custom['general']['admin']['modeGeneral'] === true) {
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeTournaments'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingDaily() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeDaily'] = !custom['general']['admin']['modeDaily']
    if (custom['general']['admin']['modeDaily'] === true) {
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeTournaments'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingFavourites() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeFavourites'] = !custom['general']['admin']['modeFavourites']
    if (custom['general']['admin']['modeFavourites'] === true) {
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeTournaments'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingMatches() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeMatches'] = !custom['general']['admin']['modeMatches']
    if (custom['general']['admin']['modeMatches'] === true) {
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeTournaments'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingCategories() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeCategories'] = !custom['general']['admin']['modeCategories']
    if (custom['general']['admin']['modeCategories'] === true) {
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeTournaments'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingTournaments() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeTournaments'] = !custom['general']['admin']['modeTournaments']
    if (custom['general']['admin']['modeTournaments'] === true) {
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeSports'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ModeSportsBettingSports() {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    custom['general']['admin']['modeSports'] = !custom['general']['admin']['modeSports']
    if (custom['general']['admin']['modeSports'] === true) {
        custom['general']['admin']['modeDaily'] = false
        custom['general']['admin']['modeGeneral'] = false
        custom['general']['admin']['modeFavourites'] = false
        custom['general']['admin']['modeCategories'] = false
        custom['general']['admin']['modeMatches'] = false
        custom['general']['admin']['modeTournaments'] = false
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function TitleSizeChanged(text:string) {
    if (parseInt(text) > 0) {
        DbRoutes.ActionSite('UPDATE admin.test_site SET title_size = ' + text, Main.Instance.FrontendStoreState.site_id, TitleSizeChanged2, text)
    }
}

function TitleSizeChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {titleSize:parseInt(text)})    
}
    
    
function BorderRadiusChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {borderRadius:parseInt(text)})    
}
    
function BorderRadiusChanged(text:string) {
    if (parseInt(text) >= 0) {
        DbRoutes.ActionSite('UPDATE admin.test_site SET border_radius = ' + text, Main.Instance.FrontendStoreState.site_id, BorderRadiusChanged2, text)
    }
}

function TitleColorChanged(color_hex:string) {
    DbRoutes.ActionSite('UPDATE admin.test_site SET title_color = \'' + color_hex + '\'', Main.Instance.FrontendStoreState.site_id)
}

function HeightChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {shopItemHeight: parseInt(text)})    
}

function HeightChanged(text:string) {
    if (parseInt(text) > 0) {
        DbRoutes.ActionSite('UPDATE admin.test_site SET shop_item_height = ' + text, Main.Instance.FrontendStoreState.site_id, HeightChanged2, text)
    }
}

function ImageHeightChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {shopItemImageHeight:parseInt(text)})    
}

function ImageHeightChanged(text:string) {
    if (parseInt(text) > 0) {
            DbRoutes.ActionSite('UPDATE admin.test_site SET shop_item_image_height = ' + text, Main.Instance.FrontendStoreState.site_id, ImageHeightChanged2, text)
    }
}

function WidthChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {shopItemWidth:parseInt(text)})    
}

function WidthChanged(text:string) {
    if (parseInt(text) > 0) {
        DbRoutes.ActionSite('UPDATE admin.test_site SET shop_item_width = ' + text, Main.Instance.FrontendStoreState.site_id, WidthChanged2, text)
    }
}

function CoverWholeChanged2() {
    Logic.Type.New(Main.Instance.FrontendStoreState, {coverWhole: !Main.Instance.FrontendStoreState.coverWhole})
}

function CoverWholeChanged() {
    DbRoutes.ActionSite('UPDATE admin.test_site SET cover_whole ='+(!Main.Instance.FrontendStoreState.coverWhole).toString(), Main.Instance.FrontendStoreState.site_id, CoverWholeChanged2)
}

function BorderWidthChanged2(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {borderWidth:parseInt(text)})    
}

function BorderWidthChanged(text:string) {
    if (parseInt(text) >= 0) {
        DbRoutes.ActionSite('UPDATE admin.test_site SET border_width = ' + text, Main.Instance.FrontendStoreState.site_id, BorderWidthChanged2, text)
    }
}

function SelectCategory(rewardShopOpen: number) {
    if (rewardShopOpen === -1) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {rewardShopOpen: 0, pages: -1, BlackFriday: true}) 
    } else { Logic.Type.New (Main.Instance.FrontendStoreState, {rewardShopOpen, pages: -1, BlackFriday: false}) }
}

function GetCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.Category) {
        // console.log (Main.Instance.FrontendStoreState.Category)
        for (let i: number = 0; i < Main.Instance.FrontendStoreState.Category.length; i++) {
            if (i === 0 || i === 1) {continue}
            categories.push (
                <button
                    title = {Assets.Category + ' ' + Main.Instance.FrontendStoreState.Category[i]['name']}
                    key = {i.toString()}
                    style = {{marginRight: '0px', padding: '5px 8px', borderRadius: '0px', marginLeft: '-1px'}}
                    onClick = {() => SelectCategory (+Main.Instance.FrontendStoreState.Category[i]['id'])}
                    className = {+Main.Instance.FrontendStoreState.Category[i]['id'] === Main.Instance.FrontendStoreState.rewardShopOpen ?
                                countItemsInCategories(Main.Instance.FrontendStoreState.rewardShopOpen) === 0 ? 'btn btn-sm btn-danger' :
                                'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
                >
                    {Main.Instance.FrontendStoreState.Category[i]['name']}
                </button>,
            )
        }
    }
    return categories
}

function ApplyMenuChange(text:string, i: number) {
    const array:string[] = Object.assign([], Main.Instance.FrontendStoreState.menus)
    array[i] = text        
    Logic.Type.New (Main.Instance.FrontendStoreState, {menus: array})
}

function AddMenu() {
    DbRoutes.addMenu(LoadData)   
}

function DeleteMenu(i:number) {

    if (i === 0 && Main.Instance.FrontendStoreState.menus.length === 1) {
            alert (Assets.atLeastOneMenuItem)
    } else if (confirm(Assets.sureDeleteMenu + ' "' + Main.Instance.FrontendStoreState.menus[i] + '"?')) {

        DbRoutes.deleteMenu(i, LoadData)                
    }
}

function enableMenuSettings(i: number) {
    Logic.Type.New (Main.Instance.FrontendStoreState, {menuOpen: i})
}

function disableMenuSettings() {
    Logic.Type.New (Main.Instance.FrontendStoreState, {menuOpen: 0})
}


function addNewTranslation() {
    if (Main.Instance.DatabaseDataState.languages) {
        const translation = Object.assign([], Main.Instance.FrontendStoreState.translation)
        for (const {} of Main.Instance.DatabaseDataState.languages) {translation.push('')}
        Logic.Type.New (Main.Instance.FrontendStoreState, {translation})
    }
}

function setCategories(categories: boolean) {
    let count: number = categories ? Main.Instance.FrontendStoreState.category ? Main.Instance.FrontendStoreState.category.length : 0 : 
                        Main.Instance.FrontendStoreState.translation ? Main.Instance.FrontendStoreState.translation.length : 0
    count /= Main.Instance.DatabaseDataState.languagesStreamAll.length
    if (categories) { count-- }
    Logic.Type.New (Main.Instance.FrontendStoreState, {categories, offset: '0', pNum: 0, count})
}

function SaveData() {
    let proceed: boolean = true

    if (Main.Instance.FrontendStoreState.menus) {
    for (const item of Main.Instance.FrontendStoreState.menus) {
        if (item.length === 0) {
            proceed = false
            alert(Assets.menusWithoutTitle)
        }
    }
    }

    if (Main.Instance.FrontendStoreState.groups) {
    for (let i: number = 0; proceed && i < Main.Instance.FrontendStoreState.groups.length; i++) {
        if (Main.Instance.FrontendStoreState.groups[i].length === 0) {
            proceed = false
            alert(Assets.groupsWithoutTitle)
        }
    }
    }

    if (proceed && IsBuildTrekronor()) {
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        custom.general.userEntry.data.favourites = []

        for (const item of getFavouritesSport()) { custom.general.userEntry.data.favourites.push({
            defaultName: item.english,
            type: IFavouriteType.sport,
            id: +item.id,
            Show: item.Show,
            ShowFavourites: item.showFavourites,
            Order: item.Order,
            ShowName: item.ShowName,
            name: item.newName && item.newName.length > 0 ? item.newName : item.english,
            ShowImage: item.ShowImage,
            image: item.image,
            dimensions: item.dimensions,
        })
        }
        
        for (const item of getFavouritesCategory()) { custom.general.userEntry.data.favourites.push({
            type: IFavouriteType.category,
            defaultName: item.english,
            sport: capitalizeFirstLetter(item.property),
            id: item.id,
            Show: item.Show,
            ShowFavourites: item.showFavourites,
            Order: item.Order,
            ShowName: item.ShowName,
            name: item.newName && item.newName.length > 0 ? item.newName : item.english,
            ShowImage: item.ShowImage,
            image: item.image,
            dimensions: item.dimensions,
        })
        }

        for (const item of getFavouritesTournament()) { custom.general.userEntry.data.favourites.push({
            defaultName: item.english,
            type: IFavouriteType.tournament,
            sport: capitalizeFirstLetter(item.property),
            category: capitalizeFirstLetter(item.Property),
            id: item.id,
            Show: item.Show,
            ShowFavourites: item.showFavourites,
            Order: item.Order,
            ShowName: item.ShowName,
            name: item.newName && item.newName.length > 0 ? item.newName : item.english,
            ShowImage: item.ShowImage,
            image: item.image,
            dimensions: item.dimensions,
        })
        }
        // console.log (custom.general.userEntry.data.favourites)
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
        DbRoutes.insertData()
    } else if (proceed) { DbRoutes.insertData() }
}

function LoadData(callback?:() => void) {
    DbRoutes.loadData(callback)        
}

function ApplyMenuTypeSelect(i: number, text: string) {
    const array:string[] = Object.assign([], Main.Instance.FrontendStoreState.menus_type)
    array[i] = text
    Logic.Type.New (Main.Instance.FrontendStoreState, {menus_type: array})
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) !== 0) {
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(text))
    } else {
            Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: '0'}) 
    }
}

function AddNewImage() {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    images.push (Assets_.zeroPng)
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}

function RemoveImage(which: number) {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    images.splice (which, 1)
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}

function ReplaceImage(image: string, which: number) {
    const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
    // alert ('length: ' + image.length)
    // alert ('which:' + which)
    images[which] = image
    Logic.Type.New(Main.Instance.FrontendStoreState, {images})
}

export function CopyImages(lang: number, Images: number) {
    if (confirm(Assets.d19)) {
        // const Images: number = 12
        const images: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
        for (let i: number = 0; i < Images; i++) { images[Images * lang + i] = images[i] }
        Logic.Type.New (Main.Instance.FrontendStoreState, {images})
    }
}

function RetainDefaultLanguage(Images: number) {
    if (confirm(Assets.d18)) {
        // const Images: number = 12
        const images_source: string[] = Object.assign([], Main.Instance.FrontendStoreState.images)
        const images: string[] = []
        for (let i: number = 0; i < Images; i++) { images.push(images_source[i]) }
        Logic.Type.New (Main.Instance.FrontendStoreState, {images})
    }
}

function getImages(): JSX.Element[] {
    let length: number = 0
    if (Main.Instance.FrontendStoreState.images) { length = Math.ceil((Main.Instance.FrontendStoreState.images.length) / 4) }
    if (length < 1) { length = 1 }

    const images: JSX.Element[] = []

    if (Main.Instance.FrontendStoreState.images) {
    for (let i: number = 0; i < length; i++) {
    const lang: number = Math.floor (i / 3)
    if (Main.Instance.DatabaseDataState.language !== lang) { continue } 
/*
    const flagSource: number = !Main.Instance.DatabaseDataState.languagesStreamAll 
        || Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined :
        parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[lang]['flag']) - 1
    const flags: string = flagSource === undefined || isNaN(flagSource) || 
        !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
        Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource]['image']
*/
    const imagesDescription: string[] = Main.Instance.FrontendStoreState.custom.imagesDescription
    const imagesNumber: number = Main.Instance.FrontendStoreState.custom.imagesDescription.length + Main.Instance.FrontendStoreState.Category.length - 2
    images.push(
    <div
        key = {i.toString()}
        className = "clearfix light_grey"
        style = {{borderBottom: i === length - 1 ? '1px solid #ddd' : '0px', padding: '10px', position: 'relative', minHeight: i === 0 ? '90px' : undefined}}
    >
        {/*i > 0 ? void 0 :*/}
        {/*<img className = "img img responsive" style = {{position: 'absolute', left: '10px', top: '50px', height: '20px', border: '1px solid #333'}} src = {flags}/>*/}
        <h3 className = "fl" style = {{width: '50%'}}>
            {/*lang > 0 && i % 3 === 0 ? 
            <button
                disabled = {!isUserAdmin()}
                title = {'Copy images from default language (English)'}
                onClick = {() => CopyImages(lang, imagesNumber)}
                style = {{position: 'absolute', marginLeft: '0px', marginRight: '0px', left: '145px', top: '55px'}}
                className = "btn btn-danger btn-xs"
            >
                {Assets.Copy}
            </button>
            : void 0*/}
            {i === 1 && IsBuildScandibet() && !isPureScandibet() || i === 0 && !IsBuildScandibet() ?
            <button
                disabled = {!isUserAdmin()}
                title = {'Add/Delete images only if you know what you are doing!'}
                onClick = {() => AddNewImage()}
                style = {{position: 'absolute', marginLeft: '10px', marginRight: '0px', left: '0px', top: '15px'}}
                className = "btn btn-primary btn-sm"
            >
                {Assets.AddNewImage}
            </button> : 
            i % 3 === 0 && IsBuildScandibet() ?
            <span
                style = {{position: 'absolute', top: '15px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                className = "label label-success"
            >
                {/*<i className = {Assets_.faImageClass} style = {{marginRight: '7px'}}/>*/}
                <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faImages}/>
                {Assets.Images}
            </span>
            : void 0}
            {i === 2 && IsBuildScandibet() && !isPureScandibet() ? 
            <button
                disabled = {!isUserAdmin()}
                title = {'Deletes all other images except default language. Please use with caution!'}
                onClick = {() => RetainDefaultLanguage(imagesNumber)}
                style = {{position: 'absolute', marginLeft: '10px', marginRight: '0px', left: '0px', top: '15px'}}
                className = "btn btn-danger btn-sm"
            >
                {Assets.RetainDefaultLang_}
            </button>
            : void 0 }

            <span className = "fl" style = {{width: '120px', marginTop: i === 0 || i === 1 && IsBuildScandibet() || i === 2 && IsBuildScandibet() && !isPureScandibet() ? '20px' : '0px'}}>
                {/*Assets.Images + ' ' + (i + 1) + '/' + length*/}
                &nbsp;
            </span>
            {Main.Instance.FrontendStoreState.images[4 * i + 0] && Main.Instance.FrontendStoreState.images[4 * i + 0].length > 0 ?
            <div
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 1 ? '' : i === 4 ? 'comingsoon' : ''}
                // onClick = {() => i === 4 ? comingSoon(1) : void 0}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 1 ? '3px solid #a00' :*/ '1px solid #999',
                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 0] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 0].length < 150 ? 'now empty'
                    : /*isPureScandibet() ? '69.4 kB' :*/
                    (Main.Instance.FrontendStoreState.images[4 * i + 0].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}
                {isPureScandibet() ? void 0 :
                <button
                    onClick = {() => RemoveImage(4 * i + 0)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>}
                <img
                 src = {/*i === 0 && isPureScandibet() ? Assets.scandibetShopSmall : */Main.Instance.FrontendStoreState.images[4 * i + 0]}
                 className = {isPureScandibet() ? 
                 Main.Instance.FrontendStoreState.activeBlackFriday && Main.Instance.FrontendStoreState.blackFriday && Main.Instance.FrontendStoreState.blackFriday.length > Assets_.validImageFileLength
                 || Main.Instance.FrontendStoreState.activeChristmas && Main.Instance.FrontendStoreState.christmas && Main.Instance.FrontendStoreState.christmas.length > Assets_.validImageFileLength ?
                 'img img-responsive Grayscale' : 'img img-responsive' : 'img img-responsive'}
                 style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 ?
            <div
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 2 ? '' : i === 4 ? 'comingsoon' : ''}
                // onClick = {() => i === 4 ? comingSoon(2) : void 0}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 2 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',

                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 1] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 1].length < 150 ? 'now empty'
                    : (Main.Instance.FrontendStoreState.images[4 * i + 1].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}
                {isPureScandibet() ? void 0 :
                <button
                    onClick = {() => RemoveImage(4 * i + 1)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>}
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 1]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 ?
            <div
                // onClick = {() => i === 4 ? comingSoon(3) : void 0}
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 3 ? '' : i === 4 ? 'comingsoon' : ''}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    float: 'left',
                    marginTop: '-15px',
                    border: /* i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 3 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',
                    padding: '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 2] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 2].length < 150 ? 'now empty'
                    : (Main.Instance.FrontendStoreState.images[4 * i + 2].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}
                {isPureScandibet() ? void 0 :
                <button
                    onClick = {() => RemoveImage(4 * i + 2)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>}
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 2]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 ?
            <div
                // onClick = {() => i === 4 ? comingSoon(4) : void 0}
                // className = {Main.Instance.FrontendStoreState.custom['comingsoon'] === 4 ? '' : i === 4 ? 'comingsoon' : ''}
                style = {{
                    cursor: /*i === 4 ? 'pointer' :*/ 'default',
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    float: 'left',
                    marginTop: '-15px',
                    border: /*i === 4 && Main.Instance.FrontendStoreState.custom['comingsoon'] === 4 ? '3px solid #a00' :*/ '1px solid #999',
                    marginLeft: '1%',
                    padding:  '5px',
                    height: '60px',
                    position: 'relative',
                }}
            >
                {Main.Instance.FrontendStoreState.images[4 * i + 3] === undefined ? void 0 :
                <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                    {Main.Instance.FrontendStoreState.images[4 * i + 3].length < 150 ? 'now empty'
                    : (Main.Instance.FrontendStoreState.images[4 * i + 3].length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>}
                {isPureScandibet() ? void 0 :
                <button
                    onClick = {() => RemoveImage(4 * i + 3)}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>}
                <img 
                    src = {Main.Instance.FrontendStoreState.images[4 * i + 3]}
                    className = "img img-responsive"
                    style = {{height: '50px'}}
                />
            </div>
            : void 0}
        </h3>
        {/*alert(JSON.stringify(Main.Instance.FrontendStoreState.Category))*/}
        {/*console.log((4 * (i - 1) - imagesDescription.length + 6 - lang * imagesNumber) + ' - ' + (4 * (i - 1) - imagesDescription.length + 9 - lang * imagesNumber))*/}

        {Main.Instance.FrontendStoreState.Category === undefined || Main.Instance.FrontendStoreState.Category.length === 0 || imagesDescription === undefined ? void 0 :
        <>
            {Main.Instance.FrontendStoreState.images[4 * i + 0] && Main.Instance.FrontendStoreState.images[4 * i + 0].length > 0 && Main.Instance.FrontendStoreState.Category[0] ?
            <div style = {{position: 'absolute', left: '50%', top: '12px'}}>
            {imagesDescription.length > 4 * i + 0 - lang * imagesNumber ?
            <span title = {Assets.CoreImage} className = "label label-primary label-sm" style = {{fontSize: '13px'}}>
                {imagesDescription[4 * i + 0 - lang * imagesNumber]}
            </span>
            :
            <span title = {Assets.CategoryImage} className = "label label-info label-sm" style = {{fontSize: '13px'}}>
                {/*Assets.Category + ' - ' + */Main.Instance.FrontendStoreState.Category[4 * (i - 1) - imagesDescription.length + 6 - lang * imagesNumber]['name']}
            </span>}
            </div> : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 && Main.Instance.FrontendStoreState.Category[0] ?
            <div style = {{position: 'absolute', left: '62.5%', top: '12px'}}>
            {imagesDescription.length > 4 * i + 1 - lang * imagesNumber ? 
            <span title = {Assets.CoreImage} className = "label label-primary label-sm" style = {{fontSize: '13px'}}>
                {imagesDescription[4 * i + 1 - lang * imagesNumber]}
            </span> 
            : 
            <span title = {Assets.CategoryImage} className = "label label-info label-sm" style = {{fontSize: '13px'}}>
                {/*Assets.Category + ' - ' + */Main.Instance.FrontendStoreState.Category[4 * (i - 1) - imagesDescription.length + 7 - lang * imagesNumber]['name']}
            </span>}
            </div> : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 && Main.Instance.FrontendStoreState.Category[0] ?
            <div style = {{position: 'absolute', left: '75%', top: '12px'}}>
            {imagesDescription.length > 4 * i + 2 - lang * imagesNumber ? 
            <span title = {Assets.CoreImage} className = "label label-primary label-sm" style = {{fontSize: '13px'}}>
                {imagesDescription[4 * i + 2 - lang * imagesNumber]}
            </span> 
            :
            <span title = {Assets.CategoryImage} className = "label label-info label-sm" style = {{fontSize: '13px'}}>
                {/*Assets.Category + ' - ' + */Main.Instance.FrontendStoreState.Category[4 * (i - 1) - imagesDescription.length + 8 - lang * imagesNumber]['name']}
            </span>}
            </div> : void 0}
            {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 && Main.Instance.FrontendStoreState.Category[0] ?
            <div style = {{position: 'absolute', left: '87.5%', top: '12px'}}>
            {imagesDescription.length > 4 * i + 3 - lang * imagesNumber ? 
            <span title = {Assets.CoreImage} className = "label label-primary label-sm" style = {{fontSize: '13px'}}>
                {imagesDescription[4 * i + 3 - lang * imagesNumber]}
            </span>
            :
            <span title = {Assets.CategoryImage} className = "label label-info label-sm" style = {{fontSize: '13px'}}>
                {/*Assets.Category + ' - ' + */Main.Instance.FrontendStoreState.Category[4 * (i - 1) - imagesDescription.length + 9 - lang * imagesNumber]['name']}
            </span>}
            </div> : void 0}
        </>}
        {/*<img id = "imagetemporary" style = {{display: 'none'}}/>
        <canvas id = "canvastemporary" style = {{display: 'none'}}/>*/}

        {Main.Instance.FrontendStoreState.images[4 * i + 0] && Main.Instance.FrontendStoreState.images[4 * i + 0].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            accept = "image/png, image/jpg, image/jpeg, image/gif, image/svg+xml"
            className = "uploadLogoWrapper"
            style = {{width: '12%', marginRight:'0.6666667%'}}
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    // let scale: number = 1
                    if (IsBuildScandibet() && !isPureScandibet()) {

                    } else if (IsBuildScandibet()) {
                        if (IsBuildScandibet() && i % 3 === 2) { // last row is different

                        }//  else scale = 1/2.6421
                    }

                    /*if (scale < 1) replaceImage(reader.result, 4 * i + 0, scale)
                    else*/
                    ReplaceImage(reader.result as string, 4 * i + 0)
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 1] && Main.Instance.FrontendStoreState.images[4 * i + 1].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight:'0.6666667%'}}
            accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    // let scale: number = 1
                    if (IsBuildScandibet() && !isPureScandibet()) {

                    } else if (IsBuildScandibet()) {
                        if (IsBuildScandibet() && i % 3 === 2) { // last row is different

                        }//  else scale = 1/2.6421
                    }

                    // alert (reader.result.length + ' ' + scale)
                    
                    /*if (scale < 1) replaceImage(reader.result, 4 * i + 1, scale)
                    else*/
                    ReplaceImage(reader.result as string, 4 * i + 1)
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => {event.target = null}}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 2] && Main.Instance.FrontendStoreState.images[4 * i + 2].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight:'0.6666667%'}}
            accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    // let scale: number = 1
                    if (IsBuildScandibet() && !isPureScandibet()) {

                    } else if (IsBuildScandibet()) {
                        if (IsBuildScandibet() && i % 3 === 2) { // last row is different

                        }//  else scale = 1/2.6421
                    }

                    /*if (scale < 1) replaceImage(reader.result, 4 * i + 2, scale)
                    else*/
                    ReplaceImage(reader.result as string, 4 * i + 2)
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

        {Main.Instance.FrontendStoreState.images[4 * i + 3] && Main.Instance.FrontendStoreState.images[4 * i + 3].length > 0 ?
        <input
            readOnly = {!isUserAdmin()}
            style = {{width: '12%', marginRight: '0px'}}
            accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
            className = "uploadLogoWrapper"
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    // let scale: number = 1
                    if (IsBuildScandibet() && !isPureScandibet()) {

                    } else if (IsBuildScandibet()) {
                        // scale = 1/2.6421
/*                        
                        if (IsBuildScandibet() && i % 3 === 2) { // last row is different

                        } else scale = 1/2.6421
*/
                    }

                    /*if (scale < 1) replaceImage(reader.result, 4 * i + 3, scale)
                    else*/
                    ReplaceImage(reader.result as string, 4 * i + 3)
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => event.target = null}
        /> : void 0}

    </div>)
    }
    }
    return images
}

export function ApplySiteSelect(text:string) {
        
    Logic.Type.New(Main.Instance.FrontendStoreState, {
            site_id:text,
    })

    if (parseInt(text) !== 0) {
            LoadData()
    }
}

function SelectSite(stream: any[]) {
    // console.log (stream)
    // console.log ('SelectSite')
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetAllLanguages()
        DbRoutes.getLevelsAllBySite(3, stream[0]['id'], false)
        LoadData()
    }
}


export function getTranslations() {
    // Logic.Type.New(Main.Instance.FrontendStoreState, {categories: Main.Instance.FrontendStoreState.categories})
    Logic.Type.New(Main.Instance.FrontendStoreState, {offset: '0', pNum: 0})
    // Logic.Type.New(Main.Instance.FrontendStoreState, {categories: !Main.Instance.FrontendStoreState.categories})
    // Logic.Type.New(Main.Instance.FrontendStoreState, {categories: !Main.Instance.FrontendStoreState.categories})
}

function FirstPage() {

    Logic.Type.New(Main.Instance.FrontendStoreState,{offset:(0).toString()})

    // getTranslations()
    Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: 0})
}

function PrevPage() {
    if (Main.Instance.FrontendStoreState.pNum > 0) {

        Logic.Type.New(Main.Instance.FrontendStoreState, {
            offset:((Main.Instance.FrontendStoreState.pNum - 1) * 
            Main.Instance.FrontendStoreState.pSize).toString()})
        // getTranslations()

        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: Main.Instance.FrontendStoreState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.FrontendStoreState.pNum + 1) * Main.Instance.FrontendStoreState.pSize < Main.Instance.FrontendStoreState.count) {

        Logic.Type.New(Main.Instance.FrontendStoreState, {
            offset: ((Main.Instance.FrontendStoreState.pNum + 1) * 
            Main.Instance.FrontendStoreState.pSize).toString()})
        // getTranslations()
        
        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: Main.Instance.FrontendStoreState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.FrontendStoreState.count <= Main.Instance.FrontendStoreState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.FrontendStoreState.count === 0) {

            Logic.Type.New(Main.Instance.FrontendStoreState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.FrontendStoreState.count - 1) / Main.Instance.FrontendStoreState.pSize)
            Logic.Type.New(Main.Instance.FrontendStoreState, {
                offset:(npNum * Main.Instance.FrontendStoreState.pSize).toString()})
        }

        // getTranslations()

        Logic.Type.New(Main.Instance.FrontendStoreState,{pNum: npNum})
    }
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.FrontendStoreState,{pSize:parseInt(text)})
        FirstPage()
    }
}

export function SiteSettings(/*stream: any[]*/) {
    // console.log (Main.Instance.FrontendStoreState.custom)
    // console.log ('bla')
    // Logic.Type.New
    // console.log (Main.Instance.DatabaseDataState.levelsAllStream)
/*
    if (!Main.Instance.FrontendStoreState.widgetContent) {
        const client = Contentful.createClient(contentful)
    }
*/
    if (!Main.Instance.FrontendStoreState.menu_type) {
        // console.log (Assets.MenuType)
        const menu_type: Option[] = []
        for (const item of Assets.MenuType) { menu_type.push({id:menu_type.length.toString(),name: item}) }
        Logic.Type.New (Main.Instance.FrontendStoreState, {menu_type})
    }
    // console.log ('here')
    // console.log (Main.Instance.FrontendStoreState.logo)
    if (Main.Instance.FrontendStoreState.first_time/* || Main.Instance.FrontendStoreState.subdomain === undefined*/) {
        // console.log ('here1')
        // console.log (Main.Instance.FrontendStoreState.custom.categoriesPositioning)
        DbRoutes.GetFlags()
        DbRoutes.getRewardShop()
        DbRoutes.GetBanner()
        DbRoutes.GetAllLanguages()
        if (isUserGameProvider()) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {first_time: false, group_filter_id: '0'/*, subdomain: 'temporary'*/})
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
/*
        } else if (isUserOperator()) {
            Logic.Type.New(Main.Instance.FrontendStoreState, {
                    first_time: false,
                    operator_id:Main.Instance.LoginState.entityId})
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId))
*/
        } else if (isUserSiteOwner() || isUserOperator()) {
            // console.log ('here')
            Logic.Type.New(Main.Instance.FrontendStoreState, {first_time: false, operator_id: Main.Instance.LoginState.entityId/*, subdomain: 'temporary'*/})
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        } else { Logic.Type.New(Main.Instance.FrontendStoreState, {first_time: false}) }
    }

    // if (!Main.Instance.DatabaseDataState.FlagsStreamAll) console.log ('no flags')
    // if (!Main.Instance.DatabaseDataState.languagesStreamAll) console.log ('no langs')

    if (!Main.Instance.DatabaseDataState.FlagsStreamAll || !Main.Instance.DatabaseDataState.languagesStreamAll) { return <div/> }
    // console.log (Main.Instance.FrontendStoreState.custom)

    if (Main.Instance.FrontendStoreState.custom !== undefined && IsBuildTrekronor()) {
        if (
            (Main.Instance.FrontendStoreState.custom['general']['database']['sports'] && ( 
                Main.Instance.FrontendStoreState.custom['general']['userEntry'] === undefined ||
                Main.Instance.FrontendStoreState.custom['general']['userEntry']['data'] === undefined ||
                Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'] === undefined ||
                Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'] &&
                Main.Instance.FrontendStoreState.custom['general']['database']['sports'].length > 
                Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'].length
            ))

        ) {
        
        // INITIALIZE
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        
        // SPORTS
        if (
            custom['general']['database']['sports'] && (
                custom['general']['userEntry'] === undefined ||
                custom['general']['userEntry']['data'] === undefined ||
                custom['general']['userEntry']['data']['sports'] === undefined/* ||
                custom['general']['userEntry']['data']['sports'] && custom['general']['database']['sports'].length >
                custom['general']['userEntry']['data']['sports'].length*/
                )
            ) {
            if (custom['general']['userEntry'] === undefined) { custom['general']['userEntry'] = {} }
            if (custom['general']['userEntry']['data'] === undefined) { custom['general']['userEntry']['data'] = {} }
            custom['general']['userEntry']['data']['sports'] = []
            for (const item of custom['general']['database']['sports']) {
                custom['general']['userEntry']['data']['sports'].push(
                    {dimensions:{width: 0, height: 0},
                    id: item.id, english: item.english, image: item.image, show: false, newName: '', showImage: false, showName: false, order: 0, favourites: false})
            }
        }
        
        // SET
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
        // console.log (Main.Instance.FrontendStoreState.custom)
        }
    }

    
    const menus: JSX.Element[] = []
    let j: number = 0

    for (let i = 0; Main.Instance.FrontendStoreState.menus && i < Main.Instance.FrontendStoreState.menus.length; i++) {
            if (!Main.Instance.FrontendStoreState.menus) {
                    continue
            }
            menus.push(
            <FormInput 
                key = {j.toString()}
                value = {!Main.Instance.FrontendStoreState.menus[i] ? '' :
                        Main.Instance.FrontendStoreState.menus[i]}
                ClassName = "form-control register-input"
                type = "text"
                divClass = "input-test-site2"
                onChange = {(text) => ApplyMenuChange (text, i)}

                placeholder = {Assets.Menu + ' ' + (i+1).toString()}
                iconNumber = {(i+1).toString()}
                
                bg = {
                    !(parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_GAME_PROGRESS  ||
                    parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_POINTS_LEVELS)
                    ?                                        
                    i + 1 === Main.Instance.FrontendStoreState.menuOpen ?
                    'rgb(0,84,135)' : 
                    undefined :
                    /*'#337ab7'*/'#bbb'
                }
                handleClick = {
                    !(parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_GAME_PROGRESS  ||
                    parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_POINTS_LEVELS)
                    ?
                    () => enableMenuSettings(i + 1) : 
                    undefined
                }
                thick = {i +1 === Main.Instance.FrontendStoreState.menuOpen}
                color = {
                    !(parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_GAME_PROGRESS  ||
                    parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === 
                    Assets_.MENU_TYPE.MT_POINTS_LEVELS)
                    ?
                    i + 1 === Main.Instance.FrontendStoreState.menuOpen ? 
                    '#fff' : 
                    undefined :
                    /*'#fff'*/'#555'
                }
                icon = {Assets_.faPuzzlePieceClass}
            >
                <span   
                        className = "form-control-feedback"
                        style = {Main.Instance.FrontendStoreState.menus[i].length > 0 ?
                                        {color:'#00aa00',zIndex:3,fontWeight:700} :
                                        {color:'#aa0000',zIndex:3,fontWeight:700}}
                >
                {parseInt(Main.Instance.FrontendStoreState.menus_type[i]) === undefined ? void 0 :
                <i className = {Assets_.MTI[parseInt(Main.Instance.FrontendStoreState.menus_type[i])]}/>}
                </span>
            </FormInput>,
            )

            j++
            {/*<h3 key = {j.toString()} style = {{width:'25%', float:'left', paddingLeft: '10px'}}>*/}

            if ((i+1) % 5 === 0 && i !== Main.Instance.FrontendStoreState.menus.length - 1) {
                menus.push(
                        <h3 
                            key = {j.toString()}
                            style = {{width:'12%',
                                        float:'left',
                                        marginLeft:'10px',
                                        marginTop:'0px !important'}}
                        >
                                <small style = {{marginTop:'-10px', display:'block'}}>&nbsp;</small>
                        </h3>,
                )
                j++
            }
    }

    if (Main.Instance.FrontendStoreState.menuOpen !== 0 && 
        Main.Instance.FrontendStoreState.menuOpen <= Main.Instance.FrontendStoreState.menus.length) {
            // menus.push(<hr key = "hr"/>)
            menus.push(<div key = "div-reward-shop" className = "clearfix"/>)
            menus.push(
                <h3 
                    key = "h3"
                    style = {{
                        width:'12%',
                        float:'left',
                        // WebkitBoxSizing: 'border-box',
                        // MozBoxSizing: 'border-box',
                        // boxSizing: 'border-box',
                        marginLeft:'10px',
                        marginTop:'0px !important',
                    }}
                >
                    <small  
                        className = "label" 
                        style = {{
                            marginTop:'-13px',
                            fontSize:'65%',
                            background: 'rgb(0,84,135)',
                            paddingTop:'0.4em',
                            // marginLeft:'10px',
                            marginRight:'10px',
                            display:'block',
                        }}
                    >
                        {Main.Instance.FrontendStoreState.menus[Main.Instance.FrontendStoreState.menuOpen - 1] ?
                        Main.Instance.FrontendStoreState.menus[Main.Instance.FrontendStoreState.menuOpen - 1] :
                        Assets.Menu + ' ' + Main.Instance.FrontendStoreState.menuOpen}
                    </small>
                </h3>,
            )
            // console.log (Main.Instance.FrontendStoreState.menu_type)
            menus.push(
                <FormSelect 
                    key = "select-type"
                    value = {Main.Instance.FrontendStoreState.menus_type[Main.Instance.FrontendStoreState.menuOpen-1]}
                    data = {Main.Instance.FrontendStoreState.menu_type}
                    // background = "#23527c"
                    // color = "#fff"
                    level165 = {true}
                    ClassName = "form-control"
                    onChange = {(text) => ApplyMenuTypeSelect(Main.Instance.FrontendStoreState.menuOpen - 1, text)}
                    icon = {Assets_.faHamburgerClass}
                />,
            )

            if (Main.Instance.FrontendStoreState.menuOpen > 0 && 
                parseInt(Main.Instance.FrontendStoreState.menus_type[Main.Instance.FrontendStoreState.menuOpen - 1]) === 
                Assets_.MENU_TYPE.MT_REWARD_SHOP) {
                    menus.push (
                        <span 
                            key = "number-of-items"
                            style = {{width:'14.85%', marginRight:'1.65%', fontSize:'16px', marginTop:'7px', paddingTop:'0.4em'}}
                            className = "fl label label-default"
                        >
                            {Main.Instance.FrontendStoreState.shop_items.length + ' '}
                            {Main.Instance.FrontendStoreState.shop_items.length === 1 ? 
                            Assets.item : Assets.items}
                        </span>,
                    )

            }

            menus.push(
                <button 
                    key = "button-delete"
                    className = "fl btn btn-danger btn-sm"
                    style = {{marginTop:'5px', width:'100px'}}
                    onClick = {() => DeleteMenu(Main.Instance.FrontendStoreState.menuOpen - 1)}
                >
                    <i className = {Assets_.faTrashClass}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {Assets.Delete}
                </button>,
            )

            menus.push(
                <button 
                    key = "button-close"
                    className = "btn btn-default btn-sm fr"
                    style = {{marginTop:'5px'}}
                    onClick = {() => disableMenuSettings()}
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>,
            )
    }
    
    if (Main.Instance.DatabaseDataState.languages !== undefined &&
    Main.Instance.FrontendStoreState.translation !== undefined && 
    Main.Instance.FrontendStoreState.Translation === undefined) {

    // if (!Main.Instance.FrontendStoreState.translation || Main.Instance.FrontendStoreState.translation.length === 0) {}
    updateTranslation()
    }

    if (
        Main.Instance.DatabaseDataState.languages !== undefined &&
        Main.Instance.FrontendStoreState.category !== undefined && 
        Main.Instance.FrontendStoreState.Category === undefined) {
        // console.log (Main.Instance.FrontendStoreState.category.length)
        // console.log (Main.Instance.DatabaseDataState.languages.length)
        if (!Main.Instance.FrontendStoreState.category || Main.Instance.FrontendStoreState.category.length === 0) {
            const category: string[] = []
            for (const {} of Main.Instance.DatabaseDataState.languages) {
                category.push(Assets_.Uncategorized)
            }
            Logic.Type.New (Main.Instance.FrontendStoreState, {category})
        }
        // console.log (Main.Instance.FrontendStoreState.category.length)
        updateCategory()
        // console.log ('update category')
    }

    if (Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.FrontendStoreState.category && 
        Main.Instance.FrontendStoreState.count === 0 && Main.Instance.FrontendStoreState.category.length !== 0) {
        const count: number = Main.Instance.FrontendStoreState.category.length / Main.Instance.DatabaseDataState.languagesStreamAll.length - 1
        if (count !== Main.Instance.FrontendStoreState.count) { Logic.Type.New (Main.Instance.FrontendStoreState, {count}) }
    }

    return (
    <div className = "clearfix">
        <div className = "clearfix" style = {{marginBottom:'10px'}}>
            <h3 style = {{width: '15%'}} className = "level fl ml">{Assets.SiteSettings}</h3>
            {isUserGameProvider() ? 
            <FormSelect
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' : Main.Instance.FrontendStoreState.operator_id}
                level = {true}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                ClassName = "form-control"
                o0 = "1"
                o1 = {Assets.SelectOperator}
                o2 = {Assets.OperatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            />
            : void 0}

            {/*isUserGameProvider() || isUserOperator() || isUserSiteOwner()*/
            isUserContractualPartner() ? 
            <FormSelect
                value = {!Main.Instance.FrontendStoreState.site_id ?
                '0' : 
                Main.Instance.FrontendStoreState.site_id}
                level = {true}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o0 = "1"
                o1 = {Assets.SelectSite}
                o2 = {Assets.SiteQ}

                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0}

            {IsBuildTrekronor() ?
            <ButtonIcon
                click = {() => ModeSportsBetting()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeSportsBetting ?
                    'btn btn-success btn-sm btn-plus fr mr60 mt20 button-icon-sportsbetting' :
                    'btn btn-default btn-sm btn-plus fr mr60 mt20 button-icon-sportsbetting'
                }
                icon = {Assets_.faFootballClass}
                text = {Assets.SportsBetting}
                description = {Assets.SportsBetting}
            /> : void 0}

            {IsBuildScandibet() ?
            <ButtonIcon
                click = {() => ModeNotify()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeNotify ?
                    'btn btn-success btn-sm btn-plus fr mr60 mt20' :
                    'btn btn-default btn-sm btn-plus fr mr60 mt20'
                }
                Icon = {faEnvelope as IconDefinition}
                text = {Assets.Notify}
                description = {Assets.Notify}
            /> : void 0}

            {IsBuildScandibet() ?
            <ButtonIcon
                click = {() => ModeMinLevel()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeMinLevel ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = {Assets_.faCogClass}
                text = {Assets.MinimumLevelShort}
                description = {Assets.MinimumLevel}
            /> : void 0}

            {/*IsBuildScandibet() ?
            <ButtonIcon
                click = {() => ModeWidget()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeWidget ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                Icon = {faGem as IconDefinition}
                text = {Assets.Widget}
                description = {Assets.Widget}
            /> : void 0*/}

            {IsBuildScandibet() ?
            <ButtonIcon
                click = {() => ModeTranslation()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeTranslation ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = {Assets_.faLanguageClass}
                text = {Assets.Translations}
                description = {Assets.Translations}
            /> : void 0}

            <ButtonIcon
                click = {() => ModeGeneral()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeGeneral ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                Icon = {faImages as IconDefinition}
                // icon = {Assets_.faImageClass}
                text = {Assets.Images}
                description = {Assets.Images}
            />

            {IsBuildScandibet() ?
            <ButtonIcon
                click = {() => ModeRewardShop()}
                ClassName = {
                    Main.Instance.FrontendStoreState.modeRewardShop ?
                    'btn btn-success btn-sm btn-plus fr mr mt20' :
                    'btn btn-default btn-sm btn-plus fr mr mt20'
                }
                icon = {Assets_.faRewardShopClass}
                text = {Assets.RewardShop}
                description = {Assets.RewardShop}
            /> : void 0}
            <Flag/>
            
        </div>
        {(Main.Instance.FrontendStoreState.modeRewardShop || Main.Instance.FrontendStoreState.modeGeneral || 
        Main.Instance.FrontendStoreState.modeMinLevel || Main.Instance.FrontendStoreState.modeNotify || 
        Main.Instance.FrontendStoreState.modeTranslation || Main.Instance.FrontendStoreState.modeSportsBetting) && isUserAdmin() ?
        <div 
            className = "clearfix test-site-data2 light_grey" 
            style = {{marginTop:'0px !important', paddingTop:'10px'}}
        >
                <button 
                    className = "fr btn btn-warning btn-sm"
                    style = {{marginBottom: '5px', width: '110px', marginRight: '10px'}}
                    onClick = {() => SaveData()}
                >
                    <FontAwesomeIcon icon = {faSave}/>
                    &nbsp;{Assets.updateData}
                </button>
            
        </div> : void 0}
        {Main.Instance.FrontendStoreState.site_id && parseInt(Main.Instance.FrontendStoreState.site_id) !== 0 ?
        <div className = "test-site-data">
            {Main.Instance.FrontendStoreState.modeGeneral ?
            <div>
            {IsBuildScandibet() ? void 0 :
            <div
                className = "clearfix light_grey"
                style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
            >
                <button
                    onClick = {() => ModeGeneral()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>

                <span
                    style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                    className = "label label-default"
                >
                    <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faImages}/>
                    {Assets.Images}
                </span>

                <h3 className = "fl" style = {{width: '58%'}}>
                    <span className="fl" style={{width:'28%', marginTop: '20px'}}>{Assets.Logotype}</span>
                    {Main.Instance.FrontendStoreState.logo && Main.Instance.FrontendStoreState.logo.length > 0 ?
                    <div 
                        style = {{
                            background: `url(${Assets_.checkered})`,
                            backgroundPosition: 'center center',
                            float: 'left',
                            marginTop: '-15px',
                            border: '1px solid #999',
                            padding:  '5px',
                        }}
                    >
                        <img 
                            src = {Main.Instance.FrontendStoreState.logo}
                            className = "img img-responsive"
                            style = {{height: '50px'}}
                        />
                    </div>
                    : void 0}
                    {Main.Instance.FrontendStoreState.logo2 && Main.Instance.FrontendStoreState.logo2.length > 0 ?
                    <div
                        style = {{
                            background: `url(${Assets_.checkered})`,
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            float: 'left',
                            marginTop: '-15px',
                            border: '1px solid #999',
                            marginLeft: '1%',
                            padding:  '5px',
                            height: '60px',
                        }}
                    >
                        <img 
                            src = {Main.Instance.FrontendStoreState.logo2}
                            className = "img img-responsive"
                            style = {{height: '50px'}}
                        />
                    </div>
                    : void 0}
                </h3>

                <span style = {{position: 'absolute', left: '58%', top: '12px'}}>
                    {Assets.Logotype + ' 1'}
                </span>
                <span style = {{position: 'absolute', left: '80%', top: '12px'}}>
                    {Assets.Logotype + ' 2'}
                </span>
                <input
                    readOnly = {!isUserAdmin()}
                    accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                    id = "logoinput" 
                    className = "uploadLogoWrapper"
                    style = {{width: '20%'}}
                    type = "file" 
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {logo: reader.result as string})}
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => event.target = null}
                />

                <input
                    readOnly = {!isUserAdmin()}
                    style = {{marginRight: '0px', width: '20%'}}
                    accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                    id = "logo2input" 
                    className = "uploadLogoWrapper"
                    type = "file" 
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {logo2: reader.result as string})
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => event.target = null}
                />
            </div>}

            {isPureScandibet() ? 
                <>
                <span
                    style = {{position: 'absolute', top: '230px', left:'40px', fontSize:'16px', paddingTop:'7px', height:'30px', zIndex: 9999}}
                    className = "label label-primary"
                >
                    <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faImages}/>
                    {Assets.Special}
                </span>
                <span style = {{position: 'absolute', top: '265px', left:'40px', fontSize:'11px', paddingTop:'7px', height:'30px', zIndex: 9999}}>
                    {Main.Instance.FrontendStoreState.blackFriday !== null && Main.Instance.FrontendStoreState.blackFriday !== undefined && 
                     Main.Instance.FrontendStoreState.blackFriday.length < 150 ? 'empty' :
                    (Main.Instance.FrontendStoreState.blackFriday.length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>
                <button
                    style = {{position: 'absolute', top: '262px', left:'93px', marginRight: '0px', zIndex: 9999}}
                    onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {
                        activeBlackFriday: !Main.Instance.FrontendStoreState.activeBlackFriday,
                        activeChristmas: false},
                    )}
                    className = {Main.Instance.FrontendStoreState.activeBlackFriday ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                >
                    {Assets.Active.toLowerCase()}
                </button>
                <div
                    style = {{
                        cursor: 'default',
                        background: `url(${Assets_.checkered})`,
                        backgroundPosition: 'center center',
                        float: 'left',
                        marginTop: '0px',
                        border: '1px solid #999',
                        padding:  '5px',
                        height: '60px',
                        position: 'absolute',
                        top: '286px',
                        left:'40px',
                        width: '100px',
                        textAlign: 'center',
                        zIndex: 9999,
                    }}
                >
                    <img
                        src = {Main.Instance.FrontendStoreState.blackFriday}
                        className = {isPureScandibet() && Main.Instance.FrontendStoreState.activeBlackFriday && Main.Instance.FrontendStoreState.blackFriday && 
                            Main.Instance.FrontendStoreState.blackFriday.length > Assets_.validImageFileLength ? 'img img-responsive' : 'img img-responsiveGrayscale'}
                        style = {{height: '50px'}}
                    />

                    <input
                        readOnly = {!isUserAdmin()}
                        style = {{marginRight: '0px', width: '98px', position: 'absolute', top: '50px', left: '0px', marginTop: '0px', marginBottom: '0px', padding: '1px', height: '28px'}}
                        accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                        id = "special1input" 
                        className = "uploadLogoWrapper"
                        type = "file" 
                        onChange = {(event) => { 
                            const cloneFile: File = event.target.files[0]
                            const reader = new FileReader()
                            reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {blackFriday: reader.result as string})}
                            reader.readAsDataURL(cloneFile);
                        }}
                        onClick = {(event) => event.target = null}
                    />
                </div>

                <span style = {{position: 'absolute', top: '365px', left:'40px', fontSize:'11px', paddingTop:'7px', height:'30px', zIndex: 9999}}>
                    {Main.Instance.FrontendStoreState.christmas !== null && Main.Instance.FrontendStoreState.christmas !== undefined && 
                     Main.Instance.FrontendStoreState.christmas.length < 150 ? 'empty' :
                    (Main.Instance.FrontendStoreState.christmas.length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                </span>
                <button
                    style = {{position: 'absolute', top: '362px', left:'93px', marginRight: '0px', zIndex: 9999}}
                    onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {
                        activeChristmas: !Main.Instance.FrontendStoreState.activeChristmas,
                        activeBlackFriday: false},
                    )}
                    className = {Main.Instance.FrontendStoreState.activeChristmas ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                >
                    {Assets.Active.toLowerCase()}
                </button>
                <div
                    style = {{
                        cursor: 'default',
                        background: `url(${Assets_.checkered})`,
                        backgroundPosition: 'center center',
                        float: 'left',
                        marginTop: '0px',
                        border: '1px solid #999',
                        padding:  '5px',
                        height: '60px',
                        position: 'absolute',
                        top: '386px',
                        left:'40px',
                        width: '100px',
                        textAlign: 'center',
                        zIndex: 9999,
                    }}
                >
                    <img
                        src = {Main.Instance.FrontendStoreState.christmas}
                        className = {isPureScandibet() && Main.Instance.FrontendStoreState.activeChristmas && Main.Instance.FrontendStoreState.christmas && 
                            Main.Instance.FrontendStoreState.christmas.length > Assets_.validImageFileLength ? 'img img-responsive' : 'img img-responsiveGrayscale'}
                        style = {{height: '50px'}}
                    />
                    <input
                        readOnly = {!isUserAdmin()}
                        style = {{marginRight: '0px', width: '98px', position: 'absolute', top: '50px', left: '0px', marginTop: '0px', marginBottom: '0px', padding: '1px', height: '28px'}}
                        accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                        id = "special2input" 
                        className = "uploadLogoWrapper"
                        type = "file" 
                        onChange = {(event) => { 
                            const cloneFile: File = event.target.files[0]
                            const reader = new FileReader()
                            reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {christmas: reader.result as string})}
                            reader.readAsDataURL(cloneFile);
                        }}
                        onClick = {(event) => event.target = null}
                    />

                </div>                
                </>
            : void 0}
            {getImages()}

            {!IsBuildScandibet() ?
            <div 
                className = "clearfix light_grey"
                style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
            >
                <h3 className = "fl" style = {{width: '50%'}}>
                    <span className = "fl" style = {{width:'20%'}}>{Assets.Resource}</span>
                    {Main.Instance.FrontendStoreState.rs_background && Main.Instance.FrontendStoreState.rs_background.length > 0 ?
                    <div 
                        style = {{
                            background: `url(${Assets_.checkered})`,
                            backgroundPosition: 'center center',
                            float: 'left',
                            marginTop: '-15px',
                            border: '1px solid #999',
                            padding:  '5px',
                            height: '60px',
                            position: 'absolute',
                            left: '12%',
                            width: '17%',
                            overflow: 'hidden',
                        }}
                    >
                        <img 
                            src = {Main.Instance.FrontendStoreState.rs_background}
                            className = "img"
                            style = {{height: '50px'}}
                        />
                    </div>
                    : void 0}
                    {Main.Instance.FrontendStoreState.rs_popup_bg && Main.Instance.FrontendStoreState.rs_popup_bg.length > 0 ?
                    <div
                        style = {{
                            background: `url(${Assets_.checkered})`,
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            float: 'left',
                            marginTop: '-15px',
                            border: '1px solid #999',
                            marginLeft: '1%',
                            padding: '5px',
                            height: '60px',
                            position: 'absolute',
                            left: '31%',
                            width: '17%',
                            overflow: 'hidden',
                        }}
                    >
                        <img 
                            src = {Main.Instance.FrontendStoreState.rs_popup_bg}
                            className = "img"
                            style = {{height: '50px'}}
                        />
                    </div>
                    : void 0}
                </h3>

                <span style = {{position: 'absolute', left: '50%', top: '12px'}}>
                    {Assets.Resource + ' 1'}
                </span>
                <span style = {{position: 'absolute', left: '76%', top: '12px'}}>
                    {Assets.Resource + ' 2'}
                </span>
                <input
                    readOnly = {!isUserAdmin()}
                    accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                    // id = "resourceinput"
                    className = "uploadLogoWrapper" 
                    type = "file" 
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {rs_background: reader.result as string})}
                        reader.readAsDataURL(cloneFile)
                    }}
                    onClick = {(event) => event.target = null}
                />

                <input
                    readOnly = {!isUserAdmin()}
                    style = {{marginRight: '0px'}}
                    accept = "image/png, image/jpg, image/jpe, image/svg+xml, image/gif"
                    // id = "resource2input" 
                    className = "uploadLogoWrapper"
                    type = "file" 
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {rs_popup_bg: reader.result as string})}
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => event.target = null}
                />
            </div> : void 0}

        {!IsBuildScandibet() ?
        <div style = {{borderBottom: '1px solid #ddd'}} className = "clearfix test-site-data light_grey">
            <h3 style = {{width:'12%', float:'left', marginLeft:'10px'}}>
                <span className = "fl">{Assets.Main}</span>
            </h3>

            <FormInput
                narrow = {true}
                value = {!Main.Instance.FrontendStoreState.title ? '' : Main.Instance.FrontendStoreState.title}
                ClassName = "form-control register-input"
                type = "text"
                divClass = "input-test-site mt20"
                onChange = {(text) => Logic.Type.New(Main.Instance.FrontendStoreState, {title:text})}
                placeholder = {Assets.SiteName}
                fix = {true}
                icon = {Assets_.faSite_Class}
            />

        </div> : void 0}

        {!IsBuildScandibet() ?
        <div className = "clearfix test-site-data light_grey">
            <h3 style = {{width:'12%', float:'left', marginLeft:'10px'}}>
                <span className = "fl">{Assets.Games}</span>
            </h3>

            <RadioButton3 
                value = {!Main.Instance.FrontendStoreState.display_name ? false : 
                        Main.Instance.FrontendStoreState.display_name}
                ClassName = "input-group-radiobutton3a margin-bottom-sm mt22"
                text = {Assets.DisplayName}
                title = {Assets.DisplayNameDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {
                        display_name: !Main.Instance.FrontendStoreState.display_name})}
            />

            <RadioButton3 
                value = {!Main.Instance.FrontendStoreState.borders ? false : 
                        Main.Instance.FrontendStoreState.borders}
                ClassName = "input-group-radiobutton3a margin-bottom-sm mt22"
                text = {Assets.Borders}
                title = {Assets.BordersDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {borders: !Main.Instance.FrontendStoreState.borders})}
            />

            <span   
                className = "label label-default"
                style = {{float:'left', marginTop:'24px', marginRight:'20px', fontSize:'14px'}}
            >
                {Assets.hovers}
            </span>

            <RadioButton3 
                value = {!Main.Instance.FrontendStoreState.show_game_info ? false : 
                        Main.Instance.FrontendStoreState.show_game_info}
                ClassName = "input-group-radiobutton3a margin-bottom-sm mt22"
                text = {Assets.ShowGameInfo}
                title = {Assets.ShowGameInfoDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {show_game_info: !Main.Instance.FrontendStoreState.show_game_info})}
            />

            <RadioButton3 
                value = {!Main.Instance.FrontendStoreState.show_version_info ? false : 
                        Main.Instance.FrontendStoreState.show_version_info}
                ClassName = "input-group-radiobutton3a margin-bottom-sm mt22"
                text = {Assets.ShowVersionInfo}
                title = {Assets.ShowVersionInfoDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {show_version_info: !Main.Instance.FrontendStoreState.show_version_info})}
            />

            <RadioButton3 
                value = {!Main.Instance.FrontendStoreState.show_debug_info ? false : 
                        Main.Instance.FrontendStoreState.show_debug_info}
                ClassName = "input-group-radiobutton3a margin-bottom-sm mt22 mr0i"
                text = {Assets.ShowDebugInfo}
                title = {Assets.ShowDebugInfoDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => Logic.Type.New (Main.Instance.FrontendStoreState, {show_debug_info: !Main.Instance.FrontendStoreState.show_debug_info})}
            />
        </div> :  void 0}

        {!IsBuildScandibet() ?
        <div 
            className = "clearfix test-site-data2 light_grey"
            style = {{position: 'relative'}}
        >
            <h3
                className = "mt6"
                style = {{width:'12%', float:'left', marginLeft:'10px', marginBottom: '0px'}}
            >
                <span style = {{display: 'block'}}>
                    {Assets.Menu}
                </span>
            </h3>

            <button 
                className = "fr btn btn-default btn-sm"
                style = {{
                    position:'absolute', 
                    top: '2px', 
                    width:'31.44px',
                    paddingLeft:'7px',
                    paddingTop:'6px',
                    right: '0px',
                }}
                onClick = {() => AddMenu()}
            >
                <i className = {Assets_.faPlusButtonClass}/>
            </button>

            {menus}
        </div> : void 0}
        </div> : void 0}

        {/*Main.Instance.FrontendStoreState.modeWidget ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeWidget()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div>
                bla
            </div>
        </div> : void 0*/}
        {Main.Instance.FrontendStoreState.modeTranslation ?
        <div
            className = "clearfix light_grey"
            style = {{borderBottom: '1px solid #ddd', padding: '10px', position: 'relative'}}
        >
            <button
                onClick = {() => ModeTranslation()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>

            <div>

            <div>
                <div className = "control-panel" style = {{marginBottom: '20px'}}>
                    <div style = {{width: '16.24%', display: 'block', float: 'left'}}>
                        <span
                            style = {{fontSize:'16px', paddingTop:'7px'}}
                            className = "label label-success"
                        >
                            <i className = {Assets_.faLanguageClass} style = {{marginRight: '7px'}}/>        
                            {Assets.Translations}
                        </span>
                    </div>
                    <FormInputNumber
                        description = {Assets.recordsPerPageDesc}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0 mt-5"
                        ClassName = "h26 w60"
                        value = {!Main.Instance.FrontendStoreState.pSize ? '' : Main.Instance.FrontendStoreState.pSize.toString()}  
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHamburgerClass}
                        onChange = {(text) => RecordsPerPageChanged(text)}
                    />

                    <button
                        className = "ml btn btn-primary btn-xs mt-3" 
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage()}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage()}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage()}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt-3"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage()}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>

                    <button
                        onClick = {() => setCategories(true)} 
                        className = {Main.Instance.FrontendStoreState.categories ? 'btn btn-info btn-xs ml mt-3' : 'btn btn-default btn-xs ml mt-3'}
                    >
                        {Assets.Categories}
                    </button>

                    <button
                        onClick = {() => setCategories(false)}
                        className = {Main.Instance.FrontendStoreState.categories ? 'btn btn-default btn-xs mr0 mt-3' : 'btn btn-info btn-xs mr0 mt-3'}
                    >
                        {Assets.OtherTranslations}
                    </button>

                    {Main.Instance.FrontendStoreState.categories ? void 0 : 
                    <button
                        onClick = {() => addNewTranslation()}
                        className = "btn btn-success btn-xs ml mt-3 mr0"
                    >
                        {Assets.AddNewTranslation}
                    </button>}

                    <span className = "label label-success table-label mt-3">
                        {Assets.records}
                        {Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.count : 0}
                    </span>
                    <span className = "label label-success table-label mt-3">
                        {Assets.showing}
                        {Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.pNum * Main.Instance.FrontendStoreState.pSize + 1 : 0}{' - '}
                        {Main.Instance.FrontendStoreState.count ? ((Main.Instance.FrontendStoreState.pNum + 1) * 
                        Main.Instance.FrontendStoreState.pSize > Main.Instance.FrontendStoreState.count ? Main.Instance.FrontendStoreState.count :
                        (Main.Instance.FrontendStoreState.pNum + 1) * Main.Instance.FrontendStoreState.pSize) : 0}
                    </span>
                    <span className = "label label-success table-label mt-3">
                        {Assets.page}
                        {Main.Instance.FrontendStoreState.count ? 
                        Main.Instance.FrontendStoreState.pNum + 1 : 0}{' / '}
                        {Main.Instance.FrontendStoreState.count ? 
                        Math.trunc((Main.Instance.FrontendStoreState.count - 1) / Main.Instance.FrontendStoreState.pSize + 1) : 0}                        
                    </span>
                </div>
            </div>
            <table className = "table table-hover" style = {{marginTop: '10px', width: '100%'}}>
                <thead>
                    <tr>
                        {getTranslationHeader(false)}
                    </tr>
                </thead>
                <tbody>
                    {getTranslationBody()}
                </tbody>            
            </table>

            </div>       
        </div> : void 0}


        {Main.Instance.FrontendStoreState.modeRewardShop ?
        <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', marginBottom: '10px', position: 'relative', borderBottom: '1px solid #ddd'}}>
        <div className = "clearfix light_grey">
            <button
                onClick = {() => ModeRewardShop()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>
            <span
                style = {{position: 'absolute', top: '10px', left: '10px', fontSize:'16px', paddingTop: '7px', height:'30px'}}
                className = "label label-success"
            >
                <i className = {Assets_.faRewardShopClass} style = {{marginRight: '7px'}}/>        
                {Assets.RewardShop}
            </span>
            <h3 className = "level fl">
                <small style = {{marginTop: '20px', display: 'block'}}>{Assets.SetDefaultImage}</small>
            </h3> 

            {!Main.Instance.FrontendStoreState.shopItemDefaultFilename || 
            Main.Instance.FrontendStoreState.shopItemDefaultFilename.length === 0 ?
            <span 
                style = {{
                    float:'left',
                    display:'block',
                    width:'10%',
                    marginTop:'30px',
                    marginRight:'2%',
                    height: '30px',
                    fontSize:'20px',
                }}
                className = "label label-warning"
            >
            {Assets.NoImage}
            </span> 
            :
            <div 
                style = {{
                    background: `url(${Assets_.checkered})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    height:'62px',
                    maxWidth:'18%',
                    minWidth:'100px',
                    marginTop:'10px',
                    marginRight:'2%',
                    float:'left',
                    display: 'block',
                    border:'1px solid #999',
                }}
            >
            <button 
                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '0px', top: '0px', padding: '0px', marginRight: '0px'}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.FrontendStoreState, {shopItemDefaultFilename: ''})
                }}
            >
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <img 
                src = {Main.Instance.FrontendStoreState.shopItemDefaultFilename} 
                style = {{
                    height: '100%',
                    width: 'auto',
                    position: 'absolute',
                    zIndex: 9998,
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    margin: 'auto',
                }}
            />
            </div>
            }
            <input
                readOnly = {!isUserAdmin()}
                accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                id = "shopitem-defaultinput" 
                className = "uploadShopItemWrapper"
                style = {{marginTop: '32px', width: '200px'}}
                type = "file"
                // value = {Main.Instance.FrontendStoreState.shopItemDefaultFilename}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {Logic.Type.New (Main.Instance.FrontendStoreState, {shopItemDefaultFilename: reader.result as string})}
                    reader.readAsDataURL(cloneFile);
                }}
                onClick = {(event) => event.target = null}
            />

            <FormInputNumber
                description = {Assets.titleSizeDesc}
                value = {!Main.Instance.FrontendStoreState.titleSize ? '' : 
                Main.Instance.FrontendStoreState.titleSize.toString()}  
                outerDiv = "rows-per-table mt32 input-group fl"
                ClassName = "h30 w60"
                type = "number" 
                min = "1"
                onChange = {(text) => TitleSizeChanged(text)}
                icon = {Assets_.faFontClass}
            />            
        </div>

        <div className = "clearfix">
            <h3 className = "level fl">
                <small>{Assets.SetGraphics}</small>
                <span style = {{fontSize: '14px', fontWeight: 400}}>
                <RadioButton3
                    value = {!Main.Instance.FrontendStoreState.coverWhole ? false : 
                            Main.Instance.FrontendStoreState.coverWhole}
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr"
                    text = {Assets.CoverWhole}
                    title = {Assets.CoverWholeDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {() => CoverWholeChanged()}
                />
                </span>
            </h3>

            <FormInputNumber  
                value = {!Main.Instance.FrontendStoreState.shopItemWidth ? '' :
                Main.Instance.FrontendStoreState.shopItemWidth.toString()}
                description = {Assets.ShopItemWidthDesc}
                outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                type = "number"
                min = "1"
                onChange = {(text) => WidthChanged(text)/*{
                Logic.Type.New(Main.Instance.FrontendStoreState, {shopItemWidth:text})}*/} 
                // icon = {Assets_.faWidthClass}
                Icon = {faArrowsAltH as IconDefinition}
            />

            <FormInputNumber  
                value = {!Main.Instance.FrontendStoreState.shopItemHeight ? '' :
                Main.Instance.FrontendStoreState.shopItemHeight.toString()} 
                description = {Assets.ShopItemHeightDesc}
                outerDiv = "rows-per-table mt20 input-group fl mr"
                ClassName = "h30 w60"
                type = "number"
                min = "1" 
                onChange = {(text) => HeightChanged(text)/*{
                Logic.Type.New(Main.Instance.FrontendStoreState, {shopItemHeight:text})}*/} 
                // icon = {Assets_.faHeightClass}
                Icon = {faArrowsAltV as IconDefinition}
            />

            <FormInputNumber
                description = {Assets.borderWidthDesc}
                value = {Main.Instance.FrontendStoreState.borderWidth === undefined ? '' : 
                Main.Instance.FrontendStoreState.borderWidth.toString()}  
                outerDiv = "rows-per-table mt20 input-group fl mr"
                ClassName = "h30 w60"
                type = "number" 
                min = "0"
                onChange = {(text) => BorderWidthChanged(text)}
                Icon = {faSquare as IconDefinition}
            />

            <FormInputNumber
                description = {Assets.borderRadiusDesc}
                value = {Main.Instance.FrontendStoreState.borderRadius === undefined ? '' :
                Main.Instance.FrontendStoreState.borderRadius.toString()}  
                outerDiv = "rows-per-table mt20 input-group fl mr"
                ClassName = "h30 w60"
                type = "number" 
                min = "0"
                onChange = {(text) => BorderRadiusChanged(text)}
                // icon = {Assets_.faBorderRadiusClass}
                Icon = {faCircle as IconDefinition}
            />

            {!Main.Instance.FrontendStoreState.coverWhole ?
            <FormInputNumber  
                value = {!Main.Instance.FrontendStoreState.shopItemImageHeight ? '' :
                Main.Instance.FrontendStoreState.shopItemImageHeight.toString()} 
                description = {Assets.ShopItemImageHeightDesc}
                outerDiv = "rows-per-table mt20 input-group fl"
                ClassName = "h30 w60"
                type = "number"
                min = "1" 
                onChange = {(text) => ImageHeightChanged(text)} 
                // icon = {Assets_.faImageFwClass}
                Icon = {faImage as IconDefinition}
            />
            : void 0}

            <div className = "picker" title = {Assets.PickAColorThatShallBeUsedForTitle}>
                <div style = {{position: 'absolute', top: '0px', right: '10px'}}>
                    {Assets.PickAColorThatShallBeUsedForTitle}
                </div>
                <Picker.CompactPicker
                    width = "240px"
                    colors = {[
                        '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', 
                        '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', 
                        '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E',
                        '#999999', '#808080', '#666666', '#4D4D4D', '#333333', '#000000', '#005487', '#33769f', '#f38b00', '#f5a233',
                    ]}
                    color = {Main.Instance.FrontendStoreState.titleColor} 
                    onChange = {(color, event) => TitleColorChanged(color.hex)}
                />
            </div>
        </div>

            {Main.Instance.FrontendStoreState.modeRewardShop ?
            <> 
                <span style = {{marginRight:'0px', fontSize: '16px', fontWeight: 600}}>{Assets.SelectCategoryForRewardsPositioning}</span>
                <button
                    title = {Assets.CloseRewardsPositioning}
                    key = "0"
                    style = {{marginRight:'0px', padding: '5px 8px', borderRadius: '0px', marginLeft: '30px'}}
                    onClick = {() => SelectCategory (0)}
                    className = {0 === Main.Instance.FrontendStoreState.rewardShopOpen && !Main.Instance.FrontendStoreState.BlackFriday ? 'btn btn-sm btn-success' : 'btn btn-sm btn-default'}
                >
                    {Assets.LeftSide} ({Assets.Default})
                </button>
                <button
                    title = {Assets.CloseRewardsPositioning}
                    key = "-1"
                    style = {{marginRight:'0px', padding: '5px 8px', borderRadius: '0px', marginLeft: '-1px'}}
                    onClick = {() => SelectCategory (-1)}
                    className = {0 === Main.Instance.FrontendStoreState.rewardShopOpen && Main.Instance.FrontendStoreState.BlackFriday ? 'btn btn-sm btn-black' : 'btn btn-sm btn-default'}
                >
                    {Assets.LeftSide} ({Assets.BlackFriday})
                </button>
                {GetCategories()}
            </> : void 0}
        </div> : void 0}
        {/*Main.Instance.FrontendStoreState.images[Assets.ImageLeft]*/}
        {/*Main.Instance.FrontendStoreState.modeRewardShop && Main.Instance.FrontendStoreState.rewardShopOpen === 0 && Main.Instance.FrontendStoreState.images ?
        <div className = "col-md-12 col-sm-12 col-xs-12 admin-users-panel" style = {{padding: '0px', marginBottom: '10px', position: 'relative'}}>
            <img
                className = "img img-responsive"
                style = {{width: '100%', height: 'auto'}}
                src = {Main.Instance.FrontendStoreState.BlackFriday ? Assets_.scandibetBFShop : Assets_.scandibetShop}
            />
            {getCategoriesImages()}
        </div> : void 0*/}
        {Main.Instance.FrontendStoreState.modeRewardShop && Main.Instance.FrontendStoreState.rewardShopOpen > 0 && countItemsInCategories(Main.Instance.FrontendStoreState.rewardShopOpen) === 0 ?
        <img
            className = "img img-responsive comingSoon"
            src = {Main.Instance.FrontendStoreState.images[isPureScandibet() ? Assets_.ComingSoonScandibet : Assets_.ComingSoon]}
        />    
        : Main.Instance.FrontendStoreState.modeRewardShop && Main.Instance.FrontendStoreState.rewardShopOpen > 0 /*&& countItemsInCategories(Main.Instance.FrontendStoreState.rewardShopOpen)*/ ?
        <div className = "col-md-12 col-sm-12 col-xs-12 admin-users-panel" style = {{padding: '10px', marginBottom: '10px', height: '720px', position: 'relative'}}>
            <button
                title = "Close Reward positioning"
                style = {{marginRight:'0px', padding: '0px', background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '0px'}}
                onClick = {() => SelectCategory(0)}
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button> 

            <div>
                <div>
                    <div
                        style = {{fontSize:'16px', paddingTop: '6px', height:'30px', borderRadius: '0px', display: 'block', width: '496px', textAlign: 'center'}}
                        className = "fl dbl label label-primary clearfix"
                    >
                        {/*<i style = {{marginRight: '1px', float: 'left', display: 'block', marginTop: '2px'}} className = {Assets_.faManyItemsClass}/>*/}
                        <i style = {{marginRight: '10px', float: 'left', display: 'block', marginTop: '2px'}} className = {Assets_.faManyItemsClass}/>
                        <span style = {{float: 'left', display: 'block'}}>
                            {Assets.RewardsPositioningForCategory}
                        </span>
                        <span style = {{float: 'right', display: 'block'}}>
                            {Main.Instance.FrontendStoreState.Category[Main.Instance.FrontendStoreState.rewardShopOpen]['name'].toUpperCase()}
                        </span>
                    </div>
                    <div style = {{width: '496px', height: '22px', float: 'none', clear: 'both', paddingTop: '5px', position: 'relative'}}>
                        {getPages()}
                    </div>
                </div>
                <div>
                    {getRewardShopItems()}
                </div>
            </div>
            <div style = {{position: 'absolute', top: '10px', left: '515px', height: 'auto', width: '560px'}}>
                <div style = {{position: 'relative', height: 'auto', width: '100%'}}>
                    <img
                        className = "img img-responsive"
                        style = {{position: 'absolute', width: '100%', height: 'auto'}}
                        src = {Main.Instance.FrontendStoreState.images[isPureScandibet() ? Assets_.ImageRightScandibet : Assets_.ImageRight]}
                    />
                    {getRewardShopItemsPositioned(Main.Instance.DatabaseDataState.rewardShopStreamAll)}
                </div>
            </div>
        </div>
        : void 0}

        {Main.Instance.FrontendStoreState.modeMinLevel ?
        <div className = "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', marginBottom: '10px', position: 'relative', borderBottom: '1px solid #ddd'}}>
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
            >
                <button
                    onClick = {() => ModeMinLevel()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div style = {{position: 'absolute', top: '55px', right: '10px', background: '#ddd', padding: '0px 20px 20px 20px'}}>
                    <div style = {{fontWeight: 700, marginBottom: '12px'}}>Level structure</div>
                    <div>{getLevels()}</div>
                </div>
                <span
                    style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                    className = "label label-success"
                >
                    <i className = {Assets_.faCogClass} style = {{marginRight: '7px'}}/>        
                    {Assets.MinimumLevel}
                </span>        
                {IsBuildScandibet() ?
                <div style = {{position: 'absolute', top: '15px', left: '170px'}}>
                    <span className = "label label-default" style = {{fontSize: '14px', fontWeight: 600}}>
                        {Assets.SetMinimumLevel}
                    </span>
                </div>
                : void 0}
                {IsBuildScandibet() ? getMinLevel() : void 0}
            </div>
        </div> : void 0}
        {IsBuildTrekronor() && Main.Instance.FrontendStoreState.modeSportsBetting && Main.Instance.FrontendStoreState.custom !== undefined ?
        <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', paddingBottom: '0px'}}>
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', height: '50px', position: 'relative'}}
            >
                <button
                    onClick = {() => ModeSportsBetting()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <span
                    style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                    className = "label label-success"
                >
                    <i className = {Assets_.faFootballClass} style = {{marginRight: '7px'}}/>
                    {Assets.SportsBetting}
                </span>

                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeSportsBettingSports()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeSports'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-sports' :
                        Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'] &&
                        Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'].length > 0 ?
                        'btn btn-default bluegray btn-sm btn-plus fr mr button-icon-sports' : 
                        'btn btn-default btn-sm btn-plus fr mr button-icon-sports'
                    }
                    title = {Assets.Sports}
                >
                    <FontAwesomeIcon icon = {faFootballBall}/>&nbsp;&nbsp;{Assets.Sports}
                </button>

                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeSportsBettingCategories()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeCategories'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-categories' :
                        getNumberOfAllSportCategories() > 0 ?
                        'btn btn-default bluegray btn-sm btn-plus fr mr button-icon-categories' : 
                        'btn btn-default btn-sm btn-plus fr mr button-icon-categories'
                    }
                    title = {Assets.Categories}
                >
                    <FontAwesomeIcon icon = {faVolleyballBall}/>&nbsp;&nbsp;{Assets.Categories}
                </button>

                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeSportsBettingTournaments()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeTournaments'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-tournaments' :
                        getNumberOfAllSportTournaments() > 0 ?
                        'btn btn-default bluegray btn-sm btn-plus fr mr button-icon-tournaments' : 
                        'btn btn-default btn-sm btn-plus fr mr button-icon-tournaments'
                    }
                    title = {Assets.Tournaments}
                >
                    <FontAwesomeIcon icon = {faDumbbell}/>&nbsp;&nbsp;{Assets.Tournaments}
                </button>

                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeSportsBettingMatches()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeMatches'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-matches' :
                        getNumberOfAllSportMatches() > 0 ?
                        'btn btn-default bluegray btn-sm btn-plus fr mr button-icon-matches' : 
                        'btn btn-default btn-sm btn-plus fr mr button-icon-matches'
                    }
                    title = {Assets.Matches}
                >
                    <FontAwesomeIcon icon = {faBasketballBall}/>&nbsp;&nbsp;{Assets.Matches}
                </button>

                <ButtonIcon
                    click = {() => ModeSportsBettingFavourites()}
                    ClassName = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeFavourites'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-favourites' :
                        getNumberOfAllFavourites() > 0 ?
                        'btn btn-default bluegray btn-sm btn-plus fr mr button-icon-favourites' : 
                        'btn btn-default btn-sm btn-plus fr mr button-icon-favourites'
                    }
                    icon = {Assets_.faCasinoClass}
                    text = {Assets.Favourites}
                    description = {Assets.Favourites}
                />

                <ButtonIcon
                    click = {() => ModeSportsBettingDaily()}
                    ClassName = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeDaily'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-daily' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-daily'
                    }
                    icon = {Assets_.faCalendarGClass}
                    text = {'Daily'}
                    description = {'Daily'}
                />

                <ButtonIcon
                    click = {() => ModeSportsBettingGeneral()}
                    ClassName = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeGeneral'] ?
                        'btn btn-primary btn-sm btn-plus fr mr button-icon-general' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-general'
                    }
                    icon = {Assets_.faCogClass}
                    text = {Assets.General}
                    description = {Assets.General}
                />
            </div>
{/* GENERAL */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeGeneral'] ?
            <div 
                className = "test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'0px', position: 'relative', marginBottom: '10px'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingGeneral()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div className = "clearfix">
                    <div
                        style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700}}
                        className = "label label-primary dibl ml mr fl"
                    >
                        <FontAwesomeIcon icon = {faCog}/>&nbsp;&nbsp;{Assets.General}
                    </div>

                <button
                    style = {{fontSize: '14px'}}                                  
                    onClick = {() => ModeGeneralSports()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeSports2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-sports' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-sports'
                    }
                    title = {Assets.Sports}
                >
                    <FontAwesomeIcon icon = {faFootballBall}/>&nbsp;&nbsp;{Assets.Sports}
                </button>
                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeGeneralCategories()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeCategories2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-categories' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-categories'
                    }
                    title = {Assets.Categories}
                >
                    <FontAwesomeIcon icon = {faVolleyballBall}/>&nbsp;&nbsp;{Assets.Categories}
                </button>
                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeGeneralTournaments()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeTournaments2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-tournaments' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-tournaments'
                    }
                    title = {Assets.Tournaments}
                >
                    <FontAwesomeIcon icon = {faDumbbell}/>&nbsp;&nbsp;{Assets.Tournaments}
                </button>

                <button
                    style = {{fontSize: '14px'}}
                    onClick = {() => ModeGeneralMatches()}
                    className = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeMatches2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-matches' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-matches'
                    }
                    title = {Assets.Matches}
                >
                    <FontAwesomeIcon icon = {faBasketballBall}/>&nbsp;&nbsp;{Assets.Matches}
                </button>

                <ButtonIcon
                    click = {() => ModeGeneralFavourites()}
                    ClassName = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeFavourites2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-favourites' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-favourites'
                    }
                    icon = {Assets_.faCasinoClass}
                    text = {Assets.Favourites}
                    description = {Assets.Favourites}
                />

                <ButtonIcon
                    click = {() => ModeGeneralDaily()}
                    ClassName = {
                        Main.Instance.FrontendStoreState.custom['general']['admin']['modeDaily2'] ?
                        'btn btn-info btn-sm btn-plus fr mr button-icon-daily' :
                        'btn btn-default btn-sm btn-plus fr mr button-icon-daily'
                    }
                    icon = {Assets_.faCalendarGClass}
                    text = {'Daily'}
                    description = {'Daily'}
                />                    
                </div>
{/*                
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllFavourites()}
                </span>
*/}
                {getSportGeneral()}
            </div> : void 0}
{/* FAVOURITES */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeFavourites'] ?
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'0px', position: 'relative'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingFavourites()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div
                    style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700}}
                    className = "label label-primary dibl ml mr"
                >
                    <FontAwesomeIcon icon = {faHeart}/>&nbsp;&nbsp;{Assets.Favourites}
                </div>
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllFavourites()}
                </span>
                {getSportFavourites(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data'])}
            </div> : void 0}
{/* MATCHES */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeMatches'] ?
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'0px', position: 'relative'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingMatches()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div
                    style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700}}
                    className = "label label-primary dibl ml mr"
                >
                    <FontAwesomeIcon icon = {faBasketballBall}/>&nbsp;&nbsp;{Assets.Matches}
                </div>
                {Main.Instance.FrontendStoreState.custom['general']['database']['matches'] ?
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllSportMatches()}
                </span> : void 0}
                {Main.Instance.FrontendStoreState.custom['general']['database']['matches'] ?
                getSportMatches(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches']) : void 0}
            </div> : void 0}
{/* TOURNAMENTS */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeTournaments'] ?
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'0px', position: 'relative'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingTournaments()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div
                    style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700}}
                    className = "label label-primary dibl ml mr"
                >
                    <FontAwesomeIcon icon = {faDumbbell}/>&nbsp;&nbsp;{Assets.Tournaments}
                </div>
                {Main.Instance.FrontendStoreState.custom['general']['database']['tournaments'] ?
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllSportTournaments()}
                </span> : void 0}
                {Main.Instance.FrontendStoreState.custom['general']['database']['tournaments'] ?
                getSportTournaments(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments']) : void 0}
            </div> : void 0}
{/* CATEGORIES */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeCategories'] ?
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'0px', position: 'relative'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingCategories()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div
                    style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700}}
                    className = "label label-primary dibl ml mr"
                >
                    <FontAwesomeIcon icon = {faVolleyballBall}/>&nbsp;&nbsp;{Assets.Categories}
                </div>
                {Main.Instance.FrontendStoreState.custom['general']['database']['categories'] ?
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllSportCategories()}
                </span> : void 0}
                {Main.Instance.FrontendStoreState.custom['general']['database']['categories'] ?
                getSportCategories(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories']) : void 0}
            </div> : void 0}
{/* SPORTS */}
            {IsBuildTrekronor() && Main.Instance.FrontendStoreState.custom['general']['admin']['modeSports'] ?
            <div 
                className = "clearfix test-site-data2 light_grey" 
                style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px',/* borderBottom: '1px solid #ddd',*/ position: 'relative'}}
            >                
                <button
                    onClick = {() => ModeSportsBettingSports()}
                    style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                    className = "btn btn-default btn-sm"
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
                <div
                    style = {{fontSize:'16px', paddingTop:'7px', height:'30px', fontWeight: 700, marginTop: '2px'}}
                    className = "label label-primary dibl ml mr"
                >
                    <FontAwesomeIcon icon = {faFootballBall}/>&nbsp;&nbsp;{Assets.Sports}
                </div>
{/*
                <button
                    style = {{fontSize:'16px', paddingTop:'3px', height:'30px', fontWeight: 900, marginTop: '-3px'}}
                    onClick = {() => DbRoutes.reloadDefaultTrekronorSports()}
                    className = "btn btn-danger btn-sm dibl"
                >
                    <FontAwesomeIcon icon = {faExclamationTriangle}/>&nbsp;&nbsp;Reload default settings
                </button>
*/}
                {Main.Instance.FrontendStoreState.custom['general']['database']['sports'] ?
                <span 
                    style = {{position: 'absolute', paddingTop:'7px', top: '3px', right: '10px', fontSize: '16px', fontWeight: 700, height:'30px'}}
                    className = "label label-primary"
                >
                    {Assets.records}{getNumberOfAllSports()}
                </span> : void 0}
                {Main.Instance.FrontendStoreState.custom['general']['database']['sports'] ?
                getSports(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports']) : void 0}
            </div> : void 0}
        </div> : void 0}

        {Main.Instance.FrontendStoreState.modeNotify ?
        <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative'}}>
        <div 
            className = "clearfix test-site-data2 light_grey" 
            style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px', borderBottom: '1px solid #ddd'}}
        >
            <button
                onClick = {() => ModeNotify()}
                style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-3px', background: 'transparent', border: '0px', padding: '0px'}}
                className = "btn btn-default btn-sm"
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>
            <span
                style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                className = "label label-success"
            >
                <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faEnvelope}/>
                {Assets.Notify}
            </span>         
            {IsBuildScandibet() ?
            <div style = {{position: 'absolute', top: '15px', left: '130px'}}>
                <span className = "label label-default" style = {{fontSize: '14px', fontWeight: 600}}>
                    {Assets.NotifyBelowContactsPurchase}
                </span>
            </div> : void 0}

            {IsBuildScandibet() ?
            <div style = {{position: 'absolute', top: '15px', right: '10px'}}>
                <span className = "label label-default" style = {{fontSize: '14px', fontWeight: 600}}>
                    {Main.Instance.FrontendStoreState.mail.length + ' contacts will be notified.'}
                </span>
            </div> : void 0}

            {IsBuildScandibet() ?
            <div
                style = {{borderBottom: '1px solid #ddd', paddingTop: '10px', paddingBottom: '10px', marginTop: '20px'}}
                className = "clearfix test-site-data light_grey"
            >
                {getCategories()}
            </div> : void 0}
            {IsBuildScandibet() ? getMails() : void 0}
        </div> </div> : void 0}

        {/*parseInt(Main.Instance.FrontendStoreState.site_id) !== 0 ?
        <div className = "store clearfix">
            <div 
                className = "clearfix"
                style = {{marginBottom:'30px'}}
            >
                <h3 className = "level fl ml">
                    {Assets.SelectGames}
                </h3> 

                <FormSelect
                    value = {!Main.Instance.FrontendStoreState.group_filter_id ?
                            '0' : Main.Instance.FrontendStoreState.group_filter_id}
                    level165 = {true}
                    data = {Main.Instance.FrontendStoreState.group_filter}
                    ClassName = "form-control"
                    o0 = "1"
                    o1 = {Assets.SelectGroupFilter}
                    o2 = {Assets.GroupFilterQ}

                    onChange = {text => {ApplyGroupFilterSelect(text)}}
                    icon = {Assets_.faCompanyClass}
                />
                
                <button 
                    className = "btn btn-primary btn-sm"
                    style = {{marginTop:'20px',width:'100px', float:'left'}}
                    onClick = {() => UpdateGamesListing(1)}
                >
                    {Assets.UpdateList}
                </button>

                <span 
                    className = "label label-primary fr"
                    style = {{
                        display:'block', 
                        float:'right',
                        marginRight:'10px',
                        fontSize:'20px', 
                        fontWeight:700,
                        marginTop:'20px'
                    }}
                >
                    {Main.Instance.FrontendStoreState.gamesSelected.toString() + 
                    '/' + Main.Instance.FrontendStoreState.gamesAll.toString()}
                </span>
            </div>
            {Main.Instance.FrontendStoreState.gamesArray.length > 0 ?
            Main.Instance.FrontendStoreState.gamesArray : void 0}
        </div> : void 0*/}
        </div> : void 0}
    </div>)
}

function SelectMinLevel(which: number, text: string) {

    let value: number = 1
    for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
        if (+item['id'] === +text) {
            value = item['hierarchy']
            break
        }
    }

    // console.log (which, value)
    const minlevel: number[] = Object.assign ([], Main.Instance.FrontendStoreState.minlevel)
    minlevel[which] = value
    // console.log (minlevel)
    Logic.Type.New (Main.Instance.FrontendStoreState, {minlevel})
}

function getMinLevel(): JSX.Element[] {
    // console.log (Main.Instance.DatabaseDataState.levelsAllStream)
    const categories: JSX.Element[] = []

    categories.push (
        <div key = "-1" className = "clearfix" style = {{lineHeight: '30px'}}>
            <div style = {{float: 'left', fontWeight: 700, width: '160px', marginTop: '30px', textAlign: 'left', marginRight: '0px'}}>
                {Assets.Category}
            </div>
            <div style = {{float: 'left', fontWeight: 700, marginTop: '30px', textAlign: 'left'}}>{Assets.MinimumLevel + ' to unlock category'}</div>
        </div>,
    )

    if (Main.Instance.FrontendStoreState.minlevel && Main.Instance.DatabaseDataState.levelsAllStream) {
        // console.log (Main.Instance.FrontendStoreState.minlevel)
        for (let i: number = 0; i < Main.Instance.FrontendStoreState.minlevel.length; i++) {
            // console.log (Main.Instance.FrontendStoreState.minlevel[i])
            categories.push (
                <div key = {i.toString()} className = "clearfix">
                    <div 
                        style = {{
                            border: '1px solid #bbb',
                            float: 'left',
                            fontWeight: 500,
                            width: '150px',
                            marginTop: '6px',
                            lineHeight: '30px',
                            paddingLeft: '10px',
                            marginRight: '10px',
                            background: '#fff',
                        }}
                    >
                        {Main.Instance.FrontendStoreState.Category[i + 2]['name']}
                    </div>
                    {!isUserAdmin() ?
                    <div 
                        style = {{
                            border: '1px solid #bbb',
                            float: 'left',
                            fontWeight: 500,
                            width: '140px',
                            marginTop: '6px',
                            lineHeight: '30px',
                            paddingLeft: '10px',
                            marginRight: '10px',
                            background: Main.Instance.FrontendStoreState.minlevel[i] === undefined ? isPureScandibet() ? Assets_.COLORS_5[0] : Assets_.COLORS_2[0] :
                                isPureScandibet() ? Assets_.COLORS_5[+Main.Instance.FrontendStoreState.minlevel[i] - 1] : Assets_.COLORS_2[+Main.Instance.FrontendStoreState.minlevel[i] - 1],
                            color: Main.Instance.FrontendStoreState.minlevel[i] === undefined ? isPureScandibet() ? Assets_.COLORS_5[0] : Assets_.COLORS_2[0] :
                            isPureScandibet() ? Assets_.COLORS5[+Main.Instance.FrontendStoreState.minlevel[i] - 1] : Assets_.COLORS5[+Main.Instance.FrontendStoreState.minlevel[i] - 1],
                        }}
                    >
                        {Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.FrontendStoreState.minlevel[i] - 1]['name']}
                    </div>
                    :
                    <FormSelect
                        background = {Main.Instance.FrontendStoreState.minlevel[i] === undefined ? isPureScandibet() ? Assets_.COLORS_5[0] : Assets_.COLORS_2[0] :
                                     isPureScandibet() ? Assets_.COLORS_5[+Main.Instance.FrontendStoreState.minlevel[i] - 1] : Assets_.COLORS_2[+Main.Instance.FrontendStoreState.minlevel[i] - 1]}
                        color = {Main.Instance.FrontendStoreState.minlevel[i] === undefined ? isPureScandibet() ? Assets_.COLORS_5[0] : Assets_.COLORS_2[0] :
                                    isPureScandibet() ? Assets_.COLORS5[+Main.Instance.FrontendStoreState.minlevel[i] - 1] : Assets_.COLORS2[+Main.Instance.FrontendStoreState.minlevel[i] - 1]}
                        level165 = {true}
                        value = {Main.Instance.FrontendStoreState.minlevel[i] === undefined ? '0' :
                                Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.FrontendStoreState.minlevel[i] - 1].id.toString()}
                        data = {Main.Instance.DatabaseDataState.levelsAllStream}
                        // background = {'#5bc0de'}
                        ClassName = "form-control"
                        o1 = {Assets.LevelQ}
                        // o2 = {Assets.LevelQ}
                        title = {Assets.MinimalPlayersLevel + ' ' + Main.Instance.FrontendStoreState.Category[i + 2]['name']}
                        onChange = {(text) => SelectMinLevel(i, text)}
                        icon = {Assets_.faCogClass}
                    />}
                </div>,
            )
        }
    }

    return categories
}

function getCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []
    // console.log (Main.Instance.FrontendStoreState.notify)
    if (Main.Instance.FrontendStoreState.notify && Main.Instance.FrontendStoreState.Category) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.notify.length; i++) { categories.push(
        <FormSelect
            key = {i.toString()}
            level165 = {true}
            value = {Main.Instance.FrontendStoreState.notify[i] === undefined ? '0' : 
            (Main.Instance.FrontendStoreState.notify[i] + 1).toString()}
            data = {Main.Instance.FrontendStoreState.Category}
            // background = {parseInt(Main.Instance.FrontendStoreState.backend_id) ? '#5bc0de' : ''}
            ClassName = "form-control"
            o1 = {Assets.CategoryQ}
            // o2 = {Assets.CategoryQ}
            title = {Assets.NotificationTrigger}
            onChange = {(text) => SelectCategory2(text, i)}
            icon = {Assets_.faRewardLgClass}
        />,
    )
    }
    }

    categories.push(
        <button disabled = {!isUserAdmin()} key = "-1" onClick = {() => DelCategory()} className = "btn btn-danger btn-sm fr" style = {{marginRight: '0px', marginTop: '5px'}}>
            <i className = {Assets_.faTrashLgClass}/>
        </button>,
    )

    categories.push(
        <button disabled = {!isUserAdmin()} key = "-2" onClick = {() => AddCategory2()} className = "btn btn-success btn-sm fr" style = {{marginRight: '10px', marginTop: '5px'}}>
            {Assets.Add}
        </button>,
    )

    return categories
}

function SelectCategory2(text: string, which: number) {
    const notify: number[] = Object.assign ([], Main.Instance.FrontendStoreState.notify)
    notify[which] = +text - 1
    Logic.Type.New (Main.Instance.FrontendStoreState, {notify})
}

function AddCategory2() {
    const notify: number[] = Object.assign ([], Main.Instance.FrontendStoreState.notify)
    notify.push(0)
    Logic.Type.New (Main.Instance.FrontendStoreState, {notify})
}

function DelCategory() {
    let notify: number[] = Object.assign ([], Main.Instance.FrontendStoreState.notify)
    notify = notify.slice(0, notify.length - 1)
    Logic.Type.New (Main.Instance.FrontendStoreState, {notify})
}

function AddMail() {
    const mail: string[] = Object.assign ([], Main.Instance.FrontendStoreState.mail)
    mail.push('|')
    Logic.Type.New (Main.Instance.FrontendStoreState, {mail})
}

function DelMail(which: number) {
    const mail: string[] = []
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.mail.length; i++) { if (i !== which) { mail.push (Main.Instance.FrontendStoreState.mail[i]) } }
    Logic.Type.New (Main.Instance.FrontendStoreState, {mail})
}

function getMails(): JSX.Element[] {
    const mails: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.mail) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.mail.length; i++) { mails.push(
    <div 
        key = {i.toString()}
        style = {{borderBottom: i !== Main.Instance.FrontendStoreState.mail.length - 1 ? '1px solid #ddd' : '0px', paddingTop: '10px', paddingBottom: '10px', position: 'relative'}}
        className = "clearfix test-site-data light_grey"
    >
        {i === 0 ?
        <button disabled = {!isUserAdmin()} onClick = {() => AddMail()} className = "btn btn-success btn-sm" style = {{position: 'absolute', left: '51.1%', top: '15px', marginRight: '0px'}}>
            Add
        </button> :
        <button
            disabled = {!isUserAdmin()}
            onClick = {() => DelMail(i)}
            className = "btn btn-danger btn-sm"
            style = {{position: 'absolute', left: '51.1%', top: '15px', marginRight: '0px', marginLeft: '5px'}}
        >
            <i className = {Assets_.faTrashLgClass}/>
        </button>}
    
        <FormInput
            value = {!Main.Instance.FrontendStoreState.mail || !Main.Instance.FrontendStoreState.mail[i] ? '' :
                    Main.Instance.FrontendStoreState.mail[i].split('|')[0]}
            ClassName = "form-control register-input"
            type = "text"
            divClass = "input-test-site"
            onChange = {(text) => ChangeMail(text, i, 0)}
            placeholder = {Assets.pEmail}
            fix = {true}
            Whole50 = {true}
            iconText = {(i+1).toString()}
            iconTextSize = "16px"
            // icon = {Assets_.faEmailClass}
        />

        <FormInput
            value = {!Main.Instance.FrontendStoreState.mail || !Main.Instance.FrontendStoreState.mail[i] ? '' :
                    Main.Instance.FrontendStoreState.mail[i].split('|')[1]}
            ClassName = "form-control register-input"
            type = "text"
            divClass = "input-test-site"
            onChange = {(text) => ChangeMail(text, i, 1)}
            placeholder = {Assets.pFirstName + ' ' + Assets.pLastName}
            fix = {true}
            Whole50 = {true}
            // iconText = {Assets.JSONWebTokenShort}
            // iconTextSize = "12px"
            icon = {Assets_.faUserClass}
        />
    </div>)
    }
    }
    return mails
}

function ChangeMail(text: string, i: number, which: number) {
    const mail: string[] = Object.assign ([], Main.Instance.FrontendStoreState.mail)
    if (which === 0) { mail[i] = text + '|' + mail[i].split('|')[1] } else if (which === 1) { mail[i] = mail[i].split('|')[0] + '|' + text }
    Logic.Type.New(Main.Instance.FrontendStoreState, {mail})
}

function getLevels(): JSX.Element[] {
    const levels: JSX.Element[] = []
    // alert (Main.Instance.DatabaseDataState.levelsAllStream.length)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.levelsAllStream.length; i++) {
        // if (i === 0) console.log (Main.Instance.DatabaseDataState.levelsAllStream[0])
        let special: string = ''
        if (i === 0) { special = '(initial)' } else if (i === Main.Instance.DatabaseDataState.levelsAllStream.length - 1) { special = '(final)' }
        levels.push (
            <div 
                key = {Main.Instance.DatabaseDataState.levelsAllStream[i]['id']} 
                style = {{
                    paddingLeft: '10px',
                    width: '200px',
                    textAlign: 'left',
                    fontWeight: 700,
                    height: '33px',
                    fontSize: '20px',
                    lineHeight: '33px',
                    background: isPureScandibet() ? Assets_.COLORS_5[i] : Assets_.COLORS_2[i],
                    color: isPureScandibet() ? Assets_.COLORS5[i] : Assets_.COLORS2[i],
                }}
            >
                {Main.Instance.DatabaseDataState.levelsAllStream[i]['hierarchy'] + '. ' + Main.Instance.DatabaseDataState.levelsAllStream[i]['name']}
                <small style = {{marginLeft: '10px'}}>{special}</small>
            </div>,
        )
    }
    return levels
}

function SwitchChanged(Which: number, which: number, i: number, property?: string, Property?: string, PROPERTY?: string, odds?: number/*, Odds?: string*/) {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    // sports
    if (Which === 10) {
        if (which === 0) { custom['general']['userEntry']['data']['sports'][i]['Show'] = !custom['general']['userEntry']['data']['sports'][i]['Show'] } else if (which === 1) { custom['general']['userEntry']['data']['sports'][i]['ShowImage'] = !custom['general']['userEntry']['data']['sports'][i]['ShowImage'] } else if (which === 2) { custom['general']['userEntry']['data']['sports'][i]['ShowName'] = !custom['general']['userEntry']['data']['sports'][i]['ShowName'] }
    } else if (Which === 11 && property) {
        if (which === 0) { custom['general']['userEntry']['data']['categories'][property][i]['Show'] = !custom['general']['userEntry']['data']['categories'][property][i]['Show'] } else if (which === 1) { custom['general']['userEntry']['data']['categories'][property][i]['ShowImage'] = !custom['general']['userEntry']['data']['categories'][property][i]['ShowImage'] } else if (which === 2) { custom['general']['userEntry']['data']['categories'][property][i]['ShowName'] = !custom['general']['userEntry']['data']['categories'][property][i]['ShowName'] }        
    } else if (Which === 12 && property && Property) {
        if (which === 0) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['Show'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['Show']
        } else if (which === 1) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['ShowImage'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['ShowImage']
 } else if (which === 2) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['ShowName'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['ShowName']
 }
    } else if (Which === 0) {
        if (which === 0) { custom['general']['userEntry']['data']['sports'][i]['show'] = !custom['general']['userEntry']['data']['sports'][i]['show'] } else if (which === 1) { custom['general']['userEntry']['data']['sports'][i]['showImage'] = !custom['general']['userEntry']['data']['sports'][i]['showImage'] } else if (which === 2) { custom['general']['userEntry']['data']['sports'][i]['showName'] = !custom['general']['userEntry']['data']['sports'][i]['showName'] } else if (which === 3) { custom['general']['userEntry']['data']['sports'][i]['favourites'] = !custom['general']['userEntry']['data']['sports'][i]['favourites'] } else if (which === 4) { custom['general']['userEntry']['data']['sports'][i]['showFavourites'] = !custom['general']['userEntry']['data']['sports'][i]['showFavourites'] }
    } else if (Which === 1 && property) {
        if (which === 0) { custom['general']['userEntry']['data']['categories'][property][i]['show'] = !custom['general']['userEntry']['data']['categories'][property][i]['show'] } else if (which === 1) { custom['general']['userEntry']['data']['categories'][property][i]['showImage'] = !custom['general']['userEntry']['data']['categories'][property][i]['showImage'] } else if (which === 2) { custom['general']['userEntry']['data']['categories'][property][i]['showName'] = !custom['general']['userEntry']['data']['categories'][property][i]['showName'] } else if (which === 3) { custom['general']['userEntry']['data']['categories'][property][i]['favourites'] = !custom['general']['userEntry']['data']['categories'][property][i]['favourites'] } else if (which === 4) { custom['general']['userEntry']['data']['categories'][property][i]['showFavourites'] = !custom['general']['userEntry']['data']['categories'][property][i]['showFavourites'] }
    } else if (Which === 2 && property && Property) {
        if (which === 0) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['show'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['show']
        } else if (which === 1) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showImage'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showImage']
 } else if (which === 2) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showName'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showName']
 } else if (which === 3) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['favourites'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['favourites']
 } else if (which === 4) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showFavourites'] =
            !custom['general']['userEntry']['data']['tournaments'][property][Property][i]['showFavourites']
 }
    } else if (Which === 3 && property && Property && PROPERTY) {
        if (which === 0) { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['show'] =
            !custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['show']
        } else if (which === 1) { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['showImage'] =
            !custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['showImage']
 }
    } else if (Which === 4 && property && Property && PROPERTY && odds !== undefined) {
        if (which === 0) {
            if (custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['odds'][odds]['disabled'] === undefined ||
                custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['odds'][odds]['disabled'] === true) {
                custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['odds'][odds]['disabled'] = false
            } else { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['odds'][odds]['disabled'] = true }
        }
    }

    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function TextInputChanged(Which: number, i: number, e, property?: string, Property?: string, PROPERTY?: string) {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    if (Which === 0) { custom['general']['userEntry']['data']['sports'][i]['newName'] = e.target.value } else if (Which === 1 && property) { custom['general']['userEntry']['data']['categories'][property][i]['newName'] = e.target.value } else if (Which === 2 && property && Property) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['newName'] = e.target.value } else if (Which === 3 && property && Property && PROPERTY) { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['newName'] = e.target.value }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function OrderChanged(Which: number, which: number, i: number, e, property?: string, Property?: string, PROPERTY?: string) {
    if (parseInt(e.target.value) > -1) {
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        if (Which === 0) {
            if (which === 0) { custom['general']['userEntry']['data']['sports'][i]['order'] = parseInt(e.target.value) } else if (which === 1) { custom['general']['userEntry']['data']['sports'][i]['Order'] = parseInt(e.target.value) }
        } else if (Which === 1 && property) {
            if (which === 0) { custom['general']['userEntry']['data']['categories'][property][i]['order'] = parseInt(e.target.value) } else if (which === 1) { custom['general']['userEntry']['data']['categories'][property][i]['Order'] = parseInt(e.target.value) }
        } else if (Which === 2 && property && Property) {
            if (which === 0) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['order'] = parseInt(e.target.value) } else if (which === 1) { custom['general']['userEntry']['data']['tournaments'][property][Property][i]['Order'] = parseInt(e.target.value) }
        } else if (Which === 3 && property && Property && PROPERTY) {
            if (which === 0) { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['order'] = parseInt(e.target.value) } else if (which === 1) { custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['Order'] = parseInt(e.target.value) }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    }
}

function DeleteImage(Which: number, i: number, property?: string, Property?: string, PROPERTY?: string) {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    if (Which === 0) {
        custom['general']['userEntry']['data']['sports'][i]['image'] = ''
        custom['general']['userEntry']['data']['sports'][i]['dimensions'] = {width: 0, height: 0}
    } else if (Which === 1 && property) {
        custom['general']['userEntry']['data']['categories'][property][i]['image'] = ''
        custom['general']['userEntry']['data']['categories'][property][i]['dimensions'] = {width: 0, height: 0}
    } else if (Which === 2 && property && Property) {
        custom['general']['userEntry']['data']['tournaments'][property][Property][i]['image'] = ''
        custom['general']['userEntry']['data']['tournaments'][property][Property][i]['dimensions'] = {width: 0, height: 0}
    } else if (Which === 3 && property && Property && PROPERTY) {
        custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['image'] = ''
        custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['dimensions'] = {width: 0, height: 0}
    }

    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function SetImage(Which: number, i: number, e, property?: string, Property?: string, PROPERTY?: string) {
    const cloneFile: File = e.target.files[0]
    const reader = new FileReader()
    if (Which === 0) {
        reader.onload = (ev) => {
            const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
            custom['general']['userEntry']['data']['sports'][i]['image'] = reader.result as string
            custom['general']['userEntry']['data']['sports'][i]['imageFilename'] = cloneFile.name

            const img = new Image()
            img.onload = () => {
                custom.general.userEntry.data.sports[i].dimensions = {height: img.height, width: img.width}
                Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
            }
    
            img.src = reader.result as string
        }
    } else if (Which === 1 && property) {
        reader.onload = (ev) => {
            const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
            custom['general']['userEntry']['data']['categories'][property][i]['image'] = reader.result as string
            custom['general']['userEntry']['data']['categories'][property][i]['imageFilename'] = cloneFile.name

            const img = new Image()
            img.onload = () => {
                custom.general.userEntry.data.categories[property][i].dimensions = {height: img.height, width: img.width}
                Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
            }
    
            img.src = reader.result as string
        }
 } else if (Which === 2 && property && Property) {
        reader.onload = (ev) => {
            const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
            custom['general']['userEntry']['data']['tournaments'][property][Property][i]['image'] = reader.result as string
            custom['general']['userEntry']['data']['tournaments'][property][Property][i]['imageFilename'] = cloneFile.name
            const img = new Image()
            img.onload = () => {
                custom.general.userEntry.data.tournaments[property][Property][i].dimensions = {height: img.height, width: img.width}
                Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
            }
    
            img.src = reader.result as string
        }
 } else if (Which === 3 && property && Property && PROPERTY) {
        reader.onload = (ev) => {
            const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
            custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['image'] = reader.result as string
            custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY][i]['imageFilename'] = cloneFile.name
            const img = new Image()
            img.onload = () => {
                custom.general.userEntry.data.matches[property][Property][PROPERTY][i].dimensions = {height: img.height, width: img.width}
                Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
            }
    
            img.src = reader.result as string
        }
 }
    reader.readAsDataURL(cloneFile);
}

function getSports(data: ISport[]): JSX.Element {
    const sports: JSX.Element[] = []
    const Sports: JSX.Element[] = []
    // console.log (Main.Instance.FrontendStoreState.custom)
    for (let i: number = 0; i < data.length; i++) {
        const item = data[i]
        if (item['image20']) { delete(item['image20']) }
        const to_push: JSX.Element = 
        <tr key = {item.id}>
            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(0, 0, i)} on = {item.show}/></td>
            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(0, 3, i)} on = {item.favourites} /*enabled = {item.show === true}*//></td>
            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(0, 4, i)} on = {item.showFavourites} enabled = {item.favourites === true}/></td>
            <td style = {item.show ? {color: '#333', textAlign: 'center'} : {color: '#999', textAlign: 'center'}}>
                <input
                    min = "0"
                    type = "number"
                    readOnly = {!item.show}
                    className = "text-input ti2"
                    value = {item.order ? item.order.toString() : ''}
                    onChange = {(e) => OrderChanged(0, 0, i, e)}
                />
            </td>
            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(0, 2, i)} on = {item.showName} enabled = {item.show === true}/></td>
            <td style = {(!item.newName || item.newName.length === 0) && item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : {color: '#999', fontWeight: 600, lineHeight: '30px'}}>
            {item.english}</td>
            <td style = {item.show ? {color: '#333'} : {color: '#999'}}>
                <input readOnly = {!item.show} className = "text-input" value ={item.newName} onChange = {(e) => TextInputChanged(0, i, e)}/>
            </td>
            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(0, 1, i)} on = {item.showImage} enabled = {item.show === true}/></td>
            <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                {item.image && item.image.length ?
                <img
                    className = {!item.showImage ? 'grayscale' : ''}
                    height = {40}
                    src = {item.image}
                /> : void 0}
                {item.image && item.dimensions ?
                <div
                    className = "doh"
                    style = {{
                        height: item.dimensions.height < 200 ? '200px' : item.dimensions.height > 300 ? '300px' : item.dimensions.height + 'px',
                        width:  item.dimensions.height < 200 ? item.dimensions.width / item.dimensions.height * 200 + 'px' :
                                item.dimensions.height > 300 ? (item.dimensions.width / item.dimensions.height * 300) + 'px' : item.dimensions.width + 'px',
                        background: `url(${Assets_.checkered})`,
                    }}
                >
                    <div>&nbsp;</div>
                    <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                    <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{item.dimensions.width}px</span>
                    <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{item.dimensions.height}px</span>
                    <img
                        style = {{
                            position:'absolute',
                            top: '0px',
                            bottom: '0px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            width: item.dimensions.width + 'px',
                            height: item.dimensions.height + 'px',
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }} 
                        src = {item.image}
                    />
                </div> : void 0}
            </td>
            <td style = {{textAlign: 'center', height: '40px', paddingTop: '4px', paddingBottom: '4px'}}>
                {!item.showImage ? void 0 :
                <input
                    accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                    style = {{marginLeft: '0px', paddingLeft: '0px', width: '91px', marginRight:'0px', display: 'inline'}}
                    type = "file"
                    onChange = {(e) => SetImage(0, i, e)}
                    onClick = {(event) => event.target = null}
                />}
                {item.imageFilename ? <div style = {{fontSize: '12px', lineHeight: '17px'}}>{item.imageFilename}</div> : void 0}
            </td>
            <td style = {{textAlign: 'center'}}>
                <button onClick = {() => DeleteImage(0, i)} disabled = {!item.image || item.image.length === 0} className = "btn btn-danger btn-xs mr0">
                    <FontAwesomeIcon icon = {faTrashAlt}/>
                </button>
            </td>
            <td style = {{textAlign: 'center'}}>
                <button onClick = {() => DbRoutes.getAllCategories(item.id, item.english)} disabled = {!item.show} className = "btn btn-default teal btn-xs mr0">
                    <FontAwesomeIcon style = {{paddingTop: '1px'}} icon = {faSync}/>
                </button>
            </td>
        </tr>

        if (item.show) { Sports.push(to_push) } else { sports.push(to_push) }
    }
    return (
        <div style = {{paddingLeft: '10px', paddingRight: '10px'}}>
            <table className = "table" style = {{marginTop: '10px', border: '1px solid #bbb'}}>
                <thead>
                    <tr className = "label-primary" style = {{fontWeight: 900, color: '#fff'}}>
                        {/*<th/>*/}
                        <th title = {Assets.Show} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}><FontAwesomeIcon icon = {faEye}/></th>
                        <th title = {Assets.Favourites} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/></th>
                        <th title = "Favourites Sport" style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/>&nbsp;&nbsp;<FontAwesomeIcon icon = {faFootballBall}/></th>
                        <th style = {{textAlign: 'center'}}title = {Assets.Order}><FontAwesomeIcon icon = {faListOl}/></th>
                        {/*<th>ID</th>*/}
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Name}</th>
                        <th>{Assets.DefaultName}</th>
                        <th>{Assets.CustomName}</th>
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Image}</th>
                        <th title = {Assets.Image} style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faImage} size = "lg"/>
                        </th>
                        <th style = {{textAlign: 'center', borderRight: '1px dashed white'}} title = {Assets.UpdateImage}>
                            <span>{Assets.Update}</span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon = {faImage} size = "lg"/>
                        </th>
                        <th title = "Delete Custom image" style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faTimes} size = "lg"/>
                        </th>
                        <th title = "Refresh categories for the sport" style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faVolleyballBall}/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Sports}
                    {sports}
                </tbody>
            </table>
        </div>
    )
}

function ToggleSportWithinCategories(property: string) {
    // console.log (property)
    const custom: ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    if (initialIsCapital(property)) {
        custom['general']['userEntry']['data']['categories'][property.toLowerCase()] = Object.assign([], custom['general']['userEntry']['data']['categories'][property])
        delete(custom['general']['userEntry']['data']['categories'][property])
    } else {
        custom['general']['userEntry']['data']['categories'][capitalizeFirstLetter(property)] = Object.assign([], custom['general']['userEntry']['data']['categories'][property])
        delete(custom['general']['userEntry']['data']['categories'][property])
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    // console.log (custom)
}

function ToggleSportWithinTournaments(property: string) {
    const custom: ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)

    if (initialIsCapital(property)) {
        custom['general']['userEntry']['data']['tournaments'][property.toLowerCase()] = Object.assign({}, custom['general']['userEntry']['data']['tournaments'][property])
        delete(custom['general']['userEntry']['data']['tournaments'][property])
    } else {
        custom['general']['userEntry']['data']['tournaments'][capitalizeFirstLetter(property)] = Object.assign({}, custom['general']['userEntry']['data']['tournaments'][property])
        delete(custom['general']['userEntry']['data']['tournaments'][property])
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ToggleSportWithinMatches(property: string) {
    const custom: ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)

    if (initialIsCapital(property)) {
        custom['general']['userEntry']['data']['matches'][property.toLowerCase()] = Object.assign({}, custom['general']['userEntry']['data']['matches'][property])
        delete(custom['general']['userEntry']['data']['matches'][property])
    } else {
        custom['general']['userEntry']['data']['matches'][capitalizeFirstLetter(property)] = Object.assign({}, custom['general']['userEntry']['data']['matches'][property])
        delete(custom['general']['userEntry']['data']['matches'][property])
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}
/*
function ToggleCategoryWithinTournaments(property: string, Property: string) {
    const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)

    if (initialIsCapital(Property)) {
        custom['general']['userEntry']['data']['tournaments'][property][Property.toLowerCase()] = 
            Object.assign([], custom['general']['userEntry']['data']['tournaments'][property][Property])
        delete(custom['general']['userEntry']['data']['tournaments'][property][Property])
    } else {
        custom['general']['userEntry']['data']['tournaments'][property][capitalizeFirstLetter(Property)] = 
            Object.assign([], custom['general']['userEntry']['data']['tournaments'][property][Property])
        delete(custom['general']['userEntry']['data']['tournaments'][property][Property])
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}
*/
function initialIsCapital(word: string): boolean {
    return word[0] !== word[0].toLowerCase()
    // return word.charAt(0) !== word.charAt(0).toLowerCase()
}
/*
function secondIsCapital(word: string): boolean {
    console.log (word)
    return word.charAt(1) !== word.charAt(1).toLowerCase()
}

function capitalizeSecondLetter(string) {
    return string.charAt(0) + string.charAt(1).toUpperCase() + string.slice(2);
}
*/

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function getSportFavourites(data: any): JSX.Element {
    // console.log(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data'])
    const f1 = getFavouritesSport()
    const f2 = getFavouritesCategory()
    const f3 = getFavouritesTournament()
    // console.log (f1)
    const favourites: JSX.Element[] = []
    for (let i: number = 0; i < f1.length; i++) {
        let ii: number = -1
        for (let jj: number = 0; jj < Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'].length; jj++) { 
            if (+Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports'][jj]['id'] === +f1[i]['id']) {
            ii = jj
            break
        }
        }
 
        if (ii !== -1) {
        favourites.push (
            <tr key = {'0' + i}>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faCheck} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faTimes} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faTimes} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(10, 0, ii)} on = {f1[i].Show}/></td>
                <td style = {{color: '#333', textAlign: 'center'}}>
                    <input
                        min = "0"
                        type = "number"
                        // readOnly = {!f1[i].show}
                        className = "text-input ti2"
                        value = {f1[i].Order ? f1[i].Order.toString() : ''}
                        onChange = {(e) => OrderChanged(0, 1, ii, e)}
                    />
                </td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(10, 2, ii)} on = {f1[i].ShowName} enabled = {f1[i].Show === true}/></td>
                <td><div style = {{fontWeight: 900, marginTop: '4px'}}>{f1[i].newName && f1[i].newName.length > 0 ? f1[i].newName : f1[i].english}</div></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(10, 1, ii)} on = {f1[i].ShowImage} enabled = {f1[i].Show === true}/></td>
                <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                    {f1[i].image && f1[i].image.length ?
                    <img
                        className = {!f1[i].showImage ? 'grayscale' : ''}
                        height = {40}
                        src = {f1[i].image}
                    /> : void 0}
                    {f1[i].image && f1[i].dimensions ?
                    <div
                        className = "doh"
                        style = {{
                            height: f1[i].dimensions.height < 200 ? '200px' : f1[i].dimensions.height > 300 ? '300px' : f1[i].dimensions.height + 'px',
                            width:  f1[i].dimensions.height < 200 ? f1[i].dimensions.width / f1[i].dimensions.height * 200 + 'px' :
                                    f1[i].dimensions.height > 300 ? (f1[i].dimensions.width / f1[i].dimensions.height * 300) + 'px' : f1[i].dimensions.width + 'px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    >
                        <div>&nbsp;</div>
                        <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                        <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{f1[i].dimensions.width}px</span>
                        <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{f1[i].dimensions.height}px</span>
                        <img
                            style = {{
                                position:'absolute',
                                top: '0px',
                                bottom: '0px',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                width: f1[i].dimensions.width + 'px',
                                height: f1[i].dimensions.height + 'px',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                            src = {f1[i].image}
                        />
                    </div> : void 0}
                </td>
            </tr>,
        )
        }
    }
    // console.log (f2)
    for (let i: number = 0; i < f2.length; i++) {
        if (f2[i].showFavourites === undefined || f2[i].showFavourites === false) { continue }
        let ii: number = -1
        // console.log (f2[i].property)
        
        for (let jj: number = 0; jj < Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][f2[i].property].length; jj++) { 
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][f2[i].property][jj]['id'] === f2[i]['id']) {
            ii = jj
            break
        }
        }

        // console.log (property)
        if (ii !== -1) {
        favourites.push (
            <tr key = {'1' + i}>
                <td style = {{textAlign: 'center'}}><div style = {{fontWeight: 700, marginTop: '4px'}}>{capitalizeFirstLetter(f2[i].property)}</div></td>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faCheck} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faTimes} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(11, 0, ii, f2[i].property)} on = {f2[i].Show}/></td>
                <td style = {{color: '#333', textAlign: 'center'}}>
                    <input
                        min = "0"
                        type = "number"
                        // readOnly = {!f2[i].show}
                        className = "text-input ti2"
                        value = {f2[i].Order ? f2[i].Order.toString() : ''}
                        onChange = {(e) => OrderChanged(1, 1, ii, e, f2[i].property)}
                    />
                </td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(11, 2, ii, f2[i].property)} on = {f2[i].ShowName} enabled = {f2[i].Show === true}/></td>
                <td><div style = {{fontWeight: 900, marginTop: '4px'}}>{f2[i].newName && f2[i].newName.length > 0 ? f2[i].newName : f2[i].english}</div></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(11, 1, ii, f2[i].property)} on = {f2[i].ShowImage} enabled = {f2[i].Show === true}/></td>
                <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                    {f2[i].image && f2[i].image.length ?
                    <img
                        className = {!f2[i].showImage ? 'grayscale' : ''}
                        height = {40}
                        src = {f2[i].image}
                    /> : void 0}
                    {f2[i].image && f2[i].dimensions ?
                    <div
                        className = "doh"
                        style = {{
                            height: f2[i].dimensions.height < 200 ? '200px' : f2[i].dimensions.height > 300 ? '300px' : f2[i].dimensions.height + 'px',
                            width:  f2[i].dimensions.height < 200 ? f2[i].dimensions.width / f2[i].dimensions.height * 200 + 'px' :
                                    f2[i].dimensions.height > 300 ? (f2[i].dimensions.width / f2[i].dimensions.height * 300) + 'px' : f2[i].dimensions.width + 'px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    >
                        <div>&nbsp;</div>
                        <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                        <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{f2[i].dimensions.width}px</span>
                        <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{f2[i].dimensions.height}px</span>
                        <img
                            style = {{
                                position:'absolute',
                                top: '0px',
                                bottom: '0px',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                width: f2[i].dimensions.width + 'px',
                                height: f2[i].dimensions.height + 'px',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                            src = {f2[i].image}
                        />
                    </div> : void 0}
                </td>
            </tr>,
        )
        }
    }
    // getFavouritesTournament
    // console.log (f3)
    for (let i: number = 0; i < f3.length; i++) {
        if (f3[i].showFavourites === undefined || f3[i].showFavourites === false) { continue }
        let ii: number = -1
        // console.log (f3[i].property)
        // console.log (f3[i].Property)
        // console.log (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'])
        for (let jj: number = 0; jj < Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][f3[i].property][f3[i].Property].length; jj++) { 
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][f3[i].property][f3[i].Property][jj]['id'] === f3[i]['id']) {
            ii = jj
            break
        }
        }

        // console.log (property)
        if (ii !== -1) {
        favourites.push (
            <tr key = {'2' + i}>
                <td style = {{textAlign: 'center'}}><div style = {{fontWeight: 700, marginTop: '4px'}}>{capitalizeFirstLetter(f3[i].property)}</div></td>
                <td style = {{textAlign: 'center'}}><div style = {{fontWeight: 700, marginTop: '4px'}}>{capitalizeFirstLetter(f3[i].Property)}</div></td>
                <td style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faCheck} size = "2x"/></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(12, 0, ii, f3[i].property, f3[i].Property)} on = {f3[i].Show}/></td>
                <td style = {{color: '#333', textAlign: 'center'}}>
                    <input
                        min = "0"
                        type = "number"
                        // readOnly = {!f3[i].show}
                        className = "text-input ti2"
                        value = {f3[i].Order ? f3[i].Order.toString() : ''}
                        onChange = {(e) => OrderChanged(2, 1, ii, e, f3[i].property, f3[i].Property)}
                    />
                </td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(12, 2, ii, f3[i].property, f3[i].Property)} on = {f3[i].ShowName} enabled = {f3[i].Show === true}/></td>
                <td><div style = {{fontWeight: 900, marginTop: '4px'}}>{f3[i].newName && f3[i].newName.length > 0 ? f3[i].newName : f3[i].english}</div></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(12, 1, ii, f3[i].property, f3[i].Property)} on = {f3[i].ShowImage} enabled = {f3[i].Show === true}/></td>
                <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                    {f3[i].image && f3[i].image.length ?
                    <img
                        className = {!f3[i].showImage ? 'grayscale' : ''}
                        height = {40}
                        src = {f3[i].image}
                    /> : void 0}
                    {f3[i].image && f3[i].dimensions ?
                    <div
                        className = "doh"
                        style = {{
                            height: f3[i].dimensions.height < 200 ? '200px' : f3[i].dimensions.height > 300 ? '300px' : f3[i].dimensions.height + 'px',
                            width:  f3[i].dimensions.height < 200 ? f3[i].dimensions.width / f3[i].dimensions.height * 200 + 'px' :
                                    f3[i].dimensions.height > 300 ? (f3[i].dimensions.width / f3[i].dimensions.height * 300) + 'px' : f3[i].dimensions.width + 'px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    >
                        <div>&nbsp;</div>
                        <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                        <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{f3[i].dimensions.width}px</span>
                        <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{f3[i].dimensions.height}px</span>
                        <img
                            style = {{
                                position:'absolute',
                                top: '0px',
                                bottom: '0px',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                width: f3[i].dimensions.width + 'px',
                                height: f3[i].dimensions.height + 'px',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                            src = {f3[i].image}
                        />
                    </div> : void 0}
                </td>
            </tr>,
        )
        }
    }

    return (
        <div style = {{paddingLeft: '10px', paddingRight: '10px'}}>
            <table className = "table" style = {{marginTop: '10px', border: '1px solid #bbb'}}>
                <thead>
                    <tr className = "label-primary" style = {{fontWeight: 900, color: '#fff'}}>
                        <th title = {Assets.Sport} style = {{textAlign: 'center'}}>{Assets.Sport}</th>
                        <th title = {Assets.Category} style = {{textAlign: 'center'}}>{Assets.Category}</th>
                        <th title = {Assets.Tournament} style = {{textAlign: 'center'}}>{Assets.Tournament}</th>
                        <th title = {Assets.Show} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}><FontAwesomeIcon icon = {faEye}/></th>
                        <th title = {Assets.Order} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faListOl}/></th>
                        <th title = {Assets.Name} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Name}</th>
                        <th title = {Assets.Name} style = {{textAlign: 'left'}}>{Assets.DisplayedName}</th>
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Image}</th>
                        <th title = {Assets.Image} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faImage} size = "lg"/></th>
                    </tr>
                </thead>
                <tbody>
                    {favourites}
                </tbody>
            </table>
        </div>
    )

}
function getSportMatches(data: any): JSX.Element {
    // console.log(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'])
   if (data === undefined) {
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        data = Object.assign({}, custom['general']['database']['matches'])
        custom['general']['userEntry']['data']['matches'] = Object.assign({}, custom['general']['database']['matches'])
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
   } 
   // console.log (data)
   const MATCHES: JSX.Element[] = []
   const hiddenMatches: JSX.Element[] = []
   // let tdMain: boolean = false
   for (const property in data) { if (data.hasOwnProperty(property)) {
       if (data[property] === undefined) { continue }
       // data[property] - contains data for single sport
       if (initialIsCapital(property)) { // these are hidden, we don't have to do anything else with them
           hiddenMatches.push(
               <tr key = {property}>
                   <td className = "norotate cyan2" colSpan = {13}>
                       <span title = {Assets.Expand + ' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinMatches(property)}>
                           <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faExpand}/>&nbsp;&nbsp;
                           {property + ': ' + getNumberOfAllMatchesInSport(property) + ' ' + Assets.Records.toLowerCase()}
                       </span>
                   </td>
               </tr>,
           )
       } else {
            const to_push: JSX.Element =
                <td className = "rotate cyan2" rowSpan = {getNumberOfAllMatchesInSport(property) + 1}>
                    <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinMatches(property)}>
                        <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faCompress}/>&nbsp;&nbsp;
                        {capitalizeFirstLetter(property) + ': ' + getNumberOfAllMatchesInSport(property) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <button onClick = {() => DeleteSportMatch(property)} className = "btn btn-danger btn-sm mr0 ml0">
                        <FontAwesomeIcon icon ={faTrashAlt} size = "lg"/>
                    </button>
                </td>
            MATCHES.push(<tr key = {0}>{to_push}</tr>)

            for (const Property in data[property]) { if (data[property].hasOwnProperty(Property)) {
                const matches: JSX.Element[] = []
                const Matches: JSX.Element[] = []
                let isCategoryWritten: boolean = false
                // console.log (data[property][Property])

                const to_push2: JSX.Element =
                <td className = "rotate indigo" rowSpan = {getNumberOfAllMatchesInCategory(property, Property)}>
                    <span>
                        {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllMatchesInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <button onClick = {() => DeleteCategoryMatch(property, Property)} className = "btn btn-danger btn-sm mr0 ml0">
                        <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                    </button>
                </td>

                let allHidden: boolean = true
                for (const PROPERTY in data[property][Property]) { if (data[property][Property].hasOwnProperty(PROPERTY)) {
        
                    if (data[property][Property][PROPERTY]) { for (let i: number = 0; i < data[property][Property][PROPERTY].length; i++) { if (data[property][Property][PROPERTY][i].show) {
                        allHidden = false
                        break
                    }
 }
                    }

                /*if (initialIsCapital(Property) && data[property][Property]) {
                    hiddenTournaments.push(
                    <tr key = {property}>
                        <td className = "norotate indigo" colSpan = {11}>
                            <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}>
                                {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllTournamentsInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}
                            </span>
                        </td>
                    </tr>
                    )
                } else*/

                    if (data[property][Property][PROPERTY]) { for (let i: number = 0; i < data[property][Property][PROPERTY].length; i++) {
                        const item = data[property][Property][PROPERTY][i]
                        // console.log (item)
                        const Odds: JSX.Element[] = []

                        for (let ii: number = 0; ii < item.odds.length; ii++) {
                            Odds.push (
                                <button
                                    onClick = {() => SwitchChanged(4, 0, i, property, Property, PROPERTY, ii)}
                                    style = {{padding: '0px 2px', marginRight: '2px'}}
                                    className = {item.odds[ii].disabled === undefined ||  item.odds[ii].disabled === true ? 'btn btn-xs btn-success' : 'btn btn-xs btn-danger'}
                                    title = {item.odds[ii].oddsName}
                                    key = {ii.toString()}
                                >
                                    <span>{item.odds[ii].oddsNameShort}</span>
                                    <small>
                                        {item.odds[ii].oddsNameShort === 'OU' ? item.odds[ii].oddsName === 'Other Over/Under' ? '' : ' 2.5' : ''}
                                     </small>
                                </button>,
                            )
                        }

                        const toPush: JSX.Element =
                        <tr key = {property + '_' + Property + '_'+ item.id}>
                            {isCategoryWritten === false ? to_push2 : void 0}
                            {/*i === 0 && initialIsCapital(Property) ?
                            <td className = "norotate indigo" colSpan = {11}>
                                <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}>
                                    {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllTournamentsInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}
                                </span>
                            </td>
                            : void 0*/}
                            {i === 0/*Tournaments.length === 0 && item.show || !item.show && i === 0 && allHidden.toString() === 'true'*/ ?
                            <td className = "rotate pink2" rowSpan = {getNumberOfAllMatchesInTournament(property, Property, PROPERTY)}>
                                <span /*title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}*/>
                                    {/*<FontAwesomeIcon style = {{lineHeight: '30px'}} icon = "compress"/>&nbsp;&nbsp;*/}
                                    {capitalizeFirstLetter(PROPERTY) + ': ' + getNumberOfAllMatchesInTournament(property, Property, PROPERTY) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <button onClick = {() => DeleteSportCategoryTournament(property, Property)} className = "btn btn-danger btn-sm mr0 ml0">
                                    <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                                </button>
                            </td> : void 0}
                            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(3, 0, i, property, Property, PROPERTY)} on = {item.show}/></td>
                            <td style = {item.show ? {color: '#333', textAlign: 'center'} : {color: '#999', textAlign: 'center'}}>
                                <input
                                    min = "0"
                                    type = "number"
                                    readOnly = {!item.show}
                                    className = "text-input ti2"
                                    value = {item.order ? item.order.toString() : ''}
                                    onChange = {(e) => OrderChanged(3, 0, i, e, property, Property, PROPERTY)}
                                />
                            </td>

                            {/*
                            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(3, 2, i, property, Property)} on = {item.showName} enabled = {item.show === true}/></td>
                            */}
                            <td 
                                style = {item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : 
                                        {color: '#999', fontWeight: 600, lineHeight: '30px'}}
                            >
                                {item.date.substring(8,10) + '.' + item.date.substring(5,7)}
                            </td>
                            <td 
                                style = {item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : 
                                        {color: '#999', fontWeight: 600, lineHeight: '30px'}}
                            >
                                {item.date.substring(11,16)}
                            </td>
                            <td 
                                style = {(!item.newName || item.newName.length === 0) && item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : 
                                        {color: '#999', fontWeight: 600, lineHeight: '30px'}}
                            >
                                {item.homeTeam + ' - ' + item.awayTeam}
                            </td>
                            {/*
                            <td style = {item.show ? {color: '#333'} : {color: '#999'}}>
                                <input readOnly = {!item.show} className = "text-input" value ={item.newName} onChange = {(e) => TextInputChanged(3, i, e, property, Property, PROPERTY)}/>
                            </td>
                            */}
                            <td>{Odds}</td>
                            <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(3, 1, i, property, Property, PROPERTY)} on = {item.showImage} enabled = {item.show===true}/></td>
                            <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                                {item.image && item.image.length ?
                                <img
                                    className = {!item.showImage ? 'grayscale' : ''}
                                    height = {40}
                                    src = {item.image}
                                /> : void 0}
                                {item.image && item.dimensions ?
                                <div
                                    className = "doh"
                                    style = {{
                                        height: item.dimensions.height < 200 ? '200px' : item.dimensions.height > 300 ? '300px' : item.dimensions.height + 'px',
                                        width:  item.dimensions.height < 200 ? item.dimensions.width / item.dimensions.height * 200 + 'px' :
                                                item.dimensions.height > 300 ? (item.dimensions.width / item.dimensions.height * 300) + 'px' : item.dimensions.width + 'px',
                                        background: `url(${Assets_.checkered})`,
                                    }}
                                >
                                    <div>&nbsp;</div>
                                    <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                                    <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{item.dimensions.width}px</span>
                                    <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{item.dimensions.height}px</span>
                                    <img
                                        style = {{
                                            position:'absolute',
                                            top: '0px',
                                            bottom: '0px',
                                            left: '0px',
                                            right: '0px',
                                            margin: 'auto',
                                            width: item.dimensions.width + 'px',
                                            height: item.dimensions.height + 'px',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }} 
                                        src = {item.image}
                                    />
                                </div> : void 0}
                            </td>
                            <td style = {{textAlign: 'center', height: '40px', paddingTop: '4px', paddingBottom: '4px'}}>
                                {!item.showImage ? void 0 :
                                <input
                                    accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                                    style = {{marginLeft: '0px', paddingLeft: '0px', width: '91px', marginRight:'0px', display: 'inline'}}
                                    type = "file"
                                    onChange = {(e) => SetImage(3, i, e, property, Property, PROPERTY)}
                                    onClick = {(event) => event.target = null}
                                />}
                                {item.imageFilename ? <div style = {{fontSize: '12px', lineHeight: '17px'}}>{item.imageFilename}</div> : void 0}
                            </td>
                            <td style = {{textAlign: 'center'}}>
                                <button onClick = {() => DeleteImage(3, i, property, Property, PROPERTY)} disabled = {!item.image || item.image.length === 0} className = "btn btn-danger btn-xs mr0">
                                    <FontAwesomeIcon icon = {faTrashAlt}/>
                                </button>
                            </td>
                            {/*
                            <td style = {{textAlign: 'center'}}>
                                <button onClick = {() => DbRoutes.getAllMatches(item.id, item.english, property, Property)} disabled = {!item.show} className = "btn btn-default teal btn-xs mr0">
                                    <FontAwesomeIcon style = {{paddingTop: '1px'}} icon = "sync"/>
                                </button>
                            </td>
                            */}
                        </tr>

                        if (isCategoryWritten === false) { isCategoryWritten = true }

                        if (allHidden) { matches.push(toPush) } else { Matches.push(toPush) }    
                    }
                    }
                }
                }
                for (const item of Matches) { MATCHES.push(item) }
                for (const item of matches) { MATCHES.push(item) }
            }
            }
       }
   }
   }

   return (
       <div style = {{paddingLeft: '10px', paddingRight: '10px'}}>
           <table className = "table" style = {{marginTop: '10px', border: '1px solid #bbb'}}>
               <thead>
                   <tr className = "label-primary" style = {{fontWeight: 900, color: '#fff'}}>
                       <th title = {Assets.Sport} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faFootballBall}/></th>
                       <th title = {Assets.Category} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faVolleyballBall}/></th>
                       <th title = {Assets.Tournament} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faDumbbell}/></th>
                       <th title = {Assets.Show} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}><FontAwesomeIcon icon = {faEye}/></th>
                       <th title = {Assets.Order} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faListOl}/></th>
                       <th style = {{textAlign: 'left', borderLeft: '1px dashed white'}}>{Assets.Date}</th>
                       <th style = {{textAlign: 'left'}}>{Assets.Time}</th>
                       <th style = {{textAlign: 'left'}}>{Assets.Match}</th>
                       {/*<th>Custom Match Name</th>*/}
                       <th>{Assets.Odds}</th>
                        {/*<th>{Assets.Name}</th>*/}
                       {/*<th>{Assets.DefaultName}</th>*/}
                       {/*<th>{Assets.CustomName}</th>*/}
                       <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Image}</th>
                       <th title = {Assets.Image} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faImage} size = "lg"/></th>
                       <th style = {{textAlign: 'center', borderRight: '1px dashed white'}} title = {Assets.UpdateImage}>
                           <span>{Assets.Update}</span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon = {faImage} size = "lg"/>
                       </th>
                       <th title = {Assets.DeleteImage} style = {{textAlign: 'center'}}>
                           <FontAwesomeIcon icon = {faTimes} size = "lg"/>
                       </th>
                       {/*
                       <th title = {Assets.RefreshMatchesForTheTournament} style = {{textAlign: 'center'}}>
                           <FontAwesomeIcon icon = {faDumbbell}/>
                       </th>
                       */}
                   </tr>
               </thead>
               <tbody>
                   {hiddenMatches}
                   {MATCHES}
               </tbody>
           </table>
       </div>
   )
}

function getSportTournaments(data: any): JSX.Element {
    // console.log (Main.Instance.FrontendStoreState.custom)
    if (data === undefined) {
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        data = Object.assign({}, custom['general']['database']['tournaments'])
        custom['general']['userEntry']['data']['tournaments'] = Object.assign({}, custom['general']['database']['tournaments'])
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    }
    // console.log (data)
    const TOURNAMENTS: JSX.Element[] = []
    const hiddenTournaments: JSX.Element[] = []
    // let tdMain: boolean = false
    for (const property in data) { if (data.hasOwnProperty(property)) {
        if (data[property] === undefined) { continue }
        // data[property] - contains data for single sport
        if (initialIsCapital(property)) { // these are hidden, we don't have to do anything else with them
            hiddenTournaments.push(
                <tr key = {property}>
                    <td className = "norotate cyan2" colSpan = {14}>
                        <span title = {Assets.Expand + ' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinTournaments(property)}>
                            <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faExpand}/>&nbsp;&nbsp;
                            {property + ': ' + getNumberOfAllTournamentsInSport(property) + ' ' + Assets.Records.toLowerCase()}
                        </span>
                    </td>
                </tr>,
            )
        } else {
            const to_push: JSX.Element =
                <td className = "rotate cyan2" rowSpan = {getNumberOfAllTournamentsInSport(property) + 1}>
                    <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinTournaments(property)}>
                        <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faCompress}/>&nbsp;&nbsp;
                        {capitalizeFirstLetter(property) + ': ' + getNumberOfAllTournamentsInSport(property) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <button onClick = {() => DeleteSportTournament(property)} className = "btn btn-danger btn-sm mr0 ml0">
                        <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                    </button>
                </td>
            TOURNAMENTS.push(<tr key = {0}>{to_push}</tr>)
            const tournaments: JSX.Element[] = []
            const Tournaments: JSX.Element[] = []

            for (const Property in data[property]) { if (data[property].hasOwnProperty(Property)) {

                let allHidden: boolean = true
        
                if (data[property][Property]) { for (let i: number = 0; i < data[property][Property].length; i++) { if (data[property][Property][i].show) {
                    allHidden = false
                    break
                }
 }
                }

                /*if (initialIsCapital(Property) && data[property][Property]) {
                    hiddenTournaments.push(
                    <tr key = {property}>
                        <td className = "norotate indigo" colSpan = {11}>
                            <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}>
                                {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllTournamentsInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}
                            </span>
                        </td>
                    </tr>                    
                    )
                } else*/

                if (data[property][Property]) { for (let i: number = 0; i < data[property][Property].length; i++) {
                    const item = data[property][Property][i]
                    const toPush: JSX.Element =
                    <tr key = {property + '_' + Property + '_'+ item.id}>
                        {/*tdMain === false ? to_push : void 0*/}
                        {/*i === 0 && initialIsCapital(Property) ?
                        <td className = "norotate indigo" colSpan = {11}>
                            <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}>
                                {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllTournamentsInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}
                            </span>
                        </td>
                        : void 0*/}
                        {i === 0/*Tournaments.length === 0 && item.show || !item.show && i === 0 && allHidden.toString() === 'true'*/ ?
                        <td className = "rotate indigo" rowSpan = {data[property][Property].length}>
                            <span /*title = {Assets.Compress + ' ' + capitalizeFirstLetter(Property)} onClick = {() => ToggleCategoryWithinTournaments(property, Property)}*/>
                                {/*<FontAwesomeIcon style = {{lineHeight: '30px'}} icon = "compress"/>&nbsp;&nbsp;*/}
                                {capitalizeFirstLetter(Property) + ': ' + getNumberOfAllTournamentsInCategory(property, Property) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <button onClick = {() => DeleteSportCategoryTournament(property, Property)} className = "btn btn-danger btn-sm mr0 ml0">
                                <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                            </button>
                        </td> : void 0}
                        <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(2, 0, i, property, Property)} on = {item.show}/></td>
                        <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(2, 3, i, property, Property)} on = {item.favourites} /*enabled = {item.show === true}*//></td>
                        <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(2, 4, i, property, Property)} on = {item.showFavourites} enabled = {item.favourites === true}/></td>
                        <td style = {item.show ? {color: '#333', textAlign: 'center'} : {color: '#999', textAlign: 'center'}}>
                            <input
                                min = "0"
                                type = "number"
                                readOnly = {!item.show}
                                className = "text-input ti2"
                                value = {item.order ? item.order.toString() : ''}
                                onChange = {(e) => OrderChanged(2, 0, i, e, property, Property)}
                            />
                        </td>
                        <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(2, 2, i, property, Property)} /*on = {item.showName} enabled = {item.show === true}*//></td>
                        <td 
                            style = {(!item.newName || item.newName.length === 0) && item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : 
                                    {color: '#999', fontWeight: 600, lineHeight: '30px'}}
                        >
                            {item.english}
                        </td>
                        <td style = {item.show ? {color: '#333'} : {color: '#999'}}>
                            <input readOnly = {!item.show} className = "text-input" value ={item.newName} onChange = {(e) => TextInputChanged(2, i, e, property, Property)}/>
                        </td>
                        <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(2, 1, i, property, Property)} on = {item.showImage} enabled = {item.show === true}/></td>
                        <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                            {item.image && item.image.length ?
                            <img
                                className = {!item.showImage ? 'grayscale' : ''}
                                height = {40}
                                src = {item.image}
                            /> : void 0}
                            {item.image && item.dimensions ?
                            <div
                                className = "doh"
                                style = {{
                                    height: item.dimensions.height < 200 ? '200px' : item.dimensions.height > 300 ? '300px' : item.dimensions.height + 'px',
                                    width:  item.dimensions.height < 200 ? item.dimensions.width / item.dimensions.height * 200 + 'px' :
                                            item.dimensions.height > 300 ? (item.dimensions.width / item.dimensions.height * 300) + 'px' : item.dimensions.width + 'px',
                                    background: `url(${Assets_.checkered})`,
                                }}
                            >
                                <div>&nbsp;</div>
                                <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                                <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{item.dimensions.width}px</span>
                                <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{item.dimensions.height}px</span>
                            <img
                                style = {{
                                    position:'absolute',
                                    top: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    width: item.dimensions.width + 'px',
                                    height: item.dimensions.height + 'px',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }} 
                                src = {item.image}
                            />
                            </div> : void 0}
                        </td>
                        <td style = {{textAlign: 'center', height: '40px', paddingTop: '4px', paddingBottom: '4px'}}>
                            {!item.showImage ? void 0 :
                            <input
                                accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                                style = {{marginLeft: '0px', paddingLeft: '0px', width: '91px', marginRight:'0px', display: 'inline'}}
                                type = "file"
                                onChange = {(e) => SetImage(2, i, e, property, Property)}
                                onClick = {(event) => event.target = null}
                            />}
                            {item.imageFilename ? <div style = {{fontSize: '12px', lineHeight: '17px'}}>{item.imageFilename}</div> : void 0}
                        </td>
                        <td style = {{textAlign: 'center'}}>
                            <button onClick = {() => DeleteImage(2, i, property, Property)} disabled = {!item.image || item.image.length === 0}  className = "btn btn-danger btn-xs mr0">
                                <FontAwesomeIcon icon = {faTrashAlt}/>
                            </button>
                        </td>
                        <td style = {{textAlign: 'center'}}>
                            <button onClick = {() => DbRoutes.getAllMatches(item.id, item.english, property, Property)} disabled = {!item.show} className = "btn btn-default teal btn-xs mr0">
                                <FontAwesomeIcon style = {{paddingTop: '1px'}} icon = {faSync}/>
                            </button>
                        </td>
                    </tr>

                    // if (tdMain === false) tdMain = true

                    if (allHidden) { tournaments.push(toPush) } else { Tournaments.push(toPush) }    
                }
                }
            }
            }
            for (const item of Tournaments) { TOURNAMENTS.push(item) }
            for (const item of tournaments) { TOURNAMENTS.push(item) }
        }
    }
    }

    return (
        <div style = {{paddingLeft: '10px', paddingRight: '10px'}}>
            <table className = "table" style = {{marginTop: '10px', border: '1px solid #bbb'}}>
                <thead>
                    <tr className = "label-primary" style = {{fontWeight: 900, color: '#fff'}}>
                        <th title = {Assets.Sport} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faFootballBall}/></th>
                        <th title = {Assets.Category} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faVolleyballBall}/></th>
                        <th title = {Assets.Show} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}><FontAwesomeIcon icon = {faEye}/></th>
                        <th title = {Assets.Favourites} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/></th>
                        <th title = {Assets.FavouritesTournament} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/>&nbsp;&nbsp;<FontAwesomeIcon icon = {faDumbbell}/></th>
                        <th title = {Assets.Order} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faListOl}/></th>
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Name}</th>
                        <th>{Assets.DefaultName}</th>
                        <th>{Assets.CustomName}</th>
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Image}</th>
                        <th title = {Assets.Image} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faImage} size = "lg"/></th>
                        <th style = {{textAlign: 'center', borderRight: '1px dashed white'}} title = {Assets.UpdateImage}>
                            <span>{Assets.Update}</span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon = {faImage} size = "lg"/>
                        </th>
                        <th title = {Assets.DeleteImage} style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faTimes} size = "lg"/>
                        </th>
                        <th title = {Assets.RefreshMatchesForTheTournament} style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faBasketballBall}/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hiddenTournaments}
                    {TOURNAMENTS}
                </tbody>
            </table>
        </div>
    )
}

function getSportCategories(data: any): JSX.Element {
    if (data === undefined) {
        const custom:ICustom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
        data = Object.assign({}, custom['general']['database']['categories'])
        custom['general']['userEntry']['data']['categories'] = Object.assign({}, custom['general']['database']['categories'])
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    }

    const CATEGORIES: JSX.Element[] = []
    const hiddenCategories: JSX.Element[] = []

    for (const property in data) { if (data.hasOwnProperty(property)) {
    const categories: JSX.Element[] = []
    const Categories: JSX.Element[] = []
    let allHidden: boolean = true

    if (data[property]) { for (let i: number = 0; i < data[property].length; i++) { if (data[property][i].show) {
        allHidden = false
        break
    }
 }
    }

    if (data[property]) { for (let i: number = 0; i < data[property].length; i++) {
        const item = data[property][i]
        if (initialIsCapital(property)) {
            if (i === 0) {
                hiddenCategories.push(
                    <tr key = {property}>
                        <td className = "norotate cyan2" colSpan = {13}>
                            <span title = {Assets.Expand +' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinCategories(property)}>
                                <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faExpand}/>&nbsp;&nbsp;
                                {property + ': ' + getNumberOfAllCategoriesInSport(property) + ' ' + Assets.Records.toLowerCase()}
                            </span>
                            <button onClick = {() => DeleteSportCategory(property)} className = "btn btn-danger btn-sm mr0 ml" style = {{marginTop: '-4px'}}>
                                <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                            </button>
                        </td>
                    </tr>,
                )
            }
            continue
        }

        const to_push: JSX.Element =
            <tr key = {property + '_' + item.id}>
                {Categories.length === 0 && item.show || !item.show && i === 0 && allHidden.toString() === 'true' ?
                <td className = "rotate cyan2" rowSpan = {data[property].length}>
                    <span title = {Assets.Compress + ' ' + capitalizeFirstLetter(property)} onClick = {() => ToggleSportWithinCategories(property)}>
                        <FontAwesomeIcon style = {{lineHeight: '30px'}} icon = {faCompress}/>&nbsp;&nbsp;
                        {capitalizeFirstLetter(property) + ': ' + getNumberOfAllCategoriesInSport(property) + ' ' + Assets.Records.toLowerCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <button onClick = {() => DeleteSportCategory(property)} className = "btn btn-danger btn-sm mr0 ml0">
                        <FontAwesomeIcon icon = {faTrashAlt} size = "lg"/>
                    </button>
                </td> : void 0}
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(1, 0, i, property)} on = {item.show}/></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(1, 3, i, property)} on = {item.favourites}/* enabled = {item.show === true}*//></td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(1, 4, i, property)} on = {item.showFavourites} enabled = {item.favourites === true}/></td>
                <td style = {item.show ? {color: '#333', textAlign: 'center'} : {color: '#999', textAlign: 'center'}}>
                    <input
                        min = "0"
                        type = "number"
                        readOnly = {!item.show}
                        className = "text-input ti2"
                        value = {item.order ? item.order.toString() : ''}
                        onChange = {(e) => OrderChanged(1, 0, i, e, property)}
                    />
                </td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(1, 2, i, property)} on = {item.showName} enabled = {item.show === true}/></td>
                <td style = {(!item.newName || item.newName.length === 0) && item.show ? {color: '#333', fontWeight: 600, lineHeight: '30px'} : {color: '#999', fontWeight: 600, lineHeight: '30px'}}>
                {item.english}</td>
                <td style = {item.show ? {color: '#333'} : {color: '#999'}}>
                    <input readOnly = {!item.show} className = "text-input" value ={item.newName} onChange = {(e) => TextInputChanged(1, i, e, property)}/>
                </td>
                <td style = {{textAlign: 'center'}}><Switch onClick = {() => SwitchChanged(1, 1, i, property)} on = {item.showImage} enabled = {item.show === true}/></td>
                <td style = {{textAlign: 'center', position: 'relative' }} className = "hoverme">
                    {item.image && item.image.length ?
                    <img
                        className = {!item.showImage ? 'grayscale' : ''}
                        height = {40}
                        src = {item.image}
                    /> : void 0}
                    {item.image && item.dimensions ?
                    <div
                        className = "doh"
                        style = {{
                            height: item.dimensions.height < 200 ? '200px' : item.dimensions.height > 300 ? '300px' : item.dimensions.height + 'px',
                            width:  item.dimensions.height < 200 ? item.dimensions.width / item.dimensions.height * 200 + 'px' :
                                    item.dimensions.height > 300 ? (item.dimensions.width / item.dimensions.height * 300) + 'px' : item.dimensions.width + 'px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    >
                        <div>&nbsp;</div>
                        <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                        <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>{item.dimensions.width}px</span>
                        <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>{item.dimensions.height}px</span>
                        <img
                            style = {{
                                position:'absolute',
                                top: '0px',
                                bottom: '0px',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                width: item.dimensions.width + 'px',
                                height: item.dimensions.height + 'px',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                            src = {item.image}
                        />
                    </div> : void 0}
                </td>
                <td style = {{textAlign: 'center', height: '40px', paddingTop: '4px', paddingBottom: '4px'}}>
                    {!item.showImage ? void 0 :
                    <input
                        accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                        style = {{marginLeft: '0px', paddingLeft: '0px', width: '91px', marginRight:'0px', display: 'inline'}}
                        type = "file"
                        onChange = {(e) => SetImage(1, i, e, property)}
                        onClick = {(event) => event.target = null}
                    />}
                    {item.imageFilename ? <div style = {{fontSize: '12px', lineHeight: '17px'}}>{item.imageFilename}</div> : void 0}          
                </td>
                <td style = {{textAlign: 'center'}}>
                    <button onClick = {() => DeleteImage(1, i, property)} disabled = {!item.image || item.image.length === 0}  className = "btn btn-danger btn-xs mr0">
                        <FontAwesomeIcon icon = {faTrashAlt}/>
                    </button>
                </td>
                
                <td style = {{textAlign: 'center'}}>
                    <button onClick = {() => DbRoutes.getAllTournaments(item.id, item.english, property)} disabled = {!item.show} className = "btn btn-default teal btn-xs mr0">
                        <FontAwesomeIcon style = {{paddingTop: '1px'}} icon = {faSync}/>
                    </button>
                </td>
            </tr>

        if (item.show) { Categories.push(to_push) } else { categories.push(to_push) }
    }
    }
    for (const item of Categories) { CATEGORIES.push(item) }
    for (const item of categories) { CATEGORIES.push(item) }
    }
    }

    return (
        <div style = {{paddingLeft: '10px', paddingRight: '10px'}}>
            <table className = "table" style = {{marginTop: '10px', border: '1px solid #bbb'}}>
                <thead>
                    <tr className = "label-primary" style = {{fontWeight: 900, color: '#fff'}}>
                        <th title = {Assets.Sport} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faFootballBall}/></th>
                        <th title = {Assets.Show} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}><FontAwesomeIcon icon = {faEye}/></th>
                        <th title = {Assets.Favourites} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/></th>
                        <th title = {Assets.FavouritesCategory} style = {{textAlign: 'center'}}><FontAwesomeIcon icon = {faHeart}/>&nbsp;&nbsp;<FontAwesomeIcon icon = {faVolleyballBall}/></th>
                        <th style = {{textAlign: 'center'}} title = {Assets.Order}><FontAwesomeIcon icon = {faListOl}/></th>
                        <th style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>{Assets.Name}</th>
                        <th>{Assets.DefaultName}</th>
                        <th>{Assets.CustomName}</th>
                        <th style = {{textAlign: 'center'}}>{Assets.Image}</th>
                        <th title = {Assets.Image} style = {{textAlign: 'center', borderLeft: '1px dashed white'}}>
                            <FontAwesomeIcon icon = {faImage} size = "lg"/>
                        </th>
                        <th style = {{textAlign: 'center', borderRight: '1px dashed white'}} title = {Assets.UpdateImage}>
                            <span>{Assets.Update}</span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon = {faImage} size = "lg"/>
                        </th>
                        <th title = {Assets.DeleteImage} style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faTimes} size = "lg"/>
                        </th>
                        <th title = {Assets.RefreshTournamentsForTheCategory} style = {{textAlign: 'center'}}>
                            <FontAwesomeIcon icon = {faDumbbell}/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hiddenCategories}
                    {CATEGORIES}
                </tbody>
            </table>
        </div>
    )
}

function getNumberOfAllCategoriesInSport(property: string): number {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property]) {
        return Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property].length
    }
    return 0
}

function getNumberOfAllTournamentsInSport(property: string): number {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property]) {
        let num: number = 0
        for (const Property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property]) {
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property] !== undefined
            && !isNaN(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property].length)) {
            num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property].length
            }
        }
        return num
    } else { return 0 }
}

function getNumberOfAllMatchesInSport(property: string): number {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property]) {
        let num: number = 0
        for (const Property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property]) {
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property] !== undefined) {
        for (const PROPERTY in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property]) {
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY] !== undefined
            && !isNaN(Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY].length)) {
            num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY].length
            }
        }
            }
        }
        return num
    } else { return 0 }
}


function getNumberOfAllTournamentsInCategory(property: string, Property: string): number {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property]) {
        return Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property].length
    }
    return 0
}

function DeleteSportMatch(property: string) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    delete(custom['general']['userEntry']['data']['matches'][property])
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function DeleteSportCategory(property: string) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    delete(custom['general']['userEntry']['data']['categories'][property])
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function DeleteSportTournament(property: string) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    delete(custom['general']['userEntry']['data']['tournaments'][property])
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function DeleteSportCategoryTournament(property: string, Property: string) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    delete(custom['general']['userEntry']['data']['tournaments'][property][Property])
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function DeleteCategoryMatch(property: string, Property: string) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    delete(custom['general']['userEntry']['data']['matches'][property][Property])
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function getNumberOfAllSportMatches(): number {
    let num: number = 0

    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches']) {
    for (const property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches']) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'].hasOwnProperty(property)) {

    for (const Property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property]) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property].hasOwnProperty(Property)) {
    
    for (const PROPERTY in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property]) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property].hasOwnProperty(PROPERTY)) {
            num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY].length
        }
    }
        }
    }
        }
    }
    }

    return num
}

function getNumberOfAllMatchesInCategory(property: string, Property: string) : number {
    let num: number = 0
    for (const PROPERTY in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property]) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property].hasOwnProperty(PROPERTY)) {
            num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY].length
        }
    }

    // console.log (num)
    return num
}

function getNumberOfAllMatchesInTournament(property, Property, PROPERTY) : number {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY]) {
        return Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['matches'][property][Property][PROPERTY].length
    }
    return 0
}

function getNumberOfAllFavourites(): number {
    return getNumberOfAllSports(true) + getNumberOfAllSportCategories(true) + getNumberOfAllSportTournaments(true)
}

// function getFavourites(): any[] { return getFavouritesSport().concat(getFavouritesCategory(), getFavouritesTournament())}

function getFavouritesSport(): any[] {
    const favs: any[] = []
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports']) {
    for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports']) {
        if(item.favourites === true) { favs.push(item) }
    }
    }
    return favs
}

function getFavouritesCategory(): any[] {
    const favs: any[] = []
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories']) {
    for (const property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories']) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'].hasOwnProperty(property)) {
            for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property]) {
                if(item.favourites === true) {
                    const Item = Object.assign({}, item)
                    Item.property = property
                    favs.push(Item)
                }
            }
        }
    }
    }

    return favs
}

function getFavouritesTournament(): any[] {
    const favs: any[] = []
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments']) {
    for (const property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments']) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'].hasOwnProperty(property)) {
            for (const Property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property]) {
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property]) {
                for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property]) {
                    if(item.favourites === true) {
                        const Item = Object.assign({}, item)
                        Item.property = property
                        Item.Property = Property
                        favs.push(Item)
                    }
                }
            }
            }
        }
    }
    }
    return favs
}

function getNumberOfAllSportCategories(favourites?: boolean): number {
    let num: number = 0
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories']) {
    for (const property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories']) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'].hasOwnProperty(property)) {
            if (favourites) { for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property]) {
                if(item.favourites === true && item.showFavourites === true) { num++ }
            }
            } else { num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['categories'][property].length }
    }
    }
    }
    return num
}

function getNumberOfAllSportTournaments(favourites?: boolean): number {
    let num: number = 0
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments']) {
    for (const property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments']) {
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property] &&
            Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'].hasOwnProperty(property)) {
            for (const Property in Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property]) {
            if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property]) {
                if (favourites) { for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property]) {
                    if(item.favourites === true && item.showFavourites === true) { num++ }
                }
                } else { num += Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['tournaments'][property][Property].length }
            }
            }
    }
    }
    }
    return num
}

function getNumberOfAllSports(favourites?: boolean): number {
    if (favourites) {
        let num: number = 0
        // for (const item of Main.Instance.FrontendStoreState.custom['general']['database']['sports']) console.log (item)
        if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports']) {
        for (const item of Main.Instance.FrontendStoreState.custom['general']['userEntry']['data']['sports']) {
            if(item.favourites === true && item.showFavourites === true) { num++ }
        }
        }
        // console.log (num)
        return num
    } else { return Main.Instance.FrontendStoreState.custom['general']['database']['sports'].length }
}

function getSportGeneral(): JSX.Element {
    if (Main.Instance.FrontendStoreState.custom['general']['userEntry']['favourites'] === undefined) {
        const custom = Object.assign ({},  Main.Instance.FrontendStoreState.custom)
        custom.general.userEntry.favourites = {}
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    }

    return (
        <div className = "clearfix">

            {Main.Instance.FrontendStoreState.custom.general.admin.modeFavourites2 ?
            <div style = {{position: 'relative'}}>
                <div
                    className = "admin-users-panel clearfix"
                    style = {{padding: '10px', marginTop: '10px', position: 'relative'}}
                >
                    <button
                        onClick = {() => ModeGeneralFavourites()}
                        style = {{position: 'absolute', marginRight: '0px', right: '0px', top: '-9px', background: 'transparent', border: '0px', padding: '0px'}}
                        className = "btn btn-default btn-sm"
                    >
                        <i className = {Assets_.faCancelButtonClass}/>
                    </button>
                    <div className = "clearfix">
                        <div
                            style = {{fontSize:'16px', paddingTop:'7px', height:'22px', fontWeight: 700}}
                            className = "label label-info dibl ml0 mr fl"
                        >
                            <FontAwesomeIcon icon = {faHeart}/>&nbsp;&nbsp;{Assets.Favourites}
                        </div>
                    </div>

                    <div
                        className = "admin-users-panel clearfix"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >
                        <span style = {{fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}} className = "label label-default label-sm fl mr mt5">
                            <FontAwesomeIcon icon = {faCogs}/>&nbsp;&nbsp;{Assets.General}
                        </span>
                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingTop ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingTop.toString()} 
                            description = "Padding top (px)"
                            outerDiv = "input-group fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 0, text)} 
                            iconText = "PD top"
                            iconTextS = "14px"
                            noIconTextFix = {true}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingBottom ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingBottom.toString()} 
                            description = "Padding bottom (px)"
                            outerDiv = "input-group fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 1, text)} 
                            iconText = "PD bottom"
                            iconTextS = "14px"
                            noIconTextFix = {true}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingLeft ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingLeft.toString()} 
                            description = "Padding left (px)"
                            outerDiv = "input-group fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 2, text)} 
                            iconText = "PD left"
                            iconTextS = "14px"
                            noIconTextFix = {true}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingRight ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.paddingRight.toString()} 
                            description = "Padding right (px)"
                            outerDiv = "input-group fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 3, text)} 
                            iconText = "PD right"
                            iconTextS = "14px"
                            noIconTextFix = {true}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.margin ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.margin.toString()} 
                            description = {Assets.Margin + ' (px)'}
                            outerDiv = "input-group fl"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 4, text)} 
                            iconText = {Assets.Margin}
                            iconTextS = "14px"
                            noIconTextFix = {true}
                        />
                    </div>
                    <div
                        className = "admin-users-panel clearfix"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >
                        <span style = {{fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}} className = "label label-default label-sm fl mr mt5">
                            <FontAwesomeIcon icon = {faImage}/>&nbsp;&nbsp;{Assets.Background}
                        </span>

                        <FormInput 
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOpacity ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOpacity.toString()} 
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site"
                            onChange = {(text) => ParameterChange(0, 5, text)} 
                            placeholder = {Assets.Opacity}
                            iconText = {Assets.Opacity}
                            iconTextSize = "14px"
                        />
                        <div
                            style = {{fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}}
                            className = "label label-default label-sm fl mr mt5"
                            title = {Assets.t4}
                        >
                            <FontAwesomeIcon icon = {faImage}/>
                        </div>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 0)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.background}
                        />
                        {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.background === true ?
                            <input
                                className = "fl dbl mr mt"
                                accept = "image/png, image/jpg, image/jpeg, image/svg+xml, image/gif"
                                style = {{marginLeft: '0px', paddingLeft: '0px', width: '91px', marginRight:'0px'}}
                                type = "file"
                                onChange = {(e) => ParameterChange(0, 6, '', e)}
                                onClick = {(event) => event.target = null}
                            />
                        : 
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation.toString()} 
                            data = {[
                                {id: '1', name: 'left -> right'},
                                {id: '2', name: 'right -> left'},
                                {id: '3', name: 'top -> bottom'},
                                {id: '4', name: 'bottom -> top'},
                                {id: '5', name: '-> top-left'},
                                {id: '6', name: '-> bottom-left'},
                                {id: '7', name: '-> top-right'},
                                {id: '8', name: '-> bottom-right'},
                            ]}
                            boldyspan = {true}
                            ClassName = "form-control"
                            o1 = {Assets.NoGradient}
                            o2 = {Assets.NoGradient}
                            title = {Assets.SetLinearGradient}
                            onChange = {(text) => ParameterChange(0, 8, text)}
                            // iconText = "Orientation"
                            icon = {Assets_.faArrowsClass}
                        />                    
                        }

                        {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.background === true ?
                        <div className = "fl dbl mr hoverme">
                            <img
                                height = {30}
                                src = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundImage}
                            />

                            {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundImage && 
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions ?
                            <div
                                className = "doh"
                                style = {{
                                    height: Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height < 200 ? 
                                            '200px' : Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height > 300 ? 
                                            '300px' : Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height + 'px',
                                    width:  Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height < 200 ?
                                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.width /
                                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height * 200 + 'px' :
                                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height > 300 ?
                                            (Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.width / 
                                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height * 300) + 'px' :
                                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.width + 'px',
                                    background: `url(${Assets_.checkered})`,
                                }}
                            >
                                <div>&nbsp;</div>
                                <span title = {Assets.Width + ', ' + Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '0px', left: '5px', fontSize: '18px'}}>W, H</span>
                                <span title = {Assets.Width} style = {{position: 'absolute', fontWeight: 900, top: '20px', left: '5px'}}>
                                    {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.width}px
                                </span>
                                <span title = {Assets.Height} style = {{position: 'absolute', fontWeight: 900, top: '36px', left: '5px'}}>
                                    {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height}px
                                </span>
                                <img
                                    style = {{
                                        position:'absolute',
                                        top: '0px',
                                        bottom: '0px',
                                        left: '0px',
                                        right: '0px',
                                        margin: 'auto',
                                        width: Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.width + 'px',
                                        height: Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundDimensions.height + 'px',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }} 
                                    src = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundImage}
                                />
                            </div>
                            : void 0}
                        </div> :
                        <div className = "picker2">
                            <Picker.CompactPicker
                                width = "240px"
                                colors = {[
                                    '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', 
                                    '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', 
                                    '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E',
                                    '#999999', '#808080', '#666666', '#4D4D4D', '#333333', '#000000', '#005487', '#33769f', '#f38b00', '#f5a233',
                                ]}
                                color = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor} 
                                onChange = {(color, event) => Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ? 
                                            ParameterChange(0, 12, color.hex) : ParameterChange(0, 14, color.hex)}
                            />
                        </div>}
                        {/*
                        <div className = "picker3">
                            <Picker.CompactPicker
                                width = "240px"
                                colors = {[
                                    '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', 
                                    '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', 
                                    '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E',
                                    '#999999', '#808080', '#666666', '#4D4D4D', '#333333', '#000000', '#005487', '#33769f', '#f38b00', '#f5a233'
                                ]}
                                color = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor} 
                                onChange = {(color, event) => ParameterChange(0, 30, color.hex)}
                            />
                        </div>
                        */}
                        {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.background === true ?
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundSize ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundSize.toString()} 
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site"
                            onChange = {(text) => ParameterChange(0, 7, text)}
                            placeholder = "Size"
                            iconText = "Size"
                            iconTextSize = "14px"
                        /> : 
                        <FormInput
                            value = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ?
                                    !Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor1 ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor1.toString() :
                                    !Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor.toString()} 
                            ClassName = "form-control register-input"
                            bg = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ?
                                !Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor1 ? '' :
                                Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor1.toString() :
                                !Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor ? '' :
                                Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor.toString()} 
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ?
                                        ParameterChange(0, 10, text) : ParameterChange(0, 13, text)}
                            placeholder = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ? 'Color 1' : 'Color'}
                            icon = {Assets_.faTintClass}
                        />}
                        {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.background === true ?
                        <FormSelect
                            level = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundRepeat ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundRepeat.toString()} 
                            data = {[
                                {id: '1', name: 'repeat'}, {id: '2', name: 'repeat-x'}, {id: '3', name: 'repeat-y'}, {id: '4', name: 'no-repeat'},
                            ]}
                            boldyspan = {true}
                            ClassName = "form-control"
                            o1 = "Not specified"
                            o2 = "Not specified"
                            title = "Set Background Repeat"
                            onChange = {(text) => ParameterChange(0, 9, text)}
                            iconText = "Repeat"
                        /> :
                        Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundOrientation ?
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor2 ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor2.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor2 ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.backgroundColor2.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 11, text)}
                            placeholder = "Color 2"
                            icon = {Assets_.faTintClass}
                        /> : void 0}
                    </div>
                    <div
                        className = "admin-users-panel clearfix level165shrink"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >
                        <span style = {{width: '100px', fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}} className = "label label-default label-sm fl mr mt5">
                            <FontAwesomeIcon icon = {faFont}/>&nbsp;&nbsp;{'Big title'}
                        </span>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 1)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.Title}
                        />
                        <span className = "fl dbl Switch Switch1">{Assets.Enable.toLowerCase()}</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily.toString()} 
                            data = {[{id: '1', name: 'Overlock'}, {id: '2', name: 'Signika'}, {id: '3', name: 'Montserrat'}]}
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = {Assets.SetFontFamily}
                            onChange = {(text) => ParameterChange(0, 15, text)}
                            // iconText = ""
                            icon = {Assets_.faFontClass}
                        />
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleWeight ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleWeight.toString()} 
                            data = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily === undefined ||
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily === 0 ?
                                    [{id: '100', name: 'Thin 100'}, {id: '200', name: 'Extra-Light 200'}, {id: '300', name: 'Light 300'},
                                     {id: '400', name: 'Regular 400'}, {id: '500', name: 'Medium 500'}, {id: '600', name: 'Semi-Bold 600'},
                                     {id: '700', name: 'Bold 700'}, {id: '800', name: 'Extra-Bold 800'}, {id: '900', name: 'Black 900'}] :
                                      Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily === 1 ?
                                     [{id: '400', name: 'Regular 400'}, {id: '700', name: 'Bold 700'}, {id: '900', name: 'Black 900'}] : 
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily === 2 ?
                                     [{id: '300', name: 'Light 300'}, {id: '400', name: 'Regular 400'}, {id: '600', name: 'Semi-Bold 600'}, {id: '700', name: 'Bold 700'}] :  
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleFamily === 3 ?
                                     [{id: '600', name: 'Semi-Bold 600'}, {id: '900', name: 'Black 900'}] :
                                     [] 
                            }
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = "Set Font Weight"
                            onChange = {(text) => ParameterChange(0, 16, text)}
                            // iconText = ""
                            icon = {Assets_.faBoldClass}
                        />
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleColor.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleColor.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 17, text)}
                            placeholder = "Color"
                            description = "Set Font color"
                            icon = {Assets_.faTintClass}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleSize ? '16' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleSize.toString()} 
                            description = "Font size (px)"
                            outerDiv = "input-group fl mw"
                            ClassName = "h30 w50px pr0 fl"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 18, text)}
                            icon = {Assets_.faTextHeight}
                        />
                        <button
                            title = "Align left"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 19, '1')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleAlignment === IAlignment.left ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignLeft}/>
                        </button>
                        <button
                            title = "Align centered"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 19, '0')}
                            className = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleAlignment ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignCenter}/>
                        </button>
                        <button
                            title = "Align right"
                            style = {{borderRadius:'0px', position: 'relative'}}
                            onClick = {() => ParameterChange(0, 19, '2')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleAlignment === IAlignment.right ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignRight}/>
                        </button>
                        <Switch
                            className = "fl dbl mr mt7 ml"
                            onClick = {() => SwitchChange(0, 2)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleItalic}
                        />
                        <span className = "fl dbl Switch italic">{Assets.Italic.toLowerCase()}</span>
                        <FormSelect
                            Background = "#bbb"
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.Titleicon ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.Titleicon.toString()} 
                            data = {[{id: '1', name: 'heart'}, {id: '2', name: 'heart-o'}]}
                            ClassName = "form-control"
                            wide = {true}
                            o1 = "No icon"
                            o2 = "No icon"
                            title = "Set Big Title Icon"
                            onChange = {(text) => ParameterChange(0, 35, text)}
                            icon = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleIcon === undefined ? Assets_.faQuestionFwClass :
                                    Assets_.faFixedWidthFa + Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.TitleIcon}
                        />
                        <span className = "mr fl dbl">&nbsp;</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconSport ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconSport.toString()} 
                            data = {[{id: '1', name: 'futbol-o'}]}
                            ClassName = "form-control"
                            wide = {true}
                            o1 = "No icon"
                            o2 = "No icon"
                            title = "Set Small Title Icon (sport)"
                            onChange = {(text) => ParameterChange(0, 36, text)}
                            icon = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconSport === undefined ? Assets_.faQuestionFwClass :
                                    Assets_.faFixedWidthFa + Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconSport}
                        />
                    </div>
                    <div
                        className = "admin-users-panel clearfix level165shrink"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >
                        <span style = {{width: '100px', fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}} className = "label label-default label-sm fl mr mt5">
                            {'Small title'}
                        </span>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 3)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.title}
                        />
                        <span className = "fl dbl Switch Switch1">{Assets.Enable.toLowerCase()}</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily.toString()} 
                            data = {[{id: '1', name: 'Overlock'}, {id: '2', name: 'Signika'}, {id: '3', name: 'Montserrat'}]}
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = {Assets.SetFontFamily}
                            onChange = {(text) => ParameterChange(0, 20, text)}
                            // iconText = ""
                            icon = {Assets_.faFontClass}
                        />
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleWeight ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleWeight.toString()} 
                            data = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily === undefined ||
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily === 0 ?
                                    [{id: '100', name: 'Thin 100'}, {id: '200', name: 'Extra-Light 200'}, {id: '300', name: 'Light 300'},
                                     {id: '400', name: 'Regular 400'}, {id: '500', name: 'Medium 500'}, {id: '600', name: 'Semi-Bold 600'},
                                     {id: '700', name: 'Bold 700'}, {id: '800', name: 'Extra-Bold 800'}, {id: '900', name: 'Black 900'}] :
                                      Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily === 1 ?
                                     [{id: '400', name: 'Regular 400'}, {id: '700', name: 'Bold 700'}, {id: '900', name: 'Black 900'}] : 
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily === 2 ?
                                     [{id: '300', name: 'Light 300'}, {id: '400', name: 'Regular 400'}, {id: '600', name: 'Semi-Bold 600'}, {id: '700', name: 'Bold 700'}] :  
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleFamily === 3 ?
                                     [{id: '600', name: 'Semi-Bold 600'}, {id: '900', name: 'Black 900'}] :
                                     [] 
                            }
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = "Set Font Weight"
                            onChange = {(text) => ParameterChange(0, 21, text)}
                            // iconText = ""
                            icon = {Assets_.faBoldClass}
                        />
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleColor.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleColor.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 22, text)}
                            placeholder = "Color"
                            description = "Set Font color"
                            icon = {Assets_.faTintClass}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleSize ? '14' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleSize.toString()} 
                            description = "Font size (px)"
                            outerDiv = "input-group fl mw"
                            ClassName = "h30 w50px pr0 fl"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 23, text)}
                            icon = {Assets_.faTextHeight}
                        />
                        <button
                            title = "Align left"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 24, '1')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleAlignment === IAlignment.left ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignLeft}/>
                        </button>
                        <button
                            title = "Align centered"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 24, '0')}
                            className = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleAlignment ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignCenter}/>
                        </button>
                        <button
                            title = "Align right"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 24, '2')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleAlignment === IAlignment.right ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignRight}/>
                        </button>

                        <Switch
                            className = "fl dbl mr mt7 ml"
                            onClick = {() => SwitchChange(0, 3)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEnEnabletry.favourites.titleItalic}
                        />
                        <span className = "fl dbl Switch italic">{Assets.Italic.toLowerCase()}</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconCategory ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconCategory.toString()} 
                            data = {[{id: '1', name: 'globe'}, {id: '2', name: 'flag'}, {id: '3', name: 'flag-o'}]}
                            ClassName = "form-control"
                            wide = {true}
                            o1 = "No icon"
                            o2 = "No icon"
                            title = "Set Small Title Icon (category)"
                            onChange = {(text) => ParameterChange(0, 37, text)}
                            icon = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconCategory === undefined ? Assets_.faQuestionFwClass :
                                    Assets_.faFixedWidthFa + Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconCategory}
                        />
                        <span className = "mr fl dbl">&nbsp;</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconTournament ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleiconTournament.toString()} 
                            data = {[{id: '1', name: 'trophy'}]}
                            ClassName = "form-control"
                            wide = {true}
                            o1 = "No icon"
                            o2 = "No icon"
                            title = "Set Small Title Icon (tournament)"
                            onChange = {(text) => ParameterChange(0, 38, text)}
                            icon = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconTournament === undefined ? Assets_.faQuestionFwClass :
                                    Assets_.faFixedWidthFa + Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.titleIconTournament}
                        />
                    </div>
                    <div
                        className = "admin-users-panel clearfix level165shrink"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >
                        <span style = {{width: '100px', fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700}} className = "label label-default label-sm fl mr mt5">
                            <FontAwesomeIcon icon = {faFont}/><FontAwesomeIcon icon = {faFont}/><FontAwesomeIcon icon = {faFont}/>&nbsp;&nbsp;{'Text'}
                        </span>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 5)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.text}
                        />
                        <span className = "fl dbl Switch Switch1">{Assets.Enable.toLowerCase()}</span>
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily.toString()} 
                            data = {[{id: '1', name: 'Overlock'}, {id: '2', name: 'Signika'}, {id: '3', name: 'Montserrat'}]}
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = {Assets.SetFontFamily}
                            onChange = {(text) => ParameterChange(0, 25, text)}
                            // iconText = ""
                            icon = {Assets_.faFontClass}
                        />
                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textWeight ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textWeight.toString()} 
                            data = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily === undefined ||
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily === 0 ?
                                    [{id: '100', name: 'Thin 100'}, {id: '200', name: 'Extra-Light 200'}, {id: '300', name: 'Light 300'},
                                     {id: '400', name: 'Regular 400'}, {id: '500', name: 'Medium 500'}, {id: '600', name: 'Semi-Bold 600'},
                                     {id: '700', name: 'Bold 700'}, {id: '800', name: 'Extra-Bold 800'}, {id: '900', name: 'Black 900'}] :
                                      Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily === 1 ?
                                     [{id: '400', name: 'Regular 400'}, {id: '700', name: 'Bold 700'}, {id: '900', name: 'Black 900'}] : 
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily === 2 ?
                                     [{id: '300', name: 'Light 300'}, {id: '400', name: 'Regular 400'}, {id: '600', name: 'Semi-Bold 600'}, {id: '700', name: 'Bold 700'}] :  
                                     Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textFamily === 3 ?
                                     [{id: '600', name: 'Semi-Bold 600'}, {id: '900', name: 'Black 900'}] :
                                     [] 
                            }
                            ClassName = "form-control"
                            o1 = {Assets.Default}
                            o2 = {Assets.Default}
                            title = "Set Font Weight"
                            onChange = {(text) => ParameterChange(0, 26, text)}
                            // iconText = ""
                            icon = {Assets_.faBoldClass}
                        />
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColor.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColor.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 27, text)}
                            placeholder = "Color"
                            description = "Set Font color"
                            icon = {Assets_.faTintClass}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textSize ? '12' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textSize.toString()} 
                            description = "Font size (px)"
                            outerDiv = "input-group  fl mw"
                            ClassName = "h30 w50px pr0 fl"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 28, text)}
                            icon = {Assets_.faTextHeight}
                        />

                        <button
                            title = "Align left"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 29, '1')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textAlignment === IAlignment.left ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignLeft}/>
                        </button>
                        <button
                            title = "Align centered"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 29, '0')}
                            className = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textAlignment ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignCenter}/>
                        </button>
                        <button
                            title = "Align right"
                            style = {{borderRadius:'0px'}}
                            onClick = {() => ParameterChange(0, 29, '2')}
                            className = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textAlignment === IAlignment.right ?
                                        'btn btn-sm btn-warning mr0 fl dbl mt5' : 'btn btn-sm btn-default mr0 fl dbl mt5'}
                        >
                            <i className = {Assets_.faAlignRight}/>
                        </button>
                        <Switch
                            className = "fl dbl mr mt7 ml"
                            onClick = {() => SwitchChange(0, 6)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textItalic}
                        />
                        <span className = "fl dbl Switch italic">{Assets.Italic.toLowerCase()}</span>
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorChosen ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorChosen.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorChosen ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorChosen.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 30, text)}
                            placeholder = "Color"
                            description = "Set Font color chosen"
                            icon = {Assets_.faTintClass}
                        />
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorHover ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorHover.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorHover ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.textColorHover.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 39, text)}
                            placeholder = "Color"
                            description = "Set Font color hover"
                            icon = {Assets_.faTintClass}
                        />
                        {/*<button className = "btn btn-danger btn-sm" onClick = {() => DbRoutes.GA()}>GA</button>*/}
                    </div>
                    <div
                        className = "admin-users-panel clearfix level165shrink"
                        style = {{padding: '10px 0px 0px', marginBottom: '0px', position: 'relative'}}
                    >

                        <span style = {{fontSize: '16px', paddingTop: '7px', height: '30px', fontWeight: 700, width: '100px'}} className = "label label-default label-sm fl mr mt5">
                            <i className = {Assets_.faBorderWidthClass}/>&nbsp;&nbsp;{'Border'}
                        </span>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 11)}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.border}
                        />
                        <span className = "fl dbl Switch Switch1">{Assets.Enable.toLowerCase()}</span>

                        <FormSelect
                            level165 = {true}
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderStyle ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderStyle.toString()} 
                            data = {[{id: '1', name: 'Solid'}, {id: '2', name: 'Dashed'}, {id: '3', name: 'Dotted'}, {id: '4', name: 'Double'}]}
                            ClassName = "form-control"
                            o1 = "None"
                            o2 = "None"
                            title = "Set Border Style"
                            onChange = {(text) => ParameterChange(0, 31, text)}
                            icon = {Assets_.faBorderWidthClass}
                        />

                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderWidth ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderWidth.toString()} 
                            description = "Border width (px)"
                            outerDiv = "input-group mw fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 32, text)}
                            icon = {Assets_.faEditButtonClass}
                        />
                        <FormInputNumber
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderRadius ? '' :
                            Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderRadius.toString()} 
                            description = "Border radius (px)"
                            outerDiv = "input-group mw fl mr"
                            ClassName = "h30 w60 pr0"
                            type = "number"
                            min = "0" 
                            onChange = {(text) => ParameterChange(0, 33, text)}
                            icon = {Assets_.faCircleOClass}
                        />
                        <FormInput
                            value = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderColor.toString()}
                            bg = {!Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderColor ? '' :
                                    Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderColor.toString()}  
                            ClassName = "form-control register-input"
                            type = "text"
                            divClass = "input-test-site-narrow"
                            onChange = {(text) => ParameterChange(0, 34, text)}
                            placeholder = "Color"
                            description = "Set Border color"
                            icon = {Assets_.faTintClass}
                        />
                        <i style = {{fontSize: '20px'}} title = "Top border" className = {Assets_.faCaretUp + ' dbl fl mr mt bold ml'}/>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 7)}
                            enabled = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.border === true}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderTop}
                        />
                        <span className = "fl dbl Switch Switch2">{Assets.Top.toLowerCase()}</span>
                        <i style = {{fontSize: '20px'}} title = "Right border" className = {Assets_.faCaretRight + ' dbl fl mr mt bold'}/>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 8)}
                            enabled = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.border === true}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderRight}
                        />
                        <span className = "fl dbl Switch Switch3">{Assets.Right.toLowerCase()}</span>
                        <i style = {{fontSize: '20px'}} title = "Bottom border" className = {Assets_.faCaretDown + ' dbl fl mr mt bold'}/>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 9)}
                            enabled = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.border === true}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderBottom}
                        />
                        <span className = "fl dbl Switch Switch4">{Assets.Bottom.toLowerCase()}</span>
                        <i style = {{fontSize: '20px'}} title = "Left border" className = {Assets_.faCaretLeft + ' dbl fl mr mt bold'}/>
                        <Switch
                            className = "fl dbl mr mt7"
                            onClick = {() => SwitchChange(0, 10)}
                            enabled = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.border === true}
                            on = {Main.Instance.FrontendStoreState.custom.general.userEntry.favourites.borderLeft}
                        />
                        <span className = "fl dbl Switch Switch5">{Assets.Left.toLowerCase()}</span>

                    </div>
                </div>
            </div> : void 0}
        </div> 
    )
}

function SwitchChange(Which: number, which: number) {
    const custom = Object.assign ({},  Main.Instance.FrontendStoreState.custom)
    if (Which === 0) { // favourites
        if (which === 0) { custom.general.userEntry.favourites.background = !custom.general.userEntry.favourites.background } else if (which === 1) { custom.general.userEntry.favourites.Title = !custom.general.userEntry.favourites.Title } else if (which === 2) { custom.general.userEntry.favourites.TitleItalic = !custom.general.userEntry.favourites.TitleItalic } else if (which === 3) { custom.general.userEntry.favourites.title = !custom.general.userEntry.favourites.title } else if (which === 4) { custom.general.userEntry.favourites.titleItalic = !custom.general.userEntry.favourites.titleItalic } else if (which === 5) { custom.general.userEntry.favourites.text = !custom.general.userEntry.favourites.text } else if (which === 6) { custom.general.userEntry.favourites.textItalic = !custom.general.userEntry.favourites.textItalic } else if (which === 7) { custom.general.userEntry.favourites.borderTop = !custom.general.userEntry.favourites.borderTop } else if (which === 8) { custom.general.userEntry.favourites.borderRight = !custom.general.userEntry.favourites.borderRight } else if (which === 9) { custom.general.userEntry.favourites.borderBottom = !custom.general.userEntry.favourites.borderBottom } else if (which === 10) { custom.general.userEntry.favourites.borderLeft = !custom.general.userEntry.favourites.borderLeft } else if (which === 11) {
            custom.general.userEntry.favourites.border = !custom.general.userEntry.favourites.border
            if (custom.general.userEntry.favourites.border === true) {
                custom.general.userEntry.favourites.borderTop = true
                custom.general.userEntry.favourites.borderBottom = true
                custom.general.userEntry.favourites.borderLeft = true
                custom.general.userEntry.favourites.borderRight = true
            } else {
                custom.general.userEntry.favourites.borderTop = false
                custom.general.userEntry.favourites.borderBottom = false
                custom.general.userEntry.favourites.borderLeft = false
                custom.general.userEntry.favourites.borderRight = false
            }
        }
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function ParameterChange(Which: number, which: number, text: string, e?: any) {
    const custom = Object.assign ({},  Main.Instance.FrontendStoreState.custom)
    if (Which === 0) { // favourites
        if (which === 0) { custom.general.userEntry.favourites.paddingTop = +text } else if (which === 1) { custom.general.userEntry.favourites.paddingBottom = +text } else if (which === 2) { custom.general.userEntry.favourites.paddingLeft = +text } else if (which === 3) { custom.general.userEntry.favourites.paddingRight = +text } else if (which === 4) { custom.general.userEntry.favourites.margin = +text } else if (which === 5 && AllowDecimalCharactersOnly(text)) { custom.general.userEntry.favourites.backgroundOpacity = text } else if (which === 6 && e) {
            const cloneFile: File = e.target.files[0]
            const reader = new FileReader()

            reader.onload = (ev) => {
                // custom['general']['userEntry']['favourites']['background'] 
                custom.general.userEntry.favourites.backgroundImage = reader.result as string

                const img = new Image()
                img.onload = () => {
                    custom.general.userEntry.favourites.backgroundDimensions = {height: img.height, width: img.width}
                    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
                }
                img.src = reader.result as string
            }

            reader.readAsDataURL(cloneFile);            
        } else if (which === 7) { custom.general.userEntry.favourites.backgroundSize = text } else if (which === 8) { custom.general.userEntry.favourites.backgroundOrientation = +text } else if (which === 9) { custom.general.userEntry.favourites.backgroundRepeat = +text } else if (which === 10) { custom.general.userEntry.favourites.backgroundColor1 = text } else if (which === 11) { custom.general.userEntry.favourites.backgroundColor2 = text } else if (which === 12 || which === 14) {
                if (which === 14 && (custom.general.userEntry.favourites.backgroundColor === undefined || custom.general.userEntry.favourites.backgroundColor.length === 0)) {
                    custom.general.userEntry.favourites.backgroundColor = text
                } else if (which === 12 && (custom.general.userEntry.favourites.backgroundColor1 === undefined || custom.general.userEntry.favourites.backgroundColor1.length === 0)) {
                    custom.general.userEntry.favourites.backgroundColor1 = text
 } else if (which === 12 && (custom.general.userEntry.favourites.backgroundColor2 === undefined || custom.general.userEntry.favourites.backgroundColor2.length === 0)) {
                    custom.general.userEntry.favourites.backgroundColor2 = text
 } else if (custom.general.userEntry.favourites.TitleColor === undefined || custom.general.userEntry.favourites.TitleColor.length === 0) {
                    custom.general.userEntry.favourites.TitleColor = text
 } else if (custom.general.userEntry.favourites.titleColor === undefined || custom.general.userEntry.favourites.titleColor.length === 0) {
                    custom.general.userEntry.favourites.titleColor = text
 } else if (custom.general.userEntry.favourites.textColor === undefined || custom.general.userEntry.favourites.textColor.length === 0) {
                    custom.general.userEntry.favourites.textColor = text
 } else if (custom.general.userEntry.favourites.textColorChosen === undefined || custom.general.userEntry.favourites.textColorChosen.length === 0) {
                    custom.general.userEntry.favourites.textColorChosen = text
 } else if (custom.general.userEntry.favourites.textColorHover === undefined || custom.general.userEntry.favourites.textColorHover.length === 0) {
                    custom.general.userEntry.favourites.textColorHover = text
 } else if (custom.general.userEntry.favourites.borderColor === undefined || custom.general.userEntry.favourites.borderColor.length === 0) {
                    custom.general.userEntry.favourites.borderColor = text
 }
        
        } else if (which === 13) { custom.general.userEntry.favourites.backgroundColor = text } else if (which === 15) { custom.general.userEntry.favourites.TitleFamily = +text } else if (which === 16) { custom.general.userEntry.favourites.TitleWeight = +text } else if (which === 17) { custom.general.userEntry.favourites.TitleColor = text } else if (which === 18) { custom.general.userEntry.favourites.TitleSize = +text } else if (which === 19) { custom.general.userEntry.favourites.TitleAlignment = +text } else if (which === 20) { custom.general.userEntry.favourites.titleFamily = +text } else if (which === 21) { custom.general.userEntry.favourites.titleWeight = +text } else if (which === 22) { custom.general.userEntry.favourites.titleColor = text } else if (which === 23) { custom.general.userEntry.favourites.titleSize = +text } else if (which === 24) { custom.general.userEntry.favourites.titleAlignment = +text } else if (which === 25) { custom.general.userEntry.favourites.textFamily = +text } else if (which === 26) { custom.general.userEntry.favourites.textWeight = +text } else if (which === 27) { custom.general.userEntry.favourites.textColor = text } else if (which === 28) { custom.general.userEntry.favourites.textSize = +text } else if (which === 29) { custom.general.userEntry.favourites.textAlignment = +text } else if (which === 31) { custom.general.userEntry.favourites.borderStyle = +text } else if (which === 32) { custom.general.userEntry.favourites.borderWidth = +text } else if (which === 33) { custom.general.userEntry.favourites.borderRadius = +text } else if (which === 34) { custom.general.userEntry.favourites.borderColor = text } else if (which === 35) {
            custom.general.userEntry.favourites.Titleicon = +text
            if (+text === 0) { custom.general.userEntry.favourites.TitleIcon = 'question' } else if (+text === 1) { custom.general.userEntry.favourites.TitleIcon = 'heart' } else if (+text === 2) { custom.general.userEntry.favourites.TitleIcon = 'heart-o' }
        } else if (which === 36) {
            custom.general.userEntry.favourites.titleiconSport = +text
            if (+text === 0) { custom.general.userEntry.favourites.titleIconSport = 'question' } else if (+text === 1) { custom.general.userEntry.favourites.titleIconSport = 'futbol-o' }
        } else if (which === 37) {
            custom.general.userEntry.favourites.titleiconCategory = +text
            if (+text === 0) { custom.general.userEntry.favourites.titleIconCategory = 'question' } else if (+text === 1) { custom.general.userEntry.favourites.titleIconCategory = 'globe' } else if (+text === 2) { custom.general.userEntry.favourites.titleIconCategory = 'flag' } else if (+text === 3) { custom.general.userEntry.favourites.titleIconCategory = 'flag-o' }
        } else if (which === 38) {
            custom.general.userEntry.favourites.titleiconTournament = +text
            if (+text === 0) { custom.general.userEntry.favourites.titleIconTournament = 'question' } else if (+text === 1) { custom.general.userEntry.favourites.titleIconTournament = 'trophy' }
        } else if (which === 30) { custom.general.userEntry.favourites.textColorChosen = text } else if (which === 39) { custom.general.userEntry.favourites.textColorHover = text }
    }
    if (Which === 0 && which === 6) {
    } else { Logic.Type.New (Main.Instance.FrontendStoreState, {custom}) }
}

function countItemsInCategories(category: number): number {
    let num: number = 0
    if (Main.Instance.DatabaseDataState.rewardShopStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.rewardShopStreamAll) { if (+item['category'] === category - 1) { num++ } }
    }
    return num
}

function Toggle(index: number, id: string, remove?: boolean) {
    // alert ('index: ' + index + ', id: ' + id + ', remove: ' + remove)

    // console.log ('index ' + index)
    // console.log ('id ' + id)
    // if empty, try to assign something that is not empty, if success => perform index change in the db, if not, do nothing
    // if not empty, try to assign the next one. if the next one is not found make it empty. both perform index change in the db
    const rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    // console.log (rewardShopStreamAll)
    if (remove && id !== undefined) {
        for (let i: number = 0; i < rewardShopStreamAll.length; i++) {
            if (+rewardShopStreamAll[i]['id'] === +id) {
                rewardShopStreamAll[i]['index'] = '0'
                Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll})
                // alert ('set to 0') // OK
                DbRoutes.changeIndexRewardShop(+id, 0)
                return
            }
        }
    }
 
    if (id === undefined) { // the reward slot is empty
        // alert ('empty')
        for (let i: number = 0; i < rewardShopStreamAll.length; i++) {
            if (+rewardShopStreamAll[i]['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && +rewardShopStreamAll[i]['index'] === 0
                && rewardShopStreamAll[i]['active'].toString() === 'true') {
                rewardShopStreamAll[i]['index'] = (index + 1).toString()
                Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll})
                // alert ('slot was empty, not anymore, index is ' + (index + 1)) // OK
                DbRoutes.changeIndexRewardShop(+rewardShopStreamAll[i]['id'], index + 1)
                return
            }
        }

    } else { // the reward slot is not empty
        // alert ('not empty')
        let hit: boolean = false
        let Hit: boolean = false
        let HIT: number = -1
        for (let i: number = 0; i < rewardShopStreamAll.length; i++) {
            if (+rewardShopStreamAll[i]['category'] !== Main.Instance.FrontendStoreState.rewardShopOpen - 1) { continue }

            if (+rewardShopStreamAll[i]['id'] === +id && rewardShopStreamAll[i]['active'] !== undefined && rewardShopStreamAll[i]['active'].toString() === 'true') {
                hit = true
                HIT = i
                // alert ('we found CURRENT: HIT: ' + i)
            } else if (hit === true && rewardShopStreamAll[i]['active'] !== undefined && rewardShopStreamAll[i]['active'].toString() === 'true' && 
                      parseInt(rewardShopStreamAll[i]['index']) === 0) {
                // alert ('stored index: ' + parseInt(rewardShopStreamAll[i]['index']))
                // alert ('new index: ' + (index + 1))
                Hit = true
                rewardShopStreamAll[i]['index'] = (index + 1).toString()
                rewardShopStreamAll[HIT]['index'] = '0'

                Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll})
                // alert ('slot was not empty, new index is ' + (index + 1))
                DbRoutes.changeIndexRewardShop(+rewardShopStreamAll[i]['id'], index + 1)
                DbRoutes.changeIndexRewardShop(+rewardShopStreamAll[HIT]['id'], 0)
                return

            }
        }
        if (hit === true && Hit === false) {
            // alert ('ID ' + id + ' INDEX 0')
            rewardShopStreamAll[HIT]['index'] = '0'
            Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStreamAll})
            // alert ('slot was not empty, now it is!')
            DbRoutes.changeIndexRewardShop(+id, 0)
        }
/*
        if (hit === false) {
            alert ('no hit')
        }
*/
    }
}

function getRewardShopItemsPositioned(stream: any[]): JSX.Element[] {
    const gsip:JSX.Element[] = []

    const slots: number = Main.Instance.FrontendStoreState.custom.slots
    // const count2: number = Main.Instance.FrontendStoreState.custom.Slots

    gsip.push(
        <div
            key = "-1"
            style = {{fontSize:'16px',padding:'6px 12px',height:'30px',borderRadius:'0px',position:'absolute', left:'0px', top:'0px', borderBottom:'1px solid #fff', borderRight:'1px solid #fff'}}
            className = "dbl label label-primary clearfix"
        >
            {Main.Instance.FrontendStoreState.Category[Main.Instance.FrontendStoreState.rewardShopOpen]['name'].toUpperCase()}
        </div>,
    )

    if (isPureScandibet()) { // scandibet
        for (let j: number = (Main.Instance.FrontendStoreState.page - 1) * slots; j < Main.Instance.FrontendStoreState.page * slots; j++) {
            let src: string
            let id: string
            for (const item of stream) {
                if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && j + 1 === +item['index']) {
                    src = item['image']
                    id = item['id']
                    break
                }
            }

            gsip.push (
                <span
                    key = {j.toString()}
                    style = {{
                        color: src ? 'yellow' : '#fff',
                        position: 'absolute',
                        left: j % slots === 0 || j % slots === slots - 1 ? '205px': j % 2 === 1 ? '117px': 'auto',
                        right: j % 2 === 0 ? '117px': 'auto',
                        top: j % slots === 0 ? '50px' : j % slots === slots - 1 ? '630px' : (Math.floor((j % slots - 1)/ 2) * 97 + 150) + 'px',
                        fontSize: '20px',
                        fontWeight: 700,
                    }}
                >
                    {j + 1}
                </span>,
            )
            gsip.push (
                <a
                    href = "javascript:void(0)"
                    onClick = {() => isUserAdmin() ? Toggle(j, id) : void 0}
                    key = {j.toString() + '_'}
                    style = {{
                        background: 'transparent',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: j % slots === 0 || j % slots === slots - 1 ? '236px' : j % 2 === 1 ? '145px': 'auto',
                        right: j % 2 === 0 ? '145px': 'auto',
                        top: j % slots === 0 ? '20px' : j % slots === slots - 1 ? '605px' : (Math.floor((j % slots - 1) / 2) * 97 + 120) + 'px',
                        width: '90px',
                        height: '90px',
                        border: src ? '1px solid yellow' : '1px solid #fff',
                    }}
                >
                    <img style = {{width: '100%', height: '100%'}} src = {src ? src : Assets_.zeroPng}/>
                </a>,
            )

            if (isUserAdmin()) { gsip.push (
                <button
                    onClick = {() => Toggle(j, id, true)}
                    key = {j.toString() + '__'}
                    title = {Assets.RemoveGameFromTheSlot}
                    style = {{
                        lineHeight: '10px',
                        background: src ? 'yellow' : '#fff',
                        width: '14px',
                        height: '14px',
                        zIndex:9999,
                        border: '0px',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: j % slots === 0 || j % slots === slots - 1 ? '312px' : j % 2 === 0 ? '401px' : '221px',
                        top: j % slots === 0 ? '20px' : j % slots === slots - 1 ? '605px' : (Math.floor((j % slots - 1) / 2) * 97 + 120) + 'px',
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>,
            )
            }
        }
/*
        let positions:{w: number, h: number}[] = [{w:456, h:56}, {w:10, h:603}, {w:461, h:600}]
        let Positions:{w: number, h: number}[] = [{w:430, h:82}, {w:110, h:637}, {w:429, h:630}]
        let positionS:{w: number, h: number}[] = [{w:532, h:56}, {w:86, h:603}, {w:537, h:600}]

        for (let j: number = count; j < count2; j++) {
            let src: string
            let id: string
            for (let item of stream) {
                if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && j + 1 === +item['index']) {
                    src = item['image']
                    id = item['id']
                    break
                }
            }

            gsip.push (
                <span
                    key = {j.toString()}
                    style = {{
                        color: src ? 'yellow' : '#fff',
                        position: 'absolute',
                        left: (Positions[j - count].w) + 'px',
                        top: (Positions[j - count].h) + 'px',
                        fontSize: '20px',
                        fontWeight: 700
                    }}
                >
                    {j + 1}
                </span>
            )

            gsip.push (
                <a
                    href = "javascript:void(0)"
                    onClick = {() => isUserAdmin() ? Toggle(j, id) : void 0}
                    key = {j.toString() + '_'}
                    style = {{
                        background: 'transparent',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: positions[j - count].w + 'px',
                        top: positions[j - count].h + 'px',
                        width: '90px',
                        height: '90px',
                        border: src ? '1px solid yellow' : '1px solid #fff'
                    }}
                >
                    <img style = {{width: '100%', height: '100%'}} src = {src ? src : Assets_.zeroPng}/>
                </a>                
            )

            if (isUserAdmin()) gsip.push (
                <button
                    title = {Assets.RemoveGameFromTheSlot}
                    onClick = {() => Toggle(j, id, true)}
                    key = {j.toString() + '__'}
                    style = {{
                        lineHeight: '10px',
                        background: src ? 'yellow' : '#fff' ,
                        width: '14px',
                        height: '14px',
                        zIndex:9999,
                        border: '0px',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: positionS[j - count].w + 'px',
                        top: positionS[j - count].h + 'px',
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
            )        
        }
*/
    } else { // frontend
        for (let j: number = (Main.Instance.FrontendStoreState.page - 1) * slots; j < Main.Instance.FrontendStoreState.page * slots; j++) {
            let src: string
            let id: string
            for (const item of stream) {
                if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && j + 1 === +item['index']) {
                    src = item['image']
                    id = item['id']
                    break
                }
            }

            gsip.push (
                <span
                    key = {j.toString()}
                    style = {{
                        color: src ? 'red' : 'black',
                        position: 'absolute',
                        left: j % 2 === 0 ? '97px': 'auto',
                        right: j % 1 === 0 ? '97px': 'auto',
                        top: (Math.floor(j / 2) * 190 + 130) + 'px',
                        fontSize: '20px',
                        fontWeight: 700,
                    }}
                >
                    {j+1}
                </span>,
            )
            
            gsip.push (
                <a
                    href = "javascript:void(0)"
                    onClick = {() => isUserAdmin() ? Toggle(j, id) : void 0}
                    key = {j.toString() + '_'}
                    style = {{
                        background: 'transparent',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: j % 2 === 0 ? '125px' : 'auto',
                        right: j % 2 === 1 ? '125px': 'auto',
                        top: (Math.floor(j % slots / 2) * 190 + 80) + 'px',
                        width: '130px',
                        height: '130px',
                        border: src ? '1px solid red' : '1px solid black',
                    }}
                >
                    <img style = {{width: '100%', height: '100%'}} src = {src ? src : Assets_.zeroPng}/>
                </a>,
            )

            if (isUserAdmin()) { gsip.push (
                <button
                    onClick = {() => Toggle(j, id, true)}
                    key = {j.toString() + '__'}
                    title = {Assets.RemoveGameFromTheSlot}
                    style = {{
                        lineHeight: '10px',
                        background: src ? 'red' : 'black',
                        width: '14px',
                        height: '14px',
                        zIndex:9999,
                        color: '#fff',
                        border: '0px',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: j % 2 === 1 ? '421px' : '241px',
                        top: (Math.floor(j % slots / 2) * 190 + 80) + 'px',
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>,
            )
            }
        }
/*
        let positions:{w: number, h: number}[] = [{w:456, h:56},{w:10, h:603},{w:461, h:600},{w:456, h:56}, {w:10, h:603}, {w:461, h:600}, {w:456, h:56}, {w:10, h:603}, {w:461, h:600}, {w:461, h:600}]
        let Positions:{w: number, h: number}[] = [{w:430, h:82},{w:110, h:637},{w:429, h:630},{w:430, h:82},{w:110, h:637},{w:429, h:630},{w:430, h:82}, {w:110, h:637}, {w:429, h:630}, {w:429, h:630}]
        let positionS:{w: number, h: number}[] = [{w:532, h:56},{w:86, h:603},{w:537, h:600},{w:532, h:56}, {w:86, h:603}, {w:537, h:600}, {w:532, h:56}, {w:86, h:603}, {w:537, h:600}, {w:537, h:600}]

        for (let j: number = count; j < count2; j++) {
            let src: string
            let id: string
            for (let item of stream) {
                if (+item['category'] === Main.Instance.FrontendStoreState.rewardShopOpen - 1 && j + 1 === +item['index']) {
                    src = item['image']
                    id = item['id']
                    break
                }
            }

            gsip.push (
                <span
                    key = {j.toString()}
                    style = {{
                        color: src ? 'yellow' : '#fff',
                        position: 'absolute',
                        left: (Positions[j - count].w) + 'px',
                        top: (Positions[j - count].h) + 'px',
                        fontSize: '20px',
                        fontWeight: 700
                    }}
                >
                    {j + 1}
                </span>
            )

            gsip.push (
                <a
                    href = "javascript:void(0)"
                    onClick = {() => isUserAdmin() ? Toggle(j, id) : void 0}
                    key = {j.toString() + '_'}
                    style = {{
                        background: 'transparent',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: positions[j - count].w + 'px',
                        top: positions[j - count].h + 'px',
                        width: '90px',
                        height: '90px',
                        border: src ? '1px solid yellow' : '1px solid #fff'
                    }}
                >
                    <img style = {{width: '100%', height: '100%'}} src = {src ? src : Assets_.zeroPng}/>
                </a>                
            )

            if (isUserAdmin()) gsip.push (
                <button
                    title = {Assets.RemoveGameFromTheSlot}
                    onClick = {() => Toggle(j, id, true)}
                    key = {j.toString() + '__'}
                    style = {{
                        lineHeight: '10px',
                        background: src ? 'yellow' : '#fff' ,
                        width: '14px',
                        height: '14px',
                        zIndex:9999,
                        border: '0px',
                        borderRadius: '0px',
                        position: 'absolute',
                        left: positionS[j - count].w + 'px',
                        top: positionS[j - count].h + 'px',
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <i className = {Assets_.faCancelButtonClass}/>
                </button>
            )        
        }
*/   
    }
    return gsip
}
/*
function replaceImage(reader, which, scale) {
    let img = new Image()
    img.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext('2d').drawImage(img, 0, 0)
        let resulting_canvas = downScaleCanvas(canvas, scale)
        ReplaceImage(resulting_canvas.toDataURL(), which)
    }
    img.src = reader.result
}

function setHoverBrightness(which: string, num: number) {
    const custom = Object.assign({}, Main.Instance.FrontendStoreState.custom)
    if (which === Assets.Category) custom.hoverBrightnessCategory = num
    else custom.hoverBrightnessItems = num
    Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
}

function getHoverBrightness(which: string): JSX.Element[] {
 const hb: JSX.Element[] = []
 if (Main.Instance.FrontendStoreState.custom === undefined) return hb
 // alert(JSON.stringify(Main.Instance.FrontendStoreState.custom))
 let HB: number = which === Assets.Category ? Main.Instance.FrontendStoreState.custom.hoverBrightnessCategory : Main.Instance.FrontendStoreState.custom.hoverBrightnessItems
 for (let i: number = 1; i <= 20; i++) {
     hb.push (
         <button
            style = {{
                width: '50px',
                marginLeft: i === 1 ? '16.24%' : '0px',
                marginTop: '10px',
                height: '24px',
                WebkitFilter: i * 10 === HB ? 'brightness(' + i * 10 + '%)' : '',
                filter: i * 10 === HB ? 'brightness(' + i * 10 + '%)' : '',
                color: i > 12 ? '#333' : '',
                marginRight: i === 20 ? '0px' : '9px'
            }}
            className = {i * 10 === HB ? which === Assets.Category ? 'btn btn-xs btn-info' : 'btn btn-xs btn-danger' : 'btn btn-xs btn-default'}
            onClick = {() => setHoverBrightness(which, i * 10)}
         >
            {i * 10 + '%'}
         </button>
     )
 }
 return hb
}
*/

function SelectPage(page: number) {Logic.Type.New (Main.Instance.FrontendStoreState, {page})}

function getPages(): JSX.Element[] {
    const pages: JSX.Element[] = []

    for (let i: number = 1; i <= Main.Instance.FrontendStoreState.pages; i++) {
        pages.push(
            <button onClick = {() => SelectPage(i)} key = {i.toString()} className = {Main.Instance.FrontendStoreState.page === i ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>
                {Assets.Page + ' ' + i}
            </button>,
        )
    }

    pages.push (
        <span style = {{position: 'absolute', right: '0px', top: '5px', fontSize: '14px', padding: '3px 6px 5px'}} className = "label label-info label-sm">
            {Main.Instance.FrontendStoreState.items + ' ' + Assets.itemsInTotal}
        </span>,
    )

    return pages
}
/*
function getCategoriesImages(): JSX.Element[] {
    const ci: JSX.Element[] = []
    const comingSoon: number = isPureScandibet() ? Assets_.ComingSoonScandibet : Assets_.ComingSoon
    for (let i: number = isPureScandibet() ? Main.Instance.FrontendStoreState.Category.length : Main.Instance.FrontendStoreState.Category.length + 1 ; i > comingSoon; i--) {
        ci.push (
            <img
                onClick = {() => SelectCategory (i - comingSoon + 1)}
                className = "hover"
                key = {i.toString() + '_'}
                src = {Main.Instance.FrontendStoreState.images[i]}
                style = {{
                    position: 'absolute',
                    left: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].left + '%',
                    top: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].top + '%',
                    height: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].height + '%',
                    width: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].width + '%',
                    borderRadius: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].circle ? '50%' : '0%',
                    opacity: 0,
                }}
            />,
        )
        ci.push (
            <img
                onClick = {() => SelectCategory (i - comingSoon + 1)}
                className = "show-on-hover"
                key = {i.toString()}
                src = {Main.Instance.FrontendStoreState.images[i]}
                style = {{
                    position: 'absolute',
                    left: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].left + '%',
                    top: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].top + '%',
                    height: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].height + '%',
                    width: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].width + '%',
                    borderRadius: Main.Instance.FrontendStoreState.custom.categoriesPositioning[i - comingSoon - 1].circle ? '50%' : '0%',
                }}
            />,
        )
    }
    return ci
}
*/