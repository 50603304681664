import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
// import {IsBuildOddyzzey, IsBuildJackpotsCH/*, IsBuildBettoday*/} from '../../../Logic/Utils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function OddsportalWrapper(props: React.Props<any>) {
    if(RouterObjects['oddsportal'] && Main.Instance.Location === RouterObjects['oddsportal'].path && Main.Instance.Back === false) {
        let path
        let Path: string
        path = RouterObjects['oddsportal'].path + RouterObjects['oddsportal'].children['Matches__Results'].path
        Path = RouterObjects['oddsportal'].path.substring (1, RouterObjects['oddsportal'].path.length) + ' -> ' +
               RouterObjects['oddsportal'].children['Matches__Results'].path.substring(1, RouterObjects['oddsportal'].children['Matches__Results'].path.length)

        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')
        DbRoutes.insertAdminLog (Config.asAdminLogView, Path, {})
        Logic.Type.New(Main.Instance, {Location: path})
    }

    return(<div>{props.children}</div>)
}