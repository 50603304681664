import * as React from 'react'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import { ManageAdminUsersSystem } from './ManageAdminUsersSystem'
import { ManageAdminUsersMaster } from './ManageAdminUsersMaster'
import { ManageAdminUsersOperator } from './ManageAdminUsersOperator'
import { ManageAdminUsersBrandOwner } from './ManageAdminUsersBrandOwner'
import { ManageAdminUsersGameProvider } from './ManageAdminUsersGameProvider'
import { ManageAdminUsersGameAssetProvider } from './ManageAdminUsersGameAssetProvider'
// import {ManageAdminUsersComplianceProvider} from './ManageAdminUsersComplianceProvider'
// import {ManageAdminUsersComplianceSubProvider} from './ManageAdminUsersComplianceSubProvider'
// import {ManageAdminUsersPaymentProvider} from './ManageAdminUsersPaymentProvider'
// import {ManageAdminUsersPaymentSubProvider} from './ManageAdminUsersPaymentSubProvider'
// import {ManageAdminUsersSite} from './ManageAdminUsersSite'
// import {ManageAdminUsersSite2} from './ManageAdminUsersSite2'
import * as Assets from '../../Assets'
import { FormSelect } from '../../Reusables/Inputs'
import {isUserAdmin, isUserSystem, isUserGameProvider, isUserOperator, isUserGameAssetProvider, isUserMaster, isUserSiteOwner} from '../../../Logic/Utils'
import * as Assets_ from '../../Assets_'
/*, isUserSite isUserGamingSite,
isUserComplianceProvider, isUserComplianceSubProvider, isUserPaymentProvider, isUserPaymentSubProvider,*/
// what is the matter with o6 - ManageAdminUsersGameAssetProvider
export function ManageAdminUsers() {
    let title: string = ''
    if (isUserSystem() || isUserMaster()) {
            /*
        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aeSystemUser
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aeMasterUser
        } else if (Main.Instance.ManageState.selectionUsers === 3) {
                title = Assets.aeOperatorUser
        } else if (Main.Instance.ManageState.selectionUsers === 4) {
                title = Assets.aeOperator2User
        } else if (Main.Instance.ManageState.selectionUsers === 5) {
                title = Assets.aeGameProviderUser
        } else if (Main.Instance.ManageState.selectionUsers === 6) {
                title = Assets.aeGameAssetProviderUser
        }
            */
            /*
        } else if (Main.Instance.ManageState.selectionUsers === 4) {
                title = Assets.aeSiteUser
            */

            /*else if (Main.Instance.ManageState.selectionUsers === 7) {
                    title = Assets.aePaymentProviderUser
            } else if (Main.Instance.ManageState.selectionUsers === 8) {
                    title = Assets.aePaymentSubProviderUser
            } else if (Main.Instance.ManageState.selectionUsers === 9) {
                    title = Assets.aeComplianceProviderUser
            } else if (Main.Instance.ManageState.selectionUsers === 10) {
                    title = Assets.aeComplianceSubProviderUser
    }*/        /*else if (Main.Instance.ManageState.selectionUsers === 12) {
                            title = Assets.aeSite2User
                    }*/

    } else if (isUserOperator()) {
        title = Assets.aeOperatorUser
        /*                
        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aeOperatorUser
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aeSiteUser
        }*/

    } else if (isUserSiteOwner()) {
        title = Assets.aeOperator2User
        /*
        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aeOperator2User
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aeSite2User
        }*/
    } else if (isUserGameProvider()) {
        /*
        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aeGameProviderUser
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aeGameAssetProviderUser
        }
        */
    } else if (isUserGameAssetProvider()) {

        title = Assets.aeGameAssetProviderUser
    }

    /*   
    else if (isUserPaymentProvider()) {

        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aePaymentProviderUser
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aePaymentSubProviderUser
        }

    }   else if (isUserComplianceProvider()) {

        if (Main.Instance.ManageState.selectionUsers === 1) {
                title = Assets.aeComplianceProviderUser
        } else if (Main.Instance.ManageState.selectionUsers === 2) {
                title = Assets.aeComplianceSubProviderUser
        }

    }   else if (isUserGamingSite()) {

        title = Assets.aeSiteUser

    }   else if (isUserGameAssetProvider()) {

        title = Assets.aeGameAssetProviderUser

    }   else if (isUserPaymentSubProvider()) {

        title = Assets.aePaymentSubProviderUser

    }   else if (isUserComplianceSubProvider()) {

        title = Assets.aeComplianceSubProviderUser

    }   else if (isUserSite()) {

        title = Assets.aeSite2User
    }
    */

    return (
        <div>
            <br />
            <div>
                {isUserSystem() ? 
                <div>{/*<h3 className = "title-left">{title}</h3>*/}
                    <FormSelect
                        value={Main.Instance.ManageState.selectionUsersInitial.toString()}
                        data={[0]}
                        ClassName="form-control select-menu"
                        // floatRight = {true}
                        w50={true}
                        // o1 = {Assets.selectUserType}
                        o1={isUserAdmin()? Assets.aeSystemUser :
                                Assets.vSystemUsers}
                        o2={isUserAdmin()? Assets.aeMasterUser :
                                Assets.vMasterUsers}
                        o3={isUserAdmin()? Assets.aeOperatorUser :
                                Assets.vOperatorUsers}
                        o4={isUserAdmin()? Assets.aeOperator2User :
                                Assets.vOperator2Users}
                        o5={isUserAdmin()? Assets.aeGameProviderUser :
                                Assets.vGameProviderUsers}
                        o6={isUserAdmin()? Assets.aeGameAssetProviderUser : Assets.vGameAssetProviderUsers}
                        // o7={isUserAdmin()? Assets.aeSiteUser : Assets.vSiteUsers}
                        onChange={(text) => RouteUsersSelection(Assets_.nSystem, text)}
                        icon={Assets_.faUserLgClass}
                    />
                    <div className="clearfix" />
                    <br />
                </div> : void 0}

                {isUserGameProvider() ?
                <div>
                    <FormSelect
                        value={Main.Instance.ManageState.selectionUsersInitial.toString()}
                        data={[0]}
                        ClassName="form-control select-menu"
                        floatRight={true}
                        w50={true}
                        // o1 = {Assets.selectUserType} 
                        o1={isUserAdmin()? Assets.aeGameProviderUsers :
                            Assets.vGameProviderUsers}
                        o2={isUserAdmin()? Assets.aeGameAssetProviderUsers :
                            Assets.vGameAssetProviderUsers}
                        onChange={(text) => 
                            RouteUsersSelection(Assets_.nGameProvider, text)}
                        icon={Assets_.faUserLgClass}
                    />
                    <div className="clearfix" />
                    <br />
                </div>
                : void 0}

                {isUserGameAssetProvider() ?
                    <h3 style={{ height: '45px', marginTop: '0px' }}>
                            {isUserAdmin()? Assets.aeGameAssetProviderUsers :
                                    Assets.vGameAssetProviderUsers}
                    </h3>
                : void 0}

                {isUserOperator() ?
                    <h3 style={{ height: '45px', marginTop: '0px' }}>
                            {isUserAdmin()? Assets.aeOperatorUsers :
                                    Assets.vOperatorUsers}
                    </h3>
                : void 0}

                {isUserSiteOwner() ?
                    <h3 style={{ height: '45px', marginTop: '0px' }}>
                            {isUserAdmin()? Assets.aeOperator2Users :
                                    Assets.vOperator2Users}
                    </h3>
                : void 0}

                {/* isUserOperator() ?
                <div>
                    <h3 className = "title-left">{title}</h3>
                    <FormSelect
                            value = {Main.Instance.ManageState.selectionUsersInitial.toString()}
                            data = {[0]}
                            ClassName = "form-control select-menu"
                            floatRight = {true}
                            w50 = {true}
                            // o1 = {Assets.selectUserType} 
                            o1 = {isUserAdmin()? Assets.aeOperatorUsers : 
                                    Assets.vOperatorUsers} 
                            o2 = {isUserAdmin()? Assets.aeSiteUsers : Assets.vSiteUsers} 
                            onChange = {text => RouteUsersSelection(Assets.nOperator, text)}
                            icon = {Assets_.faUserLgClass}
                    /> 
                    <div className = "clearfix"/>
                    <br/>
                </div>
                : void 0*/}

                {/* isUserGamingSite () ?
                <h3>{isUserAdmin()? Assets.aeSiteUsers : Assets.vSiteUsers}</h3>
                : void 0*/}

                {/* isUserSite () ?
                <h3>{isUserAdmin()? Assets.aeSite2Users : Assets.vSite2Users}</h3>
                : void 0*/}

                {/* isUserSiteOwner() ?
                <div>
                    <h3 className = "title-left">{title}</h3>
                    <FormSelect
                            value = {Main.Instance.ManageState.selectionUsersInitial.toString()}
                            data = {[0]}
                            ClassName = "form-control select-menu"
                            floatRight = {true}
                            w50 = {true}
                            // o1 = {Assets.selectUserType} 
                            o1 = {isUserAdmin()? Assets.aeOperator2Users : 
                                    Assets.vOperator2Users} 
                            o2 = {isUserAdmin()? Assets.aeSiteUsers : Assets.vSiteUsers} 
                            onChange = {text => RouteUsersSelection(Assets.nOperator2, text)}
                            icon = {Assets_.faUserLgClass}
                    /> 
                    <div className = "clearfix"/>
                    <br/> 
                </div>
                : void 0*/}

                {/* isUserPaymentProvider() ?
                <div>
                    <h3 className = "title-left">{title}</h3>
                    <FormSelect
                            value = {Main.Instance.ManageState.selectionUsersInitial.toString()}
                            data = {[0]}
                            ClassName = "form-control select-menu"
                            floatRight = {true}
                            w50 = {true}                                
                            o1 = {Assets.selectUserType} 
                            o2 = {isUserAdmin()? Assets.aeOperatorUsers : 
                                    Assets.vPaymentProviderUsers} 
                            o3 = {isUserAdmin()? Assets.aeSiteUsers : 
                                    Assets.vPaymentSubProviderUsers} 
                            onChange = {text => RouteUsersSelection(Assets.nPaymentProvider, text)}
                            icon = {Assets_.faUserLgClass}                        
                    />
                    <div className = "clearfix"/>
                    <br/>
                </div>
                : void 0*/}

                {/* isUserPaymentSubProvider() ?
                <h3>
                    {isUserAdmin()? Assets.aePaymentSubProviderUsers : Assets.vPaymentSubProviderUsers}
                </h3>
                : void 0*/}

                {/* isUserComplianceProvider() ?
                <div>
                    <h3 className = "title-left">{title}</h3>
                    <FormSelect
                            value = {Main.Instance.ManageState.selectionUsersInitial.toString()}
                            data = {[0]}
                            ClassName = "form-control select-menu"
                            floatRight = {true}
                            w50 = {true}                                
                            o1 = {Assets.selectUserType} 
                            o2 = {isUserAdmin()? Assets.aeOperatorUsers : 
                                    Assets.vComplianceProviderUsers} 
                            o3 = {isUserAdmin()? Assets.aeSiteUsers : 
                                    Assets.vComplianceSubProviderUsers} 
                            onChange = {text => RouteUsersSelection(Assets.nComplianceProvider, text)}
                            icon = {Assets_.faUserLgClass}                        
                    />
                    <div className = "clearfix"/>
                    <br/>
                </div>
                : void 0*/}

                {/* isUserComplianceSubProvider() ?
                <h3>{isUserAdmin()? Assets.aeComplianceSubProviderUsers : 
                        Assets.vComplianceSubProviderUsers}
                </h3>
                : void 0*/}

                {isUserMaster() ? <div><h3 className="title-left">{title}</h3>
                    <FormSelect
                        value={Main.Instance.ManageState.selectionUsersInitial.toString()}
                        data={[0]}
                        ClassName="form-control select-menu"
                        // floatRight = {true}
                        w50={true}
                        // o1 = {Assets.selectUserType}
                        o1={isUserAdmin()? Assets.aeMasterUser :
                                Assets.vMasterUsers}
                        o2={isUserAdmin()? Assets.aeOperatorUser :
                                Assets.vOperatorUsers}
                        o3={isUserAdmin()? Assets.aeOperator2User :
                                Assets.vOperator2Users}
                        o4={isUserAdmin()? Assets.aeGameProviderUser :
                                Assets.vGameProviderUsers}
                        o5={isUserAdmin()? Assets.aeGameAssetProviderUser :
                                Assets.vGameAssetProviderUsers}

                        onChange={(text) => RouteUsersSelection(Assets_.nMaster, text)}
                        icon={Assets_.faUserLgClass}
                    />
                    <div className="clearfix" />
                    <br /> </div>
                : void 0}

        </div>
        {renderSelection()}
    </div>)
}

function RouteUsersSelection(mode: number, text: string) {

        if (mode === Assets_.nSystem) {

                Logic.Type.New(Main.Instance.ManageState, {
                        selectionUsersInitial: parseInt(text), selectionUsers: parseInt(text) + 1,
                })

        } else if (mode === Assets_.nMaster) {

                Logic.Type.New(Main.Instance.ManageState, {
                        selectionUsersInitial: parseInt(text), selectionUsers: parseInt(text) + 2,
                })

        } else {
                // if (parseInt(text) !== 0) {
                Logic.Type.New(Main.Instance.ManageState, {
                        selectionUsersInitial: parseInt(text), selectionUsers: parseInt(text) + 1,
                })
                // } 
                /*else {
                       Logic.Type.New (Main.Instance.ManageState, { 
                               selectionUsersInitial: 0, selectionUsers: 1})
               }*/
        }

}

function renderSelection() {
        
        // console.log (Main.Instance.ManageState.selectionUsers)
        if (isUserSystem() || isUserMaster()) {
                if (Main.Instance.ManageState.selectionUsers === 1) {
                        return ManageAdminUsersSystem()
                } else if (Main.Instance.ManageState.selectionUsers === 2) {
                        return ManageAdminUsersMaster()
                } else if (Main.Instance.ManageState.selectionUsers === 3) {
                        return ManageAdminUsersOperator()
                } else if (Main.Instance.ManageState.selectionUsers === 4) {
                        return ManageAdminUsersBrandOwner()
                } else if (Main.Instance.ManageState.selectionUsers === 5) {
                        return ManageAdminUsersGameProvider()
                /* } else if (Main.Instance.ManageState.selectionUsers === 6 ) {
                        return ManageAdminUsersSite()
                */
                } else if (Main.Instance.ManageState.selectionUsers === 6) {
                        return ManageAdminUsersGameAssetProvider()
                }
                /*else if (Main.Instance.ManageState.selectionUsers === 7 ) {
                        return ManageAdminUsersPaymentProvider()
                } else if (Main.Instance.ManageState.selectionUsers === 8 ) {
                        return ManageAdminUsersPaymentSubProvider()
                } else if (Main.Instance.ManageState.selectionUsers === 9 ) {
                        return ManageAdminUsersComplianceProvider()
                } else if (Main.Instance.ManageState.selectionUsers === 10 ) {
                        return ManageAdminUsersComplianceSubProvider()
                } else if (Main.Instance.ManageState.selectionUsers === 12 ) {
                        return ManageAdminUsersSite2()
                }*/
        } else if (isUserGameProvider()) {
                if (Main.Instance.ManageState.selectionUsers === 1) {
                        return ManageAdminUsersGameProvider()
                } else if (Main.Instance.ManageState.selectionUsers === 2) {
                        return ManageAdminUsersGameAssetProvider()
                }
        } else if (isUserOperator()) {
                return ManageAdminUsersOperator()
                /*
                                if (Main.Instance.ManageState.selectionUsers === 1 ) {
                                        return ManageAdminUsersOperator()
                                } else if (Main.Instance.ManageState.selectionUsers === 2 ) {
                                        return ManageAdminUsersSite()
                                }
                */
        } else if (isUserSiteOwner()) {
                return ManageAdminUsersBrandOwner()
                /*                
                                if (Main.Instance.ManageState.selectionUsers === 1 ) {
                                        return ManageAdminUsersOperator2()
                                } else if (Main.Instance.ManageState.selectionUsers === 2 ) {
                                        return ManageAdminUsersSite()
                                }
                */
        } else if (isUserGameAssetProvider()) {
                return ManageAdminUsersGameAssetProvider()
        }

        /*        } else if (isUserSite()) {
                        return ManageAdminUsersSite2() */
        /*else if (isUserGamingSite()) {
                        return ManageAdminUsersSite()
                }*/
        /*
                } else if (isUserComplianceProvider()) {
                        if (Main.Instance.ManageState.selectionUsers === 1 ) {
                                return ManageAdminUsersComplianceProvider()
                        } else if (Main.Instance.ManageState.selectionUsers === 2 ) {
                                return ManageAdminUsersComplianceSubProvider()
                        }
                } else if (isUserComplianceSubProvider()) {
                        return ManageAdminUsersComplianceSubProvider()
                } else if (isUserPaymentProvider()) {
                        if (Main.Instance.ManageState.selectionUsers === 1 ) {
                                return ManageAdminUsersPaymentProvider()
                        } else if (Main.Instance.ManageState.selectionUsers === 2 ) {
                                return ManageAdminUsersPaymentSubProvider()
                        }
                } else if (isUserPaymentSubProvider()) {
                        return ManageAdminUsersPaymentSubProvider()
                }*/
}
