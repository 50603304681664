import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import { Main } from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as TableUtils from '../../Reusables/TableUtils'
import { gotoTop/*, injectSpaces*/} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import { LevelTypes } from '../../../Logic/Database/levels/levelsEnums';
import { /*faGamepad, *//*faTrashAlt,*/ faExclamationTriangle, faUpload, faDownload, faFileCsv, faCheckDouble, faTimes, faCheck, faSave, /* faMoneyBillWave, */ faLightbulb, faPercentage, faGift, faCogs, faCoins, /* faSitemap, */ faWrench, faPlus, faPercent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Assets_ from '../../Assets_'
import { FormInputArea, FormInputNumber, FormSelect, FormInput } from '../../Reusables/Inputs'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ButtonIcon } from '../../Reusables/Button'
import { ILevelDefinition } from '../../../Logic/Database/levels/levelsModelsNew'
import Switch from 'react-toggle-switch'
import * as base64 from 'base-64'
import {ICategoryPercentage} from '../../../Logic/Database/levels/levelsModelsNew'
/*
import txt0 from '../../../Assets/0.txt'
import txt10 from '../../../Assets/10.txt'
import txt100 from '../../../Assets/100.txt'
*/
/*
import * as txt0 from '../../../Assets/0.txt'
import * as txt10 from '../../../Assets/10.txt'
import * as txt100 from '../../../Assets/100.txt'
*/

function NoDuplicates2(array: ICategoryPercentage[]): boolean {
    // console.log (array)
    for (let i: number = 0; i < array.length; i++) {
        if (array[i].games) {
            for (let j: number = 0; j < array[i].games.length; j++) {
                for (let k: number = 0; k < array.length; k++) {
                    if (array[k].games) {
                        for (let l: number = 0; l < array[k].games.length; l++) {
                            if (i === k && j === l) {continue}
                            if (i !== k && array[i].games[j] === array[k].games[l]) {
                                // console.log (i, j, array[i].games[j], k, l)
                                return false
                            }
                        }
                    }
                }
            }
        }
    }
    return true
}

export function NoDuplicates(array: number[][]): boolean {
    for (let i: number = 0; i < array.length; i++) {
        for (let j: number = 0; j < array[i].length; j++) {
            for (let k: number = 0; k < array.length; k++) {
                for (let l: number = 0; l < array[k].length; l++) {
                    if (i === k && j === l) {continue}
                    if (i !== k && array[i][j] === array[k][l]) {
                        // console.log ('NoDuplicates', i, j, array[i][j], k, l)
                        return false
                    }
                }
            }
        }
    }
    return true
}

interface State {
    level: ILevelDefinition
    pSize: number
    pNum: number
    // savedLevel: ILevelDefinition
    index: number
    showCategoryContributions: boolean
    showCustom: boolean
    showLevelDetails: boolean
    showTokensPercentage: boolean
    // showGamesForCategory: number
    selectedTable: number
    selectedGameId: string
    selectedGameName: string
    filtered: []
}
// from export const GameCategoryIncluded
/*
enum Categories {
    Slots = 1,
    VideoPoker,
    TableGames,
    LotteryGames,
    LiveDealer,
    ScratchCards,
    Desktop,
    Nano,
    Roulette,
    BlackJack
}
*/
export class LevelsNew extends React.Component<{}, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            level: {
                id: 0,
                name: '',
                description: '',
                type: LevelTypes.Standard,
                hierarchy: 0,
                levelsDetails: {
                    points: undefined,
                    depositsAmount: undefined,
                    depositsCount: undefined,
                    rewardId: undefined
                },
                sublevelsDetails: {
                    active: false,
                    sublevelsNumber: undefined,
                    sublevelsForReward: undefined,
                    points: undefined,
                    depositsAmount: undefined,
                    depositsCount: undefined,
                },
                consecutiveDaysBonus: {
                    active: false,
                    minimalBets: undefined,
                    daysBonus: []
                },
                tokensPercentage: {
                    active: false,
                    fromWagers: undefined,
                    fromDeposits: undefined,
                    minDeposit: undefined,
                },
                categoryContributions: {
                    active: false,
                    contributions: []
                },
                custom: {
                    weeklyCashbackPercentage: undefined,
                    monthlyCashbackPercentage: undefined,
                    shopDiscountPercentage: undefined,
                    accountManager: false
                }
            },

            pSize: 10,
            pNum: 1,
            index: -1,
            showCategoryContributions: false,
            showCustom: false,
            showLevelDetails: false,
            showTokensPercentage: false,
            // showGamesForCategory: -1,
            selectedTable: -1,
            selectedGameId: '-1',
            selectedGameName: '-1',
            filtered: [],
        }
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        DbRoutes.wsReinitialize()
    }

    public handleEdit(row: any) {
        gotoTop()
     
        let index = -1
        if (Main.Instance.DatabaseDataState.levelsStream) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.levelsStream.length; i++) {
                if (Main.Instance.DatabaseDataState.levelsStream[i].id === row.original.id) {
                    index = i
                    break
                }
            }
        }

        // const level: ILevelDefinition = JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.levelsStream[index]))
        // console.log (level)
        this.setState({ index, level: JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.levelsStream[index]))})
    }

    public handleDelete(row: any) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Level.toLowerCase() + ' "' + row.original.name + '"?')) {
            DbRoutes.removeLevel(row.original.id)
            /*
                        if  (this.state.pNum === (Math.trunc((Main.Instance.DatabaseDataState.levelsNewCount - 2) / this.state.pSize) + 1)) {
                            
                            // DbRoutes.removeLevel(row.original.id, DeleteLastPage)
                        } else {
                            // DbRoutes.removeLevel(row.original.id, DeleteNotLastPage)
                        }
            */
        }
    }

    public isInsert(): boolean {
        return this.state.index === -1
    }

    public handleInsertUpdate() {
        if (this.state.level.name === '') {
            alert(Assets.PleaseEnterLevelName)
            return
        }
        if (this.state.level.hierarchy === 0) {
            alert(Assets.PleaseEnterLevel)
            return
        } 
        
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.levelsStream.length; i++) {
            if (!this.isInsert() && this.state.index === i) {
                continue
            }
            if (this.state.level.hierarchy === Main.Instance.DatabaseDataState.levelsStream[i].hierarchy) {
                alert(Assets.ThisLevelAlreadyExists)
                return
            }
            if (this.state.level.type === LevelTypes.Final && Main.Instance.DatabaseDataState.levelsStream[i].type === LevelTypes.Final) {
                alert(Assets.AFinalLevelAlreadyExists)
                return
            }
        }

        if (this.isInsert()) {
            DbRoutes.insertLevel(this.state.level)
        } else {
            DbRoutes.updateLevel(this.state.level)
        }
        this.clearFields()
    }

    public getLevels() {

    }

    public clearFields() {
        const level: ILevelDefinition = Object.assign({}, this.state.level)
        level.id = 0,
        level.name = ''
        level.description = ''
        level.type = LevelTypes.Standard
        level.hierarchy = 0
        level.levelsDetails = {}
        level.sublevelsDetails = {}
        level.consecutiveDaysBonus = {}
        level.tokensPercentage = {}
        level.categoryContributions = {
            active: false,
            contributions: []
        }
        level.custom = {}
        /*showGamesForCategory: -1,*/
        this.setState({level, index: -1, showCategoryContributions: false, showCustom: false, showLevelDetails: false, showTokensPercentage: false })
    }

    public gotoInsertMode() {
        if (JSON.stringify(this.state.level) === JSON.stringify(Main.Instance.DatabaseDataState.levelsStream[this.state.index])) {
            this.clearFields()
            
        } else {
            if (confirm(Assets.UnsavedChanges)) {
                this.clearFields()
            }
        }

    }

    public RecordsPerPageChanged(text: string) {
        if (parseInt(text) > 0) {
            this.setState({ pSize: parseInt(text) })
            this.FirstPage()
        }
    }

    public FirstPage() {


        this.getLevels()
        this.setState({ pNum: 1 })
    }

    public PrevPage() {
        if (this.state.pNum > 1) {

            this.getLevels()
            this.setState({ pNum: this.state.pNum - 1 })
        } else {
            this.LastPage()
        }
    }

    public NextPage() {
        if (this.state.pNum * this.state.pSize < Main.Instance.DatabaseDataState.levelsNewCount) {

            this.getLevels()
            this.setState({ pNum: this.state.pNum + 1 })
        } else {
            this.FirstPage()
        }
    }

    public LastPage() {
        if (Main.Instance.DatabaseDataState.levelsNewCount <= this.state.pSize) {
            this.FirstPage()
        } else {
            let pNum: number = 0;
            if (Main.Instance.DatabaseDataState.levelsNewCount === 0) {

            } else {
                pNum = Math.trunc((Main.Instance.DatabaseDataState.levelsNewCount - 1) / this.state.pSize) + 1

            }
            this.getLevels()
            this.setState({ pNum })
        }
    }


    public getTypeValue(row: any): JSX.Element {
        const levelTypes: string[] = Object.keys(LevelTypes)
        const colors: string[] = ['label label-info', 'label label-primary', 'label label-success']
        let value: string = ''
        let color: string = ''
        for (let i: number = 0; i < levelTypes.length / 2; i++) {
            if (row === parseInt(levelTypes[i])) {
                value = LevelTypes[row]
                color = colors[i]

            }


        }
        return <span style={{ fontSize: '12px' }} className={color}>{value}</span>

    }

    public getRowValue(row: any): JSX.Element {
        let title: string = ''
        if (Main.Instance.DatabaseDataState.rewardShopStream) {
            for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
                if (row === item.id) {
                    title = item.Reward
                }
            }
        }
        return <span>{title}</span>
    }

    public getTypeOptions() {
        const levelTypes: string[] = Object.keys(LevelTypes)
        const options: Array<{ id: string, name: string }> = []
        for (let i: number = 0; i < levelTypes.length / 2; i++) {
            options.push({ name: levelTypes[levelTypes.length / 2 + i], id: levelTypes[i].toString() })
        }
        return options
    }

    public getRewardOptions() {
        const options: Array<{ id: string, name: string }> = []
        if (Main.Instance.DatabaseDataState.rewardShopStream) {
            for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
                options.push({id: item.id, name: item.Reward})
            }
        }
        return options
    }

    public removeGame(game: string) {
        const level: ILevelDefinition = Object.assign({}, this.state.level)
        // const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
        // console.log (level)
        // console.log (this.state.selectedTable)
        if (level.categoryContributions && level.categoryContributions.contributions) {
            for (const item of level.categoryContributions.contributions) {
                if (item.percentage !== this.state.selectedTable) {continue}
                // console.log (item)
                // console.log (game)
                // console.log (item.games)
                if (item.games) {
                    // console.log (item.games)
                    for (let i: number = 0; i < item.games.length; i++) {
                        if (item.games[i] === game) {
                            // console.log ('i ', i)
                            item.games.splice(i, 1)
                            break
                        }
                    }
                }
            }
        }
        this.setState({level})
        // Logic.Type.New(Main.Instance.GeneralState, { general })
    }

    public getSuperfluousGames(games: string[]): JSX.Element[] {
        // console.log (this.state.level.categoryContributions.contributions)
        // console.log (games)
        const buttons: JSX.Element[] = []
        for (const item of games) {
            buttons.push(<button key = {item.toString()} type = "button" onClick = {() => this.removeGame(item) } className = "btn btn-danger btn-xs">{Assets.Remove + Assets._a_ + Assets.Game.toLowerCase() + Assets._with_ + Assets.Game.toLowerCase() + ' ' + Assets.ID + ' ' + item}</button>)
        }
        return buttons
    }

    public getContributions(row) {
        // console.log(row)
        const contributions: JSX.Element[] = []
        if (row) {
            for (let i: number = 0; i < row.length; i++) {
                contributions.push(
                    <div key = {i.toString()} style = {{lineHeight:'18px'}}>
                        <span
                            style = {{margin: '0px'}}
                            className="label label-default"
                            // title={''/*row[i].category === Categories.Other ? Assets.TableGames + ', ' + Assets.Roulette + ',' + Assets.BlackJack + ', ' + Assets.LiveCasino: ''*/}
                        >
                            {/*Assets_.GameCategoryIncluded[row[i].category - 1]:*/}
                            {row[i].percentage + '% - ' + (row[i].games ? row[i].games.length : 0) + ' ' + Assets.Game_s}
                        </span>
                    </div>)
            }
        }
        return contributions
    }

    public getCategoryOptions(): Array<{ id: string, name: string/*, disabled: boolean*/}> {
        const tableGames = 'Table Games'
        const options: Array<{ id: string, name: string/*, disabled: boolean*/}> = []
        // const categories: string[] = Object.keys(Categories)
        for (let i: number = 0; i < Assets_.GameCategoryIncluded.length /*categories.length / 2*/; i++) {
            // if (categories[i].toString() in this.state.level.categoryContributions.contributions) {
            //     continue
            // }
            let name: string = Assets_.GameCategoryIncluded[i] // injectSpaces(categories[categories.length / 2 + i])
            if (name === tableGames) {
                // name = tableGames + ' (' + Assets.Other + ')'
            } else if (name === 'Roulette') {
                name = 'Roulette (' + tableGames + ')'
            } else if (name === 'Black Jack') {
                name = 'Blackjack (' + tableGames + ')'
            }
            // /*, disabled: this.isDisabled(parseInt(categories[i]))*/
            options.push({id: (i+1).toString(), name})
        }
        return options
    }
/*
    public isDisabled(id: number): boolean {
        for (const item of this.state.level.categoryContributions.contributions) {
            // console.log(item.category)
            // console.log(id)
            if (item.category === id) {
                return true
            }
        }
        return false
    }
*/
    // public isDisabledButton(): boolean {
    //     const categories: string[] = Object.keys(Categories)
        
    //     for (let i: number = 0; i < categories.length / 2; i++) {
    //         if(parseInt(categories[i]) in this.state.level.categoryContributions.contributions) {
    //             continue
    //         } else {
    //             return false
    //         }
    //     }
        
    //     return true
    // }
/*
    public categoryContributions1(): JSX.Element[] {
        const ccc: JSX.Element[] = []
        for (let i: number = 0; i < Assets_.Lists.length; i++) {
            ccc.push(
                <div
                    style = {{padding: '8px', fontSize: '14px'}}
                    className = "fl label label-default ml"
                    key = {i + '_'}
                >
                    {Assets.Games + Assets._with_ + Assets_.Lists[i] + '%' + Assets_._txt}:
                </div>
            )

            ccc.push(
                <button
                    onClick = {() => this.DownloadCSVList(i)}
                    title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.List.toLowerCase() + ': ' + this.getGamesLength(i) + ' ' + Assets.Game_s}
                    key = {i + '__'}
                    style = {{color: '#6f9c67', background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px', marginRight: '5px'}}
                    className = "fl btn btn-default btn-sm ml"
                >
                    <FontAwesomeIcon icon = {faFileCsv}/>
                </button>
            )

            ccc.push(
                <button
                    onClick = {() => this.DownloadList(i)}
                    title = {Assets.Download + ' ' + Assets.List.toLowerCase() + ': ' + this.getGamesLength(i) + ' ' + Assets.Game_s}
                    key = {i + '___'}
                    style = {{padding: '4px 8px 3px', fontSize: '14px'}}
                    className = "fl btn btn-success btn-sm"
                >
                    <FontAwesomeIcon icon = {faDownload}/>&nbsp;{this.getGamesLength(i) + ' ' + Assets.Game_s}
                </button>
            )
        }
        const noDuplicates: boolean = NoDuplicates(Assets_.lists)
        ccc.push (<FontAwesomeIcon key = "_" title = {noDuplicates ? Assets.Game + ' ' + Assets.IDs + Assets._are_ + Assets.Unique.toLowerCase() : Assets.GameIds + Assets._are_ + Assets.LogicNot.toLowerCase() + ' ' + Assets.Unique.toLowerCase()} icon = {noDuplicates ? faCheck : faTimes} style = {{marginTop: '8px', color: noDuplicates ? '#5cb85c' : '#d9534f'}}/>)
        return ccc
    }
*/
    public categoryContributions0(): JSX.Element[] {
        const cc: JSX.Element[] = []
        if (this.state.level.categoryContributions.contributions.length === 0) {
            cc.push(
                <button
                    key = "2"
                    className = "btn btn-success btn-sm mr margin-bottom-sm dbl fl"
                    onClick = {() => {
                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                        level.categoryContributions.contributions = []
                        level.categoryContributions.contributions.push({percentage: 0, games: []})
                        level.categoryContributions.contributions.push({percentage: 10, games: []})
                        level.categoryContributions.contributions.push({percentage: 100, games: []})
                        this.setState({level})
                    }}
                >
                    {Assets.Set} {Assets.Default.toLowerCase()}
                </button>
            )
        }

        cc.push( 
            <button 
                // disabled = {!(this.state.level.categoryContributions.contributions.length < Object.keys(Categories).length / 2)}
                title = {/*this.state.level.categoryContributions.contributions.length < Object.keys(Categories).length / 2 ?*/ Assets.AddANewCategory + ' ' + Assets.Contribution.toLowerCase() /*: Assets.AllCategories + ' ' + Assets.set*/}
                key = "1"
                onClick = {() => {
                    const level: ILevelDefinition = Object.assign({}, this.state.level)
/*
                    let cat: number = 1
                    let newCat: number = 1
                    while (cat === newCat) {
                        // newCat = cat
                        for (const item of this.state.level.categoryContributions.contributions) {
                            if (newCat === item.category) {
                                newCat++
                                break
                            } 
                        }
                        if (newCat === cat) {
                            break
                        } else {
                            cat = newCat
                        }
                    }
*/
                    level.categoryContributions.contributions.push({percentage: 0, games: []})
                    this.setState({level})
                }} 
                className = "btn btn-success btn-sm mr0 margin-bottom-sm dbl fl"
                >
                    <FontAwesomeIcon icon = {faPlus}/>
            </button>
        )
        const noDuplicates: boolean = NoDuplicates2(this.state.level.categoryContributions.contributions)
        cc.push (<FontAwesomeIcon key = "2" title = {noDuplicates ? Assets.Game + ' ' + Assets.IDs + Assets._are_ + Assets.Unique.toLowerCase() : Assets.GameIds + Assets._are_ + Assets.LogicNot.toLowerCase() + ' ' + Assets.Unique.toLowerCase()} icon = {noDuplicates ? faCheck : faTimes} style = {{marginTop: '8px', marginLeft: '5px', color: noDuplicates ? '#5cb85c' : '#d9534f'}}/>)
        return cc
    }
/*
    public setCat(i: number) {
        const level: ILevelDefinition = Object.assign({}, this.state.level)
        if (level.categoryContributions.contributions[i].games === undefined) {
            level.categoryContributions.contributions[i].games = []
        }
        this.setState({showGamesForCategory: i, level})
    }
*/
    public categoryContributions(): JSX.Element[] {
        const c: JSX.Element[] = []
        for (let i: number = 0; i < this.state.level.categoryContributions.contributions.length; i++) {
            const cc: JSX.Element[] = []
            /*
            cc.push(
                <FormSelect
                    key = "0"
                    // verynarrow={true}
                    value={this.state.level.categoryContributions.contributions[i].category.toString()}
                    data={this.getCategoryOptions()}
                    ClassName="form-control"

                    onChange={(text) => {
                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                        level.categoryContributions.contributions[i].category = parseInt(text)
                        this.setState({ level })
                    }}
                    icon={Assets_.faGameClass}
                />
                // <FormInputNumber
                //     key = {i.toString()}
                //     description={Assets.Category}
                //     placeholder={Assets.Category}
                //     outerDiv="rows-per-table input-group ml0 pl0 mr mb"
                //     ClassName="w70 h30"
                //     value={this.state.level.categoryContributions.contributions[i].category.toString() ? this.state.level.categoryContributions.contributions[i].category.toString() : ''}
                //     type="number"
                //     min="1"
                //     icon={Assets_.faHashtagClass}
                //     onChange={(text) => {
                //         const level: ILevelDefinition = Object.assign({}, this.state.level)
                //         level.categoryContributions.contributions[i].category = parseInt(text)
                //         this.setState({ level })
                //     }}
                // />
            )
*/
            cc.push(
                <FormInputNumber
                    key = "1"
                    description={Assets.Percentage}
                    placeholder={Assets.Percentage}
                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                    ClassName="w70 h30"
                    value={this.state.level.categoryContributions.contributions[i].percentage.toString() ? this.state.level.categoryContributions.contributions[i].percentage.toString() : ''}
                    type="number"
                    min="1"
                    icon={Assets_.faPercentageClass}
                    onChange={(text) => {
                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                        level.categoryContributions.contributions[i].percentage = parseInt(text)
                        this.setState({ level })
                    }}
                />
            )

            cc.push(
                <div 
                    title = {Assets.RemoveCategory + ' ' + Assets.Contribution.toLowerCase()} 
                    style = {{zIndex: 2}} className = "fl pr" 
                    key = "2"
                >
                    <button 
                        onClick = {() => {
                            const level: ILevelDefinition = Object.assign({}, this.state.level)
                            level.categoryContributions.contributions.splice(i, 1)
                            this.setState({ level })
                        }} 
                        style = {{position: 'absolute', right: '14px', top: '0px', marginRight: '0px', border: '0px', background: 'transparent', padding: '0px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                </div>
            )
            // cc.push(
            //     <button
            //         key = "5"
            //         className = {i === this.state.showGamesForCategory ? 'btn btn-sm btn-primary fl dbl' : 'btn btn-sm btn-default fl dbl'}
            //         onClick = {() => i === this.state.showGamesForCategory ? this.setState({showGamesForCategory: -1}) : this.setCat(i)}
            //     >
            //         {Assets.Show + ' ' + Assets.Games.toLowerCase()}
            //     </button>
            // )
            cc.push(
                <button
                disabled = {this.state.level.categoryContributions.contributions[i].games.length === 0}
                    title = {Assets.Manage + ' ' + Assets.games + Assets._in_ + Assets.Category.toLowerCase() + Assets._with_ + Assets.Contribution.toLowerCase() + ' ' + this.state.level.categoryContributions.contributions[i].percentage.toString() + '%'}
                    key = {i.toString() + 'h'}
                    onClick = {() => {
                        if (this.state.level.categoryContributions.contributions[i].percentage === this.state.selectedTable) {
                            this.setState ({selectedTable: -1, selectedGameId: '-1', selectedGameName: '-1'})
                        } else {
                            this.setState ({selectedTable: this.state.level.categoryContributions.contributions[i].percentage})
                        }
                        
                    }}
                    style = {{borderRadius: '0px'}}
                    className = {this.state.level.categoryContributions.contributions[i].percentage === this.state.selectedTable ? 'fl dbl btn btn-primary btn-sm mr' : 'fl dbl btn btn-default btn-sm mr'}
                >
                    {Assets.ManageGames}
                </button>
            )

            if (this.state.level.categoryContributions.contributions[i].games) {
                cc.push(
                    <button
                        key = "6"
                        className = "btn btn-sm btn-danger dbl fl"
                        onClick = {() => {
                            const level: ILevelDefinition = Object.assign({}, this.state.level)
                            delete(level.categoryContributions.contributions[i].games)
                            this.setState({ level/*, showGamesForCategory: -1*/})
                        }}
                    >
                        {Assets.Ignore + ' ' + Assets.Games.toLowerCase() + ' ' + Assets.setting}
                    </button>
                )
            }
            // cc.push(<button onClick = {() => this.addGame(i)} key = "3" className = "fl dbl btn btn-sm btn-primary"><FontAwesomeIcon icon = {faPlus}/></button>)

            // if (i === this.state.showGamesForCategory) {
            cc.push(
                <div
                    title = {Assets.Upload + ' ' + Assets.List.toLowerCase()}
                    key = "7"
                    style = {{padding: '8px', fontSize: '14px'}}
                    className = "fl label label-primary mr"
                >
                    <FontAwesomeIcon icon = {faUpload}/>
                </div>
            )

            cc.push(
                <input
                    accept = "text/plain"
                    type = "file"
                    key = "9"
                    className = "uploadLogoWrapper dbl fl"
                    style = {{width: '200px', height: '30px', marginTop: '0px', marginRight: '5px'}}
                    onClick = {(event) => event.target = null}
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            // console.log (reader.result)
                            const decodedData: string = base64.decode((reader.result as string).replace('data:text/plain;base64,',''))
                            // console.log (decodedData)
                            const DecodedData: string[] = decodedData.split('\n')
                            // console.log (DecodedData)
                            // const bonus: Bonus = Object.assign ({}, Main.Instance.BonusState.bonus)
                            
                            const games: string[] = []
                            for (const item of DecodedData) {
                                if (item.length > 0) {
                                    games.push(item.replace('\r', ''))
                                }
                            }
                            // console.log ('games.length', games.length)
                            const Games: string[] = []

                            const level: ILevelDefinition = Object.assign({}, this.state.level)
                            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                                // if (Assets_.GameCategoryIncluded[this.state.level.categoryContributions.contributions[i].category - 1] === item.Category.trim()) {
                                for (const Item of games) {
                                    if (item.Id === +Item) {
                                        Games.push(Item)
                                        break
                                    }
                                }
                                // }
                            }
                            // console.log ('Games.length ', Games.length)
                            this.state.level.categoryContributions.contributions[i].games = Games
                            this.setState({level})
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                />
            )
            if (this.state.level.categoryContributions.contributions[i].games && this.state.level.categoryContributions.contributions[i].games.length > 0) {
                cc.push(
                    <button
                        onClick = {() => this.downloadCSVList(i)}
                        title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.List.toLowerCase() + ': ' + this.state.level.categoryContributions.contributions[i].games.length + ' ' + Assets.Game_s}
                        key = "12"
                        style = {{color: '#337ab7', background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px', marginRight: '5px'}}
                        className = "fl btn btn-default btn-sm ml"
                    >
                        <FontAwesomeIcon icon = {faFileCsv}/>
                    </button>
                )

                cc.push(
                    <button
                        onClick = {() => this.downloadList(i)}
                        title = {Assets.Download + ' ' + Assets.List.toLowerCase() + ': ' + this.state.level.categoryContributions.contributions[i].games.length + ' ' + Assets.Game_s}
                        key = "10"
                        style = {{padding: '4px 8px 3px', fontSize: '14px'}}
                        className = "fl btn btn-primary btn-sm mr0"
                    >
                        <FontAwesomeIcon icon = {faDownload}/>&nbsp;{this.state.level.categoryContributions.contributions[i].games.length + ' ' + Assets.Game_s}
                    </button>
                )
            }
            {/*
            if (i === this.state.showGamesForCategory && this.state.level.categoryContributions.contributions[i].games) {
                cc.push (<div key = "11" style = {{fontSize: '16px', fontWeight: 700, position: 'absolute', top: '-4px', right: '0px'}}>{Assets.GamesSelection}</div>)
                cc.push (
                    <select
                        className = "fl dbl"
                        multiple = {true}
                        key = "4"
                        onChange = {(e) => {
                            // console.log (e.target.value)
                            const level: ILevelDefinition = Object.assign({}, this.state.level)
                            // find if it is present or not
                            let found: number = -1
                            if (level.categoryContributions.contributions[i].games) {
                                for (let k: number = 0; k < level.categoryContributions.contributions[i].games.length; k++) {
                                    if (level.categoryContributions.contributions[i].games[k] === e.target.value) {
                                        found = k
                                        break
                                    }
                                }
                            }

                            if (found === -1) {
                                if (level.categoryContributions.contributions[i].games === undefined) {
                                    level.categoryContributions.contributions[i].games = []
                                }
                                level.categoryContributions.contributions[i].games.push(e.target.value)
                            } else {
                                level.categoryContributions.contributions[i].games.splice(found, 1)
                            }
                            this.setState({ level })
                        }}
                        style = {{paddingLeft: '5px', background: '#fff', position: 'absolute', top: '18px', right: '0px', bottom: '5px', minHeight: '130px'}}
                        value = {this.state.level.categoryContributions.contributions[i].games}
                    >
                        {this.getGamesOptions(i)}
                    </select>
                )
            */}
            c.push(<div key = {i.toString()} className="row admin-users-row mb register-input"><div className = "clearfix">{cc}</div></div>)
        }
        return c
    }

    public tableFilters (value, accessor) {
        const filtered = Object.assign ([], this.state.filtered)  
        let insertNewFilter: boolean = true
    
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if(filter.id === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1)
                    } else {
                        filter.value = value
                    }
                    insertNewFilter = false
                }
            })
        }
    
        if (insertNewFilter) {
            filtered.push({id: accessor, value})
        }
        this.setState({ filtered })
    }

    public getAutocomplete(missingGames?: any[]) {
        const options: JSX.Element[] = []
        const data: ILevelDefinition = Object.assign({}, this.state.level)
        // console.log(data)
        if (this.state.selectedTable === -1) {
            for (const item of missingGames) {
                options.push(<option key = {item.Id}>{item.Name}</option>)
            }
            
        } else {
            for (const item of data.categoryContributions.contributions) {
                if (item.games && item.percentage === this.state.selectedTable) {
                    for (const Item of item.games) {
                        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                            if (+Item === Main.Instance.DatabaseDataState.GamesStreamAll[i].Id) {
                                options.push(<option key = {Main.Instance.DatabaseDataState.GamesStreamAll[i].Id + '_' + Main.Instance.DatabaseDataState.GamesStreamAll[i].Name}>{Main.Instance.DatabaseDataState.GamesStreamAll[i].Name}</option>)
                                break
                            }
                        }
                    }
                }
            }
        }
        // console.log(options)
        return (
            <datalist id = "names" style = {{color: 'white'}}>
                {options}
            </datalist>
        )
    }

    public getGamesByContribution(): {games: number[], superfluousGames: string[]} {
        const games: number[] = []
        const superfluousGames: string[] = []
        if (Main.Instance.DatabaseDataState.GamesStreamAll && this.state.level.categoryContributions) {
            for (const item of this.state.level.categoryContributions.contributions) {
                if (item.games && item.percentage === this.state.selectedTable) {
                    /*
                    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                        for (const Item of item.games) {
                            if (+Item === Main.Instance.DatabaseDataState.GamesStreamAll[i].Id) {
                                games.push(Main.Instance.DatabaseDataState.GamesStreamAll[i])
                                break
                            }
                        }
                    }
                    */
                    for (const Item of item.games) {
                        let Break: boolean = false
                        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                            if (+Item === Main.Instance.DatabaseDataState.GamesStreamAll[i].Id) {
                                games.push(Main.Instance.DatabaseDataState.GamesStreamAll[i])
                                Break = true
                                break
                            }
                        }
                        if (!Break) {
                            superfluousGames.push(Item)
                        }
                    }
                }
            }
        }
        return {games, superfluousGames}
    }

    public getRemoveGameButton(row) {
        return (
            <div className= "td-center">
                <button
                    title = {Assets.Remove + ' ' + Assets.Game.toLowerCase()}
                    key = {row.original.Id}
                    onClick = {() => {
                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                        
                        for (const item of level.categoryContributions.contributions) {
                            if (item.percentage === this.state.selectedTable) {
                                for (let i: number = 0; i < item.games.length; i++) {
                                    if (+item.games[i] === row.original.Id) {
                                        item.games.splice(i, 1)
                                        break
                                    }
                                }
                            }
                        }
                        
                        this.setState({ level })
                    }}
                    style = {{borderRadius: '0px'}}
                    className = "btn btn-danger btn-sm mr0"
                >
                    {Assets.Remove + ' ' + Assets.Game.toLowerCase()}
                </button>
            </div>
            )
    } 

    public getAddGameButton(row, percent: number): JSX.Element {
        // console.log(row)
        // console.log(row.original.Id)
        return (
        <div className= "td-center">
            <button
                className = {this.state.selectedTable === -1 ? 'btn btn-success btn-sm mr0' : 'btn btn-warning btn-sm mr0'}

                // title = {this.state.selectedTable === -1 ? Assets.Add + ' ' + Assets.Game.toLowerCase() : Assets.Move + ' ' + Assets.Game.toLowerCase()}
                key = {percent}
                onClick = {() => {
                    const data: ILevelDefinition = Object.assign({}, this.state.level)
                    let found: boolean = false
                    for (const item of data.categoryContributions.contributions) {
                        if (item.percentage === percent) {
                            found = true
                        }
                    }
                    if (!found) {data.categoryContributions.contributions.push({games: [], percentage: percent})}
                    for (const item of data.categoryContributions.contributions) {
                        if (item.percentage === percent) {
                            item.games.push(row.original.Id)
                        }
                        if (this.state.selectedTable === item.percentage) {
                            for (let i: number = 0; i < item.games.length; i++) {
                                if (row.original.Id === +item.games[i]) {
                                    item.games.splice(i, 1)
                                }
                            }
                        }
                    }
                    
                    this.setState({ level: data })
                }}
                style = {{borderRadius: '0px'}}
                // className = "btn btn-success btn-sm mr0"
            >
                {this.state.selectedTable === -1 ? Assets.Add + ' ' + Assets.Game.toLowerCase() : Assets.Change + Assets._to_}
            </button>
        </div>
        )
    }

    public getGamesLength(j: number): number {
        const array: number[] = Assets_.lists[j]
        // console.log (j)
        // console.log (array)

        return array.length
    }

    public DownloadList(j: number) {
        const array: number[] = Assets_.lists[j]
        let s: string = ''
        for (let i: number = 0; i < array.length; i++) {
            s += array[i]
            if (i < array.length - 1) {
                s += '\n'
            }
        }
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/plain'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.Games + Assets._with_ + Assets_.Lists[j] + '%' + Assets_._txt
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }

    public DownloadCSVList(j: number) {
        const array: number[] = Assets_.lists[j]
        let s: string = Main.Instance.DatabaseDataState.GamesStreamAll ? 'Id,Name,Category,Provider\n' : 'Id\n'
        for (let i: number = 0; i < array.length; i++) {
            s += array[i]
            let found: boolean = false
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
                for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                    if (item.Id === array[i]) {
                        s += ',' + item.Name + ',' + item.Category + ',' + item.Provider
                        found = true
                        break
                    }
                }
            }
            if (!found) { s += ',,,'}
            if (i < array.length - 1) {
                s += '\n'
            }
        }
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.Games + Assets._with_ + Assets_.Lists[j] + '%' + Assets_._csv
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }

    public downloadList(j: number) {
        // console.log ('downloadList ', array.length)
        const array: string[] = this.state.level.categoryContributions.contributions[j].games
        let s: string = ''
        for (let i: number = 0; i < array.length; i++) {
            s += array[i]
            if (i < array.length - 1) {
                s += '\n'
            }
        }
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/plain'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.Games + Assets._for_ /*+ Assets.Category.toLowerCase() + ' ' + Assets_.GameCategoryIncluded[this.state.level.categoryContributions.contributions[j].category - 1] + '_' */+ this.state.level.categoryContributions.contributions[j].percentage + '%' + Assets_._txt
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }

    public downloadCSVList(j: number) {
        // console.log ('downloadCSVList ', array.length)
        const array: string[] = this.state.level.categoryContributions.contributions[j].games
        let s: string = Main.Instance.DatabaseDataState.GamesStreamAll ? 'Id,Name,Category,Provider\n' : 'Id\n'
        for (let i: number = 0; i < array.length; i++) {
            let found: boolean = false
            s += array[i]
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
                for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                    if (item.Id === +array[i]) {
                        s += ',' + item.Name + ',' + item.Category + ',' + item.Provider
                        found = true
                        break
                    }
                }
            }
            if (!found) { s += ',,,'}
            if (i < array.length - 1) {
                s += '\n'
            }
        }
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.Games + Assets._for_ /*+ Assets.Category.toLowerCase() + ' ' + Assets_.GameCategoryIncluded[this.state.level.categoryContributions.contributions[j].category - 1] + '_'*/ + this.state.level.categoryContributions.contributions[j].percentage + '%' + Assets_._csv
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }
/*
    public getGames(i: number): JSX.Element[] {
        const g: JSX.Element[] = []
        if (this.state.level.categoryContributions.contributions[i].games) {
            for (let j: number = 0; j < this.state.level.categoryContributions.contributions[i].games.length; j++) {
                g.push(
                    <select
                        multiple = {true}
                        key = {j.toString()}
                        onChange = {(e) => {
                            const level: ILevelDefinition = Object.assign({}, this.state.level)
                            // find if it is present or not
                            let found: number = -1
                            for (let k: number = 0; k < level.categoryContributions.contributions[i].games.length; k++) {
                                if (level.categoryContributions.contributions[i].games[k] === e.target.value) {
                                    found = k
                                    break
                                } 
                            }
                            if (found === -1) {
                                level.categoryContributions.contributions[i].games.push(e.target.value)
                            } else {
                                level.categoryContributions.contributions[i].games.splice(found, 1)
                            }
                            this.setState({ level })
                        }}
                        style = {{height: '30px', background: '#fff'}}
                        value = {this.state.level.categoryContributions.contributions[i].games}
                    >
                        {this.getGamesOptions(i)}
                    </select>
                )

                g.push(
                    <button
                      onClick = {() => {
                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                        level.categoryContributions.contributions[i].games.splice(j, 1)
                        this.setState({ level })
                      }}
                      style = {{marginLeft: '2px'}}
                      className = "btn btn-sm btn-danger"
                      key = {j.toString() + '_'}
                    >
                        <FontAwesomeIcon icon = {faTrashAlt}/>
                    </button>
                )
            }
        }
        return g
    }
*/
    public getGamesOptions(i: number): JSX.Element[] {
/*
        if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
            console.log (Main.Instance.GeneralState.general.gameCategoryContribution)
        }
*/
        // console.log (i)
        const options: JSX.Element[] = []
        // options.push(<option key = "0" value = "0">{Assets.SelectGame}</option>)
/*
        if (i === 2 || i === 8 || i === 9) {
            if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
                for (const item of Main.Instance.GeneralState.general.gameCategoryContribution) {
                    if (item.category === Assets_.GameCategoryIncluded[i]) {
                        if (item.games) {
                            for (const Item of item.games) {
                                options.push(<option key = {Item.id.toString()} value = {Item.id.toString()}>{Item.name}</option>)
                            }
                        }
                    }
                }
            }
        } else
*/
        // console.log ('Category ' + Assets_.GameCategoryIncluded[this.state.level.categoryContributions.contributions[i].category - 1])
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                // if (Assets_.GameCategoryIncluded[this.state.level.categoryContributions.contributions[i].category - 1] === item.Category.trim()) {
                options.push(<option key = {item.Id} value = {item.Id}>({item.Id})&nbsp;{item.Name}</option>)
                // }
            }
        }
        return options
    }
/*
    public addGame(i: number) {
        const level: ILevelDefinition = Object.assign({}, this.state.level)
        if (level.categoryContributions.contributions[i].games === undefined) {
            level.categoryContributions.contributions[i].games = []
        }
        level.categoryContributions.contributions[i].games.push('0')
        this.setState({ level })
    }
*/

    public getMissingGames(): {n: number, c: string, a: any[]} {
        let missingGames: string = ''
        let MissingGames: number = 0
        const missingGamesArray: any[] = []
        // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
        // console.log (this.state.level.categoryContributions.contributions)
        if (Main.Instance.DatabaseDataState.GamesStreamAll && this.state.level.categoryContributions.contributions) {
            for (let k: number = 0; k < Main.Instance.DatabaseDataState.GamesStreamAll.length; k++) {
                const gameid: number = Main.Instance.DatabaseDataState.GamesStreamAll[k].Id
                let found: boolean = false
                for (let i: number = 0; i < this.state.level.categoryContributions.contributions.length; i++) {
                    if (this.state.level.categoryContributions.contributions[i].games) {
                        for (let j: number = 0; j < this.state.level.categoryContributions.contributions[i].games.length; j++) {
                            if (gameid === +this.state.level.categoryContributions.contributions[i].games[j]) {
                                found = true
                                break
                            }
                        }
                    }
                }
                if (!found) {
                    missingGamesArray.push(Main.Instance.DatabaseDataState.GamesStreamAll[k])
                    MissingGames++
                    if (missingGames.length > 0) {missingGames += ', '}
                    missingGames += gameid
                    missingGames += ' (' 
                    missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Name
                    missingGames += ', '
                    missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Category
                    missingGames += ', '
                    missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Provider
                    missingGames += ')'
                }
            }
        }
        return {n: MissingGames, c: missingGames, a: missingGamesArray}
    }

    public render() {
        // console.log(Main.Instance.DatabaseDataState.levelsNew)
        const missingGames: {n: number, c: string, a: any[]} = this.getMissingGames()
        // this.state.level.categoryContributions.contributions
        const Columns = []
        if (this.state.level.categoryContributions && this.state.level.categoryContributions.contributions) {
            for (const item of this.state.level.categoryContributions.contributions) {
                // console.log (item)
                Columns.push ({
                    show: this.state.selectedTable !== item.percentage, width: 100, Header: item.percentage + '%', accessor: '', headerClassName: 'th-center', Cell: (row) => this.getAddGameButton(row, item.percentage)
                })
            }

            Columns.push (
                {show: this.state.selectedTable === -1, width: 210, Header: <><FontAwesomeIcon icon = {faExclamationTriangle}/>&nbsp;{Assets.Warning}</>, accessor: '', headerClassName: 'th-center', className: 'th-center', Cell: () => <div title = {Assets.ThisGameHasContribution0Desc} style = {{fontSize: '12px', padding: '8px 12px'}} className = "label label-danger">{Assets.ThisGameHasContribution0}</div>}
            )
        }

        const columns: any[] = [
            {Header: Assets.Game + ' ' + Assets.Information.toLowerCase(), headerClassName: 'th-center risk-default', columns:[
            {width: 55, Header: Assets.ID, accessor: 'Id', headerClassName: 'th-left'},
            {Header: Assets.Name, accessor: 'Name', headerClassName: 'th-left' , filterMethod: (filter, row) => {
                const id = filter.id
                return row[id] !== undefined ? (row[id].toLowerCase()) === (filter.value).toLowerCase() : true
            }},
            {width: 150, Header: Assets.Category, accessor: 'Category', headerClassName: 'th-left'},
            {width: 250, Header: Assets.Provider, accessor: 'Provider', headerClassName: 'th-left'},

            // {show: this.state.selectedTable !== 0, width: 150, Header: Assets.Contribution + ' 0%', accessor: '', headerClassName: 'th-center', Cell: (row) => this.getAddGameButton(row, 0)},
            // {show: this.state.selectedTable !== 10, width: 150, Header: Assets.Contribution + ' 10%', accessor: '', headerClassName: 'th-center', Cell: (row) => this.getAddGameButton(row, 10)},
            // {show: this.state.selectedTable !== 100, width: 150, Header: Assets.Contribution + ' 100%', accessor: '', headerClassName: 'th-center', Cell: (row) => this.getAddGameButton(row, 100)},
            // {show: this.state.selectedTable === 0 || this.state.selectedTable === 10 || this.state.selectedTable === 100, width: 150, Header: Assets.Remove + ' ' + Assets.Game.toLowerCase(), accessor: '', headerClassName: 'th-center', Cell: (row) => this.getRemoveGameButton(row)},
            ]},
        
        ]
        columns.push (
            {Header: Assets.Contribution, headerClassName: 'th-center risk-default-light', columns: Columns}
        )
    
        columns.push (
            {show: this.state.selectedTable !== -1, width: 150, Header: Assets.Remove + ' ' + Assets.Game.toLowerCase(), accessor: '', headerClassName: 'th-center', Cell: (row) => this.getRemoveGameButton(row)}
        )

        const gamesByContribution: {games: number[], superfluousGames: string[]} = this.getGamesByContribution()

        return (
            <div>
                <h3 className="level fl">{Assets.cLevel} </h3>
                <div className="clearfix" />
                <br />
                <div className="row admin-users-container clearfix">
                    <div
                        className={this.isInsert() ?
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel' :
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit'
                        }
                    >
                        <div className="register-input">
                            <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <h4>
                                {this.isInsert() ?
                                    Assets.AddLevel :
                                    Assets.EditLevel + ': ' + this.state.level.name
                                }
                            </h4>
                            
                            {!this.isInsert() ?
                                <button
                                    title={Assets.discardChanges}
                                    onClick={() => this.gotoInsertMode()}
                                    style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                                    className="btn btn-default btn-sm btn-plus"
                                >
                                    <i className={Assets_.faCancelButtonClass} />
                                </button>
                                : void 0}
                            </div>

                            <div className="row admin-users-row mb5">
                                <FormInput
                                    description={Assets.LevelName}
                                    narrow={true}
                                    value={this.state.level.name ? this.state.level.name : ''}
                                    ClassName="form-control register-input"
                                    type="text"
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.name = text
                                        this.setState({ level })
                                    }}
                                    placeholder={Assets.LevelName}
                                    icon={Assets_.faCogClass}
                                />
                                <FormInputNumber
                                    description={Assets.Level}
                                    placeholder={Assets.Level}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.hierarchy.toString() !== '0' ? this.state.level.hierarchy.toString() : ''}
                                    type="number"
                                    min="1"
                                    max="100"
                                    icon={Assets_.faHashtagClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.hierarchy = parseInt(text)
                                        this.setState({ level })
                                    }}
                                />
                                <FormSelect
                                    narrow={true}
                                    value={this.state.level.type.toString()}
                                    data={this.getTypeOptions()}
                                    ClassName="form-control"

                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.type = parseInt(text)
                                        this.setState({ level })
                                    }}
                                    icon={Assets_.faCogClass}
                                />
                                {/*
                                <span style={{ marginTop: '5px' }} className="mr fl dbl" title={Assets.BonusForConsecutiveDaysActivity}>
                                    {Assets.BonusForConsecutiveDaysActivity}:
                                </span>
                                 <Switch 
                                    className = {this.state.level.consecutiveDaysBonus.active ? 'fl mr dbl mt2' : 'fl mr dbl mt2 off-background-grey'}
                                    onClick = {() => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.consecutiveDaysBonus.active = !level.consecutiveDaysBonus.active
                                        this.setState({ level })
                                    }}
                                    on = {this.state.level.consecutiveDaysBonus.active}
                                /> */}
                                <ButtonIcon
                                    description={Assets.TokensPercentage}
                                    click={() => {
                                        let showTokensPercentage = this.state.showTokensPercentage
                                        showTokensPercentage = !showTokensPercentage
                                        this.setState({showTokensPercentage})
                                    }}
                                    ClassName={this.state.showTokensPercentage ? 'btn btn-sm btn-plus fr btn-success mr0' : this.state.level.tokensPercentage && (this.state.level.tokensPercentage.fromDeposits > 0 || this.state.level.tokensPercentage.fromWagers > 0 || this.state.level.tokensPercentage.minDeposit > 0 || this.state.level.tokensPercentage.active === true) ? 'btn btn-sm btn-plus fr btn-light-green mr0' : 'btn btn-sm btn-plus fr mr0'}
                                    Icon={faCoins as IconDefinition}
                                    text={Assets.Tokens}
                                />
                                <ButtonIcon
                                    description={Assets.Level + ' ' + Assets.Details.toLowerCase()}
                                    click={() => {
                                        let showLevelDetails = this.state.showLevelDetails
                                        showLevelDetails = !showLevelDetails
                                        this.setState({showLevelDetails})
                                    }}
                                    ClassName={this.state.showLevelDetails ? 'btn btn-sm btn-plus fr btn-success' : this.state.level.levelsDetails && (this.state.level.levelsDetails.depositsAmount > 0 || this.state.level.levelsDetails.depositsCount > 0 || this.state.level.levelsDetails.points > 0 || this.state.level.levelsDetails.rewardId > 0)  ? 'btn btn-sm btn-plus fr btn-light-green' : 'btn btn-sm btn-plus fr'}
                                    Icon={faCogs as IconDefinition}
                                    text={Assets.Details}
                                />
                                <ButtonIcon
                                    description={Assets.Custom}
                                    click={() => {
                                        let showCustom = this.state.showCustom
                                        showCustom = !showCustom
                                        this.setState({showCustom})
                                    }}
                                    ClassName={this.state.showCustom ? 'btn btn-sm btn-plus fr btn-success' : this.state.level.custom && (this.state.level.custom.monthlyCashbackPercentage > 0 || this.state.level.custom.shopDiscountPercentage > 0 || this.state.level.custom.weeklyCashbackPercentage > 0 || this.state.level.custom.accountManager === true) ? 'btn btn-sm btn-plus fr btn-light-green' : 'btn btn-sm btn-plus fr'}
                                    Icon={faWrench as IconDefinition}
                                    text={Assets.Custom}
                                />
                                <ButtonIcon
                                    description={Assets.Contributions}
                                    click={() => {
                                        let showCategoryContributions = this.state.showCategoryContributions
                                        showCategoryContributions = !showCategoryContributions
                                        this.setState({showCategoryContributions})
                                    }}
                                    ClassName={this.state.showCategoryContributions ? 'btn btn-sm btn-plus fr btn-success' : this.state.level.categoryContributions && (this.state.level.categoryContributions.contributions.length > 0 || this.state.level.categoryContributions.active === true) ? 'btn btn-sm btn-plus fr btn-light-green' : 'btn btn-sm btn-plus fr'}
                                    Icon={faPercent as IconDefinition}
                                    text={Assets.Contributions}
                                />
                            </div>

                            <div className="register-input row admin-users-row-area-small textarea-small">
                                <FormInputArea
                                    value={this.state.level.description}
                                    ClassName="form-control register-input"
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.description = text
                                        this.setState({ level })
                                    }}
                                    placeholder={Assets.Level + ' ' + Assets.Description.toLowerCase()}
                                    icon={Assets_.faCommentClass}
                                />
                            </div>
      
                            <div className="row admin-users-row mb5">
                                <ButtonIcon
                                    description={this.isInsert() ?
                                        Assets.cLevel :
                                        Assets.saveChanges
                                    }
                                    click={() => this.handleInsertUpdate()}
                                    ClassName={
                                        this.isInsert() ?
                                            'btn btn-warning btn-sm btn-plus fr mr0' :
                                            'btn btn-primary btn-sm btn-plus fr mr0'}
                                    Icon={faSave as IconDefinition}
                                    text={Assets.Save}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showCategoryContributions ?
                <div className="mt5">
                    <div className="row admin-users-container">
                        <div
                            className={this.isInsert() ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit'
                            }
                            style={{paddingTop: '10px', paddingBottom: '0px', minHeight: '200px'}}
                        >
                            
                            <div className="row admin-users-row mb register-input">
                                <ButtonIcon
                                    description={Assets.Contributions}
                                    click={() => {
                                        let showCategoryContributions = this.state.showCategoryContributions
                                        showCategoryContributions = !showCategoryContributions
                                        this.setState({showCategoryContributions})
                                    }}
                                    ClassName={this.state.showCategoryContributions ? 'btn btn-sm btn-plus fl btn-success' : this.state.level.categoryContributions.contributions.length > 0 || this.state.level.categoryContributions.active === true ? 'btn btn-sm btn-plus fl btn-light-green' : 'btn btn-sm btn-plus fl'}
                                    Icon={faPercent as IconDefinition}
                                    text={Assets.Contributions}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.Active} {/*Assets.category*/}{Assets.Contribution.toLowerCase()}
                                </span>
                                <Switch 
                                    className = {this.state.level.categoryContributions.active ? 'fl mr dbl mt2' : 'fl mr dbl mt2 off-background-grey' }
                                    onClick = {() => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.categoryContributions.active = !level.categoryContributions.active 
                                        this.setState({ level })
                                    }}
                                    on = {this.state.level.categoryContributions.active}
                                    
                                />
                                <div className = "clearfix">
                                    {this.state.level.categoryContributions && this.state.level.categoryContributions.active ? 
                                        <>
                                        {this.categoryContributions0()}
                                        {/*
                                        <div className = "fr clearfix">
                                            {this.categoryContributions1()}
                                        </div>
                                        */}
                                        </>
                                    : null}
                                </div>
                            </div>
                            {this.state.level.categoryContributions && this.state.level.categoryContributions.active ? 
                                <>
                                <div className="pr">
                                    {this.categoryContributions()}
                                </div>
                                {gamesByContribution.superfluousGames.length > 0 ?
                                <div className = "mb5">
                                    <span>{Assets.Superfluous + ' ' + Assets.Games.toLowerCase()}:&nbsp;</span>
                                    {this.getSuperfluousGames(gamesByContribution.superfluousGames)}
                                </div> : void 0}
                                <div className="row admin-users-row">
                                <div>
                                    <button
                                        disabled = {missingGames.n === 0}
                                        title = {Assets.Manage + ' ' + Assets.Missing.toLowerCase() + ' ' + Assets.games}
                                        key = "-5"
                                        onClick = {() => {
                                            this.setState({selectedTable: -1, selectedGameId: '-1', selectedGameName: '-1'})
                                            
                                        }}
                                        style = {{borderRadius: '0px'}}
                                        className = {this.state.selectedTable === -1 ? 'btn btn-primary btn-sm mr0 mb' : 'btn btn-default btn-sm mr0 mb'}
                                    >
                                        {Assets.Manage + ' ' + Assets.Missing.toLowerCase() + ' ' + Assets.games} ({missingGames.n})
                                    </button>
                                    {/* <div className = "bold">
                                        {Assets.Missing + ' ' + Assets.Games.toLowerCase()} ({missingGames.n})&nbsp;
                                    </div> */}
                                </div>
                                {this.state.selectedTable === -1 && missingGames.a.length > 0 || this.state.selectedTable !== -1 && gamesByContribution.games.length > 0 ?
                                <>
                                <div className = "pr">
                                    <input 
                                        style = {{height: '22px', width: '150px', paddingLeft: '5px', paddingRight: '5px'}} 
                                        placeholder = {Assets.Filter + ' ' + Assets.games + Assets._by_ + Assets.ID} 
                                        className = "mr mb" 
                                        onChange = {(e) => {
                                            this.tableFilters(e.target.value, 'Id');
                                            this.setState({selectedGameId: e.target.value, selectedGameName: '-1'})
                                        }} 
                                        type = "number" 
                                        value = {this.state.selectedGameId !== '-1' ? this.state.selectedGameId : ''}
                                    />
                                    {this.getAutocomplete(missingGames.a)}
                                    <input 
                                        style = {{height: '22px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                                        placeholder = {Assets.Filter + ' ' + Assets.games + Assets._by_ + Assets.Name.toLowerCase()} 
                                        className = "mr mb" 
                                        onChange = {(e) => {
                                            this.tableFilters(e.target.value, 'Name');
                                            this.setState({selectedGameId: '-1', selectedGameName: e.target.value})
                                        }} 
                                        list = "names"
                                        type = "text" 
                                        value = {this.state.selectedGameName !== '-1' ? this.state.selectedGameName : ''}
                                    />
                                    {this.state.selectedTable !== -1 ?
                                    <button
                                        style = {{position: 'absolute', top: '0px', right: '0px', marginRight: '0px', background: 'transparent', border: '0px', padding: '0px'}}
                                        onClick = {() => this.setState({selectedTable: -1})}
                                    >
                                        <FontAwesomeIcon icon = {faTimes}/>
                                    </button> : void 0}
                                </div>
                                <ReactTable
                                    data = {this.state.selectedTable === -1 ? missingGames.a : gamesByContribution.games}
                                    filtered = {this.state.filtered}
                                    onFilteredChange = {(filtered, column, value) => {
                                        this.tableFilters(value, column.accessor)
                                    }}
                                    defaultFilterMethod = {(filter, row, column) => {
                                        const id = filter.id
                                        if (+filter.value === 0) {
                                            return true
                                        }
                                        
                                        return row[id] !== undefined ? +(row[id]) === +filter.value : true
                                    }}
                                    columns = {columns}
                                    showPaginationBottom = {false}
                                    showPaginationTop = {true}
                                    defaultPageSize={10}
                                />
                                </>: void 0}
                                </div>
                                </>: null}
                        </div>
                    </div>
                </div>: null}
                
                {this.state.showCustom ?
                <div className="row admin-users-container mt5">
                    <div
                        className={this.isInsert() ?
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel' :
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit'
                        }
                        style={{paddingTop: '10px', paddingBottom: '0px'}}
                    >
                        <div className="row admin-users-row register-input">
                            <ButtonIcon
                                description={Assets.Custom}
                                click={() => {
                                    let showCustom = this.state.showCustom
                                    showCustom = !showCustom
                                    this.setState({showCustom})
                                }}
                                ClassName={this.state.showCustom ? 'btn btn-sm btn-plus fl btn-success' : this.state.level.custom.monthlyCashbackPercentage > 0 || this.state.level.custom.shopDiscountPercentage > 0 || this.state.level.custom.weeklyCashbackPercentage > 0 || this.state.level.custom.accountManager === true ? 'btn btn-sm btn-plus fl btn-light-green' : 'btn btn-sm btn-plus fl'}
                                Icon={faWrench as IconDefinition}
                                text={Assets.Custom}
                            />
                            <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                {Assets.Active} {Assets.Account} {Assets.Manager}:
                            </span>
                            
                            <Switch 
                                className = {this.state.level.custom.accountManager ? 'fl mr dbl mt2' : 'fl mr dbl mt2 off-background-grey'}
                                onClick = {() => {
                                    const level: ILevelDefinition = Object.assign({}, this.state.level)
                                    level.custom.accountManager = !level.custom.accountManager
                                    this.setState({ level })
                                }}
                                on = {this.state.level.custom.accountManager}
                            />
                            <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                {Assets.RecurringTypeShort[2] + ' ' + Assets.CashBack}:
                            </span>
                            <FormInputNumber
                                description={Assets.RecurringTypeShort[2] + ' ' + Assets.CashBackPercentage}
                                outerDiv="rows-per-table input-group ml0 pl0 mr"
                                ClassName="w70 h30"
                                value={this.state.level.custom.monthlyCashbackPercentage ? this.state.level.custom.monthlyCashbackPercentage.toString() : ''}
                                type="number"
                                min="0"
                                max="100"
                        
                                icon={Assets_.faPercentageClass}
                                onChange={(text) => {
                                    const level: ILevelDefinition = Object.assign({}, this.state.level)
                                    if (text === '') {
                                        level.custom.monthlyCashbackPercentage = undefined
                                    } else {
                                        level.custom.monthlyCashbackPercentage = parseInt(text)
                                    } 
                                    this.setState({ level })
                                }}

                            />
                            <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                {Assets.ShopDiscount}:
                            </span>
                            <FormInputNumber
                                description={Assets.ShopDiscount}
                                outerDiv="rows-per-table input-group ml0 pl0 mr"
                                ClassName="w70 h30"
                                value={this.state.level.custom.shopDiscountPercentage ? this.state.level.custom.shopDiscountPercentage.toString() : ''}
                                type="number"
                                min="0"
                                max="100"
                            
                                icon={Assets_.faPercentageClass}
                                onChange={(text) => {
                                    const level: ILevelDefinition = Object.assign({}, this.state.level)
                                    if (text === '') {
                                        level.custom.shopDiscountPercentage = undefined
                                    } else {
                                        level.custom.shopDiscountPercentage = parseInt(text)
                                    }
                                    this.setState({ level })
                                }}
                            />
                            <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                {Assets.RecurringTypeShort[1] + ' ' + Assets.CashBack}:
                            </span>
                            <FormInputNumber
                                description={Assets.RecurringTypeShort[1] + ' ' + Assets.CashBackPercentage}
                                outerDiv="rows-per-table input-group ml0 pl0 mr"
                                ClassName="w70 h30"
                                value={this.state.level.custom.weeklyCashbackPercentage ? this.state.level.custom.weeklyCashbackPercentage.toString() : ''}
                                type="number"
                                min="0"
                                max="100"
                        
                                icon={Assets_.faPercentageClass}
                                onChange={(text) => {
                                    const level: ILevelDefinition = Object.assign({}, this.state.level)
                                    if (text === '') {
                                        level.custom.weeklyCashbackPercentage = undefined
                                    } else {
                                        level.custom.weeklyCashbackPercentage = parseInt(text)
                                    }
                                    this.setState({ level })
                                }}
                            />
                        </div> 
                    </div>
                </div> : null}
                
                {this.state.showLevelDetails ?
                <div className="mt5">
                    <div className="row admin-users-container">
                        <div
                            className={this.isInsert() ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit'
                            }
                            style={{paddingTop: '10px', paddingBottom: '0px'}}
                        >
                            <div className="row admin-users-row register-input">
                                <ButtonIcon
                                    description={Assets.Level + ' ' + Assets.Details.toLowerCase()}
                                    click={() => {
                                        let showLevelDetails = this.state.showLevelDetails
                                        showLevelDetails = !showLevelDetails
                                        this.setState({showLevelDetails})
                                    }}
                                    ClassName={this.state.showLevelDetails ? 'btn btn-sm btn-plus fl btn-success' : this.state.level.levelsDetails.depositsAmount > 0 || this.state.level.levelsDetails.depositsCount > 0 || this.state.level.levelsDetails.points > 0 || this.state.level.levelsDetails.rewardId > 0  ? 'btn btn-sm btn-plus fl btn-light-green' : 'btn btn-sm btn-plus fl'}
                                    Icon={faCogs as IconDefinition}
                                    text={Assets.Details}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.DepositAmount}:
                                </span>
                                <FormInputNumber
                                    description={Assets.DepositAmount}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.levelsDetails.depositsAmount ? this.state.level.levelsDetails.depositsAmount.toString() : ''}
                                    type="number"
                                    min="0"
                                    icon={Assets_.faHashtagClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === '') {
                                            level.levelsDetails.depositsAmount = undefined
                                        } else {
                                            level.levelsDetails.depositsAmount = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.Deposit + ' ' + Assets.Count.toLowerCase()}:
                                </span>
                                <FormInputNumber
                                    description={Assets.Deposit + ' ' + Assets.Count.toLowerCase()}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.levelsDetails.depositsCount ? this.state.level.levelsDetails.depositsCount.toString() : ''}
                                    type="number"
                                    min="0"
                                    
                                    icon={Assets_.faHashtagClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === '') {
                                            level.levelsDetails.depositsCount = undefined
                                        } else {
                                            level.levelsDetails.depositsCount = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.Points}:
                                </span>
                                <FormInputNumber
                                    description={Assets.Points}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.levelsDetails.points ? this.state.level.levelsDetails.points.toString() : ''}
                                    type="number"
                                    min="0"
                                    
                                    icon={Assets_.faHashtagClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if(text === '') {
                                            level.levelsDetails.points = undefined
                                        } else {
                                            level.levelsDetails.points = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.LevelingUp + ' ' + Assets.Reward.toLowerCase()}:
                                </span>
                                {/* <FormInputNumber
                                    description={Assets.Reward}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.levelsDetails.rewardId ? this.state.level.levelsDetails.rewardId.toString() : ''}
                                    type="number"
                                    min="0"
                                    
                                    icon={Assets_.faHashtagClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === undefined) {
                                            level.levelsDetails.rewardId = undefined
                                        } else {
                                            level.levelsDetails.rewardId = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                /> */}
                                <FormSelect
                                    narrow={true}
                                    value={this.state.level.levelsDetails.rewardId ? this.state.level.levelsDetails.rewardId.toString() : ''}
                                    data={this.getRewardOptions()}
                                    ClassName="form-control"

                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === undefined) {
                                            level.levelsDetails.rewardId = undefined
                                        } else {
                                            level.levelsDetails.rewardId = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                    icon={Assets_.faRewardClass}
                                />
                            </div> 
                        </div>
                    </div>
                    
                </div> : null}
                {this.state.showTokensPercentage ?
                <div className="mt5">
                    <div className="row admin-users-container">
                        <div
                            className={this.isInsert() ?
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit'
                            }
                            style={{paddingTop: '10px', paddingBottom: '0px'}}
                        >
                            <div className="row admin-users-row mb register-input">
                                <ButtonIcon
                                    description={Assets.TokensPercentage}
                                    click={() => {
                                        let showTokensPercentage = this.state.showTokensPercentage
                                        showTokensPercentage = !showTokensPercentage
                                        this.setState({showTokensPercentage})
                                    }}
                                    ClassName={this.state.showTokensPercentage ? 'btn btn-sm btn-plus fl btn-success' : this.state.level.tokensPercentage.fromDeposits > 0 || this.state.level.tokensPercentage.fromWagers > 0 || this.state.level.tokensPercentage.minDeposit > 0 || this.state.level.tokensPercentage.active === true ? 'btn btn-sm btn-plus fl btn-light-green' : 'btn btn-sm btn-plus fl'}
                                    Icon={faCoins as IconDefinition}
                                    text={Assets.Tokens}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.Active} {Assets.TokensPercentage}:
                                </span>
                                <Switch 
                                    className = {this.state.level.tokensPercentage.active ? 'fl mr dbl mt2' : 'fl mr dbl mt2 off-background-grey'} 
                                    onClick = {() => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        level.tokensPercentage.active = !level.tokensPercentage.active
                                        this.setState({ level })
                                    }}
                                    on = {this.state.level.tokensPercentage.active}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.From + ' ' + Assets.Deposit.toLowerCase()}:
                                </span>
                                <FormInputNumber
                                    description={Assets.TokensPercentage + ' ' + Assets.From.toLowerCase() + ' ' + Assets.Deposit}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.tokensPercentage.fromDeposits ? this.state.level.tokensPercentage.fromDeposits.toString() : ''}
                                    type="number"
                                    min="1"
                                    max="100"
                                    
                                    icon={Assets_.faPercentageClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === '0') {
                                            level.tokensPercentage.fromDeposits = undefined
                                        } else {
                                            level.tokensPercentage.fromDeposits = parseInt(text)
                                        } 
                                        this.setState({ level })
                                    }}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.From + ' ' + Assets.Wager.toLowerCase()}:
                                </span>
                                <FormInputNumber
                                    description={Assets.TokensPercentage + ' ' + Assets.From.toLowerCase() + ' ' + Assets.Wager}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.tokensPercentage.fromWagers ? this.state.level.tokensPercentage.fromWagers.toString() : ''}
                                    type="number"
                                    min="1"
                                    max="100"
                                    
                                    icon={Assets_.faPercentageClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === '0') {
                                            level.tokensPercentage.fromWagers = undefined
                                        } else {
                                            level.tokensPercentage.fromWagers = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                />
                                <span style={{ marginLeft: '5px', marginTop: '5px' }} className="mr fl dbl">
                                    {Assets.From + ' ' + Assets.MinimumDeposit.toLowerCase()}:
                                </span>
                                <FormInputNumber
                                    description={Assets.TokensPercentage + ' ' + Assets.From.toLowerCase() + ' ' + Assets.MinimumDeposit}
                                    outerDiv="rows-per-table input-group ml0 pl0 mr"
                                    ClassName="w70 h30"
                                    value={this.state.level.tokensPercentage.minDeposit ? this.state.level.tokensPercentage.minDeposit.toString() : ''}
                                    type="number"
                                    min="1"
                                    max="100"
                                    
                                    icon={Assets_.faPercentageClass}
                                    onChange={(text) => {
                                        const level: ILevelDefinition = Object.assign({}, this.state.level)
                                        if (text === '0') {
                                            level.tokensPercentage.minDeposit = undefined
                                        } else {
                                            level.tokensPercentage.minDeposit = parseInt(text)
                                        }
                                        this.setState({ level })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div> : null}
                {/* console.log (Main.Instance.DatabaseDataState.levelsStream) */}
                <div className="mt5">
                    <ReactTable
                        data={Main.Instance.DatabaseDataState.levelsStream}
                        columns={[
                            { width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, this.handleEdit) },
                            {
                                Header: Assets.Levels,
                                headerClassName: 'risk-default',
                                columns: [
                                    { width: 30, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left' },
                                    { width: 100, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left' },
                                    { minWidth: 100, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left' },
                                    { width: 60, Header: Assets.Level, accessor: 'hierarchy', headerClassName: 'th-left' },
                                    { width: 100, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left', Cell: row => /*LevelTypes[row.value]*/ this.getTypeValue(row.value) },
                                    {/* maxWidth: 40, Header: <span title={Assets.BonusForConsecutiveDaysActivity}><FontAwesomeIcon icon={faMoneyBillWave} /></span>, accessor: 'consecutiveDaysBonus.active', headerClassName: 'th-center', Cell: row => <div className="tac"><FontAwesomeIcon style={{ color: row.value ? '#5cb85c' : '#d9534f' }} icon={row.value ? faCheck : faTimes} /></div> */},
                                ]
                            },
                            {
                                Header: Assets.Contributions,
                                headerClassName: 'risk-default-light',
                                columns: [
                                    { width: 40, Header: <span title={Assets.Active}><FontAwesomeIcon icon={faLightbulb} /></span>, accessor: 'categoryContributions.active', headerClassName: 'th-center', Cell: row => <div className="tac"><FontAwesomeIcon style={{ color: row.value ? '#5cb85c' : '#d9534f' }} icon={row.value ? faCheck : faTimes} /></div> },
                                    { width: 150, Header: Assets.Contribution, accessor: 'categoryContributions.contributions', headerClassName: 'th-left', Cell: row => this.getContributions(row.value) },
                                ]
                            },
                            {
                                Header: Assets.Custom,
                                headerClassName: 'risk-default',
                                columns: [
                                    { width: 40, Header: <span title={Assets.Account + ' ' + Assets.Manager.toLowerCase() + ' ' + Assets.Active.toLowerCase()}><FontAwesomeIcon icon={faCheckDouble} /></span>, accessor: 'custom.accountManager', headerClassName: 'th-center', Cell: row => <div className="tac"><FontAwesomeIcon style={{ color: row.value ? '#5cb85c' : '#d9534f' }} icon={row.value ? faCheck : faTimes} /></div> },
                                    { width: 60, Header: <span title={Assets.RecurringTypeShort[2] + ' ' + Assets.CashBackPercentage}>{'MCB'} {<FontAwesomeIcon icon={faPercentage} />}</span>, accessor: 'custom.monthlyCashbackPercentage', headerClassName: 'th-left' },
                                    { width: 60, Header: <span title={Assets.ShopDiscount + ' ' + Assets.Percentage.toLowerCase()}>{'SD'} {<FontAwesomeIcon icon={faPercentage} />}</span>, accessor: 'custom.shopDiscountPercentage', headerClassName: 'th-left' },
                                    { width: 60, Header: <span title={Assets.RecurringTypeShort[1] + ' ' + Assets.CashBackPercentage}>{'WCB'} {<FontAwesomeIcon icon={faPercentage} />}</span>, accessor: 'custom.weeklyCashbackPercentage', headerClassName: 'th-left' },
                                ]
                            },
                            {
                                Header: Assets.Level + ' ' + Assets.Details.toLowerCase(),
                                headerClassName: 'risk-default-light',
                                columns: [
                                    { width: 65, Header: <span title={Assets.DepositAmount}>{'DP amt.'}</span>, accessor: 'levelsDetails.depositsAmount', headerClassName: 'th-left' },
                                    { width: 60, Header: <span title={Assets.Deposit + ' ' + Assets.Count.toLowerCase()}>{'DP cnt.'}</span>, accessor: 'levelsDetails.depositsCount', headerClassName: 'th-left' },
                                    { width: 60, Header: <span title={Assets.Points}>{Assets.Points}</span>, accessor: 'levelsDetails.points', headerClassName: 'th-left' },
                                    { width: 150, className: 'th-left', Header: <span title = {Assets.LevelingUp + ' ' + Assets.Reward.toLowerCase()}>{Assets.LevelingUp}&nbsp;&nbsp;<FontAwesomeIcon icon={faGift} /></span>, accessor: 'levelsDetails.rewardId', headerClassName: 'th-left', Cell: row => this.getRowValue(row.value) },
                                ]
                            },
                            {
                                Header: Assets.TokensPercentage,
                                headerClassName: 'risk-default',
                                columns: [
                                    { width: 40, Header: <span title={Assets.Active}><FontAwesomeIcon icon={faLightbulb} /></span>, accessor: 'tokensPercentage.active', headerClassName: 'th-center', Cell: row => <div className="tac"><FontAwesomeIcon style={{ color: row.value ? '#5cb85c' : '#d9534f' }} icon={row.value ? faCheck : faTimes} /></div> },
                                    { width: 65, Header: <span title={Assets.From + ' ' + Assets.Deposit.toLowerCase()}>{Assets.Deposit}</span>, accessor: 'tokensPercentage.fromDeposits', headerClassName: 'th-left' },
                                    { width: 60, Header: <span title={Assets.From + ' ' + Assets.Wager.toLowerCase()}>{Assets.Wager}</span>, accessor: 'tokensPercentage.fromWagers', headerClassName: 'th-left' },
                                    { width: 65, Header: <span title={Assets.From + ' ' + Assets.MinDeposit.toLowerCase()}>{'DP ' + Assets.min + '.'}</span>, accessor: 'tokensPercentage.minDeposit', headerClassName: 'th-left' },
                                ]
                            },

                            { width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, this.handleDelete) },

                        ]}
                        showPaginationBottom={false}
                        pageSize={Main.Instance.DatabaseDataState.levelsNewCount}
                    />
                </div>
            </div>
        )
    }
}