import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
/*
import {isUserMaster, 
        isUserContractualPartner, 
        isUserSystem,
        isUserSiteOwner
        } from '../../../Logic/Utils'
*/
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function SettingsWrapper(props: React.Props<any>) {
    if (RouterObjects['settings'] && Main.Instance.Location == RouterObjects['settings'].path && Main.Instance.Back === false) {
        let path: string
        let Path: string
        if (RouterObjects['settings'].children['Generic_Settings']) {
            path = RouterObjects['settings'].path + RouterObjects['settings'].children['Generic_Settings'].path
            Path = RouterObjects['settings'].path.substring (1, RouterObjects['settings'].path.length) + ' -> ' + 
            RouterObjects['settings'].children['Generic_Settings'].path.substring(1, RouterObjects['settings'].children['Generic_Settings'].path.length)
        } else if (RouterObjects['settings'].children['Games_Settings']) {
            path = RouterObjects['settings'].path + RouterObjects['settings'].children['Games_Settings'].path
            Path = RouterObjects['settings'].path.substring (1, RouterObjects['settings'].path.length) + ' -> ' +
            RouterObjects['settings'].children['Games_Settings'].path.substring(1, RouterObjects['settings'].children['Games_Settings'].path.length)
        }
/*
        if (isUserSystem()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Masters'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' + 
            RouterObjects['user_Management'].children['Manage_Masters'].path.substring(1, RouterObjects['user_Management'].children['Manage_Masters'].path.length)
        } else if (isUserMaster()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path.substring(1, RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path.length)
        } else if (isUserContractualPartner() && !isUserSiteOwner()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Sub_Entities'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Manage_Sub_Entities'].path.substring(1, RouterObjects['user_Management'].children['Manage_Sub_Entities'].path.length)
        } else {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Admin_Users'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Manage_Admin_Users'].path.substring(1, RouterObjects['user_Management'].children['Manage_Admin_Users'].path.length)
        }
*/
        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )
        Logic.Type.New(Main.Instance, {Location: path})
    }
    return(<div>{props.children}</div>)
}