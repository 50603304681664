export const densityPallette: string[] = [/*'#fff5eb',*/'#fee6ce','#fdd0a2','#fdae6b','#fd8d3c','#f16913','#d94801','#a63603','#7f2704', '#581b02']
export const TOP: number[] = [5, 10, 20, 50]
export const ToP: number[] = [1, 2, 3, 4]
export const threshold2Pallette: number[] = [20, 40, 60, 80, 100, 120, 140, 160]
export const bubbleRadius: number[] = [8, 6, 4, 3]
export const bubbleRadiusLarge: number[] = [10, 8, 6, 5]
export const switzerlandColor: string = '#d52b1e'
export const switzerlandCantonsBorderColor: string = '#ffb6c1'
export const grandCasinoBadenColor: string = '#cb0027'
export const lakeColor: string = '#6b9def'
// export const DensityPallette: string[] = ['#fff5eb','#fee6ce','#fdd0a2','#fdae6b','#fd8d3c','#f16913','#d94801','#a63603','#7f2704', '#581b02']
export const jackpotsPopulationStatisticsYear: number = 2017
// DEPRECATED
export const thresholdPallette: number[] = [10, 50, 100, 200, 500, 1000, 2000, 4000]
export const SwitzerlandCantonsColors: string[] = [
    'rgb(255,118,0)', // orange
    'rgb(0,0,180)', // modra
    'rgb(0,154,37)', // zelena
    'rgb(255,255,0)', // rumena
    'rgb(255,0,0)', // rdeca
    'rgb(185,185,185)', // siva1
    'rgb(175,13,102)', // purple
    'rgb(146,248,70)', // lime
    'rgb(255,200,47)', // oker
    'rgb(235,235,222)', // siva2
    'rgb(55,19,112)', // viola
    'rgb(12,75,100)', // kovinsko modra
    'rgb(202,62,94)', // vinsko rdeca
    'rgb(100,100,100)', // siva3
    'rgb(205,145,63)', // svetlo rjava
    'rgb(121,33,135)', // viola2
    'rgb(175,155,50)', // olive temna
    'rgb(178,220,205)', // svetlo kovinsko modra
    'rgb(255,255,150)', // light rumena
    'rgb(37,70,25)', // temno zelena
    'rgb(83,140,208)', // modra
    'rgb(0,0,0)', // crna
    'rgb(255,152,213)', // pink
    'rgb(0,0,74)', // navy
    'rgb(175,200,74)', // svetlo olivna
    'rgb(63,25,12)', // temno rjava
/*    
    'rgb(0,0,180)',
    'rgb(175,13,102)',
    'rgb(146,248,70)',
    'rgb(255,200,47)',
    'rgb(255,118,0)',
    'rgb(185,185,185)',
    'rgb(235,235,222)',
    'rgb(100,100,100)',
    'rgb(255,255,0)',
    'rgb(55,19,112)',
    'rgb(255,255,150)',
    'rgb(202,62,94)',
    'rgb(205,145,63)',
    'rgb(12,75,100)',
    'rgb(255,0,0)',
    'rgb(175,155,50)',
    'rgb(178,220,205)',
    'rgb(37,70,25)',
    'rgb(121,33,135)',
    'rgb(83,140,208)',
    'rgb(0,154,37)',
    'rgb(0,0,0)',
    'rgb(255,152,213)',
    'rgb(0,0,74)',
    'rgb(175,200,74)',
    'rgb(63,25,12)',
*/
]

// tslint:disable-next-line:max-line-length
// const SwitzerlandCantonsColors: string[] = ['white', 'gray','silver','black','maroon','red','purple','fuchsia','green','lime','olive','yellow','navy','blue','teal','aqua','orange','indianred','lightcoral','salmon','darksalmon','lightsalmon','crimson','firebrick','darkred','pink','lightpink','hotpink','deeppink','mediumvioletred','palevioletred','coral','tomato','orangered','darkorange','gold','lightyellow','lemonchiffon','lightgoldenrodyellow','papayawhip','moccasin','peachpuff','palegoldenrod','khaki','darkkhaki','lavender','thistle','plum','violet','orchid','magenta','mediumorchid','mediumpurple','blueviolet','darkviolet','darkorchid','darkmagenta','indigo','slateblue','darkslateblue','mediumslateblue','greenyellow','chartreuse','lawngreen','limegreen','palegreen','lightgreen','mediumspringgreen','springgreen','mediumseagreen','seagreen','forestgreen','darkgreen','yellowgreen','olivedrab','darkolivegreen','mediumaquamarine','darkseagreen','lightseagreen','darkcyan','cyan','lightcyan','paleturquoise','aquamarine','turquoise','mediumturquoise','darkturquoise','cadetblue','steelblue','lightsteelblue','powderblue','lightblue','skyblue','lightskyblue','deepskyblue','dodgerblue','cornflowerblue','royalblue','mediumblue','darkblue','midnightblue','cornsilk','blanchedalmond','bisque','navajowhite','wheat','burlywood','tan','rosybrown','sandybrown','goldenrod','darkgoldenrod','peru','chocolate','saddlebrown','sienna','brown','snow','honeydew','mintcream','azure','aliceblue','ghostwhite','whitesmoke','seashell','beige','oldlace','floralwhite','ivory','antiquewhite','linen','lavenderblush','mistyrose','gainsboro','lightgrey','darkgray','dimgray','lightslategray','slategray','darkslategray']

// tslint:disable-next-line:max-line-length
/*
export const SwitzerlandCantonsColors: string[] = [
    'brown','maroon','red','green','navy',
    'blue','teal','orange','pink','darkorange',
    'gold','silver','darkmagenta','indigo','darkgreen',
    'mediumaquamarine','darkcyan','cyan', 'lavender', 'violet',
    'cadetblue','darkred','khaki','beige','darkgray',
    'fuchsia'
    ]
*/
/*
export const SwitzerlandCantonsColors: string[] = ['#5288ff',
    '#00d266',
    '#f57bff',
    '#007d28',
    '#a96bf6',
    '#969d00',
    '#0051af',
    '#93d86e',
    '#fd41af',
    '#01d39a',
    '#de006a',
    '#64dbab',
    '#d95302',
    '#00815e',
    '#ff6952',
    '#7f6ea1',
    '#efbf5d',
    '#7a3880',
    '#b3ca93',
    '#9c233a',
    '#e7b0ff',
    '#5a5a00',
    '#87365d',
    '#975a00',
    '#ff9e8b',
    '#9a2917']

export const SwitzerlandCantonsColors: string[] = ['#007653',
    '#b940c5',
    '#3dd961',
    '#8577ff',
    '#1b9700',
    '#ea005c',
    '#00e28e',
    '#ff68b5',
    '#008639',
    '#d29cff',
    '#aea800',
    '#018dea',
    '#f0c033',
    '#5b439a',
    '#608b00',
    '#9e135c',
    '#abd197',
    '#9e1f38',
    '#02b6e3',
    '#b84500',
    '#015d9f',
    '#bd7700',
    '#e4b7f0',
    '#ff7769',
    '#bc7480',
    '#ff7197']
*/
/*
export const SwitzerlandCantonsColors: string[] =     ['#5bc337',
    '#3841c2',
    '#00dc78',
    '#b052d9',
    '#b9d147',
    '#7378ff',
    '#5b8a00',
    '#ff64db',
    '#01a675',
    '#d10083',
    '#00d7d2',
    '#ef0f60',
    '#027df5',
    '#ca7f00',
    '#a3a0ff',
    '#c62c07',
    '#674092',
    '#dfc565',
    '#9e1758',
    '#485908',
    '#f5b2e0',
    '#ff7e3c',
    '#985f82',
    '#ff6b57',
    '#ad7b5a',
    '#962e17']
*/
/*

(A *)(0, 0, 180) blue
(B *)(175, 13, 102) red-violet
(C *)(146,248,70) green-yellow
(d *)(255, 200, 47) yellow-orange
(e *)(255,118,0) orange
(f *)(185,185,185) light-gray
(g *)(235,235,222) off-white
(h *)(100,100,100) gray
(i *)(255,255,0) yellow
(j *)(55,19,112) dark-purple
(k *)(255,255,150) light-yellow
(l *)(202,62,94) dark-pink
(m *)(205,145,63) dark-orange
(n *)(12,75,100) teal
(o *)(255,0,0) red
(p *)(175,155,50) dark-yellow
(q *)(0,0,0) black
(r *)(37,70,25) dark-green
(s *)(121,33,135) purple
(t *)(83,140,208) light-blue
(u *)(0,154,37) green
(v *)(178,220,205) cyan
(w *)(255,152,213) pink
(x *)(0,0,74) dark blue
(y *)(175,200,74) olive-green
(z *)(63,25,12) red-brown
*/