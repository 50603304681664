import * as React from 'react'
import {View} from '../View'
import { Main } from 'src/Logic/Main';
export function ContentWrapper(props:React.Props<any>): View {
    // console.log (Main.Instance.Location)
    return ( 
        <div className = "main_container">
            <div id = "page-content-wrapper">
                <div style = {{paddingRight: Main.Instance.Location === '/dashboard' ? '0px' : ''}} id = "page-content-wrapper1" className = "container-fluid">
                    {props.children[0]}
                </div>
            </div>
        </div>
    )
}
