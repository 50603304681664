import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInputNumber, FormInput/*, FormSelect*/} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import {AllowDecimalCharactersOnly/*, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner*/} from '../../../Logic/Utils'
import {AllowTimeCharactersOnly, gotoTop} from '../../../Logic/Utils'
// import {SingleDatePicker} from 'react-dates'
// import * as moment from 'moment'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave, faCalendar, faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as Config from '../../../Logic/Config'
import {getTimestamp, getWinners, getParticipants} from '../MainDashboard/Raffle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUsers, faTicketAlt, faCheck, faUserTie, faUser, faClock} from '@fortawesome/free-solid-svg-icons'
import {getRiskManager} from '../RiskComplianceStore/ResponsibleGaming'
import { SingleDatePicker } from 'react-dates';
import * as moment from 'moment'
import {RaffleData} from '../../../Logic/Database/DatabaseData'

export function getRafflesCount() {
    // DbRoutes.WsGetRaffles()
}
/*
function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.WsGetRaffles()
    }
}
*/
function getCompleted(completed: string) {
    return (
        <div
            title = {completed === 'true' ? Assets.PreviousRaffles : Assets.NextRaffles}
            style = {{position: 'absolute', width: '100%', height: '100%', textAlign: 'center', lineHeight: '48px'}}
            className = {completed === 'true'  ? 'Risk risk-Normal' : 'Risk risk-Severe'}
        >
            {completed === 'true' ? Assets.YesShort : Assets.NoShort}
        </div>
    )
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Raffle + ' ' + Assets.withName + ' "' + row.original.data.name + '"?')) {
            if (Main.Instance.RaffleState.pNum === (Math.trunc((Main.Instance.RaffleState.count - 2) / Main.Instance.RaffleState.pSize) + 1)) {
                DbRoutes.wsBasicDelete(Config.amRaffle, row.original.id)
                // BUG
                // DbRoutes.removeRaffle (row.original.id, DeleteLastPage)
            } else {
                DbRoutes.wsBasicDelete(Config.amRaffle, row.original.id) 
                // DbRoutes.removeRaffle (row.original.id, DeleteNotLastPage)
            }
        }
    }
}
/*
function updateName(name: string) {
    Logic.Type.New (Main.Instance.RaffleState, {name})    
}
*/
function handleEdit(row: any) {
    gotoTop()
    // console.log (row.original)
    if (row) {Logic.Type.New(Main.Instance.RaffleState, {mode: true, raffle: row.original, raffle_: JSON.stringify(row.original)})}
}

function handleInsertUpdate() {
/*
    let RaffleItem = {
        id : !Main.Instance.RaffleState.id ? '' : Main.Instance.RaffleState.id,
        name: !Main.Instance.RaffleState.name ? '' : Main.Instance.RaffleState.name,
        date: Main.Instance.RaffleState.date,
        type: Main.Instance.RaffleState.Raffle_type,
        conditions: Main.Instance.RaffleState.conditions
    }
*/
/*
    if (RaffleItem.conditions.minbet === undefined || RaffleItem.conditions.minbet.length === 0) {
        alert('MinBet cannot be 0.')
        return
    }
*/
/*
    if (RaffleItem.conditions === undefined) {
        alert('Raffle reward needs to be set.')
        return
    }
*/
/*
    if (isNaN(RaffleItem.conditions.minbet as any)) {
        alert ('Minimal bet is not formatted correctly')
        return
    }

    RaffleItem.conditions.minBet = parseFloat(RaffleItem.conditions.minbet)
    delete(RaffleItem.conditions.minbet)
*/
/*
    if (RaffleItem.conditions.currencies)
    for (const item of RaffleItem.conditions.currencies) if (item.minBet.length === 0 || +item.minBet === 0) {
        alert('Min. bet cannot be 0.')
        return
    }
*/

    // console.log (RaffleItem)
/*
    if (Main.Instance.RaffleState.name.length === 0) {
        alert (Assets.compulsoryName)
    } else if (Main.Instance.RaffleState.Raffle_type === undefined || Main.Instance.RaffleState.Raffle_type.length === 0 || 
                parseInt(Main.Instance.RaffleState.Raffle_type) === 0) {
        alert ('Raffle type needs to be selected.')
    } else if (Main.Instance.RaffleState.date === null || Main.Instance.RaffleState.date === undefined) {
        alert('Raffle date needs to be set.')
    } else if (Main.Instance.RaffleState.conditions === undefined && Main.Instance.RaffleState.conditions.rewardId === undefined
               || Main.Instance.RaffleState.conditions.rewardId === 0) {
        alert('Raffle reward needs to be set.')
*/
/*
    } else if (!validationFromTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationToTime) {
        alert (Assets.compulsoryCorrectTime)
 */
    if (Main.Instance.RaffleState.mode === false) {
        if (Main.Instance.RaffleState.date_string !== undefined && Main.Instance.RaffleState.time !== undefined && Main.Instance.RaffleState.date_string.length > 0 && Main.Instance.RaffleState.time.length === 5 && Main.Instance.RaffleState.time.charAt(2) === ':') {
            const raffleData: RaffleData = {
                completed: 'false',
                timestamp: Main.Instance.RaffleState.date_string.substring(0, 10) + ' ' + Main.Instance.RaffleState.time,
            }
            DbRoutes.wsBasicInsert(Config.amRaffle, raffleData/*Main.Instance.RaffleState.raffle.data*/)
            clearFields()
        } else if (Main.Instance.RaffleState.date_string === undefined || Main.Instance.RaffleState.date_string.length === 0) {
            alert (Assets.PleaseSelectRaffleDate)
        } else if (Main.Instance.RaffleState.time === undefined || Main.Instance.RaffleState.time.length === 0) {
            alert (Assets.PleaseSetRaffleTime)
        } else if (Main.Instance.RaffleState.time.length < 5 || Main.Instance.RaffleState.time.charAt(2) !== ':') {
            alert (Assets.PleaseSetRaffleTimeCorrectFormat)
        }
    } else {
        DbRoutes.wsBasicUpdate(Config.amRaffle, Main.Instance.RaffleState.raffle.id, Main.Instance.RaffleState.raffle.data)
    }
}

function getRaffles() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {RaffleGet: true})
    DbRoutes.wsBasicGet(Config.amRaffle)
}

function clearFields() {
    Logic.Type.New(Main.Instance.RaffleState, {
        mode : false,
        raffle: {
            id: 0,
            data: {
                completed: '',
                timestamp: null,
                mainWinners: [],
                secondaryWinners: [],
                participants: [],
                tickets: 0,
                executedBy: 0,
                executionTimestamp: null,
                mainWinnerNickname: null,
            },
        },
        date: null,
        time: '20:00',
        focusedInput: null,
        date_string: '',
        day_in_week: '',
    })
}

function gotoInsertMode() {
    // handleEdit
    if (JSON.stringify(Main.Instance.RaffleState.raffle) === Main.Instance.RaffleState.raffle_) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.RaffleState, {offset:(0).toString()})

    getRaffles()
    Logic.Type.New(Main.Instance.RaffleState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.RaffleState.pNum > 0) {

        Logic.Type.New(Main.Instance.RaffleState, {
            offset:((Main.Instance.RaffleState.pNum - 1) * 
            Main.Instance.RaffleState.pSize).toString()})
        getRaffles()

        Logic.Type.New(Main.Instance.RaffleState,{pNum: Main.Instance.RaffleState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.RaffleState.pNum + 1) * Main.Instance.RaffleState.pSize < 
    Main.Instance.RaffleState.count) {

        Logic.Type.New(Main.Instance.RaffleState, {
            offset: ((Main.Instance.RaffleState.pNum + 1) * 
            Main.Instance.RaffleState.pSize).toString()})
        getRaffles()
        
        Logic.Type.New(Main.Instance.RaffleState,{pNum: Main.Instance.RaffleState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.RaffleState.count <= Main.Instance.RaffleState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.RaffleState.count === 0) {

            Logic.Type.New(Main.Instance.RaffleState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.RaffleState.count - 1) / 
                    Main.Instance.RaffleState.pSize)
            Logic.Type.New(Main.Instance.RaffleState, {
                offset:(npNum * Main.Instance.RaffleState.pSize).toString()})
        }

        getRaffles()

        Logic.Type.New(Main.Instance.RaffleState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.RaffleState,{count:Main.Instance.RaffleState.count ++})
    LastPage()
}

export function Update() {
    clearFields()
    getRaffles()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.RaffleState,{count:Main.Instance.RaffleState.count --})
    LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.RaffleState,{count:Main.Instance.RaffleState.count --})
    getRaffles()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RaffleState, {pSize: parseInt(text)})
        FirstPage()
    }
}
/*
function SetMinRounds(text: string) {
    if (parseInt(text) > -1) {
        let conditions: Assets_.IConditions = Object.assign({}, Main.Instance.RaffleState.conditions)
        if (text.length > 0 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.minRounds = parseInt(text)
        Logic.Type.New (Main.Instance.RaffleState, {conditions})
    }   
}

function SetReward(text: string) {
    if (parseInt(text) > -1) {
        let conditions: Assets_.IConditions = Object.assign({}, Main.Instance.RaffleState.conditions)
        conditions.rewardId = +text
        Logic.Type.New (Main.Instance.RaffleState, {conditions})
    }   
}

function SetBigWin(text: string) {
    if (parseInt(text) > 0) {
        let conditions: Assets_.IConditions = Object.assign({}, Main.Instance.RaffleState.conditions)
        if (text.length > 0 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.bigWinValue = parseInt(text)
        Logic.Type.New (Main.Instance.RaffleState, {conditions})
    }   
}
*/
/*
function ApplyMasterSelect (text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect (text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect (text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getRafflesCount()
    }
}

function DateChange(date: moment.Moment) {

    let temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    Logic.Type.New(Main.Instance.RaffleState, {date_string: temp, date})
}

function SelectRaffleType(which: number) {
    Logic.Type.New (Main.Instance.RaffleState, {Raffle_type: which.toString()})
}

function getRaffleTypes(): JSX.Element[] {
    let ct:JSX.Element[] = []
    ct.push(
        <button
            title = {'Select the Raffle type'}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {0 === parseInt(Main.Instance.RaffleState.Raffle_type) ? 'btn btn-danger btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => SelectRaffleType(0)}
        >
            <i className = {Assets_.faRaffleClass}/>&nbsp;&nbsp;{'Raffle Type'}
        </button>
    )

    for (let i: number = 0; i < Assets.RaffleTypes.length; i++) {
        ct.push(
            <button
                title = {'Select the Raffle type'}
                style = {{borderRadius: '0px'}}
                key = {(i + 1).toString()}
                className = {i + 1 === parseInt(Main.Instance.RaffleState.Raffle_type) ? 'btn btn-primary btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectRaffleType(i+1)}
            >
                {Assets.RaffleTypes[i]}
            </button>
        )
    }

    return ct
}
*/
/*
function getMinByCurrency(): JSX.Element[] {
    let min: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.currenciesStream && 
        (!Main.Instance.RaffleState.conditions || !Main.Instance.RaffleState.conditions.currencies || Main.Instance.RaffleState.conditions.currencies.length === 0)) {
        let conditions: Assets_.IConditions = Main.Instance.RaffleState.conditions ? Object.assign({}, Main.Instance.RaffleState.conditions) : {}
        if (conditions.currencies === undefined) conditions.currencies = []
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            conditions.currencies.push ({code: Main.Instance.DatabaseDataState.currenciesStream[j]['code']})
        }
        Logic.Type.New (Main.Instance.RaffleState, {conditions})
    }
    if (Main.Instance.DatabaseDataState.currenciesStream &&
        Main.Instance.RaffleState.conditions && Main.Instance.RaffleState.conditions.currencies)
    for (let i: number = 0; i < Main.Instance.RaffleState.conditions.currencies.length; i++) {
        let code: string
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            if (Main.Instance.RaffleState.conditions.currencies[i]['code'] === Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                break
            }
        }
        if (code === undefined) {continue}
        // console.log (Main.Instance.RaffleState.conditions.currencies[i])
        // console.log (Main.Instance.RaffleState.conditions.currencies[i]['minBet'])
        min.push (
            <div key = {i.toString()} className = "row admin-users-row clearfix" style = {{position: 'relative', height: '35px'}}>
                <button 
                    className = {Main.Instance.RaffleState.conditions && Main.Instance.RaffleState.conditions.currencies && 
                                 Main.Instance.RaffleState.conditions.currencies[i] && Main.Instance.RaffleState.conditions.currencies[i]['minBet'] ? 
                                 'btn btn-success btn-xs fl' : 'btn btn-danger btn-xs fl'}
                    style = {{width: '50px', marginRight: '10px', height: '29px', fontSize: '16px', fontWeight: 700}}
                    onClick = {() => {
                        let conditions: Assets_.IConditions = Object.assign({}, Main.Instance.RaffleState.conditions)
                        if (conditions.currencies === undefined) conditions.currencies = []
                        if (conditions.currencies[i].minBet === undefined) conditions.currencies[i] = {code, minBet: '0'}
                        else conditions.currencies[i] = {code}
                        // console.log (conditions)
                        Logic.Type.New (Main.Instance.RaffleState, {conditions})
                    }}
                >
                    {code}
                </button>
                {Main.Instance.RaffleState.conditions && Main.Instance.RaffleState.conditions.currencies && 
                Main.Instance.RaffleState.conditions.currencies[i] && Main.Instance.RaffleState.conditions.currencies[i]['minBet'] ?
                    <FormInput
                        narrow = {true}
                        description = {Assets.MinimumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.RaffleState.conditions === undefined || Main.Instance.RaffleState.conditions.currencies[i] === undefined ||
                                Main.Instance.RaffleState.conditions.currencies[i].minBet === undefined ? '0' : Main.Instance.RaffleState.conditions.currencies[i].minBet}
                        type = "text"
                        iconText = {Assets.MinBetSize}
                        iconTextSize = "14px"
                        onChange = {text => SetMinimumBet(i, text)}
                    /> : void 0}                
            </div>
        )
    }
    return min
}
*/
/*
function SetMinimumBet(which: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        let conditions: Assets_.IConditions = Object.assign({}, Main.Instance.RaffleState.conditions)
        if (text.length > 1 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.currencies[which].minBet = text
        Logic.Type.New (Main.Instance.RaffleState, {conditions})
    }
}
*/
export function Raffles() {
    // console.log (Main.Instance.DatabaseDataState.raffle)
    DbRoutes.wsReinitialize()
/*    
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    let selectSite:string = Assets.SelectSiteGamingSite
    let siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
*/
    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets.reactDatesGreyColor}/>

    return (<div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.AddViewRaffles}
        </h3> 

        {/*isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"
            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {text => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0*/}

        {/*isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {text => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0*/}

        {/*!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {text => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0*/}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.RaffleState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>{Main.Instance.RaffleState.mode ? Assets.ViewTheRaffle + ': ' + Main.Instance.RaffleState.raffle.id : Assets.AddANewRaffle}</h4>
                        {Main.Instance.RaffleState.mode ?
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : void 0}
                    </div>
                    {Main.Instance.RaffleState.mode ?
                    <>
                    <div className = "row admin-users-row">
                        {Main.Instance.RaffleState.raffle.data.timestamp ?
                        <>
                        <div className = "fl mt5">{Assets.Scheduled + ':'}&nbsp;</div>
                        <FormInput
                            readOnly = {true}
                            description = {Assets.ScheduleTimestamp + ' (' + Assets_.UTC + ')'}
                            value = {getTimestamp(Main.Instance.RaffleState.raffle.data.timestamp)}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            // onChange = {text => void 0} 
                            placeholder = {Assets.pName} 
                            Icon = {faCalendarAlt}
                        />
                        </>: void 0}
                        {Main.Instance.RaffleState.raffle.data.completed === 'true'?
                        <>
                            <div className = "fl mt5">
                                <FontAwesomeIcon icon = {faUsers}/>:
                                &nbsp;
                                <span>{Main.Instance.RaffleState.raffle.data.participants ? Main.Instance.RaffleState.raffle.data.participants.length : Assets.Unknown}</span>
                                ,&nbsp;
                                <FontAwesomeIcon icon = {faTicketAlt}/>:
                                &nbsp;
                                <span>{Main.Instance.RaffleState.raffle.data.tickets ? Main.Instance.RaffleState.raffle.data.tickets : Assets.Unknown}</span>
                                &nbsp;
                            </div>
                            {Main.Instance.RaffleState.raffle.data.executionTimestamp ?
                            <>
                            <div className = "fl mt5">,&nbsp;{Assets.Executed + Assets._by_}&nbsp;</div>
                            <FormInput
                                readOnly = {true}
                                description = {Assets.ExecutionTimestamp + ' (' + Assets_.UTC + ')'}
                                value = {getTimestamp(Main.Instance.RaffleState.raffle.data.executionTimestamp, true)}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                // onChange = {text => void 0}
                                placeholder = {Assets.pName}
                                Icon = {faCalendar}
                            />
                            </> : void 0}
                            {Main.Instance.RaffleState.raffle.data.executedBy ?
                            <FormInput
                                readOnly = {true}
                                description = {Assets.ExecutedBy}
                                value = {getRiskManager(Main.Instance.RaffleState.raffle.data.executedBy)}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                // onChange = {text => void 0}
                                placeholder = {Assets.pName}
                                Icon = {faUserTie}
                            /> : void 0}

                            {Main.Instance.RaffleState.raffle.data.mainWinners ?
                            Main.Instance.RaffleState.raffle.data.mainWinners.length === 1 ?
                            <FormInput
                                readOnly = {true}
                                description = {Assets.MainPrizeWinner}
                                value = {Main.Instance.RaffleState.raffle.data.mainWinners[0].nickname}
                                ClassName = "form-control register-input" 
                                type = "text" 
                                // onChange = {text => void 0}
                                placeholder = {Assets.pName}
                                Icon = {faUser}
                            />
                            : <div title = {Assets.MainPrizeWinners} className = "fl mt5">{getWinners(Main.Instance.RaffleState.raffle.data.mainWinners)}</div>
                            : void 0}

                        </> : <span>{Assets.ThisRaffleIsNotCompletedYet}</span>}
                    </div>
                    <div className = "row admin-users-row">
                        {Main.Instance.RaffleState.raffle.data.secondaryWinners ?
                        <div className = "fl mt5">{Assets.SecondaryPrizeWinners}:&nbsp;{getWinners(Main.Instance.RaffleState.raffle.data.secondaryWinners)}</div> : void 0}
                    </div>                    
                    </> :
                    <>
                        <FormInput
                            readOnly = {true}
                            narrow = {true}
                            description = {Assets.DayInWeek}
                            value = {Main.Instance.RaffleState.day_in_week ? Main.Instance.RaffleState.day_in_week : ''}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            // onChange = {text => void 0}
                            placeholder = {Assets.DayInWeek}
                            Icon = {faCalendarAlt}
                        />
                        <div className = "fl mr">
                            <SingleDatePicker 
                                id = "date0"
                                placeholder = {Assets.Date}
                                date = {Main.Instance.RaffleState.date}
                                onDateChange = {(date) => DateChange(date)}
                                focused = {Main.Instance.RaffleState.focusedInput}
                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.RaffleState, {focusedInput: focused.focused})}
                                showDefaultInputIcon = {true}
                                showClearDate = {true}
                                isOutsideRange = {() => false}
                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                // customArrowIcon = {Assets_.customArrowIcon}
                                customCloseIcon = {Assets_.customCloseIcon}
                                numberOfMonths = {1}
                            />
                        </div>
                        <FormInput
                            narrow = {true}
                            description = {Assets.ScheduledTime}
                            value = {Main.Instance.RaffleState.time ? Main.Instance.RaffleState.time : ''}
                            ClassName = "form-control register-input"
                            type = "text"
                            onChange = {(text) => setTime(text)}
                            placeholder = {Assets.ScheduledTime}
                            Icon = {faClock}
                        />
                    </>}
                {/*
                <FormInput
                    description = "Raffle name"
                    value = {!Main.Instance.RaffleState.name ? '' :
                            Main.Instance.RaffleState.name} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {text => updateName(text)} 
                    placeholder = {Assets.pName} 
                    icon = {Assets_.faRaffleClass}
                />

                <div className = "fl mr singledatepicker">
                    <SingleDatePicker
                        id = "date9"
                        placeholder = {Assets.Date}
                        date = {Main.Instance.RaffleState.date}
                        onDateChange = {date => DateChange(date)}
                        focused = {Main.Instance.RaffleState.focusedInput}
                        onFocusChange = {focused => Logic.Type.New(Main.Instance.RaffleState, {focusedInput: focused.focused})}
                        showDefaultInputIcon = {true}
                        showClearDate = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets.dateDisplayFormatdmy}
                        customCloseIcon = {Assets_.customCloseIcon}
                        numberOfMonths = {1}
                        isDayHighlighted = {(date) => HighlightToday(date)}
                    /> 
                </div>
                */}
                {/*getRaffleTypes()*/}
            
            {/*<div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>*/}
            {/*parseInt(Main.Instance.RaffleState.Raffle_type) === 1 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 2 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 3 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 4 ?
            <FormInputNumber
                description = {Assets.MinimumNumberOfRounds}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName= "h30 w60"
                value = {Main.Instance.RaffleState.conditions && Main.Instance.RaffleState.conditions.minRounds ? Main.Instance.RaffleState.conditions.minRounds.toString() : '0'}
                type = "number"
                min = "0"
                iconText = {Assets.MinRounds}
                iconTextS = "14px"
                onChange = {text => SetMinRounds(text)}
                // noIconTextFix = {true}
            /> : void 0*/}

            {/*parseInt(Main.Instance.RaffleState.Raffle_type) === 1 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 2 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 3 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 4 ?
            <FormSelect
                rewardFor = {4}
                level = {true}
                wide = {true}
                mt0 = {true}
                value = {Main.Instance.RaffleState.conditions && Main.Instance.RaffleState.conditions.rewardId ? 
                        Main.Instance.RaffleState.conditions.rewardId.toString() : '0'}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "form-control"
                o1 = {Assets.SelectReward}
                o2 = {Assets.SelectReward}
                onChange = {text => SetReward(text)}
                icon = {Assets_.faRewardClass}
                /> : void 0*/}

            {/*parseInt(Main.Instance.RaffleState.Raffle_type) === 4 ?
            <FormInputNumber
                description = "Big win value"
                outerDiv = "rows-per-table input-group fl mr ml pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.RaffleState.conditions === undefined || Main.Instance.RaffleState.conditions.bigWinValue === undefined ? '15' :
                         Main.Instance.RaffleState.conditions.bigWinValue.toString()}  
                type = "number" 
                min = "1"
                iconText = "Big win x"
                iconTextS = "14px"
                onChange = {text => SetBigWin(text)}
            /> : void 0*/}
   
            {/*</div>*/}
            {/*parseInt(Main.Instance.RaffleState.Raffle_type) === 1 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 2 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 3 ||
            parseInt(Main.Instance.RaffleState.Raffle_type) === 4 ?            
            getMinByCurrency() : void 0*/}
            {Main.Instance.RaffleState.mode ? void 0 :
            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.RaffleState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RaffleState.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>}
            </div>
            </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.RaffleState.pSize ? '' : Main.Instance.RaffleState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            {/*
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RaffleState.filter_Raffle_type ? '0' : Main.Instance.RaffleState.filter_Raffle_type}
                data = {Main.Instance.FrontendStoreState.Category5}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                onChange = {text => ApplyFilterType(text)} 
            />
            */}
            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.RaffleState.count ? Main.Instance.RaffleState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.RaffleState.count ? Main.Instance.RaffleState.pNum * 
                Main.Instance.RaffleState.pSize + 1 : 0}{' - '}
                {Main.Instance.RaffleState.count ? 
                ((Main.Instance.RaffleState.pNum + 1) * 
                Main.Instance.RaffleState.pSize > Main.Instance.RaffleState.count ? 
                Main.Instance.RaffleState.count : 
                (Main.Instance.RaffleState.pNum + 1) * Main.Instance.RaffleState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.RaffleState.count ? 
            Main.Instance.RaffleState.pNum + 1 :0}{' / '}
            {Main.Instance.RaffleState.count ? 
            Math.trunc((Main.Instance.RaffleState.count - 1) /
            Main.Instance.RaffleState.pSize + 1) : 0}
        </span>
        </div>
        <div className = "pr">
        <h3>{Assets.ListRaffles}</h3>
        {/*console.log (Main.Instance.DatabaseDataState.raffle)*/}
        {Main.Instance.DatabaseDataState.raffle !== null && Main.Instance.DatabaseDataState.raffle !== undefined ?
        <>
            <div style = {{position: 'absolute',top: '0px', right: '0px'}} className = "clearfix">
                <button onClick = {() => Logic.Type.New (Main.Instance.RaffleState, {filterCompleted: undefined})} className = {Main.Instance.RaffleState.filterCompleted === undefined ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'} type = "button" style = {{float: 'right'}}>
                    {Assets.AnyRaffle}
                </button>
                <button onClick = {() => Logic.Type.New (Main.Instance.RaffleState, {filterCompleted: true})} className = {Main.Instance.RaffleState.filterCompleted === true ? 'btn btn-danger btn-xs' : 'btn btn-default btn-xs'} type = "button" style = {{float: 'right'}}>
                    {Assets.ScheduledRaffles}
                </button>

                <button onClick = {() => Logic.Type.New (Main.Instance.RaffleState, {filterCompleted: false})} className = {Main.Instance.RaffleState.filterCompleted === false ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'} type = "button" style = {{float: 'right'}}>
                    {Assets.CompletedRaffles}
                </button>
            </div>
            <ReactTable
                data = {Main.Instance.DatabaseDataState.raffle}
                filtered = {Main.Instance.RaffleState.filterCompleted === undefined ? [] : Main.Instance.RaffleState.filterCompleted === true ? [{id: 'data.completed', value: 'false'}] : [{id: 'data.completed', value: 'true'}]}
                columns = {[
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                    {width: 225, Header: Assets.ScheduleTimestamp + ' (' + Assets_.UTC + ')', accessor: 'data.timestamp', headerClassName: 'th-left', Cell: (row) => getTimestamp(row.value)},

                    {width: 120, Header: <span><FontAwesomeIcon icon = {faUsers}/>&nbsp;{Assets.Participants}</span>, accessor: 'data.participants', headerClassName: 'th-left', Cell: (row) =>
                    row.value === undefined ? <span>-</span> :
                    <span title = {getParticipants(row.value)}>{row.value.length},&nbsp;<FontAwesomeIcon icon = {faTicketAlt}/>&nbsp;{row.original.data.tickets !== undefined ? row.original.data.tickets : ''}</span>},

                    {width: 225, Header: Assets.ExecutionTimestamp + ' (' + Assets_.UTC + ')', accessor: 'data.executionTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? getTimestamp(row.value, true) : '-'},

                    {width: 120, Header: Assets.ExecutedBy, accessor: 'data.executedBy', headerClassName: 'th-left', Cell: (row) => <span><FontAwesomeIcon icon = {faUserTie}/>&nbsp;{getRiskManager(row.value)}</span>},

                    {width: 120, Header: Assets.MainWinner, accessor: 'data.mainWinners', headerClassName: 'th-left', Cell: (row) => row.value === undefined ? '-' : row.value.length === 1 ? <span><FontAwesomeIcon icon = {faUser}/>&nbsp;{row.value[0].nickname}</span> : getWinners(row.value)},

                    {Header: Assets.SecondaryWinners, accessor: 'data.secondaryWinners', headerClassName: 'th-left', Cell: (row) => row.value ? getWinners(row.value) : '-'},

                    {width: 32, Header: <FontAwesomeIcon title = {Assets.Issue} icon = {faCheck}/>, accessor: 'data.completed', headerClassName: 'th-center', className: 'dashboard-item-latest', Cell: (row) => getCompleted(row.value)},

                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={100}
            />
        </> : void 0}
        </div>
        </div>
    </div>)
}

function DateChange(date: moment.Moment) {
    const date_string: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    const day_in_week: string = date ? Assets.DaysInWeek[date.day()] : ''
    Logic.Type.New(Main.Instance.RaffleState, {day_in_week, date_string, date})
}

function setTime(time: string) {
    if (AllowTimeCharactersOnly(time, false)) {
        Logic.Type.New(Main.Instance.RaffleState, {time})
    }
}
/*
function HighlightToday(date: moment.Moment): boolean {
    if (date.format(Assets.dateDisplayFormat) === (new Date()).toISOString().substring(0, 10)) return true
    return false
}
*/
/*
function ApplyFilterType(text:string) {
    Logic.Type.New(Main.Instance.RaffleState, {filter_Raffle_type: text})
    FirstPage()
}
*/