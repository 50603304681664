import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2, RadioButton3, FormInputNumber, FormSelect, FormInputArea} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner} from '../../../Logic/Utils' 
import {Circle} from 'rc-progress'
import ReactTable from 'react-table'
import {EditButton, DeleteButton, getBanner, TB, Image} from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getGamesCount() {

    if (Main.Instance.FrontendGamesState.countCalled === false) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {countCalled:true})
        DbRoutes.loadData()
        DbRoutes.GetGames()
        DbRoutes.GetBanner()
    }
}

function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetGames()
        FirstPage()
    }
}
/*
function handleDelete(rowData:string[]) {
    if (rowData) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Games + ' ' + Assets.withName + ' "' + rowData[1] + '"?')) {

            if (Main.Instance.FrontendGamesState.pNum === 
            (Math.trunc((Main.Instance.FrontendGamesState.count - 2) / 
            Main.Instance.FrontendGamesState.pSize) + 1)) {
                DbRoutes.RemoveGames (rowData[0].toString(), DeleteLastPage)
            } else {
                DbRoutes.RemoveGames (rowData[0].toString(), DeleteNotLastPage)
            }
        }
    }
}
*/
function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Games + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {

            if (Main.Instance.FrontendGamesState.pNum === 
            (Math.trunc((Main.Instance.FrontendGamesState.count - 2) / 
            Main.Instance.FrontendGamesState.pSize) + 1)) {
                DbRoutes.RemoveGames (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.RemoveGames (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}


// function handleToggle (rowData:string[]) {if (rowData) {DbRoutes.Toggle('.games SET active = ', rowData[0], rowData[16], getGamesCount)}}
function toggleActive(row: any) {if (row) {DbRoutes.Toggle('.games SET active = ', row.original.id, row.original.active, getGamesCount)}}

function handleEdit(row: any) { 
    gotoTop()
    if (row) {
        let active:string
        active = row.original.active.toString() === 'true' ? '1' : '2'
        const winLines: string[] = Object.assign([], row.original.win_lines)
        let winlines: string  = ''
        for (const item of winLines) {
            if (winlines.length > 0) { winlines += ', ' }
            winlines += item
        }
    
        Logic.Type.New(Main.Instance.FrontendGamesState, {
            mode: true,
            id: row.original.id.toString(), 
            name: row.original.name,
            name_saved: row.original.name,
            version: row.original.version,
            version_saved: row.original.version,
            gameid: row.original.gameid,
            gameid_saved: row.original.gameid,
            provider: row.original.studio,
            provider_saved: row.original.studio,
            info: row.original.info,
            info_saved: row.original.info,
            thumbnail: row.original.thumbnail,
            thumbnail_saved: row.original.thumbnail,
            background: row.original.background,
            background_saved: row.original.background,
            link: row.original.folder,
            link_saved: row.original.folder,
            banner: row.original.banner,
            banner_saved: row.original.banner,
            group: row.original.group,
            group_saved: row.original.group,
            idx: row.original.index,
            idx_saved: row.original.index,
            provider_id: row.original.provider_id,
            provider_id_saved: row.original.provider_id,
            reels: row.original.reels,
            reels_saved: row.original.reels,
            lines: row.original.lines,
            lines_saved: row.original.lines,
            payout: row.original.payout,
            payout_saved: row.original.payout,
            active,
            active_saved: active,
            winlines,
            winlines_saved: winlines,
            symbols: row.original.symbols,
            symbols_saved: row.original.symbols,
            mystery: row.original.mystery !== null && row.original.mystery.toString() === 'true',
            mystery_saved: row.original.mystery !== null && row.original.mystery.toString() === 'true',
            scatter: row.original.scatter !== null && row.original.scatter.toString() === 'true',
            scatter_saved: row.original.scatter !== null && row.original.scatter.toString() === 'true',
            wild: row.original.wild !== null && row.original.wild.toString() === 'true',
            wild_saved: row.original.wild !== null && row.original.wild.toString() === 'true',
            free_spins: row.original.free_spins !== null && row.original.free_spins.toString() === 'true',
            free_spins_saved: row.original.free_spins !== null && row.original.free_spins.toString() === 'true',
        })
    }
}
/*
function handleEdit(rowData:string[]) {
    if (rowData) {
        let active:string
        active = rowData[16].toString() === 'true' ? '1' : '2'
        let winLines: string[] = Object.assign([], rowData[17])
        let winlines: string  = ''
        for (let item of winLines) {
            if (winlines.length > 0) winlines += ', '
            winlines += item
        }
    
        Logic.Type.New(Main.Instance.FrontendGamesState, {
            mode: true,
            id: rowData[0], 
            name: rowData[1],
            name_saved: rowData[1],
            version: rowData[2],
            version_saved: rowData[2],
            gameid: rowData[3],
            gameid_saved: rowData[3],
            provider: rowData[4],
            provider_saved: rowData[4],
            info: rowData[5],
            info_saved: rowData[5],
            thumbnail: rowData[6],
            thumbnail_saved: rowData[6],
            background: rowData[7],
            background_saved: rowData[7],
            link: rowData[8],
            link_saved: rowData[8],
            banner: rowData[9],
            banner_saved: rowData[9],
            group: parseInt(rowData[10]),
            group_saved: parseInt(rowData[10]),
            idx: parseInt(rowData[11]),
            idx_saved: parseInt(rowData[11]),
            provider_id: parseInt(rowData[12]),
            provider_id_saved: parseInt(rowData[12]),
            reels: parseInt(rowData[13]),
            reels_saved: parseInt(rowData[13]),
            lines: parseInt(rowData[14]),
            lines_saved: parseInt(rowData[14]),
            payout: rowData[15],
            payout_saved: rowData[15],
            active: active,
            active_saved: active,
            winlines,
            winlines_saved: winlines,
            symbols: parseInt(rowData[18]),
            symbols_saved: parseInt(rowData[18]),
            mystery: rowData[19] !== null && rowData[19].toString() === 'true',
            mystery_saved: rowData[19] !== null && rowData[19].toString() === 'true',
            scatter: rowData[20] !== null && rowData[20].toString() === 'true',
            scatter_saved: rowData[20] !== null && rowData[20].toString() === 'true',
            wild: rowData[21] !== null && rowData[21].toString() === 'true',
            wild_saved: rowData[21] !== null && rowData[21].toString() === 'true',
            free_spins: rowData[22] !== null && rowData[22].toString() === 'true',
            free_spins_saved: rowData[22] !== null && rowData[22].toString() === 'true'
        })
    }
}

*/

function removeImage(which: number) {
    if (Main.Instance.FrontendGamesState.id !== undefined && parseInt(Main.Instance.FrontendGamesState.id) !== 0
    && confirm (Assets.confirmRemoveImage)) {
        if (which === 0) {
            Logic.Type.New (Main.Instance.FrontendGamesState, {thumbnail: Assets_.zeroPng})
            DbRoutes.Action('.games SET thumbnail = ' + Assets_.zeroPng,
                            Main.Instance.FrontendGamesState.id,
                            getGamesCount)
        } else if (which === 1) {
            Logic.Type.New (Main.Instance.FrontendGamesState, {background: Assets_.zeroPng})
            DbRoutes.Action('.games SET background = ' + Assets_.zeroPng,
                            Main.Instance.FrontendGamesState.id,
                            getGamesCount)
        }
    }
}

function handleInsertUpdate() {
/*
    let winLines: string[] = Main.Instance.FrontendGamesState.winlines
    let winlines: number[] = []
    for (let item of winLines) {
        if (!isNaN(item as any)) {
            winlines.push (parseInt(item))
        }
    }
*/
    const gamesItem = {
        id : !Main.Instance.FrontendGamesState.id ? '' : Main.Instance.FrontendGamesState.id,
        name : !Main.Instance.FrontendGamesState.name ? '' : Main.Instance.FrontendGamesState.name,

        thumbnail : 
        Main.Instance.FrontendGamesState.thumbnail ? Main.Instance.FrontendGamesState.thumbnail : Assets_.zeroPng,
        background : 
        Main.Instance.FrontendGamesState.background ? Main.Instance.FrontendGamesState.background : Assets_.zeroPng,

        site_id: Main.Instance.FrontendStoreState.site_id,
        link : !Main.Instance.FrontendGamesState.link ? '' : Main.Instance.FrontendGamesState.link,
        banner : !Main.Instance.FrontendGamesState.banner ? '0' : Main.Instance.FrontendGamesState.banner,
        version : !Main.Instance.FrontendGamesState.version ? '' : Main.Instance.FrontendGamesState.version,
        group : !Main.Instance.FrontendGamesState.banner ? 0 : Main.Instance.FrontendGamesState.group,
        idx : !Main.Instance.FrontendGamesState.idx ? 0 : Main.Instance.FrontendGamesState.idx,
        provider_id : !Main.Instance.FrontendGamesState.provider_id ? '0' : Main.Instance.FrontendGamesState.provider_id,
        reels : !Main.Instance.FrontendGamesState.reels ? '0' : Main.Instance.FrontendGamesState.reels,
        lines : !Main.Instance.FrontendGamesState.lines ? '0' : Main.Instance.FrontendGamesState.lines,
        payout : !Main.Instance.FrontendGamesState.payout ? '' : Main.Instance.FrontendGamesState.payout,
        gameid : !Main.Instance.FrontendGamesState.gameid ? '' : Main.Instance.FrontendGamesState.gameid,
        active : !Main.Instance.FrontendGamesState.active ? '1' : Main.Instance.FrontendGamesState.active,
        provider : !Main.Instance.FrontendGamesState.provider ? '' : Main.Instance.FrontendGamesState.provider,
        info : !Main.Instance.FrontendGamesState.info ? '' : Main.Instance.FrontendGamesState.info,

        win_lines : !Main.Instance.FrontendGamesState.winlines || Main.Instance.FrontendGamesState.winlines.length===0 ? '{}' :
        '{' + Main.Instance.FrontendGamesState.winlines + '}',
        symbols : !Main.Instance.FrontendGamesState.symbols ? 0 : Main.Instance.FrontendGamesState.symbols,
        mystery : !Main.Instance.FrontendGamesState.mystery ? false : Main.Instance.FrontendGamesState.mystery,
        scatter : !Main.Instance.FrontendGamesState.scatter ? false : Main.Instance.FrontendGamesState.scatter,
        wild : !Main.Instance.FrontendGamesState.wild ? false : Main.Instance.FrontendGamesState.wild,
        free_spins : !Main.Instance.FrontendGamesState.free_spins ? false : Main.Instance.FrontendGamesState.free_spins,
    }

    if (parseInt(gamesItem.active) === 1) { gamesItem.active = 'true' }
    if (parseInt(gamesItem.active) === 2) { gamesItem.active = 'false' }

    if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
        return
    } else if (Main.Instance.FrontendGamesState.mode === false) {
        DbRoutes.insertGames (gamesItem, InsertLastPage)
    } else {
        DbRoutes.updateGames (gamesItem, Update)
    }
}

function updateInfo(text: string) {
    // let info = Object.assign([], Main.Instance.FrontendGamesState.info)
    // info[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.FrontendGamesState, {info: text})    
}


function ApplyFilterActive(text:string) {
    
    Logic.Type.New(Main.Instance.FrontendGamesState, {filter_active:text})
    FirstPage()
}

function getGames() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {gamesStream : undefined})
}

function  clearFields() {

    Logic.Type.New(Main.Instance.FrontendGamesState, {
        name: '',
        mode : false,
        active: '1',
        background: Assets_.zeroPng,
        thumbnail: Assets_.zeroPng,
        link: '',
        version: '',
        group: 0,
        idx: 0,
        provider_id: 0,
        lines: 0,
        reels: 0,
        payout: '',
        provider: '',
        assets_provider: '',
        gameid: '',
        info: '',
        winlines: '',
        symbols: 0,
        mystery: false,
        scatter: false,
        wild: false,
        free_spins: false,
        // provider_id: '0'
        // [Assets_.zeroPng,Assets_.zeroPng,Assets_.zeroPng,Assets_.zeroPng]
    })
}

function gotoInsertMode() {

    if (
        Main.Instance.FrontendGamesState.name === Main.Instance.FrontendGamesState.name_saved
        && Main.Instance.FrontendGamesState.thumbnail === Main.Instance.FrontendGamesState.thumbnail_saved
        && Main.Instance.FrontendGamesState.background === Main.Instance.FrontendGamesState.background_saved
        && Main.Instance.FrontendGamesState.link === Main.Instance.FrontendGamesState.link_saved
        && parseInt(Main.Instance.FrontendGamesState.banner) === parseInt(Main.Instance.FrontendGamesState.banner_saved)
        && Main.Instance.FrontendGamesState.group === Main.Instance.FrontendGamesState.group_saved
        && Main.Instance.FrontendGamesState.idx === Main.Instance.FrontendGamesState.idx_saved
        // && Main.Instance.FrontendGamesState.provider_id === Main.Instance.FrontendGamesState.provider_id_saved
        && Main.Instance.FrontendGamesState.provider === Main.Instance.FrontendGamesState.provider_saved
        && Main.Instance.FrontendGamesState.reels === Main.Instance.FrontendGamesState.reels_saved
        && Main.Instance.FrontendGamesState.lines === Main.Instance.FrontendGamesState.lines_saved
        && Main.Instance.FrontendGamesState.payout === Main.Instance.FrontendGamesState.payout_saved
        && Main.Instance.FrontendGamesState.gameid === Main.Instance.FrontendGamesState.gameid_saved
        && Main.Instance.FrontendGamesState.info === Main.Instance.FrontendGamesState.info_saved
        && Main.Instance.FrontendGamesState.winlines === Main.Instance.FrontendGamesState.winlines_saved
        && Main.Instance.FrontendGamesState.symbols === Main.Instance.FrontendGamesState.symbols_saved
        && Main.Instance.FrontendGamesState.mystery === Main.Instance.FrontendGamesState.mystery_saved
        && Main.Instance.FrontendGamesState.scatter === Main.Instance.FrontendGamesState.scatter_saved
        && Main.Instance.FrontendGamesState.wild === Main.Instance.FrontendGamesState.wild_saved
        && Main.Instance.FrontendGamesState.free_spins === Main.Instance.FrontendGamesState.free_spins_saved
        && parseInt(Main.Instance.FrontendGamesState.active) === parseInt(Main.Instance.FrontendGamesState.active_saved)
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {

    Logic.Type.New(Main.Instance.FrontendGamesState,{offset:(0).toString()})

    getGames()
    Logic.Type.New(Main.Instance.FrontendGamesState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.FrontendGamesState.pNum > 0) {

        Logic.Type.New(Main.Instance.FrontendGamesState, {
            offset:((Main.Instance.FrontendGamesState.pNum - 1) * 
            Main.Instance.FrontendGamesState.pSize).toString()})
        getGames()

        Logic.Type.New(Main.Instance.FrontendGamesState,{pNum: Main.Instance.FrontendGamesState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.FrontendGamesState.pNum + 1) * Main.Instance.FrontendGamesState.pSize < 
    Main.Instance.FrontendGamesState.count) {

        Logic.Type.New(Main.Instance.FrontendGamesState, {
            offset: ((Main.Instance.FrontendGamesState.pNum + 1) * 
            Main.Instance.FrontendGamesState.pSize).toString()})
        getGames()
        
        Logic.Type.New(Main.Instance.FrontendGamesState,{pNum: Main.Instance.FrontendGamesState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.FrontendGamesState.count <= Main.Instance.FrontendGamesState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.FrontendGamesState.count === 0) {

            Logic.Type.New(Main.Instance.FrontendGamesState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.FrontendGamesState.count - 1) / 
                    Main.Instance.FrontendGamesState.pSize)
            Logic.Type.New(Main.Instance.FrontendGamesState, {
                offset:(npNum * Main.Instance.FrontendGamesState.pSize).toString()})
        }

        getGames()

        Logic.Type.New(Main.Instance.FrontendGamesState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.FrontendGamesState, {count:Main.Instance.FrontendGamesState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.FrontendGamesState,{count:Main.Instance.FrontendGamesState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.FrontendGamesState,{count:Main.Instance.FrontendGamesState.count --})
    getGames()
}

function Update() {
    clearFields()
    getGames()
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function GroupChanged(text:string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {group: parseInt(text)})
    }
}

function IndexChanged(text:string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {idx: parseInt(text)})
    }
}

function SymbolsChanged(text:string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {symbols: parseInt(text)})
    }
}

/*
function ProviderChanged(text:string) {
    
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {provider_id: parseInt(text)})
    }
}
*/
function ReelsChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {reels: parseInt(text)})
    }
}

function LinesChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.FrontendGamesState, {lines: parseInt(text)})
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.FrontendGamesState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream: undefined, sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,      
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getGamesCount()
    }
}
/*
function ApplyGamesSelect (text:string) {
    Logic.Type.New(Main.Instance.FrontendGamesState, {name:text})
}
*/
function removeFile(filename:string) {
    let clonestate = Main.Instance.FrontendGamesState.files
    clonestate = clonestate.filter((fo) =>fo.name != filename)
    Logic.Type.New(Main.Instance.FrontendGamesState, {files: clonestate})
}

export function Games() {
/*
    if (!Main.Instance.DatabaseDataState.gamesAllStream) {
        DbRoutes.GetAllGames()
    }
*/
    // if (Main.Instance.DatabaseDataState.gamesStream) console.log (Main.Instance.DatabaseDataState.gamesStream)
    if (Main.Instance.FrontendStoreState.first_time) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {
                first_time: false,
            })
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId),
                                                SelectSite)
            } /*else if (isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId))
            }*/
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    const FilesList:JSX.Element[] = []
    let i = 0
    // if(Main.Instance.FrontendGamesState.gameId||
    //         Main.Instance.FrontendGamesState.gameName)resetList()
    Main.Instance.FrontendGamesState.files.forEach((file) => {
        i++
        FilesList.push(
        <li key = {i} className = "list-group-item">
            {file.name}
            <span className = "pull-right">
                <button 
                    className = "btn btn-warning btndel" 
                    onClick = {() => removeFile(file.name)}
                >
                    <span 
                        style = {{marginTop: '-2px', display: 'block'}} 
                        className = {Assets_.faTrashButtonClass}
                    />
                </button>
            </span>
        </li>)
        },
    )   

    return (
    <div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.aeGames}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => {ApplyMasterSelect(text)}}
            icon = {Assets_.faMasterClass}
        /> : null}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => {ApplyOperatorSelect(text)}}
            icon = {Assets_.faCompanyClass}
        /> : null}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => {ApplySiteSelect(text)}}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
    </div>

    <div className = "clearfix"/>
    <div className = "row admin-users-container clearfix">
        <br/>
        <div 
            className = {Main.Instance.FrontendGamesState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
        >        
            <div className = "create-input">
                <div className = "row admin-users-row"  style = {{position: 'relative'}}>
                    <h4 className = "fl width50">
                        {Main.Instance.FrontendGamesState.mode ?
                        Assets.editGames + ': ' + Main.Instance.FrontendGamesState.name
                        :
                        Assets.addGames}
                    </h4>
                    {Main.Instance.FrontendStoreState.games_folder ?
                    <span
                        title = "games folder location"
                        style = {{ 
                            float:'right',
                            display:'block',
                            marginTop: '-5px',
                            /*height: '26px',*/
                            fontSize:'14px',
                            paddingLeft:'6px',
                            paddingRight:'6px',
                            paddingTop:'3px',
                            paddingBottom:'3px',
                        }}
                        className = "label label-info"
                    >
                        {Main.Instance.FrontendStoreState.games_folder}
                    </span> : void 0}

                    {Main.Instance.FrontendStoreState.games_folder ?
                    <span
                        title = "games folder location"
                        style = {{ 
                            float:'right',
                            display:'block',
                            marginTop: '-5px',
                            /*height: '26px',*/
                            fontSize:'14px',
                            paddingLeft:'6px',
                            paddingRight:'6px',
                            paddingTop:'3px',
                            paddingBottom:'3px',
                            marginRight: '10px',
                        }}
                        className = "label label-info"
                    >
                        Folder:
                    </span> : void 0}
                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.FrontendGamesState, {
                            upload_game_files: !Main.Instance.FrontendGamesState.upload_game_files})}
                        style = {{float: 'right', display: 'block', padding: '2px 4px 0px 2px', marginTop: '-7px'}}
                        className = {Main.Instance.FrontendGamesState.upload_game_files ?'btn btn-success':'btn btn-default'}
                    >
                        <i className = {Assets_.faPlusButtonClass}/>
                    </button>

                    {Main.Instance.FrontendGamesState.mode ? 
                    <ButtonIcon 
                        description = {Assets.discardChanges} 
                        click = {() => {gotoInsertMode()}} 
                        ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : <span/>}
                </div>

                <div className = "row admin-users-row">
                    <FormInput

                        value = {!Main.Instance.FrontendGamesState.name ? '' :
                                Main.Instance.FrontendGamesState.name} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {name:text})}} 
                        placeholder = {Assets.pName} 
                        icon = {Assets_.faGamingClass}
                    />

                    <RadioButton2
                        value = {!Main.Instance.FrontendGamesState.active ? '1' :
                                Main.Instance.FrontendGamesState.active} 
                        text = {Assets.Activity}
                        ClassName = "input-group-radiobutton2 margin-bottom-sm fr" 
                        title = {Assets.ActivityDesc}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {active:text})}} 
                    />

                    <FormSelect
                        narrow = {true}
                        floatRight = {true}
                        value = {!Main.Instance.FrontendGamesState.banner ? '0' : 
                                Main.Instance.FrontendGamesState.banner}
                        data = {Main.Instance.DatabaseDataState.bannerStreamAll}
                        ClassName = "form-control"
                        o1 = {Assets.SelectBanner} 
                        o2 = {Assets.SelectBanner}
                        onChange = {(text) => Logic.Type.New (Main.Instance.FrontendGamesState, {
                            banner: text})}
                        icon = {Assets_.faPuzzleFixedPieceClass}
                    />

                    <input  
                        accept = "image/png, image/jpg, image/jpeg, image/gif"
                        id = "thumbnail-input"
                        className = "UploadShopItemWrapper" 
                        type = "file" 
                        style = {{float: 'right', marginRight: '10px', width: '95px'}}
                        onChange = {(event) => { 
                            const cloneFile: File = event.target.files[0]
                            const reader = new FileReader()
                            reader.onload = (e) => {
                                Logic.Type.New (Main.Instance.FrontendGamesState, {thumbnail: reader.result as string})
                                // DbRoutes.updateThumbnail (reader.result)
                            }
                            reader.readAsDataURL(cloneFile);
                        }}
                        onClick = {(event) => {event.target = null}}
                    />
                    {Main.Instance.FrontendGamesState.thumbnail !== Assets_.zeroPng ?
                    <div 
                        title = {Assets.Background}
                        style = {{
                            // background:'#fff',
                            position: 'relative',
                            height:'62px',
                            maxWidth:'18%',
                            width:'100px',
                            marginTop:'-35px', 
                            marginRight:'10px',
                            float:'right',
                            display: 'block',
                        }}
                    >
                        {Main.Instance.FrontendGamesState.progressFiles > 0 && Main.Instance.FrontendGamesState.progressFiles<100 ?
                        <Circle 
                            style = {{height: '100px'}} 
                            percent = {Main.Instance.FrontendGamesState.progressFiles} 
                            strokeWidth = {2} 
                            strokeColor = "rgb(243,139,0)"
                        /> : void 0}
                        <img
                            title = {Assets.Thumbnail}
                            src = {Main.Instance.FrontendGamesState.thumbnail} 
                            style = {{
                                height:'100%',
                                width:'auto',
                                zIndex:9998,
                                display:'block', border:'1px solid #999',
                            }}
                        />
                        <button 
                            className = "btn btn-xs btn-nothing"
                            title = {Assets.RemoveImage}
                            style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px'}}
                            onClick = {() => removeImage(0)}
                        >
                            <i className = {Assets_.faCancelClass}/>
                        </button>
                    </div>
                    :
                    <span
                        title = {Assets.NoThumbnailDesc}
                        style = {{ 
                            float:'right',
                            display:'block',
                            marginRight:'10px',
                            marginTop: '2px',
                            height: '26px',
                            fontSize:'16px',
                            width:'100px',
                            // paddingLeft:'12px',
                            // paddingRight:'12px',
                            paddingTop:'5px',
                        }}
                        className = "label label-warning"
                    >
                        {Assets.NoThumbnail}
                    </span>}

                    {Main.Instance.FrontendGamesState.background !== Assets_.zeroPng ?
                    <div 
                        style = {{
                            // background:'#fff',
                            position: 'relative',
                            height:'62px',
                            maxWidth:'18%',
                            width:'110px',
                            marginTop:'-35px', 
                            marginRight:'10px',
                            float:'right',
                            display: 'block',
                        }}
                    >
                        <img 
                            title = {Assets.Background}
                            src = {Main.Instance.FrontendGamesState.background} 
                            style = {{
                                height:'100%',
                                width:'auto',
                                zIndex:9998,
                                display:'block',
                                border:'1px solid #999',
                            }}
                        />
                        <button 
                            className = "btn btn-xs btn-nothing"
                            title = {Assets.RemoveImage}
                            style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px'}}
                            onClick = {() => removeImage(1)}
                        >
                            <i className = {Assets_.faCancelClass}/>
                        </button>
                    </div>
                    :
                    <span
                        title = {Assets.NoBackgroundDesc}
                        style = {{ 
                            float:'right',
                            display:'block',
                            // width:'10%',
                            marginRight:'10px',
                            marginTop: '2px',
                            height: '26px',
                            fontSize:'16px',
                            width:'110px',
                            // paddingLeft:'12px',
                            // paddingRight:'12px',
                            paddingTop:'5px',
                        }}
                        className = "label label-warning"
                    >
                        {Assets.NoBackground}
                    </span>}
                    <input  
                        accept = "image/png, image/jpg, image/jpeg, image/gif"
                        id = "background-input"
                        className = "UploadShopItemWrapper" 
                        type = "file" 
                        style = {{float: 'right', marginRight: '10px', width:'190px'}}
                        onChange = {(event) => { 
                            const cloneFile: File = event.target.files[0]
                            const reader = new FileReader()
                            reader.onload = (e) => {
                                Logic.Type.New (Main.Instance.FrontendGamesState, {background: reader.result as string})
                                // DbRoutes.updateBackground (reader.result)
                            }
                            reader.readAsDataURL(cloneFile);
                        }}
                        onClick = {(event) => {event.target = null}}
                    />
                </div>

                <div className = "row admin-users-row">

                    <FormInput
                        // full = {true}
                        narrow = {true}
                        value = {!Main.Instance.FrontendGamesState.gameid ? '' :
                                Main.Instance.FrontendGamesState.gameid}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {gameid: text})}} 
                        placeholder = "Game ID"
                        icon = {Assets_.faGamingClass}
                    />
                    
                    <FormInput
                        value = {!Main.Instance.FrontendGamesState.link ? '' :
                                Main.Instance.FrontendGamesState.link}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {link: text})}} 
                        placeholder = {Assets.Link} 
                        icon = {Assets_.faMousePointerFwClass}
                    />

                    <FormInput
                        full = {true}
                        value = {!Main.Instance.FrontendGamesState.version ? '' : Main.Instance.FrontendGamesState.version} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {Logic.Type.New(Main.Instance.FrontendGamesState, {version: text})}} 
                        placeholder = {Assets.Version}
                        icon = {Assets_.faReportClass}
                    />

                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.group === undefined ? '0' :
                        Main.Instance.FrontendGamesState.group.toString()}
                        description = {Assets.Group}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "0"
                        onChange = {(text) => GroupChanged(text)} 
                        icon = {Assets_.faListGroupClass}
                    />

                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.idx === undefined ? '0' :
                        Main.Instance.FrontendGamesState.idx.toString()}
                        description = {Assets.Index}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "0"
                        onChange = {(text) => IndexChanged(text)} 
                        icon = {Assets_.faListOlClass}
                    />
                    {/*
                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.provider_id === undefined ? '0' :
                        Main.Instance.FrontendGamesState.provider_id.toString()}
                        description = {Assets.Provider}
                        outerDiv = "rows-per-table input-group fl ml0 pl0"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "0"
                        onChange = {text => ProviderChanged(text)} 
                        icon = {Assets_.faCompanyClass}
                    />
                    */}
                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.reels === undefined ? '1' :
                        Main.Instance.FrontendGamesState.reels.toString()}
                        description = {Assets.NumberOfReels}
                        outerDiv = "rows-per-table input-group fl pl0 mr"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "1"
                        onChange = {(text) => ReelsChanged(text)} 
                        icon = {Assets_.faHeightClass}
                    />
                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.lines === undefined ? '1' :
                        Main.Instance.FrontendGamesState.lines.toString()}
                        description = {Assets.NumberOfLines}
                        outerDiv = "rows-per-table input-group fl pl0 mr"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "1"
                        onChange = {(text) => LinesChanged(text)} 
                        icon = {Assets_.faWidthClass}
                    />
                    <FormInput
                        full = {true}
                        value = {!Main.Instance.FrontendGamesState.payout ? '' :
                                Main.Instance.FrontendGamesState.payout}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {payout: text})}} 
                        placeholder = {Assets.Payout} 
                        icon = {Assets_.faPercentageClass}
                    />
                </div>
                <div>
                    <FormInput
                        narrow = {true}
                        value = {!Main.Instance.FrontendGamesState.winlines ? '' :
                                Main.Instance.FrontendGamesState.winlines}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {winlines: text})}} 
                        placeholder = "Win lines" 
                        icon = {Assets_.faHamburgerClass}
                    />

                    <FormInput
                        value = {!Main.Instance.FrontendGamesState.provider ? '' :
                                Main.Instance.FrontendGamesState.provider}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.FrontendGamesState, {provider: text})}} 
                        placeholder = "Studio" 
                        icon = {Assets_.faImageFwClass}
                    />

                    <FormInputNumber  
                        value = {Main.Instance.FrontendGamesState.symbols === undefined ? '0' :
                        Main.Instance.FrontendGamesState.symbols.toString()}
                        outerDiv = "rows-per-table input-group fl pl0 mr"
                        ClassName = "h30 w60"
                        type = "number"
                        min = "0"
                        description = "Symbols"
                        onChange = {(text) => SymbolsChanged(text)} 
                        icon = {Assets_.faBorderWidthClass}
                    />

                    <RadioButton3
                        value = {Main.Instance.FrontendGamesState.mystery}
                        text = "mystery"
                        ClassName = "input-group-radiobutton3a margin-bottom-sm" 
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {
                            mystery: !Main.Instance.FrontendGamesState.mystery})}}
                    />

                    <RadioButton3
                        value = {Main.Instance.FrontendGamesState.scatter}
                        text = "scatter"
                        ClassName = "input-group-radiobutton3a margin-bottom-sm" 
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {
                            scatter: !Main.Instance.FrontendGamesState.scatter})}}
                    />

                    <RadioButton3
                        value = {Main.Instance.FrontendGamesState.wild}
                        text = "wild"
                        ClassName = "input-group-radiobutton3a margin-bottom-sm" 
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {
                            wild: !Main.Instance.FrontendGamesState.wild})}}
                    />

                    <RadioButton3
                        value = {Main.Instance.FrontendGamesState.free_spins}
                        text = "free spins"
                        ClassName = "input-group-radiobutton3a margin-bottom-sm" 
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {
                            free_spins: !Main.Instance.FrontendGamesState.free_spins})}}
                    />
{/*
                    <RadioButton3
                        value = {Main.Instance.FrontendGamesState.free_spins}
                        text = "show info"
                        ClassName = "input-group-radiobutton3a margin-bottom-sm fr" 
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {
                            show_info: !Main.Instance.FrontendGamesState.show_info})}}
                    />
*/}
                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.FrontendGamesState, {
                            show_info: !Main.Instance.FrontendGamesState.show_info})}
                        style = {{float:'left', display:'block', padding:'3px 8px 1px 8px', margin:'2px 0px 0px 0px'}}
                        className = {Main.Instance.FrontendGamesState.show_info ? 'btn btn-success' : 'btn btn-default'}
                    >
                        <i className = {Assets_.faReportLightClass}/>&nbsp;&nbsp;{Assets.ShowInfo}
                    </button>
                </div>
                {Main.Instance.FrontendGamesState.show_info ?
                <div className = "row admin-users-row-area">
                    <div className = "register-input textarea">
                        <FormInputArea  
                            value = {!Main.Instance.FrontendGamesState.info ? '' : Main.Instance.FrontendGamesState.info}
                            ClassName = "form-control register-input"
                            onChange = {(text) => updateInfo(text)}
                            placeholder = "Info"
                            icon = {Assets_.faReportLightClass}
                        />
                    </div>
                </div> : void 0}
                {Main.Instance.FrontendGamesState.upload_game_files ?
                <div className = "upload-form row" style = {{marginTop: '40px', padding: '5px 20px'}}>
                    <div className = "upload upload-fix">
                        <h5> {/* className = "level-wide"*/}
                                {Assets.UploadGameFiles}
                        </h5>
                        {Assets.DragFilesOrClickBelow}
                        <div className = "uploadWrapper">
                            <input
                                id = "uploadinput" 
                                className = "upload" 
                                type = "file" 
                                multiple = {true}
                                onChange = {(event) => { 
                                    const clonefile = Main.Instance.FrontendGamesState.files
                                    Object.keys(event.target.files).forEach((key) => {
                                            clonefile.push(event.target.files[key])
                                    })
                                    Logic.Type.New(Main.Instance.FrontendGamesState, {files: clonefile})
                                }}
                                onClick = {(event) => {event.target = null}}
                            />
                        </div>
                        <div>
                            {Assets.Files + ':'}
                            &nbsp;&nbsp;
                            {Main.Instance.FrontendGamesState.files.length > 0 ?
                            <button
                                className = "btn btn-danger btn-sm"
                                onClick = {() => {Logic.Type.New(Main.Instance.FrontendGamesState, {files:[]})}}
                            >
                                <span className = {Assets_.faTrashButtonClass}/>
                                &nbsp;&nbsp;
                                {Assets.All}
                            </button> : void 0}
                        </div>
                        <div style = {{width: '100%'}}>
                            <ul className = "list-group" style = {{marginTop: '20px'}}>
                                    {FilesList ? FilesList : void 0}
                            </ul>
                        </div>
                    </div>
                    <div 
                        style = {{
                            position: 'relative',
                            margin: 'auto',
                            float: 'right',
                            width: '50%',
                            height: '265px',
                            backgroundImage: 'url(\'' + Main.Instance.FrontendGamesState.background + '\')',
                            backgroundSize: 'cover',
                        }}
                    >
                        <img
                            className = "img img-responsive"
                            style = {{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                top: '0px',
                                bottom: '0px',
                                border: '1px solid #bbb',
                                margin: 'auto',
                            }}
                            src = {Main.Instance.FrontendGamesState.thumbnail}
                        />
                    </div>
                </div> : void 0}
                <div className = "row admin-users-container clearfix">
                    {Main.Instance.FrontendGamesState.upload_game_files ?
                    <ButtonIcon 
                        description = {!Main.Instance.FrontendGamesState.mode ? 
                                        Assets.saveChanges : 
                                        Assets.updateChanges} 
                        click = {() => {DbRoutes.UploadGame()}}
                        ClassName = {/*Main.Instance.FrontendGamesState.show_info ?
                                    Main.Instance.FrontendGamesState.mode ?
                                    'btn btn-default btn-sm btn-plus fl mt35' :
                                    'btn btn-primary btn-sm btn-plus fl mt35' :*/
                                    Main.Instance.FrontendGamesState.mode ?
                                    'btn btn-default btn-sm btn-plus fl mt5' :
                                    'btn btn-primary btn-sm btn-plus fl mt5'}
                        Icon = {faSave as IconDefinition} 
                        text = {Assets.Update}
                    /> : void 0}
                    
                    <ButtonIcon 
                        description = {!Main.Instance.FrontendGamesState.mode ? Assets.saveChanges : Assets.updateChanges} 
                        click = {() => handleInsertUpdate()}
                        ClassName = {
                            Main.Instance.FrontendGamesState.upload_game_files ?
                            Main.Instance.FrontendGamesState.mode ?
                            'btn btn-default btn-sm btn-plus fr mt5' :
                            'btn btn-primary btn-sm btn-plus fr mt5' :                            
                            Main.Instance.FrontendGamesState.show_info ?
                            Main.Instance.FrontendGamesState.mode ?
                            'btn btn-default btn-sm btn-plus fr mt35' :
                            'btn btn-primary btn-sm btn-plus fr mt35' :
                            Main.Instance.FrontendGamesState.mode ?
                            'btn btn-default btn-sm btn-plus fr mt5' :
                            'btn btn-primary btn-sm btn-plus fr mt5'}
                        icon = {Assets_.faGamingClass} 
                        text = {Assets.Save}
                    />
                </div>
            </div>
        </div>
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.FrontendGamesState.pSize ? '' : 
                Main.Instance.FrontendGamesState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
{/*
            <RadioButton3

                value = {!Main.Instance.FrontendGamesState.showActive ? false : 
                        Main.Instance.FrontendGamesState.showActive}
                ClassName = "input-group-radiobutton3c margin-bottom-sm" // "mr" option
                text = {Assets.ShowJustActive}
                title = {Assets.ShowJustActiveDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => {Logic.Type.New (Main.Instance.FrontendGamesState, {
                           showActive: !Main.Instance.FrontendGamesState.showActive})
                           getGamesCount()}}/>

            <RadioButton3

                value = {!Main.Instance.FrontendGamesState.showFreeRound ? false : 
                        Main.Instance.FrontendGamesState.showFreeRound}
                ClassName = "input-group-radiobutton3c margin-bottom-sm" // "mr" option
                text = {Assets.ShowJustFreeRound}
                title = {Assets.ShowJustFreeRoundDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => {Logic.Type.New (Main.Instance.FrontendGamesState, {
                           showFreeRound: !Main.Instance.FrontendGamesState.showFreeRound})
                           getGamesCount()}}/>
*/}

            <FormSelect 
                span = {true}
                value = {!Main.Instance.FrontendGamesState.filter_active ? '0' : 
                        Main.Instance.FrontendGamesState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {(text) => ApplyFilterActive(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.FrontendGamesState.count ? Main.Instance.FrontendGamesState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.FrontendGamesState.count ? Main.Instance.FrontendGamesState.pNum * 
                Main.Instance.FrontendGamesState.pSize + 1 : 0}{' - '}
                {Main.Instance.FrontendGamesState.count ? 
                ((Main.Instance.FrontendGamesState.pNum + 1) * 
                Main.Instance.FrontendGamesState.pSize > Main.Instance.FrontendGamesState.count ? 
                Main.Instance.FrontendGamesState.count : 
                (Main.Instance.FrontendGamesState.pNum + 1) * Main.Instance.FrontendGamesState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.FrontendGamesState.count ? 
                Main.Instance.FrontendGamesState.pNum + 1 :0}{' / '}
                {Main.Instance.FrontendGamesState.count ? 
                Math.trunc((Main.Instance.FrontendGamesState.count - 1) /
                Main.Instance.FrontendGamesState.pSize + 1) : 0}
            </span>
        </div>
        <h3>{Assets.tGames}</h3>
        {Main.Instance.DatabaseDataState.gamesStream ? 
        <ReactTable
            data = {Main.Instance.DatabaseDataState.gamesStream}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => EditButton(row, handleEdit)},
                {Header: Assets.GameName, accessor: 'name', headerClassName: 'th-left'},
                {Header: Assets.Studio, accessor: 'studio', headerClassName: 'th-left'},
                {maxWidth: 100, Header: Assets.Info, accessor: 'info', headerClassName: 'th-left'},
                {maxWidth: 50, Header: Assets.Thumb_, accessor: 'thumbnail', Cell: (row) => Image(row.value)},
                {maxWidth: 50, Header: Assets.Backgr_, accessor: 'background', Cell: (row) => Image(row.value)},
                {maxWidth: 300, Header: Assets.Folder, accessor: 'folder', headerClassName: 'th-left'},
                {maxWidth: 150, Header: Assets.Banner, accessor: 'banner', Cell: (row) => getBanner(row.value)},
                {maxWidth: 50, Header: Assets.Index, accessor: 'index', headerClassName: 'th-left'},
                {maxWidth: 50, Header: Assets.Reels, accessor: 'reels', headerClassName: 'th-left'},
                {maxWidth: 50, Header: Assets.Lines, accessor: 'lines', headerClassName: 'th-left'},
                {maxWidth: 60, Header: Assets.Active, accessor: 'active', Cell: (row) => TB(row, toggleActive)},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}

        /> : getGamesCount()}
        {/*console.log (Main.Instance.DatabaseDataState.gamesStream)*/}
        {/*Main.Instance.DatabaseDataState.gamesStream ? 
        StremingTable(Main.Instance.DatabaseDataState.gamesStream, {
            title: Assets.tGames,
            gamesFolder: Main.Instance.FrontendStoreState.games_folder,
            mode: Main.Instance.FrontendGamesState.mode,
            rs_banner: true,
            editingId: Main.Instance.FrontendGamesState.id,
            toggleButton:(data) => {handleToggle(data)},
            delButton:(data) => {handleDelete(data)}, 
            editButton:(data) => {handleEdit(data)}}) : void 0*/}
        </div>
    </div>)
}