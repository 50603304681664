 import * as React from 'react'
 import {Main} from '../../../Logic/Main'
 import {Logic} from '../../../Logic/Type'
 import {RouterObjects} from '../../../Logic/RouterObjects'
 import * as Config from '../../../Logic/Config'
 import * as DbRoutes from '../../../Logic/Database/DbRoutes'
 import {/* isUserGamanzaGamesAdmin, isUserGamanzaAdmin, */ IsBuildDefault, IsBuildJackpotsCH, IsBuildMyChance, IsBuildPasino} from '../../../Logic/Utils'

 export function GameStoreWrapper(props:React.Props<any> ) {
    if (RouterObjects['game_Store'] && Main.Instance.Location == RouterObjects['game_Store'].path && Main.Instance.Back === false) {
        
        let path: string
        let Path: string 
        
        if (IsBuildJackpotsCH() || IsBuildPasino()) {
           //  if (isUserGamanzaGamesAdmin() || isUserGamanzaAdmin()) {
            path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Gamingenius_Game_List'].path
            Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            RouterObjects['game_Store'].children['Gamingenius_Game_List'].path.substring(1, RouterObjects['game_Store'].children['Gamingenius_Game_List'].path.length)
            // } else {
            //     path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Approved_Games_Store'].path
            //     Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            //     RouterObjects['game_Store'].children['Approved_Games_Store'].path.substring(1, RouterObjects['game_Store'].children['Approved_Games_Store'].path.length)                
            // }
        } else if (IsBuildMyChance()) {
            path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Games_Selection'].path
            Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            RouterObjects['game_Store'].children['Games_Selection'].path.substring(1, RouterObjects['game_Store'].children['Games_Selection'].path.length)
        } else if (IsBuildDefault()) {
            path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['LottoBet'].path
            Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            RouterObjects['game_Store'].children['LottoBet'].path.substring(1, RouterObjects['game_Store'].children['LottoBet'].path.length)
        } else if (RouterObjects['game_Store'].children['Bonus']) {
            path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Bonus'].path
            Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            RouterObjects['game_Store'].children['Bonus'].path.substring(1, RouterObjects['game_Store'].children['Bonus'].path.length)
        } else if (RouterObjects['game_Store'].children['Gamingenius_Game_List']) {
            path = RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Gamingenius_Game_List'].path
            Path = RouterObjects['game_Store'].path.substring (1, RouterObjects['game_Store'].path.length) + ' -> ' +
            RouterObjects['game_Store'].children['Gamingenius_Game_List'].path.substring(1, RouterObjects['game_Store'].children['Gamingenius_Game_List'].path.length)
        }
        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )
        Logic.Type.New(Main.Instance, {Location: path})
    }
/*
    if (Main.Instance.Location == RouterObjects['game_Store'].path && RouterObjects['game_Store'].children['LottoBet']) { 
        Logic.Type.New(Main.Instance,{Location:RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['LottoBet'].path}
    )} 
*/      
    return(<div>{props.children}</div>)
}
