import * as React from 'react'
// import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons'
export class Button extends React.Component<{description?:string, ClassName?:string, 
Header?:string, click?:() => void} ,{} > {

    public render() {

        return( 
            <button 
                title = {this.props.description ? this.props.description : ''}
                onClick = {() => this.props.click ? this.props.click() : ''}
                className = {this.props.ClassName}
            >
                {this.props.Header}
            </button>
        )
    }
}

export class ButtonIcon extends React.Component<{ClassName?:string, icon?:string, Icon?: IconDefinition,
zIndex?:number, icons?:string[], click?:()=>void, small?:boolean, description?:string,
text?:string, disabled?:boolean} ,{} > {

    public render() {

        let ClassName: string|undefined = this.props.small && this.props.small === true ? 
                                          this.props.ClassName : 
                                          this.props.ClassName + ' icon-big'

        const icon:JSX.Element[] = []

        if (this.props.icon) {

            icon.push(<i key = "0" className = {this.props.icon}/>)
        } else if (this.props.icons) {

            for (let i:number = 0; i < this.props.icons.length; i++) {

                icon.push(<i key = {i.toString()} className = {this.props.icons[i]}/>)
            }
        } else { icon.push(<i key = "0" className = {Assets_.faQuestionButtonClass}/>) }

        if (this.props.text) {

            ClassName += ' button-icon'
/*
            if (this.props.text === Assets.Save || this.props.text === Assets.Insert) {
                ClassName += ' button-icon-save'
            } else if (this.props.text === Assets.Widget) {
                ClassName += ' button-icon-widget'
 } else if (this.props.text === Assets.Update) {
                ClassName += ' button-icon-update'
 } else if (this.props.text === Assets.LeaderboardsAndPrizes) {
                ClassName += ' button-icon-leaderboards-and-prizes'
 } else if (this.props.text === Assets.Assign) {
                ClassName += ' button-icon-assign'
 } else if (this.props.text === Assets.AssignGames ||
                     this.props.text === Assets.AssignCurrency ||
                     this.props.text === Assets.BetslipReqs) {
                ClassName += ' button-icon-assign-games'
 } else if (this.props.text === Assets.Copy) {
                ClassName += ' button-icon-copy'
 } else if (this.props.text === Assets.SelectAll) {
                ClassName += ' button-icon-select-all'
 } else if (this.props.text === Assets.Change) {
                ClassName += ' button-icon-change'
 } else if (this.props.text === Assets.Add) {
                ClassName += ' button-icon-add'
 } else if (this.props.text === Assets.AdvancedSettingsShort) {
                ClassName += ' button-icon-advanced-settings'
 } else if (this.props.text === Assets.PrizePoolSectionShort) {
                ClassName += ' button-icon-prize-pool-section'
 } else if (this.props.text === Assets.RecurringSectionShort) {
                ClassName += ' button-icon-recurring-section'
 } else if (this.props.text === Assets.SaveCalendar) {
                ClassName += ' button-icon-save-calendar'
 } else if (this.props.text === Assets.General) {
                ClassName += ' button-icon-general'
 } else if (this.props.text === Assets.Images) {
                ClassName += ' button-icon-images'
 } else if (this.props.text === Assets.RewardShop) {
                ClassName += ' button-icon-reward-shop'
 } else if (this.props.text === Assets.MinimumLevelShort) {
                ClassName += ' button-icon-minimum-level'
 } else if (this.props.text === Assets.Notify) {
                ClassName += ' button-icon-notify'
 } else if (this.props.text === Assets.Multipliers) {
                ClassName += ' button-icon-multipliers'
 } else if (this.props.text === Assets.Points) {
                ClassName += ' button-icon-points'
 } else if (this.props.text === Assets.Session) {
                ClassName += ' button-icon-session'
 } else if (this.props.text === Assets.Frequency) {
                ClassName += ' button-icon-frequency'
 } else if (this.props.text === Assets.Boosters) {
                ClassName += ' button-icon-boosters'
 } else if (this.props.text === Assets.ValidGames) {
                ClassName += ' button-icon-validgames'
 } else if (this.props.text === Assets.OptIn) {
                ClassName += ' button-icon-optin'
 } else if (this.props.text === Assets.MariasCorner) {
                ClassName += ' button-icon-maria'
 } else if (this.props.text === Assets.Banners) {
                ClassName += ' button-icon-banners'
 } else if (this.props.text === Assets.Games) {
                ClassName += ' button-icon-games'
 } else if (this.props.text === Assets.Results) {
                ClassName += ' button-icon-results'
 } else if (this.props.text === Assets.Fonts) {
                ClassName += ' button-icon-fonts'
 } else if (this.props.text === Assets.Calendar) {
                ClassName += ' button-icon-calendar'
 } else if (this.props.text === Assets.BetSizing) {
                ClassName += ' button-icon-bet-sizing'
 } else if (this.props.text === Assets.Action) {
                ClassName += ' button-icon-action'
 } else if (this.props.text === Assets.Date_Time) {
                ClassName += ' button-icon-date-time'
 } else if (this.props.text === Assets.Players) {
                ClassName += ' button-icon-players'
 } else if (this.props.text === Assets.Deposit) {
                ClassName += ' button-icon-deposit'
 } else if (this.props.text === Assets.Withdrawal) {
                ClassName += ' button-icon-withdrawal'
 } else if (this.props.text === Assets.OptIn || this.props.text === Assets.Levels || this.props.text === Assets.Prizes) {
                ClassName += ' button-icon-notify'
 } else { ClassName += ' button-icon-other' }
*/
        }

        return(                
            <button
                disabled = {this.props.disabled !== undefined ? this.props.disabled : false}
                style = {this.props.zIndex ? {zIndex: this.props.zIndex, width: 'auto', paddingLeft: '10px', paddingRight: '10px'} : {width: 'auto', paddingLeft: '10px', paddingRight: '10px'}}
                title = {this.props.description ? this.props.description : undefined}
                onClick = {() => this.props.click ? this.props.click() : ''}
                className = {ClassName}
            >
                {this.props.icon ? icon : <FontAwesomeIcon icon = {this.props.Icon}/>}
                {this.props.text ? <span>&nbsp;&nbsp;{this.props.text}</span> : null}
            </button>
        )
    }
}



