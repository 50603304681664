import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {SectionButtonKYC, SectionButtonRG, SectionButtonRM, RiskLevel, PlayerCardButtons/*, SelectedRiskComplianceSetting*/} from '../../../Logic/RiskComplianceStore/RiskManagement'
import * as Assets from '../../Assets'
import {isUserAdmin/*, numberWithSpaces*/, isUserRFSpecialist, isUserGamanzaAdmin} from '../../../Logic/Utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserSecret, faEyeSlash, faExclamationTriangle, faAddressCard, faQuestion} from '@fortawesome/free-solid-svg-icons'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import Switch from 'react-toggle-switch'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {InternalRiskActionsPopup, InternalRiskPlayersPopup} from './internalRiskActionsPopup'
import CSCallbackResolvePopup from './callbackResolvePopup'
import History from './history'
import {toggleCurrency as toggleCurr} from './reusables'
import {SelectPlayer} from '../PlayerManagement/PlayerCard'
import {LargeValueDepositsWithdrawal, TemporaryAccountStatus, NoPlayerActivityStatus, Blacklist, MaximumBalance, Retail, RetailAction/*, Notify, IssueTypes*/, TimelineEntrySubType, AccountStatus} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
import {getIssuesTable} from './ResponsibleGaming'
import {Custom} from '../../../Logic/Database/DatabaseData'
import * as Assets_ from '../../Assets_'
import {getControlPanel1} from '../MainDashboard/Dashboard'

export function getComments(comments: Array<{timestamp: string, userId: number, comment: string}>): JSX.Element[] {
    const Comments: JSX.Element[] = []
    if (comments) { for (let i: number = 0; i < comments.length; i++) {
        Comments.push (
            <div className = "clearfix" key = {i.toString()} style = {{marginTop: i === 0 ? '0px' : '5px'}}>
                <div className = "label label-default button-xs fl" style = {{width: '100%', fontSize: '90%', borderRadius: '0px'}}>
                    {comments[i].userId === 0 ? <div className = "fl">{Assets.Automatic.toUpperCase()}</div> :
                    <div className = "fl">{getName(comments[i].userId)}</div>}
                    <div className = "fr">{comments[i].timestamp.substring(11,19)}</div>
                    <div className = "fr mr">{comments[i].timestamp.substring(0,10)}</div>
                </div>
                <textarea 
                    style = {{
                        height: 'auto',
                        padding: '3px 5px',
                        lineHeight: '14px',
                        width: '100%',
                    }}
                    disabled = {true}
                    value = {comments[i].comment}
                    title = {comments[i].comment}
                    className = "dbl fl"
                />
            </div>,
        )
    }
    }
    return Comments
}

export function BlacklistCheck(value: boolean, id: number, index: number): JSX.Element {
    return (
        <div className = "clearfix mt5">
            <Switch
                className = "fl mr"
                onClick = {() => handleSwitch(value, index, id)}
                on = {value}
            />
            {/*<div className = "fl mt2">{Assets.BlackListStrings[id-5]}</div>*/}
        </div>
    )
}

export function getLegend(): JSX.Element[] {
    const legend: JSX.Element[] = []
    legend.push(<div key = "b" className = "fr mt20 risk risk-consequence">{Assets.BConsequence}</div>)
    legend.push(<div key = "a" className = "fr mt20 risk risk-condition ml">{Assets.AConditionRule}</div>)

    for (let i: number = RiskLevel.Severe; i > RiskLevel.Undefined; i--) {
        legend.push(<div key = {i.toString()} className = {'fr mt20 risk risk-' + RiskLevel[i]}>{i}. {RiskLevel[i]} {Assets.riskLevel}</div>)
    }
    return legend
}

export function XButton(instance: any, element: {}): JSX.Element {
    return (
        <button
            onClick = {() => Logic.Type.New (instance, element)/* : Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonRG: SectionButtonRG.Nothing})*/}
            style = {{position: 'absolute', marginRight: '0px', right: '2px', top: '-1px', background: 'transparent', border: '0px', padding: '0px'}}
            className = "btn btn-default btn-sm"
        >
            <i className = {Assets_.faCancelButtonClass}/>
        </button>
    )
}


export function riskLevelSlider(handleRiskLevelID: number, rowIndex: number, rowValue: string, noEdit?: boolean) {
    const maxValue: number = RiskLevel.Severe
    const defValue: number = +rowValue
/*
    if (handleRiskLevelID === 6) {
        maxValue = Main.Instance.DatabaseDataState.retailActions ? Main.Instance.DatabaseDataState.retailActions.length - 1 : -1
        if (maxValue > -1) {
            Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.actions.map((item, index) => {
                if (item.riskLevel === +rowValue) defValue = index
            })
        }
    }
*/
    return(
        <input
            disabled = {noEdit ? true : false} 
            type = "range"
            list = "test"
            min = "1"
            max = {maxValue.toString()}
            step = "1"
            value = {defValue.toString()}
            
            // value = {noEdit ? defValue.toString() : undefined}
            // defaultValue = {noEdit ? undefined : defValue.toString()}
            onChange = {(e) => noEdit ? void 0 :
                xhandleRiskLevel(
                    handleRiskLevelID,
                    rowIndex,/*
                    handleRiskLevelID === 6 ?
                        Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.actions[+e.target.value].riskLevel
                        : */
                    +e.target.value,
                )
            }
            className = "slider"
            
                
            
        />
    )
}

export function toggleComment(rm: boolean) {
    if (rm) {
        const showCommentsRM: boolean[] = Object.assign([], Main.Instance.RiskManagementState.showCommentsRM)
        showCommentsRM[Main.Instance.RiskManagementState.sectionButtonRM] = !showCommentsRM[Main.Instance.RiskManagementState.sectionButtonRM]
        Logic.Type.New (Main.Instance.RiskManagementState, {showCommentsRM})
    } else if (rm === false) {
        const showCommentsRG: boolean[] = Object.assign([], Main.Instance.RiskManagementState.showCommentsRG)
        showCommentsRG[Main.Instance.RiskManagementState.sectionButtonRG] = !showCommentsRG[Main.Instance.RiskManagementState.sectionButtonRG]
        Logic.Type.New (Main.Instance.RiskManagementState, {showCommentsRG})
    } else {
        Logic.Type.New (Main.Instance.RiskManagementState, {showCommentsSettings: !Main.Instance.RiskManagementState.showCommentsSettings})
    }
}

export function InsideTitle(rm: boolean, text: string, icon: IconDefinition): JSX.Element {
    return (
        <>
        <span
            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
            className = "label label-primary"
        >
            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {icon}/>
            {text}
            &nbsp;
            {rm || rm === false/* || rm === undefined && Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Notifications*/ ?
            <a style = {{position: 'absolute', top: '0px', right: '2px', color: '#fff', padding: '1px 1px 1px 1px'}} onClick = {() => toggleComment(rm)}>
                <FontAwesomeIcon style = {{fontSize: '10px'}} icon = {faQuestion}/>
            </a>
            : void 0}
        </span>
        {rm && Main.Instance.RiskManagementState.showCommentsRM && Main.Instance.RiskManagementState.showCommentsRM[Main.Instance.RiskManagementState.sectionButtonRM] ?
        <p>
            {

            Main.Instance.RiskManagementState.sectionButtonRM === SectionButtonRM.RiskAlerts ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.elevatedRisk :
            Main.Instance.RiskManagementState.sectionButtonRM === SectionButtonRM.InternalPlayerRisk ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.internalPlayerRisk :                
            void 0}
        </p>
        : rm === false && Main.Instance.RiskManagementState.showCommentsRG && Main.Instance.RiskManagementState.showCommentsRG[Main.Instance.RiskManagementState.sectionButtonRG] ?
        <p>
            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.CSCallbackButton ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.csCallbackButton :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.BlacklistCheck ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.blacklistCheck :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.DashboardBackend ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.dashboardBackend :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.NetLimitLoss ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.limitNetLoss :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.NetLimitLossRegistration ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.limitNetLossRegistration :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.SelfAssessment ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.selfAssessment :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.PermanentExclusion ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.selfExclusion :
            Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.TemporaryExclude ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.responsibleGaming.temporaryExclude :
            void 0}
        </p>
        : rm === false && Main.Instance.RiskManagementState.showCommentsRG && Main.Instance.RiskManagementState.showCommentsRG[Main.Instance.RiskManagementState.sectionButtonRG] ?
        <p> {
            Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.LargeValueDeposit ?
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.largeValueDeposit :
            Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.LargeValueWithdrawal ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.largeValueWithdrawal :
            Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.MaximumAmountOnPlayerWallet ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.maximumAmount :
            Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.NoPlayerActivity ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.noPlayerActivity :
            Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.TemporaryAccountStatus ? 
            Main.Instance.DatabaseDataState.custom.general.descriptions.riskManagement.temporaryAccountStatus : void 0}
        </p>


        : /*rm === undefined && text === SelectedRiskComplianceSetting[SelectedRiskComplianceSetting.Notifications] && Main.Instance.RiskManagementState.showCommentsSettings && Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Notifications ?
        <p>{Main.Instance.DatabaseDataState.custom.general.descriptions.settings.notify}</p>
        : */void 0}
        </>
    )
}
/*


function FilterRiskLevel(id: number, checkActions?: boolean): JSX.Element {
    return (
        <div className = "clearfix" style = {{position: 'absolute', right: '10px', top: '15px'}}>
            <span className = "mr">Select min. risk level</span>
            <select
                className = "mr"
                value = {Main.Instance.RiskManagementState.minRiskLevel}
                style = {{width: '100px'}}
                onChange = {(e) => {Logic.Type.New (Main.Instance.RiskManagementState, {minRiskLevel: +e.target.value}); filterData(id)}}
            >
                {getFilterLevelOptions(true, checkActions)}
            </select>
            <span className = "mr">Select max. risk level</span>
            <select
                value = {Main.Instance.RiskManagementState.maxRiskLevel}
                style = {{width: '100px'}}
                onChange = {(e) => {Logic.Type.New (Main.Instance.RiskManagementState, {maxRiskLevel: +e.target.value}); filterData(id)}}
            >
                {getFilterLevelOptions(false, checkActions)}
            </select>
        </div>
    )
}
*/
/*
function filterData (id: number) {
    if (id === 0 && Main.Instance.RiskManagementState.minRiskLevel === RiskLevel.Undefined && Main.Instance.RiskManagementState.maxRiskLevel === RiskLevel.Undefined ||
        id === 1 && Main.Instance.RiskManagementState.minRiskLevel === RiskLevel.Undefined && Main.Instance.RiskManagementState.maxRiskLevel === RiskLevel.Undefined 
        && (Main.Instance.RiskManagementState.filterFirstName === undefined || Main.Instance.RiskManagementState.filterFirstName.length === 0)
        && (Main.Instance.RiskManagementState.filterLastName === undefined || Main.Instance.RiskManagementState.filterLastName.length === 0)
        && (Main.Instance.RiskManagementState.filterBirthdayYear === undefined || Main.Instance.RiskManagementState.filterBirthdayYear === 0)
        && (Main.Instance.RiskManagementState.filterBirthdayMonth === undefined || Main.Instance.RiskManagementState.filterBirthdayMonth === 0)
        && (Main.Instance.RiskManagementState.filterBirthdayDay === undefined || Main.Instance.RiskManagementState.filterBirthdayDay === 0)
        && (Main.Instance.RiskManagementState.filterComment === undefined || Main.Instance.RiskManagementState.filterComment.length === 0) ||
        id < 0 && Main.Instance.RiskManagementState.riskLevel === RiskLevel.Undefined) {
        Logic.Type.New (Main.Instance.RiskManagementState, {filteredData: undefined})
    } else {
        const filteredData: any[] = []
        if (id === -1) {
            if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.riskManagement && Main.Instance.DatabaseDataState.custom.riskManagement.notify) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.riskManagement.notify.length; i++) {
                    const item = Main.Instance.DatabaseDataState.custom.riskManagement.notify[i]
                    if (!item.riskLevel[Main.Instance.RiskManagementState.riskLevel]) continue
                    item.ID = i
                    filteredData.push (item)
                }
            }
        } else if (id === 0) {
            if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.riskManagement && Main.Instance.DatabaseDataState.custom.riskManagement.temporaryAccountStatus) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.riskManagement.temporaryAccountStatus.length; i++) {
                    const item = Main.Instance.DatabaseDataState.custom.riskManagement.temporaryAccountStatus[i]
                    if (Main.Instance.RiskManagementState.minRiskLevel !== RiskLevel.Undefined && item.riskLevel < Main.Instance.RiskManagementState.minRiskLevel) continue
                    if (Main.Instance.RiskManagementState.maxRiskLevel !== RiskLevel.Undefined && item.riskLevel > Main.Instance.RiskManagementState.maxRiskLevel) continue
                    item.ID = i
                    filteredData.push (item)
                }
            }
        } else if (id === 1) {
            if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.riskManagement && 
                Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk && Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players.length; i++) {
                    const item = Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players[i]
                    if (Main.Instance.RiskManagementState.minRiskLevel !== RiskLevel.Undefined && item.riskLevel < Main.Instance.RiskManagementState.minRiskLevel) continue
                    if (Main.Instance.RiskManagementState.maxRiskLevel !== RiskLevel.Undefined && item.riskLevel > Main.Instance.RiskManagementState.maxRiskLevel) continue
                    if (Main.Instance.RiskManagementState.filterFirstName !== undefined && Main.Instance.RiskManagementState.filterFirstName.length > 0) {
                        if (item.firstName.toLowerCase().indexOf(Main.Instance.RiskManagementState.filterFirstName.toLowerCase()) === -1) continue
                    }
                    if (Main.Instance.RiskManagementState.filterLastName !== undefined && Main.Instance.RiskManagementState.filterLastName.length > 0) {
                        if (item.lastName.toLowerCase().indexOf(Main.Instance.RiskManagementState.filterLastName.toLowerCase()) === -1) continue
                    }
                    if (Main.Instance.RiskManagementState.filterComment !== undefined && Main.Instance.RiskManagementState.filterComment.length > 0) {
                        if (item.comment.toLowerCase().indexOf(Main.Instance.RiskManagementState.filterComment.toLowerCase()) === -1) continue
                    }
                    if (Main.Instance.RiskManagementState.filterBirthdayYear !== undefined && Main.Instance.RiskManagementState.filterBirthdayYear > 0) {
                        if (+item.birthday.substring(0,4) !== Main.Instance.RiskManagementState.filterBirthdayYear) continue
                    }
                    if (Main.Instance.RiskManagementState.filterBirthdayMonth !== undefined && Main.Instance.RiskManagementState.filterBirthdayMonth > 0) {
                        if (+item.birthday.substring(5,7) !== Main.Instance.RiskManagementState.filterBirthdayMonth) continue
                    }
                    if (Main.Instance.RiskManagementState.filterBirthdayDay !== undefined && Main.Instance.RiskManagementState.filterBirthdayDay > 0) {
                        if (+item.birthday.substring(8,10) !== Main.Instance.RiskManagementState.filterBirthdayDay) continue
                    }

                    item.ID = i
                    filteredData.push (item)
                }
            }
        }
        Logic.Type.New (Main.Instance.RiskManagementState, {filteredData})
    }
}
*/
/*
            {Main.Instance.RiskManagementState.showFilters ?
            <>
</> : void 0}

*/
/*
function getPlayersFilters(): JSX.Element {
    return (
        <>
        <div
            className = "fl player-risk-filter"
            style = {{overflow: 'hidden', height: '22px', width: !Main.Instance.RiskManagementState.showFilters ? '70px' : ''}}
        >
            <button
                className = "btn btn-default btn-xs"
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showFilters: !Main.Instance.RiskManagementState.showFilters})}
            >
                {Main.Instance.RiskManagementState.showFilters ?
                <span>{Assets.Hide}&nbsp;&nbsp;<FontAwesomeIcon icon = {faFilter} size = "lg"/></span> : 
                <span>{Assets.Show}&nbsp;&nbsp;<FontAwesomeIcon icon = {faFilter} size = "lg"/></span>}
            </button>

            <span className = "mr">first name</span>
            <input
                // placeholder = "first name"
                className = "mr filter-input-text"
                onChange = {e => {Logic.Type.New (Main.Instance.RiskManagementState, {filterFirstName: e.target.value}); filterData(1)}}
                type = "text"
                value = {Main.Instance.RiskManagementState.filterFirstName ? Main.Instance.RiskManagementState.filterFirstName : ''}
            />
            <span className = "mr">last name</span>
            <input
                // placeholder = "last name"
                className = "mr filter-input-text"
                onChange = {e => {Logic.Type.New (Main.Instance.RiskManagementState, {filterLastName: e.target.value}); filterData(1)}}
                type = "text"
                value = {Main.Instance.RiskManagementState.filterLastName ? Main.Instance.RiskManagementState.filterLastName : ''}
            /> 
            <span className = "mr">birthday</span>
            <span className = "mr">year</span>
            <input
                // placeholder = "year"
                maxLength = {4}
                min = "1900"
                className = "mr filter-input-text filter-input-text-1"
                onChange = {e => isNaN(e.target.value as any) || e.target.value.length > 4 ? void 0 : changeYear(e.target.value)}
                type = "number"
                value = {Main.Instance.RiskManagementState.filterBirthdayYear ? Main.Instance.RiskManagementState.filterBirthdayYear : ''}
            /> 
            <span className = "mr">month</span>
            <input
                // placeholder = "mon"
                min = "0"
                maxLength = {2}
                className = "mr filter-input-text filter-input-text-2"
                onChange = {e => isNaN(e.target.value as any) || e.target.value.length > 2 ? void 0 : changeMonth(e.target.value)}
                type = "number"
                value = {Main.Instance.RiskManagementState.filterBirthdayMonth ? Main.Instance.RiskManagementState.filterBirthdayMonth : ''}
            /> 
            <span className = "mr">day</span>
            <input
                // placeholder = "day"
                min = "0"
                maxLength = {2}
                className = "mr filter-input-text filter-input-text-2"
                onChange = {e => isNaN(e.target.value as any) || e.target.value.length > 2 ? void 0 : changeDay(e.target.value)}
                type = "number"
                value = {Main.Instance.RiskManagementState.filterBirthdayDay ? Main.Instance.RiskManagementState.filterBirthdayDay : ''}
            /> 
            <span className = "mr">comment</span>
            <input
                // placeholder = "comment"
                className = "mr filter-input-text filter-input-text-0"
                onChange = {e => {Logic.Type.New (Main.Instance.RiskManagementState, {filterComment: e.target.value}); filterData(1)}}
                type = "text"
                value = {Main.Instance.RiskManagementState.filterComment ? Main.Instance.RiskManagementState.filterComment : ''}
            /> 
            <button
                className = "btn btn-warning btn-xs"
                onClick = {() => clearFilters()}
            >
                Clear filters
            </button>
        </div>
        <div>
            <button
                className = "btn btn-default btn-xs"
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showChart: !Main.Instance.RiskManagementState.showChart})}
            >
                {Main.Instance.RiskManagementState.showChart ?
                <span>Hide&nbsp;&nbsp;<FontAwesomeIcon icon = {faChartPie}/></span> : 
                <span>Show&nbsp;&nbsp;<FontAwesomeIcon icon = {faChartPie}/></span>}
            </button>
        </div>
        </>
    )
}

function clearFilters() {
    Logic.Type.New (Main.Instance.RiskManagementState, {
        filterBirthdayDay: undefined,
        filterBirthdayMonth: undefined,
        filterBirthdayYear: undefined,
        filterFirstName: undefined,
        filterLastName: undefined,
        filterComment: undefined,
        minRiskLevel: RiskLevel.Undefined,
        maxRiskLevel: RiskLevel.Undefined
    })
}

function changeDay(value: string) {
    Logic.Type.New (Main.Instance.RiskManagementState, {filterBirthdayDay: +value})
    filterData(1)
}

function changeMonth(value: string) {
    Logic.Type.New (Main.Instance.RiskManagementState, {filterBirthdayMonth: +value})
    filterData(1)
}

function changeYear(value: string) {
    Logic.Type.New (Main.Instance.RiskManagementState, {filterBirthdayYear: +value})
    filterData(1)
}
*/
export function GetLegend(): JSX.Element {
    return (
        Main.Instance.RiskManagementState.showLegend ?
        <>
            {getLegend()}
            <button 
                style = {{cursor: 'pointer'}}            
                className = "fr mt20 risk risk-legend risk-default dbl btn btn-sm mr"
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showLegend: false})}
            >
                <FontAwesomeIcon icon = {faEyeSlash}/>&nbsp;&nbsp;{Assets.Legend}
            </button>
        </>
        :
        <button className = "fr mt20 dbl btn btn-default btn-xs mr0" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showLegend: true})}>{Assets.Legend}</button>
    )
}

function getRiskManagementGroupsButtons(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    const unresolved: number = GetUnresolved(TimelineEntrySubType.RiskManagement)
    for (let i: number = SectionButtonRM.RiskAlerts/*TemporaryAccountStatus*/; i <= SectionButtonRM.InternalPlayerRisk; i++) {
        if (i === SectionButtonRM.RiskAlerts) {continue}
        buttons.push (
            <button
                key = {i.toString()}
                style = {{marginRight: i === SectionButtonRM.RiskAlerts ? '0px' : ''}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonRM: i, /*filteredData: undefined,*/ riskLevel: RiskLevel.Undefined, minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined})
                    DbRoutes.wsBasicGet(
                        i === SectionButtonRM.InternalPlayerRisk ? Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions ? Config.amRetail : Config.amRetailActions :
                        i === SectionButtonRM.RiskAlerts ? Config.amIssues :
                    undefined)
                }}
                className = {Main.Instance.RiskManagementState.sectionButtonRM === i ?
                                i === SectionButtonRM.InternalPlayerRisk ? 'btn btn-warning btn-sm dbl fl' :
                                i === SectionButtonRM.RiskAlerts ? unresolved ? 'btn btn-danger btn-sm dbl fr' : 'btn btn-warning btn-sm dbl fr' :
                                'btn btn-success btn-sm dbl fl' :
                                i === SectionButtonRM.InternalPlayerRisk ? 'btn btn-default btn-sm dbl fl' :
                                i === SectionButtonRM.RiskAlerts ? 'btn btn-default btn-sm dbl fr' : ''}
            >
                {Assets_.RiskManagementGroups[i]}
                {i === SectionButtonRM.RiskAlerts ? <>&nbsp;&nbsp;<span className="badge badge-light">{unresolved}</span></>: void 0}
            </button>,
        )
    }
    return buttons
}

export function GetUnresolved(subType: TimelineEntrySubType): number {
    if (Main.Instance.DatabaseDataState.unresolvedIssuesCount) {
        for (const item of Main.Instance.DatabaseDataState.unresolvedIssuesCount) {
            if (item.type === subType) {
                return item.totalIssues
            }
        }
    }
    return 0
}

export function GetResolved(subType: TimelineEntrySubType): number {
    if (Main.Instance.DatabaseDataState.resolvedIssuesCount) {
        for (const item of Main.Instance.DatabaseDataState.resolvedIssuesCount) {
            if (item.type === subType) {
                return item.totalIssues
            }
        }
    }
    return 0
}

export function GetAll(subType: TimelineEntrySubType): number {
    if (Main.Instance.DatabaseDataState.issuesCount) {
        for (const item of Main.Instance.DatabaseDataState.issuesCount) {
            if (item.type === subType) {
                return item.totalIssues
            }
        }
    }
    return 0
}

export function GetUnresolvedAll(subType0: TimelineEntrySubType, subType1: TimelineEntrySubType, subType2: TimelineEntrySubType, subType3: TimelineEntrySubType): number[] {
    const unresolved: number[] = [0, 0, 0, 0]
    if (Main.Instance.DatabaseDataState.unresolvedIssuesCount) {
        for (const item of Main.Instance.DatabaseDataState.unresolvedIssuesCount) {
            if (item.type === subType0) {
                unresolved[0] = item.totalIssues
            } else if (item.type === subType1) {
                unresolved[1] = item.totalIssues
            } else if (item.type === subType2) {
                unresolved[2] = item.totalIssues
            } else if (item.type === subType3) {
                unresolved[3] = item.totalIssues
            }
        }
    }
    return unresolved
}

/*
export function GetUnresolved(subType: TimelineEntrySubType): number {
    let unresolved: number = 0
    if (Main.Instance.DatabaseDataState.issues) {
        for (const item of Main.Instance.DatabaseDataState.issues) {
            if (item.entrySubType === subType && item.resolved === false) { unresolved++ }
        }
    }
    return unresolved
}

export function GetResolved(subType: TimelineEntrySubType): number {
    let unresolved: number = 0
    if (Main.Instance.DatabaseDataState.issues) {
        for (const item of Main.Instance.DatabaseDataState.issues) {
            if (item.entrySubType === subType && item.resolved === true) { unresolved++ }
        }
    }
    return unresolved
}

export function GetAll(subType: TimelineEntrySubType): number {
    let unresolved: number = 0
    if (Main.Instance.DatabaseDataState.issues) {
        for (const item of Main.Instance.DatabaseDataState.issues) {
            if (item.entrySubType === subType) { unresolved++ }
        }
    }
    return unresolved
}

export function GetUnresolvedAll(subType0: TimelineEntrySubType, subType1: TimelineEntrySubType, subType2: TimelineEntrySubType, subType3: TimelineEntrySubType): number[] {
    const unresolved: number[] = [0, 0, 0, 0]
    if (Main.Instance.DatabaseDataState.issues) {
        for (const item of Main.Instance.DatabaseDataState.issues) {
            if (item.resolved === false) {
                if (item.entrySubType === subType0) {
                    unresolved[0]++
                } else if (item.entrySubType === subType1) {
                    unresolved[1]++
                } else if (item.entrySubType === subType2) {
                    unresolved[2]++
                } else if (item.entrySubType === subType3) {
                    unresolved[3]++
                }
            }
        }
    }

    return unresolved
}
*/
function getPlayersActionsChecks(): JSX.Element {
    return (
        <div className = "fl">
            <button
                className = {Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleInternalRiskPlayersActions: true})}
            >
                {Assets.Players}
            </button>
            <button
                className = {Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === false ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleInternalRiskPlayersActions: false})}
            >
                {Assets.Actions}
            </button>
{/*
            <button
                className = {Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === undefined ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleInternalRiskPlayersActions: undefined})}                        
            >
                {Assets.Checks}
            </button>
*/}
        </div>
    )
}
/*
export function getRole(userId: number): string {
    if (Main.Instance.LogState.distinctUserId)
    for (const item of Main.Instance.LogState.distinctUserId) {
        if (item.user_id === userId) {
            const space: number = item.roles.indexOf (' ')
            return item.roles.substring(space, item.roles.length)
        }
    }
    return '?'
}
*/
export function getName(userId: number): string {
    if (Main.Instance.LogState.distinctUserId) {
    for (const item of Main.Instance.LogState.distinctUserId) {
        if (item.user_id === userId) {
            return item.username
        }
    }
    }
    return '?'
}

export function RiskManagement(): JSX.Element {
    if (!isUserRFSpecialist()) { return <div><h2>{Assets.RiskManagement}</h2><div>{Assets.pleaseUpgradeUserSecurityRightsForAccessToThisPage}</div></div> }
    if (Main.Instance.DatabaseDataState.retail === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {retail: null})
        DbRoutes.wsBasicGet(Config.amRetail)
    }
    DbRoutes.wsReinitialize()
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.RiskManagement}</h2>
                {GetLegend()}
                <br/>
            </div>

            <div className = "clearfix" style = {{marginBottom: '10px'}}>
                {getRiskManagementGroupsButtons()}
            </div>

            {Main.Instance.RiskManagementState.sectionButtonRM === SectionButtonRM.RiskAlerts ?
            <div>
            {Main.Instance.RiskManagementState.history ? <History/> : void 0}
            {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/>: void 0}                
            {/*Main.Instance.RiskManagementState.showAddRiskAlertsModal ? <ChangePlayerRiskLevelPopup what = {Assets.ChangePlayerRiskLevel}/> : void 0*/}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                <div 
                    className = "clearfix test-site-data2 light_grey" 
                    style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                >
                    {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonRM.Nothing})}
                    {InsideTitle(true, Assets.RiskManagementIssues/*Assets.RiskAlerts*/, faExclamationTriangle)}
                    <br/>
                    {getRiskAlertsTable()}
                </div>
            </div>
            </div>
            : void 0}
            {Main.Instance.RiskManagementState.sectionButtonRM === SectionButtonRM.InternalPlayerRisk ?
            <div>
            {/*<h3>{Assets.largeValueWithdrawal}</h3>*/}
            {Main.Instance.RiskManagementState.showInternalRiskModal?
            Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === true? 
            <InternalRiskPlayersPopup/>
            : <InternalRiskActionsPopup what = {Assets.AddANewAction}/>
            : void 0
            }
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonRM.Nothing})}
                        {InsideTitle(true,Assets.InternalPlayerRisk, faUserSecret)}
                        {/*Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === true ? FilterRiskLevel(1, true) : void 0*/}
                        <br/>
                        <div className = "clearfix">
                            {getPlayersActionsChecks()}
                            {/*Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === true ? getPlayersFilters() : void 0*/}
                        </div>
                        {/*Main.Instance.RiskManagementState.showChart ?
                        <div>
                            <div className = "player-risk-chart">Player risk distribution</div>
                            <PlayerRiskChart
                                data = {[
                                    {name: RiskLevel[RiskLevel.Normal] + ' risk', value: countInternalPlayerRiskMembers(RiskLevel.Normal)},
                                    {name: RiskLevel[RiskLevel.Severe] + ' risk', value: countInternalPlayerRiskMembers(RiskLevel.Severe)}
                                ]}
                            />
                        </div>
                        : void 0*/}
                        <br/>
                        {Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === true ?
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.retail ? Main.Instance.DatabaseDataState.retail : []}
                            columns = {[
                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">
                                {row.value}{/*Main.Instance.RiskManagementState.filteredData !== undefined ? (row.value + 1) : (row.index + 1)*/}</div>},
        
                                {width: 150, Header: Assets.FirstName, accessor: 'data.firstName', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left">
                                    <input
                                        // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                        className = "table-input-text"
                                        onChange = {(e) => handleText (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 3)}
                                        type = "text"
                                        value = {row.value}
                                    />
                                </div>},

                                {width: 150, Header: Assets.LastName, accessor: 'data.lastName', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left">
                                    <input
                                        // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                        className = "table-input-text"
                                        onChange = {(e) => handleText (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 4)}
                                        type = "text"
                                        value = {row.value}
                                    />
                                </div>},

                                {width: 80, Header: Assets.Birthday, accessor: 'data.birthday', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left" style = {{marginTop: '6px'}}>
                                    <div style = {{height: '25px'}}>
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            maxLength = {4}
                                            min = {1901}
                                            max = {(new Date()).getFullYear() - 18}
                                            className = "dbl table-input-text-full pr0"
                                            onChange = {(e) => handleTextBirthday (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 0)}
                                            type = "number"
                                            value = {row.value.substring(0,4)}
                                        />
                                    </div>
                                    <div className = "clearfix" style = {{height: '25px'}}>
                                        <span className = "fl dbl" style = {{width: '20px', lineHeight: '23px'}}>M</span>
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            maxLength = {2}
                                            min = {1}
                                            max = {12}
                                            className = "dbl table-input-text-small pr0 fl"
                                            onChange = {(e) => handleTextBirthday (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 1)}
                                            type = "number"
                                            value = {row.value.substring(5,7)}
                                        />
                                    </div>
                                    <div className = "clearfix" style = {{height: '25px'}}>
                                        <span className = "fl dbl" style = {{width: '20px', lineHeight: '23px'}}>D</span>
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            maxLength = {2}
                                            min = {1}
                                            max = {31}
                                            className = "dbl table-input-text-small pr0 fl"
                                            onChange = {(e) => handleTextBirthday (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 2)}
                                            type = "number"
                                            value = {row.value.substring(8,10)}
                                        />
                                    </div>
                                </div>},

                                {Header: Assets.Comment, accessor: 'data.comment', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left">
                                    <textarea
                                        // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                        style = {{height: '73px', marginTop: '6px', lineHeight:'14px', padding: '5px'}}
                                        className = "table-input-text"
                                        onChange = {(e) => handleText (e.target.value, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, 5)}
                                        value = {row.value}
                                    />
                                </div>},
                                
                                // modify risk level function that it accepts only the values that are defined in actions
                                {width: 110, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                <button
                                    // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                    className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                >{RiskLevel[row.value]}
                                    {riskLevelSlider(6, /*Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID :*/ row.index, row.value)}
                                </button>},

                                {show: (isUserAdmin() || isUserRFSpecialist()) /*&& Main.Instance.RiskManagementState.filteredData === undefined*/, width: 50,
                                    Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddPlayer()}>{Assets.Add}</button>,
                                accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeletePlayer, true)},
                                {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 6)},
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        : /* Main.Instance.RiskManagementState.toggleInternalRiskPlayersActions === false ? */
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.retailActions ? Main.Instance.DatabaseDataState.retailActions : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Consequences,
                                    headerClassName: 'risk-consequence',
                                    columns: [
                                        {width: 200, Header: Assets.NotifyCustomerSupport, accessor: 'data.notifyCS', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 19)} on = {row.value} /></div>},
        
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 20)} on = {row.value} /></div>},   
                                    
                                        {width: 110, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(7, row.index, row.value)}
                                        </button>},
                                    ],
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(),
                                        width: 50,
                                        Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddAction()}>
                                                    {Assets.Add}
                                                </button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteAction)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 7)},
                                    ],
                                },
                                
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        /*:
                        <>
                            <div className = "clearfix mt5">
                                <Switch
                                    className = "fl mr"
                                    onClick = {() => handleSwitch(Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.checks.checkAtLogin, 0, 21)}
                                    on = {Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.checks.checkAtLogin}
                                />
                                <div className = "fl mt2">{Assets.checksStrings[1]}</div>
                            </div>
                            <div className = "clearfix mt5">
                                <Switch
                                    className = "fl mr"
                                    onClick = {() => handleSwitch(Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.checks.checkAtRegistration, 0, 22)}
                                    on = {Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.checks.checkAtRegistration}
                                />
                                <div className = "fl mt2">{Assets.checksStrings[0]}</div>
                            </div>
                        </>*/}
                    </div>
                </div>
            </div>
            : void 0}

            {/*Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.BlacklistCheck ?
            <div>
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonRM.Nothing})}
                        {InsideTitle(true,Assets.BlacklistCheck, faMoneyCheck)}

                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.blacklist ? Main.Instance.DatabaseDataState.blacklist : []}
                            columns = {[
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 60, accessor: 'data.checkAtRegistration', Header: <div title={Assets.BlackListStrings[0]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[0]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 5, row.index)},
                                        {width: 60, accessor: 'data.checkAtLogin', Header: <div title={Assets.BlackListStrings[1]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[1]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 6, row.index)},
                                        {width: 60, accessor: 'data.allowCreateAccount', Header: <div title={Assets.BlackListStrings[2]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[2]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 7, row.index)},
                                        {width: 60, accessor: 'data.allowPlay', Header: <div title={Assets.BlackListStrings[3]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[3]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 8, row.index)},
                                        {width: 60, accessor: 'data.allowDeposit', Header: <div title={Assets.BlackListStrings[4]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[4]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 9, row.index)},
                                        {width: 60, accessor: 'data.allowWithdraw', Header: <div title={Assets.BlackListStrings[5]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[5]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 10, row.index)},
                                        {width: 60, accessor: 'data.allowLogin', Header: <div title={Assets.BlackListStrings[6]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[6]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 11, row.index)},
                                        {width: 60, accessor: 'data.recieveMarketingPromotion', Header: <div title={Assets.BlackListStrings[7]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[7]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 12, row.index)},
                                        {width: 60, accessor: 'data.checkBlackList', Header: <div title={Assets.BlackListStrings[8]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[8]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 13, row.index)},
                                        {width: 60, accessor: 'data.checkNightJob', Header: <div title={Assets.BlackListStrings[9]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[9]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 14, row.index)},
                                        {width: 60, accessor: 'data.allowAccountSuspension', Header: <div title={Assets.BlackListStrings[10]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[10]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 15, row.index)},
                                    ],
                                    headerClassName: 'risk-condition'
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button
                                            disabled = {true}
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(2, row.index, row.value, true)}
                                        </button>},
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 26)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence'
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 4)}
                                    ]
                                }
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={1}
                        />
                        <br/>
                    </div>
                </div>
            </div>
            : void 0*/}

            {/*Main.Instance.RiskManagementState.sectionButtonRM === SectionButtonRM.Settings ? 
            <div>
                <h3>{Assets.Settings}</h3>
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonRM.Nothing})}
                        {InsideTitle(true,Assets.Notify, faEnvelope)}
                        <button
                            style = {{position: 'absolute', top: '15px', left: '130px'}}
                            className = {Main.Instance.RiskManagementState.showDefaults ? 'btn btn-danger btn-xs' : 'btn btn-success btn-xs'}
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showDefaults: !Main.Instance.RiskManagementState.showDefaults})}
                        >
                            {Main.Instance.RiskManagementState.showDefaults ? 'Hide default risk values' : 'Show default risk values'}
                        </button>
                        {Main.Instance.RiskManagementState.showDefaults ?
                            <div style = {{position: 'absolute', top: '15px', left: '290px'}}>
                            {getNotifyDefaults()}
                            </div>
                        : void 0}
                        {filterRiskLevel()}
                        <br/>
                        <ReactTable 
                            data = {
                                Main.Instance.RiskManagementState.filteredData ?
                                Main.Instance.RiskManagementState.filteredData :
                                Main.Instance.DatabaseDataState.custom &&
                                Main.Instance.DatabaseDataState.custom.riskManagement &&
                                Main.Instance.DatabaseDataState.custom.riskManagement.notify ? 
                                Main.Instance.DatabaseDataState.custom.riskManagement.notify : []}
                            columns = {[
                                {width: 50, Header: Assets.ID, accessor: Assets.ID, headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">
                                    {Main.Instance.RiskManagementState.filteredData !== undefined ? (row.value + 1) : (row.index + 1)}</div>},
                                {width: 250, Header: 'Email', accessor: 'email', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left">
                                    <input
                                        // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                        className = "table-input-text"
                                        onChange = {e => handleText (e.target.value, Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID : row.index, 0)}
                                        type = "text"
                                        value = {row.value}
                                    />
                                </div>},
                                {width: 250, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left', Cell: (row) =>
                                <div className = "td-left">
                                    <input
                                        // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                        className = "table-input-text"
                                        onChange = {e => handleText (e.target.value, Main.Instance.RiskManagementState.filteredData !== undefined ? row.original.ID : row.index, 1)}
                                        type = "text"
                                        value = {row.value}
                                    />
                                </div>},
                                {width: 110, Header: 'Low risk', accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">{NotifyButton(RiskLevel.Low, row)}</div>},
                                {width: 110, Header: 'Normal risk', accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">{NotifyButton(RiskLevel.Normal, row)}</div>},
                                {width: 110, Header: 'Medium risk', accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">{NotifyButton(RiskLevel.Medium, row)}</div>},
                                {width: 110, Header: 'High risk', accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">{NotifyButton(RiskLevel.High, row)}</div>},
                                {width: 110, Header: 'Severe risk', accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">{NotifyButton(RiskLevel.Severe, row)}</div>},
                                {show: isUserAdmin() || isUserRFSpecialist() && Main.Instance.RiskManagementState.filteredData === undefined, width: 50,
                                    Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNotify()}>{Assets.Add}</button>,
                                accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNotify)},
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div> : void 0*/}
        </div>
    )
}

export function Update(row: any, which?: number) {
    return (
    <button
        disabled = {
            !isUserGamanzaAdmin() && which !== 6 && which !== 7 && which !== 8 && which !== 9 && which !== 10 && which !== 11 && which !== 18 || which === undefined ? true :
            which === 0 ? Main.Instance.DatabaseDataState.largeValueDeposits && Main.Instance.DatabaseDataState.largeValueDeposits_ && JSON.stringify(Main.Instance.DatabaseDataState.largeValueDeposits[row.index]) === Main.Instance.DatabaseDataState.largeValueDeposits_[row.index] : 
            which === 1 ? Main.Instance.DatabaseDataState.largeValueWithdrawal && Main.Instance.DatabaseDataState.largeValueWithdrawal_ && JSON.stringify(Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index]) === Main.Instance.DatabaseDataState.largeValueWithdrawal_[row.index] :
            which === 2 ? Main.Instance.DatabaseDataState.temporaryAccountStatus && Main.Instance.DatabaseDataState.temporaryAccountStatus_ && JSON.stringify(Main.Instance.DatabaseDataState.temporaryAccountStatus[row.index]) === Main.Instance.DatabaseDataState.temporaryAccountStatus_[row.index] :
            which === 3 ? Main.Instance.DatabaseDataState.noPlayerActivityStatus && Main.Instance.DatabaseDataState.noPlayerActivityStatus_ && JSON.stringify(Main.Instance.DatabaseDataState.noPlayerActivityStatus[row.index]) === Main.Instance.DatabaseDataState.noPlayerActivityStatus_[row.index] :
            which === 4 ? Main.Instance.DatabaseDataState.blacklist && Main.Instance.DatabaseDataState.blacklist_ && JSON.stringify(Main.Instance.DatabaseDataState.blacklist[row.index]) === Main.Instance.DatabaseDataState.blacklist_[row.index] :
            which === 5 ? Main.Instance.DatabaseDataState.maximumBalance && Main.Instance.DatabaseDataState.maximumBalance_ && JSON.stringify(Main.Instance.DatabaseDataState.maximumBalance[row.index]) === Main.Instance.DatabaseDataState.maximumBalance_[row.index] :
            which === 6 ? Main.Instance.DatabaseDataState.retail && Main.Instance.DatabaseDataState.retail_ && JSON.stringify(Main.Instance.DatabaseDataState.retail[row.index]) === Main.Instance.DatabaseDataState.retail_[row.index] :
            which === 7 ? Main.Instance.DatabaseDataState.retailActions && Main.Instance.DatabaseDataState.retailActions_ && JSON.stringify(Main.Instance.DatabaseDataState.retailActions[row.index]) === Main.Instance.DatabaseDataState.retailActions_[row.index] :
            // which === 8 ? Main.Instance.DatabaseDataState.notify && Main.Instance.DatabaseDataState.notify_ && JSON.stringify(Main.Instance.DatabaseDataState.notify[row.index]) === Main.Instance.DatabaseDataState.notify_[row.index] :
            which === 9 ? Main.Instance.DatabaseDataState.saGifts && Main.Instance.DatabaseDataState.saGifts_ && JSON.stringify(Main.Instance.DatabaseDataState.saGifts[row.index]) === Main.Instance.DatabaseDataState.saGifts_[row.index] :
            which === 10 ? Main.Instance.DatabaseDataState.saCriteria && Main.Instance.DatabaseDataState.saCriteria_ && JSON.stringify(Main.Instance.DatabaseDataState.saCriteria[row.index]) === Main.Instance.DatabaseDataState.saCriteria_[row.index] :
            which === 11 ? Main.Instance.DatabaseDataState.saQuestions && Main.Instance.DatabaseDataState.saQuestions_ && JSON.stringify(Main.Instance.DatabaseDataState.saQuestions[row.index]) === Main.Instance.DatabaseDataState.saQuestions_[row.index] :
            which === 12 ? Main.Instance.DatabaseDataState.netLimitLossIncreased && Main.Instance.DatabaseDataState.netLimitLossIncreased_ && JSON.stringify(Main.Instance.DatabaseDataState.netLimitLossIncreased[row.index]) === Main.Instance.DatabaseDataState.netLimitLossIncreased_[row.index] :
            which === 13 ? Main.Instance.DatabaseDataState.netLimitLossRegistration && Main.Instance.DatabaseDataState.netLimitLossRegistration_ && JSON.stringify(Main.Instance.DatabaseDataState.netLimitLossRegistration[row.index]) === Main.Instance.DatabaseDataState.netLimitLossRegistration_[row.index] :
            which === 14 ? Main.Instance.DatabaseDataState.temporaryExclude && Main.Instance.DatabaseDataState.temporaryExclude_ && JSON.stringify(Main.Instance.DatabaseDataState.temporaryExclude[row.index]) === Main.Instance.DatabaseDataState.temporaryExclude_[row.index] :
            which === 15 ? Main.Instance.DatabaseDataState.temporaryExcludeOptions && Main.Instance.DatabaseDataState.temporaryExcludeOptions_ && JSON.stringify(Main.Instance.DatabaseDataState.temporaryExcludeOptions[row.index]) === Main.Instance.DatabaseDataState.temporaryExcludeOptions_[row.index] :
            which === 16 ? Main.Instance.DatabaseDataState.dashboardBackend && Main.Instance.DatabaseDataState.dashboardBackend_ && JSON.stringify(Main.Instance.DatabaseDataState.dashboardBackend[row.index]) === Main.Instance.DatabaseDataState.dashboardBackend_[row.index] :
            which === 17 ? Main.Instance.DatabaseDataState.selfExclusion && Main.Instance.DatabaseDataState.selfExclusion_ && JSON.stringify(Main.Instance.DatabaseDataState.selfExclusion[row.index]) === Main.Instance.DatabaseDataState.selfExclusion_[row.index] :
            which === 18 ? Main.Instance.DatabaseDataState.counselingCentres && Main.Instance.DatabaseDataState.counselingCentres_ && JSON.stringify(Main.Instance.DatabaseDataState.counselingCentres[row.index]) === Main.Instance.DatabaseDataState.counselingCentres_[row.index] :
            void 0
        }
        type = "button"
        onClick = {() =>
            which === undefined ? void 0 :
            which === 0 ? DbRoutes.wsBasicUpdate(Config.amLargeValueDeposits, Main.Instance.DatabaseDataState.largeValueDeposits[row.index].id, Main.Instance.DatabaseDataState.largeValueDeposits[row.index].data) : 
            which === 1 ? DbRoutes.wsBasicUpdate(Config.amLargeValueWithdrawals, Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index].id, Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index].data) :
            which === 2 ? DbRoutes.wsBasicUpdate(Config.amTemporaryAccount, Main.Instance.DatabaseDataState.temporaryAccountStatus[row.index].id, Main.Instance.DatabaseDataState.temporaryAccountStatus[row.index].data) :
            which === 3 ? DbRoutes.wsBasicUpdate(Config.amPlayerActivity, Main.Instance.DatabaseDataState.noPlayerActivityStatus[row.index].id, Main.Instance.DatabaseDataState.noPlayerActivityStatus[row.index].data) :
            which === 4 ? DbRoutes.wsBasicUpdate(Config.amBlacklist, Main.Instance.DatabaseDataState.blacklist[row.index].id, Main.Instance.DatabaseDataState.blacklist[row.index].data) :
            which === 5 ? DbRoutes.wsBasicUpdate(Config.amMaximumBalance, Main.Instance.DatabaseDataState.maximumBalance[row.index].id, Main.Instance.DatabaseDataState.maximumBalance[row.index].data) :
            which === 6 ? DbRoutes.wsBasicUpdate(Config.amRetail, Main.Instance.DatabaseDataState.retail[row.index].id, Main.Instance.DatabaseDataState.retail[row.index].data) :
            which === 7 ? DbRoutes.wsBasicUpdate(Config.amRetailActions, Main.Instance.DatabaseDataState.retailActions[row.index].id, Main.Instance.DatabaseDataState.retailActions[row.index].data) :
            // which === 8 ? DbRoutes.wsBasicUpdate(Config.amNotify, Main.Instance.DatabaseDataState.notify[row.index].id, Main.Instance.DatabaseDataState.notify[row.index].data) :
            which === 9 ? DbRoutes.wsBasicUpdate(Config.amSelfAssessmentGifts, Main.Instance.DatabaseDataState.saGifts[row.index].id, Main.Instance.DatabaseDataState.saGifts[row.index].data) :
            which === 10 ? DbRoutes.wsBasicUpdate(Config.amSelfAssessmentCriteria, Main.Instance.DatabaseDataState.saCriteria[row.index].id, Main.Instance.DatabaseDataState.saCriteria[row.index].data) :
            which === 11 ? DbRoutes.wsBasicUpdate(Config.amSelfAssessmentQuestions, Main.Instance.DatabaseDataState.saQuestions[row.index].id, Main.Instance.DatabaseDataState.saQuestions[row.index].data) :
            which === 12 ? DbRoutes.wsBasicUpdate(Config.amNetLimitLossIncreased, Main.Instance.DatabaseDataState.netLimitLossIncreased[row.index].id, Main.Instance.DatabaseDataState.netLimitLossIncreased[row.index].data) :
            which === 13 ? DbRoutes.wsBasicUpdate(Config.amNetLimitLossRegistration, Main.Instance.DatabaseDataState.netLimitLossRegistration[row.index].id, Main.Instance.DatabaseDataState.netLimitLossRegistration[row.index].data) :
            which === 14 ? DbRoutes.wsBasicUpdate(Config.amTemporaryExclude, Main.Instance.DatabaseDataState.temporaryExclude[row.index].id, Main.Instance.DatabaseDataState.temporaryExclude[row.index].data) :
            which === 15 ? DbRoutes.wsBasicUpdate(Config.amTemporaryExcludeOptions, Main.Instance.DatabaseDataState.temporaryExcludeOptions[row.index].id, Main.Instance.DatabaseDataState.temporaryExcludeOptions[row.index].data) :
            which === 16 ? DbRoutes.wsBasicUpdate(Config.amDashboardBackend, Main.Instance.DatabaseDataState.dashboardBackend[row.index].id, Main.Instance.DatabaseDataState.dashboardBackend[row.index].data) :
            which === 17 ? DbRoutes.wsBasicUpdate(Config.amSelfExclusion, Main.Instance.DatabaseDataState.selfExclusion[row.index].id, Main.Instance.DatabaseDataState.selfExclusion[row.index].data) :
            which === 18 ? DbRoutes.wsBasicUpdate(Config.amCounselingCentres, Main.Instance.DatabaseDataState.counselingCentres[row.index].id, Main.Instance.DatabaseDataState.counselingCentres[row.index].data) :
            void 0
        }
        className = "btn btn-xs btn-warning"
    >
        {Assets.Update}
    </button>
    )
/*
    if (which === 0) {
        return (
            <button
                disabled = {}
                type = "button"
                onClick = {() => DbRoutes.wsBasicUpdate(Config.amLargeValueDeposits, Main.Instance.DatabaseDataState.largeValueDeposits[row.index].id, Main.Instance.DatabaseDataState.largeValueDeposits[row.index].data)}
                className = "btn btn-xs btn-warning"
            >
                {Assets.Update}
            </button>
        )
    } else if (which === 1) {
        return (
            <button
                disabled = {Main.Instance.DatabaseDataState.largeValueWithdrawal && Main.Instance.DatabaseDataState.largeValueWithdrawal_ && JSON.stringify(Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index]) === Main.Instance.DatabaseDataState.largeValueWithdrawal_[row.index]}
                type = "button"
                onClick = {() => DbRoutes.wsBasicUpdate(Config.amLargeValueWithdrawals, Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index].id, Main.Instance.DatabaseDataState.largeValueWithdrawal[row.index].data)}
                className = "btn btn-xs btn-warning"
            >
                {Assets.Update}
            </button>
        )
    }
*/
}

export function handleSwitch(value: boolean, index: number, id: number) {
    if (id === 24) {
        const noPlayerActivityStatus: NoPlayerActivityStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.noPlayerActivityStatus)
        noPlayerActivityStatus[index].data.accountSuspension = value === undefined ? true : !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {noPlayerActivityStatus})
    } else if (/*id === 0 ||*/ id === 1 || id === 2 || id === 35 || id === 36 || id === 37) {
        const temporaryAccountStatus: TemporaryAccountStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryAccountStatus)
        // if (id === 0) temporaryAccountStatus[index].data.depositSum = !value
        if (id === 1) { temporaryAccountStatus[index].data.accountSuspension = value === undefined ? true : !value } else if (id === 2) { temporaryAccountStatus[index].data.delete = !value } else if (id === 35) { temporaryAccountStatus[index].data.allowPlay = !value } else if (id === 36) { temporaryAccountStatus[index].data.allowDeposit = !value } else if (id === 37) { temporaryAccountStatus[index].data.allowWithdraw = !value }
        // temporaryAccountStatus[index].data.withdrawals = !value
        // else if (id === 29) temporaryAccountStatus[index].data.accountSuspension = value === undefined ? true : !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryAccountStatus})
    } else if (id === 3 || id === 27/* || id === 27*/ || id === 29 || id === 30 || id === 31 || id === 39) {
        const largeValueWithdrawal: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueWithdrawal)
        if (id === 3) {
            largeValueWithdrawal[index].data.oneTransaction = !value
        } else if (id === 27) {
            largeValueWithdrawal[index].data.accountSuspension = value === undefined ? true : !value
        } else if (id === 29) {
            // largeValueWithdrawal[index].data.allowPlay = !value
        } else if (id === 30) {
            // largeValueWithdrawal[index].data.allowDeposit = !value
        } else if (id === 31) {
            // largeValueWithdrawal[index].data.allowWithdraw = !value
        } else if (id === 39) {
            largeValueWithdrawal[index].data.letThrough = !value
        }
        // largeValueWithdrawal[index].data.oncePerPlayer = !value
        // else if (id === 27) largeValueWithdrawal[index].data.accountSuspension = value === undefined ? true : !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueWithdrawal})
    } else if (id > 4 && id < 16 || id === 26) {
        const blacklist: Blacklist[] = Object.assign ([], Main.Instance.DatabaseDataState.blacklist)
        if (id === 5) { blacklist[index].data.checkAtRegistration = !value } else if (id === 6) { blacklist[index].data.checkAtLogin = !value } else if (id === 7) { blacklist[index].data.allowCreateAccount = !value } else if (id === 8) { blacklist[index].data.allowPlay = !value } else if (id === 9) { blacklist[index].data.allowDeposit = !value } else if (id === 10) { blacklist[index].data.allowWithdraw = !value } else if (id === 11) { blacklist[index].data.allowLogin = !value } else if (id === 12) { blacklist[index].data.recieveMarketingPromotion = !value } else if (id === 13) { blacklist[index].data.checkBlackList = !value } else if (id === 14) { blacklist[index].data.checkNightJob = !value } else if (id === 15) { blacklist[index].data.allowAccountSuspension = !value } else if (id === 26) { blacklist[index].data.accountSuspension = value === undefined ? true : !value }
        Logic.Type.New(Main.Instance.DatabaseDataState, {blacklist})
    } else if (id > 15 && id < 19 || id === 25) {
        const maximumBalance: MaximumBalance[] = Object.assign ([], Main.Instance.DatabaseDataState.maximumBalance)
        if (id === 16) { maximumBalance[index].data.allowPlay = !value } else if (id === 17) { maximumBalance[index].data.allowDeposit = !value } else if (id === 18) { maximumBalance[index].data.allowWithdraw = !value } else if (id === 25) { maximumBalance[index].data.accountSuspension = value === undefined ? true : !value }
        Logic.Type.New(Main.Instance.DatabaseDataState, {maximumBalance})
    } else if (id === 19 || id === 20) {
        const retailActions: RetailAction[]  = Object.assign ([], Main.Instance.DatabaseDataState.retailActions)
        if (id === 19) { retailActions[index].data.notifyCS = !value } else if (id === 20) { retailActions[index].data.accountSuspension = !value }
        Logic.Type.New(Main.Instance.DatabaseDataState, {retailActions})
    } else if (id === 23 || /*id === 24 ||*/ id === 28 || id === 32 || id === 33 || id === 34 || id === 38) {
        const largeValueDeposits: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueDeposits)
        if (id === 23) {
            largeValueDeposits[index].data.oneTransaction = !value
        } else if (id === 28) {
            largeValueDeposits[index].data.accountSuspension = value === undefined ? true : !value
        } else if (id === 32) {
            // largeValueDeposits[index].data.allowPlay = !value
        } else if (id === 33) {
            // largeValueDeposits[index].data.allowDeposit = !value
        } else if (id === 34) {
            // largeValueDeposits[index].data.allowWithdraw = !value
        } else if (id === 38) { largeValueDeposits[index].data.letThrough = !value }
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueDeposits})
    }
/*
    else if (id === 21) custom.riskManagement.internalPlayerRisk.checks.checkAtLogin = !value
    else if (id === 22) custom.riskManagement.internalPlayerRisk.checks.checkAtRegistration = !value
*/
}

export function toggleCurrency(value: string, index: number, id: number) {
    // let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    const NewValue: 'N/A'|'CHF'|'EUR'|'USD' = toggleCurr(value)
/*
    else if (id === 3) custom.riskManagement.maximumAmount[index].currency = NewValue
*/
    // Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    
    if (id === 0) {
        const temporaryAccountStatus: TemporaryAccountStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryAccountStatus)
        temporaryAccountStatus[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryAccountStatus})
    } else if (id === 1) {
        const largeValueDeposits: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueDeposits)
        largeValueDeposits[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueDeposits})
    } else if (id === 2) {
        const largeValueWithdrawal: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueWithdrawal)
        largeValueWithdrawal[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueWithdrawal})
    } else if (id === 3) {
        const maximumBalance: MaximumBalance[] = Object.assign ([], Main.Instance.DatabaseDataState.maximumBalance)
        maximumBalance[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {maximumBalance})    
    }
}

export function sortNumber(a, b) {
    return a - b
}

export function AccountStatusSlider(handleRiskLevelID: number, rowIndex: number, rowValue: string, noEdit?: boolean) {
    const maxValue: number = AccountStatus.ClosedActive
    const defValue: number = +rowValue
    
    return(
        <input
            disabled = {noEdit ? true : false} 
            type = "range"
            list = "test"
            min = "0"
            max = {maxValue.toString()}
            step = "1"
            value = {defValue.toString()}
            onChange = {(e) => noEdit ? void 0 :
                xhandleRiskLevel(
                    handleRiskLevelID,
                    rowIndex,
                    +e.target.value,
                )
            }
            className = "slider"
        />
    )
}

function xhandleRiskLevel(id: number, index: number, value: number) {
    // let newValue: RiskLevel = value
    const newValue: RiskLevel|AccountStatus = value
/*
    let smallestDiff: number = 1000  // a big number
    // const RetailActions: RetailAction[]  = Object.assign ([], Main.Instance.DatabaseDataState.retailActions)
    if (id !== 6 || Main.Instance.DatabaseDataState.retailActions === undefined || Main.Instance.DatabaseDataState.retailActions.length === 0) {
        newValue = value
    } else {
        // value -> visji value, oziroma ce visji ne obstaja na najnizjega kar jih je
        let possibleValues: number [] = []
        for (const item of Main.Instance.DatabaseDataState.retailActions) {
            possibleValues.push (item.data.riskLevel)
        }
        // possibleValues = possibleValues.sort(sortNumber)

        for (const item of possibleValues) {
            if (Math.abs(item - value) < smallestDiff) {
                smallestDiff = Math.abs(item - value)
                newValue = item
            }
        }

        if (newValue === undefined) {
            newValue = possibleValues[0] 
        }
    }
*/
    if (id === 0) {
        const noPlayerActivityStatus: NoPlayerActivityStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.noPlayerActivityStatus)
        noPlayerActivityStatus[index].data.riskLevel = newValue as RiskLevel
        Logic.Type.New(Main.Instance.DatabaseDataState, {noPlayerActivityStatus})
    } else if (id === 1 || id === 10) {
        const temporaryAccountStatus: TemporaryAccountStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryAccountStatus)
        if (id === 1) { temporaryAccountStatus[index].data.riskLevel = newValue as RiskLevel } else if (id === 10) { temporaryAccountStatus[index].data.verificationStatus = newValue as AccountStatus }
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryAccountStatus})
    } else if (id === 2) {
        const blacklist: Blacklist[] = Object.assign ([], Main.Instance.DatabaseDataState.blacklist)
        blacklist[index].data.riskLevel = newValue as RiskLevel
        Logic.Type.New(Main.Instance.DatabaseDataState, {blacklist})
    } else if (id === 3 || id === 8 || id === 10) {
        const largeValueWithdrawal: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueWithdrawal)
        if (id === 3) {
            largeValueWithdrawal[index].data.riskLevel = newValue as RiskLevel
        } else if (id === 8) {
            largeValueWithdrawal[index].data.verificationStatus = newValue as AccountStatus
        } else if (id === 10) {
            largeValueWithdrawal[index].data.newAccountStatus = newValue as AccountStatus
        }
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueWithdrawal})
    } else if (id === 4 || id === 9 || id === 11) {
        const largeValueDeposits: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueDeposits)
        if (id === 4) {
            largeValueDeposits[index].data.riskLevel = newValue as RiskLevel
        } else if (id === 9) {
            largeValueDeposits[index].data.verificationStatus = newValue as AccountStatus
        } else if (id === 11) {
            largeValueDeposits[index].data.newAccountStatus = newValue as AccountStatus
        }
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueDeposits})
    } else if (id === 5) {
        const maximumBalance: MaximumBalance[] = Object.assign ([], Main.Instance.DatabaseDataState.maximumBalance)
        maximumBalance[index].data.riskLevel = newValue as RiskLevel
        Logic.Type.New(Main.Instance.DatabaseDataState, {maximumBalance})
    } else if (id === 6) {
        const retail: Retail[]  = Object.assign ([], Main.Instance.DatabaseDataState.retail)
        retail[index].data.riskLevel = newValue as RiskLevel
        Logic.Type.New(Main.Instance.DatabaseDataState, {retail})
    } else if (id === 7) {
        const retailActions: RetailAction[]  = Object.assign ([], Main.Instance.DatabaseDataState.retailActions)
        retailActions[index].data.riskLevel = newValue as RiskLevel
        Logic.Type.New(Main.Instance.DatabaseDataState, {retailActions})
    } else if (id === 12) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.riskManagement.fraudAlerts[index]['riskLevel'] = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}
/*
export function handleRiskLevel(id: number, index: number, value: number) {
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    let newValue: number
    if (id !== 6 || custom.riskManagement.internalPlayerRisk.actions === undefined || custom.riskManagement.internalPlayerRisk.actions.length === 0) {
        newValue = value === 4 ? 0 : value + 1
    } else {
        // value -> visji value, oziroma ce visji ne obstaja na najnizjega kar jih je
        let possibleValues: number [] = []
        for (const item of custom.riskManagement.internalPlayerRisk.actions) {
            possibleValues.push (item.riskLevel)
        }
        possibleValues = possibleValues.sort(sortNumber)

        for (const item of possibleValues) {
            if (item > value) {
                newValue = item
                break
            }
        }

        if (newValue === undefined) {
            newValue = possibleValues[0] 
        }
    }

    else if (id === 6) Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players[index].riskLevel = newValue
    else if (id === 7) Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.actions[index].riskLevel = newValue
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}


export function NotifyButton(i: number, row: any): JSX.Element {
    let color: string = 'risk risk-'
    color += RiskLevel[i]

    return (
        <button
            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
            onClick = {() => handleNotifyButton(row.value[i - 1] !== false ? false : true, row.index, i)}
            className = {row.value[i - 1] !== false ? 'btn btn-xs ' + color : 'btn btn-xs btn-default'}
        >
            {row.value[i - 1] === false ? Assets.doNotNotify : Assets.notify}
        </button>
    )
}

function handleNotifyButton(value: boolean, i: number, which: number) {
    const notify: Notify[] = Object.assign ([], Main.Instance.DatabaseDataState.notify)
    notify[i].data.riskLevel[which] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {notify})
}
*/
export function handleText(text: string, i: number, which: number) {
/*
    if (which === 0 || which === 1 || which === 2) {
        const notify: Notify[] = Object.assign ([], Main.Instance.DatabaseDataState.notify)
        if (which === 0) { notify[i].data.email = text } else if (which === 1) { notify[i].data.firstName = text } else if (which === 2) { notify[i].data.lastName = text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {notify})
    } else*/
    if (which === 3 || which === 4 || which === 5) {
        const retail: Retail[]  = Object.assign ([], Main.Instance.DatabaseDataState.retail)
        if (which === 3) { retail[i].data.firstName = text } else if (which === 4) { retail[i].data.lastName = text } else if (which === 5) { retail[i].data.comment = text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {retail})
    } else if (which === 6) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.responsibleGaming.issues.CSEmail = text
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function handleTextBirthday(text: string, i: number, which: number) {
    const maxYear: number = (new Date()).getFullYear() - 18
    if (which === 0 && text.length === 5 && text.charAt(0) !== '0') { text = text.substring (1,5) }
    if (which !== 0 && text.length === 3 && text.charAt(0) !== '0') { text = text.substring (1,3) }
    const retail: Retail[]  = Object.assign ([], Main.Instance.DatabaseDataState.retail)
    let dobYear: number = +retail[i].data.birthday.substring(0,4)
    let dobMonth: number = +retail[i].data.birthday.substring(5,7)
    let dobDay: number = +retail[i].data.birthday.substring(8, 10)
    if (which === 0) { dobYear = +text } else if (which === 1) { dobMonth = +text } else if (which === 2) { dobDay = +text }
    if (dobDay > 31) { dobDay = 31 }
    if (dobMonth > 12) { dobMonth = 12 }
    if (dobYear > maxYear) { dobYear = maxYear }
    if (dobDay === 31 && (dobMonth === 2 || dobMonth === 4 || dobMonth === 6 || dobMonth === 9 || dobMonth === 11 )) { dobDay = 30 }
    if (dobDay === 30 && dobMonth === 2) { dobDay = 29 }
    if (dobDay === 29 && dobMonth === 2 && dobYear % 4 !== 0) { dobDay = 28 }
    const DobMonth = dobMonth < 10 ? '0' + dobMonth : dobMonth 
    const DobDay = dobDay < 10 ? '0' + dobDay : dobDay
    const DobYear =  dobYear < 10 ? '000' + dobYear : dobYear < 100 ? '00' + dobYear : dobYear < 1000 ? '0' + dobYear : dobYear
    retail[i].data.birthday = DobYear + '-' + DobMonth + '-' + DobDay
    Logic.Type.New(Main.Instance.DatabaseDataState, {retail})
}

export function handleNumber(text: string, i: number, which: number, checkIfNumber?: boolean) {
    // console.log (text, i, which, checkIfNumber)
    text = text.replace(/ /g, '').replace(/,/g, '').replace(/'/g, '')
    if (checkIfNumber && isNaN(text as any)) { return }
    if (text === undefined) { text = '0' }
    // console.log (text, i, which, checkIfNumber)
    if (which === 0 || which === 1) {
        const noPlayerActivityStatus: NoPlayerActivityStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.noPlayerActivityStatus)
        if (which === 0) { noPlayerActivityStatus[i].data.years = +text } else if (which === 1) { noPlayerActivityStatus[i].data.months = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {noPlayerActivityStatus})
    } else if (/*which === 2 || which === 3 ||*/which === 4 || which === 5) {
        const temporaryAccountStatus: TemporaryAccountStatus[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryAccountStatus)
        // if (which === 2) temporaryAccountStatus[i].data.limit = +text
        // else if (which === 3) temporaryAccountStatus[i].data.daysOpened = +text
        if (which === 4) { temporaryAccountStatus[i].data.yearsBeforeDeletion = +text } else if (which === 5) { temporaryAccountStatus[i].data.monthsBeforeDeletion = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryAccountStatus})
    } else if (which === 6 || which === 7) {
        const largeValueWithdrawal: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueWithdrawal)
        if (which === 6) { largeValueWithdrawal[i].data.limit = +text } else if (which === 7) { largeValueWithdrawal[i].data.withinHours = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueWithdrawal})
    } else if (which === 8 || which === 9) {
        const largeValueDeposits: LargeValueDepositsWithdrawal[] = Object.assign ([], Main.Instance.DatabaseDataState.largeValueDeposits)
        if (which === 8) { largeValueDeposits[i].data.limit = +text } else if (which === 9) { largeValueDeposits[i].data.withinHours = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {largeValueDeposits})
    } else if (which === 10) {
        // console.log (+text)
        // console.log (Main.Instance.DatabaseDataState.maximumBalance)
        const maximumBalance: MaximumBalance[] = Object.assign ([], Main.Instance.DatabaseDataState.maximumBalance)
        maximumBalance[i].data.limit = +text
        Logic.Type.New(Main.Instance.DatabaseDataState, {maximumBalance})
    }
}
/*
export function AddNotify() {
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.notify && Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults) {
        DbRoutes.wsBasicInsert(Config.amNotify, {firstName: '', lastName: '', email: 'a.b@c.d', riskLevel: Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults})
    }
}
*/
function AddPlayer() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showInternalRiskModal: true})  
}

function AddAction() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showInternalRiskModal: true})  
}

/*
export function handleDeleteNotify(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.NotificationElement + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amNotify, row.original.id)
    }
}
*/

function handleDeletePlayer(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Player + ' ' + Assets.withName + ' "' + row.original.firstName + ' ' + row.original.lastName + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amRetail, row.original.id)
    }
}

function handleDeleteAction(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Action + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amRetailActions, row.original.id)
    }
}
/*
function Delete(id: number, which: number) {
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 0) custom.riskManagement.notify.splice(which, 1)
    else if (id === 7) custom.riskManagement.internalPlayerRisk.actions.splice(which, 1)
    Logic.Type.New (Main.Instance.DatabaseDataState, {custom})
    if (id === 0) Logic.Type.New (Main.Instance.RiskManagementState, {filteredData: undefined, riskLevel: RiskLevel.Undefined})
    else Logic.Type.New (Main.Instance.RiskManagementState, {filteredData: undefined, minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined})
}
*/
/*
function countInternalPlayerRiskMembers(riskLevel: RiskLevel): number {
    let members: number = 0
    if (Main.Instance.RiskManagementState.filteredData === undefined) {
        if (
            Main.Instance.DatabaseDataState.custom &&
            Main.Instance.DatabaseDataState.custom.riskManagement &&
            Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk &&
            Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players)
        for (const item of Main.Instance.DatabaseDataState.custom.riskManagement.internalPlayerRisk.players) {
            if (item.riskLevel === riskLevel) {
                members++
            }
        }
    } else {
        for (const item of Main.Instance.RiskManagementState.filteredData) {
            if (item.riskLevel === riskLevel) {
                members++
            }
        }
    }
    return members
}
*/

export function getPlayerData(playerId: string, nav: PlayerCardButtons/*, username: string*/): JSX.Element {
    // const nav: PlayerCardButtons = subType === TimelineEntrySubType.KnowYourCustomer ? PlayerCardButtons.KnowYourCustomer : TimelineEntrySubType.ResponsibleGaming ? PlayerCardButtons.ResponsibleGaming : TimelineEntrySubType.RiskManagement ? PlayerCardButtons.RiskManagement : PlayerCardButtons.EventsTimeline
    return (
        <div>
            <button 
                title = {Assets.SelectPlayerWithId + ' ' + playerId}
                onClick = {() => SelectPlayer(playerId, nav)}
                className = "btn-default fr" 
                style = {{lineHeight: '18px', fontSize: '20px', padding: '0 1px', marginRight: '0'}}
            >
                <FontAwesomeIcon icon = {faAddressCard}/>
            </button>
            <div className = "player-data">{playerId}</div>
            {/*<div className = "lh14 player-data">{username}</div>*/}
        </div>
    )
}
/*
export function getPlayerData(playerId: string, nav: PlayerCardButtons) {
    if (Main.Instance.DatabaseDataState.custom.general.players)
    for (const item of Main.Instance.DatabaseDataState.custom.general.players) {
        if (item.id === playerId) {
            // return <div title = {item.firstName + ', ' + item.birthday}>{item.lastName + ' ' + item.firstName.substring(0,1) + '.'}</div>
            return (
                <div 
                    title = {
`Player ID: ${playerId},
Firstname: ${item.firstName},
Lastname: ${item.lastName},
Birthday: ${item.birthday}
`}
                >
                    <button 
                        onClick = {() => SelectPlayer(playerId, nav)} 
                        className = "btn-default fr" 
                        style = {{lineHeight: '18px', fontSize: '20px', padding: '0 1px', marginRight: '0'}}
                    >
                        <FontAwesomeIcon 
                            icon = {faAddressCard}
                        />
                    </button>
                    <div className = "lh14 player-data">{item.firstName}</div>
                    <div className = "lh14 player-data">{item.lastName}</div>
                    <div className = "lh14 player-data">{item.birthday}</div>
                </div>
            )
        }
    }
    return ''
}
*/

/*
function getPlayerFirstName(playerId: number) {
    if (Main.Instance.DatabaseDataState.custom.general.players)
    for (const item of Main.Instance.DatabaseDataState.custom.general.players) {
        if (item.id === playerId) {
            return item.firstName
        }
    }
    return ''
}

function getPlayerLastName(playerId: number) {
    if (Main.Instance.DatabaseDataState.custom.general.players)
    for (const item of Main.Instance.DatabaseDataState.custom.general.players) {
        if (item.id === playerId) {
            return item.lastName
        }
    }
    return ''
}

function getPlayerBirthday(playerId: number) {
    if (Main.Instance.DatabaseDataState.custom.general.players)
    for (const item of Main.Instance.DatabaseDataState.custom.general.players) {
        if (item.id === playerId) {
            return item.birthday
        }
    }
    return ''
}
*/

export function getRiskAlertsTable() {
    return (
        <>
            {/*
            <button
                className = {Main.Instance.RiskManagementState.filterOnlyElevatedRisk === true ? 'btn btn-warning btn-xs fl' : 'btn btn-default btn-xs fl'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterOnlyElevatedRisk: !Main.Instance.RiskManagementState.filterOnlyElevatedRisk})}
            >
                {Assets.ShowOnlyElevatedRisk}
            </button>
            */}
            <button
                style = {{marginRight: '0px'}}
                className = {Main.Instance.RiskManagementState.filterResolved === undefined ? 'btn btn-primary btn-xs fr' : 'btn btn-default btn-xs fr'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: undefined})}
            >
                {Assets.AllRiskAlerts}
            </button>
            <button
                className = {Main.Instance.RiskManagementState.filterResolved === true ? 'btn btn-success btn-xs fr' : 'btn btn-default btn-xs fr'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: true})}
            >
                {Assets.ResolvedRiskAlerts}
            </button>
            <button
                className = {Main.Instance.RiskManagementState.filterResolved === false ? 'btn btn-warning btn-xs fr' : 'btn btn-default btn-xs fr'} 
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: false})}
            >
                {Assets.UnresolvedRiskAlerts}
            </button>
            <br/>
            <br/>
            {getControlPanel1(true)}
            {getIssuesTable(false, Main.Instance.DatabaseDataState.issues1, TimelineEntrySubType.RiskManagement)}
        </>
        
    )
}