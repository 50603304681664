import {Logic} from '../Type'
import {TimelineEntrySubType} from '../Database/DatabaseData'
export enum RiskLevel {Undefined, Low, Normal, Medium, High, Severe}
export enum DashboardRuleType {DepositMethods = 0, DepositAfterCoolOff, CancelledWithdrawals, GamingTime, NetLoss, InitiatedCoolOffs, IncreasedNetLoss}
export enum SectionButtonRM {Nothing = -1, /*TemporaryAccountStatus, NoPlayerActivity, LargeValueDeposit, LargeValueWithdrawal, BlacklistCheck, MaximumAmountOnPlayerWallet,*/ RiskAlerts, InternalPlayerRisk}
export enum SectionButtonRG {Nothing = -1, NetLimitLoss, NetLimitLossRegistration, TemporaryExclude, CSCallbackButton, PermanentExclusion, SelfAssessment, DashboardBackend, BlacklistCheck}
export enum SectionButtonKYC {Nothing = -1, TemporaryAccountStatus, NoPlayerActivity, LargeValueDeposit, LargeValueWithdrawal, MaximumAmountOnPlayerWallet, RecentUploads, Settings}

export enum PlayerCardButtons {Nothing = -1, EventsTimeline, Transactions, /*PlayingHistory,*/ GameSessions, RiskManagement, ResponsibleGaming, KnowYourCustomer, Gamification, Promotions, GiftShop, Tournaments}
export enum PlayerCardButtonsET {Everything = 0, AllTransactions, AllDeposits, AllWithdrawals, Deposits, Withdrawals, ManualDeposits, CanceledDeposits, ManualWithdrawals, CanceledWithdrawals, AllSessions, Closed, Open, AllIssues, RiskManagement, ResponsibleGaming, KnowYourCustomer, Gamification}
export enum PlayerCardButtonsETT {Nothing = -1, AllTransactions, AllDeposits, AllWithdrawals, Deposits, ManualDeposits, CanceledDeposits, Withdrawals, ManualWithdrawals, CanceledWithdrawals}
export enum PlayerCardButtonsTR  {Nothing = -1, AllTransactions, AllDeposits, AllWithdrawals, Deposits, ManualDeposits, CanceledDeposits, Withdrawals, ManualWithdrawals, CanceledWithdrawals}
// PlayerCardButtonsETT mora bit identicen kot PlayerCardButtonsTR
export enum PlayerCardButtonsPH {Nothing = -1, AllTransactions, Deposits, Withdrawals, Wagers, Wins, WagerCancel, WinCancel, BonusAdd, BonusRedeem}
export enum PlayerCardButtonsPS {Nothing = -1, AllSessions, Closed, Open}
export enum PlayerCardButtonsTS {Nothing = -1, AnyStatus = 0, Approved, Rejected, Pending, Canceled, Executed, Completed, DeniedByBank}
export enum PlayerCardButtonsRM {Nothing = -1, AllIssues = 0, /*TemporaryAccount = 101,*/ Retail = 101, FraudAlerts = 110, 
    ThreeOrMoreDepositsBelow100WithinTenMinutes,
    AccountCreatedWithinLastTenDaysAndDepositsOver10000,
    WinRatioOver80PcAndBetsOver1000,
    IpForDepositNotSameAsRegistration,
    IpNotFromEuOrNotSameAsCreditCard,
    IpFromEuNotSwitzerland,
    AnonymousProxyForDepositRecognized,
    LowGamingActivityDepositsOver1000,
    AccountCreatedWithinLastTenDaysAndWithdrawalsOver5000,
    UnusualBonusDepositRatio101,
/*
    ThreeOrMoreDepositMethods = 151,
    DepositAfterCoolOff,
    CanceledThreeOrMoreWithdrawals,
    AverageGamingTime,
    NetLossPerHour,
    NetLossThirtyDays,
    NetLossTwelveMonths,
    FourOrMoreCooloffs,
    IncreasedNetLossLimits,
*/
    Other = 199}
export enum PlayerCardButtonsRG {Nothing = -1, Overview = 0, AllIssues, CallbackRequest = 201, PermanentExclusion, SelfAssessment, CooloffsChanged, LimitsChanged, BlacklistVeto = 210, BlacklistRegistration, BlacklistExclusion, BlacklistLogin, BlacklistCronJob, BlacklistManual, BlacklistWhitelisted, DashboardBackend = 250, DepositMethods, DepositCooloff, CancelledWithdrawals, GamingTime, /*HigherNetloss,*/ NetLossPerHour, NetLossThirtyDays, NetLossTwelveMonths, InitiatedCooloffs, IncreasedNetloss, Other = 299}
export enum PlayerCardButtonsKYC {Nothing = -1, Overview = 0, AllIssues, UploadDocument = 301, UploadUtility, VerifiedDocument, VerifiedUtility, VerifiedEmail, ChangedEmail, VerifiedAddress, ChangedPhoneNumber, ChangedBankingDetails, SamePlayer, ChangedNationality, ChangedTestAccount, ManualUploadDocument, ManualUploadUtility, VerifiedMobile, ChangedUsername, TemporaryAccount = 320, PlayerActivity = 330, MoneyStuff = 340, LargeDeposit, LargeWithdrawal, MaximumBalance, ManualWithdrawal, ManualDeposit, AccountStatusChange = 350, AccountClosed = 360, AccountClosedProvisional, AccountClosedActive, CrifPep = 369, CRIFCheckMismatch, CRIFCheckLogin, CRIFCheckRegistration, CRIFAddressChangedPlayer, CRIFAddressChangedOperator, PEPCheckLogin, PEPCheckRegistration, PEPCheckNameChanged, PEPCheckBeneficaryName, PEPChangedByOperator, Bally = 380, BallyRegistrationSuccess, BallyRegistrationFailed, Other = 399}
export enum PlayerCardButtonsPR {Nothing = -1, Everything, Bonus, Raffle, Other}
export enum PlayerCardButtonsGM {Nothing = -1, AllIssues = 0, Bonuses = 410, BonusAdd, BonusActivate, BonusCancel, BonusCancelAll, BonusAddManual, BonusAddManualBonusCode, BonusActivateManual, BonusCancelManual, BonusCancelAllManual}

import {IGameSession, IGameSessionTransaction, IBonusInfo, IIssueHistory, IBonusInfoFreeGames} from '../Database/DatabaseData'
import * as moment from 'moment'

export enum Currency {N_A, CHF, EUR, USD}
export enum AddBonusOptions {ByAmount = 0, ByCode, ByCampaign, /*DepositBonus*/}

export enum BonusTypeNavigation {Money, FreeGames}
/*
const fgs1: Array<{supplierRoundId: string, roundId: string, Timestamp?: string, timestamp: string, realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number}> = [
    {supplierRoundId: 'a292', roundId: '0c8d6948', timestamp: '2019-06-09 09:01:24', bonusWagered: 0.2},
    {supplierRoundId: '437b', roundId: 'cd433bc4', timestamp: '2019-06-09 09:01:30', bonusWagered: 0.2},
    {supplierRoundId: 'ad2b', roundId: 'c5665a51', timestamp: '2019-06-09 09:01:35', bonusWagered: 0.2},
    {supplierRoundId: '216d', roundId: '91681a1b', timestamp: '2019-06-09 09:01:40', bonusWagered: 0.2},
    {supplierRoundId: 'd1cc', roundId: 'f2776dc4', timestamp: '2019-06-09 09:01:46', bonusWagered: 0.2},
    {supplierRoundId: 'a8be', roundId: '2f46aa6b', timestamp: '2019-06-09 09:01:52', bonusWagered: 0.2},
    {bonusWon: 0.06, Timestamp: '2019-06-09 09:01:58', supplierRoundId: '76ed', roundId: 'e63ff7ba', timestamp: '2019-06-09 09:01:57', bonusWagered: 0.2},
    // {supplierRoundId: '76ed', roundId: '7', bonusWon: 0.06, timestamp: '2019-06-09 09:01:58'},
    {supplierRoundId: 'a39a', roundId: '708bc03d', timestamp: '2019-06-09 09:02:06', realWagered: 0.1, bonusWagered: 0.1},
]

const fgs2: Array<{supplierRoundId: string, roundId: string, Timestamp?: string, timestamp: string, realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number}> = [
    {supplierRoundId: '72a2', roundId: 'cbad9825', timestamp: '2019-06-09 09:02:31', realWagered: 0.2},
    {realWon: 0.12, Timestamp: '2019-06-09 09:02:39', supplierRoundId: 'af3e', roundId: '88d2eef6', timestamp: '2019-06-09 09:02:38', realWagered: 0.2},
    // {supplierRoundId: 'af3e', roundId: '2', realWon: 0.12, timestamp: '2019-06-09 09:02:39'},
    {supplierRoundId: '4f13', roundId: 'c3aafe66', timestamp: '2019-06-09 09:02:49', realWagered: 0.2},
]
*/
export class PlayerCardState extends Logic.Type {
    public showSearchSettings: boolean = false
    public gettingGameSessions: boolean = false
    public selectingGameSessionId: boolean = false
    public bonusCampaignIdSelected: number
    public bonusesSelected: number[]
    public bonusIdSelected: number
    public bonusCodesSelected: string[]

    public BonusWageringRequirement: AddBonusOptions = AddBonusOptions.ByAmount
    public BonusAdd: boolean = false
    public BonusAmount: number = 0
    public BonusCode: string = ''
    public BonusCancel: boolean = false
    public BonusCancelAll: boolean = false
    public BonusActivate: boolean = false
    public BonusAddManual: boolean = false
    public BonusAddManualCode: boolean = false
    // public BonusAddManualDeposit: boolean = false
    public DepositBonusAmount: number = 0
    public BonusId: number = -1
    public WageringRequirement: number
    public QualifyingShareAmount: number
    public comment: string

    public bonusInfo: IBonusInfo[]
    public bonusInfoFreeGames: IBonusInfoFreeGames[]
    public BonusInfoGet: boolean = false
    public bonusTypeNavigation: BonusTypeNavigation = BonusTypeNavigation.Money
    // public BonusInfo: boolean = false

    public gameSessionSelectedId: string
    public gameSessionSelectedIdExternal: string
    public gameSessionSelectedGame: string
    public gameSessionPage: number = 0
    public gameSessionLimit: number = 10
    public gameSessions: IGameSession[]
    public gameSessionsCount: number = 0
    public GameSessionsGet: boolean = false
    public GameSessions: boolean = false

    public gameSessionTransactionsPage: number = 0
    public gameSessionTransactionsLimit: number = 10
    public gameSessionsTransactions: IGameSessionTransaction[]
    public gameSessionsTransactionsCount: number = 0
    public GameSessionsTransactionsGet: boolean = false


    // public fgs: Array<{realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number, transactions: Array<{supplierRoundId: string, roundId: string, timestamp: string, realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number}>, selected: boolean, gameSessionId: string, supplierGameSessionId: string, start: string, end: string, gameId: number, gameName: string}> = []
/*
    public fgs: Array<{realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number, transactions: Array<{supplierRoundId: string, roundId: string, timestamp: string, realWagered?: number, bonusWagered?: number, freeWagered?: number, realWon?: number, bonusWon?: number, pjc?: number, pjw?: number, fga?: number}>, selected: boolean, gameSessionId: string, supplierGameSessionId: string, start: string, end: string, gameId: number, gameName: string}> = [
        {
            selected: true,
            gameSessionId: '6e4411fc-e53c-46de-bd33-675e8a045e6c',
            supplierGameSessionId: '37e5be32-f597-44ae',
            start: '2019-06-09 09:02:31',
            end: '2019-06-09 09:02:49',
            gameId: 234,
            gameName: 'Thors Lightning',
            transactions: fgs2,
            realWon: 0.12,
            bonusWon: 0,
            realWagered: 0.6,
            bonusWagered: 0,
            // wagers: 3
        },
        {
            selected: true,
            gameSessionId: '5826689a-01bd-4925-94b9-f08d25a2a7e3',
            supplierGameSessionId: '4b3be1fb-9292-4104',
            start: '2019-06-09 09:01:24',
            end: '2019-06-09 09:02:06',
            gameId: 1,
            gameName: 'Casa Donatella',
            transactions: fgs1,
            realWon: 0,
            bonusWon: 0.06,
            bonusWagered: 1.5,
            realWagered: 0.1
            // wagers: 8
        },
    ]
*/
    public filterDateFrom: moment.Moment = null
    public filterDateTo: moment.Moment = null
    public filterTimeFrom: string = ''
    public filterTimeTo: string = ''
    public focusedInputFrom: any = null
    public focusedInputTo: any = null

    public toggleTopView: boolean = true
    public onlyPlayerId: boolean = undefined 
    public showComments: boolean = false
    public filterIssues: boolean = false
    public nav: PlayerCardButtons = PlayerCardButtons.Transactions // PlayerCardButtons.EventsTimeline
    public navTR: PlayerCardButtonsTR = PlayerCardButtonsTR.AllTransactions
    public navTS: PlayerCardButtonsTS = PlayerCardButtonsTS.AnyStatus
    public navET: PlayerCardButtonsET = PlayerCardButtonsET.Everything
    public navRG: PlayerCardButtonsRG = PlayerCardButtonsRG.Overview
    public navPH: PlayerCardButtonsPH = PlayerCardButtonsPH.AllTransactions
    public navPS: PlayerCardButtonsPS = PlayerCardButtonsPS.AllSessions
    public navRM: PlayerCardButtonsRM = PlayerCardButtonsRM.AllIssues
    public navKYC: PlayerCardButtonsKYC = PlayerCardButtonsKYC.Overview
    public navGM: PlayerCardButtonsGM = PlayerCardButtonsGM.AllIssues
    public navPR: PlayerCardButtonsPR = PlayerCardButtonsPR.Everything
    public navETT: PlayerCardButtonsETT = PlayerCardButtonsETT.AllTransactions
    
    public navIssue: number = 0
    // public search_id: number
    public search_string: string
    public id: string = '0'
    // player: Player = {}
    // playerSelectionDisabled: boolean = false
    public tag: string = ''
    // expandedPlayerCard: boolean = false
    public hiddenPlayerCard: boolean = false
    public forcePlayerCardSelect: boolean = false
    // selectedResponsibleGamingLimit: number = 0

    public toggleInfo: boolean = false
    public issueDetailsData: string = 'Status'
}

export enum SelectedPlayerSetting {Registration, Countries, Regions, PostalCodes, Nationalities, PlayerTags, PlayerInformation}
export enum SelectedGenericSetting {Locales, Currencies, CooloffCategories/*, InternalDescriptions*//*, SystemCommunication*//*, PlayerInformation, InternationalSettings*/}
export enum SelectedRiskComplianceSetting {/*Notifications, */Cooloffs, Limits/*TimePeriods*/, RiskManagement, ResponsibleGaming, KnowYourCustomer, /*LimitTypes,*/ AccountStatus/*, ProvisionalAccount*/, FraudAlerts, Other}
export enum SelectedGamesSetting {GameCategories, GameLabels/*, GameLabelsStrapiGameStore, Jurisdictions*/, SupportedDevices}
export enum SelectedFrontEndSetting {GameCategories, GameLabels/*, GameLabelsStrapiGameStore, Jurisdictions, SupportedDevices*/}
export enum SelectedAdminSetting {ProvisionalAccount, InternationalSettings, ApiKeys/*, InternalDescriptions*/}
export enum SelectedPaymentSetting {Account}
export enum SelectedBonusSetting {GloballyExcludedTags}

export class RiskManagementState extends Logic.Type {
    public issueSource: TimelineEntrySubType|undefined
    public playerId: string
    public selectedGamesSetting: SelectedGamesSetting = SelectedGamesSetting.GameCategories
    public selectedGenericSetting: SelectedGenericSetting = SelectedGenericSetting.Locales
    public selectedRiskComplianceSetting: SelectedRiskComplianceSetting = SelectedRiskComplianceSetting.Cooloffs
    public selectedPlayerSetting: SelectedPlayerSetting = SelectedPlayerSetting.Registration
    public selectedFrontEndSetting: SelectedFrontEndSetting = SelectedFrontEndSetting.GameCategories
    public selectedAdminSetting: SelectedAdminSetting = SelectedAdminSetting.ProvisionalAccount
    public selectedPaymentSetting: SelectedPaymentSetting = SelectedPaymentSetting.Account
    public selectedBonusSetting: SelectedBonusSetting = SelectedBonusSetting.GloballyExcludedTags

    public showCommentsRM: boolean[]
    public showCommentsRG: boolean[]
    public showCommentsSettings: boolean = false
    public language: number = 0
    public showLegend: boolean = false
    // filteredData: any[]
    // showDefaults: boolean = false
    public sectionButtonRM: SectionButtonRM = SectionButtonRM.InternalPlayerRisk
    public sectionButtonRG: SectionButtonRG = 99// SectionButtonRG.NetLimitLoss
    public sectionButtonKYC: SectionButtonKYC = 99// SectionButtonKYC.TemporaryAccountStatus

    // public sectionButtonRG: SectionButtonRG = SectionButtonRG.CSCallbackButton
    // public sectionButtonKYC: SectionButtonKYC = SectionButtonKYC.RecentUploads

    public toggleInternalRiskPlayersActions: boolean = true
    public minRiskLevel: number = RiskLevel.Undefined
    public maxRiskLevel: number = RiskLevel.Undefined
    public riskLevel: number = RiskLevel.Undefined
    // showChart: boolean = false
    public showFilters: boolean = false
    public filterFirstName: string = undefined
    public filterLastName: string = undefined
    public filterBirthdayYear: number = undefined
    public filterBirthdayMonth: number = undefined
    public filterBirthdayDay: number = undefined
    public filterComment: string = undefined
    public toggleTemporaryExclude: boolean = true
    public toggleDashboardBackend: boolean = true
    public toggleSelfAssessment: boolean = true
    public showMap: boolean = false
    public ls: boolean = true
    public lc: boolean = true
    public lg: boolean = true
    public dg: boolean = true
    public showInternalRiskModal: boolean = false
    // showAddRiskAlertsModal: boolean = false
    // issueModal: boolean = false
    public showAddCSCallbackModal: boolean = false
    public showAddTempAccountStatus: boolean = false
    public showAddNoPlayerActivity: boolean = false
    public showAddLargeWithdrawal: boolean = false
    public showAddLargeDeposit: boolean = false
    public showAddMaxAmountWallet: boolean = false
    public showAddNetLimitLoss: boolean = false
    public showAddNetLimitLossReg: boolean = false
    public showAddTempExclude: boolean = false
    public showAddDashboardBackend: boolean = false
    public showAddSelfAssessment: boolean = false
    // showAddKYCModal: boolean = false
    public dashboardRuleType: DashboardRuleType = DashboardRuleType.DepositMethods
    public toggleCSCallback: boolean = true
    // filterResolvedRiskManagement: boolean = false
    public filterResolved: boolean = false
    public rowId: number = -1
    public mode: boolean
    // filterOnlyElevatedRisk: boolean = false
    public history: IIssueHistory[]
    public addLanguage: string = ''
    public addLanguageLocale: string = ''
    public addLanguageShort: string = ''
    public addTimeUnit: string = ''
    public addTimePeriod: string = ''
    public addCurrency: string = ''
    public addCommunication: string = ''
    public addLimitType: string = ''
    public addCountry: string = ''
    public addNationality: string = ''
    public addCountryCode: string = ''
    public addNationalityCode: string = ''
    public addNationalityCode2: string = ''
    public addCountryDialCode: string = ''
    public addJurisdiction: string = ''
    public addJurisdictionCode: string = ''
    public addJurisdictionTier: string = ''
    public allowedCountry: string = '0'
    public allowedNationality: string = '0'
    public allowedJurisdiction: string = '0'
    public allowedJurisdictionTier: string = '-1'
    public accountExpiryLimit: string = '0'
    // postal codes
    public addPostalCode: string = ''
    public addPostalName: string = ''
    public addPostalRegion: string = ''
    // postalCodeFilter: string
    public postalCodeCountrySelected: string
    public postalCodeRegionSelected: number = -1
    public postalCodeSelected: number = -1
    // postalCodeGroup: boolean = true // true = group by canton, false = no group
    // postalCodeOrder: boolean = true // true = by Code, false = by Name
    public selectedPage: number = 0
}