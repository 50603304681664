import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {Logic} from '../../../Logic/Type'
import ReactTable from 'react-table'
import {FileExportGames, Tag, Casinogame} from '../../../Logic/Database/DatabaseData'
import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {getStrapiCategoryId, getProvider} from './GameList'

export function FileExport(): JSX.Element {
    if (!Main.Instance.DatabaseDataState.strapiConnected) {
        return <h4>{Assets.Strapi + ' ' + Assets.Failed.toLowerCase() + Assets._to_ + Assets.Load.toLowerCase()}</h4>
    }
    if (!Main.Instance.DatabaseDataState.gamesList) {
        return (<div>{Assets.LoadingData}</div>)
    }

    if (Main.Instance.DatabaseDataState.strapiConnected && Main.Instance.DatabaseDataState.gamesList && !Main.Instance.DatabaseDataState.gamesListExport) {
        setGames(Main.Instance.DatabaseDataState.gamesList)
    }
    DbRoutes.wsReinitialize()


    let Exists: number = 0
    if (Main.Instance.DatabaseDataState.gamesListExport) {
        for (const item of Main.Instance.DatabaseDataState.gamesListExport) {
            if (item.Exists) {Exists++}
        }
    }

    if (!Main.Instance.DatabaseDataState.gamesList) {return <div/>}
    const gamesToBeExported: number = Main.Instance.DatabaseDataState.gamesListExport.length - Exists 

    return (
        <div>
            <h2>{Assets.ExportToCMS}</h2>
            
            <div>
                <div className = "pr">
                    {Main.Instance.DatabaseDataState.gamesList ?
                    <>
                    <button  onClick = {() => exportAllGamesCMS()} className = "btn btn-warning btn-xs" title = {Assets.ExportAllGamesToCMS}>
                        {Assets.ExportAllGamesToCMS}
                    </button>
                    <span style = {{fontSize: '14px', fontWeight: 400}} className = {gamesToBeExported === 0 ? 'label label-default label-sm' : 'label label-danger label-sm'}>
                        {(gamesToBeExported) + ' ' + (gamesToBeExported === 1 ? Assets.gameNeedsToBeExportedToCMS : Assets.gamesNeedToBeExportedToCMS)}
                    </span>
                    <div style = {{position: 'absolute', right: '0px', top: '0px'}}>
                        <button onClick = {() => {toggleCMS(Main.Instance.DatabaseDataState.fileExportCMS); CmsExportFilterGames()}} className = {Main.Instance.DatabaseDataState.fileExportCMS === undefined ? 'btn btn-default btn-xs' : Main.Instance.DatabaseDataState.fileExportCMS === true ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}>
                        {Assets.CMS}&nbsp;{(Main.Instance.DatabaseDataState.fileExportCMS === undefined ? Assets.All : Main.Instance.DatabaseDataState.fileExportCMS === true ? Assets.Imported : Assets.Inexisting) + ' ' + Assets.Games}&nbsp;&nbsp;<span className = "badge badge-light">{Main.Instance.DatabaseDataState.fileExportCMS === undefined ? Main.Instance.DatabaseDataState.gamesListExport.length : Main.Instance.DatabaseDataState.fileExportCMS === true ? Exists : gamesToBeExported}</span>
                        </button>
                        <select onChange = {(e) => {Logic.Type.New (Main.Instance.DatabaseDataState, {fileExportCategory: e.target.value}); CmsExportFilterGames()}} style = {{height: '22px', background: '#fff'}} value = {Main.Instance.DatabaseDataState.fileExportCategory ? Main.Instance.DatabaseDataState.fileExportCategory : '-1'}>
                            {getCategoriesOptions()}
                        </select>
                    </div>
                    </> : void 0}
                </div>
                <br/>
                {Main.Instance.DatabaseDataState.gamesListExport ?
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.gamesListExport}
                    columns = {[
                        {width: 50, Header: <div>{Assets.ID}</div>, accessor: 'providerCategory', headerClassName: 'th-center', Cell: (row) => <div style = {{textAlign: 'center'}} className = {getBackground(row.original.Exists)}>{row.original.gameId}</div>},
                        {width: 250, Header: <div>{Assets.GameName + ' (' + Assets_.productName[0] + ')'}</div>, accessor: 'isJackpot', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}><span style = {{fontWeight: 600}}>{row.original.gameName ? row.original.gameName : Assets.Unknown}&nbsp;</span></div>},
                        {width: 200, Header: <div>{Assets.GameProvider}</div>, accessor: 'providerId', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}><span style = {{fontWeight: 600}}>{row.value ? getProvider(row.value) : Assets.Unknown}</span></div>},
                        {width: 50, Header: <div title = {Assets.ExistsInCMS}>{Assets.CMS}</div>, accessor: 'Exists', headerClassName: 'th-center', Cell: (row) => <div className = {getBackground(row.original.Exists)}><div style = {{fontWeight: 700, textAlign: 'center'}}>{row.value ? Assets.Yes : Assets.No}</div></div>},
                        {width: 160, Header: <div>{Assets.UpdatedAt + ' (' + Assets_.UTC + ')'}</div>, accessor: 'UpdatedAt', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}>{row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'}</div>},
                        {width: 160, Header: <div>{Assets.CreatedAt + ' (' + Assets_.UTC + ')'}</div>, accessor: 'CreatedAt', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}>{row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'}</div>},
                        {width: 110, Header: <div>{Assets.CMS + ' ' + Assets.Category}</div>, accessor: 'Category', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}>{row.value ? getCategoryName(row.value) : '-'}</div>},
                        {Header: <div>{Assets.CMS + ' ' + Assets.Tags}</div>, accessor: 'Tags', headerClassName: 'th-left', Cell: (row) => <div className = {getBackground(row.original.Exists)}>{getTags(row.value)}</div>},
                        {sortable: false, width: 30, Header: <div title = {Assets.ExportToCMS}><FontAwesomeIcon icon = {faFileExport}/></div>, accessor: 'Exists2', headerClassName: 'th-center', Cell: (row) => <div className = {getBackground(row.original.Exists)}><button disabled = {row.original.Exists || row.original.sameName} onClick = {() => exportGameCMS(row.original)} className = "btn btn-warning btn-xs mr0" title = {Assets.ExportToCMS}>{Assets_.strapi('#fff', 14, 19, 7)}&nbsp;&nbsp;</button></div>},
                    ]}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    defaultPageSize={10}
                /> : void 0}
                </div>
        </div>
    )
}

function getCategoryName(categoryId): string {
    if (Main.Instance.DatabaseDataState.scategories) {
        for (const item of Main.Instance.DatabaseDataState.scategories) {
            if (item.categoryId === categoryId) {
                return item.name
            }

        }
    }
    return '-'
}

export function CmsExportFilterGames() {
    const gamesListExport: FileExportGames[] = []
    for (const item of Main.Instance.DatabaseDataState.gamesList) {
        if (Main.Instance.GamesCentralState.search_string !== undefined && Main.Instance.GamesCentralState.search_string.length > 0) {
            if (isNaN(Main.Instance.GamesCentralState.search_string as any)) {
                if (item.gameName && item.gameName.toLowerCase().indexOf(Main.Instance.GamesCentralState.search_string.toLowerCase()) === -1) {
                    continue
                }
            } else {
                if (item.gameId.toString().indexOf(Main.Instance.GamesCentralState.search_string) === -1) {
                    continue
                }
            }
        }
        if (Main.Instance.DatabaseDataState.fileExportCategory && Main.Instance.DatabaseDataState.fileExportCategory !== '-1') {
            if (getStrapiCategoryId(item.gameId) !== +Main.Instance.DatabaseDataState.fileExportCategory) {
                continue
            }
        }
        if (Main.Instance.DatabaseDataState.fileExportCMS !== undefined) {
            let found: boolean = false
            for (const Item of Main.Instance.DatabaseDataState.casinoGames) {
                if (Item.gameId === item.gameId) {
                    found = true
                }
            }
            if (Main.Instance.DatabaseDataState.fileExportCMS && !found || 
                !Main.Instance.DatabaseDataState.fileExportCMS && found) {
                    continue
                }
            
        }
        
        gamesListExport.push(item)
    }
    setGames(gamesListExport)
}

function exportAllGamesCMS() {
    if (confirm(Assets.ExportAllGamesToCMS + '?')) {
        DbRoutes.exportAllGamesListCMS()
    }
}

function exportGameCMS(game: FileExportGames) {
    if (confirm(Assets.ExportGameToCMS + '?')) {
        DbRoutes.exportGameListCMS(game)
    }
}

function getBackground(Exists: boolean): string {
    return Exists ? 'background-light' : 'background-light-grey2'
}

function getTags(tags: Tag[]): JSX.Element[] {
    const Tags: JSX.Element[] = []
    if (tags) {
        for (let i: number = 0; i < tags.length; i++) {
            Tags.push (<button key = {i.toString()} style = {{cursor: 'default'}} className = "btn btn-default btn-dark btn-xs">{tags[i].name}</button>)
        }
    }
    if (Tags.length === 0) { Tags.push(<div key = "0">-</div>) }
    return Tags
}

function getCategoriesOptions(): JSX.Element[] {
    const co: JSX.Element[] = []
    co.push(<option key = "0" value = "-1">{Assets.SelectCategory}</option>)
    if (Main.Instance.DatabaseDataState.scategories && Main.Instance.DatabaseDataState.gamesList) {

        for (const item of Main.Instance.DatabaseDataState.scategories) {
            
            let len: number = 0
            for (const Item of Main.Instance.DatabaseDataState.gamesList) {
                const category = getStrapiCategoryId(Item.gameId)
                if (category && category === item.categoryId) {len++} 
            }
    
            co.push(<option key = {item.name} value = {item.categoryId}>{item.name + ' [' + len + ']'}</option>)
        }
    }
    return co
}

function toggleCMS(fileExportCMS: boolean) {
    if (fileExportCMS === undefined) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {fileExportCMS: true})
    } else if (fileExportCMS === true) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {fileExportCMS: false})
    } else {
        Logic.Type.New(Main.Instance.DatabaseDataState, {fileExportCMS: undefined})
    }
}

export function setGames(GamesListExport: FileExportGames[], casinoGames?: Casinogame[]) {
    const gamesListExport: FileExportGames[] = JSON.parse(JSON.stringify(Object.assign([], GamesListExport)))
    if (casinoGames ? casinoGames : Main.Instance.DatabaseDataState.casinoGames) {
        for (const item of gamesListExport) {
            item.Exists = false
            for (const Item of casinoGames ? casinoGames : Main.Instance.DatabaseDataState.casinoGames) {
                if (item.gameId === Item.gameId) {
                    item.Exists = true
                    item.Name = Item.name
                    item.sameName = item.Name === item.gameName
                    item.UpdatedAt = Item.updated_at
                    item.CreatedAt = Item.created_at
                    item.Tags = Item.tags
                    item.Category = Item.category !== undefined && Item.category !== null ? Item.category.categoryId : -1
                    break
                }
            }
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesListExport})
}
