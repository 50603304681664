import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {RadioButton3, FormInputNumber, FormSelect, FormInput} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet, utilsTimeValidEmptyNotValid, updateCategory} from '../../../Logic/Utils'
import * as Chart from '../../Reusables/Chart'
import {DateRangePicker, SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import ReactTable from 'react-table'

export function getRSTransactionsCount() {
    if (Main.Instance.FrontendStoreState.site_id && parseInt(Main.Instance.FrontendStoreState.site_id) !== 0 && Main.Instance.RSTState.countCalled === false) {
        Logic.Type.New(Main.Instance.RSTState, {countCalled:true})
        DbRoutes.GetAllLanguages()
        DbRoutes.getRSTransactions()
    }
}

function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
            operator_id: Main.Instance.LoginState.entityId,
            site_id: stream[0]['id'],
        })
        DbRoutes.loadData()
        FirstPage()
    }
}
/*
function handleExpand(rowData:string[]) {
    if (rowData) {
        let id:number = parseInt(rowData[0])
        let found:number = -1
        let Expand = Object.assign ([], Main.Instance.RSTState.Expand)
        for (let i:number = 0; i < Main.Instance.RSTState.Expand.length; i++) {
            if (id === Main.Instance.RSTState.Expand[i]) {
                found = i
                break
            }
        }
        if (found !== -1) {
            Expand.splice(found, 1)
        } else {
            Expand.push(id)
        }
        Logic.Type.New (Main.Instance.RSTState, {Expand: Expand})
    }
}
*/
function getRSTransactions() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
}

function FirstPage() {

    Logic.Type.New(Main.Instance.RSTState,{offset:(0).toString()})

    getRSTransactions()
    Logic.Type.New(Main.Instance.RSTState,{pNum: 0})

}

function PrevPage() {
    if (Main.Instance.RSTState.pNum > 0) {

        Logic.Type.New(Main.Instance.RSTState, {
            offset:((Main.Instance.RSTState.pNum - 1) * 
            Main.Instance.RSTState.pSize).toString()})
        getRSTransactions()

        Logic.Type.New(Main.Instance.RSTState,{pNum: Main.Instance.RSTState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.RSTState.pNum + 1) * Main.Instance.RSTState.pSize < 
        (Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count)) {

        Logic.Type.New(Main.Instance.RSTState, {
            offset: ((Main.Instance.RSTState.pNum + 1) * 
            Main.Instance.RSTState.pSize).toString()})
        getRSTransactions()
        
        Logic.Type.New(Main.Instance.RSTState,{pNum: Main.Instance.RSTState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if ((Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count) 
        <= Main.Instance.RSTState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if ((Main.Instance.RSTState.expand===true? Main.Instance.RSTState.Count : Main.Instance.RSTState.count) === 0) {

            Logic.Type.New(Main.Instance.RSTState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc(((Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : 
                    Main.Instance.RSTState.count) - 1) / Main.Instance.RSTState.pSize)
            Logic.Type.New(Main.Instance.RSTState, {
                offset:(npNum * Main.Instance.RSTState.pSize).toString()})
        }

        getRSTransactions()

        Logic.Type.New(Main.Instance.RSTState,{pNum: npNum})
    }
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RSTState,{pSize:parseInt(text)})
        FirstPage()
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getRSTransactionsCount()
    }
}
/*
function updateCategory() {
    let Category: any[] = []
    let Category2: any[] = []
    Category.push ({id: '0', name: Assets.SelectCategory})
    Category2.push ({id: '0', name: Assets.Any})
    if (Main.Instance.FrontendStoreState.category) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.category.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
                    Main.Instance.FrontendStoreState.category[i].length > 0) {
                    let id: string = (1+Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                    Category.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
                    Category2.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Category, Category2})
        DbRoutes.getRSTransactions()
    }
}
*/
function ApplyFilterCategory(text:string) {
    Logic.Type.New(Main.Instance.RSTState, {filter_category: text})
    FirstPage()
}

function Toggle() {
    if (Main.Instance.DatabaseDataState.RstAllStream) {
        const stats: any[] = Object.assign ([], Main.Instance.RSTState.stats)
        stats[0].enabled = !stats[0].enabled
        Logic.Type.New (Main.Instance.RSTState, {stats})
    }
}

function SelectItem(item: number) {

    const stats: any[] = Object.assign ([], Main.Instance.RSTState.stats)
    stats[0].item = item
    delete(stats[0].data)
    Logic.Type.New (Main.Instance.RSTState, {stats})
    DbRoutes.getRSTA()
}

function getTable(): JSX.Element {
    const colors: string[] = ['#e8c3b9', '#fddf8b', '#3cba9f', '#c45850', '#3e95cd', '#b1c28e', '#8e5ea2']
    const Colors: string[] = ['#333', '#333', '#fff', '#fff', '#fff', '#333', '#fff', '#333']
    const table: JSX.Element[] = []
    let transactions: number = 0
    for (const item of Main.Instance.RSTState.stats[0].items) {
    transactions += +Main.Instance.RSTState.stats[0].data[0][item]
    }
    if (transactions === 0) { return (<div/>) }
    table.push (<div style = {{background: '#000', color: '#fff', padding: '1px 5px 1.17px', fontWeight: 700, textAlign: 'left', position: 'relative', minWidth: '140px'}} key = "-1">
        <span style = {{width: '100%'}}>{Main.Instance.RSTState.stats[0].measure === 2 ? Assets.TotalValue + ' ' : Assets.Transactions + ' '}</span>
        <span style = {{position: 'absolute', top: '0px', right: '5px'}}>
            {transactions}
            <small>{Main.Instance.RSTState.stats[0].measure === 2 ? Assets_.XP : ''}</small>
        </span>
    </div>)
    for (const item of Main.Instance.RSTState.stats[0].items) {
    table.push (
        <div
            key = {item}
            style = {{paddingLeft: '5px', fontWeight: 600, width: '100%', lineHeight: '25px', position: 'relative', color: Colors[table.length - 1], background: colors[table.length - 1]}}
        >
            <span style = {{width: '100%'}}>{item}</span>
            <span style = {{position: 'absolute', top: '0px', right: '5px'}}>
                {Main.Instance.RSTState.stats[0].data[0][item]}
                <small>{Main.Instance.RSTState.stats[0].measure === 2 ? Assets_.XP : ''}</small>
            </span>
        </div>)
    }

    return (<div style = {{boxSizing: 'border-box', border: '1px solid #333', marginTop: '49px'}}>{table}</div>)
}

function getButtonsItem(): JSX.Element[] {

    if (Main.Instance.RSTState.stats[0].items.length === 0) {
    return [<span key = "0" style = {{marginTop: '50px', fontSize: '14px'}} className = "dbl label label-danger label-sm">{Assets.NoItemsPresent}</span>]
    }

    const buttons: JSX.Element[] = []

    const colors: string[] = ['#000','#e8c3b9', '#fddf8b', '#3cba9f', '#c45850', '#3e95cd', '#b1c28e', '#8e5ea2', '#fdab8b']
    const Colors: string[] = ['#fff','#333', '#333', '#fff', '#fff', '#fff', '#333', '#fff', '#333']
    for (let i: number = 0; i < colors.length; i++) {
        if (i === Main.Instance.RSTState.stats[0].item - 1) {
            colors[i] = '#000'
            Colors[i] = '#fff'
        } else if (i !== Main.Instance.RSTState.stats[0].item - 1 && Main.Instance.RSTState.stats[0].item > 1) {
            colors[i] = '#fff'
            Colors[i] = '#333'
        }
    }

    if (Main.Instance.RSTState.stats[0].item > 1) {
        colors[0] = '#fff'
        Colors[0] = '#333'
    }

    buttons.push 
    (<button key = "-1" onClick = {() => SelectItem(1)} style = {{ background: colors[0], color: Colors[0], marginTop: '50px'}} className = "dbl btn btn-default btn-xs mr0">Any item</button>)
    if (Main.Instance.RSTState.stats[0].category > 1) {
    for (let i: number = 0; i < Main.Instance.RSTState.stats[0].items.length; i++) {
        buttons.push (
            <button key = {i.toString()} onClick = {() => SelectItem(i + 2)} style = {{background: colors[i + 1], color: Colors[i + 1], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
                {Main.Instance.RSTState.stats[0].items[i]}
            </button>,
        )
    }
    }

    return buttons
}

function SelectCategory(category: number) {
    DbRoutes.getRSTA(category)
}

function getButtonsCategory(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    const colors: string[] = ['#000','#e8c3b9', '#fddf8b', '#3cba9f', '#c45850', '#3e95cd', '#b1c28e', '#8e5ea2']
    const Colors: string[] = ['#fff','#333', '#333', '#fff', '#fff', '#fff', '#333', '#fff']
    for (let i: number = 0; i < colors.length; i++) {
        if (i === Main.Instance.RSTState.stats[0].category - 1) {
            colors[i] = '#000'
            Colors[i] = '#fff'
        } else if (i !== Main.Instance.RSTState.stats[0].category - 1 && Main.Instance.RSTState.stats[0].category > 1) {
            colors[i] = '#fff'
            Colors[i] = '#333'
        }
    }

    if (Main.Instance.RSTState.stats[0].category > 1) {
        colors[0] = '#fff'
        Colors[0] = '#333'
    }

    buttons.push
    (<button key = "1" onClick = {() => SelectCategory(1)} style = {{ background: colors[0], color: Colors[0], marginTop: '50px'}} className = "dbl btn btn-default btn-xs mr0">Any category</button>)
    if (Main.Instance.FrontendStoreState.Category) {
    for (let i: number = 2; i < Main.Instance.FrontendStoreState.Category.length; i++) {
        buttons.push (
            <button key = {i.toString()} onClick = {() => SelectCategory(i)} style = {{background: colors[i - 1], color: Colors[i - 1], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
                {Main.Instance.FrontendStoreState.Category[i]['name']}
            </button>,
        )
    }
    }

    return buttons
}

function getButtonsMeasure(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    const colors: string[] = ['#b22222','#b22222']
    const Colors: string[] = ['#fff','#fff']
    for (let i: number = 0; i < colors.length; i++) {
        if (i !== Main.Instance.RSTState.stats[0].measure - 1) {
            colors[i] = ''
            Colors[i] = '#333'
        }
    }

    buttons.push (
        <button key = "1" onClick = {() => SelectMeasure(1)} style = {{ background: colors[0], color: Colors[0], marginTop: '50px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.MeasureByTransactions}
        </button>,
    )

    buttons.push (
        <button key = "2" onClick = {() => SelectMeasure(2)} style = {{ background: colors[1], color: Colors[1], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.MeasureByTransactionsValue}
        </button>)

    return buttons
}

function getButtonsDate(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    const colors: string[] = ['#000','#000','#000','#000','#000']
    const Colors: string[] = ['#fff','#fff','#fff','#fff','#fff']
    for (let i: number = 0; i < colors.length; i++) {
        if (i !== Main.Instance.RSTState.stats[0].date - 1) {
            colors[i] = ''
            Colors[i] = '#333'
        }
    }

    buttons.push (
        <button key = "1" onClick = {() => SelectDate(1)} style = {{ background: colors[0], color: Colors[0], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.ChooseDateRST[0]}
        </button>,
    )

    buttons.push (
        <button key = "2" onClick = {() => SelectDate(2)} style = {{ background: colors[1], color: Colors[1], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.ChooseDateRST[1]}
        </button>,
    )

    buttons.push (
        <button key = "3" onClick = {() => SelectDate(3)} style = {{ background: colors[2], color: Colors[2], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.ChooseDateRST[2]}
        </button>,
    )

    buttons.push (
        <button key = "4" onClick = {() => SelectDate(4)} style = {{ background: colors[3], color: Colors[3], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.ChooseDateRST[3]}
        </button>,
    )

    buttons.push (
        <button key = "5" onClick = {() => SelectDate(5)} style = {{ background: colors[4], color: Colors[4], marginTop: '3px'}} className = "dbl btn btn-default btn-xs mr0">
            {Assets.ChooseDateRST[4]}
        </button>,
    )

    if (Main.Instance.RSTState.stats[0].date === 5) {
        // let customArrowIcon: JSX.Element =  <i className = {Assets_.faNextButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
        // let customCloseIcon: JSX.Element =  <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>        
        buttons.push (
            <div className = "date-smaller" style = {{marginTop: '3px'}}>
                <DateRangePicker
                    startDateId = "sd3"
                    endDateId = "ed3"
                    startDate = {Main.Instance.RSTState.stats[0].from_date}
                    endDate = {Main.Instance.RSTState.stats[0].to_date}
                    onDatesChange = {({startDate, endDate}) => DatesChange(startDate, endDate)}
                    focusedInput = {Main.Instance.ReportPState.focusedInput}
                    onFocusChange = {
                        (focusedInput) => Logic.Type.New(Main.Instance.ReportPState, {focusedInput})}
                    showDefaultInputIcon = {true}
                    showClearDates = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    minimumNights = {0}
                />            
            </div>,
        )
    }

    return buttons
}

function SelectDate(date: number) {
    const stats: any[] = Object.assign ([], Main.Instance.RSTState.stats)
    stats[0].date = date
    Logic.Type.New (Main.Instance.RSTState, {stats})
    DbRoutes.getRSTA()
}

function SelectMeasure(measure: number) {
    const stats: any[] = Object.assign ([], Main.Instance.RSTState.stats)
    stats[0].measure = measure
    if (measure === 1) { stats[0].unit = ' ' + Assets.items } else if (measure === 2) { stats[0].unit = ' ' + Assets_.XP }
    Logic.Type.New (Main.Instance.RSTState, {stats})
    DbRoutes.getRSTA()
}

function DatesChange(startDate: moment.Moment, endDate: moment.Moment) {

    const f: string = startDate ? moment.default(startDate).format(Assets_.dateDisplayFormat).trim() : Assets.none
    const t: string = endDate ? moment.default(endDate).format(Assets_.dateDisplayFormat).trim() : Assets.none
    const stats: any[] = Object.assign([], Main.Instance.RSTState.stats)
    
    stats[0].from_date = startDate
    stats[0].to_date = endDate
    stats[0].fromDate = f
    stats[0].toDate = t
    // delete (stats[0].data)
    Logic.Type.New (Main.Instance.RSTState, {stats})
    // console.log (Main.Instance.RSTState.stats)
    DbRoutes.getRSTA()
}

function DateChange(which: number, date: moment.Moment) {
    const temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
/*
    if (which === 0) Logic.Type.New(Main.Instance.RSTState, {from_date: date})
    else if (which === 1) Logic.Type.New(Main.Instance.RSTState, {to_date: date})
*/ 
    if (which === 0) { Logic.Type.New(Main.Instance.RSTState, {from__date: temp, from_date: date}) } else if (which === 1) { Logic.Type.New(Main.Instance.RSTState, {to__date: temp, to_date: date}) }
}

function AllowTimeCharactersOnly(text: string, which: number) {
    let proceed: boolean = text.length <= 8 ? true : false
    let dot: number = 0
    let colon: number = 0
    let space: number = 0
    let first: number
    let second: number
    
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '.'
            && text.charAt(i) !== ':'
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
            && text.charAt(i) !== ' '
        ) { proceed = false }

        if (text.charAt(i) === '.') { dot++ } else if (text.charAt(i) === ':') { colon++ } else if (text.charAt(i) === ' ') { space++ }
        if (dot > 2 || colon > 2 || space > 2 || dot * colon > 0 || space * colon > 0 || space * dot > 0) {
            proceed = false
        }

        if (proceed && (text.charAt(i) === '.' || text.charAt(i) === ':' || text.charAt(i) === ' ')) {
            if (first) { second = i } else { first = i }
        }
    }

    if (proceed) {
        let t: string = ''

        if (text.length === 5 || text.length === 8) {
            if (!first) { proceed = false }
            if (proceed && first === 2) { t += text.charAt(0) + text.charAt(1) }
            if (proceed && first === 2 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && first === 2) { t += text.charAt(3) + text.charAt(4) }
            if (proceed && second && second === 5 && text.length === 8 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && second && second === 5 && text.length === 8) { t += text.charAt(6) + text.charAt(7) }
        } else { proceed = false }

        if (which === 0 && proceed) {
            Logic.Type.New(Main.Instance.RSTState, {from_time: text, fromTime: t})
        } else if (which === 0) {
            Logic.Type.New(Main.Instance.RSTState, {from_time: text, fromTime: ''})
        } else if (which === 1 && proceed) {
            Logic.Type.New(Main.Instance.RSTState, {to_time: text, toTime: t})
        } else if (which === 1) {
            Logic.Type.New(Main.Instance.RSTState, {to_time: text, toTime: ''})
        }
/*
        if (which === 0 && proceed) {
            Logic.Type.New(Main.Instance.RSTState, {from_time: text})
        } else if (which === 0) {
            Logic.Type.New(Main.Instance.RSTState, {from_time: text})
        } else if (which === 1 && proceed) {
            Logic.Type.New(Main.Instance.RSTState, {to_time: text})
        } else if (which === 1) {
            Logic.Type.New(Main.Instance.RSTState, {to_time: text})
        }
*/
    }
}

function RefineTransactions() {
/*
    let validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RSTState.from_time)
    alert (validationFromTime)
    let validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RSTState.to_time)
    alert (validationToTime)
    alert (moment.default(Main.Instance.RSTState.from_date).format(Assets_.dateDisplayFormat).trim())
    alert (Main.Instance.RSTState.from_time)
    alert (moment.default(Main.Instance.RSTState.to_date).format(Assets_.dateDisplayFormat).trim())
    alert (Main.Instance.RSTState.to_time)
    alert (Main.Instance.RSTState.filter_date)
    alert (Main.Instance.SearchState.search_string)
*/
    DbRoutes.getRSTransactions()
}

export function RewardShopTransactions() {

    if (!Main.Instance.RSTState.stats) {
        const stats = [{
            enabled: false,
            data: undefined,
            category: 1,
            measure: 1,
            unit: Assets.items,
            item: 1,
            items: [],
            date: 1,
            from_date: null,
            to_date: null,
            fromDate: Assets.none,
            toDate: Assets.none,
        }]
        Logic.Type.New (Main.Instance.RSTState, {stats})
    }

    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {first_time: false})
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)            
        } /*else if (isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId))            
        }*/
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    if (Main.Instance.DatabaseDataState.languages !== undefined &&
        Main.Instance.FrontendStoreState.category !== undefined && 
        Main.Instance.FrontendStoreState.Category === undefined) {
        if (!Main.Instance.FrontendStoreState.category || Main.Instance.FrontendStoreState.category.length === 0) {
                const category: string[] = []
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++) {
                category.push(Assets_.Uncategorized)
                }
                Logic.Type.New (Main.Instance.FrontendStoreState, {category})
        }
        updateCategory()
        if (Main.Instance.FrontendStoreState.category) {
            DbRoutes.getRSTransactions()
        }
    }
    // console.log (Main.Instance.DatabaseDataState.RstAllStream)
    // console.log (Main.Instance.FrontendStoreState.Category)
    // console.log (Main.Instance.RSTState.stats[0].data)

    if (Main.Instance.RSTState.stats[0].enabled && Main.Instance.DatabaseDataState.RstAllStream && !Main.Instance.RSTState.stats[0].data) {

        const stats: any[] = Object.assign ([], Main.Instance.RSTState.stats)
        stats[0].data = [
            {
                name:  stats[0].category === 1 ?

                    stats[0].measure === 1 ? Assets.DistributionRewardShopTransactionsCategory :
                    stats[0].measure === 2 ? Assets.DistributionRewardShopTransactionsValueCategory : '' :

                    stats[0].measure === 1 ? Assets.DistributionRewardShopTransactionsIn + Main.Instance.FrontendStoreState.Category[stats[0].category]['name'] :
                    stats[0].measure === 2 ? Assets.DistributionRewardShopTransactionsValueIn + Main.Instance.FrontendStoreState.Category[stats[0].category]['name']: '',
            },
        ]
        if (stats[0].category === 1) {
            // console.log (Main.Instance.FrontendStoreState.Category)
            stats[0].items = []
            for (let i: number = 2; i < Main.Instance.FrontendStoreState.Category.length; i++) {
                stats[0].items.push(Main.Instance.FrontendStoreState.Category[i]['name'])
            }
            // stats[0].items = ['Free spins', 'Bonus', 'Merchandise', 'Product', 'Holidays', 'Free money', 'Free bets']

            for (const Item of Main.Instance.RSTState.stats[0].items) {
                stats[0].data[0][Item] = 0
            }
    
        } else {
            for (const Item of Main.Instance.RSTState.stats[0].items) {
                stats[0].data[0][Item] = 0
            }
        }

        for (const item of Main.Instance.DatabaseDataState.RstAllStream) {
            if (stats[0].category === 1) {
                if (stats[0].measure === 1) {
                    for (let i: number = 0; i < stats[0].items.length; i++) {
                        if (item['category_id'] === i + 1) { stats[0].data[0][stats[0].items[i]]++ }
                    }

                } else if (stats[0].measure === 2) {

                    for (let i: number = 0; i < stats[0].items.length; i++) {
                        if (item['category_id'] === i + 1) { stats[0].data[0][stats[0].items[i]] += item['valuexp'] }
                    }
                }
            } else {
                if (stats[0].measure === 1) {
                    for (let i: number = 0; i < stats[0].items.length; i++) {
                        if (item['item_name'] === stats[0].items[i]) { stats[0].data[0][stats[0].items[i]]++ }
                    }
                } else if (stats[0].measure === 2) {
                    for (let i: number = 0; i < stats[0].items.length; i++) {
                        if (item['item_name'] === stats[0].items[i]) { stats[0].data[0][stats[0].items[i]] += item['valuexp'] }
                    }                
                }
            }
        }
        Logic.Type.New (Main.Instance.RSTState, {stats})
    }
    
    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RSTState.from_time)
    const validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RSTState.to_time)
    
    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
    if (IsBuildScandibet() && Main.Instance.RSTState.filter_date === '0') { Logic.Type.New(Main.Instance.RSTState, {filter_date: ''}) }
    if (IsBuildScandibet() && Main.Instance.RSTState.filter_player === '0') { Logic.Type.New(Main.Instance.RSTState, {filter_player: ''}) }
    return (<div>

    <div className = "clearfix" style = {{position: 'relative'}}>

    <h3 className = "level fl" style = {{width: '40%'}}>
        {Assets.ViewRewardShopTransactions}
    </h3>
    {Main.Instance.RSTState.stats ?
    <button
        onClick = {() => Toggle()}
        className = {Main.Instance.RSTState.stats[0].enabled ? 'btn btn-success' : 'btn btn-danger'}
        style = {{position: 'absolute', right: '0px', top: '20px', marginRight: '0px'}}
    >
        {Assets.ViewReport}
    </button> : void 0}

    {isUserSystem() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                Main.Instance.FrontendStoreState.holding_id}
        data = {Main.Instance.DatabaseDataState.masterAllStream}
        ClassName = "form-control"

        o1 = {Assets.SelectMaster}
        o2 = {Assets.MasterQ}
        onChange = {(text) => ApplyMasterSelect(text)}
        icon = {Assets_.faMasterClass}
    /> : null}

    {isUserSystem() || isUserMaster() || isUserGameProvider() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                Main.Instance.FrontendStoreState.operator_id}
        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
        data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
        label1 = {Assets.OP}
        label2 = {Assets.BO}
        
        ClassName = "form-control"

        o1 = {selectOperator}
        o2 = {operatorQ}
        onChange = {(text) => ApplyOperatorSelect(text)}
        icon = {Assets_.faCompanyClass}
    />
    : null
    }

    {!isUserSubEntity() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                Main.Instance.FrontendStoreState.site_id}
        data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
        ClassName = "form-control"

        o1 = {selectSite}
        o2 = {siteQ}
        onChange = {(text) => ApplySiteSelect(text)}
        icon = {Assets_.faSubEntityClass}
    />
    : null
    }

    </div>
    <div className = "clearfix"/>
    {Main.Instance.RSTState.stats[0].enabled ?
    <div>
        <div className = "clearfix">
            <div className = "fl">
                <div style = {{lineHeight: '50px', fontSize: '16px', fontWeight: 900, width: '500px', textAlign: 'center'}}>
                    {
                    Main.Instance.RSTState.stats[0].category === 1 ? 
                    Main.Instance.RSTState.stats[0].measure === 1 ?
                    Assets.DistributionRewardShopTransactionsCategory
                    : Main.Instance.RSTState.stats[0].measure === 2 ?
                    Assets.DistributionRewardShopTransactionsValueCategory
                    : '' :
                    Main.Instance.RSTState.stats[0].measure === 1 ? 
                    Assets.DistributionRewardShopTransactionsIn + Main.Instance.FrontendStoreState.Category[Main.Instance.RSTState.stats[0].category]['name']
                    : Main.Instance.RSTState.stats[0].measure === 2 ? 
                    Assets.DistributionRewardShopTransactionsValueIn + Main.Instance.FrontendStoreState.Category[Main.Instance.RSTState.stats[0].category]['name']
                    : ''}
                </div>
                {Main.Instance.RSTState.stats[0].items.length === 0 ? void 0 :
                <Chart.SimpleBarChartRSTSmall data = {Main.Instance.RSTState.stats[0].data} unit = {Main.Instance.RSTState.stats[0].unit} items = {Main.Instance.RSTState.stats[0].items}/>}
            </div>
            <div className = "fl clearfix" style = {{marginLeft: '10px'}}>{getButtonsMeasure()}{getButtonsDate()}</div>
            <div className = "fl clearfix" style = {{marginLeft: '10px'}}>{getButtonsCategory()}</div>
            <div className = "fl clearfix" style = {{marginLeft: '10px'}}>{getButtonsItem()}</div>
            <div className = "fl clearfix" style = {{marginLeft: '10px'}}>{getTable()}</div>
        </div>

        {Main.Instance.DatabaseDataState.RstAllStream ?
            <>
            <h3>{Assets.tRSTransactions}</h3>
            <ReactTable 
                data = {Main.Instance.DatabaseDataState.RstAllStream}
                columns = {[
                    {minWidth: 70, Header: Assets.Purch_Date, accessor: 'id', headerClassName: 'th-left'},
                    {Header: Assets.Item, accessor: 'item_name', headerClassName: 'th-left'},
                    {Header: Assets.Category, accessor: 'category', headerClassName: 'th-left'},
                    {minWidth: 70, Header: Assets.PlayerId, accessor: 'player_id', headerClassName: 'th-left'},
                    {minWidth: 120, Header: Assets.PurchasedDate, accessor: 'purchased_date', headerClassName: 'th-left', Cell: (row) => row.value.substring(0,10)},
                    {Header: Assets.Time + ' ' + Assets_.UTC, accessor: 'purchased_time', headerClassName: 'th-left'},
                    {Header: Assets.Quantity, accessor: 'quantity', headerClassName: 'th-left'},
                    {Header: Assets.Price, accessor: 'price', headerClassName: 'th-left'},
                    {Header: Assets.Value, accessor: 'valuexp', headerClassName: 'th-left'},
                    {minWidth: 120, Header: Assets.BalanceBefore, accessor: 'balbefxp', headerClassName: 'th-left'},
                    {Header: Assets.BalanceAfter, accessor: 'balaftxp', headerClassName: 'th-left'},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={100}
            />
            </>
        : <div>{Assets.NoData}!</div>}
    </div>
    :
           
    <div className = "row admin-users-container clearfix">
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.RSTState.pSize ? '' : Main.Instance.RSTState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            {IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RSTState.filter_category ? '0' : 
                        Main.Instance.RSTState.filter_category}
                data = {Main.Instance.FrontendStoreState.Category2}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                onChange = {(text) => ApplyFilterCategory(text)} 
            />
            :
            <RadioButton3
                value = {Main.Instance.RSTState.expand === undefined ? false : Main.Instance.RSTState.expand}
                ClassName = "input-group-radiobutton3c margin-bottom-sm"
                text = {Assets.ExpandTransactions}
                title = {Assets.ExpandTransactionsDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => {Logic.Type.New (Main.Instance.RSTState, {expand: !Main.Instance.RSTState.expand}); FirstPage()}}
            />}

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {(Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count)?
                (Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count:Main.Instance.RSTState.count) :0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {(Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count)?
                Main.Instance.RSTState.pNum * Main.Instance.RSTState.pSize + 1 : 0}{' - '}
                {(Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count)?
                ((Main.Instance.RSTState.pNum + 1) * 
                Main.Instance.RSTState.pSize > (Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : 
                Main.Instance.RSTState.count) ? 
                (Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count) :
                (Main.Instance.RSTState.pNum + 1) * Main.Instance.RSTState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {(Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count) ? 
                Main.Instance.RSTState.pNum + 1 :0}{' / '}
                {(Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : Main.Instance.RSTState.count) ? 
                Math.trunc(((Main.Instance.RSTState.expand === true ? Main.Instance.RSTState.Count : 
                Main.Instance.RSTState.count) - 1) / Main.Instance.RSTState.pSize + 1) : 0}
            </span>
        </div>
        <div className = "clearfix" style = {{marginTop: '10px'}}>
{/*
            <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                <span
                    title = {Assets.Start}
                    style = {{fontSize: '14px', paddingTop: '7px', paddingBottom: '7px', color: '#333', background: '#fff'}}
                    className = "label label-default label-sm"
                >
                    Filters:
                </span>
            </div>        
*/}
            <FormInput
                float = {true}
                date = {true}
                value = {!Main.Instance.RSTState.filter_player ? '' : Main.Instance.RSTState.filter_player} 
                ClassName = "form-control register-input" 
                type = "text" 
                onChange = {(text) => Logic.Type.New (Main.Instance.RSTState, {filter_player: text})}
                placeholder = {Assets.Player}
                icon = {Assets_.faUserDark}
            />

            <FormInput
                float = {true}
                date = {true}
                value = {!Main.Instance.RSTState.filter_date ? '' : Main.Instance.RSTState.filter_date} 
                ClassName = "form-control register-input" 
                type = "text" 
                onChange = {(text) => Logic.Type.New (Main.Instance.RSTState, {filter_date: text})}
                placeholder = {Assets.Item}
                icon = {Assets_.faSearchButtonClass}
            />

            <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                <span
                    title = {Assets.Start}
                    style = {{fontSize: '14px', paddingTop: '7px', paddingBottom: '7px', color: '#333', background: '#fff'}}
                    className = "label label-default label-sm"
                >
                    <i className = {Assets_.faHourglassStartClass}/>
                </span>
            </div>

            <div className = "fl mr singledatepicker">
                <SingleDatePicker
                    id = "date_3"
                    placeholder = {Assets.Date}
                    date = {Main.Instance.RSTState.from_date}
                    onDateChange = {(date) => DateChange(0, date)}
                    focused = {Main.Instance.RSTState.focusedInput}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.RSTState, {focusedInput: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    // customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
            </div>

            <FormInput
                date = {true}
                float = {true}
                validation = {validationFromTime}
                value = {!Main.Instance.RSTState.from_time ? '' : Main.Instance.RSTState.from_time} 
                ClassName = "form-control register-input" 
                type = "text" 
                onChange = {(text) => AllowTimeCharactersOnly(text, 0)} 
                placeholder = {Assets.Time}
                icon = {Assets_.faTimeNarrowClass}
            />

            <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                <span
                    title = {Assets.End}
                    style = {{fontSize: '14px', paddingTop: '7px', paddingBottom: '7px', color: '#333', background: '#fff', marginLeft: '0px'}}
                    className = "label label-default label-sm"
                >
                    <i className = {Assets_.faHourglassEndClass}/>
                </span>
            </div>
            <div className = "fl mr singledatepicker">
                <SingleDatePicker
                    id = "date_4"
                    placeholder = {Assets.Date}
                    date = {Main.Instance.RSTState.to_date}
                    onDateChange = {(date) => DateChange(1, date)}
                    focused = {Main.Instance.RSTState.focusedInput2}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.RSTState, {focusedInput2: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    // customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
            </div>

            <FormInput
                date = {true}
                float = {true}
                validation = {validationToTime}
                value = {!Main.Instance.RSTState.to_time ? '' : Main.Instance.RSTState.to_time} 
                ClassName = "form-control register-input" 
                type = "text" 
                onChange = {(text) => AllowTimeCharactersOnly(text, 1)}
                placeholder = {Assets.Time}
                icon = {Assets_.faTimeNarrowClass}
            />
            <button style = {{fontSize: '14px', fontWeight: 700, padding: '3px 6px'}} onClick = {() => RefineTransactions()} className = "btn btn-primary btn-xs fr mr0">
                <i className = {Assets_.faSearchButtonClass}/>&nbsp;{Assets.FilterSearch}
            </button>
        </div>
        {Main.Instance.DatabaseDataState.RstAllStream && Main.Instance.DatabaseDataState.rstStream ?
        Main.Instance.RSTState.expand === false && Main.Instance.DatabaseDataState.rstStream ?
        Main.Instance.DatabaseDataState.rstStream.length > 0 ?
        <>
        <h3>{Assets.tRSTransactions}</h3>
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.rstStream}
            columns = {[
                {minWidth: 70, Header: Assets.Purch_Date, accessor: 'id', headerClassName: 'th-left'},
                {Header: Assets.Item, accessor: 'item_name', headerClassName: 'th-left'},
                {Header: Assets.Category, accessor: 'category', headerClassName: 'th-left'},
                {minWidth: 70, Header: Assets.PlayerId, accessor: 'player_id', headerClassName: 'th-left'},
                {minWidth: 120, Header: Assets.PurchasedDate, accessor: 'purchased_date', headerClassName: 'th-left', Cell: (row) => row.value.substring(0,10)},
                {Header: Assets.Time + ' ' + Assets_.UTC, accessor: 'purchased_time', headerClassName: 'th-left'},
                {Header: Assets.Quantity, accessor: 'quantity', headerClassName: 'th-left'},
                {Header: Assets.Price, accessor: 'price', headerClassName: 'th-left'},
                {Header: Assets.Value, accessor: 'valuexp', headerClassName: 'th-left'},
                {minWidth: 120, Header: Assets.BalanceBefore, accessor: 'balbefxp', headerClassName: 'th-left'},
                {Header: Assets.BalanceAfter, accessor: 'balaftxp', headerClassName: 'th-left'},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />
        </>
    : void 0
    :
    Main.Instance.DatabaseDataState.RstStream && Main.Instance.DatabaseDataState.RstStream.length > 0 ?
    <>
    <h3>{Assets.tRSTransactions}</h3>
    <ReactTable 
        data = {Main.Instance.DatabaseDataState.RstStream}
        columns = {[
            {minWidth: 70, Header: Assets.Purch_Date, accessor: 'id', headerClassName: 'th-left'},
            {Header: Assets.Item, accessor: 'item_name', headerClassName: 'th-left'},
            {Header: Assets.Category, accessor: 'category', headerClassName: 'th-left'},
            {minWidth: 70, Header: Assets.PlayerId, accessor: 'player_id', headerClassName: 'th-left'},
            {minWidth: 120, Header: Assets.PurchasedDate, accessor: 'purchased_date', headerClassName: 'th-left', Cell: (row) => row.value.substring(0,10)},
            {Header: Assets.Time + ' ' + Assets_.UTC, accessor: 'purchased_time', headerClassName: 'th-left'},
            {Header: Assets.Quantity, accessor: 'quantity', headerClassName: 'th-left'},
            {Header: Assets.Price, accessor: 'price', headerClassName: 'th-left'},
            {Header: Assets.Value, accessor: 'valuexp', headerClassName: 'th-left'},
            {minWidth: 120, Header: Assets.BalanceBefore, accessor: 'balbefxp', headerClassName: 'th-left'},
            {Header: Assets.BalanceAfter, accessor: 'balaftxp', headerClassName: 'th-left'},
        ]}
        showPaginationBottom = {false}
        defaultPageSize={100}
    />
    </>

    : void 0
    : getRSTransactionsCount()}
    </div>}
</div>)
}