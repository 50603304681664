import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
// import {FormSelect} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {/*isUserSystem, isUserMaster, isUserSubEntity, */isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet, IsBuildOddyzzey, IsBuildMyChance} from '../../../Logic/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExchangeAlt, faPlus, faMinus/*, faPaintRoller, faSitemap*/} from '@fortawesome/free-solid-svg-icons'

export function getGamesSelectionCount() {
    if (Main.Instance.GamesState.countCalled === false) {
        Logic.Type.New(Main.Instance.GamesState, {countCalled: true})
        DbRoutes.loadData()
        DbRoutes.GetBanner()
    }
}

function SelectSite(stream: any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetGames()
        FirstPage()
    }
}

function IncludeProvider(text: string) {
    let providers: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i: number = 1; i <= Main.Instance.DatabaseDataState.gamesStreamProvider.length; i++) {
            providers.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        providers = Object.assign([], Main.Instance.GamesState.providers)
        let alreadyIn: number = -1

        for (let i: number = 0; i < Main.Instance.GamesState.providers.length; i++) {
            if (Main.Instance.GamesState.providers[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            providers.push(parseInt(text))
        } else {
            providers.splice(alreadyIn, 1)
        }
    }

    Logic.Type.New (Main.Instance.GamesState, {providers})
    // SelectGames()
}

function IncludeCategory(text: string) {
    let gameCategories: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i: number = 1; i <= Main.Instance.DatabaseDataState.Categories.length; i++) {
            gameCategories.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        gameCategories = Object.assign([], Main.Instance.GamesState.gameCategories)
        let alreadyIn: number = -1

        for (let i: number = 0; i < Main.Instance.GamesState.gameCategories.length; i++) {
            if (Main.Instance.GamesState.gameCategories[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            gameCategories.push(parseInt(text))
        } else {
            gameCategories.splice(alreadyIn, 1)
        }
    }

    Logic.Type.New (Main.Instance.GamesState, {gameCategories})
    // SelectGames()
}

export function ApplyFilterFreespins(text:string) {
    Logic.Type.New(Main.Instance.GamesState, {filter_freespins: text})
    SelectGames()
}

export function ApplyFilterFlexibleFreespins(text:string) {
    Logic.Type.New(Main.Instance.GamesState, {filter_flexible: text})
    SelectGames()
}

export function ApplyFilterCoinValues(text:string) {
    Logic.Type.New(Main.Instance.GamesState, {filter_active: text})
    SelectGames()
}

function getGamesSelection() {
    if (Main.Instance.DatabaseDataState.GamesStreamAll) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {
        GamesStream:Main.Instance.DatabaseDataState.GamesStreamAll.slice(
                    parseInt(Main.Instance.GamesState.offset),
                    parseInt(Main.Instance.GamesState.offset) + Main.Instance.GamesState.pSize,
        ),
    })
    }
}

function FirstPage() {

    Logic.Type.New(Main.Instance.GamesState,{offset:(0).toString()})

    getGamesSelection()
    Logic.Type.New(Main.Instance.GamesState,{pNum: 0})
}
/*
function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.GamesState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState, {levelsAllStream: undefined, sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,      
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getGamesSelectionCount()
    }
}
*/
/*
function getLetters(): JSX.Element[] {
    if (!Main.Instance.DatabaseDataState.GamesStreamAll) return [<div key = "0"/>]
    let letters: string[] = []
    for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
        if (letters.length === 0 || letters[letters.length - 1].toLowerCase() !== item[Assets.Name].charAt(0).toLowerCase())
            letters.push (item[Assets.Name].charAt(0).toUpperCase())
    }
    let proceed: boolean = false
    if (!Main.Instance.GamesState.filter_letter) {
        let filter_letter: boolean[] = []
        let filterLetter: string[] = []
        filter_letter.push(true)
        filterLetter.push('')
        for (let i: number = 1; i <= letters.length; i++) {filter_letter.push(false); filterLetter.push(letters[i - 1])}
        Logic.Type.New (Main.Instance.GamesState, {filter_letter, filterLetter})
        proceed = true
    }
    let l: JSX.Element[] = []
    l.push (
        <button
            onClick = {() => ToggleLetter(0)}
            className = {Main.Instance.GamesState.filter_letter[0] === true ? 'btn btn-success' : 'btn btn-default'}
            key = "0"
            style = {{padding: '3px 3px 2px', marginLeft: '0px', marginRight: '0px', borderRadius: '0px'}}
        >{Assets.All}
        </button>)

    for (let i: number = 1; i <= letters.length; i++) {
        l.push (
            <button
                onClick = {() => ToggleLetter(i)}
                className = {Main.Instance.GamesState.filter_letter[i] === true ? 'btn btn-success' : 'btn btn-default'}
                key = {i.toString()}
                style = {{padding: '3px 3px 2px', marginLeft: '4px', marginRight: '0px', borderRadius:'0px'}}
            >
                {letters[i - 1]}
            </button>)
    }
    if (proceed) SelectGames()
    return l
}
*/
function ToggleGame(which: number) {
    if (Main.Instance.GamesState.categorize) {
        if (Main.Instance.GamesState.gameCategories[which] === -1 || Main.Instance.GamesState.gameCategories[which] === Main.Instance.GamesState.category) {
            const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
            GamesStreamAllSelected[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected[which]
            Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})

            const gameCategories: number[] = Object.assign ([], Main.Instance.GamesState.gameCategories)
            if (Main.Instance.GamesState.gameCategories[which] === -1) { gameCategories[which] = Main.Instance.GamesState.category } else { gameCategories[which] = -1 }
            Logic.Type.New (Main.Instance.GamesState, {gameCategories})
        } else { alert (Assets.notPossible) }
    } else {
        const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        GamesStreamAllSelected[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected[which]
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    }
}
/*
function ToggleLetter (which: number) {
    let filter_letter: boolean[] = Object.assign ([], Main.Instance.GamesState.filter_letter)
    filter_letter[which] = !Main.Instance.GamesState.filter_letter[which]
    if (which === 0 && !Main.Instance.GamesState.filter_letter[0]) {
        for (let i: number = 1; i < Main.Instance.GamesState.filter_letter.length; i++) filter_letter[i] = false
    } else if (which !== 0 && !Main.Instance.GamesState.filter_letter[which]) filter_letter[0] = false

    Logic.Type.New (Main.Instance.GamesState, {filter_letter})
    SelectGames()
}
*/

// 

function SelectAllGames_(value: boolean) {
    // console.log (Main.Instance.GamesState.category)
    // console.log (Main.Instance.GamesState.gameCategories)
    const gameCategories: number[] = Object.assign ([], Main.Instance.GamesState.gameCategories)
    const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
    if (value && gameCategories[i] === -1 || !value && gameCategories[i] === Main.Instance.GamesState.category) {
        GamesStreamAllSelected[i] = value
        gameCategories[i] = value ? Main.Instance.GamesState.category : -1
    }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    Logic.Type.New (Main.Instance.GamesState, {gameCategories})
}

function SelectAllGamesNotDelete_() {
    const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
/*
        if (flexible === true && Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false) continue
        else if (flexible === false && Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false) continue
*/
        if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
        if (Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) {
            GamesStreamAllSelected[i] = true
        }
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
}

function SelectAllGames(value: boolean) {
    const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
        GamesStreamAllSelected[i] = value
    }
    
    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
}

function selectAllGames(which: number) {
    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) {
        const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        if (IsBuildMyChance()) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                if (
                    notCategory(Main.Instance.DatabaseDataState.GamesStreamAll[i].Category) ||
                    notProvider(Main.Instance.DatabaseDataState.GamesStreamAll[i].Provider)
                ) {
                    GamesStreamAllSelected[i] = false
                } else {
                    GamesStreamAllSelected[i] = true
                }
            }
        } else {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                GamesStreamAllSelected[i] = true
            }
        }

        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
        CSV.category1.gameIds = []
        CSV.category1.gameNames = []
        CSV.category1.gameProviders = []
        CSV.category1.spinsCoinValues = []
    } else if (which === 1) {
        const GamesStreamAllSelected2: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected2[i] = true
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2})
        CSV.category2.gameIds = []
        CSV.category2.gameNames = []
        CSV.category2.gameProviders = []
        CSV.category2.spinsCoinValues = []
    } else if (which === 2) {
        const GamesStreamAllSelected3: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected3[i] = true
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3})
        CSV.category3.gameIds = []
        CSV.category3.gameNames = []
        CSV.category3.gameProviders = []
        CSV.category3.spinsCoinValues = []
    }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
}

export function DeselectAllGames(which: number) {
    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) {
        const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected[i] = false
        }


        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
        CSV.category1.gameIds = []
        CSV.category1.gameNames = []
        CSV.category1.gameProviders = []
        CSV.category1.spinsCoinValues = []
    } else if (which === 1) {
        const GamesStreamAllSelected2: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected2[i] = false
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2})
        CSV.category2.gameIds = []
        CSV.category2.gameNames = []
        CSV.category2.gameProviders = []
        CSV.category2.spinsCoinValues = []
    } else if (which === 2) {
        const GamesStreamAllSelected3: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected3[i] = false
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3})
        CSV.category3.gameIds = []
        CSV.category3.gameNames = []
        CSV.category3.gameProviders = []
        CSV.category3.spinsCoinValues = []
    }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
}

export function SelectGamesNotDelete(which: number) {
    // console.log ('clicked apply search ', which)
    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (which === 0) {
        // console.log ('here')
        // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
        // if (!CSV.category1.gameIds)
        CSV.category1.gameIds = []
        // if (!CSV.category1.gameNames)
        CSV.category1.gameNames = []
        // if (!CSV.category1.gameProviders) 
        CSV.category1.gameProviders = []
        const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
                    if (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) {
                        GamesStreamAllSelected[i] = true
                    } /*else {
                        GamesStreamAllSelected[i] = false
                    }*/
                }

                if (GamesStreamAllSelected[i]) {
                    CSV.category1.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
                    CSV.category1.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'])
                    CSV.category1.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Provider'])
                }
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})

        CSV.category1.spinsCoinValues = getSpinsCoinValues(CSV.category1.gameIds)
    } else if (which === 1) {
        // if (!CSV.category2.gameIds)
        CSV.category2.gameIds = []
        // if (!CSV.category2.gameNames)
        CSV.category2.gameNames = []
        // if (!CSV.category2.gameProviders) 
        CSV.category2.gameProviders = []

        const GamesStreamAllSelected2: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
                    if (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) { 
                        GamesStreamAllSelected2[i] = true
                    }
                }

                if (GamesStreamAllSelected2[i]) {
                    CSV.category2.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
                    CSV.category2.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'])
                    CSV.category2.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Provider'])
                }
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2})

        CSV.category2.spinsCoinValues = getSpinsCoinValues(CSV.category2.gameIds)
    } else if (which === 2) {
        // if (!CSV.category3.gameIds)
        CSV.category3.gameIds = []
        // if (!CSV.category3.gameNames)
        CSV.category3.gameNames = []
        // if (!CSV.category3.gameProviders) 
        CSV.category3.gameProviders = []

        const GamesStreamAllSelected3: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
                    if (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) { 
                        GamesStreamAllSelected3[i] = true
                    }
                }

                if (GamesStreamAllSelected3[i]) {
                    CSV.category3.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
                    CSV.category3.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'])
                    CSV.category3.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Provider'])
                }
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3})

        CSV.category3.spinsCoinValues = getSpinsCoinValues(CSV.category3.gameIds)
    }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
} 

function SelectGames(/*first?: boolean*/) {
    const GamesStreamAllSelected: boolean[] = []
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
        const item = Main.Instance.DatabaseDataState.GamesStreamAll[i]
        let selected: boolean = false // Main.Instance.DatabaseDataState.GamesStreamAllSelected[i]
        // false
        // letter - Name
/*
        if (Main.Instance.GamesState.filter_letter[0]) selected = true
        else for (let j: number = 1; j < Main.Instance.GamesState.filter_letter.length; j++)
            if (Main.Instance.GamesState.filter_letter[j]) {
                if (item[Assets.Name].charAt(0).toLowerCase() === Main.Instance.GamesState.filterLetter[j].toLowerCase()) {
                    selected = true
                    break
                }
        }
*/
        // filter
        if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
            if (item[Assets.Name].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) { selected = true }
        }
/*
        // Provider
        if (selected && first === undefined) {
            for (const Item of Main.Instance.DatabaseDataState.gamesStreamProvider) {
                let provider = false
                for (let j: number = 0; j < Main.Instance.GamesState.providers.length; j++) {
                    if (parseInt(Item['id']) === Main.Instance.GamesState.providers[j]) {
                        provider = true
                        break
                    }
                }

                if (provider && Item['name'] === item['Provider']) {
                    // console.log (Item['name'])
                    // console.log (item['Provider'])
                    selected = true
                    break
                } else selected = false
            }
        }
*/
/*
        // free spins
        if (selected && first === undefined) {
            if (parseInt(Main.Instance.GamesState.filter_freespins) === 2 && item['FreespinsEnabled'] === true)
                selected = false
            else if (parseInt(Main.Instance.GamesState.filter_freespins) === 1 && item['FreespinsEnabled'] === false)
                selected = false
        }

        // flexible free spins
        if (selected && first === undefined) {
            if (parseInt(Main.Instance.GamesState.filter_flexible) === 2 && item['FlexibleFreespinsEnabled'] === true)
                selected = false
            else if (parseInt(Main.Instance.GamesState.filter_flexible) === 1 && item['FlexibleFreespinsEnabled'] === false)
                selected = false
        }

        // coinvalues
        if (selected) {
            if (parseInt(Main.Instance.GamesState.filter_active) === 2 && item['CoinValues'] !== undefined && 
                Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length > 0)
                selected = false
            else if (parseInt(Main.Instance.GamesState.filter_active) === 1 && item['CoinValues'] === undefined ||
                Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0)
                selected = false
        }
*/

        GamesStreamAllSelected.push (selected)
    }
    // console.log (Main.Instance.GamesState.providers)
    // console.log (Main.Instance.DatabaseDataState.gamesStreamProvider)

    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
}

export function getSelected(array: boolean[]): number {
    let s: number = 0
    if (array) { for (const item of array) { if (item) { s++ } } }
    return s
}

function DisableSelectedGame2(i: number) {
    const GamesStreamAllSelected: any[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    GamesStreamAllSelected[i] = false
    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
}

function DisableSelectedGame(i: number) {
    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (Main.Instance.RewardShopState.category_idx === 0) {
        const GamesStreamAllSelected: any[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        GamesStreamAllSelected[i] = false

        let found: number = -1
        for (let j: number = 0; j < CSV.category1.gameIds.length; j++) {
            if (CSV.category1.gameIds[j] === +Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']) {
                found = j
                break
            }
        }
        CSV.category1.gameIds.splice(found, 1)
        CSV.category1.gameNames.splice(found, 1)
        CSV.category1.gameProviders.splice(found, 1)

        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    } else if (Main.Instance.RewardShopState.category_idx === 1) {
        const GamesStreamAllSelected2: any[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
        GamesStreamAllSelected2[i] = false

        let found: number = -1
        for (let j: number = 0; j < CSV.category2.gameIds.length; j++) {
            if (CSV.category2.gameIds[j] === +Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']) {
                found = j
                break
            }
        }
        CSV.category2.gameIds.splice(found, 1)
        CSV.category2.gameNames.splice(found, 1)
        CSV.category2.gameProviders.splice(found, 1)

        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2})
    } else if (Main.Instance.RewardShopState.category_idx === 2) {
        const GamesStreamAllSelected3: any[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
        GamesStreamAllSelected3[i] = false

        let found: number = -1
        for (let j: number = 0; j < CSV.category3.gameIds.length; j++) {
            if (CSV.category3.gameIds[j] === +Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']) {
                found = j
                break
            }
        }
        CSV.category3.gameIds.splice(found, 1)
        CSV.category3.gameNames.splice(found, 1)
        CSV.category3.gameProviders.splice(found, 1)

        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3})
    }
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
}

function getSelectedGames(array: boolean[], datarray: any[]): JSX.Element[] {
    const games: JSX.Element[] = []
    if (array && datarray) { for (let i: number = 0; i < datarray.length; i++) { if (array[i] === true) { games.push (
        <button
            style = {{marginBottom: '3px'}}
            className = "btn btn-success btn-sm"
            key = {i.toString()}
            onClick = {() => DisableSelectedGame(i)}
        >
            {datarray[i][Assets.Name]}
        </button>,
    )}
 }
    }
    return games
}

function getSelectedGames2(array: boolean[], datarray: any[]): JSX.Element[] {
    const games: JSX.Element[] = []
    if (array && datarray) { for (let i: number = 0; i < datarray.length; i++) { if (array[i] === true) { games.push (
        <button
            style = {{marginBottom: '3px'}}
            className = "btn btn-success btn-sm"
            key = {i.toString()}
            onClick = {() => DisableSelectedGame2(i)}
        >
            {datarray[i][Assets.Name]}
        </button>,
    )}
 }
    }
    return games
}

export function isAllSelectedFlexible(array: boolean[], datarray:any[]): boolean {
    if (datarray && array) { for (let i: number = 0; i < datarray.length; i++) { if (array[i] === true && datarray[i]['FlexibleFreespinsEnabled'] === false) { return false } } }
    
    return true
}

export function isAllSelectedFreespin(array: boolean[], datarray:any[]): boolean {
    if (datarray && array) { for (let i: number = 0; i < datarray.length; i++) { if (array[i] === true && datarray[i]['FreespinsEnabled'] === false) { return false } } }
    return true
}

export function isAllSelectedNetent(array: boolean[], datarray:any[]): boolean {
    if (datarray && array) { for (let i: number = 0; i < datarray.length; i++) { if (array[i] === true && datarray[i]['Provider'] !== 'NetEnt') { return false } } }
    return true
}

function SelectGame(which: number, category: number) {
    // console.log (which, category)
    if (category === 0) {
        const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        GamesStreamAllSelected[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected[which]
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    } else if (category === 1) {
        const GamesStreamAllSelected2: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
        GamesStreamAllSelected2[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected2[which]
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected2})
    } else if (category === 2) {
        const GamesStreamAllSelected3: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
        GamesStreamAllSelected3[which] = !Main.Instance.DatabaseDataState.GamesStreamAllSelected3[which]
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected3})
    }

    const CSV: any = Object.assign({}, Main.Instance.RewardShopState.CSV)
    if (Main.Instance.GamesState.categorize) {
        if (category === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            if (CSV.category1.minigameId === undefined) { CSV.category1.minigameId = {} }
            if (CSV.category1.minigameId.rewards === undefined) { CSV.category1.minigameId.rewards = [] }
            CSV.category1.minigameId.id = +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']
            CSV.category1.minigameName = Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name]        
        } else if (category === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            if (CSV.category2.minigameId === undefined) { CSV.category2.minigameId = {} }
            if (CSV.category2.minigameId.rewards === undefined) { CSV.category2.minigameId.rewards = [] }
            CSV.category2.minigameId.id = +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']
            CSV.category2.minigameName = Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name]        
        } else if (category === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            if (CSV.category3.minigameId === undefined) { CSV.category3.minigameId = {} }
            if (CSV.category3.minigameId.rewards === undefined) { CSV.category3.minigameId.rewards = [] }
            CSV.category3.minigameId.id = +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']
            CSV.category3.minigameName = Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name]
        }
    } else if (Main.Instance.Modal.ModalLoyaltyFreeRoundsGames === undefined) {
        if (category === 0) {
            if (CSV.category1 === undefined) { CSV.category1 = {} }
            if (CSV.category1.gameIds === undefined) { CSV.category1.gameIds = [] }
            if (CSV.category1.gameNames === undefined) { CSV.category1.gameNames = [] }
            if (CSV.category1.gameProviders === undefined) { CSV.category1.gameProviders = [] }

            let found: number = -1
            for (let i: number = 0; i < CSV.category1.gameIds.length; i++) {
                if (CSV.category1.gameIds[i] === +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']) {
                    found = i
                    break
                }
            }
            if (found === -1) {
                CSV.category1.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id'])
                CSV.category1.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name])
                CSV.category1.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[which]['Provider'])
            } else {
                CSV.category1.gameIds.splice(found, 1)
                CSV.category1.gameNames.splice(found, 1)
                CSV.category1.gameProviders.splice(found, 1)
            }
            CSV.category1.spinsCoinValues = getSpinsCoinValues(CSV.category1.gameIds)
            // alert (JSON.stringify(CSV.category1.spinsCoinValues))
            // CSV.category1.spinValue = 0
        } else if (category === 1) {
            if (CSV.category2 === undefined) { CSV.category2 = {} }
            if (CSV.category2.gameIds === undefined) { CSV.category2.gameIds = [] }
            if (CSV.category2.gameNames === undefined) { CSV.category2.gameNames = [] }
            if (CSV.category2.gameProviders === undefined) { CSV.category2.gameProviders = [] }

            let found: number = -1
            for (let i: number = 0; i < CSV.category2.gameIds.length; i++) {
                if (CSV.category2.gameIds[i] === +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']) {
                    found = i
                    break
                }
            }
            if (found === -1) {
                CSV.category2.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id'])
                CSV.category2.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name])
                CSV.category2.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[which]['Provider'])
            } else {
                CSV.category2.gameIds.splice(found, 1)
                CSV.category2.gameNames.splice(found, 1)
                CSV.category2.gameProviders.splice(found, 1)
            }
            CSV.category2.spinsCoinValues = getSpinsCoinValues(CSV.category2.gameIds)
            // CSV.category2.spinValue = 0
        } else if (category === 2) {
            if (CSV.category3 === undefined) { CSV.category3 = {} }
            if (CSV.category3.gameIds === undefined) { CSV.category3.gameIds = [] }
            if (CSV.category3.gameNames === undefined) { CSV.category3.gameNames = [] }
            if (CSV.category3.gameProviders === undefined) { CSV.category3.gameProviders = [] }

            let found: number = -1
            for (let i: number = 0; i < CSV.category3.gameIds.length; i++) {
                if (CSV.category3.gameIds[i] === +Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id']) {
                    found = i
                    break
                }
            }
            if (found === -1) {
                CSV.category3.gameIds.push(+Main.Instance.DatabaseDataState.GamesStreamAll[which]['Id'])
                CSV.category3.gameNames.push(Main.Instance.DatabaseDataState.GamesStreamAll[which][Assets.Name])
                CSV.category3.gameProviders.push(Main.Instance.DatabaseDataState.GamesStreamAll[which]['Provider'])
            } else {
                CSV.category3.gameIds.splice(found, 1)
                CSV.category3.gameNames.splice(found, 1)
                CSV.category3.gameProviders.splice(found, 1)
            }
            CSV.category3.spinsCoinValues = getSpinsCoinValues(CSV.category3.gameIds)
            // CSV.category3.spinValue = 0
        }
    }
    // alert (JSON.stringify(CSV))
    Logic.Type.New (Main.Instance.RewardShopState, {CSV})
    // Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames:!Main.Instance.Modal.ModalWagerPlanGames})
}

function getGames(dashboard?: boolean, which?: number, Selected?: boolean[]): JSX.Element[] {
    // console.log (Main.Instance.GeneralState.general.gameCategoryContribution)
    // let ii
/*
    console.log ('ii')
    const generalGamesContribution: boolean[][] = [[],[]]
    for (const {} of Assets_.generalGamesContribution[0]) {
        generalGamesContribution[0].push(false)
    }
    for (const {} of Assets_.generalGamesContribution[1]) {
        generalGamesContribution[1].push(false)
    }
*/
    // console.log (Main.Instance.SearchState.search_string)
    if (!Main.Instance.DatabaseDataState.GamesStreamAll) {
        return [<div key = "0"/>]
    }
    // alert (Main.Instance.DatabaseDataState.GamesStreamAll)
    let flexible: boolean
    if (dashboard === false && dashboard !== undefined && which !== undefined && Main.Instance.GamesState.categorize === false) {
        if (Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.isFlexible && Main.Instance.RewardShopState.category_idx === 0) { flexible = true } else if (Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.isFlexible && Main.Instance.RewardShopState.category_idx === 1) { flexible = true } else if (Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.isFlexible && Main.Instance.RewardShopState.category_idx === 2) { flexible = true } else { flexible = false }
    }

    const g: JSX.Element[] = []
    if (!Main.Instance.DatabaseDataState.GamesStreamAllSelected) {
        const GamesStreamAllSelected: boolean[] = []
        const GamesStreamAllSelected2: boolean[] = []
        const GamesStreamAllSelected3: boolean[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected.push(false)
            GamesStreamAllSelected2.push(false)
            GamesStreamAllSelected3.push(false)
        }
        // console.log ('getgames')
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected, GamesStreamAllSelected2, GamesStreamAllSelected3})
    }
    let count: number = 0
    if (Main.Instance.DatabaseDataState.GamesStreamAll) {
        // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            const gameid: number = Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']
            if (IsBuildMyChance()) {
                // if (i === 0) {console.log (0)}
                if (/*which === 0 && */Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && isNaN(Main.Instance.SearchState.search_string as any) && Main.Instance.DatabaseDataState.GamesStreamAll[i]['Name'].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) === -1) {
                    continue
                }

                if (/*which === 0 && */Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && !isNaN(Main.Instance.SearchState.search_string as any) && Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'].toString() !== Main.Instance.SearchState.search_string) {
                    continue
                } 

    /*
                else if (which === 1 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected2[i] === false) {
                    continue
                } else if (which === 2 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected3[i] === false) {
                    continue
                }
    */
            } else {
                if (which === 0 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === false) {
                    continue
                } else if (which === 1 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected2[i] === false) {
                    continue
                } else if (which === 2 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && Main.Instance.DatabaseDataState.GamesStreamAllSelected3[i] === false) {
                    continue
                }
            }
    /*
            let Flexible: boolean
            if (dashboard === false && dashboard !== undefined && which !== undefined && Main.Instance.GamesState.categorize === false) {
                if (Main.Instance.RewardShopState.CSV.category1 && Main.Instance.RewardShopState.CSV.category1.isFlexible && Main.Instance.RewardShopState.category_idx === 0) Flexible = true
                else if (Main.Instance.RewardShopState.CSV.category2 && Main.Instance.RewardShopState.CSV.category2.isFlexible && Main.Instance.RewardShopState.category_idx === 1) Flexible = true
                else if (Main.Instance.RewardShopState.CSV.category3 && Main.Instance.RewardShopState.CSV.category3.isFlexible && Main.Instance.RewardShopState.category_idx === 2) Flexible = true
                else Flexible = false
            }

            if (Flexible === true && Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false) continue
            else if (Flexible === false && Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false) continue
    */  
            let shown: boolean = false
            if (Main.Instance.DatabaseDataState.gamesStreamProvider) {
                for (const Item of Main.Instance.DatabaseDataState.gamesStreamProvider) {
                    let provider = false
                    const id: number = parseInt(Item['id'])
                    for (let j: number = 0; j < Main.Instance.GamesState.providers.length; j++) {
                        if (id === Main.Instance.GamesState.providers[j]) {
                            provider = true
                            break
                        }
                    }
                    if (provider && Item['name'] === Main.Instance.DatabaseDataState.GamesStreamAll[i]['Provider']/*.trim()*/) {
                        // console.log (Item['name'])
                        // console.log (item['Provider'])
                        shown = true
                        break
                    }
                }
            }
            if (shown === false) { continue }
            let contribution: number = -2
            if (IsBuildMyChance()) {
                let Shown: boolean = false
                if (Main.Instance.DatabaseDataState._categories) {
                    for (const Item of Main.Instance.DatabaseDataState._categories) {
                        let provider = false
                        const id: number = parseInt(Item['id'])
                        for (let j: number = 0; j < Main.Instance.GamesState.gameCategories.length; j++) {
                            if (id === Main.Instance.GamesState.gameCategories[j]) {
                                provider = true
                                break
                            }
                        }
                        if (provider && Item['name'] === Main.Instance.DatabaseDataState.GamesStreamAll[i]['Category']/*.trim()*/) {
                            // console.log (Item['name'])
                            // console.log (item['Provider'])
                            Shown = true
                            // console.log (id)
                            if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
                                for (const ITEM of Main.Instance.GeneralState.general.gameCategoryContribution) {
                                    if (ITEM.games) {
                                        for (const IteM of ITEM.games) {
                                            if (IteM/*.id*/ === gameid) {
                                                contribution = ITEM.contribution
                                                break
                                            }
                                        }
                                        if (contribution !== -2) {
                                            break
                                        }
                                    }
                                }
                            }
                            break
                        }
                    }
                }
                // now we have shown and contribution. need to filter based on that
                // console.log (Main.Instance.GamesState.contribution)
                // console.log (contribution)
/*
                if (contribution !== -2) {
                    for (let ii: number = 0; ii < 2; ii++) {
                        for (let jj: number =0; jj < generalGamesContribution[ii].length; jj++) {
                            if (!generalGamesContribution[ii][jj] && gameid === Assets_.generalGamesContribution[ii][jj]) {
                                generalGamesContribution[ii][jj] = true
                                console.log ('set to true')
                            }
                        }
                    }
                }
*/
                if (Shown) {
                    if (Main.Instance.GamesState.contribution !== '-1') {
                        if (parseInt(Main.Instance.GamesState.contribution) !== contribution) {
                            Shown = false
                        }
                    }
                }
                if (Shown === false) { continue }
            }

            // if (Main.Instance.DatabaseDataState.FlagsStreamAllSelected && Main.Instance.DatabaseDataState.FlagsStreamAllSelected[i] === true) continue
            count++
            g.push (
                <div
                    onClick = {dashboard === false && dashboard !== undefined && which !== undefined ? () => SelectGame(i, which) : () => ToggleGame(i)}
                    key = {i.toString()}
                    style = {{
                        cursor: 'pointer',
                        position: 'relative',
                        width: '9.1%',
                        height: '145px',
                        float: 'left',
                        margin: count % 10 === 0 ? '0px 0% 10px 0px': '0px 1% 10px 0px',
                        textAlign: 'center',
                    }}

                    className = {Main.Instance.GamesState.categorize && dashboard !== false && dashboard === undefined ? 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 0 ? 'border-success' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 1 ? 'border-primary' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 2 ? 'border-info' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 3 ? 'border-warning' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 4 ? 'border-yellow' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 5 ? 'border-purple' :
                                'border-danger' :
                                Selected && Selected[i] ? 'border-success' : 
                                dashboard === false ? 'border-primary' : 'border-danger'}
                >
                    {/*
                    <div
                        style = {{
                            position: 'relative',
                            marginLeft: '5%',
                            marginTop: '5%',
                            height: '90%',
                            width: '90%',
                            padding: '7px',
                            background: '#fff',
                            display: 'table'
                        }}
                    >
                    */}
                        <span
                            title = {Assets.GameProvider}
                            style = {{
                                position: 'absolute',
                                top: '-4px',
                                left: '0%',
                                right: '0%',
                                fontSize: '14px',
                                lineHeight: '15px',
                                color: Main.Instance.GamesState.categorize && dashboard !== false && dashboard === undefined ? '#333' : '#fff',
                                padding : '0px 0px 1px 0px',
                                fontWeight: 700,
                            }}
                            
                            className = {Main.Instance.GamesState.categorize && dashboard !== false && dashboard === undefined ? 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 0 ? 'label-success' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 1 ? 'label-primary' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 2 ? 'label-info' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 3 ? 'label-warning' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 4 ? 'label-yellow' : 
                                Main.Instance.GamesState.gameCategories[i] % 6 === 5 ? 'label-purple' :
                                'label-danger' :
                                Selected && Selected[i] ? 'label-success' : 
                                dashboard === false ? 'label-primary' : 'label-danger'}
                        >
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Provider']}
                        </span>
                        {/*IsBuildOddyzzey() ?
                        <>
                        {flexible === undefined || flexible === false ?
                        <span
                            style = {{
                                position: 'absolute',
                                bottom: '-3px',
                                left: '4px',
                                fontSize: '12px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false ? '#ff0000' : '#00aa00',
                            }}
                        >
                            free spins
                        </span> : void 0}

                        {flexible === undefined || flexible === true ?
                        <span
                            style = {{
                                position: 'absolute',
                                // bottom: flexible === true ? '-2px' : '12px',
                                bottom: '9px',
                                left: '4px',
                                fontSize: '12px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false ? '#ff0000' : '#00aa00',
                            }}
                        >
                            flexible fs
                        </span> : void 0}

                        {flexible === undefined || flexible === true ?
                        <span
                            style = {{
                                position: 'absolute',
                                // bottom: flexible === true ? '-2px' : '12px',
                                bottom: '9px',
                                right: '2px',
                                fontSize: '14px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false ? '#d9534f' : '#5cb85c',
                            }}
                        >
                            <i
                                className = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false ? 
                                            Assets_.faCancelButtonClass : Assets_.faCheckButtonClass}
                            />
                        </span> : void 0}

                        {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                        Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? void 0 :
                        <span
                            title = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length > 0 ? 
                            'Coin values: ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] : ''}
                            style = {{
                                position: 'absolute',
                                bottom: '21px',
                                left: '4px',
                                fontSize: '12px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                                    Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? '#ff0000' : '#00aa00',
                            }}
                        >
                            coin values
                        </span>}

                        {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                        Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? void 0 :
                        <span
                            title = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length > 0 ? 
                                    Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] : ''}
                            style = {{
                                position: 'absolute',
                                bottom: '21px',
                                right: '2px',
                                fontSize: '14px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                                Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? '#d9534f' : '#5cb85c',
                            }}
                        >
                            <i
                                className = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined ||
                                Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? Assets_.faCancelButtonClass : Assets_.faCheckButtonClass}
                            />
                        </span>}

                        {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined || 
                        Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? void 0 :
                        <span
                            style = {{
                                position: 'absolute',
                                bottom: '33px',
                                left: '4px',
                                fontSize: '12px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined || 
                                    Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? '#ff0000' : '#00aa00',
                            }}
                        >
                            paylines
                        </span>}

                        {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined || 
                        Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? void 0 :
                        <span
                            title = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'].length > 0
                            && Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length > 0 ?
                            'PaylineBets: ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'] + ', Paylines: ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] : 
                            Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'].length > 0 ?
                            'PaylineBets: ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['PaylineBets'] :
                            Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length > 0 ?
                            'Paylines: ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines']  : ''}
                            style = {{
                                position: 'absolute',
                                bottom: '33px',
                                right: '2px',
                                fontSize: '14px',
                                color:  Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined ||
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? '#d9534f' : '#5cb85c'
                            }}
                        >
                            <i
                                className = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined ||
                                Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? Assets_.faCancelButtonClass : Assets_.faCheckButtonClass}
                            />
                        </span>}
                        {flexible === undefined || flexible === false ?
                        <span
                            style = {{
                                position: 'absolute',
                                bottom: '-5px',
                                right: '2px',
                                fontSize: '14px',
                                color: Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false ? '#d9534f' : '#5cb85c',
                            }}
                        >
                            <i
                                className = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false ? 
                                            Assets_.faCancelButtonClass : Assets_.faCheckButtonClass}
                            />
                        </span> : void 0}

                        <span
                            style = {{
                                position: 'absolute',
                                left: '1px',
                                top: '9px',
                                fontSize: '14px',
                                color: '#333',
                            }}
                        >
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']}
                        </span>

                        </> :
                        */}
                        <p 
                            className = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                            Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? 'gamedata' : 'gamedata gamedata-darker'}
                            title = {
                                (Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                                Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? '' : 
                                Assets.CoinValues + ': ' + JSON.stringify(Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues']))
                                +
                                (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined || 
                                Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? '' : 
                                Assets.Paylines + ': ' + JSON.stringify(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines']))
                            }
                        >
                            {IsBuildMyChance() ?
                            <>
                                {/*i === 0 ? console.log (Main.Instance.DatabaseDataState.GamesStreamAll[i]) : void 0*/}
                                <div className = "clearfix">
                                    <div className = "fl" style = {{textAlign: 'center', width: '60%'}}>
                                        <div style = {{color: '#fff', display: 'inline-block', minWidth: '10px', borderRadius: '10px', padding: '2px 8px', background: '#d9534f', fontSize: '16px', lineHeight: '20px', fontWeight: 600}} title = {Assets.GameID}>
                                            {gameid}
                                        </div>
                                    </div>
                                    <div className = "fl" style = {{textAlign: 'center', width: '40%'}}>
                                        <div style = {{display: 'inline-block', minWidth: '10px', /*borderRadius: '10px', padding: '2px 8px', background: '#d9534f',*/ fontSize: '15px', lineHeight: '20px', fontWeight: 700}} title = {Assets.Game + ' ' + Assets.Contribution.toLowerCase()}>
                                            {contribution !== -2 ? contribution + '%' : Assets_.NA}
                                        </div>
                                    </div>
                                </div>
                                {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Category'] ?
                                <div style = {{marginTop: '2px', textAlign: 'center', fontSize: '16px', lineHeight: '18px', fontWeight: 400}} title = {Assets.GameCategory + ': ' + Main.Instance.DatabaseDataState.GamesStreamAll[i]['Category']}>                                    
                                    {/* Main.Instance.DatabaseDataState.GamesStreamAll[i]['Category'] !== 'Table Games' ?*/ Main.Instance.DatabaseDataState.GamesStreamAll[i]['Category'] /*: categoryDescription(Main.Instance.DatabaseDataState.GamesStreamAll[i].Id)*/}
                                </div> : void 0}

                                {/*Main.Instance.DatabaseDataState.GamesStreamAll[i]['Platform'] ?
                                <div style = {{textAlign: 'center', fontSize: '16px', lineHeight: '16px', fontWeight: 300}} title = {Assets.Game + ' ' + Assets.Platform.toLowerCase()}>
                                    {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Platform']}
                                </div> : void 0*/}
                                {Main.Instance.DatabaseDataState.GamesStreamAll[i]['DesktopTableId'] ?
                                <div style = {{textAlign: 'center', fontSize: '14px', lineHeight: '16px', fontWeight: 300}} title = {Assets.Desktop + ' ' + Assets.Table.toLowerCase() + ' ' + Assets.ID}>
                                    {Main.Instance.DatabaseDataState.GamesStreamAll[i]['DesktopTableId'] }
                                </div> : void 0}
                                {Main.Instance.DatabaseDataState.GamesStreamAll[i]['MobileTableId'] ?
                                <div style = {{textAlign: 'center', fontSize: '14px', lineHeight: '16px', fontWeight: 300}} title = {Assets.Mobile + ' ' + Assets.Table.toLowerCase() + ' ' + Assets.ID}>
                                    {Main.Instance.DatabaseDataState.GamesStreamAll[i]['MobileTableId'] }
                                </div> : void 0}

                                {Main.Instance.DatabaseDataState.GamesStreamAll[i]['ExternalId'] ?
                                <div style = {{textAlign: 'center', fontSize: '14px', lineHeight: '16px', fontWeight: 300}} title = {Main.Instance.DatabaseDataState.GamesStreamAll[i]['ExternalId']}>
                                    {Assets.External + ' ' + Assets.ID + ' (' + Assets.hover + ')'}
                                </div> : void 0}


                            </>
                            :
                            <>
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'] + ', '}
                            {flexible === undefined || flexible === false ? Assets.Freespins + ': ' : ''}
                            {flexible === undefined || flexible === false ? Main.Instance.DatabaseDataState.GamesStreamAll[i]['FreespinsEnabled'] === false ? 'NO, ' : 'YES, ' : ''}
                            {flexible === undefined || flexible === true ? Assets.FlexibleFreespins + ': ' : ''}
                            {flexible === undefined || flexible === true ? Main.Instance.DatabaseDataState.GamesStreamAll[i]['FlexibleFreespinsEnabled'] === false ? 'NO, ' : 'YES, ' : ''}
                            {Assets.CoinValues + ': '}
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'] === undefined || 
                            Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues'].length === 0 ? Assets.NO + ', ' : Assets.YES + ', '}
                            {Assets.Paylines + ': '}
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'] === undefined || 
                            Main.Instance.DatabaseDataState.GamesStreamAll[i]['Paylines'].length === 0 ? Assets.NO : Assets.YES}
                            </>}
                        </p>

                        <span
                            title = {Assets.GameName}
                            style = {{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                top: '13px',
                                height: '40px',
                                padding: '1px 2px 0px',
                                fontWeight: 700,
                                wordWrap: 'break-word',
                                fontSize: 
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 40 ? '13px' :
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 30 ? '14px' :
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 20 ? '15px' :
                                        '16px',
                                lineHeight: 
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 40 ? '12px' :
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 30 ? '13px' :
                                        Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name].length > 20 ? '14px' :
                                        '20px',
                            }}
                        >
                            {Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name]}
                        </span>
                </div>,
            )
        }
    }
/*
    for (let ii: number = 0; ii < 2; ii++) {
        for (let jj: number =0; jj < generalGamesContribution[ii].length; jj++) {
            if (!generalGamesContribution[ii][jj]) {
                console.log (Assets_.generalGamesContribution[ii][jj])
            }
        }
    }
*/
    // console.log (count)
    if (count !== Main.Instance.GamesState.count) {
        Logic.Type.New (Main.Instance.GamesState, {count})
    }
    return g
}

/* function categoryDescription(id): string {
    // if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
    //     for (const item of Main.Instance.GeneralState.general.gameCategoryContribution) {
    //         if (item.category === 'Table Games' || item.category === 'Roulette' || item.category === 'Black Jack') {
    //             for (const Item of item.games) {
    //                 if (id === Item.id) {
    //                     return item.category
    //                 }
    //             }
    //         }
    //     }
    // }if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
    if (id === 2527 || id === 1084 || id === 425 || id === 2531 || id === 548 || id === 547 || id === 575 || id === 242 || id === 243 || id === 244 || id === 245 || id === 246 || id === 2185 || id === 247 || id === 947 || id === 2117) {
        return Assets.Roulette
    } else if (id === 334 || id === 118 || id === 119 || id === 120 || id === 2184 || id === 125 || id === 126 || id === 923 || id === 995 || id === 151 || id === 152 || id === 423 || id === 424 || id === 256 || id === 257 || id === 258) {
        return Assets.BlackJack
    } else {
        return Assets.TableGames
    }
} */

function Categorize() {
    // here we need to prepare the stream for categorization - gamesCategories
    // console.log (Main.Instance.DatabaseDataState.pointProgramStream)
    // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
    // console.log (Main.Instance.CRMPointState.id)
    let category: number = -1
    let Category: string = ''
    const categories: number[] = []
    const Categories: string[] = []
    const includedGames: number[][] = []
    // console.log (Main.Instance.CRMPointState.id)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.pointProgramStream.length; i++) {
        if (+Main.Instance.DatabaseDataState.pointProgramStream[i]['id'] === +Main.Instance.CRMPointState.id) {
            category = i
            Category = Main.Instance.DatabaseDataState.pointProgramStream[i]['type']
        }
        if (Main.Instance.DatabaseDataState.pointProgramStream[i]['category_id'] === 3200) { continue }
        if (Main.Instance.DatabaseDataState.pointProgramStream[i]['From Date'].trim().length > 0) { continue }
        if (Main.Instance.DatabaseDataState.pointProgramStream[i]['To Date'].trim().length > 0) { continue }
        if (Main.Instance.DatabaseDataState.pointProgramStream[i]['Selected Players'] !== 'All players') { continue }
        categories.push (+Main.Instance.DatabaseDataState.pointProgramStream[i]['id'])
        Categories.push (Main.Instance.DatabaseDataState.pointProgramStream[i]['type'])
        includedGames.push(Main.Instance.DatabaseDataState.pointProgramStream[i]['includedgames'])
    }
    // console.log (categories)
    // console.log (includedGames)

    const gameCategories: number[] = []
    // here we need to apply all the above data into this table

    for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
        let found: number = -1
        for (let j: number = 0; found === -1 && j < categories.length; j++) {
            for (let k: number = 0; found === -1 && k < includedGames[j].length; k++) {
                if (includedGames[j][k] === item['Id']) {
                    found = j
                }
            }
        }
        gameCategories.push (found) 
    }
    // console.log (gamesCategories)
    Logic.Type.New (Main.Instance.GamesState, {categorize: !Main.Instance.GamesState.categorize, category, Category, categories, gameCategories, includedGames, Categories})
}

function getCategories(): JSX.Element[]  {
    const categories: JSX.Element[] = []
    // console.log (Main.Instance.GamesState.category)
    // if (Main.Instance.GamesState.categories[i] === -1) bg = '#bbb'
    // alert (Main.Instance.GamesState.Categories + ' ' + Main.Instance.GamesState.Categories.length)
    // console.log (Main.Instance.GamesState.Categories)
    for (let i: number = 0; i < Main.Instance.GamesState.Categories.length; i++) {
        // console.log (Main.Instance.GamesState.category)
        if (i === Main.Instance.GamesState.category) { continue }

        let background: string
        let color: string = '#000'
        if (i % 6 === 0) {
            background = '#5cb85c'
            color = '#fff'
        } else if (i % 6 === 1) {
            background = '#337ab7'
            color = '#fff'
        } else if (i % 6 === 2) {
            background = '#5bc0de'
        } else if (i % 6 === 3) {
            background = '#f0ad4e'
        } else if (i % 6 === 4) {
            background = '#ffd500'
        } else if (i % 6 === 5) {
            background = '#9370DB'
            color = '#fff'
        }

        categories.push (
            <div key = {(i+1).toString()}>
                <div style = {{fontWeight: 600, fontSize: '13px', lineHeight: '14px', background, color, textAlign: 'center'}}>
                    {Main.Instance.GamesState.Categories[i]}
                </div>
            </div>,
        )
    }

    return categories
}

export function getProviders(iconButtons?: boolean): JSX.Element[] {
    {/*Main.Instance.DatabaseDataState.GamesStreamProvider ?
    <FormSelect
        selectedByID = {Main.Instance.GamesState.providers}
        span = {true}
        value = {!Main.Instance.GamesState.filter_provider ? '0' : Main.Instance.GamesState.filter_provider}
        data = {Main.Instance.DatabaseDataState.gamesStreamProvider}
        ClassName = "control-panel-select-narrow"
        o1 = {Assets.Any}
        o2 = {Assets.Any}
        onChange = {text => IncludeProvider(text)} 
    /> : void 0*/}
    // console.log (Main.Instance.GamesState.providers)
    // Main.Instance.GamesState.providers
    const providers: JSX.Element[] = []
    const Providers: number = Main.Instance.DatabaseDataState.gamesStreamProvider ? Main.Instance.DatabaseDataState.gamesStreamProvider.length : -1
    
    // providers.push(<span title = {Assets.GameProviders} key = "-3"><FontAwesomeIcon className = "mr" style = {{fontSize: '20px'}} icon = {faPaintRoller}/></span>)

    if (Main.Instance.DatabaseDataState.GamesStreamProvider) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesStreamProvider.length; i++) {
            providers.push (
                <button
                    title = {Assets.Toggle + ' ' + Assets.GameProvider.toLowerCase()}
                    key = {i.toString()}
                    style = {i === Main.Instance.DatabaseDataState.gamesStreamProvider.length - 1 ? 
                            {marginRight: '0px', fontSize: '13px', padding: '4px 10px'} : {fontSize: '13px', padding: '4px 10px'}}
                    className = {isProviderSelected(+Main.Instance.DatabaseDataState.gamesStreamProvider[i]['id']) ? 'btn btn-primary btn-sm pr mb3' : 'btn btn-default btn-sm pr mb3'}
                    onClick = {() => IncludeProvider(Main.Instance.DatabaseDataState.gamesStreamProvider[i]['id'])}
                >
                    {Main.Instance.DatabaseDataState.gamesStreamProvider[i]['name']}
                    <span
                        style = {{position: 'absolute', right: '-10px', top: '-10px', border: '1px solid #2e6da4'}}
                        className = "badge"
                    >
                        {Main.Instance.DatabaseDataState._providers_[+Main.Instance.DatabaseDataState.gamesStreamProvider[i]['id']]}
                    </span>
                </button>,
            )
        }

        providers.push (
            <button
                disabled = {Providers === Main.Instance.GamesState.providers.length}
                title = {Assets.SelectAll + ' ' + Assets.GameProviders.toLowerCase()}
                key = "-1"
                style = {{marginLeft: '15px'}}
                className = "btn btn-success btn-sm mb3"
                onClick = {() => IncludeProvider(Assets_.SELECT_ALL.toString())}
            >
                {iconButtons ? <><FontAwesomeIcon icon = {faPlus}/>&nbsp;{Assets.All /*+ ' ' + Assets.Providers.toLowerCase()*/}</> : Assets.SelectAll + ' ' + Assets.GameProviders.toLowerCase()}
            </button>,
        )
        
        providers.push (
            <button
                disabled = {Providers === 0}
                title = {Assets.DeselectAll + ' ' + Assets.GameProviders.toLowerCase()}
                key = "-2"
                className = "btn btn-danger btn-sm mr0 mb3"
                onClick = {() => IncludeProvider(Assets_.DESELECT_ALL.toString())}
            >
                {iconButtons ? <><FontAwesomeIcon icon = {faMinus}/>&nbsp;{Assets.All /*+ ' ' + Assets.Providers.toLowerCase()*/}</> : Assets.DeselectAll}
            </button>,
        )
/*
        providers.push (
            <button key = "-3" onClick = {() => AAA()}>AAA</button>
        )
*/
    }
    if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
        // console.log (Main.Instance.GeneralState.general.gameCategoryContribution)
        providers.push (
            <span style = {{marginLeft: '10px'}} key = "-4">{Assets.Game + ' ' + Assets.Contribution.toLowerCase() + ':'}</span>
        )
        providers.push (
            <select
                style = {{borderRadius: '5px', background: '#fff', height: '30px', marginLeft: '5px', paddingLeft: '5px'}}
                title = {Assets.Filter + Assets._by_ + Assets.Contribution.toLowerCase()}
                key = "-3"
                onChange = {(e) => Logic.Type.New (Main.Instance.GamesState, {contribution: e.target.value})}
                value = {Main.Instance.GamesState.contribution}
            >
                <option value = "-1">{Assets.Any}</option>
                <option value = "0">0%</option>
                <option value = "10">10%</option>
                <option value = "50">50%</option>
                <option value = "100">100%</option>
                <option value = "-2">{Assets_.NA}</option>
            </select>
        )
    }
    return providers
}
/*
function AAA() {
    // construct an array
    const s: string[] = Assets_.games.split('\n')
    // console.log (s.length)
    const games: Array<{name: string, gameId1?: number, gameId2?: number}> = []
    for (let i: number = 0; i < s.length; i++) {
        if (s[i].charAt(0) === '|') {
            games.push ({name: s[i].replace('|','').replace('|','')})
        } else {
            if (games[games.length - 1].gameId1 === undefined) {
                games[games.length - 1].gameId1 = +s[i]
            } else if (games[games.length - 1].gameId2 === undefined) {
                games[games.length - 1].gameId2 = +s[i]
            } else {
                console.log ('should not happen')
            }
        }
    }
    // console.log (games)
    // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
    
    for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
        for (const Item of games) {
            if (item.Id === Item.gameId1 || item.Id === Item.gameId2) {
                if (item.Name !== Item.name) {
                    console.log (item.Id, item.Name, Item.name)
                }
                break
            }
        }
    }
}
*/
function GetCategories(selected: number, which: number, iconButtons?: boolean): JSX.Element[] {
    // Main.Instance.DatabaseDataState.Categories
    const categories: JSX.Element[] = []
    const Categories: number = Main.Instance.DatabaseDataState._categories ? Main.Instance.DatabaseDataState._categories.length : -1
    // categories.push(<span title = {Assets.GameCategories} key = "-5"><FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faSitemap}/></span>)
    if (Main.Instance.DatabaseDataState.Categories) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState._categories.length; i++) {
            categories.push (
                <button
                    title = {Assets.Toggle + ' ' + Assets.Category.toLowerCase()}
                    key = {i.toString()}
                    style = {i === Main.Instance.DatabaseDataState._categories.length - 1 ? 
                            {marginRight: '0px', fontSize: '13px', padding: '4px 10px'} : {fontSize: '13px', padding: '4px 10px'}}
                    className = {isCategorySelected(+Main.Instance.DatabaseDataState._categories[i]['id']) ? 'btn btn-info btn-sm mt15 pr' : 'btn btn-default btn-sm mt15 pr'}
                    onClick = {() => IncludeCategory(Main.Instance.DatabaseDataState._categories[i]['id'])}
                >
                    {Main.Instance.DatabaseDataState._categories[i]['name']}
                    <span
                        style = {{position: 'absolute', right: '-10px', top: '-10px', border: '1px solid #46b8da'}}
                        className = "badge"
                    >
                        {Main.Instance.DatabaseDataState._categories_[+Main.Instance.DatabaseDataState._categories[i]['id']]}
                    </span>
                </button>,
            )
        }

        categories.push (
            <button
                disabled = {Categories === Main.Instance.GamesState.gameCategories.length}
                title = {Assets.SelectAll + ' ' + Assets.Categories.toLowerCase()}
                key = "-1"
                style = {{marginLeft: '15px'}}
                className = "btn btn-success btn-sm mt15"
                onClick = {() => IncludeCategory(Assets_.SELECT_ALL.toString())}
            >
                {iconButtons ? <><FontAwesomeIcon icon = {faPlus}/>&nbsp;{Assets.All /*+ ' ' + Assets.Categories.toLowerCase()*/}</> : Assets.SelectAll + ' ' + Assets.Categories.toLowerCase()}
            </button>,
        )
        
        categories.push (
            <button
                disabled = {Categories === 0}
                title = {Assets.DeselectAll + ' ' + Assets.Categories.toLowerCase()}
                key = "-2"
                className = "btn btn-danger btn-sm mr0 mt15"
                onClick = {() => IncludeCategory(Assets_.DESELECT_ALL.toString())}
            >
                {iconButtons ? <><FontAwesomeIcon icon = {faMinus}/>&nbsp;{Assets.All /*+ ' ' + Assets.Categories.toLowerCase()*/}</> : Assets.DeselectAll}
            </button>,
        )

        categories.push (
            <button
                title = {Assets.SelectAll + ' ' + Assets.Games.toLowerCase()}
                key = "-4"
                disabled = {selected === Main.Instance.DatabaseDataState.GamesStreamAll.length}
                className = "btn btn-sm btn-success pr ml mt15"
                onClick = {() => selectAllGames(which)}
            >
                <FontAwesomeIcon icon = {faPlus}/>&nbsp;{Assets.All + ' ' + Assets.Games.toLowerCase()}
                <span
                    style = {{position: 'absolute', right: '-10px', top: '-10px', border: '1px solid #4cae4c'}}
                    className = "badge"
                >
                    {Main.Instance.DatabaseDataState.GamesStreamAll ? getQualifiedGames() : 0}
                </span>
            </button>
            )

        categories.push (
            <button
                title = {Assets.DeselectAll + ' ' + Assets.Games.toLowerCase()}
                key = "-3"
                disabled = {selected === 0}
                className = "btn btn-sm btn-danger pr mr mt15"
                onClick = {() => DeselectAllGames(which)}
            >
                {/*Assets.DeselectAllGames*/}
                <FontAwesomeIcon icon = {faMinus}/>&nbsp;{Assets.All + ' ' + Assets.Games.toLowerCase()}
                <span
                    style = {{position: 'absolute', right: '-10px', top: '-10px', border: '1px solid #d43f3a'}}
                    className = "badge"
                >
                    {Main.Instance.DatabaseDataState.GamesStreamAll ? Main.Instance.DatabaseDataState.GamesStreamAll.length : 0}
                </span>
            </button>
        )

        categories.push (
            <button
                title = {Assets.Invert + ' ' + Assets.Games.toLowerCase()+ ' ' + Assets.Selection.toLowerCase()}
                key = "-5"
                className = "btn btn-sm btn-warning pr mr0 mt15"
                onClick = {() => invertGamesAll(which)}
            >
                <FontAwesomeIcon icon = {faExchangeAlt}/>
                {/*
                <span
                    style = {{position: 'absolute', right: '-10px', top: '-10px', border: '1px solid #d43f3a'}}
                    className = "badge"
                >
                    {Main.Instance.DatabaseDataState.GamesStreamAll ? Main.Instance.DatabaseDataState.GamesStreamAll.length : 0}
                </span>
                */}
            </button>
        )
    }
    return categories
}

function invertGamesAll(which: number) {
    const GamesStreamAllSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
        GamesStreamAllSelected[i] = !GamesStreamAllSelected[i]
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
}

function notCategory(category: string): boolean {

    if (Main.Instance.DatabaseDataState.Categories && Main.Instance.GamesState.gameCategories) {
        let Category: number
        for (const item of Main.Instance.DatabaseDataState.Categories) {
            if (item.name === category) {
                Category = +item.id
                break
            }
        }
        if (Category) {
            for (const item of Main.Instance.GamesState.gameCategories) {
                if (item === Category) {
                    return false
                }
            }
        }
    }
    return true
}

function notProvider(provider: string): boolean {

    if (Main.Instance.DatabaseDataState.Categories && Main.Instance.DatabaseDataState.gamesStreamProvider) {
        let Provider: number
        for (const item of Main.Instance.DatabaseDataState.gamesStreamProvider) {
            if (item.name === provider) {
                Provider = +item.id
                break
            }
        }
        if (Provider) {
            for (const item of Main.Instance.GamesState.providers) {
                if (item === Provider) {
                    return false
                }
            }
        }
    }
    return true
}

function getQualifiedGames(): number {
    let qualifiedGames: number = 0
    // console.log (Main.Instance.DatabaseDataState.Categories)
    // console.log (Main.Instance.GamesState.gameCategories) // number[]
    // console.log (Main.Instance.DatabaseDataState.gamesStreamProvider)
    // console.log (Main.Instance.GamesState.providers) // number[]
    // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
        // to qualify, the game needs to be hit on category and provider
        if (notCategory(Main.Instance.DatabaseDataState.GamesStreamAll[i].Category)) {continue}
        if (notProvider(Main.Instance.DatabaseDataState.GamesStreamAll[i].Provider)) {continue}
        qualifiedGames++
    }
    return qualifiedGames
}

function isProviderSelected(which: number) : boolean {
    for (const item of Main.Instance.GamesState.providers) {
        if (item === which) { return true }
    }
    return false
}

function isCategorySelected(which: number) : boolean {
    for (const item of Main.Instance.GamesState.gameCategories) {
        if (item === which) { return true }
    }
    return false
}

export function GamesSelection(dashboard?: boolean, which?: number) {
    // console.log (Main.Instance.DatabaseDataState._categories)
    // console.log (Main.Instance.DatabaseDataState.Categories)
    // console.log (Main.Instance.DatabaseDataState.GameProviders)
    let Selected: boolean[]
    // console.log (which)
    if (IsBuildMyChance()) {
        // console.log ('which 0')
        which = 0
    }
    if (which === undefined || which === 0) {
        Selected = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    } else if (which === 1) {
        Selected = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected2)
    } else if (which === 2) {
        Selected = Object.assign ([], Main.Instance.DatabaseDataState.GamesStreamAllSelected3)
    }
    // console.log (which)
    if (Main.Instance.FrontendStoreState.first_time) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {
                first_time: false,
            })
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId),
                                                SelectSite)
            }
    }
/*
    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
*/
    let background: string = '#fff'
    let color: string = '#000'
    if (Main.Instance.GamesState.category !== undefined) {
        // console.log (Main.Instance.GamesState.category)
/*
        if (Main.Instance.GamesState.category % 6 === 0) background = '#5cb85c'
        else if (Main.Instance.GamesState.category % 6 === 1) background = '#337ab7'
        else if (Main.Instance.GamesState.category % 6 === 2) background = '#5bc0de'
        else if (Main.Instance.GamesState.category % 6 === 3) background = '#f0ad4e'
        else if (Main.Instance.GamesState.category % 6 === 4) background = '#ffd500'
        else if (Main.Instance.GamesState.category % 6 === 5) background = '#9370DB'
*/
        if (Main.Instance.GamesState.Categories) {
            if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[0]) {
                background = '#5cb85c'
                color = '#fff'
                if (Main.Instance.GamesState.category !== 0) {Logic.Type.New (Main.Instance.GamesState, {category: 0}) }
            } else if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[1]) {
                background = '#337ab7'
                color = '#fff'
                if (Main.Instance.GamesState.category !== 1) { Logic.Type.New (Main.Instance.GamesState, {category: 1}) }
            } else if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[2]) {
                background = '#5bc0de'
                if (Main.Instance.GamesState.category !== 2) { Logic.Type.New (Main.Instance.GamesState, {category: 2}) }
            } else if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[3]) {
                background = '#f0ad4e'
                if (Main.Instance.GamesState.category !== 3) { Logic.Type.New (Main.Instance.GamesState, {category: 3}) }
            } else if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[4]) {
                background = '#ffd500'
                if (Main.Instance.GamesState.category !== 4) { Logic.Type.New (Main.Instance.GamesState, {category: 4}) }
            } else if (Main.Instance.GamesState.Category === Main.Instance.GamesState.Categories[5]) {
                background = '#9370DB'
                color = '#fff'
                if (Main.Instance.GamesState.category !== 5) { Logic.Type.New (Main.Instance.GamesState, {category: 5}) }
            }
        }
    }
    // console.log ('before')
    const selected: number = getSelected(Selected)
    // console.log (selected)
    return (
    <div>

    <div className = "clearfix" style = {{position: 'relative'}}>
        <h3 style = {{width: 'auto'}} className = "level fl mr5">
        {dashboard !== undefined && dashboard === true ? Assets.GamesInfo : Assets.GamesSelection}
        </h3>
        {/*isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : null*/}

        {/*isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        /> : null*/}

        {/*!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0*/}

        {IsBuildScandibet() && Main.Instance.CRMPointState.mode ?
        <button 
            onClick = {() => Categorize()}
            style = {{marginRight: '0px', marginTop: '20px'}}
            className = {Main.Instance.GamesState.categorize ? 'btn btn-danger btn-sm fr dbl' : 'btn btn-warning btn-sm fr dbl'}
        >
            {Main.Instance.GamesState.categorize ? Assets.TurnOff + ' ' + Assets.Categorizaton : Assets.TurnOn + ' ' + Assets.Categorizaton}
        </button>
        : void 0}

        {IsBuildScandibet() && Main.Instance.CRMPointState.mode && Main.Instance.GamesState.categorize ?
        <span
            className = "label label-danger label-sm dbl fr"
            style = {{color, background, fontSize: '12px', height: '29.26px', paddingTop: '9px', marginTop: '20px', marginRight: '10px'}}
        >
        {Assets.Category}:&nbsp;{Main.Instance.GamesState.Category}
        </span>
        : void 0}

        {IsBuildScandibet() && Main.Instance.CRMPointState.mode && Main.Instance.GamesState.categorize ?
        <div className = "fr" style = {{border: '1px solid #bbb', marginRight: '10px', marginTop: '-10px'}}>
            <div className = "clearfix">
            {Main.Instance.GamesState.Categories ? getCategories() : void 0}
            </div>
        </div>
        : void 0}

        {IsBuildMyChance() ? GetCategories(selected, which, true) : void 0}

        <div className = "fr mt15">
            <span className = "label label-success table-label mt2">
                {Main.Instance.GamesState.count ? Main.Instance.GamesState.count : 0}
            </span>
            <span className = "label label-success table-label mt2" style = {{background: '#5cb85c'}}>
                {Main.Instance.GamesState.count ?
                (100 * selected / Main.Instance.GamesState.count).toFixed(2) : 0}
                %
            </span>
            <span className = "label label-success table-label mt2" style = {{background: '#5cb85c'}}>
                {Main.Instance.GamesState.selected !== undefined ? selected : 0}
            </span>
        </div>
    </div>
    <div>
        <div className = "games-selection"> 
            {IsBuildScandibet() || IsBuildOddyzzey() && Main.Instance.Modal.ModalLoyaltyFreeRoundsGames !== undefined ? Main.Instance.GamesState.categorize ?
            <>
                <button
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-success"
                    onClick = {() => SelectAllGames_(true)}
                >
                    {Assets.gs1}
                </button>
                <button
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-danger"
                    onClick = {() => SelectAllGames_(false)}
                >
                    {Assets.gs2}
                </button>
            </>
            :
            <>
                <button
                    style = {{marginBottom: '3px'}}
                    disabled = {!(Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0)}
                    className = "btn btn-sm btn-primary"
                    onClick = {() => SelectAllGamesNotDelete_()}
                >
                    {Assets.ApplySearch}
                </button>

                <button
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-success"
                    onClick = {() => SelectAllGames(true)}
                >
                    {Assets.SelectAll}
                </button>
                <button
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-danger"
                    onClick = {() => SelectAllGames(false)}
                >
                    {Assets.DeselectAll}
                </button>
                {selected > 0 && selected < 100 ? getSelectedGames2(Selected, Main.Instance.DatabaseDataState.GamesStreamAll) : void 0}
            </> : void 0}
            {/*console.log(Main.Instance.Modal.ModalLoyaltyFreeRoundsGames)*/}
            {IsBuildOddyzzey() && Main.Instance.Modal.ModalLoyaltyFreeRoundsGames === undefined ?
            <>
                {/*
                <button
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-danger"
                    onClick = {() => selectAllGames(which)}
                >
                    Select all games
                </button>
                */}
                <button
                    style = {{marginBottom: '3px'}}
                    disabled = {!(Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0)}
                    className = "btn btn-sm btn-primary"
                    onClick = {() => SelectGamesNotDelete(which)}
                >
                    {Assets.ApplySearch}
                </button>

                <button
                    disabled = {selected === 0}
                    style = {{marginBottom: '3px'}}
                    className = "btn btn-sm btn-danger"
                    onClick = {() => DeselectAllGames(which)}
                >
                    {Assets.DeselectAllGames}
                </button>
                {selected > 0 && selected < 100 ? getSelectedGames(Selected, Main.Instance.DatabaseDataState.GamesStreamAll) : void 0}
            </>
            : void 0}

            {/*IsBuildMyChance() ? <div className = "clearfix">{getProviders(true)}</div> : void 0*/}
            {IsBuildMyChance() ? getProviders(true) : void 0}
            {/* Main.Instance.GamesState.categorize */}
            


            {/*selected > 0 ? 
            <span className = "label label-success label-sm" style = {{fontSize: '14px', padding: '7px 12px', marginLeft: '10px'}}>
                {selected <= 10 ? getSelectedGames(Selected, Main.Instance.DatabaseDataState.GamesStreamAll)
                : selected + ' games are selected.'}
                </span> : void 0*/}
            {/*
            <FormSelect 
                span = {true}
                narrow = {true}
                value = {!Main.Instance.GamesState.filter_freespins ? '0' : Main.Instance.GamesState.filter_freespins}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = "Freespin Any"
                o2 = "Freespin Yes"
                o3 = "Freespin No"
                onChange = {text => ApplyFilterFreespins(text)}
            />

            <FormSelect 
                span = {true}
                narrow = {true}
                value = {!Main.Instance.GamesState.filter_flexible ? '0' : Main.Instance.GamesState.filter_flexible}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = "Flexible Any"
                o2 = "Flexible Yes"
                o3 = "Flexible No"
                onChange = {text => ApplyFilterFlexibleFreespins(text)}
            />

            <FormSelect
                span = {true}
                value = {!Main.Instance.GamesState.filter_active ? '0' : Main.Instance.GamesState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = "Coinvalues Any"
                o2 = "Coinvalues Yes"
                o3 = "Coinvalues No"
                onChange = {text => ApplyFilterCoinValues(text)}
            />
            */}
            {/*
            <span className = "label label-success table-label mt2">
                {Main.Instance.GamesState.count ? Main.Instance.GamesState.count : 0}
            </span>
            <span className = "label label-success table-label mt2" style = {{background: '#5cb85c'}}>
                {Main.Instance.GamesState.count ?
                (100 * selected / Main.Instance.GamesState.count).toFixed(2) : 0}
                %
            </span>
            <span className = "label label-success table-label mt2" style = {{background: '#5cb85c'}}>
                {Main.Instance.GamesState.selected !== undefined ? selected : 0}
            </span>
            */}
        </div>
        {/*console.log (Main.Instance.DatabaseDataState.GamesStreamAll)*/}
        {!Main.Instance.DatabaseDataState.GamesStreamAll ? getGamesSelectionCount() :
        <div style = {{marginTop: '10px'}} className = "clearfix">
            {getGames(dashboard, which, Selected)}
        </div>}
        </div>
    </div>)
}

function getSpinsCoinValues(gameIds: number[]): number[] {
    let spinsCoinValues: number[] = []
    for (const item of gameIds) {
        let SpinsCoinValues: number[] = Object.assign([], spinsCoinValues)
        for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
            if (item === +Item['Id']) {
                // alert (Item['CoinValues'])
                let i: number = 0
                if (spinsCoinValues.length === 0 && Item['CoinValues'] && Item['CoinValues'].length > 0) {
                    SpinsCoinValues = Object.assign([], Item['CoinValues'])
                } else if (spinsCoinValues.length === 0 && (!Item['CoinValues'] || Item['CoinValues'].length === 0) && Item['Provider'] === 'NetEnt') {
                    SpinsCoinValues = [1, 2, 3, 4, 5, 6, 7]
                } else { while(i < SpinsCoinValues.length) {
                    let found: boolean = false
                    if (Item['CoinValues'] && Item['CoinValues'].length > 0) {
                        for (const ITEM of Item['CoinValues']) {
                            if (SpinsCoinValues[i] === ITEM) {
                                found = true
                            }
                        }
                        if (!found) { SpinsCoinValues.splice (i, 1) }
                    } else { found = true }
                    if (found) { i++ }
                }
 }
                break
            }
        }
        spinsCoinValues = Object.assign([], SpinsCoinValues)
    }

    return spinsCoinValues
}