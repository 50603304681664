import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import {IssueTypes, IIssuesCount} from '../../../Logic/Database/DatabaseData'
import {injectSpaces} from '../../../Logic/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTasks, faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import { Main } from 'src/Logic/Main';

interface Data {
    id: number
    name: string
    category: string
    group: string
    issues: {
        total: number,
        resolved: number,
        unresolved: number
    }
}

interface State {
    selectedId: string
    selectedName: string
    selectedIssues: Data[]
    selectedCategory: string
    selectedGroup: string
}



export class IssuesList extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            selectedId: '-1',
            selectedName: '-1',
            selectedIssues: [],
            selectedCategory: '-1',
            selectedGroup: '-1',
        }
        DbRoutes.wsReinitialize()
    }

    public getBoldText(id: number, value): JSX.Element {
        let color: string = ''

        if (id.toString().charAt(0) === '1') {
            color = '#E0BBE4'
        } else if (id.toString().charAt(0) === '2') {
            color = '#D291BC'
        } else if (id.toString().charAt(0) === '3') {
            color = '#FEC8D8'
        } else if (id.toString().charAt(0) === '4') {
            color = '#FFDFD3'
        } else {
            color = ''
        }

        return (
            <div style = {{fontWeight: id % 100 === 0 ? 700 : 400, background: color, color: value === 0 && id !== 0 ? '#8b8b8b' : ''}}>
                &nbsp;&nbsp;{value}
            </div> 
        )
        
        
    }

    public filterIssues(data, id?: number, name?: string, category?: string, group?: string) {
        const issues: Data[] = []
        
        if (id === -1 || parseInt(name) === -1 || parseInt(category) === -1) {
            for (const item of data) {
                issues.push(item)
            }
        } else if (parseInt(group) === -1) {
            for (const item of data) {
                if (item.category === this.state.selectedCategory) {
                    issues.push(item)
                }
            }
            
        } else {
            for (const item of data) {
                if (item.id === id || item.name === name || item.category === category || item.group === group) {
                    issues.push(item)
                }
            }
        }
        this.setState({selectedIssues: issues})
        
    }

    public getValue(data, which: number): string {
        if (which === 0) {
            for (const item of data) {
                if (item.name === this.state.selectedName) {
                    return item.id.toString()
                }
            }
        } else if (which === 1) {
            for (const item of data) {
                if (item.id === parseInt(this.state.selectedId)) {
                    return item.name
                }
            }
        } 
    }

    public getIdOptions(data): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.ID}</option>)
        
        for (let i: number = 0; i < data.length; i++) {
            options.push (<option key = {i.toString()} value = {data[i].id}>{data[i].id}</option>)
        }
    
        return options
    }

    public getCategoryOptions(data): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.Issue.toLowerCase()} {Assets.category}</option>)
        for (let i: number = 0; i < data.length; i++) {
            if(data[i].id > 0 && data[i].id % 100 === 0) {
                options.push (<option key = {i.toString()} value = {data[i].category}>{data[i].category}</option>)
            }
            
        }
    
        return options
    }

    public getGroupOptions(data): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.Issue.toLowerCase()} {Assets.group}</option>)
        const groups = []
        for (let i: number = 0; i < data.length; i++) {
            if (data[i].category === this.state.selectedCategory && !groups.includes(data[i].group)) {
                groups.push(data[i].group)
            }
        }
        for (let i: number = 0; i < groups.length; i++) {
            if (i > 0) {
                options.push (<option key = {i.toString()} value = {groups[i]}>{groups[i]}</option>)
            }
            
        }
        
        return options
    }

    public compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
          return 1;
        }
        return 0;
      }

    public getNameOptions(data, sort?: boolean): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.Issue.toLowerCase()}</option>)
        const edata: Array<{id: number, name: string}> = Object.assign([], data)

        const nameAlphabetized = sort ? edata.sort(this.compare) : data
        for (let i: number = 0; i < nameAlphabetized.length; i++) {
            options.push (<option key = {i.toString()} value = {nameAlphabetized[i].name}>{nameAlphabetized[i].name}</option>)
        }
        
        return options
    }

    public getAutocomplete(data) {
        const options: JSX.Element[] = []
        const edata: Array<{id: number, name: string}> = Object.assign([], data)
        const nameAlphabetized = edata.sort(this.compare)
        for (const item of nameAlphabetized) {
            options.push(<option key = {item.id}>{item.name}</option>)
        }
        return (
            <datalist id = "names">
                {options}
            </datalist>
        )
    }

    public render() {
        
        const issueTypes: string[] = Object.keys(IssueTypes)
        
        const data: Data[] = []
        let issueCategory: string
        let issueGroup: string
        let total: number
        let resolved: number
        let unresolved: number
        const totalIssuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.issuesCount)
        const resolvedIssuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.resolvedIssuesCount)
        const unresolvedIssuesCount: IIssuesCount[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesCount)
        for (let i: number = 0; i < issueTypes.length / 2; i++) {
            total = 0
            resolved = 0
            unresolved = 0
            if(issueTypes[i].charAt(0) === '1') {
                issueCategory = injectSpaces(IssueTypes[100])
            } else if(issueTypes[i].charAt(0) === '2') {
                issueCategory = injectSpaces(IssueTypes[200])
            } else if(issueTypes[i].charAt(0) === '3') {
                issueCategory = injectSpaces(IssueTypes[300])
            } else if(issueTypes[i].charAt(0) === '4') {
                issueCategory = injectSpaces(IssueTypes[400])
            } else {
                issueCategory = ''
            }

            if (110 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 120) {
                issueGroup = injectSpaces(IssueTypes[110])
            } else if (210 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 219) {
                issueGroup = injectSpaces(IssueTypes[210])
            } else if (250 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 259) {
                issueGroup = injectSpaces(IssueTypes[250])
            } else if (340 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 349) {
                issueGroup = injectSpaces(IssueTypes[340])
            } else if (360 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 368) {
                issueGroup = injectSpaces(IssueTypes[360])
            } else if (370 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 374) {
                issueGroup = Assets.Address + ' ' + Assets.Check.toLowerCase()
            } else if (375 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 379) {
                issueGroup = Assets.PoliticallyExposedPerson
            } else if (380 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 389) {
                issueGroup = injectSpaces(IssueTypes[380])
            } else if (410 <= parseInt(issueTypes[i]) && parseInt(issueTypes[i]) <= 419) {
                issueGroup = injectSpaces(IssueTypes[410])
            } else {
                issueGroup = ''
            }

            
            for (let j = 0; j < totalIssuesCount.length; j++) {
                if (parseInt(issueTypes[i]) === 0) {
                    total += totalIssuesCount[j].totalIssues
                } else if (totalIssuesCount[j].type === 31 && parseInt(issueTypes[i]) === 100 || totalIssuesCount[j].type === 32 && parseInt(issueTypes[i]) === 200 || totalIssuesCount[j].type === 33 && parseInt(issueTypes[i]) === 300 || totalIssuesCount[j].type === 34 && parseInt(issueTypes[i]) === 400) {
                    total = totalIssuesCount[j].totalIssues
                    break
                } else if (totalIssuesCount[j].type === 31 && issueTypes[i].charAt(0) === '1' || totalIssuesCount[j].type === 32 && issueTypes[i].charAt(0) === '2' || totalIssuesCount[j].type === 33 && issueTypes[i].charAt(0) === '3' || totalIssuesCount[j].type === 34 && issueTypes[i].charAt(0) === '4') {
                    for (const item of totalIssuesCount[j].issuesCounts) {
                        if (item.issueType === parseInt(issueTypes[i])) {
                            total = item.count
                            break
                        }
                    }
                }
            }

            for (let j = 0; j < resolvedIssuesCount.length; j++) {
                if (parseInt(issueTypes[i]) === 0) {
                    resolved += resolvedIssuesCount[j].totalIssues
                } else if (resolvedIssuesCount[j].type === 31 && parseInt(issueTypes[i]) === 100 || resolvedIssuesCount[j].type === 32 && parseInt(issueTypes[i]) === 200 || resolvedIssuesCount[j].type === 33 && parseInt(issueTypes[i]) === 300 || resolvedIssuesCount[j].type === 34 && parseInt(issueTypes[i]) === 400) {
                    resolved = resolvedIssuesCount[j].totalIssues
                    break
                } else if (resolvedIssuesCount[j].type === 31 && issueTypes[i].charAt(0) === '1' || resolvedIssuesCount[j].type === 32 && issueTypes[i].charAt(0) === '2' || resolvedIssuesCount[j].type === 33 && issueTypes[i].charAt(0) === '3' || resolvedIssuesCount[j].type === 34 && issueTypes[i].charAt(0) === '4') {
                    for (const item of resolvedIssuesCount[j].issuesCounts) {
                        if (item.issueType === parseInt(issueTypes[i])) {
                            resolved = item.count
                            break
                        }
                    }
                }
            }

            for (let j = 0; j < unresolvedIssuesCount.length; j++) {
                if (parseInt(issueTypes[i]) === 0) {
                    unresolved += unresolvedIssuesCount[j].totalIssues
                } else if (unresolvedIssuesCount[j].type === 31 && parseInt(issueTypes[i]) === 100 || unresolvedIssuesCount[j].type === 32 && parseInt(issueTypes[i]) === 200 || unresolvedIssuesCount[j].type === 33 && parseInt(issueTypes[i]) === 300 || unresolvedIssuesCount[j].type === 34 && parseInt(issueTypes[i]) === 400) {
                    unresolved = unresolvedIssuesCount[j].totalIssues
                    break
                } else if (unresolvedIssuesCount[j].type === 31 && issueTypes[i].charAt(0) === '1' || unresolvedIssuesCount[j].type === 32 && issueTypes[i].charAt(0) === '2' || unresolvedIssuesCount[j].type === 33 && issueTypes[i].charAt(0) === '3' || unresolvedIssuesCount[j].type === 34 && issueTypes[i].charAt(0) === '4') {
                    for (const item of unresolvedIssuesCount[j].issuesCounts) {
                        if (item.issueType === parseInt(issueTypes[i])) {
                            unresolved = item.count
                            break
                        }
                    }
                }
            }
            
            
            
            if (parseInt(issueTypes[i]) !== 110 && parseInt(issueTypes[i]) !== 210 && parseInt(issueTypes[i]) !== 250 && parseInt(issueTypes[i]) !== 340 && parseInt(issueTypes[i]) !== 360 && parseInt(issueTypes[i]) !== 369 && parseInt(issueTypes[i]) !== 380 && parseInt(issueTypes[i]) !== 410) {
                data.push({
                    id: parseInt(issueTypes[i]),
                    name: injectSpaces(issueTypes[issueTypes.length / 2 + i]),
                    category: issueCategory,
                    group: issueGroup,
                    issues: {
                        total,
                        resolved,
                        unresolved
                    }
                })
            }
            
        }
        // console.log(data)
        return (
            <div>
            <h3>{Assets.Issues} {Assets.List.toLowerCase()}</h3>
            <br/>
            <div className = "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix">

                <select
                    style = {{height: '24px'}}
                    className = "mr"
                    value = {this.state.selectedName !== '-1' ? this.getValue(data, 0) : this.state.selectedId}
                    onChange = {(e) => {
                        this.filterIssues(data, +e.target.value, undefined)
                        this.setState({selectedId: e.target.value, selectedName: '-1', selectedCategory: '-1'})
                        
                    }}
                >
                    {this.getIdOptions(data)}
                </select>
                
                
                <select
                    style = {{height: '24px'}}
                    className = "mr"
                    value = {this.state.selectedId !== '-1' ? this.getValue(data, 1) : this.state.selectedName}
                    onChange = {(e) => {
                        this.filterIssues(data, undefined, e.target.value)  
                        this.setState({selectedId: '-1', selectedName: e.target.value, selectedCategory: '-1'})
                    }}
                >
                    {this.getNameOptions(data, true)}
                </select>
                <input 
                    style = {{height: '22px', width: '150px', paddingLeft: '5px', paddingRight: '5px'}} 
                    placeholder = {Assets.Filter + Assets._by_ + Assets.ID} 
                    className = "mr" 
                    onChange = {(e) => {
                        let selectedId: string
                        if (e.target.value === '') {
                            selectedId = '-1'
                        } else {
                            selectedId = e.target.value
                        }
                        this.setState({selectedId, selectedName: '-1', selectedCategory: '-1'})
                        this.filterIssues(data, +selectedId, undefined) 
                    }} 
                    type = "number" 
                    value = {this.state.selectedId !== '-1' ? this.state.selectedId : ''}
                />
                {this.getAutocomplete(data)}
                <input 
                    style = {{height: '22px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                    placeholder = {Assets.Filter + Assets._by_ + Assets.Description.toLowerCase()} 
                    className = "mr" 
                    onChange = {(e) => {
                        let selectedName: string
                        if (e.target.value === '') {
                            selectedName = '-1'
                        } else {
                            selectedName = e.target.value
                        }
                        this.setState({selectedId: '-1', selectedName, selectedCategory: '-1'})
                        this.filterIssues(data, undefined, selectedName)  
                    }} 
                    list = "names"
                    type = "text" 
                    value = {this.state.selectedName !== '-1' ? this.state.selectedName : ''}
                />
                <select
                    style = {{height: '24px'}}
                    className = "mr"
                    value = {this.state.selectedCategory}
                    onChange = {(e) => {
                        this.filterIssues(data, undefined, undefined, e.target.value)
                        this.setState({selectedCategory: e.target.value, selectedId: '-1', selectedName: '-1'})
                        
                    }}
                >
                    {this.getCategoryOptions(data)}
                </select>
                {this.state.selectedCategory !== '-1' ?
                    <select
                    style = {{height: '24px'}}
                    className = "mr"
                    value = {this.state.selectedGroup}
                    onChange = {(e) => {
                        this.filterIssues(data, undefined, undefined, undefined, e.target.value)
                        this.setState({ selectedId: '-1', selectedName: '-1', selectedGroup: e.target.value})
                        
                    }}
                >
                    {this.getGroupOptions(data)}
                </select> : void 0
                }
                {/* <h5>{Assets.Filter}{Assets._by_}{Assets.category}{Assets._and_}{Assets.group}</h5> */}
            </div>
            <br/>
            
            <ReactTable
                data = {this.state.selectedIssues && this.state.selectedIssues !== [] && this.state.selectedIssues.length !== 0 ? this.state.selectedIssues : data}
                columns = {[
                    {
                        Header: Assets.Issues,
                        headerClassName: 'risk-default-light',
                        columns: [
                            {width: 70, headerClassName: 'th-left', className: 'btn-nopadding', Header: Assets.Issue + ' ' + Assets.ID, accessor: 'id', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                            {width: 200, headerClassName: 'th-left', className: 'btn-nopadding', Header: Assets.Issue + ' ' + Assets.Category.toLowerCase(), accessor: 'category', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                            {width: 200, headerClassName: 'th-left', className: 'btn-nopadding', Header: Assets.Issue + ' ' + Assets.group, accessor: 'group', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                            {headerClassName: 'th-left', className: 'btn-nopadding', Header: Assets.Issue + ' ' + Assets.Description.toLowerCase(), accessor: 'name', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                        ]
                    },
                    {
                        Header: Assets.Number + Assets._of_ + Assets.Issues.toLowerCase(),
                        headerClassName: 'risk-default',
                        columns: [  
                            {width: 70, headerClassName: 'th-center', className: 'btn-nopadding', Header: <span title = {Assets.AllIssues}><FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faTasks}/></span>, accessor: 'issues.total', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                            {width: 70, headerClassName: 'th-center Risk risk-Normal', className: 'btn-nopadding', Header: <span title = {Assets.ResolvedIssues}><FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faCheckCircle}/></span>, accessor: 'issues.resolved', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                            {width: 70, headerClassName: 'th-center Risk risk-Severe', className: 'btn-nopadding', Header: <span title = {Assets.UnresolvedIssues}><FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faExclamationCircle}/></span>, accessor: 'issues.unresolved', Cell: (row) => this.getBoldText(row.original.id, row.value)},
                        ]
                    }
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                pageSizeOptions = {[5, 10, 15, 20, 25, 50]}
                defaultPageSize = {15} 
                
            />
            
        </div>
        )
    }

}