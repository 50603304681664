import WS from './Socket2'
import * as Config from '../Logic/Config'
import { IsBuildMyChance} from 'src/Logic/Utils';
// import {Main} from '../Logic/Main'
/*
function getServerNotLocalhost(server: string): string {
    let host: string = window.location.host
    if(host.startsWith('localhost')) return server
    else {
        let serverDot = server.indexOf('.')
        let subdomain = server.substring(0, serverDot)
        let hostDot = host.indexOf('.')
        let domain = host.substring(hostDot + 1, host.length)
        return subdomain + '.' + domain
    }
}

function getWebsocketConnectionObject (server: string) : {host:string, port?: number, wss: boolean} {
    let wco: {host:string, port?: number, wss: boolean} = {host:'', wss: true}
    if (server.startsWith('localhost')) {
        wco = {
            host: server.split(':')[0],
            port: parseInt(server.split(':')[1]),
            wss: false
        }
    } else {
        wco = {
            host: getServerNotLocalhost(server),
            wss: true
        }
    }
    return wco
}
*/
/*
const stagingServer: string = Config.Build === Config.BUILD.STANLEYBET ? 'stb.gamingenius.net' : Config.Build === Config.BUILD.ODDYZZEY ? 'oddyzzey-stage.gamingenius.net' : IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'gamification-demo.gamingenius.net'
const productionServer: string = 'oddyzzey.gamingenius.net'
const Server: string = Config.STAGE ? stagingServer : productionServer
*/
// const Server: string = Config.gamesModuleServer

// console.log ('Server:' + Server)
class Empty {
    public socket: WS
    constructor() {
        // this.reConnect();
    }
    public reConnect(token: string) {
        /*
        if (IsBuildJackpotsCH() || IsBuildPasino()) {
            this.socket = new WS({host: Config.walletBackendServer + '?token=' + Config.keyGetJackpots, wss: true})
        } else 
        */
        if (IsBuildMyChance()) {
            this.socket = new WS({host: Config.environment === Config.Environment.PRODUCTION ? Config.amMyChanceProduction :  Config.amMyChanceStage, wss: Config.environment < Config.Environment.PET ? true : false})
        }
    }
}

const Socket2 = new Empty();
/*
if (IsBuildMyChance()) {
    Socket2 = new WS({host: Config.environment === Config.Environment.PRODUCTION ? Config.amMyChanceProduction :  Config.amMyChanceStage, wss: Config.environment < Config.Environment.PET ? true : false})
}
*/
export default Socket2
