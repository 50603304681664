import * as React from 'react'
import {getIssueFilters} from '../MainDashboard/Dashboard'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {Main} from '../../../Logic/Main'
import History from '../RiskComplianceStore/history'
import CSCallbackResolvePopup from '../RiskComplianceStore/callbackResolvePopup'

export function AccountStoreAllIssues(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    return (
    <>
        {Main.Instance.RiskManagementState.history ? <History/> : void 0}
        {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/> : void 0}
        {getIssueFilters()}
    </>)
}