import * as React from 'react'

export class Example extends React.Component
<{label: string, additionalClasses?: string, children?: any, mapStyle?: any, ch?: boolean}, {}> {

/* label:string,additionalClasses?:string,children:any,mapStyle?:any

	static propTypes = {
		additionalClasses: React.PropTypes.string,
		children: React.PropTypes.element.isRequired,
		label: React.PropTypes.string.isRequired,
		mapStyle: React.PropTypes.object
	};
*/
    public render() {
        return (
            <div className = "Example" style = {this.props.ch ? {position: 'absolute', left: '0px', top: '0px'} : {}}>
                <div 
                    className = {`Example-map ${this.props.additionalClasses}`}
                    style = {this.props.mapStyle}
                >
                     {React.Children.only(this.props.children)}
                </div>
            </div>
        )
    }
}