import * as React from 'react';
import {DatamapSwitzerlandStats} from './DatamapSwitzerlandStats';
import {Example} from './Example';
import * as d3geo from 'd3-geo'
import { Main } from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {Stat} from '../../../Logic/Reports/Reports'
import {Region} from '../../../Logic/Database/DatabaseData'
// import {numberWithCommas} from '../../../Logic/Utils'
// import {SwitzerlandCantonsData, SwitzerlandBiggestCities, GCBBaden, MapCity} from './SwitzerlandData'
import {densityPallette, switzerlandColor, switzerlandCantonsBorderColor/*, SwitzerlandCantonsColors*/} from './SwitzerlandResources'
// import {getColorByCanton} from '../MainDashboard/Legend'
export default class ZoomSwitzerlandStats extends React.Component<{regions: Region[], region: boolean, PlayersSelected: number[]},{}> {
    public setProjection(element) {
        const projection = d3geo.geoMercator()  // .geoEquirectangular()
            .center([8.21, 46.81]) // 12.6, 46.79 8.28, 46.82
            .rotate([0, 0]) // [4.4, 0]
            .scale(12000)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
        const path = d3geo.geoPath()
            .projection(projection);

        return { path, projection }
    }
/*
    getFillKey(canton: number): string {
        return canton > -1 ? getColorByCanton(canton) : switzerlandColor
    }
*/

    // ok current function just displays which cantos are selected. which is fine, no change should be seen here
    public getFills(): any {
        const fills = {}
        fills['defaultFill'] = switzerlandColor
        fills['WHITE'] = 'white'
        fills['TEXT'] = 'black'
        // fills['CITY'] = 'red'
        // console.log (densityPallette)
        // console.log (this.props.PlayersSelected)
        const stats: Array<{value: number, Value2: number, value2: string, region: string, country: string, name: string, c: string, Country: string}> = []
        for (let i: number = 0; i < this.props.regions.length; i++) {
            let value: number
            if (this.props.region === true && Main.Instance.PlayerInfo.regions[i] === false) {
                value = 0
            }
            if (Main.Instance.PlayerInfo.stat === Stat.Population) {
                if (value === 0 || this.props.regions[i].population === 0) {
                    fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = 'white'
                }
                stats.push ({Value2: 0, value2: '0', value: value === undefined ? this.props.regions[i].population : value, region: this.props.regions[i].code, name: this.props.regions[i].name, Country: this.props.regions[i].Country, country: this.props.regions[i].country, c: this.props.regions[i].c})
            } else if (Main.Instance.PlayerInfo.stat === Stat.PlayerRegistration) {
                if (value === 0 || this.props.PlayersSelected[i] === 0) {
                    fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = 'white'
                }
                stats.push ({Value2: 0, value2: '0',value: value === undefined ? this.props.PlayersSelected[i] : value, region: this.props.regions[i].code, name: this.props.regions[i].name, Country: this.props.regions[i].Country, country: this.props.regions[i].country, c: this.props.regions[i].c})
            } else if (Main.Instance.PlayerInfo.stat === Stat.PlayerRegistrationByPopulation) {
                if (value === 0 || this.props.PlayersSelected[i]/this.props.regions[i].population === 0) {
                    fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = 'white'
                }
                stats.push ({Value2: 0, value2: '0', value: value === undefined ? this.props.PlayersSelected[i]/this.props.regions[i].population : value, region: this.props.regions[i].code, name: this.props.regions[i].name, Country: this.props.regions[i].Country, country: this.props.regions[i].country, c: this.props.regions[i].c})
            }
        }
        // console.log ('dataRegions ' + dataRegions)
        // console.log(this.props.regions)
        // console.log (stats)
        const OrderedStats: Array<{value: number, Value2: number, value2: string, region: string, country: string, name: string, c: string, Country: string}> = stats.sort((a, b) => a.value - b.value)
        const orderedStats: Array<{value: number, Value2: number, value2: string, region: string, country: string, name: string, c: string, Country: string}> = []
        let sum: number = 0
        for (const item of OrderedStats) {
            if (item.value !== 0) {
                orderedStats.push(item)
                sum += item.value
            }
        }
        if (sum > 0) {
            for (const item of orderedStats) {
                item.value2 = (item.value / sum * 100).toFixed(2) + '%'
                item.Value2 = parseFloat((item.value / sum * 100).toFixed(2))
            }
        }
        if (JSON.stringify(orderedStats) !== JSON.stringify(Main.Instance.DatabaseDataState.orderedStats)) {
            // console.log ('5555555555555555555555555555')
            Logic.Type.New (Main.Instance.DatabaseDataState, {orderedStats})
        }            
        // console.log (orderedStats)
        const classStats: Array<{value: number, region: string, country: string, name: string, c: string, Country: string}> = []
        const classStatsLow: number[] = []
        let classStatsHigh: number[] = []
        const classStatsTitle: string[] = []
        const ratio: number = orderedStats.length / densityPallette.length 
        for (let i: number = 0; i < orderedStats.length; i++) {
            classStats.push ({value: Math.floor(i / ratio), region: orderedStats[i].region, name: orderedStats[i].name, country: orderedStats[i].country, Country: orderedStats[i].Country, c: orderedStats[i].c})
        }
        if (JSON.stringify(classStats) !== JSON.stringify(Main.Instance.DatabaseDataState.classStats)) {
            // console.log ('1111111111111111111111111111111111')
            // console.log (JSON.stringify(classStats))
            // console.log (JSON.stringify(Main.Instance.DatabaseDataState.classStats))
            Logic.Type.New (Main.Instance.DatabaseDataState, {classStats})
        }         
        for (let i: number = 0; i < densityPallette.length; i++) {
            classStatsTitle.push('')
            for (let j: number = 0; j < classStats.length; j++) {
                if (classStats[j].value !== i) { continue }
                if (classStatsTitle[i].length > 0) { classStatsTitle[i] += ', '}
                classStatsTitle[i] += classStats[j].country ? classStats[j].name + ' (' + classStats[j].country + '-' + classStats[j].region + ')' : classStats[j].name + ' (' + classStats[j].region + ')'
            }
        }

        if (JSON.stringify(classStatsTitle) !== JSON.stringify(Main.Instance.DatabaseDataState.classStatsTitle)) {
            // console.log ('2222222222222222222222222222222222222222')
            Logic.Type.New (Main.Instance.DatabaseDataState, {classStatsTitle})
        }        
        // classStatsLow
        let current: number = -1
        for (let i: number = 0; i < classStats.length; i++) {
            if (current !== classStats[i].value) {
                classStatsLow.push(orderedStats[i].value)
                current = classStats[i].value
            }
        }
        if (JSON.stringify(classStatsLow) !== JSON.stringify(Main.Instance.DatabaseDataState.classStatsLow)) {
            // console.log ('3333333333333333333333333333333333')
            Logic.Type.New (Main.Instance.DatabaseDataState, {classStatsLow})
        }
        
        // classStatsHigh
        current = Number.MAX_SAFE_INTEGER
        for (let i: number = classStats.length - 1; i >= 0; i--) {
            if (current !== classStats[i].value) {
                classStatsHigh.push(orderedStats[i].value)
                current = classStats[i].value
            }
        }
        classStatsHigh = classStatsHigh.reverse()
        if (JSON.stringify(classStatsHigh) !== JSON.stringify(Main.Instance.DatabaseDataState.classStatsHigh)) {
            // console.log ('44444444444444444444444444444444444444444')
            Logic.Type.New (Main.Instance.DatabaseDataState, {classStatsHigh})
        }
        // console.log (JSON.stringify(classStats))
        // console.log (JSON.stringify(classStatsLow))
        // console.log (JSON.stringify(classStatsHigh))
        for (let i: number = 0; i < classStats.length; i++) {
            fills[(classStats[i].country ? classStats[i].country.toUpperCase() : '') + '.' + (classStats[i].region ? classStats[i].region.toUpperCase() : '')] = densityPallette[classStats[i].value]
        }
        // console.log (fills)
/*        
        for (let i: number = 0; i < this.props.regions.length; i++) {
            if (stats[i].value > 0) {
                let position: number = -1
                for (let j: number = 0; j < orderedStats.length; j++) {
                    if (stats[i] === orderedStats[j]) {
                        position = classStats[j]
                        break
                    }                    
                }
                console.log (this.props.regions[i].code + ' ' + position)
                if (position > -1) {
                    fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = densityPallette[position]
                }
            }
        }
*/
        // console.log(this.props.PlayersSelected)
/*
        if (this.props.region) {
            for (let i: number = 0; i < this.props.regions.length; i++) {
                fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = Main.Instance.PlayerInfo.regions[i] ? switzerlandColor : 'white' // this.getFillKey(item.id - 1)
            }
        }
*/
        return fills
    }

    public getCantonAbbrev(name: any): string {
        for (const item of this.props.regions) {
            if (item.name === name) { return item.country ? item.country + '-' + item.code : item.code }
        }
        return '?'
    }

    public getCanton(name: any): string {
        return name + ' (' + this.getCantonAbbrev(name) +')'        
    }

    public render() {
/*
        const Bubbles: MapCity[] = Object.assign ([], SwitzerlandBiggestCities)
        Bubbles.push (GCBBaden)

        const Data: any = {}
        for (const item of SwitzerlandCantonsData) {
            Data[item.code] = ({fillKey: item.code})
        }
*/
        const Data: object = {}
        for (const item of this.props.regions) {
            Data[(item.country ? item.country.toUpperCase() : '') + '.' + (item.code ? item.code.toUpperCase() : '')] = {fillKey: (item.country ? item.country.toUpperCase() : '') + '.' + (item.code ? item.code.toUpperCase() : '')}
        }

        return (
            <Example label = "Switzerland">
                <DatamapSwitzerlandStats
                    scope = "che" // cantons
                    setProjection = {this.setProjection}
                    geographyConfig= { {
                        // data: Data,
                        // dataUrl: './switzerland.json',
                        // dataJson: Data,
                        popupOnHover: true,
                        hideAntarctica: true,
                        highlightOnHover: false,
                        borderColor: switzerlandCantonsBorderColor,                   
                        borderWidth: 0.5,
                        // highlightFillColor: () => switzerlandColor,
                        // highlightFillColor: Main.Instance.DatabaseDataState.selected,
                        // this function should just return a string

                        popupTemplate: (geography, data) => {
/*
                            let area, population: number
                            for (const item of SwitzerlandCantonsData) {
                                if (geography.id === item.code) {
                                    area = item.area
                                    population = item.population
                                    break
                                }
                            }
                            
*/
                            // const name = 
                            return (
                                `<div class = "geography-properties-box">
                                    <div class = "geography-properties-title">` + this.getCanton(geography.properties.name) + `</div>
                                </div>`)
/*                                
                            return (
                                `<div class = "geography-properties-box">
                                    <div class = "geography-properties-title">` + geography.properties.name + `</div>
                                    <div> Population: ` + (population ? numberWithCommas(population) : 0) + `</div>
                                    <div> Area: ` + (area ? numberWithCommas(area) : 0) + ` km<sup>2</sup></div>
                                    <div> Density: ` + (population && area ? numberWithCommas((population/area).toFixed(1)) : 'unknown') + `/km<sup>2</sup></div>
                                </div>`)
*/
                        },
                    }}

                    data = {Data}
                    fills = {this.getFills()}
                    // bubbles = {Bubbles}
                    bubbleOptions={{borderWidth: 0}}
/*                    
                    popupTemplate: (geo, data) =>
                    `<div class="hoverinfo">Bubble for ${data.name}`
*/
                />
            </Example>
        )
    }
}