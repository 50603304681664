import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import ReactTable from 'react-table'
import { Main } from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileCsv} from '@fortawesome/free-solid-svg-icons'
import {ICashbackMondayOptins} from '../../../Logic/Database/DatabaseData'


interface State {
    country: string
    level: string
    filtered: []

}

export class CashbackMondayOptins extends React.Component <{}, State> {
    private reactTable: React.RefObject<HTMLInputElement>;
    constructor(props:any) {
        super(props)
        this.reactTable = React.createRef();
        this.state = {
            country: '',
            level: '',
            filtered: [],

        }
        DbRoutes.wsReinitialize()
    }
    
    public getCountryOptions(): JSX.Element[] {
        const countryOptions: JSX.Element[] = []
        countryOptions.push(<option key = "options" value = "">{Assets.AnyCountry}</option>)
        const keys: any[] = Object.keys(Assets_.myChanceCountries)
        for (let i: number = 0; i < keys.length; i++) {
            countryOptions.push(<option key = {Assets_.myChanceCountries[keys[i]].code} value = {Assets_.myChanceCountries[keys[i]].code.toUpperCase()}>{keys[i]}</option>)
        }
        return countryOptions
    }

    public getLevelOptions(): JSX.Element[] {
        const name: string[] = []

        for (const item of Main.Instance.DatabaseDataState.levelsStream) {
            name.push (item.name) 
        }
        name.sort()
        const levelOptions: JSX.Element[] = []
        levelOptions.push(<option key = "options" value = "">{Assets.AnyLevel}</option>)
        for (let i: number = 0; i < name.length; i++) {
            for (const item of Main.Instance.DatabaseDataState.levelsStream) {
                if (item.name === name[i]) {
                    levelOptions.push(<option key = {item.hierarchy} value = {item.hierarchy}>{name[i]}</option>)
                    break
                }
            }
        }
        return levelOptions
    }

    public tableFilters (value, accessor) {
        const filtered = Object.assign ([], this.state.filtered)  
        let insertNewFilter: boolean = true
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if(filter.id === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1)
                    } else {
                        filter.value = value
                    }
                    insertNewFilter = false
                }
            })
        }
        if (insertNewFilter) {
            filtered.push({id: accessor, value})
        }
        this.setState({filtered})
    }

    public downloadCSVList() {

        const array: ICashbackMondayOptins[] = Object.assign([], this.reactTable)
        let s: string = Assets.PlayerId + ',' + Assets.Player_ + ' ' + Assets.Level.toLowerCase() + ',' + Assets.Nationality + ',' + Assets.OptIn + ' ' + Assets.Date.toLowerCase() + ',' + Assets.OptIn + ' ' + Assets.Time.toLowerCase() + '\n'
        for (let i: number = 0; i < array.length; i++) {
            s += array[i].playerId + ',' + array[i].level + ',' + array[i].nationality + ',' + array[i].optinDate.substring(0,10) + ',' + array[i].optinDate.substring(11,19)
            if (i < array.length - 1) {
                s += '\n'
            }
        }
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.CashbackMondayOptins + Assets_._csv
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }

    public render() {

        return (
            <div>
                <h3 className = "pr">
                    {Assets.CashbackMondayOptins + (Main.Instance.DatabaseDataState.CashbackMondayOptins ? ' [' + Main.Instance.DatabaseDataState.CashbackMondayOptins.length + ']' : '')}
                    <select
                        style = {{width: 'auto', position: 'absolute', top: '-5px', right: '35px'}}
                        className = "form-control"
                        onChange = {(e) => {
                            this.tableFilters(e.target.value, 'nationality');
                            this.setState({country: e.target.value})
                        }}
                        value = {this.state.country}
                    >
                        {this.getCountryOptions()}
                    </select>
                    <select
                        style = {{width: 'auto', position: 'absolute', top: '-5px', right: '160px'}}
                        className = "form-control"
                        onChange = {(e) => {
                            this.tableFilters(e.target.value, 'level');
                            this.setState({level: e.target.value})
                        }}
                        value = {this.state.level}
                    >
                        {getLevelOptions()}
                    </select>
                    <button
                        onClick = {() => this.downloadCSVList()}
                        title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.List.toLowerCase() + ': ' + (Main.Instance.DatabaseDataState.CashbackMondayOptins ? Main.Instance.DatabaseDataState.CashbackMondayOptins.length + ' ' : '') + Assets.OptIn.toLowerCase() + ' ' + Assets.Players.toLowerCase()}
                        style = {{padding: 0, background: 'transparent', border: '0px', fontSize: '27px', position: 'absolute', top: '-6px', right: '-6px'}}
                        className = "btn btn-default btn-sm "
                    >
                        <FontAwesomeIcon icon = {faFileCsv}/>
                    </button>
                </h3>
                <br/>
                <div className = "row admin-users-container clearfix mt ">
                    {Main.Instance.DatabaseDataState.CashbackMondayOptins ?
                    <ReactTable
                        data={Main.Instance.DatabaseDataState.CashbackMondayOptins}
                        ref = {(r) => {
                            if (r) {
                                this.reactTable = r.getResolvedState().sortedData   
                            }
                        }} 
                        filtered = {this.state.filtered}
                        onFilteredChange = {(filtered, column, value) => {
                            this.tableFilters(value, column.accessor)
                        }}
                        columns={[
                            {headerClassName: 'th-left risk-default-light', Header: Assets.PlayerId, accessor: 'playerId'}, 
                            {width: 100, headerClassName: 'th-left risk-default', Header: Assets.Level, accessor: 'level'}, 
                            {width: 250, headerClassName: 'th-left risk-default-light', Header: Assets.Nationality, accessor: 'nationality', Cell: row => getNationality(row.value)}, 
                            {width: 60, headerClassName: 'th-left risk-default', Header: Assets.Flag, accessor: 'nationality', Cell: row => getFlag(row.value)}, 
                            {width: 250, headerClassName: 'th-left risk-default-light', Header: Assets.OptIn + ' ' + Assets.Date, accessor: 'optinDate', Cell: (row) => row.value ? row.value.substring(0,10) : '-'}, 
                            {width: 250, headerClassName: 'th-left risk-default', Header: Assets.OptIn + ' ' + Assets.Time, accessor: 'optinDate', Cell: (row) => row.value ? row.value.substring(11,19) : '-'}, 
                        ]}
                        defaultPageSize={10}
                        showPaginationBottom={false}
                        showPaginationTop={true}
                    /> : void 0}
                </div>
            </div>
        ) 
    }
}

export function getNationality(code: string): string {
    // Assets_.myChanceCountries
    const keys: any[] = Object.keys(Assets_.myChanceCountries)
    for (let i: number = 0; i < keys.length; i++) {
        if (Assets_.myChanceCountries[keys[i]] && Assets_.myChanceCountries[keys[i]].code && code && Assets_.myChanceCountries[keys[i]].code.toLowerCase() === code.toLowerCase()) {
            return keys[i]
        }
    }

    return ''
}

export function getFlag(code: string): JSX.Element {

    let src: string
    if (Main.Instance.DatabaseDataState.FlagsStreamAll) {
        for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
            if (code && item.code && item.code.toLowerCase() === code.toLowerCase() || code && item.code2 && item.code2.toLowerCase() === code.toLowerCase()) {
                // console.log (item)
                src = item.image
                break
            }
        }
    }

    return src ? <img src = {src}/> : <span>-</span>
}

export function getLevelOptions(): JSX.Element[] {
    const name: string[] = []

    for (const item of Main.Instance.DatabaseDataState.levelsStream) {
        name.push (item.name) 
    }
    name.sort()
    const levelOptions: JSX.Element[] = []
    levelOptions.push(<option key = "options" value = "">{Assets.AnyLevel}</option>)
    for (let i: number = 0; i < name.length; i++) {
        for (const item of Main.Instance.DatabaseDataState.levelsStream) {
            if (item.name === name[i]) {
                levelOptions.push(<option key = {item.hierarchy} value = {item.hierarchy}>{name[i]}</option>)
                break
            }
        }
    }
    return levelOptions
}