import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
import * as Config from '../../../Logic/Config'
import {SelectedPaymentSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {injectSpaces, isUserAdmin, isUserAccounting} from '../../../Logic/Utils'
import {faSave/*, faPlus, faTrashAlt*/} from '@fortawesome/free-solid-svg-icons'
import {Custom} from '../../../Logic/Database/DatabaseData'

function selectNavigation(selectedPaymentSetting: SelectedPaymentSetting) {
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedPaymentSetting})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
        for (let i: number = 0; i < Object.keys(SelectedPaymentSetting).length / 2; i++) {
            navigation.push(
                <button
                    // title = {i === SelectedGamesSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGamesSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                    // disabled = {i === SelectedGamesSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGamesSetting.ResponsibleGaming && !isUserRGSpecialist()}
                    onClick = {() => selectNavigation(i)}
                    type = "button"
                    key = {i.toString()}
                    style = {{
                        marginRight: i === Object.keys(SelectedPaymentSetting).length / 2 - 1 ? '0px' : '',
                        display: 'block',
                        float: 'left',
                    }}
                    className = {i === Main.Instance.RiskManagementState.selectedPaymentSetting ? 'btn btn-primary btn-sm pr' : 'btn btn-default btn-sm pr'}
                >
                    {/*i === SelectedGamesSetting.GameLabelsStrapiGameStore ?
                        <>{Assets_.strapi(Main.Instance.RiskManagementState.selectedGamesSetting === SelectedGamesSetting.GameLabelsStrapiGameStore ? '#fff' : '#333', 19,  5, 9)}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></> :
                        <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedGamesSettingIcons[i]}/>
                    */}
                    <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedPaymentSettingIcons[i]}/>
                    {injectSpaces(SelectedPaymentSetting[i])}
                </button>
            )
        }
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}

function changeText(item: string, value: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.paymentInformation[item] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getAccount(): JSX.Element {
    const fields: string[] = [
        'beneficiary',
        'abbreviation',
        'address',
        'streetNumber',
        'postalCode',
        'city',
        'country',
        'countryAbbreviation',
        'iban',
        'bic'
    ]

    const inputs: JSX.Element[] = []
    for (const item of fields) {
        inputs.push(
            <div className = "clearfix mb5" key = {item}>
                <div style = {{width: '150px', fontWeight: 700}} className = "fl">{item}</div>
                <input disabled = {!isUserAdmin() && !isUserAccounting()} className = "dbl fl" onChange = {(e) => changeText(item, e.target.value)} style = {{width: '200px', height: '24px'}} type = "text" value = {Main.Instance.DatabaseDataState.custom.paymentInformation[item]}/>
            </div>)
    }

    return (<div>{inputs}</div>)
}

export function PaymentSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    if (Main.Instance.DatabaseDataState.category_ === undefined && Main.Instance.DatabaseDataState.gamesListCategories !== undefined) {
        const category_: string[] = []
        for (const {} of Main.Instance.DatabaseDataState.gamesListCategories) {
            category_.push(undefined)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
     }
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.GenericSettings}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {Main.Instance.RiskManagementState.selectedPaymentSetting === SelectedPaymentSetting.Account ?
                        <>
                            <div>
                                {getAccount()}
                            </div>
                        </> : void 0}
                    </div>
                </div>
            </div> : void 0}
        </div>
    )
}