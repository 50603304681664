import * as moment from 'moment-timezone'
// DIFFERENT ENVIRONMENTS   0        1       2      3         4         5
export enum Environment { LOCAL , DEVEDGE , DEV , STAGE , PRODUCTION , PET}
export enum ENVIRONMENT {'LOCAL','DEV-EDGE','DEV','STAGE','PRODUCTION','PET'}
export enum ENVIRONMENT_ {'LOC' , 'EDGE'   ,'DEV','STAGE', '', 'PET'}
export const ENVIRONMENT__: string[] = ['LOCAL' , 'DEV-EDGE'   ,'DEVELOPMENT','STAGE', 'PRODUCTION', 'PET']
export const ENVIRONMENT_PRE: string[] = ['', 'devedge-', 'dev-', 'stg-', '', ''] // dev-edge-
export const ENVIRONMENT_SHOW: boolean = false
// on master we need to push ALWAYS JACKPOTSCH DEVEDGE
export const environment: Environment = parseInt(process.env.ENV) || Environment.DEVEDGE
const prefix = 'https://'
// export const oddsServerWss: boolean = false

const oddsServer: string = 'localhost:9090'
const OddsServer: string = 'http://' + oddsServer 
// const oddsServer: string = 'scraper.gamingenius.net'
// const OddsServer: string = prefix + oddsServer
const oddsServer2: string = 'localhost:9091'
const OddsServer2: string = 'http://' + oddsServer2
// const oddsServer2: string = 'scraper.gamingenius.net'
// const OddsServer2: string = prefix + oddsServer

export const oddsServerMatches: string = OddsServer + '/matches'
export const oddsServerBookmakers: string = OddsServer + '/bookmakers'
export const oddsServerOdds: string = OddsServer + '/odds'
export const oddsServerBet: string = OddsServer + '/bet'
export const oddsServerSport: string = OddsServer + '/sport'
export const oddsServerLeague: string = OddsServer + '/league'
export const oddsServerResults: string = OddsServer + '/results'
export const oddsServerCountries: string = OddsServer + '/countries'
export const oddsServerCountriesTypes: string = OddsServer + '/countriestypes'

export const oddsServerSettings: string = OddsServer2 + '/settings'

export const keyGetJackpots: string = 'xw6pWeuaJtMYLpagk73feDNj'
export const keyGetOdds: string = 'JwwaysZXaEzUPtPjepPzerCk'

// DIFFERENT BUILDS
//                                  0          1            2           3           4          5(GG)      6(GG)         7              8           9.........10........11
export const builds: string[] = ['gamanza', 'scandibet', 'oddyzzey', 'trekronor', 'bettoday', 'frontend', 'backend', 'jackpotsch', 'stanleybet', 'mychance', 'odds', 'pasino']
export enum BUILD {
    DEFAULT = 0,
    SCANDIBET,
    ODDYZZEY,
    TREKRONOR,
    BETTODAY,
    FRONTEND,
    BACKEND,
    JACKPOTSCH,
    STANLEYBET,
    MYCHANCE,
    ODDS,
    PASINO
}
export const Build: BUILD = parseInt(process.env.BUILD, 10) || BUILD.DEFAULT

const environmentAuthServer: string[] = Build === BUILD.PASINO ?
[
    '',
    'auth.sandbox.gamingeniusstage.com',
    '',
    'auth.ps.gamingeniusstage.com',
    'auth.ps.gamingenius.com',
    ''
]
:
[
    'auth-new.gamingenius.net', // LOCAL
    'auth.jp.gamingeniusstage.com', // DEVEDGE auth-new.gamingenius.net
    'jackpotsauth.gamingenius.net', // DEV
    'authentication-stg.gamingenius.net', // STAGE
    'jp-auth.gamingenius.com', // PRODUCTION
    'auth-new.gamingenius.net', // PET
]

const environmentUploaderServer: string[] = Build === BUILD.PASINO ?
[
    '',
    'file.sandbox.gamingeniusstage.com',
    '',
    'file.ps.gamingeniusstage.com',
    'file.ps.gamingenius.com',
    ''
]
:
[
    'upload-new.gamingenius.net', // LOCAL
    'file.jp.gamingeniusstage.com', // DEVEDGE upload-new.gamingenius.net
    'upload-new.gamingenius.net', // DEV
    'fupl-stg.gamingenius.net', // STAGE 'doc-stg.gamingenius.net'
    'jp-uploads.gamingenius.com', // PRODUCTION
    'upload-new.gamingenius.net', // PET
]

export const environmentGameStoreServer: string = 'gamestore.gamingenius.net'
// export const environmentGameStoreServer: string = 'localhost:9091'
export const environmentGameStoreServerWss: boolean = true
/*
const environmentGameStoreServer: string[] = [
    'gamestore.gamingenius.net', // LOCAL
    'gamestore.gamingenius.net', // DEVEDGE
    'gamestore.gamingenius.net', // DEV
    'gamestore.gamingenius.net', // STAGE
    'gamestore.gamingenius.net', // PRODUCTION
    'gamestore.gamingenius.net', // PET
]
*/
const environmentStrapiCMS: string[] = Build === BUILD.PASINO ?
[
    'strapi.ps.gamingenius.com',
    'strapi.ps.gamingenius.com',
    'strapi.ps.gamingenius.com',
    'strapi.ps.gamingenius.com',
    'strapi.ps.gamingenius.com',
    'strapi.ps.gamingenius.com'
] :
[
    'jp-strapi.gamingenius.com', // LOCAL
    'jp-strapi.gamingenius.com', // DEVEDGE
    'jp-strapi.gamingenius.com', // DEV
    'jp-strapi.gamingenius.com', // STAGE
    'jp-strapi.gamingenius.com', // PRODUCTION // strapi-jp.gamingenius.com
    'jp-strapi.gamingenius.com'  // PET
]

const environmentAdminModuleServer: string[] = Build === BUILD.PASINO ?
[
    '',
    'admin.sandbox.gamingeniusstage.com',
    '',
    'admin.ps.gamingeniusstage.com',
    'admin.ps.gamingenius.com',
    ''
]
:
[
    'b5b7bd53.ngrok.io', // LOCAL
    'admin.jp.gamingeniusstage.com', // DEVEDGE  admin-server-new.gamingenius.net ??? d72bbcfa.ngrok.io
    'jackpotsadmin.gamingenius.net', // DEV
    'admin-module-stg.gamingenius.net', // STAGE 'admin-stg.gamingenius.net'
    'jp-admin.gamingenius.com', // PRODUCTION 'jp-admin.gamingenius.com' 'f0174922.ngrok.io' 627e6418.ngrok.io
    'localhost:9090' // PET
]
const environmentDocumentsServer: string[] = Build === BUILD.PASINO ?
[
    '',
    'doc.sandbox.gamingeniusstage.com',
    '',
    'doc.ps.gamingeniusstage.com',
    'doc.ps.gamingenius.com',
    ''
]
:
[
    'fileupload-stg.gamingenius.net', // LOCAL
    'doc.jp.gamingeniusstage.com', // DEVEDGE fileupload-new.gamingenius.net
    'jackpotsupload.gamingenius.net', // DEV
    'doc-stg.gamingenius.net', // STAGE fileupload-new.gamingenius.net
    'jp-doc.gamingenius.com', // PRODUCTION
    'jackpotsupload.gamingenius.net'  // PET
]
/*
export const documentsDirectory: string[] = [
    '', // LOCAL
    'jp-uploads', // DEVEDGE
    '', // DEV
    '', // STAGE
    'jp-file-uploads', // PRODUCTION
    ''  // PET
]
*/
// websocket
export const adminModuleServer: string = environmentAdminModuleServer[environment]
// export const gamesModuleServer: string = environmentGameStoreServer[environment]

// rest
const authServer: string = prefix + environmentAuthServer[environment]
const strapi: string = prefix + environmentStrapiCMS[environment]
const documentsServer: string = prefix + environmentDocumentsServer[environment]

// auth routes
export const aEmailCheck = authServer + '/email-check'
export const aUsernameCheck = authServer + '/username-check'

// strapiJackpots routes
export const strapiCasinoGames = strapi + '/casinogames'
export const strapiGameTags = strapi + '/gametags'
export const strapiGameCategories = strapi + '/gamecategories'

// strapiMychance

const strapiMyChance = prefix + 'mc-strapi.gamingenius.com'
// export const strapiMyChancePass = 'X09xhT8BNEy1DBqA'
export const strapiMyChanceAuth = strapiMyChance + '/auth/local'
export const strapiMyChanceShopItems = strapiMyChance + '/shopitems'
// export const strapiMyChanceCasinoGames = strapiMyChance + '/casinogames'

export const Languages: string[] = ['English', 'Deutsch']

export enum LANGUAGE {
    ENGLISH = 0,
    DEUTSCH
}

export const Language: LANGUAGE = LANGUAGE.ENGLISH
/*
type LanguageAbbrevEN = 'EN'
type LanguageAbbrevDE = 'DE'
export const LanguageAbbrev: LanguageAbbrevEN|LanguageAbbrevDE = 'EN'
*/

// uploader
export const uFiles: string = prefix + environmentUploaderServer[environment] + '/files'
export const uUpload: string = prefix + environmentUploaderServer[environment] + '/upload'
export const uDownload: string = prefix + environmentUploaderServer[environment] + '/download'

// documents server
export const dsDownloadDocument: string = documentsServer + '/download-document'
export const dsDownloadUtility: string = documentsServer + '/download-utility'
// export const dsDocumentIds: string = documentsServer + '/document-ids'
// export const dsUtilityIds: string = documentsServer + '/utility-ids'
// export const dsUploadDocument: string = '/upload-document'
// export const dsUploadUtility: string = '/upload-utility'

export const timeout: number = 3600 // 3600 seconds as default, can be less seconds for testing
export const timeoutRed: number = 60 // when 60 seconds comes, the color goes from orange to red
export const timeoutMessage: number = 10 // number of seconds that a message is on the screen 
// export const STAGE: boolean = false // production
// to correctly specify build it has to be !!!actual number!!! from above here
// export const STAGE: boolean = true

export const stage_: string = 'stage-'
// export const dev_: string = 'dev-'
export const stage: string = 'admin-stg-'
export const dev: string = 'admin-dev-'
export const dev_edge: string = 'admin-devedge-' // 'admin-dev-edge-'

// API KEYS: SCANDIBET
// export const keyName: string = 'Scandibet';export const keyEntity: string = 'scandibet';export const keyModify: string = 'qXjGJWLZXFGnBFfGkupBGKZr';export const keyGet: string = 'FUy6zNPXjnCusEkLEP9s39LW'

// API KEYS: ODDYZZEY
// export const keyName: string = 'Oddyzzey';export const keyEntity: string = 'oddyzzey';export const keyModify: string = 'ayr6ATVGrFW5fft6nkuwNMye';export const keyGet: string = 't6zvDANHJpgmkBApKSFfpRgW'

// API KEYS: BET TODAY
// export const keyName: string = 'Bet Today';export const keyEntity: string = 'bet today';export const keyModify: string = 'EGh5WQpqBxNHzB4pwDaPfDLr';export const keyGet: string = 'sdQQhYG2e2FYJDTAMzK6TWkf'

// API KEYS: STANLEYBET
// export const keyName: string = 'Stanleybet';export const keyEntity: string = 'stanleybet';export const keyModify: string = 'f88rBDmebBY5D58xMqU9YJ46';export const keyGet: string = 'uqazcN6dBb2Q4QL8gyGHehLz'

// API KEYS: DEFAULT, FRONTEND, BACKEND (GAMINGENIUS)
// export const keyName: string = 'GaminGenius';export const keyEntity: string = 'gamingenius';export const keyModify: string = 'EFCtvfyNtHBeBpRwxKDDRZUR';export const keyGet: string = 'KrKpvwRMZ22tatDsKXXCR9nj'

// API KEYS: JACKPOTSCH
// export const keyName: string = 'Jackpots.ch';export const keyEntity: string = 'jackpots.ch';export const keyModify: string = '27nUKsHDCgbASRsSs5khxpt6';export const keyGet: string = 'xw6pWeuaJtMYLpagk73feDNj'

// API KEYS: MYCHANCE
// export const keyName: string = 'MyChance';export const keyEntity: string = 'myChance';export const keyModify: string = 'SyJc2yR3k4nVvkjULPD6y7Vw';export const keyGet: string = 'TAq2peZpJN76pNU4AbJVxymf'

// development
// export const Server = 'gamanza.com'
// staging
export const Server = 'gamingenius.net'
// production
// export const Server = 'gamingenius.com'
// dynamic
// export const cloudflareSecret: string = 'DhrAW6LG4dPNJrfR0DTQ'

export const server: string = getServer(Server)

function getServer(SERVER: string): string {
    const host: string = window.location.host
    // x.gamanza.com
    if (host.startsWith('localhost')) {return SERVER}
    return host.substring(host.indexOf('.') + 1, host.length)
}

// export const prefixWallet: string = 'ext-wallet'
export const prefixAdminServer: string = 'as'
export const prefixLottoServer: string = 'lotto-server'
export const prefixLotteryServer: string = 'lottery'
export const prefixPromostore: string = 'promostore'
export const prefixTourDeBetServer = 'tour-de-bet-server-new'

export const adminServer: string = prefix + prefixAdminServer + '.' + server
// export const adminServer: string = 'http://localhost:5544'

// ADMINLOG CONSTANTS
// TYPES
export const asAdminLogLogin: string = 'LOGIN'
export const asAdminLogLogout: string = 'LOGOUT'
// actions
export const asAdminLogView: string = 'VIEW'
export const asAdminLogDelete: string = 'DELETE'
export const asAdminLogEdit: string = 'EDIT'
export const asAdminLogSaveEdit: string = 'SAVE/EDIT'
export const asAdminLogSaveInsert: string = 'SAVE/INSERT'
export const asAdminLogChangeData: string = 'CHANGE-DATA'

// SUBTYPES
export const asAdminLogUserSettings: string = 'USER SETTINGS'
export const asAdminLogSystemSettings: string = 'SYSTEM SETTINGS'

export const asAdminLogPlayerCard: string = 'PLAYER CARD'
export const asAdminLogAccountStatus: string = 'ACCOUNT STATUS'
export const asAdminLogLogoutInactivity: string = 'INACTIVITY'
export const asAdminLogLogoutOrdinary: string = 'ORDINARY'
export const asAdminLogNoPassword = 'NO PASSWORD'
export const asAdminLogWithPassword = 'WITH PASSWORD'

// GAMES MODULE
// central
// export const gamesModule: string = '35.197.193.0'

// export const gGetGames: string = 'get-games'// : getGames
// export const gGetGame: string = 'get-game'// : getGame,
// export const gUpdateGame: string = 'update-game'// : updateGame,
// export const gDeleteGame: string = 'delete-game'// : deleteGame,
// export const gInsertGame: string = 'insert-game'// : insertGame,


// export const gGetGamesData: string = 'get-games-data'// : getGamesData,
// export const gGetGameData: string = 'get-game-data'// : getGameData,
// export const gUpdateGameData: string = 'update-game-data'// : updateGameData,
// export const gDeleteGameData: string = 'delete-game-data'// : deleteGameData,
// export const gInsertGameData: string = 'insert-game-data'// : insertGameData,

// game providers

// export const gGetGameProviders: string = 'get-game-providers'// : getGameProviders,
// export const gGetGameProvider: string = 'get-game-provider'// : getGameProvider,
// export const gUpdateGameProvider: string = 'update-game-provider'// : updateGameProvider,
// export const gDeleteGameProvider: string = 'delete-game-provider'// : deleteGameProvider,
// export const gInsertGameProvider: string = 'insert-game-provider'// : insertGameProvider,

// rgs game suppliers
// export const gGetGameSuppliers: string = 'get-game-suppliers' // getGameSuppliers
// export const gGetGameSupplier: string = 'get-game-supplier' // getGameSupplier
// export const gInsertGameSuppliers: string = 'insert-game-suppliers' // insertGameSuppliers
// export const gUpdateGameSupplier: string = 'update-game-supplier' // updateGameSupplier
// export const gDeleteGameSupplier: string = 'delete-game-supplier' // deleteGameSupplier

// labels
// export const gGetLabels: string = 'get-labels' // getLabels
// export const gGetLabel: string = 'get-label' // getLabel
export const gInsertLabels: string = 'insert-labels' // insertLabels
// export const gUpdateLabel: string = 'update-label' // updateLabel
export const gDeleteLabel: string = 'delete-label' // deleteLabel

// export const gGetCategories: string = 'get-categories'// getCategories,
// export const gGetCategory: string = 'get-category'// getCategory,
export const gInsertCategories: string = 'insert-categories'// insertCategories,
export const gUpdateCategory: string = 'update-category'// updateCategory,
// export const gDeleteCategory: string = 'delete-category'// deleteCategory,

// jurisdictions

// export const gGetJurisdictions: string = 'get-jurisdictions' // getJurisdictions,
// export const gGetJurisdiction: string = 'get-jurisdiction'//  getJurisdiction,
export const gInsertJurisdictions: string = 'insert-jurisdictions'//  insertJurisdiction,
// export const gUpdateJurisdiction: string = 'update-jurisdiction'//  updateJurisdiction,
// export const gDeleteJurisdiction: string = 'delete-jurisdiction'//  deleteJurisdiction,

// GAME STORE NEW ROUTES
export const gGetGameList: string = 'games/get-game-list-admin' // replaces 'get-games'
export const gUpdateGameList: string = 'games/update-game'// replaces 'update-game'
// export const gInsertGameList: string = 'games/insert-game'// replaces 'insert-game'
export const gGetGameLabels: string = 'games/get-game-labels' // replaces 'get-labels'
export const gGetGameCategories: string = 'games/get-game-categories' // replaces 'get-categories'
// export const gGetJurisdictionsList: string = 'games/get-jurisdictions-list' // replaces 'get-jurisdictions'
export const gGetDevices: string = 'games/get-devices'
export const gGetGameProviders: string = 'games/get-game-provider-list'

// all

// export const gGetGamesAll: string = 'get-games-all' // getGamesAll
// export const gGetGameProvidersAll: string = 'get-game-providers-all' // getGameProvidersAll
// export const gGetSuppliersAll: string = 'get-game-suppliers-all' // getGameSuppliersAll

// ADMIN MODULE
export const amGetQuestionnaireInfo = 'get-questionnaire-info' // playerid, date
export const amBasicGet = 'basic-get'
// export const amInsertPlayerActivity = 'insert-player-activity'
// export const amDeletePlayerActivity = 'delete-player-activity'
// export const amGetPlayerActivity = 'get-player-activity'
// export const amUpdatePlayerActivity = 'update-player-activity'
export const amGetIssues = 'get-issues2'
// export const amGetIssue = 'get-issue'
export const amUpdateIssue = 'update-issue'
export const amInsertIssue = 'insert-issue-admin'
export const amBasicInsert = 'basic-insert'
export const amBasicUpdate = 'basic-update'
export const amBasicDelete = 'basic-delete'
// export const amGetElevatedRisk = 'get-elevated-risk'
// export const amUpdateElevatedRisk = 'update-elevated-risk'
// export const amGetPlayerHistory = 'get-player-history'

// New update player card data routes
export const amUpdatePaymentData = 'update-payment-data'

export const amGetPlayerCards = 'get-player-cards'
export const amGetPlayerCard = 'get-player-card'
export const amUpdatePlayerCard = 'update-player-card'
export const amWithdrawalHistoryGet = 'withdrawal-history-get'
export const amWithdrawalsStatusChange = 'withdrawals-status-change'
// export const amWithdrawalsGet = 'withdrawals-get'
export const amManualWithdrawal = 'manual-withdrawal'
export const amCrifCheck = 'crif-check'
export const amResendEmail = 'resend-email'
export const amManualDeposit = 'manual-deposit'
export const amCommunicationsUpdate = 'communications-update'
export const amManualCheckBlacklist = 'manual-check-blacklist'
export const amGetReports = 'get-reports'
export const amGetPlayersInfo = 'get-players-info'
// export const amGetPlayingInfo = 'get-playing-info'
// export const amGameSessionsGet = 'get-game-sessions'
// export const amGameSessionTransactionsGet = 'get-game-session-transactions'
// export const amBonusGetAll = 'bonus-get-all'
// export const amBonusActivate = 'bonus-activate'
// export const amBonusCancel = 'bonus-cancel'
// export const amBonusCancelAll = 'bonus-cancel-all'
// export const amBonusAddManualCode = 'bonus-add-manual-code'
// export const amBonusAddManual = 'bonus-add-manual'
// export const amBonusTriggeredInfoGet = 'bonus-triggered-info-get'
export const amGetExpiringAccountsInfo = 'get-expiring-accounts-info'
export const amGetAccountsInfo = 'get-accounts-info'
export const amGetIssuesCount = 'get-issues2-count'
export const amGetFirstDepositsList = 'first-deposits-list'
export const amGetPlayerIds = 'get-player-ids'
export const amGetPlayerTimeline = 'get-player-timeline'
export const amGetUnresolvedPlayerTimeline = 'get-unresolved-player-timeline'
export const amGetPlayerTransactions = 'get-player-transactions'
// export const amGetSessionTransactions = 'get-session-transactions'
export const amGetRecentEvents = 'get-recent-events'
export const amGetExpiringAccountsByDays = 'get-expiring-accounts-by-days'
export const amGetExpiringAccounts = 'get-expiring-accounts'
export const amGetExpiredAccounts = 'get-expired-accounts'
export const amGetLoggedInPlayers = 'auth/logged-in-players-get-v2'// 'get-logged-in-players'
export const amGetPepPlayers = 'get-pep-players'
// mychance new routes
// export const amGetLevelsNew = amGetLevels
// export const amGetMissionsNew = amGetMissions
export const amMyChanceStage = 'mc-server.gamingenius.net'
export const amMyChanceProduction = 'mc-server.gamingenius.com'
export const amMychanceStage = /* '35.246.191.76' */ 'mc-gamification.gamingenius.net'
export const amMychanceProduction = 'mc-gamification.gamingenius.com'
export const amMyChanceGames = 'get-casino-games'
export const amChangePlayerLevel = 'change-player-level'
export const amGetTournamentLeaderboard = 'get-tournament-leaderboard'
export const amGetMissionLeaderboard = 'get-mission-leaderboard'
export const amCashbackMondayOptionsGet = 'cashback-monday-optins-get'
export const amGetUsersInfoFiltered = 'get-users-info-filtered'
export const amGetStorePurchases = 'get-store-purchases'
export const amGetUserInfo = 'get-user-info'

// TABLES
export const amLargeValueDeposits = 'large_value_d'
export const amLargeValueWithdrawals = 'large_value_w'
export const amTemporaryAccount = 'temporary_account'
export const amPlayerActivity = 'player_activity'
export const amBlacklist = 'blacklist'
export const amMaximumBalance = 'max_balance'
export const amRetail = 'retail'
export const amRetailActions = 'retail_actions'
export const amSettings = 'settings'
export const amRaffle = 'raffle'
// export const amBonus = 'bonus_codes'
export const amProcessedPayments = 'processed_payments'
// export const amBonusCampaigns = 'bonus_campaigns'
export const amGameProviders = 'game_providers'
export const amRgsGameSupplier = 'rgs_game_supplier'
export const amRaffleParticipants = 'raffle_participants'
export const amSelfAssessmentGifts = 'self_assessment_gifts'
export const amSelfAssessmentCriteria = 'self_assessment_criteria'
export const amSelfAssessmentQuestions = 'self_assessment_questions'
export const amNetLimitLossIncreased = 'net_limit_loss_increased'
export const amNetLimitLossRegistration = 'net_limit_loss_registration'
export const amTemporaryExclude = 'temporary_exclude'
export const amTemporaryExcludeOptions = 'temporary_exclude_options'
export const amDashboardBackend = 'dashboard_backend'
export const amSelfExclusion = 'self_exclusion'
// export const amForbiddenUsernames = 'forbidden_usernames'
// export const amNotify = 'notify'
// export const amShop = 'shop'
export const amPlayerGroups = 'shop' // withdrawal_requests
// export const amPlayerGroups = 'player_groups'
export const amBonusWageringRequirement = 'notify'
export const amCounselingCentres = 'counseling_centres'
export const amIssues = 'issues'
export const amPostalCodes = 'postal_codes'
// export const amCategories = 'categories'
// export const amGames = 'games'
// export const amGamesCentral = 'games_central'
export const amCountries = 'countries'
// export const amJurisdictions = 'jurisdictions'
export const amNationalities = 'nationalities'
export const amCurrencies = 'currencies'
export const amLanguages = 'languages'
// TABLES END
// GAMIFICATION
export const amGetGeneral = 'get-general'
export const amUpdateGeneral = 'update-general'
export const amGetTournaments = 'get-tournaments'
export const amGetTournamentsAll = 'get-tournaments-all'
export const amInsertTournament = 'insert-tournament'
export const amUpdateTournament = 'update-tournament'
export const amDeleteTournament = 'delete-tournament'
export const amGetLevels = 'get-levels'
export const amGetLevelsAll = 'get-levels-all'
export const amDeleteLevel = 'delete-level'
export const amUpdateLevel = 'update-level'
export const amInsertLevel = 'insert-level'
export const amGetMissions = 'get-missions'
export const amGetMissionsAll = 'get-missions-all'
export const amDeleteMission = 'delete-mission'
export const amUpdateMission = 'update-mission'
export const amInsertMission = 'insert-mission'
export const amGetRewards = 'get-rewards'
export const amUpdateReward = 'update-reward'
export const amInsertReward = 'insert-reward'
export const amDeleteReward = 'delete-reward'
export const amGetRewardsAll = 'get-rewards-all'
export const amGetChallenges = 'get-challenges'
export const amGetChallengesAll = 'get-challenges-all'
export const amUpdateChallenge = 'update-challenge'
export const amInsertChallenge = 'insert-challenge'
export const amDeleteChallenge = 'delete-challenge'
export const amGetStores = 'get-stores'
export const amGetStoresAll = 'get-stores-all'
export const amInsertStore = 'insert-store'
export const amUpdateStore = 'update-store'
export const amDeleteStore = 'delete-store'

// export const amGetStore = 'get-store'
// export const amGetChallenge = 'get-challenge'
// export const amGetReward = 'get-reward'
// export const amGetMission = 'get-mission'
// export const amGetLevel = 'get-level'
// export const amGetTounament = 'get-tournament'
// GAMIFICATION END

// const environmentWalletBackendServer: string[] = 
// Build === BUILD.PASINO ?
// [
//     '', // LOCAL
//     'wbo.sandbox.gamingeniusstage.com', // DEVEDGE
//     '', // DEV
//     'wbo.ps.gamingeniusstage.com', // STAGE
//     'wbo.ps.gamingenius.com', // PRODUCTION
//     '', // PET
// ]
// :
// [
//     '', // LOCAL
//     'wbo.jp.gamingeniusstage.com', // DEVEDGE wbo-dev.gamingenius.net
//     '', // DEV
//     'wbo-stg.gamingenius.net', // STAGE
//     'wbo.gamingenius.com', // PRODUCTION
//     '', // PET
// ]


// export const walletBackendServer: string = environmentWalletBackendServer[environment]

// export const walletBackendServer = 'wbo.gamingenius.com' // wss://
export const walletGameSessionsGet = 'wbo/admin/game-sessions-get'
export const walletGameTransactionsGet = 'wbo/admin/game-transactions-get'
export const walletGameWithdrawGet = 'wbo/admin/withdraw-get'
export const walletTransactionsGet = 'wbo/admin/transactions-get'

export const walletGameReportsReal = 'wbo/admin/game-reports-real'
export const walletGameReportsTest = 'wbo/admin/game-reports-test'
export const walletPlayerReports = 'wbo/admin/player-reports'

export const bonusBonusGet = 'bonus/get-bonuses'
export const bonusBonusSave = 'bonus/save-bonus'
export const bonusBonusDelete = 'bonus/delete-bonus'
export const bonusBonusCampaignGet = 'bonus/get-campaigns'
export const bonusBonusCampaignSave = 'bonus/save-campaign'
export const bonusBonusCampaignUpdate = 'bonus/update-campaign'
export const bonusBonusCampaignDelete = 'bonus/delete-campaign'
export const bonusFreeGamesGet = 'bonus/get-free-game-settings-list'
export const bonusFreeGamesSave = 'bonus/save-free-game-settings'
export const bonusFreeGamesDelete = 'bonus/delete-free-game-setting'
export const bonusBonusAddManualCode = 'bonus/bonus-add-manual-code'
// export const bonusBonusAddManual = 'bonus/bonus-add-manual'
export const bonusPlayerBonusHistory = 'bonus/get-player-granted-bonus-history'
export const bonusCancelBonus = 'bonus/cancel-bonus'
export const bonusCancelBonusAll = 'bonus/bonus-cancel-all'
export const bonusBonusTriggeredInfo = 'bonus/bonus-triggered-info-get'
// export const bonusBonusAddManualDeposit = 'bonus/bonus-add-manual-deposit'
export const bonusActivateBonus = 'bonus/set-bonus-active-status'
export const bonusBonusAddManualCustom = 'bonus/bonus-add-manual-custom'

export const bonusGloballyExcludedTagsGet = 'bonus/get-globally-excluded-tags'
export const bonusGloballyExcludedTagsSet = 'bonus/set-globally-excluded-tags'

// New admin tables routes
export const amGetProcessedPayments = 'get-processed-payments'

// export const pipeServerDev = 'ws://17ae7d5d.ngrok.io/views/'
// export const pipeServer = 'wss://admin-stream.gamingenius.com/views/'
// export const pipeWithdrawals = 'withdrawals'
// export const pipeTransactionsAll = 'transactions-all'

/*
getGameSessions(input: { playerId: string, limit: number, page: number })
getGameSessionTransactions(input: { gameSessionId: string, limit: number, page: number })
*/
// export const amInitial = amSettings
// get-unresolved-player-timeline'
/*
'get-player-transactions': getPlayerTransactions,
'get-player-sessions': getPlayerSessions,
'get-session-transactions': getSessionTransactions,
'get-player-timeline': getPlayerTimeline,getPlayerTimeline(input: { playerId: string }
getPlayerTransactions(input: { playerId: string, type: TransactionTypes }
getPlayerSessions(input: { playerId: string }
getSessionTransactions(input: { sessionId: string }
*/
// ROUTES TO THE ADMIN SERVER
// this has expired now, so I will disable it
// export const asIbanCheck: string = adminServer + '/iban-check/'
export const asAdminLog: string = adminServer + '/admin-log/'
export const asAdminLogCount: string = adminServer + '/admin-log-count/'
export const asAdminLogDistinctUserId: string = adminServer + '/admin-log-distinct-user-id/'
export const asAdminLogDistinctType: string = adminServer + '/admin-log-distinct-type/'
export const asAdminLogDistinctSubtype: string = adminServer + '/admin-log-distinct-subtype/'
export const asAdminLogDistinctDate: string = adminServer + '/admin-log-distinct-date/'
// export const asAdminLogDistinctEntityId: string = adminServer + '/admin-log-distinct-entity-id/'
export const asAuthorize: string = adminServer + '/authorize/' // try to authorize
export const asAuthorizeEmail: string = adminServer + '/authorize-email/' // try to authorize
export const asAuthorizePhone: string = adminServer + '/authorize-phone/' // try to authorize
// export const asAuthorizedUser: string = adminServer + '/authorized-user/' // already authorized, get data
// export const asAuthorizedUserEmail: string = adminServer + '/authorized-user-email/' // already authorized, get data
// export const asAuthorizedUserPhone: string = adminServer + '/authorized-user-phone/' // already authorized, get data
export const asAuthorizeHash: string = adminServer + '/authorize-hash/'
export const asAuthorizeSecurityQuestionEmail: string = adminServer + '/authorize-security-question-email/'
export const asAuthorizeSecurityQuestionPhone: string = adminServer + '/authorize-security-question-phone/'
export const asAuthorizeSecurityAnswerEmail: string = adminServer + '/authorize-security-answer-email/'
export const asAuthorizeSecurityAnswerPhone: string = adminServer + '/authorize-security-answer-phone/'

export const asSettings: string = adminServer + '/settings/'
export const asLanguages: string = adminServer + '/languages/'
export const asCpAllByTypeById: string = adminServer + '/cp-all-by-type-by-id/'
export const asCpAllByType: string = adminServer + '/cp-all-by-type/'
export const asGroups: string = adminServer + '/groups/'
export const asCpSelect: string = adminServer + '/cp-select/'
export const asApiKey: string = adminServer + '/api-key/'
export const asToggle: string = adminServer + '/toggle/'
export const asAction: string = adminServer + '/action/'
export const asDomain: string = adminServer + '/domain/'
export const asTestSite: string = adminServer + '/test-site/'
export const asMenus: string = adminServer + '/menus/'
export const asCpMaster: string = adminServer + '/cp-master/'
export const asCpByTypeAll: string = adminServer + '/cp-by-type-all/'
export const asGameProvider: string = adminServer + '/game-provider/'
export const asMaster: string = adminServer + '/master/'
export const asMasterSearch: string = adminServer + '/master-search/'
export const asNewPassword: string = adminServer + '/new-password/'
export const asNewUser: string = adminServer + '/new-user/'
export const asNewUserEntities: string = adminServer + '/new-user-entities/'
// export const asNewUserCount: string = adminServer + '/new-user-count/'
export const asNewUserNoPassword: string = adminServer + '/new-user-no-password/'
export const asFlags: string = adminServer + '/flags/'
export const asSubEntity: string = adminServer + '/sub-entity/'
export const asSubEntityInsert: string = adminServer + '/sub-entity-insert/'
export const asCP: string = adminServer + '/cp/'
export const asForgotPassword: string = adminServer + '/forgot-password/'

export const asCoreTransactions: string = adminServer + '/core-transactions/'
export const asCoreTransactionsInit: string = adminServer + '/core-transactions-init/'
export const asCoreTransactionsCount: string = adminServer + '/core-transactions-count/'
export const asCoreTransactionsDistinctYears: string = adminServer + '/core-transactions-distinct-years/'
export const asCoreTransactionsDistinctMonths: string = adminServer + '/core-transactions-distinct-months/'
export const asCoreTransactionsDistinctDates: string = adminServer + '/core-transactions-distinct-dates/'
export const asCoreTransactionsDistinctGames: string = adminServer + '/core-transactions-distinct-games/'
export const asCoreTransactionsAggregated: string = adminServer + '/core-transactions-aggregated/'

// export const asCoreTransactionsDistinctPlayers: string = adminServer + '/core-transactions-distinct-players/'

// THIS IS THE SAME AS ADMIN SERVER AT THE MOMENT
// export const crmServer: string =  prefix + prefixAdminServer + '.' + server
// export const crmServer: string = 'http://localhost:5544'
// export const csEvents: string = adminServer + '/crm-events/'
/*
export const csSQL: string = adminServer + '/crm-sql/'
export const csGames: string = adminServer + '/crm-games/'
export const csPlayers: string = adminServer + '/crm-players/'
export const csCountries: string = adminServer + '/crm-countries/'
export const csCurrencies: string = adminServer + '/crm-currencies/'
export const csTimezones: string = adminServer + '/crm-timezones/'
export const csRules: string = adminServer + '/crm-rules/'
export const csRulesCount: string = adminServer + '/crm-rules-count/'
*/
const tourdebetServer: string = prefix + prefixTourDeBetServer + '.' + server
// const tourdebetServer = 'http://localhost:8084'

export let tdSports = tourdebetServer + '/sports/'
export let tdCategories = tourdebetServer + '/categories1/'
export let tdTournaments = tourdebetServer + '/tournaments1/'
export let tdMatches = tourdebetServer + '/matches/'

// THIS IS MULTILOTTO LOTTERY
const lottoServer: string = prefix + prefixLottoServer + '.' + server
// const lottoServer: string = 'http://localhost:8085'
export const lsLottery: string = lottoServer + '/lottery/'
export const lsLotteryCount: string = lottoServer + '/lottery-count/'
export const lsLotteryLeaderboard: string = lottoServer + '/lottery-leaderboard/'
export const lsLotteryTransaction: string = lottoServer + '/lottery-transaction/'
export const lsCountLotteryTransaction: string = lottoServer + '/count-lottery-transaction/'
export const lsAction: string = lottoServer + '/action/'
export const lsToggle: string = lottoServer + '/toggle/'
export const lsGameType: string = lottoServer + '/game-type/'
export const lsJackpot: string = lottoServer + '/jackpot/'
export const lsDrawings: string = lottoServer + '/drawings/'
export const lsWinnersDirect: string = lottoServer + '/winners-direct/'
export const lsDraw: string = lottoServer + '/draw/'
export const lsBet: string = lottoServer + '/bet/'

// export const lsForex: string = lottoServer + '/forex/'
// export const lsNextDrawings: string = lottoServer + '/next-drawings/'
// export const lsDrawingSchedule: string = lottoServer + '/drawing-schedule/'
// export const lsDrawingPayoutTable: string = lottoServer + '/drawing-payout-table/'
// export const lsWinners: string = lottoServer + '/winners/'
// export const lsBets: string = lottoServer + '/bets/'
// export const lsGameTypePlayable: string = lottoServer + '/game-type-playable/'
// export const lsLotteryAll: string = lottoServer + '/lottery-all/'

// THIS IS OUR LOTTERY
const lotteryServer: string = prefix + prefixLotteryServer + '.' + server
// const lotteryServer: string = 'http://localhost:8086'
export const ltRaffleJackpotGameType: string = lotteryServer + '/raffle-jackpot-game-type/'
export const ltRaffleGameType: string = lotteryServer + '/raffle-game-type/'
export const ltRaffle: string = lotteryServer + '/raffle/'
export const ltGameType: string = lotteryServer + '/game-type/'
export const ltDraw: string = lotteryServer + '/draw/'
export const ltRaffleTickets: string = lotteryServer + '/raffle-tickets/'
export const ltRafflePlaying: string = lotteryServer + '/raffle-playing/'

const promostoreServer: string = prefix + prefixPromostore + '.' + server
// const promostoreServer: string = 'http://localhost:8081'
// const promostoreServer: string = 'http://localhost:5544'
// ROUTES TO THE PROMOSTORE
export const psServerSublevelsWidget: string = promostoreServer + '/sublevels-widget/'
export const psGamesCheckLastUpdate: string = promostoreServer + '/games-check-last-update/'
export const psSettlementReportsScandibet: string = promostoreServer + '/settlement-reports/'
export const psCheckLastId: string = promostoreServer + '/check-last-id/'
export const psRst: string = promostoreServer + '/rst/'
export const psPlayerStatsXPLXP: string = promostoreServer + '/player-stats-xpl-xp/'
export const psPlayerCurrency: string = promostoreServer + '/playercurrency/'
// export const psGiveReward: string = promostoreServer + '/give-reward/'
export const psGiveRewardTest: string = promostoreServer + '/give-reward-test/'
export const psChangeIndexRewardShop: string = promostoreServer + '/change-index-reward-shop/'
export const psPlayer: string = promostoreServer + '/player/'
export const psLevelsAllDetailsBySite: string = promostoreServer + '/levels-all-details-by-site/'
export const psPlayerStats: string = promostoreServer + '/player-stats/'
export const psEventsPlayer: string = promostoreServer + '/events-player/'
export const psEvents: string = promostoreServer + '/events/'
export const psToggle: string = promostoreServer + '/toggle/'
export const psAction: string = promostoreServer + '/action/'
export const psPartRst: string = promostoreServer + '/part-rst/'
export const psCountRst: string = promostoreServer + '/count-rst/'
export const psBundleRst: string = promostoreServer + '/bundle-rst/'
export const psCountBundleRst: string = promostoreServer + '/count-bundle-rst/'
export const psAllRst: string = promostoreServer + '/all-rst/'
export const psPlayerAll: string = promostoreServer + '/player-all/'
export const psShopItem: string = promostoreServer + '/shop-item/'
export const psAllShopItems: string = promostoreServer + '/all-shop-items/'
export const psCategoryShopItems: string = promostoreServer + '/category-shop-items/'
export const psCountShopItems: string = promostoreServer + '/count-shop-items/'
export const psPartShopItems: string = promostoreServer + '/part-shop-items/'
export const psTournamentCount: string = promostoreServer + '/tournament-count/'
export const psTournamentPart: string = promostoreServer + '/tournament-part/'
export const psTournament: string = promostoreServer + '/tournament/'
export const psCalendarDay: string = promostoreServer + '/calendar-day/'
export const psCalendar: string = promostoreServer + '/calendar/'
export const psCalendarDayCount: string = promostoreServer + '/calendar-day-count/'
export const psCurrency: string = promostoreServer + '/currency/'
export const psSubLevelsAllByLevel: string = promostoreServer + '/sub-levels-all-by-level/'
export const psAllPointTypes: string = promostoreServer + '/all-point-types/'
export const psAllSubPointTypes: string = promostoreServer + '/all-sub-point-types/'
export const psCountPointTypes: string = promostoreServer + '/count-point-types/'
export const psPartPointTypes: string = promostoreServer + '/part-point-types/'
export const psDefaultPointTypes: string = promostoreServer + '/default-point-types/'
export const psPoint: string = promostoreServer + '/point/'
export const psPlayerGroup: string = promostoreServer + '/player-group/'
export const psLevel: string = promostoreServer + '/level/'
export const psCountLevel: string = promostoreServer + '/count-level/'
export const psLevelsAll: string = promostoreServer + '/levels-all/'
export const psLevelsAllSourceSite: string = promostoreServer + '/levels-all-source-site/'
export const psLevelsSubAllSourceSite: string = promostoreServer + '/levels-sub-all-source-site/'
export const psLevelSubClone: string = promostoreServer + '/level-sub-clone/'
export const psLevelClone: string = promostoreServer + '/level-clone/'
export const psLoyaltyFreeRound: string = promostoreServer + '/loyalty-free-round/'
export const psLoyaltyFreeRoundBySite: string = promostoreServer + '/loyalty-free-round-by-site/'
export const psCountLoyaltyFreeRoundBySite: string = promostoreServer + '/count-loyalty-free-round-by-site/'
export const psCountLoyaltyFreeRound: string = promostoreServer + '/count-loyalty-free-round/'
export const psPartLoyaltyFreeRound: string = promostoreServer + '/part-loyalty-free-round/'
export const psPartFilterLoyaltyFreeRound: string = promostoreServer + 'part-filter-loyalty-free-round'
export const psCountFilterLoyaltyFreeRound: string = promostoreServer + 'count-filter-loyalty-free-round'
export const psCurrencyRates: string = promostoreServer + '/currency-rates/'
export const psCurrencyAll: string = promostoreServer + '/currency-all/'
export const psCurrencyPart: string = promostoreServer + '/currency-part/'
export const psCurrencyCount: string = promostoreServer + '/currency-count/'
export const psCurrencyNames: string = promostoreServer + '/currency-names/'
export const psAllPlayerGroupBySite: string = promostoreServer + '/all-player-group-by-site/'
export const psPartPlayerGroupByOperator: string = promostoreServer + '/part-player-group-by-operator/'
export const psCountPlayerGroupByOperator: string = promostoreServer + '/count-player-group-by-operator/'
export const psPartPlayerGroup: string = promostoreServer + '/part-player-group/'
export const psPartPlayerGroupBySite: string = promostoreServer + '/part-player-group-by-site/'
export const psCountPlayerGroup: string = promostoreServer + '/count-player-group/'
export const psPlayers: string = promostoreServer + '/players/'
export const psPlayersCurrencies: string = promostoreServer + '/players-currencies/'
export const psCountSubPointTypesByPointType: string = promostoreServer + '/count-sub-point-types-by-point-type/'
export const psPartSubPointTypesByPointType: string = promostoreServer + '/part-sub-point-types-by-point-type/'
export const psSubPointTypesByPointType: string = promostoreServer + '/sub-point-types-by-point-type/'
export const psLevelsAllBySite: string = promostoreServer + '/levels-all-by-site/'
export const psCategory: string = promostoreServer + '/category/'
export const psType: string = promostoreServer + '/type/'
export const psAleaccGamesUpdate: string = promostoreServer + '/aleacc-games-update/'
export const psAleaccGames: string = promostoreServer + '/aleacc-games/'
export const psAleaccGamesStage: string = promostoreServer + '/aleacc-games-stage/'
export const psLanguage: string = promostoreServer + '/language/'
export const psLanguagePart: string = promostoreServer + '/language-part/'
export const psLanguageCount: string = promostoreServer + '/language-count/'
export const psBanner: string = promostoreServer + '/banner/'
export const psBannerPart: string = promostoreServer + '/banner-part/'
export const psBannerCount: string = promostoreServer + '/banner-count/'
export const psGame: string = promostoreServer + '/game/'
export const psGamePart: string = promostoreServer + '/game-part/'
export const psGameCount: string = promostoreServer + '/game-count/'
export const psPointsInProgramAll: string = promostoreServer + '/points-in-program-all/'
export const psPointsInProgram: string = promostoreServer + '/points-in-program/'
export const psPointsInProgramCount: string = promostoreServer + '/points-in-program-count/'
export const psPointsInProgramFilter: string = promostoreServer + '/points-in-program-filter/'
export const psPointsInProgramCountFilter: string = promostoreServer + '/points-in-program-count-filter/'
export const psAchievement: string = promostoreServer + '/achievement/'
export const psAchievementPart: string = promostoreServer + '/achievement-part/'
export const psCountAchievement: string = promostoreServer + '/count-achievement/'
export const psAchievementAll: string = promostoreServer + '/achievement-all/'
export const psAllAchievementsBySite: string = promostoreServer + '/all-achievements-by-site/'
export const psAllAchievementsByOperator: string = promostoreServer + '/all-achievements-by-operator/'
export const psAchievementsBySite: string = promostoreServer + '/achievements-by-site/'
export const psAchievementsByOperator: string = promostoreServer + '/achievements-by-operator/'
export const psCountAchievementBySite: string = promostoreServer + '/count-achievement-by-site/'
export const psCountAchievementByOperator: string = promostoreServer + '/count-achievement-by-operator/'

export function getTZ(timestamp: string, tz: string): string { 
    // const Timestamp: string = timestamp.substring(0,10) + 'T' + timestamp.substring(11,19) + 'Z'
    // console.log (timestamp, tz, Timestamp)
    const TZ: string = moment.tz(timestamp.substring(0,10) + 'T' + timestamp.substring(11,19) + 'Z', tz).format()
/*
    console.log(TZ)
    const Tz: Date = new Date (TZ)
    console.log(Tz)
    const offset: number = Tz.getTimezoneOffset()
    console.log(offset)
    const unixTime: number = Tz.getTime() - offset * 60 * 1000
    const date: Date = new Date(unixTime)
    const date_: string = date.toISOString()
    const Date_: string = date_.substring(0, 10) + ' ' + date_.substring(11, 19)
    // console.log (Date_)
    return Date_
*/
    return TZ.substring(0, 10) + ' ' + TZ.substring(11, 19)
}