// import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from 'src/Logic/Main'
import ReactTable from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHashtag} from '@fortawesome/free-solid-svg-icons'
import {loadMore, getFilterOtherWithdrawals, changeFilterWithdrawalRequestStatus} from './WithdrawalRequests'
import { PaymentProcessedDuplicates, TransactionStatusNoPending, WithdrawalRequest, PaymentProcessedStatus} from '../../../Logic/Database/DatabaseData'
import { Logic } from 'src/Logic/Type'

interface State {
    paymentProcessedTransactions: PaymentProcessedDuplicates[]
    paymentProcessedDuplicates: PaymentProcessedDuplicates[]
}

function getDuplicates(pending: boolean, withdrawalRequest: WithdrawalRequest[]): PaymentProcessedDuplicates[] {

    const duplicates: PaymentProcessedDuplicates[] = []
    for (const ITEM of withdrawalRequest) {
        let found: boolean = false
        if (Main.Instance.DatabaseDataState.paymentProcessed) {
            for (const item of Main.Instance.DatabaseDataState.paymentProcessed) {
                if (item.data.transactionIds === undefined || item.data.status === PaymentProcessedStatus.Inquiry) {continue}
                for (const Item of item.data.transactionIds) {
                    if (ITEM.transactionId === Item && (pending || !pending && !ITEM.markedForPayment)) {
                        found = true
                        // ITEM.occurrences += 1
                        break
                    }
                }
                if (found) {break}
            }
        }
        if (found) {
            let Found: boolean = false
            for (const item of duplicates) {
                if (item.id === ITEM.transactionId) {
                    item.occurrences++
                    Found = true
                    break
                }
            }
            if (!Found) {
                duplicates.push({id: ITEM.transactionId, occurrences: 1, date: (new Date(ITEM.created * 1000)).toISOString().substring(0,19).replace('T', ' ')})
            }
        }
    }
    return duplicates
}

function checkForDuplicates() {
    if (/*!Main.Instance.DatabaseDataState.duplicatesOther ||*/ !Main.Instance.DatabaseDataState.duplicatesPending) {
        if (Main.Instance.DatabaseDataState.withdrawalRequest1 && Main.Instance.DatabaseDataState.withdrawalRequest2) {
            // we have to see now if there are any duplicates among
            
            // pending
            const duplicatesPending: PaymentProcessedDuplicates[] = getDuplicates(true, Main.Instance.DatabaseDataState.withdrawalRequest1)
            const duplicatesOther: PaymentProcessedDuplicates[] = getDuplicates(false, Main.Instance.DatabaseDataState.withdrawalRequest2)

            // other
            // const duplicatesOther: PaymentProcessedDuplicates[] = []

            Logic.Type.New(Main.Instance.DatabaseDataState, {duplicatesOther, duplicatesPending})
        }
    }
}

function getDuplicatesTable(data: PaymentProcessedDuplicates[]) {
    return (
        <ReactTable
            data = {data}
            columns = {[
                {Header: Assets.Transaction + ' ' + Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                {Header: <FontAwesomeIcon icon = {faHashtag}/>, accessor: 'occurrences', headerClassName: 'th-left'},
                {Header: Assets.Timestamp + ' (' + Assets_.UTC + ')', accessor: 'date', headerClassName: 'th-left'},
                // {Header: Assets.Payment + ' ' + Assets.ID, accessor: 'paymentId', headerClassName: 'th-left'},
            ]}
            showPaginationBottom = {false}
            showPaginationTop = {true}
            pageSize={10}
        />
    )
}

export class WithdrawalDuplicates extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            paymentProcessedTransactions: undefined,
            paymentProcessedDuplicates: undefined
        }
        // this.handleEdit = this.handleEdit.bind(this);
        // if (!Main.Instance.DatabaseDataState.paymentProcessed) {
        //     DbRoutes.wsReinitialize()
        // }
        // DbRoutes.wsReinitialize()
    }

    public render() {
        if (!Main.Instance.DatabaseDataState.paymentProcessed || Main.Instance.DatabaseDataState.PaymentProcessedGet) {
            return <div>{Assets.LoadingData}</div>
        }
        // console.log (Main.Instance.DatabaseDataState.paymentProcessed)
        if (this.state.paymentProcessedTransactions === undefined) {
            const paymentProcessedTransactions: PaymentProcessedDuplicates[] = []
            for (const item of Main.Instance.DatabaseDataState.paymentProcessed) {

  

                if (item.data.transactionIds === undefined || item.data.status === PaymentProcessedStatus.Inquiry) {continue}
                for (const Item of item.data.transactionIds) {
                    let found: boolean = false
                    for (const ITEM of paymentProcessedTransactions) {
                        if (+ITEM.id === Item) {
                            found = true
                            ITEM.occurrences++
                            ITEM.paymentId.push (item.id)
                            ITEM.dates.push(item.data.date)
                            break
                        }
                    }
                    if (!found) {
                        paymentProcessedTransactions.push({id: +Item, occurrences: 1, dates: [item.data.date], paymentId: [item.id]})
                    }
                }
            }
            const paymentProcessedDuplicates: PaymentProcessedDuplicates[] = []
            for (const item of paymentProcessedTransactions) {
                if (item.occurrences > 1) {
                    paymentProcessedDuplicates.push(item)
                }
            }
            this.setState({paymentProcessedTransactions, paymentProcessedDuplicates})

        }
        if (!this.state.paymentProcessedDuplicates) {
            return <div>{Assets.LoadingData}</div>
        }

        // console.log (this.state.paymentProcessedDuplicates)
        // console.log ('Pending')
        // console.log (Main.Instance.DatabaseDataState.duplicatesPending)
        // console.log ('Other')
        // console.log (Main.Instance.DatabaseDataState.duplicatesOther)
        // const approvedWithdrawalRequests: number[] = getApprovedWithdrawalRequests()
        if (Main.Instance.DatabaseDataState.onlyWithdrawalRequestStatus !== TransactionStatusNoPending.APPROVED) {
            changeFilterWithdrawalRequestStatus(TransactionStatusNoPending.APPROVED)
        }
        return (
            <div>
                <h3>{Assets.List + Assets._of_ + Assets.Duplicates + Assets._in_ + Assets.Processed.toLowerCase() + ' ' + Assets.Withdrawals.toLowerCase()} [{this.state.paymentProcessedDuplicates.length}]</h3>
                <ReactTable
                    data = {this.state.paymentProcessedDuplicates}
                    columns = {[
                        {Header: Assets.Transaction + ' ' + Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                        {Header: <FontAwesomeIcon icon = {faHashtag}/>, accessor: 'occurrences', headerClassName: 'th-left'},
                        {Header: Assets.Date, accessor: 'dates', headerClassName: 'th-left', Cell: (row) => JSON.stringify(row.value)},
                        {Header: Assets.Payment + ' ' + Assets.ID, accessor: 'paymentId', headerClassName: 'th-left', Cell: (row) => JSON.stringify(row.value)},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    pageSize={10}
                />
                {GetDuplicates(true)}
            </div>
        ) 
    }
}

export function GetDuplicates(showFilter: boolean): JSX.Element {
    checkForDuplicates()
    return (
        <>
        <br/>
        <div>
            {Main.Instance.DatabaseDataState.withdrawalRequest1 ? loadMore(true): void 0}
            {Main.Instance.DatabaseDataState.duplicatesPending !== undefined ?
            <div>
                <strong>{Assets.Duplicates}:&nbsp;</strong>
                {Main.Instance.DatabaseDataState.duplicatesPending.length === 0 ?
                <span style = {{fontSize: '13px'}} className = "label label-success">{Assets.ThereAreNoDuplicatesFoundInPendingWithdrawals}</span>
                :
                <>
                <span>&nbsp;[{Main.Instance.DatabaseDataState.duplicatesPending.length}]</span>
                {getDuplicatesTable(Main.Instance.DatabaseDataState.duplicatesPending)}
                </>}
            </div> : void 0}
        </div>
        <br/>
        {showFilter ?
        <div className = "pr">
            <div style = {{position: 'absolute', right: '0px', top: '0px'}}>
                {getFilterOtherWithdrawals(true)}
            </div>
        </div> : void 0}
        <div>
            {Main.Instance.DatabaseDataState.withdrawalRequest2 ? loadMore(false): void 0}
            {Main.Instance.DatabaseDataState.duplicatesOther !== undefined ?
            <div>
                <strong>{Assets.Duplicates}:&nbsp;</strong>
                {Main.Instance.DatabaseDataState.duplicatesOther.length === 0 ?
                <span style = {{fontSize: '13px'}} className = "label label-success">{Assets.ThereAreNoDuplicatesFoundInProcessedWithdrawals}</span>
                :
                <>
                <span>&nbsp;[{Main.Instance.DatabaseDataState.duplicatesOther.length}]</span>
                {getDuplicatesTable(Main.Instance.DatabaseDataState.duplicatesOther)}
                </>}
            </div> : void 0}
        </div>
        </>
    )
}