import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import {isUserAdmin, isUserSystem, isUserMaster, gotoTop} from '../../../Logic/Utils'
import {FormSelect, FormInput, FormInputNumber, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave, faUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as Assets_ from '../../Assets_'

// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD

// function removeContractualPartner(id: string){
//     DbRoutes.removeContractualPartner(id)
// }

export function getContractualPartnerCount() {

        if (Main.Instance.RegisterContractualPartnerState.countCalled === false) {

                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {countCalled:true})

                // DbRoutes.countContractualPartners()
                DbRoutes.getContractualPartners()
                if (isUserSystem) { 
                        DbRoutes.getMastersAll()
                }
        }
}

function getContractualPartner() {

    Logic.Type.New (Main.Instance.DatabaseDataState, {contractualPartnersStream : undefined})
}

function clearFields() {

    Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
            id: '',
            name : '',
            email : '',
            phone : '',
            // custom : {},
            // data : {},
            // type_id: '0',
            person : '',
            gg_rm_wallet : '2',
            mode : false,
            master_id: '0' })
}

function handleInsertUpdate() {

        const items = {
                name:   !Main.Instance.RegisterContractualPartnerState.name ? '' : 
                        Main.Instance.RegisterContractualPartnerState.name,
                email:  !Main.Instance.RegisterContractualPartnerState.email ? '' :
                        Main.Instance.RegisterContractualPartnerState.email,
                telephone: !Main.Instance.RegisterContractualPartnerState.phone ? '' : 
                        Main.Instance.RegisterContractualPartnerState.phone,
                id: !Main.Instance.RegisterContractualPartnerState.id ? '' :
                        Main.Instance.RegisterContractualPartnerState.id,
                person: !Main.Instance.RegisterContractualPartnerState.person ? '' : 
                        Main.Instance.RegisterContractualPartnerState.person,
                type_id: !Main.Instance.RegisterContractualPartnerState.type_id ? '' : 
                        Main.Instance.RegisterContractualPartnerState.type_id,
                master_id: !Main.Instance.RegisterContractualPartnerState.master_id ? '' :
                        Main.Instance.RegisterContractualPartnerState.master_id,
                gg_rm_wallet: !Main.Instance.RegisterContractualPartnerState.gg_rm_wallet ? '' :
                        Main.Instance.RegisterContractualPartnerState.gg_rm_wallet,
                gg_rm_wallet_id: !Main.Instance.RegisterContractualPartnerState.gg_rm_wallet ? false : 
                        Main.Instance.RegisterContractualPartnerState.gg_rm_wallet === '1',
        }

        if (parseInt(items.type_id) !== 1 && parseInt(items.type_id) !== 5) {
                items.gg_rm_wallet = '2'
                items.gg_rm_wallet_id = false
        }

        if (!isUserSystem()) {
                items.master_id = Main.Instance.LoginState.entityId
        }

        if (items.name.length === 0) {

                alert (Assets.compulsoryContractualPartner)
                return
        } else if (parseInt(items.master_id) === 0 && isUserSystem()) {

                alert (Assets.pleaseSelectMaster)
                return
        } else if (parseInt(items.type_id) === 0) {

                alert (Assets.pleaseSelectContractualPartner)
                return
        } else if (items.person.length === 0) {

                alert (Assets.compulsoryPerson)
                return
        } else if (items.email.length === 0) {

                alert (Assets.compulsoryEmail)
                return
/*
        } else if (items.telephone.length === 0) {
                alert (Assets.compulsoryPhone)
                return
        } else if (items.telephone.charAt(0) !== '+') {
                alert (Assets.compulsoryPhoneStartWithPlus)
                return
*/
        } else if (Main.Instance.RegisterContractualPartnerState.mode === false) {
        // means that we are in false mode = INSERT

                DbRoutes.insertContractualPartner (items, InsertLastPage)
    
        } else {
        // MODE UPDATE
                DbRoutes.updateContractualPartner (items, Update)

        }
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.contractual_partners SET gg_rm_wallet = ', 
                        rowData[0], rowData[4], getContractualPartnerCount)
    }
}
*/
function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('UPDATE admin.contractual_partners SET gg_rm_wallet = ', id.toString(), value, getContractualPartnerCount) }
}

function handleEdit(row: any) { 
    gotoTop()
    if (row) {
        // let gg_rm_wallet:string
        // rowData[4].toString() === 'true' ? gg_rm_wallet = '1' : gg_rm_wallet = '2'

        let select_type_id:string
        if (parseInt(row.original.type_id) === 1) {
            select_type_id = '0'
        } else if (parseInt(row.original.type_id) === 5) {
            select_type_id = '1'
        } else if (parseInt(row.original.type_id) === 2) {
            select_type_id = '2'
        }

        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                id: row.original.id,
                name: row.original.contractualpartner,
                select_type_id,
                type_id: row.original.type_id,
                gg_rm_wallet_id: row.original.ggrmwallet.toString() === 'true',
                gg_rm_wallet:row.original.ggrmwallet.toString() === 'true'? '1' : '2',
                master_id: row.original.master_id,
                name_saved: row.original.contractualpartner,
                type_id_saved: row.original.type_id,
                gg_rm_wallet_saved: row.original.ggrmwallet.toString() === 'true'? '1' : '2',
                email:row.original.email !== null ? row.original.email : '',
                email_saved:row.original.email !== null ? row.original.email : '',
                phone:row.original.phone !== null ? row.original.telephone : '',
                phone_saved:row.original.phone !== null ? row.original.telephone : '',
                person:row.original.person !== null ? row.original.person : '',
                person_saved:row.original.person !== null ? row.original.person : '',
/*
                person: row.original.person,
                email: row.original.email,
                phone: row.original.telephone,
                person_saved: row.original.person,
                email_saved: row.original.email,
                phone_saved: row.original.telephone,
*/
                master_id_saved: row.original.master_id,
                mode:true,
        })
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.ContractualPartner + ' "' + row.original.contractualpartner + '"?')) {
            if (Main.Instance.RegisterContractualPartnerState.pNum === 
                (Math.trunc((Main.Instance.RegisterContractualPartnerState.count - 2) / 
                Main.Instance.RegisterContractualPartnerState.pSize) + 1)) {
            // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
            // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
    
                    DbRoutes.removeContractualPartner(row.original.id,DeleteLastPage)
            } else {
                    DbRoutes.removeContractualPartner(row.original.id,DeleteNotLastPage)
            }
        }
    }
}

function InsertLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
            count:Main.Instance.RegisterContractualPartnerState.count++})
    LastPage()
}

function DeleteLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
            count:Main.Instance.RegisterContractualPartnerState.count--})
    LastPage()
}

function DeleteNotLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
            count:Main.Instance.RegisterContractualPartnerState.count--})
    getContractualPartner()
}

function Update() {

    clearFields()

    getContractualPartner()
}

function gotoInsertMode() {

        if (Main.Instance.RegisterContractualPartnerState.name === 
        Main.Instance.RegisterContractualPartnerState.name_saved &&
        (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 
        parseInt(Main.Instance.RegisterContractualPartnerState.type_id_saved)) &&
        ((parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 1 || 
        parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 5) && 
        Main.Instance.RegisterContractualPartnerState.gg_rm_wallet === 
        Main.Instance.RegisterContractualPartnerState.gg_rm_wallet_saved || 
        (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) !== 1) && 
        parseInt(Main.Instance.RegisterContractualPartnerState.type_id) !== 5) &&
        Main.Instance.RegisterContractualPartnerState.person === 
        Main.Instance.RegisterContractualPartnerState.person_saved &&
        Main.Instance.RegisterContractualPartnerState.email === 
        Main.Instance.RegisterContractualPartnerState.email_saved &&
        Main.Instance.RegisterContractualPartnerState.phone === 
        Main.Instance.RegisterContractualPartnerState.phone_saved &&
        Main.Instance.RegisterContractualPartnerState.master_id === 
        Main.Instance.RegisterContractualPartnerState.master_id_saved
        ) {

                clearFields()
        } else {

                if (confirm(Assets.UnsavedChanges)) {
                        clearFields() 
                }                
        }
}

function FirstPage() {

        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {offset:(0).toString()})
        getContractualPartner()
        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {pNum: 0})
}

function PrevPage() {

        if (Main.Instance.RegisterContractualPartnerState.pNum > 0) {

                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                        offset: ((Main.Instance.RegisterContractualPartnerState.pNum - 1) *  
                Main.Instance.RegisterContractualPartnerState.pSize).toString()})
                getContractualPartner();
                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                        pNum: Main.Instance.RegisterContractualPartnerState.pNum - 1})
        } else {
               LastPage()
        }
}

function NextPage() {

        if ((Main.Instance.RegisterContractualPartnerState.pNum + 1) * 
        Main.Instance.RegisterContractualPartnerState.pSize < Main.Instance.RegisterContractualPartnerState.count) {
        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                offset:(Main.Instance.RegisterContractualPartnerState.pSize * 
                (Main.Instance.RegisterContractualPartnerState.pNum + 1)).toString()})
        getContractualPartner()                
        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
        pNum: Main.Instance.RegisterContractualPartnerState.pNum + 1})
        } else {
        FirstPage()
        }
}

function LastPage() {

        if (Main.Instance.RegisterContractualPartnerState.count <= 
            Main.Instance.RegisterContractualPartnerState.pSize) {
            FirstPage()
        } else {
            let npNum: number = 0;
            if (Main.Instance.RegisterContractualPartnerState.count === 0) {
                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {offset: (0).toString()})
            } else {
                npNum = Math.trunc((Main.Instance.RegisterContractualPartnerState.count - 1) / 
                Main.Instance.RegisterContractualPartnerState.pSize)
                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                        offset: (npNum * Main.Instance.RegisterContractualPartnerState.pSize).toString()})
            }

            getContractualPartner()

            Logic.Type.New(Main.Instance.RegisterContractualPartnerState,{pNum: npNum})
        }

}

function RecordsPerPageChanged(text:string) {

    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function ApplyFilter(text:string) {

        let selection:string

        if (parseInt(text) === 0) {
                selection = '1'
        } else if (parseInt(text) === 1) {
                selection = '5'                
        } else if (parseInt(text) === 2) {
                selection = '2'
        }

        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {type_id_filter:selection})
        FirstPage()
}

function ApplySelection(text:string) {
/*
        if (isUserSystem() || isUserMaster()) {
                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {  type_id: text,
                                                                        select_type_id: '0',
                                                                        contractual_partner_id: '0'})
                DbRoutes.getContractualPartnersAllByType()
        } else if (isUserContractualPartner()) {
                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {select_type_id:'0'})
        }
*/
        let selection:string

        if (parseInt(text) === 0) {
                selection = '1'
        } else if (parseInt(text) === 1) {
                selection = '5'                
        } else if (parseInt(text) === 2) {
                selection = '2'
        }

        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {
                type_id: selection, select_type_id: text})
}

export function ManageContractualPartners() {
    if (!isUserSystem() && !isUserMaster()) {
        if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) !== 0) {
                Logic.Type.New (Main.Instance.RegisterContractualPartnerState, {type_id_filter : '0'})
        }
    }

    let contractualPartners = Assets.tContractualPartners

    if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) === 1) {
        contractualPartners = Assets.tOperators
    } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) === 2) {
        contractualPartners = Assets.tGameProviders
    } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) === 3) {
        contractualPartners = Assets.tPaymentProviders
    } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) === 4) {
        contractualPartners = Assets.tComplianceProviders
    } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id_filter) === 5) {
        contractualPartners = Assets.tBrandOwners
    }

    let title: string = ''
    let add: string = ''
    let edit: string = ''
    let cp :string = ''

    if (isUserAdmin()) {
        if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 1) {
                title = Assets.aeOperator
                add = Assets.AddOperator
                edit = Assets.EditOperator
                cp = Assets.Operator
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 2) {
                title = Assets.aeGameProvider
                add = Assets.AddGameProvider
                edit = Assets.EditGameProvider
                cp = Assets.GameProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 3) {
                title = Assets.aePaymentProvider
                add = Assets.AddPaymentProvider
                edit = Assets.EditPaymentProvider
                cp = Assets.PaymentProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 4) {
                title = Assets.aeComplianceProvider
                add = Assets.AddComplianceProvider
                edit = Assets.EditComplianceProvider
                cp = Assets.ComplianceProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 5) {
                title = Assets.aeSiteOwner
                add = Assets.AddSiteOwner
                edit = Assets.EditSiteOwner
                cp = Assets.BrandOwner
        }

    } else {
        if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 1) {
                title = Assets.vOperator
                cp = Assets.Operator
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 2) {
                title = Assets.vGameProvider
                cp = Assets.GameProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 3) {
                title = Assets.vPaymentProvider
                cp = Assets.PaymentProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 4) {
                title = Assets.vComplianceProvider
                cp = Assets.ComplianceProvider
        } else if (parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 5) {
                title = Assets.vSiteOwner
                cp = Assets.BrandOwner
        }
    }

    return (

        <div>
        <br/>
        <div>
                {/*<h3 className = "title-left">{title}</h3>*/}
                {/*isUserAdmin() ?
                <h3>{Assets.rContractualPartner}</h3> : 
                <h3>{Assets.vContractualPartner}</h3>*/}

                <FormSelect
                        narrow = {true}
                        value = {!Main.Instance.RegisterContractualPartnerState.select_type_id ? '0' :
                                Main.Instance.RegisterContractualPartnerState.select_type_id}
                        data = {[0]}
                        ClassName = "form-control select-menu" 
                        // floatRight = {true}
                        w50 = {true}    
                        // o1 = {Assets.aeContractualPartner}
                        o1 = {isUserAdmin() ? Assets.aeOperator : Assets.vOperator}
                        o2 = {isUserAdmin() ? Assets.aeSiteOwner : Assets.vSiteOwner} 
                        o3 = {isUserAdmin() ? Assets.aeGameProvider : Assets.vGameProvider}
                        // o3 = {isUserAdmin() ? Assets.aePaymentProvider : 
                        //                      Assets.vPaymentProvider}
                        // o4 = {isUserAdmin() ? Assets.aeComplianceProvider : 
                        //                      Assets.vComplianceProvider} 
                        onChange = {(text) => {ApplySelection(text)}} 
                        icon = {Assets_.faCompanyClass}
                />
                        {/*o6 = {Assets.Affiliate}*/}

                <div className = "clearfix"/>
        </div>
        <br/>
        {isUserAdmin() ?
        // 1
        <div className = "row admin-users-container">
                <div 
                        className = {Main.Instance.RegisterContractualPartnerState.mode ? 
                                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
                >

                <div className = "register-input">
                        <div className = "row admin-users-row">
                                <h4>
                                        {isUserAdmin() && Main.Instance.RegisterContractualPartnerState.mode ? 
                                        edit + ': ' + Main.Instance.RegisterContractualPartnerState.name_saved : null}
                                        {isUserAdmin() && !Main.Instance.RegisterContractualPartnerState.mode ? 
                                        add : null}
                                        {!isUserAdmin() ? title : null}                        
                                </h4>

                                {/*<h4>
                                        {Main.Instance.RegisterContractualPartnerState.mode ? 
                                        Assets.EditContractualPartner + ': ' + 
                                        Main.Instance.RegisterContractualPartnerState.name_saved :
                                        Assets.AddContractualPartner}
                                </h4>*/}

                                {Main.Instance.RegisterContractualPartnerState.mode ?
                                <ButtonIcon
                                    description = {Assets.discardChanges}
                                    click = {() => gotoInsertMode()} 
                                    ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                                    icon = {Assets_.faCancelButtonClass}
                                /> : void 0}

                        </div>

                        <div className = "row admin-users-row">
                        <div className = "row-info">
                                <span className = "register-input">{cp}</span>
                        </div>
                        <FormInput 
                                value = {!Main.Instance.RegisterContractualPartnerState.name ? '' : 
                                        Main.Instance.RegisterContractualPartnerState.name}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {name:text})}}
                                placeholder = {Assets.pName}
                                icon = {Assets_.faCompanyClass}
                        />

                { isUserSystem() ?
                <FormSelect
                        narrow = {true}
                        value = {!Main.Instance.RegisterContractualPartnerState.master_id ? '0' : 
                                Main.Instance.RegisterContractualPartnerState.master_id}
                        data = {Main.Instance.DatabaseDataState.masterAllStream}
                        ClassName = "form-control"
                        o0 = {Main.Instance.RegisterContractualPartnerState.master_id}
                        o1 = {Assets.EmptyDatabase}
                        o2 = {Assets.MasterQ}

                        onChange = {(text) => {
                                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {master_id:text})}}
                        icon = {Assets_.faMasterClass}
                /> 
                :
                <span className = "label label-primary fl label-table mr">{Main.Instance.LoginState.entity}</span>
                }

                {parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 1 ||
                parseInt(Main.Instance.RegisterContractualPartnerState.type_id) === 5 ?
                    <RadioButton2
                        value = {!Main.Instance.RegisterContractualPartnerState.gg_rm_wallet ? '2' :
                                Main.Instance.RegisterContractualPartnerState.gg_rm_wallet} 
                        ClassName = "input-group-radiobutton3 margin-bottom-sm"
                        title = {Assets.GGRMWalletExplained}
                        text = {Assets.GGRMWalletText}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => {
                                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {gg_rm_wallet:text})}} 
                        
                    /> : null }
                </div>
                <div className = "row admin-users-row">
                        <div className = "row-info">
                        <span className = "register-input">{Assets.MainContactPerson}</span>
                        </div>
                        <FormInput 
                                value = {!Main.Instance.RegisterContractualPartnerState.person ? '':
                                        Main.Instance.RegisterContractualPartnerState.person}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {person:text})}}
                                placeholder = {Assets.pName}
                                icon =  {Assets_.faUserClass}
                        />
                        <FormInput 
                                value = {!Main.Instance.RegisterContractualPartnerState.email ? '' : 
                                Main.Instance.RegisterContractualPartnerState.email}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {email:text})}}
                                placeholder = {Assets.pEmail}
                                icon =  {Assets_.faEmailClass}
                        />
                        <FormInput 
                                value = {!Main.Instance.RegisterContractualPartnerState.phone ? '' :
                                        Main.Instance.RegisterContractualPartnerState.phone}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(text) => {
                                        Logic.Type.New(Main.Instance.RegisterContractualPartnerState, {phone:text})}}
                                placeholder = {Assets.pPhone}
                                icon =  {Assets_.faPhoneClass}
                        />
                        <ButtonIcon
                            description = {Main.Instance.RegisterContractualPartnerState.mode ? 
                                            Assets.saveChanges : 
                                            Assets.rContractualPartner}
                            click={() => handleInsertUpdate()}
                            ClassName = {Main.Instance.RegisterContractualPartnerState.mode ?
                                            'btn btn-default btn-sm btn-plus fr mr' :
                                            'btn btn-primary btn-sm btn-plus fr'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                        </div>

                        </div>
                </div>
        <br/>
        </div>
        : void 0}
        <div className = "control-panel">

                <FormInputNumber
                        description = {Assets.recordsPerPageDesc}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h26 w60"
                        value = {!Main.Instance.RegisterContractualPartnerState.pSize ? '' : 
                        Main.Instance.RegisterContractualPartnerState.pSize.toString()}  
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHamburgerClass}
                        onChange = {(text) => RecordsPerPageChanged(text)}
                />

                <button 
                        className = "ml20 btn btn-primary btn-xs" 
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage()}
                >
                        <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs" 
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage()}
                >
                        <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs" 
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage()}
                >
                        <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                        className = "btn btn-primary btn-xs" 
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage()}
                >
                        <i className = {Assets_.faLastButtonClass}/>
                </button>

                <FormSelect 
                        span = {true}
                        value = {!Main.Instance.RegisterContractualPartnerState.type_id_filter ? '0' : 
                                Main.Instance.RegisterContractualPartnerState.type_id_filter}
                        data = {[0]}
                        ClassName = "control-panel-select"
                        o1 = {Assets.ContractualPartnerTypeAny} 
                        o2 = {Assets.Operator}
                        o3 = {Assets.BrandOwner} 
                        o4 = {Assets.GameProvider}
                        // o4 = {Assets.PaymentProvider}
                        // o5 = {Assets.ComplianceProvider} 
                        onChange = {(text) => ApplyFilter(text)}
                />
                        {/*o6 = {Assets.Affiliate}*/}

                <span className = "label label-success table-label mt5">
                        {Assets.records}
                        {Main.Instance.RegisterContractualPartnerState.count ? 
                        Main.Instance.RegisterContractualPartnerState.count : 0}
                </span>
                <span className = "label label-success table-label mt5">
                        {Assets.showing}
                        {Main.Instance.RegisterContractualPartnerState.count ? 
                        Main.Instance.RegisterContractualPartnerState.pNum * 
                        Main.Instance.RegisterContractualPartnerState.pSize + 1 : 0}
                        {' - '}
                        {Main.Instance.RegisterContractualPartnerState.count ? 
                        ((Main.Instance.RegisterContractualPartnerState.pNum + 1) * 
                        Main.Instance.RegisterContractualPartnerState.pSize > 
                        Main.Instance.RegisterContractualPartnerState.count ? 
                        Main.Instance.RegisterContractualPartnerState.count : 
                        (Main.Instance.RegisterContractualPartnerState.pNum + 1) * 
                        Main.Instance.RegisterContractualPartnerState.pSize) : 0}
                </span>
                <span className = "label label-success table-label mt5">
                        {Assets.page}
                        {Main.Instance.RegisterContractualPartnerState.count ? 
                        Main.Instance.RegisterContractualPartnerState.pNum + 1 :0}{' / '}
                        {Main.Instance.RegisterContractualPartnerState.count ? 
                        Math.trunc((Main.Instance.RegisterContractualPartnerState.count - 1) /
                        Main.Instance.RegisterContractualPartnerState.pSize+1) : 0}
                </span>
        </div>
        {/* console.log (Main.Instance.DatabaseDataState.contractualPartnersStream) */}
        <h3>{contractualPartners}</h3>
        {Main.Instance.DatabaseDataState.contractualPartnersStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.contractualPartnersStream}
            columns = {[
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {minWidth: 150, Header: Assets.ContractualPartner, accessor: 'contractualpartner', headerClassName: 'th-left'},
                {minWidth: 150, Header: Assets.ContractualPartnerType, accessor: 'type_id', headerClassName: 'th-left', Cell: (row) => TableUtils.getContractualPartnerType(row.value)},
                {minWidth: 100, Header: Assets.Master, accessor: 'master', headerClassName: 'th-left'},
                {minWidth: 60, Header: Assets.GGRMWalletTableTitle , accessor: 'ggrmwallet', Cell: (row) => TableUtils.TB(row, toggleActive)},
                {minWidth: 100, Header: '# ' + Assets.SubEntities, accessor: 'get_sub_entities', Cell: (row) => <div style = {{textAlign: 'center'}}>{row.value === null ? 0 : row.value.length}</div>},
                {Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                <div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faUser} />
                        {row.original.person !== null && row.original.person.length > 0 ? row.original.person : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faEnvelope}/>
                        {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faPhone}/>
                        {row.original.telephone !== null && row.original.telephone.length > 0 ? row.original.telephone : Assets.notDefined}
                    </div>
                </div>},
                {show: isUserAdmin(), maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getContractualPartnerCount()}

        {/*Main.Instance.DatabaseDataState.contractualPartnersStream ? 
        StremingTable (Main.Instance.DatabaseDataState.contractualPartnersStream, {
                title: contractualPartners, 
                mode: Main.Instance.RegisterContractualPartnerState.mode,
                editingId: Main.Instance.RegisterContractualPartnerState.id,
                sort: getContractualPartnerCount,
                Sort:Main.Instance.RegisterContractualPartnerState,
                toggleButton:(data) => handleToggle(data),
                delButton:(data) => handleDelete(data), 
                editButton:(data) => handleEdit(data)}) : 
        void 0*/}
        </div>
        )
}