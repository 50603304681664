import { Main } from '../../../Logic/Main'
import * as React from 'react'
import {faDice, faTicketAlt, faGift, faUsers, faUser, faCity, faGlobeEurope, faGifts, faClipboardList, faFileCsv} from '@fortawesome/free-solid-svg-icons'
import {/*mockupRafflePlayers, raffleTicketsRandom,*/ PlayerVisualize, getPlayerCity, getPlayerNickname, getPlayerCountry} from './Dashboard'
import { Logic } from '../../../Logic/Type'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from 'react-table'
import {RaffleParticipants, RaffleData, RaffleResultsData} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {dashboardSmallTableRows, dashboardBigTableRows} from './Dashboard'
import {getRiskManager} from '../RiskComplianceStore/ResponsibleGaming'
/*
export function getPlayersRaffleTickets(): PlayerVisualize[] {
    const players: PlayerVisualize[] = []
    if (Main.Instance.DatabaseDataState.raffleParticipants) {
        let tickets: number = 0
        for (const item of Main.Instance.DatabaseDataState.raffleParticipants) {
            item.data.tickets
        }
        for (let i: number = 0; i < mockupRafflePlayers; i++) {
            players.push ({id: (i + 1), tickets: Math.ceil(Math.random() * raffleTicketsRandom)})
        }
    }
    return players
}
*/

export function getTimestamp(timestamp: string, long?: boolean): string {
    const date = new Date(timestamp)
    return Assets.DaysInWeek[date.getDay()] + ', ' + timestamp.substring(0,10) + ' @ ' + timestamp.substring(11, long ? 19 : 16)
}

function getNTickets2(raffleParticipants: RaffleParticipants[]): number {
    let n: number = 0
    for (const item of raffleParticipants) { n += item.data.tickets }
    return n
}

export function getNParticipants(row: any): JSX.Element {
    if (Main.Instance.DatabaseDataState.raffleActive > -1 && row.index === Main.Instance.DatabaseDataState.raffleActive && Main.Instance.DatabaseDataState.raffleParticipants) {
        return (
            <span title = {Assets.ThisIsTheNextInLineRaffle}>
                {Main.Instance.DatabaseDataState.raffleParticipants.length},&nbsp;
                <FontAwesomeIcon icon = {faTicketAlt}/>&nbsp;
                {getNTickets2(Main.Instance.DatabaseDataState.raffleParticipants)}
            </span>
        )
    } else { return <div/> }
}

export function getWinners(winners?: RaffleResultsData[]): string {
    let s: string = ''
    for (const item of winners) {
        if (s.length > 0) { s += ', ' }
        s += item.id + ' - ' + item.nickname
    }
    return s
}

export function GetWinners(winners?: RaffleResultsData[]): string {
    let s: string = ''
    for (const item of winners) {
        if (s.length > 0) { s += '|' }
        s += item.id + ' - ' + item.nickname
    }
    return s
}

export function getParticipants(participants: RaffleParticipants[]): string {
    let s: string = ''
    for (const item of participants) {
        if (s.length > 0) { s += ', ' }
        s += item.data.id + ' - ' + item.data.nickname
    }
    return s
}

export function getRaffleSelected(expanded: boolean): JSX.Element {
    if (Main.Instance.DatabaseDataState.raffleSelected === undefined || Main.Instance.DatabaseDataState.raffleSelected.data.completed !== 'true' || Main.Instance.DatabaseDataState.raffleSelected.data.participants.length === 0) { return <div/> }
    // console.log (Main.Instance.DatabaseDataState.raffleSelected)
    const scheduledDay = new Date (Main.Instance.DatabaseDataState.raffleSelected.data.timestamp)
    const executionDay = new Date (Main.Instance.DatabaseDataState.raffleSelected.data.executionTimestamp)
    return (
        <div className = "mt30" style = {{textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', height: '100%'/*, maxHeight: '100%', overflowY: 'hidden'*/}}>
            <div><strong>{Assets.Scheduled}</strong>:&nbsp;{Assets.DaysInWeek[scheduledDay.getDay()] + ', ' + Main.Instance.DatabaseDataState.raffleSelected.data.timestamp.substring(0,10) + ' @ ' + Main.Instance.DatabaseDataState.raffleSelected.data.timestamp.substring(11,16)}</div>
            <div><strong>{Assets.Executed}</strong>:&nbsp;{Assets.DaysInWeek[executionDay.getDay()] + ', ' + Main.Instance.DatabaseDataState.raffleSelected.data.executionTimestamp.substring(0,10) + ' @ ' + Main.Instance.DatabaseDataState.raffleSelected.data.executionTimestamp.substring(11,19)}</div>
            <div><strong>{Assets.ExecutedBy}</strong>:&nbsp;{getRiskManager(Main.Instance.DatabaseDataState.raffleSelected.data.executedBy)}</div>
            <div><strong>{Assets.TotalNumberOfTickets}</strong>:&nbsp;{Main.Instance.DatabaseDataState.raffleSelected.data.tickets}</div>
            <div><strong>{Assets.NumberOfParticipants}</strong>:&nbsp;{Main.Instance.DatabaseDataState.raffleSelected.data.participants.length}, ~ {(Main.Instance.DatabaseDataState.raffleSelected.data.tickets/Main.Instance.DatabaseDataState.raffleSelected.data.participants.length).toFixed(2)} {Assets.ticketsPerParticipant}</div>
            {Main.Instance.DatabaseDataState.raffleSelected.data.mainWinners.length === 1 ? 
            <div><strong>{Assets.MainPrizeWinner}</strong>:&nbsp;{Main.Instance.DatabaseDataState.raffleSelected.data.mainWinnerNickname}</div> :
            <div><strong>{Assets.MainPrizeWinners}</strong>:&nbsp;{getWinners(Main.Instance.DatabaseDataState.raffleSelected.data.mainWinners)}</div>}
            <div style = {{overflowY: 'hidden', height:'calc(100% - 170px)'}}>
                <strong>{Assets.SecondaryPrizeWinners}:&nbsp;{Main.Instance.DatabaseDataState.raffleSelected.data.secondaryWinners.length}:&nbsp;</strong>
                {getWinners(Main.Instance.DatabaseDataState.raffleSelected.data.secondaryWinners)}
            </div>       
        </div>
    )
}

export function getRaffleNext(expanded: boolean): JSX.Element {
    // const arr: Raffle[] = Main.Instance.DatabaseDataState.raffle ? Main.Instance.DatabaseDataState.raffle.reverse() : undefined
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
            {Main.Instance.DatabaseDataState.raffle ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.raffle}
                filtered = {[{id: 'data.completed', value: 'false'}]}
                columns = {[
                    {sortable: false, width: 250, Header: <span>{Assets.Timestamp + ' (' + Assets_.UTC + ')'}<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(false)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'data.timestamp', headerClassName: 'th-left', Cell: (row) => getTimestamp(row.value)},
                    {sortable: false, Header: <span><FontAwesomeIcon icon = {faUsers}/>&nbsp;{Assets.Participants}</span>, accessor: 'data.completed', headerClassName: 'th-left', Cell: (row) => getNParticipants(row)},
                ]}
                showPaginationBottom = {false}
                pageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
            /> : void 0}
        </div>
    )
}

function download(previous: boolean) {
    let s: string = ''
    if (previous) {
        s += Assets.Completed + ',' + Assets.Timestamp + ',' + Assets.ExecutionTimestamp  + ',' + Assets.ExecutedBy + ',' + Assets.Participants + ',' + Assets.Tickets + ',' + Assets.MainWinner + ',' + Assets.SecondaryWinners + '\n'
        for (const item of Main.Instance.DatabaseDataState.raffle) {
            if (item.data.completed === 'true') {
                // console.log (item.data)
                s += Assets.Yes + ',' + item.data.timestamp + ',' + item.data.executionTimestamp.substring(0,10) + ' ' + item.data.executionTimestamp.substring(11,19) + ',' + getRiskManager(item.data.executedBy) + ',' + (item.data.participants ? item.data.participants.length : '?') + ',' + item.data.tickets + ',' + item.data.mainWinnerNickname + ',' + GetWinners(item.data.secondaryWinners) + '\n'
            }
        }
    } else {
        s += Assets.Completed + ',' + Assets.Timestamp + '\n'
        for (const item of Main.Instance.DatabaseDataState.raffle) {
            if (item.data.completed === 'false') {
                // console.log (item.data)
                s += Assets.No + ',' + item.data.timestamp + '\n'
            }
        }
    }
    // getRiskManager(Main.Instance.DatabaseDataState.raffleSelected.data.executedBy)
    const filename: string = previous ? Assets.PreviousRaffles : Assets.NextRaffles
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = filename + ' ' + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

export function getRafflePrevious(expanded: boolean): JSX.Element {
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
            {Main.Instance.DatabaseDataState.raffle ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.raffle}
                filtered = {[{id: 'data.completed', value: 'true'}]}
                columns = {[
                    {sortable: false, width: 250, Header: <span>{Assets.Timestamp + ' (' + Assets_.UTC + ')'}<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(true)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'data.timestamp', headerClassName: 'th-left', Cell: (row) => getTimestamp(row.value)},
                    {sortable: false, Header: <span><FontAwesomeIcon icon = {faUsers}/>&nbsp;{Assets.Participants}</span>, accessor: 'data.completed', headerClassName: 'th-left', Cell: (row) => <span>{row.original.data.participants.length},&nbsp;<FontAwesomeIcon icon = {faTicketAlt}/>&nbsp;{row.original.data.tickets}</span>},
                    {sortable: false, width: 25, Header: <span title = {Assets.RaffleDetails}><FontAwesomeIcon icon = {faGift}/></span>, accessor: '', headerClassName: 'th-center', Cell: (row) => <div title = {Main.Instance.DatabaseDataState.raffleSelected && Main.Instance.DatabaseDataState.raffleSelected.data.timestamp === row.original.data.timestamp ? Assets.RaffleIsAlreadySelected : Assets.ShowDetailsForThisRaffle} style = {{textAlign: 'center'}}><button style = {{marginLeft: '-2px'}} disabled = {Main.Instance.DatabaseDataState.raffleSelected && Main.Instance.DatabaseDataState.raffleSelected.data.timestamp === row.original.data.timestamp} className = {Main.Instance.DatabaseDataState.raffleSelected && Main.Instance.DatabaseDataState.raffleSelected.data.timestamp === row.original.data.timestamp ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'} onClick = {() => SelectRaffle(row)}><FontAwesomeIcon icon = {faClipboardList}/></button></div>},
                ]}
                showPaginationBottom = {false}
                pageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
            /> : void 0}
        </div>
    )
}

function SelectRaffle(row: any) {
    // console.log (row.original)
    Logic.Type.New (Main.Instance.DatabaseDataState, {raffleSelected: row.original})
}

export function getRaffleResults(players: PlayerVisualize[], expanded: boolean): JSX.Element {
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
        <ReactTable
            data = {players}
            columns = {[
                {sortable: false, width: 100, Header: <span title = {Assets.PlayerUsername}><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets.Username}</span>, accessor: 'id', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.rafflePrimary ? 700 : 500}}>{getPlayerNickname(row.value)}</span>},
                {sortable: false, width: 110, Header: <span title = {Assets.PlayerCity}><FontAwesomeIcon icon = {faCity}/>&nbsp;&nbsp;{Assets.City}</span>, accessor: 'id', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.rafflePrimary ? 700 : 500}}>{getPlayerCity(row.value)}</span>},
                {sortable: false, width: 23, Header: <FontAwesomeIcon title = {Assets.Country} icon = {faGlobeEurope}/>, accessor: 'id', headerClassName: 'th-left pr0i', Cell: (row) => row.value ? <img src = {getPlayerCountry(row.value)}/> : '-'},
                {sortable: false, Header: <span title = {Assets.Prize}><FontAwesomeIcon icon = {faGifts}/>&nbsp;&nbsp;{Assets.Prize}</span>, accessor: 'win', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.rafflePrimary ? 700 : 500}}>{row.value ? row.value : '-'}</span>},
            ]}
            showPaginationBottom = {false}
            pageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
        />
        </div>
    )
}

function getNTickets(players: PlayerVisualize[]) {
    let tickets: number = 0
    for (const item of players) {
        if (item.data.tickets !== undefined) { tickets += item.data.tickets }
    }
    return tickets
}

function ExecuteRaffle(players: PlayerVisualize[], nTickets: number) {
    // here the actual raffle is performed
    const mainRaffleResults: number[] = []
    const secondaryRaffleResults: number[] = []
    if (Main.Instance.DashboardState.mainRaffleWinnerEnter) {
        mainRaffleResults.push (+Main.Instance.DashboardState.mainRaffleWinner - 1)
    } else {
        for (let i: number = 0; i < Main.Instance.DashboardState.mainRafflePrizes; i++) {
            while (1) {
                const ticket: number = Math.floor(Math.random() * nTickets)
                let playerFound: number = -1
                let ticketCumulative: number = 0
                for (let j: number = 0; j < players.length; j++) {
                    ticketCumulative += players[j].data.tickets
                    if (ticketCumulative > ticket) {
                        playerFound = j
                        break
                    }
                }
                for (const item of mainRaffleResults) {
                    if (item === playerFound) {
                        playerFound = -1
                        break
                    }
                }
                if (playerFound > -1) {
                    mainRaffleResults.push(playerFound)
                    break
                }
            }
        }
    }

    for (let i: number = 0; i < Main.Instance.DashboardState.secondaryRafflePrizes; i++) {
        while (1) {
            const ticket: number = Math.floor(Math.random() * nTickets)
            let playerFound: number = -1
            let ticketCumulative: number = 0
            for (let j: number = 0; j < players.length; j++) {
                ticketCumulative += players[j].data.tickets
                if (ticketCumulative > ticket) {
                    playerFound = j
                    break
                }
            }
            for (const item of mainRaffleResults) {
                if (item === playerFound) {
                    playerFound = -1
                    break
                }
            }

            if (playerFound > -1) {
                for (const item of secondaryRaffleResults) {
                    if (item === playerFound) {
                        playerFound = -1
                        break
                    }
                }    
            }

            if (playerFound > -1) {
                secondaryRaffleResults.push(playerFound)
                break
            }
        }
    }
    Logic.Type.New(Main.Instance.DashboardState, {
        mainRaffleResults: mainRaffleResults.length === 1 ? mainRaffleResults : mainRaffleResults.sort(Sort),
        secondaryRaffleResults: secondaryRaffleResults.length === 1 ? secondaryRaffleResults : secondaryRaffleResults.sort(Sort),
    })
}
function Sort(a, b) {return a - b}

export function getRaffleResultsMain(): string {
    let s: string = ''
    for (let i: number = 0; i < Main.Instance.DashboardState.mainRaffleResults.length; i++) {
        if (i > 0) { s += ', ' }
        // s += (Main.Instance.DashboardState.mainRaffleResults[i] + 1)
        s += Main.Instance.DatabaseDataState.raffleParticipants[Main.Instance.DashboardState.mainRaffleResults[i]].data.id + ' - ' +
             Main.Instance.DatabaseDataState.raffleParticipants[Main.Instance.DashboardState.mainRaffleResults[i]].data.nickname
    }
    return s
}

function getRaffleResultsData(array: number[]): RaffleResultsData[] {
    const results: RaffleResultsData[] = []
    for (let i: number = 0; i < array.length; i++) {
        results.push({
            id: Main.Instance.DatabaseDataState.raffleParticipants[array[i]].data.id,
            nickname: Main.Instance.DatabaseDataState.raffleParticipants[array[i]].data.nickname,
            player_id: Main.Instance.DatabaseDataState.raffleParticipants[array[i]].data.player_id,
        })

    }
    return results
}


export function getRaffleResultsSecondary(): string {
    let s: string = ''
    for (let i: number = 0; i < Main.Instance.DashboardState.secondaryRaffleResults.length; i++) {
        if (i > 0) { s += ', ' }
        s += Main.Instance.DatabaseDataState.raffleParticipants[Main.Instance.DashboardState.secondaryRaffleResults[i]].data.id + ' - ' +
             Main.Instance.DatabaseDataState.raffleParticipants[Main.Instance.DashboardState.secondaryRaffleResults[i]].data.nickname
    }
    return s    
}

function getManualDrawOptions(players: PlayerVisualize[]): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.NotSelected}</option>)
    for (let i: number = 0; i < players.length; i++) {
        // title = {'PlayerID: ' + players[i].data.player_id}
        options.push (<option key = {(i + 1).toString()} value = {(i + 1).toString()}>{players[i].data.id}&nbsp;-&nbsp;{players[i].data.nickname}&nbsp;({players[i].data.tickets}&nbsp;{Assets.Tickets.toLowerCase()})</option>)
    }
    return options
}

export function getRaffleComponent(players: PlayerVisualize[], expanded: boolean): JSX.Element {
    const nTickets: number = getNTickets(players)
    return (
        <div style = {{marginTop: '36px', paddingLeft: '5px', paddingRight: '5px'}}>
            <div className = "clearfix" style = {{textAlign: 'left'}}>
                <FontAwesomeIcon title = {Assets.MainRafflePrize} icon = {faGift} size = "lg"/>&nbsp;&nbsp;
                <input disabled = {Main.Instance.DashboardState.mainRaffleWinnerEnter} title = {Assets.MainRafflePrize} style = {{height: '22px', paddingRight: '0px', width: '50px', marginRight: '5px'}} type = "number" min = {1} value = {Main.Instance.DashboardState.mainRafflePrizes} onChange = {(e) => Logic.Type.New(Main.Instance.DashboardState, {mainRafflePrizes: +e.target.value})}/> 
                <FontAwesomeIcon title = {Assets.SecondaryRafflePrize} icon = {faGift}/>&nbsp;&nbsp;
                <input title = {Assets.SecondaryRafflePrize} style = {{height: '22px', paddingRight: '0px', width: '70px', marginRight: '5px'}} type = "number" min = {1} value = {Main.Instance.DashboardState.secondaryRafflePrizes} onChange = {(e) => Logic.Type.New(Main.Instance.DashboardState, {secondaryRafflePrizes: +e.target.value})}/> 
                <FontAwesomeIcon title = {players.length + ' ' + Assets.Participants.toLowerCase()} icon = {faUsers} size = "lg"/>&nbsp;{players.length}&nbsp;&nbsp;
                <FontAwesomeIcon title = {nTickets + ' ' + Assets.Tickets.toLowerCase()} icon = {faTicketAlt} size = "lg"/>&nbsp;{nTickets}

                <button disabled = {Main.Instance.DashboardState.mainRafflePrizes < 1 || Main.Instance.DashboardState.secondaryRafflePrizes < 1 || Main.Instance.DashboardState.mainRaffleWinnerEnter && +Main.Instance.DashboardState.mainRaffleWinner < 1/*|| Main.Instance.DashboardState.mainRaffleWinnerEnter && Main.Instance.DashboardState.mainRaffleWinner > players.length*/} onClick = {() => ExecuteRaffle(players, nTickets)} type = "button" className = "btn btn-danger btn-xs mr0 fr">
                    <FontAwesomeIcon icon = {faDice}/>&nbsp;{Assets.Execute}
                </button>
            </div>

            <div style = {{textAlign: 'left', marginTop: '3px'}}>
                <div>
                    <span className = "bold">{Assets.Results}:</span>&nbsp;
                    <FontAwesomeIcon title = {Assets.MainRafflePrize + ' (' + (Main.Instance.DashboardState.mainRaffleWinnerEnter ? 1 : Main.Instance.DashboardState.mainRaffleResults.length) + (Main.Instance.DashboardState.mainRaffleWinnerEnter || Main.Instance.DashboardState.mainRaffleResults.length === 1 ? ' ' + Assets.Prize.toLowerCase() + ')' : ' ' + Assets.Prizes.toLowerCase() + ')')} icon = {faGift} size = "lg"/>&nbsp;
                    <button
                        title = {Assets.t3}
                        type = "button"
                        className = {Main.Instance.DashboardState.mainRaffleWinnerEnter ? 'btn btn-success btn-xs mr5px' : 'btn btn-danger btn-xs mr5px'}
                        onClick = {() => Logic.Type.New (Main.Instance.DashboardState, {mainRaffleResults: [], secondaryRaffleResults: [], mainRafflePrizes: 1, mainRaffleWinnerEnter: !Main.Instance.DashboardState.mainRaffleWinnerEnter})}
                    >
                        {Assets.ManualDraw}
                    </button>
                    {Main.Instance.DashboardState.mainRaffleWinnerEnter ?
                    <select
                        disabled = {Main.Instance.DashboardState.secondaryRaffleResults !== undefined && Main.Instance.DashboardState.secondaryRaffleResults.length > 0}
                        style = {{width: '210px', background: '#fff', height: '22px'}}
                        onChange = {(e) => Logic.Type.New(Main.Instance.DashboardState, {mainRaffleWinner: e.target.value})}
                        value = {Main.Instance.DashboardState.mainRaffleWinner ? Main.Instance.DashboardState.mainRaffleWinner : '0'}
                    >
                        {getManualDrawOptions(players)}
                    </select>
                    /*<input title = {Assets.MainRafflePrize} style = {{height: '20px', paddingRight: '0px', width: '50px', marginRight: '5px'}} type = "number" min = {1} value = {Main.Instance.DashboardState.mainRaffleWinner ? Main.Instance.DashboardState.mainRaffleWinner : ''} onChange = {e => Logic.Type.New(Main.Instance.DashboardState, {mainRaffleWinner: +e.target.value})}/>*/
                    : Main.Instance.DashboardState.mainRaffleResults && Main.Instance.DashboardState.mainRaffleResults.length > 0 ? getRaffleResultsMain() : void 0}
                </div>
                {Main.Instance.DashboardState.secondaryRaffleResults && Main.Instance.DashboardState.secondaryRaffleResults.length > 0 ?
                <div>
                    {Main.Instance.DatabaseDataState.raffleActive === -1 ? void 0 :
                    <div className = "clearfix">
                        <button
                            style = {{width: '70%', marginRight: '1%'}}
                            onClick = {() => InsertRaffle()}
                            className = "dbl fl btn btn-primary btn-xs mt5"
                        >
                            {Assets.InsertTheseResultsForTheCurrentRaffle}
                        </button>
                        <button
                            style = {{width: '29%'}}
                            onClick = {() => CancelRaffle()}
                            className = "dbl fl btn btn-danger btn-xs mt5 mr0"
                        >
                            {Assets.Cancel + ' ' + Assets.Raffle.toLowerCase()}
                        </button>
                    </div>}
                    <FontAwesomeIcon
                        title = {Assets.SecondaryRafflePrize + ' (' + Main.Instance.DashboardState.secondaryRaffleResults.length + (Main.Instance.DashboardState.secondaryRaffleResults.length === 1 ? ' ' + Assets.Prize.toLowerCase() + ')' : ' ' + Assets.Prizes.toLowerCase() + ')')}
                        icon = {faGift}
                    />
                    &nbsp;{getRaffleResultsSecondary()}
                </div> : void 0}
            </div>
        </div>
    )
}

function CancelRaffle() {
    Logic.Type.New (Main.Instance.DashboardState, {
        mainRaffleResults: undefined,
        secondaryRaffleResults: undefined,
        mainRaffleWinner: '0'
    })
}

function InsertRaffle() {
    // here we need to call wsBasicUpdate with the correct arguments
    const data: RaffleData = Object.assign({}, Main.Instance.DatabaseDataState.raffle[Main.Instance.DatabaseDataState.raffleActive].data)
    data.completed = 'true'
    data.mainWinners = getRaffleResultsData(Main.Instance.DashboardState.mainRaffleResults)
    data.secondaryWinners = getRaffleResultsData(Main.Instance.DashboardState.secondaryRaffleResults)
    data.participants = Object.assign([], Main.Instance.DatabaseDataState.raffleParticipants)
    data.tickets = getNTickets2(Main.Instance.DatabaseDataState.raffleParticipants)
    data.executedBy = Main.Instance.LoginState.id
    data.executionTimestamp = (new Date()).toISOString()
    data.mainWinnerNickname = Main.Instance.DatabaseDataState.raffleParticipants[Main.Instance.DashboardState.mainRaffleResults[0]].data.nickname
    DbRoutes.wsBasicUpdate(Config.amRaffle, Main.Instance.DatabaseDataState.raffle[Main.Instance.DatabaseDataState.raffleActive].id, data)
/*
    participants?: RaffleParticipantsData[]
    tickets?: number
    executedBy?: number
    executionTimestamp: string
    mainWinnerNickname: string
*/
}