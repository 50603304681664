// import {Main} from './Main'
// tslint:disable-next-line: no-namespace
export namespace Logic {

    export class Type {
        // tslint:disable-next-line:no-shadowed-variable
        public static New<Type extends Logic.Type>(value: Type, state: Partial<Type>, nolog?: boolean): Type {
            if (value == state) { return value }
            const t = Object.assign(new (value.constructor as Type.Class<Type>)(value.model), value, state) as Type
            for (const m in t as {} as {[name: string]: Logic.Type | void}) {
                const x = (t as {} as {[name: string]: Logic.Type | void})[m]
                if (x instanceof Type) { Type.Add(t, x) }
            }
            t.model.Update(t)
/*            
            if (t.model.Update) {
                t.model.Update(t)
                // console.log (t)
            } else {
                // console.log ('this should not happen')
                // console.log (t)
            }
*/
/*
            if (nolog) {

            } else this.Log (value, state)
*/
            return t
        }
/*
        static Log<TypE extends Logic.Type>(value: TypE, state: Partial<TypE>): Type {
            if (value == state) return value
            let t = Object.assign(new (value.constructor as Type.Class<TypE>)(value.model), value, state) as TypE
            for (let m in t as {} as {[name: string]: Logic.Type | void}) {
                let x = (t as {} as {[name: string]: Logic.Type | void})[m]
                if (x instanceof Type) Type.Add(t, x)
            }
            const lastActionTime: number = new Date().getTime()
            if (lastActionTime - Main.Instance.DatabaseDataState.lastActionTime > 1000) {
                t.model.Update(t)
                this.New (Main.Instance.DatabaseDataState, {lastActionTime}, true)
            }
            return t
        }
*/
        // tslint:disable-next-line:no-shadowed-variable
        public static Add<Host extends Logic.Type, Type extends Logic.Type>(owner: Host, field: Type): Type {
            const t = new class implements Type.Model<Type> {
                public Update(value: Type): void {
                    for (const m in owner as {} as {[name: string]: Logic.Type | void}) {
                        const x = (owner as {} as {[name: string]: Logic.Type | void})[m]
                        if (x == field) { Type.New(owner, {[m]: value} as {} as Partial<Host>) }
                    }
                }
            }()
            field.model = t
            // console.log (field)
            return field
        }

        private static none = new Type(new class implements Type.Model<Type> {
            public Update(value: Type): void {
            }
        }())

        private model: Type.Model<Type>

        constructor(model?: Type.Model<Type>) {
            this.model = model ? model : Type.none.model
        }
    }

    export namespace Type {
        // tslint:disable-next-line:no-shadowed-variable
        export interface Model<Type extends Logic.Type> {
            Update(value: Type): void
        }

        // tslint:disable-next-line:no-shadowed-variable
        export type Class<Type extends Logic.Type> = new(model: Type.Model<Type>) => Type
    }
}
