import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput,
        RadioButton3,
        FormInputNumber, 
        FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {
    Select,
    SelectValue2,
    SelectCurrency,
    SelectCurrency2,
    isUserSystem,
    isUserMaster,
    isUserSubEntity,
    isUserGameProvider,
    isUserOperator,
    isUserSiteOwner,
    utilsTimeValidEmptyNotValid,
    AllowDecimalCharactersOnly,
    gotoTop
} from '../../../Logic/Utils' 
import {DateRangePicker} from 'react-dates'
import * as moment from 'moment'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

function PartialAdvanced(): string {
    if (Main.Instance.TournamentState.min_rounds && Main.Instance.TournamentState.min_rounds.length > 0 ||
        Main.Instance.TournamentState.max_rounds && Main.Instance.TournamentState.max_rounds.length > 0 ||
        parseInt(Main.Instance.TournamentState.notify_players) > 0 ||
        Main.Instance.TournamentState.bet_amount_min && Main.Instance.TournamentState.bet_amount_min[0] &&
        Main.Instance.TournamentState.bet_amount_min[0].length > 0 ||
        Main.Instance.TournamentState.bet_amount_max && Main.Instance.TournamentState.bet_amount_max[0] &&
        Main.Instance.TournamentState.bet_amount_max[0].length > 0 ||
        Main.Instance.TournamentState.level_min > 0 ||
        Main.Instance.TournamentState.level_max > 0 ||
        Main.Instance.TournamentState.sublevel_min > 0 ||
        Main.Instance.TournamentState.sublevel_max > 0
    ) { return ' partial' } else { return '' }
}

function PartialRecurring(): string {
    // from below
    let partial: boolean = false
    for (let i: number = 0; !partial && i < Main.Instance.TournamentState.prizes.length; i++) {
        for (let j: number = 0; !partial && j < Main.Instance.TournamentState.prizes[i].length; j++) {
            if (!partial && Main.Instance.TournamentState.prizes[i][j]['wrq']) {
                partial = true
            } 

            if (!Main.Instance.TournamentState.prizes[i][j]['amount']) { continue }
            for (let k: number = 0; !partial && k < Main.Instance.TournamentState.prizes[i][j]['amount'].length; k++) {
                if (Main.Instance.TournamentState.prizes[i][j]['amount'][k] &&
                    Main.Instance.TournamentState.prizes[i][j]['amount'][k].length > 0) {
                        partial = true
                }
            }
        }
    }

    if (partial) { return ' partial' }

    if (parseInt(Main.Instance.TournamentState.recurringTypeRecurring) > 0 ||
        Main.Instance.TournamentState.recurring_from_time[0] && 
        Main.Instance.TournamentState.recurring_from_time[0].length > 0 ||
        Main.Instance.TournamentState.recurring_to_time[0] && 
        Main.Instance.TournamentState.recurring_to_time[0].length > 0 ||
        Main.Instance.TournamentState.recurring_from_time[1] && 
        Main.Instance.TournamentState.recurring_from_time[1].length > 0 ||
        Main.Instance.TournamentState.recurring_to_time[1] && 
        Main.Instance.TournamentState.recurring_to_time[1].length > 0 ||
        Main.Instance.TournamentState.recurring_from_time[2] && 
        Main.Instance.TournamentState.recurring_from_time[2].length > 0 ||
        Main.Instance.TournamentState.recurring_to_time[2] && 
        Main.Instance.TournamentState.recurring_to_time[2].length > 0 ||
        Main.Instance.TournamentState.recurring_from_time[3] && 
        Main.Instance.TournamentState.recurring_from_time[3].length > 0 ||
        Main.Instance.TournamentState.recurring_to_time[3] && 
        Main.Instance.TournamentState.recurring_to_time[3].length > 0 ||
        Main.Instance.TournamentState.recurring_from_time[4] && 
        Main.Instance.TournamentState.recurring_from_time[4].length > 0 ||
        Main.Instance.TournamentState.recurring_to_time[4] && 
        Main.Instance.TournamentState.recurring_to_time[4].length > 0 ||
        Main.Instance.TournamentState.total_max0 && Main.Instance.TournamentState.total_max0[0] &&
        Main.Instance.TournamentState.total_max0[0].length > 0 ||
        Main.Instance.TournamentState.total_max1 && Main.Instance.TournamentState.total_max1[0] &&
        Main.Instance.TournamentState.total_max1[0].length > 0 ||
        Main.Instance.TournamentState.total_max2 && Main.Instance.TournamentState.total_max2[0] &&
        Main.Instance.TournamentState.total_max2[0].length > 0 ||
        Main.Instance.TournamentState.total_max3 && Main.Instance.TournamentState.total_max3[0] &&
        Main.Instance.TournamentState.total_max3[0].length > 0 ||
        Main.Instance.TournamentState.total_max4 && Main.Instance.TournamentState.total_max4[0] &&
        Main.Instance.TournamentState.total_max4[0].length > 0
    ) { return ' partial' } else { return '' }
}
/*
function PartialPrizepool(): string {
    let partial: boolean = false
    for (let i: number = 0; !partial && i < Main.Instance.TournamentState.prizes.length; i++) {
        for (let j: number = 0; !partial && j < Main.Instance.TournamentState.prizes[i].length; j++) {
            if (!partial && Main.Instance.TournamentState.prizes[i][j]['wrq']) {
                partial = true
            } 

            if (!Main.Instance.TournamentState.prizes[i][j]['amount']) continue
            for (let k: number = 0; !partial && k < Main.Instance.TournamentState.prizes[i][j]['amount'].length; k++) {
                if (Main.Instance.TournamentState.prizes[i][j]['amount'][k] &&
                    Main.Instance.TournamentState.prizes[i][j]['amount'][k].length > 0) {
                        partial = true
                }
            }
        }
    }

    if (partial) return ' partial'
    else return ''
}
*/
function GetLevelIconText(level:number):string {
    if (level === 0) { return '0' }

    return Main.Instance.DatabaseDataState.levelsAllDetailsStream[level - 1]['name'].charAt(0)
}

function LevelMinChanged() {
    if(Main.Instance.TournamentState.level_min === Main.Instance.DatabaseDataState.levelsAllDetailsStream.length) {
        Logic.Type.New(Main.Instance.TournamentState, {level_min: 0})
    } else if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[
        Main.Instance.TournamentState.level_min]['final_level'].toString() === 'true') {
        Logic.Type.New(Main.Instance.TournamentState, {
            level_min: Main.Instance.TournamentState.level_min + 1, sublevel_min: 0})
    } else { Logic.Type.New(Main.Instance.TournamentState, {level_min: Main.Instance.TournamentState.level_min + 1}) }
}

function LevelMaxChanged() {
    if(Main.Instance.TournamentState.level_max === Main.Instance.DatabaseDataState.levelsAllDetailsStream.length) {
        Logic.Type.New(Main.Instance.TournamentState, {level_max: 0})
    } else if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[
        Main.Instance.TournamentState.level_max]['final_level'].toString() === 'true') {
        Logic.Type.New(Main.Instance.TournamentState, {
            level_max: Main.Instance.TournamentState.level_max + 1, sublevel_max: 0})
    } else { Logic.Type.New(Main.Instance.TournamentState, {level_max: Main.Instance.TournamentState.level_max + 1}) }
}

function SubLevelMinChanged(num: number) {
    if (num > -1 && num <= 100 && Main.Instance.TournamentState.level_min !== 0) {
        Logic.Type.New(Main.Instance.TournamentState, {sublevel_min:num})  
    }
}

function SubLevelMaxChanged(num: number) {
    if (num > -1 && num <= 100 && Main.Instance.TournamentState.level_max !== 0) {
        Logic.Type.New(Main.Instance.TournamentState, {sublevel_max:num})
    }
}

function SelectTournamentType(text:string) {
    if (parseInt(text) === 1 || parseInt(text) === 3) {
        const vendors: number[] = []
        for (let i: number = 0; i < Main.Instance.TournamentState.Vendor.length; i++) {
            vendors.push (i + 1)
        }
        const games: number[] = []
        for (let i: number = 0; i < Main.Instance.TournamentState.Game.length; i++) {
            games.push (i + 1)
        }

        Logic.Type.New (Main.Instance.TournamentState, {
            games, vendors, tournamentType: text})
    } else { Logic.Type.New(Main.Instance.TournamentState, {tournamentType: text}) }
}

function AllowNumberCharactersOnly(text: string, type: number, which0: number, which: number, which2?: number) {
    let proceed: boolean = true
    let dot: number = 0
    // console.log (which0 + ' ' + which + ' ' + which2)
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '.'
            // && text.charAt(i) !== ':'
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
            // && text.charAt(i) !== ' '
        ) { proceed = false }

        if (text.charAt(i) === '.') { dot++ }

        if (dot > 1) {
            proceed = false
        }
    }

    if (proceed) {
        if (type === 0) {
            let anyValue: boolean = false
            const opt_in_cost: string[] = Object.assign([], Main.Instance.TournamentState.opt_in_cost)
            opt_in_cost[which] = text
            for (const item of opt_in_cost) {
                if (item && item.length > 0) {
                    anyValue = true
                    break;
                }
            }

            Logic.Type.New(Main.Instance.TournamentState, {opt_in_cost, opt_in: anyValue})
        } else if (type === 1) {
            const bet_amount_min: string[] = Object.assign([], Main.Instance.TournamentState.bet_amount_min)
            bet_amount_min[which] = text
            Logic.Type.New(Main.Instance.TournamentState, {bet_amount_min})
        } else if (type === 2) {
            const bet_amount_max: string[] = Object.assign([], Main.Instance.TournamentState.bet_amount_max)
            bet_amount_max[which] = text
            Logic.Type.New(Main.Instance.TournamentState, {bet_amount_max})
        } else if (type === 3 && which2 !== undefined) {
            if (which2 === 0) {
                const total_max0: string[] = Object.assign([], Main.Instance.TournamentState.total_max0)
                total_max0[which] = text
                Logic.Type.New(Main.Instance.TournamentState, {total_max0})
            } else if (which2 === 1) {
                const total_max1: string[] = Object.assign([], Main.Instance.TournamentState.total_max1)
                total_max1[which] = text
                Logic.Type.New(Main.Instance.TournamentState, {total_max1})
            } else if (which2 === 2) {
                const total_max2: string[] = Object.assign([], Main.Instance.TournamentState.total_max2)
                total_max2[which] = text
                Logic.Type.New(Main.Instance.TournamentState, {total_max2})
            } else if (which2 === 3) {
                const total_max3: string[] = Object.assign([], Main.Instance.TournamentState.total_max3)
                total_max3[which] = text
                Logic.Type.New(Main.Instance.TournamentState, {total_max3})
            } else if (which2 === 4) {
                const total_max4: string[] = Object.assign([], Main.Instance.TournamentState.total_max4)
                total_max4[which] = text
                Logic.Type.New(Main.Instance.TournamentState, {total_max4})
            }
        } else if (type === 4 && which2 !== undefined) {
            const prizes: any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
            prizes[which0][which2]['amount'][which] = text
            Logic.Type.New(Main.Instance.TournamentState, {prizes})
        } else if (type === 5) {
            const prizes: any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
            prizes[which0][which]['amount'][0] = text
            Logic.Type.New(Main.Instance.TournamentState, {prizes})
        }
    }
}
/*
function SelectVendors(text: string) {
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.TournamentState, {casino_vendors: text})
    } else if (parseInt(text) === 1) {
        let vendors: number[] = []
        for (let i: number = 0; i < Main.Instance.TournamentState.Vendor.length; i++) {
            vendors.push (i + 1)
        }
        Logic.Type.New (Main.Instance.TournamentState, {vendors: vendors, casino_vendors: text})
    } else if (parseInt(text) === 2) {
        Logic.Type.New (Main.Instance.TournamentState, {vendors: [], casino_vendors: text})
    }
}

function SelectGames(text: string) {
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.TournamentState, {casino_games: text})
    } else if (parseInt(text) === 1) {
        let games: number[] = []
        for (let i: number = 0; i < Main.Instance.TournamentState.Game.length; i++) {
            games.push (i + 1)
        }
        Logic.Type.New (Main.Instance.TournamentState, {games: games, casino_games: text})
    } else if (parseInt(text) === 2) {
        Logic.Type.New (Main.Instance.TournamentState, {games: [], casino_games: text})
    }
}
*/

function ModeRecurring() {
    Logic.Type.New (Main.Instance.TournamentState, {modeRecurring: !Main.Instance.TournamentState.modeRecurring})
}

function ModeAdvanced() {
    Logic.Type.New (Main.Instance.TournamentState, {modeAdvanced: !Main.Instance.TournamentState.modeAdvanced})
}
/*
function ModePrizePool() {
    Logic.Type.New (Main.Instance.TournamentState, {modePrizepool: !Main.Instance.TournamentState.modePrizepool})
}
*/
function SelectRecurringTypeRecurring(text: string) {
    const which0 = parseInt(text)
    const Prizes: any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
    if (Prizes[which0].length === 0) {
    Prizes[which0].push({
        tr: 0,
        fpr: 1,
        lpr: 1,
        wrq: 0,
        tieg: 0,
        games: [],
        game: '0',
        amount: [],
    })
    }
    Logic.Type.New(Main.Instance.TournamentState, {prizes: Prizes, recurringTypeRecurring: text, selection: 0})
    DbRoutes.handleLeaderboard()
}
/*
function SelectRecurringTypeRecurring(text: string) {
    Logic.Type.New(Main.Instance.TournamentState, {recurringTypeRecurring: text})
}
*/
function SelectRecurringType(text: string/*, which: number*/) {

    const RecurringOptions: SelectValue2[] = []
    const days_in_a_week: boolean[] = [false, false, false, false, false, false, false]
    for (const item of Main.Instance.TournamentState.days_in_a_week) {
        days_in_a_week[item - 1] = true
    }

    if (Main.Instance.TournamentState.from_date !== null && Main.Instance.TournamentState.to_date !== null) {

        let count: number = 1
        const date: moment.Moment = Main.Instance.TournamentState.from_date.clone()
        if (parseInt(text) === 1) { // daily
            while (date.format(Assets_.dateDisplayFormat) <= Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                
                let time1: boolean = true
                let time2: boolean = true
                if (date.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.from_date.format(Assets_.dateDisplayFormat)) {
                    // console.log ('time1 = false, ' + date.format(Assets_.dateDisplayFormat))
                    if (Main.Instance.TournamentState.recurring_from_time[0] < 
                        Main.Instance.TournamentState.from_time[0]) {
                        time1 = false
                    }
                }

                if (date.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    if (Main.Instance.TournamentState.recurring_to_time[0] >
                        Main.Instance.TournamentState.to_time[0]) {
                        time2 = false
                    }
                }

                if (days_in_a_week[date.isoWeekday() - 1]) {
                    RecurringOptions.push ({
                        id: count.toString(),
                        name: date.format(Assets_.dateDisplayFormatDayDMY),
                        value1: date.format(Assets_.dateDisplayFormat),
                        value2: date.format(Assets_.dateDisplayFormat),
                        time1,
                        time2,
                    })
                    count++
                }
                date.add(1, 'days')
            }

        } else if (parseInt(text) === 2) { // weekly
            while (date.format(Assets_.dateDisplayFormat) <= Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                let time1: boolean = true
                let time2: boolean = true
                let date2: moment.Moment = date.clone()
                if (count > 1) { date2.add(6, 'days') } else { date2.add(7 - date2.isoWeekday(), 'days') }
                if (date2.format(Assets_.dateDisplayFormat) > Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    date2 = Main.Instance.TournamentState.to_date.clone()
                }

                if (date.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.from_date.format(Assets_.dateDisplayFormat)) {
                    // console.log ('time1 = false, ' + date.format(Assets_.dateDisplayFormat))
                    if (Main.Instance.TournamentState.recurring_from_time[0] <
                        Main.Instance.TournamentState.from_time[0]) {
                        time1 = false
                    }
                }

                if (date2.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    if (Main.Instance.TournamentState.recurring_to_time[0] > Main.Instance.TournamentState.to_time[0]) {
                        time2 = false
                    }
                }

                RecurringOptions.push ({
                    id: count.toString(),
                    name: date.format(Assets_.dateDisplayFormatWeekDMY),
                    value1: date.format(Assets_.dateDisplayFormat),
                    value2: date2.format(Assets_.dateDisplayFormat),
                    time1,
                    time2,
                })

                if (count > 1) { date.add(7, 'days') } else { date.add(7 - date.isoWeekday() + 1, 'days') }
                count++
            }
        } else if (parseInt(text) === 3) { // monthly
            while (date.format(Assets_.dateDisplayFormat) <= Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                let time1: boolean = true
                let time2: boolean = true

                let date2: moment.Moment = date.clone()
                date2.endOf('month')
                if (date2.format(Assets_.dateDisplayFormat) > Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    date2 = Main.Instance.TournamentState.to_date.clone()
                }

                if (date.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.from_date.format(Assets_.dateDisplayFormat)) {
                    // console.log ('time1 = false, ' + date.format(Assets_.dateDisplayFormat))
                    if (Main.Instance.TournamentState.recurring_from_time[0] < 
                        Main.Instance.TournamentState.from_time[0]) {
                        time1 = false
                    }
                }

                if (date2.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    if (Main.Instance.TournamentState.recurring_to_time[0] > Main.Instance.TournamentState.to_time[0]) {
                        time2 = false
                    }
                }

                RecurringOptions.push ({
                    id: count.toString(),
                    name: date.format(Assets_.dateDisplayFormatMonthYear),
                    value1: date.format(Assets_.dateDisplayFormat),
                    value2: date2.format(Assets_.dateDisplayFormat),
                    time1,
                    time2,
                })

                date.add(1, 'months').startOf('month');
                count++
            }
        } else if (parseInt(text) === 4) { // yearly
            while (date.format(Assets_.dateDisplayFormat) <= Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                let time1: boolean = true
                let time2: boolean = true

                let date2: moment.Moment = date.clone()
                date2.endOf('year')
                if (date2.format(Assets_.dateDisplayFormat) > Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    date2 = Main.Instance.TournamentState.to_date.clone()
                }

                if (date.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.from_date.format(Assets_.dateDisplayFormat)) {
                    // console.log ('time1 = false, ' + date.format(Assets_.dateDisplayFormat))
                    if (Main.Instance.TournamentState.recurring_from_time[0] <
                        Main.Instance.TournamentState.from_time[0]) {
                        time1 = false
                    }
                }

                if (date2.format(Assets_.dateDisplayFormat) === Main.Instance.TournamentState.to_date.format(Assets_.dateDisplayFormat)) {
                    if (Main.Instance.TournamentState.recurring_to_time[0] >
                        Main.Instance.TournamentState.to_time[0]) {
                        time2 = false
                    }
                }

                RecurringOptions.push ({
                    id: count.toString(),
                    name: date.format('YYYY'),
                    value1: date.format(Assets_.dateDisplayFormat),
                    value2: date2.format(Assets_.dateDisplayFormat),                    
                    time1,
                    time2,
                })

                date.add(1, 'years').startOf('year');
                count++
            }
        }
    }

    Logic.Type.New(Main.Instance.TournamentState, {
        recurringType: text,
        RecurringOptions,
        validFrom: '',
        validTo: '',
    })
}

function SelectRecurringOption(text: string) {
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.TournamentState, {recurring_option: text})
    } else {
        Logic.Type.New(Main.Instance.TournamentState, {
            recurring_option: text,
            validFrom: Main.Instance.TournamentState.RecurringOptions[parseInt(text) - 1].value1,
            validTo: Main.Instance.TournamentState.RecurringOptions[parseInt(text) - 1].value2,
            time1: Main.Instance.TournamentState.RecurringOptions[parseInt(text) - 1].time1,
            time2: Main.Instance.TournamentState.RecurringOptions[parseInt(text) - 1].time2,            
        })

        // handleLeaderboard(Main.Instance.TournamentState.tournament)
    }
}

function AllowNaturalCharactersOnly(text: string, type: number) {
    let proceed: boolean = true

    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
        ) { proceed = false }
    }

    if (proceed) {
        if (type === 0) {
            Logic.Type.New(Main.Instance.TournamentState, {min_rounds: text})
        } else if (type === 1) {
            Logic.Type.New(Main.Instance.TournamentState, {max_rounds: text})
        }
    }
}

function AllowTimeCharactersOnly(text: string, which: number, which2?: number) {
    let proceed: boolean = text.length <= 8 ? true : false
    let dot: number = 0
    let colon: number = 0
    let space: number = 0
    let first: number
    let second: number
    
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '.'
            && text.charAt(i) !== ':'
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
            && text.charAt(i) !== ' '
        ) { proceed = false }

        if (text.charAt(i) === '.') { dot++ } else if (text.charAt(i) === ':') { colon++ } else if (text.charAt(i) === ' ') { space++ }
        if (dot > 2 || colon > 2 || space > 2 || dot * colon > 0 || space * colon > 0 || space * dot > 0) {
            proceed = false
        }

        if (proceed && (text.charAt(i) === '.' || text.charAt(i) === ':' || text.charAt(i) === ' ')) {
            if (first) { second = i } else { first = i }
        }
    }

    if (proceed) {
        let t: string = ''

        if (text.length === 5 || text.length === 8) {
            if (!first) { proceed = false }
            if (proceed && first === 2) { t += text.charAt(0) + text.charAt(1) }
            if (proceed && first === 2 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && first === 2) { t += text.charAt(3) + text.charAt(4) }
            if (proceed && second && second === 5 && text.length === 8 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && second && second === 5 && text.length === 8) { t += text.charAt(6) + text.charAt(7) }
        } else { proceed = false }

        if (which === 0 && proceed) {
            Logic.Type.New(Main.Instance.TournamentState, {from_time: text, fromTime: t})
        } else if (which === 0) {
            Logic.Type.New(Main.Instance.TournamentState, {from_time: text, fromTime: ''})
        } else if (which === 1 && proceed) {
            Logic.Type.New(Main.Instance.TournamentState, {to_time: text, toTime: t})
        } else if (which === 1) {
            Logic.Type.New(Main.Instance.TournamentState, {to_time: text, toTime: ''})
        } else if (which === 2 && proceed && which2) {
            const recurring_from_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_from_time)
            recurring_from_time[which2] = text
            const recurringFromTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringFromTime)
            recurringFromTime[which2] = t
            Logic.Type.New(Main.Instance.TournamentState, {recurring_from_time, recurringFromTime})
        } else if (which === 2 && which2) {
            const recurring_from_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_from_time)
            recurring_from_time[which2] = text
            const recurringFromTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringFromTime)
            recurringFromTime[which2] = ''
            Logic.Type.New(Main.Instance.TournamentState, {recurring_from_time, recurringFromTime})
        } else if (which === 3 && proceed && which2) {
            const recurring_to_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_to_time)
            recurring_to_time[which2] = text
            const recurringToTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringToTime)
            recurringToTime[which2] = t
            Logic.Type.New(Main.Instance.TournamentState, {recurring_to_time, recurringToTime})
        } else if (which === 3 && which2) {
            const recurring_to_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_to_time)
            recurring_to_time[which2] = text
            const recurringToTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringToTime)
            recurringToTime[which2] = ''
            Logic.Type.New(Main.Instance.TournamentState, {recurring_to_time, recurringToTime})
        }
    }
}

function DatesChange(startDate: moment.Moment, endDate: moment.Moment) {
    const f: string = startDate ? moment.default(startDate).format(Assets_.dateDisplayFormat).trim() : ''
    const t: string = endDate ? moment.default(endDate).format(Assets_.dateDisplayFormat).trim() : ''
    Logic.Type.New(Main.Instance.TournamentState, {from_date: startDate, to_date: endDate, fromDate: f, toDate: t})
}

export function Tournaments2Presentation(tournaments: any, tournamentsAll: any) {
    const tournamentStream: any[] = []
    const tournamentStreamAll: any[] = []
    const now: Date = new Date ()
    const Now: string = now.toISOString()

    for (const tournament of tournaments) {
        let tournament_current: number = 0
        if (tournament.valid_to < Now) { tournament_current = 1 } else if (tournament.valid_from > Now) { tournament_current = 2 }

        const Tournament = {
            id: tournament.id,
            name: tournament.title,
            tdf: tournament.valid_from.substring(0,10),
            ttf: tournament.valid_from.substring(11,19),
            tournament_current,
            tdt: tournament.valid_to.substring(0,10),
            ttt: tournament.valid_to.substring(11,19),
            tournament_type: tournament.tournament_type,
            leaderboard_type: tournament.leaderboard_type,
            leaderboard_type2: tournament.leaderboard_type2,
            tournament_info: '',
            prizes: '',
            tournament_leaderboard: JSON.stringify(tournament),
        }

        tournamentStream.push (Tournament)
    }

    for (const tournamentAll of tournamentsAll) {
        let tournament_current: number = 0
        if (tournamentAll.valid_to < Now) { tournament_current = 1 } else if (tournamentAll.valid_from > Now) { tournament_current = 2 }

        const TournamentAll = {
            id: tournamentAll.id,
            name: tournamentAll.title,
            tdf: tournamentAll.valid_from.substring(0,10),
            ttf: tournamentAll.valid_from.substring(11,19),
            tournament_current,
            tdt: tournamentAll.valid_to.substring(0,10),
            ttt: tournamentAll.valid_to.substring(11,19),
            tournament_type: tournamentAll.tournament_type,
            leaderboard_type: tournamentAll.leaderboard_type,
            leaderboard_type2: tournamentAll.leaderboard_type2,
            tournament_info: '',
            prizes: '',
            tournament_leaderboard: JSON.stringify(tournamentAll),
        }

        tournamentStreamAll.push (TournamentAll)
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {tournamentStream, tournamentStreamAll})
}

function getTournamentsCount() {
    if (Main.Instance.TournamentState.countCalled === false) {
        Logic.Type.New(Main.Instance.TournamentState, {countCalled:true})
        DbRoutes.getCurrencyRates()
        DbRoutes.GetAllLanguages()
        DbRoutes.GetFlags()
        if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        } else { DbRoutes.tournamentsCount(/*Tournaments2Presentation*/) }
    }
}

function SelectSite(stream:any[]) {
    
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                site_id: stream[0]['id'],
        })
        DbRoutes.getLevelsAllDetailsBySite(stream[0]['id'])
        DbRoutes.tournamentsCount(/*Tournaments2Presentation*/)
        DbRoutes.GetCurrency()
        FirstPage()
    }
}

function handleEdit(row: any) { 
    gotoTop()

    const t = JSON.parse(row.original.tournament_leaderboard)

    const fromDate = t.valid_from.substring (0, 10)
    const toDate = t.valid_to.substring (0, 10)
    
    const fromTime = t.valid_from.substring (11, t.valid_from.length - 5)
    const toTime = t.valid_to.substring (11, t.valid_to.length - 5)

    // let recurringFromTime = t.recurring_from
    // let recurringToTime = t.recurring_to
/*
    let main_currency: number = 0
    let count: number = 1
    for (const currency of Main.Instance.TournamentState.Currencies) {
        if (currency.name === t.main_currency) {
            main_currency = count
            break
        }
        count ++
    }

    let Currencies: SelectCurrency2[] = []
    for (let i = 0; i < t.currency.length; i++) {
        let item: SelectCurrency2 = {
            id: (i+1).toString(),
            name: t.currency[i],
            main: main_currency === i + 1 ? true : false,
        }
        Currencies.push (item)
    }
*/
    Logic.Type.New(Main.Instance.TournamentState, {
        mode: row.original.id === undefined ? false : true,
        // main_currency: main_currency.toString(),
        id: t.id,
        // site_id: t.site_id,
        title_saved: t.title,
        title: t.title,
        // brands: t.brands,
        // Brands: t.brand,
        // currencies: t.currencies,
        // Currencies: Currencies,
        valid_from: t.valid_from,
        valid_to: t.valid_to,
        from_date: moment.default(new Date(fromDate)),
        to_date: moment.default(new Date(toDate)),
        fromDate,
        toDate,
        fromTime,
        toTime,
        from_time: fromTime === '00:00:00' ? '' : fromTime,
        to_time: toTime === '00:00:00' ? '' : toTime,
        opt_in: t.opt_in,
        min_rounds: t.min_rounds,
        max_rounds: t.max_rounds,
        leaderboard_type: t.leaderboard_type,
        leaderboard_type2: t.leaderboard_type2,
        tournamentType: t.tournament_type,
        opt_in_cost: t.opt_in_cost,
        bet_amount_min: t.bet_amount_min,
        bet_amount_max: t.bet_amount_max,
        
        odds_type: t.sportsbook.type,
        odds_req: t.sportsbook.req,
        odds: t.sportsbook.odds,
        Odds2: t.sportsbook.another,
        odds_type2: t.sportsbook.type2,
        odds_req2: t.sportsbook.req2,
        odds2: t.sportsbook.odds2,
        prizes: t.prizes,
        casino_vendors: '[]',// t.casino.vendors,
        // Casino_vendors: t.casino.vendor,
        casino_games: '[]',// t.casino.games,
        // Casino_games: t.casino.game,
        vendors: [],// t.casino.vendors2,
        // Vendors: t.casino.vendor2,
        games : [],// t.casino.games2,
        // Games : t.casino.game2,
        itipp: t.itipp,
        days_in_a_week: t.days_in_a_week,
        days_in_a_month: t.days_in_a_month,
        weeks: t.weeks,
        months: t.months,
        annual: t.annual,
        
        recurring_types: t.recurring_types,
        notify_players: t.recurring_notification,
        // real_money: t.real_money,
        // recurring: t.recurring,

        main_currency: t.main_currency,
        sublevel_min: t.sublevel_min,
        sublevel_max: t.sublevel_max,
        level_min: t.level_min,
        level_max: t.level_max,
/*
        recurring_from_time: t.recurring_from === '00:00:00' ? '' : t.recurring_from,
        recurring_to_time: t.recurring_to === '00:00:00' ? '' : t.recurring_to,
*/
        recurring_from_time: t.recurring_from,
        recurring_to_time: t.recurring_to,
        recurringFromTime: t.recurring_from,
        recurringToTime: t.recurring_to,
        total_max0: t.total_max0,
        total_max1: t.total_max1,
        total_max2: t.total_max2,
        total_max3: t.total_max3,
        total_max4: t.total_max4,
        part_of_wager: t.part_of_wager,
        daily: t.recurring,
        tournament: t,
        recurringTypeRecurring: '0',
        // real_money STILL UNUSED IF NEEDED
    })
    Logic.Type.New (Main.Instance.DatabaseDataState, {tournamentLeaderboard: undefined})
    IncludeRecurringType()
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Tournament + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {
            if (Main.Instance.TournamentState.pNum === 
            (Math.trunc((Main.Instance.TournamentState.count - 2) / 
            Main.Instance.TournamentState.pSize) + 1)) {
                DbRoutes.removeTournament (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeTournament (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.TournamentState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,
    })

    if (parseInt(text) === 0) {
        // Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getTournamentsCount()
    }
}

function clearFields() {
/*
    let Currency: string [] = []
    let currency: SelectCurrency [] = Object.assign([], Main.Instance.DatabaseDataState.currenciesStream)    
    for (let item of currency) {
        if (item.active) Currency.push (item.code)
    }

    let Currencies: SelectCurrency2[] = []
    for (let i = 0; i < Currency.length; i++) {
        let item: SelectCurrency2 = {id: (i+1).toString(), name: Currency[i], main: currency[i].main}
        Currencies.push (item)
    }
*/
    if (Main.Instance.TournamentState.mode) {
    Logic.Type.New(Main.Instance.TournamentState, {
        part_of_wager: [true, true, true, true, true],
        daily: true,
        mode: false,
        modeAdvanced: false,
        // modePrizepool: false,
        modeRecurring: false,
        modeLeaderboard: false,
        from_date: null,
        to_date: null,
        // recurring: false,
        itipp: false,
        // real_money: false,
        days_in_a_week: [],
        days_in_a_month: [],
        recurring_from_time: ['','','','',''],
        recurringFromTime: ['','','','',''],
        recurring_to_time: ['','','','',''],
        recurringToTime: ['','','','',''],
        notify_players: '0',
        // main_currency: '0',
        id: '0',
        title: '',
        from_time: '',
        to_time: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        valid_from: '',
        valid_to: '',
        opt_in: false,
        opt_in_cost: [],
        min_rounds: '',
        max_rounds: '',
        leaderboard_type: '0',
        leaderboard_type2: '0',
        casino_vendors: '0',
        casino_games: '0',
        bet_amount_min: [],
        bet_amount_max: [],
        total_max0: [],
        total_max1: [],
        total_max2: [],
        total_max3: [],
        total_max4: [],
        // brand: '0',
        vendor: '0',
        game: '0',
        // currencySelected: '0',
        // currencies: [],
        // Currencies: Currencies,
        tournamentType: '0',
        odds_type: '0',
        odds_req: '0',
        odds: '',
        odds_type2: '0',
        odds_req2: '0',
        odds2: '',
        Odds2: false,
        games: undefined,
        vendors: undefined,
        prizes: undefined,
        Casino_vendors: undefined,
        Casino_games: undefined,
        recurring_type: '0',
        recurring_option: '0',
        recurringType: '0',
        recurringTypeRecurring: '0',
        RecurringTypeReward: [],
        leaderboardType: '0',
        RecurringInWeek: '0',
        RecurringInMonth: '0',
        recurring_types: [1,2,3,4],
        selection: 0,
    })
    Logic.Type.New (Main.Instance.DatabaseDataState, {tournamentLeaderboard: undefined})
    }
}

function gotoInsertMode() {
    
    if (Main.Instance.TournamentState.title === Main.Instance.TournamentState.title_saved /*&&
        Main.Instance.TournamentState.price === Main.Instance.TournamentState.price_saved &&
        Main.Instance.TournamentState.active === Main.Instance.TournamentState.active_saved &&
        Main.Instance.TournamentState.free_round === Main.Instance.TournamentState.free_round_saved &&
        Main.Instance.TournamentState.buyable === Main.Instance.TournamentState.buyable_saved &&
        Main.Instance.TournamentState.discount === Main.Instance.TournamentState.discount_saved*/
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function IncludeRecurringType(text?: string) {
    let recurring_types: number[] = []
    if (text && parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.RecurringType.length; i++) {
            recurring_types.push(i)
        }
    } else if (!text || parseInt(text) !== Assets_.DESELECT_ALL) {

        recurring_types = Object.assign([], Main.Instance.TournamentState.recurring_types)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.recurring_types.length; i++) {
            if (Main.Instance.TournamentState.recurring_types[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            recurring_types.push(parseInt(text))
        } else {
            recurring_types.splice(alreadyIn, 1)
        }
    }
    const RecurringTypeReward: Select[] = []
    // let count: number = 1

    for (const item of recurring_types) {
        if (Assets.RecurringType[item - 1] && Assets.RecurringType[item - 1].length > 0) {
            const item1: Select = {id: item.toString(), name: Assets.RecurringType[item - 1]}
            RecurringTypeReward.push(item1)
        }
        // count++
    }

    if (text /*&& parseInt(text) === Assets_.DESELECT_ALL*/) {
        Logic.Type.New (Main.Instance.TournamentState, {
        recurring_types, RecurringTypeReward, recurringTypeRecurring: '0'/*, recurringTypeReward: '0'*/})
    } else { Logic.Type.New (Main.Instance.TournamentState, {recurring_types, RecurringTypeReward}) }
}
/*
function isDailySelected() {
    for (const item of Main.Instance.TournamentState.RecurringTypeReward) if (parseInt(item.id) === 1) return true
    return false
}

function isWeeklySelected() {
    for (const item of Main.Instance.TournamentState.RecurringTypeReward) if (parseInt(item.id) === 2) return true
    return false
}

function isMonthlySelected() {
    for (const item of Main.Instance.TournamentState.RecurringTypeReward) if (parseInt(item.id) === 3) return true
    return false
}

function isAnnualSelected() {
    for (const item of Main.Instance.TournamentState.RecurringTypeReward) if (parseInt(item.id) === 4) return true
    return false
}
*/


function IncludeDaysInWeek(text: string) {
    let days_in_a_week: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 3; i <= Main.Instance.TournamentState.DaysInAWeek.length; i++) {
            days_in_a_week.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        days_in_a_week = Object.assign([], Main.Instance.TournamentState.days_in_a_week)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.days_in_a_week.length; i++) {
            if (Main.Instance.TournamentState.days_in_a_week[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            days_in_a_week.push(parseInt(text))
        } else {
            days_in_a_week.splice(alreadyIn, 1)
        }
    }

    Logic.Type.New (Main.Instance.TournamentState, {days_in_a_week: days_in_a_week.sort((a, b) =>a-b)})
}

function IncludeDaysInMonth(text: string) {
    let days_in_a_month: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.DaysInAMonth.length; i++) {
            days_in_a_month.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        days_in_a_month = Object.assign([], Main.Instance.TournamentState.days_in_a_month)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.days_in_a_month.length; i++) {
            if (Main.Instance.TournamentState.days_in_a_month[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            days_in_a_month.push(parseInt(text))
        } else {
            days_in_a_month.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {days_in_a_month: days_in_a_month.sort((a, b) =>a-b)})
}

function IncludeWeeks(text: string) {
    let weeks: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 3; i <= Main.Instance.TournamentState.Weeks.length; i++) {
            weeks.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        weeks = Object.assign([], Main.Instance.TournamentState.weeks)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.weeks.length; i++) {
            if (Main.Instance.TournamentState.weeks[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            weeks.push(parseInt(text))
        } else {
            weeks.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {weeks: weeks.sort((a, b) =>a-b)})
}

function IncludeMonths(text: string) {
    let months: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 3; i <= Main.Instance.TournamentState.Months.length; i++) {
            months.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        months = Object.assign([], Main.Instance.TournamentState.months)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.months.length; i++) {
            if (Main.Instance.TournamentState.months[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            months.push(parseInt(text))
        } else {
            months.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {months: months.sort((a, b) =>a-b)})
}

function IncludeAnnual(text: string) {
    let annual: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.Annual.length; i++) {
            annual.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        annual = Object.assign([], Main.Instance.TournamentState.annual)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.annual.length; i++) {
            if (Main.Instance.TournamentState.annual[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            annual.push(parseInt(text))
        } else {
            annual.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {annual:annual.sort((a, b) =>a-b)})
}

/*
function IncludeBrands(text: string) {
    
    let temp: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.Brands.length; i++) {
            temp.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        temp = Object.assign([], Main.Instance.TournamentState.brands)
        
        let alreadyIn: number = -1
        
        for (let i:number = 0; i < Main.Instance.TournamentState.brands.length; i++) {
            if (Main.Instance.TournamentState.brands[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }
    
        if (alreadyIn === -1) {
            temp.push(parseInt(text))
        } else {
            temp.splice(alreadyIn, 1)
        }
    }

    Logic.Type.New (Main.Instance.TournamentState, {brands: temp})
}
*/

function IncludeCurrencies(text: string) {
    let temp: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.Currencies.length; i++) {
            temp.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {    
        temp = Object.assign([], Main.Instance.TournamentState.currencies)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.currencies.length; i++) {
            if (Main.Instance.TournamentState.currencies[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            temp.push(parseInt(text))
        } else {
            temp.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {currencies: temp})
}

function isLevelFinalLevel(level: number): boolean {
    if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[level - 1]['final_level'].toString() === 'true') {
        return true
    } else { return false }
}

function IncludeVendors(text: string) {
    let temp: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.Vendors.length; i++) {
            temp.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        temp = Object.assign([], Main.Instance.TournamentState.vendors)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.vendors.length; i++) {
            if (Main.Instance.TournamentState.vendors[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            temp.push(parseInt(text))
        } else {
            temp.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {vendors: temp})
}

function IncludeGames(text: string) {
    let temp: number[] = []
    if (parseInt(text) === Assets_.SELECT_ALL) {
        for (let i:number = 1; i <= Main.Instance.TournamentState.Games.length; i++) {
            temp.push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {    
        temp = Object.assign([], Main.Instance.TournamentState.games)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.games.length; i++) {
            if (Main.Instance.TournamentState.games[i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            temp.push(parseInt(text))
        } else {
            temp.splice(alreadyIn, 1)
        }
    }
    Logic.Type.New (Main.Instance.TournamentState, {games: temp})
}

function IncludeGamesPrizePool(text: string, which0: number, which: number) {
    const prizes: any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
    if (parseInt(text) === Assets_.SELECT_ALL) {
        prizes[which0][which]['games'] = []
        for (let i:number = 1; i <= Main.Instance.TournamentState.Games.length; i++) {
            prizes[which0][which]['games'].push(i)
        }
    } else if (parseInt(text) !== Assets_.DESELECT_ALL) {
        // prizes = Object.assign([], Main.Instance.TournamentState.prizes)
        let alreadyIn: number = -1

        for (let i:number = 0; i < Main.Instance.TournamentState.prizes[which0][which]['games'].length; i++) {
            if (Main.Instance.TournamentState.prizes[which0][which]['games'][i] === parseInt(text)) {
                alreadyIn = i
                break
            }
        }

        if (alreadyIn === -1) {
            prizes[which0][which]['games'].push(parseInt(text))
        } else {
            prizes[which0][which]['games'].splice(alreadyIn, 1)
        }
    } else { prizes[which0][0]['games'] = [] }

    // console.log (JSON.stringify(Main.Instance.TournamentState.prizes))

    Logic.Type.New (Main.Instance.TournamentState, {prizes})
}
/*
function real_money() {
    Logic.Type.New(Main.Instance.TournamentState, {real_money: !Main.Instance.TournamentState.real_money})
}
*/
function PartOfWager(which: number) {
    const part_of_wager: boolean[] = Object.assign([], Main.Instance.TournamentState.part_of_wager)
    part_of_wager[which] = !Main.Instance.TournamentState.part_of_wager[which]
    Logic.Type.New(Main.Instance.TournamentState, {part_of_wager})
}

function Daily() {
    Logic.Type.New(Main.Instance.TournamentState, {daily: !Main.Instance.TournamentState.daily})
    DbRoutes.handleLeaderboard()
}

function Itipp() {
    Logic.Type.New(Main.Instance.TournamentState, {itipp: !Main.Instance.TournamentState.itipp})
}

function OptIn() {
    Logic.Type.New(Main.Instance.TournamentState, {opt_in: !Main.Instance.TournamentState.opt_in})
}
/*
function Recurring() {
    Logic.Type.New(Main.Instance.TournamentState, {recurring: !Main.Instance.TournamentState.recurring})
} 
*/
function Odds2() {
    Logic.Type.New(Main.Instance.TournamentState, {Odds2: !Main.Instance.TournamentState.Odds2})
}

function SelectTournamentReward(text: string, which0: number, which: number) {    
    const prizes: any[][] = Object.assign ([], Main.Instance.TournamentState.prizes)
    prizes[which0][which]['tr'] = parseInt(text)
    Logic.Type.New(Main.Instance.TournamentState, {prizes})
 }

function FirstPositionRewarded(text: string, which0: number, which: number) {
    if (parseInt(text) > 0) {
        const prizes: any[][] = Object.assign ([], Main.Instance.TournamentState.prizes)
        prizes[which0][which]['fpr'] = parseInt(text)
        if (prizes[which0][which]['lpr'] < prizes[which0][which]['fpr']) { prizes[which0][which]['lpr'] = parseInt(text) }
        Logic.Type.New(Main.Instance.TournamentState, {prizes})
    }
}

function LastPositionRewarded(text: string, which0: number, which: number) {
    if (parseInt(text) > 0) {
        const prizes: any[][] = Object.assign ([], Main.Instance.TournamentState.prizes)
        prizes[which0][which]['lpr'] = parseInt(text)
        if (prizes[which0][which]['lpr'] < prizes[which]['fpr']) { prizes[which0][which]['fpr'] = parseInt(text) }
        Logic.Type.New(Main.Instance.TournamentState, {prizes})
    }
}

function WageringRequirement(text: string, which0: number, which: number) {
    if (parseInt(text) > -1) {
        const prizes: any[][] = Object.assign ([], Main.Instance.TournamentState.prizes)
        prizes[which0][which]['wrq'] = text
        Logic.Type.New(Main.Instance.TournamentState, {prizes})
    }
}

function ToggleIncludeExcludeGames(which0: number, which: number) {
    const prizes: any[][] = Object.assign ([], Main.Instance.TournamentState.prizes)
    prizes[which0][which]['tieg'] = parseInt(prizes[which0][which]['tieg']) + 1
    if (prizes[which0][which]['tieg'] === 3) { prizes[which0][which]['tieg'] = 0 }

    if (parseInt(prizes[which0][which]['tieg']) === 1) {
        prizes[which0][which]['games'] = []
        for (let i: number = 0; i < Main.Instance.TournamentState.Games.length; i++) {
            prizes[which0][which]['games'].push(i + 1)
        }
    } else if (parseInt(prizes[which0][which]['tieg']) === 2) {
        prizes[which0][which]['games'] = []
    }
    Logic.Type.New(Main.Instance.TournamentState, {prizes})
}

function DeletePrizePool(which0: number, which: number) {
    if (Main.Instance.TournamentState.prizes[which0].length === 1) {
        alert (Assets.AtLeastOnePrizePool)
    } else {
        const prizes:any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
        prizes[which0].splice(which, 1)
        Logic.Type.New(Main.Instance.TournamentState, {prizes})
    }
}

function AddPrizePool(which0: number, which: number) {
    const prizes: any[][] = Object.assign([], Main.Instance.TournamentState.prizes)
/*
    let Amount: string[] = []
    for (let i:number = 0; i < Main.Instance.TournamentState.currencies.length; i++) {
        Amount.push('')
    }
*/
    prizes[which0].splice(which + 1, 0, {
        tr: 0,
        fpr: parseInt(Main.Instance.TournamentState.prizes[which0][which]['lpr']) + 1,
        lpr: parseInt(Main.Instance.TournamentState.prizes[which0][which]['lpr']) + 1,
        wrq: 0,
        tieg: 0,
        games: [],
        game: '0',         
        amount: [],
    })
    Logic.Type.New(Main.Instance.TournamentState, {prizes})    
}

function RecordsPerPageChanged(text:string) {
    
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.TournamentState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function getTournaments() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {tournamentStream : undefined})
}

function FirstPage() {

    Logic.Type.New(Main.Instance.TournamentState, {offset:(0).toString()})

    getTournaments()
    Logic.Type.New(Main.Instance.TournamentState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.TournamentState.pNum > 0) {

        Logic.Type.New(Main.Instance.TournamentState, {
            offset:((Main.Instance.TournamentState.pNum - 1) * 
            Main.Instance.TournamentState.pSize).toString()})
        getTournaments()

        Logic.Type.New(Main.Instance.TournamentState,{pNum: Main.Instance.TournamentState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.TournamentState.pNum + 1) * Main.Instance.TournamentState.pSize < 
    Main.Instance.TournamentState.count) {

        Logic.Type.New(Main.Instance.TournamentState, {
            offset: ((Main.Instance.TournamentState.pNum + 1) * 
            Main.Instance.TournamentState.pSize).toString()})
        getTournaments()
        
        Logic.Type.New(Main.Instance.TournamentState,{pNum: Main.Instance.TournamentState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.TournamentState.count <= Main.Instance.TournamentState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.TournamentState.count === 0) {

            Logic.Type.New(Main.Instance.TournamentState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.TournamentState.count - 1) / 
                    Main.Instance.TournamentState.pSize)
            Logic.Type.New(Main.Instance.TournamentState, {
                offset:(npNum * Main.Instance.TournamentState.pSize).toString()})
        }

        getTournaments()

        Logic.Type.New(Main.Instance.TournamentState,{pNum: npNum})
    }
}

function handleInsertUpdate(/*results?: boolean*/) : any {
/*
    let main_currency: string = Assets_.NA
    // let main_currency_default: string = Assets_.NA
    // console.log (Main.Instance.TournamentState.mainCurrency)
    // console.log (Main.Instance.TournamentState.Currencies)
    if (parseInt(Main.Instance.TournamentState.mainCurrency) !== 0) {
        main_currency = Main.Instance.TournamentState.Currencies[
            parseInt(Main.Instance.TournamentState.mainCurrency) - 1].name
    }
*/
    // console.log ('main_currency:' + main_currency)
/*
    let brand: string [] = []
    for (let item of Main.Instance.TournamentState.Brands) {
        brand.push (item.name)
    }
*/

    // let main_currency_default: string = Assets_.NA
    const currency: string [] = []
    // let mainCurrency: number = -1
    
    for (const item of Main.Instance.TournamentState.Currencies) {
        // if (item['main'] === true) mainCurrency = currency.length
        currency.push (item.name)
    }
/*
    if (mainCurrency !== -1) {
        main_currency_default = Main.Instance.TournamentState.Currencies[mainCurrency].name
        // console.log ('main_currency_default:' + main_currency_default)
        // Main.Instance.DatabaseDataState.currenciesStream
    }
*/
    // console.log ('main_currency_default:' + main_currency_default)

    const opt_in_cost: number [] = []
    for (const item of Main.Instance.TournamentState.opt_in_cost) {
        opt_in_cost.push (parseFloat(item))
    }

    const bet_amount_min: number [] = []
    for (const item of Main.Instance.TournamentState.bet_amount_min) {
        bet_amount_min.push (parseFloat(item))
    }

    const bet_amount_max: number [] = []
    for (const item of Main.Instance.TournamentState.bet_amount_max) {
        bet_amount_max.push (parseFloat(item))
    }

    const total_max0: number [] = []
    for (const item of Main.Instance.TournamentState.total_max0) {
        total_max0.push (parseFloat(item))
    }

    const total_max1: number [] = []
    for (const item of Main.Instance.TournamentState.total_max1) {
        total_max1.push (parseFloat(item))
    }

    const total_max2: number [] = []
    for (const item of Main.Instance.TournamentState.total_max2) {
        total_max2.push (parseFloat(item))
    }

    const total_max3: number [] = []
    for (const item of Main.Instance.TournamentState.total_max3) {
        total_max3.push (parseFloat(item))
    }

    const total_max4: number [] = []
    for (const item of Main.Instance.TournamentState.total_max4) {
        total_max4.push (parseFloat(item))
    }

    const part_of_wager: boolean [] = []
    for (const item of Main.Instance.TournamentState.part_of_wager) {
        part_of_wager.push (item)
    }

    const days_in_a_week: number [] = []
    for (const item of Main.Instance.TournamentState.days_in_a_week) {
        days_in_a_week.push (item)
    }

    const days_in_a_month: number [] = []
    if (Main.Instance.TournamentState.days_in_a_month) {
    for (const item of Main.Instance.TournamentState.days_in_a_month) {
        days_in_a_month.push (item)
    }
    }

    const weeks: number [] = []
    if (Main.Instance.TournamentState.weeks) {
    for (const item of Main.Instance.TournamentState.weeks) {
        weeks.push (item)
    }
    }

    const months: number [] = []
    if (Main.Instance.TournamentState.months) {
    for (const item of Main.Instance.TournamentState.months) {
        months.push (item)
    }
    }

    const annual: number [] = []
    if (Main.Instance.TournamentState.annual) {
    for (const item of Main.Instance.TournamentState.annual) {
        annual.push (item)
    }
    }

    const recurring_types: number [] = []
    for (const item of Main.Instance.TournamentState.recurring_types) {
        if (item !== null && item !== undefined) { recurring_types.push (item) }
    }
    console.log (recurring_types)
/*
    let currencies:number[] = Main.Instance.TournamentState.currencies
    for (let item of currencies) {
        item = item - 1
    }
*/
    // console.log (Main.Instance.TournamentState.prizes)

    const tournament = {        
        real_money: false, // UNUSED

        sublevel_min: !Main.Instance.TournamentState.sublevel_min ? 0 : Main.Instance.TournamentState.sublevel_min,
        sublevel_max: !Main.Instance.TournamentState.sublevel_max ? 0 : Main.Instance.TournamentState.sublevel_max,
        level_min: !Main.Instance.TournamentState.level_min ? 0 : Main.Instance.TournamentState.level_min,
        level_max: !Main.Instance.TournamentState.level_max ? 0 : Main.Instance.TournamentState.level_max,
        weeks: !Main.Instance.TournamentState.weeks ? '[]' : weeks,
        months: !Main.Instance.TournamentState.months ? '[]' : months,
        annual: !Main.Instance.TournamentState.annual ? '[]' : annual,

        site_id: Main.Instance.FrontendStoreState.site_id,
        id : !Main.Instance.TournamentState.id ? '' : Main.Instance.TournamentState.id,
        title : !Main.Instance.TournamentState.title ? '' : Main.Instance.TournamentState.title,

        // CURRENT        
        // recurring: false,
        brands: [],
        brand: [],
        currency_rates: Main.Instance.CurrencyRatesState,
        // currency: [],
        // currencies: [],
        // brands: !Main.Instance.TournamentState.brands ? '[]' :
        // JSON.stringify(Main.Instance.TournamentState.brands),

        // brand: !Main.Instance.TournamentState.Brands ? '[]' : brand,
        // currency_rates
        currencies: !Main.Instance.TournamentState.currencies ? '[]' : JSON.stringify(Main.Instance.TournamentState.currencies),
        currency: !Main.Instance.TournamentState.Currencies ? '[]' : currency,
        // currency_rates: Main.Instance.CurrencyRatesState,
        main_currency: /*main_currency === Assets_.NA ?*/ Main.Instance.TournamentState.main_currency 
        /*: main_currency*/,
        valid_from:
            Main.Instance.TournamentState.fromDate.length > 0 && Main.Instance.TournamentState.fromTime.length > 0
            ? Main.Instance.TournamentState.fromDate + 'T' + Main.Instance.TournamentState.fromTime + 'Z' :
            Main.Instance.TournamentState.fromDate.length > 0 ? Main.Instance.TournamentState.fromDate + 'Z' :
            undefined,
        
        valid_to:
            Main.Instance.TournamentState.toDate.length > 0 && Main.Instance.TournamentState.toTime.length > 0
            ? Main.Instance.TournamentState.toDate + 'T' + Main.Instance.TournamentState.toTime + 'Z' :
            Main.Instance.TournamentState.toDate.length > 0 ? Main.Instance.TournamentState.toDate + 'Z' :
            undefined,

        opt_in : Main.Instance.TournamentState.opt_in === undefined ? false : Main.Instance.TournamentState.opt_in,
        opt_in_cost: !Main.Instance.TournamentState.opt_in_cost ? '[]' : opt_in_cost,
        min_rounds: !Main.Instance.TournamentState.min_rounds || Main.Instance.TournamentState.min_rounds.length === 0 
                     ? 0 : parseInt(Main.Instance.TournamentState.min_rounds),
        max_rounds: !Main.Instance.TournamentState.max_rounds || Main.Instance.TournamentState.max_rounds.length === 0 
                     ? 0 : parseInt(Main.Instance.TournamentState.max_rounds),

        bet_amount_min: !Main.Instance.TournamentState.bet_amount_min ? '[]' : 
            bet_amount_min,
        bet_amount_max: !Main.Instance.TournamentState.bet_amount_max ? '[]' : 
            bet_amount_max,
        total_max0: !Main.Instance.TournamentState.total_max0 ? '[]' : 
            total_max0,
        total_max1: !Main.Instance.TournamentState.total_max1 ? '[]' : 
            total_max1,
        total_max2: !Main.Instance.TournamentState.total_max2 ? '[]' : 
            total_max2,
        total_max3: !Main.Instance.TournamentState.total_max3 ? '[]' : 
            total_max3,
        total_max4: !Main.Instance.TournamentState.total_max4 ? '[]' : 
            total_max4,
        part_of_wager: !Main.Instance.TournamentState.part_of_wager ? '[]' : part_of_wager,
        recurring: Main.Instance.TournamentState.daily === undefined ? false : Main.Instance.TournamentState.daily,
        leaderboard_type: !Main.Instance.TournamentState.leaderboard_type ? 0 : 
            parseInt (Main.Instance.TournamentState.leaderboard_type),
        leaderboard_type2: !Main.Instance.TournamentState.leaderboard_type2 ? 0 : 
            parseInt (Main.Instance.TournamentState.leaderboard_type2),
        tournament_type: !Main.Instance.TournamentState.tournamentType ? 0 : 
            parseInt (Main.Instance.TournamentState.tournamentType),

        casino: {
            vendors: !Main.Instance.TournamentState.casino_vendors ? 0 : 
                            parseInt (Main.Instance.TournamentState.casino_vendors),
            vendor: !Main.Instance.TournamentState.Casino_vendors ? '[]' : 
                            JSON.stringify(Main.Instance.TournamentState.Casino_vendors),
            games: !Main.Instance.TournamentState.casino_games ? 0 : 
                        parseInt (Main.Instance.TournamentState.casino_games),
            game: !Main.Instance.TournamentState.Casino_games ? '[]' : 
                        JSON.stringify(Main.Instance.TournamentState.Casino_games),

            vendors2: !Main.Instance.TournamentState.vendors ? '[]' :
            JSON.stringify(Main.Instance.TournamentState.vendors),

            vendor2: !Main.Instance.TournamentState.Vendors ? '[]' :
            JSON.stringify(Main.Instance.TournamentState.Vendors),
            
            games2: !Main.Instance.TournamentState.games ? '[]' :
            JSON.stringify(Main.Instance.TournamentState.games),

            game2: !Main.Instance.TournamentState.Games ? '[]' :
            JSON.stringify(Main.Instance.TournamentState.Games),
        },

        sportsbook: {
            type: !Main.Instance.TournamentState.odds_type ? 0 : 
            parseInt (Main.Instance.TournamentState.odds_type),

            req: !Main.Instance.TournamentState.odds_req ? 0 : 
            parseInt (Main.Instance.TournamentState.odds_req),

            odds: !Main.Instance.TournamentState.odds ? 0 : 
            parseFloat(Main.Instance.TournamentState.odds),

            another: !Main.Instance.TournamentState.Odds2 === undefined ? false : 
            Main.Instance.TournamentState.Odds2,
            
            type2: !Main.Instance.TournamentState.odds_type2 ? 0 : 
            parseInt (Main.Instance.TournamentState.odds_type2),

            req2: !Main.Instance.TournamentState.odds_req2 ? 0 : 
            parseInt (Main.Instance.TournamentState.odds_req2),

            odds2: !Main.Instance.TournamentState.odds2 ? 0 : 
            parseFloat(Main.Instance.TournamentState.odds2),
        },

        prizes: Main.Instance.TournamentState.prizes,
        // real_money : Main.Instance.TournamentState.real_money === undefined ? false : 
        // Main.Instance.TournamentState.real_money,
        itipp : Main.Instance.TournamentState.itipp === undefined ? false : 
                Main.Instance.TournamentState.itipp,
        // recurring : Main.Instance.TournamentState.recurring === undefined ? false : 
        // Main.Instance.TournamentState.recurring,
        days_in_a_week: !Main.Instance.TournamentState.days_in_a_week ? '[]' : days_in_a_week,
        days_in_a_month: !Main.Instance.TournamentState.days_in_a_month ? '[]' : days_in_a_month,
        recurring_types: !Main.Instance.TournamentState.recurring_types ? '[]' : recurring_types,
        recurring_from_time: Main.Instance.TournamentState.recurring_from_time,
        recurring_to_time: Main.Instance.TournamentState.recurring_to_time,
        recurring_notification: parseInt(Main.Instance.TournamentState.notify_players),
    }

    // console.log (JSON.stringify(tournament))

    const validationFromTime: boolean =
        Main.Instance.TournamentState.from_time.length === 0 || utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.from_time)
    const validationToTime: boolean =
        Main.Instance.TournamentState.to_time.length === 0 || utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.to_time)

    const validationRecurringFromTime: boolean[] = [true, true, true, true, true]
    for (let i: number = 0; i < validationRecurringFromTime.length; i++) {
    if (Main.Instance.TournamentState.recurring_from_time[i]) {
    validationRecurringFromTime[i] = Main.Instance.TournamentState.recurring_from_time[i].length === 0 ||
    utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.recurring_from_time[i])
    }
    }

    const validationRecurringToTime: boolean[] = [true, true, true, true, true]
    for (let i: number = 0; i < validationRecurringToTime.length; i++) {
    if (Main.Instance.TournamentState.recurring_to_time[i]) {
    validationRecurringToTime[i] = Main.Instance.TournamentState.recurring_to_time[i].length === 0 ||
    utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.recurring_to_time[i])
    }
    }

    if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
        return
    } else if (tournament.title.length === 0) {
        alert (Assets.compulsoryTournamentTitle)
        return        
/*
    } else if (tournament.currencies.length === 0) {
        alert (Assets.compulsoryTournamentCurrency)
        return
*/
    } else if (tournament.valid_from === undefined) {
        alert (Assets.compulsoryTournamentDateFrom)
        return
    } else if (tournament.valid_to === undefined) {
        alert (Assets.compulsoryTournamentDateTo)
        return
    } else if (tournament.tournament_type === 0) {
        alert (Assets.compulsoryTournamentType)
        return    
    } else if (tournament.leaderboard_type === 0) {
        alert (Assets.compulsoryLeaderboardType)
        return
/* !!!
    } else if (tournament.leaderboard_type === tournament.leaderboard_type2) {
        alert (Assets.compulsoryDifferentLeaderboardType)
        return
*/
/*
    } else if (tournament.tournament_type !== 3 && tournament.sportsbook.type === 0) {
        alert (Assets.compulsoryOddsType)
        return
    } else if (tournament.tournament_type !== 3 && tournament.sportsbook.req !== 0 && 
        tournament.sportsbook.odds === 0) {
        alert (Assets.compulsoryOdds)
        return
    } else if (tournament.tournament_type !== 3 && tournament.sportsbook.another && tournament.sportsbook.type2 === 0) {
        alert (Assets.compulsoryOddsType)
        return
    } else if (tournament.tournament_type !== 3 && tournament.sportsbook.another && tournament.sportsbook.req2 !== 0 && 
        tournament.sportsbook.odds2 === 0) {
        alert (Assets.compulsoryOdds)
        return
*/
    } else if (!validationFromTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationToTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringFromTime[0]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringToTime[0]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringFromTime[1]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringToTime[1]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringFromTime[2]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringToTime[2]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringFromTime[3]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringToTime[3]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringFromTime[4]) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationRecurringToTime[4]) {
        alert (Assets.compulsoryCorrectTime)
    }
    // console.log (results)
/*
    if (results) {
        // console.log (JSON.stringify(tournament.currency_rates))
        // ok here we have to set valid from and valid to separately if needed
        if (parseInt(Main.Instance.TournamentState.recurring_option) > 0) {
            tournament.valid_from = Main.Instance.TournamentState.validFrom + 'T' +
                                    Main.Instance.TournamentState.time1 ?
                                    Main.Instance.TournamentState.recurring_from_time + 'Z' :
                                    Main.Instance.TournamentState.from_time + 'Z'
   
            tournament.valid_to = Main.Instance.TournamentState.validTo + 'T' + 
                                  Main.Instance.TournamentState.time2 ?
                                  Main.Instance.TournamentState.recurring_to_time + 'Z' :
                                  Main.Instance.TournamentState.to_time + 'Z'

        }
        return tournament
    } else*/
    if (!Main.Instance.TournamentState.mode) {
        DbRoutes.insertTournament (tournament, InsertLastPage)
    } else {
        DbRoutes.updateTournament (tournament, Update)
    }
}

function getTournamentName(tournament: any): string {
    // console.log (tournament)
    return tournament.title
}

function getStNdRdTh(i: number): string {
    if (i % 100 === 1 && i !== 11) { return 'st' } else if (i % 100 === 2 && i !== 12) { return 'nd' } else if (i % 100 === 3  && i !== 13) { return 'rd' } else { return 'th' } 
}

function getLeaderboard(data: any[], tournament: any): JSX.Element[] {

    const leaderboard: JSX.Element[] = []
    // console.log()
    const rewards: Array<{amount: string, currency: string, xp: string, type: number}> = []
    // console.log ('recurring:' + Main.Instance.TournamentState.recurringTypeRecurring)

    let lpr: number = 0
    for (const item of tournament.prizes[Main.Instance.TournamentState.recurringTypeRecurring]) {
        // console.log (item)
        if (item.lpr > lpr) { lpr = item.lpr }
    }

    for (let i: number = 0; i < lpr; i++) {
        rewards.push ({amount: '0', currency: Assets_.NA, xp: '0', type: 0})
    }

    for (const item of tournament.prizes[Main.Instance.TournamentState.recurringTypeRecurring]) {
        for (let i: number = item.fpr; i <= item.lpr; i++) {
        rewards[i - 1] = {amount: item.amount[1], currency: 'EUR', xp: item.amount[0], type: item.tr}
        } // !! this has to be changed in base of the real currency also what if it is points?
    }
/*
    console.log (rewards)
    
*/
    // console.log (data)
    // if (Main.Instance.DatabaseDataState.FlagsStreamAll) console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)

    for (let i: number = 0; i < data.length; i++) {
        // console.log (item)
        let image: string
        let country: string
        if (Main.Instance.DatabaseDataState.FlagsStreamAll) {
        for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
            // console.log (data[i])
            if (data[i] && (data[i]['custom'] && data[i]['custom']['nationality'] === Item['code2'] || data[i]['country'] && data[i]['country'] === Item['code2'] ||
            data[i]['nationality'] && data[i]['nationality'] === Item['code2'])) {
                image = Item ['image20']
                country = Item ['name']
            } else if (data[i] && (data[i]['custom'] && data[i]['custom']['nationality'] === Item['code'] || data[i]['country'] && data[i]['country'] === Item['code'] ||
            data[i]['nationality'] && data[i]['nationality'] === Item['code'])) {
                image = Item ['image']
                country = Item ['name']
            }
        }
        }

        const bonus: string = rewards[i] && rewards[i].type === 2 ? ' (bonus)' : ''
        if (data[i]) {
        leaderboard.push (
        <tr key = {i.toString()} style = {i < rewards.length ? {background: '#92aef8', fontWeight: 700} : {}}>
            <td className = "td-right">{(i + 1).toString()}<sup>{getStNdRdTh(i + 1)}</sup></td>
            {data[i]['id'] ? <td className = "td-left">{data[i]['id']}</td> :
             data[i]['playerId'] ? <td className = "td-left">{data[i]['playerId']}</td> : <td/>}
            {data[i]['username'] ? <td className = "td-left">{data[i]['username']}</td> :
            data[i]['player'] ? <td className = "td-left">{data[i]['player']}</td> : 
            data[i]['name'] ? <td className = "td-left">{data[i]['name']}</td> : <td/>}
            {data[i]['name'] ? <td className = "td-left">{data[i]['name']}</td> :
            data[i]['player'] ? <td className = "td-left">{data[i]['player']}</td> : <td/>}
            
            <td className = "td-left">{country}</td>
            <td className = "td-center"><img style = {{border: '1px solid #999', borderRadius: '50%', objectFit: 'cover', width: '20px', height: '20px'}} src = {image}/></td>
            {data[i]['sum'] ? <td className = "td-right">{data[i]['sum'] + ' EUR'}</td> :
            data[i]['points'] ? <td className = "td-right">{data[i]['points'] + ' EUR'}</td> : 
            Main.Instance.TournamentState.leaderboard_type !== undefined && +Main.Instance.TournamentState.leaderboard_type > 0 ?
            +Main.Instance.TournamentState.leaderboard_type === 1 && data[i]['winPercent'] !== undefined ? <td className = "td-right">{data[i]['winPercent'].toFixed(2) + ' %'}</td> :
            +Main.Instance.TournamentState.leaderboard_type === 2 && data[i]['totalWin'] !== undefined ? <td className = "td-right">{data[i]['totalWin'] + ' EUR'}</td> :
            +Main.Instance.TournamentState.leaderboard_type === 3 && data[i]['totalWager'] !== undefined ? <td className = "td-right">{data[i]['totalWager'] + ' EUR'}</td> :
            +Main.Instance.TournamentState.leaderboard_type === 4 && data[i]['roundsWager'] !== undefined ? <td className = "td-right">{data[i]['roundsWager']}</td> :
            +Main.Instance.TournamentState.leaderboard_type === 5 && data[i]['highestWinRatio'] !== undefined ? <td className = "td-right">{data[i]['highestWinRatio']}</td> :
            <td/>
            :
            +tournament.leaderboard_type === 1 && data[i]['winPercent'] !== undefined ? <td className = "td-right">{data[i]['winPercent'].toFixed(2) + ' %'}</td> :
            +tournament.leaderboard_type === 2 && data[i]['totalWin'] !== undefined ? <td className = "td-right">{data[i]['totalWin'] + ' EUR'}</td> :
            +tournament.leaderboard_type === 3 && data[i]['totalWager'] !== undefined ? <td className = "td-right">{data[i]['totalWager'] + ' EUR'}</td> :
            +tournament.leaderboard_type === 4 && data[i]['roundsWager'] !== undefined ? <td className = "td-right">{data[i]['roundsWager']}</td> :
            +tournament.leaderboard_type === 5 && data[i]['highestWinRatio'] !== undefined ? <td className = "td-right">{data[i]['highestWinRatio']}</td> :
            <td/>}
            <td className = "td-right">{i < rewards.length ? rewards[i].xp ? rewards[i].xp + ' XP' + bonus : rewards[i].amount ? rewards[i].amount + ' ' + rewards[i].currency + bonus : '-' : '-'}</td>
        </tr>,
        )
        }
    }
    return leaderboard
}

function InsertLastPage() {
    
    clearFields()
    Logic.Type.New(Main.Instance.TournamentState, {count:Main.Instance.TournamentState.count ++})
    LastPage()
}

function DeleteLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.TournamentState,{count:Main.Instance.TournamentState.count --})
    LastPage()
}

function DeleteNotLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.TournamentState,{count:Main.Instance.TournamentState.count --})
    getTournaments()
}

function Update() {

    clearFields()
    getTournaments()
}

function Expand(pos: number) {

}

export function Tournaments() {
    // console.log (Main.Instance.DatabaseDataState.currenciesStream)
    let main_currency: string// = Assets_.NA
    // let currency: string [] = []
    // let mainCurrency: number = -1
    if (Main.Instance.DatabaseDataState.currenciesStream) {
    for (const item of Main.Instance.DatabaseDataState.currenciesStream) {
        if (item['main'] === true) {main_currency = item['code']; break} /*mainCurrency = currency.length*/
        
        // currency.push (item.name)
    }
    }
/*
    if (mainCurrency !== -1) {
        main_currency = Main.Instance.DatabaseDataState.currenciesStream[mainCurrency].name
        // Main.Instance.DatabaseDataState.currenciesStream
    }
*/
    // console.log (main_currency)
    if (main_currency && main_currency !== Main.Instance.TournamentState.main_currency) {
        Logic.Type.New (Main.Instance.TournamentState, {main_currency})
    }

    let which0: number = 0
    if (!isNaN(parseInt(Main.Instance.TournamentState.recurringTypeRecurring))) {
        which0 = parseInt(Main.Instance.TournamentState.recurringTypeRecurring)
        // if (which0 === 4) which0 = 0
    }

    const which0Size: number = 5
/*    
    let which0Size: number = 1
    for (const item of Main.Instance.TournamentState.RecurringTypeReward) {
        if (parseInt(item.id) !== 5) which0Size++
    }
*/
    // console.log (which0Size)

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    if (!Main.Instance.TournamentState.days_in_a_week) {

        const DaysInAWeek: Select[] = []
        for (let i = 0; i < Assets.DaysInAWeek.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.DaysInAWeek[i]}
            DaysInAWeek.push(item)
        }

        const DaysInAMonth: Select[] = []
        for (let i = 0; i < Assets.DaysInAMonth.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.DaysInAMonth[i]}
            DaysInAMonth.push(item)
        }

        const Weeks: Select[] = []
        for (let i = 0; i < Assets.Weeks.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.Weeks[i]}
            Weeks.push(item)
        }

        const Months: Select[] = []
        for (let i = 0; i < Assets.Months.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.Months[i]}
            Months.push(item)
        }

        const Annual: Select[] = []
        for (let i = 0; i < Assets_.Annual.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets_.Annual[i]}
            Annual.push(item)
        }

        const NotifyPlayers: Select[] = []
        for (let i = 0; i < Assets.NotifyPlayers.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.NotifyPlayers[i]}
            NotifyPlayers.push(item)
        }

        const RecurringType: Select[] = []
        for (let i = 0; i < Assets.RecurringType.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Assets.RecurringType[i]}
            RecurringType.push(item)
        }

        Logic.Type.New(Main.Instance.TournamentState, {
            DaysInAWeek,
            DaysInAMonth,
            Weeks,
            Months,
            Annual,
            days_in_a_week: [],
            days_in_a_month: [],
            weeks: [],
            months: [],
            annual: [],
            NotifyPlayers,
            RecurringType,
            recurring_types: [],
        })
    }
/*
    if (!Main.Instance.TournamentState.brands) {
        let Brand: string [] = Object.assign([], Main.Instance.TournamentState.Brand)
        Brand.sort()
        let Brands: Select[] = []
        for (let i = 0; i < Brand.length; i++) {
            let item: Select = {id: (i+1).toString(), name: Brand[i]}
            Brands.push (item)
        }
        Logic.Type.New(Main.Instance.TournamentState, {Brands: Brands, brands: []})
    }
*/  
    if (!Main.Instance.TournamentState.vendors) {
        const Vendor: string [] = Object.assign([], Main.Instance.TournamentState.Vendor)
        Vendor.sort()
        const Vendors: Select[] = []
        for (let i = 0; i < Vendor.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Vendor[i]}
            Vendors.push (item)
        }
        Logic.Type.New(Main.Instance.TournamentState, {Vendors, vendors: []})
    }

    if (!Main.Instance.TournamentState.games) {
        const Game: string [] = Object.assign([], Main.Instance.TournamentState.Game)
        Game.sort()
        const Games: Select[] = []
        for (let i = 0; i < Game.length; i++) {
            const item: Select = {id: (i+1).toString(), name: Game[i]}
            Games.push (item)
        }
        Logic.Type.New(Main.Instance.TournamentState, {Games, games: []})
    }    

    if (!Main.Instance.TournamentState.currencies && Main.Instance.DatabaseDataState.currenciesStream) {
        const currency: SelectCurrency [] = Object.assign([], Main.Instance.DatabaseDataState.currenciesStream)
        const Currency: string [] = []
        for (const item of currency) {
            if (item.active) { Currency.push (item.code) }
        }
        // Currency.sort()
        const Currencies: SelectCurrency2[] = []
        for (let i = 0; i < Currency.length; i++) {
            const item: SelectCurrency2 = {id: (i+1).toString(), name: Currency[i], main: currency[i].main}
            Currencies.push (item)
        }
        
        Logic.Type.New(Main.Instance.TournamentState, {Currencies, currencies: []})
    }

    if (!Main.Instance.TournamentState.TournamentType) {
        const TournamentType: any[] = []
        for (let i: number = 0; i < Assets.TournamentType.length; i++) {
            TournamentType.push({id:(i+1).toString(), name: Assets.TournamentType[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {TournamentType})
    }

    if (!Main.Instance.TournamentState.Leaderboard_type) {
        const Leaderboard_type: any[] = []
        for (let i:number = 0; i < Assets.LeaderBoard.length; i++) {
            Leaderboard_type.push({id:(i+1).toString(), name: Assets.LeaderBoard[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {Leaderboard_type})
    }

    if (!Main.Instance.TournamentState.Leaderboard_type2) {
        const Leaderboard_type2: any[] = []
        for (let i:number = 0; i < Assets.LeaderBoard2.length; i++) {
            Leaderboard_type2.push({id:(i+1).toString(), name: Assets.LeaderBoard2[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {Leaderboard_type2})
    }

    if (!Main.Instance.TournamentState.Casino_vendors) {
        const Casino_vendors: any[] = []
        for (let i:number = 0; i < Assets.CasinoVendors.length; i++) {
            Casino_vendors.push({id:(i+1).toString(), name: Assets.CasinoVendors[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {Casino_vendors})
    }

    if (!Main.Instance.TournamentState.Casino_games) {
        const Casino_games: any[] = []
        for (let i:number = 0; i < Assets.CasinoGames_.length; i++) {
            Casino_games.push({id:(i+1).toString(), name: Assets.CasinoGames_[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {Casino_games})
    }

    if (!Main.Instance.TournamentState.FixedLiveOdds) {
        const FixedLiveOdds: any[] = []
        for (let i:number = 0; i < Assets.FixedLiveOdds.length; i++) {
            FixedLiveOdds.push({id: (i+1).toString(), name: Assets.FixedLiveOdds[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {FixedLiveOdds})
    }

    if (!Main.Instance.TournamentState.OddsRequirement) {
        const OddsRequirement: any[] = []
        for (let i:number = 0; i < Assets.OddsRequirement.length; i++) {
            OddsRequirement.push({id: (i+1).toString(), name: Assets.OddsRequirement[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {OddsRequirement})
    }

    if (!Main.Instance.TournamentState.TournamentReward) {
        const TournamentReward: any[] = []
        for (let i:number = 0; i < Assets.TournamentReward.length; i++) {
            TournamentReward.push({id: (i+1).toString(), name: Assets.TournamentReward[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {TournamentReward})
    }

    if (!Main.Instance.TournamentState.prizes) {
        const Prizes: any[][] = [[],[],[],[],[]]
        Prizes[which0].push({
            tr: 0,
            fpr: 1,
            lpr: 1,
            wrq: 0,
            tieg: 0,
            games: [],
            game: '0',
            amount: [],
        })
        Logic.Type.New(Main.Instance.TournamentState, {prizes: Prizes})
    }

    const optInCost: JSX.Element[] = [] // this can be either real money or not (JUST 1 then)
    const minAmount: JSX.Element[] = []
    const maxAmount: JSX.Element[] = []
    const maxTotal0: JSX.Element[] = []
    const maxTotal1: JSX.Element[] = []
    const maxTotal2: JSX.Element[] = []
    const maxTotal3: JSX.Element[] = []
    const maxTotal4: JSX.Element[] = []
    const FixedAmount: JSX.Element[][][] = [] // 2
    const BonusAmount: JSX.Element[][][] = [] // 2
    const FreeSpins: JSX.Element[][][] = [] // 2
    const FreeBets: JSX.Element[][][] = [] // 1
    
    const WoF: JSX.Element[][] = []

    // if (Main.Instance.TournamentState.real_money === false) {
    optInCost.push (
        <FormInput
            half = {true} 
            key = "0"
            value = {!Main.Instance.TournamentState.opt_in_cost[0] ? '' : 
                    Main.Instance.TournamentState.opt_in_cost[0]}
            ClassName = "form-control register-input mr0" 
            type = "text" 
            onChange = {(text) => AllowNumberCharactersOnly(text, 0, which0, 0)} 
            // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
            description = {Assets.optInCost + ' (' + Assets_.XP + ')'}
            iconText = {Assets_.XP}
            iconTextSize = "14px"
        />,
    )
    // console.log ('which0Size:' + which0Size)
    for (let k: number = 0; k < which0Size; k++) {
        if (Main.Instance.TournamentState.prizes[k]) {
        for (let j: number = 0; j < Main.Instance.TournamentState.prizes[k].length; j++) {

            if (!FixedAmount[k]) { FixedAmount[k] = [] }
            if (!FixedAmount[k][j]) { FixedAmount[k][j] = [] }            
            FixedAmount[k][j][0] =
                <FormInput
                    half = {true}
                    // key = {(0 * Main.Instance.TournamentState.prizes[which0].length + j).toString()}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][0] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][0]}
                    ClassName = "form-control register-input mr0" 
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, 0, j)} 
                    // placeholder = {Assets.FixedAm_} 
                    description = {Assets.FixedAmount + ' (' + Assets_.XP + ')'}
                    iconText = {Assets_.XP}
                    iconTextSize = "14px"
                />

            if (!BonusAmount[k]) { BonusAmount[k] = [] }
            if (!BonusAmount[k][j]) { BonusAmount[k][j] = [] }                
            BonusAmount[k][j][0] =
                <FormInput
                    half = {true}
                    // key = {(0 * Main.Instance.TournamentState.prizes[which0].length + j).toString()}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][0] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][0]}
                    ClassName = "form-control register-input mr0"
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, 0, j)} 
                    // placeholder = {Assets.BonusAm_}
                    description = {Assets.BonusAmount + ' (' + Assets_.XP + ')'}
                    iconText = {Assets_.XP}
                    iconTextSize = "14px"
                />

            if (!FreeSpins[k]) { FreeSpins[k] = [] }
            if (!FreeSpins[k][j]) { FreeSpins[k][j] = [] }
            FreeSpins[k][j][0] =
                <FormInput
                    half = {true}
                    // key = {(0 * Main.Instance.TournamentState.prizes[which0].length + j).toString()}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][0] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][0]}
                    ClassName = "form-control register-input mr0"
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, 0, j)} 
                    // placeholder = {Assets.BonusAm_}
                    description = {Assets.FreeSpins + ' (' + Assets.number + ')'}
                    iconText = "#"
                    iconTextSize = "14px"
                />

            if (!FreeBets[k]) { FreeBets[k] = [] }
            if (!FreeBets[k][j]) { FreeBets[k][j] = [] }
            FreeBets[k][j][0] =
                <FormInput
                    half = {true}
                    // key = {(0 * Main.Instance.TournamentState.prizes[which0].length + j).toString()}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][0] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][0]}
                    ClassName = "form-control register-input mr0"
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, 0, j)} 
                    // placeholder = {Assets.BonusAm_}
                    description = {Assets.FreeBets + ' (' + Assets.amount + ')'}
                    iconText = {Main.Instance.TournamentState.main_currency}
                    iconTextSize = "14px"
                />
        }
        }
    }
    // }
    // if (Main.Instance.TournamentState.currencies) 
    for (let i: number = 1; i < 2 /*Main.Instance.TournamentState.currencies.length*/; i++) {
        optInCost.push (
            <FormInput 
                key = {i.toString()}
                half = {true}
                value = {!Main.Instance.TournamentState.opt_in_cost[optInCost.length] ? '' : 
                        Main.Instance.TournamentState.opt_in_cost[optInCost.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 0, which0, i)}
                description = ""
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                //     Assets.optInCost + ' (' + Main.Instance.TournamentState.Currencies[
                //         Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )
    }
    for (let i: number = 0; i < 1 /*Main.Instance.TournamentState.currencies.length*/; i++) {     
        minAmount.push (
            <FormInput 
                key = {i.toString()}
                half = {true}
                value = {!Main.Instance.TournamentState.bet_amount_min[minAmount.length] ? '' : 
                        Main.Instance.TournamentState.bet_amount_min[minAmount.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 1, which0, i)} 
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']}
                description = {Assets.betAmountMinDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // description = {
                //     Assets.betAmountMin + ' (' + Main.Instance.TournamentState.Currencies[
                //         Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )

        maxAmount.push (
            <FormInput 
                key = {i.toString()}
                half = {true}
                value = {!Main.Instance.TournamentState.bet_amount_max[maxAmount.length] ? '' : 
                        Main.Instance.TournamentState.bet_amount_max[maxAmount.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 2, which0, i)} 
                description = {Assets.betAmountMaxDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                //     Assets.betAmountMax + ' (' + Main.Instance.TournamentState.Currencies[
                //         Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )

        maxTotal0.push (
            <FormInput 
                key = {i.toString()}
                full = {true}
                value = {!Main.Instance.TournamentState.total_max0[maxTotal0.length] ? '' : 
                        Main.Instance.TournamentState.total_max0[maxTotal0.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 3, which0, i, 0)}
                description = {Assets.betAmountTotalDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                // Assets.betAmountTotal + ' (' + Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )

        maxTotal1.push (
            <FormInput 
                key = {i.toString()}
                full = {true}
                value = {!Main.Instance.TournamentState.total_max1[maxTotal1.length] ? '' : 
                        Main.Instance.TournamentState.total_max1[maxTotal1.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 3, which0, i, 1)}
                description = {Assets.betAmountTotalDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                // Assets.betAmountTotal + ' (' + Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )

        maxTotal2.push (
            <FormInput 
                key = {i.toString()}
                full = {true}
                value = {!Main.Instance.TournamentState.total_max2[maxTotal2.length] ? '' : 
                        Main.Instance.TournamentState.total_max2[maxTotal2.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 3, which0, i, 2)}
                description = {Assets.betAmountTotalDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                // Assets.betAmountTotal + ' (' + Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )
        
        maxTotal3.push (
            <FormInput 
                key = {i.toString()}
                full = {true}
                value = {!Main.Instance.TournamentState.total_max3[maxTotal3.length] ? '' : 
                        Main.Instance.TournamentState.total_max3[maxTotal3.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 3, which0, i, 3)}
                description = {Assets.betAmountTotalDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                // Assets.betAmountTotal + ' (' + Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )

        maxTotal4.push (
            <FormInput 
                key = {i.toString()}
                full = {true}
                value = {!Main.Instance.TournamentState.total_max4[maxTotal4.length] ? '' : 
                        Main.Instance.TournamentState.total_max4[maxTotal4.length]}
                ClassName = "form-control register-input mr0" 
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 3, which0, i, 4)}
                description = {Assets.betAmountTotalDesc}
                iconText = {Main.Instance.TournamentState.main_currency}
                // placeholder = {Main.Instance.TournamentState.Currencies[i]['name']} 
                // description = {
                // Assets.betAmountTotal + ' (' + Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                // iconText = {Main.Instance.TournamentState.Currencies[
                //     Main.Instance.TournamentState.currencies[i] - 1]['name']}
                iconTextSize = "14px"
            />,
        )
    }
    for (let i: number = 1; i < 2 /*Main.Instance.TournamentState.currencies.length*/; i++) {    
        // console.log ('which0Size:' + which0Size)
        for (let k:number = 0; k < which0Size; k++) {
        if (Main.Instance.TournamentState.prizes[k]) {
        for (let j:number = 0; j < Main.Instance.TournamentState.prizes[k].length; j++) {
            // console.log ('k:' + k)
            // console.log ('j:' + j)
            // if (FixedAmount[k] && FixedAmount[k][j]) console.log ('i:' + i)

            if (!FixedAmount[k]) { FixedAmount[k] = [] }
            if (!FixedAmount[k][j]) { FixedAmount[k][j] = [] }            
            // if (FixedAmount[k] && FixedAmount[k][j])
            FixedAmount[k][j][i] =
                <FormInput 
                    // key = {(i * Main.Instance.TournamentState.prizes[k].length + j).toString()}
                    half = {true}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][i] ? '' :
                            Main.Instance.TournamentState.prizes[k][j]['amount'][i]}
                    ClassName = "form-control register-input mr0"
                    type = "text"
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, i, j)}
                    description = {Assets.FixedAmount+' ('+Main.Instance.TournamentState.main_currency+')'}
                    iconText = {Main.Instance.TournamentState.main_currency}
                    // placeholder = {Assets.FixedAm_}
                    // description = {
                    // Assets.FixedAmount + ' (' + Main.Instance.TournamentState.Currencies[
                    //     Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                    // iconText = {Main.Instance.TournamentState.Currencies[
                    //    Main.Instance.TournamentState.currencies[i] - 1]['name']}
                    iconTextSize = "14px"
                />

            if (!BonusAmount[k]) { BonusAmount[k] = [] }
            if (!BonusAmount[k][j]) { BonusAmount[k][j] = [] }
            if (BonusAmount[k] && BonusAmount[k][j]) {
            BonusAmount[k][j][i] =
                <FormInput 
                    // key = {(i * Main.Instance.TournamentState.prizes[k].length + j).toString()}
                    half = {true}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][i] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][i]}
                    ClassName = "form-control register-input mr0"
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, i, j)}
                    description = {Assets.BonusAmount+' ('+Main.Instance.TournamentState.main_currency+')'}
                    iconText = {Main.Instance.TournamentState.main_currency}
                    // placeholder = {Assets.BonusAm_}
                    // description = {
                    // Assets.BonusAmount + ' (' + Main.Instance.TournamentState.Currencies[
                    //    Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                    // iconText = {Main.Instance.TournamentState.Currencies[
                    //    Main.Instance.TournamentState.currencies[i] - 1]['name']}
                    iconTextSize = "14px"
                />
            }

            if (!FreeSpins[k]) { FreeSpins[k] = [] }
            if (!FreeSpins[k][j]) { FreeSpins[k][j] = [] }
            if (FreeSpins[k] && FreeSpins[k][j]) {
            FreeSpins[k][j][i] =
                <FormInput 
                    // key = {(i * Main.Instance.TournamentState.prizes[k].length + j).toString()}
                    half = {true}
                    value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][i] ? '' : 
                            Main.Instance.TournamentState.prizes[k][j]['amount'][i]}
                    ClassName = "form-control register-input mr0"
                    type = "text" 
                    onChange = {(text) => AllowNumberCharactersOnly(text, 4, k, i, j)}
                    description = {Assets.FreeSpins + ' (' + Assets.amount + ')'}
                    iconText = {Main.Instance.TournamentState.main_currency}
                    // placeholder = {Assets.BonusAm_}
                    // description = {
                    // Assets.BonusAmount + ' (' + Main.Instance.TournamentState.Currencies[
                    //    Main.Instance.TournamentState.currencies[i] - 1]['name'] + ')'}
                    // iconText = {Main.Instance.TournamentState.Currencies[
                    //    Main.Instance.TournamentState.currencies[i] - 1]['name']}
                    iconTextSize = "14px"
                />
            }
        }
        }
        }   
    }

    for (let k:number = 0; k < which0Size; k++) {
    if (Main.Instance.TournamentState.prizes[k]) {    
    for (let j:number = 0; j < Main.Instance.TournamentState.prizes[k].length; j++) {
        if (!WoF[k]) { WoF[k] = [] }
        WoF[k][j] =
            <FormInput 
                // key = {j.toString() + which0Size * k}
                half = {true}
                value = {!Main.Instance.TournamentState.prizes[k][j]['amount'][0] ? '' : 
                        Main.Instance.TournamentState.prizes[k][j]['amount'][0]}
                ClassName = "form-control register-input mr0"
                type = "text" 
                onChange = {(text) => AllowNumberCharactersOnly(text, 5, k, j)} 
                // placeholder = {Assets.BonusAm_}
                description = {Assets.WoFSpins}
                iconText = {Assets.WoF}
                iconTextSize = "14px"
            />
    }
    }
    }
    
    let positions: number = 0
    const Positions: number[] = []
    let positionsInConflict: boolean = false
    if (Main.Instance.TournamentState.prizes[which0]) {
    for (const item of Main.Instance.TournamentState.prizes[which0]) {
        if (item['tr'] === 0) { continue }
        positions += parseInt(item['lpr']) - parseInt(item['fpr']) + 1
        for (let i: number = parseInt(item['fpr']); i <= parseInt(item['lpr']); i++) {
            Positions.push (i)
        }
    }
    }

    const uniq = Positions
    .map((name) =>({count: 1, name}))
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count
      return a
    },      {})
    
    const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1)    
    if (duplicates.length > 0) { positionsInConflict = true }

    // let customArrowIcon: JSX.Element = <i className = {Assets_.faNextButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>

    const validationFromTime: boolean =
        Main.Instance.TournamentState.from_time.length === 0 || utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.from_time)
    const validationToTime: boolean =
        Main.Instance.TournamentState.to_time.length === 0 || utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.to_time)

    const validationRecurringFromTime: boolean[] = [true, true, true, true, true]
    for (let i: number = 0; i < validationRecurringFromTime.length; i++) {
    if (Main.Instance.TournamentState.recurring_from_time[i]) {
    validationRecurringFromTime[i] = Main.Instance.TournamentState.recurring_from_time[i].length === 0 ||
    utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.recurring_from_time[i])
    }
    }

    const validationRecurringToTime: boolean[] = [true, true, true, true, true]
    for (let i: number = 0; i < validationRecurringToTime.length; i++) {
    if (Main.Instance.TournamentState.recurring_to_time[i]) {
    validationRecurringToTime[i] = Main.Instance.TournamentState.recurring_to_time[i].length === 0 ||
    utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.recurring_to_time[i])
    }
    }

    const prizes: JSX.Element[] = []

    for (let i: number = 0; i < Main.Instance.TournamentState.prizes[which0].length; i++) {
/*
        let fixedAmount: JSX.Element[] = []
        let bonusAmount: JSX.Element[] = []
        
        if (Main.Instance.TournamentState.currencies)
        for (let j: number = 0; j < Main.Instance.TournamentState.currencies.length; j++) {
            fixedAmount.push (FixedAmount[j * Main.Instance.TournamentState.prizes[which0].length + i])
            bonusAmount.push (BonusAmount[j * Main.Instance.TournamentState.prizes[which0].length + i])
        }

        let woF: JSX.Element = WoF[i]
*/
        // console.log (i + ': ' + fixedAmount.length)
        // fb najprej for po currency, potem se po prizes
        
        prizes.push(
            <div className = "row admin-users-row" key = {i.toString()}>
            <FormSelect
                value = {!Main.Instance.TournamentState.prizes[which0][i]['tr'] ? '0' :
                        Main.Instance.TournamentState.prizes[which0][i]['tr'].toString()}
                data = {Main.Instance.TournamentState.TournamentReward}
                ClassName = "form-control"
                o1 = {Assets.SelectTournamentPrize}
                o2 = {Assets.SelectTournamentPrize}
                onChange = {(text) => SelectTournamentReward(text, which0, i)}
                icon = {Assets_.faRewardLgClass}
            />
            {Main.Instance.TournamentState.prizes[which0][i]['tr'] > 0 ?
            <FormInputNumber
                description = {Assets.FirstPositionRewarded}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {!Main.Instance.TournamentState.prizes[which0][i]['fpr'] ? '' : 
                parseInt(Main.Instance.TournamentState.prizes[which0][i]['fpr']).toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faListOlClass}
                onChange = {(text) => FirstPositionRewarded(text, which0, i)}
            /> : void 0}
            {Main.Instance.TournamentState.prizes[which0][i]['tr'] > 0 ?
            <FormInputNumber
                description = {Assets.LastPositionRewarded}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {!Main.Instance.TournamentState.prizes[which0][i]['lpr'] ? '' : 
                parseInt(Main.Instance.TournamentState.prizes[which0][i]['lpr']).toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faListOlClass}
                onChange = {(text) => LastPositionRewarded(text, which0, i)}
            /> : void 0}
            {Main.Instance.TournamentState.prizes[which0][i]['tr'] === 2 ||
             Main.Instance.TournamentState.prizes[which0][i]['tr'] === 3 ||
             Main.Instance.TournamentState.prizes[which0][i]['tr'] === 4 ||
             Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5 ?
            <FormInputNumber
                description = {Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5 ?
                               Assets.WheelOfFortuneType :
                               Assets.WageringRequirement}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.TournamentState.prizes[which0][i]['wrq'] === undefined ? '0' : 
                         Main.Instance.TournamentState.prizes[which0][i]['wrq'].toString()}  
                type = "number" 
                min = "0"
                icon = {Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5 ?
                        Assets_.faCircleOClass:
                        Assets_.faCheckClass}
                onChange = {(text) => WageringRequirement(text, which0, i)}
            />
            : void 0}

            {/*!Main.Instance.TournamentState.real_money &&*/
            /*Main.Instance.TournamentState.prizes[which0][i]['tr'] === 1 &&
            FixedAmount[which0] && FixedAmount[which0][i] && FixedAmount[which0][i][0] ?
                FixedAmount[which0][i][0]
            : void 0*/}
            {/*!Main.Instance.TournamentState.real_money &&*/
            /*Main.Instance.TournamentState.prizes[which0][i]['tr'] === 2 &&
            BonusAmount[which0] && BonusAmount[which0][i] && BonusAmount[which0][i][0] ?
                BonusAmount[which0][i][0]
            : void 0*/}
            {Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5 &&
            WoF[which0] && WoF[which0][i] && WoF[which0][i] ? WoF[which0][i]: void 0}

            {/*Main.Instance.TournamentState.real_money &&*/
            Main.Instance.TournamentState.prizes[which0][i]['tr'] === 1 &&
            FixedAmount[which0] && FixedAmount[which0][i] ? FixedAmount[which0][i] : void 0}

            {/*Main.Instance.TournamentState.real_money &&*/
            Main.Instance.TournamentState.prizes[which0][i]['tr'] === 2 &&
            BonusAmount[which0] && BonusAmount[which0][i] ? BonusAmount[which0][i] : void 0}

            {/*Main.Instance.TournamentState.real_money &&*/
            Main.Instance.TournamentState.prizes[which0][i]['tr'] === 3 &&
            FreeSpins[which0] && FreeSpins[which0][i] ? FreeSpins[which0][i] : void 0}

            {/*Main.Instance.TournamentState.real_money &&*/
            Main.Instance.TournamentState.prizes[which0][i]['tr'] === 4 &&
            FreeBets[which0] && FreeBets[which0][i] ? FreeBets[which0][i] : void 0}

            {/*Main.Instance.TournamentState.real_money && Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5&&
            FixedAmount[which0] && FixedAmount[which0][i] ? WoF[which0][i] : void 0*/}

            {/*fixedAmount && fixedAmount.length > 0 && Main.Instance.TournamentState.prizes[which0][i]['tr'] === 1 ?
            fixedAmount : void 0*/}
            {/*bonusAmount && bonusAmount.length > 0 && Main.Instance.TournamentState.prizes[which0][i]['tr'] === 2 ?
            bonusAmount : void 0*/}
            {/*woF && Main.Instance.TournamentState.prizes[which0][i]['tr'] === 5 ?
            woF : void 0*/}

            <ButtonIcon
                description = {Assets.DeletePrizePool} 
                click = {() => {DeletePrizePool(which0, i)}} 
                ClassName = "btn btn-danger btn-sm btn-plus fr mr0" 
                icon = {Assets_.faCancelButtonClass}
            />

            <ButtonIcon
                description = {Assets.AddPrizePool} 
                click = {() => {AddPrizePool(which0, i)}} 
                ClassName = "btn btn-success btn-sm btn-plus fr" 
                icon = {Assets_.faPlusButtonClass}
            />

            {parseInt(Main.Instance.TournamentState.tournamentType) === 1 ||
            parseInt(Main.Instance.TournamentState.tournamentType) === 3 ?
            <ButtonIcon
                description = {Assets.ToggleIncludeExcludeGames} 
                click = {() => {ToggleIncludeExcludeGames(which0, i)}} 
                ClassName = {
                    Main.Instance.TournamentState.prizes[which0][i]['tieg'] === 0 ?
                    'btn btn-primary btn-sm btn-plus fr' :
                    Main.Instance.TournamentState.prizes[which0][i]['tieg'] === 1 ?
                    'btn btn-success btn-sm btn-plus fr' :
                    Main.Instance.TournamentState.prizes[which0][i]['tieg'] === 2 ?
                    'btn btn-danger btn-sm btn-plus fr' :
                    'btn btn-default btn-sm btn-plus fr'
                }
                icon = {Assets_.faGamingClass}
            /> : void 0}

            {(parseInt(Main.Instance.TournamentState.tournamentType) === 1 || 
            parseInt(Main.Instance.TournamentState.tournamentType) === 3) &&
            parseInt(Main.Instance.TournamentState.prizes[which0][i]['tieg']) !== 0 ?
            <FormSelect
                floatRight = {true}
                selectedByID = {Main.Instance.TournamentState.prizes[which0][i]['games']}
                value = {!Main.Instance.TournamentState.prizes[which0][i]['game'] ? '0' :
                        Main.Instance.TournamentState.prizes[which0][i]['game']}
                data = {Main.Instance.TournamentState.Games}
                ClassName = "form-control"
                o1 = {Assets.Casino_Games +
                    ' (' + Main.Instance.TournamentState.prizes[which0][i]['games'].length + '/' +
                    Main.Instance.TournamentState.Games.length + ')'}
                o2 = {Assets.Casino_Games + 
                    ' (' + Main.Instance.TournamentState.prizes[which0][i]['games'].length + '/' +
                    Main.Instance.TournamentState.Games.length + ')'}
                onChange = {(text) => IncludeGamesPrizePool(text, which0, i)}
                icon = {Assets_.faGamingClass}
            /> : void 0}

        </div>,
        )
    }

    const days_in_a_week: boolean[] = [false, false, false, false, false, false, false]
    for (const item of Main.Instance.TournamentState.days_in_a_week) {
        days_in_a_week[item - 1] = true
    }

    const rows: JSX.Element[] = []
    // <table
/*
"data":[{
"eventType":"wager","
 value":500,
"timestamp":"2017-09-05T18:21:59.255Z",
"odds":1.22},
*/

    if (Main.Instance.TournamentState.modeLeaderboard && Main.Instance.TournamentState.leaderboard) {
        let count: number = 0

        for (const item of Main.Instance.TournamentState.leaderboard) {
            if (item) {
            rows.push (
                <tr 
                    key = {count.toString()}
                    style = {!item.gotReward ?
                        {background: Assets_.PositionsColors[Assets_.PositionsColors.length - 1]}
                        :
                        item.position === 1 ? {background: Assets_.PositionsColors[0], color : '#fff'} :
                        item.position === 2 ? {background: Assets_.PositionsColors[1], color : '#fff'} :
                        item.position === 3 ? {background: Assets_.PositionsColors[2], color : '#fff'} :
                        {background: Assets_.PositionsColors[3], color : '#fff'}
                    }
                >
                    <td className = "td-center">
                        <button 
                                className = "btn btn-info btn-plus mr0" 
                                onClick = {() => Expand(count)}
                        >
                            <i className = {Assets_.faPlusClass}/>
                        </button>
                    </td>
                    <td className = "td-left">{item.position}.</td>
                    <td className = "td-left">{item.id}</td>
                    {/*<td className = "td-center">{item.roundsWin}</td>*/}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type) === 1 ?
                    <td className = "td-center">{parseFloat(item.winPercent).toFixed(2) + ' %'}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type) === 2 ?
                    <td className = "td-left">{parseFloat(item.totalWin).toFixed(2) + ' ' + item.main_currency}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type) === 3 ?
                    <td className = "td-left">{parseFloat(item.totalWager).toFixed(2) + ' ' + item.main_currency}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type) === 4 ?
                    <td className = "td-center">{item.roundsWager}</td>
                    : void 0}

                    {parseInt(Main.Instance.TournamentState.leaderboard_type2) === 1 ?
                    <td className = "td-center">{parseFloat(item.winPercent).toFixed(2) + ' %'}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type2) === 2 ?
                    <td className = "td-left">{parseFloat(item.totalWin).toFixed(2) + ' ' + item.main_currency}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type2) === 3 ?
                    <td className = "td-left">{parseFloat(item.totalWager).toFixed(2) + ' ' + item.main_currency}</td>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.leaderboard_type2) === 4 ?
                    <td className = "td-center">{item.roundsWager}</td>
                    : void 0}

                    {item.reward.type > 0 ?
                    <td className = "td-left">{Assets.TournamentReward[item.reward.type - 1]}</td>
                    : <td className = "td-left"/>}
                    {item.reward.type > 0 ?
                    <td className = "td-left">{parseFloat(item.reward.amount[0]).toFixed(2) + ' EUR'}</td> :
                    <td className = "td-left"/>}
                    {item.reward.type === 3 || item.reward.type === 4 || item.reward.type === 5 ?
                    <td className = "td-left">{item.reward.wagerRequirement}</td> :
                    <td className = "td-left" style = {{color: '#bbb'}}>{Assets_.NA}</td>}
                </tr>,    
            )
            count++
            }          
        }
    }

    let prizesType = -1
    for (const prize of Main.Instance.TournamentState.prizes) {
        if (prizesType === -1 && prize['tr'] > 0) {
            prizesType = prize['tr']
        } else if (prize['tr'] > 0 && prize['tr'] !== prizesType) {
            prizesType = 0
            break
        }  
    }
    // console.log (prizesType)
    // console.log (Main.Instance.TournamentState.RecurringType.length)
    // console.log (Main.Instance.TournamentState.RecurringTypeReward)
    // SelectRecurringTypeReward
    const buttons: JSX.Element[] = []
    // let Buttons: JSX.Element[] = []
    buttons.push (
        <button
            key = "0"
            style = {{
                float: 'left',
                display: 'block',
                fontSize: '14px',
                padding:'5px 10px 4px 10px',
                fontWeight: 500,
            }}
            className = {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 0 ?
                        'btn btn-default active lbrd' : 'btn btn-default lbrd'}
            onClick = {() => SelectRecurringTypeRecurring('0')}
        >
            {Assets.Default}
        </button>,
    )
/*
    Buttons.push (
        <button
            key = "0"
            style = {{
                float: 'left',
                display: 'block',
                fontSize: '14px',
                padding:'5px 10px 4px 10px',
                fontWeight: 500
            }}
            className = {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 0 ?
                        'btn btn-default active lbrd' : 'btn btn-default lbrd'}
            onClick = {() => SelectRecurringTypeRecurring('0')}
        >
            {Assets.Default}
        </button>
    )
*/
    // console.log ('RecurringType')
    // console.log (Main.Instance.TournamentState.RecurringType)

    // console.log ('RecurringTypeReward')
    // console.log (Main.Instance.TournamentState.RecurringTypeReward)

    for (let i:number = 1; i <= Main.Instance.TournamentState.RecurringType.length; i++) {
        // let proceed: boolean = false
        for (let j:number = 0; j <= Main.Instance.TournamentState.RecurringTypeReward.length; j++) {
            if (Main.Instance.TournamentState.RecurringTypeReward[j] && +Main.Instance.TournamentState.RecurringTypeReward[j].id === i) {
                buttons.push (
                    <button
                        key = {i.toString()}
                        style = {{
                            float: 'left',
                            display: 'block',
                            fontSize: '14px',
                            padding:'5px 10px 4px 10px',
                            fontWeight: 500,
                        }}
                        className = {+Main.Instance.TournamentState.recurringTypeRecurring === +Main.Instance.TournamentState.RecurringTypeReward[j].id ?
                                    'btn btn-default active lbrd' : 'btn btn-default lbrd'}
                        onClick = {() => SelectRecurringTypeRecurring(Main.Instance.TournamentState.RecurringTypeReward[j].id)}
                    >
                        {Main.Instance.TournamentState.RecurringTypeReward[j].name.split(' ')[0]}
                    </button>,
                )
/*
                Buttons.push (
                    <button
                        key = {i.toString()}
                        style = {{
                            float: 'left',
                            display: 'block',
                            fontSize: '14px',
                            padding:'5px 10px 4px 10px',
                            fontWeight: 500
                        }}
                        className = {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === (j + 1) ?
                                    'btn btn-default active lbrd' : 'btn btn-default lbrd'}
                        onClick = {() => SelectRecurringTypeRecurring((j + 1).toString())}
                    >
                        {Main.Instance.TournamentState.RecurringTypeReward[j].name.split(' ')[0]}
                    </button>
                )
*/
                break
            }
        }
    }

    return (
    <div>
        <div className = "clearfix">
            <h3 className = "level fl">
                {Assets.aeTournaments}
            </h3>

            {isUserSystem() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                        Main.Instance.FrontendStoreState.holding_id}
                data = {Main.Instance.DatabaseDataState.masterAllStream}
                ClassName = "form-control"

                o1 = {Assets.SelectMaster}
                o2 = {Assets.MasterQ}
                onChange = {(text) => ApplyMasterSelect(text)}
                icon = {Assets_.faMasterClass}
            /> : void 0}

            {isUserSystem() || isUserMaster() || isUserGameProvider() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                        Main.Instance.FrontendStoreState.operator_id}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                label1 = {Assets.OP}
                label2 = {Assets.BO}
                
                ClassName = "form-control"

                o1 = {selectOperator}
                o2 = {operatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            />
            : void 0}

            {!isUserSubEntity() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                        Main.Instance.FrontendStoreState.site_id}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o1 = {selectSite}
                o2 = {siteQ}
                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0}
        </div>
        {!Main.Instance.TournamentState.modeLeaderboard ? <div className = "clearfix"/> : void 0}

{/*SECTION MAIN*/}
        {!Main.Instance.TournamentState.modeLeaderboard ?
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4 className = "width-auto">
                            {Main.Instance.TournamentState.mode ? 
                            Assets.editTournamentItem + ': ' + 
                            Main.Instance.TournamentState.title_saved : 
                            Assets.addTournamentItem}
                        </h4>
                        {/*
                        <ButtonIcon
                            click = {() => ModePrizePool()}
                            ClassName = {Main.Instance.TournamentState.modePrizepool ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mt-5 mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mt-5 mr0' + PartialPrizepool()}
                            icon = {Assets_.faRewardClass} 
                            text = {Assets.PrizePoolSectionShort}
                            description = {Assets.PrizePoolSection}
                        />
                        */}
                        <ButtonIcon
                            click = {() => ModeRecurring()}
                            ClassName = {Main.Instance.TournamentState.modeRecurring ?
                                        'btn btn-success btn-sm btn-plus fr mr5px ml5px mt-5 mr0' :
                                        'btn btn-default btn-sm btn-plus fr mr5px ml5px mt-5 mr0' + PartialRecurring()}
                            icon = {Assets_.faRefreshClass} 
                            text = {Assets.LeaderboardsAndPrizes}
                            description = {Assets.LeaderboardsAndPrizes}
                        />

                        <ButtonIcon
                            click = {() => ModeAdvanced()}
                            ClassName = {Main.Instance.TournamentState.modeAdvanced ?
                                        'btn btn-success btn-sm btn-plus fr mr5px ml5px mt-5' :
                                        'btn btn-default btn-sm btn-plus fr mr5px ml5px mt-5' + PartialAdvanced()}
                            icon = {Assets_.faCogsClass} 
                            text = {Assets.AdvancedSettingsShort}
                            description = {Assets.AdvancedSettings}
                        />

                        {Main.Instance.TournamentState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => {gotoInsertMode()}} 
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        />
                        : void 0}
                    </div>

                    <div className = "row admin-users-row">
                        <FormInput
                            // half = {true}
                            narrow_ = {true}
                            value = {!Main.Instance.TournamentState.title ? '' : Main.Instance.TournamentState.title}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {title: text})}
                            placeholder = {Assets.pName} 
                            icon = {Assets_.faAchieveClass}
                        />
                        {/*
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.brands}
                            value = {!Main.Instance.TournamentState.brand ? '0' :
                                    Main.Instance.TournamentState.brand}
                            data = {Main.Instance.TournamentState.Brands}
                            
                            ClassName = "form-control"

                            o1 = {Assets.SelectBrands + ' (' + Main.Instance.TournamentState.brands.length 
                            + '/' + Main.Instance.TournamentState.Brands.length + ')'}
                            o2 = {Assets.SelectBrands + ' (' + Main.Instance.TournamentState.brands.length 
                            + '/' + Main.Instance.TournamentState.Brands.length + ')'}
                            onChange = {text => IncludeBrands(text)}
                            icon = {Assets_.faSubEntityClass}
                        />
                        */}
                        <div className = "fl mr">
                        <DateRangePicker
                            startDateId = "sd4"
                            endDateId = "ed4"
                            startDate = {Main.Instance.TournamentState.from_date} 
                            endDate = {Main.Instance.TournamentState.to_date}
                            onDatesChange = {({startDate, endDate}) => DatesChange(startDate, endDate)}
                            focusedInput = {Main.Instance.TournamentState.focusedInput}
                            onFocusChange = {(focusedInput) => Logic.Type.New(Main.Instance.TournamentState, {
                                focusedInput})}
                            showDefaultInputIcon = {true}
                            showClearDates = {true}
                            isOutsideRange = {() => false}
                            displayFormat = {Assets_.dateDisplayFormatdmy}
                            customArrowIcon = {Assets_.customArrowIcon}
                            customCloseIcon = {Assets_.customCloseIcon}
                            minimumNights = {0}
                        />
                        </div>

                        <FormSelect
                            floatRight = {true}
                            right = {true}                        
                            value = {!Main.Instance.TournamentState.leaderboard_type2 ? '0' :
                                    Main.Instance.TournamentState.leaderboard_type2}
                            data = {Main.Instance.TournamentState.Leaderboard_type2}
                            ClassName = "form-control"
                            o1 = {Assets.SelectSecondaryCriteria}
                            o2 = {Assets.SelectSecondaryCriteria}
                            onChange = {(text) => 
                                Logic.Type.New(Main.Instance.TournamentState, {leaderboard_type2: text})
                            }
                            icon = {Assets_.faListOlClass}
                        />
                        
                        <FormSelect
                            floatRight = {true}
                            value = {!Main.Instance.TournamentState.leaderboard_type ? '0' :
                                    Main.Instance.TournamentState.leaderboard_type}
                            data = {Main.Instance.TournamentState.Leaderboard_type}
                            ClassName = "form-control"
                            o1 = {Assets.SelectLeaderboardType}
                            o2 = {Assets.SelectLeaderboardType}
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.TournamentState, {leaderboard_type: text})
                                DbRoutes.handleLeaderboard()
                                }
                            }
                            icon = {Assets_.faListOlClass}
                        />

                        <FormSelect
                            floatRight = {true}
                            value = {!Main.Instance.TournamentState.tournamentType ? '0' :
                                    Main.Instance.TournamentState.tournamentType}
                            data = {Main.Instance.TournamentState.TournamentType}
                            ClassName = "form-control"
                            o1 = {Assets.SelectTournamentType}
                            o2 = {Assets.SelectTournamentType}
                            onChange = {(text) => SelectTournamentType(text)}
                            icon = {Assets_.facogClass}
                        />                   
                    </div>
                    {parseInt(Main.Instance.TournamentState.tournamentType) === 1 ||
                    parseInt(Main.Instance.TournamentState.tournamentType) === 2 ?
                    <div className = "row admin-users-row">
                        <FormSelect
                            value = {!Main.Instance.TournamentState.odds_type ? '0' :
                                    Main.Instance.TournamentState.odds_type}
                            data = {Main.Instance.TournamentState.FixedLiveOdds}
                            ClassName = "form-control"
                            o1 = {Assets.SelectOddsType}
                            o2 = {Assets.SelectOddsType}
                            onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {odds_type: text})}
                            icon = {Assets_.faFootballClass}
                        />

                        {parseInt(Main.Instance.TournamentState.odds_type) !== 0 ?
                        <FormSelect
                            value = {!Main.Instance.TournamentState.odds_req ? '0' :
                                    Main.Instance.TournamentState.odds_req}
                            data = {Main.Instance.TournamentState.OddsRequirement}
                            ClassName = "form-control"
                            o1 = {Assets.SelectOddsRequirement}
                            o2 = {Assets.SelectOddsRequirement}
                            onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {odds_req: text})}
                            icon = {Assets_.faFootballClass}
                        /> : void 0}

                        {parseInt(Main.Instance.TournamentState.odds_req) !== 0 ?
                        <FormInput
                            full = {true} 
                            value = {!Main.Instance.TournamentState.odds ? '' : 
                                    Main.Instance.TournamentState.odds}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowDecimalCharactersOnly(text) ? Logic.Type.New(Main.Instance.TournamentState, {odds: text}) : void 0} 
                            placeholder = {Assets.Odds}
                            iconText = {Assets.OddsRequirementAbbrev[
                                parseInt(Main.Instance.TournamentState.odds_req) - 1]}
                            iconTextSize = "14px"
                        />
                        : void 0}

                        {parseInt(Main.Instance.TournamentState.odds_type) > 1 ? 
                        <RadioButton3
                            value = {Main.Instance.TournamentState.Odds2 === undefined ? false :
                                    Main.Instance.TournamentState.Odds2}
                            text = {Assets.add + ' ' + Assets.addFixedLiveOdds[
                                parseInt(Main.Instance.TournamentState.odds_type) - 2]}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fl"
                            title = {Assets.optInDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => Odds2()} 
                        /> : void 0}

                        {/*
                        <span
                            style = {{
                                width:'18%',
                                marginRight:'2%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '14px',
                                paddingTop:'8px',
                                position: 'relative',
                                textAlign: 'left'
                            }}
                            className = "label label-primary"
                        >
                            <i
                                style = {{position:'absolute', color:'#fff', left: '13px'}}
                                className = {Assets_.faFootballClass}
                            />
                            <span style = {{position:'absolute', left: '53px', fontWeight: 500}}>
                            {Assets.addFixedLiveOdds[parseInt(Main.Instance.TournamentState.odds_type) - 2]}
                            </span>
                        </span>
                        */}

                        {Main.Instance.TournamentState.Odds2 && parseInt(Main.Instance.TournamentState.odds_type) > 1 ?

                        <FormSelect
                            value = {!Main.Instance.TournamentState.odds_req2 ? '0' :
                                    Main.Instance.TournamentState.odds_req2}
                            data = {Main.Instance.TournamentState.OddsRequirement}
                            ClassName = "form-control"
                            o1 = {Assets.SelectOddsRequirement}
                            o2 = {Assets.SelectOddsRequirement}
                            onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {odds_req2: text})}
                            icon = {Assets_.faFootballClass}
                        /> : void 0}

                        {Main.Instance.TournamentState.Odds2 && parseInt(Main.Instance.TournamentState.odds_type) > 1 &&
                        parseInt(Main.Instance.TournamentState.odds_req2) !== 0 ?
                        <FormInput
                            full = {true} 
                            value = {!Main.Instance.TournamentState.odds2 ? '' : 
                                    Main.Instance.TournamentState.odds2}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowDecimalCharactersOnly(text) ? Logic.Type.New(Main.Instance.TournamentState, {odds2: text}) : void 0} 
                            placeholder = {Assets.Odds}
                            iconText = {Assets.OddsRequirementAbbrev[
                                parseInt(Main.Instance.TournamentState.odds_req2) - 1]}
                            iconTextSize = "14px"
                        />
                        : void 0}
                    </div>
                    : void 0}
                    {parseInt(Main.Instance.TournamentState.tournamentType) === 1 ||
                    parseInt(Main.Instance.TournamentState.tournamentType) === 3 ?
                    <div className = "row admin-users-row">
                    {/*
                    <FormSelect
                        value = {!Main.Instance.TournamentState.casino_vendors ? '0' :
                                Main.Instance.TournamentState.casino_vendors}
                        data = {Main.Instance.TournamentState.Casino_vendors}
                        ClassName = "form-control"
                        o1 = {Assets.SelectCasinoVendors}
                        o2 = {Assets.SelectCasinoVendors}
                        onChange = {text => SelectVendors(text)}
                        icon = {Assets_.faCompanyClass}
                    />*/}
                    {/*parseInt(Main.Instance.TournamentState.casino_vendors) !== 0 ?*/
                    <FormSelect
                        selectedByID = {Main.Instance.TournamentState.vendors}
                        value = {!Main.Instance.TournamentState.vendor ? '0' :
                                Main.Instance.TournamentState.vendor}
                        data = {Main.Instance.TournamentState.Vendors}
                        ClassName = "form-control"
                        o1 = {Assets.Casino_Vendors + 
                            ' (' + Main.Instance.TournamentState.vendors.length + '/' +
                            Main.Instance.TournamentState.Vendors.length + ')'}
                        o2 = {Assets.Casino_Vendors +
                            ' (' + Main.Instance.TournamentState.vendors.length + '/' +
                            Main.Instance.TournamentState.Vendors.length + ')'}
                        onChange = {(text) => IncludeVendors(text)}
                        icon = {Assets_.faCompanyClass}
                    />/* : void 0*/}   

                    {/*
                    <FormSelect
                        value = {!Main.Instance.TournamentState.casino_games ? '0' :
                                Main.Instance.TournamentState.casino_games}
                        data = {Main.Instance.TournamentState.Casino_games}
                        ClassName = "form-control"
                        o1 = {Assets.SelectCasinoGames}
                        o2 = {Assets.SelectCasinoGames}
                        onChange = {text => SelectGames(text)}
                        icon = {Assets_.faGamingClass}
                    />*/}

                    {/* parseInt(Main.Instance.TournamentState.casino_games) !== 0 ?*/
                    <FormSelect
                        selectedByID = {Main.Instance.TournamentState.games}
                        value = {!Main.Instance.TournamentState.game ? '0' :
                                Main.Instance.TournamentState.game}
                        data = {Main.Instance.TournamentState.Games}
                        ClassName = "form-control"
                        o1 = {Assets.Casino_Games +
                            ' (' + Main.Instance.TournamentState.games.length + '/' +
                            Main.Instance.TournamentState.Games.length + ')'}
                        o2 = {Assets.Casino_Games + 
                            ' (' + Main.Instance.TournamentState.games.length + '/' +
                            Main.Instance.TournamentState.Games.length + ')'}
                        onChange = {(text) => IncludeGames(text)}
                        icon = {Assets_.faGamingClass}
                    />/* : void 0*/}
                    </div> : void 0}

                    <div className = "row admin-users-row">
                        {Main.Instance.DatabaseDataState.currenciesStream ?
                        <FormSelect
                            verynarrow = {true}
                            selectedByID = {Main.Instance.TournamentState.currencies}
                            mainCurrency = {parseInt(Main.Instance.TournamentState.mainCurrency)}
                            value = {!Main.Instance.TournamentState.currencySelected ? '0' :
                                    Main.Instance.TournamentState.currencySelected}
                            data = {Main.Instance.TournamentState.Currencies}
                            ClassName = "form-control"
                            o1 = {'(' + Main.Instance.TournamentState.currencies.length + '/' +
                                Main.Instance.TournamentState.Currencies.length + ')'}
                            o2 = {'(' + Main.Instance.TournamentState.currencies.length + '/' +
                                Main.Instance.TournamentState.Currencies.length + ')'}
                            onChange = {(text) => IncludeCurrencies(text)}
                            icon = {Assets_.faPaymentClass}
                        /> : void 0}

                        {Main.Instance.DatabaseDataState.currenciesStream ?
                        <FormSelect
                            verynarrow = {true}
                            verynarrow2 = {true}
                            value = {!Main.Instance.TournamentState.mainCurrency ? '0' :
                                    Main.Instance.TournamentState.mainCurrency}
                            data = {Main.Instance.TournamentState.Currencies}
                            ClassName = "form-control"
                            o1 = {Assets.Main + '?'}
                            o2 = {Assets.Main + '?'}
                            onChange = {(text) => Logic.Type.New (Main.Instance.TournamentState, {mainCurrency : text})}
                            icon = {Assets_.faPaymentClass}
                        /> : void 0}
                        <span style = {{float: 'left', display: 'block'}}>&nbsp;&nbsp;</span>
                        <FormInput 
                            date = {true} 
                            validation = {validationFromTime} 
                            value = {!Main.Instance.TournamentState.from_time ? '' : 
                                    Main.Instance.TournamentState.from_time} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 0)} 
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationToTime} 
                            value = {!Main.Instance.TournamentState.to_time ? '' : 
                                    Main.Instance.TournamentState.to_time} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 1)} 
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />
                        <span style = {{display:'block', float: 'left'}}>&nbsp;&nbsp;</span>
                        <RadioButton3
                            value = {Main.Instance.TournamentState.itipp === undefined ? 
                                    false : Main.Instance.TournamentState.itipp}
                            text = {Assets.includeTournamentInPointsProgram}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.includeTournamentInPointsProgramDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => Itipp()}
                        />

                        <RadioButton3
                            value = {Main.Instance.TournamentState.opt_in === undefined ?
                                    false : Main.Instance.TournamentState.opt_in}
                            text = {Assets.optIn}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.optInDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => OptIn()}
                        />

                        {!(optInCost.length > 0 && Main.Instance.TournamentState.opt_in === true) ? void 0 :
                        <span className = "label label-default label-tour ml mr">
                            {Assets.optInCostShort}
                        </span>}
                        {optInCost.length > 0 && Main.Instance.TournamentState.opt_in === true ? optInCost : void 0}

                        <ButtonIcon 
                            description = {!Main.Instance.TournamentState.mode ?
                                            Assets.saveChanges : 
                                            Assets.updateChanges} 
                            click = {() => {handleInsertUpdate()}}
                            ClassName = {/*Main.Instance.TournamentState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr mt-5 mr0 ':*/
                                        'btn btn-primary btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div>
{/*SECTION MAIN*/}
{/*SECTION ADVANCED*/}
            {Main.Instance.TournamentState.modeAdvanced ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb20'}
            >            
                <div className = "create-input">
                    {/*Main.Instance.TournamentState.recurring === true ?*/}
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New (Main.Instance.TournamentState, {modeAdvanced: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus" 
                            icon = {Assets_.faCancelButtonClass}
                        />
                        <span
                            style = {{
                                width:'13%',
                                marginRight:'2%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                            }}
                            className = "label label-default"
                        >   <i className = {Assets_.faCogsClass} style = {{marginRight: '7px'}}/>
                            {Assets.AdvancedSettingsShort}
                        </span>

                        <FormSelect
                            value = {!Main.Instance.TournamentState.notify_players ? '0' :
                                    Main.Instance.TournamentState.notify_players}
                            data = {Main.Instance.TournamentState.NotifyPlayers}
                            ClassName = "form-control"
                            o1 = {Assets.SelectPlayerNotification}
                            o2 = {Assets.SelectPlayerNotification}
                            onChange = {(text) => Logic.Type.New (Main.Instance.TournamentState, {notify_players: text})}
                            icon = {Assets_.faPhoneClass}
                        />

                        <FormInput
                            full = {true}
                            value = {!Main.Instance.TournamentState.min_rounds ? '' : 
                                    Main.Instance.TournamentState.min_rounds}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowNaturalCharactersOnly(text, 0)} 
                            // placeholder = {Assets.WagerRoundsShort} 
                            description = {Assets.MinimumNumberOfWagerRounds}
                            iconText = {'#' + Assets.Min + '.'}
                            iconTextSize = "14px"
                        />

                        <FormInput
                            full = {true} 
                            value = {!Main.Instance.TournamentState.max_rounds ? '' : 
                                    Main.Instance.TournamentState.max_rounds}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowNaturalCharactersOnly(text, 1)} 
                            // placeholder = {Assets.WagerRoundsShort} 
                            description = {Assets.MaximumNumberOfWagerRounds}
                            iconText = {'#' + Assets.Max + '.'}
                            iconTextSize = "14px"
                        />

                        {!(minAmount && minAmount.length > 0) ? void 0 :
                        <span className = "label label-default label-tour mr">{Assets.Min_}</span>}
                        {minAmount && minAmount.length > 0 ? minAmount : void 0}

                        {!(maxAmount && maxAmount.length > 0) ? void 0 :
                        <span className="label label-default label-tour mr">{Assets.Max_}</span>}
                        {maxAmount && maxAmount.length > 0 ? maxAmount : void 0}

                        {/*Main.Instance.DatabaseDataState.levelsAllDetailsStream ?
                        <span style = {{display:'block',float:'left',marginRight:'10px',fontSize:'20px'}}>
                        {Assets.SetLevelRequirement}
                        </span> : void 0*/}

                        {Main.Instance.DatabaseDataState.levelsAllDetailsStream ?
                        <FormInputNumber
                            description = {Assets.SublevelMinDesc}
                            // placeholder = {Assets.LevelMin}
                            outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                            ClassName = "h30 w40px pl3"
                            value = {!Main.Instance.TournamentState.sublevel_min ? '0' : 
                                    Main.Instance.TournamentState.sublevel_min.toString()}
                            type = "number" 
                            min = "0"
                            max = "100" // this is dynamic actually
                            iconTextC = "#fff"
                            background = {Main.Instance.CRMPointState.level_min > 0 ?
                              isLevelFinalLevel(Main.Instance.CRMPointState.level_min) ? '#337ab7':'#5cb85c':'#d9534f'}
                            iconText = {GetLevelIconText(Main.Instance.TournamentState.level_min)}
                            // Main.Instance.TournamentState.level_min.toString()
                            iconDescription = {Main.Instance.TournamentState.level_min === 0 ?
                                            Assets.MinLevelNotSet :
                                            Assets.Level + ': ' + 
Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.TournamentState.level_min - 1]['name'] + 
' (' + Main.Instance.TournamentState.level_min + ')' +
(Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.TournamentState.level_min - 1]['final_level'] ?
' - ' + Assets.finalLevel: '')}
                            // iconFixedWidth = {true}
                            readOnly = {Main.Instance.TournamentState.level_min === 
                                        Main.Instance.DatabaseDataState.levelsAllDetailsStream.length}
                            handleClick = {() => LevelMinChanged()}
                            onChange = {(text) => SubLevelMinChanged(parseInt(text))}
                        /> : void 0}
                        {/*Main.Instance.DatabaseDataState.levelsAllDetailsStream ?
                        <span style = {{display:'block',float:'left',marginRight:'5px',fontSize:'20px'}}>&mdash;</span>
                        : void 0*/}
                        {Main.Instance.DatabaseDataState.levelsAllDetailsStream ?
                        <FormInputNumber
                            description = {Assets.SublevelMaxDesc}
                            // placeholder = {Assets.LevelMax}
                            outerDiv = "rows-per-table input-group fl ml0 pl0 mr0"
                            ClassName = "h30 w40px pl3"
                            value = {!Main.Instance.TournamentState.sublevel_max ? '0' : 
                                    Main.Instance.TournamentState.sublevel_max.toString()}
                            type = "number" 
                            min = "0"
                            max = "100" // this is dynamic actually
                            iconTextC = "#fff"
                            background = {Main.Instance.CRMPointState.level_max > 0 ?
                              isLevelFinalLevel(Main.Instance.CRMPointState.level_max) ? '#337ab7':'#5cb85c':'#d9534f'}
                            iconText = {GetLevelIconText(Main.Instance.TournamentState.level_max)}
                            // iconText = {Main.Instance.TournamentState.level_max.toString()}
                            iconDescription = {Main.Instance.TournamentState.level_max === 0 ?
                                Assets.MaxLevelNotSet : Assets.Level + ': ' + 
Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.TournamentState.level_max - 1]['name'] + 
' (' + Main.Instance.TournamentState.level_max + ')' + 
(Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.TournamentState.level_max - 1]['final_level'] ?
' - ' + Assets.finalLevel: '')}
                            readOnly = {Main.Instance.TournamentState.level_max === 
                                        Main.Instance.DatabaseDataState.levelsAllDetailsStream.length}
                            handleClick = {() => LevelMaxChanged()}
                            onChange = {(text) => SubLevelMaxChanged(parseInt(text))}
                        /> : void 0}                        
                    </div>
                </div>
            </div> : void 0}
{/*SECTION ADVANCED END*/}
{/*SECTION LEADERBOARD*/}
            {/*Main.Instance.TournamentState.modeRecurring ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb20'}
            >            
                <div className = "create-input">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New (Main.Instance.TournamentState, {modeRecurring: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus"
                            icon = {Assets_.faCancelButtonClass}
                        />
                        <span
                            style = {{
                                width:'18%',
                                marginRight:'2%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                                fontWeight: 700,
                                marginBottom: '10px'
                            }}
                            className = "label label-default"
                        >
                            <i className = {Assets_.faRefreshClass} style = {{marginRight: '7px'}}/>
                            {Assets.LeaderboardsExtra}
                        </span>
                        {Buttons}
                        <FormSelect
                            floatRight = {true}
                            value = {!Main.Instance.TournamentState.recurring_type ? '0' :
                                    Main.Instance.TournamentState.recurring_type}
                            data = {Main.Instance.TournamentState.RecurringType}
                            selectedByID = {Main.Instance.TournamentState.recurring_types}
                            ClassName = "form-control"
                            o1 = {Assets.SelectRecurringType}
                            o2 = {Assets.SelectRecurringType}
                            onChange = {text => IncludeRecurringType(text)}
                            icon = {Assets_.faRefreshClass}
                        />                        
                    </div>

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 0 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[0]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[0] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[0]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 2, 0)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[0]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[0] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[0]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 3, 0)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal0 && maxTotal0.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal0 && maxTotal0.length > 0 ? maxTotal0 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[0] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[0]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => PartOfWager(0)}
                        />

                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 1 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>

                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.days_in_a_week}
                            value = {!Main.Instance.TournamentState.RecurringInWeek ? '0' :
                                    Main.Instance.TournamentState.RecurringInWeek}
                            data = {Main.Instance.TournamentState.DaysInAWeek}
                            title = {Assets.SelectDaysInWeekDesc}
                            ClassName = "form-control"
                            o1 = {Assets.SelectDaysInWeek}
                            o2 = {Assets.SelectDaysInWeek}
                            onChange = {text => IncludeDaysInWeek(text)}
                            icon = {Assets_.faCalendarGClass}
                        />

                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.days_in_a_month}
                            value = {!Main.Instance.TournamentState.RecurringInMonth ? '0' :
                                    Main.Instance.TournamentState.RecurringInMonth}
                            data = {Main.Instance.TournamentState.DaysInAMonth}
                            title = {Assets.SelectDaysInMonthDesc}
                            ClassName = "form-control"
                            o1 = {Assets.SelectDaysInMonth}
                            o2 = {Assets.SelectDaysInMonth}
                            onChange = {text => IncludeDaysInMonth(text)}
                            icon = {Assets_.faCalendarGClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[1]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[1] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[1]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 2, 1)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[1]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[1] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[1]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 3, 1)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal1 && maxTotal1.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal1 && maxTotal1.length > 0 ? maxTotal1 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[1] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[1]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => PartOfWager(1)}
                        />
                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 2 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.weeks}
                            value = {!Main.Instance.TournamentState.RecurringWeek ? '0' :
                                    Main.Instance.TournamentState.RecurringWeek}
                            data = {Main.Instance.TournamentState.Weeks}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectWeeks}
                            o2 = {Assets.SelectWeeks}
                            onChange = {text => IncludeWeeks(text)}
                            icon = {Assets_.faCalendarGClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[2]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[2] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[2]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 2, 2)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[2]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[2] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[2]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 3, 2)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal2 && maxTotal2.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal2 && maxTotal2.length > 0 ? maxTotal2 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[2] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[2]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => PartOfWager(2)}
                        />
                        
                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 3 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.months}
                            value = {!Main.Instance.TournamentState.RecurringMonth ? '0' :
                                    Main.Instance.TournamentState.RecurringMonth}
                            data = {Main.Instance.TournamentState.Months}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectMonths}
                            o2 = {Assets.SelectMonths}
                            onChange = {text => IncludeMonths(text)}
                            icon = {Assets_.faCalendarGClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[3]}
                            value = {!Main.Instance.TournamentState.recurring_from_time[3] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[3]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 2, 3)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true}
                            validation = {validationRecurringToTime[3]}
                            value = {!Main.Instance.TournamentState.recurring_to_time[3] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[3]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 3, 3)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal3 && maxTotal3.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal3 && maxTotal3.length > 0 ? maxTotal3 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[3] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[3]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => PartOfWager(3)}
                        />

                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 4 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.annual}
                            value = {!Main.Instance.TournamentState.RecurringYear ? '0' :
                                    Main.Instance.TournamentState.RecurringYear}
                            data = {Main.Instance.TournamentState.Annual}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectAnnual}
                            o2 = {Assets.SelectAnnual}
                            onChange = {text => IncludeAnnual(text)}
                            icon = {Assets_.faCalendarGClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[4]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[4] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[4]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 2, 4)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[4]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[4] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[4]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {text => AllowTimeCharactersOnly(text, 3, 4)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal4 && maxTotal4.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal4 && maxTotal4.length > 0 ? maxTotal4 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[4] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[4]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {text => PartOfWager(4)}
                        />
                    </div> : void 0}
                </div>
                            </div> : void 0*/}
{/*SECTION LEADERBOARD END*/}
{/*SECTION PRIZE POOL*/}
            {Main.Instance.TournamentState.modeRecurring ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb20'}
            >            
                <div className = "create-input">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New (Main.Instance.TournamentState, {modeRecurring: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus"
                            icon = {Assets_.faCancelButtonClass}
                        />
                        <span
                            style = {{
                                width:'20%',
                                marginRight:'2%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                                fontWeight: 700,
                                marginBottom: '10px',
                            }}
                            className = "label label-default"
                        >   {<i className = {Assets_.faRefreshClass} style = {{marginRight: '7px'}}/>}
                            {Assets.LeaderboardsAndPrizes}
                        </span>
                        {buttons}
                        <FormSelect
                            floatRight = {true}
                            value = {!Main.Instance.TournamentState.recurring_type ? '0' :
                                    Main.Instance.TournamentState.recurring_type}
                            data = {Main.Instance.TournamentState.RecurringType}
                            selectedByID = {Main.Instance.TournamentState.recurring_types}
                            ClassName = "form-control wid100"
                            o1 = {Assets.SelectRecurringType}
                            o2 = {Assets.SelectRecurringType}
                            onChange = {(text) => IncludeRecurringType(text)}
                            icon = {Assets_.faRefreshClass}
                        />

                        {/*!positionsInConflict ?
                        <span
                            style = {{
                                width:'18%',
                                // marginTop: '-5px',
                                marginRight:'2%',
                                float: 'right',
                                display: 'block',
                                height: '30px',
                                fontSize: '14px',
                                paddingTop:'9px',
                                fontWeight: 700,
                                marginBottom: '10px'
                            }}
                            className = "label label-default"
                        >
                        {positions === 1 ? positions.toString() + ' ' + Assets.PositionRewarded :
                        positions.toString() + ' ' + Assets.PositionsRewarded}
                        </span>
                        : void 0*/}

                        {prizes.length > 1 ?
                        <span
                            style = {{
                                width:'18%',
                                // marginTop: '-5px',
                                marginRight:'2%',
                                float: 'right',
                                display: 'block',
                                height: '30px',
                                fontSize: '14px',
                                paddingTop:'9px',
                                fontWeight: 700,
                                marginBottom: '10px',
                                position:'relative',
                            }}
                            className = {positionsInConflict ? 'label label-danger' : 'label label-success'}
                        >
                        <span style = {{position:'absolute', right:'4px', fontWeight:700, fontSize:'16px', top:'0px'}}>
                        {positions}</span>
                        {positionsInConflict ? Assets.PositionsInConflict : Assets.PositionsNotInConflict}
                        </span> : void 0}                        
                    </div>

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 0 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[0]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[0] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[0]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 2, 0)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[0]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[0] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[0]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 3, 0)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal0 && maxTotal0.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal0 && maxTotal0.length > 0 ? maxTotal0 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager === undefined ||
                                    Main.Instance.TournamentState.part_of_wager[0] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[0]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => PartOfWager(0)}
                        />

                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 1 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {/*Main.Instance.TournamentState.daily ?
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.days_in_a_week}
                            value = {!Main.Instance.TournamentState.RecurringInWeek ? '0' :
                                    Main.Instance.TournamentState.RecurringInWeek}
                            data = {Main.Instance.TournamentState.DaysInAWeek}
                            title = {Assets.SelectDaysInWeekDesc}
                            ClassName = "form-control"
                            o1 = {Assets.SelectDaysInWeek}
                            o2 = {Assets.SelectDaysInWeek}
                            onChange = {text => IncludeDaysInWeek(text)}
                            icon = {Assets_.faCalendarGClass}
                        />
                        :
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.days_in_a_month}
                            value = {!Main.Instance.TournamentState.RecurringInMonth ? '0' :
                                    Main.Instance.TournamentState.RecurringInMonth}
                            data = {Main.Instance.TournamentState.DaysInAMonth}
                            title = {Assets.SelectDaysInMonthDesc}
                            ClassName = "form-control"
                            o1 = {Assets.SelectDaysInMonth}
                            o2 = {Assets.SelectDaysInMonth}
                            onChange = {text => IncludeDaysInMonth(text)}
                            icon = {Assets_.faCalendarGClass}
                        />*/}
                        <RadioButton3
                            value = {Main.Instance.TournamentState.daily === undefined ? 
                                    false : Main.Instance.TournamentState.daily}
                            text = "weekly/monthly"
                            ClassName = "input-group-radiobutton3a margin-bottom-sm"
                            title = "toggle Select day weekly/monthly"
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => Daily()}
                        />
                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[1]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[1] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[1]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 2, 1)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[1]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[1] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[1]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 3, 1)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal1 && maxTotal1.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal1 && maxTotal1.length > 0 ? maxTotal1 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[1] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[1]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => PartOfWager(1)}
                        />
                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 1 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {Main.Instance.TournamentState.daily ?
                        getButtons(Main.Instance.TournamentState.days_in_a_week, Main.Instance.TournamentState.DaysInAWeek, IncludeDaysInWeek) :
                        getButtons(Main.Instance.TournamentState.days_in_a_month, Main.Instance.TournamentState.DaysInAMonth, IncludeDaysInMonth)}
                    </div>
                    : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 2 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {/*
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.weeks}
                            value = {!Main.Instance.TournamentState.RecurringWeek ? '0' :
                                    Main.Instance.TournamentState.RecurringWeek}
                            data = {Main.Instance.TournamentState.Weeks}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectWeeks}
                            o2 = {Assets.SelectWeeks}
                            onChange = {text => IncludeWeeks(text)}
                            icon = {Assets_.faCalendarGClass}
                        />
                        */}

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[2]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[2] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[2]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 2, 2)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[2]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[2] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[2]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 3, 2)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal2 && maxTotal2.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal2 && maxTotal2.length > 0 ? maxTotal2 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[2] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[2]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => PartOfWager(2)}
                        />
                        
                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 2 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {getButtons(Main.Instance.TournamentState.weeks, Main.Instance.TournamentState.Weeks, IncludeWeeks)}
                    </div>
                    : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 3 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {/*
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.months}
                            value = {!Main.Instance.TournamentState.RecurringMonth ? '0' :
                                    Main.Instance.TournamentState.RecurringMonth}
                            data = {Main.Instance.TournamentState.Months}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectMonths}
                            o2 = {Assets.SelectMonths}
                            onChange = {text => IncludeMonths(text)}
                            icon = {Assets_.faCalendarGClass}
                        />
                        */}

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[3]}
                            value = {!Main.Instance.TournamentState.recurring_from_time[3] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[3]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 2, 3)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true}
                            validation = {validationRecurringToTime[3]}
                            value = {!Main.Instance.TournamentState.recurring_to_time[3] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[3]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 3, 3)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal3 && maxTotal3.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal3 && maxTotal3.length > 0 ? maxTotal3 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[3] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[3]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => PartOfWager(3)}
                        />

                    </div> : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 3 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {getButtons(Main.Instance.TournamentState.months, Main.Instance.TournamentState.Months, IncludeMonths)}
                    </div>
                    : void 0}

                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 4 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {/*
                        <FormSelect
                            selectedByID = {Main.Instance.TournamentState.annual}
                            value = {!Main.Instance.TournamentState.RecurringYear ? '0' :
                                    Main.Instance.TournamentState.RecurringYear}
                            data = {Main.Instance.TournamentState.Annual}
                            // title = ""
                            ClassName = "form-control"
                            o1 = {Assets.SelectAnnual}
                            o2 = {Assets.SelectAnnual}
                            onChange = {text => IncludeAnnual(text)}
                            icon = {Assets_.faCalendarGClass}
                        />
                        */}

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringFromTime[4]} 
                            value = {!Main.Instance.TournamentState.recurring_from_time[4] ? '' : 
                                    Main.Instance.TournamentState.recurring_from_time[4]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 2, 4)}
                            placeholder = {Assets.Start}
                            description = {Assets.FromAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        <FormInput 
                            date = {true} 
                            validation = {validationRecurringToTime[4]} 
                            value = {!Main.Instance.TournamentState.recurring_to_time[4] ? '' : 
                                    Main.Instance.TournamentState.recurring_to_time[4]} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 3, 4)}
                            placeholder = {Assets.End} 
                            description = {Assets.ToAnyPeriod}
                            icon = {Assets_.faTimeNarrowClass}
                        />

                        {!(maxTotal4 && maxTotal4.length > 0) ? void 0 :
                        <span className ="label label-default label-tour ml mr">
                            {Assets.betAmountTotal}
                        </span>}
                        {maxTotal4 && maxTotal4.length > 0 ? maxTotal4 : void 0}

                        <RadioButton3
                            value = {Main.Instance.TournamentState.part_of_wager[4] === undefined ? 
                                    false : Main.Instance.TournamentState.part_of_wager[4]}
                            text = {Assets.IncludePartOfWager}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm mr0"
                            title = {Assets.IncludePartOfWager}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => PartOfWager(4)}
                        />
                    </div> : void 0}
                    {parseInt(Main.Instance.TournamentState.recurringTypeRecurring) === 4 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        {getButtons(Main.Instance.TournamentState.annual, Main.Instance.TournamentState.Annual, IncludeAnnual)}
                    </div>
                    : void 0}                    
                    <hr style = {{margin: '3px 0px 8px 0px', borderTop: '2px solid #eee'}}/>
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    {/*
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New (Main.Instance.TournamentState, {modeRecurring: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus" 
                            icon = {Assets_.faCancelButtonClass}
                        />
                        <span
                            style = {{
                                width:'18%',
                                // marginTop: '-5px',
                                marginRight:'2%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                                fontWeight: 700,
                                marginBottom: '10px'
                            }}
                            className = "label label-default"
                        >
                        <i className = {Assets_.faRewardClass} style = {{marginRight: '7px'}}/>
                        {Assets.PrizePoolSection}
                        </span>
                        {buttons}
                        
                        {!positionsInConflict ?
                        <span
                            style = {{
                                width:'18%',
                                // marginTop: '-5px',
                                marginRight:'2%',
                                float: 'right',
                                display: 'block',
                                height: '30px',
                                fontSize: '14px',
                                paddingTop:'9px',
                                fontWeight: 700,
                                marginBottom: '10px'
                            }}
                            className = "label label-default"
                        >
                        {positions === 1 ? positions.toString() + ' ' + Assets.PositionRewarded :
                        positions.toString() + ' ' + Assets.PositionsRewarded}
                        </span>
                        : void 0}

                        {prizes.length > 1 ?
                        <span
                            style = {{
                                width:'18%',
                                // marginTop: '-5px',
                                marginRight:'2%',
                                float: 'right',
                                display: 'block',
                                height: '30px',
                                fontSize: '14px',
                                paddingTop:'9px',
                                fontWeight: 700,
                                marginBottom: '10px'
                            }}
                            className = {positionsInConflict ? 'label label-danger' : 'label label-success'}
                        >
                        {positionsInConflict ?
                        Assets.PositionsInConflict : Assets.PositionsNotInConflict}
                        </span> : void 0*/}
                    </div>
                    {prizes}
            </div>
            </div>
            : void 0}
{/*SECTION PRIZE POOL END*/}
        </div>
        : void 0}
{/*SECTION TOP END*/}

        {!Main.Instance.TournamentState.modeLeaderboard ?
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.TournamentState.pSize ? '' : 
                Main.Instance.TournamentState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
{/*
            <FormSelect 
                span = {true}
                value = {!Main.Instance.TournamentState.filter_active ? '0' : 
                        Main.Instance.TournamentState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {text => ApplyFilterActive(text)} 
            />
*/}
            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.TournamentState.count ? Main.Instance.TournamentState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.TournamentState.count ? Main.Instance.TournamentState.pNum * 
                Main.Instance.TournamentState.pSize + 1 : 0}{' - '}
                {Main.Instance.TournamentState.count ? 
                ((Main.Instance.TournamentState.pNum + 1) * 
                Main.Instance.TournamentState.pSize > Main.Instance.TournamentState.count ? 
                Main.Instance.TournamentState.count : 
                (Main.Instance.TournamentState.pNum + 1) * Main.Instance.TournamentState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.TournamentState.count ? 
                Main.Instance.TournamentState.pNum + 1 :0}{' / '}
                {Main.Instance.TournamentState.count ? 
                Math.trunc((Main.Instance.TournamentState.count - 1) /
                Main.Instance.TournamentState.pSize + 1) : 0}
            </span>
        </div>
        : void 0}

{/*SECTION LEADERBOARD*/}
        {Main.Instance.TournamentState.modeLeaderboard ?
        Main.Instance.TournamentState.leaderboard ? /*#ffffe0*/
        <div style = {{background: '#fff', padding: '5px', marginTop:'10px'}}>
            <div>
            <div className = "clearfix" style = {{height: '40px'}}>
                <h4 style = {{fontSize:'20px', fontWeight: 700, float: 'left', clear: 'both'}}>
                {Assets.Leaderboard + Assets._for_ 
                + Assets.Tournament + ': ' + Main.Instance.TournamentState.title}
                </h4>
                {parseInt(Main.Instance.TournamentState.leaderboard_type) !== 0 ?
                <h4 style = {{fontSize:'20px', fontWeight: 500, marginLeft: '20px', float:'left'}}>
                {Assets.Main + ' ' + Assets.Criteria + ': ' +
                Assets.LeaderBoard[parseInt(Main.Instance.TournamentState.leaderboard_type) - 1]}
                </h4> : void 0}
                {parseInt(Main.Instance.TournamentState.leaderboard_type2) !== 0 ?
                <h4 style = {{fontSize:'20px', fontWeight: 500, marginLeft: '20px', float:'left'}}>
                {Assets.second[0]}
                <sup>{Assets.second[1]}</sup>
                {' ' + Assets.Criteria + ': ' +
                Assets.LeaderBoard[parseInt(Main.Instance.TournamentState.leaderboard_type2) - 1]}
                </h4> : void 0}
                <ButtonIcon
                    description = {''} 
                    click = {() => {
                        !Main.Instance.TournamentState.mode ? clearFields() :
                        Logic.Type.New (Main.Instance.TournamentState, {modeLeaderboard: false})
                        }
                    }
                    ClassName = "btn btn-default btn-sm btn-plus fr mr0 mt-50" 
                    icon = {Assets_.faCancelButtonClass}
                />
                <h4 style = {{fontSize:'20px', fontWeight: 500, float: 'right'}}>
                {Main.Instance.TournamentState.leaderboard.length.toString() + ' ' + Assets.players}
                </h4>
            </div>
            <div className = "clearfix" style = {{height: '40px'}}>
                {parseInt(Main.Instance.TournamentState.tournamentType) !== 0 ?
                <h4 style = {{fontSize:'20px', fontWeight: 700, float: 'left', clear: 'both'}}>
                {Assets.Type + ': ' + Assets.TournamentType[parseInt(Main.Instance.TournamentState.tournamentType) - 1]}
                </h4> : void 0}
                <h4 style = {{fontSize:'18px', fontWeight: 500, marginLeft: '20px', float: 'left', marginTop:'12px'}}>
                {Main.Instance.TournamentState.fromDate + ' ' + Main.Instance.TournamentState.fromTime + ' '}
                &mdash;
                {' ' + Main.Instance.TournamentState.toDate  + ' ' + Main.Instance.TournamentState.toTime}
                </h4>

                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 
                    style = {days_in_a_week[0] ? {marginTop:'11px', marginLeft: '10px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '10px', float: 'left'}}
                >
                    <span 
                        style = {days_in_a_week[0] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[0].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 
                    style = {days_in_a_week[1] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[1] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[1].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 
                    style = {days_in_a_week[2] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[2] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[2].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 
                    style = {days_in_a_week[3] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[3] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[3].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}                    
                <h4 
                    style = {days_in_a_week[4] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[4] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[4].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 
                    style = {days_in_a_week[5] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[5] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[5].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}
                {/*Main.Instance.TournamentState.recurring ?*/}                    
                <h4 
                    style = {days_in_a_week[6] ? {marginTop:'11px', marginLeft: '2px', float: 'left'} :
                    {marginTop:'13px', marginLeft: '2px', float: 'left'}}
                >
                    <span
                        style = {days_in_a_week[6] ? {fontWeight: 800, fontSize:'21px'}
                                : {fontWeight: 300, fontSize:'16px'}}
                    >
                        {Assets.DaysInAWeek[6].substring(0,2)}
                    </span>
                </h4>{/* : void 0*/}

                {/*Main.Instance.TournamentState.recurring ?*/}
                <h4 style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'14px'}}>
                {Main.Instance.TournamentState.recurring_from_time[0].length === 0 ? '00:00:00' :
                 Main.Instance.TournamentState.recurring_from_time[0].length === 5 ? 
                 Main.Instance.TournamentState.recurring_from_time[0] + ':00' :
                 Main.Instance.TournamentState.recurring_from_time[0]}
                 &nbsp;&mdash;&nbsp;
                 {Main.Instance.TournamentState.recurring_to_time[0].length === 0 ? '00:00:00' :
                 Main.Instance.TournamentState.recurring_to_time[0].length === 5 ? 
                 Main.Instance.TournamentState.recurring_to_time[0] + ':00' :
                 Main.Instance.TournamentState.recurring_to_time[0]}
                </h4>{/* : void 0*/}

                {/*Main.Instance.TournamentState.recurring ?*/}
                <FormSelect
                    floatRight = {true}
                    level165 = {true}
                    mt0 = {true}
                    value = {!Main.Instance.TournamentState.recurringType ? '0' :
                            Main.Instance.TournamentState.recurringType}
                    data = {Main.Instance.TournamentState.RecurringType_}
                    ClassName = "form-control"
                    o1 = {Assets.SelectRecurringType}
                    o2 = {Assets.SelectRecurringType}
                    onChange = {(text) => SelectRecurringType(text)}
                    icon = {Assets_.faRefreshClass}
                />{/* : void 0*/}
            </div>
            {/*&& Main.Instance.TournamentState.recurring*/}
            {parseInt(Main.Instance.TournamentState.recurringType) > 0 && 
            parseInt(Main.Instance.TournamentState.recurringType) < 5
            ?
            <div className = "clearfix" style = {{height: '40px', clear: 'both'}}>
                <FormSelect
                    level = {true}
                    mt5 = {true}
                    clear = {true}
                    value = {!Main.Instance.TournamentState.recurring_option ? '0' :
                            Main.Instance.TournamentState.recurring_option}
                    data = {Main.Instance.TournamentState.RecurringOptions}
                    ClassName = "form-control"
                    o1 = {Assets.SelectRecurringType}
                    o2 = {Assets.SelectRecurringType}
                    onChange = {(text) => SelectRecurringOption(text)}
                    icon = {Assets_.faRefreshClass}
                />
                {Main.Instance.TournamentState.validFrom && Main.Instance.TournamentState.validTo && 
                parseInt(Main.Instance.TournamentState.recurring_option) > 0 ?
                <div style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'2px'}}>
                    {Main.Instance.TournamentState.validFrom}
                </div> : void 0}

                {Main.Instance.TournamentState.validFrom && Main.Instance.TournamentState.validTo && 
                parseInt(Main.Instance.TournamentState.recurring_option) > 0 ?
                <div style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'2px'}}>
                    {Main.Instance.TournamentState.time1[0] ?
                    Main.Instance.TournamentState.recurring_from_time[0].length === 5 ?
                    Main.Instance.TournamentState.recurring_from_time[0] + ':00' :
                    Main.Instance.TournamentState.recurring_from_time[0]
                    : Main.Instance.TournamentState.from_time[0].length === 5 ?
                    Main.Instance.TournamentState.from_time[0] + ':00' :
                    Main.Instance.TournamentState.from_time[0]}
                </div> : void 0}
                {Main.Instance.TournamentState.validFrom && Main.Instance.TournamentState.validTo && 
                parseInt(Main.Instance.TournamentState.recurring_option) > 0 ?
                <div style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'2px'}}>
                    &mdash;
                </div>: void 0}
                {Main.Instance.TournamentState.validFrom && Main.Instance.TournamentState.validTo && 
                parseInt(Main.Instance.TournamentState.recurring_option) > 0 ?
                <div style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'2px'}}>
                    {Main.Instance.TournamentState.validTo}
                </div> : void 0}

                {Main.Instance.TournamentState.validFrom && Main.Instance.TournamentState.validTo && 
                parseInt(Main.Instance.TournamentState.recurring_option) > 0 ?
                <div style = {{fontSize:'16px', fontWeight: 500, marginLeft: '10px', float: 'left', marginTop:'2px'}}>
                    {Main.Instance.TournamentState.time2 ? Main.Instance.TournamentState.recurring_to_time.length === 5?
                    Main.Instance.TournamentState.recurring_to_time + ':00' :
                    Main.Instance.TournamentState.recurring_to_time
                    : Main.Instance.TournamentState.to_time.length === 5 ?
                    Main.Instance.TournamentState.to_time + ':00' :
                    Main.Instance.TournamentState.to_time}
                </div> : void 0}

            </div> : void 0}
            <table style = {{marginTop:'20px'}} className = "table">
                <thead className = "thead-inverse">
                    <tr>
                        <th className = "th-center">{Assets.Expand}</th>
                        <th className = "th-left">{Assets.Position}</th>
                        <th className = "th-left">{Assets.Player_}</th>

                        {parseInt(Main.Instance.TournamentState.leaderboard_type) !== 0 ?
                <th className = {Assets_.LTH[parseInt(Main.Instance.TournamentState.leaderboard_type)-1]}>
                        {Assets.LeaderBoard[parseInt(Main.Instance.TournamentState.leaderboard_type)-1]}
                        </th> : void 0}

                        {parseInt(Main.Instance.TournamentState.leaderboard_type2) !== 0 ?
                <th className = {Assets_.LTH[parseInt(Main.Instance.TournamentState.leaderboard_type2)-1]}>
                       {Assets.LeaderBoard[parseInt(Main.Instance.TournamentState.leaderboard_type2)-1]}
                        </th> : void 0}
                        
                        <th className = "th-left">{Assets.RewardType}</th>
                        <th className = "th-left">{Assets.RewardAmount}</th>
                        <th title = {Assets.WageringRequirement} className = "th-left">
                            {Assets.Wrq}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            </div>
        </div>
        :
        <div style = {{width: '100%', height: '200px', background: '#fff', padding: '5px', marginTop: '20px'}}> 
            <span 
                style = {{padding: '5px 8px', display: 'block', margin:'auto', width: '400px', fontSize: '14px'}} 
                className = "label label-danger"
            >
                {Assets.PleaseWaitTournamentLeaderboard}
            </span>
{/*SECTION LEADERBOARD END*/}
        </div>
        : 
        Main.Instance.DatabaseDataState.tournamentStream !== undefined ?
            <>
                <h3>{Assets.tTournament}</h3>
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.tournamentStream}
                    columns = {[
                        {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                        {minWidth: 100, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                        {minWidth: 60, Header: Assets.DateFrom, accessor: 'tdf', headerClassName: 'th-left'},
                        {minWidth: 60, Header: Assets.Time + ' (' + Assets_.UTC + ')', accessor: 'ttf', headerClassName: 'th-left'},
                        {minWidth: 60, Header: Assets.DateTo, accessor: 'tdt', headerClassName: 'th-left'},
                        {minWidth: 60, Header: Assets.Time + ' (' + Assets_.UTC + ')', accessor: 'ttt', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.TournamentType, accessor: 'tournament_type', headerClassName: 'th-left', Cell: (row) => TableUtils.TournamentType(row.value)},
                        {minWidth: 100, Header: Assets.LeaderboardType, accessor: 'leaderboard_type', headerClassName: 'th-left', Cell: (row) => TableUtils.LeaderboardType(row.value)},
                        {minWidth: 100, Header: <span>2<sup>nd</sup></span>, accessor: 'leaderboard_type2', headerClassName: 'th-left', Cell: (row) => TableUtils.LeaderboardType2(row.value)},
                        {maxWidth: 50, Header: Assets.LeaderboardsAbbrev, accessor: 'tournament_leaderboard', headerClassName: 'th-left', Cell: (row) => TableUtils.LeaderboardButton(row.value, DbRoutes.handleLeaderboard)},
                        {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                    ]}
                    showPaginationBottom = {false}
                    defaultPageSize={100}
                />

                {/*console.log (Main.Instance.DatabaseDataState.tournamentStream)*/}
                {/*StremingTable(Main.Instance.DatabaseDataState.tournamentStream, {
                    title: Assets.tTournament, 
                    mode: Main.Instance.TournamentState.mode,
                    editingId: Main.Instance.TournamentState.id,
                    sort: getTournamentsCount,
                    Sort: Main.Instance.TournamentState,
                    // toggleButton:(data) => {handleToggle(data)},
                    leaderboardButton:(tournament) => DbRoutes.handleLeaderboard(tournament),
                    borderRadius: Main.Instance.FrontendStoreState.borderRadius,
                    delButton:(data) => handleDelete(data), 
                editButton:(data) => handleEdit(data)}
                )*/}
            </>    
         : getTournamentsCount()}
        
        
        {Main.Instance.DatabaseDataState.tournamentLeaderboard ? 
        <div style = {{textAlign: 'center', position: 'relative'}}>
            <button
                style = {{position: 'absolute', top: '0px', right: '0px', background: 'transparent', border: '0px', marginRight: '0px'}}
                onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {tournamentLeaderboard: undefined})}
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>
            <div style = {{fontSize: '24px', fontWeight: 700, lineHeight: '36px'}}>
            {Assets.TournamentLeaderboard}&nbsp;&ndash;&nbsp;{getTournamentName(Main.Instance.DatabaseDataState.tournament)}
            </div>
            {+Main.Instance.TournamentState.recurringTypeRecurring === 1 ?
            <div style = {{fontSize: '14px', fontWeight: 600}}>
                {Main.Instance.TournamentState.daily === false && Main.Instance.TournamentState.days_in_a_month && Main.Instance.TournamentState.days_in_a_month.length > 0 ?
                /*Main.Instance.TournamentState.days_in_a_month.length === 1 ? <span className = "label label-success label-xs">Daily Tournament&nbsp;&ndash;&nbsp;
                {Main.Instance.TournamentState.days_in_a_month[0] === 1 ? 'First day of month' :
                Main.Instance.TournamentState.days_in_a_month[0] === 2 ? 'Last day of month' :
                'Day #' + (Main.Instance.TournamentState.days_in_a_month[0] - 1).toString()}</span> :*/ getDaysMonthSelection() : void 0}

                {Main.Instance.TournamentState.daily === true && Main.Instance.TournamentState.days_in_a_week && Main.Instance.TournamentState.days_in_a_week.length > 0 ?
                /*Main.Instance.TournamentState.days_in_a_week.length === 1 ? <span className = "label label-success label-xs">Daily Tournament</span> :*/ getDaysWeekSelection()
                /*<span>Choose Daily Tournament</span>*/ : void 0}
            </div> : void 0}
            {/*WEEKS*/}
            {+Main.Instance.TournamentState.recurringTypeRecurring === 2 ?
            <div style = {{fontSize: '14px', fontWeight: 600}}>
                {/*
                {Main.Instance.TournamentState.daily === false && Main.Instance.TournamentState.days_in_a_month && Main.Instance.TournamentState.days_in_a_month.length > 0 ?
                Main.Instance.TournamentState.days_in_a_month.length === 1 ? <span>Daily Tournament&nbsp;&ndash;&nbsp;
                Day #{Main.Instance.TournamentState.days_in_a_month[0] === 1 ? '1' :
                Main.Instance.TournamentState.days_in_a_month[0] === 2 ? 'Last day of month' :
                Main.Instance.TournamentState.days_in_a_month[0] - 1}</span> : <span>Choose Daily Tournament</span> : void 0}

                {Main.Instance.TournamentState.daily === true && Main.Instance.TournamentState.days_in_a_week && Main.Instance.TournamentState.days_in_a_week.length > 0 ?
                Main.Instance.TournamentState.days_in_a_week.length === 1 ? <span>Daily Tournament</span> : <span>Choose Daily Tournament</span> : void 0}
                */}
                {getWeeksSelection()}
            </div> : void 0}
            {/*MONTHS*/}
            {+Main.Instance.TournamentState.recurringTypeRecurring === 3 ?
            <div style = {{fontSize: '14px', fontWeight: 600}}>
                {getMonthsSelection()}
            </div> : void 0}

            {+Main.Instance.TournamentState.recurringTypeRecurring === 1 && Main.Instance.TournamentState.daily === false && Main.Instance.TournamentState.days_in_a_month.length === 0 ||
            +Main.Instance.TournamentState.recurringTypeRecurring === 1 && Main.Instance.TournamentState.daily === true && Main.Instance.TournamentState.days_in_a_week.length === 0 ||
            +Main.Instance.TournamentState.recurringTypeRecurring === 2 && Main.Instance.TournamentState.weeks.length === 0 ||
            +Main.Instance.TournamentState.recurringTypeRecurring === 3 && Main.Instance.TournamentState.months.length === 0 ||
            +Main.Instance.TournamentState.recurringTypeRecurring === 4 && Main.Instance.TournamentState.annual.length === 0 ?
            <div style = {{textAlign: 'center', marginTop: '5px'}}>
                <div className = "label label-danger label-sm">{Assets.NoTournamentExistsForTheSpecifiedConditions}</div>
            </div>
            :
            <table className = "table table-hover thead-dark">
                <thead>
                    <tr>
                        <th className = "th-right">{Assets.Position}</th>
                        <th className = "th-left">{Assets.PlayerID}</th>
                        <th className = "th-left">{Assets.Username}</th>
                        <th className = "th-left">{Assets.PlayerName}</th>
                        <th className = "th-left">{Assets.Country}</th>
                        <th className = "th-center">{Assets.Flag}</th>
                        {+Main.Instance.TournamentState.leaderboard_type > 0 ?
                        <th className = "th-right">{Assets.LeaderBoard[+Main.Instance.TournamentState.leaderboard_type - 1]}</th>
                        :
                        Main.Instance.DatabaseDataState.tournament && +Main.Instance.DatabaseDataState.tournament.leaderboard_type > 0 ?
                        <th className = "th-right">{Assets.LeaderBoard[+Main.Instance.DatabaseDataState.tournament.leaderboard_type - 1]}</th>
                        :
                        <th className = "th-right">{Assets.Points}&nbsp;<small>({Assets_.XP})</small></th>}
                        <th className = "th-right">{Assets.Reward}</th>
                    </tr>
                </thead>
                <tbody>
                    {getLeaderboard(Main.Instance.DatabaseDataState.tournamentLeaderboard, Main.Instance.DatabaseDataState.tournament)}
                </tbody>
            </table>}
        </div>
        : null}
    </div>
    )
}

function getButtons(elements: number[], elementNames: Select[], callback: (a: string) => void) : JSX.Element[] {
    const buttons: JSX.Element [] = []
    // console.log (Main.Instance.TournamentState.valid_from)
    // console.log (Main.Instance.TournamentState.valid_to)
    
    let lastDayInAMonth: Date 
    if (Main.Instance.TournamentState.valid_from) { lastDayInAMonth = new Date(Main.Instance.TournamentState.valid_from) }
    let validFromDateIncluding: number
    let validToDateIncluding: number
    // let lastDay: number
    let sameMonth: boolean 
    if (lastDayInAMonth && +Main.Instance.TournamentState.recurringTypeRecurring === 1 && Main.Instance.TournamentState.daily === false) {
        const validFrom: Date = new Date(Main.Instance.TournamentState.valid_from)
        validFromDateIncluding = validFrom.getDate()
        const validTo: Date = new Date(Main.Instance.TournamentState.valid_to)
        validToDateIncluding = validTo.getDate()
        if (validTo.getHours() === 0 && validTo.getMinutes() === 0 && validTo.getSeconds() === 0) { validToDateIncluding-- }
        // console.log (validFromDateIncluding)
        // console.log (validToDateIncluding)
        
        if (validFrom.getFullYear() === validTo.getFullYear() && validFrom.getMonth() === validTo.getMonth()) { sameMonth = true } else { sameMonth = false }
        lastDayInAMonth.setMonth(lastDayInAMonth.getDate() + 1)
        lastDayInAMonth.setDate(0)
/*    
        if (+Main.Instance.TournamentState.days_in_a_month[0] === 1) day = 1
        else if (+Main.Instance.TournamentState.days_in_a_month[0] === 2) day = lastDayInAMonth.getDate()
        else day = +Main.Instance.TournamentState.days_in_a_month[0] - 1
*/   
    }

    buttons.push (
        <button
            style = {{marginTop: '3px', marginBottom: '3px'}}
            className = "btn btn-warning btn-xs"
            key = "-2"
            onClick = {() => callback(Assets_.SELECT_ALL.toString())}
        >
        {Assets.SelectAll}
        </button>,
    )
    
    buttons.push (
        <button
            style = {{marginTop: '3px', marginBottom: '3px'}}
            className = "btn btn-danger btn-xs"
            key = "-1"
            onClick = {() => callback(Assets_.DESELECT_ALL.toString())}
        >
        {Assets.DeselectAll}
        </button>,
    )

    for (let i: number = 0; i < elementNames.length; i++) {
        if (sameMonth === true) {
            if (i === 0 && validFromDateIncluding > 1) { continue } else if (i === 1 && validToDateIncluding < lastDayInAMonth.getDate()) { continue } else if (i > 1 && (i < validFromDateIncluding || i > validToDateIncluding)) { continue }
        }
        let selected: boolean = false
        for (const item of elements) { if (item - 1 === i) { selected = true } }
        
        buttons.push(
        <button
            style = {{marginTop: '3px', marginBottom: '3px'}}
            className = {selected ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
            key = {i.toString()}
            onClick = {() => callback((i+1).toString())}
        >
            {elementNames[i].name + (i === 1 && sameMonth === true ? ' - ' + lastDayInAMonth.getDate() : '')}
            <sup>{+Main.Instance.TournamentState.recurringTypeRecurring === 1 && Main.Instance.TournamentState.daily === false && (i > 1 || i === 1 && sameMonth === true) ? getStNdRdTh(i) : ''}</sup>
        </button>)
    }
    return buttons
}

function SelectRecurring(selection: number) {
    Logic.Type.New (Main.Instance.TournamentState, {selection})
    DbRoutes.handleLeaderboard()
}

function getDaysWeekSelection(): JSX.Element[] {
    const days: JSX.Element[] = []
    if (Main.Instance.TournamentState.days_in_a_week) {
    for (let i: number = 0; i < Main.Instance.TournamentState.days_in_a_week.length; i++) {
        // let title: string
        let content: string
        if (Main.Instance.TournamentState.days_in_a_week[i] === 1) {
            content = Assets.FirstWeekday
            // title = 'TBD'
        } else if (Main.Instance.TournamentState.days_in_a_week[i] === 2) {
            content = Assets.LastWeekday
            // title = 'TBD'
        }
        
        days.push(
            <button
                // title = {title}
                key = {i.toString()}
                className = {i === Main.Instance.TournamentState.selection ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                onClick = {() => SelectRecurring(i)}
            >   
                {content ? content : Assets.DaysInAWeek[Main.Instance.TournamentState.days_in_a_week[i] - 1]}
            </button>,
        )
    }
    }
    return days
} 

function getDaysMonthSelection(): JSX.Element[] {
    const days: JSX.Element[] = []
    // console.log (Main.Instance.TournamentState.days_in_a_month)
    if (Main.Instance.TournamentState.days_in_a_month) {
    for (let i: number = 0; i < Main.Instance.TournamentState.days_in_a_month.length; i++) {
        // let title: string
        let content: string
        if (Main.Instance.TournamentState.days_in_a_month[i] === 1) {
            content = Assets.FirstDayInTheMonth
            // title = 'TBD'
        } else if (Main.Instance.TournamentState.days_in_a_month[i] === 2) {
            content = Assets.LastDayInTheMonth
            // title = 'TBD'
        }
        
        days.push(
            <button
                // title = {title}
                key = {i.toString()}
                className = {i === Main.Instance.TournamentState.selection ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                onClick = {() => SelectRecurring(i)}
            >
                {content ? content : (Main.Instance.TournamentState.days_in_a_month[i] - 1).toString()}
                {!content ? <sup>{getStNdRdTh(Main.Instance.TournamentState.days_in_a_month[i] - 1)}</sup> : void 0}
            </button>,
        )
    }
    }
    return days
} 

function getWeeksSelection(): JSX.Element[] {
    const weeks: JSX.Element[] = []
    // console.log (Main.Instance.TournamentState.valid_from)
    // console.log (Main.Instance.TournamentState.valid_to)
    const validFrom: Date = new Date(Main.Instance.TournamentState.valid_from)
    const validTo: Date = new Date(Main.Instance.TournamentState.valid_to)
    const validFromDate: number = validFrom.getDate()
    let validToDate: number = validTo.getDate()
    if (validTo.getHours() === 0 && validTo.getMinutes() === 0 && validTo.getSeconds() === 0) {
        validTo.setDate(validToDate - 1)
        validToDate--
    }

    if (Main.Instance.TournamentState.weeks) {
    for (let i: number = 0; i < Main.Instance.TournamentState.weeks.length; i++) {
        // let title: string
        let content: string
        let content2: string
        let content3: string
        let sup: string
        let sup2: string
        if (Main.Instance.TournamentState.weeks[i] === 1) {
            content = Assets.FirstWeek + ' ('
            const validto = new Date(Main.Instance.TournamentState.valid_from)
            if (validto.getHours() === 0 && validto.getMinutes() === 0 && validto.getSeconds() === 0) { validto.setDate(validto.getDate() + 6) } else { validto.setDate(validto.getDate() + 7) }
            const validtoDate = validto.getDate()
            content += validFromDate
            sup = getStNdRdTh(validFromDate)
            content2 = ' '  + Assets.Months [validFrom.getMonth() + 2].substring(0,3) + ' - ' + validtoDate
            sup2 =  getStNdRdTh(validtoDate)
            content3 = ' '  + Assets.Months [validto.getMonth() + 2].substring(0,3) + ')'
        } else if (Main.Instance.TournamentState.weeks[i] === 2) {
            content = Assets.LastWeek + ' ('

            const validfrom = new Date(Main.Instance.TournamentState.valid_to)
            if (validfrom.getHours() === 0 && validfrom.getMinutes() === 0 && validfrom.getSeconds() === 0) { validfrom.setDate(validfrom.getDate() - 6) } else { validfrom.setDate(validfrom.getDate() - 7) }

            let validfromDate = validfrom.getDate()
            if (validfrom.getHours() === 0 && validfrom.getMinutes() === 0 && validfrom.getSeconds() === 0) { validfromDate-- }
            content += validfromDate
            sup = getStNdRdTh(validfromDate)
            content2 = ' '  + Assets.Months [validfrom.getMonth() + 2].substring(0,3) + ' - ' + validToDate
            sup2 =  getStNdRdTh(validToDate)
            content3 = ' '  + Assets.Months [validTo.getMonth() + 2].substring(0,3) + ')'
        }
        
        weeks.push(
            <button
                // title = {title}
                key = {i.toString()}
                className = {i === Main.Instance.TournamentState.selection ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                onClick = {() => SelectRecurring(i)}
            >
                {content ? <span><span>{content}</span><sup>{sup}</sup><span>{content2}</span><sup>{sup2}</sup><span>{content3}</span></span> : Assets.Week + ' #' + (i+1).toString()}
            </button>,
        )
    }
    }
    return weeks
} 

function getMonthsSelection(): JSX.Element[] {
    const months: JSX.Element[] = []
    // console.log (Main.Instance.TournamentState.valid_from)
    // console.log (Main.Instance.TournamentState.valid_to)
/*
    const validFrom: Date = new Date(Main.Instance.TournamentState.valid_from)
    const validTo: Date = new Date(Main.Instance.TournamentState.valid_to)
    const validFromDate: number = validFrom.getDate()
    let validToDate: number = validTo.getDate()
    if (validTo.getHours() === 0 && validTo.getMinutes() === 0 && validTo.getSeconds() === 0) {
        validTo.setDate(validToDate - 1)
        validToDate--
    }
*/
    if (Main.Instance.TournamentState.months) {
    for (let i: number = 0; i < Main.Instance.TournamentState.months.length; i++) {
        // let title: string
        let content: string

        if (Main.Instance.TournamentState.months[i] === 1) {
            content = Assets.FirstMonth
        } else if (Main.Instance.TournamentState.months[i] === 2) {
            content = Assets.LastMonth
        }
        
        months.push(
            <button
                // title = {title}
                key = {i.toString()}
                className = {i === Main.Instance.TournamentState.selection ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                onClick = {() => SelectRecurring(i)}
            >
                {content ? content : Assets.Months[Main.Instance.TournamentState.months[i] - 1]}
            </button>,
        )
    }
    }
    return months
}