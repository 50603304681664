import * as React from 'react'
import * as Config from '../../../Logic/Config'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {FormInput} from '../../Reusables/Inputs'
// import {clipboardCopy} from 'clipboard-copy'
import axios from 'axios'
import '../../../CSS/API.css'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'

const KeyMsg = () => <div>{Assets.aPIKeys[0]}<br/>{Assets.aPIKeys[1]}<br/>{Assets.aPIKeys[2]}</div>
/** VARIABLES, PUT INTO TABLE ASSETS */
/** VARIABLES, PUT INTO DATABASE */
function saveKey() {
    axios.post(
        Config.asApiKey,
        {
            id: Main.Instance.LoginState.entityId,
            key: Main.Instance.APIState.keys,
            apps: Main.Instance.APIState.SelectedApps,
            name: Main.Instance.APIState.keyName,
        }, 
        {headers: {'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId}},
    )
    .then(
        (d) => Logic.Type.New(Main.Instance.APIState, {
            keys:'',
            SelectedApps:'',
        }),
    ).catch((e) => alert(e))
}

/** VARIABLES, PUT INTO DATABASE */

function getApiKeys() {
    axios.get(Config.asApiKey + '?id=' + Main.Instance.LoginState.entityId, {headers: {'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId}})
    .then((d) => Logic.Type.New(Main.Instance.APIState, {entityKeys:d.data}))
}

function addApps(t:string) {
    if (Main.Instance.APIState.SelectedApps.split(' ; ').includes(t)) {
        let newStr:string = ''
        const newArr = Main.Instance.APIState.SelectedApps
                    .split(' ; ')
                    .filter((app) => app != t)
        newArr.forEach((e) => {newStr != '' ? newStr += ' ; ' + e : newStr = e})
        Logic.Type.New(Main.Instance.APIState, {SelectedApps:newStr})
    } else {
        if (Main.Instance.APIState.SelectedApps!='') {
                Logic.Type.New(Main.Instance.APIState, {
                    SelectedApps:Main.Instance.APIState.SelectedApps+' ; '+t,
                })
        } else {
            Logic.Type.New(Main.Instance.APIState, {SelectedApps:t})     
        }
    }
}
async function generateUUID() {
    let d = new Date().getTime()
    const uuid = 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random()*16) % 16 | 0
        d = Math.floor(d/16)
        return (c == 'x' ? r : (r&0x3|0x8)).toString(16)
    })
    return uuid
}

function Tabs() {
    return(
        <ul className = "nav nav-tabs nav-justified">
            <li 
                className = {Main.Instance.APIState.SelectedView === 'addKey' ? 'active' :''}
                key = "1"
                onClick = {() => Logic.Type.New(Main.Instance.APIState, {SelectedView: 'addKey'},
                )}
            >
                <a>{Assets.AddKey}</a>
            </li>
            <li 
                className = {Main.Instance.APIState.SelectedView === 'manageKeys' ? 'active' : ''}
                key = "2"
                onClick = {() => Logic.Type.New(Main.Instance.APIState, {SelectedView: 'manageKeys'})}
            >
                <a>{Assets.ManageKeys}</a>
            </li>
        </ul>
    )
}

function KeysList() {
    const arr=[]
    if(!Main.Instance.APIState.entityKeys) {return}
    if(Main.Instance.APIState.entityKeys instanceof Array) {
        Main.Instance.APIState.entityKeys.forEach((e:any)=> {
        let apps=''
        e.apps.forEach((app) => {apps != '' ? apps = apps + ' ; ' + app.toString() : apps = apps + app.toString()})
        arr.push(
            <li className = "list-group-item keys-list" key = {e.name}>
                <span className = "col-sm-4">{e.name}</span>
                <span className = "col-sm-4">{apps}</span>
                <span className = "col-sm-4">
                    <button className = "del-btn btn btn-warning">{Assets.DeleteKEY}</button>
                </span>
            </li>,

    )})
        
    }
    return(<ul className = "list-group" >{arr}</ul>)
}

function AddKey() {
    return(
        <div className = "container-fluid add-key">
            <h3>{Assets.GenerateAPIKeys}</h3>
            {getApps()}
            <FormInput 
                divClass = "input-game pull-left"
                value = {Main.Instance.APIState.keyName||''} 
                ClassName = "input-game form-control"
                type = "text"
                onChange = {(text) => {
                    Logic.Type.New(Main.Instance.APIState,{keyName:text})
                }}
                placeholder = {Assets.INSERTKEYNAME}
            />
            <FormInput 
                divClass = "input-game pull-left"
                value = {Main.Instance.APIState.SelectedApps || ''} 
                ClassName = "input-game form-control"
                type = "text"
                onChange = {(text) => {}}
                placeholder = {Assets.INSERTAPPS}
            />
        <div>
            <button 
                className = "btn btn-warning" 
                onClick = {
                    () => generateUUID()
                    .then((d) => {
                        Logic.Type.New(Main.Instance.APIState, {keys:d})
                        // Copy.copy(Main.Instance.APIState.keys)
                        document.getElementById('key').focus()
                    })
                    .catch((e) => alert(e))
                }
            >
                {Assets.NewKey}
            </button>
            {Main.Instance.APIState.keys?
            <div >   
                <label>{Assets.PRIVATEKEY}</label>
                <p>{KeyMsg()}</p>
                <div className="container-fluid" >
                <input 
                    onFocus={(e) => {e.currentTarget.select()}}
                    id = "key"
                    value={Main.Instance.APIState.keys} 
                    className = "key-input"
                    type = "text"
                />
                <button 
                    onClick = {() => {
                    // clipboardCopy.copy(Main.Instance.APIState.keys)
                    document.getElementById('key').focus()
                    alert(Assets.KeysCopied)
                    }}
                >
                    <i className = {Assets_.faCopy}/>
                </button>
                </div>
                <button 
                    className = {Main.Instance.APIState.SelectedApps && Main.Instance.APIState.keyName ? 'btn btn-success' : 'btn btn-danger'}
                    onClick = {() => {Main.Instance.APIState.SelectedApps && Main.Instance.APIState.keyName ?
                    saveKey() : alert(Assets.insertAPPS)
                    }}
                ><i className = {Assets_.faUpdateClass}/>
                </button>
            </div>
            : null}
        </div>
    </div>)
}


function ManageKeys() {
    if(!Main.Instance.APIState.entityKeys) {getApiKeys()}
    return(
        <div>
        <h3>{Assets.ManageAPIKeys}</h3>
        {KeysList()}
        </div>
    )
}

function getApps() {
    const arr: JSX.Element[] = []
    Assets.Apps.forEach((app) => {
        arr.push(
            <button 
                className = "btn btn-link" 
                onClick={()=> addApps(app)}
                key={app}
            >
                {app}
            </button>,
        )
    })
    return <span>{arr}</span>
}

export function API(props:React.Props<any>) {
    return (
        <div className = "container-fluid">
            {Tabs()} 
            {Main.Instance.APIState.SelectedView === 'addKey' ? AddKey() : ManageKeys()}
        </div>
    )
}
