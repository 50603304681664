import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import ReactTable from 'react-table'
import {SelectTimePeriod} from './SelectTimePeriod'
import {SelectGame} from './SelectGame'
// import { isLocalhost } from 'src/Logic/Utils'

export function GameReportsReal(props: React.Props<any>): JSX.Element {
    if (Main.Instance.GGRRevenueState.initial === true && Main.Instance.GGRRevenueState.lastYear) {
        Logic.Type.New (Main.Instance.GGRRevenueState, {
            yearFrom: Main.Instance.GGRRevenueState.lastYear,
            yearTo: Main.Instance.GGRRevenueState.lastYear,
            initial: false
        })
    }
    // console.log(Main.Instance.DatabaseDataState.gameReportsTest)
/*
    if (Main.Instance.LoginState.unused3 === false && isLocalhost() === false) {
        return (
        <div>
            <h2>{Assets.YouDontHaveSecurityRightsToAccessData}</h2>
        </div>
        )
    } else {
*/
    return (
        <div>
            <div>
                <h2>{Assets.Game + ' ' + Assets.Reports + ' - ' + (isLocationGameReportsTest() ? Assets.Test : Assets.Real)}</h2>
            </div>
            <div>
                <SelectGame/>
                <SelectTimePeriod type = {isLocationGameReportsTest() ? 3 : 2}/>
                <br/>
                {(!isLocationGameReportsTest() && Main.Instance.DatabaseDataState.gameReportsReal !== undefined && Main.Instance.DatabaseDataState.gameReportsReal !== null) || (isLocationGameReportsTest() && Main.Instance.DatabaseDataState.gameReportsTest !== undefined && Main.Instance.DatabaseDataState.gameReportsTest !== null) ? 
                <div>
                    <ReactTable
                    data = {isLocationGameReportsTest() ? Main.Instance.DatabaseDataState.gameReportsTest : Main.Instance.DatabaseDataState.gameReportsReal}
                    columns = {[
                        {width: 120, Header: Assets.Date, accessor: '', headerClassName: 'th-left', Cell: (row) => <span>{row.original.year}-{row.original.month}-{row.original.day}</span>},
                        {Header: Assets.GameProvider, accessor: 'providerName', headerClassName: 'th-left'},
                        {width: 120, Header: '# ' + Assets.Rounds, accessor: 'numOfRounds', headerClassName: 'th-left'},
                        {width: 120, Header: '# ' + Assets.Sessions, accessor: 'numOfSessions', headerClassName: 'th-left'},
                        {width: 150, Header: '# ' + Assets.Unique + ' ' + Assets.players, accessor: 'numOfUniquePlayers', headerClassName: 'th-left'},
                        {
                            Header: Assets.BonusMoney,
                            headerClassName: 'risk-default',
                            columns: [
                                {width: 150, Header: Assets.TotalWager, accessor: 'wagerBonus', headerClassName: 'th-left'},
                                {width: 150, Header: Assets.TotalWin, accessor: 'winBonus', headerClassName: 'th-left'},
                            ]
                        },
                        {
                            Header: Assets.RealMoney,
                            headerClassName: 'risk-default-light',
                            columns: [
                                {width: 150, Header: Assets.TotalWager, accessor: 'wagerReal', headerClassName: 'th-left'},
                                {width: 150, Header: Assets.TotalWin, accessor: 'winReal', headerClassName: 'th-left'},
                            ]
                        }
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    />
                </div>
                : void 0}
            </div>
        </div>
    )
    // }
}

export function isLocationGameReportsTest(): boolean {
    if (Main.Instance.Location === '/reports/game-reports-test') {
        return true
    } else {
        return false
    }
}