import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {AccountStatus} from '../../../Logic/Database/DatabaseData'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Assets from '../../Assets'
// import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'

interface State {
    currency: 'N/A'|'CHF'|'EUR'|'USD'
    limit: number
    depositSum: boolean
    daysOpened: number
    // withdrawals: boolean
    deleteAccount: boolean
    yearsBeforeDeletion: number
    monthsBeforeDeletion: number
    riskLevel: RiskLevel,
    accountSuspension: boolean
    allowPlay: boolean
    allowDeposit: boolean
    allowWithdraw: boolean
    verificationStatus: AccountStatus
}

export default class TempAccountStatusPopup extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            currency: 'CHF',
            limit: 100,
            depositSum: false,
            daysOpened: 30,
            allowPlay: true,
            allowDeposit: true,
            allowWithdraw: true,
            deleteAccount: false,
            yearsBeforeDeletion: 2,
            monthsBeforeDeletion: undefined,
            riskLevel: RiskLevel.Low,
            accountSuspension: false,
            verificationStatus: AccountStatus.Active,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amTemporaryAccount, {
            currency: this.state.currency,
            limit: this.state.limit,
            depositSum: this.state.depositSum,
            daysOpened: this.state.daysOpened,
            allowDeposit: this.state.allowDeposit,
            allowWithdraw: this.state.allowWithdraw,
            allowPlay: this.state.allowPlay,
            verificationStatus: this.state.verificationStatus,
            delete: this.state.deleteAccount,
            yearsBeforeDeletion: this.state.yearsBeforeDeletion,
            monthsBeforeDeletion: this.state.monthsBeforeDeletion,
            riskLevel: this.state.riskLevel,
            accountSuspension: this.state.accountSuspension,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempAccountStatus: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempAccountStatus: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddTemporaryAccountStatusRules}</h4>
                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.Currency}</span>
                            <button 
                                className = "btn btn-xs"
                                // onClick = {() => ''}
                            >
                                {this.state.currency}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.DepositLimit}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({limit: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.limit}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({depositSum: !this.state.depositSum})} on = {this.state.depositSum} />
                            <div className = "fl mt2">{Assets.DepositSum}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.DaysOpened}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({daysOpened: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.daysOpened}
                            />
                        </div>
{/*
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({withdrawals: !this.state.withdrawals})} on = {this.state.withdrawals}/>
                            <div className = "fl mt2">{Assets.Withdrawal}</div>
                        </div>
*/}
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({deleteAccount: !this.state.deleteAccount})} on = {this.state.deleteAccount}/>
                            <div className = "fl mt2">{Assets.DeleteAccount}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.yearsBeforeDeletion}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({yearsBeforeDeletion: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.yearsBeforeDeletion}
                            />
                        </div>
                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.MonthsBeforeDeletion}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({monthsBeforeDeletion: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.monthsBeforeDeletion}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.AccountStatus}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + AccountStatus[this.state.verificationStatus]}
                            >
                                {/*AccountStatus[this.state.verificationStatus] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                AccountStatus[this.state.verificationStatus] === 'BlockedFull' ? 'Blocked Full' :
                                AccountStatus[this.state.verificationStatus] === 'BlockedPartial' ? 'Blocked Partial' :
                                AccountStatus[this.state.verificationStatus]*/}
                                {Assets.accountStatus[this.state.verificationStatus]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = {AccountStatus.Initial}
                                    max = {AccountStatus.ClosedActive}
                                    step = "1"
                                    defaultValue = "2"
                                    onChange = {(e) => this.setState({verificationStatus: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowPlay: !this.state.allowPlay})} on = {this.state.allowPlay}/>
                            <div className = "fl mt2">{Assets.AllowPlay}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowDeposit: !this.state.allowDeposit})} on = {this.state.allowDeposit}/>
                            <div className = "fl mt2">{Assets.AllowDeposit}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowWithdraw: !this.state.allowWithdraw})} on = {this.state.allowWithdraw}/>
                            <div className = "fl mt2">{Assets.AllowWithdrawal}</div>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({accountSuspension: !this.state.accountSuspension})} on = {this.state.accountSuspension}/>
                            <div className = "fl mt2">{Assets.AccountSuspension}</div>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddTempAccountStatus: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
