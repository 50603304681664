import * as React from 'react'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import {ButtonIcon} from '../../Reusables/Button'
import {/*FormInput,*/ FormInputNumber} from '../../Reusables/Inputs'
import * as Assets_ from '../../Assets_'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { GamesList, FreeGame } from 'src/Logic/Database/DatabaseData'
import {Logic} from '../../../Logic/Type'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {gotoTop} from '../../../Logic/Utils'
import * as Config from '../../../Logic/Config'
import Socket1 from '../../../Socket/socket-init'
import * as RouteClasses from '../../../Socket/route-classes'
// import Switch from 'react-toggle-switch'
// import ReactTable from 'react-table'

export function FreeGames() {
    DbRoutes.wsReinitialize()

    if (!Main.Instance.FreeGamesState.selectedGame) {
        setInitialValues()
    }

    return (
        <div>
            <div className = "clearfix">
                <h3 className = "level fl">
                    {Assets.AddEditFreeGame}
                </h3>
            </div>
            <div className = "clearfix"/>
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {Main.Instance.FreeGamesState.mode ? 
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >
                    <div className = "create-input">
                        <div className = "row admin-users-row mb" style = {{position: 'relative'}}>
                            <h4>{Main.Instance.FreeGamesState.mode ? Assets.EditTheFreeGame + ': ' + Main.Instance.FreeGamesState.selectedGame.freeGameName  : Assets.AddANewFreeGame}</h4>

                            {Main.Instance.FreeGamesState.mode ? 
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => gotoInsertMode()}
                                ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                                icon = {Assets_.faCancelButtonClass}
                            /> : <span/>}
                        </div>
                        <div className = "row admin-users-row mb">
                            {/*
                            <FormInput
                                description = {Assets.Game + ' ' + Assets.Name.toLowerCase()}
                                narrow = {true}
                                value = {Main.Instance.FreeGamesState.freeGameOffer && Main.Instance.FreeGamesState.freeGameOffer.freeGameName ? Main.Instance.FreeGamesState.freeGameOffer.freeGameName : ''} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => updateText(text, 'freeGameName')} 
                                placeholder = {Assets.Game + ' ' + Assets.Name.toLowerCase()}
                                Icon = {faDice}
                            />*/}
                            <select
                                title = {Assets.SelectGameProvider}
                                style = {{height: '30px', width: 'auto'}}
                                className = "fl dbl form-control mr"
                                onChange = {(e) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.providerId = +e.target.value
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                    Logic.Type.New(Main.Instance.DatabaseDataState, {freeGameSettingsGet: true})
                                    Socket1.socket.Get<RouteClasses.Route>(Config.bonusBonusGet).loading = 'init'
                                    if (selectedGame.providerId === -1) {
                                        Socket1.socket.Send(Config.bonusFreeGamesGet, {eventId: 'event_id'}) 
                                    } else {
                                        Socket1.socket.Send(Config.bonusFreeGamesGet, {eventId: 'event_id', providerId: selectedGame.providerId})
                                    }
                                    
                                }}
                                value = {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.providerId ? Main.Instance.FreeGamesState.selectedGame.providerId : -1}
                            >
                                {getGameProviders()}
                            </select>
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.providerId && Main.Instance.FreeGamesState.selectedGame.providerId !== -1 ?
                            <select
                                title = {Assets.SelectGame + ' ' + Assets.ID}
                                style = {{height: '30px', width: 'auto'}}
                                className = "fl dbl form-control mr"
                                onChange = {(e) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    const games = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
                                    for (const item of games) {
                                        if (item.gameId === +e.target.value) {
                                            selectedGame.freeGameName = item.gameName
                                        }

                                    }
                                    selectedGame.gameId = +e.target.value
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }}
                                value = {Main.Instance.FreeGamesState.selectedGame.gameId ? Main.Instance.FreeGamesState.selectedGame.gameId : -1}
                            >
                                {getGamesOptions(true)}
                            </select> : void 0}
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.providerId && Main.Instance.FreeGamesState.selectedGame.providerId !== -1 ?
                            <select
                                title = {Assets.SelectGame + ' ' + Assets.Name}
                                style = {{height: '30px', width: 'auto'}}
                                className = "fl dbl form-control mr"
                                onChange = {(e) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    const games = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
                                    for (const item of games) {
                                        if (item.name === e.target.value) {
                                            selectedGame.gameId = item.gameId
                                        }

                                    }
                                    selectedGame.freeGameName = e.target.value
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }}
                                value = {Main.Instance.FreeGamesState.selectedGame.freeGameName ? Main.Instance.FreeGamesState.selectedGame.freeGameName : ''}
                            >
                                {getGamesOptions(false)}
                            </select> : void 0}
                            {/*<select
                                title = {Assets.SelectGame}
                                style = {{height: '30px', width: 'auto'}}
                                className = "fl dbl form-control mr"
                                onChange = {(e) => {
                                    if (+e.target.value === -1) {
                                        Logic.Type.New(Main.Instance.FreeGamesState, {selectGames: false})
                                    }
                                    updateText(+e.target.value, 'providerId')
                                    // setGames(+e.target.value)
                                }}
                                value = {Main.Instance.FreeGamesState.freeGameOffer && Main.Instance.FreeGamesState.freeGameOffer.providerId ? Main.Instance.FreeGamesState.freeGameOffer.providerId : -1}
                            >
                                {getGameProviders()}
                            </select>*/}
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.gameId ?
                            <FormInputNumber
                                description = {Assets.FreeGames + ' ' + Assets.Amount.toLowerCase()}
                                value = {Main.Instance.FreeGamesState.selectedGame.freeGameAmount ? Main.Instance.FreeGamesState.selectedGame.freeGameAmount.toString() : '0'} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.freeGameAmount = +text
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }} 
                                min = "0"
                                iconText = {Assets.Number + Assets._of_ + Assets.FreeGames.toLowerCase()}
                                iconTextS  = "12px"
                            />
                            : void 0}
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.gameId ?
                            <FormInputNumber
                                description = {Assets.CoinValue + ' ' + Assets.level}
                                value = {Main.Instance.FreeGamesState.selectedGame.coinValueLevel ? Main.Instance.FreeGamesState.selectedGame.coinValueLevel.toString() : '0'} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.coinValueLevel = +text
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }} 
                                min = "0"
                                iconText = {Assets.CoinValue}
                                iconTextS  = "12px"
                            />
                            : void 0}
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.gameId ?
                            <FormInputNumber
                                description = {Assets.BetLevel}
                                value = {Main.Instance.FreeGamesState.selectedGame.betLevel ? Main.Instance.FreeGamesState.selectedGame.betLevel.toString() : '0'} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.betLevel = +text
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }} 
                                min = "0"
                                iconText = {Assets.BetLevel}
                                iconTextS  = "12px"
                            />
                            : void 0}
                            {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.gameId ?
                            <FormInputNumber
                                description = {Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase()}
                                value = {Main.Instance.FreeGamesState.selectedGame.valueAmount ? Main.Instance.FreeGamesState.selectedGame.valueAmount.toString() : '0'} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => {
                                    const selectedGame = Object.assign({}, Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.valueAmount = +text
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }} 
                                min = "0"
                                iconText = {Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase()}
                                iconTextS  = "12px"
                            />
                            : void 0}
                            {/*Main.Instance.FreeGamesState.freeGameOffer && Main.Instance.FreeGamesState.freeGameOffer.providerId && Main.Instance.FreeGamesState.freeGameOffer.providerId !== -1 ?
                            <ButtonIcon 
                                description = {Assets.SelectGame_s} 
                                click = {() => Logic.Type.New(Main.Instance.FreeGamesState, {selectGames: !Main.Instance.FreeGamesState.selectGames})}
                                ClassName = {Main.Instance.FreeGamesState.selectGames ? 'btn btn-success btn-sm btn-plus mr0' : 'btn btn-default btn-sm btn-plus mr0'}
                                Icon = {faDice as IconDefinition}
                                text = {Assets.SelectGame_s}
                            />
                            : void 0*/}
                        </div>
                        {/*Main.Instance.FreeGamesState.selectGames ? 
                        <div className = "row admin-users-row mb">
                            <ReactTable
                                data = {Main.Instance.FreeGamesState.filteredGames}
                                className = "bg-white"
                                columns = {[
                                    {width: 100, Header: Assets.GameID, accessor: 'id', headerClassName: 'th-left', Cell: (row) => <div className={row.original.selected ? 'background-light' : ''}>{row.value}</div>},
                                    {Header: Assets.GameName, accessor: 'name', headerClassName: 'th-left', Cell: (row) => <div className={row.original.selected ? 'background-light' : ''}>{row.value}</div>},
                                    {width: 200, Header: Assets.CoinValue, accessor: 'coinValue', headerClassName: 'th-left', Cell: (row) => 
                                        !row.original.selected ? row.value :
                                        <div className={row.original.selected ? 'background-light' : ''}>
                                            <FormInputNumber
                                                description = {Assets.CoinValue}
                                                value = {row.value ? row.value : '0'} 
                                                outerDiv = "rows-per-table input-group fl"
                                                ClassName = "h30 w60 background-light no-border"
                                                type = "number" 
                                                onChange = {(text) => manageGames(row.original, 'coinValue', text)}
                                                min = "0"
                                                max = "8"
                                            />
                                        </div>
                                    },
                                    {width: 200, Header: Assets.BetLevel, accessor: 'betLevel', headerClassName: 'th-left', Cell: (row) => 
                                    !row.original.selected ? row.value :
                                    <div className={row.original.selected ? 'background-light' : ''}>
                                        <FormInputNumber
                                            description = {Assets.BetLevel}
                                            value = {row.value ? row.value : '0'} 
                                            outerDiv = "rows-per-table input-group fl background-light"
                                            ClassName = "h30 w60 background-light no-border"
                                            type = "number" 
                                            onChange = {(text) => manageGames(row.original, 'betLevel', text)}
                                            min = "0"
                                            max = "8"
                                        />
                                    </div>
                                    },
                                    {width: 200, Header: Assets.SelectedGames, accessor: 'selected', headerClassName: 'th-left', Cell: (row) => 
                                    <div className={row.original.selected ? 'background-light' : ''}>
                                        <Switch
                                            className = "mr0 mt5 mb-9"
                                            onClick = {() => manageGames(row.original, 'selected')}
                                            on = {row.value ? row.value : false}
                                        />
                                    </div>
                                    },
                                ]}
                                showPaginationBottom = {false}
                                showPaginationTop = {true}
                                defaultPageSize={10}
                            />
                        </div>
                            : void 0*/}
                        {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.gameId ?
                        <div className = "row admin-users-row mb">
                            <textarea
                                placeholder = {Assets.Game + ' ' + Assets.Description.toLowerCase()}
                                style = {{height: '50px', paddingLeft: '5px', paddingRight: '5px', width: '100%', borderRadius: '5px'}}
                                value = {Main.Instance.FreeGamesState.selectedGame && Main.Instance.FreeGamesState.selectedGame.description ? Main.Instance.FreeGamesState.selectedGame.description : ''}
                                onChange = {(e) => {
                                    const selectedGame = Object.assign([], Main.Instance.FreeGamesState.selectedGame)
                                    selectedGame.description = e.target.value
                                    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame})
                                }}
                            />
                        </div> : void 0 }
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <ButtonIcon 
                                description = {Main.Instance.FreeGamesState.mode ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => handleInsertUpdate()}
                                ClassName = {Main.Instance.FreeGamesState.mode ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ReactTable 
                data = {Main.Instance.DatabaseDataState.freeGameSettings ? Main.Instance.DatabaseDataState.freeGameSettings : []}
                columns = {[
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {width: 70, Header: Assets.GameID, accessor: 'gameId', headerClassName: 'th-left'},
                    {width: 200, Header: Assets.GameName, accessor: 'freeGameName', headerClassName: 'th-left'},
                    {width: 150, Header: Assets.Provider, accessor: 'providerId', headerClassName: 'th-left', Cell: (row) => getProviderName(row.value)},
                    {width: 170, Header: Assets.Number + Assets._of_ + Assets.FreeGames.toLowerCase(), accessor: 'freeGameAmount', headerClassName: 'th-left'},
                    {width: 100, Header: Assets.CoinValue, accessor: 'coinValueLevel', headerClassName: 'th-left'},
                    {width: 100, Header: Assets.BetLevel, accessor: 'betLevel', headerClassName: 'th-left'},
                    {width: 250, Header: Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase(), accessor: 'valueAmount', headerClassName: 'th-left'},
                    {Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize={10}
                />
            </div>
            
        </div>
    )
}

function handleDelete(row: any) {

    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.FreeGame.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.freeGameName + '"?')) {
            DbRoutes.deleteFreeGame(row.original.gameId) 
        }
    }
}

function handleEdit(row: any) {
    gotoTop()
    const selectedGame: FreeGame= Object.assign ({}, JSON.parse(JSON.stringify(row.original)))
    if (row) {
        Logic.Type.New(Main.Instance.FreeGamesState, {mode: true, selectedGame, selectedGame_:JSON.stringify(selectedGame)})
    }
}

function gotoInsertMode() {
    if (JSON.stringify(Main.Instance.FreeGamesState.selectedGame) === Main.Instance.FreeGamesState.selectedGame_) {
        setInitialValues()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            setInitialValues()
        }                
    }        
}

function setInitialValues() {
    const selectedGame: FreeGame= Object.assign ({}, Main.Instance.FreeGamesState.selectedGame)
    selectedGame.freeGameName = ''
    selectedGame.description = ''
    selectedGame.freeGameAmount = 0
    selectedGame.coinValueLevel = 0
    selectedGame.betLevel = 0
    selectedGame.valueAmount = 0
    selectedGame.gameId = null
    selectedGame.providerId = -1
    Logic.Type.New(Main.Instance.FreeGamesState, {selectedGame, selectedGame_: undefined, mode: false})
}

function handleInsertUpdate() {

    const selectedGame: FreeGame = Object.assign ({}, Main.Instance.FreeGamesState.selectedGame)
    selectedGame.eventId = 'event_id'

    if (Main.Instance.FreeGamesState.mode === false) {
        for (const item of Main.Instance.DatabaseDataState.freeGameSettings) {
            if (item.gameId === selectedGame.gameId) {
                alert (Assets.Game + ' ' + item.freeGameName + ' ' + Assets.alreadyInDB)
                return
            }
        }
    }
    Logic.Type.New(Main.Instance.FreeGamesState, {mode: false})
    setInitialValues()
    DbRoutes.insertUpdateFreeGame(selectedGame)
    
}

/*
function updateText(value: string | number, property: string) {
    let freeGameOffer: FreeGamesOffer = Object.assign({}, Main.Instance.FreeGamesState.freeGameOffer)
    if (freeGameOffer === undefined) { freeGameOffer = {
        freeGameName: '',
        providerId: -1,
        description: ''
    } }
    freeGameOffer[property] = value
    Logic.Type.New (Main.Instance.FreeGamesState, {freeGameOffer})
}
*/
/*
function setGames(providerId: number) {
    const gamesCentralNew: GamesCentralNew[] = Object.assign([], Main.Instance.DatabaseDataState.gamesCentralNew)
    const filteredGames: OfferGameList[] = []
    if (gamesCentralNew) {
        for (const item of gamesCentralNew) {
            if (+item.providerId === providerId) {
                const {coinValue, betLevel, selected} = getGamesValues(item.gameId)
                filteredGames.push({id: item.gameId, name: item.name, coinValue, betLevel, selected})
            }
        }
    }
    Logic.Type.New(Main.Instance.FreeGamesState, {filteredGames})
}


function manageGames(game: OfferGameList, property, value?) {
    const freeGameOffer: FreeGamesOffer = Object.assign({}, Main.Instance.FreeGamesState.freeGameOffer)
    const filteredGames: OfferGameList[] = Object.assign([], Main.Instance.FreeGamesState.filteredGames)
    for (const item of filteredGames) {
        if (item.id === game.id) {
            if (value) {
                item[property] = +value
            } else {
                item[property] = !item[property]
                item.betLevel = 0
                item.coinValue = 0
            }
        }
    }
    if (freeGameOffer.games === undefined) { 
        freeGameOffer.games = []
    } 
    let found: number = -1
    for (let i: number = 0; i < freeGameOffer.games.length; i++) {
        if (freeGameOffer.games[i].id === game.id) {
            if (value) {
                freeGameOffer.games[i][property] = +value
            }
            found = i
            break
        }
        
    }
    if (found > -1 && property === 'selected') {
        freeGameOffer.games.splice(found, 1)
    }
    if (found === -1) {
        freeGameOffer.games.push({id: game.id, coinValue: game.coinValue, betLevel: game.betLevel})
    }
    Logic.Type.New(Main.Instance.FreeGamesState, {filteredGames, freeGameOffer})
}

function getGamesValues(id: number): {coinValue: number, betLevel: number, selected: boolean} {
    const freeGameOffer: FreeGamesOffer = Object.assign({}, Main.Instance.FreeGamesState.freeGameOffer)
    if (!freeGameOffer.games || freeGameOffer.games.length === 0) {
        return {coinValue: 0, betLevel: 0, selected: false}
    } else {
        for (const item of freeGameOffer.games) {
            if (item.id === id) {
                return {coinValue: item.coinValue, betLevel: item.betLevel, selected: true}
            }
        }
    }
        
    
}
*/

export function getProviderName(providerId: number): string {
    if (Main.Instance.DatabaseDataState.gameProviderNew) {
        for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
            if (item.providerId === providerId) {
                return item.name
            }
        }
    }
    return ''
}

export function getGameProviders(): JSX.Element[] {
    const gameProviders: JSX.Element[] = []
    gameProviders.push(<option key = "-1" value = {-1}>{Assets.AnyGameProvider}</option>)
    if (Main.Instance.DatabaseDataState.gameProviderNew) {
        for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
            gameProviders.push(<option key = {item.providerId} value = {item.providerId}>{item.name}</option>)
            
        }
    }
    return gameProviders
}

function getGamesOptions(isId: boolean): JSX.Element[] {
    const prop: string[] = []
    const Prop: number[] = []
    const options: JSX.Element[] = []
    const games: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)

    for (const item of games) {
        if (isId) {
            if (+item.providerId === Main.Instance.FreeGamesState.selectedGame.providerId) {
                Prop.push (item.gameId)
            }   
        } else {
            if (+item.providerId === Main.Instance.FreeGamesState.selectedGame.providerId) {
                prop.push(item.gameName)
            }
        }
    }

    if (isId) {
        Prop.sort(sortNumber)
        for (const item of Prop) {
            if (item) {
                prop.push(item.toString())
            }
            
        }
    } else {
        prop.sort()
    }

    options.push (<option key = "-1" value = "0">{Assets.SelectGame + ' ' + (isId ? Assets.ID : Assets.Name.toLowerCase())}</option>)
    for (let i: number = 0; i < prop.length; i++) {
        options.push (<option key = {i.toString()} value = {prop[i]}>{prop[i]}</option>)
    }
    return options
}

function sortNumber(a, b) {
    return a - b
}

/*
function gotoInsertMode() {
    if (JSON.stringify(Main.Instance.BonusState.bonus) === Main.Instance.BonusState.bonus_) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}
*/