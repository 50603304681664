export enum RewardTypes {
    All,
    FreeSpins,
    CashBonus,
    Tokens,
    CashBack,
    MiniGame,
    DepositBonus,
    BoosterGlobal,
    BoosterPlayerSpecific,
    MegaSpins,
    Specials
}

export enum RewardIntents {
    All = -1,
    Generic,
    Missions,
    Levels,
    Tournaments,
    Challenges,
    Store,
    MiniGame,
}

export enum RewardStatus {
    Pending = 1,
    Claimed,
    Expired,
}