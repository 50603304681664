import {Logic} from '../Type'
export class SearchState extends Logic.Type {
    public search_string: string
    public minLengthToSearch: number = 3
    public searchOnlyAtTheStartOfPhrase: boolean = true
    public playerCardUsername: boolean = true
    public playerCardPlayerId: boolean = true
    public playerCardFirst: boolean = true
    public playerCardLast: boolean = true
    public playerCardEmail: boolean = undefined
    public gameStoreGameId: boolean = true
    public gameStoreGameName: boolean = true
}