import * as R from './route-classes'
import Router from './router'
const Routes2 = new Router()
Routes2.New = {
    // MY CHANCE WEBSOCKET
    'get-casino-games': new R.General(),

    // GAMES STORE -> FOR DEVEDGE (STAGE)
    'get-games': new R.General(),
    'get-game': new R.General(),
    'update-game': new R.General(),
    'delete-game': new R.General(),
    'insert-game': new R.General(),
    'get-games-data': new R.General(),
    'get-game-data': new R.General(),
    'update-game-data': new R.General(),
    'delete-game-data': new R.General(),
    'insert-game-data': new R.General(),
    
    'get-game-providers': new R.General(),
    'get-game-provider': new R.General(),
    'update-game-provider': new R.General(),
    'delete-game-provider': new R.General(),
    'insert-game-provider': new R.General(),

    'get-game-suppliers': new R.General(),
    'get-game-supplier': new R.General(),
    'insert-game-suppliers': new R.General(),
    'update-game-supplier': new R.General(),
    'delete-game-supplier': new R.General(),

    'get-labels': new R.General(),
    'get-label': new R.General(),
    'insert-labels': new R.General(),
    'update-label': new R.General(),
    'delete-label': new R.General(),

    'get-games-all': new R.General(),
    'get-game-providers-all': new R.General(),
    'get-game-suppliers-all': new R.General(),

    'get-jurisdictions': new R.General(),
    'get-jurisdiction': new R.General(),
    'insert-jurisdictions': new R.General(),
    'update-jurisdiction': new R.General(),
    'delete-jurisdiction': new R.General(),

    'get-categories': new R.General(),
    'get-category': new R.General(),
    'insert-categories': new R.General(),
    'update-category': new R.General(),
    'delete-category': new R.General(),

/*
    'get-games': new R.General(),
    'get-game': new R.General(),
    'update-game': new R.General(),
    'delete-game': new R.General(),
    'insert-game': new R.General(),
    'get-games-data': new R.General(),
    'get-game-data': new R.General(),
    'update-game-data': new R.General(),
    'delete-game-data': new R.General(),
    'insert-game-data': new R.General(),
    
    'get-game-providers': new R.General(),
    'get-game-provider': new R.General(),
    'update-game-provider': new R.General(),
    'delete-game-provider': new R.General(),
    'insert-game-provider': new R.General(),

    'get-game-suppliers': new R.General(),
    'get-game-supplier': new R.General(),
    'insert-game-suppliers': new R.General(),
    'update-game-supplier': new R.General(),
    'delete-game-supplier': new R.General(),

    'get-labels': new R.General(),
    'get-label': new R.General(),
    'insert-labels': new R.General(),
    'update-label': new R.General(),
    'delete-label': new R.General(),

    'get-games-all': new R.General(),
    'get-game-providers-all': new R.General(),
    'get-game-suppliers-all': new R.General(),

    'get-jurisdictions': new R.General(),
    'get-jurisdiction': new R.General(),
    'insert-jurisdictions': new R.General(),
    'update-jurisdiction': new R.General(),
    'delete-jurisdiction': new R.General(),

    'get-categories': new R.General(),
    'get-category': new R.General(),
    'insert-categories': new R.General(),
    'update-category': new R.General(),
    'delete-category': new R.General(),
*/
}
export default Routes2