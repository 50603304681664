import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, /*FormInputNumber,*/ FormSelect, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {isLocalhost, isUserReports, isUserGamanzaMagicHand, gotoTop, isUserGamanzaAdmin, isUserAdmin, isUserSystem, isUserMaster, isPasswordStrongEnough, isPasswordDifferent} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartLine, faMagic, faDice, faFire, faUserSecret, faUserFriends, faUserTie, faBullhorn, faSave, faUser, faEnvelope, faPhone/*, faEye, faEyeSlash*/} from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-toggle-switch'
// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD
import {SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import {toggleSecurityRights, ToggleSecurityRights} from './ManageAdminUsersOperator'
function getSecurityRights(): JSX.Element[] {
    const securityRights: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(SecurityRights).length / 2; i++) {
        if (!isUserGamanzaAdmin() && (i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN)) {continue}
        if (!isUserGamanzaMagicHand() && i === SecurityRights.GAMANZA_MAGIC_HAND) {continue}
        securityRights.push(
            <button
                key = {i.toString()}
                title = {Assets.ToggleSecurityRight + ' ' + Assets_.SecurityRights[i]}
                style = {{
                    color:  i === SecurityRights.ADMINISTRATOR && Main.Instance.CreateMasterUserState.custom.securityRights.administrator ||
                            i === SecurityRights.SUPPORT && Main.Instance.CreateMasterUserState.custom.securityRights.support ||
                            i === SecurityRights.OPERATOR && Main.Instance.CreateMasterUserState.custom.securityRights.operator ||
                            i === SecurityRights.MARKETING && Main.Instance.CreateMasterUserState.custom.securityRights.marketing ||
                            i === SecurityRights.RFSPECIALIST && Main.Instance.CreateMasterUserState.custom.securityRights.rfSpecialist ||
                            i === SecurityRights.RGSPECIALIST && Main.Instance.CreateMasterUserState.custom.securityRights.rgSpecialist ||
                            i === SecurityRights.GDPR && Main.Instance.CreateMasterUserState.custom.securityRights.gdpr ||
                            i === SecurityRights.ACCOUNTING && Main.Instance.CreateMasterUserState.custom.securityRights.accounting ||
                            i === SecurityRights.REPORTS && Main.Instance.CreateMasterUserState.custom.securityRights.unused3
                            ? '#5cb85c' :
                            i === SecurityRights.GAMANZA_ADMIN && Main.Instance.CreateMasterUserState.custom.securityRights.unused1
                            || i === SecurityRights.GAMANZA_GAMES_ADMIN && Main.Instance.CreateMasterUserState.custom.securityRights.unused2
                            || i === SecurityRights.GAMANZA_MAGIC_HAND && Main.Instance.CreateMasterUserState.custom.securityRights.unused7
                            ? '#f38b00'
                            : '#d9534f',
                    lineHeight: '27px',
                    fontSize: '18px',
                    fontWeight: 700,
                    width: '27px',
                    // borderRadius: '0px',
                    padding: '0px',
                    // marginRight: i === Object.keys(SecurityRights).length / 2 - 1 ? '10px' : '0px',
                    textAlign: 'center',
                    background: 'transparent',
                    border: '0px',
                }}
                onClick = {() => isUserAdmin() ? ToggleSecurityRights(4, i) : void 0}
                className = "btn btn-default btn-xs dbl fl mr0 br0"
            >
                <FontAwesomeIcon icon = {Assets_.SecurityRightsIcons[i]}/>
            </button>,
        )
    }
    return securityRights
}

export function getMasterUsersCount() {

    if (Main.Instance.CreateMasterUserState.countCalled === false) {

        Logic.Type.New(Main.Instance.CreateMasterUserState, {countCalled:true})
        DbRoutes.countUsers(Main.Instance.CreateMasterUserState.filter_role,
                            Assets_.masterAdmin,
                            Assets_.masterRead)

        if (isUserSystem()) {
            DbRoutes.getMastersAll()
        }
    }
}

function getMasterUsers() {

    if (isUserSystem()) {

        Logic.Type.New(Main.Instance.DatabaseDataState, {users0Stream : undefined})
    } else if (isUserMaster()) {

        Logic.Type.New(Main.Instance.DatabaseDataState, {user0Stream : undefined})
    }
}

function clearFields() {
    if (isUserSystem()) {
        Logic.Type.New(Main.Instance.CreateMasterUserState, {
            custom: {environmentsAllowed: [false, false, false, false],securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            master_id: '0',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            id: '0' })
    } else {
        Logic.Type.New(Main.Instance.CreateMasterUserState,{
            custom: {environmentsAllowed: [false, false, false, false],securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '' })
    }
}

function handleInsertUpdate() {

    let items: any

    if (parseInt(Main.Instance.LoginState.role) === Assets_.masterAdmin) {

        items = {
            custom: JSON.stringify(Main.Instance.CreateMasterUserState.custom),
            role : 1,
            username: !Main.Instance.CreateMasterUserState.username ? '' : Main.Instance.CreateMasterUserState.username,
            password: !Main.Instance.CreateMasterUserState.password ? ''  : Main.Instance.CreateMasterUserState.password,
/*
            role: !Main.Instance.CreateMasterUserState.role_id ? '0' : Main.Instance.CreateMasterUserState.role_id,
*/
            active:!Main.Instance.CreateMasterUserState.activity_id ? '1' : Main.Instance.CreateMasterUserState.activity_id,
            id: !Main.Instance.CreateMasterUserState.id ? '' : Main.Instance.CreateMasterUserState.id,
            firstname: !Main.Instance.CreateMasterUserState.firstName ? '' : Main.Instance.CreateMasterUserState.firstName,
            lastname: !Main.Instance.CreateMasterUserState.lastName ? '' : Main.Instance.CreateMasterUserState.lastName,
            email: !Main.Instance.CreateMasterUserState.email ? '' : Main.Instance.CreateMasterUserState.email,
            phone: !Main.Instance.CreateMasterUserState.phoneNumber ? '' : Main.Instance.CreateMasterUserState.phoneNumber,
            entity_id: Main.Instance.LoginState.entityId,
        }
    } else {

        items = {
            custom: JSON.stringify(Main.Instance.CreateMasterUserState.custom),
            role : 1,
            username:       !Main.Instance.CreateMasterUserState.username        ? ''  : 
                            Main.Instance.CreateMasterUserState.username,
            password:       !Main.Instance.CreateMasterUserState.password        ? ''  : 
                            Main.Instance.CreateMasterUserState.password,
/*
            role:           !Main.Instance.CreateMasterUserState.role_id         ? '0' : 
                            Main.Instance.CreateMasterUserState.role_id,
*/
            active:         !Main.Instance.CreateMasterUserState.activity_id     ? '1' : 
                            Main.Instance.CreateMasterUserState.activity_id,
            id:             !Main.Instance.CreateMasterUserState.id              ? ''  : 
                            Main.Instance.CreateMasterUserState.id,
            firstname:      !Main.Instance.CreateMasterUserState.firstName        ? ''  : 
                            Main.Instance.CreateMasterUserState.firstName,
            
            lastname:       !Main.Instance.CreateMasterUserState.lastName        ? ''  : 
                            Main.Instance.CreateMasterUserState.lastName,

            email:          !Main.Instance.CreateMasterUserState.email        ? ''  : 
                            Main.Instance.CreateMasterUserState.email,

            phone:          !Main.Instance.CreateMasterUserState.phoneNumber        ? ''  : 
                            Main.Instance.CreateMasterUserState.phoneNumber,

            entity_id:      !Main.Instance.CreateMasterUserState.master_id? '0' : 
                            Main.Instance.CreateMasterUserState.master_id,
        }
    }
    
    // else { return }

    if (items.username.length === 0) {

        alert (Assets.compulsoryUsername)
        return
    } else if (items.password.length === 0 && Main.Instance.CreateMasterUserState.mode === false) {

        alert (Assets.compulsoryPassword)
        return
    } else if (parseInt(items.master_id) === 0) {

        alert (Assets.compulsoryCompany + Assets.Master)
        return
/*
    } else if (parseInt(items.role) === 0) {

        alert (Assets.compulsoryRole)
        return
*/
    } else if (items.firstname.length === 0) {

        alert (Assets.compulsoryFirstName)
        return
    } else if (items.lastname.length === 0) {

        alert (Assets.compulsoryLastName)
        return
    } else if (items.email.length === 0) {

        alert (Assets.compulsoryEmail)
        return
/*
    } else if (items.phone.length === 0) {
        alert (Assets.compulsoryPhone)
        return
    } else if (items.phone.charAt(0) !== '+') {
        alert (Assets.compulsoryPhoneStartWithPlus)
        return
*/
    } else if (Main.Instance.CreateMasterUserState.mode === false) {
    /* INSERT MODE*/
        if (+Main.Instance.CreateMasterUserState.security_question !== 0) {
            items.sq = +Main.Instance.CreateMasterUserState.security_question
            if (Main.Instance.CreateMasterUserState.security_question_answer === undefined ||
                Main.Instance.CreateMasterUserState.security_question_answer === null ||
                Main.Instance.CreateMasterUserState.security_question_answer.length === 0) {
                alert (Assets.compulsorySecurityAnswer)
                return
            }
            items.sqa = Main.Instance.CreateMasterUserState.security_question_answer
        }
        if (parseInt(items.role) === 1) { items.role = Assets_.masterAdmin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.masterRead.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }

        DbRoutes.insertUser (items, InsertLastPage)
    } else {
    /* UPDATE MODE*/                

        if (parseInt(items.role) === 1) { items.role = Assets_.masterAdmin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.masterRead.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }

        if (items.password.length === 0) {
            DbRoutes.updateUserNoPassword (items, Update)
        } else { 
            DbRoutes.updateUser (items, Update)
        }
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateMasterUserState, {count:Main.Instance.CreateMasterUserState.count++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateMasterUserState, {count:Main.Instance.CreateMasterUserState.count--})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateMasterUserState, {count:Main.Instance.CreateMasterUserState.count--})
    getMasterUsers()
}

function Update() {
    clearFields()
    getMasterUsers()
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.hashed SET active = ', rowData[0], rowData[6], getMasterUsersCount)
    }
}
*/
function toggleActive(id: number, value: string, row: any) {
    if (id) { DbRoutes.Toggle('UPDATE admin.hashed SET active = ', id.toString(), value, getMasterUsersCount, undefined, row) }
}

function handleEdit(row: any) { 
    gotoTop()
    if (row) {

        let activityId:string
        // let roleId:string = (parseInt(row.original.role_id) % 10).toString()
        row.original.active.toString() === 'true' ? activityId = '1' : activityId = '2'

        Logic.Type.New(Main.Instance.CreateMasterUserState, {
            custom: row.original.custom,
            custom_saved: row.original.custom,
            id:row.original.id,
            username: row.original.username,
            username_saved: row.original.username, 
            password: '',
            master_id: row.original.master_id,
            parent_id_saved: row.original.master_id,
            // role_id: roleId,
            activity_id: activityId,
            mode: true,
            firstName:row.original.firstname !== null ? row.original.firstname : '',
            firstName_saved:row.original.firstname !== null ? row.original.firstname : '',
            lastName:row.original.lastname !== null ? row.original.lastname : '',
            lastName_saved:row.original.lastname !== null ? row.original.lastname : '',
            email:row.original.email !== null ? row.original.email : '',
            email_saved:row.original.email !== null ? row.original.email : '',
            phoneNumber:row.original.phone !== null ? row.original.phone : '',
            phoneNumber_saved:row.original.phone !== null ? row.original.phone : '',
            // role_id_saved: roleId, 
            activity_id_saved: activityId, 
        })
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.MasterUser + ' ' + Assets.withUsername + ' "' + row.original.username + '"?')) {

            if (Main.Instance.CreateMasterUserState.pNum === 
                (Math.trunc((Main.Instance.CreateMasterUserState.count - 2) / 
                Main.Instance.CreateMasterUserState.pSize) + 1)) {
                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                DbRoutes.removeUser (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeUser (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function gotoInsertMode() {
    if (Main.Instance.CreateMasterUserState.username === Main.Instance.CreateMasterUserState.username_saved &&
        // (parseInt(Main.Instance.CreateMasterUserState.role_id) === parseInt(Main.Instance.CreateMasterUserState.role_id_saved)) &&
        (parseInt(Main.Instance.CreateMasterUserState.activity_id) === 
        parseInt(Main.Instance.CreateMasterUserState.activity_id_saved)) &&
        Main.Instance.CreateMasterUserState.firstName === 
        Main.Instance.CreateMasterUserState.firstName_saved &&
        Main.Instance.CreateMasterUserState.lastName === 
        Main.Instance.CreateMasterUserState.lastName_saved &&
        Main.Instance.CreateMasterUserState.phoneNumber === 
        Main.Instance.CreateMasterUserState.phoneNumber_saved &&
        Main.Instance.CreateMasterUserState.email === 
        Main.Instance.CreateMasterUserState.email_saved &&
        parseInt(Main.Instance.CreateMasterUserState.master_id) === 
        parseInt(Main.Instance.CreateMasterUserState.parent_id_saved)
        && Main.Instance.CreateMasterUserState.custom === Main.Instance.CreateMasterUserState.custom_saved
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }
    }        
}
        

function FirstPage() {

    Logic.Type.New(Main.Instance.CreateMasterUserState,{offset:(0).toString()})

    getMasterUsers()

    Logic.Type.New(Main.Instance.CreateMasterUserState,{pNum: 0})

}

// function PrevPage() {

//     if (Main.Instance.CreateMasterUserState.pNum > 0) {

//         Logic.Type.New(Main.Instance.CreateMasterUserState, {offset:((Main.Instance.CreateMasterUserState.pNum - 1) * 
//             Main.Instance.CreateMasterUserState.pSize).toString()})
//         getMasterUsers()
//         Logic.Type.New(Main.Instance.CreateMasterUserState,{pNum: Main.Instance.CreateMasterUserState.pNum - 1})
//     } else {
//         LastPage()
//     }
// }

// function NextPage() {

//     if ((Main.Instance.CreateMasterUserState.pNum + 1) * Main.Instance.CreateMasterUserState.pSize < 
//         Main.Instance.CreateMasterUserState.count) {

//         Logic.Type.New(Main.Instance.CreateMasterUserState, {
//             offset: ((Main.Instance.CreateMasterUserState.pNum + 1) * 
//             Main.Instance.CreateMasterUserState.pSize).toString()})
//         getMasterUsers()
    
//         Logic.Type.New(Main.Instance.CreateMasterUserState, {
//             pNum: Main.Instance.CreateMasterUserState.pNum + 1})                
//     } else {
//         FirstPage()
//     }
// }

function LastPage() {

    if (Main.Instance.CreateMasterUserState.count <= Main.Instance.CreateMasterUserState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0
        if (Main.Instance.CreateMasterUserState.count === 0) {

            Logic.Type.New(Main.Instance.CreateMasterUserState,{offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.CreateMasterUserState.count - 1) / 
            Main.Instance.CreateMasterUserState.pSize)
            Logic.Type.New(Main.Instance.CreateMasterUserState, {
                offset:(npNum * Main.Instance.CreateMasterUserState.pSize).toString()})
        }

        getMasterUsers()
        Logic.Type.New(Main.Instance.CreateMasterUserState, {pNum: npNum})
    }
}
/*
function ApplyFilterRole(text:string) {

        Logic.Type.New(Main.Instance.CreateMasterUserState,{filter_role:text})
        FirstPage()
}
*/
// function RecordsPerPageChanged(text:string) {

//         if (parseInt(text) > 0) {
//             Logic.Type.New(Main.Instance.CreateMasterUserState,{pSize:parseInt(text)})
//             FirstPage()
//         }
// }

export function ManageAdminUsersMaster() {
    const validation: boolean = isPasswordStrongEnough(Main.Instance.CreateMasterUserState.password)
    const validation2: boolean = isPasswordDifferent(
        Main.Instance.CreateMasterUserState.password,
        Main.Instance.CreateMasterUserState.username,
        Main.Instance.CreateMasterUserState.email,
        Main.Instance.CreateMasterUserState.phoneNumber,
    )
    const validationsq: boolean = parseInt(Main.Instance.CreateMasterUserState.security_question) !== 0
    const validationsqa: boolean = Main.Instance.CreateMasterUserState.security_question_answer.length > 0

    const tMasterUsers:string = Assets.tMasterUsers + Assets._for_ + Assets.Master + ' "' + Main.Instance.LoginState.entity + '"'

    return (
    <div>
        {isUserAdmin() ? 
        <div className = "row admin-users-container">
            <div 
                className = {Main.Instance.CreateMasterUserState.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
            >

                <div className = "create-input">
                    <div className = "row admin-users-row">
                        <h4>
                            {Main.Instance.CreateMasterUserState.mode ? 
                            Assets.editMasterUser + ': ' + 
                            Main.Instance.CreateMasterUserState.username_saved : 
                            Assets.addMasterUser}
                        </h4>
                        {Main.Instance.CreateMasterUserState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()} 
                            ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : void 0}
                    </div>                                   
                    <div className = "row admin-users-row">
                        <FormInput 
                            value = {!Main.Instance.CreateMasterUserState.username ? '' : 
                                    Main.Instance.CreateMasterUserState.username} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {username:text})}} 
                            placeholder = {Assets.pUsername} 
                            icon = {Assets_.faUserClass}
                            autoFill = "nope"
                        />
                        {/*
                        <FormSelect 
                            value = {!Main.Instance.CreateMasterUserState.role_id ? '0' : 
                                    Main.Instance.CreateMasterUserState.role_id} 
                            data = {[0]} 
                            ClassName = "form-control" 
                            o1 = {Assets.RoleQ} 
                            o2 = {Assets.Administrator} 
                            o3 = {Assets.ReadOnly} 
                            onChange = {text => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {role_id:text})}} 
                            icon = {Assets_.faRoleClass}
                        />
                        */}
                        {Main.Instance.CreateMasterUserState.mode ? void 0 :
                        <>
                            <FormInput
                                validation = {validation && validation2}
                                description = {!validation ? Assets.passwordRequirements1 : !validation2 ? Assets.passwordRequirements2 : Assets.passwordRequirements0}
                                value = {!Main.Instance.CreateMasterUserState.password ? '' : 
                                        Main.Instance.CreateMasterUserState.password} 
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(text) => {
                                    Logic.Type.New(Main.Instance.CreateMasterUserState, {
                                        password:text})}} 
                                placeholder = {Assets.pPassword} 
                                icon = {Assets_.faPasswordClass}
                                autoFill = "new-password"
                            />
                            <FormInput
                                validation = {validation && validation2}
                                description = {!validation ? Assets.passwordRequirements1 : !validation2 ? Assets.passwordRequirements2 : Assets.passwordRequirements0}
                                value = {!Main.Instance.CreateMasterUserState.password ? '' : Main.Instance.CreateMasterUserState.password} 
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {password:text})}} 
                                placeholder = {Assets.pPassword} 
                                icon = {Assets_.faPasswordClass}
                                autoFill = "new-password"
                            />
                            <FormSelect
                                validation = {validationsq}
                                value = {!Main.Instance.CreateMasterUserState.security_question ? '0' : 
                                        Main.Instance.CreateMasterUserState.security_question}
                                data = {Assets_.securityQuestions} 
                                ClassName = "form-control" 
                                o1 = {Assets_.securityQuestions[0].name} 
                                onChange = {(security_question) => Logic.Type.New(Main.Instance.CreateMasterUserState, {security_question})} 
                                icon = {Assets_.faQuestionCircleButtonClass}
                            />

                            <FormInput
                                validation = {validationsqa}
                                value = {!Main.Instance.CreateMasterUserState.security_question_answer ? '' : 
                                        Main.Instance.CreateMasterUserState.security_question_answer}
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(security_question_answer) => Logic.Type.New(Main.Instance.CreateMasterUserState, {security_question_answer})} 
                                placeholder = {Assets.pSecurityQuestionAnswer} 
                                icon = {Assets_.faQuestionCircleWhiteButtonClass}
                            />
                        </>}
                        {Main.Instance.CreateMasterUserState.custom && Main.Instance.CreateMasterUserState.custom.securityRights ? getSecurityRights() : void 0}
                    </div>
                    <div className = "row admin-users-row">
                        <FormInput 
                            value = {!Main.Instance.CreateMasterUserState.firstName ? '' : 
                                    Main.Instance.CreateMasterUserState.firstName}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {firstName:text})}}
                            placeholder = {Assets.pFirstName} 
                            icon = {Assets_.faUserClass}
                        />                  
                        <FormInput 
                            value = {!Main.Instance.CreateMasterUserState.lastName ? '' : 
                                    Main.Instance.CreateMasterUserState.lastName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {lastName:text})}} 
                            placeholder = {Assets.pLastName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateMasterUserState.email ? '' : 
                                    Main.Instance.CreateMasterUserState.email} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {email:text})}} 
                            placeholder = {Assets.pEmail} 
                            icon = {Assets_.faEmailClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateMasterUserState.phoneNumber ? '' : 
                                    Main.Instance.CreateMasterUserState.phoneNumber} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {phoneNumber:text})}} 
                            placeholder = {Assets.pPhone} 
                            icon = {Assets_.faPhoneClass}
                        />

                        <RadioButton2
                            value = {!Main.Instance.CreateMasterUserState.activity_id ? '1' :
                                    Main.Instance.CreateMasterUserState.activity_id} 
                            text = {Assets.Activity}
                            ClassName="input-group-radiobutton2 margin-bottom-sm" 
                            title={Assets.ActivityExplained}
                            i1={Assets_.active}
                            i2={Assets_.inactive}
                            onClick={(text) => {Logic.Type.New(Main.Instance.CreateMasterUserState, {activity_id:text})}} 
                        />
                        {parseInt(Main.Instance.LoginState.role) === Assets_.masterAdmin ?
                        <span className = "label label-primary fl label-table mr">
                            {Main.Instance.LoginState.entity}
                        </span>
                        :                   
                        <FormSelect 
                            value = {!Main.Instance.CreateMasterUserState.master_id ? '0' : 
                                    Main.Instance.CreateMasterUserState.master_id} 
                            data = {Main.Instance.DatabaseDataState.masterAllStream} 
                            ClassName = "form-control" 
                            o1 = {Assets.EmptyDatabase} 
                            o2 = {Assets.MasterQ} 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateMasterUserState, {master_id:text})}} 
                            icon = {Assets_.faMasterClass}
                        />}
                    </div>

                    <div className = "row admin-users-row">
                        <ButtonIcon
                            disabled = {!Main.Instance.CreateMasterUserState.mode && !validation}
                            description =   {Main.Instance.CreateMasterUserState.mode ? 
                                            Assets.saveChanges : 
                                            Assets.cMasterUser} 
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.CreateMasterUserState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr' : 
                                        'btn btn-primary btn-sm btn-plus fr'} 
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div>
        <br/>
        </div>
        : void 0}
        {/* <div className = "control-panel">

            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CreateMasterUserState.pSize ? '' : 
                Main.Instance.CreateMasterUserState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.CreateMasterUserState.filter_role? '0' : 
                        Main.Instance.CreateMasterUserState.filter_role}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.RoleAny} 
                o2 = {Assets.Administrator}
                o3 = {Assets.ReadOnly}
                onChange = {text => ApplyFilterRole(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CreateMasterUserState.count ? 
                Main.Instance.CreateMasterUserState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CreateMasterUserState.count ? 
                Main.Instance.CreateMasterUserState.pNum * 
                Main.Instance.CreateMasterUserState.pSize + 1 : 0}{' - '}
                {Main.Instance.CreateMasterUserState.count ? 
                ((Main.Instance.CreateMasterUserState.pNum + 1) * 
                Main.Instance.CreateMasterUserState.pSize > 
                Main.Instance.CreateMasterUserState.count ? 
                Main.Instance.CreateMasterUserState.count : 
                (Main.Instance.CreateMasterUserState.pNum + 1) * 
                Main.Instance.CreateMasterUserState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.CreateMasterUserState.count ? Main.Instance.CreateMasterUserState.pNum + 1 :0}{' / '}
                {Main.Instance.CreateMasterUserState.count ? 
                Math.trunc((Main.Instance.CreateMasterUserState.count - 1) /
                Main.Instance.CreateMasterUserState.pSize+1) : 0}
            </span>
        </div> */}

        <h3>{isUserSystem() ? Assets.tMasterUsers : tMasterUsers}</h3>
        {isUserSystem() && Main.Instance.DatabaseDataState.users0Stream || isUserMaster() && Main.Instance.DatabaseDataState.user0Stream ?
        <ReactTable
            data = {isUserSystem() ? Main.Instance.DatabaseDataState.users0Stream : Main.Instance.DatabaseDataState.user0Stream}
            columns = {[
                {
                    Header: '',
                    headerClassName: 'risk-default-light ',
                    columns: [
                {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {Header: Assets.Username, accessor: 'username', headerClassName: 'th-left', Cell: (row) => <span title = {row.value} style = {{fontWeight: row.original.id === Main.Instance.LoginState.id ? 900 : 400}}>{row.value}</span>},
                /*{minWidth: 120, Header: 'Role', accessor: 'role', headerClassName: 'th-left'},*/
                /*{minWidth: 60, Header: Assets_GDPR, accessor: 'gdpr', headerClassName: 'th-left', Cell: (row) =>
                <button disabled = {!isUserAdmin()} onClick = {() => toggleGDPR(row.original.id, row.value)} style = {{border: '0px', background: 'transparent'}}><FontAwesomeIcon style = {row.value ? {color: 'green'} : {color: 'red'}} icon = {row.value ? faEye : faEyeSlash} size = "2x"/></button>},*/
                {width: 150, Header: Assets.Master, accessor: 'master', show: isUserSystem(), headerClassName: 'th-left'},
                {width: 300, Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                <div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faUser} />
                        {row.original.firstname !== null && row.original.lastname !== null && row.original.firstname.length > 0 && row.original.lastname.length > 0 ?
                        row.original.firstname + ' ' + row.original.lastname : row.original.firstname !== null && row.original.firstname.length > 0 ?
                        row.original.firstname : row.original.lastname !== null && row.original.lastname.length > 0 ? row.original.lastname : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faEnvelope}/>
                        {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faPhone}/>
                        {row.original.phone !== null && row.original.phone.length > 0 ? row.original.phone : Assets.notDefined}
                    </div>
                </div>},
                {width: 60, Header: Assets.Active, accessor: 'active', Cell: (row) => 
                <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => toggleActive(row.original.id, row.value, row.original)}
                        on = {row.value}
                        disabled = {!isUserAdmin()}
                    />
                </div>},
                ]},
                {
                Header: Assets.SecurityRights,
                headerClassName: 'risk-default ',
                columns: [

                    {width: 105, Header: Assets_.SecurityRights[SecurityRights.ADMINISTRATOR], accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.administrator, SecurityRights.ADMINISTRATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.administrator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ADMINISTRATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUserFriends}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.SUPPORT]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.support, SecurityRights.SUPPORT, row.value, row.original)}
                        on = {row.original.custom.securityRights.support}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.SUPPORT] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.OPERATOR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.operator, SecurityRights.OPERATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.operator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.OPERATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span><FontAwesomeIcon icon = {faBullhorn}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.MARKETING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.marketing, SecurityRights.MARKETING, row.value, row.original)}
                        on = {row.original.custom.securityRights.marketing}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.MARKETING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span title = {Assets.RFSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RFSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.rfSpecialist, SecurityRights.RFSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rfSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RFSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span title = {Assets.RGSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RGSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.rgSpecialist, SecurityRights.RGSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rgSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RGSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 70, Header: <span><FontAwesomeIcon icon = {faUserSecret}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GDPR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.gdpr, SecurityRights.GDPR, row.value, row.original)}
                        on = {row.original.custom.securityRights.gdpr}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.GDPR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 110, Header: <span><FontAwesomeIcon icon = {faUserTie}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.ACCOUNTING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.accounting, SecurityRights.ACCOUNTING, row.value, row.original)}
                        on = {row.original.custom.securityRights.accounting}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ACCOUNTING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {width: 90, Header: <span><FontAwesomeIcon icon = {faChartLine}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.REPORTS]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserReports() && !isLocalhost() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.unused3, SecurityRights.REPORTS, row.value, row.original)}
                        on = {row.original.custom.securityRights.unused3}
                        disabled = {!isUserReports() && !isLocalhost()}
                        title = {isUserReports() || isLocalhost() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.REPORTS] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 95, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}><FontAwesomeIcon icon = {faFire}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN].split(' ')[0]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        on = {row.original.custom.securityRights.unused1}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}><FontAwesomeIcon icon = {faDice}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.unused2, SecurityRights.GAMANZA_GAMES_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused2}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}
                    />
                    </div>},

                    {show: isUserGamanzaAdmin() && isUserGamanzaMagicHand(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}><FontAwesomeIcon icon = {faMagic}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() && isUserGamanzaMagicHand() ? toggleSecurityRights(4, getMasterUsersCount, row.original.id, row.original.custom.securityRights.unused7, SecurityRights.GAMANZA_MAGIC_HAND, row.value, row.original) : void 0}
                        disabled = {!(isUserGamanzaAdmin() && isUserGamanzaMagicHand())}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused7}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}
                    />
                    </div>},
                ]},
                {
                    Header: '',
                    headerClassName: 'risk-default-light ',
                    columns: [
                {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)}]},
            ]}
            showPaginationBottom = {false}
            showPaginationTop = {true}
            defaultPageSize={10}
        />
        : getMasterUsersCount()}

        {/*console.log (Main.Instance.DatabaseDataState.users0Stream)*/}
        {/*isUserSystem() ? Main.Instance.DatabaseDataState.users0Stream ?
            StremingTable(Main.Instance.DatabaseDataState.users0Stream, {
                title: Assets.tMasterUsers,  
                mode: Main.Instance.CreateMasterUserState.mode,
                editingId: Main.Instance.CreateMasterUserState.id,
                sort: getMasterUsersCount,
                Sort:Main.Instance.CreateMasterUserState,
                toggleButton:(data) => handleToggle(data),
                delButton:(data) => handleDelete(data), 
            editButton:(data) => handleEdit(data)}) : void 0 : void 0*/}

        {/*isUserMaster() ? Main.Instance.DatabaseDataState.user0Stream ?
            StremingTable(Main.Instance.DatabaseDataState.user0Stream, {
                title: tMasterUsers,  
                mode: Main.Instance.CreateMasterUserState.mode,
                editingId: Main.Instance.CreateMasterUserState.id,
                sort: getMasterUsersCount,
                Sort:Main.Instance.CreateMasterUserState,
                toggleButton:(data) => handleToggle(data),
                delButton:(data) => handleDelete(data), 
            editButton:(data) => handleEdit(data)}) : void 0 : void 0*/}
    </div>
    )

}
