import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput,
        // RadioButton3,
        FormInputArea,
        FormInputNumber, 
        FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as PlayersSelection from './PlayersSelection'
import {
    // utilsTimeValid,
    // Select,
    // SelectValue2,
    // SelectCurrency,
    // SelectCurrency2,
    isUserSystem,
    isUserMaster,
    isUserSubEntity,
    isUserGameProvider,
    isUserOperator,
    isUserSiteOwner,
    utilsTimeValidEmptyNotValid,
    post,
    AllowDecimalCharactersOnly,
    GetValidGamesNames,
    gotoTop,
    IsBuildMyChance
} from '../../../Logic/Utils'
import Switch from 'react-toggle-switch'
import {SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
// import fontawesome from '@fortawesome/fontawesome'
// import pack from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy, faFlagCheckered} from '@fortawesome/free-solid-svg-icons'
import {Modal} from '../../Reusables/Modal'
import {GamesSelection} from './GamesSelection'
// import {PlayersSelection} from './PlayersSelection'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {/*faLightbulb, */faSave, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {getFreeSpinsData, getRewardsData} from './Store'
// import {Tournament} from '../../../Logic/Database/DatabaseData'

function PartialAdvanced(): string {
    if (+Main.Instance.TournamentState.tournamentType > 0 ||
        Main.Instance.TournamentState.info && Main.Instance.TournamentState.info.length > 0 ||

        +Main.Instance.TournamentState.min_rounds > 0 ||
        +Main.Instance.TournamentState.max_rounds > 0
    ) { 
    return ' partial'
    } else { return '' }
}

function PartialPoints(): string {
    if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.bigWin !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.megaWin !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveWins !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveLosses !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.bigWinsBooster !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.megaWinsBooster !== -1
    ) {
    return ' partial'
    } else { return '' }
}

function PartialRaces(): string {
    if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails !== -1) {
        return ' partial'
    } else { return '' }
}

function PartialOptin(): string {
    if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.optin !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.min !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.max !== -1 ||
        Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.play !== -1
    ) {
    return ' partial'
    } else { return '' }
}
{/*
function PartialBoosters(): string {
    if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.boost !== -1)
    return ' partial'
    else return ''
}
*/}
function PartialValidGames(): string {
    if (Main.Instance.TournamentState.tournament && (Main.Instance.TournamentState.tournament.valid !== -1 || Main.Instance.TournamentState.tournament.invalid !== -1)) {
    return ' partial'
    } else { return '' }
}

function PartialPrizes(): string {
    if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.prize !== -1) {
    return ' partial'
    } else { return '' }
}

function MinRoundsChanged(min_rounds: string) {
    if (min_rounds.length > 1 && min_rounds.charAt(0) === '0' && min_rounds.charAt(1) !== '.') {
        min_rounds = min_rounds.slice (1, min_rounds.length)
    }
    Logic.Type.New(Main.Instance.TournamentState, {min_rounds})
}

function MaxRoundsChanged(max_rounds: string) {
    if (max_rounds.length > 1 && max_rounds.charAt(0) === '0' && max_rounds.charAt(1) !== '.') {
        max_rounds = max_rounds.slice (1, max_rounds.length)
    }    
    Logic.Type.New(Main.Instance.TournamentState, {max_rounds})
}

function MinBetSizeChanged(i: number, min_bet: string) {
    if (!AllowDecimalCharactersOnly(min_bet)) { return }
    const B: Assets_.ICurrencies[] = Object.assign ([], Main.Instance.TournamentState.B)
    if (min_bet.length > 0 && min_bet.charAt(0) === '0' && min_bet.charAt(1) !== '.') {
        min_bet = min_bet.slice (1, min_bet.length)
    }
    B[i].minBet = min_bet
    Logic.Type.New(Main.Instance.TournamentState, {B})

}

function MaxBetSizeChanged(i: number, max_bet: string) {
    if (!AllowDecimalCharactersOnly(max_bet)) { return }
    const B: Assets_.ICurrencies[] = Object.assign ([], Main.Instance.TournamentState.B)
    if (max_bet.length > 0 && max_bet.charAt(0) === '0' && max_bet.charAt(1) !== '.') {
        max_bet = max_bet.slice (1, max_bet.length)
    }
    B[i].maxBet = max_bet
    Logic.Type.New(Main.Instance.TournamentState, {B})
}

function ModeAdvanced() {
    Logic.Type.New (Main.Instance.TournamentState, {modeAdvanced: !Main.Instance.TournamentState.modeAdvanced})
}

function ModePoints() {
    Logic.Type.New (Main.Instance.TournamentState, {modeMultipliers: !Main.Instance.TournamentState.modeMultipliers})
}

function ModeOptin() {
    Logic.Type.New (Main.Instance.TournamentState, {modeOptin: !Main.Instance.TournamentState.modeOptin})
}
{/*
function ModeBoosters() {
    Logic.Type.New (Main.Instance.TournamentState, {modeBoosters: !Main.Instance.TournamentState.modeBoosters})
}
*/}
function ModeValidGames() {
    Logic.Type.New (Main.Instance.TournamentState, {modeValidGames: !Main.Instance.TournamentState.modeValidGames})
}

function ModePrizes() {
    Logic.Type.New (Main.Instance.TournamentState, {modePrizes: !Main.Instance.TournamentState.modePrizes})
}

function ModeRaces() {
    Logic.Type.New (Main.Instance.TournamentState, {modeRaces: !Main.Instance.TournamentState.modeRaces})
}

function ModeResults() {
    Logic.Type.New (Main.Instance.TournamentState, {modeResults: !Main.Instance.TournamentState.modeResults})
}

function AllowTimeCharactersOnly(text: string, which: number, which2?: number) {
    let proceed: boolean = text.length <= 8 ? true : false
    let dot: number = 0
    let colon: number = 0
    let space: number = 0
    let first: number
    let second: number
    
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '.'
            && text.charAt(i) !== ':'
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
            && text.charAt(i) !== ' '
        ) { proceed = false }

        if (text.charAt(i) === '.') { dot++ } else if (text.charAt(i) === ':') { colon++ } else if (text.charAt(i) === ' ') { space++ }
        if (dot > 2 || colon > 2 || space > 2 || dot * colon > 0 || space * colon > 0 || space * dot > 0) {
            proceed = false
        }

        if (proceed && (text.charAt(i) === '.' || text.charAt(i) === ':' || text.charAt(i) === ' ')) {
            if (first) { second = i } else { first = i }
        }
    }

    if (proceed) {
        let t: string = ''

        if (text.length === 5 || text.length === 8) {
            if (!first) { proceed = false }
            if (proceed && first === 2) { t += text.charAt(0) + text.charAt(1) }
            if (proceed && first === 2 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && first === 2) { t += text.charAt(3) + text.charAt(4) }
            if (proceed && second && second === 5 && text.length === 8 && (which === 2 || which === 3)) { t += ':' }
            if (proceed && second && second === 5 && text.length === 8) { t += text.charAt(6) + text.charAt(7) }
        } else { proceed = false }

        if (which === 0 && proceed) {
            Logic.Type.New(Main.Instance.TournamentState, {from_time: text, fromTime: t})
        } else if (which === 0) {
            Logic.Type.New(Main.Instance.TournamentState, {from_time: text, fromTime: ''})
        } else if (which === 1 && proceed) {
            Logic.Type.New(Main.Instance.TournamentState, {to_time: text, toTime: t})
        } else if (which === 1) {
            Logic.Type.New(Main.Instance.TournamentState, {to_time: text, toTime: ''})
        } else if (which === 2 && proceed && which2) {
            const recurring_from_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_from_time)
            recurring_from_time[which2] = text
            const recurringFromTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringFromTime)
            recurringFromTime[which2] = t
            Logic.Type.New(Main.Instance.TournamentState, {recurring_from_time, recurringFromTime})
        } else if (which === 2 && which2) {
            const recurring_from_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_from_time)
            recurring_from_time[which2] = text
            const recurringFromTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringFromTime)
            recurringFromTime[which2] = ''
            Logic.Type.New(Main.Instance.TournamentState, {recurring_from_time, recurringFromTime})
        } else if (which === 3 && proceed && which2) {
            const recurring_to_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_to_time)
            recurring_to_time[which2] = text
            const recurringToTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringToTime)
            recurringToTime[which2] = t
            Logic.Type.New(Main.Instance.TournamentState, {recurring_to_time, recurringToTime})
        } else if (which === 3 && which2) {
            const recurring_to_time: string[] = Object.assign ([], Main.Instance.TournamentState.recurring_to_time)
            recurring_to_time[which2] = text
            const recurringToTime: string[] = Object.assign ([], Main.Instance.TournamentState.recurringToTime)
            recurringToTime[which2] = ''
            Logic.Type.New(Main.Instance.TournamentState, {recurring_to_time, recurringToTime})
        }
    }
}

function DateChange(which: number, date: moment.Moment) {
    const temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    if (which === 0) { Logic.Type.New(Main.Instance.TournamentState, {fromDate: temp, from_date: date}) } else if (which === 1) { Logic.Type.New(Main.Instance.TournamentState, {toDate: temp, to_date: date}) }
}

function getTournamentsCount() {
    // alert ('refresh tournaments')
    DbRoutes.WsGetTournaments()
    if (isUserSiteOwner() || isUserOperator()) {
        DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                        parseInt(Main.Instance.LoginState.entityId),
                                        SelectSite)
    }
}

function SelectSite(stream: any[]) {
    
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                site_id: stream[0]['id'],
        })
        DbRoutes.WsGetTournaments()
    }
}

export function isLocationRaces(): boolean {
    if (Main.Instance.Location === '/promo-store/races') {
        return true
    } else {
        return false
    }
}

function handleEdit(row: any) { 
    gotoTop()
    // alert (rowdata[10])
    // console.log(row)

    // const fromDate:string = row.original['Valid from (' + Assets_.UTC + ')'].trim() === '' ? '' : row.original['Valid from (' + Assets_.UTC + ')'].substring (0, 10)
    const fromDate:string = row.original.validFrom.substring (0, 10)
    let toDate: string = ''
    // if (row.original['Valid to (' + Assets_.UTC + ')'] !== null) { toDate = row.original['Valid to (' + Assets_.UTC + ')'].trim() === '' ? '' : row.original['Valid to (' + Assets_.UTC + ')'].substring (0, 10) }
    if (row.original.toDate !== null) {
        toDate = row.original.validTo.substring (0, 10)
    }

    let fromTime = ''
    if (row.original.tournamentType !== 'Daily') { /* fromTime = row.original['Valid from (' + Assets_.UTC + ')'].substring (11, 19) */ fromTime = row.original.validFrom.substring (11, 19)}
    let toTime = ''
    if (row.original.tournamentType !== 'Daily') { /* toTime = row.original['Valid to (' + Assets_.UTC + ')'].substring (11, 19) */  toTime = row.original.validTo.substring (11, 19)}
    
    const rounds = row.original.rounds.split(' - ')
    const maxRounds: string = rounds[1] === '∞' ? '0' : rounds[1]
 /*   
    let betSize = rowdata[9].toString().split(' - ')
    let maxBetSize: string = betSize[1] === '∞' ? '0' : betSize[1]
*/
    const tournament = {
        consecutiveWins: 0,
        consecutiveLosses: 0,
        bigWin: 0,
        megaWin: 0,
        consecutiveWinsBooster: 0,
        bigWinsBooster: 0,
        megaWinsBooster: 0,
        optin: 0,
        min: 0,
        max: 0,
        play: 0,
        boost: 0,
        valid: 0,
        invalid: 0,
        prize: 0,
        raceDetails: 0
    }
    
    const M = JSON.parse(JSON.stringify(row.original.points))
    if (M === undefined || M === null || M.bigWin === undefined) { tournament.bigWin = -1 }
    if (M === undefined || M === null || M.megaWin === undefined) { tournament.megaWin = -1 }
    if (M === undefined || M === null || M.consecutiveWins === undefined) { tournament.consecutiveWins = -1 }
    if (M === undefined || M === null || M.consecutiveLosses === undefined) { tournament.consecutiveLosses = -1 }

    if (M === undefined || M === null || M.consecutiveWinsBooster === undefined) { tournament.consecutiveWinsBooster = -1 }
    if (M === undefined || M === null || M.bigWinsBooster === undefined) { tournament.bigWinsBooster = -1 }
    if (M === undefined || M === null || M.megaWinsBooster === undefined) { tournament.megaWinsBooster = -1 }
    
    const O = JSON.parse(JSON.stringify(row.original['optIn']))
    if (O === undefined || O === null || O.optinCost === undefined) { tournament.optin = -1 }
    if (O === undefined || O === null || O.minLevel === undefined) { tournament.min = -1 }
    if (O === undefined || O === null || O.maxLevel === undefined) { tournament.max = -1 }
    if (O === undefined || O === null || O.players === undefined) { tournament.play = -1 } else {
        // console.log (O.players)
        const playersStreamSelected: boolean[] = []
        const count: number = 0
        if (Main.Instance.DatabaseDataState.playersStream && O) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) { for (let j: number = 0; j < O.players.length; j++) {
            if (count === O.players.length) {
                playersStreamSelected.push(false)
                break
            }
            if (Main.Instance.DatabaseDataState.playersStream[i]['player'] === O.players[j]) {
                playersStreamSelected.push(true)
                break
            }
            if (j === O.players.length - 1) { playersStreamSelected.push(false) }
        }
        }
        }
        // console.log (playersStreamSelected)
        Logic.Type.New(Main.Instance.DatabaseDataState, {playersStreamSelected})
    }

    const B/*0*/ = JSON.parse(JSON.stringify(row.original['minMaxBets']))
    // const B = [B0]
    if (B === undefined || B === null || B.length === 0) { tournament.boost = -1 }
/*
    else for (let item of B) {

        if (item.duration) {
            item.durationHours = getDuration (item.duration, 0)
            item.durationMinutes = getDuration (item.duration, 1)
            item.durationSeconds = getDuration (item.duration, 2)
        }
    }
*/
    const V = JSON.parse(JSON.stringify(row.original['validGames']))
    if (V === undefined || V === null) { tournament.valid = -1 }

    const P = JSON.parse(JSON.stringify(row.original['prizes']))
    if (P === undefined || P === null) { tournament.prize = -1 }

    const I = JSON.parse(JSON.stringify(row.original['invalidGames']))
    if (I === undefined || I === null) { tournament.invalid = -1 }
    
    // if (row.original.raceDetails && row.original.raceDetails.date) {
    const RS = JSON.parse(JSON.stringify(row.original.raceDetails))
    if (RS === undefined || RS === null) { tournament.raceDetails = -1 }
    // }

    Logic.Type.New(Main.Instance.TournamentState, {
        mode: true,
        id: row.original.id,
        title: row.original.name,
        title_saved: row.original.name,
        info: row.original.description,
        info_saved: row.original.description,
        // leaderboard_type2: rowdata[3],
        from_date: fromDate.length === 0 ? null : moment.default(new Date(fromDate)),
        to_date: toDate.length === 0 ? null : moment.default(new Date(toDate)),
        fromDate,
        toDate,
        fromTime,
        toTime,
        from_time: fromTime,
        to_time: toTime,
        leaderboard_type: row.original.leaderboardType,
        tournamentType: row.original.tournamentType === 'Daily' ? '1' : IsBuildMyChance() && isLocationRaces() ? '3' : '2' ,
        min_rounds: rounds[0],
        max_rounds: maxRounds,
        active: row.original.active && row.original.active.toString() === 'true' ? true: false,

        
/*
        bet_amount_min: [betSize[0]],
        bet_amount_max: [maxBetSize],
*/
        M,
        O,
        B,
        V,
        I,
        P,
        RS,
        tournament,
        // startHour: row.original.raceDetails ? row.original.raceDetails.date.hour : '',
        // startMinute: row.original.raceDetails ? row.original.raceDetails.date.minute : '',
        // durationMinutes: row.original.raceDetails ? row.original.raceDetails.date.durationMinutes : '',
        // dayOfWeek: row.original.raceDetails ? row.original.raceDetails.date.dayOfWeek : '',
    })
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Tournament + ' ' + 
            Assets.withName + ' "' + row.original.name + '"?')) {

            if (Main.Instance.TournamentState.pNum === 
            (Math.trunc((Main.Instance.TournamentState.count - 2) / 
            Main.Instance.TournamentState.pSize) + 1)) {
                DbRoutes.removeTournament (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeTournament (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.TournamentState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,
    })

    if (parseInt(text) === 0) {
        // Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getTournaments()
    }
}

export function clearFields() {
    if (Main.Instance.DatabaseDataState.playersStream) {
        const playersStreamSelected: boolean[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) { playersStreamSelected.push(false) }
        Logic.Type.New(Main.Instance.DatabaseDataState, {playersStreamSelected})
    }

    if (Main.Instance.TournamentState.mode) {
    Logic.Type.New(Main.Instance.TournamentState, {
        active: false,
        mode: false,
        modeAdvanced: false,
        modeMultipliers: false,
        modeOptin: false,
        modeBoosters: false,
        modeValidGames: false,
        modePrizes: false,
        modeRaces: false,
        from_date: null,
        to_date: null,
        id: '0',
        title: '',
        from_time: '',
        to_time: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        valid_from: '',
        valid_to: '',
        min_rounds: '',
        max_rounds: '',
        leaderboard_type: '0',
        info: '',
        // startHour: '',
        // durationMinutes: '',
        // dayOfWeek: '',
        // startMinute: '',
/*
        bet_amount_min: ['0'],
        bet_amount_max: ['0'],
*/
        tournamentType: IsBuildMyChance() ? isLocationRaces() ? '3' : '2' : '0',
        selection: 0,
        B: [],
        M: {},
        O: {},
        V: [],
        P: [],
        RS: [],
        tournament: undefined,
    })
    Logic.Type.New (Main.Instance.DatabaseDataState, {tournamentLeaderboard: undefined})
    }
}

function gotoInsertMode() {
    
    if (Main.Instance.TournamentState.title === Main.Instance.TournamentState.title_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.TournamentState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function getTournaments() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {tournamentStream: undefined})
    getTournamentsCount()
}

function FirstPage() {
    Logic.Type.New(Main.Instance.TournamentState, {offset:(0).toString()})
    getTournaments()
    Logic.Type.New(Main.Instance.TournamentState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.TournamentState.pNum > 0) {

        Logic.Type.New(Main.Instance.TournamentState, {
            offset:((Main.Instance.TournamentState.pNum - 1) * 
            Main.Instance.TournamentState.pSize).toString()})
        getTournaments()

        Logic.Type.New(Main.Instance.TournamentState,{pNum: Main.Instance.TournamentState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.TournamentState.pNum + 1) * Main.Instance.TournamentState.pSize < 
    Main.Instance.TournamentState.count) {

        Logic.Type.New(Main.Instance.TournamentState, {
            offset: ((Main.Instance.TournamentState.pNum + 1) * 
            Main.Instance.TournamentState.pSize).toString()})
        getTournaments()
        
        Logic.Type.New(Main.Instance.TournamentState,{pNum: Main.Instance.TournamentState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.TournamentState.count <= Main.Instance.TournamentState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.TournamentState.count === 0) {

            Logic.Type.New(Main.Instance.TournamentState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.TournamentState.count - 1) / 
                    Main.Instance.TournamentState.pSize)
            Logic.Type.New(Main.Instance.TournamentState, {
                offset:(npNum * Main.Instance.TournamentState.pSize).toString()})
        }

        getTournaments()

        Logic.Type.New(Main.Instance.TournamentState,{pNum: npNum})
    }
}

function handleInsertUpdate(duplicate?: boolean, updateTournament?: any) : any {
    // if updateTournament, we need just to toggle active
    if (updateTournament) {
        // console.log (updateTournament)
        DbRoutes.updateTournament (updateTournament)
    } else {
        if ((Main.Instance.TournamentState.from_date === null || Main.Instance.TournamentState.from_date === undefined)) {
            alert(Assets.tsd)
            return
        }

        if ((Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)
            && parseInt(Main.Instance.TournamentState.tournamentType) !== 1) {
            alert(Assets.ted)
            return
        }

        if (!isLocationRaces() && Main.Instance.TournamentState.from_time.length === 0 && parseInt(Main.Instance.TournamentState.tournamentType) !== 1) {
            alert(Assets.tst); return
        }
    
        if (!isLocationRaces() && Main.Instance.TournamentState.to_time.length === 0 && parseInt(Main.Instance.TournamentState.tournamentType) !== 1) {
            alert(Assets.tet); return
        }

        let validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.from_time)

        if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.from_time.length === 0 &&
        (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) { 
            validationFromTime = true
        }

        if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.from_time.length > 0 &&
            (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) {
            validationFromTime = false
        }

        if (validationFromTime === false && parseInt(Main.Instance.TournamentState.tournamentType) === 2) {
            alert(Assets.tstnc)
            return
        }

        let validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.to_time)
        if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.to_time.length === 0 &&
        (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) { 
            validationToTime = true
        }

        if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.to_time.length > 0 &&
            (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) {
            validationToTime = false
        }

        if (validationToTime === false && parseInt(Main.Instance.TournamentState.tournamentType) === 2) {
            alert(Assets.tetnc)
            return
        }

        let validFrom = Main.Instance.TournamentState.fromDate 
        if (Main.Instance.TournamentState.from_time && Main.Instance.TournamentState.from_time.length > 0) {
        validFrom += ' ' + Main.Instance.TournamentState.from_time.replace('|', ':').replace('|', ':').replace('/', ':').replace('/', ':')
        .replace(' ', ':').replace(' ', ':').replace('.', ':').replace('.', ':').replace('-', ':').replace('-', ':').replace('\\', ':').replace('\\', ':')
        }
        
        let validTo = Main.Instance.TournamentState.to_date !== null && Main.Instance.TournamentState.to_date !== undefined && Main.Instance.TournamentState.toDate !== null ? 
                    Main.Instance.TournamentState.toDate : null
        if (validTo !== null && Main.Instance.TournamentState.to_time && Main.Instance.TournamentState.to_time.length > 0) {
        validTo += ' ' + Main.Instance.TournamentState.to_time.replace('|', ':').replace('|', ':').replace('/', ':').replace('/', ':')
        .replace(' ', ':').replace(' ', ':').replace('.', ':').replace('.', ':').replace('-', ':').replace('-', ':').replace('\\', ':').replace('\\', ':')
        }

        if (Main.Instance.TournamentState.to_date !== null && Main.Instance.TournamentState.to_date !== undefined && validTo <= validFrom &&
            parseInt(Main.Instance.TournamentState.tournamentType) === 2) {
            alert(Assets.tt)
            return
        }

        
    /*
        let boosters = []

        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.boost !== -1) {
            let B = Object.assign([], Main.Instance.TournamentState.B)
            for (let item of B) {
                if (item.durationHours !== undefined) delete(item.durationHours)
                if (item.durationMinutes !== undefined) delete(item.durationMinutes)
                if (item.durationSeconds !== undefined) delete(item.durationSeconds)
            }
            boosters = Object.assign([], B)
        }
    */
        const points = {}
        if (Main.Instance.TournamentState.M !== undefined && Main.Instance.TournamentState.M !== null) {
            points['win'] = Main.Instance.TournamentState.M['win']
            
            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.bigWin !== -1) {
                points['bigWin'] = Object.assign({}, Main.Instance.TournamentState.M['bigWin'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.megaWin !== -1) {
                points['megaWin'] = Object.assign({}, Main.Instance.TournamentState.M['megaWin'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveWins !== -1) {
                points['consecutiveWins'] = Object.assign([], Main.Instance.TournamentState.M['consecutiveWins'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveLosses !== -1) {
                points['consecutiveLosses'] = Object.assign([], Main.Instance.TournamentState.M['consecutiveLosses'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== -1) {
                points['consecutiveWinsBooster'] = Object.assign([], Main.Instance.TournamentState.M['consecutiveWinsBooster'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.bigWinsBooster !== -1) {
                points['bigWinsBooster'] = Object.assign({}, Main.Instance.TournamentState.M['bigWinsBooster'])
            }

            if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.megaWinsBooster !== -1) {
                points['megaWinsBooster'] = Object.assign({}, Main.Instance.TournamentState.M['megaWinsBooster'])
            }
        }

        if (+Main.Instance.TournamentState.leaderboard_type === 2 && (points === undefined || points['win'] === undefined || points['win'] === 0)) {
            alert(Assets.yu)
            return
        }

        const optIn = {}
        optIn['required'] = Main.Instance.TournamentState.O.required
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.optin !== -1) {
            optIn['optinCost'] = Main.Instance.TournamentState.O.optinCost 
        }

        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.min !== -1) {
            optIn['minLevel'] = Main.Instance.TournamentState.O.minLevel
        }
        
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.max !== -1) {
            optIn['maxLevel'] = Main.Instance.TournamentState.O.maxLevel
        }

        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.play !== -1) {
            const players: number[] = []
            if (Main.Instance.DatabaseDataState.playersStreamSelected) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStreamSelected.length; i++) {
                if (Main.Instance.DatabaseDataState.playersStreamSelected[i]) {
                    players.push (Main.Instance.DatabaseDataState.playersStream[i]['player'])
                }
            }
            }
            optIn['players'] = players // Main.Instance.TournamentState.O.players
        }

        let validGames = []
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.valid !== -1) {
            validGames = Object.assign([], Main.Instance.TournamentState.V)
        }
        
        let invalidGames = []
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.invalid !== -1) {
            invalidGames = Object.assign([], Main.Instance.TournamentState.I)
        }

        let prizes = []
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.prize !== -1) {
            prizes = Object.assign([], Main.Instance.TournamentState.P)
        }

        if (prizes.length === 0) {
            alert (Assets.ftt)
            return
        } else {
            for (const item of prizes) {
                if (item.rewardId === 0) {
                    alert (Assets.ftp + ' (' + item.firstRewarded + '-' + item.lastRewarded + ')')
                    return
                }
            }
        }

        let raceDetails = []
        if (Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails !== -1) {
            raceDetails = Object.assign([], Main.Instance.TournamentState.RS)
        }

        if (isLocationRaces() && raceDetails && raceDetails.length > 0) {
            for (const item of raceDetails) {
                if (item.dayOfWeek === -1 || item.hour === -1) {
                    alert (Assets.Scheduled + ' ' + Assets.Races.toLowerCase() + ' ' + Assets.Require.toLowerCase() + ' ' + Assets.Day.toLowerCase() + Assets._of_ + Assets.Week.toLowerCase() + Assets._and_ + Assets.Hour.toLowerCase())
                    return
                }
            }
        }

        const t = {
            id : !Main.Instance.TournamentState.id ? '' : Main.Instance.TournamentState.id,
            name : duplicate ? Assets.DuplicateTournament : !Main.Instance.TournamentState.title ? '' : Main.Instance.TournamentState.title,
            description: !Main.Instance.TournamentState.info ? '' : Main.Instance.TournamentState.info,
            validFrom: validFrom === null || validFrom.trim().length === 0 ? null : validFrom.trim(),
            validTo: validTo === null || validTo.trim().length === 0 ? null : validTo.trim(),
            leaderboardType: +Main.Instance.TournamentState.leaderboard_type,
            tournamentType: +Main.Instance.TournamentState.tournamentType,
            minRounds: +Main.Instance.TournamentState.min_rounds,
            maxRounds: +Main.Instance.TournamentState.max_rounds,
            active: Main.Instance.TournamentState.active,
    /*
            minBet: Main.Instance.TournamentState.bet_amount_min[0] !== null && Main.Instance.TournamentState.bet_amount_min[0] !== undefined ? 
                    Main.Instance.TournamentState.bet_amount_min[0] : '0',
            maxBet: Main.Instance.TournamentState.bet_amount_max[0] !== null && Main.Instance.TournamentState.bet_amount_max[0] !== undefined ? 
                    Main.Instance.TournamentState.bet_amount_max[0] : '0',
    */
            points,
            optIn,
            minMaxBets: Main.Instance.TournamentState.B,
            prizes,
            validGames: JSON.stringify(validGames).replace('[','{').replace(']','}'),
            invalidGames: JSON.stringify(invalidGames).replace('[','{').replace(']','}'),
            raceDetails
        }
        // console.log (t.validGames)
        // return
    /*
        if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
            alert (Assets.compulsorySite)
            return
        } else */
        if (t.name.length === 0) {
            alert (Assets.compulsoryTournamentTitle)
            return

        } else if (t.tournamentType === 0) {
            alert (Assets.compulsoryTournamentType)
            return    
        } else if (t.leaderboardType === 0) {
            alert (Assets.compulsoryLeaderboardType)
            return
        }
        
        if (!Main.Instance.TournamentState.mode || duplicate) {
            // alert ('INSERT ' + JSON.stringify(t))
            DbRoutes.insertTournament (t/*, InsertLastPage*/)
        } else {
            // alert ('UPDATE ' + JSON.stringify(t))
            DbRoutes.updateTournament (t/*, Update*/)
        }
    }
}

export function InsertLastPage() {
    clearFields()
    // getTournaments()
    Logic.Type.New(Main.Instance.TournamentState, {count: Main.Instance.TournamentState.count ++})
    LastPage()
}

export function Update() {
    clearFields()
    getTournaments()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.TournamentState,{count:Main.Instance.TournamentState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.TournamentState,{count:Main.Instance.TournamentState.count --})
    getTournaments()
}

/*
function Expand (pos: number) {

}
*/
function OpenGS(valid: boolean) {

    const array: number[] = valid ? Main.Instance.TournamentState.V : Main.Instance.TournamentState.I
    const GamesStreamAllSelected: boolean[] = []

    if (array && array.length > 0) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            let proceed: boolean = false
            for (const item of array) {                    
                if (item === Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id']) {
                    proceed = true
                    break
                }
            }
            GamesStreamAllSelected.push(proceed)
        }

    } else {
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                GamesStreamAllSelected.push(false)
            }
        }
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: true, ModalLoyaltyFreeRoundsGames: valid})
    Logic.Type.New(Main.Instance.GamesState, {categorize: false})
}

function CloseGS() {
    if (Main.Instance.Modal.ModalLoyaltyFreeRoundsGames === true) {
        const V: number[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
            if (Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
                V.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
        }

        Logic.Type.New(Main.Instance.TournamentState, {V})
    } else {
        const I: number[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
            if (Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
                I.push(+Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
        }

        Logic.Type.New(Main.Instance.TournamentState, {I})
    }
    Logic.Type.New(Main.Instance.Modal, {ModalWagerPlanGames: false})
}

function getMinMaxByCurrency(): JSX.Element[] {
    const minmax: JSX.Element[] = []
    minmax.push (
        <div key = {-1} className = "row admin-users-row clearfix" style = {{position: 'relative', height: '35px'}}>
            <button 
                className = "btn btn-danger btn-xs fl"
                style = {{width: '140px', marginRight: '10px', height: '29px', fontSize: '16px', fontWeight: 700}}
                onClick = {() => {
                    const B: Assets_.ICurrencies[] = []
                    for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
                        B.push ({code: Main.Instance.DatabaseDataState.currenciesStream[j]['code']})
                        
                    }
                    Logic.Type.New(Main.Instance.TournamentState, {B})
                }}
            >
                {Assets.Reset + ' ' + Assets.Currencies.toLowerCase()}
            </button>
        </div>
    )
    if (Main.Instance.DatabaseDataState.currenciesStream && (Main.Instance.TournamentState.B === undefined || Main.Instance.TournamentState.B.length === 0)) {
        
        const B: Assets_.ICurrencies[] = []
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            B.push ({code: Main.Instance.DatabaseDataState.currenciesStream[j]['code']})
            
        }
        Logic.Type.New(Main.Instance.TournamentState, {B})
    }
    // console.log(Main.Instance.DatabaseDataState.currenciesStream)
    // console.log(Main.Instance.TournamentState.B)
    if (Main.Instance.DatabaseDataState.currenciesStream && Main.Instance.TournamentState.B) {
    
    for (let i: number = 0; i < Main.Instance.TournamentState.B.length; i++) {
        let code: string
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            if (Main.Instance.TournamentState.B[i]['code'] === Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                
                break
            }
        }
        
        if (code === undefined) {continue}
        minmax.push (
            <div key = {i.toString()} className = "row admin-users-row clearfix" style = {{position: 'relative', height: '35px'}}>
                <button 
                    className = {Main.Instance.TournamentState.B && Main.Instance.TournamentState.B[i] && Main.Instance.TournamentState.B[i].minBet ? 
                    'btn btn-success btn-xs fl' : 'btn btn-danger btn-xs fl'}
                    style = {{width: '50px', marginRight: '10px', height: '29px', fontSize: '16px', fontWeight: 700}}
                    onClick = {() => {
                        const B: Assets_.ICurrencies[] = Object.assign ([], Main.Instance.TournamentState.B)
                        if (B[i].minBet === undefined) { B[i] = {code, minBet: '0', maxBet: '0'} } else { B[i] = {code} }
                        Logic.Type.New(Main.Instance.TournamentState, {B})
                    }}
                >
                    {code}
                </button>
                {Main.Instance.TournamentState.B && Main.Instance.TournamentState.B[i] && Main.Instance.TournamentState.B[i].minBet !== undefined ?
                <>
                    <FormInput
                        narrow = {true}
                        description = {Assets.MinimumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.TournamentState.B && Main.Instance.TournamentState.B[i] && Main.Instance.TournamentState.B[i].minBet ?
                                Main.Instance.TournamentState.B[i].minBet : '0'}
                        type = "text"
                        // min = "0"
                        iconText = {Assets.MinBetSize}
                        iconTextSize = "14px"
                        onChange = {(text) => MinBetSizeChanged(i, text)}
                    />
                    <FormInput
                        narrow = {true}
                        description = {Assets.MaximumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.TournamentState.B && Main.Instance.TournamentState.B[i] && Main.Instance.TournamentState.B[i].maxBet ? 
                                Main.Instance.TournamentState.B[i].maxBet : '0'}
                        type = "text"
                        // min = "0"
                        iconText = {Assets.MaxBetSize}
                        iconTextSize = "14px"
                        onChange = {(text) => MaxBetSizeChanged(i, text)}
                    />
                </> : void 0}
            </div>,
        )
    }
    }
    return minmax
}

function getDayOfWeekOptions() {
    const options: Array<{id: number, name: string}> = []
    options.push({id: -1, name: Assets.Select + ' ' + Assets.Day.toLowerCase() + Assets._of_ + Assets.Week.toLowerCase()})
    for (let i: number = 1; i <= Assets.DaysInWeek.length; i++) {
        options.push({id: i === 7 ? 0 : i, name: Assets.DaysInWeek[i === 7 ? 0 : i]})
    }
    return options
}

function getHourOptions() {
    const options: Array<{id: number, name: string}> = []
    options.push({id: -1, name: Assets.Select + ' ' + Assets.Hour.toLowerCase()})
    for (let i: number = 0; i < 24; i++) {
        options.push({id: i, name: i.toString() + 'h ' + Assets_.UTC})
    }
    return options
}

export function TournamentsOddyzzey() {

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
    
    if (!Main.Instance.TournamentState.Leaderboard_type) {
        const Leaderboard_type: any[] = []
        for (let i:number = 0; i < Assets.LeaderBoardOddyzzey.length; i++) {
            Leaderboard_type.push({id:(i+1).toString(), name: Assets.LeaderBoardOddyzzey[i]})
        }
        Logic.Type.New(Main.Instance.TournamentState, {Leaderboard_type})
    }    

    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>

    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.from_time)
    let validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.TournamentState.to_time)
    if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.to_time.length === 0 &&
        (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) {
        validationToTime = true
    }
    if (parseInt(Main.Instance.TournamentState.tournamentType) === 1 && Main.Instance.TournamentState.to_time.length > 0 &&
        (Main.Instance.TournamentState.to_date === null || Main.Instance.TournamentState.to_date === undefined)) {
        validationToTime = false
    }

    // console.log ('just before return')
    // console.log (Main.Instance.DatabaseDataState.tournamentStream)
    return (
    <div>
    {<Modal isOpen = {Main.Instance.Modal.ModalWagerPlanGames} close = {() => CloseGS()}>{GamesSelection()}</Modal>}
        <div className = "clearfix">
            <h3 className = "level fl">
                {isLocationRaces() ? Assets.AddEditRaces : Assets.aeTournaments}
            </h3>

            {isUserSystem() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                        Main.Instance.FrontendStoreState.holding_id}
                data = {Main.Instance.DatabaseDataState.masterAllStream}
                ClassName = "form-control"

                o1 = {Assets.SelectMaster}
                o2 = {Assets.MasterQ}
                onChange = {(text) => ApplyMasterSelect(text)}
                icon = {Assets_.faMasterClass}
            /> : void 0}

            {isUserSystem() || isUserMaster() || isUserGameProvider() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                        Main.Instance.FrontendStoreState.operator_id}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                label1 = {Assets.OP}
                label2 = {Assets.BO}
                
                ClassName = "form-control"

                o1 = {selectOperator}
                o2 = {operatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            />
            : void 0}

            {!isUserSubEntity() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                        Main.Instance.FrontendStoreState.site_id}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o1 = {selectSite}
                o2 = {siteQ}
                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0}
        </div>
        {/*!Main.Instance.TournamentState.modeLeaderboard ? <div className = "clearfix"/> : void 0*/}

{/*SECTION MAIN*/}
        {/*!Main.Instance.TournamentState.modeLeaderboard ?*/}
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb'}
            >
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4 className = "width-auto">
                            {isLocationRaces() ? 
                            Main.Instance.TournamentState.mode ? 
                            Assets.EditTheRace /*+ ': ' + 
                            Main.Instance.TournamentState.title_saved*/ : 
                            Assets.AddANewRace : 
                            Main.Instance.TournamentState.mode ? 
                            Assets.editTournamentItem /*+ ': ' + 
                            Main.Instance.TournamentState.title_saved*/ : 
                            Assets.addTournamentItem}
                        </h4>
                        
                        

                        {Main.Instance.TournamentState.mode ? 
                        <button
                            title = {Assets.discardChanges} 
                            onClick = {() => gotoInsertMode()}
                            className = "btn btn-default btn-sm btn-anything"
                            style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                        >
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                        : void 0}
                    </div>

                    <div className = "row admin-users-row clearfix">
                    
                        <FormInput
                           
                            narrow = {true}
                            value = {!Main.Instance.TournamentState.title ? '' : Main.Instance.TournamentState.title}
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {title: text})}
                            placeholder = {Assets.pName} 
                            icon = {isLocationRaces() ? undefined : Assets_.faAchieveClass}
                            Icon = {isLocationRaces() ? faFlagCheckered : undefined}
                        />
                        
                        <FormSelect
                            // floatRight = {true}
                            level165 = {true}
                            value = {!Main.Instance.TournamentState.leaderboard_type ? '0' :
                                    Main.Instance.TournamentState.leaderboard_type}
                            data = {Main.Instance.TournamentState.Leaderboard_type}
                            ClassName = "form-control"
                            o1 = {Assets.SelectLeaderboardType}
                            o2 = {Assets.SelectLeaderboardType}
                            onChange = {(text) => // {
                                Logic.Type.New(Main.Instance.TournamentState, {leaderboard_type: text})
                                // DbRoutes.handleLeaderboard()
                                // }
                            }
                            icon = {Assets_.faCogClass}
                            narrow = {true}
                        />
                        {/*<div className = "fl">&nbsp;&nbsp;</div>*/}
                        <FormSelect
                            level165 = {true}
                            value = {
                                !Main.Instance.TournamentState.tournamentType ? '0' :
                                    Main.Instance.TournamentState.tournamentType}
                            data = {IsBuildMyChance() ? isLocationRaces() ? [{id:'1', name: 'Daily', disabled: true}, {id:'2', name: 'Special', disabled: true}, {id:'3', name: 'Races'}] : [{id:'1', name: 'Daily', disabled: true}, {id:'2', name: 'Special'}, {id:'3', name: 'Races', disabled: true}] : [{id:'1', name: 'Daily'}, {id:'2', name: 'Special'}]}
                            ClassName = "form-control"
                            o1 = {isLocationRaces() ? Assets.SelectRaceType : Assets.SelectTournamentType}
                            o2 = {isLocationRaces() ? Assets.SelectRaceType : Assets.SelectTournamentType}
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.TournamentState, {tournamentType: text})
                            }}
                            icon = {Assets_.faCogsClass}
                            narrow = {true}
                        />
                        {isLocationRaces() ? void 0 :
                        <ButtonIcon
                            click = {() => ModeResults()}
                            ClassName = {Main.Instance.TournamentState.modeResults ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0 greengray'/* + PartialResults()*/}
                            icon = {Assets_.faListOlClass} 
                            text = {Assets.Results}
                            description = {Assets.Results}
                        />}

                        <ButtonIcon
                            click = {() => ModePrizes()}
                            ClassName = {Main.Instance.TournamentState.modePrizes ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0' + PartialPrizes()}
                            icon = {Assets_.faAchievementClass} 
                            text = {Assets.Prizes}
                            description = {Assets.Prizes}
                        />
                        <ButtonIcon
                            click = {() => ModeValidGames()}
                            ClassName = {Main.Instance.TournamentState.modeValidGames ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0' + PartialValidGames()}
                            icon = {Assets_.faGamingClass} 
                            text = {Assets.ValidGames}
                            description = {Assets.ValidGames}
                        />
{/*
                        <ButtonIcon
                            click = {() => ModeBoosters()}
                            ClassName = {Main.Instance.TournamentState.modeBoosters ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mt-5 mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mt-5 mr0' + PartialBoosters()}
                            icon = {Assets_.faActivityClass} 
                            text = "Boosters"
                            description = "Boosters"
                        />
*/}
                        <ButtonIcon
                            click = {() => ModeOptin()}
                            ClassName = {Main.Instance.TournamentState.modeOptin ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0' + PartialOptin()}
                            icon = {Assets_.faChallengeClass} 
                            text = {Assets.OptIn}
                            description = {Assets.OptIn}
                        />

                        <ButtonIcon
                            click = {() => ModeAdvanced()}
                            ClassName = {Main.Instance.TournamentState.modeAdvanced ?
                                        'btn btn-success btn-sm btn-plus fr mr0 ml5px ' :
                                        'btn btn-default btn-sm btn-plus fr mr0 ml5px ' + PartialAdvanced()}
                            icon = {Assets_.faCogsClass} 
                            text = {Assets.AdvancedSettingsShort}
                            description = {Assets.AdvancedSettings}
                        />
                        {/*console.log (Main.Instance.TournamentState.leaderboardType)*/}
                        {+Main.Instance.TournamentState.leaderboard_type === 2 ? 
                        <ButtonIcon
                            click = {() => ModePoints()}
                            ClassName = {Main.Instance.TournamentState.modeMultipliers ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0' + PartialPoints()}
                            icon = {Assets_.faDatabaseButtonClass} 
                            text = {Assets.Points}
                            description = {Assets.Points}
                        /> : void 0}
                        {isLocationRaces() ? 
                        <ButtonIcon
                            click = {() => ModeRaces()}
                            ClassName = {Main.Instance.TournamentState.modeRaces ?
                                        'btn btn-success btn-sm btn-plus fr ml5px mr0' :
                                        'btn btn-default btn-sm btn-plus fr ml5px mr0' + PartialRaces()}
                            Icon = {faFlagCheckered}
                            text = {Assets.Schedule}
                            description = {Assets.Races + ' ' + Assets.Schedule.toLowerCase()}
                        /> : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix">
                        <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                            <span
                                title = {Assets.Start}
                                style = {{fontSize: '14px', paddingTop: '7px', paddingBottom: '7px', color: '#333', background: '#fff'}}
                                className = "label label-default label-sm"
                            >
                                <i className = {Assets_.faHourglassStartClass}/>
                            </span>
                        </div>
                        
                        <div className = "fl mr singledatepicker">
                            <SingleDatePicker
                                id = "date3"
                                placeholder = {Assets.Date}
                                date = {Main.Instance.TournamentState.from_date}
                                onDateChange = {(date) => DateChange(0, date)}
                                focused = {Main.Instance.TournamentState.focusedInput}
                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.TournamentState, {focusedInput: focused.focused})}
                                showDefaultInputIcon = {true}
                                showClearDate = {true}
                                isOutsideRange = {() => false}
                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                // customArrowIcon = {Assets_.customArrowIcon}
                                customCloseIcon = {Assets_.customCloseIcon}
                                numberOfMonths = {1}
                            />
                        </div>
                        {+Main.Instance.TournamentState.tournamentType === 1 || isLocationRaces() ? void 0 :
                        <FormInput
                            date = {true}
                            validation = {validationFromTime}
                            value = {!Main.Instance.TournamentState.from_time ? '' : Main.Instance.TournamentState.from_time} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 0)} 
                            placeholder = {Assets.Time}
                            icon = {Assets_.faTimeNarrowClass}
                        />}
                        {+Main.Instance.TournamentState.tournamentType === 1 ? void 0 :
                        <div style = {{marginTop: '5px', marginRight: '10px', fontWeight: 700}} className = "fl">
                            <span
                                title = {Assets.End}
                                style = {{fontSize: '14px', paddingTop: '7px', paddingBottom: '7px', color: '#333', background: '#fff', marginLeft: '0px'}}
                                className = "label label-default label-sm"
                            >
                                <i className = {Assets_.faHourglassEndClass}/>
                            </span>
                        </div>}
                        {/*+Main.Instance.TournamentState.tournamentType === 1 ? void 0 :*/}
                        <div className = "fl mr singledatepicker">
                            <SingleDatePicker
                                id = "date4"
                                placeholder = {Assets.Date}
                                date = {Main.Instance.TournamentState.to_date}
                                onDateChange = {(date) => DateChange(1, date)}
                                focused = {Main.Instance.TournamentState.focusedInput2}
                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.TournamentState, {focusedInput2: focused.focused})}
                                showDefaultInputIcon = {true}
                                showClearDate = {true}
                                isOutsideRange = {() => false}
                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                // customArrowIcon = {Assets_.customArrowIcon}
                                customCloseIcon = {Assets_.customCloseIcon}
                                numberOfMonths = {1}
                            />
                        </div>
                        {+Main.Instance.TournamentState.tournamentType === 1 || isLocationRaces() ? void 0 :
                        <FormInput
                            date = {true}
                            validation = {validationToTime}
                            value = {!Main.Instance.TournamentState.to_time ? '' : Main.Instance.TournamentState.to_time} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => AllowTimeCharactersOnly(text, 1)} 
                            placeholder = {Assets.Time}
                            icon = {Assets_.faTimeNarrowClass}
                        />}
                        
                        {<span style = {{display: 'inline-block', marginTop: '2px'}} title = {Main.Instance.TournamentState.active ? Assets.TournamentActive : Assets.TournamentInactive}>
                            <Switch
                                className = "off-background-grey"
                                onClick = {() => Logic.Type.New (Main.Instance.TournamentState, {active: !Main.Instance.TournamentState.active})}
                                on = {Main.Instance.TournamentState.active}
                            />
                        </span>}
                    </div>
                    <div className = {isLocationRaces() ? 'row admin-users-row-area-small clearfix mt5' : 'row admin-users-row-area-small clearfix'} >
                        <div className = "register-input textarea-small fl" style = {{height: '40px'}}>
                            <FormInputArea  
                                value = {!Main.Instance.TournamentState.info ? '' : Main.Instance.TournamentState.info}
                                ClassName = "form-control register-input"
                                onChange = {(info) => Logic.Type.New (Main.Instance.TournamentState, {info})}
                                placeholder = {isLocationRaces() ? Assets.Race + ' ' + Assets.Description.toLowerCase() : Assets.TournamentDescriptionOptional}
                                icon = {Assets_.faCommentClass}
                            />
                        </div>
                    </div>
                    
                    <div className = "row admin-users-row clearfix">
                        <ButtonIcon
                            disabled = {!everythingNotOverlapping() || !checkAll()}
                            description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                            click = {() => handleInsertUpdate()}
                            ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div>
{/*SECTION MAIN*/}
{/* RACES */}
            {Main.Instance.TournamentState.modeRaces ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    <button
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-anything"
                        style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                    <span
                        title = {Assets.Races}
                        style = {{
                            // width:'13%',
                            width: '50px',
                            textAlign: 'center',
                            marginRight:'1%',
                            float: 'left',
                            display: 'block',
                            height: '30px',
                            fontSize: '16px',
                            paddingTop:'7px',
                        }}
                        className = "label label-default"
                    >   <i style = {{marginRight: '0px'}}> <FontAwesomeIcon icon = {faFlagCheckered}/></i>
                    </span>
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.Schedule}
                        className = {Main.Instance.TournamentState.RS && Main.Instance.TournamentState.tournament && 
                            Main.Instance.TournamentState.tournament.raceDetails !== undefined && Main.Instance.TournamentState.tournament.raceDetails !== -1 ? 
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                        onClick = {() => Toggle(18)}
                    >
                        {Assets.Schedule}
                    </button>
                    {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails !== undefined && Main.Instance.TournamentState.tournament.raceDetails !== -1 ?
                    getRaceSchedule() : void 0}
                    </div>
                    <div className = "mt"> 
                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails !== undefined && Main.Instance.TournamentState.tournament.raceDetails !== -1 ?
                        getRacesSelects() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon
                            disabled = {!everythingNotOverlapping() || !checkAll()}
                            description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                            click = {() => handleInsertUpdate()}
                            ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div> : void 0}

{/* ADVANCED */}
            {Main.Instance.TournamentState.modeAdvanced ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
                    <button
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-anything"
                        style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                    {/*</div>*/}
                    {/*<div className = "row admin-users-row">*/}
                    <span
                        title = {Assets.AdvancedSettingsShort}
                        style = {{
                            // width:'13%',
                            width: '50px',
                            textAlign: 'center',
                            marginRight:'10px',
                            float: 'left',
                            display: 'block',
                            height: '30px',
                            fontSize: '16px',
                            paddingTop:'7px',
                        }}
                        className = "label label-default"
                    >   <i className = {Assets_.faCogsClass} style = {{marginRight: '0px'}}/>
                    </span>

                    <FormInputNumber
                        description = {Assets.MinimumNumberOfRounds}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName= "h30 w60"
                        value = {Main.Instance.TournamentState.min_rounds && Main.Instance.TournamentState.min_rounds.length > 0 ? Main.Instance.TournamentState.min_rounds : '0'}
                        type = "number"
                        min = "0"
                        iconText = {Assets.MinRounds}
                        iconTextS = "14px"
                        onChange = {(text) => MinRoundsChanged(text)}
                        noIconTextFix = {true}
                    />
                    <FormInputNumber
                        description = {Assets.MaximumNumberOfRounds}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        value = {Main.Instance.TournamentState.max_rounds && Main.Instance.TournamentState.max_rounds.length > 0 ? Main.Instance.TournamentState.max_rounds : '0'}
                        type = "number"
                        min = "0"
                        iconText = {Assets.MaxRounds}
                        iconTextS = "14px"
                        onChange = {(text) => MaxRoundsChanged(text)}
                        noIconTextFix = {true}
                    />
{/* 
                    <FormInput
                        narrow = {true}
                        description = {Assets.MinimumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.TournamentState.bet_amount_min && Main.Instance.TournamentState.bet_amount_min.length > 0 ? Main.Instance.TournamentState.bet_amount_min[0] : '0'}
                        type = "text"
                        // min = "0"
                        iconText = {Assets.MinBetSize}
                        iconTextSize = "14px"
                        onChange = {text => MinBetSizeChanged(text)}
                    />
                    <FormInput
                        narrow = {true}
                        description = {Assets.MaximumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.TournamentState.bet_amount_max && Main.Instance.TournamentState.bet_amount_max.length > 0 ? Main.Instance.TournamentState.bet_amount_max[0] : '0'}
                        type = "text"
                        // min = "0"
                        iconText = {Assets.MaxBetSize}
                        iconTextSize = "14px"
                        onChange = {text => MaxBetSizeChanged(text)}
                    />
*/}
                    {Main.Instance.TournamentState.mode ?
                    <button onClick = {() => handleInsertUpdate(true)}className = "btn btn-danger btn-sm">
                        <FontAwesomeIcon icon = {faCopy}/>&nbsp;&nbsp;{Assets.DuplicateTournament}
                    </button> : void 0}
                    
                    
                    </div>
                    {getMinMaxByCurrency()}
                    <div className = "row admin-users-row clearfix">
                        <ButtonIcon
                            disabled = {!everythingNotOverlapping() || !checkAll()}
                            description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                            click = {() => handleInsertUpdate()}
                            ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div> : void 0}
{/* MULTIPLIERS */}
            {Main.Instance.TournamentState.modeMultipliers ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    <button
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-anything"
                        style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                        <span
                            title = {Assets.Points}
                            style = {{
                                // width:'13%',
                                width: '50px',
                                textAlign: 'center',
                                marginRight:'10px',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                            }}
                            className = "label label-default"
                        >   <i className = {Assets_.faDatabaseButtonClass} style = {{marginRight: '0px'}}/>
                        </span>

                        <FormInputNumber
                            description = {Assets.WinPoints}
                            outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                            ClassName = "h30 w60"
                            value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.win ? Main.Instance.TournamentState.M.win.toString() : '0'}
                            type = "number"
                            min = "0"
                            iconText = {Assets.WinPoints.toLowerCase()}
                            iconTextS = "14px"
                            onChange = {(text) => WinChanged(text)}
                            noIconTextFix = {true}
                        />
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + ' ' + Assets.ConsecutiveWins}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.consecutiveWins !== undefined && Main.Instance.TournamentState.tournament.consecutiveWins !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(11)}
                        >
                            {Assets.ConsecutiveWins}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.consecutiveWins !== undefined && Main.Instance.TournamentState.tournament.consecutiveWins !== -1 ?
                        getConsecutiveWins() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + ' ' + Assets.ConsecutiveLosses}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.consecutiveLosses !== undefined && Main.Instance.TournamentState.tournament.consecutiveLosses !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(12)}
                        >
                            {Assets.ConsecutiveLosses}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.consecutiveLosses !== undefined && Main.Instance.TournamentState.tournament.consecutiveLosses !== -1 ?
                        getConsecutiveLosses() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + ' ' + Assets.BigWins}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.bigWin!== undefined && Main.Instance.TournamentState.tournament.bigWin!== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(13)}
                        >
                            {Assets.BigWins}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.bigWin!== undefined && Main.Instance.TournamentState.tournament.bigWin!== -1 ?
                        getBigWins() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + Assets.MegaWins}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.megaWin !== undefined && Main.Instance.TournamentState.tournament.megaWin !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(14)}
                        >
                            {Assets.MegaWins}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.megaWin !== undefined && Main.Instance.TournamentState.tournament.megaWin !== -1 ?
                        getMegaWins() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + Assets.ConsecutiveWinsBooster}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== undefined && Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(15)}
                        >
                            {Assets.ConsecutiveWinsBooster}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== undefined && Main.Instance.TournamentState.tournament.consecutiveWinsBooster !== -1 ?
                        getConsecutiveWinsBooster() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + Assets.BigWinsBooster}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.bigWinsBooster !== undefined && Main.Instance.TournamentState.tournament.bigWinsBooster !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(16)}
                        >
                            {Assets.BigWinsBooster}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.bigWinsBooster !== undefined && Main.Instance.TournamentState.tournament.bigWinsBooster !== -1 ?
                        getBigWinsBooster() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px'}}
                            title = {Assets.Toggle + Assets.MegaWinsBooster}
                            className = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.megaWinsBooster !== undefined && Main.Instance.TournamentState.tournament.megaWinsBooster !== -1 ?
                                        'btn btn-success btn-sm dbl fl mr0' : 'btn btn-danger btn-sm dbl fl mr0'}
                            onClick = {() => Toggle(17)}
                        >
                            {Assets.MegaWinsBooster}
                        </button>

                        {Main.Instance.TournamentState.tournament && 
                        Main.Instance.TournamentState.tournament.megaWinsBooster !== undefined && Main.Instance.TournamentState.tournament.megaWinsBooster !== -1 ?
                        getMegaWinsBooster() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <ButtonIcon
                            disabled = {!everythingNotOverlapping() || !checkAll()}
                            description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                            click = {() => handleInsertUpdate()}
                            ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div> : void 0}

{/* OPTIN */}
            {Main.Instance.TournamentState.modeOptin ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    <button
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-anything"
                        style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                    <span
                        title = {Assets.OptIn}
                        style = {{
                            width: '50px',
                            textAlign: 'center',
                            marginRight:'1%',
                            float: 'left',
                            display: 'block',
                            height: '30px',
                            fontSize: '16px',
                            paddingTop:'7px',
                        }}
                        className = "label label-default"
                    >   <i className = {Assets_.faChallengeClass} style = {{marginRight: '0px'}}/>
                    </span>
                    
                    {/* isLocationRaces() ? */}
                    <span className = "fl dbl mr mt5">
                        {Assets.OptIn + ' ' + Assets.required}
                    </span>

                    <Switch
                        className = "fl dbl mr mt2"
                        onClick = {() => {
                            const O = Object.assign ({}, Main.Instance.TournamentState.O)
                            O.required = !O.required
                            Logic.Type.New(Main.Instance.TournamentState, {O})
                            
                        }}
                        on = {Main.Instance.TournamentState.O.required}
                    />
                    {/*: void 0*/}
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.ToggleOptInCost}
                        className = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                     Main.Instance.TournamentState.tournament.optin !== undefined && Main.Instance.TournamentState.tournament.optin !== -1 ? 
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                        onClick = {() => Toggle(3)}
                    >
                        {Assets.Cost}
                    </button>
                    {Main.Instance.TournamentState.tournament !== undefined && Main.Instance.TournamentState.tournament.optin !== undefined && Main.Instance.TournamentState.tournament.optin !== -1 ?
                        <div className = "fl">
                        <FormInputNumber
                            description = {Assets.optInCost}
                            ClassName = "h30 w60 pl3 pr0"
                            value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.O.optinCost !== undefined ? Main.Instance.TournamentState.O.optinCost.toString() : '0'}
                            type = "number"
                            min = "0"
                            onChange = {(text) => OptinCostChanged(text)}
                            noIconTextFix = {true}
                        />
                        </div>
                    : void 0}
                    
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MinimumLevel}
                        className = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 ?
                                     'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(4)}
                    >
                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.minLevel && Main.Instance.TournamentState.O.minLevel.level &&
                        Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 ?
                        Assets.MinLevel +' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.TournamentState.O.minLevel.level - 1]['name'] : 
                        Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && Main.Instance.DatabaseDataState.levelsAllStream &&
                        Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 ?
                        Assets.MinLevel + ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[0]['name'] : Assets.MinLevel}
                    </button>
                    {Main.Instance.TournamentState.tournament !== undefined && Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MinimumLevel}
                                ClassName = "h30 w40px pl3 pr0"
                                value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.minLevel !== undefined ?
                                        Main.Instance.TournamentState.O.minLevel.level.toString() : '1'}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream.length + 1).toString()}
                                onChange = {(text) => MinLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div> : void 0}
                    {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 ?
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.MinimumSubLevel}
                        className = {Main.Instance.TournamentState.O.minLevel !== undefined && Main.Instance.TournamentState.O.minLevel.sublevel !== undefined ?
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-warning btn-sm dbl fl ml0 mr0'}
                        onClick = {() => {
                            const O = Object.assign({}, Main.Instance.TournamentState.O)
                            if (O.minLevel === undefined) { O.minLevel = {level: 1} }
                            if (O.minLevel.sublevel === undefined) { O.minLevel.sublevel = 1 } else { delete(O.minLevel.sublevel) }
                            Logic.Type.New (Main.Instance.TournamentState, {O})
                        }
                        }
                    >
                        {Assets.Sub}
                    </button> : void 0}
                    {Main.Instance.TournamentState.tournament !== undefined && Main.Instance.TournamentState.tournament.min !== undefined && Main.Instance.TournamentState.tournament.min !== -1 && 
                    Main.Instance.TournamentState.O.minLevel && Main.Instance.TournamentState.O.minLevel.sublevel !== undefined ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MinimumSubLevel}
                                ClassName = "h30 w60px pl3 pr0"
                                value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.minLevel !== undefined ?
                                         Main.Instance.TournamentState.O.minLevel.sublevel.toString() : '1'}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.TournamentState.O.minLevel.level - 1]['sublevels'] + 1).toString()}
                                onChange = {(text) => MinSubLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div>
                    : void 0}
                    
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MaximumLevel}
                        className = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 ?
                                     'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(5)}
                    >
                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.maxLevel && Main.Instance.TournamentState.O.maxLevel.level &&
                        Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 ?
                        Assets.MaxLevel + ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.TournamentState.O.maxLevel.level - 1]['name'] :
                        Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && Main.Instance.DatabaseDataState.levelsAllStream && 
                        Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 ?
                        Assets.MaxLevel + ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.DatabaseDataState.levelsAllStream.length - 1]['name'] : Assets.MaxLevel}
                    </button>
                    {Main.Instance.TournamentState.tournament !== undefined && Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MaximumLevel}
                                ClassName = "h30 w40px pl3 pr0"
                                value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.maxLevel !== undefined ?
                                        Main.Instance.TournamentState.O.maxLevel.level.toString() :
                                        Main.Instance.DatabaseDataState.levelsAllStream.length.toString()}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream.length + 1).toString()}
                                onChange = {(text) => MaxLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div> : void 0}
                    {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 ?
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MaximumSubLevel}
                        className = {Main.Instance.TournamentState.O.maxLevel !== undefined && Main.Instance.TournamentState.O.maxLevel.sublevel !== undefined ?
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-warning btn-sm dbl fl ml0 mr0'}
                        onClick = {() => {
                            const O = Object.assign({}, Main.Instance.TournamentState.O)
                            if (O.maxLevel === undefined) { O.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length} }
                            if (O.maxLevel.sublevel === undefined) { O.maxLevel.sublevel = 
                                Main.Instance.DatabaseDataState.levelsAllStream[O.maxLevel.level - 1]['sublevels']
                            } else { delete(O.maxLevel.sublevel) }
                            Logic.Type.New (Main.Instance.TournamentState, {O})
                        }
                        }
                    >
                        {Assets.Sub}
                    </button> : void 0}
                    {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament !== undefined &&
                    Main.Instance.TournamentState.tournament.max !== undefined && Main.Instance.TournamentState.tournament.max !== -1 && 
                    Main.Instance.TournamentState.O.maxLevel && Main.Instance.TournamentState.O.maxLevel.sublevel !== undefined ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MaximumSubLevel}
                                ClassName = "h30 w60px pl3 pr0"
                                value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.maxLevel !== undefined ?
                                         Main.Instance.TournamentState.O.maxLevel.sublevel.toString() : 
                                        Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.TournamentState.O.maxLevel.level - 1]['sublevels'].toString()}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.TournamentState.O.maxLevel.level - 1]['sublevels'] + 1).toString()}
                                onChange = {(text) => MaxSubLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div>
                    : void 0}
                    {isLocationRaces() ? void 0 :
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.Players}
                        className = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.tournament.play !== undefined && Main.Instance.TournamentState.tournament.play === 1 ?
                                    'btn btn-primary btn-sm dbl fl ml mr0' : Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.tournament.play !== undefined && Main.Instance.TournamentState.tournament.play === 0 ?
                                    'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(6)}
                    >
                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.play !== undefined &&
                        Main.Instance.TournamentState.tournament.play !== -1 ? Assets.Players + ' (' + PlayersSelection.CountPlayersSelected() + ' ' + Assets.Selected.toLowerCase() + ')' : Assets.Players}
                    </button>}
                    </div>
                    {!isLocationRaces() && Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.play !== undefined && Main.Instance.TournamentState.tournament.play === 0 ?
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginTop: '5px'}}>
                        {PlayersSelection.PlayersSelection()}
                    </div> : void 0}

                    <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginTop: '5px'}}>
                    {/*
                    <button
                        style = {{borderRadius: '0px', marginLeft: '60px'}}
                        title = "Toggle Players"
                        className = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                                    Main.Instance.TournamentState.tournament.play !== undefined && Main.Instance.TournamentState.tournament.play !== -1 ?
                                    'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(6)}
                    >
                        Players
                    </button>
                    */}
                    {/*Main.Instance.TournamentState.O && Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.play !== undefined && Main.Instance.TournamentState.tournament.play !== -1 ?
                        getPlayers()
                    : void 0*/}
                    <ButtonIcon
                        disabled = {!everythingNotOverlapping() || !checkAll()}
                        description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                        click = {() => handleInsertUpdate()}
                        ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                        Icon = {faSave as IconDefinition}
                        text = {Assets.Save}
                    />
                    </div>
                </div>
            </div> : void 0}

{/* BOOSTERS */}
{/*
            {Main.Instance.TournamentState.modeBoosters ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => Logic.Type.New (Main.Instance.TournamentState, {modeBoosters: false})}
                            ClassName = "btn btn-nothing-absolute btn-sm btn-plus" 
                            icon = {Assets_.faCancelButtonClass}
                        />
                    <span
                        title = {Assets.Boosters}
                        style = {{
                            // width:'13%',
                            width: '50px',
                            textAlign: 'center',
                            marginRight:'1%',
                            float: 'left',
                            display: 'block',
                            height: '30px',
                            fontSize: '16px',
                            paddingTop:'7px'
                        }}
                        className = "label label-default"
                    >   <i className = {Assets_.faActivityClass} style = {{marginRight: '0px'}}/>
                    </span>
                    <button
                        style = {{borderRadius: '0px'}}
                        title = "Toggle Boosters"
                        className = {Main.Instance.TournamentState.B && Main.Instance.TournamentState.tournament && 
                                     Main.Instance.TournamentState.tournament.boost !== undefined && Main.Instance.TournamentState.tournament.boost !== -1 ? 
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                        onClick = {() => Toggle(7)}
                    >
                        Boosters
                    </button>

                    {Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.boost !== undefined && Main.Instance.TournamentState.tournament.boost !== -1 ?
                    getBoosters() : void 0}

                    <ButtonIcon 
                        description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                        click = {() => handleInsertUpdate()}
                        ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                        Icon = {faSave}
                        // text = {Assets.Save}
                    />
                    </div>
                </div>
            </div> : void 0}
*/}
{/* VALID GAMES */}
            {Main.Instance.TournamentState.modeValidGames ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">

                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <button
                            title = {Assets.discardChanges} 
                            onClick = {() => gotoInsertMode()}
                            className = "btn btn-default btn-sm btn-anything"
                            style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                        >
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    
                        <span
                            title = {Assets.ValidGames}
                            style = {{
                                width: '50px',
                                textAlign: 'center',
                                marginRight:'10px',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                            }}
                            className = "label label-default"
                        >   <i className = {Assets_.faGamingClass} style = {{marginRight: '0px'}}/>
                        </span>
                        <button
                            style = {{borderRadius: '0px', width: '107px'}}
                            title = {Assets.Toggle + ' ' + Assets.Include.toLowerCase() + ' ' + Assets.Games.toLowerCase()}
                            className = {Main.Instance.TournamentState.V && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.valid !== undefined && Main.Instance.TournamentState.tournament.valid !== -1 ? 
                                        'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                            onClick = {() => Toggle(8)}
                        >
                            {Assets.Include + ' ' + Assets.Games.toLowerCase()}
                        </button>

                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.V &&
                        Main.Instance.TournamentState.tournament.valid !== undefined && Main.Instance.TournamentState.tournament.valid !== -1 ?
                            <button
                                onClick = {() => OpenGS(true)}
                                style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                className = {Main.Instance.TournamentState.V.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                            >
                                {Main.Instance.TournamentState.V.length === 0 ? Assets.ClickToIncludeGames :
                                Main.Instance.TournamentState.V.length === 1 ? Assets.ThereIs1IncludedGame + '.' :
                                Assets.ThereAre + ' ' + Main.Instance.TournamentState.V.length + ' ' + Assets.includedGames + '.'}
                            </button>
                        : void 0}

                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.V &&
                        Main.Instance.TournamentState.tournament.valid !== undefined && Main.Instance.TournamentState.tournament.valid !== -1 &&
                        Main.Instance.TournamentState.V.length > 0 ?
                        <div className = "hhh">{GetValidGamesNames(Main.Instance.TournamentState.V)}</div> : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{marginTop: '5px'}}>
                        <button
                            style = {{borderRadius: '0px', marginLeft: '60px', width: '107px'}}
                            title = {Assets.Toggle + ' ' + Assets.Exclude.toLowerCase() + ' ' + Assets.Games.toLowerCase()}
                            className = {Main.Instance.TournamentState.V && Main.Instance.TournamentState.tournament && 
                                        Main.Instance.TournamentState.tournament.invalid !== undefined && Main.Instance.TournamentState.tournament.invalid !== -1 ? 
                                        'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                            onClick = {() => Toggle(10)}
                        >
                            {Assets.Exclude + ' ' + Assets.Games.toLowerCase()}
                        </button>

                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.I &&
                        Main.Instance.TournamentState.tournament.invalid !== undefined && Main.Instance.TournamentState.tournament.invalid !== -1 ?
                            <button
                                onClick = {() => OpenGS(false)}
                                style = {{fontSize: '14px', fontWeight: 600, paddingTop:'4px', paddingBottom:'3px', marginTop: '0px', display: 'block'}}
                                className = {Main.Instance.TournamentState.I.length === 0 ? 'btn btn-sm btn-danger ml fl hh' : 'btn btn-sm btn-success ml fl hh'}
                            >
                                {Main.Instance.TournamentState.I.length === 0 ? Assets.ClickToExcludeGames :
                                Main.Instance.TournamentState.I.length === 1 ? Assets.ThereIs1ExcludedGame + '.' :
                                Assets.ThereAre + ' ' + Main.Instance.TournamentState.I.length + ' ' + Assets.excludedGames + '.'}
                            </button>
                        : void 0}

                        {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.I &&
                        Main.Instance.TournamentState.tournament.valid !== undefined && Main.Instance.TournamentState.tournament.valid !== -1 &&
                        Main.Instance.TournamentState.I.length > 0 ?
                        <div className = "hhh">{GetValidGamesNames(Main.Instance.TournamentState.I)}</div> : void 0}

                        <ButtonIcon
                            disabled = {!everythingNotOverlapping() || !checkAll()}
                            description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                            click = {() => handleInsertUpdate()}
                            ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div> : void 0}

{/* PRIZES */}
            {Main.Instance.TournamentState.modePrizes ?
            <div 
                className = {Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                        <button
                            title = {Assets.discardChanges} 
                            onClick = {() => gotoInsertMode()}
                            className = "btn btn-default btn-sm btn-anything"
                            style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                        >
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                    <span
                        title = {Assets.Prizes}
                        style = {{
                            // width:'13%',
                            width: '50px',
                            textAlign: 'center',
                            marginRight:'1%',
                            float: 'left',
                            display: 'block',
                            height: '30px',
                            fontSize: '16px',
                            paddingTop:'7px',
                        }}
                        className = "label label-default"
                    >   <i className = {Assets_.faAchievementClass} style = {{marginRight: '0px'}}/>
                    </span>
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.Prizes}
                        className = {Main.Instance.TournamentState.V && Main.Instance.TournamentState.tournament && 
                                     Main.Instance.TournamentState.tournament.prize !== undefined && Main.Instance.TournamentState.tournament.prize !== -1 ? 
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-danger btn-sm dbl fl ml0 mr0'}
                        onClick = {() => Toggle(9)}
                    >
                        {Assets.Prizes}
                    </button>

                    {Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.prize !== undefined && Main.Instance.TournamentState.tournament.prize !== -1 ?
                    getPrizes() : void 0}
                    </div>
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    {Main.Instance.TournamentState.tournament && 
                    Main.Instance.TournamentState.tournament.prize !== undefined && Main.Instance.TournamentState.tournament.prize !== -1 ?
                    getPrizesSelect() : void 0}
                    <ButtonIcon
                        disabled = {!everythingNotOverlapping() || !checkAll()}
                        description = {!Main.Instance.TournamentState.mode ? Assets.saveChanges : Assets.updateChanges}
                        click = {() => handleInsertUpdate()}
                        ClassName = {!Main.Instance.TournamentState.mode ? 'btn btn-primary btn-sm btn-plus fr mr0' : 'btn btn-warning btn-sm btn-plus fr mr0'}
                        Icon = {faSave as IconDefinition}
                        text = {Assets.Save}
                    />
                    </div>
                </div>
            </div> : void 0}
{/* RESULTS */}
            {Main.Instance.TournamentState.modeResults ?
            <div 
                className = {/*Main.Instance.TournamentState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb' :*/
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-dark mb'}
            >
                <div className = "register-input clearfix" style = {{position: 'relative'}}>
                    {/*Main.Instance.TournamentState.tournamentType === '1' ? getResults() :*/
                     Main.Instance.TournamentState.tournamentType === '2' ? GetResult() : void 0}
                
                    <button
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-anything"
                        style = {{position: 'absolute', top: '-17px', right: '-10px'}}
                    >
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>

                    <div className = "row admin-users-row clearfix">
                        <span
                            title = {Assets.Results}
                            style = {{
                                // width:'13%',
                                width: '50px',
                                textAlign: 'center',
                                marginRight:'1%',
                                float: 'left',
                                display: 'block',
                                height: '30px',
                                fontSize: '16px',
                                paddingTop:'7px',
                            }}
                            className = "label label-info"
                        >   <i className = {Assets_.faListOlClass} style = {{marginRight: '0px'}}/>
                        </span>
                        {Main.Instance.TournamentState.tournamentType === '1' ? getResults(Assets_.customCloseIcon) :
                        Main.Instance.TournamentState.title && Main.Instance.TournamentState.title.length > 0 ?
                        <span style = {{fontSize: '20px', fontWeight: 700}}>
                            {isLocationRaces() ? Assets.ResultsForTheRace + ' "' + Main.Instance.TournamentState.title + '"' : Assets.ResultsForTheTournament + ' "' + Main.Instance.TournamentState.title + '"'}
                        </span> :
                        <span style = {{fontSize: '20px', fontWeight: 700, color : '#aa0000'}}>
                            {isLocationRaces() ? Assets.NoResultsForTheRace + '!' : Assets.NoResultsForTheTournament + '!'}
                        </span>}
                    </div>
                    <div>
                    {Main.Instance.DatabaseDataState.tournamentLeaderboard && Main.Instance.DatabaseDataState.tournamentLeaderboard.length > 0 ?
                        <div className = "row admin-users-row clearfix">{GetResults()}</div>
                    : void 0}
                    </div>
                </div>
            </div> : void 0}      
        {IsBuildMyChance() ? void 0 :
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.TournamentState.pSize ? '' : 
                Main.Instance.TournamentState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
{/*
            <FormSelect 
                span = {true}
                value = {!Main.Instance.TournamentState.filter_active ? '0' : 
                        Main.Instance.TournamentState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {text => ApplyFilterActive(text)} 
            />
*/}
            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.TournamentState.count ? Main.Instance.TournamentState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.TournamentState.count ? Main.Instance.TournamentState.pNum * 
                Main.Instance.TournamentState.pSize + 1 : 0}{' - '}
                {Main.Instance.TournamentState.count ? 
                ((Main.Instance.TournamentState.pNum + 1) * 
                Main.Instance.TournamentState.pSize > Main.Instance.TournamentState.count ? 
                Main.Instance.TournamentState.count : 
                (Main.Instance.TournamentState.pNum + 1) * Main.Instance.TournamentState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.TournamentState.count ? 
                Main.Instance.TournamentState.pNum + 1 :0}{' / '}
                {Main.Instance.TournamentState.count ? 
                Math.trunc((Main.Instance.TournamentState.count - 1) /
                Main.Instance.TournamentState.pSize + 1) : 0}
            </span>
        </div> }
        {/*console.log (Main.Instance.DatabaseDataState.tournamentStream)*/}
        <h3>{isLocationRaces() ? Assets.ListOfRaces : Assets.tTournament}</h3>
        {Main.Instance.DatabaseDataState.tournamentStream !== null && Main.Instance.DatabaseDataState.tournamentStream !== undefined ? 
        <ReactTable
            data = {IsBuildMyChance() ? filterTournamentsData() : Main.Instance.DatabaseDataState.tournamentStream}
            columns = {[
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {
                    Header: isLocationRaces() ? Assets.Races : Assets.Tournaments,
                    headerClassName: 'risk-default',
                    columns: [
                        {width: 35, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                        {width: 200, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                        {minWidth: 150, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},
                        {width: 150, Header: Assets.LeaderboardType, accessor: 'leaderboardType', headerClassName: 'th-left', Cell: (row) => TableUtils.LeaderBoardTypeOddyzzey(+row.value)},
                        {width: 70, Header: Assets.Tourney, accessor: 'tournamentType', headerClassName: 'th-left'},
                        {width: 70, Header: Assets.Rounds, accessor: 'rounds', headerClassName: 'th-left'},
/*
                        {width: 30, Header: <FontAwesomeIcon icon = {faLightbulb} title = {Assets.Active}/>, accessor: 'active', headerClassName: 'th-center', Cell: row =>
                        <div style = {{textAlign: 'center'}}><FontAwesomeIcon style={{ color: row.value ? '#5cb85c' : '#d9534f' }} icon = {row.value ? faCheck : faTimes}/></div>},
*/
                        {width: 65, Header: Assets.Active, accessor: 'active', headerClassName: 'th-left', 
                        Cell: (row) => <span title = {row.value ? Assets.TournamentActive : Assets.TournamentInactive}>
                            <Switch
                                disabled = {true}
                                style = {{marginRight: '0px'}}
                                onClick = {() => {
                                    const tournamentStream_ = Object.assign ([], Main.Instance.DatabaseDataState.tournamentStream_)
                                    
                                    for (const item of tournamentStream_) {
                                        if (row.original.id === item.id) {
                                            item.active = !item.active
                                            // item.validGames =  JSON.stringify(item.validGames).replace('[','{').replace(']','}'),
                                            // item.invalidGames = JSON.stringify(item.invalidGames).replace('[','{').replace(']','}'),
                                            DbRoutes.updateTournament (item)
                                            break
                                        }
                                    }
                    
                                }}
                                on = {row.value}
                            />
                        </span>},
                    ]
                },
                {
                    Header: Assets.Validity + ' (' + Assets_.UTC + ')',
                    headerClassName: 'risk-default-light',
                    columns: [
                        {width: 30, Header: <i className = {Assets_.faCalendar}/>, accessor: 'tournament_current', headerClassName: 'th-left', Cell: (row) => TableUtils.TournamentCurrent(+row.value)},
                        {width: isLocationRaces() ? 100 : 150, Header: Assets.From, accessor: 'validFrom', headerClassName: 'th-left', Cell: (row) => IsBuildMyChance() && isLocationRaces() ? row.value.substring(0, 10) : row.value},
                        {width: isLocationRaces() ? 100 : 150, Header: Assets.To, accessor: 'validTo', headerClassName: 'th-left', Cell: (row) => IsBuildMyChance() && isLocationRaces() ? row.value.substring(0, 10) : row.value},
                    ]
                },
                {
                    // show: isLocationRaces(),
                    Header: Assets.Scheduled + ' ' + Assets.Races.toLowerCase(),
                    headerClassName: 'risk-default',
                    columns: [
                        {show: isLocationRaces(), width: 130, Header: Assets.Number + Assets._of_ + Assets.Races.toLowerCase(), accessor: 'raceDetails', headerClassName: 'th-left', Cell: row => <span style = {{fontSize: '14px'}} className = {row.value ? 'label label-info' : 'label label-danger'} >{row.value ? row.value.length + ' ' + Assets.Races.toLowerCase() : Assets.No + ' ' + Assets.Races.toLowerCase()}</span>}, 
                    ]
                },
                
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getTournamentsCount()}
        </div>
    </div>
    )
}

function filterTournamentsData() {
    const data: any[] = []
    // console.log(Main.Instance.DatabaseDataState.tournamentStream)
    for (const item of Main.Instance.DatabaseDataState.tournamentStream) {
        if (isLocationRaces()) {
            if (item.tournamentType === 'Races') {
               
                data.push(item)
            } 
        } else {
            if (item.tournamentType === 'Special') {
                data.push(item)
            }
        }
        
    }
    
    return data
}

function Toggle(Which: number) {
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)

    if (Which === 11 || Which === 12 || Which === 13 || Which === 14 || Which === 15 || Which === 16 || Which === 17) {
        if (Which === 11) {
            if (tournament.consecutiveWins === undefined || tournament.consecutiveWins === -1) { tournament.consecutiveWins = 0 } else { tournament.consecutiveWins = -1 }
        } else if (Which === 12) {
            if (tournament.consecutiveLosses === undefined || tournament.consecutiveLosses === -1) { tournament.consecutiveLosses = 0 } else { tournament.consecutiveLosses = -1 }
        } else if (Which === 13) {
            if (tournament.bigWin=== undefined || tournament.bigWin=== -1) { tournament.bigWin= 0 } else { tournament.bigWin= -1 }
        } else if (Which === 14) {
            if (tournament.megaWin === undefined || tournament.megaWin === -1) { tournament.megaWin = 0 } else { tournament.megaWin = -1 }
        } else if (Which === 15) {
            if (tournament.consecutiveWinsBooster === undefined || tournament.consecutiveWinsBooster === -1) { tournament.consecutiveWinsBooster = 0 } else { tournament.consecutiveWinsBooster = -1 }
        } else if (Which === 16) {
            if (tournament.bigWinsBooster === undefined || tournament.bigWinsBooster === -1) { tournament.bigWinsBooster = 0 } else { tournament.bigWinsBooster = -1 }
        } else if (Which === 17) {
            if (tournament.megaWinsBooster === undefined || tournament.megaWinsBooster === -1) { tournament.megaWinsBooster = 0 } else { tournament.megaWinsBooster = -1 }
        }
/*
        if (Which === 0) {
            if (tournament.bigWin === undefined || tournament.bigWin === -1) tournament.bigWin = 0
            else tournament.bigWin = -1
        } else if (Which === 1) {
            if (tournament.megaWin === undefined || tournament.megaWin === -1) tournament.megaWin = 0
            else tournament.megaWin = -1
        } else if (Which === 2) {
            if (tournament.consecutive === undefined || tournament.consecutive === -1) tournament.consecutive = 0
            else tournament.consecutive = -1
        }
*/        
    } else if (Which === 3 || Which === 4 || Which === 5 || Which === 6) {

        if (Which === 3) {
            if (tournament.optin === undefined || tournament.optin === -1) { tournament.optin = 0 } else { tournament.optin = -1 }
        } else if (Which === 4) {
            if (tournament.min === undefined || tournament.min === -1) { tournament.min = 0 } else { tournament.min = -1 }
        } else if (Which === 5) {
            if (tournament.max === undefined || tournament.max === -1) { tournament.max = 0 } else { tournament.max = -1 }
        } else if (Which === 6) {
            if (tournament.play === undefined || tournament.play === -1) { tournament.play = 0 } else if (tournament.play === 0) { tournament.play = 1 } else { tournament.play = -1 }
        }
/*
    } else if (Which === 7) {

        if (tournament.boost === undefined || tournament.boost === -1) tournament.boost = 0
        else tournament.boost = -1
*/
    } else if (Which === 8) {
        if (tournament.valid === undefined || tournament.valid === -1) {
            tournament.valid = 0; tournament.invalid = -1
        } else { tournament.valid = -1 }
    } else if (Which === 9) {
        if (tournament.prize === undefined || tournament.prize === -1) { tournament.prize = 0 } else { tournament.prize = -1 }
    } else if (Which === 10) {
        if (tournament.invalid === undefined || tournament.invalid === -1) {
            tournament.invalid = 0; tournament.valid = -1
        } else { tournament.invalid = -1 }
    } else if (Which === 18) {
        if (tournament.raceDetails === undefined || tournament.raceDetails === -1) { tournament.raceDetails = 0 } else { tournament.raceDetails = -1 }
    }
    Logic.Type.New (Main.Instance.TournamentState, {tournament})
}

function OptinCostChanged(optinCost: string) {
    const O = Object.assign ({}, Main.Instance.TournamentState.O)
    O.optinCost = +optinCost
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function MinLevelChanged(minLevel: string) {
    const O = Object.assign ({}, Main.Instance.TournamentState.O)
    if (O.minLevel === undefined) { O.minLevel = {level: 1} }
    O.minLevel.level = +minLevel
    if (O.minLevel.level > Main.Instance.DatabaseDataState.levelsAllStream.length) { O.minLevel.level = 1 } else if (O.minLevel.level === 0) { O.minLevel.level = Main.Instance.DatabaseDataState.levelsAllStream.length }
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function MinSubLevelChanged(minLevel: string) {
    const O = Object.assign ({}, Main.Instance.TournamentState.O)
    if (O.minLevel === undefined) { O.minLevel = {level: 1, sublevel: 1} }
    O.minLevel.sublevel = +minLevel
    if (O.minLevel.sublevel > Main.Instance.DatabaseDataState.levelsAllStream[O.minLevel.level - 1]['sublevels']) { O.minLevel.sublevel = 1 } else if (O.minLevel.sublevel === 0) { O.minLevel.sublevel = Main.Instance.DatabaseDataState.levelsAllStream[O.minLevel.level - 1]['sublevels'] }
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function MaxLevelChanged(maxLevel: string) {
    const O = Object.assign ({}, Main.Instance.TournamentState.O)
    if (O.maxLevel === undefined) { O.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length} }
    O.maxLevel.level = +maxLevel
    if (O.maxLevel.level > Main.Instance.DatabaseDataState.levelsAllStream.length) { O.maxLevel.level = 1 } else if (O.maxLevel.level === 0) { O.maxLevel.level = Main.Instance.DatabaseDataState.levelsAllStream.length }
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function MaxSubLevelChanged(maxLevel: string) {
    const O = Object.assign ({}, Main.Instance.TournamentState.O)
    if (O.maxLevel === undefined) {
        O.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length, sublevel: Main.Instance.DatabaseDataState.levelsAllStream[O.maxLevel.level - 1]['sublevels']}
    }
    O.maxLevel.sublevel = +maxLevel
    if (O.maxLevel.sublevel > Main.Instance.DatabaseDataState.levelsAllStream[O.maxLevel.level - 1]['sublevels']) { O.maxLevel.sublevel = 1 } else if (O.maxLevel.sublevel === 0) { O.maxLevel.sublevel = Main.Instance.DatabaseDataState.levelsAllStream[O.maxLevel.level - 1]['sublevels'] }
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function WinChanged(win: string) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    M.win = +win
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function getBigWins(): JSX.Element[] {
    const bw: JSX.Element[] = []

    bw.push(
        <div className = "fl" style = {{position: 'relative'}}>
        <span style = {{position: 'absolute', top: '0px', right: '20px', color: '#fff', fontSize: '20px', fontWeight: 700}}>x</span>
        <FormInputNumber
            description = {Assets.EditBigWinMinimalMultiplier}
            ClassName = "h30 w80px pr0 indigo-selected"
            value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWin&& 
                    Main.Instance.TournamentState.M.bigWin.minimalMultiplier ? 
                    Main.Instance.TournamentState.M.bigWin.minimalMultiplier.toString() : '0'}
            type = "number"
            min = "0"
            onChange = {(text) => MinimalMultipliersChanged(text, 5)}
        /></div>)
    
    bw.push(
    <button
        title = {Assets.BigWins}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.bigWin=== 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 5)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>)

    if (Main.Instance.TournamentState.M.bigWin&& Main.Instance.TournamentState.M.bigWin.consecutiveWins) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.bigWin.consecutiveWins.length; i++) {
        bw.push (
            Main.Instance.TournamentState.tournament.bigWin=== i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditBigWinConsecutiveCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWin&& 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins && Main.Instance.TournamentState.M.bigWin.consecutiveWins[i] && 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].count ? 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 5)}
                />

                <FormInputNumber
                    description = {Assets.EditBigWinPoints}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWin&& 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins && Main.Instance.TournamentState.M.bigWin.consecutiveWins[i] && 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].points ? 
                            Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].points.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => PointsChanged(text, i, 5)}
                />
            </div>
            :
            <button
                title = {Assets.SelectBigWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 5)}
            >
                {Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].count}<small>{' cons.'}</small>
                {'/' + Main.Instance.TournamentState.M.bigWin.consecutiveWins[i].points}<small>{' ' + Assets.Pts.toLowerCase()}</small>
            </button>,
        )
    }
    }
    bw.push (
        <button
            title = {Assets.AddBigWinsEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(5)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.bigWin && 
        Main.Instance.TournamentState.tournament.bigWin > 0) {
        bw.push (
            <button
                title = {Assets.RemoveBigWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(5)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return bw
}

function getMegaWins(): JSX.Element[] {
    const mw: JSX.Element[] = []

    mw.push(
        <div className = "fl" style = {{position: 'relative'}}>
        <span style = {{position: 'absolute', top: '0px', right: '20px', color: '#fff', fontSize: '20px', fontWeight: 700}}>x</span>
        <FormInputNumber
            key = "-2"
            description = {Assets.EditMegaWinMinimalMultiplier}
            ClassName = "h30 w80px pr0 indigo-selected"
            value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWin && 
                    Main.Instance.TournamentState.M.megaWin.minimalMultiplier ? 
                    Main.Instance.TournamentState.M.megaWin.minimalMultiplier.toString() : '0'}
            type = "number"
            min = "0"
            onChange = {(text) => MinimalMultipliersChanged(text, 6)}
        /></div>)
    
    mw.push(
    <button
        title = {Assets.MegaWins}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.megaWin === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 6)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )

    if (Main.Instance.TournamentState.M.megaWin && Main.Instance.TournamentState.M.megaWin.consecutiveWins) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.megaWin.consecutiveWins.length; i++) {
        mw.push (
            Main.Instance.TournamentState.tournament.megaWin === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditMegaWinConsecutiveCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWin && 
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins && Main.Instance.TournamentState.M.megaWin.consecutiveWins[i] && 
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].count ?
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 6)}
                />

                <FormInputNumber
                    description = {Assets.EditMegaWinPoints}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWin && 
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins && Main.Instance.TournamentState.M.megaWin.consecutiveWins[i] &&
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].points ?
                            Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].points.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => PointsChanged(text, i, 6)}
                />
            </div>
            :
            <button
                title = {Assets.SelectMegaWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 6)}
            >
                {Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].count}<small>{' cons.'}</small>
                {'/' + Main.Instance.TournamentState.M.megaWin.consecutiveWins[i].points}<small>{' ' + Assets.Pts.toLowerCase()}</small>
            </button>,
        )
    }
    }
    mw.push (
        <button
            title = {Assets.AddMegaWinsEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(6)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.megaWin && 
        Main.Instance.TournamentState.tournament.megaWin > 0) {
        mw.push (
            <button
                title = {Assets.RemoveMegaWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(6)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return mw
}


function getConsecutiveWins(): JSX.Element[] {
    const cw: JSX.Element[] = []
    cw.push(
    <button
        title = {Assets.ConsecutiveWins}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.consecutiveWins === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 3)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveWins) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.consecutiveWins.length; i++) {
        cw.push (
            Main.Instance.TournamentState.tournament.consecutiveWins === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditConsecutiveWinCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveWins && 
                            Main.Instance.TournamentState.M.consecutiveWins[i] && Main.Instance.TournamentState.M.consecutiveWins[i].count ? 
                            Main.Instance.TournamentState.M.consecutiveWins[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 3)}
                />
                <FormInputNumber
                    description = {Assets.EditConsecutiveWinPoints}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveWins && 
                            Main.Instance.TournamentState.M.consecutiveWins[i] && Main.Instance.TournamentState.M.consecutiveWins[i].points ? 
                            Main.Instance.TournamentState.M.consecutiveWins[i].points.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => PointsChanged(text, i, 3)}
                />

            </div>
            :
            <button
                title = {Assets.SelectConsecutiveWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 3)}
            >
                {Main.Instance.TournamentState.M.consecutiveWins[i].count}<small>{' ' + Assets.Won.toLowerCase()}</small>{'/' + Main.Instance.TournamentState.M.consecutiveWins[i].points}<small>{' ' + Assets.Pts.toLowerCase()}</small>
            </button>,
        )
    }
    }
    cw.push (
        <button
            title = {Assets.AddConsecutiveWinsEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(3)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveWins && 
        Main.Instance.TournamentState.tournament.consecutiveWins > 0) {
        cw.push (
            <button
                title = {Assets.RemoveConsecutiveWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(3)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return cw
}

function getBigWinsBooster(): JSX.Element[] {
    const bwb: JSX.Element[] = []
    bwb.push(
        <div className = "fl" style = {{position: 'relative'}}>
        <span style = {{position: 'absolute', top: '0px', right: '20px', color: '#fff', fontSize: '20px', fontWeight: 700}}>x</span>
        <FormInputNumber
            description = {Assets.EditBigWinBoosterMinimalMultiplier}
            ClassName = "h30 w80px pr0 indigo-selected"
            value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWinsBooster && 
                    Main.Instance.TournamentState.M.bigWinsBooster.minimalMultiplier ? 
                    Main.Instance.TournamentState.M.bigWinsBooster.minimalMultiplier.toString() : '0'}
            type = "number"
            min = "0"
            onChange = {(text) => MinimalMultipliersChanged(text, 8)}
        /></div>)

    bwb.push(
    <button
        title = {Assets.BigWinsBooster}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.bigWinsBooster === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 8)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )
    if (Main.Instance.TournamentState.M.bigWinsBooster && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins.length; i++) {
        bwb.push (
            Main.Instance.TournamentState.tournament.bigWinsBooster === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditBigWinsBoosterCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWinsBooster && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins &&
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].count ? 
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 8)}
                />
                <FormInputNumber
                    description = {Assets.EditBigWinsBoosterDuration}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWinsBooster && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins &&
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.duration ?
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.duration.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterDurationChanged(text, i, 8)}
                />

                <FormInputNumber
                    description = {Assets.EditBigWinsBoosterPointsMultiplier}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWinsBooster && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins && 
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.pointsMultiplier ? 
                            Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.pointsMultiplier.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterPointsMultiplierChanged(text, i, 8)}
                />

            </div>
            :
            <button
                title = {Assets.SelectBigWinsBoosterEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 8)}
            >
                {Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].count}<small>{' ' + Assets.Won.toLowerCase()}</small>
                {'/' + Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.duration}<small>{' s'}</small>
                {'/' + Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins[i].booster.pointsMultiplier}<small>{' x'}</small>
            </button>,
        )
    }
    }
    bwb.push (
        <button
            title = {Assets.AddBigWinsBoosterEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(8)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.bigWinsBooster && Main.Instance.TournamentState.M.bigWinsBooster.consecutiveWins &&
        Main.Instance.TournamentState.tournament.bigWinsBooster > 0) {
        bwb.push (
            <button
                title = {Assets.RemoveBigWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(8)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return bwb
}

function getMegaWinsBooster(): JSX.Element[] {
    const mwb: JSX.Element[] = []
    mwb.push(
        <div className = "fl" style = {{position: 'relative'}}>
        <span style = {{position: 'absolute', top: '0px', right: '20px', color: '#fff', fontSize: '20px', fontWeight: 700}}>x</span>
        <FormInputNumber
            description = {Assets.EditMegaWinBoosterMinimalMultiplier}
            ClassName = "h30 w80px pr0 indigo-selected"
            value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWinsBooster && 
                    Main.Instance.TournamentState.M.megaWinsBooster.minimalMultiplier ? 
                    Main.Instance.TournamentState.M.megaWinsBooster.minimalMultiplier.toString() : '0'}
            type = "number"
            min = "0"
            onChange = {(text) => MinimalMultipliersChanged(text, 9)}
        /></div>)

    mwb.push(
    <button
        title = {Assets.MegaWinsBooster}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.megaWinsBooster === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 9)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )
    if (Main.Instance.TournamentState.M.megaWinsBooster && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins.length; i++) {
        mwb.push (
            Main.Instance.TournamentState.tournament.megaWinsBooster === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditMegaWinsBoosterCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWinsBooster && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins &&
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].count ? 
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 9)}
                />
                <FormInputNumber
                    description = {Assets.EditMegaWinsBoosterDuration}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWinsBooster && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins &&
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.duration ?
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.duration.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterDurationChanged(text, i, 9)}
                />

                <FormInputNumber
                    description = {Assets.EditMegaWinsBoosterPointsMultiplier}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWinsBooster && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins && 
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i] && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.pointsMultiplier ? 
                            Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.pointsMultiplier.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterPointsMultiplierChanged(text, i, 9)}
                />

            </div>
            :
            <button
                title = {Assets.SelectMegaWinsBoosterEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 9)}
            >
                {Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].count}<small>{' ' + Assets.Won.toLowerCase()}</small>
                {'/' + Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.duration}<small>{' s'}</small>
                {'/' + Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins[i].booster.pointsMultiplier}<small>{' x'}</small>
            </button>,
        )
    }
    }
    mwb.push (
        <button
            title = {Assets.AddMegaWinsBoosterEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(9)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.megaWinsBooster && Main.Instance.TournamentState.M.megaWinsBooster.consecutiveWins &&
        Main.Instance.TournamentState.tournament.megaWinsBooster > 0) {
        mwb.push (
            <button
                title = {Assets.RemoveMegaWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(9)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return mwb
}

function getConsecutiveWinsBooster(): JSX.Element[] {
    const cwb: JSX.Element[] = []
    cwb.push(
    <button
        title = {Assets.ConsecutiveWinsBooster}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.consecutiveWinsBooster === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 7)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveWinsBooster) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.consecutiveWinsBooster.length; i++) {
        cwb.push (
            Main.Instance.TournamentState.tournament.consecutiveWinsBooster === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditConsecutiveWinsBoosterCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveWinsBooster && 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i] && Main.Instance.TournamentState.M.consecutiveWinsBooster[i].count ? 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 7)}
                />
                <FormInputNumber
                    description = {Assets.EditConsecutiveWinsBoosterDuration}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveWinsBooster && 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i] && Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.duration ? 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.duration.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterDurationChanged(text, i, 7)}
                />

                <FormInputNumber
                    description = {Assets.EditConsecutiveWinsBoosterPointsMultiplier}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveWinsBooster && 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i] && Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.pointsMultiplier ? 
                            Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.pointsMultiplier.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => BoosterPointsMultiplierChanged(text, i, 7)}
                />

            </div>
            :
            <button
                title = {Assets.SelectConsecutiveWinsBoosterEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 7)}
            >
                {Main.Instance.TournamentState.M.consecutiveWinsBooster[i].count}<small>{' ' + Assets.Won.toLowerCase()}</small>
                {'/' + Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.duration}<small>{' s'}</small>
                {'/' + Main.Instance.TournamentState.M.consecutiveWinsBooster[i].booster.pointsMultiplier}<small>{' x'}</small>
            </button>,
        )
    }
    }
    cwb.push (
        <button
            title = {Assets.AddConsecutiveWinsEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(7)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveWinsBooster && 
        Main.Instance.TournamentState.tournament.consecutiveWinsBooster > 0) {
        cwb.push (
            <button
                title = {Assets.RemoveConsecutiveWinsEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(7)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return cwb
}


function getConsecutiveLosses(): JSX.Element[] {
    const cl: JSX.Element[] = []
    cl.push(
    <button
        title = {Assets.ConsecutiveLosses}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.consecutiveLosses === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 4)}
    >
        <i className = {Assets_.faDatabaseButtonClass}/>
    </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveLosses) {
    for (let i: number = 0; i < Main.Instance.TournamentState.M.consecutiveLosses.length; i++) {
        cl.push (
            Main.Instance.TournamentState.tournament.consecutiveLosses === i + 1 ?
            <div className = "fl">
                <FormInputNumber
                    description = {Assets.EditConsecutiveLossesCount}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveLosses && 
                            Main.Instance.TournamentState.M.consecutiveLosses[i] && Main.Instance.TournamentState.M.consecutiveLosses[i].count ? 
                            Main.Instance.TournamentState.M.consecutiveLosses[i].count.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => CountChanged(text, i, 4)}
                />
                <FormInputNumber
                    description = {Assets.EditConsecutiveLossesPoints}
                    ClassName = "h30 w50px pr0 primary-selected"
                    value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveLosses && 
                            Main.Instance.TournamentState.M.consecutiveLosses[i] && Main.Instance.TournamentState.M.consecutiveLosses[i].points ? 
                            Main.Instance.TournamentState.M.consecutiveLosses[i].points.toString() : '0'}
                    type = "number"
                    min = "0"
                    onChange = {(text) => PointsChanged(text, i, 4)}
                />

            </div>
            :
            <button
                title = {Assets.SelectConsecutiveLossesEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 4px', textAlign: 'center', width: 'auto'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 4)}
            >
                {Main.Instance.TournamentState.M.consecutiveLosses[i].count}<small>{' lost'}</small>{'/' + Main.Instance.TournamentState.M.consecutiveLosses[i].points}<small>{' ' + Assets.Pts.toLowerCase()}</small>
            </button>,
        )
    }
    }
    cl.push (
        <button
            title = {Assets.AddConsecutiveLossesEntry}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(4)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (Main.Instance.TournamentState.M.consecutiveLosses && 
        Main.Instance.TournamentState.tournament.consecutiveLosses > 0) {
        cl.push (
            <button
                title = {Assets.RemoveConsecutiveLossesEntry}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(4)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>,
        )
    }
    return cl
}


/*
function MinWinChanged(minimalWin: string, Which: number) {
    let M = Object.assign ({}, Main.Instance.TournamentState.M)
    if (Which === 0) M.bigWinMultipliers.minimalWin = +minimalWin
    else if (Which === 1) M.megaWinMultipliers.minimalWin = +minimalWin
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function getBigWinMultipliers(): JSX.Element[] {
    let bigWinMultipliers: JSX.Element[] = []
    bigWinMultipliers.push(
    <button
        title = {'Big Win Multipliers'}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.bigWin === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 0)}
    >
        <i className = {Assets_.faSelectClass}/>
    </button>
    )
    if (Main.Instance.TournamentState.M.bigWinMultipliers && Main.Instance.TournamentState.M.bigWinMultipliers.multipliers)
    for (let i: number = 0; i < Main.Instance.TournamentState.M.bigWinMultipliers.multipliers.length; i++) {
        bigWinMultipliers.push (
            Main.Instance.TournamentState.tournament.bigWin === i + 1 ?
            <div className = "fl">
            <FormInputNumber
                description = "Edit Big win multiplier"
                ClassName = "h30 w50px pr0 primary-selected"
                value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.bigWinMultipliers && 
                        Main.Instance.TournamentState.M.bigWinMultipliers.multipliers ? 
                        Main.Instance.TournamentState.M.bigWinMultipliers.multipliers[i].toString() : '0'}
                type = "number"
                min = "0"
                onChange = {text => MultiplierChanged(text, i, 0)}
            />
            </div>
            :
            <button
                title = {'Select Big Win Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 0)}
            >
                {Main.Instance.TournamentState.M.bigWinMultipliers.multipliers[i]}<small>x</small>
            </button>
        )
    }
    bigWinMultipliers.push (
        <button
            title = {'Add Big Win Multiplier'}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(0)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>
    )
    if (Main.Instance.TournamentState.M.bigWinMultipliers && Main.Instance.TournamentState.M.bigWinMultipliers.multipliers && 
        Main.Instance.TournamentState.tournament.bigWin > 0)
        bigWinMultipliers.push (
            <button
                title = {'Remove Big Win Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(0)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>
        )
    return bigWinMultipliers
}

function getMegaWinMultipliers(): JSX.Element[] {
    let megaWinMultipliers: JSX.Element[] = []
    megaWinMultipliers.push(
    <button
        title = {'Mega Win Multipliers'}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.megaWin === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 1)}
    >
        <i className = {Assets_.faSelectClass}/>
    </button>
    )
    if (Main.Instance.TournamentState.M.megaWinMultipliers && Main.Instance.TournamentState.M.megaWinMultipliers.multipliers)
    for (let i: number = 0; i < Main.Instance.TournamentState.M.megaWinMultipliers.multipliers.length; i++) {
        megaWinMultipliers.push (
            Main.Instance.TournamentState.tournament.megaWin === i + 1 ?
            <div className = "fl">
            <FormInputNumber
                description = "Edit Mega win multiplier"
                ClassName = "h30 w50px pr0 primary-selected"
                value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.megaWinMultipliers &&
                        Main.Instance.TournamentState.M.megaWinMultipliers.multipliers ? 
                        Main.Instance.TournamentState.M.megaWinMultipliers.multipliers[i].toString() : '0'}
                type = "number"
                min = "0"
                onChange = {text => MultiplierChanged(text, i, 1)}
            />
            </div>
            :
            <button
                title = {'Select Mega Win Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 1)}
            >
                {Main.Instance.TournamentState.M.megaWinMultipliers.multipliers[i]}<small>x</small>
            </button>
        )
    }
    megaWinMultipliers.push (
        <button
            title = {'Add Mega Win Multiplier'}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(1)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>
    )
    if (Main.Instance.TournamentState.M.megaWinMultipliers && Main.Instance.TournamentState.M.megaWinMultipliers.multipliers && 
        Main.Instance.TournamentState.tournament.megaWin > 0)
        megaWinMultipliers.push (
            <button
                title = {'Remove Mega Win Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(1)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>
        )
    return megaWinMultipliers
}

function getConsecutiveMultipliers(): JSX.Element[] {
    let consecutiveMultipliers: JSX.Element[] = []
    consecutiveMultipliers.push(
    <button
        title = {'Consecutive Multipliers'}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.consecutive === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectMultiplier(0, 2)}
    >
        <i className = {Assets_.faSelectClass}/>
    </button>
    )
    if (Main.Instance.TournamentState.M.consecutiveMultipliers)
    for (let i: number = 0; i < Main.Instance.TournamentState.M.consecutiveMultipliers.length; i++) {
        consecutiveMultipliers.push (
            Main.Instance.TournamentState.tournament.consecutive === i + 1 ?
            <div className = "fl">
            <FormInputNumber
                description = "Edit Consecutive multiplier"
                ClassName = "h30 w50px pr0 primary-selected"
                value = {Main.Instance.TournamentState.M && Main.Instance.TournamentState.M.consecutiveMultipliers ? 
                        Main.Instance.TournamentState.M.consecutiveMultipliers[i].toString() : '0'}
                type = "number"
                min = "0"
                onChange = {text => MultiplierChanged(text, i, 2)}
            />
            </div>
            :
            <button
                title = {'Select Consecutive Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectMultiplier(i + 1, 2)}
            >
                {Main.Instance.TournamentState.M.consecutiveMultipliers[i]}<small>x</small>
            </button>
        )
    }
    consecutiveMultipliers.push (
        <button
            title = {'Add Consecutive Multiplier'}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddMultiplier(2)}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>
    )
    if (Main.Instance.TournamentState.M.consecutiveMultipliers && Main.Instance.TournamentState.tournament.consecutive > 0)
        consecutiveMultipliers.push (
            <button
                title = {'Remove Consecutive Multiplier'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemoveMultiplier(2)}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>
        )

    return consecutiveMultipliers
}

function MultiplierChanged(text: string, which: number, Which: number) {
    let M = Object.assign ({}, Main.Instance.TournamentState.M)
    if (Which === 0) M.bigWinMultipliers.multipliers[which] = +text
    else if (Which === 1) M.megaWinMultipliers.multipliers[which] = +text
    else if (Which === 2) M.consecutiveMultipliers[which] = +text
    Logic.Type.New(Main.Instance.TournamentState, {M})
}
*/

function CountChanged(text: string, which: number, Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    if (Which === 3) { M.consecutiveWins[which].count = +text } else if (Which === 4) { M.consecutiveLosses[which].count = +text } else if (Which === 5) { M.bigWin.consecutiveWins[which].count = +text } else if (Which === 6) { M.megaWin.consecutiveWins[which].count = +text } else if (Which === 7) { M.consecutiveWinsBooster[which].count = +text } else if (Which === 8) { M.bigWinsBooster.consecutiveWins[which].count = +text } else if (Which === 9) { M.megaWinsBooster.consecutiveWins[which].count = +text }
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function BoosterDurationChanged(text: string, which: number, Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    
    if (Which === 7) {
        if (M.consecutiveWinsBooster[which].booster === undefined) { M.consecutiveWinsBooster[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.consecutiveWinsBooster[which].booster.duration = +text
    } else if (Which === 8) {
        if (M.bigWinsBooster.consecutiveWins[which].booster === undefined) { M.bigWinsBooster.consecutiveWins[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.bigWinsBooster.consecutiveWins[which].booster.duration = +text
    } else if (Which === 9) {
        if (M.megaWinsBooster.consecutiveWins[which].booster === undefined) { M.megaWinsBooster.consecutiveWins[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.megaWinsBooster.consecutiveWins[which].booster.duration = +text
    }

    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function BoosterPointsMultiplierChanged(text: string, which: number, Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    
    if (Which === 7) {
        if (M.consecutiveWinsBooster[which].booster === undefined) { M.consecutiveWinsBooster[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.consecutiveWinsBooster[which].booster.pointsMultiplier = +text
    } else if (Which === 8) {
        if (M.bigWinsBooster.consecutiveWins[which].booster === undefined) { M.bigWinsBooster.consecutiveWins[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.bigWinsBooster.consecutiveWins[which].booster.pointsMultiplier = +text
    } else if (Which === 9) {
        if (M.megaWinsBooster.consecutiveWins[which].booster === undefined) { M.megaWinsBooster.consecutiveWins[which].booster = {duration: 0, pointsMultiplier: 0} }
        M.megaWinsBooster.consecutiveWins[which].booster.pointsMultiplier = +text
    }
    Logic.Type.New(Main.Instance.TournamentState, {M})
}


function PointsChanged(text: string, which: number, Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    if (Which === 3) { M.consecutiveWins[which].points = +text } else if (Which === 4) { M.consecutiveLosses[which].points = +text } else if (Which === 5) { M.bigWin.consecutiveWins[which].points = +text } else if (Which === 6) { M.megaWin.consecutiveWins[which].points = +text }
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function MinimalMultipliersChanged(text: string, Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    if (Which === 5) {
        if (M.bigWin=== undefined) { M.bigWin= {} }
        M.bigWin.minimalMultiplier = +text
    } else if (Which === 6) {
        if (M.megaWin === undefined) { M.megaWin = {} }
        M.megaWin.minimalMultiplier = +text
    } else if (Which === 8) {
        if (M.bigWinsBooster === undefined) { M.bigWinsBooster = {} }
        M.bigWinsBooster.minimalMultiplier = +text
    } else if (Which === 9) {
        if (M.megaWinsBooster === undefined) { M.megaWinsBooster = {} }
        M.megaWinsBooster.minimalMultiplier = +text
    }
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function AddMultiplier(Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
/*
    if (Which === 0) {
        if (M.bigWinMultipliers === undefined) M.bigWinMultipliers = {}
        if (M.bigWinMultipliers.multipliers === undefined) M.bigWinMultipliers.multipliers = []
        M.bigWinMultipliers.multipliers.push(0)
    } else if (Which === 1) {
        if (M.megaWinMultipliers === undefined) M.megaWinMultipliers = {}
        if (M.megaWinMultipliers.multipliers === undefined) M.megaWinMultipliers.multipliers = []        
        M.megaWinMultipliers.multipliers.push(0)
    } else if (Which === 2) {
        if (M.consecutiveMultipliers === undefined) M.consecutiveMultipliers = []
        M.consecutiveMultipliers.push(0)
    } else 
*/  
    if (Which === 3) {
        if (M.consecutiveWins === undefined) { M.consecutiveWins = [] }
        M.consecutiveWins.push({count: 0, points: 0})
    } else if (Which === 4) {
        if (M.consecutiveLosses === undefined) { M.consecutiveLosses = [] }
        M.consecutiveLosses.push({count: 0, points: 0})
    } else if (Which === 5) {
        if (M.bigWin=== undefined) { M.bigWin= {} }
        if (M.bigWin.consecutiveWins === undefined) { M.bigWin.consecutiveWins = [] }
        M.bigWin.consecutiveWins.push({count: 0, points: 0})
    } else if (Which === 6) {
        if (M.megaWin === undefined) { M.megaWin = {} }
        if (M.megaWin.consecutiveWins === undefined) { M.megaWin.consecutiveWins = [] }
        M.megaWin.consecutiveWins.push({count: 0, points: 0})
    } else if (Which === 7) {
        if (M.consecutiveWinsBooster === undefined) { M.consecutiveWinsBooster = [] }
        M.consecutiveWinsBooster.push({count: 0, booster: {duration: 0, pointsMultiplier: 0}})
    } else if (Which === 8) {
        if (M.bigWinsBooster === undefined) { M.bigWinsBooster = {} }
        if (M.bigWinsBooster.consecutiveWins === undefined) { M.bigWinsBooster.consecutiveWins = [] }
        M.bigWinsBooster.consecutiveWins.push({count: 0, booster: {duration: 0, pointsMultiplier: 0}})
    } else if (Which === 9) {
        if (M.megaWinsBooster === undefined) { M.megaWinsBooster = {} }
        if (M.megaWinsBooster.consecutiveWins === undefined) { M.megaWinsBooster.consecutiveWins = [] }
        M.megaWinsBooster.consecutiveWins.push({count: 0, booster: {duration: 0, pointsMultiplier: 0}})
    }
    Logic.Type.New(Main.Instance.TournamentState, {M})
}

function RemoveMultiplier(Which: number) {
    const M = Object.assign ({}, Main.Instance.TournamentState.M)
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
/*    
    if (Which === 0) {
        M.bigWinMultipliers.multipliers.splice(tournament.bigWin - 1, 1)
        tournament.bigWin = 0
    } else if (Which === 1) {
        M.megaWinMultipliers.multipliers.splice(tournament.megaWin - 1, 1)
        tournament.megaWin = 0
    } else if (Which === 2) {
        M.consecutiveMultipliers.splice(tournament.consecutive - 1, 1)
        tournament.consecutive = 0
    } else*/ 
    
    if (Which === 3) {
        M.consecutiveWins.splice(tournament.consecutiveWins - 1, 1)
        tournament.consecutiveWins = 0
    } else if (Which === 4) {
        M.consecutiveLosses.splice(tournament.consecutiveLosses - 1, 1)
        tournament.consecutiveLosses = 0
    } else if (Which === 5) {
        M.bigWin.splice(tournament.bigWin- 1, 1)
        tournament.bigWin= 0
    } else if (Which === 6) {
        M.megaWin.splice(tournament.megaWin - 1, 1)
        tournament.megaWin = 0
    } else if (Which === 7) {
        M.consecutiveWinsBooster.splice(tournament.consecutiveWinsBooster - 1, 1)
        tournament.consecutiveWinsBooster = 0
    } else if (Which === 8) {
        M.bigWinsBooster.consecutiveWins.splice(tournament.bigWinsBooster - 1, 1)
        tournament.bigWinsBooster = 0
    } else if (Which === 9) {
        M.megaWinsBooster.consecutiveWins.splice(tournament.megaWinsBooster - 1, 1)
        tournament.megaWinsBooster = 0
    }
    Logic.Type.New(Main.Instance.TournamentState, {M, tournament})
}

function SelectMultiplier(which: number, Which: number) {
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
/*
    if (Which === 0) tournament.bigWin = which
    else if (Which === 1) tournament.megaWin = which
    else if (Which === 2) tournament.consecutive = which
    else */ 
    if (Which === 3) { tournament.consecutiveWins = which } else if (Which === 4) { tournament.consecutiveLosses = which } else if (Which === 5) { tournament.bigWin= which } else if (Which === 6) { tournament.megaWin = which } else if (Which === 7) { tournament.consecutiveWinsBooster = which } else if (Which === 8) { tournament.bigWinsBooster = which } else if (Which === 9) { tournament.megaWinsBooster = which }
    Logic.Type.New(Main.Instance.TournamentState, {tournament})
}


/*
function PlayerChanged(text: string, which: number) {
    let O = Object.assign ({}, Main.Instance.TournamentState.O)
    O.players[which] = +text
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function AddPlayer() {
    let O = Object.assign ({}, Main.Instance.TournamentState.O)
    if (O.players === undefined) O.players = []
    O.players.push(0)
    Logic.Type.New(Main.Instance.TournamentState, {O})
}

function RemovePlayer() {
    let O = Object.assign ({}, Main.Instance.TournamentState.O)
    let tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    O.players.splice(tournament.play - 1, 1)
    tournament.play = 0
    Logic.Type.New(Main.Instance.TournamentState, {O, tournament})
}

function SelectPlayers(play: number) {
    let tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    tournament.play = play
    Logic.Type.New(Main.Instance.TournamentState, {tournament})
}
*/
/*
function AddBooster() {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    let tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    if (B === undefined) B = []
    B.push({duration: 0, minimalMultiplier: 1})
    // console.log (B)
    if (tournament.boost === undefined) tournament.boost = 1
    Logic.Type.New(Main.Instance.TournamentState, {B, tournament})
}

function RemoveBooster() {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    let tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    B.splice(tournament.boost - 1, 1)
    tournament.boost = 0
    Logic.Type.New(Main.Instance.TournamentState, {B, tournament})
}
*/
function AddPrizes() {
    let P = Object.assign ([], Main.Instance.TournamentState.P)
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    let lastRewarded: number = 0
    if (P === undefined) { P = [] } else if (P.length > 0) { lastRewarded = P[P.length - 1].lastRewarded }
    
    P.push({firstRewarded: lastRewarded + 1, lastRewarded: lastRewarded + 1, rewardId: 0, rewardId2: 0})
    if (tournament.valid === undefined) { tournament.valid = 1 }
    Logic.Type.New(Main.Instance.TournamentState, {P, tournament})
}

function AddRaces() {
    let RS = Object.assign ([], Main.Instance.TournamentState.RS)
    if (RS === undefined) { RS = [] }
    const tournament = Object.assign({}, Main.Instance.TournamentState.tournament)
    tournament.raceDetails = RS.length + 1

    RS.push({
        dayOfWeek: -1, 
        hour: -1, 
        minute: Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0, 
        durationMinutes: Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
    })
    // console.log(RS)
    let setDefaultMinutes: boolean
    const defMin: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0
    const defMinDur: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
    if (RS[RS.length - 1] && RS[RS.length - 1].minute === defMin) {
        setDefaultMinutes = true
    } else {
        setDefaultMinutes = false
    }

    let setDefaultDurationMinutes: boolean
    if (RS[RS.length - 1] && RS[RS.length - 1].durationMinutes === defMinDur) {
        setDefaultDurationMinutes = true
    } else {
        setDefaultDurationMinutes = false
    }

    Logic.Type.New(Main.Instance.TournamentState, {RS, tournament, setDefaultMinutes, setDefaultDurationMinutes})
}

function RemovePrize() {
    const P = Object.assign ([], Main.Instance.TournamentState.P)
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    P.splice(tournament.prize - 1, 1)
    tournament.prize = 0
    Logic.Type.New(Main.Instance.TournamentState, {P, tournament})
}

function RemoveRace() {
    const RS = Object.assign ([], Main.Instance.TournamentState.RS)
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    RS.splice(tournament.raceDetails - 1, 1)
    tournament.raceDetails = 0
    Logic.Type.New(Main.Instance.TournamentState, {RS, tournament})
}
/*
function SelectBoosters(boost: number) {
    let tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    tournament.boost = boost
    Logic.Type.New(Main.Instance.TournamentState, {tournament})
}
*/
function SelectPrizes(prize: number) {
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    tournament.prize = prize
    Logic.Type.New(Main.Instance.TournamentState, {tournament})
}

function SelectRaces(race: number) {
    const tournament = Object.assign ({}, Main.Instance.TournamentState.tournament)
    const RS = Object.assign ([], Main.Instance.TournamentState.RS)
    tournament.raceDetails = race
    let setDefaultMinutes: boolean
    const defMin: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0
    const defMinDur: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
    if (RS[race - 1] && RS[race - 1].minute === defMin) {
        setDefaultMinutes = true
    } else {
        setDefaultMinutes = false
    }

    let setDefaultDurationMinutes: boolean
    if (RS[race - 1] && RS[race - 1].durationMinutes === defMinDur) {
        setDefaultDurationMinutes = true
    } else {
        setDefaultDurationMinutes = false
    }
    
    Logic.Type.New(Main.Instance.TournamentState, {tournament, setDefaultMinutes, setDefaultDurationMinutes})
}

function isDayBlocked(date: moment.Moment, start: string, end: string): boolean {
    const block: string = date.format (Assets_.dateDisplayFormat)
    if (block >= start && block <= end) { return false }
    return true
}

function isDayHighlighted(date: moment.Moment, start: string, end: string): boolean {
    const block: string = date.format (Assets_.dateDisplayFormat)
    if (block === start || block === end) { return true }
    return false
}

function getResults(customCloseIcon: JSX.Element): JSX.Element[] {
    const results: JSX.Element[] = []
    const start: string = Main.Instance.TournamentState.from_date.format (Assets_.dateDisplayFormat)
    // console.log ('start: ' + start)
    const End: string = Main.Instance.TournamentState.to_date.format (Assets_.dateDisplayFormat)
    const endDate: moment.Moment = moment.default(End)
    if (Main.Instance.TournamentState.toTime === '00:00' || Main.Instance.TournamentState.toTime === '00:00:00') { endDate.subtract(1, 'day') }
    const end = endDate.format (Assets_.dateDisplayFormat)
    // console.log ('end: ' + end)
    results.push (
        <div key = "1" className = "fl mr" style = {{fontSize: '20px', fontWeight: 700/*, marginTop: '2px'*/}}>
            {Main.Instance.TournamentState.title && Main.Instance.TournamentState.title.length > 0 ?
            Main.Instance.CRMPointState.to_date === null || Main.Instance.CRMPointState.to_date === undefined ?
            Assets.PickADateToShowResultsForTheTournament + ' "' + Main.Instance.TournamentState.title + '"' :
            Assets.ResultsForTheTournament + ' "' + Main.Instance.TournamentState.title +  '"' :
            Main.Instance.CRMPointState.to_date === null || Main.Instance.CRMPointState.to_date === undefined ?
            Assets.PickADateToShowResultsForTheTournament : Assets.ResultsForTheTournament}
        </div>,
    )

    results.push(
        <div key = "1" className = "fl singledatepicker">
            <SingleDatePicker
                id = "date5"
                placeholder = {Assets.Date}
                date = {Main.Instance.CRMPointState.to_date}
                onDateChange = {(date) => {
                    Logic.Type.New(Main.Instance.CRMPointState, {to_date: date})
                    if (date !== null && date !== undefined && Main.Instance.TournamentState.modeLeaderboard === false) { 
                        DbRoutes.getDailyTournamentResults(+Main.Instance.TournamentState.id, date.format(Assets_.dateDisplayFormat))
                    }            
                }}
                focused = {Main.Instance.CRMPointState.focusedInput}
                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput: focused.focused})}
                showDefaultInputIcon = {true}
                showClearDate = {true}
                isOutsideRange = {() => false}
                displayFormat = {Assets_.dateDisplayFormatdmy}
                // customArrowIcon = {Assets_.customArrowIcon}
                isDayBlocked = {(date) => isDayBlocked(date, start, end)}
                isDayHighlighted = {(date) => isDayHighlighted(date, start, end)}
                customCloseIcon = {Assets_.customCloseIcon}
                numberOfMonths = {1}
            />
        </div>,        
    )
    return results
}

function GetResults(): JSX.Element {
    const results: JSX.Element[] = []
    let points: boolean = false
    let winPercentage: boolean = false
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.tournamentLeaderboard.length; i++) {
        const item = Main.Instance.DatabaseDataState.tournamentLeaderboard[i]
        if (i === 0) {
            if (item['points']) { points = true }
            if (item['winPercentage']) { winPercentage = true }
        }
        let reward: string = ''
        // if (i === 1) console.log(item)
        if (item['gotReward']) {
        for (const Item of Main.Instance.DatabaseDataState.rewardShopStreamAll) {
            // console.log (item['prize']['rewardId'])
            if (Item['id'] === item['prize']['rewardId']) {
                reward = Item['name']
                // console.log (Item)
                break
            }
        }
        }

        results.push (
            <tr key = {i.toString()} style = {item['gotReward'] ? {background: '#FFD700', fontWeight: 600} : {}}>
                <td><span>{item['position']}</span><sup>{post(item['position'])}</sup><span>{' place'}</span></td>
                <td>{item['player']}</td>
                {points ? <td>{item['points'] + ' ' + Assets.Pts.toLowerCase()}</td> : void 0}
                {winPercentage ? <td>{parseFloat(item['winPercentage']).toFixed(2) + ' %'}</td> : void 0}                
                <td>{item['roundsWager']}</td>
                <td>{item['roundsWin']}</td>
                <td>{item['gotReward'] ? <span>{reward}</span> : <span>&mdash;</span>}</td>
            </tr>,
        )
    }

    return (
        <table style = {{borderColor: '#fff', marginTop: '10px'}} className = "table fl">
            <thead>
                <tr style = {{background: '#000', color: '#fff'}}>
                    <th>{Assets.Position}</th>
                    <th>{Assets.Player}</th>
                    {points ? <th>{Assets.Points}</th> : void 0}
                    {winPercentage ? <th>{Assets.WinPercentage}</th> : void 0}
                    <th>{Assets.Rounds} ({Assets.Wager.toLowerCase()})</th>
                    <th>{Assets.Rounds} ({Assets.Win.toLowerCase()})</th>
                    <th>{Assets.Reward}</th>
                </tr>
            </thead>
            <tbody>
                {results}
            </tbody>
        </table>
    )
}



function GetResult() {
    if (Main.Instance.TournamentState.modeLeaderboard === false/* && Main.Instance.DatabaseDataState.tournamentLeaderboard === undefined*/) {
        DbRoutes.getTournamentResults(+Main.Instance.TournamentState.id)
    }
}

function getRaceSchedule(): JSX.Element[] {
    const races: JSX.Element[] = []
    races.push(
        <button
            title = {Assets.Schedule}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
            key = "-1"
            className = {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails === 0 ?
                        'btn btn-primary btn-sm mr0 ml fl' : 'btn btn-default btn-sm mr0 ml fl'}
            onClick = {() => SelectRaces(0)}
        >
            <FontAwesomeIcon icon = {faFlagCheckered}/>
        </button>
    )
    if (Main.Instance.TournamentState.RS && Main.Instance.TournamentState.tournament) {
        for (let i: number = 0; i < Main.Instance.TournamentState.RS.length; i++) {
            races.push (
                <button
                    title = {Assets.Select + ' ' + Assets.Schedule.toLowerCase()}
                    style = {{borderRadius: '0px', fontSize: '14px', padding: '4px 8px 3px', textAlign: 'center'}}
                    key = {i.toString()}
                    className = {Main.Instance.TournamentState.RS[i].dayOfWeek === -1 || Main.Instance.TournamentState.RS[i].hour === -1 ? 'btn btn-danger btn-sm mr0 ml0 fl' : Main.Instance.TournamentState.tournament.raceDetails === i + 1 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
                    onClick = {() => SelectRaces(i + 1)}
                >
                    {Main.Instance.TournamentState.RS[i].dayOfWeek !== -1 ? 
                    Main.Instance.TournamentState.RS[i].hour !== -1 && Main.Instance.TournamentState.RS[i].minute !== undefined ?
                    Assets.DaysInWeek[Main.Instance.TournamentState.RS[i].dayOfWeek].substring(0,3) + Assets._at_ + Main.Instance.TournamentState.RS[i].hour + ':' + (Main.Instance.TournamentState.RS[i].minute.toString().length === 1 ? '0' + Main.Instance.TournamentState.RS[i].minute : Main.Instance.TournamentState.RS[i].minute)
                    : Assets.DaysInWeek[Main.Instance.TournamentState.RS[i].dayOfWeek].substring(0,3)
                    : Assets.Race + ' ' + (i + 1)}
                </button>,
            )
            if (i + 1 === Main.Instance.TournamentState.tournament.raceDetails) {
                races.push (
                    <button
                        title = {Assets.Remove + Assets.Schedule.toLowerCase()}
                        style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                        key = "-3"
                        className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                        onClick = {() => RemoveRace()}
                    >
                        <i className = {Assets_.faTrashButtonClass}/>
                    </button>,
                )
            }
        }
    }
    {/*console.log (Main.Instance.TournamentState.tournament)*/}
    races.push (
        <button
            title = {Assets.Add + ' ' + Assets.Schedule.toLowerCase()}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = "btn btn-success btn-sm ml0 fl"
            onClick = {() => AddRaces()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    const overlapping: boolean = everythingNotOverlapping()
    races.push (
        <FontAwesomeIcon
            title = {overlapping ? Assets.RacesAreNotOverlapping : Assets.SomeRacesAreOverlapping}
            key = "-4"
            size = "2x"
            style={{ color: overlapping ? '#5cb85c' : '#d9534f' }}
            icon={overlapping ? faCheck : faTimes}
        />
    )
    return races
}

function everythingNotOverlapping(): boolean {
    if (Main.Instance.TournamentState.RS) {
        for (let i: number = 0; i < Main.Instance.TournamentState.RS.length; i++) {
            for (let j: number = 0; j < Main.Instance.TournamentState.RS.length; j++) {
                if (i === j) {continue}
                const t1 = Main.Instance.TournamentState.RS[i]
                const t2 = Main.Instance.TournamentState.RS[j]
                if (t1.dayOfWeek === t2.dayOfWeek) {
                    const start1 = t1.hour * 60 + t1.minute
                    const end1 = start1 + t1.durationMinutes
                    const start2 = t2.hour * 60 + t2.minute
                    if (start2 >= start1 && start2 < end1) {
                        return false
                    }
                }
            }
        }
    }
    return true
}

function RaceSort(RS: any[], setDefaultMinutes?: boolean) {
    const tournament = Object.assign({}, Main.Instance.TournamentState.tournament)
    const raceDetailsOld = Main.Instance.TournamentState.tournament.raceDetails - 1
    let raceDetailsNew: number = -1
    for (let i: number = 0; i < RS.length; i++) {
        if (i === raceDetailsOld) {
            continue
        } else if (RS[raceDetailsOld].dayOfWeek === -1 ||
            RS[raceDetailsOld].dayOfWeek >= RS[i].dayOfWeek /* && RS[i].dayOfWeek !== 0*/ ||
            RS[raceDetailsOld].dayOfWeek === 0
            ) {
            if (RS[raceDetailsOld].dayOfWeek > RS[i].dayOfWeek /* && RS[i].dayOfWeek !== 0*/ ||
                RS[raceDetailsOld].dayOfWeek === 0 && RS[i].dayOfWeek !== 0) {
                raceDetailsNew = i
            } else if (RS[raceDetailsOld].hour === -1 || RS[raceDetailsOld].hour >= RS[i].hour) {
                if (RS[raceDetailsOld].hour > RS[i].hour) {
                    raceDetailsNew = i
                } else if (RS[raceDetailsOld].minute === 0 || RS[raceDetailsOld].minute > (RS[i].minute !== undefined ? RS[i].minute : Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0)) {
                    raceDetailsNew = i
                }
            }
        }
    }
    // if (raceDetailsNew === -1) {raceDetailsNew = 0}

    // console.log ('*************************')
    // console.log(RS)
    // console.log ('raceDetailsNew: ', raceDetailsNew)
    // console.log ('raceDetailsOld: ', raceDetailsOld)
    const RS0 = []
    let pos: number = 0
    let Pos: boolean = false
    if (raceDetailsNew === -1) {
        RS0.push(RS[raceDetailsOld])
        Pos = true
    }
    for (let i: number = 0; i < RS.length; i++) {
        if (i === raceDetailsOld && raceDetailsNew !== raceDetailsOld) {
            continue
        }
        RS0.push(RS[i])
        if (!Pos) {pos++}
        if (i === raceDetailsNew && raceDetailsNew !== raceDetailsOld) {
            RS0.push(RS[raceDetailsOld])
            Pos = true
        }
    }
    // console.log ('pos ', pos)
    // console.log (RS0)
    tournament.raceDetails = pos + 1
    
    // console.log ('raceDetails: ', tournament.raceDetails)
    if (tournament.raceDetails > RS0.length) {
        tournament.raceDetails = RS0.length
    }
    if (setDefaultMinutes !== undefined) {
        Logic.Type.New (Main.Instance.TournamentState, {RS: RS0, tournament, setDefaultMinutes})
    } else {
        Logic.Type.New (Main.Instance.TournamentState, {RS: RS0, tournament})
    }
}

function checkAll(): boolean {
    if (Main.Instance.TournamentState.RS) {
        for (let i: number = 0; i < Main.Instance.TournamentState.RS.length; i++ ) {
            if (Main.Instance.TournamentState.RS[i].dayOfWeek === -1 || Main.Instance.TournamentState.RS[i].hour === -1) {
                return false
            }
        }
    }
    return true
}

function getRacesSelects(): JSX.Element[] {
    const races: JSX.Element[] = []
    if (Main.Instance.TournamentState.RS && Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.raceDetails > 0) {
        // console.log(Main.Instance.TournamentState.RS)
        // console.log(Main.Instance.TournamentState.tournament.raceDetails)
        const check: boolean = Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].dayOfWeek !== -1 && Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour !== -1
        races.push (
            <div className = "row admin-users-row">
                <span 
                    className = "dbl fl mr mt2" 
                    title = {check ? Assets.RaceEnteredCorrectly : Assets.RaceNotEnteredCorrectly + '. ' + Assets.Scheduled + ' ' + Assets.Races.toLowerCase() + ' ' + Assets.Require.toLowerCase() + ' "' + Assets.Day + Assets._of_ + Assets.Week.toLowerCase() + '"' + Assets._and_ + '"' + Assets.Hour + '"'}
                >
                    <FontAwesomeIcon 
                        size = "2x" 
                        style={{ color: check ? '#5cb85c' : '#d9534f' }} 
                        icon={check ? faCheck : faTimes}
                    />
                </span>

                <FormSelect
                    title = {Assets.Day + Assets._of_ + Assets.Week.toLowerCase()}
                    mt0 = {true}
                    value = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].dayOfWeek.toString()}
                    data = {getDayOfWeekOptions()}
                    ClassName = "form-control"
                    
                    onChange = {(text) => {
                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].dayOfWeek = +text
                        RaceSort(RS)
                    }}
                    icon = {Assets_.faCalendarClass}
                />
                <FormSelect
                    disabled = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].dayOfWeek === -1}
                    narrow = {true}
                    title = {Assets.Start + ' ' + Assets.Hour.toLowerCase()}
                    mt0 = {true}
                    value = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour !== undefined ? Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour.toString() : ''}
                    data = {getHourOptions()}
                    ClassName = "form-control"
                    onChange = {(text) => {
                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour = +text
                        RaceSort(RS)
                    }}
                    icon = {Assets_.faHourglassStartClass}
                />
                {/* <FormInputNumber
                    placeholder = {Assets.Hour}
                    description = {Assets.Start + ' ' + Assets.Hour.toLowerCase()}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName= "h30 w60"
                    value = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour !== undefined ? Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour.toString() : ''}
                    type = "number"
                    min = "0"
                    icon = {Assets_.faHourglassStartClass}
                    onChange = {(text) => {
                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour = +text
                        Logic.Type.New (Main.Instance.TournamentState, {RS})
                    }}
                    
                /> */}
                <FormInputNumber
                    disabled = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].hour === -1}
                    placeholder = {Assets.Min}
                    description = {Assets.Start + ' ' + Assets.Minute.toLowerCase()}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName= "h30 w60"
                    value = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].minute !== undefined ? Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].minute.toString() : ''}
                    type = "number"
                    min = "-1"
                    max = "60"
                    icon = {Assets_.faHourglassStartClass}
                    onChange = {(text) => {
                        if (text === '60') {
                            text = '0'
                        } else if (text === '-1') {
                            text = '59'
                        }

                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        let setDefaultMinutes: boolean
                        const defMin: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0
                        if (+text !== defMin) {
                            setDefaultMinutes = false
                        } else {
                            setDefaultMinutes = true
                        }
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].minute = +text
                        RaceSort(RS, setDefaultMinutes)
                    }}
                />
                <FormInputNumber
                    placeholder = {Assets.Min}
                    description = {Assets.DurationMinutes}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName= "h30 w60"
                    value = {Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].durationMinutes !== undefined ? Main.Instance.TournamentState.RS[Main.Instance.TournamentState.tournament.raceDetails - 1].durationMinutes.toString() : ''}
                    type = "number"
                    min = "0"
                    icon = {Assets_.faTimeNarrowClass}
                    onChange = {(text) => {
                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        let setDefaultDurationMinutes: boolean
                        const defMinDur: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
                        if (+text !== defMinDur) {
                            setDefaultDurationMinutes = false
                        } else {
                            setDefaultDurationMinutes = true
                        }
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].durationMinutes = +text
                        Logic.Type.New (Main.Instance.TournamentState, {RS, setDefaultDurationMinutes})
                    }}
                    
                />
                <button
                    title = {Assets.Set + ' ' + Assets.Default.toLowerCase() + ' ' + Assets.Start.toLowerCase() + Assets._and_ + Assets.DurationMinutes.toLowerCase()}
                    style = {{fontSize: '14px', padding: '3.5px 8px', textAlign: 'center', width: 'auto'}}
                    className = {'btn btn-primary btn-sm mr0 ml fl'}
                    onClick = {() => {
                        const RS = Object.assign ([], Main.Instance.TournamentState.RS)
                        
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].minute = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes !== undefined ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0
                        RS[Main.Instance.TournamentState.tournament.raceDetails - 1].durationMinutes = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes !== undefined ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
                        Logic.Type.New (Main.Instance.TournamentState, {RS, setDefaultMinutes: true, setDefaultDurationMinutes: true})
                    }}
                    disabled = {Main.Instance.TournamentState.setDefaultMinutes && Main.Instance.TournamentState.setDefaultDurationMinutes}
                >
                    {Assets.Set + ' ' + Assets.Default.toLowerCase() + ' ' + Assets.Minutes.toLowerCase()}
                </button>
            </div>
        )
    }
    return races
}

function getPrizes(): JSX.Element[] {
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    const prizes: JSX.Element[] = []
    prizes.push(
        
        <button
            title = {Assets.Prizes}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
            key = "-1"
            className = {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.prize === 0 ?
                        'btn btn-primary btn-sm mr0 ml fl' : 'btn btn-default btn-sm mr0 ml fl'}
            onClick = {() => SelectPrizes(0)}
        >
            <i className = {Assets_.faAchievementClass}/>
        </button>,
        )
    if (Main.Instance.TournamentState.P && Main.Instance.TournamentState.tournament) {
    for (let i: number = 0; i < Main.Instance.TournamentState.P.length; i++) {
        prizes.push (
            <button
                title = {Assets.SelectPrize}
                style = {{borderRadius: '0px', fontSize: '14px', padding: '4px 8px 3px', textAlign: 'center'}}
                key = {i.toString()}
                className = {Main.Instance.TournamentState.tournament.prize === i + 1 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectPrizes(i + 1)}
            >
                <span>{Main.Instance.TournamentState.P[i].firstRewarded}</span>
                <sup>{post(Main.Instance.TournamentState.P[i].firstRewarded)}</sup>
                {Main.Instance.TournamentState.P[i].firstRewarded !== Main.Instance.TournamentState.P[i].lastRewarded ? 
                <span>{' - '}</span> : void 0}
                {Main.Instance.TournamentState.P[i].firstRewarded !== Main.Instance.TournamentState.P[i].lastRewarded ? 
                <span>{Main.Instance.TournamentState.P[i].lastRewarded}</span> : void 0}
                {Main.Instance.TournamentState.P[i].firstRewarded !== Main.Instance.TournamentState.P[i].lastRewarded ? 
                <sup>{post(Main.Instance.TournamentState.P[i].lastRewarded)}</sup> : void 0}
            </button>,
        )
        if (i + 1 === Main.Instance.TournamentState.tournament.prize) {
            prizes.push (
                <button
                    title = {Assets.RemovePrize}
                    style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                    key = "-3"
                    className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                    onClick = {() => RemovePrize()}
                >
                    <i className = {Assets_.faTrashButtonClass}/>
                </button>,
            )
        }

        
    }
    }
    if (IsBuildMyChance()) {
        prizes.push (
            <FormSelect
                narrow = {true}
                floatRight = {true}
                title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.FreeSpinsAmount.toLowerCase()}
                value = {Main.Instance.TournamentState.freeSpins}
                data = {getFreeSpinsData(Main.Instance.TournamentState.rewardType)}
                ClassName = "form-control wi100"
                disabled = {Main.Instance.DatabaseDataState.strapiConnected === false || Main.Instance.TournamentState.rewardType !== '1' && Main.Instance.TournamentState.rewardType !== '9' ? true : false}
                onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {freeSpins: text})}
                icon = {Assets_.faHashtagClass}
            />
        )
        prizes.push(
            <FormSelect
                narrow = {true}
                floatRight = {true}
                title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.Type.toLowerCase()}
                value = {Main.Instance.TournamentState.rewardType}
                data = {Main.Instance.FrontendStoreState.Category}
                ClassName = "form-control "
                
                onChange = {(text) => Logic.Type.New(Main.Instance.TournamentState, {rewardType: text, freeSpins: '-1'})}
                icon = {Assets_.faRewardLgClass}
            />
        )
        
    }

    prizes.push (
        <button
            title = {Assets.AddPrize}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr ml0 fl'}
            onClick = {() => AddPrizes()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    return prizes
}

function getPrizesSelect(): JSX.Element[] {
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    const prizes: JSX.Element[] = []

    if (Main.Instance.TournamentState.P && Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.prize > 0) {
        prizes.push (
            <div className = "row admin-users-row mt">
                
                <FormSelect
                    rewardFor = {3}
                    key = "-4"
                    level = {true}
                    mt0 = {true}
                    value = {Main.Instance.TournamentState.P[Main.Instance.TournamentState.tournament.prize - 1].rewardId.toString()}
                    data = {IsBuildMyChance() ? getRewardsData(Main.Instance.TournamentState.rewardType, Main.Instance.TournamentState.freeSpins) : Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => SelectRewardForPrize(text)}
                    icon = {Assets_.faRewardLgClass}
                />        
                {!IsBuildMyChance() ? 
                <FormSelect
                    rewardFor = {3}
                    key = "-7"
                    level = {true}
                    mt0 = {true}
                    value = {!Main.Instance.TournamentState.P[Main.Instance.TournamentState.tournament.prize - 1].rewardId2 ? '0' :
                            Main.Instance.TournamentState.P[Main.Instance.TournamentState.tournament.prize - 1].rewardId2.toString()}
                    data = {IsBuildMyChance() ? getRewardsData(Main.Instance.TournamentState.rewardType, Main.Instance.TournamentState.freeSpins) : Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => SelectRewardForPrize2(text)}
                    icon = {Assets_.faRewardLgClass}
                    
                /> : void 0}      
                <div className = "fl">
                    <FormInputNumber
                        key = "-5"
                        description = {Assets.EditPrizeFirstRewardedPosition}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w50px pr0"
                        value = {Main.Instance.TournamentState.P ? 
                                Main.Instance.TournamentState.P[Main.Instance.TournamentState.tournament.prize - 1].firstRewarded.toString() : '0'}
                        type = "number"
                        min = "1"
                        iconText = {Assets.FirstPosition}
                        iconTextS = "14px"
                        onChange = {(text) => FirstRewardedChanged(text)}
                        noIconTextFix = {true}
                    />
                </div>
                <div className = "fl">
                    <FormInputNumber
                        key = "-6"
                        description = {Assets.EditPrizeLastRewardedPosition}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w50px pr0"
                        value = {Main.Instance.TournamentState.P ? 
                                Main.Instance.TournamentState.P[Main.Instance.TournamentState.tournament.prize - 1].lastRewarded.toString() : '0'}
                        type = "number"
                        min = "1"
                        iconText = {Assets.LastPosition}
                        iconTextS = "14px"
                        onChange = {(text) => LastRewardedChanged(text)}
                        noIconTextFix = {true}
                    />
                </div>
        
            </div>
        )
    }

    return prizes
}

function SelectRewardForPrize(text: string) {
    const P = Object.assign ([], Main.Instance.TournamentState.P)
    P[Main.Instance.TournamentState.tournament.prize - 1].rewardId = +text
    Logic.Type.New (Main.Instance.TournamentState, {P})
}

function SelectRewardForPrize2(text: string) {
    const P = Object.assign ([], Main.Instance.TournamentState.P)
    P[Main.Instance.TournamentState.tournament.prize - 1].rewardId2 = +text
    Logic.Type.New (Main.Instance.TournamentState, {P})
}

function FirstRewardedChanged(text: string) {
    const P = Object.assign ([], Main.Instance.TournamentState.P)
    P[Main.Instance.TournamentState.tournament.prize - 1].firstRewarded = +text
    if (P[Main.Instance.TournamentState.tournament.prize - 1].lastRewarded) {
        if (P[Main.Instance.TournamentState.tournament.prize - 1].firstRewarded <= P[Main.Instance.TournamentState.tournament.prize - 1].lastRewarded) {
            Logic.Type.New (Main.Instance.TournamentState, {P})
        }
    } else { Logic.Type.New (Main.Instance.TournamentState, {P}) }
}

function LastRewardedChanged(text: string) {
    const P = Object.assign ([], Main.Instance.TournamentState.P)
    P[Main.Instance.TournamentState.tournament.prize - 1].lastRewarded = +text
    if (P[Main.Instance.TournamentState.tournament.prize - 1].firstRewarded) {
        if (P[Main.Instance.TournamentState.tournament.prize - 1].firstRewarded <= P[Main.Instance.TournamentState.tournament.prize - 1].lastRewarded) {
            Logic.Type.New (Main.Instance.TournamentState, {P})
        }
    } else { Logic.Type.New (Main.Instance.TournamentState, {P}) }
}
// function getRewardsData(): any[] {
//     const options: Array<{ id: string, name: string }> = []
//     let bonusCode: string = ''
//     const reg = new RegExp(/\d+fs/i)
    
//     if (Main.Instance.DatabaseDataState.rewardShopStream) {
//         for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
//             if (item.reward.category1.bonusCode && reg.test(item.reward.category1.bonusCode)) {
//                 bonusCode = ' | ' + item.reward.category1.bonusCode.match(reg)
//             } else {
//                 bonusCode = ''
//             }
//             options.push({ name: item.Reward + ' | ' + Assets_.RewardTypes[item['Reward type']] + bonusCode, id: item.id})
//         }
//     }
//     return options.sort((a, b) => (a.name > b.name) ? 1 : -1)
// }
{/*
function getBoosters(): JSX.Element[] {
    let boosters: JSX.Element[] = []
    boosters.push(
        <button
            title = {Assets.Boosters}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
            key = "-1"
            className = {Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.boost === 0 ?
                        'btn btn-primary btn-sm mr0 ml fl' : 'btn btn-default btn-sm mr0 ml fl'}
            onClick = {() => SelectBoosters(0)}
        >
            <i className = {Assets_.faActivityClass}/>
        </button>
        )
    if (Main.Instance.TournamentState.B && Main.Instance.TournamentState.tournament)
    for (let i: number = 0; i < Main.Instance.TournamentState.B.length; i++) {
        boosters.push (
            <button
                title = {'Select Booster'}
                style = {{borderRadius: '0px', fontSize: '14px', padding: '4px 8px 3px', textAlign: 'center'}}
                key = {i.toString()}
                className = {Main.Instance.TournamentState.tournament.boost === i + 1 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectBoosters(i + 1)}
            >
                Booster {i + 1}
            </button>
        )
    }

    boosters.push (
        <button
            title = {'Add Booster'}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddBooster()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>
    )

    if (Main.Instance.TournamentState.B && Main.Instance.TournamentState.tournament && Main.Instance.TournamentState.tournament.boost > 0) {
        boosters.push (
            <button
                title = {'Remove Booster'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr ml0 fl'}
                onClick = {() => RemoveBooster()}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>
        )
        boosters.push (
            <div className = "fl">
                <FormInputNumber
                    key = "-4"
                    description = "Edit Booster Minimal multiplier"
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w50px pr0"
                    value = {Main.Instance.TournamentState.B ? 
                            Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].minimalMultiplier.toString() : '0'}
                    type = "number"
                    min = "0"
                    iconText = "BMM x"
                    iconTextS = "14px"
                    onChange = {text => MinimalMultiplierChanged(text)}
                    noIconTextFix = {true}
                />
            </div>
        )

        boosters.push (
            <FormInputNumber
                key = "-10"
                description = "Edit Boost consecutive wins"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].boostConsecutiveWins ? 
                        Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].boostConsecutiveWins.toString() : '0'}
                type = "number" 
                min = "0"
                icon = {Assets_.faManyItemsClass}
                onChange = {text => BoostConsecutiveWinsChanged(text)}
                noIconTextFix = {true}
            />
        )        

        boosters.push (
            <FormInputNumber
                key = "-9"
                description = "Edit Boost points percentage"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].boostPointsPercentage ? 
                        Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].boostPointsPercentage.toString() : '0'}
                type = "number" 
                min = "0"
                icon = {Assets_.faPercentageClass}
                onChange = {text => BoostPointsPercentageChanged(text)}
                noIconTextFix = {true}
            />
        )        


        boosters.push (<i key = "-5" title = "Booster Duration" className = {Assets_.faHourglassClass + ' fl dbl mr'} style = {{fontSize: '24px', marginTop: '3px'}}/>)

        boosters.push (
            <FormInputNumber
                key = "-6"
                description = "Edit Booster Duration hours"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w50px"
                value = {Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationHours ?
                        Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationHours.toString() : '0'}
                type = "number" 
                min = "0"
                iconText = "hrs"
                onChange = {text => DurationChanged(text, 0)}
                noIconTextFix = {true}
            />
        )

        boosters.push (
            <FormInputNumber
                key = "-7"
                description = "Edit Booster Duration minutes"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w50px"
                value = {Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes ?
                        Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes.toString() : '0'}
                type = "number" 
                min = "0"
                iconText = "min"
                onChange = {text => DurationChanged(text, 1)}
                noIconTextFix = {true}
            />
        )
        
        boosters.push (
            <FormInputNumber
                key = "-8"
                description = "Edit Booster Duration seconds"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w50px"
                value = {Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationSeconds ? 
                        Main.Instance.TournamentState.B[Main.Instance.TournamentState.tournament.boost - 1].durationSeconds.toString() : '0'}
                type = "number" 
                min = "0"
                iconText = "sec"
                onChange = {text => DurationChanged(text, 2)}
                noIconTextFix = {true}
            />
        )
    }
    return boosters
}
*/}
/*
function BoostPointsPercentageChanged(text: string) {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    B[Main.Instance.TournamentState.tournament.boost - 1].boostPointsPercentage = +text
    Logic.Type.New (Main.Instance.TournamentState, {B})
}

function BoostConsecutiveWinsChanged(text: string) {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    B[Main.Instance.TournamentState.tournament.boost - 1].boostConsecutiveWins = +text
    Logic.Type.New (Main.Instance.TournamentState, {B})
}
*/
/*
function DurationChanged (text: string, which: number) {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    if (which === 0) B[Main.Instance.TournamentState.tournament.boost - 1].durationHours = +text
    else if (which === 1) {
        if (+text > 59) {
            if (B[Main.Instance.TournamentState.tournament.boost - 1].durationHours === undefined)
            B[Main.Instance.TournamentState.tournament.boost - 1].durationHours = Math.floor (+text/60)
            else B[Main.Instance.TournamentState.tournament.boost - 1].durationHours += Math.floor (+text/60)
            text = (+text - 60 * Math.floor (+text/60)).toString()
        }
        B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes = +text
    } else if (which === 2) {
        if (+text > 59) {
            if (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes === undefined)
            B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes = Math.floor (+text/60)
            else B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes += Math.floor (+text/60)
            text = (+text - 60 * Math.floor (+text/60)).toString()

            if (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes > 59) {
                if (B[Main.Instance.TournamentState.tournament.boost - 1].durationHours === undefined)
                B[Main.Instance.TournamentState.tournament.boost - 1].durationHours = Math.floor (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes/60)
                else B[Main.Instance.TournamentState.tournament.boost - 1].durationHours += Math.floor (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes/60)
                // text = 
                B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes = 
                (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes - 60 * Math.floor (B[Main.Instance.TournamentState.tournament.boost - 1].durationMinutes/60)).toString()
            }
        }
        
        B[Main.Instance.TournamentState.tournament.boost - 1].durationSeconds = +text
    }
    Logic.Type.New (Main.Instance.TournamentState, {B})
}
*/
/*
function getDuration (boost_s: number, which: number) {
    let boost_h: number = 0
    let boost_m: number = 0
    if (boost_s >= 3600) {
        boost_h = Math.floor (boost_s / 3600)
        boost_s -= boost_h * 3600
    }

    if (boost_s >= 60) {
        boost_m = Math.floor (boost_s / 60)
        boost_s -= boost_m * 60
    }

    if (which === 0) return boost_h
    else if (which === 1) return boost_m
    else if (which === 2) return boost_s
}
*/
/*
function MinimalMultiplierChanged(minimalMultiplier: string) {
    let B = Object.assign ([], Main.Instance.TournamentState.B)
    B[Main.Instance.TournamentState.tournament.boost - 1].minimalMultiplier = +minimalMultiplier
    Logic.Type.New (Main.Instance.TournamentState, {B})
}
*/
/*
function getPlayers(): JSX.Element[] {
    let players: JSX.Element[] = []
    players.push(
    <button
        title = {Assets.Players}
        style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
        key = "-1"
        className = {Main.Instance.TournamentState.tournament.play === 0 ? 'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-default btn-sm mr0 ml0 fl'}
        onClick = {() => SelectPlayers(0)}
    >
        <i className = {Assets_.faUserClass}/>
    </button>
    )
    if (Main.Instance.TournamentState.O.players)
    for (let i: number = 0; i < Main.Instance.TournamentState.O.players.length; i++) {
        players.push (
            Main.Instance.TournamentState.tournament.play === i + 1 ?
            <div className = "fl">
            <FormInputNumber
                description = "Edit Player"
                ClassName = "h30 w50px pr0 primary-selected"
                value = {Main.Instance.TournamentState.O && Main.Instance.TournamentState.O.players ? 
                        Main.Instance.TournamentState.O.players[i].toString() : '0'}
                type = "number"
                min = "0"
                onChange = {text => PlayerChanged(text, i)}
            />
            </div>
            :
            <button
                title = {'Select Player'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '2px 0px', textAlign: 'center', width: '30px'}}
                key = {i.toString()}
                className = {'btn btn-default btn-sm mr0 ml0 fl'}
                onClick = {() => SelectPlayers(i + 1)}
            >
                {Main.Instance.TournamentState.O.players[i]}
            </button>
        )
    }

    players.push (
        <button
            title = {'Add Player'}
            style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
            key = "-2"
            className = {'btn btn-success btn-sm mr0 ml0 fl'}
            onClick = {() => AddPlayer()}
        >
            <i className = {Assets_.faPlusClass}/>
        </button>
    )

    if (Main.Instance.TournamentState.O.players && Main.Instance.TournamentState.tournament.play > 0)
        players.push (
            <button
                title = {'Remove Player'}
                style = {{borderRadius: '0px', fontSize: '16px', padding: '4px 0px 0px', textAlign: 'center', width: '30px'}}
                key = "-3"
                className = {'btn btn-danger btn-sm mr0 ml0 fl'}
                onClick = {() => RemovePlayer()}
            >
                <i className = {Assets_.faTrashButtonClass}/>
            </button>
        )

    return players
}
*/
