import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {Logic} from '../../../Logic/Type'
import {FormSelect, FormInputNumber, FormInput, RadioButton3} from '../../Reusables/Inputs'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {DateRangePicker} from 'react-dates'
import * as moment from 'moment'
import {utilsTimeValid,isUserOperator, isUserSiteOwner, /*isPureScandibet,*/ numberWithCommas, isPureScandibet} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import axios from 'axios'
import * as Config from '../../../Logic/Config'
/*
function SpecificQuery() {
    Logic.Type.New(Main.Instance.ReportPState, {specificQuery: !Main.Instance.ReportPState.specificQuery})
    // getPlayersPoints1()
} 
*/
/*
function playerPointsShowDetails () {

    let Sort: string[] = Object.assign([], Main.Instance.ReportPState3.Sort)
    let sort: number[] = Object.assign([], Main.Instance.ReportPState3.sort)

    for (let i: number = 0; i < Sort.length; i++) {
        if (Sort[i] === 'points_wagering') {
            Sort.splice(i, 1)
            sort.splice(i, 1)            
        }
    }

    Logic.Type.New (Main.Instance.ReportPState3, {
        Sort: Sort,
        sort: sort
    })

    Logic.Type.New (Main.Instance.ReportPState, {
        playerPointsShowDetails: !Main.Instance.ReportPState.playerPointsShowDetails})

}

function getSortedReportLevelsPoints1() {
    
    if (Main.Instance.ReportPState1.Sort.length > 0) {
        let sortedData: any[] = Main.Instance.ReportPState.data1
        for (let i:number = Main.Instance.ReportPState1.Sort.length - 1; i >= 0; i--) {
            let item: string = Main.Instance.ReportPState1.Sort[i]
            let sortType = item === 'playerid' ? 0 : 1

            let sortBy: string = item
            // if (item === 'eventtype') sortBy = 'sourcevalue'

            sortedData = SortData (sortedData, Main.Instance.ReportPState1.sort[i] % 100, sortType, sortBy)
        }
        Logic.Type.New (Main.Instance.ReportPState, {
            data1: sortedData,
            offset1: '0',
            pNum1: 0,
            Data1: sortedData.slice(0, Main.Instance.ReportPState.pSize1)
        })
    } else {
        Logic.Type.New (Main.Instance.ReportPState, {
            data1: Main.Instance.ReportPState.data1Copy,
            offset1: '0',
            pNum1: 0,
            Data1: Main.Instance.ReportPState.data1Copy.slice(0, Main.Instance.ReportPState.pSize1)
        })
    }
}

function getSortedReportLevelsPoints2() {
    
    if (Main.Instance.ReportPState2.Sort.length > 0) {
        let sortedData: any[] = Main.Instance.ReportPState.data2
        for (let i:number = Main.Instance.ReportPState2.Sort.length - 1; i >= 0; i--) {
            let item: string = Main.Instance.ReportPState2.Sort[i]
            let sortType = item === 'playerid' ? 0 : 1

            let sortBy: string = item
            // if (item === 'eventtype') sortBy = 'sourcevalue'
            
            sortedData = SortData (sortedData, Main.Instance.ReportPState2.sort[i] % 100, sortType, sortBy)
        }
        Logic.Type.New (Main.Instance.ReportPState, {
            data2: sortedData,
            offset2: '0',
            pNum2: 0,
            Data2: sortedData.slice(0, Main.Instance.ReportPState.pSize2)        
        })
    } else {
        Logic.Type.New (Main.Instance.ReportPState, {
            data2: Main.Instance.ReportPState.data2Copy,
            offset2: '0',
            pNum2: 0,
            Data2: Main.Instance.ReportPState.data2Copy.slice(0, Main.Instance.ReportPState.pSize2)
        })
    }  
}

function SortData (data: any[], direction: number, sortType: number, sort: string): any[] {

    if (sortType === 0) {
    data.sort((a,b) => {
        if (a[sort] < b[sort]) return -1
        else if (a[sort] > b[sort]) return 1
        else return 0
    })
    } else {
    data.sort((a,b) => {
        return a[sort] - b[sort]
    })
    }

    if (sortType === 0) {
    if (direction === 1) return data 
    else return data.reverse()
    } else {
    if (direction === 1) return data.reverse() 
    else return data    
    }
}
*/
function PlayerClick(player: string, playerLastEventDate?: string, Player?: any) {
    // console.log (Player)
    Logic.Type.New (Main.Instance.ReportPState, {playerCurrency: Player.localcurrency, playerPoints: Player.points})
    DbRoutes.getEventPlayer(player, playerLastEventDate, Player.localcurrency, Player.points)
}

// import * as Chart from '../../Reusables/Chart'
/*
import {ButtonIcon} from '../../Reusables/Button'
import {isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner} 
        from '../../../Logic/Utils' 

*/


// apart from validation, we have to also establish what the correct string is
// this is best done in this function
/*
function SelectSite(stream:any[]) {
    
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                operator_id: Main.Instance.LoginState.entityId,
                site_id: stream[0]['id']
        })
        DbRoutes.getLevelsAllBySite(0, stream[0]['id'], true)
        // DbRoutes.getRewardShopItemsAll(stream[0]['id'])
        // ApplyFilterSite(stream[0]['id'])
        // FirstPage()
    }
}
*/
function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
            operator_id: Main.Instance.LoginState.entityId,
            site_id: stream[0]['id'],
        })
        DbRoutes.PromostoreGetPlayers()
        // DbRoutes.getPlayersAllTEST3(/*0*/)
    }
}

function PerformQuery(which: number) {
    DbRoutes.getEvents(which)
}

function AllowTimeCharactersOnly(text: string, which: number) {
    let proceed: boolean = text.length <= 8 ? true : false
    let dot: number = 0
    let colon: number = 0
    let space: number = 0
    let first: number
    let second: number
    
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '.'
            && text.charAt(i) !== ':'
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
            && text.charAt(i) !== ' '
        ) { proceed = false }

        if (text.charAt(i) === '.') { dot++ } else if (text.charAt(i) === ':') { colon++ } else if (text.charAt(i) === ' ') { space++ }
        if (dot > 2 || colon > 2 || space > 2 || dot * colon > 0 || space * colon > 0 || space * dot > 0) {
            proceed = false
        }

        if (proceed && (text.charAt(i) === '.' || text.charAt(i) === ':' || text.charAt(i) === ' ')) {
            if (first) { second = i } else { first = i }
        }
    }

    if (proceed) {
        let t: string = ''

        if (text.length === 5 || text.length === 8) {
            if (!first) { proceed = false }
            if (proceed && first === 2) { t += text.charAt(0) + text.charAt(1) + text.charAt(3) + text.charAt(4) }
            if (proceed && second && second === 5 && text.length === 8) { t += text.charAt(6) + text.charAt(7) }
            // console.log (t)
            // if (proceed && text.length === 5) t += '00'
        } else { proceed = false }

        if (which === 0 && proceed) {
            Logic.Type.New(Main.Instance.ReportPState, {from_time: text, fromTime: t})
        } else if (which === 0) {
            Logic.Type.New(Main.Instance.ReportPState, {from_time: text, fromTime: ''})            
        }

        if (which === 1 && proceed) {
            Logic.Type.New(Main.Instance.ReportPState, {to_time: text, toTime: t})
        } else if (which === 1) {
            Logic.Type.New(Main.Instance.ReportPState, {to_time: text, toTime: ''})            
        }
    }
}

function DatesChange(startDate: moment.Moment, endDate: moment.Moment, which: number) {

    const f: string = startDate ? moment.default(startDate).format(Assets_.dateDisplayFormat).trim() : ''
    const t: string = endDate ? moment.default(endDate).format(Assets_.dateDisplayFormat).trim() : ''

    if (which === 1) {
        Logic.Type.New(Main.Instance.ReportPState, {from_date: startDate, to_date: endDate, fromDate: f, toDate: t})
    } else if (which === 2) {
        Logic.Type.New(Main.Instance.ReportPState, {from_date2: startDate, to_date2: endDate, fromDate2: f, toDate2: t})
    }
}

export function getPlayersPoints1() {
    DbRoutes.getEvents(1)
}

export function getPlayersPoints2() {
    DbRoutes.getEvents(2)
}

function partReportLevelsPoints1() {
    Logic.Type.New (Main.Instance.ReportPState, {
        Data1: Main.Instance.ReportPState.data1.slice(
            parseInt(Main.Instance.ReportPState.offset1),
            parseInt(Main.Instance.ReportPState.offset1) + Main.Instance.ReportPState.pSize1)},
    )
}

function partReportLevelsPoints2() {
    Logic.Type.New (Main.Instance.ReportPState, {
        Data2: Main.Instance.ReportPState.data2.slice(
            parseInt(Main.Instance.ReportPState.offset2),
            parseInt(Main.Instance.ReportPState.offset2) + Main.Instance.ReportPState.pSize2)},
    )    
}

export function getPlayersPointsCount() {
    if (Main.Instance.ReportPState.countCalled === false/* || Main.Instance.SearchState.search_string && Main.Instance.SearchState.search_string.length > 0*/) {
        // alert ('inside')
        // alert ('here')
        Logic.Type.New (Main.Instance.ReportPState, {countCalled: true, player: undefined, footer: []})
        // DbRoutes.getPlayersAllTEST3(/*0*/) // this is the default
        DbRoutes.PromostoreGetPlayers()
        if (isUserOperator() || isUserSiteOwner()) {
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        }
    }
}
/*
function getPlayersPoints() {
    Logic.Type.New (Main.Instance.ReportPState, {Data: undefined})
}
*/
function SublevelMinChanged(text:string) {
    if (parseInt(text) > -1 && parseInt(text) <= Main.Instance.ReportPState.Sublevels) {
        Logic.Type.New(Main.Instance.ReportPState, {sublevel_min: parseInt(text)})
        // !!!
        if (!Main.Instance.ReportPState.player) {
            if (!Main.Instance.ReportPState.specificQuery) { getPlayersPointsCount() } else if (!Main.Instance.ReportPState.compareDate) {
                getPlayersPoints1()
 } else {
                getPlayersPoints1()
                getPlayersPoints2()
            }
        }

    }
}
    
function SublevelMaxChanged(text:string) {
    if (parseInt(text) > -1 && parseInt(text) <= Main.Instance.ReportPState.Sublevels) {
        Logic.Type.New(Main.Instance.ReportPState, {sublevel_max: parseInt(text)})
        // !!!
        if (!Main.Instance.ReportPState.player) {
            if (!Main.Instance.ReportPState.specificQuery) { getPlayersPointsCount() } else if (!Main.Instance.ReportPState.compareDate) {
                getPlayersPoints1()
 } else {
                getPlayersPoints1()
                getPlayersPoints2()
            }
        }
    }
}

function ApplyFilterProcessed(text:string) {
    Logic.Type.New (Main.Instance.ReportPState, {filter_processed: text})
    getEventPlayer()
}

function ApplyFilterType(text:string) {
    Logic.Type.New (Main.Instance.ReportPState, {filter_type: text})
    getEventPlayer()
}

function ApplyFilter(text: string) {
    // alert (text)
    let sublevels: number = 0
    let sublevel_min: number = Main.Instance.ReportPState.sublevel_min
    let sublevel_max: number = Main.Instance.ReportPState.sublevel_max
    
    if (Main.Instance.ReportPState.sublevels.length > 0) {
        if (Main.Instance.ReportPState.sublevels[parseInt(text) - 1]) {
            sublevels = Main.Instance.ReportPState.sublevels[parseInt(text) - 1]
        }
    }

    if (sublevel_min > sublevels) { sublevel_min = sublevels }
    if (sublevel_max > sublevels) { sublevel_max = sublevels }

    Logic.Type.New (Main.Instance.ReportPState, {
        filter_level: text,
        Data: undefined,
        offset: '0',
        pNum: 0,
        offset1: '0',
        pNum1: 0,
        offset2: '0',
        pNum2: 0,
        Sublevels: sublevels,
        sublevel_min,
        sublevel_max,
    })

    if (!Main.Instance.ReportPState.player) {
        if (!Main.Instance.ReportPState.specificQuery) {
            getPlayersPointsCount()
        } else if (!Main.Instance.ReportPState.compareDate) {
            getPlayersPoints1()
 } else {
            getPlayersPoints1()
            getPlayersPoints2()
        }
    }
}
/*
function FirstPage() {
    
    Logic.Type.New(Main.Instance.ReportPState, {offset:(0).toString()})

    getPlayersPoints()
    Logic.Type.New(Main.Instance.ReportPState, {pNum: 0})
}
*/
    /*
function PrevPage() {

    if (Main.Instance.ReportPState.pNum > 0) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset:((Main.Instance.ReportPState.pNum - 1) * 
            Main.Instance.ReportPState.pSize).toString()})
        getPlayersPoints()

        Logic.Type.New(Main.Instance.ReportPState,{pNum: Main.Instance.ReportPState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.ReportPState.pNum + 1) * Main.Instance.ReportPState.pSize < 
    Main.Instance.ReportPState.count) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset: ((Main.Instance.ReportPState.pNum + 1) * 
            Main.Instance.ReportPState.pSize).toString()})
        getPlayersPoints()
        
        Logic.Type.New(Main.Instance.ReportPState,{pNum: Main.Instance.ReportPState.pNum + 1})
    } else {
        FirstPage()
    }
}
*/
/*
function LastPage() {

    if (Main.Instance.ReportPState.count <= Main.Instance.ReportPState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.ReportPState.count === 0) {

            Logic.Type.New(Main.Instance.ReportPState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.ReportPState.count - 1) / 
                    Main.Instance.ReportPState.pSize)
            Logic.Type.New(Main.Instance.ReportPState, {
                offset:(npNum * Main.Instance.ReportPState.pSize).toString()})
        }

        getPlayersPoints()

        Logic.Type.New(Main.Instance.ReportPState,{pNum: npNum})
    }
}
*/
function FirstPage1() {
    
    Logic.Type.New(Main.Instance.ReportPState, {offset1:(0).toString()})

    partReportLevelsPoints1()
    Logic.Type.New(Main.Instance.ReportPState, {pNum1: 0})
}
    
function PrevPage1() {

    if (Main.Instance.ReportPState.pNum1 > 0) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset1:((Main.Instance.ReportPState.pNum1 - 1) * 
            Main.Instance.ReportPState.pSize1).toString()})
        partReportLevelsPoints1()

        Logic.Type.New(Main.Instance.ReportPState,{pNum1: Main.Instance.ReportPState.pNum1 - 1})
    } else {
        LastPage1()
    }
}

function NextPage1() {

    if ((Main.Instance.ReportPState.pNum1 + 1) * Main.Instance.ReportPState.pSize1 < 
    Main.Instance.ReportPState.count1) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset1: ((Main.Instance.ReportPState.pNum1 + 1) * 
            Main.Instance.ReportPState.pSize1).toString()})
        partReportLevelsPoints1()
        
        Logic.Type.New(Main.Instance.ReportPState,{pNum1: Main.Instance.ReportPState.pNum1 + 1})
    } else {
        FirstPage1()
    }
}

function LastPage1() {

    if (Main.Instance.ReportPState.count1 <= Main.Instance.ReportPState.pSize1) {
        FirstPage1()
    } else {
        let npNum1: number = 0;

        if (Main.Instance.ReportPState.count1 === 0) {

            Logic.Type.New(Main.Instance.ReportPState, {offset1: (0).toString()})
        } else {

            npNum1 = Math.trunc((Main.Instance.ReportPState.count1 - 1) / 
                    Main.Instance.ReportPState.pSize1)
            Logic.Type.New(Main.Instance.ReportPState, {
                offset1:(npNum1 * Main.Instance.ReportPState.pSize1).toString()})
        }

        partReportLevelsPoints1()

        Logic.Type.New(Main.Instance.ReportPState,{pNum1: npNum1})
    }
}

function FirstPage2() {
    
    Logic.Type.New(Main.Instance.ReportPState, {offset2:(0).toString()})

    partReportLevelsPoints2()
    Logic.Type.New(Main.Instance.ReportPState, {pNum2: 0})
}
    
function PrevPage2() {

    if (Main.Instance.ReportPState.pNum2 > 0) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset2:((Main.Instance.ReportPState.pNum2 - 1) * 
            Main.Instance.ReportPState.pSize2).toString()})
        partReportLevelsPoints2()

        Logic.Type.New(Main.Instance.ReportPState,{pNum2: Main.Instance.ReportPState.pNum2 - 1})
    } else {
        LastPage2()
    }
}

function NextPage2() {

    if ((Main.Instance.ReportPState.pNum2 + 1) * Main.Instance.ReportPState.pSize2 < 
    Main.Instance.ReportPState.count2) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset2: ((Main.Instance.ReportPState.pNum2 + 1) * 
            Main.Instance.ReportPState.pSize2).toString()})
        partReportLevelsPoints2()
        
        Logic.Type.New(Main.Instance.ReportPState,{pNum2: Main.Instance.ReportPState.pNum2 + 1})
    } else {
        FirstPage2()
    }
}

function LastPage2() {

    if (Main.Instance.ReportPState.count2 <= Main.Instance.ReportPState.pSize2) {
        FirstPage2()
    } else {
        let npNum2: number = 0;

        if (Main.Instance.ReportPState.count2 === 0) {

            Logic.Type.New(Main.Instance.ReportPState, {offset2: (0).toString()})
        } else {

            npNum2 = Math.trunc((Main.Instance.ReportPState.count2 - 1) / 
                    Main.Instance.ReportPState.pSize2)
            Logic.Type.New(Main.Instance.ReportPState, {
                offset2:(npNum2 * Main.Instance.ReportPState.pSize2).toString()})
        }

        partReportLevelsPoints2()

        Logic.Type.New(Main.Instance.ReportPState,{pNum2: npNum2})
    }
}

function getEventPlayer() {
    // console.log (Main.Instance.ReportPState.player, Main.Instance.ReportPState.playerLastEventDate, Main.Instance.ReportPState.playerCurrency, Main.Instance.ReportPState.playerPoints)
    DbRoutes.getEventPlayer(Main.Instance.ReportPState.player, Main.Instance.ReportPState.playerLastEventDate, Main.Instance.ReportPState.playerCurrency, Main.Instance.ReportPState.playerPoints)
}

/*
function RecordsPerPageChanged(text:string) {
    
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.ReportPState, {pSize:parseInt(text)})
        if (!Main.Instance.ReportPState.player) FirstPage()
        else FirstPage0()
    }
}
*/
/*
function RecordsPerPageChanged1(text:string) {
    
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.ReportPState, {pSize1: parseInt(text)})
        FirstPage1()
    }
}

function RecordsPerPageChanged2(text:string) {
    
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.ReportPState, {pSize2: parseInt(text)})
        FirstPage2()
    }
}
*/
function CompareDate() {
    Logic.Type.New(Main.Instance.ReportPState, {compareDate: !Main.Instance.ReportPState.compareDate})
    // getPlayersPoints2()
} 

function FooterBottom() {
    Logic.Type.New(Main.Instance.ReportPState, {footerBottom: !Main.Instance.ReportPState.footerBottom})
    // console.log ('footerBottom: ' + Main.Instance.ReportPState.footerBottom)
} 
/*
function FirstPage0() {
    
    Logic.Type.New(Main.Instance.ReportPState, {offset:(0).toString()})

    getEventPlayer()
    Logic.Type.New(Main.Instance.ReportPState, {pNum: 0})
}
*/
/*
function PrevPage0() {

    if (Main.Instance.ReportPState.pNum > 0) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset:((Main.Instance.ReportPState.pNum - 1) * 
            Main.Instance.ReportPState.pSize).toString()})

        getEventPlayer()
        Logic.Type.New(Main.Instance.ReportPState, {pNum: Main.Instance.ReportPState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage0() {

    if ((Main.Instance.ReportPState.pNum + 1) * Main.Instance.ReportPState.pSize < 
    Main.Instance.ReportPState.Count) {

        Logic.Type.New(Main.Instance.ReportPState, {
            offset: ((Main.Instance.ReportPState.pNum + 1) * 
            Main.Instance.ReportPState.pSize).toString()})

        getEventPlayer()
        Logic.Type.New(Main.Instance.ReportPState,{pNum: Main.Instance.ReportPState.pNum + 1})
    } else {
        FirstPage0()
    }
}

function LastPage0() {

    if (Main.Instance.ReportPState.Count <= Main.Instance.ReportPState.pSize) {
        FirstPage0()
    } else {
        let npNum: number = 0;

        if (Main.Instance.ReportPState.Count === 0) {

            Logic.Type.New(Main.Instance.ReportPState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.ReportPState.Count - 1) / 
                    Main.Instance.ReportPState.pSize)
            Logic.Type.New(Main.Instance.ReportPState, {
                offset:(npNum * Main.Instance.ReportPState.pSize).toString()})
        }

        getEventPlayer()
        Logic.Type.New(Main.Instance.ReportPState, {pNum: npNum})
    }
}
*/
export function PlayersPoints() {
    // let nodata: boolean = false
    // if (!Main.Instance.ReportPState.Data || Main.Instance.ReportPState.Data.length === 0)
    //   nodata = true
    // console.log (JSON.stringify(Main.Instance.ReportPState.Sort))
    // console.log (JSON.stringify(Main.Instance.ReportPState.sort))

/*
    let report_by_level:JSX.Element[] = []

    if (Main.Instance.ReportPState.data &&
        Main.Instance.ReportPState.data.length > 0 && 
        Main.Instance.ReportPState.count > 0) {
        // console.log (JSON.stringify(Main.Instance.ReportPState.data))
        let points:number = 0
        let cumul:number = 0
        if (parseInt(Main.Instance.ReportPState.filter_level) === 0) {
            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportPState.data.length + 3).toString()}
                    style = {{width: '100%', fontWeight: 600, fontSize: '14px'}}
                >
                    <span className = "fl dbl w50px">{Assets.Level}</span>
                    <span className = "fl dbl w100px">{Assets.LevelName}</span>
                    <span className = "fl dbl w50px">{Assets.N}</span>
                    <span className = "fl dbl w80px">{Assets.TotalP}</span>
                    <span className = "fl dbl w80px">{Assets.Cumul}</span>
                    <span className = "fl dbl w80px">{Assets.CumulP}</span>
                    <span title = {Assets.TPM} className = "fl dbl w108px">
                        {Assets.TPMean}
                    </span>
                    <span title = {Assets.SLM} className = "fl dbl w108px">
                        {Assets.SLMean}
                    </span>
                    <div className = "clearfix"/>
                </p>
            )
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportPState.data.length + 2).toString()}
                />)

            // let offset:number = 0

            for (let i:number = 0; i < Main.Instance.ReportPState.data.length; i++) {
                if (Main.Instance.ReportPState.data[i].data === 0) continue
                cumul += Main.Instance.ReportPState.data[i].data
                points += Main.Instance.ReportPState.data[i].avgTP
                report_by_level.push(
                    <p
                        key = {i.toString()}
                        style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}
                    >
                        <span className = "fl dbl w50px">{Main.Instance.ReportPState.data[i].level}</span>
                        <span
                            style = {{
                                textAlign:'center',
                                fontWeight: 600,
                                marginRight: '10px',
                                background: Main.Instance.ReportPState.colors[i],
                                color: Main.Instance.ReportPState.Colors[i]
                            }}

                            className = "fl dbl w90px"
                        >
                            {Main.Instance.ReportPState.data[i].name}
                        </span>
                        <span className = "fl dbl w50px">{Main.Instance.ReportPState.data[i].data}</span>
                        <span className = "fl dbl w80px">
                        {(Main.Instance.ReportPState.data[i].data/Main.Instance.ReportPState.count*100).toFixed(2)} %
                        </span>
                        <span className = "fl dbl w80px">{cumul}</span>
                        <span className = "fl dbl w80px">
                        {(cumul / Main.Instance.ReportPState.count*100).toFixed(2)} %
                        </span>
                        <span className = "fl dbl w108px">{Main.Instance.ReportPState.data[i].avgTP}</span>
                        <span 
                            className = "fl dbl w108px"
                            style = {Main.Instance.ReportPState.data[i].avgSL > 0 ? {} : {color:'#bbb'}}    
                        >
                            {Main.Instance.ReportPState.data[i].avgSL > 0 ?
                            Main.Instance.ReportPState.data[i].avgSL : Assets_.NA}
                        </span>
                        <div className = "clearfix"/>
                    </p>
                )
            }
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportPState.data.length).toString()}
                />)

            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportPState.data.length + 1).toString()}
                    style = {{width:'100%', fontWeight:400, fontSize:'14px'}}
                >
                    <span className = "fl dbl w150px">{Assets.Total + ': '}</span>
                    <span className = "fl dbl w50px">{Main.Instance.ReportPState.count}</span>
                    <span className = "fl dbl w80px">100%</span>
                    <span className = "fl dbl w80px">{Main.Instance.ReportPState.count}</span>
                    <span className = "fl dbl w80px">100%</span>
                    <span className = "fl dbl w108px">{Math.round(points / Main.Instance.ReportPState.count)}</span>
                    <div className = "clearfix"/>
                </p>
            )
        } else {
            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportPState.data.length + 3).toString()}
                    style = {{width: '100%', fontWeight: 600, fontSize: '14px'}}
                >
                <span className = "fl dbl w150px">{Assets.SubLevelName}</span>
                <span className = "fl dbl w50px">{Assets.N}</span>
                <span className = "fl dbl w80px">{Assets.TotalP}</span>
                <span className = "fl dbl w80px">{Assets.Cumul}</span>
                <span className = "fl dbl w80px">{Assets.CumulP}</span>
                <div className = "clearfix"/>
                </p>
            )
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportPState.data.length + 2).toString()}
                />)

            let total:number = 0
            for (let i:number = 0; i < Main.Instance.ReportPState.data.length; i++) {
                total += Main.Instance.ReportPState.data[i].N
            }
            if (total > 0)
            for (let i:number = 0; i < Main.Instance.ReportPState.data.length; i++) {
                if (Main.Instance.ReportPState.data[i].N === 0) continue
                cumul += Main.Instance.ReportPState.data[i].N
                // points += Main.Instance.ReportPState.data[i].avgTP
                report_by_level.push(
                    <p
                        key = {i.toString()}
                        style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}
                    >

                        <span
                            style = {{
                                textAlign:'center',
                                fontWeight: 600,
                                marginRight: '10px',
                                background: '#AF601A',
                                color: '#fff'
                            }}

                            className = "fl dbl w140px"
                        >
                    {!Main.Instance.ReportPState.finalLevel[parseInt(Main.Instance.ReportPState.filter_level) - 1] ?
                    Main.Instance.ReportPState.levelNames[parseInt(Main.Instance.ReportPState.filter_level) - 1] + 
                    ' ' + Main.Instance.ReportPState.data[i].name :
                    Main.Instance.ReportPState.levelNames[parseInt(Main.Instance.ReportPState.filter_level) - 1]}
                        </span>
                        <span className = "fl dbl w50px">{Main.Instance.ReportPState.data[i].N}</span>
                        <span className = "fl dbl w80px">
                            {(Main.Instance.ReportPState.data[i].N / total * 100).toFixed(2) + ' %'}
                        </span>
                        <span className = "fl dbl w80px">{cumul}</span>
                        <span className = "fl dbl w80px">
                            {(cumul / total * 100).toFixed(2) + ' %'}
                        </span>
                        <div className = "clearfix"/>
                    </p>
                )
            }
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportPState.data.length).toString()}
                />)

            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportPState.data.length + 1).toString()}
                    style = {{width:'100%', fontWeight:400, fontSize:'14px'}}
                >
                    <span className = "fl dbl w150px">{Assets.Total + ': '}</span>
                    <span className = "fl dbl w50px">{total}</span>
                    <span className = "fl dbl w80px">100%</span>
                    <span className = "fl dbl w80px">{total}</span>
                    <span className = "fl dbl w80px">100%</span>
                    <div className = "clearfix"/>
                </p>
            )            
        }
    }

    // console.log (JSON.stringify(Main.Instance.ReportPState.data))
    let nodata: boolean = true
    if (Main.Instance.ReportPState.data)
    for (const item of Main.Instance.ReportPState.data) {
        if (item.data && item.data > 0) nodata = false
        if (item.N && item.N > 0) nodata = false        
    }
*/
    // let customArrowIcon: JSX.Element = <i className = {Assets_.faNextButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>

    const validationFromTime: boolean =
        Main.Instance.ReportPState.from_time.length === 0 || utilsTimeValid (Main.Instance.ReportPState.from_time)
    const validationToTime: boolean =
        Main.Instance.ReportPState.to_time.length === 0 || utilsTimeValid (Main.Instance.ReportPState.to_time)
/*
    let fin0: JSX.Element =
        <FormInputNumber
            description = {Assets.recordsPerPageDesc}
            outerDiv = "rows-per-table input-group fl mr ml0 pl0 mt3"
            ClassName = "h26 w60"
            value = {!Main.Instance.ReportPState.pSize ? '' : 
            Main.Instance.ReportPState.pSize.toString()}  
            type = "number" 
            min = "1"
            icon = {Assets_.faHamburgerClass}
            onChange = {text => RecordsPerPageChanged(text)}
        />
*/
/*
    let fin1: JSX.Element =
        <FormInputNumber
            inTitle = {true}
            description = {Assets.recordsPerPageDesc}
            outerDiv = "rows-per-table input-group fr mr"
            ClassName = "h26 w60"
            value = {!Main.Instance.ReportPState.pSize1 ? '' : 
            Main.Instance.ReportPState.pSize1.toString()}  
            type = "number" 
            min = "1"
            icon = {Assets_.faHamburgerClass}
            onChange = {text => RecordsPerPageChanged1(text)}
        />

    let fin2: JSX.Element =
        <FormInputNumber
            inTitle = {true}
            description = {Assets.recordsPerPageDesc}
            outerDiv = "rows-per-table input-group fr mr"
            ClassName = "h26 w60"
            value = {!Main.Instance.ReportPState.pSize2 ? '' : 
            Main.Instance.ReportPState.pSize2.toString()}  
            type = "number" 
            min = "1"
            icon = {Assets_.faHamburgerClass}
            onChange = {text => RecordsPerPageChanged2(text)}
        />
*/
    const bq1: JSX.Element =
        <button
            title = {Assets.PerformQuery}
            style = {{
                marginTop: '5px',
                marginRight: '0px',
                padding: '2px 8px',
                fontWeight: 700,
            }}
            onClick = {() => PerformQuery(1)}
            className = "btn btn-warning"
        >
            <i className = {Assets_.faRefreshClass}/>
        </button>    

    const bq2: JSX.Element =
        <button
            title = {Assets.PerformQuery}
            style = {{
                marginTop: '5px',
                marginRight: '0px',
                padding: '2px 8px',
                fontWeight: 700,
            }}
            onClick = {() => PerformQuery(2)}
            className = "btn btn-warning"
        >
            <i className = {Assets_.faRefreshClass}/>
        </button>    
/*
    let fb0: JSX.Element =
        <RadioButton3
            value = {Main.Instance.ReportPState.footerBottom === undefined ? false :
                    Main.Instance.ReportPState.footerBottom}
            text = {Assets.footerBottom}
            ClassName = "input-group-radiobutton3a mr0 margin-bottom-sm fr mt25" // fl mr0
            title = {Assets.footerBottomDesc}
            i1 = {Assets_.active}
            i2 = {Assets_.inactive}
            onClick = {text => FooterBottom()}     
        />
*/
    const fb: JSX.Element =
        <RadioButton3
            value = {Main.Instance.ReportPState.footerBottom === undefined ? false :
                    Main.Instance.ReportPState.footerBottom}
            text = {Assets.footerBottom}
            ClassName = "input-group-radiobutton3c margin-bottom-sm mt3" // fl mr0
            title = {Assets.footerBottomDesc}
            i1 = {Assets_.active}
            i2 = {Assets_.inactive}
            onClick = {(text) => FooterBottom()}     
        />
    // console.log (Main.Instance.ReportPState.Data)
    if (!Main.Instance.DatabaseDataState.SubLevels && isPureScandibet()) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {SubLevels: []})
        const a: string = Config.psServerSublevelsWidget + '?site_id=4' 
        // console.log (a)
        axios.get(a)
        .then((response) => {
            // console.log (response.data)
            Logic.Type.New (Main.Instance.DatabaseDataState, {SubLevels: response.data})
        })
    }

    return (
        <div>
            {/*alert (JSON.stringify(Main.Instance.DatabaseDataState.levelsAllDetailsStream))*/}
            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery ? <span>{Assets.SelectLevel}</span> : void 0}
            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery ?
            <FormSelect 
                span = {true}
                verynarrow = {true}
                value = {!Main.Instance.ReportPState.filter_level ? '0' : Main.Instance.ReportPState.filter_level}
                data = {Main.Instance.DatabaseDataState.levelsAllDetailsStream}
                ClassName = "control-panel-select-narrow mr"
                o1 = {Assets.AnyLevel} 
                o2 = {Assets.AnyLevel}
                onChange = {(text) => ApplyFilter(text)}
            />
            : void 0}

            {/*!Main.Instance.ReportPState.player ?
            <RadioButton3
                value = {Main.Instance.ReportPState.specificQuery === undefined ? false :
                        Main.Instance.ReportPState.specificQuery}
                text = {Assets.specificQuery}
                ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr0" // fl mr0
                title = {Assets.specificQueryDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {text => SpecificQuery()} 
            />
                : void 0*/}

            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery &&
            parseInt(Main.Instance.ReportPState.filter_level) > 0 && Main.Instance.ReportPState.Sublevels > 0 ?
            <span style = {{marginRight:'10px'}}>{Assets.SelectSubLevel}</span>
            : void 0}

            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery &&
            parseInt(Main.Instance.ReportPState.filter_level) > 0 && Main.Instance.ReportPState.Sublevels > 0 ?
            <FormInputNumber
                description = {Assets.subLevelMinDesc}
                ClassName = "rows-per-table mr" 
                value = {Main.Instance.ReportPState.sublevel_min === undefined ? '' : 
                Main.Instance.ReportPState.sublevel_min.toString()}  
                type = "number" 
                min = "0"
                onChange = {(text) => SublevelMinChanged(text)}
            />
            : void 0}

            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery &&
            parseInt(Main.Instance.ReportPState.filter_level) > 0 && Main.Instance.ReportPState.Sublevels > 0 ?
            <span>&ndash;</span>                    
            : void 0}

            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery &&
            parseInt(Main.Instance.ReportPState.filter_level) > 0 && Main.Instance.ReportPState.Sublevels > 0 ?
            <FormInputNumber
                description = {Assets.subLevelMaxDesc}
                ClassName = "rows-per-table ml"
                value = {Main.Instance.ReportPState.sublevel_max === undefined ? '' :
                Main.Instance.ReportPState.sublevel_max.toString()}  
                type = "number" 
                min = "0"
                onChange = {(text) => SublevelMaxChanged(text)}
            />
            : void 0}
            {!Main.Instance.ReportPState.player ?
            <button className = "btn btn-warning btn-xs" onClick = {() => ExportCSV()}>{Assets.CSVExport}</button>
            : void 0}

            {!Main.Instance.ReportPState.player && !Main.Instance.ReportPState.specificQuery &&
            parseInt(Main.Instance.ReportPState.filter_level) > 0 && Main.Instance.ReportPState.Sublevels === 0 ?
            <span
                style = {{fontSize:'14px'}}
                className = "label label-primary"
            >
                {Assets.finalLevel.toUpperCase()}
            </span>
            : void 0}
            <span>&nbsp;</span>
            {Main.Instance.ReportPState.specificQuery || Main.Instance.ReportPState.player ?
            <div
                className = "clearfix"
                style = {Main.Instance.ReportPState.player ?
                        {marginTop: '0px', background: '#f0f0f0', padding: '5px'} :
                        {marginTop: '20px', background: '#f0f0f0', padding: '5px'}}
            >
            <div style = {{marginTop: '5px'}}>
                {Main.Instance.ReportPState.player ?
                <button
                    title = {Assets.RefineSearch}
                    style = {{/*fontSize:'16px', fontWeight: 700,*/ padding: '3px 8px'}}
                    className = "fl mr ml btn btn-warning"
                    onClick = {() => getEventPlayer()}
                >
                    <i className = {Assets_.faSearchButtonClass}/>
                </button>
                :
                <span style = {{fontSize:'16px'}} className = "fl mr ml label label-default mt3">
                    {Assets.RefineSearch}
                </span>
                }
                <div className = "fl mr">
                    <DateRangePicker
                        startDateId = "sd1"
                        endDateId = "ed1"
                        startDate = {Main.Instance.ReportPState.from_date}
                        endDate = {Main.Instance.ReportPState.to_date}
                        onDatesChange = {({startDate, endDate}) => DatesChange(startDate, endDate, 1)}
                        focusedInput = {Main.Instance.ReportPState.focusedInput}
                        onFocusChange = {
                            (focusedInput) => Logic.Type.New(Main.Instance.ReportPState, {focusedInput})}
                        showDefaultInputIcon = {true}
                        showClearDates = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets_.dateDisplayFormatdmy}
                        customArrowIcon = {Assets_.customArrowIcon}
                        customCloseIcon = {Assets_.customCloseIcon}
                        minimumNights = {0}
                    />
                </div>

                <FormInput 
                    date = {true} 
                    float = {true} 
                    validation = {validationFromTime} 
                    value = {!Main.Instance.ReportPState.from_time ? '' : 
                            Main.Instance.ReportPState.from_time} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => AllowTimeCharactersOnly(text, 0)} 
                    placeholder = {Assets.Start}
                    description = {Assets.FromAnyPeriod}
                    icon = {Assets_.faTimeLgClass}
                />

                <FormInput 
                    date = {true} 
                    float = {true} 
                    validation = {validationToTime} 
                    value = {!Main.Instance.ReportPState.to_time ? '' : 
                            Main.Instance.ReportPState.to_time} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => AllowTimeCharactersOnly(text, 1)} 
                    placeholder = {Assets.End} 
                    description = {Assets.ToAnyPeriod}
                    icon = {Assets_.faTimeLgClass}
                />

                {Main.Instance.ReportPState.player ?
                <FormSelect
                    span = {true}
                    verynarrow = {true}
                    value = {!Main.Instance.ReportPState.filter_processed ? '0' : 
                            Main.Instance.ReportPState.filter_processed}
                    data = {[0]}
                    ClassName = "control-panel-select-narrow mt3"
                    o1 = {Assets.AllData} 
                    o2 = {Assets.ProcessedData}
                    o3 = {Assets.UnprocessedData}
                    onChange = {(text) => ApplyFilterProcessed(text)}
                />
                : void 0}

                {Main.Instance.ReportPState.player ?
                <FormSelect
                    span = {true}
                    verynarrow = {true}
                    value = {!Main.Instance.ReportPState.filter_type ? '0' : 
                            Main.Instance.ReportPState.filter_type}
                    data = {[0]}
                    ClassName = "control-panel-select-narrow mt3"
                    o1 = {Assets.AllData}
                    o2 = {Assets.Sportsbook}
                    o3 = {Assets.CasinoGames}
                    onChange = {(text) => ApplyFilterType(text)}
                />
                : void 0}

                {Main.Instance.ReportPState.player ? fb : void 0}

                {Main.Instance.ReportPState.player ?
                    <button
                        title = {Assets.BackToPlayerStats}
                        style = {{padding: '4px 8px', float: 'right', display: 'block'}}
                        className = "btn btn-danger"
                        onClick = {() => {
                            if (!Main.Instance.ReportPState.specificQuery) {
                                getPlayersPointsCount()
                            } else if (!Main.Instance.ReportPState.compareDate) {
                                getPlayersPoints1()
                            } else {
                                getPlayersPoints1()
                                getPlayersPoints2()
                            } 
                        }}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                : void 0}

                {Main.Instance.ReportPState.specificQuery ?
                <RadioButton3
                    value = {Main.Instance.ReportPState.compareDate === undefined ? false :
                            Main.Instance.ReportPState.compareDate}
                    text = {Assets.compareWithAnotherDate}
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fl" // fl mr0
                    title = {Assets.compareWithAnotherDateDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => CompareDate()} 
                />
                :void 0}

                {Main.Instance.ReportPState.compareDate === true && Main.Instance.ReportPState.specificQuery?
                <div className = "fl">
                    <DateRangePicker
                        startDateId = "sd2"
                        endDateId = "ed2"
                        startDate = {Main.Instance.ReportPState.from_date2} // momentPropTypes.momentObj or null,
                        endDate = {Main.Instance.ReportPState.to_date2} // momentPropTypes.momentObj or null,
                        onDatesChange = {({startDate, endDate}) => DatesChange(startDate, endDate, 2)}
                        focusedInput = {Main.Instance.ReportPState.focusedInput2}
                        onFocusChange = {
                            (focusedInput) => Logic.Type.New(Main.Instance.ReportPState, {focusedInput2: focusedInput})}
                        showDefaultInputIcon = {true}
                        showClearDates = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets_.dateDisplayFormatdmy}
                        customArrowIcon = {Assets_.customArrowIcon}
                        customCloseIcon = {Assets_.customCloseIcon}
                        minimumNights = {0}
                    />
                </div>
                : void 0}

            </div>
            </div>
            : void 0}

            <div className = "clearfix">
                {Main.Instance.ReportPState.specificQuery ?
                <div
                    className = "control-panel"
                    style = {{marginTop: '20px', width: '49%', float: 'left', marginRight: '2%'}}
                >
                    <button 
                        className = "btn btn-primary btn-xs mt5" // ml20
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage1()}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage1()}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage1()}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage1()}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>

                    {bq1}

                    <span className = "label label-success table-label mt5">
                    {Assets.all + ': '}
                    {Main.Instance.ReportPState.count1 ? Main.Instance.ReportPState.count1 : 0}
                    </span>
                    <span className = "label label-success table-label mt5">
                    {Assets.showingShort}
                    {Main.Instance.ReportPState.count1 ? Main.Instance.ReportPState.pNum1 * 
                    Main.Instance.ReportPState.pSize1 + 1 : 0}{' - '}
                    {Main.Instance.ReportPState.count1 ? 
                    ((Main.Instance.ReportPState.pNum1 + 1) * 
                    Main.Instance.ReportPState.pSize1 > Main.Instance.ReportPState.count1 ? 
                    Main.Instance.ReportPState.count1 : 
                    (Main.Instance.ReportPState.pNum1 + 1) * Main.Instance.ReportPState.pSize1) : 0}
                    </span>
                    <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.ReportPState.count1 ? 
                    Main.Instance.ReportPState.pNum1 + 1 :0}{' / '}
                    {Main.Instance.ReportPState.count1 ? 
                    Math.trunc((Main.Instance.ReportPState.count1 - 1) /
                    Main.Instance.ReportPState.pSize1 + 1) : 0}
                    </span>
                </div>
                : void 0/*
                Main.Instance.ReportPState.player ?
                isPureScandibet() ?
                <div
                    className = "control-panel"
                    style = {!Main.Instance.ReportPState.player ? {marginTop: '20px'} : {}}
                >
                    <>
                    {fin0}
                    <button 
                        className = {Main.Instance.ReportPState.player ? 
                                    'btn btn-primary btn-xs ml20 mt5' : 'btn btn-primary btn-xs ml20'}
                        title = {Assets.ListMoveTop}
                        onClick = {() => {Main.Instance.ReportPState.player ? FirstPage0() : FirstPage()}}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = {Main.Instance.ReportPState.player ? 
                            'btn btn-primary btn-xs mt5' : 'btn btn-primary btn-xs'}
                        title = {Assets.ListMoveLeft}
                        onClick = {() => {Main.Instance.ReportPState.player ? PrevPage0() : PrevPage()}}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = {Main.Instance.ReportPState.player ? 
                                    'btn btn-primary btn-xs mt5' : 'btn btn-primary btn-xs'}
                        title = {Assets.ListMoveRight}
                        onClick = {() => {Main.Instance.ReportPState.player ? NextPage0() : NextPage()}}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = {Main.Instance.ReportPState.player ? 
                                'btn btn-primary btn-xs mt5' : 'btn btn-primary btn-xs'}
                        title = {Assets.ListMoveBottom}
                        onClick = {() => {Main.Instance.ReportPState.player ? LastPage0() : LastPage()}}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>
                    </> 

                    {Main.Instance.ReportPState.player ?
                    <span className = "label label-success table-label mt5">
                    {Assets.records}
                    {Main.Instance.ReportPState.Count ? Main.Instance.ReportPState.Count : 0}
                    </span>
                    :
                    <span className = "label label-success table-label mt5">
                    {Assets.records}
                    {Main.Instance.ReportPState.count ? Main.Instance.ReportPState.count : 0}
                    </span>}

                    {Main.Instance.ReportPState.player ?
                    <span className = "label label-success table-label mt5">
                    {Assets.showing}
                    {Main.Instance.ReportPState.Count ? Main.Instance.ReportPState.pNum * 
                    Main.Instance.ReportPState.pSize + 1 : 0}{' - '}
                    {Main.Instance.ReportPState.Count ? 
                    ((Main.Instance.ReportPState.pNum + 1) * 
                    Main.Instance.ReportPState.pSize > Main.Instance.ReportPState.Count ? 
                    Main.Instance.ReportPState.Count :
                    (Main.Instance.ReportPState.pNum + 1) * Main.Instance.ReportPState.pSize) : 0}
                    </span>
                    :
                    <span className = "label label-success table-label mt5">
                    {Assets.showing}
                    {Main.Instance.ReportPState.count ? Main.Instance.ReportPState.pNum * 
                    Main.Instance.ReportPState.pSize + 1 : 0}{' - '}
                    {Main.Instance.ReportPState.count ? 
                    ((Main.Instance.ReportPState.pNum + 1) * 
                    Main.Instance.ReportPState.pSize > Main.Instance.ReportPState.count ? 
                    Main.Instance.ReportPState.count : 
                    (Main.Instance.ReportPState.pNum + 1) * Main.Instance.ReportPState.pSize) : 0}
                    </span>}

                    {Main.Instance.ReportPState.player ?                    
                    <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.ReportPState.Count ? 
                    Main.Instance.ReportPState.pNum + 1 :0}{' / '}
                    {Main.Instance.ReportPState.Count ? 
                    Math.trunc((Main.Instance.ReportPState.Count - 1) /
                    Main.Instance.ReportPState.pSize + 1) : 0}
                    </span>
                    :
                    <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.ReportPState.count ? 
                    Main.Instance.ReportPState.pNum + 1 :0}{' / '}
                    {Main.Instance.ReportPState.count ? 
                    Math.trunc((Main.Instance.ReportPState.count - 1) /
                    Main.Instance.ReportPState.pSize + 1) : 0}
                    </span>}
                    </div> : void 0 :  void 0*/}

                {Main.Instance.ReportPState.specificQuery && Main.Instance.ReportPState.compareDate ? 
                <div
                    className = "control-panel"
                    style = {Main.Instance.ReportPState.specificQuery ? 
                            {marginTop: '20px', width: '49%', float: 'left'} : {marginTop: '20px'}}
                >
                    <button 
                        className = "btn btn-primary btn-xs mt5" // ml20
                        title = {Assets.ListMoveTop}
                        onClick = {() => FirstPage2()}
                    >
                        <i className = {Assets_.faFirstButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveLeft}
                        onClick = {() => PrevPage2()}
                    >
                        <i className = {Assets_.faPrevButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveRight}
                        onClick = {() => NextPage2()}
                    >
                        <i className = {Assets_.faNextButtonClass}/>
                    </button>
                    <button 
                        className = "btn btn-primary btn-xs mt5"
                        title = {Assets.ListMoveBottom}
                        onClick = {() => LastPage2()}
                    >
                        <i className = {Assets_.faLastButtonClass}/>
                    </button>
                    
                    {bq2}

                    <span className = "label label-success table-label mt5">
                    {Assets.all + ': '}
                    {Main.Instance.ReportPState.count2 ? Main.Instance.ReportPState.count2 : 0}
                    </span>
                    <span className = "label label-success table-label mt5">
                    {Assets.showingShort}
                    {Main.Instance.ReportPState.count2 ? Main.Instance.ReportPState.pNum2 * 
                    Main.Instance.ReportPState.pSize2 + 1 : 0}{' - '}
                    {Main.Instance.ReportPState.count2 ? 
                    ((Main.Instance.ReportPState.pNum2 + 1) * 
                    Main.Instance.ReportPState.pSize2 > Main.Instance.ReportPState.count2 ? 
                    Main.Instance.ReportPState.count2 : 
                    (Main.Instance.ReportPState.pNum2 + 1) * Main.Instance.ReportPState.pSize2) : 0}
                    </span>
                    <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.ReportPState.count2 ? 
                    Main.Instance.ReportPState.pNum2 + 1 :0}{' / '}
                    {Main.Instance.ReportPState.count2 ? 
                    Math.trunc((Main.Instance.ReportPState.count2 - 1) /
                    Main.Instance.ReportPState.pSize2 + 1) : 0}
                    </span>
                </div> : void 0}
            </div>
            {/*Main.Instance.ReportPState.Data && Main.Instance.ReportPState.player ? alert (JSON.stringify(Main.Instance.ReportPState.Data[0])) : void 0*/}
            {!Main.Instance.ReportPState.specificQuery ?
            Main.Instance.ReportPState.Data ?
            <>
            <br/>
            {Main.Instance.ReportPState.player /*&& Main.Instance.DatabaseDataState.Player*/ ? 
            <h3>
                {'Detailed information about player ' + Main.Instance.ReportPState.player + ' (' + Main.Instance.ReportPState.playerCurrency + ')'}
                {/*'), ' + Main.Instance.DatabaseDataState.Player.level_ + ' (progress bar ' + TableUtils.GetPercentage(Main.Instance.DatabaseDataState.Player) + ')'*/}
            </h3> 
            : void 0}
            {/* {minWidth: 100, Header: 'Event time', accessor: 'aggregate_time', headerClassName: 'th-left'}, */}
            {Main.Instance.ReportPState.player ?
                <ReactTable 
                    data = {Main.Instance.ReportPState.Data}
                    columns = {[
                        {minWidth: 150, Header: Assets.EventDatetime + ' (' + Assets_.UTC + ')', accessor: 'timestamp', sortable: false,
                        headerClassName: 'th-left', Cell: (row) => row.value + ' ' + row.original.aggregate_time},
                        {minWidth: 100, Header: Assets.Bet, accessor: 'sourcevalue', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Event, accessor: 'eventtype', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Odds, accessor: 'custom', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Win, accessor: 'sourcevalue2', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Net, accessor: 'net', headerClassName: 'th-left', Cell: (row) => row.value.toFixed(2)},
                        {minWidth: 100, Header: Assets.Points, accessor: 'points_total', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.GameID, accessor: 'gamerefid', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Withdrawal, accessor: 'withdrawal', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Deposit, accessor: 'deposit', headerClassName: 'th-left'},
                        {minWidth: 30, Header: '%', accessor: 'percentage', headerClassName: 'th-left'},
                        {minWidth: 100, Header: Assets.Level, accessor: 'level', headerClassName: 'th-left', Cell: (row) => row.value + ' ' + row.original.sublevel},
                        // {minWidth: 30, Header: 'Sub', accessor: 'sublevel', headerClassName: 'th-left'},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    defaultPageSize = {Main.Instance.ReportPState.count < Main.Instance.ReportPState.pSize ? Main.Instance.ReportPState.count : Main.Instance.ReportPState.pSize}
                />
            :
            <ReactTable
                data = {Main.Instance.ReportPState.Data}
                columns = {[
                    {minWidth: 90, Header: Assets.PlayerId, accessor: 'playerid', headerClassName: 'th-left', Cell: (row) => TableUtils.PlayerClick (row, PlayerClick)},
                    {minWidth: 60, Header: Assets.Available, accessor: 'ap', headerClassName: 'th-left', Cell: (row) => (+row.value).toFixed(0)},
                    {minWidth: 60, Header: Assets.Coins, accessor: 'tp', headerClassName: 'th-left', Cell: (row) => (+row.value).toFixed(0)},
                    {minWidth: 80, Header: Assets.Wagered, accessor: 'wagered', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 60, Header: Assets.Wagers, accessor: 'wagers', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 60, Header: Assets.Average, accessor: 'apbs', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 80, Header: Assets.WonAmount, accessor: 'won', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 60, Header: Assets.Wins, accessor: 'wins', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {width: 80, Header: Assets.Level, accessor: 'level_', headerClassName: 'th-left'},
                    {width: 150, Header: Assets.FirstEvent, accessor: 'event_first', headerClassName: 'th-left'},
                    {width: 150, Header: Assets.LastEvent, accessor: 'event_last', headerClassName: 'th-left'},
                    {minWidth: 80, Header: Assets.Withdrawal, accessor: 'withdrawal', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 70, Header: Assets.Deposits, accessor: 'deposit', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 90, Header: Assets.Points, accessor: 'points', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 30, Header: '%', accessor: 'playerid', headerClassName: 'th-left', Cell: (row) => TableUtils.getPercentage(row)},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize = {Main.Instance.ReportPState.count < Main.Instance.ReportPState.pSize ? Main.Instance.ReportPState.count : Main.Instance.ReportPState.pSize}
            />}
            </>            
            : getPlayersPointsCount() : void 0}

            {Main.Instance.ReportPState.specificQuery && !Main.Instance.ReportPState.compareDate && 
            Main.Instance.ReportPState.Data1 ?
            <span>Data1</span>
            : void 0}

            {Main.Instance.ReportPState.specificQuery && Main.Instance.ReportPState.compareDate &&
            Main.Instance.ReportPState.Data1 ?
            <span>Data1</span>
            : void 0}

            {Main.Instance.ReportPState.specificQuery && Main.Instance.ReportPState.compareDate &&
            Main.Instance.ReportPState.Data2 ?
            <span>Data2</span>
            : void 0}
        </div>
    )
}

function ExportCSV() {
    // console.log (Main.Instance.ReportPState.Data)

    // let s: string = 'ID,Currency,AvailableCoins,TotalCoins,Wagered,Wagers,Average,WonAmount,Wins,Level,FirstEvent,LastEvent,Withdrawal,Deposits,Points\n'
    let s: string = Assets.ID+','+Assets.Currency+','+Assets.AvailableCoins+','+Assets.TotalCoins+','+Assets.Wagered+','+Assets.Wagers+','+Assets.Average+','+Assets.WonAmount+','+Assets.Wins+','+Assets.Level+','+Assets.FirstEvent+','+Assets.LastEvent+','+Assets.Withdrawal+','+Assets.Deposits+','+Assets.Points+'\n'
    for (const item of Main.Instance.ReportPState.Data) {
        s += item['playerid'] + ',' + item['localcurrency'] + ',' + item['ap'] + ',' + item['tp'] + ',' + item['wagered'] + ',' + item['wagers'] + ',' + item['apbs'] + ',' + item['won'] + ',' 
        + item['wins'] + ',' + item['level_'] + ',' + item['event_first'] + ',' + item['event_last'] + ',' + item['withdrawal'] + ',' + item['deposit'] + ',' + item['points'] + '\n'
    }

    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = 'gamanza-report.csv'
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}