import {Logic} from '../Type'

export class SettingsState extends Logic.Type {
    public username: string
    public password: string
    public password_repeat: string
    // password_old: string
    public username_saved: string

    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}