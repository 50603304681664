import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

interface StateGifts {
    title?: string
    active: boolean
}

export class SelfAssessmentGiftsPopup extends React.Component<{}, StateGifts> {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amSelfAssessmentGifts, {
            title: this.state.title,
            active: this.state.active,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddSelfAssessmentGifts}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Title}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({title: e.target.value})}
                                type = "text"
                                value = {this.state.title}
                                style = {{width: '800px'}}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({active: !this.state.active})} on = {this.state.active} />
                            <div className = "fl mt2">{Assets.Active}</div>
                        </div>

                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateAssessmentSettings {
    min?: number
    max?: number
    comment?: string
    riskLevel: RiskLevel
    resolvedAutomatically: boolean
    shownPlayer: {}
}

export class SelfAssessmentCriteriaPopup extends React.Component<{}, StateAssessmentSettings> {
    constructor(props) {
        super(props)
        this.state = {
            shownPlayer: {},
            riskLevel: RiskLevel.Low,
            resolvedAutomatically: true,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amSelfAssessmentCriteria, {
            min: this.state.min,
            max: this.state.max,
            comment: this.state.comment,
            riskLevel: this.state.riskLevel,
            shownPlayer: this.state.shownPlayer,
        })        

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})
    }

    public getShownPlayer(): JSX.Element[] {
        const gsp: JSX.Element[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
            gsp.push (
            <div key = {Main.Instance.DatabaseDataState.custom.general.languages[i].locale} className = "clearfix mt5 mb">
                <div className = "fl mt2 mr">{Assets.ShownToPlayer} - {Main.Instance.DatabaseDataState.custom.general.languages[i].locale} - {Main.Instance.DatabaseDataState.custom.general.languages[i].name}</div>
                <input
                    className = "input-popup pr0"
                    onChange = {(e) => {const shownPlayer = Object.assign({}, this.state.shownPlayer); shownPlayer[Main.Instance.DatabaseDataState.custom.general.languages[i].short] = e.target.value; this.setState({shownPlayer})}}
                    type = "text"
                    value = {this.state.comment}
                    style = {{width: '800px'}}
                />
            </div>,
            )
        }
        return gsp
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddAssessmentCriteriaRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Minimum}</div>
                            <input
                                className = "input-popup pr0 width60"
                                onChange = {(e) => this.setState({min: +e.target.value})}
                                type = "number"
                                min = {0}
                                value = {this.state.min}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Maximum}</div>
                            <input
                                className = "input-popup pr0 width60"
                                onChange = {(e) => this.setState({max: +e.target.value})}
                                type = "number"
                                min = {this.state.min}
                                value = {this.state.max}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl">{Assets.ResolvedAutomatically} ({Assets.Autoresolved})&nbsp;&nbsp;</div>
                            <div className = "fl mr" style = {{marginTop: '-3px'}}>
                                <Switch on = {this.state.resolvedAutomatically} onClick = {() => this.setState({resolvedAutomatically: !this.state.resolvedAutomatically})}/>
                            </div>
                        </div>
                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Comment}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({comment: e.target.value})}
                                type = "text"
                                value = {this.state.comment}
                                style = {{width: '800px'}}
                            />
                        </div>
                        {this.getShownPlayer()}
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateAssessmentQuestionaire {
    text: any
    points: number[]
    ratingPoints: number[]
    active: boolean,
    language: number
}

export class SelfAssessmentQuestionairePopup extends React.Component<{}, StateAssessmentQuestionaire> {
    constructor(props) {
        super(props)
        const text = {}
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
            text[Main.Instance.DatabaseDataState.custom.general.languages[i].short] = ''
        }
        this.state = {
            text,
            points: [],
            ratingPoints: [],
            active: false,
            language: 0,
        }
    }

    public getLanguage(language: number) {
        return Main.Instance.DatabaseDataState.custom.general.languages[language].short
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amSelfAssessmentQuestions, {
            text: this.state.text,
            points: this.state.points,
            ratingPoints: this.state.ratingPoints,
            active: this.state.active,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})
    }
/*
    handleText () {
        let textArr: string[] = this.state.text
        textArr[Main.Instance.RiskManagementState.language] = this.state.textTemp
        this.setState({text: textArr})
    }
*/
    public changeRatingPoints(i: number, value: string) {
        const ratingPoints: number[] = this.state.ratingPoints
        if (+value >= 0 && +value < 100) {
            ratingPoints[i] = +value
            this.setState({ratingPoints})
        }
    }
    
    public getPoints(value: number[], Points?: boolean): JSX.Element[] {
        const points: JSX.Element[] = []
        if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.responsibleGaming && Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale) {
            for (let i: number = 0; /*i < value.length &&*/ i < Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max - Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min + 1; i++) {
                points.push(
                    <input
                        style = {Points ? {textAlign: 'center'} : {}}
                        disabled = {Points ? true : false}
                        title = {Points ? Assets.Answer + ' ' + i + ' (' + Assets.Points.toLowerCase() + ')' : Assets.RatingPoints}
                        min = "0"
                        max = "99"
                        type = "number"
                        key = {i.toString()}
                        value = {Points ? value[i] !== undefined ? value[i].toString() : '' : value !== undefined && value[i] !== undefined ? value[i].toString().charAt(0) === '0' && value[i].toString().charAt(1) === '.' ? value[i].toString().substring(1, value[i].toString().length) : value[i].toString() : ''}
                        className = {Points ? 'points-question' : 'points-answer'}
                        onChange = {(e) => Points ? void 0 : this.changeRatingPoints(i, e.target.value)}
                    />,
                )
            }
        }
        return points
    }
/*
    getMultipliers(value: number[]): JSX.Element[] {
        const multipliers: JSX.Element[] = []
        multipliers.push(<button onClick = {() => this.changeValues(0)} key = "reverse" className = "btn btn-primary btn-xs mr0 br0"><FontAwesomeIcon icon = {faExchangeAlt}/></button>)
        multipliers.push(<button onClick = {() => this.changeValues(13)} key = "<" className = "btn btn-primary btn-xs mr0 br0"><i className = {Assets_.faPrevButtonClass}/></button>)
        multipliers.push(<button onClick = {() => this.changeValues(14)} key = ">" className = "btn btn-primary btn-xs mr br0"><i className = {Assets_.faNextButtonClass}/></button>)
        
        multipliers.push(<button onClick = {() => this.changeValues(1)} key = "x2" className = "btn btn-warning btn-xs mr0 br0">x2</button>)
        multipliers.push(<button onClick = {() => this.changeValues(2)} key = "x3" className = "btn btn-warning btn-xs mr0 br0">x3</button>)
        multipliers.push(<button onClick = {() => this.changeValues(3)} key = "x5" className = "btn btn-warning btn-xs mr br0">x5</button>)
        
        multipliers.push(<button onClick = {() => this.changeValues(4)} key = "y2" className = "btn btn-danger btn-xs mr0 br0">/2</button>)
        multipliers.push(<button onClick = {() => this.changeValues(5)} key = "y3" className = "btn btn-danger btn-xs mr0 br0">/3</button>)
        multipliers.push(<button onClick = {() => this.changeValues(6)} key = "y5" className = "btn btn-danger btn-xs mr br0">/5</button>)
    
        multipliers.push(<button onClick = {() => this.changeValues(7)} key = "z2" className = "btn btn-success btn-xs mr0 br0">+1</button>)
        multipliers.push(<button onClick = {() => this.changeValues(8)} key = "z3" className = "btn btn-success btn-xs mr0 br0">+2</button>)
        multipliers.push(<button onClick = {() => this.changeValues(9)} key = "z4" className = "btn btn-success btn-xs mr br0">+3</button>)
    
        multipliers.push(<button onClick = {() => this.changeValues(10)} key = "w2" className = "btn btn-info btn-xs mr0 br0">-1</button>)
        multipliers.push(<button onClick = {() => this.changeValues(11)} key = "w3" className = "btn btn-info btn-xs mr0 br0">-2</button>)
        multipliers.push(<button onClick = {() => this.changeValues(12)} key = "w4" className = "btn btn-info btn-xs mr0 br0">-3</button>)
    
        return multipliers
    }
*/
/*
    changeValues(which: number) {
        let points: number[] = []
        if (which === 0) {
            for (let i: number = this.state.points.length - 1; i >= 0; i--) {
                points.push (this.state.points[i])
            }
        } else if (which === 13) {
            for (let i: number = 1 ; i < this.state.points.length; i++) {
                points.push (this.state.points[i])
            }
            points.push (this.state.points[0])
        } else if (which === 14) {
            points.push (this.state.points[this.state.points.length - 1])
            for (let i: number = 1 ; i < this.state.points.length; i++) {
                points.push (this.state.points[i - 1])
            }
        } else {
            for (let i: number = 0; i < this.state.points.length; i++) {
                if (which === 1) points.push (this.state.points[i] * 2)
                else if (which === 2) points.push (this.state.points[i] * 3)
                else if (which === 3) points.push (this.state.points[i] * 5)
                else if (which === 4) points.push (this.state.points[i] / 2)
                else if (which === 5) points.push (this.state.points[i] / 3)
                else if (which === 6) points.push (this.state.points[i] / 5)
                else if (which === 7) points.push (this.state.points[i] + 1)
                else if (which === 8) points.push (this.state.points[i] + 2)
                else if (which === 9) points.push (this.state.points[i] + 3)
                else if (which === 10) points.push (this.state.points[i] - 1)
                else if (which === 11) points.push (this.state.points[i] - 2)
                else if (which === 12) points.push (this.state.points[i] - 3)
    
                if (points[points.length - 1] < 0 || points[points.length - 1] - Math.floor (points[points.length - 1]) !== 0) return
            }
        }
    
        this.setState({points})
    }
*/
    public getLanguageButtons(): JSX.Element[] {
        const buttons: JSX.Element[] = []
        if (Main.Instance.DatabaseDataState.custom) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
            buttons.push (
                <button
                    key = {i.toString()}
                    type = "button"
                    className = {this.state.language === i ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}
                    onClick = {() => this.setState({language: i})}
                >
                    {Main.Instance.DatabaseDataState.custom.general.languages[i].locale} - {Main.Instance.DatabaseDataState.custom.general.languages[i].name}
                </button>,
            )
        }
        }

        return buttons
    }

    public setPointValues() {
        const points: number[] = []
        const ratingPoints: number[] = []
        if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.responsibleGaming && Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max - Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min + 1; i++) {
                    points.push(i+1)
                    ratingPoints.push(0)
                }
            }
        
        this.setState({points, ratingPoints})
    }

    public render() {

        if ((!this.state.points || this.state.points.length === 0) && (!this.state.ratingPoints || this.state.ratingPoints.length === 0)) {
            this.setPointValues()
        }
        
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddQuestion}</h4>


                        <div className = "td-left">
                            <div>{this.getLanguageButtons()}</div>
                            <textarea
                                style = {{height: '40px', marginTop: '-1px', lineHeight:'14px', padding: '5px'}}
                                className = "table-input-text"
                                onChange = {(e) => {
                                    const text: any = Object.assign ({}, this.state.text)
                                    text[this.getLanguage(this.state.language)] = e.target.value
                                    this.setState({text})

                                    // this.setState({textTemp: e.target.value})
                                }}
                                // onBlur = {() => this.handleText()}
                                defaultValue = {this.state.text[this.getLanguage(this.state.language)]}
                            />
                        </div>

                        <div>
                            <div>{Assets.sap1}</div>
                            <div>{this.getPoints(this.state.points, true)}</div>
                            <div>{this.getPoints(this.state.ratingPoints)}</div>
                            {/*<div>{this.getMultipliers(this.state.points)}</div>*/}
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({active: !this.state.active})} on = {this.state.active} />
                            <div className = "fl mt2">{Assets.Active}</div>
                        </div>

                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddSelfAssessment: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


