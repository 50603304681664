import * as React from 'react'
// import {mainRafflePrizeRadiusLarge, secondaryRafflePrizeRadiusLarge} from '../Reports/ExpandSwitzerland'
// import {mainRafflePrizeRadius, secondaryRafflePrizeRadius} from '../Reports/ScaleSwitzerland'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {ChartContent,Players} from './Dashboard'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {SelectedView} from '../../../Logic/Various/Dashboard'
import {mockupCurrentPlayers} from './Dashboard'
// import {SwitzerlandCantonsData} from '../Reports/SwitzerlandData'
import {threshold2Pallette, densityPallette, TOP, ToP} from '../Reports/SwitzerlandResources'
import {faUser/*, faGift*/} from '@fortawesome/free-solid-svg-icons'

export function getColorByCanton(canton: number): string {
    for (let i: number = 0; i < threshold2Pallette.length; i++ ) { if (canton < threshold2Pallette[i]) { return densityPallette[i] } }
    return densityPallette[threshold2Pallette.length]
}

export function getHighlightByCanton(highlight: number): string {
    return highlight ? '#5cb85c' : '#fff'
}

export function getChartLegend(chartContent: ChartContent): JSX.Element {
    const legend: JSX.Element[] = []
    legend.push (
        <div className = "clearfix" key = "-1" style = {{fontSize: '14px', textAlign: 'left'}}>
            <div className = "fl" style = {{width: '14px', marginRight: '5px', marginTop: '3px', background: 'transparent'}}>
                &nbsp;
            </div>
            <div className = "fl bold" style = {{width: '50px'}}>{chartContent.dataKey.toUpperCase()}</div>
            <div className = "fl bold">{(chartContent.dataValueIcon ? <FontAwesomeIcon title = {chartContent.dataValue} icon = {chartContent.dataValueIcon}/> : chartContent.dataValue.toUpperCase())}</div>
        </div>,
    )
    legend.push (<hr key = "chart-hr" className = "chart-hr"/>)
    for (let i: number = 0; i < chartContent.data.length; i++) {
        legend.push (
            <div className = "clearfix" key = {i.toString()} style = {{fontSize: '14px', textAlign: 'left'}}>
                <div className = "fl" style = {{height: '14px', width: '14px', marginRight: '5px', marginTop: '3px', borderRadius: '50%', background: chartContent.palette ? chartContent.palette[i] : '#fff'}}>
                    &nbsp;
                </div>
                <div className = "fl" style = {{width: '50px'}}>{chartContent.data[i][chartContent.dataKey]}</div>
                <div className = "fl">{chartContent.data[i][chartContent.dataValue]}</div>
            </div>,
        )
    }
    return <div style = {{borderRadius: '0px', position: 'absolute', top: '10px', width: '120px', right: '10px', border: '1px solid #999', background: '#ddd', padding: '5px'}}>{legend}</div>
}
/*
export function getMapLegendRight(players_: Players, group: SelectedView, index: number, subtype: string, expandable?: boolean): JSX.Element {
    const legend: JSX.Element[] = []
    // console.log (JSON.stringify(cantonal))
    // legend.push (<div key = "title" style = {{fontSize: '14px', fontWeight: 600}}>{Assets.Legend}</div>)
    for (let i: number = 0; i < SwitzerlandCantonsData.length; i++) {
        legend.push (
            <div title = {SwitzerlandCantonsData[i].name + (players_ && players_.cantonal && players_.cantonal.cantons ? ' - ' + players_.cantonal.cantons[i] + ' players' : '')} className = "clearfix" key = {i.toString()} style = {{fontSize: '14px', textAlign: 'left'}}>
                <div className = "fl" style = {{height: '12px', width: '12px', marginRight: '3px', marginTop: '1px', borderRadius: '50%', background: players_ && players_.cantonal && players_.cantonal.cantons ? getColorByCanton(players_.cantonal.cantons[i]) : players_ && players_.cantonal && players_.cantonal.cantonsHighlight ? getHighlightByCanton(players_.cantonal.cantonsHighlight[i]) : '#fff'}}>
                    &nbsp;
                </div>
                <div style = {{fontSize: '10px', width: '1px', lineHeight: '14px'}} className = "fl">{SwitzerlandCantonsData[i].code.substring(3,5)}</div>
            </div>
        )
    }
    return (
        <div
            style = {{
                borderRadius: '0px',
                position: 'absolute',
                top: '5px',
                height: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 'auto' : '172px',
                overflowY: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 'hidden' : 'scroll',
                overflowX: 'hidden', width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '40px' : '50px',
                right: '5px',
                border: '1px solid #999',
                background: '#ddd',
                padding: '2px'
            }}
        >
            {legend}
        </div>
    )
}
*/
// this legend should be showing the meanings of each color, show in bottom right corner
export function getMapLegendBottom(players_: Players, group: SelectedView, index: number, subtype: string, expandable?: boolean): JSX.Element {
    const legend: JSX.Element[] = []
    const width: string = group === SelectedView.CurrentOverview ? Main.Instance.DashboardState.expandButton[group] === index && expandable ? '389px' : '155px' : undefined
    const height: string = group === SelectedView.CurrentOverview ? Main.Instance.DashboardState.expandButton[group] === index && expandable ? '50px' : '40px' : undefined
    // the legend is different for each use. first if we have cantonal.cantons === CHOROPLETH
    if (players_ && players_.cantonal && players_.cantonal.cantons && group === SelectedView.CurrentOverview) {        
        for (let i: number = 0; i <= threshold2Pallette.length; i++) {
            legend.push(
                <div key = {i.toString()} className = "fl" title = {(i === threshold2Pallette.length ? '≥' + threshold2Pallette[i - 1] : (i === 0 ? i : threshold2Pallette[i - 1]) + ' - ' + (threshold2Pallette[i] - 1)) + ' players per canton'}>
                    <div style = {{width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '43px' : '17px', height: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '20px' : '11px', background: densityPallette[i]}}>&nbsp;</div>
                </div>,
            )
        }
        for (let i: number = 0; i <= threshold2Pallette.length; i++) {
            legend.push(
                <div key = {i.toString() + '_'} className = "fl">
                    <div style = {{width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '43px' : i < 4 ? '17px' : '21px', display: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 'block' : /*i % 2 === 1 ||*/ i === 8 ? 'none' : 'block', fontSize: Main.Instance.DashboardState.expandButton[group] === index && expandable && i > 4 && i < 8 ? '11px' : '12px', lineHeight: '12px', textAlign: 'center'}}>
                        {Main.Instance.DashboardState.expandButton[group] === index && expandable ?
                        i === threshold2Pallette.length ? '≥' + threshold2Pallette[i - 1] : (i === 0 ? i : threshold2Pallette[i - 1]) + '-' + (threshold2Pallette[i] - 1) 
                        : i === threshold2Pallette.length ? threshold2Pallette[i - 1] : threshold2Pallette[i]}
                    </div>
                </div>,
            )        
        }
        legend.push (<div key = "legend" style = {{textAlign: 'center', fontSize: '12px'}}>{subtype}</div>)
    }/* else if (group === SelectedView.Raffles) {
        legend.push (<div className = "clearfix" key = "legend1" style = {{paddingTop: '3px', textAlign: 'left', fontSize: '12px'}}><div className = "mr5px fl ml5px" style = {{background: 'rgb(92, 184, 92)', borderRadius: '50%', height: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 2 * mainRafflePrizeRadiusLarge : 2 * mainRafflePrizeRadius + 'px', width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 2 * mainRafflePrizeRadiusLarge : 2 * mainRafflePrizeRadius + 'px'}}>&nbsp;</div><div className = "fl mr5px" style = {{textAlign: 'left', lineHeight: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '20px' : '17px'}}>{Assets.MainRafflePrize}</div></div>)
        legend.push (<div className = "clearfix" key = "legend2" style = {{textAlign: 'left', fontSize: '12px'}}><div className = "fl" style = {{background: 'rgb(92, 184, 92)', marginLeft: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '10px' : '10px', marginRight: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '10px' : '10px', marginTop: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '5px' : '6px', lineHeight: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '20px' : '17px', borderRadius: '50%', height:  Main.Instance.DashboardState.expandButton[group] === index && expandable ? 2 * secondaryRafflePrizeRadiusLarge : 2 * secondaryRafflePrizeRadius+ 'px', width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? 2 * secondaryRafflePrizeRadiusLarge : 2 * secondaryRafflePrizeRadius + 'px'}}>&nbsp;</div><div className = "fl mr5px" style = {{textAlign: 'left', lineHeight: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '20px' : '17px'}}>{Assets.SecondaryRafflePrize}</div></div>)
    }*/
    
    return legend.length === 0 ? <div/> : (
        <div
            style = {{
                borderRadius: '0px',
                position: 'absolute',
                bottom: '21px',
                width: width ? width : '',
                height: height ? height : '',
                right: '5px',
                border: '1px solid #999',
                background: '#ddd',
                padding: '0px',
            }}
        >
            {legend}
        </div>
    )
}

// also, this legend should display the number of all players in bottom left corner
export function getMapLegendLeft(players_: Players, group: SelectedView, index: number, subtype: string, expandable?: boolean): JSX.Element {
    const legend: JSX.Element[] = []

    if (group === SelectedView.CurrentOverview) {
        // the legend is different for each use. first if we have cantonal.cantons === CHOROPLETH    
        if (players_ && players_.cantonal && players_.cantonal.cantons) {
            legend.push(<div key = "legend" title = {mockupCurrentPlayers + ' players'}><FontAwesomeIcon icon = {/*subtype && subtype.startsWith('players') ?*/faUser/*: faQuestion*/}/>&nbsp;{mockupCurrentPlayers}</div>)
        } else if (players_ && players_.players) {
            legend.push(<div key = "legend" title = {players_.players.length + ' players'}><FontAwesomeIcon icon = {/*subtype.startsWith('players') ?*/faUser/*: faQuestion*/}/>&nbsp;{players_.players.length}</div>)
        } 
    }/* else if (group === SelectedView.Raffles) {
        legend.push(<div key = "legend" title = {players_.players.length + ' rewards'}><FontAwesomeIcon icon = {faGift}/>&nbsp;{players_.players.length}</div>)
    }*/

    return legend.length === 0 ? <div/> : (
        <div
            style = {{
                borderRadius: '0px',
                position: 'absolute',
                left: '5px',
                bottom: '21px',
                border: '1px solid #999',
                background: '#ddd',
                padding: '0px 5px',
                fontSize: '16px',
                fontWeight: 700,
            }}
        >
            {legend}
        </div>
    )
}

function Click(group: SelectedView, index: number, value: number) {
    const click: number[][] = Object.assign([[]], Main.Instance.DashboardState.click)
    // console.log (JSON.stringify(click))
    click[group][index] = click[group][index] === value ? -1 : value
    Logic.Type.New (Main.Instance.DashboardState, {click})
}

// TOP LEGEND
export function getMapLegendTop(players_: Players, group: SelectedView, index: number, subtype: string, expandable?: boolean): JSX.Element {
    const legend: JSX.Element[] = []

    if (group === SelectedView.CurrentOverview) {
        legend.push(
            <button
                title = {Assets.ClearSelection}
                key = "-1"
                style = {{borderRadius: '0px', lineHeight: '11px', width: '100%', margin: '0px', paddingLeft: '1px', paddingRight: '1px'}}
                disabled = {Main.Instance.DashboardState.click[group][index] === -1}
                className = {Main.Instance.DashboardState.click[group][index] === -1 ? 'btn btn-danger btn-xs' : 'btn btn-default btn-xs'}
                onClick = {() => Click(group, index, -1)}
            >
                {Assets.TOP + ' ' + Assets.Clear}
            </button>,
        )

        // the legend is different for each use. first if we have cantonal.cantons === CHOROPLETH
        if (players_ && players_.cantonal && players_.cantonal.cantons || players_ && players_.players) {        
            for (let i: number = 0; i < (players_.cantonal && players_.cantonal.cantons ? TOP.length : ToP.length); i++) {
                legend.push(
                    <button
                        title = {Assets.Select + ' ' + Assets.TOP + ' ' + (players_.cantonal && players_.cantonal.cantons ? TOP[i] : ToP[i]) + ' ' + (subtype ? subtype.split(' ')[0] : '')}
                        key = {i.toString()}
                        style = {{borderRadius: '0px', lineHeight: '11px', width: '100%', margin: '0px'}}
                        disabled = {Main.Instance.DashboardState.click[group][index] === i}
                        className = {Main.Instance.DashboardState.click[group][index] === i ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                        onClick = {() => Click(group, index, i)}
                    >
                        {Assets.TOP + ' ' + (players_.cantonal && players_.cantonal.cantons ? TOP[i] : ToP[i])}
                    </button>,
                )
            } 
        }
    } 
    return legend.length === 0 ? <div/> : (
        <div
            style = {{
                borderRadius: '0px',
                position: 'absolute',
                top: '5px',
                width: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '65px' : '65px',
                // height: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '50px' : '40px',
                right: Main.Instance.DashboardState.expandButton[group] === index && expandable ? '50px' : '60px',
                border: '1px solid #999',
                background: '#ddd',
                padding: '0px',
                fontSize: '16px',
                lineHeight: '13px',
                fontWeight: 700,
            }}
        >
            {legend}
        </div>
    )
}