import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Assets from '../../Assets'
// import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {AccountStatus} from '../../../Logic/Database/DatabaseData'

interface State {
    currency: 'N/A'|'CHF'|'EUR'|'USD'
    limit?: number
    oncePerPlayer: boolean
    oneTransaction: boolean
    withinHours?: number
    riskLevel: RiskLevel
    accountSuspension: boolean
    allowPlay: boolean
    allowDeposit: boolean
    allowWithdraw: boolean
    verificationStatus: AccountStatus
}
interface Props {
    deposit: boolean // DEPOSIT => true, WITHDRAWAL => false
}

export default class LargeValueTransactionModal extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            currency: 'CHF',
            oncePerPlayer: false,
            oneTransaction: false,
            riskLevel: RiskLevel.Low,
            accountSuspension: false,
            verificationStatus: AccountStatus.Active,
            allowPlay: true,
            allowDeposit: true,
            allowWithdraw: true,
        }
    }

    public AddDeposit() {
        DbRoutes.wsBasicInsert(Config.amLargeValueDeposits, {
            currency: this.state.currency,
            limit: this.state.limit,
            oncePerPlayer: this.state.oncePerPlayer,
            oneTransaction: this.state.oneTransaction,
            withinHours: this.state.withinHours,
            riskLevel: this.state.riskLevel,
            accountSuspension: this.state.accountSuspension,
            verificationStatus: this.state.verificationStatus,
            allowDeposit: this.state.allowDeposit,
            allowWithdraw: this.state.allowWithdraw,
            allowPlay: this.state.allowPlay,
 
        })
        Logic.Type.New (Main.Instance.RiskManagementState, {showAddLargeDeposit: false})
    }

    public AddWithdrawal() {
        DbRoutes.wsBasicInsert(Config.amLargeValueWithdrawals, {
            currency: this.state.currency,
            limit: this.state.limit,
            oncePerPlayer: this.state.oncePerPlayer,
            oneTransaction: this.state.oneTransaction,
            withinHours: this.state.withinHours,
            riskLevel: this.state.riskLevel,
            accountSuspension: this.state.accountSuspension,
            verificationStatus: this.state.verificationStatus,
            allowDeposit: this.state.allowDeposit,
            allowWithdraw: this.state.allowWithdraw,
            allowPlay: this.state.allowPlay,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddLargeWithdrawal: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddLargeDeposit: false, showAddLargeWithdrawal: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{this.props.deposit? Assets.AddLargeValueDepositRules : Assets.AddLargeValueWithdrawalRules}</h4>
                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.Currency}</span>
                            <button 
                                className = "btn btn-xs"
                                // onClick = {() => ''}
                            >
                                {this.state.currency}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{this.props.deposit? Assets.DepositLimit: Assets.WithdrawalLimit}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({limit: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.limit}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({oncePerPlayer: !this.state.oncePerPlayer})} on = {this.state.oncePerPlayer} />
                            <div className = "fl mt2">{Assets.OncePerPlayer}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({oneTransaction: !this.state.oneTransaction})} on = {this.state.oneTransaction}/>
                            <div className = "fl mt2">{Assets.OneTransaction}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.WithinHours}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({withinHours: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.withinHours}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.AccountStatus}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + AccountStatus[this.state.verificationStatus]}
                            >
                                {/*AccountStatus[this.state.verificationStatus] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                AccountStatus[this.state.verificationStatus] === 'BlockedFull' ? 'Blocked Full' :
                                AccountStatus[this.state.verificationStatus] === 'BlockedPartial' ? 'Blocked Partial' :
                                AccountStatus[this.state.verificationStatus]*/}
                                {Assets.accountStatus[this.state.verificationStatus]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = {AccountStatus.Initial}
                                    max = {AccountStatus.ClosedActive}
                                    step = "1"
                                    defaultValue = "2"
                                    onChange = {(e) => this.setState({verificationStatus: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowPlay: !this.state.allowPlay})} on = {this.state.allowPlay}/>
                            <div className = "fl mt2">{Assets.AllowPlay}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowDeposit: !this.state.allowDeposit})} on = {this.state.allowDeposit}/>
                            <div className = "fl mt2">{Assets.AllowDeposit}</div>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({allowWithdraw: !this.state.allowWithdraw})} on = {this.state.allowWithdraw}/>
                            <div className = "fl mt2">{Assets.AllowWithdrawal}</div>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "0"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({accountSuspension: !this.state.accountSuspension})} on = {this.state.accountSuspension}/>
                            <div className = "fl mt2">{Assets.AccountSuspension}</div>
                        </div>                        
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddLargeDeposit: false, showAddLargeWithdrawal: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.props.deposit ? this.AddDeposit() : this.AddWithdrawal()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
