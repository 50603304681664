import {Logic} from '../Type'
export class ModalState extends Logic.Type {

    public ModalOperatorSites = false
    public ModalLevels = false
    public ModalWagerPlanCurrency = false
    public ModalWagerPlanGames = false
    public ModalLoyaltyFreeRoundsGames = false
    public ModalGames = false
    public ModalCurrency = false
    public ModalSites = false
/*
    req1 = false 
    req2 = false
    req3 = false 
    req4 = false
    req5 = false 
    req6 = false
    req7 = false 
    req8 = false
    req9 = false
    req10 = false
    req11 = false
    rew1 = false 
    rew2 = false 
    rew3 = false 
    rew4 = false
*/
}