import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import {Logic} from '../../../Logic/Type'
import ReactTable from 'react-table'
import { numberWithCommas } from '../../../Logic/Utils';
import * as Assets from '../../Assets'
// import * as TableUtils from '../../Reusables/TableUtils'
export function Settlement() {
    // console.log (Main.Instance.DatabaseDataState.settlementStream)
    return (
        <div>
            <div>
            <h3>Scandibet {Assets.SettlementReport.toLowerCase()}</h3>
            {Main.Instance.DatabaseDataState.settlementStream !== undefined ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.settlementStream}
                columns = {[
                    {minWidth: 150, Header: Assets.Month, accessor: 'month', headerClassName: 'th-left'},
                    {minWidth: 100, Header: Assets.Packages, accessor: 'packages', headerClassName: 'th-left', Cell: (row) => row.value},
                    {minWidth: 100, Header: Assets.Wagers, accessor: 'wagers', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 100, Header: Assets.Level_Ups, accessor: 'levelUps', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 100, Header: Assets.Sign_Ups, accessor: 'signUps', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 150, Header: Assets.RewardShopTransactions, accessor: 'rewardShopTransactions', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                    {minWidth: 100, Header: Assets.Total, accessor: 'total', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},

                ]}
                showPaginationBottom = {false}
                defaultPageSize = {Main.Instance.DatabaseDataState.settlementStream.length < 20 ? Main.Instance.DatabaseDataState.settlementStream.length : 20}
                
            /> : Main.Instance.DatabaseDataState.settlement ? void 0 : DbRoutes.getSettlement()}
            </div>
        </div>
    )
}