import {Logic} from '../Type'
// import {CustomSecurityRights} from '../UserManagement/UserManagement'
export class LoginState extends Logic.Type {
    // public init: boolean = false
    // public logout: boolean = true
    public securityAnswerCorrect: boolean = true
    public securityQuestion: number = 0
    public securityAnswer: string = ''
    public showPassword: boolean = false
    public Username: string = ''
    public Password: string = ''
    public Password1: string = ''
    public Password2: string = ''
    public Verified: boolean = false
    public role: string = ''
    public token: string = ''
    public id: number = 0
    // public id: string = '' // string ''
    public entityId: string = ''
    public entity: string = ''
    public wrongPassword: boolean = false
    public wrongApiKey: boolean = false
    public wrongEnvironment: boolean = false
    public email: string = ''
    public phone: string = ''
    public loginMethod: number = 0
    
    public gamanzaAdmin: boolean = false
    public gamanzaMagicHand: boolean = false
    public gdpr: boolean = false
    public support: boolean = false
    public operator: boolean = false
    public marketing: boolean = false
    public rfSpecialist: boolean = false
    public rgSpecialist: boolean = false
    public administrator: boolean = false
    public accounting: boolean = false
    public unused1: boolean = false // gamanzaAdmin
    public unused2: boolean = false // gamanzaGamesAdmin
    public unused3: boolean = false // download reports
    // public unused4: boolean
    // public unused5: boolean
    // public unused6: boolean
    public unused7: boolean // gamanzaMagicHand
    // public custom: CustomSecurityRights
    // Mail: string = ''
    public environmentsAllowed: boolean[]
    public oldPassword: string = ''
}