import * as React from 'react'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import * as Combinatorics from 'js-combinatorics'
import {Logic} from '../../Logic/Type'
import {Main} from '../../Logic/Main'
import {FormInputNumber} from './Inputs'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {isUserAdmin, injectSpaces} from '../../Logic/Utils'
import {SecurityRights} from '../../Logic/UserManagement/UserManagement'
import { RewardTypes } from '../../Logic/Database/rewards/rewardsEnums'
export function GetPercentage(player: any): string {
    // console.log (player)
    let percentage: number = 0
    let pointsMin: number = 0
    let pointsMax: number = 0
/*
    if (Main.Instance.DatabaseDataState.SubLevels)
    for (const item of Main.Instance.DatabaseDataState.SubLevels) {
        if (player.level_current > item.hierarchy || player.level_current === item.hierarchy && player.sublevel_current > item.sublevel_max) {
            pointsMin += item.level_value * (item.sublevel_max - item.sublevel_min + 1)
        } else if (player.level_current === item.hierarchy) {
            pointsMax = pointsMin + item.level_value * (item.sublevel_max - item.sublevel_min + 1)
            if (parseFloat(player.points) >= pointsMin && parseFloat(player.points) < pointsMax) {
                return ((parseFloat(player.points) - pointsMin) / (pointsMax - pointsMin) * 100).toFixed(0) + '%'
            }
        }
    }
    return Assets_.NA
*/
    if (Main.Instance.DatabaseDataState.SubLevels) {
    for (const item of Main.Instance.DatabaseDataState.SubLevels) {
        pointsMax = pointsMin + item.level_value * (item.sublevel_max - item.sublevel_min + 1)
        if (pointsMax >= parseFloat(player.points)) {
            while (pointsMin < parseFloat(player.points)) {
                pointsMin += item.level_value
            }
            pointsMin -= item.level_value
            percentage = (parseFloat(player.points) - pointsMin) / item.level_value * 100
            return Math.round(percentage).toFixed(0)
        }
        pointsMin = pointsMax
    }
    }

    return Assets_.NA
}
export function getPercentage(row: any): JSX.Element {
    let percentage: number = 0
    let pointsMin: number = 0
    let pointsMax: number = 0
/*    
    if (Main.Instance.DatabaseDataState.SubLevels)
    for (const item of Main.Instance.DatabaseDataState.SubLevels) {
        if (row.original.level_current > item.hierarchy || row.original.level_current === item.hierarchy && row.original.sublevel_current > item.sublevel_max) {
            pointsMin += item.level_value * (item.sublevel_max - item.sublevel_min + 1)
        } else if (row.original.level_current === item.hierarchy) {
            pointsMax = pointsMin + item.level_value * (item.sublevel_max - item.sublevel_min + 1)
            if (parseFloat(row.original.points) >= pointsMin && parseFloat(row.original.points) < pointsMax) {
                percentage = (parseFloat(row.original.points) - pointsMin) / (pointsMax - pointsMin) * 100
                return (<div>{Math.round(percentage)}</div>)
            }
        }
    }

    return <div>Assets_.NA</div>
*/
    if (Main.Instance.DatabaseDataState.SubLevels) {
    for (const item of Main.Instance.DatabaseDataState.SubLevels) {
        // console.log ('Points acc: ' + points)
        // console.log (item.hierarchy,item.sublevel_min,item.sublevel_max, item.level_value)
        pointsMax = pointsMin + item.level_value * (item.sublevel_max - item.sublevel_min + 1)
        // console.log ('*****', points, pointsMin, pointsMax)
        if (pointsMax >= parseFloat(row.original.points)) {
            // let diff: number = -1
            while (pointsMin < parseFloat(row.original.points)) {
                pointsMin += item.level_value
                // diff++
            }
            pointsMin -= item.level_value
            // console.log (points, pointsMin, pointsMax)
            percentage = (parseFloat(row.original.points) - pointsMin) / item.level_value * 100
            // console.log (item.hierarchy, item.sublevel_min + diff, Math.round(percentage))
            // result[0] = item.hierarchy
            // result[1] = item.sublevel_min + diff
            // result[2] = Math.round(percentage)
            return <div>{Math.round(percentage)}</div>
        }
        pointsMin = pointsMax
    }
    }

    return <div>{Assets_.NA}</div>
}

export function getPercentageLevelSublevel(points: any): number[] {
    const result: number[] = [0, 0, 0]
    let percentage: number = 0
    // let level: number
    // let sublevel: number
    let pointsMin: number = 0
    let pointsMax: number = 0
    // console.log (Main.Instance.DatabaseDataState.SubLevels)
    if (Main.Instance.DatabaseDataState.SubLevels) {
    for (const item of Main.Instance.DatabaseDataState.SubLevels) {
        // console.log ('Points acc: ' + points)
        // console.log (item.hierarchy,item.sublevel_min,item.sublevel_max, item.level_value)
        pointsMax = pointsMin + item.level_value * (item.sublevel_max - item.sublevel_min + 1)
        // console.log ('*****', points, pointsMin, pointsMax)
        if (pointsMax >= points) {
            let diff: number = -1
            while (pointsMin < points) {
                pointsMin += item.level_value
                diff++
            }
            pointsMin -= item.level_value
            // console.log (points, pointsMin, pointsMax)
            percentage = (parseFloat(points) - pointsMin) / item.level_value * 100
            // console.log (item.hierarchy, item.sublevel_min + diff, Math.round(percentage))
            result[0] = item.hierarchy
            result[1] = item.sublevel_min + diff
            result[2] = Math.round(percentage)
            return result
        }
        pointsMin = pointsMax
    }
    }

    return result
}

export function PlayerClick(row: any, playerClick): JSX.Element {
    if (row === undefined || row.value === undefined) { return <div/> }
    return (
        <button
            title = {Assets.DetailedPlayerInfo + ' (' + Assets.Player.toLowerCase() + ': ' + row.value + ')'}
            className = "btn btn-nothing btn-border3"
            style = {{marginRight: '0px', padding: '3px 5px'}}
            onClick = {() => playerClick(row.value, row.original.event_last, row.original)}
        >

            {row.value + ' ' + row.original.localcurrency}
        </button>
    )
}

export function getContractualPartnerType(type_id: number): string {
    if (type_id === 1) { return Assets.Operator }
    if (type_id === 2) { return Assets.GameProvider }
    if (type_id === 3) { return Assets.PaymentProvider }
    if (type_id === 4) { return Assets.ComplianceProvider }
    if (type_id === 5) { return Assets.SiteOwner }
    return Assets_.NA
}

export function getDuration(boost_seconds: any): string {
    let boost_h: number = 0
    let boost_m: number = 0
    let boost_s: number = parseInt(boost_seconds, 10)
    let duration: string = ''
    if (boost_s >= 3600) {
        boost_h = Math.floor (boost_s / 3600)
        boost_s -= boost_h * 3600
    }

    if (boost_s >= 60) {
        boost_m = Math.floor (boost_s / 60)
        boost_s -= boost_m * 60
    }

    // if (boost_h && duration.length > 0) duration += ' '
    if (boost_h) { duration += boost_h + 'h' }
    if (boost_m && duration.length > 0) { duration += ' ' }
    if (boost_m) { duration += boost_m + 'm' }
    if (boost_s && duration.length > 0) { duration += ' ' }   
    if (boost_s) { duration += boost_s + 's' }    
    return duration
}

export function getDraws(data: any, key: any): JSX.Element[] {
    const draws: JSX.Element[] = []
    // console.log (data[key]['draws'])

    draws.push(
        <div className = "clearfix" key = "-2">
            {+data[key]['draws'] === 1 ? Assets.d10 : +data[key]['draws'] + ' ' + Assets.d11}
        </div>,
    )
    if (data[key]['drawinfo']) {
        draws.push(
            <div className = "clearfix" key = "-1">
                {(+data[key]['draws'] - data[key]['drawinfo'].length) > 0 ?
                +data[key]['draws'] - data[key]['drawinfo'].length === 1 ?
                Assets.d12
                :
                (+data[key]['draws'] - data[key]['drawinfo'].length) + ' ' + Assets.d13 :
                Assets.d14}
            </div>,
        )
    } else { draws.push(<div className = "clearfix" key = "-1">{Assets.d15}</div>) }

    if (data[key]['drawinfo']) {
    for (let i: number = 0; i < data[key]['drawinfo'].length; i+= data[key]['combos'] * data[key]['s']) {
        draws.push(
            <div className = "clearfix" key = {i.toString()}>
            <div className = "fl" style = {{lineHeight: '31px', fontSize: '16px', marginRight: '5px', width: '200px'}}>
            {Assets.Draw + ' #' + (i / (data[key]['combos'] * data[key]['s']) + 1) + ': ' + data[key]['drawinfo'][i].drawdateutc.substring(8,10) + '-' +
            data[key]['drawinfo'][i].drawdateutc.substring(5,7) + '-' + data[key]['drawinfo'][i].drawdateutc.substring(0,4)}
            </div>
            {data[key]['drawinfo'][i].numbers && data[key]['drawinfo'][i].numbers.length ? getNumbers(data[key]['drawinfo'][i].numbers, 0) : void 0}
            {data[key]['drawinfo'][i].extranumbers && data[key]['drawinfo'][i].extranumbers.length ? getNumbers(data[key]['drawinfo'][i].extranumbers, 1) : void 0}
            {data[key]['drawinfo'][i].bonusnumbers && data[key]['drawinfo'][i].bonusnumbers.length ? getNumbers(data[key]['drawinfo'][i].bonusnumbers, 2) : void 0}
            {data[key]['drawinfo'][i].refundnumbers && data[key]['drawinfo'][i].refundnumbers.length ? getNumbers(data[key]['drawinfo'][i].refundnumbers, 3) : void 0}
            </div>,
        )
    }
    }
    return draws
}

export function getCombinationsIds(data: any, key: any): JSX.Element[] {
    const combinationsIds: JSX.Element[] = []
    for (let i: number = 0; i < data[key]['ticket'].length; i++) {
        if (i > 0 && i % data[key]['combos'] === 0) {
            combinationsIds.push(<hr key = {'h' + i.toString()} style = {{marginTop: '5px', marginBottom: '0px', paddingTop: '5px', paddingBottom: '0px', borderTop: '1px solid #999'}}/>)
        }
        
        combinationsIds.push(
            <div className = "clearfix" key = {i.toString()}>
                <div className = "fl" style = {{lineHeight: '31px', fontSize: '16px', marginRight: '5px', width: '140px'}}>{Assets.Combination + ' #' + (i + 1).toString() + ': '}</div>
                <div className = "fl" style = {{lineHeight: '31px', fontSize: '16px'}}>{'#' + data[key]['ticket'][i]}</div>
            </div>,
        )
    }
    return combinationsIds
}

export function getCombinations(data: any, key: any): JSX.Element[] {
    const combinations: JSX.Element[] = []
    // console.log (data[key])
    // console.log (data[key]['ticket'])
    let a: number[]
    let cmb: any
    if (data[key]) {
    for (let l: number = 0; l < data[key]['customlt'].lotteryBet.length; l++) {
        const regularCombos: number[][] = []
        const bonusCombos: number[][] = []
        const refundCombos: number[][] = []

        if (data[key]['customlt'].lotteryBet[l].regular.length > 0 && data[key]['r']) {
            cmb = Combinatorics.combination(data[key]['customlt'].lotteryBet[l].regular, data[key]['r'])
            // tslint:disable-next-line:no-conditional-assignment
            while (a = cmb.next()) { regularCombos.push(a) }
        }

        if (data[key]['customlt'].lotteryBet[l].bonus.length > 0 && data[key]['b']) {
            cmb = Combinatorics.combination(data[key]['customlt'].lotteryBet[l].bonus, data[key]['b'])
            // tslint:disable-next-line:no-conditional-assignment
            while (a = cmb.next()) { bonusCombos.push(a) }
        }

        if (data[key]['customlt'].lotteryBet[l].refund.length > 0 && data[key]['f']) {
            cmb = Combinatorics.combination(data[key]['customlt'].lotteryBet[l].refund, data[key]['f'])
            // tslint:disable-next-line:no-conditional-assignment
            while (a = cmb.next()) { refundCombos.push(a) }
        }

        const regularCombinations: number = regularCombos.length > 0 ? regularCombos.length : 1
        const bonusCombinations: number = bonusCombos.length > 0 ? bonusCombos.length : 1
        const refundCombinations: number = refundCombos.length > 0 ? refundCombos.length : 1

        for (let i: number = 0; i < regularCombinations; i++) {
        for (let j: number = 0; j < bonusCombinations; j++) {
        for (let k: number = 0; k < refundCombinations; k++) {
            // console.log (regularCombos[i])
            // console.log (bonusCombos[j])
            // console.log (refundCombos[k])
            combinations.push(
            <div className = "clearfix" key = {(l * regularCombinations * bonusCombinations * refundCombinations + i * bonusCombinations * refundCombinations + j * refundCombinations + k).toString()}>
                <div className = "fl" style = {{lineHeight: '31px', fontSize: '16px', marginRight: '5px', width: '200px'}}>
                    {Assets.Combination + ' #' + data[key]['ticket'][l * regularCombinations * bonusCombinations * refundCombinations + i * bonusCombinations*refundCombinations + j*refundCombinations + k]+':'}
                </div>
                {regularCombos[i] && regularCombos[i].length ? getNumbers(regularCombos[i], 0) : void 0}
                {bonusCombos[j] && bonusCombos[j].length ? getNumbers(bonusCombos[j], 2) : void 0}
                {refundCombos[k] && refundCombos[k].length ? getNumbers(refundCombos[k], 3) : void 0}
            </div>,
            )
        }
        }
        }
        if (l < data[key]['customlt'].lotteryBet.length - 1) {
            combinations.push(<hr key = {'h' + l.toString()} style = {{marginTop: '5px', marginBottom: '0px', paddingTop: '5px', paddingBottom: '0px', borderTop: '1px solid #999'}}/>)
        }
    }
    }
    return combinations
}

export function getSheets(data: any, key: any): JSX.Element[] {
    const sheets: JSX.Element[] = []
    for (let i: number = 0; i < data[key]['customlt'].lotteryBet.length; i++) {
        sheets.push(
            <div className = "clearfix" key = {i.toString()}>
                <div className = "fl" style = {{lineHeight: '31px', fontSize: '16px', marginRight: '5px', width: '100px'}}>{Assets.Sheet + ' #' + (i+1).toString()}</div>
                {data[key]['customlt'].lotteryBet[i]['regular'] && data[key]['customlt'].lotteryBet[i]['regular'].length ? getNumbers(data[key]['customlt'].lotteryBet[i]['regular'], 0) : void 0}
                {data[key]['customlt'].lotteryBet[i]['bonus'] && data[key]['customlt'].lotteryBet[i]['bonus'].length ? getNumbers(data[key]['customlt'].lotteryBet[i]['bonus'], 2) : void 0}
                {data[key]['customlt'].lotteryBet[i]['refund'] && data[key]['customlt'].lotteryBet[i]['refund'].length ? getNumbers(data[key]['customlt'].lotteryBet[i]['refund'], 3) : void 0}
            </div>,
        )
        
    }
    return sheets
}

// works also for letters
export function getNumbers(array: number[] | string[], which: number): JSX.Element[] {
    const num: JSX.Element[] = []
    for (const item of array) {
        let classname: string = 'lottery-number '
        if (which === 0) { classname += 'selected-numbers' } else if (which === 1) { classname += 'selected-extranumbers' } else if (which === 2) { classname += 'selected-bonusnumbers' } else if (which === 3) { classname += 'selected-refundnumbers' } else if (which === 4) { classname += 'selected-serialnumbers' } else if (which === 5) { classname += 'selected-letters' } else if (which === 6) { classname += 'selected-winners' }
        let title: string = ''
        if (which === 0) { title = Assets.Number } else if (which === 1) { title = Assets.ExtraNumber } else if (which === 2) { title = Assets.BonusNumber } else if (which === 3) { title = Assets.RefundNumber } else if (which === 4) { title = Assets.SerialNumber } else if (which === 5) { title = Assets.Letter } else if (which === 6) { title = Assets.Winner }

        num.push(<span title = {title} style = {{textAlign: 'center', marginLeft: '0px', marginRight: '4px'}} className = {classname} key = {item.toString()}>{item}</span>)
    }
    return num
}

export function Sort(column:number, sort0:number[]):number {

    for (let i:number = 0; sort0 && i < sort0.length; i++) {
        if (Math.floor(sort0[i] / 100) === column) { return sort0[i] % 100 }     
    }
    return 0
}

export function SortClick(column: number, sort0: number[], Column: string, Sort0: string[], SORT: () => void, optSort: any): void {
    let found:boolean = false
    for (let i:number = 0; sort0 && i < sort0.length; i++) {
        if (Math.floor(sort0[i] / 100) === column) {
            if (sort0[i] % 100 === 2) { Sort0.splice(i) }
            sort0[i] % 100 === 2 ? sort0.splice(i) : sort0[i]++
            Logic.Type.New (optSort, {sort: sort0, Sort: Sort0})
            found = true
            break
        }
    }
    if (!found) {
        if (sort0) { sort0.push(100 * column + 1) }
        if (Sort0) { Sort0.push (Column) }
        if (sort0 && Sort0) { Logic.Type.New (optSort, {sort: sort0, Sort: Sort0}) }
    }
    SORT()
}

export function ActiveImage(activity: boolean): JSX.Element {
    return (<img className = {/*classname ? classname : */'activity-image2'} src = {activity ? Assets_.active : Assets_.inactive}/>)
}

export function activeImage(activity: boolean, classname?: string, style?: any, title?: string): JSX.Element {
    return (<img title = {title ? title : undefined} style = {style ? style : {}} className = {classname ? classname : 'activity-image2'} src = {activity ? Assets_.active2 : Assets_.inactive2}/>)
}

export function countProperties(obj:any):number {
    let count:number = 0;

    for(const prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            ++count;
        }
    }

    return count;
}

export function SelectButton(row:any, selectButton?: (row?:any) => void): JSX.Element | undefined {
    if (selectButton) {
        return (
            <div className = "td-center" key = "select">
                <button 
                    className = "btn btn-xs btn-success mr0"
                    title = {Assets.selectButtonDesc}                            
                    onClick = {(click) => selectButton ? selectButton(row) : void 0}
                >
                    <i className = {Assets_.faSelectClass}/>
                </button>
            </div>
        )
    }
    
    return void 0
}

export function PlayerSelectButton(player: string, playerid: number, playerSelectButton?: (player: string) => void): JSX.Element | undefined {
    if (playerSelectButton) {
        return (
            <div className = "td-center" key = "select">
                {player === 'Empty player' ? 
                <span
                    style = {{fontSize: '14px'}}
                    className = "label label-danger"
                    title = {Assets.ErrorInDatabaseEmptyPlayer}
                >
                    {player.length < Assets_.PLAYER_ID_MAX_LENGTH_DISPLAYED ? player : player.substring(0, Assets_.PLAYER_ID_MAX_LENGTH_DISPLAYED) + '...'}
                </span>                            
                :
                <button
                    title = {playerid ? Assets.Disable + ' ' + Assets.FilterByPlayer.toLowerCase() : Assets.FilterByPlayer + ' (' + Assets.Player.toLowerCase() + ': ' + player + ')'}
                    className = {playerid ? 'btn btn-info btn-border3' : 'btn btn-nothing btn-border3'}
                    style = {{marginRight: '0px', padding: '3px 5px'}}
                    onClick = {() => playerSelectButton(player)}
                >
                    {player.length < Assets_.PLAYER_ID_MAX_LENGTH_DISPLAYED ? player : player.toString().substring(0, Assets_.PLAYER_ID_MAX_LENGTH_DISPLAYED) + '...'}
                </button>}
            </div>
        )
    }
    
    return void 0
}

export function ConstructCategoriesRewards(row: any, categoryButton: (a: string, b: number) => void): JSX.Element {
    const Categories: string[] = row.value ? row.value.split(' ') : []
    const length: number = Categories.length - 1
    const c: JSX.Element[] = []
    for (let which: number = 0; which < length; which++) {
        c.push(CategoryButton(row.original.id, categoryButton, which, length - 1, parseInt(row.original.category), Categories[which]))
    }
    return <div>{c}</div>
}
/*
export function CConstructCategoriesTasks(row: any, categoryButton: (a: string, b: number) => void): JSX.Element {
    // console.log (row.original)
    // console.log (row.value)
    if (row.value && row.value.length > 0) {
        const Categories: string[] = []
        // row.original.tasks.split(' ')
        for (const item of row.value) {
            Categories.push(item.name)
        }

        const length: number = Categories.length - 1
        const categories: JSX.Element[] = []
        for (let which: number = 0; which < length; which++) {
            categories.push(CategoryButton(row.original.id, categoryButton, which, length - 1, parseInt(row.original.task_id), Categories[which]))
        }
        return (<div>{categories}</div>)
    }

    return (<div style = {{color:'#bbb'}}>{Assets_.NA}</div>)
}
*/
/*
export function CConstructCategoriesTasks2(row: any): JSX.Element {

    const T = JSON.parse(JSON.stringify(row.value))
    if (T.length) { return (
        <div>
            {parseInt(row.original.task_id) >= 0 && T[parseInt(row.original.task_id)] && T[parseInt(row.original.task_id)]['objectives'] ?
            T[parseInt(row.original.task_id)]['objectives'].length + ' obj.' : <span>&mdash;</span>}
        </div>
    )
    }
    return (<span>&mdash;</span>)
}
*/
export function ConstructCategoriesTasks(row: any, categoryButton: (a: string, b: number) => void): JSX.Element {
    if (row.value && row.value.length > 0) {
        const Categories: string[] = row.original.tasks.split(' ')
        const length: number = Categories.length - 1
        const categories: JSX.Element[] = []
        for (let which: number = 0; which < length; which++) {
            categories.push(CategoryButton(row.original.id, categoryButton, which, length - 1, parseInt(row.original.task_id), Categories[which]))
        }
        return (<div>{categories}</div>)
    } else { return (<div style = {{color:'#bbb'}}>{Assets_.NA}</div>) }
}

export function ConstructCategoriesTasks2(row: any): JSX.Element {

    const T = JSON.parse(JSON.stringify(row.value))
    if (T.length) { return (
        <div>
            {parseInt(row.original.task_id) >= 0 && T[parseInt(row.original.task_id)] && T[parseInt(row.original.task_id)]['objectives'] ?
            T[parseInt(row.original.task_id)]['objectives'].length + ' obj.' : <span>&mdash;</span>}
        </div>
    )
    }
    return (<span>&mdash;</span>)
}
export function ConstructCategoriesRewards2(row: any): JSX.Element {
    const rewards: JSX.Element[] = []
    if (row.value) {
        const R = JSON.parse(JSON.stringify(row.value))
        if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[8])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.duration) {
                    rewards.push(<span title = {Assets.Duration} key = "10">DUR: {getDuration(R.category1.duration)}</span>)
                }
                if (R.category1.boostMissionsPercentage) {
                    rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "11">BM: {R.category1.boostMissionsPercentage/100}x</span>)
                }
                if (R.category1.boostPointsPercentage) {
                    rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "12">BP: {R.category1.boostPointsPercentage/100}x</span>)
                }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.duration) {
                    rewards.push(<span title = {Assets.Duration} key = "20">DUR: {getDuration(R.category2.duration)}</span>)
                }
                if (R.category2.boostMissionsPercentage) {
                    rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "21">BM: {R.category2.boostMissionsPercentage/100}x</span>)
                }
                if (R.category2.boostPointsPercentage) {
                    rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "22">BP: {R.category2.boostPointsPercentage/100}x</span>)
                }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.duration) {
                    rewards.push(<span title = {Assets.Duration} key = "30">DUR: {getDuration(R.category3.duration)}</span>)
                }
                if (R.category3.boostMissionsPercentage) {
                    rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "31">BM: {R.category3.boostMissionsPercentage/100}x</span>)
                }
                if (R.category3.boostPointsPercentage) {
                    rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "32">BP: {R.category3.boostPointsPercentage/100}x</span>)
                }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[7])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.dateFrom) { rewards.push(<span title = {Assets.StartDatetime} key = "10">SD: {R.category1.dateFrom}</span>) }
                if (R.category1.dateTo) { rewards.push(<span title = {Assets.EndDatetime} key = "11">ED: {R.category1.dateTo}</span>) }
                if (R.category1.boostMissionsPercentage) { rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "12">BM: {R.category1.boostMissionsPercentage/100}x</span>) }
                if (R.category1.boostPointsPercentage) { rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "13">BP: {R.category1.boostPointsPercentage/100}x</span>) }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.dateFrom) { rewards.push(<span title = {Assets.StartDatetime} key = "20">SD: {R.category2.dateFrom}</span>) }
                if (R.category2.dateTo) { rewards.push(<span title = {Assets.EndDatetime} key = "21">ED: {R.category2.dateTo}</span>) }
                if (R.category2.boostMissionsPercentage) { rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "22">BM: {R.category2.boostMissionsPercentage/100}x</span>) }
                if (R.category2.boostPointsPercentage) { rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "23">BP: {R.category2.boostPointsPercentage/100}x</span>) }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.dateFrom) { rewards.push(<span title = {Assets.StartDatetime} key = "30">SD: {R.category3.dateFrom}</span>) }
                if (R.category3.dateTo) { rewards.push(<span title = {Assets.EndDatetime} key = "31">ED: {R.category3.dateTo}</span>) }
                if (R.category3.boostMissionsPercentage) { rewards.push(<span title = {Assets.BoostMissionsMultiplier} key = "32">BM: {R.category3.boostMissionsPercentage/100}x</span>) }
                if (R.category3.boostPointsPercentage) { rewards.push(<span title = {Assets.BoostPointsMultiplier} key = "33">BP: {R.category3.boostPointsPercentage/100}x</span>) }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[5])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "10">ET: {R.category1.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category1.minigameName) { rewards.push(<span title = {Assets.SpinsMinigameName} key = "12">MG: {R.category1.minigameName}</span>) }
                if (R.category1.minigameId && R.category1.minigameId.rewards) {
                    rewards.push(<span title = {Assets.SpinsMinigameRewards} key = "11">RW: {R.category1.minigameId.rewards.toString()}</span>)
                }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "20">ET: {R.category2.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category2.minigameName) { rewards.push(<span title = {Assets.SpinsMinigameName} key = "22">MG: {R.category1.minigameName}</span>) }
                if (R.category2.minigameId && R.category2.minigameId.rewards) {
                    rewards.push(<span title = {Assets.SpinsMinigameRewards} key = "21">RW: {R.category2.minigameId.rewards.toString()}</span>)
                }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "30">ET: {R.category3.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category3.minigameName) { rewards.push(<span title = {Assets.SpinsMinigameName} key = "32">MG: {R.category3.minigameName}</span>) }
                if (R.category3.minigameId && R.category3.minigameId.rewards) {
                    rewards.push(<span title = {Assets.SpinsMinigameRewards} key = "31">RW: {R.category3.minigameId.rewards.toString()}</span>)
                }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[4])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.days) { rewards.push(<span title = {Assets.Days} key = "10">{Assets.Valid}: {R.category1.days} {Assets.Days.toLowerCase()}</span>) }
                if (R.category1.cashBackPercentage) { rewards.push(<span title = {Assets.CashBackPercentage} key = "11">CB: {R.category1.cashBackPercentage} %</span>) }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.days) { rewards.push(<span title = {Assets.Days} key = "20">{Assets.Valid}: {R.category2.days} {Assets.Days.toLowerCase()}</span>) }
                if (R.category2.cashBackPercentage) { rewards.push(<span title = {Assets.CashBackPercentage} key = "21">CB: {R.category2.cashBackPercentage} %</span>) }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.days) { rewards.push(<span title = {Assets.Days} key = "30">{Assets.Valid}: {R.category3.days} {Assets.Days.toLowerCase()}</span>) }
                if (R.category3.cashBackPercentage) { rewards.push(<span title = {Assets.CashBackPercentage} key = "31">CB: {R.category3.cashBackPercentage} %</span>) }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[2])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "10">ET: {R.category1.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category1.cashBonusAmount) { rewards.push(<span title = {Assets.CashBackAmount} key = "11">CBA: {R.category1.cashBonusAmount} €</span>) }
                if (R.category1.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "12">WR: {R.category1.wageringRequirement}x</span>) }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "20">ET: {R.category2.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category2.cashBonusAmount) { rewards.push(<span title = {Assets.CashBackAmount} key = "21">CBA: {R.category2.cashBonusAmount} €</span>) }
                if (R.category2.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "22">WR: {R.category2.wageringRequirement}x</span>) }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "30">ET: {R.category3.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category3.cashBonusAmount) { rewards.push(<span title = {Assets.CashBackAmount} key = "31">CBA: {R.category3.cashBonusAmount} €</span>) }
                if (R.category3.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "32">WR: {R.category3.wageringRequirement}x</span>) }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[6])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "10">ET: {R.category1.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category1.bonusAmount) { rewards.push(<span title = {Assets.BonusAmount} key = "11">BA: {R.category1.bonusAmount} €</span>) }
                if (R.category1.bonusPercent) { rewards.push(<span title = {Assets.BonusPercent} key = "12">BP: {R.category1.bonusPercent} %</span>) }
                if (R.category1.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "13">WR: {R.category1.wageringRequirement}x</span>) }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "20">ET: {R.category2.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category2.bonusAmount) { rewards.push(<span title = {Assets.BonusAmount} key = "21">BA: {R.category2.bonusAmount} €</span>) }
                if (R.category2.bonusPercent) { rewards.push(<span title = {Assets.BonusPercent} key = "22">BP: {R.category2.bonusPercent} %</span>) }
                if (R.category2.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "23">WR: {R.category2.wageringRequirement}x</span>) }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.expirationDays) { rewards.push(<span title = {Assets.ExpirationTime} key = "30">ET: {R.category3.expirationDays} {Assets.Days.toLowerCase()}</span>) }
                if (R.category3.bonusAmount) { rewards.push(<span title = {Assets.BonusAmount} key = "31">BA: {R.category3.bonusAmount} €</span>) }
                if (R.category3.bonusPercent) { rewards.push(<span title = {Assets.BonusPercent} key = "32">BP: {R.category3.bonusPercent} %</span>) }
                if (R.category3.wageringRequirement) { rewards.push(<span title = {Assets.WageringRequirement} key = "33">WR: {R.category3.wageringRequirement}x</span>) }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[1])) {
            let a: number
            if (parseInt(row.original.category) === 0 && R.category1) {
                if (R.category1.gameNames) {
                    rewards.push(<span title = {Assets.GameNames} key = "15">GM: {JSON.stringify(R.category1.gameNames).replace('[','').replace(']','').replace(/"/g, '')}</span>)
                }
                if (R.category1.spinsCoinValues) {
                    rewards.push(<span title = {Assets.BetLevels} key = "11">BLs: {JSON.stringify(R.category1.spinsCoinValues).replace('[','').replace(']','')}</span>)
                }
                if (R.category1.spinsCoinValues && R.category1.gameProviders) {
                    a = R.category1.spinValue ? gameProviders(R.category1.gameProviders) === 'NetEnt' ? convertNetEnt(R.category1.spinBetLevel) : R.category1.spinValue : 0
                    rewards.push(<span title = {Assets.SpinValue} key = "16">SV: {a} €</span>,
                    )
                }
                // if (R.category1.spinsValue) rewards.push(<span title = "Spins value" key = "12">SV: {R.category1.spinsValue} €</span>)
                if (R.category1.spinsAmount) {
                    rewards.push(<span title = {Assets.SpinsAmount} key = "13">SA: {R.category1.spinsAmount}</span>)
                }
                if (R.category1.spinsAmount && a) { 
                    rewards.push(<span title = {Assets.SpinsTotalValue} key = "17">{Assets_.sum}: {(R.category1.spinsAmount * a).toFixed(2)} €</span>)
                }
                if (R.category1.expirationDays) {
                    rewards.push(<span title = {Assets.ExpirationTime} key = "10">ET: {R.category1.expirationDays} {Assets.Days.toLowerCase()}</span>)
                }
                if (R.category1.wageringRequirement) {
                rewards.push(<span title = {Assets.WageringRequirement} key = "14">WR: {R.category1.wageringRequirement}x</span>)
                }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                if (R.category2.gameNames) {
                    rewards.push(<span title = {Assets.GameNames} key = "25">GM: {JSON.stringify(R.category2.gameNames).replace('[','').replace(']','').replace(/"/g, '')}</span>)
                }
                if (R.category2.spinsCoinValues) {
                    rewards.push(<span title = {Assets.BetLevels} key = "21">BLs: {JSON.stringify(R.category2.spinsCoinValues).replace('[','').replace(']','')}</span>)
                }
                if (R.category2.spinsCoinValues && R.category2.gameProviders) {
                    a = R.category2.spinValue ? gameProviders(R.category2.gameProviders) === 'NetEnt' ? convertNetEnt(R.category2.spinBetLevel) : R.category2.spinValue : 0
                    rewards.push(<span title = {Assets.SpinValue} key = "16">SV: {a} €</span>,
                    )
                }
        
                // if (R.category2.spinsValue) rewards.push(<span title = "Spins value" key = "22">SV: {R.category2.spinsValue} €</span>)
                if (R.category2.spinsAmount) {
                    rewards.push(<span title = {Assets.SpinsAmount} key = "23">SA: {R.category2.spinsAmount}</span>)
                }
                if (R.category2.spinsAmount && a) { 
                    rewards.push(<span title = {Assets.SpinsTotalValue} key = "27">{Assets_.sum}: {(R.category2.spinsAmount * a).toFixed(2)} €</span>)
                }

                if (R.category2.expirationDays) {
                    rewards.push(<span title = {Assets.ExpirationTime} key = "20">ET: {R.category2.expirationDays} {Assets.Days.toLowerCase()}</span>)
                }
                if (R.category2.wageringRequirement) {
                    rewards.push(<span title = {Assets.WageringRequirement} key = "24">WR: {R.category2.wageringRequirement}x</span>)
                }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                if (R.category3.gameNames) { rewards.push(<span title = {Assets.GameNames} key = "35">GM: {JSON.stringify(R.category3.gameNames).replace('[','').replace(']','').replace(/"/g, '')}</span>) }
                if (R.category3.spinsCoinValues) { rewards.push(<span title = {Assets.BetLevels} key = "31">BLs: {JSON.stringify(R.category3.spinsCoinValues).replace('[','').replace(']','')}</span>) }
                if (R.category3.spinsCoinValues && R.category3.gameProviders) {
                    a = R.category3.spinValue ? gameProviders(R.category3.gameProviders) === 'NetEnt' ? convertNetEnt(R.category3.spinBetLevel) : R.category3.spinValue : 0
                    rewards.push(<span title = {Assets.SpinValue} key = "36">SV: {a} €</span>,
                    )
                }
                // if (R.category3.spinsValue) rewards.push(<span title = "Spins value" key = "32">SV: {R.category3.spinsValue} €</span>)
                if (R.category3.spinsAmount) {
                    rewards.push(<span title = {Assets.SpinsAmount} key = "33">SA: {R.category3.spinsAmount}</span>)
                }
                if (R.category3.spinsAmount && a) { 
                    rewards.push(<span title = {Assets.SpinsTotalValue} key = "37">{Assets_.sum}: {(R.category3.spinsAmount * a).toFixed(2)} €</span>)
                }
                
                if (R.category3.expirationDays) {
                    rewards.push(<span title = {Assets.ExpirationTime} key = "30">ET: {R.category3.expirationDays} {Assets.Days.toLowerCase()}</span>)
                }
                if (R.category3.wageringRequirement) {
                    rewards.push(<span title = {Assets.WageringRequirement} key = "34">WR: {R.category3.wageringRequirement}x</span>)
                }
            }
        } else if (row.original['Reward type'].toString() === injectSpaces(RewardTypes[3])) {
            if (parseInt(row.original.category) === 0 && R.category1) {
                // if (R.category1.expirationDays) rewards.push(<span title = {Assets.ExpirationTime} key = "10">ET: {R.category1.expirationDays} {Assets.Days.toLowerCase()}</span>)
                if (R.category1.tokens) { rewards.push(<span title = "Tokens" key = "11">TK: {R.category1.tokens}</span>) }
            } else if (parseInt(row.original.category) === 1 && R.category2) {
                // if (R.category2.expirationDays) rewards.push(<span title = {Assets.ExpirationTime} key = "20">ET: {R.category2.expirationDays} {Assets.Days.toLowerCase()}</span>)
                if (R.category2.tokens) { rewards.push(<span title = "Tokens" key = "21">TK: {R.category2.tokens}</span>) }
            } else if (parseInt(row.original.category) === 2 && R.category3) {
                // if (R.category3.expirationDays) rewards.push(<span title = {Assets.ExpirationTime} key = "30">ET: {R.category3.expirationDays} {Assets.Days.toLowerCase()}</span>)
                if (R.category3.tokens) { rewards.push(<span title = "Tokens" key = "31">TK: {R.category3.tokens}</span>) }
            }
        }
    }
    return <div className = "rewards">{rewards}</div>
}

export function convertNetEnt(value: number): number {
    if (value === 1) { return 0.01 } else if (value === 2) { return 0.02 } else if (value === 3) { return 0.05 } else if (value === 4) { return 0.1 } else if (value === 5) { return 0.2 } else if (value === 6) { return 0.5 } else if (value === 7) { return 1 } else if (value === 8) { return 2 }
    // else if (value === 9) return 5
    // else if (value === 10) return 10
    return 0
}

export function gameProviders(a: string[], slash?: boolean) {
    let b: string
    for (const item of a) {
        if (b === undefined) { b = item } else if (b !== item) { return '' }
    }
    // alert (b)

    return slash ? b === undefined ? '' : '/' + b : b
}

export function CategoryButton(rowDataId:string, categoryButton: (a: string, b: number) => void, which: number, length: number, selected: number, text: string): JSX.Element | undefined {
    if (categoryButton) {
        if (which === length) { return (
            <button 
                key = {which.toString()}
                className = {parseInt(text) - 1 === selected ? 'btn btn-xs btn-info mr0' : 'btn btn-xs btn-default mr0'}
                onClick = {(click) => categoryButton(rowDataId, which)}
            >
                {text}
            </button>)
        } else { return (
            <button
                key = {which.toString()}
                className = {parseInt(text) - 1 === selected ? 'btn btn-xs btn-info' : 'btn btn-xs btn-default'}
                onClick = {(click) => categoryButton(rowDataId, which)}
            >
                {text}
            </button>
        )
        }
    }
    return void 0
}

/*
export function AttrButton(column:number, rowData:string[], attrButton?: (a:number, b:number, rowData?:string[]) => void): JSX.Element|undefined {

    if (attrButton) {

        let value:number = 0
        if (rowData[column - 1].toString() === 'true') {
            value = 1
        }

        if (rowData[column].toString() === 'true') {
            value = 2
        }

        if (value === 0) 
            return <div className = "td-center" key = {column.toString()}>
                        <button 
                            className = "btn btn-xs btn-nothing mr0"
                            title = {Assets.NO}
                            style = {{color:'#d9534f'}}
                            // title = {Assets.attrButtonDesc}
                            onClick = {(click) => attrButton(column, value, rowData)}
                        >
                            <i className = {Assets_.faCircleClass}/>
                        </button>
                    </div>

        return <div className = "td-center" key = {column.toString()}>
                    <button 
                        className = "btn btn-xs btn-nothing mr0"
                        title = {value === 1 ? Assets.YES : Assets.ANY}
                        style = {{color:'#5cb85c'}}
                        // style = {value === 1 ? {color:'#5cb85c'} : {color:'#337ab7'}}
                        // title = {Assets.attrButtonDesc}
                        onClick = {(click) => attrButton(column, value, rowData)}
                    >
                        <i className = {value === 1 ? Assets_.faCircleOClass : Assets_.faCircleClass}/>
                    </button>
                </div>
    }
    
    return void 0
}
*/

export function ExpandButton(rowData:string[], expandButton: (rowData:string[]) => void, Expand:number[]): JSX.Element|undefined {
        
    let expanded:boolean = false

    for (let i = 0; i < Expand.length; i++) {
        if (parseInt(rowData[0]) === Expand[i]) {
            expanded = true
        }
    }

    return (<td className = "td-left" key = "expand">
                <button 
                    className = {expanded ? 'btn btn-xs btn-info mr0' : 'btn btn-xs btn-primary mr0'}
                    title = {Assets.expandButtonDesc}
                    onClick = {(click) => expandButton(rowData)}
                >
                    <i className = {expanded ? Assets_.faMinusClass : Assets_.faPlusClass}/>
                </button>
            </td>)
}

export function DeleteButton(row: any, delButton: (row) => void, disabled?: boolean, title?: string): JSX.Element {
    return  (
        <div className = "td-center">
            <button
                disabled = {disabled !== undefined ? disabled: false}
                className = "btn btn-xs btn-danger mr0"
                title = {title ? title : Assets.delButtonDesc}
                onClick = {() => delButton(row)}
            >
                <FontAwesomeIcon icon = {faTrashAlt}/>
            </button>
        </div>
    )
}

export function EditButton(row: any, editButton: (row) => void, disabled?: boolean, title?: string): JSX.Element {

    return (
        <div className = "td-center">
            <button
                disabled = {disabled !== undefined ? disabled: false}
                className = "btn btn-xs btn-warning mr0"
                title = {title ? title : Assets.editButtonDesc}
                onClick = {(click) => editButton(row)}
            >
                <FontAwesomeIcon icon = {faEdit}/>
            </button>
        </div>
    )    
}

export function TB(row: any, toggleButton?: (id: number, value: string) => void): JSX.Element {                   
    return (
        <div className = "td-center">
            <button
                disabled = {!isUserAdmin()}
                style = {{textAlign:'center'}}
                className = "btn btn-xs btn-nothing mr0"
                title = {Assets.toggleValueDesc}
                onClick = {() => toggleButton ? toggleButton(row.original.id, row.value) : void 0}
            >
                <img 
                    className = "activity-image" 
                    src = {row.value ? Assets_.active : Assets_.inactive}
                />
            </button>
        </div>
    )
}

export function TBN(value: boolean, securityRight?: SecurityRights, small?: boolean): JSX.Element {
    return (
        <div style = {{marginLeft: small ? '-8px' : ''}} title = {securityRight ? Assets.SecurityRight + ': ' + Assets_.SecurityRights[securityRight] : ''} className = "td-center">
            <img
                style = {{textAlign: 'center', padding: '0px'}}
                className = "activity-image" 
                src = {value ? Assets_.active : Assets_.inactive}
            />
        </div>
    )
}

export function TB2(row: any): JSX.Element {                   
    return (
        <div className = "td-center">
            <button
                style = {{textAlign:'center'}}
                className = "btn btn-xs btn-nothing mr0"
                title = {Assets.toggleValueDesc}
            >
                <img 
                    className = "activity-image" 
                    src = {row.value === 1 ? Assets_.active : Assets_.inactive}
                />
            </button>
        </div>
    )
}

export function getDayInWeek(array: number[]): JSX.Element[] {
    const arr: JSX.Element[] = []
    if (array) {
    for (let ii: number = 0; ii < 7; ii++) {
        let found: boolean =  false
        for (const Item of array) { if (ii === +Item) {found = true; break} }
        arr.push (
            <span
                title = {Assets.DaysInWeek[ii]}
                key = {ii.toString()}
                style = {{padding: '1px 3px 2px', marginRight: '3px', color: found ? '' : '#333', background: found ? '' : '#fff', border: found ? '' : '1px solid #ccc'}}
                className = {found ? 'label label-success' : 'label label-default'}
            >{Assets.DaysInWeek[ii].substring(0,1)}
            </span>,
        )
    }
    }
    return arr    
}

export function getWeekInMonth(array: number[]): JSX.Element[] {
    const arr: JSX.Element[] = []
    if (array) {
    for (let ii: number = 0; ii < 5; ii++) {
        let found: boolean =  false
        for (const Item of array) { if (ii === +Item) {found = true; break} }
        arr.push (
            <span
                title = {ii === 0 ? Assets.FirstWeek : ii === 1 ? Assets.SecondWeek : ii === 2 ? Assets.ThirdWeek : ii === 3 ? Assets.FourthWeek : ii === 4 ? Assets.FifthWeek : Assets.LastWeek}
                key = {ii.toString()}
                style = {{padding: '1px 4px 2px', marginRight: '3px', color: found ? '' : '#333', background: found ? '' : '#fff', border: found ? '' : '1px solid #ccc'}}
                className = {found ? 'label label-success' : 'label label-default'}
            >
                {ii+1}
            </span>,
        )
    }
    }
    return arr
}

export function Flag(flagId: number): JSX.Element {
    return (
        <div className = "td-center">
            {flagId > 0 && Main.Instance.DatabaseDataState.FlagsStreamAllOrder && Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagId - 1] ?
            <img 
                height = "20"
                // width = "30"
                src = {Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagId - 1]['image']}
                style = {{border: '1px solid #999', maxWidth: '30px'}}
            /> : <div className = "disabled">{Assets.None}</div>}
        </div>
    )
}

export function FlagByCountryName(name: string): JSX.Element {
    let flaglottery: string
    // if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) console.log (Main.Instance.DatabaseDataState.FlagsStreamAllOrder)
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
    for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        if (Item['name'] === name
            || Item['name'] === 'United States of America' && name === 'USA'
            || Item['name'] === 'United Kingdom' && name === 'UK'
            || Item['name'] === 'European Union' && name === 'Europe'
        ) {
            flaglottery = Item['image20']
            break
        } 
    }
    }

    return (
        <div className = "td-center">
            {flaglottery !== undefined ?
            <img 
                height = "20"
                // width = "30"
                src = {flaglottery}
                style = {{border: '1px solid #999', maxWidth: '30px'}}
            /> : <div className = "disabled">{Assets.None}</div>}
        </div>
    )
}

export function LossWinPending(value: string): JSX.Element {
    return <span className = {value === Assets.Loss ? 'label label-danger' : value === Assets.Win ? 'label label-success' : value === Assets.Pending ? 'label label-warning' : ''}>{value}</span>
}

export function FlagByCountryCode(code: string): JSX.Element {
    let flaglottery: string
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
    for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        if (Item['code2'] === code.toLowerCase() || Item['code'] === code.toLowerCase()
            || Item['code2'] === 'us' && code === 'USA' || Item['code'] === 'us' && code === 'USA'
            || Item['code2'] === 'uk' && code === 'UK' || Item['code'] === 'uk' && code === 'UK'
            || Item['code2'] === 'eu' && code === 'EU' || Item['code'] === 'eu' && code === 'EU'
        ) {
            flaglottery = Item['image20']
            break
        } 
    }
    }

    return (
        <div className = "td-center">
            {flaglottery !== undefined ?
            <img 
                height = "20"
                // width = "30"
                src = {flaglottery}
                style = {{border: '1px solid #999', maxWidth: '30px'}}
            /> : <div className = "disabled">{Assets.None}</div>}
        </div>
    )
}

export function FlagByCurrencyCode(code: string, empty?: boolean): JSX.Element {
    let flaglottery: string
/*
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        console.log (Main.Instance.DatabaseDataState.FlagsStreamAllOrder)
    }
*/
    // console.log ('code ' + code)
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        // console.log (Main.Instance.DatabaseDataState.FlagsStreamAllOrder)
        for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
            // if (Item.name === 'Brazil') {console.log ('Brazil ', Item)}
            /*
            let proceed: boolean = false
            if (code == 'BRL' && (Item.code2 == 'br'||Item.code == 'br')) {
                 proceed = true
            }
            */
            if(Item['code2'] === 'us' && code === 'USD' || Item['code'] === 'us' && code === 'USD'
            || Item['code2'] === 'eu' && code === 'EUR' || Item['code'] === 'eu' && code === 'EUR'
            || Item['code2'] === 'gb' && code === 'GBP' || Item['code'] === 'gb' && code === 'GBP'
            || Item['code2'] === 'jp' && code === 'JPY' || Item['code'] === 'jp' && code === 'JPY'
            || Item['code2'] === 'bg' && code === 'BGN' || Item['code'] === 'bg' && code === 'BGN'
            || Item['code2'] === 'cz' && code === 'CZK' || Item['code'] === 'cz' && code === 'CZK'
            || Item['code2'] === 'dk' && code === 'DKK' || Item['code'] === 'dk' && code === 'DKK'
            || Item['code2'] === 'hu' && code === 'HUF' || Item['code'] === 'hu' && code === 'HUF'
            || Item['code2'] === 'pl' && code === 'PLN' || Item['code'] === 'pl' && code === 'PLN'
            || Item['code2'] === 'ro' && code === 'RON' || Item['code'] === 'ro' && code === 'RON'
            || Item['code2'] === 'se' && code === 'SEK' || Item['code'] === 'se' && code === 'SEK'
            || Item['code2'] === 'ch' && code === 'CHF' || Item['code'] === 'ch' && code === 'CHF'
            || Item['code2'] === 'no' && code === 'NOK' || Item['code'] === 'no' && code === 'NOK'
            || Item['code2'] === 'hr' && code === 'HRK' || Item['code'] === 'hr' && code === 'HRK'
            || Item['code2'] === 'ru' && code === 'RUB' || Item['code'] === 'ru' && code === 'RUB'
            || Item['code2'] === 'tr' && code === 'TRY' || Item['code'] === 'tr' && code === 'TRY'
            || Item['code2'] === 'au' && code === 'AUD' || Item['code'] === 'au' && code === 'AUD'
            || Item['code2'] === 'br' && code === 'BRL' || Item['code'] === 'br' && code === 'BRL'
            || Item['code2'] === 'ca' && code === 'CAD' || Item['code'] === 'ca' && code === 'CAD'
            || Item['code2'] === 'cn' && code === 'CNY' || Item['code'] === 'cn' && code === 'CNY'
            || Item['code2'] === 'hk' && code === 'HKD' || Item['code'] === 'hk' && code === 'HKD'
            || Item['code2'] === 'id' && code === 'IDR' || Item['code'] === 'id' && code === 'IDR'
            || Item['code2'] === 'il' && code === 'ILS' || Item['code'] === 'il' && code === 'ILS'
            || Item['code2'] === 'in' && code === 'INR' || Item['code'] === 'in' && code === 'INR'
            || Item['code2'] === 'kr' && code === 'KRW' || Item['code'] === 'kr' && code === 'KRW'
            || Item['code2'] === 'mx' && code === 'MXN' || Item['code'] === 'mx' && code === 'MXN'
            || Item['code2'] === 'my' && code === 'MYR' || Item['code'] === 'my' && code === 'MYR'
            || Item['code2'] === 'nz' && code === 'NZD' || Item['code'] === 'nz' && code === 'NZD'
            || Item['code2'] === 'ph' && code === 'PHP' || Item['code'] === 'ph' && code === 'PHP'
            || Item['code2'] === 'sg' && code === 'SGD' || Item['code'] === 'sg' && code === 'SGD'
            || Item['code2'] === 'th' && code === 'THB' || Item['code'] === 'th' && code === 'THB'
            || Item['code2'] === 'za' && code === 'ZAR' || Item['code'] === 'za' && code === 'ZAR'
            || Item['code2'] === 'is' && code === 'ISK' || Item['code'] === 'is' && code === 'ISK'
            ) {
            // if (proceed) {
                flaglottery = Item['image']
                break
            } 
        }
    }
    // console.log (flaglottery)
    return (
        <div className = "td-center">
            {flaglottery !== undefined ?
            <img 
                height = "20"
                // width = "30"
                src = {flaglottery}
                style = {{border: '1px solid #999', maxWidth: '30px'}}
            /> : empty ? <div/> : <div className = "disabled">{Assets.None}</div>}
        </div>
    )
}
/*
export function FlagByCurrencyId(id: number, empty?: boolean): JSX.Element {
    let flaglottery: string
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        for (const item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
            if (item.code_id === id) {
                flaglottery = item['image20']
                break
            }
        }
    }

    return (
        <div className = "td-center">
            {flaglottery !== undefined ?
            <img 
                height = "20"
                // width = "30"
                src = {flaglottery}
                style = {{border: '1px solid #999', maxWidth: '30px'}}
            /> : empty ? <div/> : <div className = "disabled">{Assets.None}</div>}
        </div>
    )
}
*/
export function Image(src: string, id?: number, removeImageButton?: (id: string) => void, borderRadius?: number): JSX.Element {
    return (
        <div style = {{position: 'relative'}}>
            <img
                className = "img img-responsive"
                style = {{height: '34px', borderRadius: borderRadius ? borderRadius + 'px' : '0px'}}
                src = {src}
            />
            {removeImageButton && src && src.trim().length > 0 && src.length > 115 && id !== undefined?
            <button
                style = {{position: 'absolute', top: '-3px', right: '6px'}}
                className = "btn btn-xs btn-nothing mr0"
                title = {Assets.RemoveImage}
                onClick = {(click) => removeImageButton(id.toString())}
            >
                <i className = {Assets_.faCancelClass}/>
            </button> : void 0}
        </div>
    )
}

export function getCategory(category: number): string {       
    if (Main.Instance.FrontendStoreState.Category) {
    for (const Item of Main.Instance.FrontendStoreState.Category) {
        if (category + 1 === parseInt(Item['id'])) {
            return Item['name']
        }
    }
    }
    return ''
}

export function getSubentityType(value: number): string {
    let subEntityType = '?'
    if (value === 1) { 
        subEntityType = Assets.Site
    } else if (value === 2) { 
        subEntityType = Assets.GameAssetProvider
    } else if (value === 3) { 
        subEntityType = Assets.PaymentSubProvider
    } else if (value === 4) { 
        subEntityType = Assets.ComplianceSubProvider
    } else if (value === 5) { 
        subEntityType = Assets.Site
    }
    return subEntityType
}

export function editIndex(value: string, fn: (row: any, text: string) => void, row: any): JSX.Element {
    return (
        <FormInputNumber
            value = {value}
            ClassName = "h26 w45 pr0"
            type = "number"
            min = "0"
            onChange = {(text) => fn(row, text)} 
        />
    )
}

/*
export function Image(src: string, id: number, removeImageButton?: (id: string) => void, title?: string, borderRadius?: number): JSX.Element {
    if (!src || src.toString().trim().length === 0) return <div className = "disabled td-left">{Assets.None}</div>
    else return (
        <div
            style = {{position:'relative', zIndex: 9999, height: '500px', width: '500px'}}
            className = "td-left cursor_pointer"
        >
            {title === Assets.tLottery ?
            <img
                className = "img img-responsive"
                style = {{height: '20px', display: 'inline-block'}}
                src = {src}
            />

            :
            <i className = {Assets_.faImageClass}>
                <img
                    className = {title && title === Assets.tLottery ? 'show-on-hover-image scale25' : 'show-on-hover-image'}
                    style = {{
                        background: `url(${Assets_.checkered})`,
                        border: '1px solid #000',
                        position: 'absolute',
                        top: 'auto',
                        bottom: '0px',
                        right: 'auto',
                        left: '50px',
                        zIndex: 9999,
                        borderRadius: borderRadius ? borderRadius.toString() + 'px' : '0px'}}
                    src = {src}
                />
            </i>
            }
            {removeImageButton ?
            <button 
                className = "btn btn-xs btn-nothing mr0"
                title = {Assets.RemoveImage}
                onClick = {(click) => removeImageButton(id.toString())}
            >
                <i className = {Assets_.faCancelClass}/>
            </button>
            : void 0}
        </div>)
}
*/

export function AttrButton(column: number, row: any, attrButton?: (a:number, b:number, row?: any) => void): JSX.Element|undefined {

    if (attrButton) {

        const value: number = 0
/*        
        if (row.original.table_game.toString() === 'true') {
            value = 1
        }

        if (row.original.table_game_all.toString() === 'true') {
            value = 2
        }
*/
        if (value === 0) { 
            return <td className = "td-center" key = {column.toString()}>
                        <button 
                            className = "btn btn-xs btn-nothing mr0"
                            title = {Assets.NO}
                            style = {{color:'#d9534f'}}
                            // title = {Assets.attrButtonDesc}
                            onClick = {(click) => attrButton(column, value, row)}
                        >
                            <i className = {Assets_.faCircleClass}/>
                        </button>
                    </td>
        }

        return <td className = "td-center" key = {column.toString()}>
                    <button 
                        className = "btn btn-xs btn-nothing mr0"
                        title = {value === 1 ? Assets.YES : Assets.ANY}
                        style = {{color:'#5cb85c'}}
                        // style = {value === 1 ? {color:'#5cb85c'} : {color:'#337ab7'}}
                        // title = {Assets.attrButtonDesc}
                        onClick = {(click) => attrButton(column, value, row)}
                    >
                        <i className = {value === 1 ? Assets_.faCircleOClass : Assets_.faCircleClass}/>
                    </button>
                </td>
    }
    
    return void 0
}

export function getBanner(id: number): JSX.Element {
    let banner: string = ''
    let name: string = ''
    if (Main.Instance.DatabaseDataState.bannerStream) {
        for (const item of Main.Instance.DatabaseDataState.bannerStream) {
            if (+item['id'] === id) {
                name = item['name']
                banner = item['banners']
                break
            }
        }
    }
    return (
        <>
            <span>{name}</span>&nbsp;
            <img style = {{height: '50px',textAlign: 'center'}} src = {banner}/>
        </>
    )
}
export function LeaderboardButton(tournament: any, leaderboardButton: (t: any) => void): JSX.Element  {
    return (
        <button 
            className = "btn btn-xs btn-info mr0"
            title = {Assets.leaderboardButtonDesc}
            onClick = {(click) => leaderboardButton(JSON.parse (tournament))}
        >
            <i className = {Assets_.faListOlClass}/>
        </button>
    )
}

export function TournamentType(value: number): JSX.Element { 
    if (value === 0) { return <span className = "td-left" style = {{color: '#bbb'}}>{Assets_.NA}</span> } else { return <span className = "td-left">{Assets.TournamentType[value - 1]}</span> }
}

export function LeaderboardType(value: number): JSX.Element { 
    if (value === 0) { return <span className = "td-left" style = {{color: '#bbb'}}>{Assets_.NA}</span> } else { return <span className = "td-left">{Assets.LeaderBoard[value - 1]}</span> }
}

export function LeaderboardType2(value: number): JSX.Element { 
    if (value === 0) { return <span className = "td-left" style = {{color: '#bbb'}}>{Assets_.NA}</span> } else { return <span className = "td-left">{Assets.LeaderBoard2[value - 1]}</span> }
}

export function LeaderBoardTypeOddyzzey(value: number): JSX.Element { 
    if (value === 0) { return <span className = "td-left" style = {{color: '#bbb'}}>{Assets_.NA}</span> } else { return <span className = "td-left">{Assets.LeaderBoardOddyzzey[value - 1]}</span> }
}

export function TournamentCurrent(value: number): JSX.Element {
    let symbol: JSX.Element = <i style = {{color:'#5cb85c'}} className = {Assets_.faCheckButtonClass}/>
    let description: string = Assets.Valid0
    if (value === 2) {
        symbol = <i style = {{color: 'rgb(243,139,0)'}} className = {Assets_.faNextButtonClass}/>
        description = Assets.Valid2
    } else if (value === 1) {
        symbol = <i style = {{color:'#d9534f'}} className = {Assets_.faPrevButtonClass}/>
        description = Assets.Valid1
    }

    return (<div title = {description} key = "valid" className = "td-center">{symbol}</div>)    
}

export function ChallengeDescription(row: any): JSX.Element {
    if (row) {
        const C = JSON.parse(JSON.stringify(row.value))
        const challenges: JSX.Element[] = []
        if (C.minBet) { challenges.push(<span key = "1" className = "label label-info label-sm mr">{Assets.MinBet + ': ' + C.minBet}</span>) }
        if (C.minRounds) { challenges.push(<span key = "2" className = "label label-info label-sm mr">{Assets.MinRounds + ': ' + C.minRounds}</span>) }
        if (C.rewardId) {
            let reward: string = ''
            if (Main.Instance.DatabaseDataState.rewardShopStreamAll) {
            for (const Item of Main.Instance.DatabaseDataState.rewardShopStreamAll) {
                if (+Item['id'] === C.rewardId) { reward = Item['name']; break}
            }
            }
            challenges.push(<span key = "3" className = "label label-info label-sm">{'Reward: ' + reward}</span>)
        }
        if (C.bigWinValue) { challenges.push(<span key = "4" className = "ml label label-info label-sm">{Assets.BigWin + ': ' + C.bigWinValue}x</span>) } else if (row.original['Challenge Type'] === 'Big wins count') { challenges.push(<span key = "4" className = "ml label label-info label-sm">{Assets.BigWin + ': 15'}x</span>) }
        return (<div className = "consecutive_bonus">{challenges}</div>)
    }
    return (<div>&mdash;</div>)
}

export function getSubLevel(row: any): JSX.Element {
    if (row.value === row.original.level) {
        if (row.original.final_level) { return <span title = {Assets.FINALLEVELDesc} className = "label label-primary label-xs">{Assets.FINALLEVEL}</span> } else { return <span title = {Assets.LEVELDesc} className = "label label-info label-xs">{Assets.LEVEL}</span> }
    } else { return <span>{row.value}</span> }
}

export function getTokens(row: any): JSX.Element {
    if (row.original.level === row.original.sublevel) { return <span>{row.value}</span> } else {
        const num: JSX.Element[] = []
        let Num: string = ''
        if (Main.Instance.DatabaseDataState.levelsAllStream) {
        for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
            if (item['hierarchy'] === row.original.hierarchy) {
                for (let i: number = row.original.sublevel_min; i <= row.original.sublevel_max; i++) {
                    if ((i-1) % 25 === 0 && i !== 1) {
                        num.push (<div style = {{lineHeight: '16px'}} key = {i}>{Num}</div>)
                        Num = ''
                    }
                    if (Num.length > 0) { Num += ', ' }
                    Num += item['custom']['tokens'][i - 1]
                }
            }
        }
        }
        num.push (<div style = {{lineHeight: '16px'}} key = "0">{Num}</div>)
        return <div>{num}</div>
    }
}

export function getRewardIcon(small: boolean, value: number): JSX.Element {
    let reward: string = ''
    if (Main.Instance.DatabaseDataState.rewardShopStreamAll) {
    for (const Item of Main.Instance.DatabaseDataState.rewardShopStreamAll) { if (Item['id'] === value) { reward = Item['name']; break} }
    }
    return (<div style = {{textAlign: 'center'}} title = {reward}><i className = {small ? Assets_.faRewardClass : Assets_.faRewardLgClass}/></div>)
}

export function getConsecutiveBonus(cb: any): JSX.Element {
    const C = JSON.parse(JSON.stringify(cb))
    const CB: JSX.Element[] = []
    for (let ii: number = 0; ii < C.length; ii++) {
        CB.push (
            <span
                className = "label label-info label-sm"
                title = {Assets.Minimum + ' ' + Assets.Days.toLowerCase() + ', ' + Assets.Bonus + ' ' + Assets.Percentage.toLowerCase()}
                key = {ii.toString()}
                style = {ii < C.length - 1 ? {marginRight: '10px'} : {marginRight: '0px'}}
            >
                {C[ii].minDays} {Assets.Days.toLowerCase()}, {C[ii].bonus} %
            </span>)
    }
    return (<div className = "consecutive_bonus">{CB}</div>)
}