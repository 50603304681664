import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildMyChance, injectSpaces} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave, faCoins} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {RewardTypes} from '../../../Logic/Database/rewards/rewardsEnums'

export function getStoreCount() {
        DbRoutes.WsGetStore()
}

function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.WsGetStore()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' store ' + Assets.withName + ' "' + row.original.name + '"?')) {
            if (Main.Instance.StoreState.pNum === (Math.trunc((Main.Instance.StoreState.count - 2) / Main.Instance.StoreState.pSize) + 1)) {
                DbRoutes.removeStore (row.original.id, DeleteLastPage)
            } else {
                DbRoutes.removeStore (row.original.id, DeleteNotLastPage)
            }
        }
    }
}

function updateName(name: string) {
    Logic.Type.New (Main.Instance.StoreState, {name})    
}

function handleEdit(row: any) {
    gotoTop()
    if (row) {
        const rewards: any = row.original.rewards
        const filters: any = JSON.parse(row.original.boosters)
        // console.log(rewards)
/*      
        let storeType: string = '0'
        if (rowData[3].toString() === 'Bets count') storeType = '1'
        else if (rowData[3].toString() === 'Wins count') storeType = '2'
        else if (rowData[3].toString() === 'Consecutive count') storeType = '3'
        else if (rowData[3].toString() === 'Big wins count') storeType = '4'
        conditions.minbet = conditions.minBet.toString()
*/
        // console.log (conditions)
        if (filters.minLevel) { filters.min = 0 
        } else { filters.min = -1 }

        if (filters.maxLevel) { filters.max = 0 
        } else { filters.max = -1 }

        Logic.Type.New(Main.Instance.StoreState, {
            mode: true,
            id: row.original.id,
            name: row.original.name,
            name_saved: row.original.name,
            filters,
            rewards,
        })
    }
}

function handleInsertUpdate() {
    const storeItem = {
        id : !Main.Instance.StoreState.id ? '' : Main.Instance.StoreState.id,
        name: !Main.Instance.StoreState.name ? '' : Main.Instance.StoreState.name,
        filters: Main.Instance.StoreState.filters,
        rewards: Main.Instance.StoreState.rewards,
    }

    delete(storeItem.filters.min)
    delete(storeItem.filters.max)

    for (const item of Main.Instance.DatabaseDataState.storeStream) {
        if (Main.Instance.StoreState.mode === false && item.name.toLowerCase() === Main.Instance.StoreState.name.toLowerCase()) {
            alert (Assets.StoreName + ' ' + Assets.alreadyInDatabase)
            return
        }
    }

/*
    if (storeItem.conditions === undefined) {
        alert('Store reward needs to be set.')
    }

    if (isNaN(storeItem.conditions.minbet as any)) {
        alert ('Minimal bet is not formatted correctly')
        return
    }
    storeItem.conditions.minBet = parseFloat(storeItem.conditions.minbet)
    delete(storeItem.conditions.minbet)
*/
    // console.log (storeItem)

    if (Main.Instance.StoreState.name.length === 0) {
        alert (Assets.compulsoryName)
/*        
    } else if (Main.Instance.StoreState.store_type === undefined || Main.Instance.StoreState.store_type.length === 0 || 
                parseInt(Main.Instance.StoreState.store_type) === 0) {
        alert ('Store type needs to be selected.')
    } else if (Main.Instance.StoreState.date === null || Main.Instance.StoreState.date === undefined) {
        alert('Store date needs to be set.')
    } else if (Main.Instance.StoreState.conditions === undefined && Main.Instance.StoreState.conditions.rewardId === undefined
               || parseInt(Main.Instance.StoreState.conditions.rewardId) === 0) {
        alert('Store reward needs to be set.')
    } else if (!validationFromTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationToTime) {
        alert (Assets.compulsoryCorrectTime)
 */
    } else if (Main.Instance.StoreState.mode === false) {
         DbRoutes.WSinsertStore(storeItem)
    } else {
        // console.log(JSON.stringify(storeItem))
        DbRoutes.WSupdateStore(storeItem)
    }
}

function getStore() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {storeStream: undefined})
    getStoreCount()
}

function  clearFields() {
    Logic.Type.New(Main.Instance.StoreState, {
        name : '',
        mode : false,
        id: '0',
        // date: null,
        reward: 0,
        rewards: [],
        filters: {},
        rewardType: '0',
        freeSpins: '-1'
    })
}

function gotoInsertMode() {
    // handleEdit
    if (Main.Instance.StoreState.name === Main.Instance.StoreState.name_saved // &&
        // Main.Instance.StoreState.date_string === Main.Instance.StoreState.date_string_saved &&
        // parseInt(Main.Instance.StoreState.store_type) === parseInt(Main.Instance.StoreState.store_type_saved)
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.StoreState, {offset:(0).toString()})

    getStore()
    Logic.Type.New(Main.Instance.StoreState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.StoreState.pNum > 0) {

        Logic.Type.New(Main.Instance.StoreState, {
            offset:((Main.Instance.StoreState.pNum - 1) * 
            Main.Instance.StoreState.pSize).toString()})
        getStore()

        Logic.Type.New(Main.Instance.StoreState,{pNum: Main.Instance.StoreState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.StoreState.pNum + 1) * Main.Instance.StoreState.pSize < 
    Main.Instance.StoreState.count) {

        Logic.Type.New(Main.Instance.StoreState, {
            offset: ((Main.Instance.StoreState.pNum + 1) * 
            Main.Instance.StoreState.pSize).toString()})
        getStore()
        
        Logic.Type.New(Main.Instance.StoreState,{pNum: Main.Instance.StoreState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.StoreState.count <= Main.Instance.StoreState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.StoreState.count === 0) {

            Logic.Type.New(Main.Instance.StoreState, {offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.StoreState.count - 1) / 
                    Main.Instance.StoreState.pSize)
            Logic.Type.New(Main.Instance.StoreState, {
                offset:(npNum * Main.Instance.StoreState.pSize).toString()})
        }

        getStore()

        Logic.Type.New(Main.Instance.StoreState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.StoreState, {count:Main.Instance.StoreState.count++})
    LastPage()
}

export function Update() {
    clearFields()
    getStore()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.StoreState,{count:Main.Instance.StoreState.count--})
    LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.StoreState,{count:Main.Instance.StoreState.count--})
    getStore()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.StoreState, {pSize: parseInt(text)})
        FirstPage()
    }
}
/*
function SetMinRounds(text: string) {
    if (parseInt(text) > -1) {
        let conditions: any = Object.assign({}, Main.Instance.StoreState.conditions)
        conditions.minRounds = parseInt(text)
        Logic.Type.New (Main.Instance.StoreState, {conditions})
    }   
}

function SetReward(text: string) {
    if (parseInt(text) > -1) {
        let conditions: any = Object.assign({}, Main.Instance.StoreState.conditions)
        conditions.rewardId = +text
        Logic.Type.New (Main.Instance.StoreState, {conditions})
    }   
}

function SetBigWin(text: string) {
    if (parseInt(text) > 0) {
        let conditions: any = Object.assign({}, Main.Instance.StoreState.conditions)
        conditions.bigWinValue = parseInt(text)
        Logic.Type.New (Main.Instance.StoreState, {conditions})
    }   
}

function SetMinimumBet(text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        let conditions: any = Object.assign({}, Main.Instance.StoreState.conditions)
        conditions.minbet = text
        Logic.Type.New (Main.Instance.StoreState, {conditions})
    }
}
*/
function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getStoreCount()
    }
}
/*
function DateChange(date: moment.Moment) {

    let temp: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    Logic.Type.New(Main.Instance.StoreState, {date_string: temp, date})
}

let storeTypes = ['Bets count', 'Wins count', 'Consecutive wins count', 'Big wins count']

function SelectStoreType(which: number) {
    Logic.Type.New (Main.Instance.StoreState, {store_type: which.toString()})
}

function getStoreTypes(): JSX.Element[] {
    let ct:JSX.Element[] = []
    ct.push(
        <button
            title = {'Select the Store type'}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {0 === parseInt(Main.Instance.StoreState.store_type) ? 'btn btn-danger btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => SelectStoreType(0)}
        >
        <i className = {Assets_.faStoreClass}/>&nbsp;&nbsp;{'Store Type'}
        </button>
    )

    for (let i: number = 0; i < storeTypes.length; i++) {
        ct.push(
            <button
                title = {'Select the Store type'}
                style = {{borderRadius: '0px'}}
                key = {(i+1).toString()}
                className = {i + 1 === parseInt(Main.Instance.StoreState.store_type) ? 'btn btn-primary btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectStoreType(i+1)}
            >
            {storeTypes[i]}
            </button>
        )
    }

    return ct
}
*/
export function Store() {
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
    
    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets.reactDatesGreyColor}/>    
    return (<div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {IsBuildMyChance() ? Assets.AddEditShop : Assets.AddEditStore}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"
            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.StoreState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>{IsBuildMyChance() ? Main.Instance.StoreState.mode ? Assets.EditTheShop + ': ' + Main.Instance.StoreState.name_saved  : Assets.AddANewShop : Main.Instance.StoreState.mode ? Assets.EditTheStore + ': ' + Main.Instance.StoreState.name_saved  : Assets.AddANewStore}</h4>

                        {Main.Instance.StoreState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}
                    </div>

                    <div className = "row admin-users-row">

                    <FormInput
                        narrow = {true}
                        description = {Assets.StoreName}
                        value = {!Main.Instance.StoreState.name ? '' :
                                Main.Instance.StoreState.name} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => updateName(text)} 
                        placeholder = {Assets.pName} 
                        icon = {Assets_.faStoreClass + ' fa-lg'}
                    />
                    {/*console.log(Main.Instance.StoreState.filters)*/}
                    {/*console.log(Main.Instance.DatabaseDataState.levelsAllStream)*/}
                    {/*console.log(Main.Instance.StoreState.filters.minLevel.level - 1)*/}
                    {/*console.log(Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.minLevel.level - 1])*/}
                    
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MinLevel}
                        className = {Main.Instance.StoreState.filters && 
                                    Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 ?
                                    'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(4)}
                    >
                        {Main.Instance.DatabaseDataState.levelsAllStream && Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.minLevel && Main.Instance.StoreState.filters.minLevel.level &&
                        Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 && Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.minLevel.level - 1] ?
                        Assets.MinLevel + (!IsBuildMyChance() ? ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.minLevel.level - 1]['name'] : '') : 
                        Main.Instance.StoreState.filters && 
                        Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 && Main.Instance.DatabaseDataState.levelsAllStream[0] ?
                        Assets.MinLevel + (!IsBuildMyChance() ? ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[0]['name'] : '') : 'Min. level'}
                    </button>
                    {Main.Instance.StoreState.filters !== undefined && Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MinLevel}
                                ClassName = "h30 w40px pl3 pr0"
                                value = {Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.minLevel !== undefined ?
                                        Main.Instance.StoreState.filters.minLevel.level.toString() : '1'}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream.length + 1).toString()}
                                onChange = {(text) => MinLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div> : void 0}
                    {!IsBuildMyChance() && Main.Instance.StoreState.filters && 
                    Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 ?
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MinLevel}
                        className = {Main.Instance.StoreState.filters.minLevel !== undefined && Main.Instance.StoreState.filters.minLevel.sublevel !== undefined ?
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-warning btn-sm dbl fl ml0 mr0'}
                        onClick = {() => {
                            const filters = Object.assign({}, Main.Instance.StoreState.filters)
                            if (filters.minLevel === undefined) { filters.minLevel = {level: 1} }
                            if (filters.minLevel.sublevel === undefined) { filters.minLevel.sublevel = 1 } else { delete(filters.minLevel.sublevel) }
                            Logic.Type.New (Main.Instance.StoreState, {filters})
                        }
                        }
                    >
                        {Assets.Sub}
                    </button> : void 0}
                    {!IsBuildMyChance() && Main.Instance.StoreState.filters !== undefined && Main.Instance.StoreState.filters.min !== undefined && Main.Instance.StoreState.filters.min !== -1 && 
                    Main.Instance.StoreState.filters.minLevel && Main.Instance.StoreState.filters.minLevel.sublevel !== undefined ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.Toggle + ' ' + Assets.MinLevel}
                                ClassName = "h30 w50px pl3 pr0"
                                value = {Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.minLevel !== undefined ?
                                         Main.Instance.StoreState.filters.minLevel.sublevel.toString() : '1'}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.minLevel.level - 1]['sublevels'] + 1).toString()}
                                onChange = {(text) => MinSubLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div>
                    : void 0}
                    
                        <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MaxLevel}
                        className = {Main.Instance.StoreState.filters && 
                                    Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 ?
                                    'btn btn-success btn-sm dbl fl ml mr0' : 'btn btn-danger btn-sm dbl fl ml mr0'}
                        onClick = {() => Toggle(5)}
                    >
                        {Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.maxLevel && Main.Instance.StoreState.filters.maxLevel.level &&
                        Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 && Main.Instance.DatabaseDataState.levelsAllStream && Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.maxLevel.level - 1] ?
                        Assets.MaxLevel + (!IsBuildMyChance() ? ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.maxLevel.level - 1]['name'] : '') :
                        Main.Instance.StoreState.filters && 
                        Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 && Main.Instance.DatabaseDataState.levelsAllStream && Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.DatabaseDataState.levelsAllStream.length - 1] && Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.DatabaseDataState.levelsAllStream.length - 1]['name'] ?
                        Assets.MaxLevel + (!IsBuildMyChance() ? ' - ' + Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.DatabaseDataState.levelsAllStream.length - 1]['name'] : '') : 'Max. level'}
                    </button>
                    
                    {Main.Instance.StoreState.filters !== undefined && Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MaxLevel}
                                ClassName = "h30 w40px pl3 pr0"
                                value = {Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.maxLevel !== undefined ?
                                        Main.Instance.StoreState.filters.maxLevel.level.toString() :
                                        Main.Instance.DatabaseDataState.levelsAllStream.length.toString()}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream.length + 1).toString()}
                                onChange = {(text) => MaxLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div> : void 0}
                    {!IsBuildMyChance() && Main.Instance.StoreState.filters && 
                    Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 ?
                    <button
                        style = {{borderRadius: '0px'}}
                        title = {Assets.Toggle + ' ' + Assets.MaxLevel}
                        className = {Main.Instance.StoreState.filters.maxLevel !== undefined && Main.Instance.StoreState.filters.maxLevel.sublevel !== undefined ?
                                    'btn btn-success btn-sm dbl fl ml0 mr0' : 'btn btn-warning btn-sm dbl fl ml0 mr0'}
                        onClick = {() => {
                            const filters = Object.assign({}, Main.Instance.StoreState.filters)
                            if (filters.maxLevel === undefined) { filters.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length} }
                            if (filters.maxLevel.sublevel === undefined) { filters.maxLevel.sublevel = 
                                Main.Instance.DatabaseDataState.levelsAllStream[filters.maxLevel.level - 1]['sublevels']
                            } else { delete(filters.maxLevel.sublevel) }
                            Logic.Type.New (Main.Instance.StoreState, {filters})
                        }
                        }
                    >
                        {Assets.Sub}
                    </button> : void 0}
                    {!IsBuildMyChance() && Main.Instance.StoreState.filters && Main.Instance.StoreState.filters !== undefined &&
                    Main.Instance.StoreState.filters.max !== undefined && Main.Instance.StoreState.filters.max !== -1 && 
                    Main.Instance.StoreState.filters.maxLevel && Main.Instance.StoreState.filters.maxLevel.sublevel !== undefined ?
                        <div className = "fl">
                            <FormInputNumber
                                description = {Assets.MaxSublevel}
                                ClassName = "h30 w50px pl3 pr0"
                                value = {Main.Instance.StoreState.filters && Main.Instance.StoreState.filters.maxLevel !== undefined ?
                                         Main.Instance.StoreState.filters.maxLevel.sublevel.toString() : 
                                        Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.maxLevel.level - 1]['sublevels'].toString()}
                                type = "number"
                                min = "0"
                                max = {(Main.Instance.DatabaseDataState.levelsAllStream[Main.Instance.StoreState.filters.maxLevel.level - 1]['sublevels'] + 1).toString()}
                                onChange = {(text) => MaxSubLevelChanged(text)}
                                noIconTextFix = {true}
                            />
                        </div>
                    : void 0}
{/*
                <div className = "fl mr singledatepicker">
                    <SingleDatePicker
                        id = "date9"
                        placeholder = {Assets.Date}
                        date = {Main.Instance.StoreState.date}
                        onDateChange = {date => DateChange(date)}
                        focused = {Main.Instance.StoreState.focusedInput}
                        onFocusChange = {focused => Logic.Type.New(Main.Instance.StoreState, {focusedInput: focused.focused})}
                        showDefaultInputIcon = {true}
                        showClearDate = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets.dateDisplayFormatdmy}
                        customCloseIcon = {Assets_.customCloseIcon}
                        numberOfMonths = {1}
                        isDayHighlighted = {(date) => HighlightToday(date)}
                    />
                </div>
                {getStoreTypes()}
*/}
                {/*Main.Instance.DatabaseDataState.rewardShopStreamAll ? alert (JSON.stringify(Main.Instance.DatabaseDataState.rewardShopStreamAll)) : void 0*/}
                {Main.Instance.StoreState.reward ?
                <span className = "fl dbl">&nbsp;&nbsp;</span> : void 0}
                {Main.Instance.StoreState.reward ?
                <FormSelect
                    rewardFor = {IsBuildMyChance() ? undefined : 5}
                    title = {Assets.RewardName}
                    value = {Main.Instance.StoreState.rewards === undefined ||
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1] === undefined ||
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1]['rewardId'] === undefined ? '0' :
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1]['rewardId'].toString()}
                    data = {IsBuildMyChance() ? getRewardsData(Main.Instance.StoreState.rewardType, Main.Instance.StoreState.freeSpins) : Main.Instance.DatabaseDataState.rewardShopStreamAll}
                    ClassName = "form-control wi100"
                    o1 = {Assets.SelectReward}
                    o2 = {Assets.SelectReward}
                    onChange = {(text) => ApplyRewardSelect(text)}
                    icon = {Assets_.faRewardLgClass}
                /> : void 0}
                {Main.Instance.StoreState.reward ?
                <FormInputNumber
                    description = {Assets.RewardPrice + ' (' + Assets.Coins.toLowerCase() + ')'}
                    outerDiv = "rows-per-table input-group fl mr0 ml pl0"
                    ClassName = "h30 w60"
                    value = {Main.Instance.StoreState.rewards === undefined ||
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1] === undefined ||
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1]['price'] === undefined ? '0' :
                            Main.Instance.StoreState.rewards[Main.Instance.StoreState.reward - 1]['price'].toString()}
                    type = "number" 
                    min = "1"
                    Icon = {faCoins}
                    // icon = {Assets_.faPaymentClass}
                    // noIconTextFix = {true}
                    // iconText = {Assets.Price}
                    // iconTextS = "14px"
                    onChange = {(text) => SetPrice(text)}
                /> : void 0}
                {IsBuildMyChance() ?
                <FormSelect
                    narrow = {true}
                    floatRight = {true}
                    title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.FreeSpinsAmount.toLowerCase()}
                    value = {Main.Instance.StoreState.freeSpins}
                    data = {getFreeSpinsData(Main.Instance.StoreState.rewardType)}
                    ClassName = "form-control wi100"
                    disabled = {Main.Instance.DatabaseDataState.strapiConnected === false || Main.Instance.StoreState.rewardType !== '1' && Main.Instance.StoreState.rewardType !== '9' ? true : false}
                    onChange = {(text) => Logic.Type.New(Main.Instance.StoreState, {freeSpins: text})}
                    icon = {Assets_.faHashtagClass}
                /> : void 0}
                {IsBuildMyChance() ?
                <FormSelect
                    narrow = {true}
                    floatRight = {true}
                    title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.Type.toLowerCase()}
                    value = {Main.Instance.StoreState.rewardType}
                    data = {Main.Instance.FrontendStoreState.Category}
                    ClassName = "form-control "
                    
                    onChange = {(text) => Logic.Type.New(Main.Instance.StoreState, {rewardType: text, freeSpins: '-1'})}
                    icon = {Assets_.faRewardLgClass}
                /> : void 0}
                {IsBuildMyChance() ? <span style = {{marginTop: '5px'}} className = "fr dbl ml mr">{Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + ':'}</span> : void 0}
                
            </div>
            {IsBuildMyChance() ? 
            <h5 className = "row admin-users-container clearfix mb">
                {Assets.Selected + ' ' + Assets.RewardType + ': ' + 
                (Main.Instance.StoreState.rewardType === '0' ? Assets.All : injectSpaces(RewardTypes[Main.Instance.StoreState.rewardType])) +
                (Main.Instance.StoreState.rewardType === '1' ? ',   ' + Assets.FreespinAmount + ': ' + (Main.Instance.StoreState.freeSpins === '-1' ? Assets.All : Assets_.RTFreeSpinsAmount[Main.Instance.StoreState.freeSpins]) : 
                Main.Instance.StoreState.rewardType === '9' ? ',   ' + Assets.MegaspinAmount + ': ' + (Main.Instance.StoreState.freeSpins === '-1' ? Assets.All : Assets_.RTMegaSpinsAmount[Main.Instance.StoreState.freeSpins]) : '')  }
            </h5> : void 0}
            <div className = "row admin-users-row clearfix">
                {getRewards()}
            </div>
            <div className = "row admin-users-container clearfix">
                {/* <ButtonIcon 
                    description = {'Reset'} 
                    click = {() => resetRewards()}
                    ClassName = {'btn btn-danger btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    
                    text = {'Reset'}
                /> */}
                <ButtonIcon 
                    description = {!Main.Instance.StoreState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.StoreState.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>
            </div>
        {IsBuildMyChance() ? void 0 :
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.StoreState.pSize ? '' : Main.Instance.StoreState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.StoreState.count ? Main.Instance.StoreState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.StoreState.count ? Main.Instance.StoreState.pNum * 
                Main.Instance.StoreState.pSize + 1 : 0}{' - '}
                {Main.Instance.StoreState.count ? 
                ((Main.Instance.StoreState.pNum + 1) * 
                Main.Instance.StoreState.pSize > Main.Instance.StoreState.count ? 
                Main.Instance.StoreState.count : 
                (Main.Instance.StoreState.pNum + 1) * Main.Instance.StoreState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.StoreState.count ? 
            Main.Instance.StoreState.pNum + 1 :0}{' / '}
            {Main.Instance.StoreState.count ? 
            Math.trunc((Main.Instance.StoreState.count - 1) /
            Main.Instance.StoreState.pSize + 1) : 0}
        </span>
        </div>}
        <h3>{IsBuildMyChance() ? Assets.ListOfShops : Assets.ListOfStores}</h3>
        {/* console.log(Main.Instance.StoreState.count) */}
        {/* console.log(Main.Instance.DatabaseDataState.storeStream) */}
        {Main.Instance.DatabaseDataState.storeStream && Main.Instance.DatabaseDataState.storeStream.length ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.storeStream}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {show: IsBuildMyChance(), width: 40, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                {width: 150, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {Header: '# ' + Assets.Rewards.toLowerCase(), accessor: '# rewards', headerClassName: 'th-left'},
                {show: IsBuildMyChance(), Header: Assets.Min_Level, accessor: 'boosters', headerClassName: 'th-left', Cell: (row) => MinMaxLevelGet(row.value, 0)},
                {show: IsBuildMyChance(), Header: Assets.Max_Level, accessor: 'boosters', headerClassName: 'th-left', Cell: (row) => MinMaxLevelGet(row.value, 1)},
                {show: !IsBuildMyChance(), Header: Assets.Min_Level, accessor: 'MinLevel', headerClassName: 'th-left'},
                {show: !IsBuildMyChance(), Header: Assets.MinSublevel, accessor: 'MinSublevel', headerClassName: 'th-left'},
                {show: !IsBuildMyChance(), Header: Assets.Max_Level, accessor: 'MaxLevel', headerClassName: 'th-left'},
                {show: !IsBuildMyChance(), Header: Assets.MaxSublevel, accessor: 'MaxSublevel', headerClassName: 'th-left'},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete, IsBuildMyChance() ? Main.Instance.DatabaseDataState.storeStream.length <= 1 ? true: false : undefined)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={IsBuildMyChance() ? Main.Instance.DatabaseDataState.storeStream.length : 100}
        /> : void 0}

        {/*console.log (Main.Instance.DatabaseDataState.storeStream)*/}
        {/*Main.Instance.DatabaseDataState.storeStream !== null && Main.Instance.DatabaseDataState.storeStream !== undefined && 
        Main.Instance.DatabaseDataState.storeStream.length ?
        StremingTable(Main.Instance.DatabaseDataState.storeStream, {
            title: 'List of Stores', 
            mode: Main.Instance.StoreState.mode,
            editingId: Main.Instance.StoreState.id,
            sort: getStoreCount,
            Sort: Main.Instance.StoreState,
            delButton:(data) => handleDelete(data),
            editButton:(data) => handleEdit(data),
        })
        : Main.Instance.FrontendStoreState.site_id ? void 0 : void 0*/}
        </div>
    </div>)
}
/*
function HighlightToday(date: moment.Moment): boolean {
    if (date.format(Assets.dateDisplayFormat) === (new Date()).toISOString().substring(0,10)) return true
    return false
}
*/

function MinMaxLevelGet(row, which) {
    const data = JSON.parse(row)
    if (which === 0) {
        if (data.minLevel) {
            return data.minLevel.level
        }
    } else if (which === 1) {
        if (data.maxLevel) {
            return data.maxLevel.level
        }
    } 
    return ''
}

function Toggle(Which: number) {
    const filters = Object.assign ({}, Main.Instance.StoreState.filters)
    if (Which === 4 || Which === 5) {
        if (Which === 4) {
            if (filters.min === undefined || filters.min === -1) {
                filters.minLevel = {level: 1}
                filters.min = 0
            } else {
                delete(filters.minLevel)
                filters.min = -1
            }
        } else if (Which === 5) {
            if (filters.max === undefined || filters.max === -1) {
                filters.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length}
                filters.max = 0
            } else {
                delete(filters.maxLevel)
                filters.max = -1
            }
        }
    }
    Logic.Type.New (Main.Instance.StoreState, {filters})
}

function MinLevelChanged(minLevel: string) {
    const filters = Object.assign ({}, Main.Instance.StoreState.filters)
    if (filters.minLevel === undefined) { filters.minLevel = {level: 1} }
    filters.minLevel.level = +minLevel
    if (filters.minLevel.level > Main.Instance.DatabaseDataState.levelsAllStream.length) { filters.minLevel.level = 1 } else if (filters.minLevel.level === 0) { filters.minLevel.level = Main.Instance.DatabaseDataState.levelsAllStream.length }
    Logic.Type.New (Main.Instance.StoreState, {filters})
}

function MinSubLevelChanged(minLevel: string) {
    const filters = Object.assign ({}, Main.Instance.StoreState.filters)
    if (filters.minLevel === undefined) { filters.minLevel = {level: 1, sublevel: 1} }
    filters.minLevel.sublevel = +minLevel
    if (filters.minLevel.sublevel > Main.Instance.DatabaseDataState.levelsAllStream[filters.minLevel.level - 1]['sublevels']) { filters.minLevel.sublevel = 1 } else if (filters.minLevel.sublevel === 0) { filters.minLevel.sublevel = Main.Instance.DatabaseDataState.levelsAllStream[filters.minLevel.level - 1]['sublevels'] }
    Logic.Type.New (Main.Instance.StoreState, {filters})
}

function MaxLevelChanged(maxLevel: string) {
    const filters = Object.assign ({}, Main.Instance.StoreState.filters)
    if (filters.maxLevel === undefined) { filters.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length} }
    filters.maxLevel.level = +maxLevel
    if (filters.maxLevel.level > Main.Instance.DatabaseDataState.levelsAllStream.length) { filters.maxLevel.level = 1 } else if (filters.maxLevel.level === 0) { filters.maxLevel.level = Main.Instance.DatabaseDataState.levelsAllStream.length }
    Logic.Type.New (Main.Instance.StoreState, {filters})
    // console.log (filters)
}

function MaxSubLevelChanged(maxLevel: string) {
    const filters = Object.assign ({}, Main.Instance.StoreState.filters)
    if (filters.maxLevel === undefined) {
        filters.maxLevel = {level: Main.Instance.DatabaseDataState.levelsAllStream.length, sublevel: Main.Instance.DatabaseDataState.levelsAllStream[filters.maxLevel.level - 1]['sublevels']}
    }
    filters.maxLevel.sublevel = +maxLevel
    if (filters.maxLevel.sublevel > Main.Instance.DatabaseDataState.levelsAllStream[filters.maxLevel.level - 1]['sublevels']) { filters.maxLevel.sublevel = 1 } else if (filters.maxLevel.sublevel === 0) { filters.maxLevel.sublevel = Main.Instance.DatabaseDataState.levelsAllStream[filters.maxLevel.level - 1]['sublevels'] }
    Logic.Type.New (Main.Instance.StoreState, {filters})
}

function getRewards(): JSX.Element[] {
    const rewards: JSX.Element[] = []
    rewards.push (
        <button
            title = {Assets.Rewards}
            key = "-1"
            onClick = {() => SelectReward(0)}
            style = {{borderRadius: '0px'}}
            className = {Main.Instance.StoreState.reward === 0 ? 'fl dbl btn btn-danger btn-sm mr0' : 'fl dbl btn btn-default btn-sm mr0'}
        >
            <i className = {Assets_.faRewardLgClass}/>
        {/*Main.Instance.StoreState.reward === 0 ? <span>{' Rewards'}</span> : void 0*/}
        </button>,
    )
    let bonusCode: string = ''
    

            
    // console.log(Main.Instance.StoreState.rewards)
    for (let i: number = 0; i < Main.Instance.StoreState.rewards.length; i++) {
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
            if (item.rewardId === Main.Instance.StoreState.rewards[i].id) {
                if (item.numOfFreeSpins) {
                    bonusCode = ' | ' + item.numOfFreeSpins
                } else {
                    bonusCode = ''
                }
                // console.log( Main.Instance.StoreState.rewards)
                rewards.push (
                    <button
                        
                        title = {injectSpaces(RewardTypes[Main.Instance.StoreState.rewards[i].rewardType]) + bonusCode}
                        key = {i.toString()}
                        onClick = {() => SelectReward(i + 1)}
                        style = {{borderRadius: '0px', display: getDisplayStyle(Main.Instance.StoreState.rewards[i].rewardId)}}
                        className = {Main.Instance.StoreState.reward === i + 1 ? Main.Instance.StoreState.rewards[i]['rewardId'] ? 'fl dbl btn btn-primary btn-sm mr0' : 
                                    'fl dbl btn btn-warning btn-sm mr0' : 'fl dbl btn btn-default btn-sm mr0'}
                    >
                        {IsBuildMyChance() &&  Main.Instance.StoreState.rewards[i]['name'] && Main.Instance.StoreState.rewards[i]['name'] !== '' ? Main.Instance.StoreState.rewards[i]['name'] : Assets.Reward + ' ' + (i + 1).toString()}
                    </button>,
                )
        
                if (Main.Instance.StoreState.reward === i + 1) {
                    rewards.push (
                        <button
                            title = {Assets.RemoveReward}
                            key = "-3"
                            onClick = {() => RemoveReward()}
                            style = {{borderRadius: '0px'}}
                            className = "fl dbl btn btn-danger btn-sm mr0"
                        >
                            <i className = {Assets_.faTrashLgButtonClass}/>
                        </button>,
                    )
                }
                break
            }
        }
        
    }
    
    rewards.push (
        <button
            title = {Assets.AddReward}
            key = "-2"
            onClick = {() => AddReward()}
            style = {{borderRadius: '0px'}}
            className = "fl dbl btn btn-success btn-sm mr0"
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )

    return rewards
}

function getDisplayStyle(id: string): string {
    
    const reg = new RegExp(/\d+[fm]s/i)
    // console.log(bonusCode)
    // console.log(Main.Instance.StoreState.rewardType)
    // console.log(id)
    // console.log(Main.Instance.DatabaseDataState.rewardShopStream)
    for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
        if (!IsBuildMyChance()) {
            return 'block'
        } else if (id === item.id) {
            if ((Main.Instance.StoreState.rewardType !== '0' || Main.Instance.StoreState.freeSpins !== '-1') && item['Reward type'] !== '0') {
                if (Main.Instance.StoreState.rewardType !== '0') {
                    if (item['Reward type'] === undefined || item['Reward type'] === '' || parseInt(item['Reward type']) !== parseInt(Main.Instance.StoreState.rewardType)) {
                        // console.log('1')
                        return 'none'
                        
                    } else if (Main.Instance.StoreState.freeSpins !== '-1') {
                        if ((item.numOfFreeSpins && reg.test(item.numOfFreeSpins) && item.numOfFreeSpins.match(reg)[0] !== (Main.Instance.StoreState.rewardType === '1' ? Assets_.RTFreeSpinsAmount[Main.Instance.StoreState.freeSpins] : Assets_.RTMegaSpinsAmount[Main.Instance.StoreState.freeSpins])) || reg.test(item.numOfFreeSpins) === false || item.numOfFreeSpins === undefined) {
                            // console.log('2')
                            return 'none'
                        }
                    } else {
                        // console.log('3')
                        return 'block'
                    } 
                } else if (Main.Instance.StoreState.freeSpins !== '-1') {
                    if ((item.numOfFreeSpins && reg.test(item.numOfFreeSpins) && item.numOfFreeSpins.match(reg)[0] !== Assets_.RTFreeSpinsAmount[Main.Instance.StoreState.freeSpins]) || reg.test(item.numOfFreeSpins) === false || item.numOfFreeSpins === undefined) {
                        // console.log('4')
                        return 'none'
                    } else if (Main.Instance.StoreState.rewardType !== '0') {
                        if (item['Reward type'] === undefined || item['Reward type'] === '' || parseInt(item['Reward type']) !== parseInt(Main.Instance.StoreState.rewardType)) {
                            // console.log('5')
                            return 'none'
                        }
                    } else {
                        // console.log('6')
                        return 'block'
                    } 
                }
            } else {
                return 'block'
            }
    
        }

    }
    
}

// function getDisplayStyle(type: string, bonusCode: string): string {
    

//     // console.log(bonusCode)
//     // console.log(Main.Instance.StoreState.rewardType)

//     if (!IsBuildMyChance()) {
//         return 'block'
//     } else {
//         if ((Main.Instance.StoreState.rewardType !== '0' || Main.Instance.StoreState.freeSpins !== '-1') && type !== '0') {
//             if (Main.Instance.StoreState.rewardType !== '0') {
//                 if (type === undefined || type === '' || parseInt(type) !== parseInt(Main.Instance.StoreState.rewardType)) {
//                     // console.log('1')
//                     return 'none'
                    
                
//                 } else {
//                     // console.log('3')
//                     return 'block'
//                 } 
//             } else if (Main.Instance.StoreState.freeSpins !== '-1') {
//                 if (Main.Instance.StoreState.rewardType !== '0') {
//                     if (type === undefined || type === '' || parseInt(type) !== parseInt(Main.Instance.StoreState.rewardType)) {
//                         // console.log('5')
//                         return 'none'
//                     }
//                 } else {
//                     // console.log('6')
//                     return 'block'
//                 } 
//             }
//         } else {
//             return 'block'
//         }

//     }
// }

// function getRewardTypesData(): any[] {
//     const options: Array<{ id: number, name: string }> = []
//     options.push({id: (-1), name: Assets.All + ' ' + Assets.Types})
//     for (let i: number = 0; i < Assets_.RTCategoriesMyChance.length; i++) {
//         options.push({id: i, name:Assets_.RTCategoriesMyChance[i]})
//     }


//     return options
// }

export function getFreeSpinsData(rewardType): any[] {
    
    const options: Array<{ id: number, name: string }> = []
    options.push({id: (-1), name: Assets.All + ' ' + Assets.FreeSpinsAmount.toLowerCase()})
    if (rewardType === '1') {
        for (let i: number = 0; i < Assets_.RTFreeSpinsAmount.length; i++) {
            options.push({id: i, name: Assets_.RTFreeSpinsAmount[i]})
        }
    } else if (rewardType === '9') {
        for (let i: number = 0; i < Assets_.RTMegaSpinsAmount.length; i++) {
            options.push({id: i, name: Assets_.RTMegaSpinsAmount[i]})
        }
    }
    

    return options
}

function SelectReward(reward: number) {
    Logic.Type.New(Main.Instance.StoreState, {reward, /* rewardType: '-1'*/})  
}

function AddReward() {
    const rewards = Object.assign([], Main.Instance.StoreState.rewards)
    rewards.push ({rewardId: 0, price: 0, name: '', rewardType: '0', /* reward: {category1: {bonusCode: ''}}, numOfFreeSpins: ''} */})
    Logic.Type.New(Main.Instance.StoreState, {rewards, reward: rewards.length})
}

function RemoveReward() {
    const rewards = Object.assign([], Main.Instance.StoreState.rewards)
    rewards.splice (Main.Instance.StoreState.reward - 1, 1)
    Logic.Type.New(Main.Instance.StoreState, {rewards, reward: 0})
}


// function resetRewards() {
//     const rewards = Object.assign([], Main.Instance.StoreState.rewards)
//     for (const item of rewards) {
//         delete(item.reward)
//         Logic.Type.New(Main.Instance.StoreState, {rewards})
//     }
// }


function ApplyRewardSelect(text: string) {
    if (Main.Instance.StoreState.rewards) {
        const rewards = Object.assign([], Main.Instance.StoreState.rewards)
        let name: string = ''
        let rewardType: string = ''
        // let bonusCode: string = ''
        // let numOfFreeSpins: string = ''
        // console.log (text)
        // console.log(Main.Instance.DatabaseDataState.rewardShopStream)
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
            if (item.id === +text) {
                // console.log(item)
                name = item.Reward
                rewardType = item['Reward type']
                // bonusCode = item.reward.category1.bonusCode
                // numOfFreeSpins = item.numOfFreeSpins
            }
        }
        // for (let i: number = 0; i < Assets_.RTCategoriesMyChance.length; i++) {
        //     if (Assets_.RTCategoriesMyChance[i] === rewardType) {
        //         rewardType = (i + 1).toString()
        //     }
        // }
        // const rewardTypes = Object.keys(Assets_.RewardTypes)
        // for (let i: number = 0; i < rewardTypes.length / 2; i++) {
        //     if (rewardTypes[i] === rewardType) {
        //         rewardType = rewardTypes[i].toString()
        //     }
        // }
        // console.log(Main.Instance.DatabaseDataState.rewardShopStream)
        // console.log(type)
        rewards[Main.Instance.StoreState.reward - 1].rewardId = +text
        rewards[Main.Instance.StoreState.reward - 1].name = name
        rewards[Main.Instance.StoreState.reward - 1].rewardType = rewardType
        // rewards[Main.Instance.StoreState.reward - 1].reward.category1.bonusCode = bonusCode
        // rewards[Main.Instance.StoreState.reward - 1].numOfFreeSpins = numOfFreeSpins
    
        // console.log(rewards)
        Logic.Type.New(Main.Instance.StoreState, {rewards})
    }
    
}

function SetPrice(text: string) {
    const rewards = Object.assign([], Main.Instance.StoreState.rewards)
    rewards[Main.Instance.StoreState.reward - 1].price = +text
    Logic.Type.New(Main.Instance.StoreState, {rewards})
}

// export function getRewardsData(type, spins): any[] {
//     const options: Array<{ id: string, name: string }> = []
//     let bonusCode: string = ''
//     const reg = new RegExp(/\d+[fm]s/i)
//     // console.log ('rewardShopStream')
//     // console.log (Main.Instance.DatabaseDataState.rewardShopStream)
//     if (Main.Instance.DatabaseDataState.rewardShopStream) {
//         for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
//             // console.log(item['Reward type'])
//             // console.log(Main.Instance.StoreState.rewardType)
//             if (item.reward.category1.bonusCode && reg.test(item.reward.category1.bonusCode)) {
//                 bonusCode = ' | ' + item.reward.category1.bonusCode.match(reg)
//             } else {
//                 bonusCode = ''
//             }

//             // console.log(Main.Instance.StoreState.rewardType)
//             // console.log(item['Reward type'])

//             if (type !== '0' || spins !== '-1') {
//                 if (type !== '0') {
//                     // console.log('type')
//                     if (spins !== '-1') {
//                         if (+item['Reward type'] === +type && bonusCode !=='' && reg.test(item.reward.category1.bonusCode) && item.reward.category1.bonusCode.match(reg)[0] === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
//                             options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                            
//                         }
//                     } else if (+item['Reward type'] === +type) {
//                         options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
//                     } 
//                 } else if (spins !== '-1') {
//                     if (type !== '0') {
//                         if (+item['Reward type'] === +type && bonusCode !=='' && reg.test(item.reward.category1.bonusCode) && item.reward.category1.bonusCode.match(reg)[0] === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
//                             options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                            
//                         }
//                     } else if (bonusCode !=='' && reg.test(item.reward.category1.bonusCode) && item.reward.category1.bonusCode.match(reg)[0] === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
//                         options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
//                     }
//                 }
//             } else {
//                 options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
//             }      
//         }
  
//     }
    
//     return options.sort((a, b) => (a.name > b.name) ? 1 : -1)
// }

export function getRewardsData(type, spins): any[] {
    const options: Array<{ id: string, name: string }> = []
    let bonusCode: string = ''
    if (Main.Instance.DatabaseDataState.rewardShopStream) {
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
            
            if (item.numOfFreeSpins) {
                bonusCode = ' | ' + item.numOfFreeSpins
            } else {
                bonusCode = ''
            }

            if (type !== '0' || spins !== '-1') {
                if (type !== '0') {
                    // console.log('type')
                    if (spins !== '-1') {
                        if (+item['Reward type'] === +type && bonusCode !=='' && item.numOfFreeSpins && item.numOfFreeSpins === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
                            options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                            
                        }
                    } else if (+item['Reward type'] === +type) {
                        options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                    } 
                } else if (spins !== '-1') {
                    if (type !== '0') {
                        if (+item['Reward type'] === +type && bonusCode !=='' && item.numOfFreeSpins && item.numOfFreeSpins === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
                            options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                            
                        }
                    } else if (bonusCode !=='' && item.numOfFreeSpins && item.numOfFreeSpins === (type === '1' ? Assets_.RTFreeSpinsAmount[spins] : Assets_.RTMegaSpinsAmount[spins])) {
                        options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
                    }
                }
            } else {
                options.push({ name: item.Reward + ' | ' + injectSpaces(RewardTypes[item['Reward type']]) + bonusCode, id: item.id})
            } 
                
            
        }

        
    }
    return options.sort((a, b) => (a.name > b.name) ? 1 : -1)
}