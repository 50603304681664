import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

interface StateActions {
    notifyCS: boolean
    accountSuspension: boolean
    riskLevel: number
}

export class InternalRiskActionsPopup extends React.Component<{what: string}, StateActions> {
    constructor(props) {
        super(props)
        this.state = {
            notifyCS: false,
            accountSuspension: false,
            riskLevel: RiskLevel.Low,
        }
    }

    public AddAction() {

        let riskLevelExists: boolean = false
        if (Main.Instance.DatabaseDataState.retailActions) {
            for (const item of Main.Instance.DatabaseDataState.retailActions) {
                if (item.data.riskLevel === this.state.riskLevel) {
                    riskLevelExists = true
                    break
                }
            }

            if (riskLevelExists) {
                alert ('Action with this risk level already exists.')
            } else {
                DbRoutes.wsBasicInsert(Config.amRetailActions, {
                    notifyCS: this.state.notifyCS,
                    accountSuspension: this.state.accountSuspension,
                    riskLevel: this.state.riskLevel,
                })
                Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showInternalRiskModal: false})
            }
        } else { alert ('System failure.') }
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showInternalRiskModal: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{this.props.what}</h4>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({notifyCS: !this.state.notifyCS})} on = {this.state.notifyCS} />
                            <div className = "fl mt2">{Assets.NotifyCustomerSupport}</div>
                        </div>
                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({accountSuspension: !this.state.accountSuspension})} on = {this.state.accountSuspension}/>
                            <div className = "fl mt2">{Assets.AccountSuspension}</div>
                        </div>
                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showInternalRiskModal: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.AddAction()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


interface StatePlayers {
    firstName: string
    lastName: string
    birthday: string
    comment: string
    riskLevel: number
}

export class InternalRiskPlayersPopup extends React.Component<{}, StatePlayers> {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            birthday: '1901-01-01',
            comment: '',
            riskLevel: RiskLevel.Low,
        }
    }

    public handleTextBirthday(text: string, which: number) {
        const maxYear: number = (new Date()).getFullYear() - 18
        if (which === 0 && text.length === 5 && text.charAt(0) !== '0') { text = text.substring (1,5) }
        if (which !== 0 && text.length === 3 && text.charAt(0) !== '0') { text = text.substring (1,3) }
        let dobYear: number = +this.state.birthday.substring(0,4)
        let dobMonth: number = +this.state.birthday.substring(5,7)
        let dobDay: number = +this.state.birthday.substring(8, 10)
        if (which === 0) { dobYear = +text } else if (which === 1) { dobMonth = +text } else if (which === 2) { dobDay = +text }
        if (dobDay > 31) { dobDay = 31 }
        if (dobMonth > 12) { dobMonth = 12 }
        if (dobYear > maxYear) { dobYear = maxYear }
        if (dobDay === 31 && (dobMonth === 2 || dobMonth === 4 || dobMonth === 6 || dobMonth === 9 || dobMonth === 11 )) { dobDay = 30 }
        if (dobDay === 30 && dobMonth === 2) { dobDay = 29 }
        if (dobDay === 29 && dobMonth === 2 && dobYear % 4 !== 0) { dobDay = 28 }
    
        const DobMonth = dobMonth < 10 ? '0' + dobMonth : dobMonth 
        const DobDay = dobDay < 10 ? '0' + dobDay : dobDay
        const DobYear =  dobYear < 10 ? '000' + dobYear : dobYear < 100 ? '00' + dobYear : dobYear < 1000 ? '0' + dobYear : dobYear
        this.setState({birthday: DobYear + '-' + DobMonth + '-' + DobDay})
    }

    public AddPlayer() {
        let riskLevelExists: boolean = false
        if (Main.Instance.DatabaseDataState.retailActions) {
            for (const item of Main.Instance.DatabaseDataState.retailActions) {
                if (item.data.riskLevel === this.state.riskLevel) {
                    riskLevelExists = true
                    break
                }
            }
            
            if (riskLevelExists) {
                DbRoutes.wsBasicInsert(Config.amRetail, {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    birthday: this.state.birthday,
                    comment: this.state.comment,
                    riskLevel: this.state.riskLevel,
                })

                Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showInternalRiskModal: false})
            } else {
                alert ('Cannot insert a player, as action with this risk level does not exist.')
            }
        } else { alert ('System failure.') }
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showInternalRiskModal: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddNewPlayer}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.FirstName}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({firstName: e.target.value})}
                                type = "text"
                                value = {this.state.firstName}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.LastName}</div>
                            <input
                                className = "input-popup pr0"
                                onChange = {(e) => this.setState({lastName: e.target.value})}
                                type = "text"
                                value = {this.state.lastName}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div style = {{marginRight: '22px'}} className = "fl mt2">{Assets.Birthday}</div>
                            <div className = "fl mt2 mr">Year</div>
                            <input
                                maxLength = {4}
                                min = {1901}
                                max = {(new Date()).getFullYear() - 18}
                                className = "fl input-popup width60 pr0 mr"
                                onChange = {(e) => this.handleTextBirthday (e.target.value, 0)}
                                type = "number"
                                value = {this.state.birthday.substring(0,4)}
                            />
                            <div className = "fl mt2 mr">Month</div>
                            <input
                                maxLength = {2}
                                min = {1}
                                max = {12}
                                className = "fl input-popup width60 pr0 mr"
                                onChange = {(e) => this.handleTextBirthday (e.target.value, 1)}
                                type = "number"
                                value = {this.state.birthday.substring(5,7)}
                            />
                            <div className = "fl mt2 mr">Day</div>
                            <input
                                maxLength = {2}
                                min = {1}
                                max = {31}
                                className = "fl input-popup width60 pr0 mr"
                                onChange = {(e) => this.handleTextBirthday (e.target.value, 2)}
                                type = "number"
                                value = {this.state.birthday.substring(8,10)}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Comment}</div>
                            <textarea 
                                className = "input-popup pr0"
                                style = {{width: '800px', height: '200px'}}
                                onChange = {(e) => this.setState({comment: e.target.value})}
                                value = {this.state.comment}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showInternalRiskModal: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.AddPlayer()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

