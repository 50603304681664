import * as React from 'react'
import {faUserMinus, faUserPlus, faClock, faUserCheck, faCog, faHashtag, faUser, faCity, faGlobeEurope, faGifts} from '@fortawesome/free-solid-svg-icons'
import {Starting, dashboardBigTableRows, dashboardSmallTableRows, Tournament, Prizes, getPlayerCity, getPlayerNickname, getPlayerCountry, Tournaments} from './Dashboard'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from 'react-table'
import * as Assets_ from '../../Assets_'

export function getTournamentResults(tournament: Tournament, expanded: boolean): JSX.Element {
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
        <ReactTable
            data = {tournament.leaderboard}
            columns = {[
                {width: 45, Header: <FontAwesomeIcon title = {Assets.Position} icon = {faHashtag}/>, accessor: 'position', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.prize ? 700 : 200}}>{row.original.position2 === undefined ? row.value : row.value + '-' + row.original.position2}</span>},
                {width: 100, Header: <span title = {Assets.PlayerUsername}><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets.Username}</span>, accessor: 'playerId', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.prize ? 700 : 200}}>{getPlayerNickname(row.value)}</span>},
                {width: 110, Header: <span title = {Assets.PlayerCity}><FontAwesomeIcon icon = {faCity}/>&nbsp;&nbsp;{Assets.City}</span>, accessor: 'playerId', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.prize ? 700 : 200}}>{getPlayerCity(row.value)}</span>},
                {width: 23, Header: <FontAwesomeIcon title = {Assets.Country} icon = {faGlobeEurope}/>, accessor: 'playerId', headerClassName: 'th-left pr0i', Cell: (row) => row.value ? <img /*style = {{marginLeft: row.original.prize ? '0px' : '3px'}} height = {row.original.prize ? 20 : 10}*/ src = {getPlayerCountry(row.value)}/> : <div style = {{textAlign: 'center'}}>-</div>},
                {Header: <span title = {Assets.Prize}><FontAwesomeIcon icon = {faGifts}/>&nbsp;&nbsp;{Assets.Prize}</span>, accessor: 'prize', headerClassName: 'th-left', Cell: (row) => <span style = {{fontWeight: row.original.prize ? 700 : 200}}>{row.value ? row.value : '-'}</span>},
            ]}
            showPaginationBottom = {false}
            defaultPageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
        />
        </div>
    )
}

export function getPreviousTournaments(tournaments: Tournaments[], expanded: boolean): JSX.Element {
    const addWidth: number = expanded ? 40 : 0
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
        <ReactTable
            data = {tournaments}
            columns = {[
                /*{width: 70 + addWidth, Header: <span title = "Starting in (days, hours, minutes)"><FontAwesomeIcon icon = {faClock}/>&nbsp;&nbsp;Start</span>, accessor: 'starting', headerClassName: 'th-left', Cell: (row) => getStarting(row.value)},*/
                {width: 125 + addWidth, Header: <span title = {expanded ? '' : Assets.StartDate + ' & ' + Assets.Time.toLowerCase()}><FontAwesomeIcon icon = {faClock}/>&nbsp;&nbsp;{Assets.Started} ({Assets_.UTC})</span>, accessor: 'timestamp', headerClassName: 'th-left', Cell: (row) => getStartDateTime(row.value)},
                {show: expanded, width: 120 + addWidth, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {width: 85 + addWidth, Header: Assets.Prizepool, accessor: 'prizes', headerClassName: 'th-left', Cell: (row) => <span title = {row.value ? row.value.length + ' prizes' : ''}>{row.value ? getPrizepool(row.value) + (row.original.currency ? ' ' + row.original.currency : '') : '-'}</span>},
                {width: 35 + 3 * addWidth, Header: expanded ? Assets.RegisteredPlayers: <FontAwesomeIcon title = {Assets.RegisteredPlayers} icon = {faUserCheck}/>, accessor: 'registeredPlayers', headerClassName: 'th-left', Cell: (row) => row.value ? <div style = {{textAlign: 'center', fontWeight: 700, background: row.original.minPlayers !== undefined && row.value < row.original.minPlayers ? '#d9534f' : '#5cb85c', color: /* row.value < row.original.minPlayers ?*/ '#fff' /*: ''*/}}>{row.value}</div> : <div style = {{textAlign: 'center'}}>-</div>},
                /*{width: 30 + 3 * addWidth, Header: expanded ? 'Minimum players': <FontAwesomeIcon title = "Minimum players" icon = {faUserMinus}/>, accessor: 'minPlayers', headerClassName: 'th-left', Cell: (row) =>  <div style = {{textAlign: 'center'}}>{row.value ?row.value : '-'}</div>},
                {width: 30 + 3 * addWidth, Header: expanded ? 'Maximum players': <FontAwesomeIcon title = "Maximum players" icon = {faUserPlus}/>, accessor: 'maxPlayers', headerClassName: 'th-left', Cell: (row) => <div style = {{textAlign: 'center'}}>{row.value ?row.value : '-'}</div>},*/
                {width: 67 + addWidth, Header: Assets.BuyIn, accessor: 'buyIn', headerClassName: 'th-left', Cell: (row) => row.value ? row.value + (row.original.buyInCurrency ? ' ' + row.original.buyInCurrency : '') : '-'},
                {width: 75 + addWidth, Header: <span title = {Assets.GamificationLevel}>{/*<FontAwesomeIcon icon = {faCog}/>&nbsp;&nbsp;*/}{Assets.GamificationLevelShort}</span>, accessor: 'level', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? <div style = {{fontWeight: 700, textAlign: 'center', fontSize: '12px', background: Assets_.jackpotschLevelColor[row.value], color: Assets_.jackpotschLevelText[row.value]}}>{Assets.jackpotschLevels[row.value]}</div> : <div style = {{textAlign: 'center'}}>{Assets.AnyLevel}</div>},
            ]}
            showPaginationBottom = {false}
            defaultPageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
        />
        </div>
    )
}

export function getNextTournaments(tournaments: Tournaments[], expanded: boolean): JSX.Element {
    const addWidth: number = expanded ? 40 : 0
    return (
        <div className = {expanded ? 'dashboard-item-tournament expanded' : 'dashboard-item-tournament'}>
        <ReactTable
            data = {tournaments}
            columns = {[
                {width: 70 + addWidth, Header: <span title = "Starting in (days, hours, minutes)"><FontAwesomeIcon icon = {faClock}/>&nbsp;&nbsp;{Assets.Start}</span>, accessor: 'starting', headerClassName: 'th-left', Cell: (row) => <div title = {getStartDateTime(row.original.timestamp) + Assets_.UTC}>{getStarting(row.value)}</div>},
                {width: 85 + addWidth, Header: Assets.Prizepool, accessor: 'prizes', headerClassName: 'th-left', Cell: (row) => <span title = {row.value ? row.value.length + ' ' + Assets.Prizes.toLowerCase() : ''}>{row.value ? getPrizepool(row.value) + (row.original.currency ? ' ' + row.original.currency : '') : '-'}</span>},
                {width: 30 + 3 * addWidth, Header: expanded ? Assets.RegisteredPlayers : <FontAwesomeIcon title = {Assets.RegisteredPlayers} icon = {faUserCheck}/>, accessor: 'registeredPlayers', headerClassName: 'th-left', Cell: (row) => row.value ? <div style = {{textAlign: 'center', fontWeight: 700, background: row.original.minPlayers !== undefined && row.value < row.original.minPlayers ? '#d9534f' : '#5cb85c', color: /* row.value < row.original.minPlayers ?*/ '#fff' /*: ''*/}}>{row.value}</div> : <div style = {{textAlign: 'center'}}>-</div>},
                {width: 30 + 3 * addWidth, Header: expanded ? Assets.Minimum + ' ' + Assets.Players.toLowerCase() : <FontAwesomeIcon title = {Assets.Minimum + ' ' + Assets.Players.toLowerCase()} icon = {faUserMinus}/>, accessor: 'minPlayers', headerClassName: 'th-left', Cell: (row) =>  <div style = {{textAlign: 'center'}}>{row.value ?row.value : '-'}</div>},
                {width: 30 + 3 * addWidth, Header: expanded ? Assets.Maximum + ' ' + Assets.Players.toLowerCase() : <FontAwesomeIcon title = {Assets.Maximum + ' ' + Assets.Players.toLowerCase()} icon = {faUserPlus}/>, accessor: 'maxPlayers', headerClassName: 'th-left', Cell: (row) => <div style = {{textAlign: 'center'}}>{row.value ?row.value : '-'}</div>},
                {width: 67 + addWidth, Header: Assets.BuyIn, accessor: 'buyIn', headerClassName: 'th-left', Cell: (row) => row.value ? row.value + (row.original.buyInCurrency ? ' ' + row.original.buyInCurrency : '') : '-'},
                {width: 75 + addWidth, Header: <span title = {Assets.Level}><FontAwesomeIcon icon = {faCog}/>&nbsp;&nbsp;{Assets.Level}</span>, accessor: 'level', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? <div style = {{fontWeight: 700, textAlign: 'center', fontSize: '12px', background: Assets_.jackpotschLevelColor[row.value], color: Assets_.jackpotschLevelText[row.value]}}>{Assets.jackpotschLevels[row.value]}</div> : <div style = {{textAlign: 'center'}}>{Assets.AnyLevel}</div>},
            ]}
            showPaginationBottom = {false}
            defaultPageSize = {expanded ? dashboardBigTableRows : dashboardSmallTableRows}
        />
        </div>
    )
}

function getStartDateTime(timestamp: string): string {
    return timestamp.substring(8, 10) + timestamp.substring(4, 8) + timestamp.substring(0, 4) + ' ' + timestamp.substring(11, 16)
}

function getStarting(starting: Starting): JSX.Element {
    let days: string = ''
    let hours: string = ''
    let minutes: string = ''
    let Days: string = ''
    let Hours: string = ''
    let Minutes: string = ''
    let title: string = Assets.StartingIn + ' '

    if (starting.days !== undefined) { 
        days = starting.days + 'd'
        Days = starting.days + ' ' + Assets.Days.toLowerCase()
    }

    if (starting.hours !== undefined) { 
        if (starting.days !== undefined) {hours = ', '; Hours = ', '}
        hours += starting.hours + 'h'
        Hours += starting.hours + ' ' + Assets.Hours.toLowerCase()
    }

    if (starting.minutes !== undefined) {
        if (starting.days !== undefined || starting.hours !== undefined) {minutes = ', '; Minutes = ', '}
        minutes += (/*starting.minutes < 10 ? '0' + starting.minutes :*/ starting.minutes) + '\''
        Minutes += (/*starting.minutes < 10 ? '0' + starting.minutes :*/ starting.minutes) + ' ' + Assets.Minutes.toLowerCase()
    }
    title += Days + Hours + Minutes
    return <span title = {title}>{days + hours + minutes}</span>
}

function getPrizepool(prizes: Prizes[]): number {
    let n: number = 0
    for (const item of prizes) {
        n += item.value
    }
    return n
}