import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, FormInputNumber, FormSelect, FormInputArea/*, RadioButton3*/} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, isPureScandibet} from '../../../Logic/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faSave, faTimes} from '@fortawesome/free-solid-svg-icons'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {Flag} from '../../Reusables/Flag'
function formatDate(date): string {
    const d = new Date(date)
    let month = '' + (d.getUTCMonth() + 1)
    let day = '' + d.getUTCDate()
    const year = d.getUTCFullYear()

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
}

export function getCalendarCount() {
    if (Main.Instance.CalendarState.countCalled === false) {
        Logic.Type.New(Main.Instance.CalendarState, {countCalled: true})
        if (parseInt(Main.Instance.CalendarState.filter_future) === 1) {

            const now = new Date()
            const utc_timestamp = Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds(),
                now.getUTCMilliseconds(),
            )

            const utc_date: string = formatDate(utc_timestamp)
            DbRoutes.getCalendar(isPureScandibet() ? 1 : 2, utc_date)
        } else { DbRoutes.getCalendar(isPureScandibet() ? 1 : 2) }
    }
}

function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        getCalendarCount()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.CalendarItem + ' ' + 
            Assets.withName + ' "' + row.original.title[0] + '"?')) {

            if (Main.Instance.CalendarState.pNum === 
            (Math.trunc((Main.Instance.CalendarState.count - 2) / 
            Main.Instance.CalendarState.pSize) + 1)) {
                DbRoutes.removeCalendarDay (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeCalendarDay (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function removeImage() {
    if (confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.CalendarState, {image: ''})
        // DbRoutes.Action('.calendar_day SET image = \'\'', Main.Instance.CalendarState.id, getCalendarCount)
    }
}
/*
function removeImage2() {
    if (confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.CalendarState, {image2: ''})
        DbRoutes.Action('.calendar_day SET image2 = \'\'', Main.Instance.CalendarState.id, getCalendarCount)
    }
}
*/
function handleEdit(row: any) {
    gotoTop();
    Logic.Type.New(Main.Instance.CalendarState, {
        mode: true,
        id: row.original.id,
        index: +row.original.idx,
        index_saved: +row.original.idx,
        title: row.original.title,
        title_saved: row.original.title,
        button: row.original.button,
        button_saved: row.original.button,
        text: [
            row.original.text1,
            row.original.text2,
            row.original.text3,
            row.original.text4,
            row.original.text5,
        ],
        text_saved: [
            row.original.text1,
            row.original.text2,
            row.original.text3,
            row.original.text4,
            row.original.text5,
        ],
        image: row.original.image,
        image_saved: row.original.image,
        image2: row.original.image2,
        image2_saved: row.original.image2,
        bold_text: row.original.bold_text,
        bold_text_saved: row.original.bold_text,
        tc: row.original.tc,
        tc_saved: row.original.tc,
        link: row.original.link,
        link_saved: row.original.link,
        calendar_id: row.original.calendar_id,
    })
}

function handleInsertUpdate() {
    const calendarItem = {
            id : !Main.Instance.CalendarState.id ? '' : Main.Instance.CalendarState.id,
            title : !Main.Instance.CalendarState.title ? [] : Main.Instance.CalendarState.title,
            button : !Main.Instance.CalendarState.button ? [] : Main.Instance.CalendarState.button,
            link : !Main.Instance.CalendarState.link ? '' : Main.Instance.CalendarState.link,
            tc : !Main.Instance.CalendarState.tc ? [] : Main.Instance.CalendarState.tc,            
            text1 : !Main.Instance.CalendarState.text[0] ? [] : Main.Instance.CalendarState.text[0],
            text2 : !Main.Instance.CalendarState.text[1] ? [] : Main.Instance.CalendarState.text[1],
            text3 : !Main.Instance.CalendarState.text[2] ? [] : Main.Instance.CalendarState.text[2],
            text4 : !Main.Instance.CalendarState.text[3] ? [] : Main.Instance.CalendarState.text[3],
            text5 : !Main.Instance.CalendarState.text[4] ? [] : Main.Instance.CalendarState.text[4],
            bold_text : !Main.Instance.CalendarState.bold_text ? [] : Main.Instance.CalendarState.bold_text,
            // background : !Main.Instance.CalendarState.background ? '' : Main.Instance.CalendarState.background,
            image : !Main.Instance.CalendarState.image ? '' : Main.Instance.CalendarState.image,
            image2 : !Main.Instance.CalendarState.image2 ? '' : Main.Instance.CalendarState.image2,
            idx : !Main.Instance.CalendarState.index ? '' : Main.Instance.CalendarState.index,
            calendar_id: !Main.Instance.CalendarState.calendar_id ? '0' : Main.Instance.CalendarState.calendar_id,
            calendarid: isPureScandibet() ? 1 : 2,
        }
    if (Main.Instance.CalendarState.mode === false) {
         DbRoutes.insertCalendarDay (calendarItem, InsertLastPage)
    } else {
         DbRoutes.updateCalendarDay (calendarItem, Update)
    }
}

function clearFields() {
    Logic.Type.New(Main.Instance.CalendarState, {
        mode: false,
        image: '',
        id: undefined,
        link: '',
        tc: ['','','','','','','',''],
        index: 1,
        button: ['','','','','','','',''],
        title: ['','','','','','','',''],
        text: [['','','','','',''],['','','','','','','',''],['','','','','','','',''],['','','','','','','',''],['','','','','','','','']],
        bold_text: [true, false, true, false, true],
        textSelected: '0',
        banner: '0',
        image2: '0',
    })
}

function gotoInsertMode() {
    if (Main.Instance.CalendarState.title.toString() === Main.Instance.CalendarState.title_saved.toString() &&
        Main.Instance.CalendarState.button.toString() === Main.Instance.CalendarState.button_saved.toString() &&
        Main.Instance.CalendarState.link === Main.Instance.CalendarState.link_saved &&
        Main.Instance.CalendarState.tc.toString() === Main.Instance.CalendarState.tc_saved.toString() &&
        Main.Instance.CalendarState.image === Main.Instance.CalendarState.image_saved &&
        Main.Instance.CalendarState.image2 === Main.Instance.CalendarState.image2_saved &&
        Main.Instance.CalendarState.text.toString() === Main.Instance.CalendarState.text_saved.toString()/* &&
        Main.Instance.CalendarState.text[1] === Main.Instance.CalendarState.text_saved[1] &&
        Main.Instance.CalendarState.text[2] === Main.Instance.CalendarState.text_saved[2] &&
        Main.Instance.CalendarState.text[3] === Main.Instance.CalendarState.text_saved[3] &&
        Main.Instance.CalendarState.text[4] === Main.Instance.CalendarState.text_saved[4]*/
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.CalendarState, {offset:(0).toString()})
    getCalendarCount()
    Logic.Type.New(Main.Instance.CalendarState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.CalendarState.pNum > 0) {
        Logic.Type.New(Main.Instance.CalendarState, {
            offset:((Main.Instance.CalendarState.pNum - 1) * 
            Main.Instance.CalendarState.pSize).toString()})
        getCalendarCount()

        Logic.Type.New(Main.Instance.CalendarState,{pNum: Main.Instance.CalendarState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.CalendarState.pNum + 1) * Main.Instance.CalendarState.pSize < 
    Main.Instance.CalendarState.count) {
        Logic.Type.New(Main.Instance.CalendarState, {
            offset: ((Main.Instance.CalendarState.pNum + 1) * 
            Main.Instance.CalendarState.pSize).toString()})
        getCalendarCount()
        Logic.Type.New(Main.Instance.CalendarState,{pNum: Main.Instance.CalendarState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.CalendarState.count <= Main.Instance.CalendarState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CalendarState.count === 0) {

            Logic.Type.New(Main.Instance.CalendarState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.CalendarState.count - 1) / 
                    Main.Instance.CalendarState.pSize)
            Logic.Type.New(Main.Instance.CalendarState, {
                offset:(npNum * Main.Instance.CalendarState.pSize).toString()})
        }

        getCalendarCount()

        Logic.Type.New(Main.Instance.CalendarState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CalendarState, {count:Main.Instance.CalendarState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CalendarState, {count:Main.Instance.CalendarState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CalendarState, {count:Main.Instance.CalendarState.count --})
    getCalendarCount()
}

function Update() {
    clearFields()
    getCalendarCount()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.CalendarState, {pSize: parseInt(text)})
        FirstPage()
    }
}

function ApplyFilterFuture(text:string) {
    Logic.Type.New(Main.Instance.CalendarState, {filter_future: text})
    FirstPage()
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id: text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream: undefined,
            contractualPartnersAllStream2: undefined,
            sitesOperatorAllStream: undefined,
            levelsAllStream: undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM)
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {
        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)
        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState, {levelsAllStream: undefined,sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id, Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getCalendarCount()
    }
}

function TitleChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.title[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ActionChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.action_title[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function TermsChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.terms_title[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function TermsContentChanged(text: string) {
    const calendarData = Object.assign ([], Main.Instance.CalendarState.calendarData)
    calendarData.terms_and_conditions[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorHeaderChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_header = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorBodyChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_body = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorTextHeaderChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_text_header = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorTextBodyChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_text_body = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorButtonTermsChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_button_terms = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorButtonTextChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.color_button_text = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function ColorTcBackgroundChanged(text: string) {
    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
    calendarData.tc_background = text
    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
}

function SelectDefaultLanguage(text: string) {
    Logic.Type.New (Main.Instance.DatabaseDataState, {default_language: parseInt(text)})    
}

function SelectGif(text: string) {
    Logic.Type.New (Main.Instance.CalendarState, {calendar_id: text})
}

function SelectPastImage(text: string) {
    Logic.Type.New (Main.Instance.CalendarState, {image2: text})
}

function TextChanged(which: number, text: string) {
    const text1 = Object.assign ([], Main.Instance.CalendarState.text)
    text1[which][Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {text: text1})
}

function TitleDayChanged(text: string) {
    const text1 = Object.assign ([], Main.Instance.CalendarState.title)
    text1[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {title: text1})
}

function LinkChanged(link: string) {
    Logic.Type.New (Main.Instance.CalendarState, {link})
}

function TcChanged(text: string) {
    const text1 = Object.assign ([], Main.Instance.CalendarState.tc)
    text1[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {tc: text1})
}

function ButtonChanged(text: string) {
    const text1 = Object.assign ([], Main.Instance.CalendarState.button)
    text1[Main.Instance.DatabaseDataState.language] = text
    Logic.Type.New (Main.Instance.CalendarState, {button: text1})
}

function BoldTextChanged(which: number) {
    const bold_text = Object.assign ([], Main.Instance.CalendarState.bold_text)
    bold_text[which] = !bold_text[which]
    Logic.Type.New (Main.Instance.CalendarState, {bold_text})
}

function IndexChanged(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 32) {
        Logic.Type.New (Main.Instance.CalendarState, {index: parseInt(text)})
    }
}
/*
function ItemsInRowChanged (text: string) {
    if (parseInt(text) > 0) {
        let calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.items_in_row = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData, pSize: calendarData.items_in_row})
    }
}
*/
function WidthChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.width = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function ImageWidthChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.image_width = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function ImageHeightChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.image_height = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function PaddingChanged(text: string) {
    if (parseInt(text) > -1) {    
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.padding = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function DayWidthChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.day_width = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function DayHeightChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.day_height = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
    }
}

function MarginChanged(text: string) {
    if (text.indexOf(', ') > 0 || text.indexOf(',') > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        let right: string
        let bottom: string
        if (text.indexOf(', ') > 0) {
            right = text.substring (0, text.indexOf(', '))
            bottom = text.substring (text.indexOf(', ') - (-2), text.length)
            if (isNaN(parseInt(right)) || isNaN(parseInt(bottom))) { return }

            calendarData.right_margin = parseInt(right)
            calendarData.bottom_margin = parseInt(bottom)

        } else if (text.indexOf(',') > 0) {
            right = text.substring (0, text.indexOf(','))
            bottom = text.substring (text.indexOf(',') - (-1), text.length)
            if (isNaN(parseInt(right)) || isNaN(parseInt(bottom))) { return }

            calendarData.right_margin = parseInt(right)
            calendarData.bottom_margin = parseInt(bottom)
        }
        if (!isNaN(parseInt(right)) && !isNaN(parseInt(bottom))) {
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})
        }
    }
}

function HeightChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.height = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})        
    }
}

function HeightHeaderChanged(text: string) {
    if (parseInt(text) > 0) {
        const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
        calendarData.height_header = parseInt(text)
        Logic.Type.New (Main.Instance.CalendarState, {calendarData})        
    }
}

export function Calendar() {
    if (!Main.Instance.CalendarState.calendarData) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {
                first_time: false,
            })
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId),
                                                SelectSite)
            }
            Logic.Type.New(Main.Instance.CalendarState, {calendarData : {id: 0}})
            getCalendarCount()
    }
    if (!Main.Instance.CalendarState.calendarData) { return (<div/>) }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
    return (<div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.AddEditCalendar}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        /> : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        /> : void 0}
        {!Main.Instance.CalendarState.settings ?
        <button
            style = {{position: 'absolute', top: '92px', right: '70px'}}
            className = {Main.Instance.CalendarState.settings ? 'btn btn-danger btn-xs' : 'btn btn-success btn-xs'}
            onClick = {() => Logic.Type.New (Main.Instance.CalendarState, {settings: !Main.Instance.CalendarState.settings})}
        >
            {Assets.ShowSettings}
        </button> : void 0}
        <Flag/>
    </div>
    {Main.Instance.CalendarState.settings ?
    <div style = {{background: '#eee', border: '1px solid #999', paddingTop: '20px'}}>
    <div className = "clearfix">
        <h4 style = {{float:'left', width: '100px'}}>
            &nbsp;&nbsp;{Assets.Main}
        </h4>
        <div className = "fl">
        {Main.Instance.CalendarState.from ?
        <h4
            title = {Assets.calendarStart}
            style = {{
                marginRight: '10px',
                width: '110px',
                border: '1px solid #bbb',
                background: '#eee',
                textAlign: 'center',
                marginTop: '-18px',
                fontSize: '16px',
                padding: '2px 0px',
            }}
        >
            {Main.Instance.CalendarState.from[0]}
            <sup>{Main.Instance.CalendarState.from[1]}</sup>
            {Main.Instance.CalendarState.from[2]}
        </h4> : void 0}

        {Main.Instance.CalendarState.to ?
        <h4
            title = {Assets.calendarEnd}
            style = {{
                marginRight: '10px',
                width: '110px',
                border: '1px solid #bbb',
                background: '#eee',
                textAlign: 'center',
                marginTop: '-7px',
                fontSize: '16px',
                padding: '2px 0px',
            }}
        >
            {Main.Instance.CalendarState.to[0]}
            <sup>{Main.Instance.CalendarState.to[1]}</sup>
            {Main.Instance.CalendarState.to[2]}
        </h4> : void 0}
        </div>
        <div className = "fl" style = {{width:'200px'}}>
        <FormInput
            width = "90"
            value = {!Main.Instance.CalendarState.calendarData.title || !Main.Instance.CalendarState.calendarData.title[Main.Instance.DatabaseDataState.language] ?
                    '' : Main.Instance.CalendarState.calendarData.title[Main.Instance.DatabaseDataState.language]}
            ClassName = "form-control register-input" 
            type = "text"
            onChange = {(text) => TitleChanged(text)}
            placeholder = {Assets.Title}
            fix2 = {true}
            description = {Assets.defaultTitleMoreLanguages}
            icon = {Assets_.faCalendarGClass}
        />
        </div>
        <div className = "fl" style = {{width:'200px'}}>
        <FormInput
            width = "90"
            value = {!Main.Instance.CalendarState.calendarData.action_title || !Main.Instance.CalendarState.calendarData.action_title[
                    Main.Instance.DatabaseDataState.language] ? '' :
                    Main.Instance.CalendarState.calendarData.action_title[
                    Main.Instance.DatabaseDataState.language]}
            ClassName = "form-control register-input" 
            type = "text"
            onChange = {(text) => ActionChanged(text)}
            placeholder = {Assets.Title}
            description = {Assets.defaultActionButtonTextMoreLanguages}
            icon = {Assets_.faMousePointerClass}
        />
        </div>
        <div className = "fl" style = {{width:'200px'}}>
        <FormInput
            width = "90"
            value = {!Main.Instance.CalendarState.calendarData.terms_title || !Main.Instance.CalendarState.calendarData.terms_title[
                    Main.Instance.DatabaseDataState.language] ?
                    '' : Main.Instance.CalendarState.calendarData.terms_title[Main.Instance.DatabaseDataState.language]}
            ClassName = "form-control register-input" 
            type = "text"
            onChange = {(text) => TermsChanged(text)}
            placeholder = "Title"
            icon = {Assets_.faReportLightClass}
        />
        </div>
        {/*
        <div className = "fl">
            <FormInputNumber
                value = {!Main.Instance.CalendarState.calendarData.items_in_row ? '' :
                Main.Instance.CalendarState.calendarData.items_in_row.toString()}
                description = "items in a row"
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w50px"
                type = "number"
                min = "1"
                onChange = {text => ItemsInRowChanged(text)} 
                icon = {Assets_.faWidthClass}
            />
        </div>
        */}
        <FormSelect
            value = {!Main.Instance.DatabaseDataState.default_language ? '0' : 
                    Main.Instance.DatabaseDataState.default_language.toString()}
            data = {Main.Instance.DatabaseDataState.languages}
            ClassName = "form-control"
            narrow = {true}
            mt0 = {true}
            onChange = {(text) => SelectDefaultLanguage(text)}
            icon = {Assets_.faLanguageClass}
        />
        <div style = {{position: 'absolute', top: '120px', right: '20px'}}>
            <button
                title = {Assets.CloseSettings}
                style = {{border: '0px', background: 'transparent'}}
                onClick = {() => Logic.Type.New (Main.Instance.CalendarState, {settings: !Main.Instance.CalendarState.settings})}
            >
                <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
            </button>
        </div>
        <div style = {{position: 'absolute', top: '180px', right: '37px'}}>
        <ButtonIcon
            description = {Main.Instance.CalendarState.mode ? Assets.saveChanges : Assets.updateChanges} 
            click = {() => DbRoutes.updateCalendar()}
            ClassName = "btn btn-danger btn-sm btn-plus fr mr0 ml0 mt-33"
            Icon = {faSave as IconDefinition}
            text = {Assets.SaveSettings}
            zIndex = {499}
        />
        </div>
    </div>

    <div className = "clearfix calendar-help calendar-help1">
        <div className = "fl" style = {{width: '200px'}}>
            {Assets.titleMoreLanguages}
        </div>
        <div className = "fl" style = {{width: '200px'}}>
            {Assets.actionButtonLanguages}
        </div>
        <div className = "fl" style = {{width: '147px'}}>
            {Assets.tcMoreLanguages}
        </div>
        <button
            style = {{display: 'block', float: 'left', padding: '0px', lineHeight: 1.2, width: '43px', textAlign: 'center'}}
            className = {!Main.Instance.CalendarState.terms ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
            onClick = {() => Logic.Type.New (Main.Instance.CalendarState, {terms: !Main.Instance.CalendarState.terms})}
        >
            {!Main.Instance.CalendarState.terms ? 'Show' : 'Hide'}
        </button>  
        {/*
        <div className = "fl" style = {{width: '104px'}}>
            items in a row
        </div>
        */}
        <div className = "fl" style = {{width: 'auto'}}>
            {Assets.DefaultLanguage.toLowerCase()}
        </div>
    </div>

    <div
        /*
        className = {Main.Instance.CalendarState.terms ? 
        'clearfix calendar-help calendar-help3' : 
        'clearfix calendar-help calendar-help2'}
        */
        className = "clearfix calendar-help calendar-help2"
    >
        
        <div className = "fl">
            {Main.Instance.CalendarState.calendarData.image && Main.Instance.CalendarState.calendarData.image.length > 0 ? '.' : Assets.SetDefaultPopupImage.toLowerCase()}
        </div>
        <div className = "fl">
                upload default popup image
        </div>
        <div className = "fl">
                image width
        </div>
        <div className = "fl">
                image height
        </div>
        <div className = "fl">
                popup width
        </div>
        <div className = "fl">
                popup height
        </div>
        <div className = "fl" style = {{width: '102px'}}>
                header height
        </div>
        <div className = "fl" style = {{width: '100px'}}>
                padding all
        </div>
        <div className = "fl" style = {{width: '113px'}}>
                day width
        </div>
        <div className = "fl" style = {{width: '113px'}}>
                day height
        </div>
        <div className = "fl" style = {{width: '120px'}}>
                right, bottom mar.
        </div>
    </div>

    <div className = "clearfix">
        <h4 style = {{float:'left', width: '200px', marginTop: '25px'}}>
            &nbsp;&nbsp;{Assets.Various}
        </h4> 
            
        {/*!Main.Instance.CalendarState.calendarData.background || Main.Instance.CalendarState.calendarData.background.length === 0 ?
        <span 
            style = {{
                float:'left',
                display:'block',
                marginTop:'25px',
                marginRight:'10px',
                fontSize:'12px',
                paddingLeft:'4px',
                paddingRight:'4px'
            }}
            className = "label label-warning"
        >
            {Assets.NoImage}
        </span> 
        :
        <div 
            style = {{
                position: 'relative',
                height:'50px',
                width:'50px',
                marginTop:'0px',
                marginRight:'10px',
                float:'left',
                display: 'block',
                border:'1px solid #999'

            }}
        >
            <img
                src = {
                      Main.Instance.CalendarState.calendarData.background.startsWith('data:image/png;base64,') ||
                      Main.Instance.CalendarState.calendarData.background.startsWith('data:image/jpg;base64,') ||
                      Main.Instance.CalendarState.calendarData.background.startsWith('data:image/jpeg;base64,') 
                      ? Main.Instance.CalendarState.calendarData.background :
                      'data:image/jpg;base64,' + Main.Instance.CalendarState.calendarData.background} 
                style = {{
                    height:'100%',
                    width:'auto',
                    position:'absolute',
                    zIndex:9998,
                    left:'0px',
                    right:'0px',
                    top:'0px',
                    bottom: '0px',
                    background: `url(${Assets_.checkered})`,
                }}
            />
        </div>/*}
        {/*
        <input
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            id = "calendar-bg-default" 
            className = "calendarImageWrapper"
            style = {{overflow: 'hidden', marginTop: '20px', marginLeft:'0px', marginRight: '10px'}}
            type = "file" 
            onChange = {(event) => { 
                let cloneFile: File = event.target.files[0]
                let reader = new FileReader()
                
                reader.onload = (e) => {
                    let calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
                    calendarData.background = reader.result as string
                    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => {event.target = null}}
        />
        */}

        {!Main.Instance.CalendarState.calendarData.image ?
        <span 
            style = {{
                position: 'absolute',
                left: '130px',
                top: '206px',
                // marginLeft: '-100px',
                // marginRight:'10px',
                // float:'left',
                display:'block',
                // marginTop:'25px',
                fontSize:'14px',
                width: '90px',
                textAlign: 'center',
            }}
            className = "label label-warning"
        >
            {Assets.NoImage}
        </span> 
        :
        <div 
            style = {{
                position: 'absolute',
                left: '130px',
                top: '187px',
                // marginLeft: '-100px',
                // marginRight:'10px',
                // float:'left',
                height:'100px',
                width:'100px',
                // marginTop:'0px',
                // marginRight:'10px',
                // float:'left',
                display: 'block',
                // border:'1px solid #999'
            }}
        >
            <img 
                src = {
                      Main.Instance.CalendarState.calendarData.image.startsWith('data:image/png;base64,') || 
                      Main.Instance.CalendarState.calendarData.image.startsWith('data:image/jpg;base64,') ||
                      Main.Instance.CalendarState.calendarData.image.startsWith('data:image/jpeg;base64,')
                      ?
                      Main.Instance.CalendarState.calendarData.image :
                      'data:image/png;base64,' + Main.Instance.CalendarState.calendarData.image} 
                style = {{
                    height:'100%',
                    width:'auto',
                    // position:'absolute',
                    zIndex:9998,
                    // left:'0px',
                    // right:'0px',
                    // top:'0px',
                    // bottom: '0px',
                    background: `url(${Assets_.checkered})`,
                    border:'1px solid #999',
                }}
            />
            <button
                title = {Assets.RemoveDefaultPopupImage}
                style = {{
                    position: 'absolute',
                    // left: '130px',
                    // top: '187px',
                    left: '0px',
                    top: '-4px',
                    background: 'transparent',
                    border: '0px',
                    color: '#000',
                    marginRight: '0px',
                    padding: '0px',
                }}
                onClick = {() => {
                    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
                    calendarData.image = ''
                    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
                }}
            >
                <i className = {Assets_.faCancelButtonClass}/>
            </button>
        </div>}
        <input
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            id = "calendar-default" 
            className = "calendarImageWrapper"
            style = {{overflow: 'hidden', marginTop: '20px'}}
            type = "file" 
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                
                reader.onload = (e) => {
                    const calendarData = Object.assign ({}, Main.Instance.CalendarState.calendarData)
                    calendarData.image = reader.result as string
                    Logic.Type.New (Main.Instance.CalendarState, {calendarData})
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => {event.target = null}}
        />

        <FormInputNumber  
            value = {!Main.Instance.CalendarState.calendarData.image_width ? '' :
            Main.Instance.CalendarState.calendarData.image_width.toString()}
            description = "image width"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1"
            onChange = {(text) => ImageWidthChanged(text)} 
            icon = {Assets_.faWidthClass}
        />

        <FormInputNumber  
            value = {!Main.Instance.CalendarState.calendarData.image_height ? '' :
            Main.Instance.CalendarState.calendarData.image_height.toString()}
            description = "image height"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1"
            onChange = {(text) => ImageHeightChanged(text)} 
            icon = {Assets_.faHeightClass}
        />

        <FormInputNumber
            value = {!Main.Instance.CalendarState.calendarData.width ? '' :
            Main.Instance.CalendarState.calendarData.width.toString()}
            description = "popup width"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1"
            onChange = {(text) => WidthChanged(text)} 
            icon = {Assets_.faWidthClass}
        />

        <FormInputNumber  
            value = {!Main.Instance.CalendarState.calendarData.height ? '' :
            Main.Instance.CalendarState.calendarData.height.toString()} 
            description = "popup height"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1" 
            onChange = {(text) => HeightChanged(text)} 
            icon = {Assets_.faHeightClass}
        />

        <FormInputNumber  
            value = {!Main.Instance.CalendarState.calendarData.height_header ? '' :
            Main.Instance.CalendarState.calendarData.height_header.toString()} 
            description = "header height"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w50px"
            type = "number"
            min = "1" 
            onChange = {(text) => HeightHeaderChanged(text)} 
            icon = {Assets_.faHeightClass}
        />

        <FormInputNumber
            value = {Main.Instance.CalendarState.calendarData.padding !== undefined ? 
                    Main.Instance.CalendarState.calendarData.padding.toString() : ''}
            description = "padding top, right, bottom, left"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w50px"
            type = "number"
            min = "0"
            onChange = {(text) => PaddingChanged(text)} 
            icon = {Assets_.faArrowsClass}
        />

        <FormInputNumber
            value = {!Main.Instance.CalendarState.calendarData.day_width ? '' :
            Main.Instance.CalendarState.calendarData.day_width.toString()}
            description = "day width"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1"
            onChange = {(text) => DayWidthChanged(text)} 
            icon = {Assets_.faWidthClass}
        />

        <FormInputNumber
            value = {!Main.Instance.CalendarState.calendarData.day_height ? '' :
            Main.Instance.CalendarState.calendarData.day_height.toString()}
            description = "day height"
            outerDiv = "rows-per-table mt20 input-group fl mr ml0 pl0"
            ClassName = "h30 w60"
            type = "number"
            min = "1"
            onChange = {(text) => DayHeightChanged(text)} 
            icon = {Assets_.faHeightClass}
        />
        <div className = "fl mt20" style = {{width: '120px'}}>
        <FormInput
            width = "100"
            value = {Main.Instance.CalendarState.calendarData.right_margin !== undefined && 
                    Main.Instance.CalendarState.calendarData.bottom_margin !== undefined ?
                    Main.Instance.CalendarState.calendarData.right_margin.toString() + ', ' +
                    Main.Instance.CalendarState.calendarData.bottom_margin.toString() : ''}
            description = "right, bottom margin"
            ClassName = "h30 w80"
            type = "text"
            onChange = {(text) => MarginChanged(text)} 
            icon = {Assets_.faArrowsAltClass}
        />
        </div>
    </div>

    <div className = "clearfix">
        <div className = "fl" style = {{width:'10%', marginLeft: '200px'}}>
                header background
        </div>
        <div className = "fl" style = {{width:'10%'}}>
                body background
        </div>
        <div className = "fl" style = {{width:'10%'}}>
                header text
        </div>
        <div className = "fl" style = {{width:'10%'}}>
                body text
        </div>

        <div className = "fl" style = {{width:'10%'}}>
                buttons text
        </div>
        <div className = "fl" style = {{width:'10%'}}>
                t&amp;c button background
        </div>
        <div className = "fl" style = {{width:'18%'}}>
            t&amp;c background
        </div>
{/*
        <div className = "fl" style = {{width:'113px'}}>
                image width
        </div>
        <div className = "fl" style = {{width:'113px'}}>
                image height
        </div>
*/}
    </div>
    <div className = "clearfix">
        <h4 style = {{float:'left', width: '200px'}}>
            &nbsp;&nbsp;{Assets.Colors}
        </h4> 
        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_header ? '' : 
                Main.Instance.CalendarState.calendarData.color_header}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorHeaderChanged(text)}
                placeholder = {Assets.Title}
                description = "header background color"
                icon = {Assets_.faTintClass}
            />
        </div>

        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_body ? '' : 
                Main.Instance.CalendarState.calendarData.color_body}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorBodyChanged(text)}
                placeholder = {Assets.Title}
                description = "body background color"
                icon = {Assets_.faTintClass}
            />
        </div>

        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_text_header ? '' : 
                Main.Instance.CalendarState.calendarData.color_text_header}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorTextHeaderChanged(text)}
                placeholder = {Assets.Title}
                description = "header text color"
                icon = {Assets_.faTintClass}
            />
        </div>

        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_text_body ? '' : 
                Main.Instance.CalendarState.calendarData.color_text_body}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorTextBodyChanged(text)}
                placeholder = {Assets.Title}
                description = "header body color"
                icon = {Assets_.faTintClass}
            />
        </div>

        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_button_text ? '' : 
                Main.Instance.CalendarState.calendarData.color_button_text}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorButtonTextChanged(text)}
                placeholder = {Assets.Title}
                description = "buttons text color"
                icon = {Assets_.faTintClass}
            />
        </div>
        <div className = "fl" style = {{width:'10%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.color_button_terms ? '' : 
                Main.Instance.CalendarState.calendarData.color_button_terms}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorButtonTermsChanged(text)}
                placeholder = {Assets.Title}
                description = "t&amp;c button background color"
                icon = {Assets_.faTintClass}
            />
        </div>
        <div className = "fl" style = {{width:'18%'}}>
            <FormInput
                width = "90"
                value = {!Main.Instance.CalendarState.calendarData.tc_background ? '' : 
                Main.Instance.CalendarState.calendarData.tc_background}
                ClassName = "form-control register-input" 
                type = "text"
                onChange = {(text) => ColorTcBackgroundChanged(text)}
                placeholder = {Assets.Title}
                description = "terms &amp; conditions background color"
                icon = {Assets_.faTintClass}
            />
        </div>
    </div>
    {Main.Instance.CalendarState.terms ?
    <div className = "row admin-users-row-area" style = {{padding: '10px', marginBottom: '10px'}}>
        <div className = "register-input textarea">
            <FormInputArea  
                value = {!Main.Instance.CalendarState.calendarData.terms_and_conditions
                    [Main.Instance.DatabaseDataState.language]
                ? '' : Main.Instance.CalendarState.calendarData.terms_and_conditions
                    [Main.Instance.DatabaseDataState.language]}
                ClassName = "form-control register-input"
                onChange = {(text) => TermsContentChanged (text)}
                placeholder = {Assets.TermsAndConditions}
                iconText = {Assets.TC}
                boldText = {true}
            />
        </div>
    </div> : void 0}

    </div> : void 0}
    <div className = "clearfix"/>       
        <div className = "row admin-users-container clearfix">
            <br/>
            {!isPureScandibet() || Main.Instance.CalendarState.mode ? 
            <div 
                className = {Main.Instance.CalendarState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row">
                        <h4>
                            {Main.Instance.CalendarState.mode ?
                            Assets.editCalendarItem + ': ' : 
                            Assets.addCalendarItem}
                            {Main.Instance.CalendarState.mode ?
                            <strong>{getDay(Main.Instance.CalendarState.index)}</strong> : void 0}
                        </h4>

                        {Main.Instance.CalendarState.mode ? 
                        <button
                            style = {{border: '0px', background: 'transparent', position: 'absolute', top: '-1px', right: '1px', padding: '0px'}}
                            title = {Assets.discardChanges} 
                            onClick = {() => gotoInsertMode()}
                            className = "btn btn-default btn-sm mr0"
                        >
                            <i className = {Assets_.faCancelButtonClass}/>
                        </button>
                        : <span/>}

                    </div>

                    <div className = "row admin-users-row">
{isPureScandibet() ? void 0 :
                <FormInputNumber
                    value = {!Main.Instance.CalendarState.index ? '' : Main.Instance.CalendarState.index.toString()}
                    description = {Assets.CalendarDay.toLowerCase()}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w50px"
                    type = "number"
                    min = "1"
                    max = "32"
                    onChange = {(text) => IndexChanged(text)}
                    iconText = {Assets.Day}
                    iconTextS = "13px"
                    fix2 = {true}
                />
}
                {/*<div className = "fl" style = {{width: '80px', fontSize: '20px', fontWeight: 700}}>{getDay(Main.Instance.CalendarState.index)}</div>*/}
                <FormInput
                    value = {!Main.Instance.CalendarState.title[Main.Instance.DatabaseDataState.language] ? '' :
                            Main.Instance.CalendarState.title[Main.Instance.DatabaseDataState.language]} 
                    ClassName = "form-control register-input" 
                    type = "text"
                    placeholder = {Assets.CalendarDayTitle.toLowerCase()}
                    onChange = {(text) => TitleDayChanged(text)} 
                    description = {Assets.CalendarDayTitleDesc.toLowerCase()}
                    fix2 = {true}
                    icon = {Assets_.faCalendarGClass}
                />
                {Main.Instance.CalendarState.link && Main.Instance.CalendarState.link.length > 0 ?
                <FormInput
                    value = {!Main.Instance.CalendarState.button[Main.Instance.DatabaseDataState.language] ? '' :
                            Main.Instance.CalendarState.button[Main.Instance.DatabaseDataState.language]} 
                    ClassName = "form-control register-input" 
                    type = "text"
                    width = "30"
                    placeholder = {Assets.ActionButtonText.toLowerCase()}
                    onChange = {(text) => ButtonChanged(text)} 
                    description = {Assets.ActionButtonTextDesc}
                    iconText = {Assets.ActionButton.toLowerCase()}
                    iconTextSize = "13px"
                    bg = "#f0ad4e"
                    color = "white"
                /> : void 0}
                {/*
                <FormSelect
                    narrow = {true}
                    value = {!Main.Instance.CalendarState.textSelected ? '0' : 
                            Main.Instance.CalendarState.textSelected}
                    data = {[
                        {id:'1', name: 'Popup paragraph 1'},
                        {id:'2', name: 'Popup paragraph 2'},
                        {id:'3', name: 'Popup paragraph 3'},
                        {id:'4', name: 'Popup paragraph 4'},
                        {id:'5', name: 'Popup paragraph 5'}
                    ]}
                    ClassName = "form-control"
                    o1 = "Edit popup text"
                    o2 = "Edit popup text"
                    onChange = {text => Logic.Type.New (Main.Instance.CalendarState, {
                        textSelected: text})}
                    icon = {parseInt(Main.Instance.CalendarState.textSelected) === 0 ? 
                           Assets_.faReportClass : Assets_.faReportCategoryClass}
                />

                {parseInt(Main.Instance.CalendarState.textSelected) > 0 ?
                <RadioButton3
                    value = {Main.Instance.CalendarState.bold_text[parseInt(Main.Instance.CalendarState.textSelected)
                             - 1] === undefined ? false :
                            Main.Instance.CalendarState.bold_text[parseInt(Main.Instance.CalendarState.textSelected)
                            - 1]}
                    text = "bold text"
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fl mr0 ml0"
                    title = "indicates whether the text in question should be bold"
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {text => BoldTextChanged()}
                /> : void 0}
                */}
                <input
                    type = "file"
                    accept = "image/png, image/jpg, image/jpeg, image/gif"
                    id = "calendar-day" 
                    className = "calendarImageWrapper"
                    style = {{marginTop:'0px', overflow: 'hidden'}}

                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.readAsDataURL(cloneFile);
                        reader.onload = (e) => {
                            Logic.Type.New (Main.Instance.CalendarState, {image: reader.result as string})
                        }
                    }}
                    onClick = {(event) => event.target = null}
                />

                {Main.Instance.CalendarState.image && Main.Instance.CalendarState.image.length > 0 ?
                <div 
                    style = {{
                        background:'#fff',
                        position: 'relative',
                        height:'50px',
                        maxWidth:'18%',
                        marginTop:'-15px',
                        marginRight:'75px',
                        float:'right',
                        display: 'block',
                    }}
                >
                    <div 
                        style = {{
                            background: 'transparent',
                            position: 'relative',
                            height:'100px',
                            width:'auto',
                            marginRight:'10px',
                            float:'left',
                            display: 'block',
                            border:'1px solid #999',
                            margin: '0px auto 0',
                        }}
                    >

                    <img
                        src = {Main.Instance.CalendarState.image}
                        style = {{
                            height:'100%',
                            width:'auto',
                            position:'absolute',
                            zIndex:9998,
                            left:'0px',
                            right:'0px',
                            top:'0px',
                            bottom: '0px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    />
                    </div>
                    <button 
                        className = "btn btn-xs btn-nothing"
                        title = {Assets.RemoveImage}
                        style = {{position:'absolute',zIndex:9999, right:'-17px', top:'-5px', marginRight:'0px', color: '#000'}}
                        onClick = {() => removeImage()}
                    >
                        <i className = {Assets_.faCancelClass}/>
                    </button>
                </div>
                : 
                <span 
                    style = {{ 
                        float:'right',
                        display:'block',
                        marginLeft:'0%',
                        marginRight:'1%',
                        height: '30px',
                        fontSize:'16px',
                        paddingLeft:'4px',
                        paddingRight:'4px',
                        paddingTop:'7px',
                    }}
                    className = "label label-warning"
                >
                    {Assets.NoImage}
                </span>}
            </div>

            <div className = "row admin-users-container clearfix">
                <FormInput
                    value = {!Main.Instance.CalendarState.link ? '' : Main.Instance.CalendarState.link} 
                    ClassName = "form-control register-input" 
                    type = "text"
                    width = "35"
                    placeholder = {Assets.ActionButtonLink.toLowerCase()}
                    onChange = {(text) => LinkChanged(text)} 
                    description = {Assets.ActionButtonLinkDesc}
                    // fix2 = {true}
                    iconText = {Assets.ActionLink.toLowerCase()}
                    iconTextSize = "13px"
                    bg = "#f0ad4e"
                    color = "white"
                />
                {isPureScandibet() ? void 0 :
                <>
                <FormSelect
                    value = {!Main.Instance.CalendarState.calendar_id ? '0' : 
                            Main.Instance.CalendarState.calendar_id.toString()}
                    data = {Assets_.ChristmasDecorations}
                    ClassName = "form-control"
                    narrow = {true}
                    mt0 = {true}
                    onChange = {(text) => SelectGif(text)}
                    icon = {Assets_.faImageClass}
                />
{/*
                <div
                    style = {{
                        width: isPureScandibet() ? '84px' : '144px',
                        height: isPureScandibet() ? '84px' : '100px',
                        float: 'left',
                        backgroundColor: 'rgba(0, 4, 255, 0.5)',
                        marginBottom: '10px'}}
                >
                    {parseInt(Main.Instance.CalendarState.calendar_id) === 3 ? 
                    <img
                        style = {{maxWidth: '100%', maxHeight: '100%', marginTop: '-25px', display: 'block'}}
                        src = {require('../../../Assets/glitter' + Main.Instance.CalendarState.calendar_id + '.gif')}
                    />
                    :
                    <img
                        style = {parseInt(Main.Instance.CalendarState.calendar_id) === 2 ? {maxWidth: '100%', maxHeight: '100%'} :
                        {maxWidth: '100%', maxHeight: '100%'}}
                        src = {require('../../../Assets/glitter' + Main.Instance.CalendarState.calendar_id + '.gif')}
                    />}
                </div>
*/}
                </>}
                {isPureScandibet() ?
                <>
                {/*<div className = "fl" style = {{width: '2%'}}>&nbsp;</div>*/}
                <FormSelect
                    value = {!Main.Instance.CalendarState.image2 ? '0' : 
                            Main.Instance.CalendarState.image2.toString()}
                    data = {Assets_.PastImages}
                    ClassName = "form-control"
                    narrow = {true}
                    mt0 = {true}
                    onChange = {(text) => SelectPastImage(text)}
                    icon = {Assets_.faImageClass}
                />
                <div
                    style = {{
                        width: isPureScandibet() ? '84px' : '144px',
                        height: isPureScandibet() ? '84px' : '100px',
                        float: 'left',
                        backgroundColor: 'rgba(64, 64, 64, 0.5)',
                        marginTop: '-35px',
                        marginBottom: '10px',
                    }}
                >
                    {+Main.Instance.CalendarState.image2 > 0 ?
                    <img
                        style = {parseInt(Main.Instance.CalendarState.calendar_id) === 2 ? {maxWidth: '100%', maxHeight: '100%'/*, marginTop: '-35px', marginBottom: '10px'*/} :
                        {maxWidth: '100%', maxHeight: '100%'/*, marginTop: '-35px', marginBottom: '10px'*/}}
                        src = {require('../../../Assets/pi' + Main.Instance.CalendarState.image2 + '.png')}
                    /> : void 0}{/*<div style = {{color: 'red', lineHeight: '42px', width: '84px', fontWeight: 700}}>{Assets.NoPastImage}</div>*/}
                </div>
                </> : void 0}
                {/*
                <input
                    type = "file"
                    accept = "image/png, image/jpg, image/jpeg, image/gif"
                    id = "calendar-day2" 
                    className = "calendarImageWrapper"
                    style = {{marginTop:'0px', overflow: 'hidden'}}

                    onChange = {(event) => { 
                        let cloneFile: File = event.target.files[0]
                        let reader = new FileReader()
                        reader.readAsDataURL(cloneFile);
                        reader.onload = (e) => {
                            Logic.Type.New (Main.Instance.CalendarState, {image2: reader.result as string})
                        }
                    }}
                    onClick = {(event) => event.target = null}
                />
                */}
                {/*Main.Instance.CalendarState.image2 && Main.Instance.CalendarState.image2.length > 0 ?
                <div 
                    style = {{
                        background:'#fff',
                        position: 'relative',
                        height:'50px',
                        maxWidth:'18%',
                        marginTop:'0px',
                        marginRight:'1%',
                        float:'right',
                        display: 'block'
                    }}
                >
                    <div 
                        style = {{
                            background: 'transparent',
                            position: 'relative',
                            height:'50px',
                            width:'50px',
                            marginRight:'10px',
                            float:'left',
                            display: 'block',
                            border:'1px solid #999',
                            margin: '0 auto'
                        }}
                    >

                    <img
                        src = {Main.Instance.CalendarState.image2}
                        style = {{
                            height:'100%',
                            width:'auto',
                            position:'absolute',
                            zIndex:9998,
                            left:'0px',
                            right:'0px',
                            top:'0px',
                            bottom: '0px',
                            background: `url(${Assets_.checkered})`,
                        }}
                    />
                    </div>
                    <button 
                        className = "btn btn-xs btn-nothing"
                        title = {Assets.RemoveImage}
                        style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px', color: '#fff'}}
                        onClick = {() => removeImage2()}
                    >
                        <i className = {Assets_.faCancelClass}/>
                    </button>
                </div>
                : 
                <span 
                    style = {{ 
                        float:'right',
                        display:'block',
                        marginLeft:'0%',
                        marginRight:'1%',
                        height: '30px',
                        fontSize:'16px',
                        paddingLeft:'4px',
                        paddingRight:'4px',
                        paddingTop:'7px',
                    }}
                    className = "label label-warning"
                >
                    {Assets.NoPastImage}
                </span>*/}
            </div>

            {/*parseInt(Main.Instance.CalendarState.textSelected) > 0 ?*/}
            <div className = "row admin-users-row-area">
                {/*
                <div className = "register-input textarea-small">
                    <FormInputArea  
                        value = {!Main.Instance.CalendarState.text[parseInt(Main.Instance.CalendarState.textSelected)-1]
                            [Main.Instance.DatabaseDataState.language]
                        ? '' : Main.Instance.CalendarState.text[parseInt(Main.Instance.CalendarState.textSelected) - 1]
                            [Main.Instance.DatabaseDataState.language]}
                        ClassName = "form-control register-input"
                        onChange = {text => TextChanged (text)}
                        placeholder = {'Popup text ' + Main.Instance.CalendarState.textSelected}
                        bold = {Main.Instance.CalendarState.bold_text[parseInt(Main.Instance.CalendarState.textSelected)
                                -1] ?
                                true : false}
                        icon = {Assets_.faReportClass}
                    />
                </div>
                */}
                {getTextChanged()}
            </div> {/*: void 0*/}
            {Main.Instance.CalendarState.tc ?
            <div className = "row admin-users-row-area" style = {{marginTop: '230px'}}>
                <div className = "register-input textarea">
                    <FormInputArea  
                        value = {!Main.Instance.CalendarState.tc[Main.Instance.DatabaseDataState.language]
                        ? '' : Main.Instance.CalendarState.tc[Main.Instance.DatabaseDataState.language]}
                        ClassName = "form-control register-input"
                        onChange = {(text) => TcChanged (text)}
                        placeholder = {Assets.TermsAndConditions}
                        // icon = {Assets_.faReportLightClass}
                        iconText = {Assets.TC}
                        boldText = {true}
                    />
                </div>
            </div> : void 0}
            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.CalendarState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.CalendarState.mode ? 
                                'btn btn-warning btn-sm btn-plus fr mt0' : 
                                'btn btn-primary btn-sm btn-plus fr mt0'}
                    // icon= {Assets_.faUpdateButtonClass} 
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>
            </div> : void 0}

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CalendarState.pSize ? '' : 
                Main.Instance.CalendarState.pSize.toString()}
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.CalendarState.filter_future ? '0' : 
                        Main.Instance.CalendarState.filter_future}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any}
                o2 = "Today &amp; Future"
                onChange = {(text) => ApplyFilterFuture(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CalendarState.count ? Main.Instance.CalendarState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CalendarState.count ? Main.Instance.CalendarState.pNum * 
                Main.Instance.CalendarState.pSize + 1 : 0}{' - '}
                {Main.Instance.CalendarState.count ? 
                ((Main.Instance.CalendarState.pNum + 1) * 
                Main.Instance.CalendarState.pSize > Main.Instance.CalendarState.count ? 
                Main.Instance.CalendarState.count : 
                (Main.Instance.CalendarState.pNum + 1) * Main.Instance.CalendarState.pSize) : 0}
            </span>
        <span className = "label label-success table-label mt5">
            {Assets.page}
            {Main.Instance.CalendarState.count ? 
            Main.Instance.CalendarState.pNum + 1 :0}{' / '}
            {Main.Instance.CalendarState.count ? 
            Math.trunc((Main.Instance.CalendarState.count - 1) /
            Main.Instance.CalendarState.pSize + 1) : 0}
        </span>
        </div>
        <br/>
        {/*{width: 0, Header: '', accessor: '', Cell: (row) => <div/>}*/}
        {Main.Instance.CalendarState.calendarDay ? 
        <ReactTable
            data = {Main.Instance.CalendarState.calendarDay}
            columns = {[
                {width: 40, Header: '', accessor: '', Cell: (row) => dateColor(row.original.idx) !== '#ddd' ? TableUtils.EditButton(row, handleEdit) : void 0},
                {width: 100, Header: Assets.ExactDate, accessor: 'idx', headerClassName: 'th-left', Cell:
                    (row) => <span style = {{fontWeight: 700, fontSize: '16px', color: dateColor(row.value)}}>{getDay(row.value)}</span>},
                {width: 100, Header: Assets.Title, accessor: 'title', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                {width: 100, Header: Assets.Paragraph + ' 1', accessor: 'text1', headerClassName: 'th-left', Cell: (row) =>
                    <span style = {{fontWeight: row.original.bold_text[0] ? 700 : 400}}>{row.value[Main.Instance.DatabaseDataState.language]}</span>},
                {width: 100, Header: Assets.Paragraph + ' 2', accessor: 'text2', headerClassName: 'th-left', Cell: (row) =>
                    <span style = {{fontWeight: row.original.bold_text[1] ? 700 : 400}}>{row.value[Main.Instance.DatabaseDataState.language]}</span>},
                {width: 100, Header: Assets.Paragraph + ' 3', accessor: 'text3', headerClassName: 'th-left', Cell: (row) =>
                    <span style = {{fontWeight: row.original.bold_text[2] ? 700 : 400}}>{row.value[Main.Instance.DatabaseDataState.language]}</span>},
                {width: 100, Header: Assets.Paragraph + ' 4', accessor: 'text4', headerClassName: 'th-left', Cell: (row) =>
                    <span style = {{fontWeight: row.original.bold_text[3] ? 700 : 400}}>{row.value[Main.Instance.DatabaseDataState.language]}</span>},
                {width: 100, Header: Assets.Paragraph + ' 5', accessor: 'text5', headerClassName: 'th-left', Cell: (row) =>
                    <span style = {{fontWeight: row.original.bold_text[4] ? 700 : 400}}>{row.value[Main.Instance.DatabaseDataState.language]}</span>},
                {width: 150, Header: Assets.TermsAndConditions, accessor: 'tc', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                {width: 140, Header: Assets.ActionButtonText, accessor: 'button', headerClassName: 'th-left',
                Cell: (row) => <div className = {row.value !== undefined && row.value !== null && row.value[Main.Instance.DatabaseDataState.language].trim().length > 0 ? 'action-button-text' : 'abtn'}>
                    {<a className = "btn btn-info btn-sm" target = "_blank" href = {row.original.link}>{row.value[Main.Instance.DatabaseDataState.language]}</a>}</div>},
                {Header: Assets.ActionButtonLinkDescription, accessor: 'link', headerClassName: 'th-left', Cell: (row) => 
                row.value !== null && row.value !== undefined && row.value.trim().length > 0 ? <a href = {row.value} target = "_blank" className = "btn btn-info btn-sm">{row.value}</a> : ''},
                isPureScandibet() ?
                {width: 87, Header: Assets.PastImage, className: 'sip', accessor: 'image2', headerClassName: 'th-left', Cell: (row) => row.value.trim().length > 0 && parseInt(row.value) > 0 ?
                <img style = {{background: `url(${Assets_.checkered})`}} className = "img img-responsive" src = {require('../../../Assets/pi' + row.value + '.png')}/> : void 0}
                : {width: 40, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                {width: 45, Header: Assets.Image, accessor: 'image', headerClassName: 'th-left sip pt5', className: 'sip sih',
                Cell: (row) => <img style = {{background: `url(${Assets_.checkered})`}} className = "img img-responsive" src = {row.value}/>},
                // tslint:disable-next-line:max-line-length
                {show: !isPureScandibet(), width: 120, Header: Assets.Decoration, accessor: 'calendar_id', headerClassName: 'th-left', Cell: (row) => Assets_.ChristmasDecorations[row.value].name},
            ]}
            defaultPageSize = {Main.Instance.CalendarState.pSize}
            showPaginationBottom = {false}
        /> : getCalendarCount()}
        <br/>
        <br/>
        </div>
    </div>)
}

function dateColor(value: string) : string {
    const now: Date = new Date()
    const todayValue: number = (now.getMonth() - 10) * 30 + now.getDate() - 26 + parseInt(value)
    // console.log (todayValue)
    if (todayValue < 4) { return '#ddd' }
    if (todayValue === 4) { return 'red' }
    return '#333'
}

function getDay(which: number): string {
    if (which === undefined) { return '?' } else {
        const date: Date = new Date(isPureScandibet() ? '2018-11-22' : '2018-11-30')
        date.setDate (date.getDate() + which)
        const datee = date.toISOString()
        return Assets.Months[parseInt(datee.substring(5,7)) + 1].substr(0,3) + ' ' + datee.substring(8,10)
    }
}

function getTextChanged(): JSX.Element[] {
    const tc: JSX.Element[] = []
    for (let i: number = 0; i < 5; i++) {
        tc.push (
            <div
                key = {i.toString()}
                className = "register-input textarea-small textarea-small-calendar"
            >
                <FormInputArea  
                    value = {!Main.Instance.CalendarState.text[i]
                        [Main.Instance.DatabaseDataState.language]
                    ? '' : Main.Instance.CalendarState.text[i]
                        [Main.Instance.DatabaseDataState.language]}
                    ClassName = "form-control register-input"
                    onChange = {(text) => TextChanged (i, text)}
                    placeholder = {Assets.PopupText + ' ' + (i + 1).toString()/*Main.Instance.CalendarState.textSelected*/}
                    bold = {Main.Instance.CalendarState.bold_text[i] ? true : false}
                    boldText = {Main.Instance.CalendarState.bold_text[i] ? true : false}
                    iconText = {Assets.ParagraphShort.toLowerCase() + '. ' + (i+1)}
                    inputGroupAddonBottom = {true}
                />
                <button
                    onClick = {() => BoldTextChanged(i)}
                    style = {{position: 'absolute', top: '2px', left: '5px'}}
                    className = {Main.Instance.CalendarState.bold_text[i] ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                >
                        {Main.Instance.CalendarState.bold_text[i] ? 'bold txt' : 'normal'}
                </button>
            </div>,
        )
    }
    return tc
}