import * as React from 'react'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import * as Assets_ from '../../Assets_'
import {ButtonIcon} from '../../Reusables/Button'
import {FormInput, FormInputNumber} from '../../Reusables/Inputs'
import {faAd, faHourglassStart, faHourglassEnd, faArrowCircleUp, faSave, faTimes, faAsterisk, faCheck} from '@fortawesome/free-solid-svg-icons'
import {Logic} from '../../../Logic/Type'
import {SingleDatePicker} from 'react-dates'
import Switch from 'react-toggle-switch'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BonusCampaignNew } from 'src/Logic/Database/DatabaseData'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {gotoTop} from '../../../Logic/Utils'
import * as moment from 'moment'


export function BonusCampaignNew() {
    DbRoutes.wsReinitialize()

    if (!Main.Instance.BonusCampaignStateNew.bonusCampaign) {
        setInitialValues()
    }
    
    return (
        <div>
            <div className = "clearfix">
                <h3 className = "level fl">
                    {Assets.AddEditBonusCampaign}
                </h3>
            </div>
            <div className = "clearfix"/>
            <div className = "row admin-users-container clearfix">
                <br/>
                <div 
                    className = {Main.Instance.BonusCampaignStateNew.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >  
                    <div className = "create-input">
                        <div className = "row admin-users-row" style = {{position: 'relative'}}>
                            <h4>{Main.Instance.BonusCampaignStateNew.mode ? Assets.EditTheBonusCampaign + ': ' + Main.Instance.BonusCampaignStateNew.bonusCampaign.campaignName  : Assets.AddANewBonusCampaign}</h4>

                            {Main.Instance.BonusCampaignStateNew.mode ? 
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => gotoInsertMode()}
                                ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                                icon = {Assets_.faCancelButtonClass}
                            /> : <span/>}
                        </div>
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <FormInput
                                description = {Assets.BonusCampaign + ' ' + Assets.Name.toLowerCase()}
                                narrow = {true}
                                value = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.campaignName  ? Main.Instance.BonusCampaignStateNew.bonusCampaign.campaignName : ''} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => {updateValue('campaignName', text)}} 
                                placeholder = {Assets.BonusCampaign + ' ' + Assets.Name.toLowerCase()}
                                Icon = {faAd}
                            />
                            <div title = {Assets.Date + ' ' + Assets.Start.toLowerCase()} className = "fl mr singledatepicker">
                                <SingleDatePicker
                                    id = "date10"
                                    placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                                    date = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.dateStart ? Main.Instance.BonusCampaignStateNew.bonusCampaign.dateStart : null}
                                    onDateChange = {(date) => updateValue('dateStart', date)}
                                    focused = {Main.Instance.BonusCampaignStateNew.focusedInputStart}
                                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.BonusCampaignStateNew, {focusedInputStart: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                    // isDayHighlighted = {(date) => HighlightToday(date)}
                                />
                            </div>
                            <FormInput
                                description = {Assets.Time + ' ' + Assets.Start.toLowerCase()}
                                veryNarrow = {true}
                                value = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.timeStart ? Main.Instance.BonusCampaignStateNew.bonusCampaign.timeStart : ''}
                                ClassName = "form-control register-input" 
                                type = "text"
                                onChange = {(text) => updateValue('timeStart', text)} 
                                placeholder = {Assets.Time.charAt(0) + '. ' + Assets.Start.toLowerCase()}
                                Icon = {faHourglassStart}
                            />
                            <div title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl mr singledatepicker">
                                <SingleDatePicker
                                    id = "date11"
                                    placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                                    date = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.dateEnd ? Main.Instance.BonusCampaignStateNew.bonusCampaign.dateEnd : null}
                                    onDateChange = {(date) => updateValue('dateEnd', date)}
                                    focused = {Main.Instance.BonusCampaignStateNew.focusedInputEnd}
                                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.BonusCampaignStateNew, {focusedInputEnd: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                    // isDayHighlighted = {(date) => HighlightToday(date)}
                                />
                            </div>
                            <FormInput
                                description = {Assets.Time + ' ' + Assets.End.toLowerCase()}
                                veryNarrow = {true}
                                value = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.timeEnd ? Main.Instance.BonusCampaignStateNew.bonusCampaign.timeEnd : ''}
                                ClassName = "form-control register-input" 
                                type = "text"
                                onChange = {(text) => updateValue('timeEnd', text)} 
                                placeholder = {Assets.Time.charAt(0) + '. ' + Assets.Start.toLowerCase()}
                                Icon = {faHourglassEnd}
                            />
                            <FormInputNumber
                                description = {Assets.BonusCampaign + ' ' + Assets.Priority.toLowerCase()}
                                value = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.priority ? Main.Instance.BonusCampaignStateNew.bonusCampaign.priority.toString() : ''} 
                                outerDiv = "rows-per-table input-group fl mr pl0"
                                ClassName = "h30 w60 pl7"
                                type = "number" 
                                onChange = {(text) => updateValue('priority', +text)} 
                                min = "0"
                                iconText = {Assets.Priority}
                                iconTextS  = "12px"
                            />
                            <div className = "fl dbl" style = {{marginLeft: '5px', marginRight: '5px'}}>
                                <div style = {{lineHeight: '14px', marginTop: '8px'}}>{Assets.Combinable}</div>
                            </div>
                            <Switch
                                className = "mt2"
                                onClick = {() => {
                                    const bonusCampaign = Object.assign({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
                                    bonusCampaign.combinable = !bonusCampaign.combinable
                                    Logic.Type.New(Main.Instance.BonusCampaignStateNew, {bonusCampaign})
                                }}
                                on = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.combinable}
                            />
                        </div>
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            {manageBonuses()}
                        </div>
                        <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                            <ButtonIcon 
                                description = {Main.Instance.BonusCampaignStateNew.mode ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => handleInsertUpdate()}
                                ClassName = {Main.Instance.BonusCampaignStateNew.mode ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>
                    </div>
                </div>
                <h3>{Assets.ListOfBonusCampaign}</h3>

                <ReactTable
                    data = {Main.Instance.DatabaseDataState.bonusCampaignNew ? Main.Instance.DatabaseDataState.bonusCampaignNew : []}
                    columns = {[
                        {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                        {width: 200, Header: Assets.BonusCampaign + ' ' + Assets.Name.toLowerCase(), accessor: 'campaignName', headerClassName: 'th-left'},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Priority + '?'} icon = {faAsterisk}/>, accessor: 'priority', headerClassName: 'th-center'}, 
                        {width: 90, Header: Assets.Start + ' ' + Assets.Date.toLowerCase(), accessor: 'startDate', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.toString().substring(0, 10) : '-'},
                        {width: 80, Header: <span>{Assets.Time}<small>&nbsp;({Assets_.UTC})</small></span>, accessor: 'startDate', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.toString().substring(11, 19) : '-'},
                        {width: 90, Header: Assets.End + ' ' + Assets.Date.toLowerCase(), accessor: 'endDate', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.toString().substring(0, 10) : '-'},
                        {width: 80, Header: <span>{Assets.Time}<small>&nbsp;({Assets_.UTC})</small></span>, accessor: 'endDate', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.toString().substring(11, 19) : '-'},
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Combinable + '?'} icon = {faArrowCircleUp}/>, accessor: 'combinable', headerClassName: 'th-center', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>}, 
                        {Header: Assets.BonusCampaign + ' ' + Assets.Items.toLowerCase(), accessor: 'bonusIds', headerClassName: 'th-left', Cell: (row) => GetBonuses (row.value)},
                        {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    defaultPageSize={50}
                /> 
            </div> 
        </div>
    )
}

function gotoInsertMode() {
    if (JSON.stringify(Main.Instance.BonusCampaignStateNew.bonusCampaign) === Main.Instance.BonusCampaignStateNew.bonusCampaign_) {
        setInitialValues()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            setInitialValues() 
        }                
    }        
}

export function setInitialValues() {
    const bonusCampaign: BonusCampaignNew = Object.assign({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
    bonusCampaign.campaignName = ''
    bonusCampaign.id = null
    bonusCampaign.priority = 1
    bonusCampaign.bonusIds = []
    bonusCampaign.combinable = false
    bonusCampaign.startDate = ''
    bonusCampaign.endDate = ''
    bonusCampaign.dateStart = undefined
    bonusCampaign.dateEnd = undefined
    bonusCampaign.timeStart = ''
    bonusCampaign.timeEnd = ''
    Logic.Type.New(Main.Instance.BonusCampaignStateNew, {bonusCampaign, bonusCampaign_: undefined, mode: false})
}

function handleDelete(row: any) {
    if (row) {
        if (row.original.startDate) {
            
            const now = Date.now() / 1000 as number
            const start = Date.parse(row.original.startDate) / 1000 as number
            if (now > start) {
                alert(Assets.BonusCampaign + ' ' + Assets.Started)
                return
            }
        }

        
        if (confirm(Assets.sureToDelete + ' ' + Assets.BonusCampaign.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.campaignName + '"?')) {
            DbRoutes.deleteBonusCampaign(row.original.id) 
        }
    }
}

function handleInsertUpdate() {
    const bonusCampaign: BonusCampaignNew = Object.assign ({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
    bonusCampaign.startDate = bonusCampaign && bonusCampaign.dateStart ? (bonusCampaign.dateStart.format(Assets_.dateDisplayFormat) + (bonusCampaign.timeStart ? 'T' + bonusCampaign.timeStart : 'T00:00:00') + 'Z') : undefined
    bonusCampaign.endDate = bonusCampaign && bonusCampaign.dateEnd ? (bonusCampaign.dateEnd.format(Assets_.dateDisplayFormat) + (bonusCampaign.timeEnd ? 'T' + bonusCampaign.timeEnd : 'T00:00:00') + 'Z') : undefined
    
    if (Main.Instance.BonusCampaignStateNew.bonusCampaign) {
        // const now = Date.now() / 1000 as number
        const start = Date.parse(bonusCampaign.startDate) / 1000 as number
        const end = Date.parse(bonusCampaign.endDate) / 1000 as number

        if (Main.Instance.BonusCampaignStateNew.bonusCampaign.campaignName === undefined || Main.Instance.BonusCampaignStateNew.bonusCampaign.campaignName.length === 0 ) {
            alert(Assets.Please + ' ' + Assets.Insert.toLowerCase() + ' ' + Assets.BonusCampaign.toLowerCase() + ' ' + Assets.Name.toLowerCase())
            return
        }
        if (!Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds || Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds.length === 0) {
            alert(Assets.Please + ' ' + Assets.add + ' ' + Assets.Bonus_es)
            return
        }
        /*
        if (start < now) {
            alert (Assets.compulsory + ' ' + Assets.StartDate)
            return
        }
        */
        if (end < start) {
            alert (Assets.compulsory + ' ' + Assets.EndDate)
            return
        }
    } else {
        alert (Assets.sfh + ' ' + Assets.Campaign.toLowerCase())
        return
    }

    delete(bonusCampaign.dateStart)
    delete(bonusCampaign.dateEnd)
    delete(bonusCampaign.timeStart)
    delete(bonusCampaign.timeEnd)
    
    if (Main.Instance.BonusCampaignStateNew.mode === false) {
        delete(bonusCampaign.id)
    }

    bonusCampaign.eventId = 'event_id'
    DbRoutes.insertUpdateBunusCampaign(bonusCampaign)
    Logic.Type.New(Main.Instance.BonusCampaignStateNew, {mode: false})
    setInitialValues()
    
}

function handleEdit(row: any) {
    /*
    if (row.original.startDate) {
        const now = Date.now() / 1000 as number
        const start = Date.parse(row.original.startDate) / 1000 as number
        if (now > start) {
            alert(Assets.BonusCampaign + ' ' + Assets.Started)
            return
        }
    }
    */
    gotoTop()
    const bonusCampaign: BonusCampaignNew = Object.assign ({}, JSON.parse(JSON.stringify(row.original)))
    if (bonusCampaign &&  bonusCampaign.startDate) {
        bonusCampaign.dateStart = moment.default(bonusCampaign.startDate)
        bonusCampaign.timeStart = bonusCampaign.startDate.substring(11, 19)
    }
    
    if (bonusCampaign &&  bonusCampaign.endDate) {
        bonusCampaign.dateEnd = moment.default(bonusCampaign.endDate)
        bonusCampaign.timeEnd = bonusCampaign.endDate.substring(11, 19)
    }

    
    if (row) {
        Logic.Type.New(Main.Instance.BonusCampaignStateNew, {mode: true, bonusCampaign, bonusCampaign_: JSON.stringify(bonusCampaign),})
    }
}

function updateValue(property: string, value: any) {
    const bonusCampaign: BonusCampaignNew = Object.assign({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
    bonusCampaign[property] = value
    Logic.Type.New(Main.Instance.BonusCampaignStateNew, {bonusCampaign})
}

function manageBonuses(): JSX.Element[] {
    const bc: JSX.Element[] = []
    if (Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds) {
        for (let i: number = 0; i < Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds.length; i++) {
            bc.push(
                <select
                    key = {i.toString()}
                    style = {{width: '13.5%', marginRight: '1.5%', height: '30px'}}
                    className = "fl dbl form-control"
                    onChange = {(e) => {
                        const bonusCampaign: BonusCampaignNew = Object.assign ({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
                        bonusCampaign.bonusIds[i] = +e.target.value
                        Logic.Type.New (Main.Instance.BonusCampaignStateNew, {bonusCampaign})
                    }}
                    value = {Main.Instance.BonusCampaignStateNew.bonusCampaign && Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds && Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds[i] ? Main.Instance.BonusCampaignStateNew.bonusCampaign.bonusIds[i] : 0}
                >
                    {getBonusOptions()}
                </select>
            )
            bc.push(
                <div title = {Assets.RemoveABonusCode} style = {{marginLeft: '-1.5%', zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                    <button onClick = {() => removePropertyMember('bonusIds', i)} style = {{position: 'absolute', right: '-10px', top: '-3px', marginRight: '0px', border: '0px', background: 'transparent',/* marginLeft: '-22px', marginTop: '-3px',*/ padding: '0px'}}>
                        <FontAwesomeIcon icon = {faTimes}/>
                    </button>
                </div>
            )
        }
    }
    
    const allBonusesTaken: boolean = areAllBonusesTaken()
    bc.push (<button /*disabled = {allBonusesTaken}*/ title = {allBonusesTaken ? Assets.AllBonusesAreTaken : Assets.AddANewBonus} key = "-1" onClick = {() => addPropertyMember('bonusIds')} className = "btn btn-success btn-sm mr0 margin-bottom-sm dbl fl">{Assets.AddANewBonus}</button>)
    return bc
}

function removePropertyMember(property: string, i: number) {
    const bonusCampaign: BonusCampaignNew = Object.assign({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
    bonusCampaign[property].splice (i, 1)
    Logic.Type.New (Main.Instance.BonusCampaignStateNew, {bonusCampaign})
}

function addPropertyMember(property: string) {
    let bonusCampaign: BonusCampaignNew = Object.assign({}, Main.Instance.BonusCampaignStateNew.bonusCampaign)
    if (bonusCampaign === undefined) { bonusCampaign = {} }
    if (bonusCampaign[property] === undefined) { bonusCampaign[property] = [] }
    bonusCampaign[property].push(0)
    Logic.Type.New (Main.Instance.BonusCampaignStateNew, {bonusCampaign})
}

function GetBonuses(bonusIds: number[]): JSX.Element {
    const b: JSX.Element[] = []
    if (bonusIds) {
        for (let i: number = 0; i < bonusIds.length; i++) {
            let s: string = ''
            if (Main.Instance.DatabaseDataState.bonusNew) {
                for (const item of Main.Instance.DatabaseDataState.bonusNew) {
                    if (item.bonusId === bonusIds[i]) {
                        s = item.bonusName && item.bonusName.toString()
                        break
                    }
                }
            }
            

            b.push(
                <span key = {i.toString()} style = {{fontSize: '12px', marginRight: i === bonusIds.length - 1 ? '0px' : '5px'}} className = {'label label-default'}>
                    {s}
                </span>
            )        
        }
    }

    return (<div style = {{marginTop: '-2px'}}>{b}</div>)
}

function getBonusOptions(): JSX.Element[] {
    const bo: JSX.Element[] = []
    bo.push(<option key = "0" value = {0}>{Assets.Select + ' ' + Assets.Bonus.toLowerCase()}</option>)
    if (Main.Instance.DatabaseDataState.bonusNew && Main.Instance.DatabaseDataState.bonusCampaignNew) {
        for (const item of Main.Instance.DatabaseDataState.bonusNew) {
            let alreadyThere: boolean = false
            for (const Item of Main.Instance.DatabaseDataState.bonusCampaignNew) {
                if (Item.bonusIds) {
                    for (const ITEM of Item.bonusIds) {
                        if (ITEM === item.bonusId) {
                            alreadyThere = true
                            break
                        }
                    }
                }
            }

            bo.push(<option key = {item.bonusId} value = {item.bonusId} disabled = {alreadyThere}>{item.bonusName}</option>)
        }
    }
    return bo
}

function areAllBonusesTaken(): boolean {
    if (Main.Instance.DatabaseDataState.bonusNew && Main.Instance.DatabaseDataState.bonusCampaignNew) {
        for (const item of Main.Instance.DatabaseDataState.bonusNew) {
            let alreadyThere: boolean = false
            for (const Item of Main.Instance.DatabaseDataState.bonusCampaignNew) {
                let BREAK = false
                if (Item && Item.bonusIds) {
                    for (const ITEM of Item.bonusIds) {
                        if (ITEM === item.bonusId) {
                            alreadyThere = true
                            BREAK = true
                            break
                        }
                    }
                }
                if (BREAK) {break}
            }
            if (!alreadyThere) {return false}
        }
    }
    return true
}