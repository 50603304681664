import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import ReactTable from 'react-table'
import {faTimes, faCheck} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
interface State {
    robinChoice: boolean
    bookmaker: boolean
    bettingExchange: boolean 
}

export class TableOfBookmakers extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            robinChoice: false,
            bookmaker: false,
            bettingExchange: false
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getCountry(id: number): string {
        if (Main.Instance.OddsState.countries) {
            for (const item of Main.Instance.OddsState.countries) {
                if (item.id === id) {
                    return item.name
                }
            }
        }
        return '-'
    }

    public getFilter(): JSX.Element[] {
        const filter: JSX.Element[] = []
        filter.push (<input key = "0" onClick = {() => this.setState({bookmaker: !this.state.bookmaker})} checked = {this.state.bookmaker} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "1" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.Bookmaker}
            </div>
        )

        filter.push (<input key = "2" onClick = {() => this.setState({bettingExchange: !this.state.bettingExchange})} checked = {this.state.bettingExchange} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "3" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.Betting + ' ' + Assets.Exchange.toLowerCase()}
            </div>
        )

        filter.push (<input key = "4" onClick = {() => this.setState({robinChoice: !this.state.robinChoice})} checked = {this.state.robinChoice} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "5" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.RobinsChoice}
            </div>
        )

        return filter
    }

    public render() {
        // console.log (Main.Instance.OddsState.bookmakers)
        const filtered = []
        if (this.state.robinChoice) {
            filtered.push({id: 'robin_choice', value: true})
        }

        if (this.state.bookmaker) {
            filtered.push({id: 'isBookmaker', value: true})
        }

        if (this.state.bettingExchange) {
            filtered.push({id: 'isBettingExchange', value: true})
        }
        return (
            <div>
                <h3 className = "pr">
                    {Assets.Bookmakers + ' ' + Assets.Preview.toLowerCase()}
                    <div style = {{position: 'absolute', top: '10px', right: '0px'}}>{this.getFilter()}</div>
                </h3>
                {Main.Instance.OddsState.bookmakers ?
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.OddsState.bookmakers}
                    columns = {[
                        {width: 50, Header: Assets.ID, headerClassName: 'th-left', accessor: 'id'},
                        {width: 300, Header: Assets.Name, headerClassName: 'th-left', accessor: 'name'},
                        {width: 200, Header: Assets.Preferred + ' ' + Assets.Country.toLowerCase(), headerClassName: 'th-left', accessor: 'preferredCountryID', Cell: (row) => this.getCountry(row.value)},
                        {width: 100, Header: Assets.Bookmaker, headerClassName: 'th-center', accessor: 'isBookmaker', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                        {width: 200, Header: Assets.Betting + ' ' + Assets.Exchange, headerClassName: 'th-center', accessor: 'isBettingExchange', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                        {width: 120, Header: Assets.RobinsChoice, headerClassName: 'th-center', accessor: 'robin_choice', Cell: (row) => <div className = "tac"><FontAwesomeIcon style = {{color: row.value ? '#5cb85c' : '#d9534f'}} icon = {row.value ? faCheck : faTimes}/></div>},
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                /> : void 0}
            </div>
        )
    }
}