import * as React from 'react'
// import { Main } from '../../../Logic/Main'
// import d3 from 'd3'
// import topojson from 'topojson'
// import * as Datamaps from '../../../../node_modules/@gamingenius/datamapz/dist/datamaps.all.hires'
import * as Datamaps from '../../../../node_modules/@gamingenius/datamapz/dist/datamaps.che'
// import * as Datamaps from '../../../../node_modules/@gamingenius/datamapz/dist/datamaps.chl'
const MAP_CLEARING_PROPS = ['height', 'scope', 'setProjection', 'width'];

const propChangeRequiresMapClear = (oldProps:any, newProps:any) => {
    return MAP_CLEARING_PROPS.some((key) =>
        oldProps[key] !== newProps[key],
    );
};

export class DatamapSwitzerlandSmall extends React.Component <{
    arc?: any[], 
    arcOptions?: any, 
    bubbleOptions?: any, 
    bubbles?: any[], 
    data?: any,
    geographyConfig?: any,
    fills?: any,
    graticule?: boolean, 
    height?: number, 
    labels?: boolean, 
    labels_?: any, 
    responsive?: boolean, 
    style?: any, 
    updateChoroplethOptions?: any, 
    width?: number,
    aspectRatio?: number,
    setProjection?: any,
    scope: string,
    report?: boolean,
}, {}> {
/*
    static propTypes = {
        arc: React.PropTypes.array,
        arcOptions: React.PropTypes.object,
        bubbleOptions: React.PropTypes.object,
        bubbles: React.PropTypes.array,
        data: React.PropTypes.object,
        fills:React.PropTypes.object,
        geographyConfig: React.PropTypes.object,
        graticule: React.PropTypes.bool,
        height: React.PropTypes.number,
        labels: React.PropTypes.bool,
        labels_:React.PropTypes.object,
        responsive: React.PropTypes.bool,
        style: React.PropTypes.object,
        updateChoroplethOptions: React.PropTypes.object,
        width: React.PropTypes.number,
        aspectRatio: React.PropTypes.number,
        setProjection: React.PropTypes.object,
        report: React.PropTypes.bool,
        scope: React.PropTypes.string,	
    };
*/
    public map: any
    public refs: any

    constructor(props:any) {
        super(props)
        this.resizeMap = this.resizeMap.bind(this)
    }

    public componentDidMount() {
        if (this.props.responsive) {
            window.addEventListener('resize', this.resizeMap);
        }
        this.drawMap()
    }
/*
    public componentWillReceiveProps(newProps:any) {
        if (propChangeRequiresMapClear(this.props, newProps)) {
            this.clear()
        }
    }
*/
    public componentDidUpdate(newProps:any) {
        if (propChangeRequiresMapClear(this.props, newProps)) {
            this.clear()
        }
        this.drawMap()
    }

    public componentWillUnmount() {
        this.clear()
        if (this.props.responsive) {
            window.removeEventListener('resize', this.resizeMap)
        }
    }

    public clear() {
        const { container }: any = this.refs;

        for (const child of Array.from(container.childNodes)) {
            container.removeChild(child)
        }

        delete this.map
    }

    public drawMap() {
        const {
            arc,
            arcOptions,
            bubbles,
            bubbleOptions,
            data,
            graticule,
            labels,
            updateChoroplethOptions,
            labels_,
            ...props
        } = this.props

        let map = this.map

        if (map) {
            this.clear()
        }
        map = this.map = new Datamaps({
        ...props,
        data,
        element: this.refs.container,
        scope: this.props.scope,
        })

        if (arc) {
            map.arc(arc, arcOptions)
        }

        if (bubbles) {
            map.bubbles(bubbles, bubbleOptions)
        }

        if (graticule) {
            map.graticule()
        }

        if (labels) {
            map.labels(labels_)
        }
    }

    public resizeMap() {
        this.map.resize()
    }

    public render() {
        const style:any = {
            minHeight: '155px',
            position: 'relative' as 'relative',
            minWidth: '300px',
            ...this.props.style,
        }
/*
        if (this.props.report) style = {
            minHeight: '150px',
            position: 'relative' as 'relative',
            minWidth: '250px',
            ...this.props.style
        } 

        else if (Main.Instance.RiskManagementState.showMap)  
            style = {
                height: '500px',
                position: 'relative' as 'relative',
                width: '880px',
                ...this.props.style
            }        
        else 
            style = {
                minHeight: '780px',
                position: 'relative' as 'relative',
                minWidth: '1213px',
                ...this.props.style
            }
*/
        // tslint:disable-next-line:jsx-no-string-ref
        return <div ref = "container" className = "map" style = {style}/>
    }
}
/*
export class SmallSwitzerland extends React.Component <{
    arc?: any[], 
    arcOptions?: any, 
    bubbleOptions?: any, 
    bubbles?: any[], 
    data?: any,
    geographyConfig?: any,
    fills?: any,
    graticule?: boolean, 
    height?: number, 
    labels?: boolean, 
    labels_?: any, 
    responsive?: boolean, 
    style?: any, 
    updateChoroplethOptions?: any, 
    width?: number,
    aspectRatio?: number,
    setProjection?: any,
    scope: string,
    report?: boolean,
}, {}> {
    map: any
    refs: any

    constructor(props:any) {
        super(props)
        this.resizeMap = this.resizeMap.bind(this)
    }

    componentDidMount() {
        if (this.props.responsive) {
            window.addEventListener('resize', this.resizeMap);
        }
        this.drawMap()
    }

    componentWillReceiveProps(newProps:any) {
        if (propChangeRequiresMapClear(this.props, newProps)) {
            this.clear()
        }
    }

    componentDidUpdate() {
        this.drawMap()
    }

    componentWillUnmount() {
        this.clear()
        if (this.props.responsive) {
            window.removeEventListener('resize', this.resizeMap)
        }
    }

    clear() {
        const { container }: any = this.refs;

        for (const child of Array.from(container.childNodes)) {
            container.removeChild(child)
        }

        delete this.map
    }

    drawMap() {
        const {
            arc,
            arcOptions,
            bubbles,
            bubbleOptions,
            data,
            graticule,
            labels,
            updateChoroplethOptions,
            labels_,
            ...props
        } = this.props

        let map = this.map

        if (map) {
            this.clear()
        }
        map = this.map = new Datamaps({
        ...props,
        data,
        element: this.refs.container,
        scope: this.props.scope
        })

        if (arc) {
            map.arc(arc, arcOptions)
        }

        if (bubbles) {
            map.bubbles(bubbles, bubbleOptions)
        }

        if (graticule) {
            map.graticule()
        }

        if (labels) {
            map.labels(labels_)
        }
    }

    resizeMap() {
        this.map.resize()
    }

    render() {
        const style: any = {
                height: '220px',
                position: 'relative' as 'relative',
                width: '330px',
                ...this.props.style
            }        
        // tslint:disable-next-line:jsx-no-string-ref
        return <div ref = "container" className = "map" style = {style}/>
    }
}

export class LargeSwitzerland extends React.Component <{
    arc?: any[], 
    arcOptions?: any, 
    bubbleOptions?: any, 
    bubbles?: any[], 
    data?: any,
    geographyConfig?: any,
    fills?: any,
    graticule?: boolean, 
    height?: number, 
    labels?: boolean, 
    labels_?: any, 
    responsive?: boolean, 
    style?: any, 
    updateChoroplethOptions?: any, 
    width?: number,
    aspectRatio?: number,
    setProjection?: any,
    scope: string,
    report?: boolean,
}, {}> {
    map: any
    refs: any

    constructor(props:any) {
        super(props)
        this.resizeMap = this.resizeMap.bind(this)
    }

    componentDidMount() {
        if (this.props.responsive) {
            window.addEventListener('resize', this.resizeMap);
        }
        this.drawMap()
    }

    componentWillReceiveProps(newProps:any) {
        if (propChangeRequiresMapClear(this.props, newProps)) {
            this.clear()
        }
    }

    componentDidUpdate() {
        this.drawMap()
    }

    componentWillUnmount() {
        this.clear()
        if (this.props.responsive) {
            window.removeEventListener('resize', this.resizeMap)
        }
    }

    clear() {
        const { container }: any = this.refs;

        for (const child of Array.from(container.childNodes)) {
            container.removeChild(child)
        }

        delete this.map
    }

    drawMap() {
        const {
            arc,
            arcOptions,
            bubbles,
            bubbleOptions,
            data,
            graticule,
            labels,
            updateChoroplethOptions,
            labels_,
            ...props
        } = this.props

        let map = this.map

        if (map) {
            this.clear()
        }
        map = this.map = new Datamaps({
        ...props,
        data,
        element: this.refs.container,
        scope: this.props.scope
        })

        if (arc) {
            map.arc(arc, arcOptions)
        }

        if (bubbles) {
            map.bubbles(bubbles, bubbleOptions)
        }

        if (graticule) {
            map.graticule()
        }

        if (labels) {
            map.labels(labels_)
        }
    }

    resizeMap() {
        this.map.resize()
    }

    render() {
        const style: any = {
                height: '128px',
                position: 'relative' as 'relative',
                width: '200px',
                ...this.props.style
            }        
        // tslint:disable-next-line:jsx-no-string-ref
        return <div ref = "container" className = "map" style = {style}/>
    }
}
*/