import * as React from 'react'
import {Main} from './Main'
import * as Assets from '../Views/Assets'
import * as Assets_ from '../Views/Assets_'
import * as moment from 'moment'
import * as Config from './Config'
import {Logic} from './Type'
import { TimelineEntryType, TimelineEntrySubType, ThousandsSeparator, IssueTypes } from './Database/DatabaseData'
// import {isNotAssignedTo} from '../Views/Main/RiskComplianceStore/ResponsibleGaming'
// export const StringIsNumber = value => isNaN(Number(value)) === false
// import * as jsPDF from 'jspdf'

export const StringIsNotNumber = (value) => !(isNaN(Number(value)) === false)

export function IsPasswordDifferent(password: string, username): boolean {
    if (password === username) { return false }
    return true
}

export function isPasswordDifferent(password: string, username: string, email: string, phone: string): boolean {
    if (password === username || password === email || password === phone) { return false }
    return true
}

export function isPasswordStrongEnough(password: string): boolean {
    if (password === undefined || password.length < 8) { return false }
    // let validation: boolean = true
    let space: boolean = false
    let lowercase: boolean = false
    let uppercase: boolean = false
    let numbercase: boolean = false
    // let other: boolean = false

    for (let i: number = 0; i < password.length; i++) {
        if (password.charAt(i) === ' ') {
            space = true
        }

        if (password.charAt(i) >= 'a' && password.charAt(i) <= 'z') {
            lowercase = true
        } else if (password.charAt(i) >= 'A' && password.charAt(i) <= 'Z') {
            uppercase = true
        } else if (password.charAt(i) >= '0' && password.charAt(i) <= '9') {
            numbercase = true
        } // else other = true

        if (space) {
            return false
            // validation = false
            // break
        }
    }

    if (!lowercase || !uppercase || !numbercase /*|| !other*/) {return false /*validation = false*/}
    return true /*validation*/
}

export function updateCategory() {
    const category: any[] = []
    const Category2: any[] = []
    category.push ({id: '0', name: Assets.SelectCategory})
    Category2.push ({id: '0', name: Assets.AnyCategory})
    if (Main.Instance.FrontendStoreState.category) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.category.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
            Main.Instance.FrontendStoreState.category[i].length > 0) {
                const id: string = (1+Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                category.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
                Category2.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Category: category, Category2})
    }
}

export function updateTranslation() {
    const Translation: any[] = []
    const Translation_default: any[] = []
    Translation.push ({id: '0', name: Assets.SelectTranslation})
    Translation_default.push ({id: '0', name: Assets.SelectTranslation})
    if (Main.Instance.FrontendStoreState.translation && Main.Instance.DatabaseDataState.languages) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.translation.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
                    Main.Instance.FrontendStoreState.translation[i].length > 0) {
                    const id: string = (1 + Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                    Translation.push ({id, name: Main.Instance.FrontendStoreState.translation[i]})
            }
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.default_language &&
                    Main.Instance.FrontendStoreState.translation[i].length > 0) {
                    const id: string = (1 + Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                    Translation_default.push ({id, name: Main.Instance.FrontendStoreState.translation[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Translation, Translation_default})
    }
}

export function GetValidGamesNames(array: number[]):JSX.Element[] {
    const vgn:JSX.Element[] = []
    for (const item of array) {
        let name: string = ''
        for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
            if (item === Item['Id']) {
                name = Item[Assets.Name]
                break
            }
        }
        if (vgn.length > 0) { vgn.push (<span key = {item.toString() + '_'}>,&nbsp;</span>) }
        vgn.push (<span key = {item.toString()}>{name}</span>)
    }
    return vgn
}

export function areDatesSuitable(fromDate: moment.Moment, toDate: moment.Moment, fromTime: string, toTime: string): boolean {
    // console.log (toDate)
    if (fromDate === null && (fromTime === undefined || fromTime === null)) { return false }
    if (toDate === null && (toTime === undefined || toTime === null)) { return false }
    if (fromDate !== null && toDate !== null && fromDate !== undefined && toDate !== undefined && toDate.startOf('day').isBefore(fromDate.startOf('day'))) { return false }
    let fromToSameDate: boolean = false
    if (fromDate !== null && toDate !== null && fromDate !== undefined && toDate !== undefined && fromDate.startOf('day').isSame(toDate.startOf('day'))) { fromToSameDate = true }
    // console.log ('fromToSameDate: ' + fromToSameDate)
    // console.log ('fromTime: ' + fromTime)
    // console.log ('toTime: ' + toTime)
    if (fromTime === undefined || fromTime === null || fromTime.length !== 5 && fromTime.length !== 8) { return false }

    if (fromTime.charAt(0) !== '0' && fromTime.charAt(0) !== '1' && fromTime.charAt(0) !== '2') { return false }
    if (fromTime.charAt(1) !== '0' && fromTime.charAt(1) !== '1' && fromTime.charAt(1) !== '2' && fromTime.charAt(1) !== '3' && fromTime.charAt(1) !== '4'
        && fromTime.charAt(1) !== '5' && fromTime.charAt(1) !== '6' && fromTime.charAt(1) !== '7' && fromTime.charAt(1) !== '8' && fromTime.charAt(1) !== '9') {
        return false
    }
    if (fromTime.charAt(0) === '2' && fromTime.charAt(1) !== '0' && fromTime.charAt(1) !== '1' && fromTime.charAt(1) !== '2' && fromTime.charAt(1) !== '3') { return false }
    if (fromTime.charAt(2) !== ':') { return false }
    if (fromTime.charAt(3) !== '0' && fromTime.charAt(3) !== '1' && fromTime.charAt(3) !== '2' && fromTime.charAt(3) !== '3' && fromTime.charAt(3) !== '4'
        && fromTime.charAt(3) !== '5') {
        return false
    }
    if (fromTime.charAt(4) !== '0' && fromTime.charAt(4) !== '1' && fromTime.charAt(4) !== '2' && fromTime.charAt(4) !== '3' && fromTime.charAt(4) !== '4'
        && fromTime.charAt(4) !== '5' && fromTime.charAt(4) !== '6' && fromTime.charAt(4) !== '7' && fromTime.charAt(4) !== '8' && fromTime.charAt(4) !== '9') {
        return false
    }

    if (fromTime.length === 8) {
        if (fromTime.charAt(5) !== ':') { return false }
        if (fromTime.charAt(6) !== '0' && fromTime.charAt(6) !== '1' && fromTime.charAt(6) !== '2' && fromTime.charAt(6) !== '3' && fromTime.charAt(6) !== '4'
            && fromTime.charAt(6) !== '5') {
            return false
        }
        if (fromTime.charAt(7) !== '0' && fromTime.charAt(7) !== '1' && fromTime.charAt(7) !== '2' && fromTime.charAt(7) !== '3' && fromTime.charAt(7) !== '4'
            && fromTime.charAt(7) !== '5' && fromTime.charAt(7) !== '6' && fromTime.charAt(7) !== '7' && fromTime.charAt(7) !== '8' && fromTime.charAt(7) !== '9') {
            return false
        }
    } else { fromTime += ':00' }

    if (toTime === undefined || toTime === null || toTime.length !== 5 && toTime.length !== 8) { return false }
    if (toTime.charAt(0) !== '0' && toTime.charAt(0) !== '1' && toTime.charAt(0) !== '2') { return false }
    if (toTime.charAt(1) !== '0' && toTime.charAt(1) !== '1' && toTime.charAt(1) !== '2' && toTime.charAt(1) !== '3' && toTime.charAt(1) !== '4'
        && toTime.charAt(1) !== '5' && toTime.charAt(1) !== '6' && toTime.charAt(1) !== '7' && toTime.charAt(1) !== '8' && toTime.charAt(1) !== '9') {
        return false
    }
    if (toTime.charAt(0) === '2' && toTime.charAt(1) !== '0' && toTime.charAt(1) !== '1' && toTime.charAt(1) !== '2' && toTime.charAt(1) !== '3') { return false }
    if (toTime.charAt(2) !== ':') { return false }
    if (toTime.charAt(3) !== '0' && toTime.charAt(3) !== '1' && toTime.charAt(3) !== '2' && toTime.charAt(3) !== '3' && toTime.charAt(3) !== '4'
        && toTime.charAt(3) !== '5') {
        return false
    }
    if (toTime.charAt(4) !== '0' && toTime.charAt(4) !== '1' && toTime.charAt(4) !== '2' && toTime.charAt(4) !== '3' && toTime.charAt(4) !== '4'
        && toTime.charAt(4) !== '5' && toTime.charAt(4) !== '6' && toTime.charAt(4) !== '7' && toTime.charAt(4) !== '8' && toTime.charAt(4) !== '9') {
        return false
    }

    if (toTime.length === 8) {
        if (toTime.charAt(5) !== ':') { return false }
        if (toTime.charAt(6) !== '0' && toTime.charAt(6) !== '1' && toTime.charAt(6) !== '2' && toTime.charAt(6) !== '3' && toTime.charAt(6) !== '4'
            && toTime.charAt(6) !== '5') {
            return false
        }
        if (toTime.charAt(7) !== '0' && toTime.charAt(7) !== '1' && toTime.charAt(7) !== '2' && toTime.charAt(7) !== '3' && toTime.charAt(7) !== '4'
            && toTime.charAt(7) !== '5' && toTime.charAt(7) !== '6' && toTime.charAt(7) !== '7' && toTime.charAt(7) !== '8' && toTime.charAt(7) !== '9') {
            return false
        }
    } else { toTime += ':00' }

    if (fromToSameDate && fromTime && toTime && fromTime.length > 0 && toTime.length > 0) {

        if (toTime <= fromTime) { return false }
    }

    return true
}

export function post(num: number) : string {
    if (num % 100 === 1 && Math.floor (num / 10) !== 1) { return 'st' } else if (num % 100 === 2 && Math.floor (num / 10) !== 1) { return 'nd' } else if (num % 100 === 3 && Math.floor (num / 10) !== 1) { return 'rd' } else { return 'th' }
}

export function getStringFromNumbersArray(na: number[]): string {
    let result = ''
    for (const item of na) {
        if (result.length !== 0) { result += ', ' }
        result += item.toString()
    }
    return result
}

function product_Range(a: number, b: number) {
    let prd = a
    let i = a
   
    while (i++< b) {
      prd*=i;
    }
    return prd;
}

export function combinations(n: number, r: number) {
    if (n==r) {
      return 1;
    } else {
      r=(r < n-r) ? n-r : r;
      return product_Range(r + 1, n) / product_Range(1, n - r);
    }
}

export function getnumbers(array: any[], which: number) : number[] {
    const numbers:number[] = []
    let type: string = ''
    if (which === 0) { type = 'number' } else if (which === 1) { type = 'extranumber' } else if (which === 2) { type = 'bonusnumber' } else if (which === 3) { type = 'refundnumber' }    
    for (const item of array) {
        if (item.type === type) {
            numbers.push (item.number)
        }
    }
    return numbers
}

export function getNumbers(array: number[], which: number, arrayResults?: number[], extraResults?: number[]): JSX.Element[] {
    const num: JSX.Element[] = []
    for (const item of array) {
        let classname: string = 'lottery-number '
        if (which === 0) { classname += 'selected-numbers' } else if (which === 1) { classname += 'selected-extranumbers' } else if (which === 2) { classname += 'selected-bonusnumbers' } else if (which === 3) { classname += 'selected-refundnumbers' }
        let title: string = ''
        if (which === 0) { title = Assets.Number } else if (which === 1) { title = Assets.ExtraNumber } else if (which === 2) { title = Assets.BonusNumber } else if (which === 3) { title = Assets.RefundNumber }
        let numberhit: string = ''
        if (arrayResults) {
            for (const Item of arrayResults) {
                if (Item === item) {
                    classname = 'lottery-number selected-hitnumbers'
                    numberhit = ' ' + Assets.was_hit
                    break
                }
            }
        }
        if (!numberhit && extraResults) {
            for (const Item of extraResults) {
                if (Item === item) {
                    classname = 'lottery-number selected-extrahitnumbers'
                    title = Assets.ExtraNumber + ' ' + Assets.was_hit
                    numberhit = ''
                    break
                }
            }
            
        }

        num.push(<span title = {title + numberhit} style = {{textAlign: 'center', marginLeft: '0px', marginRight: '4px'}} className = {classname} key = {item.toString()}>{item}</span>)
    }
    return num
}

export function sortNumber(a: any, b: any) {
    return a - b;
}

export function abbreviateNumber(num: any, digits: number) {
    const units = ['k', 'm', 'g', 't', 'p', 'e', 'z', 'y']
    let decimal: number

    for(let i=units.length-1; i>=0; i--) {
        decimal = Math.pow(1000, i+1);

        if(num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
}

export function injectSpaces(source: string): string {
    const Type: string[] = source ? source.match(/[A-Z][a-z]+/g) : undefined
    let text: string = ''
    if (Type) {
    for (let j: number = 0; j < Type.length; j++) {
        if (j > 0) { text += ' ' }
        text += Type[j]
    }
    }
    return text
}
/*
export function numberWithCommas(x: any) {
    if (x === undefined) return 0
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, '$1,$2');
    return x;
}
*/
export function numberWithCommas(x) {
    if (x === undefined) {x = 0}
    let separator: string = ','
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.format && Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator !== undefined) {
        if (Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator === ThousandsSeparator.Apostrophe) {
            separator = '\''
        } else if (Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator === ThousandsSeparator.Space) {
            separator = ' '
        }
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join('.');
}
/*
export function numberWithSpaces(x: any) {
    if (x === undefined) return 0
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, '$1 $2');
    return x;
}
*/
export function numberWithSpaces(x) {
    return numberWithCommas(x)
/*    
    let separator: string = ','
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.format && Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator !== undefined) {
        if (Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator === ThousandsSeparator.Apostrophe) {
            separator = '\''
        } else if (Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator === ThousandsSeparator.Space) {
            separator = ' '
        }    
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join('.');
*/
}


export function DateAddZeros(original: string): string {
    const hyphen1: number = original.indexOf('-')
    const hyphen2: number = original.indexOf('-', hyphen1 + 1)
    const len1: number = hyphen2 - hyphen1 
    const len2: number = original.length - hyphen2
    let result: string = original.substring(0,5)
    const part1: string = original.substring(hyphen1 + 1, hyphen2 + 1)
    const part2: string = original.substring(hyphen2 + 1, original.length)
/*
    console.log (len1)
    console.log (len2)
    console.log (result)
    console.log (part1)
    console.log (part2)
*/
    if (len1 === 2) { result += '0' }
    result += part1
    if (len2 === 2) { result += '0' }
    result += part2
    return result 
}

export function utilsTimeValidEmptyNotValid(time: string): boolean {
    if (time === undefined || time.length === 0) { return false }

    let first_hyphen = time.indexOf(':')
    if (first_hyphen === -1) { first_hyphen = time.indexOf('-') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('.') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('/') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('|') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('\\') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf(' ') }

    if (first_hyphen !== 2) { return false }

    let second_hyphen = time.indexOf(':', 3)
    if (second_hyphen === -1) { second_hyphen = time.indexOf('-', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('.', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('/', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('|', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('\\', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf(' ', 3) }

    const len = time.length
    if (len !== 5 && len !== 8) { return false }

    if (len === 8 && second_hyphen !== 5) { return false }
    
    let i: number = 0
    let j: number
    let which: number = 0

    while (i <= len) {
        if (i === first_hyphen || i === second_hyphen || i === len) {

            i++

            if (which === 0) {
                if (j > 23) { return false }
            } else if (which === 1) {
                if (j > 59) { return false }
            } else if (which === 2) {
                if (j > 59) { return false }
            }

            j = undefined
            which++
            continue 
        }

        if (time.charAt(i) < '0' || time.charAt(i) > '9')  {
            return false
        }

        if (!j) {
            j = parseInt(time.charAt(i))
        } else {
            j = j * 10 + parseInt(time.charAt(i))
        }

        i++
    }

    return true
}

export function utilsTimeValid(time:string): boolean {
    let first_hyphen = time.indexOf(':')
    if (first_hyphen === -1) { first_hyphen = time.indexOf('-') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('.') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('/') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('|') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf('\\') }
    if (first_hyphen === -1) { first_hyphen = time.indexOf(' ') }

    if (first_hyphen !== 2) { return false }

    let second_hyphen = time.indexOf(':', 3)
    if (second_hyphen === -1) { second_hyphen = time.indexOf('-', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('.', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('/', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('|', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf('\\', 3) }
    if (second_hyphen === -1) { second_hyphen = time.indexOf(' ', 3) }

    const len = time.length
    if (len !== 5 && len !== 8) { return false }

    if (len === 8 && second_hyphen !== 5) { return false }
    
    let i: number = 0
    let j: number
    let which: number = 0

    while (i <= len) {
        if (i === first_hyphen || i === second_hyphen || i === len) {
            i++

            if (which === 0) {
                if (j > 23) { return false }
            } else if (which === 1) {
                if (j > 59) { return false }
            } else if (which === 2) {
                if (j > 59) { return false }
            }

            j = undefined
            which++
            continue 
        }

        if (time.charAt(i) < '0' || time.charAt(i) > '9')  {
            return false
        }

        if (!j) {
            j = parseInt(time.charAt(i))
        } else {
            j = j * 10 + parseInt(time.charAt(i))
        }

        i++
    }

    return true
}

export function utilsDateValid(date:string) :boolean {

    let first_hyphen = date.indexOf('-')
    if (first_hyphen === -1) { first_hyphen = date.indexOf('.') }
    if (first_hyphen === -1) { first_hyphen = date.indexOf('/') }
    if (first_hyphen === -1) { first_hyphen = date.indexOf('|') }
    if (first_hyphen === -1) { first_hyphen = date.indexOf('\\') }
    if (first_hyphen === -1) { first_hyphen = date.indexOf(' ') }
    if (first_hyphen === -1) {/*alert('wrong format date:'+date);*/ return false}

    let second_hyphen = date.indexOf('-', 5)
    if (second_hyphen === -1) { second_hyphen = date.indexOf('.', 5) }
    if (second_hyphen === -1) { second_hyphen = date.indexOf('/', 5) }
    if (second_hyphen === -1) { second_hyphen = date.indexOf('|', 5) }
    if (second_hyphen === -1) { second_hyphen = date.indexOf('\\', 5) }
    if (second_hyphen === -1) { second_hyphen = date.indexOf(' ', 5) }
    if (second_hyphen === -1)  {/*alert('wrong format date:'+date);*/ return false}

    const len = date.length

    if (len == second_hyphen)  {/*alert('wrong format date:'+date);*/ return false}

    let i:number = 0

    let j:number = 0
    let which:number = 0;
    let month:number = 0;
    let day:number;
    let year:number = 0;

    while (i < len) {
        if (i === first_hyphen || i === second_hyphen) {

            i++

            if (j === 0) {
                return false
            } 

            if (which === 0) {

            year = j
            } else if (which === 1) {
                if (j > 12) { return false }
                month = j;
            }

            j = 0;
            which++;
            continue 
        }

        if (date.charAt(i) < '0' || date.charAt(i) > '9')  {/*alert('wrong format date:'+date);*/ return false}
        j = j * 10 + parseInt(date.charAt(i))
        i++
    }

    if (j === 0) { return false}
    if (month === 0) { return false; }
    day = j;

    if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
        if (day > 31) { return false }
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        if (day > 30) { return false }
    } else if (month === 2 && year % 4 === 0) {
        if (day > 29) { return false }
    } else if (month === 2 && year % 4 !== 0) {
        if (day > 28) { return false }
    }

    return true;
}

export function utilsGetDateFromString(date:string):Date {

    let second_hyphen = date.indexOf('-', 5)
    if (second_hyphen == -1) { second_hyphen = date.indexOf('.', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('/', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('|', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('\\', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf(' ', 5) }

    const yyyy:string = date.slice(0, 4);
    const mm:string = date.slice(5, second_hyphen)
    const dd:string = date.slice(second_hyphen + 1, date.length)

    return new Date(parseInt(yyyy), parseInt(mm)-1, parseInt(dd), 0, 0, 0, 0);
}

export function utilsGetStringFromDate(date:Date):string {

    const d:number = date.getDate();
    const m:number = date.getMonth()+1 // January is 0!
    const y:number = date.getFullYear()

    let dd = d.toString();
    let mm = m.toString();
    const yyyy = y.toString();
    
    if (mm.length === 1) { mm = '0' + mm }
    if (dd.length === 1) { dd = '0' + dd }

    return yyyy + '-' + mm + '-' + dd;
}

export function utilsGetStringFromDateString(date:string):string {

    let second_hyphen = date.indexOf('-', 5)
    if (second_hyphen == -1) { second_hyphen = date.indexOf('.', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('/', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('|', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf('\\', 5) }
    if (second_hyphen == -1) { second_hyphen = date.indexOf(' ', 5) }

    const yyyy:string = date.slice(0, 4);
    let mm:string = date.slice(5, second_hyphen)
    let dd:string = date.slice(second_hyphen + 1, date.length)

    if (mm.length === 1) { mm = '0' + mm }
    if (dd.length === 1) { dd = '0' + dd }

    return yyyy + '-' + mm + '-' + dd;

}

export function utilsGetOneDayBefore(date:string): string {
    let date1 = new Date()
    date1 = utilsGetDateFromString(date)
    date1.setDate(date1.getDate() - 1);
    return utilsGetStringFromDate(date1)
}

export function utilsGetOneDayAfter(date: string): string {
    let date1 = new Date()
    date1 = utilsGetDateFromString(date)
    date1.setDate(date1.getDate() + 1)
    return utilsGetStringFromDate(date1)
}

export function utilsToday(): string { 
    return utilsGetStringFromDate(new Date())
}

export function utilsYesterday(): string {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return utilsGetStringFromDate(yesterday)
}

export function utilsLast7DaysStart(): string {
    const date = new Date()
    date.setDate(date.getDate() - 6)
    return utilsGetStringFromDate(date)
}

export function utilsLastWeekStart(): string {
     const date = new Date()
     const date1InWeek = date.getDay()
     date.setDate(date.getDate() - date1InWeek - 6)
     return utilsGetStringFromDate(date)
}

export function utilsLastWeekEnd(): string {
     const date = new Date()
     const date1InWeek = date.getDay()
     date.setDate(date.getDate() - date1InWeek)
     return utilsGetStringFromDate(date)
}

export function utilsThisWeekStart(): string {
     const date = new Date()
     const date1InWeek = date.getDay();
     date.setDate(date.getDate() - date1InWeek + 1)
     return utilsGetStringFromDate(date)
}

export function utilsThisWeekEnd(): string {
     const date = new Date()
     const date1InWeek = date.getDay();
     date.setDate(date.getDate() - date1InWeek + 7);
     return utilsGetStringFromDate(date)
}

export function utilsThisMonthStart(): string {
    const date = new Date();
    date.setDate(1);
    return utilsGetStringFromDate(date)
}

export function utilsThisMonthEnd(): string {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)
    return utilsGetStringFromDate(date)
}

export function utilsLastMonthStart(): string {
    const date = new Date()
    date.setDate(0)
    date.setDate(1)
    return utilsGetStringFromDate(date)
}

export function utilsLastMonthEnd(): string {
    const date = new Date()
    date.setDate(0)
    return utilsGetStringFromDate(date)
}

export function utilsQStart(which_quarter:number): string {
    const date = new Date();
    if (which_quarter === 0) {
        const month = date.getMonth()
        if (month === 0 || month === 1 || month === 2) { which_quarter = 1 } else if (month === 3 || month === 4 || month === 5) { which_quarter = 2 } else if (month === 6 || month === 7 || month === 8) { which_quarter = 3 } else if (month === 9 || month === 10 || month === 11) { which_quarter = 4 }
    } 
 
    date.setMonth((which_quarter - 1) * 3)

    date.setDate(1)
    return utilsGetStringFromDate(date)
}

export function utilsQEnd(which_quarter:number): string {
    const date = new Date()
    if (which_quarter === 0) {
        const month = date.getMonth()
        if (month === 0 || month === 1 || month === 2) {
            which_quarter = 1
        } else if (month === 3 || month === 4 || month === 5) {
            which_quarter = 2
        } else if (month === 6 || month === 7 || month === 8) {
            which_quarter = 3
        } else if (month === 9 || month === 10 || month === 11) {
            which_quarter = 4
        }
    }

    date.setMonth(which_quarter * 3)

    date.setDate(1)
    date.setDate(0)
    return utilsGetStringFromDate(date)
}

export function utilsThisYearStart(): string {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    return utilsGetStringFromDate(date)
}

export function utilsThisYearEnd(): string {
    const date = new Date()
    date.setMonth(11)
    date.setDate(31)
    return utilsGetStringFromDate(date)    
}

export function utilsLastYearStart(): string {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    date.setDate(0)
    date.setMonth(0)
    date.setDate(1)
    return utilsGetStringFromDate(date)
}

export function utilsLastYearEnd(): string {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    date.setDate(0)
    return utilsGetStringFromDate(date)
}

export function capitalizeFirstLetter(a:string) {
    return a.charAt(0).toUpperCase() + a.slice(1)
}

export function toObject(data:string[]):any {
    return data.reduce((acc:any, cur:any, i:any) => {acc[i] = cur; return acc}, {})
}

export function isUserGamanzaAdminFromLogin(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.gamanzaAdmin : false
}

export function isUserGamanzaMagicHandFromLogin(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.gamanzaMagicHand : false
}


export function isUserGamanzaAdmin(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.unused1 : false
}

export function isUserGamanzaGamesAdmin(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.unused2 : false
}

export function isUserGamanzaMagicHand(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.unused7 : false
}

export function isUserGDPR(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.gdpr : false
}

export function isUserAccounting(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.accounting : false
}

export function isUserReports(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.unused3 : false
}

export function isUserAdmin(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.administrator : false
}

export function isUserOperatorRole(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.operator : false
}

export function isUserSupport() : boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.support : false
}

export function isUserRFSpecialist(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.rfSpecialist : false
}

export function isUserRGSpecialist(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.rgSpecialist : false
}

export function isUserMarketing(): boolean {
    return Main.Instance.LoginState ? Main.Instance.LoginState.marketing : false
}
/*
export function isUserGamanzaAdmin(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.unused1
    } else { return false }
}

export function isUserGDPR(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.gdpr
    } else { return false }
}

export function isUserAccounting(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.accounting
    } else { return false }
}

export function isUserAdmin(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.administrator
    } else { return false }
}

export function isUserOperatorRole(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.operator
    } else { return false }
}

export function isUserSupport() : boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.support
    } else { return false }
}

export function isUserRFSpecialist(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.rfSpecialist
    } else { return false}
}

export function isUserRGSpecialist(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.rgSpecialist
    } else { return false }
}

export function isUserMarketing(): boolean {
    if (Main.Instance.LoginState && Main.Instance.LoginState.custom && Main.Instance.LoginState.custom.securityRights) {
        return Main.Instance.LoginState.custom.securityRights.marketing
    } else { return false }
}
*/

/*
export function isUserAdmin() {
    if (parseInt(Main.Instance.LoginState.role) % 10 === 1) return true
    return false
}

export function isUserRiskManager() {
    if (parseInt(Main.Instance.LoginState.role) % 10 === 4) return true
    return false
}

export function isUserView() {
    if (parseInt(Main.Instance.LoginState.role) % 10 === 2 || parseInt(Main.Instance.LoginState.role) % 10 === 3) return true
}
*/
export function isUserMaster() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 130 && parseInt(Main.Instance.LoginState.role) < 140) {return true }
    return false
}

export function isUserSystem() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 10 && parseInt(Main.Instance.LoginState.role) < 20) { return true }
    return false
}

export function isUserGameProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role)> 20 && parseInt(Main.Instance.LoginState.role) < 30) { return true }
    return false
}

export function isUserOperator() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 30 && parseInt(Main.Instance.LoginState.role) < 40) { return true }
    return false
}

export function isUserGamingSite() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 40 && parseInt(Main.Instance.LoginState.role) < 50) { return true }
    return false
}

export function isUserGameAssetProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 50 && parseInt(Main.Instance.LoginState.role) < 60) { return true }
    return false
}

export function isUserComplianceProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 60 && parseInt(Main.Instance.LoginState.role) < 70) { return true }
    return false
}

export function isUserComplianceSubProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 70 && parseInt(Main.Instance.LoginState.role) < 80) { return true }
    return false
}

export function isUserPaymentProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 80 && parseInt(Main.Instance.LoginState.role) < 90) { return true }
    return false
}

export function isUserPaymentSubProvider() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 90 && parseInt(Main.Instance.LoginState.role) < 96) { return true }
    return false
}
/*
export function isUserAffiliateSite() {
    if (parseInt(Main.Instance.LoginState.role) > 100 && parseInt(Main.Instance.LoginState.role) < 110) return true
    return false
}
*/

export function isUserSiteOwner() {
    if (Main.Instance.LoginState && Main.Instance.LoginState.role && parseInt(Main.Instance.LoginState.role) > 110 && parseInt(Main.Instance.LoginState.role) < 120) { return true }
    return false
}
/*
export function isUserSite() {
    if (parseInt(Main.Instance.LoginState.role) > 120 && parseInt(Main.Instance.LoginState.role) < 130) return true
    return false
}
*/
export function isUserContractualPartner() {
    if (isUserOperator() || 
        isUserGameProvider() ||
        isUserComplianceProvider() ||
        isUserPaymentProvider() ||
        isUserSiteOwner()) {
        return true
    }

    return false
}

export function isUserSubEntity() {
    if (isUserGamingSite() ||
        isUserGameAssetProvider() ||
        isUserComplianceSubProvider() ||
        isUserPaymentSubProvider() /*||
        isUserSite()*/) {
        return true
    }
    return false
}

export function isNormalInteger(str:string) {
    while (str.charAt(0) === '0' && str.length > 1) {str = str.slice(1,str.length); }
    const n:number = Math.floor(Number(str));
    return String(n) === str && n >= 0;
}

export function json2array(json:any) :any[] {
    const result: any[] = []
    const keys = Object.keys(json)
    keys.forEach((key) => {
        result.push(json[key])
    })
    return result
}

export function isContractualPartnerOperator(id:string) {
    // console.log ('id:' + id)
    // console.log ('length:' + Main.Instance.DatabaseDataState.contractualPartnersAllStream.length)
    // console.log ('length2:' + Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length)

    for (const item of Main.Instance.DatabaseDataState.contractualPartnersAllStream) {
        // console.log ('i, id:' + i + ', ' + Main.Instance.DatabaseDataState.contractualPartnersAllStream['id'])
        // console.log (JSON.stringify(Main.Instance.DatabaseDataState.contractualPartnersAllStream[i]))

        if (parseInt(item['id']) === parseInt(id)) {
            // console.log ('found operator')
            return true
        }
    }

    for (const item of Main.Instance.DatabaseDataState.contractualPartnersAllStream2) {
        // console.log ('i, id:' + i + ', ' + Main.Instance.DatabaseDataState.contractualPartnersAllStream2['id'])
        // console.log (JSON.stringify(Main.Instance.DatabaseDataState.contractualPartnersAllStream2[i]))

        if (parseInt(item['id']) === parseInt(id)) {
            // console.log ('found site owner')
            return false
        }
    }

    // console.log ('found nothing')
    // console.log ('this should not happen')
    return false
}

export function GetCategoryName(id:string): string {
    for (let i:number = 0; Main.Instance.DatabaseDataState.pointTypeStreamAll && 
        i < Main.Instance.DatabaseDataState.pointTypeStreamAll.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.pointTypeStreamAll[i]['id']) === parseInt(id)) {
            return Main.Instance.DatabaseDataState.pointTypeStreamAll[i]['name']
        }
    }
    return ''
}
// {TABLE_GAME = 1, DEPOSIT, ODDS_TYPE, SLOTS, LOGIN, REGISTRATION, WITHDRAWAL}
export function GetTypeAttribute(id:string, attr:number, all:boolean): boolean {
    if (attr === 0) { return false }
    let attribute:string

    if (attr === Assets_.ATTR.TABLE_GAME) {
        attribute = 'attr_table_game'
    } else if (attr === Assets_.ATTR.DEPOSIT) {
        attribute = 'attr_deposit_option'
    } else if (attr === Assets_.ATTR.ODDS_TYPE) {
        attribute = 'attr_odds_type'
    } else if (attr === Assets_.ATTR.SLOTS) {
        attribute = 'attr_slots'
    } else if (attr === Assets_.ATTR.LOGIN) {
        attribute = 'attr_login_option'
    } else if (attr === Assets_.ATTR.REGISTRATION) {
        attribute = 'attr_registration_option'
    } else if (attr === Assets_.ATTR.WITHDRAWAL) {
        attribute = 'attr_withdrawal_option'
    }
    
    if (attribute === undefined) { return false }

    if (all) { attribute += '_all' }

    // console.log ('attribute: ' + attribute)
    for (let i:number = 0; Main.Instance.DatabaseDataState.subpointTypeStreamAll && 
        i < Main.Instance.DatabaseDataState.subpointTypeStreamAll.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.subpointTypeStreamAll[i]['id']) === parseInt(id)) {
            if (Main.Instance.DatabaseDataState.subpointTypeStreamAll[i][attribute].toString() === 'true') {
                return true
            } else { return false }
        }
    }

    return false
}


// basically we should not care for the name but for the attribute
export function GetTypeName(id:string): string {

    for (let i:number = 0; Main.Instance.DatabaseDataState.subpointTypeStreamAll && 
        i < Main.Instance.DatabaseDataState.subpointTypeStreamAll.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.subpointTypeStreamAll[i]['id']) === parseInt(id)) {
            return Main.Instance.DatabaseDataState.subpointTypeStreamAll[i]['title'] // name
        }
    }
    return ''
}

export function FormatSQLArray(str:string): string {
    const s:string = str.substr(1, str.length - 2)
    return '{' + s + '}'
    // return '\'{' + s + '}\''
}

function getGames(excluded:number[], games_list: any[]): string[] {
    const games:string[] = []
    for (const Excluded of excluded) {
        let found:number = -1
        for (let i:number = 0; found === -1 && i < games_list.length; i++) {
            if (games_list[i]['id'] === Excluded) { found = i }
        }
        if (found > -1 && games_list[found]['name']) { games.push (games_list[found]['name']) } else if (found > -1 && games_list[found][Assets.Name]) { games.push (games_list[found][Assets.Name]) }
    }
    games.sort()
    return games
}

export function GetGames(excluded:number[], games_list:any[]): string {
    const games: string[] = getGames (excluded, games_list)
    let s: string = '{'
    for (const game of games) {
        if (s.length > 1) { s += ', ' }
        s += '"' + game + '"'
    }
    s += '}'
    return s
}

export function GetGamesList(excluded: number[], games_list: any[], Excluded: boolean): string {
    if (excluded.length === 0) { return Assets.None }
    if (excluded.length === games_list.length) {
        if (Excluded === true) { return Assets.AllGamesAreExcluded } else { return Assets.AllGamesAreIncluded }
    }
    const games:string[] = getGames (excluded, games_list)
    let s: string = ''
    for (const game of games) {
        if (s.length > 0) { s += ', ' }
        s += game
    }

    return s
}
    
export function GetFirstNChars(n:number, source:string): string {
    return source.slice(0,n) + '...'
}

// this function is just temporary, production will look different
export function GetPlayerList(input:any[], id:string): string {
    for (const playerList of input) {
        if (playerList['id'] === parseInt(id)) {
            
            const playersnames:string[] = playerList['names'].sort()

            let s: string = '{'
            s += '"' + playerList['name'] + '"'
        
            for (const playersname of playersnames) {
                // if (s.length > 1) 
                s += ', '
                s += '"' + playersname + '"'
            }
            
            s += '}'
            return s            
        } 
    }
    return '{}'
}

/*
function formatDate(date): string {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
*/

export interface Select {
    id: string
    name: string
}

export interface SelectValue {
    id: string
    name: string
    value: string
}

export interface SelectValue2 {
    id: string
    name: string
    value1: string
    value2: string
    time1: boolean
    time2: boolean
}

export interface SelectCurrency2 {
    id: string
    name: string    
    main: boolean
}

export interface SelectCurrency {
    id: string
    code: string
    active: boolean
    main: boolean
}

// Inside build, when you want to check for certain customer
/*
function isEntityBetToday(): boolean {
    return Main.Instance.LoginState.entity.toLowerCase() === 'bettoday' || Main.Instance.LoginState.entity.toLowerCase() === 'bet today'
}

function isEntityScandibet(): boolean {
    return Main.Instance.LoginState.entity.toLowerCase() === 'scandibet' || Main.Instance.LoginState.entity.toLowerCase() === 'frontend' || Main.Instance.LoginState.entity.toLowerCase() === 'front end'
}

function isEntityJackpotsch(): boolean {
    // console.log (Main.Instance.LoginState.entity.toLowerCase() === 'jackpotsch')
    return Main.Instance.LoginState.entity.toLowerCase() === 'jackpotsch'
}
*/
function isEntityPureScandibet(): boolean {
    return Main.Instance.LoginState.entity && Main.Instance.LoginState.entity.toLowerCase() === 'scandibet'
}

function isEntityPureOddyzzey(): boolean {
    return Main.Instance.LoginState.entity && Main.Instance.LoginState.entity.toLowerCase() === 'oddyzzey'
}
/*
function isEntityOddyzzey(): boolean {
    return (
        Main.Instance.LoginState.entity.toLowerCase() === 'oddyzzey'
        || Main.Instance.LoginState.entity.toLowerCase() === 'backend'
        || Main.Instance.LoginState.entity.toLowerCase() === 'back end'
        || Main.Instance.LoginState.entity.toLowerCase() === 'jackpots.ch' 
        || Main.Instance.LoginState.entity.toLowerCase() === 'stanleybet'
    )
}

function isEntityMyChance(): boolean {
    return Main.Instance.LoginState.entity.toLowerCase() === 'my chance'
}

function isEntityTreKronor(): boolean {
    return Main.Instance.LoginState.entity.toLowerCase() === 'tre kronor' || Main.Instance.LoginState.entity.toLowerCase() === 'trekronor'
}

export function isDefault(): boolean {
    return IsBuildDefault()
}

function isBetToday(): boolean {
    return isEntityBetToday() && IsBuildBettoday()
}

function IsBuildScandibet()(): boolean {
    return isEntityScandibet() && IsBuildScandibet()
}

function IsBuildOddyzzey()(): boolean {
    return isEntityOddyzzey() && IsBuildOddyzzey()
}

function isMyChance(): boolean {
    return isEntityMyChance() && IsBuildMyChance()
}

function isJackpotsCH(): boolean {
    return isEntityJackpotsch() && IsBuildJackpotsCH() || IsBuildPasino()
}

function isTreKronor(): boolean {
    return isEntityTreKronor() && IsBuildTrekronor()
}
*/
export function isPureOddyzzey(): boolean {
    return isEntityPureOddyzzey() && IsBuildOddyzzey()
}

export function isPureScandibet(): boolean {
    return isEntityPureScandibet() && IsBuildScandibet()
}

export function IsBuildDefault(): boolean {
    return Config.Build === Config.BUILD.DEFAULT
    // return getClient() === Config.builds[Config.BUILD.DEFAULT]
}

export function IsBuildScandibet(): boolean {
    return Config.Build === Config.BUILD.SCANDIBET
/*
    return getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.SCANDIBET] || 
            getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.FRONTEND]
*/
}

export function IsBuildPasino(): boolean {
    return Config.Build === Config.BUILD.PASINO
}

export function IsBuildOddyzzey(): boolean {
    return Config.Build === Config.BUILD.ODDYZZEY
/*
    return (
        getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.ODDYZZEY] ||
        getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.BACKEND] ||
        getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.STANLEYBET] ||
        getClient() === Config.stage_ + Config.builds[Config.BUILD.ODDYZZEY] ||
        getClient() === Config.stage_ + Config.builds[Config.BUILD.BACKEND] ||
        getClient() === Config.stage + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.dev + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.dev_edge + Config.builds[Config.BUILD.JACKPOTSCH]
    )
*/
}

export function IsBuildMyChance(): boolean {
    return Config.Build === Config.BUILD.MYCHANCE
/*
    return (getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.MYCHANCE] ||
            getClient() === Config.stage + Config.builds[Config.BUILD.MYCHANCE])
*/
}

export function IsBuildTrekronor(): boolean {
    return Config.Build === Config.BUILD.TREKRONOR
/*
    return getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.TREKRONOR]
*/
}

export function IsBuildBettoday(): boolean {
    return Config.Build === Config.BUILD.BETTODAY
    // return getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.BETTODAY]
}

export function IsBuildFrontend(): boolean {
    return Config.Build === Config.BUILD.FRONTEND
    // return getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.FRONTEND]
}

export function IsBuildBackend(): boolean {
    return Config.Build === Config.BUILD.BACKEND
/*
    return getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.BACKEND] ||
           getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.JACKPOTSCH] ||
           getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.STANLEYBET] ||
           getClient() === Config.stage_ + Config.builds[Config.BUILD.BACKEND] ||
           getClient() === Config.stage + Config.builds[Config.BUILD.JACKPOTSCH] ||
           getClient() === Config.dev + Config.builds[Config.BUILD.JACKPOTSCH] ||
           getClient() === Config.dev_edge + Config.builds[Config.BUILD.JACKPOTSCH]
*/
}

export function IsBuildStanleyBet(): boolean {
    return Config.Build === Config.BUILD.STANLEYBET
}

export function IsBuildOdds(): boolean {
    return Config.Build === Config.BUILD.ODDS
}

export function IsBuildJackpotsCH(): boolean {
    return Config.Build === Config.BUILD.JACKPOTSCH
/*
    return (
        getClient() === Config.builds[Config.BUILD.DEFAULT] + '-' + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.stage + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.dev + Config.builds[Config.BUILD.JACKPOTSCH] ||
        getClient() === Config.dev_edge + Config.builds[Config.BUILD.JACKPOTSCH]
    )
*/
}

export function AllowDecimalCharactersOnly(text: string): boolean {
    let dots: number = 0
    for (let i: number = 0; i < text.length; i++) {
        if (text.charAt(i) !== '.' 
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
        ) { return false }

        if (text.charAt(i) === '.') {
            dots++
            if (dots > 1) { return false }
        }
    }
    return true
}

export function AllowTimeCharactersOnly(text: string, long: boolean): boolean {
    let dots: number = 0
    if (long && text.length > 8) { return false }
    if (!long && text.length > 5) { return false }

    for (let i: number = 0; i < text.length; i++) {
        if (text.charAt(i) !== ':' 
            && text.charAt(i) !== '0'
            && text.charAt(i) !== '1'
            && text.charAt(i) !== '2'
            && text.charAt(i) !== '3'
            && text.charAt(i) !== '4'
            && text.charAt(i) !== '5'
            && text.charAt(i) !== '6'
            && text.charAt(i) !== '7'
            && text.charAt(i) !== '8'
            && text.charAt(i) !== '9'
        ) { return false }

        if (text.charAt(i) === ':') {
            dots++
            if (!long && dots > 1) { return false }
            if (dots > 2) { return false }
        }
    }
    return true
}

export function IsLocalhost(): boolean {
    if (window.location.host.startsWith('localhost')) {
        return true
    } else {
        return false
    }

}

export function isLocalhost(): boolean {
    return false
}

/*
function getClient(): string {
    const host = window.location.host
    if (host.startsWith('localhost')) { return (Config.Build === 0 ? Config.builds[Config.Build] : Config.builds[0] + '-' + Config.builds[Config.Build]) }
    // console.log ('getClient() ' + host.substring(0, host.indexOf('.')))
    const client: string = host.substring(0, host.indexOf('.'))
    // console.log (client)
    return client
}
*/
/*
export function getServer(SERVER: string): string {
    let host = window.location.host
    // x.gamanza.com
    if (host.startsWith('localhost')) return SERVER
    return host.substring(host.indexOf('.') + 1, host.length)
}
*/
// scales the canvas by (float) scale < 1
// returns a new canvas containing the scaled image.
export function downScaleCanvas(cv, scale): HTMLCanvasElement {
    if (!(scale < 1) || !(scale > 0)) { alert (Assets.sc3); }
    const sqScale = scale * scale; // square scale = area of source pixel within target
    const sw = cv.width; // source image width
    const sh = cv.height; // source image height
    const tw = Math.ceil(sw * scale); // target image width
    const th = Math.ceil(sh * scale); // target image height
    let sx = 0
    let sy = 0
    let sIndex = 0; // source x,y, index within source array
    let tx = 0
    let ty = 0
    let yIndex = 0
    let tIndex = 0; // target x,y, x,y index within target array
    let tX = 0
    let tY = 0; // rounded tx, ty
    let w = 0
    let nw = 0
    let wx = 0
    let nwx = 0
    let wy = 0
    let nwy = 0; // weight / next weight x / y
    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    let crossX = false; // does scaled px cross its current px right border ?
    let crossY = false; // does scaled px cross its current px bottom border ?
    const sBuffer = cv.getContext('2d').
    getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
    const tBuffer = new Float32Array(4 * sw * sh); // target buffer Float32 rgb
    let sR = 0
    let sG = 0
    let sB = 0; // source's current point r,g,b
    // untested !
    let sA = 0;  // source alpha    

    for (sy = 0; sy < sh; sy++) {
        ty = sy * scale; // y src position within target
        tY = 0 | ty;     // rounded : target pixel's y
        yIndex = 4 * tY * tw;  // line index within target array
        crossY = (tY != (0 | ty + scale)); 
        if (crossY) { // if pixel is crossing botton target pixel
            wy = (tY + 1 - ty); // weight of point within target pixel
            nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
        }
        for (sx = 0; sx < sw; sx++, sIndex += 4) {
            tx = sx * scale; // x src position within target
            tX = 0 |  tx;    // rounded : target pixel's x
            tIndex = yIndex + tX * 4; // target pixel index within target array
            crossX = (tX != (0 | tx + scale));
            if (crossX) { // if pixel is crossing target pixel's right
                wx = (tX + 1 - tx); // weight of point within target pixel
                nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
            }
            sR = sBuffer[sIndex    ];   // retrieving r,g,b for curr src px.
            sG = sBuffer[sIndex + 1];
            sB = sBuffer[sIndex + 2];
            sA = sBuffer[sIndex + 3];
            
            if (!crossX && !crossY) { // pixel does not cross
                // just add components weighted by squared scale.
                tBuffer[tIndex    ] += sR * sqScale;
                tBuffer[tIndex + 1] += sG * sqScale;
                tBuffer[tIndex + 2] += sB * sqScale;
                tBuffer[tIndex + 3] += sA * sqScale;
            } else if (crossX && !crossY) { // cross on X only
                w = wx * scale;
                // add weighted component for current px
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                tBuffer[tIndex + 3] += sA * w;
                // add weighted component for next (tX+1) px                
                nw = nwx * scale
                tBuffer[tIndex + 4] += sR * nw; // not 3
                tBuffer[tIndex + 5] += sG * nw; // not 4
                tBuffer[tIndex + 6] += sB * nw; // not 5
                tBuffer[tIndex + 7] += sA * nw; // not 6
            } else if (crossY && !crossX) { // cross on Y only
                w = wy * scale;
                // add weighted component for current px
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                tBuffer[tIndex + 3] += sA * w;
                // add weighted component for next (tY+1) px                
                nw = nwy * scale
                tBuffer[tIndex + 4 * tw    ] += sR * nw; // *4, not 3
                tBuffer[tIndex + 4 * tw + 1] += sG * nw; // *4, not 3
                tBuffer[tIndex + 4 * tw + 2] += sB * nw; // *4, not 3
                tBuffer[tIndex + 4 * tw + 3] += sA * nw; // *4, not 3
            } else { // crosses both x and y : four target points involved
                // add weighted component for current px
                w = wx * wy;
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                tBuffer[tIndex + 3] += sA * w;
                // for tX + 1; tY px
                nw = nwx * wy;
                tBuffer[tIndex + 4] += sR * nw; // same for x
                tBuffer[tIndex + 5] += sG * nw;
                tBuffer[tIndex + 6] += sB * nw;
                tBuffer[tIndex + 7] += sA * nw;
                // for tX ; tY + 1 px
                nw = wx * nwy;
                tBuffer[tIndex + 4 * tw    ] += sR * nw; // same for mul
                tBuffer[tIndex + 4 * tw + 1] += sG * nw;
                tBuffer[tIndex + 4 * tw + 2] += sB * nw;
                tBuffer[tIndex + 4 * tw + 3] += sA * nw;
                // for tX + 1 ; tY +1 px
                nw = nwx * nwy;
                tBuffer[tIndex + 4 * tw + 4] += sR * nw; // same for both x and y
                tBuffer[tIndex + 4 * tw + 5] += sG * nw;
                tBuffer[tIndex + 4 * tw + 6] += sB * nw;
                tBuffer[tIndex + 4 * tw + 7] += sA * nw;
            }
        } // end for sx 
    } // end for sy

    // create result canvas
    const resCV:HTMLCanvasElement = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;
    const resCtx = resCV.getContext('2d');
    const imgRes = resCtx.getImageData(0, 0, tw, th);
    const tByteBuffer = imgRes.data;
    // convert float32 array into a UInt8Clamped Array
    let pxIndex = 0; //  
    for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 4, tIndex += 4, pxIndex++) {
        tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
        tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
        tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
        tByteBuffer[tIndex + 3] = Math.ceil(tBuffer[sIndex + 3]);
    }
    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);
    return resCV;
}

export function getDiscountedValue(price: string, discount: string): string {
    if (price === undefined) { return '' }
    if (discount === undefined) { return price }
    if (parseFloat(discount) === 0) { return price }
    // console.log (price, discount)
    return (parseFloat(Main.Instance.RewardShopState.price) * (1 - parseFloat(Main.Instance.RewardShopState.discount)/100)).toFixed(0).toString()
}

export function getRSIBody(): JSX.Element[] {
    const tr: JSX.Element[] = []
 
    const tdn: JSX.Element[] = []
    tdn.push(<td><div style = {{marginTop: '5px', fontWeight: 700}}>{Assets.Title}</div></td>)
 
    const tdc: JSX.Element[] = []
    tdc.push(<td><div style = {{marginTop: '40px', fontWeight: 700}}>{Assets.TC}</div></td>)
 
    const tdi: JSX.Element[] = []
    tdi.push(<td><div style = {{marginTop: '40px', fontWeight: 700}}>{Assets.Info}</div></td>)

    const tdp: JSX.Element[] = []
    tdp.push(<td><div style = {{marginTop: '5px', fontWeight: 700}}>{Assets.Prices}</div></td>)

    const tdd: JSX.Element[] = []
    tdd.push(<td><div style = {{marginTop: '5px', fontWeight: 700}}>{Assets.Discounts}</div></td>)
 
    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.languagesStreamAll.length; i++) {
        if (Main.Instance.DatabaseDataState.languagesStreamAll[i]['active'] === false) { continue }
        const background: string = i === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        tdn.push (
            <td key = {i.toString()} style = {{background}}>
                <input
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.name[i]}
                    type = "text"
                    onChange = {(e) => EditName(e, i)}
                />
            </td>,
        )

        tdc.push (
            <td key = {i.toString()} style = {{background}}>
                <textarea
                    style = {{height: '100px', paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.info[i]}
                    onChange = {(e) => EditTC(e, i)}
                />
            </td>,
        )

        tdi.push (
            <td key = {i.toString()} style = {{background}}>
                <textarea
                    style = {{height: '100px', paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.choose_category[i]}
                    onChange = {(e) => EditInfo(e, i)}
                />
            </td>,
        )
       
        tdp.push (
            <td key = {i.toString()} style = {{background, position: 'relative'}}>
                <input
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                    value = {Main.Instance.RewardShopState.prices[i]}
                    type = "text"
                    onChange = {(e) => EditPrice(e, i)}
                />
            </td>,
        )

        tdd.push (
            <td key = {i.toString()} style = {{background, position: 'relative'}}>
                <input
                    style = {{paddingLeft: '5px', paddingRight: '5px', width: '90%'}}
                    value = {Main.Instance.RewardShopState.discounts[i]}
                    type = "text"
                    onChange = {(e) => EditDiscount(e, i)}
                />
                <span style = {{position: 'absolute', top: '10px', left: '92%', fontWeight: 700}}>%</span>
            </td>,
        )

    }
    }

    tr.push(<tr key = "tdn">{tdn}</tr>)
    tr.push(<tr key = "tdc">{tdc}</tr>)
    tr.push(<tr key = "tdi">{tdi}</tr>)
    tr.push(<tr key = "tdp">{tdp}</tr>)
    tr.push(<tr key = "tdd">{tdd}</tr>)
    return tr
}

export function getRSIHeader(): JSX.Element[] {
    const th: JSX.Element[] = []
    th.push(<th><div style = {{marginTop: '5px', fontWeight: 700}}>{Assets.Language}</div></th>)
    if (Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
    for (let J: number = 0; J < Main.Instance.DatabaseDataState.languagesStreamAll.length; J++) {
        if (Main.Instance.DatabaseDataState.languagesStreamAll[J]['active'] === false) { continue }
        const background: string = J === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                                    Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
                                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag']) - 1
        const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                                    !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
        th.push(
            <th
                style = {{background}}
                className = "th-center"
                title = {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['native']}
                key = {J.toString()}
            >
                <img src = {flagsource1} height = "20" width = "30"/>
                <span style = {{fontSize: '14px', marginLeft: '10px'}}>
                    {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                </span>
            </th>,
        )
    }
    }
    return th
}

function EditTC(event, which: number) {
    const info: string[] = Object.assign ([], Main.Instance.RewardShopState.info)
    info[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {info})
}

function EditInfo(event, which: number) {
    const choose_category: string[] = Object.assign ([], Main.Instance.RewardShopState.choose_category)
    choose_category[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {choose_category})
}

function EditPrice(event, which: number) {
    const prices: string[] = Object.assign ([], Main.Instance.RewardShopState.prices)
    prices[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {prices})
}

function EditDiscount(event, which: number) {
    const discounts: string[] = Object.assign ([], Main.Instance.RewardShopState.discounts)
    discounts[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {discounts})
}

function EditName(event, which: number) {
    const name: string[] = Object.assign ([], Main.Instance.RewardShopState.name)
    name[which] = event.target.value
    Logic.Type.New (Main.Instance.RewardShopState, {name})
}

function Edit(event, which: number) {
    if (Main.Instance.FrontendStoreState.categories) {
        const category: string[] = Object.assign ([], Main.Instance.FrontendStoreState.category)
        category[which] = event.target.value
        Logic.Type.New (Main.Instance.FrontendStoreState, {category})
    } else {
        const translation: string[] = Object.assign ([], Main.Instance.FrontendStoreState.translation)
        translation[which] = event.target.value
        Logic.Type.New (Main.Instance.FrontendStoreState, {translation})
    }
}

export function getTranslationBody(): JSX.Element[] {
    let activeLanguages: number = 0
    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (const item of Main.Instance.DatabaseDataState.languagesStreamAll) { if (item['active'] === true) { activeLanguages++
    } } }
    const percentage: string = activeLanguages === 0 ? '100%' : (100 / activeLanguages).toFixed(6) + '%'
    const tr: JSX.Element[] = []
    if (
        Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.languagesStreamAll.length > 0 && 
        Main.Instance.FrontendStoreState.translation && Main.Instance.FrontendStoreState.categories === false
    ||
        Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.languagesStreamAll.length > 0 && 
        Main.Instance.FrontendStoreState.category && Main.Instance.FrontendStoreState.categories === true

    ) {
        let td: JSX.Element[] = []
        let textArea: boolean = false
        let proceed: boolean

        let count: number = 0
        if (Main.Instance.FrontendStoreState.categories === false) {
        for (let i: number = 0; i < Main.Instance.FrontendStoreState.translation.length; i++) {
            const J: number = Math.floor (i / Main.Instance.DatabaseDataState.languagesStreamAll.length)
            if ((J < parseInt(Main.Instance.FrontendStoreState.offset) || J >= parseInt(Main.Instance.FrontendStoreState.offset) + Main.Instance.FrontendStoreState.pSize)
                && (Main.Instance.SearchState.search_string === undefined || Main.Instance.SearchState.search_string.length === 0)) { 
                continue
            }

            const j: number = i % Main.Instance.DatabaseDataState.languagesStreamAll.length
            if (Main.Instance.DatabaseDataState.languagesStreamAll[j]['active'] === false) {
                if (j === Main.Instance.DatabaseDataState.languagesStreamAll.length - 1) {
                    tr.push (<tr key = {J.toString()}>{td}</tr>)
                    td = []
                }
                continue
            }
            if (j === 0) { proceed = false }
            if (j === 0 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
                for (let m: number = i; m < i + Main.Instance.DatabaseDataState.languagesStreamAll.length; m++) {
                    if (Main.Instance.FrontendStoreState.translation[m].toLowerCase().indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) {
                        proceed = true
                        break
                    }
            }
                }

            if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && proceed === false) { continue }
            if (j === 0) { count++ }

            if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 &&
                (count <= parseInt(Main.Instance.FrontendStoreState.offset) || count > parseInt(Main.Instance.FrontendStoreState.offset) + Main.Instance.FrontendStoreState.pSize)) {
                continue
            }

            if (j === 0) {
                let characterSum: number = 0
                for (let m: number = i; m < i + Main.Instance.DatabaseDataState.languagesStreamAll.length; m++) {
                    characterSum += Main.Instance.FrontendStoreState.translation[m].length
                }
                characterSum /= Main.Instance.DatabaseDataState.languagesStreamAll.length
                textArea = characterSum >= 23 ? true : false
            }
            
            const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''

            td.push (
                <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                    {textArea ?
                    <textarea
                        readOnly = {!isUserAdmin()}
                        style = {{height: '100px', paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.translation[i]}
                        onChange = {(e) => Edit(e, i)}
                    />
                    :
                    <input
                        readOnly = {!isUserAdmin()}
                        style = {{paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
                        value = {Main.Instance.FrontendStoreState.translation[i]}
                        type = "text"
                        onChange = {(e) => Edit(e, i)}
                    />}
                </td>,
            )

            if (j === Main.Instance.DatabaseDataState.languagesStreamAll.length - 1) {
                tr.push (<tr key = {J.toString()}>{td}</tr>)
                td = []
            }
        }
        }

        if (Main.Instance.FrontendStoreState.categories === true) {
        for (let i: number = Main.Instance.DatabaseDataState.languagesStreamAll.length; i < Main.Instance.FrontendStoreState.category.length; i++) {
            const J: number = Math.floor (i / Main.Instance.DatabaseDataState.languagesStreamAll.length)
            if (
                (J < parseInt(Main.Instance.FrontendStoreState.offset) || J > parseInt(Main.Instance.FrontendStoreState.offset) + Main.Instance.FrontendStoreState.pSize)
                && (Main.Instance.SearchState.search_string === undefined || Main.Instance.SearchState.search_string.length === 0)
            ) {
                continue
            }
            
            if (J < parseInt(Main.Instance.FrontendStoreState.offset) || J > parseInt(Main.Instance.FrontendStoreState.offset) + Main.Instance.FrontendStoreState.pSize) {
                continue
            }
            const j: number = i % Main.Instance.DatabaseDataState.languagesStreamAll.length
            if (Main.Instance.DatabaseDataState.languagesStreamAll[j]['active'] === false) {
                if (j === Main.Instance.DatabaseDataState.languagesStreamAll.length - 1) {
                    tr.push (<tr key = {J.toString()}>{td}</tr>)
                    td = []
                }
                continue
            }
            if (j === 0) { proceed = false }
            if (j === 0 && Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0) {
                for (let m: number = i; proceed === false && m < i + Main.Instance.DatabaseDataState.languagesStreamAll.length; m++) {
                    if (Main.Instance.FrontendStoreState.category[m].toLowerCase()
                    .indexOf(Main.Instance.SearchState.search_string.toLowerCase()) !== -1) { proceed = true }
                }
            }

            if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && proceed === false) { continue }
            if (j === 0) { count++ }
            if (
                (count < parseInt(Main.Instance.FrontendStoreState.offset) || count > parseInt(Main.Instance.FrontendStoreState.offset) + Main.Instance.FrontendStoreState.pSize)
                && (Main.Instance.SearchState.search_string === undefined || Main.Instance.SearchState.search_string.length === 0)
            ) {
                continue
            }
            
            const background: string = j === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
            td.push (
                <td style = {{padding: '5px', textAlign: 'center', background, width: percentage}} key = {j.toString()}>
                    <input readOnly = {!isUserAdmin()} style = {{width: '100%'}} value = {Main.Instance.FrontendStoreState.category[i]} type = "text" onChange = {(e) => Edit(e, i)} />
                </td>,
            )

            if (j === Main.Instance.DatabaseDataState.languagesStreamAll.length - 1) {
                tr.push (<tr key = {J.toString()}>{td}</tr>)
                td = []
            }
        }
        }
        if (Main.Instance.SearchState.search_string !== undefined && Main.Instance.SearchState.search_string.length > 0 && count > 0 && count !== Main.Instance.FrontendStoreState.count) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {count})
        } else if (Main.Instance.SearchState.search_string === undefined || Main.Instance.SearchState.search_string.length === 0 && count > 0) {
            if (Main.Instance.FrontendStoreState.categories === true && 
                Main.Instance.FrontendStoreState.count !== Main.Instance.FrontendStoreState.category.length / Main.Instance.DatabaseDataState.languagesStreamAll.length - 1) {
                Logic.Type.New (Main.Instance.FrontendStoreState, {
                    offset: '0', pNum: 0, count: Main.Instance.FrontendStoreState.category.length / Main.Instance.DatabaseDataState.languagesStreamAll.length - 1})
            } else if ( Main.Instance.FrontendStoreState.categories === false && 
                Main.Instance.FrontendStoreState.count !== Main.Instance.FrontendStoreState.translation.length / Main.Instance.DatabaseDataState.languagesStreamAll.length) {
                Logic.Type.New (Main.Instance.FrontendStoreState, {
                    offset: '0', pNum: 0, count: Main.Instance.FrontendStoreState.translation.length / Main.Instance.DatabaseDataState.languagesStreamAll.length})
            }
        }
    }
    return tr
}
/*
export function getTranslationHeaderBetToday(addEmptyTD?: boolean): JSX.Element[] {
    let th: JSX.Element[] = []
    if (addEmptyTD) th.push(<th key = "-1"/>)

    if (Main.Instance.DatabaseDataState.languagesStreamAll)
    for (let J: number = 0; J < Main.Instance.DatabaseDataState.languagesStreamAll.length; J++) {
        if (Main.Instance.DatabaseDataState.languagesStreamAll[J]['active'] === false) continue
        let background: string = J === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                                    Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
                                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag']) - 1
        const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                                    !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
        th.push(
            <th
                style = {{background}}
                className = "th-center"
                title = {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['native']}
                key = {J.toString()}
            >
                <img src = {flagsource1} height = "20"/>
                <span style = {{fontSize: '14px', marginLeft: '10px'}}>
                    {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                </span>
            </th>
        )
    }

    return th
}
*/
export function getTranslationHeader(betToday: boolean, addEmptyTD?: boolean): JSX.Element[] {
    const th: JSX.Element[] = []
    if (addEmptyTD) { th.push(<th key = "-1"/>) }

    if (Main.Instance.DatabaseDataState.languagesStreamAll) {
    for (let J: number = 0; J < Main.Instance.DatabaseDataState.languagesStreamAll.length; J++) {
        if (Main.Instance.DatabaseDataState.languagesStreamAll[J]['active'] === false) { continue }
        const background: string = J === Main.Instance.DatabaseDataState.language ? '#c7dfe8' : ''
        const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                                    Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
                                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag']) - 1
        const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                                    !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
        th.push(
            <th
                style = {{background, position: 'relative'}}
                className = "th-center" // clearfix
                title = {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['native']}
                key = {J.toString()}
            >
                <img src = {flagsource1} height = "20" width = "30"/>
                <span style = {{fontSize: '14px', marginLeft: '10px'}}>
                    {Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                </span>
                {!betToday && (Main.Instance.FrontendStoreState.categories || J === 0) ? void 0 : 
                <button
                    title = {Assets.CopyTheTranslationsFromDefaultLanguageInto + ' ' + 
                            Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                    className = "btn btn-warning btn-xs btncopy"
                    onClick = {() => CopyFromEnglish(J)}
                >
                    {Assets.Copy}
                </button>}
                {!betToday && (Main.Instance.FrontendStoreState.categories || J === 0) ? void 0 :
                <button 
                    title = {Assets.DeleteTranslationsFor + ' ' + Main.Instance.DatabaseDataState.languagesAllStream[Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name']}
                    className = "btn btn-danger btn-xs btncopy2"
                    onClick = {() => Delete(J)}
                >
                    {Assets.Del}
                </button>}
            </th>,
        )
    }
    }
    // th.push(<th className = "th-center" key = {Main.Instance.DatabaseDataState.languagesStreamAll.length}/>)

    return th
}

function CopyFromEnglish(language: number) {
    // alert (Main.Instance.FrontendStoreState.translation.length + ' ' + language)
    const translation = Object.assign ([], Main.Instance.FrontendStoreState.translation)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.translation.length / Main.Instance.DatabaseDataState.languagesStreamAll.length; i++) {
        translation[i*Main.Instance.DatabaseDataState.languagesStreamAll.length + language] = 
        Main.Instance.FrontendStoreState.translation[i*Main.Instance.DatabaseDataState.languagesStreamAll.length]
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {translation})

}

function Delete(language: number) {
    const translation = Object.assign ([], Main.Instance.FrontendStoreState.translation)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.translation.length / Main.Instance.DatabaseDataState.languagesStreamAll.length; i++) {
        translation[i*Main.Instance.DatabaseDataState.languagesStreamAll.length + language] = ''
    }
    Logic.Type.New (Main.Instance.FrontendStoreState, {translation})
}

export function copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.setAttribute('style', 'position: absolute; left: -9999px;')
    // el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

// export function readCookie(name: string) {
//     // console.log (document.cookie)
//     const nameEQ = name + '='
//     const ca = document.cookie.split(';')
//     for(const item of ca) {
//         let c = item
//         while (c.charAt(0) == ' ') {c = c.substring(1, c.length)}
//         if (c.indexOf(nameEQ) == 0) {return c.substring(nameEQ.length, c.length)}
//     }
//     return null
// }

// export function deleteCookie(name: string) {
//     // console.log ('delete cookie ' + name)
//     document.cookie = name + '=; Max-Age=-99999999;'
// }

export function gotoTop() {
    // console.log ('gotoTop')
    const el = document.getElementById('root')
    if (el) {el.scrollTo(0, 0)}
    // el.style.overflow = 'auto'
}

export function isUserProhibitedToResolve(row: any): boolean {
    let entryType: TimelineEntryType = row.original.entryType
    let entrySubType: TimelineEntrySubType = row.original.entrySubType
    
    if (entryType === undefined && row.original.issueType) {
        entryType = TimelineEntryType.Issue
    }

    if (entryType !== TimelineEntryType.Issue) {return true}
    if (isUserAdmin()) {return false}

    if (entrySubType === undefined && row.original.issueType) {
        if (row.original.issueType >= IssueTypes.RiskManagement && row.original.issueType < IssueTypes.ResponsibleGaming) {
            entrySubType = TimelineEntrySubType.RiskManagement
        } else if (row.original.issueType >= IssueTypes.ResponsibleGaming && row.original.issueType < IssueTypes.KnowYourCustomer) {
            entrySubType = TimelineEntrySubType.ResponsibleGaming
        } else {
            entrySubType = TimelineEntrySubType.KnowYourCustomer
        }
    }

    // console.log (entryType)
    // console.log (entrySubType)
    // console.log (row.original.assignTo)

    const b: boolean = !(isUserSupport() && entrySubType === TimelineEntrySubType.KnowYourCustomer || isUserRGSpecialist() && entrySubType === TimelineEntrySubType.ResponsibleGaming || isUserRFSpecialist() && entrySubType === TimelineEntrySubType.RiskManagement)
    const c: boolean = isNotAssignedTo(row.original.assignTo)

    return b || c
}

export function isUserProhibitedToResolveIssue(row: any): boolean {
    if (isUserAdmin()) {return false}

    const b: boolean = !(isUserSupport() && row.original.entrySubType === TimelineEntrySubType.KnowYourCustomer || isUserRGSpecialist() && row.original.entrySubType === TimelineEntrySubType.ResponsibleGaming || isUserRFSpecialist() && row.original.entrySubType === TimelineEntrySubType.RiskManagement)
    const c: boolean = isNotAssignedTo(row.original.assignTo)

    return b || c
}

function isNotAssignedTo(array: number[]): boolean {
    let value: boolean = array.length ? true : false
    // console.log ('value is' + value)
    for (const item of array) {
        if (item === +Main.Instance.LoginState.id) {
            value = false
            break
        }
        // console.log ('VALUE is' + value)
    }
    return value
}

export function isEmailValid(email: string) {
    // tslint:disable-next-line:max-line-length
    const reg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i)
    return reg.test(email)
}


export function isUsernameValid(username: string) {
    const reg = new RegExp(/^[a-zA-Z0-9]*$/)
    return reg.test(username)
}

export function getPageNumbers(doc) {
    const pageCount: number = doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    for(let i = 0; i < pageCount; i++) { 
        doc.setPage(i); 
        doc.text(doc.internal.pageSize.width - 10, 205, doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, 'right');
    }
}

export function getHeader(type: string, doc, img, img2, part?: number, period?: string) {
    doc.addImage(img, 'png', 10, 6, 40, 40 * 559 / 2095)
    doc.addImage(img2, 'png', 277, 5, 10, 10 * 81 / 69)
    doc.setFontSize(20)
    if (type === 'GGR') {
        doc.text(60, 13, Assets.GGRRevenueReporting + ' ' + Assets.Part + ' ' + part + '/4')
    } else if (type === 'PLY') {
        doc.text(60, 13, Assets.Player_ + ' ' + Assets.Registration)
    } else if (type === 'PLR') {
        doc.text(60, 13, Assets.PlayersRegistrations)
    } else if (type === 'GRR') {
        doc.text(60, 13, Assets.Game + ' ' + Assets.Reports + ' - ' + Assets.Real)
    }
    if (period) {
        doc.setFontSize(14)
        doc.text(175, 13, Assets.Period + ': ' + period)
    }
}

export function getFooter(type: string, doc, ggr1?: boolean, text1?: string, ggr2?: boolean, text2?: string) {
    // if (type === 'GGR') {
    if (ggr1 === false && ggr2 === false) {return}
    doc.setFontSize(12)
    let y: number = 205
    if (ggr2) {
        doc.text(10, y, text2)
        y -= 5
    }

    if (ggr1) {
        doc.text(10, y, text1)
        y -= 5
    }
    // }
}

export function getIssueTypes(min: number, max: number): number[] {
    const types: number[] = [];
    for (let i = min; i <= max; i++) {
        if (i in IssueTypes) {
            types.push(i);
        }
    }
    return types;
}

/*
Sem dodau en easy regex za chekirat bonus code.
To bi mogoce se lahko nasploh nastavlo da ne morejo dat drugih.
Samo velike crke in stevilke, dolzina 3-10
*/

export function isBonusCode(bonusCode: string) {
    const reg = new RegExp(/^[A-Z0-9]{3,10}$/)
    return reg.test(bonusCode)
}

export function isProduction() {
    return Config.environment === Config.Environment.PRODUCTION
}

export function isLocal() {
    return Config.environment === Config.Environment.LOCAL
}

export function isDev() {
    return Config.environment === Config.Environment.DEV
}

export function isDevEdge() {
    return Config.environment === Config.Environment.DEVEDGE
}

export function isStage() {
    return Config.environment === Config.Environment.STAGE
}

export function isPet() {
    return Config.environment === Config.Environment.PET
}

export function getErrorMessage(error: number): string {
    for (const item of Assets_.gamanzaGameIntegrationErrors) {
        if (item.status === error) {
            return item.message
        }
    }
    return Assets.ErrorMessageNotFound
}