import * as React from 'react'
import {Latest, IssueTypes, AccountStatus, IAccountInfo} from '../../../Logic/Database/DatabaseData'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {PlayerCardButtons} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {SelectPlayer} from '../PlayerManagement/PlayerCard'
import ReactTable from 'react-table'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {/*faCheck*/faCheckDouble, faFileCsv, faExclamationCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
// import {faCheckCircle as faCheckCircle2} from '@fortawesome/free-regular-svg-icons'
import {dashboardSmallTableRows, dashboardBigTableRows} from './Dashboard'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {/*gotoTop, isUserProhibitedToResolve, */injectSpaces, isUserAdmin/*, isProduction*/} from '../../../Logic/Utils'
import {GetDate} from '../PlayerManagement/WithdrawalRequests'

function getRiskLevel(riskLevel: number): JSX.Element {

    return (
        <div
            title = {riskLevel === 0 ? Assets.NotApplicable : Assets.RiskLevel + ': ' + RiskLevel[riskLevel]}
            style = {{position: 'absolute', width: '100%', height: '100%', textAlign: 'center'}}
            className = {'Risk risk-' + RiskLevel[riskLevel]}
        >
            {riskLevel === 0 ? Assets_.NA : RiskLevel[riskLevel].charAt(0)}
        </div>
    )
}

export function GetIssueType(issueType: number): string {
    return issueType >= IssueTypes.RiskManagement && issueType < IssueTypes.ResponsibleGaming ? Assets.RiskManagementShort : issueType < IssueTypes.KnowYourCustomer ? Assets.ResponsibleGamingShort : Assets.KnowYourCustomerShort   
}

export function GetIssueTypeDetail(issueType: number): string {
    // console.log ('input ' + issueType)
    if (IssueTypes[issueType] === undefined) {
        return Assets.Unknown + ' ' + Assets.Issue + ' ' + Assets.Type
    }
    const IssueType: string[] = IssueTypes[issueType].match(/[A-Z][a-z]+/g)
    let type: string = ''
    for (let i: number = 0; i < IssueType.length; i++) {
        if (i > 0) { type += ' ' }
        type += IssueType[i]
    }
    // console.log ('output ' + type)
    return type
}

export function getIssueType(issueType: number, normal?: boolean): JSX.Element {
    if (issueType === undefined || IssueTypes[issueType] === undefined) { return <div/> }
    const IssueType: string[] = IssueTypes[issueType].match(/[A-Z][a-z]+/g)
    let type: string = ''
    for (let i: number = 0; i < IssueType.length; i++) {
        if (i > 0) { type += ' ' }
        type += IssueType[i]
    }
    return (normal ?
        <div style = {{fontWeight: 600}} title = {type}>
            {issueType >= IssueTypes.RiskManagement && issueType < IssueTypes.ResponsibleGaming ? Assets.RiskManagementShort :
            issueType < IssueTypes.KnowYourCustomer ? Assets.ResponsibleGamingShort : Assets.KnowYourCustomerShort}
        </div>
        :
        <div title = {type} style = {{position: 'absolute', width: '100%', height: '100%', textAlign: 'center'}}>
            {issueType >= IssueTypes.RiskManagement && issueType < IssueTypes.ResponsibleGaming ? Assets.RiskManagementShort :
            issueType < IssueTypes.KnowYourCustomer ? Assets.ResponsibleGamingShort : Assets.KnowYourCustomerShort}
        </div>
    )
}

function getResolved(resolved: boolean): JSX.Element {
    return (
        <div
            title = {resolved ? Assets.Resolved : Assets.Unresolved}
            style = {{position: 'absolute', width: '100%', height: '100%', textAlign: 'center'}}
            className = {resolved ? 'Risk risk-Normal' : 'Risk risk-Severe'}
        >
            {/*resolved ? Assets.Resolved.charAt(0) : Assets.Unresolved.charAt(0)*/}
            <FontAwesomeIcon style = {{fontSize: '16px', marginTop: '3px'}} icon = {resolved ? faCheckCircle : faExclamationCircle}/>
        </div>
    )
}

function getPlayerUsername(row: any) {
    // console.log (row)
    return <button onClick = {() => SelectPlayer(row.original.playerId, PlayerCardButtons.Transactions/*EventsTimeline*/)} style = {{lineHeight: 1.2}} className = "btn btn-default btn-xs mr0">{row.value}</button>
}

export function getLatest(subtype: string, title: string, latest: Latest[], expanded: boolean, subType?: number, moveable?: number, position?: number): JSX.Element {
    // console.log (title)
    // if (latest && latest.length > 0 && latest[0].riskManagerId !== undefined) 
    // console.log (latest)
/*
    if (moveable !== undefined) {
        console.log (moveable)
        console.log (position)
        console.log (position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows))
        if (latest) {
            console.log (latest.slice (position, position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)))
        }
    }
*/
    // console.log ('latest')
    // console.log (latest)
    // console.log (title)
/*
    const filtered = []
    if (subType) {
        filtered.push({id: 'issueType', value: subType})
        // console.log ()
    }
*/
    
    // console.log (latest)
    return (
        <div className = "dashboard-item-tournament">
            {latest && latest.toString() !== Assets_.NoDataReturnedFromTheQuery && latest.toString() !== Assets_.FailedToGetRecentTransactions && latest.length > 0 ?
            <ReactTable
                data = {/*subType ?

                    subType === IssueTypes.BlacklistExclusion && Main.Instance.DatabaseDataState.issuesBlacklistExclusion ?
                    moveable !== undefined && position !== undefined ? Main.Instance.DatabaseDataState.issuesBlacklistExclusion.slice (position, position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)) : Main.Instance.DatabaseDataState.issuesBlacklistExclusion :
                    subType === IssueTypes.BlacklistRegistration && Main.Instance.DatabaseDataState.issuesBlacklistRegistration ?
                    moveable !== undefined && position !== undefined ? Main.Instance.DatabaseDataState.issuesBlacklistRegistration.slice (position, position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)) : Main.Instance.DatabaseDataState.issuesBlacklistRegistration :
                    subType === IssueTypes.BlacklistLogin && Main.Instance.DatabaseDataState.issuesBlacklistLogin ?
                    moveable !== undefined && position !== undefined ? Main.Instance.DatabaseDataState.issuesBlacklistLogin.slice (position, position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)) : Main.Instance.DatabaseDataState.issuesBlacklistLogin :
                    [] :*/
                    moveable !== undefined && position !== undefined ? latest.slice (position, position + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)) : latest}
                // filtered = {filtered}
                columns = {[
                    {sortable: false, show: latest[0].username !== undefined, width: expanded ? 200 : 125, Header: Assets.PlayerUsername, accessor: 'username', headerClassName: 'th-left', Cell: (row) => getPlayerUsername(row)},
                    {sortable: false, show: latest[0].nickname !== undefined, width: 125, Header: Assets.PlayerUsername, accessor: 'nickname', headerClassName: 'th-left', Cell: (row) => getPlayerUsername(row)},
                    {sortable: false, show: latest[0].amount !== undefined, width: 100, Header: Assets.Amount, accessor: 'amount', headerClassName: 'th-left', Cell: (row) => (row.value ? row.value : '') + ' ' + row.original.currency},
                    {sortable: false, show: expanded && latest[0].playerId !== undefined, Header: Assets.PlayerId, accessor: 'playerId', headerClassName: 'th-left'},
//                  {width: 50, Header: 'Currency', accessor: 'currency', headerClassName: 'th-left'},
// <span title = {Assets.Registered}>{Assets.Registered} ({Assets_.UTC})</span>

                    {sortable: false, show: latest[0].created !== undefined && title === Assets.LoggedInPlayers, width: 245, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC}){/* <button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 4)}><FontAwesomeIcon icon = {faFileCsv}/></button> */ }</span>, accessor: 'created', headerClassName: 'th-left'},
                    {sortable: false, show: latest[0].created !== undefined && title === Assets.LatestResolvedIssues, width: 175, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 6, title)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'created', headerClassName: 'th-left'},
                    {sortable: false, show: latest[0].created !== undefined && title === Assets.LatestUnresolvedIssues, width: 150, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 6, title)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'created', headerClassName: 'th-left'},
                    {sortable: false, show: latest[0].created !== undefined && subtype === 'issues', width: 145, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, subType ? subType : 5)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'created', headerClassName: 'th-left'},
                    {sortable: false, show: latest[0].created !== undefined && (title === Assets.LatestRegistrations || title === Assets.LatestLogins || title === Assets.LatestLogouts), width: 245, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 7, title)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'created', headerClassName: 'th-left'},
                    {sortable: false, show: latest[0].created !== undefined && (title === Assets.LatestDeposits || title === Assets.LatestFirstDeposits || title === Assets.LatestWithdrawals), width: 147, Header: <span title = {Assets.Created}>{Assets.Timestamp} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 8, title)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'created', headerClassName: 'th-left', Cell: row => isNaN(row.value as any) ? row.value : GetDate(row.value)},

                    {sortable: false, show: title.indexOf(Assets.ExpiringInLessThan) === -1 && latest[0].registered !== undefined/*latest[0].registered !== undefined && !(latest[0].expiredOn === undefined && latest[0].expiresOn === undefined)*/, width: expanded ? 200 : 140, Header: <span title = {Assets.Registered}>{Assets.Registered} ({Assets_.UTC})</span>, accessor: 'registered', headerClassName: 'th-left', Cell: row => isNaN(row.value as any) ? row.value : GetDate(row.value)},
                    {sortable: false, show: title.indexOf(Assets.ExpiringInLessThan) === 0/*latest[0].registered !== undefined && latest[0].expiredOn === undefined && latest[0].expiresOn === undefined*/, width: 245, Header: <span title = {Assets.Registered}>{Assets.Registered} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 3, Assets.Accounts + ' ' + title)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'registered', headerClassName: 'th-left', Cell: row => isNaN(row.value as any) ? row.value : GetDate(row.value)},
                    {sortable: false, show: latest[0].expiredOn !== undefined && expanded, width: expanded ? 200 : 140, Header: <span title = {Assets.ExpiredOn}>{Assets.ExpiredOn} ({Assets_.UTC})<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 1)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'expiredOn', headerClassName: 'th-left', Cell: (row) => row.value ? isNaN(row.value as any) ? row.value.substring(0,10) : GetDate(row.value) : ''},
                    {sortable: false, show: latest[0].expiresOn !== undefined && expanded, width: expanded ? 200 : 140, Header: <span title = {Assets.ExpiresOn}>{Assets.ExpiresOn} ({Assets_.UTC})</span>, accessor: 'expiresOn', headerClassName: 'th-left', Cell: row => isNaN(row.value as any) ? row.value : GetDate(row.value)},
                    {sortable: false, show: latest[0].expiredOn !== undefined && expanded === false, width: 125, Header: <span title = {Assets.ExpiredOn}>{Assets.ExpiredOn}<button title = {Assets.Download + ' ' + Assets.CSV} type = "button" style = {{fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 1)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'expiredOn', headerClassName: 'th-left', Cell: (row) => row.value ? isNaN(row.value as any) ? row.value.substring(0,10) : GetDate(row.value) : ''},
                    // expiryDays
                    {sortable: false, show: title === Assets.ProvisionalAccounts, width: 105, Header: <span title = {Assets.ExpirationDays}>{Assets.Expiration}<button title = {Assets.Download + ' ' + Assets.CSV} disabled = {/*isProduction() || */!isUserAdmin()} type = "button" style = {{/*color: '#f0ad4e',*/ fontSize: '20px', border: '0px', background: 'transparent', position: 'absolute', top: '-7px', right: '-10px'}} className = "btn btn-default btn-sm mr0" onClick = {() => download(latest, 2)}><FontAwesomeIcon icon = {faFileCsv}/></button></span>, accessor: 'expiryDays', headerClassName: 'th-left', Cell: (row) => row.value ? row.value + ' ' + (row.value === 1 ? Assets.Day : Assets.Days).toLowerCase() : ''},
                    {sortable: false, show: latest[0].riskLevel !== undefined, width: 35, Header: <span title = {Assets.RiskLevel}>{Assets.RiskLevelShort}</span>, accessor: 'riskLevel', headerClassName: 'th-center', className: 'dashboard-item-latest', Cell: (row) => getRiskLevel(row.value)},
                    // {sortable: false, show: latest[0].issueType !== undefined, width: 32, Header: <FontAwesomeIcon title = {Assets.Issue + ' ' + Assets.Type} icon = {faCheck}/>, accessor: 'issueType', headerClassName: 'th-center', className: 'dashboard-item-latest', Cell: (row) => subType ? row.original.resolved ? '' : <button title = {Assets.Resolve} type = "button" style = {{marginLeft: '3px'}} className = "btn btn-warning btn-xs mr0" onClick = {() => selectIssue(row.original)}><FontAwesomeIcon icon = {faCheckDouble}/></button> : getIssueType(row.value)},
                    {sortable: false, show: latest[0].issueType !== undefined && expanded, Header: <span style = {{marginLeft: '5px'}}>{Assets.Issue + ' ' + Assets.Type + ' ' + Assets.Detail}</span>, accessor: 'issueType', headerClassName: 'th-left', className: 'dashboard-item-latest', Cell: (row) => <span className = "ml">{GetIssueTypeDetail(row.value)}</span>},

                    // {sortable: false, show: latest[0].created !== undefined && title === Assets.LatestUnresolvedIssues && expanded, width: 65, Header: <span>{Assets.Resolve}</span>, accessor: 'created', headerClassName: 'th-left', Cell: (row) => <button type = "button" className = "btn btn-warning btn-xs mr0" onClick = {() => selectIssue(row.original)}>{Assets.Resolve}</button>},
                    // {sortable: false, show: latest[0].created !== undefined && title === Assets.LatestUnresolvedIssues && !expanded, width: 30, Header: <span title = {Assets.Resolve}><FontAwesomeIcon icon = {faCheckDouble}/></span>, accessor: 'created', headerClassName: 'th-center', Cell: (row) => <button disabled = {isUserProhibitedToResolve(row)} title = {Assets.Resolve} type = "button" className = "btn btn-warning btn-xs mr0" onClick = {() => {selectIssue(row.original);gotoTop()}}><FontAwesomeIcon icon = {faCheckDouble}/></button>},

                    {sortable: false, show: latest[0].created !== undefined && subtype === 'issues', width: 35, Header: <FontAwesomeIcon title = {Assets.Resolved} icon = {faCheckDouble}/>, accessor: 'resolved', headerClassName: 'th-center', className: 'dashboard-item-latest', Cell: (row) => getResolved(row.value)},

//                  {Header: 'Nickname', accessor: 'type', headerClassName: 'th-left'},
                ]}
                showPaginationBottom = {false}
                pageSize = {expanded ? (dashboardBigTableRows < latest.length ? dashboardBigTableRows : latest.length) : (dashboardSmallTableRows < latest.length ? dashboardSmallTableRows : latest.length)}
            /> : void 0}
        </div>
    )
}

// function selectIssue(issue: Latest) {
    // console.log (issue)
    // console.log (Main.Instance.DatabaseDataState.issues)
    // Main.Instance.RiskManagementState.playerId
    // DbRoutes.wsGetPlayerCard(issue.playerId, true)
/*
    let rowId: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.issues.length; i++) {
        if (Main.Instance.DatabaseDataState.issues[i].id === issue.id) {
            rowId = i
            break
        }
    }
    // console.log (issue.playerId)
    Logic.Type.New (Main.Instance.RiskManagementState, {playerId: issue.playerId, showAddCSCallbackModal: true, rowId, mode: true})
*/
// }

export function download(latest: Latest[], id: number, title?: string) {
    // console.log (id, title)
    // console.log ('download')
    // console.log ('**********************************************************************')
    // id = 4 => Logged in Players, can fetch all with no problems whatsoever
    if (Main.Instance.DatabaseDataState.RecentEventsActive === 0 && (id === 1 || id === 2 || id === 3 || id === 5 || id === 6 || id === 7 || id === 8)) {
        // console.log (0)
        // console.log ('download')
        let which: number = 0

        if (id === 1) {
            which = 10
        } else if (id === 2) {
            which = 11
        } else if (id === 3) {
            which = 12
        } else if (id === 5) {
            which = 7
        } else if (id === 6) {
            if (title === Assets.LatestUnresolvedIssues) {
                which = 8
            } else if (title === Assets.LatestResolvedIssues) {
                which = 9
            }
        } else if (id === 7) {
            if (title === Assets.LatestRegistrations) {
                which = 4
            } else if (title === Assets.LatestLogins) {
                which = 5
            } else if (title === Assets.LatestLogouts) {
                which = 6
            }
        } else if (id === 8) {
            if (title === Assets.LatestDeposits) {
                which = 1
            } else if (title === Assets.LatestFirstDeposits) {
                which = 2
            } else if (title === Assets.LatestWithdrawals) {
                which = 3
            }
        }
        // console.log ('which: ' + which)
        if (which === 1) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetDeposits: true})}
        if (which === 2) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetFirstDeposits: true})}
        if (which === 3) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetWithdrawals: true})}
        if (which === 4) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetRegistrations: true})}
        if (which === 5) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetLogins: true})}
        if (which === 6) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetLogouts: true})}
        if (which === 7) {Logic.Type.New (Main.Instance.DatabaseDataState, {/*RecentEventsActiveTitle: Assets.LatestIssues, */RecentEventsActive: id, recentEventsActive: true, RecentEventsGetIssues: true})}
        if (which === 8) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetIssuesUnresolved: true})}
        if (which === 9) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, RecentEventsGetIssuesResolved: true})}

        if (which === 10) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: Assets.ExpiredProvisionalAccounts, RecentEventsActive: id, recentEventsActive: true, /*RecentEventsGetExpiredAccounts*/ExpiredAccountsGet: true})}
        if (which === 11) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: Assets.ProvisionalAccounts, RecentEventsActive: id, recentEventsActive: true, /*RecentEventsGetUnverifiedAccountsAll*/UnverifiedAccountsAllGet: true})}
        if (which === 12) {Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActiveTitle: title, RecentEventsActive: id, recentEventsActive: true, /*RecentEventsGetUnverifiedAccounts*/UnverifiedAccountsGet: true})}
        // console.log (which)
        DbRoutes.getRecentEvents(which, true)
    } else {
        // console.log (10)
        // console.log (id, title)
        let filename: string = ''
        if (title) { // id === 3, 6, 7, 8
            filename = title
        } else if (id === 1) {
            filename = Assets.ExpiredProvisionalAccounts
        } else if (id === 2) {
            filename = Assets.ProvisionalAccounts
        } else if (id === 4) {
            filename = Assets.LoggedInPlayers
        } else if (id === 5) {
            filename = Assets.Issues

        } else if (id === IssueTypes.BlacklistExclusion) {
            filename = Assets.Issues + ' - ' + Assets.BlacklistExclusion
        } else if (id === IssueTypes.BlacklistLogin) {
            filename = Assets.Issues + ' - ' + Assets.BlacklistLogin
        } else if (id === IssueTypes.BlacklistCronJob) {
            filename = Assets.Issues + ' - ' + Assets.BlacklistCronJob
        } else if (id === IssueTypes.BlacklistManual) {
            filename = Assets.Issues + ' - ' + Assets.BlacklistManual
        } else if (id === IssueTypes.BlacklistRegistration) {
            filename = Assets.Issues + ' - ' + Assets.BlacklistRegistration

        } else if (id === IssueTypes.PEPCheckLogin) {
            filename = Assets.Issues + ' - ' + Assets.PoliticallyExposedPersons + ' ' + Assets.Check + Assets._at_ + Assets.Login
        } else if (id === IssueTypes.PEPCheckRegistration) {
            filename = Assets.Issues + ' - ' + Assets.PoliticallyExposedPersons + ' ' + Assets.Check + Assets._at_ + Assets.Registration
        } else if (id === IssueTypes.PEPCheckNameChanged) {
            filename = Assets.Issues + ' - ' + Assets.PoliticallyExposedPersons + ' ' + Assets.Check + Assets._at_ + Assets.Name + ' ' + Assets.Change
        } else if (id === IssueTypes.PEPCheckBeneficiaryName) {
            filename = Assets.Issues + ' - ' + Assets.PoliticallyExposedPersons + ' ' + Assets.Check + ' ' + Assets.BeneficiaryName
        } else if (id === IssueTypes.PEPChangedByOperator) {
            filename = Assets.Issues + ' - ' + Assets.PoliticallyExposedPersons + ' ' + Assets.Check + Assets._by_ + Assets.Operator

        } else if (id === IssueTypes.CRIFCheckMismatch) {
            filename = Assets.Issues + ' - ' + Assets_.CRIF + ' ' + Assets.Check + Assets._at_ + Assets.Mismatch
        } else if (id === IssueTypes.CRIFCheckLogin) {
            filename = Assets.Issues + ' - ' + Assets_.CRIF + ' ' + Assets.Check + Assets._at_ + Assets.Login
        } else if (id === IssueTypes.CRIFCheckRegistration) {
            filename = Assets.Issues + ' - ' + Assets_.CRIF + ' ' + Assets.Check + Assets._at_ + Assets.Registration
        } else if (id === IssueTypes.CRIFAddressChangedOperator) {
            filename = Assets.Issues + ' - ' + Assets_.CRIF + ' ' + Assets.Address + ' ' + Assets.Change + Assets._by_ + Assets.Operator
        }
        // console.log (Main.Instance.DatabaseDataState.expiringAccountsData)
        let s: string = ''
        if (id === 100) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Registered + getExtensionHeader() + '\n'
        } else if (id === 1) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Registered + ',' + Assets.ExpiredOn /*+ getExtensionHeader()*/ + '\n'
        } else if (id === 2) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Registered + ',' + Assets.ExpiresOn + ',' + Assets.ExpirationDays + getExtensionHeader() + '\n'
        } else if (id === 3) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Registered /*+ getExtensionHeader()*/ + '\n'
        } else if (id === 4) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Timestamp + '\n'
        } else if (id === 5) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Timestamp + ',' + Assets.RiskLevel + ',' + Assets.Issue + ' ' + Assets.Type + ',' + Assets.Issue + ' ' + Assets.Type + ' ' + Assets.Details + ',' + Assets.Resolved + '\n'
        } else if (id === IssueTypes.BlacklistManual || id === IssueTypes.BlacklistCronJob || id === IssueTypes.BlacklistExclusion || id === IssueTypes.BlacklistLogin || id === IssueTypes.BlacklistRegistration || (id > IssueTypes.CrifPep && id < IssueTypes.Bally)) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Timestamp + ',' + Assets.RiskLevel + ',' + Assets.Resolved + '\n'
        } else if (id === 6) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Timestamp + ',' + Assets.RiskLevel + ',' + Assets.Issue + ' ' + Assets.Type + ',' + Assets.Issue + ' ' + Assets.Type + ' ' + Assets.Details + '\n'
        } else if (id === 7) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Timestamp + '\n'
        } else if (id === 8) {
            s += Assets.PlayerID + ',' + Assets.Username + ',' + Assets.Amount + ' (' + Assets_.currency + ')' + ',' + Assets.Timestamp + '\n'
        }

        if (id === 100) {
            s += getExtension('')
/*
            if (Main.Instance.DatabaseDataState.allAccountsData) {
                for (const item of Main.Instance.DatabaseDataState.allAccountsData) {
                    s += item.playerId + ',' + item.username + ',' + item.registered + getExtension(item.playerId, id) + '\n'
                }
            }
*/
        } else { for (const item of latest) {
            // let playerCard: {firstName: string, lastName: string, accountStatus: AccountStatus}
/*
            if (id === 1 || id === 2 || id === 3) {
                playerCard = getPlayerCard(item.playerId)
            }
*/
/*
            if (id === 100) {
                console.log (item)
                s += item.playerId + ',' + item.username + ',' + item.registered + getExtension(item.playerId, id) + '\n'
            } else
*/
            if (id === 1) {
                s += item.playerId + ',' + item.username + ',' + (isNaN(item.registered as any) ? item.registered : GetDate(item.registered as number)) + ',' + (isNaN(item.expiredOn as any) ? item.expiredOn : GetDate(item.expiredOn as number)) /*+ getExtension(playerCard)*/ + '\n'
            } else if (id === 2) {
                s += item.playerId + ',' + item.username + ',' + (isNaN(item.registered as any) ? item.registered : GetDate(item.registered as number)) + ',' + (isNaN(item.expiresOn as any) ? item.expiresOn : GetDate(item.expiresOn as number)) + ',' + item.expiryDays + getExtension(item.playerId) + '\n'
            } else if (id === 3) { // this should be expanded
                // console.log (playerCard)
                // console.log (item)
                s += item.playerId + ',' + item.username + ',' + (isNaN(item.registered as any) ? item.registered : GetDate(item.registered as number)) /*+ getExtension(playerCard)*/ + '\n'
            } else if (id === 4) {
                s += item.playerId + ',' + item.username + ',' + item.created + '\n'
            } else if (id === 5) {
                s += item.playerId + ',' + item.username + ',' + item.created + ',' + RiskLevel[item.riskLevel] + ',' + GetIssueType(item.issueType) + ',' + GetIssueTypeDetail(item.issueType) + ',' + item.resolved + '\n'
            } else if (id === IssueTypes.BlacklistManual || id === IssueTypes.BlacklistCronJob || id === IssueTypes.BlacklistExclusion || id === IssueTypes.BlacklistLogin || id === IssueTypes.BlacklistRegistration || (id > IssueTypes.CrifPep && id < IssueTypes.Bally)) {
                s += item.playerId + ',' + item.username + ',' + item.created + ',' + RiskLevel[item.riskLevel] + ',' + item.resolved + '\n'
            } else if (id === 6) {
                s += item.playerId + ',' + item.username + ',' + item.created + ',' + RiskLevel[item.riskLevel] + ',' + GetIssueType(item.issueType) + ',' + GetIssueTypeDetail(item.issueType) + '\n'
            } else if (id === 7) {
                s += item.playerId + ',' + item.username + ',' + item.created + '\n'
            } else if (id === 8) {
                s += item.playerId + ',' + item.username + ',' + item.amount + ',' + (isNaN(item.created as any) ? item.created : GetDate(item.created as number)) + '\n'
            }
        }}
        if (Main.Instance.DatabaseDataState.RecentEventsActive > 0) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {RecentEventsActive: 0, RecentEventsActiveTitle: undefined})
        }
        if (s && s.length > 0) {
            const element = document.createElement('a')
            const file = new Blob([s], {type: 'text/csv'})
            element.href = URL.createObjectURL(file)
            element.download = filename + ' ' + (new Date()).toISOString().substring(0,10).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
            // element.click()
            element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}))
        }
    }
}
/*
function getPlayerCard(playerId: string) {
    for (const item of Main.Instance.DatabaseDataState.playerCards) {
        if (item.playerId === playerId) {
            return item
        }
    }
}
*/
function getExtension(playerId: string): string {
    const data: IAccountInfo[] = playerId === '' ? Main.Instance.DatabaseDataState.allAccountsData : Main.Instance.DatabaseDataState.expiringAccountsData
    // console.log (data)
    let s: string
    if (data) {
        for (const item of data) {
            if (playerId === '' || item.playerId === playerId) {
                let nationality: string = item.nationality
                if (Main.Instance.DatabaseDataState.nationalities) {
                    for (const Item of Main.Instance.DatabaseDataState.nationalities) {
                        if (item.nationality === Item.data.code3) {
                            nationality = Item.data.nationality.split(',')[0]
                            break
                        }
                    }
                }

                let language: string = item.language
                if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.languages) {
                    for (const Item of Main.Instance.DatabaseDataState.custom.general.languages) {
                        if (item.language === Item.short) {
                            language = Item.name
                            break
                        }
                    }
                }

                // if (id) {console.log (item)}
                // accountStatus, registered, username, playerId
                if (playerId === '') {
                    s += item.playerId + ',' + item.username + ',' + (isNaN(item.registered as any) ? item.registered : GetDate(item.registered)) + 
                    getContent(item, nationality, language)
                    + '\n'

                } else {
                    return getContent(item, nationality, language)
                }
            }
        }
    }

    return s
}

function getContent(item: IAccountInfo, nationality: string, language: string): string {
    return (
    ',' + (item.testAccount ? Assets.YES : Assets.NO) +
    ',' + item.firstName +
    ',' + item.lastName +
    ',' + item.birthday +
    ',' + nationality +
    ',' + (item.gender === 'm' ? Assets.male : Assets.female) +
    ',' + item.phoneNumber +
    ',' + item.address +
    ',' + item.streetNumber +
    ',' + item.city +
    ',' + item.postalCode +
    ',' + item.email +
    ',' + (item.isEmailVerified ? Assets.YES : Assets.NO) +
    ',' + (item.isMobileVerified ? Assets.YES : Assets.NO) +
    ',' + injectSpaces(AccountStatus[item.accountStatus]) +
    ',' + (item.allowEmail ? Assets.YES : Assets.NO) +
    ',' + (item.allowSms ? Assets.YES : Assets.NO) +
    ',' + (item.allowTelephone ? Assets.YES : Assets.NO) +
    ',' + (item.allowPost ? Assets.YES : Assets.NO) +
    ',' + language
    )
}

function getExtensionHeader(): string {
    // console.log (Main.Instance.DatabaseDataState.nationalities)
    // console.log (Main.Instance.DatabaseDataState.custom.general.languages)

    return (
        ',' + Assets.TestAccount +
        ',' + Assets.FirstName +
        ',' + Assets.LastName +
        ',' + Assets.Birthday +
        ',' + Assets.Nationality +
        ',' + Assets.Gender +
        ',' + Assets.PhoneNumber +
        ',' + Assets.Address +
        ',' + Assets.StreetNumber +
        ',' + Assets.City +
        ',' + Assets.PostalCode +
        ',' + Assets.Email +
        ',' + Assets.EmailVerified +
        ',' + Assets.PhoneNumberVerified +
        ',' + Assets.AccountStatus +
        ',' + Assets.AllowEmail +
        ',' + Assets.AllowSms +
        ',' + Assets.AllowTelephone +
        ',' + Assets.AllowPost +
        ',' + Assets.Language
    )
}