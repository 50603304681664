import * as React from 'react'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ButtonIcon} from '../../Reusables/Button'
import * as DataStore from '../../../Logic/DataStore/DataStore'
import {FormInput/*, FormInputNumber*/} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
/*faQuestion,*/ /*faTint, faFillDrip,*/
import {faFileCsv, faFilePdf, faExclamationTriangle, faUsers, faGamepad, faTimes, faClock, faCogs, faStopwatch, faCoins, faArrowsAltV, faSave, faCalendarAlt, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons'
import * as Assets from '../../Assets'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import * as TableUtils from '../../Reusables/TableUtils'
import ReactTable from 'react-table'
import {IPlayersInfo, IPlayers} from '../../../Logic/Database/DatabaseData'
import {gotoTop, isUserAdmin} from '../../../Logic/Utils'
import * as Assets_ from '../../Assets_'
import * as Config from '../../../Logic/Config'
import {IPlayerGroupRule} from '../../../Logic/DataStore/DataStore'

function gotoInsertMode() {

    if (true
/*
        (parseInt(Main.Instance.PlayerGroupsInternal.code) === parseInt(Main.Instance.PlayerGroupsInternal.code_saved))
        && Main.Instance.PlayerGroupsInternal.rate === Main.Instance.PlayerGroupsInternal.rate_saved
        && parseInt(Main.Instance.PlayerGroupsInternal.active) === parseInt(Main.Instance.PlayerGroupsInternal.active_saved)
        && parseInt(Main.Instance.PlayerGroupsInternal.main) === parseInt(Main.Instance.PlayerGroupsInternal.main_saved)
        && parseInt(Main.Instance.PlayerGroupsInternal.frmc) === parseInt(Main.Instance.PlayerGroupsInternal.frmc_saved)*/
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}
/*
function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.PlayerGroupsInternal, {pSize:parseInt(text)})
        FirstPage()
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.PlayerGroupsInternal,{offset:(0).toString()})

    DbRoutes.getCRMRules()
    Logic.Type.New(Main.Instance.PlayerGroupsInternal,{pNum: 0})
}

function PrevPage() {
    if (Main.Instance.PlayerGroupsInternal.pNum > 0) {

        Logic.Type.New(Main.Instance.PlayerGroupsInternal, {
            offset:((Main.Instance.PlayerGroupsInternal.pNum - 1) * 
            Main.Instance.PlayerGroupsInternal.pSize).toString()})
        DbRoutes.getCRMRules()

        Logic.Type.New(Main.Instance.PlayerGroupsInternal,{pNum: Main.Instance.PlayerGroupsInternal.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.PlayerGroupsInternal.pNum + 1) * Main.Instance.PlayerGroupsInternal.pSize < 
    Main.Instance.PlayerGroupsInternal.count) {

        Logic.Type.New(Main.Instance.PlayerGroupsInternal, {
            offset: ((Main.Instance.PlayerGroupsInternal.pNum + 1) * 
            Main.Instance.PlayerGroupsInternal.pSize).toString()})
        DbRoutes.getCRMRules()
        
        Logic.Type.New(Main.Instance.PlayerGroupsInternal,{pNum: Main.Instance.PlayerGroupsInternal.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.PlayerGroupsInternal.count <= Main.Instance.PlayerGroupsInternal.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.PlayerGroupsInternal.count === 0) {

            Logic.Type.New(Main.Instance.PlayerGroupsInternal, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.PlayerGroupsInternal.count - 1) / 
                    Main.Instance.PlayerGroupsInternal.pSize)
            Logic.Type.New(Main.Instance.PlayerGroupsInternal, {
                offset:(npNum * Main.Instance.PlayerGroupsInternal.pSize).toString()})
        }

        DbRoutes.getCRMRules()
        Logic.Type.New(Main.Instance.PlayerGroupsInternal,{pNum: npNum})
    }
}
*/
function Freeze() {
    if (confirm(Assets.pg1)) {
        Logic.Type.New (Main.Instance.PlayerGroupsState, {
            staticTimestamp: (new Date()).toISOString(),
            staticMembers: getMembersArray(Main.Instance.PlayerGroupsInternal.id),
        })
    }
}

function getPlayerGroupsCount() {
    if (Main.Instance.PlayerGroupsInternal.countCalled === false) {
        Logic.Type.New(Main.Instance.PlayerGroupsInternal, {countCalled: true})
        DbRoutes.getCurrencyRates()
        // DbRoutes.getCRMRules()
        // DbRoutes.getCRM()
        DbRoutes.GetFlags()
    }
}

function toggleInterval() {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {interval: !Main.Instance.PlayerGroupsState.interval})
}

function handleInsertUpdate(Clone?: boolean) {
    if (Main.Instance.PlayerGroupsState.title === undefined || Main.Instance.PlayerGroupsState.title.length === 0) {
        alert (Assets.pg3 + '!')
    } else if (Main.Instance.PlayerGroupsState.priority === undefined || Main.Instance.PlayerGroupsState.priority === 0) {
        alert (Assets.pg4 + '!')
    } else if (Main.Instance.PlayerGroupsState.staticGroup === undefined) {
        alert (Assets.pg5 + '!')
    } else if (Main.Instance.PlayerGroupsInternal.mode && Clone === undefined) {
        // DbRoutes.UpdatePlayerGroup (Main.Instance.PlayerGroupsState, Update)
        if (Main.Instance.PlayerGroupsInternal.id) {
            // console.log ('id: ' + Main.Instance.PlayerGroupsInternal.id)
            DbRoutes.wsBasicUpdate(Config.amPlayerGroups, parseInt(Main.Instance.PlayerGroupsInternal.id), Main.Instance.PlayerGroupsState)
            Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
                mode: false,
                modePlayers: false,
                modeGames: false,
                modeCalendar: false,
                modeBetSizing: false,
                modeSession: false,
                modeFrequency: false,
                modeDeposit: false,
                modeWithdrawal: false
            })
        }
    } else {
        if (Clone) { Logic.Type.New (Main.Instance.PlayerGroupsState, {title: Main.Instance.PlayerGroupsState.title + ' (' + Assets_.CLONED + ')'}) }
        // DbRoutes.InsertPlayerGroup (Main.Instance.PlayerGroupsState, InsertLastPage)
        DbRoutes.wsBasicInsert(Config.amPlayerGroups, Main.Instance.PlayerGroupsState)
    }
}

function setLogic(Item: string, value: string) {
    if (Item === 'games') {
        if (Main.Instance.PlayerGroupsState.gamesLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {
            gamesLogic: undefined})
        } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {gamesLogic: value}) }
    } else if (Item === 'calendarYear') {
        if (Main.Instance.PlayerGroupsState.calendarYearLogic === value) {
            Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYearLogic: undefined})
        } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYearLogic: value}) }
    } else if (Item === 'calendarMonth') {
        if (Main.Instance.PlayerGroupsState.calendarMonthLogic === value) {
            Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthLogic: undefined})
        } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthLogic: value}) }
    } else if (Item === 'calendarWeekDays') {
        if (Main.Instance.PlayerGroupsState.calendarWeekDaysLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDaysLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDaysLogic: value}) }
    } else if (Item === 'calendarMonthDays') {
        if (Main.Instance.PlayerGroupsState.calendarMonthDaysLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDaysLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDaysLogic: value}) }
    } else if (Item === 'calendarYearMax') {
        if (Main.Instance.PlayerGroupsState.calendarYearLogicMax === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYearLogicMax: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYearLogicMax: value}) }
    } else if (Item === 'calendarMonthMax') {
        if (Main.Instance.PlayerGroupsState.calendarMonthLogicMax === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthLogicMax: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthLogicMax: value}) }
    } else if (Item === 'calendarWeekDaysMax') {
        if (Main.Instance.PlayerGroupsState.calendarWeekDaysLogicMax === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDaysLogicMax: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDaysLogicMax: value}) }
    } else if (Item === 'calendarMonthDaysMax') {
        if (Main.Instance.PlayerGroupsState.calendarMonthDaysLogicMax === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDaysLogicMax: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDaysLogicMax: value}) }
    } else if (Item === 'timezones') {
        if (Main.Instance.PlayerGroupsState.timezonesLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {timezonesLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {timezonesLogic: value}) }
    } else if (Item === 'regions') {
        if (Main.Instance.PlayerGroupsState.regionsLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {regionsLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {regionsLogic: value}) }
    } else if (Item === 'nationalities') {
        if (Main.Instance.PlayerGroupsState.nationalitiesLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {nationalitiesLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {nationalitiesLogic: value}) }

/*
    } else if (Item === 'continents') {
        if (Main.Instance.PlayerGroupsState.continentsLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {continentsLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {continentsLogic: value}) }
*/
    } else if (Item === 'countries') {
        if (Main.Instance.PlayerGroupsState.countriesLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {countriesLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {countriesLogic: value}) }
    } else if (Item === 'currencies') {
        if (Main.Instance.PlayerGroupsState.currenciesLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {currenciesLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {currenciesLogic: value}) }
    } else if (Item === 'gender') {
        if (Main.Instance.PlayerGroupsState.genderLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {genderLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {genderLogic: value}) }
    } else if (Item === 'ageGroup') {
        if (Main.Instance.PlayerGroupsState.ageGroupLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {ageGroupLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {ageGroupLogic: value}) }
    } else if (Item === 'balanceGroup') {
        if (Main.Instance.PlayerGroupsState.balanceGroupLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {balanceGroupLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {balanceGroupLogic: value}) }

    } else if (Item === 'withinDeposit') {
        if (Main.Instance.PlayerGroupsState.withinDepositLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {withinDepositLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {withinDepositLogic: value}) }

    } else if (Item === 'withinWithdrawal') {
        if (Main.Instance.PlayerGroupsState.withinWithdrawalLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {withinWithdrawalLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {withinWithdrawalLogic: value}) }

    } else if (Item === 'minDeposit') {
        if (Main.Instance.PlayerGroupsState.minDepositLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minDepositLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minDepositLogic: value}) }
    } else if (Item === 'minDepositCurrency') {
        if (Main.Instance.PlayerGroupsState.minDepositCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minDepositCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minDepositCurrencyLogic: value}) }

    } else if (Item === 'maxDeposit') {
        if (Main.Instance.PlayerGroupsState.maxDepositLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxDepositLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxDepositLogic: value}) }
    } else if (Item === 'maxDepositCurrency') {
        if (Main.Instance.PlayerGroupsState.maxDepositCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxDepositCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxDepositCurrencyLogic: value}) }

    } else if (Item === 'minWithdrawal') {
        if (Main.Instance.PlayerGroupsState.minWithdrawalLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWithdrawalLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWithdrawalLogic: value}) }
    } else if (Item === 'minWithdrawalCurrency') {
        if (Main.Instance.PlayerGroupsState.minWithdrawalCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWithdrawalCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWithdrawalCurrencyLogic: value}) }

    } else if (Item === 'maxWithdrawal') {
        if (Main.Instance.PlayerGroupsState.maxWithdrawalLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWithdrawalLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWithdrawalLogic: value}) }
    } else if (Item === 'maxWithdrawalCurrency') {
        if (Main.Instance.PlayerGroupsState.maxWithdrawalCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWithdrawalCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWithdrawalCurrencyLogic: value}) }


    } else if (Item === 'minBet') {
        if (Main.Instance.PlayerGroupsState.minBetLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetLogic: value}) }
    } else if (Item === 'minBetCurrency') {
        if (Main.Instance.PlayerGroupsState.minBetCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetCurrencyLogic: value}) }

    } else if (Item === 'maxBet') {
        if (Main.Instance.PlayerGroupsState.maxBetLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetLogic: value}) }
    } else if (Item === 'maxBetCurrency') {
        if (Main.Instance.PlayerGroupsState.maxBetCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetCurrencyLogic: value}) }

    } else if (Item === 'minWin') {
        if (Main.Instance.PlayerGroupsState.minWinLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWinLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWinLogic: value}) }
    } else if (Item === 'minWinCurrency') {
        if (Main.Instance.PlayerGroupsState.minWinCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWinCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minWinCurrencyLogic: value}) }

    } else if (Item === 'maxWin') {
        if (Main.Instance.PlayerGroupsState.maxWinLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWinLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWinLogic: value}) }
    } else if (Item === 'maxWinCurrency') {
        if (Main.Instance.PlayerGroupsState.maxWinCurrencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWinCurrencyLogic: 'EUR'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWinCurrencyLogic: value}) }

    } else if (Item === 'bet') {
        if (Main.Instance.PlayerGroupsState.betLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {betLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {betLogic: value}) }
    } else if (Item === 'win') {
        if (Main.Instance.PlayerGroupsState.winLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {winLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {winLogic: value}) }
    } else if (Item === 'deposit') {
        if (Main.Instance.PlayerGroupsState.depositLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {depositLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {depositLogic: value}) }
    } else if (Item === 'withdrawal') {
        if (Main.Instance.PlayerGroupsState.withdrawalLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {withdrawalLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {withdrawalLogic: value}) }

    } else if (Item === 'bets') {
        if (Main.Instance.PlayerGroupsState.betsSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {betsSessionLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {betsSessionLogic: value}) }
    } else if (Item === 'time') {
        if (Main.Instance.PlayerGroupsState.timeSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {timeSessionLogic: 'ignore'}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {timeSessionLogic: value}) }
    } else if (Item === 'minBetsSession') {
        if (Main.Instance.PlayerGroupsState.minBetsSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetsSessionLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetsSessionLogic: value}) }
    } else if (Item === 'maxBetsSession') {
        if (Main.Instance.PlayerGroupsState.maxBetsSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetsSessionLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetsSessionLogic: value}) }
    } else if (Item === 'minTimeSession') {
        if (Main.Instance.PlayerGroupsState.minTimeSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {minTimeSessionLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {minTimeSessionLogic: value}) }
    } else if (Item === 'maxTimeSession') {
        if (Main.Instance.PlayerGroupsState.maxTimeSessionLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxTimeSessionLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {maxTimeSessionLogic: value}) }

    } else if (Item === 'frequency0') {
        if (Main.Instance.PlayerGroupsState.frequencyLogic0 === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {frequencyLogic0: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {frequencyLogic0: value}) }
    } else if (Item === 'frequency') {
        if (Main.Instance.PlayerGroupsState.frequencyLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {frequencyLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {frequencyLogic: value}) }

    } else if (Item === 'levels') {
        if (Main.Instance.PlayerGroupsState.levelsLogic === value) { Logic.Type.New (Main.Instance.PlayerGroupsState, {levelsLogic: undefined}) } else { Logic.Type.New (Main.Instance.PlayerGroupsState, {levelsLogic: value}) }
/*   
    } else if (Item === 'sublevels') {
        if (Main.Instance.PlayerGroupsState.sublevelsLogic === value) Logic.Type.New (Main.Instance.PlayerGroupsState, {sublevelsLogic: undefined})
        else Logic.Type.New (Main.Instance.PlayerGroupsState, {sublevelsLogic: value})
*/
    }
}

function getHierarchyOptions(): JSX.Element[] {
    const hierarchyOptions: JSX.Element[] = []
    hierarchyOptions.push (<option key = "-1" value = "0">{Assets.SelectPriority}</option>)
    for (let i: number = 0; i < 100; i++) { hierarchyOptions.push (<option key = {i.toString()} value = {(i+1).toString()}>{Assets.Priority.toLowerCase() + ': ' + (i+1).toString()}</option>) }
    return hierarchyOptions
}
/*
function getColorOptions(background: boolean): JSX.Element[] {
    const colorOptions: JSX.Element[] = []
    colorOptions.push (<option value = "0">{background ? 'Select background' : 'Select color'}</option>)
    for (const item of DataStore.colors) colorOptions.push (<option value = {item}>{item}</option>)
    return colorOptions
}
*/
function ModePlayer() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modePlayers: !Main.Instance.PlayerGroupsInternal.modePlayers,
    },
)}

function ModeGames() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeGames: !Main.Instance.PlayerGroupsInternal.modeGames,
    },
)}
/*
function ModeCalendar() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeCalendar: !Main.Instance.PlayerGroupsInternal.modeCalendar
    }
)}

function ModeFrequency() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeFrequency: !Main.Instance.PlayerGroupsInternal.modeFrequency
    }
)}

function ModeSession() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeSession: !Main.Instance.PlayerGroupsInternal.modeSession
    }
)}

function ModeBetSizing() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeBetSizing: !Main.Instance.PlayerGroupsInternal.modeBetSizing
    }
)}
*/
function ModeDeposit() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeDeposit: !Main.Instance.PlayerGroupsInternal.modeDeposit,
    },
)}

function ModeWithdrawal() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        modeWithdrawal: !Main.Instance.PlayerGroupsInternal.modeWithdrawal,
    },
)}

function isSelected(array: any[], Item: any): boolean {
    if (array) {
        for (const item of array) {
            if (Item === item) {
                return true
            }
        }
    }
    return false
}
/*
function isSelectedID(array: any[], Item: any): number {
    if (array) {
        for (let i: number = 0; i < array.length; i++) {
            if (Item === array[i].id) {
                return i + 1
            }
        }
    }
    return 0
}
*/
/*
function toggleGame(id: number) {
    const games = Object.assign ([], Main.Instance.PlayerGroupsState.games)
    let found: boolean = false
    for (let i: number = 0; i < games.length; i++) {
        if (games[i] === id) {
            games.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        games.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {games})
}
*/
function toggleGender(id: string) {
    const gender = Object.assign ([], Main.Instance.PlayerGroupsState.gender)
    let found: boolean = false
    for (let i: number = 0; i < gender.length; i++) {
        if (gender[i] === id) {
            gender.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        gender.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {gender})
}

function toggleAgeGroup(id: string) {
    const ageGroup = Object.assign ([], Main.Instance.PlayerGroupsState.ageGroup)
    let found: boolean = false
    for (let i: number = 0; i < ageGroup.length; i++) {
        if (ageGroup[i] === id) {
            ageGroup.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        ageGroup.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {ageGroup})
}
/*
function toggleBalanceGroup(id: string) {
    const balanceGroup = Object.assign ([], Main.Instance.PlayerGroupsState.balanceGroup)
    let found: boolean = false
    for (let i: number = 0; i < balanceGroup.length; i++) {
        if (balanceGroup[i] === id) {
            balanceGroup.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        balanceGroup.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {balanceGroup})
}
*/
function toggleCalendarYear(id: string) {
    const calendarYear = Object.assign ([], Main.Instance.PlayerGroupsState.calendarYear)
    let found: boolean = false
    for (let i: number = 0; i < calendarYear.length; i++) {
        if (calendarYear[i] === id) {
            calendarYear.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarYear.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYear})
}

function toggleCalendarYearMax(id: string) {
    const calendarYearMax = Object.assign ([], Main.Instance.PlayerGroupsState.calendarYearMax)
    let found: boolean = false
    for (let i: number = 0; i < calendarYearMax.length; i++) {
        if (calendarYearMax[i] === id) {
            calendarYearMax.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarYearMax.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarYearMax})
}

function toggleCalendarMonth(id: string) {
    const calendarMonth = Object.assign ([], Main.Instance.PlayerGroupsState.calendarMonth)
    let found: boolean = false
    for (let i: number = 0; i < calendarMonth.length; i++) {
        if (calendarMonth[i] === id) {
            calendarMonth.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarMonth.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonth})
}

function toggleCalendarMonthMax(id: string) {
    const calendarMonthMax = Object.assign ([], Main.Instance.PlayerGroupsState.calendarMonthMax)
    let found: boolean = false
    for (let i: number = 0; i < calendarMonthMax.length; i++) {
        if (calendarMonthMax[i] === id) {
            calendarMonthMax.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarMonthMax.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthMax})
}

function toggleCalendarWeekDays(id: string) {
    const calendarWeekDays = Object.assign ([], Main.Instance.PlayerGroupsState.calendarWeekDays)
    let found: boolean = false
    for (let i: number = 0; i < calendarWeekDays.length; i++) {
        if (calendarWeekDays[i] === id) {
            calendarWeekDays.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarWeekDays.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDays})
}

function toggleCalendarWeekDaysMax(id: string) {
    const calendarWeekDaysMax = Object.assign ([], Main.Instance.PlayerGroupsState.calendarWeekDaysMax)
    let found: boolean = false
    for (let i: number = 0; i < calendarWeekDaysMax.length; i++) {
        if (calendarWeekDaysMax[i] === id) {
            calendarWeekDaysMax.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarWeekDaysMax.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarWeekDaysMax})
}

function toggleCalendarMonthDays(id: string) {
    const calendarMonthDays = Object.assign ([], Main.Instance.PlayerGroupsState.calendarMonthDays)
    let found: boolean = false
    for (let i: number = 0; i < calendarMonthDays.length; i++) {
        if (calendarMonthDays[i] === id) {
            calendarMonthDays.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarMonthDays.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDays})
}

function toggleCalendarMonthDaysMax(id: string) {
    const calendarMonthDaysMax = Object.assign ([], Main.Instance.PlayerGroupsState.calendarMonthDaysMax)
    let found: boolean = false
    for (let i: number = 0; i < calendarMonthDaysMax.length; i++) {
        if (calendarMonthDaysMax[i] === id) {
            calendarMonthDaysMax.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        calendarMonthDaysMax.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {calendarMonthDaysMax})
}


function getCalendarYear(max?: boolean): JSX.Element[] {
    const calendar: JSX.Element[] = []

    for (const item of DataStore.years) {
        calendar.push (
            <a
                className = {isSelected(max ? Main.Instance.PlayerGroupsState.calendarYearMax : Main.Instance.PlayerGroupsState.calendarYear, item) ? 'pg-calendar active' : 'pg-calendar'}
                onClick = {() => max ? toggleCalendarYearMax(item) : toggleCalendarYear(item)}
                key = {item}
                style = {{fontSize: '14px', float: 'left', display: 'block', padding: '0px 10px'}}
            >
                {item}
            </a>,
        )
    }

    if (max) {
        if (Main.Instance.PlayerGroupsState.calendarYearMax.length === 0) {
            calendar.push (<div key = "any_" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyYear.toLowerCase()}</div>)
        } else { calendar.push (<div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarYearMax.length, 'calendarYearMax', Main.Instance.PlayerGroupsState.calendarYearLogicMax)}</div>) }

    } else {
        if (Main.Instance.PlayerGroupsState.calendarYear.length === 0) {
            calendar.push (<div key = "any" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyYear.toLowerCase()}</div>)
        } else { calendar.push (<div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarYear.length, 'calendarYear', Main.Instance.PlayerGroupsState.calendarYearLogic)}</div>) }
    }
    return calendar
}

function getWithinDeposit(): JSX.Element[] {
    const withinDeposit: JSX.Element[] = []
    withinDeposit.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('withinDeposit', Main.Instance.PlayerGroupsState.withinDepositLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.withinDepositLogic === 'ignore') {
        withinDeposit.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.Anytime.toLowerCase()}</div>)
    } else {
        withinDeposit.push (
            <input
                className = "pg-money fl ml"
                placeholder = "within deposit"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleWithinDeposit(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.withinDeposit !== undefined ? Main.Instance.PlayerGroupsState.withinDeposit.toString() : ''}
            />,
        )
        withinDeposit.push (<div className = "clearfix fl ml">{Assets.DaysOfLastActivity}</div>)
        // withinDeposit.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minWithinCurrency', Main.Instance.PlayerGroupsState.withinDepositCurrencyLogic)}</div>)
    }
    return withinDeposit
}

function getWithinWithdrawal(): JSX.Element[] {
    const withinWithdrawal: JSX.Element[] = []
    withinWithdrawal.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('withinWithdrawal', Main.Instance.PlayerGroupsState.withinWithdrawalLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.withinWithdrawalLogic === 'ignore') {
        withinWithdrawal.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.Anytime.toLowerCase()}</div>)
    } else {
        withinWithdrawal.push (
            <input
                className = "pg-money fl ml"
                placeholder = "within withdrawal"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleWithinWithdrawal(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.withinWithdrawal !== undefined ? Main.Instance.PlayerGroupsState.withinWithdrawal.toString() : ''}
            />,
        )
        withinWithdrawal.push (<div className = "clearfix fl ml">{Assets.Days.toLowerCase()}</div>)
        // withinWithdrawal.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minWithinCurrency', Main.Instance.PlayerGroupsState.withinDepositCurrencyLogic)}</div>)
    }
    return withinWithdrawal
}

function getMinDeposit(): JSX.Element[] {
    const minDeposit: JSX.Element[] = []
    minDeposit.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minDeposit', Main.Instance.PlayerGroupsState.minDepositLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minDepositLogic === 'ignore') {
        minDeposit.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumDeposit.toLowerCase()}</div>)
    } else {
        minDeposit.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. deposit"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinDeposit(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minDeposit !== undefined ? Main.Instance.PlayerGroupsState.minDeposit.toString() : ''}
            />,
        )

        minDeposit.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minDepositCurrency', Main.Instance.PlayerGroupsState.minDepositCurrencyLogic)}</div>)
    }
    return minDeposit
}

function getMaxDeposit(): JSX.Element[] {
    const maxDeposit: JSX.Element[] = []
    maxDeposit.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxDeposit', Main.Instance.PlayerGroupsState.maxDepositLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxDepositLogic === 'ignore') {
        maxDeposit.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumDeposit.toLowerCase()}</div>)
    } else {
        maxDeposit.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. deposit"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxDeposit(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxDeposit !== undefined ? Main.Instance.PlayerGroupsState.maxDeposit.toString() : ''}
            />,
        )

        maxDeposit.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('maxDepositCurrency', Main.Instance.PlayerGroupsState.maxDepositCurrencyLogic)}</div>)
    }
    return maxDeposit
}

function getMinWithdrawal(): JSX.Element[] {
    const minWithdrawal: JSX.Element[] = []
    minWithdrawal.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minWithdrawal', Main.Instance.PlayerGroupsState.minWithdrawalLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minWithdrawalLogic === 'ignore') {
        minWithdrawal.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMinimumWithdrawal.toLowerCase()}</div>)
    } else {
        minWithdrawal.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. withdrawal"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinWithdrawal(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minWithdrawal !== undefined ? Main.Instance.PlayerGroupsState.minWithdrawal.toString() : ''}
            />,
        )

        minWithdrawal.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minWithdrawalCurrency', Main.Instance.PlayerGroupsState.minWithdrawalCurrencyLogic)}</div>)
    }
    return minWithdrawal
}

function getMaxWithdrawal(): JSX.Element[] {
    const maxWithdrawal: JSX.Element[] = []
    maxWithdrawal.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxWithdrawal', Main.Instance.PlayerGroupsState.maxWithdrawalLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxWithdrawalLogic === 'ignore') {
        maxWithdrawal.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumWithdrawal.toLowerCase()}</div>)
    } else {
        maxWithdrawal.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. withdrawal"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxWithdrawal(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxWithdrawal !== undefined ? Main.Instance.PlayerGroupsState.maxWithdrawal.toString() : ''}
            />,
        )

        maxWithdrawal.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('maxWithdrawalCurrency', Main.Instance.PlayerGroupsState.maxWithdrawalCurrencyLogic)}</div>)
    }
    return maxWithdrawal
}

function getMinBet(): JSX.Element[] {
    const minBet: JSX.Element[] = []
    minBet.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minBet', Main.Instance.PlayerGroupsState.minBetLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minBetLogic === 'ignore') {
        minBet.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMinimumBet.toLowerCase()}</div>)
    } else {
        minBet.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. bet"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinBet(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minBet !== undefined ? Main.Instance.PlayerGroupsState.minBet.toString() : ''}
            />,
        )

        minBet.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minBetCurrency', Main.Instance.PlayerGroupsState.minBetCurrencyLogic)}</div>)
    }
    return minBet
}

function getMaxBet(): JSX.Element[] {
    const maxBet: JSX.Element[] = []
    maxBet.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxBet', Main.Instance.PlayerGroupsState.maxBetLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxBetLogic === 'ignore') {
        maxBet.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumBet.toLowerCase()}</div>)
    } else {
        maxBet.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. bet"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxBet(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxBet !== undefined ? Main.Instance.PlayerGroupsState.maxBet.toString() : ''}
            />,
        )

        maxBet.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('maxBetCurrency', Main.Instance.PlayerGroupsState.maxBetCurrencyLogic)}</div>)
    }
    return maxBet
}

function getMinWin(): JSX.Element[] {
    const minWin: JSX.Element[] = []
    minWin.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minWin', Main.Instance.PlayerGroupsState.minWinLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minWinLogic === 'ignore') {
        minWin.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMinimumWin.toLowerCase()}</div>)
    } else {
        minWin.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. win"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinWin(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minWin !== undefined ? Main.Instance.PlayerGroupsState.minWin.toString() : ''}
            />,
        )

        minWin.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('minWinCurrency', Main.Instance.PlayerGroupsState.minWinCurrencyLogic)}</div>)
    }
    return minWin
}

function getMaxWin(): JSX.Element[] {
    const maxWin: JSX.Element[] = []
    maxWin.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxWin', Main.Instance.PlayerGroupsState.maxWinLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxWinLogic === 'ignore') {
        maxWin.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumWin}</div>)
    } else {
        maxWin.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. win"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxWin(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxWin !== undefined ? Main.Instance.PlayerGroupsState.maxWin.toString() : ''}
            />,
        )

        maxWin.push (<div key = "logicCurrency" className = "clearfix fl">{getLogicCurrency('maxWinCurrency', Main.Instance.PlayerGroupsState.maxWinCurrencyLogic)}</div>)
    }
    return maxWin
}

function getMinBets(): JSX.Element[] {
    const minBets: JSX.Element[] = []
    minBets.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minBetsSession', Main.Instance.PlayerGroupsState.minBetsSessionLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minBetsSessionLogic === 'ignore') {
        minBets.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMinimumSessionBets.toLowerCase()}</div>)
    } else {
        minBets.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. bets"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinBetsSession(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minBetsSession !== undefined ? Main.Instance.PlayerGroupsState.minBetsSession.toString() : ''}
            />,
        )

        minBets.push (<div key = "logicCurrency" className = "clearfix fl ml">{Assets.SessionBets.toLowerCase()}</div>)
    }
    return minBets
}

function getMaxBets(): JSX.Element[] {
    const maxBets: JSX.Element[] = []
    maxBets.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxBetsSession', Main.Instance.PlayerGroupsState.maxBetsSessionLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxBetsSessionLogic === 'ignore') {
        maxBets.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumSessionBets.toLowerCase()}</div>)
    } else {
        maxBets.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. bets"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxBetsSession(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxBetsSession !== undefined ? Main.Instance.PlayerGroupsState.maxBetsSession.toString() : ''}
            />,
        )

        maxBets.push (<div key = "logicCurrency" className = "clearfix fl ml">{Assets.SessionBets}</div>)
    }
    return maxBets
}

function getMinTime(): JSX.Element[] {
    const minTime: JSX.Element[] = []
    minTime.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('minTimeSession', Main.Instance.PlayerGroupsState.minTimeSessionLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.minTimeSessionLogic === 'ignore') {
        minTime.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMinimumSessionTime.toLowerCase()}</div>)
    } else {
        minTime.push (
            <input
                className = "pg-money fl ml"
                placeholder = "min. time"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMinTimeSession(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.minTimeSession !== undefined ? Main.Instance.PlayerGroupsState.minTimeSession.toString() : ''}
            />,
        )

        minTime.push (<div key = "logicCurrency" className = "clearfix fl ml">{Assets.Minutes.toLowerCase()}</div>)
    }
    return minTime
}

function getMaxTime(): JSX.Element[] {
    const maxTime: JSX.Element[] = []
    maxTime.push (<div key = "logicComparison" className = "clearfix fl">{getLogicComparison('maxTimeSession', Main.Instance.PlayerGroupsState.maxTimeSessionLogic)}</div>)
    if (Main.Instance.PlayerGroupsState.maxTimeSessionLogic === 'ignore') {
        maxTime.push (<div key = "any" style = {{fontSize: '14px'}} className = "fl label label-info ml">{Assets.AnyMaximumSessionTime.toLowerCase()}</div>)
    } else {
        maxTime.push (
            <input
                className = "pg-money fl ml"
                placeholder = "max. time"
                key = "money"
                min = "0"
                type = "number"
                onChange = {(e) => handleMaxTimeSession(e.target.value)}
                value = {Main.Instance.PlayerGroupsState.maxTimeSession !== undefined ? Main.Instance.PlayerGroupsState.maxTimeSession.toString() : ''}
            />,
        )

        maxTime.push (<div key = "logicCurrency" className = "clearfix fl ml">{Assets.Minutes.toLowerCase()}</div>)
    }
    return maxTime
}

function getCalendarMonth(max?: boolean): JSX.Element[] {
    const calendar: JSX.Element[] = []

    for (const item of DataStore.months) {
        calendar.push (
            <a
                className = {isSelected(max ? Main.Instance.PlayerGroupsState.calendarMonthMax : Main.Instance.PlayerGroupsState.calendarMonth, item) ? 'pg-calendar active' : 'pg-calendar'}
                onClick = {() => max ? toggleCalendarMonthMax(item) : toggleCalendarMonth(item)}
                key = {item}
                style = {{fontSize: '14px', float: 'left', display: 'block', padding: '0px 10px'}}
            >
                {item}
            </a>,
        )
    }
    if (max) {
        if (Main.Instance.PlayerGroupsState.calendarMonthMax.length === 0) {
            calendar.push (<div key = "any" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyMonth.toLowerCase()}</div>)
        } else { calendar.push (<div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarMonthMax.length, 'calendarMonthMax', Main.Instance.PlayerGroupsState.calendarMonthLogicMax)}</div>) }
    } else {
        if (Main.Instance.PlayerGroupsState.calendarMonth.length === 0) {
            calendar.push (<div key = "any" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyMonth.toLowerCase()}</div>)
        } else { calendar.push (<div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarMonth.length, 'calendarMonth', Main.Instance.PlayerGroupsState.calendarMonthLogic)}</div>) }
    }
    return calendar
}

function getCalendarWeekDays(max?: boolean): JSX.Element[] {
    const calendar: JSX.Element[] = []

    for (const item of Assets.DaysInWeek) {
        calendar.push (
            <a
                className = {isSelected(max ? Main.Instance.PlayerGroupsState.calendarWeekDaysMax : Main.Instance.PlayerGroupsState.calendarWeekDays, item) ? 'pg-calendar active' : 'pg-calendar'}
                onClick = {() => max ? toggleCalendarWeekDaysMax(item) : toggleCalendarWeekDays(item)}
                key = {item}
                style = {{fontSize: '14px', float: 'left', display: 'block', padding: '0px 10px'}}
            >
                {item}
            </a>,
        )
    }

    if (max) {
        if (Main.Instance.PlayerGroupsState.calendarWeekDaysMax.length === 0) {
            calendar.push (<div key = "any_" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyWeekday.toLowerCase()}</div>)
        } else { calendar.push (
            <div key = "logic_">{getLogic(Main.Instance.PlayerGroupsState.calendarWeekDaysMax.length, 'calendarWeekDaysMax', Main.Instance.PlayerGroupsState.calendarWeekDaysLogicMax)}</div>,
        )
        }
    } else {
        if (Main.Instance.PlayerGroupsState.calendarWeekDays.length === 0) {
            calendar.push (<div key = "any" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyWeekday.toLowerCase()}</div>)
        } else { calendar.push (
            <div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarWeekDays.length, 'calendarWeekDays', Main.Instance.PlayerGroupsState.calendarWeekDaysLogic)}</div>,
        )
        }    
    }

    return calendar
}

function getTimes(max?: boolean): JSX.Element[] {
    const calendar: JSX.Element[] = []
    calendar.push (<span className = "ml" key = "1">{Assets.BetweenIncluding.toLowerCase()}&nbsp;</span>)
    calendar.push(
        <input
            className = "pg-time"
            placeholder = "hrs"
            key = "hrs-min"
            min = "-1"
            max = "24"
            type = "number"
            onChange = {(e) => max ? handleTimesHoursMinMAX(e.target.value) : handleTimesHoursMin(e.target.value)}
            value = {max ? 
                Main.Instance.PlayerGroupsState.timesHoursMinMAX !== undefined ? Main.Instance.PlayerGroupsState.timesHoursMinMAX < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesHoursMinMAX : Main.Instance.PlayerGroupsState.timesHoursMinMAX.toString() : ''                
                :
                Main.Instance.PlayerGroupsState.timesHoursMin !== undefined ? Main.Instance.PlayerGroupsState.timesHoursMin < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesHoursMin : Main.Instance.PlayerGroupsState.timesHoursMin.toString() : ''
            }
        />,
    )
    calendar.push (<span key = "2">&nbsp;:&nbsp;</span>)
    calendar.push(
        <input
            className = "pg-time"
            placeholder = "min"
            key = "min-min"
            min = "-1"
            max = "60"
            type = "number"
            onChange = {(e) => max ? handleTimesMinutesMinMAX(e.target.value) : handleTimesMinutesMin(e.target.value)}
            value = {max ? 
                Main.Instance.PlayerGroupsState.timesMinutesMinMAX !== undefined ? Main.Instance.PlayerGroupsState.timesMinutesMinMAX < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesMinutesMinMAX : Main.Instance.PlayerGroupsState.timesMinutesMinMAX.toString() : ''                
                :
                Main.Instance.PlayerGroupsState.timesMinutesMin !== undefined ? Main.Instance.PlayerGroupsState.timesMinutesMin < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesMinutesMin : Main.Instance.PlayerGroupsState.timesMinutesMin.toString() : ''
            }
        />,
    )
    calendar.push (<span key = "3">&nbsp;{Assets.AndExcluding.toLowerCase()}&nbsp;</span>)
    calendar.push(
        <input
            className = "pg-time"
            placeholder = "hrs"
            key = "hrs-max"
            min = "-1"
            max = "24"
            type = "number"
            onChange = {(e) => max ? handleTimesHoursMaxMAX(e.target.value) : handleTimesHoursMax(e.target.value)}
            value = {max ? 
                Main.Instance.PlayerGroupsState.timesHoursMaxMAX !== undefined ? Main.Instance.PlayerGroupsState.timesHoursMaxMAX < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesHoursMaxMAX : Main.Instance.PlayerGroupsState.timesHoursMaxMAX.toString() : ''
                :
                Main.Instance.PlayerGroupsState.timesHoursMax !== undefined ? Main.Instance.PlayerGroupsState.timesHoursMax < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesHoursMax : Main.Instance.PlayerGroupsState.timesHoursMax.toString() : ''
            }
        />,
    )
    calendar.push (<span key = "4">&nbsp;:&nbsp;</span>)
    calendar.push(
        <input
            className = "pg-time"
            placeholder = "min"
            key = "min-max"
            min = "-1"
            max = "60"
            type = "number"
            onChange = {(e) => max ? handleTimesMinutesMaxMAX(e.target.value) : handleTimesMinutesMax(e.target.value)}
            value = {max ? 
                Main.Instance.PlayerGroupsState.timesMinutesMaxMAX !== undefined ? Main.Instance.PlayerGroupsState.timesMinutesMaxMAX < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesMinutesMaxMAX : Main.Instance.PlayerGroupsState.timesMinutesMaxMAX.toString() : ''                
                :
                Main.Instance.PlayerGroupsState.timesMinutesMax !== undefined ? Main.Instance.PlayerGroupsState.timesMinutesMax < 10 ?
                '0' + Main.Instance.PlayerGroupsState.timesMinutesMax : Main.Instance.PlayerGroupsState.timesMinutesMax.toString() : ''
            }
        />,
    )
    if (max) {
        if (Main.Instance.PlayerGroupsState.timesMinutesMaxMAX || Main.Instance.PlayerGroupsState.timesHoursMaxMAX ||
            Main.Instance.PlayerGroupsState.timesHoursMinMAX || Main.Instance.PlayerGroupsState.timesMinutesMinMAX) {
            calendar.push (<button key = "any_" style = {{fontSize: '14px', lineHeight: 1}} className = "btn btn-danger btn-sm ml" onClick = {() => mNull()}>{Assets.Ignore.toLowerCase()}</button>)
        } else { calendar.push (<span key = "any" style = {{fontSize: '14px'}} className = "label label-info ml">{Assets.AnyTimeOfDay.toLowerCase()}</span>) }
    } else {
        if (Main.Instance.PlayerGroupsState.timesMinutesMax || Main.Instance.PlayerGroupsState.timesHoursMax ||
            Main.Instance.PlayerGroupsState.timesHoursMin || Main.Instance.PlayerGroupsState.timesMinutesMin) {
            calendar.push (<button key = "any_" style = {{fontSize: '14px', lineHeight: 1}} className = "btn btn-danger btn-sm ml" onClick = {() => tNull()}>{Assets.Ignore.toLowerCase()}</button>)
        } else { calendar.push (<span key = "any" style = {{fontSize: '14px'}} className = "label label-info ml">{Assets.AnyTimeOfDay.toLowerCase()}</span>) }
    }
    return calendar
}

function mNull() {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMaxMAX: 0, timesHoursMaxMAX: 0, timesHoursMinMAX: 0, timesMinutesMinMAX: 0})
}

function tNull() {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMax: 0, timesHoursMax: 0, timesHoursMin: 0, timesMinutesMin: 0})
}

function getCalendarMonthDays(max?: boolean): JSX.Element[] {
    const calendar: JSX.Element[] = []

    for (const item of Assets.DaysInAMonth2) {
        calendar.push (
            <a
                className = {isSelected(max ? Main.Instance.PlayerGroupsState.calendarMonthDaysMax : Main.Instance.PlayerGroupsState.calendarMonthDays, item) ?
                            'pg-calendar active mlf5' : 'pg-calendar mlf5'}
                onClick = {() => max ? toggleCalendarMonthDaysMax(item) : toggleCalendarMonthDays(item)}
                key = {item}
                style = {{fontSize: '14px', float: 'left', display: 'block', padding: '0px 5px'}}
            >
                {item}
            </a>,
        )
    }

    if (max) {
        if (Main.Instance.PlayerGroupsState.calendarMonthDaysMax.length === 0) {
            calendar.push (<div key = "any_" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyMonthDay.toLowerCase()}</div>)
        } else { calendar.push (
        <div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarMonthDaysMax.length, 'calendarMonthDaysMax', Main.Instance.PlayerGroupsState.calendarMonthDaysLogicMax)}</div>)
        }    
    } else {
        if (Main.Instance.PlayerGroupsState.calendarMonthDays.length === 0) {
            calendar.push (<div key = "any" style = {{fontSize: '14px', float: 'left'}} className = "label label-info">{Assets.AnyMonthDay.toLowerCase()}</div>)
        } else { calendar.push (
            <div key = "logic">{getLogic(Main.Instance.PlayerGroupsState.calendarMonthDays.length, 'calendarMonthDays', Main.Instance.PlayerGroupsState.calendarMonthDaysLogic)}</div>,
        )
        }    
    }

    return calendar
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.PlayerGroup + ' ' + Assets.withName + ' "' + row.original.data.title + '"?')) {
            if (Main.Instance.PlayerGroupsInternal.pNum === (Math.trunc((Main.Instance.PlayerGroupsInternal.count - 2) / Main.Instance.PlayerGroupsInternal.pSize) + 1)) {
                DbRoutes.wsBasicDelete(Config.amPlayerGroups, row.original.id)
                // DbRoutes.RemovePlayerGroup (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.wsBasicDelete(Config.amPlayerGroups, row.original.id)
                // DbRoutes.RemovePlayerGroup (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function handleEdit(row: any) { 
    gotoTop()
   
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        mode: true,
        id: row.original.id,
        rule: row.original.data,
    })
    
    Logic.Type.New (Main.Instance.PlayerGroupsState, {
        // GENERAL
        title: row.original.data.title,
        title_saved: row.original.data.title,
        priority: row.original.data.priority,
        staticGroup: row.original.data.staticGroup,
        staticTimestamp: row.original.data.staticTimestamp,
        staticMembers: row.original.data.staticMembers,
        allowLowerPriority: row.original.data.allowLowerPriority,
        // backgroundColor: string
        // color: string

        // DEPOSIT SIZING
        depositLogic: row.original.data.depositLogic,
        depositLogicMin: row.original.data.depositLogicMin,
        depositLogicMax: row.original.data.depositLogicMax,
        
        minDepositLogic: row.original.data.minDepositLogic,
        minDepositCurrencyLogic: row.original.data.minDepositCurrencyLogic,
        minDeposit: row.original.data.minDeposit,
    
        maxDepositLogic: row.original.data.maxDepositLogic,
        maxDepositCurrencyLogic: row.original.data.maxDepositCurrencyLogic,
        maxDeposit: row.original.data.maxDeposit,

        withinDepositLogic: row.original.data.withinDepositLogic,
        withinDeposit: row.original.data.withinDeposit,

        // WITHDRAW SIZING
        withdrawalLogic: row.original.data.withdrawalLogic,
        withdrawalLogicMin: row.original.data.withdrawalLogicMin,
        withdrawalLogicMax: row.original.data.withdrawalLogicMax,
        
        minWithdrawalLogic: row.original.data.minWithdrawalLogic,
        minWithdrawalCurrencyLogic: row.original.data.minWithdrawalCurrencyLogic,
        minWithdrawal: row.original.data.minWithdrawal,
    
        maxWithdrawalLogic: row.original.data.maxWithdrawalLogic,
        maxWithdrawalCurrencyLogic: row.original.data.maxWithdrawalCurrencyLogic,
        maxWithdrawal: row.original.data.maxWithdrawal,

        withinWithdrawalLogic: row.original.data.withinWithdrawalLogic,
        withinWithdrawal: row.original.data.withinWithdrawal,

        // BET SIZING
        betLogic: row.original.data.betLogic,
        betLogicMin: row.original.data.betLogicMin,
        betLogicMax: row.original.data.betLogicMax,
        
        minBetLogic: row.original.data.minBetLogic,
        minBetCurrencyLogic: row.original.data.minBetCurrencyLogic,
        minBet: row.original.data.minBet,
    
        maxBetLogic: row.original.data.maxBetLogic,
        maxBetCurrencyLogic: row.original.data.maxBetCurrencyLogic,
        maxBet: row.original.data.maxBet,

        winLogic: row.original.data.winLogic,
        winLogicMin: row.original.data.winLogicMin,
        winLogicMax: row.original.data.winLogicMax,

        minWinLogic: row.original.data.minWinLogic,
        minWinCurrencyLogic: row.original.data.minWinCurrencyLogic,
        minWin: row.original.data.minWin,
    
        maxWinLogic: row.original.data.maxWinLogic,
        maxWinCurrencyLogic: row.original.data.maxWinCurrencyLogic,
        maxWin: row.original.data.maxWin,
    
        // SESSION
        betsSessionLogic: row.original.data.betsSessionLogic,
        betsSessionLogicMin: row.original.data.betsSessionLogicMin,
        betsSessionLogicMax: row.original.data.betsSessionLogicMax,
    
        minBetsSessionLogic: row.original.data.minBetsSessionLogic,
        minBetsSession: row.original.data.minBetsSession,
    
        maxBetsSessionLogic: row.original.data.maxBetsSessionLogic,
        maxBetsSession: row.original.data.maxBetsSession,
    
        timeSessionLogic: row.original.data.timeSessionLogic,
        timeSessionLogicMin: row.original.data.timeSessionLogicMin,
        timeSessionLogicMax: row.original.data.timeSessionLogicMax,
    
        minTimeSessionLogic: row.original.data.minTimeSessionLogic,
        minTimeSession: row.original.data.minTimeSession,
    
        maxTimeSessionLogic: row.original.data.maxTimeSessionLogic,
        maxTimeSession: row.original.data.maxTimeSession,
    
        // FREQUENCY
        frequencyLogic: row.original.data.frequencyLogic,
        frequencyLogic0: row.original.data.frequencyLogic0,
    
        // CALENDAR
        interval: row.original.data.interval,
        calendarYear: row.original.data.calendarYear,
        calendarYearLogic: row.original.data.calendarYearLogic,
        calendarMonth: row.original.data.calendarMonth,
        calendarMonthLogic: row.original.data.calendarMonthLogic,
        calendarWeekDays: row.original.data.calendarWeekDays,
        calendarWeekDaysLogic: row.original.data.calendarWeekDaysLogic,
        calendarMonthDays: row.original.data.calendarMonthDays,
        calendarMonthDaysLogic: row.original.data.calendarMonthDaysLogic,
        timesHoursMin: row.original.data.timesHoursMin,
        timesHoursMax: row.original.data.timesHoursMax,
        timesMinutesMin: row.original.data.timesMinutesMin,
        timesMinutesMax: row.original.data.timesMinutesMax,
    
        calendarYearMax: row.original.data.calendarYearMax,
        calendarYearLogicMax: row.original.data.calendarYearLogicMax,
        calendarMonthMax: row.original.data.calendarMonthMax,
        calendarMonthLogicMax: row.original.data.calendarMonthLogicMax,
        calendarWeekDaysMax: row.original.data.calendarWeekDaysMax,
        calendarWeekDaysLogicMax: row.original.data.calendarWeekDaysLogicMax,
        calendarMonthDaysMax: row.original.data.calendarMonthDaysMax,
        calendarMonthDaysLogicMax: row.original.data.calendarMonthDaysLogicMax,
        timesHoursMinMAX: row.original.data.timesHoursMinMAX,
        timesHoursMaxMAX: row.original.data.timesHoursMaxMAX,
        timesMinutesMinMAX: row.original.data.timesMinutesMinMAX,
        timesMinutesMaxMAX: row.original.data.timesMinutesMaxMAX,
        
        // GAMES
        games: row.original.data.games,
        gamesLogic: row.original.data.gamesLogic,
    
        // PLAYERS
        levelsLogic: row.original.data.levelsLogic,
        levels: row.original.data.levels,
        timezones: row.original.data.timezones,
        timezonesLogic: row.original.data.timezonesLogic,
        // continents: row.original.data.continents,
        // continentsLogic: row.original.data.continentsLogic,
        nationalities: row.original.data.nationalities,
        nationalitiesLogic: row.original.data.nationalitiesLogic,
        regions: row.original.data.regions,
        Regions: row.original.data.Regions,
        regionsLogic: row.original.data.regionsLogic,
        countries: row.original.data.countries,
        countriesLogic: row.original.data.countriesLogic,
        currencies: row.original.data.currencies,
        currenciesLogic: row.original.data.currenciesLogic,
        gender: row.original.data.gender,
        genderLogic: row.original.data.genderLogic,
        ageGroup: row.original.data.ageGroup,
        ageGroupLogic: row.original.data.ageGroupLogic,
        ageGroupMin: row.original.data.ageGroupMin,
        ageGroupMax: row.original.data.ageGroupMax,
/*
        balanceGroup: row.original.data.balanceGroup,
        balanceGroupLogic: row.original.data.balanceGroupLogic,
        balanceGroupMin: row.original.data.balanceGroupMin,
        balanceGroupMax: row.original.data.balanceGroupMax,
*/
    })
}
/*
function getGames(): JSX.Element {
    const games: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.crmGamesStream) {
    for (const item of Main.Instance.DatabaseDataState.crmGamesStream) {
        if (item === undefined) { continue }
        games.push (
            <a key = {item.id} onClick = {() => toggleGame(+item.id)} className = {isSelected(Main.Instance.PlayerGroupsState.games, +item.id) ? 'pg-game' : 'pg-game Grayscale'}>
                <img src = {item.image}/>
                <span>{item.game}</span>
            </a>,
        )
    }
    }
    return <div className = "clearfix">{games}</div>
}
*/
function toggleCountry(id: string) {
    const countries = Object.assign ([], Main.Instance.PlayerGroupsState.countries)
    let found: boolean = false
    for (let i: number = 0; i < countries.length; i++) {
        if (countries[i] === id) {
            countries.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        countries.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {countries})
}
/*
function toggleContinent(id: string) {
    const continents = Object.assign ([], Main.Instance.PlayerGroupsState.continents)
    let found: boolean = false
    for (let i: number = 0; i < continents.length; i++) {
        if (continents[i] === id) {
            continents.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        continents.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {continents})
}
*/
function toggleRegion(id: string, Id: string) {
    const regions = Object.assign ([], Main.Instance.PlayerGroupsState.regions)
    const Regions = Object.assign ([], Main.Instance.PlayerGroupsState.Regions)
    let found: boolean = false
    for (let i: number = 0; i < regions.length; i++) {
        if (regions[i] === id && Regions[i] === Id) {
            regions.splice (i, 1)
            Regions.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        regions.push (id)
        Regions.push (Id)
    }
    // console.log (regions)
    // console.log (Regions)
    Logic.Type.New (Main.Instance.PlayerGroupsState, {regions, Regions})
}

function toggleNationality(id: string) {
    const nationalities = Object.assign ([], Main.Instance.PlayerGroupsState.nationalities)
    let found: boolean = false
    for (let i: number = 0; i < nationalities.length; i++) {
        if (nationalities[i] === id) {
            nationalities.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        nationalities.push (id)
    }
    // console.log (nationalities)
    Logic.Type.New (Main.Instance.PlayerGroupsState, {nationalities})
}

/*
function toggleTimezone(id: string) {
    const timezones = Object.assign ([], Main.Instance.PlayerGroupsState.timezones)
    let found: boolean = false
    for (let i: number = 0; i < timezones.length; i++) {
        if (timezones[i] === id) {
            timezones.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        timezones.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {timezones})
}
*/
/*
function toggleLevel(id: string) {
    const levels = Object.assign ([], Main.Instance.PlayerGroupsState.levels)
    let found: boolean = false
    for (let i: number = 0; i < levels.length; i++) {
        if (levels[i].id === id) {
            levels.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        levels.push ({id, min: 0, max: 0})
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {levels})
}
*/
/*
function toggleSublevel(id: string) {
    let sublevels = Object.assign ([], Main.Instance.PlayerGroupsState.sublevels)
    let found: boolean = false
    for (let i: number = 0; i < sublevels.length; i++) {
        if (sublevels[i] === id) {
            sublevels.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        sublevels.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {sublevels})
}
*/

function toggleCurrency(id: string) {
    const currencies = Object.assign ([], Main.Instance.PlayerGroupsState.currencies)
    let found: boolean = false
    for (let i: number = 0; i < currencies.length; i++) {
        if (currencies[i] === id) {
            currencies.splice (i, 1)
            found = true
            break
        }
    }
    if (!found) {
        currencies.push (id)
    }

    Logic.Type.New (Main.Instance.PlayerGroupsState, {currencies})
}

/*
function getRegions(): JSX.Element {
    // console.log (Main.Instance.DatabaseDataState.crmPlayerDataStream)
    // console.log (Main.Instance.DatabaseDataState.crmPlayersStream)
    // console.log (1395)
    const regions: JSX.Element[] = []
    return (<div className = "clearfix">{regions}</div>)
}
*/
function getCountries(): JSX.Element {
    const countries: JSX.Element[] = []
    // console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)
    if (Main.Instance.DatabaseDataState.crmCountriesStream && Main.Instance.DatabaseDataState.FlagsStreamAll) {
    // console.log (Main.Instance.DatabaseDataState.crmCountriesStream)
    for (const item of Main.Instance.DatabaseDataState.crmCountriesStream) {
        if (item === undefined) { continue }
        let Item = {image: '', name: 'unknown'}
        for (const ITEM of Main.Instance.DatabaseDataState.FlagsStreamAll) {
            if (ITEM['code'] && ITEM['code'].toUpperCase() === item.country || ITEM['code2'] && ITEM['code2'].toUpperCase() === item.country) {
                Item = ITEM as {image: string, name: string}
                break
            }
        }
        // console.log (item)
/*
        let found: boolean = true
        if (Main.Instance.PlayerGroupsState.continents.length > 0) {
            if (Main.Instance.PlayerGroupsState.continentsLogic === undefined) {
                found = false
                for (const ITEM of Main.Instance.PlayerGroupsState.continents) {
                    if (ITEM === item.continent) {
                        found = true
                        break
                    }
                }
            } else {
                for (const ITEM of Main.Instance.PlayerGroupsState.continents) {
                    if (ITEM === item.continent) {
                        found = false
                        break
                    }
                }
            }
        }
        if (found) {
*/
        countries.push (
            <a
                key = {item.country}
                onClick = {() => toggleCountry(item.country)}
                className = {isSelected(Main.Instance.PlayerGroupsState.countries, item.country) ? 'pg-country' : 'pg-country Grayscale'}
            >
                <img src = {Item.image}/>
                <span>{Item.name}</span>
            </a>,
        )
        // }
    }
    }

    return <div className = "clearfix">{countries}</div>
}
/*
function getContinents(): JSX.Element {
    const continents: JSX.Element[] = []

    for (const item of DataStore.Continents) {

        continents.push (
            <a key = {item} onClick = {() => toggleContinent(item)} className = {isSelected(Main.Instance.PlayerGroupsState.continents, item) ? 'pg-continent' : 'pg-continent Grayscale'}>
                <span>{item}</span>
            </a>,
        )
    }

    return <div className = "clearfix">{continents}</div>
}
*/
// getCountries
function getRegions(): JSX.Element {
    const regions: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.crmRegionsStream) {
        for (const item of Main.Instance.DatabaseDataState.crmRegionsStream) {
            let countryFound: boolean = false
            for (const Item of Main.Instance.PlayerGroupsState.countries) {
                if (Item === item.country) {
                    countryFound = true
                }
            }

            if (countryFound) {
                regions.push (
                    <a
                        key = {item.region}
                        onClick = {() => toggleRegion(item.region, item.country)}
                        className = {
                            isSelected(Main.Instance.PlayerGroupsState.regions, item.region) &&
                            isSelected(Main.Instance.PlayerGroupsState.Regions, item.country) ?
                            'pg-region' : 'pg-region Grayscale'}
                    >
                        <span>{item.name} ({item.country} - {item.region})</span>
                    </a>,
                )
            }

        }
    }
    return <div className = "clearfix">{regions}</div>
}

function getNationalities(): JSX.Element {
    const nationalities: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.crmNationalitiesStream) {
        for (const item of Main.Instance.DatabaseDataState.crmNationalitiesStream) {
            nationalities.push (
                <a
                    key = {item.nationality}
                    onClick = {() => toggleNationality(item.nationality)}
                    className = {isSelected(Main.Instance.PlayerGroupsState.nationalities, item.nationality) ? 'pg-continent' : 'pg-continent Grayscale'}>
                    <span>{item.name}</span>
                </a>,
            )
        }
    }
    return <div className = "clearfix">{nationalities}</div>
}

/*
function getTimezones(): JSX.Element {
    // console.log (Main.Instance.PlayerGroupsState.timezones)
    const timezones: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.crmTimezonesStream) {
    for (const item of Main.Instance.DatabaseDataState.crmTimezonesStream) {
        if (item === undefined) { continue }
        let acronym = ''
        for (const Item of DataStore.Timezones) {
            if (Item.value === item.timezone) {
                acronym = ' (' + Item.acronym + ')'
            }
        }
        timezones.push (
            <a key = {item.timezone} onClick = {() => toggleTimezone(item.timezone)} className = {isSelected(Main.Instance.PlayerGroupsState.timezones, item.timezone) ? 'pg-tz' : 'pg-tz Grayscale'}>
                <span>UTC&nbsp;{item.timezone}{acronym}</span>
            </a>,
        )
    }
    }

    return <div className = "clearfix">{timezones}</div>
}
*/
/*
function getLevels(): JSX.Element {
    // console.log (Main.Instance.PlayerGroupsState.timezones)
    const levels: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.levelsAllStream) {
    for (let i: number = 1; i <= Main.Instance.DatabaseDataState.levelsAllStream.length; i++) {
        let acronym: string = ''
        let max: number = 0
        for (const Item of Main.Instance.DatabaseDataState.levelsAllStream) {
            if (Item['hierarchy'] === i) {
                acronym = Item['name'] + ' [' + i + ']'
                max = +Item['sublevels']
            }
        }
        // let position: number
        const pos: number = isSelectedID(Main.Instance.PlayerGroupsState.levels, i.toString())
        // if (selected)

        levels.push (
            <a key = {i.toString()} onClick = {() => toggleLevel(i.toString())} className = {pos ? 'pg-tz' : 'pg-tz Grayscale'}>
                {acronym}
            </a>,
        )
        if (pos) {
            levels.push (
                <input
                    key = {'min' + i}
                    placeholder = "any"
                    type = "number"
                    style = {{width: '50px', float: 'left', paddingRight: '0px', paddingLeft: '5px', lineHeight: '22px'}}
                    min = "-1"
                    max = {max + 1}
                    onChange = {(e) => handleSublevelMin(pos - 1, e.target.value, max)}
                    value = {Main.Instance.PlayerGroupsState.levels[pos - 1] && Main.Instance.PlayerGroupsState.levels[pos - 1].min ?
                            Main.Instance.PlayerGroupsState.levels[pos - 1].min.toString() : ''}
                />,
            )
            levels.push (<div key = {'_' + i} style = {{float: 'left', lineHeight: '22px', margin: '0px 2px'}}>&ndash;</div>)
            levels.push (
                <input
                    key = {'max' + i}
                    placeholder = "any"
                    type = "number"
                    style = {{width: '50px', float: 'left', paddingRight: '0px', paddingLeft: '5px', lineHeight: '22px', 
                            marginRight: i === Main.Instance.DatabaseDataState.levelsAllStream.length ? '0px' : '10px'}}
                    min = "-1"
                    max = {max + 1}
                    onChange = {(e) => handleSublevelMax(pos - 1, e.target.value, max)}
                    value = {Main.Instance.PlayerGroupsState.levels[pos - 1] && Main.Instance.PlayerGroupsState.levels[pos - 1].max ?
                            Main.Instance.PlayerGroupsState.levels[pos - 1].max.toString() : ''}
                />,
            )
        }
    }
    }
    return <div className = "clearfix">{levels}</div>
}
*/
/*
function handleSublevelMin(which: number, value: string, max: number) {
    const levels = Object.assign([], Main.Instance.PlayerGroupsState.levels)
    if (+value > max) { levels[which].min = 1 } else if (+value === -1) { levels[which].min = max } else { levels[which].min = +value }
    Logic.Type.New (Main.Instance.PlayerGroupsState, {levels})
}

function handleSublevelMax(which: number, value: string, max: number) {
    const levels = Object.assign([], Main.Instance.PlayerGroupsState.levels)
    if (+value > max) { levels[which].max = 1 } else if (+value === -1) { levels[which].max = max } else { levels[which].max = +value }
    Logic.Type.New (Main.Instance.PlayerGroupsState, {levels})
}
*/

function getCurrencies(): JSX.Element {
    const currencies: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.crmCurrenciesStream) {
    for (const item of Main.Instance.DatabaseDataState.crmCurrenciesStream) {
        if (item === undefined) { continue }
        let icon
        let disabled: string = ''
        let enabled: string = ''
        for (const Item of DataStore.Currencies) {
            if (Item.acronym === item.currency) {
                if (Item.icon) { icon = Item.icon } else {
                    disabled = Item.disabled
                    enabled = Item.enabled
                }
                break
            }
        }

        currencies.push (
            <a
                onClick = {() => toggleCurrency(item.currency)}
                className = {isSelected(Main.Instance.PlayerGroupsState.currencies, item.currency) ? 'pg-currency' : 'pg-currency Grayscale'}
                key = {item.currency}
            >
                {icon ? <FontAwesomeIcon icon = {icon}/> :
                <>
                    <img src = {disabled} className = "img img-responsive disabled"/>
                    <img src = {enabled} className = "img img-responsive enabled"/>
                </>}
                <span>{item.currency}</span>
            </a>,
        )
    }
    }

    return <div className = "clearfix">{currencies}</div>
}

function getGender(): JSX.Element {
    const gender: JSX.Element[] = []
    for (const item of DataStore.Gender) {
        gender.push (
            <a key = {item.short} onClick = {() => toggleGender(item.short)} className = {isSelected(Main.Instance.PlayerGroupsState.gender, item.short) ? 'pg-gender' : 'pg-gender Grayscale'}>
                <FontAwesomeIcon icon = {item.icon}/>
                <span>{item.long}</span>
            </a>,
        )
    }

    return <div className = "clearfix">{gender}</div>
}

function getAgeGroup(): JSX.Element {
    const ageGroup: JSX.Element[] = []

    for (const item of DataStore.AgeGroup) {
        ageGroup.push (
            <a 
                onClick = {() => toggleAgeGroup(item.text)}
                className = {isSelected(Main.Instance.PlayerGroupsState.ageGroup, item.text) && !Main.Instance.PlayerGroupsState.ageGroupMin && !Main.Instance.PlayerGroupsState.ageGroupMax ? 
                            'pg-age-group' : 'pg-age-group Grayscale'}
                key = {item.text}
            >
                {item.text}
            </a>,
        )
    }

    ageGroup.push (<span style = {{lineHeight: '28px'}} key = "a-custom">&nbsp;&nbsp;{Assets.Custom}:&nbsp;</span>)
    // tslint:disable-next-line:max-line-length
    ageGroup.push (<input className = "pg-age-group" placeholder = {Assets.ageMin_} key = "a-min" type = "number" min = "0" onChange = {(e) => handleAgeGroupMin(e.target.value)} value = {Main.Instance.PlayerGroupsState.ageGroupMin ? Main.Instance.PlayerGroupsState.ageGroupMin.toString() : ''}/>)
    ageGroup.push (<span key = "a-min-max">&nbsp;&ndash;&nbsp;</span>)
    // tslint:disable-next-line:max-line-length
    ageGroup.push (<input className = "pg-age-group" placeholder = {Assets.ageMax_} key = "a-max" type = "number" min = "0" onChange = {(e) => handleAgeGroupMax(e.target.value)} value = {Main.Instance.PlayerGroupsState.ageGroupMax ? Main.Instance.PlayerGroupsState.ageGroupMax.toString() : ''}/>)

    return <div className = "clearfix">{ageGroup}</div>
}
/*
function getBalanceGroup(): JSX.Element {
    const balanceGroup: JSX.Element[] = []

    for (const item of DataStore.BalanceGroup) {
        balanceGroup.push (
            <a 
                onClick = {() => toggleBalanceGroup(item.text)}
    // tslint:disable-next-line:max-line-length
                className = {isSelected(Main.Instance.PlayerGroupsState.balanceGroup, item.text) && !Main.Instance.PlayerGroupsState.balanceGroupMin && !Main.Instance.PlayerGroupsState.balanceGroupMax ? 
                            'pg-balance-group' : 'pg-balance-group Grayscale'}
                key = {item.text}
            >
                {item.text}
            </a>,
        )
    }

    balanceGroup.push (<span style = {{lineHeight: '28px'}} key = "a-custom">&nbsp;&nbsp;Custom:&nbsp;</span>)
    // tslint:disable-next-line:max-line-length
    balanceGroup.push (<input className = "pg-balance-group" placeholder = "balance min." key = "a-min" type = "number" min = "0" onChange = {(e) => handleBalanceGroupMin(e.target.value)} value = {Main.Instance.PlayerGroupsState.balanceGroupMin ? Main.Instance.PlayerGroupsState.balanceGroupMin.toString() : ''}/>)
    balanceGroup.push (<span key = "a-min-max">&nbsp;&ndash;&nbsp;</span>)
    // tslint:disable-next-line:max-line-length
    balanceGroup.push (<input className = "pg-balance-group" placeholder = "balance max." key = "a-max" type = "number" min = "0" onChange = {(e) => handleBalanceGroupMax(e.target.value)} value = {Main.Instance.PlayerGroupsState.balanceGroupMax ? Main.Instance.PlayerGroupsState.balanceGroupMax.toString() : ''}/>)

    return <div className = "clearfix">{balanceGroup}</div>
}
*/
function handleTitle(title: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {title})
}
/*
function handleBackgroundColor(backgroundColor: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {backgroundColor})
}

function handleColor(color: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {color})
}
*/
function handlePriority(priority: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {priority: +priority})
}

function handleMinBet(minBet: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minBet: +minBet})
}

function handleWithinDeposit(withinDeposit: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {withinDeposit: +withinDeposit})
}

function handleMinDeposit(minDeposit: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minDeposit: +minDeposit})
}

function handleMaxDeposit(maxDeposit: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxDeposit: +maxDeposit})
}

function handleWithinWithdrawal(withinWithdrawal: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {withinWithdrawal: +withinWithdrawal})
}

function handleMinWithdrawal(minWithdrawal: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minWithdrawal: +minWithdrawal})
}

function handleMaxWithdrawal(maxWithdrawal: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWithdrawal: +maxWithdrawal})
}

function handleMaxBet(maxBet: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBet: +maxBet})
}

function handleMinWin(minWin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minWin: +minWin})
}

function handleMaxWin(maxWin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxWin: +maxWin})
}

function handleMinBetsSession(minBetsSession: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minBetsSession: +minBetsSession})
}

function handleMaxBetsSession(maxBetsSession: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxBetsSession: +maxBetsSession})
}

function handleMinTimeSession(minTimeSession: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {minTimeSession: +minTimeSession})
}

function handleMaxTimeSession(maxTimeSession: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {maxTimeSession: +maxTimeSession})
}

function handleBetLogicMin(betLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {betLogicMin: betLogicMin.length > 0 ? +betLogicMin : undefined})
}

function handleBetLogicMax(betLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {betLogicMax: betLogicMax.length > 0 ? +betLogicMax : undefined})
}

function handleWinLogicMin(winLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {winLogicMin: winLogicMin.length > 0 ? +winLogicMin : undefined})
}

function handleWinLogicMax(winLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {winLogicMax: winLogicMax.length > 0 ? +winLogicMax : undefined})
}

function handleDepositLogicMin(depositLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {depositLogicMin: depositLogicMin.length > 0 ? +depositLogicMin : undefined})
}

function handleDepositLogicMax(depositLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {depositLogicMax: depositLogicMax.length > 0 ? +depositLogicMax : undefined})
}

function handleWithdrawalLogicMin(withdrawalLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {withdrawalLogicMin: withdrawalLogicMin.length > 0 ? +withdrawalLogicMin : undefined})
}

function handleWithdrawalLogicMax(withdrawalLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {withdrawalLogicMax: withdrawalLogicMax.length > 0 ? +withdrawalLogicMax : undefined})
}

function handleBetsSessionLogicMin(betsSessionLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {betsSessionLogicMin: betsSessionLogicMin.length > 0 ? +betsSessionLogicMin : undefined})
}

function handleBetsSessionLogicMax(betsSessionLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {betsSessionLogicMax: betsSessionLogicMax.length > 0 ? +betsSessionLogicMax : undefined})
}

function handleTimeSessionLogicMin(timeSessionLogicMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timeSessionLogicMin: timeSessionLogicMin.length > 0 ? +timeSessionLogicMin : undefined})
}

function handleTimeSessionLogicMax(timeSessionLogicMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timeSessionLogicMax: timeSessionLogicMax.length > 0 ? +timeSessionLogicMax : undefined})
}

function handleTimesHoursMin(timesHoursMin: string) {
    if (+timesHoursMin === 24) {
        timesHoursMin = '0'
    } else if (+timesHoursMin === -1) {timesHoursMin = '23'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesHoursMin: +timesHoursMin})
}

function handleTimesHoursMinMAX(timesHoursMinMAX: string) {
    if (+timesHoursMinMAX === 24) {
        timesHoursMinMAX = '0'
    } else if (+timesHoursMinMAX === -1) {timesHoursMinMAX = '23'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesHoursMinMAX: +timesHoursMinMAX})
}

function handleTimesHoursMax(timesHoursMax: string) {
    if (+timesHoursMax === 24) {
        timesHoursMax = '0'
    } else if (+timesHoursMax === -1) {timesHoursMax = '23'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesHoursMax: +timesHoursMax})
}

function handleTimesHoursMaxMAX(timesHoursMaxMAX: string) {
    if (+timesHoursMaxMAX === 24) {
        timesHoursMaxMAX = '0'
    } else if (+timesHoursMaxMAX === -1) {timesHoursMaxMAX = '23'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesHoursMaxMAX: +timesHoursMaxMAX})
}

function handleTimesMinutesMin(timesMinutesMin: string) {
    if (+timesMinutesMin === 60) {
        timesMinutesMin = '0'
    } else if (+timesMinutesMin === -1) {timesMinutesMin = '59'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMin: +timesMinutesMin})
}

function handleTimesMinutesMinMAX(timesMinutesMinMAX: string) {
    if (+timesMinutesMinMAX === 60) {
        timesMinutesMinMAX = '0'
    } else if (+timesMinutesMinMAX === -1) {timesMinutesMinMAX = '59'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMinMAX: +timesMinutesMinMAX})
}

function handleTimesMinutesMax(timesMinutesMax: string) {
    if (+timesMinutesMax === 60) {
        timesMinutesMax = '0'
    } else if (+timesMinutesMax === -1) {timesMinutesMax = '59'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMax: +timesMinutesMax})
}

function handleTimesMinutesMaxMAX(timesMinutesMaxMAX: string) {
    if (+timesMinutesMaxMAX === 60) {
        timesMinutesMaxMAX = '0'
    } else if (+timesMinutesMaxMAX === -1) {timesMinutesMaxMAX = '59'}
    Logic.Type.New (Main.Instance.PlayerGroupsState, {timesMinutesMaxMAX: +timesMinutesMaxMAX})
}

function handleAgeGroupMin(ageGroupMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {ageGroupMin: +ageGroupMin})
}

function handleAgeGroupMax(ageGroupMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {ageGroupMax: +ageGroupMax})
}
/*
function handleBalanceGroupMin(balanceGroupMin: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {balanceGroupMin: +balanceGroupMin})
}

function handleBalanceGroupMax(balanceGroupMax: string) {
    Logic.Type.New (Main.Instance.PlayerGroupsState, {balanceGroupMax: +balanceGroupMax})
}
*/
function getLogic(length: number, Item: string, value: string, short?: boolean): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of DataStore.logic) {
        if ((short || length === 1) && item !== 'not') { continue }
        logic.push (<a key = {item} onClick = {() => setLogic(Item, item)} className = {item === value ? 'logic active mlf5' : 'logic mlf5'}>{item}</a>)
    }

    return logic
}

function getLogicComparison(Item: string, value: string): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of DataStore.logicComparison) {
        logic.push (<a key = {item} onClick = {() => setLogic(Item, item)} className = {item === value ? 'logic active mlf5' : 'logic mlf5'}>{item}</a>)
    }

    return logic
}

function getLogicCurrency(Item: string, value: string): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of DataStore.logicCurrency) {
        logic.push (
            <a 
                title = {item.acronym}
                onClick = {() => setLogic(Item, item.acronym)}
                className = {item.icon ? item.acronym === value ? 'Logic active mlf5' : 'Logic mlf5' : item.acronym === value ? 'Logic png active mlf5' : 'Logic png mlf5'}
                key = {item.acronym}
            >
                {/*<FontAwesomeIcon icon = {item.icon}/>*/}
                {item.icon ? <FontAwesomeIcon icon = {item.icon}/> :
                <>
                    &nbsp;&nbsp;
                    <img src = {item.disabled} className = "Disabled"/>
                    <img src = {item.enabled} className = "Enabled"/>
                </>}
            </a>,
        )
    }

    return logic
}

function getLogicAction(Item: string, value: string): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of Item === 'bet' ? DataStore.logicActionBet : Item === 'win' ? DataStore.logicActionWin : 
                        Item === 'deposit' ? DataStore.logicActionDeposit : Item === 'withdrawal' ? DataStore.logicActionWithdrawal : []) {
        logic.push (
            <a key = {item} onClick = {() => setLogic(Item, item)} className = {item === value ? 'llogic active mlf5' : 'llogic mlf5'}>
                {item}
            </a>,
        )
    }
    if (value && value.startsWith('custom')) {
        logic.push (<span key = "Min">{' > '}</span>)
        logic.push (
            <input
                className = "pg-money"
                placeholder = {Item === 'bet' ? 'min. # bets' : Item === 'win' ? 'min. # wins' : Item === 'deposit' ? 'min. # deposits' : Item === 'withdrawal' ? 'min. # withdrawals' : '?'}
                key = "min"
                min = "0"
                type = "number"
                onChange = {(e) =>
                    Item === 'bet' ? handleBetLogicMin(e.target.value) : Item === 'win' ? handleWinLogicMin(e.target.value) :
                    Item === 'deposit' ? handleDepositLogicMin(e.target.value) : Item === 'withdrawal' ? handleWithdrawalLogicMin(e.target.value) :
                    void 0
                }
                value = {
                    Item === 'bet' ? Main.Instance.PlayerGroupsState.betLogicMin !== undefined ? Main.Instance.PlayerGroupsState.betLogicMin.toString() : '' :
                    Item === 'win' ? Main.Instance.PlayerGroupsState.winLogicMin !== undefined ? Main.Instance.PlayerGroupsState.winLogicMin.toString() : '' :
                    Item === 'deposit' ? Main.Instance.PlayerGroupsState.depositLogicMin !== undefined ? Main.Instance.PlayerGroupsState.depositLogicMin.toString() : '' :
                    Item === 'withdrawal' ? Main.Instance.PlayerGroupsState.withdrawalLogicMin !== undefined ? Main.Instance.PlayerGroupsState.withdrawalLogicMin.toString() : '' :                    
                    ''
                }
            />,
        )

        logic.push (<span key = "Max">{' and ≤ '}</span>)
        logic.push (
            <input
                className = "pg-money"
                placeholder = {Item === 'bet' ? 'max. # bets' : Item === 'win' ? 'max. # wins' : Item === 'deposit' ? 'max. # deposits' : Item === 'withdrawal' ? 'max. # withdrawals' : '?'}
                key = "max"
                min = "0"
                type = "number"
                onChange = {(e) =>
                    Item === 'bet' ? handleBetLogicMax(e.target.value) : Item === 'win' ? handleWinLogicMax(e.target.value) :
                    Item === 'deposit' ? handleDepositLogicMax(e.target.value) : Item === 'withdrawal' ? handleWithdrawalLogicMax(e.target.value) :
                    void 0
                }
                value = {
                    Item === 'bet' ? Main.Instance.PlayerGroupsState.betLogicMax !== undefined ? Main.Instance.PlayerGroupsState.betLogicMax.toString() : '' :
                    Item === 'win' ? Main.Instance.PlayerGroupsState.winLogicMax !== undefined ? Main.Instance.PlayerGroupsState.winLogicMax.toString() : '' :
                    Item === 'deposit' ? Main.Instance.PlayerGroupsState.depositLogicMax !== undefined ? Main.Instance.PlayerGroupsState.depositLogicMax.toString() : '' :
                    Item === 'withdrawal' ? Main.Instance.PlayerGroupsState.withdrawalLogicMax !== undefined ? Main.Instance.PlayerGroupsState.withdrawalLogicMax.toString() : '' :                    
                    
                    ''}
            />,
        )
    }

    if (value === 'ignore') {
        if (Item === 'bet') { logic.push (<span key = "any" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnyBetSize.toLowerCase()}</span>) } else if (Item === 'win') { logic.push (<span key = "any" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnyWinSize.toLowerCase()}</span>) } else if (Item === 'deposit') { logic.push (<span key = "any" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnyDepositSize.toLowerCase()}</span>) } else if (Item === 'withdrawal') { logic.push (<span key = "any" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnyWithdrawalSize.toLowerCase()}</span>) } 
    }

    return logic
}

function getLogicFrequency(Item: string, value: string, zero?: boolean): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of zero ? DataStore.logicFrequency0 : Main.Instance.PlayerGroupsState.frequencyLogic0 === 'ignore' ? DataStore.logicFrequency : DataStore.LogicFrequency) {
        logic.push (
            <a key = {item} onClick = {() => setLogic(Item, item)} className = {item === value ? 'llogic active mlf5' : 'llogic mlf5'}>
                {item}
            </a>,
        )
    }

    if (value === 'ignore') {
        logic.push (<span key = "any" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{zero ? Assets.AnyFrequency.toLowerCase() : Assets.AnyDuration.toLowerCase()}</span>)
    }

    return logic
}

function getLogicSession(Item: string, value: string): JSX.Element[] {
    const logic: JSX.Element[] = []
    for (const item of DataStore.logicSession) {
        logic.push (
            <a key = {item} onClick = {() => setLogic(Item, item)} className = {item === value ? 'llogic active mlf5' : 'llogic mlf5'}>
                {item}
            </a>,
        )
    }
    if (value && value.startsWith('custom')) {
        logic.push (<span key = "Min">{' > '}</span>)
        logic.push (
            <input
                className = "pg-session"
                placeholder = {Item === 'bets' ? 'min. # bets' : 'min. # minutes'}
                key = "min"
                min = "0"
                type = "number"
                onChange = {(e) => Item === 'bet' ? handleBetsSessionLogicMin(e.target.value) : handleTimeSessionLogicMin(e.target.value)}
                value = {Item === 'bet' ? Main.Instance.PlayerGroupsState.betLogicMin !== undefined ? Main.Instance.PlayerGroupsState.betLogicMin.toString() : '' :
                Item === 'win' ? Main.Instance.PlayerGroupsState.winLogicMin !== undefined ? Main.Instance.PlayerGroupsState.winLogicMin.toString() : '' : ''}
            />,
        )

        logic.push (<span key = "Max">{' and ≤ '}</span>)
        logic.push (
            <input
                className = "pg-session"
                placeholder = {Item === 'bets' ? 'max. # bets' : 'max. # minutes'}
                key = "max"
                min = "0"
                type = "number"
                onChange = {(e) => Item === 'bet' ? handleBetsSessionLogicMax(e.target.value) : handleTimeSessionLogicMax(e.target.value)}
                value = {Item === 'bet' ? Main.Instance.PlayerGroupsState.betLogicMax !== undefined ? Main.Instance.PlayerGroupsState.betLogicMax.toString() : '' :
                Item === 'win' ? Main.Instance.PlayerGroupsState.winLogicMax !== undefined ? Main.Instance.PlayerGroupsState.winLogicMax.toString() : '' : ''}
            />,
        )
    }

    if (value === 'ignore') {
        if (Item === 'bets') { logic.push (<span key = "anybets" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnySessionBets.toLowerCase()}</span>) } else { logic.push (<span key = "anytime" style = {{fontSize: '14px', marginLeft: '10px'}} className = "label label-info">{Assets.AnySessionTime.toLowerCase()}</span>) } 
    }

    return logic
}

function clearFields() {
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {
        mode: false,
        modeBetSizing: false,
        modeCalendar: false,
        modeFrequency: false,
        modeGames: false,
        modePlayers: false,
        modeSession: false,
        modeDeposit: false,
        modeWithdrawal: false,
    })
    Logic.Type.New (Main.Instance.PlayerGroupsState, {
    // GENERAL
        staticTimestamp: undefined,
        staticMembers: undefined,
        title: undefined,
        priority: undefined,
        staticGroup: false,
        allowLowerPriority: true,
        // backgroundColor: string
        // color: string
    // DEPOSITS
        depositLogic: 'ignore',
        depositLogicMin: undefined,
        depositLogicMax: undefined,

        withinDepositLogic: 'ignore',
        withinDeposit: undefined,

        minDepositLogic: 'ignore',
        minDepositCurrencyLogic: 'EUR',
        minDeposit: undefined,

        maxDepositLogic: 'ignore',
        maxDepositCurrencyLogic: 'EUR',
        maxDeposit: undefined,

        // WITHDRAWAL
        withdrawalLogic: 'ignore',
        withdrawalLogicMin: undefined,
        withdrawalLogicMax: undefined,

        withinWithdrawalLogic: 'ignore',
        withinWithdrawal: undefined,

        minWithdrawalLogic: 'ignore',
        minWithdrawalCurrencyLogic: 'EUR',
        minWithdrawal: undefined,

        maxWithdrawalLogic: 'ignore',
        maxWithdrawalCurrencyLogic: 'EUR',
        maxWithdrawal: undefined,

        // BET SIZING
        betLogic: 'ignore',
        betLogicMin: undefined,
        betLogicMax: undefined,

        winLogic: 'ignore',
        winLogicMin: undefined,
        winLogicMax: undefined,

        minBetLogic: 'ignore',
        minBetCurrencyLogic: 'EUR',
        minBet: undefined,

        maxBetLogic: 'ignore',
        maxBetCurrencyLogic: 'EUR',
        maxBet: undefined,

        minWinLogic: 'ignore',
        minWinCurrencyLogic: 'EUR',
        minWin: undefined,

        maxWinLogic: 'ignore',
        maxWinCurrencyLogic: 'EUR',
        maxWin: undefined,

        // SESSION
        betsSessionLogic: 'ignore',
        betsSessionLogicMin: undefined,
        betsSessionLogicMax: undefined,

        minBetsSessionLogic: 'ignore',
        minBetsSession: undefined,

        maxBetsSessionLogic: 'ignore',
        maxBetsSession: undefined,

        timeSessionLogic: 'ignore',
        timeSessionLogicMin: undefined,
        timeSessionLogicMax: undefined,

        minTimeSessionLogic: 'ignore',
        minTimeSession: undefined,

        maxTimeSessionLogic: 'ignore',
        maxTimeSession: undefined,

        // FREQUENCY
        frequencyLogic: 'ignore',
        frequencyLogic0: 'ignore',

        // CALENDAR
        interval: false,
        calendarYear: [],
        calendarYearLogic: undefined,
        calendarMonth: [],
        calendarMonthLogic: undefined,
        calendarWeekDays: [],
        calendarWeekDaysLogic: undefined,
        calendarMonthDays: [],
        calendarMonthDaysLogic: undefined,
        timesHoursMin: 0,
        timesHoursMax: 0,
        timesMinutesMin: 0,
        timesMinutesMax: 0,

        calendarYearMax: [],
        calendarYearLogicMax: undefined,
        calendarMonthMax: [],
        calendarMonthLogicMax: undefined,
        calendarWeekDaysMax: [],
        calendarWeekDaysLogicMax: undefined,
        calendarMonthDaysMax: [],
        calendarMonthDaysLogicMax: undefined,
        timesHoursMinMAX: 0,
        timesHoursMaxMAX: 0,
        timesMinutesMinMAX: 0,
        timesMinutesMaxMAX: 0,
        
        // GAMES
        games: [],
        gamesLogic: undefined,

        // PLAYERS
        levelsLogic: undefined,
        levels: [],
        timezones: [],
        timezonesLogic: undefined,
        // continents: [],
        // continentsLogic: undefined,
        regions: [],
        regionsLogic: undefined,
        countries: [],
        countriesLogic: undefined,
        currencies: [],
        currenciesLogic: undefined,
        gender: [],
        genderLogic: undefined,
        ageGroup: [],
        ageGroupLogic: undefined,
        ageGroupMin: undefined,
        ageGroupMax: undefined,
        balanceGroup: [],
        balanceGroupLogic: undefined,
        balanceGroupMin: undefined,
        balanceGroupMax: undefined,
        // MANUAL
        // players: number[] = []
    })
}

function ViewList() {
    return (
        <div 
        // className = "show-on-hover-top"
        style = {{
            overflowY: 'scroll',
            background: '#eee',
            position: 'fixed',
            top: '100px',
            height: 'auto',
            width: '75%',
            left: '20%',
            border: '1px solid #999',
            padding: '10px 10px 15px 10px',
            zIndex: 9999,
        }}
    >
        <h3 style = {{position: 'relative'}}>
            <button 
                style = {{
                    position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '-27px', padding: '0px', marginRight: '0px', fontSize: '12px',
                }}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {title: '', Data: [], viewList: false})
                    gotoTop()
                    // el.style.overflow = 'auto'
                    }
                }
            >
                <FontAwesomeIcon icon = {faTimes}/>
            </button> 
            {Assets.ListOfPlayersForPlayerGroup} '{Main.Instance.PlayerGroupsInternal.title}'
            <span className = {Main.Instance.PlayerGroupsInternal.showStatic ? 'label label-info label-sm ml' : 'label label-primary label-sm ml'}>
                {Main.Instance.PlayerGroupsInternal.showStatic ? Assets.StaticGroup.toLowerCase() : Assets.DynamicGroup.toLowerCase()}
            </span>
            {Main.Instance.PlayerGroupsInternal.showStatic && Main.Instance.PlayerGroupsInternal.staticTimestamp ? 
            <span className = "label label-info label-sm ml">
                {Assets.FreezedAt + ': ' + Main.Instance.PlayerGroupsInternal.staticTimestamp.substring(0,10) + ' ' + Main.Instance.PlayerGroupsInternal.staticTimestamp.substring(11,19)}
            </span>                
            : void 0}
            <span className = {Main.Instance.PlayerGroupsInternal.showStatic ? 'label label-info label-sm ml' : 'label label-primary label-sm ml'}>
                {Main.Instance.PlayerGroupsInternal.Data.length + ' ' + Assets.Members.toLowerCase()}
            </span>
        </h3>
        {getPlayersList(Main.Instance.PlayerGroupsInternal.Data)}
    </div>
    )
}

export function PlayerGroups() {
/*
    if (Main.Instance.DatabaseDataState.playerGroups === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerGroups: [], PlayerGroupsGet: true})
        // console.log ('get sent')
        DbRoutes.wsBasicGet(Config.amPlayerGroups)
    }
*/
    // console.log ('playersInfo 2292')
    DbRoutes.wsReinitialize()
    // console.log (Main.Instance.DatabaseDataState.playersInfo)
    // console.log (Main.Instance.DatabaseDataState.nationalities)
/*
    if (Main.Instance.DatabaseDataState.countries) {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (item.data.name === 'Switzerland') {
                console.log (item)
                break
            }
        }
    }
*/
    return (
        <div>
            {Main.Instance.PlayerGroupsInternal.viewList ? ViewList(): void 0}
            <h2>{Assets.PlayerGroups}</h2>
            <div className = "row admin-users-container clearfix">
                <div 
                    className = {Main.Instance.PlayerGroupsInternal.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-Edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
                >        
                    <div className = "create-input">
                        {/*<div className = "row admin-users-row" style = {{position: 'relative'}}>
                        </div>*/}

                        <div style = {{position: 'relative'}}>                        
                            {Main.Instance.PlayerGroupsInternal.mode ?
                            <button 
                                style = {{
                                    position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-12px', top: '-30px', padding: '0px', marginRight: '0px', fontSize: '12px',
                                }}
                                onClick = {() => gotoInsertMode()}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button> : void 0}
                            <h4>{Main.Instance.PlayerGroupsInternal.mode ? Assets.ePlayerGroup + ' ' + Main.Instance.PlayerGroupsState.title_saved : Assets.aPlayerGroup}</h4>
                            <div style = {{position: 'absolute', right: '0px', top: '-20px'}}>
                                <ButtonIcon
                                    click = {() => ModePlayer()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modePlayers ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.levels && Main.Instance.PlayerGroupsState.levels.length > 0
                                        || Main.Instance.PlayerGroupsState.timezones && Main.Instance.PlayerGroupsState.timezones.length > 0
                                        || Main.Instance.PlayerGroupsState.countries && Main.Instance.PlayerGroupsState.countries.length > 0
                                        || Main.Instance.PlayerGroupsState.regions && Main.Instance.PlayerGroupsState.regions.length > 0
                                        || Main.Instance.PlayerGroupsState.currencies && Main.Instance.PlayerGroupsState.currencies.length > 0
                                        || Main.Instance.PlayerGroupsState.gender && Main.Instance.PlayerGroupsState.gender.length > 0
                                        || Main.Instance.PlayerGroupsState.balanceGroup && Main.Instance.PlayerGroupsState.balanceGroup.length > 0
                                        || Main.Instance.PlayerGroupsState.balanceGroupMin || Main.Instance.PlayerGroupsState.balanceGroupMax
                                        || Main.Instance.PlayerGroupsState.ageGroup && Main.Instance.PlayerGroupsState.ageGroup.length > 0
                                        || Main.Instance.PlayerGroupsState.ageGroupMin || Main.Instance.PlayerGroupsState.ageGroupMax ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faUsers}
                                    text = {Assets.Players}
                                />
    
                                <ButtonIcon
                                    disabled = {true}
                                    click = {() => ModeGames()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeGames ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.games.length > 0 ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faGamepad}
                                    text = {Assets.Games}
                                />
                                {/*
                                <ButtonIcon
                                    click = {() => ModeCalendar()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeCalendar ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.calendarYear.length > 0 || Main.Instance.PlayerGroupsState.calendarMonth.length > 0 
                                        || Main.Instance.PlayerGroupsState.calendarWeekDays.length > 0 || Main.Instance.PlayerGroupsState.calendarMonthDays.length > 0
                                        || Main.Instance.PlayerGroupsState.timesHoursMin || Main.Instance.PlayerGroupsState.timesHoursMax
                                        || Main.Instance.PlayerGroupsState.timesMinutesMin || Main.Instance.PlayerGroupsState.timesMinutesMax
                                        || Main.Instance.PlayerGroupsState.interval ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faCalendarAlt}
                                    text = {Assets.Calendar}
                                />

                                <ButtonIcon
                                    click = {() => ModeFrequency()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeFrequency ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.frequencyLogic !== 'ignore' || Main.Instance.PlayerGroupsState.frequencyLogic0 !== 'ignore' ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faClock}
                                    text = {Assets.Frequency}
                                />

                                <ButtonIcon
                                    click = {() => ModeSession()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeSession ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.betsSessionLogic !== 'ignore' || Main.Instance.PlayerGroupsState.timeSessionLogic !== 'ignore' ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faStopwatch}
                                    text = {Assets.Session}
                                />

                                <ButtonIcon
                                    click = {() => ModeBetSizing()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeBetSizing ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.betLogic !== 'ignore' || Main.Instance.PlayerGroupsState.winLogic !== 'ignore' ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faCoins}
                                    text = {Assets.BetSizing}
                                />
*/}
                                <ButtonIcon
                                    disabled = {true}
                                    click = {() => ModeWithdrawal()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeWithdrawal ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.withdrawalLogic !== undefined && Main.Instance.PlayerGroupsState.withdrawalLogic !== 'ignore' ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faMinus}
                                    text = {Assets.Withdrawal}
                                />

                                <ButtonIcon
                                    disabled = {true}
                                    click = {() => ModeDeposit()}
                                    ClassName = {
                                        Main.Instance.PlayerGroupsInternal.modeDeposit ?
                                        'btn btn-success btn-sm btn-plus fr mr mt20' :
                                        Main.Instance.PlayerGroupsState.depositLogic !== undefined && Main.Instance.PlayerGroupsState.depositLogic !== 'ignore' ?
                                        'btn btn-sm btn-plus fr mr mt20 greengray greengrayborder' :
                                        'btn btn-default btn-sm btn-plus fr mr mt20'
                                    }
                                    Icon = {faPlus}
                                    text = {Assets.Deposit}
                                />
                            </div>
                        </div>

                        <div className = "clearfix" style = {{paddingTop: '10px', position: 'relative'}}>
                            <div
                                style = {{lineHeight: 1, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px', float: 'left', marginRight: '10px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faCogs}/>&nbsp;&nbsp;{Assets.General}
                            </div>

                            <FormInput
                                value = {!Main.Instance.PlayerGroupsState.title ? '' : Main.Instance.PlayerGroupsState.title}
                                ClassName = "form-control register-input"
                                type = "text"
                                divClass = "input-test-site"
                                onChange = {(text) => handleTitle(text)}
                                placeholder = "Player Group name"
                                // fix = {true}
                                // Whole50 = {true}
                                // iconText = {Assets.JSONWebTokenShort}
                                // iconTextSize = "12px"
                                icon = "fa fa-tag fa-lg"
                            />

                            <div className = "input-group margin-bottom-sm select-verynarrow4">
                                <span
                                    // style = {{color: Main.Instance.PlayerGroupsState.color}} 
                                    /*onClick = {this.props.handleClick ? this.props.handleClick : null}*/
                                    className = "input-group-addon"
                                    style = {{
                                        background: getBackgroundColor(Main.Instance.PlayerGroupsState.priority),
                                        color: Main.Instance.PlayerGroupsState.priority === undefined || Main.Instance.PlayerGroupsState.priority === 0 ? '' : '#fff',
                                    }}
                                >
                                    <FontAwesomeIcon icon = {faArrowsAltV}/>
                                </span>

                                <select
                                    style = {{width: '100%', background: '#fff', height: '30px', paddingLeft: '10px'}}
                                    value = {!Main.Instance.PlayerGroupsState.priority ? '0' : Main.Instance.PlayerGroupsState.priority.toString()}
                                    onChange = {(e) => handlePriority(e.target.value)}
                                >
                                    {getHierarchyOptions()}
                                </select>
                            </div>
                            {Main.Instance.PlayerGroupsInternal.mode ?
                            <button
                                type = "button"
                                className = "btn btn-danger btn-sm br0"
                                onClick = {() => handleInsertUpdate(true)}
                                style = {{display: 'block', float: 'left', marginRight: '0px', marginLeft: '10px'}}
                            >
                                {Assets.Clone}
                            </button> : void 0}

                            <div
                                className = "label label-default label-sm"
                                style = {{display: 'block', float: 'left', marginLeft: '10px', marginRight: '0px', borderRadius: '0px', fontSize: '14px', lineHeight: '23px'}}
                            >
                                {Assets.GroupType}
                            </div>

                            <button
                                type = "button"
                                className = {Main.Instance.PlayerGroupsState.staticGroup ? 'btn btn-info btn-sm' : 'btn btn-default btn-sm'}
                                onClick = {() => Logic.Type.New (Main.Instance.PlayerGroupsState, {
                                    staticGroup: Main.Instance.PlayerGroupsState.staticGroup ? undefined: true})}
                                style = {{display: 'block', float: 'left', marginRight: '0px', borderRadius: '0px'}}
                            >
                                {Assets.static_}
                            </button>

                            <button
                                type = "button"
                                className = {Main.Instance.PlayerGroupsState.staticGroup === false ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
                                onClick = {() => Logic.Type.New (Main.Instance.PlayerGroupsState, {
                                    staticGroup: Main.Instance.PlayerGroupsState.staticGroup === false ? undefined : false})}
                                style = {{display: 'block', float: 'left', marginRight: '0px', borderRadius: '0px'}}
                            >
                                {Assets.dynamic}
                            </button>

                            {Main.Instance.PlayerGroupsState.staticGroup ?
                            <>
                                <button
                                    type = "button"
                                    className = "btn btn-danger btn-sm ml br0"
                                    onClick = {() => Freeze()}
                                    style = {{display: 'block', float: 'left', marginRight: '0px'}}
                                >
                                    {Assets.Freeze}
                                </button>

                                {Main.Instance.PlayerGroupsState.staticTimestamp !== undefined ?
                                <>
                                    <div className = "fl label label-info label-sm fs14px br0" style = {{lineHeight: '23px'}}>{Assets.FreezedAt}:</div>
                                    <div className = "fl label label-info label-sm fs14px br0">
                                        <div style = {{lineHeight: '12px'}}>{Main.Instance.PlayerGroupsState.staticTimestamp.substring(0, 10)}</div>
                                        <div style = {{lineHeight: '11px'}}>{Main.Instance.PlayerGroupsState.staticTimestamp.substring(11, 19)}</div>
                                    </div>
                                </>
                                : void 0}
                            </> : void 0}
                            {Main.Instance.PlayerGroupsState.staticGroup ?
                                Main.Instance.PlayerGroupsState.staticMembers !== undefined ?
                                    <button
                                        style = {{display: 'block', float: 'left', marginRight: '0px', marginLeft: '10px'}}
                                        className = "btn btn-info btn-sm"
                                        // tslint:disable-next-line:max-line-length
                                        onClick = {() => showMembers(/*Main.Instance.PlayerGroupsInternal.id,*/ Main.Instance.PlayerGroupsState.title, Main.Instance.PlayerGroupsInternal.id, Main.Instance.PlayerGroupsState.staticTimestamp, Main.Instance.PlayerGroupsState.staticMembers)}
                                    >
                                        {Main.Instance.PlayerGroupsState.staticMembers.length + ' ' + Assets.Members.toLowerCase()}
                                    </button>
                                    : void 0 :
                            
                            Main.Instance.PlayerGroupsState.staticGroup === false ?
                            <button
                                style = {{display: 'block', float: 'left', marginRight: '0px', marginLeft: '10px'}}
                                className = "btn btn-primary btn-sm"
                                // tslint:disable-next-line:max-line-length
                                onClick = {() => showMembers(/*Main.Instance.PlayerGroupsInternal.id,*/ Main.Instance.PlayerGroupsState.title, Main.Instance.PlayerGroupsInternal.id, undefined)}
                            >
                                {GetData(Main.Instance.PlayerGroupsInternal.id).length  + ' ' + Assets.Members.toLowerCase()}
                            </button> : void 0}
    

                            <div
                                className = "label label-default label-sm"
                                style = {{display: 'block', float: 'left', marginLeft: '1%', marginRight: '0px', borderRadius: '0px', fontSize: '14px', lineHeight: '23px'}}
                            >
                                {Assets.LowerPriority}
                            </div>

                            <button
                                type = "button"
                                className = {Main.Instance.PlayerGroupsState.allowLowerPriority ? 'btn btn-success btn-sm' : 'btn btn-default btn-sm'}
                                onClick = {() => Logic.Type.New (Main.Instance.PlayerGroupsState, {
                                    allowLowerPriority: true})}
                                style = {{display: 'block', float: 'left', marginRight: '0px', borderRadius: '0px'}}
                            >
                                {Assets.allow}
                            </button>

                            <button
                                type = "button"
                                className = {!Main.Instance.PlayerGroupsState.allowLowerPriority ? 'btn btn-danger btn-sm' : 'btn btn-default btn-sm'}
                                onClick = {() => Logic.Type.New (Main.Instance.PlayerGroupsState, {
                                    allowLowerPriority: false})}
                                style = {{display: 'block', float: 'left', marginRight: '0px', borderRadius: '0px'}}
                            >
                                {Assets.disallow}
                            </button>
{/*
                            <div className = "input-group margin-bottom-sm">
                                <span
                                    style = {{background: Main.Instance.PlayerGroupsState.backgroundColor}} 
                                    className = "input-group-addon"
                                >
                                    <FontAwesomeIcon icon = {faFillDrip}/>
                                </span>

                                <select
                                    style = {{width: '90%', background: '#fff', height: '30px', paddingLeft: '10px'}}
                                    value = {!Main.Instance.PlayerGroupsState.backgroundColor ? '0' : Main.Instance.PlayerGroupsState.backgroundColor}
                                    onChange = {(e) => handleBackgroundColor(e.target.value)}
                                >
                                    {getColorOptions(true)}
                                </select>
                            </div>
                            <div className = "input-group margin-bottom-sm">
                                <span
                                    style = {{color: Main.Instance.PlayerGroupsState.color}} 
                                    className = "input-group-addon"
                                >
                                    <FontAwesomeIcon icon = {faTint}/>
                                </span>

                                <select
                                    style = {{width: '90%', background: '#fff', height: '30px', paddingLeft: '10px'}}
                                    value = {!Main.Instance.PlayerGroupsState.color ? '0' : Main.Instance.PlayerGroupsState.color}
                                    onChange = {(e) => handleColor(e.target.value)}
                                >
                                    {getColorOptions(false)}
                                </select>
                            </div>
*/}
                        </div>
                        <div className = "clearfix" style = {{paddingTop: '10px', position: 'relative'}}>
                            <ButtonIcon 
                                description = {Main.Instance.PlayerGroupsInternal.mode ? Assets.saveChanges : Assets.updateChanges} 
                                click = {() => handleInsertUpdate()}
                                ClassName = {Main.Instance.PlayerGroupsInternal.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                                // icon= {Assets_.faUpdateButtonClass}
                                Icon = {faSave as IconDefinition}
                                text = {Assets.Save}
                            />
                        </div>

                        {Main.Instance.PlayerGroupsInternal.modeDeposit ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeDeposit: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faPlus}/>&nbsp;&nbsp;{Assets.Deposit}
                            </div>

                            <div className = "mt">
                                <div className = "clearfix">
                                    <div className = "fl label label-primary mr pg-secondary-title2">
                                        {Assets.DepositSett_}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicAction('deposit', Main.Instance.PlayerGroupsState.depositLogic)}
                                    </div>
                                </div>

                                {Main.Instance.PlayerGroupsState.depositLogic === 'ignore' ? void 0 :
                                <>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Within}</div>
                                        {getWithinDeposit()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Minimum}</div>
                                        {getMinDeposit()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Maximum}</div>
                                        {getMaxDeposit()}
                                    </div>
                                </>}
                                <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                            </div>
                            <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeWithdrawal ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeWithdrawal: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faMinus}/>&nbsp;&nbsp;{Assets.Withdrawal}
                            </div>

                            <div className = "mt">
                                <div className = "clearfix">
                                    <div className = "fl label label-primary mr pg-secondary-title2">
                                        {Assets.WithdrawalSett_}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicAction('withdrawal', Main.Instance.PlayerGroupsState.withdrawalLogic)}
                                    </div>
                                </div>

                                {Main.Instance.PlayerGroupsState.withdrawalLogic === 'ignore' ? void 0 :
                                <>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Within}</div>
                                        {getWithinWithdrawal()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Minimum}</div>
                                        {getMinWithdrawal()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Maximum}</div>
                                        {getMaxWithdrawal()}
                                    </div>
                                </>}
                                <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                            </div>
                            <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeBetSizing ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeBetSizing: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faCoins}/>&nbsp;&nbsp;{Assets.BetSizing}
                            </div>

                            <div className = "mt">
                                <div className = "clearfix">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {Assets.BetSettings}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicAction('bet', Main.Instance.PlayerGroupsState.betLogic)}
                                    </div>
                                </div>

                                {Main.Instance.PlayerGroupsState.betLogic === 'ignore' ? void 0 :
                                <>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Minimum}</div>
                                        {getMinBet()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Maximum}</div>
                                        {getMaxBet()}
                                    </div>
                                </>}
                            </div>
                            <div className = "mt">
                                <div className = "clearfix mt">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {Assets.WinSettings}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicAction('win', Main.Instance.PlayerGroupsState.winLogic)}
                                    </div>
                                </div>

                                {Main.Instance.PlayerGroupsState.winLogic === 'ignore' ? void 0 :
                                <>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Minimum}</div>
                                        {getMinWin()}
                                    </div>
                                    <div className = "clearfix mt">
                                        <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Maximum}</div>
                                        {getMaxWin()}
                                    </div>
                                </>}
                                <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                            </div>
                            <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeSession ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeSession: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faStopwatch}/>&nbsp;&nbsp;{Assets.Session}
                            </div>

                            <div className = "mt">
                                <div className = "clearfix">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {Assets.SessionBets}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicSession('bets', Main.Instance.PlayerGroupsState.betsSessionLogic)}
                                    </div>
                                </div>
                            </div>

                            {Main.Instance.PlayerGroupsState.betsSessionLogic === 'ignore' ? void 0 :
                            <>
                                <div className = "clearfix mt">
                                    <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Minimum}</div>
                                    {getMinBets()}
                                </div>
                                <div className = "clearfix mt">
                                    <div className = "fl label label-warning mr ml0 pg-secondary-title">{Assets.Maximum}</div>
                                    {getMaxBets()}
                                </div>
                            </>}

                            <div className = "mt">
                                <div className = "clearfix">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {Assets.SessionTime}
                                    </div>
                                    <div className = "clearfix fl">
                                        {getLogicSession('time', Main.Instance.PlayerGroupsState.timeSessionLogic)}
                                    </div>
                                </div>
                            </div>

                            {Main.Instance.PlayerGroupsState.timeSessionLogic === 'ignore' ? void 0 :
                            <>
                                <div className = "clearfix mt">
                                    <div className = "fl label label-warning mr ml0 pg-secondary-title2">{Assets.Minimum}</div>
                                    {getMinTime()}
                                </div>
                                <div className = "clearfix mt">
                                    <div className = "fl label label-warning mr ml0 pg-secondary-title2">{Assets.Maximum}</div>
                                    {getMaxTime()}
                                </div>
                            </>}

                            <div style = {{height:'1px'}} className = "clearfix"><div className = "fl">&nbsp;</div></div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeFrequency ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeFrequency: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faClock}/>&nbsp;&nbsp;{Assets.Frequency}
                            </div>

                            <div className = "mt">
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {Assets.SetFrequency}
                                    </div>
                                    <div key = "logic">
                                        {getLogicFrequency('frequency0', Main.Instance.PlayerGroupsState.frequencyLogic0, true)}
                                    </div>
                                </div>
                            </div>

                            <div className = "mt">
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">
                                        {Assets.SetDuration}
                                    </div>
                                    <div key = "logic">
                                        {getLogicFrequency('frequency', Main.Instance.PlayerGroupsState.frequencyLogic)}
                                    </div>
                                </div>
                            </div>
                            
                            <div className = "clearfix">
                                <div className = "fl" style = {{height: '1px'}}>&nbsp;</div>
                            </div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeCalendar ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeCalendar: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success"
                            >
                                <FontAwesomeIcon icon = {faCalendarAlt}/>&nbsp;&nbsp;{Assets.Calendar}
                            </div>
                            {/*<h4 style = {{position: 'relative'}}>Set calendar</h4>*/}
                            <div className = "mt">
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-primary mr pg-secondary-title">
                                        {!Main.Instance.PlayerGroupsState.interval ? Assets.NoInterval : Assets.MinInterval}
                                    </div>
                                    <button
                                        onClick = {() => toggleInterval()}
                                        className = {Main.Instance.PlayerGroupsState.interval ? 'fl dbl btn btn-success btn-xs ml' : 'fl dbl btn btn-danger btn-xs ml'}
                                        style = {{fontSize: '14px', lineHeight: 1.2}}
                                    >
                                        {Assets.SetInterval}?
                                    </button>
                                </div>

                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetYear}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarYear()}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetMonth}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarMonth()}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetWeekday}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarWeekDays()}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetMonthDay}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarMonthDays()}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetTime}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getTimes()}
                                </div>

                                {Main.Instance.PlayerGroupsState.interval ?
                                <>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-primary mr pg-secondary-title">{Assets.MaxInterval}</div>
                                    {/*
                                    <button
                                        onClick = {() => toggleInterval()}
                                        className = {Main.Instance.PlayerGroupsState.interval ? 'fl dbl btn btn-success btn-xs ml' : 'fl dbl btn btn-danger btn-xs ml'}
                                        style = {{fontSize: '14px', lineHeight: 1.2}}
                                    >
                                        Set interval?
                                    </button>
                                    */}
                                </div>

                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetYear}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarYear(true)}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetMonth}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarMonth(true)}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetWeekday}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarWeekDays(true)}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetMonthDay}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getCalendarMonthDays(true)}
                                </div>
                                <div className = "clearfix mb3">
                                    <div className = "fl label label-warning mr pg-secondary-title">{Assets.SetTime}</div>
                                    {/*<div className = "fl mr">A player has been active in</div>*/}
                                    {getTimes(true)}
                                </div>
                                </> : void 0}
                            </div>
                            <div className = "clearfix">
                                <div className = "fl" style = {{height: '1px'}}>&nbsp;</div>
                            </div>
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modeGames ?
                        <div style = {{position: 'relative'}}>
                            <button 
                                style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                onClick = {() => {
                                    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modeGames: false})
                                }}
                            >
                                <FontAwesomeIcon icon = {faTimes}/>
                            </button>

                            <hr style = {{borderTopColor: '#999'}}/>
                            <div 
                                style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                className = "label label-success label-sm"
                            >
                                <FontAwesomeIcon icon = {faGamepad}/>&nbsp;&nbsp;{Assets.GamesInvolvement}
                            </div>
                                {/*<span style = {{position: 'absolute', left: '160px'}}>*/}
                                {/*</span>*/}
{/*
                            <h4 style = {{position: 'relative'}}>
                                <span className = "label label-warning mr">{Assets.SelectGames}</span>
                                {Main.Instance.PlayerGroupsState.games.length > 0 ? 
                                 getLogic(Main.Instance.PlayerGroupsState.games.length, 'games', Main.Instance.PlayerGroupsState.gamesLogic)
                                : <span className = "label label-info">{Assets.AnyGame.toLowerCase()}</span>}
                            </h4>
                            {getGames()}
*/}
                        </div> : void 0}

                        {Main.Instance.PlayerGroupsInternal.modePlayers ?
                        <>
                            <div style = {{position: 'relative'}}>
                                <button 
                                    style = {{position: 'absolute', zIndex: 9999, border: '0px', background: 'transparent', right: '-5px', top: '0px', padding: '0px', marginRight: '0px'}}
                                    onClick = {() => {
                                        Logic.Type.New (Main.Instance.PlayerGroupsInternal, {modePlayers: false})
                                    }}
                                >
                                    <FontAwesomeIcon icon = {faTimes}/>
                                </button>

                                <hr style = {{borderTopColor: '#999'}}/>

                                <div
                                    style = {{lineHeight: 1.5, borderRadius: '3px', fontWeight: 700, fontSize: '16px', paddingTop: '6px', paddingBottom: '6px'}}
                                    className = "label label-success"
                                >
                                    <FontAwesomeIcon icon = {faUsers}/>&nbsp;&nbsp;{Assets.Player_sCharacteristics}
                                </div>
{/*
                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetLevel}</span>
                                    {Main.Instance.PlayerGroupsState.levels && Main.Instance.PlayerGroupsState.levels.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.levels.length, 'levels', Main.Instance.PlayerGroupsState.levelsLogic, true) 
                                    : <span className = "label label-info">{Assets.AnyLevel.toLowerCase()}</span>}
                                </h4>
                                {getLevels()}
*/}
{/*
                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">Set sublevel</span>
                                    {Main.Instance.PlayerGroupsState.sublevels.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.sublevels.length, 'sublevels', Main.Instance.PlayerGroupsState.sublevelsLogic) 
                                    : <span className = "label label-info">any sublevel</span>}
                                </h4>
                                {getSublevels()}
*/}

{/*
                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetTimezones}</span>
                                    {Main.Instance.PlayerGroupsState.timezones && Main.Instance.PlayerGroupsState.timezones.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.timezones.length, 'timezones', Main.Instance.PlayerGroupsState.timezonesLogic, true) 
                                    : <span className = "label label-info">{Assets.AnyTimezone.toLowerCase()}</span>}
                                </h4>
                                {getTimezones()}
*/}
                                {/*
                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetContinents}</span>
                                    {Main.Instance.PlayerGroupsState.continents && Main.Instance.PlayerGroupsState.continents.length > 0 ?
                                     getLogic(Main.Instance.PlayerGroupsState.continents.length, 'continents', Main.Instance.PlayerGroupsState.continentsLogic, true)
                                    : <span className = "label label-info">{Assets.AnyContinent.toLowerCase()}</span>}
                                </h4>
                                {getContinents()}
                                */}

                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetCountries}</span>
                                    {Main.Instance.PlayerGroupsState.countries && Main.Instance.PlayerGroupsState.countries.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.countries.length, 'countries', Main.Instance.PlayerGroupsState.countriesLogic, true)
                                    : <span className = "label label-info">{Assets.AnyCountry.toLowerCase()}</span>}
                                </h4>
                                {getCountries()}
                                {Main.Instance.PlayerGroupsState.countries && Main.Instance.PlayerGroupsState.countries.length > 0 ?
                                <>
                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetRegions}</span>
                                    {Main.Instance.PlayerGroupsState.regions && Main.Instance.PlayerGroupsState.regions.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.regions.length, 'regions', Main.Instance.PlayerGroupsState.regionsLogic, true)
                                    : <span className = "label label-info">{Assets.AnyRegion.toLowerCase()}</span>}
                                </h4>
                                {getRegions()}
                                </> : void 0}

                                <h4 style = {{position: 'relative'}}>
                                    <span className = "label label-warning mr">{Assets.SetNationalities}</span>
                                    {Main.Instance.PlayerGroupsState.nationalities && Main.Instance.PlayerGroupsState.nationalities.length > 0 ?
                                    getLogic(Main.Instance.PlayerGroupsState.nationalities.length, 'nationalities', Main.Instance.PlayerGroupsState.nationalitiesLogic, true)
                                    : <span className = "label label-info">{Assets.AnyNationality.toLowerCase()}</span>}
                                </h4>
                                {getNationalities()}

                            </div>

                            <div className = "clearfix">

                                <div style = {{width: '50%', float: 'left'}}>
                                    <h4 style = {{position: 'relative'}}>
                                        <span className = "label label-warning mr">{Assets.SetCurrencies}</span>
                                        {Main.Instance.PlayerGroupsState.currencies && Main.Instance.PlayerGroupsState.currencies.length > 0 ?
                                        getLogic(Main.Instance.PlayerGroupsState.currencies.length, 'currencies', Main.Instance.PlayerGroupsState.currenciesLogic, true) 
                                        : <span className = "label label-info">{Assets.AnyCurrency.toLowerCase()}</span>}
                                    </h4>
                                    {getCurrencies()}
                                </div>

                                <div style = {{width: '50%', float: 'left'}}>
                                    <h4 style = {{position: 'relative'}}>
                                        <span className = "label label-warning mr">{Assets.SetGender}</span>
                                        {Main.Instance.PlayerGroupsState.gender && Main.Instance.PlayerGroupsState.gender.length > 0 ?
                                        getLogic(Main.Instance.PlayerGroupsState.gender.length, 'gender', Main.Instance.PlayerGroupsState.genderLogic, true)
                                        : <span className = "label label-info">{Assets.AnyGender.toLowerCase()}</span>}
                                    </h4>
                                    {getGender()}
                                </div>
                            </div>
                            <div className = "clearfix">
                                <div style = {{width: '60%', float: 'left'}}>
                                    <h4 style = {{position: 'relative'}}>
                                        <span className = "label label-warning mr">{Assets.SetAgeGroup}</span>
                                        {Main.Instance.PlayerGroupsState.ageGroup && Main.Instance.PlayerGroupsState.ageGroup.length > 0
                                        || Main.Instance.PlayerGroupsState.ageGroupMin || Main.Instance.PlayerGroupsState.ageGroupMax ? 
                                        getLogic(Main.Instance.PlayerGroupsState.ageGroup.length, 'ageGroup', Main.Instance.PlayerGroupsState.ageGroupLogic, true) :
                                        <span className = "label label-info">{Assets.AnyAgeGroup.toLowerCase()}</span>}
                                    </h4>
                                    {getAgeGroup()}
                                </div>
{/*
                                <div style = {{width: '40%', float: 'left'}}>
                                    <h4 style = {{position: 'relative'}}>
                                        <span className = "label label-warning mr">{Assets.SetBalanceGroup}</span>
                                        {Main.Instance.PlayerGroupsState.balanceGroup && Main.Instance.PlayerGroupsState.balanceGroup.length > 0
                                        || Main.Instance.PlayerGroupsState.balanceGroupMin || Main.Instance.PlayerGroupsState.balanceGroupMax ?
                                        getLogic(Main.Instance.PlayerGroupsState.balanceGroup.length, 'balanceGroup', Main.Instance.PlayerGroupsState.balanceGroupLogic, true) :
                                        <span className = "label label-info">{Assets.AnyBalanceGroup.toLowerCase()}</span>}
                                    </h4>
                                    {getBalanceGroup()}
                                </div>
*/}
                            </div>
                        </> : void 0}
                    </div>
                </div>
            </div>
        {/*
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.PlayerGroupsInternal.pSize ? '' : 
                Main.Instance.PlayerGroupsInternal.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.PlayerGroupsInternal.count ? Main.Instance.PlayerGroupsInternal.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.PlayerGroupsInternal.count ? Main.Instance.PlayerGroupsInternal.pNum * 
                Main.Instance.PlayerGroupsInternal.pSize + 1 : 0}{' - '}
                {Main.Instance.PlayerGroupsInternal.count ? 
                ((Main.Instance.PlayerGroupsInternal.pNum + 1) * 
                Main.Instance.PlayerGroupsInternal.pSize > Main.Instance.PlayerGroupsInternal.count ? 
                Main.Instance.PlayerGroupsInternal.count : 
                (Main.Instance.PlayerGroupsInternal.pNum + 1) * Main.Instance.PlayerGroupsInternal.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.PlayerGroupsInternal.count ? 
                Main.Instance.PlayerGroupsInternal.pNum + 1 :0}{' / '}
                {Main.Instance.PlayerGroupsInternal.count ? 
                Math.trunc((Main.Instance.PlayerGroupsInternal.count - 1) /
                Main.Instance.PlayerGroupsInternal.pSize + 1) : 0}
            </span>
        </div>*/}
        {/*console.log (Main.Instance.DatabaseDataState.playerGroups)*/}
        {Main.Instance.DatabaseDataState.playerGroups
        /*Main.Instance.DatabaseDataState.crmRulesStream !== undefined
        && Main.Instance.DatabaseDataState.crmGamesStream !== undefined
        && Main.Instance.DatabaseDataState.crmCountriesStream !== undefined
        && Main.Instance.DatabaseDataState.crmCurrenciesStream !== undefined
        && Main.Instance.DatabaseDataState.crmPlayersStream !== undefined
        && Main.Instance.DatabaseDataState.crmTimezonesStream !== undefined*/
        // && Main.Instance.DatabaseDataState.crmEventsStream !== undefined this is not active yet
        ?
            <>
                <h3>{Assets.ListOfPlayerGroups}</h3>
                <div className = "player-groups">
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.playerGroups}
                    columns = {[
                        {width: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},

                        {show: isUserAdmin(), Header: Assets.PlayerGroup, accessor: 'data', headerClassName: 'th-left', Cell: (row) => row.value.title},
                        
                        {width: 35, Header: <FontAwesomeIcon title = {Assets.Priority} icon = {faExclamationTriangle}/>, accessor: 'data', headerClassName: 'th-center',
                        Cell: (row) => <div className = "priority-background" style = {{background: getBackgroundColor(row.value.priority)}}>{row.value.priority}</div>},
                        
                        {width: 100, Header: Assets.GroupType, accessor: 'data', headerClassName: 'th-left', Cell: (row) => row.value.staticGroup ?
                        <span className = "label label-info fs14px">{Assets.static_}</span> : <span className = "label label-primary fs14px">{Assets.dynamic}</span>},
                        
                        {width: 100, Header: Assets.Timestamp, accessor: 'data', headerClassName: 'th-left', Cell: (row) => row.value.staticGroup ? row.value.staticTimestamp ?
                        <div><span className = "dbl lh14">{row.value.staticTimestamp.substring(0,10)}</span><span className = "dbl lh14">{row.value.staticTimestamp.substring(11,19)}</span></div>
                        : Assets.notSet : Assets_.NA},
                        
                        {width: 75, Header: <span>{Assets.Lower}&nbsp;<FontAwesomeIcon title = {Assets.Priority} icon = {faExclamationTriangle}/></span>,
                        accessor: 'data', headerClassName: 'th-left', Cell: (row) => row.value.allowLowerPriority ? Assets.allow : Assets.disallow},
                        
                        {width: 80, Header: Assets.BetSizing, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                        row.value.betLogic !== 'ignore' || row.value.winLogic !== 'ignore' ?
                        <span className = "label label-success label-sm fs14px">{Assets.set}</span> : 
                        <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>},
                        
                        {width: 80, Header: Assets.Session, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                        row.value.betsSessionLogic !== 'ignore' || row.value.timeSessionLogic !== 'ignore' ?
                        <span className = "label label-success label-sm fs14px">{Assets.set}</span> : 
                        <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>},
                        
                        {width: 85, Header: Assets.Frequency, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                        row.value.frequencyLogic !== 'ignore' || row.value.frequencyLogic0 !== 'ignore' ?
                        <span className = "label label-success label-sm fs14px">{Assets.set}</span> : 
                        <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>},
                        
                        {width: 80, Header: Assets.Calendar, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                        row.value.calendarYear && row.value.calendarYear.length > 0
                        || row.value.calendarMonth && row.value.calendarMonth.length > 0 
                        || row.value.calendarWeekDays && row.value.calendarWeekDays.length > 0
                        || row.value.calendarMonthDays && row.value.calendarMonthDays.length > 0
                        || row.value.timesHoursMin
                        || row.value.timesHoursMax
                        || row.value.timesMinutesMin
                        || row.value.timesMinutesMax
                        || row.value.interval ?
                        <span className = "label label-success label-sm fs14px">{Assets.set}</span> : 
                        <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>},
                        
                        {width: 80, Header: Assets.Games, accessor: 'data', headerClassName: 'th-left',
                        Cell: (row) => row.value.games && row.value.games.length > 0 ?
                        <span className = "label label-success label-sm fs14px">{Assets.set}</span> :
                        <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>},
                        
                        {width: 80, Header: Assets.Players, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                            row.value.levels && row.value.levels.length > 0 || row.value.timezones && row.value.timezones.length > 0 
                            || row.value.countries && row.value.countries.length > 0
                            || row.value.currencies && row.value.currencies.length > 0 || row.value.gender && row.value.gender.length > 0
                            || row.value.balanceGroup && row.value.balanceGroup.length > 0 || row.value.balanceGroupMin || row.value.balanceGroupMax
                            || row.value.ageGroup && row.value.ageGroup.length > 0 || row.value.ageGroupMin || row.value.ageGroupMax ?
                            <span className = "label label-success label-sm fs14px">{Assets.set}</span> : 
                            <span className = "label label-default label-sm fs14px">{Assets.notSet}</span>,
                        },
                        
                        {width: 120, Header: Assets.Members, accessor: 'data', headerClassName: 'th-left', Cell: (row) =>
                        <button
                            className = {row.value.staticGroup ? 'btn btn-info btn-xs' : 'btn btn-primary btn-xs'}
                            // tslint:disable-next-line:max-line-length
                            onClick = {() => showMembers(row.original.data.title, row.original.id, row.value.staticTimestamp, row.value.staticGroup && row.value.staticMembers ? row.value.staticMembers : undefined)}
                        >
                            
                            {(Main.Instance.DatabaseDataState.crmPlayerDataStream ?
                            row.value.staticGroup && row.value.staticMembers ? row.value.staticMembers.length : GetData(row.original.id).length : '0') + ' ' + Assets.Members.toLowerCase()}
                        </button>},
                        
                        // tslint:disable-next-line:max-line-length
                        {width: 60, Header: Assets.Export, accessor: 'data', headerClassName: 'th-left', Cell: (row) => getExport(row.original.data.title, row.original.id, row.value.staticTimestamp, row.value.staticGroup && row.value.staticMembers ? row.value.staticMembers : undefined)},

                        {show: isUserAdmin(), width: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    pageSize={10}
                />
            </div>
            </>
         : getPlayerGroupsCount()}
        </div>
    )
}

function getBackgroundColor(priority: number): string {
    if(priority === undefined || priority === 0) { return '' }
    const r: number = 40
    const g: number = 167
    // let b: number = 69

    const R: number = 220
    const G: number = 53
    // let B: number = 69

    const red = r + (R-r) * (priority - 1) / 99
    const green = g + (G-g) * (priority - 1) / 99
    return 'rgb(' + Math.round(red) + ',' + Math.round(green) + ',' + '69' + ')'
/*
    if (priority === 1) {
        return '#28a745'
    }

    if (priority === 100) {
        return '#dc3545'
    }
*/  
}

function showMembers(/*id: string,*/ title: string, id: string, staticTimestamp: string, playerData?: IPlayersInfo[]) {
    const Data: IPlayersInfo[] = playerData ? playerData : GetData(id)
    // if (playerData) Data = playerData
    // else Data = getData(data)
    // const args = {title, Data, viewList: true, showStatic: playerData ? true : false, staticTimestamp}
    // console.log (args)
    Logic.Type.New (Main.Instance.PlayerGroupsInternal, {title, Data, viewList: true, showStatic: playerData ? true : false, staticTimestamp})
    gotoTop()
    // el.style.overflow = 'hidden'
}

export function getExport(title: string, id: string, staticTimestamp: string, playerData?: IPlayersInfo[]): JSX.Element {
    const Data: IPlayersInfo[] = playerData ? playerData : GetData(id)
    // if (playerData) Data = playerData
    // else Data = getData(data)
    
    return (
        <div className = "clearfix">
            <button title = {Assets.Export + ' ' + Assets_.CSV} onClick = {() => ExportCSV(title, Data, staticTimestamp)} style = {{marginRight: '5px', padding: '0px', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '0px', float: 'left'}}>
                <FontAwesomeIcon icon = {faFileCsv}/>
            </button>
            <button disabled = {true} title = {Assets.Export + ' ' + Assets_.PDF} className = "btn btn-danger btn-sm mr0" onClick = {() => ExportPDF(title, Data, staticTimestamp)} style = {{padding: '0px', color: '#d9534f', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', marginTop: '2px', float: 'left'}}>
                <FontAwesomeIcon icon = {faFilePdf}/>
            </button>
        </div>
    )
}

// Main.Instance.DatabaseDataState.FlagsStreamAll
/*
function getData(data: DataStore.IPlayerGroupRule): PlayerData[] {
    return []
}
*/
function GetData(id: string): IPlayersInfo[] {
    const Id: number = id ? parseInt(id) : 0
    if (Id === 0) { return [] }
    // console.log (Id)
    // console.log (Main.Instance.DatabaseDataState.crmPlayerDataStream)
    if (Main.Instance.DatabaseDataState.crmPlayerDataStream) {
        for (const item of Main.Instance.DatabaseDataState.crmPlayerDataStream) {
            if (item.id === Id) {
                return item.data
            }
        }
    }
    // console.log ('nothing was found')
    return []
}


/*
function getData(data: DataStore.IPlayerGroupRule): PlayerData[] {
    // data is about player group
    // we need also player data, which is crmPlayersStream
    // and then finally event data crmEventsStream
    let Data: PlayerData[] = Object.assign ([], Main.Instance.DatabaseDataState.crmPlayersStream)

    // P L A Y E R S  - HERE WE DO NOT CHECK THE EVENTS, BUT ONLY PLAYERS DATA
    // LEVELS
    if (data.levels.length > 0) {
        // console.log (data.levels)
        const DATA: PlayerData[] = []
        for (const item of Data) {
            // console.log (item.level)
            // console.log (item.sublevel)
            if (data.levelsLogic === undefined) {
                for (const Item of data.levels) {
                    if (item.level === +Item.id) {
                        if (Item.min) {
                            if (item.sublevel < +Item.min) {
                                continue
                            }
                        }

                        if (Item.max) {
                            if (item.sublevel > +Item.max) {
                                continue
                            }
                        }

                        DATA.push(item)
                        break
                    }
                }
            } else if (data.levelsLogic === 'not') {
                let proceed: boolean = true
                for (const Item of data.levels) {
                    if (item.level === +Item.id) {
                        if (Item.min) {
                            if (item.sublevel < +Item.min) {
                                continue
                            }
                        }

                        if (Item.max) {
                            if (item.sublevel > +Item.max) {
                                continue
                            }
                        }

                        proceed = false
                        break
                    }
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    // COUNTRIES
    if (data.countries.length > 0) {
        const DATA: PlayerData[] = []
        for (const item of Data) {
            if (data.countriesLogic === undefined) {
                for (const Item of data.countries) {
                    if (item.country === Item) {
                        DATA.push(item)
                        break
                    }
                }
            } else if (data.countriesLogic === 'not') {
                let proceed: boolean = true
                for (const Item of data.countries) {
                    if (item.country === Item) {
                        proceed = false
                        break
                    }
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    // TIMEZONES
    if (data.timezones.length > 0) {
        const DATA: PlayerData[] = []
        for (const item of Data) {

            if (data.timezonesLogic === undefined) {
                for (const Item of data.timezones) {
                    if (item.timezone === Item) {
                        DATA.push(item)
                        break
                    }
                }
            } else if (data.timezonesLogic === 'not') {
                let proceed: boolean = true
                for (const Item of data.timezones) {
                    if (item.timezone === Item) {
                        proceed = false
                        break
                    }
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    // CURRENCIES
    if (data.currencies.length > 0) {
        const DATA: PlayerData[] = []
        for (const item of Data) {

            if (data.currenciesLogic === undefined) {
                for (const Item of data.currencies) {
                    if (item.currency === Item) {
                        DATA.push(item)
                        break
                    }
                }
            } else if (data.currenciesLogic === 'not') {
                let proceed: boolean = true
                for (const Item of data.currencies) {
                    if (item.currency === Item) {
                        proceed = false
                        break
                    }
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    // GENDER
    if (data.gender.length > 0) {
        const DATA: PlayerData[] = []
        for (const item of Data) {

            if (data.genderLogic === undefined) {
                for (const Item of data.gender) {
                    const ITEM: string = item.gender ? 'f' : 'm'
                    if (ITEM === Item) {
                        DATA.push(item)
                        break
                    }
                }
            } else if (data.genderLogic === 'not') {
                let proceed: boolean = true
                for (const Item of data.gender) {
                    const ITEM: string = item.gender ? 'f' : 'm'
                    if (ITEM === Item) {
                        proceed = false
                        break
                    }
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    // AGE GROUP
    if (data.ageGroup.length > 0 || data.ageGroupMin || data.ageGroupMax) {
        
        let customAge: string
        if (data.ageGroupMin || data.ageGroupMax) {
            // console.log ('min ' + data.ageGroupMin)
            // console.log ('max ' + data.ageGroupMax)
            if (data.ageGroupMin && data.ageGroupMax) {
                customAge = data.ageGroupMin + '-' + data.ageGroupMax 
            } else if (data.ageGroupMin) {
                customAge = data.ageGroupMin + '+'
            } else if (data.ageGroupMax) {
                customAge = data.ageGroupMax + '<'
            }
        }
        // console.log ('countriesLogic: ' + data.countriesLogic)
        const DATA: PlayerData[] = []
        for (const item of Data) {
            const age = getAge(item.dob)
            if (data.ageGroupLogic === undefined) {
                for (const Item of customAge ? [customAge] : data.ageGroup) {
                    let ageMin: number
                    let ageMax: number
                    
                    if (Item.indexOf('-') !== -1) {
                        const Age: string[] = Item.split('-')
                        ageMin = parseInt(Age[0])
                        ageMax = parseInt(Age[1])
                    } else if (Item.indexOf('+') !== -1) {
                        const Age: string[] = Item.split('+')
                        ageMin = parseInt(Age[0])
                    } else if (Item.indexOf('<') !== -1) {
                        const Age: string[] = Item.split('<')
                        ageMax = parseInt(Age[0])
                    }

                    if (ageMin && age < ageMin) continue
                    if (ageMax && age > ageMax) continue

                    DATA.push(item)
                    break
                }
            } else if (data.ageGroupLogic === 'not') {
                let proceed: boolean = true
                for (const Item of customAge ? [customAge] : data.ageGroup) {
                    let ageMin: number
                    let ageMax: number
                    
                    if (Item.indexOf('-') !== -1) {
                        const Age: string[] = Item.split('-')
                        ageMin = parseInt(Age[0])
                        ageMax = parseInt(Age[1])
                    } else if (Item.indexOf('+') !== -1) {
                        const Age: string[] = Item.split('+')
                        ageMin = parseInt(Age[0])
                    } else if (Item.indexOf('<') !== -1) {
                        const Age: string[] = Item.split('<')
                        ageMax = parseInt(Age[0])
                    }

                    if (ageMin && age < ageMin) continue
                    if (ageMax && age > ageMax) continue
                    proceed = false
                }
                if (proceed) DATA.push(item) 
            }
        }
        Data = Object.assign ([], DATA)
    }

    const args:{arg: string, value: (string|number)[]}[] = []
    if (data.games.length > 0) {
        // console.log (data.games)
        const value: (string|number)[] = []
        for (const ITEM of data.games) {
            value.push(ITEM)
        }

        args.push({arg: 'gameid', value})
    }
    if (data.games.length > 0) {
        return []
    } else return Data
}
*/
/*
function getMembersArray (data: DataStore.IPlayerGroupRule): PlayerData[] {
    return getData(data)
}
*/
function getMembersArray(id: string): IPlayersInfo[] {
    return GetData(id)
}

/*
function getMembers (data: DataStore.IPlayerGroupRule): JSX.Element {
    const Data: PlayerData[] = getDatA(data)
    return (<div>{Data.length + ' ' + Assets.Members.toLowerCase()}</div>)
}
*/
/*
function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function getLevel(level: number): string {
    let Level: string = ''
    for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
        if (+item['hierarchy'] === level) {
            Level = item['name']
            break
        }
    }

    return Level + ' (' + level + ')'
}
*/
/*
function getCountryOld(code: string) {
    if (Main.Instance.DatabaseDataState.FlagsStreamAll) {
        for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
            if (item['code'] === code || item['code2'] === code) { return item['name'] }
        }
    }
    return ''
}
*/
function getRegion(Code: string, code: string) {
    // console.log (Code, code)
    // console.log (Main.Instance.DatabaseDataState.countries)
    if (Main.Instance.DatabaseDataState.countries) {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (item.data.code === Code) {
                if (item.data.regions) {
                    for (const Item of item.data.regions) {
                        if (Item.code === code) {
                            return Item.name
                        }
                    }
                } else {
                    break
                }
            }
        }
    }
    return '?'

}

function getNationality(code: string) {
    if (Main.Instance.DatabaseDataState.nationalities) {
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            if (item.data.code3 === code) { return item.data.nationality }
        }
    }
    return '?'
}

function getCountry(code: string) {
    if (Main.Instance.DatabaseDataState.countries) {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (item.data.code === code) { return item.data.name }
        }
    }
    return '?'
}

function getPlayersList(data): JSX.Element {
/*
    {width: 80, Header: Assets.Deposit, accessor: '', headerClassName: 'th-left', Cell: (row) => '?'},
    {width: 80, Header: 'Withdraw', accessor: '', headerClassName: 'th-left', Cell: (row) => '?'},
*/
    // return <div>a</div>

    return (
        <ReactTable
            style = {{background: '#fff'}}
            data = {data}
            columns = {[
                // {width: 55, Header: Assets.ID + ' #', accessor: 'id', headerClassName: 'th-left', Cell: (row) => row.value},
                // {width: 100, Header: Assets.FirstName, accessor: 'name', headerClassName: 'th-left', Cell: (row) => row.value},
                // {width: 100, Header: Assets.LastName, accessor: 'surname', headerClassName: 'th-left', Cell: (row) => row.value},
                {width: 65, Header: Assets.Gender, accessor: 'gender', headerClassName: 'th-left', Cell: (row) => row.value === 'm' ? Assets.male : Assets.female},
                // {width: 100, Header: Assets.DateOfBirth, accessor: 'dob', headerClassName: 'th-left', Cell: (row) => row.value},
                {width: 40, Header: Assets.Age, accessor: 'age', headerClassName: 'th-left', Cell: (row) => row.value},
                {Header: Assets.Country, accessor: 'country', headerClassName: 'th-left', Cell: (row) => row.value ? getCountry(row.value) : '?'},
                {Header: Assets.Region, accessor: 'canton', headerClassName: 'th-left', Cell: (row) => row.value ? getRegion(row.original.country, row.value) : '?'},
                {Header: Assets.Nationality, accessor: 'nationality', headerClassName: 'th-left', Cell: (row) => row.value ? getNationality(row.value) : '?'},
                {width: 75, Header: Assets.Currency, accessor: 'currency', headerClassName: 'th-left', Cell: (row) => row.value ? row.value : 'CHF'},
                // {width: 140, Header: Assets.Continent, accessor: 'continent', headerClassName: 'th-left', Cell: (row) => row.value},
                // {width: 80, Header: Assets.Timezone, accessor: 'timezone', headerClassName: 'th-left', Cell: (row) => Assets_.UTC + ' ' + row.value},
                // {width: 100, Header: Assets.Level, accessor: 'level', headerClassName: 'th-left', Cell: (row) => getLevel(row.value)},
                // {width: 40, Header: Assets.Sub, accessor: 'sublevel', headerClassName: 'th-left', Cell: (row) => row.value},
                // {width: 80, Header: Assets.Balance, accessor: 'balance', headerClassName: 'th-left', Cell: (row) => row.value},
            ]}
            // showPaginationBottom = {false}
            defaultPageSize={10}
        />
    )

}

function ExportPDF(title: string, data: IPlayersInfo[], staticTimestamp: string) {
}

// tslint:disable-next-line:max-line-length
function ExportCSV(title: string, data: IPlayersInfo[], staticTimestamp: string) {
    // let s: string = 'ID,Name,Surname,Gender,DateOfBirth,Age,Country,Currency,Continent,Timezone,Level,Sublevel,Balance\n' // ,Deposit,Withdraw
    let s: string = /*Assets.ID+','+*//*Assets.FirstName+','+Assets.LastName+','+*/Assets.Gender+','+Assets.Nationality+','+Assets.Age+','+Assets.Country+','+Assets.Currency+','+Assets.Region/*+','+Assets.Timezone+','+Assets.Level+','+Assets.Sublevel+','+Assets.Balance*/ + '\n'
    for (const item of data) {
        s += /*item.id + ',' + item.name + ',' + item.surname + ',' + */(item.gender === 'm' ? Assets.male : Assets.female) + ',' + item.nationality + ',' + item.age + ',' 
        + getCountry(item.country) + ',' + (item.currency ? item.currency : 'CHF') + ',' + item.canton /*+ ',' + Assets_.UTC + ' ' + item.timezone + ',' + item.level + ',' + item.sublevel + ','
        + item.balance */+ '\n' // + ',' + item.deposit + ',' + item.withdraw + '\n'
    }

    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = 'player-group-' + title + '-' + (staticTimestamp ? staticTimestamp : Assets.dynamic) + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}
/*
export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.PlayerGroupsInternal, {count: Main.Instance.PlayerGroupsInternal.count ++})
    DbRoutes.getCRMRules()
    // LastPage()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.PlayerGroupsInternal, {count: Main.Instance.PlayerGroupsInternal.count --})
    DbRoutes.getCRMRules()
    // LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.PlayerGroupsInternal, {count: Main.Instance.PlayerGroupsInternal.count --})
    DbRoutes.getCRMRules()
    // getPlayerGroups()
}

export function Update() {
    clearFields()
    DbRoutes.getCRMRules()
    // getPlayerGroups()
}
*/

export function populateRealData(which: number) {
    // console.log (which + '/' + Main.Instance.DatabaseDataState.crmRulesStream.length) crmRulesStream
    if (Main.Instance.DatabaseDataState.playerGroups[which] && Main.Instance.DatabaseDataState.playerGroups[which].data) {
        getData(which, Main.Instance.DatabaseDataState.playerGroups[which].data)
    }
    if (which < Main.Instance.DatabaseDataState.playerGroups.length - 1) {
        populateRealData(which + 1)
    } else {
        // console.log ('finished')
    }
}

function getData(which: number, data: IPlayerGroupRule) {
    // data is about player group
    // we need also player data, which is crmPlayersStream
    // and then finally event data crmEventsStream
    const Data: IPlayersInfo[] = Object.assign ([], Main.Instance.DatabaseDataState.playersInfo)

    // P L A Y E R S  - HERE WE DO NOT CHECK THE EVENTS, BUT ONLY PLAYERS DATA
   
    // LEVELS

    // THESE ARE VARIOUS ARGUMENTS USED FOR ALL KIND OF DATA THAT NEEDS TO BE RECALCULATED
    const args: Array<{
        arg: string,
        value?: Array<string|number>,
        argMin?: number,
        argMax?: number,
        min?: number,
        minArg?: string,
        max?: number,
        maxArg?: string,
        within?: number,
        withinArg?: string,
        currencies?: string[],
        currenciesLogic?: string,
        countries?: string[],
        countriesLogic?: string,
        regions?: string[],
        Regions?: string[],
        regionsLogic?: string,
        nationalities?: string[],
        nationalitiesLogic?: string,
        timezones?: string[],
        timezonesLogic?: string,
        gender?: string[],
        genderLogic?: string,
        levels?: Array<{id: string, max: number, min: number}>,
        levelsLogic?: string,
        ageGroup?: string[],
        ageGroupLogic?: string,
        balanceGroup?: string[],
        balanceGroupLogic?: string,
    }> = []
    // console.log (data.title)
    // console.log (data)
    if (data.games && data.games.length > 0 ||                      // GAMES
        
        data.depositLogic && data.depositLogic !== 'ignore' ||      // DEEP SQL
        
        data.currencies && data.currencies.length > 0 ||            // SHALLOW SQL
        data.countries && data.countries.length > 0 ||
        data.timezones && data.timezones.length > 0 ||
        data.gender && data.gender.length > 0 ||
        data.levels && data.levels.length > 0 ||
        data.ageGroup && data.ageGroup.length > 0 || data.ageGroupMin !== undefined || data.ageGroupMax !== undefined ||
        data.balanceGroup && data.balanceGroup.length > 0 || data.balanceGroupMin !== undefined || data.balanceGroupMax !== undefined
    ) {
        if (data.games && data.games.length > 0) {
            // console.log (data.games)
            const value: Array<string|number> = []
            for (const ITEM of data.games) {
                value.push(ITEM)
            }
            args.push({arg: 'gameid', value})
        }

        if (data.levels && data.levels.length > 0) {
            args.push ({
                arg: 'levels',
                levels: data.levels && data.levels.length > 0 ? data.levels : undefined,
                levelsLogic: data.levelsLogic,
            })
        }

        if (data.currencies && data.currencies.length > 0) {
            args.push ({
                arg: 'currencies',
                currencies: data.currencies && data.currencies.length > 0 ? data.currencies : undefined,
                currenciesLogic: data.currenciesLogic,
            })
        }

        if (data.countries && data.countries.length > 0) {
            args.push ({
                arg: 'countries',
                countries: data.countries && data.countries.length > 0 ? data.countries : undefined,
                countriesLogic: data.countriesLogic,
            })
        }

        if (data.nationalities && data.nationalities.length > 0) {
            args.push ({
                arg: 'nationalities',
                nationalities: data.nationalities && data.nationalities.length > 0 ? data.nationalities : undefined,
                nationalitiesLogic: data.nationalitiesLogic,
            })
        }

        if (data.regions && data.regions.length > 0) {
            args.push ({
                arg: 'regions',
                Regions: data.Regions && data.Regions.length > 0 ? data.Regions : undefined,
                regions: data.regions && data.regions.length > 0 ? data.regions : undefined,
                regionsLogic: data.regionsLogic,
            })
        }

        if (data.timezones && data.timezones.length > 0) {
            args.push ({
                arg: 'timezones',
                timezones: data.timezones && data.timezones.length > 0 ? data.timezones : undefined,
                timezonesLogic: data.timezonesLogic,
            })
        }

        if (data.gender && data.gender.length > 0) {
            args.push ({
                arg: 'gender',
                gender: data.gender && data.gender.length > 0 ? data.gender : undefined,
                genderLogic: data.genderLogic,
            })
        }

        if (data.ageGroup && data.ageGroup.length > 0 || data.ageGroupMin !== undefined || data.ageGroupMax !== undefined) {
            args.push ({
                arg: 'age',
                ageGroup: data.ageGroup && data.ageGroup.length > 0 ? data.ageGroup : undefined,
                ageGroupLogic: data.ageGroupLogic,
                min: data.ageGroupMin,
                max: data.ageGroupMax,
            })
        }

        if (data.balanceGroup && data.balanceGroup.length > 0 || data.balanceGroupMin !== undefined || data.balanceGroupMax !== undefined) {
            args.push ({
                arg: 'balance',
                balanceGroup: data.balanceGroup && data.balanceGroup.length > 0 ? data.balanceGroup : undefined,
                balanceGroupLogic: data.balanceGroupLogic,
                min: data.balanceGroupMin,
                max: data.balanceGroupMax,
            })
        }

        // HERE

        if (data.depositLogic && data.depositLogic !== 'ignore') {
            args.push ({
                arg: data.depositLogic,
                argMin: data.depositLogic.startsWith ('custom') && data.depositLogicMin !== undefined ? data.depositLogicMin : undefined,
                argMax: data.depositLogic.startsWith ('custom') && data.depositLogicMax !== undefined ? data.depositLogicMax : undefined,
                min: data.minDepositLogic && data.minDepositLogic !== 'ignore' && data.minDeposit !== undefined ? data.minDeposit : undefined,
                minArg: data.minDepositLogic && data.minDepositLogic !== 'ignore' ? data.minDepositLogic : undefined,
                max: data.maxDepositLogic && data.maxDepositLogic !== 'ignore' && data.maxDeposit !== undefined ? data.maxDeposit : undefined,
                maxArg: data.maxDepositLogic && data.maxDepositLogic !== 'ignore' ? data.maxDepositLogic : undefined,
                within: data.withinDepositLogic && data.withinDepositLogic !== 'ignore' && data.withinDeposit !== undefined ? data.withinDeposit : undefined,
                withinArg: data.withinDepositLogic && data.withinDepositLogic !== 'ignore' ? data.withinDepositLogic : undefined,
            })
        }

        // console.log (args)
        // finally
        getCRMSQL(args, Data, which)
    } else {
        const crmPlayerDataStream: IPlayers[] = Object.assign([], Main.Instance.DatabaseDataState.crmPlayerDataStream)
        crmPlayerDataStream[which].data = Data
        Logic.Type.New (Main.Instance.DatabaseDataState, {crmPlayerDataStream})
        // console.log ('NOSQL ' + which + ' ' +  Data.length)
    }
}

function getCRMSQL(args, Data, which) {
    // console.log (args)
    const calculatedData: IPlayersInfo[] = []
    // const responseSQL = {data: []}
    // console.log (Data.length)
    // console.log (Data[0])
    for (const item of Data) {
        let Continue: boolean = false

        for (const Item of args) {
            if (Item.arg === 'currencies' && Item.currencies && Item.currencies.length > 0) {
                // LEAVE IT BE
            } else if (Item.arg === 'countries' && Item.countries && Item.countries.length > 0) {
                // LEAVE IT BE
            } else if (Item.arg === 'nationalities' && Item.nationalities && Item.nationalities.length > 0) {
                let found: boolean = false
                for (const ITEM of Item.nationalities) {
                    if (ITEM === item.nationality) {
                        found = true
                        break
                    }
                }
                if (!found) {Continue = true; break}
            } else if (Item.arg === 'regions' && Item.regions && Item.regions.length > 0 && Item.Regions && Item.Regions.length > 0) {

                let found: boolean = false
                for (let i: number = 0; i < Item.regions.length; i++) {
                    if (Item.regions[i] === item.canton && Item.Regions[i] === item.country) {
                        found = true
                        break
                    }
                }
                if (!found) {Continue = true; break}

            } else if (Item.arg === 'gender' && Item.gender && Item.gender.length > 0) {
                let found: boolean = false
                for (const ITEM of Item.gender) {
                    if (ITEM === item.gender) {
                        found = true
                        break
                    }
                }
                if (!found) {Continue = true; break}
// getRegion

            } else if (Item.arg === 'age' && (Item.ageGroup && Item.ageGroup.length > 0 || item.ageMin !== undefined || item.ageMax !== undefined)) {
                let ageMin: number
                let ageMax: number
                let found: boolean = false
                // console.log ()
                if (Item.ageGroup && Item.ageGroup.length > 0) {
                    for (const ITEM of Item.ageGroup) {
                        const split = ITEM.split('-')
                        if (split.length === 2) {
                            ageMin = parseInt(split[0])
                            ageMax = parseInt(split[1])
                        } else if (split.length === 1) {
                            if (split[0].charAt(0) === '≤') {
                                ageMax = parseInt(split[0].substring(1, split[0].length))
                            } else if (split[0].charAt(0) === '≥') {
                                ageMin = parseInt(split[0].substring(1, split[0].length))
                            }
                        }
                        // console.log (ageMin, ageMax)
                        if (ageMin !== undefined && ageMax !== undefined && item.age >= ageMin && item.age <= ageMax) {
                            found = true
                            break
                        } else if (ageMin !== undefined && ageMax === undefined && item.age >= ageMin) {
                            found = true
                            break
                        } else if (ageMin === undefined && ageMax !== undefined && item.age <= ageMax) {
                            found = true
                            break
                        }
                    }
                } else {

                }
                // console.log (item.age, found)
                if (!found) {Continue = true; break}
            }
        }

/*
        for (const Item of responseSQL.data) {
            if (item.id === parseInt(Item.playerid)) {
                calculatedData.push (item)
            }
        }
*/

        if (Continue) {continue}
        calculatedData.push(item)
    }
    // console.log (calculatedData.length)
    const crmPlayerDataStream: IPlayers[] = Object.assign([], Main.Instance.DatabaseDataState.crmPlayerDataStream)
    // crmPlayerDataStream[which].id = parseInt(id)
    crmPlayerDataStream[which].data = calculatedData
    Logic.Type.New (Main.Instance.DatabaseDataState, {crmPlayerDataStream})
/*
axios.get (Config.csSQL, {params: {args: JSON.stringify(args)}, headers: {'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId}})
.then((responseSQL) => {
    const calculatedData: PlayerData[] = []
    for (const item of Data) {
        for (const Item of responseSQL.data) {
            if (item.id === parseInt(Item.playerid)) {
                calculatedData.push (item)
            }
        }
    }
    const crmPlayerDataStream: Array<{id: string, data: PlayerData[]}> = Object.assign([], Main.Instance.DatabaseDataState.crmPlayerDataStream)
    crmPlayerDataStream[which].data = calculatedData
    Logic.Type.New (Main.Instance.DatabaseDataState, {crmPlayerDataStream})
    // console.log ('SQL ' + which + ' ' +  calculatedData.length)
    // Logic.Type.New (Main.Instance.PlayerGroupsInternal, {calculatedData})
})
*/
}