import * as React from 'react'
import {Main} from '../../Logic/Main'
import {Stat, StatType} from '../../Logic/Reports/Reports'
import {SwitzerlandCantonsColors, densityPallette} from '../Main/Reports/SwitzerlandResources'
// import {PieChart, Pie, ScatterChart, Scatter,AreaChart, Area,LineChart,Line,BarChart, 
//  Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import * as Assets from '../Assets'
import { ChartContent } from '../Main/MainDashboard/Dashboard'
import {
  PieChart,
  Pie,
  Cell,
  Bar,
  BarChart,
  Legend,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Tooltip,
  CartesianGrid,
  Area,
  AreaChart,
  ReferenceDot,
  Label,/*,LabelList*/
} from 'recharts'
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#555']
const COLORS2 = ['#0088FE', '#00C49F']
const COLORSPlayerRisk = ['#28a745', '#dc3545']
// const CL10 = ['#922B21','#B03A2E','#6C3483','#2874A6','#148F77','#1E8449','#B7950B','#B9770E','#AF601A','#A04000']
const RADIAN = Math.PI / 180;
/*
const renderCustomizedLabelScandibet = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x  = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy  + radius * Math.sin(-midAngle * RADIAN)

  const fill:string = Assets_.COLORS4[index % Assets_.COLORS4.length]  

  return (
    <text 
      x = {x} 
      y = {y}
      fontSize = "16"
      fill = {fill} // "white" 
      textAnchor = {x > cx ? 'start' : 'end'} 	
      dominantBaseline = "central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
*/
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export class SimpleLineChart0 extends React.Component<{ data: any[], width: number }, {}> {
  public render() {
    return (
      <LineChart
        width={this.props.width}
        height={500}
        data={this.props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="BetSize"
          stroke="#8884d8"
          activeDot={{ r: 5 }}
          yAxisId={0}
          strokeWidth={3}
          animationDuration={0}
        />
        <Line
          type="monotone"
          dataKey="PlayerAverageBetSize"
          stroke="#82ca9d"
          activeDot={{ r: 5 }}
          strokeWidth={3}
          animationDuration={0}
        />
      </LineChart>
    )
  }
}

export class SimpleLineChart1 extends React.Component<{ data: any[], width: number }, {}> {
  public render() {
    return (
      <LineChart
        width={this.props.width}
        height={500}
        data={this.props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" />
        <YAxis yAxisId="right" orientation="right" />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="BetSize"
          stroke="#8884d8"
          activeDot={{ r: 5 }}
          strokeWidth={3}
          animationDuration={0}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="BetSizePercent"
          stroke="#999"
          activeDot={{ r: 3 }}
          strokeWidth={2}
          animationDuration={0}
        />
      </LineChart>
    )
  }
}

export class SimpleLineChart2 extends React.Component<{ data: any[], width: number }, {}> {
  public render() {
    return (
      <LineChart
        width={this.props.width}
        height={500}
        data={this.props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" />
        <YAxis yAxisId="right" orientation="right" />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="PlayerAverageBetSize"
          stroke="#82ca9d"
          activeDot={{ r: 5 }}
          strokeWidth={3}
          animationDuration={0}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="PlayerAverageBetSizePercent"
          stroke="#999"
          activeDot={{ r: 3 }}
          strokeWidth={2}
          animationDuration={0}
        />
      </LineChart>
    )
  }
}

export class SimplePieChartPlayersLevels extends React.Component
  <{ data: any[], entity: string, colors: string[] }, {}> {
  public render() {
    return (
      <PieChart width={440} height={440} /*title = "adafadafa"*/>
        {/*<Legend />*/}
        <Pie
          data={this.props.data}

          cx={200}
          cy={200}
          labelLine={false}
          // label = {renderCustomizedLabelScandibet}
          outerRadius={180}
          fill="#8884d8"
          layout="vertical"
          align="center"
          dataKey="data"
        >
          {this.props.entity.toLowerCase().startsWith('scandibet') ||
            this.props.entity.toLowerCase().startsWith('winnie jackpot') ?
            this.props.data.map((entry, index) =>
              <Cell
                key={index.toString()}
                fill={this.props.colors[index % this.props.colors.length]}
              />,
            )
            :
            this.props.data.map((entry, index) =>
              <Cell key={index.toString()} fill={COLORS[index % COLORS.length]} />,
            )
          }
        </Pie>
      </PieChart>
    )
  }
}

export class SimpleBarChartPlayersSubLevels extends React.Component<{ data: any[] }, {}> {
  public render() {
    return (
      <BarChart
        width={700}
        height={300}
        data={this.props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="N"
          fill="#AF601A"
        // fill = {parseInt(Main.Instance.ReportLSState.filter_level) > 0 ?
        // Assets_.COLORS4[parseInt(Main.Instance.ReportLSState.filter_level) - 1] : COLORS10[0]}
        />
      </BarChart>
    );
  }
}

export class SimpleBarChartRST extends React.Component<{ data: any[] }, {}> {
  public render() {
    return (
      <BarChart
        width={1000}
        height={400}
        data={this.props.data}
        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="Free spins" fill="#e8c3b9" />
        <Bar dataKey="Free bets" fill="#8e5ea2" />
        <Bar dataKey="Free money" fill="#b1c28e" />
        <Bar dataKey="Holidays" fill="#3e95cd" />
        <Bar dataKey="Bonus" fill="#fddf8b" />
        <Bar dataKey="Merchandise" fill="#3cba9f" />
        <Bar dataKey="Product" fill="#c45850" />
      </BarChart>
    );
  }
}

export class SimpleBarChartRSTSmall extends React.Component<{ data: any[], unit: string, items: string[] }, {}> {
  public getBars(): Bar[] {
    const fills: string[] = ['#e8c3b9', '#fddf8b', '#3cba9f', '#c45850', '#3e95cd', '#b1c28e', '#8e5ea2', '#fdab8b']
    const bar: Bar[] = []
    for (let i: number = 0; i < this.props.items.length; i++) {
      bar.push(<Bar key={i.toString()} dataKey={this.props.items[i]} fill={fills[i]} />)
    }
    return bar
  }

  public render() {
    return (
      <BarChart
        name=""
        maxBarSize={30}
        width={500}
        height={200}
        barGap="20"
        data={this.props.data}
        margin={{ top: 0, right: 0, left: 5, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <YAxis unit={' ' + this.props.unit} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {/*<Legend margin = {{top: 0, right: 0, left: 0, bottom: 0}}/>*/}
        {this.getBars()}
        {/*this.props.items.length > 0 ?<Bar dataKey = {items[0]} fill = "#e8c3b9"/> : void 0*/}
        {/*<Bar dataKey = "Free bets" fill = "#8e5ea2"/>
            <Bar dataKey = "Free money" fill = "#b1c28e"/>
            <Bar dataKey = "Holidays" fill = "#3e95cd"/>
            <Bar dataKey = "Bonus" fill = "#fddf8b"/>
            <Bar dataKey = "Merchandise" fill = "#3cba9f"/>
            <Bar dataKey = "Product" fill = "#c45850"/>*/}
      </BarChart>
    );
  }
}

export class SimplePieChart extends React.Component<{ data: any[] }, {}> {
  public render() {
    return (
      <PieChart width={200} height={450}>
        <Legend />
        <Pie
          data={this.props.data}
          cx={100}
          cy={100}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          layout="vertical"
          align="left"
          dataKey="percentage"
        >
          {
            this.props.data.length === 2 ?
              this.props.data.map((entry, index) =>
                <Cell key={index.toString()} fill={COLORS2[index % COLORS2.length]} />) :
              this.props.data.map((entry, index) =>
                <Cell key={index.toString()} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    )
  }
}
/*
export const SimplePieChart2 = React.createClass({
	render () {
    //console.log (Main.Instance.DatabaseDataState.reportsDataStream.length)
  	return (
    	<PieChart width={200} height={450} onMouseEnter={this.onPieEnter}>
        <Legend />
        <Pie
          data={Main.Instance.DatabaseDataState.reportsPieChart2} 
          cx={100} 
          cy={100} 
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80} 
          fill="#8884d8"
        >
        	{
          	Main.Instance.DatabaseDataState.reportsPieChart2.map((entry, index) => 
            <Cell fill={COLORS[index % COLORS.length]}/>)
          }
        </Pie>
      </PieChart>
    );
  }
})

export const SimplePieChart3 = React.createClass({
	render () {
    //console.log (Main.Instance.DatabaseDataState.reportsDataStream.length)
  	return (
    	<PieChart width={200} height={450} onMouseEnter={this.onPieEnter}>
        <Legend />
        <Pie
          data={Main.Instance.DatabaseDataState.reportsPieChart3} 
          cx={100} 
          cy={100} 
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80} 
          fill="#8884d8"
        >
        	{
          	Main.Instance.DatabaseDataState.reportsPieChart3.map
            ((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
          }
        </Pie>
      </PieChart>
    );
  }
})
*/
/*
export const SimplePieChart4 = React.createClass({
	render () {
    //console.log (Main.Instance.DatabaseDataState.reportsDataStream.length)
  	return (
    	<PieChart width={200} height={450} onMouseEnter={this.onPieEnter}>
        <Legend />
        <Pie
          data={Main.Instance.DatabaseDataState.reportsPieChart4} 
          cx={100} 
          cy={100} 
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80} 
          fill="#8884d8"
        >
        	{
          	Main.Instance.DatabaseDataState.reportsPieChart4.map((entry, index) => 
            <Cell fill={COLORS2[index % COLORS2.length]}/>)
          }
        </Pie>
      </PieChart>
    );
  }
})


export const SimplePieChart5 = React.createClass({
	render () {
    //console.log (Main.Instance.DatabaseDataState.reportsDataStream.length)
  	return (
    	<PieChart width={200} height={450} onMouseEnter={this.onPieEnter}>
        <Legend />
        <Pie
          data={Main.Instance.DatabaseDataState.reportsPieChart5} 
          cx={100} 
          cy={100} 
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80} 
          fill="#8884d8"
        >
        	{
          	Main.Instance.DatabaseDataState.reportsPieChart5.map((entry, index) => 
            <Cell fill={COLORS2[index % COLORS2.length]}/>)
          }
        </Pie>
      </PieChart>
    );
  }
})
*/
/*
const data3 = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
                  {name: 'Group C', value: 300}, {name: 'Group D', value: 200},
                  {name: 'Group E', value: 278}, {name: 'Group F', value: 189}]

export const SimplePieChart = React.createClass({
	render () {
    console.log (Main.Instance.DatabaseDataState.reportsDataStream.length)
  	return (
    	<PieChart width={800} height={400}>
        <Pie  data={data3} cx={200} cy={200} outerRadius={80} fill="#8884d8" label/>
       </PieChart>
    );
  }
})
*/
/*
const data = [
      {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
      {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
      {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
      {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
      {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
      {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
      {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];

export const SimpleBarChart = React.createClass({
	render () {
  	return (
    	<BarChart width={600} height={300} data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <XAxis dataKey="name"/>
       <YAxis/>
       <CartesianGrid strokeDasharray="3 3"/>
       <Tooltip/>
       <Legend />
       <Bar dataKey="pv" fill="#8884d8" />
       <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart>
    );
  }
});

export const  SimpleLineChart = React.createClass({
	render () {
  	return (
    	<LineChart width={600} height={300} data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <XAxis dataKey="name"/>
       <YAxis/>
       <CartesianGrid strokeDasharray="3 3"/>
       <Tooltip/>
       <Legend />
       <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}}/>
       <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    );
  }
})

export const SimpleAreaChart = React.createClass({
	render () {
  	return (
    	<AreaChart width={600} height={400} data={data}
            margin={{top: 10, right: 30, left: 0, bottom: 0}}>
        <XAxis dataKey="name"/>
        <YAxis/>
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip/>
        <Area type='monotone' dataKey='uv' stroke='#8884d8' fill='#8884d8' />
      </AreaChart>
    );
  }
})

const data2 = [{x: 100, y: 200, z: 200}, {x: 120, y: 100, z: 260},
                  {x: 170, y: 300, z: 400}, {x: 140, y: 250, z: 280},
                  {x: 150, y: 400, z: 500}, {x: 110, y: 280, z: 200}]

export const SimpleScatterChart = React.createClass({
	render () {
  	return (
    	<ScatterChart width={400} height={400} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
      	<XAxis dataKey={'x'} name='stature' unit='cm'/>
      	<YAxis dataKey={'y'} name='weight' unit='kg'/>
      	<Scatter name='A school' data={data2} fill='#8884d8'/>
      	<CartesianGrid />
      	<Tooltip cursor={{strokeDasharray: '3 3'}}/>
      </ScatterChart>
    );
  }
})




*/

{/* Pie attribute if you want halfpie startAngle={180} endAngle={0}*/ }

export class PlayerRiskChart extends React.Component<{ data: any[] }, {}> {
  public render() {
    return (
      <PieChart width={200} height={300}>
        <Legend />
        <Pie data={this.props.data} cx="50%" cy="50%" outerRadius={100} fill="#8884d8">
          {
            this.props.data.length === 2 ?
              this.props.data.map((entry, index) =>
                <Cell key={index.toString()} fill={COLORSPlayerRisk[index % COLORSPlayerRisk.length]} />) :
              this.props.data.map((entry, index) =>
                <Cell key={index.toString()} fill={COLORSPlayerRisk[index % COLORSPlayerRisk.length]} />)
          }

        </Pie>
      </PieChart>
    )
  }
}

export class SwitzerlandMapDensity extends React.Component<{ data: any[], title: string, palette: string[] }, {}> {
  public render() {
    return (
      <>
        <h4 style={{ width: '700px', textAlign: 'center' }}>{this.props.title}</h4>
        <BarChart
          showTitle={true}
          title={this.props.title}
          width={900}
          height={200}
          data={this.props.data}
        // margin = {{top: 5, right: 30, left: 20, bottom: 5}}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          {/*<Legend />*/}
          <Bar
            dataKey="items"
            fill="#AF601A"
          // fill = {parseInt(Main.Instance.ReportLSState.filter_level) > 0 ?
          // Assets_.COLORS4[parseInt(Main.Instance.ReportLSState.filter_level) - 1] : COLORS10[0]}
          >
            {
              this.props.data.map((entry, index) =>
                <Cell key={index.toString()} fill={this.props.palette[index]} />)
            }
          </Bar>
        </BarChart>
      </>
    )
  }
}

export class BarDistribution extends React.Component<{Width: number, barDistribution:Array<{key: string, value: number, key2?: string}>/*, regionName: string*/}, {}> {
    public render() {
    return (
    <div>
        <BarChart
            width={this.props.Width === 0 ? 190 : 190 + (this.props.Width - 1108) / 2}
            height={300}
            data={this.props.barDistribution}
            margin={{ top: 70, right: 0, left: -30, bottom: 0 }}
        >
            <XAxis height = {50} dataKey="key" minTickGap = {0}>
                <Label value = {(Main.Instance.PlayerInfo.statType === StatType.Hourly ? Assets.Hour : Main.Instance.PlayerInfo.statType === StatType.InWeek ? Assets.DayInWeek : Main.Instance.PlayerInfo.statType === StatType.Daily ? Assets.DayInMonth : Main.Instance.PlayerInfo.statType === StatType.Monthly ? Assets.Month : Main.Instance.PlayerInfo.statType === StatType.Quarterly ? Assets.Quarter : Main.Instance.PlayerInfo.statType === StatType.Annual ? Assets.Year : '').toLowerCase()}/>
            </XAxis>
            <YAxis width = {80}>
                <Label angle ={-90} value = {Assets.Players.toLowerCase()}/>
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Bar dataKey="value" fill="#01579b"/>
            {/*a.map((entry, index) =>
            <Cell key={index.toString()} fill={densityPallette[Main.Instance.DatabaseDataState.classStats[index].value]}/>)*/}
            {/*<LabelList dataKey="region" position="top" fill = "#333"/>*/}
            {/*</Bar>*/}
        </BarChart>
    </div>)
    }
}

export class PieDataChart extends React.Component<{statType: StatType, Width: number, barDistribution?:Array<{key: string, value: number, key2?: string}>/*, title: string, startAngle?: number, endAngle?: number*/}, {}> {
    public render() {
        // console.log (this.props.statType)
        if (this.props.statType === StatType.Cantons && Main.Instance.DatabaseDataState.orderedStats === undefined) {
            return <div/>
        }
        
        // console.log(Main.Instance.DatabaseDataState.orderedStats)
        
        const orderedStats: Array<{value: number, value2: string, region: string, name: string, country: string, c: string, Country: string, Value2: number}> = []
        if (Main.Instance.DatabaseDataState.orderedStats && Main.Instance.DatabaseDataState.orderedStats.length > 0) {
          orderedStats.push({
            Value2: 0,
            value2: '', 
            value: 0,
            region: Assets.Other,
            country: Main.Instance.DatabaseDataState.orderedStats[0].country,
            name: Assets.Other,
            c: Assets.Other,
            Country: Main.Instance.DatabaseDataState.orderedStats[0].Country
          })
          for (let i = 0; i < Main.Instance.DatabaseDataState.orderedStats.length; i++) {
            if (i < Main.Instance.DatabaseDataState.orderedStats.length - 7) {
              orderedStats[0].value += Main.Instance.DatabaseDataState.orderedStats[i].value
              orderedStats[0].Value2 += Main.Instance.DatabaseDataState.orderedStats[i].Value2
            } else {
              orderedStats.push(Main.Instance.DatabaseDataState.orderedStats[i])
            }
          }
          orderedStats[0].value2 = orderedStats[0].Value2 + '%'
        }

        // console.log(orderedStats)

        let sum: number = 0
        const barDistribution: Array<{key: string, Value2: number, value2: string, value: number, key2?: string}> = []
        if (this.props.barDistribution) {
            for (const item of this.props.barDistribution) {
                sum += item.value
            }
            if (sum > 0) {
                for (const item of this.props.barDistribution) {
                    barDistribution.push ({key: item.key, key2: item.key2, value: item.value, Value2: parseFloat((item.value / sum * 100).toFixed(2)), value2: (item.value / sum * 100).toFixed(2) + '&'})
                }
            }
        }
/*
        let sum: number = undefined
        if (Main.Instance.DatabaseDataState.orderedStats) {
            sum = 0
            Main.Instance.DatabaseDataState.orderedStats.map(entry => sum += entry.value)
        }
*/
/*
    const RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        // if (this.props.cc.label === undefined) return void 0
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
        };
*/
/*
        const RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            return (
                <text>{`${(percent * 100).toFixed(0)}%`}</text>
            )
        }
*/
        const barDistributionNoNull: Array<{key: string, value: number, Value2: number, value2: string, key2?: string}> = []
        if (this.props.statType === StatType.Nationality) {
            for (const item of this.props.barDistribution) {
                if (item.value > 0) {
                    barDistributionNoNull.push({key: item.key, key2: item.key2, value: item.value, Value2: parseFloat((item.value / sum * 100).toFixed(2)), value2: (item.value / sum * 100).toFixed(2) + '%'}) // item
                }
            }
        }
        // console.log (Main.Instance.DatabaseDataState.orderedStats)
        // console.log (this.props.barDistribution)
/*
        const RenderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
          }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
            
                return (
                    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                        {`${(percent * 100).toFixed(0)}%`}
                    </text>
                );
          };
*/        
        const RenderCustomizedLabel = ({
          cx, cy, midAngle, innerRadius, outerRadius, percent, index
          }) => {
            const radius = 23 + outerRadius;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
        
            return (
              <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fill={SwitzerlandCantonsColors[index % SwitzerlandCantonsColors.length]} fontSize="13.5px">
                  {`${(percent * 100).toFixed(2)}%`}
              </text>
            );
        };
       

        return (
        <div>
            {/*sum ? <div style={{ position: 'absolute', left: '5px', bottom: '25px' }}>{Assets.Total}: {sum}</div> : void 0*/}
            <PieChart width={(this.props.Width - 1108) / 2 + 130} height={620} margin={{top: 0, right: 0, left: 0, bottom: 5 }}>
            {/*this.props.startAngle !== undefined ? <Legend /> : void 0*/}
            <Legend/>
            <Pie
                label={RenderCustomizedLabel}
                // label = {true}
                // labelLine={false}
                // startAngle={this.props.startAngle !== undefined ? this.props.startAngle : undefined}
                // endAngle={this.props.endAngle !== undefined ? this.props.endAngle : undefined}
                legendType="circle"
                nameKey={this.props.statType === StatType.Cantons ? 'region' : (this.props.statType === StatType.Nationality && barDistributionNoNull.length > 20 ? 'key2' : 'key')}
                dataKey="Value2"
                data={this.props.statType === StatType.Nationality ? barDistributionNoNull : this.props.statType === StatType.Cantons ? orderedStats : (/*this.props.*/barDistribution ? /*this.props.*/barDistribution : [])}
                cx={/*this.props.startAngle !== undefined ? '50%' : */'50%'}
                cy={/*this.props.startAngle !== undefined ? '95%' : */'50%'}
                outerRadius={/*this.props.startAngle !== undefined ? 160 : */(this.props.Width - 1108) / 4}
                fill="#8884d8"
                
                
            >
                {this.props.statType === StatType.Nationality ?
                barDistributionNoNull.map((entry, index) =>
                <Cell key={index.toString()} fill={SwitzerlandCantonsColors[index % SwitzerlandCantonsColors.length]} />)
                :                
                this.props.statType === StatType.Cantons ?
                orderedStats.map((entry, index) =>
                <Cell key={index.toString()} fill={SwitzerlandCantonsColors[index % SwitzerlandCantonsColors.length]} />)
                :
                /*this.props.*/barDistribution ?
                /*this.props.*/barDistribution.map((entry, index) =>
                <Cell key={index.toString()} fill={SwitzerlandCantonsColors[index % SwitzerlandCantonsColors.length]} />)
                : void 0}
            </Pie>
            </PieChart>
        </div>
      )
    }
  }

export class DataChart extends React.Component<{Width: number, title: string}, {}> {
    // renderCustomLabel(obj) {console.log (obj)}
    public render() {
      if (Main.Instance.DatabaseDataState.orderedStats === undefined || Main.Instance.DatabaseDataState.classStats === undefined) {
          return <div/>
      }
      // console.log (JSON.stringify(Main.Instance.DatabaseDataState.orderedStats))
      // const a = Main.Instance.DatabaseDataState.orderedStats.reverse()
      // console.log (JSON.stringify(Main.Instance.DatabaseDataState.classStats))
      // const b = Main.Instance.DatabaseDataState.classStats.reverse()
      // console.log (densityPallette)
      const a = []
      for (const item of JSON.parse(JSON.stringify(Main.Instance.DatabaseDataState.orderedStats))) {
          a.push({value: Main.Instance.PlayerInfo.stat === Stat.Population ? item.value / 1000 : item.value, region: item.region, country: item.country, name: item.name, c: item.c})
      }
      // console.log (a)
      return (
          <BarChart
            // title="tu bo naslov"
            width={this.props.Width === 0 ? 290 : 290 + (this.props.Width - 1108) / 2}
            height={225}
            data={a/*Main.Instance.DatabaseDataState.orderedStats*/}
            margin={{ top: 15, right: 0, left: -10, bottom: 0 }}
          >
            {/*this.props.cc.changeOrientation ?
              <XAxis dataKey={this.props.cc.dataKey} angle={-60} dy={7} dx={-10} tick={{ fontSize: 10 }} interval={0} />
            : <XAxis dataKey={this.props.cc.dataKey} />*/}
            <XAxis height = {50} dataKey={Main.Instance.PlayerInfo.c ? 'c' : 'region'} minTickGap = {0}>
                <Label value = {this.props.title}/>
            </XAxis>
            <YAxis width = {120}/*85 hide = {true}*/>
                <Label angle ={-90} value = {Main.Instance.PlayerInfo.stat === Stat.Population ? (Assets.Thousand + ' ' + Assets.People).toLowerCase() : Assets.Players.toLowerCase()}/>
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {/*<Legend wrapperStyle={{ left: 10 }}/>*/}
            {/*<Bar dataKey="value" fill="#01579b"/>*/}
            <Bar /*label = {(obj) => this.renderCustomLabel(obj)}*/ dataKey="value" /*fill="#01579b"*/>
            {/*Main.Instance.DatabaseDataState.orderedStats*/a.map((entry, index) =>
              <Cell key={index.toString()} fill={densityPallette[Main.Instance.DatabaseDataState.classStats[index].value]}/>)}
              {/*<LabelList dataKey="region" position="top" fill = "#333"/>*/}
            </Bar>
            {/*<Bar dataKey="value0" fill={densityPallette[0]}/>*/}
            {/*GetDataBar()*/}
            {/*this.props.cc.dataValue && this.props.cc.palette ? createBar(this.props.cc.dataValue, this.props.cc.palette, this.props.cc.data, 0) : void 0*/}
          </BarChart>
      )
    }
}
/*
function GetDataBar(): JSX.Element[] {
    const gdb: JSX.Element[] = []
    for (let i: number = 0; i < densityPallette.length; i++) {
        gdb.push(<Bar dataKey={'value' + i} fill={densityPallette[i]}/>)
    }
    return gdb
}
*/
const dashboardChartWidth: number = 390
const dashboardChartHeight: number = 220
const dashboardChartWidthExpanded: number = 1170
const dashboardChartHeightExpanded: number = 737

export class DashboardBarChart extends React.Component<{ cc: ChartContent, expanded: boolean }, {}> {
  public render() {
    return (
      <>
        {/*this.props.cc.title ? <h4 style = {{width: '322px', textAlign: 'center'}}>{this.props.cc.title}</h4> : void 0*/}
        <BarChart
          title={this.props.cc.title}
          width={this.props.expanded ? dashboardChartWidthExpanded : dashboardChartWidth}
          height={this.props.expanded ? dashboardChartHeightExpanded : dashboardChartHeight}
          data={this.props.cc.data}
          margin={{ top: 15, right: 0, left: -10, bottom: 0 }}
        >
          {this.props.cc.changeOrientation ?
            <XAxis dataKey={this.props.cc.dataKey} angle={-60} dy={7} dx={-10} tick={{ fontSize: 10 }} interval={0} />
            : <XAxis dataKey={this.props.cc.dataKey} />}
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend wrapperStyle={{ left: 10 }}/*width = {200} wrapperStyle = {{left: 0, right: 0, margin: 'auto'}}*/ />

          {this.props.cc.dataValue && this.props.cc.palette ? createBar(this.props.cc.dataValue, this.props.cc.palette, this.props.cc.data, 0) : void 0}
          {this.props.cc.dataValue2 && this.props.cc.palette ? createBar(this.props.cc.dataValue2, this.props.cc.palette, this.props.cc.data, 1) : void 0}
          {this.props.cc.dataValue3 && this.props.cc.palette ? createBar(this.props.cc.dataValue3, this.props.cc.palette, this.props.cc.data, 2) : void 0}
          {this.props.cc.dataValue4 && this.props.cc.palette ? createBar(this.props.cc.dataValue4, this.props.cc.palette, this.props.cc.data, 3) : void 0}
          {this.props.cc.dataValue5 && this.props.cc.palette ? createBar(this.props.cc.dataValue5, this.props.cc.palette, this.props.cc.data, 4) : void 0}
          {this.props.cc.dataValue6 && this.props.cc.palette ? createBar(this.props.cc.dataValue6, this.props.cc.palette, this.props.cc.data, 5) : void 0}
        </BarChart>
      </>
    )
  }
}

function createBar(dataValue: string, palette: string[], data: any[], index: number): JSX.Element {
  return (
    <Bar dataKey={dataValue} stackId="a" fill={palette[index]}>
      {/* {palette === undefined ? void 0 :
      data.map((entry, index) =>
      <Cell key = {index.toString()} fill = {palette[index]}/>)} */}
    </Bar>
  )
}
/*<Legend layout = "vertical" wrapperStyle = {{top: 10, left: 60, right: 10, bottom: 0}}/>*/
/*
4D4D4D (gray)
5DA5DA (blue)
FAA43A (orange)
60BD68 (green)
F17CB0 (pink)
B2912F (brown)
B276B2 (purple)
DECF3F (yellow)
F15854 (red)
*/

export class DashboardPieChart extends React.Component<{ cc: ChartContent, startAngle?: number, endAngle?: number }, {}> {
  public render() {
    let sum: number
    if (this.props.cc.data) {
      if (this.props.cc.data[0].players) {
        sum = 0
        this.props.cc.data.map((entry) => sum += entry.players)
      }
    }
    const RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      if (this.props.cc.label === undefined) { return void 0 }
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )
    };
    return (
      <div>
        {sum ? <div style={{ position: 'absolute', left: '5px', bottom: '25px' }}>{Assets.Total}: {sum}</div> : void 0}
        <PieChart width={dashboardChartWidth} height={dashboardChartHeight}>
          {this.props.startAngle !== undefined ? <Legend /> : void 0}
          <Pie
            label={RenderCustomizedLabel}
            labelLine={false}
            startAngle={this.props.startAngle !== undefined ? this.props.startAngle : undefined}
            endAngle={this.props.endAngle !== undefined ? this.props.endAngle : undefined}
            legendType="circle"
            dataKey={this.props.cc.dataValue}
            data={this.props.cc.data}
            cx={this.props.startAngle !== undefined ? '50%' : '33%'}
            cy={this.props.startAngle !== undefined ? '95%' : '50%'}
            outerRadius={this.props.startAngle !== undefined ? 160 : 100}
            fill="#8884d8"
          >
            {this.props.cc.data.map((entry, index) =>
              <Cell key={index.toString()} fill={this.props.cc.palette[index % this.props.cc.palette.length]} />)}
          </Pie>
        </PieChart>
      </div>
    )
  }
}

export class DashboardAreaChart extends React.Component<{ cc: ChartContent, subtype: string }, {}> {

  public gradientOffset = () => {
    const dataMax = Math.max(...this.props.cc.data.map((i) => i[this.props.cc.dataValue]))
    const dataMin = Math.min(...this.props.cc.data.map((i) => i[this.props.cc.dataValue]))

    if (dataMax <= 0) {
      return 0
    } else if (dataMin >= 0) {
      return 1
    } else {
      return dataMax / (dataMax - dataMin);
    }

  }

  public render() {
    // console.log (this.props.subtype)
    const off = this.props.subtype === Assets.AreaChart2 ? this.gradientOffset() : undefined
    return (
      <AreaChart
        width={dashboardChartWidth}
        height={dashboardChartHeight}
        data={this.props.cc.data}
        margin={{ top: 0, right: 0, left: -10, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={this.props.cc.dataKey} />
        <YAxis />
        <Tooltip />
        <defs>
          {this.props.subtype === Assets.AreaChart2 ?
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="green" stopOpacity={1} />
              <stop offset={off} stopColor="red" stopOpacity={1} />
            </linearGradient>
            : this.props.subtype === Assets.AreaChart ?
              <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient> : void 0}
          {this.props.cc.dataValue2 ?
            <linearGradient id="gradientColor2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            : void 0}
        </defs>
        <Area type="monotone" dataKey={this.props.cc.dataValue} stroke={this.props.subtype === Assets.AreaChart2 ? '#000' : '#82ca9d'} fillOpacity={1} fill={'url(#' + (this.props.subtype === Assets.AreaChart2 ? 'splitColor' : this.props.subtype === Assets.AreaChart ? 'gradientColor' : void 0) + ')'} />
        {this.props.cc.dataValue2 ? <Area type="monotone" dataKey={this.props.cc.dataValue2} stroke="#8884d8'" fillOpacity={1} fill="url(#gradientColor2)" /> : void 0}
      </AreaChart>
    )
  }
}



export class CustomTooltip extends React.Component<{ payload: any }, {}> {
  public render() {
    if (this.props.payload.length > 0) {
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ color: 'black' }}>{this.props.payload[0].payload.event}</p>

        </div>
      )
    } else { return '' }
  }
}

export function getReferences(x: number | string, y: number | string, label: string, expanded: boolean): JSX.Element {
  return (
    <ReferenceDot key = {'refDot' + x + '--' + y} x={x} y={y} r = {5} fill="#ff9900" stroke="none">
      {expanded? <Label value = {label} offset={5} position="top" style = {{fontSize: '12px'}}/>: void 0}
    </ReferenceDot>
  )
}

export class SpecialLineChart extends React.Component<{ cc: ChartContent, expanded: boolean }, {}> {
  public render() {
    console.log('SpecialLineChart re-render')
    const references: JSX.Element[] = []
    this.props.cc.data.forEach((item) => { if (item.event && item.event.length > 0) { references.push(getReferences(item.date, item.revenue, item.event, this.props.expanded)) }})
    return (
      <LineChart
        width={this.props.expanded? dashboardChartWidthExpanded: dashboardChartWidth}
        height={this.props.expanded? dashboardChartHeightExpanded: dashboardChartHeight}
        data={this.props.cc.data}
        margin={{ top: 15, right: 5, left: -10, bottom: 0 }}
      >
        {this.props.cc.changeOrientation ?
            <XAxis dataKey={this.props.cc.dataKey} angle={-60} dy={7} dx={-10} tick={{ fontSize: 10 }} interval={0} />
            : <XAxis dataKey={this.props.cc.dataKey} />}
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={(props) => <CustomTooltip {...props} />} />
        <Legend />
        <Line
          type="monotone"
          dataKey={this.props.cc.dataValue}
          stroke="#8884d8"
          animationDuration={0}
        />
        {references}
      </LineChart>
    )
  }
}
