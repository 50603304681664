import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import {/*FormInput,*/ FormInputNumber} from '../../Reusables/Inputs'
// import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
// import * as TableUtils from '../../Reusables/TableUtils'
// import {faSave, faExternalLinkAlt, faPaintRoller, faCode, faSitemap, faPlus, /*faTrashAlt*/} from '@fortawesome/free-solid-svg-icons'
// import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
// import * as Config from '../../../Logic/Config'
// import {GameProviderNew} from '../../../Logic/Database/DatabaseData'
import Switch from 'react-toggle-switch'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {/*gotoTop,*/ isUserGamanzaGamesAdmin} from '../../../Logic/Utils'
// import {getTimestamp, getWinners, getParticipants} from '../MainDashboard/Bonus'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faUsers, faTicketAlt, faCheck} from '@fortawesome/free-solid-svg-icons'
// import {getRiskManager} from '../RiskComplianceStore/ResponsibleGaming'
import * as Assets_ from '../../Assets_'

/*
function removeCategory(i: number) {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    gameProvider.categories.splice(i, 1)
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider})
}

function addCategory() {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    if (gameProvider.categories === undefined) { gameProvider.categories = [] }
    gameProvider.categories.push(Main.Instance.GameProviderState.category)
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider, category: undefined})
}
*/
{/*disabled = {true}*/}
/*
function getRgsgsOptions(): JSX.Element[] {
    const rgsgs: JSX.Element[] = []
    rgsgs.push (<option key = "0" value = "0">{Assets.SelectRGSGameSupplier}</option>)
    if (Main.Instance.DatabaseDataState.rgsGameSupplierNew) {
        for (const item of Main.Instance.DatabaseDataState.rgsGameSupplierNew) {
            rgsgs.push (<option key = {item.id.toString()} value = {item.id.toString()} disabled = {getDisabled(item.id)}>{item.name}</option>)
        }
    }
    return rgsgs
}


function getDisabled(id: number): boolean {
    if (Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers) {
        for (const item of Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers) {
            if (item === id) { return true }
        }
    }
    return false
}
*/

/*
function removeRgsgs(i: number) {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    gameProvider.rgsGameSuppliers.splice(i, 1)
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider})
}

function addRgsgs() {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    if (gameProvider.rgsGameSuppliers === undefined || gameProvider.rgsGameSuppliers === null) {
        gameProvider.rgsGameSuppliers = []
    }
    gameProvider.rgsGameSuppliers.push(Main.Instance.GameProviderState.rgsgs)
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider, rgsgs: undefined})
}
*/

/*
function GetCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []
    categories.push(<button title = {Assets.AddGameProviderGamesCategory} style = {{fontSize: '14px', lineHeight: '24px', padding: '1px 7px', marginLeft: '10px'}} onClick = {() => addCategory()} className = {Main.Instance.GameProviderState.mode ? 'btn btn-warning btn-sm' : 'btn btn-primary btn-sm'} key = "add"><FontAwesomeIcon icon = {faPlus}/></button>)
    if (Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.categories) {
        for (let i: number = 0; i < Main.Instance.GameProviderState.gameProvider.categories.length; i++) {
            categories.push(<button title = {Assets.RemoveGameProviderGamesCategory} style = {{marginRight: i === Main.Instance.GameProviderState.gameProvider.categories.length - 1 ? '0px' : '', fontSize: '14px', lineHeight: '24px', padding: '1px 7px'}} onClick = {() => removeCategory(i)} className = "btn btn-info btn-sm" key = {i.toString()}>{Main.Instance.GameProviderState.gameProvider.categories[i]}</button>)
        }
    }
    return categories
}
*/

// Main.Instance.GameProviderState.gameProvider.rgsGameSupplierDefault
/*
function toggleRgsgs(rgsgs: number) {
    if (rgsgs !== Main.Instance.GameProviderState.gameProvider.rgsGameSupplierDefault) {
        const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
        // if (gameProviderNew === undefined) gameProviderNew = {}
        // if (gameProvider.rgsGameSuppliers === undefined) gameProvider.rgsGameSuppliers = []
        // for (const item of gameProvider.rgsGameSuppliers)
        gameProvider.rgsGameSupplierDefault = rgsgs
        Logic.Type.New (Main.Instance.GameProviderState, {gameProvider, rgsgs: undefined})
    }
}
*/
/*
function GetRgsgs(): JSX.Element[] {
    const rgsgs: JSX.Element[] = []
    rgsgs.push(<button title = {Assets.AddGameProviderRgsGameSupplier} style = {{fontSize: '14px', lineHeight: '24px', padding: '1px 7px', marginLeft: '10px'}} onClick = {() => addRgsgs()} className = {Main.Instance.GameProviderState.mode ? 'btn btn-warning btn-sm' : 'btn btn-primary btn-sm'} key = "add"><FontAwesomeIcon icon = {faPlus}/></button>)
    if (Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers) {
        for (let i: number = 0; i < Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers.length; i++) {
            rgsgs.push(<button onClick = {() => toggleRgsgs(Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers[i])} title = {Assets.RemoveGameProviderRgsGameSupplier} style = {{marginRight: i === Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers.length - 1 ? '0px' : '', fontSize: '14px', lineHeight: '24px', padding: '1px 7px'}} className = {Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers[i] === Main.Instance.GameProviderState.gameProvider.rgsGameSupplierDefault ? 'btn btn-default btn-dark btn-sm br0 mr0' : 'btn btn-default btn-sm br0 mr0'} key = {i.toString()}>{getRGSGS(Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers[i])}</button>)
            rgsgs.push(<button title = {Assets.RemoveGameProviderRgsGameSupplier} style = {{marginRight: i === Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers.length - 1 ? '0px' : '', fontSize: '14px', lineHeight: '24px', padding: '1px 7px'}} onClick = {() => removeRgsgs(i)} className = "btn btn-danger btn-sm br0" key = {i.toString() + '_'}><FontAwesomeIcon icon = {faTrashAlt}/></button>)
        }
    }
    return rgsgs
}
*/

export function getGameProviderCount() {
}
/*
function getCompleted(completed: string) {
    return (
        <div
            title = {completed === 'true' ? Assets.PreviousBonuss : Assets.NextBonuss}
            style = {{position: 'absolute', width: '100%', height: '100%', textAlign: 'center', lineHeight: '48px'}}
            className = {completed === 'true'  ? 'Risk risk-Normal' : 'Risk risk-Severe'}
        >
            {completed === 'true'  ? Assets.YesShort : Assets.NoShort}
        </div>
    )
}
*/

/*
function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.GameProvider + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {
            if (Main.Instance.GameProviderState.pNum === (Math.trunc((Main.Instance.GameProviderState.count - 2) / Main.Instance.GameProviderState.pSize) + 1)) {
                // DbRoutes.wsBasicDelete(Config.amGameProviders, row.original.id)
                DbRoutes.deleteGameProviderNew(row.original.id)
                // DbRoutes.wsBasicDelete(Config.amRgsGameSupplier, row.original.id)
                // BUG
                const gameProviderNew: GameProviderNew[] = Object.assign ([], Main.Instance.DatabaseDataState.gameProviderNew)
                gameProviderNew.splice(row.index, 1)
                Logic.Type.New(Main.Instance.DatabaseDataState, {gameProviderNew})

            } else {
                DbRoutes.deleteGameProviderNew(row.original.id)
                // DbRoutes.wsBasicDelete(Config.amGameProviders, row.original.id) 
                const gameProviderNew: GameProviderNew[] = Object.assign ([], Main.Instance.DatabaseDataState.gameProviderNew)
                gameProviderNew.splice(row.index, 1)
                Logic.Type.New(Main.Instance.DatabaseDataState, {gameProviderNew})
            }
        }
    }
}
*/

/*
function handleEdit(row: any) {
    gotoTop()
   
    // console.log (row.original)
    const gameProvider: GameProviderNew = Object.assign ({}, JSON.parse(JSON.stringify(row.original)))
    if (row) { Logic.Type.New(Main.Instance.GameProviderState, {
        mode: true,
        gameProvider,
        gameProvider_: JSON.stringify(gameProvider),
        name_saved: gameProvider && gameProvider.name ? gameProvider.name : '',
        category: '',
        rgsgs: 0,
    })
    }
}
*/

/*
function updateText(value: string, property: string) {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    gameProvider[property] = value
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider})
}

function updateBoolean(property: string) {
    const gameProvider: GameProviderNew = Object.assign({}, Main.Instance.GameProviderState.gameProvider)
    gameProvider[property] = !gameProvider[property]
    Logic.Type.New (Main.Instance.GameProviderState, {gameProvider})
}
*/

/*
function UpdateBoolean(property: string, index: number) {
    const gameProvider: GameProviderNew[] = Object.assign([], Main.Instance.DatabaseDataState.gameProviderNew)
    const gameProvider_New: string[] = Object.assign([], Main.Instance.DatabaseDataState.gameProvider_New)
    gameProvider[index][property] = !gameProvider[index][property]
    gameProvider_New[index] = JSON.stringify(gameProvider[index])
    Logic.Type.New (Main.Instance.DatabaseDataState, {gameProviderNew: gameProvider, gameProvider_New})
    // updateGameProviderNew
    DbRoutes.updateGameProviderNew(gameProvider[index])
    // DbRoutes.wsBasicUpdate(Config.amGameProviders, Main.Instance.DatabaseDataState.gameProviderNew[index].id, Main.Instance.DatabaseDataState.gameProviderNew[index].data)
}
*/
/*
function updateNumber(value: number, property: string) {
    let bonus = Object.assign({}, Main.Instance.GameProviderState.bonus)
    if (bonus === undefined) bonus = {}
    if (bonus.data === undefined) bonus.data = {}
    bonus.data[property] = value
    Logic.Type.New (Main.Instance.GameProviderState, {bonus})
}
*/
/*
function handleInsertUpdate() {

    // console.log (Main.Instance.GameProviderState.gameProvider)

    if (Main.Instance.GameProviderState.gameProvider) {
        if (Main.Instance.GameProviderState.gameProvider.name === undefined ||
            Main.Instance.GameProviderState.gameProvider.name.length === 0 ) {
                alert (Assets.gp1)
                return
            }
    } else {
        alert (Assets.gp1)
        return
    }
    // console.log (Main.Instance.GameProviderState.gameProvider)
    // const gameProvider: GameProviderNew = Object.assign ({}, Main.Instance.GameProviderState.gameProvider)
    const gameProviderNew: GameProviderNew[] = Object.assign ([], Main.Instance.DatabaseDataState.gameProviderNew)
    if (Main.Instance.GameProviderState.mode === false) {
        let found: boolean = false
        if (Main.Instance.DatabaseDataState.gameProviderNew) {
            for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
                if (/*gameProvider.gameIntegrationEndpoint !== '' && item.gameIntegrationEndpoint === Main.Instance.GameProviderState.gameProvider.gameIntegrationEndpoint) {
                    found = true
                    alert(Assets.GameIntegrationEndPointShouldNotBeDuplicated)
                    break
                }

                if (item.name === Main.Instance.GameProviderState.gameProvider.name) {
                    alert(Assets.GameProviderNameShouldNotBeDuplicated)
                    found = true
                    break
                }
            }
        }
        if (!found) {
            // console.log (gameProvider)
            DbRoutes.insertGameProviderNew(Main.Instance.GameProviderState.gameProvider)
            // DbRoutes.wsBasicInsert(Config.amGameProviders, gameProvider)
        }
    } else {
        // console.log (gameProviderNew)
        for (const item of gameProviderNew) {
            if (item.providerId === Main.Instance.GameProviderState.gameProvider.id) {
                item.active = Main.Instance.GameProviderState.gameProvider.active
                item.categories = Main.Instance.GameProviderState.gameProvider.categories
                item.gameIntegrationEndpoint = Main.Instance.GameProviderState.gameProvider.gameIntegrationEndpoint
                item.gameLaunchEndpoint = Main.Instance.GameProviderState.gameProvider.gameLaunchEndpoint
                item.name = Main.Instance.GameProviderState.gameProvider.name
                item.providerId = Main.Instance.GameProviderState.gameProvider.providerId
                item.rgsGameSupplierDefault = Main.Instance.GameProviderState.gameProvider.rgsGameSupplierDefault
                item.rgsGameSuppliers = Main.Instance.GameProviderState.gameProvider.rgsGameSuppliers
                break
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameProviderNew})
        // console.log (Main.Instance.GameProviderState.gameProvider)
        DbRoutes.updateGameProviderNew(Main.Instance.GameProviderState.gameProvider)
        // DbRoutes.wsBasicUpdate(Config.amGameProviders, gameProvider.providerid, gameProvider)
    }
    clearFields()
}
*/

function getGameProvider() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {GameProviderGetNew: true})
    DbRoutes.getGameProviders()
}

export function clearFields() {
    Logic.Type.New(Main.Instance.GameProviderState, {
        mode: false,
        gameProvider: undefined,
        gameProvider_: undefined,
        category: undefined,
        rgsgs: undefined,
    })
}

/*
function gotoInsertMode() {
    if (JSON.stringify(Main.Instance.GameProviderState.gameProvider) === Main.Instance.GameProviderState.gameProvider_) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}
*/

function FirstPage() {
    Logic.Type.New(Main.Instance.GameProviderState, {offset:(0).toString()})
    getGameProvider()
    Logic.Type.New(Main.Instance.GameProviderState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.GameProviderState.pNum > 0) {

        Logic.Type.New(Main.Instance.GameProviderState, {
            offset:((Main.Instance.GameProviderState.pNum - 1) * 
            Main.Instance.GameProviderState.pSize).toString()})
        getGameProvider()

        Logic.Type.New(Main.Instance.GameProviderState,{pNum: Main.Instance.GameProviderState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.GameProviderState.pNum + 1) * Main.Instance.GameProviderState.pSize < 
    Main.Instance.GameProviderState.count) {

        Logic.Type.New(Main.Instance.GameProviderState, {
            offset: ((Main.Instance.GameProviderState.pNum + 1) * 
            Main.Instance.GameProviderState.pSize).toString()})
        getGameProvider()
        
        Logic.Type.New(Main.Instance.GameProviderState,{pNum: Main.Instance.GameProviderState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.GameProviderState.count <= Main.Instance.GameProviderState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.GameProviderState.count === 0) {

            Logic.Type.New(Main.Instance.GameProviderState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.GameProviderState.count - 1) / 
                    Main.Instance.GameProviderState.pSize)
            Logic.Type.New(Main.Instance.GameProviderState, {
                offset:(npNum * Main.Instance.GameProviderState.pSize).toString()})
        }

        getGameProvider()

        Logic.Type.New(Main.Instance.GameProviderState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GameProviderState,{count:Main.Instance.GameProviderState.count ++})
    LastPage()
}

export function Update() {
    clearFields()
    getGameProvider()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GameProviderState,{count:Main.Instance.GameProviderState.count --})
    LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.GameProviderState,{count:Main.Instance.GameProviderState.count --})
    getGameProvider()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.GameProviderState, {pSize: parseInt(text)})
        FirstPage()
    }
}

export function GameProviders() {
    // console.log (Main.Instance.DatabaseDataState.bonus)
    if (Main.Instance.GameProviderState.count) {

    } else if (Main.Instance.DatabaseDataState.gameProviderNew) {
        Logic.Type.New (Main.Instance.GameProviderState, {count: Main.Instance.DatabaseDataState.gameProviderNew.length})
    }
    DbRoutes.wsReinitialize()
    return (<div>
    <div className = "clearfix">
        {/* {isUserGamanzaGamesAdmin() ?
        <h3 className = "level fl">
            {Assets.aeGameProvider}
        </h3> : void 0} */}
        <h3>{Assets.lGameProvider}</h3>
        {/*isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"
            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {text => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0*/}

        {/*isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {text => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0*/}

        {/*!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {text => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0*/}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            {/*isUserGamanzaGamesAdmin() ?
            <div 
                className = {Main.Instance.GameProviderState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>{Main.Instance.GameProviderState.mode ? Assets.eGameProvider + ': ' + Main.Instance.GameProviderState.name_saved  : Assets.aGameProvider}</h4>

                        {Main.Instance.GameProviderState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}
                    </div>

                    <div className = "row admin-users-row">
                        <FormInput
                            description = {Assets.GameProvider}
                            value = {Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.name ? Main.Instance.GameProviderState.gameProvider.name : ''} 
                            ClassName = "form-control register-input wi100" 
                            type = "text" 
                            onChange = {(text) => updateText(text, 'name')} 
                            placeholder = {Assets.GameProvider}
                            Icon = {faPaintRoller}
                        />
                        <div className = "fl">&nbsp;&nbsp;</div>
                        <FormInput
                            description = {Assets.GameLaunchEndpoint}
                            value = {Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.gameLaunchEndpoint ? Main.Instance.GameProviderState.gameProvider.gameLaunchEndpoint : ''} 
                            ClassName = "form-control register-input wi100" 
                            type = "text" 
                            onChange = {(text) => updateText(text, 'gameLaunchEndpoint')} 
                            placeholder = {Assets.GameLaunchEndpoint}
                            Icon = {faExternalLinkAlt}
                        />
                        <div className = "fl">&nbsp;&nbsp;</div>
                        <FormInput
                            description = {Assets.GameIntegrationEndpoint}
                            value = {Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.gameIntegrationEndpoint ? Main.Instance.GameProviderState.gameProvider.gameIntegrationEndpoint : ''} 
                            ClassName = "form-control register-input wi100" 
                            type = "text" 
                            onChange = {(text) => updateText(text, 'gameIntegrationEndpoint')} 
                            placeholder = {Assets.GameIntegrationEndpoint}
                            Icon = {faCode}
                        />

                        <div title = {Assets.Active} className = "fr mt2">
                            <Switch onClick = {() => updateBoolean('active')} on = {Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider && Main.Instance.GameProviderState.gameProvider.active ? Main.Instance.GameProviderState.gameProvider.active : false}/>
                        </div>
                    </div>
                    <div className = "row admin-users-row">
                        <FormInput
                            description = {Assets.GameProviderGamesCategory}
                            value = {Main.Instance.GameProviderState.category ? Main.Instance.GameProviderState.category : ''} 
                            ClassName = "form-control register-input wi100" 
                            type = "text" 
                            onChange = {(category) => Logic.Type.New (Main.Instance.GameProviderState, {category})}
                            placeholder = {Assets.GameProviderGamesCategory}
                            Icon = {faSitemap}
                        />
                        {GetCategories()}
                    </div>
{/*                    <div className = "row admin-users-row">

                        <FormInput
                            description = {Assets.GameProviderGamesCategory}
                            value = {Main.Instance.GameProviderState.rgsgs ? Main.Instance.GameProviderState.rgsgs.toString() : ''} 
                            ClassName = "form-control register-input wi100" 
                            type = "text" 
                            onChange = {rgsgs => Logic.Type.New (Main.Instance.GameProviderState, {rgsgs: parseInt(rgsgs)})}
                            placeholder = {Assets.GameProviderGamesCategory}
                            Icon = {faCode}
                        />

                        <select value = {Main.Instance.GameProviderState.rgsgs === undefined ? '0' : Main.Instance.GameProviderState.rgsgs.toString()} style = {{background: '#fff', height: '28px'}} onChange = {(e) => selectRgsgs(+e.target.value)}>
                            {getRgsgsOptions()}
                        </select>
                        {GetRgsgs()}
                    </div>
*/}
            {/*parseInt(Main.Instance.GameProviderState.Bonus_type) === 1 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 2 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 3 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 4 ?
            <FormInputNumber
                description = {Assets.MinimumNumberOfRounds}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName= "h30 w60"
                value = {Main.Instance.GameProviderState.conditions && Main.Instance.GameProviderState.conditions.minRounds ? Main.Instance.GameProviderState.conditions.minRounds.toString() : '0'}
                type = "number"
                min = "0"
                iconText = {Assets.MinRounds}
                iconTextS = "14px"
                onChange = {text => SetMinRounds(text)}
                // noIconTextFix = {true}
            /> : void 0*/}

            {/*parseInt(Main.Instance.GameProviderState.Bonus_type) === 1 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 2 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 3 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 4 ?
            <FormSelect
                rewardFor = {4}
                level = {true}
                wide = {true}
                mt0 = {true}
                value = {Main.Instance.GameProviderState.conditions && Main.Instance.GameProviderState.conditions.rewardId ? 
                        Main.Instance.GameProviderState.conditions.rewardId.toString() : '0'}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "form-control"
                o1 = {Assets.SelectReward}
                o2 = {Assets.SelectReward}
                onChange = {text => SetReward(text)}
                icon = {Assets_.faRewardClass}
                /> : void 0*/}

            {/*parseInt(Main.Instance.GameProviderState.Bonus_type) === 4 ?
            <FormInputNumber
                description = "Big win value"
                outerDiv = "rows-per-table input-group fl mr ml pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.GameProviderState.conditions === undefined || Main.Instance.GameProviderState.conditions.bigWinValue === undefined ? '15' :
                         Main.Instance.GameProviderState.conditions.bigWinValue.toString()}  
                type = "number" 
                min = "1"
                iconText = "Big win x"
                iconTextS = "14px"
                onChange = {text => SetBigWin(text)}
            /> : void 0*/}
   
            {/*</div>*/}
            {/*parseInt(Main.Instance.GameProviderState.Bonus_type) === 1 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 2 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 3 ||
            parseInt(Main.Instance.GameProviderState.Bonus_type) === 4 ?            
            getMinByCurrency() : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon
                    disabled = {!isUserGamanzaGamesAdmin()}
                    description = {Main.Instance.GameProviderState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.GameProviderState.mode ? 'btn btn-warning btn-sm btn-plus fr mr0' : 'btn btn-primary btn-sm btn-plus fr mr0'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>    
        </div> : void 0*/}

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.GameProviderState.pSize ? '' : Main.Instance.GameProviderState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            {/*
            <FormSelect 
                span = {true}
                value = {!Main.Instance.GameProviderState.filter_Bonus_type ? '0' : Main.Instance.GameProviderState.filter_Bonus_type}
                data = {Main.Instance.FrontendStoreState.Category5}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                onChange = {text => ApplyFilterType(text)} 
            />
            */}
            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.GameProviderState.count ? Main.Instance.GameProviderState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.GameProviderState.count ? Main.Instance.GameProviderState.pNum * 
                Main.Instance.GameProviderState.pSize + 1 : 0}{' - '}
                {Main.Instance.GameProviderState.count ? 
                ((Main.Instance.GameProviderState.pNum + 1) * 
                Main.Instance.GameProviderState.pSize > Main.Instance.GameProviderState.count ? 
                Main.Instance.GameProviderState.count : 
                (Main.Instance.GameProviderState.pNum + 1) * Main.Instance.GameProviderState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.GameProviderState.count ? 
            Main.Instance.GameProviderState.pNum + 1 :0}{' / '}
            {Main.Instance.GameProviderState.count ? 
            Math.trunc((Main.Instance.GameProviderState.count - 1) /
            Main.Instance.GameProviderState.pSize + 1) : 0}
        </span>
        </div>
        <div className = "pr">
            <div style = {{display: 'block', width: '100%', marginTop: '10px', marginBottom: '10px'}}>{getActiveInactive()}</div>
            {/* <div style = {{position: 'absolute', top: '5px', right: '0px'}}>{getActiveInactive()}</div>
            <h3>{Assets.lGameProvider}</h3> */}
            {/*console.log (Main.Instance.DatabaseDataState.gameProviderNew)*/}
            {Main.Instance.DatabaseDataState.gameProviderNew !== null && Main.Instance.DatabaseDataState.gameProviderNew !== undefined ?
            <>
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.gameProviderNew}
                    filtered = {Main.Instance.GameProviderState.active === undefined ? [] : 
                                Main.Instance.GameProviderState.active === true ? [{id: 'active', value: 'true'}] : 
                                [{id: 'active', value: 'false'}]}
                    columns = {[
                        // {show: isUserGamanzaGamesAdmin(), width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                        {width: 50, Header: Assets.ID, accessor: 'providerId', headerClassName: 'th-left'},
                        {/*width: 200, */Header: Assets.GameProviderSupplier, accessor: 'name', headerClassName: 'th-left'},
                        // {minWidth: 200, Header: Assets.GameLaunchEndpoint, accessor: 'gameLaunchEndpoint', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {row.value}>{row.value}</span> : <span style = {{fontSize: '14px'}} className = "label label-danger label-sm">{Assets.NoneDefined}</span>},
                        // {minWidth: 200, Header: Assets.GameIntegrationEndpoint, accessor: 'gameIntegrationEndpoint', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {row.value}>{row.value}</span> : <span style = {{fontSize: '14px'}} className = "label label-danger label-sm">{Assets.NoneDefined}</span>},
                        // {minWidth: 200, Header: Assets.GameProviderGamesCategories, accessor: 'categories', headerClassName: 'th-left', Cell: (row) => row.value && row.value.length > 0 ? getCategories(row.value) : <span style = {{fontSize: '14px'}} className = "label label-danger label-sm">{Assets.NoneDefined}</span>},
                        // {minWidth: 200, Header: Assets.RgsGameSuppliers, accessor: 'rgsGameSuppliers', headerClassName: 'th-left', Cell: (row) => row.value && row.value.length > 0 ? getRgsGameSuppliers(row.value, row.original.rgsGameSupplierDefault) : <span style = {{fontSize: '14px'}} className = "label label-danger label-sm">{Assets.NoneDefined}</span>},
                        {width: 60, Header: Assets.Active, accessor: 'active', headerClassName: 'th-center', Cell: (row) => <Switch enabled={false} className = {isUserGamanzaGamesAdmin() ? 'fl mr mt5' : 'switch-disabled2 fl mr mt5'} onClick = {() => /*isUserGamanzaGamesAdmin() ? UpdateBoolean('active', row.index) :*/ void 0} on = {row.value ? row.value : false}/>},
                        // {show: isUserGamanzaGamesAdmin(), width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                    ]}
                    showPaginationBottom = {false}
                    defaultPageSize={Main.Instance.GameProviderState.pSize}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                />
            </> : void 0}
        </div>
        </div>
    </div>)
}

function getActiveInactive(): JSX.Element[] {
    const ai: JSX.Element[] = []
    const count: {active: number, inactive: number} = countGameProviders()
    ai.push (<button onClick = {() => Logic.Type.New (Main.Instance.GameProviderState, {active: undefined})} key = "0" className = {Main.Instance.GameProviderState.active === undefined ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.AllGameProviders}&nbsp;<span className="badge badge-light">{count.active + count.inactive}</span></button>)
    ai.push (<button onClick = {() => Logic.Type.New (Main.Instance.GameProviderState, {active: true})} key = "1" className = {Main.Instance.GameProviderState.active === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.ActiveGameProviders}&nbsp;<span className="badge badge-light">{count.active}</span></button>)
    ai.push (<button onClick = {() => Logic.Type.New (Main.Instance.GameProviderState, {active: false})} key = "2" className = {Main.Instance.GameProviderState.active === false ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}>{Assets.InactiveGameProviders}&nbsp;<span className="badge badge-light">{count.inactive}</span></button>)
    return ai
}

function countGameProviders(): {active: number, inactive: number} {
    const count: {active: number, inactive: number} = {active: 0, inactive: 0}
    if (Main.Instance.DatabaseDataState.gameProviderNew) {
        for (const item of Main.Instance.DatabaseDataState.gameProviderNew) {
            if (item.active) { count.active++ } else { count.inactive++ }
        } 
    }
    return count
}

/*
function selectRgsgs(rgsgs: number) {
    Logic.Type.New (Main.Instance.GameProviderState, {rgsgs})
}
/*
function getCategories(array: string[]): string {
    let s: string = ''
    for (const item of array) {
        if (s.length > 0) s += ', '
        s += item
    }
    return s
}
*/
/*
function getCategories(array: string[]): JSX.Element {
    const categories: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        categories.push(<button key = {i.toString()} style = {{cursor: 'default', marginRight: i === array.length - 1 ? '0px' : ''}} className = "btn btn-sm btn-info">{array[i]}</button>)
    }
    return <div>{categories}</div>
}
*/
/*
function getRgsGameSuppliers(array: number[], Default: number): JSX.Element {
    const rgsgs: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        rgsgs.push(<button title = {array[i] === Default ? Assets.ThisIsTheDefaultChoice : Assets.ThisIsNotTheDefaultChoice} key = {i.toString()} style = {{cursor: 'default', marginRight: i === array.length - 1 ? '0px' : ''}} className = {array[i] === Default ? 'btn btn-sm btn-default btn-dark' : 'btn btn-sm btn-default'}>{getRGSGS(array[i])}</button>)
    }
    return <div>{rgsgs}</div>
}


function getRGSGS(id: number): string {
    if (Main.Instance.DatabaseDataState.rgsGameSupplierNew) {
        for (const item of Main.Instance.DatabaseDataState.rgsGameSupplierNew) {
            if (item.id === id) { return item.name }
        }
    }
    return '?'
}
*/