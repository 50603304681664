import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
// import * as Assets_ from '../../Assets_'
import { Logic } from '../../../Logic/Type'

interface State {
    mode?: boolean
}

export class SelectGame extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            // mode: false,
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getGameOptions(isId: boolean): JSX.Element[] {

        const prop: string[] = []
        const Prop: number[] = []
        const options: JSX.Element[] = []
        const games = Object.assign([], Main.Instance.DatabaseDataState.gamesList)

        for (const item of games) {
            if (isId) {
                Prop.push (item.gameId)
            } else {
                prop.push(item.gameName)
            }
        }
    
        if (isId) {
            Prop.sort(this.sortNumber)
            for (const item of Prop) {
                if (item) {
                    prop.push(item.toString())
                }
                
            }
        } else {
            prop.sort()
        }
    
        options.push (<option key = "-1" value = "0">{Assets.Any.toLowerCase() + ' ' + (isId ? Assets.ID : Assets.Name.toLowerCase())}</option>)
        for (let i: number = 0; i < prop.length; i++) {
            options.push (<option key = {i.toString()} value = {prop[i]}>{prop[i]}</option>)
        }
        return options
    }

    public sortNumber(a, b) {
        return a - b
    }

    public render() {
        return (
            <div className = "clearfix">
                <h4 className = "fl mr">{Assets.Select + ' ' + Assets.Game}</h4>
                <select
                    style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Game}
                    className = "fl dbl mt mr form-control"
                    value = {Main.Instance.GGRRevenueState.gameId}
                    onChange = {(e) => {
                        let name: string = '0'
                        const games = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
                        for (const item of games) {
                            if (item.gameId === +e.target.value) {
                                name = item.gameName
                            }

                        }
                        Logic.Type.New (Main.Instance.GGRRevenueState, {gameId: +e.target.value, gameName: name})
                    }}
                >
                    {this.getGameOptions(true)}
                </select>
                <select
                    style = {{width: 'auto', height: '22px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Game}
                    className = "fl dbl mt mr form-control"
                    value = {Main.Instance.GGRRevenueState.gameName}
                    onChange = {(e) => {
                        let id: number = -1
                        const games = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
                        for (const item of games) {
                            if (item.gameName === e.target.value) {
                                id = item.gameId
                            }

                        }
                        Logic.Type.New (Main.Instance.GGRRevenueState, {gameName: e.target.value, gameId: id})
                    }
                        
                    }
                >
                    {this.getGameOptions(false)}
                </select>
            </div>
        ) 
    }
}