import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function GeneralWrapper(props: React.Props<any>) {
    if(Main.Instance.Location === RouterObjects['general_Settings'].path && Main.Instance.Back === false) {

        const path = RouterObjects['general_Settings'].path + RouterObjects['general_Settings'].children['Currencies_Management'].path
        let Path = RouterObjects['general_Settings'].path.substring (1, RouterObjects['general_Settings'].path.length)
        + ' -> ' +
        RouterObjects['general_Settings'].children['Currencies_Management'].path.substring(1, RouterObjects['general_Settings'].children['Currencies_Management'].path.length)

        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')
        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )
    
        Logic.Type.New(Main.Instance, {Location: path})
    }

    return(<div>{props.children}</div>)
}