import * as React from 'react'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import ReactTable from 'react-table'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {copyStringToClipboard, injectSpaces, isUserSystem, isUserGamanzaAdmin,/*, isUserGamanzaMagicHand, copyStringToClipboard*/
IsBuildMyChance, IsBuildPasino,
IsBuildJackpotsCH} from '../../../Logic/Utils'
import {FormInputNumber} from '../../Reusables/Inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAsterisk, faCopy, faGlobeEurope, faSnowflake, faGamepad, faLightbulb, faCheck, faCheckDouble, faTimes, faMagic, faFire, faUserShield, faDice, faUserSecret, faUserTie, faBullhorn, faUserCheck, faUserFriends, faUser, faUserInjured, faDatabase, faCoins, faSitemap, faBolt, faSyncAlt, faTrophy, faFlagCheckered, faGift, faHourglassStart, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {TBN} from '../../Reusables/TableUtils'
import {SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import * as Assets_ from '../../Assets_'
import {CooloffGameCategory, UsersNew} from '../../../Logic/Database/DatabaseData'
import {getProvider} from '../GameStore/GameList'

import * as Config from '../../../Logic/Config'
import axios from 'axios'
import { RewardTypes } from '../../../Logic/Database/rewards/rewardsEnums'
import { LevelTypes } from '../../../Logic/Database/levels/levelsEnums';
import { faClock } from '@fortawesome/free-regular-svg-icons'


function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.LogState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.LogState, {offset:(0).toString()})

    DbRoutes.getAdminLog()
    Logic.Type.New(Main.Instance.LogState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.LogState.pNum > 0) {

        Logic.Type.New(Main.Instance.LogState, {
            offset:((Main.Instance.LogState.pNum - 1) * 
            Main.Instance.LogState.pSize).toString()})
        DbRoutes.getAdminLog()

        Logic.Type.New(Main.Instance.LogState,{pNum: Main.Instance.LogState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.LogState.pNum + 1) * Main.Instance.LogState.pSize < 
    Main.Instance.LogState.count) {

        Logic.Type.New(Main.Instance.LogState, {
            offset: ((Main.Instance.LogState.pNum + 1) * 
            Main.Instance.LogState.pSize).toString()})
        DbRoutes.getAdminLog()
        
        Logic.Type.New(Main.Instance.LogState,{pNum: Main.Instance.LogState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.LogState.count <= Main.Instance.LogState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.LogState.count === 0) {

            Logic.Type.New(Main.Instance.LogState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.LogState.count - 1) / 
                    Main.Instance.LogState.pSize)
            Logic.Type.New(Main.Instance.LogState, {
                offset:(npNum * Main.Instance.LogState.pSize).toString()})
        }

        DbRoutes.getAdminLog()
        Logic.Type.New(Main.Instance.LogState,{pNum: npNum})
    }
}

function getUserIdOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    // const distinctUserId: any[] = Object.assign([], Main.Instance.LogState.distinctUserId)
    // console.log (distinctUserId)
    // distinctUserId.sort(sortUserId)
    options.push (<option key = "0" value = "0">{Assets.AnyUserId}</option>)
    if (Main.Instance.LogState.distinctUserIdSorted) {
        for (const item of Main.Instance.LogState.distinctUserIdSorted) {
            options.push (<option key = {item.user_id} value = {item.user_id}>{item.user_id}</option>)
        }
    }
    return options
}

function getUserNameOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnyUsername}</option>)
    if (Main.Instance.LogState.distinctUserId) {
        for (const item of Main.Instance.LogState.distinctUserId) {
            options.push (<option key = {item.username} value = {item.user_id}>{item.username}</option>)
        }
    }
    return options
}
/*
function getUserRoleOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnyRole}</option>)
    if (Main.Instance.LogState.distinctUserId) {
        for (const item of Main.Instance.LogState.distinctUserId) {
            options.push (<option key = {item.role} value = {item.role}>{item.roles}</option>)
        }
    }
    return options
}
*/
function getTypeOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnyType}</option>)
    if (Main.Instance.LogState.distinctType) {
        for (const item of Main.Instance.LogState.distinctType) {
            options.push (<option key = {item.type} value = {item.type}>{item.type}</option>)
        }
    }
    return options
}

function getSubtypeOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnySubtype}</option>)
    if (Main.Instance.LogState.distinctSubtype) {
        for (const item of Main.Instance.LogState.distinctSubtype) {
            options.push (<option key = {item.subtype} value = {item.subtype}>{item.subtype}</option>)
        }
    }
    return options
}

function getDateOptions(start: boolean): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option /*title = {start ? Assets.StartDate : Assets.EndDate}*/ key = "0" value = "0">{/*Assets.AnyDate + ' ' + (start ? '+' : '-')*/}{start ? Assets.Any.toLowerCase() + ' ' + Assets.StartDate.toLowerCase() : Assets.Any.toLowerCase() + ' ' + Assets.EndDate.toLowerCase()}</option>)
    if (Main.Instance.LogState.distinctDate) {
        for (const item of Main.Instance.LogState.distinctDate) {
            options.push (<option key = {item.timestamp} value = {item.timestamp}>{item.timestamp}</option>)
        }
    }
    return options
}

export function setLog(data) {
    // console.log (data)

    let DATA: any[]
    let ID: string
    let NAME: string
    // let NAME2: string = ''
    if (isLevels()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.levelsStream)
        ID = 'id'
        NAME = 'name'
    }
    if (isRewards()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
        ID = 'id'
        NAME = 'Reward'
    }
    if (isManageAdminUsers()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.usersNew)
        ID = 'id'
        NAME = 'username'
    }
    /*
    if (isRgsGameSupplier()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.rgsGameSupplierNew)
        ID = 'id'
        NAME = 'name'
    }
    */
    if (isGameProvider()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.gameProviderNew)
        ID = 'providerId'
        NAME = 'name'
    }
    if (isBonusCampaign()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.bonusCampaignNew)
        ID = 'id'
        NAME = 'campaignName'
    }
    if (isBonus()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.bonusNew)
        ID = 'id'
        NAME = 'bonusName'
    }
    if (isGames() || isGamesCentral()) {
        // DATA = Object.assign([], Main.Instance.DatabaseDataState.gamesCentralNew)
        // ID = 'gameId'
        // NAME = 'name'
        DATA = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
        ID = 'gameId'
        NAME = 'gameName'
    }
    if (isShop()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.storeStream)
        ID = 'id'
        NAME = 'name'
    }
    if (isChances()) {
        DATA = Object.assign([], Main.Instance.DatabaseDataState.missionsNew)
        ID = 'id'
        NAME = 'name'
    }
    if (isTournaments() || isRaces()) {
        DATA = filterTournamentsRaces()
        ID = 'id'
        NAME = 'name'
    }
    

    let currentid: number = +Main.Instance.LogState.currentId
    if (currentid === 0) {
        if (DATA) {
            for (const item of DATA) {
                /* if (NAME2 !== '') {
                    if (item[NAME][NAME2] === Main.Instance.LogState.currentName) {
                        currentid = item[ID]
                        break
                    }
                } else {
                */
                if (item[NAME] === Main.Instance.LogState.currentName) {
                    currentid = item[ID]
                    break
                }
                // }
                
            }
        }
    }

    let currentname: string = Main.Instance.LogState.currentName
    if (currentname === '0') {
        if (DATA) {
            for (const item of DATA) {
                if (item[ID] === +Main.Instance.LogState.currentId) {
                    currentname = (/*NAME2 !== '' ? item[NAME][NAME2] :*/ item[NAME])
                    break
                }
            }
        }
    }

    const log: any[] = []
    for (const item of data) {
        // console.log (item.custom.data)
        const Data = JSON.parse(item.custom.data)
        // console.log(Data)
        if (isGames() && (isSaveEdit() || isSaveInsert() || isDelete())) {
            if (Data.id === currentid) {
                log.push (item)
            }
        } else if (isGamesCentral() && (isSaveEdit() || isDelete())) {
            if (Data.gameId === currentid) {
                log.push (item)
            }
        } else if ((isGamesCentral() || isGameProvider() || isRgsGameSupplier() || isRewards() || isLevels() || isShop() || isChances() || isTournaments() || isRaces()) && isSaveInsert()) {
            if (Data.name === currentname) {
                log.push (item)
            }
        } else if ((isBonus() || isBonusCampaign() || isRgsGameSupplier() || isRewards() || isLevels() || isShop() || isChances() || isTournaments() || isRaces()) && isSaveEdit()) {
            if (Data.id === currentid) {
                log.push (item)
            }
        } else if (isGameProvider() && isSaveEdit()) {
            if (Data.providerId === currentid) {
                log.push (item)
            }
        } else if (isBonus() && (isSaveEdit() || isSaveInsert() || isDelete())) {
            if (Data.bonusName === currentname) {
                log.push (item)
            }
        } else if (isBonusCampaign() && (isSaveEdit() || isSaveInsert() || isDelete())) {
            if (Data.campaignName === currentname) {
                log.push (item)
            }
        }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {Log: log})
}

function sortNumber(a, b) {
    return a - b
}

/*
function getEntityIdOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnyEntityId}</option>)
    if (Main.Instance.LogState.distinctEntityId) {
        for (const item of Main.Instance.LogState.distinctEntityId) {
            options.push (<option key = {item.entity_id} value = {item.entity_id}>{item.entity_id}</option>)
        }
    }
    return options
}

function getEntityNameOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.AnyEntity}</option>)
    if (Main.Instance.LogState.distinctEntityId) {
        for (const item of Main.Instance.LogState.distinctEntityId) {
            options.push (<option key = {item.name} value = {item.name}>{item.name}</option>)
        }
    }
    return options
}
*/


export function Log(props: React.Props<any>): JSX.Element {
    if (Main.Instance.DatabaseDataState.log === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {log: []})
        DbRoutes.getAdminLog()
    }

    if (Main.Instance.DatabaseDataState.usersNew === undefined) {
        const usersNew: UsersNew[] = []
        axios.get(Config.asNewUser + (+Main.Instance.LoginState.entityId === 0 ? '' : '?entityId=' + Main.Instance.LoginState.entityId), { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
        .then((response1) => {
            
            for (const item of response1.data) {
                usersNew.push(item)
            }
            // console.log(JSON.stringify(usersNew))
            Logic.Type.New(Main.Instance.DatabaseDataState, {usersNew})
        })
    }
    DbRoutes.wsReinitialize()

    return (
        <div>
            <h2>{Assets.Log}
            <button 
                className = "dbl fr btn btn-default btn-sm"
                style = {{border: 'none'}}
                title = {Assets.Info}
                onClick = {() => {
                    const toggle = !Main.Instance.LogState.toggle
                    Logic.Type.New(Main.Instance.LogState, {toggle})
                }}
            >
                <FontAwesomeIcon title = {Assets.Log + ' ' + Assets.Info} icon = {faInfoCircle}/>
            </button>
            </h2>
            
            <br/>
            {/*console.log (Main.Instance.DatabaseDataState.Log)*/}
            {Main.Instance.LogState.toggle ?
            <div style = {{padding: '10px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'absolute', top: '150px', bottom: '150px', left: '300px', right: '300px'}}>
                <button
                    title={Assets.Close}
                    onClick={() => Logic.Type.New(Main.Instance.LogState, {toggle: false})}
                    style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                    className="btn btn-default btn-sm btn-plus"
                >
                    <i className={Assets_.faCancelButtonClass} />
                </button>
                <p style = {{margin: '10px', fontSize: '14px'}}>
                The log displays information about the activity of users in the GaminGenius Admin. The information about changes (insert, edit, delete) on the site is listed in the Various column.
                The important information of the changed/inserted/deleted element is listed directly in the table <u>after selecting the type and subtype</u>. 
                All the information of the changed/inserted/deleted element is in the Data column, which can be copied to clipboard.
                Aditionally to type and subtype filters, there are filters for the user who triggered the log insert and start / end date.<br/>
                List of Type/SubType selection that displays additional data in the table: 
                </p>
                {IsBuildMyChance() ? <p style = {{margin: '10px', fontSize: '14px'}}>Type: SAVE/EDIT<br/>
                Subtypes: General Settings → General, Gamification → Rewards, Gamification → Levels, Gamification → Shop, Gamification → Chances,<br/> Gamification → Tournaments, Gamification → Races, User Management → Manage Admin Users<br/>
                Type: SAVE/INSERT<br/>
                Subtypes: Gamification → Rewards, Gamification → Levels, Gamification → Shop, Gamification → Chances, Gamification → Tournaments,<br/> Gamification → Races, User Management → Manage Admin Users<br/>
                Type: DELETE<br/>
                Subtypes: Gamification → Rewards, Gamification → Levels, Gamification → Shop, Gamification → Chances, Gamification → Tournaments,<br/> Gamification → Races, User Management → Manage Admin Users<br/></p> : 
                IsBuildJackpotsCH() || IsBuildPasino() ? <p style = {{margin: '10px', fontSize: '14px'}}>Type: SAVE/EDIT<br/>
                Subtypes: Game Store → Gamingenius Game List, Game Store → Approved Games Store, Bonus Section → Bonus Campaign,<br/> Bonus Section → Bonus, Game Store → Game Providers, Game Store → RGS Game Suppliers , User Management → Manage Admin Users<br/>
                Type: SAVE/INSERT<br/>
                Subtypes: Game Store → Gamingenius Game List, Game Store → Approved Games Store, Bonus Section → Bonus Campaign,<br/> Bonus Section → Bonus, Game Store → Game Providers, Game Store → RGS Game Suppliers , User Management → Manage Admin Users<br/>
                Type: DELETE<br/>
                Subtypes: Game Store → Gamingenius Game List, Game Store → Approved Games Store, Bonus Section → Bonus Campaign,<br/> Bonus Section → Bonus, Game Store → Game Providers, Game Store → RGS Game Suppliers , User Management → Manage Admin Users<br/></p>
                 : void 0}
            </div> : void 0}
            {Main.Instance.DatabaseDataState.Log === undefined ?
            <div className = "control-panel" style = {{height: '30px'}}>
                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.LogState.pSize ? '' : Main.Instance.LogState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
                />
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>

                <span className = "label label-success table-label">
                    {Assets.records}
                    {Main.Instance.LogState.count ? Main.Instance.LogState.count : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.showing}
                    {Main.Instance.LogState.count ? Main.Instance.LogState.pNum * 
                    Main.Instance.LogState.pSize + 1 : 0}{' - '}
                    {Main.Instance.LogState.count ? 
                    ((Main.Instance.LogState.pNum + 1) * 
                    Main.Instance.LogState.pSize > Main.Instance.LogState.count ? 
                    Main.Instance.LogState.count : 
                    (Main.Instance.LogState.pNum + 1) * Main.Instance.LogState.pSize) : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.page}
                    {Main.Instance.LogState.count ? 
                    Main.Instance.LogState.pNum + 1 :0}{' / '}
                    {Main.Instance.LogState.count ? 
                    Math.trunc((Main.Instance.LogState.count - 1) /
                    Main.Instance.LogState.pSize + 1) : 0}
                </span>
            </div> : void 0}
            <div className = "pr">
                <select
                    style = {{height: '24px'/*, marginLeft: '5px'*/}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctUserIdValue: e.target.value})
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getUserIdOptions()}
                </select>

                <select
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctUserIdValue: e.target.value});
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getUserNameOptions()}
                </select>
                {/*
                <select
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctUserRoleValue: e.target.value});
                        DbRoutes.getAdminLog()
                    }}
                >
                    {getUserRoleOptions()}
                </select>


                <select
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {Logic.Type.New (Main.Instance.LogState, {distinctEntityIdValue: e.target.value}); DbRoutes.getAdminLog()}}
                >
                    {getEntityIdOptions()}
                </select>

                <select
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {Logic.Type.New (Main.Instance.LogState, {distinctEntityNameValue: e.target.value}); DbRoutes.getAdminLog()}}
                >
                    {getEntityNameOptions()}
                </select>
                */}
                {/* console.log (Main.Instance.LogState.distinctTypeValue, Main.Instance.LogState.distinctSubtypeValue)*/}
                <select
                    value = {Main.Instance.LogState.distinctTypeValue}
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctTypeValue: e.target.value,
                            currentId: '0',
                            currentName: '0'})
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getTypeOptions()}
                </select>

                <select
                    value = {Main.Instance.LogState.distinctSubtypeValue}
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctSubtypeValue: e.target.value, 
                            currentId: '0',
                            currentName: '0'})
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getSubtypeOptions()}
                </select>

                <select
                    value = {Main.Instance.LogState.distinctDateValueStart}
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {  
                        Logic.Type.New (Main.Instance.LogState, {distinctDateValueStart: e.target.value});
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getDateOptions(true)}
                </select>

                <select
                    value = {Main.Instance.LogState.distinctDateValueEnd}
                    style = {{height: '24px', marginLeft: '5px'}}
                    onChange = {(e) => {
                        Logic.Type.New (Main.Instance.LogState, {distinctDateValueEnd: e.target.value});
                        Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                        FirstPage()
                        // DbRoutes.getAdminLog()
                    }}
                >
                    {getDateOptions(false)}
                </select>
                <div style = {{position: 'absolute', top: '0px', right: '0px'}}>
                {(isGames() || isGamesCentral()) && (isSaveEdit() || isSaveInsert() || isDelete()) ?
                // getSelects(Main.Instance.DatabaseDataState.gamesCentralNew, 'gameId', 'name')
                getSelects(Main.Instance.DatabaseDataState.gamesList, 'gameId', 'gameName')
                : void 0}
                {isBonus() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.bonusNew, 'id', 'bonusName')
                : void 0}
                {isBonusCampaign() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.bonusCampaignNew, 'id', 'campaignName')
                : void 0}     
                {isGameProvider() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.gameProviderNew, 'providerId', 'name')
                : void 0}
                {/*isRgsGameSupplier() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.rgsGameSupplierNew, 'id', 'name')
                : void 0*/}
                {isManageAdminUsers() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.usersNew, 'id', 'username')
                : void 0}
                {isRewards() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.rewardShopStream, 'id', 'Reward')
                : void 0}
                {isLevels() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.levelsStream, 'id', 'name')
                : void 0}
                {isShop() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.storeStream, 'id', 'name')
                : void 0}
                {isChances() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(Main.Instance.DatabaseDataState.missionsNew, 'id', 'name')
                : void 0}
                {isTournaments() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(filterTournamentsRaces(), 'id', 'name')
                : void 0}
                {isRaces() && (isSaveEdit() || isSaveInsert()) ?
                getSelects(filterTournamentsRaces(), 'id', 'name')
                : void 0}
                </div>
            </div>
            <br/>
            
            {/*console.log(Main.Instance.DatabaseDataState.log)*/}
            <ReactTable
                className = "dashboard-item-log"
                data = {Main.Instance.DatabaseDataState.Log ? Main.Instance.DatabaseDataState.Log : Main.Instance.DatabaseDataState.log ? Main.Instance.DatabaseDataState.log : []}
                columns = {[
                    {
                    columns: [
                        {width: 80, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                    ]},
                    {
                    Header: Assets.UserData,
                    headerClassName: 'risk-default',
                    columns: [
                        {width: 43, Header: <><FontAwesomeIcon icon = {faUserTie}/>&nbsp;{Assets.ID}</>, accessor: 'user_id', headerClassName: 'th-left'},
                        {width: 140, Header: <><FontAwesomeIcon icon = {faUserTie}/>&nbsp;{Assets.Username}</>, accessor: 'username', headerClassName: 'th-left'},
                    ]},
                    {
                    Header: Assets.UserSecurityRights,
                    headerClassName: 'risk-default-light',
                    columns: [
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.ADMINISTRATOR]} icon = {faUserShield}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'administrator'), SecurityRights.ADMINISTRATOR)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.SUPPORT]} icon = {faUserFriends}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'support'), SecurityRights.SUPPORT)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.OPERATOR]} icon = {faUser}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'operator'), SecurityRights.OPERATOR)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.MARKETING]} icon = {faBullhorn}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'marketing'), SecurityRights.MARKETING)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.RFSPECIALIST]} icon = {faUserCheck}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'rfSpecialist'), SecurityRights.RFSPECIALIST)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.RGSPECIALIST]} icon = {faUserInjured}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'rgSpecialist'), SecurityRights.RGSPECIALIST)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.GDPR]} icon = {faUserSecret}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'gdpr'), SecurityRights.GDPR)},
                        {width: 32, Header: <FontAwesomeIcon title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.ACCOUNTING]} icon = {faUserTie}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'accounting'), SecurityRights.ACCOUNTING)},
                    ]},
                    {
                    Header: Assets_.Gamanza,
                    headerClassName: 'risk-default-light',
                    columns: [
                        {show: isUserGamanzaAdmin(), width: 32, Header: <FontAwesomeIcon style = {{color: '#999'/*'#f38b00'*/}} title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]} icon = {faFire}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'unused1'), SecurityRights.GAMANZA_ADMIN)},
                        {show: isUserGamanzaAdmin(), width: 32, Header: <FontAwesomeIcon style = {{color: '#999'/*'#f38b00'*/}} title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]} icon = {faDice}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'unused2'), SecurityRights.GAMANZA_GAMES_ADMIN)},
                        {show: isUserGamanzaAdmin()/*isUserGamanzaMagicHand()*/, width: 32, Header: <FontAwesomeIcon style = {{color: '#999'/*'#f38b00'*/}} title = {Assets.SecurityRight + ': ' + Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]} icon = {faMagic}/>, accessor: 'user_id', className: 'dashboard-item-latest', headerClassName: 'th-center', Cell: (row) => TBN(getValue(row.value, 'unused7'), SecurityRights.GAMANZA_MAGIC_HAND)},
                    ]},
                    {
                    Header: isUserSystem() ? 'Entity/Type/Subtype' : 'Type/Subtype',
                    headerClassName: 'risk-default',
                    columns: [
                        {show: isUserSystem(), width: 70, Header: Assets.EntityID, accessor: 'entity_id', headerClassName: 'th-left'},
                        {show: isUserSystem(), width: 120, Header: Assets.Entity, accessor: 'entity', headerClassName: 'th-left'},
                        {width: 120, Header: Assets.Type, accessor: 'custom', headerClassName: 'th-left', Cell: (row) => row.value.type},
                        {width: 200, Header: Assets.Subtype, accessor: 'custom', headerClassName: 'th-left', Cell: (row) => <div title = {row.value.subType}>{row.value.subType ? row.value.subType : '-'}</div>},
                    ]},
                    {
                    Header: Assets.Timestamp + ' ' + Assets_.UTC,
                    headerClassName: 'risk-default-light',
                    columns: [
                        {width: 90, Header: Assets.Date, accessor: 'custom', headerClassName: 'th-left', Cell: (row) => row.value.timestamp.substring(0, 10)},
                        {width: 70, Header: Assets.Time, accessor: 'custom', headerClassName: 'th-left', Cell: (row) => row.value.timestamp.substring(11, 19)},
                    ]},
                    
                    {
                    Header: Assets.Games,
                    headerClassName: 'risk-default',
                    columns: [
                        {width: 30, show: saveEditGameStoreGames() || saveInsertGameStoreGames(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                        {width: saveEditGameStoreGames() ? 40 : undefined, show: saveEditGameStoreGames() || saveInsertGameStoreGames() || deleteGameStoreGames(), Header: <FontAwesomeIcon title = {Assets.GameID} icon = {faGamepad}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                        {width: 30, show: saveEditGameStoreGames() || saveInsertGameStoreGames(), Header: <FontAwesomeIcon title = {Assets.Approved} icon = {faCheckDouble}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'approvedJurisdiction').length > 1 ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'approvedJurisdiction').length > 1 ? faCheck : faTimes}/> : '-'},
                        {width: 120, show: saveEditGameStoreGames(), Header: <><FontAwesomeIcon title = {Assets.Cooloff + ' ' + Assets.Category.toLowerCase()} icon = {faSnowflake}/>&nbsp;{Assets.category}</>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? injectSpaces(CooloffGameCategory[+getTextFromData(row.value, 'cooloffCategory')]) : '-'},
                        {show: saveEditGameStoreGames() , Header: <><FontAwesomeIcon icon = {faGlobeEurope}/>&nbsp;{Assets.Jurisdictions + ' ' + Assets.Allowed.toLowerCase()}</>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'approvedJurisdiction') : '-'},
                        
                    ]},
                    {
                    Header: Assets.GamingeniusGameList,
                    headerClassName: 'risk-default',
                    columns: [
                        // {width: 30, show: saveEditGameStoreGamesCentral() || saveInsertGameStoreGamesCentral(), Header: <FontAwesomeIcon title = {Assets.Approvable + ' ' + Assets.Game.toLowerCase()} icon = {faCheckDouble}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getArrayFromData(row.value, 'jurisdictions').length > 1 ? '#28a745' : '#dc3545'}} icon = {getArrayFromData(row.value, 'jurisdictions').length > 1 ? faCheck : faTimes}/> : '-'},
                        {width: deleteGameStoreGamesCentral() ? undefined : 40, show: saveEditGameStoreGamesCentral() || deleteGameStoreGamesCentral(), Header: <FontAwesomeIcon title = {Assets.GameID} icon = {faGamepad}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'gameId') : '-'},
                        {width: 120, show: saveEditGameStoreGamesCentral() || saveInsertGameStoreGamesCentral(), Header: Assets.GameName, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'gameName')}>{getTextFromData(row.value, 'gameName')}</span> : '-'},
                        // {show: saveEditGameStoreGamesCentral() || saveInsertGameStoreGamesCentral(), Header: <FontAwesomeIcon title = {Assets.ApprovableJurisdictions} icon = {faGlobeEurope}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getArrayFromData(row.value, 'jurisdictions') : '-'},
                        {width: 80, show: saveEditGameStoreGamesCentral() || saveInsertGameStoreGamesCentral(), Header: Assets.Provider, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getProvider(+getTextFromData(row.value, 'providerId'))}>{getProvider(+getTextFromData(row.value, 'providerId'))}</span> : '-'},
                        {width: 80, show: saveEditGameStoreGamesCentral() || saveInsertGameStoreGamesCentral(), Header: Assets.Category, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getCategory(+getTextFromData(row.value, 'categoryId'))}>{getCategory(+getTextFromData(row.value, 'categoryId'))}</span> : '-'},
                        
                    ]},
                    {
                        Header: Assets.aeSystemUsers,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 30, show: saveEditUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers(), Header: <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.User.toLowerCase()} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-center', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active')=== 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: 40, show: saveEditUserManagementManageAdminUsers() || deleteUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers(), Header: <span title = {Assets.User + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => getTextFromData(row.value, 'id')},
                            {width: deleteUserManagementManageAdminUsers() ? undefined : 100, show: saveEditUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers() || deleteUserManagementManageAdminUsers(), Header: Assets.Username, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => <span title = {getTextFromData(row.value, 'username')}>{getTextFromData(row.value, 'username')}</span>},
                            {width: 100, show: saveEditUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers(), Header: Assets.FullName, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => <span title = {getTextFromData(row.value, 'firstname') + ' ' + getTextFromData(row.value, 'lastname')}>{getTextFromData(row.value, 'firstname')} {getTextFromData(row.value, 'lastname')}</span>},
                            {width: 100, show: saveEditUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers(), Header: Assets.Email, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => <span title = {getTextFromData(row.value, 'email')}>{getTextFromData(row.value, 'email')}</span>},
                            {width: 80, show: saveEditUserManagementManageAdminUsers() || saveInsertUserManagementManageAdminUsers(), Header: Assets.Phone, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => getTextFromData(row.value, 'phone')},
                            
                        ]
                    },
                    {
                        Header: Assets.BonusCampaign,
                        headerClassName: 'risk-default',
                        columns: [
                            // {width: 30, show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: deleteGameStoreBonusCampaign() ? undefined : 30, show: saveEditGameStoreBonusCampaign() || deleteGameStoreBonusCampaign(), Header: Assets.ID, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'campaignName')}</span> : '-'},
                            {width: 90, show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: Assets.StartDate, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'startDate').substring(0, 10) + ' ' + getTextFromData(row.value, 'startDate').substring(11, 19) : '-'},
                            {width: 90, show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: Assets.EndDate, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'endDate').substring(0, 10) + ' ' + getTextFromData(row.value, 'endDate').substring(11, 19) : '-'},
                            
                            {width: 30, show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: <FontAwesomeIcon title = {Assets.Combinable} icon = {faAsterisk}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'combinable') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'combinable') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: 90, show: saveEditGameStoreBonusCampaign() || saveInsertGameStoreBonusCampaign(), Header: Assets.Bonus_es, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'bonusIds').replace(',', ', ') : '-'},
                            
                    ]},
                    {
                        Header: Assets.Bonus,
                        headerClassName: 'risk-default',
                        columns: [
                            // {width: 30, show: saveEditGameStoreBonus() || saveInsertGameStoreBonus(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: deleteGameStoreBonus() ? undefined : 30, show: saveEditGameStoreBonus() || deleteGameStoreBonus(), Header: Assets.ID, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGameStoreBonus() || saveInsertGameStoreBonus(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'bonusName')}</span> : '-'},
                            {show: saveEditGameStoreBonus() || saveInsertGameStoreBonus(), Header: Assets.Bonus + ' ' + Assets.Type.toLowerCase(), accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getTextFromData(row.value, 'bonusType').replace('_', ' ').toLowerCase()}</span> : '-'},
                            {show: saveEditGameStoreBonus() || saveInsertGameStoreBonus(), Header: Assets.TriggerType, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getTextFromData(row.value, 'triggerType').replace('_', ' ').toLowerCase()}</span> : '-'},
                            // {width: 70, show: saveEditGameStoreBonus() || saveInsertGameStoreBonus(), Header: Assets.Amount, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getTextFromData(row.value, 'amount')}&nbsp;{getTextFromData(row.value, 'currency')}</span> : '-'},
                            
                    ]},
                    {
                        Header: Assets.GameProviders,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 30, show: saveEditGameStoreGameProvider() || saveInsertGameStoreGameProvider(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: deleteGameStoreGameProvider() ? undefined : 30, show: saveEditGameStoreGameProvider() || deleteGameStoreGameProvider(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, deleteGameStoreGameProvider() ? 'id' : 'providerId') : '-'},
                            {width: 120, show: saveEditGameStoreGameProvider() || saveInsertGameStoreGameProvider(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {show: saveEditGameStoreGameProvider() || saveInsertGameStoreGameProvider(), Header: Assets.GameCategories, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getTextFromData(row.value, 'categories').replace(',', ', ')}</span> : '-'},
                            
                    ]},
                    {
                        Header: Assets.RgsGameSuppliers,
                        headerClassName: 'risk-default',
                        columns: [
                           
                            {width: deleteGameStoreRgsGameSuppliers() ? undefined : 30, show: saveEditGameStoreRgsGameSuppliers() || deleteGameStoreRgsGameSuppliers(), Header: <span title = {Assets.Supplier + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGameStoreRgsGameSuppliers() || saveInsertGameStoreRgsGameSuppliers(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
      
                    ]},
                    {
                        Header: Assets.Rewards,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: deleteGamificationRewards() ? undefined : 40, show: saveEditGamificationRewards() || deleteGamificationRewards(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {width: 120, show: saveEditGamificationRewards() || saveInsertGamificationRewards(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 120, show: saveEditGamificationRewards() || saveInsertGamificationRewards(), Header: Assets.RewardType, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? injectSpaces(RewardTypes[parseInt(getTextFromData(row.value, 'rewardType'))]) : '-'},
                            {show: saveEditGamificationRewards() || saveInsertGamificationRewards(), Header: Assets.BonusCode, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getSubCategoryFromData(row.value, 'reward', 'category1', 'bonusCode') : '-'},
                            
                    ]},
                    {
                        Header: Assets.Levels,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: deleteGamificationLevels() ? undefined : 40, show: saveEditGamificationLevels() || deleteGamificationLevels(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGamificationLevels() || saveInsertGamificationLevels(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 80, show: saveEditGamificationLevels() || saveInsertGamificationLevels(), Header: Assets.Type, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? injectSpaces(LevelTypes[parseInt(getTextFromData(row.value, 'type'))]) : '-'},
                            {width: 60, show: saveEditGamificationLevels() || saveInsertGamificationLevels(), Header: Assets.Level, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'hierarchy') : '-'},
                            {width: 30, show: saveEditGamificationLevels() || saveInsertGamificationLevels(), Header: <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.category + ' ' + Assets.Contribution.toLowerCase()} icon = {faSitemap}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getSubCategoryFromData(row.value, 'categoryContributions', 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getSubCategoryFromData(row.value, 'categoryContributions', 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: 30, show: saveEditGamificationLevels() || saveInsertGamificationLevels(), Header: <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.TokensPercentage.toLowerCase()} icon = {faCoins}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getSubCategoryFromData(row.value, 'tokensPercentage', 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getSubCategoryFromData(row.value, 'tokensPercentage', 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            
                    ]},
                    {
                        Header: Assets.Shop,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: deleteGamificationShop() ? undefined : 40, show: saveEditGamificationShop() || deleteGamificationShop(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGamificationShop() || saveInsertGamificationShop(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 90, show: saveEditGamificationShop() || saveInsertGamificationShop(), Header: Assets.Min_Level, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getSubCategoryFromData(row.value, 'filters', 'minLevel', 'level') : '-'},
                            {width: 90, show: saveEditGamificationShop() || saveInsertGamificationShop(), Header: Assets.Max_Level, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getSubCategoryFromData(row.value, 'filters', 'maxLevel', 'level') : '-'},
                            {width: 80, show: saveEditGamificationShop() || saveInsertGamificationShop(), Header: '#' + Assets.Rewards.toLowerCase(), accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getArrayLengthFromData(row.value, 'rewards') : '-'},
                            
                    ]},
                    {
                        Header: Assets.Chances,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: deleteGamificationChances() ? undefined : 30, show: saveEditGamificationChances() || deleteGamificationChances(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 90, show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: <span title = {Assets.Active + ' ' + Assets.FromDate.toLowerCase}>{Assets.FromDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'activeFrom')) : '-'},
                            {width: 90, show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: <span title = {Assets.Active + ' ' + Assets.ToDate.toLowerCase}>{Assets.ToDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'activeTo')) : '-'},
                            {width: 30, show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: <FontAwesomeIcon title = {Assets.OptIn + ' ' + Assets.required} icon = {faBolt}/>, accessor: 'custom.data', headerClassName: 'th-center', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getSubCategoryFromData(row.value, 'optIn', 'required') === 'true' ? '#28a745' : '#dc3545'}} icon = {getSubCategoryFromData(row.value, 'optIn', 'required') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: 60, show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: '#' + Assets.Tasks.toLowerCase(), accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getArrayLengthFromData(row.value, 'tasks') : '-'},
                            {width: 70, show: saveEditGamificationChances() || saveInsertGamificationChances(), Header: <FontAwesomeIcon title = {Assets.Repeat + ' ' + Assets.Every.toLowerCase()} icon = {faSyncAlt}/>, accessor: 'custom.data', headerClassName: 'th-center', Cell: (row) => row.value ? <span>{getSubCategoryFromData(row.value, 'repeatEvery', 'amount') === '-' ? '0' : getSubCategoryFromData(row.value, 'repeatEvery', 'amount')} {getSubCategoryFromData(row.value, 'repeatEvery', 'unit')}</span> : '-'},
                    ]},
                    {
                        Header: Assets.Tournaments,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 30, show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: deleteGamificationTournaments() ? undefined : 30, show: saveEditGamificationTournaments() || deleteGamificationTournaments(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 90, show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: <span title = {Assets.Valid + ' ' + Assets.FromDate.toLowerCase}>{Assets.FromDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'validFrom')) : '-'},
                            {width: 90, show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: <span title = {Assets.Valid + ' ' + Assets.ToDate.toLowerCase}>{Assets.ToDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'validTo')) : '-'},
                            {width: 50, show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: <span title = {Assets.LeaderboardType}>{Assets.Type}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {Assets.LeaderBoardOddyzzey[+getTextFromData(row.value, 'leaderboardType') - 1]}>{getTextFromData(row.value, 'leaderboardType')}</span> : '-'},
                            {width: 45, show: saveEditGamificationTournaments() || saveInsertGamificationTournaments(), Header: <span title = {Assets.Number + Assets._of_ + Assets.Prizes.toLowerCase()}># <FontAwesomeIcon  icon = {faTrophy}/></span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getArrayLengthFromData(row.value, 'prizes') : '-'},
                             
                    ]},
                    {
                        Header: Assets.Races,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 30, show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: <FontAwesomeIcon title = {Assets.Active} icon = {faLightbulb}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getTextFromData(row.value, 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getTextFromData(row.value, 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: deleteGamificationRaces() ? undefined : 30, show: saveEditGamificationRaces() || deleteGamificationRaces(), Header: <span title = {Assets.Provider + ' ' + Assets.ID}>{Assets.ID}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getTextFromData(row.value, 'id') : '-'},
                            {show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: Assets.Name, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getTextFromData(row.value, 'name')}>{getTextFromData(row.value, 'name')}</span> : '-'},
                            {width: 90, show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: <span title = {Assets.Valid + ' ' + Assets.FromDate.toLowerCase}>{Assets.FromDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'validFrom')) : '-'},
                            {width: 90, show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: <span title = {Assets.Valid + ' ' + Assets.ToDate.toLowerCase}>{Assets.ToDate}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getDate(getTextFromData(row.value, 'validTo')) : '-'},
                            {width: 50, show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: <span title = {Assets.LeaderboardType}>{Assets.Type}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {Assets.LeaderBoardOddyzzey[+getTextFromData(row.value, 'leaderboardType') - 1]}>{getTextFromData(row.value, 'leaderboardType')}</span> : '-'},
                            {width: 45, show: saveEditGamificationRaces() || saveInsertGamificationRaces(), Header: <span title = {Assets.Number + Assets._of_ + Assets.Races.toLowerCase()}># <FontAwesomeIcon  icon = {faFlagCheckered}/></span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getArrayLengthFromData(row.value, 'raceDetails') : '-'},
                             
                    ]},
                    {
                        Header: Assets.General,
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 30, show: saveEditGeneralSettingsGeneral(), Header: <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.SignUpBonusSetupReward.toLowerCase()} icon = {faGift}/>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <FontAwesomeIcon style = {{color: getSubCategoryFromData(row.value, 'signUpBonusReward', 'active') === 'true' ? '#28a745' : '#dc3545'}} icon = {getSubCategoryFromData(row.value, 'signUpBonusReward', 'active') === 'true' ? faCheck : faTimes}/> : '-'},
                            {width: 100, show: saveEditGeneralSettingsGeneral(), Header: <span title = {Assets.SignUpBonusSetupReward.toLowerCase()}>{Assets.Reward}</span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span title = {getReward(+getSubCategoryFromData(row.value, 'signUpBonusReward', 'rewardId'))}>{getReward(+getSubCategoryFromData(row.value, 'signUpBonusReward', 'rewardId'))}</span> : '-'},
                            {width: 44, show: saveEditGeneralSettingsGeneral(), Header: <span title = {Assets.Races + ' ' + Assets.Start.toLowerCase() + ' ' + Assets.Minutes.toLowerCase()}><FontAwesomeIcon icon = {faFlagCheckered}/> <FontAwesomeIcon icon = {faHourglassStart}/></span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getSubCategoryFromData(row.value, 'globalRacesConfiguration', 'startMinutes') !== '-' ? getSubCategoryFromData(row.value, 'globalRacesConfiguration', 'startMinutes') : '0' : '-'},
                            {width: 44, show: saveEditGeneralSettingsGeneral(), Header: <span title = {Assets.Races + ' ' + Assets.DurationMinutes.toLowerCase()}><FontAwesomeIcon icon = {faFlagCheckered}/> <FontAwesomeIcon icon = {faClock}/></span>, accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? getSubCategoryFromData(row.value, 'globalRacesConfiguration', 'durationMinutes') : '-'},
                            {show: saveEditGeneralSettingsGeneral(), Header: '#' + Assets.Games + Assets._by_ + Assets.Contribution.toLowerCase(), accessor: 'custom.data', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getArrayFromData(row.value, 'gameCategoryContribution')}</span> : '-'}
                    ]},
                    {
                        Header: Assets.Various,
                        headerClassName: 'risk-default',
                        columns: [
                            { show: !showOnlyData(),Header: Assets.Data, accessor: 'custom', headerClassName: 'th-left', Cell: (row) => <div title = {row.value.data}>{row.value.data ? row.value.data : '-'}</div>},
                    ]},
                    {
                        headerClassName: 'risk-default',
                        columns: [
                            {width: 32, Header: <FontAwesomeIcon title = {Assets.Data} icon = {faDatabase}/>, accessor: 'custom.data', headerClassName: 'th-center', Cell: (row) => row.value && row.value !== '{}' ? 
                            <button title = {row.value} className = "btn btn-default btn-xs mr0" type = "button" onClick = {() => copyStringToClipboard(row.value)} style = {{background: 'transparent', border: 'none'}}>
                                <FontAwesomeIcon icon = {faCopy}/>
                            </button>
                            : '-'},
                        ]
                    },
                ]}
                showPaginationTop = {Main.Instance.DatabaseDataState.Log ? true : false}
                showPaginationBottom = {false}
                pageSize={10}
            />
        </div>
    )
}

function filterTournamentsRaces() {
    const data: any[] = []
    for (const item of Main.Instance.DatabaseDataState.tournamentStream) {
        if (isRaces()) {
            if (item.tournamentType === 'Races') {   
                data.push(item)
            } 
        } else if (isTournaments()) {
            if (item.tournamentType === 'Special') {
                data.push(item)
            }
        }
    }
    return data
}

function getDate(unix: string): string {
    if (unix === '' || unix === undefined || unix === null || unix === '-') {
        return '-'
    }
    const Unix = parseInt(unix)
    const date: string = new Date(Unix * 1000).toISOString()
    return (date.substring(0,10))
}

function getCategory(category: number): string {
    if (Main.Instance.DatabaseDataState.gamesListCategories) {
        for (const item of Main.Instance.DatabaseDataState.gamesListCategories) {
            if (item.categoryId === category) {return item.name}
        }
    }
    return '-'
} 

function getReward(RewardId: number): string {
    if (Main.Instance.DatabaseDataState.rewardShopStream) {
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
            if (item.id === RewardId) {
                return item.Reward
            }
        }
    }
    return '-'
}

function getArrayLengthFromData(stringify: string, property: string, object?: boolean): string {
    const data = JSON.parse(stringify)
    // console.log(data)
    // console.log(data[property])
    if (data[property]) {
        
        return data[property].length.toString()
    } else {
        return '-'
    }
}

function getArrayFromData(stringify: string, property: string): string {
    const data = JSON.parse(stringify)
    
    if (data[property]) {
        
        let s: string = ''
        
        for (const item of data[property]) {
            /*
            if (property === 'jurisdictions') {
                for (const Item of Main.Instance.DatabaseDataState.jurisdictionsList) {
                    if (Item.id === item) {
                        if (s.length > 0) {s += ', '}
                        s += Item.code
                    }
                }
            }
            */
            /*if (item.approvable) {
                if (s.length > 0) {s += ', '}
                s += item.name
            } else*/
            if (item && item.games) {
                if (s.length > 0) {s += ', '}
                s += item.contribution + '%: ' + item.games.length
            }
        }
        if (s.length === 0) {
            return '-'
        } else {
            
            return s
        }
        
    } else {
        return '-'
    }
}

function getSubCategoryFromData(stringify: string, property: string,  property1?: string,  property2?: string): string {
    const data = JSON.parse(stringify)
    if (property && property1 && property2) {
        return data[property] && data[property][property1] && data[property][property1][property2] ? data[property][property1][property2].toString() : '-'
    } else if (property && property1) {
        return data[property] && data[property][property1] ? data[property][property1].toString() : '-'
    } else if (property) {
        return data[property] ? data[property].toString() : '-'
    } else {
        return '-'
    }
 
}

function getTextFromData(stringify: string, property: string): string {
    const data = JSON.parse(stringify)
    // console.log (data)
    return data[property] ? data[property].toString() : '-'
}

function isSaveEdit(): boolean {
    return Main.Instance.LogState.distinctTypeValue === 'SAVE/EDIT'
}

function isSaveInsert(): boolean {
    return Main.Instance.LogState.distinctTypeValue === 'SAVE/INSERT'
}

function isDelete(): boolean {
    return Main.Instance.LogState.distinctTypeValue === 'DELETE'
}

function isGames(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Game Store -> Games'
}

function isGamesCentral(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Game Store -> Games Central'
}

function isManageAdminUsers(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'User Management -> Manage Admin Users'
}

function isBonus(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Bonus Section -> Bonus'
}

function isBonusCampaign(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Bonus Section -> Bonus Campaign'
}

function isGameProvider(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Game Store -> Game Provider'
}

function isRgsGameSupplier(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Game Store -> Rgs Game Supplier'
}

function isRewards(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Rewards'
}

function isLevels(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Levels'
}

function isShop(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Shop'
}

function isChances(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Chances'
}

function isTournaments(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Tournaments'
}

function isRaces(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'Gamification -> Races'
}

function isGeneral(): boolean {
    return Main.Instance.LogState.distinctSubtypeValue === 'General Settings -> General'
}

function saveEditUserManagementManageAdminUsers(): boolean {
    if (isSaveEdit() && isManageAdminUsers()) {
        return true
    } 
    return false 
}

function saveInsertUserManagementManageAdminUsers(): boolean {
    if (isSaveInsert() && isManageAdminUsers()) {
        return true
    } 
    return false 
}

function deleteUserManagementManageAdminUsers(): boolean {
    if (isDelete() && isManageAdminUsers()) {
        return true
    } 
    return false 
}

function saveEditGameStoreGames(): boolean {
    if (isSaveEdit() && isGames()) {
        return true
    } 
    return false 
    
}

function saveInsertGameStoreGames(): boolean {
    if (isSaveInsert() && isGames()) {
        return true
    } 
    return false 
    
}

function deleteGameStoreGames(): boolean {
    if (isDelete() && isGames()) {
        return true
    } 
    return false 
    
}

function saveEditGameStoreGamesCentral(): boolean {
    if (isSaveEdit() && isGamesCentral()) {
        return true
    } 
    return false 
}

function saveInsertGameStoreGamesCentral(): boolean {
    if (isSaveInsert() && isGamesCentral()) {
        return true
    } 
    return false 
    
}

function deleteGameStoreGamesCentral(): boolean {
    if (isDelete() && isGamesCentral()) {
        return true
    } 
    return false 
    
}

function saveEditGameStoreBonus(): boolean {
    if (isSaveEdit() && isBonus()) {
        return true
    } 
    return false 
}

function saveInsertGameStoreBonus(): boolean {
    if (isSaveInsert() && isBonus()) {
        return true
    } 
    return false 
}

function deleteGameStoreBonus(): boolean {
    if (isDelete() && isBonus()) {
        return true
    } 
    return false 
}

function saveEditGameStoreBonusCampaign(): boolean {
    if (isSaveEdit() && isBonusCampaign()) {
        return true
    } 
    return false 
}

function saveInsertGameStoreBonusCampaign(): boolean {
    if (isSaveInsert() && isBonusCampaign()) {
        return true
    } 
    return false 
}

function deleteGameStoreBonusCampaign(): boolean {
    if (isDelete() && isBonusCampaign()) {
        return true
    } 
    return false 
}

function saveEditGameStoreGameProvider(): boolean {
    if (isSaveEdit() && isGameProvider()) {
        return true
    } 
    return false 
}

function saveInsertGameStoreGameProvider(): boolean {
    if (isSaveInsert() && isGameProvider()) {
        return true
    } 
    return false 
}

function deleteGameStoreGameProvider(): boolean {
    if (isDelete() && isGameProvider()) {
        return true
    } 
    return false 
    
}

function saveEditGameStoreRgsGameSuppliers(): boolean {
    if (isSaveEdit() && isRgsGameSupplier()) {
        return true
    } 
    return false 
}

function saveInsertGameStoreRgsGameSuppliers(): boolean {
    if (isSaveInsert() && isRgsGameSupplier()) {
        return true
    } 
    return false 
}

function deleteGameStoreRgsGameSuppliers(): boolean {
    if (isDelete() && isRgsGameSupplier()) {
        return true
    } 
    return false 
    
}

function saveEditGamificationLevels(): boolean {
    if (isSaveEdit() && isLevels()) {
        return true
    } 
    return false 
}

function saveInsertGamificationLevels(): boolean {
    if (isSaveInsert() && isLevels()) {
        return true
    } 
    return false 
}

function deleteGamificationLevels(): boolean {
    if (isDelete() && isLevels()) {
        return true
    } 
    return false 
}

function saveEditGamificationRewards(): boolean {
    if (isSaveEdit() && isRewards()) {
        return true
    } 
    return false 
}

function saveInsertGamificationRewards(): boolean {
    if (isSaveInsert() && isRewards()) {
        return true
    } 
    return false 
}

function deleteGamificationRewards(): boolean {
    if (isDelete() && isRewards()) {
        return true
    } 
    return false 
}



function saveEditGamificationShop(): boolean {
    if (isSaveEdit() && isShop()) {
        return true
    } 
    return false 
}

function saveInsertGamificationShop(): boolean {
    if (isSaveInsert() && isShop()) {
        return true
    } 
    return false 
}

function deleteGamificationShop(): boolean {
    if (isDelete() && isShop()) {
        return true
    } 
    return false 
}

function saveEditGamificationChances(): boolean {
    if (isSaveEdit() && isChances()) {
        return true
    } 
    return false 
}

function saveInsertGamificationChances(): boolean {
    if (isSaveInsert() && isChances()) {
        return true
    } 
    return false 
}

function deleteGamificationChances(): boolean {
    if (isDelete() && isChances()) {
        return true
    } 
    return false 
}

function saveEditGamificationTournaments(): boolean {
    if (isSaveEdit() && isTournaments()) {
        return true
    } 
    return false 
}

function saveInsertGamificationTournaments(): boolean {
    if (isSaveInsert() && isTournaments()) {
        return true
    } 
    return false 
}

function deleteGamificationTournaments(): boolean {
    if (isDelete() && isTournaments()) {
        return true
    } 
    return false 
}

function saveEditGamificationRaces(): boolean {
    if (isSaveEdit() && isRaces()) {
        return true
    } 
    return false 
}

function saveInsertGamificationRaces(): boolean {
    if (isSaveInsert() && isRaces()) {
        return true
    } 
    return false 
}

function deleteGamificationRaces(): boolean {
    if (isDelete() && isRaces()) {
        return true
    } 
    return false 
}

function saveEditGeneralSettingsGeneral(): boolean {
    if (isSaveEdit() && isGeneral()) {
        return true
    } 
    return false 
}

function showOnlyData(): boolean {
    return saveEditGameStoreGames() || 
    saveEditGameStoreGamesCentral() || 
    saveEditUserManagementManageAdminUsers() || 
    saveInsertGameStoreGames() ||
    saveInsertGameStoreGamesCentral() ||
    saveInsertUserManagementManageAdminUsers() ||
    deleteUserManagementManageAdminUsers() ||
    saveEditGameStoreBonus() ||
    saveEditGameStoreBonusCampaign() ||
    saveEditGameStoreGameProvider() ||
    saveEditGameStoreRgsGameSuppliers() || 
    saveInsertGameStoreBonus() ||
    saveInsertGameStoreBonusCampaign() ||
    deleteGameStoreBonus() ||
    deleteGameStoreBonusCampaign() || 
    saveInsertGameStoreGameProvider() ||
    saveInsertGameStoreRgsGameSuppliers() ||
    deleteGameStoreGameProvider() ||
    deleteGameStoreRgsGameSuppliers() ||
    saveEditGamificationLevels() ||
    saveInsertGamificationLevels() ||
    deleteGamificationLevels() ||
    saveEditGamificationRewards() ||
    saveInsertGamificationRewards() ||
    deleteGamificationRewards() ||
    saveEditGamificationShop() ||
    saveInsertGamificationShop() ||
    deleteGamificationShop() ||
    saveEditGamificationChances() ||
    saveInsertGamificationChances() ||
    deleteGamificationChances() ||
    saveEditGamificationTournaments() ||
    saveInsertGamificationTournaments() ||
    deleteGamificationTournaments() ||
    saveEditGamificationRaces() ||
    saveInsertGamificationRaces() ||
    deleteGamificationRaces() ||
    saveEditGeneralSettingsGeneral()
}


function getValue(userId: string, what: string): boolean {
    if (Main.Instance.LogState.distinctUserId) {
        for (const item of Main.Instance.LogState.distinctUserId) {
            // console.log (item)
            if (item.user_id === +userId) {
                return item.custom.securityRights[what]
            }
        }
    }
    return false
}

function getSelects(data, ID: string, NAME: string, NAME2?: string): JSX.Element {
  
    return (
        <div>
            <select
                            value = {Main.Instance.LogState.currentId}
                            style = {{height: '24px', marginLeft: '5px'}}
                            onChange = {(e) => {
                                let currentName: string = '0'
                                for (const item of data) {
                                    if (item[ID] === +e.target.value) {
                                            currentName = NAME2 ? item[NAME][NAME2] : item[NAME]
                                    }

                                }
                                Logic.Type.New (Main.Instance.LogState, {
                                    currentId: e.target.value,
                                    currentName
                                })
                                Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                                if (e.target.value === '0') {
                                    DbRoutes.getAdminLog()
                                } else {
                                    DbRoutes.getAdminLog(false, true)
                                }
                            }}
                        >
                            {getOptions(data, true, ID, NAME, NAME2)}
                        </select>

                        <select
                            value = {Main.Instance.LogState.currentName}
                            style = {{height: '24px', marginLeft: '5px'}}
                            onChange = {(e) => {
                                let currentId: string = '0'
                                for (const item of data) {
                                    if (NAME2) {
                                        if (item[NAME][NAME2] === e.target.value) {
                                            currentId = item[ID].toString()
                                        }
                                    } else {
                                        if (item[NAME] === e.target.value) {
                                            currentId = item[ID].toString()
                                        }
                                    }
                                    
                                }
                                Logic.Type.New (Main.Instance.LogState, {
                                    currentId,
                                    currentName: e.target.value
                                })

                                Logic.Type.New (Main.Instance.DatabaseDataState, {Log: undefined})
                                if (e.target.value === '0') {
                                    DbRoutes.getAdminLog()
                                } else {
                                    DbRoutes.getAdminLog(false, true)
                                }
                            }}
                        >
                            {getOptions(data, false, ID, NAME, NAME2)}
                        </select>
        </div>
    )
}

function getOptions(data, isId: boolean, ID: string, NAME: string, NAME2?: string): JSX.Element[] {
    const prop: string[] = []
    const Prop: number[] = []

    if (data) {
        for (const item of data) {
            if (isId) {
                Prop.push (item[ID])
            } else {
                NAME2 ? prop.push (item[NAME][NAME2]) : prop.push (item[NAME])
            }
        }
    }

    if (isId) {
        Prop.sort(sortNumber)
        for (const item of Prop) {
            prop.push (item.toString())
        }
    } else {
        prop.sort()
    }

    const options: JSX.Element[] = []
    options.push (<option key = "-1" value = "0">{Assets.Any.toLowerCase() + ' ' + (isId ? Assets.ID : Assets.Name.toLowerCase())}</option>)
    for (let i: number = 0; i < prop.length; i++) {
        options.push (<option key = {i.toString()} value = {prop[i]}>{prop[i]}</option>)
    } 
    return options

}