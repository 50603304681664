import * as React from 'react'
import {Logic} from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import { BonusNew } from 'src/Logic/Database/DatabaseData'
import ReactTable from 'react-table'
import { triggerType } from 'src/Logic/BonusStore/BonusStore'

interface State {
    autocompleteId: boolean
    bonusId: number
    bonusName: string
    addedBonuses: Array<{bonusId: number, bonusName: string}>
    dependantBonuses: BonusNew[]
}

export default class ManageBonusesPopup extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            autocompleteId: undefined,
            bonusId: -1,
            bonusName: '',
            addedBonuses: [],
            dependantBonuses: undefined
        }
    }
    
    
    public getAutocomplete() {
        const options: JSX.Element[] = []
        const data: BonusNew[] = Object.assign([], this.state.dependantBonuses)
        if (this.state.autocompleteId !== undefined) {
            if (this.state.autocompleteId) {
                for (const item of data) {
                    options.push(<option key = {item.bonusId} value={item.bonusId}>{item.bonusId}</option>) 
                }
                return (
                    <datalist id = {'ids'} style = {{color: 'white'}}>
                        {options}
                    </datalist>
                )
            } else {
                for (const item of data) {
                    options.push(<option key = {item.bonusId} value={item.bonusName}>{item.bonusName}</option>) 
                }
                return (
                    <datalist id = {'names'} style = {{color: 'white'}}>
                        {options}
                    </datalist>
                )
            }   
        }    
    }

    public adaptValues(which: string, value: string) {
        const data: BonusNew[] = Object.assign([], this.state.dependantBonuses)
        if (data) {
            for (const item of data) {
                if (which === 'id') {
                    if (+value === item.bonusId) {
                        this.setState({bonusName: item.bonusName})
                        break
                    }
                } else if (which === 'name') {
                    if (value === item.bonusName) {
                        this.setState({bonusId: item.bonusId})
                        break
                    }
                }
            }
        }
    }

    public addBonus() {
        const data: BonusNew[] = Object.assign([], this.state.dependantBonuses)
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        const addedBonuses = Object.assign([], this.state.addedBonuses)
        if (data && data.find(item => item.bonusId === this.state.bonusId) && bonus && !bonus.dependentBonusIds.find((item) => item === this.state.bonusId)) {
            bonus.dependentBonusIds.push(this.state.bonusId) 
            addedBonuses.push({bonusId: this.state.bonusId, bonusName: this.state.bonusName})
        }
        this.setState({addedBonuses})
        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
    }

    public removeBonus(bonusId) {
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        const addedBonuses = Object.assign([], this.state.addedBonuses)
        let indexBonus
        let indexAddedBonus
        bonus.dependentBonusIds.map((item, index) => {
            if (item === bonusId) {
                indexBonus = index
            }
        })
        addedBonuses.map((item, index) => {
            if (item === bonusId) {
                indexAddedBonus = index
            }
        })
        bonus.dependentBonusIds.splice(indexBonus, 1)
        addedBonuses.splice(indexAddedBonus, 1)

        this.setState({addedBonuses})
        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
    }
    
    public render() {
        if (this.state.dependantBonuses === undefined) {
            const dependantBonuses: BonusNew[] = []
            for (const item of Main.Instance.DatabaseDataState.bonusNew) {
                if (item.triggerType === triggerType[6]) {
                    dependantBonuses.push(item)
                }
            }
            this.setState({dependantBonuses})
        }

        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)

        if (bonus.dependentBonusIds && bonus.dependentBonusIds.length > 0 && (!this.state.addedBonuses || this.state.addedBonuses && this.state.addedBonuses.length === 0)) {
            const addedBonuses = []
            for (const item of bonus.dependentBonusIds) {
                for (const Item of Main.Instance.DatabaseDataState.bonusNew) {
                    if (item === Item.bonusId) {

                        addedBonuses.push({bonusId: Item.bonusId, bonusName: Item.bonusName})
                    }
                }
            }
            this.setState({addedBonuses})
        }
        
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main add-setting-wide">
                    <div style = {{position: 'relative'}}>
                    <button style = {{position: 'absolute', marginRight: '0px', top: '-30px', right: '-10px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.BonusStateNew, {manageDependantBonuses: false})}>
                        <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                    </button>
                    <input 
                        style = {{height: '30px', width: '150px', paddingLeft: '5px', paddingRight: '5px'}} 
                        placeholder = {Assets.Select + ' ' + Assets.Bonus.toLowerCase() + Assets._by_ + Assets.ID} 
                        className = "mr mb" 
                        onChange = {(e) => {
                            this.setState({bonusId: +e.target.value})
                            this.adaptValues('id', e.target.value)
                        }}
                        onFocus = {() => this.setState({autocompleteId: true})}
                        list = "ids"
                        type = "number" 
                        value = {this.state.bonusId !== -1 ? this.state.bonusId.toString() : ''}
                    />
                    {this.getAutocomplete()}
                    <input 
                        style = {{height: '30px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                        placeholder = {Assets.Select + ' ' + Assets.Bonus.toLowerCase() + Assets._by_ + Assets.Name.toLowerCase()} 
                        className = "mr mb" 
                        onChange = {(e) => {
                            this.setState({bonusName: e.target.value})
                            this.adaptValues('name', e.target.value)
                        }} 
                        list = "names"
                        type = "text" 
                        onFocus = {() => this.setState({autocompleteId: false})}
                        value = {this.state.bonusName}
                    />
                    <button 
                        title = {Assets.Add + ' ' + Assets.Bonus} 
                        onClick = {() => {
                            this.addBonus()
                        }} 
                        className = {'btn btn-success btn-sm mr mb5 dibl'}>
                            {Assets.Add}
                    </button>
                    <ReactTable
                        data = {this.state.addedBonuses}
                        columns = {[
                            {width: 100, Header: Assets.Bonus + ' ' + Assets.ID, headerClassName: 'th-left', accessor: 'bonusId' },
                            {Header: Assets.Bonus + ' ' + Assets.Name.toLowerCase(), accessor: 'bonusName', headerClassName: 'th-left'},
                            {width: 100, Header: '', accessor: 'bonusId', Cell: row => {
                                return (
                                <button 
                                    title = {Assets.Remove + ' ' + Assets.Bonus} 
                                    onClick = {() => {
                                        this.removeBonus(row.value)
                                    }} 
                                    className = {'btn btn-danger btn-sm mr mb5 dibl'}>
                                        {Assets.Remove}
                                </button>)
                                
                            }},
                        ]}
                        showPaginationTop = {true}
                        showPaginationBottom = {false}
                        defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
        )
    }

}