import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import {FormInput, /*FormInputNumber,*/ FormSelect, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {isLocalhost, isUserReports, isUserGamanzaMagicHand, gotoTop, isUserGamanzaAdmin, isUserAdmin, isPasswordStrongEnough, isPasswordDifferent} from '../../../Logic/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartLine, faMagic, faDice, faFire, faUserSecret, faUserFriends, faUserTie, faBullhorn, faSave, faUser, faEnvelope, faPhone/*, faEye, faEyeSlash*/} from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-toggle-switch'
// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD
import {SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import {toggleSecurityRights, ToggleSecurityRights} from './ManageAdminUsersOperator'
import * as Assets_ from '../../Assets_'


function getSecurityRights(): JSX.Element[] {
    const securityRights: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(SecurityRights).length / 2; i++) {
        if (!isUserGamanzaAdmin() && (i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN)) {continue}
        if (!isUserGamanzaMagicHand() && i === SecurityRights.GAMANZA_MAGIC_HAND) {continue}
        securityRights.push(
            <button
                key = {i.toString()}
                title = {Assets.ToggleSecurityRight + ' ' + Assets_.SecurityRights[i]}
                style = {{
                    color:  i === SecurityRights.ADMINISTRATOR && Main.Instance.CreateSystemUserState.custom.securityRights.administrator ||
                            i === SecurityRights.SUPPORT && Main.Instance.CreateSystemUserState.custom.securityRights.support ||
                            i === SecurityRights.OPERATOR && Main.Instance.CreateSystemUserState.custom.securityRights.operator ||
                            i === SecurityRights.MARKETING && Main.Instance.CreateSystemUserState.custom.securityRights.marketing ||
                            i === SecurityRights.RFSPECIALIST && Main.Instance.CreateSystemUserState.custom.securityRights.rfSpecialist ||
                            i === SecurityRights.RGSPECIALIST && Main.Instance.CreateSystemUserState.custom.securityRights.rgSpecialist ||
                            i === SecurityRights.GDPR && Main.Instance.CreateSystemUserState.custom.securityRights.gdpr ||
                            i === SecurityRights.ACCOUNTING && Main.Instance.CreateSystemUserState.custom.securityRights.accounting ||
                            i === SecurityRights.REPORTS && Main.Instance.CreateSystemUserState.custom.securityRights.unused3
                            ? '#5cb85c' :
                            i === SecurityRights.GAMANZA_ADMIN && Main.Instance.CreateSystemUserState.custom.securityRights.unused1
                            || i === SecurityRights.GAMANZA_GAMES_ADMIN && Main.Instance.CreateSystemUserState.custom.securityRights.unused2
                            || i === SecurityRights.GAMANZA_MAGIC_HAND && Main.Instance.CreateSystemUserState.custom.securityRights.unused7
                            ? '#f38b00'
                            : '#d9534f',
                    lineHeight: '27px',
                    fontSize: '18px',
                    fontWeight: 700,
                    width: '27px',
                    // borderRadius: '0px',
                    padding: '0px',
                    // marginRight: i === Object.keys(SecurityRights).length / 2 - 1 ? '10px' : '0px',
                    textAlign: 'center',
                    background: 'transparent',
                    border: '0px',
                }}
                onClick = {() => isUserAdmin() ? ToggleSecurityRights(5, i) : void 0}
                className = "btn btn-default btn-xs dbl fl mr0 br0"
            >
                <FontAwesomeIcon icon = {Assets_.SecurityRightsIcons[i]}/>
            </button>,
        )
    }
    return securityRights
}

export function getSystemUsersCount() {

    if (Main.Instance.CreateSystemUserState.countCalled === false) {

        Logic.Type.New(Main.Instance.CreateSystemUserState, {countCalled:true})
        DbRoutes.countUsers (   Main.Instance.CreateSystemUserState.filter_role,
                                Assets_.systemAdmin,
                                Assets_.systemRead)
        // DbRoutes.getSystemUsers ()
    }
}

function getSystemUsers() {

    Logic.Type.New(Main.Instance.DatabaseDataState,{users1Stream : undefined})
}

function clearFields() {

    Logic.Type.New(Main.Instance.CreateSystemUserState, {
        custom: {environmentsAllowed: [false, false, false, false],securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
        username : '', password : '', // role_id : '0', 
        activity_id : '1', mode : false, firstName: '', lastName: '', email: '', phoneNumber: '', id: '0' })
}

function  handleInsertUpdate() {
    let items:any
    items = {
        custom: JSON.stringify(Main.Instance.CreateSystemUserState.custom),
        role : 1,

        username:       !Main.Instance.CreateSystemUserState.username        ? ''  : 
                        Main.Instance.CreateSystemUserState.username,
        password:       !Main.Instance.CreateSystemUserState.password        ? ''  : 
                        Main.Instance.CreateSystemUserState.password,
/*
        role:           !Main.Instance.CreateSystemUserState.role_id         ? '0' : 
                        Main.Instance.CreateSystemUserState.role_id,
*/
        active:         !Main.Instance.CreateSystemUserState.activity_id     ? '1' : 
                        Main.Instance.CreateSystemUserState.activity_id,
        id:             !Main.Instance.CreateSystemUserState.id              ? ''  : 
                        Main.Instance.CreateSystemUserState.id,
        firstname:      !Main.Instance.CreateSystemUserState.firstName        ? ''  : 
                        Main.Instance.CreateSystemUserState.firstName,
        lastname:       !Main.Instance.CreateSystemUserState.lastName        ? ''  : 
                        Main.Instance.CreateSystemUserState.lastName,
        email:          !Main.Instance.CreateSystemUserState.email        ? ''  : 
                        Main.Instance.CreateSystemUserState.email,
        phone:          !Main.Instance.CreateSystemUserState.phoneNumber        ? ''  : 
                        Main.Instance.CreateSystemUserState.phoneNumber,

        entity_id: '0',

    }

    if (items.username.length === 0) {

        alert (Assets.compulsoryUsername)
        return
    } else if (items.password.length === 0 && Main.Instance.CreateSystemUserState.mode === false) {

        alert (Assets.compulsoryPassword)
        return
/*
    } else if (parseInt(items.role) === 0) {

        alert (Assets.compulsoryRole)
        return
*/
    } else if (items.firstname.length === 0) {

        alert (Assets.compulsoryFirstName)
        return
    } else if (items.lastname.length === 0) {

        alert (Assets.compulsoryLastName)
        return
    } else if (items.email.length === 0) {

        alert (Assets.compulsoryEmail)
        return
    /*
    } else if (items.phone.length === 0) {

        alert (Assets.compulsoryPhone)
        return
    } else if (items.phone.charAt(0) !== '+') {

        alert (Assets.compulsoryPhoneStartWithPlus)
        return
*/
    } else if (Main.Instance.CreateSystemUserState.mode === false) {
    // means that we are in false mode = INSERT

        // let username = {username: !Main.Instance.CreateSystemUserState.username ? '' : 
        // Main.Instance.CreateSystemUserState.username}
        if (+Main.Instance.CreateSystemUserState.security_question !== 0) {
            items.sq = +Main.Instance.CreateSystemUserState.security_question
            if (Main.Instance.CreateSystemUserState.security_question_answer === undefined ||
                Main.Instance.CreateSystemUserState.security_question_answer === null ||
                Main.Instance.CreateSystemUserState.security_question_answer.length === 0) {
                alert (Assets.compulsorySecurityAnswer)
                return
            }
            items.sqa = Main.Instance.CreateSystemUserState.security_question_answer
        }


        if (parseInt(items.role) === 1) { items.role = Assets_.systemAdmin.toString() } else if (parseInt(items.role) === 2) { items.role = Assets_.systemRead.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' } else if (parseInt(items.active) === 2) { items.active = 'false' }

        // Logic.Type.New (Main.Instance.TempState,{temp:true})
            
        DbRoutes.insertUser (items, InsertLastPage)
    } else {
        // means that we are in true mode = UPDATE
        // alert (items.role)

        if (parseInt(items.role) === 1) { items.role = Assets_.systemAdmin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.systemRead.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }

        if (items.password.length === 0) {
            DbRoutes.updateUserNoPassword (items, Update)
        } else { 
            DbRoutes.updateUser (items, Update)
        }
    }
}

function handleEdit(row: any) {
    gotoTop()
    if (row) {
        let activityId:string

        // let roleId: string = (parseInt(row.original.role_id) % 10).toString()

        row.original.active.toString() === 'true' ? activityId = '1' : activityId = '2'

        Logic.Type.New(Main.Instance.CreateSystemUserState,{ 
            mode: true,
            custom: row.original.custom,
            custom_saved: row.original.custom,
            id: row.original.id,
            username: row.original.username, 
            username_saved: row.original.username, 
            password: '',
            // password_saved: rowData[2],
            // role_id: roleId, 
            activity_id: activityId, 
            firstName:row.original.firstname !== null ? row.original.firstname : '',
            firstName_saved:row.original.firstname !== null ? row.original.firstname : '',
            lastName:row.original.lastname !== null ? row.original.lastname : '',
            lastName_saved:row.original.lastname !== null ? row.original.lastname : '',
            email:row.original.email !== null ? row.original.email : '',
            email_saved:row.original.email !== null ? row.original.email : '',
            phoneNumber:row.original.phone !== null ? row.original.phone : '',
            phoneNumber_saved:row.original.phone !== null ? row.original.phone : '',
            // role_id_saved: roleId, 
            activity_id_saved: activityId,   
        })
    }
}

function toggleActive(id: number, value: string, row?: any) {
    
    if (id) { DbRoutes.Toggle('UPDATE admin.hashed SET active = ', id.toString(), value, getSystemUsersCount, undefined, row) }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.SystemUser + ' ' + 
                    Assets.withUsername + ' "' + row.original.username + '"?')) {
            if (Main.Instance.CreateSystemUserState.pNum === 
                (Math.trunc((Main.Instance.CreateSystemUserState.count - 2) / 
                Main.Instance.CreateSystemUserState.pSize) + 1)) {
                DbRoutes.removeUser (row.original.id, DeleteLastPage)
            } else {
                DbRoutes.removeUser (row.original.id, DeleteNotLastPage)
            }
        }
    }
}

function gotoInsertMode() {
    if (Main.Instance.CreateSystemUserState.username === Main.Instance.CreateSystemUserState.username_saved &&
        // (parseInt(Main.Instance.CreateSystemUserState.role_id) === parseInt(Main.Instance.CreateSystemUserState.role_id_saved)) &&
        (parseInt(Main.Instance.CreateSystemUserState.activity_id) === 
        parseInt(Main.Instance.CreateSystemUserState.activity_id_saved)) &&
        Main.Instance.CreateSystemUserState.firstName === Main.Instance.CreateSystemUserState.firstName_saved &&
        Main.Instance.CreateSystemUserState.lastName === Main.Instance.CreateSystemUserState.lastName_saved &&
        Main.Instance.CreateSystemUserState.phoneNumber === Main.Instance.CreateSystemUserState.phoneNumber_saved &&
        Main.Instance.CreateSystemUserState.email === Main.Instance.CreateSystemUserState.email_saved
        && Main.Instance.CreateSystemUserState.custom === Main.Instance.CreateSystemUserState.custom_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {clearFields() }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.CreateSystemUserState,{offset:(0).toString()})
    getSystemUsers()
    Logic.Type.New(Main.Instance.CreateSystemUserState,{pNum: 0})

}

// function PrevPage() {
//     if (Main.Instance.CreateSystemUserState.pNum > 0) {
//         Logic.Type.New(Main.Instance.CreateSystemUserState, {
//             offset: ((Main.Instance.CreateSystemUserState.pNum - 1) * 
//                     Main.Instance.CreateSystemUserState.pSize).toString()})
//         getSystemUsers()
//         Logic.Type.New(Main.Instance.CreateSystemUserState,{pNum: Main.Instance.CreateSystemUserState.pNum - 1})
//     } else {
//         LastPage()
//     }
// }

// // function NextPage() {
// //     if ((Main.Instance.CreateSystemUserState.pNum + 1) * Main.Instance.CreateSystemUserState.pSize < 
// //         Main.Instance.CreateSystemUserState.count) {

// //         Logic.Type.New(Main.Instance.CreateSystemUserState, {
// //             offset: ((Main.Instance.CreateSystemUserState.pNum + 1) * 
// //             Main.Instance.CreateSystemUserState.pSize).toString()})
// //         getSystemUsers()
// //         Logic.Type.New(Main.Instance.CreateSystemUserState, {pNum: Main.Instance.CreateSystemUserState.pNum + 1})
// //     } else {
// //         FirstPage()
// //     }
// // }

function LastPage() {
    if (Main.Instance.CreateSystemUserState.count <= Main.Instance.CreateSystemUserState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CreateSystemUserState.count === 0) {
            Logic.Type.New(Main.Instance.CreateSystemUserState, {offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.CreateSystemUserState.count - 1) / 
            Main.Instance.CreateSystemUserState.pSize)
            Logic.Type.New(Main.Instance.CreateSystemUserState, {
                offset:(npNum * Main.Instance.CreateSystemUserState.pSize).toString()})
        }
        getSystemUsers()
        Logic.Type.New(Main.Instance.CreateSystemUserState, {pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateSystemUserState, {count:Main.Instance.CreateSystemUserState.count++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateSystemUserState, {count:Main.Instance.CreateSystemUserState.count--})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateSystemUserState, {count:Main.Instance.CreateSystemUserState.count--})
    getSystemUsers()
}

function Update() {
    clearFields()
    getSystemUsers()
}

// function RecordsPerPageChanged(text:string) {
//     if (parseInt(text) > 0) {
//         Logic.Type.New(Main.Instance.CreateSystemUserState,{pSize:parseInt(text)})
//         FirstPage()
//     }
// }
/*
function ApplyFilterRole(text:string) {
    Logic.Type.New(Main.Instance.CreateSystemUserState,{filter_role:text})
    FirstPage()
}
*/
export function ManageAdminUsersSystem() {
    // console.log(Main.Instance.CreateSystemUserState.username)
    const validation: boolean = isPasswordStrongEnough(Main.Instance.CreateSystemUserState.password)
    const validation2: boolean = isPasswordDifferent(
        Main.Instance.CreateSystemUserState.password,
        Main.Instance.CreateSystemUserState.username,
        Main.Instance.CreateSystemUserState.email,
        Main.Instance.CreateSystemUserState.phoneNumber,
    )
    const validationsq: boolean = parseInt(Main.Instance.CreateSystemUserState.security_question) !== 0
    const validationsqa: boolean = Main.Instance.CreateSystemUserState.security_question_answer.length > 0
    
    
    return (
        <div>
            {isUserAdmin() ?
                            
            <div className = "row admin-users-container">
                <div 
                    className =    {Main.Instance.CreateSystemUserState.mode ? 
                                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
                >
                    <div className = "create-input">
                        <div className = "row admin-users-row">
                            <h4>
                                {Main.Instance.CreateSystemUserState.mode ? 
                                Assets.editSystemUser + ': ' + 
                                Main.Instance.CreateSystemUserState.username_saved : 
                                Assets.addSystemUser}
                            </h4>

                            {Main.Instance.CreateSystemUserState.mode ? 
                            <ButtonIcon 
                                description = {Assets.discardChanges} 
                                click = {() => gotoInsertMode()} 
                                ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                                icon = {Assets_.faCancelButtonClass}
                            /> : void 0}
                        </div>

                        <div className = "row admin-users-row">
                            <FormInput 
                                value = {!Main.Instance.CreateSystemUserState.username ? '' : 
                                        Main.Instance.CreateSystemUserState.username} 
                                ClassName = "form-control register-input" 
                                type = "text" 
                                onChange = {(text) => {
                                    Logic.Type.New(Main.Instance.CreateSystemUserState, {username:text})}} 
                                // placeholder = {Assets.pUsername} 
                                autoFill = "nope"
                                icon = {Assets_.faUserClass}
                            />
                            {/*
                            <FormSelect 
                                value = {!Main.Instance.CreateSystemUserState.role_id ? '0' : 
                                        Main.Instance.CreateSystemUserState.role_id} 
                                data = {[0]} 
                                ClassName = "form-control" 
                                o1 = {Assets.RoleQ} 
                                o2 = {Assets.Super} 
                                o3 = {Assets.ReadOnly} 
                                onChange = {text => {
                                    Logic.Type.New(Main.Instance.CreateSystemUserState, {role_id:text})}} 
                                icon = {Assets_.faRoleClass}
                            />
                            */}
                            {Main.Instance.CreateSystemUserState.mode ? void 0 :
                            <>
                            <FormInput
                                validation = {validation && validation2}
                                description = {!validation ? Assets.passwordRequirements1 : !validation2 ? Assets.passwordRequirements2 : Assets.passwordRequirements0}
                                value = {!Main.Instance.CreateSystemUserState.password ? '' : 
                                        Main.Instance.CreateSystemUserState.password} 
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(text) => {Logic.Type.New(Main.Instance.CreateSystemUserState, {password:text})}} 
                                placeholder = {Assets.pPassword} 
                                icon = {Assets_.faPasswordClass}
                                autoFill = "new-password"
                            />

                            <FormSelect
                                validation = {validationsq}
                                value = {!Main.Instance.CreateSystemUserState.security_question ? '0' : 
                                        Main.Instance.CreateSystemUserState.security_question}
                                data = {Assets_.securityQuestions} 
                                ClassName = "form-control" 
                                o1 = {Assets_.securityQuestions[0].name} 
                                onChange = {(security_question) => Logic.Type.New(Main.Instance.CreateSystemUserState, {security_question})} 
                                icon = {Assets_.faQuestionCircleButtonClass}
                            />

                            <FormInput
                                validation = {validationsqa}
                                value = {!Main.Instance.CreateSystemUserState.security_question_answer ? '' : 
                                        Main.Instance.CreateSystemUserState.security_question_answer}
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(security_question_answer) => Logic.Type.New(Main.Instance.CreateSystemUserState, {security_question_answer})} 
                                placeholder = {Assets.pSecurityQuestionAnswer} 
                                icon = {Assets_.faQuestionCircleWhiteButtonClass}
                            />
                        </>}
                        {Main.Instance.CreateSystemUserState.custom && Main.Instance.CreateSystemUserState.custom.securityRights ? getSecurityRights() : void 0}
                    </div>

                    <div className = "admin-users-row row">
                        <FormInput 
                            value = {!Main.Instance.CreateSystemUserState.firstName ? '' : 
                                    Main.Instance.CreateSystemUserState.firstName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateSystemUserState, {firstName:text})}} 
                            placeholder = {Assets.pFirstName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateSystemUserState.lastName ? '' : 
                                    Main.Instance.CreateSystemUserState.lastName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateSystemUserState, {lastName:text})}} 
                            placeholder = {Assets.pLastName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateSystemUserState.email ? '' : 
                                    Main.Instance.CreateSystemUserState.email} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateSystemUserState, {email:text})}} 
                            placeholder = {Assets.pEmail} 
                            icon = {Assets_.faEmailClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateSystemUserState.phoneNumber ? '' : 
                                    Main.Instance.CreateSystemUserState.phoneNumber} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CreateSystemUserState, {phoneNumber:text})}} 
                            placeholder = {Assets.pPhone} 
                            icon = {Assets_.faPhoneClass}
                        />

                        <RadioButton2
                            value = {!Main.Instance.CreateSystemUserState.activity_id ? '1' :
                                    Main.Instance.CreateSystemUserState.activity_id} 
                            ClassName = "input-group-radiobutton2 margin-bottom-sm"
                            text = {Assets.Activity} 
                            title = {Assets.ActivityExplained}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => {
                                Logic.Type.New(Main.Instance.CreateSystemUserState, {activity_id:text})}} 
                        />

                    </div>

                    <div className = "row admin-users-row">
                        <ButtonIcon
                            disabled = {!Main.Instance.CreateSystemUserState.mode && !validation}
                            description =   {Main.Instance.CreateSystemUserState.mode ? 
                                            Assets.saveChanges : Assets.cSystemUser} 
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.CreateSystemUserState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr' : 
                                        'btn btn-primary btn-sm btn-plus fr'} 
                            Icon = {faSave as IconDefinition} 
                            text = {Assets.Save}
                        />
                        </div>
                    </div>            
                </div>
            <br/>
            </div>
            
            : void 0}
            {/* <div className = "control-panel">

                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.CreateSystemUserState.pSize ? '' : 
                    Main.Instance.CreateSystemUserState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
                />

                <button 
                    className = "ml20 btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>

                <FormSelect 
                    span = {true}
                    value = {!Main.Instance.CreateSystemUserState.filter_role? '0' : 
                            Main.Instance.CreateSystemUserState.filter_role}
                    data = {[0]}
                    ClassName = "control-panel-select-narrow"
                    o1 = {Assets.RoleAny} 
                    o2 = {Assets.Super}
                    o3 = {Assets.ReadOnly}
                    onChange = {text => ApplyFilterRole(text)}
                />

                <span className = "label label-success table-label mt5">
                    {Assets.records}{Main.Instance.CreateSystemUserState.count ? 
                    Main.Instance.CreateSystemUserState.count : 0}
                </span>
                <span className = "label label-success table-label mt5">
                    {Assets.showing}
                    {Main.Instance.CreateSystemUserState.count ? 
                    Main.Instance.CreateSystemUserState.pNum * Main.Instance.CreateSystemUserState.pSize + 1 : 0}{' - '}
                    {Main.Instance.CreateSystemUserState.count ? 
                    ((Main.Instance.CreateSystemUserState.pNum + 1) * 
                    Main.Instance.CreateSystemUserState.pSize > 
                    Main.Instance.CreateSystemUserState.count ? 
                    Main.Instance.CreateSystemUserState.count : 
                    (Main.Instance.CreateSystemUserState.pNum + 1) * 
                    Main.Instance.CreateSystemUserState.pSize) : 0}
                </span>
                <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.CreateSystemUserState.count ? 
                    Main.Instance.CreateSystemUserState.pNum + 1 :0}{' / '}
                    {Main.Instance.CreateSystemUserState.count ? 
                    Math.trunc((Main.Instance.CreateSystemUserState.count - 1) /
                    Main.Instance.CreateSystemUserState.pSize+1) : 0}
                </span>
            </div> */}
            <h3>{Assets.tSystemUsers}</h3>
            {Main.Instance.DatabaseDataState.users1Stream ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.users1Stream}
                columns = {[
                    {
                        Header: '',
                        headerClassName: 'risk-default-light ',
                        columns: [
                    {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {Header: Assets.Username, accessor: 'username', headerClassName: 'th-left', Cell: (row) => <span title = {row.value} style = {{fontWeight: row.original.id === Main.Instance.LoginState.id ? 900 : 400}}>{row.value}</span>},
                    /*{width: 120, Header: 'Role', accessor: 'role', headerClassName: 'th-left'},*/
                    /*{minWidth: 60, Header: Assets_GDPR, accessor: 'gdpr', headerClassName: 'th-left', Cell: (row) =>
                    <button disabled = {!isUserAdmin()} onClick = {() => toggleGDPR(row.original.id, row.value)} style = {{border: '0px', background: 'transparent'}}><FontAwesomeIcon style = {row.value ? {color: 'green'} : {color: 'red'}} icon = {row.value ? faEye : faEyeSlash} size = "2x"/></button>},*/
                    {width: 300, Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                    <div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faUser} />
                            {row.original.firstname !== null && row.original.lastname !== null && row.original.firstname.length > 0 && row.original.lastname.length > 0 ?
                            row.original.firstname + ' ' + row.original.lastname : row.original.firstname !== null && row.original.firstname.length > 0 ?
                            row.original.firstname : row.original.lastname !== null && row.original.lastname.length > 0 ? row.original.lastname : Assets.notDefined}
                        </div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faEnvelope}/>
                            {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                        </div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faPhone}/>
                            {row.original.phone !== null && row.original.phone.length > 0 ? row.original.phone : Assets.notDefined}
                        </div>
                    </div>},
                {width: 60, Header: Assets.Active, accessor: 'active', Cell: (row) => 
                <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => toggleActive(row.original.id, row.value, row.original)}
                        on = {row.value}
                        disabled = {!isUserAdmin()}
                    />
                </div>},
                ]},
                {
                Header: Assets.SecurityRights,
                headerClassName: 'risk-default ',
                columns: [
                    {width: 105, Header: Assets_.SecurityRights[SecurityRights.ADMINISTRATOR], accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.administrator, SecurityRights.ADMINISTRATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.administrator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ADMINISTRATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUserFriends}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.SUPPORT]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.support, SecurityRights.SUPPORT, row.value, row.original)}
                        on = {row.original.custom.securityRights.support}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.SUPPORT] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header:  <span><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.OPERATOR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.operator, SecurityRights.OPERATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.operator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.OPERATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span><FontAwesomeIcon icon = {faBullhorn}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.MARKETING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.marketing, SecurityRights.MARKETING, row.value, row.original)}
                        on = {row.original.custom.securityRights.marketing}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.MARKETING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span title = {Assets.RFSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RFSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.rfSpecialist, SecurityRights.RFSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rfSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RFSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span title = {Assets.RGSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RGSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.rgSpecialist, SecurityRights.RGSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rgSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RGSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 70, Header: <span><FontAwesomeIcon icon = {faUserSecret}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GDPR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.gdpr, SecurityRights.GDPR, row.value, row.original)}
                        on = {row.original.custom.securityRights.gdpr}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.GDPR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 110, Header: <span><FontAwesomeIcon icon = {faUserTie}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.ACCOUNTING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.accounting, SecurityRights.ACCOUNTING, row.value, row.original)}
                        on = {row.original.custom.securityRights.accounting}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ACCOUNTING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {width: 90, Header: <span><FontAwesomeIcon icon = {faChartLine}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.REPORTS]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => (!isUserAdmin() || !isUserReports()) && !isLocalhost() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.unused3, SecurityRights.REPORTS, row.value, row.original)}
                        on = {row.original.custom.securityRights.unused3}
                        disabled = {(!isUserAdmin() || !isUserReports()) && !isLocalhost()}
                        title = {!isUserAdmin() && isUserReports() || isLocalhost() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.REPORTS] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 95, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}><FontAwesomeIcon icon = {faFire}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN].split(' ')[0]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        on = {row.original.custom.securityRights.unused1}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}><FontAwesomeIcon icon = {faDice}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.unused2, SecurityRights.GAMANZA_GAMES_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused2}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}
                    />
                    </div>},

                    {show: isUserGamanzaAdmin() && isUserGamanzaMagicHand(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}><FontAwesomeIcon icon = {faMagic}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() && isUserGamanzaMagicHand() ? toggleSecurityRights(5, getSystemUsersCount, row.original.id, row.original.custom.securityRights.unused7, SecurityRights.GAMANZA_MAGIC_HAND, row.value, row.original) : void 0}
                        disabled = {!(isUserGamanzaAdmin() && isUserGamanzaMagicHand())}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused7}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}
                    />
                    </div>},
                ]},
                {
                    Header: '',
                    headerClassName: 'risk-default-light ',
                    columns: [
                    {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)}],
                }]}
                showPaginationBottom = {false}
                defaultPageSize={10}
                showPaginationTop={true}
            />
            : getSystemUsersCount()}
        </div>
        )
}
