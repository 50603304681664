import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import ReactTable from 'react-table'
import {Main} from '../../../Logic/Main'
import axios from 'axios'
import * as Config from '../../../Logic/Config'
// import { Logic } from 'src/Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {injectSpaces} from '../../../Logic/Utils'
import * as OddsData from '../../../Logic/OddsStore/OddsState'

enum FilterDate {
    AnyDate,
    Yesterday,
    Today,
    Tomorrow
}


interface State {
    selectedMatch: number
    navigationBet: number
    filterMatches: boolean
    selectedMatchCategories?: string
    selectedMatchDetails?: string
    odds?: OddsData.Odd[]
    league: string
    region: string
    sport: string
    filterDate: FilterDate
}

export class TableOfMatches extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            selectedMatch: -1,
            navigationBet: -1,
            filterMatches: false,
            league: '',
            region: '',
            sport: '',
            filterDate: FilterDate.AnyDate
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public async SelectButton(selectedMatch: number, row) {
        // console.log (row)
        // here we need to get odds for this particular match
        const requestOdds: string = Config.oddsServerOdds + '?matchid=' + selectedMatch
        const responseOdds = await axios.get(requestOdds, {headers: {'api-key': Config.keyGetOdds}})
        const selectedMatchCategories: string = row.original.sport + ', ' + row.original.country + ', ' + row.original.tournament
        const day: number = new Date(row.original.date).getDay()
        const selectedMatchDetails: string = row.original.home + ' - ' + row.original.away + ' (' + Assets.DaysInWeek[day] + ', ' + row.original.date.substring(0,16).replace('T', ' ') + ' ' + Assets_.UTC + ')'
        this.setState({odds: responseOdds.data, navigationBet: 0, selectedMatch, selectedMatchCategories, selectedMatchDetails})
    }

    public selectButton(selected: number, row): JSX.Element {
        return (
            <button
                type = "button"
                className = {selected === this.state.selectedMatch ? 'btn btn-primary btn-sm mr0' : 'btn btn-default btn-sm mr0'}
                onClick = {() => this.SelectButton(selected, row)}
            >
                {Assets.View + ' ' + Assets.Odds.toLowerCase()} [{row.original.count}]
            </button>
        )
    }

    public getBetName(betid: number): string {
        for (const item of Main.Instance.OddsState.bet) {
            if (item.id === betid) {
                return item.name
            }
        }
        return ''
    }

    public getNavigationBet(): JSX.Element[] {
        const n: JSX.Element[] = []
        for (let i: number = 0; i < this.state.odds.length; i++) {
            const title: string = this.getBetName(this.state.odds[i].betid)
            const shortTitle: string = title.replace('Asian Handicap', 'AH').replace('European Handicap', 'EH').replace('Both Teams to Score', 'BTS').replace('Double Chance', 'DC').replace('Over/Under', 'OU').replace('Draw No Bet', 'DNB')
            const desc: string = title.replace('3-way', '1X2')
            n.push(
                <button
                    title = {desc}
                    className = {this.state.navigationBet === i ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}
                    key = {i.toString()}
                    type = "button"
                    onClick = {() => this.setState({navigationBet: i})}
                >
                    {this.state.navigationBet === i ? title : shortTitle}
                </button>
            )
        }
        return n
    }

    public getBookmaker(id: number): string {
        if (Main.Instance.OddsState.bookmakers) {
            for (const item of Main.Instance.OddsState.bookmakers) {
                if (item.id === id) {
                    return item.name
                }
            }
        }
        return Assets.Unknown + ' ' + Assets.Bookmaker.toLowerCase()
    }

    public getIat(iat: number): string {
        return (new Date(iat * 1000)).toISOString().substring(0,19).replace('T', ' ')
    }

    public getOdd(odds: OddsData.IOddsData[], bestOdds: OddsData.IOddsData, ID: number): JSX.Element[] {
        // console.log (bestOdds)
        // console.log (odds)
        const odd: JSX.Element[] = []
        for (let i: number = 0; i < odds.length; i++) {
            if (odds[i].odds) {
                odd.push(<span key = {odds[i].name} style = {{fontSize: '14px'}} className = "label label-default br0">{odds[i].name}</span>)
                odd.push(<span key = {odds[i].name + '_'} style = {{fontSize: '14px'}} className = {odds[i].odds === bestOdds[i].odds && odds[i].outcome === bestOdds[i].outcome ? 'label label-danger br0' : 'label label-primary br0'}>{odds[i].odds ? odds[i].odds.toFixed(2) : '-'}</span>)
                if (odds[i].iat && ID !== 0) {
                    odd.push(<span key = {odds[i].name + '__'} style = {{fontSize: '14px', marginRight: i === odds.length - 1 ? '0px' : '10px'}} className = "label label-default br0">{this.getIat(odds[i].iat)}</span>)
                }
            }
        }
        if (ID === 0 && odds[0] && odds[0].iat) {
            odd.push (<span key = {Assets.Imported} style = {{fontSize: '14px'}} className = "label label-success br0 ml">{Assets.Imported + Assets._at_ + this.getIat(odds[0].iat)}</span>)
        }
        return odd
    }

    public getOdds(): JSX.Element {
        // console.log (this.state.odds[this.state.navigationBet].odds)
        return (
            <ReactTable
                data = {this.state.odds[this.state.navigationBet].odds}
                columns = {[
                    {width: 50, Header: Assets.ID, headerClassName: 'th-left', accessor: 'id'},
                    {width: 200, Header: Assets.Bookmaker, headerClassName: 'th-left', accessor: 'id', Cell: (row) => this.getBookmaker(row.value)},
                    {Header: Assets.Odds, headerClassName: 'th-left', accessor: 'odds', Cell: (row) => this.getOdd(row.value, this.state.odds[this.state.navigationBet].odds[0].odds, row.original.id)},
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                defaultPageSize={10}
                pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
            />
        )
    }

    public getLink(section: string, league: string, sportid: number): string|undefined {
        // console.log (Main.Instance.OddsState.league)
        if (Main.Instance.OddsState.league) {
            for (const item of Main.Instance.OddsState.league) {
                if (item.data.section === section && item.data.league === league && (item.data.sportid === sportid || item.data.sportid === undefined && sportid === OddsData.SOCCER)) {
                    return item.data.link
                }
            }
        }
        return undefined
    }

    public fetchResult(row: OddsData.Match/*, date: string, status: number*/) {
        // here we should fetch result - develop 18 10 2019
        // console.log ('country ', row.country)
        // console.log ('tournament ', row.tournament)
        // console.log (date)
        // console.log (status)
        // this is only for oddsportal events
        // link is calculated based on link, sport, country and tournament
        // console.log (row)
        const link: string = this.getLink(row.country, row.tournament, row.sportid)
        // console.log (link)
        if (link) {
            // console.log ('link ', link)
            const source: OddsData.LeagueData = {
                link,
                sportid: row.sportid,
                section: row.country,
                league: row.tournament
            }
            // console.log (source)
            axios.post(Config.oddsServerResults, {source}, {headers: {'api-key': Config.keyGetOdds}})
            .then((response) => {
                // console.log (response)
                if (response.status === 200) {DbRoutes.updateMatches()}
            })
        }
    }

    public fetchMatch(row: OddsData.Match, date: string) {
        // here we should fetch result - develop 18 10 2019
        // console.log ('country ', row.country)
        // console.log ('tournament ', row.tournament)
        // console.log (date)
        // console.log (status)
        // this is only for oddsportal events
        // link is calculated based on link, sport, country and tournament
        // console.log (row)
        const link: string = this.getLink(row.country, row.tournament, row.sportid)
        // console.log (link)
        if (link) {
            // console.log ('link ', link)
            const source: OddsData.LeagueData = {
                link,
                sportid: row.sportid,
                section: row.country,
                league: row.tournament,
                home: row.home,
                away: row.away,
                date: date.substring(0, 10),
                time: date.substring(11, 17)
            }
            // console.log (source)
            axios.post(Config.oddsServerMatches, {import: OddsData.Import.Oddsportal, source}, {headers: {'api-key': Config.keyGetOdds}})
            .then((response) => {
                // console.log (response)
                if (response.status === 200) {DbRoutes.updateMatches()}
            })
        }
    }

    // awayid
    public getResult(row: OddsData.Match, result: boolean): JSX.Element {
        if (row && row.results) {
            // console.log (row.results)
            return result ? <strong>{row.results.name}</strong> : <span>-</span>
            // return <button>WE GOT IT</button>
        }

        let date: string = row.date.substring (0,10) + ' ' + row.date.substring(11,16)
        const Now: Date = new Date()
        const now: string = Now.toISOString().substring(0,16)// .replace('T', ' ')
        const date_: Date = new Date(Date.UTC(+date.substring(0,4), +date.substring(5,7) - 1, +date.substring(8,10), +date.substring(11,13), +date.substring(14,16), 0, 0))
        const in3h: number = date_.getTime() + 3 * 60 * 60 * 1000
        const in3hours: string = (new Date(in3h)).toISOString().substring(0,16)// .replace('T', ' ')
        date = date.replace(' ', 'T')
        // console.log ('|' + date + '|', '|' + now + '|', '|' + in3hours + '|')
        const status: OddsData.MatchStatus = (
            now < date ? OddsData.MatchStatus.NotStarted :
            now < in3hours ? OddsData.MatchStatus.Ongoing :
            OddsData.MatchStatus.GetResults)
        // console.log ()
        return ( result ? 
            <button
                onClick = {() => this.fetchResult(row)}
                disabled = {status === OddsData.MatchStatus.NotStarted}
                type = "button"
                className = {status === OddsData.MatchStatus.GetResults ? 'btn btn-xs btn-danger' : status === OddsData.MatchStatus.NotStarted ? 'btn btn-xs btn-success' : 'btn btn-xs btn-primary'}
            >
                {injectSpaces(OddsData.MatchStatus[status])}
            </button>
            :
            status === OddsData.MatchStatus.NotStarted ?
            <button
                onClick = {() => this.fetchMatch(row, date)}
                type = "button"
                className = "btn btn-xs btn-primary"
            >
                {Assets.Import}
            </button>
            :
            <span>-</span>
        )
    }

    public getTeam(homeTeam: boolean, result: string, team: string): JSX.Element {
        const home: string = result.substring(0, result.indexOf(':'))

        let end: number = result.length
        const space: number = result.indexOf(' ')
        if (space !== -1 && space < end) {
            end = space
        }
        const away: string = result.substring(result.indexOf(':') + 1, end)
        const Home: number = +home
        const Away: number = +away
        const className: string = 'label label-sm ' + (Home > Away && homeTeam || Home < Away && !homeTeam ? 'label-success' : Home < Away && homeTeam || Home > Away && !homeTeam ? 'label-danger' : 'label-default')
        return <span style = {{fontSize: '14px'}} className = {className}>{team}</span>
    }

    public getSport(): JSX.Element[] {
        const sport: JSX.Element[] = []
        sport.push(<option key = "_" value = "">{Assets.Any + ' ' + Assets.Sport.toLowerCase()}</option>)
        if (Main.Instance.OddsState.distinctSport) {
            for (const item of Main.Instance.OddsState.distinctSport) {
                sport.push(<option key = {item.sport} value = {item.sport}>{item.sport}</option>)
            }
        }
        return sport
    }

    public getRegions(): JSX.Element[] {
        const regions: JSX.Element[] = []
        // const Regions: any[] = []
        regions.push(<option key = "_" value = "">{Assets.AnyRegion}</option>)
        if (this.state.sport === '') {
            if (Main.Instance.OddsState.distinctRegion2) {
                for (const item of Main.Instance.OddsState.distinctRegion2) {
                    // Regions.push({sport: item.sport, region: item.region})
                    regions.push(<option key = {item.region} value = {item.region}>{item.region}</option>)
                }
            }
        } else {
            if (Main.Instance.OddsState.distinctRegion) {
                for (const item of Main.Instance.OddsState.distinctRegion) {
                    if (this.state.sport.length > 0 && this.state.sport !== item.sport) {continue}
                    // Regions.push({sport: item.sport, region: item.region})
                    regions.push(<option key = {item.sport + '_' + item.region} value = {item.region}>{item.region}</option>)
                }
            }
        }
        return regions
    }

    public getLeagues(): JSX.Element[] {
        const leagues: JSX.Element[] = []
        leagues.push(<option key = "_" value = "">{Assets.Any + ' ' + Assets.League.toLowerCase()}</option>)
        if (this.state.region === '') {
            if (Main.Instance.OddsState.distinctLeague2) {
                for (const item of Main.Instance.OddsState.distinctLeague2) {
                    leagues.push(<option key = {item.league} value = {item.league}>{item.league}</option>)
                }
            }    
        } else {
            if (Main.Instance.OddsState.distinctLeague) {
                for (const item of Main.Instance.OddsState.distinctLeague) {
                    if (this.state.sport.length > 0 && this.state.sport !== item.sport) {continue}
                    if (this.state.region.length > 0 && this.state.region !== item.region) {continue}
                    leagues.push(<option key = {item.sport + '_' + item.region + '_' + item.league} value = {item.league}>{item.league}</option>)
                }
            }
    
        }
        return leagues
    }

    public getFilter(): JSX.Element[] {

        const filter: JSX.Element[] = []
        filter.push(<select onChange = {(e) => this.setState({sport: e.target.value})} key = "-1" value = {this.state.sport} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getSport()}</select>)
        filter.push(<select onChange = {(e) => this.setState({region: e.target.value})} key = "-2" value = {this.state.region} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getRegions()}</select>)
        filter.push(<select onChange = {(e) => this.setState({league: e.target.value})} key = "-3" value = {this.state.league} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getLeagues()}</select>)

        filter.push (<input key = "0" onClick = {() => this.setState({filterMatches: undefined})} checked = {this.state.filterMatches === undefined} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "1" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.All.toLowerCase() + ' ' + Assets.Matches.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "2" onClick = {() => this.setState({filterMatches: true})} checked = {this.state.filterMatches === true} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "3" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.With.toLowerCase() + ' ' + Assets.Results.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "4" onClick = {() => this.setState({filterMatches: false})} checked = {this.state.filterMatches === false} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "5" className = "fl dbl" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px', marginRight: '20px'}}>
                {Assets.Without.toLowerCase() + ' ' + Assets.Results.toLowerCase()}
            </div>
        )

        filter.push (<input key = "8" onClick = {() => this.setState({filterDate: FilterDate.Yesterday})} checked = {this.state.filterDate === FilterDate.Yesterday} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "9" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.Yesterday.toLowerCase()}
            </div>
        )

        filter.push (<input key = "10" onClick = {() => this.setState({filterDate: FilterDate.Today})} checked = {this.state.filterDate === FilterDate.Today} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "11" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.Today.toLowerCase()}
            </div>
        )

        filter.push (<input key = "12" onClick = {() => this.setState({filterDate: FilterDate.Tomorrow})} checked = {this.state.filterDate === FilterDate.Tomorrow} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "13" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.Tomorrow.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "14" onClick = {() => this.setState({filterDate: FilterDate.AnyDate})} checked = {this.state.filterDate === FilterDate.AnyDate} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "15" className = "fl dbl" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.AnyDate}
            </div>
        )

        return filter
    }

    public getDateTime(datetime: string): string {
        // console.log ('datetime ', datetime)
        // const dt: string = datetime.replace(' ', 'T')
        // console.log (dt)
        const dateTime: string = (new Date(datetime)).toISOString()
        return dateTime.substring(0,10) + ' ' + dateTime.substring(11,16)

    }
    
    public render() {
        // console.log (Main.Instance.OddsState.matches)
        const filtered = []
        if (this.state.filterMatches === true) {
            filtered.push({id: 'result', value: Assets.YES})
        } else if (this.state.filterMatches === false) {
            filtered.push({id: 'result', value: Assets.NO})
        }
        let now: Date = new Date()
        if (this.state.filterDate === FilterDate.Today) {
            filtered.push({id: 'Date', value: now.toISOString().substring(0, 10)})
        } else if (this.state.filterDate === FilterDate.Tomorrow) {
            now = new Date(now.getTime() + 24 * 3600 * 1000)
            filtered.push({id: 'Date', value: now.toISOString().substring(0, 10)})
        } else if (this.state.filterDate === FilterDate.Yesterday) {
            now = new Date(now.getTime() - 24 * 3600 * 1000)
            filtered.push({id: 'Date', value: now.toISOString().substring(0, 10)})
        }
        // console.log (filtered)
        if (this.state.sport.length > 0) {
            filtered.push({id: 'sport', value: this.state.sport})
        }

        if (this.state.region.length > 0) {
            filtered.push({id: 'country', value: this.state.region})
        }

        if (this.state.league.length > 0) {
            filtered.push({id: 'tournament', value: this.state.league})
        }

        // console.log (Main.Instance.OddsState.distinctLeague)
        // console.log (Main.Instance.OddsState.distinctRegion)
        // console.log (Main.Instance.OddsState.distinctSport)
        // console.log (Main.Instance.OddsState.matches)
        return (
            <div>
                {this.state.selectedMatch === -1 || this.state.odds === undefined ? void 0 :
                <div>
                    <h3>{Assets.Odds + ' ' + Assets.Preview.toLowerCase()}</h3>
                    <div className = "odds">
                        <button
                            onClick = {() => this.setState({selectedMatch: -1, odds: undefined})}
                            className = "btn btn-default btn-xs"
                            type = "button"
                            style = {{position: 'absolute', top: '0px', right: '-5px', zIndex: 4, background: 'transparent', border: '0px', padding: '0px'}}
                        >
                            <FontAwesomeIcon icon = {faTimes}/>
                        </button>
                        
                        <div className = "odds-inside">
                            {this.getNavigationBet()}
                            <div style = {{fontSize: '14px', position: 'absolute', top: '-35px', right: '0px', zIndex: 3}}>
                                <strong>{this.state.selectedMatchCategories},</strong>
                                &nbsp;
                                <span>{this.state.selectedMatchDetails}</span>
                            </div>
                        </div>
                        <div>
                            {this.getOdds()}
                        </div>
                    </div>
                </div>}
                <h3 className = "pr">
                    <div className = "clearfix" style = {{position: 'absolute', top: '10px', right: '0px'}}>{this.getFilter()}</div>
                    {Assets.Matches + ' ' + Assets.Preview.toLowerCase() + '/' +Assets.Import + ' ' + Assets.Results}
                </h3>
                {Main.Instance.OddsState.matches ?
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.OddsState.matches}
                    columns = {[
                        {width: 50, Header: Assets.ID, accessor: 'id'},
                        {Header: Assets.Categories, headerClassName: 'risk-default', columns: [
                            {width: 200, Header: Assets.Sport, accessor: 'sport', headerClassName: 'th-left'},
                            {width: 200, Header: Assets.Region, accessor: 'country', headerClassName: 'th-left'},
                            {width: 250, Header: Assets.League, accessor: 'tournament', headerClassName: 'th-left'}
                        ]},
                        {Header: Assets.Match + ' ' + Assets.Details.toLowerCase(), headerClassName: 'risk-default-light', columns: [
                            {width: 250, Header: Assets.Home + ' ' + Assets.Team, accessor: 'home', headerClassName: 'th-left', Cell : (row) => row.original.results ? this.getTeam(true, row.original.results.name, row.value) : row.value},
                            {width: 250, Header: Assets.Away + ' ' + Assets.Team, accessor: 'away', headerClassName: 'th-left', Cell : (row) => row.original.results ? this.getTeam(false, row.original.results.name, row.value) : row.value},
                            {width: 125, Header: Assets.Date + '/' + Assets.Time + ' ' + Assets_.UTC, accessor: 'date', headerClassName: 'th-left', Cell: (row) => row.value ? this.getDateTime(row.value) : '-'},
                            {width: 110, Header: Assets.Status + '/' + Assets.Result, accessor: 'Date', headerClassName: 'th-left', Cell: (row) => this.getResult(row.original, true)},
                        ]},
                        {Header: Assets.Actions, headerClassName: 'risk-default', columns: [
                            {width: 120, Header: Assets.Select + Assets._a_ + Assets.Match.toLowerCase(), accessor: 'result', Cell: (row) => this.selectButton(row.original.id, row)},
                            {width: 75, Header: Assets.Import, accessor: '', headerClassName: 'th-left', Cell: (row) => this.getResult(row.original, false)},
                        ]}
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                /> : void 0}
            </div>
        ) 
    }
}