import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
// import {isUserSiteOwner} from '../../../Logic/Utils'
import {IsBuildJackpotsCH,/*, IsBuildOddyzzey, IsBuildBettoday*/IsBuildMyChance, IsBuildPasino} from '../../../Logic/Utils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function ReportsWrapper(props:React.Props<any>) {
    // console.log (RouterObjects['reports'])
    if(RouterObjects['reports'] && Main.Instance.Location === RouterObjects['reports'].path && Main.Instance.Back === false) {
        let path: string
        let Path: string
        // Logic.Type.New(Main.Instance, {Location:RouterObjects['reports'].path + RouterObjects['reports'].children['Report_Hidden'].path})
        // console.log (IsBuildJackpotsCH() || IsBuildPasino())
        // console.log (RouterObjects['reports'].path)
        if (IsBuildJackpotsCH() || IsBuildPasino()) {
            path = RouterObjects['reports'].path + RouterObjects['reports'].children['GGR_Revenue_Report'].path
            Path = RouterObjects['reports'].path.substring(1, RouterObjects['reports'].path.length) + ' -> ' + 
            RouterObjects['reports'].children['GGR_Revenue_Report'].path.substring (1, RouterObjects['reports'].children['GGR_Revenue_Report'].path.length)
        } else if (IsBuildMyChance()) {
            path = RouterObjects['reports'].path + RouterObjects['reports'].children['Users_Info'].path
            Path = RouterObjects['reports'].path.substring(1, RouterObjects['reports'].path.length) + ' -> ' + 
            RouterObjects['reports'].children['Users_Info'].path.substring(1, RouterObjects['reports'].children['Users_Info'].path.length)
        } else {
            path = RouterObjects['reports'].path + RouterObjects['reports'].children['Report_Levels__Sublevels'].path
            Path = RouterObjects['reports'].path.substring(1, RouterObjects['reports'].path.length) + ' -> ' + 
            RouterObjects['reports'].children['Report_Levels__Sublevels'].path.substring(1, RouterObjects['reports'].children['Report_Levels__Sublevels'].path.length)
        }
        
        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )
        Logic.Type.New(Main.Instance, {Location: path})
    }
   
    return(<div>{props.children}</div>)
}
