import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {FormSelect,FormInputNumber} from '../../Reusables/Inputs'
import {isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, numberWithCommas, combinations, getnumbers, getNumbers} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
export function getLottobetReportCount() {
    if (Main.Instance.LottoTransactionsState.countCalled === false) {
        Logic.Type.New(Main.Instance.LottoTransactionsState, {countCalled:true})
        // DbRoutes.GetBanner()
        // console.log ('HERE')
        DbRoutes.getLotto(true)
        // DbRoutes.getLottoTransactions()
        // DbRoutes.GetAllLanguages()
        DbRoutes.GetFlags()
    }
}

function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                site_id: stream[0]['id'],
                // shopItemDefaultFilename: Config.localServer + '/logo/' + stream[0]['id'] + '/shop.jpg'
        })
        if (Main.Instance.LottoTransactionsState.countCalled === false) {
            Logic.Type.New(Main.Instance.LottoTransactionsState, {countCalled:true})        
            DbRoutes.getLotto(true)
        }
        // DbRoutes.getLottoTransactions()
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.LottoTransactionsState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,
        // shopItemDefaultFilename: parseInt(text) === 0 ? '': Config.localServer + '/logo/' + text + '/shop.jpg'
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getLottobetReportCount()
    }
}

function getLottobetReport() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {lottoTransactionsStream : undefined})
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.LottoTransactionsState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.LottoTransactionsState, {offset:(0).toString()})

    getLottobetReport()
    Logic.Type.New(Main.Instance.LottoTransactionsState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.LottoTransactionsState.pNum > 0) {

        Logic.Type.New(Main.Instance.LottoTransactionsState, {
            offset:((Main.Instance.LottoTransactionsState.pNum - 1) * 
            Main.Instance.LottoTransactionsState.pSize).toString()})
        getLottobetReport()

        Logic.Type.New(Main.Instance.LottoTransactionsState,{pNum: Main.Instance.LottoTransactionsState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.LottoTransactionsState.pNum + 1) * Main.Instance.LottoTransactionsState.pSize < 
    Main.Instance.LottoTransactionsState.count) {

        Logic.Type.New(Main.Instance.LottoTransactionsState, {
            offset: ((Main.Instance.LottoTransactionsState.pNum + 1) * 
            Main.Instance.LottoTransactionsState.pSize).toString()})
        getLottobetReport()
        
        Logic.Type.New(Main.Instance.LottoTransactionsState,{pNum: Main.Instance.LottoTransactionsState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.LottoTransactionsState.count <= Main.Instance.LottoTransactionsState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.LottoTransactionsState.count === 0) {

            Logic.Type.New(Main.Instance.LottoTransactionsState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.LottoTransactionsState.count - 1) / 
                    Main.Instance.LottoTransactionsState.pSize)
            Logic.Type.New(Main.Instance.LottoTransactionsState, {
                offset:(npNum * Main.Instance.LottoTransactionsState.pSize).toString()})
        }

        getLottobetReport()
        Logic.Type.New(Main.Instance.LottoTransactionsState,{pNum: npNum})
    }
}

function SelectCombination(combo: number, comboid: number) {
    // console.log ('new combo ' + combo)
    // console.log ('new comboid ' + comboid)
    Logic.Type.New (Main.Instance.LottoTransactionsState, {combo, comboid})
    // DbRoutes.getResults(combo)
}

function SelectDraw(draw: number) {
    Logic.Type.New (Main.Instance.LottoTransactionsState, {draw})
    // DbRoutes.getResults(combo)
}

function getDraws(chosenItem): JSX.Element[] {
    const draws: JSX.Element[] = []
    // console.log (Main.Instance.LottoTransactionsState.ticket.length)
    // console.log (chosenItem.drawinfo)
    let drawsMissing: number = 0
    for (let i: number = 0; i <= chosenItem.draws; i++) {
        let drawid: number = 0
        let shortDate: string = ''
        let j: number = 0
        if (i > 0) {
            j = (i-1) * Main.Instance.LottoTransactionsState.ticket.length
            // console.log (i + ' ' + j)
            if (chosenItem.drawinfo[j]) {
                drawid = chosenItem.drawinfo[j].drawid
                shortDate = chosenItem['drawinfo'][j].drawdateutc.substring(8,10) + '-' + chosenItem['drawinfo'][j].drawdateutc.substring(5,7)
            } else { drawsMissing++ }
        }
        if (i === 0 || drawid > 0) {
        draws.push (
            <button
                style = {{display: 'block', float: 'left', marginBottom: '5px'}}
                key = {i.toString()}
                onClick = {() => SelectDraw(drawid)}
                className = {Main.Instance.LottoTransactionsState.draw === drawid ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
            >
                {i === 0 ? Assets.AnyDraw : shortDate}
            </button>,
        )
        }
    }
    if (drawsMissing > 0) {
    draws.push (
        <span
            style = {{fontSize: '14px', display: 'block', float: 'left', marginBottom: '5px'}}
            key = "-1"
            className = "label label-default label-xs"
        >
            {drawsMissing === 1 ? Assets.d16 : drawsMissing + ' ' + Assets.d17}
        </span>,
    )
    }
    return draws
}

function SelectSheet(sheet: number, c: number) {
    Logic.Type.New (Main.Instance.LottoTransactionsState, {sheet})

    for (let i: number = 0; i < Main.Instance.LottoTransactionsState.ticket.length; i++) {
        // console.log ('combinations ' + c)
        if (Main.Instance.LottoTransactionsState.sheet > 0 && Math.floor(i/c) !== (Main.Instance.LottoTransactionsState.sheet - 1)) { continue }    

        if ((Main.Instance.LottoTransactionsState.sheet === 0 && i === 0 || Main.Instance.LottoTransactionsState.sheet > 0 && i % c === 0 ) &&
           (Main.Instance.LottoTransactionsState.comboid !== i || Main.Instance.LottoTransactionsState.combo !== Main.Instance.LottoTransactionsState.ticket[i])) {
           Logic.Type.New (Main.Instance.LottoTransactionsState, {comboid: i, combo: Main.Instance.LottoTransactionsState.ticket[i]})
        }
    }
}

function getSheets(c: number): JSX.Element[] {
    const sheets: JSX.Element[] = []
    sheets.push(
        <button
            style = {{display: 'block', float: 'left', marginBottom: '5px'}}
            key = "0"
            onClick = {() => SelectSheet(0, c)}
            className = {Main.Instance.LottoTransactionsState.sheet === 0 ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
        >{Assets.AnySheet}
        </button>,
    )

    for (let i: number = 0; i < Main.Instance.LottoTransactionsState.custom.lotteryBet.length; i++) {
        sheets.push(
            <button
                style = {{display: 'block', float: 'left', marginBottom: '5px'}}
                key = {(i+1).toString()}
                onClick = {() => SelectSheet(i + 1, c)}
                className = {Main.Instance.LottoTransactionsState.sheet === i + 1 ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
            >
                {Assets.Sheet + ' #' + (i + 1).toString()}
            </button>,
        )
    }
    return sheets
}

function getCombinations(c: number): JSX.Element[] {
    // console.log (Main.Instance.LottoTransactionsState.ticket)
    const combination: JSX.Element[] = []
    for (let i: number = 0; i < Main.Instance.LottoTransactionsState.ticket.length; i++) {
        // console.log ('combinations ' + c)
        if (Main.Instance.LottoTransactionsState.sheet > 0 && Math.floor(i/c) !== (Main.Instance.LottoTransactionsState.sheet - 1)) { continue }
/*
        if ((Main.Instance.LottoTransactionsState.sheet === 0 && i === 0 || Main.Instance.LottoTransactionsState.sheet > 0 && i % c === 0 ) &&
           (Main.Instance.LottoTransactionsState.comboid !== i || Main.Instance.LottoTransactionsState.combo !== Main.Instance.LottoTransactionsState.ticket[i])) {
           Logic.Type.New (Main.Instance.LottoTransactionsState, {comboid: i, combo: Main.Instance.LottoTransactionsState.ticket[i]})
        }
*/
        combination.push (
            <button
                style = {{display: 'block', float: 'left', marginBottom: '5px'}}
                key = {i.toString()}
                onClick = {() => SelectCombination(Main.Instance.LottoTransactionsState.ticket[i], i)}
                className = {Main.Instance.LottoTransactionsState.combo === +Main.Instance.LottoTransactionsState.ticket[i] ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
            >
                {'#' + Main.Instance.LottoTransactionsState.ticket[i]}
            </button>,
        )
    }
    return combination
}

function getSelectedNumbers(): JSX.Element[] {
    const sn: JSX.Element[] = []
    for (let i:number = 0; i < Main.Instance.LottoTransactionsState.custom.lotteryBet.length; i++) {
        if (Main.Instance.LottoTransactionsState.sheet > 0 && Main.Instance.LottoTransactionsState.sheet !== i + 1) { continue }
        sn.push(
        <div key = {i.toString()} className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['regular'].length > 0 ?
            <div className = "fl label label-primary label-xs" style = {{fontSize: '14px', marginTop: '5px', marginRight: '5px'}}>
                {Assets.Sheet + ' #' + (i+1).toString() + ' - ' + Assets.Selectednumbers}
            </div> : void 0}

            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['regular'].length > 0 ?
            <div 
                className = "fl label label-primary label-xs"
                style = {{color: '#333', background: '#78f16b',fontSize: '14px',marginTop: '4px', marginRight:'5px', marginLeft:'5px', border:'1px solid #bbb', paddingTop:'4px', paddingBottom:'3px'}}
            >
                {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['regular'].length === 1 ? Assets.number : Assets.numbers}
            </div>: void 0}

            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['regular'].length > 0 ?
            getNumbers(Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['regular'], 0) : void 0}

            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['bonus'].length > 0 ?
            <div
                className = "fl label label-primary label-xs"
                style = {{color: '#333', background: '#f1f641', fontSize: '14px',marginTop: '4px', marginRight:'5px', marginLeft:'5px', border:'1px solid #bbb', paddingTop:'4px', paddingBottom:'3px'}}
            >
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['bonus'].length === 1 ? Assets.bonusnumber : Assets.bonusnumbers}
            </div> : void 0}
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['bonus'].length > 0 ?
            getNumbers(Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['bonus'], 2) : void 0}
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['refund'].length > 0 ?
            <div
                className = "fl label label-primary label-xs"
                style = {{color: '#333', background: '#f08385', fontSize: '14px',marginTop: '4px', marginRight:'5px', marginLeft:'5px', border:'1px solid #bbb', paddingTop:'4px', paddingBottom:'3px'}}
            >
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['refund'].length === 1 ? Assets.refundnumber : Assets.refundnumbers}
            </div> : void 0}
            {Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['refund'].length > 0 ?
            getNumbers(Main.Instance.LottoTransactionsState.custom.lotteryBet[i]['refund'], 3) : void 0}
        </div>)
    }

    return sn
}

function getResultInformation(chosenItem: any): JSX.Element[] {
    const ri: JSX.Element[] = []
    for (let i: number = 0; i < Main.Instance.LottoTransactionsState.custom['drawings']; i++) {
    const j: number = i * Main.Instance.LottoTransactionsState.ticket.length
    if (!(chosenItem['drawinfo'] && chosenItem['drawinfo'][j])) { continue }
    if (Main.Instance.LottoTransactionsState.draw !== 0 && chosenItem['drawinfo'][j].drawid !== Main.Instance.LottoTransactionsState.draw) { continue }

    let date: string
    let day: number

    if (chosenItem['drawinfo'][j].drawdateutc) {
        date = chosenItem['drawinfo'][j].drawdateutc.substring(8,10) + '-' +
        chosenItem['drawinfo'][j].drawdateutc.substring(5,7) + '-' +
        chosenItem['drawinfo'][j].drawdateutc.substring(0,4) 
        const newdate = new Date( chosenItem['drawinfo'][j].drawdateutc.substring(0,4),
                                  chosenItem['drawinfo'][j].drawdateutc.substring(5,7) - 1,
                                  chosenItem['drawinfo'][j].drawdateutc.substring(8,10))
        day = newdate.getDay()
    }

    ri.push(
    <div key = {i.toString()} className = "clearfix">
        <div className = "fl label label-warning label-xs" style = {{fontSize:'14px', marginRight: '10px', marginTop: '5px'}}>
        {Assets.ResultsForDraw + ' ' + chosenItem['drawinfo'][j].drawdateutc.substring(8,10) + '-' + chosenItem['drawinfo'][j].drawdateutc.substring(5,7)}
        </div>
        {day !== undefined && date ?
        <div className = "fl label label-warning label-xs" style = {{fontSize:'14px', marginTop: '5px', marginRight: '5px'}}>
        {Assets.DaysInWeek[day] + ', ' + date}
        </div> : void 0}
        <div className = "fl label label-warning label-xs" style = {{fontSize:'14px', marginTop: '5px', marginRight: '5px'}}>
        {chosenItem['drawinfo'][j].drawdateutc.substring(11,16) + ' ' + Assets_.UTC}
        </div>

        <div className = "fl label label-warning label-xs" style = {{fontSize:'14px', marginTop: '5px', marginRight: '5px'}}>
        {Assets.Jackpot + ': ' + numberWithCommas(chosenItem['drawinfo'][j].jackpotsize) + ' ' + chosenItem['drawinfo'][j].jackpotcurrency}
        </div>


        <div className = "fl label label-warning label-xs" style = {{fontSize:'14px', marginTop: '5px', marginRight: '5px'}}>{Assets.Numbers}</div>

        {chosenItem['drawinfo'][j] && chosenItem['drawinfo'][j].numbers ?
        getNumbers(chosenItem['drawinfo'][j].numbers, 0)
        : void 0}
        {chosenItem['drawinfo'][j] && chosenItem['drawinfo'][j].extranumbers ?
        getNumbers(chosenItem['drawinfo'][j].extranumbers, 1)
        : void 0}
        {chosenItem['drawinfo'][j] && chosenItem['drawinfo'][j].bonusnumbers ?
        getNumbers(chosenItem['drawinfo'][j].bonusnumbers, 2)
        : void 0}
        {chosenItem['drawinfo'][j] && chosenItem['drawinfo'][j].refundnumbers ?
        getNumbers(chosenItem['drawinfo'][j].refundnumbers, 3)
        : void 0}
    </div>)
    }
    return ri
}

function getCombination(chosenItem): JSX.Element[] {
    const combination: JSX.Element[] = []

    for (let i: number = 0; i < Main.Instance.LottoTransactionsState.custom['drawings']; i++) {
    const j: number = i * Main.Instance.LottoTransactionsState.ticket.length
    if (!(chosenItem['drawinfo'] && chosenItem['drawinfo'][j])) { continue }
    if (Main.Instance.LottoTransactionsState.draw !== 0 && chosenItem['drawinfo'][j].drawid !== Main.Instance.LottoTransactionsState.draw) { continue }
    combination.push (

    <div key = {i.toString()} className = "clearfix" style = {{marginTop: '5px'}}>
    
    <div
        className = {+chosenItem.externalid[j].payout === 0 ? 'fl label label-danger label-xs' : 'fl label label-success label-xs'}
        style = {{fontSize:'14px', marginTop: '5px', marginRight: '5px'}}
    >
        {Main.Instance.LottoTransactionsState.ticket.length > 1 ? '#' + Main.Instance.LottoTransactionsState.combo :
        Assets.Draw + ' ' + chosenItem['drawinfo'][j].drawdateutc.substring(8,10) + '-' + chosenItem['drawinfo'][j].drawdateutc.substring(5,7)}
    </div>
    {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 0), 0, 
                chosenItem['drawinfo'][j].numbers,
                chosenItem['drawinfo'][j].extranumbers)}
    {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 2), 2,
                chosenItem['drawinfo'][j].bonusnumbers)}
    {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 3), 3,
                chosenItem['drawinfo'][j].refundnumbers)}
        <div 
            style = {{background: /*'#CC231E'*/'#000', fontSize: '14px', color: '#fff', border: '1px solid #bbb', paddingTop: '5px', paddingBottom: '2px'}}
            className = "fl label label-primary label-xs ml5px bold mt5"
        >HIT
        </div>

        <div className = "fl clearfix label label-primary label-xs mt5 ml5px" style={{background:'#78f16b',fontSize:'14px',color:'#333',border:'1px solid #bbb',paddingTop:'4px', paddingBottom:'3px'}}>
        <div className = "fl">{chosenItem.externalid[j].correctnumbers}</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].correctnumbers !== 1 ? Assets.numbers : Assets.number}</div>
        </div>

        <div className = "fl clearfix label label-primary label-xs mt5 ml5px" style={{background:'#88deed',fontSize:'14px',color:'#333',border:'1px solid #bbb',paddingTop:'4px', paddingBottom:'3px'}}>
        <div className = "fl">{chosenItem.externalid[j].correctextranumbers}</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].correctextranumbers !== 1 ? Assets.extranumbers : Assets.extranumber}</div>
        </div>
        <div className = "fl clearfix label label-primary label-xs mt5 ml5px" style={{background:'#f1f641',fontSize:'14px',color:'#333',border:'1px solid #bbb',paddingTop:'4px', paddingBottom:'3px'}}>
        <div className = "fl">{chosenItem.externalid[j].correctbonusnumbers}</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].correctbonusnumbers !== 1 ? Assets.bonusnumbers : Assets.bonusnumber}</div>
        </div>
        <div className = "fl clearfix label label-primary label-xs mt5 ml5px" style={{background:'#f08385',fontSize:'14px',color:'#333',border:'1px solid #bbb',paddingTop:'5px', paddingBottom:'3px'}}>
        <div className = "fl">{chosenItem.externalid[j].correctrefundnumbers}</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].correctrefundnumbers !== 1 ? Assets.refundnumbers : Assets.refundnumber}</div>
        </div>
        <div 
            className = {+chosenItem.externalid[j].payout === 0 ?
                        'fl clearfix label label-danger label-xs mt5 ml5px' : 'fl clearfix label label-success label-xs mt5 ml5px'}
            style = {{border: '1px solid #bbb', fontSize: '14px'}}
        >
        <div className = "fl bold">{Assets.Payout}:</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].payout}</div>
        <div className = "fl ml5px">{chosenItem.externalid[j].payoutcurrency}</div>
        </div>
        <div 
            className = {+chosenItem.externalid[j].payout === 0 ?
                'fl clearfix label label-danger label-xs mt5 ml5px' : 'fl clearfix label label-success label-xs mt5 ml5px'}
            style = {{border: '1px solid #bbb', fontSize: '14px'}}
        >                            
        {chosenItem.externalid[j].payoutcurrency !== chosenItem.externalid[j].usercurrency ?
        <div className = "fl">{parseFloat(chosenItem.externalid[j].payoutusercurrency).toFixed(2)}</div> : void 0}
        {chosenItem.externalid[j].payoutcurrency !== chosenItem.externalid[j].usercurrency ?
        <div className = "fl ml5px">{chosenItem.externalid[j].usercurrency}</div> : void 0}
        </div>
    </div>,
    )
    }
    return combination
}

export function LottobetReport() {
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
    let combination: number = 1
    let lotteryId: number = -1
    if (Main.Instance.LottoTransactionsState.id) {
        // console.log (Main.Instance.LottoTransactionsState.custom)
        // console.log (Main.Instance.DatabaseDataState.lottoStream)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.lottoStream.length; i++) {
            if (Main.Instance.DatabaseDataState.lottoStream[i]['identification'] === Main.Instance.LottoTransactionsState.custom.gametypeid) {
                lotteryId = i
                break
            }
        }

        if (Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['regular'].length > 0 && Main.Instance.LottoTransactionsState.custom.lotteryBets['regularNumbers'] > 0) {
            combination *= combinations(Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['regular'].length, Main.Instance.LottoTransactionsState.custom.lotteryBets['regularNumbers'])
        }

        if (Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['bonus'].length > 0 && Main.Instance.LottoTransactionsState.custom.lotteryBets['bonusNumbers'] > 0) {
            combination *= combinations(Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['bonus'].length, Main.Instance.LottoTransactionsState.custom.lotteryBets['bonusNumbers'])
        }

        if (Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['refund'].length > 0 && Main.Instance.LottoTransactionsState.custom.lotteryBets['refundNumbers'] > 0) {
            combination *= combinations(Main.Instance.LottoTransactionsState.custom.lotteryBet[0]['refund'].length, Main.Instance.LottoTransactionsState.custom.lotteryBets['refundNumbers'])
        }
    }

    let result: boolean
    let pending: boolean = true
    // let day: number
    // let date: string
    let chosenItem: any
    if (Main.Instance.LottoTransactionsState.id && Main.Instance.DatabaseDataState.lottoTransactionsStream) {
        // console.log (Main.Instance.LottoTransactionsState.id)
        for (const item of Main.Instance.DatabaseDataState.lottoTransactionsStream) {
            // console.log (item['id'])
            if (parseInt(item['id']) === Main.Instance.LottoTransactionsState.id) {
                // console.log ('found')
                // console.log (item['id'])
                chosenItem = Object.assign({}, item)
                if (chosenItem.result === Assets.Win) {
                    result = true
                    pending = false
                } else if(chosenItem.result === Assets.Loss) {
                    result = false
                    pending = false
                } else if (chosenItem.result === Assets.WinPending) {
                    result = true
                } else if(chosenItem.result === Assets.LossPending) {
                    result = false
                }
/*
                if (chosenItem['drawinfo'] && chosenItem['drawinfo'][0] && 
                chosenItem['drawinfo'][0].drawdateutc) {
                    date = chosenItem['drawinfo'][0].drawdateutc.substring(8,10) + '-' +
                    chosenItem['drawinfo'][0].drawdateutc.substring(5,7) + '-' +
                    chosenItem['drawinfo'][0].drawdateutc.substring(0,4) 

                    let newdate = new Date( chosenItem['drawinfo'][0].drawdateutc.substring(0,4),
                                            chosenItem['drawinfo'][0].drawdateutc.substring(5,7) - 1,
                                            chosenItem['drawinfo'][0].drawdateutc.substring(8,10))
                    day = newdate.getDay()

                }
*/
                break
            }
        }
    }
/*
    if (Main.Instance.LottoTransactionsState.id) {
        if (Main.Instance.LottoTransactionsState.Result && Main.Instance.LottoTransactionsState.Result.length > 1) {
            for (const item of Main.Instance.LottoTransactionsState.Result) {
                if (item.payout > 0) {
                    result = true
                    break
                } else if (item.payout !== null) {
                    // console.log (item)
                    result = false
                }
            }

        } else if (Main.Instance.LottoTransactionsState.results && Main.Instance.LottoTransactionsState.results[0]) {
            if (Main.Instance.LottoTransactionsState.results[0].payout > 0) {
                result = true
            } else if (Main.Instance.LottoTransactionsState.results[0].payout !== null) {
                // console.log (Main.Instance.LottoTransactionsState.results[0])
                result = false
            }
        }
    }
    let day: number, date: string
    if (Main.Instance.LottoTransactionsState.result && Main.Instance.LottoTransactionsState.result.drawdatelocal) {
        date =  Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(8,10) + '-' + 
                Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(5,7) + '-' + 
                Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(0,4) 
        let newdate = new Date( Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(0,4),
                                Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(5,7) - 1,
                                Main.Instance.LottoTransactionsState.result.drawdatelocal.substring(8,10))
        day = newdate.getDay()
    }
*/
    return(
    <div>
    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.LottoBetReport}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}

        </div>
        {Main.Instance.LottoTransactionsState.id ?
        <div className = "row admin-users-container clearfix" style = {{position: 'relative'}}>
            <br/>
            <div 
                className = {
                    Main.Instance.LottoState.mode ? 
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' :
                    pending.toString() === 'true' ?
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-pending mb20' :
                    result.toString() === 'true' ?
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-win mb20' :
                    result.toString() === 'false' ?
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel-loss mb20' :
                    'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
                }
            >   
                {pending.toString() === 'true' ?
                <span className = "label label-warning label-sm" style = {{position: 'absolute', top: '60px', right: '8px', fontSize: '16px', zIndex: 1000}}>
                {result === undefined ? Assets.PENDING : result.toString() === 'true' ? Assets.PENDINGWIN : result.toString() === 'false' ? Assets.PENDINGLOSS : Assets.UNKNOWN}
                </span> :
                result.toString() === 'true' ?
                <span className = "label label-success label-sm" style = {{position: 'absolute', top: '60px', right: '8px',fontSize: '16px', zIndex: 1000}}>{Assets.WIN}</span> :
                result.toString() === 'false' ?  
                <span className = "label label-danger label-sm" style = {{position: 'absolute', top: '60px', right: '8px',fontSize: '16px', zIndex: 1000}}>{Assets.LOSS}</span>
                : void 0}                 
                <div className = "create-input">
                    <div className = "row admin-users-row clearfix" style = {{position: 'relative'}}>
                    <button 
                        onClick = {() => Logic.Type.New (Main.Instance.LottoTransactionsState, {id: 0, combo: 0, comboid: 0})}
                        style = {{border: '0px', background: 'transparent', color: '#333', position: 'absolute', top: '-20px', right: '-28px'}}
                    >
                        <i className = {Assets_.faCancelButtonClass}/>
                    </button>
                    {lotteryId !== -1 ?
                    <img src = {Main.Instance.DatabaseDataState.lottoStream[lotteryId]['image']} height = "50" style = {{position: 'absolute', top: '-8px', right: '-6px'}}/>
                    : void 0}
                    <div>
                        <div className = "fl label label-primary label-sm" style = {{fontSize:'18px'}}>{Assets.TicketInformation}</div>

                        {Main.Instance.LottoTransactionsState.custom['drawings'] === 1 ?
                        <div className = "fl label label-info label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>{Assets.oneDraw}</div>
                        :
                        <div className = "fl label label-info label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>
                        {Main.Instance.LottoTransactionsState.custom['drawings'] + ' draws'}</div>}

                        {Main.Instance.LottoTransactionsState.custom.lotteryBet.length === 1 ?
                        <div className = "fl label label-info label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>{Assets.oneSheet}</div>
                        :
                        <div className = "fl label label-info label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>
                        {Main.Instance.LottoTransactionsState.custom.lotteryBet.length + ' sheets'}</div>}

                        {combination === 1 ?
                        <div className = "fl label label-info label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>{Assets.RegularTicket}</div> :
                        <div className = "fl label label-danger label-sm" style = {{fontSize:'14px', marginTop: '3px', marginLeft: '10px'}}>
                            {Assets.SystemTicket + ': ' + combination + ' ' + Assets.combinations}
                        </div>}
                        
                    </div>
                    </div>
                    {Main.Instance.LottoTransactionsState.custom.lotteryBet.length > 1 ?
                    <div className = "clearfix" style = {{marginTop: '5px'}}>
                        {Main.Instance.LottoTransactionsState.custom.lotteryBet.length > 1 ?
                        <div className = "fl label label-primary label-xs" style = {{fontSize:'14px', marginTop: '0px', marginRight: '5px'}}>{Assets.SelectSheet}</div> : void 0}
                        {getSheets(combination)}
                    </div> : void 0}

                    {Main.Instance.LottoTransactionsState.custom ? getSelectedNumbers() : void 0}

                    {chosenItem && chosenItem['drawinfo'] && chosenItem['drawinfo'][0] && chosenItem.draws > 1 ?
                    <div className = "clearfix" style = {{marginTop: '5px'}}>
                        <div className = "fl label label-primary label-xs" style = {{fontSize:'14px', marginTop: '0px', marginRight: '5px'}}>{Assets.SelectDraw}</div>
                        {getDraws(chosenItem)}
                    </div> : void 0}

                    {chosenItem && chosenItem['drawinfo'] && chosenItem['drawinfo'][0] && chosenItem.externalid[Main.Instance.LottoTransactionsState.comboid] ?
                    getResultInformation(chosenItem) : void 0}

                    {Main.Instance.LottoTransactionsState.ticket ?
                    <div className = "clearfix" style = {{marginTop: '5px'}}>
                        {Main.Instance.LottoTransactionsState.ticket.length > 1 ?
                        <div className = "fl label label-primary label-xs" style = {{fontSize:'14px', marginTop: '0px', marginRight: '5px'}}>{Assets.SelectCombination}</div> : void 0}
                        {Main.Instance.LottoTransactionsState.ticket.length > 1 ? getCombinations(combination) : void 0}
                    </div> : void 0}
                    
                    {chosenItem && (!chosenItem['drawinfo'] || chosenItem['drawinfo'] && !chosenItem['drawinfo'][0])
                    && chosenItem['betinfo'] && chosenItem['betinfo'].length > 1 ?
                    <div className = "clearfix" style = {{marginTop: '5px'}}>
                        {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 0), 0)}
                        {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 2), 2)}
                        {getNumbers(getnumbers(chosenItem['betinfo'][Main.Instance.LottoTransactionsState.comboid].numbers, 3), 3)}

                    </div>
                    : chosenItem && chosenItem['drawinfo'] && chosenItem['drawinfo'][0] && chosenItem['betinfo'] ?
                    getCombination(chosenItem)
                    : void 0}
                </div>
            </div>
        </div>
        : void 0}
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.LottoTransactionsState.pSize ? '' : 
                Main.Instance.LottoTransactionsState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            {Main.Instance.LottoTransactionsState.playerid ? 
            <span className = "ml label label-info label-sm">{Assets.playerFilterOn}</span> : 
            <span className = "ml label label-danger label-sm">{Assets.playerFilterOff}</span>}
            {Main.Instance.LottoTransactionsState.playerid ?
            <button 
                onClick = {() => {Logic.Type.New(Main.Instance.LottoTransactionsState, {playerid: 0}); getLottobetReport()}}
                className = "btn btn-default btn-xs ml"
            >
                {Assets.disable}
            </button> : void 0}

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.LottoTransactionsState.count ? Main.Instance.LottoTransactionsState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.LottoTransactionsState.count ? Main.Instance.LottoTransactionsState.pNum * 
                Main.Instance.LottoTransactionsState.pSize + 1 : 0}{' - '}
                {Main.Instance.LottoTransactionsState.count ? 
                ((Main.Instance.LottoTransactionsState.pNum + 1) * 
                Main.Instance.LottoTransactionsState.pSize > Main.Instance.LottoTransactionsState.count ? 
                Main.Instance.LottoTransactionsState.count : 
                (Main.Instance.LottoTransactionsState.pNum + 1) * Main.Instance.LottoTransactionsState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.LottoTransactionsState.count ? 
                Main.Instance.LottoTransactionsState.pNum + 1 :0}{' / '}
                {Main.Instance.LottoTransactionsState.count ? 
                Math.trunc((Main.Instance.LottoTransactionsState.count - 1) /
                Main.Instance.LottoTransactionsState.pSize + 1) : 0}
            </span>
        </div>
        <h3>List of LottoBet Tickets</h3>
        {console.log(Main.Instance.DatabaseDataState.lottoTransactionsStream)}
        {Main.Instance.DatabaseDataState.lottoTransactionsStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.lottoTransactionsStream}
            columns = {[
                {minWidth: 40, Header: '', accessor: '', Cell: (row) => TableUtils.SelectButton(row, handleSelect)},
                {maxWidth: 30, Header: 'Id', accessor: 'id', headerClassName: 'th-left'},
                {Header: Assets.Date + ' ' + Assets_.UTC, accessor: 'datelt', headerClassName: 'th-left'},
                {Header: Assets.Time + ' ' + Assets_.UTC, accessor: 'timelt', headerClassName: 'th-left'},
                {minWidth: 50, Header: Assets.PlayerIDAbbrev, accessor: 'cid', Cell: (row) => TableUtils.PlayerSelectButton(row.value, Main.Instance.LottoTransactionsState.playerid, handlePlayer)},
                {Header: Assets.Country, accessor: 'c2', Cell: (row) => TableUtils.FlagByCountryCode(row.value)},
                {Header: Assets.LotteryName, accessor: 'lottery', headerClassName: 'th-left'},
                {Header: Assets.Price, accessor: 'pricelt', headerClassName: 'th-left'},
                {maxWidth: 30, Header: 'D', accessor: 'draws', headerClassName: 'th-left'},
                {maxWidth: 30, Header: 'S', accessor: 's', headerClassName: 'th-left'},
                {maxWidth: 30, Header: 'C', accessor: 'combos', headerClassName: 'th-left'},
                {Header: Assets.QuantityAbbrev + '.', accessor: 'quantitylt', headerClassName: 'th-left'},
                {Header: Assets.Value, accessor: 'valuelt', headerClassName: 'th-left'},
                {Header: Assets.Regular, accessor: 'r', Cell: (row) => <span>{row.value + ' / ' + row.original.rp}</span>, headerClassName: 'th-left'},
                {Header: Assets.Bonus, accessor: 'b', Cell: (row) => <span>{row.value + ' / ' + row.original.bp}</span>, headerClassName: 'th-left'},
                {Header: Assets.Refund, accessor: 'f', Cell: (row) => <span>{row.value + ' / ' + row.original.fp}</span>, headerClassName: 'th-left'},
                {Header: Assets.Result, accessor: 'result', Cell: (row) => TableUtils.LossWinPending(row.value), headerClassName: 'th-left'},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />
        : Main.Instance.FrontendStoreState.site_id ? getLottobetReportCount() : void 0}

        {/*Main.Instance.DatabaseDataState.lottoTransactionsStream ?
        StremingTable(Main.Instance.DatabaseDataState.lottoTransactionsStream, {
            show_id: true,
            show_id_combos: true,
            title: 'List of LottoBet Tickets',
            editingId: Main.Instance.LottoTransactionsState.id.toString(),
            selectButton:(data) => handleSelect(data),
            playerClick: PlayerClick,
            playerid: Main.Instance.LottoTransactionsState.playerid,
            mode: Main.Instance.LottoTransactionsState.id !== 0,
            sort: getLottobetReportCount,
            Sort: Main.Instance.LottoTransactionsState,
        })
        : Main.Instance.FrontendStoreState.site_id ? void 0 : void 0*/}
    </div>)
}

function handlePlayer(player: string) {
    if (Main.Instance.LottoTransactionsState.playerid) { Logic.Type.New (Main.Instance.LottoTransactionsState, {playerid:0, id:0, ticket:[], custom: {}, combo:0, comboid: 0, draw: 0, sheet: 0}) } else { Logic.Type.New (Main.Instance.LottoTransactionsState, {playerid: +player, id: 0, ticket: [], custom: {}, combo: 0, comboid: 0, draw: 0, sheet: 0}) }
    getLottobetReport()
}

function handleSelect(row: any) {
    for (const item of Main.Instance.DatabaseDataState.lottoTransactionsStream) {
        if (+item['id'] === +row.original.id) {
            // alert ('in there')        
            const ticket: number[] = []
            for (const Item of item['ticket']) { ticket.push (parseInt(Item)) }
            Logic.Type.New (Main.Instance.LottoTransactionsState, {id: +item['id'], custom: item['customlt'], ticket, combo: ticket[0], comboid: 0, draw: 0, sheet: 0})
            break
        }
    }
}
/*
function PlayerClick(player: string) {
    if (Main.Instance.LottoTransactionsState.playerid) Logic.Type.New (Main.Instance.LottoTransactionsState, {playerid:0, id:0, ticket:[], custom: {}, combo:0, comboid: 0, draw: 0, sheet: 0})
    else Logic.Type.New (Main.Instance.LottoTransactionsState, {playerid: +player, id: 0, ticket: [], custom: {}, combo: 0, comboid: 0, draw: 0, sheet: 0})
    getLottobetReport()
}
*/