import * as React from 'react'
import { Main } from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileCsv, faFilePdf as FaFilePdf, faMars, faVenus, faChartBar, faChartPie} from '@fortawesome/free-solid-svg-icons'
import {faFilePdf} from '@fortawesome/free-regular-svg-icons'
import {numberWithSpaces, getHeader, getFooter, getPageNumbers, isLocalhost, isUserReports} from '../../../Logic/Utils'
import './playersregistrations.css'
import * as jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
// import * as Config from '../../../Logic/Config'
import {
  PieChart,
  Pie,
  Cell,
  Bar,
  BarChart,
  Legend,
  XAxis,
  YAxis,
  // LineChart,
  // Line,
  Tooltip,
  CartesianGrid,
  // Area,
  // AreaChart,
  // ReferenceDot,
  Label
  // LabelList
} from 'recharts'

enum WhichChart {General, Gender, Nationalities, AgeGroups, Regions}
enum WhatChartGeneral {NoCharts, Players}
enum WhatChartGender {Male, Female}
enum WhichPieChart {NoCharts, Gender, Nationalities, AgeGroups, Regions}

interface ChartInterface {
    date: string
    players: number
}

const faPercentage: string = 'fa fa-percent'
// const faMars: string = 'fa fa-mars'
// const faVenus: string = 'fa fa-venus'

interface Region {
    code: string
    name: string
}

interface PlayerListed {
    date: string 
    players: number
    playersbyGender: {
        male: number
        female: number
    }
    playersbyNationality: number[]
    playersbyAge: number[]
    playersbyRegion: number[]
}

interface Nationalities {
    code: string
    name: string
    image: string
}

interface State {
    nationalities?: Nationalities[]
    regions?: Region[]
    shownRegions?: Region[]
    playerListed?: PlayerListed[]
    showPercentages: boolean
    showNationality?: Nationalities[]
    // filters
    selectedRegionCode: string
    selectedNationality: string
    selectedGender: string
    selectedAgeGroup: string
    selectedFromDate: moment.Moment
    selectedToDate: moment.Moment
    focusedFromDate: boolean
    focusedToDate: boolean
    which: number
    what: number
    whichDate: string
    // WhichDate: string
    whichPieChart: number
    mainNationality: string
    mainNationalityCode: string
    mainCountry: string
    mainRegions: string[]
    secondaryNationalities: string[]
}

export class PlayersRegistrations extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            mainCountry: 'CH',
            mainRegions: ['AG','BE','LU','SG','SO','VD','ZH'], // Aargau, Bern, Sankt Gallen, Solothurn, Vaud, Zurich
            mainNationalityCode: 'CHE',
            mainNationality: 'Swiss',
            secondaryNationalities: ['Austrian','French','German','Italian','Portuguese','Turkish'],
            showPercentages: false,
            selectedRegionCode: Assets.All,
            selectedNationality: Assets.All,
            selectedGender: Assets.All,
            selectedAgeGroup: Assets.All,
            selectedFromDate: null,
            selectedToDate: null,
            focusedFromDate: false,
            focusedToDate: false,
            which: WhichChart.General,
            what: WhatChartGeneral.NoCharts,
            whichDate: Assets.Any,
            // WhichDate: Assets.Any,
            whichPieChart: WhichChart.General
        }
        DbRoutes.wsReinitialize()
    }

    public numberWithSpaces(x: number) : JSX.Element {
        return (<span className = {x ? '' : 'color-text-light'}>{numberWithSpaces(x)}</span>)
    }

    public colorTextLight(x: string) : JSX.Element {
        return (<span className = {x !== '0.00' ? '' : 'color-text-light'}>{x}</span>)
    }

    public ColorTextLight(x: string) : JSX.Element {
        return (<span>{x}</span>)
    }

    public initializeRegions(country: string, mainRegions?: string[]) {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (item.data.code === country && item.data.regions) {
                const regions: Region[] = []
                for (const Item of item.data.regions) {
                    regions.push({code: Item.code, name: Item.name})
                }
                if (mainRegions) {
                    this.UpdateRegions(mainRegions, regions.sort(this.byName))
                    // this.setState({mainRegions, regions: regions.sort(this.byName)})
                } else {
                    this.setState({regions: regions.sort(this.byName)})
                }
                // console.log (regions)
                break
            }
        }        
    }
/*
    public selectByDay(date: string) {
        const playerListed: PlayerListed[] = []
        for (const item of Main.Instance.DatabaseDataState.playersInfo) {
            
                               
            if ((item.registered as string).substring(0,10) === date ) {
                console.log('aa')
                
                playerListed.push({gender: item.gender, age: item.age, canton: item.canton, accountStatus: item.accountStatus})
                
                break

            }
            
            
        }
    }
*/  // state.nationalities
    public initializePlayerTable(selectedGender: string, selectedNationality: string, selectedAgeGroup: string, selectedRegionCode: string, selectedFromDate: moment.Moment, selectedToDate: moment.Moment, shownRegions?, mainRegions?, regions?, nationalities?, secondaryNationalities?) {
        // console.log (Main.Instance.DatabaseDataState.playersInfo)
        const fromDate: string = selectedFromDate ? selectedFromDate.format(Assets_.dateDisplayFormat) : undefined
        const toDate: string = selectedToDate ? selectedToDate.format(Assets_.dateDisplayFormat) : undefined
        // console.log (fromDate)
        
        const playerListed: PlayerListed[] = []
        for (const item of Main.Instance.DatabaseDataState.playersInfo) {
            if (fromDate && fromDate > (item.registered as string).substring(0, 10)) {continue}
            if (toDate && toDate < (item.registered as string).substring(0, 10)) {continue}

            if (selectedGender && selectedGender !== Assets.All && selectedGender.charAt(0) !== item.gender) {continue}
            if (selectedNationality && selectedNationality !== Assets.All && selectedNationality !== item.nationality) {continue}
            if (selectedAgeGroup && selectedAgeGroup !== Assets.All) {
                let low: number
                let high: number
                for (const Item of Assets_.ageGroup) {
                    if (Item.text === selectedAgeGroup) {
                        low = Item.low
                        high = Item.high
                        break
                    }
                }
                if (low !== undefined && high !== undefined && (item.age < low || item.age > high)) {
                    continue
                } else if (low === undefined && high !== undefined && item.age > high) {
                    continue
                } else if (high === undefined && low !== undefined && item.age < low) {
                    continue
                }
            }
            if (selectedRegionCode && selectedRegionCode !== Assets.All && selectedRegionCode !== item.canton) {continue}

            let foundInPlayerListed: number = -1
          
            for (let i: number = 0; i < playerListed.length; i++) {
                if (playerListed[i].date === (item.registered as string).substring(0, 10)) {
                    foundInPlayerListed = i
                    break
                }
            }
            if (foundInPlayerListed === -1) {
                const playersbyNationality: number[] = []
                const playersbyAge: number[] = []
                const playersbyRegion: number[] = []

                for (const {} of (nationalities ? nationalities :this.state.nationalities)) {
                    playersbyNationality.push(0)
                }
                playersbyNationality.push(0)

                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    playersbyAge.push(0)
                }

                for (const {} of (shownRegions ? shownRegions : this.state.shownRegions)) {
                    playersbyRegion.push(0)
                }
                playersbyRegion.push(0)

                // console.log (playersbyNationality)
                // console.log (playersbyAge)
                // console.log (playersbyRegion)

                playerListed.push({
                    date: (item.registered as string).substring(0, 10),
                    players: 0,
                    playersbyGender: {male: 0, female: 0},
                    playersbyNationality,
                    playersbyAge,
                    playersbyRegion
                })
                foundInPlayerListed = playerListed.length - 1
            }
            
            playerListed[foundInPlayerListed].players++

            if (item.gender === 'm') {
                playerListed[foundInPlayerListed].playersbyGender.male ++
            } else if (item.gender === 'f') {
                playerListed[foundInPlayerListed].playersbyGender.female ++
            }

            let foundNation: boolean = false
            for (let i: number = 0; i < (nationalities ? nationalities.length : this.state.nationalities.length); i++) {
                if ((nationalities ? nationalities[i].code : this.state.nationalities[i].code) === item.nationality) {
                    playerListed[foundInPlayerListed].playersbyNationality[i]++
                    foundNation = true
                    break
                }
            }
            if (!foundNation) {
                playerListed[foundInPlayerListed].playersbyNationality[nationalities ? nationalities.length : this.state.nationalities.length]++
            }


            for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                if (Assets_.ageGroup[i].low && Assets_.ageGroup[i].high && Assets_.ageGroup[i].low <= item.age && item.age <= Assets_.ageGroup[i].high
                    || Assets_.ageGroup[i].high && !Assets_.ageGroup[i].low && item.age <= Assets_.ageGroup[i].high
                    || Assets_.ageGroup[i].low && !Assets_.ageGroup[i].high && Assets_.ageGroup[i].low <= item.age) {
                    playerListed[foundInPlayerListed].playersbyAge[i]++                
                    break
                }
            }

            let foundRegion: boolean = false
            for (let i: number = 0; i < (shownRegions ? shownRegions.length : this.state.shownRegions.length); i++) {
                if ((shownRegions ? shownRegions[i].code :this.state.shownRegions[i].code) === item.canton) {
                    playerListed[foundInPlayerListed].playersbyRegion[i]++
                    foundRegion = true
                    break
                }
            }
            if (!foundRegion) {
                playerListed[foundInPlayerListed].playersbyRegion[shownRegions ? shownRegions.length : this.state.shownRegions.length]++
            }

        }
        // console.log (playerListed)
        if (shownRegions && regions && mainRegions) {
            this.setState({playerListed, shownRegions, regions, mainRegions})
        } else if (nationalities && secondaryNationalities) {
            this.setState({playerListed, nationalities, secondaryNationalities})
        } else {
            this.setState({playerListed})
        }
        
    }
/*
    public initializePlayerTableByRegion(selectedRegionCode?: string) {
        if (selectedRegionCode) {
            console.log (selectedRegionCode)
            console.log (this.state.playerListed)
            if (this.state.playerListed && selectedRegionCode) {
                this.state.playerListed.filter(({canton}) => canton === selectedRegionCode)            
            }
        } else {
            if (this.state.playerListed && this.state.selectedRegionCode) {
                this.state.playerListed.filter(({canton}) => canton === this.state.selectedRegionCode)            
            }
        }

    } 
*/

    public changeRegionsSelect(selectedRegionCode: string) {
        this.setState({selectedRegionCode})
        this.initializePlayerTable(
            this.state.selectedGender,
            this.state.selectedNationality,
            this.state.selectedAgeGroup,
            selectedRegionCode,
            this.state.selectedFromDate,
            this.state.selectedToDate
        )
    }

    public changeNationalitySelect(selectedNationality: string) {
        this.setState({selectedNationality})
        this.initializePlayerTable(
            this.state.selectedGender,
            selectedNationality,
            this.state.selectedAgeGroup,
            this.state.selectedRegionCode,
            this.state.selectedFromDate,
            this.state.selectedToDate
        )
    }

    public changeGenderSelect(selectedGender: string) {
        this.setState({selectedGender})
        this.initializePlayerTable(
            selectedGender,
            this.state.selectedNationality,
            this.state.selectedAgeGroup,
            this.state.selectedRegionCode,
            this.state.selectedFromDate,
            this.state.selectedToDate
        )
    }
    
    public changeAgeGroupSelect(selectedAgeGroup: string) {
        this.setState({selectedAgeGroup})
        this.initializePlayerTable(
            this.state.selectedGender,
            this.state.selectedNationality,
            selectedAgeGroup,
            this.state.selectedRegionCode,
            this.state.selectedFromDate,
            this.state.selectedToDate
        )
    }

    public getAgeGroupFilter(): JSX.Element {
        return (
            <div style = {{height: '22px'}} className = "fl ml">
                <span style = {{height: '22px'}}>{Assets.AgeGroup_s}:&nbsp;</span>
                <select
                    style = {{height: '22px', background: '#fff'}}
                    onChange = {(e) => this.changeAgeGroupSelect(e.target.value)}
                    value = {this.state.selectedAgeGroup}
                >
                    {this.getAgeGroup()}
                </select>
            </div>
        )
    }

    public getRegionsFilter(): JSX.Element {
        return (
            <div style = {{height: '22px'}} className = "fl ml">
                <span style = {{height: '22px'}}>{Assets.Region}:&nbsp;</span>
                <select
                    style = {{height: '22px', background: '#fff'}}
                    onChange = {(e) => this.changeRegionsSelect(e.target.value)}
                    value = {this.state.selectedRegionCode}
                >
                    {this.getRegions()}
                </select>
            </div>
        )
    }

    public getNationalityFilter(): JSX.Element {
        return (
            <div style = {{height: '22px'}} className = "fl ml">
                <span style = {{height: '22px'}}>{Assets.Nationality}:&nbsp;</span>
                <select
                    style = {{height: '22px', background: '#fff'}}
                    onChange = {(e) => this.changeNationalitySelect(e.target.value)}
                    value = {this.state.selectedNationality}
                >
                    {this.getNationality()}
                </select>
            </div>
        )

    }

    public getGenderFilter(): JSX.Element {
        return (
            <div style = {{height: '22px'}} className = "fl ml">
                <span style = {{height: '22px'}}>{Assets.Gender}:&nbsp;</span>
                <select
                    style = {{height: '22px', background: '#fff'}}
                    onChange = {(e) => this.changeGenderSelect(e.target.value)}
                    value = {this.state.selectedGender}
                >
                    {this.getGender()}
                </select>
            </div>
        )
    }

    public setDateFrom(selectedFromDate: moment.Moment) {
        const newDateFrom: string = selectedFromDate ? selectedFromDate.format(Assets_.dateDisplayFormat) : undefined
        if (!this.state.selectedToDate || !selectedFromDate || newDateFrom <= this.state.selectedToDate.format(Assets_.dateDisplayFormat)) {
            this.setState({selectedFromDate})
            this.initializePlayerTable(
                this.state.selectedGender,
                this.state.selectedNationality,
                this.state.selectedAgeGroup,
                this.state.selectedRegionCode,
                selectedFromDate,
                this.state.selectedToDate
            )
        }
    }

    public setDateTo(selectedToDate: moment.Moment) {
        const newDateTo: string = selectedToDate ? selectedToDate.format(Assets_.dateDisplayFormat) : undefined
        if (!this.state.selectedFromDate || !selectedToDate ||newDateTo >= this.state.selectedFromDate.format(Assets_.dateDisplayFormat)) {
            this.setState({selectedToDate})
            this.initializePlayerTable(
                this.state.selectedGender,
                this.state.selectedNationality,
                this.state.selectedAgeGroup,
                this.state.selectedRegionCode,
                this.state.selectedFromDate,
                selectedToDate
            )
        }
    }

    public getDateFilterFrom(): JSX.Element {
        return (
            <div id = "datadate0" className = "fl ml">
                <SingleDatePicker
                    id = "datadate3"
                    placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                    date = {this.state.selectedFromDate}
                    onDateChange = {(date) => this.setDateFrom(date)}
                    focused = {this.state.focusedFromDate}
                    onFocusChange = {(focused) => this.setState({focusedFromDate: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
            />
            </div>
        )
    }

    public getDateFilterTo(): JSX.Element {
        return (
            <div id = "datadate00" className = "fl ml">
                <SingleDatePicker
                    id = "datadate4"
                    placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                    date = {this.state.selectedToDate}
                    onDateChange = {(date) => this.setDateTo(date)}
                    focused = {this.state.focusedToDate}
                    onFocusChange = {(focused) => this.setState({focusedToDate: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
            />
            </div>
        )
    }

    public exportToPDF() {
        window['html2canvas'] = html2canvas
        const el: HTMLElement = document.getElementById('Exported2')
        if (el) {
            const img = new Image();
            img.src = Assets_.jackpotsLogo
            img.onload = () => {
                const img2 = new Image()
                img2.src = Assets_.badenLogo
                
                img2.onload = () => {
                    const HTML_Width: number = el.clientWidth
                    const HTML_Height: number = el.clientHeight
                    html2canvas(el, {
                        allowTaint: true,
                        // useCORS: true,
                        onclone: (document) => {
                            el.style.borderStyle = 'none'
                            el.style.background = '#fff'
                        }
                    }).then((canvas) => {
                        canvas.getContext('2d')
                        const imgData = canvas.toDataURL('image/jpeg', 1.0);
                        const pdf = new jsPDF('landscape', 'mm', 'a4');
                        pdf.addImage(
                            imgData,
                            'JPG',
                            10,
                            20,
                            297-20,
                            (297-20) / HTML_Width * HTML_Height
                        );
    
                        getHeader('PLR', pdf, img, img2)
                        getFooter('PLR', pdf)
    
    
    /*
                        for (let i = 1; i <= totalPDFPages; i++) {
                            pdf.addPage(PDF_Width, PDF_Height);
                            pdf.addImage(
                                imgData,
                                'JPG',
                                top_left_margin,
                                -(PDF_Height * i) + top_left_margin * 4,
                                canvas_image_width,
                                canvas_image_height
                            );
                        }
    */
                        pdf.save(Assets.PlayersRegistrations + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + '.pdf')
                    });
                }
            }
        }
    }

    public exportToPDFTable() {
        const doc = new jsPDF('landscape', 'mm', 'a4')
        const img = new Image();
        img.src = Assets_.jackpotsLogo
        img.onload = () => {
            const img2 = new Image()
            img2.src = Assets_.badenLogo
            img2.onload = () => {
                // settings
                doc.autoTableSetDefaults(
                    {margin: {top: 20, left: 10, right: 10, bottom: 20},
                    headStyles: {fillColor: '#c90227'},
                    footStyles: {fillColor: '#111'},
                    cellPadding: 5,
                })
                // page 1
                getHeader('PLR', doc, img, img2)
                doc.autoTable({html: '#playerstable'})
                getFooter('PLR', doc)
    
                // page 2
                // doc.addPage();

                // set page numbers
                getPageNumbers(doc)
    
                // finally
                doc.save(Assets.PlayersRegistrations + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + '.pdf')
            }
        }    }

    public exportPlayersByNationality(playersByNationality: number[], players: number): string {
        let PlayersByNationality: string = ''
        if (playersByNationality) {
            for (let i: number = 0; i < playersByNationality.length; i++) {
                PlayersByNationality += this.state.showPercentages ? (playersByNationality[i] / players * 100).toFixed(2) + ',': playersByNationality[i] + ','
            }
        }
        return PlayersByNationality
    }

    public exportPlayersByAge(playersByAge: number[], players: number): string {
        let PlayersByAge: string = ''
        if (playersByAge) {
            for (let i: number = 0; i < playersByAge.length; i++) {
                PlayersByAge += this.state.showPercentages ? (playersByAge[i] / players * 100).toFixed(2) + ',': playersByAge[i] + ','
            }
        }
        return PlayersByAge
    }

    public exportPlayersByRegion(playersByRegion: number[], players: number): string {
        let PlayersByRegion: string = ''
        if (playersByRegion && this.state.selectedRegionCode === Assets.All) {
            for (let i: number = 0; i < playersByRegion.length; i++) {
                PlayersByRegion += this.state.showPercentages ? (playersByRegion[i] / players * 100).toFixed(2) + ',': playersByRegion[i] + ','
            }
        }
        return PlayersByRegion
    }

    public exportFooter() {
        let footerExport: string = ''
        let sumPlayers = 0
        let sumMale = 0
        let sumFemale = 0
        const sumNationalities: number[] = []
        const sumAgeGroup: number[] = []
        const sumShowRegions: number[] = []
        

        if (this.state.nationalities) {
            for (const {} of this.state.nationalities) {
                sumNationalities.push(0)
            }
        }
        sumNationalities.push(0)

        if (Assets_.ageGroup) {
            for (const {} of Assets_.ageGroup) {
                sumAgeGroup.push(0)
            }
        }

        if (this.state.shownRegions && this.state.selectedRegionCode === Assets.All) {
            for (const {} of this.state.shownRegions) {
                sumShowRegions.push(0)
            }
            sumShowRegions.push(0)
        }
        
      
        if (this.state.playerListed) {
            for (const item of this.state.playerListed) {
                sumPlayers += item.players
                sumMale += item.playersbyGender.male
                sumFemale += item.playersbyGender.female                     
                for (let i: number = 0; i < sumNationalities.length; i++) {
                    sumNationalities[i] += item.playersbyNationality[i]
                }
                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    sumAgeGroup[i] += item.playersbyAge[i]
                }
                for (let i: number = 0; i < sumShowRegions.length; i++) {
                    sumShowRegions[i] += item.playersbyRegion[i]
                }
            }
            
        }
        if (this.state.showPercentages) {
            footerExport += sumPlayers + ',' + (sumMale / sumPlayers * 100).toFixed(2) + ',' + (sumFemale / sumPlayers * 100).toFixed(2) + ','
            for (let item of sumNationalities) { 
                item = item  / sumPlayers * 100
                footerExport += item.toFixed(2) + ','}
            for (let item of sumAgeGroup) { 
                item = item  / sumPlayers * 100
                footerExport += item.toFixed(2) + ','}
            for (let item of sumShowRegions) {
                item = item  / sumPlayers * 100
                footerExport += item.toFixed(2) + ','}
            } else {
                footerExport += sumPlayers + ',' + sumMale + ',' + sumFemale + ','
                for (const item of sumNationalities) {footerExport += item + ','}
                for (const item of sumAgeGroup) {footerExport += item + ','}
                for (const item of sumShowRegions) {footerExport += item + ','}
            }
        
        return footerExport
    }

    

    public exportToCSV() {
        let csv: string = Assets.Date + ',' + Assets.Players + ',' + Assets.male + ',' + Assets.female + ','
        for (const item of this.state.nationalities) {csv += item.name + ','}
        csv += Assets.Other + ','
        for (const item of Assets_.ageGroup) {csv += item.text + ','}
        if (this.state.selectedRegionCode === Assets.All) {
            for (const item of this.state.shownRegions) {
                csv += item.name + ','}
            csv += Assets.Other + ','
        }
        csv += '\n'
        
        if (this.state.playerListed) {
            for (const item of this.state.playerListed) {
                csv += item.date + ',' + item.players + ',' +  
                (this.state.showPercentages ? (item.playersbyGender.male / item.players * 100).toFixed(2) + ','  : item.playersbyGender.male + ',') + 
                (this.state.showPercentages ? (item.playersbyGender.female / item.players * 100).toFixed(2) + ','  : item.playersbyGender.female + ',') + 
                this.exportPlayersByNationality(item.playersbyNationality, item.players) + 
                this.exportPlayersByAge(item.playersbyAge, item.players) + 
                this.exportPlayersByRegion(item.playersbyRegion, item.players) + '\n'
            }
        }

        csv += Assets.Total + ',' + this.exportFooter() + '\n'

        


        
        
        const element = document.createElement('a')
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.PlayersRegistrations + ' ' + (new Date()).toISOString().substring(0,10).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}))     
    }

    public exportToCSVButton(): JSX.Element {
        return (
            <div data-html2canvas-ignore="true" style = {{height: '22px', lineHeight: '22px'}} className = "fr">
                <button
                    disabled = {!isUserReports() && !isLocalhost()}
                    onClick = {() => this.exportToCSV()}
                    className = "btn btn-default btn-sm mr0 csv-button"
                >
                    <FontAwesomeIcon icon = {faFileCsv}/>
                </button>
            </div>
        )
    }

    public exportToPDFButton(): JSX.Element {
        return (
            <div data-html2canvas-ignore="true" style = {{height: '22px', lineHeight: '22px'}} className = "fr">
                <button
                    disabled = {!isUserReports() && !isLocalhost()}
                    title = {Assets.PDFExport}
                    onClick = {() => this.exportToPDF()}
                    className = "btn btn-danger btn-sm pdf-button"
                >
                    <FontAwesomeIcon icon = {faFilePdf}/>
                </button>
            </div>
        )
    }

    public exportToPDFButtonTable(): JSX.Element {
        return (
            <div data-html2canvas-ignore="true" style = {{height: '22px', lineHeight: '22px'}} className = "fr">
                <button
                    disabled = {!isUserReports() && !isLocalhost()}
                    title = {Assets.PDFExportTable}
                    onClick = {() => this.exportToPDFTable()}
                    className = "btn btn-danger btn-sm pdf-button"
                >
                    <FontAwesomeIcon icon = {FaFilePdf}/>
                </button>
            </div>
        )
    }

    public getPercentageToggle(): JSX.Element {
        return (
            <div style = {{height: '22px', lineHeight: '22px'}} className = "fr">
                <a
                    onClick = {() => this.setState({showPercentages: !this.state.showPercentages})}
                    className = {this.state.showPercentages ? 'btn btn-success btn-sm percentage-button' : 'btn btn-default btn-sm percentage-button'}
                >
                    <i className = {faPercentage}/>
                </a>
            </div>
        )
    }

    public getFilters(): JSX.Element {
        return (
            <div className = "clearfix" data-html2canvas-ignore="true">
                <div style = {{height: '22px', lineHeight: '22px'}} className = "fl bold">{Assets.Filter}:&nbsp;</div>
                <div className = "fl ml" style = {{height: '22px', lineHeight: '22px'}}>{Assets.Date + ' ' + Assets.Start.toLowerCase()}:&nbsp;</div>
                {this.getDateFilterFrom()}
                <div className = "fl ml" style = {{height: '22px', lineHeight: '22px'}}>{Assets.Date + ' ' + Assets.End.toLowerCase()}:&nbsp;</div>
                {this.getDateFilterTo()}
                {this.getGenderFilter()}
                {this.getNationalityFilter()}
                {this.getAgeGroupFilter()}
                {this.getRegionsFilter()}
                {this.exportToCSVButton()}
                {this.exportToPDFButton()}
                {this.exportToPDFButtonTable()}
                {this.getPercentageToggle()}
            </div>
        )
    }

    public getGender(): JSX.Element[] {
        const gender: JSX.Element[] = []
        gender.push(<option key = {Assets.All} value = {Assets.All}>{Assets.All}</option>)
        gender.push(<option key = {Assets.male} value = {Assets.male}>{Assets.male}</option>)
        gender.push(<option key = {Assets.female} value = {Assets.female}>{Assets.female}</option>)
        return gender
    }
    
    public getNationality(): JSX.Element[] {
        const nationality: JSX.Element[] = []
        nationality.push(<option key = "0" value = {Assets.All}>{Assets.All}</option>)

        if (Main.Instance.DatabaseDataState.nationalities) {
            for (const item of Main.Instance.DatabaseDataState.nationalities) {
                nationality.push(<option key = {item.data.code3} value = {item.data.code3}>{item.data.nationality} ({item.data.code3})</option>)
            }
        }
        
        return nationality
    }

    public getAgeGroup(): JSX.Element[] {
        const ageGroup: JSX.Element[] = []
        ageGroup.push(<option key = "0" value = {Assets.All}>{Assets.All}</option>)

        for (const item of Assets_.ageGroup) {
            ageGroup.push(<option key = {item.text} value = {item.text}>{item.text}</option>)
        }
        return ageGroup
    }

    public getRegions(): JSX.Element[] {
        const regions: JSX.Element[] = []
        regions.push(<option key = "0" value = {Assets.All}>{Assets.All}</option>)

        if (this.state.regions) {
            for (const item of this.state.regions) {
                regions.push(<option key = {item.code} value = {item.code}>{item.name} ({item.code})</option>)
            }
        }

        return regions

/*
        const regions: JSX.Element[] = []
        regions.push(<button key = "0" type = "button" onClick = {() => this.selectRegionCode('')} className = {this.state.selectedRegionCode === '' ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.All}</button>)

        if (this.state.regions) {
            for (const item of this.state.regions) {
                regions.push(<button key = {item.code} type = "button" onClick = {() => this.selectRegionCode(item.code)} className = {this.state.selectedRegionCode === item.code ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{item.name} ({item.code})</button>)
            }
        }
        return regions
    */
    }

    public byName (a, b): number {
        return a.name > b.name ? 1 : -1
    }

    public getPlayersByNationality(playersByNationality: number[], players: number): JSX.Element[] {
        const PlayersByNationality: JSX.Element[] = []
        if (playersByNationality && this.state.selectedNationality === Assets.All) {
            for (let i: number = 0; i < playersByNationality.length; i++) {
                PlayersByNationality.push(<td key = {i.toString()} className = {(i===0)? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}{this.state.showPercentages ? this.colorTextLight((playersByNationality[i] / players * 100).toFixed(2)) : this.numberWithSpaces(playersByNationality[i])}</td>)
            }
        }
        return PlayersByNationality
    }

    public getPlayersByRegion(playersByRegion: number[], players: number): JSX.Element[] {
        const PlayersByRegion: JSX.Element[] = []
        if (playersByRegion && this.state.selectedRegionCode === Assets.All) {
            for (let i: number = 0; i < playersByRegion.length; i++) {
                PlayersByRegion.push(<td key = {i.toString()} className = {(i===0)? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}{this.state.showPercentages ? this.colorTextLight((playersByRegion[i] / players * 100).toFixed(2)) : this.numberWithSpaces(playersByRegion[i])}</td>)
            }
        }
        return PlayersByRegion
    }

    public getPlayersByAge(playersByAge: number[], players: number): JSX.Element[] {
        const PlayersByAge: JSX.Element[] = []
        if (playersByAge) {
            for (let i: number = 0; i < playersByAge.length; i++) {
                PlayersByAge.push(<td key = {i.toString()} className = {i===0? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}{this.state.showPercentages ? this.colorTextLight((playersByAge[i] / players * 100).toFixed(2)) : this.numberWithSpaces(playersByAge[i])}</td>)
            }
        }
        return PlayersByAge
    }

    public fillTableData(): JSX.Element[] {
        const tableRows: JSX.Element[] = []

        if (this.state.playerListed) {
            for (const item of this.state.playerListed) {
                tableRows.push(<tr key={item.date}>
                    
                    <td key = {item.date}><button type = "button" onClick = {() => this.selectWhichDate(item.date)} className = {this.whichDateSelected(item.date) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{item.date}</button></td>
                    <td className = "bold-text">{this.numberWithSpaces(item.players)}</td>
                    {this.state.selectedGender === Assets.All ?
                    <>
                    <td className = "border-left">&nbsp;{this.state.showPercentages ? this.colorTextLight((item.playersbyGender.male / item.players * 100).toFixed(2)) : this.numberWithSpaces(item.playersbyGender.male)}</td>
                    <td>{this.state.showPercentages ? this.colorTextLight((item.playersbyGender.female / item.players * 100).toFixed(2)) : this.numberWithSpaces(item.playersbyGender.female)}</td>
                    </> : void 0}
                    {this.state.selectedNationality === Assets.All ? this.getPlayersByNationality(item.playersbyNationality, item.players) : void 0}
                    {this.state.selectedAgeGroup === Assets.All ? this.getPlayersByAge(item.playersbyAge, item.players) : void 0}
                    {this.state.selectedRegionCode === Assets.All ? this.getPlayersByRegion(item.playersbyRegion, item.players) : void 0}                   
                </tr>)
            }
        }

        return tableRows
    }

    public getFlag(selectedNationality: string): JSX.Element {
        let nationality: string
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            if (item.data.code3 === selectedNationality) {
                nationality = item.data.nationality
                for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                    if (Item.code.toLowerCase() === item.data.code2.toLowerCase()) {
                        return <img title = {nationality} src = {Item.image}/>
                    }
                }
            }
        }
        return <small>{nationality ? nationality : ''}</small>
    }

    public fillTableHeader0(): JSX.Element[] {
        const tableHeader: JSX.Element[] = []
        let headerLight: boolean = false
        // console.log (this.state.selectedNationality)
        tableHeader.push(
            <th className = {headerLight ? 'risk-default-light table-title' : 'risk-default table-title'} colSpan={2} key = "title">
                {Assets.PlayersRegistrations}
                {this.state.selectedNationality !== Assets.All ? <>&nbsp;&nbsp;&nbsp;{this.getFlag(this.state.selectedNationality)}</> : void 0}
            </th>
        )

        if (this.state.selectedGender === Assets.All) {
            headerLight = !headerLight
            tableHeader.push(<th className = {headerLight ? 'risk-default-light' : 'risk-default'} colSpan={2} style = {{textAlign: 'center'}} key = {Assets.Gender}>{Assets.Gender}</th>)
        }

        if (this.state.selectedNationality === Assets.All) {
            headerLight = !headerLight
            tableHeader.push(<th className = {headerLight ? 'risk-default-light' : 'risk-default'} colSpan={(this.state.nationalities ? this.state.nationalities.length : 0) + 1} style = {{textAlign: 'center'}} key = {Assets.Nationality}>{Assets.Nationality}</th>)
        }

        if (this.state.selectedAgeGroup === Assets.All) {
            headerLight = !headerLight
            tableHeader.push(<th className = {headerLight ? 'risk-default-light' : 'risk-default'} colSpan={(Assets_.ageGroup ? Assets_.ageGroup.length : 0)} style = {{textAlign: 'center'}} key = {Assets.AgeGroup_s}>{Assets.AgeGroup_s}</th>)
        }

        if (this.state.selectedRegionCode === Assets.All) {
            headerLight = !headerLight
            tableHeader.push(<th className = {headerLight ? 'risk-default-light' : 'risk-default'} colSpan={(this.state.shownRegions ? this.state.shownRegions.length : 0) + 1} style = {{textAlign: 'center'}} key = {Assets.Region}>{Assets.Region}</th>)
        }
        return tableHeader
    }

    public selectChart(which: number, what: number) {
        this.setState({which, what})
    }

    public barChartSelected(which: number, what: number): boolean {
        if (which === this.state.which && what === this.state.what) {
            return true
        }

        return false
    }

    public selectPieChart(whichPieChart: number) {
        this.setState({whichPieChart})
    }

    public selectWhichDate(whichDate: string) {
        // console.log (whichDate)
        this.setState({whichDate})
    }

    public pieChartSelected(whichPieChart: WhichPieChart): boolean {
        if (whichPieChart === this.state.whichPieChart) {
            return true
        }
        return false
    }

    public whichDateSelected(whichDate: string): boolean {
        if (whichDate === this.state.whichDate) {
            return true
        }
        return false
    }

    public fillTableHeader1(): JSX.Element[] {
        const tableHeader: JSX.Element[] = []
        if (this.state.whichDate !== Assets.Any) {
            tableHeader.push(<th colSpan = {2} key = "0"><button type = "button" onClick = {() => this.selectPieChart(WhichPieChart.NoCharts)} className = {this.pieChartSelected(WhichPieChart.NoCharts) ? 'btn btn-danger btn-xs mr0' : 'btn btn-default btn-xs mr0'}>{(this.pieChartSelected(WhichPieChart.NoCharts) ? Assets.Show : Assets.Hide) + ' ' + Assets.Pie.toLowerCase() + ' ' + Assets.Chart.toLowerCase()}</button></th>)
            if (this.state.selectedGender === Assets.All) {
                tableHeader.push(<th className = "border-left" colSpan = {2} key = "1" style = {{textAlign: 'center', padding: '0px 0px 3px 0px'}}><button style = {{fontSize: '20px', border: '0px'}} type = "button" onClick = {() => this.selectPieChart(WhichPieChart.Gender)} className = {this.pieChartSelected(WhichPieChart.Gender) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartPie}/></button></th>)
            }
            if (this.state.selectedNationality === Assets.All) {
                tableHeader.push(<th className = "border-left" colSpan = {this.state.nationalities.length + 1} key = "2" style = {{textAlign: 'center', padding: '0px 0px 3px 0px'}}><button style = {{fontSize: '20px', border: '0px'}} type = "button" onClick = {() => this.selectPieChart(WhichPieChart.Nationalities)} className = {this.pieChartSelected(WhichPieChart.Nationalities) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartPie}/></button></th>)
            }
            if (this.state.selectedAgeGroup === Assets.All) {
                tableHeader.push(<th className = "border-left" colSpan = {Assets_.ageGroup.length} key = "3" style = {{textAlign: 'center', padding: '0px 0px 3px 0px'}}><button style = {{fontSize: '20px', border: '0px'}} type = "button" onClick = {() => this.selectPieChart(WhichPieChart.AgeGroups)} className = {this.pieChartSelected(WhichPieChart.AgeGroups) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartPie}/></button></th>)
            }
            if (this.state.selectedRegionCode === Assets.All) {
                tableHeader.push(<th className = "border-left" colSpan = {this.state.shownRegions.length + 1} key = "4" style = {{textAlign: 'center', padding: '0px 0px 3px 0px'}}><button style = {{fontSize: '20px', border: '0px'}} type = "button" onClick = {() => this.selectPieChart(WhichPieChart.Regions)} className = {this.pieChartSelected(WhichPieChart.Regions) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartPie}/></button></th>)
            }

        } else {
            tableHeader.push(<th key = "00"><button type = "button" onClick = {() => this.selectChart(WhichChart.General, WhatChartGeneral.NoCharts)} className = {this.barChartSelected(WhichChart.General, WhatChartGeneral.NoCharts) ? 'btn btn-danger btn-xs mr0' : 'btn btn-default btn-xs mr0'}>{(this.barChartSelected(WhichChart.General, WhatChartGeneral.NoCharts) ? Assets.Show : Assets.Hide) + ' ' + Assets.Bar.toLowerCase() + ' ' + Assets.Chart.toLowerCase()}</button></th>)
            tableHeader.push(<th key = "01" style = {{padding: '0px 0px 3px 8px'}}><button type = "button" style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} onClick = {() => this.selectChart(WhichChart.General, WhatChartGeneral.Players)} className = {this.barChartSelected(WhichChart.General, WhatChartGeneral.Players) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
            
            if (this.state.selectedGender === Assets.All) {
                tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} className = "border-left" key = "10">&nbsp;<button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(WhichChart.Gender, WhatChartGender.Male)} className = {this.barChartSelected(WhichChart.Gender, WhatChartGender.Male) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
                tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} key = "11"><button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(WhichChart.Gender, WhatChartGender.Female)} className = {this.barChartSelected(WhichChart.Gender, WhatChartGender.Female) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
            
            }
            if (this.state.nationalities && this.state.selectedNationality === Assets.All) {
                for (let i: number = 0; i < this.state.nationalities.length; i++) {
                    tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} className = {i === 0 ? 'border-left' : ''} key = {'2' + i}>{i === 0 ? <span>&nbsp;</span> : void 0}<button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(2, i)} className = {this.barChartSelected(2, i) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
                }
                tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} key = {'2' + this.state.nationalities.length}><button style = {{fontSize: '20px', border: '0px'}} type = "button" onClick = {() => this.selectChart(2, this.state.nationalities.length)} className = {this.barChartSelected(2, this.state.nationalities.length) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
            }
            
            if (this.state.selectedAgeGroup === Assets.All) {
                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} className = {i === 0 ? 'border-left' : ''} key = {'3' + i}>{i === 0 ? <span>&nbsp;</span> : void 0}<button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(3, i)} className = {this.barChartSelected(3, i) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
                }
            }
            
            if (this.state.shownRegions && this.state.selectedRegionCode === Assets.All) {
                for (let i: number = 0; i < this.state.shownRegions.length; i++) {
                    tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} className = {i === 0 ? 'border-left' : ''} key = {'4' + i}>{i === 0 ? <span>&nbsp;</span> : void 0}<button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(4, i)} className = {this.barChartSelected(4, i) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
                }
                tableHeader.push(<th style = {{padding: '0px 0px 3px 8px'}} key = {'4' + this.state.shownRegions.length}><button style = {{fontSize: '20px', border: '0px', paddingLeft: '0px', paddingRight: '0px'}} type = "button" onClick = {() => this.selectChart(4, this.state.shownRegions.length)} className = {this.barChartSelected(4, this.state.shownRegions.length) ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}><FontAwesomeIcon icon = {faChartBar}/></button></th>)
            }
        }
        return tableHeader
    }

    public fillTableHeader2(): JSX.Element[] {
        const tableHeader: JSX.Element[] = []

        tableHeader.push(<th key = {Assets.Date}><button type = "button" onClick = {() => this.selectWhichDate(Assets.Any)} className = {this.whichDateSelected(Assets.Any) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.Any + ' ' + Assets.Date.toLowerCase()}</button></th>)
        tableHeader.push(<th key = {Assets.Players}>{Assets.Players}</th>)

        if (this.state.selectedGender === Assets.All) {
            tableHeader.push(
                <th
                    className = "gender border-left pr"
                    style = {{width: '60px'}}
                    key = {Assets.male}
                    title = {Assets.male}
                >
                    &nbsp;<FontAwesomeIcon style = {{color: '#fff'}} icon = {faMars}/>
                    <span style = {{fontSize: '14px', marginLeft: '-20px'/*position: 'absolute', left: '10px', top: '3px'*/}}>
                        {Assets.male.toUpperCase().charAt(0)}
                    </span>
                </th>
            )
            tableHeader.push(
                <th 
                    className = "gender pr"
                    style = {{width: '60px'}}
                    key = {Assets.female}
                    title = {Assets.female}
                >
                    <FontAwesomeIcon style = {{color: '#fff'}} icon = {faVenus}/>
                    <span style = {{fontSize: '14px', marginLeft: '-14px'/*position: 'absolute', left: '10px', top: '3px'*/}}>
                        {Assets.female.toUpperCase().charAt(0)}
                    </span>
                </th>)
        }

        if (this.state.nationalities && this.state.selectedNationality === Assets.All) {
            let i: number = 0
            for (const item of this.state.nationalities) {
                tableHeader.push(<th style = {{width: '60px'}} key = {i.toString()} className = {i===0? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}<img title = {item.name} src = {item.image} alt = {item.name}/></th>)
                i++
            }
            tableHeader.push(<th style = {{width: '60px'}} key = {Assets.Other}>{Assets.Other}</th>)
        }
        
        if (this.state.selectedAgeGroup === Assets.All) {
            let i: number = 0
            for (const item of Assets_.ageGroup) {
                tableHeader.push(<th style = {{width: '60px'}} key = {i.toString() + '_'} className = {i===0? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}{item.text}</th>)
                i++
            }
        }
        if (this.state.shownRegions && this.state.selectedRegionCode === Assets.All) {
            let i: number = 0
            for (const item of this.state.shownRegions) {
                tableHeader.push(<th style = {{width: '60px'}} key = {i.toString() + '__'} title = {item.name} className = {i===0? 'border-left': ''}>{i === 0 ? <span>&nbsp;</span> : void 0}{item.code}</th>)
                i++
            }
            tableHeader.push(<th style = {{width: '60px'}} key = {Assets.Other + '_'}>{Assets.Other}</th>)
        }
        
        return tableHeader
    }

    public fillTableFooter(): JSX.Element[] {
        let footerLight: boolean = false

        const tableFooter: JSX.Element[] = []
        let sumPlayers = 0
        // let sumMale = 0
        // let sumFemale = 0
        const sumNationalities: number[] = []
        const sumAgeGroup: number[] = []
        const sumShowRegions: number[] = []
        const sumGender: number[] = []
        if (this.state.selectedGender === Assets.All) {
            sumGender.push(0)
            sumGender.push(0)
        }
        // console.log (this.state.nationalities)
        if (this.state.selectedNationality === Assets.All) {
            if (this.state.nationalities) {
                for (const {} of this.state.nationalities) {
                    sumNationalities.push(0)
                }
            }
            sumNationalities.push(0)
        }

        if (this.state.selectedAgeGroup === Assets.All) {
            if (Assets_.ageGroup) {
                for (const {} of Assets_.ageGroup) {
                    sumAgeGroup.push(0)
                }
            }
        }

        if (this.state.selectedRegionCode === Assets.All) {
            if (this.state.shownRegions) {
                for (const {} of this.state.shownRegions) {
                    sumShowRegions.push(0)
                }
            }
            sumShowRegions.push(0)
        }
      
        if (this.state.playerListed) {
            for (const item of this.state.playerListed) {
                sumPlayers += item.players
                if (this.state.selectedGender === Assets.All) {
                    sumGender[0] += item.playersbyGender.male
                    sumGender[1] += item.playersbyGender.female
                }
                for (let i: number = 0; i < sumNationalities.length; i++) {
                    sumNationalities[i] += item.playersbyNationality[i]
                }
                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    sumAgeGroup[i] += item.playersbyAge[i]
                }
                for (let i: number = 0; i < sumShowRegions.length; i++) {
                    sumShowRegions[i] += item.playersbyRegion[i]
                }
            }
        }

        tableFooter.push(<th className = {footerLight ? 'risk-default-light' : 'risk-default'} key = {Assets.Total}><button type = "button" onClick = {() => this.selectWhichDate(Assets.Total)} className = {this.whichDateSelected(Assets.Total) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>{Assets.Total}</button></th>)
        tableFooter.push(<th className = {footerLight ? 'risk-default-light' : 'risk-default'} key = "sumPlayers">{this.numberWithSpaces(sumPlayers)}</th>)
        
        if (this.state.selectedGender === Assets.All) {
            footerLight = !footerLight
            this.state.showPercentages ? tableFooter.push(<th key = "sumMale" className = {footerLight ? 'risk-default-light border-left' : 'risk-default border-left'}>&nbsp;{this.ColorTextLight((sumGender[0] / sumPlayers * 100).toFixed(2))}</th>) : tableFooter.push(<th key = "sumMale" className = {footerLight ? 'risk-default-light border-left' : 'risk-default border-left'}>&nbsp;{numberWithSpaces(sumGender[0])}</th>)
            this.state.showPercentages ? tableFooter.push(<th key = "sumFemale" className = {footerLight ? 'risk-default-light' : 'risk-default'}>{this.ColorTextLight((sumGender[1] / sumPlayers * 100).toFixed(2))}</th>) : tableFooter.push(<th key = "sumFemale" className = {footerLight ? 'risk-default-light' : 'risk-default'}>{numberWithSpaces(sumGender[1])}</th>)
        }
        // console.log (this.state.selectedNationality)
        // console.log (sumNationalities)

        if (this.state.selectedNationality === Assets.All) {
            footerLight = !footerLight
            for (let i: number = 0; i < sumNationalities.length; i++) {
                tableFooter.push(
                    <th key = {i.toString()} className = {i===0 ? footerLight ? 'risk-default-light border-left' : 'risk-default border-left' : footerLight ? 'risk-default-light' : 'risk-default'}>
                        {i === 0 ? <span>&nbsp;</span> : void 0}
                        {this.state.showPercentages ? this.ColorTextLight((sumNationalities[i] / sumPlayers * 100).toFixed(2)) : numberWithSpaces(sumNationalities[i])}
                    </th>
                )
            }
        }
        // }
        if (this.state.selectedAgeGroup === Assets.All) {
            footerLight = !footerLight
            for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                tableFooter.push(
                    <th key = {Assets_.ageGroup[i].text} className = {i===0 ? footerLight ? 'risk-default-light border-left' : 'risk-default border-left': footerLight ? 'risk-default-light' : 'risk-default'}>
                        {i === 0 ? <span>&nbsp;</span> : void 0}
                        {this.state.showPercentages ? this.ColorTextLight((sumAgeGroup[i] / sumPlayers * 100).toFixed(2)) : numberWithSpaces(sumAgeGroup[i])}
                    </th>
                )
            }
        }

        if (this.state.selectedRegionCode === Assets.All) {
            footerLight = !footerLight
            for (let i: number = 0; i < sumShowRegions.length; i++) {
                tableFooter.push(
                    <th key = {i.toString() + '_'} className = {i===0 ? footerLight ? 'risk-default-light border-left' : 'risk-default border-left': footerLight ? 'risk-default-light' : 'risk-default'}>
                        {i === 0 ? <span>&nbsp;</span> : void 0}
                        {this.state.showPercentages ? this.ColorTextLight((sumShowRegions[i] / sumPlayers * 100).toFixed(2)) : numberWithSpaces(sumShowRegions[i])}
                    </th>
                )
            }
        }
         
        return tableFooter
    }

    public getTable(): JSX.Element {
        return (
            <table style = {{border: '1px solid #bbb'}} id = "playerstable" className = "table">
                <thead>
                    <tr>
                        {this.fillTableHeader0()}
                    </tr>
                    <tr data-html2canvas-ignore="true">
                        {this.fillTableHeader1()}
                    </tr>
                    <tr>
                        {this.fillTableHeader2()}
                    </tr>
                </thead>
                <tbody>
                    {this.fillTableData()}
                </tbody>
                <tfoot>
                    <tr>
                        {this.fillTableFooter()}
                    </tr>
                </tfoot>
            </table>
        )
    }

    public fillChartData() {
        const chartData: ChartInterface[] = []
        for (const item of this.state.playerListed) {
 
            if (this.state.which === WhichChart.General && this.state.what === WhatChartGeneral.Players) {
                chartData.push({
                        date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                        players: item.players
                    })
                
            } else if (this.state.which === WhichChart.Gender && this.state.what === WhatChartGender.Male) {
                chartData.push({
                        date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                        players: item.playersbyGender.male
                    })
                
            } else if (this.state.which === WhichChart.Gender && this.state.what === WhatChartGender.Female) {
                chartData.push({
                        date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                        players: item.playersbyGender.female
                    })
                
            } else if (this.state.which === WhichChart.Nationalities) {
                for (let i: number = 0; i <= this.state.nationalities.length; i++) {
                    if (this.state.what === i) {
                        chartData.push({
                            date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                            players: item.playersbyNationality[i]
                        })
                    }

                }
            } else if (this.state.which === WhichChart.AgeGroups) {
                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    if (this.state.what === i) {
                        chartData.push({
                            date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                            players: item.playersbyAge[i]
                        })
                    }
                }
            } else if (this.state.which === WhichChart.Regions) {
                for (let i: number = 0; i <= this.state.regions.length; i++) {
                    if (this.state.what === i) {
                        chartData.push({
                            date: item.date.substring(8,10) + '.' + item.date.substring(5,7),
                            players: item.playersbyRegion[i]
                        })
                    }
                }
            }
        }
        return chartData
    }

    public fillPieChartData() {
        const data: Array<{name: string, players: number}> = []
        if (this.state.whichDate === Assets.Total) {
            if (this.state.whichPieChart === WhichPieChart.Gender) {
                data.push({name: Assets.male, players: 0})
                data.push({name: Assets.female, players: 0})
            } else if (this.state.whichPieChart === WhichPieChart.Nationalities) {
                for (let i: number = 0; i < this.state.nationalities.length; i++) {
                    data.push({name: this.state.nationalities[i].name, players: 0})
                }
                data.push({name: Assets.Other, players: 0})
            } else if (this.state.whichPieChart === WhichPieChart.AgeGroups) {
                for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                    data.push({name: Assets_.ageGroup[i].text, players: 0})
                }
            } else if (this.state.whichPieChart === WhichPieChart.Regions) {
                for (let i: number = 0; i < this.state.shownRegions.length; i++) {
                    data.push({name: this.state.shownRegions[i].name, players: 0})
                }
                data.push({name: Assets.Other, players: 0})
            }
        }
        for (const item of this.state.playerListed) {
            if (this.state.whichPieChart === WhichPieChart.Gender) {
                if (this.state.whichDate === Assets.Total) {
                    data[0].players += item.playersbyGender.male
                    data[1].players += item.playersbyGender.female
                } else if (item.date === this.state.whichDate) {
                    data.push({name: Assets.male, players: item.playersbyGender.male})
                    data.push({name: Assets.female, players: item.playersbyGender.female})
                    return data
                }
            } else if (this.state.whichPieChart === WhichPieChart.Nationalities) {
                if (this.state.whichDate === Assets.Total) {
                    for (let i: number = 0; i < item.playersbyNationality.length; i++) {
                        data[i].players += item.playersbyNationality[i]
                    }
                } else if (item.date === this.state.whichDate) {
                    for (let i: number = 0; i < this.state.nationalities.length; i++) {
                        data.push({name: this.state.nationalities[i].name, players: item.playersbyNationality[i]})
                    }
                    data.push({name: Assets.Other, players: item.playersbyNationality[this.state.nationalities.length]})
                }
            } else if (this.state.whichPieChart === WhichPieChart.AgeGroups) {
                if (this.state.whichDate === Assets.Total) {
                    for (let i: number = 0; i < item.playersbyAge.length; i++) {
                        data[i].players += item.playersbyAge[i]
                    }
                } else if (item.date === this.state.whichDate) {
                    for (let i: number = 0; i < Assets_.ageGroup.length; i++) {
                        data.push({name: Assets_.ageGroup[i].text, players: item.playersbyAge[i]})
                    }
                }
            } else if (this.state.whichPieChart === WhichPieChart.Regions) {
                if (this.state.whichDate === Assets.Total) {
                    for (let i: number = 0; i < item.playersbyRegion.length; i++) {
                        data[i].players += item.playersbyRegion[i]
                    }
                } else if (item.date === this.state.whichDate) {
                    for (let i: number = 0; i < this.state.shownRegions.length; i++) {
                        data.push({name: this.state.shownRegions[i].name, players: item.playersbyRegion[i]})
                    }
                    data.push({name: Assets.Other, players: item.playersbyRegion[this.state.shownRegions.length]})
                }
            }
        }
        return data
    }

    public getBarChart(width: number): JSX.Element {
        // console.log(this.fillChartData())
        return (
            <BarChart margin = {{top: 5, right: 5, bottom: -20, left: -40}} width={width} height={350} data={this.fillChartData()}>
                <CartesianGrid strokeDasharray="5 3" />
                <XAxis dataKey="date" height = {80}>
                    <Label value = {Assets.Date}/>
                </XAxis>
                <YAxis width = {110}>
                    <Label angle ={-90} value = {Assets.Players}/>
                </YAxis>
                <Tooltip />
                {/* <Legend />*/}
                <Bar dataKey="players" fill="#8884d8" />
            </BarChart>
        )
    }

    public getPieChart(width: number): JSX.Element {
        // const el: HTMLElement = document.getElementById('Exported2')
        // const width: number = el ? el.clientWidth - 0 : 1200
        const colors: string[] = [
            // bars
            'rgb(114,147,203)',
            'rgb(225,151,76)',
            'rgb(132,186,91)',
            'rgb(211,94,96)',
            'rgb(128,133,133)',
            'rgb(144,103,167)',
            'rgb(171,104,87)',
            'rgb(204,194,16)',
            // lines and points
            'rgb(57,106,177)', // #396AB1
            'rgb(218,124,48)', // #DA7C30
            'rgb(62,150,81)', // #3E9651
            'rgb(204,37,41)', // #CC2529
            'rgb(83,81,84)', // #535154
            'rgb(107,76,154)', // #6B4C9A
            'rgb(146,36,40)', // #922428
            'rgb(148,139,61)' // #948B3D
        ]
/*
        const RADIAN = Math.PI / 180;
        const RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            if (this.props.cc.label === undefined) { return void 0 }
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
            return (
              <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
              </text>
            )
          };
*/
        if (this.state.playerListed === undefined) {return <div/>}
        const data = this.fillPieChartData()
        return (
            <PieChart width={width} height={450}>
                <Pie
                    label = {true}
                    // label={RenderCustomizedLabel}
                    labelLine={true}
                    data={data}
                    dataKey="players"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                >
                    {data.map((entry, index) =>
                    <Cell key={index.toString()} fill={colors[index % colors.length]}/>,
                    )}
                </Pie>
                <Legend
                    width = {200}
                    layout = "horizontal"
                    align = "right"
                    verticalAlign = "middle"
                    iconType = "circle"
                />
            </PieChart>
        )
    }
 
    public isMainRegion(code: string, array?: string[]): boolean {
        for (const item of (array ? array : this.state.mainRegions)) {
            if (item === code) {return true}
        }
        return false
    }

    public isSecondaryNationality(code: string, array?: string[]): boolean {
        for (const item of (array ? array : this.state.secondaryNationalities)) {
            if (item === code) {return true}
        }
        return false
    }

    public removeMainRegion(i: number) {
        const mainRegions: string[] = Object.assign([], this.state.mainRegions)
        mainRegions.splice(i, 1)
        // this.setState({mainRegions})
        this.updateRegions(mainRegions)
    }

    public addMainRegion(region: string) {
        if (region !== '0') {
            const mainRegions: string[] = Object.assign([], this.state.mainRegions)
            mainRegions.push(region)
            this.updateRegions(mainRegions)
            // this.setState({mainRegions})
        }
    }

    public selectRegionOptions(): JSX.Element[] {
        const ro: JSX.Element[] = []
        ro.push (<option key = "0" value = "0">{Assets.Add + ' ' + Assets.Displayed.toLowerCase() + ' ' + Assets.Region.toLowerCase()}</option>)

        for (const item of this.state.regions) {
            ro.push (<option title = {Assets.Remove + ' ' + Assets.Displayed.toLowerCase() + ' ' + Assets.Region.toLowerCase()} disabled = {this.isMainRegion(item.code) || item.code === this.state.mainNationalityCode} key = {item.code} value = {item.code}>{item.name}</option>)
        }
        return ro
    }

    public getRegionName(code: string): string {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (item.data.code === this.state.mainCountry) {
                for (const Item of item.data.regions) {
                    if (Item.code === code) {
                        return Item.name
                    }
                }
            }
        }
        return ''
    }

    public getMainRegions(): JSX.Element[] {
        const mr: JSX.Element[] = []
        for (let i: number = 0; i < this.state.mainRegions.length; i++) {
            mr.push(<button className = "btn btn-danger btn-xs" key = {i.toString()} onClick = {() => this.removeMainRegion(i)} title = {Assets.Remove + ' ' + Assets.Displayed.toLowerCase() + ' ' + Assets.Region.toLowerCase()}>{this.getRegionName(this.state.mainRegions[i])}</button>)
        }
        mr.push(<select key = "-1" value = "0" style = {{width: '160px', height: '24px', background: '#fff'}} onChange = {(e) => this.addMainRegion(e.target.value)}>{this.selectRegionOptions()}</select>)
        return mr
    }

    public removeSecondaryNationality(i: number) {
        const secondaryNationalities: string[] = Object.assign([], this.state.secondaryNationalities)
        secondaryNationalities.splice(i, 1)
        // this.setState({secondaryNationalities})
        this.updateNationalities(secondaryNationalities)
    }

    public updateNationalities(secondaryNationalities: string[]) {
        this.initializeNationalities(secondaryNationalities)
        // this.setState({secondaryNationalities})
    }

    public initializeNationalities(secondaryNationalities?: string[]) {
        const nationality0: Nationalities[] = []
        const nationality: Nationalities[] = []
        // console.log (Main.Instance.DatabaseDataState.nationalities)
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (Item.code.toLowerCase() === item.data.code2.toLowerCase()) {
                    if (item.data.nationality === this.state.mainNationality) {
                        nationality0.push({code: item.data.code3, name: item.data.nationality, image: Item.image})
                    } else if (this.isSecondaryNationality(item.data.nationality, secondaryNationalities)) {
                        nationality.push({code: item.data.code3, name: item.data.nationality, image: Item.image})
                    }        
                }
            }
        }
        const nationalities: Nationalities[] = []
        nationalities.push(...nationality0)
        nationalities.push(...nationality)
        // console.log (nationalities)
        if (secondaryNationalities) {
            // this.setState({nationalities, secondaryNationalities})
            this.initializeTable(undefined, undefined, undefined, nationalities, secondaryNationalities)
        } else {
            this.setState({nationalities})
        }
    }

    public updateRegions(mainRegions: string[]) {
        this.initializeRegions(this.state.mainCountry, mainRegions)
        // this.setState({mainRegions})
    }

    public UpdateRegions(mainRegions?: string[], regions?: Region[]) {
        const shownRegions: Region[] = []
        for (const item of (regions ? regions : this.state.regions)) {
            if (this.isMainRegion(item.code, mainRegions)) {
                shownRegions.push({code: item.code, name: item.name})
            }        
        }
        // console.log (shownRegions)
        if (mainRegions && regions) {
            // this.setState({shownRegions, mainRegions, regions})
            this.initializeTable(shownRegions, mainRegions, regions)
        } else {
            this.setState({shownRegions})
        }
    }

    public initializeTable(shownRegions?, mainRegions?, regions?, nationalities?, secondaryNationalities?) {
        this.initializePlayerTable(
            this.state.selectedGender,
            this.state.selectedNationality,
            this.state.selectedAgeGroup,
            this.state.selectedRegionCode,
            this.state.selectedFromDate,
            this.state.selectedToDate,
            shownRegions, mainRegions, regions, nationalities, secondaryNationalities
        )
    }

    public addSecondaryNationality(nationality: string) {
        if (nationality !== '0') {
            const secondaryNationalities: string[] = Object.assign([], this.state.secondaryNationalities)
            secondaryNationalities.push(nationality)
            this.updateNationalities(secondaryNationalities)
        }
    }

    public selectNationalityOptions(): JSX.Element[] {
        const ro: JSX.Element[] = []
        ro.push (<option key = "0" value = "0">{Assets.Add + ' ' + Assets.Displayed.toLowerCase() + ' ' + Assets.Nationality.toLowerCase()}</option>)
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            ro.push (<option disabled = {this.isSecondaryNationality(item.data.nationality)} key = {item.data.code3} value = {item.data.nationality}>{item.data.nationality}</option>)
        }
        return ro
    }

    public getSecondaryNationalities(): JSX.Element[] {
        const sn: JSX.Element[] = []
        sn.push(<button title = {Assets.Main + ' ' + Assets.Nationality.toLowerCase()} className = "btn btn-danger btn-xs" disabled = {true} key = "-1">{this.state.mainNationality}</button>)
        for (let i: number = 0; i < this.state.secondaryNationalities.length; i++) {
            sn.push(<button title = {Assets.Remove + ' ' + Assets.Displayed.toLowerCase() + ' ' + Assets.Nationality.toLowerCase()} className = "btn btn-warning btn-xs" key = {i.toString()} onClick = {() => this.removeSecondaryNationality(i)}>{this.state.secondaryNationalities[i]}</button>)
        }
        sn.push(<select key = "-2" value = "0" style = {{width: '185px', height: '24px', background: '#fff', marginRight: '20px'}} onChange = {(e) => this.addSecondaryNationality(e.target.value)}>{this.selectNationalityOptions()}</select>)

        return sn
    }

    public render() {
        DbRoutes.wsReinitialize()
        if (!Main.Instance.DatabaseDataState.playersInfo ||  Main.Instance.DatabaseDataState.nationalities === undefined || Main.Instance.DatabaseDataState.countries === undefined) {
            return <div>{Assets.LoadingData}</div>
        }
        // console.log (Main.Instance.DatabaseDataState.playersInfo)
        // console.log (Main.Instance.DatabaseDataState.countries)
        // console.log (Main.Instance.DatabaseDataState.nationalities)
        // console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)
        // console.log (this.state.shownRegions)
        // console.log (this.state.nationalities)
        if (Main.Instance.DatabaseDataState.nationalities && !this.state.nationalities && Main.Instance.DatabaseDataState.FlagsStreamAll) {
            this.initializeNationalities()
        }

        if (this.state.regions && !this.state.shownRegions) {
            this.UpdateRegions()
        }

        if (Main.Instance.DatabaseDataState.countries && !this.state.regions) {
            this.initializeRegions(this.state.mainCountry)
        }
/*
        if (Main.Instance.DatabaseDataState.playersInfo && !this.state.playerListed) {
            this.selectByDay(this.state.registrationDate)
        }
*/
        if (Main.Instance.DatabaseDataState.playersInfo && this.state.playerListed === undefined && this.state.nationalities && this.state.shownRegions) {
            this.initializeTable()
/*            
            this.initializePlayerTable(
                this.state.selectedGender,
                this.state.selectedNationality,
                this.state.selectedAgeGroup,
                this.state.selectedRegionCode,
                this.state.selectedFromDate,
                this.state.selectedToDate
            )
*/
        }
        // console.log (this.state.playerListed)
        const el: HTMLElement = document.getElementById('Exported2')
        const width: number = el ? el.clientWidth - 0 : 1200
/*
        if (Main.Instance.LoginState.unused3 === false && isLocalhost() === false) {
            return (
            <div>
                <h2>{Assets.YouDontHaveSecurityRightsToAccessData}</h2>
            </div>
            )
        } else {
*/
        return (
            <div id = "Exported2">
                <div className = "clearfix">
                    <h2 className = "fl">{Assets.PlayersRegistrations}</h2>
                    <div style = {{marginTop: '25px'}} className = "fr clearfix">
                        {this.state.nationalities ? <div className = "fl mr">{this.getSecondaryNationalities()}</div> : void 0}
                        {this.state.regions ? <div className = "fl">{this.getMainRegions()}</div> : void 0}
                    </div>
                </div>
                {this.state.whichDate !== Assets.Any || this.state.which === 0 && this.state.what === 0 ?
                void 0 : this.getBarChart(width)}
                {this.state.whichDate === Assets.Any || this.state.whichPieChart === 0 ?
                void 0 : this.getPieChart(width)}
                {this.getFilters()}
                {this.state.nationalities ? this.getTable() : void 0}
            </div>
            
        )
        // }
    }
}