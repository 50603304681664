import {Logic} from '../Type'
export class ReusablesState extends Logic.Type {

    public operator_id: string
    public holding_id: string
    // main_level_id:string

    public timeData: {

        hours: string,
        minutes: string,
        seconds: string,
        date: string,
    }
}