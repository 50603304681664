import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {getIssuesTable} from '../RiskComplianceStore/ResponsibleGaming'
import History from '../RiskComplianceStore/history'
import {TimelineEntrySubType/*, IssueTypes*/} from '../../../Logic/Database/DatabaseData'
import CSCallbackResolvePopup from '../RiskComplianceStore/callbackResolvePopup'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {GetAll, GetUnresolved, GetResolved} from '../RiskComplianceStore/RiskManagement'
import {Logic} from '../../../Logic/Type'
import {/*getIssuesNavigation, */getIssuesShow, getIssuesFilter, disabledIssueType, getUnresolvedSubCategoryButtons} from '../MainDashboard/Dashboard'
import {getControlPanel4, getUnresolvedPaginatedIssues} from '../MainDashboard/Dashboard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faExclamationCircle, faTasks} from '@fortawesome/free-solid-svg-icons'
export function AccountStoreGMIssues(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    const issues: number[] = [GetAll(TimelineEntrySubType.Gamification), GetResolved(TimelineEntrySubType.Gamification), GetUnresolved(TimelineEntrySubType.Gamification)]

    return (
        <div>
            {Main.Instance.RiskManagementState.history ? <History/> : void 0}
            {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/> : void 0}
            <h2>{Assets.Gamification + ' ' + Assets.Issues}&nbsp;&nbsp;<small>{Assets.Gamification}</small></h2>
            <div className = "clearfix mt-35">
                <button
                    style = {{marginRight: '0px'}}
                    className = {Main.Instance.RiskManagementState.filterResolved === undefined ? 'btn btn-warning btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*                        
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: undefined,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Gamification :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Other4:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues4Limit,
                            TimelineEntrySubType.Gamification,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
                        )
*/
                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: undefined})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, 1)
                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTasks}/>&nbsp;&nbsp;
                    {Assets.AllIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[0] > 0 ? issues[0] > 0 ? {backgroundColor: '#777', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[0]}</span>
                </button>
                <button
                    className = {Main.Instance.RiskManagementState.filterResolved === true ? 'btn btn-success btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: true,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Gamification :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Other4:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues4Limit,
                            TimelineEntrySubType.Gamification,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
                        )
*/
                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: true})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, 1)

                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faCheckCircle}/>&nbsp;&nbsp;
                    {Assets.ResolvedIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[1] > 0 ? issues[1] > 0 ? {backgroundColor: '#777', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[1]}</span>
                </button>
                <button
                    className = {Main.Instance.RiskManagementState.filterResolved === false ? 'btn btn-danger btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: false,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Gamification :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                              IssueTypes.Other4:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues4Limit,
                            TimelineEntrySubType.Gamification,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
                        )
*/
                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: false})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, 1)

                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faExclamationCircle}/>&nbsp;&nbsp;
                    {Assets.UnresolvedIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[2] > 0 ? /* issues[2] > 0 ? {backgroundColor: '#d9534f', color: '#fff'}: */{backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[2]}</span>
                </button>
            </div>
            <br/>
            {/*getIssuesNavigation()*/}
            {getIssuesShow(false)}
            {getIssuesFilter(4, Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
            {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
            getUnresolvedSubCategoryButtons(4, Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
            {getControlPanel4(false)}
            {getIssuesTable(false, Main.Instance.DatabaseDataState.issues4, TimelineEntrySubType.Gamification/*, true*/)}
        </div>
    )
}