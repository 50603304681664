import * as React from 'react'
// import { View } from './View'
import { AppBar } from './AppBar/AppBar'
import { SideBar } from './SideBar/SideBar'
import { ContentWrapper } from './Reusables/Wrappers'
import { Main } from '../Logic/Main'
import { Logic } from '../Logic/Type'
import * as DbRoutes from '../Logic/Database/DbRoutes'
import * as Assets_ from './Assets_'
// import { Build, BUILD } from '../Logic/Config'

import { subscribe } from '../Socket/statelogic'
import Socket1 from '../Socket/socket-init'
import SocketState from '../Socket/socketstate'
import Socket2 from '../Socket/socket-init2'
import SocketState2 from '../Socket/socketstate2'

import Cookies from 'js-cookie'
import * as Assets from './Assets'
import { Messages } from './Messages';
import * as Config from '../Logic/Config'
import {dashboardSmallTableRows} from '../Views/Main/MainDashboard/Dashboard'
import {RouterObjects} from '../Logic/RouterObjects'
// import {RecentEventsTypes} from '../Logic/Database/DatabaseData'
import axios from 'axios'
import {IsBuildPasino, IsBuildOdds, IsBuildDefault, IsBuildJackpotsCH, IsBuildOddyzzey, IsBuildMyChance, isUserGameProvider, isUserSiteOwner, isUserOperator } from '../Logic/Utils'

function SelectSite(stream: any[]) {
    // console.log (stream)
    if (stream && stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, { site_id: stream[0]['id'] })
        // console.log ('before Initialize and before InitializeSite')
        DbRoutes.Initialize()
        DbRoutes.InitializeSite()
    }
}

function getTime() {
    const now: Date = new Date()
    let time = now.getTime()
    time += 3600000
    return time
} 


function SetCookies() {

    if (
        Main.Instance.LoginState
        && Main.Instance.Location
        && Main.Instance.LoginState.Username
        && Main.Instance.LoginState.role) {

        const location: string = Main.Instance.Location == '/login' ? '/login' : Main.Instance.Location
        // document.cookie = 'loginData=' + base64.encode(JSON.stringify(Main.Instance.LoginState))

        Cookies.remove('location', { path: '/' })
        Cookies.remove('expires', { path: '/' })
        Cookies.set('location', location, { path: '/' })
        Cookies.set('expires', getTime().toString(), { path: '/' })
        // document.cookie = 'location=' + location
        // document.cookie = 'expires=' + getTime().toString()
        
        DbRoutes.getLoadedResources('Cookie - Set', 'location=')
        DbRoutes.getLoadedResources('Cookie - Set', 'expires=')
        
    }
}

/* root view component */
@subscribe(SocketState)
@subscribe(SocketState2)
export class MainView extends React.Component<{ props: React.Props<any> }, {/* connection: boolean,*/ timeout: boolean, interval: any }> {
    constructor(props: any) {
        super(props)
        this.state = {
            timeout: true,
            interval: 0
        }
        this.everythingLoadedJackpots = this.everythingLoadedJackpots.bind(this);
        this.everythingLoadedMyChance = this.everythingLoadedMyChance.bind(this);
    }
    
    
    public componentDidMount() {
        SetCookies()
        window.onpopstate = (e) => {
            if (e.state === window.location.pathname && e.state !== Main.Instance.Location) {
                Logic.Type.New(Main.Instance, { Location: e.state, Back: true })
            } else if (e.state === window.location.pathname && e.state === Main.Instance.Location) {
                window.history.back()
                Logic.Type.New(Main.Instance, { Location: e.state, Back: false })
            } else {
                window.history.back()
                Logic.Type.New(Main.Instance, { Location: e.state, Back: true })
            }
        }
        // console.log ('being here')
        /*
                console.log (Socket1)
                if (!Socket1) {
                    console.log (Main.Instance.LoginState.token)
                    reConnect(Main.Instance.LoginState.token)
                }
        */
        /*        
                if (Socket1 && Socket2 && Main.Instance.LoginState && Main.Instance.LoginState.entity && IsBuildOddyzzey()) {
                    console.log ('try to connect')
                    // if (Main.Instance.LoginState.token) {
                    // connect to admin server
                    Socket1.onOpen = () => {
                        // connect on game store server
                        Socket2.onOpen = () => {
                            if (Build === BUILD.JACKPOTSCH) {
                                // DbRoutes.wsJackpotsInitialize()
                                DbRoutes.WsGetRewards()
                                DbRoutes.WsGetLevels()
                            } else {
                                DbRoutes.WsGetRewards()
                                DbRoutes.WsGetLevels()
                                DbRoutes.loadData()
                                DbRoutes.WsGetGeneral()
                            }
                        }
                    }
                }
        */
    }

    public EverythingLoaded(): boolean {
        return false
    }
    public everythingLoadedMyChance(): boolean {

        if (!Socket1 || !Socket1 || !Socket2 || !Socket2.socket || Main.Instance.FrontendStoreState.strapiPass === undefined) {
            // console.log ('settimeout')
            setTimeout(() => this.everythingLoadedMyChance(), 100)
        } else {
            return this.EverythingLoadedMyChance()
        }
    }

    public everythingLoadedJackpots(): boolean {
        if (!Socket1 || !Socket1 /* || !Socket2 || !Socket2.socket */) {
            setTimeout(() => this.everythingLoadedJackpots(), 100)
        } else {
            return this.EverythingLoadedJackpots()
        }
    }

    public EverythingLoadedMyChance(): boolean {
        // console.log (Socket1, Socket2, Main.Instance.FrontendStoreState.strapiPass)
        // if (this.state) {console.log(this.state.interval)}
        if (!Main.Instance.DatabaseDataState.everythingLoadedFirstCalled) {
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
        }
        // console.log ('rewardShopStream main')
        // console.log (Main.Instance.DatabaseDataState.rewardShopStream)
        if (// Main.Instance.DatabaseDataState.levelsNew &&
            Main.Instance.DatabaseDataState.currenciesStream &&
            Main.Instance.DatabaseDataState.levelsAllStream &&
            Main.Instance.DatabaseDataState.missionStreamAll &&
            Main.Instance.DatabaseDataState.rewardShopStream &&
            Main.Instance.DatabaseDataState.rewardShopStreamAll &&
            Main.Instance.DatabaseDataState.storeStreamAll &&
            Main.Instance.DatabaseDataState.tournamentStreamAll &&
            Main.Instance.DatabaseDataState.Games
            // Main.Instance.DatabaseDataState.challengesAllStream &&
            ) {
            if (this.state && this.state.interval) {
                // console.log ('CLEARED')
                clearInterval(this.state.interval)
                this.setState({interval: 0})
            }    
            return true
        }

        if ((new Date()).getTime() > Main.Instance.DatabaseDataState.everythingLoadedFirstCalled + Main.Instance.DatabaseDataState.everythingLoadedFirstCalledPanicSeconds * 1000) {
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
            // console.log('Smth off')
            if (Main.Instance.DatabaseDataState.Games === undefined) {
                console.log('Games')
                // console.log (Socket2.socket)
                if (Socket2.socket) {
                    Socket2.socket.Send (Config.amMyChanceGames, {})
                    DbRoutes.getLoadedResources('Socket2 - Send', Config.amMyChanceGames)
                } else {
                    Socket2.reConnect(Main.Instance.LoginState.token)
                }
            }

            if (Main.Instance.DatabaseDataState.currenciesStream === undefined) {
                console.log('currenciesStream')
                DbRoutes.GetCurrency()
            } 

            if (Main.Instance.DatabaseDataState.levelsAllStream === undefined) {
                console.log('levelsStreamAll')
                if (Socket1.socket) {
                    Socket1.socket.Send (Config.amGetLevelsAll, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetLevelsAll)
                }
            }
            if (Main.Instance.DatabaseDataState.missionStreamAll === undefined) {
                console.log('missionStreamAll')
                if (Socket1.socket) {
                    Socket1.socket.Send (Config.amGetMissionsAll, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetMissionsAll)
                }
            }

            if (Main.Instance.DatabaseDataState.rewardShopStreamAll === undefined) {
                console.log('rewardShopStreamAll')
                Logic.Type.New(Main.Instance.DatabaseDataState, {strapiConnected: false})
                if (Main.Instance.DatabaseDataState.strapiAuth === undefined && Main.Instance.FrontendStoreState.strapiPass) {
                    // console.log('main if')
                    Logic.Type.New(Main.Instance.DatabaseDataState, {strapiAuth: ''})
                    // console.log (Main.Instance.FrontendStoreState.strapiPass)
                    axios.post(Config.strapiMyChanceAuth, {identifier: 'frontend', password: Main.Instance.FrontendStoreState.strapiPass})
                    .then((response) => {
                        axios.get(Config.strapiMyChanceShopItems + '?_limit=10000&_sort=id:ASC&status_in=active&status_in=preview', {
                            headers: {
                            Authorization: 'Bearer ' + response.data.jwt
                            }
                        })
                        .then((response2) => {
                            Logic.Type.New(Main.Instance.DatabaseDataState, {
                                strapiAuth: response.data.jwt,
                                rewardsCMS: response2.data,
                                strapiConnected: true
                            })
                            if (Socket1.socket) {
                                Socket1.socket.Send (Config.amGetRewardsAll, {})
                                DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetRewardsAll)
                            }

                            
/*
                            axios.get(Config.strapiMyChanceCasinoGames + '?_limit=10000&_sort=id:ASC&status_in=active&status_in=preview', {
                                headers: {
                                Authorization: 'Bearer ' + response.data.jwt
                                }
                            })

                            .then((response3) => {
                                DbRoutes.strapiMyChanceCasinoGames(response3.data, response2.data, response.data.jwt)
                            })
*/
                        })
                        .catch((e) => {Socket1.socket.Send(Config.amGetRewardsAll, {})})
                    })
                    .catch((e) => {Socket1.socket.Send(Config.amGetRewardsAll, {})})

                } else if (Main.Instance.DatabaseDataState.strapiAuth !== '' && Main.Instance.FrontendStoreState.strapiPass) {
                    // console.log('main else')
                    axios.get(Config.strapiMyChanceShopItems + '?_limit=10000&_sort=id:ASC&status_in=active&status_in=preview', {
                        headers: {
                        Authorization: 'Bearer ' + Main.Instance.DatabaseDataState.strapiAuth
                        }
                    })
                    .then((response2) => {
                        Logic.Type.New(Main.Instance.DatabaseDataState, {
                            rewardsCMS: response2.data
                        })
                        if (Socket1.socket) {
                            Socket1.socket.Send (Config.amGetRewardsAll, {})
                            DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetRewardsAll)
                        }

/*
                        axios.get(Config.strapiMyChanceCasinoGames + '?_limit=10000&_sort=id:ASC&status_in=active&status_in=preview', {
                            headers: {
                            Authorization: 'Bearer ' + Main.Instance.DatabaseDataState.strapiAuth
                            }
                        })
                        .then((response3) => {
                            DbRoutes.strapiMyChanceCasinoGames(response3.data, response2.data)
                        })
*/
                    })
                    .catch((e) => {Socket1.socket.Send(Config.amGetRewardsAll, {})})
                }
                
                
            }

            if (Main.Instance.DatabaseDataState.rewardShopStream === undefined) {
                console.log('rewardShopStream')
                if (Socket1.socket) {
                    Socket1.socket.Send (Config.amGetRewards, {
                        intent: -1,
                        limit: 1000,
                        offset: 0,
                        type: 0
    /*
                        intent: +Main.Instance.RewardShopState.filter_category - 1,
                        type: +Main.Instance.RewardShopState.filter_type,
                        // type: +Main.Instance.RewardShopState.filter_type === 0 ? undefined : +Main.Instance.RewardShopState.filter_type - 1,
                        limit: +Main.Instance.RewardShopState.pSize,
                        offset: +Main.Instance.RewardShopState.offset,
    */
                    })
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetRewards)
                }
           }

            if (Main.Instance.DatabaseDataState.storeStreamAll === undefined) {
                console.log('storeStreamAll')
                if (Socket1.socket) {
                    Socket1.socket.Send (Config.amGetStoresAll, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetStoresAll)
                }
            }
/*
            if (Main.Instance.DatabaseDataState === undefined) {
                console.log('storeStreamAll')
                Socket1.socket.Send (Config.amGetStoresAll, {})
            }
*/
/*
            if (Main.Instance.DatabaseDataState.challengesAllStream === undefined) {
                console.log('challengesAllStream')
                Socket1.socket.Send (Config.amGetChallengesAll, {})
            }
*/
            if (Main.Instance.DatabaseDataState.tournamentStreamAll === undefined) {
                console.log('tournamentStreamAll')
                if (Socket1.socket) {
                    Socket1.socket.Send (Config.amGetTournamentsAll, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetTournamentsAll)
                }
            }

        }

        return false
    }

    public EverythingLoadedDefault(): boolean {
        // console.log(Main.Instance.DatabaseDataState)
        if (!Main.Instance.DatabaseDataState.everythingLoadedFirstCalled) {
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
            // console.log ('start ', (new Date()).getTime())
        }

        if (
            Main.Instance.DatabaseDataState.gamesList &&
            Main.Instance.DatabaseDataState.gameProviderNew &&
            // Main.Instance.DatabaseDataState.rgsGameSupplierNew &&
            Main.Instance.DatabaseDataState.gamesListLabels &&
            Main.Instance.DatabaseDataState.gamesListCategories
        ) {
            if (this.state && this.state.interval) {
                // console.log ('CLEARED')
                clearInterval(this.state.interval)
                this.setState({interval: 0})
            }
            return true
        }
        // console.log (Socket1.socket)
        if ((new Date()).getTime() > Main.Instance.DatabaseDataState.everythingLoadedFirstCalled + Main.Instance.DatabaseDataState.everythingLoadedFirstCalledPanicSeconds * 1000) {
            // console.log('Panic')
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
            // console.log ('SOCKET')
            // console.log (Socket1.socket)

            // if (Config.environment === Config.Environment.PRODUCTION && Socket1.socket) {
            if (Main.Instance.DatabaseDataState.gamesList === undefined) {
                Socket1.socket.Send(Config.gGetGameList, {})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameList)
                console.log('gamesCentralNew')
            }

            if (Main.Instance.DatabaseDataState.gameProviderNew === undefined) {
                console.log('gameProviderNew')
                Socket1.socket.Send(Config.gGetGameProviders, {})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameProviders)
            }
            /*
            if (Main.Instance.DatabaseDataState.rgsGameSupplierNew === undefined) {
                console.log('rgsGameSupplierNew')
                Socket1.socket.Send(Config.gGetGameSuppliers, {})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameSuppliers)
            }
            */
            if (Main.Instance.DatabaseDataState.gamesListLabels === undefined) {
                console.log('gameLabels')
                Socket1.socket.Send(Config.gGetGameLabels, {})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameLabels)
            }
            
            if (Main.Instance.DatabaseDataState.gamesListCategories === undefined) {
                console.log('categories')
                Socket1.socket.Send(Config.gGetGameCategories, {})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameCategories)
            }
/*
            } else if (Config.environment !== Config.Environment.PRODUCTION && Socket2.socket) {
                if (Main.Instance.DatabaseDataState.gamesCentralNew === undefined) {
                    console.log('gamesCentralNew')
                    Socket2.socket.Send(Config.gGetGames, {})
                }

                if (Main.Instance.DatabaseDataState.gameProviderNew === undefined) {
                    console.log('gameProviderNew')
                    Socket2.socket.Send(Config.gGetGameProviders, {})
                }
                
                if (Main.Instance.DatabaseDataState.rgsGameSupplierNew === undefined) {
                    console.log('rgsGameSupplierNew')
                    Socket2.socket.Send(Config.gGetGameSuppliers, {})
                }
                
                if (Main.Instance.DatabaseDataState.gameLabels === undefined) {
                    console.log('gameLabels')
                    Socket2.socket.Send(Config.gGetLabels, {})
                }
                
                if (Main.Instance.DatabaseDataState.categories === undefined) {
                    console.log('categories')
                    Socket2.socket.Send(Config.gGetCategories, {})
                }
                
                if (Main.Instance.DatabaseDataState.jurisdictions === undefined) {
                    console.log('jurisdictions')
                    Socket2.socket.Send(Config.gGetJurisdictions, {})
                }

            }
*/
        }
    }

    public EverythingLoadedJackpots(): boolean {
        // console.log(Main.Instance.DatabaseDataState)
        if (!Main.Instance.DatabaseDataState.everythingLoadedFirstCalled) {
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
            // console.log ('start ', (new Date()).getTime())
        }
        
        if ( Main.Instance.DatabaseDataState.unresolvedIssuesCount
            && Main.Instance.DatabaseDataState.resolvedIssuesCount
            && Main.Instance.DatabaseDataState.issuesCount
            // && Main.Instance.DatabaseDataState.ggrReports
            // && Main.Instance.DatabaseDataState.ggrReportsTotal
            // && 
            && Main.Instance.DatabaseDataState.playerCards
            // && Main.Instance.DatabaseDataState.recentEventsLogins
            // && Main.Instance.DatabaseDataState.recentEventsRegistrations
            // && Main.Instance.DatabaseDataState.recentEventsIssues
            // && Main.Instance.DatabaseDataState.recentEventsLogouts
            // && Main.Instance.DatabaseDataState.recentEventsIssuesResolved
            // && Main.Instance.DatabaseDataState.recentEventsIssuesUnresolved
            // && Main.Instance.DatabaseDataState.loggedPlayers
            // && Main.Instance.DatabaseDataState.unverifiedAccounts
            && Main.Instance.DatabaseDataState.unverifiedAccountsAll
            && Main.Instance.DatabaseDataState.expiredAccounts
            // && Main.Instance.DatabaseDataState.gamesCentralNew
            // && Main.Instance.DatabaseDataState.games
            // && Main.Instance.DatabaseDataState.gameProviderNew
            // && Main.Instance.DatabaseDataState.rgsGameSupplierNew
            // && Main.Instance.DatabaseDataState.gameLabels
            // && Main.Instance.DatabaseDataState.categories
            // && Main.Instance.DatabaseDataState.jurisdictions
            // && Main.Instance.DatabaseDataState.recentEventsFirstDeposits 
            // && Main.Instance.DatabaseDataState.playersInfo
            ) {
            // console.log ('loaded ', (new Date()).getTime())
            if (this.state && this.state.interval) {
                // console.log ('CLEARED')
                clearInterval(this.state.interval)
                this.setState({interval: 0})
            }
            return true
        }
        
        if ((new Date()).getTime() > Main.Instance.DatabaseDataState.everythingLoadedFirstCalled + Main.Instance.DatabaseDataState.everythingLoadedFirstCalledPanicSeconds * 1000) {
            // console.log('Panic')
            Logic.Type.New(Main.Instance.DatabaseDataState, { everythingLoadedFirstCalled: (new Date()).getTime() })
            // console.log ('SOCKET')
            if (Socket1.socket) {
/* TEMPORARY */ 
                if (Main.Instance.DatabaseDataState.issuesCount === undefined || Main.Instance.DatabaseDataState.resolvedIssuesCount === undefined || Main.Instance.DatabaseDataState.unresolvedIssuesCount === undefined) {
                    if (Main.Instance.DatabaseDataState.unresolvedIssuesCount === undefined) {console.log('unresolvedIssuesCount')}
                    if (Main.Instance.DatabaseDataState.resolvedIssuesCount === undefined) {console.log('resolvedIssuesCount')}
                    if (Main.Instance.DatabaseDataState.issuesCount === undefined) {console.log('issuesCount')}
                    Socket1.socket.Send (Config.amGetIssuesCount, {filters:{}})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssuesCount)
                }

/*
                if (Main.Instance.DatabaseDataState.ggrReports === undefined || Main.Instance.DatabaseDataState.ggrReportsTotal === undefined) {
                    if (Main.Instance.DatabaseDataState.ggrReportsTotal === undefined) {console.log('ggrReportsTotal')}
                    if (Main.Instance.DatabaseDataState.ggrReports === undefined) {console.log('ggrReports')}  
                    Socket1.socket.Send (Config.amGetReports, {})
                }
*/
                if (Main.Instance.DatabaseDataState.playerCards === undefined) {
                    console.log('playerCards')
                    Socket1.socket.Send (Config.amGetPlayerCards, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetPlayerCards)
                }
/*
                if (Main.Instance.DatabaseDataState.recentEventsLogins === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsRegistrations === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsIssues === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsLogouts === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsIssuesResolved === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsIssuesUnresolved === undefined ||
                    Main.Instance.DatabaseDataState.recentEventsFirstDeposits === undefined
                    ) {
                        if (Main.Instance.DatabaseDataState.recentEventsLogins === undefined) {console.log('recentEventsLogins')}
                        if (Main.Instance.DatabaseDataState.recentEventsRegistrations === undefined) {console.log('recentEventsRegistrations')}
                        if (Main.Instance.DatabaseDataState.recentEventsIssues === undefined) {console.log('recentEventsIssues')}
                        if (Main.Instance.DatabaseDataState.recentEventsLogouts === undefined) {console.log('recentEventsLogouts')}
                        if (Main.Instance.DatabaseDataState.recentEventsIssuesResolved === undefined) {console.log('recentEventsIssuesResolved')}
                        if (Main.Instance.DatabaseDataState.recentEventsIssuesUnresolved === undefined) {console.log('recentEventsIssuesUnresolved')}
                        if (Main.Instance.DatabaseDataState.recentEventsFirstDeposits === undefined) {console.log('recentEventsFirstDeposits')}
                        Socket1.socket.Send (Config.amGetRecentEvents, {eventType: RecentEventsTypes.Login, limit: dashboardSmallTableRows})
                    }


                if (Main.Instance.DatabaseDataState.loggedPlayers === undefined) {
                    console.log('loggedPlayers')
                    Socket1.socket.Send (Config.amGetLoggedInPlayers, {page: 1, size: 7})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetLoggedInPlayers)
                }

                if (Main.Instance.DatabaseDataState.unverifiedAccounts === undefined) {
                    console.log('unverifiedAccounts')
                    Socket1.socket.Send(Config.amGetExpiringAccountsByDays, { page: 1, limit: dashboardSmallTableRows })
                }
*/
                if (Main.Instance.DatabaseDataState.unverifiedAccountsAll === undefined) {
                    console.log('unverifiedAccountsAll')
                    Socket1.socket.Send(Config.amGetExpiringAccounts, { page: 1, limit: dashboardSmallTableRows })
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetExpiringAccounts)
                }

                if (Main.Instance.DatabaseDataState.expiredAccounts === undefined) {
                    console.log('expiredAccounts')
                    Socket1.socket.Send(Config.amGetExpiredAccounts, { page: 1, limit: dashboardSmallTableRows })
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetExpiredAccounts)
                }

/*
                if (Main.Instance.DatabaseDataState.playersInfo === undefined) {
                    console.log('playersInfo')
                    Socket1.socket.Send(Config.amGetPlayersInfo, {})
                }
*/
            }
        }
        return false
    }

    public render() {
        // console.log (Main.Instance.Location)
        // console.log ('IsBuildOddyzzey ', IsBuildOddyzzey())
        // console.log ('IsBuildBackend ', IsBuildBackend())
        // console.log (Socket1)
        // console.log (Socket2)
        // console.log (Main.Instance.Location)
        if (Main.Instance.Location === '/') {
            if (IsBuildJackpotsCH() || IsBuildPasino()) {
                Logic.Type.New(Main.Instance, { Location: '/dashboard' })
            } else if (IsBuildMyChance()) {
                Logic.Type.New(Main.Instance, { Location: '/promo-store' })
            } else if (IsBuildDefault()) {
                Logic.Type.New(Main.Instance, { Location: '/deployed-projects' })
            } else if (IsBuildOdds()) {
                Logic.Type.New(Main.Instance, { Location: '/oddsportal' })
            }
        }
        if (IsBuildOdds()) {
            DbRoutes.initOdds()
        }
        if (Main.Instance.DatabaseDataState.connection === false && Socket1 && Socket1.socket && Main.Instance.LoginState && Main.Instance.LoginState.entity /* && (IsBuildOddyzzey() || IsBuildMyChance() && Socket2 && Socket2.socket)*/) {
            Logic.Type.New(Main.Instance.DatabaseDataState, { connection: true })
            // console.log ('try to connect')
            // if (Main.Instance.LoginState.token) {
            // connect to admin server
            Socket1.socket.onOpen = () => {

                DbRoutes.getLoadedResources('Socket1 - Send', 'onOpen')
                // connect on game store server
                // Socket2.onOpen = () => {
                // if (IsBuildJackpotsCH() || IsBuildPasino()) {
                    // DbRoutes.wsJackpotsInitialize()
                    // DbRoutes.WsGetRewards()
                    // DbRoutes.WsGetLevels()
                    // Socket2.socket.onOpen = () => {

                    //     DbRoutes.getLoadedResources('Socket2 - Send', 'onOpen')
                    // }
                // } else 
                if (IsBuildMyChance() && Socket2 && Socket2.socket) {
                    Socket2.socket.onOpen = () => {

                        DbRoutes.getLoadedResources('Socket2 - Send', 'onOpen')
                        DbRoutes.loadData()
                        DbRoutes.WsGetGeneral()
                        DbRoutes.WsGetRewards()
                        DbRoutes.WsGetLevels()
                        DbRoutes.WsGetMissions()
                        DbRoutes.WsGetTournaments()
                        DbRoutes.WsGetStore()
                    }
                } else if (IsBuildOddyzzey()) {
                    DbRoutes.WsGetRewards()
                    DbRoutes.WsGetLevels()
                    DbRoutes.loadData()
                    DbRoutes.WsGetGeneral()
                }
                // }
            }
        }

        if (Main.Instance.LoginState && Main.Instance.LoginState.entity && (IsBuildDefault() || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildOddyzzey() || IsBuildMyChance())) {
            if ((IsBuildDefault() ||IsBuildJackpotsCH() || IsBuildPasino()) && Socket1 && Socket1.socket /*&& Socket2*/) {
                DbRoutes.wsGet()
                DbRoutes.wsUpdate()
                DbRoutes.wsInsert()
                DbRoutes.wsDelete()
                // DbRoutes.wsInsertSpecial()
                DbRoutes.wsInitialize()
                // DbRoutes.wsGetStore()
                // DbRoutes.wsGetLevels()
                // DbRoutes.wsGetRewards()
                // DbRoutes.wsGetMissions()
                // DbRoutes.wsGetTournaments()
                // DbRoutes.wsGetChallenges()
                // DbRoutes.wsGetPlayerIds()
                // DbRoutes.wsGetGeneral()

                // DbRoutes.wsDeleteStore()
                // DbRoutes.wsDeleteLevel()
                // DbRoutes.wsDeleteReward()
                // DbRoutes.wsDeleteMission()
                // DbRoutes.wsDeleteTournament()
                // DbRoutes.wsDeleteChallenge()

                // DbRoutes.wsInsertStore()
                // DbRoutes.wsInsertLevel()
                // DbRoutes.wsInsertReward()
                // DbRoutes.wsInsertMission()
                // DbRoutes.wsInsertTournament()
                // DbRoutes.wsInsertChallenge()

                // DbRoutes.wsUpdateStore()
                // DbRoutes.wsUpdateLevel()
                // DbRoutes.wsUpdateReward()
                // DbRoutes.wsUpdateMission()
                // DbRoutes.wsUpdateTournament()
                // DbRoutes.wsUpdateChallenge()

                // DbRoutes.wsGetTournamentResults()
                // DbRoutes.wsGetDailyTournamentResults()
            } else if (IsBuildMyChance() && Socket1 && Socket1.socket && Socket2 && Socket2.socket) {
                DbRoutes.wsGet()
                DbRoutes.wsUpdate()
                DbRoutes.wsInsert()
                DbRoutes.wsDelete()

                DbRoutes.wsInitialize()

                DbRoutes.wsGetStore()
                DbRoutes.wsGetLevels()
                DbRoutes.wsGetRewards()
                DbRoutes.wsGetMissions()
                DbRoutes.wsGetTournaments()
                // DbRoutes.wsGetChallenges()
                DbRoutes.wsGetPlayerIds()
                DbRoutes.wsGetGeneral()

                DbRoutes.wsDeleteStore()
                DbRoutes.wsDeleteLevel()
                DbRoutes.wsDeleteReward()
                DbRoutes.wsDeleteMission()
                DbRoutes.wsDeleteTournament()
                // DbRoutes.wsDeleteChallenge()

                DbRoutes.wsInsertStore()
                DbRoutes.wsInsertLevel()
                DbRoutes.wsInsertReward()
                DbRoutes.wsInsertMission()
                DbRoutes.wsInsertTournament()
                // DbRoutes.wsInsertChallenge()

                DbRoutes.wsUpdateStore()
                DbRoutes.wsUpdateLevel()
                DbRoutes.wsUpdateReward()
                DbRoutes.wsUpdateMission()
                DbRoutes.wsUpdateTournament()
                // DbRoutes.wsUpdateChallenge()
            } else if (Socket1 && Socket1.socket) {
                DbRoutes.wsInitialize()
                DbRoutes.wsGetStore()
                DbRoutes.wsGetLevels()
                DbRoutes.wsGetRewards()
                DbRoutes.wsGetMissions()
                DbRoutes.wsGetTournaments()
                DbRoutes.wsGetChallenges()
                DbRoutes.wsGetPlayerIds()
                DbRoutes.wsGetGeneral()

                DbRoutes.wsDeleteStore()
                DbRoutes.wsDeleteLevel()
                DbRoutes.wsDeleteReward()
                DbRoutes.wsDeleteMission()
                DbRoutes.wsDeleteTournament()
                DbRoutes.wsDeleteChallenge()

                DbRoutes.wsInsertStore()
                DbRoutes.wsInsertLevel()
                DbRoutes.wsInsertReward()
                DbRoutes.wsInsertMission()
                DbRoutes.wsInsertTournament()
                DbRoutes.wsInsertChallenge()

                DbRoutes.wsUpdateStore()
                DbRoutes.wsUpdateLevel()
                DbRoutes.wsUpdateReward()
                DbRoutes.wsUpdateMission()
                DbRoutes.wsUpdateTournament()
                DbRoutes.wsUpdateChallenge()

                DbRoutes.wsGetTournamentResults()
                DbRoutes.wsGetDailyTournamentResults()
            } else if (!Socket1) {
                console.log ('here')
            }
        }

        if (Main.Instance.DashboardState.countCalled === undefined) {
            Logic.Type.New(Main.Instance.DashboardState, { countCalled: true })
            DbRoutes.Initialize()
            if (IsBuildJackpotsCH() || IsBuildPasino() || IsBuildMyChance() || IsBuildDefault()) {
                DbRoutes.wsReinitialize()
            }
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster(Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
            } 
        }

        if (Socket1 && this.state.timeout) {
            // console.log ('ONCE')
            let callback
            
            if (IsBuildJackpotsCH() || IsBuildPasino()) {
                callback = this.everythingLoadedJackpots
            } else if (IsBuildMyChance()) {
                callback = this.everythingLoadedMyChance
/*
            } else if (IsBuildDefault()) {
                callback = this.EverythingLoadedDefault
*/
            } else {
                callback = this.EverythingLoaded
            } 
            this.setState({
                timeout: false,
                interval: setInterval(callback, Main.Instance.DatabaseDataState.everythingLoadedFirstCalledPanicSeconds * 1000)})
        }
        if (Socket1 && Main.Instance.Location !== RouterObjects['forgotPassword'].path) {
            if ((IsBuildJackpotsCH() || IsBuildPasino()) && !this.everythingLoadedJackpots()
                || IsBuildMyChance() && !this.everythingLoadedMyChance()
                // || IsBuildDefault() && !this.EverythingLoadedDefault()
            ) { 
                return <div className="centered message">{Assets.LoadingData}</div> 
            }
        }

        return (
            <div>
                <AppBar />
                <div id="wrapper" className={Main.Instance.MenuState.Open ? 'toggled' : ''}>
                    <SideBar State={this.props} />
                    <ContentWrapper children={this.props.children} />
                    <Messages />
                </div>
            </div>
        )
    }
}