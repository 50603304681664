import * as React from 'react'
import {Logic} from '../../Logic/Type'
import {Main} from '../../Logic/Main'
import {RouterObjects} from '../../Logic/RouterObjects'
import * as Assets from '../Assets'
export function RegisterLogin() {
    return (
        <div className = "container fluid">
            <div className = "row">
                <div className = "col-sm-4 col-sm-offset-4 login col-xs-12" >
                    <button 
                        className = "btn btn-warning btn-block" 
                        onClick={() => {Logic.Type.New(Main.Instance, {Location: RouterObjects['login'].path})}}
                    >
                        {Assets.BackToLogin}
                    </button>
                </div>
            </div>
        </div>
    )
}