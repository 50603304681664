type TLoading = 'init'|'loading'|'loaded'
import {RecentEventsTypes} from '../Logic/Database/DatabaseData'
/** INTERFACES FROM SERVER */

/** Base Class to extend */
export class Route {
    public error: {isGamanzaError: boolean, status: number, message: string}
    public playerCard: any
    public message: any
    public gameSessions: any[]
    public gameTransactions: any[]
    public transactions: any[]
    public totalNumOfItems: number
    public response: any
    public payload: any
    public resolved: boolean
    public loading: TLoading
    public table: string
    public eventType: RecentEventsTypes
    public gameReports: any[]
    public playerReports: any[]
    public gameList: any[]
    public gameLabels: any[]
    public gameCategories: any[]
    public jurisdictionsList: any[]
    public devices: any[]
    public id: number
    public gameProviderList: any[]
    public data: any
    public page: number
    constructor(loading?: TLoading) {
        if (!loading) {
            this.loading = 'init'
        } else {
            this.loading = loading
        }
    }
}

/*
export interface User {
    name: string
    email: string
    username: string
    password: string
    nationality: string
}
*/
/* CLASS EXTENDS ROUTE IMPLEMENTS INTERFACE FROM SERVER ALWAYS INITIALIZE OBJECTS */

export class General extends Route {
    public Route: {}
}

export class Tournaments extends Route {
    public Route: {}
}

export class Tournament extends Route {
    public Route: {}
}

export class UpdateTournament extends Route {
    public Route: {}
}

export class TournamentOptin extends Route {
    public Route: {}
}

export class InsertTournament extends Route {
    public Route: {}
}

export class TournamentResults extends Route {
    public Route: {}
}

export class Missions extends Route {
    public Route: {}
}

export class Mission extends Route {
    public Route: {}
}

export class UpdateMission extends Route {
    public Route: {}
}

export class InsertMission extends Route {
    public Route: {}
}

export interface IConsecutiveBonus {
    minDays: number
    bonus: number
}

export class Levels extends Route {
    public Route: {
        message: [{
            name: string,
            hierarchy: number,
            sublevels: number,
            sublevelPoints: number,
            rewardEvery: number,
            rewardId: number,
            rewardFinal: number,
            operatorId: number,
            siteId: number,
            custom: any
            consecutiveBonus: IConsecutiveBonus[]
            tokensPercentage: number,
        }],
    }
}

export class Level extends Route {
    public Route: {}
}

export class Rewards extends Route {
    public Route: {}
}

export class Reward extends Route {
    public Route: {}
}

export class UserInfo extends Route {
    public Route: {}
}

export class UsersInfo extends Route {
    public Route: {}
}

export class Test extends Route {
    public Route: {}
}

export class Challenge extends Route {
    public Route: {}
}

export class Store extends Route {
    public Route: {}
}

export class Any extends Route {
    public Route: {}
}