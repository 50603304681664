import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import {Logic} from '../../../Logic/Type'
import ReactTable from 'react-table'
// import { numberWithCommas } from '../../../Logic/Utils';
// import * as TableUtils from '../../Reusables/TableUtils'
import {Logic} from '../../../Logic/Type'
import * as Assets from '../../Assets'
/*
function selectGame(game: number) {
    const coreTransactionsGame: number[] = Object.assign([], Main.Instance.DatabaseDataState.coreTransactionsGame)
    let found: number = -1
    for (let i: number = 0; i < coreTransactionsGame.length; i++) {
        if (coreTransactionsGame[i] === game) {
            found = i
            break
        }
    }
    if (found === -1) coreTransactionsGame.push (game)
    else coreTransactionsGame.splice (found, 1)
    Logic.Type.New (Main.Instance.DatabaseDataState, {coreTransactionsGame})
    
    // Logic.Type.New(Main.Instance.DatabaseDataState, {coreTransactionsGame})
    DbRoutes.getCoreTransactionsRefresh()
}
*/
// i === Main.Instance.DatabaseDataState.coreTransactionsGame
/*
function gameExists(game: number) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.coreTransactionsGame.length; i++) {
        if (Main.Instance.DatabaseDataState.coreTransactionsGame[i] === game) {
            return true
        }
    }
    return false
}
*/
function selectYear(coreTransactionsYear: number) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {coreTransactionsYear, coreTransactionsMonth: undefined, coreTransactionsDate: undefined/*, coreTransactionsGame: undefined*/})
    DbRoutes.getCoreTransactionsRefresh()
}

function selectMonth(coreTransactionsMonth: number) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {coreTransactionsMonth, coreTransactionsYear: undefined, coreTransactionsDate: undefined/*, coreTransactionsGame: undefined*/})
    DbRoutes.getCoreTransactionsRefresh()
}

function selectDate(coreTransactionsDate: number) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {coreTransactionsDate, coreTransactionsMonth: undefined, coreTransactionsYear: undefined/*, coreTransactionsGame: undefined*/})
    DbRoutes.getCoreTransactionsRefresh()
}

function getYears(): JSX.Element[] {
    const years: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.coreTransactionsDistinctYears) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.coreTransactionsDistinctYears.length; i++) {
        years.push (
            <button onClick ={() => selectYear(i)} className = {i === Main.Instance.DatabaseDataState.coreTransactionsYear ? 'btn btn-danger btn-xs' : 'btn btn-default btn-xs'}>
                {Main.Instance.DatabaseDataState.coreTransactionsDistinctYears[i].year}
            </button>,
        )
    }
    }
    return years
}

function getMonths(): JSX.Element[] {
    const months: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.coreTransactionsDistinctMonths) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.coreTransactionsDistinctMonths.length; i++) {
        months.push (
            <button onClick ={() => selectMonth(i)} className = {i === Main.Instance.DatabaseDataState.coreTransactionsMonth ? 'btn btn-warning btn-xs' : 'btn btn-default btn-xs'}>
                {Main.Instance.DatabaseDataState.coreTransactionsDistinctMonths[i].month}
            </button>,
        )
    }
    }
    return months
}

function getDates(): JSX.Element[] {
    const dates: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.coreTransactionsDistinctDates) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.coreTransactionsDistinctDates.length; i++) {
        dates.push (
            <button onClick ={() => selectDate(i)} className = {i === Main.Instance.DatabaseDataState.coreTransactionsDate ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                {Main.Instance.DatabaseDataState.coreTransactionsDistinctDates[i].date}
            </button>,
        )
    }
    }
    return dates
}
/*
function getGames(): JSX.Element[] {
    const games: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.coreTransactionsDistinctGames)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.coreTransactionsDistinctGames.length; i++) {
        games.push (
            <button onClick ={() => selectGame(i)} className = {gameExists(i) ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>
                {Main.Instance.DatabaseDataState.coreTransactionsDistinctGames[i].game}
            </button>
        )
    }
    return games
}
*/
export function SettlementReport() {
/*
            {Main.Instance.DatabaseDataState.coreTransactionsStream !== undefined ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.coreTransactionsStream}
                columns = {[
                    {width: 55, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                    {width: 140, Header: Assets.SessionID, accessor: 'sessionid', headerClassName: 'th-left'},
                    {width: 50, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left'},
                    {width: 30, Header: 'SD', accessor: 'siteid', headerClassName: 'th-left'},
                    {width: 110, Header: Assets.GameName, accessor: 'gamename', headerClassName: 'th-left'},
                    {width: 75, Header: Assets.PlayerId, accessor: 'playerid', headerClassName: 'th-left'},
                    {width: 175, Header: Assets.Timestamp, accessor: 'timestamp', headerClassName: 'th-left'},
                    {width: 100, Header: 'Balance', accessor: 'accountresponse', headerClassName: 'th-left', Cell: (row) => getBalance(row.value)},
                    {width: 50, Header: Assets.Bet, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetCurrent(row.value)},
                    {width: 50, Header: 'min', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetMin(row.value)},
                    {width: 50, Header: 'max', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetMax(row.value)},
                    {width: 50, Header: 'start', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetStart(row.value)},
                    {width: 50, Header: 'chng', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetChange(row.value)},
                    {width: 50, Header: Assets.Lines, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesCurrent(row.value)},
                    {width: 50, Header: 'min', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesMin(row.value)},
                    {width: 50, Header: 'max', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesMax(row.value)},
                    {width: 50, Header: 'start', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesStart(row.value)},
                    {width: 50, Header: 'chg', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesChange(row.value)},
                    {width: 50, Header: 'Is win', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getIsWin(row.value)},
                    {Header: 'Screen', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getScreen(row.value)},
                    {width: 100, Header: 'Total bets', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalBets(row.value)},
                    {width: 100, Header: 'Total ratio', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalRatio(row.value)},
                    {width: 100, Header: 'Total RTP', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalRTP(row.value)},
                    {width: 100, Header: 'Total spins', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalSpins(row.value)},
                    {width: 150, Header: 'Total win amount', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalWinAmount(row.value)},
                    {width: 150, Header: 'Total winning spins', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalWinningSpins(row.value)},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize = {10}
                resizable = {true}
                onPageChange = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {lastActionTime: new Date().getTime()})}
            />
            : DbRoutes.getCoreTransactions()}
                    {width: 100, Header: Assets.Win, accessor: 'win', headerClassName: 'th-left', Cell: (row) => row.original.win + row.original.win2},

*/
    return (
        <div>
            <div>
            <h3>{Main.Instance.Location.endsWith('s') ? 'Core Transactions' : 'Settlement Report'}</h3>
            {Main.Instance.DatabaseDataState.coreTransactionsDetailedData === undefined ?
            <>
            {Main.Instance.Location.endsWith('s') ?<h4>{Assets.Overview}</h4> : void 0}
            <div className = "clearfix">
                <div className = "fl mr">{getYears()}</div>
                <div className = "fl mr">{getMonths()}</div>
                <div className = "fl mr">{getDates()}</div>
                {/*<div className = "fr mr">{getGames()}</div>*/}
            </div>
            <br/>
            {Main.Instance.DatabaseDataState.coreTransactionsAggregatedStream !== undefined ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.coreTransactionsAggregatedStream}
                columns = {[
                    Main.Instance.DatabaseDataState.coreTransactionsDate !== undefined ?
                    {width: 120, Header: Assets.GameName, accessor: 'game', headerClassName: 'th-left'} : {width: 120, Header: Assets.PeriodDate, accessor: 'date', headerClassName: 'th-left'},
                    {width: 100, Header: Assets.Bet, accessor: 'bet', headerClassName: 'th-left', Cell: (row) => (row.value ? row.value/100 : 0) + '€'},
                    {width: 100, Header: Assets.Win, accessor: 'win', headerClassName: 'th-left', Cell: (row) => (row.value ? row.value/100 : 0) + '€'},
                    {width: 100, Header: Assets.BonusCosts, accessor: '', headerClassName: 'th-left', Cell: (row) => '0'+ '€'},
                    {width: 120, Header: Assets.JackpotCosts, accessor: '', headerClassName: 'th-left', Cell: (row) => '0'+ '€'},
                    {width: 150, Header: Assets.Free_RoundCosts, accessor: '', headerClassName: 'th-left', Cell: (row) => '0'+ '€'},
                    {width: 100, Header: <span title = {Assets.ReturnToPlayer}>{Assets.ReturnToPlayerAbbrev}</span>, accessor: '', headerClassName: 'th-left', Cell:
                        (row) => row.original.bet === undefined || row.original.bet === 0 ? '-' : (row.original.win/row.original.bet*100).toFixed(2) + '%'},
                    {width: 100, Header: <span title = {Assets.GrossGamingRevenue}>{Assets.GrossGamingRevenueAbbrev}</span>, accessor: '', headerClassName: 'th-left', Cell: (row) => 
                        <span className = {row.original.bet - row.original.win > 0 ? 'label label-success labell' : 'label label-danger labell'}>
                        {((row.original.bet - row.original.win)/100).toFixed(2) + '€'}</span>},
                    {show: false, width: 50, Header: Assets.Tax + ' %', accessor: '', headerClassName: 'th-left', Cell: (row) => '14%'},
                    {show: false, width: 100, Header: <span title = {Assets.NetGamingRevenue}>{Assets.NetGamingRevenueAbbrev}</span>, accessor: '', headerClassName: 'th-left', Cell: (row) => 
                    <span className = {row.original.bet - row.original.win > 0 ? 'label label-success labell' : 'label label-danger labell'}>
                        {row.original.bet - row.original.win > 0 ?
                        ((row.original.bet - row.original.win) * 0.0086).toFixed(2) : (0.01 * (row.original.bet - row.original.win)).toFixed(2) + '€'}</span>},
                    {show: Main.Instance.DatabaseDataState.coreTransactionsDate !== undefined && Main.Instance.Location.endsWith('s'),
                    width: 200, Header: Assets.ShowSingleGamesDataFor, accessor: 'game', headerClassName: 'th-left', Cell: (row) => getDetailedDataButton(row.value)},
                ]}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize = {10}
                resizable = {true}
                onPageChange = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {lastActionTime: new Date().getTime()})}
            />
            : DbRoutes.getCoreTransactions()}
            </> :
            <div style = {{position: 'relative'}}>
                <button
                    style = {{position: 'absolute', top: '0px', right: '0px'}}
                    className = "btn btn-primary btn-xs"
                    onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {coreTransactionsDetailedData: undefined})}
                >
                    {Assets.BackToOverview}
                </button>
                <h4>
                    {Assets.SingleGamesData}
                    <span className = "label label-success labell ml">{Main.Instance.DatabaseDataState.coreTransactionsDistinctDates[Main.Instance.DatabaseDataState.coreTransactionsDate].date}</span>
                    <span className = "label label-info labell ml">{Main.Instance.DatabaseDataState.coreTransactionsDetailedData}</span>
                </h4>
                {Main.Instance.DatabaseDataState.coreTransactionsStream !== undefined ?
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.coreTransactionsStream}
                    columns = {[
                        {width: 55, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                        {width: 140, Header: Assets.SessionID, accessor: 'playerid', headerClassName: 'th-left', Cell: (row) => getSessionID (+row.value, row.original.timestamp)},
                        {show: false, width: 50, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left'},
                        {show: false, width: 30, Header: <span title = {Assets.SiteID}>{Assets.SiteIDShort}</span>, accessor: 'siteid', headerClassName: 'th-left'},
                        {width: 75, Header: Assets.PlayerId, accessor: 'playerid', headerClassName: 'th-left'},
                        {width: 110, Header: Assets.GameID, accessor: 'gamename', headerClassName: 'th-left'},
                        {show: false, width: 100, Header: Assets.GameID, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getGameID(row.value)},
                        {show: false, width: 175, Header: Assets.Timestamp, accessor: 'timestamp', headerClassName: 'th-left'},
                        {width: 80, Header: Assets.Status, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getStatus(row.value)},
                        {width: 100, Header: Assets.GameStart, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTimestampStart(row.value)},
                        {width: 110, Header: Assets.StakesPlaced, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTimestampEnd(row.value)},
                        {width: 100, Header: Assets.GameEnd, accessor: 'timestamp', headerClassName: 'th-left', Cell: (row) => 
                        <div><div>{row.value.substring (0,10)}</div><div>{row.value.substring (11,23)}</div></div>},
                        {width: 100, Header: Assets.BalanceInit, accessor: 'accountresponse', headerClassName: 'th-left', Cell: (row) => (parseFloat(getBalance0(row.value))/100).toFixed(2) + '€'},
                        {width: 100, Header: Assets.BAfterBet, accessor: 'accountresponse', headerClassName: 'th-left', Cell: (row) => (parseFloat(getBalance1(row.value))/100).toFixed(2) + '€'},
                        {width: 100, Header: Assets.BAfterEnd, accessor: 'accountresponse', headerClassName: 'th-left',
                        Cell: (row) => getBalance2(row.value) === '-' ? '-' : (parseFloat(getBalance2(row.value))/100).toFixed(2) + '€'},
                        {width: 70, Header: Assets.Bet, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => (parseFloat(getBetCurrent(row.value))/100).toFixed(2) + '€'},
                        {show: false, width: 50, Header: Assets.min, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetMin(row.value)},
                        {show: false, width: 50, Header: Assets.max, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetMax(row.value)},
                        {show: false, width: 50, Header: Assets.Start.toLowerCase(), accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetStart(row.value)},
                        {show: false, width: 50, Header: Assets.ChangeShort.toLowerCase(), accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getBetChange(row.value)},
                        {width: 50, Header: Assets.Lines, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesCurrent(row.value)},
                        {width: 80, Header: Assets.TotalBet, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => (parseFloat(getTotalBet(row.value))/100).toFixed(2) + '€'},
                        {show: false, width: 50, Header: Assets.min, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesMin(row.value)},
                        {show: false, width: 50, Header: Assets.max, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesMax(row.value)},
                        {show: false, width: 50, Header: Assets.Start.toLowerCase(), accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesStart(row.value)},
                        {show: false, width: 50, Header: Assets.ChangeShort.toLowerCase(), accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getLinesChange(row.value)},
                        {width: 60, Header: Assets.IsWin + '?', accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getIsWin(row.value)},
                        {width: 100, Header: Assets.WinAmount, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => 
                        <span className = {parseFloat(getWin(row.value)) > 0 ? 'label label-danger labell':'label label-primary labell'}>{(parseFloat(getWin(row.value))/100).toFixed(2) + '€'}</span>},
                        {width: 100, Header: Assets.NetAmount, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => 
                        <span className = {parseFloat(getNet(row.value)) > 0 ? 'label label-success labell':'label label-danger labell'}>{(parseFloat(getNet(row.value))/100).toFixed(2) + '€'}</span>},
                        {show: false, Header: Assets.Screen, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getScreen(row.value)},
                        {show: false, width: 100, Header: Assets.TotalBets, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalBets(row.value)},
                        {show: false, width: 100, Header: Assets.TotalRatio, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalRatio(row.value)},
                        {show: false, width: 100, Header: Assets.TotalRTP, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalRTP(row.value)},
                        {show: false, width: 100, Header: Assets.TotalSpins, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalSpins(row.value)},
                        {show: false, width: 150, Header: Assets.TotalWinAmount, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalWinAmount(row.value)},
                        {show: false, width: 150, Header: Assets.TotalWinningSpins, accessor: 'engineresponse', headerClassName: 'th-left', Cell: (row) => getTotalWinningSpins(row.value)},
                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    defaultPageSize = {10}
                    resizable = {true}
                    onPageChange = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {lastActionTime: new Date().getTime()})}
                /> : void 0}
            </div>}
            </div>
        </div>
    )
}

function getSessionID(playerid: number, timestamp: string): string {
    // console.log (Main.Instance.DatabaseDataState.coreTransactionsStreamInit)
    // (item['timestamp'].substring(0,10) + ' ' + item['timestamp'].substring(11,23))
    // return Main.Instance.DatabaseDataState.coreTransactionsStreamInit[0]
    for (const item of Main.Instance.DatabaseDataState.coreTransactionsStreamInit) {
        if (+item['playerid'] === playerid && timestamp > item['timestamp']) {
            return item['sessionid']
        }
    }
    return '' // 'sessionid'
}

function getDetailedDataButton(game: string): JSX.Element {
    return (
        <button
            className = "btn btn-info btn-sm labell"
            onClick = {() => {
                Logic.Type.New (Main.Instance.DatabaseDataState, {coreTransactionsDetailedData: game})
                DbRoutes.getCoreTransactionsDetailedData()
            }}
        >
            {game}
        </button>
    )
}


function getIsWin(engineresponse: {isWin: boolean}): JSX.Element {
    if (engineresponse && engineresponse && engineresponse.isWin !== undefined) {
        return <div className = {engineresponse.isWin.toString() === 'true' ? 'label label-danger labell' : 'label label-success labell'}>
        {engineresponse.isWin.toString() === 'true' ? Assets.Win.toLowerCase() : Assets.Loss.toLowerCase()}</div>
    } else { return <div>-</div> }
}

function getWin(engineresponse: {winDescription: {totalWin: number}}): string {
    if (engineresponse && engineresponse && engineresponse.winDescription && engineresponse.winDescription.totalWin !== undefined) {
        return engineresponse.winDescription.totalWin.toString()
    } else { return '-' }
}

function getNet(engineresponse: {winDescription: {totalWin: number}, bet: {current: number, lines: {current: number}}}): string {
    if (engineresponse && engineresponse && engineresponse.winDescription && engineresponse.winDescription.totalWin !== undefined &&
        engineresponse.bet && engineresponse.bet.current !== undefined) {
        return (engineresponse.bet.lines.current * engineresponse.bet.current - engineresponse.winDescription.totalWin).toString()
    } else { return '-' }
}

function getScreen(engineresponse: {screen: number[][]}): JSX.Element {
    if (engineresponse && engineresponse && engineresponse.screen) {
        const screen: JSX.Element[] = []
        for (const item of engineresponse.screen) {
            const screens: JSX.Element[] = []
            for (const Item of item) {
                screens.push (<span key = {screens.length}>{Item}&nbsp;</span>)
            }
            screen.push (<div style = {{height: '16px'}} key = {screen.length}>{screens}</div>)
        }
        return <div style = {{marginTop: '-16px', height: '70px'}}>{screen}</div>
    } else { return <div>-</div> }
}

// function getGameID(engineresponse: {gameId: string}): string {
//     if (engineresponse && engineresponse && engineresponse.gameId)
//         return engineresponse.gameId
//     else return '-'
// }

function getTotalRatio(engineresponse: {totals: {ratio: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.ratio !== undefined) {
        return engineresponse.totals.ratio.toFixed(6)
    } else { return '-' }
}

function getTotalRTP(engineresponse: {totals: {rtp: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.rtp !== undefined) {
        return engineresponse.totals.rtp.toFixed(6)
    } else { return '-' }
}

function getTotalSpins(engineresponse: {totals: {spins: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.spins !== undefined) {
        return engineresponse.totals.spins.toString()
    } else { return '-' }
}

function getTotalWinAmount(engineresponse: {totals: {winAmount: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.winAmount !== undefined) {
        return engineresponse.totals.winAmount.toString()
    } else { return '-' }
}

function getTotalWinningSpins(engineresponse: {totals: {winningSpins: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.winningSpins !== undefined) {
        return engineresponse.totals.winningSpins.toString()
    } else { return '-' }
}

function getTotalBets(engineresponse: {totals: {bets: number}}): string {
    if (engineresponse && engineresponse.totals && engineresponse.totals.bets !== undefined) {
        return engineresponse.totals.bets.toString()
    } else { return '-' }
}

function getTotalBet(engineresponse: {bet: {current: number, lines: {current: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.current !== undefined && engineresponse.bet.current !== undefined) {
        return (engineresponse.bet.lines.current * engineresponse.bet.current).toString()
    } else { return '-' }
}

function getLinesCurrent(engineresponse: {bet: {lines: {current: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.current !== undefined) {
        return engineresponse.bet.lines.current.toString()
    } else { return '-' }
}

function getLinesMin(engineresponse: {bet: {lines: {min: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.min !== undefined) {
        return engineresponse.bet.lines.min.toString()
    } else { return '-' }
}

function getLinesMax(engineresponse: {bet: {lines: {max: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.max !== undefined) {
        return engineresponse.bet.lines.max.toString()
    } else { return '-' }
}

function getLinesStart(engineresponse: {bet: {lines: {start: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.start !== undefined) {
        return engineresponse.bet.lines.start.toString()
    } else { return '-' }
}

function getLinesChange(engineresponse: {bet: {lines: {change: number}}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.lines && engineresponse.bet.lines.change !== undefined) {
        return engineresponse.bet.lines.change.toString()
    } else { return '-' }
}

function getBetStart(engineresponse: {bet: {start: number}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.start !== undefined) {
        return engineresponse.bet.start.toString()
    } else { return '-' }
}

function getBetChange(engineresponse: {bet: {change: number}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.change !== undefined) {
        return engineresponse.bet.change.toString()
    } else { return '-' }
}


function getBetMin(engineresponse: {bet: {min: number}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.min !== undefined) {
        return engineresponse.bet.min.toString()
    } else { return '-' }
}

function getBetMax(engineresponse: {bet: {max: number}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.max !== undefined) {
        return engineresponse.bet.max.toString()
    } else { return '-' }
}

function getBetCurrent(engineresponse: {bet: {current: number}}): string {
    if (engineresponse && engineresponse.bet && engineresponse.bet.current !== undefined) {
        return engineresponse.bet.current.toString()
    } else { return '-' }
}

function getTimestampStart(engineresponse: {start: string}): JSX.Element {
    if (engineresponse && engineresponse.start) {
        return <div><div>{engineresponse.start.substring(0,10)}</div><div>{engineresponse.start.substring(11,23)}</div></div>
    } else { return <div>-</div> }
}

function getTimestampEnd(engineresponse: {end: string}): JSX.Element {
    if (engineresponse && engineresponse.end) {
        return <div><div>{engineresponse.end.substring(0,10)}</div><div>{engineresponse.end.substring(11,23)}</div></div>
    } else { return <div>-</div> }
}

function getGameID(engineresponse: {gameId: string}): string {
    if (engineresponse && engineresponse.gameId) {
        return engineresponse.gameId
    } else { return '-' }
}

function getStatus(engineresponse: {}): JSX.Element {
    return <span className = "label label-success">{Assets.Completed.toLowerCase()}</span>
}

function getBalance0(accountresponse: {responses: Array<{balance: string, playerId: string, resolved: boolean}>}): string {
    if (accountresponse && accountresponse.responses && accountresponse.responses[0] && accountresponse.responses[0].resolved) {
        return accountresponse.responses[0].balance
    } else { return '-' }
}

function getBalance1(accountresponse: {responses: Array<{balance: string, playerId: string, resolved: boolean}>}): string {
    if (accountresponse && accountresponse.responses && accountresponse.responses[1] && accountresponse.responses[1].resolved) {
        return accountresponse.responses[1].balance
    } else { return '-' }
}

function getBalance2(accountresponse: {responses: Array<{balance: string, playerId: string, resolved: boolean}>}): string {
    if (accountresponse && accountresponse.responses && accountresponse.responses[2] && accountresponse.responses[2].resolved) {
        return accountresponse.responses[2].balance
    } else { return getBalance1(accountresponse) }
}