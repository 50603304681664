import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import ReactTable from 'react-table'
import {SelectTimePeriod} from './SelectTimePeriod'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import {SelectPlayer} from './SelectPlayer'
// import { isLocalhost } from 'src/Logic/Utils'
// import * as DbRoutes from '../../../Logic/Database/DbRoutes'

function getTotalWagerBonus(): string {
    let sum: number = 0
    for (const item of Main.Instance.DatabaseDataState.playerReports) {
        sum += item.wagerBonus
    }
    return sum.toFixed(2)
}

function getTotalWinBonus(): string {
    let sum: number = 0
    for (const item of Main.Instance.DatabaseDataState.playerReports) {
        sum += item.winBonus
    }
    return sum.toFixed(2)
}

function getTotalWagerReal(): string {
    let sum: number = 0
    for (const item of Main.Instance.DatabaseDataState.playerReports) {

        sum += item.wagerReal
    }

    return sum.toFixed(2)
}

function getTotalWinReal(): string {
    let sum: number = 0
    for (const item of Main.Instance.DatabaseDataState.playerReports) {
        sum += item.winReal
    }
    return sum.toFixed(2)
}

export function PlayerReports(props: React.Props<any>): JSX.Element {
/*
    DbRoutes.wsReinitialize()
    if (!Main.Instance.DatabaseDataState.playersInfo) {
        return <div>{Assets.LoadingData}</div>
    }
*/
    if (Main.Instance.GGRRevenueState.initial === true && Main.Instance.GGRRevenueState.lastYear) {
        Logic.Type.New (Main.Instance.GGRRevenueState, {
            yearFrom: Main.Instance.GGRRevenueState.lastYear,
            yearTo: Main.Instance.GGRRevenueState.lastYear,
            initial: false
        })
    }
    // console.log(Main.Instance.DatabaseDataState.playerReports)
    // console.log(Main.Instance.DatabaseDataState.playersInfo)
/*
    if (Main.Instance.LoginState.unused3 === false && isLocalhost() === false) {
        return (
        <div>
            <h2>{Assets.YouDontHaveSecurityRightsToAccessData}</h2>
        </div>
        )
    } else {
*/
    return (
        <div>
            <div>
                <h2>{Assets.Player_ + ' ' + Assets.Reports}</h2>
            </div>
            <div>
                {<SelectPlayer/>}
                <SelectTimePeriod type = {4}/>
                <br/>
                {Main.Instance.DatabaseDataState.playerReports !== undefined && Main.Instance.DatabaseDataState.playerReports !== null ? 
                <div>
                    <ReactTable
                    data = {Main.Instance.DatabaseDataState.playerReports}
                    columns = {[
                        {width: 120, Header: Assets.Date, accessor: '', headerClassName: 'th-left', Cell: (row) => <span>{row.original.year}-{row.original.month}-{row.original.day}</span>, Footer: <b>{Assets.Total}:</b>},
                        {Header: Assets.Username, accessor: 'username', headerClassName: 'th-left'},
                        {width: 50, Header:  <span title = {Assets.TestAccount}>{Assets.Test}</span>, accessor: 'isTestAccount', headerClassName: 'th-center', className: 'th-center', Cell: (row) => <FontAwesomeIcon style = {{color: row.value === true ? '#28a745' : '#dc3545'}} icon = {row.value === true ? faCheck : faTimes}/>},
                        {width: 80, Header:  Assets.Deposit, accessor: 'deposit', headerClassName: 'th-left'},
                        {width: 80, Header:  Assets.Withdraw, accessor: 'withdraw', headerClassName: 'th-left'},
                        {width: 120, Header:  Assets.BalanceBefore, accessor: 'balanceBefore', headerClassName: 'th-left'},
                        {width: 120, Header:  Assets.BalanceAfter, accessor: 'balanceAfter', headerClassName: 'th-left'},
                        {
                            Header: Assets.Bonus,
                            headerClassName: 'risk-default-light',
                            columns: [
                                {width: 100, Header:  Assets.Cancelled, accessor: 'bonusCanceled', headerClassName: 'th-left'},
                                {width: 100, Header:  Assets.Converted, accessor: 'bonusConverted', headerClassName: 'th-left'},
                                {width: 100, Header:  Assets.Received, accessor: 'bonusReceived', headerClassName: 'th-left'},
                            ]
                        },
                        
                        {
                            Header: Assets.BonusMoney,
                            headerClassName: 'risk-default',
                            columns: [
                                {width: 120, Header: Assets.TotalWager, accessor: 'wagerBonus', headerClassName: 'th-left', Footer: <b>{getTotalWagerBonus()}</b>},
                                {width: 120, Header: Assets.TotalWin, accessor: 'winBonus', headerClassName: 'th-left', Footer: <b>{getTotalWinBonus()}</b>},
                            ]
                        },
                        {
                            Header: Assets.RealMoney,
                            headerClassName: 'risk-default-light',
                            columns: [
                                {width: 120, Header: Assets.TotalWager, accessor: 'wagerReal', headerClassName: 'th-left', Footer: <b>{getTotalWagerReal()}</b>},
                                {width: 120, Header: Assets.TotalWin, accessor: 'winReal', headerClassName: 'th-left', Footer: <b>{getTotalWinReal()}</b>},
                            ]
                        }
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    />
                </div>
                : void 0}
            </div>
        </div>
    )
    // }
}