import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {getIssuesTable} from '../RiskComplianceStore/ResponsibleGaming'
import History from '../RiskComplianceStore/history'
import {TimelineEntrySubType/*, IssueTypes*/} from '../../../Logic/Database/DatabaseData'
import CSCallbackResolvePopup from '../RiskComplianceStore/callbackResolvePopup'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import {GetAll, GetUnresolved, GetResolved} from '../RiskComplianceStore/RiskManagement'
import {Logic} from '../../../Logic/Type'
import {/*getIssuesNavigation, */getIssuesShow, getIssuesFilter} from '../MainDashboard/Dashboard'
import {getControlPanel3, disabledIssueType, getUnresolvedSubCategoryButtons, getUnresolvedPaginatedIssues} from '../MainDashboard/Dashboard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faExclamationCircle, faTasks} from '@fortawesome/free-solid-svg-icons'

export function AccountStoreKYCIssues(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    const issues: number[] = [GetAll(TimelineEntrySubType.KnowYourCustomer), GetResolved(TimelineEntrySubType.KnowYourCustomer), GetUnresolved(TimelineEntrySubType.KnowYourCustomer)]

    return (
        <div>
            {Main.Instance.RiskManagementState.history ? <History/> : void 0}
            {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/> : void 0}
            <h2>{Assets.KnowYourCustomer + ' ' + Assets.Issues}&nbsp;&nbsp;<small>{Assets.KnowYourCustomer}</small></h2>
            <div className = "clearfix mt-35">
                <button
                    style = {{marginRight: '0px'}}
                    className = {Main.Instance.RiskManagementState.filterResolved === undefined ? 'btn btn-warning btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: undefined,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.KnowYourCustomer :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.Other3:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues3Limit,
                            TimelineEntrySubType.KnowYourCustomer,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
                        )
*/

                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: undefined})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, 1)

                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTasks}/>&nbsp;&nbsp;
                    {Assets.AllIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[0] > 0 ? issues[0] > 0 ? {backgroundColor: '#777', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[0]}</span>
                </button>
                <button
                    className = {Main.Instance.RiskManagementState.filterResolved === true ? 'btn btn-success btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: true,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.KnowYourCustomer :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.Other3:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues3Limit,
                            TimelineEntrySubType.KnowYourCustomer,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
                        )
*/
                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: true})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, 1)

                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faCheckCircle}/>&nbsp;&nbsp;
                    {Assets.ResolvedIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[1] > 0 ? issues[1] > 0 ? {backgroundColor: '#777', color: '#fff'}: {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[1]}</span>
                </button>
                <button
                    className = {Main.Instance.RiskManagementState.filterResolved === false ? 'btn btn-danger btn-xs fr' : 'btn btn-default btn-xs fr'} 
                    onClick = {() => {
/*
                        DbRoutes.getPaginatedIssues(
                            {
                                resolved: false,
                                minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.KnowYourCustomer :
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
                                maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                              IssueTypes.Other3:
                                              Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
                            },
                            1,
                            Main.Instance.DatabaseDataState.issues3Limit,
                            TimelineEntrySubType.KnowYourCustomer,
                            Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
                        )
*/
                        Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1})
                        Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: false})
                        getUnresolvedPaginatedIssues(Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, 1)

                    }}
                >
                    <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faExclamationCircle}/>&nbsp;&nbsp;
                    {Assets.UnresolvedIssues}&nbsp;&nbsp;
                    <span className="badge badge-light" style = {issues[2] > 0 ? /* issues[2] > 0 ? {backgroundColor: '#d9534f', color: '#fff'}:*/ {backgroundColor: '#f0ad4e', color: '#fff'}: {}}>{issues[2]}</span>
                </button>
            </div>
            <br/>
            {/*getIssuesNavigation()*/}
            {getIssuesShow(false)}
            {getIssuesFilter(3, Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount)}
            {Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved === 9999 || disabledIssueType(Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved) ?
            getUnresolvedSubCategoryButtons(3, Main.Instance.RiskManagementState.filterResolved === undefined ? Main.Instance.DatabaseDataState.issuesCount : Main.Instance.RiskManagementState.filterResolved === true ? Main.Instance.DatabaseDataState.resolvedIssuesCount : Main.Instance.DatabaseDataState.unresolvedIssuesCount) : void 0}
            {getControlPanel3(false)}
            {getIssuesTable(false, Main.Instance.DatabaseDataState.issues3, TimelineEntrySubType.KnowYourCustomer/*, true*/)}
        </div>
    )
}