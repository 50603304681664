import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
import {IsBuildOddyzzey, IsBuildJackpotsCH, IsBuildPasino} from '../../../Logic/Utils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function PromoStoreWrapper(props: React.Props<any>) {
    // console.log (Main.Instance.Location)
    // console.log (Main.Instance.Location === RouterObjects['gamification'].path)
    // console.log (Main.Instance.Location === RouterObjects['gamification'].children['Raffles'].path)
    
    // console.log (JSON.stringify(RouterObjects))
    // console.log (RouterObjects)
    // console.log (RouterObjects['gamification'])
    // console.log (RouterObjects['general_Settings'])
    if(RouterObjects['gamification'] && Main.Instance.Location === RouterObjects['gamification'].path && Main.Instance.Back === false) {
        let path
        let Path: string
        if (IsBuildJackpotsCH() || IsBuildPasino()) {
            // console.log (1)
            path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Raffles'].path
            Path = RouterObjects['gamification'].path.substring (1, RouterObjects['gamification'].path.length) + ' -> ' +
                   RouterObjects['gamification'].children['Raffles'].path.substring(1, RouterObjects['gamification'].children['Raffles'].path.length)
        } else if (IsBuildOddyzzey()) {
            // console.log (2)
            path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Rewards'].path
            Path = RouterObjects['gamification'].path.substring (1, RouterObjects['gamification'].path.length) + ' -> ' +
                   RouterObjects['gamification'].children['Rewards'].path.substring(1, RouterObjects['gamification'].children['Rewards'].path.length)
        } else {
            if (RouterObjects['gamification'].children['Languages_Management']) {
                // console.log (3)
                path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Languages_Management'].path
                Path = RouterObjects['gamification'].path.substring (1, RouterObjects['gamification'].path.length) + ' -> ' +
                       RouterObjects['gamification'].children['Languages_Management'].path.substring(1, RouterObjects['gamification'].children['Languages_Management'].path.length)    
            } else {
                // console.log (4)
                path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Rewards'].path
                Path = RouterObjects['gamification'].path.substring (1, RouterObjects['gamification'].path.length) + ' -> ' +
                       RouterObjects['gamification'].children['Rewards'].path.substring(1, RouterObjects['gamification'].children['Rewards'].path.length)    
            }
        }
/*
        else if (IsBuildBettoday()) path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Languages_Management'].path
        else path = RouterObjects['gamification'].path + RouterObjects['gamification'].children['Currencies_Management'].path
*/
        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')
        DbRoutes.insertAdminLog (Config.asAdminLogView, Path, {})
        Logic.Type.New(Main.Instance, {Location: path})
    }

    return(<div>{props.children}</div>)
}