import * as R from './route-classes'
import Router from './router'
const Routes = new Router()
Routes.New = {
    'get-general': new R.General(),
    'update-general': new R.General(),

    'delete-challenge': new R.Challenge(),
    'get-challenge': new R.Challenge(),
    'get-challenges': new R.Challenge(),
    'get-challenges-all': new R.Challenge(),
    'update-challenge': new R.Challenge(),
    'insert-challenge': new R.Challenge(),

    'get-tournaments': new R.Tournaments(),
    'get-tournaments-all': new R.Tournaments(),
    'get-tournament': new R.Tournament(),
    'delete-tournament': new R.Tournament(),
    'update-tournament': new R.UpdateTournament(),
    'insert-tournament': new R.InsertTournament(),
    'tournament-results': new R.TournamentResults(),
    'daily-tournament-results': new R.TournamentResults(),
    'tournament-optin': new R.TournamentOptin(),

    'delete-reward': new R.Reward(),
    'get-rewards': new R.Rewards(),
    'get-rewards-all': new R.Rewards(),
    'insert-reward': new R.Reward(),
    'update-reward': new R.Reward(),

    'delete-mission': new R.Mission(),
    'get-missions': new R.Missions(),
    'get-missions-all': new R.Missions(),
    'get-mission': new R.Mission(),
    'update-mission': new R.UpdateMission(),
    'insert-mission': new R.InsertMission(),

    'delete-level': new R.Level(),
    'get-levels': new R.Levels(),
    'get-levels-all': new R.Levels(),
    'get-level': new R.Level(),
    'update-level': new R.Level(),
    'insert-level': new R.Level(),
    
    'get-user-info': new R.UserInfo(),
    'get-users-info': new R.UsersInfo(),
    'get-player-ids': new R.UsersInfo(),

    'get-store': new R.Store(),
    'get-stores': new R.Store(),
    'get-stores-all': new R.Store(),
    'insert-store': new R.Store(),
    'update-store': new R.Store(),
    'delete-store': new R.Store(),

    'basic-get': new R.Any(),
    'basic-insert': new R.Any(),
    'basic-update': new R.Any(),
    'basic-delete': new R.Any(),
    'get-issues2': new R.Any(),
    'get-player-cards': new R.Any(),
    'get-player-card': new R.Any(),
    'update-player-card': new R.Any(),
    'get-player-timeline': new R.Any(),
    'get-unresolved-player-timeline': new R.Any(),
    'get-session-transactions': new R.Any(),
    'get-recent-events': new R.Any(),
    'get-expiring-accounts-by-days': new R.Any(),
    'get-expiring-accounts': new R.Any(),
    'get-expired-accounts': new R.Any(),
    'update-issue': new R.Any(),
    'get-questionnaire-info': new R.Any(),
    // 'get-logged-in-players': new R.Any(),
    'get-pep-players': new R.Any(),
    'get-reports': new R.Any(),
    'get-players-info': new R.Any(),
    'get-playing-info': new R.Any(),
    'insert-issue-admin': new R.Any(),
    'manual-withdrawal': new R.Any(),
    'crif-check': new R.Any(),
    'resend-email': new R.Any(),
    'manual-deposit': new R.Any(),
    'communications-update': new R.Any(),
    'withdrawal-history-get': new R.Any(),
    'withdrawals-status-change': new R.Any(),
    'withdrawals-get': new R.Any(),
    'manual-check-blacklist': new R.Any(),
    'get-accounts-info' : new R.Any(),
// ---------------------------------------------------------
    'get-games': new R.General(),
    'get-game': new R.General(),
    'update-game': new R.General(),
    'delete-game': new R.General(),
    'insert-game': new R.General(),
    'get-games-data': new R.General(),
    'get-game-data': new R.General(),
    'update-game-data': new R.General(),
    'delete-game-data': new R.General(),
    'insert-game-data': new R.General(),

    'get-game-providers': new R.General(),
    'get-game-provider': new R.General(),
    'update-game-provider': new R.General(),
    'delete-game-provider': new R.General(),
    'insert-game-provider': new R.General(),

    'get-game-suppliers': new R.General(),
    'get-game-supplier': new R.General(),
    'insert-game-suppliers': new R.General(),
    'update-game-supplier': new R.General(),
    'delete-game-supplier': new R.General(),

    'get-labels': new R.General(),
    'get-label': new R.General(),
    'insert-labels': new R.General(),
    'update-label': new R.General(),
    'delete-label': new R.General(),

    'get-games-all': new R.General(),
    'get-game-providers-all': new R.General(),
    'get-game-suppliers-all': new R.General(),

    'get-jurisdictions': new R.General(),
    'get-jurisdiction': new R.General(),
    'insert-jurisdictions': new R.General(),
    'update-jurisdiction': new R.General(),
    'delete-jurisdiction': new R.General(),

    'get-categories': new R.General(),
    'get-category': new R.General(),
    'insert-categories': new R.General(),
    'update-category': new R.General(),
    'delete-category': new R.General(),

    // game store new routes
    'games/get-game-list-admin': new R.General(),
    'games/get-game-labels': new R.General(),
    'games/get-game-categories': new R.General(),
    // 'games/get-jurisdictions-list': new R.General(),
    'games/update-game': new R.General(),
    // 'games/insert-game': new R.General(),
    'games/get-devices': new R.General(),
    'games/get-game-provider-list': new R.General(),

// ---------------------------------------------------------
    // 'get-game-sessions': new R.General(),
    // 'get-game-session-transactions': new R.General(),
    // 'bonus-get-all': new R.General(),
    // 'bonus-activate': new R.General(),
    // 'bonus-cancel': new R.General(),
    // 'bonus-cancel-all': new R.General(),
    // 'bonus-add-manual-code': new R.General(),
    // 'bonus-add-manual': new R.General(),
    // 'bonus-triggered-info-get': new R.General(),
    'get-expiring-accounts-info': new R.General(),
    'get-issues2-count': new R.General(),
    'first-deposits-list': new R.General(),
    'get-tournament-leaderboard': new R.General(),
    // mychance new routes
    // 'get-missions-new': new R.General(),
    // 'get-levels-new': new R.General(),
    // 'insert-level-new': new R.General(),
    // 'update-level-new': new R.General(),
    // 'delete-level-new': new R.General(),
    'get-mission-leaderboard': new R.General(),
    'change-player-level': new R.General(),
    'cashback-monday-optins-get': new R.General(),
    'get-users-info-filtered': new R.General(),
    'get-store-purchases': new R.General(),
    // bonus new routes
    'bonus/get-bonuses': new R.Any(),
    'bonus/get-campaigns': new R.Any(),
    'bonus/get-free-game-settings-list': new R.Any(),
    'bonus/save-bonus': new R.Any(),
    'bonus/save-campaign': new R.Any(),
    'bonus/save-free-game-settings': new R.Any(),
    'bonus/delete-bonus': new R.Any(),
    'bonus/delete-campaign': new R.Any(),
    'bonus/delete-free-game-setting': new R.Any(),
    'bonus/update-campaign': new R.Any(),
    'bonus/bonus-add-manual-code': new R.Any(),
    // 'bonus/bonus-add-manual': new R.Any(),
    'bonus/get-player-granted-bonus-history': new R.Any(),
    'bonus/cancel-bonus': new R.Any(),
    'bonus/bonus-cancel-all': new R.Any(),
    'bonus/bonus-triggered-info-get': new R.Any(),
    // 'bonus/bonus-add-manual-deposit': new R.Any(),
    'bonus/get-globally-excluded-tags': new R.Any(),
    'bonus/set-globally-excluded-tags': new R.Any(),
    'bonus/bonus-add-manual-custom': new R.Any(),
    'bonus/set-bonus-active-status': new R.Any(),
    // New update player card data routes
    'update-payment-data': new R.Any(),

    // wbo routes - moved to admin module
    'wbo/admin/game-sessions-get': new R.General(),
    'wbo/admin/game-transactions-get': new R.General(),
    'wbo/admin/withdraw-get': new R.General(),
    'wbo/admin/transactions-get': new R.General(),
    'wbo/admin/game-reports-real': new R.General(),
    'wbo/admin/game-reports-test': new R.General(),
    'wbo/admin/player-reports': new R.General(),

    // auth new routes
    'auth/logged-in-players-get-v2': new R.Any(),
    // admin tables new routes
    'get-processed-payments': new R.Any(),
}
export default Routes
