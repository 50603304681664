import * as React from 'react'
// import ReactTable from 'react-table'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main';
import * as Config from '../../../Logic/Config'
import {Logic} from '../../../Logic/Type'
import {isUserAdmin, numberWithSpaces, isUserRFSpecialist} from '../../../Logic/Utils'
import { SectionButtonKYC, RiskLevel/*, PlayerCardButtons*/} from '../../../Logic/RiskComplianceStore/RiskManagement';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserSlash, faUserClock,faFileUpload, faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
// import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
// import {getComments, getPlayerData} from './RiskManagement'
// import {isUserAdmin, isUserRFSpecialist} from '../../../Logic/Utils'
// import ResolveKYCUploadPopup from './resolveKYCUploadPopup'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {getIssuesTable} from './ResponsibleGaming'
import {TimelineEntrySubType, AccountStatus} from '../../../Logic/Database/DatabaseData'
import CSCallbackResolvePopup from './callbackResolvePopup'
import History from './history'
import {GetLegend, InsideTitle, XButton, handleNumber/*, getPlayerData*/, Update, toggleCurrency, handleSwitch, riskLevelSlider, AccountStatusSlider, GetUnresolved} from './RiskManagement'
import MaxAmountWallet from './maxAmountWalletPopup';
import Switch from 'react-toggle-switch'
import ReactTable from 'react-table'
import TempAccountStatusPopup from './tempAccountStatusPopup';
import NoPlayerActivityModal from './noPlayerActivityPopup';
import LargeValueTransactionModal from './largeValueTransactionPopup';
import * as TableUtils from '../../Reusables/TableUtils'
import {getControlPanel3} from '../MainDashboard/Dashboard'
// import Socket from '../../../Socket/socket-init'
// import * as Config from '../../../Logic/Config'
/*
export function XButton(): JSX.Element {
    return (
        <button
            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonKYC: SectionButtonKYC.Nothing})}
            style = {{position: 'absolute', marginRight: '0px', right: '2px', top: '-1px', background: 'transparent', border: '0px', padding: '0px'}}
            className = "btn btn-default btn-sm"
        >
            <i className = {Assets_.faCancelButtonClass}/>
        </button>
    )
}
*/
/*
export function InsideTitle (text: string, icon: IconDefinition): JSX.Element {
    return (
        <>
        <span
            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
            className = "label label-primary"
        >
            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {icon}/>
            {text}
        </span>
        </>
    )
}
*/
export function KnowYourCustomer(less: boolean): JSX.Element {
    DbRoutes.wsReinitialize()
    return (
        <div>
            {less ? void 0 :
            <div className = "clearfix">
                <h2 className = "fl">{Assets.KnowYourCustomer} ({Assets.KnowYourCustomerShort})</h2>
                {GetLegend()}
                <br/>
            </div>}

            <div className = "clearfix" style = {{marginBottom: '10px'}}>
                {getKYCGroupsButtons(less)}
            </div>

            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.RecentUploads ?
            <div>
                {Main.Instance.RiskManagementState.history ? <History/> : void 0}
                {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/>: void 0}
                {/*Main.Instance.RiskManagementState.showAddKYCModal? <ResolveKYCUploadPopup/>: void 0*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {/*XButton()*/}
                        {InsideTitle(false, Assets.KYCIssues, faFileUpload)}
                        <button
                            style = {{marginRight: '0px'}}
                            className = {Main.Instance.RiskManagementState.filterResolved === undefined ? 'btn btn-primary btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: undefined})}
                        >
                            {Assets.AllIssues}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.filterResolved === true ? 'btn btn-success btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: true})}
                        >
                            {Assets.ResolvedIssues}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.filterResolved === false ? 'btn btn-warning btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: false})}                        
                        >
                            {Assets.UnresolvedIssues}
                        </button>
                        <br/>
                        <br/>
                        {/*getUploadsTable()*/}
                        {getControlPanel3(true)}
                        {getIssuesTable(false, Main.Instance.DatabaseDataState.issues3, TimelineEntrySubType.KnowYourCustomer)}
                    </div>
                </div>
            </div> : void 0}
            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.MaximumAmountOnPlayerWallet ?
            <div>
            {Main.Instance.RiskManagementState.showAddMaxAmountWallet? <MaxAmountWallet/>: void 0}
            {/*<h3>{Assets.largeValueWithdrawal}</h3>*/}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonKYC.Nothing})}
                        {InsideTitle(true,Assets.MaximumAmountOnPlayerWallet, faMoneyCheck)}
                        <br/>
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.maximumBalance ? Main.Instance.DatabaseDataState.maximumBalance : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.Currency, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency (row.value, row.index, 3)} className = "btn btn-default btn-xs">{row.value}</button></div>},
                                        {width: 150, Header: Assets.Limit, accessor: 'data.limit', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 10, true)}
                                                type = "text"
                                                value = {row.value !== undefined ? numberWithSpaces(row.value) : ''}
                                            />
                                        </div>},
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.AllowPlay, accessor: 'data.allowPlay', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 16)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowDeposit, accessor: 'data.allowDeposit', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 17)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowWithdraw, accessor: 'data.allowWithdraw', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 18)} on = {row.value} /></div>},
                                        {width: 110, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(5, row.index, row.value)}
                                        </button>},
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 25)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddMaximumAmount()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteMaximumAmount)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 5)},
                                    ],
                                },
                                
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
            : void 0}

            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.LargeValueWithdrawal ?
            <div>
            {Main.Instance.RiskManagementState.showAddLargeWithdrawal? <LargeValueTransactionModal deposit={false}/>: void 0}
            {/*<h3>{Assets.largeValueWithdrawal}</h3>*/}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonKYC.Nothing})}
                        {InsideTitle(true, Assets.LargeWithdrawals, faMoneyCheck)}
                        <br/>
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.largeValueWithdrawal ? Main.Instance.DatabaseDataState.largeValueWithdrawal : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.Currency, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency (row.value, row.index, 2)} className = "btn btn-default btn-xs">{row.value}</button></div>},
                                        {width: 150, Header: Assets.WithdrawalLimit, accessor: 'data.limit', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 6, true)}
                                                type = "text"
                                                value = {row.value !== undefined ? numberWithSpaces(row.value) : ''}
                                            />
                                        </div>},
                                        /*{width: 150, Header: 'Once per player', accessor: 'data.oncePerPlayer', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 4)} on = {row.value} /></div>},*/
                                        {width: 150, Header: Assets.OneTransaction, accessor: 'data.oneTransaction', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 3)} on = {row.value} /></div>},
        
                                        {width: 150, Header: Assets.WithinHours, accessor: 'data.withinHours', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                disabled = {row.original.oneTransaction}
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 7)}
                                                type = "number"
                                                value = {row.value !== undefined ? row.value : ''}
                                            />
                                        </div>},
                                        {width: 120, Header: <span title = {Assets.AccountStatus}>{Assets.AccountStatus}</span>, accessor: 'data.verificationStatus', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + AccountStatus[row.value]}
                                        >
                                            {Assets.accountStatus[row.value]}
                                            {/*AccountStatus[row.value] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                            AccountStatus[row.value] === 'BlockedFull' ? 'Blocked Full' :
                                            AccountStatus[row.value] === 'BlockedPartial' ? 'Blocked Partial' :
                                            AccountStatus[row.value]*/}
                                            {AccountStatusSlider(8, row.index, row.value)}
                                        </button>},
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: <span title = {Assets.AccountStatus}>{Assets.AccountStatus}</span>, accessor: 'data.newAccountStatus', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + AccountStatus[row.value]}
                                        >
                                            {Assets.accountStatus[row.value]}
                                            {/*AccountStatus[row.value] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                            AccountStatus[row.value] === 'BlockedFull' ? 'Blocked Full' :
                                            AccountStatus[row.value] === 'BlockedPartial' ? 'Blocked Partial' :
                                            AccountStatus[row.value]*/}
                                            {AccountStatusSlider(10, row.index, row.value)}
                                        </button>},                                        
/*
                                        {width: 120, Header: Assets.AllowPlay, accessor: 'data.allowPlay', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 29)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowDeposit, accessor: 'data.allowDeposit', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 30)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowWithdraw, accessor: 'data.allowWithdraw', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 31)} on = {row.value} /></div>},
*/
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(3, row.index, row.value)}
                                        </button>},
                                        {width: 150, Header: Assets.LetThrough, accessor: 'data.letThrough', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 39)} on = {row.value ? row.value: false} /></div>},
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 27)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddWithdrawalAlert()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteWithdrawalAlert)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 1)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
            : void 0}

            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.LargeValueDeposit ?
            <div>
            {Main.Instance.RiskManagementState.showAddLargeDeposit? <LargeValueTransactionModal deposit={true}/>: void 0}
            {/*<h3>{Assets.largeValueWithdrawal}</h3>*/}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonKYC.Nothing})}
                        {InsideTitle(true,Assets.LargeDeposits, faMoneyCheck)}
                        <br/>
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.largeValueDeposits ? Main.Instance.DatabaseDataState.largeValueDeposits : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.Currency, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency (row.value, row.index, 1)} className = "btn btn-default btn-xs">{row.value}</button></div>},
                                        {width: 150, Header: Assets.DepositLimit, accessor: 'data.limit', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 8, true)}
                                                type = "text"
                                                value = {row.value !== undefined ? numberWithSpaces(row.value) : ''}
                                            />
                                        </div>},
//                                        {width: 150, Header: 'Once per player', accessor: 'data.oncePerPlayer', headerClassName: 'th-left', Cell: (row) => 
//                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 24)} on = {row.value} /></div>},
                                        {width: 150, Header: Assets.OneTransaction, accessor: 'data.oneTransaction', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 23)} on = {row.value} /></div>},
        
                                        {width: 150, Header: Assets.WithinHours, accessor: 'data.withinHours', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                disabled = {row.original.oneTransaction}
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 9)}
                                                type = "number"
                                                value = {row.value !== undefined ? row.value : ''}
                                            />
                                        </div>},
                                        {width: 120, Header: <span title = {Assets.AccountStatus}>{Assets.AccountStatus}</span>, accessor: 'data.verificationStatus', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + AccountStatus[row.value]}
                                        >
                                            {Assets.accountStatus[row.value]}
                                            {/*AccountStatus[row.value] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                            AccountStatus[row.value] === 'BlockedFull' ? 'Blocked Full' :
                                            AccountStatus[row.value] === 'BlockedPartial' ? 'Blocked Partial' :
                                            AccountStatus[row.value]*/}
                                            {AccountStatusSlider(9, row.index, row.value)}
                                        </button>},
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: <span title = {Assets.AccountStatus}>{Assets.AccountStatus}</span>, accessor: 'data.newAccountStatus', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + AccountStatus[row.value]}
                                        >
                                            {Assets.accountStatus[row.value]}
                                            {/*AccountStatus[row.value] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                            AccountStatus[row.value] === 'BlockedFull' ? 'Blocked Full' :
                                            AccountStatus[row.value] === 'BlockedPartial' ? 'Blocked Partial' :
                                            AccountStatus[row.value]*/}
                                            {AccountStatusSlider(11, row.index, row.value)}
                                        </button>},

/*
                                        {width: 120, Header: Assets.AllowPlay, accessor: 'data.allowPlay', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 32)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowDeposit, accessor: 'data.allowDeposit', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 33)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowWithdraw, accessor: 'data.allowWithdraw', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 34)} on = {row.value} /></div>},
*/
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            // onClick = {() => handleRiskLevel(4, row.index, row.value)} 
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(4, row.index, row.value)}
                                        </button>,
                                        },
                                        {width: 150, Header: Assets.LetThrough, accessor: 'data.letThrough', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 38)} on = {row.value ? row.value: false} /></div>},
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 28)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddDepositAlert()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteDepositAlert)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 0)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
            : void 0}

            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.TemporaryAccountStatus ?
            <div>
            {Main.Instance.RiskManagementState.showAddTempAccountStatus? <TempAccountStatusPopup />: void 0}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonKYC.Nothing})}
                        {InsideTitle(true, Assets.ProvisionalAccountStatus, faUserClock)}
                        <br/>
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.temporaryAccountStatus ? Main.Instance.DatabaseDataState.temporaryAccountStatus : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Rules,
                                    columns: [
                                        {width: 100, Header: Assets.Currency, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency (row.value, row.index, 0)} className = "btn btn-default btn-xs">{row.value}</button></div>},
                                        /*
                                        {width: 120, Header: 'Deposit limit', accessor: 'data.limit', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {e => handleNumber (e.target.value, row.index, 2, true)}
                                                type = "text"
                                                value = {row.value !== undefined ? numberWithSpaces(row.value) : ''}
                                            />
                                        </div>},
                                        {width: 100, Header: 'Deposit sum', accessor: 'data.depositSum', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 0)} on = {row.value} /></div>},
                                        */
                                        {width: 120, Header: Assets.DaysOpened, accessor: 'data.daysOpened', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <input min = {0} className = "table-input-number" onChange = {(e) => handleNumber (e.target.value, row.index, 3)} type = "number" value = {row.value !== undefined ? row.value : ''}/></div>},
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        /*{width: 100, Header: 'Withdrawals', accessor: 'data.withdrawals', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 1)} on = {row.value} /></div>},*/
                                        {width: 130, Header: Assets.DeleteAccount + '?', accessor: 'data.delete', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 2)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.YearsDeletion, accessor: 'data.yearsBeforeDeletion', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left">
                                            <input
                                                disabled = {!row.original.delete}
                                                min = {0}
                                                className = "table-input-number"
                                                onChange = {(e) => handleNumber (e.target.value, row.index, 4)}
                                                type = "number"
                                                value = {row.value !== undefined ? row.value : ''}
                                            />
                                        </div>},
                                        {width: 130, Header: Assets.MonthsDeletion, accessor: 'data.monthsBeforeDeletion', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">
                                                <input
                                                    disabled = {!row.original.delete}
                                                    min = {0}
                                                    max = {11}
                                                    className = "table-input-number"
                                                    onChange = {(e) => handleNumber (e.target.value, row.index, 5)}
                                                    type = "number"
                                                    value = {row.value !== undefined ? row.value : ''}
                                                />
                                            </div>},
                                        {width: 120, Header: <span title = {Assets.AccountStatus}>{Assets.AccountStatus}</span>, accessor: 'data.verificationStatus', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + AccountStatus[row.value]}
                                        >
                                            {/*AccountStatus[row.value] === 'SuperVerified' ? 'Super Verified' : AccountStatus[row.value]*/}
                                            {Assets.accountStatus[row.value]}
                                            {AccountStatusSlider(10, row.index, row.value)}
                                        </button>},

                                        {width: 120, Header: Assets.AllowPlay, accessor: 'data.allowPlay', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 35)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowDeposit, accessor: 'data.allowDeposit', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 36)} on = {row.value} /></div>},
                                        {width: 120, Header: Assets.AllowWithdraw, accessor: 'data.allowWithdraw', headerClassName: 'th-left', Cell: (row) => 
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 37)} on = {row.value} /></div>},

                                        {width: 110, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            // onClick = {() => handleRiskLevel(1, row.index , row.value)}
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(1, row.index, row.value)}
                                        </button>,
                                        },
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 1)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddTemporaryAccountStatus()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteTemporaryAccountStatus)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 2)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
            : void 0}

            {Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.NoPlayerActivity ?
            <div>
            {/*<h3>{Assets.NoPlayerActivity}</h3>*/}
            {Main.Instance.RiskManagementState.showAddNoPlayerActivity? <NoPlayerActivityModal />: void 0}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRM: SectionButtonKYC.Nothing})}
                        {InsideTitle(true,Assets.NoPlayerActivity, faUserSlash)}
                        <br/>
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.noPlayerActivityStatus ? Main.Instance.DatabaseDataState.noPlayerActivityStatus : []}
                            columns = {[
                                {
                                    columns: [
                                        /*{show: isUserAdmin(), minWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},*/
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        /*{width: 250, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <input className = "table-input-text" onChange = {e => handleText (e.target.value, row.index, 2)} type = "text" value = {row.value}/></div>},
                                        */
                                        {width: 120, Header: Assets.YearsInactive, accessor: 'data.years', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <input min = {0} className = "table-input-number" onChange = {(e) => handleNumber (e.target.value, row.index, 0)} type = "number" value = {row.value !== undefined ? row.value : ''}/></div>},
                                        {width: 120, Header: Assets.MonthsInactive, accessor: 'data.months', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">
                                                <input min = {0} max = {11} className = "table-input-number" onChange = {(e) => handleNumber (e.target.value, row.index, 1)} type = "number" value = {row.value !== undefined ? row.value : ''}/>
                                            </div>,
                                        },
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button 
                                            // onClick = {() => handleRiskLevel(0, row.index, row.value)}
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(0, row.index, row.value)}
                                        </button>,
                                        },
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 24)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNoPlayerActivity()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNoPlayerActivity)},
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 3)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>
            : void 0}

            {/*: void 0*/}
            
            {/*Main.Instance.RiskManagementState.sectionButtonKYC === SectionButtonKYC.Settings?
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                <div 
                    className = "clearfix test-site-data2 light_grey" 
                    style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                >
                    {XButton()}
                    {InsideTitle(Assets.Settings, faCog)}
                    <br/>

                    <div className = "fl mr100">
                        <h4>{Assets.Identification}</h4>
                        {Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.KYC && Main.Instance.FrontendStoreState.custom.KYC.identification?
                        <>
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.identification.nationalIdCard, 0, Assets.NationalIdCard)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.identification.passport, 1, Assets.Passport)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.identification.drivingLicense, 2, Assets.DrivingLicense)}
                            {DocumentsFormat(Main.Instance.FrontendStoreState.custom.KYC.identification.documentsFormat, 0, Assets.AllowedDocumentsFormats)}
                        </>
                        : void 0
                        }
                    </div>
                    <div className = "fl mr100">
                        <h4>{Assets.ProofOfAddress}</h4>
                        {Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.KYC && Main.Instance.FrontendStoreState.custom.KYC.proofOfAddress?
                        <>
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfAddress.utilityBill, 3, Assets.UtilityBill)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfAddress.bankStatement, 4, Assets.BankStatement)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfAddress.localAuthorityTaxBill, 5, Assets.LocalAuthorityTaxBill)}
                            {DocumentsFormat(Main.Instance.FrontendStoreState.custom.KYC.proofOfAddress.documentsFormat, 1, Assets.AllowedDocumentsFormats)}
                        </>
                        : void 0
                        }
                    </div>
                    <div className = "fl mr100">
                        <h4>{Assets.ProofOfPayment}</h4>
                            {Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.KYC &&
                                Main.Instance.FrontendStoreState.custom.KYC.proofOfPayment ?
                        <>
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfPayment.bankAccount, 6, Assets.BankAccount)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfPayment.creditCard, 7, Assets.Creditcard)}
                            {Switches(Main.Instance.FrontendStoreState.custom.KYC.proofOfPayment.eWallet, 8, Assets.eWallet)}
                            {DocumentsFormat(Main.Instance.FrontendStoreState.custom.KYC.proofOfPayment.documentsFormat, 2, Assets.AllowedDocumentsFormats)}
                        </>
                        : void 0
                        }
                    </div>
                </div>
            </div>
            : void 0
            */}
        </div>
    )
}
/*
function getKYCGroupsButtons(): JSX.Element[] {
    let unresolved: number = getUnresolved()
    const buttons: JSX.Element[] = []
    for (let i: number = SectionButtonKYC.RecentUploads; i <= SectionButtonKYC.Settings; i++) {
        buttons.push (
            <button
                key = {i.toString()}
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonKYC: i})}
                className = {
                    Main.Instance.RiskManagementState.sectionButtonKYC === i ? 'dbl btn btn-warning btn-sm fr mr0' : 'btn btn-default btn-sm dbl fl'
                }
            >
                {Assets.KYCGroups[i]}
                {i === SectionButtonKYC.RecentUploads ? <>&nbsp;&nbsp;<span className="badge badge-light">{unresolved}</span></>: void 0}

            </button>
        )
    }
    return buttons
}
*/

function getKYCGroupsButtons(less?: boolean): JSX.Element[] {
    const buttons: JSX.Element[] = []
    const unresolved: number = less ? 0 : GetUnresolved(TimelineEntrySubType.KnowYourCustomer)
    for (let i: number = SectionButtonKYC.TemporaryAccountStatus; i <= SectionButtonKYC.RecentUploads; i++) {
        if (less && i === SectionButtonKYC.RecentUploads) {continue}
        buttons.push (
            <button
                key = {i.toString()}
                style = {{marginRight: i === SectionButtonKYC.RecentUploads ? '0px' : ''}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonKYC: i, /*filteredData: undefined,*/ riskLevel: RiskLevel.Undefined, minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined})
                    DbRoutes.wsBasicGet(
                        i === SectionButtonKYC.LargeValueDeposit ? Config.amLargeValueDeposits :
                        i === SectionButtonKYC.LargeValueWithdrawal ? Config.amLargeValueWithdrawals :
                        i === SectionButtonKYC.TemporaryAccountStatus ? Config.amTemporaryAccount :
                        i === SectionButtonKYC.NoPlayerActivity ? Config.amPlayerActivity :
                        i === SectionButtonKYC.MaximumAmountOnPlayerWallet ? Config.amMaximumBalance :
                    undefined)
                }}
                className = {Main.Instance.RiskManagementState.sectionButtonKYC === i ?
                                i !== SectionButtonKYC.RecentUploads ? 'btn btn-warning btn-sm dbl fl' :
                                i === SectionButtonKYC.RecentUploads ? unresolved ? 'btn btn-danger btn-sm dbl fr' : 'btn btn-warning btn-sm dbl fr' :
                                'btn btn-success btn-sm dbl fl' :
                                i !== SectionButtonKYC.RecentUploads ? 'btn btn-default btn-sm dbl fl' :
                                i === SectionButtonKYC.RecentUploads ? 'btn btn-default btn-sm dbl fr' : ''}
            >
                {Assets_.KnowYourCustomerGroups[i]}
                {i === SectionButtonKYC.RecentUploads ? <>&nbsp;&nbsp;<span className="badge badge-light">{unresolved}</span></>: void 0}
            </button>,
        )
    }

    if (less) {
        buttons.push (
            <button
                key = "99"
                style = {{marginRight: '0px'}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonKYC: 99})
                }}
                className = {Main.Instance.RiskManagementState.sectionButtonKYC === 99 ?
                            'dbl btn btn-warning btn-sm fl' : 'btn btn-default btn-sm dbl fl'}
            >
                {Assets.Other}
            </button>,
        )
    }    
    return buttons
}

function AddMaximumAmount() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showAddMaxAmountWallet: true})  
}

function handleDeleteMaximumAmount(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amMaximumBalance, row.original.id)
    }
}

function AddNoPlayerActivity() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showAddNoPlayerActivity: true})  
}

function AddTemporaryAccountStatus() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showAddTempAccountStatus: true})
}

function handleDeleteNoPlayerActivity(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amPlayerActivity, row.original.id)
    }
}

function handleDeleteTemporaryAccountStatus(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amTemporaryAccount, row.original.id)
    }
}

function handleDeleteWithdrawalAlert(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amLargeValueWithdrawals, row.original.id)
    }
}

function handleDeleteDepositAlert(row: any) {
    // console.log (JSON.stringify(row.original))
    if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amLargeValueDeposits, row.original.id)
    }
}

function AddWithdrawalAlert() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showAddLargeWithdrawal: true})  
}

function AddDepositAlert() {
    Logic.Type.New (Main.Instance.RiskManagementState, {/*filteredData: undefined,*/ minRiskLevel: RiskLevel.Undefined, maxRiskLevel: RiskLevel.Undefined, showAddLargeDeposit: true})  
}