import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { Logic } from '../../../Logic/Type'
import { injectSpaces} from '../../../Logic/Utils'
import {getNationality } from './CashbackMondayOptin'
import ReactTable from 'react-table'
import { GetDate0 } from '../PlayerManagement/WithdrawalRequests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faTimes, faDiceD20, faGift, faTrophy, faFilter} from '@fortawesome/free-solid-svg-icons'
import {MissionStatus} from '../../../Logic/Database/missions/missionModelsNew'
import {FormSelect, FormInput, FormInputNumber} from '../../Reusables/Inputs'
import {SingleDatePicker} from 'react-dates'
// import * as moment from 'moment'
// import './userInfoStyle.css'



export function getUserData() {
    const data = Object.assign([], Main.Instance.DatabaseDataState.usersInfoFiltered)
    let playerId: string
    data.sort((a, b) => (a.username > b.username) ? 1 : -1)
    if (Main.Instance.UsersInfoState.search_string && Main.Instance.UsersInfoState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
        for (let i: number = 0; i < data.length; i++) {
            if (Main.Instance.UsersInfoState.username) {
                const position: number = data[i].username.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        playerId = data[i].playerId
                        break
                    }
                } else {
                    if (position !== -1) {
                        playerId = data[i].playerId
                        break
                    }
                }
            }

            if (playerId === undefined && Main.Instance.UsersInfoState.playerId) {
                const position: number = data[i].playerId.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        playerId = data[i].playerId
                        break
                    }
                } else {
                    if (position !== -1) {
                        playerId = data[i].playerId
                        break
                    }
                }
            }

            
        }
    }
    if (playerId !== undefined) {
        DbRoutes.getUserInfo(playerId)
    }
}

function getFilters(): JSX.Element {
    return (
        <div className = "row admin-users-row">
            {Main.Instance.UserInfoState.navigation === 3 ?
            <div title = {Assets.Filter + ' ' + Assets.Tournaments.toLowerCase() + '/' + Assets.Races.toLowerCase()} style = {{width: 'min-content'}} className = "fl mr">
                <FormSelect
                    narrow = {true}
                    float = {true}
                    value={Main.Instance.UserInfoState.selectedTournamentType}
                    data={optionsTournamentType()}
                    ClassName="form-control"
                    onChange={(text) => {
                        tableFilters(text, 'tournamentType', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {selectedTournamentType: text})
                    }}
                    Icon={faFilter}
                />
            </div>: void 0}
            {Main.Instance.UserInfoState.navigation === 3 ?
            <div title = {Assets.Filter + Assets._by_ + Assets.ID.toLowerCase()} style = {{width: 'min-content'}} className = "fl mr">
                <FormInputNumber
                    description={Assets.ID}
                    placeholder={Assets.ID}
                    outerDiv="rows-per-table input-group ml0 pl0"
                    ClassName="w70 h30"
                    value={Main.Instance.UserInfoState.selectedTournamentId}
                    type="number"
                    min="1"
                    Icon={faTrophy}
                    onChange={(text) => {
                        const id: string = getTournamentData(+text, 'ID')
                        tableFilters(id.toString(), 'tournamentId', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {selectedTournamentId: text, selectedTournamentName: ''})
                    }}
                />
            </div>: void 0}
            {Main.Instance.UserInfoState.navigation === 3 ?
            <div title = {Assets.Filter + Assets._by_ + Assets.Name.toLowerCase()} style = {{width: 'min-content'}} className = "fl mr">
                <FormInput
                    list = "tournamentAuto"
                    description={Assets.Name}
                    width="190px"
                    narrow={true}
                    value={Main.Instance.UserInfoState.selectedTournamentName}
                    ClassName="form-control register-input select-wide"
                    type="text"
                    onChange={(text) => {
                        const id = getTournamentId(text)
                        tableFilters(id.toString(), 'tournamentId', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {selectedTournamentName: text, selectedTournamentId: ''})  
                    }}
                    placeholder={Assets.Name}
                    Icon={faTrophy}
                />
            </div>: void 0}
            {Main.Instance.UserInfoState.navigation === 1 ?
            <div title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.ID.toLowerCase()} style = {{width: 'min-content'}} className = "fl mr">
                <FormInputNumber
                    description={Assets.Reward + ' ' + Assets.ID}
                    placeholder={Assets.ID}
                    outerDiv="rows-per-table input-group ml0 pl0"
                    ClassName="w70 h30"
                    value={Main.Instance.UserInfoState.selectedRewardId}
                    type="number"
                    min="1"
                    Icon={faGift}
                    onChange={(text) => {
                        const id: string = getRewardData(+text, 'ID')
                        tableFilters(id.toString(), 'rewardId', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {selectedRewardId: text, selectedRewardName: ''})
                    }}
                />
            </div>: void 0}
            {Main.Instance.UserInfoState.navigation === 1 ?
            <div title = {Assets.Filter + ' ' + Assets.Rewards.toLowerCase() + Assets._by_ + Assets.Name.toLowerCase()} style = {{width: 'min-content'}} className = "fl mr">
                <FormInput
                    list = "rewardAuto"
                    description={Assets.RewardName}
                    width="190px"
                    narrow={true}
                    value={Main.Instance.UserInfoState.selectedRewardName}
                    ClassName="form-control register-input select-wide"
                    type="text"
                    onChange={(text) => {
                        const id = getRewardId(text)
                        tableFilters(id.toString(), 'rewardId', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {selectedRewardName: text, selectedRewardId: ''})  
                    }}
                    placeholder={Assets.RewardName}
                    Icon={faGift}
                />
            </div>: void 0}
            {Main.Instance.UserInfoState.navigation === 1 ?
            <div title = {Assets.Reward + ' ' + Assets.Received.toLowerCase() + ' ' + Assets.From.toLowerCase()}  style = {{width: 'min-content'}} className = "fl">
                <FormSelect
                    narrow = {true}
                    float = {true}
                    value={Main.Instance.UserInfoState.rewardFromFilter}
                    data={optionsRewardsFrom()}
                    ClassName="form-control"
                    onChange={(text) => {
                        tableFilters(text, 'rewardFrom', undefined);
                        Logic.Type.New(Main.Instance.UserInfoState, {rewardFromFilter: text})
                    }}
                    Icon={faDiceD20}
                /> 
            </div>: void 0}
            {getAutocomplete()}
            <div title = {(Main.Instance.UserInfoState.navigation === 1 ? Assets.Rewards + ' ' + Assets.Received.toLowerCase() + ' ' + Assets.ToDate.toLowerCase() : '')} className = {'singledatepicker fr'}>
                <SingleDatePicker
                    id = "filterTo"
                    placeholder = {Assets.ToDate}
                    date = {Main.Instance.UserInfoState.selectedToDate}
                    onDateChange = {(date) => {
                        if (date === null || Main.Instance.UserInfoState.selectedToDate === null || (Main.Instance.UserInfoState.selectedFromDate ? Main.Instance.UserInfoState.selectedFromDate.format(Assets_.dateDisplayFormat) <= date.format(Assets_.dateDisplayFormat) : {})) {
                            if (date === null) {
                                tableFilters('', Main.Instance.UserInfoState.navigation === 1 ? 'dateReceived' : 'tournamentDate', false);
                            } else {
                                tableFilters(date.format(Assets_.dateDisplayFormat), Main.Instance.UserInfoState.navigation === 1 ? 'dateReceived' : 'tournamentDate', false);
                            }
                            Logic.Type.New(Main.Instance.UserInfoState, {selectedToDate: date})  
                        }
                    }}
                    focused = {Main.Instance.UserInfoState.focusedToDate}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.UserInfoState, {focusedToDate: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
            </div>

            <div title = {(Main.Instance.UserInfoState.navigation === 1 ? Assets.Rewards + ' ' + Assets.Received.toLowerCase() + ' ' + Assets.FromDate.toLowerCase() : '')} className = "singledatepicker fr mr">
                <SingleDatePicker
                    id = "filterFrom"
                    placeholder = {Assets.FromDate}
                    date = {Main.Instance.UserInfoState.selectedFromDate}
                    onDateChange = {(date) => {
                        if (date === null || Main.Instance.UserInfoState.selectedFromDate === null || (Main.Instance.UserInfoState.selectedToDate ? date.format(Assets_.dateDisplayFormat) <=  Main.Instance.UserInfoState.selectedToDate.format(Assets_.dateDisplayFormat) : {})) {
                            if (date === null) {
                                tableFilters('', Main.Instance.UserInfoState.navigation === 1 ? 'dateReceived' : 'tournamentDate', true);
                            } else {
                                tableFilters(date.format(Assets_.dateDisplayFormat), Main.Instance.UserInfoState.navigation === 1 ? 'dateReceived' : 'tournamentDate', true);
                            }
                            Logic.Type.New(Main.Instance.UserInfoState, {selectedFromDate: date}) 
                        }
                    }}
                    focused = {Main.Instance.UserInfoState.focusedFromDate}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.UserInfoState, {focusedFromDate: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
            </div>
        </div>
    )
}

function getAutocomplete() {
    const options: JSX.Element[] = []
    if (Main.Instance.UserInfoState.navigation === 1) {
        const data = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
        const alphabetized = data.sort(compareReward)
        for (const item of alphabetized) {
            options.push(<option key = {item.id}>{item.Reward}</option>)
        }
        return (
            <datalist id = "rewardAuto">
                {options}
            </datalist>
        )
    }
    if (Main.Instance.UserInfoState.navigation === 3) {
        const data = Object.assign([], Main.Instance.DatabaseDataState.tournamentStream)
        const alphabetized = data.sort(compareTournament)
        for (const item of alphabetized) {
            options.push(<option key = {item.id}>{item.name}</option>)
        }
        return (
            <datalist id = "tournamentAuto">
                {options}
            </datalist>
        )
    }
  
    
}

function compareReward( a, b) {
    if ( a.Reward.toLowerCase() < b.Reward.toLowerCase() ) {
      return -1;
    }
    if ( a.Reward.toLowerCase() > b.Reward.toLowerCase() ) {
      return 1;
    }
    return 0;
}

function compareTournament( a, b) {
    if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
      return -1;
    }
    if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
      return 1;
    }
    return 0;
}

function tableFilters (value, accessor, from: boolean) {

    const filtered = Object.assign ([], Main.Instance.UserInfoState.filtered)  
    let insertNewFilter: boolean = true
    if (filtered.length) {
        filtered.forEach((filter, i) => {
            if(filter.id === accessor) {
                if (filter.from !== from) {
                    insertNewFilter = true
                    
                } else {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1)
                        insertNewFilter = false
                    } else {
                        filter.value = value
                        insertNewFilter = false
                    }
                }
    
            }
        })
    }
    if (insertNewFilter) {
        filtered.push({id: accessor, value, from})
    }
    Logic.Type.New(Main.Instance.UserInfoState, {filtered})
}

function optionsRewardsFrom(): Array<{ id: string, name: string }> {
        const options: Array<{ id: string, name: string }> = []
        options.push({ name: Assets.All, id: '' })
        for (let i: number = 0; i < Assets.RewardFrom.length; i++) {
            options.push({ name: Assets.RewardFrom[i], id: i.toString() })
        }
        return options
}

function optionsTournamentType(): Array<{ id: string, name: string }> {
    const options: Array<{ id: string, name: string }> = []
    options.push({ name: Assets.All, id: '' })
    options.push({ name: Assets.Races, id: Assets.Race})
    options.push({ name: Assets.Tournaments, id: Assets.Tournament }) 
    return options
}

export function UserInfo(): JSX.Element {
    DbRoutes.wsReinitialize()
    return (
        <div>
            {!Main.Instance.DatabaseDataState.userIdSelected ?
                <h2>{Assets.p4}</h2> :
                <div>
                    <div className="clearfix">
                        <h2 className="dbl fl">{Assets.UserData}</h2>
                        <div className="dbl fr" style={{ paddingTop: '20px' }}>
                            {getButtonsFirstLine()}
                        </div>
                    </div>
                    {Main.Instance.UserInfoState.navigation === 1 || Main.Instance.UserInfoState.navigation === 3 ?
                    <div className="clearfix mb">
                        {getFilters()}
                    </div> : void 0}
                    <div className="clearfix">
                        {getContent()}
                    </div>
                </div>}
        </div>

    )
}

function applyDateFilters(row): boolean {
    let date
    if (Main.Instance.UserInfoState.navigation === 1) {
        date = new Date((row.dateReceived).substring(0, 10)).getTime() / 1000
    } else if (Main.Instance.UserInfoState.navigation === 3) {
        date = row.tournamentDate
    }


    let from
    let to
    for (const item of Main.Instance.UserInfoState.filtered) { 
        if (item.from === true) {
            from = new Date(item.value + 'T00:00:00').getTime() / 1000
        }
        if (item.from === false) {
            to = new Date(item.value + 'T23:59:59').getTime() / 1000
        }
    }

    if (from && to) {
        return from <= date && date <= to                            
    } else if (from) {
        return from <= date
    } else if (to) {
        return date <= to
    }
    return true
}

function getRewardData(id: number, which: string): string {

    if (Main.Instance.DatabaseDataState.rewardShopStream) {
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
            
            if (item.id === id) {
                if (which === 'Name') {
                    return item.Reward
                } else if (which === 'FS') {
                    return item.numOfFreeSpins
                } else if (which === 'ID') {
                    return item.id.toString()
                } else if (which === 'bonusCode') {
                    return item.reward.category1.bonusCode
                } else if (which === 'description') {
                    return item.reward.description
                }            
            }
        }
    }
    return ''

}

function getTournamentData(id: number, which: string): string {

    if (Main.Instance.DatabaseDataState.tournamentStream) {
        for (const item of Main.Instance.DatabaseDataState.tournamentStream) {      
            if (item.id === id) {
                if (which === 'Name') {
                    return item.name
                } else if (which === 'ID') {
                    return item.id.toString()
                }             
            }
        }
    }
    return ''

}

function getRewardId(name: string): number | string {

    if (Main.Instance.DatabaseDataState.rewardShopStream) {
        for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {

            if (item.Reward.toLowerCase().replace(' ', '') === name.toLowerCase().replace(' ', '')) {

                return item.id
            }
        }
    }
    return ''
}

function getTournamentId(name: string): number | string  {

    if (Main.Instance.DatabaseDataState.tournamentStream) {
        for (const item of Main.Instance.DatabaseDataState.tournamentStream) {
            if (item.name.toLowerCase().replace(' ', '') === name.toLowerCase().replace(' ', '')) {
                return item.id
            }
        }
    }
    return ''
}

function getContent(): JSX.Element {
    if (Main.Instance.UserInfoState.navigation === 0) {
        return (
            <div>
                <div>
                    <div>
                        <h3>{Assets.General}</h3>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.PlayerId}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.playerId}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Username}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}} >{Main.Instance.DatabaseDataState.userInfo.username}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Nationality}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{getNationality(Main.Instance.DatabaseDataState.userInfo.nationality)}&nbsp;&nbsp;&nbsp;{getFlag(Main.Instance.DatabaseDataState.userInfo.nationality)}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Currency}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.currency}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Last} {Assets.Played.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.lastPlayed}</span>
                    </div>
                    <div >
                        <span className="label dibl"  style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Consecutive} {Assets.Days.toLowerCase()}</span>
                        <span className="label dibl"  style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.consecutiveDays}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <h3>{Assets.Level}</h3>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Level}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.levels.level}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Level + ' ' + Assets.Bets.toLowerCase() + ' ' + Assets.Count.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.levels.levelBetsCount}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Level + ' ' + Assets.Bets.toLowerCase() + ' ' + Assets.Total.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Math.round(Main.Instance.DatabaseDataState.userInfo.levels.levelBetsTotal * 100) / 100}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Level + ' ' + Assets.Percentage.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Math.round(Main.Instance.DatabaseDataState.userInfo.levels.levelPercentage * 100) / 100}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <h3>{Assets.Status}</h3>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Deposits}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.deposits}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Withdrawals}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.withdrawals}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.TotalSpins}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.spins}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Bets + ' ' + Assets.Today.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.betsToday}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Average} {Assets.Bet.toLowerCase()} {Assets.Size.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.averageBetSize ? Main.Instance.DatabaseDataState.userInfo.averageBetSize : 0}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Bets} {Assets.Count.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.betsCount}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.TotalBets}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Math.round(Main.Instance.DatabaseDataState.userInfo.totalBet * 100) / 100}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Wins} {Assets.Count.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Main.Instance.DatabaseDataState.userInfo.winsCount}</span>
                    </div>
                    <div >
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', background:'#eee', width: '150px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Assets.Total} {Assets.Won.toLowerCase()}</span>
                        <span className="label dibl" style = {{height:'29px', border:'1px solid #ddd', borderRadius:'0px', padding:'7px 3px 7px 3px', width: '250px', fontSize: '14px', color: '#333333', fontWeight:'initial'}}>{Math.round(Main.Instance.DatabaseDataState.userInfo.totalWon * 100) / 100}</span>
                    </div>
                </div>
            </div>

        )
    } else if (Main.Instance.UserInfoState.navigation === 1) {
        
        return (
            <div>
                <ReactTable
                    data={Main.Instance.DatabaseDataState.userInfo.rewards}
                    filtered = {Main.Instance.UserInfoState.filtered}
                    onFilteredChange = {(filtered, column, value) => {
                        tableFilters(value, column.accessor, filtered.from)
                    }}
                    columns={[

                        {
                            Header: Assets.Reward,
                            headerClassName: 'th-center risk-default-light',
                            columns: [
                                { width: 50, Header: Assets.ID, accessor: 'rewardId', headerClassName: 'th-left', filterMethod: (filter, row) => {
                                    if (filter.value === '') {
                                        return true;
                                    } else {
                                        return +filter.value === row.rewardId
                                    }
                                }},
                                { Header: Assets.Name, accessor: 'rewardName', headerClassName: 'th-left', Cell: (row) => getRewardData(row.original.rewardId, 'Name')},
                                { width: 120, Header: Assets.Type, accessor: 'rewardType', headerClassName: 'th-left' },
                                // { width: 120, Header: Assets.Status, accessor: 'rewardStatus', headerClassName: 'th-left' },
                                // { width: 120, Header: Assets.Category, accessor: 'category', headerClassName: 'th-left' },
                                // { width: 120, Header: Assets.SpinsAmount, accessor: 'rewardId', headerClassName: 'th-left', Cell: (row) => getRewardData(row.original.rewardId, 'FS') },
                                // { width: 120, Header: Assets.Tokens + ' ' + Assets.Amount.toLowerCase(), accessor: 'tokensAmount', headerClassName: 'th-left', Cell: (row) => row.value ? row.value :  '' },
                                { width: 120, Header: Assets.BonusCode, accessor: 'bonusCode', headerClassName: 'th-left', Cell: (row) => getRewardData(row.original.rewardId, 'bonusCode') },
                                { width: 150, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left', Cell: (row) => getRewardData(row.original.rewardId, 'description') },
                            ]
                        },
                        {
                            Header: Assets.Received + ' ' + Assets.On.toLowerCase(),
                            headerClassName: 'th-center risk-default',
                            columns: [
                                { width: 120, Header: Assets.Date, accessor: 'dateReceived', headerClassName: 'th-left', Cell: (row) => (row.value).substring(0, 10), filterMethod: (filter, row) => applyDateFilters(row)},
                                { width: 120, Header: Assets.Time, accessor: 'dateReceived', headerClassName: 'th-left', Cell: (row) => (row.value).substring(11, 19) },
                            ]
                        },
                        {
                            Header: Assets.ExpiresOn,
                            headerClassName: 'th-center risk-default-light',
                            columns: [
                                { width: 120, Header: Assets.Date, accessor: 'expirationDate', headerClassName: 'th-left', Cell: (row) => row.value ? (row.value).substring(0, 10) : '' },
                                { width: 120, Header: Assets.Time, accessor: 'expirationDate', headerClassName: 'th-left', Cell: (row) => row.value ? (row.value).substring(11, 19) : '' },
                            ]
                        },
                        {
                            Header: Assets.Received + ' ' + Assets.From.toLowerCase(),
                            headerClassName: 'th-center risk-default',
                            columns: [
                                { width: 150, Header: Assets.From, accessor: 'rewardFrom', headerClassName: 'th-left', Cell: (row) => rewardReceivedFrom(row.original, 1), filterMethod: (filter, row) => {
                                    if (filter.value === '') {
                                        return true;
                                    } else {
                                        return rewardReceivedFrom(row._original, 1) === Assets.RewardFrom[+filter.value]
                                    }
                                }},
                                { width: 70, Header: Assets.ID, headerClassName: 'th-left', Cell: (row) => rewardReceivedFrom(row.original, 2) },
                                { width: 150, Header: Assets.For, headerClassName: 'th-left', Cell: (row) => rewardReceivedFrom(row.original, 3) },
                            ]
                        }



                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    showPaginationTop={true}
                />
            </div>
        )
    } else if (Main.Instance.UserInfoState.navigation === 2) {
        return (
            <div>
                {Main.Instance.UserInfoState.selectedMission && Main.Instance.UserInfoState.selectedMission !== 0 ?
                <div>
                    <ReactTable
                    data={getTasksData()}
                    columns={[
                        {
                            Header: Assets.Task, 
                            headerClassName: 'th-center risk-default-light',
                            columns: [
                                {className: 'btn-nopadding', Header: Assets.Task + ' ' + Assets.Name, accessor: 'name', headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div> },
                                {className: 'btn-nopadding', Header: Assets.Bets + ' ' + Assets.Count.toLowerCase(), accessor: 'betsCount', headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div> },
                                {className: 'btn-nopadding', Header: Assets.Bets + ' ' + Assets.Total.toLowerCase(), accessor: 'betsTotal', headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{Math.round(row.value * 100) / 100}</div> },
                            ]
                        },
                        {
                            Header: Assets.Objective, 
                            headerClassName: 'th-center risk-default',
                            columns: [
                                {className: 'btn-nopadding', Header: Assets.Name, accessor: 'objectivesNames', headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{row.value[0]}</div>},
                                {className: 'btn-nopadding', Header: Assets.Completed, accessor: 'objectivesCompleted', headerClassName: 'th-left', Cell: (row) =>  <div>&nbsp;&nbsp;<FontAwesomeIcon style = {{color: row.value[0] === true ? '#28a745' : '#dc3545'}} icon = {row.value[0] === true ? faCheck : faTimes}/></div> },
                                {className: 'btn-nopadding', Header: Assets.Progress, headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{(Math.round(row.original.objectivesCount[0] * 100) / 100) + '/' + row.original.objectivesNeeded[0]}</div>},
                                {className: 'btn-nopadding', Header: Assets.Progress + ' %', accessor: 'objectivesPercentages', headerClassName: 'th-left', Cell: (row) => <div>&nbsp;&nbsp;{row.value[0]}</div> },
                            ]
                        },
                        
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    showPaginationTop={true}
                />
                </div>
                : void 0}
                <div>
                <ReactTable
                    data={Main.Instance.DatabaseDataState.userInfo.missionsState}
                    columns={[
                        {className: 'btn-nopadding', Header: Assets.Mission + ' ' + Assets.ID, accessor: 'missionId', headerClassName: 'th-left risk-default-light', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div> },
                        {className: 'btn-nopadding', Header: Assets.Mission + ' ' + Assets.Status.toLowerCase(), accessor: 'missionStatus', headerClassName: 'th-left risk-default', Cell: (row) => <div>&nbsp;&nbsp;{injectSpaces(MissionStatus[row.value])}</div>},
                        {className: 'btn-nopadding', Header: Assets.Times + ' ' + Assets.Completed.toLowerCase(), accessor: 'timesCompleted', headerClassName: 'th-left risk-default-light', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', Header: Assets.RepeatedCycles, accessor: 'repeatedCyclesCount', headerClassName: 'th-left risk-default', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', Header: Assets.Tasks + ' ' + Assets.Completed.toLowerCase(), accessor: 'tasksCompleted', headerClassName: 'th-left risk-default-light', Cell: (row) => <div>&nbsp;&nbsp;{completedTasks(row.value)}</div>},
                        {className: 'btn-nopadding th-center', width: 90, accessor: '', Cell: (row) => 
                        <div>&nbsp;&nbsp;
                            <button
                                className = {row.original.missionId === Main.Instance.UserInfoState.selectedMission ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                onClick = {() => {
                                    if (row.original.missionId === Main.Instance.UserInfoState.selectedMission) {
                                        Logic.Type.New(Main.Instance.UserInfoState, {selectedMission: 0})
                                    } else {
                                        Logic.Type.New(Main.Instance.UserInfoState, {selectedMission: row.original.missionId})
                                    }
                                }}
                            >
                                {Assets.View + ' ' + Assets.Tasks}
                            </button>
                        </div>
                        },
                        
                        
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    showPaginationTop={true}
                />
                </div>
            </div>
        )
    } else if (Main.Instance.UserInfoState.navigation === 3) {

        return (
            
            <div>
                <ReactTable
                    data={Main.Instance.DatabaseDataState.userInfo.tournamentsInfo}
                    filtered = {Main.Instance.UserInfoState.filtered}
                    onFilteredChange = {(filtered, column, value) => {
                        tableFilters(value, column.accessor, filtered.from)
                    }}
                    columns={[
                        { width: 50, Header: Assets.ID, accessor: 'tournamentId', headerClassName: 'th-left risk-default-light' , filterMethod: (filter, row) => {
                            if (filter.value === '') {
                                return true;
                            } else {
                                return +filter.value === row.tournamentId
                            }
                        }},
                        { Header: Assets.Name, accessor: 'tournamentName', headerClassName: 'th-left risk-default', Cell: (row) => getTournamentData(row.original.tournamentId, 'Name') },
                        { width: 120, Header: Assets.Type, accessor: 'tournamentType', headerClassName: 'th-left risk-default-light', Cell: (row) => tournamentType(row.original), filterMethod: (filter, row) => {
                            if (filter.value === '') {
                                return true;
                            } else {
                                return tournamentType(row._original) === filter.value
                            }
                        }},
                        { width: 120, Header: Assets.Date, accessor: 'tournamentDate', headerClassName: 'th-left risk-default', Cell: (row) => GetDate0(row.value).substring(0, 10), filterMethod: (filter, row) => applyDateFilters(row)  },
                        { width: 120, Header: Assets.Time, accessor: 'tournamentDate', headerClassName: 'th-left risk-default-light', Cell: (row) => GetDate0(row.value).substring(11, 19) },
                        { width: 120, Header: Assets.OptIn + ' ' + Assets.Date.toLowerCase(), accessor: 'optinDate', headerClassName: 'th-left risk-default', Cell: (row) => (row.value).substring(0, 10)},
                        { width: 120, Header: Assets.OptIn + ' ' + Assets.Time.toLowerCase(), accessor: 'optinDate', headerClassName: 'th-left risk-default-light', Cell: (row) => (row.value).substring(11, 19) },
                        { width: 120, Header: Assets.Rounds + ' ' + Assets.Played.toLowerCase(), accessor: 'roundsPlayed', headerClassName: 'th-left risk-default' },
                        { width: 120, Header: Assets.Rounds + ' ' + Assets.Won.toLowerCase(), accessor: 'roundsWon', headerClassName: 'th-left risk-default-light' },
                        { width: 120, Header: Assets.TotalBets, accessor: 'sumOfBets', headerClassName: 'th-left risk-default', Cell: (row) => (Math.round(row.value * 100) / 100) },
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    showPaginationTop={true}
                />
            </div>
        )
    }
}

function getTasksData() {
    for (const item of Main.Instance.DatabaseDataState.userInfo.missionsState) {
        if (item.missionId === Main.Instance.UserInfoState.selectedMission) {
            return item.tasksState
        }
    }
}

export function getFlag(code: string): JSX.Element {
    let src: string
    if (Main.Instance.DatabaseDataState.FlagsStreamAll) {
        for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
            if (code && item.code && item.code.toLowerCase() === code.toLowerCase() || code && item.code2 && item.code2.toLowerCase() === code.toLowerCase()) {
                // console.log (item)
                src = item.image
                break
            }
        }
    }
    return src ? <img style = {{height: '16px'}} src = {src}/> : <span>-</span>
}

function tournamentType(row) {
    for (const item of Main.Instance.DatabaseDataState.tournamentStream) {
        if (row.tournamentId === item.id) {
            if (item.tournamentType === 'Races') {
                return Assets.Race
            } else {
                return Assets.Tournament
            }
        }

    }
}

function rewardReceivedFrom(row, column) {
    if (row.missionId) {
        if (column === 1) {
            return Assets.Chance
        } else if (column === 2) {
            return row.missionId
        } else if (column === 3) {
            return Assets.Task + ' ' + Assets.ID + ': ' + row.taskId
        } else {
            return '-'
        }
    } else if (row.tournamentId) {
        if (column === 1) {
            return Assets.Tournament
        } else if (column === 2) {
            return row.tournamentId
        } else if (column === 3) {
            return Assets.Position + ': ' + row.position
        } else {
            return '-'
        }
    } else if (row.storeId) {
        if (column === 1) {
            return Assets.Shop
        } else if (column === 2) {
            return row.storeId
        } else {
            return '-'
        }
    } else if (row.levels) {
        if (column === 1) {
            return Assets.Level
        } else if (column === 2) {
            return row.levels.level
        } else {
            return '-'
        }
    } else {
        return '-'
    }
}

function completedTasks(arr) {
    let i = 0
    for (const item of arr) {
        if (item === true) {
            i++
        }
    }
    return i + '/' + arr.length
}

function getButtonsFirstLine() {
    const buttons: JSX.Element[] = []
    for (let i: number = 0; i < Assets_.UserInfoButtons.length; i++) {
        buttons.push(
            <button
                type="button"
                className={i === Main.Instance.UserInfoState.navigation ? 'dbl fl btn btn-primary' : 'dbl fl btn btn-default'}
                onClick={() =>
                    Logic.Type.New(Main.Instance.UserInfoState, { navigation: i, filtered: [], selectedFromDate: null, selectedToDate: null })
                }
                style={{marginRight: i === 3 ? '0px' : ''}}
                key={Assets_.UserInfoButtons[i]}
            >
                {Assets_.UserInfoButtons[i]}
            </button>
        )
    }
    return buttons
}