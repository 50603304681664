import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {IsBuildPasino, IsBuildJackpotsCH} from '../../../Logic/Utils'

export function BonusWrapper(props:React.Props<any> ) {
   if (RouterObjects['bonus_Section'] && Main.Instance.Location == RouterObjects['bonus_Section'].path && Main.Instance.Back === false) {
       
       let path: string
       let Path: string 
       
       if (IsBuildJackpotsCH() || IsBuildPasino()) {
           path = RouterObjects['bonus_Section'].path + RouterObjects['bonus_Section'].children['Bonus_Campaign'].path
           Path = RouterObjects['bonus_Section'].path.substring (1, RouterObjects['bonus_Section'].path.length) + ' -> ' +
           RouterObjects['bonus_Section'].children['Bonus_Campaign'].path.substring(1, RouterObjects['bonus_Section'].children['Bonus_Campaign'].path.length)
       } 
   
       Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

       DbRoutes.insertAdminLog (
           Config.asAdminLogView,
           Path,
           {},
       )
       Logic.Type.New(Main.Instance, {Location: path})
   }

   return(<div>{props.children}</div>)
}
