import * as React from 'react'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as Assets from '../../Assets'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {SelectedAdminSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
import * as Assets_ from '../../Assets_'
import {injectSpaces} from '../../../Logic/Utils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'
import {faSave, faPlus} from '@fortawesome/free-solid-svg-icons'
import {Custom, ThousandsSeparator/*, Category2*/} from '../../../Logic/Database/DatabaseData'
import {getAllowedNumber, setOption, getOptions, addOption} from './PlayerSettings'
import Switch from 'react-toggle-switch'

function selectNavigation(selectedAdminSetting: SelectedAdminSetting) {
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedAdminSetting})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
        for (let i: number = 0; i < Object.keys(SelectedAdminSetting).length / 2; i++) {
            navigation.push(
                <button
                    // title = {i === SelectedAdminSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedAdminSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                    // disabled = {i === SelectedAdminSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedAdminSetting.ResponsibleGaming && !isUserRGSpecialist()}
                    onClick = {() => selectNavigation(i)}
                    type = "button"
                    key = {i.toString()}
                    style = {{
                        marginRight: i === Object.keys(SelectedAdminSetting).length / 2 - 1 ? '0px' : '',
                        display: 'block',
                        float: 'left',
                    }}
                    className = {i === Main.Instance.RiskManagementState.selectedAdminSetting ? 'btn btn-primary btn-sm pr' : 'btn btn-default btn-sm pr'}
                >
                    {/*i === SelectedAdminSetting.GameLabelsStrapiGameStore ?
                        <>{Assets_.strapi(Main.Instance.RiskManagementState.selectedAdminSetting === SelectedAdminSetting.GameLabelsStrapiGameStore ? '#fff' : '#333', 19,  5, 9)}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></> :
                        <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedAdminSettingIcons[i]}/>
                    */}
                    <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedAdminSettingIcons[i]}/>
                    {injectSpaces(SelectedAdminSetting[i])}
                </button>)
        }
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}
/*
function set(a: string, key: string, value: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.descriptions[a][key] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getDescriptions(a: string): JSX.Element[] {
    const descriptions: JSX.Element[] = []
    for (const key in Main.Instance.DatabaseDataState.custom.general.descriptions[a]) {
        descriptions.push(<div className = "clearfix mt3" key = {a + '_' + key} style = {{width: '100%'}}><div className = "fl" style = {{width: '12%', fontWeight: 700, lineHeight: '26px'}}>{key === 'settings' ? 'notify' : key}:</div><input className = "fl dbl" style = {{width: '88%'}} onChange = {(e) => set(a, key, e.target.value)} type = "text" value = {Main.Instance.DatabaseDataState.custom.general.descriptions[a][key]}/></div>)
    }
    return descriptions
}
*/
export function AdminSettings(props: React.Props<any>): JSX.Element {
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.AdminSettings}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {Main.Instance.RiskManagementState.selectedAdminSetting === SelectedAdminSetting.InternationalSettings ?
                        <>
                            <div>
                                <div className="mt5">
                                <h4>{Assets.ThousandsSeparator}</h4>
                                {getThousandsSeparator()}
                                </div>
                            </div>
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedAdminSetting === SelectedAdminSetting.ApiKeys ?
                        <>
                            <div>
                                <div className="mt5">
                                    <h4>{Assets_.IBAN}</h4>
                                    {getIBAN()}
                                </div>
                            </div>
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedAdminSetting === SelectedAdminSetting.ProvisionalAccount ?
                            <>
                                {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players ?
                                <>
                                    <h4>{Assets.Expired_ExpiringProvisionalAccounts}</h4>
                                    <div className = "clearfix mb">
                                        <Switch
                                            className = "dbl fl mr"
                                            onClick = {() => {
                                                const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
                                                custom.general.players.getExpiredAccounts = !custom.general.players.getExpiredAccounts
                                                Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
                                            }}
                                            on = {Main.Instance.DatabaseDataState.custom.general.players.getExpiredAccounts}
                                        />
                                        <span className = "fl dbl mt3">{Assets.ShowExpiredProvisionalAccounts}</span>
                                    </div>
                                    <div className = "clearfix mb">
                                        <Switch
                                            className = "dbl fl mr"
                                            onClick = {() => {
                                                const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
                                                custom.general.players.getAllExpiringAccounts = !custom.general.players.getAllExpiringAccounts
                                                Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
                                            }}
                                            on = {Main.Instance.DatabaseDataState.custom.general.players.getAllExpiringAccounts}
                                        />
                                        <span className = "fl dbl mt3">{Assets.ShowAllExpiringProvisionalAccounts}</span>
                                    </div>                                  
                                </> : void 0}
                                <div>
                                    <h4>{Assets.pg2}</h4>
                                    <strong>{Assets.ShowTheFollowingPlayerGroups}:</strong>&nbsp;
                                    {getAllowedNumber(5, Main.Instance.DatabaseDataState.custom.general.players.accountExpiryLimits)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(5, e.target.value)} value={Main.Instance.RiskManagementState.accountExpiryLimit}>
                                        {getOptions(5)}
                                    </select>
                                    <button onClick={() => addOption(5)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.accountExpiryLimit === '0'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>

                            </> : void 0}

                            {/*Main.Instance.RiskManagementState.selectedAdminSetting === SelectedAdminSetting.InternalDescriptions ?
                            <>
                                <div>
                                    <h2>{Assets.RiskManagement}</h2>
                                    {getDescriptions('riskManagement')}
                                    <h2>{Assets.ResponsibleGaming}</h2>
                                    {getDescriptions('responsibleGaming')}
                                </div>
                            </> : void 0*/}
                    </div>
                </div>
            </div> : void 0}
        </div>
    )
}

function getIBAN(): JSX.Element {
/*
    const iban: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(ThousandsSeparator).length / 2; i++) {
        ts.push(<button type = "button" onClick = {() => setThousandsSeparator(i)} className = {i === Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator ? 'btn btn-xs btn-primary br0 mr0' : 'btn btn-xs btn-default br0 mr0'}>{injectSpaces(Object.keys(ThousandsSeparator)[Object.keys(ThousandsSeparator).length / 2 + i])}</button>)
    }
    return iban
*/
    return (<input type = "text" style = {{width: '300px'}} onChange = {(e) => setIBAN(e.target.value)} value = {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.API && Main.Instance.DatabaseDataState.custom.API.iban ? Main.Instance.DatabaseDataState.custom.API.iban : ''}/>)
}

function setIBAN(iban: string) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (custom.API === undefined) {custom.API = {}}
    custom.API.iban = iban
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getThousandsSeparator(): JSX.Element[] {
    const ts: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(ThousandsSeparator).length / 2; i++) {
        ts.push(<button type = "button" onClick = {() => setThousandsSeparator(i)} className = {i === Main.Instance.DatabaseDataState.custom.general.format.thousandsSeparator ? 'btn btn-xs btn-primary br0 mr0' : 'btn btn-xs btn-default br0 mr0'}>{injectSpaces(Object.keys(ThousandsSeparator)[Object.keys(ThousandsSeparator).length / 2 + i])}</button>)
    }
    return ts
}

function setThousandsSeparator(thousandsSeparator: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    custom.general.format.thousandsSeparator = thousandsSeparator
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}