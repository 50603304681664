import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import * as Assets from '../../Assets'

interface State {
    mode?: boolean
}

export class DeployedProjects extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            // mode: false,
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getFlag (code: string): string {
        if (Main.Instance.DatabaseDataState.FlagsStreamAll) {
            for (const item of Main.Instance.DatabaseDataState.FlagsStreamAll) {
                if (item.code === code || item.code2 === code) {
                    return item.image
                }
            }
        }
        return ''
    }
    public getImages(languages: string[], Languages: string[]): JSX.Element[] {
        const images: JSX.Element[] = []
        for (let i: number = 0; i < languages.length; i++) {
            images.push(<img style = {{marginLeft: '4px', maxWidth: '20px'}} alt = "" className = "fl" title = {Languages[i]} src = {this.getFlag(languages[i])}/>)
        }
        return images
    }

    public render() {
        // console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)
        const dp: JSX.Element[] = []
        const customers: Array<{textColor: string, background: string, Country: string, description?: string, site: string, company?: string, link: string, country?: string, languages?: string[], Languages?: string[]}> = []
        customers.push ({textColor: '#333', background: '#fff', Country: 'Switzerland', description: 'The first legal online casino in Switzerland', site: Assets_.jackpotsLogo, company: Assets_.badenLogo, link: 'jackpots.ch', country: 'ch', languages: ['de', 'us', 'fr', 'it'], Languages: ['deutsch', 'english', 'français', 'italiano']})
        customers.push ({textColor: '#fff', background: '#154057', Country: 'Malta', description: 'Gamification system', site: Assets_.myChanceLogo, link: 'Mychance.com', country: 'mt', languages: ['no', 'ca', 'nz', 'in', 'fi', 'de'], Languages: ['norsk', 'english', 'english', 'english','suomi', 'deutsch']})
        customers.push ({textColor: '#fff', background: '#001f2b', Country: 'Switzerland', description: '100% Swiss', site: Assets_.pasinoLogo, link: 'pasino.ch', country: 'ch', languages: ['de', 'us', 'fr', 'it'], Languages: ['deutsch', 'english', 'français', 'italiano']})

        for (let i: number = 0; i < customers.length; i++) {
            dp.push (
                <a title = {Assets.Visit + ' ' + Assets.Website.toLowerCase()} target = "_blank" onClick = {() => window.open('https://' + customers[i].link)} style = {{background: customers[i].background}} key = {i.toString()} className = "dashboard-item">
                    <img className = "img img-responsive" style = {{width: '95%', marginLeft: '2.5%', marginRight: '2.5%', position: 'absolute', top: '0px', bottom: '0px', marginTop: 'auto', marginBottom: 'auto'}} src = {customers[i].site}/>
                    {customers[i].company ?
                    <img className = "img img-responsive" style = {{position: 'absolute', top: '3px', right: '3px', maxHeight: '60px'}} src = {customers[i].company}/> : void 0}
                    {customers[i].country ?
                    <img alt = "" title = {customers[i].Country} src = {this.getFlag(customers[i].country)} className = "img img-responsive" style = {{position: 'absolute', top: '3px', left: '3px', maxWidth: '20px'}}/> : void 0}
                    {customers[i].languages && customers[i].Languages ?
                    <div className = "clearfix" style = {{position: 'absolute', bottom: '3px', right: '3px'}}>
                        {this.getImages(customers[i].languages, customers[i].Languages)}
                    </div> : void 0}
                    {customers[i].description ?
                    <div style = {{position: 'absolute', bottom: '3px', left: '3px', fontSize: '12px', lineHeight: '12px', color: customers[i].textColor}}>
                        {customers[i].description}
                    </div> : void 0}
                </a>
            )
        }
        return (
            <div className = "clearfix mt5">
                {dp}
            </div>
        )
    }
}