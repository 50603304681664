import axios from 'axios'
import * as React from 'react'
import '../../CSS/AppBar.css'
import { Logic } from '../../Logic/Type'
import { Main } from '../../Logic/Main'
import { RouterObjects } from '../../Logic/RouterObjects'
import { DisplayLoginEntity } from '../Reusables/UserStuff'
import {FormInput} from '../Reusables/Inputs'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import * as Config from '../../Logic/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {/*faUserCog, */faSyncAlt, faGamepad, faBriefcaseMedical, faLongArrowAltLeft, faLongArrowAltRight, faSearch, faCaretDown, faCaretUp, faUsersCog/*, faTimes/*, faEyeSlash/*, faUserCircle*/} from '@fortawesome/free-solid-svg-icons'
import * as DbRoutes from '../../Logic/Database/DbRoutes'
import {isUserSystem,
        isUserGameProvider,
        isUserOperator,
        isUserSiteOwner,
        isUserGameAssetProvider,
        // isUserGamingSite,
        isUserMaster,
        IsBuildOddyzzey,
        isPureScandibet,
        IsBuildJackpotsCH,
        isPureOddyzzey,
        isUserGDPR,
        IsBuildMyChance,
        IsBuildPasino,
        /*IsBuildScandibet()*/
/*
        isUserPaymentProvider,
        isUserComplianceProvider,
        isUserPaymentSubProvider,
        isUserComplianceSubProvider,
*/
        // readCookie,
        // gotoTop,
        } from '../../Logic/Utils'
import {/*DisplayTime,*/ Clock } from '../Reusables/Time'
import {getTranslations} from '../Main/FrontendStore/SiteSettings'
import {getLottobetReportCount} from '../Main/GameStore/LottobetReport'
import {getContractualPartnerCount} from '../Main/UserManagement/ManageContractualPartners'
import {getSubEntityCount} from '../Main/UserManagement/ManageSubEntity'
import {getHoldingCount} from '../Main/UserManagement/ManageHolding'
import {getSystemUsersCount} from '../Main/UserManagement/ManageAdminUsersSystem'
import {getMasterUsersCount} from '../Main/UserManagement/ManageAdminUsersMaster'
import {getOperatorsUsersCount} from '../Main/UserManagement/ManageAdminUsersOperator'
// import {getSitesUsersCount} from '../Main/UserManagement/ManageAdminUsersSite'
import {getGameProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersGameProvider'
import {getGameAssetProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersGameAssetProvider'
/*
import {getPaymentProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersPaymentProvider'
import {getPaymentSubProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersPaymentSubProvider'
import {getComplianceProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersComplianceProvider'
import {getComplianceSubProvidersUsersCount} from '../Main/UserManagement/ManageAdminUsersComplianceSubProvider'
*/
// import {getPlayersPointsCount} from '../Main/Reports/PlayersPoints' 
import {getBrandOwnerUsersCount} from '../Main/UserManagement/ManageAdminUsersBrandOwner'
import {getLevelsCount} from '../Main/PromoStore/Levels'
// import {getRSTransactionsCount} from '../Main/PromoStore/RewardShopTransactions'
// import {getRewardShopCount} from '../Main/PromoStore/RewardShop'
import {getPlayersPointsCount,
        getPlayersPoints1,
        getPlayersPoints2} from '../Main/Reports/PlayersPoints'
import Cookies from 'js-cookie'
import {SelectGamesNotDelete} from '../Main/PromoStore/GamesSelection'
// import {FileExportFilterGames} from '../Main/GameStore/FileExport'
import {CmsExportFilterGames} from '../Main/GameStore/GameListCmsExport'
// import {AllGamesFilterGames, setApprovedGames} from '../Main/GameStore/AllGamesNew'
import {setCentralGames/*, setApprovedGames*/} from '../Main/GameStore/GameList'
import {getPaginatedData} from '../Main/Reports/StorePurchases'
import {getFilteredData} from '../Main/Reports/UsersInfo'
import {getUserData} from '../Main/Reports/UserInfo'
import {getChancesData} from '../Main/Reports/TournamentLeaderboard'

// import {SelectPlayer} from '../Main/RiskComplianceStore/PlayerCard'
function ApplySearch(search_string: string) {
    // console.log ('ApplySearch')
    //  console.log('start')
   
    if (IsBuildOddyzzey() || IsBuildJackpotsCH() || IsBuildPasino()) {
        // DEBUG
        // console.log (Main.Instance.Location)
/*
        if(RouterObjects['front___End_Store'].children['Shop_Settings'])
            console.log (RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Shop_Settings'].path)
        if(RouterObjects['front___End_Store'].children['Export_to_CMS'])
            console.log (RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Export_to_CMS'].path)
*/      
        if (RouterObjects['account_Store'] && 
        (RouterObjects['account_Store'].children['Player_Card'] && Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Player_Card'].path
        || RouterObjects['account_Store'].children['Playercard'] && Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Playercard'].path
        )
        ) {
            if (search_string.length === 0) {
                // console.log ('empty')
                Logic.Type.New (Main.Instance.PlayerCardState, {
                    GameSessions: false,
                    gameSessions: undefined,
                    gameSessionsTransactions: undefined,
                    gameSessionSelectedId: undefined,
                    // BonusInfo: false,
                    // eventsTimeline: [], 
                    // search_id: undefined, 
                    search_string: undefined, 
                    id: '',
                    forcePlayerCardSelect: false
                    // , playerSelectionDisabled: false
                })
                // Logic.Type.New (Main.Instance.PlayerCardState, {forcePlayerCardSelect: false})
                // SelectPlayer(0)
/*
            } else if (!isNaN(search_string as any)) {
                Logic.Type.New (Main.Instance.PlayerCardState, {
                    GameSessions: false,
                    gameSessions: undefined,
                    gameSessionsTransactions: undefined,
                    gameSessionSelectedId: undefined,
                    BonusInfo: false,
                    // eventsTimeline: [],
                    search_id: +search_string,
                    search_string: undefined,
                    id: ''
                })
                // SelectPlayer()
                console.log ('number')
                Logic.Type.New (Main.Instance.PlayerCardState, {forcePlayerCardSelect: true})
*/
/*
            } else if (isUserGDPR()) {
                Logic.Type.New (Main.Instance.PlayerCardState, {search_string: search_string, search_id: undefined, id: ''})
*/
            } else if (search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                // console.log ('now search in player card')
                // console.log (search_string)
                Logic.Type.New (Main.Instance.PlayerCardState, {
                    showSearchSettings: false,
                    GameSessions: false,
                    gameSessions: undefined,
                    gameSessionsTransactions: undefined,
                    gameSessionSelectedId: undefined,
                    // BonusInfo: false,
                    // eventsTimeline: [],
                    search_string,
                    // search_id: undefined,
                    id: '',
                    forcePlayerCardSelect: true
                    // , playerSelectionDisabled: false
                })
                // SelectPlayer()
                // console.log('searchedFor: ' + Main.Instance.PlayerCardState.search_string)
                // Logic.Type.New (Main.Instance.PlayerCardState, {forcePlayerCardSelect: true})
            }
            // console.log (Main.Instance.PlayerCardState)
/*
            Logic.Type.New (Main.Instance.DatabaseDataState, {
                GameSessions: false, gameSessions: undefined, gameSessionsTransactions: undefined, gameSessionSelectedId: undefined
            })
*/
            // DbRoutes.get
/*
            console.log ('bonusInfo')
            console.log (Main.Instance.DatabaseDataState.bonusInfo)
            console.log ('bonusInfo')
            console.log (Main.Instance.DatabaseDataState.bonusInfo)
*/
        } else if (Main.Instance.Modal && Main.Instance.Modal.ModalWagerPlanGames === true/* || RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Games_Selection'] && Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Games_Selection'].path*/) {
            SelectGamesNotDelete(Main.Instance.RewardShopState.category_idx)
        } else if (RouterObjects['front___End_Store'] && RouterObjects['front___End_Store'].children['Export_to_CMS'] &&
        Main.Instance.Location === RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Export_to_CMS'].path) {
            // FileExportFilterGames()
            if (search_string.length === 0) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string: undefined})
            } else if (search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string})
            }
            CmsExportFilterGames()
        } else if (RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Gamingenius_Game_List'] &&
        Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Gamingenius_Game_List'].path) {
            // AllGamesFilterGames()
            if (search_string.length === 0) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string: undefined})
            } else if (search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string})
            }
            setCentralGames()
        } 
        /*else if (RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Approved_Games_Store'] &&
        Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Approved_Games_Store'].path) {
            if (search_string.length === 0) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string: undefined})
            } else if (search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
                Logic.Type.New(Main.Instance.GamesCentralState, {search_string})
            }
            setApprovedGames()
        }
        */

    } else if (IsBuildMyChance()) {
        if (search_string.length === 0) {
            Logic.Type.New(Main.Instance.UsersInfoState, {search_string: undefined})
        } else if (search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
            Logic.Type.New(Main.Instance.UsersInfoState, {search_string})
        }


        if (RouterObjects['reports'] && RouterObjects['reports'].children['Store_Purchases'] && Main.Instance.Location === RouterObjects['reports'].path +
        RouterObjects['reports'].children['Store_Purchases'].path) {
            Logic.Type.New(Main.Instance.StorePurchasesState, {pNum: 1})
            getPaginatedData(1, 10, search_string)
        } else if (RouterObjects['reports'] && RouterObjects['reports'].children['Users_Info'] && Main.Instance.Location === RouterObjects['reports'].path +
        RouterObjects['reports'].children['Users_Info'].path) {
            getFilteredData()
        } else if (RouterObjects['reports'] && RouterObjects['reports'].children['User_Info'] && Main.Instance.Location === RouterObjects['reports'].path +
        RouterObjects['reports'].children['User_Info'].path) {
            getUserData()
        } else if (RouterObjects['reports'] && RouterObjects['reports'].children['Chances_Leaderboard'] && Main.Instance.Location === RouterObjects['reports'].path +
        RouterObjects['reports'].children['Chances_Leaderboard'].path) {
            Logic.Type.New(Main.Instance.MissionLeaderboardState, {selectedPage: 0})
            getChancesData()
        }


    } else if (RouterObjects['reports'] && RouterObjects['reports'].children['Report_Players__Points'] && Main.Instance.Location === RouterObjects['reports'].path +
        RouterObjects['reports'].children['Report_Players__Points'].path) {
        if (!Main.Instance.ReportPState.player) {
            if (!Main.Instance.ReportPState.specificQuery) {
                getPlayersPointsCount()
            } else if (!Main.Instance.ReportPState.compareDate) {
                getPlayersPoints1()
            } else {
                getPlayersPoints1()
                getPlayersPoints2()
            }
        }
    } else if (RouterObjects['game_Store'] && RouterObjects['game_Store'].children['LottoBet_Report'] &&
              Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['LottoBet_Report'].path) {
        if (!isNaN(search_string as any)) {
            Logic.Type.New (Main.Instance.LottoTransactionsState, {playerid: +search_string})
            getLottobetReportCount()
        }
    } else if (RouterObjects['front___End_Store'] && RouterObjects['front___End_Store'].children['Shop_Settings'] && 
    Main.Instance.Location === RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Shop_Settings'].path) {
        getTranslations()
    } else if (RouterObjects['gamification'] && RouterObjects['gamification'].children['Levels_Management'] &&
        Main.Instance.Location === RouterObjects['gamification'].path + RouterObjects['gamification'].children['Levels_Management'].path) {
        getLevelsCount()
    } else if (RouterObjects['gamification'] && RouterObjects['gamification'].children['Reward_Shop'] &&
        Main.Instance.Location === RouterObjects['gamification'].path + RouterObjects['gamification'].children['Reward_Shop'].path) {
    } else if (RouterObjects['user_Management'] && RouterObjects['user_Management'].children['Manage_Masters'] &&
        Main.Instance.Location === RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Masters'].path) {
        getHoldingCount()
    } else if (RouterObjects['user_Management'] && RouterObjects['user_Management'].children['Manage_Contractual_Partners'] &&
        Main.Instance.Location === RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path) {
        getContractualPartnerCount()
    } else if (RouterObjects['user_Management'] && RouterObjects['user_Management'].children['Manage_Sub_Entities'] &&
        Main.Instance.Location === RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Sub_Entities'].path) {
        getSubEntityCount()
    } else if (RouterObjects['user_Management'] && RouterObjects['user_Management'].children['Manage_Admin_Users'] &&
        Main.Instance.Location === RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Admin_Users'].path) {

        if (isUserSystem()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getSystemUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getMasterUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 3) {
                getOperatorsUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 4) {
                getSitesUsersCount()
*/
            } else if (Main.Instance.ManageState.selectionUsers === 5) {
                getGameProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 6) {
                getGameAssetProvidersUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 7) {
                getPaymentProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 8) {
                getPaymentSubProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 9) {
                getComplianceProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 10) {
                getComplianceSubProvidersUsersCount()
*/
            } else if (Main.Instance.ManageState.selectionUsers === 11) {
                getBrandOwnerUsersCount()
            }
        } else if (isUserMaster()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getMasterUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getOperatorsUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 3) {
                getSitesUsersCount()
*/
            } else if (Main.Instance.ManageState.selectionUsers === 4) {
                getGameProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 5) {
                getGameAssetProvidersUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 6) {
                getPaymentProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 7) {
                getPaymentSubProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 8) {
                getComplianceProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 9) {
                getComplianceSubProvidersUsersCount()
*/
            } else if (Main.Instance.ManageState.selectionUsers === 10) {
                getBrandOwnerUsersCount()
            }
        } else if (isUserOperator()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getOperatorsUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getSitesUsersCount()
*/
            }
        } else if (isUserGameProvider()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getGameProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getGameAssetProvidersUsersCount()
            }
/*
        } else if (isUserPaymentProvider()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getPaymentProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getPaymentSubProvidersUsersCount()
            }
        } else if (isUserComplianceProvider()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getComplianceProvidersUsersCount()
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getComplianceSubProvidersUsersCount()
            }
*/
        } else if (isUserSiteOwner()) {
            if (Main.Instance.ManageState.selectionUsers === 1) {
                getBrandOwnerUsersCount()
/*
            } else if (Main.Instance.ManageState.selectionUsers === 2) {
                getSitesUsersCount()
*/
            }
/*
        } else if (isUserGamingSite()) {
            getSitesUsersCount()
*/
        } else if (isUserGameAssetProvider()) {
            getGameAssetProvidersUsersCount()
/*
        } else if (isUserPaymentSubProvider()) {
            getPaymentSubProvidersUsersCount()
        } else if (isUserComplianceSubProvider()) {
            getComplianceSubProvidersUsersCount()
*/
        }
    }
    Logic.Type.New(Main.Instance.SearchState, {search_string})
    // console.log('end')
}

function healthCheck() {
    axios.get(Config.psCheckLastId + '?customer=scandibet&threshold=100').then((response) => {
        const p1: number = response.data.indexOf(Assets.LastidTimestamp + ':') + (Assets.LastidTimestamp + ':').length
        const p2: number = response.data.indexOf(' ' + Assets.Difference + ':')
        const Health: boolean = response.data.substring(0,3) === 'OK!' ? true : false
        Logic.Type.New (Main.Instance, {Health, HealthInfo: Assets.LastProcessedEventAt + ':' + response.data.substring(p1,p2)})
    })
}

function gamesCheck() {
    axios.get(Config.psGamesCheckLastUpdate + '?customer=' + (isPureScandibet() ? 'scandibet' : isPureOddyzzey() ? 'oddyzzey' : ''))
    .then((response) => {
        Logic.Type.New (Main.Instance, {GamesInfo: Assets.GamesWereUpdatedForTheLastTimeOn + ' ' + response.data.updated.substring(0,10) + ' ' + response.data.updated.substring(11,19) + ' ' + Assets_.UTC})
    })
}

export function AppBar() {
    if (Main.Instance.LoginState === null || Main.Instance.LoginState.entity === undefined) {
        return (
            <nav className = "navbar appbar">
            <div className = "nav">
                {/*<span className = "w5p" style = {{paddingLeft: '0px'}}>*/}
                <a
                    style = {{position: 'relative', padding: '2px 5px 10px 2px', borderRadius: '0px'}}
                    className = "btn appbar-btn fl dbl"
                    onClick = {() => Logic.Type.New(Main.Instance.MenuState, {Open: !Main.Instance.MenuState.Open})}
                >
                    <img className = "dbl" height = "35" src = {Assets_.logoImage}/>
                    <FontAwesomeIcon style = {{position: 'absolute', top: '27px', left: '3px', color: '#fff'}} className = "dbl" icon = {Main.Instance.MenuState.Open ? faLongArrowAltRight : faLongArrowAltLeft}/>
                </a>
                {/*</span>*/}

                <div className = "sidebar-brand">
                    &nbsp;&nbsp;{Assets_.productName[0]}<sup style ={{top: '-1em', fontSize: '14px'}}>{Assets_.productName[1]}</sup>{Assets_.productName[2] + ' ' + Assets.ForgotPasswordResetForm}
                </div>
                {/*<div className = "clearfix"/>*/}
            </div>
        </nav>
        )
    }

    if ((isPureScandibet() || isPureOddyzzey()) && Main.Instance.HealthInfo === undefined) {
        Logic.Type.New (Main.Instance, {HealthInfo: ''})
        if (isPureScandibet()) {
            healthCheck()
            setInterval(healthCheck, 10000)
            // gamesCheck()
        }
        gamesCheck()
    }

    return(
        <nav className = "navbar appbar">
            <div className = "nav">
                {/*<span className = "w5p">*/}
                <a
                    style = {{position: 'relative', padding: '2px 5px 10px 2px', borderRadius: '0px'}}
                    className = "btn appbar-btn fl dbl"
                    onClick = {() => Logic.Type.New(Main.Instance.MenuState, {Open: !Main.Instance.MenuState.Open})}
                >
                    <img height = "35" src = {Assets_.logoImage}/>
                    <FontAwesomeIcon
                        style = {{position: 'absolute', top: '27px', left: '3px', color: '#fff'}}
                        size = "2x"
                        icon = {Main.Instance.MenuState.Open ? faLongArrowAltRight : faLongArrowAltLeft}
                    />
                </a>

                <div className = "sidebar-brand">
                    &nbsp;&nbsp;{Assets_.productName[0]}<sup style ={{top: '-1em', fontSize: '14px'}}>{Assets_.productName[1]}</sup>{Assets_.productName[2]}
                </div>
                <div 
                onClick = {() => {
                    if (RouterObjects['account_Store'] && (
                        RouterObjects['account_Store'].children['Player_Card'] &&
                        Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Player_Card'].path
                        || RouterObjects['account_Store'].children['Playercard'] &&
                        Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Playercard'].path
                    )) {
                        Logic.Type.New (Main.Instance.PlayerCardState, {forcePlayerCardSelect: !Main.Instance.PlayerCardState.forcePlayerCardSelect})
                    }
                }} 
                className = "fl pr">
                    <FontAwesomeIcon icon = {faSearch} style = {{color: '#999', top: '15px', left: '30px', position: 'absolute' }} size = "lg"/>
                    <FormInput
                        ClassName = "search-box"
                        placeholder = {
                            RouterObjects['account_Store'] && (
                                RouterObjects['account_Store'].children['Player_Card'] &&
                                Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Player_Card'].path
                                || RouterObjects['account_Store'].children['Playercard'] &&
                                Main.Instance.Location === RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Playercard'].path
                            )
                            ?
                            Assets.Search + ' ' + Assets.Player.toLowerCase() :

                            RouterObjects['front___End_Store'] && RouterObjects['front___End_Store'].children['Export_to_CMS'] &&
                            Main.Instance.Location === RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Export_to_CMS'].path ?
                            Assets.Search + ' ' + Assets.Game_s.toLowerCase() :

                            RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Gamingenius_Game_List'] &&
                            Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Gamingenius_Game_List'].path ?
                            Assets.Search + ' ' + Assets.Game_s.toLowerCase() :

                            RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Games_Selection'] &&
                            Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Games_Selection'].path ?
                            Assets.Search + Assets._by_ + Assets.GameName.toLowerCase() + Assets._or_ + Assets.Game.toLowerCase() + ' ' + Assets.ID :

                            RouterObjects['game_Store'] && RouterObjects['game_Store'].children['Approved_Games_Store'] &&
                            Main.Instance.Location === RouterObjects['game_Store'].path + RouterObjects['game_Store'].children['Approved_Games_Store'].path ?
                            Assets.Search + ' ' + Assets.Game_s.toLowerCase() :

                            IsBuildMyChance() && RouterObjects['reports'] && (
                            (RouterObjects['reports'].children['Users_Info'] && Main.Instance.Location === RouterObjects['reports'].path + RouterObjects['reports'].children['Users_Info'].path)
                            || (RouterObjects['reports'].children['Store_Purchases'] && Main.Instance.Location === RouterObjects['reports'].path + RouterObjects['reports'].children['Store_Purchases'].path)
                            || (RouterObjects['reports'].children['User_Info'] && Main.Instance.Location === RouterObjects['reports'].path + RouterObjects['reports'].children['User_Info'].path)
                            || (RouterObjects['reports'].children['Chances_Leaderboard'] && Main.Instance.Location === RouterObjects['reports'].path + RouterObjects['reports'].children['Chances_Leaderboard'].path)
                            ) ?
                            Assets.Search + ' ' + Assets.Player.toLowerCase() :

                            Assets.Search} 
                        raw = {true}
                        value = {!Main.Instance.SearchState.search_string ? '' : Main.Instance.SearchState.search_string}
                        type = "text"
                        onChange = {(text) => {
                            ApplySearch(text)
                        }}
                    />
                    {((IsBuildJackpotsCH() || IsBuildPasino()) && Main.Instance.Location !== '/account-store/player-card' && Main.Instance.Location !== '/game-store/gamingenius-game-list' && Main.Instance.Location !== '/game-store/approved-games-store') || (IsBuildMyChance() && Main.Instance.Location !== '/reports/users-info' && Main.Instance.Location !== '/reports/user-info' &&  Main.Instance.Location !== '/reports/store-purchases' &&  Main.Instance.Location !== '/reports/chances-leaderboard') ||
                    Main.Instance.PlayerCardState.showSearchSettings ? void 0 :
                    <a
                        onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {showSearchSettings: !Main.Instance.PlayerCardState.showSearchSettings})}
                        className = "btn btn-sm btn-default"
                        style = {{background: 'transparent', border: '0px', position: 'absolute', top: '17px', right: '30px', padding: '0px'}}
                    >
                        <FontAwesomeIcon style = {{fontSize: '16px', color: '#999'}} icon = {/*Main.Instance.PlayerCardState.showSearchSettings ? faEyeSlash : */faCaretDown}/>
                    </a>}
                </div>
                {((IsBuildJackpotsCH() || IsBuildPasino()) && (Main.Instance.Location === '/account-store/player-card' || Main.Instance.Location === '/game-store/gamingenius-game-list' || Main.Instance.Location === '/game-store/approved-games-store') || IsBuildMyChance() && (Main.Instance.Location === '/reports/users-info' || Main.Instance.Location === '/reports/user-info'|| Main.Instance.Location === '/reports/store-purchases'|| Main.Instance.Location === '/reports/chances-leaderboard')) &&
                Main.Instance.PlayerCardState.showSearchSettings ?
                <div className = "pr" style = {{minHeight: '100px', width: '352px', position: 'fixed', left: '368px', top: '41px', background: '#fff', padding: '5px', zIndex: 9999, borderBottom: '1px solid #293133', borderLeft: '1px solid #293133', borderRight: '1px solid #293133'}}>
                    <a
                        onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {showSearchSettings: !Main.Instance.PlayerCardState.showSearchSettings})}
                        className = "btn btn-sm btn-default"
                        style = {{background: 'transparent', border: '0px', position: 'absolute', top: '5px', right: '5px', padding: '0px'}}
                    >
                        <FontAwesomeIcon style = {{fontSize: '14px', color: '#999'}} icon = {faCaretUp}/>
                    </a>
                    <h5 style = {{marginTop: '5px', marginBottom: '5px', fontWeight: 700}}>
                        <FontAwesomeIcon style = {{fontSize: '14px'}} icon = {faUsersCog}/>
                        &nbsp;
                        {Main.Instance.Location === '/account-store/player-card' ? Assets.Player_ + ' ' + Assets.Card.toLowerCase()+ ' ' + Assets.Search.toLowerCase() + ' ' + Assets.Settings.toLowerCase() : (Main.Instance.Location === '/game-store/gamingenius-game-list' || Main.Instance.Location === '/game-store/approved-games-store') ? Assets.Games + ' ' + Assets.Search.toLowerCase() + ' ' + Assets.Settings.toLowerCase() : void 0 }
                    </h5>
                    <div>
                        <span style = {{lineHeight: '22px'}}>{Assets.MinimumNumberOfCharactersToSearch}:</span>
                        <input
                            value = {Main.Instance.SearchState.minLengthToSearch}
                            onChange = {(e) => {
                                Cookies.set('minLengthToSearch', e.target.value, { path: '/' })
                                // document.cookie = 'minLengthToSearch=' + e.target.value
                                Logic.Type.New (Main.Instance.SearchState, {minLengthToSearch: +e.target.value})
                            }}
                            type = "number"
                            min = "1"
                            style = {{height: '20px', width: '33px', marginLeft: '5px', paddingLeft: '5px', paddingRight: '0px', paddingTop: '0px'}}
                        />
                    </div>

                    <div style = {{marginTop: '1px'}}>
                        <span style = {{marginRight: '5px', lineHeight: '22px'}}>{Assets.SearchOnlyAtTheStartOfPhrase}:</span>
                        <button
                            onClick = {() => {
                                Cookies.set('searchOnlyAtTheStartOfPhrase', !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase, { path: '/' })
                                // document.cookie = 'searchOnlyAtTheStartOfPhrase=' + !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase
                                Logic.Type.New (Main.Instance.SearchState, {searchOnlyAtTheStartOfPhrase: !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase})
                                if (Main.Instance.Location === '/reports/users-info') {getFilteredData()}
                                if (Main.Instance.Location === '/reports/chances-leaderboard') {getChancesData()}
                            }}
                            style = {{padding: '0px 4px'}}
                            className = {!Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase ? 'btn btn-default btn-xs mr0 br0' : 'btn btn-success btn-xs mr0 br0'}
                        >
                            {Assets.Yes}
                        </button>
                        <button
                            onClick = {() => {
                                Cookies.set('searchOnlyAtTheStartOfPhrase', !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase, { path: '/' })
                                // document.cookie = 'searchOnlyAtTheStartOfPhrase=' + !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase
                                Logic.Type.New (Main.Instance.SearchState, {searchOnlyAtTheStartOfPhrase: !Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase})
                                if (Main.Instance.Location === '/reports/users-info') {getFilteredData()}
                                if (Main.Instance.Location === '/reports/chances-leaderboard') {getChancesData()}
                            }}
                            style = {{padding: '0px 5px'}}
                            className = {!Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}
                        >
                            {Assets.No}
                        </button>
                    </div>
                    {Main.Instance.Location === '/account-store/player-card' ?
                    <div style = {{marginTop: '1px'}}>
                        <span style = {{marginRight: '4px', lineHeight: '22px'}}>
                            {Assets.Search + ' ' + Assets.For.toLowerCase()}:
                        </span>

                        <button
                            disabled = {Main.Instance.SearchState.playerCardPlayerId === undefined}
                            onClick = {() => {
                                Cookies.set('playerCardPlayerId', !Main.Instance.SearchState.playerCardPlayerId, { path: '/' })
                                // document.cookie = 'playerCardPlayerId=' + !Main.Instance.SearchState.playerCardPlayerId
                                Logic.Type.New (Main.Instance.SearchState, {playerCardPlayerId: !Main.Instance.SearchState.playerCardPlayerId})
                            }}
                            style = {{padding: '0px 3px'}}
                            className = {Main.Instance.SearchState.playerCardPlayerId === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.playerCardPlayerId ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.ID}
                        </button>

                        <button
                            disabled = {Main.Instance.SearchState.playerCardUsername === undefined}
                            onClick = {() => {
                                // document.cookie = 'playerCardUsername=' + !Main.Instance.SearchState.playerCardUsername
                                Cookies.set('playerCardUsername', !Main.Instance.SearchState.playerCardUsername, { path: '/' })
                                Logic.Type.New (Main.Instance.SearchState, {playerCardUsername: !Main.Instance.SearchState.playerCardUsername})
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {Main.Instance.SearchState.playerCardUsername === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.playerCardUsername ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.Username}
                        </button>

                        <button
                            disabled = {!isUserGDPR() || Main.Instance.SearchState.playerCardFirst === undefined}
                            onClick = {() => {
                                Cookies.set('playerCardFirst', !Main.Instance.SearchState.playerCardFirst, { path: '/' })
                                // document.cookie = 'playerCardFirst=' + !Main.Instance.SearchState.playerCardFirst
                                Logic.Type.New (Main.Instance.SearchState, {playerCardFirst: !Main.Instance.SearchState.playerCardFirst})
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {!isUserGDPR() || Main.Instance.SearchState.playerCardFirst === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.playerCardFirst ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.FirstName}
                        </button>

                        <button
                            disabled = {!isUserGDPR() || Main.Instance.SearchState.playerCardLast === undefined}
                            onClick = {() => {
                                Cookies.set('playerCardLast', !Main.Instance.SearchState.playerCardLast, { path: '/' })
                                // document.cookie = 'playerCardLast=' + !Main.Instance.SearchState.playerCardLast
                                Logic.Type.New (Main.Instance.SearchState, {playerCardLast: !Main.Instance.SearchState.playerCardLast})
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {!isUserGDPR() || Main.Instance.SearchState.playerCardLast === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.playerCardLast ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.LastName}
                        </button>

                        <button
                            disabled = {!isUserGDPR() || Main.Instance.SearchState.playerCardEmail === undefined}
                            onClick = {() => { 
                                Cookies.set('playerCardEmail', !Main.Instance.SearchState.playerCardEmail, { path: '/' })
                                // document.cookie = 'playerCardEmail=' + !Main.Instance.SearchState.playerCardEmail
                                Logic.Type.New (Main.Instance.SearchState, {playerCardEmail: !Main.Instance.SearchState.playerCardEmail})
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {!isUserGDPR() || Main.Instance.SearchState.playerCardEmail === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.playerCardEmail ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.Email}
                        </button>
                    </div> : void 0}
                    {Main.Instance.Location === '/game-store/gamingenius-game-list' || Main.Instance.Location === '/game-store/approved-games-store' ?
                    <div style = {{marginTop: '1px'}}>
                        <span style = {{marginRight: '4px', lineHeight: '22px'}}>
                            {Assets.Search + ' ' + Assets.For.toLowerCase()}:
                        </span>

                        <button
                            disabled = {Main.Instance.SearchState.gameStoreGameId === undefined}
                            onClick = {() => {
                                Cookies.set('gameStoreGameId', !Main.Instance.SearchState.gameStoreGameId, { path: '/' })
                                // document.cookie = 'playerCardPlayerId=' + !Main.Instance.SearchState.playerCardPlayerId
                                Logic.Type.New (Main.Instance.SearchState, {gameStoreGameId: !Main.Instance.SearchState.gameStoreGameId})
                            }}
                            style = {{padding: '0px 3px'}}
                            className = {Main.Instance.SearchState.gameStoreGameId === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.gameStoreGameId ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.ID}
                        </button>

                        <button
                            disabled = {Main.Instance.SearchState.gameStoreGameName === undefined}
                            onClick = {() => {
                                // document.cookie = 'playerCardUsername=' + !Main.Instance.SearchState.playerCardUsername
                                Cookies.set('gameStoreGameName', !Main.Instance.SearchState.gameStoreGameName, { path: '/' })
                                Logic.Type.New (Main.Instance.SearchState, {gameStoreGameName: !Main.Instance.SearchState.gameStoreGameName})
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {Main.Instance.SearchState.gameStoreGameName === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.SearchState.gameStoreGameName ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.Name}
                        </button>
                    </div> : void 0}
                    {IsBuildMyChance() && (Main.Instance.Location === '/reports/users-info' || Main.Instance.Location === '/reports/user-info'|| Main.Instance.Location === '/reports/store-purchases'|| Main.Instance.Location === '/reports/chances-leaderboard') ?
                    <div style = {{marginTop: '1px'}}>
                        <span style = {{marginRight: '4px', lineHeight: '22px'}}>
                            {Assets.Search + ' ' + Assets.For.toLowerCase()}:
                        </span>

                        <button
                            disabled = {Main.Instance.UsersInfoState.playerId === undefined}
                            onClick = {() => {
                                // Cookies.set('playerCardPlayerId', !Main.Instance.UsersInfoState.playerId, { path: '/' })
                                // document.cookie = 'playerCardPlayerId=' + !Main.Instance.SearchState.playerCardPlayerId
                                Logic.Type.New (Main.Instance.UsersInfoState, {playerId: !Main.Instance.UsersInfoState.playerId})
                                if (Main.Instance.Location === '/reports/users-info') {getFilteredData()}
                                if (Main.Instance.Location === '/reports/chances-leaderboard') {getChancesData()}
                            }}
                            style = {{padding: '0px 3px'}}
                            className = {Main.Instance.UsersInfoState.playerId === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.UsersInfoState.playerId ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.ID}
                        </button>

                        <button
                            disabled = {Main.Instance.UsersInfoState.username === undefined}
                            onClick = {() => {
                                // document.cookie = 'playerCardUsername=' + !Main.Instance.SearchState.playerCardUsername
                                // Cookies.set('playerCardUsername', !Main.Instance.UsersInfoState.username, { path: '/' })
                                Logic.Type.New (Main.Instance.UsersInfoState, {username: !Main.Instance.UsersInfoState.username})
                                if (Main.Instance.Location === '/reports/users-info') {getFilteredData()}
                                if (Main.Instance.Location === '/reports/chances-leaderboard') {getChancesData()}
                            }}
                            style = {{padding: '0px 3px', marginLeft: '2px'}}
                            className = {Main.Instance.UsersInfoState.username === undefined ? 'btn btn-default btn-xs mr0 br0' : Main.Instance.UsersInfoState.username ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-danger btn-xs mr0 br0'}
                        >
                            {Assets.Username}
                        </button>

                    </div>
                    : void 0}

                </div> : void 0}
                <Clock/>
                <button title = {Assets.Refresh} style = {{position: 'absolute', top: '5px', right: '29px', padding: '0px 3px', zIndex: 9999}} disabled = {!Main.Instance.DatabaseDataState.RefreshButtonEnabled} onClick = {() => DbRoutes.wsReinitialize(true)} className = "btn btn-danger btn-sm mr0">
                    <FontAwesomeIcon icon = {faSyncAlt}/>
                    {/*&nbsp;&nbsp;{Assets.Refresh}*/}
                </button>
                {Config.ENVIRONMENT_SHOW ?
                <span title = {Config.ENVIRONMENT__[Config.environment]} style = {{fontWeight: 700, color: '#fff', fontSize: '12px', position: 'absolute', top: '2px', left: '70px', padding: '0px', zIndex: 9999}}>{Config.ENVIRONMENT_[Config.environment]}</span>
                : void 0}
                <div className = "fr pr clearfix" style = {{height: '50px', paddingTop: '5px'}}>
                    {/*<div title = {Assets.loggedInAs} className = "fl white"><FontAwesomeIcon icon = {faUserCircle} size = "lg"/>&nbsp;</div>*/}
                    {/*<div title = {Assets.loggedInAs} className = "fl bold white">{Main.Instance.LoginState.Username}&nbsp;&nbsp;&nbsp;</div>*/}
                    
                    {/*<div className = "fl menu-role white">{Assets.Role_}&nbsp;</div>*/}
                    {/*<div className = "fl bold white">{Main.Instance.LoginState.roles}&nbsp;&nbsp;&nbsp;</div>*/}
                    
                    <div style = {{marginRight: '60px'}} className = "fl">
                        <DisplayLoginEntity/>
                    </div>
                    {isPureScandibet() || isPureOddyzzey() ?
                    <> 
                    <button
                        disabled = {!Main.Instance.FrontendStoreState.skin_id}
                        className = "btn btn-xs btn-primary gamepad-update-button"
                        title = {Assets.UpdateTheGamesDatabase}
                        onClick = {() => {
                            if (Main.Instance.FrontendStoreState.skin_id !== undefined && Main.Instance.FrontendStoreState.jwt !== undefined) {
                                // alert ('PRODUCTION GAMES')
                                axios.get(Config.psAleaccGamesUpdate + '?skin_id=' + Main.Instance.FrontendStoreState.skin_id + '&jwt=' + Main.Instance.FrontendStoreState.jwt)
                                // .then((response) => {
                                    // alert ('here')
                                    // alert (JSON.stringify(response))
                                    // alert (response.data.data.length + ' games were inserted into the database.')
                                    // gamesCheck()
                                    // DbRoutes.setGamesAleacc (response.data)
                                    // DbRoutes.getGamesAleacc(Main.Instance.FrontendStoreState.skin_id, Main.Instance.FrontendStoreState.jwt)
                                // })
                            } // else {alert ('Sorry, there are no credentials. ' + JSON.stringify(Main.Instance.FrontendStoreState.custom))}
                        }}
                    >
                        <FontAwesomeIcon icon = {faSyncAlt}/>
                    </button>
                    <span
                        className = "gamepad-icon"
                        style = {{color: '#fff'}}
                        title = {Main.Instance.GamesInfo}
                    >
                        <FontAwesomeIcon icon = {faGamepad} size = "2x"/>
                    </span>
                    {isPureScandibet() ?
                    <span 
                        style = {{color: Main.Instance.Health ? '#5cb85c' : '#d9534f'}}
                        title = {Main.Instance.HealthInfo}
                        className = "health-check"
                    >
                        <FontAwesomeIcon icon = {faBriefcaseMedical} size = "2x"/>
                    </span> : void 0}
                    </> : void 0}
                    {/*
                    <a
                        title = {Assets.UserSettings}
                        className = "settings"
                        style = {{color: Main.Instance.Location === RouterObjects['userSettings'].path ? 'rgb(243,139,0)' : ''}}
                        onClick = {() => {
                            DbRoutes.insertAdminLog (
                                Config.asAdminLogView,
                                Config.asAdminLogUserSettings, 
                                {},
                            )
                            Logic.Type.New(Main.Instance, {Location: RouterObjects['userSettings'].path})}
                        }
                    >
                        <FontAwesomeIcon style = {{fontSize: '23px'}} icon = {faUserCog} size = "lg"/>
                    </a>
                    */}
                    <a title = {Assets.Logout} className = "logoff" onClick = {() => logOut(false)}>
                        <i className = {Assets_.faPowerOffClass + ' fa-2x'}/>
                    </a>
                    {/*IsBuildJackpotsCH() || IsBuildPasino() ?
                    <button onClick = {() => test()} className = "btn btn-default btn-sm" title = {Assets_.productName[0] + Assets_.productName[1] + Assets_.productName[2] + ' ' + Assets.Language.toLowerCase()} style = {{position:'absolute', right: '1px', top: '28px', color: '#fff', fontWeight: 600}}>
                        {Config.Languages[Config.Language]}
                    </button> : void 0*/}

                    {/*IsBuildJackpotsCH() || IsBuildPasino() ?*/}
                    <span title = {Assets_.productName[0] + Assets_.productName[1] + Assets_.productName[2] + ' ' + Assets.Language.toLowerCase()} style = {{position:'absolute', right: '1px', top: '28px', color: '#fff', fontWeight: 600}}>
                        {Config.Languages[Config.Language]}
                    </span> {/*: void 0*/}
                    {/* <span style = {{position:'absolute', right: '80px', top: '30px', color: '#fff'}}>{Assets_.strapi(Main.Instance.DatabaseDataState.strapiConnected === true ? '#5cb85c' : Main.Instance.DatabaseDataState.strapiConnected === false ? '#d9534f' : '#fff', 16, 0, 4)}</span> */}
                    {/*
                    <span title = {Assets.Language} style = {{position:'absolute', right: '0px', top: '28px', color: '#fff', fontWeight: 900}}>
                        {Config.LanguageAbbrev}
                    </span>
                    */}
                </div>
            </div>
        </nav>
    )
}
/*
function test() {
    console.log ('test ', Main.Instance.LoginState.entityId)
    axios.get(Config.asNewUser, {
        params: {
            // entityId: Main.Instance.LoginState.entityId,
            // sortById: true
        },
        headers: {'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId}
    }).then((response) => {
        console.log (response)
    })
}
*/
/*
function logoutChecker(take: number) {
    console.log ('logoutChecker ' + take)
    const Location = readCookie('location')
    // const loginData = readCookie('loginData')
    console.log (Location)
    // console.log (loginData)
    if ((Location === null || Location === undefined || Location.length < 10)) {
        if (Main.Instance.LoginState) {
            Logic.Type.New (Main.Instance.LoginState, {
                // logout: true,
                securityAnswerCorrect: true,
                securityQuestion: 0,
                securityAnswer: '',
                showPassword: false,
                Username: '',
                Password: '',
                Password1: '',
                Password2: '',
                role: undefined,
                id: undefined,
                entityId: undefined,
                entity: undefined,
                wrongPassword: false,
                wrongApiKey: false,
                email: '',
                phone: '',
                loginMethod: 0,
                // custom: undefined,
                Verified: false,
            })
        }
        // location.replace('/')
        location.reload()
        return
    } else {
        deleteCookie('location')
        deleteCookie('loginData')
        setTimeout(() => logoutChecker(++take), 1000)
    }
}
*/
export function logOut(inactivityLogout: boolean) {

    Cookies.remove('location', { path: '/' })
    Cookies.remove('loginData', { path: '/' })
    
    
    if (inactivityLogout) {
        Cookies.set('inactivity', 'YES', { path: '/' })
        // document.cookie = 'inactivity=YES'
    } else {
        Cookies.set('inactivity', 'NO', { path: '/' })
        // document.cookie = 'inactivity=NO'
    }

    // console.log (Main.Instance.LoginState)
    DbRoutes.insertAdminLog (
        Config.asAdminLogLogout,
        inactivityLogout ? Config.asAdminLogLogoutInactivity: Config.asAdminLogLogoutOrdinary, 
        {},
    )
    Logic.Type.New (Main.Instance, {Location: '/login'})
    // console.log ('logoutChecker')
    // logoutChecker(0)
}