import * as React from 'react'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import { Main } from '../../Logic/Main';
// import {View} from '../View'
// import {Main} from '../../Logic/Main'
import {Logic} from '../../Logic/Type'
import {logOut} from './../AppBar/AppBar'
// import {Main} from '../../Logic/Main'
/* 
type DisplayTimeProps={//UTCOffset:string
    curDate:Date
}
*/
import * as Config from '../../Logic/Config'
export class Clock extends React.Component<{}, {date:Date}> {
  public tooSoonSeconds: number = 15
  public timerID: number
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  public componentDidMount() {
    this.timerID = window.setInterval(() => this.tick(), 1000);
  }

  public componentWillUnmount() {
    clearInterval(this.timerID);
  }

  public tick() {
    this.setState({date: new Date()});
  }
  public render() {
    const curDate = this.state.date
    const hoursNumber = curDate.getUTCHours()// +parseInt(props.UTCOffset)
    // if (hoursNumber >= 24) {hoursNumber -= 24}
    // if (hoursNumber < 0) {hoursNumber += 12}

    let hours = hoursNumber.toString()
    // hours += ''
    if (hours.length == 1) {hours = '0' + hours}
    
    let minutes = curDate.getUTCMinutes().toString();
    if (minutes.length == 1) {minutes = '0' + minutes;}

    let seconds = curDate.getUTCSeconds().toString();
    if (seconds.length == 1) { seconds = '0' + seconds;}
    // console.log(hours, minutes, seconds)

    let dd:string = curDate.getUTCDate().toString();
    let mm:string = (curDate.getUTCMonth() + 1).toString();
    const yyyy:string = curDate.getUTCFullYear().toString();
    if (dd.length == 1) {dd = '0' + dd;}
    if (mm.length == 1) {mm = '0' + mm;}

    const dayInWeek: string = Assets.DaysInWeek[curDate.getUTCDay()]
    const date: string = Assets_.UTC + ', ' + dayInWeek + ', ' + dd + '.' + mm + '.' + yyyy + ', ' + hours + ':' + minutes + ':' + seconds

    const HoursNumber = curDate.getHours()

    let Hours = HoursNumber.toString()
    // Hours += ''
    if (Hours.length == 1) {Hours = '0' + Hours}
    
    let Minutes = curDate.getMinutes().toString();
    if (Minutes.length == 1) {Minutes = '0' + Minutes;}

    let Seconds = curDate.getSeconds().toString();
    if (Seconds.length == 1) {Seconds = '0' + Seconds;}
    // console.log(hours, minutes, seconds)

    let DD:string = curDate.getDate().toString();
    let MM:string = (curDate.getMonth() + 1).toString();
    const YYYY:string = curDate.getFullYear().toString();
    if (DD.length == 1) {DD = '0' + DD;}
    if (MM.length == 1) {MM = '0' + MM;}

    const DayInWeek: string = Assets.DaysInWeek[curDate.getDay()]
    const date2: string = DayInWeek + ', ' + DD + '.' + MM + '.' + YYYY + ', ' + Hours + ':' + Minutes + ':' + Seconds

    // console.log (Main.Instance.LoginState.lastActionTime)
    const time: number = new Date().getTime()
    const Time: number = Math.floor(time / 1000)
    const withdrawalRequest1Time: boolean = Main.Instance.DatabaseDataState.WithdrawalRequest1Time === 0 || Time - Main.Instance.DatabaseDataState.WithdrawalRequest1Time < this.tooSoonSeconds ? true : false
    const withdrawalRequest2Time: boolean = Main.Instance.DatabaseDataState.WithdrawalRequest2Time === 0 || Time - Main.Instance.DatabaseDataState.WithdrawalRequest2Time < this.tooSoonSeconds ? true : false

    if (Main.Instance.DatabaseDataState.lastActionTime === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {
          lastActionTime: time,
          withdrawalRequest1Time,
          withdrawalRequest2Time,
        })
    } else if (withdrawalRequest1Time !== Main.Instance.DatabaseDataState.withdrawalRequest1Time || withdrawalRequest2Time !== Main.Instance.DatabaseDataState.withdrawalRequest2Time) {
      Logic.Type.New (Main.Instance.DatabaseDataState, {
        withdrawalRequest1Time,
        withdrawalRequest2Time,
      })
    }

    const difference: number = Main.Instance.DatabaseDataState.lastActionTime !== undefined ?
                               Math.round((Main.Instance.DatabaseDataState.lastActionTime - curDate.getTime()) / 1000 + Config.timeout) :
                               Config.timeout

    if (difference <= 0) {
        logOut(true)
    }
    const differenceMinutes: string = Math.floor(difference / 60).toString()
    const differenceSeconds: string = Math.floor(difference % 60).toString()
    const DifferenceMinutes: string = parseInt(differenceMinutes) < 10 ? '0' + differenceMinutes : differenceMinutes
    const DifferenceSeconds: string = parseInt(differenceSeconds) < 10 ? '0' + differenceSeconds : differenceSeconds

    return (
        <div className = "fl">
            <div className = "clearfix">
                <div className = "fl bold" title = {Assets.YouWillBeLoggedOutIn} style = {{color: difference < Config.timeoutRed ? 'red' : 'orange', width: '40px', marginTop: '4px', fontSize: '14px'}}>
                    {DifferenceMinutes}:{DifferenceSeconds}
                </div>
                <div className = "fl bold" style = {{color: '#fff', marginTop: '4px'}} title = {Assets.CurrentSystemTime}>{date}</div>
            </div>
            <div className = "clearfix">
                <div className = "fl bold" title = {Assets.YourTime} style = {{color: '#fff', width: '75px', marginTop: '1px'}}>
                    {Assets.YourTime + ':'}
                </div>
                <div className = "fl bold" style = {{color: '#fff', marginTop: '1px'}} title = {Assets.YourComputerTime}>{date2}</div>
            </div>
        </div>
    )
  }
}
// .Refresh
/********************************************************************************************* */
/*
export function DisplayTime (props:DisplayTimeProps) : View {
    //SetTime(props.UTCOffset)

    //if (props === undefined) return null
    //if (props.curDate === undefined) return null

    var curDate= props.curDate//new Date()
    var hoursNumber=curDate.getUTCHours()//+parseInt(props.UTCOffset)
    //if (hoursNumber>=24){hoursNumber-=24}
    //if (hoursNumber<0){hoursNumber+=12}

    var hours=hoursNumber.toString()
    hours +=""
    if( hours.length == 1 ){ hours = "0" + hours }
    var minutes = curDate.getUTCMinutes().toString();
    minutes = minutes + "";
      if( minutes.length == 1 ){ minutes = "0" + minutes; }

      var seconds = curDate.getUTCSeconds().toString();
      if( seconds.length == 1 ){ seconds = "0" + seconds; }
      //console.log(hours, minutes, seconds)

      let dd:string = curDate.getDate().toString();
      let mm:string = (curDate.getMonth()+1).toString();
      let yyyy:string = curDate.getFullYear().toString();
      if (dd.length == 1) { dd = "0" + dd; }
      if (mm.length == 1) { mm = "0" + mm; }

      let dayinweek:number = curDate.getDay()
      let dayInWeek:string = '';

      if (dayinweek == 0) {dayInWeek = "Sunday"}
      if (dayinweek == 1) {dayInWeek = "Monday"}
      if (dayinweek == 2) {dayInWeek = "Tuesday"}
      if (dayinweek == 3) {dayInWeek = "Wednesday"}
      if (dayinweek == 4) {dayInWeek = "Thursday"}
      if (dayinweek == 5) {dayInWeek = "Friday"}
      if (dayinweek == 6) {dayInWeek = "Saturday"}

      let date:string = dayInWeek+', '+dd+'.'+mm+'.'+yyyy+', '+hours+':'+minutes+':'+seconds;

    return(<span className="datetime">{date}</span>)
}
*/
/*
function SetTime(UTCOffset:string){
    var curDate= new Date()
    var hoursNumber=curDate.getUTCHours()+parseInt(UTCOffset)
    if (hoursNumber>=24){hoursNumber-=24}
    if (hoursNumber<0){hoursNumber+=12}

    var hours=hoursNumber.toString()
    hours +=""
    if( hours.length == 1 ){ hours = "0" + hours }
    var minutes = curDate.getUTCMinutes().toString();
    minutes = minutes + "";
      if( minutes.length == 1 ){ minutes = "0" + minutes; }

      var seconds = curDate.getUTCSeconds().toString();
      if( seconds.length == 1 ){ seconds = "0" + seconds; }
      //console.log(hours, minutes, seconds)

      let dd:string = curDate.getDate().toString();
      let mm:string = (curDate.getMonth()+1).toString();
      let yyyy:string = curDate.getFullYear().toString();
      if (dd.length == 1) { dd = "0" + dd; }
      if (mm.length == 1) { mm = "0" + mm; }

      let dayinweek:number = curDate.getDay()
      let dayInWeek:string = '';

      if (dayinweek == 0) {dayInWeek = "Sunday"}
      if (dayinweek == 1) {dayInWeek = "Monday"}
      if (dayinweek == 2) {dayInWeek = "Tuesday"}
      if (dayinweek == 3) {dayInWeek = "Wednesday"}
      if (dayinweek == 4) {dayInWeek = "Thursday"}
      if (dayinweek == 5) {dayInWeek = "Friday"}
      if (dayinweek == 6) {dayInWeek = "Saturday"}

      let date:string = dayInWeek+', '+dd+'.'+mm+'.'+yyyy;

      // letenterData={hours:hours,minutes:minutes,seconds:seconds,date:date}

      //Logic.Type.New(Main.Instance.ReusableState,{timeData:enterData})
}

*/