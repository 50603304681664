
import * as React from 'react'

export function subscribe(...stores: AppState[]) {
    return (WrappedComponent: any) => {
        let FinalComponent = WrappedComponent
        for (const store of stores) {
            FinalComponent = store.subscribe(FinalComponent)
        }
        return FinalComponent
    }
}

export default class AppState {
    public _components: any[] ;
    public _instanceCount: number;
    private _propsAndValues: object ;
    constructor(propsAndValues: object) {
        this._propsAndValues = propsAndValues // each prop with initial value
        this._components = [] // each subscribed component
        this._instanceCount = 0 // how many instances to call setState()
    }

    public subscribe(WrappedComponent: any) {
        const component = {
            instances: { }, // AppStateSubscriber instances
            nextInstanceId: 0,
        }
        this._components.push(component);
        const THIS = this // so we can access it inside class below

        return class AppStateSubscriber extends React.Component {
            private _instanceId: string
            
            constructor(props: object) {
                super(props)
                this._instanceId = 'in' + (component.nextInstanceId++)
                component.instances[this._instanceId] = this // store instance using ID as key
                ++THIS._instanceCount;
                this.state = { ...THIS._propsAndValues } // receive current values right away
            }

            public componentWillUnmount() {
                --THIS._instanceCount;
                delete component.instances[this._instanceId] // so set() won't dispatch to inexistent instance
            }

            public render() {
                return <WrappedComponent {...this.props} {...this.state}/>
            }
        }
    }

    public get(propName: string) {
        // console.log(this._propsAndValues[propName])
        if (!this._propsAndValues.hasOwnProperty(propName)) {
        return 
            // throw new Error('Retrieving an inexistent prop: ' + propName);
        }
        return this._propsAndValues[propName]
    }

    public set(propsAndValues: any, callback?: ()=> void) {
        for (const propName of Object.keys(propsAndValues)) {
            if (!this._propsAndValues.hasOwnProperty(propName)) {
                return
                // throw new Error('Updating an inexistent prop: ' + propName);
            }
        }
        this._propsAndValues = { ...this._propsAndValues, ...propsAndValues }; // update internal store
        let asyncCallback = null
        if (callback) {
            let instanceCount = this._instanceCount;
            asyncCallback = () => {
                if (--instanceCount === 0) {
                    callback(); // user callback is fired after last setState() returns
                }
            }
        }
        for (const component of this._components) {
            for (const instanceId of Object.keys(component.instances)) {
                component.instances[instanceId].setState({ ...propsAndValues }, asyncCallback); // AppStateSubscriber.setState()
            }
        }
    }
}