import * as React from 'react'
import * as Assets from '../../Assets'
import { Logic } from '../../../Logic/Type'
import { Main } from '../../../Logic/Main'
import {/*FormInput, */FormInputNumber} from '../../Reusables/Inputs'
import {PlayerCardButtons, PlayerCardButtonsET, PlayerCardButtonsTR, /*PlayerCardButtonsPH, PlayerCardButtonsPS,*/ PlayerCardButtonsKYC, PlayerCardButtonsRG, PlayerCardButtonsRM, PlayerCardButtonsETT, PlayerCardButtonsGM, AddBonusOptions, BonusTypeNavigation} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {RouterObjects} from '../../../Logic/RouterObjects'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {/*isUserGamanzaMagicHand, */ isEmailValid, isUserProhibitedToResolve, isUserGDPR, isUserRFSpecialist, isUserRGSpecialist/*, isUserSupport*/, isUserAdmin, injectSpaces, numberWithCommas, copyStringToClipboard, gotoTop, StringIsNotNumber, isUsernameValid} from '../../../Logic/Utils'
import ReactTable from 'react-table'
import {riskLevelSlider} from '../RiskComplianceStore/RiskManagement'
import {getRiskManager/*, isNotAssignedTo*/, getRiskManagers, getManual, filterDataByRights} from '../RiskComplianceStore/ResponsibleGaming'
import CSCallbackResolvePopup from '../RiskComplianceStore/callbackResolvePopup'
import {/*IBonusInfo, */GameSessionStatus, Filtered, TransactionStatus, TransactionType, TransactionStatus_, /*UploadDocumentsFiles,*/ ICommunicationUpdate, /*ITransactionWithinSession, */TimelineEntryType, TimelineEntrySubType, IssueTypes, IIssue, Player, PlayerCard, AccountStatus, LimitTypesNew, LimitPeriods, IssueTypesInsert, IIssueInsertAdmin, GameCategories, BonusNew,/*, ITransactionFilters*/
} from '../../../Logic/Database/DatabaseData'
import {TBN, activeImage} from '../../Reusables/TableUtils'
import {getOptions} from '../Settings/PlayerSettings'
import History from '../RiskComplianceStore/history'
import {faSun, faEdit as FaEdit, faCopy} from '@fortawesome/free-regular-svg-icons'
import {faInfoCircle, faSearch, faSave, faMinusSquare, faPlusSquare, faUserCircle, /*faExclamation, */faAd, faPlusCircle, faCode, faMoneyBillWave, faCopy as FaCopy, faTrashAlt, /*faLightbulb,*/ faAdjust, faCompress, faExpand, /*faDice,faGamepad,faHashtag, faMoneyBillWaveAlt,*/faCheck, faExclamationTriangle, faSitemap, faEdit, faAt, /*faHourglassStart, faHourglassEnd, */faEnvelope, faPhoneAlt, faFileCsv, faUserAlt, faMagic, faClock, faEye, faCheckSquare, faCheckDouble, faCaretLeft, faCaretRight, faAddressCard/*, faUnlockAl, faSave*/, faUser, faTimes, faLaptopCode, faSyncAlt, faArrowCircleUp, faArrowCircleLeft, faArrowCircleRight, faMoneyBillWaveAlt,/*, faExclamationCircle*/
/*faDonate*/} from '@fortawesome/free-solid-svg-icons'
import {dashboardSmallTableRows, dashboardBigTableRows} from '../MainDashboard/Dashboard'
import {getIssueType as GetIssueType, GetIssueType as GetIssueType2, GetIssueTypeDetail as GetIssueType3} from '../MainDashboard/Latest'
import * as Assets_ from '../../Assets_'
import * as moment from 'moment'
import * as bfi from '@gamingenius/bicfromiban'
import axios, {AxiosRequestConfig} from 'axios'
import { Document, Page } from 'react-pdf'
import {GetDate} from './WithdrawalRequests'
import {getProvider} from '../GameStore/GameList'
import {SingleDatePicker} from 'react-dates'
import Socket1 from '../../../Socket/socket-init'
import Cookies from 'js-cookie'
import {bonusType, conditionRule, /*triggerType*/} from '../../../Logic/BonusStore/BonusStore'

// import * as base64 from 'base-64'
/*
enum GameCategories {
    All,
    Slots,
    LiveCasino,
    Blackjack,
    Roulette,
    Baccarat,
    Poker,
    VirtualSports,
}

export interface ITransactionWithinSession {
    amount: number
    created: string
    currency: string
    type: string
    info: any
}
*/

/*
function hasWin(index: number): boolean {
    if (Main.Instance.DatabaseDataState.sessionTransactionsFiltered[index - 1] && 
        Main.Instance.DatabaseDataState.sessionTransactionsFiltered[index - 1].type === 'win') {
        return true
    }
    return false
}

function getWinTimestamp(index: number): string {
    return Main.Instance.DatabaseDataState.sessionTransactionsFiltered[index - 1].created
}

function getWin(index: number): number {
    return Main.Instance.DatabaseDataState.sessionTransactionsFiltered[index - 1].amount
}

function getValue(timestamp: string, property: string): number|string {
    // console.log (timestamp)
    if (Main.Instance.PlayerCardState.fgs) {
        for (let i: number = 0; i < Main.Instance.PlayerCardState.fgs.length; i++) {
            for (let j: number = 0; j < Main.Instance.PlayerCardState.fgs[i].transactions.length; j++) {
                if (Main.Instance.PlayerCardState.fgs[i].transactions[j].timestamp === timestamp) {
                    if (property === 'index') {
                        // console.log (i, j)
                        return Main.Instance.PlayerCardState.fgs.length - i // i + 1
                    } else if (property === 'Index') {
                        return j + 1
                    } else if (property === 'roundId') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].roundId
                    } else if (property === 'supplierRoundId') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].supplierRoundId
                    } else if (property === 'realWagered') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].realWagered ? Main.Instance.PlayerCardState.fgs[i].transactions[j].realWagered : 0
                    } else if (property === 'realWon') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].realWon ? Main.Instance.PlayerCardState.fgs[i].transactions[j].realWon : 0
                    } else if (property === 'bonusWagered') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].bonusWagered ? Main.Instance.PlayerCardState.fgs[i].transactions[j].bonusWagered : 0
                    } else if (property === 'bonusWon') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].bonusWon ? Main.Instance.PlayerCardState.fgs[i].transactions[j].bonusWon : 0
                    } else if (property === 'pjc') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].pjc ? Main.Instance.PlayerCardState.fgs[i].transactions[j].pjc : 0
                    } else if (property === 'pjw') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].pjw ? Main.Instance.PlayerCardState.fgs[i].transactions[j].pjw : 0
                    } else if (property === 'fga') {
                        return Main.Instance.PlayerCardState.fgs[i].transactions[j].fga ? Main.Instance.PlayerCardState.fgs[i].transactions[j].fga : 0

                    }
                }
            }
        }
    }
    return '?'
}
*/
/*
function filterSessionTransactions() {
    const sessionTransactionsFiltered: ITransactionWithinSession[] = []
    const filterDateFrom: string = Main.Instance.PlayerCardState.filterDateFrom !== null && Main.Instance.PlayerCardState.filterDateFrom !== undefined ? moment.default(Main.Instance.PlayerCardState.filterDateFrom).format(Assets_.dateDisplayFormat) : undefined
    const filterDateTo: string = Main.Instance.PlayerCardState.filterDateTo !== null && Main.Instance.PlayerCardState.filterDateTo !== undefined ? moment.default(Main.Instance.PlayerCardState.filterDateTo).format(Assets_.dateDisplayFormat) : undefined
    // console.log (filterDateFrom, filterDateTo)
    for (const item of Main.Instance.DatabaseDataState.sessionTransactions) {
        // item.created.substring(0,10) => Date
        // item.created.substring(11,19) => Time
        // console.log (item)
        if (item.created === undefined) {continue}
        let CONTINUE: boolean = false
        for (const Item of (Main.Instance.PlayerCardState.fgs)) {
            let BREAK: boolean = false
            if (Item.selected) {continue}
            for (const ITEM of Item.transactions) {
                if (ITEM.timestamp === item.created) {
                    BREAK = true
                    CONTINUE = true
                    break
                }
            }
            if (BREAK) {break}
        }
        if (CONTINUE) {continue}

        if (Main.Instance.PlayerCardState.filterTimeFrom && Main.Instance.PlayerCardState.filterTimeFrom.length > 0) {
            if (filterDateFrom) {
                if (filterDateFrom + ' ' + Main.Instance.PlayerCardState.filterTimeFrom > item.created.substring(0,19)) {continue}
            } else if (Main.Instance.PlayerCardState.filterTimeFrom > item.created.substring(11,19)) {continue}  
        } else if (filterDateFrom && filterDateFrom > item.created.substring(0,10)) {continue}

        if (Main.Instance.PlayerCardState.filterTimeTo && Main.Instance.PlayerCardState.filterTimeTo.length > 0) {
            if (filterDateTo) {
                if (filterDateTo + ' ' + Main.Instance.PlayerCardState.filterTimeTo < item.created.substring(0,19)) {continue}
            } else if (Main.Instance.PlayerCardState.filterTimeTo < item.created.substring(11,19)) {continue}
        } else if (filterDateTo && filterDateTo < item.created.substring(0,10)) {continue}
        
        sessionTransactionsFiltered.push(item)
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {sessionTransactionsFiltered})
}
*/
/*
function updateDate(property: string, date: moment.Moment) {
    if (property === 'filterDateFrom') {
        Logic.Type.New (Main.Instance.PlayerCardState, {filterDateFrom: date})
    } else {
        Logic.Type.New (Main.Instance.PlayerCardState, {filterDateTo: date})
    }
    filterSessionTransactions()
}

function updateText(value: string, property: string) {
    if (property === 'filterTimeFrom') {
        Logic.Type.New (Main.Instance.PlayerCardState, {filterTimeFrom: value})
    } else {
        Logic.Type.New (Main.Instance.PlayerCardState, {filterTimeTo: value})
    }
    filterSessionTransactions()
}
*/
export function getOptionsKYC(type: string): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push(<option key = "-1" value = "0">{Assets.Select + ' ' + type}</option>)
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC[type]) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.KYC[type].length; i++) {
            if (Main.Instance.DatabaseDataState.custom.KYC[type][i].active) {
            options.push (<option key = {i.toString()} value = {Main.Instance.DatabaseDataState.custom.KYC[type][i].key}>{Main.Instance.DatabaseDataState.custom.KYC[type][i].label}</option>)
            }

        }
    }
    return options
}

function threeStatuses(): JSX.Element {
    return (
        <div style = {{display: 'flex', justifyContent: 'space-evenly', height: '30px'}}>
        <div className = "player-card-quick-status">
            <div
                style = {{paddingLeft: '0px', paddingRight: '0px', borderTopLeftRadius: '5px', height: '20px', color: 'white', lineHeight: '20px', width: '100%', fontSize: '12px'}}
                title = {Assets.AccountStatus}
                className = {'risk risk-' + AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]}
            >
                {/*AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                 AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'BlockedFull' ? 'Blocked Full' :
                 AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'BlockedPartial' ? 'Blocked Partial' :
                AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]*/}
                {Assets.accountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]}
            </div>
            <div
                style = {{fontWeight: 700, background: '#111',height: '10px', borderBottomLeftRadius: '5px', color: 'white', fontSize: '11px', lineHeight: '10px', width: '100%'}}
            >
                {Assets.AccountStatus.toLowerCase()}
            </div>
        </div>
        <div className = "player-card-quick-status">
            <div 
                style = {{paddingLeft: '0px', paddingRight: '0px', height: '20px', lineHeight: '20px', width: '100%'}}
                title = {Assets.RiskLevel}
                className = {'risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.riskLevel]}
            >
                {RiskLevel[Main.Instance.DatabaseDataState.playerCard.riskLevel]}
            </div>
            <div
                style = {{borderLeft: '1px solid #999', borderRight: '1px solid #999', fontWeight: 700, background: '#111',height: '10px', color: 'white', fontSize: '11px', lineHeight: '10px', width: '100%'}}
            >
                {Assets.RiskLevel.toLowerCase()}
            </div>
        </div>
        <div className = "player-card-quick-status">
            <div 
                style = {{paddingLeft: '0px', paddingRight: '0px', height: '20px', lineHeight: '20px', width: '100%', borderTopRightRadius: '5px', background: Assets_.jackpotschLevelColor[Main.Instance.DatabaseDataState.playerCard.level], color: Assets_.jackpotschLevelText[Main.Instance.DatabaseDataState.playerCard.level]}}
                title = {Assets.GamificationLevel}
            >
                {Assets.jackpotschLevels[Main.Instance.DatabaseDataState.playerCard.level]}
            </div>
            <div
                style = {{fontWeight: 700, background: '#111', borderBottomRightRadius: '5px', height: '10px', color: 'white', fontSize: '11px', lineHeight: '10px', width: '100%'}}
            >
                {Assets.GamificationLevel.toLowerCase()}
            </div>
        </div>
    </div>
    )
}

function Clicked(PlayerId?: string) {
    // forcePlayerCardSelect
    // console.log ('Clicked ' + PlayerId)
    // console.log (Main.Instance.DatabaseDataState.playerCard.playerId)
    // console.log (Main.Instance.PlayerCardState.id)
    // console.log (Main.Instance.DatabaseDataState.optionsTopPlayerId[0])
    // console.log (Main.Instance.DatabaseDataState.optionsBottomPlayerId[0])
    // console.log (Main.Instance.DatabaseDataState.optionsTopPlayerId)
    // console.log (Main.Instance.DatabaseDataState.optionsBottomPlayerId)

    let playerId: string = PlayerId ? PlayerId : '?'
    if (PlayerId === undefined) {
        if (Main.Instance.DatabaseDataState.optionsTopPlayerId.length > 0) {
            playerId = Main.Instance.DatabaseDataState.optionsTopPlayerId[0]
        } else if (Main.Instance.DatabaseDataState.optionsBottomPlayerId.length > 0) {
            playerId = Main.Instance.DatabaseDataState.optionsBottomPlayerId[0]
        }
    }
    // console.log ('playerId ' + playerId)
    // console.log ('stored playerid ' + Main.Instance.DatabaseDataState.playerCard.playerId)
    if (playerId !== Main.Instance.DatabaseDataState.playerCard.playerId && playerId !== '?') {
        // console.log ('SelectPlayer')
        SelectPlayer(playerId, Main.Instance.PlayerCardState.nav)
    }
}

export function SelectPlayer(id: string, nav: PlayerCardButtons/*, dontDisablePlayer?: boolean*/) {
    // console.log ('SelectPlayer playerId: ' + id)
    // console.log ('playerId ' + id)
    if (id === '0') {
        Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionPage: 0, forcePlayerCardSelect: false, nav, id, filterIssues: false})
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard: {}})
    } else {
        DbRoutes.wsGetPlayerCard(id, true)
        // console.log (PlayerCardButtons)
        // console.log (Main.Instance.PlayerCardState.nav)
        Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionPage: 0, forcePlayerCardSelect: false, nav, id, filterIssues: false /* playerSelectionDisabled: dontDisablePlayer ? false : true, player: getPlayer(id)*/})
        Logic.Type.New (Main.Instance.DatabaseDataState, {
            manualWithdrawal: false,
            manualBonus: false,
            manualDeposit: false,
            insertANewIssue: false,
            manualCrif: false,
            resendVerificationEmail: false,
            showUploads: false,
            checkVETO: false,
            playerCard: {}// getPlayer(id)
        })
    }
    const path: string = RouterObjects['account_Store'].path + RouterObjects['account_Store'].children['Player_Card'].path
    let Path: string = RouterObjects['account_Store'].path.substring (1, RouterObjects['account_Store'].path.length) + ' -> ' +
        RouterObjects['account_Store'].children['Player_Card'].path.substring(1, RouterObjects['account_Store'].children['Player_Card'].path.length)
    Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

    DbRoutes.insertAdminLog (
        Config.asAdminLogView,
        Path,
        {},
    )    
    Logic.Type.New(Main.Instance, {Location: path})
}

// this is the function (aggregator) of different events.
// first lets just add the ids of ER (Elevated risk)
// function getEventsTimeline(id: string, filterIssues?: boolean): {f: EventsTimeline[], a: EventsTimeline[]} {
    // return {f: [], a: []}
/*    
    let eventsTimeline: EventsTimeline[] = []
    const EventTimeline: EventsTimeline[] = []
    // RISK MANAGEMENT
    if (Main.Instance.FrontendStoreState.custom.riskManagement && Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk.length; i++) {
        if (Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].playerId === id) {
            if (filterIssues && Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].resolved) continue
            const eventTimeline: EventsTimeline = {
                type: 'Elevated risk',
                ID: 'ER',
                id: '?',
                requestedTimestamp: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].requestedTimestamp,
                resolvedTimestamp: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].resolvedTimestamp,
                riskManagerId: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].riskManagerId,
                currentRiskLevel: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].currentRiskLevel,
                previousRiskLevel: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].previousRiskLevel,
                resolved: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].resolved,
                comments: Main.Instance.FrontendStoreState.custom.riskManagement.elevatedRisk[i].comments
            }
            EventTimeline.push(eventTimeline)
            if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline || Main.Instance.PlayerCardState.nav === PlayerCardButtons.RiskManagement)
                eventsTimeline.push(eventTimeline)
        }
    }

    // RESPONSIBLE GAMING
    if (Main.Instance.FrontendStoreState.custom.responsibleGaming && Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton && Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list.length; i++) {
        if (Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].playerId === id) {
            if (filterIssues && Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].resolved) continue
            const eventTimeline: EventsTimeline = {
                type: 'Customer support callback',
                ID: 'CSC',
                id: '?',
                requestedTimestamp: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].requestedTimestamp,
                resolvedTimestamp: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].resolvedTimestamp,
                // riskManagerId: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].riskManagerId,
                // currentRiskLevel: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].currentRiskLevel,
                // previousRiskLevel: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].previousRiskLevel,
                phoneNumber: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].phoneNumber,
                resolved: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].resolved,
                comments: Main.Instance.FrontendStoreState.custom.responsibleGaming.CSCallbackButton.list[i].comments
            }
            EventTimeline.push(eventTimeline)
            if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline || Main.Instance.PlayerCardState.nav === PlayerCardButtons.ResponsibleGaming &&
                (Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.Everything || Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.CSSupport))
                eventsTimeline.push(eventTimeline)
        }
    }

    // KNOW YOUR CUSTOMER
    if (Main.Instance.FrontendStoreState.custom.KYC && Main.Instance.FrontendStoreState.custom.KYC.recentUploads)
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.KYC.recentUploads.length; i++) {
        if (Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].playerId === id) {
            if (filterIssues && Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].resolved) continue
            const eventTimeline: EventsTimeline = {
                type: 'Know your customer',
                ID: 'KYC',
                id: '?',
                requestedTimestamp: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].requestedTimestamp,
                resolvedTimestamp: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].resolvedTimestamp,
                resolved: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].resolved,
                comments: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].comments,
                files: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].files,
                isValid: Main.Instance.FrontendStoreState.custom.KYC.recentUploads[i].isValid
            }
            EventTimeline.push(eventTimeline)
            if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline || Main.Instance.PlayerCardState.nav === PlayerCardButtons.KnowYourCustomer &&
                (Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.Everything || Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.Uploads))
                eventsTimeline.push(eventTimeline)
        }
    }

    // this has now to be ordered by requestedTimestamp
    eventsTimeline = eventsTimeline.sort(requestedTimestampReverse)
    return {a: EventTimeline, f: eventsTimeline}
*/
// }

export function requestedTimestampReverse(a,b) {
    if (a.requestedTimestamp < b.requestedTimestamp) { return 1 }
    if (a.requestedTimestamp > b.requestedTimestamp) { return -1 }
    return 0
}

// function getPlayer(id: string): Player {return {}}

function buttonCopyToClipboard(what: string, property: string, right: number): JSX.Element {
    let string
    if (property === 'birthday') {
        string = Main.Instance.DatabaseDataState.playerCard[property] !== undefined && Main.Instance.DatabaseDataState.playerCard[property] !== null ? displayBirthday(false) : ''
  
    } else {
        string = Main.Instance.DatabaseDataState.playerCard[property] !== undefined && Main.Instance.DatabaseDataState.playerCard[property] !== null ? Main.Instance.DatabaseDataState.playerCard[property].toString() : ''
    }
    return (
        <button title = {Assets.Copy + ' ' + what} className = "btn btn-default btn-sm mr0" type = "button" onClick = {() => copyStringToClipboard(string)} style = {{fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '-4px', right: (right - 6) + 'px'}}>
            <FontAwesomeIcon icon = {faCopy}/>
        </button>
    )
}
/*
function buttonShowBonus(): JSX.Element {
    return (
        <a
            className = "btn btn-sm mr0"
            onClick = {() => Main.Instance.PlayerCardState.BonusInfo ? Logic.Type.New (Main.Instance.PlayerCardState, {BonusInfo: false}) : DbRoutes.getBonusInfo(Main.Instance.DatabaseDataState.playerCard.playerId)}
            style = {{
                color: Main.Instance.PlayerCardState.BonusInfo ? 'rgb(92, 184, 92)' : '#999',
                fontSize: '14px',
                background: 'transparent',
                border: '0px', position: 'absolute',
                top: '1px',
                right: '58px',
                padding: '0px'
            }}
            title = {Main.Instance.PlayerCardState.BonusInfo ? Main.Instance.PlayerCardState.bonusInfo.length + ' ' + Assets.Bonus_es : Assets.Show + ' ' + Assets.Bonus.toLowerCase() + ' ' + Assets.Info.toLowerCase()}
        >
            <FontAwesomeIcon icon = {faEye}/>
        </a>
    )
}
*/
/*
function buttonAddBonus(): JSX.Element {
    return (
        <a
            className = "btn btn-sm mr0"
            onClick = {() => Main.Instance.PlayerCardState.BonusAdd ? Logic.Type.New (Main.Instance.PlayerCardState, {BonusAdd: false}) : Logic.Type.New (Main.Instance.PlayerCardState, {BonusAdd: true, BonusAmount: 0, BonusCode: '', BonusWageringRequirement: true})}
            style = {{
                color: Main.Instance.PlayerCardState.BonusAdd ? 'rgb(92, 184, 92)' : '#999',
                fontSize: '14px',
                background: 'transparent',
                border: '0px', position: 'absolute',
                top: '1px',
                right: '79px',
                padding: '0px'
            }}
            title = {Assets.AddANewBonus}
        >
            <FontAwesomeIcon icon = {faPlusCircle}/>
        </a>
    )
}
*/
/*
function unlockAccount() {
    // doloci glede na isEmailVerified, isUtilityVerified, isDocumentVerified, isSuperVerified
    const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
    // playerCard.isEmailVerified
    // playerCard.isUtilityVerified
    // playerCard.isDocumentVerified
    // playerCard.isSuperVerified
    // console.log (playerCard)
    playerCard.accountStatus = AccountStatus.Provisional
    const rules:{name: string, requirements?: {isEmailVerified?: true, isUtilityBillVerified?: true, isDocumentVerified?: true, isSuperVerified?: true}, allow?: {login?: true, play?: true, deposit?: true, withdraw?: true, temporary?: true}}[] = Object.assign([], Main.Instance.DatabaseDataState.custom.general.players.accountStatus)

    // console.log ('isEmailVerified:' + playerCard.isEmailVerified)
    // console.log ('isDocumentVerified:' + playerCard.isDocumentVerified)
    // console.log ('isUtilityVerified:' + playerCard.isUtilityVerified)
    // console.log ('isSuperVerified:' + playerCard.isSuperVerified)

    for (let i: number = AccountStatus.Initial; i < Main.Instance.DatabaseDataState.custom.general.players.accountStatus.length; i++) {
        if (Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements) {
            // requirements
            // console.log (Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements)

            // const isEmailVerified: boolean = Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isEmailVerified ? true : false
            // const isDocumentVerified: boolean = Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isDocumentVerified ? true : false
            // const isUtilityBillVerified: boolean = Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isUtilityBillVerified ? true : false
            // const isSuperVerified: boolean = Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isSuperVerified ? true : false

            // console.log (rules[i].requirements)
            let allRequirementsMet = true

            if(!!rules[i].requirements.isEmailVerified === playerCard.isEmailVerified) {

            } else allRequirementsMet = false

            if(!!rules[i].requirements.isDocumentVerified === playerCard.isDocumentVerified) {

            } else allRequirementsMet = false

            if(!!rules[i].requirements.isUtilityBillVerified === playerCard.isUtilityVerified) {

            } else allRequirementsMet = false

            if(!!rules[i].requirements.isSuperVerified === playerCard.isSuperVerified) {

            } else allRequirementsMet = false

            if (allRequirementsMet) {
                // console.log ('FOUND')
                playerCard.accountStatus = i
                break
            }
        }
    }
    // playerCard.accountStatus = 1

    Logic.Type.New(Main.Instance.DatabaseDataState, {playerCard})
}
*/
function toggle(property: string) {
    const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)

    if (property === 'isDocumentVerified' && !playerCard.isDocumentVerified) {
        if (playerCard.documentExpiry === undefined || playerCard.documentExpiry.length === 0) { return }
        if (playerCard.documentNumber === undefined || playerCard.documentNumber.length === 0) { return }
        if (playerCard.issuingAuthority === undefined || playerCard.issuingAuthority.length === 0) { return }
        if (playerCard.documentType === undefined || playerCard.documentType.length === 0) { return }
        if (playerCard.issueDate === undefined || playerCard.issueDate.length === 0) { return }
    }

    playerCard[property] = !playerCard[property]
/*    
    if (property === 'isDocumentVerified' || property === 'isUtilityVerified' || property === 'isSuperVerified' ||
        property === 'allowLogin' || property === 'allowPlay' || property === 'allowDeposit' || property === 'allowWithdraw') {
        // console.log ('changing property: ' + property)

        // const isEmailVerified: boolean = playerCard.isEmailVerified ? playerCard.isEmailVerified : undefined
        // const isDocumentVerified: boolean = playerCard.isDocumentVerified ? playerCard.isDocumentVerified : undefined
        // const isUtilityVerified: boolean = playerCard.isUtilityVerified ? playerCard.isUtilityVerified : undefined
        // const isSuperVerified: boolean = playerCard.isSuperVerified ? playerCard.isSuperVerified : undefined

        // console.log ('isEmailVerified: ' + playerCard.isEmailVerified)
        // console.log ('isDocumentVerified: ' + playerCard.isDocumentVerified)
        // console.log ('isUtilityVerified: ' + playerCard.isUtilityVerified)
        // console.log ('isSuperVerified: ' + playerCard.isSuperVerified)

        // THIS IS BASICALLY HARDCODED NOW, WE HAVE TO MAKE IT DIFFERENT

        // for higher account statuses this does not apply
        const rules:{name: string, requirements?: {isEmailVerified?: true, isUtilityBillVerified?: true, isDocumentVerified?: true, isSuperVerified?: true}, allow?: {login?: true, play?: true, deposit?: true, withdraw?: true, temporary?: true}}[] = Object.assign([], Main.Instance.DatabaseDataState.custom.general.players.accountStatus)
        // console.log (rules)
        
        // for (let i: number = AccountStatus.Initial; i <= AccountStatus.ActiveFullyIdentified; i++) {
        for (let i: number = AccountStatus.ActiveFullyIdentified; i >= AccountStatus.Initial; i--) {
            // console.log (rules[i])
            if (rules[i].requirements.isEmailVerified && playerCard.isEmailVerified !== rules[i].requirements.isEmailVerified) continue
            if (rules[i].requirements.isDocumentVerified && playerCard.isDocumentVerified !== rules[i].requirements.isDocumentVerified) continue
            if (rules[i].requirements.isUtilityBillVerified && playerCard.isUtilityVerified !== rules[i].requirements.isUtilityBillVerified) continue
            if (rules[i].requirements.isSuperVerified && playerCard.isSuperVerified !== rules[i].requirements.isSuperVerified) continue
                
            playerCard.allowLogin = rules[i].allow.login
            playerCard.allowDeposit = rules[i].allow.deposit
            playerCard.allowWithdraw = rules[i].allow.withdraw
            playerCard.allowPlay = rules[i].allow.play
            playerCard.accountStatus = i
            // console.log ('set player account status to ' + playerCard.accountStatus)
            break
        }

/*
        if (!playerCard.isEmailVerified) {
            // console.log ('1')
            playerCard.accountStatus = AccountStatus.Initial
        } else if (playerCard.isEmailVerified && playerCard.isDocumentVerified && playerCard.isUtilityVerified && playerCard.isSuperVerified) {
            // console.log ('4')
            playerCard.accountStatus = AccountStatus.ActiveFullyIdentified
        } else if (playerCard.isEmailVerified && playerCard.isDocumentVerified && playerCard.isUtilityVerified) {
            // console.log ('3')
            playerCard.accountStatus = AccountStatus.Active
        } else if (playerCard.isEmailVerified) {
            // console.log ('2')
            playerCard.accountStatus = AccountStatus.Provisional
        }
*/
//  }
    Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
    DbRoutes.updatePlayerCard(playerCard)
}

function toggleTestAccount() {
    if (confirm(Assets.PleaseConfirmChangeTestAccountStatus)) {
        // here we need to insert a proper issue that will handle things
        const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
        newIssue.resolved = true
        newIssue.playerId = Main.Instance.DatabaseDataState.playerCard.playerId
        newIssue.riskManagerId = Main.Instance.LoginState.id
        newIssue.issueType = IssueTypes.ChangedTestAccount
        newIssue.riskLevel = RiskLevel.Undefined
        newIssue.data = {
            testAccount: !Main.Instance.DatabaseDataState.playerCard.testAccount
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
        InsertNewIssue()
/*        
        const playerCard = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
        playerCard.testAccount = !playerCard.testAccount
        Logic.Type.New(Main.Instance.DatabaseDataState, {playerCard})
        DbRoutes.updatePlayerCard(playerCard)
*/
    }
}

// Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i]

function clickMoveButtonLeft(i: number, size: number) {
    const unresolvedIssuesPlayersPositions: number[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions)
    unresolvedIssuesPlayersPositions[i] = unresolvedIssuesPlayersPositions[i] - (/*Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : */dashboardSmallTableRows)
    if (unresolvedIssuesPlayersPositions[i] < 0) {
        unresolvedIssuesPlayersPositions[i] = 0
    }
    // console.log ('new Position: ' + position[moveable])
    Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesPlayersPositions})
}

function clickMoveButtonRight(i: number, size: number) {
    const unresolvedIssuesPlayersPositions: number[] = Object.assign([], Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions)
    unresolvedIssuesPlayersPositions[i] = unresolvedIssuesPlayersPositions[i] + (/*Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : */dashboardSmallTableRows)
    if (unresolvedIssuesPlayersPositions[i] > size - (/*Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : */dashboardSmallTableRows)) {
        unresolvedIssuesPlayersPositions[i] = size - (/*Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : */dashboardSmallTableRows)
    }
    // console.log ('new Position: ' + position[moveable])
    Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesPlayersPositions})
}

function getBackground(accountStatus: AccountStatus): string {
    // console.log ('accountStatus:' + AccountStatus[accountStatus])
    return AccountStatus[accountStatus]
    // return '#d9534f'
}

function GetListPlayersSelection(): string {
    let s: string = ''
    s += Assets.PlayerId + ',' + Assets.Player_ + ',' + Assets.Issue + ' ' + Assets.Number + ',' + Assets.Issue + ' ' + Assets.Type + ',' + Assets.IssueClassification + ',' + Assets.Created + ' ' + Assets.Timestamp + '\n'
    const list: Array<{accountStatus: AccountStatus, index: number[], playerId: string, firstname: string, lastname: string, firstName: string, lastName: string, userName: string, username: string, issues: IIssue[]}> = []
    const shortlist: Array<{playerId: string, issues: number[]}> = []
    // const List: JSX.Element[] = []
    // let unresolvedIssuesPlayers: number = 0
    // let unresolvedIssuesNumber: number = 0
    if (Main.Instance.DatabaseDataState.playerCards) {
        const playerCards: PlayerCard[] = Object.assign([], Main.Instance.DatabaseDataState.playerCards)
        if (Main.Instance.DatabaseDataState.playerCards) {
            for (let i: number = 0; i < playerCards.length; i++) {
                if (Main.Instance.DatabaseDataState.viewPlayerCards[playerCards[i].accountStatus] === false) { continue }
                const item: PlayerCard = playerCards[i]
                item.Issues = []
                // const issues: number[] = []
                // const index: number[] = []
/*
                if (Main.Instance.DatabaseDataState.issues) {
                    for (let j: number = 0; j < Main.Instance.DatabaseDataState.issues.length; j++) {
                        const Item: IIssue = Main.Instance.DatabaseDataState.issues[j]
                        let found: boolean = true
                        if (Main.Instance.DatabaseDataState.ShowJustAssignedToIssues) {
                            found = false
                            for (const ITEM of Item.assignTo) {
                                if (ITEM === Main.Instance.LoginState.id) {
                                    found = true
                                    break
                                }
                            }
                        }
                        if (found && Item.playerId === item.playerId && Item.resolved === false) {
                            // if (item.issues === 0) console.log (Item.playerId)
                            if (item.Issues.length === 0) { unresolvedIssuesPlayers++ }
                            unresolvedIssuesNumber++
                            item.Issues.push(Item)
                            issues.push(Item.id)
                            index.push(j)
                        }
                    }
                }

                if (issues.length > 0) {
                    list.push({accountStatus: item.accountStatus, index, playerId: item.playerId, userName: item.username, username: item.username.toLowerCase(), firstname: item.firstName, lastname: item.lastName, firstName: item.firstName.toLowerCase(), lastName: item.lastName.toLowerCase(), issues: item.Issues})
                    shortlist.push({playerId: item.playerId, issues})
                }
*/
            }
/*
            if (unresolvedIssuesNumber !== Main.Instance.DatabaseDataState.unresolvedIssuesNumber && unresolvedIssuesNumber !== Main.Instance.DatabaseDataState.unresolvedIssuesPlayers) {
                Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesNumber, unresolvedIssuesPlayers})
            }
*/
            if (isUserGDPR()) {
                list.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
                list.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
            } else {
                list.sort((a, b) => (a.username > b.username) ? 1 : -1)
                list.sort((a, b) => (a.username > b.username) ? 1 : -1)
            }
            const shortList: string = JSON.stringify(shortlist)

            if (Main.Instance.DatabaseDataState.shortList === undefined || Main.Instance.DatabaseDataState.shortList !== shortList) {
                const unresolvedIssuesPlayersPositions: number[] = []
                for (const {} of list) {
                    unresolvedIssuesPlayersPositions.push(0)
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesPlayersPositions, shortList})
            }
            // const moveable: boolean = false
            // const expanded: boolean = false

            for (let i: number = 0; i < list.length; i++) {
                const Item = list[i]
                if (Item.issues.length > 0) {
                    for (const ITEM of Item.issues) {
                        // console.log (ITEM)
                        s += ITEM.playerId + ',' + (isUserGDPR() ? Item.lastname + ' ' + Item.firstname + ' (' + Item.userName + ')' : Item.userName) + ',' + ITEM.id + ',' + GetIssueType2(ITEM.issueType) + ',' + GetIssueType3(ITEM.issueType) + ',' + ITEM.created + '\n'
                    }
                }
            }
        }
    }
    // return List
    return s
}

function getListPlayersSelection(): JSX.Element[] {
    const list: Array<{accountStatus: AccountStatus, index: number[], playerId: string, firstname: string, lastname: string, firstName: string, lastName: string, userName: string, username: string, issues: IIssue[]}> = []
    const shortlist: Array<{playerId: string, issues: number[]}> = []
    const List: JSX.Element[] = []
    // let unresolvedIssuesPlayers: number = 0
    // let unresolvedIssuesNumber: number = 0
    // console.log (Main.Instance.DatabaseDataState.playerCards)
    // console.log (Main.Instance.DatabaseDataState.issues)
    if (Main.Instance.DatabaseDataState.playerCards) {
        const playerCards: PlayerCard[] = Object.assign([], Main.Instance.DatabaseDataState.playerCards)
        if (Main.Instance.DatabaseDataState.playerCards) {
            let foundOneAccountStatus: boolean = false
            for (const item of Main.Instance.DatabaseDataState.viewPlayerCards) {
                if (item) {
                    foundOneAccountStatus = true
                    break
                }
            }
            // PlayerCard
            // console.log (playerCards)
            if (playerCards) {
                for (let i: number = Main.Instance.DatabaseDataState.playerCardsSize * Main.Instance.DatabaseDataState.playerCardsPosition; i < playerCards.length && i < Main.Instance.DatabaseDataState.playerCardsSize * (Main.Instance.DatabaseDataState.playerCardsPosition + 1); i++) {
                    if (foundOneAccountStatus && Main.Instance.DatabaseDataState.viewPlayerCards[playerCards[i].accountStatus] === false) { continue }
                    // const item: PlayerCard = playerCards[i] // playerCards ? playerCards[i] : {}
                    // item.Issues = []
                    // const issues: number[] = []
                    // const index: number[] = []
/*
                    if (Main.Instance.DatabaseDataState.issues) {
                        for (let j: number = 0; j < Main.Instance.DatabaseDataState.issues.length; j++) {
                            const Item: IIssue = Main.Instance.DatabaseDataState.issues[j]
                            let found: boolean = true
                            if (Main.Instance.DatabaseDataState.ShowJustAssignedToIssues) {
                                found = false
                                for (const ITEM of Item.assignTo) {
                                    if (ITEM === Main.Instance.LoginState.id) {
                                        found = true
                                        break
                                    }
                                }
                            }
                            if (found && Item.playerId === item.playerId && Item.resolved === false) {
                                // if (item.issues === 0) console.log (Item.playerId)
                                if (item.Issues.length === 0) { unresolvedIssuesPlayers++ }
                                unresolvedIssuesNumber++
                                item.Issues.push(Item)
                                issues.push(Item.id)
                                index.push(j)
                            }
                        }
                    }

                    if (issues.length > 0) {
                        list.push({accountStatus: item.accountStatus, index, playerId: item.playerId, userName: item.username, username: item.username.toLowerCase(), firstname: item.firstName, lastname: item.lastName, firstName: item.firstName.toLowerCase(), lastName: item.lastName.toLowerCase(), issues: item.Issues})
                        shortlist.push({playerId: item.playerId, issues})
                    }
*/
                }
            }
/*            
            if (unresolvedIssuesNumber !== Main.Instance.DatabaseDataState.unresolvedIssuesNumber && unresolvedIssuesNumber !== Main.Instance.DatabaseDataState.unresolvedIssuesPlayers) {
                Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesNumber, unresolvedIssuesPlayers})
            }
*/
/*
            if (isUserGDPR()) {
                list.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
                list.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
            } else {
                list.sort((a, b) => (a.username > b.username) ? 1 : -1)
                list.sort((a, b) => (a.username > b.username) ? 1 : -1)
            }
*/
            const shortList: string = JSON.stringify(shortlist)

            if (Main.Instance.DatabaseDataState.shortList === undefined || Main.Instance.DatabaseDataState.shortList !== shortList) {
                const unresolvedIssuesPlayersPositions: number[] = []
                for (const {} of list) {
                    unresolvedIssuesPlayersPositions.push(0)
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {unresolvedIssuesPlayersPositions, shortList})
            }
            const moveable: boolean = false
            const expanded: boolean = false

            for (let i: number = 0; i < list.length; i++) {
                const Item = list[i]
                // if (Main.Instance.DatabaseDataState.viewPlayerCards[Item.accountStatus] === false) continue
                if (Item.issues.length > 0) {
                    // console.log (Item.issues)
                    List.push(
                        <div className = "dashboard-item" key = {Item.username}>
                            <div style = {{height: '29px', paddingLeft: '40px',paddingRight: '40px',lineHeight: (isUserGDPR() ? Item.lastname + ' ' + Item.firstname + ' (' + Item.userName + ')' : Item.userName).length > 35 ? '15px' : '', fontSize: (isUserGDPR() ? Item.lastname + ' ' + Item.firstname + ' (' + Item.userName + ')' : Item.userName).length > 35 ? '14px' : ''}} title = {Assets.AccountStatus + ': ' + Assets.accountStatus[Item.accountStatus]} className = {'dashboard-item-title risk-' + getBackground(Item.accountStatus)}>
                                <button title = {Assets.OpenPlayerCard + ' - ' + Item.issues.length + ' ' + (Item.issues.length === 1 ? Assets.Issue.toLowerCase() : Assets.Issues.toLowerCase())} onClick = {() => SelectPlayer(Item.playerId, /*PlayerCardButtons.Transactions*/ PlayerCardButtons.EventsTimeline)} className = "btn btn-sm btn-default mr0" style = {{padding: '0px 5px', fontSize: '14px', position: 'absolute', left: '5px', top: '3px'}}>
                                    <FontAwesomeIcon icon = {faAddressCard}/>
                                    {/*<span className="badge badge-light" style = {{background: '#d9534f'}}>{Item.issues.length}</span>*/}
                                </button>
                                {isUserGDPR() ? Item.lastname + ' ' + Item.firstname + ' (' + Item.userName + ')' : Item.userName}

                                <button disabled = {Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] === 0} style = {{position: 'absolute', right: '18px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}} className = "btn btn-default btn-sm" type = "button" onClick = {() => clickMoveButtonLeft(i, Item.issues.length)}>
                                    <FontAwesomeIcon icon = {faCaretLeft}/>
                                </button>

                                <button disabled = {Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] >= Item.issues.length - (/*Main.Instance.DashboardState.expandButton[selectedView] === which ? dashboardBigTableRows : */dashboardSmallTableRows)} style = {{position: 'absolute', right: '4px', top:'4px', padding: '2px 4px 0px', marginRight: '0px', lineHeight: '1.4'}} className = "btn btn-default btn-sm" type = "button" onClick = {() => clickMoveButtonRight(i, Item.issues.length)}>
                                    <FontAwesomeIcon icon = {faCaretRight}/>
                                </button>
                            </div>
                            {/* assignTo */}
                            <div className = "dashboard-item-tournament unresolved-issues">
                                {/*console.log (Item)*/}
                                <ReactTable
                                    data = {moveable !== undefined && Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] !== undefined ? Item.issues.slice (Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i], Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] + (expanded ? dashboardBigTableRows : dashboardSmallTableRows)) : Item.issues}
                                    columns = {[
                                        {width: 54, Header: Assets.Issue, accessor: 'id', headerClassName: 'th-left'},
                                        {width: 35, Header: <FontAwesomeIcon title = {Assets.Issue + ' ' + Assets.Type.toLowerCase()} icon = {faSitemap}/>, accessor: 'issueType', headerClassName: 'th-center', Cell: (row) => GetIssueType(row.value, true)},
                                        {width: 138, Header: Assets.IssueClassification, accessor: 'issueType', headerClassName: 'th-left', Cell: (row) => getIssueType(IssueTypes[row.value], row.original.riskLevel, true)},
                                        {width: 82, Header: <span title = {Assets.Created + ' ' + Assets.Timestamp.toLowerCase()}>{Assets.Created}&nbsp;<FontAwesomeIcon icon = {faClock}/></span>, accessor: 'created', headerClassName: 'th-left', Cell: (row) => row.value ? <div><div style = {{marginTop: '0px'}}>{row.value.substring(0, 10)}</div><div style = {{marginTop: '-9px'}}>{row.value.substring(11, 19)}<span style = {{fontSize: '11px'}}>&nbsp;{Assets_.UTC}</span></div></div> : Assets_.NA},
                                        {width: 25, Header: <FontAwesomeIcon title = {Assets.New} icon = {faMagic}/>, accessor: 'history', headerClassName: 'th-center', Cell: (row) => TBN(row.value === undefined || row.value.length === 0 ? true : false, undefined, true)},
                                        {width: 26, Header: <FontAwesomeIcon title = {Assets.History} icon = {faEye}/>, accessor: 'history', headerClassName: 'th-center', Cell: (row) => row.value ? <button style = {{marginLeft: '-4px'}} disabled = {row.value === undefined || row.value.length === 0} type = "button" title = {Assets.Show + ' '  + Assets.History.toLowerCase()} className = "btn btn-info btn-xs mr0" onClick = {() => {Logic.Type.New(Main.Instance.RiskManagementState, {mode: true, rowId: Item.index[Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] + row.index], history: row.value});;gotoTop()}}><FontAwesomeIcon icon = {faEye}/></button> : '-'},
                                        {width: 26, Header: <FontAwesomeIcon title = {Assets.Resolve} icon = {faCheckDouble}/>, accessor: 'resolved', headerClassName: 'th-center', Cell: (row) => row.value !== undefined ? <button title = {Assets.Resolve} style = {{marginLeft: '-4px'}} disabled = {isUserProhibitedToResolve(row)} onClick = {() => {DbRoutes.wsGetPlayerCard(Item.playerId); Logic.Type.New(Main.Instance.RiskManagementState, {mode: true, rowId: Item.index[Main.Instance.DatabaseDataState.unresolvedIssuesPlayersPositions[i] + row.index], showAddCSCallbackModal: true});gotoTop()}} type = "button" className = "btn btn-warning btn-xs mr0"><FontAwesomeIcon icon = {faCheckDouble}/></button> : '-'},
                                    ]}
                                    showPaginationBottom = {false}
                                    pageSize = {expanded ? (dashboardBigTableRows < Item.issues.length ? dashboardBigTableRows : Item.issues.length) : (dashboardSmallTableRows < Item.issues.length ? dashboardSmallTableRows : Item.issues.length)}
                                />
                            </div>
                        </div>,
                    )
                }
            }

        }
    }
    return List
}

// this function actually displays what is in the search options
function getOptionsPlayersSelection(): JSX.Element[] {
    // console.log (new Date().toISOString())
    // console.log ('playercard search string ' + Main.Instance.PlayerCardState.search_string)
    gotoTop()
    const options: JSX.Element[] = []
    const optionsTop: Array<{firstName: string, lastName: string, username: string, jsx: JSX.Element, playerId: string}> = []
    const optionsBottom: Array<{firstName: string, lastName: string, username: string, jsx: JSX.Element, playerId: string}> = []
    // options.push (<option key = "-1" value = "0">{Assets.ShowPlayersWithUnresolvedIssues/*SelectAPlayerFromTheListBelow*/}</option>)
    // for (const item of playerCards) {}
    let onlyPlayerId: string
    if (Main.Instance.DatabaseDataState.playerCards) {
        const playerCards: PlayerCard[] = Object.assign([], Main.Instance.DatabaseDataState.playerCards)
/*
        let searchId: string
        if (Main.Instance.PlayerCardState.search_id) {
            searchId = Main.Instance.PlayerCardState.search_id.toString()
        }
*/
        // console.log ('search_string ' + Main.Instance.PlayerCardState.search_string)
        if (Main.Instance.PlayerCardState.search_string && Main.Instance.PlayerCardState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
            // console.log ('search string ' + Main.Instance.PlayerCardState.search_string)
            for (let i: number = 0; i < playerCards.length; i++) {
                // .issues
                // console.log (item)
                // console.log ('search_id ' + Main.Instance.PlayerCardState.search_id)
                const item: PlayerCard = playerCards[i]
                // console.log ('item.playerId.length ' + item.playerId.length)
                if (Main.Instance.PlayerCardState.search_string) {
                    let found: boolean = false
                    // playerCardUsername
                    if (Main.Instance.PlayerCardState.search_string.length < Assets_.PLAYER_ID_LENGTH && Main.Instance.SearchState.playerCardUsername) {
                        const position: number = item.username.toLowerCase().indexOf(Main.Instance.PlayerCardState.search_string.toLowerCase())
                        if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                            if (position === 0) {
                                found = true
                            }
                        } else {
                            if (position !== -1) {
                                found = true
                            }
                        }
                    }

                    // playerCardId
                    if (!found && Main.Instance.SearchState.playerCardPlayerId) {
                        const position: number = item.playerId.toLowerCase().indexOf(Main.Instance.PlayerCardState.search_string.toLowerCase())
                        if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                            if (position === 0) {
                                found = true
                            }
                        } else {
                            if (position !== -1) {
                                found = true
                            }
                        }
                    }

                    // playerCardFirstName
                    if (Main.Instance.PlayerCardState.search_string.length < Assets_.PLAYER_ID_LENGTH && !found && isUserGDPR() && Main.Instance.SearchState.playerCardFirst) {
                        const position: number = item.firstName.toLowerCase().indexOf(Main.Instance.PlayerCardState.search_string.toLowerCase())
                        if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                            if (position === 0) {
                                found = true
                            }
                        } else {
                            if (position !== -1) {
                                found = true
                            }
                        }
                    }

                    // playerCardLastName
                    if (Main.Instance.PlayerCardState.search_string.length < Assets_.PLAYER_ID_LENGTH && !found && isUserGDPR() && Main.Instance.SearchState.playerCardLast) {
                        const position: number = item.lastName.toLowerCase().indexOf(Main.Instance.PlayerCardState.search_string.toLowerCase())
                        if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                            if (position === 0) {
                                found = true
                            }
                        } else {
                            if (position !== -1) {
                                found = true
                            }
                        }
                    }
                    if (!found) { continue}
                }
                // console.log ('let through: ' + item.playerId)
                // console.log (item.playerId)

                item.issues = 0
/*
                if (Main.Instance.DatabaseDataState.issues) {
                    for (const Item of Main.Instance.DatabaseDataState.issues) {
                        if (Item.playerId === item.playerId && Item.resolved === false) {
                            // if (item.issues === 0) console.log (Item.playerId)
                            item.issues++
                        }
                    }
                }
*/
                /*options.length === 1 && */
                if (optionsTop.length === 0 && optionsBottom.length === 0) { onlyPlayerId = item.playerId }
                // console.log (item)

                if (item.issues === 0) {
                    optionsBottom.push ({playerId: item.playerId, username: item.username.toLowerCase(), firstName: item.firstName.toLowerCase(), lastName: item.lastName.toLowerCase(),
                    jsx:
                        <option key = {i.toString()} value = {item.playerId}>
                            {isUserGDPR() ? item.lastName + ' ' + item.firstName + ' (' + item.username + ')' : item.username}
                        </option>,
                    })
                } else {
                    optionsTop.push ({playerId: item.playerId, username: item.username.toLowerCase(), firstName: item.firstName.toLowerCase(), lastName: item.lastName.toLowerCase(),
                    jsx:
                        <option key = {i.toString()} value = {item.playerId}>
                            {isUserGDPR() ? item.lastName + ' ' + item.firstName + ' (' + item.username + ') [' + item.issues + ']' : item.username + '[' + item.issues + ']'}
                        </option>,
                    })
                }
            }
        }
        
        if (isUserGDPR()) {
            optionsTop.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
            optionsBottom.sort((a, b) => (a.lastName > b.lastName) ? 1 : (a.lastName === b.lastName) ? ((a.firstName > b.firstName) ? 1 : (a.firstName === b.firstName) ? ((a.username > b.username) ? 1 : -1) : -1) : -1)
        } else {
            optionsTop.sort((a, b) => (a.username > b.username) ? 1 : -1)
            optionsBottom.sort((a, b) => (a.username > b.username) ? 1 : -1)
        }

        const optionsTopPlayerId: string[] = []
        for (const ITEM of optionsTop) {
            optionsTopPlayerId.push (ITEM.playerId)
        }

        const optionsBottomPlayerId: string[] = []
        for (const ITEM of optionsBottom) {
            optionsBottomPlayerId.push (ITEM.playerId)
        }

        if (optionsTopPlayerId.length > 0 && JSON.stringify(optionsTopPlayerId) !== JSON.stringify(Main.Instance.DatabaseDataState.optionsTopPlayerId)) {
            // console.log (optionsTopPlayerId)
            // console.log (Main.Instance.DatabaseDataState.optionsTopPlayerId)
            Logic.Type.New (Main.Instance.DatabaseDataState, {optionsTopPlayerId})
        }

        if (optionsBottomPlayerId.length > 0 && JSON.stringify(optionsBottomPlayerId) !== JSON.stringify(Main.Instance.DatabaseDataState.optionsBottomPlayerId)) {
            // console.log (optionsBottomPlayerId)
            // console.log (Main.Instance.DatabaseDataState.optionsBottomPlayerId)
            Logic.Type.New (Main.Instance.DatabaseDataState, {optionsBottomPlayerId})
        }

        // console.log ('top ' + optionsTop.length)

        for (const item of optionsTop) {
            options.push(item.jsx)
        }

        if (optionsTop.length > 0 && optionsBottom.length > 0) {
            options.push(
                <option disabled = {true} key = "-" value = "-">
                    &mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&ndash;
                </option>,
            )
        }
        
        // console.log ('bottom ' + optionsBottom.length)

        for (const item of optionsBottom) {
            options.push(item.jsx)
        }

        // console.log ('options ' + options.length)
        // console.log (Main.Instance.DatabaseDataState.playerCard.playerId)
        if (/*Main.Instance.PlayerCardState.search_id || */Main.Instance.PlayerCardState.search_string) {
            if (options.length === 1 && Main.Instance.PlayerCardState.id !== onlyPlayerId) {
                DbRoutes.wsGetPlayerCard(onlyPlayerId, true)
                Logic.Type.New (Main.Instance.PlayerCardState, {
                    gameSessionPage: 0,
                    onlyPlayerId: true,
                    id: onlyPlayerId,
                    filterIssues: false,
                    forcePlayerCardSelect: false
                })
                Logic.Type.New (Main.Instance.DatabaseDataState, {
                    manualWithdrawal: false,
                    manualBonus: false,
                    manualDeposit: false,
                    insertANewIssue: false,
                    manualCrif: false,
                    resendVerificationEmail: false,
                    showUploads: false,
                    checkVETO: false,
                    playerCard: {}
                })
            } else if (options.length === 0 && Main.Instance.DatabaseDataState.playerCard.playerId !== undefined) {
                // console.log (1)
                Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionPage: 0, onlyPlayerId: undefined, id: '0', filterIssues: false})
                Logic.Type.New (Main.Instance.DatabaseDataState, {
                    manualWithdrawal: false,
                    manualBonus: false,
                    manualDeposit: false,
                    insertANewIssue: false,
                    manualCrif: false,
                    resendVerificationEmail: false,
                    showUploads: false,
                    checkVETO: false,
                    playerCard: {}
                })

            } else if (options.length !== 1 && Main.Instance.PlayerCardState.onlyPlayerId !== undefined) {
                // console.log (2)
                Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionPage: 0, onlyPlayerId: undefined})
            }
        } else if (Main.Instance.PlayerCardState.onlyPlayerId !== undefined) {
            Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionPage: 0, onlyPlayerId: undefined})
        }
    }
    return options
}

export function getPlayerTimeline(nav: PlayerCardButtons) {
    // console.log ('request sent')
    Socket1.socket.Send(Config.amGetIssues, { 
        filters: {
        playerId: Main.Instance.DatabaseDataState.playerCard.playerId
        },
        page: 1,
        limit: 9007199254740991
    })
    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetIssues)

    Logic.Type.New (Main.Instance.DatabaseDataState, {PlayerTimelineIssues: true})
    if (nav !== Main.Instance.PlayerCardState.nav) {
        Logic.Type.New (Main.Instance.PlayerCardState, {nav})
    }
}

function selectGameSession(nav: PlayerCardButtons) {
/*
    console.log (Main.Instance.DatabaseDataState.d3)
    console.log (Main.Instance.DatabaseDataState.t3)
    console.log (Main.Instance.DatabaseDataState.d4)
    console.log (Main.Instance.DatabaseDataState.t4)
*/
    /*
    if (!Main.Instance.DatabaseDataState.d3 && !Main.Instance.DatabaseDataState.d4) {
        // console.log ('a')
        const d = new Date()
        const date: string = d.toISOString()
        const d3: moment.Moment = moment.default()
        const d4: moment.Moment = moment.default()
        const t3: string = '00:00:00'
        const t4: string = '23:59:59'

        const a3: string = date.substring(0,10) + ' ' + t3
        const a4: string = date.substring(0,10) + ' ' + t4
        // console.log (d3, d4, a3, a4, t3, t4)
        Logic.Type.New (Main.Instance.DatabaseDataState, {d3, d4, t3, t4})
        DbRoutes.getGameSessions(undefined, a3, a4)
    } else {
        DbRoutes.getGameSessions()
    }
    */
    DbRoutes.getGameSessions()
    Logic.Type.New (Main.Instance.PlayerCardState, {nav})
}

function f2_(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navRG: PlayerCardButtonsRG.Overview})
    getPlayerTimeline(i)
}

function f3_(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navKYC: PlayerCardButtonsKYC.Overview})
    getPlayerTimeline(i)
}

function f2(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navRG: PlayerCardButtonsRG.AllIssues})
    getPlayerTimeline(i)
}

function f3(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navKYC: PlayerCardButtonsKYC.AllIssues})
    getPlayerTimeline(i)
}

function f1(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navRM: PlayerCardButtonsRM.AllIssues})
    getPlayerTimeline(i)
}

function f4(i: number) {
    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navGM: PlayerCardButtonsGM.AllIssues})
    getPlayerTimeline(i)
}

function getButtonsFirstLine(unresolvedIssues: number[]): JSX.Element[] {
    const buttons: JSX.Element[] = []

    for (let i: number = 0; i < Assets_.PlayerCardButtons.length; i++) {
        if (i === PlayerCardButtons.GiftShop || i === PlayerCardButtons.Tournaments || i === PlayerCardButtons.Promotions) { continue }
        buttons.push (
            <button
                disabled = {((i === PlayerCardButtons.EventsTimeline || i === PlayerCardButtons.RiskManagement || i === PlayerCardButtons.ResponsibleGaming || i === PlayerCardButtons.KnowYourCustomer || i === PlayerCardButtons.Gamification) && !Main.Instance.DatabaseDataState.playerTimelineTransactions)}
                title = {Assets.PlayerCardButtonsDescription[i]}
                type = "button"
                className = {i === Main.Instance.PlayerCardState.nav ? 'dbl fl btn btn-primary btn-xs' : 'dbl fl btn btn-default btn-xs'}
                onClick = {() => {
                    Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                    i === PlayerCardButtons.EventsTimeline || i >= PlayerCardButtons.RiskManagement && i <= PlayerCardButtons.Gamification ?
                        i === PlayerCardButtons.ResponsibleGaming && unresolvedIssues[i - 2] === 0 ? f2_(i)
                        : i === PlayerCardButtons.KnowYourCustomer && unresolvedIssues[i - 2] === 0 ? f3_(i)
                        : i === PlayerCardButtons.ResponsibleGaming ? f2(i) 
                        : i === PlayerCardButtons.KnowYourCustomer ? f3(i)
                        : i === PlayerCardButtons.RiskManagement ? f1(i) 
                        : i === PlayerCardButtons.Gamification ? f4(i)
                        : getPlayerTimeline(i)
                    :
                    i === PlayerCardButtons.GameSessions ? selectGameSession(i)
                    :
/*
                    i === PlayerCardButtons.ResponsibleGaming && unresolvedIssues[i - 2] === 0 ?
                    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navRG: PlayerCardButtonsRG.Overview})
                    :
                    i === PlayerCardButtons.KnowYourCustomer && unresolvedIssues[i - 2] === 0 ?
                    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i, navKYC: PlayerCardButtonsKYC.Overview})
                    :
*/
                    Logic.Type.New (Main.Instance.PlayerCardState, {nav: i})
                }}
                key = {i.toString()}
            >
            {Assets_.PlayerCardButtons[i]}
            {
            i === PlayerCardButtons.EventsTimeline ?
            <>
                &nbsp;&nbsp;
                <span className = "badge badge-light">
                    {Main.Instance.DatabaseDataState.PlayerTimelineIssues === undefined ? '?' : Main.Instance.DatabaseDataState.playerTimeline.length}
                </span>
            </> :
            i === PlayerCardButtons.Transactions ?
            <>
                &nbsp;&nbsp;
                <span className = "badge badge-light">
                    { Main.Instance.DatabaseDataState.playerTimelineTransactions === undefined ? '?' : Main.Instance.DatabaseDataState.playerTimelineTransactions.length}
                </span>
            </> :
            i === PlayerCardButtons.ResponsibleGaming || i === PlayerCardButtons.RiskManagement || i === PlayerCardButtons.KnowYourCustomer || i === PlayerCardButtons.Gamification ?
            <>
                &nbsp;&nbsp;
                <span className = {unresolvedIssues[i - 2] > 0 ? 'badge badge-danger' : 'badge badge-light'}>
                    {Main.Instance.DatabaseDataState.PlayerTimelineIssues === undefined ? '?' : unresolvedIssues[i - 2]}
                </span>
            </> : void 0}
            </button>,
        )
    }
    
    return buttons
}

function isWrongGroup(i: PlayerCardButtonsET): boolean {
    if (Main.Instance.PlayerCardState.navET >= PlayerCardButtonsET.AllTransactions && Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllSessions) {
        if (i === PlayerCardButtonsET.Closed || i === PlayerCardButtonsET.Open || i === PlayerCardButtonsET.KnowYourCustomer || i === PlayerCardButtonsET.ResponsibleGaming || i === PlayerCardButtonsET.RiskManagement || i === PlayerCardButtonsET.Gamification) {
            return true
        }
    }

    if (Main.Instance.PlayerCardState.navET >= PlayerCardButtonsET.AllSessions && Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllIssues) {
        if (i === PlayerCardButtonsET.AllDeposits || i === PlayerCardButtonsET.AllWithdrawals ||i === PlayerCardButtonsET.Deposits || i === PlayerCardButtonsET.Withdrawals || i === PlayerCardButtonsET.ManualDeposits || i === PlayerCardButtonsET.ManualWithdrawals || i === PlayerCardButtonsET.CanceledWithdrawals || i === PlayerCardButtonsET.KnowYourCustomer || i === PlayerCardButtonsET.ResponsibleGaming || i === PlayerCardButtonsET.RiskManagement || i === PlayerCardButtonsET.Gamification) {
            return true
        }
    }

    if (Main.Instance.PlayerCardState.navET >= PlayerCardButtonsET.AllIssues) {
        if (i === PlayerCardButtonsET.AllDeposits || i === PlayerCardButtonsET.AllWithdrawals || i === PlayerCardButtonsET.Deposits || i === PlayerCardButtonsET.Withdrawals || i === PlayerCardButtonsET.ManualDeposits || i === PlayerCardButtonsET.ManualWithdrawals || i === PlayerCardButtonsET.CanceledWithdrawals || i === PlayerCardButtonsET.Closed || i === PlayerCardButtonsET.Open) {
            return true
        }
    }

    if (Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Everything) {
        if (i === PlayerCardButtonsET.AllDeposits || i === PlayerCardButtonsET.AllWithdrawals ||i === PlayerCardButtonsET.Deposits || i === PlayerCardButtonsET.Withdrawals || i === PlayerCardButtonsET.ManualDeposits || i === PlayerCardButtonsET.ManualWithdrawals || i === PlayerCardButtonsET.CanceledWithdrawals || i === PlayerCardButtonsET.Closed || i === PlayerCardButtonsET.Open || i === PlayerCardButtonsET.KnowYourCustomer || i === PlayerCardButtonsET.ResponsibleGaming || i === PlayerCardButtonsET.RiskManagement || i === PlayerCardButtonsET.Gamification) {
            return true
        }

    }
}

function isGroup(i: PlayerCardButtonsET): boolean {
    if (i === PlayerCardButtonsET.AllTransactions && Main.Instance.PlayerCardState.navET > PlayerCardButtonsET.AllTransactions && Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllSessions) {
        return true
    }

    if (i === PlayerCardButtonsET.AllSessions && Main.Instance.PlayerCardState.navET > PlayerCardButtonsET.AllSessions && Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllIssues) {
        return true
    }

    if (i === PlayerCardButtonsET.AllIssues && Main.Instance.PlayerCardState.navET > PlayerCardButtonsET.AllIssues) {
        return true
    }

    return false
}

/*
function getButtonsThirdLine(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    for (let i: number = 0; i < Assets.StatusTypeText.length; i++) {
        buttons.push (
            <button
                type = "button"
                className = {i === Main.Instance.PlayerCardState.navTS ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                onClick = {() =>
                    Logic.Type.New (Main.Instance.PlayerCardState, {navTS: i})
                }
                key = {i.toString()}
            >
                {Assets.StatusTypeText[i]}
            </button>,
        )
    }
    return buttons
}
*/


function getButtonsSecondLine(/*playerTimeline?: boolean*/): JSX.Element[] {

    const buttons: JSX.Element[] = []
    // console.log (Main.Instance.PlayerCardState.nav)
    // console.log (PlayerCardButtons[Main.Instance.PlayerCardState.nav])
    if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline) {
        const allIssues: number[] = []
        const Buttons: JSX.Element[] = []
        // init
        for (let i: number = 0; i < Object.keys(PlayerCardButtonsET).length / 2; i++) {
            allIssues.push(0)
        }

        if (Main.Instance.DatabaseDataState.playerTimeline) {
            for (const item of Main.Instance.DatabaseDataState.playerTimeline) {
                allIssues[0]++
                if (item.entryType === TimelineEntryType.Transaction) {
                    // All Transactions = 1
                    allIssues[1]++
                    if (item.entrySubType === TimelineEntrySubType.Deposit || item.entrySubType === TimelineEntrySubType.ManualDeposit || item.entrySubType === TimelineEntrySubType.CanceledDeposit) {
                        allIssues[2]++
                    } else if (item.entrySubType === TimelineEntrySubType.Withdraw || item.entrySubType === TimelineEntrySubType.ManualWithdraw || item.entrySubType === TimelineEntrySubType.CanceledWithdraw) {
                        allIssues[3]++
                    }

                    allIssues[item.entrySubType - 7]++
                } else if (item.entryType === TimelineEntryType.Session) {
                    // All sessions = 10
                    allIssues[4 + 6]++
                    allIssues[item.entrySubType - 16 + 6]++
                } else if (item.entryType === TimelineEntryType.Issue) {
                    // All Issues = 10
                    allIssues[7 + 6]++
                    allIssues[item.entrySubType - 23 + 6]++
                }
            }
        }
        
        // console.log (allIssues)
        // console.log (allIssues.length, Object.keys(PlayerCardButtonsET).length / 2)
        for (let i: number = 0; i < Object.keys(PlayerCardButtonsET).length / 2; i++) {
            // console.log (i, PlayerCardButtonsET[i])
            // if (allIssues[i] === 0 || i > PlayerCardButtonsET.AllIssues && Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllIssues) continue
            if (isWrongGroup(i) || allIssues[i] === 0) { continue }
            Buttons.push (
                <button
                    type = "button"
                    className = {i === Main.Instance.PlayerCardState.navET ? 'dbl fl btn btn-primary btn-xs' : isGroup(i) ? 'dbl fl btn btn-default btn-dark btn-xs' : 'dbl fl btn btn-default btn-xs'}
                    onClick = {() => {
                        Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                        Logic.Type.New (Main.Instance.PlayerCardState, {navET: i})
                    }}
                    key = {i.toString()}
                >
                    {isGroup(i) && i !== Main.Instance.PlayerCardState.navET ? injectSpaces(PlayerCardButtonsET[i]).split(' ')[1].trim() : injectSpaces(PlayerCardButtonsET[i])}
                    <span className = "badge badge-light ml">{allIssues[i]}</span>
                </button>,
            )
        }

        buttons.push(<div style = {{width: '100%', height: '30px'}} key = "Buttons">{Buttons}</div>)
        if (Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.Overview) {
            Logic.Type.New (Main.Instance.PlayerCardState, {navRG: PlayerCardButtonsRG.AllIssues})
        }
        if (Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.Overview) {
            Logic.Type.New (Main.Instance.PlayerCardState, {navKYC: PlayerCardButtonsKYC.AllIssues})
        }
    }
    
    if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions /* || Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllTransactions*/) {
        const count: number[] = []
        for (const {} of Assets.PlayerCardButtonsTR) {
            count.push(0)
        }
        if (Main.Instance.DatabaseDataState.playerTimelineTransactions) {
            for (const item of Main.Instance.DatabaseDataState.playerTimelineTransactions) {
                count[0]++
                if (item['transactionType'] === TransactionType.DEPOSIT) {
                    count[1]++
                    count[3]++
                } else if (item['transactionType'] === TransactionType.DEPOSIT_MANUAL) {
                    count[1]++
                    count[4]++
                } else if (item['transactionType'] === TransactionType.DEPOSIT_CANCEL) {
                    count[1]++
                    count[5]++
                } else if (item['transactionType'] === TransactionType.WITHDRAW) {
                    count[2]++
                    count[6]++
                } else if (item['transactionType'] === TransactionType.WITHDRAW_MANUAL) {
                    count[2]++
                    count[7]++
                } else if (item['transactionType'] === TransactionType.WITHDRAW_CANCEL) {
                    count[2]++
                    count[8]++
                }
            }
        }

        for (let i: number = 0; i < Assets.PlayerCardButtonsTR.length; i++) {
            if (count[i] === 0) {continue}
            buttons.push (
                <button
                    type = "button"
                    className = {i === Main.Instance.PlayerCardState.navTR || i === Main.Instance.PlayerCardState.navETT ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                    onClick = {() => {
                        Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                        Logic.Type.New (Main.Instance.PlayerCardState, {navTR: i, navETT: i})
                    }}
                    key = {i.toString()}
                >
                    {Assets.PlayerCardButtonsTR[i]}<span className = "badge badge-primary ml">{count[i]}</span>
                </button>,
            )
        }
        // badge
/*
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory && Main.Instance.DatabaseDataState.sessionId && Main.Instance.DatabaseDataState.sessionTransactions) {
        for (let i: number = 0; i < Assets.PlayerCardButtonsPH.length; i++) {
            buttons.push (
                <button
                    type = "button"
                    className = {i === Main.Instance.PlayerCardState.navPH ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                    onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {navPH: i})}
                    key = {i.toString()}
                >{Assets.PlayerCardButtonsPH[i]}
                </button>,
            )
        }
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory) {
        for (let i: number = 0; i < Assets.PlayerCardButtonsPS.length; i++) {
            buttons.push (
                <button
                    type = "button"
                    className = {i === Main.Instance.PlayerCardState.navPS ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                    onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {navPS: i})}
                    key = {i.toString()}
                >{Assets.PlayerCardButtonsPS[i]}
                </button>,
            )
        }
*/
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.GameSessions) {


    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.Promotions) {
        for (let i: number = 0; i < Assets.PlayerCardButtonsPR.length; i++) {
            buttons.push (
                <button
                    type = "button"
                    className = {i === Main.Instance.PlayerCardState.navPR ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                    onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {navPR: i})}
                    key = {i.toString()}
                >{Assets.PlayerCardButtonsPR[i]}
                </button>,
            )
        }
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.RiskManagement || Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.RiskManagement) {
        const array: number[] = Object.keys(PlayerCardButtonsRM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRM[key])
        // console.log (array)
        // console.log (Object.keys(PlayerCardButtonsRM))
        const unresolved: number[] = []
        const allIssues: number[] = []
        for (let i: number = 0; i < Assets.PlayerCardButtonsRM.length; i++) {
            unresolved.push(0)
            allIssues.push(0)
        }
        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline.length : 0); i++) {
            if (Main.Instance.DatabaseDataState.playerTimeline[i].entrySubType === TimelineEntrySubType.RiskManagement) {
                const item: IIssue = Main.Instance.DatabaseDataState.playerTimeline[i] as IIssue
                allIssues[0]++
                for (let j: number = 2; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        allIssues[j - 1]++
                    }
                }
                if (item.resolved) { continue }
                unresolved[0]++
                for (let j: number = 2; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        unresolved[j - 1]++
                    }
                }
            }
        }

        for (let i: number = 0; i < Assets.PlayerCardButtonsRM.length; i++) {
            if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && allIssues[i] || allIssues[i] /*unresolved[i]*/) {
                buttons.push (
                    <button
                        disabled = {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ? allIssues[i] === 0 : false /*unresolved[i] === 0*/}
                        style = {i === Assets.PlayerCardButtonsRM.length - 1 ? {marginRight: '0px', marginBottom: '5px'} : {marginBottom: '5px'}}
                        type = "button"
                        className = {i === Main.Instance.PlayerCardState.navRM ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                        onClick = {() => {
                            Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                            Logic.Type.New (Main.Instance.PlayerCardState, {navRM: i, navIssue: array[i + 1]})
                        }}
                        key = {i.toString()}
                    >
                        {Assets.PlayerCardButtonsRM[i]}
                        {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ?
                        <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{allIssues[i]}</span> :
                        <>
                        <span className = {unresolved[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span>
                        <span className = {allIssues[i] ? 'badge badge-default ml' : 'badge badge-light ml'}>{allIssues[i]}</span>
                        </>
                        }
                    </button>,
                )
            }
        }
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.ResponsibleGaming || Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.ResponsibleGaming) {
        // console.log (Object.keys(PlayerCardButtonsRG))
        const array: number[] = Object.keys(PlayerCardButtonsRG).filter(StringIsNotNumber).map((key) => PlayerCardButtonsRG[key])
        // console.log (array)
        const unresolved: number[] = []
        const allIssues: number[] = []
        for (let i: number = 0; i < Assets.PlayerCardButtonsRG.length; i++) {
            unresolved.push(0)
            allIssues.push(0)
        } 
        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline.length : 0); i++) {
            if (Main.Instance.DatabaseDataState.playerTimeline[i].entrySubType === TimelineEntrySubType.ResponsibleGaming) {
                const item: IIssue = Main.Instance.DatabaseDataState.playerTimeline[i] as IIssue
                allIssues[1]++
                for (let j: number = 3; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        allIssues[j - 1]++
                    }
                }
                if (item.resolved) { continue }
                unresolved[1]++
                for (let j: number = 3; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        unresolved[j - 1]++
                    }
                }
            }
        }

        for (let i: number = 0; i < Assets.PlayerCardButtonsRG.length; i++) {
            const DASHBOARD_BACKEND_POSITION: number = 13
            if (i === DASHBOARD_BACKEND_POSITION) { buttons.push (
                <br style = {{float: 'none', clear: 'both'}} key = "br"/>,
            )
            }
            if (i > 0 && Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && allIssues[i] || Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline && (i === 0 || allIssues[i] /*unresolved[i]*/)) {
                buttons.push (
                    <button
                        disabled = {i > 0 && (i === DASHBOARD_BACKEND_POSITION || (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ? allIssues[i] === 0 : false /*unresolved[i] === 0*/))}
                        type = "button"
                        style = {i >= DASHBOARD_BACKEND_POSITION ? {marginTop: '5px', marginBottom: '5px'} : {marginBottom: '5px'}}
                        className = {i === DASHBOARD_BACKEND_POSITION ? 'dbl fl btn btn-default btn-xs' : i === Main.Instance.PlayerCardState.navRG ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                        onClick = {() => {
                            Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                            Logic.Type.New (Main.Instance.PlayerCardState, {navRG: i, navIssue: array[i + 1]})
                        }}
                        key = {i.toString()}
                    >
                        {Assets.PlayerCardButtonsRG[i]}
                        {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ?
                        i > 0 ? <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{allIssues[i]}</span> : void 0 :
                        i > 0 ? 
                        <>
                        <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span> 
                        <span className = {allIssues[i] ? 'badge badge-default ml' : 'badge badge-light ml'}>{allIssues[i]}</span>
                        </>
                        : void 0}
                    </button>,
                )
            }
        }
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.KnowYourCustomer || Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.KnowYourCustomer) {
        const array: number[] = Object.keys(PlayerCardButtonsKYC).filter(StringIsNotNumber).map((key) => PlayerCardButtonsKYC[key])
        const unresolved: number[] = []
        const allIssues: number[] = []
        for (let i: number = 0; i < Assets.PlayerCardButtonsKYC.length; i++) {
            unresolved.push(0)
            allIssues.push(0)
        } 
        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline.length : 0); i++) {
            if (Main.Instance.DatabaseDataState.playerTimeline[i].entrySubType === TimelineEntrySubType.KnowYourCustomer) {
                const item: IIssue = Main.Instance.DatabaseDataState.playerTimeline[i] as IIssue
                allIssues[1]++
                for (let j: number = 3; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        allIssues[j - 1]++
                    }
                }

                if (item.resolved) { continue }
                unresolved[1]++
                for (let j: number = 3; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        unresolved[j - 1]++
                    }
                }
            }
        }

        for (let i: number = 0; i < Assets.PlayerCardButtonsKYC.length; i++) {
            if (i > 0 && Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && allIssues[i] || 
                Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline && (i === 0 || allIssues[i])) {
                buttons.push (
                    <button
                        disabled = {i > 0 && (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ? allIssues[i] === 0 : false/*unresolved[i] === 0*/)}
                        style = {{marginBottom: '5px'}}
                        type = "button"
                        className = {i === Main.Instance.PlayerCardState.navKYC ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                        onClick = {() => {
                            Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                            Logic.Type.New (Main.Instance.PlayerCardState, {navKYC: i, navIssue: array[i + 1]})
                        }}
                        key = {i.toString()}
                    >
                        {Assets.PlayerCardButtonsKYC[i]}
                        {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ?
                        i > 0 ? <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{allIssues[i]}</span> : void 0 :
                        i > 0 ? 
                        <>
                        <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span>
                        <span className = {allIssues[i] ? 'badge badge-default ml' : 'badge badge-light ml'}>{allIssues[i]}</span>
                        </>
                        : void 0}
                    </button>,
                )
            }
        }
    } else if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.Gamification || Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Gamification) {
        const array: number[] = Object.keys(PlayerCardButtonsGM).filter(StringIsNotNumber).map((key) => PlayerCardButtonsGM[key])
        const unresolved: number[] = []
        const allIssues: number[] = []
        for (let i: number = 0; i < Assets.PlayerCardButtonsGM.length; i++) {
            unresolved.push(0)
            allIssues.push(0)
        } 
        for (let i: number = 0; i < (Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline.length : 0); i++) {
            if (Main.Instance.DatabaseDataState.playerTimeline[i].entrySubType === TimelineEntrySubType.Gamification) {
                const item: IIssue = Main.Instance.DatabaseDataState.playerTimeline[i] as IIssue
                allIssues[0]++
                for (let j: number = 2; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        allIssues[j - 1]++
                    }
                }

                if (item.resolved) { continue }
                unresolved[0]++
                for (let j: number = 2; j < array.length; j++) {
                    if (item.issueType === array[j]) {
                        unresolved[j - 1]++
                    }
                }
            }
        }

        for (let i: number = 0; i < Assets.PlayerCardButtonsGM.length; i++) {
            if (Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && allIssues[i] || allIssues[i]) {
                buttons.push (
                    <button
                        disabled = {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ? allIssues[i] === 0 : false /*unresolved[i] === 0*/}
                        style = {{marginBottom: '5px'}}
                        type = "button"
                        className = {i === Main.Instance.PlayerCardState.navGM ? 'dbl fl btn btn-info btn-xs' : 'dbl fl btn btn-default btn-xs'}
                        onClick = {() => {
                            Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: 0})
                            Logic.Type.New (Main.Instance.PlayerCardState, {navGM: i, navIssue: array[i + 1]})
                        }}
                        key = {i.toString()}
                    >
                        {Assets.PlayerCardButtonsGM[i]}
                        {Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ?
                        <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{allIssues[i]}</span> :
                        <>
                        <span className = {allIssues[i] ? 'badge badge-danger ml' : 'badge badge-light ml'}>{unresolved[i]}</span>
                        <span className = {allIssues[i] ? 'badge badge-default ml' : 'badge badge-light ml'}>{allIssues[i]}</span>
                        </>
                        }
                    </button>,
                )
            }
        }

    }

    
    return buttons
}

function getIssueType(type: string, riskLevel: RiskLevel, oneRow?: boolean): JSX.Element {
    if (type) {
        const Type: string[] = type.match(/[A-Z][a-z]+/g)
        const split: JSX.Element[] = []
        let s: string = ''
        let nv: boolean = false
        let cat: string
        // console.log (riskLevel)
        // console.log (IssueTypes[IssueTypes.LargeWithdrawal])
        // console.log (IssueTypes[IssueTypes.LargeDeposit])
        // console.log (type)
        for (let i: number = 0; i < Type.length; i++) {
            if (oneRow) {
                if (i > 0) { s += ' ' }

                if (i === Type.length - 1 && (type === IssueTypes[IssueTypes.AccountClosedActive] || type === IssueTypes[IssueTypes.AccountClosedProvisional])) {
                    if (type === IssueTypes[IssueTypes.AccountClosedActive]) {
                        cat = Assets.ActiveShort + '.'
                    } else if (type === IssueTypes[IssueTypes.AccountClosedProvisional]) {
                        cat = Assets.ProvisionalShort + '.'
                    }
                    // console.log (cat, Type, i, type)
                    s += ' ' + cat/*.charAt(0)*/ // + ')'
                } else { s += Type[i] }
                
                // console.log (Type[i])
                if (i === Type.length - 1 && (type === IssueTypes[IssueTypes.LargeDeposit] || type === IssueTypes[IssueTypes.LargeWithdrawal]) && riskLevel >= RiskLevel.High) {
                    s += ' ' + Assets.NeedsVerificationAbbrev // + ')'
                    nv = true
                }
            }

            split.push (<div title = {oneRow && nv ? Assets.NeedsVerification : oneRow && cat ? cat : ''} className = "lh14" key = {i.toString()}>{Type[i]}{(type === 'Large Deposit' || type === 'Large Withdrawal') && riskLevel >= RiskLevel.High ? <strong style = {{marginLeft: '3px'}} title = {Assets.NeedsVerification}>{Assets.NeedsVerificationAbbrev}</strong> : void 0}</div>)
        }
        return <div>{oneRow ? s : split}</div>
    }
    return <div className = "strong">?</div>
}

function getIssueSubType(subType: TimelineEntrySubType, long?: boolean): string {
    if (subType === TimelineEntrySubType.KnowYourCustomer) {
        return long ? Assets.KnowYourCustomer : Assets.KnowYourCustomerShort
    } else if (subType === TimelineEntrySubType.ResponsibleGaming) {
        return long ? Assets.ResponsibleGaming : Assets.ResponsibleGamingShort
    } else if (subType === TimelineEntrySubType.RiskManagement) {
        return long ? Assets.RiskManagement : Assets.RiskManagementShort
    } else if (subType === TimelineEntrySubType.Gamification) {
        return long ? Assets.Gamification : Assets.GamificationShort
    } else {
        return '?'
    }
}

/*
function getSessionType(type: string): JSX.Element {
    return <div>{type.match(/[A-Z][a-z]+/g)[0]}</div>
}
*/
function getUnresolvedIssues(): number[] {
    const unresolvedIssues: number[] = [0, 0, 0, 0, 0]
    // console.log (Main.Instance.LoginState.custom.securityRights)
    // console.log (Main.Instance.DatabaseDataState.playerTimeline)
    if (Main.Instance.DatabaseDataState.playerTimeline) {
        for (const item of Main.Instance.DatabaseDataState.playerTimeline) {
            if (!item['resolved'] && item['entryType'] === TimelineEntryType.Issue) {
                unresolvedIssues[0]++
                if (item['entrySubType'] === TimelineEntrySubType.Gamification) {
                    unresolvedIssues[4]++
                } else if (item['entrySubType'] === TimelineEntrySubType.KnowYourCustomer) {
                    unresolvedIssues[3]++
                } else if (item['entrySubType'] === TimelineEntrySubType.ResponsibleGaming) {
                    unresolvedIssues[2]++
                } else if (item['entrySubType'] === TimelineEntrySubType.RiskManagement) {
                    unresolvedIssues[1]++
                }
            }
        }
    }

    return unresolvedIssues
}
function canAccountStatusBeChanged(value: number) {
    return Main.Instance.DatabaseDataState.custom.general.players.accountStatus[value].requirements === undefined 
}

function changeText(property: string, value: string) {
/*
    if (property === 'emailNew') {
        // if (value.length > 1) {
        const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
        playerCard[property] = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})

        // }
    } else if (property === 'addressNew' || property === 'streetNumberNew' || property === 'cityNew' || property === 'postalCodeNew') {
        // if (property === 'streetNumberNew' || value.length > 1) {
        const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
        if (property === 'addressNew') {playerCard.addressNew = value}
        if (property === 'streetNumberNew') {playerCard.streetNumberNew = value}
        if (property === 'cityNew') {playerCard.cityNew = value}
        if (property === 'postalCodeNew') {playerCard.postalCodeNew = value}
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
        // }
    } else if (property === 'genderNew' || property === 'birthdayNew' || property === 'firstNameNew' || property === 'lastNameNew') {
        // if (property === 'genderNew' || value.length > 1) {
        const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
        if (property === 'genderNew') {playerCard.genderNew = value}
        if (property === 'birthdayNew') {playerCard.birthdayNew = value}
        if (property === 'firstNameNew') {playerCard.firstNameNew = value}
        if (property === 'lastNameNew') {playerCard.lastNameNew = value}
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})

        // }
    } else
*/

    if (property !== 'gender' && property !== 'genderNew' || property === 'gender' && (value.length === 0 || value.length === 1 && (value.charAt(0) === 'm' || value.charAt(0) === 'f')) || property === 'genderNew' && (value.length === 0 || value.length === 1 && (value.charAt(0) === 'm' || value.charAt(0) === 'f'))) {
        const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
        
        playerCard[property] = value
        if (property === 'emailNew') {
            axios.post(Config.aEmailCheck, {email: value}).then((response) => {
                // console.log ('response')
                // console.log (response.data)
                Logic.Type.New (Main.Instance.DatabaseDataState, {
                    emailAlreadyUsed: !response.data.resolved && response.data.error.status === 2102,
                    playerCard,
                    emailNewValid: isEmailValid(value)
                })
            })
        } else if (property === 'usernameNew') {
            axios.post(Config.aUsernameCheck, {username: value}).then((response) => {
                // console.log ('response')
                // console.log (response.data)
                Logic.Type.New (Main.Instance.DatabaseDataState, {
                    usernameAlreadyUsed: !response.data.resolved && response.data.error.status === 2101,
                    playerCard,
                    usernameNewValid: isUsernameValid(value)
                })
            })

        } else {
            Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
        }
    }
}

function isPhoneNumberChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.phoneNumber !== Main.Instance.DatabaseDataState.playerCard.phoneNumberNew
}

function isEmailChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.email !== Main.Instance.DatabaseDataState.playerCard.emailNew
}

function isNationalityChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.nationality !== Main.Instance.DatabaseDataState.playerCard.nationalityNew
}

export function isUsernameChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.username !== Main.Instance.DatabaseDataState.playerCard.usernameNew
}

function isBankingDetailsChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.beneficiaryName !== Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew
    || Main.Instance.DatabaseDataState.playerCard.iban !== Main.Instance.DatabaseDataState.playerCard.ibanNew
    || Main.Instance.DatabaseDataState.playerCard.bic !== Main.Instance.DatabaseDataState.playerCard.bicNew
}

function isNameChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.firstName !== Main.Instance.DatabaseDataState.playerCard.firstNameNew
    || Main.Instance.DatabaseDataState.playerCard.lastName !== Main.Instance.DatabaseDataState.playerCard.lastNameNew
    || displayBirthday(false) !== Main.Instance.DatabaseDataState.playerCard.birthdayNew
    || Main.Instance.DatabaseDataState.playerCard.gender !== Main.Instance.DatabaseDataState.playerCard.genderNew
}

function isAddressChanged(): boolean {
    return Main.Instance.DatabaseDataState.playerCard.address !== Main.Instance.DatabaseDataState.playerCard.addressNew
    || Main.Instance.DatabaseDataState.playerCard.streetNumber !== Main.Instance.DatabaseDataState.playerCard.streetNumberNew
    || Main.Instance.DatabaseDataState.playerCard.city !== Main.Instance.DatabaseDataState.playerCard.cityNew
    || Main.Instance.DatabaseDataState.playerCard.postalCode !== Main.Instance.DatabaseDataState.playerCard.postalCodeNew
    || Main.Instance.DatabaseDataState.playerCard.country !== Main.Instance.DatabaseDataState.playerCard.countryNew
}

function changeNumber(property: string, value: string) {
    if (property !== 'accountStatus' || property === 'accountStatus' && canAccountStatusBeChanged(parseInt(value))) {
/*
        console.log ('1')
        console.log (Main.Instance.DatabaseDataState.playerCard[property])
        console.log ('2')
        console.log (parseInt(value))
        if (property === 'accountStatus' && Main.Instance.DatabaseDataState.playerCard[property] === AccountStatus.Provisional && parseInt(value) === AccountStatus.BlockedPartial) {
            console.log (disabled)
        } else {
  */
        const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
        playerCard[property] = +value
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
        // }
    }
}
/*
function getTitle(property: string): string {
    return canBeModifiedByAdmin(property) ? property : Assets.ThisItemCannotBeChangedInThePlayerCard 
}
*/
function canBeModifiedByAdmin(property: string, force?: boolean): boolean {
    if (force === false) { return force }
    if (isNew(property)) {return true}
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.playerCard) {
        for (const item of Main.Instance.DatabaseDataState.custom.general.players.playerCard) {
            if (item.name === property) {
                if (item.flags) { return item.flags.modifyAdmin } else { return false }
            }
        }
    }
    return false
}

function isNew(property: string): boolean {
    if (property === 'usernameNew' ||property === 'nationalityNew' || property === 'bicNew' || property === 'ibanNew' || property === 'beneficiaryNameNew' || property === 'phoneNumberNew' || property === 'streetNumberNew' || property === 'firstNameNew' || property === 'lastNameNew' || property === 'addressNew' || property === 'cityNew' || property === 'postalCodeNew' || property === 'emailNew' || property === 'birthdayNew' || property === 'genderNew') {return true}
    return false
}

function isSame(property: string): boolean {
    const Property: string = property.substring(0, property.length -3)
    // console.log (property, Property, Main.Instance.DatabaseDataState.playerCard[property], Main.Instance.DatabaseDataState.playerCard[Property])
    if ((Main.Instance.DatabaseDataState.playerCard[property] === null || Main.Instance.DatabaseDataState.playerCard[property] === undefined) && 
       (Main.Instance.DatabaseDataState.playerCard[Property] === null || Main.Instance.DatabaseDataState.playerCard[Property] === undefined)) {
        return true
    }

    if (Main.Instance.DatabaseDataState.playerCard[property] === null || Main.Instance.DatabaseDataState.playerCard[property] === undefined || 
        Main.Instance.DatabaseDataState.playerCard[Property] === null || Main.Instance.DatabaseDataState.playerCard[Property] === undefined) {
           return false
    }
    return Property === 'birthday' ? displayBirthday(true).trim() === Main.Instance.DatabaseDataState.playerCard[Property].trim() : Main.Instance.DatabaseDataState.playerCard[property].trim() === Main.Instance.DatabaseDataState.playerCard[Property].trim()
}

function getStyle(property: string, force?: boolean): any {
    if (property === 'isDocumentVerified' && !Main.Instance.DatabaseDataState.playerCard.isDocumentVerified) {
        if (Main.Instance.DatabaseDataState.playerCard.documentExpiry === undefined || Main.Instance.DatabaseDataState.playerCard.documentExpiry === null || Main.Instance.DatabaseDataState.playerCard.documentExpiry.length === 0) { force = true }
        if (Main.Instance.DatabaseDataState.playerCard.issuingAuthority === undefined || Main.Instance.DatabaseDataState.playerCard.issuingAuthority === null || Main.Instance.DatabaseDataState.playerCard.issuingAuthority.length === 0) { force = true }
        if (Main.Instance.DatabaseDataState.playerCard.documentNumber === undefined || Main.Instance.DatabaseDataState.playerCard.documentNumber === null || Main.Instance.DatabaseDataState.playerCard.documentNumber.length === 0) { force = true }
        if (Main.Instance.DatabaseDataState.playerCard.documentType === undefined || Main.Instance.DatabaseDataState.playerCard.documentType === null || Main.Instance.DatabaseDataState.playerCard.documentType.length === 0) { force = true }
        if (Main.Instance.DatabaseDataState.playerCard.issueDate === undefined || Main.Instance.DatabaseDataState.playerCard.issueDate === null || Main.Instance.DatabaseDataState.playerCard.issueDate.length === 0) { force = true }
    }

    if (property === 'playerId' || property === Assets.AllCategories) { return {borderRadius: '0px', fontSize: '13px', color: '#333', background: 'rgba(217,83,79,0.5)', border: '1px solid rgba(212,63,58,0.5)', paddingLeft: '0px', paddingRight: '0px'} }
    if (property === 'Limits' || property === 'SelfAssessment' || property === 'Cooloffs') { return {borderRadius: '0px', fontSize: '13px', color: '#111', background: '#999'/*'rgba(119,119,119,1)'*/, border: '1px solid #333', paddingLeft: '0px', paddingRight: '0px'} }
    if (property === 'withdrawBalance') { return {borderRadius: '0px', fontSize: '13px', color: '#fff', background: 'rgb(92, 184, 92)'/*'rgba(119,119,119,1)'*/, border: '1px solid #459F45', paddingLeft: '0px', paddingRight: '0px'} }

    return canBeModifiedByAdmin(property) && !force ? isNew(property) ? {background: isSame(property) ? 'rgba(92,184,92,0.5)' : 'rgba(217, 83, 79,0.5)', cursor: 'pointer', borderRadius: '0px', fontSize: '13px', color: '#333', border: '1px solid #777', paddingLeft: '0px', paddingRight: '0px'} : {borderRadius: '0px', fontSize: '13px', color: '#333', border: '1px solid #777', paddingLeft: '0px', paddingRight: '0px'} : {borderRadius: '0px', fontSize: '13px', color: '#333', background: 'rgba(119,119,119,0.5)', border: '1px solid rgba(119,119,119, 0.7)', paddingLeft: '0px', paddingRight: '0px'}
}

// function updatePlayerCard() {DbRoutes.updatePlayerCard()}

function removeACoolOff(i: number) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Cooloff.toLowerCase() + ' (' + getCategoryName(Main.Instance.DatabaseDataState.playerCard.cooloffs[i].category) + ', ' + Assets.ValidUntil + ': ' + Main.Instance.DatabaseDataState.playerCard.cooloffs[i].validUntil + ', ' + Assets.DateCreated + ': ' + Main.Instance.DatabaseDataState.playerCard.cooloffs[i].dateCreated + ')?')) {
        const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
        playerCard.cooloffs.splice(i, 1)
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
    }
}

function getCooloffs(): JSX.Element[] {
    const cooloffs: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.playerCard.cooloffs) {
        // console.log (Main.Instance.DatabaseDataState.playerCard.cooloffs)
        cooloffs.push (
            <div key = "1" className = "clearfix mt">
                <div title = {Assets.Cooloffs} style = {Main.Instance.DatabaseDataState.playerCard.cooloffs.length === 0 ? getStyle('cooloffs') : getStyle('Cooloffs')} className = "label button-default w130px fl">
                    {Assets.Cooloffs}
                </div>
                <div className = "ml3 fl clearfix">
                    {/*<input style = {{width: '31px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.Ctg + '.'} className = "pcti fl" type = "text"/>*/}
                    {Main.Instance.DatabaseDataState.playerCard.cooloffs.length === 0 ?
                    <div className = "pcti fl" style = {{marginLeft: '5px',marginRight: '0px', width: '177px'}}>{Assets.NoCoolOffsAreDefined}.</div>
                    : <>
                    {/*<input style = {{width: '92px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.DateCreated} className = "pcti fl" type = "text"/>*/}
                    <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {true} value = {Assets.ValidUntil/* + ' (hover: ' + Assets.Created.toLowerCase() + ')'*/} className = "pcti fl" type = "text"/>
                    <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {true} value = {Assets.DateCreated} className = "pcti fl" type = "text"/>
                    <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.RemoveACoolOff} className = "pcti fl" type = "text"/>
                    </>}
                </div>
            </div>,
        )
        for (let ITEM: number = 0; ITEM < Main.Instance.DatabaseDataState.playerCard.cooloffs.length; ITEM++) {
            const item = Main.Instance.DatabaseDataState.playerCard.cooloffs[ITEM]
            // console.log (item)
        // for (const item of Main.Instance.DatabaseDataState.playerCard.cooloffs) {
            if (item.category !== 0) { continue }
            cooloffs.push (
                <div key = {Assets.AllCategories} className = "clearfix mt2">
                    <div style = {getStyle(item.category === 0 ? Assets.AllCategories : 'cooloffs')} className = "label button-default w130px fl">
                        {Assets.AllCategories}
                    </div>
                    <div className = "ml3 fl clearfix">
                        {/*<input title = {item.dateCreated + ' ' + Assets_.UTC} style = {{width: '92px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.dateCreated} className = "pcti fl" type = "text"/>*/}
                        <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.validUntil + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/>
                        <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.dateCreated + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/>
                        {hasCoolOffExpired(item.validUntil) ? <input style = {{background: '#dc3545', color: '#fff', width: '187px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.Cooloff + ' ' + Assets.Expired.toLowerCase()} className = "pcti fl" type = "text"/> :
                        <button style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px'}} onClick = {() => removeACoolOff(ITEM)} title = {!isOlderThan2Months(item.dateCreated) ? Assets.CoolOffRemovalIsNotYetAllowed : Assets.CoolOffRemovalIsAllowed} disabled = {!isOlderThan2Months(item.dateCreated)} value = {Assets.RemoveACoolOff} className = "pcti fl btn btn-warning btn-xs" type = "button">{Assets.RemoveACoolOff}</button>}
                    </div>
                </div>,
            )
        }

        for (let ITEM: number = 0; ITEM < Main.Instance.DatabaseDataState.playerCard.cooloffs.length; ITEM++) {
            const item = Main.Instance.DatabaseDataState.playerCard.cooloffs[ITEM]
        // for (const item of Main.Instance.DatabaseDataState.playerCard.cooloffs) {
            if (item.category === 0) { continue }
            cooloffs.push (
                <div key = {item.category.toString() + '_' + item.dateCreated + '_' + item.validUntil} className = "clearfix mt2">
                    <div style = {getStyle('cooloffs')} className = "label button-default w130px fl">
                        {/*console.log (Main.Instance.DatabaseDataState.scategories)*/}
                        {getCategoryName(/*Main.Instance.DatabaseDataState.scategories,*/ item.category)}
                        {/*item.category === GameCategories.LiveCasino ? 'Live casino' : item.category === GameCategories.VirtualSports ? 'Virtual sports' : GameCategories[item.category]*/}
                    </div>
                    <div className = "ml3 fl clearfix">
                        {/*<input title = {item.dateCreated + ' ' + Assets_.UTC} style = {{width: '92px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.dateCreated} className = "pcti fl" type = "text"/>*/}
                        <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.validUntil + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/>
                        <input style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px', marginRight: '3px'}} disabled = {!canBeModifiedByAdmin('cooloffs')} onChange = {(e) => changeText('cooloffs', e.target.value)} value = {item.dateCreated + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/>
                        {hasCoolOffExpired(item.validUntil) ? <input style = {{background: '#dc3545', color: '#fff', width: '187px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.Cooloff + ' ' + Assets.Expired.toLowerCase()} className = "pcti fl" type = "text"/> :
                        <button style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px'}} onClick = {() => removeACoolOff(ITEM)} title = {!isOlderThan2Months(item.dateCreated) ? Assets.CoolOffRemovalIsNotYetAllowed : Assets.CoolOffRemovalIsAllowed} disabled = {!isOlderThan2Months(item.dateCreated)} value = {Assets.RemoveACoolOff} className = "pcti fl btn btn-warning btn-xs" type = "button">{Assets.RemoveACoolOff}</button>}
                    </div>
                </div>,
            )
        }
    }
    return cooloffs
}

export function getCategoryName(categoryId: number): string {
    // console.log (Object.keys(GameCategories))
    return injectSpaces(Object.keys(GameCategories)[categoryId + Object.keys(GameCategories).length / 2])
/*    
    for (const item of Main.Instance.DatabaseDataState.scategories) {
        if (item.categoryId === categoryId) { return item.name }
    }
    return Assets.Unknown
*/
}

function hasCoolOffExpired(ValidUntil: string): boolean {
/*
    const now: moment.Moment = moment.default()
    // console.log (now)
    const validUntil: moment.Moment = moment.default(ValidUntil)
    // console.log (validUntil)
    const duration: moment.Duration = moment.duration(now.diff(validUntil))
    // console.log (duration)
    const durationInMonths: number = duration.get('months')
    const durationInYears: number = duration.get('years')
    // console.log (durationInMonths)
    if (durationInYears >= 1 || durationInMonths >= 2) return true
*/
    const now: number = (new Date()).getTime()
    const validUntil: number = (new Date(ValidUntil)).getTime()
    if (now > validUntil) { return true }
    return false
}

function isOlderThan2Months(DateCreated: string): boolean {
    const now: moment.Moment = moment.default()
    // console.log (now)
    const dateCreated: moment.Moment = moment.default(DateCreated)
    // console.log (validUntil)
    const duration: moment.Duration = moment.duration(now.diff(dateCreated))
    // console.log (duration)
    const durationInMonths: number = duration.get('months')
    const durationInYears: number = duration.get('years')
    // console.log (durationInMonths)
    if (durationInYears >= 1 || durationInMonths >= 2) { return true }
    return false
}

function getSelfAssessment(): JSX.Element[] {
    const sa: JSX.Element [] = []
    // console.log (Main.Instance.DatabaseDataState.playerCard.selfAssessment)
    for (let i: number = Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 2; i >= 0; i--) {
        // console.log (i)
        // console.log (Main.Instance.DatabaseDataState.playerCard.selfAssessment[i])
        if (Main.Instance.DatabaseDataState.playerCard.selfAssessment[i] === undefined) {
            continue
        }
        sa.push (
            <div key = {i.toString()} className = "clearfix mt2">
            <div title = {Assets.PreviousSelfAssessmentTaken} style = {getStyle('selfAssessment')} className = "label button-default w130px fl">{Assets.PreviousAssessmentShort}</div>
            <div className = "ml3 fl clearfix">
                {Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].date ?
                <input /*title = {Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].date + ' ' + Assets_.UTC}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px'/*'80px'*/, marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeText('selfAssessment', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].date + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/> : void 0}
                {Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].riskLevel ?
                <input /*title = {Assets.RiskLevel}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px'/*'60px'*/, marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeNumber('selfAssessment', e.target.value)} value = {Assets.RiskLevel + ': ' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].riskLevel]} className = {'pcti fl risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].riskLevel]}  type = "text"/> : void 0}
                {Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].totalPoints ?
                <input /*title = {Assets.TotalPoints}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px' /*'41px'*/, paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeNumber('selfAssessment', e.target.value)} value = {Assets.TotalPoints + ': ' + Main.Instance.DatabaseDataState.playerCard.selfAssessment[i].totalPoints.toFixed(1)} className = "pcti fl" type = "text"/> : void 0}
            </div>
        </div>,
        )
    }

    return sa
}

/*

function isPlayerCardChanged() {
    if (JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_) { return false }
    return true
}
function getResponsibleGamingControl(): JSX.Element {
    if (Main.Instance.DatabaseDataState.playerCard.limits === undefined || Main.Instance.DatabaseDataState.playerCard.limits.length === 0) {
        return <div/>
    }
    // console.log (Main.Instance.DatabaseDataState.playerCard.limits)
    const rgc: JSX.Element[] = []
    const Rgc: string[] = ['Net loss', 'Wager', Assets.Deposit, 'Do not filter']
    const RGC: number[] = [0, 0, 0]

    for (const item of Main.Instance.DatabaseDataState.playerCard.limits) {
        // if (item.type > 3) console.log ('this should not happen')
        RGC[item.type - 1]++
    }
    // console.log (RGC)
    // let setLimit: boolean = false
    rgc.push (<FontAwesomeIcon key = "fafilter" style = {{display: 'inline-block'}} title = "Filter by Responsible gaming limits type" icon = {faFilter}/>)
    rgc.push (<FontAwesomeIcon key = "fahandshelping" style = {{display: 'inline-block', marginRight: '10px'}} title = "Filter by Responsible gaming limits type" icon = {faHandsHelping}/>)
    for (let i: number = 0; i < Rgc.length; i++) {

        rgc.push (
            <button
                key = {i.toString()}
                title = {'Select Responsible gaming limits type filter: ' + Rgc[i]}
                disabled = {i === RGC.length || RGC[i] ? false : true}
                style = {{marginRight: i === RGC.length ? '0px' : ''}}
                onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {selectedResponsibleGamingLimit: i})}
                className = {i === Main.Instance.PlayerCardState.selectedResponsibleGamingLimit ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
            >
                {Rgc[i]}
            </button>
        )
    }

    return (
        <div style = {{border: '1px solid #bbb', padding: '2px', borderRadius: '3px', background: '#eee'}} className = "clearfix mt">
            {rgc}
        </div>
    )
}
*/
function getResponsibleGamingLimits(): JSX.Element[] {
    const rgl: JSX.Element[] = []
    // tukaj dodaj izbiro za selectedResponsibleGamingLimit
    // console.log (Main.Instance.DatabaseDataState.playerCard.limits)
    if (Main.Instance.DatabaseDataState.playerCard.limits) {
        let newLimit: boolean = false
        let changeDate: boolean = false

        for (const item of Main.Instance.DatabaseDataState.playerCard.limits) {
            /*
                        if (Main.Instance.PlayerCardState.selectedResponsibleGamingLimit < 3 && item.type !== Main.Instance.PlayerCardState.selectedResponsibleGamingLimit + 1) {
                            continue
                        }
            */
            for (const Item of item.limits) {
                if (Item.newLimit !== undefined) {
                    newLimit = true
                }

                if (Item.changeDate !== undefined) {
                    changeDate = true
                }
            }
        }

        rgl.push (
            <div key = "0" className = "clearfix mt">
                <div title = {Assets.ResponsibleGamingLimits} style = {getStyle('Limits')} className = "label button-default w130px fl">
                    {Assets.ResponsibleLimits}
                </div>
{/*
                <div className = "ml3 fl clearfix">
                    <input style = {{width: '54px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.Limit} className = "pcti fl" type = "text"/>
                    <input title = {Assets.NewLimit} style = {{width: '54px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.NewLimitShort} className = "pcti fl" type = "text"/>
                    <input title = {Assets.ChangeDate} style = {{width: '73px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.ChangeDateShort} className = "pcti fl" type = "text"/>
                </div>
*/}
                <div className = "ml3 fl clearfix">
                    <input style = {{width: '187px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.Limit} className = "pcti fl" type = "text"/>
                    {newLimit ?
                        <input title = {Assets.NewLimit} style = {{width: '187px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.NewLimit/*Short*/} className = "pcti fl" type = "text"/>
                    : void 0}
                    {changeDate ?
                        <input title = {Assets.ChangeDate} style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Assets.ChangeDate/*Short*/} className = "pcti fl" type = "text"/>
                    : void 0}

                </div>
            </div>,
        )

        for (const item of Main.Instance.DatabaseDataState.playerCard.limits) {
/*
            if (Main.Instance.PlayerCardState.selectedResponsibleGamingLimit < 3 && item.type !== Main.Instance.PlayerCardState.selectedResponsibleGamingLimit + 1) {
                continue
            }
*/
            for (const Item of item.limits) {
                rgl.push (
                    <div key = {item.type + '_' + Item.period} className = "clearfix mt2">
                        <div style = {getStyle('limits')} className = "label button-default w130px fl">{(LimitTypesNew[item.type] === 'NetLoss' ? 'Net loss' : LimitTypesNew[item.type]) + ' ' + LimitPeriods[Item.period]}</div>
                        <div className = "ml3 fl clearfix">
                            <input title = {Item.limit.toString() + ' ' + Main.Instance.DatabaseDataState.playerCard.currency} style = {{width: '149px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {!canBeModifiedByAdmin('limits')} onChange = {(e) => changeNumber('limits', e.target.value)} value = {Item.limit} className = "pcti fl" type = "text"/>
                            <input style = {{width: '35px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>

                            {Item.newLimit === undefined ? void 0 :
                            <input title = {Item.newLimit.toString() + ' ' + Main.Instance.DatabaseDataState.playerCard.currency} style = {{width: '149px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {!canBeModifiedByAdmin('limits')} onChange = {(e) => changeNumber('limits', e.target.value)} value = {Item.newLimit} className = "pcti fl" type = "text"/>}
                            {Item.newLimit === undefined ? void 0 :
                            <input style = {{width: '35px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>}

                            {Item.changeDate === undefined ? void 0 :
                            <input title = {Item.changeDate + ' ' + Assets_.UTC} style = {{fontSize: '13px', width: '187px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {!canBeModifiedByAdmin('limits')} onChange = {(e) => changeNumber('limits', e.target.value)} value = {Item.changeDate + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/>}
                        </div>
                    </div>,
                )
            }
        }
    }
    
    return rgl
}
/*
function getAge(age: string) {
    const date = new Date (age)
    if (date == 'Invalid Date') return ''
    const now: Date = new Date()
    const diff
    return age
}
viewPlayerCards
*/
function toggleAccountStatus(i: number) {
    const viewPlayerCards: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.viewPlayerCards)
    viewPlayerCards[i] = !viewPlayerCards[i]
    Logic.Type.New(Main.Instance.DatabaseDataState, {viewPlayerCards})
}

function getAccountStatus(): JSX.Element[] {
    const as: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(AccountStatus).length / 2; i++) {
        if (i === AccountStatus.PaymentsBlocked || i === AccountStatus.PendingActive) { continue }
        as.push (
            <button
                onClick = {() => toggleAccountStatus(i)}
                style = {{fontWeight: 600, color: Main.Instance.DatabaseDataState.viewPlayerCards[i] ? '#fff' : '', marginRight: i === Math.floor(Object.keys(AccountStatus).length / 2) - 1 ? '0px' : '10px'}}
                type = "button"
                className = {Main.Instance.DatabaseDataState.viewPlayerCards[i] ? 'btn btn-xs btn-default risk-' + AccountStatus[i] : 'btn btn-xs btn-default riskborder-' + AccountStatus[i]}
                key = {i.toString()}
            >
                {Assets.accountStatus[i]}
            </button>,
        )
    }
    return as
}

function download() {
    const s: string = GetListPlayersSelection()
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.Players + ' ' + Assets.UnresolvedIssues + (Main.Instance.DatabaseDataState.ShowJustAssignedToIssues ? ' ' + Assets.AssignedTo + ' ' + Main.Instance.LoginState.Username : '') + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

function changePlayerCardsPosition(left: boolean) {
    if (left) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {
            playerCardsPosition: Main.Instance.DatabaseDataState.playerCardsPosition - 1})
    } else {
        Logic.Type.New (Main.Instance.DatabaseDataState, {
            playerCardsPosition: Main.Instance.DatabaseDataState.playerCardsPosition + 1})
    }
}

/*
function toggleBonusWageringRequirement() {
    Logic.Type.New (Main.Instance.PlayerCardState, {BonusWageringRequirement: !Main.Instance.PlayerCardState.BonusWageringRequirement})
}
*/

function setBonusWageringRequirement(BonusWageringRequirement: AddBonusOptions) {
    Logic.Type.New (Main.Instance.PlayerCardState, {BonusWageringRequirement})
}

export function PlayerCard(): JSX.Element {
    // console.log (Main.Instance.PlayerCardState.gameSessions)
    DbRoutes.wsReinitialize()
    const unresolvedIssues: number[] = getUnresolvedIssues()
    // console.log (Main.Instance.RiskManagementState.history)
    // console.log (Main.Instance.DatabaseDataState.issues)
    // console.log ('here2')
/*
    console.log ('*************1')
    console.log (Main.Instance.PlayerCardState.navET)
    console.log (PlayerCardButtonsET)
    console.log ('*************2')
    console.log (Main.Instance.PlayerCardState.navETT)
    console.log (PlayerCardButtonsETT)
    console.log ('*************3')
    console.log (Main.Instance.PlayerCardState.navTR)
    console.log (PlayerCardButtonsTR)
    console.log ('*************4')
*/
    const filtered =
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ?
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Everything ? [] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllTransactions ? // ETT START
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.AllDeposits ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'd'}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.AllWithdrawals ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'w'}] :

                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.Deposits ? 
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT /*TransactionType[TransactionType.DEPOSIT]*/}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.Withdrawals ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW /*TransactionType[TransactionType.WITHDRAW]*/}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.ManualDeposits ? 
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_MANUAL /*value: TransactionType[TransactionType.DEPOSIT_MANUAL]*/}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.ManualWithdrawals ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_MANUAL /*TransactionType[TransactionType.WITHDRAW_MANUAL]*/}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.CanceledWithdrawals ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :
                Main.Instance.PlayerCardState.navETT === PlayerCardButtonsETT.CanceledDeposits ?
                    [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :

                [{id: 'entryType', value: TimelineEntryType.Transaction}] :

            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllDeposits ? // ET START
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'd'}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'w'}] :

            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Deposits ? 
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT /*TransactionType[TransactionType.DEPOSIT]*/}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Withdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW /*TransactionType[TransactionType.WITHDRAW]*/}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.ManualDeposits ? 
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_MANUAL /*TransactionType[TransactionType.DEPOSIT_MANUAL]*/}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.ManualWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_MANUAL /*TransactionType[TransactionType.WITHDRAW_MANUAL]*/}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.CanceledWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.CanceledDeposits ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :

            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllIssues ?
                [{id: 'entryType', value: TimelineEntryType.Issue}] :
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.RiskManagement ?
                Main.Instance.PlayerCardState.navRM === PlayerCardButtonsRM.AllIssues ?
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.RiskManagement}] :
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.RiskManagement},
                    {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :
            
            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.ResponsibleGaming ?
                Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.AllIssues ?
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.ResponsibleGaming}] :
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.ResponsibleGaming},
                    {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :

            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.KnowYourCustomer ?
                Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.AllIssues ?
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.KnowYourCustomer}] :
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.KnowYourCustomer},
                    {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :

            Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Gamification ?
                Main.Instance.PlayerCardState.navGM === PlayerCardButtonsGM.AllIssues ?
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.Gamification}] :
                    [{id: 'entryType', value: TimelineEntryType.Issue},
                    {id: 'entrySubType', value: TimelineEntrySubType.Gamification},
                    {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :
        [] :

        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions ?
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.AllTransactions ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}] :

            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.AllDeposits ? // TR START
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'd'}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.AllWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactiontype_', value: 'w'}] :

            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.Deposits ? 
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT /*TransactionType[TransactionType.DEPOSIT]*/}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.Withdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW /*TransactionType[TransactionType.WITHDRAW]*/}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.ManualDeposits ? 
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_MANUAL /*TransactionType[TransactionType.DEPOSIT_MANUAL]*/}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.ManualWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_MANUAL /*TransactionType[TransactionType.WITHDRAW_MANUAL]*/}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.CanceledWithdrawals ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.WITHDRAW_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :
            Main.Instance.PlayerCardState.navTR === PlayerCardButtonsTR.CanceledDeposits ?
                [{id: 'entryType', value: TimelineEntryType.Transaction}, {id: 'transactionType', value: TransactionType.DEPOSIT_CANCEL /*TransactionType[TransactionType.WITHDRAW_CANCEL]*/}] :

            []
            :
/*
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory ?
            Main.Instance.PlayerCardState.navPS === PlayerCardButtonsPS.AllSessions ?
                [{id: 'entryType', value: TimelineEntryType.Session}] :
            Main.Instance.PlayerCardState.navPS === PlayerCardButtonsPS.Closed ?
                [{id: 'entryType', value: TimelineEntryType.Session}, {id: 'entrySubType', value: TimelineEntrySubType.ClosedSession}] :
            Main.Instance.PlayerCardState.navPS === PlayerCardButtonsPS.Open ?
                [{id: 'entryType', value: TimelineEntryType.Session}, {id: 'entrySubType', value: TimelineEntrySubType.OpenSession}] :
            [] :
*/
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.RiskManagement ?
            Main.Instance.PlayerCardState.navRM === PlayerCardButtonsRM.AllIssues ?
                [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'entrySubType', value: TimelineEntrySubType.RiskManagement}] :
            [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.ResponsibleGaming ?
            Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.AllIssues ?
                [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'entrySubType', value: TimelineEntrySubType.ResponsibleGaming}] :
            [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.KnowYourCustomer ?
            Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.AllIssues ?
                [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'entrySubType', value: TimelineEntrySubType.KnowYourCustomer}] :
            [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}] :
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Gamification ?
            Main.Instance.PlayerCardState.navGM === PlayerCardButtonsGM.AllIssues ?
                [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'entrySubType', value: TimelineEntrySubType.Gamification}] :
            [{id: 'entryType', value: TimelineEntryType.Issue}, {id: 'issueType', value: Main.Instance.PlayerCardState.navIssue}]
        : []
    // console.log (filtered)
    // console.log ('*************5')
/*
    console.log (filtered)
    console.log ('TR')
    console.log (Main.Instance.PlayerCardState.navTR)
    console.log ('ET')
    console.log (Main.Instance.PlayerCardState.navET)
    console.log ('ETT')
    console.log (Main.Instance.PlayerCardState.navETT)
    console.log ('nav')
    console.log (Main.Instance.PlayerCardState.nav)
    console.log ('RM')
    console.log (Main.Instance.PlayerCardState.navRM)
    console.log ('RG')
    console.log (Main.Instance.PlayerCardState.navRG)
    console.log ('KYC')
    console.log (Main.Instance.PlayerCardState.navKYC)
    console.log ('GM')
    console.log (Main.Instance.PlayerCardState.navGM)
*/
    return (
        <div>
            {Main.Instance.RiskManagementState.history ? <History/> : void 0}
            {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/> : void 0}
            {Main.Instance.PlayerCardState.showSearchSettings === false && Main.Instance.PlayerCardState.forcePlayerCardSelect/* || Main.Instance.PlayerCardState.id === '0' */?
            <select 
                // size = {Main.Instance.PlayerCardState.search_string && Main.Instance.PlayerCardState.search_string.length > 0 ? 5 : 0}
                // disabled = {Main.Instance.DatabaseDataState.playerCardSelectionDisabled}
                value = {Main.Instance.PlayerCardState.id}
                // with position=absolute => Main.Instance.MenuState.Open ? '333px' : '129px'
                style = {{overflow: Main.Instance.PlayerCardState.search_string && Main.Instance.PlayerCardState.search_string.length > 0 ? 'auto' : '', position: 'fixed', left: '369px', top: '41px', /*height: '24px',*/ fontSize: '16px', width: '350px', zIndex: 9998/*, marginRight: '10px'*/}}
                // className = "mt20"
                onChange = {(e) => Main.Instance.PlayerCardState.id || e.target.value !== Main.Instance.DatabaseDataState.optionsTopPlayerId[0] || Main.Instance.DatabaseDataState.optionsTopPlayerId.length === 0 && e.target.value !== Main.Instance.DatabaseDataState.optionsBottomPlayerId[0] ? SelectPlayer(e.target.value, Main.Instance.PlayerCardState.nav) : Clicked(e.target.value)}
                onClick = {() => Main.Instance.DatabaseDataState.playerCard.playerId ? {} : Clicked()}
            >
                {getOptionsPlayersSelection()}
            </select> : void 0}
            {/*Main.Instance.PlayerCardState.onlyPlayerId === true ? void 0 :
            <div className = "mb3 mt-5">
                <FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;
                <select
                    // disabled = {Main.Instance.DatabaseDataState.playerCardSelectionDisabled}
                    value = {Main.Instance.PlayerCardState.id}
                    style = {{height: '24px', fontSize: '16px', marginRight: '10px'}}
                    // className = "mt20"
                    onChange = {(e) => e.target.value !== '0' ? SelectPlayer(e.target.value, Main.Instance.PlayerCardState.nav, true) : void 0}
                >
                    {getOptionsPlayersSelection()}
                </select>

                {Main.Instance.PlayerCardState.id === '0' ? void 0 :
                <button title = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? 'Force Player card refresh - no changes to save' : 'Force Player card refresh - there are some changes that need to be saved'} onClick = {() => DbRoutes.wsGetPlayerCard(Main.Instance.PlayerCardState.id)} style = {{paddingTop: '5px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px', lineHeight: 1.2, marginTop: '-4px'}} className = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? 'btn btn-primary btn-sm' : 'btn btn-warning btn-sm'}><FontAwesomeIcon icon = {faSyncAlt}/></button>}
                {Main.Instance.PlayerCardState.id === '0' ? void 0 : <button title = "Hide this row" onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {onlyPlayerId: true})} style = {{paddingTop: '5px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px', lineHeight: 1.2, marginTop: '-4px'}} className = "btn btn-success btn-sm mr0"><FontAwesomeIcon icon = {faEyeSlash}/></button>}
            </div>*/}
            {Main.Instance.PlayerCardState.id === '0' || Main.Instance.DatabaseDataState.playerCard === undefined ?
            <div>
                <h2 className = "pr">{Assets.p4}
                    {Main.Instance.DatabaseDataState.unresolvedIssuesNumber !== undefined && Main.Instance.DatabaseDataState.unresolvedIssuesPlayers !== undefined ?
                    <>
                    &nbsp;<FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faUserAlt}/>&nbsp;
                    <span title = {Main.Instance.DatabaseDataState.unresolvedIssuesPlayers + ' ' + Assets.players_}>{Main.Instance.DatabaseDataState.unresolvedIssuesPlayers}</span>
                    <span>,</span>&nbsp;<FontAwesomeIcon style = {{fontSize: '20px'}} icon = {faCheckSquare}/>&nbsp;
                    <span title = {Main.Instance.DatabaseDataState.unresolvedIssuesNumber + ' ' + Assets.issues_}>{Main.Instance.DatabaseDataState.unresolvedIssuesNumber}</span>&nbsp;
                    {/*<button style = {{fontSize: '16px', padding: '1px 5px'}} title = {Main.Instance.DatabaseDataState.ShowJustAssignedToIssues ? Assets.ShowAllIssues : Assets.ShowJustIssuesThatAreAssignedToYou} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {ShowJustAssignedToIssues: !Main.Instance.DatabaseDataState.ShowJustAssignedToIssues})} type = "button" className = {Main.Instance.DatabaseDataState.ShowJustAssignedToIssues ? 'btn btn-success btn-sm mr' : 'btn btn-danger btn-sm mr'}><FontAwesomeIcon icon = {faUserAlt}/></button>*/}
                    <button title = {Assets.Download + ' ' + Assets.CSV} style = {{fontSize: '16px', padding: '1px 5px'}} onClick = {() => download()} type = "button" className = "btn btn-default btn-sm mr0"><FontAwesomeIcon icon = {faFileCsv}/></button>

                    <span style = {{marginLeft: '10px'/*position:'absolute', top: '0px', left: '735px'*/}}>
                        {getAccountStatus()}
                    </span>
                    <button
                        disabled = {Main.Instance.DatabaseDataState.playerCardsPosition === 0}
                        onClick = {() => changePlayerCardsPosition(true)}
                        className = "btn btn-default btn-xs mr0"
                    >
                        <FontAwesomeIcon icon = {faArrowCircleLeft}/>
                    </button>
                    <button
                        style = {{marginLeft: '3px'}}
                        disabled = {Main.Instance.DatabaseDataState.playerCardsPosition > (Main.Instance.DatabaseDataState.playerCards ? Main.Instance.DatabaseDataState.playerCards.length : 0) / Main.Instance.DatabaseDataState.playerCardsSize - 1}
                        onClick = {() => changePlayerCardsPosition(false)}
                        className = "btn btn-default btn-xs mr0"
                    >
                        <FontAwesomeIcon icon = {faArrowCircleRight}/>
                    </button>
                    </> : void 0}
                </h2>
                {getListPlayersSelection()}
            </div> : void 0}
            {/*console.log (Main.Instance.DatabaseDataState.playerCards)*/}
            {Main.Instance.PlayerCardState.id !== '0' && Main.Instance.DatabaseDataState.playerCard && Main.Instance.DatabaseDataState.playerCard.playerId ?
            <div className = "player-card">
                <div className = "clearfix" style = {{height: '100%', position: 'relative'}}>
                    {!Main.Instance.PlayerCardState.hiddenPlayerCard ?
                    <div className = "fl player-card-left">
                        <div>
                            <>
                                {threeStatuses()}
                                <div style = {{padding: '0px', marginTop: '3px'}} title = {Assets.Player_ + ' ' + Assets.Username.toLowerCase()} className = "label button-default label-title pr">
                                    {button8()}
                                    &nbsp;
                                    {Main.Instance.DatabaseDataState.playerCard.username ? Main.Instance.DatabaseDataState.playerCard.username : Assets.Player_ + ' ' + Assets.Username.toLowerCase()}
                                    &nbsp;
                                    {isUsernameChanged() && Main.Instance.DatabaseDataState.playerCard.usernameNew !== undefined ? Button8() : 
                                    <button
                                        title = {Assets.Copy + ' ' + Assets.Username} 
                                        className = "btn btn-default btn-sm mr0"
                                        type = "button" 
                                        onClick = {() => copyStringToClipboard(Main.Instance.DatabaseDataState.playerCard.username)}
                                        style = {{fontSize: '14px', background: 'transparent', padding: '0px', border: '0px'}}
                                    >
                                        <FontAwesomeIcon icon = {faCopy}/>
                                    </button>}
                                    {/*
                                    <div 
                                        className = "btn btn-default btn-xs"
                                        style = {{position: 'absolute', top: '4px', right: '0px'}}
                                        onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {expandedPlayerCard: !Main.Instance.PlayerCardState.expandedPlayerCard})}
                                    >
                                        {Main.Instance.PlayerCardState.expandedPlayerCard ? Assets.ShowLess : Assets.ShowMore}
                                    </div>
                                    */}
                                    <button
                                        title = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? Assets.f1 : Assets.f2}
                                        onClick = {() => {
                                            DbRoutes.wsGetPlayerCard(Main.Instance.PlayerCardState.id, true)
                                            DbRoutes.getBonusInfo(Main.Instance.PlayerCardState.id)
                                        }}
                                        style = {{position: 'absolute', top: '-2px', right: '0px'}}
                                        className = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? 'btn btn-default btn-xs mr0' : 'btn btn-warning btn-xs mr0'}
                                    >
                                        <FontAwesomeIcon icon = {faSyncAlt}/>{/*&nbsp;*/}{/*Assets.Force + ' ' + Assets.Refresh.toLowerCase()*/}
                                    </button>
                                    {Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 :
                                    <button
                                        title = {Assets.MoveOnTop}
                                        className = "btn btn-default btn-xs mr0"
                                        style = {{position: 'absolute', top: '-2px', left: '-1px'}}
                                        onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {hiddenPlayerCard: !Main.Instance.PlayerCardState.hiddenPlayerCard})}
                                    >
                                        <FontAwesomeIcon icon = {faArrowCircleUp}/>
                                    </button>}
                                </div>
                                
                                {Main.Instance.DatabaseDataState.playerCard.usernameNew !== undefined ?
                                <div key = "2" className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('usernameNew', Main.Instance.DatabaseDataState.playerCard.username)} title = {Assets.Copy + ' ' + Assets.Username} style = {getStyle('usernameNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.Username.toLowerCase()}</div>
                                    <div className = "ml3 fl">
                                        <input placeholder = {Assets.pUsername} disabled = {!canBeModifiedByAdmin('usernameNew')} onChange = {(e) => changeText('usernameNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.usernameNew} className = "pcti" type = "text"/>
                                        {Main.Instance.DatabaseDataState.playerCard.username !== Main.Instance.DatabaseDataState.playerCard.usernameNew && Main.Instance.DatabaseDataState.usernameAlreadyUsed ?
                                        <button style = {{marginRight: '0px', padding: '0px', color: 'rgb(217, 83, 79)', fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '1px', right: '5px'}}>
                                            <FontAwesomeIcon icon = {faUserCircle}/>
                                        </button>
                                        :
                                        <button style = {{marginRight: '0px', padding: '0px', color: Main.Instance.DatabaseDataState.usernameNewValid ? 'rgb(92, 184, 92)' : 'rgb(217, 83, 79)', fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '1px', right: '5px'}}>
                                            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.usernameNewValid ? faCheck : faTimes}/>
                                        </button>}
                                    </div>
                                </div> : null}
                                {getImportantInformation(0)}
                                {getImportantInformation(2)}
                                {/*console.log (Main.Instance.DatabaseDataState)*/}
                                {/*!Main.Instance.PlayerCardState.hiddenPlayerCard && Main.Instance.PlayerCardState.BonusAdd ?
                                <div className = "pr clearfix">
                                    <button type = "button" onClick = {() => toggleBonusWageringRequirement()} className = {Main.Instance.PlayerCardState.BonusWageringRequirement ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                                        <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.Amount} icon = {faMoneyBillWave}/>
                                    </button>
                                    <button type = "button" onClick = {() => toggleBonusWageringRequirement()} className = {!Main.Instance.PlayerCardState.BonusWageringRequirement ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                                        <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.BonusCode} icon = {faCode}/>
                                    </button>
                                    {Main.Instance.PlayerCardState.BonusWageringRequirement ? 
                                    <input
                                        className = "fl dbl"
                                        min = {0}
                                        style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                                        type = "number"
                                        value = {Main.Instance.PlayerCardState.BonusAmount}
                                        onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusAmount: parseInt(e.target.value)})}
                                    />
                                    :
                                    <input
                                        className = "fl dbl"
                                        style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                                        type = "text"
                                        value = {Main.Instance.PlayerCardState.BonusCode}
                                        onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusCode: e.target.value})}
                                    />
                                    }
                                    <button
                                        disabled = {Main.Instance.PlayerCardState.BonusWageringRequirement && Main.Instance.PlayerCardState.BonusAmount <= 0 || !Main.Instance.PlayerCardState.BonusWageringRequirement && Main.Instance.PlayerCardState.BonusCode.length === 0}
                                        type = "button"
                                        onClick = {() => Main.Instance.PlayerCardState.BonusWageringRequirement ? DbRoutes.addBonusManual(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusAmount) : DbRoutes.addBonusManualCode(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusCode)}
                                        className = "btn btn-xs btn-primary btn-xs br0 mr0 fl dbl"
                                    >
                                        {Assets.InsertANewBonus}
                                    </button>

                                </div>
                                : void 0}
                                {!Main.Instance.PlayerCardState.hiddenPlayerCard && Main.Instance.PlayerCardState.BonusInfo ?
                                Main.Instance.PlayerCardState.bonusInfo.length === 0 ?
                                <div className = "mt2 pr" style = {{textAlign: 'center', fontWeight: 600}}>
                                    {Assets.ThereAreNoBonusesForThisPlayer}
                                </div>
                                :
                                getBonusInfo()
                                : void 0*/}

                                {isUserGDPR() ?
                                <>
                                <div title = {Assets.BeneficiaryName} className = "clearfix mt2 pr">
                                    <div style = {getStyle('beneficiaryName')} className = "label button-default w60px fl">{Assets.BeneficiaryAbbrev + '.' + Assets.Name.toUpperCase()}</div>
                                    <div className = "ml3 fl">
                                        <input style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('beneficiaryName')} onChange = {(e) => changeText('beneficiaryName', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.beneficiaryName} className = "pcti" type = "text"/>
                                        {buttonCopyToClipboard(Assets.BeneficiaryName, 'beneficiaryName', 0)}
                                    </div>
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('beneficiaryNameNew', Main.Instance.DatabaseDataState.playerCard.beneficiaryName)} title = {Assets.Copy + ' ' + Assets.BeneficiaryName} style = {getStyle('beneficiaryNameNew')} className = "label button-default w60px fl">{Assets.New + ' ' + Assets.BeneficiaryNameAbbrev.toLowerCase() + '.'}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewBeneficiaryName} style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('beneficiaryNameNew')} onChange = {(e) => changeText('beneficiaryNameNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}

                                <div title = {Assets.IBANExplained} className = "clearfix mt2 pr">
                                    <div style = {getStyle('iban')} className = "label button-default w60px fl">{Assets_.IBAN}</div>
                                    <div className = "ml3 fl pr">
                                        <input style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('iban')} onChange = {(e) => changeText('iban', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.iban} className = "pcti" type = "text"/>
                                        {buttonCopyToClipboard(Assets_.IBAN, 'iban', 0)}
                                    </div>
                                    {/*button7()*/}
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.ibanNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('ibanNew', Main.Instance.DatabaseDataState.playerCard.iban)} title = {Assets.Copy + ' ' + Assets_.IBAN} style = {getStyle('ibanNew')} className = "label button-default w60px fl">{Assets.New + Assets_.IBAN.toLowerCase()}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewIban} style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('ibanNew')} onChange = {(e) => changeText('ibanNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.ibanNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}

                                <div title = {Assets.BICExplained} className = "clearfix mt2 pr">
                                    <div style = {getStyle('bic')} className = "label button-default w60px fl">{Assets_.BIC}</div>
                                    <div title = {Assets.ThisFieldIsOptionalDesc} className = "ml3 fl">
                                        <input placeholder = {Main.Instance.DatabaseDataState.playerCard.iban && Main.Instance.DatabaseDataState.playerCard.iban.length > 4 ? bfi.getBIC((Main.Instance.DatabaseDataState.playerCard.iban).replace(/ /g,'')) : Assets.ThisFieldIsOptional} style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('bic')} onChange = {(e) => changeText('bic', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.bic} className = "pcti" type = "text"/>
                                        <button 
                                            title = {Assets.Copy + ' ' + Assets_.BIC} 
                                            className = "btn btn-default btn-sm mr0"
                                            type = "button" 
                                            onClick = {() => Main.Instance.DatabaseDataState.playerCard.bic ? copyStringToClipboard(Main.Instance.DatabaseDataState.playerCard.bic) : copyStringToClipboard(Main.Instance.DatabaseDataState.playerCard.iban.length > 4 ? bfi.getBIC((Main.Instance.DatabaseDataState.playerCard.iban).replace(/ /g,'')) : '')}
                                            style = {{fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '-4px', right: '-6px'}}
                                        >
                                            <FontAwesomeIcon icon = {faCopy}/>
                                        </button>
                                        {/* buttonCopyToClipboard(Assets_.BIC, 'bic', 0)*/ }
                                    </div>
                                    {button5()}
                                    {isBankingDetailsChanged() && Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew !== undefined ? Button5() : void 0}
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('bicNew', Main.Instance.DatabaseDataState.playerCard.bic)} title = {Assets.Copy + ' ' + Assets_.BIC} style = {getStyle('bicNew')} className = "label button-default w60px fl">{Assets.New + ' ' + Assets_.BIC.toLowerCase()}</div>
                                    <div className = "ml3 fl">
                                        <input placeholder = {Main.Instance.DatabaseDataState.playerCard.ibanNew && Main.Instance.DatabaseDataState.playerCard.ibanNew.length > 4 ? bfi.getBIC((Main.Instance.DatabaseDataState.playerCard.ibanNew).replace(/ /g,'')) : Assets.PasteNewBic} /*placeholder = {Assets.PasteNewBic}*/ style = {{width: '257px'}} disabled = {!canBeModifiedByAdmin('bicNew')} onChange = {(e) => changeText('bicNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.bicNew} className = "pcti" type = "text"/>
                                    </div>
                                </div> : void 0}
                                </> : void 0}
                                <div className = "clearfix mt2">
                                    <button style = {{width: '43%', padding: '0px 10px'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} className = {Main.Instance.DatabaseDataState.manualWithdrawal ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleManualWithdrawal()}>
                                        {Assets.Manual + ' ' + Assets.Withdrawal.toLowerCase()}
                                    </button>
                                    <button style = {{marginTop: '0px', marginLeft: '1%', width: '36%', padding: '0px 10px'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} className = {Main.Instance.DatabaseDataState.manualDeposit ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleManualDeposit()}>
                                        {Assets.Manual + ' ' + Assets.Deposit.toLowerCase()}
                                    </button>
                                    <button style = {{marginTop: '0px', marginLeft: '1%', width: '19%', padding: '0px 10px'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} className = {Main.Instance.DatabaseDataState.manualBonus ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleManualBonus()}>
                                        {Assets.Bonus}
                                    </button>
                                </div>
                                <div className = "clearfix mt2">
                                    <button style = {{width: '38%',marginTop: '0px', /*marginLeft: '2%', width: '49%',*/ padding: '0px 10px'}} className = {Main.Instance.DatabaseDataState.insertANewIssue ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleInsertNewIssue()}>
                                        {Assets.InsertANewIssue}
                                    </button>

                                    <button style = {{width: '29%',marginTop: '0px', marginLeft: '1%', padding: '0px 10px'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} className = {Main.Instance.DatabaseDataState.checkVETO ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleCheckVETO()}>
                                        {Assets.CheckVETO}
                                    </button>
                                    {/*disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())}*/}
                                    <button style = {{width: '31%', marginTop: '0px', marginLeft: '1%', padding: '0px 10px'}} className = {Main.Instance.DatabaseDataState.showUploads ? 'btn btn-warning btn-sm btn-block dbl fl mr0' : 'btn btn-default btn-dark btn-sm btn-block dbl fl mr0'} onClick = {() => toggleShowUploads()}>
                                        {Assets.ShowUploads}
                                    </button>
                                </div>

                                {isUserGDPR() ?
                                <>
                                {getImportantInformation(3, true)}
                                <div className = "clearfix mt2">
                                    <div className = "fl" style = {{width: '268px', background: Main.Instance.DatabaseDataState.playerCard.isPEP ? '#d9534f' : '#5cb85c', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                                        {Main.Instance.DatabaseDataState.playerCard.isPEP ? Assets.PoliticallyExposedPerson : Assets.NotAPoliticallyExposedPerson}
                                    </div>
                                    <button style = {{marginLeft: '4px'}} onClick = {() => verifyCrifManually(true)} title = {Assets.Check + Assets.ifPoliticallyExposedPerson} className = {Main.Instance.DatabaseDataState.manualCrif ? 'btn btn-warning btn-dark btn-xs fl dbl mr0' : 'btn btn-default btn-dark btn-xs fl dbl mr0'}>{Assets.Check}</button>
                                </div>

                                {getImportantInformation(4, true)}
                                {Main.Instance.DatabaseDataState.playerCard.emailNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('emailNew', Main.Instance.DatabaseDataState.playerCard.email)} title = {Assets.Copy + ' ' + Assets.Email} style = {getStyle('emailNew')} className = "label button-default w80px fl">{Assets.New + ' ' + Assets.Email.toLowerCase()}</div>
                                    <div className = "ml3 fl pr">
                                        <input placeholder = {Assets.PasteNewEmail} style = {{width: '237px'}} disabled = {!canBeModifiedByAdmin('emailNew')} onChange = {(e) => changeText('emailNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.emailNew} className = "pcti" type = "text"/>
                                        {Main.Instance.DatabaseDataState.playerCard.email !== Main.Instance.DatabaseDataState.playerCard.emailNew && Main.Instance.DatabaseDataState.emailAlreadyUsed ?
                                        <button style = {{marginRight: '0px', padding: '0px', color: 'rgb(217, 83, 79)', fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '1px', right: '5px'}}>
                                            <FontAwesomeIcon icon = {faAt}/>
                                        </button>
                                        :
                                        <button style = {{marginRight: '0px', padding: '0px', color: Main.Instance.DatabaseDataState.emailNewValid ? 'rgb(92, 184, 92)' : 'rgb(217, 83, 79)', fontSize: '14px', background: 'transparent', border: '0px', position: 'absolute', top: '1px', right: '5px'}}>
                                            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.emailNewValid ? faCheck : faTimes}/>
                                        </button>}
                                    </div>
                                </div> : void 0}

                                {/*Main.Instance.DatabaseDataState.playerCard.isEmailVerified ? void 0 :*/}
                                {/*
                                <div className = "clearfix mt2">
                                    <button style = {{padding: '0px 10px'}} className = {Main.Instance.DatabaseDataState.resendVerificationEmail ? 'btn btn-warning btn-sm btn-block' : 'btn btn-default btn-dark btn-sm btn-block'} onClick = {() => toggleResendVerificationEmail()}>
                                        {Assets.ResendVerificationEmail}
                                    </button>
                                </div>
                                */}
                                {/*Main.Instance.PlayerCardState.expandedPlayerCard ?*/}
                                <>
                                <div className = "clearfix mt2 pr">
                                    <div style = {getStyle('phoneNumber')} className = "label button-default w130px fl">{Assets.MobilePhone}</div>
                                    <div className = "ml3 fl pr">
                                        <input disabled = {!canBeModifiedByAdmin('phoneNumber')} onChange = {(e) => changeText('phoneNumber', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.phoneNumber} className = "pcti" type = "text"/>
                                        {buttonCopyToClipboard(Assets.MobilePhone, 'phoneNumber', 0)}
                                    </div>
                                    {!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.isMobileVerified, 'activity-image3', {position: 'absolute', top: '7px', left: '112px'}, Assets.PhoneNumberVerified)}
                                    {button4()}
                                    {isPhoneNumberChanged() && Main.Instance.DatabaseDataState.playerCard.phoneNumberNew !== undefined ? Button4() : void 0}
                                </div>
                                {Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : 
                                <div
                                    style = {{background: Main.Instance.DatabaseDataState.playerCard.isMobileVerified ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}
                                    className = "clearfix mt2 pr"
                                >
                                    {Main.Instance.DatabaseDataState.playerCard.isMobileVerified ? Assets.PhoneNumberVerified : Assets.PhoneNumberNotVerified}
                                </div>}
                                {Main.Instance.DatabaseDataState.playerCard.phoneNumberNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('phoneNumberNew', Main.Instance.DatabaseDataState.playerCard.phoneNumber)} title = {Assets.Copy + ' ' + Assets.MobilePhone} style = {getStyle('phoneNumberNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.MobilePhone.toLowerCase()}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewMobilePhone} disabled = {!canBeModifiedByAdmin('phoneNumberNew')} onChange = {(e) => changeText('phoneNumberNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.phoneNumberNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}

                                <div className = "clearfix mt pr">
                                    <div title = {Assets.Address + ' & ' + Assets.StreetNumber} style = {getStyle('address')} className = "label button-default w130px fl">{Assets.Address + ' & ' + Assets.StreetNumberAbbrev}</div>
                                    <div className = "ml3 fl">
                                        <input title = {Assets.Address} style = {{width: '150px'}} disabled = {!canBeModifiedByAdmin('address')} onChange = {(e) => changeText('address', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.address} className = "pcti" type = "text"/>
                                    </div>
                                    <div className = "ml3 fl">
                                        <input title = {Assets.StreetNumber} style = {{width: '34px'}} disabled = {!canBeModifiedByAdmin('streetNumber')} onChange = {(e) => changeText('streetNumber', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.streetNumber} className = "pcti" type = "text"/>
                                    </div>
                                    {button1()}
                                    {isAddressChanged() && Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ? Button1() : void 0}
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => {changeText('addressNew', Main.Instance.DatabaseDataState.playerCard.address);changeText('streetNumberNew', Main.Instance.DatabaseDataState.playerCard.streetNumber)}} title = {Assets.Copy + ' ' + Assets.Address + Assets._and_ + Assets.StreetNumber} style = {getStyle('addressNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.Address.toLowerCase()}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewAddress} style = {{width: '150px'}} disabled = {!canBeModifiedByAdmin('addressNew')} onChange = {(e) => changeText('addressNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.addressNew} className = "pcti" type = "text"/></div>
                                    <div className = "ml3 fl"><input title = {Assets.PasteNewStreetNumber} style = {{width: '34px'}} disabled = {!canBeModifiedByAdmin('streetNumberNew')} onChange = {(e) => changeText('streetNumberNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.streetNumberNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}
                                <div className = "clearfix mt2">
                                    <div className = "fl" style = {{width: /*Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? '' :*/ '268px', background: Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                                        {Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? Assets.AddressVerified : Assets.AddressNotVerified}
                                    </div>
                                    {/*Main.Instance.DatabaseDataState.playerCard.isAddressVerified ? void 0 :*/}
                                    <button style = {{marginLeft: '4px'}} onClick = {() => verifyCrifManually(false)} title = {Assets.Check + ' ' + Assets.Correct.toLowerCase() + ' ' + Assets.Address.toLowerCase() + ' ' + Assets.Manually.toLowerCase()} className = {Main.Instance.DatabaseDataState.manualCrif ? 'btn btn-warning btn-dark btn-xs fl dbl mr0' : 'btn btn-default btn-dark btn-xs fl dbl mr0'}>{Assets.Check}</button>
                                </div>
                                <div className = "clearfix mt2 pr">
                                    <div style = {getStyle('city')} className = "label button-default w130px fl">{Assets.City}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('city')} onChange = {(e) => changeText('city', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.city} className = "pcti" type = "text"/></div>
                                    {button1()}
                                    {isAddressChanged() && Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ? Button1() : void 0}
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('cityNew', Main.Instance.DatabaseDataState.playerCard.city)} title = {Assets.Copy + ' ' + Assets.City} style = {getStyle('cityNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.City.toLowerCase()}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewCity} disabled = {!canBeModifiedByAdmin('cityNew')} onChange = {(e) => changeText('cityNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.cityNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}

                                <div className = "clearfix mt2 pr">
                                    <div style = {getStyle('postalCode')} className = "label button-default w130px fl">{Assets.PostalCode}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('postalCode')} onChange = {(e) => changeText('postalCode', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.postalCode} className = "pcti" type = "text"/></div>
                                    {button1()}
                                    {isAddressChanged() && Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ? Button1() : void 0}
                                </div>

                                {Main.Instance.DatabaseDataState.playerCard.postalCodeNew !== undefined ?
                                <div className = "clearfix mt2 pr">
                                    <div onClick = {() => changeText('postalCodeNew', Main.Instance.DatabaseDataState.playerCard.postalCode)} title = {Assets.Copy + ' ' + Assets.PostalCode} style = {getStyle('postalCodeNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.PostalCode.toLowerCase()}</div>
                                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewPostalCode} disabled = {!canBeModifiedByAdmin('postalCodeNew')} onChange = {(e) => changeText('postalCodeNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.postalCodeNew} className = "pcti" type = "text"/></div>
                                </div> : void 0}

                                <div className = "clearfix mt2">
                                    <div style = {getStyle('country')} className = "label button-default w130px fl">{Assets.Country}</div>
                                    <div className = "ml3 fl">
                                        <select title = {canBeModifiedByAdmin('country') ? '' : Assets.p3} value = {Main.Instance.DatabaseDataState.playerCard.country ? Main.Instance.DatabaseDataState.playerCard.country.toUpperCase() : '0'} style = {{width: '187px', height: '22px', paddingLeft: '1px', background: canBeModifiedByAdmin('country') ? '#fff' : ''}} disabled = {!canBeModifiedByAdmin('country')} onChange = {(e) => changeText('country', e.target.value)}>
                                            {getOptions(1, true)}
                                        </select>
                                    </div>
                                </div>
{/*
                                <div className = "clearfix mt">
                                    <div style = {getStyle('nationality')} className = "label button-default w130px fl">{Assets.Nationality}</div>
                                    <div className = "ml3 fl">
                                        <select title = {canBeModifiedByAdmin('nationality') ? '' : 'Nationality of the player cannot be changed.'} value = {Main.Instance.DatabaseDataState.playerCard.nationality ? Main.Instance.DatabaseDataState.playerCard.nationality.toUpperCase() : '0'} style = {{width: '187px', height: '22px', paddingLeft: '1px', background: canBeModifiedByAdmin('nationality') ? '#fff' : ''}} disabled = {!canBeModifiedByAdmin('nationality')} onChange = {(e) => changeText('nationality', e.target.value)}>
                                            {getOptions(2, true)}
                                        </select>
                                    </div>
                                </div>

                                <div className = "clearfix mt pr">
                                    <div style = {getStyle('phoneNumber')} className = "label button-default w130px fl">{Assets.MobilePhone}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('phoneNumber')} onChange = {(e) => changeText('phoneNumber', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.phoneNumber} className = "pcti" type = "text"/></div>
                                    {!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.isMobileVerified, 'activity-image3', {position: 'absolute', top: '7px', left: '112px'}, Assets.PhoneNumberVerified)}
                                </div>
*/}
                                </>{/* : void 0*/}
                                </> : void 0}
                                </>

                                {/*Main.Instance.PlayerCardState.expandedPlayerCard ?
                                <>
                                <div className = "clearfix mt">
                                    <div style = {getStyle('documentType', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentType}</div>
                                    <div className = "ml3 fl"><select disabled = {!canBeModifiedByAdmin('documentType', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentType', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentType} className = "pcti">{getOptionsKYC('identification')}</select></div>
                                </div>

                                <div className = "clearfix mt2">
                                    <div style = {getStyle('issuingAuthority', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.IssuingAuthority}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('issuingAuthority', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('issuingAuthority', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.issuingAuthority} className = "pcti" type = "text"/></div>
                                </div>

                                <div className = "clearfix mt2">
                                    <div style = {getStyle('documentNumber', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentNumber}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('documentNumber', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentNumber', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentNumber} className = "pcti" type = "text"/></div>
                                </div>

                                <div className = "clearfix mt2">
                                    <div style = {getStyle('documentExpiry', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentExpiryDateShort}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('documentExpiry', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentExpiry', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentExpiry} className = "pcti" type = "text"/></div>
                                </div>

                                <div className = "clearfix mt2">
                                    <div style = {getStyle('issueDate', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentIssueDateShort}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('issueDate', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('issueDate', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.issueDate} className = "pcti" type = "text"/></div>
                                </div>
                                </>
                                : void 0*/}
                                {/*getImportantInformation(8)*/}
                                </div>

                                {/*Main.Instance.PlayerCardState.expandedPlayerCard ? */}
                                <>
                                <div className = "clearfix mt2">
                                    <div style = {getStyle('registrationIp')} className = "label button-default w130px fl">{Assets.RegistrationIp}</div>
                                    <div className = "ml3 fl pr">
                                        <input disabled = {!canBeModifiedByAdmin('registrationIp')} onChange = {(e) => changeText('registrationIp', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.registrationIp} className = "pcti" type = "text"/>
                                        {buttonCopyToClipboard(Assets.RegistrationIp, 'registrationIp', 0)}
                                    </div>
                                </div>
                                {/*
                                <div className = "clearfix mt2">
                                    <div style = {getStyle('registrationDevice')} className = "label button-default w130px fl">{Assets.RegistrationDevice}</div>
                                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('registrationDevice')} onChange = {(e) => changeText('registrationDevice', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.registrationDevice} className = "pcti" type = "text"/></div>
                                </div>
                                */}
                                </>{/* : void 0*/}
                                {/*console.log (Main.Instance.DatabaseDataState.playerCard)*/}
                                {/*Main.Instance.DatabaseDataState.playerCard.selfAssessment ? console.log (Main.Instance.DatabaseDataState.playerCard.selfAssessment) : void 0*/}
                                {/*Main.Instance.PlayerCardState.expandedPlayerCard ? */}
                                <>
                                    <div className = "clearfix mt">
                                        {/*console.log (Main.Instance.DatabaseDataState.custom.general.players.accountStatus)*/}
                                        <div title = {Assets.AccountStatus} style = {getStyle('accountStatus')} className = "label button-default w130px fl tal pr">
                                            &nbsp;&nbsp;&nbsp;&nbsp;{Assets.AccountStatus}
                                            {/*Main.Instance.DatabaseDataState.playerCard.accountStatus !== undefined && Main.Instance.DatabaseDataState.custom.general.players.accountStatus && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus].requirements === undefined ? 
                                            <button style = {{position: 'absolute', zIndex: 9999, right: '0px', top: '0px', padding: '0px 4px 0px 3px', marginRight: '0px', borderRadius: '0px'}} className = "btn btn-sm btn-danger" onClick = {() => unlockAccount()}>
                                                <FontAwesomeIcon icon = {faUnlockAlt}/>
                                            </button> : void 0*/}
                                        </div>
                                        <div className = "ml3 fl">
                                        <button
                                            style = {{cursor: 'default', width: '187px', lineHeight: 1.1}}
                                            className = {'mr0 btn btn-xs risk risk-' + AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]}
                                        >
                                            {/*AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'ActiveFullyIdentified' ? 'Fully Identified' :
                                                AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'BlockedFull' ? 'Blocked Full' :
                                                AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus] === 'BlockedPartial' ? 'Blocked Partial' :
                                            AccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]*/}
                                            {Assets.accountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]}
                                            <input
                                                style = {{cursor: 'default'}}
                                                // disabled = {!canBeModifiedByAdmin('accountStatus')}
                                                // onChange = {(e) => changeNumber('accountStatus', e.target.value)}
                                                disabled = {true}
                                                onChange = {(e) => {}/*Nothing()*/}
                                                type = "range"
                                                list = "test"
                                                min = {AccountStatus.Initial}
                                                max = {AccountStatus.ClosedActive}
                                                step = "1"
                                                value = {Main.Instance.DatabaseDataState.playerCard.accountStatus}
                                                className = "slider"
                                            />
                                        </button>
                                        </div>
                                    </div>
{/*
                                    <div className = "clearfix">
                                        <div title = {Assets.ForceAccountStatus} style = {{fontSize: '18px', width: '169px'}} className = "label label-default fl">
                                            {Assets.ForceAccountStatusShort}
                                        </div>
                                        <div className = "ml3 fl">
                                        <button 
                                            className = {'mr0 btn btn-xs risk risk-' + ForceAccountStatus[Main.Instance.DatabaseDataState.playerCard.accountStatus]}
                                        >
                                            {ForceAccountStatus[Main.Instance.DatabaseDataState.playerCard.forceAccountStatus !== undefined ? Main.Instance.DatabaseDataState.playerCard.forceAccountStatus : 0]}
                                            <input
                                                style = {{cursor: 'default'}}
                                                disabled = {true} 
                                                type = "range"
                                                list = "test"
                                                min = {AccountStatus.Initial}
                                                max = {AccountStatus.ClosedActive}
                                                step = "1"
                                                value = {Main.Instance.DatabaseDataState.playerCard.accountStatus}
                                                className = "slider"
                                            />
                                        </button>
                                        </div>
                                    </div>
*/}

                                    {/*Main.Instance.DatabaseDataState.playerCard.limits ?
                                    <>
                                    <div className = "clearfix">
                                        <div className = "label label-default w100px fl">{Assets.NetLossLimit}</div>
                                        <div className = "ml3 fl mt-3">{Main.Instance.DatabaseDataState.playerCard.netLossLimit.value}</div>
                                    </div>

                                    <div className = "clearfix">
                                        <div className = "label label-default w100px fl">{Assets.Currency}</div>
                                        <div className = "ml3 fl mt-3">{Main.Instance.DatabaseDataState.playerCard.netLossLimit.currency}</div>
                                    </div>

                                    <div className = "clearfix">
                                        <div className = "label label-default w100px fl">{Assets.TimePeriod}</div>
                                        <div className = "ml3 fl mt-3">{Main.Instance.DatabaseDataState.playerCard.netLossLimit.timePeriod}</div>
                                    </div>
                                    </> : void 0*/}
                                    <div className = "clearfix mt2">
                                        <div style = {getStyle('riskLevel')} className = "label button-default w130px fl">{Assets.RiskLevel}</div>
                                        <div className = "ml3 fl">
                                        <button
                                            style = {{width: '187px', lineHeight: 1.1}}
                                            className = {'mr0 btn btn-xs risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.riskLevel]}
                                        >
                                            {RiskLevel[Main.Instance.DatabaseDataState.playerCard.riskLevel]}
                                            <input
                                                style = {{cursor: 'default'}}
                                                disabled = {true} 
                                                type = "range"
                                                list = "test"
                                                min = {RiskLevel.Low}
                                                max = {RiskLevel.Severe}
                                                step = "1"
                                                value = {Main.Instance.DatabaseDataState.playerCard.riskLevel}
                                                className = "slider"
                                            />
                                        </button>
                                        </div>
                                    </div>

                                    <div className = "clearfix mt2">
                                        <div style = {getStyle('level')} className = "label button-default w130px fl">
                                            {Assets.GamificationLevel}
                                        </div>
                                        <div style = {{borderRadius: '3px', textAlign: 'center', width: '187px', fontWeight: 700, lineHeight: '22px', fontSize: '16px', background: Assets_.jackpotschLevelColor[Main.Instance.DatabaseDataState.playerCard.level], color: Assets_.jackpotschLevelText[Main.Instance.DatabaseDataState.playerCard.level]}} className = "ml3 fl">
                                            {Assets.jackpotschLevels[Main.Instance.DatabaseDataState.playerCard.level]}
                                        </div>
                                    </div>

                                    <div className = "clearfix mt2">
                                        <div style = {getStyle('ballyId')} className = "label button-default w130px fl">{Assets.BallyId}</div>
                                        <div className = "ml3 fl pr">
                                            <input disabled = {!canBeModifiedByAdmin('ballyId')} onChange = {(e) => changeText('ballyId', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.ballyId} className = "pcti" type = "text"/>
                                            {buttonCopyToClipboard(Assets.BallyId, 'ballyId', 0)}
                                        </div>
                                    </div>


                                    <div className = "clearfix mt2">
                                        <div style = {getStyle('communication-channels')} className = "label button-default w190px fl">
                                            {Assets.CommunicationChannels}
                                        </div>
                                        <div style = {{borderRadius: '3px', textAlign: 'left', width: '127px', fontWeight: 700, lineHeight: '22px', fontSize: '16px'}} className = "ml3 fl">
                                            {getCommunicationChannels()}
                                        </div>
                                    </div>

                                    <div className = "clearfix mt2">
                                        <div style = {getStyle('language')} className = "label button-default w110px fl">{Assets.Player_ + ' ' + Assets.Language.toLowerCase()}</div>
                                        <div className = "ml3 fl">{getLanguages()}</div>
                                    </div>,

{/*
                                    <div className = "clearfix mt2">
                                        <button style = {{padding: '0px 10px'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} className = {Main.Instance.DatabaseDataState.insertANewIssue ? 'btn btn-success btn-sm btn-block' : 'btn btn-default btn-dark btn-sm btn-block'} onClick = {() => toggleInsertNewIssue()}>
                                            {Assets.InsertANewIssue}
                                        </button>
                                    </div>
*/}
                                    {/* TAGS
                                    <div className = "clearfix">
                                        <div className = "label label-default w100px fl" style = {{fontSize: '14px'}}>{Assets.Tags}</div>
                                        {getTags(Main.Instance.DatabaseDataState.playerCard.tags, Main.Instance.DatabaseDataState.playerCard.id)}
                                    </div>
                                    */}
                                </>{/* : void 0*/}
                    </div> : void 0}
                    <div className = "fl player-card-right" style = {Main.Instance.PlayerCardState.hiddenPlayerCard ? {marginLeft: '0px', width: '100%'} : {width: 'calc(100% - 340px)'}}>
                        {Main.Instance.PlayerCardState.hiddenPlayerCard ?
                        <div className = "clearfix" style = {{background: '#ddd', paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px', paddingBottom: '2px', borderBottom: '1px solid #bbb', position: 'relative'}}>
                            <div className = "fl" style = {{width: '340px', marginTop: '9px'}}>{threeStatuses()}</div>
                            {Main.Instance.PlayerCardState.toggleTopView ?
                            <>
                            <div className = "fl" style = {{width: '290px', marginLeft: '5px'}}>{getImportantInformation(1, undefined, true)}</div>
                            <div className = "fl" style = {{width: '250px', marginLeft: '5px'}}>{getImportantInformation(2, undefined, true)}</div>

                            {isUserGDPR() ?
                            <>
                            <div className = "fl" style = {{width: '270px', marginLeft: '5px'}}>{getImportantInformation(3, undefined, true)}</div>
                            <div className = "fl" style = {{width: '270px', marginLeft: '5px'}}>{getImportantInformation(4, undefined, true)}</div>
                            </>
                            : void 0}
                            </>
                            :
                            <>
                            <div className = "fl" style = {{width: '250px', marginLeft: '5px'}}>{getImportantInformation(5, undefined, true)}</div>
                            <div className = "fl" style = {{width: '280px', marginLeft: '5px'}}>{getImportantInformation(6, undefined, true)}</div>
                            <div className = "fl" style = {{width: '305px', marginLeft: '5px'}}>{getImportantInformation(7, undefined, true)}</div>
                            <div className = "fl" style = {{width: '305px', marginLeft: '5px'}}>{getImportantInformation(8, undefined, true)}</div>
                            </>
                            }
                            <div style = {{marginLeft: Main.Instance.PlayerCardState.toggleTopView ? '' : '-10px'}} className = "fl ml">
                                <button style = {{display: 'block'}} className = "btn btn-default btn-xs mt2" onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {toggleTopView: !Main.Instance.PlayerCardState.toggleTopView})}>
                                    {Main.Instance.PlayerCardState.toggleTopView ? Assets.More + '..' : Assets.Basic}
                                </button>
                                <button className = {Main.Instance.DatabaseDataState.insertANewIssue ? 'btn btn-warning btn-xs mt2' : 'btn btn-default btn-dark btn-xs mt2'} style = {{display: 'block'}} disabled = {!(isUserAdmin() || isUserRFSpecialist() || isUserRGSpecialist())} title = {Assets.InsertANewIssue} onClick = {() => toggleInsertNewIssue()}>
                                    {Assets.InsertANewIssueShort}
                                </button>
                            </div>
                            <button title = {Assets.MoveOnLeft} className = "btn btn-default btn-xs" onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {hiddenPlayerCard: false})} style = {{right: '2px', top: '2px', marginRight: '0px', position: 'absolute', border: '0px', zIndex: 2}}>
                                <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faArrowCircleLeft}/>
                            </button>

                            <button
                                title = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? Assets.f1 : Assets.f2}
                                onClick = {() => DbRoutes.wsGetPlayerCard(Main.Instance.PlayerCardState.id)}
                                style = {{position: 'absolute', top: '25px', right: '2px', marginRight: '0px', zIndex: 2}}
                                className = {JSON.stringify(Main.Instance.DatabaseDataState.playerCard) === Main.Instance.DatabaseDataState.playerCard_ ? 'btn btn-default btn-xs' : 'btn btn-warning btn-xs'}
                            >
                                <FontAwesomeIcon icon = {faSyncAlt}/>
                            </button>

                        </div> : void 0}
                        {
                        Main.Instance.DatabaseDataState.insertANewIssue ? InsertingNewIssue() :
                        Main.Instance.DatabaseDataState.manualWithdrawal ? ManualWithdrawal() :
                        Main.Instance.DatabaseDataState.manualBonus ? ManualBonus() :
                        Main.Instance.DatabaseDataState.manualDeposit ? ManualDeposit() :
                        Main.Instance.DatabaseDataState.resendVerificationEmail ? ResendVerificationEmail() :
                        Main.Instance.DatabaseDataState.manualCrif ? ManualCrif() :
                        Main.Instance.DatabaseDataState.showUploads ? ShowUploads() :
                        <>
                        <div className = "clearfix" style = {{background: '#ddd', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px', position: 'relative'}}>
                            {getButtonsFirstLine(unresolvedIssues)}
                            {Main.Instance.PlayerCardState.toggleInfo ? getDetailsInfoTable() : void 0}
                            {Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions ? Main.Instance.DatabaseDataState.wrongId === false ? 
                            <button style = {{right: '22px', top: '-4px', marginRight: '0px',position: 'absolute', background: 'transparent', border: '0px', zIndex: 2, padding: '0px'}}><FontAwesomeIcon style = {{color: 'green'}} title = {Assets.AllTransactionsHaveCorrectPlayerId} icon = {faCheck}/></button>
                             : <button style = {{right: '22px', top: '-4px', marginRight: '0px',position: 'absolute', background: 'transparent', border: '0px', zIndex: 2, padding: '0px'}}><FontAwesomeIcon style = {{color: 'red'}} title = {Assets.TransactionsHaveTheWrongPlayerId} icon = {faTimes}/></button> : void 0}
                            <button onClick = {() => { Cookies.remove('playerId', { path: '/' });Logic.Type.New (Main.Instance.PlayerCardState, {id: '0', onlyPlayerId: undefined}); Logic.Type.New (Main.Instance.SearchState, {search_string: ''})}} style = {{right: '2px', top: '-4px', marginRight: '0px',position: 'absolute', background: 'transparent', border: '0px', zIndex: 2, padding: '0px'}}>
                                <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                            </button>
                            {/*
                            <button
                                disabled = {!isPlayerCardChanged()}
                                className = "btn btn-warning btn-xs"
                                onClick = {() => DbRoutes.updatePlayerCard(Main.Instance.DatabaseDataState.playerCard)}
                                style = {{position: 'absolute', top: '10px', right: '0px', zIndex: 999}}
                            >
                                <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '14px'}} icon = {faSave}/>
                                {Assets.UpdatePlayerCard}
                            </button>
                            */}
                            {/*
                            <button
                                disabled = {unresolvedIssues[0] === 0}
                                onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {filterIssues: !Main.Instance.PlayerCardState.filterIssues})}
                                title = "Filter unresolved issues"
                                style = {{position: 'absolute', top: '10px', right: '150px', zIndex: 999}}
                                className = {!Main.Instance.PlayerCardState.filterIssues ? 'btn btn-default btn-xs' : 'btn btn-danger btn-xs'}
                            >
                                <FontAwesomeIcon icon = {faFilter}/> 
                            </button>*/}
                            <span title = {Assets.UnresolvedIssues} style = {{position: 'absolute', top: '12px', right: '1px'/*'160px'*/, zIndex: 998}} className = {unresolvedIssues[0] === 0 ? 'badge' : 'badge badge-danger'}>
                                {unresolvedIssues[0]}
                            </span>
                        </div>
                        <div className = "ml mr">
                        {/*SPECIAL SESSION ID TO SHOW MORE DETAIL*/}
                        {/*Main.Instance.DatabaseDataState.sessionId === '52f59590-8a94-11e9-bddf-35d30d04f7d2' && Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory ? void 0 :*/}
                        <div className = "clearfix mt5 mb5">
                            {getButtonsSecondLine()}
                        </div>{/*END*/}
                        {/*Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions ?
                        <div className = "clearfix mb5">
                            {getButtonsThirdLine()}
                        </div>
                        : void 0*/}
                        {/*console.log (Main.Instance.DatabaseDataState.playerTimeline)*/}
                        {/*Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ? getButtonsSecondLine() : void 0*/}
                        {/* PLAYING HISTORY */}
                        {Main.Instance.PlayerCardState.nav === PlayerCardButtons.GameSessions && Main.Instance.PlayerCardState.gameSessions && Main.Instance.PlayerCardState.GameSessions ?
                        <>
                            {Main.Instance.PlayerCardState.gameSessionSelectedId && Main.Instance.PlayerCardState.gameSessionsTransactions ?
                            <div>
                                <div className = "clearfix pr">
                                    <div className = "fl">
                                        <a
                                            onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionSelectedId: undefined})}
                                            style = {{position: 'absolute', top: '0px', right: '0px', fontSize: '12px', border: '0px', background: 'transparent', color: '#333'}}
                                        >
                                            <FontAwesomeIcon icon = {faTimes}/>
                                        </a>
                                        {/*
                                        <span style = {{fontWeight: 700, fontSize: '12px'}}>{Assets.Selected + ' ' + Assets.Game.toLowerCase() + ' ' + Assets.SessionID.toLowerCase()}:&nbsp;&nbsp;</span>
                                        <button
                                            type = "button"
                                            title = {Assets.Close}
                                            className = "btn btn-danger btn-xs"
                                            onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionSelectedId: undefined})}
                                        >
                                            {Main.Instance.PlayerCardState.gameSessionSelectedId}
                                        </button>
                                        */}
                                        <button
                                            onClick = {() => copyStringToClipboard(Main.Instance.PlayerCardState.gameSessionSelectedId)}
                                            title = {Main.Instance.PlayerCardState.gameSessionSelectedId}
                                            className = "btn btn-default btn-xs"
                                        >
                                            <FontAwesomeIcon icon = {FaCopy}/>&nbsp;
                                            {Assets.Game + ' ' + Assets.SessionID.toLowerCase()}
                                        </button>
{/*
                                        <button
                                            onClick = {() => copyStringToClipboard(getSession())}
                                            title = {getSession()}
                                            className = "btn btn-default btn-xs"
                                        >
                                            <FontAwesomeIcon icon = {FaCopy}/>&nbsp;{Assets.Session + ' ' + Assets.ID.toLowerCase()}
                                        </button>
*/}
                                        {Main.Instance.PlayerCardState.gameSessionSelectedIdExternal ? 
                                        <button
                                            onClick = {() => copyStringToClipboard(Main.Instance.PlayerCardState.gameSessionSelectedIdExternal)}
                                            title = {Main.Instance.PlayerCardState.gameSessionSelectedIdExternal}
                                            className = "btn btn-default btn-xs"
                                        >
                                            <FontAwesomeIcon icon = {faCopy}/>&nbsp;{Assets.External + ' ' + Assets.Game.toLowerCase() + ' ' +' ' + Assets.SessionID.toLowerCase()}
                                        </button> : void 0}
                                        <span style = {{fontWeight: 700, fontSize: '13px'}}>
                                            {Main.Instance.PlayerCardState.gameSessionSelectedGame}
                                        </span>
                                        &nbsp;
                                        <span style = {{fontWeight: 400, fontSize: '13px'}}>
                                            {Assets.TotalBet}:
                                        </span>
                                        &nbsp;
                                        <span style = {{fontWeight: 700, fontSize: '13px'}}>
                                            {getTotalBet()}
                                        </span>
                                        &nbsp;
                                        <span style = {{fontWeight: 400, fontSize: '13px'}}>
                                            {Assets.TotalWin}:
                                        </span>
                                        &nbsp;
                                        <span style = {{fontWeight: 700, fontSize: '13px'}}>
                                            {getTotalWin()}
                                        </span>

                                    </div>
                                    {/*console.log (Main.Instance.DatabaseDataState.d2)*/}
                                    <div className = "fr clearfix">
                                        <div id = "datadate03" title = {Assets.Date + ' ' + Assets.Start.toLowerCase()} className = "fl singledatepicker">
                                            <SingleDatePicker
                                                id = "datadate7"
                                                placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                                                date = {Main.Instance.DatabaseDataState.d1}
                                                onDateChange = {(date) => date === null || Main.Instance.DatabaseDataState.d2 === null || date.format(Assets_.dateDisplayFormat) <= Main.Instance.DatabaseDataState.d2.format(Assets_.dateDisplayFormat) ? Logic.Type.New(Main.Instance.DatabaseDataState, {d1: date}) : {}}
                                                focused = {Main.Instance.DatabaseDataState.f1}
                                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.DatabaseDataState, {f1: focused.focused})}
                                                showDefaultInputIcon = {true}
                                                showClearDate = {true}
                                                isOutsideRange = {() => false}
                                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                                customCloseIcon = {Assets_.customCloseIcon}
                                                numberOfMonths = {1}
                                                // isDayHighlighted = {(date) => HighlightToday(date)}
                                            />
                                        </div>
                                        {Main.Instance.DatabaseDataState.d1 ?
                                        <input className = "dbl fl" style = {{height: '24px', width: '70px', marginLeft: '5px', paddingLeft: '5px', paddingRight: '5px'}} type = "text" value = {Main.Instance.DatabaseDataState.t1} onChange = {(e) => Main.Instance.DatabaseDataState.d1.format(Assets_.dateDisplayFormat) === Main.Instance.DatabaseDataState.d2.format(Assets_.dateDisplayFormat) && e.target.value > Main.Instance.DatabaseDataState.t2 ? {} : Logic.Type.New(Main.Instance.DatabaseDataState, {t1: e.target.value})}/>
                                        : void 0}
                                        <div id = "datadate04" title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl ml singledatepicker">
                                            <SingleDatePicker
                                                id = "datadate8"
                                                placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                                                date = {Main.Instance.DatabaseDataState.d2}
                                                onDateChange = {(date) => date === null || Main.Instance.DatabaseDataState.d1 === null || date.format(Assets_.dateDisplayFormat) >= Main.Instance.DatabaseDataState.d1.format(Assets_.dateDisplayFormat) ? Logic.Type.New(Main.Instance.DatabaseDataState, {d2: date}) : {}}
                                                focused = {Main.Instance.DatabaseDataState.f2}
                                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.DatabaseDataState, {f2: focused.focused})}
                                                showDefaultInputIcon = {true}
                                                showClearDate = {true}
                                                isOutsideRange = {() => false}
                                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                                customCloseIcon = {Assets_.customCloseIcon}
                                                numberOfMonths = {1}
                                                // isDayHighlighted = {(date) => HighlightToday(date)}
                                            />
                                        </div>
                                        {Main.Instance.DatabaseDataState.d2 ?
                                        <input className = "dbl fl" style = {{height: '24px', width: '70px', marginLeft: '5px', paddingLeft: '5px', paddingRight: '5px'}} type = "text" value = {Main.Instance.DatabaseDataState.t2} onChange = {(e) => Main.Instance.DatabaseDataState.d1.format(Assets_.dateDisplayFormat) === Main.Instance.DatabaseDataState.d2.format(Assets_.dateDisplayFormat) && e.target.value < Main.Instance.DatabaseDataState.t1 ? {} : Logic.Type.New(Main.Instance.DatabaseDataState, {t2: e.target.value})}/>
                                        : void 0}
                                        <button style = {{marginRight: '20px', marginTop: '2px'}} onClick = {() => DbRoutes.getGameSessionsTransactions()} className = "btn btn-success btn-xs ml" title = {Assets.Refresh}>
                                            <FontAwesomeIcon icon = {faSearch}/> {Assets.Apply}
                                        </button>
                                    </div>
                                </div>
                                <div className = "pr clearfix register-input">
                                    <div className = "control-panel clearfix" style = {{height: '30px', marginBottom: '5px'}}>
                                        <FormInputNumber
                                            description = {Assets.recordsPerPageDesc}
                                            outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                            ClassName = "h26 w60"
                                            value = {!Main.Instance.PlayerCardState.gameSessionTransactionsLimit ? '' : Main.Instance.PlayerCardState.gameSessionTransactionsLimit.toString()}  
                                            type = "number" 
                                            min = "1"
                                            icon = {Assets_.faHamburgerClass}
                                            onChange = {(text) => recordsPerPageChanged(text)}
                                        />
                                        <button 
                                            className = "btn btn-primary btn-xs dbl fl" 
                                            title = {Assets.ListMoveTop}
                                            onClick = {() => firstPage()}
                                        >
                                            <i className = {Assets_.faFirstButtonClass}/>
                                        </button>
                                        <button 
                                            className = "btn btn-primary btn-xs dbl fl" 
                                            title = {Assets.ListMoveLeft}
                                            onClick = {() => prevPage()}
                                        >
                                            <i className = {Assets_.faPrevButtonClass}/>
                                        </button>
                                        <button 
                                            className = "btn btn-primary btn-xs dbl fl"
                                            title = {Assets.ListMoveRight}
                                            onClick = {() => nextPage()}
                                        >
                                            <i className = {Assets_.faNextButtonClass}/>
                                        </button>

                                        <button
                                            className = "btn btn-primary btn-xs dbl fl"
                                            title = {Assets.ListMoveBottom}
                                            onClick = {() => lastPage()}
                                        >
                                            <i className = {Assets_.faLastButtonClass}/>
                                        </button>
                                        <select
                                            className = "ml dbl fl"
                                            style = {{background: '#fff', height: '24px'}}
                                            onChange = {(e) => {Logic.Type.New (Main.Instance.DatabaseDataState, {tt: parseInt(e.target.value)});DbRoutes.getGameSessionsTransactions()}}
                                            value = {Main.Instance.DatabaseDataState.tt}
                                        >
                                            {getTransactionTypeOptions()}
                                        </select>
                                        <select
                                            className = "ml dbl fl"
                                            style = {{background: '#fff', height: '24px'}}
                                            onChange = {(e) => {Logic.Type.New (Main.Instance.DatabaseDataState, {ts: parseInt(e.target.value)});DbRoutes.getGameSessionsTransactions()}}
                                            value = {Main.Instance.DatabaseDataState.ts}
                                        >
                                            {getTransactionStatusOptions()}
                                        </select>
                                        {/*
                                        <button onClick = {() => DbRoutes.getGameSessionsTransactions()} className = "btn btn-success btn-xs ml mr0">{Assets.Refresh}</button>
                                        */}
                                        <span className = "label label-success table-label">
                                            {Assets.records}
                                            {Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? Main.Instance.PlayerCardState.gameSessionsTransactionsCount : 0}
                                        </span>

                                        <span className = "label label-success table-label">
                                            {Assets.showing}
                                            {Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? Main.Instance.PlayerCardState.gameSessionTransactionsPage * 
                                            Main.Instance.PlayerCardState.gameSessionTransactionsLimit + 1 : 0}{' - '}
                                            {Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? 
                                            ((Main.Instance.PlayerCardState.gameSessionTransactionsPage + 1) * 
                                            Main.Instance.PlayerCardState.gameSessionTransactionsLimit > Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? 
                                            Main.Instance.PlayerCardState.gameSessionsTransactionsCount : 
                                            (Main.Instance.PlayerCardState.gameSessionTransactionsPage + 1) * Main.Instance.PlayerCardState.gameSessionTransactionsLimit) : 0}
                                        </span>

                                        <span className = "label label-success table-label">
                                            {Assets.page}
                                            {Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? 
                                            Main.Instance.PlayerCardState.gameSessionTransactionsPage + 1 :0}{' / '}
                                            {Main.Instance.PlayerCardState.gameSessionsTransactionsCount ? 
                                            Math.trunc((Main.Instance.PlayerCardState.gameSessionsTransactionsCount - 1) /
                                            Main.Instance.PlayerCardState.gameSessionTransactionsLimit + 1) : 0}
                                        </span>
                                    </div>
                                </div>
                                {/*console.log (Main.Instance.PlayerCardState.gameSessionsTransactions)*/}
                                <ReactTable
                                    data = {Main.Instance.PlayerCardState.gameSessionsTransactions}
                                    columns = {[
                                        {sortable: false, width: 50, Header: Assets.Index, accessor: '', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.index + 1 + Main.Instance.PlayerCardState.gameSessionTransactionsPage * Main.Instance.PlayerCardState.gameSessionTransactionsLimit},
                                        {sortable: false, width: 40, Header: <FontAwesomeIcon title = {Assets.Currency} icon = {faMoneyBillWaveAlt}/>, accessor: 'currency', headerClassName: 'th-center', className: 'td-left', Cell: (row) => row.value ? row.value : 'CHF'},
                                        
                                        {sortable: false, Header: Assets.Transaction, headerClassName: 'th-center risk-default', columns : [
                                            {sortable: false, width: Main.Instance.DatabaseDataState.showRoundId ? 450 : 120, Header: Assets.Round + ' ' + Assets.ID + '/' + Assets.ID, accessor: 'id', headerClassName: 'th-left', className: 'td-left', Cell: (row) => 
                                                <div>
                                                    <div>
                                                        {Main.Instance.DatabaseDataState.showRoundId ?
                                                        <span title = {Assets.Round + ' ' + Assets.ID} style = {{marginRight: '5px'}}>{row.original.roundId}</span>
                                                        : void 0}
                                                        <button
                                                            onClick = {() => copyStringToClipboard(row.original.roundId)}
                                                            title = {Assets.Copy + ' ' + Assets.Round + ' ' + Assets.ID + Assets._to_ + Assets.Clipboard}
                                                            className = "btn btn-default btn-xs mr0"
                                                        >
                                                            <FontAwesomeIcon icon = {FaCopy}/>
                                                        </button>
                                                        &nbsp;
                                                        <span title = {Assets.ID}>{row.value}</span>
                                                        {/*&nbsp;*/}
                                                        {Main.Instance.DatabaseDataState.showRoundId ?
                                                        <button
                                                            onClick = {() => copyStringToClipboard(row.value)}
                                                            className = "btn btn-default btn-xs mr0"
                                                            // style = {{marginRight: '3px'}}
                                                            title = {Assets.Copy + ' ' + Assets.ID + Assets._to_ + Assets.Clipboard}
                                                        >
                                                            <FontAwesomeIcon icon = {faCopy}/>
                                                        </button>
                                                        : void 0}
                                                        <button
                                                            style = {{marginTop: '0px', marginLeft: '5px'}}
                                                            className = "btn btn-default btn-xs mr0"
                                                            onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {showRoundId: !Main.Instance.DatabaseDataState.showRoundId})}
                                                        >
                                                            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.showRoundId ? faCompress : faExpand}/>
                                                        </button>

                                                    </div>
                                                </div>},
                                            {sortable: false, Header: <span title = {Assets.Transaction + ' ' + Assets.Type}>{Assets.Type}</span>, accessor: 'transactionType', headerClassName: 'th-left', className: 'td-left', Cell: (row) => TransactionType[row.value].toLowerCase()},
                                            {sortable: false, width: 85, Header: <span title = {Assets.Transaction + ' ' + Assets.Status}>{Assets.Status}</span>, accessor: 'transactionStatus', headerClassName: 'th-left', className: 'td-left', Cell: (row) => TransactionStatus[row.value].toLowerCase()},
                                        ]},

                                        {sortable: false, Header: Assets.Timestamps, headerClassName: 'th-center risk-default-light', columns : [
                                            {sortable: false, width: 145, Header: <span title = {Assets.Created + ' ' + Assets.Timestamp.toLowerCase() + ' ' + Assets_.UTC}>{Assets.Created + ' ' + Assets_.UTC}</span>, accessor: 'created', headerClassName: 'th-left', className: 'td-left', Cell: (row) => GetDate(parseInt(row.value))},
                                            {sortable: false, width: 145, Header: <span title = {Assets.Completed + ' ' + Assets.Timestamp.toLowerCase() + ' ' + Assets_.UTC}>{Assets.Completed + ' ' + Assets_.UTC}</span>, accessor: 'completed', headerClassName: 'th-left', className: 'td-left', Cell: (row) => GetDate(parseInt(row.value))},
                                    ]},

                                        {sortable: false, Header: Assets.RealBalance, headerClassName: 'th-center risk-default', columns : [
                                            {sortable: false, width: 90, Header: <span title = {Assets.RealBalance + ' ' + Assets.Before.toLowerCase()}>{Assets.Before}</span>, accessor: 'realBalanceBefore', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value.toFixed(2)) : '-'},
                                            {sortable: false, width: 90, Header: <span title = {Assets.RealBalance + ' ' + Assets.After.toLowerCase()}>{Assets.After}</span>, accessor: 'realBalanceAfter', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined? numberWithCommas(row.value.toFixed(2)) : '-'},
                                            {sortable: false, width: 90, Header: <span title = {Assets.Real + ' ' + Assets.Amount.toLowerCase()}>{Assets.Amount}</span>, accessor: 'realAmount', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined ? <span title = {row.original.transactionType === TransactionType.WIN && row.value === 0 ? Assets.no_win : ''} style = {{color: row.original.transactionType === TransactionType.WIN && row.value === 0 ? '#a00' : ''}}>{numberWithCommas(row.value.toFixed(2))}</span> : '-'},
                                    ]},

                                        {sortable: false, Header: Assets.BonusBalance, headerClassName: 'th-center risk-default-light', columns : [
                                            {sortable: false, width: 90, Header: <span title = {Assets.BonusBalance + ' ' + Assets.Before.toLowerCase()}>{Assets.Before}</span>, accessor: 'bonusBalanceBefore', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value.toFixed(2)) : '-'},
                                            {sortable: false, width: 90, Header: <span title = {Assets.BonusBalance + ' ' + Assets.After.toLowerCase()}>{Assets.After}</span>, accessor: 'bonusBalanceAfter', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value.toFixed(2)) : '-'},
                                            {sortable: false, width: 90, Header: <span title = {Assets.BonusAmount}>{Assets.Amount}</span>, accessor: 'bonusAmount', headerClassName: 'th-left', className: 'td-left', Cell: (row) => row.value !== undefined ? <span title = {row.original.transactionType === TransactionType.WIN && row.value === 0 ? Assets.no_win : ''} style = {{color: row.original.transactionType === TransactionType.WIN && row.value === 0 ? '#999' : ''}}>{numberWithCommas(row.value.toFixed(2))}</span> : '-'},
                                    ]},

                                    ]}
                                    showPaginationTop = {false}
                                    showPaginationBottom = {false}
                                    pageSize = {Main.Instance.PlayerCardState.gameSessionsTransactionsCount < Main.Instance.PlayerCardState.gameSessionTransactionsLimit ? Main.Instance.PlayerCardState.gameSessionsTransactionsCount : Main.Instance.PlayerCardState.gameSessionTransactionsLimit}
                                    className="-striped -highlight"
                                />
                                <br/>
                            </div>
                            : void 0}
                            <div className = "pr clearfix register-input">
                                {/*Main.Instance.PlayerCardState.gameSessions.length === 0 ? void 0 :*/}
                                <div className = "control-panel clearfix" style = {{height: '30px', marginBottom: '5px'}}>
                                    <FormInputNumber
                                        description = {Assets.recordsPerPageDesc}
                                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                                        ClassName = "h26 w60"
                                        value = {!Main.Instance.PlayerCardState.gameSessionLimit ? '' : Main.Instance.PlayerCardState.gameSessionLimit.toString()}  
                                        type = "number" 
                                        min = "1"
                                        icon = {Assets_.faHamburgerClass}
                                        onChange = {(text) => RecordsPerPageChanged(text)}
                                    />
                                    <button 
                                        className = "btn btn-primary btn-xs dbl fl" 
                                        title = {Assets.ListMoveTop}
                                        onClick = {() => FirstPage()}
                                    >
                                        <i className = {Assets_.faFirstButtonClass}/>
                                    </button>
                                    <button 
                                        className = "btn btn-primary btn-xs dbl fl" 
                                        title = {Assets.ListMoveLeft}
                                        onClick = {() => PrevPage()}
                                    >
                                        <i className = {Assets_.faPrevButtonClass}/>
                                    </button>
                                    <button 
                                        className = "btn btn-primary btn-xs dbl fl"
                                        title = {Assets.ListMoveRight}
                                        onClick = {() => NextPage()}
                                    >
                                        <i className = {Assets_.faNextButtonClass}/>
                                    </button>
                                    <button 
                                        className = "btn btn-primary btn-xs dbl fl"
                                        title = {Assets.ListMoveBottom}
                                        onClick = {() => LastPage()}
                                    >
                                        <i className = {Assets_.faLastButtonClass}/>
                                    </button>
                                    <select
                                        className = "ml dbl fl"
                                        style = {{background: '#fff', height: '24px'}}
                                        onChange = {(e) => {
                                            Logic.Type.New (Main.Instance.DatabaseDataState, {gs: parseInt(e.target.value)})
                                            DbRoutes.getGameSessions()
                                        }}
                                        value = {Main.Instance.DatabaseDataState.gs}
                                    >
                                        {getGameSessionStatusOptions()}
                                    </select>

                                    <div className = "fl clearfix ml">
                                        <div id = "datadate05" title = {Assets.Date + ' ' + Assets.Start.toLowerCase()} className = "fl singledatepicker">
                                            <SingleDatePicker
                                                id = "datadate9"
                                                placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                                                date = {Main.Instance.DatabaseDataState.d3}
                                                onDateChange = {(date) => date === null || Main.Instance.DatabaseDataState.d4 === null || date.format(Assets_.dateDisplayFormat) <= Main.Instance.DatabaseDataState.d4.format(Assets_.dateDisplayFormat) ? Logic.Type.New(Main.Instance.DatabaseDataState, {d3: date}) : {}}
                                                focused = {Main.Instance.DatabaseDataState.f3}
                                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.DatabaseDataState, {f3: focused.focused})}
                                                showDefaultInputIcon = {true}
                                                showClearDate = {true}
                                                isOutsideRange = {() => false}
                                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                                customCloseIcon = {Assets_.customCloseIcon}
                                                numberOfMonths = {1}
                                                // isDayHighlighted = {(date) => HighlightToday(date)}
                                            />
                                        </div>
                                        {Main.Instance.DatabaseDataState.d3 ?
                                        <input className = "dbl fl" style = {{height: '24px', width: '70px', marginLeft: '5px', paddingLeft: '5px', paddingRight: '5px'}} type = "text" value = {Main.Instance.DatabaseDataState.t3} onChange = {(e) => Main.Instance.DatabaseDataState.d3.format(Assets_.dateDisplayFormat) === Main.Instance.DatabaseDataState.d4.format(Assets_.dateDisplayFormat) && e.target.value > Main.Instance.DatabaseDataState.t4 ? {} : Logic.Type.New(Main.Instance.DatabaseDataState, {t3: e.target.value})}/>
                                        : void 0}
                                        <div id = "datadate06" title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl ml singledatepicker">
                                            <SingleDatePicker
                                                id = "datadate10"
                                                placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                                                date = {Main.Instance.DatabaseDataState.d4}
                                                onDateChange = {(date) => date === null || Main.Instance.DatabaseDataState.d3 === null || date.format(Assets_.dateDisplayFormat) >= Main.Instance.DatabaseDataState.d3.format(Assets_.dateDisplayFormat) ? Logic.Type.New(Main.Instance.DatabaseDataState, {d4: date}) : {}}
                                                focused = {Main.Instance.DatabaseDataState.f4}
                                                onFocusChange = {(focused) => Logic.Type.New(Main.Instance.DatabaseDataState, {f4: focused.focused})}
                                                showDefaultInputIcon = {true}
                                                showClearDate = {true}
                                                isOutsideRange = {() => false}
                                                displayFormat = {Assets_.dateDisplayFormatdmy}
                                                customCloseIcon = {Assets_.customCloseIcon}
                                                numberOfMonths = {1}
                                                // isDayHighlighted = {(date) => HighlightToday(date)}
                                            />
                                        </div>
                                        {Main.Instance.DatabaseDataState.d4 ?
                                        <input className = "dbl fl" style = {{height: '24px', width: '70px', marginLeft: '5px', paddingLeft: '5px', paddingRight: '5px'}} type = "text" value = {Main.Instance.DatabaseDataState.t4} onChange = {(e) => Main.Instance.DatabaseDataState.d3.format(Assets_.dateDisplayFormat) === Main.Instance.DatabaseDataState.d4.format(Assets_.dateDisplayFormat) && e.target.value < Main.Instance.DatabaseDataState.t3 ? {} : Logic.Type.New(Main.Instance.DatabaseDataState, {t4: e.target.value})}/>
                                        : void 0}
                                        <button style = {{marginTop: '2px'}} onClick = {() => DbRoutes.getGameSessions(/*Main.Instance.DatabaseDataState.playerCard.playerId*/)} className = "btn btn-success btn-xs ml" title = {Assets.Refresh}>
                                            <FontAwesomeIcon icon = {faSearch}/> {Assets.Apply}
                                        </button>
                                    </div>

                                    <span className = "label label-success table-label">
                                        {Assets.records}
                                        {Main.Instance.PlayerCardState.gameSessionsCount ? Main.Instance.PlayerCardState.gameSessionsCount : 0}
                                    </span>
                                    <span className = "label label-success table-label">
                                        {Assets.showing}
                                        {Main.Instance.PlayerCardState.gameSessionsCount ? Main.Instance.PlayerCardState.gameSessionPage * 
                                        Main.Instance.PlayerCardState.gameSessionLimit + 1 : 0}{' - '}
                                        {Main.Instance.PlayerCardState.gameSessionsCount ? 
                                        ((Main.Instance.PlayerCardState.gameSessionPage + 1) * 
                                        Main.Instance.PlayerCardState.gameSessionLimit > Main.Instance.PlayerCardState.gameSessionsCount ? 
                                        Main.Instance.PlayerCardState.gameSessionsCount : 
                                        (Main.Instance.PlayerCardState.gameSessionPage + 1) * Main.Instance.PlayerCardState.gameSessionLimit) : 0}
                                    </span>
                                    <span className = "label label-success table-label">
                                        {Assets.page}
                                        {Main.Instance.PlayerCardState.gameSessionsCount ? 
                                        Main.Instance.PlayerCardState.gameSessionPage + 1 :0}{' / '}
                                        {Main.Instance.PlayerCardState.gameSessionsCount ? 
                                        Math.trunc((Main.Instance.PlayerCardState.gameSessionsCount - 1) /
                                        Main.Instance.PlayerCardState.gameSessionLimit + 1) : 0}
                                    </span>
                                </div>
                                {/*console.log (Main.Instance.PlayerCardState.gameSessions)*/}
                                {/*console.log (Main.Instance.DatabaseDataState.gs)*/}
                                {(Main.Instance.DatabaseDataState.gs === undefined || Main.Instance.DatabaseDataState.gs === 0) && Main.Instance.PlayerCardState.gameSessions.length === 0 ?
                                <div>
                                    <FontAwesomeIcon icon = {faExclamationTriangle}/>&nbsp;
                                    {Assets.ThereAreNoGamingSessionsInTheSystemInsertedForThisPlayer}
                                </div>
                                :
                                <ReactTable
                                    data = {Main.Instance.PlayerCardState.gameSessions}
                                    columns = {[
                                        {
                                            sortable: false, 
                                            width: 50, 
                                            Header: Assets.Index, 
                                            accessor: 'gameSessionId', 
                                            headerClassName: 'th-center', 
                                            className: 'td-center', 
                                            Cell: (row) => 
                                                <button
                                                    disabled = {row.original.roundsPlayed === 0 && row.original.totalBetReal === 0 && row.original.totalWinReal === 0 && row.original.totalBetBonus === 0 && row.original.totalWinBonus === 0}
                                                    title = {row.original.roundsPlayed === 0 && row.original.totalBetReal === 0 && row.original.totalWinReal === 0 && row.original.totalBetBonus === 0 && row.original.totalWinBonus === 0 ? Assets.Empty + ' ' + Assets.GameSession.toLowerCase() : Assets.SelectAGameSession}
                                                    onClick = {() => row.value === Main.Instance.PlayerCardState.gameSessionSelectedId ? Logic.Type.New (Main.Instance.PlayerCardState, {gameSessionSelectedId: undefined}) : selectGameSessionID(row.value, row.original.gameId, row.original.externalGameSessionId, row)}
                                                    className = {row.value === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                                >
                                                    {row.index + 1 + Main.Instance.PlayerCardState.gameSessionPage * Main.Instance.PlayerCardState.gameSessionLimit}
                                                </button>
                                        },
                                        {
                                            sortable: false,
                                            Header: Assets.GameSession + ' ' + Assets.Information.toLowerCase(),
                                            headerClassName: 'th-center risk-default',
                                            width: 450,
                                            columns: [
                                        {
                                            sortable: false, 
                                            width: 140, 
                                            Header: (
                                                <div>
                                                    <span style={{display: 'block'}} title = {Assets.Created + ' ' + Assets.Timestamp.toLowerCase() + ' ' + Assets_.UTC}>{Assets.Created + ' ' + Assets_.UTC}/</span>
                                                    <span title = {Assets.Completed + ' ' + Assets.Timestamp.toLowerCase() + ' ' + Assets_.UTC}>{Assets.Completed + ' ' + Assets_.UTC}</span>
                                                </div>
                                            ),
                                            headerClassName: 'th-center', 
                                            className: 'td-center', 
                                            Cell: (row) => (
                                                <div>
                                                    <span style={{display: 'block'}}>{GetDate(row.original.created)}</span>
                                                    <span>{GetDate(row.original.completed)}</span>
                                                </div>
                                            )
                                        },
                                        {
                                            sortable: false,
                                            width: Main.Instance.DatabaseDataState.showGsidEgsid ? 320 : 60,
                                            Header: (
                                                <div>
                                                    <span
                                                        title = {Assets.Game + ' ' + Assets.SessionID}
                                                        style={{ display: Main.Instance.DatabaseDataState.showGsidEgsid ? '' : 'block' }}
                                                    >
                                                        {Assets.GSID}/
                                                    </span>
                                                    <span
                                                        title = {Assets.External + ' ' + Assets.Game + ' ' + Assets.SessionID}
                                                    >
                                                        {Assets.EGSID}
                                                    </span>
                                                    {Main.Instance.DatabaseDataState.showGsidEgsid ?
                                                    <button
                                                        title = {Assets.Compress}
                                                        className = "btn btn-default btn-xs ml"
                                                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {showGsidEgsid: !Main.Instance.DatabaseDataState.showGsidEgsid})}
                                                    >
                                                        {Assets.ShowLess}
                                                    </button> : void 0}
                                                </div>
                                            ),
                                            accessor: 'gameSessionId',
                                            headerClassName: 'th-center', 
                                            className: 'td-center', 
                                            Cell: (row) => Main.Instance.DatabaseDataState.showGsidEgsid ?
                                                <div style = {{textAlign: 'left'}}>
                                                    <div>
                                                        {row.value}&nbsp;
                                                        <button 
                                                            style = {{marginRight: '0px'}}
                                                            title = {Assets.Copy + ' ' + Assets.Game + ' ' + Assets.SessionID + Assets._to_ + Assets.Clipboard} 
                                                            onClick = {() => copyStringToClipboard(row.value)} 
                                                            className = {row.original.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                                        >
                                                            <FontAwesomeIcon icon = {FaCopy}/>
                                                        </button>
                                                    </div>
                                                    {row.original.externalGameSessionId ?
                                                    <div>
                                                        {row.original.externalGameSessionId}&nbsp;
                                                        <button
                                                            style = {{marginRight: '0px'}}
                                                            title = {Assets.Copy + ' ' + Assets.External + ' ' + Assets.Game + ' ' + Assets.SessionID + Assets._to_ + Assets.Clipboard} 
                                                            onClick = {() => copyStringToClipboard(row.original.externalGameSessionId)} 
                                                            className = {row.original.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                                        >
                                                            <FontAwesomeIcon icon = {faCopy}/>
                                                        </button>
                                                    </div> : void 0}
                                                </div>
                                                :
                                                <div>
                                                    <div>
                                                        <button 
                                                            style = {{marginRight: '5px'}}
                                                            title = {Assets.Copy + ' ' + Assets.Game + ' ' + Assets.SessionID + Assets._to_ + Assets.Clipboard} 
                                                            onClick = {() => copyStringToClipboard(row.value)} 
                                                            className = {row.original.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                                        >
                                                            <FontAwesomeIcon icon = {FaCopy}/>
                                                        </button>
                                                        {row.original.externalGameSessionId ?
                                                        <button
                                                            style = {{marginRight: '0px'}}
                                                            title = {Assets.Copy + ' ' + Assets.External + ' ' + Assets.Game + ' ' + Assets.SessionID + Assets._to_ + Assets.Clipboard} 
                                                            onClick = {() => copyStringToClipboard(row.original.externalGameSessionId)} 
                                                            className = {row.original.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                                                        >
                                                            <FontAwesomeIcon icon = {faCopy}/>
                                                        </button> : void 0}
                                                    </div>
                                                    <div>
                                                        <button
                                                            title = {Assets.Expand}
                                                            style = {{marginTop: '0px', lineHeight: '1.2'}}
                                                            className = {row.original.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}
                                                            onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {showGsidEgsid: !Main.Instance.DatabaseDataState.showGsidEgsid})}
                                                        >
                                                            {Assets.More + '...'}
                                                        </button>
                                                    </div>
                                                </div>
                                        },
                                        {
                                            sortable: false, 
                                            width: 70, 
                                            Header: <span title={`${Assets.Rounds} ${Assets.Played}`}>{Assets.Rounds} <br/> {Assets.Played}</span>, 
                                            accessor: 'roundsPlayed', 
                                            headerClassName: 'th-center', 
                                            className: 'td-center', 
                                            Cell: (row) => numberWithCommas(row.value)
                                        },
                                        ]},
                                        {
                                            sortable: false,
                                            Header: Assets.Game + ' ' + Assets.Information.toLowerCase(),
                                            headerClassName: 'th-center risk-default-light',
                                            width: 450,
                                            columns: [
                                                {
                                                    sortable: false,
                                                    width: 270, 
                                                    Header: Assets.GameName,
                                                    accessor: 'gameName',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    // Cell: (row) => getGameOnly(parseInt(row.value))
                                                },
                                                {
                                                    sortable: false,
                                                    width: 180,
                                                    Header: Assets.GameProvider,
                                                    accessor: 'providerName',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    // Cell: (row) => getGameProvider(parseInt(row.value))
                                                }
                                            ]
                                        },
/*
                                        {
                                            sortable: false, 
                                            width: 70, 
                                            Header: <span title={`${Assets.Rounds} ${Assets.Played}`}>{Assets.Rounds} <br/> {Assets.Played}</span>, 
                                            accessor: 'roundsPlayed', 
                                            headerClassName: 'th-center', 
                                            className: 'td-center', 
                                            Cell: (row) => numberWithCommas(row.value)
                                        },
*/
                                        {
                                            sortable: false,
                                            Header: Assets.RealMoney,
                                            headerClassName: 'th-center risk-default',
                                            width: 500,
                                            columns: [
                                                {
                                                    sortable: false, 
                                                    // width: 70,
                                                    Header: 
                                                        <span title={`${Assets.Total} ${Assets.Bets} ${Assets.Real}`}>
                                                            {Assets.Total} <br/> {Assets.Bets}
                                                        </span>, 
                                                    accessor: 'totalBetReal', 
                                                    headerClassName: 'th-center', 
                                                    className: 'td-center', 
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2)),
                                                    // Footer: (data) =>  Assets.Sum + ' ' + getTotal(data)
                                                },
                                                {
                                                    sortable: false, 
                                                    // width: 70, 
                                                    Header: 
                                                        <span title={`${Assets.Total} ${Assets.Win} ${Assets.Real}`}>
                                                            {Assets.Total} <br/> {Assets.Win}
                                                        </span>, 
                                                    accessor: 'totalWinReal', 
                                                    headerClassName: 'th-center', 
                                                    className: 'td-center', 
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false, 
                                                    // width: 70, 
                                                    Header: 
                                                        <span title={`${Assets.Net} ${Assets.Win} ${Assets.Real}`}>
                                                            {Assets.Net} <br/> {Assets.Win}
                                                        </span>, 
                                                    accessor: 'netWinReal',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false, 
                                                    // width: 70, 
                                                    Header: 
                                                        <span title={`${Assets.Starting} ${Assets.Balance} ${Assets.Real}`}>
                                                            {Assets.Starting} <br/> {Assets.Balance}
                                                        </span>, 
                                                    accessor: 'startingBalanceReal',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center', 
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false, 
                                                    // width: 70, 
                                                    Header: 
                                                        <span title={`${Assets.Ending} ${Assets.Balance} ${Assets.Real}`}>
                                                            {Assets.Ending} <br/> {Assets.Balance}
                                                        </span>, 
                                                    accessor: 'endingBalanceReal',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                }
                                            ]
                                        },
                                        {
                                            sortable: false,
                                            Header: Assets.BonusMoney,
                                            headerClassName: 'th-center risk-default-light',
                                            width: 500,
                                            columns: [
                                                {
                                                    sortable: false, 
                                                    // width: 70, 
                                                    Header: 
                                                        <span title={`${Assets.Total} ${Assets.Bets} ${Assets.Bonus}`}>
                                                            {Assets.Total} <br/> {Assets.Bets}
                                                        </span>, 
                                                    accessor: 'totalBetBonus',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false,  
                                                    // width: 70,
                                                    Header: 
                                                        <span title={`${Assets.Total} ${Assets.Win} ${Assets.Bonus}`}>
                                                            {Assets.Total} <br/> {Assets.Win}
                                                        </span>, 
                                                    accessor: 'totalWinBonus',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false,  
                                                    // width: 70,
                                                    Header: 
                                                        <span title={`${Assets.Net} ${Assets.Win} ${Assets.Bonus}`}>
                                                            {Assets.Net} <br/> {Assets.Win}
                                                        </span>, 
                                                    accessor: 'netWinBonus',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false,  
                                                    // width: 70,
                                                    Header: 
                                                        <span title={`${Assets.Starting} ${Assets.Balance} ${Assets.Bonus}`}>
                                                            {Assets.Starting} <br/> {Assets.Balance}
                                                        </span>, 
                                                    accessor: 'startingBalanceBonus',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                },
                                                {
                                                    sortable: false,
                                                    // width: 70,
                                                    Header: 
                                                        <span title={`${Assets.Ending} ${Assets.Balance} ${Assets.Bonus}`}>
                                                            {Assets.Ending} <br/> {Assets.Balance}
                                                        </span>, 
                                                    accessor: 'endingBalanceBonus',
                                                    headerClassName: 'th-center',
                                                    className: 'td-center',
                                                    Cell: (row) => numberWithCommas(row.value.toFixed(2))
                                                }
                                            ]
                                        },
                                    ]}
                                    showPaginationTop = {false}
                                    showPaginationBottom = {false}
                                    pageSize = {Main.Instance.PlayerCardState.gameSessionsCount < Main.Instance.PlayerCardState.gameSessionLimit ? Main.Instance.PlayerCardState.gameSessionsCount : Main.Instance.PlayerCardState.gameSessionLimit}
                                    className="-striped -highlight game-sessions"
                                />}
                            </div>
                        </>
/*
                        : Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory && Main.Instance.DatabaseDataState.sessionId && Main.Instance.DatabaseDataState.sessionTransactions ?
                        <>
                            <br/>
                            <div className = "pr clearfix register-input">
                                <button title = {Assets.DeselectSession} className = "btn btn-default btn-xs mr0" style = {{position: 'absolute', top: '-5px', right: '0px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {sessionId: undefined, gameSessions: undefined, gameSessionSelectedId: undefined, sessionTransactions: undefined})}><FontAwesomeIcon icon = {faTimes}/></button>
                                <div className = "fl mt5">{Assets.SelectedSessionId}: <span className = "label label-default label-xs mr0" onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {sessionId: undefined, gameSessions: undefined, gameSessionSelectedId: undefined, sessionTransactions: undefined})}>{Main.Instance.DatabaseDataState.sessionId}</span> {Assets.Contains.toLowerCase()}&nbsp;<span className = "badge badge-default">{Main.Instance.DatabaseDataState.sessionTransactions.length}</span>&nbsp;{Assets.Transactions.toLowerCase()}</div>
                                <div title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl ml mr5px singledatepicker">
                                <SingleDatePicker
                                    id = "date12"
                                    placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                                    date = {Main.Instance.PlayerCardState.filterDateFrom ? Main.Instance.PlayerCardState.filterDateFrom : null}
                                    onDateChange = {(date) => updateDate('filterDateFrom', date)}
                                    focused = {Main.Instance.PlayerCardState.focusedInputFrom}
                                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.PlayerCardState, {focusedInputFrom: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                />
                                </div>
                                <FormInput
                                    description = {Assets.Time + ' ' + Assets.End.toLowerCase()}
                                    veryNarrow = {true}
                                    value = {Main.Instance.PlayerCardState.filterTimeFrom ? Main.Instance.PlayerCardState.filterTimeFrom : ''}
                                    ClassName = "form-control register-input" 
                                    type = "text"
                                    onChange = {(text) => updateText(text, 'filterTimeFrom')}
                                    placeholder = {Assets.Time + ' ' + Assets.Start.toLowerCase()}
                                    Icon = {faHourglassStart}
                                />
                                <div title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl mr5px singledatepicker">
                                <SingleDatePicker
                                    id = "date13"
                                    placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                                    date = {Main.Instance.PlayerCardState.filterDateTo ? Main.Instance.PlayerCardState.filterDateTo : null}
                                    onDateChange = {(date) => updateDate('filterDateTo', date)}
                                    focused = {Main.Instance.PlayerCardState.focusedInputTo}
                                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.PlayerCardState, {focusedInputTo: focused.focused})}
                                    showDefaultInputIcon = {true}
                                    showClearDate = {true}
                                    isOutsideRange = {() => false}
                                    displayFormat = {Assets_.dateDisplayFormatdmy}
                                    customCloseIcon = {Assets_.customCloseIcon}
                                    numberOfMonths = {1}
                                />
                                </div>
                                <FormInput
                                    description = {Assets.Time + ' ' + Assets.End.toLowerCase()}
                                    veryNarrow = {true}
                                    value = {Main.Instance.PlayerCardState.filterTimeTo ? Main.Instance.PlayerCardState.filterTimeTo : ''}
                                    ClassName = "form-control register-input" 
                                    type = "text"
                                    onChange = {(text) => updateText(text, 'filterTimeTo')}
                                    placeholder = {Assets.Time + ' ' + Assets.End.toLowerCase()}
                                    Icon = {faHourglassEnd}
                                />
                            </div>
                            {Main.Instance.DatabaseDataState.sessionTransactionsFiltered.length === 0 ?
                            <div>{Assets.t2}</div>
                            :

                            <ReactTable
                                data = {Main.Instance.DatabaseDataState.sessionTransactionsFiltered}
                                filtered = {
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.Deposits ? [{id: 'type', value: 'deposit'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.Withdrawals ? [{id: 'type', value: 'withdrawal'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.Wagers ? [{id: 'type', value: 'wager'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.Wins ? [{id: 'type', value: 'win'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.WagerCancel ? [{id: 'type', value: 'wager_cancel'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.WinCancel ? [{id: 'type', value: 'win_cancel'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.BonusAdd ? [{id: 'type', value: 'bonus_add'}] :
                                    Main.Instance.PlayerCardState.navPH === PlayerCardButtonsPH.BonusRedeem ? [{id: 'type', value: 'bonus_redeem'}] :
                                    []}
                                columns = {[
                                    // {width: 40, Header: <FontAwesomeIcon icon = {faHashtag}/>, accessor: 'created', headerClassName: 'th-left', className: 'td-left', Cell: (row) => <div>{getValue(row.value, 'index')}</div>},
                                    {width: 90, Header: <span title = {Assets.Created + '/' + Assets.Started + ' ' + Assets.Timestamp + ' (' + Assets_.UTC + ')'}>{Assets.Timestamp}</span>, accessor: 'created', headerClassName: 'th-left', Cell: (row) => row.value ? 
                                    <div><div className = "lh14">{row.value.substring(0, 10)}</div><div className = "lh14">{row.value.substring(11, row.value.length)}</div></div> : '-'},
                                    {width: 120, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left'},
                                    {width: 100, Header: Assets.Amount, accessor: 'amount', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value) + ' ' + row.original.currency},
                                    // {width: 300, Header: Assets.Game + ' ' + Assets.SessionID, accessor: 'created', headerClassName: 'th-left', className: 'td-left', Cell: (row) => <div>{getValue(row.value, 'gameSessionId')}</div>},
                                    // {Header: Assets.FurtherInformation, accessor: '', headerClassName: 'th-left', Cell: (row) => 'will be implemented later'},
                                ]}
                                showPaginationTop = {true}
                                showPaginationBottom = {false}
                                defaultPageSize={10}
                            />
                            }
                        </>
*/
                        :
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.ResponsibleGaming && Main.Instance.PlayerCardState.navRG === PlayerCardButtonsRG.Overview ?
                        getOverview(true) :
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.KnowYourCustomer && Main.Instance.PlayerCardState.navKYC === PlayerCardButtonsKYC.Overview ?
                        getOverview(false) :
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions && Main.Instance.DatabaseDataState.playerTimelineTransactions === undefined ? <div>{Assets.LoadingData}</div> : 
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions && Main.Instance.DatabaseDataState.playerTimelineTransactions.length === 0 ? <div>{Assets.NoData}</div> : 
                        // EVERYTHING EXCEPT PLAYING HISTORY
                        // EVENTS TIMELINE
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline ||
                        // TRANSACTIONS
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions ||
                        // PLAYING HISTORY - SESSIONS select
                        // Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory ||

                        // ISSUES GM
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Gamification ||
                        // ISSUES RM
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.RiskManagement ||
                        // ISSUES RG
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.ResponsibleGaming/*&& Main.Instance.PlayerCardState.navRG !== PlayerCardButtonsRG.Overview*/||
                        // ISSUES KYC
                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.KnowYourCustomer/*&& Main.Instance.PlayerCardState.navKYC !== PlayerCardButtonsKYC.Overview*/
                        ?
                        <>
                            {/*
                            <button disabled = {Main.Instance.DatabaseDataState.timelinePage === 1} onClick = {() => changeTimeLinePage(true)} type = "button" className = "btn btn-default btn-sm mr0" style = {{position: 'absolute', top: '42px', right: '94px', padding: '0px', border: '0px', background: 'transparent'}}>
                                <FontAwesomeIcon icon = {faArrowCircleLeft}/>
                            </button>
                            <div style = {{position: 'absolute', right: '14px', top: '40px', width: '80px', textAlign: 'center'}} title = {Assets.PlayerTimeline + ' ' + Assets.Page.toLowerCase()}>
                                {Assets.Page.toLowerCase()}&nbsp;{Main.Instance.DatabaseDataState.timelinePage}
                            </div>
                            <button disabled = {Main.Instance.DatabaseDataState.playerTimeline && Main.Instance.DatabaseDataState.playerTimeline.length === 0} onClick = {() => changeTimeLinePage(false)} type = "button" className = "btn btn-default btn-sm mr0" style = {{position: 'absolute', border: '0px', background: 'transparent', padding: '0px', top: '42px', right: '2px'}}>
                                <FontAwesomeIcon icon = {faArrowCircleRight}/>
                            </button>
                            */}
                            {/*console.log ('isUserAdmin(): ' + isUserAdmin())*/}
                            {/*console.log ('issueType: ' + Main.Instance.PlayerCardState.navRG)*/}
                            {/*console.log (Main.Instance.DatabaseDataState.playerTimeline)*/}
                            
                            <ReactTable
                                className = "rt-no-white-space"
                                style = {{overflowX: 'hidden'}}
                                data = {Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline : []}
                                filtered = {filtered}
                                defaultFilterMethod={(filter, row) => row[filter.id].toString() === filter.value.toString()} 
                                columns = {[
                                    {
                                        Header: <span>{Assets.EventType}</span>,
                                        headerClassName: 'risk-default ',
                                        columns: [
                                            {width: 80, Header: Assets.Type + ' / ' + Assets.ID, accessor: 'entryType', headerClassName: 'th-left', Cell: (row) => 
                                            <div className = "bg-q" title = {TimelineEntryType[row.value] + (row.value === TimelineEntryType.Issue ? ' - ' + getIssueSubType(row.original.entrySubType, true) : '')}>
                                                <div className = "lh14 bold">
                                                    {row.value !== undefined && row.value !== null && TimelineEntryType[row.value] && row.value !== TimelineEntryType.Transaction ? 
                                                        TimelineEntryType[row.value].toUpperCase() :
                                                    row.value !== undefined && row.value !== null && TimelineEntryType[row.value] && row.value === TimelineEntryType.Transaction ?
                                                    <div>
                                                        <div>
                                                            {TimelineEntryType[row.value].toUpperCase().substring(0, 5) + '-'}
                                                        </div>
                                                        <div style = {{marginTop: '-2px'}}>
                                                            {TimelineEntryType[row.value].toUpperCase().substring(5, TimelineEntryType[row.value].length)}
                                                        </div>
                                                    </div>
                                                    : void 0}
                                                    {row.value === TimelineEntryType.Issue ?
                                                    <small>
                                                        &nbsp;{getIssueSubType(row.original.entrySubType)}
                                                    </small> : void 0}
                                                </div>
                                                <div className = "lh14 bold">
                                                    {row.value === TimelineEntryType.Transaction ? <div style = {{marginTop: '-2px'}}>{row.original.transactionId}</div> :
/*
                                                    row.value === TimelineEntryType.Session ? row.original.wagered > 0 ?
                                                    <button
                                                        style = {{height: '21px', marginTop: '2px'}}
                                                        disabled = {true}
                                                        type = "button"
                                                        // onClick = {() => selectSession(row.original.sessionId)}
                                                        className = {row.original.sessionId === Main.Instance.DatabaseDataState.sessionId ? 'btn btn-primary btn-xs mr0' : 'btn btn-default btn-xs mr0'}
                                                        title = {row.original.sessionId === Main.Instance.DatabaseDataState.sessionId ? Assets.SessionWithID + ' ' + row.original.sessionId + ' ' + Assets.isSelected : Assets.Select + ' ' + Assets.SessionWithID + ': ' + row.original.sessionId}
                                                    >
                                                        {row.original.sessionId === Main.Instance.DatabaseDataState.sessionId ? Assets.Selected.toLowerCase() : Assets.select}
                                                    </button>
                                                    :
                                                    <span className = "label label-danger label-xs">{Assets.NoBets}</span>
                                                    :
*/
                                                    row.value === TimelineEntryType.Issue ? row.original.id :
                                                    ''}
                                                </div>
                                            </div>},

                                                // {width: 120, Header: Assets.Transaction + ' ' + Assets.ID, accessor: 'transactiontype_', headerClassName: 'th-left', Cell: (row) => row.original.transactionId ? row.original.transactionId : '??'},
                                                // {width: 150, Header: Assets.Subtype, accessor: 'transactionType', headerClassName: 'th-left'/*, Cell: (row) => capitalizeFirstLetter(TransactionType[row.value].toLowerCase())*/},

                                        {width: 85, Header: Assets.Subtype, accessor: 'transactionType', headerClassName: 'th-left', Cell: (row) => 
                                        <div style = {{fontSize: '13px', lineHeight: '17px', height: '100%'}} className = "breakword">
                                            {/*row.original.entryType === TimelineEntryType.Session ?
                                                getSessionType(TimelineEntrySubType[row.value]) : */
                                            row.original.entryType === TimelineEntryType.Issue ?
                                                getIssueType(IssueTypes[row.original.issueType], row.original.riskLevel) :
                                            row.original.entryType === TimelineEntryType.Transaction ?
                                                getTransactionType(row.value) : ''}
                                        </div>,
                                        },
                                        ],
                                    },
                                    {
                                        Header: <span>{Assets.Timestamps}</span>,
                                        headerClassName: 'risk-default-light',
                                        columns: [
                                            {width: 90, Header: <span title = {Assets.Created + '/' + Assets.Started + ' ' + Assets.Date + ' (' + Assets_.UTC + ')'}>{Assets.Started}&nbsp;<small>{Assets_.UTC}</small></span>, accessor: 'entrySubType', headerClassName: 'th-left', Cell: (row) => row.original.created ?
                                            <div><div className = "lh14">{row.original.created.substring(0, 10)}</div><div className = "lh14">{row.original.created.substring(11, 19)}</div></div> : '-'},
                                            {width: 90, Header: <span title = {Assets.Closed + '/' + Assets.Closed + ' ' + Assets.Date + ' (' + Assets_.UTC + ')'}>{Assets.Closed}&nbsp;<small>{Assets_.UTC}</small></span>, accessor: 'transactiontype_', headerClassName: 'th-left', Cell: (row) => row.original.completed ?
                                            <div><div className = "lh14">{row.original.completed.substring(0, 10)}</div><div className = "lh14">{row.original.completed.substring(11, 19)}</div></div> : '-'},

/*
                                            {width: 90, Header: <span title = {Assets.Closed + '/' + Assets.Resolved + ' ' + Assets.Date + ' (' + Assets_.UTC + ')'}>{Assets.Closed}&nbsp;<small>{Assets_.UTC}</small></span>, accessor: 'issueType', headerClassName: 'th-left', Cell: (row) => row.original.completed && row.original.status === TransactionStatus.COMPLETED ?
                                            row.original.entrySubType === TimelineEntrySubType.OpenSession ? <div style = {{fontSize: '12px'}} className = "label label-success label-sm">{Assets.InProgress}</div> :
                                            <div><div className = "lh14">{row.original.completed.substring(0, 10)}</div><div className = "lh14">{row.original.completed.substring(11, row.original.completed.length)}</div></div> : '-'},
*/
/*
                                            {width: 90, Header: <span title = {'Created/Started Date (' + Assets_.UTC + ')'}>Date ({Assets_.UTC})</span>, accessor: 'created', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                                            {width: 70, Header: <span title = {'Created/Started Time (' + Assets_.UTC + ')'}>{Assets.Time}</span>, accessor: 'created', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length) : '-'},
*/
                                        ],
                                    },
/*
                                    {
                                        Header: <span title = {'Closed/Resolved timestamp'}>Closed/Resolved</span>,
                                        headerClassName: 'risk-default-light',
                                        columns: [
                                            {width: 90, Header: <span title = {'Closed/Resolved Date (' + Assets_.UTC + ')'}>Date ({Assets_.UTC})</span>, accessor: 'closed', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                                            {width: 70, Header: <span title = {'Closed/Resolved Time (' + Assets_.UTC + ')'}>{Assets.Time}</span>, accessor: 'closed', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length) : '-'},
                                        ]
                                    },
*/
                                    {
                                        Header: <span title = {Assets.Deposit + '/' + Assets.Withdrawal}>{Assets.Transaction}</span>,
                                        headerClassName: 'risk-default',
                                        columns: [
/*
                                            {show: !isIssue(), width: 130, Header: <span>{Assets.Operator}</span>, accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) =>
                                            row.value !== undefined ? row.value : '-' /*getRiskManager(row.value)*/// },

                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && PlayerCardButtonsET.AllIssues || !isIssue(), width: 130, Header: <span>{Assets.Status}</span>, accessor: 'transactionstatus', headerClassName: 'th-left', Cell: (row) => row.original.transactionStatus ? getTransactionStatus(row.original.transactionStatus) : '-'/*, Cell: (row) =>
                                        TransactionStatus[row.value] ? capitalizeFirstLetter(TransactionStatus[row.value].toLowerCase()) : Assets.Unknown*/},
/*
                                            {show: !isIssue(), width: 150, Header: <span>{Assets.Deposited + ' ' + Assets.Amount.toLowerCase()}</span>, accessor: 'deposited', headerClassName: 'th-left', Cell: (row) =>
                                            row.original.entrySubType === TimelineEntrySubType.Deposit || row.original.entrySubType === TimelineEntrySubType.ManualDeposit ?
                                            row.original.amount ? numberWithCommas(row.original.amount) + ' ' + row.original.currency : '-' :
                                            row.value ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},

                                            {show: !isIssue(), width: 150, Header: <span>{Assets.Withdrawn + ' ' + Assets.Amount.toLowerCase()}</span>, accessor: 'withdrawn', headerClassName: 'th-left', Cell: (row) =>
                                            row.original.entrySubType === TimelineEntrySubType.Withdraw || row.original.entrySubType === TimelineEntrySubType.ManualWithdraw ?
                                            row.original.amount ? numberWithCommas(row.original.amount) + ' ' + row.original.currency : '-' :
                                            row.value ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},
*/

                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && PlayerCardButtonsET.AllIssues || !isIssue(), width: 145, Header: <span>{Assets.Amount}</span>, accessor: 'amount', headerClassName: 'th-left', Cell: (row) =>
                                            row.original.amount ? numberWithCommas(row.original.amount) + ' ' + row.original.currency : '-'},


                                            {show: !isIssue() && Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline, width: 145, Header: <span>{Assets.BalanceBefore}</span>, accessor: 'balanceBefore', headerClassName: 'th-left', Cell:
                                            (row) => row.value !== undefined && row.value !== null ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},

                                            {show: !isIssue() && Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline, width: 145, Header: <span>{Assets.BalanceAfter}</span>, accessor: 'balanceAfter', headerClassName: 'th-left', Cell:
                                            (row) => row.value !== undefined && row.value !== null ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},
                                        ],
                                    },
                                    {
                                        Header: <span>{Assets.Transaction + ' ' + Assets.Information.toLowerCase() + ' ('+ Assets.Basic + ')'}</span>,
                                        headerClassName: 'risk-default-light',
                                        columns: [
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 67, Header: <span title = {Assets.Error + ' ' + Assets.Code.toLowerCase()}>{Assets.Error}</span>, accessor: 'info.errCode', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? getError(row) : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 67, Header: <span title = {Assets.Extended + ' ' + Assets.Error.toLowerCase() + ' ' + Assets.Code.toLowerCase()}>{Assets.Extended.substring(0,6)}.</span>, accessor: 'info.exErrCode', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? getError(row) : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 67, Header: <span title = {Assets.Reason + ' ' + Assets.Code.toLowerCase()}>{Assets.Reason}</span>, accessor: 'info.reasonCode', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? getError(row) : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 161, Header: <span title = {Assets.Reason + ' ' + Assets.Description.toLowerCase()}>{Assets.Reason + ' ' + Assets.Description.toLowerCase()}</span>, accessor: 'info.reason', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => row.value !== undefined && row.value.length > 0 ? <p style = {{wordBreak: 'break-word', marginBottom: '0px'}}>{row.value}</p> : '-'},
                                        ]
                                    },
                                    {
                                        Header: <span>{Assets.Transaction + ' ' + Assets.Information.toLowerCase() + ' ('+ Assets.Extended + ')'}</span>,
                                        headerClassName: 'risk-default',
                                        columns: [

                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 100, Header: <span title = {Assets.Card + ' ' + Assets.Company.toLowerCase()}>{Assets.Card}</span>, accessor: 'info.cardCompany', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 100, Header: <span title = {Assets.Card + ' ' + Assets.Type.toLowerCase()}>{Assets.Card + ' ' + Assets.Type}</span>, accessor: 'info.cardType', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 80, Header: <span title = {Assets.Payment + ' ' + Assets.Method.toLowerCase()}>{Assets.Payment}</span>, accessor: 'info.paymentMethod', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 200, Header: <span title = {Assets.Country}>{Assets.Country}</span>, accessor: 'info.country', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                            
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 100, Header: <span title = {Assets.Order + ' ' + Assets.Transaction.toLowerCase() + Assets.ID}>{Assets.Order + ' ' + Assets.ID}</span>, accessor: 'info.orderTransactionId', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                            {show: Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions, width: 200, Header: <span title = {Assets.Transaction.toLowerCase() + Assets.ID}>{Assets.Transaction + ' ' + Assets.ID}</span>, accessor: 'info.transactionID', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value : '-'},
                                        ]
                                    },
/*
                                    {
                                        Header: <span title = {Assets.Wagered + '/' + Assets.Won + '/' + Assets.Net}>{Assets.WageringSummary}</span>,
                                        headerClassName: 'risk-default-light',
                                        columns: [
                                            {show: !isIssue(), width: 80, Header: <span>{Assets.Wagered}</span>, accessor: 'wagered', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},
                                            {show: !isIssue(), width: 80, Header: <span>{Assets.Won}&nbsp;<small>{Assets.Gross}</small></span>, accessor: 'won', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},
                                            {show: !isIssue(), width: 80, Header: <span>{Assets.Won}&nbsp;<small>{Assets.Net}</small></span>, accessor: 'total', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? numberWithCommas(row.value) + ' ' + row.original.currency : '-'},
                                        ],
                                    },
*/
                                    {

                                        show: Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline ||
                                        Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline &&
                                        (Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Everything
                                        || Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.AllIssues
                                        || Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.RiskManagement
                                        || Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.ResponsibleGaming
                                        || Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.KnowYourCustomer
                                        || Main.Instance.PlayerCardState.navET === PlayerCardButtonsET.Gamification
                                        )
                                        ,
                                        Header: <span>{Assets.IssueControlPanel}</span>,
                                        headerClassName: Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline && PlayerCardButtonsET.AllIssues || !isIssue() ? 'risk-default-light' : 'risk-default',
                                        columns: [
                                            {show: Main.Instance.PlayerCardState.nav !== PlayerCardButtons.EventsTimeline && isIssue(), width: 100, Header: <span>{Assets.AssignedTo}</span>, accessor: 'assignTo', headerClassName: 'th-left', Cell: (row) => <span>{row.value && row.value.length > 0 ? <span><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManagers(row.value)}</span> : '-'}</span>},
                                            {show: isIssue(), width: 25, Header: <FontAwesomeIcon title = {Assets.Manual + ' ' + Assets.Issue.toLowerCase()} icon = {faUser}/>, accessor: 'history', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.original.entryType === TimelineEntryType.Issue ? getManual(row.value, row.original.riskManagerId) : '-'}</div>},
                                            {show: isIssue(), width: 161, Header: <span /*title = {Assets.RFSpecialist}*/>{Assets.LastIssueUpdate}</span>, accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) => row.value !== undefined && row.value !== null ? row.value === 0 ? <span title = {Assets.ThisIssueWasBroughtUpAutomatically}><FontAwesomeIcon icon = {faLaptopCode}/>&nbsp;&nbsp;{Assets.AutomaticIssue}</span> : <span><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManager(row.value)}</span> : '-'},
                                            // {width: 150, Header: <span title = {Assets.RiskManager}>{Assets.RiskManager}</span>, accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) => row.value !== undefined ? row.value === 0 ? <span title = {Assets.ThisIssueWasBroughtUpAutomatically}><FontAwesomeIcon icon = {faLaptopCode}/>&nbsp;&nbsp;{Assets.AutomaticIssue}</span> : row.value : '-'},
                                            {show: isIssue(), width: 110, Header: <span>{Assets.RiskLevel}</span>, accessor: 'riskLevel', headerClassName: 'th-left', Cell: (row) => row.value ? 
                                            <button 
                                                className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                            >
                                                {RiskLevel[row.value]}
                                                {riskLevelSlider(10, row.index, row.value, true)}
                                            </button>
                                            : '-'},
                                            {show: isIssue(), Header: Assets.Comment, accessor: 'issueType', className: 'no-white-space2', headerClassName: 'th-left', Cell: (row) => row.original.comment ? <p title = {row.original.comment}>{row.original.comment}</p> : '-'/*, Cell: (row) => row.value ? isIssue() ? row.value : <span title = {row.value.toString()} style = {{fontSize: '12px'}} className = "label label-default label-xs">{row.value.toString().length > 6 ? row.value.toString().substring(0, 6) + '...' : row.value.toString().substring(0,5)}</span> : '-'*/},
                                            {width: 100, show: isIssue(), Header: Assets.Alert + ' ' + Assets.Code.toLowerCase(), accessor: 'alertCode', className: 'no-white-space2', headerClassName: 'th-left', Cell: (row) => row.value ? <p title = {row.value}>{row.value}</p> : '-'},
                                            // {width: 65, show: isIssue(), Header: Assets.Alert + ' ' + Assets.ID, accessor: 'alertInstanceId', className: 'no-white-space2', headerClassName: 'th-left', Cell: (row) => row.value ? <p title = {row.value}>{row.value}</p> : '-'},
                                            /*{width: 80, Header: Assets.Image, accessor: 'data.image', headerClassName: 'th-center', Cell: (row) => row.value === undefined || row.original.entryType !== TimelineEntryType.Issue ? '' : <img className = "img img-responsive" width = {70} src = {'data:image/png;base64,' + row.value}/>},*/
                                            {show: isIssue(), width: 100, Header: 
                                            <div>
                                                <span className = "fl">{Assets.Details}</span>
                                                <button 
                                                    className = "dbl fr btn btn-xs btn-default btn-nopadding mr0"
                                                    style = {{border: 'none'}}
                                                    title = {Assets.Issue + ' ' + Assets.Details.toLowerCase() + ' ' + Assets.Info.toLowerCase()} 
                                                    onClick = {() => {
                                                        const toggleInfo = !Main.Instance.PlayerCardState.toggleInfo
                                                        Logic.Type.New(Main.Instance.PlayerCardState, {toggleInfo})
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon = {faInfoCircle}/>
                                                </button>
                                            </div>, accessor: 'data', headerClassName: 'th-center', className: 'no-white-space2', Cell: (row) => <p title = {row.value ? filterDataByRights(row.value) : ''}>{row.value ? filterDataByRights(row.value) : '-'}</p>},
                                            {show: isIssue(), width: 40, Header: <FontAwesomeIcon title = {Assets.New} icon = {faMagic}/>, accessor: 'history', headerClassName: 'th-center', Cell: (row) => row.original.entryType !== TimelineEntryType.Issue ? '-' : TBN(row.value === undefined || row.value.length === 0 ? true : false)},
                                            {show: isIssue(), width: 60, Header: Assets.History, accessor: 'history', headerClassName: 'th-left', Cell: (row) => row.value ? <button title = {row.value === undefined || row.value.length === 0 ? Assets.NoHistory : ''} disabled = {row.value === undefined || row.value.length === 0} type = "button" className = "btn btn-info btn-xs mr0" onClick = {() => Logic.Type.New(Main.Instance.RiskManagementState, {mode: false, rowId: row.index, history: row.value, selectedPage: row.page})}>{Assets.View}</button> : '-'},
                                            {show: isIssue(), width: 80, Header: Assets.Action, accessor: 'resolved', headerClassName: 'th-left', Cell: (row) => row.value !== undefined && row.value !== null ? row.value ? /*row.original.issueType === IssueTypes.UploadDocument || row.original.issueType === IssueTypes.UploadUtility ||*/ row.original.issueType === IssueTypes.VerifiedDocument || row.original.issueType === IssueTypes.VerifiedUtility ? 
                                                <button 
                                                    disabled = {isUserProhibitedToResolve(row)} 
                                                    onClick = {() => {
                                                        Logic.Type.New(Main.Instance.RiskManagementState, {mode: false, rowId: row.index, showAddCSCallbackModal: true, selectedPage: row.page})
                                                    }} 
                                                    type = "button" 
                                                    className = "btn btn-info btn-xs mr0"
                                                >
                                                    {Assets.Uploaded}
                                                </button> : 
                                                <button 
                                                    title = {Assets.ThisIssueWasAlreadyResolved} 
                                                    disabled = {isUserProhibitedToResolve(row)} 
                                                    onClick = {() => {
                                                        Logic.Type.New(Main.Instance.RiskManagementState, {mode: false, rowId: row.index, showAddCSCallbackModal: true, selectedPage: row.page}); gotoTop()
                                                    }} 
                                                    type = "button" 
                                                    className = "btn btn-danger btn-xs mr0"
                                                >
                                                    {Assets.Reopen}
                                                </button> :
                                                <button 
                                                    disabled = {isUserProhibitedToResolve(row)} 
                                                    onClick = {() => {
                                                        Logic.Type.New(Main.Instance.RiskManagementState, {mode: false, rowId: row.index, showAddCSCallbackModal: true, selectedPage: row.page}); gotoTop()
                                                    }} 
                                                    type = "button" 
                                                    className = "btn btn-warning btn-xs mr0"
                                                >
                                                    {Assets.Resolve}
                                                </button> : 
                                                '-'
                                            },
                                        ],
                                    },
                                    /*
                                    {
                                        Header: 'Timestamps',
                                        headerClassName: 'risk-default',
                                        columns: [

                                            {width: 90, Header: 'Date (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                                            {width: 100, Header: 'Time (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length - 1) : '-'},
                                        ]
                                    },
                                        Header: 'Response',
                                        headerClassName: 'risk-manager',
                                        columns: [
                                            // {width: 50, Header: <div title = "User ID">User</div>, accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) => row.value ? row.value : '-'},
                                            {width: 110, Header: Assets.Username, accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) => row.value ? getName(row.value) : '-'},
                                            {width: 100, Header: 'Role', accessor: 'riskManagerId', headerClassName: 'th-left', Cell: (row) => row.value ? <span>{getRole(row.value)}</span> : '-'},
                                            {width: 90, Header: 'Date (' + Assets_.UTC + ')', accessor: 'resolvedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                                            {width: 100, Header: 'Time (' + Assets_.UTC + ')', accessor: 'resolvedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length - 1) : '-'},
                                            {width: 80, Header: 'Resolved', accessor: 'resolved', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{!row.value ? isUserAdmin() || isUserRFSpecialist() ? <button className = "btn btn-warning btn-xs" onClick = {() => row.original.ID === 'KYC' ? Logic.Type.New(Main.Instance.RiskManagementState, {rowId: row.index, showAddKYCModal: true}) : row.original.ID === 'CSC' ? Logic.Type.New(Main.Instance.RiskManagementState, {rowId: row.index, showAddCSCallbackModal: true}) : row.original.ID === 'ER' ? Logic.Type.New(Main.Instance.RiskManagementState, {rowId: row.index, showAddRiskAlertsModal: true}) : void 0}>Resolve</button> : <div>Pending</div> : <div>Resolved</div>}</div>},
                                        ]
                                    },
                                    {
                                        Header: 'Risk Level',
                                        headerClassName: 'risk-default',
                                        columns : [
                                        {width: 110, Header: 'Previous', accessor: 'previousRiskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        row.value ?
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(8, row.index, row.value, true)}
                                        </button> : '-'},
        
                                        {width: 110, Header: 'Current', accessor: 'currentRiskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        row.value ?
                                        <button 
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(9, row.index, row.value, true)}
                                        </button> : '-'},
                                        {width: 45, Header: <><i className = {Assets_.faFirstButtonClass}/><i className = {Assets_.faLastButtonClass}/></>,
                                        accessor: '', headerClassName: 'th-left', Cell: (row) =>
                                        row.original.currentRiskLevel && row.original.previousRiskLevel ?
                                        <span
                                            className = {row.original.currentRiskLevel === row.original.previousRiskLevel ? 'label label-default label-sm' : row.original.currentRiskLevel - row.original.previousRiskLevel > 0 ? 'label label-default label-sm' : 'label label-default label-sm'} 
                                        >{row.original.currentRiskLevel === row.original.previousRiskLevel ? <FontAwesomeIcon icon = {faCircle}/> :
                                        row.original.currentRiskLevel - row.original.previousRiskLevel > 0 ? <i className = {Assets_.faFirstButtonClass}/> : <i className = {Assets_.faLastButtonClass}/>}
                                        {row.original.currentRiskLevel === row.original.previousRiskLevel ? void 0 :
                                        <span style = {{lineHeight: '14px', fontSize: '12px'}}>{Math.abs(row.original.currentRiskLevel - row.original.previousRiskLevel)}</span>}
                                        </span> : '-'
                                        }
                                        ]
                                    },
                                    {
                                        columns: [
                                            {width: 20, show: isUserGDPR(), Header: <FontAwesomeIcon title = "Attached mobile number" icon = {faMobileAlt}/>, accessor: 'phoneNumber', headerClassName: 'th-left', Cell: (row) => <div>{row.value ? <FontAwesomeIcon title = {'Mobile number: ' + row.value} icon = {faMobileAlt}/> : '-'}</div>},
                                            {width: 25, show: isUserGDPR(), Header: <FontAwesomeIcon title = "Files uploaded" icon = {faFileImage}/>, accessor: 'files', headerClassName: 'th-left', Cell: (row) => <div>{row.value ? <FontAwesomeIcon title = {'Files: ' + row.value.length} icon = {faFileImage}/> : void 0}</div>},
                                            {width: 25, show: isUserGDPR(), Header: <FontAwesomeIcon title = "Validity" icon = {faCheck}/>, accessor: 'isValid', headerClassName: 'th-left', Cell: (row) => row.original.ID !== 'KYC' ? '-' : <div>{row.original.resolved === false ? <FontAwesomeIcon title = "Unresolved" icon = {faQuestion}/> : row.value ? <FontAwesomeIcon title = "Valid" icon = {faCheck}/> : <FontAwesomeIcon title = "Invalid" icon = {faTimes}/>}</div>},
                                            {Header: <button onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {showComments: !Main.Instance.PlayerCardState.showComments})}>{Main.Instance.PlayerCardState.showComments ? 'Comments' : <FontAwesomeIcon icon = {faComments}/>}</button>, accessor: 'comments', headerClassName: 'th-left', Cell: (row) => <div>{Main.Instance.PlayerCardState.showComments ? getComments(row.value) : <FontAwesomeIcon icon = {faEllipsisH}/>}</div>}
                                    ]}
*/
                                ]}
                                // showPaginationTop = {false}
                                showPaginationTop = {true}
                                showPaginationBottom = {false}
                                defaultPageSize = {10}
                                onPageChange={(pageIndex) => { 
                                    Logic.Type.New(Main.Instance.RiskManagementState, {selectedPage: pageIndex})
                                }}
                                
                                page={Main.Instance.RiskManagementState.selectedPage}
                                // pageSize={Main.Instance.DatabaseDataState.playerTimeline ? Main.Instance.DatabaseDataState.playerTimeline.length : 0}
                            />
                        </> : void 0}
                        </div>
                        </>}
                    </div>
                </div> 
            </div> : void 0}
        </div>
    )
}
/*
function toggleGameSessionSelection(gameSessionId: string) {
    // console.log (gameSessionId)
    const fgs = Object.assign([], Main.Instance.PlayerCardState.fgs)
    for (const item of fgs) {
        if (item.gameSessionId === gameSessionId) {
            item.selected = !item.selected
            // console.log ('found')
            break
        }
    }
    Logic.Type.New(Main.Instance.PlayerCardState, {fgs})
    filterSessionTransactions(fgs)
}
*/
function getIssueTypesInsert(): JSX.Element[] {
    const iti: JSX.Element[] = []
    // console.log (Object.keys(IssueTypesInsert))
    for (let i: number = 0; i < Object.keys(IssueTypesInsert).length / 2; i++) {
        const position: number = Object.keys(IssueTypesInsert).length / 2 + i
        // console.log (position)
        iti.push(<button /*disabled = {Config.environment === Config.Environment.PRODUCTION && (i === 3 || i === 4)}*/ style = {{marginLeft: '-1px'}} onClick = {() => toggleIssueType(parseInt(Object.keys(IssueTypesInsert)[i]))} className = {Main.Instance.DatabaseDataState.newIssue.issueType === parseInt(Object.keys(IssueTypesInsert)[i]) ? i === IssueTypesInsert.SelectIssueType as number ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : (Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number || Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadDocument as number || Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadUtility as number) && i === 6 ? 'btn btn-default btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'} key = {i.toString()} type = "button">{injectSpaces(Object.keys(IssueTypesInsert)[position])}</button>)
    }
    return iti
}

function toggleIssueType(issueType: number) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.issueType = issueType
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function getAssignTo(): JSX.Element[] {
    const ass: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.newIssue.assignTo && Main.Instance.DatabaseDataState.newIssue.assignTo.length > 0) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.newIssue.assignTo.length; i++) {
            ass.push (<button onClick = {() => deselect(i)} key = {Main.Instance.DatabaseDataState.newIssue.assignTo[i].toString()} className = "btn btn-default btn-xs">{getRiskManager(Main.Instance.DatabaseDataState.newIssue.assignTo[i])}</button>)
        }
    } else { ass.push (<span className = "mr">{Assets.Nobody}</span>) }
    ass.push (<span className = "mr" key = "span">{Assets.AssignTo}</span>)
    ass.push (<select key = "select" onChange = {(e) => select(+e.target.value)} value = {Main.Instance.DatabaseDataState.assignee} className = "mr">{getOptionsAssignee()}</select>)
    ass.push (<button disabled = {Main.Instance.DatabaseDataState.assignee === 0} key = "button" onClick = {() => add()} className = "btn btn-primary btn-xs">{Assets.AddAssignee}</button>)
    return ass    
}

function select(assignee: number) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {assignee})
}

function deselect(i: number) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    // const assignTo = Object.assign ([], Main.Instance.DatabaseDataState.assignTo)
    newIssue.assignTo.splice (i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {assignee: 0, newIssue})
}

function add() {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    // const assignTo = Object.assign ([], Main.Instance.DatabaseDataState.assignTo)
    newIssue.assignTo.push (Main.Instance.DatabaseDataState.assignee)
    // Logic.Type.New(Main.Instance.DatabaseDataState,{assignTo, assignee: 0})
    Logic.Type.New(Main.Instance.DatabaseDataState, {assignee: 0, newIssue})
}

function isAlreadyThere(user_id: number): boolean {
    if (Main.Instance.DatabaseDataState.newIssue.assignTo) { for (const item of Main.Instance.DatabaseDataState.newIssue.assignTo) { if (item === user_id) { return true } } }
    return false
}

function getOptionsAssignee(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "0" value = "0">{Assets.SelectAssignee}</option>)
    for (const item of Main.Instance.LogState.distinctUserId) {
        options.push (<option disabled = {isAlreadyThere(item.user_id)} key = {item.user_id} value = {item.user_id}>{item.username}</option>)
    }
    return options
}

function changeWithdrawBalance(withdrawBalance: number) {
    if (withdrawBalance > 0) {
        const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
        newIssue.data.withdrawBalance = withdrawBalance
        Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
    }
}

function ManualCrif(): JSX.Element {
    return (
        <div className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => verifyCrifManually()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2>{Assets.ManualCrifCheck}&nbsp;&nbsp;<small><FontAwesomeIcon icon = {faUser}/>&nbsp;{isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}</small></h2>
            <div className = "clearfix mt2">
                <div className = "fl">
                    <div className = "clearfix">
                        <input style = {{marginTop: '4px', marginRight: '5px'}} type = "checkbox" checked = {Main.Instance.DatabaseDataState.manualPEP} onChange = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {manualPEP: !Main.Instance.DatabaseDataState.manualPEP})} className = "dbl fl"/>
                        <div className = "fl">{Assets.CheckPEPManually}</div>
                    </div>
                    <div className = "clearfix">
                        <input style = {{marginTop: '4px', marginRight: '5px'}} type = "checkbox" checked = {Main.Instance.DatabaseDataState.manualAddress} onChange = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {manualAddress: !Main.Instance.DatabaseDataState.manualAddress})} className = "dbl fl"/>
                        <div className = "fl">{Assets.CheckAddressManually}</div>
                    </div>
                </div>
                <button style = {{marginTop: '5px'}} onClick = {() => DbRoutes.checkCrif(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.DatabaseDataState.manualPEP, Main.Instance.DatabaseDataState.manualAddress)} disabled = {!Main.Instance.DatabaseDataState.manualAddress && !Main.Instance.DatabaseDataState.manualPEP} type = "button" className = "btn btn-warning btn-sm mr0 dbl fl ml">{Assets.CheckCrif}</button>
            </div>
            
            {Main.Instance.DatabaseDataState.manualCrifResolved ?
            <div>
                {Main.Instance.DatabaseDataState.manualPEP && Main.Instance.DatabaseDataState.manualCrifResponsePEP ? 
                <div>
                    <hr/>
                    <div style = {{width: '260px',fontSize: '16px'}} className = {
                        Main.Instance.DatabaseDataState.manualCrifResponsePEP.decision === 'GREEN' ?
                        'dbl label label-success label-sm' :
                        Main.Instance.DatabaseDataState.manualCrifResponsePEP.decision === 'RED' ||
                        Main.Instance.DatabaseDataState.manualCrifResponsePEP.decision === 'DARK_RED' ?
                        'dbl label label-danger label-sm' :
                        'dbl label label-warning label-sm'}
                    >
                        {Assets.Decision}:&nbsp;{Main.Instance.DatabaseDataState.manualCrifResponsePEP.decision}
                    </div>
                    <br/>
                    <p style = {{padding: '5px', border: '#999', background: '#ddd', color: '#333'}}>
                        {JSON.stringify(Main.Instance.DatabaseDataState.manualCrifResponsePEP)}
                    </p>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '260px', background: Main.Instance.DatabaseDataState.isPEP ? '#d9534f' : '#5cb85c', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                            {Main.Instance.DatabaseDataState.isPEP ? Assets.PoliticallyExposedPerson : Assets.NotAPoliticallyExposedPerson}
                        </div>
                        <button onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {isPEP: !Main.Instance.DatabaseDataState.isPEP})} type = "button" className = "btn btn-warning btn-xs mr0 ml dbl fl">{Assets.Change}</button>
                    </div>
                    <br/>
                    <button
                        onClick = {() => insertANewIssue(false)}
                        className = "btn btn-danger btn-sm dbl fl mr0"
                    >
                        {Assets.InsertANewIssue}
                    </button>
                </div> : void 0}
                {Main.Instance.DatabaseDataState.manualAddress && Main.Instance.DatabaseDataState.manualCrifResponseAddress ? 
                <div>
                    <hr/>
                    <div style = {{width: '260px',fontSize: '16px'}} className = {
                        Main.Instance.DatabaseDataState.manualCrifResponseAddress.character === 'IDENTITY' ?
                        'dbl label label-success label-sm' :
                        Main.Instance.DatabaseDataState.manualCrifResponseAddress.character === 'NOT_CONFIRMED' ?
                        'dbl label label-danger label-sm' :
                        'dbl label label-warning label-sm'}
                    >
                        {Assets.Character}:&nbsp;{Main.Instance.DatabaseDataState.manualCrifResponseAddress.character}
                    </div>
                    <br/>
                    <p style = {{padding: '5px', border: '#999', background: '#ddd', color: '#333'}}>
                        {JSON.stringify(Main.Instance.DatabaseDataState.manualCrifResponseAddress)}
                    </p>
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '260px', background: Main.Instance.DatabaseDataState.isAddressVerified ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                            {Main.Instance.DatabaseDataState.isAddressVerified ? Assets.AddressVerified : Assets.AddressNotVerified}
                        </div>
                        <button onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {isAddressVerified: !Main.Instance.DatabaseDataState.isAddressVerified})} type = "button" className = "btn btn-warning btn-xs mr0 ml dbl fl">{Assets.Change}</button>
                    </div>
                    <br/>
                    <button
                        onClick = {() => insertANewIssue(true)}
                        className = "btn btn-danger btn-smdbl fl mr0"
                    >
                        {Assets.InsertANewIssue}
                    </button>
                </div> : void 0}
            </div> : 
            Main.Instance.DatabaseDataState.manualCrifError ?
            <div>
                <br/>
                {Assets.errorWhileCrif}
            </div> : void 0}
        </div>
    )
}

function ResendVerificationEmail(): JSX.Element {
    return (
    <div className = "pr" style = {{padding: '0px 20px'}}>
        <button onClick = {() => toggleResendVerificationEmail()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
            <FontAwesomeIcon icon = {faTimes}/>
        </button>
        <h2>{Assets.ResendVerificationEmail}&nbsp;&nbsp;<small><FontAwesomeIcon icon = {faUser}/>&nbsp;{isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}</small></h2>
        {/*getImportantInformation(4, true)*/}
        <div className = "clearfix mt2 pr">
            <div style = {getStyle('email')} className = {'label button-default w50px fl'}>{Assets.Email}</div>
            <div className = "ml3 fl">
                <input style = {{width: '300px'}} disabled = {!canBeModifiedByAdmin('email')} onChange = {(e) => changeText('email', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.email} className = "pcti" type = "text"/>
            </div>
            {!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.isEmailVerified, 'activity-image3', {position: 'absolute', top: '7px', left: '62px'}, Assets.EmailVerified)}
            <button onClick = {() => resendVerificationEmail()} disabled = {Main.Instance.DatabaseDataState.manualResendVerification} /*disabled = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 || Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance}*/ type = "button" className = {/*Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 ? 'btn btn-default btn-sm mt' : Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance ? 'btn btn-danger btn-sm mt' :*/ 'btn btn-warning btn-xs ml dbl fl'}>
                {Assets.ResendVerificationEmail}
            </button>
        </div>
        {/* .withdrawBalance*/}
        {/**/}
        {/*
        <div className = "clearfix mt2 pr">
            <div style = {getStyle('withdrawBalance')} className = "label button-default w130px fl">{Assets.WithdrawBalance}</div>
            <div className = "ml3 fl clearfix">
                <input style = {{fontWeight: 700, width: '149px'}} disabled = {false} onChange = {(e) => changeWithdrawBalance(+e.target.value)} value = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance} className = "pcti fl" type = "number"/>
                <input style = {{width: '35px', marginRight: '3px', marginLeft: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>
                <button
                    type = "button"
                    onClick = {() => changeWithdrawBalance(Main.Instance.DatabaseDataState.playerCard.realBalance)}
                    disabled = {Main.Instance.DatabaseDataState.playerCard.realBalance === Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance}
                    className = {Main.Instance.DatabaseDataState.playerCard.realBalance === Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance ? 'btn btn-xs btn-default' : 'btn btn-xs btn-danger'}
                >
                    {Assets.CopyWholeRealBalance}
                </button>
            </div>
        </div>
        */}
        {/*
        <div className = "mt5">
            <div className = "clearfix">
                <div className = "fl" style = {{fontSize: '12px', marginTop: '5px'}}>{Assets.Comment + ':'}</div>
                <div className = "fr">
                    <button onClick = {() => InsertNewIssueComment(Assets.ThisIssueWasResolvedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.')} type = "button" className = "btn btn-default btn-xs mr0 br0">
                        {Assets.Default + ' ' + Assets.Comment}
                    </button>
                </div>
            </div>
            <textarea onChange = {(e) => InsertNewIssueComment(e.target.value)} style = {{marginTop: '-1px', resize: 'none', width: '100%', height: '50px'}} value = {Main.Instance.DatabaseDataState.newIssue.comment ? Main.Instance.DatabaseDataState.newIssue.comment : ''}/>
        </div>
        */}
    </div>
    )
}
/*
function togglePEP() {
    const playerCard: Player = Object.assign ({}, Main.Instance.DatabaseDataState.playerCard)
    playerCard.isPEP = !playerCard.isPEP
    Logic.Type.New(Main.Instance.DatabaseDataState, {playerCard})
}
*/

function resendVerificationEmail() {
    // MISSING
    DbRoutes.resendVerificationEmail(Main.Instance.DatabaseDataState.playerCard.playerId)
}

function ManualDeposit(): JSX.Element {
    return (
        <div className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => toggleManualDeposit()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2>{Assets.Manual + ' ' + Assets.Deposit.toLowerCase()}&nbsp;&nbsp;<small><FontAwesomeIcon icon = {faUser}/>&nbsp;{isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}</small></h2>
            {getImportantInformation(9)}
            <div className = "clearfix mt2 pr">
                <div style = {getStyle('withdrawBalance')} className = "label button-default w130px fl">{Assets.Deposit}</div>
                <div className = "ml3 fl clearfix">
                    <input style = {{fontWeight: 700, width: '149px'}} disabled = {false} onChange = {(e) => changeWithdrawBalance(+e.target.value)} value = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance} className = "pcti fl" type = "number"/>
                    <input style = {{width: '35px', marginRight: '3px', marginLeft: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>
                </div>
            </div>

            <div className = "mt5 clearfix">
                <input
                    className = "fl dbl"
                    type = "checkbox"
                    checked = {Main.Instance.DatabaseDataState.newIssue.data.bypassRisk}
                    onChange = {() => {
                        const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
                        newIssue.data.bypassRisk = !newIssue.data.bypassRisk
                        Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
                    }}
                />
                <span
                    className = "fl dbl"
                    style = {{fontSize: '14px', marginTop: '1px', marginLeft: '4px', marginRight: '10px'}}
                >
                    {Assets.Bypass + ' ' + Assets.Risk.toLowerCase()}
                </span>
                {/*
                <input
                    className = "fl dbl"
                    type = "checkbox"
                    checked = {Main.Instance.DatabaseDataState.newIssue.data.bypassBonus}
                    onChange = {() => {
                        const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
                        newIssue.data.bypassBonus = !newIssue.data.bypassBonus
                        Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
                    }}
                />
                <span
                    className = "fl dbl"
                    style = {{fontSize: '14px', marginTop: '1px', marginLeft: '4px'}}
                >
                    {Assets.Bypass + ' ' + Assets.Bonus.toLowerCase()}
                </span>
                */}
            </div>
            <div className = "mt5">
                <div className = "clearfix">
                    <div className = "fl" style = {{fontSize: '12px', marginTop: '5px'}}>{Assets.Comment + ':'}</div>
                    <div className = "fr">
                        <button onClick = {() => InsertNewIssueComment(Assets.ThisIssueWasResolvedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.')} type = "button" className = "btn btn-default btn-xs mr0 br0">
                            {Assets.Default + ' ' + Assets.Comment}
                        </button>
                    </div>
                </div>
                <textarea onChange = {(e) => InsertNewIssueComment(e.target.value)} style = {{marginTop: '-1px', resize: 'none', width: '100%', height: '50px'}} value = {Main.Instance.DatabaseDataState.newIssue.comment ? Main.Instance.DatabaseDataState.newIssue.comment : ''}/>
            </div>

            <button disabled = {Main.Instance.DatabaseDataState.newIssue.comment === undefined || Main.Instance.DatabaseDataState.newIssue.comment.length === 0 || Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 /*|| Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance*/} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 ? 'btn btn-default btn-sm mt' : /*Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance ? 'btn btn-danger btn-sm mt' :*/ 'btn btn-warning btn-sm mt'} onClick = {() => insertNewIssue(true)}>
                {Assets.InsertANewManualDeposit}
            </button>
        </div>
    )
}

function selectUploadedFile(row) {
    // fetch
    const split: string[] = row.original.name.split('/')
    // console.log (split)
    // console.log (row.original.name)
    const selectedUploadIsImage: boolean = !(row.original.name && row.original.name.endsWith('.pdf'))
    // console.log (selectedUploadIsImage)

    const args = {
        method: 'POST',
        body: JSON.stringify({file: split[1], folderName: split[0]}),
        headers: {'Content-Type' : 'application/json', 'api-key': 'burek'
    }}
    // console.log (Config.uDownload)
    // console.log (args)
    fetch(Config.uDownload, args).then((response) => {
        // console.log (response)
        response.blob().then((response2) => {
            const selectedUpload: string = URL.createObjectURL(response2)
            if (selectedUploadIsImage) {
                const image = new Image()
                // console.log (Main.Instance.DatabaseDataState.selectedUpload)
                image.src = selectedUpload
                // console.log ('image')
                image.onload = () => {
                    // console.log ('image loaded')
                    const element = document.getElementById ('ShowUploadsContainer')
                    if (element) {
                        // console.log (image.width)
                        // console.log (element.clientWidth)
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {containerWidth: element.clientWidth, imageWidth: image.width}) 
                                    
                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            containerWidth: element.clientWidth,
                            imageWidth: image.width,
                            selectedUploadIsImage,
                            uploadedSelectedName: row.original.name,
                            uploadedSelectedContentType: row.original.contentType,
                            selectedUpload: row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? undefined : selectedUpload,
                            uploadedSelectedPosition: row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? -1 : row.index
                        })
                    }
                }
            } else {
                Logic.Type.New (Main.Instance.DatabaseDataState, {
                    selectedUploadIsImage,
                    uploadedSelectedName: row.original.name,
                    uploadedSelectedContentType: row.original.contentType,
                    selectedUpload: row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? undefined : selectedUpload,
                    uploadedSelectedPosition: row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? -1 : row.index
                })
            }
        })
    })
}


function ShowUploads(): JSX.Element {
    const filteredPdfImage: Filtered[] = []
    const filteredPdfImage2: Filtered[] = []
    const filtered: any[] = []
    if (Main.Instance.DatabaseDataState.pdfImage === true) {
        filteredPdfImage.push({id: 'metadata.contentType', value: 'application/pdf'})
        filteredPdfImage2.push({id: 'contentType', value: 'application/pdf'})
    } else if (Main.Instance.DatabaseDataState.pdfImage === false) {
        if (Main.Instance.DatabaseDataState.uploadExisting) {
            for (const item of Main.Instance.DatabaseDataState.uploadExisting) {
                if (item.metadata) {
                    if (item.metadata.contentType.startsWith('image/')) {
                        filtered.push(item)
                    }
                } else {
                    if (item.contentType.startsWith('image/')) {
                        filtered.push(item)
                    }
                }
            }
        }
    }

    return (
        <div id = "ShowUploadsContainer" className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => toggleShowUploads()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-15px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2 className = "pr">
                {Assets.ShowUploads}
                <div className = "clearfix" style = {{position: 'absolute', top: '0px', right: '0px'}}>
                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {pdfImage: undefined})}
                        type = "button"
                        className = {Main.Instance.DatabaseDataState.pdfImage === undefined ? 'btn btn-primary btn-xs fl dbl' : 'btn btn-default btn-xs fl dbl'}
                    >
                        {Assets.All + ' ' + Assets.Files.toLowerCase()}
                    </button>

                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {pdfImage: true})}
                        type = "button"
                        className = {Main.Instance.DatabaseDataState.pdfImage === true ? 'btn btn-danger btn-xs fl dbl' : 'btn btn-default btn-xs fl dbl'}
                    >
                        {Assets.Pdfs + ' ' + Assets.Only.toLowerCase()}
                    </button>

                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {pdfImage: false})}
                        type = "button"
                        className = {Main.Instance.DatabaseDataState.pdfImage === false ? 'btn btn-info btn-xs fl dbl' : 'btn btn-default btn-xs fl dbl'}
                    >
                        {Assets.Images + ' ' + Assets.Only.toLowerCase()}
                    </button>

                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {ShowGuideHowToUploadFiles: ! Main.Instance.DatabaseDataState.ShowGuideHowToUploadFiles})}
                        type = "button"
                        className = {Main.Instance.DatabaseDataState.ShowGuideHowToUploadFiles ? 'btn btn-warning btn-xs mr0 fl dbl' : 'btn btn-default btn-xs mr0 fl dbl'}
                    >
                        {Main.Instance.DatabaseDataState.ShowGuideHowToUploadFiles ? Assets.Hide : Assets.Show}
                        &nbsp;{Assets.StepByStepGuideHowToUploadFiles.toLowerCase()}
                    </button>
                </div>
            </h2>
            {/*console.log (Main.Instance.DatabaseDataState.selectedUpload)*/}
            {Main.Instance.DatabaseDataState.selectedUpload ?
            <>
                <br/>
                <div style = {{position: 'relative', top: '0px', paddingTop: '30px'}}>
                    <button
                        className = "btn btn-sm btn-default mr0"
                        title = {Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? Assets.Compress : Assets.Expand}
                        style = {{position: 'absolute', top: '0px', left: '0px', zIndex: 2}}
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUploadIsExpanded: !Main.Instance.DatabaseDataState.selectedUploadIsExpanded})}
                    >
                        <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? faCompress : faExpand}/>
                    </button>

                    <input
                        type = "range"
                        min = "1"
                        max = "20"
                        title = {Assets.Brightness}
                        value = {Main.Instance.DatabaseDataState.selectedUploadBrightness}
                        style = {{width: '200px', position: 'absolute', top: '5px', left: '70px', zIndex: 2}}
                        onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUploadBrightness: +e.target.value})}
                    />
                    <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '8px', left: '45px', zIndex: 2}}/>

                    <input
                        type = "range"
                        min = "1"
                        max = "20"
                        value = {Main.Instance.DatabaseDataState.selectedUploadContrast}
                        title = {Assets.Contrast}
                        style = {{width: '200px', position: 'absolute', float: 'right',top: '5px', right: '115px', zIndex: 2}}
                        onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUploadContrast: +e.target.value})}
                    />
                    <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '8px', right: '90px', zIndex: 2}}/>

                    <button
                        className = "btn btn-sm btn-danger"
                        style = {{lineHeight: '1.2', position: 'absolute', top: '0px', left: 'calc(50% - 27px)', zIndex: 9999}}
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUpload: undefined, uploadedSelectedPosition: -1})}
                    >
                        {Assets.Close}
                    </button>

                    <button
                        className = "btn btn-sm btn-default mr0"
                        title = {Assets.Rotate}
                        style = {{position: 'absolute', top: '0px', right: '0px', zIndex: 9999}}
                        onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUploadRotation: Main.Instance.DatabaseDataState.selectedUploadRotation === 3 ? 0 : Main.Instance.DatabaseDataState.selectedUploadRotation + 1})}
                    >
                        <FontAwesomeIcon icon = {faSyncAlt}/>
                    </button>

                    <span style = {{position: 'absolute', top: '1px', right: '40px', zIndex: 9999, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                        {Main.Instance.DatabaseDataState.selectedUploadRotation * 90}°
                    </span>

                    {Main.Instance.DatabaseDataState.selectedUploadIsImage ?
                    <div
/*
                        style = {{
                            height: Main.Instance.DatabaseDataState.selectedUploadRotation === 1 || Main.Instance.DatabaseDataState.selectedUploadRotation === 3 ? ((Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? Main.Instance.DatabaseDataState.containerWidth : Main.Instance.DatabaseDataState.imageWidth) + 'px') : '',
                        }}
*/
                    >
                    <img
                        className = {Main.Instance.DatabaseDataState.selectedUploadRotation ? 'rotate' + Main.Instance.DatabaseDataState.selectedUploadRotation : ''}
                        style = {{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '0px',
                            filter: 'brightness(' + Main.Instance.DatabaseDataState.selectedUploadBrightness + '0%) contrast(' + Main.Instance.DatabaseDataState.selectedUploadContrast + '0%)',
                            maxWidth: Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? '100%' : '30%',
                            padding: '5px',
                            border: '2px solid #999',
                            borderRadius: '0px'
                        }}
                        src = {Main.Instance.DatabaseDataState.selectedUpload}
                    />
                    </div>
                    : 
                    <div
                        className = "pr"
                        style = {{
                            paddingLeft: Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? '0%' : '0%',
                            maxWidth: Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? '100%' : '100%'
                        }}
                    >
                        <Document
                            className = {Main.Instance.DatabaseDataState.selectedUploadRotation ? 'rotate' + Main.Instance.DatabaseDataState.selectedUploadRotation : ''}
                            style = {{
                                
                                filter: 'brightness(' + Main.Instance.DatabaseDataState.selectedUploadBrightness + '0%) contrast(' + Main.Instance.DatabaseDataState.selectedUploadContrast + '0%)',
                                maxWidth: Main.Instance.DatabaseDataState.selectedUploadIsExpanded ? '100%' : '30%',
                                padding: '5px',
                                border: '2px solid #999',
                                borderRadius: '0px'
                            }}
                            file = {Main.Instance.DatabaseDataState.selectedUpload}
                            onLoadSuccess={onDocumentLoadSuccess1}
                        >
                            <Page pageNumber={Main.Instance.DatabaseDataState.pageNumber1}/>
                        </Document>
                        <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                            <div className = "fl">{Assets.Page}:&nbsp;</div>
                            <button
                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: Main.Instance.DatabaseDataState.pageNumber1 === 1 ? '' : '#5cb85c'}}
                                type = "button"
                                className = "fl btn btn-default btn-xs mr0"
                                onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {pageNumber1: Main.Instance.DatabaseDataState.pageNumber1 - 1})}
                                disabled = {Main.Instance.DatabaseDataState.pageNumber1 === 1}
                            >
                                <FontAwesomeIcon icon = {faMinusSquare}/>
                            </button>
                            <div
                                style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                className = "fl label label-success label-sm"
                            >
                                {Main.Instance.DatabaseDataState.pageNumber1}
                            </div>
                            <button
                                style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: Main.Instance.DatabaseDataState.pageNumber1 === Main.Instance.DatabaseDataState.numPages1 ? '' : '#5cb85c'}}
                                type = "button"
                                className = "fl btn btn-default btn-xs mr0"
                                onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {pageNumber1: Main.Instance.DatabaseDataState.pageNumber1 + 1})}
                                disabled = {Main.Instance.DatabaseDataState.pageNumber1 === Main.Instance.DatabaseDataState.numPages1}
                            >
                                <FontAwesomeIcon icon = {faPlusSquare}/>
                            </button>

                            <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                            <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                {Main.Instance.DatabaseDataState.numPages1}
                            </div>
                        </div>
                    {/*<p>{Assets.Page} {Main.Instance.DatabaseDataState.pageNumber}{Assets._of_}{Main.Instance.DatabaseDataState.numPages}</p>*/}
                    </div>}
                </div>

            </>
            : Main.Instance.DatabaseDataState.ShowGuideHowToUploadFiles ?
            <>
                <h3>{Assets.StepByStepGuideHowToUploadFiles}</h3>
                {getOrderedList(Assets.OrderedListUploadFiles)}
            </>
            : void 0}
            <br/>
            {/*console.log (4527)*/}
            {/*console.log (Main.Instance.DatabaseDataState.uploadExisting)*/}
            {(Main.Instance.DatabaseDataState.pdfImage === false ? filtered : Main.Instance.DatabaseDataState.uploadExisting ? Main.Instance.DatabaseDataState.uploadExisting : []).length > 0 ?
            (Main.Instance.DatabaseDataState.pdfImage === false ? filtered : Main.Instance.DatabaseDataState.uploadExisting ? Main.Instance.DatabaseDataState.uploadExisting : [])[0].metadata ?
            <ReactTable
                data = {Main.Instance.DatabaseDataState.pdfImage === false ? filtered : Main.Instance.DatabaseDataState.uploadExisting ? Main.Instance.DatabaseDataState.uploadExisting : []}
                filtered = {filteredPdfImage}
                columns = {[
                    {Header: Assets.Filename, accessor: 'name', headerClassName: 'th-left', Cell: row => row.value ? getFilename(row.value) : '-'},
                    {width: 100, Header: Assets.Size, accessor: 'metadata.size', headerClassName: 'th-left', Cell: row => row.value ? (Math.round(row.value/1024)) + ' ' + Assets_.kilobytesShort : '-'},
                    {width: 150, Header: Assets.File + ' ' +  Assets.Type.toLowerCase(), accessor: 'metadata.contentType', headerClassName: 'th-left', Cell: row => <button type = "button" className = {row.value === 'application/pdf' ? 'btn btn-danger btn-sm' : 'btn btn-info btn-sm'}>{getContentType(row.original.name)}</button>},
                    {width: 150, Header: Assets.Time + ' ' + Assets.Created.toLowerCase(), accessor: 'metadata.timeCreated', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'},
                    {width: 150, Header: Assets.Time + ' ' + Assets.Updated.toLowerCase(), accessor: 'metadata.updated', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'},
                    {width: 80, Header: Assets.Selected, accessor: 'metadata.contentType', headerClassName: 'th-left', Cell: row => selectButton(row)}
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                defaultPageSize={10}
            />
            :
            <ReactTable
                data = {Main.Instance.DatabaseDataState.pdfImage === false ? filtered : Main.Instance.DatabaseDataState.uploadExisting ? Main.Instance.DatabaseDataState.uploadExisting : []}
                filtered = {filteredPdfImage2}
                columns = {[
                    {Header: Assets.Filename, accessor: 'name', headerClassName: 'th-left', Cell: row => row.value ? getFilename(row.value) : '-'},
                    {width: 100, Header: Assets.Size, accessor: 'size', headerClassName: 'th-left', Cell: row => row.value ? (Math.round(row.value/1024)) + ' ' + Assets_.kilobytesShort : '-'},
                    {width: 150, Header: Assets.File + ' ' +  Assets.Type.toLowerCase(), accessor: 'contentType', headerClassName: 'th-left', Cell: row => <button type = "button" className = {row.value === 'application/pdf' ? 'btn btn-danger btn-sm' : 'btn btn-info btn-sm'}>{getContentType(row.original.name)}</button>},
                    {width: 150, Header: Assets.Time + ' ' + Assets.Created.toLowerCase(), accessor: 'timeCreated', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'},
                    {width: 150, Header: Assets.Time + ' ' + Assets.Updated.toLowerCase(), accessor: 'updated', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0,10) + ' ' + row.value.substring(11,19) : '-'},
                    {width: 80, Header: Assets.Selected, accessor: 'contentType', headerClassName: 'th-left', Cell: row => selectButton(row)}
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                defaultPageSize={10}
            /> 
            : void 0}
            <br/>
            {/*isUserGamanzaMagicHand() ?*/}
            <div className = "clearfix">
                <br/>
                <div className = "fl">{Assets.Upload + ' ' + Assets.File.toLowerCase()}&nbsp;</div>
                <input
                    id = "fileUpload"
                    type = "file"
                    className = "uploadLogoWrapper dbl"
                    style = {{width: '300px', marginTop: '-7px', marginLeft: '10px', marginRight: '0px'}}
                    onClick = {(event) => event.target = null}
                    // disabled = {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.SelectIssueType as number}
                    onChange = {(event) => uploadFile(Main.Instance.DatabaseDataState.playerCard.playerId, 'fileUpload')}
                />
            </div> {/*: void 0*/}
        </div>
    )
}

function getContentType(filename: string) {
    const ending: string = filename.substring(filename.lastIndexOf('.') + 1, filename.length)
    if (ending === 'pdf') {
        return 'application/pdf'
    } else {
        return 'image/' + ending
    }
}

export function getFilename (value: string): string {
    const Value: string = value.substring(value.indexOf('/') + 1, value.length)
    return Value.substring(0, Value.lastIndexOf('.'))
}

function selectButton(row): JSX.Element {
    return (
        <button 
            onClick = {() => selectUploadedFile(row)}
            type = "button"
            className = {row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? 'btn btn-primary btn-sm mr0': 'btn btn-default btn-sm mr0'}
        >
            {row.index === Main.Instance.DatabaseDataState.uploadedSelectedPosition ? Assets.Selected : Assets.Select}
        </button>
    
    )
}
/*
function ManualBonusAdd(): JSX.Element {
    return (
        <div className = "pr clearfix">
            <button type = "button" onClick = {() => toggleBonusWageringRequirement()} className = {Main.Instance.PlayerCardState.BonusWageringRequirement ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.Amount} icon = {faMoneyBillWave}/>
            </button>
            <button type = "button" onClick = {() => toggleBonusWageringRequirement()} className = {!Main.Instance.PlayerCardState.BonusWageringRequirement ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.BonusCode} icon = {faCode}/>
            </button>
            {Main.Instance.PlayerCardState.BonusWageringRequirement ? 
            <input
                className = "fl dbl"
                min = {0}
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "number"
                value = {Main.Instance.PlayerCardState.BonusAmount}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusAmount: parseInt(e.target.value)})}
            />
            :
            <input
                className = "fl dbl"
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "text"
                value = {Main.Instance.PlayerCardState.BonusCode}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusCode: e.target.value})}
            />
            }
            <button
                disabled = {Main.Instance.PlayerCardState.BonusWageringRequirement && Main.Instance.PlayerCardState.BonusAmount <= 0 || !Main.Instance.PlayerCardState.BonusWageringRequirement && Main.Instance.PlayerCardState.BonusCode.length === 0}
                type = "button"
                onClick = {() => Main.Instance.PlayerCardState.BonusWageringRequirement ? DbRoutes.addBonusManual(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusAmount) : DbRoutes.addBonusManualCode(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusCode)}
                className = "btn btn-xs btn-primary btn-xs br0 mr0 fl dbl"
            >
                {Assets.InsertANewBonus}
            </button>
        </div>
    )
}
*/
//  public bonusCampaignIdSelected: number = 0
//  public bonusIdSelected: number = 0

function getBonusCampaign(): JSX.Element {
    const bc: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.bonusCampaignNew) {
        for (const item of /*Main.Instance.DatabaseDataState.bonusCampaign*/Main.Instance.DatabaseDataState.bonusCampaignNew) {
            const now = Date.now() / 1000 as number
            const start = new Date(item.startDate).getTime() / 1000 as number
            const end = new Date(item.endDate).getTime() / 1000 as number

            if (start <= now && now <= end) {
                bc.push (
                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {
                            bonusCampaignIdSelected: item.id,
                            bonusesSelected: /*item.data.bonus*/ item.bonusIds,
                            bonusIdSelected: undefined,
                            bonusCodesSelected: undefined,
                            BonusCode: ''
                        })}
                        className = {Main.Instance.PlayerCardState.bonusCampaignIdSelected === item.id ? 'btn btn-primary btn-xs mt2' : 'btn btn-default btn-xs mt2'}
                    >
                        {/*item.data.name*/item.campaignName}
                    </button>)
            }
        }
    }
    return <div>{bc}</div>
}

function getBonus(): JSX.Element {
    const b: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.bonusNew) {
        for (const item of /*Main.Instance.DatabaseDataState.bonus*/ Main.Instance.DatabaseDataState.bonusNew) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerCardState.bonusesSelected) {
                if (/*item.id*/ item.bonusId === Item) {
                    found = true
                    let ruleExists = false
                    if (item.bonusType === bonusType[0]) {
                        
                        for (const ITEM of item.bonusRules) {
                            if (ITEM.rule === conditionRule[2]) {
                                ruleExists = true
                                break
                            }
                        }
                    } else if (item.bonusType === bonusType[1]) {
                        ruleExists = true
                        if (item.freeGames && item.freeGames.length > 0) {
                            for (const ITEM of item.freeGames) {
                                if (ITEM.betLevel === null || ITEM.coinValueLevel === null || ITEM.freeGameAmount === null) {
                                    ruleExists = false
                                }
                            }
                        } else {
                            ruleExists = false
                        }
                        
                    }
                    if (!ruleExists) {
                        found = false
                    }
                    break
                }
                
            }

            if (found) {
                b.push (
                    <button
                        onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {
                            bonusIdSelected: /*item.id*/ item.bonusId,
                            bonusCodesSelected: /*item.data.code*/ item.bonusCodes,
                            BonusCode: ''
                        })}
                        className = {Main.Instance.PlayerCardState.bonusIdSelected === /*item.id*/ item.bonusId ? 'btn btn-info btn-xs mt2' : 'btn btn-default btn-xs mt2'}
                    >
                        {/*item.data.name*/ item.bonusName}
                    </button>
                )
            }
        }
    }
    return <div>{b}</div>

}

function getBonusCodes(): JSX.Element {
    const bc: JSX.Element[] = []
    for (const item of Main.Instance.PlayerCardState.bonusCodesSelected) {
        bc.push (
            <button
                onClick = {() => Logic.Type.New (Main.Instance.PlayerCardState, {BonusCode: item})}
                className = {Main.Instance.PlayerCardState.BonusCode === item ? 'btn btn-success btn-xs mt2' : 'btn btn-default btn-xs mt2'}
            >
                {item}
            </button>
        )
    }
    return <div>{bc}</div>
}

function getBonusSelection(): JSX.Element {
    // console.log (Main.Instance.DatabaseDataState.bonusCampaign)
    // console.log (Main.Instance.DatabaseDataState.bonus)
    return (
        <div>
            {getBonusCampaign()}
            {Main.Instance.PlayerCardState.bonusCampaignIdSelected && Main.Instance.PlayerCardState.bonusesSelected && Main.Instance.PlayerCardState.bonusesSelected.length > 0 ? getBonus() : void 0}
            {Main.Instance.PlayerCardState.bonusCodesSelected ? getBonusCodes() : void 0}
            <span className="mr mt">{Assets.Comment}:</span>
            <input
                min = {0}
                title = {Assets.Comment}
                style = {{width: '250px', height: '22px', paddingRight: '0px', marginTop: '10px', marginBottom: '10px'}}
                type = "text"
                value = {Main.Instance.PlayerCardState.comment ? Main.Instance.PlayerCardState.comment : ''}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {comment: e.target.value})}
            />
        </div>
    )
}
/*
function getDepositBonusSelection(): JSX.Element {
    return (
        <div>
            <input
                min = {0}
                title = {Assets.BonusAmount}
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "number"
                value = {Main.Instance.PlayerCardState.DepositBonusAmount}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {DepositBonusAmount: parseInt(e.target.value)})}
            />
            <select value = {Main.Instance.PlayerCardState.DepositBonusId} style = {{width: 'auto', height: '22px', paddingLeft: '1px', marginLeft: '20px', background: '#FFF'}} onChange = {(e) => Logic.Type.New(Main.Instance.PlayerCardState, {DepositBonusId: +e.target.value})}>
                {getDepositBonusOptions()}
            </select>
        </div>
    )
}
*/
function getBonusOptions() {
    const options: JSX.Element[] = []
    options.push(<option key="-1" value="-1">{Assets.Select + ' ' + Assets.Bonus}</option>)
    if (Main.Instance.DatabaseDataState.bonusNew) {
        for (const item of Main.Instance.DatabaseDataState.bonusNew) {
            if (item.bonusType === bonusType[0]) {
                options.push(<option key={item.bonusId} value={item.bonusId}>{item.bonusName}</option>)
            }
        }
    }
    return options
}

function ManualBonusAdd(): JSX.Element {
    return (
        <>
        <div className = "pr clearfix">
            <button type = "button" onClick = {() => setBonusWageringRequirement(AddBonusOptions.ByAmount)} className = {Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByAmount ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.Amount} icon = {faMoneyBillWave}/>
            </button>
            <button type = "button" onClick = {() => setBonusWageringRequirement(AddBonusOptions.ByCode)} className = {Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByCode ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.BonusCode} icon = {faCode}/>
            </button>
            <button type = "button" onClick = {() => setBonusWageringRequirement(AddBonusOptions.ByCampaign)} className = {Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByCampaign ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.AddANewBonus + Assets._by_ + Assets.BonusCampaign + '/' + Assets.Bonus} icon = {faAd}/>
            </button>
            {/* <button type = "button" onClick = {() => setBonusWageringRequirement(AddBonusOptions.DepositBonus)} className = {Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.DepositBonus ? 'btn btn-xs btn-primary btn-xs mr0 br0 fl dbl' : 'btn btn-xs btn-default btn-xs mr0 br0 fl dbl'}>
                <FontAwesomeIcon title = {Assets.Add + Assets._a_ + Assets.DepositBonus} icon = {faDonate}/>
            </button> */}
        </div>
        <div className = "pr mt2">
            {Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByAmount ? 
            <>
            <select value = {Main.Instance.PlayerCardState.BonusId} style = {{width: 'auto', height: '22px', paddingLeft: '1px', marginRight: '20px', background: '#FFF'}} onChange = {(e) => {
                let bonus: BonusNew
                if (Main.Instance.DatabaseDataState.bonusNew) {
                    bonus = Main.Instance.DatabaseDataState.bonusNew.find(item => item.bonusId === +e.target.value)
                }
                let BonusAmount = 0
                if (bonus && bonus.bonusRules) {
                    const rewardAmount = bonus.bonusRules.find(rules => rules.rule === conditionRule[2])
                    if (rewardAmount) {
                        BonusAmount = +rewardAmount.value
                    }
                }
                Logic.Type.New(Main.Instance.PlayerCardState, {BonusId: +e.target.value, BonusAmount})
                }}>
                {getBonusOptions()}
            </select>
            <input
                // className = "fl dbl"
                min = {0}
                title = {Assets.BonusAmount}
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "number"
                value = {Main.Instance.PlayerCardState.BonusAmount}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusAmount: parseInt(e.target.value)})}
            />
            <span className="ml mr">{Assets.WageringRequirementShort}:</span>
            <input
                // className = "fl dbl"
                min = {0}
                title = {Assets.WageringReq}
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "number"
                value = {Main.Instance.PlayerCardState.WageringRequirement ? Main.Instance.PlayerCardState.WageringRequirement : ''}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {WageringRequirement: parseInt(e.target.value)})}
            />
            <span className="ml mr">{Assets.Qualifying + ' ' + Assets.Share.toLowerCase() + ' ' + Assets.Amount.toLowerCase()}:</span>
            <input
                min = {0}
                title = {Assets.WageringReq}
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "number"
                value = {Main.Instance.PlayerCardState.QualifyingShareAmount ? Main.Instance.PlayerCardState.QualifyingShareAmount : ''}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {QualifyingShareAmount: parseInt(e.target.value)})}
            />
            <span className="ml mr">{Assets.Comment}:</span>
            <input
                min = {0}
                title = {Assets.Comment}
                style = {{width: '250px', height: '22px', paddingRight: '0px'}}
                type = "text"
                value = {Main.Instance.PlayerCardState.comment ? Main.Instance.PlayerCardState.comment : ''}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {comment: e.target.value})}
            />
            </>
            : Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByCode ?
            <>
            <input
                // className = "fl dbl"
                style = {{width: '147px', height: '22px', paddingRight: '0px'}}
                type = "text"
                title = {Assets.BonusCode}
                value = {Main.Instance.PlayerCardState.BonusCode}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {BonusCode: e.target.value})}
            />
            <span className="ml mr">{Assets.Comment}:</span>
            <input
                min = {0}
                title = {Assets.Comment}
                style = {{width: '250px', height: '22px', paddingRight: '0px'}}
                type = "text"
                value = {Main.Instance.PlayerCardState.comment ? Main.Instance.PlayerCardState.comment : ''}
                onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {comment: e.target.value})}
            />
            </>
            : 
            getBonusSelection()
            // : getDepositBonusSelection()
            }
        </div>
        <div className = "pr mt2 mb5">
            <button
                disabled = {Main.Instance.PlayerCardState.BonusAddManualCode || Main.Instance.PlayerCardState.BonusAddManual || /*Main.Instance.PlayerCardState.BonusAddManualDeposit ||*/ Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByAmount && (Main.Instance.PlayerCardState.BonusAmount <= 0 || Main.Instance.PlayerCardState.BonusId === undefined || Main.Instance.PlayerCardState.BonusId === -1 ) || ((Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByCode || Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByCampaign) && Main.Instance.PlayerCardState.BonusCode.length === 0) /* || Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.DepositBonus && (!Main.Instance.PlayerCardState.DepositBonusAmount || Main.Instance.PlayerCardState.DepositBonusAmount <= 0 || !Main.Instance.PlayerCardState.DepositBonusId || Main.Instance.PlayerCardState.DepositBonusId === -1)*/}
                type = "button"
                onClick = {() => Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.ByAmount ? DbRoutes.addBonusManual(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusAmount, Main.Instance.PlayerCardState.BonusId, Main.Instance.PlayerCardState.WageringRequirement, Main.Instance.PlayerCardState.QualifyingShareAmount, Main.Instance.PlayerCardState.comment) : /* Main.Instance.PlayerCardState.BonusWageringRequirement === AddBonusOptions.DepositBonus ? DbRoutes.addBonusManualDeposit(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.DepositBonusId, Main.Instance.PlayerCardState.DepositBonusAmount) : */ DbRoutes.addBonusManualCode(Main.Instance.DatabaseDataState.playerCard.playerId, Main.Instance.PlayerCardState.BonusCode, Main.Instance.PlayerCardState.comment)}
                className = "btn btn-xs btn-primary btn-xs br0 mr0"
            >
                {Assets.InsertANewBonus}
            </button>
        </div>
        </>
    )
}

function ManualBonus(): JSX.Element {
    let activeBonuses = 0
    if (Main.Instance.PlayerCardState.bonusInfo) {
        for (const ITEM of Main.Instance.PlayerCardState.bonusInfo) {
            if (ITEM.state === 'GRANTED') {
                activeBonuses++
            }
        }
    }
    
    if (Main.Instance.PlayerCardState.bonusInfoFreeGames) {
        for (const ITEM of Main.Instance.PlayerCardState.bonusInfoFreeGames) {
            if (ITEM.state === 'GRANTED') {
                activeBonuses++
            }
        }
    }
    
    return (
        <div className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => toggleManualBonus()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2 className = "pr">
                {Assets.Bonus}&nbsp;&nbsp;
                <small>
                    <FontAwesomeIcon icon = {faUser}/>
                    &nbsp;
                    {isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}
                </small>
                <button
                    style = {{position: 'absolute', top: '0px', right: '0px'}}
                    className = {Main.Instance.PlayerCardState.BonusAdd ? 'btn btn-xs btn-primary mr0' : 'btn btn-xs btn-default mr0'}
                    onClick = {
                        () => Main.Instance.PlayerCardState.BonusAdd ?
                        Logic.Type.New (Main.Instance.PlayerCardState, {BonusAdd: false}) :
                        Logic.Type.New (Main.Instance.PlayerCardState, {BonusAdd: true, BonusAmount: 0, BonusCode: '', BonusWageringRequirement: AddBonusOptions.ByAmount})
                    }
                >
                    <FontAwesomeIcon icon = {faPlusCircle}/>&nbsp;{Assets.AddANewBonus}
                </button>
            </h2>
            {/*!Main.Instance.PlayerCardState.hiddenPlayerCard &&*/ Main.Instance.PlayerCardState.BonusAdd ?
            ManualBonusAdd()
            : void 0}
            {/*!Main.Instance.PlayerCardState.hiddenPlayerCard && Main.Instance.PlayerCardState.bonusInfo || Main.Instance.PlayerCardState.bonusInfoFreeGames ? */
            (!Main.Instance.PlayerCardState.bonusInfo || Main.Instance.PlayerCardState.bonusInfo && Main.Instance.PlayerCardState.bonusInfo.length === 0) && (!Main.Instance.PlayerCardState.bonusInfoFreeGames || Main.Instance.PlayerCardState.bonusInfoFreeGames && Main.Instance.PlayerCardState.bonusInfoFreeGames.length === 0) ?
            <div className = "mt2 pr" style = {{textAlign: 'left', fontWeight: 600}}>
                {Assets.ThereAreNoBonusesForThisPlayer}
            </div>
            :
            <div>
            {GetBonusNavigation()}
            {Main.Instance.PlayerCardState.bonusTypeNavigation === BonusTypeNavigation.Money ? GetBonusInfoNew(activeBonuses) : Main.Instance.PlayerCardState.bonusTypeNavigation === BonusTypeNavigation.FreeGames ? GetBonusInfoFreeGames(activeBonuses) : void 0}
            </div>
            /*: void 0*/}
        </div>
    )
}

function GetBonusNavigation(): JSX.Element {
    return (
        <div className="mb">
            <button
                type = "button"
                onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {bonusTypeNavigation: BonusTypeNavigation.Money})}
                className = {Main.Instance.PlayerCardState.bonusTypeNavigation === BonusTypeNavigation.Money ? 'btn btn-xs btn-primary' : 'btn btn-xs btn-default'}
            >
                {BonusTypeNavigation[0]}
            </button>
            <button
                type = "button"
                onClick = {() => Logic.Type.New(Main.Instance.PlayerCardState, {bonusTypeNavigation: BonusTypeNavigation.FreeGames})}
                className = {Main.Instance.PlayerCardState.bonusTypeNavigation === BonusTypeNavigation.FreeGames ? 'btn btn-xs btn-primary' : 'btn btn-xs btn-default'}
            >
                {injectSpaces(BonusTypeNavigation[1])}
            </button>
        </div>
    )
}

function ManualWithdrawal(): JSX.Element {
    return (
        <div className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => toggleManualWithdrawal()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2>
                {Assets.Manual + ' ' + Assets.Withdrawal.toLowerCase()}&nbsp;&nbsp;
                <small>
                    <FontAwesomeIcon icon = {faUser}/>&nbsp;
                    {isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}
                </small>
            </h2>
            {getImportantInformation(2)}
            {/* .withdrawBalance*/}
            {/**/}
            <div className = "clearfix mt2 pr">
                <div style = {getStyle('withdrawBalance')} className = "label button-default w130px fl">{Assets.Withdraw}</div>
                <div className = "ml3 fl clearfix">
                    <input style = {{fontWeight: 700, width: '149px'}} disabled = {false} onChange = {(e) => changeWithdrawBalance(+e.target.value)} value = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance} className = "pcti fl" type = "number"/>
                    <input style = {{width: '35px', marginRight: '3px', marginLeft: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>
                    <button
                        type = "button"
                        onClick = {() => changeWithdrawBalance(Main.Instance.DatabaseDataState.playerCard.realBalance)}
                        disabled = {Main.Instance.DatabaseDataState.playerCard.realBalance === Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance}
                        className = {Main.Instance.DatabaseDataState.playerCard.realBalance === Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance ? 'btn btn-xs btn-default' : 'btn btn-xs btn-danger'}
                    >
                        {Assets.CopyWholeRealBalance}
                    </button>
                </div>
            </div>

            <div className = "mt5">
                <div className = "clearfix">
                    <div className = "fl" style = {{fontSize: '12px', marginTop: '5px'}}>{Assets.Comment + ':'}</div>
                    <div className = "fr">
                        <button onClick = {() => InsertNewIssueComment(Assets.ThisIssueWasResolvedBy + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.')} type = "button" className = "btn btn-default btn-xs mr0 br0">
                            {Assets.Default + ' ' + Assets.Comment}
                        </button>
                    </div>
                </div>
                <textarea onChange = {(e) => InsertNewIssueComment(e.target.value)} style = {{marginTop: '-1px', resize: 'none', width: '100%', height: '50px'}} value = {Main.Instance.DatabaseDataState.newIssue.comment ? Main.Instance.DatabaseDataState.newIssue.comment : ''}/>
            </div>

            <button disabled = {Main.Instance.DatabaseDataState.newIssue.comment === undefined || Main.Instance.DatabaseDataState.newIssue.comment.length === 0 || Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 || Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance === 0 ? 'btn btn-default btn-sm mt' : Main.Instance.DatabaseDataState.newIssue.data.withdrawBalance > Main.Instance.DatabaseDataState.playerCard.realBalance ? 'btn btn-danger btn-sm mt' : 'btn btn-warning btn-sm mt'} onClick = {() => insertNewIssue(false)}>
                {Assets.InsertANewManualWithdrawal}
            </button>
        </div>
    )
}

function getCommunicationChannels(): JSX.Element[] {
    // allowSms?: boolean allowEmail?: boolean allowTelephone?: boolean allowPost?: boolean
    // console.log (Main.Instance.DatabaseDataState.playerCard.allowSms)
    // console.log (Main.Instance.DatabaseDataState.playerCard.allowTelephone)
    // console.log (Main.Instance.DatabaseDataState.playerCard.allowPost)
    // console.log (Main.Instance.DatabaseDataState.playerCard.allowEmail)
    const cc: JSX.Element[] = []
    cc.push (<button onClick = {() => toggleCommunicationChannel(0)} style = {{marginTop: '-2px', padding: '1px 6px', marginRight: '3px'}} key = "0" className = {Main.Instance.DatabaseDataState.playerCard.allowEmail ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'}><FontAwesomeIcon icon = {faAt}/></button>)
    cc.push (<button onClick = {() => toggleCommunicationChannel(1)} style = {{marginTop: '-2px', padding: '1px 6px', marginRight: '3px'}} key = "1" className = {Main.Instance.DatabaseDataState.playerCard.allowPost ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'}><FontAwesomeIcon icon = {faEnvelope}/></button>)
    cc.push (<button onClick = {() => toggleCommunicationChannel(2)} style = {{marginTop: '-2px', padding: '1px 6px', marginRight: '3px'}} key = "2" className = {Main.Instance.DatabaseDataState.playerCard.allowSms ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'}>{Assets_.SMS}</button>)
    cc.push (<button onClick = {() => toggleCommunicationChannel(3)} style = {{marginTop: '-2px', padding: '1px 6px', marginRight: '0px'}} key = "3" className = {Main.Instance.DatabaseDataState.playerCard.allowTelephone ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'}><FontAwesomeIcon icon = {faPhoneAlt}/></button>)
    return cc
}

function InsertingNewIssue(): JSX.Element {
    return (
        <div className = "pr" style = {{padding: '0px 20px'}}>
            <button onClick = {() => toggleInsertNewIssue()} type = "button" style = {{background: 'transparent', border: '0px', position: 'absolute', right: '0px', top: '-20px', marginRight: '0px'}}>
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <h2 className = "pr">
                {Assets.InsertANewIssue}&nbsp;&nbsp;
                <small>
                    <FontAwesomeIcon icon = {faUser}/>&nbsp;
                    {isUserGDPR() ? Main.Instance.DatabaseDataState.playerCard.firstName + ' ' + Main.Instance.DatabaseDataState.playerCard.lastName + ' (' + Main.Instance.DatabaseDataState.playerCard.username + ')' : Main.Instance.DatabaseDataState.playerCard.username}
                </small>
                <button
                    style = {{position: 'absolute', top: '0px', right: '0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.DatabaseDataState, {showGuideHowToUploadFiles: ! Main.Instance.DatabaseDataState.showGuideHowToUploadFiles})}
                    type = "button"
                    className = {Main.Instance.DatabaseDataState.showGuideHowToUploadFiles ? 'btn btn-warning btn-xs mr0' : 'btn btn-default btn-xs mr0'}
                >
                    {Main.Instance.DatabaseDataState.showGuideHowToUploadFiles ? Assets.Hide : Assets.Show}
                    &nbsp;{Assets.StepByStepGuideHowToUploadFiles.toLowerCase()}
                </button>
            </h2>
            {Main.Instance.DatabaseDataState.showGuideHowToUploadFiles ?
            <>
                <h3>{Assets.StepByStepGuideHowToUploadFiles}</h3>
                {getOrderedList(Assets.OrderedListUploadFilesIssue)}
            </>
            : void 0}
            <div>{getIssueTypesInsert()}</div>
            <div className = "mt5">
                <span style = {{marginRight: '20px'}}>{Assets.Issue + ' ' + Assets.RiskLevel}</span>
                <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.newIssue.riskLevel]}>
                    {RiskLevel[Main.Instance.DatabaseDataState.newIssue.riskLevel]}
                    <input
                        value = {Main.Instance.DatabaseDataState.newIssue.riskLevel}
                        type = "range"
                        list = "test"
                        min = "0"
                        max = "5"
                        step = "1"
                        onChange = {(e) => InsertNewIssueRiskLevel(parseInt(e.target.value))}
                        className = "slider"
                    />
                </button>
            </div>
            <div className = "mt5">
                <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.MarkAsResolved}</span>
                <button onClick = {() => InsertNewIssueResolved(true)} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.resolved ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.YES}
                </button>
                <button onClick = {() => InsertNewIssueResolved(false)} type = "button" className = {!Main.Instance.DatabaseDataState.newIssue.resolved ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.NO}
                </button>
            </div>
            {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadDocument as number || Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadUtility as number ?
            <div className = "mt5">
                <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadDocument as number ? Assets.crp4 : Assets.crp5}</span>
                <button onClick = {() => InsertNewIssueVerified(true)} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && Main.Instance.DatabaseDataState.newIssue.data.verified ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.YES}
                </button>
                <button onClick = {() => InsertNewIssueVerified(false)} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && !Main.Instance.DatabaseDataState.newIssue.data.verified ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.NO}
                </button>
            </div>
            : void 0}
            {!Main.Instance.DatabaseDataState.newIssue.resolved ?
            <div className = "mt5">
                <span className = "mr">{Assets.AssignedTo}:</span>{getAssignTo()}
            </div> : void 0}
            <div className = "mt5">
                <div className = "clearfix">
                    <div className = "fl" style = {{fontSize: '12px', marginTop: '5px'}}>{Assets.Comment + ':'}</div>
                    <div className = "fr">
                        <button onClick = {() => InsertNewIssueComment((Main.Instance.DatabaseDataState.newIssue.resolved ? Assets.ThisIssueWasResolvedBy : Assets.ThisIssueWasNotResolvedBy) + ' ' + Main.Instance.LoginState.Username + ' (' + Assets.ID + ' ' + Main.Instance.LoginState.id + ')' + '.')} type = "button" className = "btn btn-default btn-xs mr0 br0">
                            {Assets.Default + ' ' + Assets.Comment}
                        </button>
                    </div>
                </div>
                <textarea onChange = {(e) => InsertNewIssueComment(e.target.value)} style = {{marginTop: '-1px', resize: 'none', width: '100%', height: '50px'}} value = {Main.Instance.DatabaseDataState.newIssue.comment ? Main.Instance.DatabaseDataState.newIssue.comment : ''}/>
            </div>
            {/*console.log ('3082')*/}
            {/*console.log (Main.Instance.DatabaseDataState.newIssue)*/}
            {!Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive &&
            !Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified &&
            !Main.Instance.DatabaseDataState.newIssue.data.disableSpecialAccount &&
            Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number ?
            <div className = "mt5 clearfix">
                {/*Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active ||
                Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified ? <span>Active</span> :

                Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Initial ||
                Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Provisional ? <span>Provisional</span> : <span>Special</span>*/}

                <div className = "fl mr" style = {{lineHeight: '22px'}}>{Assets.New + ' ' + Assets.AccountStatus + ':'}</div>
                {getButtonsChangeAccountStatus(Main.Instance.DatabaseDataState.newIssue.data.previousStatus, Main.Instance.DatabaseDataState.newIssue.data.currentStatus)}
                {/*
                <button
                    style = {{cursor: 'default', width: '187px', lineHeight: 1.1}}
                    className = {'mr0 btn btn-xs risk risk-' + AccountStatus[Main.Instance.DatabaseDataState.newIssue.data.currentStatus ? Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active || Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified && Main.Instance.DatabaseDataState.newIssue.data.currentStatus > AccountStatus.BlockedPartial ? Main.Instance.DatabaseDataState.newIssue.data.currentStatus + 1 : Main.Instance.DatabaseDataState.newIssue.data.currentStatus : AccountStatus.BlockedFull]}
                >
                    {Assets.accountStatus[Main.Instance.DatabaseDataState.newIssue.data.currentStatus ? Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active || Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified && Main.Instance.DatabaseDataState.newIssue.data.currentStatus > AccountStatus.BlockedPartial ? Main.Instance.DatabaseDataState.newIssue.data.currentStatus + 1 : Main.Instance.DatabaseDataState.newIssue.data.currentStatus : AccountStatus.BlockedFull]}

                    {Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active ||
                    Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified ?
                    <input
                        onChange = {(e) => InsertNewIssueAccountStatus(parseInt(e.target.value))}
                        type = "range"
                        list = "test"
                        min = {AccountStatus.BlockedFull}
                        max = {AccountStatus.Inactive}
                        step = "1"
                        value = {Main.Instance.DatabaseDataState.newIssue.data.currentStatus ? Main.Instance.DatabaseDataState.newIssue.data.currentStatus : AccountStatus.BlockedFull}
                        className = "slider"
                    />
                    :
                    Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Initial ||
                    Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Provisional ?
                        <input
                            onChange = {(e) => InsertNewIssueAccountStatus(parseInt(e.target.value))}
                            type = "range"
                            list = "test"
                            min = {AccountStatus.BlockedFull}
                            max = {AccountStatus.Inactive}
                            step = "1"
                            value = {Main.Instance.DatabaseDataState.newIssue.data.currentStatus ? Main.Instance.DatabaseDataState.newIssue.data.currentStatus : AccountStatus.BlockedFull}
                            className = "slider"
                        />
                    :
                    <input
                        onChange = {(e) => InsertNewIssueAccountStatus(parseInt(e.target.value))}
                        type = "range"
                        list = "test"
                        min = {AccountStatus.BlockedFull}
                        max = {AccountStatus.ClosedActive}
                        step = "1"
                        value = {Main.Instance.DatabaseDataState.newIssue.data.currentStatus ? Main.Instance.DatabaseDataState.newIssue.data.currentStatus : AccountStatus.BlockedFull}
                        className = "slider"
                    />}
                </button>*/}
            </div> : void 0}

            {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number &&
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active ?
            <div className = "mt5">
                <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.TurnIntoActiveFullyIdentified}</span>
                <button onClick = {() => {InsertNewIssueTurnIntoActiveFullyIdentified(true); InsertNewIssueAccountStatus(AccountStatus.ActiveFullyIdentified)}} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.YES}
                </button>
                <button onClick = {() => {InsertNewIssueTurnIntoActiveFullyIdentified(false); InsertNewIssueAccountStatus(AccountStatus.Active)}} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && !Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.NO}
                </button>
            </div>
            : void 0}

            {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number &&
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified ?
            <div className = "mt5">
                <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.TurnBackIntoActive}</span>
                <button onClick = {() => {InsertNewIssueTurnBackIntoActive(true); InsertNewIssueAccountStatus(AccountStatus.Active)}} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.YES}
                </button>
                <button onClick = {() => {InsertNewIssueTurnBackIntoActive(false); InsertNewIssueAccountStatus(AccountStatus.ActiveFullyIdentified)}} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data && !Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.NO}
                </button>
            </div>
            : void 0}

            {!Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive &&
            !Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified &&
            Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number ?
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Initial ||
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Provisional ||
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.Active ||
            Main.Instance.DatabaseDataState.newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified
            ?
            void 0
            :
            <div className = "mt">
                <span style = {{marginRight: '20px', lineHeight: '22px'}}>{Assets.DisableSpecialAccountStatus}</span>
                <button onClick = {() => InsertNewIssueDisableSpecialAccount(true)} type = "button" className = {Main.Instance.DatabaseDataState.newIssue.data.disableSpecialAccount ? 'btn btn-success btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.YES}
                </button>
                <button onClick = {() => InsertNewIssueDisableSpecialAccount(false)} type = "button" className = {!Main.Instance.DatabaseDataState.newIssue.data.disableSpecialAccount ? 'btn btn-danger btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>
                    {Assets.NO}
                </button>
            </div>
            : void 0}

            <div style = {{width: '100%', textAlign: 'center'}}>
                <br/>
                <div className = "pr">
                    <h4 className = "pr">
                        <input
                            style = {{fontSize: '12px', position: 'absolute', left: '0px', top: '0px'}}
                            type = "checkbox"
                            checked = {Main.Instance.DatabaseDataState.uploadShowAvailableFiles}
                            onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {
                                uploadShowAvailableFiles: !Main.Instance.DatabaseDataState.uploadShowAvailableFiles,
                                uploadShowAvailableFilesImages: true,
                                uploadShowAvailableFilesPdfs: true,
                            })}
                        />
                        <span style = {{fontSize: '12px', position: 'absolute', left: '17px', top: '5px'}}>
                            {Assets.ShowAvailableFilesForPlayer}
                        </span>
                        
                        {Main.Instance.DatabaseDataState.uploadShowAvailableFiles ?
                        <>
                            &nbsp;
                            {Main.Instance.DatabaseDataState.uploadExisting !== [] && Main.Instance.DatabaseDataState.uploadExisting !== undefined  && Main.Instance.DatabaseDataState.uploadExisting.length !== 0 ?
                                <>
                                <input
                                    style = {{fontSize: '12px', position: 'absolute', left: '200px', top: '0px'}}
                                    type = "checkbox"
                                    checked = {Main.Instance.DatabaseDataState.uploadShowAvailableFilesPdfs}
                                    onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {uploadShowAvailableFilesPdfs: Main.Instance.DatabaseDataState.uploadShowAvailableFilesImages ? !Main.Instance.DatabaseDataState.uploadShowAvailableFilesPdfs : true})}
                                />
                                <span style = {{fontSize: '12px', position: 'absolute', left: '217px', top: '5px'}}>
                                    {Assets.Pdfs}
                                </span>
                                &nbsp;
                                <input
                                    style = {{fontSize: '12px', position: 'absolute', left: '257px', top: '0px'}}
                                    type = "checkbox"
                                    checked = {Main.Instance.DatabaseDataState.uploadShowAvailableFilesImages}
                                    onChange = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {uploadShowAvailableFilesImages: Main.Instance.DatabaseDataState.uploadShowAvailableFilesPdfs ? !Main.Instance.DatabaseDataState.uploadShowAvailableFilesImages : true})}
                                />
                                <span style = {{fontSize: '12px', position: 'absolute', left: '274px', top: '5px'}}>
                                    {Assets.Images}
                                </span> 
                                </> : 
                                <span style = {{fontSize: '12px', position: 'absolute', left: '200px', top: '5px'}}>
                                    {Assets.No} {Assets.AvailableFiles}
                                 </span>}
                        </> : void 0}
                        

                        {Assets.UploadFile}
                        <input
                            accept = "image/png, image/jpg, image/jpeg, image/gif"
                            style = {{fontSize: '12px', position: 'absolute', right: '0px', top: '-10px', width: '300px', marginTop: '-7px', marginLeft: '10px', marginRight: '0px'}}
                            title = {Main.Instance.DatabaseDataState.playerCard.playerId}
                            id = "fileUpload3"
                            type = "file"
                            className = "uploadLogoWrapper dbl"
                            onClick = {(event) => event.target = null}
                            // disabled = {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.SelectIssueType as number}
                            onChange = {(event) => { 
                                uploadFile(Main.Instance.DatabaseDataState.playerCard.playerId, 'fileUpload3')
                            }}
                        />
                        <span style = {{fontSize: '12px', position: 'absolute', right: '305px', top: '4px'}}>
                            {Assets.UploadANewFile}:
                        </span>
                    </h4>
                    {Main.Instance.DatabaseDataState.uploadShowAvailableFiles ? getAlreadyUploaded() : void 0}

                    {Main.Instance.DatabaseDataState.upload ?
                    <>
                    <div className = "pr">
                        <button
                            className = "btn btn-sm btn-default mr0"
                            title = {Main.Instance.DatabaseDataState.uploadExpanded ? Assets.Compress : Assets.Expand}
                            style = {{position: 'absolute', top: '0px', left: '0px', zIndex: 9999}}
                            onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState,{uploadExpanded: !Main.Instance.DatabaseDataState.uploadExpanded})}
                        >
                            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.uploadExpanded ? faCompress : faExpand}/>
                        </button>

                        <input
                            type = "range"
                            min = "1"
                            max = "20"
                            value = {Main.Instance.DatabaseDataState.uploadBrightness}
                            title = {Assets.Brightness}
                            style = {{width: '200px', position: 'absolute', top: '5px', left: '70px', zIndex: 2}}
                            onChange = {(e) => Logic.Type.New(Main.Instance.DatabaseDataState,{uploadBrightness: +e.target.value})}
                        />
                        <FontAwesomeIcon title = {Assets.Brightness} icon = {faSun} style = {{position: 'absolute', top: '8px', left: '45px', zIndex: 2}}/>

                        <FontAwesomeIcon title = {Assets.Contrast} icon = {faAdjust} style = {{position: 'absolute', top: '8px', right: '90px', zIndex: 2}}/>
                        <input
                            type = "range"
                            min = "1"
                            max = "20"
                            value = {Main.Instance.DatabaseDataState.uploadContrast}
                            title = {Assets.Contrast}
                            style = {{width: '200px', position: 'absolute', top: '5px', right: '115px', zIndex: 2}}
                            onChange = {(e) => Logic.Type.New(Main.Instance.DatabaseDataState,{uploadContrast: +e.target.value})}
                        />

                        <button
                            className = "btn btn-sm btn-default mr0"
                            title = {Assets.Rotate}
                            style = {{position: 'absolute', top: '0px', right: '0px', zIndex: 9999}}
                            onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState,{uploadRotated: Main.Instance.DatabaseDataState.uploadRotated === 3 ? 0 : Main.Instance.DatabaseDataState.uploadRotated + 1})}
                        >
                            <FontAwesomeIcon icon = {faSyncAlt}/>
                        </button>
                        <span style = {{position: 'absolute', top: '1px', right: '40px', zIndex: 9999, background: '#eee', padding: '4px 6px', border: '1px solid #999'}}>
                            {Main.Instance.DatabaseDataState.uploadRotated * 90}°
                        </span>

                        {!Main.Instance.DatabaseDataState.uploadIsImage ?
                        <div
                            style = {{
                                position: 'relative',
                                textAlign: 'center',
                                paddingTop: '30px',
                                paddingLeft: /*Main.Instance.DatabaseDataState.uploadExpanded ? '0%' :*/'0%',
                                maxWidth: /*Main.Instance.DatabaseDataState.uploadExpanded ? '100%' :*/'100%'
                            }}
                        >
                            <Document
                                className = {Main.Instance.DatabaseDataState.uploadRotated ? 'rotate' + Main.Instance.DatabaseDataState.uploadRotated : ''}
                                style = {{marginTop: '10px', textAlign: 'center', filter: 'brightness(' + Main.Instance.DatabaseDataState.uploadBrightness + '0%) contrast(' + Main.Instance.DatabaseDataState.uploadContrast + '0%)'}}
                                file={Main.Instance.DatabaseDataState.upload}
                                onLoadSuccess={onDocumentLoadSuccess2}
                            >
                                <Page pageNumber={Main.Instance.DatabaseDataState.pageNumber2}/>
                            </Document>
                            <div className = "clearfix" style = {{position: 'absolute', bottom: '0px', right: '0px'}}>
                                <div className = "fl">{Assets.Page}:&nbsp;</div>
                                <button
                                    style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: Main.Instance.DatabaseDataState.pageNumber2 === 1 ? '' : '#5cb85c'}}
                                    type = "button"
                                    className = "fl btn btn-default btn-xs mr0"
                                    onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {pageNumber2: Main.Instance.DatabaseDataState.pageNumber2 - 1})}
                                    disabled = {Main.Instance.DatabaseDataState.pageNumber2 === 1}
                                >
                                    <FontAwesomeIcon icon = {faMinusSquare}/>
                                </button>
                                <div
                                    style = {{marginTop: '2px', marginLeft: '3px', marginRight: '3px'}}
                                    className = "fl label label-success label-sm"
                                >
                                    {Main.Instance.DatabaseDataState.pageNumber2}
                                </div>
                                <button
                                    style = {{marginTop: '2px', padding: '0px', background: 'transparent', border: '0px', color: Main.Instance.DatabaseDataState.pageNumber2 === Main.Instance.DatabaseDataState.numPages2 ? '' : '#5cb85c'}}
                                    type = "button"
                                    className = "fl btn btn-default btn-xs mr0"
                                    onClick = {() => Logic.Type.New(Main.Instance.DatabaseDataState, {pageNumber2: Main.Instance.DatabaseDataState.pageNumber2 + 1})}
                                    disabled = {Main.Instance.DatabaseDataState.pageNumber2 === Main.Instance.DatabaseDataState.numPages2}
                                >
                                    <FontAwesomeIcon icon = {faPlusSquare}/>
                                </button>

                                <div className = "fl">&nbsp;{Assets._of_}&nbsp;</div>
                                <div style = {{marginTop: '2px'}} className = "fl label label-default label-sm">
                                    {Main.Instance.DatabaseDataState.numPages2}
                                </div>
                            </div>
                        </div>
                        :
                        <img
                            className = {Main.Instance.DatabaseDataState.uploadRotated ? 'rotate' + Main.Instance.DatabaseDataState.uploadRotated : ''}
                            style = {{
                                filter: 'brightness(' + Main.Instance.DatabaseDataState.uploadBrightness + '0%) contrast(' + Main.Instance.DatabaseDataState.uploadContrast + '0%)',
                                maxWidth: Main.Instance.DatabaseDataState.uploadExpanded ? '100%' : '30%',
                                padding: '5px',
                                marginTop: '30px',
                                border: '2px solid #999',
                                borderRadius: '0px'
                            }}
                            src = {Main.Instance.DatabaseDataState.upload}
                        />}

                    </div>
                    
                    {Main.Instance.DatabaseDataState.uploadSelected !== -1 ? 
                    <div>
                        <button
                            style = {{marginTop: '10px', marginBottom: '10px', marginRight: '0px'}}
                            disabled = {isThisFileAlreadyAttached()}
                            className = "btn btn-primary btn-sm"
                            onClick = {() => attachThisFile()}
                        >
                            {Assets.AttachThisFile}
                        </button>
                    </div> : void 0}
                    </> : void 0}
                    {getAttached()}
                </div>
            </div>

{/*
            <div className = "clearfix">
                <br/>
                <div className = "fl">{Assets.Attachments}&nbsp;</div>
                <input
                    id = "fileupload"
                    type = "file"
                    className = "uploadLogoWrapper dbl"
                    style = {{width: '300px', marginTop: '-3px', marginLeft: '10px', marginRight: '0px'}}
                    onClick = {(event) => event.target = null}
                    disabled = {Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.SelectIssueType as number}
                    onChange = {(event) => { 
                        // const cloneFile: File = event.target.files[0]
                        // const reader = new FileReader()
                        // reader.onload = (e) => {
                        uploadFile (Main.Instance.DatabaseDataState.playerCard.playerId, 'fileupload')
                            // console.log (reader.result)

//                            const index: number = (reader.result as string).indexOf('base64,')
//                            const encodedString: string = 
//                            const decodedData: string = base64.decode((reader.result as string))// .replace('data:text/plain;base64,',''))
//                            console.log (decodedData)

}}
                
                />
            </div>*/}
            <button
                disabled = {(Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadDocument as number || Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.ManualUploadUtility as number) && (Main.Instance.DatabaseDataState.attachedUploads === undefined || Main.Instance.DatabaseDataState.attachedUploads.length === 0) || Main.Instance.DatabaseDataState.newIssue.comment === undefined || Main.Instance.DatabaseDataState.newIssue.comment.length === 0 || Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.SelectIssueType as number}
                type = "button"
                className = "btn btn-warning btn-sm mt"
                onClick = {() => InsertNewIssue()}
            >
                {Assets.InsertANewIssue}
            </button>
        </div>
        
    )
}

function uploadFile(playerId: string, id: string) {
    const upload = document.getElementById(id) as HTMLInputElement
    if (upload.files && upload.files.length) {
        // console.log (playerId)
        // console.log(id)
        const data = new FormData()
        data.append('file', upload.files[0])
        data.append('folderName', playerId)
        const config: AxiosRequestConfig = {
            method: 'post',
            url: Config.uUpload,
            headers: {
                'api-key': 'burek',
                'Content-Type' : 'multipart/form-data'
            },
            data
        };
        axios(config).then((response) => {
            getUploads()
            // console.log (response)
            // alert(response.data.msg + ' ' + (response.data.storageResponse && response.data.storageResponse[0] && response.data.storageResponse[0].originalName ? response.data.storageResponse[0].originalName : '') + ' (' + (response.data.storageResponse && response.data.storageResponse[0] && response.data.storageResponse[0].mimetype ? response.data.storageResponse[0].mimetype : '')  +')')
            let pdfImage = Main.Instance.DatabaseDataState.pdfImage
            if (response.data.storageResponse && response.data.storageResponse[0]) {
                // console.log (response.data.storageResponse[0].mimetype)
                if (pdfImage === true && response.data.storageResponse[0].mimetype.startsWith('image') || pdfImage === false && response.data.storageResponse[0].mimetype === 'application/pdf') {
                    pdfImage = undefined
                }
/*
                if (pdfImage === true && !response.data.storageResponse[0].id.endsWith('pdf') || pdfImage === false && response.data.storageResponse[0].id.endsWith('pdf')) {
                    pdfImage = undefined
                }
*/
            }
            
            Logic.Type.New (Main.Instance.DatabaseDataState, {
                uploadShowAvailableFiles: true,
                pdfImage
            })
        }).catch((error) => console.log (error))
    }
}

function InsertNewIssueAccountStatus(currentStatus: AccountStatus) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.data.currentStatus = currentStatus
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueRiskLevel(riskLevel: number) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.riskLevel = riskLevel
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueResolved(resolved: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.resolved = resolved
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueVerified(verified: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    if (newIssue.data === undefined) {newIssue.data = {}}
    newIssue.data.verified = verified
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueDisableSpecialAccount(disableSpecialAccount: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.data.disableSpecialAccount = disableSpecialAccount
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueTurnIntoActiveFullyIdentified(turnIntoActiveFullyIdentified: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.data.turnIntoActiveFullyIdentified = turnIntoActiveFullyIdentified
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueTurnBackIntoActive(turnBackIntoActive: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.data.turnBackIntoActive = turnBackIntoActive
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function InsertNewIssueComment(comment: string) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    newIssue.comment = comment
    Logic.Type.New (Main.Instance.DatabaseDataState, {newIssue})
}

function insertANewIssue(type: boolean) {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    // console.log (type)
    // newIssue.resolved = Main.Instance.DatabaseDataState.resolved
    if (type) {
        newIssue.issueType = IssueTypes.CRIFCheckMismatch
        newIssue.data = {
            isAddressVerified: Main.Instance.DatabaseDataState.isAddressVerified
        }
    } else {
        newIssue.issueType = IssueTypes.PEPChangedByOperator
        newIssue.data = {
            isPEP: Main.Instance.DatabaseDataState.isPEP
        }
    }

    newIssue.assignTo = []
    newIssue.playerId = Main.Instance.DatabaseDataState.playerCard.playerId
    newIssue.resolved = true
    newIssue.riskLevel = RiskLevel.Undefined  
    newIssue.riskManagerId = Main.Instance.LoginState.id

    DbRoutes.wsInsertIssue(newIssue)
    Logic.Type.New (Main.Instance.DatabaseDataState, {manualCrif: false, newIssue})
}

function insertNewIssue(deposit: boolean) {
    if (deposit) {
        DbRoutes.wsInsertManualDeposit(Main.Instance.DatabaseDataState.newIssue)
        Logic.Type.New(Main.Instance.DatabaseDataState, {manualDeposit: false})
    } else {
        DbRoutes.wsInsertManualWithdrawal(Main.Instance.DatabaseDataState.newIssue)
        Logic.Type.New(Main.Instance.DatabaseDataState, {manualWithdrawal: false})
    }
}
/*
function insertNewIssue2() {
    DbRoutes.wsInsertManualDeposit(Main.Instance.DatabaseDataState.newIssue)
    Logic.Type.New(Main.Instance.DatabaseDataState, {manualDeposit: false})
}
*/
function InsertNewIssue() {
    const newIssue: IIssueInsertAdmin = Object.assign({}, Main.Instance.DatabaseDataState.newIssue)
    
    if (newIssue.resolved || newIssue.assignTo.length === 0) {
        delete(newIssue.assignTo)
    }
/*
    if (Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified) {
    } else if (Main.Instance.DatabaseDataState.newIssue.data.turnIntoActiveFullyIdentified === false) {
    } else if (Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive === false) {
    } else if (!Main.Instance.DatabaseDataState.newIssue.data.turnBackIntoActive === false) {

    } else if (Main.Instance.DatabaseDataState.newIssue.data.currentStatus === undefined &&

        !Main.Instance.DatabaseDataState.newIssue.data.disableSpecialAccount && 
        Main.Instance.DatabaseDataState.newIssue.issueType === IssueTypesInsert.AccountStatusChange as number) {
            newIssue.data.currentStatus = AccountStatus.BlockedFull
    } else if (Main.Instance.DatabaseDataState.newIssue.data.currentStatus) {
        if (newIssue.data.previousStatus === AccountStatus.Active || newIssue.data.previousStatus === AccountStatus.ActiveFullyIdentified && newIssue.data.currentStatus > AccountStatus.BlockedPartial) {
            newIssue.data.currentStatus = newIssue.data.currentStatus + 1
        }

    }
*/
    if (Main.Instance.DatabaseDataState.attachedUploads) {
        if (newIssue.data === undefined) {
            newIssue.data = {}
        }
        newIssue.data.attachedUploads = Main.Instance.DatabaseDataState.attachedUploads
    }

    DbRoutes.wsInsertIssue(newIssue)
    Logic.Type.New(Main.Instance.DatabaseDataState, {insertANewIssue: false, newIssue})
}

function toggleManualBonus() {
    gotoTop()
    if (/*Main.Instance.DatabaseDataState.bonus === undefined*/ !Main.Instance.DatabaseDataState.bonusNew) {
        Socket1.socket.Send(Config.bonusBonusGet, {eventId: 'event_id', page: 1, limit: 1000})
    }
    if (!Main.Instance.DatabaseDataState.bonusCampaignNew) {
        Socket1.socket.Send(Config.bonusBonusCampaignGet, {eventId: 'event_id'})
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        manualBonus: !Main.Instance.DatabaseDataState.manualBonus,
        manualWithdrawal: false,
        manualDeposit: false,
        insertANewIssue: false,
        manualCrif: false,
        resendVerificationEmail: false,
        showUploads: false,
        checkVETO: false,
        assignee: 0,
        newIssue: {
            assignTo: [],
            resolved: true,
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.ManualWithdrawal,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined,
                withdrawBalance: 0
            },
        },
    })
/*
    if (Main.Instance.PlayerCardState.BonusInfo) {
        Logic.Type.New (Main.Instance.PlayerCardState, {BonusInfo: false})
    } else {
*/
    Logic.Type.New (Main.Instance.PlayerCardState, {
        bonusCampaignIdSelected: undefined,
        bonusesSelected: undefined,
        bonusIdSelected: undefined,
        bonusCodesSelected: undefined,
        bonusInfo: undefined
    })
    DbRoutes.getBonusInfo(Main.Instance.DatabaseDataState.playerCard.playerId)
//    }
}

function toggleManualWithdrawal() {
    gotoTop()
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        manualWithdrawal: !Main.Instance.DatabaseDataState.manualWithdrawal,
        manualDeposit: false,
        manualBonus: false,
        insertANewIssue: false,
        manualCrif: false,
        resendVerificationEmail: false,
        showUploads: false,
        checkVETO: false,
        assignee: 0,
        newIssue: {
            assignTo: [],
            resolved: true,
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.ManualWithdrawal,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined,
                withdrawBalance: 0
            },
        },
    })
}

function toggleCheckVETO() {
    // gotoTop()
    Logic.Type.New (Main.Instance.DatabaseDataState, {ManualCheckBlacklistGet: true})
    DbRoutes.checkVeto(Main.Instance.DatabaseDataState.playerCard.playerId)
    console.log(DbRoutes.checkVeto(Main.Instance.DatabaseDataState.playerCard.playerId))
/*    
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        checkVETO: false,
        manualDeposit: false,
        insertANewIssue: false,
        manualCrif: false,
        manualWithdrawal: false,
        resendVerificationEmail: false,
        showUploads: false,
        newIssue: {
            assignTo: [],
            resolved: true,
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.BlacklistManual,
            riskLevel: RiskLevel.Undefined,
            data: {},
        },

    }) 
    DbRoutes.wsInsertIssue(Main.Instance.DatabaseDataState.newIssue)
*/
    alert(Assets.ManualVetoCheckIsUnderWay)
}

function toggleManualDeposit() {
    gotoTop()
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        manualDeposit: !Main.Instance.DatabaseDataState.manualDeposit,
        insertANewIssue: false,
        manualCrif: false,
        manualWithdrawal: false,
        manualBonus: false,
        resendVerificationEmail: false,
        showUploads: false,
        checkVETO: false,
        assignee: 0,
        newIssue: {
            assignTo: [],
            resolved: true,
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.ManualDeposit,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined,
                withdrawBalance: 0,
                bypassRisk: undefined,
                bypassBonus: undefined
            },
        },
    })
}

function toggleResendVerificationEmail() {
    gotoTop()
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        manualWithdrawal: false,
        manualDeposit: false,
        manualBonus: false,
        insertANewIssue: false,
        manualCrif: false,
        showUploads: false,
        checkVETO: false,
        resendVerificationEmail: !Main.Instance.DatabaseDataState.resendVerificationEmail,
        assignee: 0,
        newIssue: {
            assignTo: [],
            resolved: true,
            
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.Other3,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined,
            }
        },
    })
}

function getStatusProvisionalOrActive() {
    Socket1.socket.Send (Config.amGetIssues, {
        filters: {
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            issueType: [IssueTypes.AccountStatusChange]
        },
        page: 1,
        limit: 1
    })
    Logic.Type.New(Main.Instance.DatabaseDataState, {IssuesGet: true, issueGetStatusProvisionalOrActive: true})
}

function toggleInsertNewIssue() {
    gotoTop()
    getUploads()
    getStatusProvisionalOrActive()
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        // issueTypesInsert: IssueTypesInsert.SelectIssueType,
        numPages: undefined,
        pageNumber: 1,
    
        attachedSelected: -1,
        uploadSelected: -1,
        uploadExisting: undefined,
        uploadContrast: 10,
        uploadBrightness: 10,
        uploadExpanded: false,
        uploadRotated: 0,
        upload: undefined,
        uploadIsImage: undefined,
        uploadShowAvailableFiles: false,


        attachedUploads: undefined,
        insertANewIssue: !Main.Instance.DatabaseDataState.insertANewIssue,
        manualWithdrawal: false,
        manualBonus: false,
        manualDeposit: false,
        resendVerificationEmail: false,
        manualCrif: false,
        showUploads: false,
        checkVETO: false,
        assignee: 0,
        newIssue: {
            assignTo: [],
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.All,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: Main.Instance.DatabaseDataState.playerCard.accountStatus,
                verified: undefined,
                // attachedUploads: undefined
            },
        },
    })
}

// function toggleShowUploads() {}

function getUploads() {
    axios.post(
        Config.uFiles,
        {
            folderName: Main.Instance.DatabaseDataState.playerCard.playerId
        },
        {
            headers: {
                'api-key': 'burek',
                'Content-Type' : 'application/json'
            }
        }
    ).then((response) => {
        
        // const uploadExisting: UploadDocumentsFiles[] = []
        if (response.data && response.data[0]) {
            // console.log (response.data[0])

            // for (const item of response.data[0]) {
                // if (item.id.startsWith(/*Config.documentsDirectory[Config.environment] + '/' + */Main.Instance.DatabaseDataState.playerCard.playerId)) {
                // item.ContentType = item.contentType === 'application/pdf' ? 'pdf' : 'image'
                // item.ContentType = item.id.endsWith('pdf') ? 'pdf' : 'image'
                // uploadExisting.push(item)
                // }
            // }
            // console.log (response.data[0])
            Logic.Type.New (Main.Instance.DatabaseDataState, {uploadExisting: response.data[0]})
        }
        // console.log (uploadExisting)
        
    }).catch((error) => console.log (error))
}

function toggleShowUploads() {
    gotoTop()
    if (!Main.Instance.DatabaseDataState.showUploads) {
        // const data = new FormData()
        // application/json {formData: 'fe'}
        // data.append('folderName', 'jp-uploads/' + Main.Instance.DatabaseDataState.playerCard.playerId)
        // console.log (data1.getAll('folderName'))

        getUploads()
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        // issueTypesInsert: IssueTypesInsert.SelectIssueType,
        selectedUpload: undefined,
        showUploads: !Main.Instance.DatabaseDataState.showUploads,
        insertANewIssue: false,
        manualWithdrawal: false,
        manualBonus: false,
        manualDeposit: false,
        resendVerificationEmail: false,
        manualCrif: false,
        assignee: 0,
        newIssue: {
            assignTo: [],
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.All,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined
            },
        },
    })
}

function verifyCrifManually(pep?: boolean) {
    gotoTop()
    Logic.Type.New (Main.Instance.DatabaseDataState, {
        manualCrif: !Main.Instance.DatabaseDataState.manualCrif,
        insertANewIssue: false,
        manualWithdrawal: false,
        manualBonus: false,
        manualDeposit: false,
        showUploads: false,
        checkVETO: false,
        resendVerificationEmail: false,
        manualPEP: pep !== undefined ? pep : false,
        manualAddress: pep !== undefined ? !pep : false,
        manualCrifResponse: false,
        manualCrifResolved: false,
        manualCrifResponseAddress: undefined,
        manualCrifResponsePEP: undefined,
        isAddressVerified: undefined,
        isPEP: undefined,
        assignee: 0,
        // resolved: false,
        newIssue: {
            assignTo: [],
            playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
            riskManagerId: Main.Instance.LoginState.id,
            issueType: IssueTypes.All,
            riskLevel: RiskLevel.Undefined,
            data: {
                previousStatus: undefined,

            },
        },
    })
}
/*
function selectSession(sessionId: string) {
    // console.log (sessionId)
    DbRoutes.wsGetSessionTransactions(sessionId)
    Logic.Type.New(Main.Instance.DatabaseDataState, {sessionId, gameSessions: undefined, gameSessionSelectedId: undefined, sessionTransactions: undefined})
}
*/
/*
function removeTag(i: number, id: number) {
    let custom: Custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    let player: number
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.players)
    for (let pos: number = 0; pos < Main.Instance.FrontendStoreState.custom.general.players.length; pos++) {
        if (Main.Instance.FrontendStoreState.custom.general.players[pos].id === id) {
            player = pos
            break
        }
    }
    if (player !== undefined) {
        custom.general.players[player].tags.splice(i, 1)
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
    }
}

function addTag(id: number) {
    if (Main.Instance.PlayerCardState.tag.length === 0) return
    let custom: Custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
    let player: number
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.players)
    for (let pos: number = 0; pos < Main.Instance.FrontendStoreState.custom.general.players.length; pos++) {
        if (Main.Instance.FrontendStoreState.custom.general.players[pos].id === id) {
            player = pos
            break
        }
    }
    if (player !== undefined) {
        if (custom.general.players[player].tags !== undefined) {
            for (const item of custom.general.players[player].tags) {
                if (item === Main.Instance.PlayerCardState.tag) {
                    return
                }
            }
        }
        if (custom.general.players[player].tags === undefined) custom.general.players[player].tags = []
        custom.general.players[player].tags.push(Main.Instance.PlayerCardState.tag)
        Logic.Type.New (Main.Instance.FrontendStoreState, {custom})
        Logic.Type.New (Main.Instance.PlayerCardState, {tag: ''})
    }
}

function getTags(tags: string[], id: number): JSX.Element {
    const Tags: JSX.Element[] = []
    let matching: boolean = false
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.playerTags)
        matching = (Main.Instance.FrontendStoreState.custom.playerTags.indexOf(Main.Instance.PlayerCardState.tag) !== -1 )
    if (matching! && Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.players)
        Main.Instance.FrontendStoreState.custom.general.players.map(player => player.id === id && player.tags.indexOf(Main.Instance.PlayerCardState.tag) !== -1? matching = false: void 0)

    let availableTags: JSX.Element[] = []
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.playerTags) {
        Main.Instance.FrontendStoreState.custom.playerTags.map(ta(g) => availableTags.push(<option value = {tag} key = {tag}/>))
    }
    if (tags) {
        for (let i: number = 0; i < tags.length; i++) {
            Tags.push (
                <button 
                    key = {i.toString()}
                    style = {{padding: '0px 3px'}}
                    onClick = {() => removeTag(i, id)}
                    className = "btn btn-default btn-xs mb3"
                >
                    {tags[i]}
                    {<FontAwesomeIcon icon = {faTimes} style = {{fontSize: '0.8em', marginLeft: '3px'}}/>}
                </button>)
        }
    }
    return (
        <>
            <div className = "ml mb3 fl">
                <input
                    list = "availableTags"
                    type = "text"
                    className = "tag-input"
                    style = {Main.Instance.PlayerCardState.tag.length > 0? matching? {borderColor: 'green', backgroundColor: '#63f18199'}: {borderColor: 'red', backgroundColor: 'rgba(255, 0, 0, 0.2)'} : {}}
                    value = {Main.Instance.PlayerCardState.tag}
                    onChange = {(e) => Logic.Type.New (Main.Instance.PlayerCardState, {tag: e.target.value})}
                />
                <datalist id = "availableTags" key = "availableTags">
                    {availableTags}
                </datalist>
                <button
                    disabled = {!matching}
                    type = "button"
                    className = "btn btn-default btn-xs mr0"
                    onClick = {() => addTag(id)}
                >
                    <FontAwesomeIcon icon = {faPlus}/>
                </button>
            </div>
            <div className = "player-card-tags">
                {Tags}
            </div>
        </>
    )
}
*/
function isIssue(): boolean {
    return !(
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.EventsTimeline &&
        Main.Instance.PlayerCardState.navET > PlayerCardButtonsET.Everything &&
        Main.Instance.PlayerCardState.navET < PlayerCardButtonsET.AllIssues
        ||
        Main.Instance.PlayerCardState.nav === PlayerCardButtons.Transactions
        // || Main.Instance.PlayerCardState.nav === PlayerCardButtons.PlayingHistory
    )
}

function setLanguage(language: string) {
    if (confirm(Assets.PleaseConfirmLanguageChange + '!')) {
        const c: ICommunicationUpdate = {
            allowEmail: Main.Instance.DatabaseDataState.playerCard.allowEmail,
            allowPost: Main.Instance.DatabaseDataState.playerCard.allowPost,
            allowSms: Main.Instance.DatabaseDataState.playerCard.allowSms,
            allowTelephone: Main.Instance.DatabaseDataState.playerCard.allowTelephone,
            language// Main.Instance.DatabaseDataState.playerCard.language,
        }

        DbRoutes.updateCommunications(Main.Instance.DatabaseDataState.playerCard.playerId, c)
/*        
        const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
        playerCard.language = language
        Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
*/
    }
}

function toggleCommunicationChannel(channel: number) { // email post sms telephone
    if (confirm(Assets.PleaseConfirmToggleCommunicationChannel + Assets.communicationChannels[channel] + '!')) {
        // const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
        const c: ICommunicationUpdate = {
            allowEmail: Main.Instance.DatabaseDataState.playerCard.allowEmail,
            allowPost: Main.Instance.DatabaseDataState.playerCard.allowPost,
            allowSms: Main.Instance.DatabaseDataState.playerCard.allowSms,
            allowTelephone: Main.Instance.DatabaseDataState.playerCard.allowTelephone,
            language: Main.Instance.DatabaseDataState.playerCard.language,
        }
        
        if (channel === 0) {
            c.allowEmail = !c.allowEmail
            // playerCard.allowEmail = !playerCard.allowEmail
        } else if (channel === 1) {
            c.allowPost = !c.allowPost
            // playerCard.allowPost = !playerCard.allowPost
        } else if (channel === 2) {
            c.allowSms = !c.allowSms
            // playerCard.allowSms = !playerCard.allowSms
        } else if (channel === 3) {
            c.allowTelephone = !c.allowTelephone
            // playerCard.allowTelephone = !playerCard.allowTelephone
        }

        DbRoutes.updateCommunications(Main.Instance.DatabaseDataState.playerCard.playerId, c)
        // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
    }
}


function getLanguages(): JSX.Element[] {
    const languages: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.languages) {
        for (const item of Main.Instance.DatabaseDataState.custom.general.languages) {
            languages.push(<button onClick = {() => setLanguage(item.locale)} type = "button" title = {item.locale} key = {item.locale} className = {Main.Instance.DatabaseDataState.playerCard.language === item.locale ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}>{item.name}</button>)
        }
    }
    return languages
}

function displayBirthday(newBirthday: boolean): string {
    let birthday: string = ''
    if (newBirthday === true) {
        birthday = Main.Instance.DatabaseDataState.playerCard.birthdayNew
    } else {
        birthday = Main.Instance.DatabaseDataState.playerCard.birthday
    }

    const arr = newBirthday ? birthday.split('.') : birthday.split('-') 
    let str = ''
    for (let i: number = arr.length - 1; i >= 0; i--) {
        str += arr[i]
        if (i > 0) {
            str += newBirthday ? '-' : '.'
        }
    }

    return str
}

function getImportantInformation(id: number, mt?: boolean, short?: boolean): JSX.Element[] {
    const gii: JSX.Element[] = []
    if (id === 0 || id === 1) {
        gii.push(
            <div key = "0" className = {mt ? 'clearfix mt pr' : 'clearfix mt2 pr'}>
                <div title = {Assets.PlayerID} style = {getStyle('playerId')} className = {'label button-default w20px fl'}>
                    {Assets.ID}
                </div>
                <div className = "ml3 fl pr">
                    <input
                        style = {{width: short ? '267px' : '297px'}}
                        disabled = {!canBeModifiedByAdmin('playerId')}
                        onChange = {(e) => changeText('id', e.target.value)}
                        value = {Main.Instance.DatabaseDataState.playerCard.playerId}
                        className = "pcti"
                        type = "text"
                    />
                    {buttonCopyToClipboard(Assets.PlayerID, 'playerId', 0)}
                </div>
                {/*!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.acceptedTC, 'activity-image3', {position: 'absolute', top: '7px', left: short ? '82px' : '112px'}, Assets.AcceptedTC)*/}
            </div>,
        )
        /*
        if (!short) {
            gii.push(
                <div key = "1" className = "clearfix mt2">
                    <div style = {getStyle('language')} className = "label button-default w110px fl">{Assets.Player_ + ' ' + Assets.Language.toLowerCase()}</div>
                    <div className = "ml3 fl">{getLanguages()}</div>
                </div>,
            )
        }
        */
        if (id === 1) {
            gii.push(
                <div key = "1" className = "clearfix mt2 pr">
                    <div style = {getStyle('username')} className = {short ? 'label button-default w100px fl' : 'label button-default w130px fl'}>{Assets.Username}</div>
                    <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('username')} onChange = {(e) => changeText('username', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.username} className = "pcti" type = "text"/></div>
                    {!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.loggedIn, 'activity-image3', {position: 'absolute', top: '7px', left: short ? '82px' : '112px'}, Assets.OnlineRightNow)}
                </div>,
            )
            
        }
        
        /*
        if (!Main.Instance.PlayerCardState.hiddenPlayerCard) {
            gii.push(
                <div
                    key = "2"
                    style = {{background: Main.Instance.DatabaseDataState.playerCard.acceptedTC ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}
                    className = "clearfix mt2 pr"
                >
                    {Main.Instance.DatabaseDataState.playerCard.acceptedTC ? Assets.AcceptedTC : Assets.AcceptedNotTC}
                </div>,
            )
        }
        */
       
        if (!Main.Instance.PlayerCardState.hiddenPlayerCard) {
            gii.push(
                <div 
                    key = "3"
                    className = "clearfix mt2 pr"
                >
                    <div style = {{float: 'left', width: '49%', marginRight: '2%', background: Main.Instance.DatabaseDataState.playerCard.loggedIn ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}>
                        {Main.Instance.DatabaseDataState.playerCard.loggedIn ? Assets.OnlineRightNow : Assets.NotOnlineRightNow}
                    </div>
                    <button title = {Main.Instance.DatabaseDataState.playerCard.testAccount ? Assets.TestAccountCannotBeReverted : Assets.TurnIntoATestAccount} disabled = {Main.Instance.DatabaseDataState.playerCard.testAccount/* ? true : false*/} onClick = {() => toggleTestAccount()} className = {Main.Instance.DatabaseDataState.playerCard.testAccount ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'} style = {{fontSize: '13px', padding: '0px', float: 'left', display: 'block', width: '49%', textAlign: 'center'}}>
                        <FontAwesomeIcon icon = {faExclamationTriangle}/>&nbsp;
                        {Main.Instance.DatabaseDataState.playerCard.testAccount ? Assets.TestAccount : Assets.NotATestAccount}
                    </button>
                </div>,
            )
        }
    } else if (id === 2 || id === 9) {
        gii.push(
            <div key = "0" className = {short ? 'clearfix mt2 pr' : 'clearfix mt pr'}>
                <div style = {getStyle('realBalance')} className = {short ? 'label button-default w110px fl' : 'label button-default w130px fl'}>{Assets.RealBalance}</div>
                <div className = "ml3 fl clearfix pr">
                    <input style = {{fontWeight: 700, width: short ? '99px' : '149px'}} disabled = {!canBeModifiedByAdmin('realBalance')} onChange = {(e) => changeText('realBalance', e.target.value)} value = {numberWithCommas(Main.Instance.DatabaseDataState.playerCard.realBalance)} className = "pcti fl" type = "text"/>
                    <input style = {{width: '35px', marginLeft: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>
                    {buttonCopyToClipboard(Assets.RealBalance, 'realBalance', 38)}
                </div>
            </div>,
        )
        if (id === 2) {
            gii.push(
                <div key = "1" className = "clearfix mt2 pr">
                    <div style = {getStyle('bonusBalance')} className = {short ? 'label button-default w110px fl' : 'label button-default w130px fl'}>{Assets.BonusBalance}</div>
                    <div className = "ml3 fl clearfix pr">
                        <input style = {{fontWeight: 700, width: short ? '99px' : '149px'}} disabled = {!canBeModifiedByAdmin('bonusBalance')} onChange = {(e) => changeText('bonusBalance', e.target.value)} value = {numberWithCommas(Main.Instance.DatabaseDataState.playerCard.bonusBalance)} className = "pcti fl" type = "text"/>
                        <input style = {{width: '35px', marginLeft: '3px', paddingLeft: '1px', paddingRight: '0px'}} disabled = {true} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti fl" type = "text"/>
                        {buttonCopyToClipboard(Assets.BonusBalance, 'bonusBalance', 38)}
                        {/*!Main.Instance.PlayerCardState.hiddenPlayerCard ? buttonShowBonus() : void 0*/}
                        {/*!Main.Instance.PlayerCardState.hiddenPlayerCard ? buttonAddBonus() : void 0*/}
                    </div>
                </div>,
            )
/*
            if (!Main.Instance.PlayerCardState.hiddenPlayerCard && Main.Instance.PlayerCardState.bonusInfo) {
                if (Main.Instance.PlayerCardState.bonusInfo.length === 0) {
                    gii.push(
                        <div key = "2" className = "mt2 pr" style = {{textAlign: 'center', fontWeight: 600}}>
                            {Assets.ThereAreNoBonusesForThisPlayer}
                        </div>
                    )
                } else {
                    // console.log (Main.Instance.PlayerCardState.bonusInfo)
                    for (let i: number = 0; i < Main.Instance.PlayerCardState.bonusInfo.length; i++) {
                        const ITEM: IBonusInfo = Main.Instance.PlayerCardState.bonusInfo[i]
                        gii.push(
                            <div key = {(i+2).toString()} className = "clearfix mt2 pr">
                                <div title = {Assets.Bonus + ' ' + Assets.ID} className = "fl" style = {{width: '40px', background: '#f8f8f8'}}>
                                    {ITEM.bonusId}
                                </div>
                                <div title = {Assets.CreatedAt + ':'} className = "fl" style = {{width: '139px', background: '#eee'}}>
                                    &nbsp;{GetDate(ITEM.created)}
                                </div>
                                <div title = {Assets.Amount + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '70px', background: '#f8f8f8'}}>
                                    &nbsp;{ITEM.amount}
                                </div>
                                <div title = {Assets.Amount + ' ' + Assets.Converted.toLowerCase() + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '70px', background: '#eee'}}>
                                    &nbsp;{ITEM.amountConverted}
                                </div>
                            </div>
                        )
                        gii.push(
                            <div key = {(i+2).toString() + '_'} className = "clearfix mt2 pr" style = {{marginBottom: '5px', borderBottom: '1px solid #999'}}>
                                <FontAwesomeIcon title = {Assets.Active} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.active ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faLightbulb}/>
                                <FontAwesomeIcon title = {Assets.Completed} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.completed ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faCheck}/>
                                <FontAwesomeIcon title = {Assets.Cancelled} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.canceled ? 'rgb(217, 83, 79)' : '#bbb'}} icon = {faTimes}/>

                                <div title = {Assets.Wagered + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '90px', background: '#f8f8f8'}}>
                                    &nbsp;{ITEM.wagered.toFixed(2)}
                                </div>
                                <div title = {Assets.WageringRequirement + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '90px', background: '#eee'}}>
                                    &nbsp;{ITEM.wagerRequirement.toFixed(2)}
                                </div>
                                {ITEM.wagerRequirement ?
                                <div title = {Assets.Playthrough} className = "fl" style = {{width: '90px', background: '#f8f8f8'}}>
                                    &nbsp;{(ITEM.wagered / ITEM.wagerRequirement * 100).toFixed(2) + '%'}
                                </div> : void 0}

                            </div>
                        )
                    }
                }
            }
*/
        }
    } else if (id === 3) {
        gii.push(
            <div key = "0" className = {mt ? 'clearfix mt pr' : 'clearfix mt2 pr'}>
                <div style = {getStyle('firstName')} className = {short ? 'label button-default w80px fl' : 'label button-default w130px fl'}>{Assets.FirstName}</div>
                <div className = "ml3 fl pr">
                    <input disabled = {!canBeModifiedByAdmin('firstName')} onChange = {(e) => changeText('firstName', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.firstName} className = "pcti" type = "text"/>
                    {buttonCopyToClipboard(Assets.FirstName, 'firstName', 0)}
                </div>
                {short ? void 0 : button2()}
                {isNameChanged() && !short && Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined ? Button2() : void 0}
            </div>,
        )

        if (Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined) {
            gii.push(
                <div key = "2" className = "clearfix mt2 pr">
                    <div onClick = {() => changeText('firstNameNew', Main.Instance.DatabaseDataState.playerCard.firstName)} title = {Assets.Copy + ' ' + Assets.FirstName} style = {getStyle('firstNameNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.FirstName.toLowerCase()}</div>
                    <div className = "ml3 fl">
                        <input placeholder = {Assets.PasteNewFirstName} disabled = {!canBeModifiedByAdmin('firstNameNew')} onChange = {(e) => changeText('firstNameNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.firstNameNew} className = "pcti" type = "text"/>
                    </div>
                </div>,
            )
        }

        gii.push(
            <div key = "1" className = "clearfix mt2 pr">
                <div style = {getStyle('lastName')} className = {short ? 'label button-default w80px fl' : 'label button-default w130px fl'}>{Assets.LastName}</div>
                <div className = "ml3 fl">
                    <input disabled = {!canBeModifiedByAdmin('lastName')} onChange = {(e) => changeText('lastName', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.lastName} className = "pcti" type = "text"/>
                    {buttonCopyToClipboard(Assets.LastName, 'lastName', 0)}
                </div>
                {short ? void 0 : button2()}
                {isNameChanged() && !short && Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined ? Button2() : void 0}
            </div>,
        )

        if (Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined) {
            gii.push(
                <div key = "3" className = "clearfix mt2 pr">
                    <div onClick = {() => changeText('lastNameNew', Main.Instance.DatabaseDataState.playerCard.lastName)} title = {Assets.Copy + ' ' + Assets.LastName} style = {getStyle('lastNameNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.LastName.toLowerCase()}</div>
                    <div className = "ml3 fl"><input placeholder = {Assets.PasteNewLastName} disabled = {!canBeModifiedByAdmin('lastNameNew')} onChange = {(e) => changeText('lastNameNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.lastNameNew} className = "pcti" type = "text"/></div>
                </div>,
            )
        }        
    } else if (id === 4) {
        gii.push(
            <div key = "1" className = {short ? 'clearfix mt2 pr' : 'clearfix mt2 pr'}>
                <div style = {getStyle('birthday')} className = {short ? 'label button-default w80px fl' : 'label button-default w130px fl'}>{Assets.Birthday}</div>
                <div className = "ml3 fl pr">
                    <input style = {{width: short ? '' : '104px'}} disabled = {!canBeModifiedByAdmin('birthday')} onChange = {(e) => changeText('birthday', e.target.value)} value = {displayBirthday(false)} className = "pcti" type = "text"/>
                    {buttonCopyToClipboard(Assets.Birthday, 'birthday', 0)}
                </div>

                {short ? void 0 :
                <>
                    <div style = {getStyle('gender')} className = "label button-default w60px fl ml3">{Assets.Gender}</div>
                    <div className = "ml3 fl"><input style = {{width: short ? '' : '17px', paddingLeft: short ? '' : '0px'}} disabled = {!canBeModifiedByAdmin('gender')} onChange = {(e) => changeText('gender', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.gender} className = "pcti" type = "text"/></div>
                </>}
                {short ? void 0 : button2()}
                {isNameChanged() && !short && Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined ? Button2() : void 0}
            </div>,
        )

        if (Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined) {
            gii.push(
                <div key = "4" className = {short ? 'clearfix mt2 pr' : 'clearfix mt2 pr'}>
                    <div onClick = {() => changeText('birthdayNew', displayBirthday(false))} title = {Assets.Copy + ' ' + Assets.Birthday} style = {getStyle('birthdayNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.Birthday.toLowerCase()}</div>
                    <div className = "ml3 fl"><input title = {Assets.PasteNewBirthday} placeholder = {Assets.PasteBirthday} style = {{width: short ? '' : '104px'}} disabled = {!canBeModifiedByAdmin('birthdayNew')} onChange = {(e) => changeText('birthdayNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.birthdayNew} className = "pcti" type = "text"/></div>
                    {short ? void 0 :
                    <>
                        <div onClick = {() => changeText('genderNew', Main.Instance.DatabaseDataState.playerCard.gender)} title = {Assets.Copy + ' ' + Assets.Gender} style = {getStyle('genderNew')} className = "label button-default w60px fl ml3">{Assets.New + ' ' + Assets.Gender.charAt(0).toLowerCase() + '. '}</div>
                        <div className = "ml3 fl"><input title = {Assets.PasteNewGender} style = {{width: short ? '' : '17px', paddingLeft: short ? '' : '0px'}} disabled = {!canBeModifiedByAdmin('genderNew')} onChange = {(e) => changeText('genderNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.genderNew} className = "pcti" type = "text"/></div>
                    </>}
                </div>,
            )
        }

        if (!short) {
            gii.push(
                <div key = "3" className = "clearfix mt2 pr">
                    <div style = {getStyle('nationality')} className = "label button-default w130px fl">{Assets.Nationality}</div>
                    <div className = "ml3 fl">
                        <select title = {canBeModifiedByAdmin('nationality') ? '' : Assets.NationalityOfThePlayerCannotBeChanged} value = {Main.Instance.DatabaseDataState.playerCard.nationality ? Main.Instance.DatabaseDataState.playerCard.nationality.toUpperCase() : '0'} style = {{width: '187px', height: '22px', paddingLeft: '1px', background: canBeModifiedByAdmin('nationality') ? '#fff' : ''}} disabled = {!canBeModifiedByAdmin('nationality')} onChange = {(e) => changeText('nationality', e.target.value)}>
                            {getOptions(2, true)}
                        </select>
                    </div>
                    {button6()}
                    {isNationalityChanged() && Main.Instance.DatabaseDataState.playerCard.nationalityNew !== undefined ? Button6() : void 0}
                </div>,
            )
        }

        if (Main.Instance.DatabaseDataState.playerCard.nationalityNew !== undefined) {
            gii.push(
                <div key = "5" className = {short ? 'clearfix mt2 pr' : 'clearfix mt2 pr'}>
                    <div onClick = {() => changeText('nationalityNew', Main.Instance.DatabaseDataState.playerCard.nationality)} title = {Assets.Copy + ' ' + Assets.Nationality} style = {getStyle('nationalityNew')} className = "label button-default w130px fl">{Assets.New + ' ' + Assets.Nationality.toLowerCase()}</div>
                    <div className = "ml3 fl">
                        <select title = {canBeModifiedByAdmin('nationalityNew') ? '' : Assets.NationalityOfThePlayerCannotBeChanged} value = {Main.Instance.DatabaseDataState.playerCard.nationalityNew ? Main.Instance.DatabaseDataState.playerCard.nationalityNew.toUpperCase() : '0'} style = {{width: '187px', height: '22px', paddingLeft: '1px', background: canBeModifiedByAdmin('nationalityNew') ? '#fff' : ''}} disabled = {!canBeModifiedByAdmin('nationalityNew')} onChange = {(e) => changeText('nationalityNew', e.target.value)}>
                            {getOptions(2, true)}
                        </select>                        
                        {/*<input placeholder = {Assets.PasteNewNationality} disabled = {!canBeModifiedByAdmin('nationalityNew')} onChange = {(e) => changeText('nationalityNew', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.nationalityNew} className = "pcti" type = "text"/>*/}
                    </div>
                </div>,
            )
        }

        gii.push(
            <div key = "0" className = {mt ? 'clearfix mt pr' : 'clearfix mt2 pr'}>
                <div style = {getStyle('email')} className = {short ? 'label button-default w80px fl' : 'label button-default w80px fl'}>{Assets.Email}</div>
                <div className = "ml3 fl pr">
                    <input style = {{width: short ? '' : '237px'}} disabled = {!canBeModifiedByAdmin('email')} onChange = {(e) => changeText('email', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.email} className = "pcti" type = "text"/>
                    {buttonCopyToClipboard(Assets.Email, 'email', 0)}
                </div>
                {!Main.Instance.PlayerCardState.hiddenPlayerCard ? void 0 : activeImage(Main.Instance.DatabaseDataState.playerCard.isEmailVerified, 'activity-image3', {position: 'absolute', top: '7px', left: short ? '62px' : '112px'}, Assets.EmailVerified)}
                {short ? void 0 : button3()}
                {isEmailChanged() && !short && Main.Instance.DatabaseDataState.playerCard.emailNew !== undefined ? Button3() : void 0}
            </div>,
        )

        if (!Main.Instance.PlayerCardState.hiddenPlayerCard) {
            gii.push(
                <div
                    key = "2"
                    className = "clearfix mt2 pr"
                >
                    <div className = "fl"
                        style = {{width: '268px', background: Main.Instance.DatabaseDataState.playerCard.isEmailVerified ? '#5cb85c' : '#d9534f', textAlign: 'center', fontWeight: 600, color: '#fff'}}
                    >
                        {Main.Instance.DatabaseDataState.playerCard.isEmailVerified ? Assets.EmailVerified : Assets.EmailNotVerified}
                    </div>
                    <div className = "fl">
                        <button style = {{marginLeft: '4px', width: '48px', paddingLeft: '0px', paddingRight: '0px'}} className = {Main.Instance.DatabaseDataState.resendVerificationEmail ? 'btn btn-warning btn-xs mr0' : 'btn btn-default btn-dark btn-xs mr0'} onClick = {() => toggleResendVerificationEmail()}>
                            {Assets.Resend}
                        </button>
                    </div>
                </div>,
            )
        }

    } else if (id === 5) {
        gii.push(
            <div key = "0" className = {mt ? 'clearfix mt pr' : 'clearfix mt2 pr'}>
                <div style = {getStyle('registered')} className = {short ? 'label button-default w80px fl' : 'label button-default w130px fl'}>{Assets.Registered}</div>
                <div className = "ml3 fl"><input style = {short ? {width: '167px'} : {}} disabled = {!canBeModifiedByAdmin('registered')} onChange = {(e) => changeText('issueDate', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.registered + ' ' + Assets_.UTC} className = "pcti" type = "text"/></div>
            </div>,
        )
        gii.push(
            <div key = "1" className = "clearfix mt2">
                <div style = {getStyle('lastLogin')} className = {short ? 'label button-default w80px fl' : 'label button-default w130px fl'}>{Assets.LastLogin}</div>
                <div className = "ml3 fl"><input style = {short ? {width: '167px'} : {}} disabled = {!canBeModifiedByAdmin('lastLogin')} onChange = {(e) => changeText('lastLogin', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.lastLogin === Assets_.NA ? Main.Instance.DatabaseDataState.playerCard.lastLogin : Main.Instance.DatabaseDataState.playerCard.lastLogin + ' ' + Assets_.UTC} className = "pcti" type = "text"/></div>
            </div>,
        )        
    } else if (id === 6) {
        gii.push(
        <div key = "0" className = "clearfix mt2">
            <div className = "clearfix fl" style = {{width: short ? '50%' : '170px'}}>
                <div style = {getStyle('currency')} className = {short ? 'label button-default w100px fl' : 'label button-default w130px fl'}>{Assets.Currency}</div>

                <div className = "ml3 fl">
                    <input style = {{width: '35px', paddingLeft: '1px', paddingRight: '0px', marginRight: '1px'}} maxLength={3} disabled = {!canBeModifiedByAdmin('currency')} onChange = {(e) => changeText('currency', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.currency} className = "pcti" type = "text"/>
                </div>
            </div>
            <div className = "clearfix fl">
                <div title = {Assets.mf} style = {getStyle('gender')} className = {short ? 'label button-default w100px fl' : 'label button-default w130px fl'}>{Assets.Gender}</div>
                <div className = "ml3 fl"><input style = {{width: '17px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}} maxLength={1} disabled = {!canBeModifiedByAdmin('gender')} onChange = {(e) => changeText('gender', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.gender === undefined || Main.Instance.DatabaseDataState.playerCard.gender.length === 0 ? '' : Main.Instance.DatabaseDataState.playerCard.gender === 'm' ? 'm' : 'f'} className = "pcti" type = "text"/></div>
            </div>
        </div>,
        )
    
        gii.push(
        <div key = "1" className = "clearfix mt2">
            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                <div style = {getStyle('allowLogin')} className = {short ? 'label button-default w100px fl' : 'label button-default w130px fl'}>{Assets.AllowToLogin}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('allowLogin') ? toggle('allowLogin') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.allowLogin, 'activity-image3')}
                    </a>
                </div>
            </div>

            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                <div style = {getStyle('allowPlay')} className = {short ? 'label button-default w100px fl' : 'label button-default w130px fl ml'}>{Assets.AllowToPlay}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('allowPlay') ? toggle('allowPlay') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.allowPlay, 'activity-image3')}
                    </a>
                </div>
            </div>
        </div>,
        )    
    } else if (id === 7) {
        gii.push(
            <div key = "0" className = "clearfix mt2">
                <div className = "clearfix fl" style = {{width: short ? '50%' : '160px', marginLeft: short ? '-15px' : ''}}>
                    <div style = {getStyle('allowDeposit')} className = "label button-default w130px fl">{Assets.AllowToDeposit}</div>
                    <div className = "ml3 fl">
                        <a onClick = {() => canBeModifiedByAdmin('allowDeposit') ? toggle('allowDeposit') : void 0} className = "btn btn-default btn-sm btn-Anything">
                            {activeImage(Main.Instance.DatabaseDataState.playerCard.allowDeposit, 'activity-image3')}
                        </a>
                    </div>
                </div>
    
                <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                    <div style = {getStyle('allowWithdraw')} className = {short ? 'label button-default w130px fl' : 'label button-default w130px fl ml'}>{Assets.AllowToWithdraw}</div>
                    <div className = "ml3 fl">
                        <a onClick = {() => canBeModifiedByAdmin('allowWithdraw') ? toggle('allowWithdraw') : void 0} className = "btn btn-default btn-sm btn-Anything">
                            {activeImage(Main.Instance.DatabaseDataState.playerCard.allowWithdraw, 'activity-image3')}
                        </a>
                    </div>
                </div>
            </div>,
            )
        gii.push (
        <div key = "1" className = "clearfix mt2">
            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px', marginLeft: short ? '-15px' : ''}}>
                <div style = {getStyle('isSuperVerified', !Main.Instance.DatabaseDataState.playerCard.isGettingSuperVerified)} className = "label button-default w130px fl">{Assets.FullyIdentified}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isSuperVerified', Main.Instance.DatabaseDataState.playerCard.isGettingSuperVerified) ? toggle('isSuperVerified') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isSuperVerified, 'activity-image3')}
                    </a>
                </div>
            </div>

            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                <div title = {Assets.GettingFullyIdentified} style = {getStyle('isGettingSuperVerified', Main.Instance.DatabaseDataState.playerCard.isSuperVerified)} className = {short ? 'label button-default w130px fl' : 'label button-default w130px fl ml'}>{Assets.GettingFullyIdentifiedShort}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isGettingSuperVerified', !Main.Instance.DatabaseDataState.playerCard.isSuperVerified) ? toggle('isGettingSuperVerified') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isGettingSuperVerified, 'activity-image3')}
                    </a>
                </div>
            </div>
        </div>,
        )
    } else if (id === 8) {
        gii.push(
        <div key = "0" className = "clearfix mt2">
            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px', marginLeft: short ? '-15px' : ''}}>
                <div style = {getStyle('isDocumentVerified', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentVerified}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isDocumentVerified', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded) ? toggle('isDocumentVerified') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isDocumentVerified, 'activity-image3')}
                    </a>
                </div>
            </div>
            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                <div title = {Assets.DocumentUploaded} style = {getStyle('isDocumentUploaded', Main.Instance.DatabaseDataState.playerCard.isDocumentVerified)} className = {short ? 'label button-default w130px fl' : 'label button-default w130px fl ml'}>{Assets.DocumentUploadedShort}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isDocumentUploaded', !Main.Instance.DatabaseDataState.playerCard.isDocumentVerified) ? toggle('isDocumentUploaded') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded, 'activity-image3')}
                    </a>
                </div>
            </div>
        </div>,
        )
        // isDocument
        gii.push(
        <div key = "1" className = "clearfix mt2">
            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px', marginLeft: short ? '-15px' : ''}}>
                <div style = {getStyle('isUtilityVerified', !Main.Instance.DatabaseDataState.playerCard.isUtilityUploaded)} className = "label button-default w130px fl">{Assets.UtilityBillVerified}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isUtilityVerified', Main.Instance.DatabaseDataState.playerCard.isUtilityUploaded) ? toggle('isUtilityVerified') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isUtilityVerified, 'activity-image3')}
                    </a>
                </div>
            </div>

            <div className = "clearfix fl" style = {{width: short ? '50%' : '160px'}}>
                <div title = {Assets.UtilityBillUploaded} style = {getStyle('isUtilityUploaded', Main.Instance.DatabaseDataState.playerCard.isUtilityVerified)} className = {short ? 'label button-default w130px fl' : 'label button-default w130px fl ml'}>{Assets.UtilityBillUploadedShort}</div>
                <div className = "ml3 fl">
                    <a onClick = {() => canBeModifiedByAdmin('isUtilityUploaded', !Main.Instance.DatabaseDataState.playerCard.isUtilityVerified) ? toggle('isUtilityUploaded') : void 0} className = "btn btn-default btn-sm btn-Anything">
                        {activeImage(Main.Instance.DatabaseDataState.playerCard.isUtilityUploaded, 'activity-image3')}
                    </a>
                </div>
            </div>
        </div>,
        ) 
    }
    return gii
}

function getOverview(RG: boolean): JSX.Element {
    return RG ?
    <>
    {Main.Instance.DatabaseDataState.playerCard.selfAssessment ?
    <div className = "clearfix mt">
        <div title = {Assets.LastSelfAssessmentTaken} style = {getStyle(Main.Instance.DatabaseDataState.playerCard.selfAssessment.length === 0 ? 'selfAssessment' : 'SelfAssessment')} className = "label button-default w130px fl">{Assets.LastAssessment}</div>
        <div className = "ml3 fl clearfix">
            { Main.Instance.DatabaseDataState.playerCard.selfAssessment.length === 0 ?
            <span className = "pcti" style = {{marginLeft: '5px',marginRight: '0px', width: '177px'}}>
                {Assets.HasNotTakenTheTestYet}
            </span>
            :
            <>
            {Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].date ?
            <input /*title = {Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].date + ' ' + Assets_.UTC}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeText('selfAssessment', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].date + ' ' + Assets_.UTC} className = "pcti fl" type = "text"/> : void 0}
            {Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].riskLevel ?
            <input /*title = {Assets.RiskLevel}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px', marginRight: '3px', paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeNumber('selfAssessment', e.target.value)} value = {Assets.RiskLevel + ': ' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].riskLevel]} className = {'pcti fl risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].riskLevel]}  type = "text"/> : void 0}
            {Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].totalPoints ?
            <input /*title = {Assets.TotalPoints}*/ disabled = {!canBeModifiedByAdmin('selfAssessment')} style = {{width: '187px', paddingLeft: '1px', paddingRight: '0px'}} onChange = {(e) => changeNumber('selfAssessment', e.target.value)} value = {Assets.TotalPoints + ': ' + Main.Instance.DatabaseDataState.playerCard.selfAssessment[Main.Instance.DatabaseDataState.playerCard.selfAssessment.length - 1].totalPoints.toFixed(1)} className = "pcti fl" type = "text"/> : void 0}
            </>}
        </div>
    </div> : void 0}
    {Main.Instance.DatabaseDataState.playerCard.selfAssessment && Main.Instance.DatabaseDataState.playerCard.selfAssessment.length > 1 ?
    getSelfAssessment() : void 0}
    {/*getResponsibleGamingControl()*/}
    {getResponsibleGamingLimits()}
    {getCooloffs()}
    </> :
    <>
        {getImportantInformation(5, true)}
        {getImportantInformation(6)}
        {getImportantInformation(7)}
        {/*console.log (Main.Instance.DatabaseDataState.playerCard)*/}
        {/*Main.Instance.PlayerCardState.expandedPlayerCard ? */}
        <>
        <div className = "clearfix mt">
            <div style = {getStyle('documentType', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentType}</div>
            <div className = "ml3 fl"><select disabled = {!canBeModifiedByAdmin('documentType', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentType', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentType ? Main.Instance.DatabaseDataState.playerCard.documentType : '0'} className = "pcti">{getOptionsKYC('identification')}</select></div>
        </div>

        <div className = "clearfix mt2">
            <div style = {getStyle('issuingAuthority', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.IssuingAuthority}</div>
            <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('issuingAuthority', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('issuingAuthority', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.issuingAuthority ? Main.Instance.DatabaseDataState.playerCard.issuingAuthority : ''} className = "pcti" type = "text"/></div>
        </div>

        <div className = "clearfix mt2">
            <div style = {getStyle('documentNumber', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentNumber}</div>
            <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('documentNumber', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentNumber', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentNumber ? Main.Instance.DatabaseDataState.playerCard.documentNumber : ''} className = "pcti" type = "text"/></div>
        </div>

        <div className = "clearfix mt2">
            <div style = {getStyle('documentExpiry', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentExpiryDateShort}</div>
            <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('documentExpiry', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('documentExpiry', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.documentExpiry ? Main.Instance.DatabaseDataState.playerCard.documentExpiry : ''} className = "pcti" type = "text"/></div>
        </div>

        <div className = "clearfix mt2">
            <div style = {getStyle('issueDate', !Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} className = "label button-default w130px fl">{Assets.DocumentIssueDateShort}</div>
            <div className = "ml3 fl"><input disabled = {!canBeModifiedByAdmin('issueDate', Main.Instance.DatabaseDataState.playerCard.isDocumentUploaded)} onChange = {(e) => changeText('issueDate', e.target.value)} value = {Main.Instance.DatabaseDataState.playerCard.issueDate ? Main.Instance.DatabaseDataState.playerCard.issueDate : ''} className = "pcti" type = "text"/></div>
        </div>
        </>
        {/*: void 0*/}
        {getImportantInformation(8)}
    </> 
}

function Button1(): JSX.Element {
    return (
        <button
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert}
            title = {Assets.saveChanges}
            onClick = {() => 
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.CRIFAddressChangedOperator,
                    riskLevel: RiskLevel.Undefined,
                    resolved: true,
                    data: {
                        address: Main.Instance.DatabaseDataState.playerCard.addressNew,
                        streetNumber: Main.Instance.DatabaseDataState.playerCard.streetNumberNew,
                        city: Main.Instance.DatabaseDataState.playerCard.cityNew,
                        postalCode: Main.Instance.DatabaseDataState.playerCard.postalCodeNew,
                        country: Main.Instance.DatabaseDataState.playerCard.countryNew
                    },
                })
            }
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '112px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button2(): JSX.Element {

    return (
        <button
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert}
            title = {Assets.saveChanges}
            onClick = {() => {
                const birthdayNew = displayBirthday(true)
                // console.log(birthdayNew)
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.PEPCheckNameChanged,
                    riskLevel: RiskLevel.Undefined,
                    resolved: true,
                    data: {
                        gender: Main.Instance.DatabaseDataState.playerCard.genderNew,
                        birthday: birthdayNew,
                        firstName: Main.Instance.DatabaseDataState.playerCard.firstNameNew,
                        lastName: Main.Instance.DatabaseDataState.playerCard.lastNameNew
                    },
                })
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '112px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button3(): JSX.Element {
    return (
        <button
            title = {Main.Instance.DatabaseDataState.emailAlreadyUsed ? Assets.EmailIsAlreadyUsed : Assets.saveChanges}
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert || !Main.Instance.DatabaseDataState.emailNewValid || Main.Instance.DatabaseDataState.emailAlreadyUsed}
            onClick = {() =>
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.ChangedEmail,
                    resolved: true,
                    riskLevel: RiskLevel.Undefined,
                    data: {
                        email: Main.Instance.DatabaseDataState.playerCard.emailNew
                    },
                })    
            }
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '62px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button4(): JSX.Element {
    return (
        <button
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert}
            title = {Assets.saveChanges}
            onClick = {() =>
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.ChangedPhoneNumber,
                    resolved: true,
                    riskLevel: RiskLevel.Undefined,
                    data: {
                        phoneNumber: Main.Instance.DatabaseDataState.playerCard.phoneNumberNew
                    },
                })    
            }
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '112px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button5(): JSX.Element {
    return (
        <button
            title = {Assets.saveChanges}
            onClick = {() =>
                // DbRoutes.wsInsertIssue({
                //     assignTo: [],
                //     playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                //     riskManagerId: Main.Instance.LoginState.id,
                //     issueType: IssueTypes.ChangedBankingDetails,
                //     resolved: true,
                //     riskLevel: RiskLevel.Undefined,
                //     data: {
                //         beneficiaryName: Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew,
                //         iban: Main.Instance.DatabaseDataState.playerCard.ibanNew,
                //         bic: Main.Instance.DatabaseDataState.playerCard.bicNew,
                //     },
                // })
                DbRoutes.updatePaymentData({
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId, 
                    paymentData: {
                        beneficiaryName: Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew,
                        iban: Main.Instance.DatabaseDataState.playerCard.ibanNew,
                        bic: Main.Instance.DatabaseDataState.playerCard.bicNew,
                    },
                })
            }
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert || Main.Instance.DatabaseDataState.playerCard.bicNew && Main.Instance.DatabaseDataState.playerCard.bicNew.length !== 0 && Main.Instance.DatabaseDataState.playerCard.bicNew.length !== 8 && Main.Instance.DatabaseDataState.playerCard.bicNew.length !== 11}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '42px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button6(): JSX.Element {
    return (
        <button
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert}
            title = {Assets.saveChanges}
            onClick = {() =>
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.ChangedNationality,
                    resolved: true,
                    riskLevel: RiskLevel.Undefined,
                    data: {
                        nationality: Main.Instance.DatabaseDataState.playerCard.nationalityNew
                    },
                })    
            }
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '2px', left: '112px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function Button8(): JSX.Element {
    return (
        <button
            title = {Main.Instance.DatabaseDataState.usernameAlreadyUsed ? Assets.UsernameIsAlreadyUsed : Assets.saveChanges}
            disabled = {Main.Instance.DatabaseDataState.IssuesInsert || !Main.Instance.DatabaseDataState.usernameNewValid || Main.Instance.DatabaseDataState.usernameAlreadyUsed}
            // title = {Assets.saveChanges}
            onClick = {() =>       
                DbRoutes.wsInsertIssue({
                    assignTo: [],
                    playerId: Main.Instance.DatabaseDataState.playerCard.playerId,
                    riskManagerId: Main.Instance.LoginState.id,
                    issueType: IssueTypes.ChangedUsername,
                    resolved: true,
                    riskLevel: RiskLevel.Undefined,
                    data: {
                        username: Main.Instance.DatabaseDataState.playerCard.usernameNew
                    },
                })
            }
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', /* position: 'absolute', top: '2px', left: '112px', */ padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faSave}/>
        </button>
    )
}

function button1(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.addressNew !== undefined) {
                    playerCard.addressNew = undefined
                    playerCard.streetNumberNew = undefined
                    playerCard.cityNew = undefined
                    playerCard.postalCodeNew = undefined
                    playerCard.countryNew = undefined
                } else {
                    // playerCard.addressNew = ''
                    // playerCard.streetNumberNew = ''
                    // playerCard.cityNew = ''
                    // playerCard.postalCodeNew = ''
                    playerCard.addressNew = playerCard.address
                    playerCard.streetNumberNew = playerCard.streetNumber
                    playerCard.cityNew = playerCard.city
                    playerCard.postalCodeNew = playerCard.postalCode
                    playerCard.countryNew = playerCard.country
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.addressNew !== undefined ? FaEdit : faEdit}/>
    </button>
    )
}

function button2(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.firstNameNew !== undefined) {
                    playerCard.firstNameNew = undefined
                    playerCard.lastNameNew = undefined
                    playerCard.birthdayNew = undefined
                    playerCard.genderNew = undefined
                } else {
                    // playerCard.firstNameNew = ''
                    // playerCard.lastNameNew = ''
                    // playerCard.birthdayNew = ''
                    // playerCard.genderNew = ''
                    playerCard.firstNameNew = playerCard.firstName
                    playerCard.lastNameNew = playerCard.lastName
                    playerCard.birthdayNew = displayBirthday(false)
                    playerCard.genderNew = playerCard.gender
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.firstNameNew !== undefined ? FaEdit : faEdit}/>
        </button>
    )
}

function button3(): JSX.Element {
    // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard, emailNewValid: isEmailValid(value)})
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.emailNew !== undefined) {
                    playerCard.emailNew = undefined
                } else {
                    // playerCard.emailNew = ''
                    playerCard.emailNew = playerCard.email
                }
                // console.log (playerCard.email)
                // console.log (isEmailValid(playerCard.email))
/*
                axios.post(Config.aEmailCheck, {email: playerCard.email}).then((response) => {
                    console.log ('response')
                    console.log (response)
                })
*/
                Logic.Type.New (Main.Instance.DatabaseDataState, {emailAlreadyUsed: true, playerCard, emailNewValid: isEmailValid(playerCard.email)})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.emailNew !== undefined ? FaEdit : faEdit}/>
        </button>
    )
}

function button4(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.phoneNumberNew !== undefined) {
                    playerCard.phoneNumberNew = undefined
                } else {
                    // playerCard.emailNew = ''
                    playerCard.phoneNumberNew = playerCard.phoneNumber
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.phoneNumberNew !== undefined ? FaEdit : faEdit}/>
        </button>
    )
}

function button5(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.beneficiaryNameNew !== undefined) {
                    playerCard.beneficiaryNameNew = undefined
                    playerCard.ibanNew = undefined
                    playerCard.bicNew = undefined
                } else {
                    playerCard.beneficiaryNameNew = playerCard.beneficiaryName
                    playerCard.ibanNew = playerCard.iban
                    playerCard.bicNew = playerCard.bic
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.beneficiaryNameNew !== undefined ? FaEdit : faEdit}/>
        </button>
    )
}

function button6(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.nationalityNew !== undefined) {
                    playerCard.nationalityNew = undefined
                } else {
                    // playerCard.emailNew = ''
                    playerCard.nationalityNew = playerCard.nationality
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '4px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.nationalityNew !== undefined ? FaEdit : faEdit}/>
        </button>
    )
}

function button8(): JSX.Element {
    return (
        <button
            title = {Assets.Edit}
            onClick = {() => {
                const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                if (playerCard.usernameNew !== undefined) {
                    playerCard.usernameNew = undefined
                } else {
                    playerCard.usernameNew = playerCard.username
                }
                Logic.Type.New (Main.Instance.DatabaseDataState, {usernameAlreadyUsed: true, playerCard, usernameNewValid: isUsernameValid(playerCard.username)})
                // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
            }}
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px',/* position: 'absolute', top: '1px', left: '4px',*/ padding: '0px'}}
        >
            <FontAwesomeIcon icon = {Main.Instance.DatabaseDataState.playerCard.usernameNew !== undefined ? FaEdit : faEdit}/>

        </button>
    )
}
/*
function button7(): JSX.Element {
    return (
        <button
            title = {Main.Instance.DatabaseDataState.playerCard.iban === undefined || Main.Instance.DatabaseDataState.playerCard.iban === null || Main.Instance.DatabaseDataState.playerCard.iban.length === 0 ? Assets.PleaseEnterIbanToCheckBIC : Assets.ClickToRetrieveCorrectBIC}
            disabled = {Main.Instance.DatabaseDataState.custom === undefined || Main.Instance.DatabaseDataState.custom.API === undefined || Main.Instance.DatabaseDataState.custom.API.iban === undefined || Main.Instance.DatabaseDataState.playerCard.iban === undefined || Main.Instance.DatabaseDataState.playerCard.iban === null || Main.Instance.DatabaseDataState.playerCard.iban.length === 0 }
            onClick = {() =>
                axios.get(Config.asIbanCheck, {
                    params: {
                        iban: Main.Instance.DatabaseDataState.playerCard.iban.toUpperCase().replace(/ /g,''),
                        apikey: Main.Instance.DatabaseDataState.custom.API.iban
                    },
                    headers: {'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId}
                }).then((response) => {
                    // console.log (response)
                    const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                    // playerCard.bic = response.data.bank_data.bic
                    playerCard.bicNew = response.data.bank_data.bic
                    playerCard.beneficiaryNameNew = playerCard.beneficiaryName
                    playerCard.ibanNew = playerCard.iban
                    Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
                    // const bank_data = JSON.parse(response.data.bank_data)
                    // console.log (response.data)
                })// .catch((e) => {})
            }
            // title = {Assets_.BIC + ' ' + Assets.From.toLowerCase() + ' ' + Assets_.IBAN} 
            className = "btn btn-default btn-sm mr0"
            style = {{background: 'transparent', border: '0px', position: 'absolute', top: '1px', left: '5px', padding: '0px'}}
        >
            <FontAwesomeIcon icon = {faExclamation}/>
        </button>
    )
}
*/
/*
                // const playerCard: Player = Object.assign({}, Main.Instance.DatabaseDataState.playerCard)
                // fetch('https://api.iban.com/clients/api/v4/iban/', {method: 'POST', body: JSON.stringify({api_key: Main.Instance.DatabaseDataState.custom.API.iban,format: 'json' , iban: Main.Instance.DatabaseDataState.playerCard.iban.toUpperCase().replace(/ /g,'')}), headers: {'User-Agent': 'IBAN API Client/0.0.1', 'Content-Type' : 'IBAN API Client/0.0.1'}})
                const data = new FormData()
                data.append('api_key', Main.Instance.DatabaseDataState.custom.API.iban)
                data.append('format', 'json')
                data.append('iban', Main.Instance.DatabaseDataState.playerCard.iban.toUpperCase().replace(/ /g,''))
                console.log (Main.Instance.DatabaseDataState.custom.API.iban)
                console.log (Main.Instance.DatabaseDataState.playerCard.iban.toUpperCase().replace(/ /g,''))
                const config: AxiosRequestConfig = {
                    method: 'post',
                    url: 'https://api.iban.com/clients/api/v4/iban/',
                    headers: {
                        'User-Agent': 'IBAN API Client/0.0.1',
                        'Content-Type' : 'application/x-www-form-urlencoded'
                    },
                    data
                };
                axios(config)  
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        // Logic.Type.New (Main.Instance.DatabaseDataState, {playerCard})
                    }
                    
                }).catch((e) => {
                    console.log ('there was an error')
                    console.log (e)
                })
            }}
*/
/*
function changeTimeLinePage (left: boolean) {
    if (left) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {timelinePage: Main.Instance.DatabaseDataState.timelinePage - 1})
    } else {
        Logic.Type.New (Main.Instance.DatabaseDataState, {timelinePage: Main.Instance.DatabaseDataState.timelinePage + 1})
    }
    // , Main.Instance.DatabaseDataState.timelineLimit, Main.Instance.DatabaseDataState.timelinePage
    DbRoutes.wsGetPlayerTimeline(Main.Instance.DatabaseDataState.playerCard.playerId)
}
*/

function onDocumentLoadSuccess1(PDFDocumentProxy) {
    // console.log (PDFDocumentProxy)
    Logic.Type.New(Main.Instance.DatabaseDataState, {
        numPages1: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
        pageNumber1: 1
    })
}

function onDocumentLoadSuccess2(PDFDocumentProxy) {
    Logic.Type.New(Main.Instance.DatabaseDataState, {
        numPages2: (PDFDocumentProxy && PDFDocumentProxy._pdfInfo && PDFDocumentProxy._pdfInfo.numPages ? PDFDocumentProxy._pdfInfo.numPages : 0),
        pageNumber2: 1
    })
}

function getAttached(): JSX.Element {
    const ga: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.attachedUploads) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.attachedUploads.length; i++) {
            ga.push(
                <button
                    onClick = {() => selectAttached(i)}
                    key = {i.toString()}
                    className = {i === Main.Instance.DatabaseDataState.attachedSelected ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}
                >
                    {getFilename(Main.Instance.DatabaseDataState.attachedUploads[i])}
                </button>
            )
            ga.push(
                <button
                    onClick = {() => deleteAttached(i)}
                    key = {i.toString() + '_'}
                    className = "btn btn-danger btn-xs br0"
                >
                    <FontAwesomeIcon icon = {faTrashAlt}/>
                </button>
            )
        }
    } else {
        // Logic.Type.New(Main.Instance.DatabaseDataState,{uploadExisting: []})
    }
    return (<div style = {{textAlign: 'left', marginBottom: '5px'}} /*style = {{position: 'absolute', top: '0px', right: '0px'}}*/>{ga}</div>)
}

function getAlreadyUploaded(): JSX.Element {
    const gau: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.uploadExisting) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.uploadExisting.length; i++) {
            // console.log (Main.Instance.DatabaseDataState.uploadExisting[i])
            if (Main.Instance.DatabaseDataState.uploadExisting[i].metadata) {
                if (Main.Instance.DatabaseDataState.uploadExisting[i].metadata.contentType === 'application/pdf' && Main.Instance.DatabaseDataState.uploadShowAvailableFilesPdfs ||
                    Main.Instance.DatabaseDataState.uploadExisting[i].metadata.contentType.startsWith('image/') && Main.Instance.DatabaseDataState.uploadShowAvailableFilesImages) {
                    gau.push(
                        <button
                            onClick = {() => selectUpload(i)}
                            key = {i.toString()}
                            className = {i === Main.Instance.DatabaseDataState.uploadSelected ? 'btn btn-primary btn-xs mb5' : 'btn btn-default btn-xs mb5'}
                        >
                            {Main.Instance.DatabaseDataState.uploadExisting[i].name ? getFilename(Main.Instance.DatabaseDataState.uploadExisting[i].name.split('/')[1]) : '?'}
                        </button>
                    )
                }
            } else {
                if (Main.Instance.DatabaseDataState.uploadExisting[i].contentType === 'application/pdf' && Main.Instance.DatabaseDataState.uploadShowAvailableFilesPdfs ||
                    Main.Instance.DatabaseDataState.uploadExisting[i].contentType.startsWith('image/') && Main.Instance.DatabaseDataState.uploadShowAvailableFilesImages) {
                    gau.push(
                        <button
                            onClick = {() => selectUpload(i)}
                            key = {i.toString()}
                            className = {i === Main.Instance.DatabaseDataState.uploadSelected ? 'btn btn-primary btn-xs mb5' : 'btn btn-default btn-xs mb5'}
                        >
                            {Main.Instance.DatabaseDataState.uploadExisting[i].name ? getFilename(Main.Instance.DatabaseDataState.uploadExisting[i].name.split('/')[1]) : '?'}
                        </button>
                    )
                }
            }
        }
    } else {
        // Logic.Type.New(Main.Instance.DatabaseDataState,{uploadExisting: []})
    }
    return (<div style = {{textAlign: 'left'}} /*style = {{position: 'absolute', top: '0px', right: '0px'}}*/>{gau}</div>)

}

function isThisFileAlreadyAttached(): boolean {
    const split = Main.Instance.DatabaseDataState.uploadExisting[Main.Instance.DatabaseDataState.uploadSelected].name.split('/')
    const name = split[1] ? split[1] : '?'
    if (Main.Instance.DatabaseDataState.attachedUploads) {
        for (const item of Main.Instance.DatabaseDataState.attachedUploads) {
            if (item === name) {return true}
        }
    }
    return false
}

function attachThisFile() {
    const split = Main.Instance.DatabaseDataState.uploadExisting[Main.Instance.DatabaseDataState.uploadSelected].name.split('/')
    const name = split[1] ? split[1] : '?'
    if (Main.Instance.DatabaseDataState.attachedUploads === undefined) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {attachedUploads: [name]})
    } else {
        const attachedUploads: string[] = Object.assign([], Main.Instance.DatabaseDataState.attachedUploads)
        attachedUploads.push (name)
        Logic.Type.New(Main.Instance.DatabaseDataState,{attachedUploads})
    }
}

function deleteAttached(i: number) {
    // console.log (Main.Instance.DatabaseDataState.attachedUploads[i])
    if (confirm(Assets.areYouSureThatYouWantToDeleteAnAttachedFile + ' ' + Main.Instance.DatabaseDataState.attachedUploads[i] + '?')) {
        const attachedUploads: string[] = Object.assign([], Main.Instance.DatabaseDataState.attachedUploads)
        attachedUploads.splice (i, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState,{attachedUploads})
    }
}

function selectAttached(attachedSelected: number) {
    // const split: string[] = Main.Instance.DatabaseDataState.uploadExisting[uploadSelected].name.split('/')
    // console.log (split)
    const name: string = Main.Instance.DatabaseDataState.attachedUploads[attachedSelected]
    const uploadIsImage: boolean = !name.endsWith('.pdf')
    const args = {
        method: 'POST',
        body: JSON.stringify({file: name, folderName: Main.Instance.DatabaseDataState.playerCard.playerId}),
        headers: {'Content-Type' : 'application/json', 'api-key': 'burek'
    }}
    // console.log (Config.uDownload)
    // console.log (args)
    fetch(Config.uDownload, args).then((response) => {
        // console.log (response)
        response.blob().then((response2) => {
            const upload: string = URL.createObjectURL(response2)
            // console.log (selectedUpload)
            // Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUpload})
            Logic.Type.New(Main.Instance.DatabaseDataState,{upload, uploadSelected: -1, attachedSelected, uploadIsImage})
        })
    })
}

function selectUpload(uploadSelected: number) {
    const split: string[] = Main.Instance.DatabaseDataState.uploadExisting[uploadSelected].name.split('/')
    // console.log (split)
    const uploadIsImage: boolean = !Main.Instance.DatabaseDataState.uploadExisting[uploadSelected].name.endsWith('.pdf')
    const args = {
        method: 'POST',
        body: JSON.stringify({file: split[1], folderName: split[0]}),
        headers: {'Content-Type' : 'application/json', 'api-key': 'burek'
    }}
    // console.log ('Config.uDownload')
    // console.log (args)
    fetch(Config.uDownload, args).then((response) => {
        // console.log (response)
        response.blob().then((response2) => {
            const upload: string = URL.createObjectURL(response2)
            // console.log (selectedUpload)
            // Logic.Type.New (Main.Instance.DatabaseDataState, {selectedUpload})
            Logic.Type.New(Main.Instance.DatabaseDataState,{upload, uploadSelected, uploadIsImage, attachedSelected: -1})
        })
    })
}

function FirstPage() {
    Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionPage: 0})
    DbRoutes.getGameSessions(/*Main.Instance.DatabaseDataState.playerCard.playerId*/)
}

function PrevPage() {
    if (Main.Instance.PlayerCardState.gameSessionPage > 0) {
        Logic.Type.New(Main.Instance.PlayerCardState,{gameSessionPage: Main.Instance.PlayerCardState.gameSessionPage - 1})
        DbRoutes.getGameSessions(/*Main.Instance.DatabaseDataState.playerCard.playerId*/)

    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.PlayerCardState.gameSessionPage + 1) * Main.Instance.PlayerCardState.gameSessionLimit < 
        Main.Instance.PlayerCardState.gameSessionsCount) {
        Logic.Type.New(Main.Instance.PlayerCardState,{gameSessionPage: Main.Instance.PlayerCardState.gameSessionPage + 1})
        DbRoutes.getGameSessions(/*Main.Instance.DatabaseDataState.playerCard.playerId*/)
        
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.PlayerCardState.gameSessionsCount <= Main.Instance.PlayerCardState.gameSessionLimit) {
        FirstPage()
    } else {
        let ngameSessionPage: number = 0

        if (Main.Instance.PlayerCardState.gameSessionsCount === 0) {
            Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionPage: 0})
        } else {

            ngameSessionPage = Math.trunc((Main.Instance.PlayerCardState.gameSessionsCount - 1) / 
                    Main.Instance.PlayerCardState.gameSessionLimit)
            Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionPage: ngameSessionPage})
        }
        DbRoutes.getGameSessions(/*Main.Instance.DatabaseDataState.playerCard.playerId*/)
    }
}

function firstPage() {
    Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionTransactionsPage: 0})
    DbRoutes.getGameSessionsTransactions(/*getFilter()*/)
}

function prevPage() {
    if (Main.Instance.PlayerCardState.gameSessionTransactionsPage > 0) {
        Logic.Type.New(Main.Instance.PlayerCardState, {
            gameSessionTransactionsPage: Main.Instance.PlayerCardState.gameSessionTransactionsPage - 1
        })
        DbRoutes.getGameSessionsTransactions(/*getFilter()*/)

    } else {
        lastPage()
    }
}

function nextPage() {
    if ((Main.Instance.PlayerCardState.gameSessionTransactionsPage + 1) * Main.Instance.PlayerCardState.gameSessionTransactionsLimit < 
        Main.Instance.PlayerCardState.gameSessionsTransactionsCount) {
        Logic.Type.New(Main.Instance.PlayerCardState, {
            gameSessionTransactionsPage: Main.Instance.PlayerCardState.gameSessionTransactionsPage + 1})
        DbRoutes.getGameSessionsTransactions(/*getFilter()*/)
        
    } else {
        firstPage()
    }
}

function lastPage() {
    if (Main.Instance.PlayerCardState.gameSessionsTransactionsCount <= Main.Instance.PlayerCardState.gameSessionTransactionsLimit) {
        firstPage()
    } else {
        let ngameSessionPage: number = 0

        if (Main.Instance.PlayerCardState.gameSessionsTransactionsCount === 0) {
            Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionTransactionsPage: 0})
        } else {

            ngameSessionPage = Math.trunc((Main.Instance.PlayerCardState.gameSessionsTransactionsCount - 1) / 
                    Main.Instance.PlayerCardState.gameSessionTransactionsLimit)
            Logic.Type.New(Main.Instance.PlayerCardState, {
                gameSessionTransactionsPage: ngameSessionPage
            })
        }
        DbRoutes.getGameSessionsTransactions(/*getFilter()*/)
    }
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionLimit: parseInt(text)})
        // FirstPage()
    }
}

function recordsPerPageChanged(text: string) {
    if (parseInt(text) > 0 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.PlayerCardState, {gameSessionTransactionsLimit: parseInt(text)})
        // firstPage()
    }
}
// externalGameSessionId
function selectGameSessionID(gameSessionSelectedId: string, gameId: string, gameSessionSelectedIdExternal: string, row: any) {
    // console.log (gameSessionSelectedIdExternal)
    // console.log (row)
    Logic.Type.New (Main.Instance.PlayerCardState, {
        selectingGameSessionId: true,
        gameSessionSelectedId,
        gameSessionSelectedGame: getGame(parseInt(gameId)),
        gameSessionSelectedIdExternal
    })
    gotoTop()
    DbRoutes.getGameSessionsTransactions(/*getFilter()*/)
    // load transactions for this game session id
}
/*
function getFilter(): ITransactionFilters {
    return {}
}
*/
function getGame(gameId: number): string {
    // console.log (gameId)
    // console.log (Main.Instance.DatabaseDataState.gamesCentralNew)
    if (Main.Instance.DatabaseDataState.gamesList) {
        for (const item of Main.Instance.DatabaseDataState.gamesList) {
            if (item.gameId === gameId) {
                // console.log (item.providerId)
                // const provider: string = getProvider(parseInt(item.providerId))
                return item.gameName + ' (' + getProvider(item.providerId) + ')'
            }
        }
    }
    return ''
}
/*
function getGameOnly(gameId: number): string {
    // console.log (gameId)
    // console.log (Main.Instance.DatabaseDataState.gamesCentralNew)
    if (Main.Instance.DatabaseDataState.gamesCentralNew) {
        for (const item of Main.Instance.DatabaseDataState.gamesCentralNew) {
            if (item.gameId === gameId) {
                // console.log (item.providerId)
                // const provider: string = getProvider(parseInt(item.providerId))
                return item.name
            }
        }
    }
    return ''
}
*/
/*
function getGameProvider(gameId: number): string {
    // console.log (gameId)
    // console.log (Main.Instance.DatabaseDataState.gamesCentralNew)
    if (Main.Instance.DatabaseDataState.gamesCentralNew) {
        for (const item of Main.Instance.DatabaseDataState.gamesCentralNew) {
            if (item.gameId === gameId) {
                // console.log (item.providerId)
                // const provider: string = getProvider(parseInt(item.providerId))
                return getProvider(parseInt(item.providerId))
            }
        }
    }
    return ''
}
*/

function GetBonusInfoFreeGames(activeBonuses: number): JSX.Element {
    if (Main.Instance.PlayerCardState.bonusInfoFreeGames) {
        return (
            <div>
            <ReactTable
                data = {Main.Instance.PlayerCardState.bonusInfoFreeGames}
                columns = {[
                    {width: 90, Header: Assets.Instance + ' ' + Assets.ID, accessor: 'bonusInstanceId', headerClassName: 'th-left'},
                    {width: 80, Header: Assets.Bonus + ' ' + Assets.ID, accessor: 'bonusId', headerClassName: 'th-left'},
                    {width: 180, Header: Assets.Bonus + ' ' + Assets.Name, accessor: 'bonusName', headerClassName: 'th-left'},
                    {Header: Assets.GameProvider, accessor: 'provider', headerClassName: 'th-left'},
                    {width: 130, Header: Assets.FreeGames + ' ' + Assets.Amount.toLowerCase(), accessor: 'freeGamesAmount', headerClassName: 'th-left'},
                    {width: 110, Header: Assets.Value + ' ' + Assets.Amount.toLowerCase(), accessor: 'freeGamesValueAmount', headerClassName: 'th-left'},
                    {width: 100, Header: Assets.Win + ' ' + Assets.Amount.toLowerCase(), accessor: 'freeGamesWinAmount', headerClassName: 'th-left'},
                    {width: 160, Header: Assets.Granted + Assets._at_, accessor: 'grantedDate', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0, 10) + ' ' + row.value.substring(11, 19) : ''},
                    {width: 120, Header: Assets.Bonus + ' ' + Assets.Status, accessor: 'state', headerClassName: 'th-left'},


                    {width: 75, Header: <span title = {Assets.Cancel + ' ' + Assets.Bonus}>{Assets.Cancel}</span>, accessor: 'state', headerClassName: 'th-left', Cell: row => /*row.original.active && !row.original.completed && !row.original.canceled*/ row.original.state === 'GRANTED' ?
                    <button
                        style = {{fontWeight: 700}}
                        className = "btn-xs btn-warning btn"
                        onClick = {() => DbRoutes.cancelBonus(Main.Instance.DatabaseDataState.playerCard.playerId, row.original.bonusInstanceId, row.original.walletId)}
                        title = {Assets.Cancel + Assets._the_ + Assets.Bonus + Assets._with_ + Assets.Bonus + ' ' + Assets.ID + ' ' + row.original.bonusId}
                    >
                        {Assets.Cancel}
                    </button>
                    : '-'},
                ]}
                className = "-stripped-higlight"
                showPaginationTop = {false}
                showPaginationBottom = {false}
                pageSize={Main.Instance.PlayerCardState.bonusInfoFreeGames.length}
            />
            {activeBonuses ?
                <div style = {{marginTop: '3px'}}>
                    <button
                        style = {{fontWeight: 700}}
                        className = "btn-block btn-xs btn-danger btn"
                        onClick = {() => DbRoutes.cancelAllBonuses(Main.Instance.DatabaseDataState.playerCard.playerId)}
                    >
                        {Assets.Cancel + Assets._all_ + Assets.Bonus_es + Assets._for_ + Assets.this_ + ' ' + Assets.Player + ' [' + (activeBonuses) + ']'}
                    </button>
                </div>
            : void 0
        }
            </div>
        )
    }
    return void 0

}
function GetBonusInfoNew(activeBonuses: number): JSX.Element {
    if (Main.Instance.PlayerCardState.bonusInfo) {
//         let activeBonuses: number = 0
//         // let newBonuses: number = 0
//         for (const ITEM of Main.Instance.PlayerCardState.bonusInfo) {
//             // if (ITEM.active && !ITEM.completed && !ITEM.canceled) {
//             //     activeBonuses++
//             // }
// //          if (!ITEM.active && !ITEM.completed && !ITEM.canceled) {
// //              newBonuses++
// //          }
//             if (ITEM.state === 'GRANTED') {
//                 activeBonuses++
//             }
//         }

//         for (const ITEM of Main.Instance.PlayerCardState.bonusInfoFreeGames) {
//             if (ITEM.state === 'GRANTED') {
//                 activeBonuses++
//             }
//         }

        return (
            <div>
            <ReactTable
                data = {Main.Instance.PlayerCardState.bonusInfo}
                columns = {[
                    {width: 90, Header: Assets.Instance + ' ' + Assets.ID, accessor: 'bonusInstanceId', headerClassName: 'th-left'},
                    {width: 80, Header: Assets.Bonus + ' ' + Assets.ID, accessor: 'bonusId', headerClassName: 'th-left'},
                    {width: 180, Header: Assets.Bonus + ' ' + Assets.Name, accessor: 'bonusName', headerClassName: 'th-left'},
                    {width: 160, Header: Assets.Granted + Assets._at_, accessor: 'grantedDate', headerClassName: 'th-left', Cell: row => row.value ? row.value.substring(0, 10) + ' ' + row.value.substring(11, 19) : ''},
                    {width: 120, Header: Assets.Bonus + ' ' + Assets.Status, accessor: 'state', headerClassName: 'th-left'},
                    {Header: Assets.WageringRequirement, accessor: 'wageringRequirement', headerClassName: 'th-left'},
                    // {width: 150, Header: Assets.CreatedAt + ' (' + Assets_.UTC + '):', accessor: 'created', headerClassName: 'th-left', Cell: row => GetDate(row.value)},
                    {width: 80, Header: Assets.Amount, accessor: 'amount', headerClassName: 'th-left'/*, Cell: row => row.value + ' ' + row.original.currency*/},
                    // {width: 90, Header: <span title = {Assets.Amount + ' ' + Assets.Converted.toLowerCase()}>{Assets.Converted}</span>, accessor: 'amountConverted', headerClassName: 'th-left', Cell: row => row.value + ' ' + row.original.currency},

                    // {width: 25, Header: <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.Bonus} style = {{height: '20px', fontSize: '20px', /*background: '#eee',*/ paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px'/*, color: row.value ? 'rgb(92, 184, 92)' : '#bbb'*/}} icon = {faLightbulb}/>, accessor: 'active', headerClassName: 'th-left', Cell: row => <FontAwesomeIcon /*title = {Assets.Active}*/ style = {{height: '20px', fontSize: '20px'/*, background: '#eee'*/, paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: row.value ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faLightbulb}/>},
                    // {width: 30, Header: <FontAwesomeIcon title = {Assets.Completed + ' ' + Assets.Bonus} style = {{height: '20px', fontSize: '20px', /*background: '#eee',*/ paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px'/*, color: row.value ? 'rgb(92, 184, 92)' : '#bbb'*/}} icon = {faCheck}/>, accessor: 'completed', headerClassName: 'th-left', Cell: row => <FontAwesomeIcon /*title = {Assets.Active}*/ style = {{height: '20px', fontSize: '20px'/*, background: '#eee'*/, paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: row.value ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faCheck}/>},
                    // {width: 25, Header: <FontAwesomeIcon title = {Assets.Cancelled + ' ' + Assets.Bonus} style = {{height: '20px', fontSize: '20px', /*background: '#eee',*/ paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px'/*, color: row.value ? 'rgb(217, 83, 79)' : '#bbb'*/}} icon = {faTimes}/>, accessor: 'canceled', headerClassName: 'th-left', Cell: row => <FontAwesomeIcon /*title = {Assets.Active}*/ style = {{height: '20px', fontSize: '20px'/*, background: '#eee'*/, paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: row.value ? 'rgb(217, 83, 79)' : '#bbb'}} icon = {faTimes}/>},

                    // {width: 120, Header: Assets.Wagered, accessor: 'wagered', headerClassName: 'th-left', Cell: row => row.value.toFixed(2) + ' ' + row.original.currency},
                    // {width: 180, Header: Assets.WageringRequirement, accessor: 'wagerRequirement', headerClassName: 'th-left', Cell: row => <span>{row.original.amount ? row.value / row.original.amount : row.value}x&nbsp;({row.value.toFixed(2) + ' ' + row.original.currency})</span>},
                    // {width: 100, Header: Assets.Playthrough, accessor: 'currency', headerClassName: 'th-left', Cell: row => row.original.wagerRequirement ? (row.original.wagered / row.original.wagerRequirement * 100).toFixed(2) + '%' : '-'},
                    {width: 80, Header: Assets.Balance, accessor: 'balance', headerClassName: 'th-left'},
                    {width: 100, Header: Assets.Converted, accessor: 'amountConverted', headerClassName: 'th-left', Cell: row => row.value ? row.value : 0},            
                    {width: 100, Header: Assets.Wagered + ' (%)', accessor: 'wagered', headerClassName: 'th-left', Cell: row => row.value + ' (' + row.original.playthrough + '%)'},
                    // {width: 80, Header: <span title = {Assets.Activate + ' ' + Assets.Bonus}>{Assets.Activate}</span>, accessor: 'state', headerClassName: 'th-left', Cell: row => /*!row.original.active && !row.original.completed && !row.original.canceled && activeBonuses === 0 &&*/ row.original.state === 'CANCELED' && row.original.wagered === 0 ?
                    // <button
                    //     disabled = {true}
                    //     style = {{fontWeight: 700}}
                    //     className = "btn-xs btn-success btn"
                    //     // onClick = {() => DbRoutes.activateBonus(Main.Instance.DatabaseDataState.playerCard.playerId, row.original.bonusId)}
                    //     title = {Assets.Activate + Assets._the_ + Assets.Bonus + Assets._with_ + Assets.Bonus + ' ' + Assets.ID + ' ' + row.original.bonusId}
                    // >
                    //     {Assets.Activate}
                    // </button>
                    // : '-'},
                    {width: 80, Header: <span title = {Assets.Cancel + ' ' + Assets.Bonus}>{Assets.Cancel}</span>, accessor: 'state', headerClassName: 'th-left', Cell: row => /*row.original.active && !row.original.completed && !row.original.canceled*/ row.original.state === 'GRANTED' ?
                    <button
                        style = {{fontWeight: 700}}
                        className = "btn-xs btn-warning btn"
                        onClick = {() => DbRoutes.cancelBonus(Main.Instance.DatabaseDataState.playerCard.playerId, row.original.bonusInstanceId, row.original.walletId)}
                        title = {Assets.Cancel + Assets._the_ + Assets.Bonus + Assets._with_ + Assets.Bonus + ' ' + Assets.ID + ' ' + row.original.bonusId}
                    >
                        {Assets.Cancel}
                    </button>
                    : '-'},
                ]}
                className = "-stripped-higlight"
                showPaginationTop = {false}
                showPaginationBottom = {false}
                pageSize={Main.Instance.PlayerCardState.bonusInfo.length}
            />
            {activeBonuses ?
                <div style = {{marginTop: '3px'}}>
                    <button
                        style = {{fontWeight: 700}}
                        className = "btn-block btn-xs btn-danger btn"
                        onClick = {() => DbRoutes.cancelAllBonuses(Main.Instance.DatabaseDataState.playerCard.playerId)}
                    >
                        {Assets.Cancel + Assets._all_ + Assets.Bonus_es + Assets._for_ + Assets.this_ + ' ' + Assets.Player + ' [' + (activeBonuses) + ']'}
                    </button>
                </div>
            : void 0
        }
            </div>
        )
    }
    return void 0
}
/*
function GetBonusInfo(): JSX.Element[] {
    const bi: JSX.Element[] = []
    // console.log (Main.Instance.PlayerCardState.bonusInfo)
    if (Main.Instance.PlayerCardState.BonusInfo) {
        let activeBonuses: number = 0
        // let newBonuses: number = 0
        for (const ITEM of Main.Instance.PlayerCardState.bonusInfo) {
            if (ITEM.active && !ITEM.completed && !ITEM.canceled) {
                activeBonuses++
            }
//          if (!ITEM.active && !ITEM.completed && !ITEM.canceled) {
//              newBonuses++
//          }
        }

        for (let i: number = 0; i < Main.Instance.PlayerCardState.bonusInfo.length; i++) {
            const ITEM: IBonusInfo = Main.Instance.PlayerCardState.bonusInfo[i]
            bi.push(
                <div key = {i.toString()} className = "clearfix mt2 pr">
                    <div title = {Assets.Bonus + ' ' + Assets.ID} className = "fl" style = {{fontWeight: 700, width: '60px', background: '#f8f8f8'}}>
                        &nbsp;{ITEM.bonusId}
                    </div>
                    <div title = {Assets.CreatedAt + ' (' + Assets_.UTC + '):'} className = "fl" style = {{width: '139px', background: '#eee'}}>
                        &nbsp;{GetDate(ITEM.created)}
                    </div>
                    <div title = {Assets.Amount + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '60px', background: '#f8f8f8'}}>
                        &nbsp;{ITEM.amount}
                    </div>
                    <div title = {Assets.Amount + ' ' + Assets.Converted.toLowerCase() + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '60px', background: '#eee'}}>
                        &nbsp;{ITEM.amountConverted}
                    </div>
                </div>
            )
            // remove ITEM.active
            if (ITEM.active && !ITEM.completed && !ITEM.canceled) {
                bi.push(
                    <div key = {i.toString() + '__'} className = "mt2">
                        <button
                            style = {{fontWeight: 700}}
                            className = "btn-block btn-xs btn-warning btn"
                            onClick = {() => DbRoutes.cancelBonus(Main.Instance.DatabaseDataState.playerCard.playerId, ITEM.bonusId)}
                        >
                            {Assets.Cancel + Assets._the_ + Assets.Bonus + Assets._with_ + Assets.Bonus + ' ' + Assets.ID + ' ' + ITEM.bonusId}
                        </button>
                    </div>
                )
            }

            if (!ITEM.active && !ITEM.canceled && !ITEM.completed && activeBonuses === 0) {
                bi.push(
                    <div key = {i.toString() + '___'} className = "mt2">
                        <button
                            style = {{fontWeight: 700}}
                            className = "btn-block btn-xs btn-success btn"
                            onClick = {() => DbRoutes.activateBonus(Main.Instance.DatabaseDataState.playerCard.playerId, ITEM.bonusId)}
                        >
                            {Assets.Activate + Assets._the_ + Assets.Bonus + Assets._with_ + Assets.Bonus + ' ' + Assets.ID + ' ' + ITEM.bonusId}
                        </button>
                    </div>
                )
            }

            bi.push(
                <div key = {i.toString() + '_'} className = "clearfix mt2 pr" style = {{marginBottom: '3px', borderBottom: '1px solid #999'}}>
                    <FontAwesomeIcon title = {Assets.Active + ' ' + Assets.Bonus} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.active ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faLightbulb}/>
                    <FontAwesomeIcon title = {Assets.Completed + ' ' + Assets.Bonus} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.completed ? 'rgb(92, 184, 92)' : '#bbb'}} icon = {faCheck}/>
                    <FontAwesomeIcon title = {Assets.Cancelled + ' ' + Assets.Bonus} className = "fl dbl" style = {{height: '20px', fontSize: '20px', background: '#eee', paddingRight: '3px', paddingTop: '1px', paddingBottom: '1px', color: ITEM.canceled ? 'rgb(217, 83, 79)' : '#bbb'}} icon = {faTimes}/>

                    <div title = {Assets.Wagered + Assets._in_ + ITEM.currency} className = "fl" style = {{width: '80px', background: '#f8f8f8'}}>
                        &nbsp;{ITEM.wagered.toFixed(2)}
                    </div>
                    <div title = {Assets.WageringRequirement + ' (' + Assets.WageringRequirement + Assets._in_ + ITEM.currency + ')'} className = "fl" style = {{width: '120px', background: '#eee'}}>
                        &nbsp;{ITEM.amount ? ITEM.wagerRequirement/ITEM.amount : ITEM.wagerRequirement}x&nbsp;({ITEM.wagerRequirement.toFixed(2)})
                    </div>
                    {ITEM.wagerRequirement ?
                    <div title = {Assets.Playthrough} className = "fl" style = {{fontWeight: 700, width: '71px', background: '#f8f8f8'}}>
                        &nbsp;{(ITEM.wagered / ITEM.wagerRequirement * 100).toFixed(2) + '%'}
                    </div> : void 0}
                </div>
            )
        }
        // add || newBonuses
        if (activeBonuses) {
            bi.push(
                <div key = "cancelAllBonuses" style = {{marginBottom: '3px'}}>
                    <button
                        style = {{fontWeight: 700}}
                        className = "btn-block btn-xs btn-danger btn"
                        onClick = {() => DbRoutes.cancelAllBonuses(Main.Instance.DatabaseDataState.playerCard.playerId)}
                    >
                        {Assets.Cancel + Assets._all_ + Assets.Bonus_es + Assets._for_ + Assets.this_ + ' ' + Assets.Player + ' [' + (activeBonuses) + ']'} // activeBonuses + newBonuses
                    </button>
                </div>
            )
        }
    }
    return bi
}
*/

function getTransactionType(transactionType: TransactionType): string {
    if (transactionType === TransactionType.DEPOSIT) {return Assets.Deposit}
    if (transactionType === TransactionType.DEPOSIT_MANUAL) {return Assets.Manual + ' ' + Assets.Deposit}
    if (transactionType === TransactionType.DEPOSIT_CANCEL) {return Assets.Cancelled + ' ' + Assets.Deposit}

    if (transactionType === TransactionType.WITHDRAW) {return Assets.Withdrawal}
    if (transactionType === TransactionType.WITHDRAW_CANCEL) {return Assets.Cancelled + ' ' + Assets.Withdrawal}
    if (transactionType === TransactionType.WITHDRAW_MANUAL) {return Assets.Manual + ' ' + Assets.Withdrawal}
    
    if (transactionType === TransactionType.WAGER) {return Assets.Wager}
    if (transactionType === TransactionType.WAGER_CANCEL) {return Assets.Cancelled + ' ' + Assets.Wager}

    if (transactionType === TransactionType.WIN) {return Assets.Win}
    if (transactionType === TransactionType.WIN_CANCEL) {return Assets.Cancelled + ' ' + Assets.Win}

    if (transactionType === TransactionType.BONUS_ADD) {return Assets.Bonus + ' ' + Assets.Add}
    if (transactionType === TransactionType.BONUS_CANCEL) {return Assets.Bonus + ' ' + Assets.Cancelled}
    if (transactionType === TransactionType.BONUS_REDEEM) {return Assets.Bonus + ' ' + Assets.Redeemed}

    return Assets.Unknown
}

function getTransactionTypeOptions(): JSX.Element[] {
    const tto: JSX.Element[] = []
    // console.log (Object.keys(TransactionType))
    tto.push(<option key = "0" value = {0}>{Assets.All}</option>)
    for (let i: number = 1; i <= Object.keys(TransactionType).length/2; i++) {
        tto.push(<option key = {i.toString()} value = {i}>{TransactionType[i].toLowerCase()}</option>)
    }
    return tto
}

function getTransactionStatusOptions(): JSX.Element[] {
    const tso: JSX.Element[] = []
    // console.log (Object.keys(TransactionStatus_))
    tso.push(<option key = "-1" value = {0}>{Assets.All}</option>)
    for (let i: number = 0; i < Object.keys(TransactionStatus_).length/2; i++) {
        tso.push(<option key = {i.toString()} value = {parseInt(Object.keys(TransactionStatus_)[i])}>{TransactionStatus_[parseInt(Object.keys(TransactionStatus_)[i])].toLowerCase()}</option>)
    }
    return tso
}

function getGameSessionStatusOptions(): JSX.Element[] {
    const gsso: JSX.Element[] = []
    // console.log (Object.keys(GameSessionStatus))
    gsso.push(<option key = "-1" value = {-1}>{Assets.All}</option>)
    for (let i: number = 0; i < Object.keys(GameSessionStatus).length/2; i++) {
        gsso.push(<option key = {i.toString()} value = {parseInt(Object.keys(GameSessionStatus)[i])}>{GameSessionStatus[parseInt(Object.keys(GameSessionStatus)[i])].toLowerCase()}</option>)
    }
    return gsso
}

function getTotalBet(): string {
    // need to add selected
    // console.log (Main.Instance.PlayerCardState.gameSessionsTransactions[0].currency)
    // console.log (Main.Instance.PlayerCardState.gameSessionSelectedId)
    // console.log (Main.Instance.PlayerCardState.gameSessions)
    if (Main.Instance.PlayerCardState.gameSessions) {
        for (const item of Main.Instance.PlayerCardState.gameSessions) {
            if (item.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId) {
                return (numberWithCommas(item.totalBetReal.toFixed(2)) + ' ' + (Main.Instance.PlayerCardState.gameSessionsTransactions[0] && Main.Instance.PlayerCardState.gameSessionsTransactions[0].currency ? Main.Instance.PlayerCardState.gameSessionsTransactions[0].currency : 'CHF'))
            }
        }
    }
    return '-'
}

function getTotalWin(): string {
    if (Main.Instance.PlayerCardState.gameSessions) {
        for (const item of Main.Instance.PlayerCardState.gameSessions) {
            if (item.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId) {
                return (numberWithCommas(item.totalWinReal.toFixed(2)) + ' ' + (Main.Instance.PlayerCardState.gameSessionsTransactions[0] && Main.Instance.PlayerCardState.gameSessionsTransactions[0].currency ? Main.Instance.PlayerCardState.gameSessionsTransactions[0].currency : 'CHF'))
            }
        }
    }
    return '-'
}
/*
function getSession(): string {
    if (Main.Instance.PlayerCardState.gameSessions) {
        for (const item of Main.Instance.PlayerCardState.gameSessions) {
            if (item.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId) {
                return (item.gameSessionId ? item.gameSessionId : '-')
            }
        }
    }
    return '-'
}
*/
// externalGameSessionId
/*
function getExternal(): string {
    if (Main.Instance.PlayerCardState.gameSessions) {
        for (const item of Main.Instance.PlayerCardState.gameSessions) {
            if (item.gameSessionId === Main.Instance.PlayerCardState.gameSessionSelectedId) {
                return (item.externalGameSessionId ? item.externalGameSessionId : '-')
            }
        }
    }
    return '-'
}
*/

function getOrderedList(array: string[]): JSX.Element {
    const ol: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        ol.push (<li key = {i.toString()}>{array[i]}</li>)
    }
    return (
        <ol>
            {ol}
        </ol>
    )
}
/*
function getDate(date: string): string {
    const d = new Date(parseInt(date) * 1000)
    return d.toISOString()
}
*/
function getTransactionStatus(status: number): JSX.Element {
    let classname = 'label label-sm label-'
    if (status === TransactionStatus.APPROVED) {
        classname += 'primary'
    } else if (status === TransactionStatus.CANCELED) {
        classname += 'cancelled'
    } else if (status === TransactionStatus.REJECTED) {
        classname += 'danger'
    } else if (status === TransactionStatus.COMPLETED) {
        classname += 'success'
    } else if (status === TransactionStatus.EXECUTED) {
        classname += 'info'
    } else if (status === TransactionStatus.DENIEDBYBANK) {
        classname += 'denied-by-bank'
    } else if (status === TransactionStatus.PENDING) {
        classname += 'warning'
    }

    return (<span style = {{fontSize: '13px'}} className = {classname}>{Assets.StatusTypeText[status]}</span>)
}

function getError(row): JSX.Element {
    let title: string = ''
    if (row.column.id === 'info.errCode') {
        for (const item of Assets_.errorCodes) {
            if (item.errCode === row.value) {
                title = item.reason
            }
        }
    } else if (row.column.id === 'info.exErrCode') {
        for (const item of Assets_.exErrorCodes) {
            if (item.exErrCode === row.value) {
                title = item.description
            }
        }
    }
    if (+row.value === 0) {
        return <span style = {{fontSize: '13px', paddingLeft: '3px', paddingRight: '3px'}} className = "label label-success label-sm">{Assets.NoError.toLowerCase()}</span>
    } else {
        return <span title = {title} style = {{fontSize: '13px', paddingLeft: '3px', paddingRight: '3px'}} className = "label label-danger label-sm">{row.value}</span>
    }
}
/*
function getCode(status: string): JSX.Element {
    if (+status === 0) {
        return <span style = {{fontSize: '13px', paddingLeft: '3px', paddingRight: '3px'}} className = "label label-success label-sm">{Assets.NoError.toLowerCase()}</span>
    } else {
        return <span style = {{fontSize: '13px', paddingLeft: '3px', paddingRight: '3px'}} className = "label label-danger label-sm">{status}</span>
    }
}
*/

function isStatusActive(status: AccountStatus): boolean {
    return status === AccountStatus.Active || status === AccountStatus.ActiveFullyIdentified
}

function isStatusActivePossibilities(status: AccountStatus): boolean {
    if (status === undefined || status === null) {return false}
    return !(isStatusNormal(status) || status === AccountStatus.ClosedProvisional)
}

function isStatusProvisional(status: AccountStatus): boolean {
    return status === AccountStatus.Initial || status === AccountStatus.Provisional
}

function isStatusProvisionalPossibilities(status: AccountStatus): boolean {
    if (status === undefined || status === null) {return false}
    return !(isStatusNormal(status) || status === AccountStatus.ClosedActive)
}

function isStatusNormal(status: AccountStatus): boolean {
    return status === AccountStatus.Initial || status === AccountStatus.Provisional || status === AccountStatus.Active || status === AccountStatus.ActiveFullyIdentified
}

function isStatusSpecial(status: AccountStatus): boolean {
    if (Main.Instance.DatabaseDataState.statusProvisionalOrActive && Main.Instance.DatabaseDataState.statusProvisionalOrActive.wasActive) {
        return isStatusActivePossibilities(status)
    } else if (Main.Instance.DatabaseDataState.statusProvisionalOrActive && !Main.Instance.DatabaseDataState.statusProvisionalOrActive.wasActive) {
        return isStatusProvisionalPossibilities(status)
    } else {
        return (!isStatusNormal(status) || status === AccountStatus.ClosedProvisional || status === AccountStatus.ClosedActive)
    }
}

function isStatusSpecialPossibilities(status: AccountStatus): boolean {
    if (status === undefined || status === null) {return false}
    if (Main.Instance.DatabaseDataState.statusProvisionalOrActive && Main.Instance.DatabaseDataState.statusProvisionalOrActive.wasActive) {
        return isStatusActivePossibilities(status)
    } else if (Main.Instance.DatabaseDataState.statusProvisionalOrActive && !Main.Instance.DatabaseDataState.statusProvisionalOrActive.wasActive) {
        return isStatusProvisionalPossibilities(status)
    } else {
        return isStatusSpecial(status)
    }
}


function getButtonsChangeAccountStatus(previousStatus: AccountStatus, currentStatus: AccountStatus): JSX.Element[] {

    // set status to BlockedFull if the status is unknown
    if (isStatusActive(previousStatus) && !isStatusActivePossibilities(currentStatus)
       || isStatusProvisional(previousStatus) && !isStatusProvisionalPossibilities(currentStatus)
       || isStatusSpecial(previousStatus) && !isStatusSpecialPossibilities(currentStatus)
    ) {
        InsertNewIssueAccountStatus(AccountStatus.BlockedFull)
    }

    // if (currentStatus === null || currentStatus === undefined) {currentStatus = AccountStatus.BlockedFull}
    const array = Object.keys(AccountStatus)
    // console.log(array)
    const bcas: JSX.Element[] = []
    for (let i: number = 0; i < array.length / 2; i++) {
        if (i === AccountStatus.PaymentsBlocked as number || i === AccountStatus.PendingActive as number) {continue}
        if (previousStatus === i) {continue}

        if (isStatusActive(previousStatus) && !isStatusActivePossibilities(i)) {
            continue
        }

        if (isStatusProvisional(previousStatus) && !isStatusProvisionalPossibilities(i)) {
            continue
        }

        if (isStatusSpecial(previousStatus) && !isStatusSpecialPossibilities(i)) {
            continue
        }


        // set the right color (if selected) blocked
        const color: string = 'risk risk-' + array[i + array.length / 2]
        bcas.push (<button style = {{marginLeft: '-1px', color: i === currentStatus as number ? '#fff' : '#333'}} onClick = {() => InsertNewIssueAccountStatus(i)} key = {i.toString()} className = {i === currentStatus as number ? 'dbl fl btn btn-default btn-xs br0 mr0 ' + color : 'dbl fl btn btn-default btn-xs br0 mr0'}>{injectSpaces(array[i + array.length / 2])}</button>)
    }
    return bcas
}

function getDetailsInfoTable(): JSX.Element {
    return (
        <div style = {{height: 'fit-content', padding: '10px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'absolute', top: '20px', left: '20px', right: '20px'}}>
            <button
                title={Assets.Close}
                onClick={() => Logic.Type.New(Main.Instance.PlayerCardState, {toggleInfo: !Main.Instance.PlayerCardState.toggleInfo})}
                style={{ border: '0px', background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px' }}
                className="btn btn-default btn-sm btn-plus"
            >
                <FontAwesomeIcon icon = {faTimes}/>
            </button>
            <select
                value = {Main.Instance.PlayerCardState.issueDetailsData}
                title = {Assets.Issue + ' ' + Assets.Details.toLowerCase()}
                className = "mb"
                onChange = {(e) => Logic.Type.New(Main.Instance.PlayerCardState, {issueDetailsData: e.target.value})}>
                {getDetailsInfoOptions()}
            </select>
            <ReactTable
                data = {Main.Instance.PlayerCardState.issueDetailsData === Assets.Error ? Assets_.gamanzaGameIntegrationErrors : getAccountStatusData()}
                columns = {[
                    {width: 300, show: Main.Instance.PlayerCardState.issueDetailsData === Assets.Error, headerClassName: 'th-left', Header: Assets.Status, accessor: 'status'},
                    {show: Main.Instance.PlayerCardState.issueDetailsData === Assets.Error, headerClassName: 'th-left', Header: Assets.GamanzaErrorMessages.charAt(0) + Assets.GamanzaErrorMessages.substring(1).toLowerCase(), accessor: 'message'},
                    {width: 300, show: Main.Instance.PlayerCardState.issueDetailsData === Assets.Status, headerClassName: 'th-left', Header: Assets.AccountStatus + ' ' + Assets.ID.toLowerCase(), accessor: 'id'},
                    {show: Main.Instance.PlayerCardState.issueDetailsData === Assets.Status, headerClassName: 'th-left', Header: Assets.AccountStatus + ' ' + Assets.Name.toLowerCase(), accessor: 'name'},
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                pageSizeOptions = {[5, 10, 15, 20, 25, 50]}
                defaultPageSize = {10}
            />
        </div>
    )
}

function getDetailsInfoOptions(): JSX.Element[] {
    const options: JSX.Element[] = []
    options.push (<option key = "1" value = {Assets.Status}>{Assets.Account + ' ' + Assets.Statuses.toLowerCase()}</option>)
    options.push (<option key = "0" value = {Assets.Error}>{Assets.Error + ' ' + Assets.Messages.toLowerCase()}</option>)
    
    return options
}

function getAccountStatusData(): Array<{id: string, name: string}> {
    const status = Object.keys(AccountStatus)
    const arr: Array<{id: string, name: string}> = []
    for (let i: number = 0; i < status.length / 2; i++) {
        if (+status[i] === AccountStatus.PaymentsBlocked as number) {continue}
        arr.push({id: status[i], name: injectSpaces(status[i + status.length / 2])})
    }
    return arr
}