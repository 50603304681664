import * as React from 'react'

export function View<Type>(render: (state: Type) => View): View.Class<Type> {
    return class extends React.PureComponent<{State: Type}, {}> {
        public render(): JSX.Element {
            return render(this.props.State)
        }
    }
}

// tslint:disable-next-line:no-namespace
export namespace View {
    export function New<Type>(view: Class<Type>, state: Type): View {
        return React.createElement(view, {State: state})
    }

    export type Class<Type> = React.ComponentClass<{State: Type}>
}

export type View = JSX.Element