import * as React from 'react'
import { Main } from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Logic } from '../../../Logic/Type'
import {
    isUserSystem,
    isUserMaster,
    isUserSubEntity,
    isUserGameProvider,
    AllowDecimalCharactersOnly,
    IsBuildMyChance,
    capitalizeFirstLetter,
    injectSpaces
} from '../../../Logic/Utils'
// import {GameCategories} from '../../../Logic/GeneralSettings/General'
import { FormSelect, FormInput, FormInputNumber } from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGeneral/*, GameCategories*/ } from '../../../Logic/Database/DatabaseData'
import Switch from 'react-toggle-switch'
import { /*faCheck, faTimes, */faTimes, faSitemap, faUpload, faDownload, faFileCsv, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import * as base64 from 'base-64'
// import { /*IGameCategoryContributionGame, */IGameCategoryContributions } from 'src/Logic/GeneralSettings/General';
// import txtg0 from 'raw-loader!../../../Assets/g0.txt'
// import txtg100 from 'raw-loader!../../../Assets/g100.txt'
// import {NoDuplicates} from '../PromoStore/LevelsNew'
import ReactTable from 'react-table'

function ApplyMasterSelect(text: string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, { holding_id: text })
    // console.log (Main.Instance.TournamentState.holding_id)
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream: undefined,
            contractualPartnersAllStream2: undefined,
            sitesOperatorAllStream: undefined,
            levelsAllStream: undefined
        })

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM)

        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text: string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, { operator_id: text })
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState, { levelsAllStream: undefined, sitesOperatorAllStream: undefined })

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id, Assets.GG_RM)
        }
    } else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function getGeneral() {

}

function ApplySiteSelect(text: string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id: text,
    })

    if (parseInt(text) === 0) {
        // Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getGeneral()
    }
}

function getGamificationProvider(): JSX.Element {
    return (
        <div>
            <div className="row clearfix">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.Gamification + ' ' + Assets.Provider.toLowerCase()}</h4>
                <span className="fl dbl mr btn btn-border mt5" style={{ paddingLeft: '10px', paddingRight: '10px' }}>{Main.Instance.GeneralState.general.gamificationProvider}</span>
            </div>
        </div>
    )
}

function getGameCategoryContribution(): JSX.Element {
    // const noDuplicates: boolean = NoDuplicates2(Main.Instance.GeneralState.general.gameCategoryContribution)
    const missingGames: {n: number, c: string, a: any[]} = getMissingGames()
    const Columns = []
    if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
        for (const item of Main.Instance.GeneralState.general.gameCategoryContribution) {
            // console.log (item)
            Columns.push ({
                show: Main.Instance.GeneralState.selectedTable !== item.contribution, width: 100, Header: item.contribution + '%', accessor: '', headerClassName: 'th-center', Cell: (row) => getAddGameButton(row, item.contribution)
            })
        }

        Columns.push (
            {show: Main.Instance.GeneralState.selectedTable === -1, width: 210, Header: <><FontAwesomeIcon icon = {faExclamationTriangle}/>&nbsp;{Assets.Warning}</>, accessor: '', headerClassName: 'th-center', className: 'th-center', Cell: () => <div title = {Assets.ThisGameHasContribution0Desc} style = {{fontSize: '12px', padding: '8px 12px'}} className = "label label-danger">{Assets.ThisGameHasContribution0}</div>}
        )
    }

    const columns: any[] = [
        {Header: Assets.Game + ' ' + Assets.Information.toLowerCase(), headerClassName: 'th-center risk-default', columns:[
        {width: 55, Header: Assets.ID, accessor: 'Id', headerClassName: 'th-left'},
        {Header: Assets.Name, accessor: 'Name', headerClassName: 'th-left' , filterMethod: (filter, row) => {
            const id = filter.id
            return row[id] !== undefined ? (row[id].toLowerCase()) === (filter.value).toLowerCase() : true
        }},
        {width: 150, Header: Assets.Category, accessor: 'Category', headerClassName: 'th-left'},
        {width: 250, Header: Assets.Provider, accessor: 'Provider', headerClassName: 'th-left'},
        // {show: Main.Instance.GeneralState.selectedTable === -1, width: 200, Header: Assets.Warning, accessor: '', headerClassName: 'th-center', Cell: () => <div>{Assets.ThisGameHasContribution0}</div>}
        ]},
    ]
/*
    if (Main.Instance.GeneralState.selectedTable === -1) {
        columns.push (
            {width: 200, Header: Assets.Warning, accessor: '', headerClassName: 'th-center', Cell: () => <div>{Assets.ThisGameHasContribution0}</div>}
        )
    } else {
        columns.push (
            {show: Main.Instance.GeneralState.selectedTable !== -1, width: 150, Header: Assets.Remove + ' ' + Assets.Game.toLowerCase(), accessor: '', headerClassName: 'th-center', Cell: (row) => getRemoveGameButton(row)}
        )
    }
*/
    columns.push (
        {Header: Assets.Contribution, headerClassName: 'th-center risk-default-light', columns: Columns}
    )

    columns.push (
        {show: Main.Instance.GeneralState.selectedTable !== -1, width: 150, Header: Assets.Remove + ' ' + Assets.Game.toLowerCase(), accessor: '', headerClassName: 'th-center', Cell: (row) => getRemoveGameButton(row)}
    )

/*
        {show: Main.Instance.GeneralState.selectedTable !== 0, width: 150, Header: Assets.Contribution + ' 0%', accessor: '', headerClassName: 'th-center', Cell: (row) => getAddGameButton(row, 0)},
        {show: Main.Instance.GeneralState.selectedTable !== 10, width: 150, Header: Assets.Contribution + ' 10%', accessor: '', headerClassName: 'th-center', Cell: (row) => getAddGameButton(row, 10)},
        {show: Main.Instance.GeneralState.selectedTable !== 100, width: 150, Header: Assets.Contribution + ' 100%', accessor: '', headerClassName: 'th-center', Cell: (row) => getAddGameButton(row, 100)},
        {show: Main.Instance.GeneralState.selectedTable === 0 || Main.Instance.GeneralState.selectedTable === 10 || Main.Instance.GeneralState.selectedTable === 100, width: 150, Header: Assets.Remove + ' ' + Assets.Game.toLowerCase(), accessor: '', headerClassName: 'th-center', Cell: (row) => getRemoveGameButton(row)},
*/
    const gamesByContribution: {games: number[], superfluousGames: number[]} = getGamesByContribution()
    return (
        <div>
            <div className="row clearfix">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.Game + ' ' + Assets.Category.toLowerCase() + ' ' + Assets.Configuration.toLowerCase()}</h4>
                <button
                    title={Assets.AddCategory}
                    key="-2"
                    onClick={() => {
                        const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                        general.gameCategoryContribution.push({/*category: '', */contribution: 0, games: [] })
                        Logic.Type.New(Main.Instance.GeneralState, { general })
                    }}
                    style={{ borderRadius: '0px' }}
                    className="fl dbl btn btn-success btn-sm mr"
                >
                    <i className={Assets_.faPlusClass}/>
                </button>
                {/*<FontAwesomeIcon key = "2" title = {noDuplicates ? Assets.Game + ' ' + Assets.IDs + Assets._are_ + Assets.Unique.toLowerCase() : Assets.GameIds + Assets._are_ + Assets.LogicNot.toLowerCase() + ' ' + Assets.Unique.toLowerCase()} icon = {noDuplicates ? faCheck : faTimes} style = {{marginTop: '8px', marginLeft: '5px', color: noDuplicates ? '#5cb85c' : '#d9534f'}}/>*/}
                {/*
                <div className = "clearfix fr">
                    {getGeneralContributions()}
                </div>
                */}
            </div>
            
            <div className="pr">
                {getGameCategoryContributions()}
                {Main.Instance.GeneralState.selectedCategory !== -1 ?
                <>
                <div style = {{fontSize: '16px', fontWeight: 700, position: 'absolute', top: '-4px', right: '0px'}}>
                    {Assets.GamesSelection}
                </div>
                {displaySelectGames(Main.Instance.GeneralState.selectedCategory)}
                </> : void 0}
            </div>
            {gamesByContribution.superfluousGames.length > 0 ?
            <div className = "mb5">
                <span>{Assets.Superfluous + ' ' + Assets.Games.toLowerCase()}:&nbsp;</span>
                {getSuperfluousGames(gamesByContribution.superfluousGames)}
            </div> : void 0}
            <div>
                <button
                    disabled = {missingGames.n === 0}
                    title = {Assets.Manage + ' ' + Assets.Missing.toLowerCase() + ' ' + Assets.games}
                    key = "-5"
                    onClick = {() =>
                        Logic.Type.New (Main.Instance.GeneralState, {selectedTable: -1, selectedGameId: '-1', selectedGameName: '-1'})
                    }
                    style = {{borderRadius: '0px'}}
                    className = {Main.Instance.GeneralState.selectedTable === -1 ? 'btn btn-primary btn-sm mr0 mb' : 'btn btn-default btn-sm mr0 mb'}
                >
                    {Assets.Manage + ' ' + Assets.Missing.toLowerCase() + ' ' + Assets.games} ({missingGames.n})
                </button>
            </div>

            {Main.Instance.GeneralState.selectedTable === -1 && missingGames.a.length > 0 || Main.Instance.GeneralState.selectedTable !== -1 && gamesByContribution.games.length > 0 ?
            <>
            <div className = "pr">
                <input 
                    style = {{height: '22px', width: '150px', paddingLeft: '5px', paddingRight: '5px'}} 
                    placeholder = {Assets.Filter + ' ' + Assets.games + Assets._by_ + Assets.ID} 
                    className = "mr mb" 
                    onChange = {(e) => {
                        tableFilters(e.target.value, 'Id');
                        Logic.Type.New(Main.Instance.GeneralState, {selectedGameId: e.target.value, selectedGameName: '-1'})
                    }} 
                    type = "number" 
                    value = {Main.Instance.GeneralState.selectedGameId !== '-1' ? Main.Instance.GeneralState.selectedGameId : ''}
                />
                {getAutocomplete(missingGames.a)}
                <input 
                    style = {{height: '22px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                    placeholder = {Assets.Filter + ' ' + Assets.games + Assets._by_ + Assets.Name.toLowerCase()} 
                    className = "mr mb" 
                    onChange = {(e) => {
                        tableFilters(e.target.value, 'Name');
                        Logic.Type.New(Main.Instance.GeneralState, {selectedGameId: '-1', selectedGameName: e.target.value}) 
                    }} 
                    list = "names"
                    type = "text" 
                    value = {Main.Instance.GeneralState.selectedGameName !== '-1' ? Main.Instance.GeneralState.selectedGameName : ''}
                />

                {Main.Instance.GeneralState.selectedTable !== -1 ?
                <button
                    style = {{position: 'absolute', top: '0px', right: '0px', marginRight: '0px', background: 'transparent', border: '0px', padding: '0px'}}
                    onClick = {() => Logic.Type.New(Main.Instance.GeneralState, {selectedTable: -1})}
                >
                    <FontAwesomeIcon icon = {faTimes}/>
                </button> : void 0}
            </div>
            <ReactTable
                data = {Main.Instance.GeneralState.selectedTable === -1 ? missingGames.a : gamesByContribution.games}
                filtered = {Main.Instance.GeneralState.filtered}
                onFilteredChange = {(filtered, column, value) => {
                    tableFilters(value, column.accessor)
                }}
                defaultFilterMethod = {(filter, row, column) => {
                    const id = filter.id
                    if (+filter.value === 0) {
                        return true
                    }
                    
                    return row[id] !== undefined ? +(row[id]) === +filter.value : true
                }}
                columns = {columns}
                showPaginationBottom = {false}
                showPaginationTop = {true}
                defaultPageSize={10}
            />
            </> : void 0}
        </div>
    )
}

function removeGame(game: number) {
    const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
    for (const item of general.gameCategoryContribution) {
        if (item.contribution !== Main.Instance.GeneralState.selectedTable) {continue}
        if (item.games) {
            for (let i: number = 0; i < item.games.length; i++) {
                if (game === item.games[i]) {
                    item.games.splice(i, 1)
                    break
                }
            }
        }
    }
    Logic.Type.New(Main.Instance.GeneralState, { general })
}

function getSuperfluousGames(games: number[]): JSX.Element[] {
    const buttons: JSX.Element[] = []
    for (const item of games) {
        buttons.push(<button key = {item.toString()} type = "button" onClick = {() => removeGame(item) } className = "btn btn-danger btn-xs">{Assets.Remove + Assets._a_ + Assets.Game.toLowerCase() + Assets._with_ + Assets.Game.toLowerCase() + ' ' + Assets.ID + ' ' + item}</button>)
    }
    return buttons
}

function tableFilters (value, accessor) {
    const filtered = Object.assign ([], Main.Instance.GeneralState.filtered)  
    let insertNewFilter: boolean = true

    if (filtered.length) {
        filtered.forEach((filter, i) => {
            if(filter.id === accessor) {
                if (value === '' || !value.length) {
                    filtered.splice(i, 1)
                } else {
                    filter.value = value
                }
                insertNewFilter = false
            }
        })
    }

    if (insertNewFilter) {
        filtered.push({id: accessor, value})
    }
    Logic.Type.New(Main.Instance.GeneralState, {filtered})
}

function getAutocomplete(missingGames?: any[]) {
    const options: JSX.Element[] = []
    const data: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
    // console.log(missingGames)
    if (Main.Instance.GeneralState.selectedTable === -1) {
        for (const item of missingGames) {
                options.push(<option key = {item.Id}>{item.Name}</option>)
        }
        
    } else {
        for (const item of data.gameCategoryContribution) {
            if (item.contribution === Main.Instance.GeneralState.selectedTable) {
                for (const Item of item.games) {
                    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                        if (Item === Main.Instance.DatabaseDataState.GamesStreamAll[i].Id) {
                            options.push(<option key = {Main.Instance.DatabaseDataState.GamesStreamAll[i].Id}>{Main.Instance.DatabaseDataState.GamesStreamAll[i].Name}</option>)
                        }
                    }
                    
                }
            }
        }
    }
    // console.log(options)
    return (
        <datalist id = "names" style = {{color: 'white'}}>
            {options}
        </datalist>
    )
}

function getRemoveGameButton(row) {
    return (
        <div className= "td-center">
            <button
                title = {Assets.Remove + ' ' + Assets.Game.toLowerCase()}
                key = {row.original.Id}
                onClick = {() => {
                    const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                    for (const item of general.gameCategoryContribution) {
                        if (item.contribution === Main.Instance.GeneralState.selectedTable) {
                            for (let i: number = 0; i < item.games.length; i++) {
                                if (item.games[i] === row.original.Id) {
                                    item.games.splice(i, 1)
                                    break
                                }
                            }
                        }
                    }
                    Logic.Type.New(Main.Instance.GeneralState, { general })
                }}
                style = {{borderRadius: '0px'}}
                className = "btn btn-danger btn-sm mr0"
            >
                {Assets.Remove + ' ' + Assets.Game.toLowerCase()}
            </button>
        </div>
    )
} 

function getGamesByContribution(): {games: number[], superfluousGames: number[]} {
    const games: any[] = []
    const superfluousGames: any[] = []
    if (Main.Instance.DatabaseDataState.GamesStreamAll && Main.Instance.GeneralState.general.gameCategoryContribution) {
        for (const item of Main.Instance.GeneralState.general.gameCategoryContribution) {
            if (item.contribution === Main.Instance.GeneralState.selectedTable && item.games) {
                for (const Item of item.games) {
                    let Break: boolean = false
                    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {

                        if (Item === Main.Instance.DatabaseDataState.GamesStreamAll[i].Id) {
                            games.push(Main.Instance.DatabaseDataState.GamesStreamAll[i])
                            Break = true
                            break
                        }
                    }
                    if (!Break) {
                        superfluousGames.push(Item)
                    }
                }
            }
        }
    }
    return {games, superfluousGames}
}

function getAddGameButton(row, percent: number): JSX.Element {
    // console.log(row)
    // console.log(row.original.Id)
    return (
    <div className= "td-center">
        <button
            className = {Main.Instance.GeneralState.selectedTable === -1 ? 'btn btn-success btn-sm mr0' : 'btn btn-warning btn-sm mr0'}
            // title = {Main.Instance.GeneralState.selectedTable === -1 ? Assets.Add + ' ' + Assets.Game.toLowerCase() : Assets.Move + ' ' + Assets.Game.toLowerCase()}
            key = {percent}
            onClick = {() => {
                const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                let found: boolean = false
                for (const item of general.gameCategoryContribution) {
                    if (item.contribution === percent) {
                        found = true
                    }
                }
                if (!found) {general.gameCategoryContribution.push({games: [], contribution: percent})}
                for (const item of general.gameCategoryContribution) {
                    if (item.contribution === percent) {
                        item.games.push(row.original.Id)
                        
                        
                    }
                    if (Main.Instance.GeneralState.selectedTable === item.contribution) {
                        for (let i: number = 0; i <  item.games.length; i++) {
                            if (row.original.Id === item.games[i]) {
                                item.games.splice(i, 1)
                            }
                        }    
                    }
                }
                
                Logic.Type.New(Main.Instance.GeneralState, { general })
            }}
            style = {{borderRadius: '0px'}}
        >
            {Main.Instance.GeneralState.selectedTable === -1 ? Assets.Add + ' ' + Assets.Game.toLowerCase() : Assets.Change + Assets._to_}
        </button>
    </div>
    )
}

function getMissingGames(): {n: number, c: string, a: any[]} {
    let missingGames: string = ''
    let MissingGames: number = 0
    const missingGamesArray: any[] = []
    // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
    // console.log (Main.Instance.GeneralState.general.gameCategoryContribution)
    if (Main.Instance.DatabaseDataState.GamesStreamAll && Main.Instance.GeneralState.general.gameCategoryContribution) {
        for (let k: number = 0; k < Main.Instance.DatabaseDataState.GamesStreamAll.length; k++) {
            const gameid: number = Main.Instance.DatabaseDataState.GamesStreamAll[k].Id
            let found: boolean = false
            for (let i: number = 0; i < Main.Instance.GeneralState.general.gameCategoryContribution.length; i++) {
                if (Main.Instance.GeneralState.general.gameCategoryContribution[i].games) {
                    for (let j: number = 0; j < Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length; j++) {
                        if (gameid === Main.Instance.GeneralState.general.gameCategoryContribution[i].games[j]) {
                            found = true
                            break
                        }
                    }
                }
            }
            if (!found) {
                missingGamesArray.push(Main.Instance.DatabaseDataState.GamesStreamAll[k])
                MissingGames++
                if (missingGames.length > 0) {missingGames += ', '}
                missingGames += gameid
                missingGames += ' (' 
                missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Name
                missingGames += ', '
                missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Category
                missingGames += ', '
                missingGames += Main.Instance.DatabaseDataState.GamesStreamAll[k].Provider
                missingGames += ')'
            }
        }
    }
    return {n: MissingGames, c: missingGames, a: missingGamesArray}
}
/*
function getGeneralContributions() : JSX.Element[] {
    const cc: JSX.Element[] = []
    for (let i: number = 0; i < Assets_.GeneralGamesContribution.length; i++) {
        cc.push(
            <div
                style = {{padding: '8px', fontSize: '14px'}}
                className = "fl label label-default ml"
                key = {i + '_'}
            >
                {Assets.Games + Assets._with_ + Assets_.GeneralGamesContribution[i] + '%' + Assets_._txt}:
            </div>
        )
        cc.push(
        <button
            onClick = {() => downloadCSVListAll(i)}
            title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.List.toLowerCase() + Assets._of_ + Assets.Games.toLowerCase() + Assets._with_ + Assets_.GeneralGamesContribution[i] + '% ' + Assets.Contribution.toLowerCase()}
            key = {i + '__'}
            style = {{color: '#6f9c67', background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px', marginRight: '5px'}}
            className = "fl btn btn-default btn-sm ml"
        >
            <FontAwesomeIcon icon = {faFileCsv}/>
        </button>
        )
        cc.push(
        <button
            onClick = {() => downloadListAll(i)}
            title =  {Assets.Download + ' ' + Assets.List.toLowerCase() + Assets._of_ + Assets.Games.toLowerCase() + Assets._with_ + Assets_.GeneralGamesContribution[i] + '% ' + Assets.Contribution.toLowerCase()}
            key = {i + '___'}
            style = {{padding: '4px 8px 3px', fontSize: '14px'}}
            className = "fl btn btn-success btn-sm mr"
        >
            <FontAwesomeIcon icon = {faDownload}/>{' ' + Assets_.generalGamesContribution[i].length + ' ' + Assets.Game_s}
        </button>
        )
    }

    const noDuplicates: boolean = NoDuplicates(Assets_.lists)
    cc.push (<FontAwesomeIcon key = "_" title = {noDuplicates ? Assets.Game + ' ' + Assets.IDs + Assets._are_ + Assets.Unique.toLowerCase() : Assets.GameIds + Assets._are_ + Assets.LogicNot.toLowerCase() + ' ' + Assets.Unique.toLowerCase()} icon = {noDuplicates ? faCheck : faTimes} style = {{marginTop: '8px', color: noDuplicates ? '#5cb85c' : '#d9534f'}}/>)
    return cc
}
*/
/*
function NoDuplicates2(array: IGameCategoryContributions[]): boolean {
    if (array === undefined) {return true}
    // if (array.length > 1) {
    for (let i: number = 0; i < array.length; i++) {
        if (array[i].games === undefined) { continue }
        for (let j: number = 0; j < array[i].games.length; j++) {
            for (let k: number = 0; k < array.length; k++) {
                if (array[k].games === undefined) { continue }
                for (let l: number = 0; l < array[k].games.length; l++) {
                    if (i === k && j === l) { continue }
                    if (i !== k && array[i].games[j] === array[k].games[l]) {
                        // console.log (i, j, array[i].games[j], k, l)
                        return false
                    }
                }
            }
        }
    }
    // }
    return true
}
*/
function getGameCategoryContributions(): JSX.Element[] {
    const gcc: JSX.Element[] = []
    // console.log (171)
    // console.log(Main.Instance.GeneralState.general)
    if (Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.gameCategoryContribution) {
        // console.log(Main.Instance.GeneralState.general.gameCategoryContribution)
        for (let i: number = 0; i < Main.Instance.GeneralState.general.gameCategoryContribution.length; i++) {
            gcc.push(
                <div key={i.toString()} className="clearfix" /*className = "row clearfix ml0"*/>
                    {/*
                    <FormSelect
                        verynarrow2 = {true}
                        title={Assets.GameCategory}
                        level={true}
                        mt0={true}
                        value={Main.Instance.GeneralState.general.gameCategoryContribution[i].category ? Main.Instance.GeneralState.general.gameCategoryContribution[i].category : ''}
                        data={getGameCategoryContributionOptions()}
                        ClassName="form-control"
                        o1={Assets.SelectCategory}
                        o2={Assets.SelectCategory}
                        onChange={(text) => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.gameCategoryContribution[i].category = text
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }}
                        icon={Assets_.faSiteClass}
                    />
                    */}
                    <FormInputNumber
                        value={Main.Instance.GeneralState.general.gameCategoryContribution[i] && Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution ? Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution.toString() : '0'}
                        // description={Main.Instance.GeneralState.general.gameCategoryContribution[i] && Main.Instance.GeneralState.general.gameCategoryContribution[i].games && Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length > 0 ? contributionGamesList(Assets_.GameCategoryIncluded[i]) : ''}
                        outerDiv="rows-per-table input-group fl ml0 pl0 mb5 mr"
                        ClassName="h30 w60"
                        type="number"
                        min="0"
                        max="100"
                        onChange={(text) => {
                            // console.log(text)
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            if (!general.gameCategoryContribution[i]) {
                                general.gameCategoryContribution.push({ /*category: Assets_.GameCategoryIncluded[i],*/ contribution: 0 })
                            }
                            general.gameCategoryContribution[i].contribution = parseInt(text)

                            // general.gameCategoryContribution[i].category = Assets_.GameCategoryIncluded[i]
                            // general.gameCategoryContribution[i].games = []
                            // for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                            //     if (Item.Id === 919 || Item.Id === 135 || Item.Id === 136 || Item.Id === 138 || Item.Id === 1037 || Item.Id === 1036 || Item.Id === 848 || Item.Id === 768 || Item.Id === 771 || Item.Id === 769 || Item.Id === 770 || Item.Id === 855 || Item.Id === 918 || Item.Id === 978) {
                            //         general.gameCategoryContribution[i].games.push({id: Item.Id, name: Item.Name})
                            //     }
                            // }
                            // console.log(general)
                            Logic.Type.New(Main.Instance.GeneralState, { general })

                        }}
                        icon={Assets_.faPercentageClass}
                    // iconText = {Assets_.GameCategoryIncluded[i] + ' ' + (Main.Instance.GeneralState.general.gameCategoryContribution[i] && (Main.Instance.GeneralState.general.gameCategoryContribution[i].category === 'Roulette' || Main.Instance.GeneralState.general.gameCategoryContribution[i].category === 'Black Jack') ? ' (' + Assets.TableGames + ') ' : '') + Assets.Contribution.toLowerCase() + Assets._in_ + '%'}
                    // iconTextS = "14px"
                    />
                    <button
                        title={Assets.RemoveCategory}
                        key={i.toString() + 'a'}

                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.gameCategoryContribution.splice(i, 1)
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }}
                        style={{ borderRadius: '0px' }}
                        className="fl dbl btn btn-danger btn-sm mr"
                    >
                        <i className={Assets_.faTrashLgButtonClass} />
                    </button>
                    {/* <button
                        key={i.toString() + 'b'}
                        type="button"
                        onClick={() => {
                            let selectedCategory: number
                            if (Main.Instance.GeneralState.selectedCategory === i) {
                                selectedCategory = -1
                            } else {
                                selectedCategory = i
                            }
                            
                            Logic.Type.New(Main.Instance.GeneralState, { selectedCategory })
                        }}
                        className={Main.Instance.GeneralState.selectedCategory === i ? 'fl dbl btn btn-primary btn-sm mr' : 'fl dbl btn btn-default btn-sm mr'}
                    >
                        {Assets.SelectGames}
                    </button> */}
                    <button
                        disabled = {Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length === 0}
                        title = {Assets.Manage + ' ' + Assets.games + Assets._in_ + Assets.Category.toLowerCase() + Assets._with_ + Assets.Contribution.toLowerCase() + ' ' + Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution.toString() + '%'}
                        key = {i.toString() + 'h'}
                        onClick = {() => {
                            if (Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution === Main.Instance.GeneralState.selectedTable) {
                                Logic.Type.New (Main.Instance.GeneralState, {selectedTable: -1, selectedGameId: '-1', selectedGameName: '-1'})
                            } else {
                                Logic.Type.New (Main.Instance.GeneralState, {selectedTable: Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution})
                            }
                            
                        }}
                        style = {{borderRadius: '0px'}}
                        className = {Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution === Main.Instance.GeneralState.selectedTable ? 'fl dbl btn btn-primary btn-sm mr' : 'fl dbl btn btn-default btn-sm mr'}
                    >
                        {Assets.ManageGames}
                    </button>
                    {Main.Instance.GeneralState.general.gameCategoryContribution[i].games && Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length > 0 ? 
                    <button
                        key={i.toString() + 'c'}
                        type="button"
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            delete general.gameCategoryContribution[i].games
                            Logic.Type.New(Main.Instance.GeneralState, { selectedCategory: -1, general })
                        }}
                        className={'fl dbl btn btn-danger btn-sm mr'}
                    >
                        {Assets.Ignore + ' ' + Assets.Game.toLowerCase() + ' ' + Assets.Selection.toLowerCase()}
                    </button> : void 0}
                    <div
                       title = {Assets.Upload + ' ' + Assets.List.toLowerCase()}
                       key = {i.toString() + 'd'}
                       style = {{padding: '8px', fontSize: '14px'}}
                       className = "fl label label-primary mr"
                    >
                        <FontAwesomeIcon icon = {faUpload}/>
                    </div>
                    <input
                        accept = "text/plain"
                        type = "file"
                        key = {i.toString() + 'e'}
                        className = "uploadLogoWrapper dbl fl"
                        style = {{width: '200px', height: '30px', marginTop: '0px', marginRight: '5px'}}
                        onClick = {(event) => event.target = null}
                        onChange = {(event) => {
                            const cloneFile: File = event.target.files[0]
                            const reader = new FileReader()
                            reader.onload = (e) => {
                                // console.log (reader.result)
                                const decodedData: string = base64.decode((reader.result as string).replace('data:text/plain;base64,',''))
                                // console.log (decodedData)
                                const DecodedData: string[] = decodedData.split('\n')
                                // console.log (DecodedData)
                                // const bonus: Bonus = Object.assign ({}, Main.Instance.BonusState.bonus)
                                const games: string[] = []
                                for (const item of DecodedData) {
                                    if (item.length > 0) {
                                        games.push(item.replace('\r', ''))
                                    }
                                }
                                const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                                // if (!general.gameCategoryContribution[i].games) {
                                general.gameCategoryContribution[i].games = []
                                // }
                                for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                                    // if (general.gameCategoryContribution[i].category === item.Category.trim()) {
                                    for (const Item of games) {
                                        if (item.Id === +Item) {
                                            general.gameCategoryContribution[i].games.push(item.Id/*{id: item.Id, name: item.Name}*/)
                                            break
                                        }
                                    }
                                    // }
                                }
                                
                                Logic.Type.New (Main.Instance.GeneralState, {general, selectedCategory: i})
                            }
                            reader.readAsDataURL(cloneFile);
                        }}
                    />
                    {Main.Instance.GeneralState.general.gameCategoryContribution[i].games && Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length > 0 ?
                    <button
                        onClick = {() => downloadCSVList(i)}
                        title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.List.toLowerCase() + ': ' + Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length + ' ' + Assets.Game_s}
                        key = {i.toString() + 'f'}
                        style = {{color: '#337ab7', background: 'transparent', border: '0px', fontSize: '20px', padding: '0px', marginTop: '0px', marginRight: '5px'}}
                        className = "fl btn btn-default btn-sm"
                    >
                        <FontAwesomeIcon icon = {faFileCsv}/>
                    </button> : void 0}
                    {/*console.log (Main.Instance.GeneralState.general.gameCategoryContribution[i].games)*/}
                    {Main.Instance.GeneralState.general.gameCategoryContribution[i].games && Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length > 0 ?
                    <button
                        onClick = {() => downloadList(i)}
                        title = {Assets.Download + ' ' + Assets.List.toLowerCase() + ': ' + Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length + ' ' + Assets.Game_s}
                        key = {i.toString() + 'g'}
                        style = {{padding: '4px 8px 3px', fontSize: '14px'}}
                        className = "fl btn btn-primary btn-sm mr"
                    >
                        <FontAwesomeIcon icon = {faDownload}/>{' ' + Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length + ' ' + Assets.Game_s}
                    </button> : void 0}
                    
                    {/*
                    <FormInputNumber
                        narrow = {true}
                        // float = {true}
                        description = {Assets.Contribution + ' (' + Assets.Percentage + ')'}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution ? Main.Instance.GeneralState.general.gameCategoryContribution[i].contribution.toString() : ''}
                        type = "text"
                        iconText = {Assets.Contribution + Assets._in_ + '%'}
                        iconTextSize = "14px"
                        onChange = {(text) => {
                            const general: IGeneral = Object.assign ({}, Main.Instance.GeneralState.general)
                            general.gameCategoryContribution[i].contribution = parseInt(text)
                            Logic.Type.New (Main.Instance.GeneralState, {general})
                        }}
                    />
                    */}
                </div>
            )
        }
        
    }
    return gcc
}

function downloadCSVList(j: number) {
    // console.log ('downloadCSVList ', array.length)
    const array: number[] = Main.Instance.GeneralState.general.gameCategoryContribution[j].games
    let s: string = Main.Instance.DatabaseDataState.GamesStreamAll ? 'Id,Name,Category,Provider\n' : 'Id\n'
    for (let i: number = 0; i < array.length; i++) {
        let found: boolean = false
        s += array[i]/*.id*/
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                if (item.Id === array[i]/*.id*/) {
                    s += ',' + item.Name + ',' + item.Category + ',' + item.Provider
                    found = true
                    break
                }
            }
        }
        if (!found) { s += ',,,'}
        if (i < array.length - 1) {
            s += '\n'
        }
    }
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.Games + Assets._for_ + /* Assets.Category.toLowerCase() + ' ' + Main.Instance.GeneralState.general.gameCategoryContribution[j].category + '_'*/ + Main.Instance.GeneralState.general.gameCategoryContribution[j].contribution + '%' + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}
/*
function downloadCSVListAll(j: number) {
    // console.log ('downloadCSVList ', array.length)
    const array = Assets_.generalGamesContribution[j]
    // const array = (contribution === '100' ? Assets_.generalGamesContribution100 : contribution === '0' ?  Assets_.generalGamesContribution0: undefined)
    let s: string = Main.Instance.DatabaseDataState.GamesStreamAll ? 'Id,Name,Category,Provider\n' : 'Id\n'
    for (let i: number = 0; i < array.length; i++) {
        let found: boolean = false
        s += array[i]
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            // console.log (Main.Instance.DatabaseDataState.GamesStreamAll)
            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                if (item.Id === +array[i]) {
                    s += ',' + item.Name + ',' + item.Category + ',' + item.Provider
                    found = true
                    break
                }
            }
        }
        if (!found) { s += ',,,'}
        if (i < array.length - 1) {
            s += '\n'
        }
    }
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.Games + ' ' + Assets._with_ + Assets_.GeneralGamesContribution[j] + '%' + Assets_._csv
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}
*/
function downloadList(j: number) {
     // console.log ('downloadList ', array.length)
       // tu se mora zgodit click
       const array: number[] = Main.Instance.GeneralState.general.gameCategoryContribution[j].games
       let s: string = ''
       for (let i: number = 0; i < array.length; i++) {
           // console.log (array[i])
           s += array[i]
           if (i < array.length - 1) {
               s += '\n'
           }
       }
       const element = document.createElement('a')
       const file = new Blob([s], {type: 'text/plain'})
       element.href = URL.createObjectURL(file)
       element.download = Assets.Games + Assets._for_ /*+ Assets.Category.toLowerCase() + ' ' + Main.Instance.GeneralState.general.gameCategoryContribution[j].category + '_' */+ Main.Instance.GeneralState.general.gameCategoryContribution[j].contribution + '%' + Assets_._txt
       // element.click()
       element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
  
}
/*
function downloadListAll(j: number) { 
    // const readline = require('linebyline')
    // const fs = require('fs')

    // const readInterface = readline.createInterface({
    //     input: fs.createReadStream('../Assets/g0.txt'),
    //     output: process.stdout,
    //     console: false
    // });
    // const arr = []
    // readInterface.on('line', (line) => {
    //     arr.push(line);
    // });
    const array: number[] = Assets_.generalGamesContribution[j]
    let s: string = ''
    // console.log (array)
    for (let i: number = 0; i < array.length; i++) {
        // console.log ()
        s += array[j][i]
        if (i < array.length - 1) {
            s += '\n'
        }
    }
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/plain'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.Games + ' ' + Assets._with_ + Assets_.GeneralGamesContribution[j] + '%' + Assets_._txt
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
 
}
*/
// function ReadTextFile(rawFile) {
//     if(rawFile.readyState === 4) {
//         if(rawFile.status === 200 || rawFile.status == 0) {
//             const allText = rawFile.responseText;
//             alert(allText);
//         }
//     }
//  }

// function readTextFile(file) {
//     const rawFile = new XMLHttpRequest();
//     rawFile.open('GET', file, false);
//     rawFile.onreadystatechange = () => ReadTextFile(file)
//     rawFile.send(null);
// }

function displaySelectGames(i) {
    if (Main.Instance.GeneralState.general.gameCategoryContribution[i]) {
        return (
            <select
                multiple={true}
                style = {{minHeight: '50px', background: '#fff', position: 'absolute', top: '18px', right: '0px', bottom: '5px', paddingLeft: '5px', zIndex: 2}}
                value={Main.Instance.GeneralState.general.gameCategoryContribution[i].games && Main.Instance.GeneralState.general.gameCategoryContribution[i].games.length > 0 ? getMultipleSelectGames(Main.Instance.GeneralState.general.gameCategoryContribution[i].games) : []}
                onChange={(e) => {
                    const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                    if (!general.gameCategoryContribution[i].games) {
                        general.gameCategoryContribution[i].games = []
                        for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                            if (item.Id === +e.target.value) {
                                general.gameCategoryContribution[i].games.push(item.Id/*{id: item.Id, name: item.Name}*/)
                            }
                        }
                    } else {
                        if (general.gameCategoryContribution[i].games./*map(a => a.id).*/indexOf(+e.target.value) === -1) {
                            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                                if (item.Id === +e.target.value) {
                                    general.gameCategoryContribution[i].games.push(item.Id/*{id: item.Id, name: item.Name}*/)
                                }
                            }
                        } else {
                            general.gameCategoryContribution[i].games.splice(general.gameCategoryContribution[i].games./*map(a => a.id).*/indexOf(+e.target.value), 1)
                        }
                    }
                    Logic.Type.New(Main.Instance.GeneralState, { general })
                }}
            >
                {getMultipleSelectOptions(/*Main.Instance.GeneralState.general.gameCategoryContribution[i].category*/)}
            </select>
        )
    }
}

function getMultipleSelectOptions(/*category*/) {
    const options: JSX.Element[] = []
    for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
        // if (item.Category === category) {
        options.push(<option key={item.Id} value={item.Id}>({item.Id})&nbsp;{item.Name}</option>)
        // }
    }
    // for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
    //     if (category === 'Roulette') {
    //         if (item.Id === 2527 || item.Id === 1084 || item.Id === 425 || item.Id === 2531 || item.Id === 548 || item.Id === 547 || item.Id === 575 || item.Id === 242 || item.Id === 243 || item.Id === 244 || item.Id === 245 || item.Id === 246 || item.Id === 2185 || item.Id === 247 || item.Id === 947 || item.Id === 2117) {
    //             options.push(<option key={item.Id} value={item.Id}>{item.Name}</option>)
    //         }
    //     } else if (category === 'Black Jack') {
    //         if (item.Id === 334 || item.Id === 118 || item.Id === 119 || item.Id === 120 || item.Id === 2184 || item.Id === 125 || item.Id === 126 || item.Id === 923 || item.Id === 995 || item.Id === 151 || item.Id === 152 || item.Id === 423 || item.Id === 424 || item.Id === 256 || item.Id === 257 || item.Id === 258) {
    //             options.push(<option key={item.Id} value={item.Id}>{item.Name}</option>)
    //         }
    //     } else if (category === 'Table Games') {
    //         if (item.Id === 110 || item.Id === 111 || item.Id === 135 || item.Id === 295 || item.Id === 296 || item.Id === 297) {
    //             options.push(<option key={item.Id} value={item.Id}>{item.Name}</option>)
    //         }
    //     } else if (item.Category === category) {
    //         options.push(<option key={item.Id} value={item.Id}>{item.Name}</option>)
    //     }
    // }
    return options
}

function getMultipleSelectGames(games: number[]) {
    const arr: string[] = []
    for (const item of games) {
        arr.push(item.toString())
    }
    return arr
}
/*
function contributionGamesList(category) {
    let description: string = ''
    for (const item of Main.Instance.GeneralState.general.gameCategoryContribution) {
        // if (item.category === category) {
        if (item.games) {
            for (let i: number = 0; i < item.games.length; i++) {
                const name = 'tralala'
                description += name
                if (i < item.games.length - 1) {
                    description += ', '
                }
            }
        }
        // }
    }
    return description
}
*/
export function getGameCategoryContributionOptions(): Array<{ id: string, name: string }> {
    const array: Array<{ id: string, name: string }> = []
    // console.log (Object.keys(GameCategories))
    // for (let i: number = 0; i < Object.keys(GameCategories).length; i++) {
    //     array.push ({
    //         id: GameCategories[Object.keys(GameCategories)[i]],
    //         name: GameCategories[Object.keys(GameCategories)[i]]
    //     })
    //     // array.push(<option key = {i.toString()} value = {GameCategories[parseInt(Object.keys(GameCategories)[i])]}>{GameCategories[parseInt(Object.keys(GameCategories)[i])]}</option>)
    // }
    // console.log (array)
    // GameSessions
    // console.log (GameCategories)
    for (let i: number = 0; i < Assets_.GameCategoryIncluded.length; i++) {
        array.push({ name: Assets_.GameCategoryIncluded[i], id: Assets_.GameCategoryIncluded[i] })
    }
    return array
}

function allowBonusMoneyInTournaments(): JSX.Element {
    return (
        <div>
            <div className="row clearfix mt7">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.AllowBonusMoneyInTournaments}</h4>
                <div className="fl dbl mr mt7">
                    <Switch
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.allowBonusMoneyInTournaments= !general.allowBonusMoneyInTournaments
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }
                        }
                        className={Main.Instance.GeneralState.general.allowBonusMoneyInTournaments ? '' : 'off-background-grey'}
                        on={Main.Instance.GeneralState.general.allowBonusMoneyInTournaments}
                    />
                </div>
            </div>
        </div>
    )
    }

function getGlobalRacesConfiguration(): JSX.Element {
    return (
        <div>
            <div className="row clearfix">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.GlobalRacesConfiguration}</h4>
            </div>
            <div className="row clearfix ml0">
                {/* FormInputNumber */}
                <FormInputNumber
                    value={Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes.toString() : '0'}
                    description={Assets.Start + ' ' + Assets.Minutes}
                    outerDiv="rows-per-table input-group fl ml0 pl0"
                    ClassName="h30 w60"
                    type="number"
                    min="0"
                    onChange={(text) => {
                        const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                        general.globalRacesConfiguration.startMinutes = parseInt(text)
                        Logic.Type.New(Main.Instance.GeneralState, { general })
                    }}
                    // icon = {Assets_.faPercentageClass}
                    iconText={Assets.Start + ' ' + Assets.Minutes.toLowerCase()}
                    iconTextS="14px"
                />

                <FormInputNumber
                    value={Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes.toString() : '0'}
                    description={Assets.Start + ' ' + Assets.Minutes}
                    outerDiv="rows-per-table input-group fl ml pl0"
                    ClassName="h30 w60"
                    type="number"
                    min="0"
                    onChange={(text) => {
                        const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                        general.globalRacesConfiguration.startMinutes = parseInt(text)
                        Logic.Type.New(Main.Instance.GeneralState, { general })
                    }}
                    // icon = {Assets_.faPercentageClass}
                    iconText={Assets.DurationMinutes}
                    iconTextS="14px"
                />
                {/*
                <FormInput
                    float = {true}
                    narrow = {true}
                    description = {Assets.Start + ' ' + Assets.Minutes}
                    ClassName = "form-control register-input"
                    value = {Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ?  Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes.toString() : '0'}
                    type = "text"
                    iconText = {Assets.Start + ' ' + Assets.Minutes}
                    iconTextSize = "14px"
                    onChange = {(text) => {
                        const general: IGeneral = Object.assign ({}, Main.Instance.GeneralState.general)
                        general.globalRacesConfiguration.startMinutes = parseInt(text)
                        Logic.Type.New (Main.Instance.GeneralState, {general})
                    }}
                />
*/}
                {/*
            </div>
            <div className="row clearfix ml0">
*/}
                {/*
                <FormInput
                    float = {true}
                    narrow = {true}
                    description = {Assets.DurationMinutes}
                    ClassName = "form-control register-input"
                    value = {Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ?  Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes.toString() : '0'}
                    type = "text"
                    iconText = {Assets.DurationMinutes}
                    iconTextSize = "14px"
                    onChange = {(text) => {
                        const general: IGeneral = Object.assign ({}, Main.Instance.GeneralState.general)
                        general.globalRacesConfiguration.durationMinutes = parseInt(text)
                        Logic.Type.New (Main.Instance.GeneralState, {general})
                    }}
                />
*/}
            </div>
        </div>
    )
}

function getCurrencyContributions(): JSX.Element {
    return (
        <div>
            <div className="row clearfix">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.Currency + ' ' + Assets.Contribution.toLowerCase()}</h4>

                <div className="fl dbl mr mt7" title="activity">
                    <Switch
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.currencyContributions.active = !general.currencyContributions.active
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }
                        }
                        on={Main.Instance.GeneralState.general.currencyContributions && Main.Instance.GeneralState.general.currencyContributions.active}
                        className={Main.Instance.GeneralState.general.currencyContributions && Main.Instance.GeneralState.general.currencyContributions.active ? '' : 'off-background-grey'}
                    />
                </div>
            </div>
            {Main.Instance.GeneralState.general.currencyContributions && Main.Instance.GeneralState.general.currencyContributions.active ?
                <div className="clearfix">

                    {getContributionsByCurrency()}
                </div> : void 0}
        </div>
    )
}

function getSignUpBonusSetupReward(): JSX.Element {
    return (
        <div>
            <div className="row clearfix">
                <h4 className="fl dbl mr" style={{ marginLeft: '15px' }}>{Assets.SignUpBonusSetupReward}</h4>

                <div className="fl dbl mr mt7" title="activity">
                    <Switch
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.signUpBonusReward.active = !general.signUpBonusReward.active
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }
                        }
                        className={Main.Instance.GeneralState.general.signUpBonusReward && Main.Instance.GeneralState.general.signUpBonusReward.active ? '' : 'off-background-grey'}
                        on={Main.Instance.GeneralState.general.signUpBonusReward && Main.Instance.GeneralState.general.signUpBonusReward.active}
                    />
                </div>
            </div>
            {Main.Instance.GeneralState.general.signUpBonusReward && Main.Instance.GeneralState.general.signUpBonusReward.active ?
                <div className="row clearfix" style={{ marginLeft: '0px' }}>
                    <FormSelect
                        level={true}
                        wide={true}
                        mt0={true}
                        value={Main.Instance.GeneralState.general.signUpBonusReward && Main.Instance.GeneralState.general.signUpBonusReward.rewardId ?
                            Main.Instance.GeneralState.general.signUpBonusReward.rewardId.toString() : '0'}
                        data={Main.Instance.DatabaseDataState.rewardShopStreamAll}
                        ClassName="form-control"
                        o1={Assets.SelectReward}
                        o2={Assets.SelectReward}
                        onChange={(text) => SetBonusSetupReward(text)}
                        icon={Assets_.faRewardClass}
                    />
                </div> : void 0}

        </div>
    )
}

function getCategoriesSetup(): JSX.Element {
    return (
        <div>
            <h4>{Assets.CategoriesSetup}</h4>
            <div className="clearfix">
                <div className="fl mr" title="activity">
                    <Switch
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            general.playerCategories.active = !general.playerCategories.active
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }
                        }
                        on={Main.Instance.GeneralState.general.playerCategories && Main.Instance.GeneralState.general.playerCategories.active}
                    />
                </div>
                {Main.Instance.GeneralState.general.playerCategories.active ? getCategories() : void 0}
            </div>
            {Main.Instance.GeneralState.general.playerCategories.active && Main.Instance.GeneralState.category > -1 ?
                <div style={{ marginTop: '5px' }} className="clearfix">
                    {getMinMaxByCurrency()}
                </div> : void 0}
        </div>
    )
}

function getGlobalObjectiveConfiguration(): JSX.Element {
    if (Main.Instance.GeneralState.general.globalObjectiveConfiguration === undefined) {
        return <div />
    }
    // console.log (Main.Instance.GeneralState.general.globalObjectiveConfiguration.betsConfiguration)
    return (
        <div>
            <h4>{Assets.GlobalObjectiveConfiguration}</h4>
            {GetMinByCurrency('betsConfiguration')}
            {GetMinByCurrency('depositsConfiguration')}
            {GetMinByCurrency('withdrawalsConfiguration')}
        </div>
    )
}

export function General() {
    DbRoutes.wsReinitialize()
    let selectOperator: string = Assets.SelectOperatorBrandOwner
    let operatorQ: string = Assets.OperatorBrandOwnerQ

    const selectSite: string = Assets.SelectSiteGamingSite
    const siteQ: string = Assets.SiteGamingSiteQ

    if (Main.Instance.GeneralState.category === -2) {
        Logic.Type.New(Main.Instance.GeneralState, { category: -1 })
        getGeneralData()
    }

    if (Main.Instance.GeneralState.general === undefined) { return (<div />) }

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream2 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }
    // console.log(Main.Instance.GeneralState.general)
/*
    if (Main.Instance.GeneralState.general.gameCategoryContribution) {
        console.log (JSON.stringify(Main.Instance.GeneralState.general.gameCategoryContribution))
    }
*/
    return (
        <div>
            <div className="clearfix">
                <h3 className="level fl">{Assets.GeneralSettings}</h3>

                {isUserSystem() ?
                    <FormSelect
                        level={true}
                        value={!Main.Instance.FrontendStoreState.holding_id ? '0' :
                            Main.Instance.FrontendStoreState.holding_id}
                        data={Main.Instance.DatabaseDataState.masterAllStream}
                        ClassName="form-control"

                        o1={Assets.SelectMaster}
                        o2={Assets.MasterQ}
                        onChange={(text) => ApplyMasterSelect(text)}
                        icon={Assets_.faMasterClass}
                    /> : void 0}

                {isUserSystem() || isUserMaster() || isUserGameProvider() ?
                    <FormSelect
                        level={true}
                        value={!Main.Instance.FrontendStoreState.operator_id ? '0' :
                            Main.Instance.FrontendStoreState.operator_id}
                        data={Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                        data2={Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                        label1={Assets.OP}
                        label2={Assets.BO}

                        ClassName="form-control"

                        o1={selectOperator}
                        o2={operatorQ}
                        onChange={(text) => ApplyOperatorSelect(text)}
                        icon={Assets_.faCompanyClass}
                    />
                    : void 0}

                {!isUserSubEntity() ?
                    <FormSelect
                        level={true}
                        value={!Main.Instance.FrontendStoreState.site_id ? '0' :
                            Main.Instance.FrontendStoreState.site_id}
                        data={Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                        ClassName="form-control"
                        o1={selectSite}
                        o2={siteQ}
                        onChange={(text) => ApplySiteSelect(text)}
                        icon={Assets_.faSubEntityClass}
                    />
                    : void 0}
            </div>
            {IsBuildMyChance() ? void 0 : getCategoriesSetup()}
            {Main.Instance.GeneralState.general.gamificationProvider ? getGamificationProvider() : void 0}
            {Main.Instance.GeneralState.general.gameCategoryContribution ? getGameCategoryContribution() : void 0}
            {IsBuildMyChance() && Main.Instance.GeneralState.general.allowBonusMoneyInTournaments !== undefined ? allowBonusMoneyInTournaments() : void 0}
            {Main.Instance.GeneralState.general.globalRacesConfiguration ? getGlobalRacesConfiguration() : void 0}
            {Main.Instance.GeneralState.general.signUpBonusReward ? getSignUpBonusSetupReward() : void 0}
            {Main.Instance.GeneralState.general.currencyContributions ? getCurrencyContributions() : void 0}
            {Main.Instance.GeneralState.general.globalObjectiveConfiguration ? getGlobalObjectiveConfiguration() : void 0}
            {/*console.log (Main.Instance.GeneralState.general.gameCategoryContribution)*/}
            {getButtons()}
        </div>
    )
}
/*
function Sett() {
    const general: IGeneral = Object.assign ({}, Main.Instance.GeneralState.general)
    general.gameCategoryContribution = [
        {category: GameCategories.Slots, contribution: 100},
        {category: GameCategories.BlackJack, contribution: 10},
        {category: GameCategories.LiveCasino, contribution: 10},
        {category: GameCategories.Roulette, contribution: 10},
        {category: GameCategories.VideoPoker, contribution: 50}
    ]
    Logic.Type.New (Main.Instance.GeneralState, {general})
}
*/
function getButtons(): JSX.Element {
    return (
        <div className="mb">
            <button disabled={JSON.stringify(Main.Instance.GeneralState.general) === Main.Instance.GeneralState.general_} onClick={() => setGeneralData()} className="btn btn-danger btn-sm">{Assets.UpdateGeneralData}</button>
            <button onClick={() => getGeneralData()} className="btn btn-warning btn-sm">{Assets.GetGeneralDataFromDatabase}</button>
        </div>
    )
}

function setGeneralData() {
    DbRoutes.WsUpdateGeneral(Main.Instance.GeneralState.general)
    alert(Assets.General + ' ' + Assets.Data.toLowerCase() + ' ' + Assets.Updated.toLowerCase())
}

function getGeneralData() {
    DbRoutes.WsGetGeneral()
    /*
        Logic.Type.New (Main.Instance.GeneralState.general. {
            signUpBonusReward : {
                active: true,
                rewardId: 1
            },
            currencies : [
                {code: 'EUR', minBet: '0.3'},
                {code: 'USD', minBet: '0.3'},
                {code: 'GBP', minBet: '0.3'},
                {code: 'SEK', minBet: '3'},
                {code: 'NOK', minBet: '3'},
                {code: 'CAD', minBet: '0.3'},
            ],
            missionsMinimalBets : {
                active: true, 
                minimalBets: [
                    {upperThreshold: 1, upperInclusive: false},
                    {lowerThreshold: 1, lowerInclusive: true, upperThreshold: 5, upperInclusive: false},
                    {lowerThreshold: 5, lowerInclusive: true}
                ]
            }
        })
    */
}

function SetBonusSetupReward(text: string) {
    const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
    general.signUpBonusReward.rewardId = +text
    Logic.Type.New(Main.Instance.GeneralState, { general })
}

function selectCategory(category) {
    Logic.Type.New(Main.Instance.GeneralState, { category })
}

function getCategories(): JSX.Element[] {
    const c: JSX.Element[] = []
    c.push(
        <button
            key="-1"
            type="button"
            onClick={() => selectCategory(-1)}
            className={Main.Instance.GeneralState.category === -1 || Main.Instance.GeneralState.category === -2 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
        >
            <FontAwesomeIcon icon={faSitemap} />
        </button>,
    )
    if (Main.Instance.GeneralState.general.playerCategories && Main.Instance.GeneralState.general.playerCategories.categories) {
        for (let i: number = 0; i < Main.Instance.GeneralState.general.playerCategories.categories.length; i++) {
            c.push(
                <button
                    key={i.toString()}
                    type="button"
                    onClick={() => selectCategory(i)}
                    className={Main.Instance.GeneralState.category === i ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}
                >
                    {Assets.Category}{' ' + (i + 1)}
                </button>,
            )
        }
    }

    if (Main.Instance.GeneralState.category !== -1 && Main.Instance.GeneralState.category !== -2) {
        c.push(
            <button
                key="-2"
                type="button"
                onClick={() => Edit('lowerInclusive', Main.Instance.GeneralState.category)}
                className={
                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === undefined ? 'btn btn-default btn-sm' :
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === false ? 'btn btn-danger btn-sm' :
                            Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === true ? 'btn btn-success btn-sm' : ''}
            >
                {Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === undefined ? Assets.LowerBoundaryNonExistent :
                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === false ? Assets.LowerBoundaryExclusive :
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive === true ? Assets.LowerBoundaryInclusive : ''}
            </button>,
        )

        c.push(
            <button
                key="-4"
                type="button"
                onClick={() => Edit('upperInclusive', Main.Instance.GeneralState.category)}
                className={
                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === undefined ? 'btn btn-default btn-sm' :
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === false ? 'btn btn-danger btn-sm' :
                            Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === true ? 'btn btn-success btn-sm' : ''}
            >
                {Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === undefined ? Assets.UpperBoundaryNonExistent :
                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === false ? Assets.UpperBoundaryExclusive :
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive === true ? Assets.UpperBoundaryInclusive : ''}
            </button>,
        )
    }

    return c
}

function Edit(what: string, which: number, value?: string) {
    const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
    /*if (what === 'lowerThreshold' || what === 'upperThreshold') {
        general.playerCategories.categories[which][what] = +value
    } else*/
    if (what === 'lowerInclusive' || what === 'upperInclusive') {
        if (general.playerCategories.categories[which][what] === undefined) { general.playerCategories.categories[which][what] = false } else if (general.playerCategories.categories[which][what] === false) { general.playerCategories.categories[which][what] = true } else if (general.playerCategories.categories[which][what] === true) { general.playerCategories.categories[which][what] = undefined }
    }

    Logic.Type.New(Main.Instance.GeneralState, { general })
    // }
}

function GetMinByCurrency(which: string): JSX.Element {
    return (
        <div>
            <div className="row clearfix">
                <h5 className="fl dbl mr" style={{ marginLeft: '15px' }}>{injectSpaces(capitalizeFirstLetter(which))}</h5>
                <div className="fl dbl mr mt5" title="activity">
                    <Switch
                        onClick={() => {
                            const general: IGeneral = Object.assign({}, Main.Instance.GeneralState.general)
                            if (Main.Instance.GeneralState.general.globalObjectiveConfiguration[which]) {
                                general.globalObjectiveConfiguration[which].active = !general.globalObjectiveConfiguration[which].active
                                Logic.Type.New(Main.Instance.GeneralState, { general })
                            }

                        }
                        }
                        className={Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] ? Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].active ? '' : 'off-background-grey' : ''}
                        enabled={Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] ? true : false}
                        on={Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] && Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].active}
                    />
                </div>
            </div>
            {Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] && Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].active ?
                getMinByCurrency(which) : void 0}
        </div>
    )
}


function getMinByCurrency(which: string): JSX.Element[] {
    const min: JSX.Element[] = []

    if (Main.Instance.DatabaseDataState.currenciesStream) {
        for (let i: number = 0; i < Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts.length; i++) {
            let code: string
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
                if (Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i]['code'] === Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                    code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                    break
                }
            }
            if (code === undefined) { continue }
            min.push(
                <div key={i.toString()} className="row admin-users-row clearfix" style={{ position: 'relative' }}>
                    <button
                        className={Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] && Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i] &&
                            Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i].minValue !== undefined ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                        style={{ float: 'left', width: '50px', marginRight: '10px', height: '29px', marginBottom: '6px', fontSize: '16px', fontWeight: 700, display: 'block' }}
                        onClick={() => {
                            const general = Object.assign({}, Main.Instance.GeneralState.general)
                            if (general.globalObjectiveConfiguration[which].minAmounts[i] === undefined || general.globalObjectiveConfiguration[which].minAmounts[i].minValue === undefined) {
                                general.globalObjectiveConfiguration[which].minAmounts[i] = { code, minBet: '0' }
                            } else { general.globalObjectiveConfiguration[which].minAmounts[i] = { code } }
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }}
                    >
                        {code}
                    </button>
                    {Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] && Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i] &&
                        Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i].minValue !== undefined ?
                        <div className="fl clearfix" style={{ width: '50%' }}>
                            <FormInput
                                narrow={true}
                                description={Assets.MinimumBetSizeAmount}
                                ClassName="form-control register-input"
                                value={Main.Instance.GeneralState.general.globalObjectiveConfiguration[which] === undefined || Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i] === undefined ||
                                    Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i].minValue === undefined ? '0' : Main.Instance.GeneralState.general.globalObjectiveConfiguration[which].minAmounts[i].minValue}
                                type="text"
                                iconText={Assets.MinBetSize}
                                iconTextSize="14px"
                                onChange={(text) => SetMinimumBet(i, text, which)}
                            />
                        </div>
                        : void 0}
                </div>,
            )
        }
    }
    return min
}

function SetMinimumBet(i: number, text: string, which: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const general = Object.assign({}, Main.Instance.GeneralState.general)
        if (text.length > 1 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice(1, text.length)
        }
        general.globalObjectiveConfiguration[which].minAmounts[i].minValue = text
        Logic.Type.New(Main.Instance.GeneralState, { general })
    }
}


function SetMinBet(i: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const general = Object.assign({}, Main.Instance.GeneralState.general)
        general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].minBet = text
        Logic.Type.New(Main.Instance.GeneralState, { general })
    }
}

function SetMaxBet(i: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const general = Object.assign({}, Main.Instance.GeneralState.general)
        general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].maxBet = text
        Logic.Type.New(Main.Instance.GeneralState, { general })
    }
}

function getMinMaxByCurrency(): JSX.Element[] {
    const minmax: JSX.Element[] = []

    // console.log (Main.Instance.DatabaseDataState.currenciesStream)
    // console.log (Main.Instance.GeneralState.general.playerCategories.categories)

    if (Main.Instance.DatabaseDataState.currenciesStream &&
        Main.Instance.GeneralState.general &&
        Main.Instance.GeneralState.general.playerCategories &&
        Main.Instance.GeneralState.general.playerCategories.categories &&
        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category] &&
        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets) {
        for (let i: number = 0; i < Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets.length; i++) {
            let code: string
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
                if (Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i]['code'] ===
                    Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                    code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                    break
                }
            }
            if (code === undefined) { continue }
            minmax.push(
                <div key={i.toString()} className="row admin-users-row clearfix" style={{ position: 'relative' }}>
                    <button
                        className={Main.Instance.GeneralState.general.playerCategories && Main.Instance.GeneralState.general.playerCategories.categories
                            && Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets &&
                            Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] &&
                            Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].minBet !== undefined
                            ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                        style={{ float: 'left', width: '50px', marginRight: '10px', height: '29px', marginBottom: '6px', fontSize: '16px', fontWeight: 700, display: 'block' }}
                        onClick={() => {
                            const general = Object.assign({}, Main.Instance.GeneralState.general)

                            if (general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets === undefined) {
                                general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets = []
                            }

                            if (general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i]['minBet'] === undefined) {
                                general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] = { code, minBet: '0', maxBet: '0' }
                            } else { general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] = { code } }

                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }}
                    >
                        {code}
                    </button>
                    {Main.Instance.GeneralState.general.playerCategories && Main.Instance.GeneralState.general.playerCategories.categories
                        && Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].lowerInclusive !== undefined
                        && Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets &&
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] &&
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].minBet !== undefined ?
                        <div className="fl clearfix" style={{ width: '220px' }}>
                            <FormInput
                                narrow={true}
                                description={Assets.MinimumBetSizeAmount}
                                ClassName="form-control register-input"
                                value={Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] === undefined ||
                                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] === undefined ||
                                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].minBet === undefined ?
                                    '0' : Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].minBet}
                                type="text"
                                iconText={Assets.MinBetSize}
                                iconTextSize="14px"
                                onChange={(text) => SetMinBet(i, text)}
                            />
                        </div>
                        : void 0}

                    {Main.Instance.GeneralState.general.playerCategories && Main.Instance.GeneralState.general.playerCategories.categories
                        && Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].upperInclusive !== undefined
                        && Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets &&
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] &&
                        Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].maxBet !== undefined ?
                        <div className="fl clearfix" style={{ width: '220px' }}>
                            <FormInput
                                narrow={true}
                                description={Assets.MaximumBetSizeAmount}
                                ClassName="form-control register-input"
                                value={Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] === undefined ||
                                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i] === undefined ||
                                    Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].maxBet === undefined ?
                                    '0' : Main.Instance.GeneralState.general.playerCategories.categories[Main.Instance.GeneralState.category].minMaxBets[i].maxBet}
                                type="text"
                                iconText={Assets.MaxBetSize}
                                iconTextSize="14px"
                                onChange={(text) => SetMaxBet(i, text)}
                            />
                        </div>
                        : void 0}
                </div>,
            )
        }
    }
    return minmax
}

function getContributionsByCurrency(): JSX.Element[] {
    const min: JSX.Element[] = []

    if (Main.Instance.DatabaseDataState.currenciesStream &&
        Main.Instance.GeneralState.general &&
        Main.Instance.GeneralState.general.currencyContributions &&
        Main.Instance.GeneralState.general.currencyContributions.contributions) {
        for (let i: number = 0; i < Main.Instance.GeneralState.general.currencyContributions.contributions.length; i++) {
            let code: string
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
                if (Main.Instance.GeneralState.general.currencyContributions.contributions[i]['code'] === Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                    code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                    break
                }
            }
            if (code === undefined) { continue }
            min.push(
                <div key={i.toString()} className="row admin-users-row clearfix" style={{ position: 'relative' }}>
                    <button
                        className={Main.Instance.GeneralState.general.currencyContributions && Main.Instance.GeneralState.general.currencyContributions.contributions[i] &&
                            Main.Instance.GeneralState.general.currencyContributions.contributions[i].value !== undefined ? 'btn btn-success btn-xs' : 'btn btn-danger btn-xs'}
                        style={{ float: 'left', width: '50px', marginRight: '10px', height: '29px', marginBottom: '6px', fontSize: '16px', fontWeight: 700, display: 'block' }}
                        onClick={() => {
                            const general = Object.assign({}, Main.Instance.GeneralState.general)
                            if (general.currencyContributions.contributions[i] === undefined || general.currencyContributions.contributions[i].value === undefined) {
                                general.currencyContributions.contributions[i] = { code, value: '0' }
                            } else {
                                general.currencyContributions.contributions[i] = { code }
                            }
                            Logic.Type.New(Main.Instance.GeneralState, { general })
                        }}
                    >
                        {code}
                    </button>
                    {Main.Instance.GeneralState.general.currencyContributions && Main.Instance.GeneralState.general.currencyContributions.contributions[i] &&
                        Main.Instance.GeneralState.general.currencyContributions.contributions[i].value !== undefined ?
                        <div className="fl clearfix" style={{ width: '50%' }}>
                            <FormInput
                                narrow={true}
                                description={Assets.Contribution + ' ' + Assets.Amount.toLowerCase()}
                                ClassName="form-control register-input"
                                value={Main.Instance.GeneralState.general.currencyContributions === undefined || Main.Instance.GeneralState.general.currencyContributions.contributions[i] === undefined ||
                                    Main.Instance.GeneralState.general.currencyContributions.contributions[i].value === undefined ? '0' : Main.Instance.GeneralState.general.currencyContributions.contributions[i].value}
                                type="text"
                                iconText={Assets.Contribution}
                                iconTextSize="14px"
                                onChange={(text) => SetCurrencyContribution(i, text)}
                            />
                        </div>
                        : void 0}
                </div>,
            )
        }
    }
    return min
}

function SetCurrencyContribution(i: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const general = Object.assign({}, Main.Instance.GeneralState.general)
        if (text.length > 1 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice(1, text.length)
        }
        general.currencyContributions.contributions[i].value = text
        Logic.Type.New(Main.Instance.GeneralState, { general })
    }
}