import * as React from 'react'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import { Main } from '../../../Logic/Main'
import {getFlag, getNationality} from './CashbackMondayOptin'
import { Logic } from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {getLevelOptions} from './CashbackMondayOptin'
import {RouterObjects} from '../../../Logic/RouterObjects'
import * as Assets_ from '../../Assets_'
import {FormInputNumber} from '../../Reusables/Inputs'



export function findUser() {
    // console.log('IN')
    const data = Object.assign([], Main.Instance.DatabaseDataState.usersInfoFiltered)
    let selectedPosition: number = -1
 
    let found: boolean = false
    if (Main.Instance.UsersInfoState.search_string && Main.Instance.UsersInfoState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
        for (let i: number = 0; i < data.length; i++) {
            if (Main.Instance.UsersInfoState.username) {
                const position: number = data[i].username.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        found = true
                        selectedPosition = i
                        break
                    }
                } else {
                    if (position !== -1) {
                        found = true
                        selectedPosition = i
                        break
                    }
                }
            }

            if (!found && Main.Instance.UsersInfoState.playerId) {
                const position: number = data[i].playerId.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (position === 0) {
                        found = true
                        selectedPosition = i
                        break
                    }
                } else {
                    if (position !== -1) {
                        found = true
                        selectedPosition = i
                        break
                    }
                }
            }

            
        }
    }
    Logic.Type.New(Main.Instance.UsersInfoState, {selectedPosition, selectedPage: Math.trunc(selectedPosition / Main.Instance.UsersInfoState.pSize)})

}

export function getFilteredData() {
    const data = Object.assign([], Main.Instance.DatabaseDataState.usersInfoFiltered)
    const FilteredData = []
    
    for (let i: number = 0; i < data.length; i++) {
        let found: boolean = false
        
        if (Main.Instance.UsersInfoState.search_string && Main.Instance.UsersInfoState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
            const positionUsername: number = data[i].username.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
            const positionId: number = data[i].playerId.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
            if (Main.Instance.UsersInfoState.username && Main.Instance.UsersInfoState.playerId) {
                
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionUsername === 0 || positionId === 0) {
                        found = true

                    } 
                } else {
                    if (positionUsername !== -1 || positionId !== -1) {
                        found = true

                    }
                }
            } else if (Main.Instance.UsersInfoState.username) {
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionUsername === 0) {
                        found = true

                    } 
                } else {
                    if (positionUsername !== -1) {
                        found = true

                    }
                }
            } else if (Main.Instance.UsersInfoState.playerId) {
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionId === 0) {
                        found = true

                    } 
                } else {
                    if (positionId !== -1) {
                        found = true

                    }
                }
            }
  
        } else {
            found = true

        }
        if (found) {
            FilteredData.push(data[i])
        }
    }

    Logic.Type.New(Main.Instance.UsersInfoState, {filteredData: FilteredData.slice((Main.Instance.UsersInfoState.pNum - 1) * Main.Instance.UsersInfoState.pSize, (Main.Instance.UsersInfoState.pNum - 1) * Main.Instance.UsersInfoState.pSize + Main.Instance.UsersInfoState.pSize)})
    Logic.Type.New(Main.Instance.DatabaseDataState, {usersInfoCount: FilteredData.length})
}



function getPaginatedData(pNum?: number, pSize?: number) { 
    let filters
    if (Main.Instance.UsersInfoState.maxLevel) {
        filters = {
            stats: Main.Instance.UsersInfoState.showStats,
            minLevel: Main.Instance.UsersInfoState.minLevel,
            maxLevel: Main.Instance.UsersInfoState.maxLevel,
        }
    } else {
        filters = {
            stats: Main.Instance.UsersInfoState.showStats,
            minLevel: Main.Instance.UsersInfoState.minLevel,
        }
    }
    DbRoutes.getUsersInfoFiltered(/*pSize ? pSize : Main.Instance.UsersInfoState.pSize, pNum ? pNum : Main.Instance.UsersInfoState.pNum, */undefined, undefined, filters)
      
}

export function FirstPage() {
    Logic.Type.New(Main.Instance.UsersInfoState, {pNum: 1})
    // getPaginatedData(1)
    getFilteredData()
}

export function PrevPage() {
    if (Main.Instance.UsersInfoState.pNum > 1) {
        const page = Main.Instance.UsersInfoState.pNum - 1
        // getPaginatedData(page)
        
        Logic.Type.New(Main.Instance.UsersInfoState, {pNum: page})  
        getFilteredData()
    } else {
        LastPage()
    }
    
}

export function NextPage() {

    if ((Main.Instance.UsersInfoState.pNum) * Main.Instance.UsersInfoState.pSize < Main.Instance.DatabaseDataState.usersInfoCount) {
        const page = Main.Instance.UsersInfoState.pNum + 1
        // getPaginatedData(page)
        Logic.Type.New(Main.Instance.UsersInfoState, {pNum: page})
        getFilteredData()
    } else {
        FirstPage()
    }   
}

export function LastPage() {
    if (Main.Instance.DatabaseDataState.usersInfoCount <= Main.Instance.UsersInfoState.pSize) {
        FirstPage()
    } else {
        const pNum = Math.trunc((Main.Instance.DatabaseDataState.usersInfoCount - 1) / Main.Instance.UsersInfoState.pSize) + 1
        Logic.Type.New(Main.Instance.UsersInfoState, {pNum})
        getFilteredData()
        // getPaginatedData(pNum)
    }
}

export function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.UsersInfoState, {pSize: parseInt(text), pNum: 1})
        // getPaginatedData(1, parseInt(text))
        getFilteredData()
    }
}




export function UsersInformation(): JSX.Element {

    if (!Main.Instance.UsersInfoState.filteredData) {
        getFilteredData()
    }
    return (
        <div>
            <div style = {{marginBottom: '20px'}}>
                <h3>
                {Assets.User + ' ' + Assets.Information}
                <select
                    style = {{width: 'auto', height: '32px'}}
                    className = "form-control dbl fr mr0"
                    onChange = {(e) => {
                        Logic.Type.New(Main.Instance.UsersInfoState, {maxLevel: +e.target.value})
                        getPaginatedData()
                    }}
                    value = {Main.Instance.UsersInfoState.maxLevel ? Main.Instance.UsersInfoState.maxLevel.toString() : ''}
                >
                    {getLevelOptions()}
                </select>
                <span className = "dbl fr mr" style = {{fontSize: '14px', marginTop: '10px'}}>{Assets.MaxLevel}:</span>
                <select
                    style = {{width: 'auto', height: '32px'}}
                    className = "form-control dbl fr mr"
                    onChange = {(e) => {
                        Logic.Type.New(Main.Instance.UsersInfoState, {minLevel: +e.target.value})
                        getPaginatedData()
                    }}
                    value = {Main.Instance.UsersInfoState.minLevel ? Main.Instance.UsersInfoState.minLevel.toString() : ''}
                >
                    {getLevelOptions()}
                </select>
                <span className = "dbl fr mr" style = {{fontSize: '14px', marginTop: '10px'}}>{Assets.MinLevel}:</span>
                <button 
                    className = {Main.Instance.UsersInfoState.showStats ? 'btn btn-primary dbl fr mr' : 'btn btn-default dbl fr mr'}
                    onClick = {() => {
                        // getPaginatedData(undefined, undefined, {stats: !Main.Instance.UsersInfoState.showStats})
                        Logic.Type.New(Main.Instance.UsersInfoState, {showStats: !Main.Instance.UsersInfoState.showStats})
                        getPaginatedData()
                        
                    }}
                >
                    {Assets.Show + ' ' + Assets.Status}
                </button>
                </h3>
            </div>
            {<div className = "control-panel">
                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.UsersInfoState.pSize ? '' : Main.Instance.UsersInfoState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => {
                        RecordsPerPageChanged(text)
                    }}
                />

                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>
    
                <span className = "label label-success table-label">
                    {Assets.records}
                    {Main.Instance.DatabaseDataState.usersInfoCount ? Main.Instance.DatabaseDataState.usersInfoCount : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.showing}
                    {Main.Instance.DatabaseDataState.usersInfoCount ? (Main.Instance.UsersInfoState.pNum - 1) * Main.Instance.UsersInfoState.pSize + 1 : 0}
                    {' - '}
                    {Main.Instance.DatabaseDataState.usersInfoCount ? ((Main.Instance.UsersInfoState.pNum) * Main.Instance.UsersInfoState.pSize > Main.Instance.DatabaseDataState.usersInfoCount ? 
                    Main.Instance.DatabaseDataState.usersInfoCount : (Main.Instance.UsersInfoState.pNum) * Main.Instance.UsersInfoState.pSize) : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.page}
                    {Main.Instance.DatabaseDataState.usersInfoCount ? Main.Instance.UsersInfoState.pNum : 0}
                    {' / '}
                    {Main.Instance.DatabaseDataState.usersInfoCount ? Math.trunc((Main.Instance.DatabaseDataState.usersInfoCount - 1) / Main.Instance.UsersInfoState.pSize + 1) : 0}
                </span>
            </div>}
            <br/>
            <div>
                <ReactTable
                    data = {Main.Instance.UsersInfoState.filteredData}
                    onPageSizeChange={(pageSize, pageIndex) => {
                        if (pageSize !== Main.Instance.UsersInfoState.pSize) {
                            Logic.Type.New(Main.Instance.UsersInfoState, {pSize: pageSize})
                        }
                    }}
                    columns = {[
                        {className: 'btn-nopadding', width: 80, Header: Assets.PlayerID, accessor: 'playerId', headerClassName: 'th-left risk-default-light', Cell: (row) => 
                        <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>
                            &nbsp;&nbsp;
                            <button
                                className = "btn btn-default btn-xs"
                                onClick = {() => {
                                    const path: string = RouterObjects['reports'].path + RouterObjects['reports'].children['User_Info'].path
                                    Logic.Type.New(Main.Instance, {Location: path})
                                    DbRoutes.getUserInfo(row.value) 
                                }}
                            >
                                {row.value}
                            </button>
                        </div>},
                        {className: 'btn-nopadding', Header: Assets.Username, accessor: 'username', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div> },
                        {className: 'btn-nopadding', width: 80, Header: Assets.Currency, accessor: 'currency', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', width: 130, Header: Assets.Nationality, accessor: 'nationality', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{getNationality(row.value)}</div>},
                        {className: 'btn-nopadding', width: 60, Header: Assets.Flag, accessor: 'nationality', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{getFlag(row.value)}</div>},
                        {className: 'btn-nopadding', width: 60, Header: Assets.Level, accessor: 'level', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div> },
                        {show: false, className: 'btn-nopadding', width: 150, Header: Assets.SubLevel, accessor: 'sublevel', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div> },
                        {className: 'btn-nopadding', width: 130, Header: Assets.Date + ' ' + Assets.Last.toLowerCase() + ' ' + Assets.Played.toLowerCase(), accessor: 'lastPlayed', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value.toString().substring(0,10)}</div> },
                        {className: 'btn-nopadding', width: 130, Header: Assets.Time + ' ' + Assets.Last.toLowerCase() + ' ' + Assets.Played.toLowerCase(), accessor: 'lastPlayed', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value.toString().substring(11,19)}</div> },
                        {className: 'btn-nopadding', show: Main.Instance.UsersInfoState.showStats, width: 120, Header: Assets.SpinsAmount, accessor: 'stats.spins', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{Math.round(+row.value * 100) / 100}</div> },
                        {className: 'btn-nopadding', show: Main.Instance.UsersInfoState.showStats, width: 90, Header: Assets.Bets, accessor: 'stats.betsCount', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div> },
                        {className: 'btn-nopadding', show: Main.Instance.UsersInfoState.showStats, width: 90, Header: Assets.TotalBet, accessor: 'stats.totalBet', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{Math.round(+row.value * 100) / 100}</div> },
                        {className: 'btn-nopadding', show: Main.Instance.UsersInfoState.showStats, width: 90, Header: Assets.Wins, accessor: 'stats.winsCount', headerClassName: 'th-left risk-default', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{row.value}</div> },
                        {className: 'btn-nopadding', show: Main.Instance.UsersInfoState.showStats, width: 90, Header: Assets.TotalWin, accessor: 'stats.totalWon', headerClassName: 'th-left risk-default-light', Cell: (row) => <div style = {{background: row.index === Main.Instance.UsersInfoState.selectedPosition ? '#C0D9AF' : ''}}>&nbsp;&nbsp;{Math.round(+row.value * 100) / 100}</div> },
                    ]}
                    showPaginationBottom = {false}
                    // showPaginationTop = {true}
                    pageSize = {Main.Instance.UsersInfoState.pSize}
                    // defaultPageSize = {15}
                    sortable={false}
                    page={Main.Instance.UsersInfoState.selectedPage}
                    onPageChange={(pageIndex) => { 
                        Logic.Type.New(Main.Instance.UsersInfoState, {selectedPage: pageIndex})
                    }}
                    // pageSizeOptions = {[10, 15, 20, 50, 100]}
                />
            </div>
        </div>
    )
}