import {Logic} from '../Type'
import * as Assets_ from '../../Views/Assets_'
// import {RiskLevel/*, DashboardRuleType*/} from '../RiskComplianceStore/RiskManagement'
import {Option, FrontendShopItem} from '../../Logic/Database/DatabaseData'

export class FrontendStoreState extends Logic.Type {
    // contentfulWidget: any
    // contentfulSettings: any
    public strapiPass: string
    // public keyGet: string
    public christmas: string = ''
    public blackFriday: string = ''
    public activeBlackFriday: boolean = false
    public activeChristmas: boolean = false
    public BlackFriday: boolean = false
    public Christmas: boolean = false
    public api_key: string
    public jwt: string
    public skin_id: number
    public items: number = 0 
    public page: number = 0
    public pages: number = -1
    public categories: boolean = true
    public images: string[]
    public authAleaccUsername: string
    public authAleaccPassword: string
    public modeFont: boolean = false
    public modeGames: boolean = false
    public modeImages: boolean = true
    public modeGeneral: boolean = false
    public modePromotions: boolean = false
    public modeMariasCorner: boolean = false
    public modeBanner: boolean = false
    public modeCasinoGames: boolean = false
    public modeTranslation: boolean = false
    public modeLottery: boolean = false
    public modePromotions2: boolean = false
    public modeLiveCasino: boolean = false
    public modeMoonShopping: boolean = false
    public modeRewardShop: boolean = true
    public modeMinLevel: boolean = false
    public modeNotify: boolean = false
    // modeWidget: boolean = false
    public banner: number = -1
    public bannerItem: number = 0
    public promotion: number = -1
    public promotionItem: number = 0
    public font: number = -1
    public maria: number = -1
    public mariaItem: number = 0
    // fontItem: number = 0
    public modeSportsBetting: boolean = true
    public translation_: string
    public translation_default: string
    public translation_id: string
    public Translation: any[]
    public Translation_default: any[]
    public custom: any
    public translation: string[]
    public menu_type: Option[]
    public menuOpen:number = 0
    public groupOpen:number = 0
    public rewardShopOpen:number = 0    
    public domain_id:string
    public subdomain: string = ''
    public badge:string[]
    public group:string[] // it tells in which group is the game
    public group_filter: Option[]
    public group_filter_id:string = '0'
    public index:string[]
    public size:boolean[]
    public active:boolean[]
    public instance:number[]
    public instances:number[]
    public domain:string = ''
    public title:string = ''
    public menus:string[]
    public menus_type:string[]
    public logo:string
    public logo2:string
    public minlevel: number[]
    public minlevel_id: number[]
    public games_folder: string
    public mail: string[]
    public notify: number[]
    public category: string[]
    public Category: any[]
    public Category2: any[]
    public Category3: any[]
    public Category4: any[]
    public Category5: any[]
    public rs_background: string
    public rs_popup_bg: string

    public shop_items: FrontendShopItem[]
    public shopItemDefaultFilename:string
    public shopItemDefaultUploadedFilename:string = 'shop.jpg'

    public titleSize:number
    public titleColor:string
    public coverWhole:boolean
    public shopItemHeight:number
    public shopItemWidth:number
    public borderWidth:number
    public borderRadius:number
    public shopItemImageHeight: number
    public groups:string[]
    public group_size:number[]
    public group_size_selected0:number
    public take_into_account:boolean[]
    public show_version_info:boolean = true
    public show_game_info:boolean = true
    public show_debug_info:boolean = true
    public display_name:boolean = true
    public borders:boolean = true
    public holding_id: string = '0'
    public first_time = true
    public first_time2 = true
    public gamesPaths = []
    public countCalled: boolean = false
    public countPlayersCalled: boolean = false
    public operator_id: string = '0'
    public site_id: string = '0'
    public gamesArray: JSX.Element[] = []
    public gamesSelected:number = 0
    public gamesAll:number = 0
    public pNum :number = 0
    public pSize :number = 10
    public count :number = 0
    public mode :boolean = false
    public id :string = '0'
    public offset:string = '0'
    public Id :string = '0'
    public sublevel_min: number = 0
    public sublevel_max: number = 0
    public sublevels:number[] = []
    public Sublevels: number = 0
    public username :string = ''
    public password :string = ''
    public email:string = ''
    public showBalance = true
    public showXP = true
    public showXPL = true
    public filter_level:string = '0'
    public balance:string = ''
    public xp:string = ''
    public xpl:string = ''
    public xp_saved:string
    public xpl_saved:string
    public level:string = ''
    public level_description:string = ''
    public username_saved :string 
    public name:string 
    public name_saved:string 
    public email_saved:string 
    public sort:number[] = []
    public Sort:string[] = []
    public menu_active: string[]
    public menu_inactive: string[]
}

export class FrontendGamesState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    public count: number = 0
    public Count: number = 0
    public selected: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id: string
    public native:string
    public name: string = ''
    public name_saved: string

    public background: string = Assets_.zeroPng
    public background_saved: string
    public thumbnail: string = Assets_.zeroPng
    public thumbnail_saved: string

    public link: string = ''
    public link_saved: string

    public banner: string = '0'
    public banner_saved: string

    public version: string = ''
    public version_saved: string

    public group: number = 0
    public group_saved: number

    public idx: number = 0
    public idx_saved: number

    public provider_id: number = 0
    public provider_id_saved: number

    public lines: number = 0
    public lines_saved: number

    public reels: number = 0
    public reels_saved: number

    public payout: string = ''
    public payout_saved: string

    public gameid: string = ''
    public gameid_saved: string

    public provider: string = ''
    public provider_saved: string

    public assets_provider: string = ''
    public assets_provider_saved: string    

    public winlines: string
    public winlines_saved: string
    
    public symbols: number = 0
    public symbols_saved: number

    public mystery: boolean = false
    public mystery_saved: boolean

    public scatter: boolean = false
    public scatter_saved: boolean

    public wild: boolean = false
    public wild_saved: boolean

    public free_spins: boolean = false
    public free_spins_saved: boolean

    public info: string = ''
    public info_saved: string

    public show_info: boolean = false
    
    public active: string = '1'
    public active_saved: string
    public filter_active: string = '0'
    public upload_game_files: boolean = false
    public files: File[] = []
    public progressFiles: number = 0
    public filter_provider: string = '0'
    public filter_freespins: string = '0'
    public filter_flexible: string = '0'
    public filter_letter: boolean[]
    public filterLetter: string[]
    public providers: number[] = []
}
