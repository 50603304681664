import * as React from 'react'
import {SmallSwitzerland} from './DatamapSwitzerland'
import {Example} from './Example'
import * as d3geo from 'd3-geo'
import {PlayerVisualize, PlayersCantonal} from '../MainDashboard/Dashboard'
import {getColorByCanton} from '../MainDashboard/Legend'
import {SelectedView} from '../../../Logic/Various/Dashboard'
import {bubbleRadius, switzerlandColor, switzerlandCantonsBorderColor} from './SwitzerlandResources'
import {Main} from '../../../Logic/Main'

export const mainRafflePrizeRadius: number = 8
export const secondaryRafflePrizeRadius: number = 3
export const defaultRafflePrizeRadius: number = 1

export default class ScaleSwitzerland extends React.Component<{players: PlayerVisualize[], cantonal: PlayersCantonal, group?: SelectedView, index: number},{}> {
    public setProjection(element) {
        const projection = d3geo.geoMercator()  // .geoEquirectangular()
            .center([8.28, 46.82]) // 12.6, 46.79
            .rotate([0, 0]) // [4.4, 0]
            .scale(4000) // 15000
            .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
        const path = d3geo.geoPath()
            .projection(projection);

        return { path, projection }
    }
    public getFillKey(canton: number): string {
        // if (this.props.cantonal) console.log (canton, this.props.cantonal.cantons[canton])
        return this.props.cantonal && this.props.cantonal.cantons ? getColorByCanton(this.props.cantonal.cantons[canton]) :
        /*this.props.cantonal && this.props.cantonal.cantonsHighlight ? getHighlightByCanton(this.props.cantonal.cantonsHighlight[canton]) :*/ switzerlandColor
    }
    public render() {
        let BubbleRadius: number
        // console.log ('*** START ***')
        // console.log (this.props.group)
        // console.log (this.props.index)
        if (this.props.group !== undefined && this.props.index !== undefined && Main.Instance.DashboardState.click[this.props.group][this.props.index] > -1) {
            // console.log ('IN ->' + Main.Instance.DashboardState.click[this.props.group][this.props.index])
            BubbleRadius = bubbleRadius[Main.Instance.DashboardState.click[this.props.group][this.props.index]]
        }
        // console.log ('*** END ***')
        const Bubbles = []
        if (this.props.players) {
            for (const item of this.props.players) {
                Bubbles.push({
                    name: item.firstName && item.lastName && item.win ? item.firstName + ' ' + item.lastName + ' (' + item.city + ') ' + item.win : item.id + ' (' + item.plz + ' ' + item.city + ')',
                    radius: item.rafflePrimary ? mainRafflePrizeRadius : BubbleRadius !== undefined ? BubbleRadius : item.firstName && item.lastName && item.win ? secondaryRafflePrizeRadius : defaultRafflePrizeRadius,
                    latitude: item.locationN/1000,
                    longitude: item.locationE/1000,
                    fillKey: /*item.rafflePrimary ? 'TEXT' :*/ item.firstName && item.lastName && item.win ? 'LIME' : 'TEXT',
                })
            }
        }
        return (
            <Example label = "Switzerland">
                <SmallSwitzerland
                    scope = "che" // cantons
                    setProjection = {this.setProjection}
                    geographyConfig= { {
                        // data: Data,
                        // dataUrl: './switzerland.json',
                        // dataJson: Data,
                        popupOnHover: false,
                        hideAntarctica: true,
                        highlightOnHover: false,
                        borderColor: /*this.props.cantonal ? switzerlandColor :*/ switzerlandCantonsBorderColor,
                        borderWidth: 1,
                        // highlightFillColor: () => switzerlandColor,
                        // highlightFillColor: Main.Instance.DatabaseDataState.selected,
                        // this function should just return a string
/*
                        popupTemplate: (geography, data) => {
                            let area, population: number
                            // if (!Main.Instance.RiskManagementState.showMap)
                            for (const item of SwitzerlandCantonsData) {
                                if (geography.id === item.code) {
                                    area = item.area
                                    population = item.population
                                    break
                                }
                            }
                            return (
                                `<div class = "geography-properties-box">
                                    <div class = "geography-properties-title">` + geography.properties.name + `</div>
                                    <div> Population: ` + (population ? numberWithCommas(population) : 0) + `</div>
                                    <div> Area: ` + (area ? numberWithCommas(area) : 0) + ` km<sup>2</sup></div>
                                    <div> Density: ` + (population && area ? numberWithCommas((population/area).toFixed(1)) : 'unknown') + `/km<sup>2</sup></div>
                                </div>`)
                        }
*/
                    }}

                    data = {this.props.cantonal ? {
                        'CH.GE': {fillKey: 'CH.GE'},
                        'CH.JU': {fillKey: 'CH.JU'},
                        'CH.NE': {fillKey: 'CH.NE'},
                        'CH.AG': {fillKey: 'CH.AG'},
                        'CH.LU': {fillKey: 'CH.LU'},
                        'CH.NW': {fillKey: 'CH.NW'},
                        'CH.VS': {fillKey: 'CH.VS'},
                        'CH.AR': {fillKey: 'CH.AR'},
                        'CH.SG': {fillKey: 'CH.SG'},
                        'CH.TI': {fillKey: 'CH.TI'},
                        'CH.GL': {fillKey: 'CH.GL'},
                        'CH.GR': {fillKey: 'CH.GR'},
                        'CH.SH': {fillKey: 'CH.SH'},
                        'CH.SZ': {fillKey: 'CH.SZ'},
                        'CH.TG': {fillKey: 'CH.TG'},
                        'CH.UR': {fillKey: 'CH.UR'},
                        'CH.ZH': {fillKey: 'CH.ZH'},
                        'CH.ZG': {fillKey: 'CH.ZG'},
                        'CH.FR': {fillKey: 'CH.FR'},
                        'CH.VD': {fillKey: 'CH.VD'},
                        'CH.BL': {fillKey: 'CH.BL'},
                        'CH.BE': {fillKey: 'CH.BE'},
                        'CH.BS': {fillKey: 'CH.BS'},
                        'CH.SO': {fillKey: 'CH.SO'},
                        'CH.OW': {fillKey: 'CH.OW'},
                        'CH.AI': {fillKey: 'CH.AI'},  
                    } : {}}

                    fills = {this.props.cantonal ?
                        {
                        defaultFill: switzerlandColor,
                        'CH.GE': this.getFillKey(0),
                        'CH.JU': this.getFillKey(1),
                        'CH.NE': this.getFillKey(2),
                        'CH.AG': this.getFillKey(3),
                        'CH.LU': this.getFillKey(4),
                        'CH.NW': this.getFillKey(5),
                        'CH.VS': this.getFillKey(6),
                        'CH.SG': this.getFillKey(7),
                        'CH.TI': this.getFillKey(8),
                        'CH.GL': this.getFillKey(9),
                        'CH.GR': this.getFillKey(10),
                        'CH.SH': this.getFillKey(11),
                        'CH.SZ': this.getFillKey(12),
                        'CH.TG': this.getFillKey(13),
                        'CH.UR': this.getFillKey(14),
                        'CH.ZH': this.getFillKey(15),
                        'CH.ZG': this.getFillKey(16),
                        'CH.FR': this.getFillKey(17),
                        'CH.VD': this.getFillKey(18),
                        'CH.BL': this.getFillKey(19),
                        'CH.BE': this.getFillKey(20),
                        'CH.BS': this.getFillKey(21),
                        'CH.SO': this.getFillKey(22),
                        'CH.OW': this.getFillKey(23),
                        'CH.AI': this.getFillKey(24),
                        'CH.AR': this.getFillKey(25),
                        'TEXT': '#000',
                        'LIME': '#5cb85c',    
                        }
                    :
                        {
                        defaultFill: switzerlandColor,
                        'LIME': '#5cb85c',
                        'TEXT': '#000',
                        }
                    }
                    bubbles = {Bubbles}
                    bubbleOptions={{borderWidth: 0}}
/*                    
                    popupTemplate: (geo, data) =>
                    `<div class="hoverinfo">Bubble for ${data.name}`
*/
                />
            </Example>
        )
    }
}