import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {SelectedBonusSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {injectSpaces} from '../../../Logic/Utils'
import { faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function BonusSettings() {
    DbRoutes.wsReinitialize()

    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.Bonus + ' ' + Assets.Settings}</h2>
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            <div>
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {Main.Instance.RiskManagementState.selectedBonusSetting === SelectedBonusSetting.GloballyExcludedTags ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {excludeTags()}
                            </div>
                        : void 0}
                    </div>
                </div>
            </div> 
        </div>
    )
}

function excludeTags(): JSX.Element[] {
    const tags: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.globallyExcludedTags) {
        tags.push (<button style = {{cursor: 'default', width: '180px', marginRight: '1.25%', fontWeight: 700, fontSize: '14px', marginBottom: '2px'}} key = "-5" onClick = {() => {}} className = "btn btn-default btn-dark btn-sm dbl fl">{Assets.Excluded + ' ' + Assets.Tags.toLowerCase()} [{Main.Instance.DatabaseDataState.globallyExcludedTags.length}]</button>)
    }


    if (Main.Instance.DatabaseDataState.globallyExcludedTags) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.globallyExcludedTags.length; i++) {
            tags.push(
                <div key={i} style = {{width: '180px', display: 'flex', marginBottom: '5px'}}>
                    <input
                        placeholder = {Assets.Tag}
                        title = {Assets.Tag}
                        value = {Main.Instance.DatabaseDataState.globallyExcludedTags[i] ? Main.Instance.DatabaseDataState.globallyExcludedTags[i] : ''} 
                        onChange = {(e) => updateExcludedTags(i, e.target.value)} 
                        className = "dibl mr0"
                    />

                    <div title = {Assets.Remove + Assets._a_ + Assets.Tag.toLowerCase()} style = {{zIndex: 2}} className = "fl pr" key = {i.toString() + '_'}>
                        <button 
                        className="btn btn-danger btn-xs br0 dibl"
                        onClick = {() => updateExcludedTags(i, undefined, true)} 
                        style={{ height: '26px'}}>
                            <FontAwesomeIcon icon = {faTrashAlt}/>
                        </button>
                    </div>
                </div>                    
            )
        }
    }
   
    tags.push (
        <button 
        title = {Assets.Exclude + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()} 
        key = "-1" 
        onClick = {() => updateExcludedTags(undefined)} 
        style = {{width: '180px'}}
        className = "btn btn-primary btn-sm mr mb5 dbl fl">
            {Assets.Exclude + Assets._a_ + Assets.NEW.toLowerCase() + ' ' + Assets.Tag.toLowerCase()}
        </button>
    )
    return tags
}

function updateExcludedTags(index: number, tag?: string, remove?: boolean) {
    let tags = Object.assign([], Main.Instance.DatabaseDataState.globallyExcludedTags)
    if (remove) {
        if (index === undefined) {
            tags = []
        } else {
            tags.splice(index, 1) 
        }
    } else {
        if (!tags) {
            tags = []
        } 
        if (index === undefined || tags[index] === undefined) {
            tags.push('')
        } else {
            tags[index] = tag ? tag : Main.Instance.DatabaseDataState.globallyExcludedTags[index]
        }
    }  
    Logic.Type.New(Main.Instance.DatabaseDataState, {globallyExcludedTags: tags})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(SelectedBonusSetting).length / 2; i++) {
        navigation.push(
            <button
                onClick = {() => Logic.Type.New(Main.Instance.RiskManagementState, {selectedBonusSetting: i})}
                type = "button"
                key = {i.toString()}
                style = {{
                    marginRight: i === Object.keys(SelectedBonusSetting).length / 2 - 1 ? '0px' : '',
                    display: 'block',
                    float: 'left',
                }}
                className = {i === Main.Instance.RiskManagementState.selectedBonusSetting ? 'btn btn-primary btn-sm pr' : 'btn btn-default btn-sm pr'}
            >
                {injectSpaces(SelectedBonusSetting[i])}
            </button>
        )
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.setGloballyExcludedTags()}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)
    
    return navigation
}