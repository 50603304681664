import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, AllowDecimalCharactersOnly, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner} from '../../../Logic/Utils'
import {SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getChallengesCount() {
    DbRoutes.WsGetChallenges()
}

function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.WsGetChallenges()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Challenge.toLowerCase() + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {
            if (Main.Instance.ChallengeState.pNum === (Math.trunc((Main.Instance.ChallengeState.count - 2) / Main.Instance.ChallengeState.pSize) + 1)) {
                DbRoutes.removeChallenge (row.original.id, DeleteLastPage)
            } else {
                DbRoutes.removeChallenge (row.original.id, DeleteNotLastPage)
            }
        }
    }
}

function updateName(name: string) {
    Logic.Type.New (Main.Instance.ChallengeState, {name})    
}

function handleEdit(row: any) {
    gotoTop()
    if (row) {
        const conditions: Assets_.IConditions = JSON.parse(JSON.stringify(row.original.challenge))
        let challengeType: string = '0'
        if (row.original['Challenge Type'] === 'Bets count') { challengeType = '1' } else if (row.original['Challenge Type'] === 'Wins count') { challengeType = '2' } else if (row.original['Challenge Type'] === 'Consecutive count') { challengeType = '3' } else if (row.original['Challenge Type'] === 'Big wins count') { challengeType = '4' }
        // conditions.minbet = conditions.minBet.toString()
        // console.log (conditions)
        Logic.Type.New(Main.Instance.ChallengeState, {
            mode: true,
            id: row.original.id,
            name: row.original.name,
            name_saved: row.original.name,
            date: moment.default(row.original.date.substring(0,10)),
            date_string: row.original.date.substring(0,10),
            date_string_saved: row.original.date.substring(0,10),
            challenge_type: challengeType,
            challenge_type_saved: challengeType,
            conditions,
        })
    }
}

function handleInsertUpdate() {

    const challengeItem = {
        id : !Main.Instance.ChallengeState.id ? '' : Main.Instance.ChallengeState.id,
        name: !Main.Instance.ChallengeState.name ? '' : Main.Instance.ChallengeState.name,
        date: Main.Instance.ChallengeState.date,
        type: Main.Instance.ChallengeState.challenge_type,
        conditions: Main.Instance.ChallengeState.conditions,
    }
/*
    if (challengeItem.conditions.minbet === undefined || challengeItem.conditions.minbet.length === 0) {
        alert('MinBet cannot be 0.')
        return
    }
*/

    if (challengeItem.conditions === undefined) {
        alert(Assets.ChallengeRewardNeedsToBeSet)
        return
    }
/*
    if (isNaN(challengeItem.conditions.minbet as any)) {
        alert ('Minimal bet is not formatted correctly')
        return
    }

    challengeItem.conditions.minBet = parseFloat(challengeItem.conditions.minbet)
    delete(challengeItem.conditions.minbet)
*/
    if (challengeItem.conditions.currencies) {
    for (const item of challengeItem.conditions.currencies) { if (item.minBet.length === 0 || +item.minBet === 0) {
        alert(Assets.MinBetCannotBe0)
        return
    }
    }
    }


    // console.log (challengeItem)

    if (Main.Instance.ChallengeState.name.length === 0) {
        alert (Assets.compulsoryName)
    } else if (Main.Instance.ChallengeState.challenge_type === undefined || Main.Instance.ChallengeState.challenge_type.length === 0 || 
                parseInt(Main.Instance.ChallengeState.challenge_type) === 0) {
        alert (Assets.ChallengeTypeNeedsToBeSeleected)
    } else if (Main.Instance.ChallengeState.date === null || Main.Instance.ChallengeState.date === undefined) {
        alert(Assets.ChallengeDateNeedsToBeSet)
    } else if (Main.Instance.ChallengeState.conditions === undefined && Main.Instance.ChallengeState.conditions.rewardId === undefined
               || Main.Instance.ChallengeState.conditions.rewardId === 0) {
        alert(Assets.ChallengeRewardNeedsToBeSet)

/*
    } else if (!validationFromTime) {
        alert (Assets.compulsoryCorrectTime)
    } else if (!validationToTime) {
        alert (Assets.compulsoryCorrectTime)
 */
    } else if (Main.Instance.ChallengeState.mode === false) {
        DbRoutes.WSinsertChallenge(challengeItem)
    } else {
        DbRoutes.WSupdateChallenge(challengeItem)
    }
}

function getChallenges() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {challengesStream : undefined})
    getChallengesCount()
}

function  clearFields() {
    Logic.Type.New(Main.Instance.ChallengeState, {
        name : '',
        mode : false,
        id: '0',
        date: null,
        challenge_type: '0',
        conditions: {},
    })
}

function gotoInsertMode() {
    // handleEdit
    if (Main.Instance.ChallengeState.name === Main.Instance.ChallengeState.name_saved &&
        Main.Instance.ChallengeState.date_string === Main.Instance.ChallengeState.date_string_saved &&
        parseInt(Main.Instance.ChallengeState.challenge_type) === parseInt(Main.Instance.ChallengeState.challenge_type_saved)
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.ChallengeState, {offset:(0).toString()})

    getChallenges()
    Logic.Type.New(Main.Instance.ChallengeState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.ChallengeState.pNum > 0) {

        Logic.Type.New(Main.Instance.ChallengeState, {
            offset:((Main.Instance.ChallengeState.pNum - 1) * 
            Main.Instance.ChallengeState.pSize).toString()})
        getChallenges()

        Logic.Type.New(Main.Instance.ChallengeState,{pNum: Main.Instance.ChallengeState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.ChallengeState.pNum + 1) * Main.Instance.ChallengeState.pSize < 
    Main.Instance.ChallengeState.count) {

        Logic.Type.New(Main.Instance.ChallengeState, {
            offset: ((Main.Instance.ChallengeState.pNum + 1) * 
            Main.Instance.ChallengeState.pSize).toString()})
        getChallenges()
        
        Logic.Type.New(Main.Instance.ChallengeState,{pNum: Main.Instance.ChallengeState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.ChallengeState.count <= Main.Instance.ChallengeState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.ChallengeState.count === 0) {

            Logic.Type.New(Main.Instance.ChallengeState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.ChallengeState.count - 1) / 
                    Main.Instance.ChallengeState.pSize)
            Logic.Type.New(Main.Instance.ChallengeState, {
                offset:(npNum * Main.Instance.ChallengeState.pSize).toString()})
        }

        getChallenges()
        Logic.Type.New(Main.Instance.ChallengeState,{pNum: npNum})
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.ChallengeState,{count:Main.Instance.ChallengeState.count ++})
    LastPage()
}

export function Update() {
    clearFields()
    getChallenges()
}

export function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.ChallengeState,{count:Main.Instance.ChallengeState.count --})
    LastPage()
}

export function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.ChallengeState,{count:Main.Instance.ChallengeState.count --})
    getChallenges()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.ChallengeState, {pSize: parseInt(text)})
        FirstPage()
    }
}

function SetMinRounds(text: string) {
    if (parseInt(text) > -1) {
        const conditions: Assets_.IConditions = Object.assign({}, Main.Instance.ChallengeState.conditions)
        if (text.length > 0 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.minRounds = parseInt(text)
        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
    }   
}

function SetReward(text: string) {
    if (parseInt(text) > -1) {
        const conditions: Assets_.IConditions = Object.assign({}, Main.Instance.ChallengeState.conditions)
        conditions.rewardId = +text
        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
    }   
}

function SetBigWin(text: string) {
    if (parseInt(text) > 0) {
        const conditions: Assets_.IConditions = Object.assign({}, Main.Instance.ChallengeState.conditions)
        if (text.length > 0 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.bigWinValue = parseInt(text)
        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
    }   
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getChallengesCount()
    }
}

function DateChange(date: moment.Moment) {
    const date_string: string = date ? moment.default(date).format(Assets_.dateDisplayFormat).trim() : ''
    Logic.Type.New(Main.Instance.ChallengeState, {date_string, date})
}

function SelectChallengeType(which: number) {
    Logic.Type.New (Main.Instance.ChallengeState, {challenge_type: which.toString()})
}

function getChallengeTypes(): JSX.Element[] {
    const ct:JSX.Element[] = []
    ct.push(
        <button
            title = {Assets.SelectTheChallengeType}
            style = {{borderRadius: '0px'}}
            key = "0"
            className = {0 === parseInt(Main.Instance.ChallengeState.challenge_type) ? 'btn btn-danger btn-sm mr0 ml' : 'btn btn-default btn-sm ml mr0'}
            onClick = {() => SelectChallengeType(0)}
        >
            <i className = {Assets_.faChallengeClass}/>&nbsp;&nbsp;{Assets.ChallengeType}
        </button>,
    )

    for (let i: number = 0; i < Assets.challengeTypes.length; i++) {
        ct.push(
            <button
                title = {Assets.SelectTheChallengeType}
                style = {{borderRadius: '0px'}}
                key = {(i + 1).toString()}
                className = {i + 1 === parseInt(Main.Instance.ChallengeState.challenge_type) ? 'btn btn-primary btn-sm mr0 ml0' : 'btn btn-default btn-sm ml0 mr0'}
                onClick = {() => SelectChallengeType(i+1)}
            >
                {Assets.challengeTypes[i]}
            </button>,
        )
    }

    return ct
}

function getMinByCurrency(): JSX.Element[] {
    const min: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.currenciesStream && 
        (!Main.Instance.ChallengeState.conditions || !Main.Instance.ChallengeState.conditions.currencies || Main.Instance.ChallengeState.conditions.currencies.length === 0)) {
        const conditions: Assets_.IConditions = Main.Instance.ChallengeState.conditions ? Object.assign({}, Main.Instance.ChallengeState.conditions) : {}
        if (conditions.currencies === undefined) { conditions.currencies = [] }
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            conditions.currencies.push ({code: Main.Instance.DatabaseDataState.currenciesStream[j]['code']})
        }
        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
    }
    if (Main.Instance.DatabaseDataState.currenciesStream &&
        Main.Instance.ChallengeState.conditions && Main.Instance.ChallengeState.conditions.currencies) {
    for (let i: number = 0; i < Main.Instance.ChallengeState.conditions.currencies.length; i++) {
        let code: string
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.currenciesStream.length; j++) {
            if (Main.Instance.ChallengeState.conditions.currencies[i]['code'] === Main.Instance.DatabaseDataState.currenciesStream[j]['code']) {
                code = Main.Instance.DatabaseDataState.currenciesStream[j]['code']
                break
            }
        }
        if (code === undefined) {continue}
        // console.log (Main.Instance.ChallengeState.conditions.currencies[i])
        // console.log (Main.Instance.ChallengeState.conditions.currencies[i]['minBet'])
        min.push (
            <div key = {i.toString()} className = "row admin-users-row clearfix" style = {{position: 'relative', height: '35px'}}>
                <button 
                    className = {Main.Instance.ChallengeState.conditions && Main.Instance.ChallengeState.conditions.currencies && 
                                 Main.Instance.ChallengeState.conditions.currencies[i] && Main.Instance.ChallengeState.conditions.currencies[i]['minBet'] ? 
                                 'btn btn-success btn-xs fl' : 'btn btn-danger btn-xs fl'}
                    style = {{width: '50px', marginRight: '10px', height: '29px', fontSize: '16px', fontWeight: 700}}
                    onClick = {() => {
                        const conditions: Assets_.IConditions = Object.assign({}, Main.Instance.ChallengeState.conditions)
                        if (conditions.currencies === undefined) { conditions.currencies = [] }
                        if (conditions.currencies[i].minBet === undefined) { conditions.currencies[i] = {code, minBet: '0'} } else { conditions.currencies[i] = {code} }
                        // console.log (conditions)
                        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
                    }}
                >
                    {code}
                </button>
                {Main.Instance.ChallengeState.conditions && Main.Instance.ChallengeState.conditions.currencies && 
                Main.Instance.ChallengeState.conditions.currencies[i] && Main.Instance.ChallengeState.conditions.currencies[i]['minBet'] ?
                    <FormInput
                        narrow = {true}
                        description = {Assets.MinimumBetSizeAmount}
                        ClassName = "form-control register-input"
                        value = {Main.Instance.ChallengeState.conditions === undefined || Main.Instance.ChallengeState.conditions.currencies[i] === undefined ||
                                Main.Instance.ChallengeState.conditions.currencies[i].minBet === undefined ? '0' : Main.Instance.ChallengeState.conditions.currencies[i].minBet}
                        type = "text"
                        iconText = {Assets.MinBetSize}
                        iconTextSize = "14px"
                        onChange = {(text) => SetMinimumBet(i, text)}
                    /> : void 0}                
            </div>,
        )
    }
    }

    return min
}

function SetMinimumBet(which: number, text: string) {
    if (AllowDecimalCharactersOnly(text)) {
        const conditions: Assets_.IConditions = Object.assign({}, Main.Instance.ChallengeState.conditions)
        if (text.length > 1 && text.charAt(0) === '0' && text.charAt(1) !== '.') {
            text = text.slice (1, text.length)
        }
        conditions.currencies[which].minBet = text
        Logic.Type.New (Main.Instance.ChallengeState, {conditions})
    }
}

export function Challenges() {
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    // let customCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>    
    return (<div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {Assets.aeChallenges}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"
            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            ClassName = "form-control"
            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' : Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.ChallengeState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>{Main.Instance.ChallengeState.mode ? Assets.eChallenges + ': ' + Main.Instance.ChallengeState.name_saved  : Assets.aChallenges}</h4>

                        {Main.Instance.ChallengeState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}
                    </div>

                    <div className = "row admin-users-row">

                <FormInput
                    description = {Assets.ChallengeName}
                    value = {!Main.Instance.ChallengeState.name ? '' :
                            Main.Instance.ChallengeState.name} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateName(text)} 
                    placeholder = {Assets.pName} 
                    icon = {Assets_.faChallengeClass}
                />

                <div className = "fl mr singledatepicker">
                    <SingleDatePicker
                        id = "date9"
                        placeholder = {Assets.Date}
                        date = {Main.Instance.ChallengeState.date}
                        onDateChange = {(date) => DateChange(date)}
                        focused = {Main.Instance.ChallengeState.focusedInput}
                        onFocusChange = {(focused) => Logic.Type.New(Main.Instance.ChallengeState, {focusedInput: focused.focused})}
                        showDefaultInputIcon = {true}
                        showClearDate = {true}
                        isOutsideRange = {() => false}
                        displayFormat = {Assets_.dateDisplayFormatdmy}
                        customCloseIcon = {Assets_.customCloseIcon}
                        numberOfMonths = {1}
                        isDayHighlighted = {(date) => HighlightToday(date)}
                    />
                </div>
                {getChallengeTypes()}
            </div>
            <div className = "row admin-users-row clearfix" style = {{position: 'relative', marginBottom: '5px'}}>
            {parseInt(Main.Instance.ChallengeState.challenge_type) === 1 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 2 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 3 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 4 ?
            <FormInputNumber
                description = {Assets.MinimumNumberOfRounds}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName= "h30 w60"
                value = {Main.Instance.ChallengeState.conditions && Main.Instance.ChallengeState.conditions.minRounds ? Main.Instance.ChallengeState.conditions.minRounds.toString() : '0'}
                type = "number"
                min = "0"
                iconText = {Assets.MinRounds}
                iconTextS = "14px"
                onChange = {(text) => SetMinRounds(text)}
                // noIconTextFix = {true}
            /> : void 0}

            {parseInt(Main.Instance.ChallengeState.challenge_type) === 1 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 2 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 3 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 4 ?
            <FormSelect
                rewardFor = {4}
                level = {true}
                wide = {true}
                mt0 = {true}
                value = {Main.Instance.ChallengeState.conditions && Main.Instance.ChallengeState.conditions.rewardId ? 
                        Main.Instance.ChallengeState.conditions.rewardId.toString() : '0'}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "form-control"
                o1 = {Assets.SelectReward}
                o2 = {Assets.SelectReward}
                onChange = {(text) => SetReward(text)}
                icon = {Assets_.faRewardClass}
            /> : void 0}

            {parseInt(Main.Instance.ChallengeState.challenge_type) === 4 ?
            <FormInputNumber
                description = {Assets.BigWinValue}
                outerDiv = "rows-per-table input-group fl mr ml pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.ChallengeState.conditions === undefined || Main.Instance.ChallengeState.conditions.bigWinValue === undefined ? '15' :
                         Main.Instance.ChallengeState.conditions.bigWinValue.toString()}  
                type = "number" 
                min = "1"
                iconText = {Assets.BigWin + ' x'}
                iconTextS = "14px"
                onChange = {(text) => SetBigWin(text)}
            /> : void 0}
   
            </div>
            {parseInt(Main.Instance.ChallengeState.challenge_type) === 1 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 2 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 3 ||
            parseInt(Main.Instance.ChallengeState.challenge_type) === 4 ?            
            getMinByCurrency() : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.ChallengeState.mode ? Assets.saveChanges : Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.ChallengeState.mode ? 'btn btn-warning btn-sm btn-plus fr' : 'btn btn-primary btn-sm btn-plus fr'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>
            </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.ChallengeState.pSize ? '' : Main.Instance.ChallengeState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.ChallengeState.filter_challenge_type ? '0' : Main.Instance.ChallengeState.filter_challenge_type}
                data = {Main.Instance.FrontendStoreState.Category5}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                onChange = {(text) => ApplyFilterType(text)} 
            />

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.ChallengeState.count ? Main.Instance.ChallengeState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.ChallengeState.count ? Main.Instance.ChallengeState.pNum * 
                Main.Instance.ChallengeState.pSize + 1 : 0}{' - '}
                {Main.Instance.ChallengeState.count ? 
                ((Main.Instance.ChallengeState.pNum + 1) * 
                Main.Instance.ChallengeState.pSize > Main.Instance.ChallengeState.count ? 
                Main.Instance.ChallengeState.count : 
                (Main.Instance.ChallengeState.pNum + 1) * Main.Instance.ChallengeState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.ChallengeState.count ? 
            Main.Instance.ChallengeState.pNum + 1 :0}{' / '}
            {Main.Instance.ChallengeState.count ? 
            Math.trunc((Main.Instance.ChallengeState.count - 1) /
            Main.Instance.ChallengeState.pSize + 1) : 0}
        </span>
        </div>
        <h3>{Assets.lChallenges}</h3>
        {/*console.log (Main.Instance.DatabaseDataState.challengesStream)*/}
        {Main.Instance.DatabaseDataState.challengesStream !== null && Main.Instance.DatabaseDataState.challengesStream !== undefined ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.challengesStream}
            columns = {[
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                {width: 200, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {width: 90, Header: Assets.Date, accessor: 'date', headerClassName: 'th-left'},
                {width: 150, Header: Assets.ChallengeType, accessor: 'Challenge Type', headerClassName: 'th-left'},
                {Header: Assets.Challenge, accessor: 'challenge', headerClassName: 'th-left', Cell: (row) => TableUtils.ChallengeDescription(row)},
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : void 0}

        {/*console.log(Main.Instance.DatabaseDataState.challengesStream)*/}
        {/*Main.Instance.DatabaseDataState.challengesStream !== null && Main.Instance.DatabaseDataState.challengesStream !== undefined ?
        StremingTable(Main.Instance.DatabaseDataState.challengesStream, {
            title: 'List of Challenges', 
            mode: Main.Instance.ChallengeState.mode,
            editingId: Main.Instance.ChallengeState.id,
            sort: getChallengesCount,
            Sort: Main.Instance.ChallengeState,
            borderRadius: Main.Instance.FrontendStoreState.borderRadius,
            delButton:(data) => handleDelete(data),
            editButton:(data) => handleEdit(data),
        })
    : Main.Instance.FrontendStoreState.site_id ? void 0 : void 0*/}
        </div>
    </div>)
}

function HighlightToday(date: moment.Moment): boolean {
    if (date.format(Assets_.dateDisplayFormat) === (new Date()).toISOString().substring(0, 10)) { return true }
    return false
}

function ApplyFilterType(text:string) {
    Logic.Type.New(Main.Instance.ChallengeState, {filter_challenge_type: text})
    FirstPage()
}