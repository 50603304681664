import {Logic} from '../Type'
import {BonusNew, BonusCampaignNew, FreeGame} from '../Database/DatabaseData'

export enum wageringType {
    ROUNDS,
    TURNOVER
}

export enum triggerType {
    DEPOSIT,
    ACTIVATE_ACCOUNT,
    ACCOUNT_VERIFICATION,
    BONUS_CODE,
    MANUAL_BONUS,
    SEGMENTATION_BONUS,
    DEPENDENT,
    GAMIFICATION
}
    
export enum conditionRule {
    COUNT,
    // AMOUNT,
    MIN_AMOUNT,
    REWARD_AMOUNT,
    FACTOR,
    MAX_REWARD_AMOUNT,
    VERIFICATION_TYPE
}
    
export enum conditionOperator { 
    EQUAL,
    LESS_THAN,
    MORE_THAN,
    LESS_THAN_OR_EQUAL,
    MORE_THAN_OR_EQUAL
}

export enum bonusType {
    MONEY,
    FREE_GAMES
}

export enum verificationType {
    SMS,
    EMAIL,
    SMS_EMAIL
}

export enum timeUnits {
    hours,
    days,
    weeks,
    months,
    years
  }
    

export class BonusCampaignState extends Logic.Type {
    public bonusCampaign: BonusCampaignNew
    public bonusCampaign_: string
    public mode: boolean = false
    public focusedInputStart: any = null
    public focusedInputEnd: any = null
}

export class BonusState extends Logic.Type {
    public bonus: BonusNew
    public bonus_: string
    public mode: boolean = false
    public selectGames: string = '-1'
    public filteredGames: FreeGame[]
    public termsConditionsPopup: boolean = false
    public setBonusValidity: boolean = false
    public manageWageringContribution: boolean = false
    public manageBonusCodes: boolean = false
    public manageTags: boolean = false
    public manageDependantBonuses: boolean = false
    public timeToActivateBonusImmediate: boolean = false
}


export class FreeGamesState extends Logic.Type {
    public mode: boolean = false
    public selectGames: boolean = false
    public selectedGame: FreeGame
    public selectedGame_: string

}