import {Logic} from '../Type'
import * as Assets from '../../Views/Assets'
export enum SelectedView {CurrentOverview, DashboardBackend, Gamification, TopPlayers, /*Veto, Crif, Pep, */AccountsData, RiskAlerts, CustomerSupportCallback, RecentUploads, GamificationIssues, IssueFilters}

export class DashboardState extends Logic.Type {
    public mainRaffleWinnerEnter: boolean = true
    public mainRaffleWinner: string = '0'
    public mainRaffleResults: number[]
    public secondaryRaffleResults: number[]
    public mainRafflePrizes: number = 1
    public secondaryRafflePrizes: number = 9
    public countCalled: boolean
    public selectedView: SelectedView = SelectedView.CurrentOverview
    public expandButton: number[] = [-1, -1, -1, -1, -1, -1, -1, -1] /*  CurrentOverview, Last24Hours, Players, Settlement, DepositsWithdrawals, Raffles, Tournaments, GiftShop */
    public click: number[][] = [[-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1], [-1, -1, -1, -1]]
    public position: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    public yearFrom: number = -1
    public yearTo: number = -1
    public monthFrom: number = -1
    public monthTo: number = -1
    public monthFromPart: number = -1
    public monthToPart: number = -1
    public lastYear: number = undefined
    public selectedIssueCategory: string
    public selectedIssueType: number = -1
    public selectedIssueSubCategory: number = -1
    // public selectedIssueTypeAllIssues: boolean = true
    public selectedResolvedType: string = Assets.All
    public selectedDay: string
    public selectedIssueSubCategoryUnresolved: number = -1

    // old items, was connected with the map
/*
    getit: string
    array: string[]
    Data: JSON
    selectedView: 'PromoStore'|'Players'|'Users'|'Admin'
    selection: string = '0'
    selected_country: string = ''
    selected_country_players: string = ''
    selection_map: string = '0'
    hovered_country: string = ''
    show_map: boolean = false
    show_flags: boolean = false
*/
}