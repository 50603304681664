import * as React from 'react'
import { BonusNew, GamesList, /*FreeGame*/ } from 'src/Logic/Database/DatabaseData'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import Switch from 'react-toggle-switch'
import {Logic} from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FormInputNumber} from '../../Reusables/Inputs'
import {getGameProviders, getProviderName} from './FreeGames'

interface Games {
    gameId?: number,
    coinValueLevel?: number,
    betLevel?: number,
    valueAmount?: number,
    gameName?: string,
    selected?: boolean,
    freeGameAmount?: number
    providerId: number
}

interface Props {
    bonusType: string
}

interface State {
    games: Games[]
    // single: boolean
    coinValueLevel: number
    betLevel: number
    freeGameAmount: number
    selectedGameProvider: number
    valueAmount: number
}

export default class GameAddPopup extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            games: undefined,
            // single: Main.Instance.BonusStateNew.bonus.freeGamesAllowedGames && Main.Instance.BonusStateNew.bonus.freeGamesAllowedGames.length > 1 ? false : true,
            coinValueLevel: Main.Instance.BonusStateNew.bonus.freeGames && Main.Instance.BonusStateNew.bonus.freeGames.length > 0 ? Main.Instance.BonusStateNew.bonus.freeGames[0].coinValueLevel : 0,
            betLevel: Main.Instance.BonusStateNew.bonus.freeGames && Main.Instance.BonusStateNew.bonus.freeGames.length > 0 ? Main.Instance.BonusStateNew.bonus.freeGames[0].betLevel : 0,
            freeGameAmount: Main.Instance.BonusStateNew.bonus.freeGames && Main.Instance.BonusStateNew.bonus.freeGames.length > 0 ? Main.Instance.BonusStateNew.bonus.freeGames[0].freeGameAmount : 0,
            valueAmount: Main.Instance.BonusStateNew.bonus.freeGames && Main.Instance.BonusStateNew.bonus.freeGames.length > 0 ? Main.Instance.BonusStateNew.bonus.freeGames[0].valueAmount : 0,
            selectedGameProvider: -1 
        }

    }

    public setGames() {
        const gamesCentral: GamesList[] = Object.assign([], Main.Instance.DatabaseDataState.gamesList)
        const games: Games[] = []
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        // if (this.props.bonusType === 'free') {
            
            
        //     const arr = bonus.freeGamesAllowedGames
        //     if (Main.Instance.DatabaseDataState.freeGameSettings) {
        //         for (const item of Main.Instance.DatabaseDataState.freeGameSettings) {
        //             let selected: boolean = false
        //             let coinValueLevel: number = item.coinValueLevel
        //             let betLevel: number = item.betLevel
        //             let freeGameAmount: number = item.freeGameAmount
        //             if (arr) {
        //                 for (const Item of arr) {
        //                     if (Item === item.gameId) {
        //                         selected = true
        //                     }
        //                 }
        //             }
        //             if (bonus.freeGames) {
        //                 for (const ITEM of bonus.freeGames) {
        //                     if (ITEM.gameId === item.gameId) {
        //                         coinValueLevel = ITEM.coinValueLevel
        //                         betLevel = ITEM.betLevel
        //                         freeGameAmount = ITEM.freeGameAmount
        //                         break
        //                     }
        //                 }
                        
        //             }
        //             games.push({gameId: item.gameId, gameName: item.freeGameName, selected, coinValueLevel, betLevel, freeGameAmount, providerId: item.providerId})
        //         }
            
        //     }

        // } else {

        for (const item of gamesCentral) {
            let selected: boolean = false
            let coinValueLevel: number
            let betLevel: number
            let freeGameAmount: number
            let valueAmount: number
            let arr = []
            if (this.props.bonusType === 'include' ) {
                arr = bonus.bonusGameInclude
                if (arr) {
                    for (const Item of arr) {
                        if (Item === item.gameId) {
                            selected = true
                        }
                    }
                }
            } else if (this.props.bonusType === 'exclude') {
                arr = bonus.bonusGameExclude
                if (arr) {
                    for (const Item of arr) {
                        if (Item === item.gameId) {
                            selected = true
                        }
                    }
                }
            } else if (this.props.bonusType === 'free') {
                arr = bonus.freeGamesAllowedGames
                if (arr) {
                    for (const Item of arr) {
                        if (Item === item.gameId) {
                            selected = true
                        }

                        
                    }
                }
                if (Main.Instance.DatabaseDataState.freeGameSettings) {
                    for (const Item of Main.Instance.DatabaseDataState.freeGameSettings) {
                        if (Item.gameId === item.gameId) {
                            coinValueLevel = Item.coinValueLevel
                            betLevel = Item.betLevel
                            freeGameAmount = Item.freeGameAmount
                            valueAmount = Item.valueAmount
                            break
                        }
                    }
                }
                if (bonus.freeGames) {
                    for (const Item of bonus.freeGames) {
                        if (Item.gameId === item.gameId) {
                            coinValueLevel = Item.coinValueLevel
                            betLevel = Item.betLevel
                            freeGameAmount = Item.freeGameAmount
                            valueAmount = Item.valueAmount
                            break
                        }
                    }
                    
                }
                
            } 
            if (this.props.bonusType === 'free') {
                games.push({gameId: item.gameId, gameName: item.gameName, selected, coinValueLevel, betLevel, freeGameAmount, valueAmount, providerId: item.providerId})

            } else {
                games.push({gameId: item.gameId, gameName: item.gameName, selected, providerId: +item.providerId})
            }
            
        }
        // }
        this.setState({games})
    }

    public updateValue(GameId: number) {

        const games: Games[] = Object.assign([], this.state.games)
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        // if (this.props.bonusType === 'free' && this.state.single && bonus.bonusGameInclude.length === 1 && bonus.freeGamesAllowedGames[0] !== GameId) {
        //     alert(Assets.Only + ' ' + Assets.One.toLowerCase() + ' ' + Assets.Game.toLowerCase() + ' ' + Assets.CanBeSelected.toLowerCase())
        //     return
        // }
        for (const item of games) {
            if (item.gameId === GameId) {
                item.selected = !item.selected
                if (this.props.bonusType === 'include' ) {
                    if (item.selected) {
                        bonus.bonusGameInclude.push(item.gameId)
                    } else {
                        for (let i: number = 0; i < bonus.bonusGameInclude.length; i++) {
                            if (bonus.bonusGameInclude[i] === item.gameId) {
                                bonus.bonusGameInclude.splice(i, 1)
                                break
                            }
                        }
                    }
                } else if (this.props.bonusType === 'exclude') {
                    if (item.selected) {
                        bonus.bonusGameExclude.push(item.gameId)
                    } else {
                        for (let i: number = 0; i < bonus.bonusGameExclude.length; i++) {
                            if (bonus.bonusGameExclude[i] === item.gameId) {
                                bonus.bonusGameExclude.splice(i, 1)
                                break
                            }
                        }
                    }
                } else {
                    if (item.selected) {
                        bonus.freeGamesAllowedGames.push(item.gameId)
                        if (bonus.freeGamesAllowedGames.length === 1 && item.betLevel && item.coinValueLevel && item.freeGameAmount && item.valueAmount) {
                            this.setState({coinValueLevel: item.coinValueLevel, betLevel: item.betLevel, freeGameAmount: item.freeGameAmount, valueAmount: item.valueAmount})
                        } else {
                            item.coinValueLevel = /*this.state.single ? item.coinValueLevel :*/ this.state.coinValueLevel
                            item.betLevel = /*this.state.single ? item.betLevel :*/ this.state.betLevel
                            item.freeGameAmount = /*this.state.single ? item.freeGameAmount :*/ this.state.freeGameAmount
                            item.valueAmount = this.state.valueAmount
                        }

                        if (!this.arePropsSame(item)) {
                            bonus.freeGames.push({gameId: item.gameId, coinValueLevel: item.coinValueLevel, betLevel: item.betLevel, freeGameAmount: item.freeGameAmount, valueAmount: item.valueAmount})
                        } 
                    } else {
                        for (let i: number = 0; i < bonus.freeGames.length; i++) {
                            if (bonus.freeGames[i].gameId === item.gameId) {
                                bonus.freeGames.splice(i, 1)
                                break
                            }
                        }
                        for (let i: number = 0; i < bonus.freeGamesAllowedGames.length; i++) {
                            if (bonus.freeGamesAllowedGames[i] === item.gameId) {
                                bonus.freeGamesAllowedGames.splice(i, 1)
                                break
                            }
                        }
                    }
                } 

                break
            }
            
        }

        this.setState({games})
        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
    }

    public arePropsSame(game: Games): boolean {
        for (const item of Main.Instance.DatabaseDataState.freeGameSettings) {
            if (game.gameId == item.gameId) {
                if (game.betLevel === item.betLevel && game.coinValueLevel === item.coinValueLevel && game.valueAmount === item.valueAmount && game.freeGameAmount === item.freeGameAmount) {
                    return true
                }
            }
        }
        return false
    }

    public updateInput(property: string, GameId: number, event) {
        const games: Games[] = Object.assign([], this.state.games)
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        for (const item of games) {
            if (item.gameId === GameId) {
                item[property] = +event.target.value
                let found = false
                for (let i: number = 0; i < bonus.freeGames.length; i++) {
                    if (bonus.freeGames[i].gameId === item.gameId) {
                        bonus.freeGames[i][property] = +event.target.value
                        found = true
                        if (this.arePropsSame(item)) {
                            bonus.freeGames.splice(i, 1)
                        }
                        break

                    }
                }
                if (!found) {
                    if (!this.arePropsSame(item)) {
                        bonus.freeGames.push({gameId: item.gameId, coinValueLevel: item.coinValueLevel, valueAmount: item.valueAmount, betLevel: item.betLevel, freeGameAmount: item.freeGameAmount})
                    } 
                }
                break
            }
        }
        this.setState({games})
        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
    }

    public getInput(value, original, accessor): JSX.Element {
        return (
            <input 
                style={{width: 'calc(100%)', height: '27px', borderWidth: '1px'}}
                key={accessor}
                type = "number" 
                value = {value} 
                onChange = {this.updateInput.bind(this, accessor, original.gameId)}
            />
        )
    }

    public updateMultiple(property: string, value: number) {
        const games: Games[] = Object.assign([], this.state.games)
        const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)
        for (const item of games) {
            if (item.selected) {
                item[property] = value 
                for (let i: number = 0; i < bonus.freeGames.length; i++) {
                    bonus.freeGames[i][property] = value
                }
                if (this.arePropsSame(item)) {
                    for (let i: number = 0; i < bonus.freeGames.length; i++) {
                        if (item.gameId === bonus.freeGames[i].gameId) {
                            bonus.freeGames.splice(i, 1)
                        }
                    }
                } else {
                    let found: boolean = false
                    for (let i: number = 0; i < bonus.freeGames.length; i++) {
                        if (item.gameId === bonus.freeGames[i].gameId) {
                            found = true
                        }
                    }
                    if (!found) {
                        bonus.freeGames.push({gameId: item.gameId, coinValueLevel: item.coinValueLevel, valueAmount: item.valueAmount, betLevel: item.betLevel, freeGameAmount: item.freeGameAmount})
                    }
                }
            }

        
        
        
        }
        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
          
    }

    public render() {

        if (!this.state.games) {
            this.setGames()
        }

        return (
        <div className = "clearfix add-setting-popup">
            <div className = "add-setting-main add-setting-wide">
                <div style = {{position: 'relative'}}>
                    <button style = {{position: 'absolute', marginRight: '0px', top: '-30px', right: '-10px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.BonusStateNew, {selectGames: '-1'})}>
                        <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                    </button>
                    <select
                        title = {Assets.SelectGameProvider}
                        style = {{height: '30px', width: 'auto'}}
                        className = "fr dbl form-control mr"
                        onChange = {(e) => {this.setState({selectedGameProvider: +e.target.value})}}
                        value = {this.state.selectedGameProvider}
                    >
                        {getGameProviders()}
                    </select>
                    {/* {Main.Instance.BonusStateNew.selectGames === 'free' ?
                    <div className="dbl">
                        <button 
                            title = {Assets.Select + ' ' + Assets.Single.toLowerCase() + ' ' + Assets.Game.toLowerCase()} 
                            key = "single" 
                            onClick = {() => {
                                const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)

                                if (bonus.bonusGameInclude && bonus.bonusGameInclude.length > 0) {
                                    if (confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.includedGames)) {
                                        bonus.freeGames = []
                                        bonus.bonusGameInclude = []
                                        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
                                        this.setState({single: true})
                                        this.setGames()
                                    }
                                } else {
                                    this.setState({single: true})
                                }
                                
                            }} 
                            className = {this.state.single ? 'btn btn-primary btn-sm mr mb5 dbl fl' : 'btn btn-default btn-sm mr mb5 dbl fl'}>
                                {Assets.Single + ' ' + Assets.Game.toLowerCase()}
                        </button>
                        <button 
                            title = {Assets.Select + ' ' + Assets.Multiple.toLowerCase() + ' ' + Assets.Games.toLowerCase()} 
                            key = "multiple" 
                            onClick = {() => {
                                const bonus: BonusNew = Object.assign({}, Main.Instance.BonusStateNew.bonus)

                                if (bonus.bonusGameInclude && bonus.bonusGameInclude.length > 0) {
                                    if (confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.includedGames)) {
                                        bonus.freeGames = []
                                        bonus.bonusGameInclude = []
                                        Logic.Type.New(Main.Instance.BonusStateNew, {bonus})
                                        this.setState({single: false})
                                        this.setGames()
                                    }
                                } else {
                                    this.setState({single: false})
                                }
                                
                            }} 
                            className = {!this.state.single ? 'btn btn-primary btn-sm mr mb5 dbl fl' : 'btn btn-default btn-sm mr mb5 dbl fl'}>
                                {Assets.Multiple + ' ' + Assets.Games.toLowerCase()}
                        </button>
                        
                    </div> : void 0} */}
                    {Main.Instance.BonusStateNew.selectGames === 'free' /*&& !this.state.single*/ ? 
                    <FormInputNumber
                        description = {Assets.CoinValue + ' ' + Assets.level}
                        value = {this.state.coinValueLevel ? this.state.coinValueLevel.toString() : '0'} 
                        outerDiv = "rows-per-table input-group fl mr pl0"
                        ClassName = "h30 w60 pl7"
                        type = "number" 
                        onChange = {(text) => {
                            this.setState({coinValueLevel: +text})
                            this.updateMultiple('coinValueLevel', +text)
                        }} 
                        min = "0"
                        iconText = {Assets.CoinValue}
                        iconTextS  = "12px"
                    />
                    : void 0}
                    {Main.Instance.BonusStateNew.selectGames === 'free' /*&& !this.state.single*/ ? 
                    <FormInputNumber
                        description = {Assets.BetLevel}
                        value = {this.state.betLevel ? this.state.betLevel.toString() : '0'} 
                        outerDiv = "rows-per-table input-group fl mr pl0"
                        ClassName = "h30 w60 pl7"
                        type = "number" 
                        onChange = {(text) => {
                            this.setState({betLevel: +text})
                            this.updateMultiple('betLevel', +text)
                        }} 
                        min = "0"
                        iconText = {Assets.BetLevel}
                        iconTextS  = "12px"
                    />
                    : void 0}
                    {Main.Instance.BonusStateNew.selectGames === 'free' /*&& !this.state.single*/ ? 
                    <FormInputNumber
                        description = {Assets.Number + Assets._of_ + Assets.FreeGames.toLowerCase()}
                        value = {this.state.freeGameAmount ? this.state.freeGameAmount.toString() : '0'} 
                        outerDiv = "rows-per-table input-group fl mr pl0"
                        ClassName = "h30 w60 pl7"
                        type = "number" 
                        onChange = {(text) => {
                            this.setState({freeGameAmount: +text})
                            this.updateMultiple('freeGameAmount', +text)
                        }} 
                        min = "0"
                        iconText = {Assets.Number + Assets._of_ + Assets.FreeGames.toLowerCase()}
                        iconTextS  = "12px"
                    />
                    : void 0}
                    {Main.Instance.BonusStateNew.selectGames === 'free' /*&& !this.state.single*/ ? 
                    <FormInputNumber
                        description = {Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase()}
                        value = {this.state.valueAmount ? this.state.valueAmount.toString() : '0'} 
                        outerDiv = "rows-per-table input-group fl mr pl0"
                        ClassName = "h30 w60 pl7"
                        type = "number" 
                        onChange = {(text) => {
                            this.setState({valueAmount: +text})
                            this.updateMultiple('valueAmount', +text)
                        }} 
                        min = "0"
                        iconText = {Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase()}
                        iconTextS  = "12px"
                    />
                    : void 0}
                    <br/>
                    <br/>
                    {this.state.games ?
                    <ReactTable
                    data = {this.state.games ? this.state.games : []}
                    filtered = {[{id: 'providerId', value: this.state.selectedGameProvider}]}
                    // onFilteredChange = {(filtered, column, value) => {
                    //     tableFilters(value, column.accessor)
                    // }}
                    defaultFilterMethod = {(filter, row, column) => {
                        const id = filter.id
                        if (+filter.value === -1) {
                            return true
                        }
                        
                        return row[id] !== undefined ? +(row[id]) === +filter.value : true
                    }}
                    columns = {[
                        {width: 150, Header: Assets.GameID, accessor: 'gameId', headerClassName: 'th-left'},
                        {Header: Assets.Game + ' ' + Assets.Name.toLowerCase(), accessor: 'gameName', headerClassName: 'th-left'},
                        {width: 200, Header: Assets.Provider, accessor: 'providerId', headerClassName: 'th-left', Cell: (row) => getProviderName(row.value)},
                        {width: 200, show: this.props.bonusType === 'free' /*&& this.state.single*/, Header: Assets.CoinValue, accessor: 'coinValueLevel', headerClassName: 'th-left', Cell: (row) =>
                            <div className = {'pr'}>
                                {row.value}
                                    {/*!row.original.selected ? row.value
                                    : 
                                    this.getInput(row.value, row.original, 'coinValueLevel')
                                    */}
                            </div> 
                        },
                        {width: 200, show: this.props.bonusType === 'free' /*&& this.state.single*/, Header: Assets.BetLevel, accessor: 'betLevel', headerClassName: 'th-left', Cell: (row) =>
                        <div className = {'pr'}>
                            {row.value}
                                {/* {!row.original.selected ? row.value
                                : 
                                this.getInput(row.value, row.original, 'betLevel')} */}
                            </div> 
                        },
                        {width: 200, show: this.props.bonusType === 'free' /*&& this.state.single*/, Header: Assets.Number + Assets._of_ + Assets.FreeGames.toLowerCase(), accessor: 'freeGameAmount', headerClassName: 'th-left', Cell: (row) => 
                            <div className = {'pr'}>
                                {row.value}
                                {/* {!row.original.selected ? row.value
                                : 
                                 this.getInput(row.value, row.original, 'freeGameAmount')} */}
                            </div> 
                        },
                        {width: 250, show: this.props.bonusType === 'free' /*&& this.state.single*/, Header: Assets.Value + Assets._of_ + Assets.FreeSpins.toLowerCase() + Assets._in_ + Assets.RealMoney.toLowerCase(), accessor: 'valueAmount', headerClassName: 'th-left', Cell: (row) => 
                            <div className = {'pr'}>
                                {row.value}
                                {/* {!row.original.selected ? row.value
                                : 
                                 this.getInput(row.value, row.original, 'freeGameAmount')} */}
                            </div> 
                        },
                        {width: 150, Header: this.props.bonusType === 'exclude' ? Assets.Excluded : Assets.Included, accessor: 'selected', headerClassName: 'th-center', Cell: (row) => 
                        <Switch
                            className = "fl dbl mr"
                            onClick = {() => {this.updateValue(row.original.gameId)}}
                            on = {row.original.selected}
                        />
                        }, 

                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    /> : void 0}
                </div>
            </div>
        </div>
        )
    }
}
