// import {_js, _min, jpg, png, _jpg} from './Assets_'
export const challengeTypes = ['Bets count', 'Wins count', 'Consecutive wins count', 'Big wins count']
export const somethingWentWrongInsert: string = 'Something went wrong with insert. Not any free space anymore, perhaps?'
export const Path = 'Path'
export const GamesInfo = 'Games Info'
export const General = 'General'
export const GeneralSettings = 'General Settings'
export const UserSettings = 'User Settings'
export const GamesSelection = 'Games Selection'
export const PlayersSelection = 'Players Selection'
export const games_selected = ' game(s) selected'
export const UnsavedChanges: string = 'There are unsaved changes. Are you sure that you want to close editing?'
export const ContactDetails: string = 'Contact Details'
export const Entity: string = 'Entity'
export const loggedInAs: string = 'Logged in as'
export const Version: string = 'Version'
export const _for_:string = ' for '
export const _of_:string = ' of '
export const _and_:string = ' and '
export const and_:string = 'and '
export const _or_:string = ' or '
export const _to_:string = ' to '
export const _at_:string = ' at '
export const _by_:string = ' by '
export const _in_:string = ' in '
export const Login:string = 'Login'
export const ForgotPassword:string = 'Forgot Password'
export const Points: string = 'Points'
export const Question: string = 'Question'
export const ListOfMissions: string = 'List of Missions'
export const Mission: string = 'Mission'
export const SelectAHiddenReward: string = 'Select a Hidden reward'
export const HiddenRewards: string = 'Hidden rewards'
export const HiddenReward: string = 'Hidden reward'
export const hramt: string = 'Hidden reward appears in mission take #'
export const hraat: string = 'Hidden reward appears after Task # is completed'
export const HiddenRewardShort: string = 'HdR'
export const HiddenRewardShortest: string = 'Hr'
export const AddANewHiddenReward: string = 'Add a new Hidden reward'
export const RemoveTheHiddenReward: string = 'Remove the Hidden reward'
export const MissionDescriptionNoTaskSelected: string = 'Mission Description / No task selected'
export const SelectATask: string = 'Select a Task'
export const Task: string = 'Task'
export const Tasks: string = 'Tasks'
export const Order: string = 'Order'
export const Name: string = 'Name'
export const Description: string = 'Description'
export const Mt: string = 'Mt'
export const MinBet: string = 'Min. bet'
export const AddANewTask: string = 'Add a new Task'
export const RemoveTheTask: string = 'Remove the Task'
export const AddANewObjective: string = 'Add a new Objective'
export const RemoveTheObjective: string = 'Remove the Objective'
export const Objectives: string = 'Objectives'
export const Objective: string = 'Objective'
export const SelectAnObjective: string = 'Select an Objective'
export const AddEditMissions: string = 'Add/Edit Missions'
export const EditTheMission: string = 'Edit the Mission'
export const AddANewMission: string = 'Add a new Mission'
export const MissionDescriptionOptional: string = 'Mission description (optional)'
export const t1o: string = 'There is 1 objective for this task.'
export const t1r: string = 'There is 1 reward for this hidden reward.'
export const rthr: string = 'rewards for this hidden reward.'
export const oftt: string = 'objectives for this task.'
export const ThereAre: string = 'There are'
export const HiddenRewardsName: string = 'Hidden rewards name'
export const MaximumAmountOfTimesTheMissionCanBeTaken: string = 'Maximum amount of times the mission can be taken'
export const SpecifyOrderOfTheMissions: string = 'Specify order of the missions'
export const MissionName: string = 'Mission name'
export const RatingPoints: string = 'Rating points'
export const Loss:string = 'Loss'
export const Losses:string = 'Losses'
export const Wagering:string = 'Wagering'
export const LossesOnly:string = 'Losses Only'
export const WageringOnly:string = 'Wagering Only'
export const LossesDesc:string = 'Percentage of points that should be assigned through losses.'
export const WageringDesc:string = 'Percentage of points that should be assigned through wagering.'
export const Rule:string = 'Rule'
export const HeightPx:string = 'Height (px)'
export const WidthPx:string = 'Width (px)'
export const Height:string = 'Height'
export const Width:string = 'Width'
export const ShopItemHeightDesc:string = 'Height (px) of the shop item'
export const ShopItemImageHeightDesc:string = 'Height (px) of the shop item image'
export const ShopItemWidthDesc:string = 'Width (px) of the shop item'
export const borderWidthDesc:string = 'Width (px) of the shop item border. Set to 0 if you want to remove it.'
export const CoverWhole:string = 'image covers whole'
export const CoverWholeDesc:string = 'Indicates whether the shop item image should cover the whole item'
export const borderRadiusDesc:string = 'Set the border radius for the shop item title frame'
export const titleSizeDesc:string = 'Set the size and color of the font that is used for the shop item title'
export const titleSize:string = 'item title font size'
export const titleColorDesc:string = 'Set the color of the font that is used for the shop item title'
export const OpsDetails: string = 'Selected Operators:'
export const GpsDetails: string = 'Selected Game Providers:'
export const PpsDetails: string = 'Selected Payment Providers:'
export const CpsDetails: string = 'Selected Compliance Providers:'
export const BosDetails: string = 'Selected Site Owners:'
export const SitsDetails: string = 'Selected Gaming Sites:'
export const GapsDetails: string = 'Selected Game Asset Providers:'
export const PspsDetails: string = 'Selected Payment Sub-Providers:'
export const CspsDetails: string = 'Selected Compliance Sub-Providers:'
export const SubEntitiesDetails:string = 'List of Sub Entities:'
export const SetGraphics: string = 'Set Graphics'
export const pCity: string      = 'City'
export const pUsername: string  = 'Username'
export const pOldPassword: string  = 'Old Password'
export const pNewPassword: string  = 'New Password'
export const ProblemsChange: string  = 'Errors in supplied data, please correct before changing.'
export const SuccessChange: string  = 'Successfully changed password/username/security question/security question answer.'
export const Change: string  = 'Change'
export const ChangeShort: string  = 'Chg'
export const ChangePassword: string  = 'Change Password'
export const pPassword: string  = 'Password'
export const pBalance: string  = 'Balance'
export const pRepeatPassword: string  = 'Retype New Password'
export const pCountry: string   = 'Country'
export const pPostal: string    = 'Postal code'
export const pPhone: string     = 'Telephone'
export const pEmail: string     = 'Email address'
export const pCompany: string   = 'Entity name'
export const pAddress: string   = 'Address'
export const Search: string     = 'Search'
export const pSearch: string    = Search + ' ...'
export const pFirstName: string = 'First name'
export const pLastName: string  = 'Last name'
export const pName: string  = 'Name'    
export const pContractualPartner: string  = 'Contractual Partner' 
export const ContractualPartnerType: string  = 'Contractual Partner Type' 
export const SubEntityType: string  = 'Sub Entity Type' 
export const pRows:string = 'rows'
export const show:string = 'show'
export const Show:string = 'Show'
export const Hide:string = 'Hide'
export const recordsPerPage:string = 'records per page'
export const recordsPerPage1:string = 'records'
export const recordsPerPage2:string = 'per page'
export const SelectAverageType = 'APBS Type?'
export const _Median = 'APBS - Median'
export const _Mean = 'APBS - Mean'
export const Median = 'Median'
export const Mean = 'Mean'
export const OperatorsCountTitle            : string = 'OPs (#)'
export const GameProvidersCountTitle        : string = 'GPs (#)'
export const PaymentProvidersCountTitle     : string = 'PPs (#)'
export const ComplianceProvidersCountTitle  : string = 'CPs (#)'
export const BrandOwnersCountTitle  : string = 'SOs (#)'
export const SitesCountTitle                : string = 'GSTs (#)'
export const GameAssetProvidersCountTitle   : string = 'GAPs (#)'
export const PaymentSubProvidersCountTitle  : string = 'PSPs (#)'
export const ComplianceSubProvidersCountTitle: string = 'CSPs (#)'
export const StoresCountTitle: string = 'GSTs (#)'
export const ContractualPartner:string = 'Contractual Partner'
export const ContractualPartners:string = 'Contractual Partners'
export const tContractualPartners          : string = 'List of Contractual Partners'
export const tSubEntity                    : string = 'List of Sub Entities'
export const AddGamingSite                  : string = 'Add a new Gaming Site'
export const AddCategory                   : string = 'Add a new Category'
export const EditCategory                   : string = 'Edit the Category'
export const EditTranslation                   : string = 'Edit the Translation'
export const AddType                   : string = 'Add a new Type'
export const EditType                   : string = 'Edit the Type'
export const AddGameAssetProvider           : string = 'Add a new Game Asset Provider'
export const AddPaymentSubProvider         : string = 'Add a new Payment Sub-Provider'
export const AddComplianceSubProvider      : string = 'Add a new Compliance Sub-Provider'
export const EditGamingSite                  : string = 'Edit the Gaming Site'
export const EditGameAssetProvider           : string = 'Edit the Game Asset Provider'
export const EditPaymentSubProvider         : string = 'Edit the Payment Sub-Provider'
export const EditComplianceSubProvider      : string = 'Edit the Compliance Sub-Provider'
export const AddContractualPartner         : string = 'Add a new Contractual Partner'
export const EditContractualPartner        : string = 'Edit the Contractual Partner'
export const AddMaster                    : string = 'Add a new Master'
export const EditMaster                   : string = 'Edit the Master'
export const AddUser                       : string = 'Add a new User'
export const EditUser                      : string = 'Edit the User'
export const tGameProviders                : string = 'List of Game Providers'
export const tGameProvidersUsers           : string = 'List of Game Provider Users'
export const tOperators                    : string = 'List of Operators'
export const tOperatorsUsers               : string = 'List of Operator Users'
export const tOperators2                    : string = 'List of Site Owners'
export const tOperators2Users               : string = 'List of Site Owner Users'
export const tSites                        : string = 'List of Gaming Sites'
export const tSites2                        : string = 'List of Sites'
export const tSitesUsers                   : string = 'List of Gaming Site Users'
export const tSites2Users                   : string = 'List of Site Users'
export const tSystemUsers                  : string = 'List of System Users'
export const tPlayers                      : string = 'List of Players'
export const tGameAssetProviders           : string = 'List of Game Asset Providers'
export const tGameAssetProvidersUsers      : string = 'List of Game Asset Provider Users'
export const tComplianceProviders          : string = 'List of Compliance Providers'
export const tComplianceProvidersUsers     : string = 'List of Compliance Provider Users'
export const tComplianceSubProviders       : string = 'List of Compliance Sub-Providers'
export const tComplianceSubProvidersUsers  : string = 'List of Compliance Sub-Provider Users'
export const tPaymentProviders             : string = 'List of Payment Providers'
export const tPaymentProvidersUsers        : string = 'List of Payment Provider Users'
export const tPaymentSubProviders          : string = 'List of Payment Sub-Providers'
export const tPaymentSubProvidersUsers     : string = 'List of Payment Sub-Provider Users'
export const tMasterUsers           : string = 'List of Master Users'
export const tMasters                     : string = 'List of Masters'
export const tCategory                     : string = 'List of Categories'
export const tType                     : string = 'List of Types'
export const tLoyaltyFreeRounds            : string = 'List of Loyalty Free Rounds'
export const tLevels                       : string = 'List of Levels'
export const tAchievements                 : string = 'List of Points Programs'
export const tPlayerGroups                 : string = 'List of Player Reward Groups'
export const compulsoryFillOutAll          : string = 'It is compulsory to fill out all the fields below.'
export const compulsorySiteName            : string = 'The Gaming Site name field is compulsory.'
export const compulsoryOperator            : string = 'It is compulsory to select an Operator.'
export const compulsoryGameProvider        : string = 'It is compulsory to select a Game Provider.'
export const compulsoryCompanyName         : string = 'The Entity name field is compulsory.'
export const compulsoryUsername            : string = 'The Username field is compulsory.'
export const compulsoryPrice            : string = 'The Price field is compulsory.'
export const compulsoryLottery            : string = 'The Lottery field is compulsory.'
export const compulsoryPriceCurrency            : string = 'The Price Currency field is compulsory.'
export const compulsoryJackpotCurrency            : string = 'The Jackpot Currency field is compulsory.'
export const compulsoryJackpot            : string = 'The Jackpot field is compulsory.'
export const compulsoryImage            : string = 'The Image field is compulsory.'
export const compulsoryName            : string = 'The Name field is compulsory.'
export const compulsoryCurrencyCode            : string = 'The Currency code field is compulsory.'
export const compulsoryLanguageCode            : string = 'The Language code field is compulsory.'
export const compulsoryPassword            : string = 'The Password field is compulsory.'
export const compulsorySite            : string = 'Selecting The Site is compulsory.'
export const compulsoryCompany             : string = 'It is compulsory to select the '
export const compulsoryRole                : string = 'It is compulsory to assign a role.'
export const compulsoryActivity            : string = 'It is compulsory to set activity.'
export const compulsoryLevel               : string = 'The Level field is compulsory.'
export const compulsoryLoyaltyFreeRound    : string = 'The Loyalty Free Round field is compulsory.'
export const compulsoryAchievement         : string = 'The Points field is compulsory.'
export const compulsoryPlayerGroup         : string = 'The Player reward groups field is compulsory.'
export const compulsoryWagerPlanName       : string = 'The Wager Plan Name field is compulsory.'
export const compulsoryPointName       : string = 'The Point Name field is compulsory.'
export const compulsoryRule       : string = 'The Rule field is compulsory.'
export const compulsoryCategoryName       : string = 'The Category Name field is compulsory.'
export const compulsoryTypeName       : string = 'The Type Name field is compulsory.'
export const compulsoryGameCategory         : string = 'It is compulsory to select the Game category.'
export const compulsorySecurityAnswer               : string = 'The Security question answer field is compulsory.'
export const compulsoryEmail               : string = 'The Email address field is compulsory.'
export const compulsoryFirstName           : string = 'The First Name field is compulsory.'
export const compulsoryLastName            : string = 'The Last Name field is compulsory.'
export const pleaseSelectContractualPartner: string = 'Selecting Contractual Partner Type is compulsory.'
export const SelectContractualPartner      : string = 'Select Contractual Partner(s):'
export const pleaseSelectSubEntity         : string = 'Selecting Sub Entity Type is compulsory.'
export const pleaseSelectSubEntityType     : string = 'Select a Sub Entity Type Above'
export const pleaseSelectMaster            : string = 'Selecting Master is compulsory.'
export const pleaseSelectOperator          : string = 'Selecting Operator is compulsory.'
export const pleaseSelectGamingSite        : string = 'Selecting Gaming Site is compulsory.'
export const pleaseSelectLevel             : string = 'Please Select Level'
export const compulsoryPerson              : string = 'The Main Contact Person Name field is compulsory.'
export const compulsoryContractualPartner  : string = 'The Contractual Partner field is compulsory.'
export const compulsoryMaster             : string = 'The Master field is compulsory.'
export const compulsoryContractualPartnerID: string = 'It is compulsory to select a Contractual Partner.'
export const compulsorySubEntity           : string = 'The Sub Entity field is compulsory.'
export const GameProviderUser              : string = 'Game Provider User'
export const OperatorUser                  : string = 'Operator User'
export const SystemUser                    : string = 'System User'
export const SiteUser                      : string = 'Gaming Site User'
export const GameAssetProviderUser         : string = 'Game Asset Provider User'
export const ComplianceProviderUser        : string = 'Compliance Provider User'
export const ComplianceSubProviderUser     : string = 'Compliance Sub-Provider User'
export const PaymentProviderUser           : string = 'Payment Provider User'
export const PaymentSubProviderUser        : string = 'Payment Sub-Provider User'
export const AffiliateSiteUser             : string = 'Affiliate Site User'
export const aePlayer           : string = 'Add/Edit a Player'
export const vPlayer            : string = 'View Players'
export const rContractualPartner           : string = 'Add/Edit a Contractual Partner'
export const rSubEntity                    : string = 'Add/Edit a Sub Entity'
export const rMaster                      : string = 'Add/Edit a Master'
export const rSite                         : string = 'Manage a Gaming Site'
export const saveChanges                   : string = 'Save changes'
export const updateChanges                 : string = 'Update Changes'
export const discardChanges                : string = 'Discard changes'
export const vContractualPartner            : string = 'View Contractual Partners'
export const vSite                         : string = 'View Gaming Sites'
export const vGameAssetProvider            : string = 'View Game Asset Providers'
export const vComplianceSubProvider        : string = 'View Compliance Sub-Providers'
export const vPaymentSubProvider           : string = 'View Payment Sub-Providers'
export const cLevel                        : string = 'Add/Edit a Level'
export const cSubLevel                        : string = 'Add/Edit a Sub-Level'
export const vLevel                        : string = 'View Levels'
export const vSubLevel                        : string = 'View Sub-Levels'
export const vCategory                     : string = 'View Categories'
export const cCategory                     : string = 'Add/Edit a Category'
export const vType                     : string = 'View Types'
export const cType                     : string = 'Add/Edit a Type'
export const cLoyaltyFreeRound             : string = 'Add/Edit a Loyalty Free Round'
export const vLoyaltyFreeRound             : string = 'View Loyalty Free Round'
export const cRule                  : string = 'Add/Edit Rule'
export const cAchievement                  : string = 'Add/Edit Points Program'
export const vAchievement                  : string = 'View Points'
export const vRule                  : string = 'View Rules'
export const cPlayerGroup                  : string = 'Add/Edit a Player Reward Group'
export const vPlayerGroup                  : string = 'View Player Reward Groups'
export const cGameProviderUser             : string = 'Add/Edit a Game Provider User'
export const cOperatorUser                 : string = 'Add/Edit an Operator User'
export const cSiteUser                     : string = 'Add/Edit a Gaming Site User'
export const cSystemUser                   : string = 'Add/Edit a System User'
export const cPlayer                       : string = 'Add/Edit a Player'
export const addPlayer                     : string = 'Add a new Player'
export const editPlayer                    : string = 'Edit the Player'
export const addRewardShopItem             : string = 'Add a new Reward Shop Item'
export const editRewardShopItem            : string = 'Edit the Reward Shop Item'
export const addLotteryItem                : string = 'Add a new Lottery Item'
export const editLotteryItem               : string = 'Edit the Lottery Item'
export const addTournamentItem             : string = 'Add a new Tournament'
export const editTournamentItem            : string = 'Edit the Tournament'
export const addCalendarItem             : string = 'Add a new Calendar Day'
export const editCalendarItem            : string = 'Edit the Calendar Day'
export const addCurrency             : string = 'Add a new Currency'
export const addLanguage             : string = 'Add a new Language'
export const addBanner                 : string = 'Add a new Banner'
export const addGames                 : string = 'Add a new Game'
export const editCurrency            : string = 'Edit the Currency'
export const editLanguage            : string = 'Edit the Language'
export const editBanner            : string = 'Edit the Banner'
export const editGames              : string = 'Edit the Game'
export const editMainLanguage            : string = 'Edit the Main Language'
export const editMainCurrency            : string = 'Edit the Main Currency'
export const LastDateCurrency_ : string = 'currency rates fetched last time on '
export const cMasterUser                   : string = 'Add/Edit a System User'
export const cGameAssetProviderUser        : string = 'Add/Edit a Game Asset Provider User'
export const cComplianceProviderUser       : string = 'Add/Edit a Compliance Provider User'
export const cComplianceSubProviderUser    : string = 'Add/Edit a Compliance Sub-Provider User'
export const cPaymentProviderUser          : string = 'Add/Edit a Payment Provider User'
export const cPaymentSubProviderUser       : string = 'Add/Edit a Payment Sub-Provider User'
export const cAffiliateSiteUser            : string = 'Add/Edit an Affiliate Site User'
export const vGameProviderUser             : string = 'View Game Provider Users'
export const vOperatorUser                 : string = 'View Operator Users'
export const vSiteUser                     : string = 'View Gaming Site Users'
export const vSystemUser                   : string = 'View System Users'
export const vMasterUser                   : string = 'View Master Users'
export const vMasterUsers                  : string = 'View Master Users'
export const User                          : string = 'User'
export const playerGroup                   : string = 'Player Reward Group'
export const gameProvider                  : string = 'Game Provider'
export const operator                      : string = 'Operator'
export const master                        : string = 'Master'
export const site                          : string = 'Gaming Site'
export const operator2                      : string = 'Site Owner'
export const site2                          : string = 'Site'
export const gameAssetProvider             : string = 'Game Asset Provider'
export const complianceProvider            : string = 'Compliance Provider'
export const complianceSubProvider         : string = 'Compliance Sub-Provider'
export const paymentProvider               : string = 'Payment Provider'
export const paymentSubProvider            : string = 'Payment Sub-Provider'
export const affiliateSite                 : string = 'Affiliate Site'
export const sites                         : string = 'sites'
export const level                         : string = 'level'
export const Level                         : string = 'Level'
export const LevelAndSublevel              : string = 'Level & Sub-Level'
export const AnyLevel                      : string = 'Any Level'
export const achievement                   : string = 'point'
export const Achievement                   : string = 'Point'
export const GameProvider                  : string = 'Game provider'
export const Operator                      : string = 'Operator'
export const Site                          : string = 'Gaming Site'
export const ComplianceProvider            : string = 'Compliance Provider'
export const ComplianceSubProvider         : string = 'Compliance Sub-Provider'
export const PaymentProvider               : string = 'Payment Provider'
export const PaymentSubProvider            : string = 'Payment Sub-Provider'
export const AffiliateSite                 : string = 'Affiliate Site'
export const Affiliate                     : string = 'Affiliate'
export const GameProviderQ                 : string = 'Game Provider?'
export const SiteQ                         : string = 'Gaming Site?'
export const Site2Q                        : string = 'Site?'
export const SiteGamingSiteQ               : string = 'Gaming Site/Site?'
export const SiteCloneQ                    : string = 'Level Structure Site destination?'
export const OperatorQ                     : string = 'Operator?'
export const Operator2Q                     : string = 'Site Owner?'
export const RoleQ                         : string = 'Role?'
export const GameAssetProviderQ            : string = 'Game Asset Provider?'
export const ComplianceProviderQ           : string = 'Compliance Provider?'
export const ComplianceSubProviderQ        : string = 'Compliance Sub-Provider?'
export const PaymentProviderQ              : string = 'Payment Provider?'
export const PaymentSubProviderQ           : string = 'Payment Sub-Provider?'
export const AffiliateSiteQ                : string = 'Affiliate Site?'
export const AffiliateQ                    : string = 'Affiliate?'
export const ContractualPartnerTypeQ       : string = 'Contractual Partner Type?'
export const ContractualPartnerTypeAny     : string = 'Any Contractual Partner'
export const ContractualPartnerQ           : string = 'Contractual Partner?'
export const SubEntityTypeQ                : string = 'Sub Entity Type?'
export const SubEntityTypeAny              : string = 'Any Sub Entity Type'
export const SubEntity                     : string = 'Sub Entity'    
export const SubEntities                     : string = 'Sub Entities'    
export const SubEntityID                   : string = 'Sub Entity with this Site ID'
export const aeSubEntity                   : string = 'Select a Sub Entity type'
export const aeContractualPartner          : string = 'Select a Contractual Partner type'
export const sureToDelete                  : string = 'Are you sure that you want to delete the'
export const alreadyInDatabase             : string = 'with this name is already in the database.'
export const playerAlreadyInDatabase       : string = 'Player with this username is already in the database.'    
export const alreadyInDB                   : string = 'is already in the database.'
export const sameCharacteristics           : string = 'exactly the same characteristics'
export const withUsername                  : string = 'with username'
export const withName                  : string = 'with name'
export const records                       : string = 'records: '
export const showing                       : string = 'showing records: '
export const showingShort                  : string = 'showing: '
export const page                          : string = 'page: '
export const Player                        : string = 'player'
export const compulsory                    : string = 'Compulsory field'
export const compulsorys                   : string = 'Compulsory fields'
export const optional                      : string = 'Optional field'
export const optionals                     : string = 'Optional fields'
export const LevelDescriptionOptional      : string = 'Level description (optional)'
export const AchievementDescriptionOptional: string = 'Points Program description (optional)'
export const PlayerGroupsDescriptionOptional:string = 'Player Reward Group description (optional)'
export const EmptyDatabase                 : string = 'Empty Database'
export const NoSubLevelExists              : string = 'No Sub-Level saved'
export const Role                          : string = 'Role'
export const Super                         : string = 'Super User'
export const Support                       : string = 'Support'
export const Accounting                    : string = 'Accounting'

export const ReadOnly                      : string = 'Read-Only'
export const FreeRound                     : string = 'free-round'
export const FREERound                     : string = 'Free Round'
export const FREERoundNot                     : string = 'Not Free Round'
export const Activity                      : string = 'active'
export const ActivityDesc                  : string = 'Indicates whether the item in question should be active.'
export const mainCurrency                  : string = 'main currency'
export const mainLanguage                  : string = 'main language'
export const mainCurrencyDesc              : string = 'Indicates whether the currency in question is main currency.'
export const mainLanguageDesc              : string = 'Indicates whether the language in question is main language.'
export const frmc                          : string = 'fixed relation'
export const frmcDesc:string='Indicates whether the points should be converted automatically or via fixed relation.'
export const FreeRoundDesc: string = 'Indicates whether the item in question is a free-round item.'
export const ActivityExplained             : string = 
'Indicates whether the User should be active (default: Yes - green)'
export const buyable                      : string = 'buyable'
export const BuyableNot                      : string = 'Not Buyable'
export const Buyable                      : string = 'Buyable'
export const BuyableDesc: string = 'Indicates whether the item can be bought multiple times.'
export const ExampleDesc:string ='Calculate how many points would be assigned given the Bet Size Multiplier above.'
export const GGRMWalletExplained           : string = 
'Indicates whether the Operator uses a Real Money Wallet from GaminGenius® (default: No - red)'
export const GGRMWalletText                : string = 'GG RM:'
export const GGRMWalletTableTitle          : string = 'GG RM'
export const quantitySubEntities           : string = '# Sub Entities'
export const GGAffiliateSystemExplained    : string = 
'Indicates whether the Gaming Site uses a GaminGenius® Affiliate system (default: No - red)'
export const GGAffiliateSystemText         : string = 'GG AS:'
export const GGAffiliateSystemTableTitle   : string = 'GG AS'
export const Active                        : string = 'Active'
export const Inactive                      : string = 'Inactive'
export const SelectTheSites                : string = 'Select the sites'
export const SelectTheGames                : string = 'Select the games'
export const SetTheCurrencies              : string = 'Set the currencies'
export const select                        : string = 'select'
export const Select                        : string = 'Select'    
export const SelectAll                     : string = 'Select All'    
export const DeselectAll                   : string = 'Deselect All'    
export const SetCurrencies                 : string = 'Set currencies'
export const AssignPoints                  : string = 'Assign points and currencies'
export const toSetNewLevel                 : string = 'to set a new level'
export const toSetNewAchievement           : string = 'to set a new points threshold'
export const toSetNewPlayerGroup           : string = 'to set a new player reward group'
export const forSelectedOperator           : string = 'for selected Operator'
export const Cancel                        : string = 'Cancel'
export const Discard                       : string = 'Discard'
export const DiscardEverything             : string = 'Discard Everything'
export const Set                           : string = 'Set'
export const LevelNameCompulsory           : string = 'Level name (comp.)'
export const SubLevelNameCompulsory        : string = 'Sub-Level name'
export const LevelName                     : string = 'Level name'
export const SubLevelName                  : string = 'Sub-Level name'
export const LevelReq                      : string = 'Level req.'
export const SubLevelReq                   : string = 'Sub-Level req.'
export const LevelRequirement              : string = 'Level Requirement'
export const SubLevelRequirement           : string = 'Sub-Level Requirement'
export const PointType : string = 'Point type'
export const SubPointType : string = 'Sub-Point type'
export const Linear : string = 'Linear'
export const LinearDesc : string = 'Linear Points Multiplier'
export const BetSizeMultiplier : string = 'BSM'
export const BetSizeMultiplierDesc : string = 'Bet Size Multiplier'
export const AverageMultiplier : string = 'APBSM'
export const AverageType : string = 'APBS'
export const AverageTypeDesc : string = 'Average Player Bet Size Type'
export const AverageMultiplierDesc : string = 'Average Player Bet Size Multiplier'
export const AchievementName               : string = 'Points Program name'
export const PlayerGroupName               : string = 'Player Reward Group'
export const BetSizeMultiplierQ            : string = 'Bet Size Multiplier?'
export const ApplyBSM                      : string = 'Apply Bet Size Multiplier'
export const LinearMultiplierQ            : string = 'Linear Multiplier?'
export const BetSizeQ            : string = 'Bet Size?'
export const ApplyMultiplier                      : string = 'Apply Multiplier'
export const NoSites                       : string = 'No Gaming Sites in the system'
export const NoSitesSelected               : string = 'No Gaming sites selected at the moment'
export const maxDescriptionTableLength     : number = 13
export const maxNumberSitesPerOperator     : number = 1000
export const maxNumberGamesPerSite         : number = 1000
export const maxSitesNoModal               : number = 5
export const maxNumberCurrencies           : number = 32 + 1
export const definitionCurrency            : string = 
'Number of points that players get for each unit of currency (for automatic conversion select default currency)'
export const GlobalSettings: string = 'Global Settings'
export const GlobalSettingsShort: string = 'GS'
export const amountCurrency                : string = 'Amount to wager to obtain points'
export const set                 : string = 'set'
export const notSet                 : string = 'not set'
export const oneGameSelected               : string = '1 game'
export const moreGamesSelected             : string = ' games'
export const gamesNotSelected              : string = '0 games'
export const dateFrom                      : string = 'From (ymd)'
export const dateTo                        : string = 'To (ymd)'
export const timeFrom                      : string = 'From (hms)'
export const timeTo                        : string = 'To (hms)'
export const RequirementShort              : string = 'Req'
export const RewardShort                   : string = 'Rew'
export const PlayerGroup                   : string = 'Player group'
export const From                          : string = 'From'
export const To                            : string = 'To'
export const Close                         : string = 'Close'
export const SelectTheRequirements         : string = 'Select the Requirements'    
export const SelectTheRewards              : string = 'Select the Rewards'
export const Req1:string = 'Add/edit "Points Requirement" to qualify for the player group'
export const Req2:string = 'Add/edit "Levels Requirement" to qualify for the player group'
export const Req3:string = 'Add/edit "Games Log-in Requirement" to qualify for the player group'
export const Req4:string = 'Add/edit "Total Wager Requirement" to qualify for the player group'
export const Req5:string = 'Add/edit "Total Winning Requirement" to qualify for the player group'
export const Req6:string = 'Add/edit "Gamble Game Wager Requirement" to qualify for the player group'
export const Req7:string = 'Add/edit "Gamble Game Winning Requirement" to qualify for the player group'
export const Req8:string = 'Add/edit "Bonus Game Wager" to qualify for the player group'
export const Req9:string = 'Add/edit "Bonus Game Winning" to qualify for the player group'
export const Req10:string = 'Add/edit "Player Group(s) requirement" to qualify for the player group'
export const Req11:string = 'Add/edit "Low Balance Requirement" to qualify for the player group'
export const showRewards                   : string = 'Display the Rewards section'
export const showRequirements              : string = 'Display the Requirements section'
export const Rew1                          : string = 'Add/edit "Points rewards"'
export const Rew2                          : string = 'Select Mid Unit Page (MPU)'
export const Rew3                          : string = 'Add/edit "Levels rewards"'
export const Rew4                          : string = 'Add "Free Round Rewards"'
export const SelectDefaultCurrency         : string = 'Select default currency'
export const SelectDefaultLanguage         : string = 'Select default language'
export const SetDateFrom                   : string = 'Set the date from which the entry applies (compulsory).'
export const SetDateTo                     : string = 'Set the date to which the entry applies.'
export const compulsoryDateFrom            : string = 'It is compulsory to enter the "Date From" field.'
export const dateFromNotValid              : string = '"Date From" field is invalid (please enter as yyyy-mm-dd)!'
export const dateToNotValid                : string = '"Date To" field is invalid (please enter as yyyy-mm-dd)!'
export const Yes                           : string = 'Yes'
export const YesShort                      : string = 'Y'
export const No                            : string = 'No'
export const NoShort                       : string = 'N'
export const Reward :string = 'Reward'
export const setMultipleRewards            : string = 'Set multiple rewards'
export const MultipleReward                : string = 'Multiple reward'
export const Delay                         : string = 'Delay (points do not count after receiving a reward)'
export const setDelayDays                  : string = 'Set days to delay'
export const enterDelayDays                : string = 'Days?'
export const setDelayHours                 : string = 'Set hours to delay'
export const enterDelayHours               : string = 'Hours?'
export const DelayHasToBeSpecified         : string = 'Delay has to be specified'
export const NumberOfPointsNeeded          : string = 'Number of points needed'
export const PointsAfterValid:string = 'Player reward group shall take into account points earned while being valid'
export const PointsProgram                 : string = 'Points Program'
export const AchievementQ                  : string = 'Points Program?'
export const LevelRequirementQ             : string = 'Level Requirement?'
export const SubLevelRequirementQ          : string = 'Sub-Level Requirement?'
export const LevelQ                        : string = 'Level?'
export const SubLevelQ                     : string = 'Sub-Level?'
export const PlayerGroupQ                  : string = 'Player group?'
export const Logins                        : string = 'Logins'
export const NumberOfLoginsRequired        : string = 'Number Of logins required'
export const MaxLoginsPerDay               : string = 'Max logins per day'
export const MaxLogins                     : string = 'Max logins'
export const MinHours                      : string = 'Min hours'
export const MinNumberHoursBetweenTwoLogins: string = 'Min number of hours needed between two consecutive logins'
export const DateFromWhenLoginCountStarted : string = 'Date from when the login count is started'
export const StartDate                     : string = 'Start date'
export const TotalAmountWagered            : string = 'Total amount needed to be wagered'   
export const NumberOfTimesWagered          : string = 'Number of times the player needs to wager'   
export const DateFromWhenWagerCountStarted : string = 'Date from when the wager count is started'
export const NumberTimes                   : string = 'Number of times'
export const LowBalance                    : string = 'Low Balance'
export const TotalAmountWinning            : string = 'Amount needed to be won'   
export const NumberOfTimesWinning          : string = 'Number of times the player needs to win'   
export const DateFromWhenWinningCountStarted:string = 'Date from when the winning count is started'
export const TotalAmountGamble             : string = 'Total amount needed to be wagered'   
export const NumberOfTimesGamble           : string = 'Number of times the player needs to wager in a gamble'   
export const DateFromWhenGambleCountStarted: string = 'Date from when the gamble wager count is started'
export const TotalAmountGambleW            : string = 'Total amount needed to be won'   
export const NumberOfTimesGambleW          : string = 'Number of times the player needs to win from gamble games'   
export const DateFromWhenGambleWCountStarted:string = 'Date from when the gamble winning count is started'
export const TotalAmountBonus              : string = 'Total amount needed to be wagered'   
export const NumberOfTimesBonus            : string = 'Number of times the player needs to wager in a bonus game'   
export const DateFromWhenBonusCountStarted : string = 'Date from when the bonus wager count is started'
export const TotalAmountBonusW             : string = 'Total amount needed to be won'   
export const NumberOfTimesBonusW           : string = 'Number of times the player needs to win from bonus games'   
export const DateFromWhenBonusWCountStarted: string = 'Date from when the bonus winning count is started'
export const LogicAnd                      : string = 'And'
export const LogicOr                       : string = 'Or'
export const LogicNot                      : string = 'Not'
export const LogicQ                        : string = 'Logic?'
export const NumberOfTimesPlayerCanReceieveAchievement: string = 
'Number of times a player can receieve an achievement.'
export const Times                         : string = 'Times'
export const CurrencyQ                     : string = 'Currency'
export const NumberOfRoundsAwarded         : string = 'Number of rounds that will be awarded'
export const NumberRounds                  : string = 'Number of rounds'
export const TotalAmount                   : string = 'Total amount'
export const SelectLanguage                : string = 'Select language'
export const SelectFlag                    : string = 'Select flag'
export const SelectCurrency                : string = 'Select currency'
export const FreeRoundActivated            : string = 'Free round activated'
export const FreeRoundsTypeQ               : string = 'Free rounds type?'
export const FreeRoundsType                : string = 
'Indicates the different options how a Free round can be rewarded to the players.'
export const BaseGameLaunch                : string = 'Base game at game launch'
export const BaseGameRT                    : string = 'Base game in real time (offline convert)'
export const BaseGameRTNOT                 : string = 'Base game in real time (not if offline)'
export const BonusGameLaunch               : string = 'Bonus game at game launch'
export const BonusGameRT                   : string = 'Bonus game in real time (offline convert)'
export const BonusGameRTNOT                : string = 'Bonus game in real time (not if offline)'
export const PlayerLevelType               : string = 'Depending on Player Level type ->'
export const GameLevelType                 : string = 'Depending on Game Level type ->'
export const BetLevel                      : string = 'Bet level'
export const CoinValue                     : string = 'Coin value'
export const NumLines                      : string = 'Number of lines'
export const GameIds                       : string  = 'Games id'
export const GameIdsLong                   : string  = 
'The Games id for the games that are valid in the given free rounds reward.'
export const Priority                      : string  =  'Priority'
export const PriorityLong                  : string  = 
'Indicates the priority should a player have more than one valid free round reward.'
export const WageringReqFactor             : string  = 'Wagering required factor'
export const WageringReqFactorLong         : string  = 
'This Factor times the win amount needs to be wagered before the winning can be paid out.'
export const WageringReqAmount             : string  = 'Wagering required amount'
export const WageringReqAmountLong         : string  = 
'This Amount times the win amount needs to be wagered before the winning can be paid out.'
export const WagerRequirementGaminGenius   : string  = 
'Indicates who will take care of the wagering requirement calculation (Factor/Amount).'
export const WagerRequirementGaminGeniusYes: string  = ' - Gamingenius'
export const WagerRequirementGaminGeniusNo : string  = ' - Account/Wallet platform'
export const DelayHours                    : string  = 'Delay - hours'
export const DelayDays                     : string  = 'Delay - days'
export const ValidAfterTriggered           : string  = 
'Indicates how much time will the reward be available to the players after it has been triggered.'
export const ValidAfterTriggeredDays       : string  = 
'Indicates how many days will the reward be available to the players after it has been triggered.'
export const ValidAfterTriggeredHours      : string  = 
'Indicates how many hours will the reward be available to the players after it has been triggered.'
export const ValidAfterActivation          : string  = 
'Indicates how much time will the reward be available to the players after it has been activated.'
export const ValidAfterActivationDays      : string  = 
'Indicates how many days will the reward be available to the players after it has been activated.'
export const ValidAfterActivationHours     : string  = 
'Indicates how many hours will the reward be available to the players after it has been activated.'
export const ValidAfterFirstDefer          : string  = 
'Indicates how much time will the reward be available to the players after it has been first deffered.'
export const ValidAfterFirstDeferDays      : string  = 
'Indicates how many days will the reward be available to the players after it has been first deffered.'
export const ValidAfterFirstDeferHours     : string  = 
'Indicates how many hours will the reward be available to the players after it has been first deffered.'
export const Currency                      : string  = 'Currency'
export const Language                      : string  = 'Language'
export const Banner                        : string  = 'Banner'
export const Master                       : string  = 'Master'
export const game                          : string  = 'game'
export const games                         : string  = 'games'
export const Games                         : string  = 'Games'
export const AutoInclude                   : string ='Auto-Include'
export const selectUserType                : string  = 'Select User Type'
export const aeSystemUsers                 : string  = 'Manage Admin Users (System)'
export const aeOperatorUsers               : string  = 'Manage Admin Users (Operator)'
export const aeSiteUsers                   : string  = 'Manage Admin Users (Gaming Site)'
export const aeSite2Users                   : string  = 'Manage Admin Users (Site)'    
export const aeOperator2Users               : string  = 'Manage Admin Users (Site Owner)'
export const aeGameProviderUsers           : string  = 'Manage Admin Users (Game Provider User)'
export const aeGameAssetProviderUsers      : string  = 'Manage Admin Users (Game Asset Provider)'
export const aePaymentProviderUsers        : string  = 'Manage Admin Users (Payment Provider)'
export const aePaymentSubProviderUsers     : string  = 'Manage Admin Users (Payment Sub-Provider)'
export const aeComplianceProviderUsers     : string  = 'Manage Admin Users (Compliance Provider)'
export const aeComplianceSubProviderUsers  : string  = 'Manage Admin Users (Compliance Sub-Provider)'
export const aeMasterUsers                 : string  = 'Manage Admin Users (Master)'
export const aeComplianceSubProvider       : string  = 'Add/Edit a Compliance Sub-Provider'
export const aeGamingSite                  : string  = 'Add/Edit a Gaming Site'
export const GamingSite                    : string  = 'Gaming Site'
export const aeGameAssetProvider           : string  = 'Add/Edit a Game Asset Provider'
export const aePaymentSubProvider          : string  = 'Add/Edit a Payment Sub-Provider'
export const aeMaster                      : string  = 'Add/Edit a Master'
export const aeOperator                : string  = 'Add/Edit an Operator'
export const vOperator                 : string = 'View Operators'
export const aeGameProvider                : string  = 'Add/Edit a Game Provider'
export const vGameProvider                 : string = 'View Game Providers'
export const aeSiteOwner                : string  = 'Add/Edit an Site Owner'
export const vSiteOwner                 : string = 'View Site Owners'
export const aePaymentProvider                : string  = 'Add/Edit a Payment Provider'
export const vPaymentProvider                 : string = 'View Payment Providers'
export const aeComplianceProvider                : string  = 'Add/Edit a Compliance Provider'
export const vComplianceProvider                 : string = 'View Compliance Providers'
export const AddGameProvider           : string = 'Add a new Game Provider'
export const EditGameProvider          : string = 'Edit the Game Provider'
export const AddOperator           : string = 'Add a new Operator'
export const EditOperator          : string = 'Edit the Operator'
export const AddSiteOwner           : string = 'Add a new Site Owner'
export const EditSiteOwner          : string = 'Edit the Site Owner'
export const SiteOwner: string = 'Site Owner'
export const AddPaymentProvider           : string = 'Add a new Payment Provider'
export const EditPaymentProvider          : string = 'Edit the Payment Provider'
export const AddComplianceProvider           : string = 'Add a new Compliance Provider'
export const EditComplianceProvider          : string = 'Edit the Compliance Provider'
export const aeSystemUser                  : string = 'Add/Edit a System User'
export const aeOperatorUser                : string = 'Add/Edit an Operator User'
export const aeSiteUser                    : string = 'Add/Edit a Gaming Site User'
export const aeOperator2User                : string = 'Add/Edit a Site Owner User'
export const aeSite2User                    : string = 'Add/Edit Site User'
export const aeGameProviderUser            : string = 'Add/Edit a Game Provider User'
export const aeGameAssetProviderUser       : string = 'Add/Edit a Game Asset Provider User'
export const aePaymentProviderUser         : string = 'Add/Edit Payment Provider User'
export const aePaymentSubProviderUser      : string = 'Add/Edit Payment Sub-Provider User'
export const aeComplianceProviderUser      : string = 'Add/Edit Compliance Provider User'
export const aeComplianceSubProviderUser   : string = 'Add/Edit Compliance Sub-Provider User'
export const aeMasterUser                  : string = 'Add/Edit a Master User'
export const addSystemUser                  : string = 'Add a new System User'
export const addOperatorUser                : string = 'Add a new Operator User'
export const addSiteUser                    : string = 'Add a new Gaming Site User'
export const addOperator2User                : string = 'Add a new Site Owner User'
export const addSite2User                    : string = 'Add a new Site User'
export const addGameProviderUser            : string = 'Add a new Game Provider User'
export const addGameAssetProviderUser       : string = 'Add a new Game Asset Provider User'
export const addPaymentProviderUser         : string = 'Add a new Payment Provider User'
export const addPaymentSubProviderUser      : string = 'Add a new Payment Sub-Provider User'
export const addComplianceProviderUser      : string = 'Add a new Compliance Provider User'
export const addComplianceSubProviderUser   : string = 'Add a new Compliance Sub-Provider User'
export const addMasterUser                   : string = 'Add a new Master User'
export const editSystemUser                  : string = 'Edit the System User'
export const editOperatorUser                : string = 'Edit the Operator User'
export const editSiteUser                    : string = 'Edit the Gaming Site User'
export const editOperator2User                : string = 'Edit the Site Owner User'
export const editSite2User                    : string = 'Edit the Site User'
export const editGameProviderUser            : string = 'Edit the Game Provider User'
export const editGameAssetProviderUser       : string = 'Edit the Game Asset Provider User'
export const editPaymentProviderUser         : string = 'Edit the Payment Provider User'
export const editPaymentSubProviderUser      : string = 'Edit the Payment Sub-Provider User'
export const editComplianceProviderUser      : string = 'Edit the Compliance Provider User'
export const editComplianceSubProviderUser   : string = 'Edit the Compliance Sub-Provider User'
export const editMasterUser                  : string = 'Edit the Master User'
export const viewSystemUser                  : string = 'View the System User'
export const viewOperatorUser                : string = 'View the Operator User'
export const viewSiteUser                    : string = 'View the Gaming Site User'
export const viewGameProviderUser            : string = 'View the Game Provider User'
export const viewGameAssetProviderUser       : string = 'View the Game Asset Provider User'
export const viewPaymentProviderUser         : string = 'View the Payment Provider User'
export const viewPaymentSubProviderUser      : string = 'View the Payment Sub-Provider User'
export const viewComplianceProviderUser      : string = 'View the Compliance Provider User'
export const viewComplianceSubProviderUser   : string = 'View the Compliance Sub-Provider User'
export const viewMasterUser                  : string = 'View the Master User'
export const MasterUser                        : string = 'Master User'
export const MasterQ                        : string = 'Master?'
export const vSystemUsers                  : string  = 'View System Users'
export const vGameProviderUsers            : string  = 'View Game Provider Users'
export const vOperatorUsers                : string  = 'View Operator Users'
export const vSiteUsers                    : string  = 'View Gaming Site Users'
export const vOperator2Users                : string  = 'View Site Owner Users'
export const vSite2Users                    : string  = 'View Site Users'
export const vGameAssetProviderUsers       : string  = 'View Game Asset Provider Users'
export const vComplianceProviderUsers      : string  = 'View Compliance Provider Users'
export const vComplianceSubProviderUsers   : string  = 'View Compliance Sub-Provider Users'
export const vPaymentProviderUsers         : string  = 'View Payment Provider Users'
export const vPaymentSubProviderUsers      : string  = 'View Payment Sub-Provider Users'
export const vAffiliateSiteUsers           : string  = 'View Affiliate Site Users'
export const vAffiliateSite                : string  = 'View Affiliate Sites'
export const vGamingSite                   : string  = 'View Gaming Sites'
export const vMaster                      : string  = 'View Masters'
export const Save                          : string  = 'Save'
export const MainContactPerson             : string  = 'Main Contact Person'
export const StreamingTableBtnEdit         : string  = 'Edit the record'
export const StreamingTableBtnDelete       : string  = 'Delete the record'
export const pSiteID                       : string = 'Site ID'
export const compulsorySiteIDMinLength     : string = 'Site ID should have at least 8 characters!'
export const compulsorySiteIDMaxLength     : string = 'Site ID should have at most 12 characters!'
export const SiteIDTitle                   : string = 'Site ID'
export const OperatorParentheses           : string = ' (Operator)'
export const GameProviderParentheses       : string = ' (Game Provider)'
export const PaymentProviderParentheses    : string = ' (Payment Provider)'
export const ComplianceProviderParentheses : string = ' (Compliance Provider)'
export const BrandOwnerParentheses : string = ' (Site Owner)'
export const aeLevel                     : string = 'Add/Edit a Level'
export const aeSubLevel                     : string = 'Add/Edit a Sub-Level'
export const AddLevel                     : string = 'Add a new Level'
export const CopyLevelStructure              : string = 'Copy Level Structure'
export const EditLevel                     : string = 'Edit the Level'
export const AddSubLevel                     : string = 'Add a new Sub-Level'
export const EditSubLevel                     : string = 'Edit the Sub-Level'
export const aeLoyaltyFreeRound                     : string = 'Add/Edit a Loyalty Free Round'
export const AddLoyaltyFreeRound                     : string = 'Add a new Loyalty Free Round'
export const EditLoyaltyFreeRound                     : string = 'Edit the Loyalty Free Round'
export const aePlayerRewardGroup                     : string = 'Add/Edit a Player Reward Group'
export const AddPlayerRewardGroup                     : string = 'Add a new Player Reward Group'
export const EditPlayerRewardGroup                     : string = 'Edit the Player Reward Group'
export const Hierarchy                     : string = 'Level'
export const SubLevels                     : string = 'Sub-Levels'
export const SubLevelsDesc                 : string = 'Number of Sub-Levels that pertain to a Level'
export const SubLevelsShort                : string = '#SL'
export const SubLevelAverageValue           : string = 'Sub-Level Average Value'
export const SLAV: string = 'SLAV'
export const STAG: string = 'STAG'
export const StagnationDesc                 : string = 'Factor, with which leveling up stagnates with time.'
export const Stagnation                     : string = 'Stagnation'
export const aeAchievement                  : string = 'Add/Edit Points Program'
export const AddAchievement                 : string = 'Add new Points Program'
export const EditAchievement                : string = 'Edit the Points Program'
export const frbet: string = 'FR Bet'
export const frwins: string = 'FR Wins'
export const nr_players: string = '# players'
export const maincurrency: string = 'Main'
export const amountLocal = 'Represent amounts in local currency if possible'
export const amountEUR = 'Represent amounts in EUR'
export const amountUSD = 'Represent amounts in USD'
export const SelectOperator = 'Select Operator'
export const SelectPointProgram = 'Select Point Program'
export const PointProgramQ = 'Point Program?'
export const SelectCategory = 'Select Category'
export const SelectTranslation = 'Select Translation'
export const CategoryQ = 'Category?'
export const SubCategoryQ = 'Subcategory?'
export const Category = 'Category'
export const Translation = 'Translation'
export const Translations = 'Translations'
export const SiteSettings = 'Site Settings'
export const SelectType = 'Select Type'
export const TypeQ = 'Type?'
export const Type = 'Type'
export const SelectReward = 'Select Reward'
export const RewardQ = 'Reward?'
export const SelectMaster = 'Select Master'
export const SelectData = 'Select Data'
export const SelectDataCategory = 'Select Data Category'
export const SelectDataItem = 'Select Data Item'
export const SelectDataVariable = 'Select Data Variable'
export const SelectDataTop = 'Select Data Top'
export const SelectPointType = 'Select Point Type'
export const SelectSubPointType = 'Select Sub-Point Type'
export const PointTypeQ = 'Point Type?'
export const SubPointTypeQ = 'Sub-Point Type?'
export const SelectSite = 'Select Gaming Site'
export const SelectSite_ = 'Select Site'
export const SelectSiteGamingSite = 'Gaming Site/Site?'
export const SelectSiteClone = 'Select Gaming Site to copy levels'    
export const SelectLevel = 'Select Level'
export const SelectSubLevel = 'Select Sub-Level'
export const SubLevel = 'Sub-Level'
export const SubLevelS = 'Sub-Level(s)'
export const Insert = 'Insert'
export const Copy = 'Copy'
export const cloneLevelStructure = 
'Copy the Level Structure from the Source Site at the top of the page to the Destination Site specified on the left.'
export const World = 'World'
export const Africa = 'Africa'
export const Europe = 'Europe'
export const NorthAmerica = 'North America'
export const CentralAmerica = 'Central America'
export const SouthAmerica = 'South America'
export const Asia = 'Asia'
export const AustraliaOceania = 'Australia & Oceania'
export const BrandOwner = 'Site Owner'
export const BrandOwnerQ = 'Site Owner?'
export const tBrandOwners = 'List of Site Owners'
export const OperatorBrandOwnerQ = 'Operator/Site Owner?'
export const aeSite                     : string = 'Add/Edit a Site'
export const AddSite                     : string = 'Add a new Site'
export const EditSite                     : string = 'Edit the Site'
export const SitesDetails: string = 'Selected Sites:'
export const OP : string = 'OP: '
export const BO : string = 'SO: '
export const SelectBrandOwner: string = 'Select Site Owner'
export const SelectOperatorBrandOwner: string = 'Operator/Site Owner?'
export const levelSubLevel: string = 'Level or Sub-Level'
export const parentOnly: string = 'Level Only'
export const subOnly: string = 'Sub-Level Only'
export const tournamentsOnly: string = 'tournaments-only'
export const TournamentsOnly: string = 'Tournaments Only'
export const TournamentsOnlyDesc: string = 
'Indicates whether the wager plan shall be valid only for tournament games.'
export const TournamentsOnlyShort: string = 'TO'
export const SelectGameCategory: string = 'Game Category?'
export const SelectAnyGameCategory: string = 'Any Game Category'
export const Roulette: string = 'Roulette'
export const TableGames: string = 'Table Games'
export const DepositOption: string = 'Deposit Option'
export const BlackJack = 'BlackJack'
export const Videoslots = 'Videoslots'
export const Slots = 'Slots'
export const Videopoker = 'Videopoker'
export const OddsFixed = 'Odds Fixed'
export const OddsLive = 'Odds Live' 
export const Poker = 'Poker' 
export const Bingo = 'Bingo' 
export const AutomaticInclusion = 'auto-include'
export const AutomaticInclusionDesc = 
'Indicates whether the new games that belong to the category shall be automatically included.' 
export const WagerPlans = 'Wager Plans'
export const WagerPlansDesc = 
'Toggles between the List of Points Programs and the List of Wager Plans pertaining to selected Point Program.'
export const PointsDesc = 
'Toggles between the List of Points Programs and the List of Points pertaining to selected Point Program.'
export const aeWagerPlan = 'Add/Edit a Wager Plan'
export const aePoint = 'Add/Edit a Point'
export const selectWagerPlan = 'Select Wager Plan'
export const selectPoint = 'Select Point'
export const WagerPlanQ = 'Wager Plan?'
export const PointQ = 'Point?'
export const Point = 'Point?'
export const WagerPlanName = 'Wager Plan Name'
export const PointName = 'Point Name'
export const AddWagerPlan = 'Add a new Wager Plan'
export const EditWagerPlan = 'Edit the Wager Plan' 
export const AddWagerPlanDesc = 'Add a new Wager Plan for a given Point Program.'
export const EditWagerPlanDesc = 'Edit the Wager Plan for a given Point Program.'
export const AddPoint = 'Add a new Point'
export const EditPoint = 'Edit the Point' 
export const AddRule = 'Add a new Rule'
export const EditRule = 'Edit the Rule' 
export const AddPointDesc = 'Add a new Point for a given Point Program.'
export const EditPointDesc = 'Edit the Point for a given Point Program.'
export const WagerPlan = 'Wager Plan'
export const Assign = 'Assign'
export const AssignGames  = 'Assign Games'
export const AssignCurrency  = 'Assign Points'
export const AssignGamesDesc = 'Assign games for which the Wager Plan is valid.'
export const AssignCurrencyDesc = 'Assign how much a point is valid in each currency.'
export const tWagerPlans:string = 'List of Wager Plans'
export const tRules:string = 'List of Rules'
export const None:string = 'None'
export const AnyCategory:string = 'Any category'
export const ValueCannotBe0:string = 'Value cannot be zero.'
export const GamesNr:string = 'Games #'
export const BetslipReqs:string = 'Betslip Reqs'
export const BetslipRequirements:string = 'Betslip Requirements'
export const BetslipReqsDesc:string = 'Set the Betslip Requirements for the given Wager Plan.'
export const CloseEditMode:string = 'Edit Mode'
export const CloseEditModeDesc:string = 'Close Edit Mode'
export const SelectOddsType:string = 'Select Odds Type'
export const GreaterThan:string = 'Greater Than'
export const GreaterThanEqual:string = 'Greater Than or Equal To'
export const Equal:string = 'Equal To'
export const LessThanEqual:string = 'Less Than or Equal To'
export const LessThan:string = 'Less Than'
export const GREATER_THAN: number = 1
export const GREATER_THAN_OR_EQUAL_TO: number = 2
export const EQUAL_TO: number = 3
export const LESS_THAN_OR_EQUAL_TO: number = 4
export const LESS_THAN: number = 5
export const Odds:string = 'Odds'
export const OddsDesc:string = 'Count all the bets that pertain to this odds threshold'
export const OddsType:string = 'Odds Type'
export const LoyaltyFreeRound:string = 'Loyalty Free Round'
export const LoyaltyFreeRoundName:string = 'Loyalty Free Round Name'
export const Recurring: string = 'recurring'
export const RecurringDesc: string = 
'Indicates whether the counter is reset when a Loyalty Free Rounds has been rewarded'
+ ' and it starts to count immediately after the reward.'
export const RecurringTitle:string = 'Rec.'
export const RecurringTournament:string = 'Recurring Tournament'
export const WAGER_PLAN:number = 0
export const LOYALTY_FREE_ROUND:number = 1
export const PLAYER_GROUP:number = 2
export const GG_RM : boolean = true
export const noGames:string = 'No games were found on given criteria.'
export const FrbReq:string = '# req.'
export const FrbReqDesc:string = 'Number of spins that are required to release the award.'
export const FrbReqDesc2:string = 'Number of bets that are required to release the award.'
export const FrbReqTitle = '# Req.'
export const FrbReqMin:string = 'min req.'
export const FrbReqMinDesc:string = 'The minimum amount per bet to Qualify.'
export const FrbReqMinTitle = 'Min Req.'
export const FrbReqMinOddsDesc:string = 'The minimum odds to Qualify'
export const FrbReqMinOddsTitle = 'Min Odds'
export const FrbReqMinOddsPlaceholder = ''
export const FrbRew:string = '# reward'
export const FrbRewDesc:string = 'Number of spins awarded when the requirement has been fulfilled.'
export const FrbRewTitle = '# Rew.'
export const FrbRewAmount:string = 'reward amount'
export const FrbRewAmountDesc:string = 
'Not Compulsory – If not set, the amount that the player had when being rewarded the free rounds shall be used.'
export const FrbRewAmountTitle = '# Rew. Amount'
export const RecurringFromGameLaunchOnly:string = 'recurring @ launch'
export const RecurringFromGameLaunchOnlyDesc:string = 
'If this option is selected, the recurring free rounds will be awarded, but only one time per “remote_session_id”.'
export const RecurringFromGameLaunchOnlyTitle = 'Rec. @ launch'
export const ResetGameLaunch:string = 'reset @ launch'
export const ResetGameLaunchDesc:string = 
'If Green, then the counter shall be set to zero per each unique RemoteSessionId.' + 
' If Red, then the counter shall “carry over”, i.e. old Spins or Bets will be used for each new RemoteSessionId.'
export const ResetGameLaunchTitle = 'Reset @ launch'
export const PlayerCap:string = 'player cap'
export const PlayerCapDesc:string = 
'This means the Max number of times a player can be awarded Free Rounds based in this program.' + 
' The value “0” indicates infinity'
export const PlayerCapTitle:string = 'Cap'
export const cloneSiteSelectSource: string = 
'Please select the source (from which site should the level structure be copied)'
export const cloneSiteSelectDestination: string = 
'Please select the destination (to which site should the level structure be copied)'
export const noDataAtSiteSource:string = 
'Site Level Structure was not copied, as there are no Levels inserted for the given Site'
export const cloneSiteSuccess:string = 'Site Level Structure was successfully copied.'
export const cloneSameSourceDestination:string = 
'Aborting, because source site and destination site should not be the same.'
export const CopyLevelStructureFromExistingSite:string = 'Copy Level Structure'
export const CopyLevelStructureFromExistingSiteDesc:string = 
'If enabled it gives the possibility to copy the Level Structure from one site to another.'
export const Settings:string = 'Settings'
export const Logout:string = 'Logout'
export const SelectTimeFrame:string  = 'Select Time Frame'
export const SelectTimePeriod:string  = 'Select Time Period'
export const SelectReport:string  = 'Select Report'
export const SelectReportCategory:string  = 'Select Report Category'
export const QuarterShort:string = 'Q'
export const anyPeriod:string = 'Any Period'
export const Start: string = 'Start'
export const End: string = 'End'
export const AnyTimeFramePeriod:string = 'Any Time Frame or Period'
export const Today = 'Today'
export const Yesterday = 'Yesterday'
export const ThisWeek = 'This Week'
export const LastWeek = 'Last Week'
export const Last7Days = 'Last 7 days'
export const ThisMonth = 'This Month'
export const LastMonth = 'Last Month'
export const CurrentQuarter = 'Current Quarter'
export const FirstQuarter = 'First Quarter'
export const SecondQuarter = 'Second Quarter'
export const ThirdQuarter = 'Third Quarter'
export const FourthQuarter = 'Fourth Quarter'
export const ThisYear = 'This Year'
export const LastYear = 'Last Year'
export const Reports:string = 'Reports'
export const NotIncludingShort:string = 'Not incl.'
export const data0:string = 'No data'
export const data1:string = 'Less than 3'
export const data2:string = 'Less than 5'
export const data3:string = 'Less than 7'
export const data4:string = 'More than 6'
export const Legend:string = 'Legend'
export const to:string = 'to'
export const DataSummaryFor:string = 'Data summary for'
export const DataSummaryByCountry:string = 'Data summary by country'
export const players = 'players'
export const FreeRoundsReport:string='Free Rounds Report'
export const PreExisting:string = 'Pre-Existing'
export const Rewarded:string = 'Rewarded'
export const Played:string = 'Played'
export const Forfeited:string = 'Forfeited'
export const Remaining:string = 'Remaining'
export const PctPlayedRewarded:string = '% Played of Rewarded'
export const NumberOfPlayers:string = '# Players'
export const NumberOfFreeRounds:string = '# Free Rounds'
export const ValueOfFreeRounds:string = 'Free Rounds Value'
export const Total:string = 'Total'
export const TotalShortP:string = 'Tot. %'
export const TotalP:string = 'Total %'
export const CumulativeShort:string = 'Cml.'
export const Cumulative:string = 'Cumulative'
export const CumulativePShort:string = 'Cml. %'
export const CumulativeP:string = 'Cumulative %'
export const AnyCurrency:string = 'Any currency'
export const ByCountry:string = ' by country' 
export const ByGame:string = ' by game' 
export const ExchangeRate:string = 'Exchange Rate'
export const Revenues = 'Revenues'
export const FreeRounds = 'Free Rounds'
export const Players = 'Players'
export const Number = 'Number'
export const Value = 'Value'
export const Achievements = 'Achievements'
export const Country = 'Country'
export const Countries = 'Countries'
export const Game = 'Game'
export const Top1 = 'Top 1'
export const Top2 = 'Top 2'
export const Top3 = 'Top 3'
export const Top4 = 'Top 4'
export const Top5 = 'Top 5'
export const filename_base = 'Client'
export const filename_client: string = filename_base + '.js'
export const filename_client_min: string = filename_base + '.min' + '.js'
export const OnlyJpg: string = 'Only ' + 'jpg' + ' images can be inserted as game assets.'
export const OnlyPng: string = 'Only ' + 'png' + ' images can be inserted as game assets.'
export const filename_presentation: string = 'presentation' + '.jpg'
export const changeGS: string = 'change global settings'
export const CGS: string = 'CGS'
export const ChangeGS: string = 'Change Global Settings'
export const ChangeGSDesc: string = 'Indicates whether the user changes the global settings.'
export const SelectGameStage: string = 'Select Game Stage'
export const Development: string = 'Development'
export const Staging: string = 'Staging'
export const Production: string = 'Production'
export const AssetsUploaded: string = 'Assets were sucessfully uploaded!'
export const FilesUploaded: string = 'Files were sucessfully uploaded!'
export const AllUploaded: string = 'Everything was sucessfully uploaded!'
export const ExpandTransactionsDesc: string = 'if enabled, multi-product transactions will be shown as one'
export const ExpandTransactions: string = 'show multi-product transactions'
export const UploadGames: string = 'Upload Games'
export const UploadAll: string = 'Upload All'
export const Default: string = 'Default'
export const ReUploadAssets: string = 'Re-upload Image'
export const ReUploadAssetFiles: string = 'Re-upload Thumbnail Image'
export const ReUploadGameFiles: string = 'Re-upload Game Files'
export const ReUploadGame: string = 'Re-upload Game'
const UploadAlert0: string = 'data '
const UploadAlert1: string = 'To upload '
const UploadAlert2: string = 'there needs to be '
const UploadAlert3: string = 'assets '
const UploadAlert4: string = 'a game '
const UploadAlert5: string = 'an image called '
const UploadAlert6: string = 'a file called '
const UploadAlert7: string = 'the Upload Thumbnail Image Section '
const UploadAlert8: string = 'the Upload Game Files section '
const UploadAlert9: string = 'and all game data must be filled in!'
export const UploadAssetsAlert:string = UploadAlert1 + 
                                        UploadAlert3 + 
                                        UploadAlert2 +
                                        UploadAlert5 + 
                                        filename_presentation + 
                                        _in_ +
                                        UploadAlert7 +
                                        UploadAlert9

export const UploadGamesAlert: string = UploadAlert1 +
                                        UploadAlert4 +
                                        UploadAlert2 +
                                        UploadAlert6 + 
                                        filename_client +
                                        _or_ + 
                                        filename_client_min +
                                        _in_ +
                                        UploadAlert8 +
                                        UploadAlert9

export const UploadAllAlert: string =   UploadAlert1 +
                                        UploadAlert4 +
                                        and_ +
                                        UploadAlert3 +
                                        UploadAlert2 +
                                        UploadAlert6 + 
                                        filename_client +
                                        _or_ + 
                                        filename_client_min +
                                        _in_ +
                                        UploadAlert8 + 
                                        and_ +
                                        UploadAlert5 + 
                                        filename_presentation + 
                                        _in_ +
                                        UploadAlert7 +
                                        UploadAlert9

export const UploadDataAlert: string =  UploadAlert1 +
                                        UploadAlert0 +
                                        UploadAlert2 +
                                        UploadAlert6 + 
                                        filename_client +
                                        _or_ + 
                                        filename_client_min +
                                        _in_ +
                                        UploadAlert8 + 
                                        and_ +
                                        UploadAlert5 + 
                                        filename_presentation + 
                                        _in_ +
                                        UploadAlert7 +
                                        UploadAlert9
export const GameData: string = 'Game data'
export const GameName: string = 'Game name'
export const GameID: string = 'Game ID'
export const GameURL: string = 'Game URL'
export const ThumbnailImage: string = 'Thumbnail Image'
export const NumberOfLines: string = 'Number of Lines'
export const NumberOfReels: string = 'Number of Reels'
export const Payout: string = 'Payout'
export const Reels: string = 'Reels'
export const Lines: string = 'Lines'
export const UploadThumbnailImage: string = 'Upload Thumbnail Image'
export const DragFilesOrClickBelow: string = 'Drag files or click below'
export const DragFileOrClickBelow: string = 'Drag file or click below'
export const Files: string = 'Files'
export const All: string = 'All'
export const UploadGameFiles: string = 'Upload Game Files'
export const AddGames: string = 'Add Games'
export const TestGames: string = 'Test Games'
export const ManageGames: string = 'Manage Games'
export const AssignedGames: string = 'Assigned Games'
export const MyGames: string = 'My Games'
export const MySites: string = 'My Sites'
export const Stage: string = 'Stage'
export const DisplaySize: string = 'Display Size'
export const SelectSize: string = 'Select Size'
export const Normal: string = 'Normal'
export const SmallPhone: string = 'Small Phone'
export const LargePhone: string = 'Large Phone'
export const SmallTablet: string = 'Small Tablet'
export const LargeTablet: string = 'Large Tablet'    
export const FullScreen: string = 'FullScreen'
export const SelectGame: string = 'Select Game'
export const Manage: string = 'Manage'
export const GameAssetProvider: string = 'Game Asset Provider'
export const UpdateGameDataQ: string = 'Do you want to update game data?'
export const Update: string = 'Update'
export const Delete: string = 'Delete'
export const Upload: string = 'Upload'
export const GameUploaded: string = 'Game Uploaded!'    
export const DeleteGameQ: string = 'Do you want to delete the game and all its Stages?'
export const _games_: string = '/games/'
export const _assets_: string = '/assets/'
export const note: string = 'note'
export const countriesWithNoData:string = 'countries with no data'
export const countriesWithData:string = 'countries with data'
export const selectedCountry:string = 'selected country'
/*
export const CapeVerde:string = 'Cape Verde'
export const tooSmallToShowWorldMap:string = 'is too small to show on a world map'
*/
export const UpdateGames:string =  'Update games'
export const UpdateList:string =  'Update list'
export const SelectGames:string = 'Select Games'
export const AddEditPlayers:string = 'Add/Edit Players'
export const SetData:string = 'Set Data'
export const SetDomain:string = 'Set Domain'
export const SelectDomain:string = 'Select Domain'
export const Domain:string = 'Domain'
export const DomainQ:string = 'Domain?'
export const Gamification:string = 'Gamification'
export const SetGameGroups:string = 'Set Game Groups'
export const GameGroups:string = 'Game Groups'
export const SetSiteData:string = 'Set Site Data'
export const SaveData:string = 'Save Data'
export const UpdateData:string = 'Update Data'
export const DefaultData:string = 'Defaults'
export const LoadData:string = 'Load Data'
export const Logo:string = 'Logo'
export const SetLogo:string = 'Set Logo'
export const PleaseUploadLogoJpg:string = 'Please Upload Logo (*.jpg)'
export const PleaseUploadLogoPng:string = 'Please Upload Logo (*.png)'
export const LogoUploaded:string = 'Logo is uploaded'
export const LogoSavedSuccess:string = 'Logo was saved successfully.'
export const LogoSavedFailure:string = 'There was an error while saving logo.'
export const DataSavedSuccess:string = 'Data was saved successfully.'
export const DataSavedSuccessInsert:string = 'Data was inserted successfully.'
export const DataSavedSuccessUpdate:string = 'Data was updated successfully.'
export const DataSavedFailure:string = 'There was an error while saving data.'
export const SiteName:string = 'Site Name'
export const Copyright:string = 'Copyright'
export const Group:string = 'Group'
export const Groups:string = 'Groups'
export const Index:string = 'Index'
export const group:string = 'group'
export const index:string = 'index'
export const MenusDefault:string[] = ['Default']
export const Menu:string = 'Menu'
export const ShowBalance: string = 'balance'
export const ShowBalanceDesc: string = 'Indicates whether balance should be displayed alongside other player data.'
export const ShowAvailablePoints: string = 'available pts'
export const ShowAvailablePointsDesc: string = 
'Indicates whether available points balance should be displayed alongside other player data.'
export const ShowTotalPoints: string = 'total pts'
export const ShowTotalPointsDesc: string = 
'Indicates whether total points balance should be displayed alongside other player data.'
export const ShowTotals: string = 'totals'
export const ShowTotalsDesc: string = 'Toggle, if you want to show/hide totals columns'
export const BigGamesShort:string = 'big'
export const ActiveGamesShort:string = 'active'
export const BigGamesLetter:string = 'b'
export const ActiveGamesLetter:string = 'a'
export const ActiveGamesDesc:string = 'Indicates whether the games on the site should be displayed.'
export const BigGames:string = 'big games'
export const BigGamesDesc:string = 'Indicates whether the games on the site should be displayed in large format.'
export const ShowGameInfo:string = 'game info'
export const ShowGameInfoDesc:string = 'Indicates whether the name of the game should be displayed on hover.'
export const ShowVersionInfo:string = 'version info'
export const ShowDebugInfoDesc:string = 'Indicates whether the debug information should be displayed on hover.'
export const ShowDebugInfo:string = 'debug info'
export const hover = 'hover'
export const hovers = 'hovers'
export const Borders:string = 'borders'
export const BordersDesc:string = 'Indicates whether the games should have stylish borders.'
export const DisplayName: string = 'display name'
export const DisplayNameDesc: string = 'Indicates whether you want to include name of the Site in the title of the'
+ ' Test Site. Usually you would want to do that, if the shown logo does not include the name of the Site.'
export const ShowVersionInfoDesc:string = 'Indicates whether the version of the game should be displayed on hover.'
export const GetFromDatabase:string = 'Get Asset Providers From Database'
export const GetFromDatabaseDesc:string = 'Indicates whether the Game Asset Provider' 
                                        + 'should be chosen among those that are stored in the database.'
export const ListMoveTop: string = 'List: top page'
export const ListMoveLeft: string = 'List: previous page'
export const ListMoveRight: string = 'List: next page'
export const ListMoveBottom: string = 'List: bottom page'
export const recordsPerPageDesc: string = 
'Indicates the maximum number of records that can be listed on a single page.'
export const subLevelMinDesc: string = 'Indicates the minimum Sub-Level (if zero, it is not applicable).' 
export const subLevelMaxDesc: string = 'Indicates the maximum Sub-Level (if zero, it is not applicable).'
export const LEVEL:string = 'LEVEL'
export const LEVELDesc:string = 'This Level is NOT a Sub-Level, therefore it has no Sub-Level name.'
export const FINALLEVEL:string = 'FINAL LEVEL'
export const FINALLEVELDesc:string = 'This is the Final Level (no levelling up is possible anymore).'    
export const delButtonDesc:string = 'Delete a Record'
export const editButtonDesc:string = 'Edit a Record'
export const selectButtonDesc:string = 'Select a Record'
export const attrButtonDesc:string = 'Change Attribute'
export const expandButtonDesc:string = 'Expand a Record'
export const toggleValueDesc:string = 'Toggle Value'
export const FilterSiteDesc:string = 'Filter the List by selecting the Gaming Site.'
export const FilterLevelDesc:string = 'Filter the List by selecting the Level.'
export const NoBadge: string = 'No Badge'
export const NoGroup: string = 'No Group'
export const NoIndex: string = 'No Index'
export const ALPHA: string = 'ALPHA'
export const COLD: string = 'COLD'
export const FIRE: string = 'FIRE'
export const HOT: string = 'HOT'
export const NEW: string = 'NEW'
export const WHEEL: string = 'WHEEL'
export const ShowRewardShopItems: string = 'Show Reward Shop Items'
export const ShopItem: string = 'Shop Item'
export const RewardShopItem: string = 'Reward ' + ShopItem
export const LotteryItem: string = 'Lottery Item '
export const ShopItems: string = 'Shop Items'
export const ManageShop: string = 'Manage Shop'
export const defaultImage: string = 'default image'
export const item:string = 'item'
export const items:string = 'items'
export const sureDeleteMenu: string = 'Are you sure that you want to delete the menu with the title'
export const sureDeleteGroup: string = 'Are you sure that you want to delete the group with the title'
export const sureDeleteShopItem: string = 'Are you sure that you want to delete the shop item with the title'
export const menusWithoutTitle: string = 'There are some menus without a title. Please remove them before updating.'
export const groupsWithoutTitle: string='There are some groups without a title. Please remove them before updating.'
export const atLeastOneMenuItem: string = 'There needs to be at least one menu item, it is impossible to delete it.'
export const GameAddAnotherInstance : string = 'Add another instance of the same game'
export const TestSiteDeselectGame : string = 'Deselect a Game'
export const TestSiteSelectGame : string = 'Select a Game'
export const create_a_new : string = 'create a new'
export const or_select_from_existing : string = 'or select from existing'
export const thisDomainAlreadyExists: string = 'This domain already exists, please choose another.'
export const thisDomainIsInvalid: string = 'This domain is invalid, please choose another.'
export const pleaseChooseDomain: string = 'If you want to set the domain, please choose from the listing.'
export const domainSuccessfullyUpdated: string = 'Domain was successfully updated.'
export const pleaseSelectDomain: string = 'Please select domain from the list.'
export const ThisShouldNotBePossible: string = 'This should not be possible.'
export const domainBelongsToAnotherSite: string = 'This domain cannot be chosen, as it belongs to another site.'
export const SelectGroupFilter: string = 'Select Group Filter'
export const GroupFilterQ: string = 'Group Filter?'
export const AddDomain: string = 'Add Domain' 
export const MenuType: string[] = ['Undefined', 'Game Progress', 'Points & Levels', 'Reward Shop', 'Tournament',
                                    'Casino', 'Odds', 'Promotions', 'Tour de Bet', 'Link', 'Link to Betslip',
                                    'Link to Tdb Premium', 'Link to Tdb Free', 'Forum', 'Blog', 'Lotto']
export const Ungrouped: string = 'Ungrouped'
export const NumberOfInstances:string = 'Indicates the number of identical games as the game in question that are '
+'shown. If there is only one identical game, the background of the game is blue, otherwise the background is red.'
export const CannotDeleteNonEmptyGroup:string = 'Cannot delete group, as it is not empty.'
export const UpdateGamesListToProceed:string = 'Update Games List To Proceed'
export const CurrentlyActiveDomain:string = 'Currently Active Domain'
export const SetDomainToEnableTheSite:string = 'Set the Domain to enable the Test site.'
export const DomainNotSet:string = 'domain not set'
export const CurrentlyActive:string = 'Currently active'
export const UngroupedDesc:string = 'This group cannot be deleted, has no title and shall show no games, if needed.'
+ ' Its purpose is to show games on the top, if it is desired that way.'
export const Price:string = 'Price'
export const AvgPrice:string = 'Avg. Price'
export const Example:string = 'Example'
export const Discount:string = 'Discount'
export const DiscountShort:string = 'Dis.'
export const Quantity:string = 'Quantity'
export const Image:string = 'Image'    
export const Discounted:string = 'Discounted'
export const shopItemImage:string = 'shop item image'
export const ShopItemsUpdatedSuccessfully:string = 'Shop items data was updated successfully.'
export const ShopItemUpdatedSuccessfully:string = 'Shop item data was updated successfully.'
export const ShopItemDefaultImageSavedSuccess:string = 'Default Product Image was saved successfully.'
export const ShopItemDefaultImageSavedFailure:string = 'There was an error while saving Default Product Image.'
export const ShopItemImageSavedSuccess:string = 'Shop Item Image was saved successfully.'
export const ShopItemImageSavedFailure:string = 'There was an error while saving Shop Item Image.'
export const RewardShopItemActiveDesc:string = 
'Please, set as inactive (red), if you want to hide the item from the list on the Test site'
export const tRSTransactions:string = 'List of Reward Shop Transactions'
export const tRewardShop:string = 'List of Reward Shop Items'
export const tLottery:string = 'List of Lotteries'
export const tCalendar:string = 'List of Calendar Days'
export const tTournament:string = 'List of Tournaments'
export const tCurrency:string = 'List of Currencies'
export const vCurrency:string = 'View Currencies'
export const aeCurrency:string = 'Add/Edit Currencies'
export const tLanguage:string = 'List of Languages'
export const tBanner:string = 'List of Banners'
export const tGames:string = 'List of Games'
export const vLanguage:string = 'View Languages'
export const vBanner:string = 'View Banners'
export const vGames:string = 'View Games'
export const aeLanguage:string = 'Add/Edit Languages'
export const aeBanner:string = 'Add/Edit Banners'
export const aeGames:string = 'Add/Edit Games'
export const vTransactions:string = 'View Transactions'
export const vRewardShop:string = 'View Reward Shop'
export const aeRewardShop:string = 'Add/Edit Reward Shop'
export const aeLottery:string = 'Add/Edit Lottery'
export const vTournaments:string = 'View Tournaments'
export const aeTournaments:string = 'Add/Edit Tournaments'
export const SetDefaultImage:string = 'Set Default Product Image'
export const SetDefaultPopupImage:string = 'Set Default Popup Image'
export const PurchasedDate:string = 'Purhased Date'
export const PurchasedTime:string = 'Purhased Time'  
export const BalanceBefore:string = 'Balance Before'
export const BalanceAfter:string = 'Balance After'
export const PlayerId:string = 'Player ID'
// export const P:string = '%'
export const DiscountedPrice:string = 'Discounted Price'
export const DiscountedPriceShort:string = 'DP'
export const PlayerManagement:string = 'Player Management'
export const NoImage:string = 'No Image'
export const Background:string = 'Background'
export const Thumbnail:string = 'Thumbnail'
export const NoFlag:string = 'No Flag'
export const NoThumbnail:string = 'No Thumb.'
export const NoBackground:string = 'No Backgr.'
export const NoThumbnailDesc:string = 'No Thumbnail for this game'
export const NoBackgroundDesc:string = 'No Background for this game'
export const AddImage: string = 'Add Image'
export const Add: string = 'Add'
export const NoteGGRMWalletMustBeEnabled: string = 
'Please note, gg_rm_wallet must be enabled for the contractual partner in question!'
export const RemoveImage: string = 'Remove Image'
export const confirmRemoveImage:string = 'Do you really want to remove the selected image?'
export const DuplicateLevelForSite:string = 
'Cannot insert Level, as Level with this name is already stored for this Site.'
export const DuplicateCategory:string = 'Cannot insert Category, as Category with this name is already stored.' 
export const DuplicateType:string = 
'Cannot insert Type for the Category, as Type with this name is already stored for the Category.'    
export const Item:string = 'Item'
export const Expand:string = 'Expand'
export const Compress:string = 'Compress'
export const Any:string = 'Any'
export const ShowJustActive:string = 'just active'
export const ShowJustActiveDesc:string = 'show just active items'
export const ShowJustFreeRound:string = 'just free round'
export const ShowJustFreeRoundDesc:string = 'show just free round items'
export const NotSet:string = 'Not Set'
export const CategoryName:string = 'Category Name'
export const TypeName:string = 'Type Name'
export const AttrTableGame:string = 'Table Game - NO'
export const AttrTableGameSelect:string = 'Table Game - YES'
export const AttrTableGameAny:string = 'Table Game - ANY'
export const AttrDepositOption:string = 'Deposit Option - NO'
export const AttrDepositOptionSelect:string = 'Deposit Option - YES'
export const AttrDepositOptionAny:string = 'Deposit Option - ANY'
export const AttrLoginOption:string = 'Login Type - NO'
export const AttrLoginOptionSelect:string = 'Login Type - YES'
export const AttrLoginOptionAny:string = 'Login Type - ANY'
export const AttrRegistrationOption:string = 'Registration Type - NO'
export const AttrRegistrationOptionSelect:string = 'Registration Type - YES'
export const AttrRegistrationOptionAny:string = 'Registration Type - ANY'
export const AttrWithdrawalOption:string = 'Withdrawal Type - NO'
export const AttrWithdrawalOptionSelect:string = 'Withdrawal Type - YES'
export const AttrWithdrawalOptionAny:string = 'Withdrawal Type - ANY'
export const AttrOddsType:string = 'Odds Type - NO'
export const AttrOddsTypeSelect:string = 'Odds Type - YES'
export const AttrOddsTypeAny:string = 'Odds Type - ANY'
export const AttrSlots:string = 'Slots - NO'
export const AttrSlotsSelect:string = 'Slots - YES'
export const AttrSlotsAny:string = 'Slots - ANY'
export const NO:string = 'NO'
export const YES:string = 'YES'
export const ANY:string = 'Any'
export const TableGame:string = 'Table Game'
export const PrteDesc:string = 'Points Required to Enter this Level'
export const PRTE:string = 'PRTE'
export const LoginType:string = 'Login Type'
export const RegistrationType:string = 'Registration Type'
export const WithdrawalType:string = 'Withdrawal Type'
export const columnsDesc:string = 'Indicates the number of columns that are displayed at the table.'
export const rewardDesc:string = 
'Indicates the frequency on which the rewards are being dealt out upon leveling up.'
export const reward1:string = 'On completion of every'
export const reward2:string = 'th level a'
export const reward3:string = 'will be credited to the player\'s account.'
export const reward4:string = 'At the end'
export const RecurringReward:string = 'Recurring'
export const FinalReward:string = 'Final Reward'
export const finalLevel:string = 'final level'
export const FLDesc:string = 'Indicates whether this Level is a Final Level (no levelling up is possible anymore).'
export const Levels:string = 'Levels'
export const RR:string = 'RR'
export const FrequencyRecurringReward:string = 'Frequency (# of Sub-Levels) of Recurring Reward'
export const ToggleInformationAboutSubLevels:string = 'Show information about Sub-Levels'
export const AddChart:string = 'Add Chart'
export const FinalRewardEnabled:string = 'final reward enabled'
export const ShowTotal:string = 'total'
export const ShowCumul:string = 'cumul.'
export const ShowTotalDesc:string = 'Show column "Total"'
export const ShowTotalPDesc:string = 'Show column "Total %"'
export const ShowCumulDesc:string = 'Show column "Cumulative"'
export const ShowCumulPDesc:string = 'Show column "Cumulative %"'
export const CurrencyDescription:string = 'Name'
export const eurDesc:string = 'Indicates Fixed Relation to the Main Currency'
export const TargetShort:string = 'Tgt.'
export const TargetDesc:string = 'Indicates how the points are being assigned'
export const AnyTarget:string = 'Wagering & Losses'
export const BetMinDesc: string = 'Minimum Wagering Amount. If not applicable, enter 0.'
export const BetMaxDesc: string = 'Maximum Wagering Amount. If not applicable, enter 0.'
export const SizeDesc:string = 'Minimum Wagering Amount, Maximum Wagering Amount'
export const pointsAssignedByAmount:string = 'points assigned (by amount)'
export const pointsAssignedByPercentage:string = 'percentage of difference of points assigned (by amount)'
export const ValidDesc:string = 'Indicates if the date period specified in rule is currently valid.'
export const Valid0:string = 'Date period is currently active.'
export const Valid1:string = 'Date period is in the past.'
export const Valid2:string = 'Date period is in the future.'
export const Code:string = 'Code'
export const AvailablePoints:string = 'Available points'
export const TotalPoints:string = 'Total points'
export const DuplicateRule:string = 'Duplicate Rule'
export const ProblemWithRule: string = 
'There is a problem with the Rule. It has the same properties as one of the other rules or it is in a conflict.'
export const SublevelMinDesc: string = 
'Indicates the minimum Sub-Level for the Rule to be valid. 0 means that the Rule is valid for the whole Level.'
export const SublevelMaxDesc: string =
'Indicates the maximum Sub-Level for the Rule to be valid. 0 means that the Rule is valid for the whole Level.'
export const MinLevelNotSet: string = 'Minimum Level is not set'
export const MaxLevelNotSet: string = 'Maximum Level is not set'
export const LevelMaxOverLevelMin:string = 'Minimum Level should not be higher than Maximum Level.'
export const SubLevelMaxOverSubLevelMin:string = 'Minimum Sub-Level should not be higher than Maximum Sub-Level.'
export const ValidInLevel: string = 'Valid in Level'
export const MinimumLevel: string = 'Minimum Level'
export const MaximumLevel: string = 'Maximum Level'
export const ValidInSubLevel: string = 'Valid in Sub-Level'
export const MinimumSubLevel: string = 'Minimum Sub-Level'
export const MaximumSubLevel: string = 'Maximum Sub-Level'
export const ValidForWholeLevel: string  = 'Valid for whole Level(s)'
export const LevelInfo:string = 'Indicates (Sub-)Levels requirement'
export const excludeGames:string = 'exclude games'
export const excludeGamesDesc:string = 'Exclude games'
export const ExcludeGames:string = 'Exclude Games'
export const includeGames:string = 'include games'
export const includeGamesDesc:string = 'Include games'
export const IncludeGames:string = 'Include Games'
export const playerList:string = 'player list'
export const SelectPlayerList:string = 'Player List?'
export const AllGamesAreExcluded:string = 'All games are excluded. This rule cannot be saved as it has no meaning.'
export const AllGamesAreIncluded:string = 'All games are included. This rule cannot be saved as it has no meaning.'
export const IncludeExcludeInfo:string = 'Indicates included/excluded games requirement'
export const PlayerListInfo:string = 'Indicates Player List requirement'
export const PlayerDistributionByLevel:string = 'Player distribution By Level'
export const PlayerDistributionBySubLevel:string = 'Player distribution By Sub-Level'
export const PlayerDistributionByLevelChart:string = 'Chart - Player distribution By Level'
export const PlayerDistributionBySubLevelChart:string = 'Chart - Player distribution By Sub-Level'
export const TPMean: string = 'TP mean'
export const SLMean: string = 'SL mean'
export const TPM: string = 'Total Points average (mean)'
export const SLM: string = 'Sub-Level average (mean)'
export const ThereAreNoSublevels: string = 'There are no Sub-Levels'
export const Refresh: string = 'Refresh'
export const RefreshWarning: string = 'This command will re-create the table of the players'
export const NoData: string = 'No data'
export const EventLastDesc:string = 'Last Event'
export const EventLast:string = 'Last Evt.'
export const EventFirstDesc:string = 'First Event'
export const EventFirst:string = '1st Evt.'
export const ABS:string = 'Mean'
export const ABSDesc:string = 'Average Bet Size (mean)'
export const MED:string = 'Med.'
export const MEDDesc:string = 'Average Bet Size (median)'
export const AvailablePts:string = 'Avail.'
export const TotalPts:string = 'Total'
export const AvailablePtsDesc:string = 'Available Points'
export const TotalPtsDesc:string = 'Total Points'
export const Current:string = 'Current'
export const Highest:string = 'Highest'
export const tPlayerStats:string = 'List of Player Stats'
export const tPlayerEvents:string = 'List of Events for player with Player ID "'
export const s1: string = 'Total (Min)'
export const a1: string = 'Mean (Max)'
export const s0: string[] = ['s0', 'Min', 'Total']
export const a0: string[] = ['a0', 'Max', 'Mean']
export const MinLevel: string = 'Minimum Level'
export const MaxLevel: string = 'Maximum Level'
export const Min_Level: string = 'Min. Level'
export const Max_Level: string = 'Max. Level'
export const MinDate: string = 'Minimum Date'
export const MaxDate: string = 'Maximum Date'
export const FOOTER_TOTAL_MIN: number = 0
export const FOOTER_MEAN_MAX: number = 1
export const SpecificQuery: string = 'Specific query'
export const specificQuery: string = 'specific query'
export const specificQueryDesc: string = 'enable, if you want to query player stats with more detail'    
export const FromAnyPeriod: string = 'From Any Period'
export const ToAnyPeriod: string = 'To Any Period'
export const compareWithAnotherDate: string = 'compare date'
export const compareWithAnotherDateDesc: string = 'compare with data from another date'
export const PerformQuery: string = 'Perform query'
export const DetailedPlayerInfo: string = 'detailed information/points calculation of player events'
export const FilterByPlayer: string = 'Filter By Player'
export const footerBottom: string = 'fb'
export const footerBottomDesc: string = 'if enabled, the aggregate data gets displayed on the bottom'    
export const Custom: string = 'Custom'
export const Competition: string = 'Competition'
export const Contest: string = 'Contest'
export const Event: string = 'Event'
export const Wager: string = 'Wager'
export const Wagered: string = 'Wagered'
export const Gross: string = 'Gross'
export const Won: string = 'Won'
export const Net: string = 'Net'
export const Deposited: string = 'Deposited'
export const Withdrawn: string = 'Withdrawn'
export const Win: string = 'Win'
export const Date: string = 'Date'
export const DateTime: string = 'Date & Time'
export const Time: string = 'Time'
export const Activity_: string = 'Activity'
export const BackToPlayerStats: string = 'Back to Player Stats'
export const PointsTotal: string = 'Points Total (XP)'
export const PointsWagering: string = 'Points Wagering (XP)'
export const PointsLosses: string = 'Points Losses (XP)'
export const Pts_: string = 'Pts.'
export const TimeAggregatesDesc: string = 'Time / Aggregates'
export const TimeAggregates: string = 'Time / Aggr.'
export const RefineSearch: string = 'Refine Search'
export const AllData: string = 'All Data'
export const ProcessedData: string = 'Processed Data'
export const UnprocessedData: string = 'Unprocessed Data'
export const Sportsbook: string = 'Sportsbook'
export const CasinoGames: string = 'Casino & Games'
export const WarningUnprocessedData: string = 'These events haven\'t yet been considered in the points calculation'
export const ErrorInDatabaseEmptyPlayer: string = 'Error in database: Empty player'
export const WithdrawalDesc: string = 'Withdrawals'
export const SelectBrands: string = 'Select Brands'
export const SelectDays: string = 'Select Days'
export const SelectDaysInWeek: string = 'Select Days (weekly)'
export const SelectDaysInMonth: string = 'Select Days (monthly)'    
export const SelectDaysInWeekDesc: string = 'Select Days in a week'
export const SelectDaysInMonthDesc: string = 'Select Days in a month'
export const SelectWeeks: string = 'Select Weeks'
export const SelectMonths: string = 'Select Months'
export const SelectAnnual: string = 'Select Years'
export const SelectCurrencies: string = 'Select Currencies'
export const optIn: string = 'opt-in req.'
export const recurring: string = 'recurring'
export const AdvancedSettings: string = 'Advanced Settings'
export const AdvancedSettingsShort: string = 'Advanced'
export const optInDesc:string = 'indicates, whether the player is required to opt-in to engage in a tournament'
export const recurringDesc:string = 'indicates, whether the tournament is of a recurring nature'
export const optInCost: string = 'Cost to Opt-In'
export const optInCostShort: string = 'Cost'
export const Min_: string = 'Min.'
export const Max_: string = 'Max.'
export const betAmountMinDesc: string = 'leave empty, if you do not want to specify Minimum Bet Amount'
export const betAmountMaxDesc: string = 'leave empty, if you do not want to specify Maximum Bet Amount'
export const betAmountTotal: string = 'Max. Total'
export const betAmountTotalDesc: string = 'leave empty, if you do not want to specify Maximum Total Wager Amount'
export const MainCurrency: string = 'This is the Main Currency'
export const NonMainCurrency: string = 'This is NOT the Main Currency'
export const MinimumNumberOfWagerRounds: string =
'leave empty, if you do not want to specify Minimum Number of Wager Rounds'
export const MaximumNumberOfWagerRounds: string =
'leave empty, if you do not want to specify Maximum Number of Wager Rounds'
export const WagerRounds: string = 'Wager Rounds'
export const WagerRoundsShort: string = 'WR'
export const SelectLeaderboardType: string = 'Leaderboard Type?'
export const LeaderBoard:string[] =['Winning Percentage','Win Amount','Wager Amount','Number of Rounds','Highest Win Ratio']
export const LeaderBoardOddyzzey: string[] = ['Win Percentage', 'Total Points', 'Highest Win Ratio', 'Highest Win', 'Highest Wager', 'Wagers Count', 'Wins Count', 'Total Won', 'Total Wagered']
export const LeaderBoard2:string[] = ['Highest Wager', 'Highest Win', 'Highest Single Win Ratio']
export const CasinoVendors: string[] = ['Include Casino Vendors', 'Exclude Casino Vendors']
export const CasinoGames_: string[] = ['Include Casino Games', 'Exclude Casino Games']
export const SelectCasinoVendors: string = 'Select Casino Vendors'
export const SelectCasinoGames: string = 'Select Casino Games'
export const MIN: string = 'MIN'
export const MAX: string = 'MAX'
export const Min: string = 'Min'
export const Max: string = 'Max'    
export const CurrencyAlreadyInDb: string = 'Currency selected is already in the database so it cannot be inserted.'
export const LanguageAlreadyInDb: string = 'Language selected is already in the database so it cannot be inserted.'
export const Casino_Vendors: string = 'Casino Vendors'
export const Casino_Games: string = 'Casino Games'
export const FixedLiveOdds: string[] = ['Fixed + Live Odds', 'Fixed Odds', 'Live Odds']
export const OddsRequirement: string[] = [
    'Greater Than',
    'Greater Than or Equal To',
    'Equal To',
    'Less Than or Equal To',
    'Less Than']
export const OddsRequirementAbbrev : string[] = ['GT', 'GTE', 'EQL', 'LTE', 'LT']
export const DaysInWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const DaysInAWeek: string[] = ['First weekday', 'Last weekday', 'Monday', 'Tuesday',
                                        'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const DaysInAMonth: string[] = [ 'First day in the month',
                                        'Last day in the month', '2', '3', '4', '5', '6', '7', '8',
                                        '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                                        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
export const DaysInAMonth2: string[] = [ 'First',
                                        'Last', '2', '3', '4', '5', '6', '7', '8',
                                        '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                                        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
export const Weeks: string[] = ['First week', 'Last week', 'First week (monthly)', 'Second week (monthly)',
                                'Third week (monthly)', 'Fourth week (monthly)', 'Last week (monthly)']
export const Months: string[] = ['First Month', 'Last Month','January', 'February', 'March', 'April', 'May',
                                    'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const SelectOddsRequirement = 'Odds Requirement'
export const addFixedLiveOdds: string[] = ['Live Odds', 'Fixed Odds']
export const add: string = 'add'
export const TournamentType: string[] = ['Sportsbook & Casino', 'Sportsbook', 'Casino', 'LottoBet', 'Custom']
export const SelectTournamentType: string = 'Tournament Type?'
export const PrizePoolSection: string = 'Prize Pool Section'
export const PrizePoolSectionShort: string = 'Prize Pool'
export const RecurringSection: string = 'Leaderboards Settings'
export const RecurringSectionShort: string = 'Leaderboards'
export const FirstPositionRewarded: string = 'First Position Rewarded'
export const LastPositionRewarded: string = 'Last Position Rewarded'
export const TournamentReward: string[] = ['Fixed Amount', 'Bonus Amount', 'Free Spins', 'Free Bets', 'WoF Spins']
export const SelectTournamentPrize: string = 'Tournament Prize?'
export const FixedAmount: string = 'Fixed Amount'
export const BonusAmount: string = 'Bonus Amount'
export const WageringRequirement: string = 'Wagering Requirement'
export const WheelOfFortuneType: string = 'Wheel of Fortune Type'
export const DeletePrizePool: string = 'Delete the Prize Pool'
export const AddPrizePool: string = 'Add a new Prize Pool'
export const AtLeastOnePrizePool: string = 'At Least One Prize Pool has to be defined'
export const PrizePool: string = 'prize pool'
export const PrizePools: string = 'prize pools'
export const position: string = 'position'
export const positions: string = 'positions'
export const PositionsRewarded: string = 'positions rewarded'
export const PositionRewarded: string = 'position rewarded'
export const PositionsInConflict: string = 'positions are in conflict'
export const PositionsNotInConflict: string = 'positions not in conflict'
export const ToggleIncludeExcludeGames: string = 'Toggle include-exclude games'
export const compulsoryTournamentTitle: string = 'It is compulsory to set the name of the tournament.'
export const compulsoryTournamentCurrency: string = 'It is compulsory to set at least one currency.'
export const compulsoryTournamentDateFrom: string = 'It is compulsory to enter the start day of the tournament.'
export const compulsoryTournamentDateTo: string = 'It is compulsory to enter the end day of the tournament.'
export const compulsoryTournamentType: string = 'It is compulsory to set the tournament type.'
export const compulsoryLeaderboardType: string = 'It is compulsory to set the leaderboard type.'
export const compulsoryDifferentLeaderboardType: string = 
'If selected, the second leaderboard type should be different than first.'
export const compulsoryOddsType: string = 'It is compulsory to set the odds type.'
export const compulsoryOdds: string = 'It is compulsory to set the odds.'
export const compulsoryCorrectTime: string = 'It is compulsory to set the time correctly.'
export const Tournament: string = 'Tournament'
export const Leaderboard: string = 'Leaderboard'
export const TournamentLeaderboard: string = Tournament + ' ' + Leaderboard

export const TournamentLeaderboardShort: string = 'LBs'
export const TimestampFrom: string = 'Timestamp From'
export const TimestampTo: string = 'Timestamp To'
export const DateFrom: string = 'Date From'
export const DateTo: string = 'Date To'
export const TimeFrom: string = 'Time From'
export const TimeTo: string = 'Time To'
export const TournamentType_: string = 'Tournament Type'
export const LeaderboardType: string = 'Leaderboard Type'
export const LeaderboardType2: string[] = ['Leaderboard 2','nd']
export const TournamentInfo: string = 'Info'
export const TournamentPrizes: string = 'Prizes'
export const WoF: string = 'WoF'
export const WoFSpins: string = 'WoF spins'
export const second: string[] = ['2','nd']
export const Main: string = 'Main'
export const leaderboardButtonDesc: string = 'Leaderboard - results of the tournament'
export const OptIn: string = 'Opt in'
export const Currencies: string = 'Currencies'
export const Brands: string = 'Brands'
export const notRequired: string = 'not required'
export const required: string = 'required'
export const notDefined: string = 'not defined'
export const PleaseWaitTournamentLeaderboard: string = 'Please wait, tournament leaderboard is calculating...'
export const Criteria: string = 'Criteria'
export const AssessmentCriteria: string = 'Assessment Criteria'
export const Rewards: string = 'Rewards'
export const Duration: string = 'Duration'
export const notifyPlayers: string = 'notify players'
export const IncludeTournamentInPointsProgram : string = 'Include Tournament in Points Program'
export const includeTournamentInPointsProgram : string = 'incl. in points program'
export const includeTournamentInPointsProgramDesc: string = 
'indicates, whether the actions that are valid for the tournament in question should count for points program'
export const SelectPlayerNotification: string = 'Notification?'
export const NotifyPlayers: string[] = ['Notify by both', 'Notify by e-mail', 'Notify by mobile', 'Notify endpoint']
export const SelectRecurringType: string = 'Select Leaderboards?'
export const SelectRecurringTypeAdvanced: string = 'Select Leaderboard?'
export const RecurringType: string[] = ['Daily leaderboard', 'Weekly leaderboard', 'Monthly leaderboard', 'Annual leaderboard']
export const RecurringTypeShort: string[] = ['Daily', 'Weekly', 'Monthly', 'Annual']
export const RecurringDays: string = 'Recurring Days'
export const RecurringTypes: string = 'Recurring types'
export const MainCurency: string = 'Main Currency'
export const RecurringNotification: string = 'Recurring Notification'
export const RewardAmount: string = 'Reward Amount'
export const RewardType: string = 'Reward Type'
export const Player_: string = 'Player'
export const Position: string = 'Position'
export const Wrq: string = 'Wrq'
export const th: string = 'th'
export const sub: string = 'sub'
export const SelectBanner: string = 'Select Banner'
export const real_money: string = 'real money currency for buy-in/rewards'
export const Title: string = 'Title'
export const CalendarItem: string = 'Calendar Day'
export const SelectSecondaryCriteria: string = 'Secondary Criteria?'
export const amount: string = 'amount'
export const number: string = 'number'
export const AMT: string = 'AMT'
export const FreeSpins: string = 'Free spins'
export const FreeBets: string = 'Free bets'
export const ChristmasBackground: string[] = ['Lights', 'Snowflake', 'Christmas Tree', 'Gifts', 'Snowing', 'Stars']
export const LeaderboardsExtra: string = 'Leaderboards Extra'
export const LeaderboardsAndPrizes: string = 'Leaderboards & Prizes'
export const SetLevelRequirement: string = 'Set Level Requirement'
export const IncludePartOfWager: string = 'include part of wager'
export const Link: string = 'Link'
export const Provider: string = 'Provider'
export const noGame: string = 'no game'
export const selected: string = 'selected: '
export const all: string = 'all'
export const pleaseDefineLanguages = 'Please define languages!'
export const updateData: string = 'Update Data'
export const updateRewardShopData: string = 'Update Reward Shop Data'
export const textboxContent: string = 'Textbox content'
export const deleteCategory: string = 'Delete Category'
export const deleteTranslation: string = 'Delete Translation'
export const addCategory: string = 'Add Category'
export const addTranslation: string = 'Add Translation'
export const editCategory: string = 'Edit Category'
export const chooseCategory: string = 'Choose Category'
export const mainTitle: string = 'Main Title'
export const textboxTitle: string = 'Textbox Title'
export const categoryTitle1: string = 'Category Title 1'
export const categoryTitle2: string = 'Category Title 2'
export const TotalNumberOfRewardShopCategories: string = 'Total number of reward shop categories'
export const NumberOfRewardShopItemsInTheGivenCategory: string = 'Number of reward shop items in the given category'
export const TotalNumberOfRewardShopItems: string = 'Total number of reward shop items'
export const setCategories: string = 'Set Categories'
export const Dashboard: string = 'Dashboard'
export const Logotype: string = 'Logotype'
export const Original: string = 'Original'
export const Resource: string = 'Resource'
export const MenuImage: string = 'Menu Image'
export const JSONWebToken: string = 'JSON Web Token'
export const JSONWebTokenShort: string = 'JWT'
export const SkinID: string = 'Skin ID'
export const SelectBackend: string = 'Select Backend'
export const Aleacc: string = 'Aleacc'
export const RewardShop: string = 'Reward Shop'
export const SaveCalendar: string = 'Save Calendar'
export const MinimumLevelShort: string = 'Min. Level'
export const SetMinimumLevel: string = 'Set player\'s minimum level to purchase in the following categories!'
export const DistributionRewardShopTransactionsCategory: string = 'Distribution of Reward Shop Transactions by Category'
export const DistributionRewardShopTransactionsValueCategory: string = 'Distribution of Reward Shop Transactions Value by Category'
export const DistributionRewardShopTransactionsIn: string = 'Distribution of Reward Shop Transactions in '
export const DistributionRewardShopTransactionsValueIn: string = 'Distribution of Reward Shop Transactions Value in '
export const Notify: string = 'Notify'
export const Notifications: string = 'Notifications'
export const notify: string = 'notify'
export const doNotNotify: string = 'do not notify'
export const NotifyBelowContactsPurchase: string = 'Notify below contacts, when a purchase is made in the following categories!'
export const ViewReport: string = 'View Report'
export const ViewRewardShopTransactions: string = 'View Reward Shop Transactions'
export const lRewardShopTransactions: string = 'List of All Reward Shop Transactions'
export const none: string = 'none'
export const ChooseDateRST: string[] = ['Any date', 'Today', 'Yesterday', 'This month', 'Custom']
export const MeasureByTransactions: string = 'Measure by Transactions'
export const MeasureByTransactionsValue: string = 'Measure by Transactions Value'
export const NoItemsPresent: string = 'No items present!'
export const TotalValue: string = 'Total Value'
export const Transactions: string = 'Transactions'
export const AnySheet: string = 'Any Sheet'
export const Sheet: string = 'Sheet'
export const PendingShort: string = 'Pend'
export const Pending: string = 'Pending'
export const WinPending: string = 'Win *'
export const LossPending: string = 'Loss *'
export const LottoBetReport: string = 'LottoBet Report'
export const PENDING: string = 'PENDING'
export const PENDINGWIN: string = 'PENDING WIN'
export const PENDINGLOSS: string = 'PENDING LOSS'
export const UNKNOWN: string = 'UNKNOWN'
export const WIN: string = 'WIN'
export const LOSS: string = 'LOSS'
export const TicketInformation: string = 'Ticket information'
export const oneDraw: string = '1 draw'
export const oneSheet: string = '1 sheet'
export const RegularTicket: string = 'Regular ticket'
export const SystemTicket: string = 'System ticket'
export const combinations: string = 'combinations'
export const SelectSheet: string = 'Select Sheet'
export const SelectDraw: string = 'Select Draw'
export const SelectCombination: string = 'Select Combination'
export const playerFilterOn: string = 'player filter on'
export const playerFilterOff: string = 'player filter off'
export const disable: string = 'disable'
export const ManageAPIKeys: string = 'Manage API keys'
export const insertAPPS: string = 'Please, insert APPS'
export const aPIKeys: string[] = ['The key should already be copied to your clipboard.','Please store this key.','If you lose it you will have to generate a new one']
export const Apps: string[] = ['Game Store', 'Internal Wallet', 'Promo Store']
export const KeysCopied: string = 'Key copied to clipboard.'
export const PRIVATEKEY: string = 'PRIVATE KEY'
export const NewKey: string = 'New Key'
export const AddKey: string = 'Add Key'
export const ManageKeys: string = 'Manage Keys'
export const DeleteKEY: string = 'Delete KEY'
export const INSERTKEYNAME: string = 'INSERT KEY NAME'
export const INSERTAPPS: string = 'INSERT APPS'
export const GenerateAPIKeys: string = 'Generate API Keys'
export const Selectednumbers: string = 'Selected numbers'
export const numbers: string = 'numbers'
export const Numbers: string = 'Numbers'
export const bonusnumber: string = 'bonus number'
export const bonusnumbers: string = 'bonus numbers'
export const Bonusnumbers: string = 'Bonus numbers'
export const Bonus: string = 'Bonus'
export const refundnumber: string = 'refund number'
export const refundnumbers: string = 'refund numbers'
export const Refundnumbers: string = 'Refund numbers'
export const Refund: string = 'Refund'
export const extranumber: string = 'extra number'
export const extranumbers: string = 'extra numbers'
export const Extranumbers: string = 'Extra numbers'
export const Extra: string = 'Extra'
export const ResultsForDraw: string = 'Results for draw'
export const LastJackpot: string = 'Last Jackpot'
export const Jackpot: string = 'Jackpot'
export const Draw: string = 'Draw'
export const PriceCurrency: string = 'Price Currency'
export const Nextdraw: string = 'Next draw'
export const LASTDRAW: string = 'LAST DRAW'
export const CutOff: string = 'Cut-off'
export const CutOffhours: string = 'Cut-off hours'
export const DETAILS: string = 'DETAILS'
export const hours: string = 'hours'
export const DEFAULT: string = 'DEFAULT'
export const DEFAULTDesc: string = 'As this is the DEFAULT rule, dates or players cannot be set!'
export const RuleName: string = 'Rule Name'
export const CopyGamesSelection: string = 'Copy Games Selection'
export const CopyGamesSelectionDesc: string = 'Enable in order to copy Games Selection from DEFAULT Rule (this option is useful only for Category Casino)'
export const Stake: string = 'Stake'
export const Fixedodds: string = 'Fixed odds'
export const letters: string = 'letters'
export const Letters: string = 'Letters'
export const Fixedoddstable: string = 'Fixed odds table'
export const FixedoddstableShort: string = 'FOT'
export const Winnings: string = 'Winnings'
export const Play: string = 'Play'
export const ListRaffleGameTypes: string = 'List of Raffle Game Types'
export const ListRaffles: string = 'List of Raffles'
export const ListLotteryGameTypes: string = 'List of Lottery Game Types'
export const ListLotteryDraws: string = 'List of Lottery draws'
export const NumberLetters: string = 'Number of Letters'
export const MaxSerialNumber: string = 'Max. serial number'
export const MinSerialNumber: string = 'Min. serial number'
export const Serialnumbers: string = 'Serial numbers'
export const MaxNumber: string = 'Max. number'
export const MinNumber: string = 'Min. number'
export const Drawtime: string = 'Draw time'
export const Payoutpercentage: string = 'Payout percentage'
export const Identification: string = 'Identification'
export const Winners: string = 'Winners'
export const Playable: string = 'Playable'
export const playable: string = 'playable'
export const playableDesc: string = 'Indicates whether the lottery/raffle is playable.'
export const repeatablenumbers: string = 'repeatable numbers'
export const repeatablenumbersDesc: string = 'Indicates whether the lottery has repeatable numbers.'
export const Selectregulation: string = 'Select regulation'
export const ergt: string = 'Edit the Raffle Game Type'
export const argt: string = 'Add a new Raffle Game Type'
export const elgt: string = 'Edit the Lottery Game Type'
export const algt: string = 'Add a new Lottery Game Type'
export const ListUnusedRaffleTickets: string = 'List of Unused Raffle Tickets'
export const ListRaffleTickets: string = 'List of Raffle Tickets'
export const tickets: string = 'tickets'
export const minrequired: string = 'min. required'
export const Lottery: string = 'Lottery'
export const Raffle: string = 'Raffle'
export const RaffleDetails: string = 'Raffle details'
export const Regular: string = 'Regular'
export const Gametypes: string = 'Gametypes'
export const Actualdraws: string = 'Actual draws'
export const Newticket: string = 'New ticket'
export const OtherTranslations: string = 'Other translations'
export const Categories: string = 'Categories'
export const Favourites: string = 'Favourites'
export const Sports: string = 'Sports'
export const Tournaments: string = 'Tournaments'
export const Matches: string = 'Matches'
export const Match: string = 'Match'
export const AddNewImage: string = 'Add a new image'
export const Images: string = 'Images'
export const Daily: string = 'Daily'
export const ImportFilePlayersSelection: string[] = ['Import plain text file', 'for players selection']
export const MinimalPlayersLevel: string = 'Minimal Player\'s Level to make a purchase in category'
export const NotificationTrigger: string = 'Notification will trigger when one of the items in this category is bought'
export const SportsBetting: string = 'Sports Betting'
export const Coins: string = 'Coins'
export const Widget: string = 'Widget'
export const RewardIntentStrings: string[] = ['Generic', 'Missions', 'Levels', 'Tournaments', 'Challenges', 'Store', 'Mini game']
export const passwordRequirements0: string = 'Password is suitable.'
export const passwordRequirements1: string = 'Password has to contain at least eight characters: At least one lowercase letter, at least one uppercase letter and at least one number.'
export const passwordRequirements2: string = 'Password has to be different from username, email address and telephone number.'
export const passwordRequirements3: string = 'Password has to be different from previous password.'
export const passwordRequirements4: string = 'Password has to be different from username.'
export const passwordsMatch: string = 'Passwords match.'
export const passwordsDontMatch: string = 'Passwords do not match.'
export const pSecurityQuestionAnswer: string = 'security question answer'
export const allow: string = 'allow'
export const disallow: string = 'disallow'
export const static_: string = 'static'
export const dynamic: string = 'dynamic'
export const GroupType: string = 'Group type'
export const Freeze: string = 'Freeze'
export const CSV: string = 'CSV'
export const New: string = 'New'
export const Clone: string = 'Clone'
export const Members: string = 'Members'
export const Timestamps: string = 'Timestamps'
export const Timestamp: string = 'Timestamp'
export const BetSizing: string = 'Bet sizing'
export const Session: string = 'Session'
export const Frequency: string = 'Frequency'
export const Calendar: string = 'Calendar'
export const Deposit: string = 'Deposit'
export const Withdrawal: string = 'Withdrawal'
export const Minimum: string = 'Minimum'
export const Maximum: string = 'Maximum'
export const Within: string = 'Within'
export const NoPlayerActivity = 'No player activity'
export const LargeDeposits = 'Large deposits'
export const LargeWithdrawals = 'Large withdrawals'
export const BlacklistCheck = 'Blacklist check'
export const InternalPlayerRisk = 'Retail - no account online'
export const MaximumAmountOnPlayerWallet = 'Maximum balance'
export const NotificationElement = 'Notification element'
export const TemporaryAccountStatus = 'Temporary account'
export const RiskManagementIssues = 'Risk alerts'
export const KnowYourCustomerIssues = 'KYC issues'
export const ElevatedRisk = 'Elevated risk'
export const BlackListStrings: string[] = [
    'Check central database at registration',
    'Check central database at login',
    'Allow blacklisted player to register',
    'Allow blacklisted player to play',
    'Allow blacklisted player to deposit',
    'Allow blacklisted player to withdraw',
    'Allow blacklisted player to login',
    'Send blacklisted player marketing promotion',
    'Check central database periodically',
    'Check central database during night job',
    'Allow account suspension of blacklisted players',
]
export const TemporaryExclude: string = 'Temporary exclude'
export const PermanentExclusion: string = 'Permanent exclusion'
export const DashboardBackend: string = 'Dashboard backend'
export const NetLimitLoss: string = 'Net limit loss increased'
export const NetLimitLossRegistration: string = 'Net limit loss registration'
export const SelfAssessment: string = 'Self assessment'
export const LastSelfAssessment: string = 'Last self assessment'
export const PreviousSelfAssessmentTaken: string = 'Previous self assessment taken'
export const LastAssessment: string = 'Last assessment'
export const LastSelfAssessmentTaken: string = 'Last self assessment taken'
export const RiskManagement: string = 'Risk Management'
export const RiskManagementShort: string = 'RM'
export const ResponsibleGaming: string = 'Responsible Gaming'
export const ResponsibleGamingShort: string = 'RG'
export const CSCallbackButton: string = 'CS callback button'
export const ResponsibleGamingIssues: string = 'RG issues'
export const ChangePlayerRiskLevel: string = 'Change player risk level'
export const checksStrings: string[] = [
    'Check at registration',
    'Check at login',
]
export const riskLevel: string = 'risk level'
export const RiskLevel: string = 'Risk level'
export const RiskLevelShort: string = 'RL'
export const CurrentRiskLevel: string = 'Current risk level'
export const NewRiskLevel: string = 'New risk level'
export const Actions: string = 'Actions'
export const Action: string = 'Action'
export const Checks: string = 'Checks'
export const withID: string = 'with ID'
export const setting: string = 'setting'
export const Options: string = 'Options'
export const Option: string = 'Option'
export const Indicators: string = 'Indicators'
export const GameCategories: string = 'Game Categories'
export const category: string = 'category'
export const BanSetExclusionDate: string = 'Upon ban set exclusion date to'
export const BanSetExclusionDateDescription: string = 'Upon ban set exclusion date to (format: YYYY-MM-DD)'
export const InitialExclusionPeriod: string = 'Initial exclusion period'
export const InitialExclusionPeriodDescription: string = 'Initial exclusion period while waiting for process to end'
export const BanCanBeLiftedAfterPeriod: string = 'Ban can be lifted'
export const BanCanBeLiftedAfterPeriodDescription: string = 'Ban can be lifted after period of'
export const CheckNightJob: string = 'Check every 24 hours (night job)'
export const Gifts: string = 'Gifts'
export const gift: string = 'gift'
export const Questionnaire: string = 'Questionnaire'
export const withGiftID: string = 'with gift ID'
export const AutomaticallySendNotification: string = 'Automatically send notification after specified has elapsed from registration: '
export const LastDepositRequirementForAutomatedNotification: string = 'Last deposit requirement for automated notification: '
export const SendNotificationVia: string = 'Send notification via: '
export const question: string = 'question'
export const withQuestionID: string = 'with question ID: '
export const AnswersScale: string = 'Answers Scale: '
export const min: string = 'min'
export const max: string = 'max'
export const Log: string = 'Log'
export const AnyUserId: string = 'any user id'
export const AnyUsername: string = 'any username'
export const AnyType: string = 'any type'
export const AnySubtype: string = 'any subtype'
export const AnyDate: string = 'any date'
export const RiskManager: string = 'Risk Manager'
export const DepositMethods: string = 'Deposit methods'
export const DepositAfterCoolOff: string = 'Deposit after cool-off'
export const CancelledWithdrawals: string = 'Cancelled withdrawals'
export const GamingTime: string = 'Gaming time'
export const HigherNetLoss: string = 'Higher net loss'
export const InitiatedCoolOffs: string = 'Initiated cool-offs'
export const IncreasedNetLoss: string = 'Increased net loss'
export const Confirm: string = 'Confirm'
export const AccountSuspension: string = 'Account suspension'
export const NotifyCustomerSupport: string = 'Notify customer support'
export const CallbackList: string = 'Callback List'
export const CallbackOptionActive: string = 'Callback option is active: '
export const CustomerSupportEmail: string = 'Customer support email: '
export const SetRiskLevelCallback: string = 'On callback activation set player\'s risk level to: '
export const Resolved: string = 'Resolved'
export const Unresolved: string = 'Unresolved'
export const KnowYourCustomer: string = 'Know Your Customer'
export const KnowYourCustomerShort: string = 'KYC'
export const PlayerCard: string = 'Player Card'
export const PlayerTimeline: string = 'Player timeline'
export const PlayingHistory: string = 'Playing history'
export const Promotions: string = 'Promotions'
export const GiftShop: string = 'Gift shop'
export const PlayerCardButtonsDescription: string[] = [
    'Summary of all important events that pertain to the player.',
    'Transactions',
    'Game sessions',
    'Risk Management',
    'Responsible Gaming',
    'Know Your Customer',
    'Gamification',
    'Promotions',
    'Gift shop',
    'Tournaments',
]
export const IssueControlPanel: string = 'Issue control panel'
export const PlayerCardButtonsTR: string[] = ['All transactions', 'All deposits', 'All withdrawals', 'Deposits', 'Manual deposits', 'Cancelled deposits', 'Withdrawals', 'Manual withdrawals', 'Cancelled withdrawals']

export const PlayerCardButtonsPS: string[] = ['All sessions', 'Closed', 'Open']





















export const PlayerCardButtonsPR: string[] = ['All promotions', 'Bonus', 'Raffle', 'Other']
export const SelectPlayer: string = 'Select player'
export const PlayerID: string = 'Player ID'
export const FirstName: string = 'First name'
export const LastName: string = 'Last name'
export const Birthday: string = 'Birthday'
export const DepositLimit: string = 'Deposit limit'
export const  DepositSum: string = 'Deposit sum'
export const  DaysOpened: string = 'Days opened'
export const  DeleteAccount: string = 'Delete account'
export const  yearsBeforeDeletion: string = 'Years before deletion'
export const  MonthsBeforeDeletion: string = 'Months before deletion'
export const AddTemporaryAccountStatusRules: string = 'Add temporary account rules'
export const AddNoPlayerActivityRules: string = 'Add no player activity rules'
export const YearsInactive: string = 'Years inactive'
export const MonthsInactive: string = 'Months inactive'
export const AddLargeValueWithdrawalRules: string = 'Add large withdrawals rules'
export const AddLargeValueDepositRules: string = 'Add large deposits rules'
export const WithdrawalLimit: string = 'Withdrawal limit'
export const OncePerPlayer: string = 'Once per player'
export const OneTransaction: string = 'One transaction'
export const WithinHours: string = 'Within hours'
export const AddMaxBalanceOnPlayerWalletRules: string = 'Add maximal balance on player wallet rules'
export const Limit: string = 'Limit'
export const AllowToPlay: string = 'Allow to play'
export const AllowToDeposit: string = 'Allow to deposit'
export const AllowToWithdraw: string = 'Allow to withdraw'
export const AllowToLogin: string = 'Allow to login'
export const AllowPlay: string = 'Allow play'
export const AllowDeposit: string = 'Allow deposit'
export const AllowWithdraw: string = 'Allow withdraw'
export const AllowLogin: string = 'Allow login'
export const AddNewPlayer: string = 'Add a new player'
export const Comment: string = 'Comment'
export const AddNetLimitLossRules: string = 'Add net limit loss rules'
export const TimePeriod: string = 'Time period'
export const HighNetLossTreshold: string = 'High net loss treshold'
export const SettingTakesPlace: string = 'Setting takes place'
export const SettingTakesPlaceUnit: string = 'Setting takes place Unit'
export const SettingActive: string = 'Setting active'
export const AddNetLimitLossRegistrationRules: string = 'Add net limit loss registration rules'
export const DefaultAmount: string = 'Default Amount'
export const DefaultSetting: string = 'Default Setting'
export const AddTemporaryExclusionSettingsRules: string = 'Add temporary exclusion settings rules'
export const CategoriesSelected: string  = 'Categories selected'
export const ManualExclusionFinish: string  = 'Manual exclusion finish'
export const MinimalExclusionTime: string = 'Minimal exclusion time'
export const MinimalExclusionTimeUnit: string = 'Minimal exclusion time unit'
export const DurationUnit: string = 'Duration unit'
export const AddDepositMethodsRules: string = 'Add deposit methods rules'
export const DepositMethodsMinimum: string = 'Deposit methods minimum'
export const WithinUnit: string = 'Within unit'
export const AddDepositAfterCoolOffRules: string = 'Add deposit after cool-off rules'
export const DepositMinimum: string = 'Deposit minimum'
export const AfterCoolOffPercentageHigher: string = 'After cool-off percentage higher'
export const BeforeCoolOffSpan: string = 'Before cool-off span'
export const BeforeCoolOffSpanUnit: string = 'Before cool-off span unit'
export const AddCancelledWithdrawalsRules: string = 'Add cancelled withdrawals rules'
export const WithdrawalRequestCancelledTreshold: string = 'Withdrawal request cancelled treshold'
export const AddGamingTimeRules: string = 'Add gaming time rules'
export const GamingTimeUnit: string = 'Gaming time unit'
export const GamingTimePercentageOver: string = 'Gaming time percentage over'
export const GamingDayMustHaveSession: string = 'Gaming day must have session'
export const GamingDayMinimalSessionLength: string = 'Gaming day minimal session length'
export const GamingDayMinimalSessionLengthUnit: string = 'Gaming day minimal session length unit'
export const AddNetLossRules: string = 'Add net loss rules'
export const NetLossPerHour: string = 'Net loss per hour'
export const AddInitiatedCoolOffsRules: string = 'Add initiated Cool-offs rules'
export const CoolOffsInitiatedThreshold: string = 'Cool-offs initiated threshold'
export const TotalNetLoss: string = 'Total net loss'
export const NetLossLimitIncreasedThreshold: string = 'Net loss limit increased threshold'
export const AddIncreasedNetLossRules: string = 'Add increased net loss rules'
export const AddSelfAssessmentGifts: string = 'Add self assessment gifts'
export const AddAssessmentCriteriaRules: string = 'Add assessment criteria rules'
export const AddQuestions: string = 'Add questions'
export const Email: string = 'Email'
export const Address: string = 'Address'
export const Nationality: string = 'Nationality'
export const City: string = 'City'
// export const MobileNumber: string = 'Mobile number'
export const PostalCode: string = 'Postal code'
export const TC: string = 'T&C'
export const AcceptedTC: string = 'T&C accepted'
export const AcceptedNotTC: string = 'T&C not accepted'
export const NetLossLimit: string = 'Net loss limit'
export const AddQuestion: string = 'Add a question'
export const Next: string = 'Next'
export const Previous: string = 'Previous'
export const InProgress: string = 'In progress'
export const PlayerData: string = 'Player data'
export const BasicInformation: string = 'Basic information'
export const Location: string = 'Location'
export const AccountOverview: string = 'Account overview'
export const Other: string = 'Other'
export const NationalIdCard: string = 'National ID card'
export const Passport: string = 'Passport'
export const DrivingLicense: string = 'Driving License'
export const AllowedDocumentsFormats: string = 'Allowed documents formats'
export const UtilityBill: string = 'Utility bill'
export const BankStatement: string = 'Bank statement'
export const LocalAuthorityTaxBill: string = 'Local authority tax bill'
export const BankAccount: string = 'Bank account'
export const Creditcard: string = 'Creditcard'
export const eWallet: string = 'e-Wallet'
export const ProofOfAddress: string = 'Proof Of Address'
export const ProofOfPayment: string = 'Proof Of Payment'
export const KYCIssues: string = 'KYC issues'
export const Automatic: string = 'Automatic'
export const DocumentationValidity: string = 'Documentation validity: '
export const AddACommentInCaseValidityChanges: string = 'Add a comment in case validity changes.'
export const Jurisdiction: string = 'Jurisdiction'
export const Gender: string = 'Gender'
export const Registered: string = 'Registered'
export const Activated: string = 'Activated'
export const Modified: string = 'Modified'
export const LastLogin: string = 'Last Login'
export const Tags: string = 'Tags'
export const Loaded: string = 'Loaded'
export const NotLoaded: string = 'Not loaded'
export const NotPresent: string = 'Not present'
export const NotInTheSystem: string = 'Not in the system'
export const System: string = 'System'
export const Miscellaneous: string = 'Miscellaneous'
export const PlayerTags: string = 'Player Tags'
export const AddNewTag: string = 'Add a new tag: '
export const playerTag: string = 'player tag'
export const GamificationLevelShort: string = 'Gm. level'
export const GamificationLevel: string = 'Gamification level'
export const OnlineRightNow: string = 'Online right now'
export const NotOnlineRightNow: string = 'Not online right now'
export const SelectedView: string[] = ['Current overview', 'Dashboard backend', 'Gamification', 'Top players', /*'VETO', 'CRIF', 'PEP',*/ 'Accounts data', 'Risk', 'RG', 'KYC', 'GM', 'All']
export const PlayerSettings: string = 'Player Settings'
export const Chart: string = 'Chart'
export const BarChart: string = 'BarChart'
export const PieChart: string = 'PieChart'
export const HalfPieChart: string = 'HalfPieChart'
export const AreaChart: string = 'AreaChart'
export const AreaChart2: string = 'AreaChart2'
export const SpecialLineChart: string = 'SpecialLineChart'
export const Map: string = 'Map'
export const TournamentFinished: string = 'Tournament finished'
export const TournamentInProgress: string = 'Tournament in progress'
export const TournamentNotStartedYet: string = 'Tournament not started yet'
export const TournamentsFinished: string = 'Tournaments finished'
export const TournamentsInProgress: string = 'Tournaments in progress'
export const TournamentsNotStartedYet: string = 'Tournaments not started yet'
export const RaffleFinished: string = 'Raffle finished'
export const RaffleInProgress: string = 'Raffle in progress'
export const NextRaffles: string = 'Next raffles'
export const PreviousRaffles: string = 'Previous raffles'
export const NextRaffle: string = 'Next raffle'
export const MonthlyRaffle: string = 'Monthly raffle'
export const ExecuteMonthlyRaffle: string = 'Execute monthly raffle'
export const RaffleWinners: string = 'Raffle winners'
export const RaffleComponent: string = 'Raffle component'
export const GenericSettings: string = 'Generic Settings'
export const RiskComplianceSettings: string = 'Risk/Compliance settings'
export const Clear: string = 'Clear'
export const TOP: string = 'TOP'
export const Execute: string = 'Execute'
export const Results: string = 'Results'
export const Prizes: string = 'Prizes'
export const ShowMore: string = 'Show more'
export const ShowLess: string = 'Show less'
export const MainRafflePrize: string = 'Main raffle prize'
export const SecondaryRafflePrize: string = 'Secondary raffle prize'
export const ManualDraw: string = 'Manual draw'
export const ShowOnlyElevatedRisk: string = 'Show only elevated risk'
export const History: string = 'History'
export const WageringSummary: string = 'Wagering summary'
export const EventType: string = 'Event type'
export const View: string = 'View'
export const Resolve: string = 'Resolve'
export const AutomaticIssue: string = 'Automatic issue'
export const ResolveTheIssue: string = 'Resolve the issue'
export const MarkAsResolved: string = 'Mark as resolved'
export const LeaveAComment: string = 'Leave a comment'
export const ThisIssueWasResolvedBy: string = 'This issue was resolved by'
export const ThisIssueWasNotResolvedBy: string = 'This issue was not resolved by'
export const NoHistory: string = 'No history'
export const ThisIssueIsBeingResolvedBy: string = 'This issue is being resolved by'
export const ThisIssueWasBroughtUpAutomatically: string = 'This issue was brought up automatically.'
export const Recent: string = 'Recent'
export const Issued: string = 'Issued'
export const CurrentIssueState: string = 'Current issue state'
export const IssueHistory: string = 'Issue history'
export const RFSpecialist: string = 'RF Specialist'
export const RGSpecialist: string = 'RG Specialist'
export const RFSpecialistLong: string = 'Risk fraud Specialist'
export const RGSpecialistLong: string = 'Responsible gaming Specialist'
export const Closed: string = 'Closed'
export const Started: string = 'Started'
export const Issue: string = 'Issue'
export const Marketing: string = 'Marketing'
export const Administrator: string = 'Administrator'
export const YouDontHaveSecurityRights: string = 'You do not possess security rights to change this setting.'
export const ToggleSecurityRightsThatPertainTo: string = 'Toggle security rights that pertain to'
export const SecurityRight: string = 'Security right'
export const ToggleSecurityRight: string = 'Toggle security right'
export const Enabled: string = 'Enabled'
export const Disabled: string = 'Disabled'
export const TermsAndConditions: string = 'Terms & Conditions'
export const isScheduledOn: string = 'is scheduled on'
export const wasExecutedOn: string = 'was executed on'
export const Participants: string = 'Participants'
export const ThisIsTheNextInLineRaffle: string = 'This is the number of participants in the current raffle and number of all tickets in it.'
export const InsertTheseResultsForTheCurrentRaffle: string = 'Insert these results for the current raffle'
export const SelectedRaffle: string = 'Selected raffle'
export const Selected: string = 'Selected'
export const LatestRaffle: string = 'Latest raffle'
export const Scheduled: string = 'Scheduled'
export const Executed: string = 'Executed'
export const NumberOfParticipants: string = 'Number of participants'
export const TotalNumberOfTickets: string = 'Total number of tickets'
export const ExecutedBy: string = 'Executed by'
export const MainPrizeWinner: string = 'Main prize winner'
export const MainPrizeWinners: string = 'Main prize winners'
export const SecondaryPrizeWinners: string = 'Secondary prize winners'
export const ticketsPerParticipant: string = 'tickets per participant'
export const PersonalData: string = 'Personal data'
export const NotReadyYet: string = 'Not ready yet'
export const UpdatedOn: string = 'Updated on'
export const LastIssueUpdate: string = 'Last issue update'
export const pleaseUpgradeUserSecurityRightsForAccess: string = 'Please, upgrade user security rights for access.'
export const pleaseUpgradeUserSecurityRightsForAccessToThisPage: string = 'Please, upgrade user security rights for access to this page.'
export const AllowWithdrawal: string = 'Allow withdrawal'
export const AccountStatus: string = 'Account status'
export const ForceAccountStatus: string = 'Force account status'
export const ForceAccountStatusShort: string = 'Force acc. status'
export const UserSecurityRights: string = 'User security rights'
export const UserData: string = 'User data'
export const Various: string = 'Various'
export const AnyRaffle: string = 'Any Raffle'
export const Issues: string = 'Issues'
export const AllIssues: string = All + ' ' + Issues.toLowerCase()
export const ResolvedIssues: string = Resolved + ' ' + Issues.toLowerCase()
export const UnresolvedIssues: string = Unresolved + ' ' + Issues.toLowerCase()
export const AllRiskAlerts: string = All + ' ' + RiskManagementIssues.toLowerCase()
export const ResolvedRiskAlerts: string = Resolved + ' ' + RiskManagementIssues.toLowerCase()
export const UnresolvedRiskAlerts: string = Unresolved + ' ' + RiskManagementIssues.toLowerCase()
export const UploadedImage: string = 'Uploaded image'
export const UploadedDocument: string = 'Uploaded document'
export const UploadedUtility: string = 'Uploaded utility bill'
export const Username: string = 'Username'
export const ThisItemCannotBeChangedInThePlayerCard: string = 'This item cannot be changed in the Player card'
export const EmailVerified: string = 'Email verified'
export const EmailNotVerified: string = 'Email not verified'
export const PhoneNumberVerified: string = 'Phone number verified'
export const PhoneNumberNotVerified: string = 'Phone number not verified'
export const UtilityUploaded: string = 'Utility uploaded'
export const UtilityBillUploaded: string = 'Utility bill uploaded'
export const UtilityBillVerified: string = 'Utility bill verified'
export const UtilityBillUploadedShort: string = 'Utility bill upload.'
export const UtilityVerified: string = 'Utility verified'
export const DocumentUploadedShort: string = 'Document upload.'
export const DocumentUploaded: string = 'Document uploaded'
export const DocumentVerified: string = 'Document verified'
export const FullyIdentified: string = 'Fully identified'
export const DocumentType: string = 'Document type'
export const DocumentNumber: string = 'Document number'
export const DocumentExpiryDateShort: string = 'Document expiry'
export const DocumentExpiryDate: string = 'Document expiration date'
export const DocumentIssueDateShort: string = 'Document issue d.'
export const DocumentIssueDate: string = 'Document issue date'
export const GettingFullyIdentifiedShort: string = 'Getting fully ident.'
export const GettingFullyIdentified: string = 'Getting fully identified'
export const RealBalance: string = 'Real balance'
export const BonusBalance: string = 'Bonus balance'
export const RegistrationIp: string = 'Registration IP'
export const RegistrationDevice: string = 'Registration device'
export const ResponsibleLimits: string = 'Responsible limits'
export const ResponsibleGamingLimits: string = 'Responsible gaming limits'
export const ChangeDate: string = 'Change date'
export const ChangeDateShort: string = 'Change d.'
export const NewLimit: string = 'New limit'
export const NewLimitShort: string = 'New l.'
export const Cooloffs: string = 'Cool-offs'
export const Ctg: string = 'Ctg'
export const DateCreated: string = 'Date created'
export const ValidUntil: string = 'Valid until'
export const Created: string = 'Created'
export const Nobody: string = 'Nobody'
export const SelectAssignee: string = 'Select assignee'
export const AssignTo: string = 'Assign to'
export const AssignedTo: string = 'Assigned to'
export const IssuingAuthority: string = 'Issuing authority'
export const WildSymbol: string = 'Wild symbol'
export const ScatterSymbol: string = 'Scatter symbol'
export const FreeGames: string = 'Free games'
export const Filter: string = 'Filter'
export const Short: string = 'Short'
export const Long: string = 'Long'
export const Labels: string = 'Labels'
export const GamesSettings: string = 'Games Settings'
export const ID: string = 'ID'
export const Region: string = 'Region'
export const List: string = 'List'
export const Selection: string = 'Selection'
export const AddEditRaffles: string = 'Add/Edit Raffles'
export const EditTheRaffle: string = 'Edit the Raffle'
export const AddANewRaffle: string = 'Add a new Raffle'
export const ScheduleTimestamp: string = 'Schedule timestamp'
export const ExecutionTimestamp: string = 'Execution timestamp'
export const MainWinner: string = 'Main winner'
export const SecondaryWinners: string = 'Secondary winners'
export const tsd: string = 'Tournament start date needs to be set.'
export const ted: string = 'Tournament end date needs to be set.'
export const tst: string = 'Tournament start time needs to be set.'
export const tet: string = 'Tournament end time needs to be set.'
export const tstnc: string = 'Tournament start time is not correct.'
export const tetnc: string = 'Tournament end time is not correct.'
export const tt: string = 'Tournament end datetime cannot be sooner or the same as tournament start datetime.'
export const yu: string = 'If you have a tournament that uses Total Points for the leaderboard, you have to set at least win Points!'
export const ftt: string = 'For the tournament to be valid, you have to select at least one prize.'
export const ftp: string = 'For the prizes to be valid, you have to select at least one prize for the reward for the places'
export const DuplicateTournament: string = 'Duplicate tournament'
export const MinimumBetSizeAmount: string = 'Minimum bet size amount'
export const MaximumBetSizeAmount: string = 'Maximum bet size amount'
export const MinBetSize: string = 'Min. bet size'
export const MaxBetSize: string = 'Max. bet size'
export const ValidGames: string = 'Valid games'
export const TournamentActive: string = 'Tournament active'
export const TournamentInactive: string = 'Tournament inactive'
export const MinimumNumberOfRounds: string = 'Minimum number of rounds'
export const MinRounds: string = 'Min. rounds'
export const MaximumNumberOfRounds: string = 'Maximum number of rounds'
export const MaxRounds: string = 'Max. rounds'
export const TournamentDescriptionOptional: string = 'Tournament description (optional)'
export const WinPoints: string = 'Win points'
export const Toggle: string = 'Toggle'
export const ConsecutiveWins: string = 'Consecutive wins'
export const ConsecutiveLosses: string = 'Consecutive losses'
export const BigWins: string = 'Big wins'
export const MegaWins: string = 'Mega wins'
export const ConsecutiveWinsBooster: string = 'Consecutive wins booster'
export const BigWinsBooster: string = 'Big wins booster'
export const MegaWinsBooster: string = 'Mega wins booster'
export const ToggleOptInCost: string = 'Toggle opt in cost'
export const Cost: string = 'Cost'
export const Sub: string = 'Sub'
export const Include: string = 'Include'
export const Exclude: string = 'Exclude'
export const SelectDeselectCountries: string = '(De)select countries'
export const WhitelistPlayersFromCertainCountry: string = 'Whitelist players from certain country'
export const BlacklistPlayersFromCertainCountry: string = 'Blacklist players from certain country'
export const SelectDeselectNationalities: string = '(De)select nationalities'
export const WhitelistPlayersFromCertainNationality: string = 'Whitelist players from certain nationality'
export const BlacklistPlayersFromCertainNationality: string = 'Blacklist players from certain nationality'
export const SelectJurisdictions: string = 'Select jurisdictions'
export const EnableJurisdiction: string = 'Enable jurisdiction'
export const SelectJurisdictionTiers: string = 'Select jurisdiction tiers'
export const EnableJurisdictionTier: string = 'Enable jurisdiction tier'
export const MinimumAgeToRegisterAnAccount: string = 'Minimum age to register an account'
export const PhoneNumberMandatoryWhileRegistration: string = 'Phone number mandatory while registration'
export const ResponsibleGamingLimitMandatoryWhileRegistration: string = 'Responsible gaming limit mandatory while registration'
export const AllowedPlayerCountries: string = 'Allowed player countries'
export const AllowedPlayerNationalities: string = 'Allowed player nationalities'
export const ListOfDefinedRegionsByCountry: string = 'List of defined regions (by country)'
export const ListOfDefinedPostalCodesByCountryAndRegion: string = 'List of defined postal codes (by country and region)'
export const PostalCodes: string = 'Postal codes'
export const EditPostalCode: string ='Edit Postal code'
export const EditPostalCodeName: string = 'Edit Postal code name'
export const EditPostalCodeRegion: string = 'Edit Postal code region'
export const StopEditingPostalCode: string = 'Stop editing Postal code'
export const StartEditingPostalCode: string = 'Start editing Postal code'
export const RemovePostalCode: string = 'Remove Postal code'
export const AddANewPostalCode: string = 'Add a new postal code'
export const CountriesAndTheirInternationalCallingCodes: string =  'Countries and their international calling codes'
export const AreYouSureThatYouWantToDeletePostalCodeWithCode: string = 'Are you sure that you want to delete postal code with code'
export const SelectCountry: string = 'Select country'
export const SelectNationality: string = 'Select nationality'
export const SelectJurisdiction: string = 'Select jurisdiction'
export const SelectJurisdictionTier: string = 'Select jurisdiction tier'
export const SelectNumberOfDaysUntilAccountExpiry: string = 'Select number of days until account expiration'
export const Tier: string = 'Tier'
export const CountryCode: string = 'Country code'
export const CountryName: string = 'Country name'
export const CountryDialCode: string = 'Country dial code'
export const NationalityCode: string = 'Nationality code'
export const JurisdictionCode: string = 'Jurisdiction code'
export const Unclassified: string = 'Unclassified'
export const AddANewCountry: string = 'Add a new country'
export const AddANewNationality: string = 'Add a new nationality'
export const AddANewJurisdiction: string = 'Add a new jurisdiction'
export const DialCode: string = 'Dial code'
export const toj: string = 'Tier of jurisdiction has to be set between 0 and 3.'
export const GamesCanBeCategorizedInTheFollowingCategories: string = 'Games can be categorized in the following categories'
export const EachGameCanBeGivenOneOrMoreLabelsBelow: string = 'Each game can be given one (or more) labels below'
export const AllowedJurisdictions: string = 'Allowed jurisdictions'
export const EachGameCanBeSupportedonOneOrMoreDevicesBelow: string = 'Each game can be supported on one (or more) devices below'
export const VideoLotteryTerminal: string = 'Video Lottery Terminal'
export const VideoLotteryTerminalAbbrev: string = 'VLT'
export const RemoveLabel: string = 'Remove label'
export const PlayerCardAccessAndModificationRules: string = 'Player card access and modification rules'
export const AllPlayerCardInformationIsSentTo: string = 'All Player Card information is sent to'
export const ThisIsTheDefaultLanguage: string = 'This is the default language'
export const ClickToSetTheLanguageAsTheDefaultLanguage: string = 'Click to set the language as the default language'
export const IncludeThisLanguage: string = 'Include this language'
export const ThisIsTheDefaultChoice: string = 'This is the default choice'
export const ThisIsNotTheDefaultChoice: string = 'This is not the default choice'
export const ThisIsTheDefaultCurrency: string = 'This is the default currency'
export const ClickToSetTheCurrencyAsTheDefaultCurrency: string = 'Click to set the language as the default currency'
export const IncludeThisCurrency: string = 'Include this currency'
export const Limits: string = 'Limits'
export const Rules: string = 'Rules'
export const Cool_offs: string = 'Cool-offs'
export const CPC: string = 'CLONED - PLEASE CHANGE'
export const playerCardProperty: string[] = ['player id','username','email','first name','last name','balance','gender','birthday','nationality','jurisdiction','account status','currency','accepted T&C','risk level','gamification level','allow play','allow login','allow deposit','allow withdraw','is email verified','is utility bill uploaded','is utility bill verified','is document uploaded','is document verified','is fully identified','document type','issuing authority','document number','document expiry','document issue date','address','postal code','city','country','phone number','registered timestamp','last login timestamp','currently logged in','responsible gaming limits','self assessment','tags','real balance','bonus balance','cool-off periods','registration ip','registration device','is getting fully identified','is phone number verified', 'is address verified', 'politically exposed person', 'allow SMS', 'allow e-mail', 'allow telephone', 'allow post', 'IBAN', 'beneficiary name', 'BIC', 'street number', 'bally id', 'test account']
export const isSentToUserClient: string = 'is sent to user (client)'
export const canBeViewedByUser: string = 'can be viewed by user'
export const canBeModifiedByUser: string = 'can be modified by user'
export const canBeModifiedBy: string = 'can be modified by'
export const YearsDeletion: string = 'Years (deletion)'
export const MonthsDeletion: string = 'Months (deletion)'
export const Conditions: string = 'Conditions'
export const Consequences: string = 'Consequences'
export const LetThrough: string = 'Let through'
export const AConditionRule: string = 'A. Condition (rule)'
export const BConsequence: string = 'B. Consequence'
export const AddANewAction: string = 'Add a new action'
export const SelectPlayerWithId: string = 'Select player with id'
export const Unit: string = 'Unit'
export const MinPoints: string = 'Min. points'
export const MaxPoints: string = 'Max. points'
export const pg1: string = 'Do you want to freeze the players in this moment (old data will be overwritten)?'
export const sap1: string = 'Answers (points) and Rating points given for each answer'
export const ShownToPlayer: string = 'Shown to player'
export const QuestionTextDifferentLanguages: string = 'Question text (different languages)'
export const AnswersAndRatingPointsGivenForEachAnswer: string = 'Answers and rating points given for each answer'
export const WithdrawalRequestsCancelledThreshold: string = 'Withdrawal requests cancelled threshold'
export const GamingDayMinSessionLength: string = 'Gaming day min. session length'
export const NeedsVerification: string = 'Needs verification'
export const IssueClassification: string = 'Issue classification'
export const Answer: string = 'Answer'
export const Overview: string = 'Overview'
export const Bet: string = 'Bet'
export const Bets: string = 'Bets'
export const Tax: string = 'Tax'
export const BonusCosts: string = 'Bonus costs'
export const JackpotCosts: string = 'Jackpot costs'
export const Free_RoundCosts: string = 'Free-round costs'
export const ReturnToPlayerAbbrev: string = 'RTP'
export const ReturnToPlayer: string = 'Return to player'
export const GrossGamingRevenueAbbrev: string = 'GGR'
export const GrossGamingRevenue: string = 'Gross gaming revenue'
export const NetGamingRevenueAbbrev: string = 'NGR'
export const NetGamingRevenue: string = 'Net gaming revenue'
export const PeriodDate: string = 'Period date'
export const ShowSingleGamesDataFor: string = 'Show single games data for'
export const BackToOverview: string = 'Back to overview'
export const SingleGamesData: string = 'Single games data'
export const SessionID: string = 'Session ID'
export const Subtype: string = 'Subtype'
export const SiteID: string = 'Site ID'
export const SiteIDShort: string = 'SD'
export const Status: string = 'Status'
export const GameStart: string = 'Game start'
export const Completed: string = 'Completed'
export const StakesPlaced:string = 'Stakes placed'
export const GameEnd:string = 'Game end'
export const BalanceInit:string = 'Balance init'
export const BAfterBet:string = 'B. after bet'
export const BAfterEnd:string = 'B. after end'
export const TotalBet:string = 'Total Bet'
export const IsWin:string = 'Is win'
export const WinAmount:string = 'Win amount'
export const NetAmount:string = 'Net amount'
export const Screen:string = 'Screen'
export const TotalBets:string = 'Total bets'
export const TotalRatio:string = 'Total ratio'
export const TotalRTP:string = 'Total RTP'
export const TotalSpins:string = 'Total spins'
export const TotalWinAmount:string = 'Total win amount'
export const TotalWinningSpins:string = 'Total winning spins'
export const LastidTimestamp:string = 'Lastid timestamp'
export const Difference:string = 'Difference'
export const LastProcessedEventAt:string = 'Last processed event at'
export const GamesWereUpdatedForTheLastTimeOn:string = 'Games were updated for the last time on'
export const ForgotPasswordResetForm:string = 'Forgot Password Reset Form'
export const UpdateTheGamesDatabase:string = 'Update the games database'
export const PlayerUsername:string = 'Player username'
export const Amount:string = 'Amount'
export const ExpiredOn:string = 'Expired on'
export const ExpiresOn:string = 'Expires on'
export const ExpirationDays:string = 'Expiration days'
export const Expiration:string = 'Expiration'
export const LoggedInPlayers:string = 'Logged in players'
export const LatestDeposits:string = 'Latest deposits'
export const LatestFirstDeposits:string = 'Latest first deposits'
export const LatestWithdrawals: string = 'Latest withdrawals'
export const LatestRegistrations: string = 'Latest registrations'
export const LatestLogins: string = 'Latest logins'
export const LatestLogouts: string = 'Latest logouts'
export const LatestIssues:string = 'Latest issues'
export const LatestUnresolvedIssues:string = 'Latest unresolved issues'
export const LatestResolvedIssues:string = 'Latest resolved issues'
export const ExpiredProvisionalAccounts: string = 'Expired provisional accounts'
// export const ProvisionalAccounts:string = 'Provisional Accounts'
export const Expired:string = 'Expired'
export const jackpotsRaffle: string[] = ['1. The raffle takes place at month end at a predefined time (8 pm).','2. All Raffle Tickets collected after the defined time will go into the raffle of the next month. A player is offered a raffle ticket every 1000 status points.','3. There is one main prize and a custom number of secondary prizes. Each player can win one prize at most.','4. The raffle is based on a random selection and the prizes of the raffle can be a choice of several prices for the player. The draw is an adjustable manual/automatic for the first gifts, the small ones in a same draw are given automatically.']
export const SelectGameLabels:string = 'Select game labels'
export const SelectGameShortDescription:string = 'Select game short description'
export const SelectGameLongDescription:string = 'Select game long description'
export const locale:string = 'locale'
export const AnyGameProvider:string = 'Any game provider'
export const GameCategory:string = 'Game category'
export const Unapproved:string = 'Unapproved'
export const StoreApprovedGames:string = 'Store - Approved Games'
export const GameStoreAllGames:string = 'Game store - All Games'
export const NumberOfApprovedGames:string = 'Number of approved games'
export const NumberOfAllGames:string = 'Number of all games'
export const NoDetails:string = 'No details'
export const ViewDetails:string = 'View details'
export const AddANewGame:string = 'Add a new game'
export const GameLink:string = 'Game link'
export const NoLink:string = 'No link'
export const ShowLink:string = 'Show link'
export const Jurisdictions: string = 'Jurisdictions'
export const GameLaunchID: string = 'Game Launch ID'
export const OpenTheGameInNewWindow: string = 'Open the game in new window'
export const tJackpotGame: string = 'This is a jackpot game'
export const tJackpotGameNOT: string = 'This is NOT a jackpot game'
export const CopyLinkToClipboard: string = 'Copy link to clipboard'
export const DeselectGame:string = 'Deselect game'
export const SymbolsShort:string = 'Sym'
export const Symbols:string = 'Symbols'
export const Symbol:string = 'Symbol'
export const Rows:string = 'Rows'
export const GPCategoryNotDefined: string = 'GP Category not defined'
export const RGSGSNotDefined: string = 'RGSGS not defined'
export const RGSGSupplier: string = 'RGSG Supplier'
export const Wild:string = 'Wild'
export const Free:string = 'Free'
export const GameImage:string = 'Game image'
export const Devices:string = 'Devices'
export const Scatter:string = 'Scatter'
export const GameProviderSupplier:string = 'Game provider/supplier'
export const GameProviderCategory:string = 'Game provider category'
export const Supported:string = 'Supported'
export const ApprovedJurisdictions:string = 'Approved jurisdictions'
export const ActiveJurisdictions:string = 'Active jurisdictions'
export const AllJurisdictions:string = 'All jurisdictions'
export const Approved:string = 'Approved'
export const Percentage:string = 'Percentage'
export const Paylines:string = 'Paylines'
export const TheGameIsApprovedInJurisdiction:string = 'The game is approved in jurisdiction'
export const TheGameCanBeApprovedInJurisdiction:string = 'The game can be approved in jurisdiction'
export const TheGameCannotBeApprovedInTheJurisdiction:string = 'The game cannot be approved in the jurisdiction'
export const gameTitle0: string = 'This game is active.'
export const gameTitle1: string = 'This game can be activated (Active switch on the right).'
export const gameTitle2: string = 'This game needs to be approved so that it can be activated.'
export const sfh: string = 'Some fields have to be filled out before saving a bonus.'
export const AnyPlayerLevel: string = 'Any player level'
export const TriggerType: string = 'Trigger type'
export const TriggerSubtype: string = 'Trigger subtype'
export const AddEditBonus: string = 'Add/Edit Bonus'
export const EditTheBonus: string = 'Edit the Bonus'
export const AddANewBonus: string = 'Add a new Bonus'
export const Coexist: string = 'Coexist'
export const SelectPlayerLevel: string = 'Select player level'
export const BonusIsClaimable: string = 'Bonus is claimable'
export const LifetimeAfterBeingClaimed: string = 'Lifetime after being claimed'
export const ClaimableTimeValue: string = 'Claimable time value'
export const ActivatedInstantly: string = 'Activated instantly'
export const BonusBeingActivatedAfterTriggered: string = 'Bonus being activated after triggered'
export const BonusCode: string = 'Bonus code'
export const AmountWagered: string = 'Amount wagered'
export const Multi_ActivityTriggers: string = 'Multi-activity triggers'
export const Multi_ActivityTriggerTimeValue: string = 'Multi-activity trigger time value'
export const ListOfBonus: string = 'List of Bonus'
export const PriorityDesc: string = 'A higher number indicates a higher priority'
export const CoexistDesc: string = 'Can this bonus coexist with other bonuses'
export const AmtWagered: string = 'Amt. wagered'
export const PlayerLevel: string = 'Player level'
export const NonExistent: string = 'Non-existent'
export const SettingsWereSuccessfullyUpdated: string = 'Settings were successfully updated'
export const u1: string = 'Update is not allowed as this risk level value is not allowed for retail.'
export const t1: string = 'There is something wrong with your link to reset password.'
export const y1: string = 'Your username does not exist in our system, please provide the correct username.'
export const y2: string = 'Your password was successfully changed. Please login to your e-mail account, as link to change the password was sent there.'
export const Found: string = 'Found'
export const gitdf: string = ' got into the draw for '
export const YourTestTicketIDIs: string = 'Your test Ticket ID is'
export const c1: string = 'Congratulations, you won the'
export const TicketID: string = 'Ticket ID'
export const On: string = 'On'
export const p1: string = 'Please describe the reason for giving out coins!'
export const sc1: string = 'Set "Coins" to at least 1 coin!'
export const PlayersAreNotDefined: string = 'Players are not defined'
export const sc2: string = 'Select at least one player to give coins!'
export const DoYouWantToGive: string = 'Do you want to give'
export const d1: string = 'coins to player(s)'
export const d2: string = ' coins were given to player(s): '
export const d3: string = ' coins were given to (new) player(s): '
export const d4: string = 'Please describe the reason for removing coins!'
export const d5: string = 'Set "Coins" to at least 1 coin!'
export const d6: string = 'Select at least one player to remove coins!'
export const DoYouWantToRemove: string = 'Do you want to remove'
export const d7: string = ' coins from player(s) '
export const d8: string = ' coins were removed from player(s): '
export const r1: string = 'Could not change user data. Reason: '
export const ThisUsernameIsAlreadyTaken: string = 'This username is already taken.'
export const d9: string = 'Please define Deposit, which needs to be more than 0!'
export const r2: string = 'Successfully placed reward for player'
export const AnyProvider: string = 'Any provider'
export const OpenPlayerCard: string = 'Open player card'
export const SelectAPlayerFromTheListBelow: string = 'Select a player from the list below'
export const NoCoolOffsAreDefined: string = 'No Cool-offs are defined'
export const p2: string = 'Player(s) with unresolved issues'
export const players_: string = 'player(s)'
export const days_: string = 'day(s)'
export const issues_: string = 'issue(s)'
export const f1: string = 'Force Player card refresh - no changes to save'
export const f2: string = 'Force Player card refresh - there are some changes that need to be saved'
export const MoveOnTop: string = 'Move on top'
export const p3: string = 'Country of the player cannot be changed.'
export const UpdatePlayerCard: string = 'Update player card'
export const SelectedSessionId: string = 'Selected sessionId'
export const Contains: string = 'Contains'
export const t2: string = 'All game sessions are deselected at the moment. Please select at least one game session to show data.'
export const DeselectSession: string = 'Deselect session'
export const FurtherInformation: string = 'Further information'
export const SessionWithID: string = 'Session with ID'
export const isSelected: string = 'is selected'
export const NoBets: string = 'No bets'
export const Deposits: string = 'Deposits'
export const Withdrawals: string = 'Withdrawals'
export const Uploaded: string = 'Uploaded'
export const mf: string = 'm (male) or f (female) gender'
export const HasNotTakenTheTestYet: string = 'Has not taken the test yet.'
export const RaffleIsAlreadySelected: string = 'Raffle is already selected'
export const ShowDetailsForThisRaffle: string = 'Show details for this raffle'
export const PlayerCity: string = 'Player city'
export const Prize: string = 'Prize'
export const NotSelected: string = 'Not selected'
export const Tickets: string = 'Tickets'
export const t3: string = 'Toggle manual raffle draw for the main prize.'
export const YouWillBeLoggedOutIn: string = 'You will be logged out in'
export const CurrentSystemTime: string = 'Current system time'
export const d10: string = '1 draw altogether'
export const d11: string = 'draws altogether'
export const d12: string = '1 draw remaining'
export const d13: string = 'draws remaining'
export const d14: string = 'No more draws'
export const d15: string = 'No draw completed yet'
export const BoostMissionsMultiplier: string = 'Boost missions multiplier'
export const BoostPointsMultiplier: string = 'Boost points multiplier'
export const Combination: string = 'Combination'
export const ExtraNumber: string = 'Extra number'
export const BonusNumber: string = 'Bonus number'
export const SerialNumber: string = 'Serial number'
export const RefundNumber: string = 'Refund number'
export const Winner: string = 'Winner'
export const Letter: string = 'Letter'
export const Disable: string = 'Disable'
export const StartDatetime: string = 'Start Datetime'
export const EndDatetime: string = 'End Datetime'
export const ExpirationTime: string = 'Expiration time'
export const SpinsMinigameName: string = 'Spins minigame name'
export const SpinsMinigameRewards: string = 'Spins minigame rewards'
export const Days: string = 'Days'
export const Valid: string = 'Valid'
export const CashBackPercentage: string = 'Cash back percentage'
export const CashBackAmount: string = 'Cash back amount'
export const BonusPercent: string = 'Bonus percent'
export const GameNames: string = 'Game names'
export const BetLevels: string = 'Bet levels'
export const SpinValue: string = 'Spin value'
export const SpinsTotalValue: string = 'Spins total value'
export const SpinsAmount: string = 'Spins amount'
export const FirstWeek: string = 'First week'
export const SecondWeek: string = 'Second week'
export const ThirdWeek: string = 'Third week'
export const FourthWeek: string = 'Fourth week'
export const FifthWeek: string = 'Fifth week'
export const BigWin: string = 'Big win'
export const andPath: string = 'and path'
export const LanguageCode: string = 'Language code'
export const LanguageNative: string = 'Language native'
export const LanguageDirection: string = 'Language direction'
export const Native:string = 'Native'
export const Direction:string = 'Direction'
export const Flag:string = 'Flag'
export const l1: string = 'Do you want to reset password for username'
export const l2: string = 'Please enter an username to retrieve a forgotten password.'
export const l3: string = 'Please enter an email address to retrieve a forgotten password.'
export const l4: string = 'Please enter a security answer.'
export const l5: string = 'Please enter a phone number to retrieve a forgotten password.'
export const SetNewPassword: string = 'Set new Password'
export const BackToLogin: string = 'Back to Login'
export const l6: string = 'Access to this page is not authorized.'
export const WaitingForAuthorization: string = 'Waiting for authorization'
export const BuiltOn: string = 'Built on'
export const STAGE: string = 'STAGE'
export const LoginWith: string = 'Login with'
export const Everything: string = 'Everything'
export const Forgot: string = 'Forgot'
export const Password: string = 'Password'
export const l8: string = 'You have been logged out due to inactivity!'
export const l9: string = 'There is a problem with your api key. Access to the GaminGenius® Admin is not allowed.'
export const l10: string = 'Wrong password! Please enter the correct password or use \'Forgot Password?\' function.'
export const l11: string = 'If you do not remember your email address, please contact GaminGenius® support.'
export const l12: string = 'If you do not remember your phone number, please contact GaminGenius® support.'
export const l13: string = 'If you do not remember the answer to your security question, please contact GaminGenius® support.'
export const l14: string = 'The answer to your security question is not correct!'
export const was_hit: string = 'was hit'
export const sc3: string = 'scale must be a positive number <1'
export const DeleteTranslationsFor: string = 'Delete translations for'
export const CopyTheTranslationsFromDefaultLanguageInto: string = 'Copy the translations from default language into'
export const Del: string = 'Del'
export const Prices: string = 'Prices'
export const Discounts: string = 'Discounts'
export const Info: string = 'Info'
export const ClearSelection: string = 'Clear selection'
export const r3: string = 'Exactly one player should be selected to give the reward.'
export const shouldBeDefined: string = 'should be defined'
export const AmountForCurrency: string = 'Amount for currency'
export const BetLevelForCurrency: string = 'Bet Level for currency'
export const FreespinAmountForCurrency: string = 'Freespin Amount for currency'
export const r4: string = 'Please define the reward type.'
export const r5: string = 'Please define the product type.'
export const r6: string = 'Please define the wagering type.'
export const r7: string = 'Valid days should be more than zero.'
export const r8: string = 'Please select at least one game.'
export const r9: string = 'If multiple games are being selected, they all need to be from NetEnt.'
export const r10: string = 'At least one game does not support non-flexible freespins.'
export const r11: string = 'At least one game does not support flexible freespins.'
export const FreespinAmount: string = 'Freespin Amount'
export const MegaspinAmount: string = 'Megaspin Amount'
export const MinimumDeposit: string = 'Minimum deposit'
export const MinDeposit: string = 'Min. deposit'
export const MaximumDeposit: string = 'Maximum deposit'
export const MaxDeposit: string = 'Max. deposit'
export const MaxDepositMatch: string = 'Max. deposit match'
export const MaximumDepositMatch: string = 'Maximum deposit match'
export const ViewTheRewardShopItem: string = 'View the Reward Shop Item'
export const GiveThisRewardToASpecifiedPlayer: string = 'Give this reward to a specified player'
export const DetailedSettings:string = 'Detailed settings'
export const OfferThisRewardOnlyToSpecifiedPlayers:string = 'Offer this reward only to specified players'
export const TranslationsNameInfo: string = 'Translations (name + info)'
export const SetRewardDuration: string = 'Set Reward duration'
export const CloneTheReward: string = 'Clone the reward'
export const r12: string = 'Indicates whether the item is buyable only for newly registered players (up to level Wood(1), sublevel 3)'
export const leaveEmptyIfIndefinite: string = 'leave empty if indefinite'
export const SelectAllPlayers: string = 'Select All Players'
export const DeselectAllPlayers: string = 'Deselect All Players'
export const PlayerSelection: string = 'Player Selection'
export const r13: string = 'Give the reward to the specified player (in the specifed currency)'
export const ProductType: string = 'Product Type'
export const r14: string = 'Indicates, whether the freespin is flexible or not'
export const Flexible: string = 'Flexible'
export const WageringType: string = 'Wagering Type'
export const WageringFactor: string = 'Wagering Factor'
export const ValidDays: string = 'Valid days'
export const MatchValue: string = 'Match value'
export const AllMultipleGamesAreFromNetEnt: string = 'All multiple games are from NetEnt!'
export const AllMultipleGamesAreNotFromNetEnt: string = 'All multiple games are not from NetEnt!'
export const FlexibleFreespinSuitability: string = 'Flexible Freespin Suitability'
export const FreespinSuitability: string = 'Freespin Suitability'
export const DepositAmount: string = 'Deposit amount'
export const MinimumOdds: string = 'Minimum odds'
export const MinimumAmount: string = 'Minimum amount'
export const MinOdds: string = 'Min. odds'
export const gamesAreSelected: string = 'games are selected'
export const Newly: string = 'Newly'
export const Multiple: string = 'Multiple'
export const Multi: string = 'Multi'
export const Once: string = 'Once'
export const indefinite: string = 'indefinite'
export const Disc_: string = 'Disc.'
export const D_Price: string = 'D. Price'
export const onlyNewlyRegisteredPlayers: string = 'only newly registered players'
export const itemCanBeBoughtMultipleTimes: string = 'item can be bought multiple times'
export const Freeround: string = 'Freeround'
export const AddEditCalendar: string = 'Add/Edit Calendar'
export const defaultTitleMoreLanguages: string = 'default title (more languages)'
export const calendarEnd: string = 'calendar end'
export const calendarStart: string = 'calendar start'
export const defaultActionButtonTextMoreLanguages: string = 'default action button text (more languages)'
export const titleMoreLanguages: string = 'title (more languages)'
export const actionButtonLanguages: string = 'action button (languages)'
export const tcMoreLanguages: string = 't&c (more languages)'
export const ShowSettings: string = 'Show settings'
export const CloseSettings: string = 'Close settings'
export const SaveSettings: string = 'Save settings'
export const DefaultLanguage: string = 'Default language'
export const Autoresolved: string = 'Autoresolved'
export const ResolvedAutomatically: string = 'Resolved automatically'
export const Studio: string = 'Studio'
export const Thumb_: string = 'Thumb.'
export const Backgr_: string = 'Backgr.'
export const Folder: string = 'Folder'
export const MinSublevel: string = 'Min. sublevel'
export const MaxSublevel: string = 'Max. sublevel'
export const RewardPrice: string = 'Reward price'
export const AddReward: string = 'Add Reward'
export const RemoveReward: string = 'Remove Reward'
export const RewardSelection: string = 'Reward selection'
export const AddANewStore: string = 'Add a new Store'
export const EditTheStore: string = 'Edit the Store'
export const AddEditStore: string = 'Add/Edit Store'
export const StoreName: string = 'Store name'
export const Purch_Date: string = 'Purch date'
export const RewardShopTransferCoins: string = 'Reward Shop Transfer Coins'
export const DEVELOPMENT: string = 'DEVELOPMENT'
export const Download: string = 'Download'
export const Downloaded: string = 'Downloaded'
export const rgs1: string = 'Some fields have to be filled out before saving a RGS Game supplier.'
export const RgsGameSupplier: string = 'RGS game supplier'
export const RgsGameSuppliers: string = 'RGS Game Suppliers'
export const AddGameProviderGamesCategory: string = 'Add Game provider games category'
export const RemoveGameProviderGamesCategory: string = 'Remove Game provider games category'
export const AddGameProviderRgsGameSupplier: string = 'Add Game provider RGS game supplier'
export const RemoveGameProviderRgsGameSupplier: string = 'Remove Game provider RGS game supplier'
export const lRgsGameSupplier: string = 'List of RGS Game Suppliers'
export const eRgsGameSupplier: string = 'Edit the RGS Game Supplier'
export const aRgsGameSupplier: string = 'Add a new RGS Game Supplier'
export const aeRgsGameSupplier: string = 'Add/Edit RGS Game Supplier'
export const gp1: string = 'Some fields have to be filled out before saving a Game provider.'
export const lGameProvider: string = 'List of Game Providers'
export const eGameProvider: string = 'Edit the Game Providers'
export const aGameProvider: string = 'Add a new Game Providers'
export const GameLaunchEndpoint: string = 'Game launch endpoint'
export const GameIntegrationEndpoint: string = 'Game integration endpoint'
export const GameProviderGamesCategory: string = 'Game provider games category'
export const GameProviderGamesCategories: string = 'Game provider games categories'
export const NoneDefined: string = 'None defined'
export const SelectRGSGameSupplier: string = 'Select RGS Game Supplier'
export const GameIntegrationEndPointShouldNotBeDuplicated: string = 'Game integration end point should not be duplicated.'
export const GameNameShouldNotBeDuplicated: string = 'Game name should not be duplicated.'
export const GameProviderNameShouldNotBeDuplicated: string = 'Game provider name should not be duplicated.'
export const BonusNameShouldNotBeDuplicated: string = 'Bonus name should not be duplicated.'
export const BonusCampaignNameShouldNotBeDuplicated: string = 'Bonus campaign name should not be duplicated.'
export const RgsGameSupplierNameShouldNotBeDuplicated: string = 'RGS game supplier name should not be duplicated.'
export const SelectMulti_ActivityTriggerTime: string = 'Select multi-activity trigger time'
export const GiveCoinsToTheSelectedPlayers_: string = 'Give coins to the selected player(s)'
export const GiveCoins: string = 'Give coins'
export const RemoveCoins: string = 'Remove coins'
export const RemoveCoinsFromTheSelectedPlayers_: string = 'Remove coins from the selected player(s)'
export const AmountOfCoinsToGiveToSelectedPlayers_: string = 'Amount of coins to give to selected player(s)'
export const DescribeTheReasonForGivingOut_RemovingCoins: string = 'Describe the reason for giving out (removing) coins'
export const AmountOfCoinsToGiveOnRegistration: string = 'Amount of coins to give on registration'
export const CoinsGivenOnRegistration: string = 'Coins given on registration'
export const ShowInfo: string = 'Show info'
export const TheRewardTypeFieldIsCompulsory: string = 'The Reward Type field is compulsory.'
export const RewardDescription: string = 'Reward Description'
export const SelectRewardIntent: string = 'Select Reward Intent'
export const RewardIntent: string = 'Reward Intent'
export const NoRewardTypeIsSelected: string = 'No Reward Type is selected'
export const SelectRewardType: string = 'Select Reward Type'
export const AddEditRewards: string = 'Add/Edit Rewards'
export const EditTheReward: string = 'Edit the Reward'
export const AddANewReward: string = 'Add a new Reward'
export const RewardName: string = 'Reward name'
export const BoostMissions: string = 'Boost missions'
export const BoostPoints: string = 'Boost points'
export const BoostTime: string = 'Boost time'
export const DurationHours: string = 'Duration hours'
export const DurationMinutes: string = 'Duration minutes'
export const DurationSeconds: string = 'Duration seconds'
export const ExpirationTime_Days: string = 'Expiration time (days)'
export const CashBack: string = 'Cash back'
export const CashBonusAmount: string = 'Cash bonus amount'
export const ClickToToggleBetweenFreespinAndFlexibleFreespin: string = 'Click to toggle between freespin and flexible freespin'
export const ClickToChangeTheSelection: string = 'Click to change the selection'
export const RewardDescriptionOptional: string = 'Reward description (optional)'
export const RemoveTheReward: string = 'Remove the Reward'
export const DepositBonus: string = 'Deposit bonus'
export const DepositBonusAmount: string = 'Deposit bonus amount'
export const DepositBonusPercentage: string = 'Deposit bonus percentage'
export const ClickToIncludeGames: string = 'Click to include games'
export const ThereIs1IncludedGame: string = 'There is 1 included game'
export const includedGames: string = 'included games'
export const BetLevelsAbbrev: string = 'BLs'
export const FreeSpinsValue: string = 'Free spins value'
export const FreeSpinsAmount: string = 'Free spins amount'
export const SelectGame_s: string = 'Select game(s)'
export const ClickToSelectGame_s: string = 'Click to select game(s)'
export const FreeSpinsTotalValue: string = 'Free spins total value'
export const ClickToExcludeGames: string = 'Click to exclude games'
export const ThereIs1ExcludedGame: string = 'There is 1 excluded game'
export const excludedGames: string = 'excluded games'
export const AddAssignee: string = 'Add assignee'
export const QuestionnaireDate: string = 'Questionnaire date'
export const crp1: string = 'Large deposit/withdrawal triggered mandatory account verification (for Fully identified account)'
export const crp2: string = 'Would you like to set this large deposit/withdrawal as verified (account of this player will then become "Fully identified")?'
export const crp3: string = 'You have to fill all five fields above in order to have the possibility to set this document as verified.'
export const crp4: string = 'Would you like to set this document as verified?'
export const crp5: string = 'Would you like to set this utility bill as verified?'
export const ThisQuestionIsUnknown: string = 'This question is unknown'
export const BackPage: string = 'Back page'
export const FrontPage: string = 'Front page'
export const ViewTheIssue: string = 'View the issue'
export const SecurityRights: string = 'Security rights'
export const Date_Time: string = 'Date/Time'
export const Fonts:string = 'Fonts'
export const Boosters:string = 'Boosters'
export const Multipliers:string = 'Multipliers'
export const Banners:string = 'Banners'
export const MariasCorner:string = 'Maria\'s Corner'
export const Sublevel: string = 'Sublevel'
export const Export: string = 'Export'
export const Data: string = 'Data'
export const EntityID: string = 'Entity ID'
export const ShowDefaultRiskValues: string = 'Show default risk values'
export const LowRisk: string = 'Low risk'
export const NormalRisk: string = 'Normal risk'
export const MediumRisk: string = 'Medium risk'
export const HighRisk: string = 'High risk'
export const SevereRisk: string = 'Severe risk'
export const CounselingCentres_ByCanton: string = 'Counseling centres (by canton)'
export const pg2: string = 'Player groups (by days until provisional account expires)'
export const ShowTheFollowingPlayerGroups: string = 'Show the following player groups'
export const ShowAllExpiringTemporaryAccounts: string = 'Show all expiring temporary accounts'
export const ShowExpiredTemporaryAccounts: string = 'Show expired temporary accounts'
export const Expired_ExpiringTemporaryAccounts: string = 'Expired/Expiring temporary accounts'
export const CantonName: string = 'Canton name'
export const CounselingCentreName: string = 'Counseling centre name'
export const PhoneNumber: string = 'Phone number'
export const PostalC_: string = 'Postal c.'
export const AccountStatusCharacteristics: string = 'Account status characteristics'
export const CanBeBoughtMultipleTimes: string = 'Can be bought multiple times'
export const Available: string = 'Available'
export const Wagers: string = 'Wagers'
export const WonAmount: string = 'Won amount'
export const Average: string = 'Average'
export const Wins: string = 'Wins'
export const FirstEvent: string = 'First event'
export const LastEvent: string = 'Last event'
export const OnlyNewlyRegistered: string = 'Only newly registered'
export const TokensPercentage: string = 'Tokens percentage'
export const Reward_Final: string = 'Reward (final)'
export const Reward_Interval: string = 'Reward (interval)'
export const SublevelRewardInterval: string = 'Sublevel reward interval'
export const SublevelPoints: string = 'Sublevel points'
export const BonusForConsecutiveDaysActivity: string = 'Bonus for consecutive days activity'
export const ShowEverything: string = 'Show everything'
export const Month: string = 'Month'
export const Packages: string = 'Packages'
export const Level_Ups: string = 'Level-ups'
export const Sign_Ups: string = 'Sign-ups'
export const RewardShopTransactions: string = 'Reward Shop Transactions'
export const SettlementReport: string = 'Settlement report'
export const l15: string = 'Final (Big) Reward (after level completion)'
export const l16: string = 'Interval (Small) Reward (intra-level)'
export const l17: string = 'Indicates the number of points that have to be collected in order to get to the next sublevel'
export const SubLevelValue: string = 'Sub-Level Value'
export const SubLevelDefaultValue: string = 'Sub-Level Default Value'
export const l18: string = 'Change the number of tokens to get when finishing sublevel'
export const Tokens: string = 'Tokens'
export const ViewSubLevel: string =  'View Sub-Level'
export const ViewLevel: string =  'View Level'
export const l19: string = 'Percentage of points to be rewarded (acts as a bonus)'
export const l20: string = 'Minimal number of consecutive days being active to trigger'
export const l21: string = 'Remove Bonus for consecutive days activity'
export const l22: string = 'Add Bonus for consecutive days activity'
export const l23: string = 'Select Bonus for consecutive days activity'
export const l24: string = 'Bonus for consecutive days activity'
export const CoinValues: string = 'Coin values'
export const Freespins: string = 'Freespins'
export const FlexibleFreespins: string = 'Flexible freespins'
// export const SelectAllGameProviders: string = 'Select All Game Providers'
export const Categorizaton: string = 'Categorizaton'
export const TurnOn: string = 'Turn on'
export const TurnOff: string = 'Turn off'
export const gs1: string = 'Select all remaining (not selected in other categories)'
export const gs2: string = 'Deselect all remaining (not selected in other categories)'
export const ApplySearch: string = 'Apply Search'
export const DeselectAllGames: string = 'Deselect all games'
export const ChallengeType: string = 'Challenge type'
export const Challenge: string = 'Challenge'
export const BigWinValue: string = 'Big win value'
export const lChallenges: string = 'List of Challenges'
export const aeChallenges: string = 'Add/Edit Challenges'
export const eChallenges: string = 'Edit the Challenge'
export const aChallenges: string = 'Add a new Challenge'
export const ChallengeRewardNeedsToBeSet: string = 'Challenge reward needs to be set.'
export const MinBetCannotBe0: string = 'Min. bet cannot be 0.'
export const ChallengeTypeNeedsToBeSeleected: string = 'Challenge type needs to be selected.'
export const ChallengeDateNeedsToBeSet: string = 'Challenge date needs to be set.'
export const SelectTheChallengeType: string = 'Select the Challenge type'
export const ChallengeName: string = 'Challenge name'
export const Fixed: string = 'Fixed'
export const ViewCurrency: string = 'View Currency'
export const PlayerIDAbbrev: string = 'PID'
export const QuantityAbbrev: string = 'Qty'
export const LotteryName: string = 'Lottery name'
export const d16: string = '1 draw is not completed yet'
export const d17: string = 'draws are not completed yet'
export const Result: string = 'Result'
export const AnyDraw: string = 'Any draw'
export const Font: string = 'Font'
export const FontColor: string = 'Font color'
export const UploadImage: string = 'Upload image'
export const g1: string = 'Games are not loaded yet, please try again in a few seconds.'
export const itemsInTotal: string = 'items in total'
export const RemoveGameFromTheSlot: string = 'Remove game from the slot'
export const Top: string = 'Top'
export const Left: string = 'Left'
export const Right: string = 'Right'
export const Bottom: string = 'Bottom'
export const t4: string = 'Turn on switch for background with image, turn off for regular background'
export const PickAColorThatShallBeUsedForTitle: string = 'Pick a color that shall be used for title'
export const d18: string = 'Do you really want to retain just images of the default language (English)?'
export const d19: string = 'Do you really want to copy images from default language (English)?'
export const RetainDefaultLang_: string = 'Retain default lang.'
export const CoreImage: string = 'Core image'
export const CategoryImage: string = 'Category image'
export const Special: string = 'Special'
export const AddNewTranslation: string = 'Add new translation'
export const LeftSide: string = 'Left side'
export const DefaultName: string = 'Default name'
export const CustomName: string = 'Custom name'
export const BlackFriday: string = 'Black Friday'
export const CloseRewardsPositioning: string = 'Close rewards positioning'
export const RewardsPositioningForCategory: string = 'Rewards positioning for category'
export const SaveAndExit: string = 'Save & Exit'
export const SaveAndAddANewGame: string = 'Save & Add a new game'
export const Page: string = 'Page'
export const SelectLabels: string = 'Select labels'
export const JackpotGame: string = 'Jackpot game'
export const Enable: string = 'Enable'
export const ApprovableJurisdictions: string = 'Approvable jurisdictions'
export const SelectGameProvider: string = 'Select Game provider'
export const SetFontFamily: string = 'Set font family'
export const Italic: string = 'Italic'
export const Sport: string = 'Sport'
export const Records: string = 'Records'
export const UpdateImage: string = 'Update image'
export const DeleteImage: string = 'Delete image'
export const DisplayedName: string = 'Displayed name'
export const FavouritesTournament: string = 'Favourites tournament'
export const RefreshMatchesForTheTournament: string = 'Refresh matches for the tournament'
export const SelectDevices: string = 'Select devices'
export const SetLinearGradient: string = 'Set linear gradient'
export const NoGradient: string = 'No gradient'
export const SelectGameProviderCategory: string = 'Select game provider category'
export const SelectCategoryForRewardsPositioning: string = 'Select category for rewards positioning'
export const PastImage: string = 'Past image'
export const LotteryType: string = 'Lottery Type'
export const FavouritesCategory: string = 'Favourites category'
export const RefreshTournamentsForTheCategory: string = 'Refresh tournaments for the category'
export const Margin: string = 'Margin'
export const Opacity: string = 'Opacity'
// SENT V1 5th April 2019
export const Pts: string = 'Pts'
export const Week: string = 'Week'
export const FirstMonth: string = 'First month'
export const FirstDayInTheMonth: string = 'First day in the month'
export const LastDayInTheMonth: string = 'Last day in the month'
export const FirstWeekday: string = 'First weekday'
export const LastWeekday: string = 'Last weekday'
export const PlayerName: string = 'Player name'
export const NoTournamentExistsForTheSpecifiedConditions: string = 'No Tournament exists for the specified conditions'
export const LeaderboardsAbbrev: string = 'LBs'
export const FilterSearch: string = 'Filter Search'
export const RegisteredPlayers: string = 'Registered players'
export const Prizepool: string = 'Prizepool'
export const BuyIn: string = 'Buy-in'
export const StartingIn: string = 'Starting in'
export const Hours: string = 'Hours'
export const Minutes: string = 'Minutes'
export const SignUpBonusSetupReward: string = 'Sign-up bonus setup reward'
export const GeneralMinimumBetForMissionsAndTasks: string = 'General Minimum bet for Missions and Tasks'
export const UpdateGeneralData: string = 'Update general data'
export const GetGeneralDataFromDatabase: string = 'Get general data from database'
export const LowerBoundaryNonExistent: string = 'Lower boundary non-existent'
export const LowerBoundaryExclusive: string = 'Lower boundary exclusive'
export const LowerBoundaryInclusive: string = 'Lower boundary inclusive'
export const UpperBoundaryNonExistent: string = 'Upper boundary non-existent'
export const UpperBoundaryExclusive: string = 'Upper boundary exclusive'
export const UpperBoundaryInclusive: string = 'Upper boundary inclusive'
export const RaffleJackpot: string = 'Raffle Jackpot'
export const GameType: string = 'Game type'
export const PleaseInsertTheTelephoneNumber: string = 'Please insert the telephone number'
export const PleaseChooseYourCountry: string = 'Please choose your country'
export const PleaseChooseWhichRaffleYouWantToPlay: string = 'Please choose which raffle you want to play'
export const ToggleDayInWeek: string = 'Toggle Day in a week'
export const ToggleWeekInMonth: string = 'Toggle Week in a month'
export const Phone: string = 'Phone'
export const TicketBought: string = 'Ticket Bought'
export const Bought: string = 'Bought'
export const GGRRevenueReporting: string = 'GGR Revenue Reporting'
export const AccountingSummary: string = 'Accounting Summary'
export const ShortestReport: string = 'Shortest report'
export const ShortReport: string = 'Short report'
export const LongReport: string = 'Long report'
export const ggr1: string = '(1) Bonus Money converts to Real Money after Playthrough is reached. The converted amount is deducted from GGR.'
export const ggr2: string = '(2) Manual Adjustments lead to a difference in the GGR check and need to be recorded and documented for each case.'
export const TotalBetReal: string = 'Total Bet Real'
export const Money_RNG: string = 'Money (RNG)'
export const TotalWinReal: string = 'Total Win Real'
export const Money_Live: string = 'Money (Live)'
export const WANJackpot: string = 'WAN Jackpot'
export const RNG: string = 'RNG'
export const BonusMoney: string = 'Bonus Money'
export const TotalWin: string = 'Total Win'
export const JackpotHits: string = 'Jackpot Hits'
export const After: string = 'After'
export const Delta: string = 'Delta'
export const Withdraws: string = 'Withdraws'
export const Playthrough: string = 'Playthrough'
export const Increments: string = 'Increments'
export const Converted: string = 'Converted'
export const Wallet: string = 'Wallet'
export const PSP: string = 'PSP'
export const Manual: string = 'Manual'
export const Balance: string = 'Balance'
export const Starting: string = 'Starting'
export const Ending: string = 'Ending'
export const RealMoney: string = 'Real Money'
export const Adjustments: string = 'Adjustments'
export const Processed: string = 'Processed'
export const Movement: string = 'Movement'
export const TotalGGR: string = 'Total GGR'
export const Awarded: string = 'Awarded'
export const RealMoneyWallet: string = 'Real Money Wallet'
export const WithdrawsPending: string = 'Withdraws Pending'
export const WithdrawsProcessed: string = 'Withdraws Processed'
export const TotalCasinoResult: string = 'Total Casino Result'
export const BetsLive: string = 'Bets Live'
export const WinLive: string = 'Win Live'
export const BetsRNG: string = 'Bets RNG'
export const WinRNG: string = 'Win RNG'
export const ConvertedBonusMoney: string = 'Converted Bonus Money'
export const WANJackpotIncrements: string = 'WAN Jackpot Increments'
export const Check: string = 'Check'
export const SelectDate: string = 'Select date'
export const ManualAdjustmentsAsPerSeparateDocumentation: string = 'Manual Adjustments as per separate documentation'
export const Regulation: string = 'Regulation'
export const Min_Required: string = 'Min. required'
export const Ident_: string = 'Ident.'
export const DayInWeek: string = 'Day in a week'
export const WeekInMonth: string = 'Week in a month'
export const us1: string = 'Change password, security question and username for the user with (current) username'
export const DefaultRuleRule: string = 'As this is the DEFAULT rule, dates or players cannot be set!'
export const DEFAULTRulesRule: string = 'DEFAULT Rules cannot be deleted, they can be only modified.'
export const PleaseDefineStartTime: string = 'Please define start time'
export const PleaseDefineEndTime: string = 'Please define end time'
export const SpecialSlots: string = 'Special Slots'
export const LiveCasino: string = 'Live Casino'
export const SelectedGames: string = 'Selected Games'
export const Sel_Players: string = 'Sel. Players'
export const FromDate: string = 'From Date'
export const ToDate: string = 'To Date'
export const AvailableCoins:string = 'Available coins'
export const TotalCoins:string = 'Total coins'
export const CSVExport:string = 'CSV Export'
export const EventDatetime:string = 'Event Datetime'
export const EditPrizeFirstRewardedPosition:string = 'Edit Prize First Rewarded position'
export const FirstPosition:string = 'First position'
export const EditPrizeLastRewardedPosition:string = 'Edit Prize Last Rewarded position'
export const LastPosition: string = 'Last position'
export const SelectPrize: string = 'Select prize'
export const AddPrize: string = 'Add prize'
export const RemovePrize: string = 'Remove prize'
export const ResultsForTheTournament: string = 'Results for the tournament'
export const NoResultsForTheTournament: string = 'No results for the tournament'
export const EditBigWinMinimalMultiplier: string = 'Edit Big win minimal multiplier'
export const ValidFrom: string = 'Valid from'
export const ValidTo: string = 'Valid to'
export const Tourney: string = 'Tourney'
export const Rounds: string = 'Rounds'
export const WinPercentage: string = 'Win percentage'
export const EditBigWinConsecutiveCount: string = 'Edit Big win consecutive count'
export const EditBigWinPoints: string = 'Edit Big win points'
export const SelectBigWinsEntry: string = 'Select Big wins entry'
export const AddBigWinsEntry: string = 'Add Big wins entry'
export const RemoveBigWinsEntry: string = 'Remove Big wins entry'
export const EditMegaWinMinimalMultiplier: string = 'Edit Mega win minimal multiplier'
export const PickADateToShowResultsForTheTournament: string = 'Pick a date to show results for the tournament'
export const EditMegaWinConsecutiveCount: string = 'Edit Mega win consecutive count'
export const EditMegaWinPoints: string = 'Edit Mega win points'
export const SelectMegaWinsEntry: string = 'Select Mega wins entry'
export const AddMegaWinsEntry: string = 'Add Mega wins entry'
export const RemoveMegaWinsEntry: string = 'Remove Mega wins entry'
export const EditConsecutiveWinPoints: string = 'Edit Consecutive win points'
export const EditConsecutiveWinCount: string = 'Edit Consecutive win count'
export const SelectConsecutiveWinsEntry: string = 'Select Consecutive wins entry'
export const AddConsecutiveWinsEntry: string = 'Add Consecutive wins entry'
export const RemoveConsecutiveWinsEntry: string = 'Remove Consecutive wins entry'
export const EditBigWinBoosterMinimalMultiplier: string = 'Edit Big win Booster minimal multiplier'
export const EditBigWinsBoosterCount: string = 'Edit Big wins booster count'
export const EditBigWinsBoosterDuration: string = 'Edit Big wins booster duration'
export const EditBigWinsBoosterPointsMultiplier: string = 'Edit Big wins booster points multiplier'
export const SelectBigWinsBoosterEntry: string = 'Select Big wins booster entry'
export const AddBigWinsBoosterEntry: string = 'Add Big wins Booster entry'
export const EditMegaWinBoosterMinimalMultiplier: string = 'Edit Mega win Booster minimal multiplier'
export const EditMegaWinsBoosterCount: string = 'Edit Mega wins Booster count'
export const EditMegaWinsBoosterDuration: string = 'Edit Mega wins booster duration'
export const EditMegaWinsBoosterPointsMultiplier: string = 'Edit Mega wins booster points multiplier'
export const SelectMegaWinsBoosterEntry: string = 'Select Mega wins booster entry'
export const AddMegaWinsBoosterEntry: string = 'Add Mega wins Booster entry'
export const EditConsecutiveWinsBoosterCount: string = 'Edit Consecutive wins booster count'
export const EditConsecutiveWinsBoosterDuration: string = 'Edit Consecutive wins booster duration'
export const EditConsecutiveWinsBoosterPointsMultiplier: string = 'Edit Consecutive wins booster points multiplier'
export const SelectConsecutiveWinsBoosterEntry: string = 'Select Consecutive wins booster entry'
export const EditConsecutiveLossesCount: string = 'Edit Consecutive losses count'
export const EditConsecutiveLossesPoints: string = 'Edit Consecutive losses points'
export const SelectConsecutiveLossesEntry: string = 'Select Consecutive losses entry'
export const AddConsecutiveLossesEntry: string = 'Add Consecutive losses entry'
export const RemoveConsecutiveLossesEntry: string = 'Remove Consecutive losses entry'
export const CantonCode: string = 'Canton code'
export const CantonCodeAbbrev: string = 'CC'
export const IsEmailVerified: string = 'Is email verified'
export const IsDocumentVerified: string = 'Is document verified'
export const IsUtilityBillVerified: string = 'Is utility bill verified'
export const IsFullyIdentified: string = 'Is fully identified'
export const NormalAccountStatus: string = 'Normal account status'
export const SpecialAccountStatus: string = 'Special account status'
export const NormalAccount: string = 'Normal account'
export const SpecialAccount: string = 'Special account'
export const AllowedActionsForEachAccountStatus: string = 'Allowed actions for each account status'
export const VerifiedItemsForEachAccountStatus: string = 'Verified items for each account status'
export const RuleType: string = 'Rule type'
export const Day: string = 'Day'
export const CalendarDay: string = 'Calendar day'
export const CalendarDayTitle: string = 'Calendar day title'
export const CalendarDayTitleDesc: string = 'calendar day title - if empty, the general title shall be used'
export const Colors: string = 'Colors'
export const PopupText: string = 'Popup text'
export const ParagraphShort: string = 'Par'
export const Paragraph: string = 'Paragraph'
export const ActionButtonText: string = 'Action button text'
export const ActionButton: string = 'Action button'
export const ActionButtonLinkDescription: string = 'Action button link (same for all languages)'
export const ExactDate: string = 'Exact date'
export const Decoration: string = 'Decoration'
export const ActionButtonLink: string = 'Action button link'
export const ActionButtonLinkDesc: string = 'action button link - if empty, there will be no action button shown'
export const ActionLink: string = 'Action link'
export const ActionButtonTextDesc: string = 'action button text - if empty, the general text for action button shall be used'
export const RemoveDefaultPopupImage: string = 'Remove default popup image'
export const PopupImage: string = 'Popup image'
// export const Surname: string = 'Surname'
export const DateOfBirth: string = 'Date of birth'
export const Age: string = 'Age'
export const Continent: string = 'Continent'
export const Timezone: string = 'Timezone'
export const male: string = 'male'
export const female: string = 'female'
export const Lower: string = 'Lower'
export const ListOfPlayerGroups: string = 'List of Player Groups'
export const AnyBalanceGroup: string = 'Any balance group'
export const SetBalanceGroup: string = 'Set balance group'
export const AnyAgeGroup: string = 'Any age group'
export const SetAgeGroup: string = 'Set age group'
export const AnyGender: string = 'Any gender'
export const SetGender: string = 'Set gender'
export const AnyCountry: string = 'Any country'
export const SetCountries: string = 'Set countries'
export const SetContinents: string = 'Set continents'
export const AnyContinent: string = 'Any continent'
export const SetTimezones: string = 'Set timezones'
export const AnyTimezone: string = 'Any timezone'
export const Player_sCharacteristics: string = 'Player\'s characteristics'
export const SetLevel: string = 'Set level'
export const AnyGame: string = 'Any game'
export const GamesInvolvement: string = 'Games involvement'
export const ePlayerGroup: string = 'Edit the Player Group'
export const aPlayerGroup: string = 'Add a new Player Group'
export const ListOfPlayersForPlayerGroup: string = 'List of players for Player Group'
export const pg3: string = 'Please, set Player Group name to save this Player Group'
export const pg4: string = 'Please, set Priority from 1-100 to save this Player Group'
export const pg5: string = 'Please, set Group type to save this Player Group'
export const SelectPriority: string = 'Select priority'
export const AnyYear: string = 'Any year'
export const Anytime: string = 'Anytime'
export const DaysOfLastActivity: string = 'Days of last activity'
export const AnyMaximumDeposit: string = 'Any maximum deposit'
export const AnyMinimumDeposit: string = 'Any minimum deposit'
export const AnyMaximumWithdrawal: string = 'Any maximum withdrawal'
export const AnyMinimumWithdrawal: string = 'Any minimum withdrawal'
export const AnyMaximumBet: string = 'Any maximum bet'
export const AnyMinimumBet: string = 'Any minimum bet'
export const AnyMaximumWin: string = 'Any maximum win'
export const AnyMinimumWin: string = 'Any minimum win'
export const SessionBets: string = 'Session bets'
export const AnyMaximumSessionBets: string = 'Any maximum session bets'
export const AnyMinimumSessionBets: string = 'Any minimum session bets'
export const AnyMonth: string = 'Any month'
export const Ignore: string = 'Ignore'
export const AnyWeekday: string = 'Any weekday'
export const BetweenIncluding: string = 'Between including'
export const AndIncluding: string = 'And including'
export const AndExcluding: string = 'And excluding'
export const AnyMaximumSessionTime: string = 'Any maximum session time'
export const AnyMinimumSessionTime: string = 'Any minimum session time'
export const AnyMonthDay: string = 'Any month day'
export const AnyTimeOfDay: string = 'Any time of day'
export const ageMin_: string = 'age min.'
export const ageMax_: string = 'age max.'
export const AnyBetSize: string = 'Any bet size'
export const AnyWinSize: string = 'Any win size'
export const AnyDepositSize: string = 'Any deposit size'
export const AnyWithdrawalSize: string = 'Any withdrawal size'
export const AnyFrequency: string = 'Any frequency'
export const AnyDuration: string = 'Any duration'
export const AnySessionBets: string = 'Any session bets'
export const AnySessionTime: string = 'Any session time'
export const MinInterval: string = 'Min. interval'
export const NoInterval: string = 'No interval'
export const SetInterval: string = 'Set interval'
export const SetFrequency: string = 'Set frequency'
export const SetDuration: string = 'Set duration'
export const FreezedAt: string = 'Freezed at'
export const DynamicGroup: string = 'Dynamic group'
export const StaticGroup: string = 'Static group'
export const LowerPriority: string = 'Lower priority'
export const DepositSett_: string = 'Deposit sett.'
export const WithdrawalSett_: string = 'Withdrawal sett.'
export const BetSettings: string = 'Bet settings'
export const WinSettings: string = 'Win settings'
export const SetYear: string = 'Set year'
export const SetMonth: string = 'Set month'
export const SessionTime: string = 'Session time'
export const MaxInterval: string = 'Max. interval'
export const SetTime: string = 'Set time'
export const SetWeekday: string = 'Set weekday'
export const SetMonthDay: string = 'Set month day'
export const AddViewRaffles: string = 'Add/View Raffles'
export const ViewTheRaffle: string = 'View the Raffle'
export const ScheduledRaffles: string = 'Scheduled raffles'
export const CompletedRaffles: string = 'Completed raffles'
export const ThisRaffleIsNotCompletedYet: string = 'This raffle is not completed yet'
export const Unknown: string = 'Unknown'
export const ScheduledTime: string = 'Scheduled time'
export const PleaseSelectRaffleDate: string = 'Please, select raffle date'
export const PleaseSetRaffleTime: string = 'Please, set raffle time'
export const PleaseSetRaffleTimeCorrectFormat: string = 'Please, set raffle time in correct format (hh:mm)'
export const ChangeLog: string = 'Change log'
export const JackpotSlotGame: string = 'Jackpot slot game'
export const ProgressiveJackpot: string = 'Progressive jackpot'
export const ProgressiveJackpotAbbrev: string = 'PJ'
export const AllGameProviders: string = 'All game providers'
export const ActiveGameProviders: string = 'Active game providers'
export const InactiveGameProviders: string = 'Inactive game providers'
export const SelectGameCategory2: string = 'Select game category'
export const DesktopBackgroundFilename: string = 'Desktop background filename'
export const MobileBackgroundFilename: string = 'Mobile background filename'
export const DesktopThumbnailFilename: string = 'Desktop thumbnail filename'
export const MobileThumbnailFilename: string = 'Mobile thumbnail filename'
export const DesktopBackgroundFilenameAbbrev: string = 'DB'
export const MobileBackgroundFilenameAbbrev: string = 'MB'
export const DesktopThumbnailFilenameAbbrev: string = 'DT'
export const MobileThumbnailFilenameAbbrev: string = 'MT'
// SENT V2 5th April 2019
export const UnblockPlayer: string = 'Unblock player'
export const ThisPlayerAccountWasUnblockedBy: string = 'This player account was unblocked by'
export const ThisPlayerAccountWasNotUnblockedBy: string = 'This player account was NOT unblocked by'
export const InsertANewGameIntoDB:string = 'Insert a new game into DB'
export const InsertANewGameIntoDatabase:string = 'Insert a new game into database'
export const Certified: string = 'Certified'
export const Detail: string = 'Detail'
export const TheGameCurrentlyCannotBeApprovedInTheJurisdiction: string = 'The game currently cannot be approved in the jurisdiction'
export const ExportToCMS: string = 'Export to CMS'
export const ExportGameToCMS: string = 'Export game to CMS'
export const ExportAllGamesToCMS: string = 'Export all games to CMS'
export const GamesWereSuccessfullyExportedToCMS: string = 'Games were successfully exported to CMS.'
export const GamesFailedToExportToCMS: string = 'Games failed to export to CMS.'
export const GameWasSuccessfullyExportedToCMS: string = 'Game was successfully exported to CMS.'
export const GameFailedToExportToCMS: string = 'Game failed to export to CMS.'
export const NotApplicable: string = 'Not applicable'
export const ExistsInCMS: string = 'Exists in CMS'
export const ShortDescription: string = 'Short description'
export const LongDescription: string = 'Long description'
export const UpdatedAt: string = 'Updated at'
export const CreatedAt: string = 'Created at'
export const CMS: string = 'CMS'
export const YourTime: string = 'Your time'
export const YourComputerTime: string = 'Your computer time'
export const NeedsVerificationAbbrev: string = 'NV'
export const gamesNeedToBeExportedToCMS: string = 'games need to be exported to CMS'
export const gameNeedsToBeExportedToCMS: string = 'game needs to be exported to CMS'
export const GameNameIsIdenticalInCMS: string = 'Game name is identical in CMS'
export const Database: string = 'Database'
export const sameName: string = 'same name'
export const ClientGameStore: string = 'Client Game store'
export const CentralGameStore: string = 'Central Game store'
export const ComplianceSettings: string = 'Compliance settings'
export const FrontEndSettings: string = 'Front-End settings'
export const RemoveCategory: string = 'Remove category'
export const PreviousAssessmentShort: string = 'Previous assessmnt'
export const Nationality_s: string = 'Nationality(ies)'
export const Country_s: string = 'Country(ies)'
export const AgeGroup_s: string = 'Age group(s)'
export const Gender_s: string = 'Gender(s)'
export const AccountStatus_s: string = 'Account status(es)'
export const RegisteredPlayer_s: string = 'Registered player(s)'
export const byDate: string = 'by date'
export const byDayInWeek: string = 'by day in a week'
export const byDayInMonth: string = 'by day in a month'
export const byMonth: string = 'by month'
export const byYear: string = 'by year'
export const byQuarter: string = 'by quarter'
export const Population: string = 'Population'
export const Thousand: string = 'Thousand'
export const People: string = 'People'
// accountStatus was modified, please delete the row with accountStatus above
export const accountStatus: string[] = ['Initial', 'Provisional', 'Active', 'Fully Identified', 'Blocked Full', 'Blocked Partial', 'Closed Provisional', 'Inactive', 'Closed Active', 'Payments Blocked', 'Pending Active']
export const RemoveACoolOff: string = 'Remove a cool-off'
export const CoolOffRemovalIsNotYetAllowed = 'Cool-off removal is not yet allowed, since 2 months have not passed yet.'
export const CoolOffRemovalIsAllowed = 'Cool-off removal is allowed, since 2 months have already passed.'
export const Cooloff: string = 'Cool-off'
// PlayerCardButtonsKYC was modified, please delete the row with PlayerCardButtonsKYC above
// export const PlayerCardButtonsKYC: string[] = ['Overview', 'All issues', 'Upload document', 'Upload utility bill', 'Verified document', 'Verified utility bill', 'Same player', 'Temporary account', 'No player activity', 'Large deposits', 'Large withdrawals', 'Maximum balance', 'Other']
// PlayerCardButtonsRG was modified, please delete the row with PlayerCardButtonsRG above
// export const PlayerCardButtonsRG: string[] = ['Overview', 'All issues','Callback request','Permanent exclusion','Self-assessment','Temporary exclude','Net limit loss registration', 'Net limit loss increased','Dashboard backend',    'Deposit methods','Deposit cool-off','Cancelled withdrawals','Gaming time','Higher net loss','Initiated cool-off','Increased net loss','Blacklist (VETO) check']
export const AllCategories: string = 'All categories'
export const PlayerCardButtonsKYC: string[] = [
    'Overview',
    'All issues',
    'Upload document',
    'Upload utility bill',
    'Verified document',
    'Verified utility bill',
    'Verified email',
    'Changed email',
    'Verified address',
    'Changed phone number',
    'Changed banking details',
    'Same player',
    'Changed nationality',
    'Changed test account',
    'Manual upload document',
    'Manual upload utility bill',
    'Verified mobile',
    'Changed username',
    'Temporary account',
    'No player activity',
    'Money stuff',
    'Large deposits',
    'Large withdrawals',
    'Maximum balance',
    'Manual withdrawal',
    'Manual deposit',
    'Account status change',
    'Account closed',
    'Provisional account closed',
    'Active account closed',
    'CRIF / PEP', 
    'CRIF check at mismatch',
    'CRIF check at login',
    'CRIF check at registration',
    'CRIF address changed by player',
    'CRIF address changed by operator',
    'PEP check at login',
    'PEP check at registration',
    'PEP check at name changed',
    'PEP check at beneficiary name',
    'PEP changed by operator',
    'Bally',
    'Bally registration success',
    'Bally registration failed',
    'Other']
export const PlayerCardButtonsRG: string[] = [
    'Overview', 
    'All issues',
    'Callback request',
    'Permanent exclusion',
    'Self-assessment',
    'Cool-offs changed',
    'Limits changed',
    'Blacklist veto',
    'Blacklist registration',
    'Blacklist exclusion',
    'Blacklist login',
    'Blacklist cron job',
    'Blacklist manual',
    'Blacklist Whitelisted',
    'Dashboard backend',    
    'Deposit methods',
    'Deposit cool-off',
    'Cancelled withdrawals',
    'Gaming time',
    // 'Higher net loss',
    'Net loss per hour',
    'Net loss thirty days',
    'Net loss twelve months',
    'Initiated cool-off',
    'Increased net loss',
    'Other']
export const Provisional: string = 'Provisional'
export const ThisPlayerAccountWasClosedBy: string = 'This player account was closed by'
export const ThisPlayerAccountWasNotClosedBy: string = 'This player account was NOT closed by'
export const ClosePlayerAccount: string = 'Close player account'
export const ActiveShort: string = 'Act'
export const ProvisionalShort: string = 'Prv'
export const DayInMonth: string = 'Day in a Month'
export const Hour: string = 'Hour'
export const WednesdayShort: string = 'Wednes'
export const OpeningThePlayercard: string = 'Opening the playercard'
export const MoveOnLeft: string = 'Move on left'
export const Details: string = 'Details'
export const Key: string = 'Key'
export const Quarter: string = 'Quarter'
export const Year: string = 'Year'
// PlayerCardButtonsPH was modified, please delete the row with PlayerCardButtonsPH above
export const PlayerCardButtonsPH: string[] = ['All transactions', 'Deposits', 'Withdrawals', 'Wagers', 'Wins', 'Wager cancel', 'Win cancel', 'Bonus add', 'Bonus redeem']
export const InsertANewIssue: string = 'Insert a new issue'
export const InsertANewIssueShort: string = 'Insert issue'
export const Force: string = 'Force'
export const More: string = 'More'
export const Basic: string = 'Basic'
export const DisableSpecialAccountStatus: string = 'Disable special account status'
export const ThousandsSeparator: string = 'Thousands separator'
export const Accounts: string = 'Accounts'
// PlayerCardButtonsRM was modified, please delete the row with PlayerCardButtonsRM above
export const PlayerCardButtonsRM: string[] = [
    'All issues',
    'Retail - no account online',
    'Fraud alerts',
    '3 or more deposits below 100 within 10 minutes',
    'Account created within last 10 days and deposits over 10000',
    'win ratio over 80% and bets over 1000',
    'IP for deposit not same as registration',
    'IP not from EU or not same as credit card',
    'IP from EU, not Switzerland',
    'Anonymous proxy for deposit recognized',
    'Low gaming activity with deposits over 1000',
    'Account created within last ten days and withdrawals over 5000',
    'Unusual bonus deposit ratio above 1.01',
/*
    'Three or more deposit methods',
    'Deposit after cool off',
    'Canceled three or more withdrawals',
    'Average gaming time',
    'Net loss per hour',
    'Net loss thirty days',
    'Net loss twelve months',
    'Four or more cooloffs',
    'Increased net loss limits',
*/
    'Other',]
export const ShowJustIssuesThatAreAssignedToYou: string = 'Show just issues that are assigned to you'
export const ShowAllIssues: string = 'Show all issues'
export const PleaseEnterANumberToToggleSearch: string = 'Please enter a number to toggle search'
export const PleaseRemoveDotsFromTheSearchString: string = 'Please remove dots from the search string'
export const Shop: string = 'Shop'
export const AddViewShopItems: string = 'Add/View Shop items'
export const ViewShopItems: string = 'View Shop items'
export const AddANewShopItem: string = 'Add a new Shop item'
export const ListShopItems: string = 'List Shop items'
export const ShopItemName: string = 'Shop item name'
export const WithdrawalRequests: string = 'Withdrawal Requests'
export const Size: string = 'Size'
export const SelectPlayerActivity: string = 'Select player activity'
export const Requested: string = 'Requested'
export const ChangeStatusTo: string = 'Change status to'
export const Denied: string = 'Denied'
export const GenerateAPaymentFile: string = 'Generate a payment file'
export const Payment: string = 'Payment'
export const Payments: string = 'Payments'
export const PaymentFilePreview: string = 'Payment file preview'
export const IBANIsNotValid: string = 'IBAN is not valid'
export const IBANIsValid: string = 'IBAN is valid'
export const Validity: string = 'Validity'
export const Rollback: string = 'Rollback'
export const PaymentFiles: string = 'Payment Files'
export const Waiting: string = 'Waiting'
export const Approval: string = 'Approval'
export const FilterWithdrawalRequestStatus: string = 'Filter withdrawal request status'
export const Process: string = 'Process'
export const PaymentFile: string = 'Payment file'
export const BeneficiaryName: string = 'Beneficiary name'
export const FraudAlerts: string = 'Fraud alerts'
export const Generated: string = 'Generated'
export const Open: string = 'Open'
export const PaymentFileView: string = 'Payment file view'
export const CopyContentToClipboard: string = 'Copy content to clipboard'
export const First: string = 'First'
export const Execution: string = 'Execution'
export const FilterPaymentProcessedStatus: string = 'Filter payment processed status'
export const _on_:string = ' on '
export const _as_:string = ' as '
export const DeniedByBank: string = 'Denied by bank'
export const DeniedByBankAbbrev: string = 'DBB'
export const AmountShort: string = 'Amnt'
export const BonusCodes: string = 'Bonus codes'
export const DoesNotExist: string = 'Does not exist'
export const RemoveABonusCode: string = 'Remove a Bonus code'
export const AnyAccountStatus: string = 'Any account status'
export const Registration: string = 'Registration'
export const BonusCode_s: string = 'Bonus code(s)'
export const Minute: string = 'Minute'
export const Second: string = 'Second'
export const _plural_: string = '(s)'
// delete from above TournamentLeaderboards
export const TournamentLeaderboards: string = TournamentLeaderboard + _plural_
export const TimeUnits: string[] = [Year + _plural_, Month + _plural_, Week + _plural_, Day + _plural_, Hour + _plural_, Minute + _plural_, Second + _plural_]
export const MultipleBonus: string = 'Multiple bonus'
export const CodesPerAccount: string = 'Codes per account'
export const Combinable: string = 'Combinable'
export const bicmessage: string = 'Please edit the BIC (SWIFT) only if you have a reason to do so!'
export const restrictedmessage: string = 'This section is restricted. Please check your security rights!'
export const Required: string = 'Required'
export const BonusCampaign: string = 'Bonus campaign'
export const AddEditBonusCampaign: string = 'Add/Edit Bonus campaign'
export const EditTheBonusCampaign: string = 'Edit the Bonus campaign'
export const AddANewBonusCampaign: string = 'Add a new Bonus campaign'
export const ListOfBonusCampaign: string = 'List of Bonus campaigns'
export const Campaign: string = 'Campaign'
export const Items: string = 'Items'
export const AddANewWageringRequirement: string = 'Add a new Wagering requirement'
export const RemoveAWageringRequirement: string = 'Remove a Wagering requirement'
export const Else: string = 'Else'
export const jackpotschLevels: string[] = ['', 'Member', 'Silver', 'Gold', 'Diamond', 'Black circle']
export const ApprovedGamesStore: string = 'Approved Games Store'
export const GamingeniusGameList: string = 'Gamingenius Game List'
export const Edit: string = 'Edit'
export const Account: string = 'Account'
export const Base: string = 'Base'
export const WageringRequirementShort: string = 'WR'
export const WageringContribution: string = 'Wagering Contribution'
export const WageringContributionShort: string = 'WC'
export const AddANewWageringContribution: string = 'Add a new Wagering contribution'
export const RemoveAWageringContribution: string = 'Remove a Wagering contribution'
export const Individual: string = 'Individual'
export const UploadBonusCodesFile: string = 'Upload bonus codes file'
export const Code_s: string = 'Code(s)'
export const TimeUnits_: string = 'Time units'
export const LimitTypes: string = 'Limit types'
export const ShowAllExpiringProvisionalAccounts: string = 'Show all expiring provisional accounts'
export const ShowExpiredProvisionalAccounts: string = 'Show expired provisional accounts'
export const Expired_ExpiringProvisionalAccounts: string = 'Expired/Expiring provisional accounts'
export const ProvisionalAccountStatus: string = 'Provisional account'
export const ProvisionalAccounts: string = 'Provisional accounts'
export const RequestedDocumentsForAccountVerification: string = 'Requested documents for account verification'
export const AdminSettings: string = 'Admin Settings'
export const ManualWithdrawal: string = 'Manual Withdrawal'
export const Withdraw: string = 'Withdraw'
export const CopyWholeRealBalance: string = 'Copy whole real balance'
export const InsertANewManualWithdrawal: string = 'Insert a new manual withdrawal'
export const ShouldNotHaveSpecialCharacters: string = 'Should not have special characters'
export const ShouldNotStartWithANumber: string = 'Should not start with a number'
export const a2: string = 'You can change to status Provisional Closed only if you are on status Provisional'
export const a3: string = 'You can change to status Active Closed only if you are on status Active or Active Fully Identified'
export const ForbiddenUsernames: string = 'Forbidden usernames'
export const ResendVerificationEmail: string = 'Re-send a verification e-mail'
export const AddressVerified: string = 'Address verified'
export const AddressNotVerified: string = 'Address not verified'
export const Demo: string = 'Demo'
export const NoApprovable: string = 'No approvable'
export const VetoSettings: string = 'VETO settings'
export const ShownSettings: string = 'Shown settings'
export const ifUnsuccessfull: string = 'if unsuccessfull'
export const ifPlayerFoundSuspicious: string = 'if player found suspicious'
export const BlacklistLogin: string = 'Blacklist login'
export const BlacklistCronJob: string = 'Blacklist cron job'
export const BlacklistExclusion: string = 'Blacklist exclusion'
export const BlacklistRegistration: string = 'Blacklist registration'
export const Blacklist: string = 'Blacklist'
export const PoliticallyExposedPerson: string = 'Politically exposed person'
export const NotAPoliticallyExposedPerson: string = 'Not a politically exposed person'
export const IBANExplained: string = 'International Bank Account Number'
export const CommunicationChannels: string = 'Communication channels'
export const SelectedViewDescriptions: string[] = ['Current overview', 'Transactions', 'Gamification', 'Top players', /*'VETO Blacklist', 'Address verification', 'Politically exposed person verification',*/ 'Download info about all accounts in the system', 'Risk alerts', 'Responsible gaming issues', 'Know your customer issues', 'Gamification issues']
export const PoliticallyExposedPersons: string = 'Politically exposed persons'
export const CrifStrings: string[] = [
    'Check at registration',
    'Check at login',
    'Can player directly change the address',
    'Check operator change address',
    'Equivalent to utility bill upload',
    'Provider',
    'Product',
    'Interface version',
    'Support email',
    'Telephone (Switzerland)',
    'Telephone (International)',
    'Documentation',
]
export const PepStrings: string[] = [
    'Check at registration',
    'Check at login',
    'Check at name changed',
    'Check beneficary name',
    'Double check on PEP match',
    'Mark the player manually',
    'Provider',
    'Product',
    'Support email',
    'Telephone (Switzerland)',
    'Telephone (International)',
    'Documentation',
]
// export const Verify: string = 'Verify'
export const Manually: string = 'Manually'
export const IsAddressVerified: string = 'Is address verified'
export const Implemented: string = 'Implemented'
export const Descriptions: string = 'Descriptions'
export const Cancelled: string = 'Cancelled'
export const Marked: string = 'Marked'
// export const PlayingSessions: string = 'Playing sessions'
export const Character: string = 'Character'
export const Decision: string = 'Decision'
export const Applicable: string = 'Applicable'
export const Mismatch: string = 'Mismatch'
export const Supplier: string = 'Supplier'
export const Round: string = 'Round'
export const RoundShort: string = 'Rnd'
export const Stopped: string = 'Stopped'
export const Stop: string = 'Stop'
export const TotalWager: string = 'Total wager'
export const RealMoneyShort: string = 'RM'
export const BonusMoneyShort: string = 'BM'
export const FreeGameShort: string = 'FG'
export const ProgressiveJackpotContributionShort: string = 'PJC'
export const ProgressiveJackpotContribution: string = 'Progressive jackpot contribution'
export const ProgressiveJackpotWinsShort: string = 'PJW'
export const ProgressiveJackpotWins: string = 'Progressive jackpot wins'
export const ViewOutcome: string = 'View outcome'
export const FreeGame: string = 'Free game'
export const BICExplained: string = 'Bank Identifier Code'
export const BallyId: string = 'Bally Id'
export const Invisible: string = 'Invisible'
export const Visible: string = 'Visible'
export const AllBonusesAreTaken: string = 'All bonuses already belong to a bonus campaign. Please create a new bonus, if you want to add it to a bonus campaign.'
export const ifPoliticallyExposedPerson: string = ' if a player is a politically exposed person manually'
export const SomethingWentWrongWhileResendingEmail: string = 'Something went wrong while resending e-mail.'
export const ThisFieldIsOptional: string = 'This field is optional'
export const ThisFieldIsOptionalDesc: string = 'When IBAN is entered, BIC (SWIFT) will be most probably extracted from IBAN and shown here in grey letters. If that does not happen, please double check IBAN and if everything checks out write in manually the correct BIC.'
export const ManualCrifCheck: string = 'Manual CRIF check'
export const CheckPEPManually: string = 'Check politically exposed person manually'
export const CheckAddressManually: string = 'Check correct address manually'
export const Correct: string = 'Correct'
export const CheckCrif: string = 'Check CRIF'
export const errorWhileCrif: string = 'There was an error while connecting to the CRIF. Please retry.'
export const PasteNewEmail: string = 'Paste email address change here.'
export const NationalityOfThePlayerCannotBeChanged: string = 'Nationality of the player cannot be changed.'
export const ChangedEmailIssueIsSaved: string = 'Changed email issue is saved.'
export const PasteNewAddress: string = 'Paste new address'
export const CrifAddressChangedOperatorStarted: string = 'CRIF Address Changed byDashboardBackend operator check was started.'
export const PasteNewCity: string = 'Paste new city'
export const PasteNewPostalCode: string = 'Paste new postal code'
export const PasteNewStreetNumber: string = 'Paste new street number'
export const PasteNewFirstName: string = 'Paste new first name'
export const PasteNewLastName: string = 'Paste new last name'
export const PepNameChangedOperatorStarted: string = 'PEP Name Changed by operator check was started'
export const PasteNewBirthday: string = 'Paste new birthday'
export const PasteBirthday: string = 'Paste birthday'
export const PasteNewGender: string = 'Paste new gender'
export const StreetNumber: string = 'Street number'
export const StreetNumberAbbrev: string = 'SN'
export const AddressOfThisPlayerInThePlayerCard: string = 'Currently stored address of this player in the playercard'
export const Rotate: string = 'Rotate'
export const Identity: string = 'Identity'
export const ThisIsAManualIssue: string = 'This issue originated as a manual issue'
export const ThisIsNotAManualIssue: string = 'This issue did not originate as a manual issue'
export const ThisIssueWasAlreadyResolved: string = 'Reopen an issue, that has been already resolved'
export const Reopen: string = 'Reopen'
export const Attachments: string = 'Attachments'
export const ManualDeposit: string = 'Manual Deposit'
export const InsertANewManualDeposit: string = 'Insert a new manual deposit'
export const ShowUploads: string = 'Show uploads'
export const File: string = 'File'
export const Filename: string = 'Filename'
export const Brightness: string = 'Brightness'
export const Contrast: string = 'Contrast'
export const _with_: string = ' with '
export const communicationChannels: string[] = ['E-mail', 'Post', 'SMS', 'Telephone']
export const PleaseConfirmToggleCommunicationChannel: string = 'Please, confirm toggle of a communication channel '
// export const CrifCheckStarted: string = 'Crif check started'
// export const PepCheckByOperatorStarted: string = 'Pep check by operator started'
export const MobilePhone: string = 'Mobile phone'
export const PasteNewMobilePhone: string = 'Paste mobile phone change here.'
export const PleaseConfirmLanguageChange: string = 'Please, confirm language change'
export const PasteNewBic: string = 'Paste new BIC'
export const PasteNewIban: string = 'Paste new IBAN'
export const PasteNewBeneficiaryName: string = 'Paste new beneficiary name'
export const BeneficiaryNameAbbrev: string = 'BN'
export const BeneficiaryAbbrev: string = 'B'
export const PasteNewNationality: string = 'Paste new nationality'
export const TestAccount: string = 'Test account'
export const NotATestAccount: string = 'Not a test account'
export const PleaseConfirmChangeTestAccountStatus: string = 'Please, confirm change test account status!'
export const MissingIBAN: string = 'Missing IBAN'
export const Rejected: string = 'Rejected'
export const BlacklistManual: string = 'Blacklist manual'
export const CheckVETO: string = 'Check VETO'
export const ManualVetoCheckIsUnderWay: string = 'Manual blacklist VETO check is underway!'
export const TurnIntoATestAccount: string = 'Turn into a test account. Please note, this action cannot be reverted!'
export const TestAccountCannotBeReverted: string = 'If labeled as a test account, it cannot be reverted anymore.'
export const Resend: string = 'Resend'
export const ShowPlayersWithUnresolvedIssues: string = 'Show players w. unresolved issues'
export const Transaction: string = 'Transaction'
export const Assignment: string = 'Assignment'
export const Changed: string = 'Changed'
export const BasicDataOfThisPlayerInThePlayerCard: string = 'Currently stored basic data of this player in the playercard'
export const WAN: string = 'WAN'
export const Live: string = 'Live'
export const Hits: string = 'Hits'
export const Money: string = 'Money'
export const ConvertedBonus: string = 'Converted bonus'
export const MoneyAfter: string = 'Money after'
export const Part: string = 'Part'
export const Period: string = 'Period'
export const EmailIsAlreadyUsed: string = 'Email is already used.'
export const RejectWithdrawals: string = 'Reject withdrawals'
export const SetRegions: string = 'Set regions'
export const AnyRegion: string = 'Any region'
export const PlayerGroups: string = 'Player Groups'
export const SetNationalities: string = 'Set nationalities'
export const AnyNationality: string = 'Any nationality'
export const PlayerRewardGroup: string = 'Player reward group'
// export const NoMembers: string = 'No members'
export const UploadFile: string = 'Upload file'
export const AvailableFiles: string = 'Available files'
export const UploadedFiles: string = 'Uploaded files'
export const AttachedFiles: string = 'Attached files'
export const UploadANewFile: string = 'Upload a new file'
export const ShowAvailableFilesForPlayer: string = 'Show available files for player'
export const AttachThisFile: string = 'Attach this file'
export const areYouSureThatYouWantToDeleteAnAttachedFile: string = 'Are you sure that you want to delete an attached file'
export const PlayersRegistrations: string = 'Players registrations'
export const GetFullReport: string = 'Get full report'
export const TransactionShort: string = 'Trans'
export const CouldNotFindThePaymentsFile: string = 'Could not find the payments file'
export const GameSessions: string = 'Game sessions'
export const External: string = 'External'
export const SelectAGameSession: string = 'Select a game session'
export const Before: string = 'Before'
export const Real: string = 'Real'
export const ThereAreNoBonusesForThisPlayer: string = 'There are no bonuses in system for this player.'
export const Bonus_es: string = 'Bonus(es)'
export const _a_: string = ' a ' 
export const _the_: string = ' the '
export const Activate: string = 'Activate'
export const _all_: string = ' all '
export const this_: string = 'this'
export const InsertANewBonus: string = 'Insert a new bonus'
export const cancelAllBonuses: string = 'Are you sure, that you would like to cancel all bonuses?'
export const cancelAllBonus: string = 'Are you sure, that you would like to cancel the bonus'
export const activateBonus: string = 'Are you sure, that you would like to activate the bonus'
export const PDFExport: string = 'Export to PDF'
export const PDFExportTable: string = 'Export to PDF Table'
export const Bar: string = 'Bar'
export const Pie: string = 'Pie'
export const Displayed: string = 'Displayed'
export const Remove: string = 'Remove'
export const Missing: string = 'Missing'
export const GSID: string = 'GSID'
export const EGSID: string = 'EGSID'
export const ThereAreNoGamingSessionsInTheSystemInsertedForThisPlayer: string = 'There are no gaming sessions in the system inserted for this player.'
export const Clipboard: string = 'Clipboard'
export const WageringReq: string = 'Wagering Req.'
export const AllowEmail: string = 'Allow email'
export const AllowSms: string = 'Allow SMS'
export const AllowTelephone: string = 'Allow telephone'
export const AllowPost: string = 'Allow post'
export const GettingDataForProvisionalAccountsCSVExport: string = 'Getting data for CSV export of provisional accounts'
export const PleaseWaitThisOperationWillTakeSomeTime: string = 'Please wait, this operation will take some time...'
export const MonthParts: string[] = ['Start (1-10)', 'Mid (11-20)', 'End (21-?)']
export const PleaseWaitSelectionOfGameSessionInProgress: string = 'Please wait, selection of game session in progress.'
export const GamificationShort:string = 'GM'
export const MinimumNumberOfCharactersToSearch: string = 'Min. number of characters to perform a search'
export const SearchOnlyAtTheStartOfPhrase: string = 'Search only at the beginning of the phrase'
export const For: string = 'For'
export const Last: string = 'Last'
export const PleaseEnterIbanToCheckBIC: string = 'Please, enter IBAN to check BIC.'
export const ClickToRetrieveCorrectBIC: string = 'Click to retrieve correct BIC!'
export const Environment: string = 'Environment'
export const Environments: string = 'Environments'
export const Allowed: string = 'Allowed'
export const AllAcounts: string = 'All accounts'
export const GettingDataForAllAccountsCSVExport: string = 'Getting data for CSV export of all accounts'
export const l25: string = 'You do not have rights to access this environment!'
export const Card: string = 'Card'
export const GamificationIssues: string = 'GM issues'
export const PlayerCardButtonsGM: string[] = [
    'All issues',
    'Bonus',
    'Bonus add',
    'Bonus activate',
    'Bonus cancel',
    'Bonus cancel all',
    'Bonus add manual',
    'Bonus add manual bonus code',
    'Bonus activate manual',
    'Bonus cancel manual',
    'Bonus cancel all manual',
]
export const ExpiringInLessThan: string = 'Expiring in less than'
export const Administrative: string = 'Administrative'
export const UsernameIsAlreadyUsed: string = 'Username is already used.'
export const TurnIntoActiveFullyIdentified: string = 'Turn into active fully identified'
export const TurnBackIntoActive: string = 'Turn back into active'
export const StepByStepGuideHowToUploadFiles: string = 'Step by step guide how to upload files'
export const OrderedListUploadFilesIssue: string[] = [
    'Manually upload a document or an utility (select the type above).',
    'Upload a new file from the computer (Choose file button) if needed.',
    'Click on "Show available files for player" ,optionally filter by file type and then select the appropriate file.',
    'Click on "Attach this file" button, which is below the displayed file.',

    'Determine if the document should be verified, based on the file content.',
    'Mark as resolved and enter a comment (mandatory).',
    'Clik on "Insert a new issue" button to insert the issue.'
]
export const OrderedListUploadFiles: string[] = [
    'Manually upload an image (jpeg, gif, png) or a pdf file.',
    'Then, this uploaded file can be attached to an issue.',
    'It can be attached to the issue when a new issue is being created.',
    'The other option is to attach it to the existing issue.',
    'When "Show Uploads" button is clicked, all the images and pdf files that pertain to the player are shown.',
    'You can also filter the table and show pdfs only or images only',
    'For the time being, it is not possible to remove the uploaded files from the system.',
]
export const Pdfs: string = 'Pdfs'
export const Only: string = 'Only'
export const Bypass: string = 'Bypass'
export const Risk: string = 'Risk'
export const Empty: string = 'Empty'
export const GameSession: string = 'Game session'
export const Information: string = 'Information'
export const Game_s: string = 'game(s)'
export const ReloadGameStore: string = 'Reload Game Store'
export const ErrorMessageNotFound: string = 'Error Message not found'
export const GamanzaErrorMessages: string = 'Gamanza Error Messages'
export const Message: string = 'Message'
export const IssuesFilterBy: string = 'Filter by issue category, type, date and resolved status'
export const PleaseSelectIssueCategory: string = 'Please select the issue category'
export const p4: string = 'Please select a player in the player search above'
export const ListOfRewards: string = 'List of Rewards'
export const DoYouWantToResetPasswordForUsername: string = 'Do you want to reset password for username'
export const Filters: string = 'Filters'
export const Assignees: string = 'Assignees'
export const Updated: string = 'Updated'
export const Revert: string = 'Revert'
export const GameWithThisNameAndProviderAlreadyExistsInTheDatabase: string = 'Game with this name for this provider already exists in the database'
export const DurationOfPasswordResetEmail: string = 'Duration of password reset email (seconds)'
export const LoadingGameStore: string = 'Loading game store...'
export const LoadingData: string = 'Loading data...'
export const GameWithThisLaunchIDAndProviderAlreadyExistsInTheDatabase: string = 'Game with this launch ID for this provider already exists in the database'

export const ShopDiscount: string = 'Shop discount'
export const Manager: string = 'Manager'
export const Contribution: string = 'Contribution'
export const PleaseEnterLevel: string = 'Please enter level'
export const PleaseEnterLevelName: string = 'Please enter level name'
export const ThisLevelAlreadyExists: string = 'This level already exists'
export const AFinalLevelAlreadyExists: string = 'A final level already exists'
export const AddANewCategory: string = 'Add a new category'


export const Repeat: string = 'Repeat'
export const Every: string = 'Every'
export const Completion: string = 'Completion'
export const Count: string = 'Count'
export const Consecutive: string = 'Consecutive'
export const is: string = 'is'
export const NoTasksDefined: string = 'No tasks defined'
export const NoHiddenRewardsDefined: string = 'No hidden rewards'
export const NumberOfTasks: string = 'Number of tasks'
export const NumberOfHiddenRewards: string = '# of hidden rewards'
export const NoTaskSelected: string = 'No task selected'
export const Needed: string = 'Needed'
export const TimestampFromIsMalformed: string = 'Timestamp from is malformed'
export const TimestampToIsMalformed: string = 'Timestamp to is malformed'
export const TimestampToShouldBeAfterTimestampFrom: string = 'Timestamp to should be after timestamp from'
export const MinMaxConstraints: string = 'Min./max. constraints'
export const Definition: string = 'Definition'
export const LevelingUp: string = 'Leveling-up'
export const AddEditChances: string = 'Add/Edit Chances'
export const EditTheChance: string = 'Edit the Chance'
export const AddANewChance: string = 'Add a new Chance'
export const Chance: string = 'Chance'
export const SpecifyOrderOfTheChances: string = 'Specify order of the chances'
export const ChanceDescriptionOptional: string = 'Chance description (optional)'
export const AddEditShop: string = 'Add/Edit Shop'
export const EditTheShop: string = 'Edit the Shop'
export const AddANewShop: string = 'Add a new Shop'
export const ListOfShops: string = 'List of Shops'
export const AddEditRaces: string = 'Add/Edit Races'
export const EditTheRace: string = 'Edit the Race'
export const AddANewRace: string = 'Add a new Race'
export const SelectRaceType: string = 'Race Type?'
export const ResultsForTheRace: string = 'Results for the race'
export const NoResultsForTheRace: string = 'No results for the race'
export const ListOfRaces: string = 'List of Races'
export const ListOfStores: string = 'List of Stores'
export const notPossible: string = 'not possible'
export const Platform: string = 'Platform'
export const Types: string = 'Types'
export const GameProviders : string = 'Game providers'
export const Excluded: string = 'Excluded'
export const Included: string = 'Included'
export const IDs: string = 'IDs'
export const CurrencyShort: string = 'Curr'
export const Constraint: string = 'Constraint'
export const CategoriesSetup: string = 'Categories setup'
export const GlobalObjectiveConfiguration: string = 'Global objective configuration'
export const _from_:string = ' from '
export const Schedule: string = 'Schedule'
export const Races: string = 'Races'
export const Race: string = 'Race'
export const Ongoing: string = 'Ongoing'
export const Future: string = 'Future'
export const Past: string = 'Past'
export const GlobalRacesConfiguration: string = 'Global races configuration'
export const ListOfChances: string = 'List of Chances'
export const PleaseWaitGettingGameSessionsInProgress: string = 'Please wait, getting game sessions in progress'
export const Require: string = 'Require'
export const Configuration: string = 'Configuration'
export const Reset: string = 'Reset'
export const Providers: string = 'Providers'
export const _are_: string = ' are '
export const Unique: string = 'Unique'
export const Sum: string = 'Sum'
export const Chances: string = 'Chances'
export const Test: string = 'Test'
export const Mechanics: string = 'Mechanics'
export const Sort: string = 'Sort'
export const RacesAreNotOverlapping: string = 'Races are not overlapping.'
export const SomeRacesAreOverlapping: string = 'Some races are overlapping, therefore, it is not possible to save the race.'
export const RaceEnteredCorrectly: string = 'Race is entered correctly.'
export const RaceNotEnteredCorrectly: string = 'Race not entered correctly'
export const PleaseSaveChanceFirstThenEditAndAddTasks: string = 'Please save the chance first, then edit it and add the tasks.'
export const EndDate: string = 'End date'
export const Store: string = 'Store'
export const Approvable: string = 'Approvable'
export const PleaseWaitGettingPendingWithdrawalRequests: string = 'Please wait, getting pending withdrawal requests'
export const PleaseWaitGettingProcessedWithdrawalRequests: string = 'Please wait, getting non-pending withdrawal requests'
export const Invert: string = 'Invert'
export const FullName: string = 'Full name'
export const Invitation: string = 'Invitation'
export const StatusTypeText: string[] = ['Any status', 'Approved', 'Rejected', 'Pending', 'Canceled', 'Executed', 'Completed', 'Denied by bank']
export const LoadMore: string = 'Load more'
export const Move: string = 'Move'
export const Contributions: string = 'Contributions'
export const PleaseDefineNameOfReward: string = 'Please define the name of the reward'
export const BonusCodeForTokensHasToBeNumber: string = 'The bonus code for Tokens has to be a number'
export const CashbackMondayOptins: string = 'Cashback Monday Optins'
export const AllTransactionsHaveCorrectPlayerId: string = 'All transactions have the correct player Id'
export const TransactionsHaveTheWrongPlayerId: string = 'Transactions have the wrong player Id'
export const Visit: string = 'Visit'
export const Website: string = 'Website'
export const NoResults: string = 'No results'
export const Get: string = 'Get'
export const Sessions: string = 'Sessions'
export const Please: string = 'Please'
export const ThisGameHasContribution0: string = 'This game has contribution 0.'
export const Warning: string = 'Warning'
export const ThisGameHasContribution0Desc: string = 'No contribution is assigned to the game. Please select one of the options on the left to select the appropriate contribution!'
export const Superfluous: string = 'Superfluous'
export const Must: string = 'Must'
export const _be_: string = ' be '
export const Received: string = 'Received'
export const YouDontHaveSecurityRightsToAccessData: string = 'You do not have security rights to access this data'
export const Purchases: string = 'Purchases'
export const RepeatedCycles: string = 'Repeated cycles'
export const Progress: string = 'Progress'
export const Duplicates: string = 'Duplicates'
export const WithdrawalsHowTo: string[] = [
    'Load all available Pending Withdrawal Requests (Load more button)',
    'Load all available Processed Withdrawal Requests - Approved (Load more button)',
    'Select the withdrawals that you would like to process',
    'Generate a Processed Payment File (having one with status Generated will block you from selecting new Processed Withdrawals)',
    'Process the Processed Payments File.'
]
export const TryAgain: string = 'Try again'
export const Inquiry: string = 'Inquiry'
export const Home: string = 'Home'
export const Away: string = 'Away'
export const Team: string = 'Team'
export const Bookmaker: string = 'Bookmaker'
export const Source: string = 'Source'
export const Soccer: string = 'Soccer'
export const OddsChecker: string = 'OddsChecker'
export const OddsPortal: string = 'OddsPortal'
// export const SoccerFootball: string = 'Soccer/Football'
export const Import: string = 'Import'
export const Preferred: string = 'Preferred'
export const Betting: string = 'Betting'
export const Exchange: string = 'Exchange'
export const Regions: string = 'Regions'
export const Bookmakers: string = 'Bookmakers'
export const Leagues: string = 'Leagues'
export const BetTypes: string = 'Bet types'
export const BetType: string = 'Bet type'
export const Imported: string = 'Imported'
export const League: string = 'League'
export const Preview: string = 'Preview'
export const Without: string = 'Without'
export const With: string = 'With'
export const BetScopes: string = 'Bet scopes'
export const Tomorrow: string = 'Tomorrow'
export const RobinsChoice: string = 'Robin\'s choice'
export const Scope: string = 'Scope'
export const ThereAreNoDuplicatesFoundInPendingWithdrawals: string = 'There are no duplicates found in pending withdrawals'
export const ThereAreNoDuplicatesFoundInProcessedWithdrawals: string = 'There are no duplicates found in processed withdrawals'
export const Outcomes: string = 'Outcomes'
export const Desktop: string = 'Desktop'
export const Mobile: string = 'Mobile'
export const Table: string = 'Table'
export const Apply: string = 'Apply'
export const Strapi: string = 'Strapi'
export const Failed: string = 'Failed'
export const Load: string = 'Load'
export const no_win: string = 'no win'
export const Redeemed: string = 'Redeemed'
export const Error: string = 'Error'
export const Extended: string = 'Extended'
export const Reason: string = 'Reason'
export const Company: string = 'Company'
export const Method: string = 'Method'
export const NoError: string = 'No error'
export const Inexisting: string = 'Inexisting'
export const Exists: string = 'Exists'
export const Existent: string = 'Existent'
export const Purchase: string = 'Purchase'
export const InsertOrDeleteWageringContribution: string = 'Please insert values for the new wagering contribution or delete the empty wagering contribution'
export const InsertWageringContributionForAllCategories: string = 'Please insert wagering contributions for all categories'
export const Mass: string = 'Mass'
export const Ratio: string = 'Ratio'
export const RewardFrom: string[] = [Level, Shop, Chance, Tournament]
export const Again: string = 'Again'
export const Statuses: string = 'Statuses'
export const Messages: string = 'Messages'
export const PleaseSelectAtLeastOne: string = 'Please select at least one'
export const PleaseSelectThe: string = 'Please select the'
export const ConfirmActivateGame: string = 'Are you sure you want to activate the game'
export const ConfirmDeactivateGame: string = 'Are you sure you want to deactivate the game'
export const AllowBonusMoneyInTournaments: string = 'Allow bonus money in tournaments'
export const Not: string = 'Not'
export const AddEditFreeGame: string = 'Add/Edit Free Game'
export const AddANewFreeGame: string = 'Add a new free game'
export const EditTheFreeGame: string = 'Edit the free game'
export const Granted: string = 'Granted'
export const Condition: string = 'Condition'
export const Repeated: string = 'Repeated'
export const Eligible: string = 'Eligible'
export const Trigger: string = 'Trigger'
export const Duplicate: string = 'Duplicate'
export const Single: string = 'Single'
export const One: string = 'One'
export const CanBeSelected: string = 'Can be selected'
export const Device: string = 'Device'
export const CannotBeDeleted: string = 'Cannot be deleted'
export const InsertAtLeastOneWageringContribution: string = 'Please insert wagering contributions for at least one category'
export const Reusable: string = 'Reusable'
export const Accept: string = 'Accept'
export const Consume: string = 'Consume'
export const VerifiedBy: string = 'Verified by'
export const Instance: string = 'Instance'
export const DoYouWantToContinue: string = 'Do you want to continue'
export const Tag: string = 'Tag'
export const Dependant: string = 'Dependant'
export const Immediate: string = 'Immediate'
export const Alert: string = 'Alert'
export const Tested: string = 'Tested'
export const Qualifying: string = 'Qualifying'
export const Share: string = 'Share'
export const ThisMayTakeAWhile: string = 'This may take a while.'
