import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main'
import {faCog, faUserCog} from '@fortawesome/free-solid-svg-icons'
import {FormInput, FormSelect} from '../../Reusables/Inputs'
import {LevelTypes} from '../../../Logic/Database/levels/levelsEnums'

interface State {
    player: string,
    levelId: string,
    levels?: Array<{id: string, name: string, hierarchy: number}>
}

export class ChangePlayerLevel extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            player: '',
            levelId: '0'
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public byHierarchy(a, b) {
        return a.hierarchy > b.hierarchy ? 1 : -1
    }
/*
    public changePlayerLevel() {
        DbRoutes.changePlayerLevel(this.state.player, +this.state.levelId)
    }
*/
    public render() {
        const levels: Array<{id: string, name: string, hierarchy: number}> = []
        
        if (this.state.levels === undefined && Main.Instance.DatabaseDataState.levelsAllStream) {
            // console.log (Main.Instance.DatabaseDataState.levelsStream)
            for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
                if (item.type === LevelTypes.Invitation) {
                    levels.push({id: item.id.toString(), name: item.name, hierarchy: item.hierarchy})
                }
            }

            levels.sort(this.byHierarchy)
            this.setState({levels})
        }
        return (
            <div>
                <h2>{Assets.Change + ' ' + Assets.Player.toLowerCase() + ' ' + Assets.Level.toLowerCase()}</h2>
            
                <div className = "clearfix">
                    <FormInput
                        float = {true}
                        placeholder = {Assets.PlayerID}
                        value = {this.state.player}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(player) => this.setState({player})} 
                        Icon = {faUserCog}
                    />

                    <FormSelect
                        mt0 = {true}
                        level = {true}
                        value = {this.state.levelId}
                        data = {this.state.levels}
                        ClassName = "form-control"

                        o1 = {Assets.SelectLevel + ' (' + Assets.Invitation + ')'}
                        o2 = {Assets.SelectLevel + ' (' + Assets.Invitation + ')'}
                        onChange = {(levelId) => this.setState({levelId})}
                        Icon = {faCog}
                    />
                </div>
                <div>
                    <button onClick = {() => DbRoutes.changePlayerLevel(this.state.player, +this.state.levelId)} disabled = {Main.Instance.DatabaseDataState.isChangingPlayerLevel || this.state.player.length === 0 || parseInt(this.state.levelId) === 0} type = "button" className = "btn btn-danger btn-sm">
                        {Assets.Change + ' ' + Assets.Player.toLowerCase() + ' ' + Assets.Level.toLowerCase()}
                    </button>
                </div>
            </div>
        ) 
    }
}