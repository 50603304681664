import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import ReactTable from 'react-table'

interface State {
    scope: boolean
    scopes: Array<{id: number, name: string}>
}

export class TableOfSports extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            scope: undefined,
            scopes: this.getScopes()
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public getScope(first: boolean): JSX.Element[] {
        const scope: JSX.Element[] = []
        for (let i: number = first ? 0 : Math.floor(this.state.scopes.length/2); i < (first ? Math.floor(this.state.scopes.length/2) : this.state.scopes.length); i++) {
            scope.push(<li key = {this.state.scopes[i].id.toString()}><strong>{this.state.scopes[i].id}</strong>&nbsp;&nbsp;{this.state.scopes[i].name}</li>)
        }
        return scope
    }
/*
update odds3.sport set scope = 2 where id in(1, 4, 12, 7, 5, 21, 42, 8)
update odds3.sport set scope = 1 where id in(2,3,6,11)
*/
    public getScopes(): Array<{name: string, id: number}> {
        const scopes: string = '{"1":"FT including OT","2":"Full Time","3":"1st Half","4":"2nd Half","5":"1st Period","6":"2nd Period","7":"3rd Period","8":"1Q","9":"2Q","10":"3Q","11":"4Q","12":"1st Set","13":"2nd Set","14":"3rd Set","15":"4th Set","16":"5th Set","17":"1st Inning","18":"2nd Inning","19":"3rd Inning","20":"4th Inning","21":"5th Inning","22":"6th Inning","23":"7th Inning","24":"8th Inning","25":"9th Inning","26":"Next Set","27":"Current Set","28":"Next Game","29":"Current Game"}'
        const Scopes: Array<{name: string, id: number}> = []
        const scope = JSON.parse(scopes)
        const keys: string[] = Object.keys(scope)
        for (const item of keys) {
            Scopes.push ({id: +item, name: scope[item].replace(/&nbsp;/g, ' ')})
        }
        // console.log (Scopes)
        return Scopes
    }

    public getScopeOddsportal(id: number): string {
        // console.log (this.state.betTypes)
        // console.log (id)
        if (id) {
            for (let i: number = 0; i < this.state.scopes.length; i++) {
                if (this.state.scopes[i].id === id) {
                    return this.state.scopes[i].name
                }
            }
        }
        return '-'
    }

    public getFilter() {
        const filter: JSX.Element[] = []
        filter.push (<input key = "0" onClick = {() => this.setState({scope: undefined})} checked = {this.state.scope === undefined} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "1" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.All + ' ' + Assets.BetScopes.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "2" onClick = {() => this.setState({scope: true})} checked = {this.state.scope === true} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "3" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {this.getScopeOddsportal(1)}
            </div>
        )
        
        filter.push (<input key = "4" onClick = {() => this.setState({scope: false})} checked = {this.state.scope === false} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "5" className = "fl dbl" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px', marginRight: '20px'}}>
                {this.getScopeOddsportal(2)}
            </div>
        )

        return filter
    }

    public render() {
        // console.log (Main.Instance.OddsState.sport)
        const filtered = []
        if (this.state.scope === true) {
            filtered.push({id: 'scope', value: 1})
        } else if (this.state.scope === false) {
            filtered.push({id: 'scope', value: 2})
        }
        return (
            <div>
                <h3 className = "pr">
                <div className = "clearfix" style = {{position: 'absolute', top: '10px', right: '0px'}}>{this.getFilter()}</div>
                    {Assets.Sports + ' ' + Assets.Preview.toLowerCase()}
                </h3>
                {Main.Instance.OddsState.sport ?
                <>
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.OddsState.sport}
                    columns = {[
                        {width: 50, Header: Assets.ID, headerClassName: 'th-left', accessor: 'id'},
                        {width: 200, Header: Assets.Main + ' ' + Assets.Scope.toLowerCase() + ' (' + Assets.OddsPortal + ')', headerClassName: 'th-left', accessor: 'scope', Cell: (row) => this.getScopeOddsportal(row.value)},
                        {Header: Assets.Name, headerClassName: 'th-left', accessor: 'name'},
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                />
                <div className = "clearfix mt5">
                    <div className = "fl" style = {{width: '25%'}}>
                        <strong>{Assets.BetScopes}:</strong>
                        <ul style = {{marginLeft: '20px'}}>{this.getScope(true)}</ul>
                    </div>
                    <div className = "fl" style = {{width: '25%'}}>
                        <ul style = {{marginLeft: '0px'}}>{this.getScope(false)}</ul>
                    </div>
                </div>
                </> : void 0}
            </div>
        )
    }
}