import * as React from 'react'
import * as Assets from '../../Assets'
import {FormInput, FormSelect} from '../../Reusables/Inputs'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {isUserSystem} from '../../../Logic/Utils' 
import {isPasswordStrongEnough, IsPasswordDifferent} from '../../../Logic/Utils'
import * as Config from '../../../Logic/Config'
import * as Assets_ from '../../Assets_'

function Update_() {
    const items = { 
        password: Main.Instance.SettingsState.password, 
        username: Main.Instance.SettingsState.username,
        id: Main.Instance.LoginState.id,
        sq: +Main.Instance.SettingsState.security_question > 0 ? Main.Instance.SettingsState.security_question : undefined,
        sqa: Main.Instance.SettingsState.security_question_answer,
    //  entity_id: Main.Instance.LoginState.entity_id
    }
    DbRoutes.updatePassword(items, UpdateCallback)
}

function UpdateCallback() {
    Logic.Type.New (Main.Instance.SettingsState, {
        username_saved: Main.Instance.SettingsState.username,
        // password_old: '',
        password_repeat: '',
        password: '',
        security_question: '0',
        security_question_answer: '',
    })
    alert (Assets.SuccessChange)
}

function Update(/*a:boolean,*/ b:boolean, c:boolean, d:boolean/*, e: boolean, f: boolean*/) {

    if (/*a &&*/ b && c && d /*&& e && f*/) {
        Update_()
    } else {
        alert (Assets.ProblemsChange)
    }
}

export function UserSettings() {
    let validation_new: boolean = false
    // let validation: boolean = false
    let validation1: boolean = false
    let validation2: boolean = false
    // let validation3: boolean = false
    let validation_username: boolean = false

    if (!Main.Instance.SettingsState.username) {
    Logic.Type.New (Main.Instance.SettingsState, {
        username_saved:Main.Instance.LoginState.Username,
        username:Main.Instance.LoginState.Username,
        // password_old:'',
        password_repeat:'',
        password:'',
    })
    }

    if (Main.Instance.SettingsState.username.length > 0) {
        validation_username = true
    }
/*
    console.log (Main.Instance.SettingsState.password_old)
    if (Main.Instance.SettingsState.password !== Main.Instance.SettingsState.password_old) {
        validation3 = true
    }
*/
    // if (validation3)
    validation1 = isPasswordStrongEnough(Main.Instance.SettingsState.password)
    if (/*validation3 &&*/ validation1) { validation2 = IsPasswordDifferent(Main.Instance.SettingsState.password, Main.Instance.SettingsState.username) }

    if (Main.Instance.SettingsState.password_repeat.length > 0 && 
    Main.Instance.SettingsState.password === Main.Instance.SettingsState.password_repeat /*&& 
    Main.Instance.SettingsState.password !== Main.Instance.SettingsState.password_old*/) {
        validation_new = true
    }

    // we have to check for password strength as well.
    // at least 8 characters
    // at least 1 uppercase letter
    // at least 1 lowercase letter
    // at least 1 number
    // at least 1 special character

    const validationsq: boolean = parseInt(Main.Instance.SettingsState.security_question) !== 0
    const validationsqa: boolean = Main.Instance.SettingsState.security_question_answer.length > 0

    return( 
        <div>
            <h3 className = "level fl">
                {Assets.UserSettings}
            </h3>

            {!isUserSystem() ?
            <span className = "label label-primary fr label-table mr mt20">
                {Main.Instance.LoginState.entity}
            </span> :
            void 0}
            {/*
            <span className = "label label-primary fr label-table-grey mr mt20">
                {Main.Instance.LoginState.roles}
            </span>
            */}
            <br/>
            <div className="row admin-users-container">
                <div className="col-md-12 col-sm-12 col-xs-12 admin-users-panel">
                    <div className="register-input">
                        <div className = "row admin-users-row">
                            <h4>
                                {Assets.us1 + ' "'}
                                {!Main.Instance.SettingsState.username_saved ? '' : Main.Instance.SettingsState.username_saved}{'"'}
                            </h4>
                        </div>

                        <div className = "row admin-users-row">
                            <FormInput 
                                validation = {validation1 && validation2/* && validation3*/}
                                description = {
                                    !validation1 ? Assets.passwordRequirements1 :
                                    !validation2 ? Assets.passwordRequirements4 :
                                    /*!validation3 ? Assets.passwordRequirements3 :*/
                                    Assets.passwordRequirements0
                                }                                
                                value = {!Main.Instance.SettingsState.password ? '' : Main.Instance.SettingsState.password}
                                ClassName = "form-control register-input"
                                type = "password"
                                onChange = {(password) => Logic.Type.New(Main.Instance.SettingsState, {password})}
                                placeholder = {Assets.pNewPassword}
                                icon = {Assets_.faPasswordClass}
                            />

                            <FormInput 
                                validation = {validation_new}
                                description = {validation_new ? Assets.passwordsMatch : Assets.passwordsDontMatch}
                                value = {!Main.Instance.SettingsState.password_repeat ? '' : Main.Instance.SettingsState.password_repeat}
                                ClassName = "form-control register-input"
                                type = "password"
                                onChange = {(password_repeat) => Logic.Type.New(Main.Instance.SettingsState, {password_repeat})}
                                placeholder = {Assets.pRepeatPassword}
                                icon = {Assets_.faPasswordClass}
                            />

                            <FormSelect
                                validation = {validationsq}
                                value = {!Main.Instance.SettingsState.security_question ? '0' : Main.Instance.SettingsState.security_question}
                                data = {Assets_.securityQuestions} 
                                ClassName = "form-control" 
                                o1 = {Assets_.securityQuestions[0].name} 
                                onChange = {(security_question) => Logic.Type.New(Main.Instance.SettingsState, {security_question})} 
                                icon = {Assets_.faQuestionCircleButtonClass}
                            />

                            <FormInput
                                validation = {validationsqa}
                                value = {!Main.Instance.SettingsState.security_question_answer ? '' : Main.Instance.SettingsState.security_question_answer}
                                ClassName = "form-control register-input" 
                                type = "password" 
                                onChange = {(security_question_answer) => Logic.Type.New(Main.Instance.SettingsState, {security_question_answer})} 
                                placeholder = {Assets.pSecurityQuestionAnswer} 
                                icon = {Assets_.faQuestionCircleWhiteButtonClass}
                            />
                        </div>
                        <div className = "row admin-users-row">
                            <FormInput 
                                validation = {validation_username}
                                value = {!Main.Instance.SettingsState.username ? '' : Main.Instance.SettingsState.username}
                                ClassName = "form-control register-input"
                                type = "text"
                                onChange = {(username) => Logic.Type.New(Main.Instance.SettingsState, {username})}
                                placeholder = {Assets.pUsername}
                                icon = {Assets_.faUsernameClass}
                            />

                            <ButtonIcon
                                disabled = {!(validation_new && validation1 && validation2 && validation_username /*&& validationsq && validationsqa*/)}
                                description = {Assets.saveChanges}
                                click = {() => {
                                    DbRoutes.insertAdminLog (
                                        Config.asAdminLogChangeData,
                                        Config.asAdminLogUserSettings,
                                        {
                                        },
                                    )
                                    Update(validation_new, validation1 && validation2, validation_username/*, validationsq, validationsqa*/)
                                }}
                                ClassName = {
                                    validation_new && validation1 && validation2 /*&& validation3*/ && validation_username ? 
                                    'btn btn-success btn-sm btn-plus fr mr' :
                                    'btn btn-danger btn-sm btn-plus fr mr'
                                }
                                icon = {Assets_.faLockClass}
                                text = {Assets.Change}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>{Assets.passwordRequirements1}&nbsp;{Assets.passwordRequirements4}{/*&nbsp;*/}{/*Assets.passwordRequirements3*/}</div>
        </div>
    )
}