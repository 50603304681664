import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
// import {isUserSiteOwner} from '../../../Logic/Utils' 
import {IsBuildScandibet, IsBuildTrekronor, IsBuildBettoday, IsBuildJackpotsCH, IsBuildMyChance, IsBuildPasino} from '../../../Logic/Utils'

export function FrontendStoreWrapper(props: React.Props<any>) {
    // console.log (Main.Instance.LoginState.entity/* !== 'Back End'*/)
    frontendStoreRouter()
    return (<div>{props.children}</div>)
}

function frontendStoreRouter() {
    if (RouterObjects['front___End_Store'] && Main.Instance.Location === RouterObjects['front___End_Store'].path) {
        if (IsBuildMyChance() && RouterObjects['front___End_Store'].children['Languages_Management']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Languages_Management'].path})
        } else if ((IsBuildJackpotsCH() || IsBuildPasino()) && RouterObjects['front___End_Store'].children['Export_to_CMS']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Export_to_CMS'].path})
        } else if (IsBuildBettoday() && RouterObjects['front___End_Store'].children['Site_Settings']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Site_Settings'].path})
        } else if (IsBuildScandibet() && RouterObjects['front___End_Store'].children['Shop_Settings']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Shop_Settings'].path})
        } else if (IsBuildTrekronor() && RouterObjects['front___End_Store'].children['Site_Settings']) {
                Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Site_Settings'].path})
        } else if (RouterObjects['front___End_Store'].children['Games']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Games'].path})
        } else if (RouterObjects['front___End_Store'].children['Shop_Settings']) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['front___End_Store'].path + RouterObjects['front___End_Store'].children['Shop_Settings'].path})
        }
    }
}
