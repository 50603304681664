import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {/*RiskLevel, */SelectedGenericSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
// import Switch from 'react-toggle-switch'
import {isUserAdmin, injectSpaces,/*, isUserRFSpecialist, isUserRGSpecialist*/isUserGamanzaAdmin, isUserGamanzaGamesAdmin} from '../../../Logic/Utils'
import {faSave, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
// import ReactTable from 'react-table'
// import * as TableUtils from '../../Reusables/TableUtils'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
// import {InsideTitle/*, handleText, NotifyButton, AddNotify, handleDeleteNotify, Update*/} from '../RiskComplianceStore/RiskManagement'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Config from '../../../Logic/Config'
import {Custom, CooloffCategories, CooloffOptionCategory, CooloffGameCategory} from '../../../Logic/Database/DatabaseData'
// import {riskLevelSlider} from '../RiskComplianceStore/ResponsibleGaming'
/*
function handleNumber(id: number, text: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) custom.responsibleGaming.selfAssessment.answersScale.min = +text
    else if (id === 2) custom.responsibleGaming.selfAssessment.answersScale.max = +text
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/
function selectNavigation(selectedGenericSetting: SelectedGenericSetting) {
    if (selectedGenericSetting === SelectedGenericSetting.Currencies) {
        DbRoutes.wsBasicGet(Config.amCurrencies)
    }
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedGenericSetting})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
        for (let i: number = 0; i < Object.keys(SelectedGenericSetting).length / 2; i++) {
            if (i === SelectedGenericSetting.CooloffCategories && (isUserGamanzaAdmin() || isUserGamanzaGamesAdmin())) {continue}
            navigation.push(
                <button
                    // title = {i === SelectedGenericSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGenericSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                    // disabled = {i === SelectedGenericSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedGenericSetting.ResponsibleGaming && !isUserRGSpecialist()}
                    onClick = {() => selectNavigation(i)}
                    type = "button"
                    key = {i.toString()}
                    style = {{
                        marginRight: i === Object.keys(SelectedGenericSetting).length / 2 - 1 ? '0px' : '',
                        display: 'block',
                        float: 'left',
                    }}
                    className = {i === Main.Instance.RiskManagementState.selectedGenericSetting ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
                >
                    <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedGenericSettingIcons[i]}/>
                    {injectSpaces(SelectedGenericSetting[i])}
                </button>)
        }
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}
/*
function toggleNotifyDefault (i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.notify.riskLevelDefaults[i] = !custom.notify.riskLevelDefaults[i]
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getNotifyDefaults(): JSX.Element[] {
    const notifyDefaults: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.notify && Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults.length; i++) {
        notifyDefaults.push (
            <button
                key = {i.toString()}
                onClick = {() => toggleNotifyDefault(i)}
                className = {Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults[i] ? 'btn btn-xs risk risk-' + RiskLevel[i + 1] : 'btn btn-default btn-xs'}
            >
                {RiskLevel[i + 1]}
            </button>
        )
    }
    return notifyDefaults
}
*/
// CooloffOptionCategory, CooloffGameCategory

function GetCooloffCategory(i: number, j: number): boolean {
    const cooloffOptionCategories: string[] = Object.keys(CooloffOptionCategory)

    for (let k: number = 0; k < cooloffOptionCategories.length / 2; k++) {
        if ((+cooloffOptionCategories[k]) === 100 * i + j) {
            return true
        }
    }

    const cooloffGameCategories: string[] = Object.keys(CooloffGameCategory)
    for (let k: number = 0; k < cooloffGameCategories.length / 2; k++) {
        if ((+cooloffGameCategories[k]) === 100 * i + j) {
            return false
        }
    }

    return undefined
}
// 
function getCooloffCategoryName(cc: boolean, c: number): string {
    if (cc) {
        const cooloffOptionCategories: string[] = Object.keys(CooloffOptionCategory)
        for (let i: number = 0; i < cooloffOptionCategories.length / 2; i++) {
            return injectSpaces(CooloffOptionCategory[c])
        }

    } else if (cc === false) {
        const cooloffGameCategories: string[] = Object.keys(CooloffGameCategory)
        for (let i: number = 0; i < cooloffGameCategories.length / 2; i++) {
            return injectSpaces(CooloffGameCategory[c])
        }
    } else {
        const cooloffCategories: string[] = Object.keys(CooloffCategories)
        for (let i: number = 0; i < cooloffCategories.length / 2; i++) {
            return injectSpaces(CooloffCategories[c])
        }
    }
}

function getCooloffCategoryClassName(cc: boolean): string {
    return cc ? 'btn btn-xs btn-primary' : cc === false ? 'btn btn-xs btn-default' : 'btn btn-xs btn-dark btn-default'
}

function getCooloffCategoryDescription(cc: boolean): string {
    return cc ? Assets.Cooloff + ' ' + Assets.Option.toLowerCase() : cc === false ? Assets.Cooloff + ' ' + Assets.GameCategory.toLowerCase() : Assets.Disabled
}

function getCooloffCategories(): JSX.Element {
    const cc: JSX.Element[][] = []
    let I: number = -1
    let J: number = -1
    const cooloffCategories: string[] = Object.keys(CooloffCategories)
    // console.log (cooloffCategories)
    for (let i: number = 0; i < cooloffCategories.length / 2; i++) {
        if ((+cooloffCategories[i]) / 100 >= I) {I = Math.floor((+cooloffCategories[i]) / 100)}
        if ((+cooloffCategories[i]) % 100 >= J) {J = (+cooloffCategories[i]) % 100}
    }

    // console.log (I, J)
    for (let i: number = 0; i <= I; i++) {
        cc.push([])
        for (let j: number = 0; j <= J; j++) {
            const CC: boolean = GetCooloffCategory(i, j)
            cc[i].push(<button style = {{width: '120px', cursor: 'default'}} title = {getCooloffCategoryDescription(CC)} className = {getCooloffCategoryClassName(CC)}>{getCooloffCategoryName(CC, i * 100 + j)}</button>)
        }
    }

    const c: JSX.Element[] = []
    for (let i: number = 0; i < cc.length; i++) {
        const C: JSX.Element[] = []
        for (let j: number = 0; j < cc[i].length; j++) {
            C.push (cc[i][j])
        }
        c.push (<div className = "mb5" key = {i.toString()}>{C}</div>)
    }

    return <div>{c}</div>
}

export function GenericSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    if (Main.Instance.DatabaseDataState.category_ === undefined && Main.Instance.DatabaseDataState.gamesListCategories !== undefined) {
        const category_: string[] = []
        for (const {} of Main.Instance.DatabaseDataState.gamesListCategories) {
            category_.push(undefined)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
    }
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.GenericSettings}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {/*InsideTitle(undefined, injectSpaces(SelectedGenericSetting[Main.Instance.RiskManagementState.selectedGenericSetting]), Assets.SelectedGenericSettingIcons[Main.Instance.RiskManagementState.selectedGenericSetting])*/}
                        {/*<br/>*/}
                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.Notifications ?
                        <>                        
                        <span
                            style = {{position: 'absolute', top: '15px', left: '170px', fontSize: '14px'}}
                            className = "label label-default label-sm"
                        >
                            Show default risk values
                        </span>
                        <div style = {{position: 'absolute', top: '15px', left: '370px'}}>{getNotifyDefaults()}</div>
                        <br/>
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.notify ? Main.Instance.DatabaseDataState.notify : []}
                            columns = {[
                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">
                                    {(row.value)}</div>},
                                {
                                Header: <span>{Assets.PersonalData}</span>,
                                headerClassName: 'risk-default',
                                columns: [
                                    {width: 250, Header: Assets.Email, accessor: 'data.email', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {e => handleText (e.target.value, row.index, 0)}
                                            type = "text"
                                            value = {row.value}
                                        />
                                    </div>},
                                    {width: 150, Header: Assets.FirstName, accessor: 'data.firstName', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {e => handleText (e.target.value, row.index, 1)}
                                            type = "text"
                                            value = {row.value}
                                        />
                                    </div>},
                                    {width: 150, Header: Assets.LastName, accessor: 'data.lastName', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {e => handleText (e.target.value, row.index, 2)}
                                            type = "text"
                                            value = {row.value}
                                        />
                                    </div>},
                                ]},
                                {
                                    Header: <span>{Assets.RiskLevel}</span>,
                                    headerClassName: 'risk-default-light',
                                    columns: [
                                        {width: 110, Header: 'Low risk', accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Low, row)}</div>},
                                        {width: 110, Header: 'Normal risk', accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Normal, row)}</div>},
                                        {width: 110, Header: 'Medium risk', accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Medium, row)}</div>},
                                        {width: 110, Header: 'High risk', accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.High, row)}</div>},
                                        {width: 110, Header: 'Severe risk', accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Severe, row)}</div>},
                                    ]
                                },
                                {
                                Header: <span>{Assets.Actions}</span>,
                                show: isUserAdmin(),
                                headerClassName: 'risk-default',
                                columns: [
                                    {show: isUserAdmin(), width: 50,
                                        Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNotify()}>{Assets.Add}</button>,
                                    accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNotify)},
                                    {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 8)}
                                ]}
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        </> : void 0*/}
                        {Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.CooloffCategories ?
                        <>
                            <div>
                                {getCooloffCategories()}
                            </div>
                        </> : void 0}
                        {Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.Locales ?
                        <>
                            <div>
                                {getItems(1, [], Main.Instance.DatabaseDataState.custom.general.languages)}
                            </div>
                        </> : void 0}

                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.TimeUnits ?
                        <>
                            <div>
                                {getItems(2, Main.Instance.DatabaseDataState.custom.general.timeUnits.cooloffs)}
                            </div>
                        </> : void 0*/}

                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.TimePeriods ?
                        <>
                              <div>
                                {getItems(3, Main.Instance.DatabaseDataState.custom.general.timePeriods.limits)}
                            </div>
                        </> : void 0*/}
                        
                        {Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.Currencies ?
                        <>
                            <div>
                                {getItems(4, Main.Instance.DatabaseDataState.custom.general.currencies)}
                            </div>
                        </> : void 0}
                        
                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.RiskManagement ?
                        <>
                            <div className = "clearfix">
                                <span className = "fl dbl mt3 mr5px">{Assets.CheckNightJob}</span>
                                <Switch
                                    className = "dbl fl"
                                    onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.riskManagement.showOnlyElevatedRisk, 0, 7)}
                                    on = {Main.Instance.DatabaseDataState.custom.riskManagement.showOnlyElevatedRisk}
                                />
                            </div>
                        </> : void 0*/}
                        
                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.ResponsibleGaming ?
                        <>
                        <div>
                            {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend ?
                            <>
                                <h4>{Assets.DashboardBackend}</h4>
                                <div className = "clearfix">
                                    <span className = "fl dbl mt3 mr5px">{Assets.CheckNightJob}</span>
                                    <Switch
                                        className = "dbl fl"
                                        onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.checkNightJob, 0, 5)}
                                        on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.checkNightJob}
                                    />
                                </div>
                            </> : void 0}

                            <div>
                                <br/>
                                <h4>{Assets.Questionnaire}</h4>
                                <div>
                                    <span className = "mr2">{Assets.AnswersScale}</span>
                                    <span className = "mr5px">{Assets.min}</span>
                                    <input
                                        disabled = {true}
                                        type = "number"
                                        min = "1"
                                        size = {5}
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min}
                                        onChange = {e => handleNumber(1, e.target.value)}
                                        className = "mr2 pr0"
                                        style = {{width: '50px'}}
                                    />
                                    <span className = "mr5px">{Assets.max}</span>
                                    <input
                                        disabled = {true}
                                        type = "number"
                                        min = "1"
                                        size = {5}
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max}
                                        onChange = {e => handleNumber(2, e.target.value)}
                                        className = "pr0"
                                        style = {{width: '50px'}}
                                    />
                                </div>
                            </div>

                            {Main.Instance.DatabaseDataState.custom &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.issues ?
                            <>
                                <div className = "clearfix mt20">
                                    <h4>{Assets.CallbackList}</h4>
                                    <span className = "fl dbl mt3 mr5px">{Assets.CallbackOptionActive}</span>
                                    <Switch
                                        className = "dbl fl"
                                        onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active, 0, 12)}
                                        on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active}
                                    />
                                </div>
                                <div className = "mt5">
                                    <span>{Assets.CustomerSupportEmail}</span>
                                    <input
                                        type = "text"
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.CSEmail}
                                        onChange = {e => handleText(e.target.value, 0, 6)}
                                        className = "pr0"
                                        style = {{width: '300px'}}
                                    />
                                </div>
                                <div className = "mt5">
                                    <span>{Assets.SetRiskLevelCallback}</span>
                                    <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}>
                                        {RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}
                                        {riskLevelSlider(6, 0, Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel)}
                                    </button>

                                </div>
                            </> : void 0}
                        </div>
                        </> : void 0*/}
                        
                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.KnowYourCustomer ?
                        <>
                            <div className = "fl mr100">
                                <h4>{Assets.Identification}</h4>
                                {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC && Main.Instance.DatabaseDataState.custom.KYC.identification?
                                <>
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.identification.nationalIdCard, 0, Assets.NationalIdCard)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.identification.passport, 1, Assets.Passport)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.identification.drivingLicense, 2, Assets.DrivingLicense)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.identification.documentsFormat, 0, Assets.AllowedDocumentsFormats)}
                                </>
                                : void 0
                                }
                            </div>
                            <div className = "fl mr100">
                                <h4>{Assets.ProofOfAddress}</h4>
                                {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC && Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress ?
                                <>
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress.utilityBill, 3, Assets.UtilityBill)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress.bankStatement, 4, Assets.BankStatement)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress.localAuthorityTaxBill, 5, Assets.LocalAuthorityTaxBill)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress.documentsFormat, 1, Assets.AllowedDocumentsFormats)}
                                </>
                                : void 0
                                }
                            </div>
                            <div className = "fl mr100">
                                <h4>{Assets.ProofOfPayment}</h4>
                                    {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC &&
                                        Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment ?
                                <>
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment.bankAccount, 6, Assets.BankAccount)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment.creditCard, 7, Assets.Creditcard)}
                                    {Switches(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment.eWallet, 8, Assets.eWallet)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment.documentsFormat, 2, Assets.AllowedDocumentsFormats)}
                                </>
                                : void 0
                                }
                            </div>
                        </> : void 0*/}

                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.SystemCommunication ?
                        <>
                            <div>
                                {getItems(5, Main.Instance.DatabaseDataState.custom.general.communications)}
                            </div>
                        </> : void 0*/}

                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.GameCategories ?
                        <>
                            <div>
                                <h4>Games can be categorized in the following categories - subcategories (if defined) are below categories</h4>
                                {getCategories()}
                            </div>
                        </> : void 0*/}

                        {/*Main.Instance.RiskManagementState.selectedGenericSetting === SelectedGenericSetting.LimitTypes ?
                        <>
                              <div>
                                {getItems(6, Main.Instance.DatabaseDataState.custom.general.limitTypes)}
                            </div>
                        </> : void 0*/}
                    </div>
                </div>
            </div> : void 0}            
        </div>
    )
}

function add(id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) { custom.general.languages.push({locale: Main.Instance.RiskManagementState.addLanguageLocale, name: Main.Instance.RiskManagementState.addLanguage,short: Main.Instance.RiskManagementState.addLanguageShort}) } else if (id === 5) { custom.general.communications.push(Main.Instance.RiskManagementState.addCommunication) }
    // else if (id === 6) custom.general.limitTypes.push({limitType: Main.Instance.RiskManagementState.addCommunication, active: true})
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})

    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, {addLanguage: '', addLanguageLocale: '', addLanguageShort: ''}) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, {addTimeUnit: ''}) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, {addTimePeriod: ''}) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, {addCurrency: ''}) } else if (id === 5) { Logic.Type.New(Main.Instance.RiskManagementState, {addCommunication: ''}) } else if (id === 6) { Logic.Type.New(Main.Instance.RiskManagementState, {addLimitType: ''}) }
}

function edit(id: number, value: string) {
    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, {addLanguage: value}) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, {addTimeUnit: value}) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, {addTimePeriod: value}) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, {addCurrency: value}) } else if (id === 5) { Logic.Type.New(Main.Instance.RiskManagementState, {addCommunication: value}) } else if (id === 6) { Logic.Type.New(Main.Instance.RiskManagementState, {addLimitType: value}) }
}

// function edit2 (id: number, value: string) {if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguageLocale: value})}
// function edit3 (id: number, value: string) {if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguageShort: value})}

function del(id: number, i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    // if (id === 1) custom.general.languages.splice (i, 1)
    // else if (id === 2) custom.general.timeUnits.cooloffs.splice (i, 1)
    // else if (id === 3) custom.general.timePeriods.limits.splice (i, 1)
    // else if (id === 4) custom.general.currencies.splice (i, 1)
    if (id === 5) { custom.general.communications.splice (i, 1) }
    // else if (id === 6) custom.general.limitTypes.splice (i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setDefaultLocale(defaultLocale: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.defaultLocale = defaultLocale
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setDefaultCurrency(defaultCurrency: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.defaultCurrency = defaultCurrency
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setLocale(found) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.languages.push({locale: found.code, name: found.name, short: found.name.substring(0,2)})
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setCurrency(found) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.currencies.push(found.code)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function removeLocale(code: string) {
    let found: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
        if (Main.Instance.DatabaseDataState.custom.general.languages[i].locale === code) {
            found = i
            break
        }
    }
    if (found !== -1) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.general.languages.splice(found, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function removeCurrency(code: string) {
    let found: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.currencies.length; i++) {
        if (Main.Instance.DatabaseDataState.custom.general.currencies[i] === code) {
            found = i
            break
        }
    }
    if (found !== -1) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.general.currencies.splice(found, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function toggle(id: number, key: string) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (id === 2) { custom.general.timeUnits.cooloffs[key] = !custom.general.timeUnits.cooloffs[key] } else if (id === 3) { custom.general.timePeriods.limits[key] = !custom.general.timePeriods.limits[key] } else if (id === 6) { custom.general.limitTypes[key] = !custom.general.limitTypes[key] }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

export function getItems(id: number, array: string[]|any, array2?: Array<{locale: string, name: string, short: string}>, array3?:Array<{span: string, active: boolean}>, array4?:Array<{limitType: string, active: boolean}>): JSX.Element[] { 
    const items: JSX.Element[] = []
    if (id === 1 || id === 4 || id === 5) {
        if (id === 1) {
            // console.log (Main.Instance.DatabaseDataState.Languages)
            let firstLetter: string = '0'
            if (Main.Instance.DatabaseDataState.Languages) {
            for (const item of Main.Instance.DatabaseDataState.Languages) {
                const currentFirstLetter: string = item.data.name.charAt(0)
                if (currentFirstLetter !== firstLetter) {
                    firstLetter = currentFirstLetter
                    items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                }
                let found: boolean = false

                // let Found
                for (const Item of array2) {
                    if (Item.locale === item.data.code) {
                        found = true
                        // Found = item
                        break
                    }
                }
                items.push (
                    <span className = "mr" key = {item.data.code + '_' + item.data.name}>
                        <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeLocale(item.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.code}</button>
                        <button onClick = {() => found ? setDefaultLocale(item.data.code) : setLocale(item.data)} title = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? Assets.ThisIsTheDefaultLanguage + '.' : Assets.ClickToSetTheLanguageAsTheDefaultLanguage + '.' : Assets.IncludeThisLanguage + '.'} className = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>
                            {item.data.name}
                        </button>
                    </span>,
                )
            }
            }
        } else if (id === 4) {
            // console.log (Main.Instance.DatabaseDataState.currencies)
            let firstLetter: string = '0'

            if (Main.Instance.DatabaseDataState.currencies) {
            for (let i: number = 3; i < Main.Instance.DatabaseDataState.currencies.length; i++) { // START WITH 3, because there are some special cases
                const item = Main.Instance.DatabaseDataState.currencies[i]

                const currentFirstLetter: string = item.data.code.charAt(0)
                if (currentFirstLetter !== firstLetter) {
                    firstLetter = currentFirstLetter
                    items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                }
                let found: boolean = false

                // let Found
                for (const Item of array) {
                    if (Item === item.data.code) {
                        found = true
                        // Found = item
                        break
                    }
                }

                items.push (
                    <span className = "mr" key = {item.data.code + '_' + item.data.name}>
                        <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(item.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.code}</button>
                        <button onClick = {() => found ? setDefaultCurrency(item.data.code) : setCurrency(item.data)} title = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? Assets.ThisIsTheDefaultCurrency + '.' : Assets.ClickToSetTheCurrencyAsTheDefaultCurrency + '.' : Assets.IncludeThisCurrency + '.'} className = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>
                            {item.data.name}
                        </button>
                    </span>,
                )

                if (i === 24) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[1]
                    found = false
                    const CurrentFirstLetter: string = ITEM.data.code.charAt(0)
                    if (CurrentFirstLetter !== firstLetter) {
                        firstLetter = CurrentFirstLetter
                        items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                    }
    
                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }
    
                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? Assets.ThisIsTheDefaultCurrency + '.' : Assets.ClickToSetTheCurrencyAsTheDefaultCurrency + '.' : Assets.IncludeThisCurrency + '.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>
                                {ITEM.data.name}
                            </button>
                        </span>,
                    )
                } else if (i === 40) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[2]
                    found = false

                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }

                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? Assets.ThisIsTheDefaultCurrency + '.' : Assets.ClickToSetTheCurrencyAsTheDefaultCurrency + '.' : Assets.IncludeThisCurrency + '.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>
                                {ITEM.data.name}
                            </button>
                        </span>,
                    )
                } else if (i === 126) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[0]
                    found = false

                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }
                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? Assets.ThisIsTheDefaultCurrency + '.' : Assets.ClickToSetTheCurrencyAsTheDefaultCurrency + '.' : Assets.IncludeThisCurrency + '.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>
                                {ITEM.data.name}
                            </button>
                        </span>,
                    )
                }

            }
            }
        } else if (id === 5) {
            for (let i: number = 0; i < (array.length === 0 ? array2.length : array.length); i++) {
/*
                if (id === 1) {
                    items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array2[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].locale}</button>)
                    items.push (<button onClick = {() => setDefaultLocale(array2[i].locale)} title = {array2[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'This is the default locale.' : 'Click to set it as the default locale.'} key = {i.toString()} className = {array2[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-success btn-xs br0 mr0'}>{array2[i].name}</button>)
                    items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array2[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].short}</button>)
                } else items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array.length === 1 ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array[i]}</button>)


*/

                items.push (<button onClick = {() => del(id, i)} style = {{cursor: 'default'}} key = {i.toString() + '__'} className = "btn btn-success btn-xs br0 mr0">{array[i]}</button>)
                items.push (<button style = {{cursor: 'default', marginLeft: '-1px'}} disabled = {!isUserAdmin()} key = {i.toString() + '_'} className = "btn btn-danger btn-xs br0"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
            }
        }
        if (id === 5) {
            // if (id === 1) items.push (<input disabled = {!isUserAdmin()} key = "-4" onChange = {(e) => edit2 (id, e.target.value)} style = {{height: '22px', width: '70px'}} type = "text" maxLength = {7} value = {Main.Instance.RiskManagementState.addLanguageLocale}/>)
            items.push (<input disabled = {!isUserAdmin()} key = "-2" onChange = {(e) => edit (id, e.target.value)} style = {{height: '22px'}} type = "text" value = {/*id === 1 ? Main.Instance.RiskManagementState.addLanguage : /*id === 2 ? Main.Instance.RiskManagementState.addTimeUnit : id === 3 ? Main.Instance.RiskManagementState.addTimePeriod : id === 4 ? Main.Instance.RiskManagementState.addCurrency : */id === 5 ? Main.Instance.RiskManagementState.addCommunication : Main.Instance.RiskManagementState.addLimitType}/>)
            // if (id === 1) items.push (<input disabled = {!isUserAdmin()} key = "-3" onChange = {(e) => edit3 (id, e.target.value)} style = {{height: '22px', width: '50px'}} type = "text" maxLength = {3} value = {Main.Instance.RiskManagementState.addLanguageShort}/>)
            items.push (<button disabled = {!isUserAdmin()} key = "-1" onClick = {() => add(id)} className = "btn btn-primary btn-xs mr0 br0"><FontAwesomeIcon icon = {faPlus}/></button>)
        }
    } else {
        items.push (<h4 key = {id === 2 ? Assets.TimeUnits_ : id === 3 ? Assets.Limits : ''}>{id === 2 ? Assets.TimeUnits_ : id === 3 ? Assets.Limits : ''}</h4>)

        for (const key in array) {
            // console.log (key + ': ' + array[key])
            // items.push (<button style = {{cursor: 'default'}} key = {key} className = {array[key] ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].locale}</button>)
            items.push (<button onClick = {() => toggle(id, key)} key = {key} className = {array[key] ? 'btn btn-success btn-xs' : 'btn btn-secondary btn-xs'}>{key}</button>)
            // items.push (<button style = {{cursor: 'default'}} key = {key} className = {array[key] ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].short}</button>)

        }
/*
        for (let i: number = 0; i < (array3.length === 0 ? array4.length : array3.length); i++) {
            if (id === 6) {
                items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array4[i].active ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].locale}</button>)
                items.push (<button onClick = {() => setDefaultLocale(array4[i].locale)} title = {array4[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'This is the default locale.' : 'Click to set it as the default locale.'} key = {i.toString()} className = {array2[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-secondary btn-xs br0 mr0'}>{array2[i].name}</button>)
                items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array4[i].locale === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].short}</button>)
            } else items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = {array.length === 1 ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array[i]}</button>)
            items.push (<button disabled = {!isUserAdmin()} style = {{marginLeft: '-1px'}} key = {i.toString() + '_'} onClick = {() => del(id, i)} className = "btn btn-danger btn-xs br0"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
        }
*/
    }

    return items
}
// Main.Instance.DatabaseDataState.custom.general.descriptions.settings

/*
function handleCheckbox (id: number, format: Assets_.pdf|'jpg'|'png'|'gif') {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 0) custom.KYC.identification.documentsFormat.indexOf(format) === -1? custom.KYC.identification.documentsFormat.push(format) : custom.KYC.identification.documentsFormat.splice(custom.KYC.identification.documentsFormat.indexOf(format), 1)
    else if (id === 1) custom.KYC.proofOfAddress.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfAddress.documentsFormat.push(format) : custom.KYC.proofOfAddress.documentsFormat.splice(custom.KYC.proofOfAddress.documentsFormat.indexOf(format), 1)
    else if (id === 2) custom.KYC.proofOfPayment.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfPayment.documentsFormat.push(format) : custom.KYC.proofOfPayment.documentsFormat.splice(custom.KYC.proofOfPayment.documentsFormat.indexOf(format), 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function Switches (item: boolean, id: number, text: string): JSX.Element {
    return (
        <div className = "clearfix mb">
            <Switch
                className = "dbl fl mr"
                onClick = {() => handleSwitch(item, id)}
                on = {item}
            />
            <span className = "fl dbl mt3">{text}</span>
        </div>
    )
}

function DocumentsFormat (array: (Assets_.pdf|'jpg'|'png'|'gif')[], id: number, text: string): JSX.Element {
    return (
        <div className = "clearfix">
            <h5>{text}:</h5>
            <button 
                className = {array.indexOf(Assets_.pdf) === -1? 'btn btn-xs btn-default fl': 'btn btn-xs btn-success fl'}
                name = "pdf"
                onClick = {() => handleCheckbox(id, Assets_.pdf)}
            >
            pdf
            </button>
            <button 
                className = {array.indexOf('jpg') === -1? 'btn btn-xs btn-default fl': 'btn btn-xs btn-success fl'}
                name = "jpg"
                onClick = {() => handleCheckbox(id, 'jpg')}
            >
            jpg
            </button>
            <button 
                className = {array.indexOf('png') === -1? 'btn btn-xs btn-default': 'btn btn-xs btn-success'}
                name = "png"
                onClick = {() => handleCheckbox(id, 'png')}
            >
            png
            </button>
            <button 
                className = {array.indexOf('gif') === -1? 'btn btn-xs btn-default': 'btn btn-xs btn-success'}
                name = "gif"
                onClick = {() => handleCheckbox(id, 'gif')}
            >
            gif
            </button>

        </div>
    )
}

function HandleSwitch (value: boolean, index: number, id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 5) custom.responsibleGaming.dashboardBackend.checkNightJob = !value
    else if (id === 7) custom.riskManagement.showOnlyElevatedRisk = !value
    else if (id === 12) custom.responsibleGaming.issues.active = !value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function handleSwitch (value: boolean, id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)

    if (id === 0) custom.KYC.identification.nationalIdCard = !value
    else if (id === 1) custom.KYC.identification.passport = !value
    else if (id === 2) custom.KYC.identification.drivingLicense = !value
    else if (id === 3) custom.KYC.proofOfAddress.utilityBill = !value
    else if (id === 4) custom.KYC.proofOfAddress.bankStatement = !value
    else if (id === 5) custom.KYC.proofOfAddress.localAuthorityTaxBill = !value
    else if (id === 6) custom.KYC.proofOfPayment.bankAccount = !value
    else if (id === 7) custom.KYC.proofOfPayment.creditCard = !value
    else if (id === 8) custom.KYC.proofOfPayment.eWallet = !value

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/


export function ToggleSwitch(i: number, j: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    const what: string = j === 1 ? 'isEmailVerified' : j === 2 ? 'isDocumentVerified' : j === 3 ? 'isUtilityBillVerified' : j === 4 ? 'isSuperVerified' :
        j === 5 ? 'sentUser' : j === 6 ? 'viewUser' : j === 7 ? 'modifyUser' : j === 8 ? 'viewAdmin' : j === 9 ? 'modifyAdmin' : ''

    if (j < 5) {
        if (custom.general.players.accountStatus[i].requirements && custom.general.players.accountStatus[i].requirements[what]) {
            delete (custom.general.players.accountStatus[i].requirements[what])
        } else {
            if (custom.general.players.accountStatus[i].requirements === undefined) {
                custom.general.players.accountStatus[i].requirements = {}
            }
            custom.general.players.accountStatus[i].requirements[what] = true
        }
    } else {
        if (custom.general.players.playerCard[i].flags && custom.general.players.playerCard[i].flags[what]) {
            delete (custom.general.players.playerCard[i].flags[what])
        } else {
            if (custom.general.players.playerCard[i].flags === undefined) {
                custom.general.players.playerCard[i].flags = {}
            }
            custom.general.players.playerCard[i].flags[what] = true
        }
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}
/*
function getCategories(): JSX.Element[] {
    const categories: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.categories)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        const cItems: JSX.Element[] = []
        if (Main.Instance.DatabaseDataState.categories[i].data.subs) {
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.categories[i].data.subs.length; j++) {
                cItems.push(
                    <button key = {i.toString() + '_' + j.toString()} title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? Assets.Active : Assets.Inactive : 'Category is inactive (therefore subcategory disabled)'} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active} onClick = {() => toggleSubcategory(i, j)} className = {Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? 'btn btn-info btn-xs' : 'btn btn-default btn-xs'}>
                        {i+1}.{j+1}.
                    </button>
                )
                cItems.push(
                    <input title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Main.Instance.DatabaseDataState.categories[i].data.subs[j].active ? Assets.Active : Assets.Inactive : 'Category is inactive (therefore subcategory disabled)'} key = {i.toString() + '__' + j.toString()} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.subs[j].active} style = {{height: '22px', width: 'auto', marginRight: '10px'}} type = "text" onChange = {(e) => updateName(i, e.target.value, j)} value = {Main.Instance.DatabaseDataState.categories[i].data.subs[j].name}/>
                )
            }
        }
        categories.push (
            <div key = {i.toString()} className = "mt">
                <button title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Assets.Active : Assets.Inactive} onClick = {() => toggleCategory(i)} className = {Main.Instance.DatabaseDataState.categories[i].data.active ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>
                    {i+1}.
                </button>
                <input title = {Main.Instance.DatabaseDataState.categories[i].data.active ? Assets.Active : Assets.Inactive} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active} style = {{height: '22px', width: 'auto', marginRight: '10px'}} type = "text" onChange = {(e) => updateName(i, e.target.value)} value = {Main.Instance.DatabaseDataState.categories[i].data.name}/>
                
                <button disabled = {Main.Instance.DatabaseDataState.category_ === undefined || Main.Instance.DatabaseDataState.category_[i] === undefined} onClick = {() => addSubCategory(i)} className = "btn btn-danger btn-xs">
                    {Assets.Add + ' a new subcategory'}
                </button>
                <input title = {Assets.Add + ' a new subcategory'} disabled = {!Main.Instance.DatabaseDataState.categories[i].data.active} style = {{height: '22px', width: 'auto', marginRight: '10px'}} type = "text" onChange = {(e) => UpdateName(i, e.target.value)} value = {Main.Instance.DatabaseDataState.category_[i] ? Main.Instance.DatabaseDataState.category_[i] : ''}/>
                
                <button onClick = {() => DbRoutes.wsBasicUpdate(Config.amCategories, Main.Instance.DatabaseDataState.categories[i].id, Main.Instance.DatabaseDataState.categories[i].data)} disabled = {JSON.stringify(Main.Instance.DatabaseDataState.categories[i]) === Main.Instance.DatabaseDataState.categories_[i]} className = "btn btn-warning btn-xs mr0">
                    {Assets.Update}
                </button>
            </div>
        )
        if (cItems.length > 0) {
            categories.push (
                <div key = {i.toString() + '_'} className = "mt">            
                    {cItems}
                </div>
            )
        } else {
            categories.push (
                <div key = {i.toString() + '_'} className = "mt">            
                    Subcategories are not yet defined for category {Main.Instance.DatabaseDataState.categories[i].data.name}.
                </div>
            )
        }

        categories.push (
            <hr key = {i.toString() + '__'} style = {{marginTop: '10px', marginBottom: '2px', borderTop: '1px solid #bbb'}}/>
        )
    }

    categories.push(
        <div key = "-1" className = "mt">
            <button disabled = {Main.Instance.DatabaseDataState.category === undefined} title = "Add a new category" onClick = {() => addCategory()} className = "btn btn-danger btn-xs">
                Add a new category
            </button>
            <input title = "Add a new category" style = {{width: '200px', height: '21px'}} type = "text" value = {Main.Instance.DatabaseDataState.category ? Main.Instance.DatabaseDataState.category : ''} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {category: e.target.value})}/>
        </div>
    )
    return categories
}

function updateName(i: number, value: string, j?: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: Category2[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        if (j === undefined) {
            categories[i].data.name = value
        } else categories[i].data.subs[j].name = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined})
    }
}


function UpdateName(i: number, value: string) {
    if (Main.Instance.DatabaseDataState.categories) {
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[i] = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
    }
}
*/
/*
function updateAllCategoriesDisabled(): boolean {
    let disabled: boolean = true
    if (Main.Instance.DatabaseDataState.categories)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if (JSON.stringify(Main.Instance.DatabaseDataState.categories[i]) !== Main.Instance.DatabaseDataState.categories_[i]) {
            disabled = false
            break
        }
    }
    return disabled    
}

function updateAllCategories() {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if ()
        DbRoutes.wsBasicUpdate(Config.amCategories, Main.Instance.DatabaseDataState.categories[i].id, Main.Instance.DatabaseDataState.categories[i].data)
    }
}
*/
/*
function addCategory() {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: Category2[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        let greatestID: number = 0
        for (const item of categories) {
            if (item.id > greatestID) {
                greatestID = item.id
            }
        }
        greatestID++

        categories.push({id: greatestID, data: {name: Main.Instance.DatabaseDataState.category, active: true}})
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined})
    }
}

function addSubCategory(category: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: Category2[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        
        let greatestID: number = 0
        if (categories[category].data.subs) {
            for (const item of categories[category].data.subs) {
                if (item.id > greatestID) {
                    greatestID = item.id
                }
            }
        } else {
            categories[category].data.subs = []
        }
        greatestID++

        categories[category].data.subs.push({id: greatestID, name: Main.Instance.DatabaseDataState.category_[category], active: true})
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[category] = undefined
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined, category_})
    }
}

function toggleCategory(i: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.active = !categories[i].data.active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}

function toggleSubcategory(i: number, j: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.subs[j].active = !categories[i].data.subs[j].active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}
*/