import {Main} from '../../../Logic/Main'
// import * as Assets_ from '../../Assets_'

export function toggleTimePeriod(value: string) {
    let newValue: string = ''
    let position: number = -1
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.timePeriods) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.general.timePeriods.length; i++) {
        if (value === Main.Instance.FrontendStoreState.custom.general.timePeriods[i]) {
            position = i
            break
        }
    }
    }

    if (position === Main.Instance.FrontendStoreState.custom.general.timePeriods.length - 1) {
        newValue = 'N/A'
    } else if (position === -1) {
        newValue = Main.Instance.FrontendStoreState.custom.general.timePeriods[0]
    } else { newValue = Main.Instance.FrontendStoreState.custom.general.timePeriods[position + 1] }
    const NewValue: 'daily'|'weekly'|'monthly' = newValue as 'daily'|'weekly'|'monthly'

    return NewValue

}

export function toggleTimeUnit(unit: string) {
    let newUnit: string = ''
    let position: number = -1
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.timeUnits) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.general.timeUnits.length; i++) {
        if (unit === Main.Instance.FrontendStoreState.custom.general.timeUnits[i]) {
            position = i
            break
        }
    }
    }

    if (position === Main.Instance.FrontendStoreState.custom.general.timeUnits.length - 1) {
        newUnit = 'N/A'
    } else if (position === - 1) {
        newUnit = Main.Instance.FrontendStoreState.custom.general.timeUnits[0]
    } else { newUnit = Main.Instance.FrontendStoreState.custom.general.timeUnits[position + 1] }
    const NewUnitMHDM: 'min'|'hour'|'day'|'month' = newUnit as 'min'|'hour'|'day'|'month'
    const NewUnitMH: 'min'|'hour' = newUnit as 'min'|'hour'
    
    return {NewUnitMH, NewUnitMHDM}
}

export function toggleCurrency(value: string) {
    let newValue: string = ''
    let position: number = -1
    if (Main.Instance.FrontendStoreState.custom && Main.Instance.FrontendStoreState.custom.general && Main.Instance.FrontendStoreState.custom.general.currencies) {
    for (let i: number = 0; i < Main.Instance.FrontendStoreState.custom.general.currencies.length; i++) {
        if (value === Main.Instance.FrontendStoreState.custom.general.currencies[i]) {
            position = i
            break
        }
    }
    }

    if (position === Main.Instance.FrontendStoreState.custom.general.currencies.length - 1) {
        newValue = 'N/A'
    } else if (position === -1) {
        newValue = Main.Instance.FrontendStoreState.custom.general.currencies[0]
    } else { newValue = Main.Instance.FrontendStoreState.custom.general.currencies[position + 1] }
    const NewValue: 'N/A'|'CHF'|'EUR'|'USD' = newValue as 'N/A'|'CHF'|'EUR'|'USD'

    return NewValue
}