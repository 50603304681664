import * as React from 'react'
// import * as ReactDOM from 'react-dom'
import { View } from '../View'
import {/*isLocalhost, */isProduction, gotoTop, isUserSystem, isUserMaster, isUserContractualPartner, isUserSubEntity, IsBuildOddyzzey, IsBuildJackpotsCH, IsBuildMyChance, IsBuildDefault, IsBuildPasino} from '../../Logic/Utils'
import { Logic } from '../../Logic/Type'
import { Main } from '../../Logic/Main'
import { RouterObjects } from '../../Logic/RouterObjects'
import '../../CSS/Navbar.css'
import * as Assets from '../Assets'
import * as DbRoutes from '../../Logic/Database/DbRoutes'
import * as SiteSettings from '../Main/FrontendStore/SiteSettings'
// import {getCalendarCount} from '../Main/PromoStore/Calendar'
import {getLevelsCount} from '../Main/PromoStore/Levels'
import {getPointsCount} from '../Main/PromoStore/Achievements'
import * as Config from '../../Logic/Config'
// import Socket from '../../Socket/socket-init'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAllergies, faCandyCane} from '@fortawesome/free-solid-svg-icons'
import {GetUnresolvedAll} from '../Main/RiskComplianceStore/RiskManagement'
import {UsersNew, TimelineEntrySubType, /*IssueTypes,*/ TransactionStatus/*, TransactionType, TimelineEntryType*/} from '../../Logic/Database/DatabaseData'
import * as Assets_ from '../Assets_'
const navChildMenuStyle = {display: 'block'}
import Socket1 from '../../Socket/socket-init'
// import Socket2 from '../../Socket/socket-init2'
import {clearFields as clearFieldsTournament} from '../Main/PromoStore/TournamentsOddyzzey'
import axios from 'axios'
import * as RouteClasses from '../../Socket/route-classes'
// import {setApprovedGames, AllGamesFilterGames} from '../Main/GameStore/AllGamesNew'
import {/*setApprovedGames,*/ setCentralGames} from '../Main/GameStore/GameList'
import {getUnresolvedPaginatedIssues} from '../Main/MainDashboard/Dashboard'
import {getPaginatedData} from '../Main/Reports/StorePurchases'
import {getFilteredData} from '../Main/Reports/UsersInfo'
import {getUserData} from '../Main/Reports/UserInfo'
import {getChancesData} from '../Main/Reports/TournamentLeaderboard'

// import {getStream} from '../../Logic/Database/getStream'

// const threshold: number = 1
// let arr1 = []
// let arr2 = []
// let arr3 = []
/*
export function onProgress(n: object) {
    // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1: n as never})

    arr1.push(n as never)
    // console.log (data)
    if (arr1.length >= threshold) {
        const withdrawalRequest1 = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
        withdrawalRequest1.push(...arr1)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1})
        arr1 = []
    }
}

export function onProgress2(n: object) {
    // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2: n as never})

    arr2.push(n as never)
    // console.log (data)
    if (arr2.length >= threshold) {
        const withdrawalRequest2 = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
        withdrawalRequest2.push(...arr2)
        Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
        arr2 = []
    }
}
*/
/*
function getDate(date: number): string {
    // console.log (date)
    if (date === undefined) {return ''}
    const d = new Date(date * 1000)
    return d.toISOString()
}
*/
/*
export function onProgress4(n: object) {
    arr4.push(n as never)
    // console.log (data)
    if (arr4.length >= threshold) {
        console.log (arr4)
        // const withdrawalRequest2 = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
        // withdrawalRequest2.push(...arr4)
        // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
        arr4 = []
    }
}

export function onEnd4() {

}

export function onError4() {

}
*/
/*
export function onProgress3(n: object) {
    // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2: n as never})
    // console.log ('onProgress3')
    arr3.push(n as never)
    // console.log (arr3)
    for (const item of arr3) {
        item.entrySubType =
            item.transactionType === TransactionType.DEPOSIT ? TimelineEntrySubType.Deposit :
            item.transactionType === TransactionType.WITHDRAW ? TimelineEntrySubType.Withdraw :
            item.transactionType === TransactionType.DEPOSIT_MANUAL ? TimelineEntrySubType.ManualDeposit :
            item.transactionType === TransactionType.WITHDRAW_MANUAL ? TimelineEntrySubType.ManualWithdraw :
            item.transactionType === TransactionType.WITHDRAW_CANCEL ? TimelineEntrySubType.CanceledWithdraw : 0

        item.created = getDate(item.created ? +item.created : undefined)
        item.completed = getDate(item.completed ? +item.completed : undefined)
        item.entryType = TimelineEntryType.Transaction
        item.transactiontype = TransactionType[item.transactionType]
        item.transactionstatus = TransactionStatus[item.transactionStatus]
        item.transactiontype_ = item.transactionType === TransactionType.DEPOSIT || item.transactionType === TransactionType.DEPOSIT_MANUAL ? TransactionType[TransactionType.DEPOSIT] : TransactionType[TransactionType.WITHDRAW]
    }
    // console.log (arr3)
    if (arr3.length >= threshold) {
        const playerTimeline = Object.assign([], Main.Instance.DatabaseDataState.playerTimeline)
        playerTimeline.push(...arr3)

        const playerTimelineTransactions = Object.assign([], Main.Instance.DatabaseDataState.playerTimelineTransactions)
        playerTimelineTransactions.push(...arr3)

        Logic.Type.New (Main.Instance.DatabaseDataState, {playerTimeline, playerTimelineTransactions})
        arr3 = []
    }
}
*/
/*
export function onEnd() {
    // console.log ('onEnd')
    // console.log (arr)
    // console.log ('1')
    // console.log (arr)

    // const withdrawalRequest1: any[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest1)
    // withdrawalRequest1.push(...arr1)
    // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest1})
    // arr1 = []
}
*/
/*
export function onEnd2() {
    // console.log ('onEnd2')
    // console.log (Arr)
    // const withdrawalRequest2: any[] = Object.assign([], Main.Instance.DatabaseDataState.withdrawalRequest2)
    // withdrawalRequest2.push(...arr2)
    // Logic.Type.New (Main.Instance.DatabaseDataState, {withdrawalRequest2})
    // arr2 = []

}

export function onError() {
}

export function onError2() {
}
*/
export function onError3() {
    // console.log ('onError3')
}

export function onEnd3() {
    // console.log ('onEnd3')
    // console.log (Main.Instance.DatabaseDataState.playerTimeline)
}

async function ManageClick(path: string, key: any, childkey?: any) {
    
    // alert ('click')
    // window.scrollTo(0, -1000)
    // console.log (path, key, childkey)
    gotoTop()
   
/*
    const el: HTMLCollectionOf<Element> = document.getElementsByClassName('main_container')
    if (el) {
        console.log (el)
        for (let i: number = 0; i < el.length; i++) el[i].scrollTo(0,0)
    }
*/
    let Path: string = key && childkey ? key + ' -> ' + childkey : key
    Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')

    DbRoutes.insertAdminLog (
        Config.asAdminLogView,
        Path,
        {},
    )

    if ((IsBuildOddyzzey() || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildMyChance()) && Socket1 /* && (!IsBuildMyChance() || Socket2) */) {

        if ((key === 'game_Store' || key === 'front___End_Store') && (IsBuildDefault() || IsBuildJackpotsCH() || IsBuildPasino())) {
            // console.log (childkey)
            if (!childkey || childkey ==='Gamingenius_Game_List' || childkey === 'Approved_Games_Store') {
                // console.log('SideBar')
                if (Main.Instance.DatabaseDataState.gamesList === undefined) {
                    // console.log('SideBar2')
                    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList: null})
                    DbRoutes.wsJackpotsReinitializeGames()
                }
/*
                if (childkey === 'Approved_Games_Store') {
                    setApprovedGames()
                }
*/
                if (childkey ==='Gamingenius_Game_List') {
                    setCentralGames()
                }

            }
        } else if (key === 'gamification') {
            // console.log ('childkey ' + childkey)
            if (childkey === 'Levels' /*|| childkey === 'Change_Player_Level'*/) {
                DbRoutes.WsGetLevels()
            } else if (childkey === 'Missions'||childkey === 'Chances') {
                DbRoutes.WsGetMissions()
            } else if (childkey === 'Tournaments'||childkey === 'Races') {
                if (IsBuildMyChance()) {
                    if (childkey === 'Tournaments') {
                        Logic.Type.New(Main.Instance.TournamentState, {tournamentType: '2'})
                        clearFieldsTournament()
                    } else if (childkey === 'Races') {
                        Logic.Type.New(Main.Instance.TournamentState, {tournamentType: '3'})
                        clearFieldsTournament()
                    }
                }
                DbRoutes.WsGetTournaments()
                DbRoutes.WsGetPlayerIds()
            } else if (childkey === 'Challenges') {
                DbRoutes.WsGetChallenges()
            } else if (childkey === 'Rewards' || childkey === undefined) {
                DbRoutes.WsGetRewards()
            } else if (childkey === 'Store' || childkey === 'Shop') {
                DbRoutes.WsGetStore()
            } 
        } else if (key === 'account_Store') {
            if (!childkey || childkey === 'Player_Card' || childkey === 'Playercard') {
                if (Socket1 && Main.Instance.DatabaseDataState.playerCards === undefined) {
                    Socket1.socket.Send (Config.amGetPlayerCards, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetPlayerCards)
                }
            } else if (childkey === 'Withdrawal_Requests' || childkey === 'Withdrawal_Duplicates') {
                if (Main.Instance.DatabaseDataState.paymentProcessed === undefined) {
                    // console.log ('dbroutes')
                    // console.log (new Date())
                    Logic.Type.New(Main.Instance.DatabaseDataState, { paymentProcessed: null, PaymentProcessedGet: true })
                    // Socket1.socket.Send (Config.amBasicGet, {table: Config.amProcessedPayments})
                    // DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amProcessedPayments)
                    Socket1.socket.Send(Config.amGetProcessedPayments, {page: 1, limit: 10, table: "processed_payments"})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetProcessedPayments)
                    // if (childkey === 'Withdrawal_Requests') {
                    if (!Main.Instance.DatabaseDataState.withdrawalRequest1) {
                        Logic.Type.New(Main.Instance.DatabaseDataState, {withdrawalRequest1: []})
                    }

                    if (Main.Instance.DatabaseDataState.WithdrawalRequest1 === false) {
                        Socket1.socket.Send(Config.walletGameWithdrawGet, {
                            transactionStatus: TransactionStatus.PENDING,
                            page: 1,
                            pageSize: isProduction() ? 50 : 10
                            // pageSize: isProduction() ? undefined : 100
                        })
                        Socket1.socket.Get<RouteClasses.Route>(Config.walletGameWithdrawGet).loading = 'init'

                        Logic.Type.New (Main.Instance.DatabaseDataState, {
                            WithdrawalRequest1Time: Math.floor((new Date()).getTime()/1000),
                            withdrawalRequest1: [],
                            WithdrawalRequest1: true,
                            withdrawalRequest1Count: 0
                        })
                        DbRoutes.getLoadedResources('Socket1 - Send', Config.walletGameWithdrawGet)
                    }
                }
                // }
            } else if (childkey === 'Risk_Alerts') {
                // console.log (1)
/*
                DbRoutes.getPaginatedIssues(
                    {
                        resolved: Main.Instance.RiskManagementState.filterResolved,
                        minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
                                    IssueTypes.RiskManagement :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any,
                        maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM === 999 ?
                                    IssueTypes.Other1:
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM as any
                    },
                    Main.Instance.DatabaseDataState.issues1Page,
                    Main.Instance.DatabaseDataState.issues1Limit,
                    TimelineEntrySubType.RiskManagement,
                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
                )
*/
                Logic.Type.New (Main.Instance.DatabaseDataState, {issues1Page: 1, issuesFilterIssueTypeRM: 999})
                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
                getUnresolvedPaginatedIssues(999, TimelineEntrySubType.RiskManagement, Main.Instance.DatabaseDataState.issues1Limit, 1)
            } else if (childkey === 'RG_Issues') {
                // console.log (2)
/*
                DbRoutes.getPaginatedIssues(
                    {
                        resolved: Main.Instance.RiskManagementState.filterResolved,
                        minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                    IssueTypes.ResponsibleGaming :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any,
                        maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG === 999 ?
                                    IssueTypes.Other2 :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG as any
                    },
                    Main.Instance.DatabaseDataState.issues2Page,
                    Main.Instance.DatabaseDataState.issues2Limit,
                    TimelineEntrySubType.ResponsibleGaming,
                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
                )
*/
                Logic.Type.New (Main.Instance.DatabaseDataState, {issues2Page: 1, issuesFilterIssueTypeRG: 999})
                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
                getUnresolvedPaginatedIssues(999, TimelineEntrySubType.ResponsibleGaming, Main.Instance.DatabaseDataState.issues2Limit, 1)

            } else if (childkey === 'KYC_Issues') {
                // console.log (3)
/*
                DbRoutes.getPaginatedIssues(
                    {
                        resolved: Main.Instance.RiskManagementState.filterResolved,
                        minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                    IssueTypes.KnowYourCustomer :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any,
                        maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC === 999 ?
                                    IssueTypes.Other3:
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC as any
                    },
                    Main.Instance.DatabaseDataState.issues3Page,
                    Main.Instance.DatabaseDataState.issues3Limit,
                    TimelineEntrySubType.KnowYourCustomer,
                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
                )
*/
                Logic.Type.New (Main.Instance.DatabaseDataState, {issues3Page: 1, issuesFilterIssueTypeKYC: 999})
                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
                getUnresolvedPaginatedIssues(999, TimelineEntrySubType.KnowYourCustomer, Main.Instance.DatabaseDataState.issues3Limit, 1)
                

            } else if (childkey === 'GM_Issues') {
                // console.log (4)
/*
                DbRoutes.getPaginatedIssues(
                    {
                        resolved: Main.Instance.RiskManagementState.filterResolved,
                        minIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                    IssueTypes.Gamification :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any,
                        maxIssueType: Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM === 999 ?
                                    IssueTypes.Other4 :
                                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM as any
                    },
                    Main.Instance.DatabaseDataState.issues4Page,
                    Main.Instance.DatabaseDataState.issues4Limit,
                    TimelineEntrySubType.Gamification,
                    Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
                )
*/
                Logic.Type.New (Main.Instance.DatabaseDataState, {issues4Page: 1, issuesFilterIssueTypeGM: 999})
                Logic.Type.New (Main.Instance.DashboardState, {selectedIssueSubCategoryUnresolved: -1})
                getUnresolvedPaginatedIssues(999, TimelineEntrySubType.Gamification, Main.Instance.DatabaseDataState.issues4Limit, 1)

            }
        } else if (key === 'cRM') {
            if (!childkey || childkey === 'Player_Groups') {
                // console.log ('data store player groups')
    /*
                if (Socket && Main.Instance.DatabaseDataState.playersInfo === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {PlayerInfoGet: true})
                    Socket1.socket.Send (Config.amGetPlayersInfo, {})
                }
    */
            }
        } else if (key === 'gamification' && (IsBuildJackpotsCH() || IsBuildPasino())) {
            if (!childkey || childkey === 'Raffles') {
                if (Socket1 && Main.Instance.DatabaseDataState.raffle === undefined) {
                    Socket1.socket.Send(Config.amBasicGet, {table: Config.amRaffle})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amRaffle)
                }
            }
        } else if (key === 'settings') {
            if (!childkey || childkey === 'Generic_Settings') {
                if (Socket1 && Main.Instance.DatabaseDataState.Languages === undefined) {
                    Socket1.socket.Send(Config.amBasicGet, {table: Config.amLanguages})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amLanguages)
                }
            } else if (childkey === 'Player_Settings') {
                if (Socket1 && Main.Instance.DatabaseDataState.countries === undefined) {
                    Socket1.socket.Send(Config.amBasicGet, {table: Config.amCountries})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amCountries)
                }
            } else if (IsBuildJackpotsCH() && childkey === 'Games_Settings') {
                if (Socket1 && Main.Instance.DatabaseDataState.gamesListCategories === undefined) {
                    Socket1.socket.Send(Config.gGetGameCategories, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameCategories)
                }
                if (Socket1 && Main.Instance.DatabaseDataState.gamesListLabels === undefined) {
                    Socket1.socket.Send(Config.gGetGameLabels, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameLabels)
                }
                /*
                if (Socket1 && Main.Instance.DatabaseDataState.jurisdictionsList === undefined) {
                    Socket1.socket.Send(Config.gGetJurisdictionsList, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetJurisdictionsList)
                }
                */
                if (Socket1 && Main.Instance.DatabaseDataState.devices === undefined) {
                    Socket1.socket.Send(Config.gGetDevices, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetDevices)
                } 
            } else if (childkey === 'Bonus_Settings') {
                if (Socket1 && !Main.Instance.DatabaseDataState.globallyExcludedTags) {
                    Logic.Type.New(Main.Instance.DatabaseDataState, {globallyExcludedTagsGet: true})
                    Socket1.socket.Send(Config.bonusGloballyExcludedTagsGet, {eventId: 'event_id'})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusGloballyExcludedTagsGet)
                }
            }
        } else if (key === 'reports') {

            if (childkey === 'Bonus_Campaign_Reports') {
                // if (Socket1 && Main.Instance.DatabaseDataState.bonus === undefined) {
                //     Socket1.socket.Send(Config.amBasicGet, {table: Config.amBonus})
                //     DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amBonus)
                // }
                if (Main.Instance.DatabaseDataState.bonusNew === undefined) {
                    Socket1.socket.Send(Config.bonusBonusGet, {eventId: 'event_id', page: 1, limit: 1000})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusBonusGet)
                }
                if (Main.Instance.DatabaseDataState.bonusCampaignNew === undefined) {
                    Socket1.socket.Send(Config.bonusBonusCampaignGet, {eventId: 'event_id'})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusBonusCampaignGet)
                }

            } else if (childkey === 'Players_Registrations' || childkey === 'Players') {
                if (Main.Instance.DatabaseDataState.playersInfo === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {playersInfo: null})
                    Socket1.socket.Send(Config.amGetPlayersInfo, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetPlayersInfo)
                }
            } else if (childkey === 'GGR_Revenue_Report') {
                if (Main.Instance.DatabaseDataState.ggrReports === undefined || Main.Instance.DatabaseDataState.ggrReportsTotal === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        ggrReports: null,
                        ggrReportsTotal: null
                    })
                    
                    Socket1.socket.Send(Config.amGetReports, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetReports)
                }
            } else if (childkey === 'Game_reports___Real') {
                
                if (Main.Instance.DatabaseDataState.gameReportsReal === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        gameReportsReal: null,
                    })
                } else {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        gameReportsTest: null,
                        playerReports: null,
                    })
                }

                if (Main.Instance.DatabaseDataState.gamesList === undefined) {
                    // console.log('SideBar2')
                    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList: null})
                    DbRoutes.wsJackpotsReinitializeGames()
                }

            } else if (childkey === 'Game_reports___Test') {
                
                if (Main.Instance.DatabaseDataState.gameReportsTest === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        gameReportsTest: null,
                    })
                } else {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        gameReportsReal: null,
                        playerReports: null,
                    })
                }

                if (Main.Instance.DatabaseDataState.gamesList === undefined) {
                    // console.log('SideBar2')
                    Logic.Type.New (Main.Instance.DatabaseDataState, {gamesList: null})
                    DbRoutes.wsJackpotsReinitializeGames()
                }
                
            } else if (childkey === 'Player_reports') {
                
                if (Main.Instance.DatabaseDataState.playerReports === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        playerReports: null,
                    })
                } else {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        gameReportsReal: null,
                        gameReportsTest: null
                    })
                }
            } else if (childkey === 'Store_Purchases') {
                
                if (Main.Instance.DatabaseDataState.storePurchases === undefined) {
                    Logic.Type.New (Main.Instance.DatabaseDataState, {
                        storePurchases: null,
                    })
                    Socket1.socket.Send(Config.amGetStorePurchases, {limit: 10, page: 1})
                } 
                getPaginatedData(1, 10, Main.Instance.SearchState.search_string)
                
            } else if (childkey === 'Cashback_Monday_Optins' || childkey === 'Users_Info') {
                DbRoutes.WsGetLevels()
                if (childkey === 'Users_Info') {
                    getFilteredData()
                }
                
            } else if (childkey === 'Chances_Leaderboard') {
                if (Main.Instance.DatabaseDataState.missionLeaderboard === undefined) {

                    Logic.Type.New(Main.Instance.DatabaseDataState, {missionLeaderboard: null})
                    Socket1.socket.Send(Config.amGetMissionLeaderboard, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.amGetMissionLeaderboard)
                }
                getChancesData()
            } else if (childkey === 'User_Info') {
                getUserData()
            }
        
        } else if (key === 'user_Management') {
            if (childkey === 'Log') {
                if (Main.Instance.DatabaseDataState.usersNew === undefined) {
                    const usersNew: UsersNew[] = []
                    axios.get(Config.asNewUser + (parseInt(Main.Instance.LoginState.entityId) === 0 ? '' : '?entityId=' + Main.Instance.LoginState.entityId), { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
                    .then((response1) => {
                        for (const item of response1.data) {
                            usersNew.push(item)
                        }
                        // console.log(JSON.stringify(usersNew))
                        Logic.Type.New(Main.Instance.DatabaseDataState, {usersNew})
                    })
                }
                DbRoutes.getAdminLog()
                /*
                Socket1.socket.Send (Config.amBasicGet, {table: Config.amBonus})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.amBasicGet, Config.amBonus)
                */
                Socket1.socket.Send(Config.bonusBonusGet, {eventId: 'event_id', page: 1, limit: 1000})
                DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusBonusGet)
                /*
                if (IsBuildJackpotsCH() && Main.Instance.DatabaseDataState.jurisdictionsList === undefined) {
  
                    Logic.Type.New(Main.Instance.DatabaseDataState, {jurisdictionsList: null})
                    Socket1.socket.Send(Config.gGetJurisdictionsList, {})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetJurisdictionsList)
                }
                */
                
            }
        } else if ((IsBuildPasino() || IsBuildJackpotsCH()) && key === 'bonus_Section') {

            if (childkey === 'Bonus_Offers' || childkey === 'Bonus_Campaign' || childkey === 'Free_Games') {
                
                if (Socket1 && Main.Instance.DatabaseDataState.bonusNew === undefined) {
                    Socket1.socket.Send(Config.bonusBonusGet, {eventId: 'event_id', page: 1, limit: 1000})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusBonusGet)
                    Logic.Type.New(Main.Instance.DatabaseDataState, { bonusNew: null })   
                }
                if (Socket1 && Main.Instance.DatabaseDataState.bonusCampaignNew === undefined) {
                    Socket1.socket.Send(Config.bonusBonusCampaignGet, {eventId: 'event_id'})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusBonusCampaignGet)
                    Logic.Type.New(Main.Instance.DatabaseDataState, { bonusCampaignNew: null })   
                }
                if (Main.Instance.DatabaseDataState.gamesList === undefined) {
                    Logic.Type.New(Main.Instance.DatabaseDataState, { gamesList: null }) 
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameList)   
                    Socket1.socket.Send(Config.gGetGameList, {})
                }

                if (Main.Instance.DatabaseDataState.freeGameSettings === undefined) {
                    Socket1.socket.Send(Config.bonusFreeGamesGet, {eventId: 'event_id'})
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.bonusFreeGamesGet)
                    Logic.Type.New(Main.Instance.DatabaseDataState, { freeGameSettings: null }) 
                }
                if (Main.Instance.DatabaseDataState.gameProviderNew === undefined) {
                    Logic.Type.New(Main.Instance.DatabaseDataState, { gameProviderNew: null })
                    DbRoutes.getLoadedResources('Socket1 - Send', Config.gGetGameProviders)   
                    Socket1.socket.Send(Config.gGetGameProviders, {})
                }
            }
        }
    } else if (key === 'game_Store') {
        // console.log (childkey)
        // console.log (RouterObjects['game_Store'].children['Gamingenius_Game_List'])
        // console.log (RouterObjects['game_Store'].children['Approved_Games_Store'])
        if (!childkey || childkey === 'The_Store') {
        } else if (childkey === 'Gamingenius_Game_List' || childkey === 'Approved_Games_Store') {
            Logic.Type.New (Main.Instance.GamesCentralState, {selectedGameId: undefined})
        } 

    } else if (key === 'front___End_Store') {

        if (childkey === 'Shop_Settings' || childkey === 'Site_Settings') {
            SiteSettings.ApplySiteSelect(Main.Instance.FrontendStoreState.site_id)
        }
/*
    } else if  (key === 'account_Store' && !childkey) {

        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: true,
            gamesPaths: [],
            countCalled: false,
            gamesArray: []
        })  

        await Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:[],
            contractualPartnersAllStream2:[],
            sitesOperatorAllStream:[],
            levelsAllStream:[]})

        await DbRoutes.getCurrencyNames()

        if (isUserSystem()) {
            DbRoutes.getMastersAll()
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)

            DbRoutes.getAllOperators(Assets.GG_RM)
            DbRoutes.getAllBrandOwners(Assets.GG_RM)
        }
*/
    }

    if (key === 'user_Management') { 
        if ((!childkey || childkey === 'Manage_Sub_Entities')) { 
            if (isUserSystem() || isUserMaster()) {
                await Logic.Type.New (Main.Instance.RegisterSubEntityState, {type_id: '1', select_type_id: '0'})
            }

            if (isUserSystem()) {
                await Logic.Type.New (Main.Instance.ManageState, {selectionUsers: 1, selectionUsersInitial: 0})
            } else if (isUserMaster()) {
                await Logic.Type.New (Main.Instance.ManageState, {selectionUsers: 2, selectionUsersInitial: 0})
            } else if (isUserContractualPartner()) {
                await Logic.Type.New (Main.Instance.ManageState, {selectionUsers: 1, selectionUsersInitial: 0})
            } else if (isUserSubEntity()) {
                await Logic.Type.New (Main.Instance.ManageState, {selectionUsers: 1, selectionUsersInitial: 0})
            }
        }
    } else if (key === 'gamification') {
/*
        if (childkey === 'Loyalty_Free_Rounds__Bets') {
            if (isUserSystem()) {
                await Logic.Type.New (Main.Instance.DatabaseDataState, {gamesAllStream: undefined})
                
                await Logic.Type.New(Main.Instance.DatabaseDataState, { contractualPartnersAllStream: [],
                                                                        contractualPartnersAllStream2: [],
                                                                        sitesOperatorAllStream:[]})

                await DbRoutes.getMastersAll()

                await DbRoutes.getAllOperators()
                await DbRoutes.getAllBrandOwners() 

                await DbRoutes.getExtendedSitesAll()
            }
        } else if (childkey === Assets.Calendar) {
            getCalendarCount()
 
        } else */ 
        if (childkey === 'Reward_Shop') {
            DbRoutes.loadData()
        } else if (childkey === 'Levels_Management') {
            if (isUserSystem()) {
                await Logic.Type.New(Main.Instance.DatabaseDataState, { contractualPartnersAllStream: [],
                                                                        contractualPartnersAllStream2: [],
                                                                        sitesOperatorAllStream: [],
                                                                        levelsAllStream: []})
                await DbRoutes.getMastersAll()
                await DbRoutes.getExtendedSitesAll()

                await DbRoutes.getAllOperators()
                await DbRoutes.getAllBrandOwners()

                await DbRoutes.getLevelsAll(true)
            } else {
                getLevelsCount()  
            }
        } else if (childkey === 'Currencies_Management') {
            await DbRoutes.getCurrencyRates()
            await DbRoutes.GetCurrencyAll()
            await DbRoutes.getCurrencyNames()
            await DbRoutes.GetFlags()
        } else if (childkey === 'Points_Management') {
            await DbRoutes.GetCurrencyAll()
            if (isUserSystem()) {
                await Logic.Type.New (Main.Instance.DatabaseDataState, {gamesAllStream: undefined})

                await Logic.Type.New(Main.Instance.DatabaseDataState, { contractualPartnersAllStream: [],
                                                                        contractualPartnersAllStream2: [],
                                                                        sitesOperatorAllStream:[],
                                                                        achievementsAllStream:[],
                                                                        levelsAllStream:[]})

                await DbRoutes.getExtendedSitesAll()

                await DbRoutes.getMastersAll()

                await DbRoutes.getAllOperators()
                await DbRoutes.getAllBrandOwners()
            } else {
                getPointsCount()
            }
/*            
        } else if (childkey === 'Player_Reward_Groups') {
            
            if (isUserSystem()) {
                await Logic.Type.New(Main.Instance.DatabaseDataState, { contractualPartnersAllStream: [],
                                                                        contractualPartnersAllStream2: [],
                                                                        sitesOperatorAllStream: [],
                                                                        gamesAllStream: undefined,
                                                                        achievementsAllStream: undefined,
                                                                        levelsAllStream: undefined,
                                                                        playerGroupsAllStream: undefined})

                await Logic.Type.New(Main.Instance.CRMPlayerGroupsRewardsState, {date_from: utilsToday()})

                await DbRoutes.getCurrencyNames()
                await DbRoutes.getMastersAll()
                await DbRoutes.getAllOperators()
                await DbRoutes.getAllBrandOwners() 
                await DbRoutes.getExtendedSitesAll()
            }
*/
        } else if (!childkey) {  // if clicked on main
            await DbRoutes.GetCurrencyAll()
            if (isUserSystem()) {
                await Logic.Type.New(Main.Instance.DatabaseDataState, { contractualPartnersAllStream: [],
                                                                        contractualPartnersAllStream2: [],
                                                                        sitesOperatorAllStream: [],
                                                                        levelsAllStream: []})
                DbRoutes.getMastersAll()
                DbRoutes.getExtendedSitesAll()

                DbRoutes.getAllOperators()
                DbRoutes.getAllBrandOwners()

                DbRoutes.getLevelsAll(true)
            }
        }

    } else if (key === 'reports') { 
        if (!childkey) { // if clicked on main
            if (isUserSystem()) { 

                await Logic.Type.New(Main.Instance.DatabaseDataState, {
                    contractualPartnersAllStream: [],
                    contractualPartnersAllStream2: [],
                    sitesOperatorAllStream: [],
                    levelsAllStream: [],
                })
                await DbRoutes.getCurrencyNames()

                DbRoutes.getMastersAll()
                DbRoutes.getExtendedSitesAll()

                DbRoutes.getAllOperators()
                DbRoutes.getAllBrandOwners()

                DbRoutes.getLevelsAll(true)
            }
        } else {
            if (childkey === 'Players__Points') {
                Logic.Type.New (Main.Instance.ReportLSState, {data: undefined})
            } else if (childkey === 'Tournaments') {
                await DbRoutes.GetCurrencyAll()
            }
        }
    } else if (key === 'support') { 
        if (!childkey) { // if clicked on main
            if (isUserSystem()) { 

                await Logic.Type.New(Main.Instance.DatabaseDataState, {
                    contractualPartnersAllStream: [],
                    contractualPartnersAllStream2: [],
                    sitesOperatorAllStream: [],
                    levelsAllStream: [],
                })
                await DbRoutes.getCurrencyNames()

                DbRoutes.getMastersAll()
                DbRoutes.getExtendedSitesAll()

                DbRoutes.getAllOperators()
                DbRoutes.getAllBrandOwners()

                DbRoutes.getLevelsAll(true)
            }
        } else {
            if (childkey === 'Levels__Sublevels') {
            }
        }
    }

}

function onClickParent(key:any) {
    const path = RouterObjects[key].path
    Logic.Type.New(Main.Instance, { Location: path })
    if (Main.Instance.RiskManagementState.showAddCSCallbackModal) { Logic.Type.New(Main.Instance.RiskManagementState, {showAddCSCallbackModal: false}) }
    // if (Main.Instance.RiskManagementState.showAddRiskAlertsModal) Logic.Type.New(Main.Instance.RiskManagementState, {showAddRiskAlertsModal: false})
    // if (Main.Instance.RiskManagementState.showAddKYCModal) Logic.Type.New(Main.Instance.RiskManagementState, {showAddKYCModal: false})
    ManageClick (path, key)
}

function onClickChild(key:any, childkey:any) {
    const path = RouterObjects[key].path + RouterObjects[key].children[childkey].path
    Logic.Type.New(Main.Instance,{ Location: path})
    ManageClick (path, key, childkey)
}

function AllowedRoleChildren(key: string, childkey: string) : boolean {
    if (key === undefined || childkey === undefined) {return false}
    let proceed: boolean = false
    if (Main.Instance.LoginState) {
        if (RouterObjects[key].children[childkey].AllowedRoles2) {
            // console.log (RouterObjects[key].children[childkey].AllowedRoles2)
            for (const item of RouterObjects[key].children[childkey].AllowedRoles2) {
                // Main.Instance.LoginState.custom.securityRights[item]
                if (Main.Instance.LoginState[item]) {
                    proceed = true
                    break
                }
            }
            return proceed
        }
        for (let i: number = 0; i < RouterObjects[key].children[childkey].AllowedRoles.length; i++) {
            if (RouterObjects[key].children[childkey].AllowedRoles[i] === parseInt(Main.Instance.LoginState.role)) {
                proceed = true
                break;
            }
        }
    }
    return proceed
}

function AllowedRole(key: string) : boolean {
    if (key === undefined) {return false}
    let proceed:boolean = false
    if (Main.Instance.LoginState) {
/*
        if (key === 'reports' && isLocalhost()) {
            proceed = true
        }
*/ 
        if (RouterObjects[key].AllowedRoles2) {
            for (const item of RouterObjects[key].AllowedRoles2) {
                // Main.Instance.LoginState.custom.securityRights[item]
                
                if (Main.Instance.LoginState[item]) {
                    proceed = true
                    break
                }
            }
            return proceed
        }
        for (let i: number = 0; i < RouterObjects[key].AllowedRoles.length; i++) {
            if (RouterObjects[key].AllowedRoles[i] === parseInt(Main.Instance.LoginState.role)) {
                proceed = true
                break;
            }
        }
    }
    return proceed
}


function MenuItems() {
    // console.log (RouterObjects)
    const Array: JSX.Element[] = []
    const unresolvedAll: number[] = GetUnresolvedAll(TimelineEntrySubType.RiskManagement, TimelineEntrySubType.ResponsibleGaming, TimelineEntrySubType.KnowYourCustomer, TimelineEntrySubType.Gamification)
    Object.keys(RouterObjects).forEach((key) => {
        const correctTitle = key ? key.toString()[0].toLocaleUpperCase() + key.toString().slice(1, key.toString().length) : ''
        // console.log (correctTitle)
        if (key) {
            if (RouterObjects[key].AllowedRoles) {
                if (AllowedRole(key)) {
                    Array.push(
                        <div key = {key}>
                            <li 
                                title = {correctTitle.replace(/____/g, '.').replace(/___/g,'-').replace(/__/g,'/').replace(/_/g,' ')}
                                className = {Main.Instance.Location.split('/')[1] == RouterObjects[key].path.replace('/','') ? 'menu-item active' : 'menu-item inactive'}
                                onClick = {() => onClickParent(key)}
                            >
                                <FontAwesomeIcon style = {{width: '35px'}} icon = {RouterObjects[key].menuicon}/>
                                {/*<i className = {RouterObjects[key].menuicon ? RouterObjects[key].menuicon : Assets_.faUserPlus}/>*/}
                                {correctTitle.replace(/____/g, '.').replace(/___/g,'-').replace(/__/g,'/').replace(/_/g,' ')}
                                &nbsp;&nbsp;
                                {/*Object.keys(RouterObjects[key].children).length > 0 ?
                                <span 
                                    className = {Main.Instance.Location.split('/')[1] == 
                                                RouterObjects[key].path.replace('/','') ? 
                                                'gfont-caret-down' : 'gfont-caret-up'}
                                />
                                :
                                <span 
                                    className = {Main.Instance.Location ==
                                                RouterObjects[key].path ?
                                                'gfont-caret-right' : 'gfont-caret-up'}
                                />
                                */}
                            </li>
                            <div className = "clearfix"/>
                            {RouterObjects[key].children ? Main.Instance.Location.split('/')[1] == RouterObjects[key].path.replace('/','') ?
                            <div className = "nav child_menu" style = {navChildMenuStyle}>
                                {Object.keys(RouterObjects[key].children).map((childkey) => AllowedRoleChildren(key, childkey) ?
                                <li
                                    title = {
                                        key === 'account_Store' && childkey === 'RG_Issues' ? Assets.ResponsibleGaming + ' ' + Assets.Issues :
                                        key === 'account_Store' && childkey === 'KYC_Issues' ? Assets.KnowYourCustomer + ' ' + Assets.Issues :
                                        key === 'account_Store' && childkey === 'GM_Issues' ? Assets.Gamification + ' ' + Assets.Issues :
                                        childkey.replace(/____/g, '.').replace(/___/g,'-').replace(/__/g,'/').replace(/_/g,' ')
                                    }
                                    style = {{paddingLeft: '25px'}}
                                    key = {childkey}
                                    className = {Main.Instance.Location.split('/')[2] == RouterObjects[key].children[childkey].path.replace('/','') ? 'current-page' : ''}
                                    onClick = {()=> onClickChild(key, childkey)}
                                >
                                    {RouterObjects[key].children[childkey].menuicon === faAllergies ?
                                    <span style = {{color: 'inherit', position: 'relative', marginLeft: '5px', marginRight: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Assets_.strapi(Main.Instance.Location.split('/')[2] == RouterObjects[key].children[childkey].path.replace('/','') ? 'rgb(243,139,0)' : '#fff', 16, 0, 4)}</span>
                                    : RouterObjects[key].children[childkey].menuicon === faCandyCane ?
                                    <span style = {{color: 'inherit', position: 'relative', marginLeft: '5px', marginRight: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Assets_.clover(Main.Instance.Location.split('/')[2] == RouterObjects[key].children[childkey].path.replace('/','') ? 'rgb(243,139,0)' : '#fff', 16, 0, 4)}</span>
                                    : <FontAwesomeIcon style = {{width: '35px'}} icon = {RouterObjects[key].children[childkey].menuicon}/>}
                                    {/*<FontAwesomeIcon style = {{width: '25px'}} icon = {RouterObjects[key].children[childkey].menuicon}/>*/}
                                    {childkey.toString().replace(/____/g, '.').replace(/___/g,'-').replace(/__/g,'/').replace(/_/g,' ')}
                                    <strong>
                                        {key === 'account_Store' && (childkey === 'All_Issues' || childkey === 'Risk_Alerts' || childkey === 'RG_Issues' || childkey === 'KYC_Issues' || childkey === 'GM_Issues')
                                        ? ' [' + (childkey === 'All_Issues' ? Main.Instance.DatabaseDataState.totalIssuesCount : unresolvedAll[childkey === 'Risk_Alerts' ? 0 : childkey === 'RG_Issues' ? 1 : childkey === 'KYC_Issues' ? 2 : 3]) + ']' : void 0}
                                    </strong>
                                </li>
                                : null)}
                            </div> : null : null}
                        </div>,
                    )
                } else {return null}
            }
        }
    })
    return <ul className = "side-menu">{Array}</ul>
}

export let SideBar = View(() => {
    return (
       <div id = "sidebar-wrapper" style = {{overflowY: 'auto'}}>
            <ul className = "sidebar-nav" style = {{paddingBottom: '50px'}}>
                <div id = "sidebar-menu" className = "main_menu_side hidden_print main_menu" >
                    <div className = "menu_section">
                        <ul className = "nav side-menu">
                            <MenuItems/>
                        </ul>
                    </div>
                </div>
            </ul>
        </div>
    )
})