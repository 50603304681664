import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faPlus, faMinus, faDice, faCheck, faGifts, faUser, faToggleOn, faToggleOff, faHashtag, faAsterisk, faHourglassStart, faFlagCheckered, faStepForward, faStepBackward, faTimes, faStopwatch, faCalendarDay, faGamepad, faTrophy, faAward } from '@fortawesome/free-solid-svg-icons'
import { months } from '../../../Logic/DataStore/DataStore'
import { Main } from '../../../Logic/Main'
import {capitalizeFirstLetter, injectSpaces} from '../../../Logic/Utils'
import {LeaderboardTypes} from '../../../Logic/Database/tournaments/enums'
import ReactTable from 'react-table'
import {TournamentResult} from '../../../Logic/Database/DatabaseData'


type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6
type HourOfDay = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23

interface Races {
    id: number
    active: boolean
    dayOfWeek: DayOfWeek
    name: string
    hour: HourOfDay
    minute?: number
    durationMinutes?: number
    startDate?: string // YYYY-MM-DD, including
    endDate?: string // YYYY-MM-DD, including
    invalidGames?: []
    validGames?: []
    prizes: any[]
    mechanics: LeaderboardTypes
}

interface Race {
    id: number
    dayOfWeek: DayOfWeek
    name: string
    hour: number
    minute: number
    durationMinutes: number
    status: boolean // undefined = past, true = current, false = future
    invalidGames?: []
    validGames?: []
    prizes: any[]
    mechanics: LeaderboardTypes
}

interface FromTo {
    from: string
    to: string
}

interface PrevCurrNext {
    prev: number
    curr: number
    next: number
}

interface RacesScheduleDays {
    races: Race[]
}

interface RacesScheduleAll {
    days: RacesScheduleDays[]
}

interface State {
    schedule: RacesScheduleAll
    race: Race
    raceDay: number
    raceQueue: number

    date: Date
    week: number
    weekYear: number
    weekFromTo: FromTo
    month: PrevCurrNext

    currentDate: Date
    currentWeek: number
    currentWeekYear: number
    currentWeekFromTo: FromTo
    currentMonth: PrevCurrNext

    showGames: boolean
    Open: boolean
    loaded: boolean
    toggle: boolean
    selectedName: string
    selectedPosition: number
    selectedPage: number

    dayView: boolean
}

export class RacesSchedule extends React.Component<{}, State> {
    constructor(props: any) {
        super(props)
        const date: Date = new Date()
        const currentDate: Date = new Date()
        const week: number = this.getWeek()
        const weekYear: number = this.getWeekYear()
        const weekFromTo: FromTo = this.getDateRangeOfWeek(week, weekYear)
        const month: PrevCurrNext = this.getMonths(weekFromTo)
        if (Main.Instance.DatabaseDataState.tournamentStream === undefined) {
            this.state = {
                selectedPosition: -1,
                selectedPage: -1,
                selectedName: '',
                loaded: false,
                toggle: false,
                race: undefined, // should be false
                raceDay: -1,
                raceQueue: -1,
                date,
                week,
                weekYear,
                weekFromTo,
                month,
                currentDate,
                currentWeek: week,
                currentWeekYear: weekYear,
                currentWeekFromTo: weekFromTo,
                currentMonth: month,
                schedule: undefined,
                showGames: false,
                Open: Main.Instance.MenuState.Open,
                dayView: true
            }
        } else {
            this.state = {
                selectedName: '',
                selectedPosition: -1,
                selectedPage: -1,
                loaded: false,
                toggle: false,
                race: undefined,
                raceDay: -1,
                raceQueue: -1,
                date,
                week,
                weekYear,
                weekFromTo,
                month,
                currentDate,
                currentWeek: week,
                currentWeekYear: weekYear,
                currentWeekFromTo: weekFromTo,
                currentMonth: month,
                schedule: this.setRacesSchedule(weekFromTo),
                showGames: false,
                Open: Main.Instance.MenuState.Open,
                dayView: true
            }
        }
        this.windowChange = this.windowChange.bind(this);
        this.WindowChange = this.WindowChange.bind(this);
        // this.handleEdit = this.handleEdit.bind(this);
        // console.log (this.state.week)
        // console.log (this.state.weekYear)
        // console.log (this.getDateRangeOfWeek(this.state.week, this.state.weekYear))
        DbRoutes.wsReinitialize()
    }

    public componentDidMount() {
        window.addEventListener('resize', this.windowChange);
    }

    public windowChange() {
        this.setState({schedule: this.setRacesSchedule(this.state.weekFromTo)})
    }
    public WindowChange() {
        this.setState({
            Open: Main.Instance.MenuState.Open,
            schedule: this.setRacesSchedule(this.state.weekFromTo)
        })
    }
    public getMonths(weekFromTo: FromTo): PrevCurrNext {
        // weekFromTo = {from: '2019-09-30', to: '2019-10-06'}
        // console.log (weekFromTo)
        const months_: PrevCurrNext = { prev: -1, curr: -1, next: -1 }
        // const startDate: Date = new Date(Date.UTC(+weekFromTo.from.substring(0,4), +weekFromTo.from.substring(5,7) - 1, +weekFromTo.from.substring(8,10)))
        // const endDate: Date = new Date(Date.UTC(+weekFromTo.to.substring(0,4), +weekFromTo.to.substring(5,7) - 1, +weekFromTo.to.substring(8,10)))
        const midDate: Date = new Date(Date.UTC(+weekFromTo.from.substring(0, 4), +weekFromTo.from.substring(5, 7) - 1, +weekFromTo.from.substring(8, 10)))

        midDate.setDate(midDate.getDate() + 3)
        // console.log (startDate.toISOString().substring(0,10))
        // console.log (endDate.toISOString().substring(0,10))
        // console.log (midDate.toISOString().substring(0,10))
        const startMonth: number = +weekFromTo.from.substring(5, 7) - 1
        const endMonth: number = +weekFromTo.to.substring(5, 7) - 1
        // const midMonth: number = midDate.getMonth()
        months_.curr = midDate.getMonth()
        if (startMonth !== months_.curr) {
            months_.prev = startMonth
        } else if (endMonth !== months_.curr) {
            months_.next = endMonth
        }
        // console.log (months)
        return months_
    }

    public addZeroToOneDigit(input: number): string {
        return input < 10 ? '0' + input.toString() : input.toString()
    }

    public getDateRangeOfWeek(weekNo: number, y: number): { from: string, to: string } {
        // var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
        const d1 = new Date('' + y + '');
        const numOfdaysPastSinceLastMonday = d1.getDay() - 1;
        d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        d1.setDate(d1.getDate() + (7 * (weekNo - this.getWeek(d1))));
        const rangeIsFrom = d1.getFullYear() + '-' + this.addZeroToOneDigit(d1.getMonth() + 1) + '-' + this.addZeroToOneDigit(d1.getDate());
        d1.setDate(d1.getDate() + 6);
        const rangeIsTo = d1.getFullYear() + '-' + this.addZeroToOneDigit(d1.getMonth() + 1) + '-' + this.addZeroToOneDigit(d1.getDate());
        return { from: rangeIsFrom, to: rangeIsTo };
    };

    public getWeek(d1?: Date): number {
        const date: Date = d1 ? new Date(d1) : new Date()
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    // Returns the four-digit year corresponding to the ISO week of the date.
    public getWeekYear(d1?: Date): number {
        const date: Date = d1 ? new Date(d1) : new Date()
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
    }

    public selectRace(race: Race, raceDay: number, raceQueue: number) {
        // console.log (this.state.weekFromTo)
        const date: Date = new Date(race.dayOfWeek === 0 ? this.state.weekFromTo.to : this.state.weekFromTo.from)
        if (race.dayOfWeek > 0) {
            date.setUTCDate(date.getUTCDate() + race.dayOfWeek - 1)
        }
        date.setUTCHours(race.hour)
        date.setUTCMinutes(race.minute)
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        // console.log (date)
        // console.log (date.getTime())
        if (race.status !== undefined) {
            DbRoutes.getTournamentLeaderboard(race.id, date.getTime() / 1000)
        }
        
        if (this.state.dayView) {
            this.setState({
                selectedName: '',
                selectedPosition: -1,
                selectedPage: 0,
                race, 
                raceDay, 
                raceQueue, 
                toggle: false 
            })
        } else {
            this.setState({ race, raceDay, raceQueue, toggle: false })
        }
    }

    public getRacesAll(): JSX.Element[] {
        const divraces = document.getElementById('divraces')
        // console.log (window.innerWidth)
        const firstColumnWidth: number = 35
        let width: number = window.innerWidth - (Main.Instance.MenuState.Open ? 296 : 46) - firstColumnWidth
        
        if (divraces) {
            // console.log (divraces.clientWidth)
            width = divraces.clientWidth - firstColumnWidth
        } else {
            // return []
        }
        const columnHeight: number = 32
        const top: number = 50
        const gra: JSX.Element[] = []
        for (let j: number = 0; j < this.state.schedule.days.length; j++) {
            for (let i: number = 0; i < this.state.schedule.days[j].races.length; i++) {
                if (this.state.dayView && !this.isTodayReces(this.state.schedule.days[j].races[i].dayOfWeek)) {continue}
                // console.log (this.state.schedule.days[j].races[i])
                const disabled: boolean = j === this.state.raceDay && i === this.state.raceQueue
                gra.push(
                    <button
                        title={(this.state.schedule.days[j].races[i].status ? Assets.Ongoing : this.state.schedule.days[j].races[i].status === undefined ? Assets.Future : Assets.Past) + ' ' + Assets.Race.toLowerCase()}
                        className="btn br0 mr0"
                        style={{
                            zIndex: 9997,
                            color: disabled ? '#fff' : '',
                            background: disabled ? this.state.schedule.days[j].races[i].status ? '#337ab7' : this.state.schedule.days[j].races[i].status === undefined ? '#5cb85c' : '#d9534f' : this.state.schedule.days[j].races[i].status ? '#D6E6F4' : this.state.schedule.days[j].races[i].status === undefined ? '#DBEFDB' : '#F6D5D4',
                            position: 'absolute',
                            top: ((this.state.schedule.days[j].races[i].hour + this.state.schedule.days[j].races[i].minute / 60) * columnHeight + top) + 'px',
                            height: (this.state.schedule.days[j].races[i].durationMinutes * columnHeight / 60) + 'px',
                            lineHeight: (this.state.schedule.days[j].races[i].durationMinutes * columnHeight / 60) + 'px',
                            right: this.state.dayView ? '' : (width / 7 * (6 - j)) + 'px',
                            width: this.state.dayView ? '254px' :(width / 7) + 'px',
                            padding: '1px 0px 0px',
                            left: this.state.dayView ? '45px' : ''
                        }}
                        disabled={disabled}
                        type="button"
                        onClick={() => this.selectRace(this.state.schedule.days[j].races[i], j, i)}
                    >
                        <span
                            style = {{fontWeight: this.state.schedule.days[j].races[i].name === Assets.All + ' ' + Assets.Games ? 600 : 400}}
                        >
                            {this.state.schedule.days[j].races[i].name}
                        </span>
                        {this.ShowSelectedGames(this.state.schedule.days[j].races[i].validGames && this.state.schedule.days[j].races[i].validGames.length > 0 ? this.state.schedule.days[j].races[i].validGames : undefined, this.state.schedule.days[j].races[i].invalidGames && this.state.schedule.days[j].races[i].invalidGames.length > 0 ? this.state.schedule.days[j].races[i].invalidGames : undefined) ?
                        <span
                            style = {{position: 'absolute', top: '-4px', right: '5px'}}
                            title = {this.showSelectedGames(this.state.schedule.days[j].races[i].validGames && this.state.schedule.days[j].races[i].validGames.length > 0 ? this.state.schedule.days[j].races[i].validGames : undefined, this.state.schedule.days[j].races[i].invalidGames && this.state.schedule.days[j].races[i].invalidGames.length > 0 ? this.state.schedule.days[j].races[i].invalidGames : undefined).description}
                        >
                            <FontAwesomeIcon icon = {this.state.schedule.days[j].races[i].name === Assets.All + ' ' + Assets.Games ? faAsterisk : faGamepad}/>
                        </span> : void 0}
                        
                    </button>
                )
            }
        }
        return gra
    }

    public setRacesSchedule(fromTo: FromTo): RacesScheduleAll {
        // console.log (fromTo)
        const Minute: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.startMinutes : 0
        const durationMinute: number = Main.Instance.GeneralState.general && Main.Instance.GeneralState.general.globalRacesConfiguration && Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes ? Main.Instance.GeneralState.general.globalRacesConfiguration.durationMinutes : 55
        const races: Races[] = []
        const allTournaments = Object.assign ([], Main.Instance.DatabaseDataState.tournamentStream)
        for (const item of allTournaments) {
            if (item.tournamentType === 'Races' && item.raceDetails) {
                for (const Item of item.raceDetails) {
                    // console.log (item)
                    races.push({
                        id: item.id,
                        active: item.active, 
                        dayOfWeek: Item.dayOfWeek, 
                        name: item.name, 
                        hour: Item.hour, 
                        startDate: item.validFrom.substring(0, 10), 
                        endDate: item.validTo.substring (0, 10), 
                        minute: Item.minute, 
                        durationMinutes: Item.durationMinutes,
                        validGames: item.validGames,
                        invalidGames: item.invalidGames,
                        prizes: item.prizes,
                        mechanics: item.leaderboardType
                    })
                }
            }
        }
        // console.log (races)

        const rsa: RacesScheduleAll = {
            days: [
                { races: [] },
                { races: [] },
                { races: [] },
                { races: [] },
                { races: [] },
                { races: [] },
                { races: [] }
            ]
        }
        const cdate: string = (new Date()).toISOString()
        // console.log (cdate.toISOString())
        if (races) {
            for (const item of races) {
                if (item.active) {
                    const dayOfWeek: DayOfWeek = (item.dayOfWeek === 0 ? 6 : item.dayOfWeek - 1) as DayOfWeek
                    const date = new Date(fromTo.from)
                    date.setUTCDate(date.getUTCDate() + dayOfWeek)
                    date.setUTCHours(item.hour)
                    const minute: number = item.minute ? item.minute : Minute
                    date.setUTCMinutes(minute)
                    date.setUTCSeconds(0)
                    date.setUTCMilliseconds(0)
                    // console.log (date.toISOString())
                    const date2 = new Date(fromTo.from)
                    date2.setUTCDate(date2.getUTCDate() + dayOfWeek)
                    date2.setUTCHours(item.hour)
                    const durationMinutes: number = item.durationMinutes ? item.durationMinutes : durationMinute
                    date2.setUTCMinutes(minute + durationMinutes)
                    date2.setUTCSeconds(0)
                    date2.setUTCMilliseconds(0)
                    // console.log (date2.toISOString())
                    if (item.startDate && item.startDate > date.toISOString().substring(0, 10)) { continue }
                    if (item.endDate && item.endDate < date.toISOString().substring(0, 10)) { continue }

                    let status: boolean = true
                    if (cdate < date.toISOString()) {
                        status = undefined
                    } else if (cdate > date2.toISOString()) {
                        status = false
                    }
                    // we cannot just push into array, we need to find the position
                    let position: number = -1
                    const racesNew: Race[] = []
                    for (let i: number = 0; i < rsa.days[dayOfWeek].races.length; i++) {
                        if (rsa.days[dayOfWeek].races[i].hour <= item.hour && rsa.days[dayOfWeek].races[i].minute <= item.minute) {
                            position = i
                        } 
                    }
/*
                    rsa.days[dayOfWeek].races.push({
                        id: item.id,
                        dayOfWeek: item.dayOfWeek,
                        name: item.name,
                        hour: item.hour,
                        minute,
                        durationMinutes,
                        status, 
                        validGames: item.validGames,
                        invalidGames: item.invalidGames,
                        prizes: item.prizes,
                        mechanics: item.mechanics
                    })
*/
                    if (position > -1) {
                        racesNew.push (...rsa.days[dayOfWeek].races.slice(0, position + 1))
                    }
                    racesNew.push({
                        id: item.id,
                        dayOfWeek: item.dayOfWeek,
                        name: item.name,
                        hour: item.hour,
                        minute,
                        durationMinutes,
                        status, 
                        validGames: item.validGames,
                        invalidGames: item.invalidGames,
                        prizes: item.prizes,
                        mechanics: item.mechanics
                    })
                    if (position < rsa.days[dayOfWeek].races.length) {
                        racesNew.push (...rsa.days[dayOfWeek].races.slice(position + 1, rsa.days[dayOfWeek].races.length))
                    }
                    // console.log (rsa.days[dayOfWeek].races)
                    // console.log (position)
                    rsa.days[dayOfWeek].races = racesNew
                    // console.log (racesNew)
                }
            }
        }
        
        return rsa
    }

    public getHeader0(): JSX.Element[] {
        const header: JSX.Element[] = []
        let totalRaces: number = 0
        for (let i: number = 1; i <= Assets.DaysInWeek.length; i++) {
            totalRaces += this.state.schedule.days[i - 1].races.length
        }

        header.push(<th key="0" style={{ paddingTop: '1px', paddingBottom: '1px', fontSize: '16px', position: 'relative' }} className="th-center blackthick" colSpan={Assets.DaysInWeek.length + 1}><FontAwesomeIcon icon={faFlagCheckered}/>&nbsp;&nbsp;{Assets.Races + ' ' + Assets.Schedule + ' (' + Assets_.UTC + ')'}&nbsp;&nbsp;<FontAwesomeIcon icon={faStopwatch} />&nbsp;{Assets._for_ + Assets.Week.toLowerCase() + ' ' + Assets.Starting.toLowerCase() + Assets._on_}&nbsp;<FontAwesomeIcon icon={faCalendarDay} />&nbsp;{this.state.weekFromTo.from + Assets._and_ + Assets.Ending.toLowerCase() + Assets._on_}&nbsp;<FontAwesomeIcon icon={faCalendarDay} />&nbsp;{this.state.weekFromTo.to}, {Assets.Total.toLowerCase()}: {totalRaces}/{24 * 7} ({(totalRaces * 100 / (24 * 7)).toFixed(2)}%)<a onClick = {() => this.downloadCSVRaces(false)}title = {Assets.Download + ' ' + Assets.Races.toLowerCase() + ' ' + Assets.List.toLowerCase() + Assets._for_ + Assets.this_ + ' ' + Assets.Week.toLowerCase()} style = {{position: 'absolute', top: '-2px', right: '5px', color: '#333', fontSize: '20px', padding: '0px'}}><FontAwesomeIcon icon = {faFileCsv}/></a><a onClick = {() => this.downloadCSVRaces(true)}title = {Assets.Download + ' ' + Assets.Races.toLowerCase() + ' ' + Assets.List.toLowerCase() + Assets._for_ + Assets.Today.toLowerCase()} style = {{position: 'absolute', top: '-2px', left: '5px', color: '#333', fontSize: '20px', padding: '0px'}}><FontAwesomeIcon icon = {faFileCsv}/></a></th>)
        /*
                header.push(<th key = "0" style = {{paddingTop: '1px', paddingBottom: '1px', fontSize: '16px'}} className = "th-center blackthick" colSpan = {2}><FontAwesomeIcon icon = {faFlagCheckered}/>&nbsp;&nbsp;{Assets.Races + ' ' + Assets.Schedule + ' (' + Assets_.UTC + ')'}&nbsp;&nbsp;<FontAwesomeIcon icon = {faStopwatch}/></th>)
                header.push(<th key = "1" style = {{paddingTop: '1px', paddingBottom: '1px', fontSize: '16px'}} className = "th-center blackthick" colSpan = {Assets.DaysInWeek.length - 1}>{Assets._for_ + Assets.Week.toLowerCase() + ' ' + Assets.Starting.toLowerCase() + Assets._on_ + this.state.weekFromTo.from + Assets._and_ + Assets.Ending.toLowerCase() + Assets._on_ + this.state.weekFromTo.to}</th>)
        */
        return header
    }

    public getHeader0Day(): JSX.Element[] {
        const header: JSX.Element[] = []
        
        header.push(<th key="0" style={{ paddingTop: '1px', paddingBottom: '1px', fontSize: '16px', position: 'relative', paddingLeft: '20px' }} className="th-center blackthick" colSpan={2}><FontAwesomeIcon icon={faFlagCheckered}/>&nbsp;&nbsp;{Assets.Races + ' ' + Assets.Schedule + ' (' + Assets_.UTC + ')'}<a onClick = {() => this.downloadCSVRaces(true)}title = {Assets.Download + ' ' + Assets.Races.toLowerCase() + ' ' + Assets.List.toLowerCase() + ' ' + Assets.For.toLowerCase() + Assets._the_ + Assets.Selected.toLowerCase() + ' ' + Assets.Day.toLowerCase()} style = {{position: 'absolute', top: '-2px', left: '5px', color: '#333', fontSize: '20px', padding: '0px'}}><FontAwesomeIcon icon = {faFileCsv}/></a></th>)

        return header
    }

    public getFrom(addDays: number): string {
        const date: Date = new Date(this.state.weekFromTo.from)
        date.setDate(date.getDate() + addDays)
        return date.toISOString().substring(0, 10)
    }

    public getToday(): string {
        const date: Date = new Date(this.state.date)
        date.setDate(date.getDate())
        let day: string = ''
        for (const item of Assets.DaysInWeek) {
            if (item.substring(0,2) === date.toString().substring(0,2)) {
                day = item
            }
        }
        return day + ' | ' + date.toISOString().substring(0, 10)
    }

    public isDayToday(): boolean {
        const date: Date = new Date(this.state.date)
        date.setDate(date.getDate())
        if (date.toISOString().substring(0, 10) === this.state.currentDate.toISOString().substring(0, 10)) {
            return true
        }
        return false
    }

    public isTodayReces(i: number): boolean {

        const date: Date = new Date(this.state.date)
        date.setDate(date.getDate())
        if (Assets.DaysInWeek[i].substring(0,2) === date.toString().substring(0,2)) {
            return true
            
        }
        return false
    }
    // this.state.weekFromTo.from, 

    public isHeadingToday(column: number): boolean {
        const today: Date = new Date(this.state.weekFromTo.from)
        today.setDate(today.getDate() + column)
        if (today.toISOString().substring(0, 10) === this.state.currentDate.toISOString().substring(0, 10)) {
            return true
        }
        return false
    }

    public getHeader1(): JSX.Element[] {
        const header: JSX.Element[] = []
        header.push(<th style={{ paddingTop: '1px', paddingBottom: '1px'}} title={Assets.Start + ' ' + Assets.Time} className="th-center bold" key="0"><FontAwesomeIcon icon={faHourglassStart} /></th>)
        for (let i: number = 1; i <= Assets.DaysInWeek.length; i++) {
            const isHeadingToday: boolean = this.isHeadingToday(i - 1)
            header.push(<th className="th-center pr" style={{ color: isHeadingToday ? '#fff' : '', background: isHeadingToday ? '#000' /*'#DC143C'*/ : '', paddingTop: '1px', paddingBottom: '1px' }} /*className = {i % 2 === 0 ? 'th-center bold risk-default pr' : 'th-center bold risk-default-light pr'}*/ key={i.toString()}>&nbsp;<div style={{ position: 'absolute', top: '1px', left: '0px', right: '0px' }}><FontAwesomeIcon icon={faCalendarDay} />&nbsp;&nbsp;{Assets.DaysInWeek[i === 7 ? 0 : i]}{' | '}{i === 7 ? this.state.weekFromTo.to : i === 1 ? this.state.weekFromTo.from : this.getFrom(i - 1)}</div></th>)
            }
    
        return (header)
    }

    public getHeaderDay(): JSX.Element[] {
        const header: JSX.Element[] = []
        header.push(<th style={{ paddingTop: '1px', paddingBottom: '1px', width:'35px'}} title={Assets.Start + ' ' + Assets.Time} className="th-center bold" key="0"><FontAwesomeIcon icon={faHourglassStart} /></th>)
        header.push(<th className="th-center pr" style={{ color: '#fff', background: '#000', paddingTop: '1px', paddingBottom: '1px' }} key="1">&nbsp;<div style={{ position: 'absolute', top: '1px', left: '0px', right: '0px' }}><FontAwesomeIcon icon={faCalendarDay} />&nbsp;&nbsp;{this.getToday()}</div></th>)
        return (header)
    }

    public getHeader(): JSX.Element[] {
        const header: JSX.Element[] = []
        if (!this.state.dayView) {
            header.push(<tr key="0">{this.getHeader0()}</tr>)
            header.push(<tr key="1">{this.getHeader1()}</tr>)
        } else {
            header.push(<tr key="0">{this.getHeader0Day()}</tr>)
            header.push(<tr key="1">{this.getHeaderDay()}</tr>)
        }
        
        return header
    }

    public getFooter(): JSX.Element[] {
        const footer: JSX.Element[] = []
        footer.push(...this.getFooter1())
        return footer
    }

    public getRacesByHour(which: number, width: number, date: string) {
        // console.log (which, width)
        const hours: number = +date.substring(11, 13)
        const races: JSX.Element[] = []
        races.push(<td className="th-center" style={{ color: hours === which ? '#fff' : '', background: hours === which ? '#000' : '', fontSize: '16px', fontWeight: 700, width: '35px', height: '31px', lineHeight: '31px', padding: '0px' }} /*className = {which % 2 === 0 ? 'th-center risk-default bold' : 'th-center risk-default-light bold'}*/ key="0">{which < 10 ? '0' : ''}{which}</td>)
        for (let i: number = 1; i <= (this.state.dayView ? 1 : Assets.DaysInWeek.length); i++) {
            const isHeadingNow: boolean = (this.state.dayView ? this.isDayToday() && hours === which : this.isHeadingToday(i - 1) && hours === which)
            races.push(
            <td className = "pr" style={{width: width + 'px', height: '31px', lineHeight: '31px', padding: '0px'/*, background: isHeadingNow ? '#000' : ''*/}} key={i.toString()}>
                &nbsp;
                {isHeadingNow ?
                <>
                <div style = {{position: 'absolute', zIndex: 9998, left: '0px', bottom: '0px', height: '3px', right: '0px', background: '#000'}}>
                    &nbsp;
                </div>
                <div style = {{position: 'absolute', zIndex: 9998, left: '0px', top: '0px', height: '3px', right: '0px', background: '#000'}}>
                    &nbsp;
                </div>
                <div style = {{position: 'absolute', zIndex: 9998, left: '0px', top: '0px', width: '3px', bottom: '0px', background: '#000'}}>
                    &nbsp;
                </div>
                <div style = {{position: 'absolute', zIndex: 9998, right: '0px', top: '0px', width: '3px', bottom: '0px', background: '#000'}}>
                    &nbsp;
                </div>
                </>
                : void 0}
            </td>)
        }
        return races
    }

    public getBody(date: string): JSX.Element[] {
        const divraces = document.getElementById('divraces')
        // console.log (window.innerWidth)
        // console.log (window.outerWidth)
        const firstColumnWidth: number = 35
        let width: number = (window.innerWidth - (Main.Instance.MenuState.Open ? 296 : 46) - firstColumnWidth) / 7

        if (divraces) {
            // console.log (divraces.clientWidth)
            width = (divraces.clientWidth - firstColumnWidth) / 7 
        } else {
            // return []
        }

        if (this.state.dayView) {
            width = 200
        }
        
        const body: JSX.Element[] = []
        for (let i: number = 0; i < 24; i++) {
            body.push(<tr key={i.toString()}>{this.getRacesByHour(i, width, date)}</tr>)
        }
        return body
    }

    public getFooter1(): JSX.Element[] {
        const footer: JSX.Element[] = []
        let TotalRaces: number = 0
        for (let i: number = 1; i <= Assets.DaysInWeek.length; i++) {
            
            if (this.state.dayView && !this.isTodayReces(i-1)) {continue}
            TotalRaces += this.state.schedule.days[i - 1].races.length
        }

        footer.push(<th title={Assets.Total + ': ' + TotalRaces + '/' + (24 * (this.state.dayView ? 1 : 7)) + ' (' + (TotalRaces / (24 * (this.state.dayView ? 1 : 7)) * 100).toFixed(2) + '%)'} style={{ color: '#fff', background: TotalRaces === 24 * (this.state.dayView ? 1 : 7) ? '#5cb85c' : '#d9534f', width: '35px', height: '30px', lineHeight: '30px', padding: '0px', fontSize: '20px' }} className="th-center bold" key="0">{Assets_.sum}</th>)
        for (let i: number = 1; i <= Assets.DaysInWeek.length; i++) {
            if (this.state.dayView && !this.isTodayReces(i-1)) {continue}
            const totalRaces: number = this.state.schedule.days[i - 1].races.length
            footer.push(<th className="th-center" style={{ color: '#fff', background: totalRaces === 24 ? '#5cb85c' : '#d9534f', height: '30px', lineHeight: '30px', padding: '0px' }} key={i.toString()}>{Assets.Total}: {totalRaces}/24 ({(totalRaces * 100 / 24).toFixed(2)}%)</th>)
        }
        return footer
    }

    public getRacesScheduleTable(): JSX.Element {
        const date: string = (new Date()).toISOString()
        // console.log (date)
        return (
            <div className="pr" id="divraces">
                {this.state.toggle ?
                <>
                <div style = {{padding: '10px', background: '#fff', border: '1px solid #999', zIndex: 9999, position: 'absolute', top: '10px', bottom: '10px', left: '10px', right: '10px'}}>
                    {this.getTournamentResultTable()}
                </div>
                <a
                    style = {{fontSize: '12px', color: '#333', position: 'absolute', top: '10px', right: '15px', background: 'transparent', border: '0px', zIndex: 10000}}
                    onClick = {() => this.setState({toggle: !this.state.toggle})}
                >
                    <FontAwesomeIcon icon = {faTimes}/>
                </a>
                </> : void 0}
                <table className="table table-bordered table-striped" style = {this.state.dayView ? {maxWidth: '200px'} : {}}>
                    <thead>{this.getHeader()}</thead>
                    <tbody>{this.getBody(date)}</tbody>
                    <tfoot><tr>{this.getFooter()}</tr></tfoot>
                </table>
                {/* window.addEventListener('resize', this.getRacesAll) */}
                {this.getRacesAll()}
            </div>
        )
    }

    public getTournamentResultTable(): JSX.Element  {
        return (
            <div>
                <div className = "pr clearfix">
                        <h3 className = "fl">
                            {Assets.Race + ' ' + Assets.Leaderboard.toLowerCase()}
                        </h3>
                        <div className = "fr mt20">
                            <select
                                style = {{height: '24px'}}
                                className = "mr"
                                value = {this.state.selectedName}
                                onChange = {(e) => {
                                    const data: TournamentResult[] = Object.assign([], Main.Instance.DatabaseDataState.tournamentResult)
                                    let selectedPosition: number = -1

                                    for (let i: number = 0; i < data.length; i++) {
                                        if (e.target.value === data[i].username) {
                                            selectedPosition = i
                                        }
                                    }
                                    this.setState({selectedName: e.target.value, selectedPosition, selectedPage: Math.trunc(selectedPosition / 10)})
                                }}
                            >
                                {this.getOptions(1, true)}
                            </select>
                            {this.getAutocomplete(Main.Instance.DatabaseDataState.tournamentResult)}
                            <input 
                                style = {{height: '22px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                                placeholder = {Assets.Filter + Assets._by_ + Assets.Name.toLowerCase()} 
                                // className = "mr"
                                onChange = {(e) => {
                                    const data: TournamentResult[] = Object.assign([], Main.Instance.DatabaseDataState.tournamentResult)
                                    let selectedPosition: number = -1
                                    
                                    for (let i: number = 0; i < data.length; i++) {
                                        if (e.target.value === data[i].username) {
                                            selectedPosition = i
                                        }
                                    }
                                    this.setState({selectedName: e.target.value, selectedPosition, selectedPage: Math.trunc(selectedPosition / 10)})
                                    
                                }} 
                                list = "names"
                                type = "text" 
                                value = {this.state.selectedName !== '-1' ? this.state.selectedName : ''}
                            />
                        </div>
                    </div>
                    {/*console.log (Main.Instance.DatabaseDataState.tournamentResult)*/}
                    <ReactTable
                        data = {Main.Instance.DatabaseDataState.tournamentResult}
                        columns = {[
                            {className: 'btn-nopadding', headerClassName: 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].position !== undefined, Header: <><FontAwesomeIcon icon = {faHashtag}/>&nbsp;{Assets.Position}</>, accessor: 'position', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg' :*/ ''}>&nbsp;&nbsp;{row.value}&nbsp;&nbsp;{row.value === 1 || row.value === 2 || row.value === 3 ? <FontAwesomeIcon icon = {faTrophy} style = {{fontSize: '18px', color: row.value === 1 ? '#FFD700' : row.value === 2 ?'#C0C0C0' : row.value === 3 ? '#cd7f32' : ''}}/> : row.value !== undefined ? <FontAwesomeIcon icon = {faAward} style = {{fontSize: '20px'}}/> : void 0}</div>},
                            {className: 'btn-nopadding', headerClassName: 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].username !== undefined, Header: <><FontAwesomeIcon icon = {faUser}/>&nbsp;{Assets.Username}</>, accessor: 'username', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg' :*/ ''}>&nbsp;&nbsp;{row.value}</div>},
                            {className: 'btn-nopadding', headerClassName: 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].reward1 !== undefined, Header: <><FontAwesomeIcon icon = {faGifts}/>&nbsp;{Assets.Reward}</>, accessor: 'reward1', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.value !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value === undefined ? Assets.No + ' ' + Assets.Reward.toLowerCase() : this.getReward(row.value)}</div>},
                            {className: 'btn-nopadding', headerClassName: this.state.race.mechanics === LeaderboardTypes.HighestWager ? 'goldbg th-left' : 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].highestWager !== undefined, Header: <><i className = {Assets_.faFirstButtonClass}/>&nbsp;{Assets.Highest + ' ' + Assets.Wager.toLowerCase()}</>, accessor: 'highestWager', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value}</div>},
                            {className: 'btn-nopadding', headerClassName: this.state.race.mechanics === LeaderboardTypes.RoundsPlayed ? 'goldbg th-left' : 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].roundsPlayed !== undefined, Header: <><FontAwesomeIcon icon = {faDice}/>&nbsp;{Assets.Rounds + ' ' + Assets.Played.toLowerCase()}</>, accessor: 'roundsPlayed', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value}</div>},
                            {className: 'btn-nopadding', headerClassName: this.state.race.mechanics === LeaderboardTypes.RoundsWon ? 'goldbg th-left' : 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].roundsWon !== undefined, Header: <><FontAwesomeIcon icon = {faCheck}/>&nbsp;{Assets.Rounds + ' ' + Assets.Won.toLowerCase()}</>, accessor: 'roundsWon', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value}</div>},
                            {className: 'btn-nopadding', headerClassName: this.state.race.mechanics === LeaderboardTypes.TotalWagered ? 'goldbg th-left' : 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].sumOfBets !== undefined, Header: <>Σ&nbsp;{Assets.Sum + Assets._of_ + Assets.Bets.toLowerCase()}</>, accessor: 'sumOfBets', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value.toFixed(2)}</div>},
                            {className: 'btn-nopadding', headerClassName: this.state.race.mechanics === LeaderboardTypes.HighestWinRatio ? 'goldbg th-left' : 'th-left', show: Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult[0] && Main.Instance.DatabaseDataState.tournamentResult[0].highestWinRatio !== undefined, Header: <><i className = {Assets_.faFirstButtonClass}/>&nbsp;{Assets.Highest + ' ' + Assets.Win.toLowerCase() + ' ' + Assets.Ratio}</>, accessor: 'highestWinRatio', Cell: row => <div className = {row.index === this.state.selectedPosition ? 'light_green' : /*row.original.reward1 !== undefined ? 'goldbg':*/ ''}>&nbsp;&nbsp;{row.value.toFixed(2)}</div>},

                        ]}
                        showPaginationBottom = {false}
                        showPaginationTop = {true}
                        defaultPageSize={10}
                        sortable={false}
                        page={this.state.selectedPage}
                        onPageChange={(pageIndex) => { this.setState({selectedPage: pageIndex});}}
                    />
            </div>
        )
    }

    public getRacesScheduleTableByDay(): JSX.Element  {
        const date: string = (new Date()).toISOString()
        const divraces = document.getElementById('divraces')
        let width: number = (window.innerWidth - (Main.Instance.MenuState.Open ? 296 : 46) - 330)
        if (divraces) {
            width = (divraces.clientWidth - 330) 
        } else {
        }

        return (
            <div className="pr" id="divraces">
                <div className= "dbl fl" style = {{width: '300px'}}>
                    <table className="table table-bordered table-striped">
                        <thead>{this.getHeader()}</thead>
                        <tbody>{this.getBody(date)}</tbody>
                        {<tfoot><tr>{this.getFooter()}</tr></tfoot> }
                    </table>
                    {this.getRacesAll()}
                </div>
                {this.state.race ?  
                <div className= "dbl fr" style = {{width: width + 'px'}}>
                   {this.state.race.status !== undefined ? this.getTournamentResultTable() : this.gerPrizesTable()}
                </div> :
                <div className= "dbl fr" style = {{width: width + 'px'}}>
                    <h3>{Assets.Please + ' ' + Assets.Select.toLowerCase() + Assets._a_ + Assets.Race.toLowerCase()}</h3>
                </div>}

                
            </div>
        )

    }

    public gerPrizesTable(): JSX.Element {
        // console.log(this.state.race.prizes)
        return (
            <div>
                <div className = "pr clearfix">
                    <h3 className = "fl">
                        {Assets.ListOfRewards}
                    </h3>
                </div>
                <ReactTable
                    data = {this.state.race.prizes}
                    columns = {[
                        {width: 120, className: 'btn-nopadding', headerClassName: 'th-left', Header: Assets.First + ' ' + Assets.Rewarded.toLowerCase(), accessor: 'firstRewarded', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {width: 120, className: 'btn-nopadding', headerClassName: 'th-left', Header: Assets.Last + ' ' + Assets.Rewarded.toLowerCase(), accessor: 'lastRewarded', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', headerClassName: 'th-left', Header: <><FontAwesomeIcon icon = {faGifts}/>&nbsp;{Assets.Reward}</>, accessor: 'reward1Info.rewardName', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {width: 120, className: 'btn-nopadding', headerClassName: 'th-left', Header:<><FontAwesomeIcon icon = {faHashtag}/>&nbsp;{Assets.Rewards}</>, accessor: '', Cell: (row) => <div>&nbsp;&nbsp;{row.original.lastRewarded - row.original.firstRewarded + 1}</div>},
                        {width: 150, className: 'btn-nopadding', headerClassName: 'th-left', Header: Assets.BonusCode, accessor: 'reward1Info.bonusCode', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', headerClassName: 'th-left', Header: Assets.Description, accessor: 'reward1Info.description', Cell: (row) => <div>&nbsp;&nbsp;{row.value}</div>},

                    ]}
                    showPaginationBottom = {false}
                    showPaginationTop = {true}
                    // pageSize={this.state.race.prizes.length}
                    defaultPageSize={10}

                />
            </div>
        )
    }


    public getOptions(data, sort?: boolean): JSX.Element[] {
        const options: JSX.Element[] = []
        
        const edata: TournamentResult[] = Object.assign([], Main.Instance.DatabaseDataState.tournamentResult)
/*
        if (data === 0) {
            options.push (<option key = "-1" value = "-1">{Assets.Select} {Assets.ID}</option>)
            const nameAlphabetized = sort ? edata.sort(this.compareId) : data
            for (let i: number = 0; i < nameAlphabetized.length; i++) {
                options.push (<option key = {i.toString()} value = {nameAlphabetized[i].playerId}>{nameAlphabetized[i].playerId}</option>)
            }
        } else if (data === 1) {
*/
        options.push (<option key = "-1" value = "-1">{Assets.Select} {Assets.Name}</option>)
        const nameAlphabetized = sort ? edata.sort(this.compareName) : data
        for (let i: number = 0; i < nameAlphabetized.length; i++) {
            options.push (<option key = {i.toString()} value = {nameAlphabetized[i].username}>{nameAlphabetized[i].username}</option>)
        }
/*
        }
*/
     
        return options
    }

    public changeWeek(change: number) {
        const date: Date = this.state.date

        date.setDate(date.getDate() + change * (this.state.dayView ? 1 : 7))
        
        const week = this.getWeek(date)
        const weekYear = this.getWeekYear(date)
        const weekFromTo: FromTo = this.getDateRangeOfWeek(week, weekYear)
        const month: PrevCurrNext = this.getMonths(weekFromTo)
        const schedule = this.setRacesSchedule(weekFromTo)
        this.setState({
            date,
            week,
            weekYear,
            weekFromTo,
            month,// : this.getMonths(weekFromTo),
            schedule,// : this.setRacesSchedule(weekFromTo),
            race: undefined,
            raceDay: -1,
            raceQueue: -1,
            toggle: false,
            selectedName: '',
            selectedPosition: -1,
            selectedPage: -1,
        })
    }

    public getMonthsView(): JSX.Element[] {

        const monthsView: JSX.Element[] = []
        // monthsView.push(<div title={Assets.Previous + ' ' + Assets.Month.toLowerCase()} key="0" style={{ paddingTop: '.3em', paddingLeft: '0px', paddingRight: '0px', border: '1px solid #999', width: '150px', fontSize: '16px', lineHeight: '24px', background: this.state.month.prev === -1 ? '#fff' : '#999999' }} className="br0 mr fl label label-default">&nbsp;{this.state.month.prev === -1 ? '' : months[this.state.month.prev]}&nbsp;</div>)
        monthsView.push(<div title={Assets.Current + ' ' + Assets.Month.toLowerCase()} key="1" style={{ paddingTop: '.3em', paddingLeft: '0px', paddingRight: '0px', border: '1px solid #999', width: '160px', fontSize: '16px', lineHeight: '24px'}} className="br0 label label-default fl mr">&nbsp;{months[this.state.month.curr]}&nbsp;{this.state.weekYear}&nbsp;</div>)
        // monthsView.push(<div title={Assets.Next + ' ' + Assets.Month.toLowerCase()} key="2" style={{ paddingTop: '.3em', paddingLeft: '0px', paddingRight: '0px', border: '1px solid #999', width: '150px', fontSize: '16px', lineHeight: '24px', background: this.state.month.next === -1 ? '#fff' : '#999999' }} className="br0 fl label label-default">&nbsp;{this.state.month.next === -1 ? '' : months[this.state.month.next]}&nbsp;</div>)
        monthsView.push(
            <button key="3" onClick = {() => {this.setState({dayView: !this.state.dayView})}} className = {this.state.dayView ? 'btn btn-primary btn-sm dbl fl' : 'btn btn-default btn-sm dbl fl'} style={{ padding: '3px 10px', lineHeight: '24px', width: '110px'}}>{Assets.View + Assets._by_ + Assets.Day.toLowerCase()}</button>
        )
        monthsView.push(
            <button key="4" onClick = {() => {this.setState({dayView: !this.state.dayView})}} className = {this.state.dayView ? 'btn btn-default btn-sm dbl fl mr0' : 'btn btn-primary btn-sm dbl fl mr0'} style={{ padding: '3px 10px', lineHeight: '24px', width: '110px'}}>{Assets.View + Assets._by_ + Assets.Week.toLowerCase()}</button>
        )
        return monthsView
    }

    public getDaysView(): JSX.Element[] {
        const weeksView: JSX.Element[] = []
        weeksView.push(<button title={Assets.Change + Assets._to_ + Assets.Previous.toLowerCase() + ' ' + Assets.Day.toLowerCase()} style={{ padding: '3px 10px' }} key="3" onClick={() => this.changeWeek(-1)} className="btn btn-default btn-sm dbl fl"><FontAwesomeIcon size="lg" icon={faStepBackward} /></button>)
        weeksView.push(<div title={Assets.Current + ' ' + Assets.Day.toLowerCase()} key="1" style={{ padding: '0px 10px', border: '1px solid #999', fontSize: '16px', lineHeight: '24px' }} className="br0 mr label label-default fl">{this.state.date.toISOString().substring(0,10)}</div>)
        weeksView.push(<button title={Assets.Change + Assets._to_ + Assets.Next.toLowerCase() + ' ' + Assets.Week.toLowerCase()} style={{ padding: '3px 10px' }} key="4" onClick={() => this.changeWeek(1)} className="btn btn-default btn-sm mr0 dbl fl"><FontAwesomeIcon size="lg" icon={faStepForward} /></button>)
        return weeksView
    }

    public getWeeksView(): JSX.Element[] {
        const weeksView: JSX.Element[] = []
        weeksView.push(<button title={Assets.Change + Assets._to_ + Assets.Previous.toLowerCase() + ' ' + Assets.Week.toLowerCase()} style={{ padding: '3px 10px' }} key="3" onClick={() => this.changeWeek(-1)} className="btn btn-default btn-sm dbl fl"><FontAwesomeIcon size="lg" icon={faStepBackward} /></button>)
        weeksView.push(<div title={Assets.Previous + ' ' + Assets.Week.toLowerCase()} key="0" style={{ padding: '0px', border: '1px solid #999', width: '50px', fontSize: '16px', lineHeight: '24px', background: '#999999' }} className="br0 mr fl label label-default">W{this.state.week - 1}</div>)
        weeksView.push(<div title={Assets.Current + ' ' + Assets.Week.toLowerCase()} key="1" style={{ padding: '0px', border: '1px solid #999', width: '50px', fontSize: '16px', lineHeight: '24px' }} className="br0 mr label label-default fl">W{this.state.week}</div>)
        weeksView.push(<div title={Assets.Next + ' ' + Assets.Week.toLowerCase()} key="2" style={{ padding: '0px', border: '1px solid #999', width: '50px', fontSize: '16px', lineHeight: '24px', background: '#999999' }} className="br0 mr fl label label-default">W{this.state.week + 1}</div>)
        weeksView.push(<button title={Assets.Change + Assets._to_ + Assets.Next.toLowerCase() + ' ' + Assets.Week.toLowerCase()} style={{ padding: '3px 10px' }} key="4" onClick={() => this.changeWeek(1)} className="btn btn-default btn-sm mr0 dbl fl"><FontAwesomeIcon size="lg" icon={faStepForward} /></button>)
        return weeksView
    }

    // public getYearView(): JSX.Element {
    //     return <div style={{ padding: '0px', border: '1px solid #999', width: '60px', fontSize: '16px', lineHeight: '24px', background: '#999999' }} className="br0 label label-default dbl">{this.state.weekYear}</div>
    // }

    public setCurrentWeek() {
        // same as constructor
        const date: Date = new Date()
        const week: number = this.getWeek()
        const weekYear: number = this.getWeekYear()
        const weekFromTo: FromTo = this.getDateRangeOfWeek(week, weekYear)
        const month: PrevCurrNext = this.getMonths(weekFromTo)

        this.setState({
            date,
            week,
            weekYear,
            weekFromTo,
            month,
            schedule: this.setRacesSchedule(weekFromTo),
            selectedName: '',
            selectedPosition: -1,
            selectedPage: -1,
            toggle: false,
            race: undefined,
            raceDay: -1,
            raceQueue: -1,
        })
    }

    public getCurrentWeekView(): JSX.Element {
        return (
            <button
                disabled={this.state.currentDate.toISOString().substring(0,10) === this.state.date.toISOString().substring(0,10)}
                style={{ padding: '3px 10px' }}
                onClick={() => this.setCurrentWeek()}
                className="btn btn-default btn-sm mr0 dbl"
            >
                {Assets.Set + Assets._to_ + Assets.Current.toLowerCase() + ' ' + Assets.Week.toLowerCase()}
            </button>
        )
    }

    public getCurrentDayView(): JSX.Element {
        return (
            <button
                disabled={this.state.currentDate.toISOString().substring(0,10) === this.state.date.toISOString().substring(0,10)}
                style={{ padding: '3px 10px' }}
                onClick={() => this.setCurrentWeek()}
                className="btn btn-default btn-sm mr0 dbl"
            >
                {Assets.Set + Assets._to_ + Assets.Current.toLowerCase() + ' ' + Assets.Day.toLowerCase()}
            </button>
        )
    }

    public getHeaderPrizes(): JSX.Element {
        return (
            <tr>
                {this.state.race.prizes[0] ?
                <>
                    {this.state.race.prizes[0].firstRewarded !== undefined ?
                    <th style = {{padding: '0px 2px', fontSize: '12px'}} title = {Assets.FirstPositionRewarded}>
                        <FontAwesomeIcon icon = {faPlus}/>
                    </th> : void 0}

                    {this.state.race.prizes[0].lastRewarded !== undefined ?
                    <th style = {{padding: '0px 2px', fontSize: '12px'}} title = {Assets.LastPositionRewarded}>
                        <FontAwesomeIcon icon = {faMinus}/>
                    </th> : void 0}

                    {this.state.race.prizes[0].rewardId !== undefined ?
                    <th style = {{padding: '0px 2px', fontSize: '12px'}}>
                        <FontAwesomeIcon icon = {faGifts}/>&nbsp;{Assets.Reward}
                    </th> : void 0}

                    {this.state.race.prizes[0].firstRewarded !== undefined &&
                    this.state.race.prizes[0].lastRewarded !== undefined ?
                    <th style = {{padding: '0px 2px', fontSize: '12px'}} title = {Assets.Number + Assets._of_ + Assets.Rewards.toLowerCase()}>
                        <FontAwesomeIcon icon = {faHashtag}/>
                    </th> : void 0}
                </> : void 0}
            </tr>
        )
    }

    public getBodyPrizes(start: number): JSX.Element[] {
        const body: JSX.Element[] = []
        for (let i: number = start; i < start + 2; i++) {
            if (i === this.state.race.prizes.length) {break}
            if (this.state.race.prizes[i] === undefined) {break}
            body.push(
                <tr key = {i.toString()}>
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {this.state.race.prizes[i].firstRewarded !== undefined ? this.state.race.prizes[i].firstRewarded : ''}
                    </td>
                    
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {this.state.race.prizes[i].lastRewarded !== undefined ? this.state.race.prizes[i].lastRewarded : ''}
                    </td>

                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {this.state.race.prizes[i].rewardId !== undefined ? this.getReward(this.state.race.prizes[i].rewardId) : ''}
                    </td>

                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {this.state.race.prizes[i].firstRewarded !== undefined && this.state.race.prizes[i].lastRewarded !== undefined ? this.state.race.prizes[i].lastRewarded - this.state.race.prizes[i].firstRewarded + 1 : ''}
                    </td>
                </tr>
            )
        }
        return body
    }

    public getHeaderTL(): JSX.Element {
        // this.state.race.mechanics === LeaderboardTypes.HighestWager
        return (
            <tr>
                {Main.Instance.DatabaseDataState.tournamentResult[0] ?
                <>
                {Main.Instance.DatabaseDataState.tournamentResult[0].position !== undefined ?
                <th style = {{padding: '0px 2px', fontSize: '12px'}} title = {Assets.Position}>
                    <FontAwesomeIcon icon = {faHashtag}/>
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].username !== undefined ?
                <th style = {{padding: '0px 2px', fontSize: '12px'}}>
                    <FontAwesomeIcon icon = {faUser}/>&nbsp;{Assets.Username}
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].reward1 !== undefined ?
                <th style = {{padding: '0px 2px', fontSize: '12px'}}>
                    <FontAwesomeIcon icon = {faGifts}/>&nbsp;{Assets.Reward}
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].highestWager !== undefined ?
                <th
                    style = {{background: this.state.race.mechanics === LeaderboardTypes.HighestWager ? '#d4af37' : '', padding: '0px 2px', fontSize: '12px'}}
                    title = {Assets.Highest + ' ' + Assets.Wager.toLowerCase()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>&nbsp;HW
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].roundsPlayed !== undefined ?
                <th style = {{background: this.state.race.mechanics === LeaderboardTypes.RoundsPlayed ? '#d4af37' : '', padding: '0px 2px', fontSize: '12px'}} title = {Assets.Rounds + ' ' + Assets.Played.toLowerCase()}>
                    <FontAwesomeIcon icon = {faDice}/>&nbsp;RP
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].roundsWon !== undefined ?
                <th style = {{background: this.state.race.mechanics === LeaderboardTypes.RoundsWon ? '#d4af37' : '', padding: '0px 2px', fontSize: '12px'}} title = {Assets.Rounds + ' ' + Assets.Won.toLowerCase()}>
                    <FontAwesomeIcon icon = {faCheck}/>&nbsp;RW
                </th> : void 0}

                {Main.Instance.DatabaseDataState.tournamentResult[0].sumOfBets !== undefined ?
                <th style = {{background: this.state.race.mechanics === LeaderboardTypes.TotalWagered ? '#d4af37' : '', padding: '0px 2px', fontSize: '12px'}} title = {Assets.Sum + Assets._of_ + Assets.Bets.toLowerCase()}>
                    Σ&nbsp;SB
                </th> : void 0}
                </> : void 0}
            </tr>
        )
    }

    public getReward(rewardId: number): string {
        // console.log (rewardId)
        if (Main.Instance.DatabaseDataState.rewardShopStream) {
            for (const item of Main.Instance.DatabaseDataState.rewardShopStream) {
                if (item.id === rewardId) {
                    return item.Reward
                }
            }
        }
        return Assets.Unknown + ' ' + Assets.Reward.toLowerCase()
    }

    public getBodyTL(start: number): JSX.Element[] {
        const body: JSX.Element[] = []
        for (let i: number = start; i < start + 2; i++) {
            if (i === Main.Instance.DatabaseDataState.tournamentResult.length) {break}
            if (Main.Instance.DatabaseDataState.tournamentResult[i] === undefined) {break}
            const reward: boolean = Main.Instance.DatabaseDataState.tournamentResult[i].reward1 !== undefined
            
            body.push(
                <tr style = {{background: reward ? '#d4af37' : ''}} key = {i.toString()}>
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].position !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].position : ''}
                    </td>

                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].username !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].username : ''}
                    </td>

                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {reward ? this.getReward(Main.Instance.DatabaseDataState.tournamentResult[i].reward1) : Assets.No + ' ' + Assets.Reward.toLowerCase()}
                    </td>
                    
                    {Main.Instance.DatabaseDataState.tournamentResult[0].highestWager !== undefined ?
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].highestWager !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].highestWager : ''}
                    </td> : void 0}

                    {Main.Instance.DatabaseDataState.tournamentResult[0].roundsPlayed !== undefined ?
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].roundsPlayed !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].roundsPlayed : ''}
                    </td> : void 0}

                    {Main.Instance.DatabaseDataState.tournamentResult[0].roundsWon !== undefined ?
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].roundsWon !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].roundsWon : ''}
                    </td> : void 0}

                    {Main.Instance.DatabaseDataState.tournamentResult[0].sumOfBets !== undefined ?
                    <td style = {{padding: '0px 2px', fontSize: '12px'}}>
                        {Main.Instance.DatabaseDataState.tournamentResult[i].sumOfBets !== undefined ? Main.Instance.DatabaseDataState.tournamentResult[i].sumOfBets : ''}
                    </td> : void 0}
                </tr>
            )
        }
        return body
    }
    
    public getTournamentLeaderboard(): JSX.Element {
        return (
            <div className = "clearfix">
                {Main.Instance.DatabaseDataState.tournamentResult.length > 2 ?
                    <>
                    <table
                        style = {{marginBottom: '0px', width: 'auto', background: '#eee'}}
                        className="fr table table-bordered table-striped"
                    >
                        <thead>
                            {this.getHeaderTL()}
                        </thead>
                        <tbody>
                            {this.getBodyTL(2)}
                        </tbody>
                    </table>
                    <div
                        style = {{background: 'transparent'}}
                        className = "fr"
                    >
                        &nbsp;
                    </div>
                    </>
                : void 0}
                <table
                    style = {{marginBottom: '0px', width: 'auto', background: '#eee'}}
                    className="fr table table-bordered table-striped"
                >
                    <thead>
                        {this.getHeaderTL()}
                    </thead>
                    <tbody>
                        {this.getBodyTL(0)}
                    </tbody>
                </table>
            </div>
        )
    }

    public GetTournamentPrizes(): JSX.Element[] {
        const tp: JSX.Element[] = []
        for (let i: number = 0; i < this.state.race.prizes.length; i += 2) {
            if (i > 0) {
                tp.push (
                    <div
                        key = {i.toString()}
                        style = {{background: 'transparent'}}
                        className = "fl"
                    >
                        &nbsp;
                    </div>
                )
            }
            tp.push (
                <table
                    key = {(i+1).toString()}
                    style = {{marginBottom: '0px', width: 'auto', background: '#eee'}}
                    className="fl table table-bordered table-striped"
                >
                    <thead>
                        {this.getHeaderPrizes()}
                    </thead>
                    <tbody>
                        {this.getBodyPrizes(i)}
                    </tbody>
                </table>
            )
        }
        return tp
    }

    public getTournamentPrizes(): JSX.Element {
        // console.log (this.state.race.prizes)
        return (
            <div className = "clearfix">
                {this.GetTournamentPrizes()}
            </div>
        )
    }

    public getTournamentInfo(): JSX.Element {
        // console.log (Main.Instance.DatabaseDataState.tournamentResult)
        // console.log (this.state.race)
        return (
            <div style = {{position: 'absolute', right: '25px', top: '-1px', bottom: '-1px', padding: '0px'}}>
                {this.state.race.status === undefined ? this.state.race.prizes ? this.getTournamentPrizes() : void 0 : Main.Instance.DatabaseDataState.tournamentResult ? this.getTournamentLeaderboard() : void 0}
            </div>
        )
    }
    
    public downloadCSVRaces(Today: boolean) {
        const today: string = (new Date()).toISOString().substring(0, 10)
        const title: string = !this.state.dayView ? Assets.Races + ' ' + Assets.Schedule + ' (' + Assets_.UTC + ')' + Assets._for_ + Assets.Week.toLowerCase() + ' ' + Assets.Starting.toLowerCase() + Assets._on_ + this.state.weekFromTo.from + Assets._and_ + Assets.Ending.toLowerCase() + Assets._on_ + this.state.weekFromTo.to : Assets.Races + ' ' + Assets.Schedule + ' (' + Assets_.UTC + ')' + Assets._for_ + this.state.date.toISOString().substring(0, 10)
        let s: string = Assets.Name
        s += ',' + Assets.Day + Assets._of_ + Assets.Week.toLowerCase()
        s += ',' + Assets.Date
        s += ',' + Assets.Time
        s += ',' + Assets.Duration + Assets._in_ + Assets.Minutes.toLowerCase()
        s += ',' + Assets.Status
        s += ',' + Assets.Mechanics
        s += ',' + Assets.Games
        s += ',' + Assets.Prizes
        s += ',' + Assets.Prize + 1
        s += ',' + Assets.Prize + 2
        s += ',' + Assets.Prize + 3
        s += ',' + Assets.Prize + 4
        s += '\n'

        for (let j: number = 0; j < this.state.schedule.days.length; j++) {
            for (let i: number = 0; i < this.state.schedule.days[j].races.length; i++) {
                const date: Date = new Date(this.state.schedule.days[j].races[i].dayOfWeek === 0 ? this.state.weekFromTo.to : this.state.weekFromTo.from)
                if (this.state.schedule.days[j].races[i].dayOfWeek > 0) {
                    date.setUTCDate(date.getUTCDate() + this.state.schedule.days[j].races[i].dayOfWeek - 1)
                }
                if (!this.state.dayView && Today && date.toISOString().substring(0,10) !== today) {continue}
                if (this.state.dayView && Today && !this.isTodayReces(this.state.schedule.days[j].races[i].dayOfWeek)) {continue}
                date.setUTCHours(this.state.schedule.days[j].races[i].hour)
                date.setUTCMinutes(this.state.schedule.days[j].races[i].minute)
                date.setUTCSeconds(0)
                date.setUTCMilliseconds(0)
                const dates: string = date.toISOString().substring(0,10)
                const status: string = (this.state.schedule.days[j].races[i].status ? Assets.Ongoing : this.state.schedule.days[j].races[i].status === undefined ? Assets.Future : Assets.Past) + ' ' + Assets.Race.toLowerCase()
                const hour: string = this.addZeroToOneDigit(this.state.schedule.days[j].races[i].hour)
                const minute: string = this.addZeroToOneDigit(this.state.schedule.days[j].races[i].minute)
                const duration: string = this.state.schedule.days[j].races[i].durationMinutes.toString()
                const dayOfWeek: string = Assets.DaysInWeek[this.state.schedule.days[j].races[i].dayOfWeek]
                const mechanics: string = this.convertMechanics(this.state.schedule.days[j].races[i].mechanics)
                let games: string = ''
                if (this.state.schedule.days[j].races[i].validGames && this.state.schedule.days[j].races[i].validGames.length > 0) {
                    for (let k: number = 0; k < this.state.schedule.days[j].races[i].validGames.length; k++) {
                        if (k > 0) {
                            games += '|'
                        } else {
                            games += this.getGameName(this.state.schedule.days[j].races[i].validGames[k])
                        }
                    }
                } else {
                    games = Assets.All + ' ' + Assets.Games.toLowerCase()
                }
                const name: string = this.state.schedule.days[j].races[i].name
                // this.state.schedule.days[j].races[i]
                const time: string = hour + ':' + minute + ' ' + Assets_.UTC
                const prizes: string = this.state.schedule.days[j].races[i].prizes ? this.state.schedule.days[j].races[i].prizes.length.toString() : '0'
                const prize: string[] = [',',',',',',',']
                // console.log (this.state.schedule.days[j].races[i].prizes)
                if (this.state.schedule.days[j].races[i].prizes) {
                    for (let k: number = 0; k < this.state.schedule.days[j].races[i].prizes.length; k++) {
                        prize[k] += this.getReward(this.state.schedule.days[j].races[i].prizes[k].rewardId)
                    }
                }
                s += name + ',' + dayOfWeek + ',' + dates + ',' + time + ',' + duration + ',' + status + ',' + mechanics + ',' + games + ',' + prizes + prize[0] + prize[1] + prize[2] + prize[3] + '\n'
            }
        }

        // if (this.state.races)
        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = title + Assets_._csv
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));

    }
    public downloadCSV() {
        let s: string = ''
        // console.log (this.state.race)
        let raceName: string = this.state.race.name

        const date: Date = new Date(this.state.race.dayOfWeek === 0 ? this.state.weekFromTo.to : this.state.weekFromTo.from)
        if (this.state.race.dayOfWeek > 0) {
            date.setUTCDate(date.getUTCDate() + this.state.race.dayOfWeek - 1)
        }
        date.setUTCHours(this.state.race.hour)
        date.setUTCMinutes(this.state.race.minute)
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        raceName += Assets._on_ + Assets.DaysInWeek[this.state.race.dayOfWeek] + ' ' + date.toISOString().substring(0,10)
        // this.state.weekFromTo

        raceName += Assets._at_ + date.toISOString().substring(11,19).replace(':', '-').replace(':', '-') + ' ' + Assets_.UTC
        // this.addZeroToOneDigit(this.state.race.hour) + ':' + this.addZeroToOneDigit(this.state.race.minute)
        raceName += ' ' + this.convertMechanics(this.state.race.mechanics)
        s += Assets.Position

        if (Main.Instance.DatabaseDataState.tournamentResult[0].username !== undefined) {
            s += ',' + Assets.Username
        }
        
        if (Main.Instance.DatabaseDataState.tournamentResult[0].reward1 !== undefined) {
            s += ',' + Assets.Reward
        }

        if (Main.Instance.DatabaseDataState.tournamentResult[0].highestWager !== undefined) {
            s += ',' + Assets.Highest + ' ' + Assets.Wager.toLowerCase()
        }

        if (Main.Instance.DatabaseDataState.tournamentResult[0].roundsPlayed !== undefined) {
            s += ',' + Assets.Rounds + ' ' + Assets.Played.toLowerCase()
        }

        if (Main.Instance.DatabaseDataState.tournamentResult[0].roundsWon !== undefined) {
            s += ',' + Assets.Rounds + ' ' + Assets.Won.toLowerCase()
        }

        if (Main.Instance.DatabaseDataState.tournamentResult[0].sumOfBets !== undefined) {
            s += ',' + Assets.Sum + Assets._of_ + Assets.Bets.toLowerCase()
        }

        s += '\n'

        for (const item of Main.Instance.DatabaseDataState.tournamentResult) {
            s += item.position
            if (Main.Instance.DatabaseDataState.tournamentResult[0].username !== undefined) {
                s += ',' + item.username
            }
            if (Main.Instance.DatabaseDataState.tournamentResult[0].reward1 !== undefined) {
                s += ',' + (item.reward1 ? this.getReward(item.reward1) : '')
            }
            if (Main.Instance.DatabaseDataState.tournamentResult[0].highestWager !== undefined) {
                s += ',' + item.highestWager
            }
            if (Main.Instance.DatabaseDataState.tournamentResult[0].roundsPlayed !== undefined) {
                s += ',' + item.roundsPlayed
            }
            if (Main.Instance.DatabaseDataState.tournamentResult[0].roundsWon !== undefined) {
                s += ',' + item.roundsWon
            }
            if (Main.Instance.DatabaseDataState.tournamentResult[0].sumOfBets !== undefined) {
                s += ',' + item.sumOfBets
            }
            s += '\n'
        }

        const element = document.createElement('a')
        const file = new Blob([s], {type: 'text/csv'})
        element.href = URL.createObjectURL(file)
        element.download = Assets.Leaderboard + Assets._of_ + Assets.Race + ' ' + raceName + Assets_._csv
        // element.click()
        element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    }

    public getRacesScheduleControl(): JSX.Element {
        const g: {description: string, name: string} = this.showSelectedGames(this.state.race && this.state.race.validGames && this.state.race.validGames.length > 0 ? this.state.race.validGames : undefined, this.state.race && this.state.race.invalidGames && this.state.race.invalidGames.length > 0 ? this.state.race.invalidGames : undefined)
        // console.log (g)
        return (
            <div className="pr" style={{ height: '75px'}}>
                {this.state.race ?
                    <div style={{background: this.state.race.status ? '#D6E6F4' : this.state.race.status === undefined ? '#DBEFDB' : '#F6D5D4', padding: '5px', border: '1px solid #999', position: 'absolute', left: '0px', top: '0px', height: '66px', width: 'calc(100% - 480px)' }}>
                        <div className="pr" style = {{margin: 'auto',width: '100%', height: '100%'}}>
                            <button
                                style={{ position: 'absolute', top: '-3px', right: '2px' }}
                                title={Assets.Close + ' ' + Assets.Tournament.toLowerCase()}
                                type="button"
                                className="btn btn-default btn-sm btn-anything"
                                onClick={() => this.setState({ race: undefined, raceDay: -1, raceQueue: -1, toggle: false })}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div style = {{fontSize: '16px', lineHeight: '18px'}}>
                                {Assets.Start + ' ' + Assets.Time.toLowerCase()}: {this.addZeroToOneDigit(this.state.race.hour)}:{this.addZeroToOneDigit(this.state.race.minute)} {Assets_.UTC}, {Assets.Duration}: {this.state.race.durationMinutes}′
                            </div>
                            <div style = {{fontSize: '20px', lineHeight: '22px'}}>
                                {this.state.race.name}
                                {Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult.length === 0 ?
                                    <small>{' (' + Assets.NoResults + ')'}</small>
                                : void 0}
                            </div>
                            <div className = "clearfix">
                                <div style = {{fontSize: '14px', lineHeight: '16px'}} className="fl">
                                    {(this.state.race.status ? Assets.Ongoing : this.state.race.status === undefined ? Assets.Future : Assets.Past) + ' ' + Assets.Race.toLowerCase()}
                                    {Main.Instance.DatabaseDataState.tournamentResult ? ' - ' + Main.Instance.DatabaseDataState.tournamentResult.length + ' ' + Assets.players_ : ''}
                                    {this.state.race.mechanics ? <strong>{' (' + this.convertMechanics(this.state.race.mechanics) + ')'}</strong> : void 0}
                                </div>
                                <div style = {{fontSize: '14px', lineHeight: '16px'}} className="fr">
                                    <FontAwesomeIcon title = {g.description} icon = {g.name.length === 0 ? faAsterisk : faGamepad}/>
                                </div>
                            </div>
                            {Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult.length > 0 ?
                            <>
                                <a
                                    title = {Assets.Download + ' ' + Assets_.CSV + Assets._of_ + Assets.Tournament + ' ' + Assets.Results}
                                    className = "btn btn-default btn-sm mr0"
                                    style = {{fontSize: this.state.dayView ? '18px' : '14px', border: '0px', padding: '0px', background: 'transparent', position: 'absolute', top: this.state.dayView ? '13px' : '11px', right: '0px'}}
                                    onClick = {() => this.downloadCSV()}
                                >
                                    {/*<FontAwesomeIcon icon = {faFileCsv}/>*/}
                                    {this.state.dayView ? <FontAwesomeIcon icon = {faFileCsv}/> : <i className = {Assets_.faLastButtonClass}/>}
                                    
                                </a>
                                {this.state.dayView ? void 0 :
                                <a
                                    title = {Assets.Toggle + ' ' + Assets.Tournament + ' ' + Assets.Results}
                                    className = "btn btn-default btn-sm mr0"
                                    style = {{fontSize: '14px', border: '0px', padding: '0px', background: 'transparent', position: 'absolute', top: '25px', right: '0px'}}
                                    onClick = {() => this.setState({
                                        toggle: !this.state.toggle,
                                        selectedName: '',
                                        selectedPosition: -1,
                                        selectedPage: 0,
                                    })}
                                >
                                    <FontAwesomeIcon icon = {this.state.toggle ? faToggleOn : faToggleOff}/>
                                </a>}
                            </>
                            : void 0}
                            {this.state.dayView ? void 0 : this.getTournamentInfo()}
                            {/*Main.Instance.DatabaseDataState.tournamentResult && Main.Instance.DatabaseDataState.tournamentResult.length === 0 ?
                                <div style = {{fontSize: '16px', fontWeight: 600, position: 'absolute', bottom: '15px', width: '100%', textAlign: 'center'}}>
                                    {Assets.NoResultsForTheRace}
                                </div>
                            : void 0*/}
                        </div>
                    </div>
                    : void 0}
                <div className="clearfix" style={{ position: 'absolute', top: '0px', right: '0px' }}>{this.getMonthsView()}</div>
                {this.state.dayView ? 
                <div>
                    <div className="clearfix" style={{ position: 'absolute', top: '40px', right: '0px' }}>{this.getDaysView()}</div>
                    <div title={Assets.Set + Assets._to_ + Assets.Day.toLowerCase() + ' ' + this.state.currentDate.toISOString().substring(0,10)} className="clearfix" style={{ position: 'absolute', top: '40px', right: '280px' }}>{this.getCurrentDayView()}</div>
                </div> :
                <div>
                    <div className="clearfix" style={{ position: 'absolute', top: '40px', right: '0px' }}>{this.getWeeksView()}</div>
                    {/* <div className="clearfix" style={{ position: 'absolute', top: '40px', right: '410px' }}>{this.getYearView()}</div> */}
                    <div title={Assets.Set + Assets._to_ + Assets.Week.toLowerCase() + Assets._from_ + this.state.currentWeekFromTo.from + Assets._to_ + this.state.currentWeekFromTo.to} className="clearfix" style={{ position: 'absolute', top: '40px', right: '270px' }}>{this.getCurrentWeekView()}</div>
                </div>
                }
                
            </div>
        )
    }

    public getAutocomplete(data) {
        const options: JSX.Element[] = []
        const edata: TournamentResult[] = Object.assign([], data)
        const nameAlphabetized = edata.sort(this.compareName)
        for (const item of nameAlphabetized) {
            options.push(<option key = {item.username}>{item.username}</option>)
        }
        return (
            <datalist id = "names">
                {options}
            </datalist>
        )
    }

    public compareName( a, b) {
        if ( a.username.toLowerCase() < b.username.toLowerCase() ) {
          return -1;
        }
        if ( a.username.toLowerCase() > b.username.toLowerCase() ) {
          return 1;
        }
        return 0;
    }

    public convertMechanics(mechanics: LeaderboardTypes): string {
        switch (mechanics) {
            case LeaderboardTypes.WinPercentage :
            return Assets.Win + ' %'
            case LeaderboardTypes.RoundsPlayed :
            return Assets.Rounds
            case LeaderboardTypes.TotalWagered :
            return Assets.Wager
            default:
            return injectSpaces(LeaderboardTypes[mechanics])
        }
    }

    public getGameName(gameid: number): string {
        if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                if (gameid === Item.Id) {
                    return Item.Name
                }
            }
        }
        return Assets.Unknown + ' ' + Assets.Name.toLowerCase()
    }

    public showSelectedGames(valid?, invalid?): {name: string, description: string} {
        let games: string = ''
        let name: string = ''
        // console.log(Main.Instance.DatabaseDataState.GamesStreamAll)
        if (valid) {
            games += Assets.SelectedGames + ': '
            if (valid.length > 10) {
                games += valid.length + ' ' + Assets.Games.toLowerCase()
            } else {
                for (const item of valid) {
                    if (games.length > (Assets.SelectedGames + ': ').length) {games += ', '}
                    if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                        for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                            if (item === Item.Id) {
                                games += Item.Name
                                name = Item.Name
                                break
                            }
                        }
                    }
                }
            }
        } else if (invalid) {
            games += capitalizeFirstLetter(Assets.excludedGames) + ': '
            for (const item of invalid) {
                if (games.length > (Assets.excludedGames + ': ').length) {games += ', '}
                if (Main.Instance.DatabaseDataState.GamesStreamAll) {
                    for (const Item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                        if (item === Item.Id) {
                            games += Item.Name
                            break
                        }
                    }
                }
            }
        } else {
            games += Assets.SelectedGames + ': ' + Assets.All
        }

        return {name, description: games}
    }

    public ShowSelectedGames(valid?, invalid?): boolean {
        if (valid || invalid) {
            return true
        }
        return false
    }

    public getRacesSchedule(): JSX.Element {
        return (
            <div>
                {this.getRacesScheduleControl()}
                {this.state.dayView ? this.getRacesScheduleTableByDay() : this.getRacesScheduleTable()}
            </div>
        )
    }

    public render() {
        if (this.state.Open !== Main.Instance.MenuState.Open) {
            // console.log('diferent')
            window.setTimeout(this.WindowChange, 1000)
        }
        if (this.state.loaded === false && Main.Instance.DatabaseDataState.tournamentStream) {
            const date: Date = new Date()
            const currentDate: Date = new Date()
            const week: number = this.getWeek()
            const weekYear: number = this.getWeekYear()
            const weekFromTo: FromTo = this.getDateRangeOfWeek(week, weekYear)
            const month: PrevCurrNext = this.getMonths(weekFromTo)
            const schedule = this.setRacesSchedule(weekFromTo)

            this.setState({
                loaded: true,
                date,
                currentDate,
                week,
                weekYear,
                weekFromTo,
                month,
                schedule
            })
        }
        return (
            <div>
                {this.getRacesSchedule()}
            </div>
        )
    }
}