import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {isUserContractualPartner,
        isUserOperator,
        isUserSiteOwner,
        isUserSystem, 
        isUserAdmin,
        isUserMaster,
        isUserSubEntity,
        isContractualPartnerOperator,
        IsBuildScandibet,
        gotoTop,
        // numberWithCommas,
        // IsBuildJackpotsCH,
        IsBuildOddyzzey} from '../../../Logic/Utils' 
import {FormInput, FormSelect, FormInputArea, FormInputNumber, RadioButton3} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import Socket from '../../../Socket/socket-init'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

function toggle(which: number) {
    if (which === 0) {
        Logic.Type.New (Main.Instance.CRMLevelsState, {show_total: !Main.Instance.CRMLevelsState.show_total})
    } else if (which === 1) {
        Logic.Type.New (Main.Instance.CRMLevelsState, {show_totalp: !Main.Instance.CRMLevelsState.show_totalp})
    } else if (which === 2) {
        Logic.Type.New (Main.Instance.CRMLevelsState, {show_cumul: !Main.Instance.CRMLevelsState.show_cumul})
    } else if (which === 3) {
        Logic.Type.New (Main.Instance.CRMLevelsState, {show_cumulp: !Main.Instance.CRMLevelsState.show_cumulp})
    }
}

function Toggle(updateValue:boolean) {
    if (updateValue) {
        Logic.Type.New (Main.Instance.CRMLevelsState, {toggle: !Main.Instance.CRMLevelsState.toggle})
    }
}

function getButtons(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    if (Main.Instance.CRMLevelsState.ticket !== undefined && Main.Instance.CRMLevelsState.ticket['tokens'] !== undefined && Main.Instance.CRMLevelsState.ticket['tokens'].length > 0) {
        buttons.push(<span style = {{fontWeight: 700}}>Token distribution:&nbsp;</span>)
        const Tokens: any = Main.Instance.CRMLevelsState.ticket as any
        const tokens: any[] = Tokens.tokens // .toString().replace('{','').replace('}','').split(',')
        const style: any = {padding: '1px 3px', margin: '3px 0px 5px 3px', borderRadius: '0px'}
        const h: number = parseInt(Main.Instance.CRMLevelsState.hierarchy) - 1
        for (let i: number = 0; i < tokens.length; i++) {
            let className: string = 'btn-default'
            if (i + 1 === Main.Instance.CRMLevelsState.token) { className = 'btn-success' } else if ((i + 1) % 100 === 0) { className = 'btn-danger' } else if ((i + 1) % 10 === 0) { className = 'btn-warning' } else if (+tokens[i] !== 5) { className = 'btn-primary' }

            buttons.push (
                <button onClick = {() => changeToken(i + 1)} title = {'Sublevel #' + (h * 100 + i + 1).toString()} style = {style} className = {'btn ' + className} key = {(i + 1).toString()}>
                    {tokens[i]}
                </button>,
            )
        }
    }
    return buttons
}

function changeToken(token: number) {
    Logic.Type.New (Main.Instance.CRMLevelsState, {token})
}

function setToken(token: number, text: string) {
    const tokens: number[] = Main.Instance.CRMLevelsState.ticket['tokens']
    tokens[token - 1] = +text
    const ticket: any = {tokens}
    Logic.Type.New (Main.Instance.CRMLevelsState, {ticket})
}

function getToken(token: number): number {
    return Main.Instance.CRMLevelsState.ticket['tokens'][token - 1]
}

function clearFields() {
    Logic.Type.New(Main.Instance.CRMLevelsState, {
        name: '',
        description: '',
        id: '',
        mode: false,
        toggle: false,
        hierarchy:'0',
        prte:'0',
        sublevels:'0',
        level_value:'0',
        stagnation:'1000',
        level_id:'0',
        sublevel_min:'0',
        sublevel_max:'0',
        site_clone_id:'0',
        columns: 20,
        reward_every: 0,
        reward_id: '0',
        reward_final: '0',
        final_level: false,
        show_total: true,
        show_totalp: true,
        show_cumul: true,
        show_cumulp: true,
        copy_existing_site: false,
        isSubLevel:false,
        tokens: '0',
        token: 0,
        ticket: {tokens: []},
        consecutive_bonus: [],
        tokens_percentage: 0,
    })
}

export function getLevelsCount() {

    if (Main.Instance.CRMLevelsState.countCalled === false || IsBuildOddyzzey()) {

        Logic.Type.New(Main.Instance.CRMLevelsState, {countCalled: true})
        DbRoutes.getLevels()

        if (parseInt(Main.Instance.CRMLevelsState.site_id) === 0) {
            if ((isUserOperator() || isUserSiteOwner())) {
                DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
            }
        }
    }
} 

function getLevels() {
    if (IsBuildOddyzzey()) { Logic.Type.New(Main.Instance.DatabaseDataState, {levelsStream : undefined}) }
    DbRoutes.getLevels()
    // Logic.Type.New(Main.Instance.DatabaseDataState, {levelsStream : undefined})
} 

function gotoInsertMode() {

    if (!IsBuildOddyzzey() &&
    Main.Instance.CRMLevelsState.description === Main.Instance.CRMLevelsState.description_saved 
    && Main.Instance.CRMLevelsState.name === Main.Instance.CRMLevelsState.name_saved 
    && parseInt(Main.Instance.CRMLevelsState.hierarchy) === parseInt(Main.Instance.CRMLevelsState.hierarchy_saved)
    && parseInt(Main.Instance.CRMLevelsState.prte) === parseInt(Main.Instance.CRMLevelsState.prte_saved)
    && parseInt(Main.Instance.CRMLevelsState.sublevels) === parseInt(Main.Instance.CRMLevelsState.sublevels_saved)
    && parseInt(Main.Instance.CRMLevelsState.level_value) === parseInt(Main.Instance.CRMLevelsState.level_value_saved)
    && parseInt(Main.Instance.CRMLevelsState.stagnation) === parseInt(Main.Instance.CRMLevelsState.stagnation_saved)
    && Main.Instance.CRMLevelsState.columns === Main.Instance.CRMLevelsState.columns_saved

    && Main.Instance.CRMLevelsState.reward_every === Main.Instance.CRMLevelsState.reward_every_saved
    && parseInt(Main.Instance.CRMLevelsState.reward_id) === parseInt(Main.Instance.CRMLevelsState.reward_id_saved)
    && parseInt(Main.Instance.CRMLevelsState.reward_final) === parseInt(Main.Instance.CRMLevelsState.reward_final_saved)
    // && parseInt(Main.Instance.CRMLevelsState.level) === parseInt(Main.Instance.CRMLevelsState.level_saved)
    // && parseInt(Main.Instance.CRMLevelsState.level_id) === parseInt(Main.Instance.CRMLevelsState.level_id_saved)
    && parseInt(Main.Instance.CRMLevelsState.sublevel_min) === parseInt(Main.Instance.CRMLevelsState.sublevel_min_saved)
    && parseInt(Main.Instance.CRMLevelsState.sublevel_max) === parseInt(Main.Instance.CRMLevelsState.sublevel_max_saved)
    && Main.Instance.CRMLevelsState.final_level === Main.Instance.CRMLevelsState.final_level_saved 
    && Main.Instance.CRMLevelsState.ticket === Main.Instance.CRMLevelsState.ticket_saved ||
    IsBuildOddyzzey() &&
    Main.Instance.CRMLevelsState.description === Main.Instance.CRMLevelsState.description_saved 
    && Main.Instance.CRMLevelsState.name === Main.Instance.CRMLevelsState.name_saved 
    && parseInt(Main.Instance.CRMLevelsState.hierarchy) === parseInt(Main.Instance.CRMLevelsState.hierarchy_saved)
    && parseInt(Main.Instance.CRMLevelsState.sublevels) === parseInt(Main.Instance.CRMLevelsState.sublevels_saved)
    && parseInt(Main.Instance.CRMLevelsState.level_value) === parseInt(Main.Instance.CRMLevelsState.level_value_saved)
    && Main.Instance.CRMLevelsState.reward_every === Main.Instance.CRMLevelsState.reward_every_saved
    && parseInt(Main.Instance.CRMLevelsState.reward_id) === parseInt(Main.Instance.CRMLevelsState.reward_id_saved)
    && parseInt(Main.Instance.CRMLevelsState.reward_final) === parseInt(Main.Instance.CRMLevelsState.reward_final_saved)
    && Main.Instance.CRMLevelsState.tokens_percentage === Main.Instance.CRMLevelsState.tokens_percentage_saved
    // consecutive_bonus_saved consecutive_bonus
    ) {

        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }
}

function getLevelHierarchy(): string {
    let result: string
    for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
    if (+item['id'] === +Main.Instance.CRMLevelsState.level_id) {result = item['hierarchy'].toString(); break}
    }
    return result ? result : '0'
}

function getCurrentSublevel(): string {
    for (const item of Main.Instance.DatabaseDataState.levelsAllStream) {
    if (item['id'] === +Main.Instance.CRMLevelsState.level_id) { return item['name'] }
    }
    return ''
}

function handleInsertUpdate() {
    let items = {}
    if (IsBuildOddyzzey()) {
        items = {
            id: !Main.Instance.CRMLevelsState.id ? 0 : +Main.Instance.CRMLevelsState.id,
            name: !Main.Instance.CRMLevelsState.name ? '' : Main.Instance.CRMLevelsState.name,
            hierarchy: !Main.Instance.CRMLevelsState.hierarchy ? 0 : +Main.Instance.CRMLevelsState.hierarchy,
            sublevels: !Main.Instance.CRMLevelsState.sublevels ? 0 : +Main.Instance.CRMLevelsState.sublevels,
            sublevelPoints: !Main.Instance.CRMLevelsState.level_value ? 0 : +Main.Instance.CRMLevelsState.level_value,
            rewardEvery: !Main.Instance.CRMLevelsState.reward_every ? 0 : Main.Instance.CRMLevelsState.reward_every,
            rewardId: !Main.Instance.CRMLevelsState.reward_id ? 0 : +Main.Instance.CRMLevelsState.reward_id,
            rewardFinal: !Main.Instance.CRMLevelsState.reward_final ? 0 : +Main.Instance.CRMLevelsState.reward_final,
            consecutiveBonus: Main.Instance.CRMLevelsState.consecutive_bonus,
            tokensPercentage: !Main.Instance.CRMLevelsState.tokens_percentage ? '0' : Main.Instance.CRMLevelsState.tokens_percentage.toString(),
            description:!Main.Instance.CRMLevelsState.description ? '' : Main.Instance.CRMLevelsState.description,
            custom: Main.Instance.CRMLevelsState.ticket,
        }
    } else {
        items = {
            name: !Main.Instance.CRMLevelsState.name ? '' : Main.Instance.CRMLevelsState.name,
            description:!Main.Instance.CRMLevelsState.description ? '' : Main.Instance.CRMLevelsState.description,
            id: !Main.Instance.CRMLevelsState.id ? '' : Main.Instance.CRMLevelsState.id,
            operator_id : Main.Instance.LoginState.entityId,
            custom: JSON.stringify(Main.Instance.CRMLevelsState.ticket),
            // custom_jsonb: Main.Instance.CRMLevelsState.ticket,
            hierarchy:  Main.Instance.CRMLevelsState.mode ? Main.Instance.CRMLevelsState.hierarchy : 
                        Main.Instance.CRMLevelsState.level_id ? getLevelHierarchy() : !Main.Instance.CRMLevelsState.hierarchy ? '0' : Main.Instance.CRMLevelsState.hierarchy,
            prte: !Main.Instance.CRMLevelsState.prte ? '0' : Main.Instance.CRMLevelsState.prte,
            sublevels: !Main.Instance.CRMLevelsState.sublevels ? '0' : Main.Instance.CRMLevelsState.sublevels,
            level_value: !Main.Instance.CRMLevelsState.isSubLevel ? '0' : !Main.Instance.CRMLevelsState.level_value ? '0' : Main.Instance.CRMLevelsState.level_value.split(' ')[0],
            stagnation: !Main.Instance.CRMLevelsState.stagnation ? '1000' : Main.Instance.CRMLevelsState.stagnation,
            columns: !Main.Instance.CRMLevelsState.columns ? '10' : Main.Instance.CRMLevelsState.columns.toString(),
            reward_every: !Main.Instance.CRMLevelsState.reward_every ? '0' : 
            Main.Instance.CRMLevelsState.reward_every.toString(),
            reward_id: !Main.Instance.CRMLevelsState.reward_id ? '0' : Main.Instance.CRMLevelsState.reward_id,
            reward_final: !Main.Instance.CRMLevelsState.reward_final ? '0' : Main.Instance.CRMLevelsState.reward_final,
            final_level: Main.Instance.CRMLevelsState.final_level,
            show_total: Main.Instance.CRMLevelsState.show_total,
            show_totalp: Main.Instance.CRMLevelsState.show_totalp,
            show_cumul: Main.Instance.CRMLevelsState.show_cumul,
            show_cumulp: Main.Instance.CRMLevelsState.show_cumulp,
            site_id :Main.Instance.CRMLevelsState.site_id,
            holding_id :'0',
            level_id: Main.Instance.CRMLevelsState.isSubLevel ? Main.Instance.CRMLevelsState.level_id : Main.Instance.CRMLevelsState.id,
            sublevel_min: Main.Instance.CRMLevelsState.sublevel_min,
            sublevel_max: Main.Instance.CRMLevelsState.sublevel_max,
        }
    }

    if (Main.Instance.CRMLevelsState.mode === false) {
        if (IsBuildOddyzzey()) { handleInsertUpdate2(items) } else if (parseInt(Main.Instance.CRMLevelsState.site_id) === 0) {
            alert (Assets.pleaseSelectGamingSite)
            return
            }
        if (!IsBuildOddyzzey()) { DbRoutes.getParentsFromSite(items['site_id'], handleInsertUpdate2, items) }
    } else {
        handleInsertUpdate2(items)
    }

}

export function handleInsertUpdate2(items:any) {

    if (items.name.length === 0) {
        alert (Assets.compulsoryLevel)
        return
        }

    if (Main.Instance.CRMLevelsState.mode === false) {
        // INSERT MODE
        // console.log ('insertLevel')
        DbRoutes.insertLevel(items, InsertLastPage)
    } else {
        // UPDATE MODE
        // console.log ('updateLevel')
        // console.log (items)
        DbRoutes.updateLevel(items, Update)
    }
}

function handleDelete(row: any) {
    let level: string = row.original.sublevel
    let sublevel: string = 'level'
    if (IsBuildOddyzzey()) { level = row.original.name } else {
        if (row.original.level === row.original.sublevel) { level = row.original.level }
        sublevel = 'sublevel'
        if (row.original.level === row.original.sublevel) { level = Assets.level }
    }
    if (confirm(Assets.sureToDelete + ' ' + sublevel + ' "' + level + '"?')) {

        if  (Main.Instance.CRMLevelsState.pNum ===
            (Math.trunc((Main.Instance.CRMLevelsState.count - 2) / Main.Instance.CRMLevelsState.pSize) + 1)) {
                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
            DbRoutes.removeLevel(row.original.id, DeleteLastPage)
        } else {
            DbRoutes.removeLevel(row.original.id, DeleteNotLastPage)
        }
    }
}

export function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CRMLevelsState, {count:Main.Instance.CRMLevelsState.count + 1})
    LastPage()
}

function DeleteLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CRMLevelsState, {count:Main.Instance.CRMLevelsState.count - 1})
    LastPage()
}

function DeleteNotLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CRMLevelsState, {count:Main.Instance.CRMLevelsState.count - 1})
    getLevels()
}

export function Update() {
    clearFields()
    getLevels()
}

async function handleEdit(row: any) {
    gotoTop()
    if (IsBuildOddyzzey()) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {
            mode: true,
            isSubLevel: false,
            id: row.original.id,
            name:row.original.name,
            name_saved:row.original.name,
            description: row.original.description,
            description_saved: row.original.description,
            hierarchy:row.original.hierarchy,
            hierarchy_saved:row.original.hierarchy,
            sublevels:row.original.sublevels,
            sublevels_saved:row.original.sublevels,
            level_value:row.original.sublevelPoints,
            level_value_saved:row.original.sublevelPoints,
            reward_every:row.original.rewardEvery,
            reward_every_saved:row.original.rewardEvery,
            reward_id:row.original.rewardId.toString(),
            reward_id_saved:row.original.rewardId.toString(),
            reward_final:row.original.rewardFinal.toString(),
            reward_final_saved:row.original.rewardFinal.toString(),
            consecutive_bonus:JSON.parse(JSON.stringify(row.original.consecutiveBonus)),
            consecutive_bonus_saved:JSON.parse(JSON.stringify(row.original.consecutiveBonus)),
            tokens_percentage:+row.original.tokensPercentage,
            tokens_percentage_saved:+row.original.tokensPercentage,
        })
    } else {
        // console.log ('handleEdit')
        /*let a = */
        await DbRoutes.getLevelsAllBySite(0, row.original.site_id, false)
        // console.log (a)
        let isSubLevel:boolean
        const level_id:string = row.original.level_id
        let level_name:string = row.original.sublevel
        if (row.original.id === row.original.level_id) {
            isSubLevel = false
            level_name = row.original.level
        } else { isSubLevel = true }

        Logic.Type.New(Main.Instance.CRMLevelsState, {
            mode:true,
            isSubLevel,
            name: level_name,
            name_saved: level_name,
            level_id,
            level_id_saved:level_id,
            id: row.original.id.toString(),
            level:row.original.level,
            level_saved:row.original.level,
            description: row.original.description,
            description_saved: row.original.description,
            operator_id: row.original.operator_id.toString(),
            // site_name: rowData[4],
            hierarchy:row.original.hierarchy.toString(),
            hierarchy_saved:row.original.hierarchy.toString(),
            site_id:row.original.site_id.toString(),
            holding_id:row.original.master_id.toString(),
            // prte:row.original.prte.toString(),
            // prte_saved:row.original.prte.toString(),
            sublevels:row.original.sublevels,
            sublevels_saved:row.original.sublevels,
            level_value:row.original.value,
            level_value_saved:row.original.value,
            columns:row.original.columns,
            columns_saved:row.original.columns,
/*
            reward_every:parseInt(rowData[14]),
            reward_every_saved:parseInt(rowData[14]),
*/
            reward_id:row.original.reward_id,
            reward_id_saved:row.original.reward_id,
            // 16 is for image
            reward_final:row.original.reward_final.toString(),
            reward_final_saved:row.original.reward_final.toString(),
            show_total:row.original.show_total,
            show_totalp:row.original.show_totalp,
            show_cumul:row.original.show_cumul,
            show_cumulp:row.original.show_cumulp,
            final_level:row.original.final_level,
            final_level_saved:row.original.final_level,
            sublevel_min:row.original.sublevel_min,
            sublevel_min_saved:row.original.sublevel_min,
            sublevel_max:row.original.sublevel_max,
            sublevel_max_saved:row.original.sublevel_max,
            tokens: row.original.tokens,
            tokens_saved: row.original.tokens,
            ticket: row.original.ticket,
            ticket_saved: row.original.ticket,
            stagnation:row.original.stagnation ? row.original.stagnation.toString() : '1000',
            stagnation_saved:row.original.stagnation ? row.original.stagnation.toString() : '1000',

            copy_existing_site: false,
        })
    }
}

function FirstPage() {

    Logic.Type.New(Main.Instance.CRMLevelsState, {offset:(0).toString()})
    getLevels()
    Logic.Type.New(Main.Instance.CRMLevelsState, {pNum: 0})
}

function PrevPage() {

    if (Main.Instance.CRMLevelsState.pNum > 0) {
        
        Logic.Type.New(Main.Instance.CRMLevelsState, {offset: ((Main.Instance.CRMLevelsState.pNum - 1) *  
        Main.Instance.CRMLevelsState.pSize).toString()})
        getLevels()
        Logic.Type.New(Main.Instance.CRMLevelsState,{pNum: Main.Instance.CRMLevelsState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.CRMLevelsState.pNum + 1) * Main.Instance.CRMLevelsState.pSize < 
        Main.Instance.CRMLevelsState.count) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {
            offset:((Main.Instance.CRMLevelsState.pNum + 1) * Main.Instance.CRMLevelsState.pSize).toString()})
        getLevels()
        Logic.Type.New(Main.Instance.CRMLevelsState,{pNum: Main.Instance.CRMLevelsState.pNum + 1})
    } else {
        FirstPage()
    }

}

function LastPage() {

    if (Main.Instance.CRMLevelsState.count <= Main.Instance.CRMLevelsState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;
        if (Main.Instance.CRMLevelsState.count === 0) {

            Logic.Type.New(Main.Instance.CRMLevelsState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.CRMLevelsState.count - 1) / Main.Instance.CRMLevelsState.pSize)
            Logic.Type.New(Main.Instance.CRMLevelsState, {
                offset: (npNum * Main.Instance.CRMLevelsState.pSize).toString()})
        }                             

        Logic.Type.New(Main.Instance.CRMLevelsState,{pNum: npNum})
        getLevels()
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMLevelsState,{holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators()
        DbRoutes.getAllBrandOwners() 
        
        DbRoutes.getExtendedSitesAll()        
    } else {

        DbRoutes.getAllOperatorsByMaster(text)
        DbRoutes.getAllBrandOwnersByMaster(text)

        DbRoutes.getExtendedSitesAllByMaster(text)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMLevelsState,{operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState, {levelsAllStream:undefined, sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.CRMLevelsState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll()
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.CRMLevelsState.holding_id)
        }        
    }  else {
        if (isContractualPartnerOperator(text)) {
            DbRoutes.getAllSitesByOperator(text)
        } else {
            DbRoutes.getAllSitesBySiteOwner(text)
        }
    }
}

function SelectSite(stream: any[]) {
    // console.log (stream)
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {
                operator_id: Main.Instance.LoginState.entityId,
                site_id: stream[0]['id'],
        })
        DbRoutes.getLevelsAllBySite(0, stream[0]['id'], false)
        DbRoutes.getRewardShopItemsAll(stream[0]['id'])
        ApplyFilterSite(stream[0]['id'])
        getLevelsCount()
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMLevelsState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {levelsAllStream:undefined})
    } else {
        DbRoutes.getLevelsAllBySite(0, text, false)
        // DbRoutes.getRewardShopItemsAll(text)
        getLevelsCount()
    }
    ApplyFilterSite(text)
}

function ApplySiteCloneSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMLevelsState, {site_clone_id:text})
    DbRoutes.getLevelsAllBySite(0, text,false)
}

function ApplyLevelSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMLevelsState, {level_id:text})
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {isSubLevel:false})
    } else { Logic.Type.New(Main.Instance.CRMLevelsState, {isSubLevel:true}) }
}

function RecordsPerPageChanged(text:string) {

    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.CRMLevelsState,{pSize:parseInt(text)})
        FirstPage()
    }
}

function ColumnsChanged(text:string) {
    
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {columns:parseInt(text)})
    }
}

function RewardEveryChanged(text:string) {
    
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {reward_every:parseInt(text)})
    }
}

function HierarchyChanged(text:string) {

    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {hierarchy:text})  
    }
}

function SubLevelMinChanged(sublevel_min: string) {
    if (parseInt(sublevel_min) > 0) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {sublevel_min})  
    }
}

function SubLevelMaxChanged(sublevel_max:string) {
    if (parseInt(sublevel_max) > 0) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {sublevel_max})  
    }
}

function SubLevelsChanged(text:string) {

    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {sublevels:text})  
    }
}

function LevelValueChanged(text: string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {level_value: text + ' €'})  
    }
}

function OddyzzeyLevelValueChanged(text: string) {
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {level_value: text})  
    }
}

function StagnationChanged(text:string) {
    
    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {stagnation:text})  
    }
}

function PrteChanged(text:string) {

    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMLevelsState, {prte:text})  
    }
}

function cloneLevelStructure() {

    if (parseInt(Main.Instance.CRMLevelsState.site_id) === 0) {
        alert (Assets.cloneSiteSelectSource)
        return
    }

    if (parseInt(Main.Instance.CRMLevelsState.site_clone_id) === 0) {
        alert (Assets.cloneSiteSelectDestination)
        return
    }

    if (parseInt(Main.Instance.CRMLevelsState.site_id) === parseInt(Main.Instance.CRMLevelsState.site_clone_id) ) {
        alert (Assets.cloneSameSourceDestination)
        return
    }

    // everything is ok

    // get all levels from the source site
    DbRoutes.getAllLevelsSourceSite(cloneLevelStructure_callback)
    }

function cloneLevelStructure_callback() {

    if (Main.Instance.DatabaseDataState.tempStream.length === 0) {
        alert (Assets.noDataAtSiteSource)
        Logic.Type.New (Main.Instance.DatabaseDataState,{tempStream:undefined})
        return
    }

    DbRoutes.getParentsFromSite(Main.Instance.CRMLevelsState.site_clone_id, cloneLevelStructure_callback2)
}

async function cloneLevelStructure_callback2() {

    for (let i: number = 0; i < Main.Instance.DatabaseDataState.tempStream.length; i++) {

        interface Item1 {
            name:string
            description:string
            id:string
            operator_id:string
            custom:string,
            custom_jsonb:any
            hierarchy:string
            site_id:string
            holding_id:string
            level_id:string
        }

        const items:Item1 = { 
            id:Main.Instance.DatabaseDataState.tempStream[i]['id'],
            name: Main.Instance.DatabaseDataState.tempStream[i]['name'], 
            description:Main.Instance.DatabaseDataState.tempStream[i]['description'], 
            // operator_id:Main.Instance.DatabaseDataState.tempStream[i]['operator_id'],
            operator_id:Main.Instance.ReusableState.operator_id,
            custom:Main.Instance.DatabaseDataState.tempStream[i]['custom'],
            custom_jsonb:Main.Instance.DatabaseDataState.tempStream[i]['custom'],
            hierarchy:Main.Instance.DatabaseDataState.tempStream[i]['hierarchy'],
            site_id:Main.Instance.CRMLevelsState.site_clone_id,
            // holding_id:Main.Instance.DatabaseDataState.tempStream[i]['holding_id'],
            holding_id:Main.Instance.ReusableState.holding_id,
            level_id:'0',
        }

        await DbRoutes.insertCloneLevel(items, 
                                        insertCloneSubLevels,
                                        Main.Instance.DatabaseDataState.tempStream[i]['level_id'])
    }
}

async function lastPage() {
    await alert (Assets.cloneSiteSuccess)
    await LastPage()       
}

function insertCloneSubLevels(level_id:number, former_level_id:string) {
    DbRoutes.getAllSubLevelsSourceSite(insertCloneSubLevels2, level_id.toString(), former_level_id, lastPage)
}

async function insertCloneSubLevels2(level_id:string) {

    for (let i: number = 0; i < Main.Instance.DatabaseDataState.temp2Stream.length; i++) {

        interface Item2 {
            name:string
            description:string
            id:string
            custom:string,
            custom_jsonb:any
            hierarchy:string
            holding_id:string
            operator_id:string
            site_id:string
            level_id:string
        }

        const items:Item2 = { 
            id:Main.Instance.DatabaseDataState.temp2Stream[i]['id'],
            name: Main.Instance.DatabaseDataState.temp2Stream[i]['name'], 
            description:Main.Instance.DatabaseDataState.temp2Stream[i]['description'], 
            // operator_id:Main.Instance.DatabaseDataState.tempStream[i]['operator_id'],
            custom:Main.Instance.DatabaseDataState.temp2Stream[i]['custom'],
            custom_jsonb:Main.Instance.DatabaseDataState.temp2Stream[i]['custom'],
            hierarchy:Main.Instance.DatabaseDataState.temp2Stream[i]['hierarchy'],
            // holding_id:Main.Instance.DatabaseDataState.tempStream[i]['holding_id'],
            holding_id:Main.Instance.ReusableState.holding_id,
            operator_id:Main.Instance.ReusableState.operator_id,
            site_id:Main.Instance.CRMLevelsState.site_clone_id,
            level_id,
        }

        await DbRoutes.insertCloneSubLevel(items, lastPage)
    }
}

function ApplyFilterLevelID(text:string) {

    Logic.Type.New(Main.Instance.CRMLevelsState,{filter_level_id:text})
    FirstPage()
}

function ApplyFilterLevel(text:string) {
    
    Logic.Type.New(Main.Instance.CRMLevelsState, {filter_level:text})
    FirstPage()
}

function ApplyFilterSite(text:string) {


    Logic.Type.New(Main.Instance.CRMLevelsState, {filter_site_id: text, filter_level_id: '0'})

    if (parseInt(text) !== 0) {
        DbRoutes.getLevelsAllBySite(0, text, true, FirstPage)
    } else {
        DbRoutes.getLevelsAll(true, FirstPage)
    }
}

function SetCopyFromExistingSite( text:string) {

    if (text == 'true') {
        Logic.Type.New(Main.Instance.CRMLevelsState, {copy_existing_site: true})
    } else {
        Logic.Type.New(Main.Instance.CRMLevelsState, {copy_existing_site: false})
    }
}
/*
function EntityActive(): number {
    if (parseInt(Main.Instance.CRMLevelsState.site_id) !== 0) return 3
    if (parseInt(Main.Instance.CRMLevelsState.operator_id) !== 0) return 2
    if (parseInt(Main.Instance.CRMLevelsState.holding_id) !== 0) return 1
    return 0
}
*/
function ApplyRewardSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMLevelsState, {reward_id: text})
}

function ApplyRewardFinalSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMLevelsState, {reward_final: text})
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.levels SET final_level = ', rowData[0], rowData[26], getLevelsCount)
    }
}
*/
function SelectConsecutiveBonus(Prte: number) {
    Logic.Type.New(Main.Instance.CRMLevelsState, {prte: Prte.toString()})  
}

function AddConsecutiveBonus() {
    const consecutive_bonus = Object.assign({}, Main.Instance.CRMLevelsState.consecutive_bonus)
    if (consecutive_bonus['daysBonus'] === undefined) { consecutive_bonus['daysBonus'] = [] }
    consecutive_bonus['daysBonus'].push ({minDays: 0, bonus: 100})
    Logic.Type.New(Main.Instance.CRMLevelsState, {consecutive_bonus})
}

function RemoveConsecutiveBonus() {
    const consecutive_bonus = Object.assign({}, Main.Instance.CRMLevelsState.consecutive_bonus)
    consecutive_bonus['daysBonus'].splice (+Main.Instance.CRMLevelsState.prte - 1, 1)
    Logic.Type.New(Main.Instance.CRMLevelsState, {consecutive_bonus, prte: '0'})
}

function setMinimalBets(minimalBets: number) {
    const consecutive_bonus = Object.assign({}, Main.Instance.CRMLevelsState.consecutive_bonus)
    consecutive_bonus['minimalBets'] = minimalBets
    Logic.Type.New(Main.Instance.CRMLevelsState, {consecutive_bonus})
}

function getConsecutiveBonus(): JSX.Element[] {
    // console.log (Main.Instance.CRMLevelsState.consecutive_bonus)
    const cb: JSX.Element[] = []
    // key -4, -5
    cb.push (
       <span className = "fl dbl mr mt7">{Assets.Minimum + Assets.Bets.toLowerCase()}</span>, 
    )
    cb.push (
        <input
            className = "fl dbl mr mt2"
            style = {{width: '60px', paddingRight: '0px'}}
            key = "-5"
            type = "number" 
            value = {Main.Instance.CRMLevelsState.consecutive_bonus['minimalBets'] ? Main.Instance.CRMLevelsState.consecutive_bonus['minimalBets'].toString() : ''}
            onChange={(event) => setMinimalBets(+event.target.value)}
        />,
    )

    cb.push (
        <button
            title = {Assets.l24}
            key = "-1"
            onClick = {() => SelectConsecutiveBonus(0)}
            style = {{borderRadius: '0px'}}
            className = {+Main.Instance.CRMLevelsState.prte === 0 ? 'fl dbl btn btn-danger btn-sm mr0' : 'fl dbl btn btn-default btn-sm mr0'}
        >
            <i className = {Assets_.faManyItemsClass}/>
        </button>,
    )
    if (Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus']) {
    for (let i: number = 0; i < Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus'].length; i++) {
        if (Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus'][i]['minDays'] !== undefined && 
        Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus'][i]['bonus'] !== undefined) {
            cb.push (
                <button
                    title = {Assets.l23}
                    key = {i.toString()}
                    onClick = {() => SelectConsecutiveBonus(i + 1)}
                    style = {{borderRadius: '0px'}}
                    className = {+Main.Instance.CRMLevelsState.prte === i + 1 ? 'fl dbl btn btn-primary btn-sm mr0' : 'fl dbl btn btn-default btn-sm mr0'}
                >
                    {'Bcda ' + (i + 1).toString()}
                </button>,
            )
        }
    }
    }
    
    cb.push (
        <button
            title = {Assets.l22}
            key = "-2"
            onClick = {() => AddConsecutiveBonus()}
            style = {{borderRadius: '0px'}}
            className = "fl dbl btn btn-success btn-sm mr0"
        >
            <i className = {Assets_.faPlusClass}/>
        </button>,
    )
    if (+Main.Instance.CRMLevelsState.prte > 0) {
        cb.push (
            <button
                title = {Assets.l21}
                key = "-3"
                onClick = {() => RemoveConsecutiveBonus()}
                style = {{borderRadius: '0px'}}
                className = "fl dbl btn btn-danger btn-sm mr0"
            >
                <i className = {Assets_.faTrashLgButtonClass}/>
            </button>,
        )
    }
    return cb
}

function MinDaysChanged(text: string) {
    const consecutive_bonus = Object.assign({}, Main.Instance.CRMLevelsState.consecutive_bonus)
    consecutive_bonus['daysBonus'][+Main.Instance.CRMLevelsState.prte - 1].minDays = +text
    Logic.Type.New(Main.Instance.CRMLevelsState, {consecutive_bonus})
}

function BonusChanged(text: string) {
    const consecutive_bonus = Object.assign({}, Main.Instance.CRMLevelsState.consecutive_bonus)
    consecutive_bonus['daysBonus'][+Main.Instance.CRMLevelsState.prte - 1].bonus = +text
    Logic.Type.New(Main.Instance.CRMLevelsState, {consecutive_bonus})
}

function getSelectedConsecutiveBonus(): JSX.Element[] {
    const scb: JSX.Element[] = []
    scb.push (
        <FormInputNumber
            key = "0"
            description = {Assets.l20}
            outerDiv = "rows-per-table input-group fl ml pl0 mr"
            ClassName = "w60 h30"
            value = {Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus'][+Main.Instance.CRMLevelsState.prte - 1]['minDays']}
            type = "number"
            min = "1"
            icon = {Assets_.faCalendarRClass/*Assets_.faRequireClass*/}
            onChange = {(text) => MinDaysChanged(text)}
        />,
    )
    scb.push (<span key = "1" className = "fl dbl mr mt5">{Assets.days_}</span>)
    scb.push (
        <FormInputNumber
            key = "2"
            description = {Assets.l19}
            outerDiv = "rows-per-table input-group fl ml pl0 mr"
            ClassName = "w60 h30"
            value = {Main.Instance.CRMLevelsState.consecutive_bonus['daysBonus'][+Main.Instance.CRMLevelsState.prte - 1]['bonus']}
            type = "number"
            min = "100"
            icon = {Assets_.faPercentageClass}
            onChange = {(text) => BonusChanged(text)}
        />,
    )
    return scb
}

function TokensPercentageChanged(text: string) {
    Logic.Type.New(Main.Instance.CRMLevelsState, {tokens_percentage: parseInt(text)})
}

export function Levels() {
    if (Main.Instance.FrontendStoreState.first_time) {

        Logic.Type.New (Main.Instance.FrontendStoreState, {first_time: false})
        /*if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else*/
        if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }
    // console.log ('IsBuildOddyzzey():' + IsBuildOddyzzey())
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    // console.log ('websocket test')
    // console.log (Main.Instance.CRMLevelsState.level_id)
    // console.log (Main.Instance.DatabaseDataState.levelsStream)
    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSite
    const siteQ:string = Assets.SiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream &&
               Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
        // selectSite = Assets.SelectSite_
        // siteQ = Assets.SiteQ_
    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream2 &&
               Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ    
        // selectSite = Assets.SelectSite
        // siteQ = Assets.SiteQ
    }

    if (Main.Instance.DatabaseDataState.sitesOperatorAllStream && parseInt(Main.Instance.CRMLevelsState.site_id) > 0) {

        Main.Instance.DatabaseDataState.sitesOperatorAllStream.forEach((item:{id:string, name:string}) => {
            if (parseInt(item.id) === parseInt(Main.Instance.CRMLevelsState.site_id)) {
                if (Main.Instance.CRMLevelsState.site_name_filter !== item.name) {
                    Logic.Type.New (Main.Instance.CRMLevelsState,{site_name_filter:item.name})
                }
            }
        })
    }

    const columns:number = Main.Instance.CRMLevelsState.columns
    const stagnation:number = 1000
    const DIFF:number = 1/stagnation
    let sublevels_remainder = columns - parseInt(Main.Instance.CRMLevelsState.sublevels) % columns
    if (sublevels_remainder === columns) {
        sublevels_remainder = 0
    }
    const sublevels:number[] = []
    for (let i:number = 0; i < parseInt(Main.Instance.CRMLevelsState.sublevels); i++) {
        sublevels.push (0)
    }
    const sublevels_even:boolean = parseInt(Main.Instance.CRMLevelsState.sublevels) % 2 === 0 ? true : false
    // let sublevels_even:boolean = parseInt(Main.Instance.CRMLevelsState.sublevels) % 2 === 0 ? true : false
    // console.log ('sublevels_even:' + sublevels_even)
    if (sublevels_even === true) {
        for (let i:number = 0; i < parseInt(Main.Instance.CRMLevelsState.sublevels); i++) {
            const diff:number = i - parseInt(Main.Instance.CRMLevelsState.sublevels) / 2
            const stagDiff:number = parseInt(Main.Instance.CRMLevelsState.stagnation) - stagnation
            
            if (i >= parseInt(Main.Instance.CRMLevelsState.sublevels) / 2) {
                sublevels[i] = parseInt(Main.Instance.CRMLevelsState.level_value) + diff * DIFF * stagDiff
            } else {
                sublevels[i] = parseInt(Main.Instance.CRMLevelsState.level_value) + (diff + 1) * DIFF * stagDiff
            }
        }    
    } else {
        for (let i:number = 0; i < parseInt(Main.Instance.CRMLevelsState.sublevels); i++) {
            const diff:number = i - (parseInt(Main.Instance.CRMLevelsState.sublevels) - 1) / 2
            const stagDiff:number = parseInt(Main.Instance.CRMLevelsState.stagnation) - stagnation

            if (i > (parseInt(Main.Instance.CRMLevelsState.sublevels) - 1) / 2) {
                sublevels[i] = parseInt(Main.Instance.CRMLevelsState.level_value) + (diff - 1) * DIFF * stagDiff
                const Diff = parseInt(Main.Instance.CRMLevelsState.stagnation) - (stagnation + 1)
                if (i === (parseInt(Main.Instance.CRMLevelsState.sublevels) - 1) / 2) {
                    sublevels[i] += Diff * DIFF
                } else { sublevels[i] -= DIFF }

                sublevels[i] -=  - DIFF * 2 - Diff/stagnation

            } else {
                sublevels[i] = parseInt(Main.Instance.CRMLevelsState.level_value) + (diff) * DIFF * stagDiff
            }        
        }
    }

    const th:JSX.Element[] = []
    // th.push(<th key = "-1">{Assets.SubLevels}</th>)

    th.push(
        <th key = "-1">
            <FormInputNumber
                description = {Assets.columnsDesc}
                outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                ClassName = "w60 h26"
                value = {!Main.Instance.CRMLevelsState.columns ? '' : 
                Main.Instance.CRMLevelsState.columns.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => ColumnsChanged(text)}
            />
            {/*<span>{Assets.columns}</span>*/}
        </th>,
    )

    const tf:JSX.Element[] = []
    const tf0:JSX.Element[] = []
    const tb:JSX.Element[] = []
   
    for (let i:number = 0; i < columns; i++) {
        th.push(<th key = {i.toString()} className = "th-center">{((i+1) % 10).toString()}</th>)
    }

    let legend_columns: number = 0
    if (Main.Instance.CRMLevelsState.show_total === true) {
        legend_columns++
        th.push(<th style = {{background:'#eee'}} className = "th-center" key = "-2">{Assets.Total}</th>)
    }

    if (Main.Instance.CRMLevelsState.show_totalp === true) {
        legend_columns++
        th.push(
            <th
                style = {{background:'#eee'}}
                className = "th-center"
                key = "-3"
                title = {Assets.TotalP}
            >
                {Assets.TotalShortP}
            </th>)
    }

    if (Main.Instance.CRMLevelsState.show_cumul === true) {
        legend_columns++
        th.push(
            <th
                style = {{background:'#eee'}}
                className = "th-center"
                key = "-4"
                title = {Assets.Cumulative}
            >
                {Assets.CumulativeShort}
            </th>)
    }

    if (Main.Instance.CRMLevelsState.show_cumulp === true) {
        legend_columns++
        th.push(
            <th 
                style = {{background:'#eee'}} 
                className = "th-center" 
                key = "-5" 
                title = {Assets.CumulativeP}
            >
                {Assets.CumulativePShort}
            </th>)
    }

 
    // first we have to gather total
    let total: number = 0

    for (let i: number = 0; i < Math.ceil(parseInt(Main.Instance.CRMLevelsState.sublevels)/columns); i++) {

        for (let j:number = 0; j < columns; j++) {
            if (sublevels[i * columns + j]) {
                const value = sublevels[i * columns + j]
                total += value
            } else {
                break
            }
        }
    }

    let cumulative:number = 0
    // then we can construct the table
    for (let i:number = 0; i < Math.ceil(parseInt(Main.Instance.CRMLevelsState.sublevels) / columns); i++) {
        const row:JSX.Element[] = []
        let totalRow:number = 0
        if (i === Math.ceil(parseInt(Main.Instance.CRMLevelsState.sublevels) / columns) - 1) {
            row.push(
                <td key = "-1">
                    {Assets.Levels}&nbsp;&nbsp;
                    {(i * columns + 1).toString()} - {((i+1) * columns/* - 1*/ - sublevels_remainder).toString()}
                </td>,
            )
        } else {
            row.push(
                <td key = "-1">
                    {Assets.Levels}&nbsp;&nbsp;                    
                    {(i * columns + 1).toString()} - {((i + 1) * columns/* - 1*/).toString()}
                </td>)
        }

        for (let j:number = 0; j < columns; j++) {
            if (sublevels[i * columns + j]) {
                const value = sublevels[i * columns + j]
                totalRow += value
                row.push (<td className = "td-center" key = {j.toString()}>{value.toFixed(3)}</td>)
            } else {
                row.push (<td key = "-3" colSpan = {sublevels_remainder}/>)
                break
            }
        }

        cumulative += totalRow
        if (Main.Instance.CRMLevelsState.show_total === true) {
            row.push (<td style = {{background: '#eee'}} className = "td-center" key = "-2">
            {totalRow.toFixed(2)}</td>)
        }

        if (Main.Instance.CRMLevelsState.show_totalp === true) {
            row.push (<td style = {{background: '#eee'}} className = "td-center" key = "-3">
            {(totalRow/total*100).toFixed(2) + ' %'}</td>)
        }

        if (Main.Instance.CRMLevelsState.show_cumul === true) {
            row.push (<td style = {{background: '#eee'}} className = "td-center" key = "-4">
            {cumulative.toFixed(2)}</td>)
        }

        if (Main.Instance.CRMLevelsState.show_cumulp === true) {
            row.push (<td style = {{background: '#eee'}} className = "td-center" key = "-5">
            {(cumulative/total*100).toFixed(3) + ' %'}</td>)
        }
        tb.push(<tr key = {i.toString()}>{row}</tr>)
    }

    tf.push (
        <td key = "0">
            <span style = {{float:'left', display:'block', marginTop:'3px'}}>
                {Assets.RecurringReward}
            </span>
            <i 
                style = {
                    parseInt(Main.Instance.CRMLevelsState.reward_id) === 0 ? {
                        marginTop:'1px',
                        marginLeft:'0px',
                        fontSize:'24px',
                        color:'#d9534f',
                        float:'left',
                        display:'block',
                    } : {
                        marginTop:'1px',
                        marginLeft:'0px',
                        fontSize:'24px',
                        color: '#5cb85c',
                        float:'left',
                        display:'block',
                    }
                }
                className = {Assets_.faRewardClass}
            />            
        </td>)

    tf0.push (
        <td key = "0">
            <span style = {{float:'left', display:'block', marginTop:'3px'}}>
                {Assets.FinalReward}
            </span>
            <i 
                style = {
                    parseInt(Main.Instance.CRMLevelsState.reward_final) === 0 ? {
                        marginTop:'1px',
                        marginLeft:'0px',
                        fontSize:'24px',
                        color:'#d9534f',
                        float:'left',
                        display:'block',
                    } : {
                        marginTop:'1px',
                        marginLeft:'0px',
                        fontSize:'24px',
                        color: '#5cb85c',
                        float:'left',
                        display:'block',
                    }
                }

                className = {Assets_.faRewardClass}
            />            
        </td>)

    tf.push (
        <td colSpan = {columns + legend_columns} key = "1">
            <span style = {{marginRight:'10px'}}>{Assets.reward1}</span>
            <FormInputNumber
                description = {Assets.rewardDesc}
                // outerDiv = "rows-per-table input-group fl ml0 pl0 mr0"
                ClassName = "w60 h26"
                value = {!Main.Instance.CRMLevelsState.reward_every ? '0' : 
                Main.Instance.CRMLevelsState.reward_every.toString()}  
                type = "number" 
                min = "0"
                // icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RewardEveryChanged(text)}
            />

            <span style = {{marginLeft:'3px'}}>{Assets.reward2}</span>
            <FormSelect
                rewardFor = {2}
                span = {true}
                value = {!Main.Instance.CRMLevelsState.reward_id ? '0' : Main.Instance.CRMLevelsState.reward_id}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "control-panel-select control-panel-select-wide"
                o1 = {Assets.SelectReward}
                o2 = {Assets.RewardQ}
                onChange = {(text) => ApplyRewardSelect(text)}
            />            
            <span style = {{marginLeft:'10px', marginRight:'10px'}}>{Assets.reward3}</span>
        </td>,
    )

    tf0.push (
        <td colSpan = {columns} key = "1">
            <span>{Assets.reward4}</span>
            <FormSelect
                rewardFor = {2}
                span = {true}
                value = {!Main.Instance.CRMLevelsState.reward_final ? '0' : Main.Instance.CRMLevelsState.reward_final}
                data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                ClassName = "control-panel-select control-panel-select-wide"
                o1 = {Assets.SelectReward}
                o2 = {Assets.RewardQ}
                onChange = {(text) => ApplyRewardFinalSelect(text)}
            />            
            <span style = {{marginLeft:'10px'}}>{Assets.reward3}</span>
        </td>,
    )
/*    
    tf0.push (
        <td colSpan = {4} className = "td-center" key = "2">
            <i 
                style = {
                    parseInt(Main.Instance.CRMLevelsState.reward_final) === 0 ? 
                    {marginTop:'6px',marginLeft:'10px',fontSize:'15px',color:'#d9534f',float:'left',display:'block'} :
                    {marginTop:'6px',marginLeft:'10px',fontSize:'15px', color: '#5cb85c', float:'left', display:'block'}
                } 
                className = {Assets_.faCircleClass}
            />
            <span 
                style = {{marginLeft:'10px', float:'left', display:'block', marginTop:'2px'}}
            >
                {Assets.FinalRewardEnabled}
            </span>
        </td>
    )
*/    
    if (Main.Instance.CRMLevelsState.show_total === true) {
        tf0.push (<td style = {{background:'#ddd', fontSize:'17px'}} className = "td-center" key = "2">
            {total.toFixed(0)}</td>)
    }

    if (Main.Instance.CRMLevelsState.show_totalp === true) {
        tf0.push (<td style = {{background:'#ddd', fontSize:'17px'}} className = "td-center" key = "3">{'100 %'}</td>)
    }
    
    if (Main.Instance.CRMLevelsState.show_cumul === true) {
        tf0.push (<td style = {{background:'#ddd', fontSize:'17px'}} className = "td-center" key = "4">
        {cumulative.toFixed(0)}</td>)
    }
    
    if (Main.Instance.CRMLevelsState.show_cumulp === true) {
        tf0.push (<td style = {{background:'#ddd', fontSize:'17px'}} className = "td-center" key = "5">
        {(cumulative/total*100).toFixed(0) + ' %'}</td>)
    }

    const tableStagnation: JSX.Element =
        <table 
            style = {{background:'#f9f9f9'}}
            className = "table table-bordered table-responsive"
        >
            <thead>
                <tr>
                    {th}
                </tr>
            </thead>
            <tfoot>
                <tr>{tf0}</tr>
                <tr>{tf}</tr>
            </tfoot>
            <tbody>
                {tb}
            </tbody>
        </table>
    // console.log (Main.Instance.DatabaseDataState.levelsAllStream)
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    // console.log (Main.Instance.DatabaseDataState.rewardShopStreamAll)
    return (
        <div>

        {/*!Main.Instance.CRMLevelsState.isSubLevel ?
        isUserContractualPartner() || isUserGamingSite() ?
        isUserAdmin() ? 
        
        <h3 className = "level fl">
            {Assets.aeLevel}
            <span className = "label label-primary label-table fr">
                {Main.Instance.CRMLevelsState.name}
            </span>
        </h3>
        : 
        <h3 className = "level fl">
            {Assets.vLevel}
            <span className = "label label-primary label-table fr">
                {Main.Instance.CRMLevelsState.name}
            </span>
        </h3>
         :
         void 0
         :
         void 0*/}

        {/*Main.Instance.CRMLevelsState.isSubLevel ?
        isUserContractualPartner() || isUserGamingSite() ?
        isUserAdmin() ? 
        
        <h3 className = "level fl">
            {Assets.aeSubLevel}
            <span className = "label label-primary label-table fr">
                {Main.Instance.CRMLevelsState.name}
            </span>
        </h3>
        : 
        <h3 className = "level fl">
            {Assets.vSubLevel}
            <span className = "label label-primary label-table fr">
                {Main.Instance.CRMLevelsState.name}
            </span>
        </h3>
        
        : void 0
        : void 0*/}

        {(isUserSystem() || isUserMaster() || isUserContractualPartner() || isUserSubEntity()) ?
        !Main.Instance.CRMLevelsState.isSubLevel ?
        <h3 className = "level fl">{isUserAdmin() ? Assets.cLevel : Assets.vLevel} </h3> :
        <h3 className = "level fl">{isUserAdmin() ? Assets.cSubLevel : Assets.vSubLevel} </h3> : void 0}

        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.CRMLevelsState.holding_id ? '0' : Main.Instance.CRMLevelsState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {/*isUserMaster() ? 
        <span className = "label label-info fl label-table mr">
                {Main.Instance.LoginState.entity}
        </span>
        : void 0*/}

        {isUserSystem() || isUserMaster() ?
        !Main.Instance.DatabaseDataState.contractualPartnersAllStream ||
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length === 0 ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.CRMLevelsState.operator_id ? '0' : Main.Instance.CRMLevelsState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => {ApplyOperatorSelect(text)}}
            icon = {Assets_.faCompanyClass}
        />
        :
        !Main.Instance.DatabaseDataState.contractualPartnersAllStream2 ||
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length === 0 ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.CRMLevelsState.operator_id ? '0' : Main.Instance.CRMLevelsState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        :
        <FormSelect
            level = {true}
            value = {!Main.Instance.CRMLevelsState.operator_id ? '0' : Main.Instance.CRMLevelsState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {/*isUserContractualPartner() ?
        <span style = {{marginTop:'20px', paddingTop: '7.34px'}} className = "label label-primary fl label-table mr">
                {Main.Instance.LoginState.entity}
        </span>         
        : void 0*/}

        {isUserSystem() || isUserMaster() || isUserContractualPartner() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.CRMLevelsState.site_id ? '0' : Main.Instance.CRMLevelsState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"

            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        /> : void 0}
                        
        <div className = "clearfix"/>
        <br/>
        {/*isUserAdmin() ? */}
        <div className = "row admin-users-container">
            <div 
                className = {Main.Instance.CRMLevelsState.mode ?
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
            >
                <div className = "register-input">
                    <div className = "row admin-users-row">
                    {!Main.Instance.CRMLevelsState.isSubLevel ?
                    <h4>
                    {isUserAdmin() && Main.Instance.CRMLevelsState.mode ? 
                    Assets.EditLevel + ': ' +
                    Main.Instance.CRMLevelsState.name_saved/* + Assets._for_ + 
                    Main.Instance.CRMLevelsState.site_name*/
                    : void 0}
                    {isUserAdmin() && !Main.Instance.CRMLevelsState.mode && 
                    !Main.Instance.CRMLevelsState.copy_existing_site ?
                    parseInt(Main.Instance.CRMLevelsState.site_id) > 0 ? 
                    Assets.AddLevel + Assets._for_ +
                    Main.Instance.CRMLevelsState.site_name_filter : Assets.AddLevel
                    : void 0}

                    {isUserAdmin() && !Main.Instance.CRMLevelsState.mode && 
                    Main.Instance.CRMLevelsState.copy_existing_site ? 
                    parseInt(Main.Instance.CRMLevelsState.site_id) > 0 ? 
                    Assets.CopyLevelStructure + Assets._of_ + 
                    Main.Instance.CRMLevelsState.site_name_filter:Assets.CopyLevelStructure
                    : void 0}

                    {isUserAdmin() ? void 0 : Assets.ViewLevel}
                    </h4>
                    : void 0}

                    {Main.Instance.CRMLevelsState.isSubLevel ?
                    <h4>
                    {isUserAdmin() && Main.Instance.CRMLevelsState.mode ? 
                    Assets.EditSubLevel + ': ' + Main.Instance.CRMLevelsState.name
                    : void 0}
                    {isUserAdmin() && !Main.Instance.CRMLevelsState.mode ? Assets.AddSubLevel + Assets._for_ + 
                    getCurrentSublevel() + 
                    (parseInt(Main.Instance.CRMLevelsState.site_id) > 0 ? Assets._for_ + Main.Instance.CRMLevelsState.site_name_filter : '')
                    : void 0}
                    {isUserAdmin() ? void 0 : Assets.ViewSubLevel}
                    </h4>
                    : void 0}

                    {Main.Instance.CRMLevelsState.mode ?
                    <button 
                        title = {Assets.discardChanges}
                        onClick = {() => gotoInsertMode()}
                        style = {{border: '0px',background: 'transparent', position: 'absolute', right: '-8px', top: '-8px', marginRight: '0px'}}
                        className = "btn btn-default btn-sm btn-plus"
                    >
                        <i className = {Assets_.faCancelButtonClass}/>
                    </button>
                    : <span/>}

                    </div>

                    <div className = "row admin-users-row">
                        {Main.Instance.CRMLevelsState.isSubLevel && Main.Instance.CRMLevelsState.mode == true ?
                        <span className = "label label-table-info fl mr">
                            {Assets.Level + ': ' + Main.Instance.CRMLevelsState.level}
                        </span>
                        : void 0} 
                        {isUserSystem() && !Main.Instance.CRMLevelsState.mode ?
                        <RadioButton3
                            value = {!Main.Instance.CRMLevelsState.copy_existing_site ? false :
                                    Main.Instance.CRMLevelsState.copy_existing_site}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr0"
                            text = {Assets.CopyLevelStructureFromExistingSite} 
                            title = {Assets.CopyLevelStructureFromExistingSiteDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => SetCopyFromExistingSite(text)}
                        /> : void 0}
                        
                    {!Main.Instance.CRMLevelsState.copy_existing_site ?
                    <FormInput
                        description = {Assets.LevelName}
                        narrow = {true}
                        value = {!Main.Instance.CRMLevelsState.name ? '' : Main.Instance.CRMLevelsState.name}
                        ClassName = "form-control register-input"
                        type = "text"
                        onChange = {(text) => {Logic.Type.New(Main.Instance.CRMLevelsState, {name:text})}}
                        placeholder = {!Main.Instance.CRMLevelsState.isSubLevel ? Assets.LevelName : Assets.SubLevelName}
                        icon = {Main.Instance.CRMLevelsState.isSubLevel ? 
                                Assets_.faCogsClass : 
                                Assets_.faCogClass}
                    /> : void 0}
                    {IsBuildOddyzzey() ? void 0 :
                    <RadioButton3
                        value = {Main.Instance.CRMLevelsState.final_level === undefined ? false :
                                Main.Instance.CRMLevelsState.final_level}
                        text = {Assets.finalLevel}
                        ClassName = "input-group-radiobutton3a margin-bottom-sm fl mr" 
                        title = {Assets.FLDesc}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => { Logic.Type.New(Main.Instance.CRMLevelsState, {
                                                final_level:!Main.Instance.CRMLevelsState.final_level})}} 
                    />}

                    {(isUserSystem() || isUserMaster() || isUserContractualPartner() || isUserSubEntity()) && 
                    !Main.Instance.CRMLevelsState.mode && !Main.Instance.CRMLevelsState.copy_existing_site && !IsBuildOddyzzey() ?
                    <FormSelect
                        narrow = {true}
                        value = {Main.Instance.CRMLevelsState.level_id === undefined ? '0' : Main.Instance.CRMLevelsState.level_id}
                        data = {Main.Instance.DatabaseDataState.levelsAllStream}
                        ClassName = "form-control"

                        o1 = {Assets.SelectLevel}
                        o2 = {Assets.LevelQ}
                        onChange = {(text) => ApplyLevelSelect(text)}
                        icon = {/*Main.Instance.CRMLevelsState.isSubLevel ?
                                Assets_.faCogsClass :*/
                                Assets_.faCogClass}
                    /> : void 0}

                    {/*!Main.Instance.CRMLevelsState.copy_existing_site ?
                    <span className ="hierarchy">&nbsp;{Assets.Hierarchy}&nbsp;</span>
                    : void 0*/}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && !Main.Instance.CRMLevelsState.isSubLevel ?
                    <FormInputNumber
                        description = {Assets.Hierarchy} 
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {!Main.Instance.CRMLevelsState.hierarchy ? '0' : Main.Instance.CRMLevelsState.hierarchy}
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faHashtagClass}
                        onChange = {(text) => HierarchyChanged(text)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.isSubLevel ?
                    <FormInputNumber
                        description = {Assets.SubLevel + Assets.Minimum}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {Main.Instance.CRMLevelsState.sublevel_min === undefined ? '0' : Main.Instance.CRMLevelsState.sublevel_min}
                        type = "number" 
                        min = "1"
                        icon = {Assets_.faHashtagClass}
                        onChange = {(text) => SubLevelMinChanged(text)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.isSubLevel ?
                    <FormInputNumber
                        description = {Assets.SubLevel + Assets.Maximum}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {Main.Instance.CRMLevelsState.sublevel_max === undefined ? '0' : Main.Instance.CRMLevelsState.sublevel_max}
                        type = "number"
                        min = "1"
                        icon = {Assets_.faHashtagClass}
                        onChange = {(text) => SubLevelMaxChanged(text)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && !Main.Instance.CRMLevelsState.isSubLevel ?
                    <FormInputNumber
                        description = {'# ' + Assets.SubLevels} 
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {!Main.Instance.CRMLevelsState.sublevels ? '0' : Main.Instance.CRMLevelsState.sublevels}
                        type = "number"
                        min = "0"
                        icon = {Assets_.faCogsClass}
                        onChange = {(text) => SubLevelsChanged(text)}
                    /> : void 0}

                    {IsBuildScandibet() && Main.Instance.CRMLevelsState.token ?
                    <FormInputNumber
                        description = {Assets.l18 + ' #' + Main.Instance.CRMLevelsState.token.toString()}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {getToken(Main.Instance.CRMLevelsState.token).toString()}
                        type = "number" 
                        min = "0"
                        // icon = {Assets_.faActivityClass}
                        iconText = {'#' + Assets.Tokens.toLowerCase() + ' SbL ' + (100 * (parseInt(Main.Instance.CRMLevelsState.hierarchy) - 1) + Main.Instance.CRMLevelsState.token).toString()}
                        iconTextS = "14px"
                        onChange = {(text) => setToken(Main.Instance.CRMLevelsState.token, text)}
                    /> : void 0}

                    {!IsBuildScandibet() && !IsBuildOddyzzey() && !Main.Instance.CRMLevelsState.copy_existing_site ?
                    <FormInputNumber
                        description = {Assets.PrteDesc} 
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w80 h30"
                        value = {!Main.Instance.CRMLevelsState.prte ? '0' : Main.Instance.CRMLevelsState.prte}  
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faAchievementClass}
                        onChange = {(text) => PrteChanged(text)}
                    /> : void 0}

                    {IsBuildScandibet() && Main.Instance.CRMLevelsState.isSubLevel && !Main.Instance.CRMLevelsState.copy_existing_site ?
                    <FormInputNumber
                        description = {IsBuildScandibet() ? !Main.Instance.CRMLevelsState.isSubLevel ? Assets.SubLevelDefaultValue : Assets.SubLevelValue : Assets.SubLevelAverageValue} 
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w80 h30"
                        value = {!Main.Instance.CRMLevelsState.level_value ? '0' : Main.Instance.CRMLevelsState.level_value.split(' ')[0]}
                        type = "number"
                        min = "0"
                        icon = {Assets_.faPaymentClass}
                        onChange = {(text) => LevelValueChanged(text)}
                    /> : void 0}

                    {!IsBuildScandibet() && !IsBuildOddyzzey() && !Main.Instance.CRMLevelsState.copy_existing_site ?
                    <FormInputNumber
                        description = {Assets.Stagnation}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w70 h30"
                        value ={!Main.Instance.CRMLevelsState.stagnation ? '1000' : Main.Instance.CRMLevelsState.stagnation}
                        type = "number"
                        min = "0"
                        icon = {Assets_.faSelectClass}
                        onChange = {(text) => StagnationChanged(text)}
                    /> : void 0}

                    {IsBuildOddyzzey() ? 
                    <FormInputNumber
                        noIconTextFix = {true}
                        description = {Assets.l17}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {!Main.Instance.CRMLevelsState.level_value ? '0' : Main.Instance.CRMLevelsState.level_value}  
                        type = "number" 
                        min = "0"
                        // icon = {Assets_.faPaymentClass}
                        iconText = {'# ' + Assets.Pts_}
                        iconTextS = "14px"
                        onChange = {(text) => OddyzzeyLevelValueChanged(text)}
                    /> : void 0}

                    {!IsBuildScandibet() && !Main.Instance.CRMLevelsState.copy_existing_site && !Main.Instance.CRMLevelsState.isSubLevel && !Main.Instance.CRMLevelsState.final_level ?
                    <FormInputNumber
                        description = {Assets.rewardDesc}
                        outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                        ClassName = "w60 h30"
                        value = {!Main.Instance.CRMLevelsState.reward_every ? '0' : 
                        Main.Instance.CRMLevelsState.reward_every.toString()}  
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faFirstButtonClass}
                        onChange = {(text) => RewardEveryChanged(text)}
                    /> : void 0}

                    {IsBuildOddyzzey() ? 
                    <FormSelect
                        rewardFor = {2}
                        title = {Assets.l16}
                        value = {!Main.Instance.CRMLevelsState.reward_id ? '0' : Main.Instance.CRMLevelsState.reward_id}
                        data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                        ClassName = "form-control wi100"
                        o1 = {Assets.SelectReward}
                        o2 = {Assets.SelectReward}
                        onChange = {(text) => ApplyRewardSelect(text)}
                        icon = {Assets_.faRewardClass}
                    /> : void 0}
                    <div className = "fl">&nbsp;&nbsp;&nbsp;</div>
                    {IsBuildOddyzzey() ? 
                    <FormSelect
                        rewardFor = {2}
                        title = {Assets.l15}
                        value = {!Main.Instance.CRMLevelsState.reward_final ? '0' : Main.Instance.CRMLevelsState.reward_final}
                        data = {Main.Instance.DatabaseDataState.rewardShopStreamAll}
                        ClassName = "form-control wi100"
                        o1 = {Assets.SelectReward}
                        o2 = {Assets.SelectReward}
                        onChange = {(text) => ApplyRewardFinalSelect(text)}
                        icon = {Assets_.faRewardLgClass}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.toggle 
                    && Main.Instance.CRMLevelsState.mode ?
                    <RadioButton3
                            value = {Main.Instance.CRMLevelsState.show_total === undefined ? true :
                                    Main.Instance.CRMLevelsState.show_total}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fl mr"
                            text = {Assets.ShowTotals} 
                            title = {Assets.ShowTotalsDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => toggle(0)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.toggle 
                    && Main.Instance.CRMLevelsState.mode ?
                    <RadioButton3
                            value = {Main.Instance.CRMLevelsState.show_totalp === undefined ? true :
                                    Main.Instance.CRMLevelsState.show_totalp}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fl mr"
                            text = "" // {Assets.ShowTotalP} 
                            title = "" // {Assets.ShowTotalPDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => toggle(1)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.toggle 
                    && Main.Instance.CRMLevelsState.mode ?
                    <RadioButton3
                            value = {Main.Instance.CRMLevelsState.show_cumul === undefined ? true :
                                    Main.Instance.CRMLevelsState.show_cumul}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fl mr"
                            text = "" // {Assets.ShowCumul} 
                            title = "" // {Assets.ShowCumulDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => toggle(2)}
                    /> : void 0}

                    {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.toggle
                    && Main.Instance.CRMLevelsState.mode ?
                    <RadioButton3
                            value = {Main.Instance.CRMLevelsState.show_cumulp === undefined ? true :
                                    Main.Instance.CRMLevelsState.show_cumulp}
                            ClassName = "input-group-radiobutton3a margin-bottom-sm fl"
                            text = "" // {Assets.ShowCumulP} 
                            title = "" // {Assets.ShowCumulPDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => toggle(3)}
                    /> : void 0}

                    {!IsBuildScandibet() && !IsBuildOddyzzey()
                    && parseInt(Main.Instance.CRMLevelsState.sublevels) > 0
                    && parseInt(Main.Instance.CRMLevelsState.level_value) > 0
                    && parseInt(Main.Instance.CRMLevelsState.stagnation) > 0 ?                    
                    
                    <button 
                        style = {{
                            float:'right',
                            marginTop:'-2px',
                            marginRight:'0px',
                            paddingLeft:'0px',
                            paddingRight:'0px',
                        }}
                        title = {Assets.ToggleInformationAboutSubLevels}
                        className = {Main.Instance.CRMLevelsState.toggle ? 
                                    'toggle-button tb-green' : 'toggle-button tb-red'}
                        onClick = {() => Toggle(true)}
                    >
                        <i style = {{fontSize:'30px'}} className = {Assets_.faInfoCircleClass}/>
                    </button>                        
                    : 
                    void 0}
                    {!Main.Instance.CRMLevelsState.mode && isUserSystem() && 
                    Main.Instance.CRMLevelsState.copy_existing_site ?
                    <FormSelect
                            narrow = {true}
                            value = {!Main.Instance.CRMLevelsState.site_clone_id ? '0' : 
                                    Main.Instance.CRMLevelsState.site_clone_id}
                            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                            ClassName = "form-control"
                            o1 = {Assets.SelectSiteClone}
                            o2 = {Assets.SiteCloneQ}
                            onChange = {(text) => ApplySiteCloneSelect(text)}
                            icon = {Assets_.faSubEntityClass}
                    />
                    : void 0}
                </div>
                    
                {/*!Main.Instance.CRMLevelsState.copy_existing_site && !Main.Instance.CRMLevelsState.toggle ? 
                <div className = "row admin-users-row-area">
                    <div className = "register-input textarea">
                        <FormInputArea  
                            value = {!Main.Instance.CRMLevelsState.description ? '' :
                                    Main.Instance.CRMLevelsState.description}
                            ClassName = "form-control register-input"
                            onChange = {text => {
                                Logic.Type.New(Main.Instance.CRMLevelsState, {description:text})}
                            }
                            placeholder = {Assets.LevelDescriptionOptional}
                            icon = {Assets_.faCommentClass}
                        />
                    </div>
                </div>
                : void 0*/}
                {IsBuildOddyzzey() ?
                <div className = "row admin-users-row" style = {{marginBottom: '5px'}}> 
                    {getConsecutiveBonus()}
                    {Main.Instance.CRMLevelsState.prte !== undefined && +Main.Instance.CRMLevelsState.prte > 0 ? getSelectedConsecutiveBonus() : void 0}
                    <FormInputNumber
                        key = "0"
                        description = {Assets.TokensPercentage}
                        outerDiv = "rows-per-table input-group fr ml0 pl0 mr0"
                        ClassName = "w60 h30"
                        value = {Main.Instance.CRMLevelsState.tokens_percentage.toString()}
                        type = "number"
                        min = "1"
                        icon = {Assets_.faPercentageClass}
                        onChange = {(text) => TokensPercentageChanged(text)}
                    />                    
                </div>
                : void 0}
                
                {!Main.Instance.CRMLevelsState.copy_existing_site && Main.Instance.CRMLevelsState.toggle

                && parseInt(Main.Instance.CRMLevelsState.sublevels) > 0
                && parseInt(Main.Instance.CRMLevelsState.level_value) > 0
                && parseInt(Main.Instance.CRMLevelsState.stagnation) > 0                  

                ? 
                <div className = "row admin-users-row">
                    {tableStagnation}
                </div>
                : !Main.Instance.CRMLevelsState.copy_existing_site ?
                <div className = "row admin-users-row-area-small">
                    <div className = "register-input textarea-small">
                        <FormInputArea  
                            value = {!Main.Instance.CRMLevelsState.description ? '' :
                                    Main.Instance.CRMLevelsState.description}
                            ClassName = "form-control register-input"
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CRMLevelsState, {description:text})}
                            }
                            placeholder = {Assets.LevelDescriptionOptional}
                            icon = {Assets_.faCommentClass}
                        />
                    </div>
                </div>
                : void 0}

                <div className = "row admin-users-row">
                    {getButtons()}
                    {/*Main.Instance.CRMLevelsState.ticket.length > 2 ? getButtons() : void 0*/}
                    {!Main.Instance.CRMLevelsState.mode && Main.Instance.CRMLevelsState.copy_existing_site ?
                    <ButtonIcon 
                        description = {Assets.cloneLevelStructure}
                        click = {() => cloneLevelStructure()}
                        ClassName = "btn btn-primary btn-sm btn-plus fr"
                        icon = {Assets_.faCloneClass}
                        text = {Assets.Copy}
                    />
                    :
                    <ButtonIcon
                        disabled = {!isUserAdmin()}
                        description = { Main.Instance.CRMLevelsState.mode ? 
                                        Assets.saveChanges :
                                        Assets.cLevel}
                        click = {() => handleInsertUpdate()}
                        ClassName = {
                            Main.Instance.CRMLevelsState.mode ? 
                            'btn btn-warning btn-sm btn-plus fr mr' :
                            'btn btn-primary btn-sm btn-plus fr'}
                        Icon = {faSave as IconDefinition}
                        text = {Assets.Save}
                    />}
                </div>
            </div>
            </div>
    </div>
        {/*:void 0*/}

        <div className = "control-panel">

            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "w60 h26"
                value = {!Main.Instance.CRMLevelsState.pSize ? '' : 
                Main.Instance.CRMLevelsState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>

            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            {parseInt(Main.Instance.CRMLevelsState.site_id) === 0 ?
            <FormSelect 
                span = {true}
                title = {Assets.FilterSiteDesc}
                value = {!Main.Instance.CRMLevelsState.filter_site_id ? '0' : 
                        Main.Instance.CRMLevelsState.filter_site_id}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                o1 = {Assets.SelectSite}
                o2 = {Assets.SiteQ}
                ClassName = "control-panel-select-narrow"
                onChange = {(text) => ApplyFilterSite(text)}
            /> : void 0}
            {!IsBuildOddyzzey() ?
            <FormSelect 
                span = {true}
                title = {Assets.FilterLevelDesc}
                value = {!Main.Instance.CRMLevelsState.filter_level_id ? '0' : 
                        Main.Instance.CRMLevelsState.filter_level_id}
                data = {Main.Instance.DatabaseDataState.levelsAllFilterStream}
                o1 = {Assets.SelectLevel}
                o2 = {Assets.LevelQ}                        
                ClassName = "control-panel-select-narrow"
                onChange = {(text) => ApplyFilterLevelID(text)}
            /> : void 0}

            {!IsBuildOddyzzey() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CRMLevelsState.filter_level ? '0' : 
                        Main.Instance.CRMLevelsState.filter_level}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.ShowEverything}
                o2 = {Assets.parentOnly}
                o3 = {Assets.subOnly}
                onChange = {(text) => ApplyFilterLevel(text)}
            /> : void 0}

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.CRMLevelsState.count ? Main.Instance.CRMLevelsState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.CRMLevelsState.count ? Main.Instance.CRMLevelsState.pNum * 
                Main.Instance.CRMLevelsState.pSize + 1 : 0}
                {' - '}
                {Main.Instance.CRMLevelsState.count ? ((Main.Instance.CRMLevelsState.pNum + 1) * 
                Main.Instance.CRMLevelsState.pSize > Main.Instance.CRMLevelsState.count ? 
                Main.Instance.CRMLevelsState.count : 
                (Main.Instance.CRMLevelsState.pNum + 1) * Main.Instance.CRMLevelsState.pSize) : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.page}
                {Main.Instance.CRMLevelsState.count ? Main.Instance.CRMLevelsState.pNum + 1 : 0}{' / '}
                {Main.Instance.CRMLevelsState.count ? Math.trunc((Main.Instance.CRMLevelsState.count - 1)/
                Main.Instance.CRMLevelsState.pSize+1) : 0}
            </span>
        </div>
        {/*console.log (Main.Instance.DatabaseDataState.levelsStream)*/}
        <h3>{Assets.tLevels}</h3>
        {Main.Instance.DatabaseDataState.levelsStream ?
        IsBuildOddyzzey() ?
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.levelsStream}
            columns = {[
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-left'},
                {maxWidth: 150, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {minWidth: 100, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},
                {maxWidth: 60, Header: Assets.Level, accessor: 'hierarchy', Cell: (row) => <div style = {{textAlign: 'center'}}>{row.value}</div>},
                {maxWidth: 60, Header: <span title = {Assets.SubLevelsDesc}>{Assets.SubLevelsShort}</span>, accessor: 'sublevels', headerClassName: 'th-left'},
                {maxWidth: 50, Header: <span title = {Assets.SublevelPoints}>SLP</span>, accessor: 'sublevelPoints', headerClassName: 'th-left'},
                {maxWidth: 50, Header: <span title = {Assets.SublevelRewardInterval}>SLRI</span>, accessor: 'rewardEvery', headerClassName: 'th-left'},
                {maxWidth: 50, Header: <span title = {Assets.Reward_Interval}>RI</span>, accessor: 'rewardId', Cell: (row) => TableUtils.getRewardIcon(true, +row.value)},
                {maxWidth: 50, Header: <span title = {Assets.Reward_Final}>RF</span>, accessor: 'rewardFinal', Cell: (row) => TableUtils.getRewardIcon(false, +row.value)},
                {minWidth: 150, Header: Assets.BonusForConsecutiveDaysActivity, accessor: 'consecutiveBonus', headerClassName: 'th-left', Cell: (row) => TableUtils.getConsecutiveBonus(row.value)},
                {maxWidth: 80, Header: <span title = {Assets.TokensPercentage}>TP (%)</span>, accessor: 'tokensPercentage', headerClassName: 'th-left'},
                {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},

            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />
        :
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.levelsStream}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {maxWidth: 150, Header: Assets.Level, accessor: 'level', headerClassName: 'th-left'},
                {maxWidth: 150, Header: Assets.Sublevel, accessor: 'sublevel', headerClassName: 'th-left', Cell: (row) => TableUtils.getSubLevel(row)},
                {maxWidth: 40, Header: Assets.Min_, accessor: 'sublevel_min', headerClassName: 'th-left'},
                {maxWidth: 50, Header: Assets.Max_, accessor: 'sublevel_max', headerClassName: 'th-left'},
                {maxWidth: 150, Header: Assets.Description, accessor: 'description', headerClassName: 'th-left'},
                {minWidth: 40, Header: Assets.Level, accessor: 'hierarchy', Cell: (row) => <div className = "th-center">{row.value}</div>},
                {maxWidth: 70, Header: '#SL', accessor: 'sublevels', headerClassName: 'th-left'},
                // {minWidth: 70, Header: 'Wager', show: (row) => row !== undefined, accessor: 'sub_level_value', headerClassName: 'th-left', Cell: (row) => numberWithCommas(row.value)},
                {Header: Assets.Coins, accessor: 'tokens', headerClassName: 'th-left', Cell: (row) => TableUtils.getTokens(row)},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />
        : IsBuildOddyzzey() ? void 0 : getLevelsCount()}
        </div>
    )}