import SocketState from './socketstate'
import { Route } from './route-classes'
import {EventEmitter} from 'events'
import { v4 as uuidv4 } from 'uuid';

interface WSOptions {
    host: string
    port?: number
    wss?: boolean
}

export class Message {
    constructor(private input: object, private path: string, private meta: object) {
    }
    get toString() {
        return JSON.stringify({
          path: this.path,
          input: this.input,
          meta: this.meta
        })
    }
}
class WS {
    public onOpen: () => void
    public onError: () => void
    public onClose: () => void
    protected Socket: WebSocket
    private event: EventEmitter
    constructor(private options: WSOptions) {
        const address = `${options.wss ? 'wss' : 'ws'}://${options.host}${options.port ? ':' + options.port : ''}`
        // console.log(address)
        this.event = new EventEmitter()
        this.Socket = new WebSocket(address)
        this.Socket.addEventListener('open', () => { if (this.onOpen) {this.onOpen()}})
        this.Socket.addEventListener('error', () => { if (this.onError) {this.onError();} this.Socket.close()})
        this.Socket.addEventListener('close', () => { if (this.onClose) {this.onClose()}})
        this.Socket.addEventListener('message', (m) => {
            this.State = m.data
        })
    }
    public terminate() {this.Socket.close()/*; this.Socket = null*/}
    set State(input: string) {
        let parse: { path: string, input: object }
        try {
            parse = JSON.parse(input)
            if (SocketState.get(parse.path) &&
                parse.input !== undefined) {
                SocketState.set({ [parse.path]: Object.assign(new Route('loaded'), parse.input) })
                this.event.emit(parse.path, parse.input)
            }
        } catch (err) {
            console.log(err)
        }
    }
    public Send(path: string, input: object, cb?: any) {
        // console.log(this.Socket.readyState)
        if (this.Socket.readyState === this.Socket.CONNECTING || this.Socket.readyState === this.Socket.CLOSING) {
            // console.log('CONNECTING')
            return setTimeout(() => {this.Send(path, input, cb)},1000)
            
        }
        if (this.Socket.readyState === this.Socket.OPEN) {
            // console.log('OPEN')
            const m = new Message(input, path, {
              eventId: uuidv4()
            })
            const o = SocketState.get(path)
            o.loading = 'loading'
            SocketState.set({ [path]: o })
            // console.log(m)
            this.Socket.send(m.toString)
            if (cb) {this.event.once(path, cb)}
            return
        }
        if (this.Socket.readyState === this.Socket.CLOSED) {
            // console.log('CLOSED')
            const address = `${this.options.wss ? 'wss' : 'ws'}://${this.options.host}${this.options.port ? ':' + this.options.port : ''}`
            // console.log(address)
            this.Socket = new WebSocket(address)
            this.Socket.addEventListener('open', () => {
                if (this.onOpen) {this.onOpen()}
                const m = new Message(input, path, {
                  eventId: uuidv4()
                })
                const o = SocketState.get(path)
                o.loading = 'loading'
                SocketState.set({ [path]: o })
                
                this.Socket.send(m.toString) })
            this.Socket.addEventListener('error', () => { if (this.onError) {this.onError();} this.Socket.close() })
            this.Socket.addEventListener('close', () => { if (this.onClose) {this.onClose()} })
            this.Socket.addEventListener('message', (m) => {
                this.State = m.data
            })      
            return  
        }
        throw new Error(`Wrong state, ${this.Socket.readyState}`)
    }
    public Get<T>(state: string): T {
        return SocketState.get(state)
    }
    public Set<T>(state: string, input: T) {
        // console.log('huyyyyy')
        delete input['loading']
        SocketState.set({ [state]: Object.assign(input, { loading: 'loaded' }) })
    }
}
export default WS