import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet, numberWithCommas, getStringFromNumbersArray, updateCategory} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import {EditButton, DeleteButton, FlagByCountryName, editIndex, TB2, Image, TB} from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getLottoCount() {
    if (Main.Instance.LottoState.countCalled === false) {
        Logic.Type.New(Main.Instance.LottoState, {countCalled:true})
        DbRoutes.getLotto()
        DbRoutes.GetFlags()
    }
}
import {LotteryDetail} from '../../../Logic/Database/DatabaseData'
function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.getLotto()
    }
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE lotto.lottery SET active = ', rowData[0], rowData[10], getLottoCount)
    }
}
*/
function toggleActive(row:any) {
    if (row) {
        DbRoutes.Toggle('UPDATE lotto.lottery SET active = ', row.original.id, row.original.active, getLottoCount)
    }
}

/*
function handleRemoveImage (rowData:string[]) {
    if (rowData && confirm (Assets.confirmRemoveImage)) {
        DbRoutes.Action('UPDATE lotto.lottery SET image = \'\'', rowData[0], getLottoCount)
    }
}
*/
function handleImage(id: string) {
    if (confirm (Assets.confirmRemoveImage)) {
        DbRoutes.Action('UPDATE lotto.lottery SET image = \'\'', id, getLottoCount)
    }
}

function removeImage() {
    if (confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.LottoState, {image: ''})
        DbRoutes.Action('UPDATE lotto.lottery SET image = \'\'', Main.Instance.LottoState.id, getLottoCount)
    }
}
/*
function handleDelete(rowData:string[]) {
    if (rowData) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.LotteryItem + ' ' + 
            Assets.withName + ' "' + rowData[2] + '"?')) {

            if (Main.Instance.LottoState.pNum === 
            (Math.trunc((Main.Instance.LottoState.count - 2) / 
            Main.Instance.LottoState.pSize) + 1)) {
                DbRoutes.removeLotteryItem (rowData[0].toString(), parseInt(rowData[2]), DeleteLastPage)
            } else {
                DbRoutes.removeLotteryItem (rowData[0].toString(), parseInt(rowData[2]), DeleteNotLastPage)
            }
        }
    }
}

function handleEdit(rowData: string[]) {
    let active: string
    active = rowData[10].toString() === 'true' ? '1' : '2'
    if (rowData) {
        Logic.Type.New(Main.Instance.LottoState, {
            mode: true,
            id: rowData[0],
            site_id: rowData[1],  
            idx: rowData[2],
            idx_saved: rowData[2],

            image:rowData[5],
            image_saved:rowData[5],
            price:rowData[6],
            price_saved:rowData[6],
            price_currency:rowData[7],
            price_currency_saved:rowData[7],
            active: active,
            active_saved: active,
            identification: +rowData[13],
            identification_saved: +rowData[13]
        })
    }
}
*/

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.LotteryItem + ' ' + Assets.withName + ' "' + row.original.lottery + '"?')) {

            if (Main.Instance.LottoState.pNum === 
            (Math.trunc((Main.Instance.LottoState.count - 2) / 
            Main.Instance.LottoState.pSize) + 1)) {
                DbRoutes.removeLotteryItem (row.original.id.toString(), row.original.index, DeleteLastPage)
            } else {
                DbRoutes.removeLotteryItem (row.original.id.toString(), row.original.index, DeleteNotLastPage)
            }
        }
    }
}

function handleEdit(row: any) {
    gotoTop()
    let active: string
    active = row.original.active.toString() === 'true' ? '1' : '2'
    if (row) {
        Logic.Type.New(Main.Instance.LottoState, {
            mode: true,
            id: row.original.id,
            site_id: row.original.site_id,  
            idx: row.original.index,
            idx_saved: row.original.index,

            image:row.original.image,
            image_saved:row.original.image,
            price:row.original.price,
            price_saved:row.original.price,
            price_currency:row.original.price_currency,
            price_currency_saved:row.original.price_currency,
            active,
            active_saved: active,
            identification: row.original.identification,
            identification_saved: row.original.identification,
        })
    }
}

function GetLotteryAttribute(attribute: string): string {
    if (Main.Instance.DatabaseDataState.lottoGameTypeStream) {
    for (const item of Main.Instance.DatabaseDataState.lottoGameTypeStream) {
        if (+item['id'] === +Main.Instance.LottoState.identification) {
            return (item[attribute])
        }
    }
    }
    return ''
}

function handleInsertUpdate() {
    const lotteryItem = {
        id : !Main.Instance.LottoState.id ? '' : Main.Instance.LottoState.id,
        name: !Main.Instance.LottoState.identification ? '' : GetLotteryAttribute('name'),
        image: !Main.Instance.LottoState.image ? '' : Main.Instance.LottoState.image,
        idx: Main.Instance.LottoState.mode ? Main.Instance.LottoState.idx : Main.Instance.LottoState.count + 1,
        site_id: Main.Instance.FrontendStoreState.site_id,
        active : !Main.Instance.LottoState.active ? '1' : Main.Instance.LottoState.active,
        jackpot: !Main.Instance.LottoState.JackpotSize ? '0' : +Main.Instance.LottoState.JackpotSize,
        jackpot_currency: !Main.Instance.LottoState.JackpotCurrency ? Assets_.NA : Main.Instance.LottoState.JackpotCurrency,
        price: !Main.Instance.LottoState.price ? '0' : +Main.Instance.LottoState.price,
        price_currency: !Main.Instance.LottoState.price_currency ? '' : Main.Instance.LottoState.price_currency,
        identification: !Main.Instance.LottoState.identification ? '0' : Main.Instance.LottoState.identification,
        // custom: "'{}'"
    }

    if (parseInt(lotteryItem.active) === 1) { lotteryItem.active = 'true' }
    if (parseInt(lotteryItem.active) === 2) { lotteryItem.active = 'false' }

    if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
    } else if (Main.Instance.LottoState.price.length === 0) {
        alert (Assets.compulsoryPrice)
    } else if (Main.Instance.LottoState.identification === 0) {
        alert (Assets.compulsoryLottery)
    } else if (Main.Instance.LottoState.image.length === 0) {
        alert (Assets.compulsoryImage)
    } else if (Main.Instance.LottoState.price_currency.length === 0) {
        alert (Assets.compulsoryPriceCurrency)
    } else if (Main.Instance.LottoState.mode === false) {
         DbRoutes.insertLotteryItem (lotteryItem, InsertLastPage)
    } else {
         DbRoutes.updateLotteryItem (lotteryItem, Update)
    }
}

function ApplyFilterActive(text:string) {
    Logic.Type.New(Main.Instance.LottoState, {filter_active:text})
    FirstPage()
}

function getLotto() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {lottoStream : undefined})
}

function  clearFields() {
    Logic.Type.New(Main.Instance.LottoState, {
        JackpotSize: undefined,
        JackpotCurrency: undefined,
        price: '0',
        price_currency: '',
        image : Assets_.zeroPng,
        mode : false,
        active: '1',
        identification: 0,
        idx: '1',
    })
}

function gotoInsertMode() {
    if (
        Main.Instance.LottoState.price === Main.Instance.LottoState.price_saved &&
        Main.Instance.LottoState.price_currency === Main.Instance.LottoState.price_currency_saved &&
        Main.Instance.LottoState.idx === Main.Instance.LottoState.idx_saved &&
        Main.Instance.LottoState.image === Main.Instance.LottoState.image_saved &&
        Main.Instance.LottoState.identification === Main.Instance.LottoState.identification_saved &&
        Main.Instance.LottoState.active === Main.Instance.LottoState.active_saved) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.LottoState, {offset:(0).toString()})

    getLotto()
    Logic.Type.New(Main.Instance.LottoState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.LottoState.pNum > 0) {

        Logic.Type.New(Main.Instance.LottoState, {
            offset:((Main.Instance.LottoState.pNum - 1) * 
            Main.Instance.LottoState.pSize).toString()})
        getLotto()

        Logic.Type.New(Main.Instance.LottoState,{pNum: Main.Instance.LottoState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.LottoState.pNum + 1) * Main.Instance.LottoState.pSize < 
    Main.Instance.LottoState.count) {

        Logic.Type.New(Main.Instance.LottoState, {
            offset: ((Main.Instance.LottoState.pNum + 1) * 
            Main.Instance.LottoState.pSize).toString()})
        getLotto()
        
        Logic.Type.New(Main.Instance.LottoState,{pNum: Main.Instance.LottoState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.LottoState.count <= Main.Instance.LottoState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.LottoState.count === 0) {

            Logic.Type.New(Main.Instance.LottoState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.LottoState.count - 1) / 
                    Main.Instance.LottoState.pSize)
            Logic.Type.New(Main.Instance.LottoState, {
                offset:(npNum * Main.Instance.LottoState.pSize).toString()})
        }

        getLotto()

        Logic.Type.New(Main.Instance.LottoState,{pNum: npNum})
    }
}

function InsertLastPage() {
    Logic.Type.New(Main.Instance.LottoState,{count:Main.Instance.LottoState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.LottoState,{count:Main.Instance.LottoState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.LottoState,{count:Main.Instance.LottoState.count --})
    getLotto()
}

function Update() {
    clearFields()
    getLotto()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.LottoState, {pSize:parseInt(text)})
        FirstPage()
    }
}
/*
function handleEditIndex(rowData: string[], text: string) {
    let new_value: number = parseInt(text)
    let old_value: number = parseInt(rowData[2])

    if (new_value === old_value + 1 || new_value === old_value - 1) {
        
        if (new_value === old_value + 1) {
            new_value = old_value - 1
        } else {
            new_value = old_value + 1
        }

        if (new_value !== 0) DbRoutes.patchLotteryItem (rowData[0], new_value.toString(), rowData[2], FirstPage)
    } else if (new_value !== 0) {
        DbRoutes.patchLotteryItem (rowData[0], text, rowData[2], FirstPage)
    }
}
*/
function handleEditIndex(row: any, text: string) {
    let new_value: number = parseInt(text)
    const old_value: number = row.original.index

    if (new_value === old_value + 1 || new_value === old_value - 1) {
        
        if (new_value === old_value + 1) {
            new_value = old_value - 1
        } else {
            new_value = old_value + 1
        }

        if (new_value !== 0) { DbRoutes.patchLotteryItem (row.original.id, new_value.toString(), row.original.index, FirstPage) }
    } else if (new_value !== 0) {
        DbRoutes.patchLotteryItem (row.original.id, text, row.original.index, FirstPage)
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getLottoCount()
    }
}
/*
function updateCategory() {
    let Category: any[] = []
    let Category2: any[] = []
    Category.push ({id: '0', name: Assets.SelectCategory})
    Category2.push ({id: '0', name: Assets.AnyCategory})
    if (Main.Instance.FrontendStoreState.category) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.category.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
                Main.Instance.FrontendStoreState.category[i].length > 0) {
                let id: string = (1+Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                Category.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
                Category2.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Category, Category2})
    }
}
*/
export function LottoBet() {

    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    if (Main.Instance.DatabaseDataState.languages !== undefined &&
        Main.Instance.FrontendStoreState.category !== undefined && 
        Main.Instance.FrontendStoreState.Category === undefined) {
        if (!Main.Instance.FrontendStoreState.category || Main.Instance.FrontendStoreState.category.length === 0) {
            const category: string[] = []
            for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++) {
                category.push(Assets_.Uncategorized)
            }
            Logic.Type.New (Main.Instance.FrontendStoreState, {category})
        }

        updateCategory()
    }

    let lotteryDetail: LotteryDetail
    if (Main.Instance.DatabaseDataState.lottoGameTypeStream) {
    for (const item of Main.Instance.DatabaseDataState.lottoGameTypeStream) {
        if (+item['id'] === Main.Instance.LottoState.identification) {
            lotteryDetail = item
            if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
            for (const Item of Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
                if (Item['name'] === lotteryDetail.country
                    || Item['name'] === 'United States of America' && lotteryDetail.country === 'USA'
                    || Item['name'] === 'United Kingdom' && lotteryDetail.country === 'UK'
                    || Item['name'] === 'European Union' && lotteryDetail.country === 'Europe'
                ) {
                    lotteryDetail.flag = Item['image']
                    break
                } 
            }
            }

            if (Main.Instance.DatabaseDataState.lottoJackpotStream) {
            for (const Item of Main.Instance.DatabaseDataState.lottoJackpotStream) {
                if (+Item['gametypeid'] === Main.Instance.LottoState.identification) {
                    if (+Item['jackpotsize'] > 0) {
                        // console.log ('here')
                        lotteryDetail.JackpotSize = +Item['jackpotsize']
                        lotteryDetail.JackpotCurrency = Item['jackpotcurrency']
                        lotteryDetail.nextDrawTime = Item['drawdateutc'].substring(11,16)
                        // console.log (lotteryDetail.nextDrawTime)
                        lotteryDetail.nextDrawDate = Item['drawdateutc'].substring(8,10) + '-' + Item['drawdateutc'].substring(5,7) + '-' + Item['drawdateutc'].substring(0,4)
                        const date = new Date(+Item['drawdateutc'].substring(0,4), +Item['drawdateutc'].substring(5,7), +Item['drawdateutc'].substring(8,10))
                        // console.log (lotteryDetail.nextDrawDate)
                        lotteryDetail.nextDrawDay = Assets.DaysInAWeek[date.getDay()]
                        // console.log (lotteryDetail.nextDrawDay)
                        break
                    }
                }
            }
            }

            if (Main.Instance.DatabaseDataState.lottoDrawingStream) {
            for (const Item of Main.Instance.DatabaseDataState.lottoDrawingStream) {
                if (+Item['gametypeid'] === Main.Instance.LottoState.identification) {
                    if (+Item['jackpotsize'] > 0) {

                        lotteryDetail.lastJackpotSize = +Item['jackpotsize']
                        lotteryDetail.lastJackpotCurrency = Item['jackpotcurrency']
                        lotteryDetail.lastDrawTime = Item['drawdateutc'].substring(11,16)
                        // console.log (lotteryDetail.nextDrawTime)
                        lotteryDetail.lastDrawDate = Item['drawdateutc'].substring(8,10) + '-' + Item['drawdateutc'].substring(5,7) + '-' + Item['drawdateutc'].substring(0,4)
                        const date = new Date(+Item['drawdateutc'].substring(0,4), +Item['drawdateutc'].substring(5,7), +Item['drawdateutc'].substring(8,10))
                        // console.log (lotteryDetail.nextDrawDate)
                        lotteryDetail.lastDrawDay = Assets.DaysInAWeek[date.getDay()]
                        // console.log (lotteryDetail.nextDrawDay)
                        lotteryDetail.lastDrawid = Item['drawid']
                        lotteryDetail.lastNumbers = Item['numbers']
                        lotteryDetail.lastExtranumbers = Item['extranumbers']
                        lotteryDetail.lastBonusnumbers = Item['bonusnumbers']
                        lotteryDetail.lastRefundnumbers = Item['refundnumbers']
                        break
                    }
                }
            }
            }
            break
        }
    }
    }

    return (
<div>
    <div className = "clearfix">
        <h3 className = "level fl">{Assets.aeLottery}</h3>
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.LottoState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>
                            {Main.Instance.LottoState.mode ? Assets.editLotteryItem + ': ' + lotteryDetail.name : Assets.addLotteryItem}
                        </h4>

                        <RadioButton2
                            value = {!Main.Instance.LottoState.active ? '1' :
                                    Main.Instance.LottoState.active} 
                            text = {Assets.Activity}
                            ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                            title = {Assets.ActivityDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => {
                                Logic.Type.New(Main.Instance.LottoState, {active:text})}} 
                        />

                        {Main.Instance.LottoState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()} 
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}

                    </div>

                    <div className = "row admin-users-row">

                <FormSelect
                    value = {!Main.Instance.LottoState.identification ? '0' : 
                            Main.Instance.LottoState.identification.toString()}
                    data = {Main.Instance.DatabaseDataState.lottoGameTypeStream}
                    ClassName = "form-control"
                    o1 = {Assets.LotteryType + '?'}
                    o2 = {Assets.LotteryType + '?'}
                    onChange = {(text) => Logic.Type.New (Main.Instance.LottoState, {identification: +text})}
                    icon = {Assets_.faLottoClass}
                />
                {lotteryDetail ?
                <div className = "fl">
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "bold">{Assets.Playable}:</span>
                {lotteryDetail.isplayable === 1 ?
                <span style = {{fontSize: '16px', color: 'green', lineHeight: '30px'}} className = "ml5px bold">{Assets.YES}</span>
                :
                <span style = {{fontSize: '16px', color: 'red', lineHeight: '30px'}} className = "ml5px bold">{Assets.NO}</span>}
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "bold ml">{Assets.Country}:</span>
                <span style = {{fontSize: '14px', lineHeight: '30px'}} className = "ml5px">{lotteryDetail.country}</span>
                <img height = "20" className = "ml5px" src = {lotteryDetail.flag}/>
                <span style = {{fontSize: '14px', lineHeight: '30px'}} className = "bold ml">{Assets.Currency}:</span>
                <span style = {{fontSize: '14px', lineHeight: '30px'}} className = "ml5px mr">{lotteryDetail.currency}</span>
                </div>
                : void 0}
                <input  
                    accept = "image/png, image/jpg, image/jpeg, image/gif"
                    id = "shopitem-input" 
                    className = "UploadShopItemWrapper" 
                    type = "file" 
                    style = {{float: 'left', marginRight: '10px'}}
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            Logic.Type.New (Main.Instance.LottoState, {image: reader.result as string})
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => {event.target = null}}
                />

                {Main.Instance.LottoState.image && 
                Main.Instance.LottoState.image !== Assets_.zeroPng ?
                <div 
                    style = {{
                        padding: '2px',
                        background: `url(${Assets_.checkered})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat', 
                        position: 'relative',
                        height:'40px',
                        maxWidth:'18%',
                        width:'auto',
                        marginTop:'-12px', 
                        float:'left',
                        display: 'block',
                    }}
                >
                    <img 
                        src = {Main.Instance.LottoState.image} 
                        style = {{
                            height:'100%',
                            width:'auto',
                            zIndex:9998,
                            display:'block',
                        }}
                    />
                    <button 
                        className = "btn btn-xs btn-nothing"
                        title = {Assets.RemoveImage}
                        style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px'}}
                        onClick = {() => removeImage()}
                    >
                        <i className = {Assets_.faCancelClass}/>
                    </button>
                </div>
                :
                <span 
                    style = {{ 
                        float:'left',
                        display:'block',
                        marginRight:'1%',
                        height: '30px',
                        fontSize:'16px',
                        paddingLeft:'12px',
                        paddingRight:'12px',
                        paddingTop:'7px',
                    }}
                    className = "label label-warning"
                >
                    {Assets.NoImage}
                </span>}
            </div>
            <div className = "row admin-users-row">
                <FormInput
                    value = {!Main.Instance.LottoState.price ? '' : Main.Instance.LottoState.price} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => Logic.Type.New(Main.Instance.LottoState, {price:text})}
                    placeholder = {Assets.Price}
                    description = {Assets.Price}
                    iconText = {Assets.Price}
                    iconTextSize = "14px"
                />
                <FormInput
                    // width = "15"
                    value = {!Main.Instance.LottoState.price_currency ? '' : Main.Instance.LottoState.price_currency} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => Logic.Type.New(Main.Instance.LottoState, {price_currency:text})}
                    placeholder = {Assets.PriceCurrency}
                    description = {Assets.PriceCurrency}
                    icon = {Assets_.faPaymentClass}
                    // iconText = "prc"
                    // iconTextSize = "14px"
                />
                {lotteryDetail && lotteryDetail.JackpotSize ?
                <span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "bold ml">{Assets.Jackpot}:</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "ml5px">{numberWithCommas(lotteryDetail.JackpotSize)}</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "ml5px">{lotteryDetail.JackpotCurrency}</span>
                <span className = "ml5px">|</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "bold ml5px">{Assets.Nextdraw}:</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "ml5px">{lotteryDetail.nextDrawDay + ','}</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "ml5px">{lotteryDetail.nextDrawDate}</span>
                <span style = {{fontSize: '16px', lineHeight: '30px'}} className = "ml5px">{lotteryDetail.nextDrawTime + ' ' + Assets_.UTC}</span>
                </span>
                : void 0}
            </div>
            {lotteryDetail ?
            <div className = "row admin-users-row">
                <div>
                    <span className = "bold">{Assets.DETAILS}:</span>
                    <span className = "bold ml">{Assets.CutOff}:</span>
                    <span className = "ml5px">{lotteryDetail.cutoffhours + ' ' + Assets.hours}</span>
                    <span className = "ml5px">|</span>
                    <span className = "bold ml5px">{Assets.Numbers}:</span>
                    <span className = "ml5px">{lotteryDetail.numbers}</span>
                    <span className = "bold ml">{Assets.Min}:</span>
                    <span className = "ml5px">{lotteryDetail.numbermin}</span>
                    <span className = "bold ml">{Assets.Max}:</span>
                    <span className = "ml5px">{lotteryDetail.numbermax}</span>
                    <span className = "ml5px">|</span>
                    <span className = "bold ml5px">{Assets.Extranumbers}:</span>
                    <span className = "ml5px">{lotteryDetail.extranumbers}</span>
                    <span className = "ml5px">|</span>
                    <span className = "bold ml5px">{Assets.Bonusnumbers}:</span>
                    <span className = "ml5px">{lotteryDetail.bonusnumbers}</span>
                    <span className = "bold ml">{Assets.Min}:</span>
                    <span className = "ml5px">{lotteryDetail.bonusnumbermin}</span>
                    <span className = "bold ml">{Assets.Max}:</span>
                    <span className = "ml5px">{lotteryDetail.bonusnumbermax}</span>
                    <span className = "ml5px">|</span>
                    <span className = "bold ml5px">{Assets.Refundnumbers}:</span>
                    <span className = "ml5px">{lotteryDetail.refundnumbers}</span>
                    <span className = "bold ml">{Assets.Min}:</span>
                    <span className = "ml5px">{lotteryDetail.refundnumbermin}</span>
                    <span className = "bold ml">{Assets.Max}:</span>
                    <span className = "ml5px">{lotteryDetail.refundnumbermax}</span>
                </div>
            </div> : void 0}
            {lotteryDetail && lotteryDetail.lastDrawid ?
            <div className = "row admin-users-row">
                <span className = "bold">{Assets.LASTDRAW}:</span>
                {/*<span className = "ml5px">{lotteryDetail.lastDrawid}</span>*/}
                <span className = "ml5px">{lotteryDetail.lastDrawDay + ','}</span>
                <span className = "ml5px">{lotteryDetail.lastDrawDate + ' ' + Assets_.UTC}</span>
                <span className = "ml5px">{lotteryDetail.lastDrawTime}</span>
                <span className = "bold ml">{Assets.LastJackpot}:</span>
                <span className = "ml5px">{numberWithCommas(lotteryDetail.lastJackpotSize)}</span>
                <span className = "ml5px">{lotteryDetail.lastJackpotCurrency}</span>
                {lotteryDetail.lastNumbers.length > 0 ?
                <span>
                    <span className = "bold ml">{Assets.Numbers}:</span>
                    <span className = "ml5px">{getStringFromNumbersArray(lotteryDetail.lastNumbers)}</span>
                </span>
                : void 0}
                {lotteryDetail.lastExtranumbers.length > 0 ?
                <span>
                    <span className = "bold ml">{Assets.Extra}:</span>
                    <span className = "ml5px">{getStringFromNumbersArray(lotteryDetail.lastExtranumbers)}</span>
                </span>
                : void 0}
                {lotteryDetail.lastBonusnumbers.length > 0 ?
                <span>
                    <span className = "bold ml">{Assets.Bonus}:</span>
                    <span className = "ml5px">{getStringFromNumbersArray(lotteryDetail.lastBonusnumbers)}</span>
                </span>
                : void 0}
                {lotteryDetail.lastRefundnumbers.length > 0 ?
                <span>
                    <span className = "bold ml">{Assets.Refund}:</span>
                    <span className = "ml5px">{getStringFromNumbersArray(lotteryDetail.lastRefundnumbers)}</span>
                </span>
                : void 0}


            </div> : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon 
                    description = {Main.Instance.LottoState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.LottoState.mode ? 
                                'btn btn-default btn-sm btn-plus fr mt-20' : 
                                Main.Instance.LottoState.identification === 0 ?
                                'btn btn-primary btn-sm btn-plus fr mt0' :
                                'btn btn-primary btn-sm btn-plus fr mt-20'}
                    // icon= {Assets_.faUpdateButtonClass}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
            </div>
            </div>
            </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.LottoState.pSize ? '' : 
                Main.Instance.LottoState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.LottoState.filter_active ? '0' : 
                        Main.Instance.LottoState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {(text) => ApplyFilterActive(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.LottoState.count ? Main.Instance.LottoState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.LottoState.count ? Main.Instance.LottoState.pNum * 
                Main.Instance.LottoState.pSize + 1 : 0}{' - '}
                {Main.Instance.LottoState.count ? 
                ((Main.Instance.LottoState.pNum + 1) * 
                Main.Instance.LottoState.pSize > Main.Instance.LottoState.count ? 
                Main.Instance.LottoState.count : 
                (Main.Instance.LottoState.pNum + 1) * Main.Instance.LottoState.pSize) : 0}
            </span>
        <span className = "label label-success table-label mt5">
            {Assets.page}
            {Main.Instance.LottoState.count ? 
            Main.Instance.LottoState.pNum + 1 :0}{' / '}
            {Main.Instance.LottoState.count ? 
            Math.trunc((Main.Instance.LottoState.count - 1) /
            Main.Instance.LottoState.pSize + 1) : 0}
        </span>
        </div>
        <h3>{Assets.tLottery}</h3>
        {/*console.log (Main.Instance.DatabaseDataState.lottoStream)*/}
        {Main.Instance.DatabaseDataState.lottoStream ?
        IsBuildScandibet() ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.lottoStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => EditButton(row, handleEdit)},
                {Header: Assets.Index, accessor: 'index', Cell: (row) => editIndex(row.value, row, handleEditIndex)},
                {Header: Assets.LotteryName, accessor: 'lottery', headerClassName: 'th-left'},
                {Header: Assets.Country, accessor: 'flag_lottery', Cell: (row) => FlagByCountryName(row.value)},
                {Header: Assets.Image, accessor: 'image', Cell: (row) => Image(row.value, row.original.id, handleImage), headerClassName: 'th-left'},
                {Header: <span>Price <small>(Coins)</small></span>, accessor: 'price', headerClassName: 'th-left'},
                {Header: <i className = "fa fa-money"/>, accessor: 'price_currency', headerClassName: 'th-left'},
                {Header: Assets.Jackpot, accessor: 'jackpot', Cell: (row) => numberWithCommas(row.value) + ' ' + row.original.jackpot_currency},
                {Header: Assets.Active, accessor: 'active', Cell: (row) => TB(row, toggleActive)},
                {Header: Assets.Play, accessor: 'isplayable', Cell: (row) => TB2(row)},
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> :
        <ReactTable
            data = {Main.Instance.DatabaseDataState.lottoStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => EditButton(row, handleEdit)},
                {Header: Assets.Index, accessor: 'index', Cell: (row) => editIndex(row.value, row, handleEditIndex)},
                {Header: Assets.LotteryName, accessor: 'lottery', headerClassName: 'th-left'},
                {Header: Assets.Country, accessor: 'flag_lottery', Cell: (row) => FlagByCountryName(row.value)},
                {Header: Assets.Image, accessor: 'image', Cell: (row) => Image(row.value, row.original.id, handleImage), headerClassName: 'th-left'},
                {Header: <span>{Assets.Price} <small>({Assets.Coins})</small></span>, accessor: 'price', headerClassName: 'th-left'},
                {Header: <i className = "fa fa-money"/>, accessor: 'price_currency', headerClassName: 'th-left'},
                {Header: Assets.Jackpot, accessor: 'jackpot', Cell: (row) => numberWithCommas(row.value) + ' ' + row.original.jackpot_currency},
                {Header: Assets.Active, accessor: 'active', Cell: (row) => TB(row, toggleActive)},
                {Header: Assets.Play, accessor: 'isplayable', Cell: (row) => TB2(row)},
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : Main.Instance.FrontendStoreState.site_id ? getLottoCount() : void 0}

        </div>
    </div>)
}