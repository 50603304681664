import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel, DashboardRuleType} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
// import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {toggleTimeUnit, toggleCurrency} from './reusables'

interface StateDepositMetods {
    depositMethodsMin?: number
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    riskLevel: RiskLevel
}

export class DBDepositMethodsPopup extends React.Component<{}, StateDepositMetods> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            depositMethodsMin: this.state.depositMethodsMin,
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.DepositMethods,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddDepositMethodsRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.DepositMethodsMinimum}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({depositMethodsMin: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.depositMethodsMin}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>
                        
                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    // value = {this.state.riskLevel}
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateDepositAfterCoolOff {
    depositMin?: number
    afterCoolOffPercentageHigher?: number
    beforeCoolOffSpan?: number,
    beforeCoolOffSpanUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    riskLevel: RiskLevel
}

export class DBDepositAfterCoolOffPopup extends React.Component<{}, StateDepositAfterCoolOff> {
    constructor(props) {
        super(props)
        this.state = {
            beforeCoolOffSpanUnit: 'N/A',
            currency: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            depositMin: this.state.depositMin,
            afterCoolOffPercentageHigher: this.state.afterCoolOffPercentageHigher,
            beforeCoolOffSpan: this.state.beforeCoolOffSpan,
            beforeCoolOffSpanUnit: this.state.beforeCoolOffSpanUnit,
            currency: this.state.currency,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.DepositAfterCoolOff,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddDepositAfterCoolOffRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.DepositMinimum}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({depositMin: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.depositMin}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.AfterCoolOffPercentageHigher}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({afterCoolOffPercentageHigher: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.afterCoolOffPercentageHigher}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.BeforeCoolOffSpan}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({beforeCoolOffSpan: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.beforeCoolOffSpan}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.BeforeCoolOffSpanUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({beforeCoolOffSpanUnit: toggleTimeUnit(this.state.beforeCoolOffSpanUnit).NewUnitMHDM})}
                            >
                                {this.state.beforeCoolOffSpanUnit}
                            </button>
                        </div>
                        
                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.Currency}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({currency: toggleCurrency(this.state.currency)})}
                            >
                                {this.state.currency}
                            </button>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


interface StateCancelledWitdrawals {
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    withdrawalRequestsCancelledThreshold?: number,
    riskLevel: RiskLevel
}

export class DBCancelledWithdrawalsPopup extends React.Component<{}, StateCancelledWitdrawals> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            withdrawalRequestsCancelledThreshold: this.state.withdrawalRequestsCancelledThreshold,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.CancelledWithdrawals,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddCancelledWithdrawalsRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.WithdrawalRequestCancelledTreshold}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({withdrawalRequestsCancelledThreshold: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.withdrawalRequestsCancelledThreshold}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateGamingTime {
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    gamingTime?: number
    gamingTimeUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    gamingTimePercentageOver?: number
    gamingDayMustHaveSession?: boolean
    gamingDayMinSessionLength?: number
    gamingDayMinSessionLengthUnit?: 'N/A'|'min'|'hour'
    riskLevel: RiskLevel
}

export class DBGamingTimePopup extends React.Component<{}, StateGamingTime> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            gamingTimeUnit: 'N/A',
            gamingDayMinSessionLengthUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            gamingTime: this.state.gamingTime,
            gamingTimeUnit: this.state.gamingTimeUnit,
            gamingTimePercentageOver: this.state.gamingTimePercentageOver,
            gamingDayMustHaveSession: this.state.gamingDayMustHaveSession,
            gamingDayMinSessionLength: this.state.gamingDayMinSessionLength,
            gamingDayMinSessionLengthUnit: this.state.gamingDayMinSessionLengthUnit,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.GamingTime,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddGamingTimeRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.GamingTime}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({gamingTime: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.gamingTime}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.GamingTimeUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({gamingTimeUnit: toggleTimeUnit(this.state.gamingTimeUnit).NewUnitMHDM})}
                            >
                                {this.state.gamingTimeUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.GamingTimePercentageOver}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({gamingTimePercentageOver: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.gamingTimePercentageOver}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({gamingDayMustHaveSession: !this.state.gamingDayMustHaveSession})} on = {this.state.gamingDayMustHaveSession} />
                            <div className = "fl mt2">{Assets.GamingDayMustHaveSession}</div>
                        </div>


                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.GamingDayMinimalSessionLength}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({gamingDayMinSessionLength: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.gamingDayMinSessionLength}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.GamingDayMinimalSessionLengthUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({gamingDayMinSessionLengthUnit: toggleTimeUnit(this.state.gamingDayMinSessionLengthUnit).NewUnitMH})}
                            >
                                {this.state.gamingDayMinSessionLengthUnit}
                            </button>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateNetLoss {
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    netLossPerHour?: number
    totalNetLoss?: number
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    riskLevel: RiskLevel
}

export class DBNetLossPopup extends React.Component<{}, StateNetLoss> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            currency: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
/*
        let custom: Custom = Object.assign ({}, Main.Instance.FrontendStoreState.custom)
        if (custom === undefined) custom = {}
        if (custom.responsibleGaming === undefined) custom.responsibleGaming = {}
        if (custom.responsibleGaming.dashboardBackend === undefined) custom.responsibleGaming.dashboardBackend = {}
        if (custom.responsibleGaming.dashboardBackend.rules === undefined) custom.responsibleGaming.dashboardBackend.rules = []
        
        custom.responsibleGaming.dashboardBackend.rules.push ({
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            netLossPerHour: this.state.netLossPerHour,
            totalNetLoss: this.state.totalNetLoss,
            currency: this.state.currency,
            riskLevel: this.state.riskLevel,
            ruleType: 4 // NetLoss
        })
        Logic.Type.New(Main.Instance.FrontendStoreState, {custom})
*/
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            netLossPerHour: this.state.netLossPerHour,
            totalNetLoss: this.state.totalNetLoss,
            currency: this.state.currency,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.NetLoss,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddNetLossRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.NetLossPerHour}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({netLossPerHour: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.netLossPerHour}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.TotalNetLoss}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({totalNetLoss: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.totalNetLoss}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.Currency}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({currency: toggleCurrency(this.state.currency)})}
                            >
                                {this.state.currency}
                            </button>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateInitiatedCoolOffs {
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    coolOffsInitiatedThreshold?: number
    riskLevel: RiskLevel
}

export class DBInitiatedCoolOffsPopup extends React.Component<{}, StateInitiatedCoolOffs> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            coolOffsInitiatedThreshold: this.state.coolOffsInitiatedThreshold,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.InitiatedCoolOffs,
        })
        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddInitiatedCoolOffsRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.CoolOffsInitiatedThreshold}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({coolOffsInitiatedThreshold: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.coolOffsInitiatedThreshold}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface StateIncreasedNetLoss {
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year'
    netLossLimitIncreasedThreshold?: number
    riskLevel: RiskLevel
}

export class DBIncreasedNetLossPopup extends React.Component<{}, StateIncreasedNetLoss> {
    constructor(props) {
        super(props)
        this.state = {
            withinUnit: 'N/A',
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amDashboardBackend, {
            within: this.state.within,
            withinUnit: this.state.withinUnit,
            netLossLimitIncreasedThreshold: this.state.netLossLimitIncreasedThreshold,
            riskLevel: this.state.riskLevel,
            ruleType: DashboardRuleType.IncreasedNetLoss,
        })
        Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddIncreasedNetLossRules}</h4>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.Within}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({within: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.within}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.WithinUnit}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({withinUnit: toggleTimeUnit(this.state.withinUnit).NewUnitMHDM})}
                            >
                                {this.state.withinUnit}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.NetLossLimitIncreasedThreshold}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({netLossLimitIncreasedThreshold: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.netLossLimitIncreasedThreshold}
                            />
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddDashboardBackend: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}