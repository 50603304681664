import * as React from 'react'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { SelectPlayer } from '../PlayerManagement/PlayerCard'
import {PlayerCardButtons} from '../../../Logic/RiskComplianceStore/RiskManagement'
// import {GetDate} from '../PlayerManagement/WithdrawalRequests'
import {FormInputNumber} from '../../Reusables/Inputs'
import {SingleDatePicker} from 'react-dates'
import moment from 'moment'
import {faCopy} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { copyStringToClipboard/*, isLocalhost*/ } from 'src/Logic/Utils';

interface State {
    selectedBonusCampaignId: number
    selectedBonusId: number
    selectedBonusCodeId: string

    selectedBonusCampaignIndex: number
    selectedBonusIndex: number
    selectedBonusCodeIndex: number

    wfPlayerId1: string
    wfPlayerIdSearch1: string

    date1: moment.Moment
    date2: moment.Moment
    focused1: boolean
    focused2: boolean
    time1: string
    time2: string

    dateRange: boolean
    date3: moment.Moment
    focused3: boolean
}

export class BonusCampaignReports extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            selectedBonusCampaignId: 0,
            selectedBonusId: 0,
            selectedBonusCodeId: Assets.All,

            selectedBonusCampaignIndex: -1,
            selectedBonusIndex: -1,
            selectedBonusCodeIndex: -1,

            wfPlayerId1: '0',
            wfPlayerIdSearch1: '',
            date1: null,
            date2: null,
            focused1: false,
            focused2: false,
            time1: '00:00:00',
            time2: '23:59:59',

            dateRange: false,
            date3: null,
            focused3: false
        }
        DbRoutes.wsReinitialize()
        DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
    }

    public from(): string {
        if (this.state.dateRange === true) {
            if (this.state.date1 !== null) {
                return this.state.date1.format(Assets_.dateDisplayFormat) + ' ' + this.state.time1
            }
            return undefined
        } else if (this.state.dateRange === false) {
            if (this.state.date3 !== null) {
                return this.state.date3.format(Assets_.dateDisplayFormat) + ' 00:00:00' 
            }
            return undefined
        }
        return undefined
    }

    public to(): string {
        if (this.state.dateRange === true) {
            if (this.state.date2 !== null) {
                return this.state.date2.format(Assets_.dateDisplayFormat) + ' ' + this.state.time2
            }
            return undefined
        } else if (this.state.dateRange === false) {
            if (this.state.date3 !== null) {
                return this.state.date3.format(Assets_.dateDisplayFormat) + ' 23:59:59' 
            }
            return undefined
        }
        return undefined
    }


    public date1(): JSX.Element {
        return (
            <div key = "2" id = "datadate01" title = {Assets.Date + ' ' + Assets.Start.toLowerCase()} className = "fl mr singledatepicker">
                <SingleDatePicker
                    id = "datadate5"
                    placeholder = {Assets.Date + ' ' + Assets.Start.toLowerCase()}
                    date = {this.state.date1}
                    onDateChange = {(date) => this.setState({date1: date})}
                    focused = {this.state.focused1}
                    onFocusChange = {(focused) => this.setState({focused1: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                    // isDayHighlighted = {(date) => HighlightToday(date)}
                />
            </div>
        )
    }

    public date2(): JSX.Element {
        return (
            <div key = "3" id = "datadate02" title = {Assets.Date + ' ' + Assets.End.toLowerCase()} className = "fl mr singledatepicker">
                <SingleDatePicker
                    id = "datadate6"
                    placeholder = {Assets.Date + ' ' + Assets.End.toLowerCase()}
                    date = {this.state.date2}
                    onDateChange = {(date) => this.setState({date2: date})}
                    focused = {this.state.focused2}
                    onFocusChange = {(focused) => this.setState({focused2: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                    // isDayHighlighted = {(date) => HighlightToday(date)}
                />
            </div>
        )
    }

    public date3(): JSX.Element {
        return (
            <div key = "3" id = "datadate02" title = {Assets.Select + ' ' + Assets.Date.toLowerCase()} className = "fl mr singledatepicker">
                <SingleDatePicker
                    id = "datadate6"
                    placeholder = {Assets.Select + ' ' + Assets.Date.toLowerCase()}
                    date = {this.state.date3}
                    onDateChange = {(date) => this.setState({date3: date})}
                    focused = {this.state.focused3}
                    onFocusChange = {(focused) => this.setState({focused3: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                    // isDayHighlighted = {(date) => HighlightToday(date)}
                />
            </div>
        )
    }


    public FirstPage() {
        Logic.Type.New(Main.Instance.DatabaseDataState, {bonusCampaignReportsPage: 0})
        DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
    }
    
    public PrevPage() {
        if (Main.Instance.DatabaseDataState.bonusCampaignReportsPage > 0) {
            Logic.Type.New(Main.Instance.DatabaseDataState,{bonusCampaignReportsPage: Main.Instance.DatabaseDataState.bonusCampaignReportsPage - 1})
            DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
    
        } else {
            this.LastPage()
        }
    }
    
    public NextPage() {
        if ((Main.Instance.DatabaseDataState.bonusCampaignReportsPage + 1) * Main.Instance.DatabaseDataState.bonusCampaignReportsLimit < 
            Main.Instance.DatabaseDataState.bonusCampaignReportsCount) {
            Logic.Type.New(Main.Instance.DatabaseDataState,{bonusCampaignReportsPage: Main.Instance.DatabaseDataState.bonusCampaignReportsPage + 1})
            DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
            
        } else {
            this.FirstPage()
        }
    }
    
    public LastPage() {
        if (Main.Instance.DatabaseDataState.bonusCampaignReportsCount <= Main.Instance.DatabaseDataState.bonusCampaignReportsLimit) {
            this.FirstPage()
        } else {
            let nbonusCampaignReportsPage: number = 0
    
            if (Main.Instance.DatabaseDataState.bonusCampaignReportsCount === 0) {
                Logic.Type.New(Main.Instance.DatabaseDataState, {bonusCampaignReportsPage: 0})
            } else {
    
                nbonusCampaignReportsPage = Math.trunc((Main.Instance.DatabaseDataState.bonusCampaignReportsCount - 1) / 
                        Main.Instance.DatabaseDataState.bonusCampaignReportsLimit)
                Logic.Type.New(Main.Instance.DatabaseDataState, {bonusCampaignReportsPage: nbonusCampaignReportsPage})
            }
            DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
        }
    }

    public RecordsPerPageChanged(text: string) {
        if (parseInt(text) > 0) {
            Logic.Type.New(Main.Instance.DatabaseDataState, {bonusCampaignReportsLimit: parseInt(text)})
            this.FirstPage()
        }
    }

    public selectBonusCampaign(): JSX.Element {
        const bonusCampaigns: JSX.Element[] = []
        bonusCampaigns.push(
            <button
                key = "-1"
                type = "button"
                onClick = {() => {this.setState({selectedBonusCampaignId: 0, selectedBonusCampaignIndex: -1}); DbRoutes.getBonusCampaignReports(0, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                className = {this.state.selectedBonusCampaignId === 0 ? 'btn btn-danger btn-xs mb3' : 'btn btn-default btn-xs mb3'}
            >
                {Assets.BonusCampaign + ' ' + Assets.NotSelected.toLowerCase()}
            </button>
        )

        for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusCampaignNew.length; i++) {
            bonusCampaigns.push(
                <button
                    key = {i.toString()}
                    type = "button"
                    onClick = {() => {
                        this.setState({selectedBonusCodeId: Assets.All, selectedBonusCodeIndex: -1, selectedBonusId: 0, selectedBonusIndex: -1, selectedBonusCampaignIndex: i, selectedBonusCampaignId: Main.Instance.DatabaseDataState.bonusCampaignNew[i].id}); 
                        DbRoutes.getBonusCampaignReports(Main.Instance.DatabaseDataState.bonusCampaignNew[i].id, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                    className = {this.state.selectedBonusCampaignId === Main.Instance.DatabaseDataState.bonusCampaignNew[i].id ? 'btn btn-primary btn-xs mb3' : 'btn btn-default btn-xs mb3'}
                >
                    {Main.Instance.DatabaseDataState.bonusCampaignNew[i].campaignName ? Main.Instance.DatabaseDataState.bonusCampaignNew[i].campaignName : '-'}
                </button>
            )
        }
        return (<div>{bonusCampaigns}</div>)
    }

    public getBonusName(bonusId: number): string {
        for (const item of Main.Instance.DatabaseDataState.bonusNew) {
            if (item.bonusId === bonusId) {
                return (item.bonusName)
            }
        }
        return '-'
    }

    public getBonusCampaignName(campaignId: number): string {
        for (const item of Main.Instance.DatabaseDataState.bonusCampaignNew) {
            if (item.id === campaignId) {
                return (item.campaignName)
            }
        }
        return '-'
    }

    public getBonusIndex(bonusId: number): number {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusNew.length; i++) {
            if (Main.Instance.DatabaseDataState.bonusNew[i].bonusId === bonusId) {
                return i
            }
        }
        return -1
    }

    public selectBonus(): JSX.Element {
        const bonus: JSX.Element[] = []
        bonus.push(
            <button
                key = "-1"
                type = "button"
                onClick = {() => {this.setState({selectedBonusId: 0, selectedBonusIndex: -1});DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, 0, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                className = {this.state.selectedBonusId === 0 ? 'btn btn-info btn-xs mb3' : 'btn btn-default btn-xs mb3'}
            >
                {Assets.Bonus + ' ' + Assets.NotSelected.toLowerCase()}
            </button>
        )

        for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds.length; i++) {
            bonus.push(
                <button
                    key = {i.toString()}
                    type = "button"
                    onClick = {() => {this.setState({selectedBonusCodeId: Assets.All, selectedBonusCodeIndex: -1, selectedBonusIndex: this.getBonusIndex(Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds[i]), selectedBonusId: Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds[i]}); DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds[i], this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                    className = {this.state.selectedBonusId === Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds[i] ? 'btn btn-primary btn-xs mb3' : 'btn btn-default btn-xs mb3'}
                >
                    {Main.Instance.DatabaseDataState.bonusNew ? this.getBonusName(Main.Instance.DatabaseDataState.bonusCampaignNew[this.state.selectedBonusCampaignIndex].bonusIds[i]) : Assets.Unknown + ' ' + Assets.Bonus.toLowerCase()}
                </button>
            )
        }
        return (<div>{bonus}</div>)
    }

    public selectBonusCode(): JSX.Element {
        const bonusCode: JSX.Element[] = []
        bonusCode.push(
            <button
                key = "-1"
                type = "button"
                onClick = {() => {this.setState({selectedBonusCodeId: Assets.All, selectedBonusCodeIndex: -1}); DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, Assets.All, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                className = {this.state.selectedBonusCodeId === Assets.All ? 'btn btn-info btn-xs mb3' : 'btn btn-default btn-xs mb3'}
            >
                {Assets.BonusCode + ' ' + Assets.NotSelected.toLowerCase()}
            </button>
        )

        for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes.length; i++) {
            bonusCode.push(
                <button
                    key = {i.toString()}
                    type = "button"
                    onClick = {() => {this.setState({selectedBonusCodeIndex: i, selectedBonusCodeId: Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes[i]}); DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes[i], this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())}}
                    className = {this.state.selectedBonusCodeId === Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes[i] ? 'btn btn-primary btn-xs mb3' : 'btn btn-default btn-xs mb3'}
                >
                    {Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex] && Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes[i] ? Main.Instance.DatabaseDataState.bonusNew[this.state.selectedBonusIndex].bonusCodes[i] : ''}
                </button>
            )
        }
        return (<div>{bonusCode}</div>)
    }

    public getHeader(): JSX.Element {
        return (
            <tr>
                <th>{Assets.Index}</th>
                <th>{Assets.PlayerID}</th>
                <th>{Assets.BonusCampaign}</th>
                <th>{Assets.Bonus}</th>
                <th>{Assets.BonusCode}</th>
                <th>{Assets.Created + ' ' + Assets_.UTC}</th>
            </tr>
        )
    }

    public getBody(): JSX.Element[] {
        const body: JSX.Element[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.bonusCampaignReports.length; i++) {
            body.push(
                <tr key = {i.toString()}>
                    <td>{Main.Instance.DatabaseDataState.bonusCampaignReportsLimit*Main.Instance.DatabaseDataState.bonusCampaignReportsPage+i+1}</td>
                    <td><button title = {Assets.Show + ' ' + Assets.PlayerCard} type = "button" className = "btn btn-default btn-xs" onClick = {() => SelectPlayer(Main.Instance.DatabaseDataState.bonusCampaignReports[i].playerId, PlayerCardButtons.Transactions/*EventsTimeline*/)}>{Main.Instance.DatabaseDataState.bonusCampaignReports[i].playerId}</button>&nbsp;<button type = "button" onClick = {() => copyStringToClipboard(Main.Instance.DatabaseDataState.bonusCampaignReports[i].playerId)} className = "btn btn-default btn-xs" title = {Assets.CopyContentToClipboard}><FontAwesomeIcon icon = {faCopy}/></button></td>
                    <td>{this.getBonusCampaignName(Main.Instance.DatabaseDataState.bonusCampaignReports[i].campaignId)}</td>
                    <td>{this.getBonusName(Main.Instance.DatabaseDataState.bonusCampaignReports[i].bonusId)}</td>
                    <td>{Main.Instance.DatabaseDataState.bonusCampaignReports[i].bonusCode}</td>
                    <td>{Main.Instance.DatabaseDataState.bonusCampaignReports[i].created.substring(0, 10) + ' ' + Main.Instance.DatabaseDataState.bonusCampaignReports[i].created.substring(11, 19)}</td>
                </tr>
            )
        }
        return body
    }

    public getControlPanel(): JSX.Element {
        return (
            <div className = "control-panel clearfix">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.DatabaseDataState.bonusCampaignReportsLimit ? '' : Main.Instance.DatabaseDataState.bonusCampaignReportsLimit.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => this.RecordsPerPageChanged(text)}
            />
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => this.FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => this.PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => this.NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => this.LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? Main.Instance.DatabaseDataState.bonusCampaignReportsCount : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showing}
                {Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? Main.Instance.DatabaseDataState.bonusCampaignReportsPage * 
                Main.Instance.DatabaseDataState.bonusCampaignReportsLimit + 1 : 0}{' - '}
                {Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? 
                ((Main.Instance.DatabaseDataState.bonusCampaignReportsPage + 1) * 
                Main.Instance.DatabaseDataState.bonusCampaignReportsLimit > Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? 
                Main.Instance.DatabaseDataState.bonusCampaignReportsCount : 
                (Main.Instance.DatabaseDataState.bonusCampaignReportsPage + 1) * Main.Instance.DatabaseDataState.bonusCampaignReportsLimit) : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.page}
                {Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? 
                Main.Instance.DatabaseDataState.bonusCampaignReportsPage + 1 :0}{' / '}
                {Main.Instance.DatabaseDataState.bonusCampaignReportsCount ? 
                Math.trunc((Main.Instance.DatabaseDataState.bonusCampaignReportsCount - 1) /
                Main.Instance.DatabaseDataState.bonusCampaignReportsLimit + 1) : 0}
            </span>
        </div>

        )
    }

    public getTable(): JSX.Element {
        return (
            <div>
                {this.getControlPanel()}
                <br/>
                <table className = "table" id = "Exported3">
                    <thead>
                        {this.getHeader()}
                    </thead>
                    <tbody>
                        {this.getBody()}
                    </tbody>
                    {/*
                    <tfoot>
                        {this.getFooter()}
                    </tfoot>
                    */}
                </table>
            </div>
        )
    }

    public getPlayerIdOptions(): JSX.Element[] {
        const pio: JSX.Element[] = []
        pio.push(<option key = "0" value = "0">{Assets.FilterByPlayer}</option>)
        if (Main.Instance.DatabaseDataState.playerCards) {
            for (const item of Main.Instance.DatabaseDataState.playerCards) {
                pio.push(<option key = {item.playerId} value = {item.playerId}>{item.playerId}</option>)
            }
        }
        return pio
    }


    public getOtherFilters(): JSX.Element[] {
        const of: JSX.Element[] = []
        of.push(
            <select
                key = "0"
                disabled = {this.state.wfPlayerIdSearch1.length > 0}
                className = "fl dbl mr"
                style = {{height: '22px'}}
                value = {this.state.wfPlayerId1}
                onChange = {(e) => {this.setState({wfPlayerId1: e.target.value}); DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, e.target.value, this.from(), this.to())}}
            >
                {this.getPlayerIdOptions()}
            </select>
        )
        if (this.state.wfPlayerId1 === '0') {
            of.push(
                <input
                    key = "1"
                    style = {{height: '22px', width: '300px', paddingLeft: '5px', paddingRight: '5px'}}
                    placeholder = {Assets.Filter + Assets._by_ + Assets.PlayerId}
                    className = "dbl fl mr"
                    onChange = {(e) => {this.setState({wfPlayerIdSearch1: e.target.value, wfPlayerId1: '0'});DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, e.target.value, this.from(), this.to())}}
                    type = "text"
                    value = {this.state.wfPlayerIdSearch1}
                />
            )
        }
        of.push(
            <button 
            onClick = {() => this.setState({dateRange: !this.state.dateRange})}
            key = "6" 
            type = "button" 
            className = "btn btn-default btn-xs mr fl"
            title = {Assets.Change + Assets._the_ + Assets.Date.toLowerCase() + ' ' + Assets.Filter.toLowerCase() + ' ' + Assets.Method.toLowerCase() + Assets._to_ + (!this.state.dateRange ? Assets.DateFrom.toLowerCase() + '/' + Assets.to : Assets.SelectDate.toLowerCase())}
            >
                {!this.state.dateRange ? Assets.Filter + ': ' + Assets.DateFrom.toLowerCase() + '/' + Assets.to : Assets.Filter + ': ' + Assets.SelectDate.toLowerCase()}
            </button>
        )
        if (this.state.dateRange) {
            of.push(this.date1())
            if (this.state.date1 !== null) {
                of.push(<input value = {this.state.time1} className = "dbl fl mr" key = "4" type = "text" style = {{height: '22px', width: '70px', paddingLeft: '5px', paddingRight: '5px'}} onChange = {(e) => this.setState({time1: e.target.value})}/>)
            }
            of.push(this.date2())
            if (this.state.date2 !== null) {
                of.push(<input value = {this.state.time2} className = "dbl fl mr" key = "5" type = "text" style = {{height: '22px', width: '70px', paddingLeft: '5px', paddingRight: '5px'}} onChange = {(e) => this.setState({time2: e.target.value})}/>)
            }
        } else {
            of.push(this.date3())
        }
        of.push(
            <button 
            onClick = {() => {
                DbRoutes.getBonusCampaignReports(this.state.selectedBonusCampaignId, this.state.selectedBonusId, this.state.selectedBonusCodeId, this.state.wfPlayerId1 !== '0' ? this.state.wfPlayerId1 : this.state.wfPlayerIdSearch1, this.from(), this.to())
            }} 
            key = "6" 
            type = "button" 
            className = "btn btn-success btn-xs mr0"
            >
                {Assets.Refresh}
        </button>
        )
        return of
    }


    public render() {
        return (
            <div>
                <h2>{Assets.BonusCampaign + ' ' + Assets.Reports.toLowerCase()}</h2>
                {Main.Instance.DatabaseDataState.bonusCampaignNew ? this.selectBonusCampaign() : void 0}
                {this.state.selectedBonusCampaignIndex !== -1 ? this.selectBonus() : void 0}
                {this.state.selectedBonusCampaignIndex !== -1 && this.state.selectedBonusIndex !== -1 ? this.selectBonusCode() : void 0}
                <div className = "clearfix mt">{this.getOtherFilters()}</div>
                {Main.Instance.DatabaseDataState.bonusCampaignReports && Main.Instance.DatabaseDataState.bonusCampaignNew && Main.Instance.DatabaseDataState.bonusNew ? this.getTable() : void 0}
            </div>
        )
        // }
    }
}