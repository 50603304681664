import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {/*RiskLevel,*/SelectedPlayerSetting } from '../../../Logic/RiskComplianceStore/RiskManagement'
import { PlayerTags} from '../PlayerManagement/PlayerTags'
import { isUserAdmin, injectSpaces, isUserGamanzaGamesAdmin, isUserGamanzaAdmin } from '../../../Logic/Utils'
// import { InsideTitle/*, handleText, NotifyButton, AddNotify, handleDeleteNotify, Update*/ } from '../RiskComplianceStore/RiskManagement'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Custom, Country, Region, PostalCodes, PostalCodesData} from '../../../Logic/Database/DatabaseData'
import { faSave, faPlus, faTrashAlt, faStop } from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-toggle-switch'
import * as Config from '../../../Logic/Config'
import * as Assets_ from '../../Assets_'
import * as base64 from 'base-64'
import {ToggleSwitch} from './GenericSettings'
/*
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {riskLevelSlider} from './ResponsibleGaming'
*/
function selectNavigation(selectedPlayerSetting: SelectedPlayerSetting) {
    if (selectedPlayerSetting === SelectedPlayerSetting.PostalCodes) {
        DbRoutes.wsBasicGet(Config.amPostalCodes)
    }
    Logic.Type.New(Main.Instance.RiskManagementState, { selectedPlayerSetting })
}
/*
export function injectSpaces(source: string): string {
    if (source === undefined) { return '' }
    const Type: string[] = source.match(/[A-Z][a-z]+/g)
    let text: string = ''
    for (let j: number = 0; j < Type.length; j++) {
        if (j > 0) { text += ' ' }
        text += Type[j]
    }
    return text
}
*/
function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
        for (let i: number = 0; i < Object.keys(SelectedPlayerSetting).length / 2; i++) {
            navigation.push(
                <button
                    // title = {i === SelectedPlayerSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedPlayerSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                    // disabled = {i === SelectedPlayerSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedPlayerSetting.ResponsibleGaming && !isUserRGSpecialist()}
                    onClick={() => selectNavigation(i)}
                    type="button"
                    key={i.toString()}
                    style={{
                        marginRight: i === Object.keys(SelectedPlayerSetting).length / 2 - 1 ? '0px' : '',
                        display: 'block',
                        float: 'left',
                    }}
                    className = {i === Main.Instance.RiskManagementState.selectedPlayerSetting ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
                    // className={i === Main.Instance.RiskManagementState.selectedPlayerSetting ? i === SelectedPlayerSetting.Countries || i === SelectedPlayerSetting.Nationalities ? 'btn btn-danger btn-sm' : 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
                >
                    <FontAwesomeIcon style={{ marginRight: '7px', float: 'left', fontSize: '17px' }} icon = {Assets_.SelectedPlayerSettingIcons[i]} />
                    {i === SelectedPlayerSetting.Countries ? Assets.CountriesAndTheirInternationalCallingCodes : injectSpaces(SelectedPlayerSetting[i])}
                </button>)
        }
    }
    // DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)
    // console.log (JSON.stringify(Main.Instance.DatabaseDataState.custom))
    navigation.push(
        <button
            onClick={() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type="button"
            key="-1"
            style={{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className="btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style={{ marginRight: '7px', float: 'left', fontSize: '17px' }} icon={faSave} />
            {Assets.Update}
        </button>)

    return navigation
}
/*
function toggleNotifyDefault (i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.notify.riskLevelDefaults[i] = !custom.notify.riskLevelDefaults[i]
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getNotifyDefaults(): JSX.Element[] {
    const notifyDefaults: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.notify && Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults.length; i++) {
        notifyDefaults.push (
            <button
                key = {i.toString()}
                onClick = {() => toggleNotifyDefault(i)}
                className = {Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults[i] ? 'btn btn-xs risk risk-' + RiskLevel[i + 1] : 'btn btn-default btn-xs'}
            >
                {RiskLevel[i + 1]}
            </button>
        )
    }
    return notifyDefaults
}
*/
export function PlayerSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    if (Main.Instance.DatabaseDataState.custom === undefined) {return <div/>}
    return (
        <div>
            <div className="clearfix">
                <h2 className="fl">{Assets.PlayerSettings}</h2>
                {/*GetLegend()*/}
            </div>
            <div>{getNavigation()}</div>
            <br />
            <br />
            <div>
                <div className="col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style={{ padding: '0px 10px 10px', position: 'relative', minHeight: '50px' }}>
                    <div
                        className="clearfix test-site-data2 light_grey"
                        style={{ marginTop: '0px !important', paddingTop: '10px', paddingBottom: '10px' }}
                    >
                        {/*InsideTitle(undefined, injectSpaces(SelectedPlayerSetting[Main.Instance.RiskManagementState.selectedPlayerSetting]), Assets.SelectedPlayerSettingIcons[Main.Instance.RiskManagementState.selectedPlayerSetting])*/}
                        {/*<br/>*/}
                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.PlayerTags ?
                            <>
                                {PlayerTags()}
                            </>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.Registration ?
                            <>
                                <h4>{Assets.SelectDeselectCountries}</h4>
                                <div className="mt5">
                                    <button type="button" onClick={() => isCountriesAllowed(true)} className={Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                                        {Assets.WhitelistPlayersFromCertainCountry}
                                    </button>
                                    <button type="button" onClick={() => isCountriesAllowed(false)} className={!Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed ? 'btn btn-danger btn-xs' : 'btn btn-default btn-xs'}>
                                        {Assets.BlacklistPlayersFromCertainCountry}
                                    </button>
                                    {getAllowedString(1, Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed ? Main.Instance.DatabaseDataState.custom.general.players.countriesAllowed : Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(1, e.target.value)} value={Main.Instance.RiskManagementState.allowedCountry}>
                                        {getOptions(1)}
                                    </select>
                                    <button onClick={() => addOption(1)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedCountry === '0'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                                <br />
                                <h4>{Assets.SelectDeselectNationalities}</h4>
                                <div className="mt5">
                                    <button type="button" onClick={() => isNationalitiesAllowed(true)} className={Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                                        {Assets.WhitelistPlayersFromCertainNationality}
                                    </button>
                                    <button type="button" onClick={() => isNationalitiesAllowed(false)} className={!Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed ? 'btn btn-danger btn-xs' : 'btn btn-default btn-xs'}>
                                        {Assets.BlacklistPlayersFromCertainNationality}
                                    </button>
                                    {getAllowedString(2, Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed ? Main.Instance.DatabaseDataState.custom.general.players.nationalitiesAllowed : Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(2, e.target.value)} value={Main.Instance.RiskManagementState.allowedNationality}>
                                        {getOptions(2)}
                                    </select>
                                    <button onClick={() => addOption(2)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedNationality === '0'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
{/*
                                <br />
                                <h4>{Assets.SelectJurisdictions}</h4>
                                <div className="mt5">
                                    <strong>{Assets.EnableJurisdiction}:</strong>&nbsp;
                                        {getAllowedString(3, Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(3, e.target.value)} value={Main.Instance.RiskManagementState.allowedJurisdiction}>
                                        {getOptions(3)}
                                    </select>
                                    <button onClick={() => addOption(3)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedJurisdiction === '0'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                                <br />
                                <h4>{Assets.SelectJurisdictionTiers}</h4>
                                <div className="mt5">
                                    <strong>{Assets.EnableJurisdictionTier}:</strong>&nbsp;
                                        {getAllowedNumber(4, Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsTiersAllowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(4, e.target.value)} value={Main.Instance.RiskManagementState.allowedJurisdictionTier}>
                                        {getOptions(4)}
                                    </select>
                                    <button onClick={() => addOption(4)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedJurisdictionTier === '-1'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
*/}
                                <br/>
                                <h4>{Assets.Various}</h4>
                                <div className="mt5 clearfix">
                                    <strong>{Assets.MinimumAgeToRegisterAnAccount}:</strong>&nbsp;
                                    <input min={13} style={{ paddingRight: '0px', width: '50px' }} type="number" value={Main.Instance.DatabaseDataState.custom.general.players.legalAge} onChange={(e) => changeLegalAge(+e.target.value)} />
                                </div>
                                <div className="mt5 clearfix">
                                    <strong className="dbl fl mt3">
                                        {Assets.PhoneNumberMandatoryWhileRegistration}:
                                    </strong>
                                    <Switch className="dbl fl ml" onClick={() => toggleSwitch(1)} on={Main.Instance.DatabaseDataState.custom.general.players.phoneNumberMandatory} />
                                </div>
                                <div className="mt5 clearfix">
                                    <strong className="dbl fl mt3">
                                        {Assets.ResponsibleGamingLimitMandatoryWhileRegistration}:
                                    </strong>
                                    <Switch className="dbl fl ml" onClick={() => toggleSwitch(2)} on={Main.Instance.DatabaseDataState.custom.general.players.rglMandatory} />
                                </div>
                                <h4>{Assets.Username}</h4>

                                <div className="mt5 clearfix">
                                    <strong className="dbl fl mt3">
                                        {Assets.ShouldNotHaveSpecialCharacters}:
                                    </strong>
                                    <Switch className="dbl fl ml" onClick={() => toggleSwitch(3)} on={Main.Instance.DatabaseDataState.custom.general.players.username.shouldNotHaveSpecialCharacters} />
                                </div>

                                <div className="mt5 clearfix">
                                    <strong className="dbl fl mt3">
                                        {Assets.ShouldNotStartWithANumber}:
                                    </strong>
                                    <Switch className="dbl fl ml" onClick={() => toggleSwitch(4)} on={Main.Instance.DatabaseDataState.custom.general.players.username.shouldNotStartWithANumber} />
                                </div>

                                <div className="mt5 clearfix">
                                    <strong className="dbl fl mt5">
                                        {Assets.ForbiddenUsernames} [{Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.username && Main.Instance.DatabaseDataState.custom.general.players.username.forbidden ? Main.Instance.DatabaseDataState.custom.general.players.username.forbidden.length : 0}]:
                                    </strong>
                                    {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.username && Main.Instance.DatabaseDataState.custom.general.players.username.forbidden ?
                                    <>
                                    <button type = "button" className = "btn btn-danger btn-sm dbl fl ml" onClick = {() => {
                                        if(confirm(Assets.DoYouWantToRemove + ' ' + Assets.All + ' ' + Assets.ForbiddenUsernames)) {
                                            const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
                                            delete (custom.general.players.username.forbidden)
                                            Logic.Type.New (Main.Instance.DatabaseDataState, {custom})
                                        }
                                    }}>
                                        {Assets.Delete + ' ' + Assets.All.toLowerCase() + ' ' + Assets.ForbiddenUsernames.toLowerCase()}
                                    </button>

                                    <button type = "button" className = {Main.Instance.DatabaseDataState.showForbiddenUsernames ? 'btn btn-success btn-sm dbl fl ml' : 'btn btn-default btn-sm dbl fl ml'} onClick = {() => {
                                        Logic.Type.New (Main.Instance.DatabaseDataState, {showForbiddenUsernames: !Main.Instance.DatabaseDataState.showForbiddenUsernames})
                                    }}>
                                        {(Main.Instance.DatabaseDataState.showForbiddenUsernames ? Assets.Hide : Assets.Show) + ' ' + Assets.ForbiddenUsernames.toLowerCase()}
                                    </button>                                    
                                    </>
                                    : void 0}
                                    <input
                                        type = "file"
                                        className = "uploadLogoWrapper dbl"
                                        style = {{width: '300px', marginTop: '-3px', marginLeft: '10px', marginRight: '0px'}}
                                        accept = "text/plain"
                                        onClick = {(event) => event.target = null}
                                        onChange = {(event) => { 
                                            const cloneFile: File = event.target.files[0]
                                            const reader = new FileReader()
                                            reader.onload = (e) => {
                                                // console.log (reader.result)
                                                const decodedData: string = base64.decode((reader.result as string).replace('data:text/plain;base64,',''))
                                                // console.log (decodedData)
                                                const DecodedData: string[] = decodedData.split('\n')
                                                // console.log (DecodedData)
                                                const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
                                                if (custom.general.players.username.forbidden === undefined) {
                                                    custom.general.players.username.forbidden = []
                                                }
                                                for (const item of DecodedData) {
                                                    if (item.length > 0) {
                                                        let found: boolean = false
                                                        for (const Item of custom.general.players.username.forbidden) {
                                                            if (Item === item.replace('\r', '').toLowerCase()) {
                                                                found = true
                                                                break
                                                            }
                                                        }
                                                        if (!found) {
                                                            custom.general.players.username.forbidden.push (item.replace('\r', '').toLowerCase())
                                                        }
                                                    }
                                                }
                                                // update
                                                Logic.Type.New (Main.Instance.DatabaseDataState, {custom})
                                            }
                                            reader.readAsDataURL(cloneFile);
                                        }}
                                    />
                                </div>
                                {Main.Instance.DatabaseDataState.showForbiddenUsernames && Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players && Main.Instance.DatabaseDataState.custom.general.players.username && Main.Instance.DatabaseDataState.custom.general.players.username.forbidden ?
                                <p className="mt5">
                                    {getList(Main.Instance.DatabaseDataState.custom.general.players.username.forbidden)}
                                </p> : void 0}
                            </>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.Countries ?
                            <>
                                <h4>{Assets.AllowedPlayerCountries}</h4>
                                <div className="clearfix">{getStaticItems(1, Main.Instance.DatabaseDataState.countries)}</div>
                            </>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.Nationalities ?
                            <>
                                <h4>{Assets.AllowedPlayerNationalities}</h4>
                                <div className="clearfix">{getStaticItems(2, Main.Instance.DatabaseDataState.nationalities)}</div>
                            </>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.Regions ?
                            <>
                                <h4>{Assets.ListOfDefinedRegionsByCountry}</h4>
                                <div>{getRegions(Main.Instance.DatabaseDataState.countries)}</div>
                            </>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.PlayerInformation ?
                        <>
                            <div>
                                <div className = "clearfix">
                                    <h4 className = "fl dbl">{Assets.PlayerCardAccessAndModificationRules}</h4>
                                    <h5 style = {{marginTop: '14px'}} className = "fl dbl ml">({Assets.AllPlayerCardInformationIsSentTo + ' ' + Assets_.productName[0]}<sup>{Assets_.productName[1]}</sup>{Assets_.productName[2]}!)</h5>
                                </div>
                                <div>{getPlayerCard()}</div>
                            </div>
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedPlayerSetting === SelectedPlayerSetting.PostalCodes ?
                            <>
                                <h4>{Assets.ListOfDefinedPostalCodesByCountryAndRegion}</h4>
                                {/*<button onClick = {() => Do()}>ONCE!</button>*/}
                                <div>{getPostalCodes(Main.Instance.DatabaseDataState.countries, Main.Instance.DatabaseDataState.postalCodes)}</div>
                            </>
                        : void 0}

                    </div>
                </div>
            </div>
        </div>
    )
}

function getPlayerCard(): JSX.Element[] {
    // console.log (Main.Instance.DatabaseDataState.custom.general.players.playerCard)
    const ac: JSX.Element[] = []
    const ac1: JSX.Element[] = []
    const ac2: JSX.Element[] = []
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.players.playerCard.length; i++) {
        const a: JSX.Element[] = []
        // console.log (Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].name)
        a.push(
            <button disabled={true} style={{ width: '26px' }} key={i.toString()} className="btn btn-secondary btn-xs mr5px">
                {(i + 1).toString()}
            </button>,
        )

        a.push(
            <button disabled={true} style={{ width: '160px' }} key={i.toString() + '-'} className="btn btn-dark btn-xs">
                {Assets.playerCardProperty[i]}
            </button>,
        )

        a.push(
            <button disabled = {!isUserGamanzaAdmin()} style={{ width: '135px' }} onClick={() => ToggleSwitch(i, 5)} key={i.toString() + '___'} className={Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags && Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags.sentUser ? 'btn btn-success btn-xs mr5px' : 'btn btn-default btn-xs mr5px'}>
                {Assets.isSentToUserClient}
            </button>,
        )

        a.push(
            <button disabled = {!isUserGamanzaAdmin()} style={{ width: '140px' }} onClick={() => ToggleSwitch(i, 6)} key={i.toString() + '_'} className={Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags && Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags.viewUser ? 'btn btn-success btn-xs mr5px' : 'btn btn-default btn-xs mr5px'}>
                {Assets.canBeViewedByUser}
            </button>,
        )

        a.push(
            <button disabled = {!isUserGamanzaAdmin()} style={{ width: '150px' }} onClick={() => ToggleSwitch(i, 7)} key={i.toString() + '__'} className={Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags && Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags.modifyUser ? 'btn btn-success btn-xs mr5px' : 'btn btn-default btn-xs mr5px'}>
                {Assets.canBeModifiedByUser}
            </button>,
        )

        a.push(
            <button disabled = {!isUserGamanzaAdmin() || i === 8 || i === 34 || i === 54 || i === 55 || i === 56 || i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5 || i === 6 || i === 7 || i === 10 || i === 11 || i === 12 || i === 13 || i === 14 || i === 15 || i === 16 || i === 17 || i === 18 || i === 19 || i === 20 || i === 21 || i === 22 || i === 23 || i === 24 || i === 30 || i === 31 || i === 32 || i === 33 || i === 35 || i === 36 || i === 37 || i === 38 || i === 39 || i === 41 || i === 42 || i === 43 || i === 44 || i === 45 || i === 46 || i === 47 || i === 50 || i === 51 || i === 52 || i === 53 || i === 57 || i === 58} style={{ width: '245px' }} onClick={() => ToggleSwitch(i, 9)} key={i.toString() + '____'} className={Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags && Main.Instance.DatabaseDataState.custom.general.players.playerCard[i].flags.modifyAdmin ? 'btn btn-success btn-xs mr0' : 'btn btn-danger btn-xs mr0'}>
                {Assets.canBeModifiedBy + ' ' + Assets_.productName[0]}<sup>{Assets_.productName[1]}</sup>{Assets_.productName[2]}
            </button>,
        )
        if (i < Main.Instance.DatabaseDataState.custom.general.players.playerCard.length / 2) {
            ac1.push(<div className="mt5 mr2" key={i.toString()}>{a}</div>)
        } else {
            ac2.push(<div className="mt5" key={i.toString()}>{a}</div>)
        }
    }
    ac.push(<div key="left" style={{ float: 'left' }}>{ac1}</div>)
    ac.push(<div key="right" style={{ float: 'left' }}>{ac2}</div>)
    return ac
}

/* function getList(array: string[]) {
    let s: string = ''
    for (const item of array) {
        if (s.length > 0) {
            s += ', '
        }
        s += item
    }
    return s 
} */
// aa-wurst,abart
function getList(array: string[]) {

    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const alphabet2 = ' abcdefghijklmnopqrstuvwxyz'.split('');
    // console.log (array)
    let position = 0;
    const byFirstLetter: JSX.Element[] = [];
    for (const Item of alphabet) {
        byFirstLetter.push(<h3 key = {Item}>{Item.toUpperCase()}</h3>);
        for (const Item2 of alphabet2) {
            // console.log (Item + Item2)
            let line: string = '';
            for (let i: number = position; i < array.length; i++) {
                
                if ((Item === array[i].charAt(0) || Item === 'a' && array[i].charAt(0) === 'à' || Item === 'u' && array[i].charAt(0) === 'ù' || Item === 'e' && array[i].charAt(0) === 'è' || Item === 'e' && array[i].charAt(0) === 'é' || Item === 'a' && array[i].charAt(0) === 'ä' || Item === 'o' && array[i].charAt(0) === 'ö' || Item === 'u' && array[i].charAt(0) === 'ü' || Item === 's' && array[i].charAt(0) === 'ß')
                
                && (Item2 === ' ' && array[i].length === 1 || Item2 === 'a' && array[i].charAt(1) === 'à' || Item2 === 'u' && array[i].charAt(1) === 'ù' || Item2 === 'e' && array[i].charAt(1) === 'è' || Item2 === 'e' && array[i].charAt(1) === 'é' || Item2 === array[i].charAt(1) || Item2 === 'a' && array[i].charAt(1) === 'ä' || Item2 === 'o' && array[i].charAt(1) === 'ö' || Item2 === 'u' && array[i].charAt(1) === 'ü' || Item2 === 's' && array[i].charAt(1) === 'ß')) {
                    if (i === position) {byFirstLetter.push(<h4 key = {Item+Item2}>{Item+Item2}</h4>)}
                    line += array[i] + ', ';
                } else {
                    position = i;
                    break
                }
            }
            byFirstLetter.push(<p style = {{wordBreak: 'break-all'}} key = {Item+Item2+'-'}>{line}</p>);
        }
    }
    return byFirstLetter;
}

{/*
function Do2(postalCodes: PostalCodes[], ID: number) {
    postalCodes[ID].data.country = 'CH'
    DbRoutes.wsBasicUpdate(Config.amPostalCodes, postalCodes[ID].id, postalCodes[ID].data)
    setTimeout(() => {
        if (ID < postalCodes.length - 1) Do2(postalCodes, ++ID)
    },         200)
}

function Do() {
    const postalCodes: PostalCodes[] = Object.assign([], Main.Instance.DatabaseDataState.postalCodes)
    if (postalCodes) {
        let ID: number = 2767
        // for (let i: number = 0; i < postalCodes.length; i++) {
            // console.log (i)
        Do2(postalCodes, ID)
        // }
    }
}
*/}

function selectRegion(postalCodeRegionSelected: number) {
    Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeRegionSelected})
}

function selectCountry(postalCodeCountrySelected: string) {
    Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeCountrySelected})
}

function getPostalCodes(countries: Country[], postalCodeS: PostalCodes[]): JSX.Element {
    const postalcodes = Object.assign([], postalCodeS).sort((a, b) => (a.data.plz > b.data.plz) ? 1 : (a.data.plz === b.data.plz) ? ((a.data.name > b.data.name) ? 1 : -1) : -1 )
    const pcBody: JSX.Element[] = []
    const pcInit: JSX.Element[] = []
    const pcHeader: JSX.Element[] = []
    const pcFooter: JSX.Element[] = []
    let firstRegion: string
    if (countries && postalcodes) {
        for (const item of countries) {
            // const n: number[] = [0]
            let n: number = 0
            if (item.data.regions) {
                if (firstRegion === undefined && Main.Instance.RiskManagementState.postalCodeCountrySelected === undefined) {
                    firstRegion = item.data.code
                }
                pcInit.push(<div key = {item.data.code + '_'}><button onClick = {() => selectCountry(item.data.code)} className = {Main.Instance.RiskManagementState.postalCodeCountrySelected === item.data.code || firstRegion === item.data.code ? 'btn btn-primary btn-sm mr mb3' : 'btn btn-default btn-sm mr mb3'}>{item.data.name}</button></div>)
                const postalCodesByRegion: PostalCodes[][] = []
                for (const {} of item.data.regions) {
                    // n.push(0)
                    postalCodesByRegion.push([])
                    // pcFooter.push([])
                }
                const regionsOrder: Region[] = Object.assign([], item.data.regions).sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.code > b.code) ? 1 : -1) : -1 )
                for (const PC of postalcodes) {
                    if (PC.data && PC.data.country && PC.data.country.toLowerCase() === item.data.code.toLowerCase()) {
                        // n[0]++
                        for (let i: number = 0; i < regionsOrder.length; i++) {
                            if (regionsOrder[i].code.toLowerCase() === PC.data.region.toLowerCase()) {
                                // n[i + 1]++
                                postalCodesByRegion[i].push(PC)
                                // postalCodesByRegion[i][postalCodesByRegion[i].length - 1].data.id
                                break
                            }
                        }
                        n++
                    } /* else {
                        console.log ('aaaa' + PC.id)
                    }*/
                }
                pcHeader.push(<button onClick = {() => selectRegion(-1)} key = {item.data.code} className = {Main.Instance.RiskManagementState.postalCodeRegionSelected === -1 ? 'btn btn-primary btn-sm mr mb3' : 'btn btn-dark btn-sm mr mb3'}><strong>{item.data.code}</strong> {item.data.name} ({n} {Assets.PostalCodes.toLowerCase()})</button>)
                pcHeader.push(<span style = {{marginRight: '10px'}} key = {item.data.code + '_'}>{(item.data.region ? item.data.region : Assets.Region) + ' ' + Assets.Selection.toLowerCase()}:</span>)

                if (Main.Instance.RiskManagementState.postalCodeRegionSelected === - 1) {
                    let firstDigit = 0
                    let firstDigit2 = 0
                    let firstDigit3 = 0
                    if (postalcodes) { for (let i: number = 0; i < postalcodes.length; i++) {
                        const ITEM = postalcodes[i]
                        if (Math.floor(parseInt(ITEM.data.plz)/1000) > firstDigit) {
                            firstDigit = Math.floor(parseInt(ITEM.data.plz)/1000)
                            firstDigit2 = Math.floor(parseInt(ITEM.data.plz)/100)
                            firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                            pcBody.push(<h4 style = {{clear: 'both', marginTop: '5px', marginBottom: '2px'}} key = {firstDigit.toString()}>{firstDigit * 1000}</h4>)
                        }
                        
                        if (Math.floor(parseInt(ITEM.data.plz)/100) > 0) {
                            if (Math.floor(parseInt(ITEM.data.plz)/100) > firstDigit2) {
                                firstDigit2 = Math.floor(parseInt(ITEM.data.plz)/100)
                                firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                                pcBody.push(<h5 style = {{clear: 'both', marginTop: '5px', marginBottom: '2px'}} key = {firstDigit.toString() + firstDigit2.toString()}>{firstDigit2 * 100}</h5>)
                            }
                        }

                        if (Math.floor(parseInt(ITEM.data.plz)/10) > 0) {
                            if (Math.floor(parseInt(ITEM.data.plz)/10) > firstDigit3) {
                                firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                                pcBody.push(<div key = {firstDigit.toString() + firstDigit2.toString() + firstDigit3.toString()} style = {{height: '1px', clear: 'both'}}>&nbsp;</div>)
                            }
                        }

                        if (Main.Instance.RiskManagementState.postalCodeSelected === ITEM.id) {
                            pcBody.push(
                                <div className = "fl" key = {ITEM.data.plz + '_' + ITEM.data.name}>
                                    <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 1, e.target.value, ITEM.data)} style = {{height: '22px', width: '50px', background: 'transparent', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.plz} title = {Assets.EditPostalCode}/>
                                    <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 2, e.target.value, ITEM.data)} style = {{height: '22px', width: '200px', background: '#337ab7', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.name} title = {Assets.EditPostalCodeName}/>
                                    <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 3, e.target.value, ITEM.data)} style = {{height: '22px', width: '50px', background: '#31b0d5', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.region} title = {Assets.EditPostalCodeRegion}/>
                                    <button title = {Assets.StopEditingPostalCode} style = {{padding: '0px 5px', marginBottom: '4px'}} className = "btn btn-xs btn-danger br0" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeSelected: -1})}>
                                        <FontAwesomeIcon icon = {faStop}/>
                                    </button>
                                </div>,
                            )
                        } else {
                            pcBody.push(
                                <div className = "fl" key = {ITEM.data.plz + '_' + ITEM.data.name}>
                                    <button onClick = {() => startEditingPostalCode(ITEM.id)} title = {Assets.StartEditingPostalCode} className = "btn btn-default btn-xs br0 mr0 mb3">{ITEM.data.plz}</button>
                                    <button onClick = {() => startEditingPostalCode(ITEM.id)} title = {Assets.StartEditingPostalCode} className = "btn btn-primary btn-xs mr0 br0 mb3">{ITEM.data.name}</button>
                                    <button onClick = {() => removePostalCode(ITEM.id, ITEM.data.plz, ITEM.data.name)} title = {Assets.RemovePostalCode} className = "btn btn-info btn-xs br0 mb3">{ITEM.data.region}</button>
                                </div>,
                            )
                        }
                    }
                    }
                } else {
                    for (let i: number = 0; i < regionsOrder.length; i++) {
                        let firstDigit = 0
                        let firstDigit2 = 0
                        let firstDigit3 = 0
                        if (Main.Instance.RiskManagementState.postalCodeRegionSelected === i) {
                            for (const ITEM of postalCodesByRegion[Main.Instance.RiskManagementState.postalCodeRegionSelected]) {
                                if (Math.floor(parseInt(ITEM.data.plz)/1000) > firstDigit) {
                                    firstDigit = Math.floor(parseInt(ITEM.data.plz)/1000)
                                    firstDigit2 = Math.floor(parseInt(ITEM.data.plz)/100)
                                    firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                                    pcBody.push(<h4 style = {{clear: 'both', marginTop: '5px', marginBottom: '2px'}} key = {firstDigit.toString()}>{firstDigit * 1000}</h4>)
                                } 
                                
                                if (Math.floor(parseInt(ITEM.data.plz)/100) > 0) {
                                    if (Math.floor(parseInt(ITEM.data.plz)/100) > firstDigit2) {
                                        firstDigit2 = Math.floor(parseInt(ITEM.data.plz)/100)
                                        firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                                        pcBody.push(<h5 style = {{clear: 'both', marginTop: '5px', marginBottom: '2px'}} key = {firstDigit.toString() + firstDigit2.toString()}>{firstDigit2 * 100}</h5>)
                                    }
                                }
                                
                                if (Math.floor(parseInt(ITEM.data.plz)/10) > 0) {
                                    if (Math.floor(parseInt(ITEM.data.plz)/10) > firstDigit3) {
                                        firstDigit3 = Math.floor(parseInt(ITEM.data.plz)/10)
                                        pcBody.push(<div key = {firstDigit.toString() + firstDigit2.toString() + firstDigit3.toString()} style = {{height: '1px', clear: 'both'}}>&nbsp;</div>)
                                    }
                                }
        
                                if (Main.Instance.RiskManagementState.postalCodeSelected === ITEM.id) {
                                    pcBody.push(
                                        <div className = "fl" key = {ITEM.data.plz + '_' + ITEM.data.name}>
                                            <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 1, e.target.value, ITEM.data)} style = {{height: '22px', width: '50px', background: 'transparent', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.plz} title = {Assets.EditPostalCode}/>
                                            <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 2, e.target.value, ITEM.data)} style = {{height: '22px', width: '200px', background: '#337ab7', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.name} title = {Assets.EditPostalCodeName}/>
                                            <input onChange = {(e) => editPostalCode(Main.Instance.RiskManagementState.postalCodeSelected, 3, e.target.value, ITEM.data)} style = {{height: '22px', width: '50px', background: '#31b0d5', color: '#fff', padding: '1px 2px'}} type = "text" value = {ITEM.data.region} title = {Assets.EditPostalCodeRegion}/>
                                            <button title = {Assets.StopEditingPostalCode} style = {{padding: '0px 5px', marginBottom: '4px'}} className = "btn btn-xs btn-danger br0" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeSelected: -1})}>
                                                <FontAwesomeIcon icon = {faStop}/>
                                            </button>
                                        </div>,
                                    )
                                } else {
                                    pcBody.push(
                                        <div className = "fl" key = {ITEM.data.plz + '_' + ITEM.data.name}>
                                            <button onClick = {() => startEditingPostalCode(/*postalcodes, */ITEM.id)} title = {Assets.StartEditingPostalCode} className = "btn btn-default btn-xs br0 mr0 mb3">{ITEM.data.plz}</button>
                                            <button onClick = {() => startEditingPostalCode(/*postalcodes, */ITEM.id)} title = {Assets.StartEditingPostalCode} className = "btn btn-primary btn-xs br0 mr0 mb3">{ITEM.data.name}</button>
                                            <button onClick = {() => removePostalCode(/*postalcodes, */ITEM.id, ITEM.data.plz, ITEM.data.name)} title = {Assets.RemovePostalCode} className = "btn btn-info btn-xs br0 mb3">{ITEM.data.region}</button>
                                        </div>,
                                    )
                                }
                            }
                        }
                    }
                }

                for (let i: number = 0; i < regionsOrder.length; i++) {
                    pcHeader.push(<button onClick = {() => selectRegion(i)} key = {i.toString()} className = {Main.Instance.RiskManagementState.postalCodeRegionSelected === i ? 'btn btn-info btn-sm mr mb3' : 'btn btn-default btn-sm mr mb3'}><strong>{regionsOrder[i].code}</strong> {regionsOrder[i].name} ({postalCodesByRegion[i].length})</button>)
                }
            }
        }
        // console.log (postalCodes.length)
    }


    pcFooter.push(<strong style={{ lineHeight: '30px', clear: 'both' }} className="fl dbl mr">{Assets.AddANewPostalCode}</strong>)
    pcFooter.push(<input min={0} max={9999} style={{ marginTop: '4px', height: '22px', width: '50px', paddingRight: '0px'}} className="fl dbl" title={Assets.PostalCode} /*maxLength={4}*/ disabled={!isUserAdmin()} key="-3" onChange={(e) => edit2(4, e.target.value)} type={'number'} value={Main.Instance.RiskManagementState.addPostalCode}/>)
    pcFooter.push(<input style={{ marginTop: '4px', height: '22px' }} className="fl dbl" title={Assets.Name} disabled={!isUserAdmin()} key="-2" onChange={(e) => edit(4, e.target.value)} type="text" value={Main.Instance.RiskManagementState.addPostalName}/>)
    pcFooter.push(<input style={{ marginTop: '4px', height: '22px', width: '70px' }} className="fl dbl" title={Assets.Region} /*maxLength={2}*/ disabled={!isUserAdmin()} key="-4" onChange={(e) => edit3(4, e.target.value)} type="text" value={Main.Instance.RiskManagementState.addPostalRegion}/>)
    pcFooter.push(<button style={{ marginTop: '4px' }} className="fl dbl btn btn-danger btn-xs mr0 br0" title={Assets.Insert} disabled={!isUserAdmin()} key="-1" onClick={() => add(4)}><FontAwesomeIcon icon={faPlus}/></button>)

    return (
        <div>
{/*
            <div className = "mb3">
                <button onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeGroup: true})} className = {Main.Instance.RiskManagementState.postalCodeGroup ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}>Group by region</button>
                <button onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeGroup: false})} className = {!Main.Instance.RiskManagementState.postalCodeGroup ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}>Group by first digit</button>

                <button onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeOrder: true})} className = {Main.Instance.RiskManagementState.postalCodeOrder ? 'btn btn-sm btn-info' : 'btn btn-sm btn-default'}>Order by code</button>
                <button onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeOrder: false})} className = {!Main.Instance.RiskManagementState.postalCodeOrder ? 'btn btn-sm btn-info' : 'btn btn-sm btn-default'}>Order by name</button>
            </div>
*/}
            <div>{pcInit}</div>
            <hr style = {{borderTop: '1px solid #bbb', marginTop: '5px', marginBottom: '8px'}}/>
            <div>{pcHeader}</div>
            <hr style = {{borderTop: '1px solid #bbb', marginTop: '5px', marginBottom: '0px'}}/>
            <div className = "clearfix">{pcBody}</div>
            <hr style = {{borderTop: '1px solid #bbb', marginTop: '5px', marginBottom: '0px'}}/>
            <div className = "clearfix">{pcFooter}</div>
        </div>
    )
}

function editPostalCode(id: number, which: number, value: string, data: PostalCodesData) {
    if (which === 1) { data.plz = value } else if (which === 2) { data.name = value } else if (which === 3) { data.region = value }
    DbRoutes.wsBasicUpdate(Config.amPostalCodes, id, data)
}

function startEditingPostalCode(postalCodeSelected: number) {
    Logic.Type.New (Main.Instance.RiskManagementState, {postalCodeSelected})
}
function removePostalCode(id: number, plz: string, name: string) {
    // console.log (postalcodes[index].data.name)
/*
    // first we need to find the real ID
    const postalCodes: PostalCodes[] = Object.assign([], Main.Instance.DatabaseDataState.postalCodes)
    let found: number = -1
    for (let i: number = 0; i < postalCodes.length; i++) {
        if (postalCodes[i].id === postalcodes[index].id) {
            found = i
            break
        }
    }
    if (found) {
        DbRoutes.wsBasicDelete(Config.amPostalCodes, postalcodes[index].id)
    }
*/
    if (confirm(Assets.AreYouSureThatYouWantToDeletePostalCodeWithCode + ' ' + plz + Assets._and_ + Assets.Name.toLowerCase() + ' ' + name + '?')) {
        DbRoutes.wsBasicDelete(Config.amPostalCodes, id)
    }
    // Logic.Type.New (Main.Instance.DatabaseDataState, {postalCodes})
}

function getRegions(countries: Country[]): JSX.Element[] {
    const Regions: JSX.Element[] = []
    if (countries) {
        for (const item of countries) {
            if (item.data.regions) {
                Regions.push (<h5 className = {Regions.length === 0 ? 'btn btn-warning btn-sm mr0 mb3' : 'btn btn-warning btn-sm mr0 mb3 mt'} style = {{cursor: 'default'}} key = {item.data.name}>{item.data.name} - {item.data.region ? item.data.region : Assets.Region} [N: {item.data.regions.length}]</h5>)
                const regions: JSX.Element[] = []
                const regionsOrder: Region[] = Object.assign([], item.data.regions).sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.code > b.code) ? 1 : -1) : -1 )
                for (const Item of regionsOrder) {
                    regions.push(
                    <div key = {Item.code}>
                        <button className = "btn btn-info btn-xs mr0 br0 mb3 fl" style = {{cursor: 'default'}}>{Item.code}</button>
                        <button className = "btn btn-primary btn-xs br0 mb3 fl" style = {{cursor: 'default'}}>{Item.name}</button>
                    </div>,
                    )
                }
                Regions.push(<div className = "clearfix" key = {item.data.name + '_'}>{regions}</div>)
            }
        }
    }
    return Regions
}

export function setOption(id: number, value: string) {
    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, { allowedCountry: value }) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, { allowedNationality: value }) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, { allowedJurisdiction: value }) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, { allowedJurisdictionTier: value }) } else if (id === 5) { Logic.Type.New(Main.Instance.RiskManagementState, { accountExpiryLimit: value }) }
}

export function addOption(id: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed) {
            custom.general.players.countriesAllowed.push(Main.Instance.RiskManagementState.allowedCountry)
        } else { custom.general.players.countriesDisallowed.push(Main.Instance.RiskManagementState.allowedCountry) }
        Logic.Type.New(Main.Instance.RiskManagementState, { allowedCountry: '0' })
    } else if (id === 2) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed) {
            custom.general.players.nationalitiesAllowed.push(Main.Instance.RiskManagementState.allowedNationality)
        } else { custom.general.players.nationalitiesDisallowed.push(Main.Instance.RiskManagementState.allowedNationality) }
        Logic.Type.New(Main.Instance.RiskManagementState, { allowedNationality: '0' })
    } else if (id === 3) {
        custom.general.players.jurisdictionsAllowed.push(Main.Instance.RiskManagementState.allowedJurisdiction)
        Logic.Type.New(Main.Instance.RiskManagementState, { allowedJurisdiction: '0' })
    } else if (id === 4) {
        custom.general.players.jurisdictionsTiersAllowed.push(parseInt(Main.Instance.RiskManagementState.allowedJurisdictionTier))
        Logic.Type.New(Main.Instance.RiskManagementState, { allowedJurisdictionTier: '-1' })
    } else if (id === 5) {
        custom.general.players.accountExpiryLimits.push(parseInt(Main.Instance.RiskManagementState.accountExpiryLimit))
        Logic.Type.New(Main.Instance.RiskManagementState, { accountExpiryLimit: '0' })
    }

    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}

export function getOptions(id: number, ignore?: boolean) {
    const options: JSX.Element[] = []
    if (id === 1) {
        options.push(<option key="0" value="0">{Assets.SelectCountry}</option>)
        if (Main.Instance.DatabaseDataState.countries) {
            for (const item of Main.Instance.DatabaseDataState.countries) {
                let disabled = false
                if (ignore === undefined) {
                    for (const Item of (Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed ? Main.Instance.DatabaseDataState.custom.general.players.countriesAllowed : Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed)) {
                        if (Item.toLowerCase() === item.data.code.toLowerCase()) {
                            disabled = true
                            break
                        }
                    }
                }

                options.push(<option disabled={disabled} key={item.data.code} value={item.data.code}>{item.data.name}</option>)
            }
        }
    } else if (id === 2) {
        options.push(<option key="0" value="0">{Assets.SelectNationality}</option>)
        if (Main.Instance.DatabaseDataState.nationalities) {
            for (const item of Main.Instance.DatabaseDataState.nationalities) {
                let disabled = false
                if (ignore === undefined) {
                    for (const Item of (Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed ? Main.Instance.DatabaseDataState.custom.general.players.nationalitiesAllowed : Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed)) {
                        if (Item.toLowerCase() === item.data.code3.toLowerCase()) {
                            disabled = true
                            break
                        }
                    }
                }
                options.push(<option disabled={disabled} key={item.data.code3} value={item.data.code3}>{item.data.nationality}</option>)
            }
        }
    } else if (id === 3) {
        options.push(<option key="0" value="0">{Assets.SelectJurisdiction}</option>)
        if (Main.Instance.DatabaseDataState.jurisdictionsList) {
            for (const item of Main.Instance.DatabaseDataState.jurisdictionsList) {
                let disabled = false
                if (ignore === undefined) {
                    for (const Item of Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed) {
                        if (Item.toLowerCase() === item.name.toLowerCase()) {
                            disabled = true
                            break
                        }
                    }
                }
                options.push(<option disabled={disabled} key={item.name} value={item.name}>{item.name}</option>)
            }
        }
    } else if (id === 4) {
        options.push(<option key="-1" value="-1">{Assets.SelectJurisdictionTier}</option>)

        for (let i: number = 0; i <= 3; i++) {
            let disabled = false
            if (ignore === undefined) {
                for (const Item of Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsTiersAllowed) {
                    if (Item === i) {
                        disabled = true
                        break
                    }
                }
            }

            options.push(<option disabled={disabled} key={i.toString()} value={i.toString()}>{i.toString()}</option>)
        }
    } else if (id === 5) {
        options.push(<option key="0" value="0">{Assets.SelectNumberOfDaysUntilAccountExpiry}</option>)
        for (let i: number = 1; i <= 30; i++) {
            let disabled = false
            if (ignore === undefined) {
                for (const Item of Main.Instance.DatabaseDataState.custom.general.players.accountExpiryLimits) {
                    if (Item === i) {
                        disabled = true
                        break
                    }
                }
            }

            options.push(<option disabled={disabled} key={i.toString()} value={i.toString()}>{i.toString()}</option>)
        }
    }
    return options
}

function del(id: number, i: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed) {
            custom.general.players.countriesAllowed.splice(i, 1)
        } else { custom.general.players.countriesDisallowed.splice(i, 1) }
    } else if (id === 2) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed) {
            custom.general.players.nationalitiesAllowed.splice(i, 1)
        } else { custom.general.players.nationalitiesDisallowed.splice(i, 1) }
    } else if (id === 3) {
        custom.general.players.jurisdictionsAllowed.splice(i, 1)
    } else if (id === 4) {
        custom.general.players.jurisdictionsTiersAllowed.splice(i, 1)
    } else if (id === 5) {
        custom.general.players.accountExpiryLimits.splice(i, 1)
    }

    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}

function getElement(id: number, value: string, value2?: number): string {
    // console.log (id, value, value2)
    if (id === 1) {
        if (Main.Instance.DatabaseDataState.countries) {
            for (const item of Main.Instance.DatabaseDataState.countries) {
                if (item.data.code && item.data.code.toLowerCase() === value.toLowerCase()) { return item.data.name + ' [' + value + ']'  }
            }
        }
    } else if (id === 2) {
        if (Main.Instance.DatabaseDataState.nationalities) {
            for (const item of Main.Instance.DatabaseDataState.nationalities) {
                if (item.data.code3 && item.data.code3.toLowerCase() === value.toLowerCase()) { return item.data.nationality + ' [' + value + ']' }
            }
        }
    } else if (id === 3) {
        // console.log (value)
        // console.log (Main.Instance.DatabaseDataState.jurisdictions)
        if (Main.Instance.DatabaseDataState.jurisdictionsList) {
            for (const item of Main.Instance.DatabaseDataState.jurisdictionsList) {
                if (item.code && item.code.toLowerCase() === value.toLowerCase()) { return item.code + ' (' + item.name + ')' }
            }
        }
    } else if (id === 4 || id === 5) {
        return value2.toString()
    }

    return '?'
}

export function getAllowedString(id: number, array: string[]): JSX.Element[] {
    const allowed: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        allowed.push(<button key={i.toString()} style={{ cursor: 'default' }} className="btn btn-default btn-xs br0 mr0">{getElement(id, array[i])}</button>)
        allowed.push(<button key={i.toString() + '_'} disabled={!isUserAdmin()} style={{ marginLeft: '-1px' }} onClick={() => del(id, i)} className="btn btn-danger btn-xs br0"><FontAwesomeIcon icon={faTrashAlt}/></button>)
    }

    return allowed
}

export function getAllowedNumber(id: number, array: number[]): JSX.Element[] {
    const allowed: JSX.Element[] = []
    for (let i: number = 0; i < array.length; i++) {
        allowed.push(<button key={i.toString()} style={{ cursor: 'default' }} className="btn btn-default btn-xs br0 mr0">{getElement(id, undefined, array[i])}</button>)
        allowed.push(<button key={i.toString() + '_'} disabled={!isUserAdmin()} style={{ marginLeft: '-1px' }} onClick={() => del(id, i)} className="btn btn-danger btn-xs br0"><FontAwesomeIcon icon={faTrashAlt} /></button>)
    }
    return allowed
}


function isCountriesAllowed(value: boolean) {
    if (value !== Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed) {
        const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
        custom.general.players.isCountriesAllowed = value
        Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
    }
}

function isNationalitiesAllowed(value: boolean) {
    if (value !== Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed) {
        const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
        custom.general.players.isNationalitiesAllowed = value
        Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
    }
}

function changeLegalAge(value: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    custom.general.players.legalAge = value
    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}

export function getStaticItems(id: number, array: any[], jurisdictions?: boolean): JSX.Element[] {
    const items: JSX.Element[] = []
    const Items: JSX.Element[][] = []
    const ITEMS: JSX.Element[][] = [[],[],[],[],[]]
    for (let i: number = 0; i < 26; i++) { Items.push([]) }
    if (array) {
        // console.log (array)
        for (let i: number = 0; i < array.length; i++) {
            let selected: boolean
            let selectedTier: boolean
            if (id === 1) {
                if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players) {
                    if (Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed) {
                        selected = false
                        for (const Item of Main.Instance.DatabaseDataState.custom.general.players.countriesAllowed) {
                            if (array[i].data.code.toLowerCase() === Item.toLowerCase()) {
                                selected = true
                                break
                            }
                        }
                    } else if (Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed) {
                        selected = true
                        for (const Item of Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed) {
                            if (array[i].data.code.toLowerCase() === Item.toLowerCase()) {
                                selected = false
                                break
                            }
                        }

                    }
                }
            } else if (id === 2) {
                if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players) {
                    if (Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed) {
                        selected = false
                        for (const Item of Main.Instance.DatabaseDataState.custom.general.players.nationalitiesAllowed) {
                            if (array[i].data.code3.toLowerCase() === Item.toLowerCase()) {
                                selected = true
                                break
                            }
                        }
                    } else if (Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed) {
                        selected = true
                        for (const Item of Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed) {
                            if (array[i].data.code3.toLowerCase() === Item.toLowerCase()) {
                                selected = false
                                break
                            }
                        }
                    }
                }
            } else if (id === 3) {
                if (jurisdictions) {
                    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players) {
                        if (Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed) {
                            selected = false
                            selectedTier = false
                            // console.log (Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed)
                            // console.log (array[i].data.code)
                            for (const Item of Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed) {
                                if (array[i].code.toLowerCase() === Item.toLowerCase()) {
                                    selected = true
                                    break
                                }
                            }
                        }
                        if (Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsTiersAllowed) {
                            for (const Item of Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsTiersAllowed) {
                                if (array[i].tier === Item) {
                                    selectedTier = true
                                    break
                                }
                            }
                        }
                    }    
                } else {
                    selected = false
                    selectedTier = false
                }
            }

            const item: JSX.Element[] = []
            if (id === 1) { item.push(<button title={Assets.CountryCode} style={{ cursor: 'default' }} key={i.toString()} className={selected ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-secondary btn-xs br0 mr0'}>{array[i].data.code}</button>) } else if (id === 2) { item.push(<button title={Assets.NationalityCode + ' 2'} style={{ cursor: 'default' }} key={i.toString()} className={selected ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-secondary btn-xs br0 mr0'}>{array[i].data.code3}</button>) } else if (id === 3 && (jurisdictions === undefined || jurisdictions && selected)) { item.push(<button title={Assets.Tier} style={{ cursor: 'default' }} key={i.toString()} className={selectedTier ? 'btn btn-success btn-xs br0 mr0' : array[i].active ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-secondary btn-xs br0 mr0'}>{Assets.Tier.toUpperCase() + ' ' + array[i].tier}</button>) }

            if (id === 1) { item.push(<button title={Assets.CountryName} style={{ cursor: 'default' }} key={i.toString() + '_'} className={selected ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array[i].data.name}</button>) } else if (id === 2) { item.push(<button title={Assets.Nationality} style={{ cursor: 'default' }} key={i.toString() + '_'} className={selected ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array[i].data.nationality}</button>) } else if (id === 3 && (jurisdictions === undefined || jurisdictions && selected)) { item.push(<button title={Assets.Jurisdiction} style={{ cursor: 'default' }} key={i.toString() + '_'} className={selected ? 'btn btn-success btn-xs br0 mr0' : array[i].active ? 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array[i].name}</button>) }

            if (id === 1) { item.push(<button title={Assets.CountryDialCode} style={{ cursor: 'default' }} key={i.toString() + '__'} className={selected ? 'btn btn-dark btn-xs br0 mr' : 'btn btn-secondary btn-xs br0 mr'}>{array[i].data.dial_code}</button>) } else if (id === 2) { item.push(<button title={Assets.NationalityCode} style={{ cursor: 'default' }} key={i.toString() + '__'} className={selected ? 'btn btn-dark btn-xs br0 mr' : 'btn btn-secondary btn-xs br0 mr'}>{array[i].data.code2}</button>) } else if (id === 3 && (jurisdictions === undefined || jurisdictions && selected)) { item.push(<button title={Assets.JurisdictionCode} style={{ cursor: 'default' }} key={i.toString() + '__'} className={selected ? 'btn btn-success btn-xs br0 mr' : array[i].active ? 'btn btn-primary btn-xs br0 mr' : 'btn btn-secondary btn-xs br0 mr'}>{array[i].code}</button>) }
            
            // else if (id === 2)
            // items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = "btn btn-default btn-xs br0 mr0">{array[i]['name']}</button>)
            // items.push (<button disabled = {true} style = {{marginLeft: '-1px'}} key = {i.toString() + '_'} onClick = {() => del(id, i)} className = "btn btn-danger btn-xs br0"><FontAwesomeIcon icon = {faTrashAlt}/></button>)

            if (id === 3 /* && (jurisdictions === undefined || jurisdictions && selected)*/) {
                // items.push(<div className="fl" style={{ lineHeight: '30px' }} key={i.toString()}>{item}</div>)
                // console.log (array[i].data)
                // if (item.length > 0) 
                Items[array[i].tier !== undefined ? array[i].tier : 4].push(<div style={{ lineHeight: '30px' }} key={i.toString()}>{item}</div>)
            } else if (id === 1 || id === 2) {
                const what: string = id === 1 ? 'name' : 'nationality'
                // console.log (what)
                const code: number = array[i].data[what].substring(0, 1).toUpperCase().charCodeAt(0)
                // console.log (code)
                let Code: number = code - 'A'.charCodeAt(0)
                if (Code === 132) {Code = 0} // aland islands...
                // console.log (Code)
                // console.log (Items)
                Items[Code].push(<div style={{ lineHeight: '30px' }} key={i.toString()}>{item}</div>)
            }
        }
        if (id === 3) {
            for (let i: number = 0; i < Items.length; i++) {
                if (Items[i].length > 0) {
                    // console.log (i)
                    // console.log (Items[i])
                    ITEMS[i].push(
                    <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                        <div style = {{fontWeight: 900, fontSize: '18px'}}>
                            {i === 4 ? Assets.Unclassified : Assets.Tier + ' ' + i}
                        </div>
                        <div>
                            {Items[i]}
                        </div>
                    </div>,
                    )
                }
                // ITEMS[i].push(<div className="fl" style={{ lineHeight: '30px' }} key={i.toString()}>{item}</div>)
            }
        } else if (id === 1 || id === 2) {
            let totalLength = 0
            for (let i: number = 0; i < Items.length; i++) {
                totalLength += Items[i].length
            }

            let currentLength = 0
            for (let i: number = 0; i < Items.length; i++) {
                // if (Items[i].length === 0) continue
                const ITEM: JSX.Element[] = []
                for (let j: number = 0; j < Items[i].length; j++) {
                    ITEM.push(<div key = {j.toString()} className = "clearfix">{Items[i][j]}</div>)
                }

                if (ITEM && ITEM.length > 0) {
/*
                    items.push(
                        <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                            <div style = {{fontWeight: 900, fontSize: '18px'}}>{String.fromCharCode('A'.charCodeAt(0) + i)}</div>
                            <div>{ITEM}</div>
                        </div>
                    )
*/
                    if (currentLength < Math.floor(1 * totalLength / 5)) {
                        ITEMS[0].push(
                            <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                                <div style = {{fontWeight: 900, fontSize: '18px'}}>
                                    {String.fromCharCode('A'.charCodeAt(0) + i)}
                                </div>
                                <div>{ITEM}</div>
                            </div>,
                        )
                    } else if (currentLength < Math.floor(2 * totalLength / 5)) {
                        ITEMS[1].push(
                            <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                                <div style = {{fontWeight: 900, fontSize: '18px'}}>{String.fromCharCode('A'.charCodeAt(0) + i)}</div>
                                <div>{ITEM}</div>
                            </div>,
                        )
                    } else if (currentLength < Math.floor(3 * totalLength / 5)) {
                        ITEMS[2].push(
                            <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                                <div style = {{fontWeight: 900, fontSize: '18px'}}>{String.fromCharCode('A'.charCodeAt(0) + i)}</div>
                                <div>{ITEM}</div>
                            </div>,
                        )
                    } else if (currentLength < Math.floor(4 * totalLength / 5)) {
                        ITEMS[3].push(
                            <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                                <div style = {{fontWeight: 900, fontSize: '18px'}}>{String.fromCharCode('A'.charCodeAt(0) + i)}</div>
                                <div>{ITEM}</div>
                            </div>,
                        )
                    } else {
                        ITEMS[4].push(
                            <div style = {{height: '100%'}} className = "fl" key = {i.toString()}>
                                <div style = {{fontWeight: 900, fontSize: '18px'}}>{String.fromCharCode('A'.charCodeAt(0) + i)}</div>
                                <div>{ITEM}</div>
                            </div>,
                        )
                    }
                }
                currentLength += Items[i].length
            }
        }
        items.push(
            <div key = "items" className = "clearfix">
                {getItems(ITEMS)}
            </div>,
        )
        
        items.push(<br style = {{clear: 'both'}} key = "-5"/>)
        if (id !== 3) {items.push(<strong key = "-6" style={{ lineHeight: '30px', clear: 'both' }} className="fl dbl mr">{id === 1 ? Assets.AddANewCountry + ':' : id === 2 ? Assets.AddANewNationality + ':' : id === 3 ? Assets.AddANewJurisdiction + ':' : ''}</strong>)}
        if (id !== 3) {items.push(<input min={id === 3 ? 0 : undefined} max={id === 3 ? 3 : undefined} style={{ marginTop: '4px', height: '22px', width: '50px', paddingRight: id === 3 ? '0px' : '' }} className="fl dbl" title={id === 3 ? Assets.Tier : Assets.Code} maxLength={id === 1 ? 2 : id === 2 ? 3 : id === 3 ? 1 : 0} disabled={id === 3 ? !isUserGamanzaGamesAdmin() : !isUserAdmin()} key="-3" onChange={(e) => edit2(id, e.target.value)} type={id === 3 ? 'number' : 'text'} value={id === 1 ? Main.Instance.RiskManagementState.addCountryCode : id === 2 ? Main.Instance.RiskManagementState.addNationalityCode2 : id === 3 ? Main.Instance.RiskManagementState.addJurisdictionTier : ''} />)}
        if (id !== 3) {items.push(<input style={{ marginTop: '4px', height: '22px' }} className="fl dbl" title={Assets.Name} disabled={id === 3 ? !isUserGamanzaGamesAdmin() : !isUserAdmin()} key="-2" onChange={(e) => edit(id, e.target.value)} type="text" value={id === 1 ? Main.Instance.RiskManagementState.addCountry : id === 2 ? Main.Instance.RiskManagementState.addNationality : id === 3 ? Main.Instance.RiskManagementState.addJurisdiction : ''} />)}
        if (id !== 3) {items.push(<input style={{ marginTop: '4px', height: '22px', width: '70px' }} className="fl dbl" title={id === 1 ? Assets.DialCode : id === 2 ? Assets.Code + 2 : Assets.Code} maxLength={id === 1 || id === 3 ? undefined : 2} disabled={id === 3 ? !isUserGamanzaGamesAdmin() : !isUserAdmin()} key="-4" onChange={(e) => edit3(id, e.target.value)} type="text" value={id === 1 ? Main.Instance.RiskManagementState.addCountryDialCode : id === 2 ? Main.Instance.RiskManagementState.addNationalityCode : id === 3 ? Main.Instance.RiskManagementState.addJurisdictionCode : ''} />)}
        if (id !== 3) {items.push(<button style={{ marginTop: '4px' }} className="fl dbl btn btn-danger btn-xs mr0 br0" title={Assets.Insert} disabled={id === 3 ? !isUserGamanzaGamesAdmin() : !isUserAdmin()} key="-1" onClick={() => add(id)}><FontAwesomeIcon icon={faPlus} /></button>)}
        // }
    }
    return items
}

function getItems(ITEMS: JSX.Element[][]): JSX.Element[] {
    const items: JSX.Element[] = []
    for (let i: number = 0; i < 5; i++) {
        items.push (<div style = {{width: '20%'}} className = "fl" key = {i.toString()}>{ITEMS[i]}</div>)
    }
/*
    <div style = {{width: '20%'}} className = "fl" key = "0">{ITEMS[0]}</div>
    <div style = {{width: '20%'}} className = "fl" key = "1">{ITEMS[1]}</div>
    <div style = {{width: '20%'}} className = "fl" key = "2">{ITEMS[2]}</div>
    <div style = {{width: '20%'}} className = "fl" key = "3">{ITEMS[3]}</div>
    <div style = {{width: '20%'}} className = "fl" key = "4">{ITEMS[4]}</div>
*/
    return items
}

function add(id: number) {
    // const name: string = id === 1 ? Main.Instance.RiskManagementState.addCountry : id === 2 ? Main.Instance.RiskManagementState.addNationality : ''
    // const code: string = id === 1 ? Main.Instance.RiskManagementState.addCountryCode : id === 2 ? Main.Instance.RiskManagementState.addNationalityCode : ''
    // if (id === 1) Socket.Send (Config.amBasicInsert, {table: Config.amCountries, data: {code: Main.Instance.RiskManagementState.addCountryCode, }})
    if (id === 1) {
        DbRoutes.wsBasicInsert(Config.amCountries, { code: Main.Instance.RiskManagementState.addCountryCode, name: Main.Instance.RiskManagementState.addCountry, dial_code: Main.Instance.RiskManagementState.addCountryDialCode })
        Logic.Type.New(Main.Instance.RiskManagementState, { addCountry: '', addCountryCode: '', addCountryDialCode: '' })
    } else if (id === 2) {
        DbRoutes.wsBasicInsert(Config.amNationalities, { code2: Main.Instance.RiskManagementState.addNationalityCode2, code3: Main.Instance.RiskManagementState.addNationalityCode, nationality: Main.Instance.RiskManagementState.addNationality })
        Logic.Type.New(Main.Instance.RiskManagementState, { addNationality: '', addNationalityCode: '', addNationalityCode2: '' })
    } else if (id === 3 && parseInt(Main.Instance.RiskManagementState.addJurisdictionTier) >= 0 && parseInt(Main.Instance.RiskManagementState.addJurisdictionTier) < 3) {
/*
        DbRoutes.wsBasicInsert(Config.amJurisdictions, { tier: parseInt(Main.Instance.RiskManagementState.addJurisdictionTier), name: Main.Instance.RiskManagementState.addJurisdiction, code: Main.Instance.RiskManagementState.addJurisdictionCode, active: false })
        Logic.Type.New(Main.Instance.RiskManagementState, { addJurisdiction: '', addJurisdictionTier: '' })
*/
        DbRoutes.insertJurisdiction({ tier: parseInt(Main.Instance.RiskManagementState.addJurisdictionTier), name: Main.Instance.RiskManagementState.addJurisdiction, code: Main.Instance.RiskManagementState.addJurisdictionCode, active: false })
    } else if (id === 3) {
        alert(Assets.toj)
    } else if (id === 4) {
        DbRoutes.wsBasicInsert(Config.amPostalCodes, { plz: Main.Instance.RiskManagementState.addPostalCode, name: Main.Instance.RiskManagementState.addPostalName, region: Main.Instance.RiskManagementState.addPostalRegion})
        Logic.Type.New(Main.Instance.RiskManagementState, { addPostalCode: '', addPostalName: '', addPostalRegion: ''})
    }
}

function edit(id: number, value: string) {
    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, { addCountry: value }) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, { addNationality: value }) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, { addJurisdiction: value }) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, { addPostalName: value }) }
}

function edit2(id: number, value: string) {
    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, { addCountryCode: value }) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, { addNationalityCode2: value }) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, { addJurisdictionTier: value }) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, { addPostalCode: value }) }
}

function edit3(id: number, value: string) {
    if (id === 1) { Logic.Type.New(Main.Instance.RiskManagementState, { addCountryDialCode: value }) } else if (id === 2) { Logic.Type.New(Main.Instance.RiskManagementState, { addNationalityCode: value }) } else if (id === 3) { Logic.Type.New(Main.Instance.RiskManagementState, { addJurisdictionCode: value }) } else if (id === 4) { Logic.Type.New(Main.Instance.RiskManagementState, { addPostalRegion: value }) }
}

/*
function del(id: number, i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) custom.general.languages.splice (i, 1)
    else if (id === 2) custom.general.timeUnits.splice (i, 1)
    else if (id === 3) custom.general.timePeriods.splice (i, 1)
    else if (id === 4) custom.general.currencies.splice (i, 1)
    else if (id === 5) custom.general.communications.splice (i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getItems (id: number, array: string[]): JSX.Element[] { 
    const items: JSX.Element[] = []
    if (array) {
        for (let i: number = 0; i < array.length; i++) {
            items.push (<button style = {{cursor: 'default'}} key = {i.toString()} className = "btn btn-default btn-xs br0 mr0">{array[i]}</button>)
            items.push (<button disabled = {!isUserAdmin()} style = {{marginLeft: '-1px'}} key = {i.toString() + '_'} onClick = {() => del(id, i)} className = "btn btn-danger btn-xs br0"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
        }
        // if (isUserAdmin()) {
        items.push (<input disabled = {!isUserAdmin()} key = "-2" onChange = {(e) => edit (id, e.target.value)} style = {{height: '22px'}} type = "text" value = {id === 1 ? Main.Instance.RiskManagementState.addLanguage : id === 2 ? Main.Instance.RiskManagementState.addTimeUnit : id === 3 ? Main.Instance.RiskManagementState.addTimePeriod : id === 4 ? Main.Instance.RiskManagementState.addCurrency : id === 5 ? Main.Instance.RiskManagementState.addCommunication : ''}/>)
        items.push (<button disabled = {!isUserAdmin()} key = "-1" onClick = {() => add(id)} className = "btn btn-primary btn-xs mr0 br0"><FontAwesomeIcon icon = {faPlus}/></button>)
        // }
    }
    return items
}

function set(a: string, key: string, value: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.descriptions[a][key] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getDescriptions (a: string): JSX.Element[] {
    const descriptions: JSX.Element[] = []
    for (const key in Main.Instance.DatabaseDataState.custom.general.descriptions[a]) {
        descriptions.push(<div className = "clearfix mt3" key = {a + '_' + key} style = {{width: '100%'}}><div className = "fl" style = {{width: '12%', fontWeight: 700, lineHeight: '26px'}}>{key === 'settings' ? 'notify' : key}:</div><input className = "fl dbl" style = {{width: '88%'}} onChange = {(e) => set(a, key, e.target.value)} type = "text" value = {Main.Instance.DatabaseDataState.custom.general.descriptions[a][key]}/></div>)
    }
    return descriptions
}

function handleCheckbox (id: number, format: Assets_.pdf|'jpg'|'png') {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)

    if (id === 0) custom.KYC.identification.documentsFormat.indexOf(format) === -1? custom.KYC.identification.documentsFormat.push(format) : custom.KYC.identification.documentsFormat.splice(custom.KYC.identification.documentsFormat.indexOf(format), 1)
    else if (id === 1) custom.KYC.proofOfAddress.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfAddress.documentsFormat.push(format) : custom.KYC.proofOfAddress.documentsFormat.splice(custom.KYC.proofOfAddress.documentsFormat.indexOf(format), 1)
    else if (id === 2) custom.KYC.proofOfPayment.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfPayment.documentsFormat.push(format) : custom.KYC.proofOfPayment.documentsFormat.splice(custom.KYC.proofOfPayment.documentsFormat.indexOf(format), 1)

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function Switches (item: boolean, id: number, text: string): JSX.Element {
    return (
        <div className = "clearfix mb">
            <Switch
                className = "dbl fl mr"
                onClick = {() => handleSwitch(item, id)}
                on = {item}
            />
            <span className = "fl dbl mt3">{text}</span>
        </div>
    )
}

function HandleSwitch (value: boolean, index: number, id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 5) custom.responsibleGaming.dashboardBackend.checkNightJob = !value
    else if (id === 7) custom.riskManagement.showOnlyElevatedRisk = !value
    else if (id === 12) custom.responsibleGaming.issues.active = !value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function handleSwitch (value: boolean, id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)

    if (id === 0) custom.KYC.identification.nationalIdCard = !value
    else if (id === 1) custom.KYC.identification.passport = !value
    else if (id === 2) custom.KYC.identification.drivingLicense = !value
    else if (id === 3) custom.KYC.proofOfAddress.utilityBill = !value
    else if (id === 4) custom.KYC.proofOfAddress.bankStatement = !value
    else if (id === 5) custom.KYC.proofOfAddress.localAuthorityTaxBill = !value
    else if (id === 6) custom.KYC.proofOfPayment.bankAccount = !value
    else if (id === 7) custom.KYC.proofOfPayment.creditCard = !value
    else if (id === 8) custom.KYC.proofOfPayment.eWallet = !value

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/

function toggleSwitch(id: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) {
        custom.general.players.phoneNumberMandatory = !custom.general.players.phoneNumberMandatory
    } else if (id === 2) {
        custom.general.players.rglMandatory = !custom.general.players.rglMandatory
    } else if (id === 3) {
        if (custom.general.players.username === undefined) {custom.general.players.username = {}}
        custom.general.players.username.shouldNotHaveSpecialCharacters = !custom.general.players.username.shouldNotHaveSpecialCharacters
    } else if (id === 4) {
        if (custom.general.players.username === undefined) {custom.general.players.username = {}}
        custom.general.players.username.shouldNotStartWithANumber = !custom.general.players.username.shouldNotStartWithANumber
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}
