import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import ReactTable from 'react-table'

interface State {
    // scopes: Array<{id: number, name: string}>
    betTypes: Array<{id: number, name: string, shortName: string, outright: boolean}>
}


export class TableOfBetTypes extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            // scopes: this.getScopes(),
            betTypes: this.getBetTypes()
        }
        // console.log (this.state.betTypes)
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }
/*
    public getScopes(): Array<{name: string, id: number}> {
        const scopes: string = '{"1":"FT&nbsp;including&nbsp;OT","2":"Full&nbsp;Time","3":"1st&nbsp;Half","4":"2nd&nbsp;Half","5":"1st&nbsp;Period","6":"2nd&nbsp;Period","7":"3rd&nbsp;Period","8":"1Q","9":"2Q","10":"3Q","11":"4Q","12":"1st&nbsp;Set","13":"2nd&nbsp;Set","14":"3rd&nbsp;Set","15":"4th&nbsp;Set","16":"5th&nbsp;Set","17":"1st&nbsp;Inning","18":"2nd&nbsp;Inning","19":"3rd&nbsp;Inning","20":"4th&nbsp;Inning","21":"5th&nbsp;Inning","22":"6th&nbsp;Inning","23":"7th&nbsp;Inning","24":"8th&nbsp;Inning","25":"9th&nbsp;Inning","26":"Next&nbsp;Set","27":"Current&nbsp;Set","28":"Next&nbsp;Game","29":"Current&nbsp;Game"}'
        const Scopes: Array<{name: string, id: number}> = []
        const scope = JSON.parse(scopes)
        const keys: string[] = Object.keys(scope)
        for (const item of keys) {
            Scopes.push ({id: +item, name: scope[item].replace(/&nbsp;/g, ' ')})
        }
        // console.log (Scopes)
        return Scopes
    }
*/
    public getBetTypes(): Array<{name: string, shortName: string, outright: boolean, id: number}> {
        const betTypes: string = '{"11":{"name":"Winner","short-name":"Winner","position":"0","outright":true},"1":{"name":"1X2","short-name":"1X2","position":"1","outright":false},"3":{"name":"Home\/Away","short-name":"Home\/Away","position":"2","outright":false},"5":{"name":"Asian Handicap","short-name":"AH","position":"3","outright":false},"2":{"name":"Over\/Under","short-name":"O\/U","position":"4","outright":false},"6":{"name":"Draw No Bet","short-name":"DNB","position":"5","outright":false},"12":{"name":"European Handicap","short-name":"EH","position":"6","outright":false},"4":{"name":"Double Chance","short-name":"DC","position":"7","outright":false},"7":{"name":"To Qualify","short-name":"TQ","position":"8","outright":false},"8":{"name":"Correct Score","short-name":"CS","position":"9","outright":false},"9":{"name":"Half Time \/ Full Time","short-name":"HT\/FT","position":"10","outright":false},"10":{"name":"Odd or Even","short-name":"O\/E","position":"11","outright":false},"13":{"name":"Both Teams to Score","short-name":"BTS","position":"12","outright":false}}'
        const BetTypes: Array<{name: string, shortName: string, outright: boolean, id: number}> = []
        const betType = JSON.parse(betTypes)
        const keys: string[] = Object.keys(betType)
        for (const item of keys) {
            BetTypes.push ({id: +item, name: betType[item].name, shortName: betType[item]['short-name'], outright: betType[item].outright})
        }
        // console.log (BetTypes)
        return BetTypes
    }

    public getSport(id: number): string {
        if (id === 0) {return Assets.Any}
        if (Main.Instance.OddsState.sport) {
            for (const item of Main.Instance.OddsState.sport) {
                if (item.id === id) {
                    return item.name
                }
            }
        }
        return '-'
    }
/*
    public getScope(first: boolean): JSX.Element[] {
        const scope: JSX.Element[] = []
        for (let i: number = first ? 0 : Math.floor(this.state.scopes.length/2); i < (first ? Math.floor(this.state.scopes.length/2) : this.state.scopes.length); i++) {
            scope.push(<li key = {this.state.scopes[i].id.toString()}><strong>{this.state.scopes[i].id}</strong>&nbsp;&nbsp;{this.state.scopes[i].name}</li>)
        }
        return scope
    }
*/
    public getBetType(first: boolean): JSX.Element[] {
        const betType: JSX.Element[] = []
        for (let i: number = 0; i < this.state.betTypes.length; i++) {
            betType.push(<li key = {this.state.betTypes[i].id.toString()}><strong>{this.state.betTypes[i].id}</strong>&nbsp;&nbsp;{this.state.betTypes[i].shortName} ({this.state.betTypes[i].name})</li>)
        }
        return betType
    }

    public getBetTypeOddsportal(id: number): string {
        // console.log (this.state.betTypes)
        // console.log (id)
        if (id) {
            for (let i: number = 0; i < this.state.betTypes.length; i++) {
                if (this.state.betTypes[i].id === id) {
                    return this.state.betTypes[i].name
                }
            }
        }
        return '-'
    }

    public getOutcomes(outcomes: Array<{name: string, outcome: number}>): JSX.Element[] {
        const Outcomes: JSX.Element[] = []
        for (let i: number = 0; i < outcomes.length; i++) {
            Outcomes.push(<span style = {{fontSize: '13px'}} className = "label label-primary br0" key = {i + '_'}>{outcomes[i].name}</span>)
            Outcomes.push(<span style = {{fontSize: '13px', marginRight: i === outcomes.length - 1 ? '0px' : '5px'}} className = "label label-default br0" key = {i + '__'}>{outcomes[i].outcome}</span>)
        }
        return Outcomes
    }

    public render() {
        // console.log (Main.Instance.OddsState.bet)
        return (
            <div>
                <h3>{Assets.BetTypes + ' ' + Assets.Preview.toLowerCase()}</h3>
                {Main.Instance.OddsState.bet ?
                <>
                    <ReactTable
                        data = {Main.Instance.OddsState.bet}
                        columns = {[
                            {width: 200, Header: Assets.BetType + ' (' + Assets.OddsChecker + ')', headerClassName: 'th-left', accessor: 'id'},
                            {width: 200, Header: Assets.BetType + ' (' + Assets.OddsPortal + ')', headerClassName: 'th-left', accessor: 'oid', Cell: (row) => this.getBetTypeOddsportal(row.value)},
                            {width: 100, Header: Assets.Value, headerClassName: 'th-left', accessor: 'special_value'},
                            {width: 200, Header: Assets.Odds + ' ' + Assets.Name.toLowerCase(), headerClassName: 'th-left', accessor: 'name'},
                            {width: 100, Header: Assets.Sport, headerClassName: 'th-left', accessor: 'sportid', Cell: (row) => this.getSport(row.value)},
                            {Header: Assets.Outcomes, headerClassName: 'th-left', accessor: 'data', Cell: (row) => row.value ? this.getOutcomes(row.value)/*JSON.stringify(row.value)*/ : '-'},
                        ]}
                        showPaginationTop = {true}
                        showPaginationBottom = {false}
                        defaultPageSize={15}
                        pageSizeOptions = {[5, 10, 15, 20, 25, 50, 100, 200, 500, 1000]}
                    />
                    <br/>
{/*
                    <div className = "clearfix">
                        <div className = "fl" style = {{width: '50%'}}>
                            <strong>{Assets.BetTypes} ({Assets.OddsPortal})</strong>
                            <ul style = {{marginLeft: '20px'}}>{this.getBetType(true)}</ul>
                        </div>
                        <div className = "fl" style = {{width: '25%'}}>
                            <strong>{Assets.BetScopes}</strong>
                            <ul style = {{marginLeft: '20px'}}>{this.getScope(true)}</ul>
                        </div>
                        <div className = "fl" style = {{width: '25%'}}>
                            <ul style = {{marginLeft: '0px'}}>{this.getScope(false)}</ul>
                        </div>
                    </div>
*/}
                </> : void 0}
            </div>
        )
    }
}