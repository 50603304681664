import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import {FormInput, /*FormInputNumber,*/ FormSelect, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
// import {StremingTable} from '../../Reusables/StremingTable'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserReports, isUserGamanzaMagicHand, isUserGamanzaAdmin, isUserAdmin, isUserSystem, isUserContractualPartner, isUserMaster, isPasswordStrongEnough, isPasswordDifferent, isLocalhost, IsBuildDefault} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import {EditButton, DeleteButton} from '../../Reusables/TableUtils'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagic, faDice,faFire, faUserSecret, faUserFriends, faUser, faUserTie, faBullhorn, faEnvelope, faPhone, faSave, faChartLine} from '@fortawesome/free-solid-svg-icons'
import {CustomSecurityRights, SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import Switch from 'react-toggle-switch'
import {Environment} from '../../../Logic/Database/DatabaseData'
// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD
import * as Config from '../../../Logic/Config'
import * as Assets_ from '../../Assets_'
import axios from 'axios'


function getSecurityRights(): JSX.Element[] {
    const securityRights: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(SecurityRights).length / 2; i++) {
        if (!isUserGamanzaAdmin() && (i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN || i === SecurityRights.GAMANZA_MAGIC_HAND)) {continue}
        if (!isUserGamanzaMagicHand() && i === SecurityRights.GAMANZA_MAGIC_HAND) {continue}
        securityRights.push(
            <button
                key = {i.toString()}
                title = {Assets.ToggleSecurityRight + ' ' + Assets_.SecurityRights[i]}
                style = {{
                    color:  i === SecurityRights.ADMINISTRATOR && Main.Instance.CreateOperatorUserState.custom.securityRights.administrator ||
                            i === SecurityRights.SUPPORT && Main.Instance.CreateOperatorUserState.custom.securityRights.support ||
                            i === SecurityRights.OPERATOR && Main.Instance.CreateOperatorUserState.custom.securityRights.operator ||
                            i === SecurityRights.MARKETING && Main.Instance.CreateOperatorUserState.custom.securityRights.marketing ||
                            i === SecurityRights.RFSPECIALIST && Main.Instance.CreateOperatorUserState.custom.securityRights.rfSpecialist ||
                            i === SecurityRights.RGSPECIALIST && Main.Instance.CreateOperatorUserState.custom.securityRights.rgSpecialist ||
                            i === SecurityRights.GDPR && Main.Instance.CreateOperatorUserState.custom.securityRights.gdpr ||
                            i === SecurityRights.ACCOUNTING && Main.Instance.CreateOperatorUserState.custom.securityRights.accounting ||
                            i === SecurityRights.REPORTS && Main.Instance.CreateOperatorUserState.custom.securityRights.unused3
                            ? '#5cb85c' :
                            i === SecurityRights.GAMANZA_ADMIN && Main.Instance.CreateOperatorUserState.custom.securityRights.unused1
                            || i === SecurityRights.GAMANZA_GAMES_ADMIN && Main.Instance.CreateOperatorUserState.custom.securityRights.unused2
                            || i === SecurityRights.GAMANZA_MAGIC_HAND && Main.Instance.CreateOperatorUserState.custom.securityRights.unused7
                            ? '#f38b00'
                            : '#d9534f',
                    lineHeight: '27px',
                    fontSize: '18px',
                    fontWeight: 700,
                    width: '27px',
                    // borderRadius: '0px',
                    padding: '0px',
                    // marginRight: i === Object.keys(SecurityRights).length / 2 - 1 ? '10px' : '0px',
                    textAlign: 'center',
                    background: 'transparent',
                    border: '0px',
                }}
                // disabled = {!isUserAdmin() && !isLocalhost()}
                onClick = {() => ToggleSecurityRights(0, i)}
                className = "btn btn-default btn-xs dbl fl mr0 br0"
            >
                <FontAwesomeIcon icon = {Assets_.SecurityRightsIcons[i]}/>
            </button>,
        )
    }
    return securityRights
}
function getOptions(): JSX.Element[] {
    const o: JSX.Element[] = []

    o.push(<option value = {-1} key = "0">Select main entity</option>)
    if (Main.Instance.DatabaseDataState.cp) {
        for (const item of (Main.Instance.DatabaseDataState.cp)) {
            o.push(<option value = {item.id} key = {item.id}>{item.contractualpartner}</option>)
        }
    }

    return o
}

function ToggleEnvironmentsAllowed(what: number, which: Environment) {
    let custom: CustomSecurityRights
    if (what === 0) {
        custom = Object.assign ({}, Main.Instance.CreateOperatorUserState.custom)
    } else if (what === 1) {
        custom = Object.assign ({}, Main.Instance.CreateBrandOwnerUserState.custom)
    } else if (what === 2) {
        custom = Object.assign ({}, Main.Instance.CreateGameAssetProviderUserState.custom)
    } else if (what === 3) {
        custom = Object.assign ({}, Main.Instance.CreateGameProviderUserState.custom)
    } else if (what === 4) {
        custom = Object.assign ({}, Main.Instance.CreateMasterUserState.custom)
    } else if (what === 5) {
        custom = Object.assign ({}, Main.Instance.CreateSystemUserState.custom)
    }
    if (custom.environmentsAllowed === undefined) {
        custom.environmentsAllowed = [false, false, false, false]
    }
    custom.environmentsAllowed[which] = !custom.environmentsAllowed[which]

    if (what === 0) {
        Logic.Type.New (Main.Instance.CreateOperatorUserState, {custom})
    } else if (what === 1) {
        Logic.Type.New (Main.Instance.CreateBrandOwnerUserState, {custom})
    } else if (what === 2) {
        Logic.Type.New (Main.Instance.CreateGameAssetProviderUserState, {custom})
    } else if (what === 3) {
        Logic.Type.New (Main.Instance.CreateGameProviderUserState, {custom})
    } else if (what === 4) {
        Logic.Type.New (Main.Instance.CreateMasterUserState, {custom})
    } else if (what === 5) {
        Logic.Type.New (Main.Instance.CreateSystemUserState, {custom})
    }
/*
    Main.Instance.LoginState.environmentsAllowed ? Main.Instance.LoginState.environmentsAllowed : [])
    environmentsAllowed[which] = !environmentsAllowed[which]
    Logic.Type.New (Main.Instance.LoginState, {environmentsAllowed})
*/
}

export function ToggleSecurityRights(what: number, which: SecurityRights) {
    let custom: CustomSecurityRights
    if (what === 0) {
        custom = Object.assign ({}, Main.Instance.CreateOperatorUserState.custom)
    } else if (what === 1) {
        custom = Object.assign ({}, Main.Instance.CreateBrandOwnerUserState.custom)
    } else if (what === 2) {
        custom = Object.assign ({}, Main.Instance.CreateGameAssetProviderUserState.custom)
    } else if (what === 3) {
        custom = Object.assign ({}, Main.Instance.CreateGameProviderUserState.custom)
    } else if (what === 4) {
        custom = Object.assign ({}, Main.Instance.CreateMasterUserState.custom)
    } else if (what === 5) {
        custom = Object.assign ({}, Main.Instance.CreateSystemUserState.custom)
    }

    if (which === SecurityRights.GDPR) {
        custom.securityRights.gdpr = !custom.securityRights.gdpr
    } else if (which === SecurityRights.OPERATOR) {
        custom.securityRights.operator = !custom.securityRights.operator
    } else if (which === SecurityRights.ADMINISTRATOR) {
        custom.securityRights.administrator = !custom.securityRights.administrator
    } else if (which === SecurityRights.MARKETING) {
        custom.securityRights.marketing = !custom.securityRights.marketing
    } else if (which === SecurityRights.RFSPECIALIST) {
        custom.securityRights.rfSpecialist = !custom.securityRights.rfSpecialist
    } else if (which === SecurityRights.RGSPECIALIST) {
        custom.securityRights.rgSpecialist = !custom.securityRights.rgSpecialist
    } else if (which === SecurityRights.SUPPORT) {
        custom.securityRights.support = !custom.securityRights.support
    } else if (which === SecurityRights.ACCOUNTING) {
        custom.securityRights.accounting = !custom.securityRights.accounting
    } else if (which === SecurityRights.REPORTS) {
        if (isUserReports() && isUserAdmin() || isLocalhost()) {
            custom.securityRights.unused3 = !custom.securityRights.unused3
        } else {
            alert(Assets.YouDontHaveSecurityRights)
        }   
    } else if (which === SecurityRights.GAMANZA_ADMIN) {
        custom.securityRights.unused1 = !custom.securityRights.unused1
    } else if (which === SecurityRights.GAMANZA_GAMES_ADMIN) {
        custom.securityRights.unused2 = !custom.securityRights.unused2
    } else if (which === SecurityRights.GAMANZA_MAGIC_HAND) {
        custom.securityRights.unused7 = !custom.securityRights.unused7
    }
    if (what === 0) {
        Logic.Type.New (Main.Instance.CreateOperatorUserState, {custom})
    } else if (what === 1) {
        Logic.Type.New (Main.Instance.CreateBrandOwnerUserState, {custom})
    } else if (what === 2) {
        Logic.Type.New (Main.Instance.CreateGameAssetProviderUserState, {custom})
    } else if (what === 3) {
        Logic.Type.New (Main.Instance.CreateGameProviderUserState, {custom})
    } else if (what === 4) {
        Logic.Type.New (Main.Instance.CreateMasterUserState, {custom})
    } else if (what === 5) {
        Logic.Type.New (Main.Instance.CreateSystemUserState, {custom})
    }
}

/*
function toggleEnvironmentsAllowed(i: number) {
    const environmentsAllowed: boolean[] = Object.assign([], Main.Instance.LoginState.environmentsAllowed ? Main.Instance.LoginState.environmentsAllowed : [])
    environmentsAllowed[i] = !environmentsAllowed[i]
    Logic.Type.New (Main.Instance.LoginState, {environmentsAllowed})
}
*/

const icons: string[] = ['D', 'E', 'S', 'P']
const iconsDesc: string[] = ['Dev', 'devEdge', 'Stage', 'Production']

function getEnvironmentsAllowed(): JSX.Element[] {
    const environmentsAllowed: JSX.Element[] = []
    for(let i: number = 0; i < Object.keys(icons).length; i++) {
        environmentsAllowed.push(
            <button
                // disabled = {!isUserAdmin() && !isLocalhost()}
                key = {i.toString()}
                title = {iconsDesc[i] + ' ' + Assets.Environment}
                onClick = {() => ToggleEnvironmentsAllowed(0, i)}
                style = {{
                    // color: Main.Instance.LoginState.environmentsAllowed[i] ? '#5cb85c' : '#d9534f',
                    lineHeight: '27px',
                    fontSize: '18px',
                    fontWeight: 700,
                    width: '23px',
                    marginLeft: '1px',
                    padding: '0px',
                    textAlign: 'center',
                    // background: 'transparent',
                    // border: '0px',
                }}
                // onClick = {() => isUserAdmin() ? ToggleSecurityRights(0, i) : void 0}
                className = {Main.Instance.CreateOperatorUserState.custom.environmentsAllowed ? Main.Instance.CreateOperatorUserState.custom.environmentsAllowed[i] ? 'btn btn-success btn-xs dbl fl mr0 br0' : 'btn btn-danger btn-xs dbl fl mr0 br0' : 'btn btn-default btn-xs dbl fl mr0 br0'}
            >
                {icons[i]}
            </button>
        )
    }

    return environmentsAllowed;
}

export function getOperatorsUsersCount() {
    if (Main.Instance.CreateOperatorUserState.countCalled === false) {

        Logic.Type.New(Main.Instance.CreateOperatorUserState, {countCalled:true})
        DbRoutes.countUsers(Main.Instance.CreateOperatorUserState.filter_role,
                            Assets_.operatorAdmin,
                            Assets_.operatorSupport,
                            Assets_.operatorAccount,
                            Assets_.operatorRiskManager)
        if (!isUserContractualPartner()) {
            DbRoutes.getContractualPartnersByTypeAll(Assets_.OPERATOR)
        }
    }
}

function getOperatorsUsers() {
    if (isUserSystem()) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {users3Stream : undefined})
    } else if (isUserContractualPartner()) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {user3Stream : undefined})
    } else if (isUserMaster()) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {user3mStream : undefined})
    }
}

function clearFields() {
    if (!isUserContractualPartner()) {
        Logic.Type.New(Main.Instance.CreateOperatorUserState, {
            custom: {environmentsAllowed: [false, false, false, false], securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            operator_id : '0',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            id: '0'
        })
    } else {
        Logic.Type.New(Main.Instance.CreateOperatorUserState, {
            custom: {environmentsAllowed: [false, false, false, false], securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        })
    }
}

function handleInsertUpdate() {
    let items: any
    if (parseInt(Main.Instance.LoginState.role) === Assets_.operatorAdmin) {
        items = {
            role : 1,
            custom: JSON.stringify(Main.Instance.CreateOperatorUserState.custom),
            username:       !Main.Instance.CreateOperatorUserState.username        ? ''  : 
                            Main.Instance.CreateOperatorUserState.username,
            password:       !Main.Instance.CreateOperatorUserState.password        ? ''  : 
                            Main.Instance.CreateOperatorUserState.password,
/*
            role:           !Main.Instance.CreateOperatorUserState.role_id         ? '0' : 
                            Main.Instance.CreateOperatorUserState.role_id,
*/
            active:         !Main.Instance.CreateOperatorUserState.activity_id     ? '1' : 
                            Main.Instance.CreateOperatorUserState.activity_id,
            id:             !Main.Instance.CreateOperatorUserState.id              ? ''  : 
                            Main.Instance.CreateOperatorUserState.id,
            firstname:      !Main.Instance.CreateOperatorUserState.firstName        ? ''  : 
                            Main.Instance.CreateOperatorUserState.firstName,
            lastname:       !Main.Instance.CreateOperatorUserState.lastName        ? ''  : 
                            Main.Instance.CreateOperatorUserState.lastName,
            email:          !Main.Instance.CreateOperatorUserState.email        ? ''  : 
                            Main.Instance.CreateOperatorUserState.email,
            phone:          !Main.Instance.CreateOperatorUserState.phoneNumber        ? ''  : 
                            Main.Instance.CreateOperatorUserState.phoneNumber,
            entity_id:      IsBuildDefault() ? Main.Instance.CreateOperatorUserState.entityId : Main.Instance.LoginState.entityId,
        }
    } else  {

        items = {
            role : 1,
            custom: JSON.stringify(Main.Instance.CreateOperatorUserState.custom),

            username:       !Main.Instance.CreateOperatorUserState.username        ? ''  : 
                            Main.Instance.CreateOperatorUserState.username,
            password:       !Main.Instance.CreateOperatorUserState.password        ? ''  : 
                            Main.Instance.CreateOperatorUserState.password,
/*
            role:           !Main.Instance.CreateOperatorUserState.role_id         ? '0' : 
                            Main.Instance.CreateOperatorUserState.role_id,
*/
            active:         !Main.Instance.CreateOperatorUserState.activity_id     ? '1' : 
                            Main.Instance.CreateOperatorUserState.activity_id,
            id:             !Main.Instance.CreateOperatorUserState.id              ? ''  : 
                            Main.Instance.CreateOperatorUserState.id,
            firstname:      !Main.Instance.CreateOperatorUserState.firstName        ? ''  : 
                            Main.Instance.CreateOperatorUserState.firstName,
            lastname:       !Main.Instance.CreateOperatorUserState.lastName        ? ''  : 
                            Main.Instance.CreateOperatorUserState.lastName,
            email:          !Main.Instance.CreateOperatorUserState.email        ? ''  : 
                            Main.Instance.CreateOperatorUserState.email,
            phone:          !Main.Instance.CreateOperatorUserState.phoneNumber        ? ''  : 
                            Main.Instance.CreateOperatorUserState.phoneNumber,
            entity_id:      !Main.Instance.CreateOperatorUserState.operator_id     ? '0' : 
                            Main.Instance.CreateOperatorUserState.operator_id,
      }
    }

    if (items.username.length === 0) {
        alert (Assets.compulsoryUsername)
        return
    } else if (items.password.length === 0 && Main.Instance.CreateOperatorUserState.mode === false) {
        alert (Assets.compulsoryPassword)
        return
    } else if (parseInt(items.operator_id) === 0) {
        alert (Assets.compulsoryCompany + Assets.Operator)
        return
/*
    } else if (parseInt(items.role) === 0) {

        alert (Assets.compulsoryRole)
        return
*/
    } else if (items.firstname.length === 0) {

        alert (Assets.compulsoryFirstName)
        return
    } else if (items.lastname.length === 0) {

        alert (Assets.compulsoryLastName)
        return
    } else if (items.email.length === 0) {

        alert (Assets.compulsoryEmail)
        return
/*
    } else if (items.phone.length === 0) {
        alert (Assets.compulsoryPhone)
        return
    } else if (items.phone.charAt(0) !== '+') {
        alert (Assets.compulsoryPhoneStartWithPlus)
        return
*/
    } else if (IsBuildDefault() && (!items.entity_id || items.entity_id === -1)) {
        alert (Assets.Select + ' ' + Assets.Entity.toLowerCase())
        return
    } else if (Main.Instance.CreateOperatorUserState.mode === false) {
    /* INSERT MODE */
        if (+Main.Instance.CreateOperatorUserState.security_question !== 0) {
            items.sq = +Main.Instance.CreateOperatorUserState.security_question
            if (Main.Instance.CreateOperatorUserState.security_question_answer === undefined ||
                Main.Instance.CreateOperatorUserState.security_question_answer === null ||
                Main.Instance.CreateOperatorUserState.security_question_answer.length === 0) {
                alert (Assets.compulsorySecurityAnswer)
                return
            }
            items.sqa = Main.Instance.CreateOperatorUserState.security_question_answer
        }

        if (parseInt(items.role) === 1) { items.role = Assets_.operatorAdmin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.operatorSupport.toString() }
        if (parseInt(items.role) === 3) { items.role = Assets_.operatorAccount.toString() }
        if (parseInt(items.role) === 4) { items.role = Assets_.operatorRiskManager.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }
        
        const item = Object.assign({}, items)
        delete (item.password)
       
        DbRoutes.insertUser (items, InsertLastPage)
    } else {
    /* UPDATE MODE*/  
        if (parseInt(items.role) === 1) { items.role = Assets_.operatorAdmin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.operatorSupport.toString() }
        if (parseInt(items.role) === 3) { items.role = Assets_.operatorAccount.toString() }
        if (parseInt(items.role) === 4) { items.role = Assets_.operatorRiskManager.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }

        if (items.password.length === 0) {
            const item = Object.assign({}, items)
            delete (item.password)
            
            DbRoutes.updateUserNoPassword (items, Update)
        } else {
            const item = Object.assign({}, items)
            delete (item.password)
           
            DbRoutes.updateUser (items, Update)
        }
    }
}

function InsertLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CreateOperatorUserState, {count:Main.Instance.CreateOperatorUserState.count++})
    LastPage()
}

function DeleteLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CreateOperatorUserState, {count:Main.Instance.CreateOperatorUserState.count--})
    LastPage()
}

function DeleteNotLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CreateOperatorUserState, {count:Main.Instance.CreateOperatorUserState.count--})
    getOperatorsUsers()
}

function Update() {
    clearFields()
    getOperatorsUsers()
}
/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.hashed SET active = ', rowData[0], rowData[7], getOperatorsUsersCount)
    }
}
*/

function toggleActive(id: number, value: string, row?: any) {
    if (id) { DbRoutes.Toggle('UPDATE admin.hashed SET active = ', id.toString(), value, getOperatorsUsersCount, undefined, row) }
}
/*
function setLoginState(which: SecurityRights, value: boolean) {
    console.log ('in')
    const custom: CustomSecurityRights = Object.assign ({}, Main.Instance.LoginState.custom)
    if (which === SecurityRights.GDPR) {custom.securityRights.gdpr = !value}
    else if (which === SecurityRights.OPERATOR) {custom.securityRights.operator = !value}
    else if (which === SecurityRights.ADMINISTRATOR) {custom.securityRights.administrator = !value}
    else if (which === SecurityRights.MARKETING) {custom.securityRights.marketing = !value}
    else if (which === SecurityRights.RFSPECIALIST) {custom.securityRights.rfSpecialist = !value}
    else if (which === SecurityRights.RGSPECIALIST) {custom.securityRights.rgSpecialist = !value}
    else if (which === SecurityRights.SUPPORT) {custom.securityRights.support = !value}
    else if (which === SecurityRights.ACCOUNTING) {custom.securityRights.accounting = !value}
    else if (which === SecurityRights.GAMANZA_ADMIN) {custom.securityRights.unused1 = !value}
    Logic.Type.New (Main.Instance.LoginState, {custom})
}
*/
export function toggleSecurityRights(what: number, func: () => void, id: number, value: boolean, which: SecurityRights, Custom: CustomSecurityRights, row: any) {
    const custom: CustomSecurityRights = Object.assign ({}, Custom)
    if (which === SecurityRights.GAMANZA_MAGIC_HAND) {
        custom.securityRights.unused7 = !value
    } else if (which === SecurityRights.GAMANZA_GAMES_ADMIN) {
        custom.securityRights.unused2 = !value
    } else if (which === SecurityRights.GAMANZA_ADMIN) {
        custom.securityRights.unused1 = !value
    } else if (which === SecurityRights.GDPR) {
        custom.securityRights.gdpr = !value
    } else if (which === SecurityRights.OPERATOR) {
        custom.securityRights.operator = !value
    } else if (which === SecurityRights.ADMINISTRATOR) {
        custom.securityRights.administrator = !value
    } else if (which === SecurityRights.MARKETING) {
        custom.securityRights.marketing = !value
    } else if (which === SecurityRights.RFSPECIALIST) {
        custom.securityRights.rfSpecialist = !value
    } else if (which === SecurityRights.RGSPECIALIST) {
        custom.securityRights.rgSpecialist = !value
    } else if (which === SecurityRights.SUPPORT) {
        custom.securityRights.support = !value
    } else if (which === SecurityRights.ACCOUNTING) {
        custom.securityRights.accounting = !value
    } else if (which === SecurityRights.REPORTS) {
        custom.securityRights.unused3 = !value
    }
    // console.log (custom)
    if (id) { DbRoutes.Action('UPDATE admin.hashed SET custom = \'' + JSON.stringify(custom) + '\'', id.toString(), func) }
    const data = Object.assign({}, row)
    data.custom = custom
    DbRoutes.insertAdminLog(Config.asAdminLogSaveEdit, 'User Management -> Manage Admin Users' , data)

    // console.log (id, Main.Instance.LoginState.id)
    if (id === Main.Instance.LoginState.id) {
        if (which === SecurityRights.GAMANZA_MAGIC_HAND) {
            Logic.Type.New (Main.Instance.LoginState, {unused7: !value})
        } else if (which === SecurityRights.GAMANZA_GAMES_ADMIN) {
            Logic.Type.New (Main.Instance.LoginState, {unused2: !value})
        } else if (which === SecurityRights.GAMANZA_ADMIN) {
            Logic.Type.New (Main.Instance.LoginState, {unused1: !value})
        } else if (which === SecurityRights.GDPR) {
            Logic.Type.New (Main.Instance.LoginState, {gdpr: !value})
        } else if (which === SecurityRights.OPERATOR) {
            Logic.Type.New (Main.Instance.LoginState, {operator: !value})
        } else if (which === SecurityRights.ADMINISTRATOR) {
            Logic.Type.New (Main.Instance.LoginState, {administrator: !value})
        } else if (which === SecurityRights.MARKETING) {
            Logic.Type.New (Main.Instance.LoginState, {marketing: !value})
        } else if (which === SecurityRights.RFSPECIALIST) {
            Logic.Type.New (Main.Instance.LoginState, {rfSpecialist: !value})
        } else if (which === SecurityRights.RGSPECIALIST) {
            Logic.Type.New (Main.Instance.LoginState, {rgSpecialist: !value})
        } else if (which === SecurityRights.SUPPORT) {
            Logic.Type.New (Main.Instance.LoginState, {support: !value})
        } else if (which === SecurityRights.ACCOUNTING) {
            Logic.Type.New (Main.Instance.LoginState, {accounting: !value})
        }
    }

    if (what === 0 && Main.Instance.CreateOperatorUserState.mode) {
        Logic.Type.New (Main.Instance.CreateOperatorUserState, {custom})
    } else if (what === 1 && Main.Instance.CreateBrandOwnerUserState.mode) {
        Logic.Type.New (Main.Instance.CreateBrandOwnerUserState, {custom})
    } else if (what === 2 && Main.Instance.CreateGameAssetProviderUserState.mode) {
        Logic.Type.New (Main.Instance.CreateGameAssetProviderUserState, {custom})
    } else if (what === 3 && Main.Instance.CreateGameProviderUserState.mode) {
        Logic.Type.New (Main.Instance.CreateGameProviderUserState, {custom})
    } else if (what === 4 && Main.Instance.CreateMasterUserState.mode) {
        Logic.Type.New (Main.Instance.CreateMasterUserState, {custom})
    } else if (what === 5 && Main.Instance.CreateSystemUserState.mode) {
        Logic.Type.New (Main.Instance.CreateSystemUserState, {custom})
    }
    
    // if (id === Main.Instance.LoginState.id) setLoginState(which, value)
}

function handleEdit(row: any) {
    gotoTop()
   
    DbRoutes.insertAdminLog (
        Config.asAdminLogEdit,
        'User Management -> Manage Admin Users',
        {id: row.original.id, username: row.original.username},
    )
    if (row) {
        let activityId:string
        // let roleId:string = (row.original.role_id % 10).toString()
        row.original.active.toString() === 'true' ? activityId = '1' : activityId = '2'

        // THIS IS MODEL TO FOLLOW
        Logic.Type.New(Main.Instance.CreateOperatorUserState, {
            custom: row.original.custom,
            custom_saved: row.original.custom,
            mode: true,
            id: row.original.id,
            username: row.original.username, 
            username_saved: row.original.username,
            password: '',
            // password_saved: rowData[2],
            operator_id: row.original.operator_id,
            parent_id_saved: row.original.operator_id,
            // role_id: roleId, 
            activity_id: activityId,
            firstName:row.original.firstname !== null ? row.original.firstname : '',
            firstName_saved:row.original.firstname !== null ? row.original.firstname : '',
            lastName:row.original.lastname !== null ? row.original.lastname : '',
            lastName_saved:row.original.lastname !== null ? row.original.lastname : '',
            email:row.original.email !== null ? row.original.email : '',
            email_saved:row.original.email !== null ? row.original.email : '',
            phoneNumber:row.original.phone !== null ? row.original.phone : '',
            phoneNumber_saved:row.original.phone !== null ? row.original.phone : '',
            // role_id_saved: roleId, 
            activity_id_saved: activityId, 
            entityId: row.original.operator_id ? row.original.operator_id : -1
        })
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.OperatorUser + ' ' + 
                    Assets.withUsername + ' "' + row.original.username + '"?')) {
            
            if (Main.Instance.CreateOperatorUserState.pNum === 
                (Math.trunc((Main.Instance.CreateOperatorUserState.count - 2) / 
                Main.Instance.CreateOperatorUserState.pSize) + 1)) {
                    // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
            // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0  
                DbRoutes.removeUser (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.removeUser (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function gotoInsertMode() {

    if (Main.Instance.CreateOperatorUserState.username === Main.Instance.CreateOperatorUserState.username_saved &&
    // (parseInt(Main.Instance.CreateOperatorUserState.role_id) === parseInt(Main.Instance.CreateOperatorUserState.role_id_saved)) &&
    (parseInt(Main.Instance.CreateOperatorUserState.activity_id) === 
    parseInt(Main.Instance.CreateOperatorUserState.activity_id_saved)) &&
    Main.Instance.CreateOperatorUserState.firstName === Main.Instance.CreateOperatorUserState.firstName_saved &&
    Main.Instance.CreateOperatorUserState.lastName === Main.Instance.CreateOperatorUserState.lastName_saved &&
    Main.Instance.CreateOperatorUserState.phoneNumber === Main.Instance.CreateOperatorUserState.phoneNumber_saved &&
    Main.Instance.CreateOperatorUserState.email === Main.Instance.CreateOperatorUserState.email_saved &&
    parseInt(Main.Instance.CreateOperatorUserState.operator_id) ===
    parseInt(Main.Instance.CreateOperatorUserState.parent_id_saved)
    && JSON.stringify(Main.Instance.CreateOperatorUserState.custom) === JSON.stringify(Main.Instance.CreateOperatorUserState.custom_saved)
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }
    }
}

function FirstPage() {

    Logic.Type.New(Main.Instance.CreateOperatorUserState, {offset:(0).toString()})
    getOperatorsUsers()
    Logic.Type.New(Main.Instance.CreateOperatorUserState, {pNum: 0})
}

// function PrevPage() {

//     if (Main.Instance.CreateOperatorUserState.pNum > 0) {

//         Logic.Type.New(Main.Instance.CreateOperatorUserState, {
//             offset:((Main.Instance.CreateOperatorUserState.pNum - 1) * 
//             Main.Instance.CreateOperatorUserState.pSize).toString()})
//         getOperatorsUsers()
//         Logic.Type.New(Main.Instance.CreateOperatorUserState, {pNum: Main.Instance.CreateOperatorUserState.pNum - 1})
//     } else {
//         LastPage()
//     }
// }

// function NextPage() {

//     if ((Main.Instance.CreateOperatorUserState.pNum + 1) * 
//         Main.Instance.CreateOperatorUserState.pSize < Main.Instance.CreateOperatorUserState.count) {

//         Logic.Type.New(Main.Instance.CreateOperatorUserState, {
//             offset: ((Main.Instance.CreateOperatorUserState.pNum + 1) * 
//             Main.Instance.CreateOperatorUserState.pSize).toString()})
//         getOperatorsUsers()
    
//         Logic.Type.New(Main.Instance.CreateOperatorUserState, {
//             pNum: Main.Instance.CreateOperatorUserState.pNum + 1})
//     } else {
//         FirstPage()
//     }
// }

function LastPage() {

    if (Main.Instance.CreateOperatorUserState.count <= Main.Instance.CreateOperatorUserState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CreateOperatorUserState.count === 0) {

            Logic.Type.New(Main.Instance.CreateOperatorUserState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.CreateOperatorUserState.count - 1) / 
                    Main.Instance.CreateOperatorUserState.pSize)
            Logic.Type.New(Main.Instance.CreateOperatorUserState, {
                offset:(npNum * Main.Instance.CreateOperatorUserState.pSize).toString()})
        }

        getOperatorsUsers()
        Logic.Type.New(Main.Instance.CreateOperatorUserState,{pNum: npNum})
    }
}
/*
function ApplyFilterRole(text:string) {

        Logic.Type.New(Main.Instance.CreateOperatorUserState,{filter_role:text})
        FirstPage()
}
*/
// function RecordsPerPageChanged(text:string) {

//         if (parseInt(text) > 0) {
//             Logic.Type.New(Main.Instance.CreateOperatorUserState,{pSize:parseInt(text)})
//             FirstPage()
//         }
// }

export function ManageAdminUsersOperator() {
    if (IsBuildDefault()) {
        if (Main.Instance.DatabaseDataState.users === undefined) {
            Logic.Type.New (Main.Instance.DatabaseDataState, {users: null})
            axios.get(Config.asNewUser, { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
            .then((response) => {
                axios.patch(Config.asCP, {}, { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
                .then((Response) => {
                    const cps: any[] = [{id: '0', contractualpartner: 'Gamanza'}]
                    for (const item of Response.data) {
                        if (+item.id < 16 && +item.id !== 8) {
                            continue
                        }
                        cps.push(item)
                    }
                    Logic.Type.New(Main.Instance.DatabaseDataState, {users: response.data, cps, cp: Response.data})
                })
            })
        }
    }
    const validation: boolean = isPasswordStrongEnough(Main.Instance.CreateOperatorUserState.password)
    const validation2: boolean = isPasswordDifferent(
        Main.Instance.CreateOperatorUserState.password,
        Main.Instance.CreateOperatorUserState.username,
        Main.Instance.CreateOperatorUserState.email,
        Main.Instance.CreateOperatorUserState.phoneNumber,
    )
    const validationsq: boolean = parseInt(Main.Instance.CreateOperatorUserState.security_question) !== 0
    const validationsqa: boolean = Main.Instance.CreateOperatorUserState.security_question_answer.length > 0

    const temp:string =   isUserMaster() ? Assets.master :
                        isUserContractualPartner() ? Assets.operator :
                        '' 
    const tOperatorUsers:string = Assets.tOperatorsUsers + Assets._for_ + 
                                temp + 
                                ' "' + Main.Instance.LoginState.entity + '"'

    return (

    <div>
        {isUserAdmin() || isLocalhost() || IsBuildDefault() ?
        <div className = "row admin-users-container">
            {IsBuildDefault() &&  <select onChange = {(e) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {entityId: +e.target.value})} style = {{background: '#fff', width: '200px', height: '22px', marginRight: '5px', paddingLeft: '5px', marginBottom: '20px'}} placeholder = "Other entities" value = {Main.Instance.CreateOperatorUserState.entityId}>{getOptions()}</select>}

            <div 
                className = {Main.Instance.CreateOperatorUserState.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
            >
                <div className = "create-input">
                <div className = "row admin-users-row">
                    <h4>
                        {Main.Instance.CreateOperatorUserState.mode ? 
                        Assets.editOperatorUser + ': ' + 
                        Main.Instance.CreateOperatorUserState.username_saved : 
                        Assets.addOperatorUser}
                    </h4>

                    {Main.Instance.CreateOperatorUserState.mode ? 
                    <ButtonIcon
                        description = {Assets.discardChanges} 
                        click = {() => gotoInsertMode()} 
                        ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : void 0}
                </div>
                <div className = "row admin-users-row">
                    <FormInput 
                        value = {!Main.Instance.CreateOperatorUserState.username ? '' : Main.Instance.CreateOperatorUserState.username} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(username) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {username})} 
                        placeholder = {Assets.pUsername} 
                        icon = {Assets_.faUserClass}
                        autoFill = "nope"
                    />
                    {/*
                    <FormSelect
                        value = {!Main.Instance.CreateOperatorUserState.role_id ? '0' : Main.Instance.CreateOperatorUserState.role_id} 
                        data = {[0]} 
                        ClassName = "form-control" 
                        o1 = {Assets.RoleQ} 
                        o2 = {Assets.Super} 
                        o3 = {Assets.Support} 
                        o4 = {Assets.Accounting}
                        o5 = {Assets.RiskManager}
                        onChange = {role_id => Logic.Type.New(Main.Instance.CreateOperatorUserState, {role_id})} 
                        icon = {Assets_.faRoleClass}
                    />
                    */}
                    {Main.Instance.CreateOperatorUserState.mode ? void 0 :
                    <>
                        <FormInput
                            validation = {validation && validation2}
                            description = {!validation ? Assets.passwordRequirements1 : !validation2 ? Assets.passwordRequirements2 : Assets.passwordRequirements0}
                            value = {!Main.Instance.CreateOperatorUserState.password ? '' : 
                                    Main.Instance.CreateOperatorUserState.password} 
                            ClassName = "form-control register-input" 
                            type = "password" 
                            onChange = {(password) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {password})} 
                            placeholder = {Assets.pPassword} 
                            icon = {Assets_.faPasswordClass}
                            autoFill = "new-password"
                        />
                        <FormSelect
                            validation = {validationsq}
                            value = {!Main.Instance.CreateOperatorUserState.security_question ? '0' : 
                                    Main.Instance.CreateOperatorUserState.security_question}
                            data = {Assets_.securityQuestions} 
                            ClassName = "form-control" 
                            o1 = {Assets_.securityQuestions[0].name} 
                            onChange = {(security_question) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {security_question})} 
                            icon = {Assets_.faQuestionCircleButtonClass}
                        />
                        <FormInput
                            validation = {validationsqa}
                            value = {!Main.Instance.CreateOperatorUserState.security_question_answer ? '' : 
                                    Main.Instance.CreateOperatorUserState.security_question_answer}
                            ClassName = "form-control register-input" 
                            type = "password" 
                            onChange = {(security_question_answer) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {security_question_answer})} 
                            placeholder = {Assets.pSecurityQuestionAnswer} 
                            icon = {Assets_.faQuestionCircleWhiteButtonClass}
                        />
                    </>}
                    {Main.Instance.CreateOperatorUserState.custom && Main.Instance.CreateOperatorUserState.custom.securityRights ? getSecurityRights() : void 0}
                    </div>

                    <div className = "row admin-users-row">
                        <FormInput 
                            value = {!Main.Instance.CreateOperatorUserState.firstName ? '' : 
                                    Main.Instance.CreateOperatorUserState.firstName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(firstName) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {firstName})} 
                            placeholder = {Assets.pFirstName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateOperatorUserState.lastName ? '' : 
                                    Main.Instance.CreateOperatorUserState.lastName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(lastName) => {Logic.Type.New(Main.Instance.CreateOperatorUserState, {lastName})}} 
                            placeholder = {Assets.pLastName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateOperatorUserState.email ? '' : 
                                    Main.Instance.CreateOperatorUserState.email} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(email) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {email})}
                            placeholder = {Assets.pEmail} 
                            icon = {Assets_.faEmailClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateOperatorUserState.phoneNumber ? '' : 
                                    Main.Instance.CreateOperatorUserState.phoneNumber} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(phoneNumber) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {phoneNumber})} 
                            placeholder = {Assets.pPhone} 
                            icon = {Assets_.faPhoneClass}
                        />

                        {isUserContractualPartner() ? 
                        <span className = "label label-primary fl label-table mr">{Main.Instance.LoginState.entity}</span> :
                        <FormSelect 
                            value = {!Main.Instance.CreateOperatorUserState.operator_id? '0' : 
                                    Main.Instance.CreateOperatorUserState.operator_id} 
                            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream} 
                            ClassName = "form-control" 
                            o1 = {Assets.EmptyDatabase} 
                            o2 = {Assets.OperatorQ} 
                            onChange = {(operator_id) => Logic.Type.New(Main.Instance.CreateOperatorUserState ,{operator_id})} 
                            icon = {Assets_.faCompanyClass}
                        />}

                        <RadioButton2
                            value = {!Main.Instance.CreateOperatorUserState.activity_id ? '1' :
                                    Main.Instance.CreateOperatorUserState.activity_id} 
                            text = {Assets.Activity}
                            ClassName = "input-group-radiobutton2 margin-bottom-sm" 
                            title = {Assets.ActivityExplained}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(activity_id) => Logic.Type.New(Main.Instance.CreateOperatorUserState, {activity_id})}
                        />
                        {getEnvironmentsAllowed()}
                    </div>
                    <div className = "row admin-users-row">
                        <ButtonIcon
                            disabled = {!Main.Instance.CreateOperatorUserState.mode && !validation}
                            description =   {Main.Instance.CreateOperatorUserState.mode ? 
                                            Assets.saveChanges : 
                                            Assets.cGameProviderUser} 
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.CreateOperatorUserState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr' : 
                                        'btn btn-primary btn-sm btn-plus fr'} 
                            Icon = {faSave as IconDefinition} 
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div>
        <br/>
        </div>
        : void 0}
        {/* <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CreateOperatorUserState.pSize ? '' : 
                Main.Instance.CreateOperatorUserState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick ={() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.CreateOperatorUserState.filter_role? '0' : 
                Main.Instance.CreateOperatorUserState.filter_role}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.RoleAny} 
                o2 = {Assets.Administrator}
                o3 = {Assets.Support}
                o4 = {Assets.Accounting}
                o5 = {Assets.RiskManager}
                onChange = {text => ApplyFilterRole(text)}
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CreateOperatorUserState.count ? Main.Instance.CreateOperatorUserState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CreateOperatorUserState.count ? Main.Instance.CreateOperatorUserState.pNum * 
                Main.Instance.CreateOperatorUserState.pSize + 1 : 0}{' - '}
                {Main.Instance.CreateOperatorUserState.count ? ((Main.Instance.CreateOperatorUserState.pNum + 1) * 
                Main.Instance.CreateOperatorUserState.pSize > Main.Instance.CreateOperatorUserState.count ? 
                Main.Instance.CreateOperatorUserState.count : (Main.Instance.CreateOperatorUserState.pNum + 1) * 
                Main.Instance.CreateOperatorUserState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.CreateOperatorUserState.count ? 
                Main.Instance.CreateOperatorUserState.pNum + 1 :0}{' / '}
                {Main.Instance.CreateOperatorUserState.count ? 
                Math.trunc((Main.Instance.CreateOperatorUserState.count - 1) /
                Main.Instance.CreateOperatorUserState.pSize+1) : 0}
            </span>
        </div> */}
        {/* console.log( Main.Instance.DatabaseDataState.user3Stream) */}
        <h3>{isUserSystem() ? Assets.tOperatorsUsers : tOperatorUsers}</h3>
        {isUserSystem() && Main.Instance.DatabaseDataState.users3Stream ||
        isUserMaster() && Main.Instance.DatabaseDataState.user3mStream ||
        isUserContractualPartner() && Main.Instance.DatabaseDataState.user3Stream ?
        <ReactTable
            data = {isUserSystem() ? Main.Instance.DatabaseDataState.users3Stream : isUserMaster() ? Main.Instance.DatabaseDataState.user3mStream : Main.Instance.DatabaseDataState.user3Stream}
            columns = {[
                {
                Header: '',
                // headerClassName: 'risk-default-light ',
                columns: [
                {width: 40, show: isUserAdmin() || isLocalhost(), Header: '', accessor: '', Cell: (row) => EditButton(row, handleEdit)},
                {Header: Assets.Username, accessor: 'username', headerClassName: 'th-left', className: 'pr', Cell: (row) => <span title = {row.value} style = {{fontWeight: row.original.id === Main.Instance.LoginState.id ? 900 : 400}}>{row.value}</span>},
                /*{minWidth: 120, Header: 'Role', accessor: 'role', headerClassName: 'th-left'},*/
                {width: 150, Header: Assets.Operator, accessor: 'operator', show: !isUserContractualPartner(), headerClassName: 'th-left'},
                {width: 150, Header: Assets.Master, accessor: 'master', show: isUserSystem(), headerClassName: 'th-left'},
                {width: 300, Header: () => <div><div style = {{position: 'absolute', left: '5px'}}>{Assets.PersonalData}</div><div title = {Assets.Allowed + ' ' + Assets.Environments} style = {{position: 'absolute', right: '5px'}}>{Assets.Environments}</div></div>, headerClassName: 'th-left', accessor: '', className: 'pr', Cell: (row) => 
                <div>
                    <div style = {{position: 'absolute', right: '0px', top: '0px'}}>
                        {getEnvironmentButtons(row.original.custom.environmentsAllowed)}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faUser} />
                        {row.original.firstname !== null && row.original.lastname !== null && row.original.firstname.length > 0 && row.original.lastname.length > 0 ?
                        row.original.firstname + ' ' + row.original.lastname : row.original.firstname !== null && row.original.firstname.length > 0 ?
                        row.original.firstname : row.original.lastname !== null && row.original.lastname.length > 0 ? row.original.lastname : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faEnvelope}/>
                        {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                    </div>
                    <div className = "personal-data">
                        <FontAwesomeIcon icon = {faPhone}/>
                        {row.original.phone !== null && row.original.phone.length > 0 ? row.original.phone : Assets.notDefined}
                    </div>
                </div>},
                
                {width: 60, Header: Assets.Active, accessor: 'active', Cell: (row) => 
                <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleActive(row.original.id, row.value, row.original)}
                        on = {row.original.active}
                        // disabled = {!isUserAdmin()}
                    />
                </div>},
                ]},
                {
                Header: Assets.Administrative + ' ' + Assets.SecurityRights.toLowerCase(),
                headerClassName: 'risk-default ',
                columns: [
                    {width: 105, Header: Assets_.SecurityRights[SecurityRights.ADMINISTRATOR], accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.administrator, SecurityRights.ADMINISTRATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.administrator}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ADMINISTRATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUserFriends}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.SUPPORT]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin()  ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.support, SecurityRights.SUPPORT, row.value, row.original)}
                        on = {row.original.custom.securityRights.support}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin()  ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.SUPPORT] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.OPERATOR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.operator, SecurityRights.OPERATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.operator}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.OPERATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span><FontAwesomeIcon icon = {faBullhorn}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.MARKETING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.marketing, SecurityRights.MARKETING, row.value, row.original)}
                        on = {row.original.custom.securityRights.marketing}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.MARKETING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span title = {Assets.RFSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RFSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.rfSpecialist, SecurityRights.RFSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rfSpecialist}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RFSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span title = {Assets.RGSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RGSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.rgSpecialist, SecurityRights.RGSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rgSpecialist}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RGSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 70, Header: <span><FontAwesomeIcon icon = {faUserSecret}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GDPR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.gdpr, SecurityRights.GDPR, row.value, row.original)}
                        on = {row.original.custom.securityRights.gdpr}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.GDPR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 110, Header: <span><FontAwesomeIcon icon = {faUserTie}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.ACCOUNTING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => !isUserAdmin() && !isUserGamanzaAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.accounting, SecurityRights.ACCOUNTING, row.value, row.original)}
                        on = {row.original.custom.securityRights.accounting}
                        // disabled = {!isUserAdmin()}
                        title = {isUserAdmin() || isUserGamanzaAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ACCOUNTING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {width: 90, Header: <span><FontAwesomeIcon icon = {faChartLine}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.REPORTS]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = {isUserAdmin() || isUserGamanzaAdmin() ? 'mt5' : 'mt5 switch-disabled'}
                        onClick = {() => (!isUserAdmin() && !isUserGamanzaAdmin() || !isUserReports()) && !isLocalhost() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused3, SecurityRights.REPORTS, row.value, row.original)}
                        on = {row.original.custom.securityRights.unused3}
                        // disabled = {(!isUserAdmin() || !isUserReports()) && !isLocalhost()}
                        title = {isUserReports() && (isUserAdmin() || isUserGamanzaAdmin()) || isLocalhost() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.REPORTS] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 95, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}><FontAwesomeIcon icon = {faFire}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN].split(' ')[0]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value, row.original) : void 0}
                        // disabled = {!isUserGamanzaAdmin()}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused1}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}><FontAwesomeIcon icon = {faDice}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused2, SecurityRights.GAMANZA_GAMES_ADMIN, row.value, row.original) : void 0}
                        // disabled = {!isUserGamanzaAdmin()}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused2}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}
                    />
                    </div>},

                    {show: isUserGamanzaAdmin() && isUserGamanzaMagicHand(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}><FontAwesomeIcon icon = {faMagic}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() && isUserGamanzaMagicHand() ? toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused7, SecurityRights.GAMANZA_MAGIC_HAND, row.value, row.original) : void 0}
                        // disabled = {!(isUserGamanzaAdmin() && isUserGamanzaMagicHand())}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused7}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}
                    />
                    </div>},
                    ]},
                {
                Header: '',
                // headerClassName: 'risk-default-light ',
                columns: [{width: 40, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => DeleteButton(row, handleDelete)}],
                },
            ]}
            showPaginationBottom = {false}
            showPaginationTop = {true}
            defaultPageSize = {10}
            // pageSize={(isUserSystem() ? Main.Instance.DatabaseDataState.users3Stream : isUserMaster() ? Main.Instance.DatabaseDataState.user3mStream : Main.Instance.DatabaseDataState.user3Stream).length < 100 ? ((isUserSystem() ? Main.Instance.DatabaseDataState.users3Stream : isUserMaster() ? Main.Instance.DatabaseDataState.user3mStream : Main.Instance.DatabaseDataState.user3Stream).length) : 100}
        />
        : getOperatorsUsersCount()}
    </div>
    )
}

function getEnvironmentButtons(e: boolean[]): JSX.Element[] {
    // console.log (e)
    const eb: JSX.Element [] = []
    for (let i: number = 0; i < icons.length; i++) {
        eb.push(
            <div
                style = {{width: '12px', padding: '0px', textAlign: 'center', fontSize: '12px', lineHeight: '14px'}}
                key = {i.toString()}
                title = {iconsDesc[i] + ' ' + Assets.Environment}
                className = {e && e[i] ? 'label label-success label-sm dbl br0' : 'label label-danger label-sm dbl br0'}
            >
                {icons[i]}
            </div>
        )
    }
    return (eb)
}