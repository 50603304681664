import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, /*FormInputNumber,*/ FormSelect, RadioButton2} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {isLocalhost, isUserReports, isUserGamanzaMagicHand, gotoTop, isUserGamanzaAdmin, isUserAdmin, isUserSystem, isUserContractualPartner, isUserMaster, /*IsBuildScandibet,*/ isPasswordStrongEnough, isPasswordDifferent} from '../../../Logic/Utils' 
import * as TableUtils from '../../Reusables/TableUtils'
import ReactTable from 'react-table'
import {faChartLine, faMagic, faDice, faFire, faUserSecret, faUserFriends, faUserTie, faBullhorn, faSave, faUser, faEnvelope, faPhone/*, faEye, faEyeSlash*/} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// state description
// count = number of records
// pNum = page to be shown (default = 0)
// pSize = number of record on page (default = 5)
// mode false -> INSERT A RECORD, which is a default value
// mode true  -> UPDATE A RECORD
import * as Config from '../../../Logic/Config'
import {SecurityRights} from '../../../Logic/UserManagement/UserManagement'
import {toggleSecurityRights, ToggleSecurityRights} from './ManageAdminUsersOperator'
import Switch from 'react-toggle-switch'
function getSecurityRights(): JSX.Element[] {
    const securityRights: JSX.Element[] = []
    for (let i: number = 0; i < Object.keys(SecurityRights).length / 2; i++) {
        if (!isUserGamanzaAdmin() && (i === SecurityRights.GAMANZA_ADMIN || i === SecurityRights.GAMANZA_GAMES_ADMIN || i === SecurityRights.GAMANZA_MAGIC_HAND)) {continue}
        if (!isUserGamanzaMagicHand() && i === SecurityRights.GAMANZA_MAGIC_HAND) {continue}
        securityRights.push(
            <button
                key = {i.toString()}
                title = {Assets.ToggleSecurityRight + ' ' + Assets_.SecurityRights[i]}
                style = {{
                    color:  i === SecurityRights.ADMINISTRATOR && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.administrator ||
                            i === SecurityRights.SUPPORT && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.support ||
                            i === SecurityRights.OPERATOR && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.operator ||
                            i === SecurityRights.MARKETING && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.marketing ||
                            i === SecurityRights.RFSPECIALIST && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.rfSpecialist ||
                            i === SecurityRights.RGSPECIALIST && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.rgSpecialist ||
                            i === SecurityRights.GDPR && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.gdpr ||
                            i === SecurityRights.ACCOUNTING && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.accounting ||
                            i === SecurityRights.REPORTS && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.unused3
                            ? '#5cb85c' :
                            i === SecurityRights.GAMANZA_ADMIN && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.unused1
                            || i === SecurityRights.GAMANZA_GAMES_ADMIN && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.unused2
                            || i === SecurityRights.GAMANZA_MAGIC_HAND && Main.Instance.CreateBrandOwnerUserState.custom.securityRights.unused7
                            ? '#f38b00'
                            : '#d9534f',
                    lineHeight: '27px',
                    fontSize: '18px',
                    fontWeight: 700,
                    width: '27px',
                    // borderRadius: '0px',
                    padding: '0px',
                    // marginRight: i === Object.keys(SecurityRights).length / 2 - 1 ? '10px' : '0px',
                    textAlign: 'center',
                    background: 'transparent',
                    border: '0px',
                }}
                onClick = {() => isUserAdmin() ? ToggleSecurityRights(1, i) : void 0}
                className = "btn btn-default btn-xs dbl fl mr0 br0"
            >
                <FontAwesomeIcon icon = {Assets_.SecurityRightsIcons[i]}/>
            </button>,
        )
    }
    return securityRights
}

export function getBrandOwnerUsersCount() {
    if (Main.Instance.CreateBrandOwnerUserState.countCalled === false) {

        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {countCalled:true})
        DbRoutes.countUsers(Main.Instance.CreateBrandOwnerUserState.filter_role,
                            Assets_.operator2Admin,
                            Assets_.operator2Support,
                            Assets_.operator2Account,
                            Assets_.operator2RiskManager)
        if (!isUserContractualPartner()) {
            DbRoutes.getContractualPartnersByTypeAll(Assets_.SITE_OWNER)
        }
        // DbRoutes.getOperators2Users ()
    }
}

function getOperatorsUsers() {
    if (isUserSystem()) {
        Logic.Type.New(Main.Instance.DatabaseDataState,{users11Stream : undefined})
    } else if (isUserContractualPartner()) {
        Logic.Type.New(Main.Instance.DatabaseDataState,{user11Stream : undefined})
    } else if (isUserMaster()) {
        Logic.Type.New(Main.Instance.DatabaseDataState,{user11mStream : undefined})
    }
}

function clearFields() {
    if (!isUserContractualPartner()) {
        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
            custom: {environmentsAllowed: [false, false, false, false], securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            site_owner_id : '0',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            security_question: '0',
            security_question_answer: '',
            id: '0'
        })
    } else {
        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{
            custom: {environmentsAllowed: [false, false, false, false], securityRights: {gdpr: false, administrator: false, operator: false, rfSpecialist: false, rgSpecialist: false, marketing: false, support: false, accounting: false, unused1: false, unused2: false, unused3: false, unused4: false, unused5: false, unused6: false, unused7: false}},
            username : '',
            password : '',
            // role_id : '0',
            activity_id : '1',
            mode : false,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            security_question: '0',
            security_question_answer: '',
        })
    }
}

function handleInsertUpdate() {
    let items: any
    if (parseInt(Main.Instance.LoginState.role) === Assets_.operator2Admin) {
        items = {
            custom: JSON.stringify(Main.Instance.CreateBrandOwnerUserState.custom),
            role : 1,
            username:       !Main.Instance.CreateBrandOwnerUserState.username        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.username,
            password:       !Main.Instance.CreateBrandOwnerUserState.password        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.password,
/*
            role:           !Main.Instance.CreateBrandOwnerUserState.role_id         ? '0' : 
                            Main.Instance.CreateBrandOwnerUserState.role_id,
*/
            active:         !Main.Instance.CreateBrandOwnerUserState.activity_id     ? '1' : 
                            Main.Instance.CreateBrandOwnerUserState.activity_id,
            id:             !Main.Instance.CreateBrandOwnerUserState.id              ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.id,
            firstname:      !Main.Instance.CreateBrandOwnerUserState.firstName        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.firstName,
            lastname:       !Main.Instance.CreateBrandOwnerUserState.lastName        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.lastName,
            email:          !Main.Instance.CreateBrandOwnerUserState.email        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.email,
            phone:          !Main.Instance.CreateBrandOwnerUserState.phoneNumber        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.phoneNumber,
            entity_id:      Main.Instance.LoginState.entityId,
        }
    }  else  {
        items = {
            custom: JSON.stringify(Main.Instance.CreateBrandOwnerUserState.custom),
            role : 1,

            username:       !Main.Instance.CreateBrandOwnerUserState.username        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.username,
            password:       !Main.Instance.CreateBrandOwnerUserState.password        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.password,
/*
            role:           !Main.Instance.CreateBrandOwnerUserState.role_id         ? '0' : 
                            Main.Instance.CreateBrandOwnerUserState.role_id,
*/
            active:         !Main.Instance.CreateBrandOwnerUserState.activity_id     ? '1' : 
                            Main.Instance.CreateBrandOwnerUserState.activity_id,
            id:             !Main.Instance.CreateBrandOwnerUserState.id              ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.id,
            firstname:       !Main.Instance.CreateBrandOwnerUserState.firstName        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.firstName,
            lastname:       !Main.Instance.CreateBrandOwnerUserState.lastName        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.lastName,
            email:       !Main.Instance.CreateBrandOwnerUserState.email        ? ''  : 
                        Main.Instance.CreateBrandOwnerUserState.email,
            phone:       !Main.Instance.CreateBrandOwnerUserState.phoneNumber        ? ''  : 
                            Main.Instance.CreateBrandOwnerUserState.phoneNumber,
            entity_id:    !Main.Instance.CreateBrandOwnerUserState.site_owner_id     ? '0' : 
                            Main.Instance.CreateBrandOwnerUserState.site_owner_id,
        }
    }

    if (items.username.length === 0) {

        alert (Assets.compulsoryUsername)
        return
    } else if (items.password.length === 0 && Main.Instance.CreateBrandOwnerUserState.mode === false) {

        alert (Assets.compulsoryPassword)
        return
    } else if (parseInt(items.operator_id) === 0) {

        alert (Assets.compulsoryCompany + Assets.Operator)
        return
/*
    } else if (parseInt(items.role) === 0) {

        alert (Assets.compulsoryRole)
        return
*/
    } else if (items.firstname.length === 0) {

        alert (Assets.compulsoryFirstName)
        return
    } else if (items.lastname.length === 0) {

        alert (Assets.compulsoryLastName)
        return
    } else if (items.email.length === 0) {

        alert (Assets.compulsoryEmail)
        return
/*
    } else if (!IsBuildScandibet() && items.phone.length === 0) {
        alert (Assets.compulsoryPhone)
        return      
    } else if (!IsBuildScandibet() && items.phone.charAt(0) !== '+') {
        alert (Assets.compulsoryPhoneStartWithPlus)
        return
*/
    } else if (Main.Instance.CreateBrandOwnerUserState.mode === false) {
    /* INSERT MODE */
        if (+Main.Instance.CreateBrandOwnerUserState.security_question !== 0) {
            items.sq = +Main.Instance.CreateBrandOwnerUserState.security_question
            if (Main.Instance.CreateBrandOwnerUserState.security_question_answer === undefined ||
                Main.Instance.CreateBrandOwnerUserState.security_question_answer === null ||
                Main.Instance.CreateBrandOwnerUserState.security_question_answer.length === 0) {
                alert (Assets.compulsorySecurityAnswer)
                return
            }
            items.sqa = Main.Instance.CreateBrandOwnerUserState.security_question_answer
        }

        if (parseInt(items.role) === 1) { items.role = Assets_.operator2Admin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.operator2Support.toString() }
        if (parseInt(items.role) === 3) { items.role = Assets_.operator2Account.toString() }
        if (parseInt(items.role) === 4) { items.role = Assets_.operator2RiskManager.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }
        
        const item = Object.assign({}, items)
        delete (item.password)
        
        DbRoutes.insertUser (items, InsertLastPage)

    } else {
    /* UPDATE MODE*/  
        if (parseInt(items.role) === 1) { items.role = Assets_.operator2Admin.toString() }
        if (parseInt(items.role) === 2) { items.role = Assets_.operator2Support.toString() }
        if (parseInt(items.role) === 3) { items.role = Assets_.operator2Account.toString() }

        if (parseInt(items.active) === 1) { items.active = 'true' }
        if (parseInt(items.active) === 2) { items.active = 'false' }

        const item = Object.assign({}, items)
        delete (item.password)
        if (items.password.length === 0) {
            
            DbRoutes.updateUserNoPassword (items, Update)
        } else {
            
            DbRoutes.updateUser (items, Update)
        }
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {count:Main.Instance.CreateBrandOwnerUserState.count++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {count:Main.Instance.CreateBrandOwnerUserState.count--})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{count:Main.Instance.CreateBrandOwnerUserState.count--})
    getOperatorsUsers()
}

function Update() {
    clearFields()
    getOperatorsUsers()
}
/*
function toggleActiveOld (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.hashed SET active = ', rowData[0], rowData[7], getOperators2UsersCount)
    }
}
*/
function toggleActive(id: number, value: string, row?: any) {
    if (id) { DbRoutes.Toggle('UPDATE admin.hashed SET active = ', id.toString(), value, getBrandOwnerUsersCount, undefined, row) }
}

/*
function handleEditOld(rowData:string[]) {
    if (rowData) {

        let activityId:string
        let roleId:string = (parseInt(rowData[3]) % 10).toString()
        rowData[7].toString() === 'true' ? activityId = '1' : activityId = '2'

        // THIS IS MODEL TO FOLLOW

        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, { 
            mode: true,
            id:rowData[0], 
            username: rowData[1], 
            username_saved: rowData[1],
            password: '',
            // password_saved: rowData[2],
            site_owner_id: rowData[2],
            parent_id_saved: rowData[2],
            role_id: roleId, 
            activity_id: activityId,  
            firstName:rowData[8],
            firstName_saved:rowData[8],
            lastName:rowData[9],
            lastName_saved:rowData[9],
            email:rowData[10],
            email_saved:rowData[10],
            phoneNumber:rowData[11],
            phoneNumber_saved:rowData[11],
            role_id_saved: roleId, 
            activity_id_saved: activityId, 
        })
    }
}
*/

function handleEdit(row: any) {
    gotoTop()
    DbRoutes.insertAdminLog (
        Config.asAdminLogEdit,
        'User Management -> Manage Admin Users',
        {id: row.original.id, username: row.original.username},
    )
    if (row) {
        const activityId: string = row.original.active.toString() === 'true' ? '1' : '2'
        // let roleId: string = (parseInt(row.original.role_id.toString()) % 10).toString()

        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
            custom: row.original.custom,
            custom_saved: row.original.custom,
            mode: true,
            id:row.original.id, 
            username: row.original.username, 
            username_saved: row.original.username,
            password: '',
            // password_saved: rowData[2],
            site_owner_id: row.original.site_owner_id,
            parent_id_saved: row.original.site_owner_id,
            // role_id: roleId, 
            activity_id: activityId,  
            firstName:row.original.firstname !== null ? row.original.firstname : '',
            firstName_saved:row.original.firstname !== null ? row.original.firstname : '',
            lastName:row.original.lastname !== null ? row.original.lastname : '',
            lastName_saved:row.original.lastname !== null ? row.original.lastname : '',
            email:row.original.email !== null ? row.original.email : '',
            email_saved:row.original.email !== null ? row.original.email : '',
            phoneNumber:row.original.phone !== null ? row.original.phone : '',
            phoneNumber_saved:row.original.phone !== null ? row.original.phone : '',
            // role_id_saved: roleId, 
            activity_id_saved: activityId, 
        })
    }
}
/*
function handleDeleteOld(rowData:string[]) {
    if (rowData) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.OperatorUser + ' ' + 
                    Assets.withUsername + ' "' + rowData[1] + '"?')) {

            if (Main.Instance.CreateBrandOwnerUserState.pNum === 
                (Math.trunc((Main.Instance.CreateBrandOwnerUserState.count - 2) / 
                Main.Instance.CreateBrandOwnerUserState.pSize) + 1))  {
                    // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                    // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                DbRoutes.removeUser (rowData[0].toString(), DeleteLastPage)
            } else {
                DbRoutes.removeUser (rowData[0].toString(), DeleteNotLastPage)
            }
        }
    }
}
*/
function handleDelete(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.OperatorUser + ' ' + Assets.withUsername + ' "' + row.original.username + '"?')) {
        

        if (Main.Instance.CreateBrandOwnerUserState.pNum === 
            (Math.trunc((Main.Instance.CreateBrandOwnerUserState.count - 2) / 
            Main.Instance.CreateBrandOwnerUserState.pSize) + 1))  {
            DbRoutes.removeUser (row.original.id.toString(), DeleteLastPage)
        } else {
            DbRoutes.removeUser (row.original.id.toString(), DeleteNotLastPage)
        }
    }
}

function gotoInsertMode() {
    if (Main.Instance.CreateBrandOwnerUserState.username === Main.Instance.CreateBrandOwnerUserState.username_saved &&
    // (parseInt(Main.Instance.CreateBrandOwnerUserState.role_id) === parseInt(Main.Instance.CreateBrandOwnerUserState.role_id_saved)) &&
    (parseInt(Main.Instance.CreateBrandOwnerUserState.activity_id) === 
    parseInt(Main.Instance.CreateBrandOwnerUserState.activity_id_saved)) &&
    Main.Instance.CreateBrandOwnerUserState.firstName === Main.Instance.CreateBrandOwnerUserState.firstName_saved &&
    Main.Instance.CreateBrandOwnerUserState.lastName === Main.Instance.CreateBrandOwnerUserState.lastName_saved &&
    Main.Instance.CreateBrandOwnerUserState.phoneNumber === 
    Main.Instance.CreateBrandOwnerUserState.phoneNumber_saved &&
    Main.Instance.CreateBrandOwnerUserState.email === Main.Instance.CreateBrandOwnerUserState.email_saved &&
    parseInt(Main.Instance.CreateBrandOwnerUserState.site_owner_id) === 
    parseInt(Main.Instance.CreateBrandOwnerUserState.parent_id_saved)
    && Main.Instance.CreateBrandOwnerUserState.custom === Main.Instance.CreateBrandOwnerUserState.custom_saved
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields()
        }                
    }     
}

function FirstPage() {
    Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {offset:(0).toString()})
    getOperatorsUsers()
    Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{pNum: 0})
}

// function PrevPage() {
//     if (Main.Instance.CreateBrandOwnerUserState.pNum > 0) {

//         Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
//             offset:((Main.Instance.CreateBrandOwnerUserState.pNum - 1) * 
//                     Main.Instance.CreateBrandOwnerUserState.pSize).toString()})
//         getOperatorsUsers()

//         Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {pNum: Main.Instance.CreateBrandOwnerUserState.pNum - 1})
//     } else {
//         LastPage()
//     }
// }

// function NextPage() {
//     if ((Main.Instance.CreateBrandOwnerUserState.pNum + 1) * 
//         Main.Instance.CreateBrandOwnerUserState.pSize < Main.Instance.CreateBrandOwnerUserState.count) {

//         Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
//             offset: ((Main.Instance.CreateBrandOwnerUserState.pNum + 1) * 
//                     Main.Instance.CreateBrandOwnerUserState.pSize).toString()})
//         getOperatorsUsers()
    
//         Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
//             pNum: Main.Instance.CreateBrandOwnerUserState.pNum + 1})                
//     } else {
//         FirstPage()
//     }
// }

function LastPage() {
    if (Main.Instance.CreateBrandOwnerUserState.count <= Main.Instance.CreateBrandOwnerUserState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CreateBrandOwnerUserState.count === 0) {

            Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.CreateBrandOwnerUserState.count - 1) / 
                    Main.Instance.CreateBrandOwnerUserState.pSize)
            Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {
                offset:(npNum * Main.Instance.CreateBrandOwnerUserState.pSize).toString()})
        }
        getOperatorsUsers()
        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{pNum: npNum})
    }
}
/*
function ApplyFilterRole(text:string) {
        Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{filter_role:text})
        FirstPage()
}
// */
// function RecordsPerPageChanged(text:string) {
//         if (parseInt(text) > 0) {
//             Logic.Type.New(Main.Instance.CreateBrandOwnerUserState,{pSize:parseInt(text)})
//             FirstPage()
//         }
// }

export function ManageAdminUsersBrandOwner() {
    const validation: boolean = isPasswordStrongEnough(Main.Instance.CreateBrandOwnerUserState.password)
    const validation2: boolean = isPasswordDifferent(
        Main.Instance.CreateBrandOwnerUserState.password,
        Main.Instance.CreateBrandOwnerUserState.username,
        Main.Instance.CreateBrandOwnerUserState.email,
        Main.Instance.CreateBrandOwnerUserState.phoneNumber,
    )
    const validationsq: boolean = parseInt(Main.Instance.CreateBrandOwnerUserState.security_question) !== 0
    const validationsqa: boolean = Main.Instance.CreateBrandOwnerUserState.security_question_answer.length > 0

    const temp:string =   isUserMaster() ? Assets.master :
                        isUserContractualPartner() ? Assets.operator2 :
                        '' 
    const tOperatorUsers:string = Assets.tOperators2Users + Assets._for_ + temp + ' "' + Main.Instance.LoginState.entity + '"'
    return (
    <div>
        {isUserAdmin() ?
        <div className = "row admin-users-container">
            <div 
                className = {Main.Instance.CreateBrandOwnerUserState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' :
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
            >

                <div className = "create-input">
                    <div className = "row admin-users-row">
                        <h4>
                            {Main.Instance.CreateBrandOwnerUserState.mode ? 
                            Assets.editOperator2User + ': ' + 
                            Main.Instance.CreateBrandOwnerUserState.username_saved : 
                            Assets.addOperator2User}
                        </h4>                    
                    {Main.Instance.CreateBrandOwnerUserState.mode ? 
                    <ButtonIcon
                        description = {Assets.discardChanges}
                        click = {() => gotoInsertMode()} 
                        ClassName = "btn btn-default btn-sm btn-plus fr mr0 btn-anything btn-cancel-absolute" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : void 0}
                </div>
                <div className = "row admin-users-row">
                    <FormInput 
                        value = {!Main.Instance.CreateBrandOwnerUserState.username ? '' : 
                                Main.Instance.CreateBrandOwnerUserState.username} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(username) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {username})}
                        placeholder = {Assets.pUsername} 
                        icon = {Assets_.faUserClass}
                        autoFill = "nope"
                    />
                    {/*
                    <FormSelect 
                        value = {!Main.Instance.CreateBrandOwnerUserState.role_id ? '0' : 
                                Main.Instance.CreateBrandOwnerUserState.role_id} 
                        data = {[0]} 
                        ClassName = "form-control" 
                        o1 = {Assets.RoleQ} 
                        o2 = {Assets.Super} 
                        o3 = {Assets.Support}  
                        o4 = {Assets.Accounting}
                        o5 = {Assets.RiskManager} 
                        onChange = {role_id => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {role_id})} 
                        icon = {Assets_.faRoleClass}
                    />
                    */}
                    {Main.Instance.CreateBrandOwnerUserState.mode ? void 0 :
                    <>
                        <FormInput 
                            validation = {validation && validation2}
                            description = {!validation ? Assets.passwordRequirements1 : !validation2 ? Assets.passwordRequirements2 : Assets.passwordRequirements0}
                            value = {!Main.Instance.CreateBrandOwnerUserState.password ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.password} 
                            ClassName = "form-control register-input" 
                            type = "password" 
                            onChange = {(password) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {password})} 
                            placeholder = {Assets.pPassword} 
                            icon = {Assets_.faPasswordClass}
                            autoFill = "new-password"
                        />

                        <FormSelect
                            validation = {validationsq}
                            value = {!Main.Instance.CreateBrandOwnerUserState.security_question ? '0' : 
                                    Main.Instance.CreateBrandOwnerUserState.security_question}
                            data = {Assets_.securityQuestions} 
                            ClassName = "form-control" 
                            o1 = {Assets_.securityQuestions[0].name} 
                            onChange = {(security_question) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {security_question})} 
                            icon = {Assets_.faQuestionCircleButtonClass}
                        />

                        <FormInput
                            validation = {validationsqa}
                            value = {!Main.Instance.CreateBrandOwnerUserState.security_question_answer ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.security_question_answer}
                            ClassName = "form-control register-input" 
                            type = "password" 
                            onChange = {(security_question_answer) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {security_question_answer})} 
                            placeholder = {Assets.pSecurityQuestionAnswer} 
                            icon = {Assets_.faQuestionCircleWhiteButtonClass}
                        />
                    </>}
                    {Main.Instance.CreateBrandOwnerUserState.custom && Main.Instance.CreateBrandOwnerUserState.custom.securityRights ? getSecurityRights() : void 0}
                    </div>

                    <div className = "row admin-users-row">
                        <FormInput 
                            value = {!Main.Instance.CreateBrandOwnerUserState.firstName ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.firstName} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(firstName) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {firstName})}
                            placeholder = {Assets.pFirstName} 
                            icon = {Assets_.faUserClass}
                        /> 
                        <FormInput 
                            value = {!Main.Instance.CreateBrandOwnerUserState.lastName ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.lastName} 
                            ClassName = "form-control register-input" 
                            type = "text"
                            onChange = {(lastName) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {lastName})} 
                            placeholder = {Assets.pLastName} 
                            icon = {Assets_.faUserClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateBrandOwnerUserState.email ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.email} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(email) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {email})} 
                            placeholder = {Assets.pEmail} 
                            icon = {Assets_.faEmailClass}
                        />
                        <FormInput 
                            value = {!Main.Instance.CreateBrandOwnerUserState.phoneNumber ? '' : 
                                    Main.Instance.CreateBrandOwnerUserState.phoneNumber} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(phoneNumber) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {phoneNumber})}
                            placeholder = {Assets.pPhone} 
                            icon = {Assets_.faPhoneClass}
                        />

                    <RadioButton2
                        value = {!Main.Instance.CreateBrandOwnerUserState.activity_id ? '1' :
                                Main.Instance.CreateBrandOwnerUserState.activity_id} 
                        text = {Assets.Activity}
                        ClassName = "input-group-radiobutton2 margin-bottom-sm" 
                        title = {Assets.ActivityExplained}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(activity_id) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {activity_id})}                         
                    />

                    {parseInt(Main.Instance.LoginState.role) === Assets_.operator2Admin ? 
                    <span className = "label label-primary fl label-table mr">{Main.Instance.LoginState.entity}</span>
                    :
                    <FormSelect 
                        value = {!Main.Instance.CreateBrandOwnerUserState.site_owner_id ? '0' : 
                                Main.Instance.CreateBrandOwnerUserState.site_owner_id} 
                        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2} 
                        ClassName = "form-control" 
                        o1 = {Assets.EmptyDatabase} 
                        o2 = {Assets.Operator2Q} 
                        onChange = {(site_owner_id) => Logic.Type.New(Main.Instance.CreateBrandOwnerUserState, {site_owner_id})}
                        icon = {Assets_.faCompanyClass}
                    />}
                    </div>

                    <div className = "row admin-users-row">
                        <ButtonIcon
                            disabled = {!Main.Instance.CreateBrandOwnerUserState.mode && !validation}
                            description = {Main.Instance.CreateBrandOwnerUserState.mode ? Assets.saveChanges : Assets.cGameProviderUser}
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.CreateBrandOwnerUserState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr' :
                                        'btn btn-primary btn-sm btn-plus fr'} 
                            Icon = {faSave as IconDefinition}
                            text = {Assets.Save}
                        />
                    </div>
                </div>

            </div>
        <br/>
        </div>
        : void 0}
        {/* <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CreateBrandOwnerUserState.pSize ? '' : 
                Main.Instance.CreateBrandOwnerUserState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
           
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CreateBrandOwnerUserState.filter_role ? '0' : 
                        Main.Instance.CreateBrandOwnerUserState.filter_role}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.RoleAny} 
                o2 = {Assets.Administrator}
                o3 = {Assets.Support}
                o4 = {Assets.Accounting}
                o5 = {Assets.RiskManager}
                onChange = {text => ApplyFilterRole(text)}
            />
            
            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CreateBrandOwnerUserState.count ? Main.Instance.CreateBrandOwnerUserState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CreateBrandOwnerUserState.count ? Main.Instance.CreateBrandOwnerUserState.pNum * 
                Main.Instance.CreateBrandOwnerUserState.pSize + 1 : 0}{' - '}
                {Main.Instance.CreateBrandOwnerUserState.count ? ((Main.Instance.CreateBrandOwnerUserState.pNum + 1) * 
                Main.Instance.CreateBrandOwnerUserState.pSize > Main.Instance.CreateBrandOwnerUserState.count ? 
                Main.Instance.CreateBrandOwnerUserState.count : 
                (Main.Instance.CreateBrandOwnerUserState.pNum + 1) * Main.Instance.CreateBrandOwnerUserState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.CreateBrandOwnerUserState.count ? 
                Main.Instance.CreateBrandOwnerUserState.pNum + 1 :0}{' / '}
                {Main.Instance.CreateBrandOwnerUserState.count ? 
                Math.trunc((Main.Instance.CreateBrandOwnerUserState.count - 1) /
                Main.Instance.CreateBrandOwnerUserState.pSize+1) : 0}
            </span>
        </div> */}
        {/* console.log (Assets_.SecurityRights)*/}
        <h3>{isUserSystem() ? Assets.tOperators2Users : tOperatorUsers}</h3>
        {/*alert (JSON.stringify(Main.Instance.DatabaseDataState.user11Stream))*/}
        {isUserMaster() && Main.Instance.DatabaseDataState.user11mStream ||
        isUserContractualPartner() && Main.Instance.DatabaseDataState.user11Stream ||
        isUserSystem() && Main.Instance.DatabaseDataState.users11Stream ? 
        <ReactTable
            data = {isUserSystem() ? Main.Instance.DatabaseDataState.users11Stream :
                    isUserMaster() ? Main.Instance.DatabaseDataState.user11mStream : 
                    Main.Instance.DatabaseDataState.user11Stream
            }
            columns = {[
                {
                Header: '',
                headerClassName: 'risk-default-light ',
                columns: [
                    {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {Header: Assets.Username, accessor: 'username', headerClassName: 'th-left', Cell: (row) => <span title = {row.value} style = {{fontWeight: row.original.id === Main.Instance.LoginState.id ? 900 : 400}}>{row.value}</span>},
                    /*{Header: 'Role', accessor: 'role', headerClassName: 'th-left'},*/
                    /*{minWidth: 60, Header: Assets_GDPR, accessor: 'gdpr', headerClassName: 'th-left', Cell: (row) =>
                    <button disabled = {!isUserAdmin()} onClick = {() => toggleGDPR(row.original.id, row.value)} style = {{border: '0px', background: 'transparent'}}><FontAwesomeIcon style = {row.value ? {color: 'green'} : {color: 'red'}} icon = {row.value ? faEye : faEyeSlash} size = "2x"/></button>},*/
                    {width: 150, Header: Assets.Master, accessor: 'master', headerClassName: 'th-left', show: isUserSystem()},
                    {width: 150, Header: Assets.SiteOwner, accessor: 'siteowner', headerClassName: 'th-left', show: !isUserContractualPartner()},
                    {width: 300, Header: Assets.PersonalData, headerClassName: 'th-left', accessor: '', Cell: (row) => 
                    <div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faUser} />
                            {row.original.firstname !== null && row.original.lastname !== null && row.original.firstname.length > 0 && row.original.lastname.length > 0 ?
                            row.original.firstname + ' ' + row.original.lastname : row.original.firstname !== null && row.original.firstname.length > 0 ?
                            row.original.firstname : row.original.lastname !== null && row.original.lastname.length > 0 ? row.original.lastname : Assets.notDefined}
                        </div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faEnvelope}/>
                            {row.original.email !== null && row.original.email.length > 0 ? row.original.email : Assets.notDefined}
                        </div>
                        <div className = "personal-data">
                            <FontAwesomeIcon icon = {faPhone}/>
                            {row.original.phone !== null && row.original.phone.length > 0 ? row.original.phone : Assets.notDefined}
                        </div>
                    </div>},
                {width: 60, Header: Assets.Active, accessor: 'active', Cell: (row) => 
                <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => toggleActive(row.original.id, row.value, row.original)}
                        on = {row.value}
                        disabled = {!isUserAdmin()}
                    />
                </div>},
                ]},
                {
                Header: Assets.SecurityRights,
                headerClassName: 'risk-default ',
                columns: [
                    {width: 105, Header: Assets_.SecurityRights[SecurityRights.ADMINISTRATOR], accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.administrator, SecurityRights.ADMINISTRATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.administrator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ADMINISTRATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUserFriends}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.SUPPORT]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.support, SecurityRights.SUPPORT, row.value, row.original)}
                        on = {row.original.custom.securityRights.support}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.SUPPORT] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span><FontAwesomeIcon icon = {faUser}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.OPERATOR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.operator, SecurityRights.OPERATOR, row.value, row.original)}
                        on = {row.original.custom.securityRights.operator}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.OPERATOR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span><FontAwesomeIcon icon = {faBullhorn}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.MARKETING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.marketing, SecurityRights.MARKETING, row.value, row.original)}
                        on = {row.original.custom.securityRights.marketing}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.MARKETING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 100, Header: <span title = {Assets.RFSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RFSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.rfSpecialist, SecurityRights.RFSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rfSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RFSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 105, Header: <span title = {Assets.RGSpecialistLong}>{Assets_.SecurityRights[SecurityRights.RGSPECIALIST]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.rgSpecialist, SecurityRights.RGSPECIALIST, row.value, row.original)}
                        on = {row.original.custom.securityRights.rgSpecialist}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.RGSPECIALIST] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 70, Header: <span><FontAwesomeIcon icon = {faUserSecret}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GDPR]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.gdpr, SecurityRights.GDPR, row.value, row.original)}
                        on = {row.original.custom.securityRights.gdpr}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.GDPR] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    
                    {width: 110, Header: <span><FontAwesomeIcon icon = {faUserTie}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.ACCOUNTING]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.accounting, SecurityRights.ACCOUNTING, row.value, row.original)}
                        on = {row.original.custom.securityRights.accounting}
                        disabled = {!isUserAdmin()}
                        title = {isUserAdmin() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.ACCOUNTING] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {width: 90, Header: <span><FontAwesomeIcon icon = {faChartLine}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.REPORTS]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5"
                        onClick = {() => (!isUserAdmin() || !isUserReports()) && !isLocalhost() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.unused3, SecurityRights.REPORTS, row.value, row.original)}
                        on = {row.original.custom.securityRights.unused3}
                        disabled = {(!isUserAdmin() || !isUserReports()) && !isLocalhost() }
                        title = {isUserAdmin() && isUserReports() || isLocalhost() ? Assets.ToggleSecurityRightsThatPertainTo + ' ' + Assets_.SecurityRights[SecurityRights.REPORTS] : Assets.YouDontHaveSecurityRights}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 95, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}><FontAwesomeIcon icon = {faFire}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN].split(' ')[0]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        style = {{cursor: 'default'}}
                        // onClick = {() => {}}
                        on = {row.original.custom.securityRights.unused1}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_ADMIN]}
                    />
                    </div>},
                    {show: isUserGamanzaAdmin(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}><FontAwesomeIcon icon = {faDice}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                    <div style = {{textAlign: 'center'}}>
                    <Switch
                        className = "mt5 switch-disabled3"
                        // style = {{cursor: 'default'}}
                        onClick = {() => isUserGamanzaAdmin() ? toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.unused2, SecurityRights.GAMANZA_GAMES_ADMIN, row.value, row.original) : void 0}
                        disabled = {!isUserGamanzaAdmin()}
                        // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                        on = {row.original.custom.securityRights.unused2}
                        title = {Assets_.SecurityRights[SecurityRights.GAMANZA_GAMES_ADMIN]}
                    />
                    </div>},

                {show: isUserGamanzaAdmin() && isUserGamanzaMagicHand(), width: 85, Header: <span title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}><FontAwesomeIcon icon = {faMagic}/>&nbsp;&nbsp;{Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND].split(' ')[1]}</span>, accessor: 'custom', headerClassName: 'th-center', Cell: (row) =>
                <div style = {{textAlign: 'center'}}>
                <Switch
                    className = "mt5 switch-disabled3"
                    // style = {{cursor: 'default'}}
                    onClick = {() => isUserGamanzaAdmin() && isUserGamanzaMagicHand() ? toggleSecurityRights(1, getBrandOwnerUsersCount, row.original.id, row.original.custom.securityRights.unused7, SecurityRights.GAMANZA_MAGIC_HAND, row.value, row.original) : void 0}
                    disabled = {!(isUserGamanzaAdmin() && isUserGamanzaMagicHand())}
                    // onClick = {() => !isUserAdmin() ? alert(Assets.YouDontHaveSecurityRights) : toggleSecurityRights(0, getOperatorsUsersCount, row.original.id, row.original.custom.securityRights.unused1, SecurityRights.GAMANZA_ADMIN, row.value)}
                    on = {row.original.custom.securityRights.unused7}
                    title = {Assets_.SecurityRights[SecurityRights.GAMANZA_MAGIC_HAND]}
                />
                </div>},
                ]},
                {
                Header: '',
                headerClassName: 'risk-default-light ',
                columns: [                    
                    {width: 50, show: isUserAdmin(), Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ],
                },
            ]}
            showPaginationBottom = {false}
            showPaginationTop = {true}
            defaultPageSize={10}
        />
        : getBrandOwnerUsersCount()}
    </div>
    )
}
