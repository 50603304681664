import {Logic} from '../Type'

export interface CustomSecurityRights {
    securityRights: {
        gdpr: boolean,
        support: boolean,
        operator: boolean,
        marketing: boolean,
        rfSpecialist: boolean,
        rgSpecialist: boolean,
        administrator: boolean,
        accounting: boolean,
        unused1: boolean,
        unused2: boolean,
        unused3: boolean,
        unused4: boolean,
        unused5: boolean,
        unused6: boolean,
        unused7: boolean,
    },
    environmentsAllowed: boolean[]
}

export enum SecurityRights {ADMINISTRATOR, SUPPORT, OPERATOR, MARKETING, RFSPECIALIST, RGSPECIALIST, GDPR, ACCOUNTING, REPORTS, GAMANZA_ADMIN, GAMANZA_GAMES_ADMIN, GAMANZA_MAGIC_HAND}


export class ManageState extends Logic.Type {
    
    /* top selection */
    public Selection:number = 3

    /* bottom selection */
    public selection:number = 1

    /* selection regarding users - dropdown */
    public selectionUsers:number = 1
    // this should always be zero and it is used to copy the value to the above
    public selectionUsersInitial:number = 0
}

export class CreateSystemUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false
    public id :string = '0'
    public offset:string = '0'

    public username :string = ''
    public password :string = ''
    // role_id :string = '0'
    public activity_id :string = '1'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public sort:number[] = []
    public Sort:string[] = []
    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class CreateGameProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    public game_provider_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string   
    public sort:number[] = []
    public Sort:string[] = []

    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class CreateMasterUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    public master_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string   
    public sort:number[] = []
    public Sort:string[] = []

    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class CreateOperatorUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number  = 100
    public count :number = 0
    public mode :boolean = false
    public id :string  = '0'
    public username :string  = ''
    public password :string  = ''
    public operator_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string

    public entityId: number = -1
}

export class CreateBrandOwnerUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number  = 100
    public count :number = 0
    public mode :boolean = false
    public id :string  = '0'
    public username :string  = ''
    public password :string  = ''
    public site_owner_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []    
    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class CreateSiteUserState extends Logic.Type {

    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum  :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false
    public id :string  = '0'
    public username :string  = ''
    public password :string  = ''
    public site_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    // operator_id :string  = '0'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class CreateSite2UserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum  :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false
    public id :string  = '0'
    public username :string  = ''
    public password :string  = ''
    public site_id :string  = '0'
    // role_id :string  = '0'
    public activity_id :string  = '1'
    // site_owner_id :string  = '0'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class CreateGameAssetProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''
    // game_provider_id :string  = '0'
    public game_asset_provider_id :string  = '0'

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class CreateComplianceProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false]
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''
    public compliance_provider_id :string  = '0'

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class CreateComplianceSubProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''
    // compliance_provider_id :string  = '0'
    public compliance_sub_provider_id :string  = '0'

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class CreatePaymentProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false

    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''
    public payment_provider_id :string  = '0'

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class CreatePaymentSubProviderUserState extends Logic.Type {
    public custom: CustomSecurityRights = {
        securityRights: {
            gdpr: false,
            support: false,
            operator: false,
            marketing: false,
            rfSpecialist: false,
            rgSpecialist: false,
            administrator: false,
            accounting: false,
            unused1: false,
            unused2: false,
            unused3: false,
            unused4: false,
            unused5: false,
            unused6: false,
            unused7: false,
        },
        environmentsAllowed: [false, false, false, false],
    }
    public custom_saved: CustomSecurityRights
    public countCalled:boolean = false
    public pNum :number = 0
    public pSize :number = 100
    public count :number = 0
    public mode :boolean  = false
    public id :string = '0'
    public username :string = '' 
    public password :string  = ''
    // role_id :string  = '0'
    public activity_id :string  = '1'
    public offset:string = '0'
    public firstName:string = ''
    public lastName:string = ''
    public phoneNumber:string = ''
    public email:string = ''
    // payment_provider_id :string  = '0'
    public payment_sub_provider_id :string  = '0'

    public username_saved :string 
    // password_saved :string 
    // role_id_saved :string 
    public activity_id_saved :string 
    public firstName_saved:string 
    public lastName_saved:string 
    public phoneNumber_saved:string 
    public email_saved:string 
    public parent_id_saved:string    
    public sort:number[] = []
    public Sort:string[] = []
    
    public filter_role:string = '0'
}

export class RegisterSiteState extends Logic.Type {

    public countCalled:boolean = false
    public search:string = ''
    public pNum:number = 0
    public pSize:number = 5
    public count:number = 0
    public mode:boolean = false
    public id:string = ''
    public name:string = ''
    public operator_id:string = '0'
    // custom:any = {}
    public offset:string = '0'
}

export class RegisterOperatorState extends Logic.Type {

    public countCalled:boolean = false
    public search:string = ''
    public pNum :number = 0
    public pSize :number = 5
    public count :number = 0
    public mode :boolean = false
    public id :string = ''
    public name :string = ''
    public email :string = ''
    public telephone :string = ''
    public country :string = ''
    public address :string = ''
    public postal :string = ''
    public city :string = ''
    public offset:string = '0'

    public siteid: string[] = []
    public sites: string[] = []
    public site: boolean[] = []
    public operator_id:string = '0'
    public site_count: number = 0
}

export class RegisterGameProviderState extends Logic.Type {

    public countCalled:boolean = false
    public search:string  = ''
    public pNum :number = 0
    public pSize :number = 5
    public count :number = 0
    public mode :boolean = false
    public id :string  = ''
    public name :string = ''
    public email :string = ''
    public telephone :string = '' 
    public country :string  = ''
    public address :string  = ''
    public postal :string = ''
    public city :string = ''
    public offset:string = '0'
    public security_question: string = '0'
    public security_question_saved: string
    public security_question_answer: string = ''
    public security_question_answer_saved: string
}

export class RegisterHoldingState extends Logic.Type {

    public countCalled:boolean = false
    public search:string = ''
    public pNum :number = 0

    // this should now be displayed as an option
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false
    public temp : boolean = false
    public id :string = ''

    public name :string = ''

    // this is used to create a user
    // username :string = '' 
    // password :string  = ''    
    
    // selections, first pertains to user, second to contractual partner
    // role_id :string  = '0'
    public type_temp_id :string = '0'
    public ops_selected:string = '0'
    public ops_total:string = '0'
    public gps_selected:string = '0'
    public gps_total:string = '0'
    public pps_selected:string = '0'
    public pps_total:string = '0'
    public cps_selected:string = '0'
    public cps_total:string = '0'
    public bos_selected:string = '0'
    public bos_total:string = '0'
    public bos:boolean[] = []
    public bos_id:string[] = []
    public bos_cb : JSX.Element[]


    public ops:boolean[] = []
    public gps:boolean[] = []
    public pps:boolean[] = []
    public cps:boolean[] = []
    public ops_id:string[] = []
    public gps_id:string[] = []
    public pps_id:string[] = []
    public cps_id:string[] = []
    public ops_cb : JSX.Element[]
    public gps_cb : JSX.Element[]
    public cps_cb : JSX.Element[]
    public pps_cb : JSX.Element[]

    public sort:number[] = []
    public Sort:string[] = []
    // type_id_filter:string = '0'
    // activity_id :string = '1'

    // this gets returned after insert user and then gets inserted in the main table
    // user_id :string = '0'

    // person contact info
    public email :string = ''
    public phone :string = ''
    public person:string = ''
    
    // saved when editing
    public email_saved :string
    public phone_saved :string
    public person_saved :string
    public name_saved :string

    // type_id_saved: string

    // ignored for this moment
    // custom : any = {}
    // data: any = {}

    public offset:string = '0'
    // siteid: string[] = []
    // sites: string[] = []
    // site: boolean[] = []
    // site_count: number = 0
    // gg_rm_wallet : string = '2'
    // gg_rm_wallet_id : boolean = false
}


export class RegisterContractualPartnerState extends Logic.Type {
    public sort:number[] = []
    public Sort:string[] = []    
    public countCalled:boolean = false
    public search:string = ''
    public pNum :number = 0

    // this should now be displayed as an option
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false

    public id :string = ''

    public name :string = ''

    // this is used to create a user
    // username :string = '' 
    // password :string  = ''    
    
    // selections, first pertains to user, second to contractual partner
    // role_id :string  = '0'
    public type_id :string = '1'
    public select_type_id :string = '0'
    public type_id_filter:string = '0'
    // activity_id :string = '1'

    // this gets returned after insert user and then gets inserted in the main table
    // user_id :string = '0'

    // person contact info
    public email :string = ''
    public phone :string = ''
    public person:string = ''
    
    // saved when editing
    public email_saved :string
    public phone_saved :string
    public person_saved :string
    public name_saved :string
    public gg_rm_wallet_saved :string
    public type_id_saved: string

    // ignored for this moment
    // custom : any = {}
    // data: any = {}

    public offset:string = '0'
    public siteid: string[] = []
    public sites: string[] = []
    public site: boolean[] = []
    public site_count: number = 0
    public gg_rm_wallet : string = '2'
    public gg_rm_wallet_id : boolean = false
    public master_id:string = '0'
    public master_id_saved:string
}

export class RegisterSubEntityState extends Logic.Type {

    public ID : string = ''
    public countCalled:boolean = false
    public search:string = ''
    public pNum :number = 0

    // this should now be displayed as an option
    public pSize :number = 100
    public count :number = 0
    public mode :boolean = false

    public id :string = ''

    public name :string = ''

    // this is used to create a user
    // username :string = '' 
    // password :string  = ''    
    
    // selections, first pertains to user, second to contractual partner
    // role_id :string  = '0'
    public type_id :string = '1'
    public select_type_id :string = '0'
    public type_id_filter:string = '0'
    // activity_id :string = '1'

    // this gets returned after insert user and then gets inserted in the main table
    // user_id :string = '0'

    // person contact info
    public email :string = ''
    public phone :string = ''
    public person:string = ''
    
    // saved when editing
    public email_saved :string
    public phone_saved :string
    public person_saved :string
    public name_saved :string
    public gg_affiliate_system_saved :string
    public type_id_saved: string
    public contractual_partner_id_saved : string 
    public contractual_partner_2_saved : string
    public ID_saved:string

    // ignored for this moment
    // custom : any = {}
    // data: any = {}

    public offset:string = '0'
    public siteid: string[] = []
    public sites: string[] = []
    public site: boolean[] = []
    public site_count: number = 0
    public gg_affiliate_system : string = '2'
    public gg_affiliate_system_id : boolean = false
    public contractual_partner_id : string = '0'
    public contractual_partner_2 : string = '0'
    // contractual_partner: string = ''
    public sort:number[] = []
    public Sort:string[] = []
}
