import * as React from 'react'
import {Main} from '../../Logic/Main'
import * as Assets_ from '../Assets_'
import {Logic} from '../../Logic/Type'
import {updateTranslation, updateCategory} from '../../Logic/Utils'

export class Flags extends React.Component<{}, {}> {
    public getFlags(): JSX.Element[] {
        const flags: JSX.Element[] = []

        if(Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
            for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
                if (Main.Instance.DatabaseDataState.languagesStreamAll[j]['active'] === false) { continue }
                // const flagSource1: number = parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[j]['flag_id'])-1
                // const flagsource1: string = Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
                const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
                Main.Instance.DatabaseDataState.languagesStreamAll[j]['flag'])-1
                const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                    !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
                // console.log (Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'])
                // console.log (Main.Instance.DatabaseDataState.languagesAllStream)
                const name1: string = Main.Instance.DatabaseDataState.languagesAllStream &&
                                      Main.Instance.DatabaseDataState.languagesStreamAll &&
                                      Main.Instance.DatabaseDataState.languagesStreamAll[j] &&
                                      Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] &&
                                      Main.Instance.DatabaseDataState.languagesAllStream[
                                      Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1] ? 
                    Main.Instance.DatabaseDataState.languagesAllStream[
                    Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1]['name'] + ' (' +
                    Main.Instance.DatabaseDataState.languagesAllStream[
                    Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1]['native'] + ')' : ''
                flags.push(
                    <span
                        className = "flags"
                        style = {{background: this.LanguageContentShopColor(j), marginLeft: j === 0 ? '20px' : '5px'}}                    
                        title = {name1}
                        key = {j.toString()} 
                    >
                        <img src = {flagsource1} height = "20"/>
                    </span>,
                )
            }
        }
        return flags
    }

    public LanguageContentShopColor(which: number): string {
        if (Main.Instance.RewardShopState.name && Main.Instance.RewardShopState.info && Main.Instance.RewardShopState.choose_category &&

            Main.Instance.RewardShopState.name[which] !== null &&
            Main.Instance.RewardShopState.name[which] !== undefined &&
                Main.Instance.RewardShopState.name[which].length > 0 &&

            Main.Instance.RewardShopState.choose_category[which] !== null &&
            Main.Instance.RewardShopState.choose_category[which] !== undefined &&
                Main.Instance.RewardShopState.choose_category[which].length > 0 &&
        
            Main.Instance.RewardShopState.info[which] !== null &&
            Main.Instance.RewardShopState.info[which] !== undefined &&
                Main.Instance.RewardShopState.info[which].length > 0) {
        return '#5cb85c'
        }

        if (
            (Main.Instance.RewardShopState.name[which] === null ||
            Main.Instance.RewardShopState.name[which] === undefined ||
            Main.Instance.RewardShopState.name[which].length === 0)
            &&
            (Main.Instance.RewardShopState.info[which] === null ||
            Main.Instance.RewardShopState.info[which] === undefined ||
            Main.Instance.RewardShopState.info[which].length === 0)
            &&
            (Main.Instance.RewardShopState.choose_category[which] === null ||
            Main.Instance.RewardShopState.choose_category[which] === undefined ||
            Main.Instance.RewardShopState.choose_category[which].length === 0)
        ) {
        return '#d9534f'
        }

        return '#ec971f'
    }

    public render() {
        return (<>{this.getFlags()}</>)
    }        
}

export class Flag extends React.Component<{rewardShop?: boolean}, {}> {
    constructor(props: any) {
        super (props)
    }
    public SelectFlagRewardShop(language: number) {
        if (!Main.Instance.DatabaseDataState.selectLanguage) {
            Logic.Type.New(Main.Instance.DatabaseDataState, {
                selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
        } else {
            Logic.Type.New(Main.Instance.DatabaseDataState, {language, 
                selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
            Logic.Type.New (Main.Instance.RewardShopState, {category: '', category_id: '0'})
        }
    }

    public SelectFlag(language: number) {
        if (!Main.Instance.DatabaseDataState.selectLanguage) {
                Logic.Type.New(Main.Instance.DatabaseDataState, {
                selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
        } else {
                Logic.Type.New(Main.Instance.DatabaseDataState, {language, 
                selectLanguage: !Main.Instance.DatabaseDataState.selectLanguage})
                Logic.Type.New (Main.Instance.FrontendStoreState, {translation_: '', translation_default: '', translation_id: '0'})
                updateTranslation()
                updateCategory()
        }
    }

    public getFlags(): JSX.Element[] {
        const flags: JSX.Element[] = []

        const flagSource: number = !Main.Instance.DatabaseDataState.languagesStreamAll 
        || Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined :
        parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[Main.Instance.DatabaseDataState.language]['flag']) - 1
        const flagsource: string = flagSource === undefined || isNaN(flagSource) || 
            !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
            Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource]['image']

        flags.push(
            <a
                title = {name}
                style = {{background: 'transparent'}}
                // style = {{top: startOffset.toString() + 'px'}}
                className = "calendar-language-active calendar-language"
                key = {Main.Instance.DatabaseDataState.language.toString()} 
                href = "javascript:void(0)"
                onClick = {() => this.props.rewardShop ? this.SelectFlagRewardShop(Main.Instance.DatabaseDataState.language): this.SelectFlag(Main.Instance.DatabaseDataState.language)}
            >
                {Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.languagesStreamAll[
                Main.Instance.DatabaseDataState.language] ? <img src = {flagsource} height = "20"/> : void 0}
                <i className = {!Main.Instance.DatabaseDataState.selectLanguage ? Assets_.faCaretDown : Assets_.faCaretUp}/>
            </a>,
        )

        if(Main.Instance.DatabaseDataState.languagesStreamAll) {
            for (let J: number = 0; J < Main.Instance.DatabaseDataState.languagesStreamAll.length; J++) {
                if (Main.Instance.DatabaseDataState.languagesStreamAll[J]['active'] === false) { continue }
                // const flagSource1: number = parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag_id'])-1
                // const flagsource1: string = Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
                const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
                Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : 
                parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[J]['flag']) - 1
                const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']

                const name1: string = 
                    Main.Instance.DatabaseDataState.languagesAllStream &&
                    Main.Instance.DatabaseDataState.languagesStreamAll &&
                    Main.Instance.DatabaseDataState.languagesStreamAll[J] &&
                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] &&
                    Main.Instance.DatabaseDataState.languagesAllStream[
                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1] ? 
                    Main.Instance.DatabaseDataState.languagesAllStream[
                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['name'] + ' (' +
                    Main.Instance.DatabaseDataState.languagesAllStream[
                    Main.Instance.DatabaseDataState.languagesStreamAll[J]['name'] - 1]['native'] + ')' : ''
                
                if (J === Main.Instance.DatabaseDataState.language) {continue}
                // position++
                if (Main.Instance.DatabaseDataState.selectLanguage) {
                flags.push(
                    <a
                        title = {name1}
                        // style = {{top: (startOffset + offset * position).toString() + 'px'}}
                        className = "calendar-language"
                        key = {J.toString()} 
                        href = "javascript:void(0)"
                        onClick = {() => this.props.rewardShop ? this.SelectFlagRewardShop(J) : this.SelectFlag(J)}
                        style = {{background: 'transparent', opacity: 0.5}}
                    >
                        <img src = {flagsource1} height = "20"/>
                    </a>,
                )
                }
            }
        }
        return flags
    }

    public render() {
        return (<div className = "calendar-header">{this.getFlags()}</div>)
    }
 }