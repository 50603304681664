import * as React from 'react'
import * as Assets from '../Assets'
import * as Assets_ from '../Assets_'
import {Main} from '../../Logic/Main'
import {Logic} from '../../Logic/Type'
// import {ForgotPassword} from './ForgotPassword'
// import {RegisterLogin} from './RegisterLogin'
import {RouterObjects} from '../../Logic/RouterObjects'
import '../../CSS/Login.css'
import '../../CSS/Navbar.css'
import * as DbRoutes from '../../Logic/Database/DbRoutes'
import * as Config from '../../Logic/Config'
import {LoginInput} from '../Reusables/Inputs'
import {faUnlockAlt, faUserAlt, faEye, faEnvelope, faPhone, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {post} from '../../Logic/Utils'
import Cookies from 'js-cookie'
/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPenNib} from '@fortawesome/free-solid-svg-icons'
*/
function handleLogin(e?: any) {
    if (e && e.key === Assets_.KEY_ENTER || !e) {
        if (Main.Instance.LoginState.loginMethod === 0 && Main.Instance.LoginState.Username && Main.Instance.LoginState.Password &&
            Main.Instance.LoginState.Username.length > 0 && Main.Instance.LoginState.Password.length > 0) {
            DbRoutes.Login(Main.Instance.LoginState.Username, Main.Instance.LoginState.Password)

        } else if (Main.Instance.LoginState.loginMethod === 1 && Main.Instance.LoginState.Password && Main.Instance.LoginState.email &&
            Main.Instance.LoginState.Password.length > 0 && Main.Instance.LoginState.email.length > 0) {
            DbRoutes.Login(Main.Instance.LoginState.email, Main.Instance.LoginState.Password)

        } else if (Main.Instance.LoginState.loginMethod === 2 && Main.Instance.LoginState.Password && Main.Instance.LoginState.phone &&
            Main.Instance.LoginState.Password.length > 0 && Main.Instance.LoginState.phone.length > 0) {
            DbRoutes.Login(Main.Instance.LoginState.phone, Main.Instance.LoginState.Password)

        }
    }
}

export function handleForgotPassword(e?: any) {
    if (e && e.key === Assets_.KEY_ENTER || !e) {
        if (Main.Instance.LoginState.loginMethod === 4) {
            if (Main.Instance.LoginState !== null && Main.Instance.LoginState.Username && Main.Instance.LoginState.Username.length > 0) {
                if (confirm(Assets.l1 + ' "' + Main.Instance.LoginState.Username + '"?')) {
                    DbRoutes.ForgotPassword(Main.Instance.LoginState.Username)
                }
            } else { alert (Assets.l2) }
        } else if (Main.Instance.LoginState.loginMethod === 5) {
            // need to find security question for this user
            if (Main.Instance.LoginState !== null && Main.Instance.LoginState.email && Main.Instance.LoginState.email.length > 0 && Main.Instance.LoginState.securityQuestion === 0) {
                DbRoutes.AuthorizeSecurityQuestion(Main.Instance.LoginState.email)
            } else if (Main.Instance.LoginState !== null && Main.Instance.LoginState.email && Main.Instance.LoginState.email.length > 0 &&
                Main.Instance.LoginState.securityAnswer && Main.Instance.LoginState.securityAnswer.length > 0) {
                DbRoutes.AuthorizeSecurityAnswer(Main.Instance.LoginState.email, Main.Instance.LoginState.securityAnswer)
            } else if (Main.Instance.LoginState.securityQuestion === 0) {
                alert (Assets.l3) 
            } else {
                alert (Assets.l4)
            }
        } else if (Main.Instance.LoginState.loginMethod === 6) {
            // need to find security question for this user
            if (Main.Instance.LoginState !== null && Main.Instance.LoginState.phone && Main.Instance.LoginState.phone.length > 0 && Main.Instance.LoginState.securityQuestion === 0) {
                DbRoutes.AuthorizeSecurityQuestion(Main.Instance.LoginState.phone)
            } else if (Main.Instance.LoginState !== null && Main.Instance.LoginState.phone && Main.Instance.LoginState.phone.length > 0 &&
                Main.Instance.LoginState.securityAnswer && Main.Instance.LoginState.securityAnswer.length > 0) {
                DbRoutes.AuthorizeSecurityAnswer(Main.Instance.LoginState.phone, Main.Instance.LoginState.securityAnswer)
            } else if (Main.Instance.LoginState.securityQuestion === 0) {
                alert (Assets.l5)
            } else {
                alert (Assets.l4)
            }
        }
    }
}

function ForgotPassword() {
    return (
        <div className = "container fluid">
            <div className = "row">
                <div className = "login-forgot-password">
                    <img height = "25" className = "mr" src = {Assets_.logo}/>
                    <span style = {{fontSize: '16px'}}>{Assets_.productName[0]}<sup style = {{top: '-1em', fontSize: '13px'}}>{Assets_.productName[1]}</sup>{Assets_.productName[2] + ' Password Reset Form'}</span>
                    <div className = "mt">
                        <LoginInput 
                            type = "password"
                            onChange = {(text) => Logic.Type.New (Main.Instance.LoginState, {Password1: text})}
                            placeholder = {Assets.pNewPassword}
                            Icon = {faUnlockAlt as IconDefinition}
                            border = {Main.Instance.LoginState.Password1.length > 0 ? '2px solid green' : '2px solid red'}
                        />

                        <LoginInput
                            type = "password"
                            onChange = {(text) => Logic.Type.New (Main.Instance.LoginState, {Password2: text})}
                            placeholder = {Assets.pRepeatPassword}
                            Icon = {faUnlockAlt as IconDefinition}
                            border = {Main.Instance.LoginState.Password1.length > 0 && Main.Instance.LoginState.Password2.length > 0 && 
                                Main.Instance.LoginState.Password1 === Main.Instance.LoginState.Password2 ? '2px solid green' : '2px solid red'}
                        />
                    </div>

                    <button
                        disabled = {!(Main.Instance.LoginState.Password1.length > 0 && Main.Instance.LoginState.Password2.length > 0 && 
                            Main.Instance.LoginState.Password1 === Main.Instance.LoginState.Password2)} 
                        onClick = {() => DbRoutes.SetNewPassword(Main.Instance.LoginState.Username, Main.Instance.LoginState.Password1, Main.Instance.LoginState.Password)}
                        className = "btn btn-primary btn-block"
                    >
                        {Assets.SetNewPassword}
                    </button>
                    <button
                        className = "btn btn-warning btn-block"
                        onClick={() => Logic.Type.New(Main.Instance, {Location: RouterObjects['login'].path})}
                    >
                        {Assets.BackToLogin}
                    </button>
                </div>
            </div>
        </div>  
    )
}

export function LoginLogin(): JSX.Element {
    if (Main.Instance.Location === RouterObjects['forgotPassword'].path) {

        const href: string = window.location.href
        const searchFor: string = '/forgot-password?'
        const searchFor2: string = '&hash='
        const searchFor3: string = 'user='

        if (Main.Instance.LoginState === null || !Main.Instance.LoginState.Verified && !Main.Instance.LoginState.wrongPassword) {
            const forgotPassword: number = href.indexOf(searchFor)
            const forgotPassword2: number = href.indexOf(searchFor2)
            const Username: string = href.substring(forgotPassword + searchFor.length + searchFor3.length, forgotPassword2)
            if (forgotPassword === -1 || forgotPassword2 === -1) { return <div className = "mt ml">{Assets.l6}</div> }
            Logic.Type.New (Main.Instance.LoginState, {
                wrongPassword: true,
                Username,
                Password: href.substring (forgotPassword2 + searchFor2.length, href.length),
                oldPassword: href.substring (forgotPassword2 + searchFor2.length, href.length)
            })
            DbRoutes.authorizeHash(href.substring (forgotPassword + searchFor.length - 1, href.length))
        }

        if (Main.Instance.LoginState === null || !Main.Instance.LoginState.Verified) {
            return <div className = "mt ml">{Assets.WaitingForAuthorization}...</div>
        }             
        return ForgotPassword()

    } else if (Main.Instance.Location !== RouterObjects['registerLogin'].path && Main.Instance.Location !== RouterObjects['forgotPassword'].path) {
        // console.log ('here')
        if (Main.Instance.Location != RouterObjects['login'].path) {
            Logic.Type.New(Main.Instance, {Location: RouterObjects['login'].path})
        }
    }
    const date: string = Assets_.productVersion.substring(6, 10) + Assets_.productVersion.substring(2, 6) + Assets_.productVersion.substring(0, 2)
    // console.log (date)
    const d: Date = new Date(date)
    // console.log (d)
    // console.log (n)
    const dayInWeek: string = Assets.DaysInWeek[d.getDay()]
    const month: string = Assets.Months[d.getMonth() + 2]
    // console.log (Main.Instance.DatabaseDataState.inactivityLogout)
    let il: string
    if (Cookies.get('inactivity')) {
        il = Cookies.get('inactivity')
    }
    
    if (il === 'YES') {
        Logic.Type.New (Main.Instance.DatabaseDataState, {inactivityLogout: true})
        Cookies.remove('inactivity', { path: '/' })
        Cookies.set('inactivity', 'NO', { path: '/' })
    }
    return (Main.Instance.LoginState === null ? <div/> :
        <div className = "main" style = {{overflowX: 'hidden'}}>
            <div className = "row">
                <div 
                    style = {{textAlign: 'center'}}
                    className = {Main.Instance.LoginState.loginMethod < 3 ? 'login' : 'login-forgot'}
                    onKeyPress = {Main.Instance.LoginState.loginMethod < 3 ? handleLogin : handleForgotPassword}
                >
                    <img 
                        className = "logo"
                        height = "100px"
                        src = {Assets_.logoGamanza}
                    />
                    <div className = "center">
                        <span className = "title">
                            {Assets_.productName[0]}
                            <sup style = {{top: '-1em', fontSize: '11px'}}>
                                {Assets_.productName[1]}
                            </sup>
                            {Assets_.productName[2]}&nbsp;
                            {Config.Build !== Config.BUILD.JACKPOTSCH || Config.environment === Config.Environment.PRODUCTION ? void 0 :
                            <small style = {{fontWeight: 700}}>{Config.ENVIRONMENT[Config.environment]}</small>
                            }
                        </span>
                        <div style = {{fontWeight: 700, marginBottom: '5px'}}>
                            {/*<FontAwesomeIcon icon = {faPenNib}/>*/}
                            <span>{Assets.BuiltOn + ': ' + dayInWeek + ', ' + Assets_.productVersion.substring(0, 2)}</span>
                            <sup>{post(parseInt(Assets_.productVersion.substring(0, 2)))}</sup>
                            <span>{' ' + month + ' ' + Assets_.productVersion.substring(6, 10) + Assets._at_ + Assets_.productVersion.substring(11, 19)}</span>
                            
                        </div>

                        <div style = {{marginBottom: '3px'}}>
                            <span className = {Main.Instance.LoginState.loginMethod < 3 ? 'label label-primary label-sm mr0 br0 ml-1 lgn' : 'label label-default label-sm mr0 br0 ml-1 lgn lgn0'}>
                                {Assets.LoginWith}
                            </span>
                            <button onClick = {() => lm(0)} className = {Main.Instance.LoginState.loginMethod === 0 ? 'btn btn-primary btn-sm mr0 br0 ml-1' : 'btn btn-default btn-sm mr0 br0 ml-1'}>
                                <FontAwesomeIcon icon = {faUserAlt}/>
                            </button>
                            <button onClick = {() => lm(1)} className = {Main.Instance.LoginState.loginMethod === 1 ? 'btn btn-primary btn-sm mr0 br0 ml-1' : 'btn btn-default btn-sm mr0 br0 ml-1'}>
                                <FontAwesomeIcon icon = {faEnvelope}/>
                            </button>
                            <button onClick = {() => lm(2)} className = {Main.Instance.LoginState.loginMethod === 2 ? 'btn btn-primary btn-sm br0 ml-1' : 'btn btn-default btn-sm br0 ml-1'}>
                                <FontAwesomeIcon icon = {faPhone}/>
                            </button>
                            <span className = {Main.Instance.LoginState.loginMethod > 2 ? 'label label-danger label-sm mr0 br0 ml-1 lgn lgnd' : 'label label-default label-sm mr0 br0 ml-1 lgn lgn0'}>
                                {Assets.Forgot}
                            </span>
                            <button onClick = {() => lm(4)} className = {Main.Instance.LoginState.loginMethod === 4 ? 'btn btn-danger btn-sm mr0 br0 ml-1' : 'btn btn-default btn-sm mr0 br0 ml-1'}>
                                {Assets.Password}
                            </button>
                            <button onClick = {() => lm(5)} className = {Main.Instance.LoginState.loginMethod === 5 ? 'btn btn-danger btn-sm mr0 br0 ml-1' : 'btn btn-default btn-sm mr0 br0 ml-1'}>
                                {Assets.Username + Assets._and_ + Assets.Password.toLowerCase()}
                            </button>
                            <button onClick = {() => lm(6)} className = {Main.Instance.LoginState.loginMethod === 6 ? 'btn btn-danger btn-sm mr0 br0 ml-1' : 'btn btn-default btn-sm mr0 br0 ml-1'}>
                                {Assets.Everything}
                            </button>

                        </div>

                        {Main.Instance.LoginState.loginMethod === 0 || Main.Instance.LoginState.loginMethod === 4 ?
                        <div className="input-group" style = {{marginBottom: '3px'/*, marginLeft: '180px'*/}}>
                            <span className="input-group-addon"><FontAwesomeIcon icon = {faUserAlt}/></span>
                            <input
                                value = {Main.Instance.LoginState.Username ? Main.Instance.LoginState.Username : ''}
                                type = "text"
                                className="form-control"
                                // onChange = {e => Main.Instance.LoginState.Username = e.target.value}
                                onChange = {(e) => Logic.Type.New (Main.Instance.LoginState, {Username: e.target.value})}
                                placeholder = {Assets.pUsername}
                                // style = {{width: '200px'}}
                            />
                        </div> : void 0}

                        {Main.Instance.LoginState.loginMethod === 1 || Main.Instance.LoginState.loginMethod === 5 ?
                        <div className="input-group" style = {{marginBottom: '3px'/*, marginLeft: '180px'*/}}>
                            <span className="input-group-addon"><FontAwesomeIcon icon = {faEnvelope}/></span>
                            <input
                                value = {Main.Instance.LoginState.email ? Main.Instance.LoginState.email : ''}
                                type = "text"
                                className="form-control"
                                // onChange = {e => Main.Instance.LoginState.Username = e.target.value}
                                onChange = {(e) => Logic.Type.New (Main.Instance.LoginState, {email: e.target.value})}
                                placeholder = {Assets.pEmail}
                                // style = {{width: '200px'}}
                            />
                        </div> : void 0}

                        {Main.Instance.LoginState.loginMethod === 2 || Main.Instance.LoginState.loginMethod === 6 ?
                        <div className="input-group" style = {{marginBottom: '3px'/*, marginLeft: '180px'*/}}>
                            <span className="input-group-addon"><FontAwesomeIcon icon = {faPhone}/></span>
                            <input
                                value = {Main.Instance.LoginState.phone ? Main.Instance.LoginState.phone : ''}
                                type = "text"
                                className="form-control"
                                // onChange = {e => Main.Instance.LoginState.Username = e.target.value}
                                onChange = {(e) => Logic.Type.New (Main.Instance.LoginState, {phone: e.target.value})}
                                placeholder = {Assets.pPhone}
                                // style = {{width: '200px'}}
                            />
                        </div> : void 0}

                        {Main.Instance.LoginState.securityQuestion > 0 && (Main.Instance.LoginState.loginMethod === 5 || Main.Instance.LoginState.loginMethod === 6) ?
                        <div className="input-group" style = {{marginBottom: '3px'/*, marginLeft: '180px'*/}}>
                            <span className="input-group-addon"><i className = {Assets_.faQuestionCircleButtonClass}/></span>
                            <input
                                readOnly = {true}
                                value = {Assets_.securityQuestions[Main.Instance.LoginState.securityQuestion].name}
                                type = "text"
                                className="form-control"
                            />
                        </div> : void 0}

                        {Main.Instance.LoginState.securityQuestion > 0 && (Main.Instance.LoginState.loginMethod === 5 || Main.Instance.LoginState.loginMethod === 6) ?
                        <div className="input-group" style = {{marginBottom: '3px'/*, marginLeft: '180px'*/}}>
                            <span className="input-group-addon"><i className = {Assets_.faQuestionCircleWhiteButtonClass}/></span>
                            <input
                                value = {Main.Instance.LoginState.securityAnswer ? Main.Instance.LoginState.securityAnswer : ''}
                                type = "text"
                                className="form-control"
                                placeholder = "security question answer"
                                onChange = {(e) => Logic.Type.New (Main.Instance.LoginState, {securityAnswer: e.target.value, securityAnswerCorrect: true})}
                            />
                        </div> : void 0}

                        {Main.Instance.LoginState.loginMethod < 3 ?
                        <div style = {{position: 'relative', marginBottom: '10px'/*, marginLeft: '180px'*/}}>
                            <div className="input-group">
                                <span className="input-group-addon"><FontAwesomeIcon icon = {faUnlockAlt}/></span>
                                <input
                                    type = {Main.Instance.LoginState.showPassword ? 'text' : 'password'}
                                    className="form-control"
                                    // onChange = {e => Main.Instance.LoginState.Password = e.target.value}
                                    onChange = {(e) => Logic.Type.New (Main.Instance.LoginState, {Password: e.target.value})}
                                    placeholder = {Assets.pPassword}
                                    // style = {{width: '200px'}}
                                />
                            </div>
                            <a
                                onClick = {() => Logic.Type.New (Main.Instance.LoginState, {showPassword: !Main.Instance.LoginState.showPassword})}
                                className = "btn btn-default"
                                style = {{zIndex: 9999, border: '0px', background: 'transparent', right: '0px', top: '0px', position: 'absolute'}}
                            >
                                <FontAwesomeIcon icon = {Main.Instance.LoginState.showPassword ? faEye : faEyeSlash}/>
                            </a>
                        </div> : void 0}

                        {/*
                        <div className = "input-wrapper container-fluid">
                            <span className = "password-input">
                                <i className = "gfont-key gfont-login"/>
                                &nbsp;&nbsp;
                                <input
                                    style = {{width: '170px', border: Main.Instance.LoginState !== null && Main.Instance.LoginState.wrongPassword ? '2px solid #a00' : ''}}
                                    type = "password"
                                    onChange = {e => Main.Instance.LoginState.Password = e.target.value}
                                    placeholder = {Assets.pPassword}
                                />
                            </span>
                        </div>
                        */}
                        {Main.Instance.DatabaseDataState.inactivityLogout ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l8}
                        </div>
                        :
                        Main.Instance.LoginState !== null && Main.Instance.LoginState.wrongApiKey ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l9}
                        </div>
                        :
                        Main.Instance.LoginState !== null && Main.Instance.LoginState.wrongEnvironment ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l25}
                        </div>
                        :
                        Main.Instance.LoginState !== null && Main.Instance.LoginState.wrongPassword ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l10}
                        </div>
                        :
                        /*
                        Main.Instance.LoginState.securityQuestion === 0 &&
                        (Main.Instance.LoginState.loginMethod === 5 || Main.Instance.LoginState.loginMethod === 6) && Main.Instance.LoginState.securityAnswerCorrect ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            The answer to your security question is correct!
                        </div>
                        :
                        */
                        Main.Instance.LoginState.securityAnswerCorrect &&
                        Main.Instance.LoginState.loginMethod === 5 && Main.Instance.LoginState.securityQuestion === 0 ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l11}
                        </div>
                        :
                        Main.Instance.LoginState.securityAnswerCorrect &&
                        Main.Instance.LoginState.loginMethod === 6 && Main.Instance.LoginState.securityQuestion === 0 ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l12}
                        </div>
                        :
                        Main.Instance.LoginState.securityAnswerCorrect && 
                        (Main.Instance.LoginState.loginMethod === 5 || Main.Instance.LoginState.loginMethod === 6) && Main.Instance.LoginState.securityQuestion > 0 ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l13}
                        </div>
                        :
                        (Main.Instance.LoginState.loginMethod === 5 || Main.Instance.LoginState.loginMethod === 6) && Main.Instance.LoginState.securityAnswerCorrect === false ?
                        <div style = {{color: '#a00', fontWeight: 600}}>
                            {Assets.l14}
                        </div>
                        :
                        <div>&nbsp;</div>}
                        {/*href !== 'http://localhost:3000/login' ? void 0 :
                        <div className = "container-fluid">
                            <span className = "dev-password-input">
                                <i className = "gfont-key gfont-login"/>
                                &nbsp;&nbsp;
                                <input
                                    style = {{width: '100%'}}
                                    type = "password"
                                    onChange = {e => Main.Instance.LoginState.Password1 = e.target.value}
                                    placeholder = "DEV PASSWORD"
                                />
                            </span>
                        </div>*/}
                    </div>
                    {Main.Instance.LoginState.loginMethod < 3 ?
                    <button className = "btn btn-primary btn-sm mr0 mt5" onClick = {() => handleLogin()}>{Assets.Login}</button> :
                    <button className = "btn btn-danger btn-sm mr0 mt5" onClick = {() => handleForgotPassword()}>{Assets.ForgotPassword}</button>}
                    {/*
                    <div className = "button-group">
                        <div className = "btn-group-vertical group">
                    */}
                            {/*href !== 'http://localhost:3000/login' ? void 0 :
                            <button 
                                className = "btn btn-login"
                                onClick = {() => DbRoutes.Login(Main.Instance.LoginState.Username, Main.Instance.LoginState.Password1, true)}
                            >
                                DEV LOGIN
                            </button>
                            */}
                            {/*
                            <button 
                                className = "btn btn-forgot-password btn-danger"
                                onClick = {() => handleForgotPassword()}
                            >
                                {Assets.ForgotPassword + '?'}
                            </button>
                            */}
                    {/*
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </div>
    )
    // }
}

function lm(loginMethod: number) {
/*
    if (loginMethod === 0) Logic.Type.New (Main.Instance.LoginState, {loginMethod, Username: ''})
    else if (loginMethod === 1) Logic.Type.New (Main.Instance.LoginState, {loginMethod, email: ''})
    else if (loginMethod === 2) Logic.Type.New (Main.Instance.LoginState, {loginMethod, phone: ''})
  
    else */
    Logic.Type.New (Main.Instance.LoginState, {loginMethod})
}