import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

export function getBannerDatabase() {
    if (Main.Instance.BannerState.countCalled === false) {
        Logic.Type.New(Main.Instance.BannerState, {countCalled:true})
        DbRoutes.GetBanner()
    }
}

function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetBanner()
        FirstPage()
    }
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Banner + ' ' + Assets.withName + ' "' + row.original.name + '"?')) {

            if (Main.Instance.BannerState.pNum === 
            (Math.trunc((Main.Instance.BannerState.count - 2) / 
            Main.Instance.BannerState.pSize) + 1)) {
                DbRoutes.RemoveBanner (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.RemoveBanner (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function toggleActive(row: any) {
    if (row) {
        DbRoutes.Toggle('.banner SET active = ', row.original.id, row.original.active, getBannerDatabase)
    }
}

function handleEdit(row: any) {
    gotoTop()

    if (row) {
        let active: string
        active = row.original.active.toString() === 'true' ? '1' : '2'

        Logic.Type.New(Main.Instance.BannerState, {
            mode: true,
            id: row.original.id.toString(), 
            name: row.original.name,
            name_saved: row.original.name,
            image: row.original.banners,
            image_saved: row.original.banners,
            active,
            active_saved: active,
        })
    }
}

function removeImage() {
    if (Main.Instance.BannerState.id !== undefined && parseInt(Main.Instance.BannerState.id) !== 0 && confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.BannerState, {image: Assets_.zeroPng})
        DbRoutes.Action('.banner SET image = ' + Assets_.zeroPng, Main.Instance.BannerState.id, getBannerDatabase)
    }
}

function handleInsertUpdate() {

    const bannerItem = {
        id : !Main.Instance.BannerState.id ? '' : Main.Instance.BannerState.id,
        name : !Main.Instance.BannerState.name ? '' : Main.Instance.BannerState.name,
        image : Main.Instance.BannerState.image ? Main.Instance.BannerState.image : Assets_.zeroPng,
        site_id: Main.Instance.FrontendStoreState.site_id,
        active : !Main.Instance.BannerState.active ? '1' : Main.Instance.BannerState.active,
    }

    if (parseInt(bannerItem.active) === 1) { bannerItem.active = 'true' }
    if (parseInt(bannerItem.active) === 2) { bannerItem.active = 'false' }

    if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
    } else if (Main.Instance.BannerState.mode === false) {
        DbRoutes.insertBanner (bannerItem, InsertLastPage)
    } else {
        DbRoutes.updateBanner (bannerItem, Update)
    }
}

function ApplyFilterActive(text: string) {
    Logic.Type.New(Main.Instance.BannerState, {filter_active:text})
    FirstPage()
}

function getBanner() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {bannerStream : undefined})
}

function  clearFields() {
    Logic.Type.New(Main.Instance.BannerState, {
        name: '',
        mode : false,
        active: '1',
        image: Assets_.zeroPng,
    })
}

function gotoInsertMode() {
    if (
        Main.Instance.BannerState.name === Main.Instance.BannerState.name_saved
        && Main.Instance.BannerState.image === Main.Instance.BannerState.image_saved
        && parseInt(Main.Instance.BannerState.active) === parseInt(Main.Instance.BannerState.active_saved)
    ) {
        clearFields()
    } else {
        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.BannerState,{offset:(0).toString()})
    getBanner()
    Logic.Type.New(Main.Instance.BannerState,{pNum: 0})
}

function PrevPage() {
    if (Main.Instance.BannerState.pNum > 0) {
        Logic.Type.New(Main.Instance.BannerState, {offset:((Main.Instance.BannerState.pNum - 1) * Main.Instance.BannerState.pSize).toString()})
        getBanner()
        Logic.Type.New(Main.Instance.BannerState,{pNum: Main.Instance.BannerState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.BannerState.pNum + 1) * Main.Instance.BannerState.pSize < Main.Instance.BannerState.count) {
        Logic.Type.New(Main.Instance.BannerState, {
            offset: ((Main.Instance.BannerState.pNum + 1) * 
            Main.Instance.BannerState.pSize).toString()})
        getBanner()
        Logic.Type.New(Main.Instance.BannerState,{pNum: Main.Instance.BannerState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.BannerState.count <= Main.Instance.BannerState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.BannerState.count === 0) {

            Logic.Type.New(Main.Instance.BannerState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.BannerState.count - 1) / 
                    Main.Instance.BannerState.pSize)
            Logic.Type.New(Main.Instance.BannerState, {
                offset:(npNum * Main.Instance.BannerState.pSize).toString()})
        }

        getBanner()

        Logic.Type.New(Main.Instance.BannerState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.BannerState, {count:Main.Instance.BannerState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.BannerState,{count:Main.Instance.BannerState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.BannerState,{count:Main.Instance.BannerState.count --})
    getBanner()
}

function Update() {
    clearFields()
    getBanner()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.BannerState, {pSize: parseInt(text)})
        FirstPage()
    }
}

function ApplyMasterSelect(text: string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id: text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream: undefined,
            contractualPartnersAllStream2: undefined,
            sitesOperatorAllStream: undefined,
            levelsAllStream: undefined,
        })

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {
        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)
        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream: undefined, sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id, Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id: text,      
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream: undefined})
    } else {
        getBannerDatabase()
    }
}

export function Banner() {
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })

        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    return (
    <div>
        <div className = "clearfix">
            <h3 className = "level fl">
                {Assets.aeBanner}
            </h3> 
            {isUserSystem() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                        Main.Instance.FrontendStoreState.holding_id}
                data = {Main.Instance.DatabaseDataState.masterAllStream}
                ClassName = "form-control"

                o1 = {Assets.SelectMaster}
                o2 = {Assets.MasterQ}
                onChange = {(text) => ApplyMasterSelect(text)}
                icon = {Assets_.faMasterClass}
            /> : null}

            {isUserSystem() || isUserMaster() || isUserGameProvider() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                        Main.Instance.FrontendStoreState.operator_id}
                data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
                data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
                label1 = {Assets.OP}
                label2 = {Assets.BO}
                
                ClassName = "form-control"

                o1 = {selectOperator}
                o2 = {operatorQ}
                onChange = {(text) => ApplyOperatorSelect(text)}
                icon = {Assets_.faCompanyClass}
            /> : null
            }

            {!isUserSubEntity() ?
            <FormSelect
                level = {true}
                value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                        Main.Instance.FrontendStoreState.site_id}
                data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
                ClassName = "form-control"
                o1 = {selectSite}
                o2 = {siteQ}
                onChange = {(text) => ApplySiteSelect(text)}
                icon = {Assets_.faSubEntityClass}
            />
            : void 0
            }

        </div>
        <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix" style = {{position: 'relative'}}>
            <br/>
            <div 
                className = {Main.Instance.BannerState.mode ? 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                                'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row">
                        <h4 className = "fl">
                            {Main.Instance.BannerState.mode ?
                            Assets.editBanner + ': ' + Main.Instance.BannerState.name
                            :
                            Assets.addBanner}
                        </h4>

                        {Main.Instance.BannerState.mode ?
                        <button
                            style = {{position: 'absolute', top: '-10px', right: '-9px', background: 'transparent', marginRight: '0px', padding: '0px', border: '0px'}}
                            title = {Assets.CloseEditModeDesc} 
                            onClick = {() => gotoInsertMode()} 
                            className = "btn btn-default btn-sm"
                            // icon = {Assets_.faCancelClass} 
                            // text = {Assets.CloseEditMode}
                        >
                            <i className = {Assets_.faCancelClass}/>
                        </button>                    
                        : <span/>
                        }
                    </div>

                    <div className = "row admin-users-row">
                        <FormInput 
                            value = {!Main.Instance.BannerState.name ? '' :
                                    Main.Instance.BannerState.name} 
                            ClassName = "form-control register-input" 
                            type = "text" 
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.BannerState, {name:text})}} 
                            placeholder = {Assets.pName} 
                            icon = {Assets_.faPuzzleFixedPieceClass}
                        />

                        <RadioButton2
                            value = {!Main.Instance.BannerState.active ? '1' :
                                    Main.Instance.BannerState.active} 
                            text = {Assets.Activity}
                            ClassName = "input-group-radiobutton2 margin-bottom-sm fr ml" 
                            title = {Assets.ActivityDesc}
                            i1 = {Assets_.active}
                            i2 = {Assets_.inactive}
                            onClick = {(text) => {
                                Logic.Type.New(Main.Instance.BannerState, {active:text})}} 
                        />

                        {
                        <input  
                            accept = "image/png, image/jpg, image/jpeg, image/gif"
                            id = "banner-input"
                            className = "UploadShopItemWrapper" 
                            type = "file" 
                            style = {{float: 'right', /*width: '95px',*/ marginLeft: '10px'/*, marginRight: '10px'*/}}
                            onChange = {(event) => { 
                                const cloneFile: File = event.target.files[0]
                                const reader = new FileReader()
                                reader.onload = (e) => {
                                    Logic.Type.New (Main.Instance.BannerState, {image: reader.result as string})
                                }
                                reader.readAsDataURL(cloneFile);
                            }}
                            onClick = {(event) => {event.target = null}}
                        />}
                        {Main.Instance.BannerState.image !== Assets_.zeroPng ?
                        <div 
                            style = {{
                                // background:'#fff',
                                position: 'relative',
                                height:'98px',
                                maxWidth:'18%',
                                width:'98px',
                                marginTop:'-35px', 
                                // marginRight:'2%',
                                float:'right',
                                display: 'block',
                            }}
                        >
                            <img 
                                src = {Main.Instance.BannerState.image} 
                                style = {{
                                    height:'100%',
                                    width:'auto',
                                    zIndex:9998,
                                    display:'block', border:'1px solid #999',
                                }}
                            />
                            <button 
                                className = "btn btn-xs btn-nothing"
                                title = {Assets.RemoveImage}
                                style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px'}}
                                onClick = {() => removeImage()}
                            >
                                <i className = {Assets_.faCancelClass}/>
                            </button>
                        </div>
                        :
                        <span 
                            style = {{ 
                                float:'right',
                                display:'block',
                                // width:'10%',
                                // marginRight:'1%',
                                marginTop: '2px',
                                height: '26px',
                                fontSize:'16px',
                                paddingLeft:'12px',
                                paddingRight:'12px',
                                paddingTop:'5px',
                            }}
                            className = "label label-warning"
                        >
                            {Assets.NoImage}
                        </span>}
                    </div>

                    <div className = "row admin-users-container clearfix">
                        <ButtonIcon 
                            description = {!Main.Instance.BannerState.mode ? 
                                            Assets.saveChanges : 
                                            Assets.updateChanges} 
                            click = {() => handleInsertUpdate()}
                            ClassName = {Main.Instance.BannerState.mode ? 
                                        'btn btn-default btn-sm btn-plus fr mt-30' :
                                        'btn btn-primary btn-sm btn-plus fr mt0'}
                            Icon = {faSave as IconDefinition} 
                            text = {Assets.Save}
                        />
                    </div>
                </div>
            </div>

            <div className = "control-panel">
                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.BannerState.pSize ? '' : 
                    Main.Instance.BannerState.pSize.toString()}  
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
                />
                <button 
                    className = "ml20 btn btn-primary btn-xs" 
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs" 
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button 
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>

                <FormSelect 
                    span = {true}
                    value = {!Main.Instance.BannerState.filter_active ? '0' : 
                            Main.Instance.BannerState.filter_active}
                    data = {[0]}
                    ClassName = "control-panel-select-narrow"
                    o1 = {Assets.Any} 
                    o2 = {Assets.Active}
                    o3 = {Assets.Inactive}
                    onChange = {(text) => ApplyFilterActive(text)} 
                />

                <span className = "label label-success table-label mt5">
                    {Assets.records}
                    {Main.Instance.BannerState.count ? Main.Instance.BannerState.count : 0}
                </span>
                <span className = "label label-success table-label mt5">
                    {Assets.showing}
                    {Main.Instance.BannerState.count ? Main.Instance.BannerState.pNum * 
                    Main.Instance.BannerState.pSize + 1 : 0}{' - '}
                    {Main.Instance.BannerState.count ? 
                    ((Main.Instance.BannerState.pNum + 1) * 
                    Main.Instance.BannerState.pSize > Main.Instance.BannerState.count ? 
                    Main.Instance.BannerState.count : 
                    (Main.Instance.BannerState.pNum + 1) * Main.Instance.BannerState.pSize) : 0}
                </span>
                <span className = "label label-success table-label mt5">
                    {Assets.page}
                    {Main.Instance.BannerState.count ? 
                    Main.Instance.BannerState.pNum + 1 :0}{' / '}
                    {Main.Instance.BannerState.count ? 
                    Math.trunc((Main.Instance.BannerState.count - 1) /
                    Main.Instance.BannerState.pSize + 1) : 0}
                </span>
            </div>
            <h3>{Assets.tBanner}</h3>

            {Main.Instance.DatabaseDataState.bannerStream ? 
            <ReactTable
                data = {Main.Instance.DatabaseDataState.bannerStream}
                columns = {[
                    {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                    {Header: Assets.Banner, accessor: 'banners', headerClassName: 'th-left', Cell: (row) => <img style = {{height: '50px',textAlign: 'center'}} src = {row.value}/>},
                    {Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                    {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
            />
            : getBannerDatabase()}
        </div>
    </div>
    )
}