import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import {LeagueData, Import} from '../../../Logic/OddsStore/OddsState'
import * as Config from '../../../Logic/Config'
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileImport} from '@fortawesome/free-solid-svg-icons'
import * as OddsData from '../../../Logic/OddsStore/OddsState'
import {injectSpaces} from '../../../Logic/Utils'

interface State {
    // selectedLeague: number
    filterDisabled: boolean
    league: string
    region: string
    sport: string
}

enum Source {
    Unknown,
    Both,
    OddsPortal,
    OddsChecker
}

export class TableOfLeagues extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            filterDisabled: true,
            league: '',
            region: '',
            sport: '',
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }
    public getSource(data: LeagueData): Source {
        if (data && data.url && data.url.length > 0 && data.link && data.link.length > 0) {
            return Source.Both
        } else if (data && data.url && data.url.length > 0) {
            return Source.OddsChecker
        } else if (data && data.link && data.link.length > 0) {
            return Source.OddsPortal
        } else {
            return Source.Unknown
        }
    }

    public GetSource(data: LeagueData): JSX.Element {
        const source: Source = this.getSource(data)
        const className: string = source === Source.Unknown ? 'label label-sm label-default' : source === Source.OddsPortal ? 'label label-sm label-primary' : source === Source.OddsChecker ? 'label label-sm label-info' : source === Source.Both ? 'label label-sm label-success' : ''
        return <span style = {{fontSize: '13px'}} className = {className}>{Source[source]}</span>
    }

    public getSportById(sportid: number): string {
        if (Main.Instance.OddsState.sport) {
            for (const item of Main.Instance.OddsState.sport) {
                if (item.id === sportid) {
                    return item.name
                }
            }
        }
        return '-'
    }


    public getSport(): JSX.Element[] {
        const sport: JSX.Element[] = []
        sport.push(<option key = "_" value = "">{Assets.Any + ' ' + Assets.Sport.toLowerCase()}</option>)
        if ( Main.Instance.OddsState.DistinctSport) {
            for (const item of  Main.Instance.OddsState.DistinctSport) {
                sport.push(<option key = {item.sport} value = {item.sport}>{item.sport}</option>)
            }
        }
        return sport
    }

    public getRegions(): JSX.Element[] {
        const regions: JSX.Element[] = []
        // const Regions: any[] = []
        regions.push(<option key = "_" value = "">{Assets.AnyRegion}</option>)
        if (this.state.sport === '') {
            if ( Main.Instance.OddsState.DistinctRegion2) {
                for (const item of  Main.Instance.OddsState.DistinctRegion2) {
                    // Regions.push({sport: item.sport, region: item.region})
                    regions.push(<option key = {item.region} value = {item.region}>{item.region}</option>)
                }
            }    
        } else {
            if ( Main.Instance.OddsState.DistinctRegion) {
                for (const item of  Main.Instance.OddsState.DistinctRegion) {
                    if (this.state.sport.length > 0 && this.state.sport !== item.sport) {continue}
                    // Regions.push({sport: item.sport, region: item.region})
                    regions.push(<option key = {item.sport + '_' + item.region} value = {item.region}>{item.region}</option>)
                }
            }
        }
        return regions
    }

    public getLeagues(): JSX.Element[] {
        const leagues: JSX.Element[] = []
        leagues.push(<option key = "_" value = "">{Assets.Any + ' ' + Assets.League.toLowerCase()}</option>)
        if (this.state.region === '') {
            if ( Main.Instance.OddsState.DistinctLeague2) {
                for (const item of  Main.Instance.OddsState.DistinctLeague2) {
                    leagues.push(<option key = {item.league} value = {item.league}>{item.league}</option>)
                }
            }    
        } else {
            if ( Main.Instance.OddsState.DistinctLeague) {
                for (const item of  Main.Instance.OddsState.DistinctLeague) {
                    if (this.state.sport.length > 0 && this.state.sport !== item.sport) {continue}
                    if (this.state.region.length > 0 && this.state.region !== item.region) {continue}
                    leagues.push(<option key = {item.sport + '_' + item.region + '_' + item.league} value = {item.league}>{item.league}</option>)
                }
            }    
        }

        return leagues
    }

    public import (data: LeagueData, source: Source) {
        // console.log (data, source)
        if (source === Source.OddsPortal) {
            // console.log (data)
            axios.post(Config.oddsServerMatches, {import: Import.Oddsportal, source: data}, {headers: {'api-key': Config.keyGetOdds}})
        }
    }

    public getImportButton(text: string, data: LeagueData, source: Source): JSX.Element {
        return (
            <button
                onClick = {() => this.import (data, source)}
                type = "button"
                className = {source === Source.OddsPortal ? 'btn btn-primary btn-sm mr0' : source === Source.OddsChecker ? 'btn btn-info btn-sm mr0' : 'btn btn-success btn-sm mr0'}
            >
                {text}
            </button>
        )
    }

    public fetchResult(row: OddsData.LeagueData) {
        // console.log (row)
        axios.post(Config.oddsServerResults, {source: row}, {headers: {'api-key': Config.keyGetOdds}})
        .then((response) => {
            // console.log (response)
            if (response.status === 200) {DbRoutes.updateMatches()}
        })
    }

    public getResult(row: OddsData.LeagueData, disabled: boolean): JSX.Element {
        return <button disabled = {disabled} onClick = {() => this.fetchResult(row)} type = "button" className = "btn btn-xs btn-primary">{injectSpaces(OddsData.MatchStatus[OddsData.MatchStatus.GetResults])}</button>
    }

    public getFilter() {
        const filter: JSX.Element[] = []
        filter.push(<select onChange = {(e) => this.setState({sport: e.target.value})} key = "-1" value = {this.state.sport} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getSport()}</select>)
        filter.push(<select onChange = {(e) => this.setState({region: e.target.value})} key = "-2" value = {this.state.region} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getRegions()}</select>)
        filter.push(<select onChange = {(e) => this.setState({league: e.target.value})} key = "-3" value = {this.state.league} className = "form-control dbl fl mr" style = {{width: 'auto', marginTop: '-13px'}}>{this.getLeagues()}</select>)

        filter.push (<input key = "0" onClick = {() => this.setState({filterDisabled: undefined})} checked = {this.state.filterDisabled === undefined} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "1" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.All.toLowerCase() + ' ' + Assets.Leagues.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "2" onClick = {() => this.setState({filterDisabled: true})} checked = {this.state.filterDisabled === true} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "3" className = "fl dbl mr" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px'}}>
                {Assets.With.toLowerCase() + ' ' + Assets.Matches.toLowerCase()}
            </div>
        )
        
        filter.push (<input key = "4" onClick = {() => this.setState({filterDisabled: false})} checked = {this.state.filterDisabled === false} className = "fl dbl" type = "checkbox"/>)
        filter.push (
            <div key = "5" className = "fl dbl" style = {{marginLeft: '5px', lineHeight: '14px', fontSize: '14px', marginTop: '4px', marginRight: '20px'}}>
                {Assets.Without.toLowerCase() + ' ' + Assets.Matches.toLowerCase()}
            </div>
        )

        return filter
    }

    public GetOddsType(oddsType: number): string {
        // console.log (Main.Instance.OddsState.bet)
        if (Main.Instance.OddsState.bet) {
            for (const item of Main.Instance.OddsState.bet) {
                if (item.id === oddsType) {
                    return item.name
                }
            }
        }
        return oddsType ? oddsType.toString() : '-'
    }

    public GetOddsSubtype(oddsType: number): string {
        // console.log (Main.Instance.OddsState.bet)
        if (Main.Instance.OddsState.bet) {
            for (const item of Main.Instance.OddsState.bet) {
                if (item.id === oddsType) {
                    return (item.special_value ? item.special_value.toString() : '-')
                }
            }
        }
        return oddsType ? oddsType.toString() : '-'
    }

    public getOddsType(oddsType: number[][]): JSX.Element {
        if (oddsType === undefined || oddsType === null) {
            // oddsType = [[10], [60]] // 2 => FOR SOCCER OVER/UNDER 1.5, 2.5, 3.5
            // oddsType = [[10], [56, undefined, 59, 69, 60, 70, 61]] // 2 => FOR SOCCER OVER/UNDER 1.5, 2.5, 3.5
            oddsType = [[10], [43], [56, undefined, 59, 88, 69, 79, 60, 89, 70, 80, 61], [46], [47], [55, undefined, 99, 100, 101, 102, 103, 104], [51, undefined, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120]]
        }
        // console.log (oddsType)
        const OddsType: JSX.Element[] = []
        if (oddsType) {
            for (let i: number = 0; i < oddsType.length; i++) {
                let Content: string = ''
                let content: string = ''
                if (oddsType[i].length > 1 && oddsType[i][1] !== undefined) {
                    Content += (content.length === 0 && Content.length === 0 ? ':' : ',') + ' ' + oddsType[i][1]
                }

                if (oddsType[i].length > 2) {
                    for (let j: number = 2; j < oddsType[i].length; j++) {
                        content += (content.length === 0 && Content.length === 0 ? ':' : ',') + ' ' + this.GetOddsSubtype(oddsType[i][j])
                    }
                }
                OddsType.push(
                    <div
                        style = {{marginLeft: '5px'}}
                        className = "lh16"
                        key = {i.toString()}
                    >
                        {this.GetOddsType(oddsType[i][0])}{Content}{content}
                    </div>
                )
            }
        }
        return (
            <div>
                {OddsType}
            </div>
        )
    }

    public render() {
        // console.log (Main.Instance.OddsState.DistinctLeague)
        // console.log (Main.Instance.OddsState.DistinctRegion)
        // console.log (Main.Instance.OddsState.DistinctSport)
        // console.log (Main.Instance.OddsState.league)
        const filtered = []
        if (this.state.filterDisabled === true) {
            filtered.push({id: 'disabledResults', value: false})
        } else if (this.state.filterDisabled === false) {
            filtered.push({id: 'disabledResults', value: true})
        }

        if (this.state.sport.length > 0) {
            filtered.push({id: 'data.sport', value: this.state.sport})
        }

        if (this.state.region.length > 0) {
            filtered.push({id: 'data.section', value: this.state.region})
        }

        if (this.state.league.length > 0) {
            filtered.push({id: 'data.league', value: this.state.league})
        }

        // console.log (this.state.sport, this.state.region, this.state.league)

        return (
            <div>
                <h3 className = "pr">
                    <div className = "clearfix" style = {{position: 'absolute', top: '10px', right: '0px'}}>{this.getFilter()}</div>
                    {Assets.Leagues}/{Assets.Import + ' ' + Assets.New.toLowerCase() + ' ' + Assets.Matches.toLowerCase() + '/' + Assets.Import + ' ' + Assets.Results}
                </h3>
                {Main.Instance.OddsState.league ?
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.OddsState.league}
                    columns = {[
                        {width: 50, Header: Assets.ID, headerClassName: 'th-left', accessor: 'id'},
                        {width: 120, Header: Assets.Source, headerClassName: 'th-left', accessor: 'data', Cell: (row) => this.GetSource(row.value)},
                        {width: 240, Header: <><FontAwesomeIcon icon = {faFileImport}/>&nbsp;&nbsp;{Assets.Import + ' ' + Assets.OddsPortal}</>, headerClassName: 'th-left', accessor: 'data', Cell: (row) => row.value && row.value.link && row.value.link.length > 0 ? this.getImportButton(row.value.link, row.value, Source.OddsPortal) : '-'},
                        {width: 210, Header: <><FontAwesomeIcon icon = {faFileImport}/>&nbsp;&nbsp;{Assets.Import + ' ' + Assets.OddsChecker}</>, headerClassName: 'th-left', accessor: 'data', Cell: (row) => row.value && row.value.url && row.value.url.length > 0 ? this.getImportButton(row.value.url, row.value, Source.OddsChecker) : '-'},
                        {width: 100, Header: <><FontAwesomeIcon icon = {faFileImport}/>&nbsp;&nbsp;{Assets.Results}</>, headerClassName: 'th-left', accessor: 'disabledResults', Cell: (row) => this.getResult(row.original.data, row.value)},

                        {width: 200, Header: Assets.Sport , headerClassName: 'th-left', accessor: 'data.sport', Cell: (row) => row.original.data && row.value && row.value.length > 0 ? row.value : '-'},
                        {width: 200, Header: Assets.Region, headerClassName: 'th-left', accessor: 'data.section', Cell: (row) => row.original.data && row.value && row.value.length > 0 ? row.value : '-'},
                        {width: 200, Header: Assets.League, headerClassName: 'th-left', accessor: 'data.league', Cell: (row) => row.original.data && row.value && row.value.length > 0 ? row.value : '-'},
                        {Header: Assets.Odds + ' ' + Assets.Type.toLowerCase(), headerClassName: 'th-left', accessor: 'data', className: 'btn-nopadding', Cell: (row) => this.getOddsType(row.value.oddstype)/*JSON.stringify(row.value.oddstype)*/ /*: '-'*/},
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    defaultPageSize={10}
                    pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
                /> : void 0}
            </div>
        )
    }
}