import {Logic} from '../Type'
import {IPlayerGroupRule} from '../DataStore/DataStore'
import {RiskLevel, DashboardRuleType, PlayerCardButtonsKYC, PlayerCardButtonsRG, PlayerCardButtonsRM, PlayerCardButtonsGM} from '../RiskComplianceStore/RiskManagement'
import * as moment from 'moment'
import {ILevelDefinition} from './levels/levelsModelsNew'
import {IMissionDefinition2, IMissionState} from './missions/missionModelsNew'
import {IGameCategoryContributions, ICategories, ISignupBonus, IGlobalObjectiveConfiguration, ICurrencyContributions, IGlobalRacesConfiguration} from '../GeneralSettings/General'
import { CustomSecurityRights } from '../UserManagement/UserManagement'
import { ILevels } from './players/models/levelsModels'
export enum MT {BETS_COUNT = 1, WINS_COUNT, CONSECUTIVE_WINS, CONSECUTIVE_DAYS, BIG_WINS_COUNT, POINTS, DIFFERENT_DAYS}
export enum CT {BETS_COUNT = 1, WINS_COUNT, CONSECUTIVE_WINS, BIG_WINS_COUNT}

export interface PaymentProcessedDuplicates {
    id: number
    occurrences: number
    dates?: string[]
    date?: string
    paymentId?: number[]
}

export interface IGeneral {
    allowBonusMoneyInTournaments: boolean
    playerCategories: ICategories
    signUpBonusReward: ISignupBonus
    globalObjectiveConfiguration?: IGlobalObjectiveConfiguration
    currencyContributions: ICurrencyContributions
    gamificationProvider: string
    globalRacesConfiguration: IGlobalRacesConfiguration
    gameCategoryContribution: IGameCategoryContributions[]
}

type DayOfWeek = 0|1|2|3|4|5|6
type HourOfDay = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23

export interface UsersNew {
    active: boolean
    custom: CustomSecurityRights
    email: string
    entityId: number
    firstName: string
    id: number
    lastName: string
    phone: string
    role: number
    username: string
}

export interface Tournament {
    id: number
    name: string
    description: string
    tournament_current: number
    validFrom: string
    validTo: string
    leaderboardType: number
    tournamentType: string
    rounds: string
    maxRounds: number
    minRounds: number
    points: {}
    optIn: {}
    minMaxBets: MinMaxBets[]
    validGames: []
    prizes: Prizes[]
    invalidGames: []
    active: boolean
    raceDetails: RaceDate[]
}

export interface RaceDate {
    hour: HourOfDay
    dayOfWeek: DayOfWeek
    minute?: number
    durationMinutes?: number
}

export interface Prizes {
    firstRewarded: number
    lastRewarded: number
    rewardId: number
    rewardId2: number
    reward1Info: {}
    reward2Info: {}
}

export interface MinMaxBets {
    code: string
    maxBet: string
    minBet: string
}

export interface IMissionLeaderboard {
    playerId: string
    username: string
    tasksCompleted: number
    position: number
}

export interface IRewardsCRM {
    id: number
    name: string
    type: string
    numOfFreeSpins: number
    rewardId: number
    status: string
    updated_at: string
    image: {}
    value_spin: {}
}

export interface Game {
    Category: string
    Id: number
    Image: string
    Link: string
    Name: string
    Platform: string
    Provider: string
}

export interface IIssueCount {
    issueType: IssueTypes
    count: number
}

export interface IIssuesCount {
    type: TimelineEntrySubType
    totalIssues: number
    issuesCounts: IIssueCount[]
}

export interface IIssuesFilters {
    resolved?: boolean
    playerId?: string
    issueType?: IssueTypes[]
    riskLevel?: RiskLevel
    minIssueType?: IssueTypes
    maxIssueType?: IssueTypes
    dateFrom?: number
    dateTo?: number
}

/*
export interface IBonusInfo {
    bonusId: number
    amount: number
    amountConverted: number
    created: number

    active: boolean
    completed: boolean
    canceled: boolean

    wagered: number
    wagerRequirement: number

    // playerId: string
    // totalNumOfItems: number

    // let it be for now
    bonusType: BonusType
    walletId: string,
    balance: number
    currency: CurrencyType
    wageringRequirements: IBonusWageringRequirements[]
}
*/

export interface IBonusInfo {
    bonusId: number
    bonusName: string
    bonusInstanceId: number
    walletId: number
    state: string
    balance: number
    currency: string
    amount: number
    wagered: number
    wagerRequirement: number
    wageringRequirement: string
    playthrough: string
    amountConverted: number
    created: string
    grantedDate: string
}

export interface IBonusInfoFreeGames {
    bonusId: number,
    bonusName: string,
    bonusType: string,
    bonusInstanceId: number,
    created: string,
    state: string,
    freeGamesAmount: number,
    freeGamesValueAmount: number,
    freeGamesWinAmount: number,
    grantedDate: string,
    provider: string
}

export interface IBonusWageringRequirements {
    gameCategory: number
    coefficient: number
}

export enum BonusType {
    WAGER = 1,
}

export enum CurrencyType {
    CHF = 'CHF',
}

export interface IGameSession {
    completed: number
    created: number
    externalGameSessionId: string
    gameId: string
    gameSessionId: string
    providerId: number
    totalNumOfItems: string
    // playerId: string
    // ADDED EXTRA
    playerId: string
    roundsPlayed: number
    totalBetReal: number
    totalWinReal: number
    netWinReal: number
    startingBalanceReal: number
    endingBalanceReal: number
    totalBetBonus: number
    totalWinBonus: number
    netWinBonus: number
    startingBalanceBonus: number
    endingBalanceBonus: number
}

export interface IGameSessionTransaction {
    currency: string
    id: string
    sessionId: string
    roundId: string
    totalAmount: number
    transactionType: TransactionType
    transactionStatus: TransactionStatus
    created: number
    completed: number
    realAmount: number
    bonusAmount: number
    realBalanceBefore: number
    realBalanceAfter: number
    bonusBalanceBefore: number
    bonusBalanceAfter: number
/*
    id: string
    // playerId: string
    sessionId: string
    // gameSessionId: string
    // gameId: number
    roundId: number
    totalAmount: number
    transactionType: TransactionType
    transactionStatus: number
    created: string
    completed: string
    realAmount: string
    bonusAmount: string
    realBalanceBefore: string
    realBalanceAfter: string
    bonusBalanceBefore: string
    bonusBalanceAfter: string
    currency: string
*/
}

export interface AgeGroup {text: string, low?: number, high?: number}

export interface UploadDocumentsFiles2 {
    acl: {
        owners: any
        pathPrefix: string
        readers: any
        writers: any
    }
    baseUrl: string
    bucket: {
        acl: {
            default: {
                owners: any
                pathPrefix: string
                readers: any
                writers: any
            }
            owners: any
            pathPrefix: string
            readers: any
            writers: any
        },
        baseUrl: string,
        iam: {
            resourceId_: string
        }
        id: string
        interceptors: any[]
        metadata: any
        methods: any
        name: string
        parent: any
        storage: any
    }
    id: string
    interceptors: any[]
    metadata: {
        bucket: string
        contentType: string
        crc32c: string
        etag: string
        generation: string
        id: string
        kind: string
        md5Hash: string
        mediaLink: string
        metageneration: string
        name: string
        selfLink: string
        size: string
        storageClass: string
        timeCreated: string
        timeStorageClassUpdated: string
        updated: string
    }
    methods: any
    name: string
    parent: any
    storage: {
        acl: {
            OWNER_ROLE: string,
            READER_ROLE: string,
            WRITER_ROLE: string
        }
    }
    _events: any
    _eventsCount: number
}


/*
export interface UploadDocumentsFiles {
    kind: string,
    id: string,
    selfLink: string,
    name: string,
    bucket: string,
    generation: string,
    metageneration: string,
    contentType: string,
    ContentType?: string,
    timeCreated: string,
    updated: string,
    storageClass: string,
    timeStorageClassUpdated: string,
    size: string,
    md5Hash: string,
    mediaLink: string,
    crc32c: string,
    etag: string
}
*/
export interface Point {
    id: number,
    title: string,
    point_program_id: number,
    namespace: string,
    playerlist: string,
    includedgames: string,
    included_games: string,
    include_games: string,
    excludedgames: string,
    excluded_games: string,
    exclude_games: string,
    player_list_id: number,
    player_list: string,
    level_min: number,
    level_max: number,
    sublevel_min: number,
    sublevel_max: number,
    target: string,
    nlp: number,
    category_id: number,
    detail_id: number,
    points_pcu: number,
    bet_size_c: number,
    bet_size_id: number,
    multiplier_id: number,
    from_date: string,
    to_date: string,
    various: string,
    currency: string,
    average_c: number,
    average_id: number,
    active: string,
    change_gs: string,
    wagering: number,
    losses: number,
    bet_min: number,
    bet_max: number,
    chart1_start: number,
    chart2_start: number,
    chart1_step: number,
    chart2_step: number,
    chart1_stop: number,
    chart2_stop: number,
    chart1_value: number,
    chart2_value: number,
  }

export interface LotteryDetail {
    bonusnumbermax: number
    bonusnumbermin: number
    bonusnumbers: number
    country: string
    currency: string
    cutoffhours: string
    name: string
    flag: string
    extranumbers: number
    isplayable: number
    numbermax: number
    numbermin: number
    numbers: number
    refundnumbermax: number
    refundnumbermin: number
    refundnumbers: number

    JackpotSize: number
    JackpotCurrency: string
    nextDrawDate: string
    nextDrawTime: string
    nextDrawDay: string
    
    lastDrawid: number
    lastDrawDate: string
    lastDrawTime: string
    lastDrawDay: string
    lastJackpotSize: number
    lastJackpotCurrency: number
    lastNumbers: number[]
    lastExtranumbers: number[]
    lastBonusnumbers: number[]
    lastRefundnumbers: number[]
}

export interface FrontendShopItem {
    active: boolean
    free_round: boolean
    title: string
    image: string
    price: string
    discount: string /* in percent */
}

export interface Option {
    id: string
    name: string
}

export interface FixedOdds {
    edited: boolean
    n: number
    fixed_odds: number
    stake?: number
    l?: number
}

export interface Task {
    objectives: Objective
    rewardId: number
}

export interface Objective {
    missionType: MT
    minBet: number // this should also be ?
    roundsNeeded: number
    rewardId: number
    bigWinValue?: number
    daysNeeded?: number
}

interface IAleaccTriggerFreeRoundCurrency {
    Selected: boolean
    Name: string
    Amount?: number
    FreespinAmount?: number
    BetLevel?: number
    MinDeposit?: number
    MaxDeposit?: number
    MaxDepositMatch?: number
}

export interface IAleaccTriggerFreeRound {
    Identifier?: string
    Name?: string
    Currency?: IAleaccTriggerFreeRoundCurrency[]
    TriggerType?: 'Gamanza'
    Product?: 'Casino' | 'Bingo' | 'Sportsbook' | 'ScoutFantasy' | 'PoolBetting' | 'Poker' | 'Lotto'
    product?: number
    RewardType?: 'FixedSum' | 'MatchedDeposit' | 'Freespin' | 'Freebet'
    rewardType?: number
    ValidDays?: number
    WageringType?: 'PaidBonusAmountXFactor' | 'PaidBonusAmountPlusDepositAmountXFactor' | 'FixedAmount' // | 'NumberOfBetsXBetAmount'
    wageringType?: number
    WageringFactor?: number
    SelectedGameIds?: number[]
    PlayerId?: number
    SelectedGameProvider?: string
    IsFlexibleFreespin?: boolean
    Payline?: number
    PaylineBet?: number
    MinOdds?: number
    MinAmount?: number
    CoinValues?: number[]
    Percentage?: number
    WageringConditionType: 'None' | 'MinAmount' | 'MinOdds'
    WageringConditionFactor?: number
}

export enum TimeUnits {
    Second = 0,
    Minute,
    Hour,
    Day,
    Week,
    Month,
    Quarter,
    Year,
}

export interface PlayerData {
    id: string,
    name: string,
    surname: string,
    gender: boolean,
    dob: string,
    country: string,
    currency: string,
    continent: string,
    timezone: string,
    level: number,
    sublevel: number
    balance: number
}

export interface IPlayersInfo {
    gender: string
    age: number
    canton: string
    nationality: string
    country: string
    currency?: string

    accountStatus: AccountStatus // not taken into account
    registered: string|number // not taken into account
    testAccount: boolean // not recieved
}
/*
export interface PlayerDataNew {
    id: string,
    name: string,
    surname: string,
    gender: boolean,
    dob: string,
    country: string,
    currency: string,
    region: string,
    timezone: string,
    level: number,
    sublevel: number
    balance: number
    accountStatus: number
}
*/

interface LargeValueDepositsWithdrawalData {
    limit?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    riskLevel?: RiskLevel,
    oneTransaction?: boolean,
    withinHours?: number,
    accountSuspension?: boolean,
    // allowPlay: boolean,
    // allowDeposit: boolean,
    // allowWithdraw: boolean,
    verificationStatus: AccountStatus,
    letThrough: boolean,
    newAccountStatus: AccountStatus
}
export interface LargeValueDepositsWithdrawal {
    id: number,
    data?: LargeValueDepositsWithdrawalData
}

interface TemporaryAccountStatusData {
    // limit?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    // daysOpened?: number,
    // depositSum: boolean,
    allowPlay: boolean,
    allowDeposit: boolean,
    allowWithdraw: boolean,
    verificationStatus: AccountStatus,
    riskLevel: RiskLevel,
    yearsBeforeDeletion?: number,
    monthsBeforeDeletion?: number,
    delete: boolean,
    accountSuspension?: boolean
}
export interface TemporaryAccountStatus {
    id: number,
    data?: TemporaryAccountStatusData
}

interface NoPlayerActivityStatusData {
    years?: number,
    months?: number,
    riskLevel: RiskLevel,
    accountSuspension?: boolean
}
export interface NoPlayerActivityStatus {
    id: number,
    data?: NoPlayerActivityStatusData
}

interface BlacklistData {
    checkAtRegistration: boolean,
    checkAtLogin: boolean,
    allowCreateAccount: boolean,
    allowPlay: boolean,
    allowDeposit: boolean,
    allowWithdraw: boolean,
    allowLogin: boolean,
    recieveMarketingPromotion: boolean,
    riskLevel: RiskLevel,
    checkBlackList: boolean,
    checkNightJob: boolean,
    allowAccountSuspension: boolean,
    accountSuspension?: boolean
    check?: {
        dayOfWeek: number,
        timeUtc: string
    }
}
export interface Blacklist {
    id: number,
    data?: BlacklistData
}

interface MaximumBalanceData {
    limit?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    allowPlay: boolean,
    allowDeposit: boolean,
    allowWithdraw: boolean,
    riskLevel: RiskLevel,
    accountSuspension?: boolean
}
export interface MaximumBalance {
    id: number,
    data?: MaximumBalanceData
}

interface RetailData {
    firstName: string,
    lastName: string,
    birthday: string,
    comment: string,
    riskLevel: RiskLevel
}

export interface Retail {
    id: number,
    data?: RetailData
}

interface RetailActionData {
    notifyCS: boolean,
    accountSuspension?: boolean,
    riskLevel: number    
}

export interface RetailAction {
    id: number,
    data?: RetailActionData
}
/*
interface ElevatedRiskData {
    playerId: number,
    // timestamp: string,
    // comment: string,
    previousRiskLevel: RiskLevel,
    currentRiskLevel: RiskLevel,
    riskManagerId?: number,
    resolved: boolean,
    requestedTimestamp: string,
    resolvedTimestamp: string,
    comments?: {
        timestamp: string,
        userId: number,
        comment: string
    }[],
}

export interface ElevatedRisk {
    id: number,
    data?: ElevatedRiskData
}
*/
/*
export interface Issue {
    comment: string
    id: number
    issueType: number
    playerId: string
    resolved: boolean
    riskLevel: number
    riskManagerId: number
    timestamp: string
}
*/
export interface PlayerCard {
    playerId: string
    firstName: string
    lastName: string
    username: string
    accountStatus: AccountStatus
    issues?: number
    Issues?: IIssue[]
}

export enum Level {Member = 1, Silver, Gold, Diamond, BlackCircle}

// THIS WILL NEED TO BE CHANGED AT SOME POINT WE SHOULD NOT HARDCODE

export enum GameCategories {
    Unknown,
    Slots,
    LiveCasino,
    Blackjack,
    Roulette,
    Baccarat,
    Poker,
    VirtualSports,
}

export enum CooloffCategories {
    All = 0, // cannot be attributed to a game, but it can be chosen as a category
    Slots, // cannot be attributed to a game, but it can be chosen as a category
    Blackjack, // cannot be attributed to a game, but it can be chosen as a category
    Roulette, // cannot be attributed to a game, but it can be chosen as a category
    Baccarat, // cannot be attributed to a game, but it can be chosen as a category
    Poker, // cannot be attributed to a game, but it can be chosen as a category
    VirtualSports, // cannot be attributed to a game, but it can be chosen as a category
    RngAll = 100, // cannot be attributed to a game, it cannot be chosen as a category
    RngSlots, // can be attributed to a game
    RngBlackjack, // can be attributed to a game
    RngRoulette, // can be attributed to a game
    RngBaccarat, // can be attributed to a game
    RngPoker, // can be attributed to a game
    RngVirtualSports, // can be attributed to a game
    LiveAll = 200, // cannot be attributed to a game, but it can be chosen as a category
    LiveSlots, // can be attributed to a game DISABLED AT THE MOMENT
    LiveBlackjack, // can be attributed to a game
    LiveRoulette, // can be attributed to a game
    LiveBaccarat, // can be attributed to a game
    LivePoker, // can be attributed to a game
    LiveVirtualSports, // can be attributed to a game DISABLED AT THE MOMENT
}

export enum CooloffOptionCategory {
    All = 0,
    Slots,
    Blackjack, 
    Roulette, 
    Baccarat, 
    Poker,
    VirtualSports,
    LiveCasino = 200
}

export enum CooloffGameCategory {
    RngSlots = 101,
    RngBlackjack,
    RngRoulette,
    RngBaccarat,
    RngPoker,
    RngVirtualSports,
    // LiveSlots,
    LiveBlackjack = 202,
    LiveRoulette,
    LiveBaccarat,
    LivePoker,
    // LiveVirtualSports,
}

export interface ICoolOffs {
    category: number
    dateCreated: string
    validUntil: string
}

export interface IQuestion {
    points: number
    question: string
    questionId: number
    ratingPoints: number
}

export interface ISelfAssessment {
    date: string,
    riskLevel: RiskLevel
    totalPoints: number
    questions: IQuestion[]
}


// import {TransactionStatus} from "./withdrawalsEnums";
// import {Level} from "../playerCard/playerCardEnums";
// import {AccountStatus, RiskLevels} from "../defaultEnums";
// import {TransactionType} from "../connections/walletEnums";

export interface IWithdrawals {
    playerId: string
    amount: number
    transactionId: number
    currency: string
    status: TransactionStatus
    beneficiaryName: string
    iban: string
    bic: string
    firstName: string
    lastName: string
    username: string
    address: string
    streetNumber: string
    postalCode: string
    city: string
    countryAbbreviation: string
    level: Level
    riskLevel: RiskLevel
    accountStatus: AccountStatus
}

export interface IWithdrawalEvent {
    transactionId: number
    status: TransactionStatus
    operatorId?: number
    created?: number
}
/*
export interface IWithdrawalEventDb {
    transaction_id: string
    status: TransactionStatus
    operator_id?: number
    created?: string
}
*/
export interface ITransactionWallet {
    transactionStatus: TransactionStatus | TransactionStatus[];
    transactionType: TransactionType | TransactionType[];
    playerId: string;
    sessionId: string;
    gameSessionId: string;
    page: number;
    pageSize: number;
    dateFrom: number;
    dateTo: number;
}

export interface ITransactionFilters {
    transactionStatus?: TransactionStatus_[];
    transactionType?: TransactionType[];
    dateFrom?: string;
    dateTo?: string;
}

export enum GameSessionStatus {
    ONGOING = 1,
    COMPLETED,
    // EMPTY
}

export interface IGameSessionFilters {
    gameSessionStatus?: GameSessionStatus;
    dateFrom?: string;
    dateTo?: string;
}

export enum TransactionStatus_ {
    PENDING = 3,
    CANCELED = 4,
    COMPLETED = 6,
}

export enum TransactionStatus {
    APPROVED = 1,
    REJECTED = 2,
    PENDING = 3,
    CANCELED = 4,
    EXECUTED = 5,
    COMPLETED = 6,
    DENIEDBYBANK = 7,
}

export enum TransactionStatusNoPending {
    APPROVED = 1,
    REJECTED = 2,
    CANCELED = 4,
    EXECUTED = 5,
    COMPLETED = 6,
    DENIEDBYBANK = 7,
}

export enum TransactionStatusIndex {
    APPROVED = 0,
    REJECTED = 1,
    PENDING = 2,
    CANCELED = 3,
    EXECUTED = 4,
    COMPLETED = 5,
    DENIEDBYBANK = 6,
}

export interface Player {
    playerId?: string
    firstName?: string
    lastName?: string
    issuingAuthority?: string
    documentNumber?: string
    documentType?: string
    birthday?: string
    documentExpiry?: string
    nationality?: string
    gender?: string
    jurisdiction?: string
    issueDate?: string
    address?: string
    city?: string
    postalCode?: string
    phoneNumber?: string
    country?: string
    username?: string
    email?: string
    registered?: string
    isEmailVerified?: boolean
    isUtilityUploaded?: boolean
    isDocumentUploaded?: boolean
    isUtilityVerified?: boolean
    isDocumentVerified?: boolean
    isSuperVerified?: boolean
    allowLogin?: boolean
    allowPlay?: boolean
    allowDeposit?: boolean
    allowWithdraw?: boolean
    accountStatus?: AccountStatus
    acceptedTC?: boolean
    currency?: string
    lastLogin?: string
    loggedIn?: boolean
    limits?: ILimits[]
    selfAssessment?: ISelfAssessment[]
    tags?: string[]
    riskLevel?: RiskLevel
    level?: Level
    realBalance?: number
    bonusBalance?: number
    registrationIp?: string
    registrationDevice?: string
    cooloffs?: ICoolOffs[]
    isGettingSuperVerified?: boolean
    isMobileVerified?: boolean
    isAddressVerified?: boolean
    isPEP?: boolean
    allowSms?: boolean
    allowEmail?: boolean
    allowTelephone?: boolean
    allowPost?: boolean
    iban?: string
    beneficiaryName?: string
    ballyId?: string
    bic?: string
    streetNumber?: string
    language?: string
    testAccount?: boolean
    // just for the frontend
    streetNumberNew?: string
    firstNameNew?: string
    lastNameNew?: string
    addressNew?: string
    usernameNew?: string
    cityNew?: string
    postalCodeNew?: string
    emailNew?: string
    birthdayNew?: string
    genderNew?: string
    countryNew?: string

    phoneNumberNew?: string
    beneficiaryNameNew?: string
    ibanNew?: string
    bicNew?: string
    nationalityNew?: string
}

/*
export interface PlayerCardDetail {
    loggedIn: boolean,
    acceptedTC: boolean
    activated: string,
    active: boolean,
    address: string,
    birthday: string,
    city: string,
    country: string,
    currency: string,
    email: string,
    firstName: string,
    gender: string,
    id: number,
    jurisdiction: string, 
    kycStatus: string,
    lastLogin: string,
    lastName: string,
    mobilePhoneNumber: string
    modified: string,
    nationality: string
    netLossLimit: {
        timePeriod: 'N/A'|'daily'|'weekly'|'monthly',
        value: number,
        currency?: 'N/A'|'CHF'|'EUR'|'USD',
    }
    username: string
    postalCode: string,
    registered: string,
    tags: string[],
    // riskLevel: RiskLevel
    // level?: Level
}
*/
/*
interface NotifyData {
    email: string,
    firstName: string
    lastName: string
    riskLevel: boolean[]
}

export interface Notify {
    id: number,
    data: NotifyData
}
*/
interface SAGiftsData {
    title: string,
    active: boolean
}

export interface SAGifts {
    id: number,
    data: SAGiftsData
}

interface SACriteriaData {
    min?: number,
    max?: number,
    riskLevel: RiskLevel,
    comment: string,
    shownPlayer: string[],
    resolvedAutomatically: boolean
}

export interface SACriteria {
    id: number,
    data: SACriteriaData
}

interface SAQuestionsData {
    text: any,
    points: number[],
    ratingPoints: number[],
    active: boolean    
}

export interface SAQuestions {
    id: number,
    data: SAQuestionsData
}

interface NetLimitLossIncreasedData {
    timePeriod?: 'N/A'|'daily'|'weekly'|'monthly',
    // highNetLossThreshold?: number,
    // currency?: 'N/A'|'CHF'|'EUR'|'USD',
    settingTakesPlace?: number,
    settingTakesPlaceUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    settingActive?: boolean,
    riskLevel: RiskLevel
}

export interface NetLimitLossIncreased {
    id: number,
    data: NetLimitLossIncreasedData
}

interface NetLimitLossRegistrationData {
    timePeriod?: 'N/A'|'daily'|'weekly'|'monthly',
    defaultAmount?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    isDefault?: boolean,
    riskLevel: RiskLevel,
    accountSuspension?: boolean
}

export interface NetLimitLossRegistration {
    id: number,
    data: NetLimitLossRegistrationData
}

interface TemporaryExcludeData {
    allowPlay: boolean,
    allowDeposit: boolean,
    allowWithdraw: boolean,
    categoriesSelected: boolean,                
    manualExclusionFinish: boolean  // 'true' if it is possible to finish the cool-off manualy by contacting CS.
    minimalExclusionTime: number,
    minimalExclusionTimeUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    accountSuspension?: boolean
}

export interface TemporaryExclude {
    id: number,
    data: TemporaryExcludeData
}

interface TemporaryExcludeOptionsData {
    duration?: number,
    durationUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    riskLevel: RiskLevel,
    accountSuspension?: boolean
}

export interface TemporaryExcludeOptions {
    id: number,
    data: TemporaryExcludeOptionsData
}

interface DashboardBackendData {
    depositMin?: number
    depositMethodsMin?: number
    within?: number
    withinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    afterCoolOffPercentageHigher?: number
    beforeCoolOffSpan?: number,
    beforeCoolOffSpanUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    withdrawalRequestsCancelledThreshold?: number,
    gamingTime?: number,
    gamingTimeUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    gamingTimePercentageOver?: number,
    gamingDayMustHaveSession?: boolean,
    gamingDayMinSessionLength?: number,
    gamingDayMinSessionLengthUnit?: 'N/A'|'min'|'hour',
    netLossPerHour?: number,
    totalNetLoss?: number,
    coolOffsInitiatedThreshold?: number,
    netLossLimitIncreasedThreshold?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    riskLevel: RiskLevel,
    ruleType?: DashboardRuleType,
    accountSuspension?: boolean
}

export interface DashboardBackend {
    id: number,
    data: DashboardBackendData
}

interface SelfExclusionData {
    date: string,
    autoDuration: number,
    autoDurationUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    banCanBeLiftedThreshold: number,
    banCanBeLiftedThresholdUnit: 'N/A'|'min'|'hour'|'day'|'month'|'year',
    riskLevel: RiskLevel,
    accountSuspension?: boolean
}

export interface SelfExclusion {
    id: number,
    data: SelfExclusionData
}
/*
export enum RiskLevels {
    All,
    Low,
    Normal,
    Medium,
    High,
    Severe,
}
*/
export enum TransactionTypes {
    All,
    Deposit,
    Withdraw,
    Wager,
    Win,
}

interface IssueData {
    attachedUploads: string[]
    documentId: string
    utilityId: string
    type: string
    documentExpiry: string
    documentNumber: string
    issueDate: string
    issuingAuthority: string
    fileNames: string[]
    unblock: boolean
    riskLevel: number
    totalPoints: number
    verified: boolean
    closed: boolean
    phoneNumber: string
    wasProvisional: boolean
    wasActive: boolean
    previousStatus: AccountStatus
    currentStatus: AccountStatus
    allowLogin: boolean
    allowPlay: boolean
    allowDeposit: boolean
    allowWithdraw: boolean
    date: string|number
    amount: number
    period: number
    gameCategories: number[]
    disableSpecialAccount: boolean
    withdrawBalance: number
    addressCheckResult: IAddressMatchResult
    pepCheckResult: IDecisionMatrix
    email: string
    isPEP: boolean
    isAddressVerified: boolean
    firstName: string
    lastName: string
    address: string
    streetNumber: string
    postalCode: string
    city: string
    birthday: string,
    gender: string,
    country: string,
    beneficiaryName: string,
    iban: string,
    bic: string,
    nationality: string
    testAccount: boolean
    bonusId: number
    bonusCode: string
    username: string
    turnIntoActiveFullyIdentified: boolean
    turnBackIntoActive: boolean
    bypassRisk: boolean
    bypassBonus: boolean
}

export interface TriggeredBonusesInfo {
    playerId?: string
    bonusId?: number
    campaignId?: number
    bonusCode?: string
    dateFrom?: string
    dateTo?: string
    limit?: number
    page?: number
}

// Params for our service criff client calls. Credentials are set in the client
export interface IGetReportInput extends ITypeBaseRequest {
    searchedAddress?: IPersonAddressDescription;
  }
  
// Params for soap request, adds control and identityDescriptor properties (our credentials)
export interface ISoapGetReportInput extends ISoapTypeBaseRequest {
/* Either addressDescription or identifier (6.36)
have to be filled. If both elements are
provided, the system will use the identifier
and ignore the address. */
searchedAddress?: IPersonAddressDescription;
identifier?: IIdentifier;
reportType: ReportType;
targetReportFormat?: TargetReportFormatType;
idVerificationRequestData?: IIdVerificationRequestData;
additionalInput?: [IAdditionalData];
}

// Output for getReport soap method.
export interface ISoapGetReportOutput extends ITypeBaseResponse {
decisionMatrix: IDecisionMatrix;
addressMatchResult: IAddressMatchResult;
reportDetails: IReportDetails;
report?: string;
additionalOutput?: [IAdditionalData];
}

// Base interface for noode soap method calls
interface ISoapTypeBaseRequest extends ITypeBaseRequest {
// The versions indicate to what application version the customer integrated to. Set based on provided technical docs.
control: {
majorVersion: number;
minorVersion: number;
};
// Criff service credentials
identityDescriptor: {
userName: string;
password: string;
endUserId?: string;
costGroupId?: string;
};
}

interface ITypeBaseRequest {
// ID of request. This should be stored relative to the output response id. Specifications declare null is allowed, but we should be storing each request.
referenceNumber: string;
}

interface ITypeBaseResponse {
// ID of response. This should be stored relative to the input request id. Specifications declare null is allowed, so column should not be NOT NULL.
archivingId?: string;
}

interface IAddressDescription {
location?: ILocation;
contactItems?: IContactItem;
}

interface IPersonAddressDescription extends IAddressDescription {
firstName?: string;
lastName?: string;
middleName?: string;
coName?: string;
sex?: Sex;
birthDate?: string;
nationality?: string;
}

interface IIdVerificationRequestData {
documentImage?: string;
document?: IIdVerificationDocument;
person?: IIdVerificationPerson;
documentType?: IdVerificationDocumentType;
}

interface IIdentifier {
identifierText: string;
identifierType: 'ADDRESS_ID' | 'UNIT_ID' | 'LIABLE_ID' | 'CH_NUMBER' | 'CH_UID' | 'AT_FIRMENBUCHNUMMER' | 'CH_LEGACY_ADDRESS_ID' | 'CH_UID_VAT' | 'AT_UID' | 'AT_OENB' | 'AT_DVR_NR' | 'SCHUFA_ID';
}

export interface IDecisionMatrix {
decision: DecisionType;
decisionText: string;
subdecisions?: [{
type: string;
decision: DecisionType;
value: string;
infoText?: string;
}],
ratings?: [{
ratingType: string;
rating: string;
}],
creditLimit?: number;
}

interface ILocation {
street?: string;
houseNumber?: string;
apartment?: string;
zip?: string;
city?: string;
regionCode?: string;
subRegionCode?: string;
country?: string;
}

interface IReportDetails {
debts?: {
riskClass: 'NO_NEGATIVE' | 'PRE_LEGAL' | 'LEGAL_INITIAL' | 'LEGAL_ESCALATION' | 'LEGAL_DEFAULTED' | 'UNKNOWN';
dateOpen: string;
dateClose?: string;
amount?: IAmount;
amountOpen?: IAmount;
debtType: 'INFORMATION' | 'COLLECTION';
paymentStatus: 'IN_PROCESS' | 'WRITTEN_OFF' | 'PAID' | 'UNKNOWN';
paymentStatusText: string;
origin?: string;
text?: string;
};
// TODO

companyBaseData?: any;
paymentDelay?: any;
organizationPositionList?: any;
whoOwnsWhom?: any;
furtherRelations?: any;
publicationList?: any;
branchOfficeList?: any;
financialStatements?: any;
financialStatementsGroup?: any;
addressHistory?: any;
businessIndustryLicenses?: any;
scoreAnalysis?: any;
complianceCheckResult?: any;
idVerificationResponseData?: any;
schufaResponseData?: any;
controlPersons?: any;
}

interface IAmount {
// xml double
amount: string;
currency: string;
}

interface IIdVerificationDocument {
documentNumber?: string;
documentType?: 'DRIVING_LICENCE' | 'IDENTITY_CARD' | 'PASSPORT' | 'RESIDENCE_PERMIT' | 'UTILITY_BILL' | 'UNDETERMINED';
documentDescription?: string;
issuingStateOrOrganization?: string;
issuingDate?: string;
expirationDate?: string;
validityFromDate?: string;
signDate?: string;
mrz1?: string;
mrz2?: string;
mrz3?: string;
}

interface IIdVerificationPerson {
// IPersonAddressDescription
address?: {
firstName?: string;
lastName?: string;
maidenName?: string;
middleName?: string;
coName?: string;
sex?: Sex;
birthDate?: string;
};
address1?: string;
address2?: string;
nationalIdentificationNumber?: string;
placeOfBirth?: string;
nationality?: string;
}

export interface IAddressMatchResult {
addressMatchResultType: 'MATCH' | 'CANDIDATES' | 'NO_MATCH';
locationIdentification?: {
locationIdentificationType: 'HOUSE_CONFIRMED' | 'STREET_CONFIRMED_HOUSE_NOT_PROVIDED' | 'STREET_CONFIRMED_HOUSE_NOT_IDENTIFIED' | 'CITY_CONFIRMED_STREET_NOT_PROVIDED' | 'CITY_CONFIRMED_STREET_NOT_IDENTIFIED' | 'NOT_CONFIRMED' | 'NOT_PROVIDED';
houseType?: 'REGULAR' | 'PRIVATE_ONLY' | 'COMPANY_ONLY' | 'POLICE_STATION' | 'SHARED_USAGE' | 'RETIREMENT_HOME' | 'SCHOOL' | 'INTERNATIONAL_OR_PRIVATE_SCHOOL' | 'CHURCH' | 'CAMPING_PLACE' | 'HOSPITAL' | 'AMUSEMENT_PARK' | 'HOTEL' | 'ADMINISTRATION' | 'COURT' | 'EMBASSY' | 'AIRPORT' | 'ASYLUM_HOME' | 'PRISON' | 'NURSING_HOME' | 'UNDELIVERABLE_BY_POST' | 'UNKNOWN';
requestLocationNormalized?: ILocation
};
nameHint?: 'EMPTY' | 'CONFIRMED' | 'TYPO' | 'PARTIAL' | 'INCOMPLETE' | 'PROMINENT' | 'FICTIONAL' | 'INVALID' | 'NO_HINT';
foundAddress?: {
address: IAddressDescription;
identificationType: 'IDENTITY_IN_HOUSE' | 'IDENTITY_IN_STREET' | 'IDENTITY_IN_CITY' | 'IDENTITY_IN_UNIVERSE' | 'OWNER' | 'LASTNAME_IN_HOUSE' | 'COMPANY_GROUP' | 'NOT_IDENTIFIED';
identifiers?: IIdentifier;
};
candidates?: [{
address: IAddressDescription;
identifiers?: IIdentifier;
candidateRank: number;
groupId: number;
}];
character?: 'IDENTITY' | 'IDENTITY_WITH_CONFLICTING_HOUSE' | 'IDENTITY_WITH_CONFLICTING_STREET' | 'IDENTITY_WITH_CONFLICTING_CITY' | 'LAST_NAME_IN_HOUSE' | 'HOUSE_CONFIRMED' | 'STREET_CONFIRMED' | 'CITY_CONFIRMED' | 'NOT_CONFIRMED';
}

interface IContactItem {
contactText?: string;
contactType?: 'PHONE' | 'MOBILE' | 'FAX' | 'EMAIL' | 'WEB' | 'OTHER';
}

interface IAdditionalData {
key: string;
value: string;
}

export type ReportType =
'CREDIT_CHECK_CONSUMER'
| 'QUICK_CHECK_CONSUMER'
| 'CREDIT_CHECK_BUSINESS'
| 'FINANCE_CHECK_BUSINESS'
| 'QUICK_CHECK_BUSINESS'
| 'PREMIUM_CHECK_BUSINESS'
| 'PREMIUM_CHECK_CONSUMER'
| 'E_CHECK_CONSUMER'
| 'SANCTION_LIST_CHECK_CONSUMER'
| 'SANCTION_LIST_CHECK_BUSINESS'
| 'CONTROL_PERSON_BUSINESS'
| 'RISK_CHECK_BUSINESS'
| 'TENANT_CHECK_CONSUMER'
| 'COLLECTION_CHECK_CONSUMER'
| 'COLLECTION_CHECK_BUSINESS'
| 'REVIVAL_CHECK_CONSUMER'
| 'INFO_REPORT_BUSINESS'
| 'INFO_REPORT_CONSUMER'
| 'SCHUFA_CHECK_CONSUMER';

type TargetReportFormatType = 'PDF' | 'URL' | 'NONE';

type DecisionType = 'LIGHT_GREEN' | 'GREEN' | 'YELLOW_GREEN' | 'YELLOW' | 'ORANGE' | 'RED' | 'DARK_RED';

type Sex = 'MALE' | 'FEMALE' | 'UNKNOWN';

type IdVerificationDocumentType =
'DRIVING_LICENCE'
| 'IDENTITY_CARD'
| 'PASSPORT'
| 'RESIDENCE_PERMIT'
| 'UTILITY_BILL'
| 'UNDETERMINED';

export interface ICriffService {
getReportAsync: (args: ISoapGetReportInput) => Promise<any>;
}

export interface IIssue {
    id: number
    playerId?: string
    issueType?: IssueTypes
    entryType?: TimelineEntryType
    entrySubType?: TimelineEntrySubType
    riskLevel: RiskLevel
    comment: string
    assignTo: number[]
    resolved: boolean
    created?: string
    riskManagerId: number
    history?: IIssueHistory[]
    data?: Partial<IssueData>
}

export enum RecentEventsTypes {
    Authentication = 10,
    Registration,
    Login,
    Logout,
    Transactions = 20,
    Deposit,
    Withdraw,
    // FirstDeposits,
    Issues = 30,
    AllIssues,
    ResolvedIssues,
    UnresolvedIssues,
}

export interface IIssueHistory {
    // id: history
    riskLevel: RiskLevel
    comment: string
    resolved: boolean
    created: string
    riskManagerId: number
}

export enum TimelineEntryType {
    Transaction = 1,
    Session,
    Issue,
}

export enum TimelineEntrySubType {
    None,
    Transactions = 10,
    Deposit,
    Withdraw,
    ManualDeposit,
    CanceledDeposit,
    ManualWithdraw,
    CanceledWithdraw,
    Sessions = 20,
    ClosedSession,
    OpenSession,
    Issues = 30,
    RiskManagement,
    ResponsibleGaming,
    KnowYourCustomer,
    Gamification,
    DashboardBackend = 44,
    IssuesNonPaginated = 45,
    FraudAlerts,
    Veto,
    Crif,
    Pep
}

/*
export enum TimelineEntrySubType {
    None,
    Transactions = 10,
    Deposit,
    Withdraw,
    ManualDeposit,
    ManualWithdraw,
    CanceledWithdraw,
    CanceledDeposit,
    Sessions = 20,
    ClosedSession,
    OpenSession,
    Issues = 30,
    RiskManagement,
    ResponsibleGaming,
    KnowYourCustomer,
    Gamification,
    DashboardBackend = 44,
    IssuesNonPaginated = 45,
    FraudAlerts,
    Veto,
    Crif,
    Pep
}

*/

export enum IssueTypesInsert {
    SelectIssueType = 0,
    RiskManagement = 199,
    ResponsibleGaming = 299,
    ManualUploadDocument = 313,
    ManualUploadUtility = 314,
    AccountStatusChange = 350,
    KnowYourCustomer = 399,
}

export enum IssueTypes {
    All,
    RiskManagement = 100,
    Retail,
    FraudAlerts = 110,
    ThreeOrMoreDepositsBelowHundredWithinTenMinutes,
    AccountCreatedWithinLastTenDaysAndDepositsOverTenThousand,
    WinRatioOverEightyPcAndBetsOverThousand,
    IpForDepositNotSameAsRegistration,
    IpNotFromEuOrNotSameAsCreditCard,
    IpFromEuNotSwitzerland,
    AnonymousProxyForDepositRecognized,
    LowGamingActivityDepositsOverThousand,
    AccountCreatedWithinLastTenDaysAndWithdrawalsOverFiveThousand,
    UnusualBonusDepositRatio101,
    DepositAfterCoolOff = 152,
    NetLoss30DaysOld = 156,
    Other1 = 199,
    ResponsibleGaming = 200,
    CallbackRequest,
    PermanentExclusion,
    SelfAssessment,
    CooloffsChanged,
    LimitsChanged,
    BlacklistVeto = 210,
    BlacklistRegistration,
    BlacklistExclusion,
    BlacklistLogin,
    BlacklistCronJob,
    BlacklistManual,
    BlacklistWhitelisted,
    DashboardBackend = 250,
    DepositMethods, // 1 v specifikaciji
    DepositCooloff, // 2
    CancelledWithdrawals, // 3
    GamingTime, // 4 
    // HigherNetloss, // 5 - 7
    NetLossPerHour, // 5
    NetLossThirtyDays, // 6
    NetLossTwelveMonths, // 7
    InitiatedCooloffs, // 8
    IncreasedNetloss, // 9
    Other2 = 299,
    KnowYourCustomer = 300,
    UploadDocument,
    UploadUtility,
    VerifiedDocument,
    VerifiedUtility,
    VerifiedEmail,
    ChangedEmail,
    VerifiedAddress,
    ChangedPhoneNumber, // 308
    ChangedBankingDetails, // 309
    SamePlayer, // 310
    ChangedNationality, // 311
    ChangedTestAccount, // 312
    ManualUploadDocument,// 313
    ManualUploadUtility,// 314
    VerifiedMobile, // 315
    ChangedUsername, // 316
    TemporaryAccount = 320,
    PlayerActivity = 330,
    MoneyStuff = 340,
    LargeDeposit,
    LargeWithdrawal,
    MaximumBalance,
    ManualWithdrawal,
    ManualDeposit,
    AccountStatusChange = 350,

    AccountClosed = 360,
    AccountClosedProvisional,
    AccountClosedActive,
    CrifPep = 369,
    CRIFCheckMismatch = 370,
    CRIFCheckLogin = 371,
    CRIFCheckRegistration = 372,
    CRIFAddressChangedPlayer = 373,
    CRIFAddressChangedOperator = 374,
    PEPCheckLogin = 375,
    PEPCheckRegistration = 376,
    PEPCheckNameChanged = 377,
    PEPCheckBeneficiaryName = 378,
    PEPChangedByOperator = 379,
    Bally = 380,
    BallyRegistrationSuccess,
    BallyRegistrationFailed,
    Other3 = 399,
    Gamification = 400,
    NetLoss24hours,
    Bonuses = 410,
    BonusAdd,
    BonusActivate,
    BonusCancel,
    BonusCancelAll,
    BonusAddManual,
    BonusAddManualBonusCode,
    BonusActivateManual,
    BonusCancelManual,
    BonusCancelAllManual,
    Other4 = 499,
}

export interface ISession {
    entryType: TimelineEntryType,
    entrySubType: TimelineEntrySubType,
    sessionId: string
    created: string
    lastActivity: string
    closed: string
    deposited: number
    withdrawn: number
    wagered: number
    won: number
    total: number,
    currency: string
}
/*
export interface ITransaction {
    entryType: TimelineEntryType,
    entrySubType: TimelineEntrySubType,
    transactionId: number
    // walletId: number
    sessionId: string
    type: string
    amount: number
    currency: string
    created: string
    info: any
}
*/
export interface ITransactionWithinSession {
    amount: number
    created: string
    currency: string
    type: string
    info: any
}

// transctioni v timelineu in recent events imajo ta interface
export interface ITransaction {
    entryType: TimelineEntryType,
    entrySubType: TimelineEntrySubType,
    playerId?: string
    transactionId: number
    type: string
    amount: number
    currency: string
    status: TransactionStatus
    created: string
    completed?: string
    balanceBefore: number
    balanceAfter: number
    info: any
    username?: string
}

export interface Latest {
    amount?: number
    created?: string|number
    registered?: string|number
    currency?: string
    info?: any
    transactionId?: string
    type?: string
    username?: string
    nickname?: string
    playerId?: string
    comment?: string
    history?: IIssueHistory[]
    issueType?: IssueTypes
    resolved?: boolean
    riskLevel?: RiskLevel
    riskManagerId?: number
    expiryDays?: number
    expiredOn?: string|number
    expiresOn?: string|number
    assignTo?: number[]
    id?: number
    entryType?: TimelineEntryType
    entrySubType?: TimelineEntrySubType
}

export enum AccountStatus {
    Initial,
    Provisional,
    Active,
    ActiveFullyIdentified,
    BlockedFull,
    BlockedPartial,
    ClosedProvisional,
    Inactive,
    ClosedActive,
    PaymentsBlocked,
    PendingActive
}

export enum ForceAccountStatus {
    DoNotForce,
    BlockedFull,
    BlockedPartial,
    Closed,
    Inactive,
}

export interface RaffleResultsData {
    id: number
    nickname: string
    player_id: string
}

export interface RaffleParticipantsData {
    id: number
    tickets: number
    nickname: string
    player_id: string
}

export interface RaffleParticipants {
    id: number,
    data: RaffleParticipantsData
}

export interface RaffleData {
    completed?: string
    timestamp?: string|null
    mainWinners?: RaffleResultsData[]
    secondaryWinners?: RaffleResultsData[]
    participants?: RaffleParticipantsData[]
    tickets?: number
    executedBy?: number
    executionTimestamp?: string|null
    mainWinnerNickname?: string|null
}

export interface Raffle {
    id?: number,
    data?: RaffleData
}

// 'Days'|'Hours'|'Minutes'|''N/A''
export enum Claimable {
    NotApplicable, Days, Hours, Minutes,
}

export enum ActivatedNotInstantly {NotApplicable, LoginAfter}
export enum MultiActivityTrigger {NotApplicable, ConsecutiveLogin, ConsecutiveWager}

export enum TriggerSubtype {
    Custom = 0,
    ManualBonus = 100,
    Registration = 200,
    DepositMatchBonus = 300,
    KnowYourCustomer = 400, EmailVerification = 401, SmsVerification = 402, AccountVerification = 403, DocumentUpload = 404, UtilityUpload = 405, BankInfoUpload = 406, Completed = 407,
    AmountWagered = 500,
    BonusCode = 600,
    RewardShop = 700,
    MultiActivityTriggers = 800,
}

export enum TriggerType {
    Custom = 0,
    ManualBonus,
    Registration,
    DepositMatchBonus,
    KnowYourCustomer,
    AmountWagered,
    BonusCode,
    RewardShop,
    MultiActivityTriggers,
}

export interface GameProviderData {
    name?: string
    gameLaunchEndpoint?: string
    gameIntegrationEndpoint?: string
    active?: boolean
    rgsGameSuppliers?: number[]
    rgsGameSupplierDefault?: number
    categories?: string[]
}

export interface GameProvider {
    id?: number,
    data?: GameProviderData
}

export interface GameProviderNew {
    // id?: number
    providerId?: number,
    active?: boolean
    // gameIntegrationEndpoint?: string
    // gameLaunchEndpoint?: string
    name?: string
    // rgsGameSuppliers?: number[]
    // rgsGameSupplierDefault?: number
    // categories?: string[]
}
/*
export interface RgsGameSupplierData {
    name?: string
    // active?: boolean
}

export interface RgsGameSupplier {
    id?: number,
    data?: RgsGameSupplierData
}
*/
/*
export interface RgsGameSupplierNew {
    id?: number,
    name?: string
}
*/
/*
export interface BonusCampaignData {
    name: string
    bonus: number[]
    active: boolean
    dateStart?: string
    dateEnd?: string
    DateStart?: moment.Moment
    DateEnd?: moment.Moment
    timeStart?: string
    timeEnd?: string
    combinable?: boolean    
}
*/
// WageringRequirementType.ByContribution
export enum WageringRequirementType {ByWageringRequirement = 1, ByContribution}

export interface BonusDataWageringRequirement {
    category: number
    // wrx: number
    contribution: number
}

/*
export interface BonusData {
    // first line
    triggerType?: TriggerType
    name?: string
    accountStatus?: AccountStatus
    max?: boolean
    description?: string

    individualBonusCode?: boolean
    // expandSelect?: boolean

    gameids: number[]

    level?: Level
    active?: boolean
    category?: number
    validityFromRegistration?: boolean
    validityFromRegistrationValue?: number
    validityFromRegistrationUnit?: 'Year'|'Quarter'|'Month'|'Week'|'Day'|'Hour'|'Minute'|'Second'

    multipleBonusCodesPerAccount?: boolean
    // second line
    triggerSubtype?: TriggerSubtype
    code?: string[]

    amount?: number
    percentage?: number
    currency?: string
    amountWagered?: number
    firstDeposit?: boolean

    // OLD
    wageringRequirementType: WageringRequirementType
    baseWageringRequirement?: number
    wrc?: BonusDataWageringRequirement[]
    // NEW
    // bonusWageringRequirementId?: number

    // subcategory?: number
    // multipleBonusCodesAmount?: number
    // priority?: number
    // coexist?: boolean
    // activatedInstantly?: boolean
    // activatedNotInstantly?: ActivatedNotInstantly
    // activatedNotInstantlyTime?: Claimable
    // activatedNotInstantlyTimeValue?: number

    // multiActivityTrigger?: MultiActivityTrigger
    // multiActivityTriggerTime?: Claimable
    // multiActivityTriggerTimeValue?: number
    // third line
    // claimable?: boolean
    // claimableTime?: Claimable
    // claimableTimeValue?: number
    // GAMES

}
*/

export interface BonusDataWageringRequirementNew {
    category: GameCategories
    contribution: number // percent
}

export interface BonusWageringRequirementData {
    baseWageringRequirement?: number
    wageringRequirementsByContribution: BonusDataWageringRequirementNew[]
    name: string
    default: boolean
}

export interface IBonusWageringRequirement {
    id?: number
    data?: Partial<BonusWageringRequirementData>
}
/*
export interface BonusCampaign {
    id?: number
    data?: Partial<BonusCampaignData>
}
*/
/*
export interface Bonus {
    id?: number,
    data?: BonusData
}
*/

export interface BonusCampaignNew {
    id?: number,
    campaignName?: string,
    startDate?: string,
    endDate?: string,
    priority?: number,
    combinable?: boolean,
    bonusIds?: number[]
    dateStart?: moment.Moment
    dateEnd?: moment.Moment
    timeStart?: string
    timeEnd?: string
    eventId?: string
}

export interface BonusRules {
    rule: string,
    operator: string,
    value: number | string
}


export interface CategoryContribution {
    categoryId: number,
    coefficient: number
}

export interface PlayerBonus {
    bonusId: number,
    campaignId: number,
    bonusInstanceId: number,
    bonusName : string,
    bonusText : string,
    state: string,
    triggerType: TriggerType,
    bonusCode: string,
    optIn?: boolean,
    tcId?: string
}

export interface BonusNew {
    id?: number,
    bonusName?: string,
    bonusId?: number,
    bonusType?: string,
    defaultCurrency?: string,
    supportedCurrencies?: string[],
    maxGrantedAmount?: number,
    maxGrantedCount?: number,
    triggerType?: string,
    wageringType?: string,
    wageringRequirement?: number,
    optIn?: boolean,
    repeat?: number,
    bonusRules?: BonusRules[],
    bonusGameInclude?: number[],
    bonusGameExclude?: number[],
    gameCategoryContribution?: CategoryContribution[],
    bonusCodes?: string[],
    showTc?: boolean,
    tcId?: string,
    allPlayersEligible?: boolean,
    freeGames?: BonusFreeGame[],
    eventId?: string,
    senderId?: string,
    timeFromEventValue?: number,
    timeFromEventUnit?: string,
    timeToConsumeValue?: number,
    timeToConsumeUnit?: string,
    reusableCodes?: boolean,
    priority?: number,
    active?: boolean,
    timeToConsumeFreeGamesUnit?: string,
    timeToConsumeFreeGamesValue?: number,
    excludedTags?: string[],
    includedTags?: string[],
    dependentBonusIds?: number[],
    timeToActivateUnit?: string,
    timeToActivateValue?: number
    freeGamesAllowedGames?: number[],
}

export interface BonusFreeGame {
    gameId?: number,
    coinValueLevel?: number,
    betLevel?: number,
    freeGameAmount?: number,
    valueAmount?: number
}

export interface FreeGame {
    id?: number,
    gameId?: number,
    coinValueLevel?: number,
    betLevel?: number,
    freeGameName?: string,
    description?: string,
    freeGameAmount?: number
    providerId?: number
    selected?: boolean
    eventId?: string
    valueAmount?: number
}

interface LoggedPlayers {
    list: {
        created: string,
        username: string,
        playerId: string
    }[],
    pagination: {
        page: number,
        size: number,
        total: number
    }
}

interface PepPlayers {
    created: string,
    username: string,
    playerId: string
}
/*
export enum VerificationStatus {
    Unverified,
    Verified,
    SuperVerified
}
*/
export interface ILimits {
    type: LimitTypesNew
    limits: IPeriodLimit[]
}
    
export interface IPeriodLimit {
    period: LimitPeriods
    limit: number
    newLimit?: number
    changeDate?: string
}

export enum LimitTypesNew {
    NetLoss = 1,
    Wager,
    Deposit,
    Win,
}

export enum LimitPeriods {
    Hourly = 0,
    Daily,
    Weekly,
    Monthly,
    Quartely,
    Annual,
    Total,
}

export interface Region {name: string, code: string, id: number, population: number, Country?: string, country?: string, c?: string}

export interface CountryData {name: string, dial_code: string, code: string, region: string, regions: Region[]}
export interface Country {id: number, data: CountryData}

export interface NationalityData {nationality: string, code2: string, code3: string}
export interface Nationality {id: number, data: NationalityData}
/*
interface JurisdictionData {tier: number, name: string, code: string, active: boolean}
export interface Jurisdiction {id: number, data: JurisdictionData}
*/
export interface Jurisdiction {id?: number, tier: number, name: string, code: string, active: boolean, authority?: string}
// interface SubCategoryData {id: number, name: string, active: boolean}
interface CategoryData {name: string, active: boolean/*, subs?: SubCategoryData[]*/}
export interface Category {id: number, data: CategoryData}
// interface Resolve {riskLevel: RiskLevel, necessary: boolean}

export enum ThousandsSeparator {Apostrophe, Comma, Space}
/*
export interface TempData {
    name: string
}

export interface Temp {
    id: number
    data: Partial<TempData>
}
*/
export enum PaymentProcessedStatus {Generated, Pending, Completed, Inquiry}

export interface PaymentProcessedData {
    timestampGenerated: string
    timestampPending: string
    timestampCompleted: string
    totalAmount: number
    totalPayments: number
    status: PaymentProcessedStatus
    operatorId: number
    date: string
    currency: string
    xmlPaymentFile: string
    fileInDate: number
    transactionIds: number[]
}

export interface PaymentProcessed {
    id: number
    data: Partial<PaymentProcessedData>
}

// export enum WithdrawalRequestStatus {Pending, Approved, Denied, Executed, Completed, DeniedByBank, Cancelled}
export interface FraudAlertType {
    shortCode: 'A'|'B'|'C'|'D'|'E'|'F'|'G'|'H'|'I'|'J'|'K'|'L'|'M'|'N'|'O'|'P'|'Q'|'R'|'S'|'T'|'U'|'V'|'W'|'X'|'Y'|'Z',
    type: 'Deposit'|'Withdrawal',
    implemented: boolean,
    description: string,
    issueType: IssueTypes,
    riskLevel: RiskLevel
}
/*
export interface FraudAlert {
    type: FraudAlertType
}

export interface WithdrawalRequestData extends WithdrawalRequestPaymentFileData {
    markedForPayment: boolean
}
*/
export enum TransactionType {
    DEPOSIT = 1,
    WITHDRAW = 2,
    WAGER = 3,
    WIN = 4,
    DEPOSIT_CANCEL = 5,
    WITHDRAW_CANCEL = 6,
    WAGER_CANCEL = 7,
    WIN_CANCEL = 8,
    DEPOSIT_MANUAL = 9,
    WITHDRAW_MANUAL = 10,
    BONUS_ADD = 11,
    BONUS_REDEEM = 12,
    BONUS_CANCEL = 13,
}

export interface WithdrawalRequestPaymentFileData {
    transactionId: number
    amount: number
    currency: string
    changeStatus: boolean
    status: TransactionStatus // WithdrawalRequestStatus
/*
    timestampRequested: string
    timestampApprovedDenied: string
    timestampExecutedCancelled: string
    timestampCompletedDeniedByBank: string
*/
    playerId: string
    // operatorId: number

    beneficiaryName: string
    firstName: string
    lastName: string
    username: string
    address: string
    postalCode: string
    city: string
    countryAbbreviation: string
    streetNumber: string
    iban: string
    bic: string
    level: Level
    riskLevel: RiskLevel
    accountStatus: AccountStatus
    // fraudAlerts: FraudAlert[]
}

export interface ICommunicationUpdate {
    allowEmail: boolean
    allowSms: boolean
    allowTelephone: boolean
    allowPost: boolean
    language: string
}

export interface WithdrawalRequestPaymentFile {
    id?: number
    data: Partial<WithdrawalRequestPaymentFileData[]>
}

export interface WithdrawalRequest extends WithdrawalRequestPaymentFile {
    transactionId: number
    amount: number
    currency: string
    status: TransactionStatus
    playerId: string
    created: number
    beneficiaryName: string
    firstName: string
    lastName: string
    username: string
    address: string
    postalCode: string
    city: string
    countryAbbreviation: string
    streetNumber: string
    iban: string
    bic: string
    level: Level
    riskLevel: RiskLevel
    accountStatus: AccountStatus
    // FRONTEND ONLY
    changeStatus: boolean // this is for frontend only
    markedForPayment?: boolean // this is for frontend only
}

export interface Custom {
    API?: {
        iban?: string
    },
    paymentInformation?:{
        iban: string,
        bic: string,
        beneficiary: string,
        address: string,
        postalCode: string,
        city: string,
        abbreviation: string,
        countryAbbreviation: string,
        streetNumber: string,
        country: string
    },

    KYC?: {
        crif: {
            checkAtRegistration: boolean,
            checkAtLogin: boolean,
            canPlayerChangeAddress: boolean,
            checkOperatorChangeAddress: boolean,
            isEquivalentToUtilityBillUpload: boolean,
            provider: string,
            product: string,
            interfaceVersion: string,
            supportEmail: string,
            telephoneSwitzerland: string,
            telephoneInternational: string,
            documentation: string
        },
        
        pep: {
            checkAtRegistration: boolean,
            checkAtLogin: boolean
            checkAtNameChanged: true,
            checkBeneficiaryName: false,
            doubleCheckOnPepMatch: boolean,
            markThePlayerManually: boolean,
            provider: string,
            product: string,
            supportEmail: string,
            telephoneSwitzerland: string,
            telephoneInternational: string,
            documentation: string
        }

        identification: Array<{
            key: string
            label: string
            active: boolean
            documentsFormat: Array<'pdf'|'jpg'|'png'|'gif'>
            maxSizeMB: number,
        }>,
        proofOfAddress: Array<{
            key: string
            label: string
            active: boolean
            documentsFormat: Array<'pdf'|'jpg'|'png'|'gif'>
            maxSizeMB: number,
        }>,
        proofOfPayment: Array<{
            key: string
            label: string
            active: boolean
            documentsFormat: Array<'pdf'|'jpg'|'png'|'gif'>
            maxSizeMB: number,
        }>,
    },
    notify?: {
        riskLevelDefaults?: boolean[],        
    },
    
    playerTags?: string[],
    
    riskManagement?: {
        showOnlyElevatedRisk?: boolean,
        fraudAlerts: FraudAlertType[]
    }
    
    responsibleGaming?: {
        issues?: {
            active?: boolean,
            CSEmail?: string,
            riskLevel?: RiskLevel,
        },
        dashboardBackend: {
            checkNightJob: boolean,
        },
        selfAssessment: {
            waitPeriod?: number
            waitPeriodUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
            depositRequirementWithin?: number,
            depositRequirementWithinUnit?: 'N/A'|'min'|'hour'|'day'|'month'|'year',
            actionTaken?: 'N/A'|'sms'|'e-mail'|'app',
            answersScale?: {
                min: number
                max: number,
            },
        },
    },
    games?: {
        // labels?: string[]
        devices?: string[],
    },
    general?: {
        durationPasswordResetEmailSeconds?: number
        cooloffOptionCategories?: CooloffOptionCategory[]
        cooloffGameCategories?: CooloffGameCategory[]
        format? : {
            thousandsSeparator: ThousandsSeparator,
        }
        players?: {
            username: {
                shouldNotHaveSpecialCharacters?: boolean,
                shouldNotStartWithANumber?: boolean,
                forbidden?: string[]
            }
            accountStatus: Array<{name: string, specialAccountStatus: boolean, requirements?: {isEmailVerified?: true, isUtilityBillVerified?: true, isDocumentVerified?: true, isSuperVerified?: true}, allow?: {login?: true, play?: true, deposit?: true, withdraw?: true, temporary?: true}}>
            playerCard: Array<{name: string, flags?: {modifyUser?: true, viewUser?: true, sentUser?: true, modifyAdmin?: true, viewAdmin?: true}}>
            legalAge: number

            isCountriesAllowed: boolean
            countriesAllowed: string[]
            countriesDisallowed: string[]

            isNationalitiesAllowed: boolean
            nationalitiesAllowed: string[]
            nationalitiesDisallowed: string[]
            jurisdictionsAllowed: string[]
            jurisdictionsTiersAllowed: number[]
            // emailVerificationMandatory: boolean
            phoneNumberMandatory: boolean
            rglMandatory: boolean
            accountExpiryLimits: number[]
            getExpiredAccounts: boolean,
            getAllExpiringAccounts: boolean,
        }
        currencies?: string[]
        defaultLocale?: string
        defaultCurrency?: string
        timeUnits?: {
            cooloffs?: {second: boolean, min: boolean, hour: boolean, day: boolean, week: boolean, month: boolean, quarter: boolean, year: boolean},
        }
        timePeriods?: {
            limits?: {hourly: boolean, daily: boolean, weekly: boolean, monthly: boolean, quarterly: boolean, annual: boolean, total: boolean},
        }
        limitTypes?:{netLoss: boolean, wager: boolean, deposit: boolean, win: boolean}
        communications?: string[]
        strings?: {blackListCheck?: string}
        languages?: Array<{locale: string, name: string, short: string}>
        descriptions?: {
            riskManagement: {
                temporaryAccountStatus: string,
                noPlayerActivity: string,
                largeValueDeposit: string,
                largeValueWithdrawal: string,
                blacklistCheck: string,
                maximumAmount: string,
                internalPlayerRisk: string,
                elevatedRisk: string,
            },
            responsibleGaming: {
                limitNetLoss: string,
                limitNetLossRegistration: string,
                temporaryExclude: string,
                dashboardBackend: string,
                selfAssessment: string,
                selfExclusion: string,
                csCallbackButton: string,
            },
            settings: {
                notify: string,
            },
        },
    }
}
/*
interface IQuestion {
    points: number
    question: string
    questionId: number
    ratingPoints: number
}

interface Questionnaire {
    date: string
    riskLevel: RiskLevel
    totalPoints: number
    questions: IQuestion[]
}
*/
// playerId

interface UnverifiedAccounts {
    registered: string,
    username: string,
    playerId: string
}

interface UnverifiedAccountAll {
    registered: string,
    username: string,
    playerId: string,
    expiresOn: string|number,
    expiryDays: number
}

interface ExpiredAccount {
    registered: string,
    username: string,
    playerId: string,
    expiredOn: string|number,
    // expiryDays: number
}

interface UnverifiedAccount {
    name: string
    accounts: UnverifiedAccounts[]
}
/*
interface UnverifiedAccountAll {
    name: string
    accounts: UnverifiedAccountsAll[]
}
*/
/*
interface ExpiredAccount {
    name: string
    accounts: ExpiredAccounts[]
}
*/
interface Currency {
    id: number
    data: {code: string, name: string, symbol: string, rounding: number, decimal: number}   
}

interface Language {
    id: number
    data: {code: string, name: string}   
}

interface CounselingCentreData {
    code: string, name: string, address: string, phone: string, plz: string, city: string, description: string
}

export interface CounselingCentre {
    id: number
    data: CounselingCentreData
}
/*
interface CategoryData {
    name: string, active: boolean
}

export interface Category2 {
    id: number
    data: CategoryData
}
*/
export interface IReports {
    date?: number
    // date_: string
    totalBetRealRNG: number
    totalWinRealRNG: number
    totalBetRealLive: number
    totalWinRealLive: number
    WANJackpotIncrements: number
    jackpotHitsRng: number
    totalBetBonus: number
    totalWinBonus: number
    convertedBonus: number
    totalGGR: number
    depositsPSP: number
    manualDeposits: number
    withdrawsProcessed: number
    withdrawsPending: number
    manualAdjustments: number
    realStartingBalance: number
    realEndingBalance: number
    realMovement: number
    totalGGR2: number
    difference: number
    bonusAwarded: number
    bonusStartingBalance: number
    bonusEndingBalance: number
    canceledBonus: number
}
/*
export interface GamesCentralData {
    name: string
    labels?: string[]
    active: string
    url: string
    jurisdiction: {name: string, approvable: boolean}[]
    // provider?: string
    providerId?: string
    category?: string
    // subcategory?: string
    isJackpot?: string
    isProgressiveJackpot?: string
    isFreeGames?: string
    isWildSymbol?: string
    isScatterSymbol?: string
    rtp?: number
    reels?: number
    lines?: number
    rows?: number
    symbols?: number
    devices?: string[]
    gameLaunchId?: string
    providerCategory?: string
    descriptions?: {
        short: any
        long: any
    }
    rgsGameSupplier?: number
}
*/

export interface GamesList {
    gameId: number
    demo: boolean
    gameName: string
    providerId?: number
    categoryId?: number
    labels: number[]
    devices: number[]
    active: boolean
    betMin: number
    betMax: number
    tested: boolean
    providerData: {
        gameLaunchId?: string
        gameLaunchName?: string
        category?: string           // evolution
        tableIdMobile?: string
        tableIdDesktop?: string
        tableIdVirtual?: string
        moduleId?: number           // microgaming
        mobileId?: string
        desktopId?: string
        clientMobileId?: number
        clientDesktopId?: number
        tableGameId?: string        // netent
        mobileGameId?: string
    }
}

export interface FileExportGames extends GamesList {
    Exists?: boolean
    Name?: string
    sameName?: boolean
    UpdatedAt?: string
    CreatedAt?: string
    Tags?: Tag[]
    Category?: number
}

export interface GamesListLabel {
    labelId: number
    name: string
}

export interface GamesListCategory {
    categoryId: number
    name: string
}

export interface Device {
    deviceId: number
    device: string
}



export interface GamesCentralNew {
    id?: number
    gameId?: number
    demo: boolean
    category?: string
    descriptions?: {short: any, long: any,}
    gameLaunchId?: string
    isFreeGames?: boolean
    isJackpot?: boolean
    isProgressiveJackpot?: boolean
    isWildSymbol?: boolean
    isScatterSymbol?: boolean
    jurisdiction: Array<{name: string, approvable: boolean}>
    labels?: string[]
    lines?: number
    name?: string
    providerId?: string
    providerCategory?: string
    reels?: number
    rows?: number
    rtp?: number
    symbols?: number
    url?: string
    rgsGameSupplier?: number
    devices?: string[]
    // active?: boolean
    desktopBackgroundFilename?: string
    desktopThumbnailFilename?: string
    mobileBackgroundFilename?: string
    mobileThumbnailFilename?: string

    // IN CONJUNCTION WITH CLIENT GAMES DATABASE
    Existing?: boolean // this is used only in conjuntion with client games database
    Approved?: boolean // this is used only in conjuntion with client games database
    Active?: boolean // this is used only in conjuntion with client games database
    ID?: number // this is used only in conjuntion with client games database
    Category?: string // this is used only in conjuntion with client games database
    CooloffCategory?: CooloffGameCategory // this is used only in conjuntion with client games database
    TestGame?: boolean
    // STRAPI
    Provider?: string // STRAPI
    Exists?: boolean // STRAPI
    Exists2?: boolean // STRAPI
    Name?: string // STRAPI
    sameName?: boolean// STRAPI
    ShortDescription?: Translation // STRAPI
    LongDescription?: Translation // STRAPI
    UpdatedAt?: string // STRAPI
    CreatedAt?: string // STRAPI
    Tags?: Tag[] // STRAPI
    // Category?: CCategory // STRAPI
}
/*
export interface GamesCentral {
    id: number
    data: GamesCentralData
}
*/
export interface GamesData {
    id: number
    active: boolean
    approved?: boolean
    approvedJurisdiction?: string[]
    cooloffCategory: CooloffGameCategory
    testGame: boolean
}

export interface Games {
    id: number
    data: GamesData
}

export interface Translation {
    id: number
    name: string
    type: 'label' | 'sentence' | 'paragraph'
/*
    content_en_US: string
    content_de_CH: string
    content_fr_CH: string
    content_it_CH: string
*/
}

export interface Tag {created_at?: string, id?: number, name: string, updated_at?: string}
export interface CCategory {created_at: string, id: number, name: string, updated_at: string, categoryId: number}

export interface Casinogame {
    gameId: number
    name: string
    provider: string
    id?: number
    imageDesktop?: {
        id: number
        name: string
        url: string,
    }
    URL?: any
    shortDescription?: Translation
    longDescription?: Translation
    updated_at?: string
    created_at?: string
    tags?: Tag[]
    category?: CCategory

}

export interface PostalCodesData {
    plz: string, name: string, region: string, country: string
}

export interface PostalCodes {
    id: number
    data: PostalCodesData
}

export interface ShopData {
    name: string
    price: number
    currency: string
    discount: number
    level: Level
    image: string
    category: number
    active: boolean
    Currency: string
}

export interface Shop {
    id?: number
    data?: Partial<ShopData>
}

export interface GameLabel {
    id: number
    name: string
}

export interface GameCategory {
    id: number
    name: string
}

export interface SCategory {
    casinogames?: Casinogame[]
    created_at?: string
    id?: number
    name: string
    updated_at?: string
    categoryId: number
}
export interface Filtered {
    id: string
    value: string
}

export interface IIssueInsertAdmin {
    playerId: string
    issueType: IssueTypes
    riskLevel: RiskLevel
    comment?: string
    resolved?: boolean
    riskManagerId?: number
    data?: Partial<IssueData>
    assignTo?: number[]
}

export interface IPlayerGroup {
    id: number
    data: IPlayerGroupRule
}

export interface IPlayers {
    id: number
    data: IPlayersInfo[]
}

interface WithdrawalHistoryTimes {
    utc?: string
    date?: string
    time?: string
}

export interface WithdrawalHistory {
    transactionId: number
    playerId: string
    amount: number
    times: WithdrawalHistoryTimes[]
}

export interface BonusCampaignReports {
    bonusCode: string,
    bonusId: number,
    campaignId: number,
    created: string,
    playerId: string
}

export interface IAccountInfo {
    playerId: string
    testAccount: boolean
    // kyc.userdata
    firstName: string
    lastName: string
    birthday: string
    nationality: string
    gender: string
    phoneNumber: string
    // kyc.addresses
    address: string
    streetNumber: string
    city: string
    postalCode: string
    // users.logindata
    username: string
    email: string
    isEmailVerified: boolean
    isMobileVerified: boolean
    accountStatus: AccountStatus
    registered: number
    // users.communication
    allowEmail: boolean
    allowSms: boolean
    allowTelephone: boolean
    allowPost: boolean
    language: string
}

export enum Environment {
    DEV, DEVEDGE, STAGE, PRODUCTION
}

export interface TournamentResult {
    highestWager: number
    position: number
    reward1: number
    roundsPlayed: number
    roundsWon: number
    sumOfBets: number
    username: string
    highestWinRatio: number
}

export interface ICashbackMondayOptins {
    playerId: string
    nationality: string
    optinDate: string
    level: number
}



export interface IPlayerInfoFiltered {
    playerId: string
    username: string
    stats?: IPlayerInfoStats
    level: number
    sublevel: number
    lastPlayed: string | any
    currency: string
    nationality: string
    }

export interface IPlayerInfoStats {
    betsCount: number
    totalBet: number
    winsCount: number
    totalWon: number
    spins: number
    withdrawals: number
    deposits: number
}

export interface IStorePurchases {
    playerId?: string
    username?: string
    storeId?: number
    rewardId: number
    dateReceived: string | any
    name: string
}

export interface IUserInfo {
    playerId: string
    username: string
    betsCount: number
    totalBet: number
    winsCount: number
    totalWon: number
    spins: number
    withdrawals: number
    deposits: number
    nationality: string
    currency: string
    lastPlayed: string | any
    consecutiveDays: number
    betsToday: number
    averageBetSize: number
    tokens: ITokens
    boosters: []
    challengeStates: []
    activeMissions: []
    tasksCompleted: {}
    missionsState: IMissionState[]
    levels: ILevels
    tournamentsInfo: ITournamentsInfo[]
    rewards: IRewardsInfo[]
}

export interface ITokens {
    availableTokens: number
    totalTokens: number
}

export interface ITournamentsInfo {
    roundsWon: number
    sumOfBets: number
    roundsLeft: number
    roundsPlayed: number
    tournamentId: number
    tournamentDate: string
}

export interface IRewardsInfo {
    taskId: number
    category: number
    rewardId: number
    missionId: number
    dateReceived: string
    rewardStatus: number
    rewardType: string
    spinsAmount: number
    expirationDate: string | any
}

export interface IProvisionalOrActive {
    playerId?: string
    wasProvisional?: boolean
    wasActive?: boolean
}

    
export interface IUploadedDeposits {
    playerId: string
    username: string
    amount?: number
    comment?: string
    bypassRisk?: boolean
}

export class DatabaseDataState extends Logic.Type {
    public entities: boolean[] = []
    public search: string[] = ['','','','','']
    public cp: any[]
    public cps: any[]
    public users: any[]
    public updatePaymentData: boolean = false
    public globallyExcludedTags: string[] = []
    public globallyExcludedTagsGet: boolean = false
    public globallyExcludedTagsSet: boolean = false
    public processButtonDisabled: boolean = false
    public bonusNew: BonusNew[]
    public bonusNewGet: boolean = false
    public bonusNewUpdate: boolean = false
    public bonusNewDelete: boolean = false
    public bonusActivate: boolean = false
    public freeGameSettings: FreeGame[]
    public freeGameSettingsGet: boolean = false
    public freeGameSettingsUpdate: boolean = false
    public freeGameSettingsDelete: boolean = false
    public bonusCampaignNew: BonusCampaignNew[]
    public bonusCampaignNewGet: boolean = false
    public bonusCampaignNewUpdate: boolean = false
    public bonusCampaignNewDelete: boolean = false
    public statusProvisionalOrActive: IProvisionalOrActive
    public issueGetStatusProvisionalOrActive: boolean = false
    public isInsertingMassManualUploads: boolean = false
    public uploadedMassManualDeposits: IUploadedDeposits[] = []
    public insertedMassManualDeposits: IUploadedDeposits[] = []
    public countMassManualDeposits: number = 0
    public strapiConnected: boolean 
    public userInfoGet: boolean = false
    public userInfo: IUserInfo
    public userIdSelected: boolean = false
    public storePurchasesSelectedPlayerUsername: string
    public usersInfoFiltered: IPlayerInfoFiltered[]
    public usersInfoFilteredGet: boolean = false
    public usersInfoCount: number = 98
    public storePurchases: IStorePurchases[]
    public storePurchasesGet: boolean = false
    public storePurchasesCount: number
    public CashbackMondayOptins: ICashbackMondayOptins[]
    public wrongId: boolean = false
    public usersNew: UsersNew[]
    public isChangingPlayerLevel: boolean = false
    public rewardShopCount: number = 0
    public tournamentResult: TournamentResult[]
    public TournamentLeaderboardGet: boolean = false
    public missionLeaderboard: IMissionLeaderboard[]
    // public missionLeaderboardPage: number = 1
    // public missionLeaderboardLimit: number = 10
    public missionLeaderboardCount: number
    public missionLeaderboardGet: boolean = false
    public missionLeaderboardUpdate: boolean = false

    public strapiAuth: string
    public _providers_: number[]
    public _categories_: number[]
    public _categories: string[]
    public Categories: any[]
    public rewardsCMS: IRewardsCRM[]
    public Games: Game[]
    public levelsNewCount: number
    
    public levelsNew: ILevelDefinition[]
    public levelsNewGet: boolean = false
    public levelsNewGetAll: boolean = false
    public levelsNewInsert: boolean = false
    public levelsNewUpdate: boolean = false
    public levelsNewDelete: boolean = false
    
    public missionsNew: IMissionDefinition2[]
    public missionsNew_: IMissionDefinition2[]
    public missionsNewGet: boolean = false
    public missionsNewGetAll: boolean = false
    public missionsNewInsert: boolean = false
    public missionsNewUpdate: boolean = false
    public missionsNewDelete: boolean = false
    public missionsNewCount: number
    public missionIdDuplicate: number = -1

    public storesNewGet: boolean = false
    public storesNewGetAll: boolean = false
    public storesNewInsert: boolean = false
    public storesNewUpdate: boolean = false
    public storesNewDelete: boolean = false

    public challengesNewGet: boolean = false
    public challengesNewGetAll: boolean = false
    public challengesNewInsert: boolean = false
    public challengesNewUpdate: boolean = false
    public challengesNewDelete: boolean = false

    public rewardsNewGet: boolean = false
    public rewardsNewGetAll: boolean = false
    public rewardsNewInsert: boolean = false
    public rewardsNewUpdate: boolean = false
    public rewardsNewDelete: boolean = false

    public tournamentsNewGet: boolean = false
    public tournamentsNewGetAll: boolean = false
    public tournamentsNewInsert: boolean = false
    public tournamentsNewUpdate: boolean = false
    public tournamentsNewDelete: boolean = false

    public type: number
    public everythingLoadedFirstCalled: number
    public everythingLoadedFirstCalledPanicSeconds: number = 10
    public issueType: number
    public optionsTopPlayerId: string[] = []
    public optionsBottomPlayerId: string[] = []
    // public issuesCount: any
    public pdfImage: boolean = undefined
    public showGuideHowToUploadFiles: boolean = false
    public ShowGuideHowToUploadFiles: boolean = false
    public showRoundId: boolean = false
    public showGsidEgsid: boolean = false
    // public isGettingIssues: boolean
    public whichIssuesToGet: TimelineEntrySubType = TimelineEntrySubType.None
    public bonusSelectGamesGameProvider: number = -1
    public bonusSelectGames: boolean = false
    public allAccountsStarted: boolean = false
    public allAccountsPage: number = 1
    public allAccountsLimit: number = 20
    public allAccountsData: IAccountInfo[]

    public dashboardBackendOptionsDates: string[]
    public dashboardBackendOptionsDatesFrom: number = -1
    public dashboardBackendOptionsDatesTo: number = -1
    public loadedPlayerCard: boolean = false
    public expiringAccountsStarted: boolean = false
    public expiringAccountsPage: number = 1
    public expiringAccountsLimit: number = 20
    public expiringAccountsData: IAccountInfo[]
    public DashboardBackend: boolean = false

    public AllIssuesFilter: boolean = false
    public issuesAllIssuesFilter: IIssue[]
    public allIssuesOptionsDates: string[]
    public allIssuesOptionsDatesFrom: number = -1
    public allIssuesOptionsDatesTo: number = -1

    public ToUpdateIBonusWageringRequirement: boolean = false
    public toUpdateIBonusWageringRequirement: IBonusWageringRequirement
    public tt: TransactionType|0 = 0
    public ts: TransactionStatus_|0 = 0
    public gs: GameSessionStatus|0 = 0
    
    public d1: moment.Moment = null
    public d2: moment.Moment = null
    public t1: string = '00:00:00'
    public t2: string = '23:59:59'
    public f1: boolean = false
    public f2: boolean = false
    
    public d3: moment.Moment = null
    public d4: moment.Moment = null
    public t3: string = '00:00:00'
    public t4: string = '23:59:59'
    public f3: boolean = false
    public f4: boolean = false

    public bonusCampaignReportsPage: number = 0
    public bonusCampaignReportsLimit: number = 10
    public bonusCampaignReportsCount: number

    public BonusCampaignReportsGet: boolean = false
    public bonusCampaignReports: BonusCampaignReports[]

    public S: WithdrawalHistory[]
    public allWithdrawalHistoryCSV: boolean
    public allWithdrawalHistory: boolean = false
    public w: WithdrawalRequest[]
    public ID: number
    public s: string
    public WithdrawalHistory: boolean = false
    
    // BASE
    public numPages: number
    public pageNumber: number = 1
    // DERIVATIVES
    public numPages1: number
    public pageNumber1: number = 1
    public numPages2: number
    public pageNumber2: number = 1
/*
    public numPages3: number
    public pageNumber3: number = 1
    public numPages4: number
    public pageNumber4: number = 1
    public numPages5: number
    public pageNumber5: number = 1
    public numPages6: number
    public pageNumber6: number = 1
*/
    public wallet: boolean = false
    public Wallet: boolean = false
    public walletStatus: TransactionStatus
    public withdrawalsPage: number = 1
    // public withdrawalsLimit: number = 10
    public attachedSelected: number = -1
    public uploadSelected: number = -1
    public uploadExisting: /*UploadDocumentsFiles*/any[]
    public uploadContrast: number = 10
    public uploadBrightness: number = 10
    public uploadExpanded: boolean = false
    public uploadRotated: number = 0
    public upload?: string
    public uploadIsImage: boolean
    public uploadShowAvailableFiles: boolean = false
    public uploadShowAvailableFilesImages: boolean = true
    public uploadShowAvailableFilesPdfs: boolean = true
    public attachedUploads: string[]
    public imageWidth: number
    public containerWidth: number

    // ma tu mora bit isto kot v get-players-info PlayerDataNew crm IPlayersInfo

    public selectedUpload: string = undefined
    public selectedUploadIsExpanded: boolean = false
    public selectedUploadIsImage: boolean = true
    public selectedUploadBrightness: number = 10
    public selectedUploadContrast: number = 10
    public selectedUploadRotation: number = 0
    public IssuesCount: number = 0
    
    public issuesCount: IIssuesCount[]
    public unresolvedIssuesCount: IIssuesCount[]
    public resolvedIssuesCount: IIssuesCount[]
    public issuesCountCustom: IIssuesCount[]
    public unresolvedIssuesCountCustom: IIssuesCount[]
    public resolvedIssuesCountCustom: IIssuesCount[]
    
    public totalIssuesCount: number = 0

    // public totalUnresolvedIssuesCount: number = 0
    // public totalResolvedIssuesCount: number = 0

    public issuesPage: number = 1
    public issuesLimit: number = 10

    public issues0Page: number = 1 // dashboard backend
    public issues0Limit: number = 10 // dashboard backend
    public issues1Page: number = 1
    public issues1Limit: number = 10
    public issues2Page: number = 1
    public issues2Limit: number = 10
    public issues3Page: number = 1
    public issues3Limit: number = 10
    public issues4Page: number = 1
    public issues4Limit: number = 10

    public playerGroups: IPlayerGroup[]
    public playerGroups_: string[]
    public PlayerGroupsGet: boolean = false
    public PlayerGroupsInsert: boolean = false
    public PlayerGroupsUpdate: boolean = false
    public PlayerGroupsDelete: boolean = false

    
    // public emailValid: boolean = false
    public emailAlreadyUsed: boolean = false
    public emailNewValid: boolean = false
    public usernameAlreadyUsed: boolean = false
    public usernameNewValid: boolean = false
    // public 

    public playerCardsSize: number = 12
    public playerCardsPosition: number = 0
    public ManualCheckBlacklistGet: boolean = false
    public connection: boolean = false
    // public issuesFilterIssueType: IssueTypes
    public issuesFilterIssueTypeRM: PlayerCardButtonsRM|999 = 999
    public issuesFilterIssueTypeRG: PlayerCardButtonsRG|999 = 999
    public issuesFilterIssueTypeKYC: PlayerCardButtonsKYC|999 = 999
    public issuesFilterIssueTypeGM: PlayerCardButtonsGM|999 = 999
    public issuesFilterIssueTypeDB: PlayerCardButtonsRG|999 = 999

    public issuesShowDetails: boolean = true
    public issuesShowAssignedTo: boolean = true
    public issuesShowRiskLevel: boolean = true
    public issuesShowNames: boolean = true
    // public initialize: boolean = false

    // public withdrawalHistoryIsPending: boolean
    public withdrawalHistorySelected: number
    // public withdrawalHistorySelectedPending: string
    public withdrawalHistory: IWithdrawalEvent[]
    // public withdrawalHistoryPending: IWithdrawalEvent[]

    public TransactionHistoryGet: boolean = false
    public WithdrawalStatusChange: boolean = false
    public WithdrawalStatusChangeExecuted: boolean = false
    public CommunicationsUpdate: boolean = false
    public showUploads: boolean = false
    // public uploaded: UploadDocumentsFiles[]
    public uploadedSelectedPosition: number = -1
    public uploadedSelectedContent
    public uploadedSelectedContentType: string
    public uploadedSelectedName: string
    public checkVETO: boolean = false
    public timelinePage: number = 1
    public timelineLimit: number = 10
/*
    public levelsAll: any[]
    public tournamentsAll: any[]
    public missionsAll: any[]
    public challengesAll: any[]
    public storesAll: any[]
    public rewardsAll: any[]
*/
    // public gamanzaAdminClick: boolean = false

/*
    public wfTimestamp: string = '-1'
    public wfDateFrom: string = ''
    public wfDateTo: string = ''
    public wfTimeFrom: string = ''
    public wfTimeTo: string = ''
*/
    // public resolved: boolean = false
    public wfPlayerIdSearch1: string = ''
    public wfPlayerId1: string = '0'    
    public wfPlayerIdSearch: string = ''
    public wfPlayerId: string = '0'
    public manualPEP: boolean = false
    public manualAddress: boolean = false
    public manualCrif: boolean = false
    public manualCrifResolved: boolean = false
    public manualCrifError: boolean = false
    public manualCrifResponse: boolean = false
    public manualCrifResponseAddress: IAddressMatchResult
    public manualCrifResponsePEP: IDecisionMatrix
    public isAddressVerified: boolean
    public isPEP: boolean
    public manualResendVerification: boolean = false
    public ResendVerificationEmailInsert: boolean = false
    public resendVerificationEmail: boolean = false
    public showForbiddenUsernames: boolean = false
    public ManualWithdrawalInsert: boolean = false
    public manualWithdrawal: boolean = false
    public manualBonus: boolean = false
    public ManualDepositInsert: boolean = false
    public manualDeposit: boolean = false    
    public showJustAssignedToIssues: boolean = false
    public ShowJustAssignedToIssues: boolean = false
    public recentEventsActive: boolean = false
    public RecentEventsActive: number = 0
    public RecentEventsActiveTitle: string
    public assignee: number
    // issueTypesInsert: IssueTypesInsert = IssueTypesInsert.SelectIssueType
    public insertANewIssue: boolean = false
    public newIssue: IIssueInsertAdmin
    // issuePlayerId: string
    public openingPlayerCard: boolean = false
    public openedPlayerCard: boolean = false
    public classStatsLow: number[]
    public classStatsHigh: number[]
    public classStatsTitle: string[]
    public classStats: Array<{value: number, region: string, country: string, name: string, c: string, Country: string}>
    public orderedStats: Array<{value: number, value2: string, region: string, country: string, name: string, c: string, Country: string, Value2: number}>

    public playersInfo: IPlayersInfo[]
    // public PlayerInfoGet: boolean = false

    public toggleTags: boolean[]
    public toggleLabels: boolean[]
    // fileExportSameName: boolean// = undefined
    public fileExportCMS: boolean// = undefined
    public fileExportCategory: string// = undefined
    public viewPlayerCards: boolean[] = [false, false, false, false, false, false, false, false, false]
    // viewPlayerCards: boolean[] = [true, true, true, true, true, true, true, true, true]
    public shortList: string
    public unresolvedIssuesNumber: number
    public unresolvedIssuesPlayers: number
    public unresolvedIssuesPlayersPositions: number[]
    public RefreshButtonEnabled: boolean = false
    // refreshButtonEnabled: boolean = false
    public postalCodes: PostalCodes[]
    public postalCodes_: string[]
    public PostalCodesGet: boolean = false
    public PostalCodesUpdate: boolean = false
    public PostalCodesInsert: boolean = false
    public PostalCodesDelete: boolean = false

    public shop: Shop[]
    public shop_: string[]
    public ShopGet: boolean = false
    public ShopUpdate: boolean = false
    public ShopInsert: boolean = false
    public ShopDelete: boolean = false
    
    public withdrawalRequest1: WithdrawalRequest[]
    public withdrawalRequest2: WithdrawalRequest[]

    public duplicatesPending: PaymentProcessedDuplicates[]
    public duplicatesOther: PaymentProcessedDuplicates[]
    public markedForPayment: boolean = false

    public WithdrawalRequest1: boolean = false
    public WithdrawalRequest2: boolean = false
    public withdrawalRequest1Count: number = 0
    public withdrawalRequest2Count: number = 0
    public WithdrawalRequest1_: boolean = false
    public WithdrawalRequest2_: boolean = false
    public withdrawalRequest1Page: number = 1
    public withdrawalRequest2Page: number = 1
    public infoWithdrawals: boolean = false
    public WithdrawalRequest1Time: number = 0
    public WithdrawalRequest2Time: number = 0
    public withdrawalRequest1Time: boolean = true
    public withdrawalRequest2Time: boolean = true
    public withdrawalRequest1selectedPage: number = 0
    public withdrawalRequest1PageSize: number = 50
    public withdrawalRequest2selectedPage: number = 0
    public withdrawalRequest2PageSize: number = 10

    public wtithdrawalRequest2DuplicatesInTable: WithdrawalRequest[]

    // public rejectWithdrawals: boolean = false


    // public withdrawalRequestTemp: WithdrawalRequest[] = []
/*
    public withdrawalRequest_: string[]
    public WithdrawalRequestGet: boolean = false

    public withdrawalRequestPending_: string[]
    public WithdrawalRequestPendingGet: boolean = true
*/
/*
    public WithdrawalRequestUpdate: boolean = false
    public WithdrawalRequestInsert: boolean = false
    public WithdrawalRequestDelete: boolean = false
*/


    public withdrawalRequestPaymentFile: WithdrawalRequestPaymentFile[]
    // public xmlPaymentFile: string
    public selectedPaymentFile: number
    public selectedPaymentFileIndex: number

    public showFraudAlertsDescriptions: boolean = false
    public onlyWithdrawalRequestStatus: TransactionStatusNoPending = TransactionStatusNoPending.APPROVED
    public onlyPaymentProcessedStatusAll: boolean = false
    public onlyPaymentProcessedStatusGenerated: boolean = true
    public onlyPaymentProcessedStatusPending: boolean = false
    public onlyPaymentProcessedStatusCompleted: boolean = false
    public onlyPaymentProcessedStatusInquiry: boolean = false
    public paymentProcessed: PaymentProcessed[]
    public paymentProcessedIndex: number = -1
    // public selectedPaymentProcessed: PaymentProcessed
    public paymentProcessed_: string[]
    public PaymentProcessedGet: boolean = false
    public PaymentProcessedUpdate: boolean = false
    public PaymentProcessedInsert: boolean = false
    public PaymentProcessedInsert2: boolean = false
    public PaymentProcessedDelete: boolean = false

    // public bonusCampaign: BonusCampaign[]
    // public bonusCampaign_: string[]
    // public BonusCampaignGet: boolean = false
    // public BonusCampaignUpdate: boolean = false
    // public BonusCampaignInsert: boolean = false
    // public BonusCampaignDelete: boolean = false

    // to be implemented
    public bonusWageringRequirement: IBonusWageringRequirement[]
    // public bonusWageringRequirement_: string[]
    public BonusWageringRequirementGet: boolean = false
    public BonusWageringRequirementUpdate: boolean = false
    public BonusWageringRequirementInsert: boolean = false
    public BonusWageringRequirementDelete: boolean = false
    // to be implemented end

    public gameLabel: string
    public gameLabel_: string

    public gameCategory: string
    public gameCategory_: string

    public ggrReports: IReports[]
    public ggrReportsFiltered: IReports[]
    public ggrReportsDates: string[]
    public ggrReportsGet: boolean = false
    public ggrReportsTotal: IReports

    public gameReportsReal: any[]
    public gameReportsRealGet: boolean = false
    public gameReportsTest: any[]
    public gameReportsTestGet: boolean = false
    public playerReports: any[]
    public playerReportsGet: boolean = false
    public gamesIdAndName: string
    public casinoGames: Casinogame[]
    
    // public categories: GameCategory[] // Category2
    // public categories_: string[]
    // public CategoriesGet: boolean = false
    public CategoriesInsert: boolean = false
    public CategoriesUpdate: boolean = false
    // CategoriesDelete: boolean = false
    public category: string
    public category_: string[]
/*
    gamesCentral: GamesCentral[]
    gamesApprovedAll: GamesCentral[]
    GamesCentralGet: boolean = false
    GamesCentralUpdate: boolean = false
    GamesCentralInsert: boolean = false
    gamesCentral_: string[]
*/
    public tags: Tag[] // this has to be filled when strapi is reached
    public scategories: SCategory[]
    // public gamesCentralNew: GamesCentralNew[]
    public gamesCentralFilteredNew: GamesCentralNew[]
    public gamesCentralFiltered: GamesCentralNew[]
    public gamesApprovedAllNew: GamesCentralNew[]
    public GamesCentralGetNew: boolean = false
    public gamesCentral_New: string[]
    public GamesCentralInsertNew: boolean = false
    public GamesCentralUpdateNew: boolean = false
    public gamesCentralSort: boolean = false

    public gamesList: GamesList[]
    public gamesList_: string[]
    public gamesListGet: boolean = false
    public gamesListUpdate: boolean = false
    public gamesListInsert: boolean = false
    public gameListNeedsUpdate: boolean = true

    public gamesListCentral: GamesList[]
    public gamesListApproved: GamesList[]
    public gamesListExport: FileExportGames[]

    public gamesListLabelsGet: boolean = false 
    public toggleGameLabels: boolean[]
    public gamesListLabels_: string []
    public gamesListLabels: GamesListLabel[]

    public gamesListCategories: GamesListCategory[]
    public gamesListCategories_: string[]
    public gamesListCategoriesGet: boolean = false

    public jurisdictionsList: Jurisdiction[]
    public jurisdictionsListGet: boolean = false

    public devices: Device[]
    public devicesGet: boolean = false
/*
    
    GamesCentralDeleteNew: boolean = false
*/
    public gameProviderNew: GameProviderNew[]
    public gameProvider_New: string[]
    public GameProviderGetNew: boolean = false
    public GameProviderInsertNew: boolean = false
/*
    GameProviderUpdateNew: boolean = false
    GameProviderDeleteNew: boolean = false
*/
    public games: Games[]
    public GamesGet: boolean = false
    public GamesUpdate: boolean = false
    public GamesInsert: boolean = false
    public GamesDelete: boolean = false
    public games_: string[]
    public loadingGames: boolean = false
    public loadingGames2: boolean = false
    // public loadingGames3: boolean = false
    // public loadingGames4: boolean = false

    // public gameLabels: GameLabel[]
    // public gameLabels_: string[]
    // public GameLabelsGet: boolean = false
    public GameLabelsInsert: boolean = false
    public GameLabel: string
    public GameLabel_: string

    // gameCategories: GameCategory[]
    // gameCategories_: string[]
    // GameCategoriesGet: boolean = false
    // GameCategoriesInsert: boolean = false

    public counselingCentres: CounselingCentre[]
    public counselingCentres_: string[]
    public CounselingCentresGet: boolean = false
    public CounselingCentresUpdate: boolean = false

    // id: number
    public currencies: Currency[]
    public CurrenciesGet: boolean = false

    public Languages: Language[]
    public LanguagesGet: boolean = false

    public unverifiedAccounts: UnverifiedAccount[]
    public UnverifiedAccountsGet: boolean = false
    public unverifiedAccountsTimestamp: string

    public unverifiedAccountsAll: UnverifiedAccountAll[]
    public UnverifiedAccountsAllGet: boolean = false
    public unverifiedAccountsAllTimestamp: string

    public expiredAccounts: ExpiredAccount[]
    public ExpiredAccountsGet: boolean = false
    public expiredAccountsTimestamp: string

    public questionnaire: ISelfAssessment
    public QuestionnaireGet: boolean = false

    public custom: Custom
    public custom_: string
    public CustomGet: boolean = false
    public CustomUpdate: boolean = false

    public countries: Country[]
    public CountriesGet: boolean = false
    public CountriesInsert: boolean = false

    public nationalities: Nationality[]
    public NationalitiesGet: boolean = false
    public NationalitiesInsert: boolean = false

    // public jurisdictions: Jurisdiction[]
    // public JurisdictionsGet: boolean = false
    public JurisdictionsInsert: boolean = false
    // JurisdictionsUpdate: boolean = false

    public loggedPlayers: LoggedPlayers
    public LoggedPlayersGet: boolean = false
    public loggedPlayersTimestamp: string

    public pepPlayers: PepPlayers[]
    public PepPlayersGet: boolean = false
    public pepPlayersTimestamp: string

    public raffleSelected: Raffle
    public raffleActive: number = -1
    public raffleActiveTimestamp: string
    public raffleLatest: number = -1
    public raffleLatestTimestamp: string

    public raffle: Raffle[]
    public raffle_: string[]
    public RaffleGet: boolean = false
    public RaffleUpdate: boolean = false
    public RaffleInsert: boolean = false
    public RaffleDelete: boolean = false

    // public bonus: Bonus[]
    // public bonus_: string[]
    // public BonusGet: boolean = false
    // public BonusUpdate: boolean = false
    // public BonusInsert: boolean = false
    // public BonusDelete: boolean = false

    // public rgsGameSupplierNew: RgsGameSupplierNew[]
    // public rgsGameSupplier_New: string[]
    // public RgsGameSupplierGetNew: boolean = false
    // public RgsGameSupplierInsertNew: boolean = false

    public raffleParticipants: RaffleParticipants[]
    public raffleParticipants_: string[]
    public RaffleParticipantsGet: boolean = false
    public RaffleParticipantsUpdate: boolean = false
    public RaffleParticipantsInsert: boolean = false
    public RaffleParticipantsDelete: boolean = false

    public RecentEventsGetLogouts: boolean = false
    public recentEventsLogouts: Latest[]
    public recentEventsLogoutsTimestamp: string = ''

    public RecentEventsGetDeposits: boolean = false
    public recentEventsDeposits: Latest[]
    public recentEventsDepositsTimestamp: string = ''
    
    public RecentEventsGetWithdrawals: boolean = false
    public recentEventsWithdrawals: Latest[]
    public recentEventsWithdrawalsTimestamp: string = ''
    
    public RecentEventsGetRegistrations: boolean = false
    public recentEventsRegistrations: Latest[]
    public recentEventsRegistrationsTimestamp: string = ''
    
    public RecentEventsGetLogins: boolean = false
    public recentEventsLogins: Latest[]
    public recentEventsLoginsTimestamp: string = ''
    
    public RecentEventsGetFirstDeposits: boolean = false
    public recentEventsFirstDeposits: Latest[]
    public recentEventsFirstDepositsTimestamp: string = ''
    
    
    public RecentEventsGetIssues: boolean = false
    public recentEventsIssues: Latest[]
    public recentEventsIssuesTimestamp: string = ''

    public issuesBlacklistRegistration: Latest[]
    public issuesBlacklistExclusion: Latest[]
    public issuesBlacklistLogin: Latest[]
    public issuesBlacklistCronJob: Latest[]
    public issuesBlacklistManual: Latest[]

    public issuesPEPCheckLogin: Latest[] = []
    public issuesPEPCheckRegistration: Latest[] = []
    public issuesPEPCheckNameChanged: Latest[] = []
    public issuesPEPCheckBeneficiaryName: Latest[] = []
    public issuesPEPCheckByOperator: Latest[] = []

    public issuesCRIFCheckMismatch: Latest[] = []
    public issuesCRIFCheckLogin: Latest[] = []
    public issuesCRIFCheckRegistration: Latest[] = []
    public issuesCRIFChangedOperator: Latest[] = []
    
    public RecentEventsGetIssuesResolved: boolean = false
    public recentEventsIssuesResolved: Latest[]
    public recentEventsIssuesResolvedTimestamp: string = ''

    public RecentEventsGetIssuesUnresolved: boolean = false
    public recentEventsIssuesUnresolved: Latest[]
    public recentEventsIssuesUnresolvedTimestamp: string = ''
/*
    public RecentEventsGetExpiredAccounts: boolean = false
    public recentEventsExpiredAccounts: Latest[]
    public recentEventsIssuesExpiredAccountsTimestamp: string = ''

    public RecentEventsGetUnverifiedAccountsAll: boolean = false
    public recentEventsUnverifiedAccountsAll: Latest[]
    public recentEventsIssuesUnverifiedAccountsAllTimestamp: string = ''

    public RecentEventsGetUnverifiedAccounts: boolean = false
    public recentEventsUnverifiedAccounts: Latest[]
    public recentEventsIssuesUnverifiedAccountsTimestamp: string = ''
*/


    public PlayerTimelineGet: boolean = false
    public playerTimeline: Array<ISession | IIssue | ITransaction>
    public playerTimelineIssues: Array<ISession | IIssue | ITransaction>
    public playerTimelineTransactions: Array<ISession | IIssue | ITransaction>
    public PlayerTimelineIssues: boolean = undefined
    // public playerTimelineTransactionsCount: number
    // public PlayerTimelinePlayerId: string
/*
    public gameSessionSelectedId: string
    public gameSessionSelectedGame: string
    public gameSessionPage: number = 0
    public gameSessionLimit: number = 10
    public gameSessions: IGameSession[]
    public gameSessionsCount: number = 0
    public GameSessionsGet: boolean = false
    public GameSessions: boolean = false

    public gameSessionTransactionsPage: number = 0
    public gameSessionTransactionsLimit: number = 10
    public gameSessionsTransactions: IGameSessionTransaction[] // 
    public gameSessionsTransactionsCount: number = 0
    public GameSessionsTransactionsGet: boolean = false
*/  
    public sessionId: string
    public SessionTransactionsGet: boolean = false
    public sessionTransactions: ITransactionWithinSession[]
    public sessionTransactionsFiltered: ITransactionWithinSession[]

    public selfExclusion: SelfExclusion[]
    public selfExclusion_: string[]
    public SelfExclusionGet: boolean = false
    public SelfExclusionUpdate: boolean = false
    public SelfExclusionInsert: boolean = false
    public SelfExclusionDelete: boolean = false


    public dashboardBackend: DashboardBackend[]
    public dashboardBackend_: string[]
    public DashboardBackendGet: boolean = false
    public DashboardBackendUpdate: boolean = false
    public DashboardBackendInsert: boolean = false
    public DashboardBackendDelete: boolean = false

    public temporaryExcludeOptions: TemporaryExcludeOptions[]
    public temporaryExcludeOptions_: string[]
    public TemporaryExcludeOptionsGet: boolean = false
    public TemporaryExcludeOptionsUpdate: boolean = false
    public TemporaryExcludeOptionsInsert: boolean = false
    public TemporaryExcludeOptionsDelete: boolean = false

    public temporaryExclude: TemporaryExclude[]
    public temporaryExclude_: string[]
    public TemporaryExcludeGet: boolean = false
    public TemporaryExcludeUpdate: boolean = false
    public TemporaryExcludeInsert: boolean = false
    public TemporaryExcludeDelete: boolean = false

    public netLimitLossIncreased: NetLimitLossIncreased[]
    public netLimitLossIncreased_: string[]
    public NetLimitLossIncreasedGet: boolean = false
    public NetLimitLossIncreasedUpdate: boolean = false
    public NetLimitLossIncreasedInsert: boolean = false
    public NetLimitLossIncreasedDelete: boolean = false

    public netLimitLossRegistration: NetLimitLossRegistration[]
    public netLimitLossRegistration_: string[]
    public NetLimitLossRegistrationGet: boolean = false
    public NetLimitLossRegistrationUpdate: boolean = false
    public NetLimitLossRegistrationInsert: boolean = false
    public NetLimitLossRegistrationDelete: boolean = false

    public saGifts: SAGifts[]
    public saGifts_: string[]
    public SaGiftsGet: boolean = false
    public SaGiftsUpdate: boolean = false
    public SaGiftsInsert: boolean = false
    public SaGiftsDelete: boolean = false

    public saCriteria: SACriteria[]
    public saCriteria_: string[]
    public SaCriteriaGet: boolean = false
    public SaCriteriaUpdate: boolean = false
    public SaCriteriaInsert: boolean = false
    public SaCriteriaDelete: boolean = false

    public saQuestions: SAQuestions[]
    public saQuestions_: string[]
    public SaQuestionsGet: boolean = false
    public SaQuestionsUpdate: boolean = false
    public SaQuestionsInsert: boolean = false
    public SaQuestionsDelete: boolean = false
/*
    public notify: Notify[]
    public notify_: string[]
    public NotifyGet: boolean = false
    public NotifyUpdate: boolean = false
    public NotifyInsert: boolean = false
    public NotifyDelete: boolean = false
*/
/* NOT ACTIVE FOR THE TIME BEING, BUT IT WILL HAVE TO BE ACTIVATED
    public UnresolvedIssuesGet: boolean = false
    public unresolvedIssues: IIssue[] = []
    public unresolvedIssues_: string[]
*/
    // public issues: IIssue[]
    /*
    public issueId: number
    public issue: IIssue
    */
    
    public issues1: IIssue[]
    public issues2: IIssue[]
    public issues3: IIssue[]
    public issues4: IIssue[]
    // public issues_: string[]
    
    // ************************************************
    // paginated:
    public issuesDashboardBackend: IIssue[] 
    // non paginated:
    // public issuesVeto: IIssue[]
    // public issuesCrif: IIssue[]
    // public issuesPep: IIssue[]
    public issuesFraudAlerts: IIssue[]
    // ************************************************
    
    public IssuesGet: boolean = false
    public IssuesUpdate: boolean = false
    public IssuesInsert: boolean = false
    public IssuesInsertType: IssueTypes
    public playerCards: PlayerCard[]
    public playerCards_: string[]
    public PlayerCardsGet: boolean = false

    // playerCard: PlayerCardDetail[]
    public playerCard: Player = {/*language: 'en'*/} // this is just a placeholder
    public playerCard_: string
    public PlayerCardGet: boolean = false
    public PlayerCardUpdate: boolean = false
    public PlayerCardAccountStatusChanged: boolean = false
    public PlayerCardAccountStatusOldStatus: string
    public PlayerCardAccountStatusNewStatus: string

    public largeValueDeposits: LargeValueDepositsWithdrawal[]
    public largeValueDeposits_: string[]
    public LargeValueDepositsGet: boolean = false
    public LargeValueDepositsUpdate: boolean = false
    public LargeValueDepositsInsert: boolean = false
    public LargeValueDepositsDelete: boolean = false

    public largeValueWithdrawal: LargeValueDepositsWithdrawal[]
    public largeValueWithdrawal_: string[]
    public LargeValueWithdrawalGet: boolean = false
    public LargeValueWithdrawalUpdate: boolean = false
    public LargeValueWithdrawalInsert: boolean = false
    public LargeValueWithdrawalDelete: boolean = false

    public temporaryAccountStatus: TemporaryAccountStatus[]
    public temporaryAccountStatus_: string[]
    public TemporaryAccountStatusGet: boolean = false
    public TemporaryAccountStatusUpdate: boolean = false
    public TemporaryAccountStatusInsert: boolean = false
    public TemporaryAccountStatusDelete: boolean = false

    public noPlayerActivityStatus: NoPlayerActivityStatus[]
    public noPlayerActivityStatus_: string[]
    public NoPlayerActivityStatusGet: boolean = false
    public NoPlayerActivityStatusUpdate: boolean = false
    public NoPlayerActivityStatusInsert: boolean = false
    public NoPlayerActivityStatusDelete: boolean = false

    public blacklist: Blacklist[]
    public blacklist_: string[]
    public BlacklistGet: boolean = false
    public BlacklistUpdate: boolean = false
    public BlacklistInsert: boolean = false
    public BlacklistDelete: boolean = false    
    
    public maximumBalance: MaximumBalance[]
    public maximumBalance_: string[]
    public MaximumBalanceGet: boolean = false
    public MaximumBalanceUpdate: boolean = false
    public MaximumBalanceInsert: boolean = false
    public MaximumBalanceDelete: boolean = false
    
    public retail: Retail[]
    public retail_: string[]
    public RetailGet: boolean = false
    public RetailUpdate: boolean = false
    public RetailInsert: boolean = false
    public RetailDelete: boolean = false

    public retailActions: RetailAction[]
    public retailActions_: string[]
    public RetailActionsGet: boolean = false
    public RetailActionsUpdate: boolean = false
    public RetailActionsInsert: boolean = false
    public RetailActionsDelete: boolean = false
    
    public log: any[]
    public Log: any[]
    public coreTransactionsAggregatedStream: Array<{date: string}> = undefined
    public coreTransactionsCalled: boolean = false
    public coreTransactionsStreamCount:number = 0
    public coreTransactionsStream:any[] = undefined
    public coreTransactionsStreamInit:any[] = undefined
    public coreTransactionsDistinctYears:Array<{year: string}> = undefined
    public coreTransactionsYear: number = 0
    public coreTransactionsDistinctMonths:Array<{month: string}> = undefined
    public coreTransactionsMonth: number = undefined
    public coreTransactionsDistinctDates:Array<{date: string}> = undefined
    public coreTransactionsDate: number = undefined
    public coreTransactionsDistinctGames:Array<{game: string}> = undefined
    public coreTransactionsGame: number[] = []
    public coreTransactionsDetailedData: string = undefined
/*
    coreTransactionsDistinctPlayers:{playerid: string}[] = undefined
    coreTransactionsPlayer: number[] = []
*/
    public coreTransactionsLimit: number = 100
    public coreTransactionsOffset: number = 0

    public inactivityLogout: boolean = false
    public lastActionTime: number
    public crmPlayerDataStream: IPlayers[] = undefined
    // public crmRulesStream: Array<{id: string, data: IPlayerGroupRule}> = undefined
    // crmEventsStream: {id: string, playerid: string, gameid: string, betamount: string, winamount: string, date: string}[] = undefined
    // public crmGamesStream: Array<{id: string, game: string, image: string}> = undefined
    public crmCountriesStream: Array<{country: string/*, continent: string*/, name: string}> = undefined
    public crmRegionsStream: Array<{region: string, country: string, name: string}> = undefined
    public crmNationalitiesStream: Array<{nationality: string, name: string}> = undefined
    public crmCurrenciesStream: Array<{currency: string}> = undefined
    // tslint:disable-next-line:max-line-length
    // public crmPlayersStream: PlayerData[] = undefined
    // public crmTimezonesStream: Array<{timezone: string}> = undefined
    public SubLevels: any[]
    // Player: any
    public settlement: boolean = false
    public settlementStream:any[] = undefined
    public tempStream:any[] = undefined
    public temp2Stream:any[] = undefined

    public storeStream:any[] = undefined
    public storeStreamAll:any[] = undefined

    public missionStream:any[] = undefined
    public missionStreamAll: IMissionDefinition2[] = undefined
    public tournamentStream: any[] = undefined
    public tournamentStreamAll: any[] = undefined
    public tournamentStream_: any[] = undefined
    public tournamentLeaderboard: any[] = undefined
    public tournament: any = undefined
    /* player */
    // playerStream:any[] = undefined

    /* reports */
    public rstStream:any[] = undefined
    public RstStream:any[] = undefined
    public RstAllStream:any[] = undefined
    
    public lottoStream:any[] = undefined
    public lottoStreamAll:any[] = undefined
    public lottoGameTypeStream:any[] = undefined
    public lottoJackpotStream:any[] = undefined
    public lottoDrawingStream:any[] = undefined

    public lottoTransactionsStream:any[] = undefined
    // new
    public lottoNextDrawingsStream:any[] = undefined
    public lottoDrawingScheduleStream:any[] = undefined
    public lottoForexStream:any[] = undefined
    public lottoDrawingPayoutStream:any[] = undefined
    
    public rewardShopStream:any[] = undefined
    public rewardShopStreamAll:any[] = undefined
    public rewardShopStreamCategory:any[] = undefined
    public domains:any[] = undefined
    public reportsDataStream:any[] = undefined
    public reportsItemsDataStream:any[] = undefined
    public reports2DataStream:any[] = undefined
    public reports2ItemsDataStream:any[] = undefined

    public reportsPieChart:any[] = undefined
    // reportsPieChartDataCategory:any[] = undefined
    // reportsPieChartData:any[] = undefined
    // reportsPieChartDataVariable:any[] = undefined
    // reportsPieChartDataTop:any[] = undefined
    public change = false
    /* countries */

    public countriesDataStream:any[] = undefined

    /* achievements*/
    public achievementsStream:any[] = undefined // logged in as system user
/*
    achievementStream:any[] = undefined // logged in as site user
    achievementsoStream:any[] = undefined // logged in as operator user
*/
    public achievementsAllStream:any[] = undefined// all

    /* levels */
    public levelsStream:any[] = undefined      // logged in as system user
    public bigLevels: number = 0
    public sublevels: number = 0
    public finalLevel: string = ''
/*
    levelStream:any[] = undefined      // logged in as site user
    levelsoStream:any[] = undefined     // logged in as operator user
    levelsmStream:any[] = undefined     // logged in as operator user
*/  
    public levelsAllDetailsStream:any[] = undefined

    public pointProgramStream:any[] = undefined
    public pointProgramStreamAll:any[] = undefined
    public pointProgramAllStream:any[] = undefined  
    public levelsAllStream:ILevelDefinition[] = undefined   // all levels
    public sublevelsAllStream:any[] = undefined   // all sublevels
    public sublevels2AllStream:any[] = undefined   // all sublevels
    public levelsAllFilterStream:any[] = undefined
    public pointTypeStreamAll:any[] = undefined
    public subPointTypeStreamAll:any[] = undefined
    public subpointTypeStreamAll:any[] = undefined
    public subPointFilterTypeStreamAll:any[] = undefined
    public pointTypeStreamDefault:any[] = undefined
    public pointTypeStream:any[] = undefined
    public subPointTypeStream:any[] = undefined


    /* game provider */
    // gameProvidersStream:any[] = undefined
    // gameProvidersAllStream:any[] = undefined

    /* new */
    // gameAssetsProvidersAllStream:any[] = undefined
    // complianceProvidersAllStream:any[] = undefined
    // complianceSubProvidersAllStream:any[] = undefined
    // paymentProvidersAllStream:any[] = undefined
    // paymentSubProvidersAllStream:any[] = undefined

    // brandOwnersAllStream:any[] = undefined
    // storesAllStream:any[] = undefined

    // gameAssetsProvidersStream:any[] = undefined
    // complianceProvidersStream:any[] = undefined
    // complianceSubProvidersStream:any[] = undefined
    // paymentProvidersStream:any[] = undefined
    // paymentSubProvidersStream:any[] = undefined
    // affiliateSiteStream:any[] = undefined
    // affiliateSiteAllStream:any[] = undefined
    
    public playersStream:any[] = undefined
    public playersStreamSelected: boolean[] = undefined

    /* master-holding users */
    public users0Stream:any[] = undefined // logged in as system user
    public user0Stream:any[] = undefined // logged in as master user 

    /* system users */
    public users1Stream:any[] = undefined // table of all system users 

    /* game provider users */
    public users2Stream:any[] = undefined // logged in as system user
    public user2Stream:any[] = undefined  // logged in as game provider user
    public user2mStream:any[] = undefined // logged in as master user

    /* operator users */
    public users3Stream:any[] = undefined // logged in as system user
    public user3Stream:any[] = undefined  // logged in as operator user
    public user3mStream:any[] = undefined // logged in as master user

    /* site users */
    public users4Stream:any[] = undefined // logged in as system user
    public user4Stream:any[] = undefined  // logged in as site user
    public user4oStream:any[] = undefined // logged in as operator user
    public user4mStream:any[] = undefined // logged in as master user

    /* new users */

    /* game asset providers*/
    public users5Stream:any[] = undefined
    public user5Stream:any[] = undefined
    public user5oStream:any[] = undefined // logged in as contractual partner user
    public user5mStream:any[] = undefined // logged in as master user

    /* compliance providers */
    public users6Stream:any[] = undefined
    public user6Stream:any[] = undefined
    public user6mStream:any[] = undefined // logged in as master user

    /* compliance sub-providers */
    public users7Stream:any[] = undefined
    public user7Stream:any[] = undefined
    public user7oStream:any[] = undefined // logged in as contractual partner user
    public user7mStream:any[] = undefined // logged in as master user

    /* payment providers */
    public users8Stream:any[] = undefined
    public user8Stream:any[] = undefined
    public user8mStream:any[] = undefined // logged in as master user

    /* payment sub-providers */
    public users9Stream:any[] = undefined
    public user9Stream:any[] = undefined
    public user9oStream:any[] = undefined // logged in as contractual partner user
    public user9mStream:any[] = undefined // logged in as master user

    /* affiliate site */
    /* users10Stream:any[] = undefined */
    /* user10Stream:any[] = undefined */

    /* operator2 users */
    public users11Stream:any[] = undefined
    public user11Stream:any[] = undefined
    public user11mStream:any[] = undefined // logged in as master user

    /* site2 users */
    public users12Stream:any[] = undefined
    public user12Stream:any[] = undefined
    public user12oStream:any[] = undefined // logged in as contractual partner user
    public user12mStream:any[] = undefined // logged in as master user
    

    /* table of sites for each of 5 shown operators */
    /* deprecated
    operatorsSites1Stream:any[] = undefined
    operatorsSites2Stream:any[] = undefined
    operatorsSites3Stream:any[] = undefined
    operatorsSites4Stream:any[] = undefined
    operatorsSites5Stream:any[] = undefined
    */
    /* holdings */

    public masterStream:any[] = undefined
    public masterAllStream:any[] = undefined

    /* contractual partners */
    public contractualPartnersStream:any[] = undefined
    public contractualPartnersAllStream:any[] = undefined
    public contractualPartnersAllStream2:any[] = undefined

    /* sub entities */
    public subEntityStream:any[] = undefined
    public subEntityAllStream:any[] = undefined
    // subEntityAllStream2:any[] = undefined

    /* operators */
    // operatorsStream:any[] = undefined
    public operatorsAllStream:any[] = undefined
    
    /* sites */
    public sitesStream:any[] = undefined
    // sitesAllStream:any[] = undefined

    /* sites of specific operator */
    // sitesOperatorStream:any[] = undefined

    // sitesAllStream:any[] = undefined
    public sitesOperatorAllStream:any[] = undefined
    public challengesAllStream:any[] = undefined
    public challengesStream:any[] = undefined
    /* currency */
    public currenciesAllStream:any[] = undefined
    public mainCurrency: string = undefined
    public lastDateCurrency: string = undefined
    public currenciesStream:any[] = undefined
    public currencyStream:any[] = undefined

    /* banner */
    public bannerAllStream:any[] = undefined
    public bannerStreamAll:any[] = undefined
    public bannerStreamAll2:any[] = undefined
    public bannerStream:any[] = undefined
    
    /* language */
    public languagesAllStream:any[] = undefined
    public languagesStreamAll:any[] = undefined
    public languagesStream:any[] = undefined
    public language: number = 0
    public languages: any[] = undefined
    public default_language: number = 0
    public selectLanguage: boolean = false
    // currencyRatesStream:any[] = undefined

    /* player groups */
    public playerGroupStream:any[] = undefined
    public playerGroupsStream:any[] = undefined
    public playerGroupsoStream:any[] = undefined
    public playerGroupsAllStream:any[] = undefined

    /* games */
    public gamesAllStream:any[] = undefined
    public gamesStream:any[] = undefined
    public gamesStreamAll:any[] = undefined
    public GamesStreamAll:any[] = undefined
    public GamesStreamAllSelected: boolean[] = undefined
    public GamesStreamAllSelected2: boolean[] = undefined
    public GamesStreamAllSelected3: boolean[] = undefined
    public GamesStreamCategorySelected: number[] = undefined
    public GamesStream:any[] = undefined
    public GamesStreamProvider:any[] = undefined
    public gamesStreamProvider:any[] = undefined

    public FlagsStreamAll:any[] = undefined
    public FlagsStreamAllOrder:any[] = undefined
    public FlagsStreamAllSelected: boolean[] = undefined

    public data:string
    public no_data:string
    public selected:string

    public dataGrey0:string
    public dataGrey25:string
    public dataGrey50:string
    public dataGrey75:string
    public dataGrey100:string
    public selectedGrey:string
    public borderGrey:string
    public borderGreyWidth:number
    public borderGreyOpacity:number

    public countCalledCurrency: boolean = false
    // wagerPlanStream:any[] = undefined
    public loyaltyFreeRoundStream:any[] = undefined

    public selectedErrors: any[] = undefined
    public selectedStatus: string = '-1'
    public selectedMessage: string = '-1'
}
