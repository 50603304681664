import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {IAleaccTriggerFreeRound} from '../../../Logic/Database/DatabaseData'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet, isUserAdmin, isPureScandibet, updateCategory, getDiscountedValue,
getRSIBody, getRSIHeader, utilsTimeValidEmptyNotValid/*, areDatesSuitable*/} from '../../../Logic/Utils' 
import {SingleDatePicker} from 'react-dates'
import {GamesSelection, getSelected, isAllSelectedFlexible, isAllSelectedFreespin, isAllSelectedNetent} from './GamesSelection'
import {PlayersSelection, CountPlayersSelected} from './PlayersSelection'
import {Modal} from '../../Reusables/Modal'
import * as moment from 'moment'
// import Switch from 'react-toggle-switch'
import {faUsers, faUserLock} from '@fortawesome/free-solid-svg-icons'
import {downScaleCanvas} from '../../../Logic/Utils'
import * as base64 from 'base-64'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {Flag, Flags} from '../../Reusables/Flag'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

export function getRewardShopCount() {
    if (Main.Instance.RewardShopState.countCalled === false) {
        Logic.Type.New(Main.Instance.RewardShopState, {countCalled:true})
        DbRoutes.loadData()
        DbRoutes.GetBanner()
        DbRoutes.getRewardShop()
        DbRoutes.GetAllLanguages()
        DbRoutes.GetFlags()
        DbRoutes.GetCurrencyAll()
        DbRoutes.GetCurrency()
    }
}

function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                site_id: stream[0]['id'],
        })
        DbRoutes.loadData()
        DbRoutes.getRewardShop()
        DbRoutes.GetCurrency()
    }
}

function handleDelete(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.RewardShopItem + ' ' + 
        Assets.withName + ' "' + row.original.name[0] + '"?')) {

        if (Main.Instance.RewardShopState.pNum === 
        (Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 2) / 
        Main.Instance.RewardShopState.pSize) + 1)) {
            DbRoutes.removeRewardShopItem (row.original.id.toString(), DeleteLastPage)
        } else {
            DbRoutes.removeRewardShopItem (row.original.id.toString(), DeleteNotLastPage)
        }
    }
}

function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.reward_shop SET active = ', id.toString(), value, getRewardShopCount) }
}

function toggleFreeRound(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.reward_shop SET free_round = ', id.toString(), value, getRewardShopCount) }
}

function toggleBuyable(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.reward_shop SET buyable = ', id.toString(), value, getRewardShopCount) }
}

function toggleNewly(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.reward_shop SET only_newly_registered = ', id.toString(), value, getRewardShopCount) }
}

function handleRemoveImage(id: string) {
    if (confirm (Assets.confirmRemoveImage)) {
        DbRoutes.Action('.reward_shop SET image = \'\'', id, getRewardShopCount)
    }
}

function handleEdit(row: any) {
    gotoTop();
    if (Main.Instance.DatabaseDataState.GamesStreamAll === undefined) {
        alert (Assets.g1)
    } else {
        // console.log (row.original.players.length)
        const playersStreamSelected: boolean[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
            playersStreamSelected[i] = false
            for (const Data of row.original.players) { if (+Main.Instance.DatabaseDataState.playersStream[i]['player'] === Data) {
                playersStreamSelected[i] = true
                break
            }
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})

        let active: string
        let price: string
        let discount: string
        let free_round: string
        let buyable: string
        let only_newly_registered: string
        active = row.original.active.toString() === 'true' ? '1' : '2'
        free_round = row.original.free_round.toString() === 'true' ? '1' : '2'
        buyable = row.original.buyable.toString() === 'true' ? '1' : '2'
        only_newly_registered = row.original.only_newly_registered.toString() === 'true' ? '1' : '2'
        price = row.original.price ? row.original.price : '0'
        discount = row.original.discount ? row.original.discount : '0'
        const custom: IAleaccTriggerFreeRound = 
            row.original.custom !== undefined && row.original.custom !== null && row.original.custom.toString() !== 'undefined' && 
            row.original.custom.toString() !== 'null' && row.original.custom.toString() !== '{}' && row.original.custom.toString().trim().length > 0 ?
            JSON.parse(JSON.stringify(row.original.custom)) : undefined
        if (custom) {
            const GamesStreamAllSelected: boolean[] = []
            
            if (custom.SelectedGameIds) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    let value: boolean = false
                    for (let j: number = 0; j < custom.SelectedGameIds.length; j++) {
                        if (custom.SelectedGameIds[j] === parseInt(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])) {
                            value = true
                            break
                        }
                    }
                    GamesStreamAllSelected.push(value) 
                }
            } else {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
                    GamesStreamAllSelected.push(false)
                }
            }
            
            Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})

            if (custom.RewardType) {
                if (custom.RewardType === 'FixedSum') { custom.rewardType = 1 } else if (custom.RewardType === 'Freespin') { custom.rewardType = 3 }
                // else if (custom.RewardType === 'Freebet') custom.rewardType = 4
                delete(custom.RewardType)
            }
            
            if (custom.WageringType) {
                if (custom.WageringType === 'PaidBonusAmountXFactor') { custom.wageringType = 1 } else if (custom.WageringType === 'PaidBonusAmountPlusDepositAmountXFactor') { custom.wageringType = 2 } else if (custom.WageringType === 'FixedAmount') { custom.wageringType = 3 }
                // else if (custom.WageringType === 'NumberOfBetsXBetAmount') custom.wageringType = 4
                delete(custom.WageringType)
            }

            if (custom.Product) {
                if (custom.Product === 'Casino') { custom.product = 1 } else if (custom.Product === 'Bingo') { custom.product = 2 } else if (custom.Product === 'Sportsbook') { custom.product = 3 } else if (custom.Product === 'ScoutFantasy') { custom.product = 4 } else if (custom.Product === 'PoolBetting') { custom.product = 5 } else if (custom.Product === 'Poker') { custom.product = 6 } else if (custom.Product === 'Lotto') { custom.product = 7 }
                delete(custom.Product)
            }

            if (custom.WageringConditionType === 'None') {
                custom.MinOdds = undefined
                custom.MinAmount = undefined
            } else if (custom.WageringConditionType === 'MinOdds') {
                custom.MinOdds = custom.WageringConditionFactor
                custom.MinAmount = undefined
            } else if (custom.WageringConditionType === 'MinAmount') {
                custom.MinAmount = custom.WageringConditionFactor
                custom.MinOdds = undefined
            }
        }

        Logic.Type.New(Main.Instance.RewardShopState, {
            mode: true,
            id: row.original.id, 
            // index: rowData[1],
            name: row.original.name,
            name_saved: row.original.name,
            info: row.original.info,
            info_saved: row.original.info,
            prices: row.original.prices,
            discounts: row.original.discounts,
            banner:row.original.banner,
            banner_saved:row.original.banner,
            choose_category: row.original.info2,
            Category: (parseInt(row.original.category) + 1).toString(),
            Category_saved:(parseInt(row.original.category) + 1).toString(),
            image:row.original.image,
            image_saved:row.original.image,
            price,
            discount,
            active,
            free_round,
            free_round_saved: free_round,
            buyable,
            buyable_saved: buyable,
            only_newly_registered,
            only_newly_registered_saved: only_newly_registered,
            price_saved: price,
            discount_saved: discount,
            active_saved: active,
            custom,
            custom_saved: custom,

            fromDate: row.original.fromtime && row.original.fromtime.substring(0, 10) !== '1970-01-01' ? moment.default(row.original.fromtime.substring(0, 10)) : null,
            fromTime: row.original.fromtime && (!(row.original.totime && row.original.totime.substring(0, 10) !== '1970-01-01') || 
                      (row.original.fromtime.substring(0, 10) !== '1970-01-01')) ? row.original.fromtime.substring(11, 19) : '',

            toDate: row.original.totime && row.original.totime.substring(0, 10) !== '1970-01-01' ? moment.default(row.original.totime.substring(0, 10)) : null,
            toTime: row.original.totime && (!(row.original.fromtime && row.original.fromtime.substring(0, 10) !== '1970-01-01') || 
                    (row.original.totime.substring(0, 10) !== '1970-01-01')) ? row.original.totime.substring(11, 19) : '',
        })
    }
}

function removeImage() {
    if (confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.RewardShopState, {image: ''})
        DbRoutes.Action('.reward_shop SET image = \'\'', 
                        Main.Instance.RewardShopState.id, getRewardShopCount)
    }
}

function updateName(text: string) {
    if (Main.Instance.RewardShopState.textsSelected === undefined || Main.Instance.RewardShopState.textsSelected === false) {
        const name = Object.assign([], Main.Instance.RewardShopState.name)
        name[Main.Instance.DatabaseDataState.language] = text
        Logic.Type.New (Main.Instance.RewardShopState, {name})
    }
}

function handleInsertUpdate(playerName?: string) {
/*
    if (Main.Instance.RewardShopState.fromDate || Main.Instance.RewardShopState.toDate) {
        if (!areDatesSuitable(Main.Instance.RewardShopState.fromDate, Main.Instance.RewardShopState.toDate, Main.Instance.RewardShopState.fromTime, Main.Instance.RewardShopState.toTime)) {
            alert ('Dates are not suitable'); return
        }
    }
*/
    const custom: IAleaccTriggerFreeRound = Object.assign ({}, Main.Instance.RewardShopState.custom)
    // THIS IS DIFFERENT. WTF IS THIS DIFFERENT
    // console.log (Main.Instance.RewardShopState.custom)
    // console.log (custom)
    
    if (custom.rewardType !== undefined && custom.rewardType === 0) { delete(custom.rewardType) }
    if (custom.product !== undefined && custom.product === 0) { delete(custom.product) }
    if (custom.wageringType !== undefined && custom.wageringType === 0) { delete(custom.wageringType) }

    if (Main.Instance.RewardShopState.custom !== undefined && Object.keys(custom).length > 0 && custom.constructor === Object) {
        // console.log (custom)
        const Selected: number = CountPlayersSelected()
        if (playerName && Selected !== 1) {
            alert (Assets.r3)
            return
        }
        custom.WageringConditionType = 'None'
        if (Main.Instance.RewardShopState.custom.MinOdds) {
            custom.WageringConditionType = 'MinOdds'
            custom.WageringConditionFactor = Main.Instance.RewardShopState.custom.MinOdds
            delete (Main.Instance.RewardShopState.custom.MinOdds)
        } else if (Main.Instance.RewardShopState.custom.MinAmount) {
            custom.WageringConditionType = 'MinAmount'
            custom.WageringConditionFactor = Main.Instance.RewardShopState.custom.MinAmount
            delete (Main.Instance.RewardShopState.custom.MinAmount)
        }

        const getselected: number = Main.Instance.DatabaseDataState.GamesStreamAllSelected ? getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected) : 0

        if (Main.Instance.RewardShopState.custom.Currency) {
            for (const item of custom.Currency) {
                if (item['Selected'] === false) { continue }
                if ((Main.Instance.RewardShopState.custom.rewardType === 1 || /*Main.Instance.RewardShopState.custom.rewardType === 2 ||*/ 
                    Main.Instance.RewardShopState.custom.rewardType === 4) && item[Assets.Amount] === 0) {
                    alert (Assets.AmountForCurrency + ' ' + item[Assets.Name] + ' ' + Assets.shouldBeDefined + '.')
                    return
                } else if (Main.Instance.RewardShopState.custom.rewardType === 3 && Main.Instance.RewardShopState.custom.IsFlexibleFreespin === false && item['BetLevel'] === 0) {
                    alert (Assets.BetLevelForCurrency + ' ' + item[Assets.Name] + ' ' + Assets.shouldBeDefined + '.')
                    return
                } else if (Main.Instance.RewardShopState.custom.rewardType === 3 && Main.Instance.RewardShopState.custom.IsFlexibleFreespin === false && item['FreespinAmount'] === 0) {
                    alert (Assets.FreespinAmountForCurrency + ' ' + item[Assets.Name] + ' ' + Assets.shouldBeDefined + '.')
                    return
                } else if (Main.Instance.RewardShopState.custom.rewardType === 3 && custom.IsFlexibleFreespin === true && item[Assets.Amount] === 0) {
                    alert (Assets.AmountForCurrency + ' ' + item[Assets.Name] + ' ' + Assets.shouldBeDefined + '.')
                    return
                }
            }
        } 
        
        if (Main.Instance.RewardShopState.custom.rewardType === 1) { custom.RewardType = 'FixedSum' } else if (Main.Instance.RewardShopState.custom.rewardType === 3) { custom.RewardType = 'Freespin' }
        // else if (Main.Instance.RewardShopState.custom.rewardType === 4) custom.RewardType = 'Freebet'

        if (Main.Instance.RewardShopState.custom.wageringType === 1) { custom.WageringType = 'PaidBonusAmountXFactor' } else if (Main.Instance.RewardShopState.custom.wageringType === 2) { custom.WageringType = 'PaidBonusAmountPlusDepositAmountXFactor' } else if (Main.Instance.RewardShopState.custom.wageringType === 3) { custom.WageringType = 'FixedAmount' }
        // else if (Main.Instance.RewardShopState.custom.wageringType === 4) custom.WageringType = 'NumberOfBetsXBetAmount'

        if (Main.Instance.RewardShopState.custom.product === 1) { custom.Product = 'Casino' } else if (Main.Instance.RewardShopState.custom.product === 2) { custom.Product = 'Bingo' } else if (Main.Instance.RewardShopState.custom.product === 3) { custom.Product = 'Sportsbook' } else if (Main.Instance.RewardShopState.custom.product === 4) { custom.Product = 'ScoutFantasy' } else if (Main.Instance.RewardShopState.custom.product === 5) { custom.Product = 'PoolBetting' } else if (Main.Instance.RewardShopState.custom.product === 6) { custom.Product = 'Poker' } else if (Main.Instance.RewardShopState.custom.product === 7) { custom.Product = 'Lotto' }

        if (Main.Instance.RewardShopState.custom.rewardType === undefined || Main.Instance.RewardShopState.custom.rewardType === 0) {
            alert (Assets.r4)
            return
        } else if (Main.Instance.RewardShopState.custom.product === undefined || Main.Instance.RewardShopState.custom.rewardType === 0) {
            alert (Assets.r5)
            return
        } else if (Main.Instance.RewardShopState.custom.wageringType === undefined || Main.Instance.RewardShopState.custom.wageringType === 0) {
            alert (Assets.r6)
            return
        } 

        delete(custom.rewardType)
        delete(custom.product)
        delete(custom.wageringType)
        // alert ('here')
        if (Main.Instance.RewardShopState.custom.ValidDays === undefined || Main.Instance.RewardShopState.custom.ValidDays === 0) {
            alert (Assets.r7)
            return
        } else if (Main.Instance.RewardShopState.custom.rewardType === 3 && getselected === 0 && 
            Main.Instance.DatabaseDataState.GamesStreamAll !== undefined && Main.Instance.DatabaseDataState.GamesStreamAll.length > 0) {
            alert (Assets.r8)
            return
        } else if (Main.Instance.DatabaseDataState.GamesStreamAll !== undefined && Main.Instance.DatabaseDataState.GamesStreamAll.length > 0 &&
            Main.Instance.RewardShopState.custom.rewardType === 3 && getselected > 1) {
            // console.log ('here')
            if(isAllSelectedNetent(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) === false) {
                alert (Assets.r9)
                return
            }
        } else if (Main.Instance.DatabaseDataState.GamesStreamAll !== undefined && Main.Instance.DatabaseDataState.GamesStreamAll.length > 0 &&
                    Main.Instance.RewardShopState.custom.rewardType === 3 && Main.Instance.RewardShopState.custom.IsFlexibleFreespin === false &&
                    isAllSelectedFreespin(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) === false) {
            alert (Assets.r10)
            return
        } else if (Main.Instance.DatabaseDataState.GamesStreamAll !== undefined && Main.Instance.DatabaseDataState.GamesStreamAll.length > 0 &&
                    Main.Instance.RewardShopState.custom.rewardType === 3 && Main.Instance.RewardShopState.custom.IsFlexibleFreespin === false &&
                    isAllSelectedFreespin(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) === false) {
            alert (Assets.r10)
            return
        } else if (Main.Instance.DatabaseDataState.GamesStreamAll !== undefined && Main.Instance.DatabaseDataState.GamesStreamAll.length > 0 &&
                    Main.Instance.RewardShopState.custom.rewardType === 3 && Main.Instance.RewardShopState.custom.IsFlexibleFreespin === true &&
                    isAllSelectedFlexible(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) === false) {
            alert (Assets.r11)
            return
        } /*else if ((Main.Instance.RewardShopState.custom.rewardType === 1 || Main.Instance.RewardShopState.custom.rewardType === 3) && 
                    Main.Instance.RewardShopState.custom.wageringType === 4) {
            alert ('Wagering type Number Of Bets X Bet Amount cannot be combined with Reward type Fixed Sum, Freespin or Flexible Freespin')
            return
        }*/

        custom.SelectedGameIds = []
        if (Main.Instance.DatabaseDataState.GamesStreamAllSelected) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
            if (Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
                custom.SelectedGameIds.push(Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'])
            }
        }
        }

        custom.Identifier = Main.Instance.RewardShopState.id
        custom.Name = Main.Instance.RewardShopState.name[0]
        custom.TriggerType = 'Gamanza'

        // console.log (custom)
    }
    // console.log (Main.Instance.DatabaseDataState.playersStream)
    const players: number[] = []
    if (Main.Instance.DatabaseDataState.playersStream && Main.Instance.DatabaseDataState.playersStream.length > 0 && Main.Instance.DatabaseDataState.playersStreamSelected) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
        if (Main.Instance.DatabaseDataState.playersStreamSelected[i]) { players.push(Main.Instance.DatabaseDataState.playersStream[i]['player']) }
    }
    }
    // alert (players)
    const Players: string = '{' + players.toString() + '}'
    // alert (Players)

    const rewardShopItem = {
        // fromtime: Main.Instance.RewardShopState.
        id : !Main.Instance.RewardShopState.id ? '' : Main.Instance.RewardShopState.id,
        site_id: Main.Instance.FrontendStoreState.site_id,
        title: !Main.Instance.RewardShopState.name ? ['','','','','','','',''] : Main.Instance.RewardShopState.name,
        info: !Main.Instance.RewardShopState.info ? ['','','','','','','',''] : Main.Instance.RewardShopState.info,
        info2: !Main.Instance.RewardShopState.choose_category ? ['','','','','','','',''] : Main.Instance.RewardShopState.choose_category,
        prices: !Main.Instance.RewardShopState.prices ? ['','','','','','','',''] : Main.Instance.RewardShopState.prices,
        discounts: !Main.Instance.RewardShopState.discounts ? ['','','','','','','',''] : Main.Instance.RewardShopState.discounts,
        price: !Main.Instance.RewardShopState.price ? '' : Main.Instance.RewardShopState.price,
        discount: !Main.Instance.RewardShopState.discount ? '0' : Main.Instance.RewardShopState.discount,
        banner: !Main.Instance.RewardShopState.banner ? '0' : Main.Instance.RewardShopState.banner,
        category: !Main.Instance.RewardShopState.Category ? '0' : 
        (parseInt(Main.Instance.RewardShopState.Category) - 1).toString(),
        image: !Main.Instance.RewardShopState.image ? '' : Main.Instance.RewardShopState.image,
        active : !Main.Instance.RewardShopState.active ? '1' : Main.Instance.RewardShopState.active,
        free_round : !Main.Instance.RewardShopState.free_round ? '2' : Main.Instance.RewardShopState.free_round,
        buyable : !Main.Instance.RewardShopState.buyable ? '1' : Main.Instance.RewardShopState.buyable,
        only_newly_registered : !Main.Instance.RewardShopState.only_newly_registered ? '1' : Main.Instance.RewardShopState.only_newly_registered,
        index: Main.Instance.RewardShopState.mode ? Main.Instance.RewardShopState.index : '0',// Main.Instance.DatabaseDataState.rewardShopCount + 1
        custom: custom === undefined || Object.keys(custom).length === 0 && custom.constructor === Object ? undefined : custom,
        fromtime: Main.Instance.RewardShopState.fromDate ?
            Main.Instance.RewardShopState.fromDate.format(Assets_.dateDisplayFormat) + ' ' + Main.Instance.RewardShopState.fromTime : 
            '1970-01-01 ' + Main.Instance.RewardShopState.fromTime,
        totime: Main.Instance.RewardShopState.toDate ?
            Main.Instance.RewardShopState.toDate.format(Assets_.dateDisplayFormat) + ' ' + Main.Instance.RewardShopState.toTime : 
            '1970-01-01 ' + Main.Instance.RewardShopState.toTime,
        // players
        players: Players,
    }
    // delete(custom['currency'])
    // console.log (rewardShopItem.custom)
    if (parseInt(rewardShopItem.active) === 1) { rewardShopItem.active = 'true' }
    if (parseInt(rewardShopItem.active) === 2) { rewardShopItem.active = 'false' }

    if (parseInt(rewardShopItem.free_round) === 1) { rewardShopItem.free_round = 'true' }
    if (parseInt(rewardShopItem.free_round) === 2) { rewardShopItem.free_round = 'false' }
        
    if (parseInt(rewardShopItem.buyable) === 1) { rewardShopItem.buyable = 'true' }
    if (parseInt(rewardShopItem.buyable) === 2) { rewardShopItem.buyable = 'false' }

    if (parseInt(rewardShopItem.only_newly_registered) === 1) { rewardShopItem.only_newly_registered = 'true' }
    if (parseInt(rewardShopItem.only_newly_registered) === 2) { rewardShopItem.only_newly_registered = 'false' }

    if (!playerName && (!Main.Instance.RewardShopState.name || Main.Instance.RewardShopState.name.length === 0)) {
        alert (Assets.compulsoryName)
    } else if (!playerName && (!Main.Instance.RewardShopState.price || Main.Instance.RewardShopState.price.length === 0)) {
        alert (Assets.compulsoryPrice)
    } else if (!playerName && parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
    } else if (playerName) {
        custom.PlayerId = +playerName
        custom.Percentage = Main.Instance.RewardShopState.rewardFor_saved
        DbRoutes.GiveRewardTest(rewardShopItem.custom)
    } else if (Main.Instance.RewardShopState.mode === false || rewardShopItem.title[0].trim() === Assets.CPC) {
        if (Main.Instance.RewardShopState.mode === true && rewardShopItem.title[0].trim() === Assets.CPC) {
           rewardShopItem.active = 'false'
           rewardShopItem.index = '0'
        }
        DbRoutes.insertRewardShopItem (rewardShopItem, InsertLastPage)
    } else {
        DbRoutes.updateRewardShopItem (rewardShopItem, Update)
    }
}

function ApplyFilterOnlyNewlyRegistered(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_only_newly_registered:text})
    FirstPage()
}

function ApplyFilterBuyable(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_buyable:text})
    FirstPage()
}

function ApplyFilterFreeRound(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_free_round:text})
    FirstPage()
}

function ApplyFilterActive(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_active:text})
    FirstPage()
}

function ApplyFilterCategory(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_category: text})
    FirstPage()
}

function ApplyFilterBanner(text:string) {
    Logic.Type.New(Main.Instance.RewardShopState, {filter_banner:text})
    FirstPage()
}

function getRewardShop() {
    Logic.Type.New(Main.Instance.DatabaseDataState, {rewardShopStream : undefined})
}

function clearFields() {
/*
    let GamesStreamAllSelected = []
    if (Main.Instance.DatabaseDataState.GamesStreamAllSelected && Main.Instance.DatabaseDataState.GamesStreamAll)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) GamesStreamAllSelected.push(false)

    let playersStreamSelected = []
    if (Main.Instance.DatabaseDataState.playersStreamSelected && Main.Instance.DatabaseDataState.playersStream)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStreamSelected.length; i++) playersStreamSelected.push(false)

    if (Main.Instance.DatabaseDataState.GamesStreamAllSelected && Main.Instance.DatabaseDataState.GamesStreamAll && 
        Main.Instance.DatabaseDataState.playersStreamSelected && Main.Instance.DatabaseDataState.playersStream)
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected, playersStreamSelected})
    else if (Main.Instance.DatabaseDataState.playersStreamSelected && Main.Instance.DatabaseDataState.playersStream)
        Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
    else if (Main.Instance.DatabaseDataState.GamesStreamAllSelected && Main.Instance.DatabaseDataState.GamesStreamAll)
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
*/

    Logic.Type.New(Main.Instance.RewardShopState, {
        custom: undefined,
        name : ['','','','','','','',''],
        info: ['','','','','','','',''],
        prices: ['','','','','','','',''],
        discounts: ['','','','','','','',''],        
        choose_category: ['','','','','','','',''],
        image : Assets_.zeroPng,
        mode : false,
        price: '',
        banner: '0',
        Category: '0',
        active: '1',
        // free_round: '2',
        buyable: '1',
        only_newly_registered: '1',
        discount: '',
        index: '1',
        rewardFor_saved: 0,
        free_round_saved: '0',
        index_saved: '0',
        detailsSelected: false,
        playersSelected: false,
        textsSelected: false,
        eligibleSelected: false,
        fromDate: null,
        fromTime: '',
        toDate: null,
        toTime: '',
        // players: []
    })
}

function gotoInsertMode() {
    if (Main.Instance.RewardShopState.price === Main.Instance.RewardShopState.price_saved &&
        // Main.Instance.RewardShopState.name === Main.Instance.RewardShopState.name_saved &&
        // Main.Instance.RewardShopState.info === Main.Instance.RewardShopState.info_saved &&
        Main.Instance.RewardShopState.banner === Main.Instance.RewardShopState.banner_saved &&
        // Main.Instance.RewardShopState.Category === Main.Instance.RewardShopState.Category_saved &&
        Main.Instance.RewardShopState.active === Main.Instance.RewardShopState.active_saved &&
        Main.Instance.RewardShopState.free_round === Main.Instance.RewardShopState.free_round_saved &&
        Main.Instance.RewardShopState.buyable === Main.Instance.RewardShopState.buyable_saved &&
        Main.Instance.RewardShopState.only_newly_registered === Main.Instance.RewardShopState.only_newly_registered_saved &&
        Main.Instance.RewardShopState.discount === Main.Instance.RewardShopState.discount_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.RewardShopState, {offset:(0).toString()})

    getRewardShop()
    Logic.Type.New(Main.Instance.RewardShopState, {pNum: 0})

}

function PrevPage() {
    if (Main.Instance.RewardShopState.pNum > 0) {

        Logic.Type.New(Main.Instance.RewardShopState, {
            offset:((Main.Instance.RewardShopState.pNum - 1) * 
            Main.Instance.RewardShopState.pSize).toString()})
        getRewardShop()

        Logic.Type.New(Main.Instance.RewardShopState,{pNum: Main.Instance.RewardShopState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.RewardShopState.pNum + 1) * Main.Instance.RewardShopState.pSize < 
    Main.Instance.DatabaseDataState.rewardShopCount) {

        Logic.Type.New(Main.Instance.RewardShopState, {
            offset: ((Main.Instance.RewardShopState.pNum + 1) * 
            Main.Instance.RewardShopState.pSize).toString()})
        getRewardShop()
        
        Logic.Type.New(Main.Instance.RewardShopState,{pNum: Main.Instance.RewardShopState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.DatabaseDataState.rewardShopCount <= Main.Instance.RewardShopState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.DatabaseDataState.rewardShopCount === 0) {

            Logic.Type.New(Main.Instance.RewardShopState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 1) / 
                    Main.Instance.RewardShopState.pSize)
            Logic.Type.New(Main.Instance.RewardShopState, {
                offset:(npNum * Main.Instance.RewardShopState.pSize).toString()})
        }

        getRewardShop()

        Logic.Type.New(Main.Instance.RewardShopState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.DatabaseDataState,{rewardShopCount:Main.Instance.DatabaseDataState.rewardShopCount --})
    LastPage()
}

function Update() {
    clearFields()
    getRewardShop()
}

function RecordsPerPageChanged(text: string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.RewardShopState, {pSize:parseInt(text)})
        FirstPage()
    }
}
/*
function ApplyCurrencySelect (text:string) {
    Logic.Type.New(Main.Instance.CurrencyState, {code:text})
}
*/
/*
export function handleEditIndex(rowData: string[], text: string) {

    let rewardShopStream = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStream)
    let rewardShopStreamAll = Object.assign([], Main.Instance.DatabaseDataState.rewardShopStreamAll)
    for (const item of rewardShopStream) if (+item['id'] === +rowData[0]) {item['index'] = +text; break}
    for (const item of rewardShopStreamAll) if (+item['id'] === +rowData[0]) {item['index'] = +text; break}
    Logic.Type.New (Main.Instance.DatabaseDataState, {rewardShopStream, rewardShopStreamAll})
}
*/

function IndexSavedChanged(index_saved: string) {
    if (parseInt(index_saved) >= 0) { Logic.Type.New(Main.Instance.RewardShopState, {index_saved}) }
}
/*
function CategoryIdxChanged(text: string) {
    if (parseInt(text) >= 0) Logic.Type.New(Main.Instance.RewardShopState, {category_idx: +text})
}
*/
function ApplyMasterSelect(text: string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.RewardShopState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getRewardShopCount()
    }
}

/*
function DeleteCategory() {
    if (Main.Instance.DatabaseDataState.languages) {
        let category: string[] = Object.assign ([], Main.Instance.FrontendStoreState.category)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++)
        category[(parseInt(Main.Instance.RewardShopState.category_id) - 1) * 
            Main.Instance.DatabaseDataState.languages.length + i] = ''
        Logic.Type.New(Main.Instance.FrontendStoreState, {category})
        Logic.Type.New(Main.Instance.RewardShopState, {category: ''})
        updateCategory()
    } else alert (Assets.pleaseDefineLanguages)
}
*/


/*
function LanguageContentColor(which: number): string {
    if (Main.Instance.FrontendStoreState.choose_category !== undefined &&
        Main.Instance.FrontendStoreState.choose_category !== null &&
        
        Main.Instance.FrontendStoreState.tb_title !== undefined &&
        Main.Instance.FrontendStoreState.tb_title !== null &&
        
        Main.Instance.FrontendStoreState.tb_content !== undefined &&
        Main.Instance.FrontendStoreState.tb_content !== null &&
        
        Main.Instance.FrontendStoreState.main_title !== undefined &&
        Main.Instance.FrontendStoreState.main_title !== null &&
        
        Main.Instance.FrontendStoreState.choose_category[which] !== undefined && 
        Main.Instance.FrontendStoreState.choose_category[which] !== null && 
        Main.Instance.FrontendStoreState.choose_category[which].length > 0 &&

        Main.Instance.FrontendStoreState.tb_title[which] !== undefined &&
        Main.Instance.FrontendStoreState.tb_title[which] !== null && 
        Main.Instance.FrontendStoreState.tb_title[which].length > 0 &&
        
        Main.Instance.FrontendStoreState.tb_content[which] !== null && 
        Main.Instance.FrontendStoreState.tb_content[which] !== undefined && 
        Main.Instance.FrontendStoreState.tb_content[which].length > 0 &&
        
        Main.Instance.FrontendStoreState.main_title[which] !== null && 
        Main.Instance.FrontendStoreState.main_title[which] !== undefined && 
        Main.Instance.FrontendStoreState.main_title[which].length > 0    
    )
    return '#5cb85c'

    if (Main.Instance.FrontendStoreState.choose_category !== undefined &&
        Main.Instance.FrontendStoreState.choose_category !== null &&

        Main.Instance.FrontendStoreState.tb_title !== undefined &&
        Main.Instance.FrontendStoreState.tb_title !== null &&

        Main.Instance.FrontendStoreState.tb_content !== undefined &&
        Main.Instance.FrontendStoreState.tb_content !== null &&

        Main.Instance.FrontendStoreState.main_title !== undefined &&
        Main.Instance.FrontendStoreState.main_title !== null &&

        (Main.Instance.FrontendStoreState.choose_category[which] === null ||
        Main.Instance.FrontendStoreState.choose_category[which] === undefined || 
        Main.Instance.FrontendStoreState.choose_category[which].length === 0)
        &&        
        (Main.Instance.FrontendStoreState.tb_title[which] === null ||
        Main.Instance.FrontendStoreState.tb_title[which] === undefined || 
        Main.Instance.FrontendStoreState.tb_title[which].length === 0)
        &&
        (Main.Instance.FrontendStoreState.tb_content[which] === null ||
        Main.Instance.FrontendStoreState.tb_content[which] === undefined || 
        Main.Instance.FrontendStoreState.tb_content[which].length === 0)
        &&
        (Main.Instance.FrontendStoreState.main_title[which] === null ||
        Main.Instance.FrontendStoreState.main_title[which] === undefined || 
        Main.Instance.FrontendStoreState.main_title[which].length === 0)
    )
    return '#d9534f'

    return '#ec971f'
}
*/
/*
function updateCategory() {
    let Category: any[] = []
    let Category2: any[] = []
    Category.push ({id: '0', name: Assets.SelectCategory})
    Category2.push ({id: '0', name: Assets.AnyCategory})
    if (Main.Instance.FrontendStoreState.category) {
        for (let i: number = 0 ; i < Main.Instance.FrontendStoreState.category.length; i++) {
            if (i % Main.Instance.DatabaseDataState.languages.length === Main.Instance.DatabaseDataState.language &&
                    Main.Instance.FrontendStoreState.category[i].length > 0) {
                    let id: string = (1+Math.floor(i/Main.Instance.DatabaseDataState.languages.length)).toString()
                    Category.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
                    Category2.push ({id, name: Main.Instance.FrontendStoreState.category[i]})
            }
        }
        Logic.Type.New (Main.Instance.FrontendStoreState, {Category, Category2})
    }
}
*/
function ToggleGS() {
    Logic.Type.New(Main.Instance.Modal,{ModalWagerPlanGames:!Main.Instance.Modal.ModalWagerPlanGames})
    Logic.Type.New(Main.Instance.GamesState, {categorize: false})
}

function TogglePS() {
    Logic.Type.New(Main.Instance.Modal, {ModalOperatorSites: !Main.Instance.Modal.ModalOperatorSites})
}

function getCurrencies(currencyStream: any[], coinvalues: any[]): JSX.Element[] {
    // amount, fs amount, bet level
    const c: JSX.Element[] = []
    let CUSTOM: any
    if (Main.Instance.RewardShopState.custom !== undefined) { CUSTOM = Object.assign ({}, Main.Instance.RewardShopState.custom) } else { CUSTOM = {} }

    if (CUSTOM.Currency === undefined) {
        CUSTOM.Currency = []
        for (const item of currencyStream) { CUSTOM.Currency.push ({Selected: false, Name: item['name']}) }
        Logic.Type.New (Main.Instance.RewardShopState, {custom: CUSTOM})
    }
    // if (Main.Instance.RewardShopState.custom.Currency) console.log(Main.Instance.RewardShopState.custom.Currency)
    if (Main.Instance.RewardShopState.custom.Currency) {
    for (let i: number = 0; i < Main.Instance.RewardShopState.custom.Currency.length; i++) {
        const C: JSX.Element[] = []
        c.push (
            <button
                key = {i.toString() + '_' + 0}
                onClick = {() => {
                    const Custom = Object.assign ({}, Main.Instance.RewardShopState.custom)
                    Custom.Currency[i].Selected = !Custom.Currency[i].Selected
                    Logic.Type.New (Main.Instance.RewardShopState, {custom: Custom})
                    // console.log (Main.Instance.RewardShopState.custom)
                }}
                style = {{fontWeight: 700, width: '60px', marginRight: '10px', marginBottom: '5px', height: '30px', fontSize: '20px', paddingTop: '0px'}}
                className = {Main.Instance.RewardShopState.custom.Currency[i].Selected === true ? 'dbl fl btn btn-xs btn-success' : 'dbl fl btn btn-xs btn-danger'}
            >
                {currencyStream[i]['name']}
            </button>)
        if (Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.Currency[i].Selected) {
            if (Main.Instance.RewardShopState.custom.product === 1 && Main.Instance.RewardShopState.custom.rewardType === 3 && !Main.Instance.RewardShopState.custom.IsFlexibleFreespin) {
            c.push(
            <FormInputNumber
                key = {i.toString() + '_' + 1}
                description = {Assets.FreespinAmount}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.RewardShopState.custom.Currency[i].FreespinAmount === undefined ?
                        '0' : Main.Instance.RewardShopState.custom.Currency[i].FreespinAmount.toString()}
                type = "number" 
                min = "0"
                // icon = {Assets_.faHashtagClass}
                iconText = {Assets.FreespinAmount}
                iconTextS = "14px"
                onChange = {(text) => {
                    const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                    Custom.Currency[i].FreespinAmount = +text
                    Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})
                }}
            />)
            } else if(Main.Instance.RewardShopState.custom.rewardType === 1 /*|| Main.Instance.RewardShopState.custom.rewardType === 2*/ 
            || Main.Instance.RewardShopState.custom.rewardType === 3 || Main.Instance.RewardShopState.custom.rewardType === 4) {
            C.push(
            <FormInputNumber
                key = {i.toString() + '_' + 1}
                description = {Assets.Amount}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h30 w60"
                value = {Main.Instance.RewardShopState.custom.Currency[i].Amount === undefined ?
                        '0' : Main.Instance.RewardShopState.custom.Currency[i].Amount.toString()}
                type = "number" 
                min = "0"
                // icon = {Assets_.faHashtagClass}
                iconText = {Assets.Amount}
                iconTextS = "14px"
                onChange = {(text) => {
                    const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                    Custom.Currency[i].Amount = +text
                    Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})
                }}
            />)
 }

            if(Main.Instance.RewardShopState.custom.product === 1 && Main.Instance.RewardShopState.custom.rewardType === 3 && !Main.Instance.RewardShopState.custom.IsFlexibleFreespin) {
            C.push(
            <FormSelect
                key = {i.toString() + '_' + 2}
                verynarrow = {true}
                verynarrow2 = {true}
                title = {Assets.BetLevel}
                value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.Currency[i].BetLevel === undefined ?
                    '0' : Main.Instance.RewardShopState.custom.Currency[i].BetLevel.toString()}
                data = {coinvalues ? coinvalues : 
                        [{id:'1', name: '0.01'}, {id:'2', name: '0.02'}, {id:'3', name: '0.05'}, {id:'4', name: '0.10'}, {id:'5', name: '0.20'}, {id:'6', name: '0.50'}, {id:'7', name: '1.00'}]}
                ClassName = "form-control"
                o1 = {Assets.Bet + '?'}
                o2 = {Assets.Bet + '?'}
                onChange = {(text) => {
                    const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                    Custom.Currency[i].BetLevel = +text
                    Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})  
                }}
                iconText = "BL"
                // iconTextSize = "14px"
                // icon = {Assets_.facogClass}
            />)
            }
            
            if(Main.Instance.RewardShopState.custom.rewardType === 2) {
                C.push(
                    <FormInputNumber
                        key = {i.toString() + '_' + 3}
                        description = {Assets.MinimumDeposit}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        value = {Main.Instance.RewardShopState.custom.Currency[i].MinDeposit === undefined ?
                                '0' : Main.Instance.RewardShopState.custom.Currency[i].MinDeposit.toString()}
                        type = "number"
                        min = "0"
                        // icon = {Assets_.faHashtagClass}
                        iconText = {Assets.MinDeposit}
                        iconTextS = "14px"
                        onChange = {(text) => {
                            const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                            Custom.Currency[i].MinDeposit = +text
                            Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})
                        }}
                    />)
        
                C.push(
                    <FormInputNumber
                        key = {i.toString() + '_' + 4}
                        description = {Assets.MaximumDeposit}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        value = {Main.Instance.RewardShopState.custom.Currency[i].MaxDeposit === undefined ?
                                '0' : Main.Instance.RewardShopState.custom.Currency[i].MaxDeposit.toString()}
                        type = "number"
                        min = "0"
                        // icon = {Assets_.faHashtagClass}
                        iconText = {Assets.MaxDeposit}
                        iconTextS = "14px"
                        onChange = {(text) => {
                            const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                            Custom.Currency[i].MaxDeposit = +text
                            Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})
                        }}
                    />,
                )

                C.push(
                    <FormInputNumber
                        key = {i.toString() + '_' + 5}
                        description = {Assets.MaximumDepositMatch}
                        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                        ClassName = "h30 w60"
                        value = {Main.Instance.RewardShopState.custom.Currency[i]['MaxDepositMatch'] === undefined ?
                                '0' : Main.Instance.RewardShopState.custom.Currency[i]['MaxDepositMatch'].toString()}
                        type = "number"
                        min = "0"
                        // icon = {Assets_.faHashtagClass}
                        iconText = {Assets.MaxDepositMatch}
                        iconTextS = "14px"
                        onChange = {(text) => {
                            const Custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                            Custom.Currency[i]['MaxDepositMatch'] = +text
                            Logic.Type.New(Main.Instance.RewardShopState, {custom: Custom})
                        }}
                    />,
                )
            }
        }

        c.push (<div key = {i.toString()} className = "row admin-users-container clearfix">{C}</div>)
    }
    }

    return c
}
/*
function GiveCoins () {
    DbRoutes.GiveCoins (Main.Instance.RewardShopState.category_idx)
}
*/
/*
function getFlags(): JSX.Element[] {
    // let FlagsShop: JSX.Element[] = []
    let flags: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.languagesStreamAll === undefined) return flags
    if (Main.Instance.DatabaseDataState.FlagsStreamAllOrder === undefined) return flags

    const flagSource: number = !Main.Instance.DatabaseDataState.languagesStreamAll 
    || Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined :
    parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[Main.Instance.DatabaseDataState.language]['flag'])-1
    const flagsource: string = flagSource === undefined || isNaN(flagSource) || 
        !Main.Instance.DatabaseDataState.FlagsStreamAllOrder? Assets_.zeroPng : 
        Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource]['image']
    const name: string =Main.Instance.DatabaseDataState.languagesAllStream &&
                        Main.Instance.DatabaseDataState.languagesStreamAll &&
                        Main.Instance.DatabaseDataState.languagesStreamAll[Main.Instance.DatabaseDataState.language] &&
                        Main.Instance.DatabaseDataState.languagesStreamAll[
                            Main.Instance.DatabaseDataState.language]['name'] &&
                        Main.Instance.DatabaseDataState.languagesAllStream[
                        Main.Instance.DatabaseDataState.languagesStreamAll[
                            Main.Instance.DatabaseDataState.language]['name'] - 1] ? 
                Main.Instance.DatabaseDataState.languagesAllStream[
                Main.Instance.DatabaseDataState.languagesStreamAll[
                    Main.Instance.DatabaseDataState.language]['name'] - 1]['name'] + ' (' +
                Main.Instance.DatabaseDataState.languagesAllStream[
                Main.Instance.DatabaseDataState.languagesStreamAll[
                    Main.Instance.DatabaseDataState.language]['name'] - 1]['native'] + ')' : ''
    flags.push(
        <a
            title = {name}
            // style = {{top: startOffset.toString() + 'px'}}
            className = "calendar-language-active calendar-language"
            key = {Main.Instance.DatabaseDataState.language.toString()} 
            href = "javascript:void(0)"
            onClick = {() => SelectBanner(Main.Instance.DatabaseDataState.language)}
        >
        {Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.languagesStreamAll[
        Main.Instance.DatabaseDataState.language] && flagsource ?
        <img
            src = {flagsource}
            height = "20"
        /> : void 0}
            <i 
                className = {!Main.Instance.DatabaseDataState.selectLanguage ? 
                Assets.caretDown : Assets.caretUp}
            />
        </a>
    )

    if(Main.Instance.DatabaseDataState.languagesStreamAll && Main.Instance.DatabaseDataState.FlagsStreamAllOrder) {
        for (let j: number = 0; j < Main.Instance.DatabaseDataState.languagesStreamAll.length; j++) {
            if (Main.Instance.DatabaseDataState.languagesStreamAll[j]['active'] === false) continue
            // const flagSource1: number = parseInt(Main.Instance.DatabaseDataState.languagesStreamAll[j]['flag_id'])-1
            // const flagsource1: string = Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']
            const flagSource1: number = !Main.Instance.DatabaseDataState.languagesStreamAll || 
            Main.Instance.DatabaseDataState.languagesStreamAll.length === 0 ? undefined : parseInt(
            Main.Instance.DatabaseDataState.languagesStreamAll[j]['flag'])-1
            const flagsource1: string = flagSource1 === undefined || isNaN(flagSource1) || 
                !Main.Instance.DatabaseDataState.FlagsStreamAllOrder ? Assets_.zeroPng : 
                Main.Instance.DatabaseDataState.FlagsStreamAllOrder[flagSource1]['image']

            const name1: string = Main.Instance.DatabaseDataState.languagesAllStream &&
                                  Main.Instance.DatabaseDataState.languagesStreamAll &&
                                  Main.Instance.DatabaseDataState.languagesStreamAll[j] &&
                                  Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] &&
                                  Main.Instance.DatabaseDataState.languagesAllStream[
                                  Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1] ? 
                Main.Instance.DatabaseDataState.languagesAllStream[
                Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1]['name'] + ' (' +
                Main.Instance.DatabaseDataState.languagesAllStream[
                Main.Instance.DatabaseDataState.languagesStreamAll[j]['name'] - 1]['native'] + ')' : ''

            if (j === Main.Instance.DatabaseDataState.language) {
                continue
            }

            if (Main.Instance.DatabaseDataState.selectLanguage)
            flags.push(
                <a
                    title = {name1}
                    // style = {{top: (startOffset + offset * position).toString() + 'px'}}
                    className = "calendar-language"
                    key = {j.toString()} 
                    href = "javascript:void(0)" 
                    onClick = {() => SelectBanner(j)}
                >
                    <img src = {flagsource1} height = "20"/>
                </a>
            )
        }
    }
    return flags
}
*/

function selectAll(value: boolean) {
    if (Main.Instance.DatabaseDataState.playersStreamSelected) {
        const playersStreamSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.playersStreamSelected)
        for (let i: number = 0; i < playersStreamSelected.length; i++) {
            playersStreamSelected[i] = value
        }
        Logic.Type.New(Main.Instance.DatabaseDataState, {playersStreamSelected})
    }
}

export function RewardShop() {

    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {
            first_time: false,
        })
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    if (Main.Instance.DatabaseDataState.languages !== undefined &&
        Main.Instance.FrontendStoreState.category !== undefined && 
        Main.Instance.FrontendStoreState.Category === undefined) {
        if (!Main.Instance.FrontendStoreState.category || Main.Instance.FrontendStoreState.category.length === 0) {
                const category: string[] = []
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.languages.length; i++) {
                    category.push(Assets_.Uncategorized)
                }
                Logic.Type.New (Main.Instance.FrontendStoreState, {category})
        }
        updateCategory()
    }

    // console.log (Main.Instance.DatabaseDataState.rewardShopStream)
    // console.log (Main.Instance.FrontendStoreState.Category)
    // let selected = 0
    // let Selected = 0
    const selected = getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected)
    const Selected = CountPlayersSelected()
    // console.log ('selected:' + selected)
    let gameName: string = ''
    let coinValues: number[]
    if (selected > 0 && selected < 4) { for (let i: number = 0; i < Main.Instance.DatabaseDataState.GamesStreamAllSelected.length; i++) {
        if (Main.Instance.DatabaseDataState.GamesStreamAllSelected[i] === true) {
            if (gameName.length > 0) { gameName += ', ' }
            gameName += Main.Instance.DatabaseDataState.GamesStreamAll[i][Assets.Name]
            if (Main.Instance.DatabaseDataState.GamesStreamAll[i] && Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues']) {
                coinValues = Main.Instance.DatabaseDataState.GamesStreamAll[i]['CoinValues']
            }
        }
 }
    }
    const coinvalues: Array<{id: string, name: string}> = []
    if (coinValues) { for (let i: number = 0; i < coinValues.length; i++) { coinvalues.push({id: (i + 1).toString(), name: coinValues[i].toString()}) } }
    if (Main.Instance.RewardShopState.custom && coinValues !== undefined && coinValues !== Main.Instance.RewardShopState.custom.CoinValues) {
        const custom = Object.assign ({}, Main.Instance.RewardShopState.custom)
        custom.CoinValues = coinValues
        Logic.Type.New (Main.Instance.RewardShopState, {custom})
    }

    let playerName: string = ''
    if (Selected === 1) { for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStreamSelected.length; i++) {
        if (Main.Instance.DatabaseDataState.playersStreamSelected[i] === true) {
            playerName += Main.Instance.DatabaseDataState.playersStream[i]['player']
            if (Main.Instance.RewardShopState.free_round_saved !== Main.Instance.DatabaseDataState.playersStream[i]['currency']) {
                Logic.Type.New (Main.Instance.RewardShopState, {free_round_saved: Main.Instance.DatabaseDataState.playersStream[i]['currency']})
            }
            break
        }
 }
    }

    const currencyStream: any[] = []
    if (Main.Instance.DatabaseDataState.currenciesStream) {
    for (const item of Main.Instance.DatabaseDataState.currenciesStream) { if (item['active'] === true) { currencyStream.push({id: item['id'], name: item['code']}) } }
    }
    
    // letcustomCloseIcon: JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RewardShopState.fromTime)
    const validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.RewardShopState.toTime)
    // console.log ('here')
    return (<div>
    {<Modal isOpen = {Main.Instance.Modal.ModalWagerPlanGames} close = {() => ToggleGS()}>{GamesSelection()}</Modal>}
    {<Modal isOpen = {Main.Instance.Modal.ModalOperatorSites} close = {() => TogglePS()}>{PlayersSelection()}</Modal>}
    <div className = "clearfix">

        <h3 className = "level fl"> {isUserAdmin() ? Assets.aeRewardShop : Assets.ViewTheRewardShopItem}</h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : void 0}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        />
        : void 0}

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0}
        <Flag/>
        {/*<div className = "calendar-header">{getFlags()}</div>*/}
    </div>

    <div className = "clearfix"/>
        <div className = "row admin-users-container clearfix">
            <br/>
            <div 
                className = {Main.Instance.RewardShopState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
            >        
                <div className = "create-input">
                    <div className = "row admin-users-row" style = {{position: 'relative'}}>
                        <h4>
                            {Main.Instance.RewardShopState.mode ? isUserAdmin() ?
                            Assets.editRewardShopItem : Assets.ViewTheRewardShopItem + ':' :
                            isUserAdmin() ? Assets.addRewardShopItem : Assets.ViewTheRewardShopItem}
                            {Main.Instance.RewardShopState.textsSelected ? <Flags/> : void 0}
                        </h4>

                <button
                    style = {Main.Instance.RewardShopState.detailsSelected ? {} : {
                        background: Main.Instance.RewardShopState.custom !== undefined ? Assets_.lightBlue : '', 
                        color: Main.Instance.RewardShopState.custom !== undefined ? '#333' : '',
                    }}
                    title = {Assets.DetailedSettings}
                    className = {Main.Instance.RewardShopState.detailsSelected ? 'fr btn btn-sm btn-primary mr0 detail' : 'fr btn btn-sm btn-default mr0 detail'}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {
                        detailsSelected: !Main.Instance.RewardShopState.detailsSelected, eligibleSelected: false, playersSelected: false, textsSelected: false, calendarSelected: false})}
                >
                    <i style = {{fontSize: '16px'}} className = {Assets_.faCogsClass}/>
                </button>

                <button
                    title = {Assets.GiveThisRewardToASpecifiedPlayer}
                    className = {Main.Instance.RewardShopState.playersSelected ? 'fr btn btn-sm btn-warning mr specplayer' : 'fr btn btn-sm btn-default mr specplayer'}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {
                        playersSelected: !Main.Instance.RewardShopState.playersSelected, eligibleSelected: false, textsSelected: false, detailsSelected: false, calendarSelected: false})}
                >
                    <FontAwesomeIcon style = {{fontSize: '16px'}} icon = {faUserLock}/>
                </button>

                <button
                    title = {Assets.OfferThisRewardOnlyToSpecifiedPlayers}
                    className = {Main.Instance.RewardShopState.eligibleSelected ? 'fr btn btn-sm btn-primary mr specplayer' : 'fr btn btn-sm btn-default mr specplayer'}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {
                        eligibleSelected: !Main.Instance.RewardShopState.eligibleSelected, textsSelected: false, playersSelected: false, detailsSelected: false, calendarSelected: false})}
                >
                    <FontAwesomeIcon style = {{fontSize: '16px'}} icon = {faUsers}/>
                </button>

                <button
                    style = {Main.Instance.RewardShopState.textsSelected ? {} : {
                        color: Main.Instance.RewardShopState.name[0].length > 0 ? '#333' : '',
                        background: Main.Instance.RewardShopState.name[0].length > 0 ? Assets_.lightBlue : '',
                    }}
                    title = {Assets.TranslationsNameInfo}
                    className = {Main.Instance.RewardShopState.textsSelected ? 'fr btn btn-sm btn-primary mr texts' : 'fr btn btn-sm btn-default mr texts'}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {
                        textsSelected: !Main.Instance.RewardShopState.textsSelected, eligibleSelected: false, playersSelected: false, detailsSelected: false, calendarSelected: false})}
                >
                    <i style = {{fontSize: '16px'}} className = {Assets_.faCountrylgClass}/>
                </button>

                <button
                    title = {Assets.SetRewardDuration}
                    className = {Main.Instance.RewardShopState.calendarSelected ? 'fr btn btn-sm btn-primary mr calendarduration' : 'fr btn btn-sm btn-default mr calendarduration'}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {
                        calendarSelected: !Main.Instance.RewardShopState.calendarSelected, eligibleSelected: false, playersSelected: false, detailsSelected: false, textsSelected: false})}
                >
                    <i style = {{fontSize: '16px'}} className = {Assets_.faCalendarClass}/>
                </button>

                {!Main.Instance.RewardShopState.mode ? void 0 :
                <button
                    title = {Assets.CloneTheReward}
                    className = "btn btn-sm btn-danger fr mr"
                    onClick = {() => {
                        const Name: string[] = Object.assign(Main.Instance.RewardShopState.name)
                        Name[0] = Assets.CPC
                        Logic.Type.New (Main.Instance.RewardShopState, {name: Name})
                        handleInsertUpdate()
                    }}
                >
                    <i style = {{fontSize: '16px'}} className = {Assets_.faCloneClass}/>
                </button>}

                        {Main.Instance.RewardShopState.mode ? 
                        <ButtonIcon 
                            description = {Assets.discardChanges} 
                            click = {() => gotoInsertMode()}
                            ClassName = "btn btn-default btn-sm btn-plus fr btn-nothing-absolute" 
                            icon = {Assets_.faCancelButtonClass}
                        /> : <span/>}

                    </div>

                    <div className = "row admin-users-row">

                <FormInput
                    thick = {Main.Instance.RewardShopState.textsSelected === undefined ? false : Main.Instance.RewardShopState.textsSelected}
                    readOnly = {Main.Instance.RewardShopState.textsSelected === undefined ? false : Main.Instance.RewardShopState.textsSelected}
                    value = {!Main.Instance.RewardShopState.name[Main.Instance.DatabaseDataState.language] ? '' :
                            Main.Instance.RewardShopState.name[Main.Instance.DatabaseDataState.language]} 
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateName(text)} 
                    placeholder = {Assets.pName} 
                    icon = {Assets_.faRewardLgClass}
                />

                <FormInput
                    description = {Assets.Price}
                    value = {!Main.Instance.RewardShopState.price ? '' : Main.Instance.RewardShopState.price} 
                    ClassName = "form-control register-input pr0" 
                    type = "number"
                    width = "9"
                    onChange = {(text) => {
                        if (parseInt(text) > -1) { 
                        Logic.Type.New(Main.Instance.RewardShopState, {price:text})
                        }
                        }
                    } 
                    placeholder = {Assets.Price}
                    icon = {Assets_.faPaymentClass}
                />

                <FormInput
                    description = {Assets.Discount}
                    value = {!Main.Instance.RewardShopState.discount ? '' : Main.Instance.RewardShopState.discount} 
                    ClassName = "form-control register-input pr0" 
                    type = "number"
                    width = "9"
                    onChange = {(text) => {
                        if (parseInt(text) > -1 && parseInt(text) <= 100) { 
                            Logic.Type.New(Main.Instance.RewardShopState, {discount:text})
                        }
                        }
                    } 
                    placeholder = {Assets.Discount}
                    icon = {Assets_.faPercentageClass}
                />

                <FormInput
                    description = {Assets.DiscountedPrice}
                    readOnly = {true}
                    value = {getDiscountedValue(Main.Instance.RewardShopState.price, Main.Instance.RewardShopState.discount)}
                    ClassName = "form-control register-input pr0" 
                    type = "text"
                    width = "8"
                    onChange = {() => void 0}
                    // onChange = {text => {if (parseInt(text) > -1) Logic.Type.New(Main.Instance.RewardShopState, {price:text})}}
                    placeholder = {Assets.Price + ' *'}
                    icon = {Assets_.faPaymentClass}
                    color = "#fff"
                    bg = "#d9534f"
                />

                {IsBuildScandibet() ?
                <FormSelect
                    title = {Assets.SelectCategory}
                    level165 = {true}
                    value = {!Main.Instance.RewardShopState.Category ? '0' : Main.Instance.RewardShopState.Category}
                    data = {Main.Instance.FrontendStoreState.Category}
                    ClassName = "form-control"
                    o1 = {Assets.SelectCategory}
                    onChange = {(text) => Logic.Type.New (Main.Instance.RewardShopState, {Category: text})}
                    icon = {Assets_.faListGroupClass}
                />
                :
                <FormSelect
                    title = {Assets.SelectBanner}
                    level165 = {true}
                    value = {!Main.Instance.RewardShopState.banner ? '0' : Main.Instance.RewardShopState.banner}
                    data = {Main.Instance.DatabaseDataState.bannerStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectBanner} 
                    onChange = {(text) => Logic.Type.New (Main.Instance.RewardShopState, {banner: text})}
                    icon = {Assets_.faPuzzleFixedPieceClass}
                />
                }

                {Main.Instance.RewardShopState.image && Main.Instance.RewardShopState.image !== Assets_.zeroPng ?
                <div 
                    style = {{
                        // background:'#fff',
                        background: `url(${Assets_.checkered})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat', 
                        position: 'relative',
                        borderRadius:(95/Main.Instance.FrontendStoreState.shopItemHeight*15).toFixed(2)+'px',
                        height:'70px',
                        maxWidth:'18%',
                        width:'auto',
                        marginTop:'-40px',
                        float:'left',
                        display: 'block',/*, border:'1px solid #999'*/
                    }}
                >
                    {Main.Instance.RewardShopState.image === undefined || Main.Instance.RewardShopState.image.length < 150 ? void 0 :
                    <span style = {{position: 'absolute', right: '0px', top: '-14px', fontSize: '11px'}}>
                        {(Main.Instance.RewardShopState.image.length/1024).toFixed(1) + ' ' + Assets_.kilobytesShort}
                    </span>}
                    {Main.Instance.RewardShopState.image && Main.Instance.RewardShopState.image.length >= 150 ?
                    <img 
                        src = {Main.Instance.RewardShopState.image} 
                        style = {{
                            height:'100%',
                            width:'auto',
                            zIndex:9998,
                            display:'block',
                            border:'1px solid #999',
                        }}
                    /> : void 0}
                    <button 
                        className = "btn btn-xs btn-nothing"
                        title = {Assets.RemoveImage}
                        style = {{position:'absolute', zIndex:8999, right:'-7px', top:'-5px', marginRight:'0px'}}
                        onClick = {() => removeImage()}
                    >
                        <i className = {Assets_.faCancelClass}/>
                    </button>
                </div>
                :
                <span 
                    style = {{ 
                        float:'left',
                        display:'block',
                        height: '30px',
                        fontSize:'16px',
                        paddingLeft:'12px',
                        paddingRight:'12px',
                        paddingTop:'7px',
                    }}
                    className = "label label-warning"
                >
                    {Assets.NoImage}
                </span>}

                <input
                    accept = "image/png, image/jpg, image/jpeg, image/gif"
                    id = "shopitem-input" 
                    className = "UploadShopItemWrapper" 
                    type = "file" 
                    style = {{float: 'left', marginLeft: '10px', paddingLeft: '0px', width: '91px', marginRight:'0px'}}
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            // every pic has to be set to 97*97 for scandibet, here we assume that the input will be a square
                            // it has to be 129 * 129 for gamanza store
                            const destination: number = IsBuildScandibet() && isPureScandibet() ? 97 : 129
                            // alert (destination)
                            const img = new Image()
                            img.onload = () => {
                                const scale = destination / img.width
                                if (scale < 1) {
                                    const canvas = document.createElement('canvas')
                                    canvas.width = img.width
                                    canvas.height = img.height
                                    canvas.getContext('2d').drawImage(img, 0, 0)    
                                    const resulting_canvas = downScaleCanvas(canvas, scale)
                                    Logic.Type.New (Main.Instance.RewardShopState, {image: resulting_canvas.toDataURL()})
                                } else { Logic.Type.New (Main.Instance.RewardShopState, {image: reader.result as string}) }
                            }
                            img.src = reader.result as string
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => event.target = null}
                />
                
                {IsBuildScandibet() ?
                <RadioButton2
                    value = {!Main.Instance.RewardShopState.only_newly_registered ? '1' :
                            Main.Instance.RewardShopState.only_newly_registered}
                    text = "newly"
                    ClassName = "input-group-radiobutton2 margin-bottom-sm fr" 
                    title = {Assets.r12}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => {Logic.Type.New(Main.Instance.RewardShopState, {only_newly_registered: text})}}
                /> : void 0}

                {IsBuildScandibet() ?
                <RadioButton2
                    value = {!Main.Instance.RewardShopState.buyable ? '1' :
                            Main.Instance.RewardShopState.buyable}
                    text = "multi"
                    ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                    title = {Assets.BuyableDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => {Logic.Type.New(Main.Instance.RewardShopState, {buyable:text})}} 
                /> : void 0}

                {!IsBuildScandibet() ?
                <RadioButton2
                    value = {!Main.Instance.RewardShopState.free_round ? '2' :
                            Main.Instance.RewardShopState.free_round}
                    text = {Assets.FreeRound}
                    ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                    title = {Assets.FreeRoundDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => {Logic.Type.New(Main.Instance.RewardShopState, {free_round:text})}}
                /> : void 0}

                <RadioButton2
                    value = {!Main.Instance.RewardShopState.active ? '1' : Main.Instance.RewardShopState.active} 
                    text = {Assets.Activity}
                    ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                    title = {Assets.ActivityDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => Logic.Type.New(Main.Instance.RewardShopState, {active:text})}
                />
            </div>
{/*
            <div className = "row admin-users-row">
            </div>
*/}

{/*
            <div className = "row admin-users-container clearfix">

                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.RewardShopState.index ? '' : 
                    Main.Instance.RewardShopState.index}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faHashtagClass}
                    onChange = {text => IndexChanged(text)}
                />

                <FormSelect
                    value = {!Main.Instance.RewardShopState.banner ? '0' : 
                            Main.Instance.RewardShopState.banner}
                    data = {Main.Instance.RewardShopState.Banner}
                    ClassName = "form-control"
                    o1 = {Assets.SelectBanner} 
                    o2 = {Assets.SelectBanner}
                    onChange = {text => Logic.Type.New (Main.Instance.RewardShopState, {
                        banner: text})}
                    icon = {Assets_.faPuzzleFixedPieceClass}
                />

            </div>
*/}
            {Main.Instance.RewardShopState.calendarSelected ?
            <div className = "row admin-users-container clearfix verywide" style = {{position: 'relative'}}>
                <button 
                    className = "btn btn-xs btn-nothing"
                    title = {Assets.Close}
                    style = {{position: 'absolute', zIndex: 9995, right: '-15px', top: '-5px', marginRight: '0px', padding: '0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {calendarSelected: !Main.Instance.RewardShopState.calendarSelected})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button>
                <div className = "mr fl mt3" style = {{fontSize: '20px', fontWeight: 700}}>{Assets.Start}</div>
                <div className = "mr fl mt7">({Assets.leaveEmptyIfIndefinite})</div>
                <div className = "mr fl">
                <SingleDatePicker
                    id = "date__1"
                    placeholder = {Assets.Date}
                    date = {Main.Instance.RewardShopState.fromDate}
                    onDateChange = {(date) => Logic.Type.New (Main.Instance.RewardShopState, {fromDate: date})}
                    focused = {Main.Instance.RewardShopState.focusedInput01}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.RewardShopState, {focusedInput01: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    // customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>
                <FormInput
                    validation = {validationFromTime}
                    date = {true}
                    value = {Main.Instance.RewardShopState.fromTime ? Main.Instance.RewardShopState.fromTime : ''}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(0, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
                <div className = "ml mr fl mt3" style = {{fontSize: '20px', fontWeight: 700}}>{Assets.End}</div>
                <div className = "mr fl mt7">({Assets.leaveEmptyIfIndefinite})</div>
                <div className = "mr fl">
                <SingleDatePicker
                    id = "date__2"
                    placeholder = {Assets.Date}
                    date = {Main.Instance.RewardShopState.toDate}
                    onDateChange = {(date) => Logic.Type.New (Main.Instance.RewardShopState, {toDate: date})}
                    focused = {Main.Instance.RewardShopState.focusedInput02}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.RewardShopState, {focusedInput02: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    // customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>
                <FormInput
                    validation = {validationToTime}
                    date = {true}
                    value = {Main.Instance.RewardShopState.toTime ? Main.Instance.RewardShopState.toTime : ''}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(1, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
            </div>
            : void 0}
            {Main.Instance.RewardShopState.eligibleSelected ?
            <div className = "row admin-users-container clearfix verywide" style = {{position: 'relative'}}>
                <button 
                    className = "btn btn-xs btn-nothing"
                    title = {Assets.Close}
                    style = {{position: 'absolute', zIndex: 9995, right: '-15px', top: '-5px', marginRight: '0px', padding: '0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {eligibleSelected: !Main.Instance.RewardShopState.eligibleSelected})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button>

                <div style = {{float: 'left', marginRight: '10px'}}>
                    <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[0]}</div>
                    <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[1]}</div>
                </div>

                <input
                    accept = "text/csv, text/plain" 
                    id = "players-csv" 
                    className = "UploadShopItemWrapper" 
                    type = "file" 
                    style = {{float: 'left', marginRight: '10px'}}
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            const decodedData: string = base64.decode((reader.result as string).replace('data:text/csv;base64,',''))
                            const data: string[] = decodedData.split('\n')
                            const playersStreamSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.playersStreamSelected)
                            for (let i: number = 0; i < playersStreamSelected.length; i++) {
                                playersStreamSelected[i] = false
                                for (const Data of data) { if (Data.trim().length > 0 && +Main.Instance.DatabaseDataState.playersStream[i]['player'] === parseInt(Data.trim())) {
                                    playersStreamSelected[i] = true
                                    break
                                }
                                }
                            }
                            
                            Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => {event.target = null}}
                />

                {Main.Instance.DatabaseDataState.playersStream ?
                <>
                <button
                    className = "btn btn-default btn-sm fl mr"
                    style = {{display: 'block'}}
                    onClick = {() => TogglePS()}
                >
                    {Assets.PlayersSelection}
                </button>

                <button
                    disabled = {Selected === Main.Instance.DatabaseDataState.playersStream.length}
                    className = {Selected === Main.Instance.DatabaseDataState.playersStream.length ? 'btn btn-primary btn-sm fl mr' : 'btn btn-default btn-sm fl mr'}
                    style = {{display: 'block'}}
                    onClick = {() => selectAll(true)}
                >
                    {Assets.SelectAllPlayers}
                </button>

                <button
                    disabled = {Selected === 0}
                    className = {Selected === 0 ? 'btn btn-primary btn-sm fl mr0' : 'btn btn-default btn-sm fl mr0'}
                    style = {{display: 'block'}}
                    onClick = {() => selectAll(false)}
                >
                    {Assets.DeselectAllPlayers}
                </button>

                <span
                    className="label label-success table-label2 mt2"
                    style = {{background: '#5cb85c', display: 'block'}}
                >
                    {Assets.selected}
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined  ? Selected : 0}
                </span>

                <span
                    className = "label label-success table-label2 mt2"
                    style = {{background:'#5cb85c', display: 'block'}}
                >
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined ?
                    (100 * Selected / Main.Instance.DatabaseDataState.playersStream.length).toFixed(3) : 0.0}%
                </span>

                <span
                    className = "label label-success table-label2 mt2"
                    style = {{float: 'left', display: 'block'}}
                >
                    {'players: '}
                    {Main.Instance.DatabaseDataState.playersStream.length ?
                    Main.Instance.DatabaseDataState.playersStream.length : 0}
                </span>                
                </> : void 0}

{/*
                <button onClick = {() => GiveCoins()} className = "btn btn-danger btn-sm ml">Give Coins</button>
                <FormInputNumber 
                    description = "Amount of coins to give to selected player(s)"
                    outerDiv = "rows-per-table input-group fl mr0 ml pl0"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.RewardShopState.category_idx ? '0' : Main.Instance.RewardShopState.category_idx.toString()}
                    type = "number"
                    min = "0"
                    iconText = "Coins"
                    iconTextS = "14px"
                    noIconTextFix = {true}
                    onChange = {text => CategoryIdxChanged(text)}
                />
*/}
            </div> :  void 0}

            {Main.Instance.RewardShopState.playersSelected ?
            <div className = "row admin-users-container clearfix verywide" style = {{position: 'relative'}}>
                <button 
                    className = "btn btn-xs btn-nothing"
                    title = {Assets.Close}
                    style = {{position: 'absolute', zIndex: 9995, right: '-15px', top: '-5px', marginRight: '0px', padding: '0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {playersSelected: !Main.Instance.RewardShopState.playersSelected})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button>

                <button
                    style = {{fontWeight: Selected === 1 ? 700:400, display:'block', padding: Selected === 1 ? '1px 10px' : '4px 10px', fontSize: Selected === 1 ? '17px' : '13px', marginBottom:'5px'}}
                    title = {Assets.PlayerSelection}
                    className = {Selected === 0 ? 'fl btn btn-danger btn-sm ml0 mr0' : Selected === 1 ? 
                                'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-danger btn-sm mr0 ml0 fl'}
                    onClick = {() => TogglePS()}
                >
                    {Selected === 1 ? <i style = {{marginRight: '7px'}} className = {Assets_.faUserDark}/>: void 0}
                    {Selected === 1 ? playerName : Selected === 0 ? 'Select a player' : ' Too many players are selected'}
                </button>
                {Selected === 1 ?
                <span className = "dbl fl label label-primary label-sm ml" style = {{padding: '5px 10px', fontSize: '19px', lineHeight: '19px'}}>
                    <i style = {{marginRight: '7px', fontSize: '17px'}} className = {Assets_.faUserDark}/>
                    {Main.Instance.RewardShopState.free_round_saved ? Main.Instance.RewardShopState.free_round_saved : ''}
                </span> : void 0}
                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.rewardType === 2 ?
                <FormInputNumber
                    description = {Assets.DepositAmount}
                    outerDiv = "rows-per-table input-group fl mr0 ml pl0"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.RewardShopState.index_saved ? '0' : Main.Instance.RewardShopState.index_saved}
                    type = "number"
                    min = "0"
                    iconText = "Deposit"
                    iconTextS = "14px"
                    noIconTextFix = {true}
                    onChange = {(text) => IndexSavedChanged(text)}
                /> : void 0}
                <button
                    disabled = {Selected !== 1 || !isUserAdmin()}
                    style = {{display: 'block', padding: '4px 10px', fontSize: '13px', marginBottom: '5px'}}
                    title = {'Give the reward to a specified player'}
                    className = {'btn btn-danger btn-sm mr0 ml fl'}
                    onClick = {() => handleInsertUpdate(playerName)}
                >
                    {Assets.r13}
                </button>
            </div>
            : void 0}
            {Main.Instance.RewardShopState.detailsSelected ?
            <div className = "row admin-users-container clearfix verywide" style = {{position: 'relative'}}>
                <button 
                    className = "btn btn-xs btn-nothing"
                    title = {Assets.Close}
                    style = {{position: 'absolute', zIndex: 9995, right: '-15px', top: '-5px', marginRight: '0px', padding: '0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {detailsSelected: !Main.Instance.RewardShopState.detailsSelected})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button>
                <FormSelect
                    // level165 = {true}
                    title = "Product type"
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.product === undefined ?
                        '0' : Main.Instance.RewardShopState.custom.product.toString()}
                    data = {[{id: '1', name: 'Casino'}, {id: '2', name: 'Bingo'}, {id: '3', name: 'Sportsbook'}, {id: '4', name: 'ScoutFantasy'}
                            , {id: '5', name: 'PoolBetting'}, {id: '6', name: 'Poker'}, {id: '7', name: 'Lotto'}]}
                    ClassName = "form-control"
                    o1 = {Assets.ProductType + '?'}
                    o2 = {Assets.ProductType + '?'}
                    onChange = {(text) => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.product = +text
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})  
                    }}
                    icon = {Assets_.faCasinoClass + ' fa-fw'}
                />
                {Main.Instance.RewardShopState.custom !== undefined
                && Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
                <FormSelect
                    // level165 = {true}
                    title = {Assets.RewardType}
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.rewardType === undefined ?
                        '0' : Main.Instance.RewardShopState.custom.rewardType.toString()}
                    data = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.product === undefined ?
                            [{id: '1', name: 'Fixed Sum'}, /*{id: '2', name: 'Matched Deposit'},*/ {id: '3', name: 'Free spin'}/*, {id: '4', name: 'Free bet'}*/] :
                            Main.Instance.RewardShopState.custom.product === 1 ?
                            [{id: '1', name: 'Fixed Sum'}, /*{id: '2', name: 'Matched Deposit'},*/ {id: '3', name: 'Free spin'}/*, {id: '4', name: 'Free bet'}*/] :
                            [{id: '1', name: 'Fixed Sum'} /*{id: '2', name: 'Matched Deposit'}, {id: '4', name: 'Free bet'}*/]
                    }
                    ClassName = "form-control"
                    o1 = {Assets.RewardType + '?'}
                    o2 = {Assets.RewardType + '?'}
                    onChange = {(text) => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.rewardType = +text
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})  
                    }}
                    icon = {Assets_.faRewardLgClass}
                /> : void 0}

                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.rewardType === 3 
                && Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product === 1 ?
                <button
                    title = {Assets.r14}
                    style = {{display: 'block', float: 'left', marginTop: '0px', marginLeft: '0px', marginRight: '10px'}}
                    className = {Main.Instance.RewardShopState.custom.IsFlexibleFreespin === true ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'}
                    onClick = {() => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.IsFlexibleFreespin = !Main.Instance.RewardShopState.custom.IsFlexibleFreespin
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})
                    }}
                >
                    {Assets.Flexible}?
                </button>
                : void 0}
                {Main.Instance.RewardShopState.custom &&
                Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
                <FormSelect
                    wide = {true}
                    title = {Assets.WageringType}
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.wageringType === undefined ?
                        '0' : Main.Instance.RewardShopState.custom.wageringType.toString()}
                    data = {/* Main.Instance.RewardShopState.custom.rewardType === 1 || Main.Instance.RewardShopState.custom.rewardType === 3 ?*/
[{id: '1', name: 'Paid Bonus Amount X Factor'}, {id: '2', name: 'Paid Bonus Amount Plus Deposit Amount X Factor'}, {id: '3', name: 'Fixed Amount'}]}
                    ClassName = "form-control"
                    o1 = {Assets.WageringType + '?'}
                    o2 = {Assets.WageringType + '?'}
                    onChange = {(text) => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.wageringType = +text
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})  
                    }}
                    icon = {Assets_.facogClass}
                /> : void 0}

                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
                <FormInputNumber
                    description = {Assets.WageringFactor + ' (' + Assets.WageringRequirement + ')'}
                    outerDiv = "rows-per-table input-group fl mr ml pl0"
                    ClassName = "h30 w50px"
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.WageringFactor === undefined ?
                            '0' : Main.Instance.RewardShopState.custom.WageringFactor.toString()}
                    type = "number" 
                    min = "0"
                    icon = {Main.Instance.RewardShopState.custom.wageringType === 3 ? Assets_.faBorderWidthClass : Assets_.faCancelButtonClass + ' fa-lg'}
                    onChange = {(text) => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.WageringFactor = +text
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})
                    }}
                /> : void 0}

                {Main.Instance.RewardShopState.custom && 
                Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
                <FormInputNumber
                    description = {Assets.ValidDays}
                    outerDiv = "rows-per-table- input-group fl mr ml0 pl0 pr0"
                    ClassName = "h30 w50px"
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.ValidDays === undefined ?
                            '0' : Main.Instance.RewardShopState.custom.ValidDays.toString()}
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faCalendarGClass}
                    // iconText = {Assets.ValidDays}
                    // noIconTextFix = {true}
                    // iconTextS = "13px"
                    onChange = {(text) => {
                        const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.ValidDays = +text
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})
                    }}
                /> : void 0 }

                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.rewardType !== 3 &&
                Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
                    <FormInputNumber
                        description = {Assets.MatchValue + ' %'}
                        outerDiv = "rows-per-table input-group fl mr ml pl0"
                        ClassName = "h30 w50px"
                        value = {!Main.Instance.RewardShopState.rewardFor_saved ? '0' : Main.Instance.RewardShopState.rewardFor_saved.toString()}
                        type = "number"
                        min = "0"
                        icon = {Assets_.faPercentageClass}
                        // iconText = "MV %"
                        // iconTextS = "14px"
                        // noIconTextFix = {true}
                        onChange = {(text) => Logic.Type.New (Main.Instance.RewardShopState, {rewardFor_saved: parseFloat(text)})}
                    />
                : void 0}
{/*
                    <FormInputNumber
                        description = "Minimal Odds"
                        outerDiv = "rows-per-table- input-group fl mr0 ml pl0 pr0"
                        ClassName = "h30 w50px"
                        value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.MinOdds === undefined ?
                                '' : Main.Instance.RewardShopState.custom.MinOdds.toString()}
                        type = "number" 
                        icon = {Assets_.faFootballClass}
                        // iconText = "MO"
                        // noIconTextFix = {true}
                        // iconTextS = "13px"
                        onChange = {text => {
                            if (!isNaN(text as any)) {
                                let custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                                custom.MinOdds = parseFloat(text)
                                Logic.Type.New(Main.Instance.RewardShopState, {custom})
                            }
                        }}
                    />
*/}

                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.product === 3 ?
                    <FormInput
                        description = {Assets.MinimumOdds}
                        value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.MinOdds === undefined ?
                                '' : Main.Instance.RewardShopState.custom.MinOdds.toString()}
                        ClassName = "form-control register-input pr0" 
                        type = "number"
                        width = "15"
                        onChange = {(text) => {
                            if (text.length === 0 || parseFloat(text) >= 1) {
                                const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                                if (text.length === 0) { delete(custom.MinOdds) } else { custom.MinOdds = parseFloat(text) }
                                Logic.Type.New(Main.Instance.RewardShopState, {custom})
                            }
                        }}
                        placeholder = {Assets.MinOdds}
                        icon = {Assets_.faFootballClass}
                    />
                : void 0}

                {Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.product !== 3 ?
                    <FormInputNumber
                        description = {Assets.MinimumAmount}
                        outerDiv = "rows-per-table- input-group fl mr0 ml pl0 pr0"
                        ClassName = "h30 w50px"
                        value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.MinAmount === undefined ?
                                '' : Main.Instance.RewardShopState.custom.MinAmount.toString()}
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faPaymentClass}
                        // iconText = "MO"
                        // noIconTextFix = {true}
                        // iconTextS = "13px"
                        onChange = {(text) => {
                            if (!isNaN(text as any)) {
                                const custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                                custom.MinAmount = parseFloat(text)
                                Logic.Type.New(Main.Instance.RewardShopState, {custom})
                            }
                        }}
                    />
                : void 0}
            </div> : void 0}
            {/*Main.Instance.RewardShopState.detailsSelected && Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.product === 3 ?
            <div>
                <FormInputNumber
                    description = "Minimal Odds"
                    outerDiv = "rows-per-table- input-group fl mr ml0 pl0 pr0"
                    ClassName = "h30 w50px"
                    value = {Main.Instance.RewardShopState.custom === undefined || Main.Instance.RewardShopState.custom.ValidDays === undefined ?
                            '0' : Main.Instance.RewardShopState.custom.ValidDays.toString()}
                    type = "number" 
                    min = "1"
                    // icon = {Assets_.faCalendarGClass}
                    iconText = "Min. odds"
                    noIconTextFix = {true}
                    iconTextS = "13px"
                    onChange = {text => {
                        let custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.MinOdds = parseFloat(text)
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})
                    }}
                />
                </div> : void 0*/}

            {/*console.log (Main.Instance.DatabaseDataState.GamesStreamAll)*/}

            {Main.Instance.RewardShopState.detailsSelected && Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.rewardType === 3 &&
            Main.Instance.DatabaseDataState.GamesStreamAll && Main.Instance.DatabaseDataState.GamesStreamAll.length && Main.Instance.RewardShopState.custom.product === 1 ? 
            <div className = "row admin-users-container clearfix">
                <button
                    style = {{display: 'block', padding: '4px 10px', fontSize: '13px', marginBottom: '5px'}}
                    // title = {'(' + selected + ' of ' + Main.Instance.DatabaseDataState.GamesStreamAll.length + ')'}
                    title = {Assets.GamesSelection}
                    className = {selected === 0 ? 'fl btn btn-danger btn-sm ml0 mr0' : selected === 1 ? 
                                'btn btn-primary btn-sm mr0 ml0 fl' : 'btn btn-success btn-sm mr0 ml0 fl'}
                    onClick = {() => ToggleGS()}
                >
                    {selected > 0 && selected < 4 ? gameName : selected === 0 ? Assets.SelectGames : selected + ' ' + Assets.gamesAreSelected}
                </button>

                {Main.Instance.RewardShopState.custom.rewardType === 3 && selected > 0 ? 
                !Main.Instance.RewardShopState.custom.IsFlexibleFreespin ?
                <span
                    style = {{display: 'block', fontSize: '14px', fontWeight: 700, paddingTop: '8px', paddingBottom: '7px'}}
                    className = {isAllSelectedFreespin(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) ? 
                                'fl label label-success label-sm ml mr0' : 'label label-danger label-sm mr0 ml fl'}
                >
                    {Assets.FreespinSuitability}
                </span>
                :
                <span
                    style = {{display: 'block', fontSize: '14px', fontWeight: 700, paddingTop: '8px', paddingBottom: '7px'}}
                    className = {isAllSelectedFlexible(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) ?
                                'fl label label-success label-sm ml mr0' : 'label label-danger label-sm mr0 ml fl'}
                >
                    {Assets.FlexibleFreespinSuitability}
                </span>
                : void 0}

                {Main.Instance.RewardShopState.custom.rewardType === 3 && selected > 1 ?
                isAllSelectedNetent(Main.Instance.DatabaseDataState.GamesStreamAllSelected, Main.Instance.DatabaseDataState.GamesStreamAll) ?
                <span
                    style = {{display: 'block', fontSize: '14px', fontWeight: 700, paddingTop: '8px', paddingBottom: '7px'}}
                    className = "fl label label-success label-sm ml mr0"
                >
                    {Assets.AllMultipleGamesAreFromNetEnt}
                </span> :
                <span
                    style = {{display: 'block', fontSize: '14px', fontWeight: 700, paddingTop: '8px', paddingBottom: '7px'}}
                    className = "label label-danger label-sm mr0 ml fl"
                >
                    {Assets.AllMultipleGamesAreNotFromNetEnt}
                </span>
                : void 0}
{/*
                <span className = "label label-primary label-sm ml fl" style = {{padding: '5px 10px', fontSize: '19px'}}>
                    {Main.Instance.RewardShopState.free_round_saved ? Main.Instance.RewardShopState.free_round_saved : ''}
                </span>
*/}
            </div> : void 0}
            {/*Main.Instance.RewardShopState.detailsSelected && Main.Instance.RewardShopState.custom ?
            <div className = "row admin-users-container clearfix">

                {Main.Instance.RewardShopState.custom.rewardType === 3 ?
                <div style = {{display: 'block', float: 'left', marginRight: '10px', marginTop: '2px'}}>
                <Switch
                    onClick = {() => {
                        let custom: IAleaccTriggerFreeRound = Object.assign({}, Main.Instance.RewardShopState.custom)
                        custom.IsFlexibleFreespin = !Main.Instance.RewardShopState.custom.IsFlexibleFreespin
                        Logic.Type.New(Main.Instance.RewardShopState, {custom})
                    }}
                    on = {Main.Instance.RewardShopState.custom.IsFlexibleFreespin}
                />
                </div>
                : void 0}
            </div> : void 0*/}
            {Main.Instance.RewardShopState.detailsSelected && Main.Instance.RewardShopState.custom && Main.Instance.RewardShopState.custom.rewardType > 0 &&
            Main.Instance.RewardShopState.custom.product !== undefined && Main.Instance.RewardShopState.custom.product > 0 ?
            getCurrencies(currencyStream, coinvalues) : void 0}
            {Main.Instance.RewardShopState.textsSelected ?
            <div className = "row admin-users-row-area-big" style = {{position: 'relative'}}>
                <button 
                    className = "btn btn-xs btn-nothing"
                    title = {Assets.Close}
                    style = {{position:'absolute', zIndex:9999, right:'-5px', top:'-5px', marginRight:'0px'}}
                    onClick = {() => Logic.Type.New (Main.Instance.RewardShopState, {textsSelected: !Main.Instance.RewardShopState.textsSelected})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button>
                <table className = "table">
                    <thead>
                        <tr>
                            {getRSIHeader()}
                        </tr>
                    </thead>

                    <tbody>
                        {getRSIBody()}
                    </tbody>
                </table>
            </div> : void 0}

            <div className = "row admin-users-container clearfix">
                <ButtonIcon
                    disabled = {!isUserAdmin()}
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.textsSelected ?
                                Main.Instance.RewardShopState.mode ?
                                'btn btn-warning btn-sm btn-plus fr mt50' : 
                                'btn btn-primary btn-sm btn-plus fr mt50' :
                                Main.Instance.RewardShopState.mode ?
                                'btn btn-warning btn-sm btn-plus fr mt' : 
                                'btn btn-primary btn-sm btn-plus fr mt'}
                    Icon = {faSave as IconDefinition}
                    text = {Assets.Save}
                />
{/*
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => test()}
                    ClassName = {Main.Instance.RewardShopState.textsSelected ?
                                Main.Instance.RewardShopState.mode ?
                                'btn btn-warning btn-sm btn-plus fr mt40' : 
                                'btn btn-primary btn-sm btn-plus fr mt40' :
                                Main.Instance.RewardShopState.mode ?
                                'btn btn-warning btn-sm btn-plus fr mt' : 
                                'btn btn-primary btn-sm btn-plus fr mt'}
                    // icon= {Assets_.faUpdateButtonClass} 
                    text = "TEST"
                />
*/}
            </div>

{/*      
            <div className = "row admin-users-container clearfix">
                {Main.Instance.RewardShopState.image && Main.Instance.RewardShopState.image.length > 0 ?
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.mode ? 
                                'btn btn-default btn-sm btn-plus fr mt-30' : 
                                'btn btn-primary btn-sm btn-plus fr mt-30'}
                    icon= {Assets_.faUpdateButtonClass} 
                    text = {Assets.Save}
                />
                :
                <ButtonIcon 
                    description = {Main.Instance.RewardShopState.mode ? 
                                    Assets.saveChanges : 
                                    Assets.updateChanges} 
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.RewardShopState.mode ? 
                                'btn btn-default btn-sm btn-plus fr mt0' : 
                                'btn btn-primary btn-sm btn-plus fr mt0'}
                    icon= {Assets_.faUpdateButtonClass} 
                    text = {Assets.Save}
                />
                }
            </div>
*/}
            </div>
            </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.RewardShopState.pSize ? '' : 
                Main.Instance.RewardShopState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <FormSelect 
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_active ? '0' : 
                        Main.Instance.RewardShopState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrowest"
                o1 = {Assets.Activity_ + '?'}
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {(text) => ApplyFilterActive(text)} 
            />
            {!IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_free_round ? '0' : 
                        Main.Instance.RewardShopState.filter_free_round}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.FREERound}
                o3 = {Assets.FREERoundNot}
                onChange = {(text) => ApplyFilterFreeRound(text)} 
            /> : void 0}

            {IsBuildScandibet() ?
            <FormSelect
                narrow = {true}
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_buyable ? '0' : 
                        Main.Instance.RewardShopState.filter_buyable}
                data = {[0]}
                ClassName = "control-panel-select-narrowest"
                o1 = {Assets.Multiple + '?'}
                o2 = {Assets.Multi}
                o3 = {Assets.Once}
                onChange = {(text) => ApplyFilterBuyable(text)} 
            /> : void 0}

            {IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_only_newly_registered ? '0' : 
                        Main.Instance.RewardShopState.filter_only_newly_registered}
                data = {[0]}
                ClassName = "control-panel-select-narrowest"
                o1 = {Assets.Newly + '?'}
                o2 = {Assets.Newly + '?'}
                o3 = {Assets.Other}
                onChange = {(text) => ApplyFilterOnlyNewlyRegistered(text)} 
            /> : void 0}

            {IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_category ? '0' : 
                        Main.Instance.RewardShopState.filter_category}
                data = {Main.Instance.FrontendStoreState.Category2}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.AnyCategory}
                onChange = {(text) => ApplyFilterCategory(text)} 
            />
            :
            <FormSelect 
                span = {true}
                value = {!Main.Instance.RewardShopState.filter_banner ? '0' : 
                        Main.Instance.RewardShopState.filter_banner}
                data = {Main.Instance.DatabaseDataState.bannerStreamAll2}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                onChange = {(text) => ApplyFilterBanner(text)} 
            />
            }
            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.DatabaseDataState.rewardShopCount ? Main.Instance.DatabaseDataState.rewardShopCount : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showingShort}
                {Main.Instance.DatabaseDataState.rewardShopCount ? Main.Instance.RewardShopState.pNum * 
                Main.Instance.RewardShopState.pSize + 1 : 0}{' - '}
                {Main.Instance.DatabaseDataState.rewardShopCount ? 
                ((Main.Instance.RewardShopState.pNum + 1) * 
                Main.Instance.RewardShopState.pSize > Main.Instance.DatabaseDataState.rewardShopCount ? 
                Main.Instance.DatabaseDataState.rewardShopCount : 
                (Main.Instance.RewardShopState.pNum + 1) * Main.Instance.RewardShopState.pSize) : 0}
            </span>
        <span className = "label label-success table-label">
            {Assets.page}
            {Main.Instance.DatabaseDataState.rewardShopCount ? 
            Main.Instance.RewardShopState.pNum + 1 :0}{' / '}
            {Main.Instance.DatabaseDataState.rewardShopCount ? 
            Math.trunc((Main.Instance.DatabaseDataState.rewardShopCount - 1) /
            Main.Instance.RewardShopState.pSize + 1) : 0}
        </span>
        </div>
        <h3>{Assets.tRewardShop}</h3>
        {Main.Instance.DatabaseDataState.rewardShopStream ?
            IsBuildScandibet() ?
            <ReactTable 
                data = {Main.Instance.DatabaseDataState.rewardShopStream}
                columns = {[
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {width: 250, Header: Assets.Name, accessor: 'name', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                    {Header: Assets.TC, accessor: 'info', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                    {Header: Assets.Info, accessor: 'info2', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                    {width: 100, Header: Assets.DateFrom, accessor: 'fromtime', headerClassName: 'th-left', Cell: (row) => !row.value ? <div>{Assets.indefinite}</div> :
                        <div><div>{row.value && row.value.substring(0, 10) !== '1970-01-01' ? row.value.substring(0, 10) : ''}</div>
                        <div>{row.value && (!(row.original.totime && row.original.totime.substring(0, 10) !== '1970-01-01') || 
                        (row.value.substring(0, 10) !== '1970-01-01')) ? row.value.substring(11,19) : ''}</div></div>},
                    {width: 100, Header: Assets.DateTo, accessor: 'totime', headerClassName: 'th-left', Cell: (row) => !row.value ? <div>{Assets.indefinite}</div> :
                        <div><div>{row.value && row.value.substring(0, 10) !== '1970-01-01' ? row.value.substring(0, 10) : ''}</div>
                        <div>{row.value && (!(row.original.fromtime && row.original.fromtime.substring(0, 10) !== '1970-01-01') || 
                        (row.value.substring(0, 10) !== '1970-01-01')) ? <div>{row.value.substring(11,19)}</div> : ''}</div></div>},
                    {width: 70, Header: Assets.Image, accessor: 'image', headerClassName: 'th-left', Cell: (row) => TableUtils.Image(row.value, row.original.id, isUserAdmin() ? handleRemoveImage:undefined)},
                    {width: 80, Header: Assets.Price, accessor: 'price', headerClassName: 'th-left', Cell: (row) => row.value + ' coins'},
                    {width: 50, Header: Assets.Disc_, accessor: 'discount', headerClassName: 'th-left', Cell: (row) => row.value + ' %'},
                    {width: 80, Header: Assets.D_Price, accessor: 'discountedprice', headerClassName: 'th-left', Cell: (row) => row.value + ' coins'},
                    {width: 120, Header: Assets.Category, accessor: 'category', headerClassName: 'th-left', Cell: (row) => TableUtils.getCategory(row.value)},
                    {width: 70, Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                    {width: 50, Header: <div className = "td-center" title = {Assets.itemCanBeBoughtMultipleTimes}><i className = {Assets_.faSelectClass}/></div>,
                    accessor: 'buyable', Cell: (row) => TableUtils.TB(row, toggleBuyable)},
                    {width: 50, Header: <div className = "td-center" title = {Assets.onlyNewlyRegisteredPlayers}><i className = {Assets_.faRegisteredClass}/></div>,
                    accessor: 'only_newly_registered', Cell: (row) =>TableUtils.TB(row, toggleNewly)},
                    {width: 35, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={100}
            />
            :
            <ReactTable 
                data = {Main.Instance.DatabaseDataState.rewardShopStream}
                columns = {[
                    {Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {Header: Assets.Name, accessor: 'name', headerClassName: 'th-left', Cell: (row) => row.value[Main.Instance.DatabaseDataState.language]},
                    {Header: Assets.Path, accessor: 'path', headerClassName: 'th-left'},
                    {Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                    {Header: Assets.Native, accessor: 'native', headerClassName: 'th-left'},
                    {Header: Assets.Direction, accessor: 'direction', headerClassName: 'th-left'},
                    {Header: Assets.Flag, accessor: 'flag', Cell: (row) => TableUtils.Flag(row.value)},
                    {Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                    {Header: Assets.Freeround, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleFreeRound)},
                    {Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={100}
            />
        : Main.Instance.FrontendStoreState.site_id ? getRewardShopCount() : void 0}
        </div>
    </div>)
}

function updateTime(which: number, text: string) {
    if (text.length > 8) { return }
    for (let i: number = 0; i < text.length; i++) {
    if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && 
        text.charAt(i) !== '5' && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== ':') {
        return
    }
    }

    if (which === 0) { Logic.Type.New (Main.Instance.RewardShopState, {fromTime: text}) } else if (which === 1) { Logic.Type.New (Main.Instance.RewardShopState, {toTime: text}) }
}