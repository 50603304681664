/*
import PlayersModels from '../players/models/playersModels'

type unixTimestamp = number

export {
    MissionStatus,
    ObjectiveType,
    ITaskDefinition,
    IObjectiveDefinition,
    IHiddenReward,
    IMissionDefinition,
    IMissionDefinitionDb,
    IMissionState,
    ITaskState,
    IRewardForCompletedTasks,
}

enum MissionStatus {
    Active = 1,
    Paused,
    TasksCompleted,
    MissionCompleted,
    MissionFailed,
}

enum ObjectiveType {
    BetsCount = 1,
    WinsCount = 2,
    ConsecutiveWins = 3,
    BigWinsCount = 4,
    Points = 5,
    Login = 6,
    Deposit = 7,
    Withdraw = 8,
    AccountVerification = 9,
    ClaimWelcomeBonus = 10,
    ShopPurchase = 11,
    WinRace = 12,
    BetsAmount = 13,
    WinsAmount = 14,
    PlayFromMobile = 15,
}

interface IMissionDefinition {
    id: number
    name: string
    description?: string
    missionOrder: number

    activeFrom: unixTimestamp
    activeTo?: unixTimestamp
    // TODO(ij) introduce amount and unit to property support monthly missions for mychance
    repeatEvery: {amount: number, unit: string}
    maxCompletionCount: number
    isConsecutiveMission: boolean // this is for mission completion order when next repetition cycle starts
    isTaskCompletionConsecutive: boolean // this is for task completion order within one repetition cycle

    tasks: ITaskDefinition[]
    hiddenRewards: IHiddenReward[]
}

interface IMissionDefinitionDb {
    id: number
    name: string
    description?: string
    mission_order: number

    active_from: unixTimestamp
    active_to?: unixTimestamp
    // TODO(ij) introduce amount and unit to property support monthly missions for mychance
    repeat_every: number
    max_completion_count: number
    is_consecutive_mission: boolean // this is for mission completion order when next repetition cycle starts
    is_task_completion_consecutive: boolean // this is for task completion order within one repetition cycle

    tasks: ITaskDefinition[]
    hidden_rewards: IHiddenReward[]
}

interface IMissionState {
    missionId: number
    missionStatus: MissionStatus
    repeatedCyclesCount: number
    timesCompleted: number
    hiddenRewardsAverageBets: PlayersModels.IHiddenRewardsAverageBets[]
    tasksCompleted: boolean[]
    tasksState: ITaskState[],
    rewardsForCompletedTasks: IRewardForCompletedTasks[]
}

interface IRewardForCompletedTasks {
    taskId: number,
    rewardId: number,
}

interface ITaskState {
    betsCount: number
    betsTotal: number
    objectivesCompleted: boolean[]
    objectivesCount: number[]
    objectivesPercentages?: number[]
}

interface IHiddenReward {
    timesPlaying: number
    taskCompleted: number
    rewardId: number
    hiddenRewardId: string
}

interface ITaskDefinition {
    name: string
    rewardId: number
    objectives: IObjectiveDefinition[]
}

interface IObjectiveDefinition {
    name: string
    objectiveType: ObjectiveType
    // TODO(ij) replace minBets, minDeposits, minWithdrawals with minAmounts
    minBets?: ICurrency[]
    minDeposits?: ICurrency[]
    minWithdrawals?: ICurrency[]
    gameFilter?: IGameFilterDefinition
    roundsNeeded: number
    bigWinValue?: number
}

interface IGameFilterDefinition {
    includedGameIds?: string[]
    excludedGameIds?: string[]
    includedGameCategories?: string[]
    excludedGameCategories?: string[]
}

interface ICurrency {
    code: string
    minValue?: number
    maxValue?: number
}
*/
import PlayersModels from '../players/models/playersModels'
import {ILevels} from '../players/models/levelsModels'
import {IAmountMinMaxConstraint} from '../general/generalModel'
type unixTimestamp = number

export {
    MissionStatus,
    ObjectiveType,
    ITaskDefinition,
    IObjectiveDefinition,
    IHiddenReward,
    IMissionDefinition,
    IMissionDefinition2,
    IMissionState,
    ITaskState,
    IRewardForCompletedTasks,
    IRepeatEvery,
}

enum MissionStatus {
    Active = 1,
    Paused,
    TasksCompleted,
    MissionCompleted,
    MissionFailed,
}

// TODO(ij) cleanup objectiveType names to clearly distinguish between counts and amounts
enum ObjectiveType {
    BetsCount = 1,
    WinsCount = 2,
    ConsecutiveWins = 3,
    BigWinsCount = 4,
    Points = 5,
    Login = 6,
    Deposit = 7,
    Withdraw = 8,
    AccountVerification = 9,
    ClaimWelcomeBonus = 10,
    ShopPurchase = 11,
    WinTournament = 12,
    BetsAmount = 13,
    WinsAmount = 14,
    PlayFromMobile = 15,
    ClaimMidweekBoost = 16,
    LevelUp = 17,
}

interface IRepeatEvery {
    unit: string
    amount: number
}

interface IMissionDefinition {
    id: number
    name: string
    description?: string
    mission_order: number
    active_from: unixTimestamp
    active_to?: unixTimestamp
    repeat_every: IRepeatEvery
    max_completion_count: number
    is_consecutive_mission: boolean // this is for mission completion order when next repetition cycle starts
    is_task_completion_consecutive: boolean // this is for task completion order within one repetition cycle
    tasks: ITaskDefinition[]
    hidden_rewards: IHiddenReward[]
}

interface  IMissionDefinition2 {
    id: number
    name: string
    description?: string
    missionOrder: number
    activeFrom: unixTimestamp
    activeTo?: unixTimestamp
    repeatEvery: IRepeatEvery
    maxCompletionCount: number
    isConsecutiveMission: boolean // this is for mission completion order when next repetition cycle starts
    isTaskCompletionConsecutive: boolean // this is for task completion order within one repetition cycle
    tasks: ITaskDefinition[]
    hiddenRewards: IHiddenReward[]
    optIn: IOptinMission
}

interface IOptinMission {
    required: boolean
    optinCost?: number
    minLevel?: ILevels
    maxLevel?: ILevels
    players?: string[]
}

interface IMissionState {
    missionId: number
    missionStatus: MissionStatus
    repeatedCyclesCount: number
    timesCompleted: number
    hiddenRewardsAverageBets: PlayersModels.IHiddenRewardsAverageBets[]
    tasksCompleted: boolean[]
    tasksState: ITaskState[],
    rewardsForCompletedTasks: IRewardForCompletedTasks[]
}

interface IRewardForCompletedTasks {
    taskId: number,
    rewardId: number,
}

interface ITaskState {
    betsCount: number
    betsTotal: number
    objectivesCompleted: boolean[]
    objectivesCount: number[]
    objectivesPercentages?: number[]
}

interface IHiddenReward {
    timesPlaying: number
    taskCompleted: number
    rewardId: number
    hiddenRewardId: string
}

interface ITaskDefinition {
    name: string
    rewardId: number
    objectives: IObjectiveDefinition[]
}

interface IObjectiveDefinition {
    name: string
    objectiveType: ObjectiveType
    minAmounts?: IAmountMinMaxConstraint[]
    gameFilter?: IGameFilterDefinition
    roundsNeeded: number
    bigWinValue?: number
}

interface IGameFilterDefinition {
    includedGameIds?: string[]
    excludedGameIds?: string[]
    includedGameCategories?: string[]
    excludedGameCategories?: string[]
}