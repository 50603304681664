import * as React from 'react'
import Switch from 'react-toggle-switch'
import {RiskLevel} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import * as Config from '../../../Logic/Config'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Assets from '../../Assets'
// import * as Assets_ from '../../Assets_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {toggleTimePeriod, toggleCurrency} from './reusables'

interface State {
    timePeriod?: 'N/A'|'daily'|'weekly'|'monthly',
    defaultAmount?: number,
    currency?: 'N/A'|'CHF'|'EUR'|'USD',
    isDefault?: boolean,
    riskLevel: RiskLevel
}

export default class NetLimitLossRegPopup extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            timePeriod: 'N/A',
            currency: 'N/A',
            isDefault: false,
            riskLevel: RiskLevel.Low,
        }
    }

    public Add() {
        DbRoutes.wsBasicInsert(Config.amNetLimitLossRegistration, {
            timePeriod: this.state.timePeriod,
            defaultAmount: this.state.defaultAmount,
            currency: this.state.currency,
            isDefault: this.state.isDefault,
            riskLevel: this.state.riskLevel,
        })

        Logic.Type.New (Main.Instance.RiskManagementState, {showAddNetLimitLossReg: false})
    }

    public render() {
        return (
            <div className = "clearfix add-setting-popup">
                <div className = "add-setting-main">
                    <div style = {{position: 'relative'}}>
                        <button style = {{position: 'absolute', top: '-20px', right: '-20px', border: '0px', background: 'transparent'}} onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddNetLimitLossReg: false})}>
                            <FontAwesomeIcon style = {{fontSize: '12px'}} icon = {faTimes}/>
                        </button>

                        <h4>{Assets.AddNetLimitLossRegistrationRules}</h4>
                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.TimePeriod}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({timePeriod: toggleTimePeriod(this.state.timePeriod)})}
                            >
                                {this.state.timePeriod}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <div className = "fl mt2 mr">{Assets.DefaultAmount}</div>
                            <input
                                className = "input-popup width60 pr0"
                                onChange = {(e) => this.setState({defaultAmount: +e.target.value})}
                                type = "number"
                                min = "0"
                                value = {this.state.defaultAmount}
                            />
                        </div>

                        <div className = "clearfix mt5 mb">
                            <span className = "mr">{Assets.Currency}</span>
                            <button 
                                className = "btn btn-xs"
                                onClick = {() => this.setState({currency: toggleCurrency(this.state.currency)})}
                            >
                                {this.state.currency}
                            </button>
                        </div>

                        <div className = "clearfix mt5 mb">
                            <Switch className = "fl mr" onClick = {() => this.setState({isDefault: /*!this.state.isDefault*/false})} on = {this.state.isDefault} />
                            <div className = "fl mt2">{Assets.DefaultSetting}</div>
                        </div>

                        <div className = "mb20">
                            <span className = "mr">{Assets.RiskLevel}</span>
                            <button 
                                className = {'btn btn-xs risk risk-' + RiskLevel[this.state.riskLevel]}
                            >
                                {RiskLevel[this.state.riskLevel]}
                                <input 
                                    type = "range"
                                    list = "test"
                                    min = "1"
                                    max = "5"
                                    step = "1"
                                    defaultValue = "1"
                                    onChange = {(e) => this.setState({riskLevel: +e.target.value})}
                                    className = "slider"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button className = "btn btn-danger btn-xs" onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {showAddNetLimitLossReg: false})}>
                            {Assets.Cancel}
                        </button>
                        <button className = "btn btn-success btn-xs" onClick = {() => this.Add()}>
                            {Assets.Confirm}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
