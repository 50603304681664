import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {SectionButtonRG, RiskLevel, DashboardRuleType, PlayerCardButtons} from '../../../Logic/RiskComplianceStore/RiskManagement'
import {faCheck, faTimes, faMoneyCheck, faUser, faUserClock, faMoneyBillAlt, faUserSlash, faTachometerAlt, faHandsHelping} from '@fortawesome/free-solid-svg-icons'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import Switch from 'react-toggle-switch'
import {Custom} from '../../../Logic/Database/DatabaseData'
import {gotoTop, isUserAdmin, isUserRFSpecialist, isUserRGSpecialist, isUserProhibitedToResolveIssue, isUserGDPR} from '../../../Logic/Utils'
import * as TableUtils from '../../Reusables/TableUtils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import CSCallbackResolvePopup from './callbackResolvePopup'
import History from './history'
import NetLimitLossPopup from './netLimitLossPopup'
import {toggleTimePeriod, toggleTimeUnit, toggleCurrency as toggleCurr} from './reusables'
import NetLimitLossRegPopup from './netLimitLossRegPopup';
import {TempExcludeSettingsPopup, TempExcludeOptionsPopup} from './tempExcludePopup';
import { DBDepositMethodsPopup, DBDepositAfterCoolOffPopup, DBCancelledWithdrawalsPopup, DBGamingTimePopup, DBNetLossPopup, DBInitiatedCoolOffsPopup, DBIncreasedNetLossPopup } from './dashboardBackendPopup'
import { SelfAssessmentGiftsPopup, SelfAssessmentCriteriaPopup, SelfAssessmentQuestionairePopup } from './selfAssessmentPopup'
import {SAGifts, SACriteria, SAQuestions, NetLimitLossIncreased, NetLimitLossRegistration, TemporaryExclude, TemporaryExcludeOptions, DashboardBackend, SelfExclusion, TimelineEntrySubType, IssueTypes, IIssue, IIssueHistory} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
import {faLaptopCode} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {riskLevelSlider as RiskLevelSlider, BlacklistCheck, GetUnresolved, GetLegend, InsideTitle, XButton, getPlayerData, Update} from './RiskManagement'
// import {TimelineEntryType, TimelineEntrySubType, IssueTypes, IIssue} from '../../../Logic/Database/DatabaseData'
import {TBN} from '../../Reusables/TableUtils'
import * as Assets_ from '../../Assets_'
import {getControlPanel2, disabledIssueType} from '../MainDashboard/Dashboard'

function dateValidator(date:string) {  // Has to be in form YYYY-MM-DD
    const pattern = new RegExp('^\\d{4}-\\d{2}-\\d{2}$', 'g')
    if (!date.match(pattern)) { return false }
    const validateDate: Date = new Date(date);
    if (Number.isNaN(validateDate.getTime())) { return false }
    return validateDate.toISOString().slice(0, 10) === date
}

function buttonValue(value: string) {
    if (!value) { return false } else if (value.toUpperCase() === 'N/A') { return false } else { return true }
}

export function riskLevelSlider(handleRiskLevelID: number, rowIndex: number, rowValue: RiskLevel) {
    return(
        <input 
            type = "range"
            list = "test"
            min = "1"
            max = "5"
            step = "1"
            value = {rowValue.toString()}
            // defaultValue = {rowValue} isWrong
            onChange = {(e) => xhandleRiskLevel(handleRiskLevelID, rowIndex, +e.target.value)}
            className = "slider"
        />
    )
}

function getResponsibleGamingGroupsButtons(less?: boolean): JSX.Element[] {
    const unresolved: number = less ? 0 : GetUnresolved(TimelineEntrySubType.ResponsibleGaming)
    const buttons: JSX.Element[] = []
    for (let i: number = SectionButtonRG.NetLimitLoss; i <= SectionButtonRG.BlacklistCheck; i++) {
        if (less && i === SectionButtonRG.CSCallbackButton) {continue}
        buttons.push (
            <button
                key = {i.toString()}
                style = {{marginRight: i === SectionButtonRG.CSCallbackButton ? '0px' : ''}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonRG: i})
                    DbRoutes.wsBasicGet(
                        i === SectionButtonRG.SelfAssessment ? Config.amSelfAssessmentGifts :
                        i === SectionButtonRG.NetLimitLoss ? Config.amNetLimitLossIncreased :
                        i === SectionButtonRG.BlacklistCheck ? Config.amBlacklist :
                        i === SectionButtonRG.NetLimitLossRegistration ? Config.amNetLimitLossRegistration :
                        i === SectionButtonRG.TemporaryExclude ? Main.Instance.RiskManagementState.toggleTemporaryExclude ? Config.amTemporaryExclude : Config.amTemporaryExcludeOptions : 
                        i === SectionButtonRG.DashboardBackend ? Config.amDashboardBackend :
                        i === SectionButtonRG.PermanentExclusion ? Config.amSelfExclusion :
                    void 0)
                }}
                className = {i !== SectionButtonRG.CSCallbackButton ?
                            Main.Instance.RiskManagementState.sectionButtonRG === i ? 'dbl btn btn-warning btn-sm fl' : 'btn btn-default btn-sm dbl fl' :
                            Main.Instance.RiskManagementState.sectionButtonRG === i ? 
                            i === SectionButtonRG.CSCallbackButton && unresolved ? 'btn btn-danger btn-sm dbl fr' : 'dbl btn btn-warning btn-sm fr' :
                            'btn btn-default btn-sm dbl fr'
                }
            >
                {Assets_.ResponsibleGamingGroups[i]}
                {i === SectionButtonRG.CSCallbackButton ?
                <>&nbsp;&nbsp;<span className="badge badge-light">{unresolved}</span></>
                : void 0}
            </button>,
        )
    }
    if (less) {
        buttons.push (
            <button
                key = "99"
                style = {{marginRight: '0px'}}
                onClick = {() => {
                    Logic.Type.New (Main.Instance.RiskManagementState, {sectionButtonRG: 99})
                }}
                className = {Main.Instance.RiskManagementState.sectionButtonRG === 99 ?
                            'dbl btn btn-warning btn-sm fl' : 'btn btn-default btn-sm dbl fl'}
            >
                {Assets.Other}
            </button>,
        )
    }
    return buttons
}
/*
export function getUnresolved(issueType: IssueTypes): number {
    let unresolved: number = 0

    if (Main.Instance.DatabaseDataState.issues) {
        for (const item of Main.Instance.DatabaseDataState.issues) {
            if (item.issueType === issueType && item.resolved === false) { unresolved++ }
        }
    }
    return unresolved
}
*/
function getRGDashboardGroupButtons(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    for (let i: number = DashboardRuleType.DepositMethods; i <= DashboardRuleType.IncreasedNetLoss; i++) {
        buttons.push (
            <button
                key = {i.toString()}
                onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {dashboardRuleType: i})}
                className = {Main.Instance.RiskManagementState.dashboardRuleType === i ? 'btn btn-info btn-sm' : 'btn btn-default btn-sm'}
            >
                {Assets_.RGDashboardGroups[i]}
            </button>,
        )
    }
    return buttons
}

// export function getPlayerData(id: number) {
//     let players: any[] = []
//     let Player: any = {}
//     if (Main.Instance.DatabaseDataState.custom &&
//         Main.Instance.DatabaseDataState.custom.general &&
//         Main.Instance.DatabaseDataState.custom.general.players)
//         players = Main.Instance.DatabaseDataState.custom.general.players
//     for (const player of players) {
//         if (player.id === id) Player = player
//     }
//     return Player
// }

export function ResponsibleGaming(less?: boolean/*props: React.Props<any>*/): JSX.Element {
    if (!isUserRGSpecialist()) { return <div><h2>{Assets.ResponsibleGaming}</h2><div>{Assets.pleaseUpgradeUserSecurityRightsForAccessToThisPage}</div></div> }
    DbRoutes.wsReinitialize()
    return (
        <div>
            {less ? void 0 :
            <div className = "clearfix">
                <h2 className = "fl">{Assets.ResponsibleGaming}</h2>
                {GetLegend()}
                <br/>
            </div>}

            <div className = "clearfix" style = {{marginBottom: '10px'}}>
                {getResponsibleGamingGroupsButtons(less)}
            </div>

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.TemporaryExclude ?
            <div>
                {Main.Instance.RiskManagementState.showAddTempExclude?
                Main.Instance.RiskManagementState.toggleTemporaryExclude?
                <TempExcludeSettingsPopup/>
                : <TempExcludeOptionsPopup/>
                : void 0
                }
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.TemporaryExclude, faUserClock)}
                        <br/>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleTemporaryExclude === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => {
                                Logic.Type.New (Main.Instance.RiskManagementState, {toggleTemporaryExclude: true})
                                DbRoutes.wsBasicGet(Config.amTemporaryExclude)
                            }}
                        >
                            {Assets.Settings}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleTemporaryExclude === false ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => {
                                Logic.Type.New (Main.Instance.RiskManagementState, {toggleTemporaryExclude: false})
                                DbRoutes.wsBasicGet(Config.amTemporaryExcludeOptions)
                            }}                        
                        >
                            {Assets.Options}
                        </button>
                        <br/>
                        <br/>
                        {Main.Instance.RiskManagementState.toggleTemporaryExclude ?
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.temporaryExclude ? Main.Instance.DatabaseDataState.temporaryExclude : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 150, Header: Assets.AllowPlay, accessor: 'data.allowPlay', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 0)} on = {row.value} /></div>},
                                        
                                        {width: 150, Header: Assets.AllowDeposit, accessor: 'data.allowDeposit', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 1)} on = {row.value} /></div>},
                                        
                                        {width: 150, Header: Assets.AllowWithdraw, accessor: 'data.allowWithdraw', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 2)} on = {row.value} /></div>},
                                        
                                        {width: 150, Header: Assets.CategoriesSelected, accessor: 'data.categoriesSelected', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 3)} on = {row.value} /></div>},
                                        
                                        {width: 180, Header: Assets.ManualExclusionFinish, accessor: 'data.manualExclusionFinish', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 4)} on = {row.value} /></div>},
                                    
                                        {width: 180, Header: Assets.MinimalExclusionTime, accessor: 'data.minimalExclusionTime', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 25)} type = "number" value = {row.value}/></div>},
                                    
                                        {width: 200, Header: Assets.MinimalExclusionTimeUnit, accessor: 'data.minimalExclusionTimeUnit', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleTimeUnits(row.value, row.index, 10)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},                                    
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddTempExcludeSetting()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteTempExcludeSetting)},
                                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 14)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        :
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.temporaryExcludeOptions ? Main.Instance.DatabaseDataState.temporaryExcludeOptions : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.index + 1}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.Duration, accessor: 'data.duration', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 0)} type = "number" value = {row.value}/></div>},
                                        
                                        {width: 120, Header: Assets.DurationUnit, accessor: 'data.durationUnit', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleTimeUnits(row.value, row.index, 0)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},                                        
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center',Cell: (row) =>
                                        <div>
                                            <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                                                {RiskLevel[row.value]}
                                                {riskLevelSlider(0, row.index, row.value)}
                                            </button>
                                        </div>,
                                        },
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddTempExcludeOptions()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteTempExclude)},
                                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 15)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        }
                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.PermanentExclusion ?
            <div>
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.PermanentExclusion, faUserSlash)}
                        {/*
                        <span
                            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                            className = "label label-primary"
                        >
                            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faUserSlash}/>
                            {Assets.SelfExclusion}
                        </span>
                        */}
                        <br/>
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.selfExclusion ? Main.Instance.DatabaseDataState.selfExclusion : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 250, Header: <span title = {Assets.BanSetExclusionDateDescription}>{Assets.BanSetExclusionDate}</span>, accessor: 'data.date', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input className = "table-input-text pr0" onChange = {(e) => handleText (e.target.value, row.index, 3, true)} type = "text" defaultValue = {row.value} onBlur = {(e) => handleText (e.target.value, row.index, 3)}/></div>},
                                        {width: 200, Header: <span title = {Assets.InitialExclusionPeriodDescription}>{Assets.InitialExclusionPeriod}</span>, accessor: 'data.autoDuration', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input className = "table-input-text pr0" type = "number" min = "0" value = {row.value} onChange = {(e) => handleNumber(e.target.value, row.index, 1)}/></div>},
                                        {width: 200, Header: 'Unit', accessor: 'data.autoDurationUnit', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button 
                                            onClick = {() => toggleTimeUnits(row.value, row.index, 1)}
                                            className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}
                                        >
                                            {row.value}
                                        </button>
                                        </div>},
                                        {width: 200, Header: <span title = {Assets.BanCanBeLiftedAfterPeriodDescription}>{Assets.BanCanBeLiftedAfterPeriod}</span>, accessor: 'data.banCanBeLiftedThreshold', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input className = "table-input-text pr0" type = "number" min = "0" value = {row.value} onChange = {(e) => handleNumber(e.target.value, row.index, 2)}/></div>},
                                        {width: 200, Header: Assets.Unit, accessor: 'data.banCanBeLiftedThresholdUnit', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button 
                                            onClick = {() => toggleTimeUnits(row.value, row.index, 2)}
                                            className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}
                                        >
                                            {row.value}
                                        </button>
                                        </div>},


                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                            <div>
                                                <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                                                    {RiskLevel[row.value]}
                                                    {riskLevelSlider(1, row.index, row.value)}
                                                </button>
                                            </div>,
                                        },
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
/*
                                        {show: isUserAdmin(), width: 50, Header: <button disabled = {true} className = "btn btn-primary btn-xs" onClick = {() => AddSelfExclusion()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteSelfExclusion, true)},
*/
                                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 17)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />

                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.DashboardBackend ?
            <div>
                {Main.Instance.RiskManagementState.showAddDashboardBackend?
                Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.DepositMethods ?   
                <DBDepositMethodsPopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.DepositAfterCoolOff ?   
                <DBDepositAfterCoolOffPopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.CancelledWithdrawals ?   
                <DBCancelledWithdrawalsPopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.GamingTime ?   
                <DBGamingTimePopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.NetLoss ?   
                <DBNetLossPopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.InitiatedCoolOffs ?   
                <DBInitiatedCoolOffsPopup/>
                : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.IncreasedNetLoss ?   
                <DBIncreasedNetLossPopup/>
                : void 0
                :void 0
                }
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.DashboardBackend, faTachometerAlt)}
                        {/*
                        <span
                            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                            className = "label label-primary"
                        >
                            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faTachometerAlt}/>
                            {Assets.DashboardBackend}
                        </span>
                        */}
                        <br/>
                        {/*
                        <div>
                            <h4>{Assets.Settings}</h4>
                            {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend ?
                                <>
                                <div className = "clearfix">
                                    <Switch
                                        className = "dbl fl mr"
                                        onClick = {() => handleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.settings.checkNightJob, 0, 5)}
                                        on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.settings.checkNightJob}
                                    />
                                    <span className = "fl dbl mt3">{Assets.CheckNightJob}</span>
                                </div>
                                </>
                                : void 0
                            }
                        </div>
                        */}
                        <br/>
                        {getRGDashboardGroupButtons()}
                        <br/>
                        <br/>
{/*
                        <br/>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleDashboardBackend === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleDashboardBackend: true})}
                        >
                            {Assets.Settings}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleDashboardBackend === false ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleDashboardBackend: false})}                        
                        >
                            {Assets.Indicators}
                        </button>
                        <br/>
*/}

                        {Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.DepositMethods ?   
                        dashboardTable(DashboardRuleType.DepositMethods)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.DepositAfterCoolOff ?   
                        dashboardTable(DashboardRuleType.DepositAfterCoolOff)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.CancelledWithdrawals ?   
                        dashboardTable(DashboardRuleType.CancelledWithdrawals)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.GamingTime ?   
                        dashboardTable(DashboardRuleType.GamingTime)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.NetLoss ?   
                        dashboardTable(DashboardRuleType.NetLoss)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.InitiatedCoolOffs ?   
                        dashboardTable(DashboardRuleType.InitiatedCoolOffs)
                        : Main.Instance.RiskManagementState.dashboardRuleType === DashboardRuleType.IncreasedNetLoss ?   
                        dashboardTable(DashboardRuleType.IncreasedNetLoss)
                        : void 0
                        }


                        
                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.NetLimitLoss ?
            <div>
                {Main.Instance.RiskManagementState.showAddNetLimitLoss? <NetLimitLossPopup/>: void 0}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.NetLimitLoss, faMoneyBillAlt)}
                        {/*
                        <span
                            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                            className = "label label-primary"
                        >
                            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faMoneyBillAlt}/>
                            {Assets.NetLimitLoss}
                        </span>
                        */}
                        <br/>
                        {/*console.log (Main.Instance.DatabaseDataState.netLimitLossIncreased)*/}
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.netLimitLossIncreased ? Main.Instance.DatabaseDataState.netLimitLossIncreased : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.TimePeriod, accessor: 'data.timePeriod', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleTimePeriods(row.value, row.index, 0)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},
/*                                        
                                        {width: 180, Header: 'High Net Loss Threshold', accessor: 'data.highNetLossThreshold', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input  min = "1" className = "table-input-text pr0" onChange = {e => handleNumber (e.target.value, row.index, 16)} type = "number" value = {row.value}/></div>},
                                        
                                        {width: 80, Header: 'Currency', accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency(row.value, row.index, 1)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},
 */                                       
                                        {width: 150, Header: Assets.SettingTakesPlace, accessor: 'data.settingTakesPlace', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 17)} type = "number" value = {row.value}/></div>},
                                        
                                        {width: 180, Header: Assets.SettingTakesPlaceUnit, accessor: 'data.settingTakesPlaceUnit', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleTimeUnits(row.value, row.index, 7)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},
                                        
                                        {width: 120, Header: Assets.SettingActive, accessor: 'data.settingActive', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 7)} on = {row.value} /></div>},                                        
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                            <div>
                                                <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                                                    {RiskLevel[row.value]}
                                                    {riskLevelSlider(3, row.index, row.value)}
                                                </button>
                                            </div>,
                                        },
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNetLossLimit()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNetLossLimit)},
                                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 12)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.NetLimitLossRegistration ?
            <div>
                {Main.Instance.RiskManagementState.showAddNetLimitLossReg? <NetLimitLossRegPopup/>: void 0}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.NetLimitLossRegistration, faMoneyBillAlt)}
                        {/*
                        <span
                            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                            className = "label label-primary"
                        >
                            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faMoneyBillAlt}/>
                            {Assets.NetLimitLossRegistration}
                        </span>
                        */}
                        <br/>
                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.netLimitLossRegistration ? Main.Instance.DatabaseDataState.netLimitLossRegistration : []}
                            columns = {[
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    ],
                                },
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 100, Header: Assets.TimePeriod, accessor: 'data.timePeriod', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleTimePeriods(row.value, row.index, 1)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},
                                        
                                        {width: 120, Header: Assets.DefaultAmount, accessor: 'data.defaultAmount', headerClassName: 'th-left', Cell: (row) => <div>
                                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 18)} type = "number" value = {row.value}/></div>},
                                        
                                        {width: 80, Header: Assets.Currency, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                                        <button onClick = {() => toggleCurrency(row.value, row.index, 2)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>},
                                        
                                        {width: 120, Header: Assets.DefaultSetting, accessor: 'data.isDefault', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 8)} on = {row.value} /></div>},                                        
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) => <div>
                                        <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                                            {RiskLevel[row.value]}
                                            {riskLevelSlider(4, row.index, row.value)}
                                        </button>
                                        </div>,
                                        },
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
                                {
                                    columns: [
                                        {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNetLossLimitRegistration()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNetLossLimitRegistration)},
                                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 13)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.SelfAssessment ?
            <div>
                {Main.Instance.RiskManagementState.showAddSelfAssessment?
                Main.Instance.RiskManagementState.toggleSelfAssessment === true?
                <SelfAssessmentGiftsPopup/>
                : Main.Instance.RiskManagementState.toggleSelfAssessment === false?
                <SelfAssessmentCriteriaPopup/>
                : Main.Instance.RiskManagementState.toggleSelfAssessment === undefined?
                <SelfAssessmentQuestionairePopup/>
                : void 0
                : void 0
                }
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(false, Assets.SelfAssessment, faMoneyBillAlt)}
{/*                        
                        <span
                            style = {{position: 'absolute', top: '10px', left:'10px', fontSize:'16px', paddingTop:'7px', height:'30px'}}
                            className = "label label-primary"
                        >
                            <FontAwesomeIcon style = {{marginRight: '7px'}} icon = {faMoneyBillAlt}/>
                            {Assets.SelfAssessment}
                        </span>
*/}
                        <br/>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleSelfAssessment === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => {
                                Logic.Type.New (Main.Instance.RiskManagementState, {toggleSelfAssessment: true})
                                DbRoutes.wsBasicGet(Config.amSelfAssessmentGifts)
                            }}
                        >
                            {Assets.Gifts}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleSelfAssessment === false ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => {
                                Logic.Type.New (Main.Instance.RiskManagementState, {toggleSelfAssessment: false})
                                DbRoutes.wsBasicGet(Config.amSelfAssessmentCriteria)
                            }}
                        >
                            {Assets.AssessmentCriteria}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.toggleSelfAssessment === undefined ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                            onClick = {() => {
                                Logic.Type.New (Main.Instance.RiskManagementState, {toggleSelfAssessment: undefined})
                                DbRoutes.wsBasicGet(Config.amSelfAssessmentQuestions)
                            }}
                        >
                            {Assets.Questionnaire}
                        </button>
                        <br/>
                        <br/>
                        {Main.Instance.RiskManagementState.toggleSelfAssessment === true ?

                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.saGifts ? Main.Instance.DatabaseDataState.saGifts : []}
                            columns = {[
                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                {width: 300, Header: Assets.Title, accessor: 'data.title', headerClassName: 'th-left', Cell: (row) => <div>
                                <input className = "table-input-text pr0" onChange = {(e) => handleText (e.target.value, row.index, 0)} type = "text" value = {row.value}/></div>},
                                {width: 60, Header: Assets.Active, accessor: 'data.active', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 9)} on = {row.value ? row.value : true} /></div>},
                                {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddSelfAssessmentGifts()}>{Assets.Add}</button>,
                                accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteSelfAssessmentGifts)},
                                {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 9)},
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />

                        : Main.Instance.RiskManagementState.toggleSelfAssessment === false ?
/*
                            Main.Instance.DatabaseDataState.custom &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriod &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriodUnit &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithin &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithinUnit ?
*/
                            <>
{/*
                                <div>
                                    <span>{Assets.AutomaticallySendNotification}</span>
                                    <input
                                        className = "pr0 mr"
                                        type = "number"
                                        min = "1"
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriod}
                                        onChange = {e => handleNumber(e.target.value, 0, 20)}
                                    />
                                    <button 
                                        onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriodUnit, 0, 8)}
                                        className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriodUnit)}
                                    >
                                        {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.waitPeriodUnit}
                                    </button>
                                </div>
                                <br/>
                                <div>
                                    <span>{Assets.LastDepositRequirementForAutomatedNotification}</span>
                                    <input
                                        className = "pr0 mr"
                                        type = "number"
                                        min = "1"
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithin}
                                        onChange = {e => handleNumber(e.target.value, 0, 21)}
                                    />
                                    <button 
                                        onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithinUnit, 0, 9)}
                                        className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithinUnit)}
                                    >
                                        {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.depositRequirementWithinUnit}
                                    </button>
                                </div>
                                <br/>
                                <div>
                                    <span className = "mr">{Assets.SendNotificationVia}</span>
                                    <button 
                                        onClick = {() => toggleCommunications(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.actionTaken, 0, 0)}
                                        className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.actionTaken)}
                                    >
                                        {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.settings.actionTaken}
                                    </button>
                                </div>
                                <br/>
*/}
                                <h4>{Assets.AssessmentCriteria}</h4>
                                <ReactTable 
                                    data = {Main.Instance.DatabaseDataState.saCriteria ? Main.Instance.DatabaseDataState.saCriteria : []}
                                    columns = {[
                                        {
                                            columns: [
                                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                            ],
                                        },
                                        {
                                            Header: Assets.Conditions,
                                            columns: [
                                                {width: 90, Header: Assets.MinPoints, accessor: 'data.min', headerClassName: 'th-left', Cell: (row) => <div>
                                                <input className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 26)} type = "number" value = {row.value ? row.value : ''}/></div>},
                                                {width: 90, Header: Assets.MaxPoints, accessor: 'data.max', headerClassName: 'th-left', Cell: (row) => <div>
                                                <input className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 27)} type = "number" value = {row.value ? row.value : ''}/></div>},
                                            ],
                                            headerClassName: 'risk-condition',
                                        },
                                        {
                                            Header: Assets.Consequences,
                                            columns: [
                                                {width: 105, Header: Assets.Autoresolved, accessor: 'data.resolvedAutomatically', headerClassName: 'th-center', Cell: (row) =>
                                                <Switch className = "mt4" on = {row.value} onClick = {() => handleSwitch(row.value, row.index, 11)}/>,
                                                },
                                                {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                                <div>
                                                    <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                                                        {RiskLevel[row.value]}
                                                        {riskLevelSlider(5, row.index, row.value)}
                                                    </button>
                                                </div>,
                                                },
                                                {width: 500, Header: Assets.Comment, accessor: 'data.comment', headerClassName: 'th-left', Cell: (row) => <div>
                                                <textarea title = {row.value ? row.value : ''} style = {{height: '50px', lineHeight: '20px'}} className = "table-input-text pr0" onChange = {(e) => handleText (e.target.value, row.index, 2)} value = {row.value}/></div>},
                                                {Header: Assets.ShownToPlayer, accessor: 'data.shownPlayer', headerClassName: 'th-left', Cell: (row) => <div style = {{marginTop: '-10px'}}>
                                                <div>{getLanguageButtons()}</div>
                                                <textarea style = {{height: '40px', marginTop: '-5px', lineHeight:'14px', padding: '5px'}} className = "table-input-text pr0" onChange = {(e) => handleText (e.target.value, row.index, 4)} value = {row.value[Main.Instance.DatabaseDataState.custom.general.languages[Main.Instance.RiskManagementState.language].short]}/></div>},
                                            ],
                                            headerClassName: 'risk-consequence',
                                        },
                                        {
                                            columns: [
                                                {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddSelfAssessmentSettings()}>{Assets.Add}</button>,
                                                accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteSelfAssessmentCriteria)},
                                                {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 10)},
                                            ],
                                        },
                                    ]}
                                    showPaginationTop = {true}
                                    showPaginationBottom = {false}
                                    defaultPageSize={10}
                                />
                            </>
                        /*: void 0*/
                        : Main.Instance.DatabaseDataState.custom &&
                        Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                        Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
                        Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale ?
                        <>
{/*
                            <div>
                                <span className = "mr2">{Assets.AnswersScale}</span>
                                <span className = "mr5px">{Assets.min}</span>
                                <input
                                    disabled = {true}
                                    type = "number"
                                    min = "1"
                                    size = {5}
                                    value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min}
                                    onChange = {e => handleNumber(e.target.value, 0, 23)}
                                    className = "mr2 pr0"
                                    style = {{width: '50px'}}
                                />
                                <span className = "mr5px">{Assets.max}</span>
                                <input
                                    disabled = {true}
                                    type = "number"
                                    min = "1"
                                    size = {5}
                                    value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max}
                                    onChange = {e => handleNumber(e.target.value, 0, 24)}
                                    className = "pr0"
                                    style = {{width: '50px'}}
                                />
                            </div>
                            <br/>
*/}
                            <ReactTable 
                                data = {Main.Instance.DatabaseDataState.saQuestions ? Main.Instance.DatabaseDataState.saQuestions : []}
                                columns = {[
                                    {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                                    /*
                                    {width: 80, Header: 'Question ID', accessor: 'id', headerClassName: 'th-left', Cell: (row) => <div>
                                    <input  min = "1" className = "table-input-text pr0" onChange = {e => handleNumber (e.target.value, row.index, 22)} type = "number" value = {row.value}/></div>},
                                    */
                                    {Header: Assets.QuestionTextDifferentLanguages, accessor: 'data.text', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <div>{getLanguageButtons()}</div>
                                        <textarea
                                            style = {{height: '40px', marginTop: '-5px', lineHeight:'14px', padding: '5px'}}
                                            className = "table-input-text"
                                            onChange = {(e) => handleText (e.target.value, row.index, 1)}
                                            value = {row.value ? row.value[Main.Instance.DatabaseDataState.custom.general.languages[Main.Instance.RiskManagementState.language].short] : ''}
                                        />
                                    </div>},

                                    {width: (10 + 40 * (Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max - Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min + 1)) > 350 ? (10 + 40 * (Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max - Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min + 1)) : 350,
                                    className: 'td-center', Header: Assets.AnswersAndRatingPointsGivenForEachAnswer, headerClassName: 'th-left', accessor: 'data.points',
                                    Cell: (row) => <div><div>{getPoints(row.value, row.index)}</div><div>{getRatingPoints(row.original.data.ratingPoints, row.index)}</div></div>},
                                    {width: 60, Header: Assets.Active, accessor: 'data.active', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                                    <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 10)} on = {row.value} /></div>},
                                    {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddSelfAssessmentQuestions()}>{Assets.Add}</button>,
                                    accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteSelfAssessmentQuestions, true)},
                                    {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 11)},
                                ]}
                                showPaginationTop = {true}
                                showPaginationBottom = {false}
                                defaultPageSize={10}
                            />
                        </>
                        : void 0
                        }
                        {/*console.log (Main.Instance.DatabaseDataState.custom)*/}
                    </div>
                </div>
            </div> : void 0
            }

            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.BlacklistCheck ?
            <div>
                {/*<h3>{Assets.largeValueWithdrawal}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                    >
                        {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                        {InsideTitle(true,Assets.BlacklistCheck, faMoneyCheck)}

                        <ReactTable 
                            data = {Main.Instance.DatabaseDataState.blacklist ? Main.Instance.DatabaseDataState.blacklist : []}
                            columns = {[
/*
                                {
                                    columns: [
                                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>}
                                    ]
                                },
 */
/*
                                {
                                    Header: Assets.Conditions,
                                    columns: [
                                        {width: 60, accessor: 'data.checkAtRegistration', Header: <div title={Assets.BlackListStrings[0]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[0]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 5, row.index)},
                                        {width: 60, accessor: 'data.checkAtLogin', Header: <div title={Assets.BlackListStrings[1]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[1]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 6, row.index)},
                                        {width: 60, accessor: 'data.allowCreateAccount', Header: <div title={Assets.BlackListStrings[2]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[2]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 7, row.index)},
                                        {width: 60, accessor: 'data.allowPlay', Header: <div title={Assets.BlackListStrings[3]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[3]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 8, row.index)},
                                        {width: 60, accessor: 'data.allowDeposit', Header: <div title={Assets.BlackListStrings[4]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[4]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 9, row.index)},
                                        {width: 60, accessor: 'data.allowWithdraw', Header: <div title={Assets.BlackListStrings[5]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[5]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 10, row.index)},
                                        {width: 60, accessor: 'data.allowLogin', Header: <div title={Assets.BlackListStrings[6]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[6]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 11, row.index)},
                                        {width: 60, accessor: 'data.recieveMarketingPromotion', Header: <div title={Assets.BlackListStrings[7]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[7]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 12, row.index)},
                                        {width: 60, accessor: 'data.checkBlackList', Header: <div title={Assets.BlackListStrings[8]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[8]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 13, row.index)},
                                        {width: 60, accessor: 'data.checkNightJob', Header: <div title={Assets.BlackListStrings[9]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[9]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 14, row.index)},
                                        {width: 60, accessor: 'data.allowAccountSuspension', Header: <div title={Assets.BlackListStrings[10]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[10]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
                                        BlacklistCheck(row.value, 15, row.index)},
                                    ],
                                    headerClassName: 'risk-condition',
                                },
                                {
                                    Header: Assets.Consequences,
                                    columns: [
                                        {width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
                                        <button
                                            disabled = {true}
                                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                                        >
                                            {RiskLevel[row.value]}
                                            {RiskLevelSlider(2, row.index, row.value, true)}
                                        </button>},
                                        {width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
                                        <div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 26)} on = {row.value ? row.value: false} /></div>},
                                    ],
                                    headerClassName: 'risk-consequence',
                                },
*/

{width: 60, accessor: 'data.checkAtRegistration', Header: <div title={Assets.BlackListStrings[0]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[0]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 5, row.index)},
{width: 60, accessor: 'data.checkAtLogin', Header: <div title={Assets.BlackListStrings[1]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[1]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 6, row.index)},
{width: 60, accessor: 'data.allowCreateAccount', Header: <div title={Assets.BlackListStrings[2]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[2]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 7, row.index)},
{width: 60, accessor: 'data.allowPlay', Header: <div title={Assets.BlackListStrings[3]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[3]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 8, row.index)},
{width: 60, accessor: 'data.allowDeposit', Header: <div title={Assets.BlackListStrings[4]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[4]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 9, row.index)},
{width: 60, accessor: 'data.allowWithdraw', Header: <div title={Assets.BlackListStrings[5]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[5]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 10, row.index)},
{width: 60, accessor: 'data.allowLogin', Header: <div title={Assets.BlackListStrings[6]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[6]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 11, row.index)},
{width: 60, accessor: 'data.recieveMarketingPromotion', Header: <div title={Assets.BlackListStrings[7]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[7]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 12, row.index)},
{width: 60, accessor: 'data.checkBlackList', Header: <div title={Assets.BlackListStrings[8]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[8]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 13, row.index)},
{width: 60, accessor: 'data.checkNightJob', Header: <div title={Assets.BlackListStrings[9]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[9]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 14, row.index)},
{width: 60, accessor: 'data.allowAccountSuspension', Header: <div title={Assets.BlackListStrings[10]} style = {{height: '350px'}} className = "pr"><div style = {{position: 'absolute', top: '160px', left: '-150px', width: '350px', transform: 'rotate(-90deg)'}}>{Assets.BlackListStrings[10]}</div></div>, headerClassName: 'th-left', Cell: (row) =>
BlacklistCheck(row.value, 15, row.index)},
{width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) =>
<button
    disabled = {true}
    className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
>
    {RiskLevel[row.value]}
    {RiskLevelSlider(2, row.index, row.value, true)}
</button>},
{width: 150, Header: Assets.AccountSuspension, accessor: 'data.accountSuspension', headerClassName: 'th-left', Cell: (row) =>
<div className = "td-left"><Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 26)} on = {row.value ? row.value: false} /></div>},


                                {
                                    columns: [
/*
                                        {show: isUserAdmin(), width: 50, Header: <button disabled = {true} className = "btn btn-primary btn-xs" onClick = {() => AddWithdrawalAlert()}>{Assets.Add}</button>,
                                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteWithdrawalAlert, true)},
*/
                                        {show: isUserAdmin() || isUserRFSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 4)},
                                    ],
                                },
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={1}
                        />
                        <br/>
                    </div>
                </div>
            </div>
            : void 0}


            {Main.Instance.RiskManagementState.sectionButtonRG === SectionButtonRG.CSCallbackButton ?
            <div>
            {Main.Instance.RiskManagementState.history ? <History/> : void 0}
            {Main.Instance.RiskManagementState.showAddCSCallbackModal ? <CSCallbackResolvePopup/>: void 0}
            <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                <div 
                    className = "clearfix test-site-data2 light_grey" 
                    style = {{marginTop:'0px !important', paddingTop:'30px', paddingBottom:'10px'}}
                >
                    {XButton(Main.Instance.RiskManagementState,  {sectionButtonRG: SectionButtonRG.Nothing})}
                    {InsideTitle(false, Assets.ResponsibleGamingIssues, faHandsHelping)}
                    {/*
                    <br/>
                    <button
                        className = {Main.Instance.RiskManagementState.toggleCSCallback === true ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                        onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleCSCallback: true})}
                    >
                        {Assets.CallbackList}
                    </button>
                    <button
                        className = {Main.Instance.RiskManagementState.toggleCSCallback === false ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'} 
                        onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {toggleCSCallback: false})}                        
                    >
                        {Assets.Settings}
                    </button>
                    */}
                    {Main.Instance.RiskManagementState.toggleCSCallback?
                    <>
                        <button
                            style = {{marginRight: '0px'}}
                            className = {Main.Instance.RiskManagementState.filterResolved === undefined ? 'btn btn-primary btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: undefined})}
                        >
                            {Assets.AllIssues}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.filterResolved === true ? 'btn btn-success btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: true})}
                        >
                            {Assets.ResolvedIssues}
                        </button>
                        <button
                            className = {Main.Instance.RiskManagementState.filterResolved === false ? 'btn btn-warning btn-xs fr' : 'btn btn-default btn-xs fr'} 
                            onClick = {() => Logic.Type.New (Main.Instance.RiskManagementState, {filterResolved: false})}                        
                        >
                            {Assets.UnresolvedIssues}
                        </button>
                    </>
                    : void 0
                    }
                    <br/>
                    <br/>
                    {/*console.log (Main.Instance.DatabaseDataState.custom)*/}
                    {getControlPanel2(true)}
                    {/*Main.Instance.RiskManagementState.toggleCSCallback ?*/
                    getIssuesTable(false, Main.Instance.DatabaseDataState.issues2, TimelineEntrySubType.ResponsibleGaming)
                    /*:
                    Main.Instance.DatabaseDataState.custom &&
                        Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                        Main.Instance.DatabaseDataState.custom.responsibleGaming.issues ?
                        <>    
                            <div className = "clearfix mt20">
                                <span className = "fl dbl mt3 mr5px">{Assets.CallbackOptionActive}</span>
                                <Switch
                                    className = "dbl fl"
                                    onClick = {() => handleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active, 0, 12)}
                                    on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active}
                                />
                            </div>
                            <div className = "mt5">
                                <span>{Assets.CustomerSupportEmail}</span>
                                <input
                                    type = "text"
                                    value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.CSEmail}
                                    onChange = {e => handleText(e.target.value, 0, 6)}
                                    className = "pr0"
                                    style = {{width: '300px'}}
                                />
                            </div>
                            <div className = "mt5">
                                <span>{Assets.SetRiskLevelCallback}</span>
                                <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}>
                                    {RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}
                                    {riskLevelSlider(6, 0, Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel)}
                                </button>

                            </div>
                        </>
                        : void 0
                    */}
                </div>
            </div>
        </div> : void 0
            }
        </div>
    )
}

export function getLanguageButtons(): JSX.Element[] {
    const buttons: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
        buttons.push (
            <button
                type = "button"
                className = {Main.Instance.RiskManagementState.language === i ? 'btn btn-primary btn-xs mr0 br0' : 'btn btn-default btn-xs mr0 br0'}
                onClick = {() => Logic.Type.New(Main.Instance.RiskManagementState, {language: i})}
            >
                {Main.Instance.DatabaseDataState.custom.general.languages[i].locale + ', ' + Main.Instance.DatabaseDataState.custom.general.languages[i].name}
            </button>,
        )
    }
    }

    return buttons
}

/*
function moveQuestion(moveUp: boolean, row: ReactTable.Row) {
    const index: number = moveUp ? -1 : 1
    const saQuestions: SAQuestions[] = Object.assign ([], Main.Instance.DatabaseDataState.saQuestions)
    const text: string[] = Object.assign([], saQuestions[row.index].data.text)
    const points: number[] = Object.assign([], saQuestions[row.index].data.points)
    const active: boolean = Object.assign([], saQuestions[row.index].data.active)

    saQuestions[row.index].data.text = saQuestions[row.index + index].data.text
    saQuestions[row.index].data.points = saQuestions[row.index + index].data.points
    saQuestions[row.index].data.active = saQuestions[row.index + index].data.active

    saQuestions[row.index + index].data.text = text
    saQuestions[row.index + index].data.points = points
    saQuestions[row.index + index].data.active = active

    Logic.Type.New(Main.Instance.DatabaseDataState, {saQuestions})

}
*/
/*
function changePoints (index: number, i: number, value: string) {
    if (+value >= 0 && +value < 100) {
        const saQuestions: SAQuestions[] = Object.assign ([], Main.Instance.DatabaseDataState.saQuestions)
        saQuestions[index].data.points[i] = +value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saQuestions})
    }
}
*/
function changeRatingPoints(index: number, i: number, value: string) {
    if (+value >= 0 && +value < 100) {
        const saQuestions: SAQuestions[] = Object.assign ([], Main.Instance.DatabaseDataState.saQuestions)
        if (!saQuestions[index].data.points[i]) {
            saQuestions[index].data.points.push(i)
        }
        saQuestions[index].data.ratingPoints[i] = +value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saQuestions})        
    }
}

function getPoints(value: number[], index: number): JSX.Element[] {
    const points: JSX.Element[] = []
    // console.log(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min)

    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.responsibleGaming && Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
        Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale) {
    for (let i: number = Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min; i <= Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max ; i++) {
        points.push(
            <input
                style = {{textAlign: 'center'}}
                title = {Assets.Answer + ' ' + i + ' (' + Assets.Points.toLowerCase() + ')'}
                disabled = {true}
                min = "0"
                max = "99"
                type = "text"
                key = {i.toString()}
                value = {i}
                className = "points-question"
                // onChange = {e => changePoints(index, i, e.target.value)}
            />,
        )
    }
    }
    return points
}

function getRatingPoints(value: number[], index: number): JSX.Element[] {

    const points: JSX.Element[] = []
   

    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.responsibleGaming && Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment &&
        Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale) {
    for (let i: number = 0; i<=(Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max -Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min); i++) {
        points.push(
            <input
                title = {Assets.Answer + ' ' + i + ' (' + Assets.RatingPoints.toLowerCase() + ')'}
                min = "0"
                max = "99"
                type = "number"
                key = {i.toString()}
                value = {value !== undefined && value[i] !== undefined ? value[i].toString().charAt(0) === '0' && value[i].toString().charAt(1) === '.' ? value[i].toString().substring(1, value[i].toString().length) : value[i].toString() : ''}
                className = "points-answer"
                onChange = {(e) => changeRatingPoints(index, i, e.target.value)}
            />,
        )
    }
}
    
    return points
}

/*
function changeValues(index: number, which: number) {
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (custom.responsibleGaming && custom.responsibleGaming.selfAssessment && custom.responsibleGaming.selfAssessment.questionnaire &&
        custom.responsibleGaming.selfAssessment.questionnaire.questions && custom.responsibleGaming.selfAssessment.questionnaire.questions[index] &&
        custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points) {
        const points: number[] = []
        if (which === 0) {
            for (let i: number = custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points.length - 1; i >= 0; i--) {
                points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i])
            }
        } else if (which === 13) {
            for (let i: number = 1 ; i < custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points.length; i++) {
                points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i])
            }
            points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[0])
        } else if (which === 14) {
            points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points.length - 1])
            for (let i: number = 1 ; i < custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points.length; i++) {
                points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i - 1])
            }
        } else {
            for (let i: number = 0; i < custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points.length; i++) {
                if (which === 1) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] * 2)
                else if (which === 2) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] * 3)
                else if (which === 3) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] * 5)
                else if (which === 4) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] / 2)
                else if (which === 5) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] / 3)
                else if (which === 6) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] / 5)
                else if (which === 7) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] + 1)
                else if (which === 8) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] + 2)
                else if (which === 9) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] + 3)
                else if (which === 10) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] - 1)
                else if (which === 11) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] - 2)
                else if (which === 12) points.push (custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points[i] - 3)

                if (points[points.length - 1] < 0 || points[points.length - 1] - Math.floor (points[points.length - 1]) !== 0) return
            }
        }
        custom.responsibleGaming.selfAssessment.questionnaire.questions[index].points = points
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/
/*
function getMultipliers(value: number[], index: number): JSX.Element[] {
    const multipliers: JSX.Element[] = []
    multipliers.push(<button onClick = {() => changeValues(index, 0)} key = "reverse" className = "btn btn-primary btn-xs mr0 br0"><FontAwesomeIcon icon = {faExchangeAlt}/></button>)
    multipliers.push(<button onClick = {() => changeValues(index, 13)} key = "<" className = "btn btn-primary btn-xs mr0 br0"><i className = {Assets_.faPrevButtonClass}/></button>)
    multipliers.push(<button onClick = {() => changeValues(index, 14)} key = ">" className = "btn btn-primary btn-xs mr br0"><i className = {Assets_.faNextButtonClass}/></button>)
    
    multipliers.push(<button onClick = {() => changeValues(index, 1)} key = "x2" className = "btn btn-warning btn-xs mr0 br0">x2</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 2)} key = "x3" className = "btn btn-warning btn-xs mr0 br0">x3</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 3)} key = "x5" className = "btn btn-warning btn-xs mr br0">x5</button>)
    
    multipliers.push(<button onClick = {() => changeValues(index, 4)} key = "y2" className = "btn btn-danger btn-xs mr0 br0">/2</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 5)} key = "y3" className = "btn btn-danger btn-xs mr0 br0">/3</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 6)} key = "y5" className = "btn btn-danger btn-xs mr br0">/5</button>)

    multipliers.push(<button onClick = {() => changeValues(index, 7)} key = "z2" className = "btn btn-success btn-xs mr0 br0">+1</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 8)} key = "z3" className = "btn btn-success btn-xs mr0 br0">+2</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 9)} key = "z4" className = "btn btn-success btn-xs mr br0">+3</button>)

    multipliers.push(<button onClick = {() => changeValues(index, 10)} key = "w2" className = "btn btn-info btn-xs mr0 br0">-1</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 11)} key = "w3" className = "btn btn-info btn-xs mr0 br0">-2</button>)
    multipliers.push(<button onClick = {() => changeValues(index, 12)} key = "w4" className = "btn btn-info btn-xs mr0 br0">-3</button>)

    return multipliers
}
*/
export function xhandleRiskLevel(id: number, index: number, value: number) {

    if (id === 0) {
        const temporaryExcludeOptions: TemporaryExcludeOptions[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExcludeOptions)
        temporaryExcludeOptions[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExcludeOptions})
    } else if (id === 1) {
        const selfExclusion: SelfExclusion[] = Object.assign ([], Main.Instance.DatabaseDataState.selfExclusion)
        selfExclusion[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {selfExclusion})
    } else if (id === 2) {
        const dashboardBackend: DashboardBackend[] = Object.assign ([], Main.Instance.DatabaseDataState.dashboardBackend)
        dashboardBackend[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {dashboardBackend})
    } else if (id === 3) {
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
        netLimitLossIncreased[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})
    } else if (id === 4) {
        const netLimitLossRegistration: NetLimitLossRegistration[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossRegistration)
        netLimitLossRegistration[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossRegistration})
    } else if (id === 5) {
        const saCriteria: SACriteria[] = Object.assign ([], Main.Instance.DatabaseDataState.saCriteria)
        saCriteria[index].data.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saCriteria})
    } else if (id === 6) {
        const custom: Custom = Object.assign ([], Main.Instance.DatabaseDataState.custom)
        custom.responsibleGaming.issues.riskLevel = value
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function toggleTimeUnits(unit: string, index: number, id: number) {
    const NewUnitMHDM: 'min'|'hour'|'day'|'month' = toggleTimeUnit(unit).NewUnitMHDM
    const NewUnitMH: 'min'|'hour' = toggleTimeUnit(unit).NewUnitMH
    if (id === 0) {
        const temporaryExcludeOptions: TemporaryExcludeOptions[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExcludeOptions)
        temporaryExcludeOptions[index].data.durationUnit = NewUnitMHDM
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExcludeOptions})
    } else if (id === 1 || id === 2) {
        const selfExclusion: SelfExclusion[] = Object.assign ([], Main.Instance.DatabaseDataState.selfExclusion)
        if (id === 1) { selfExclusion[index].data.autoDurationUnit = NewUnitMHDM } else if (id === 2) { selfExclusion[index].data.banCanBeLiftedThresholdUnit = NewUnitMHDM }
        Logic.Type.New(Main.Instance.DatabaseDataState, {selfExclusion})
    } else if (id === 3 || id === 4 || id === 5 || id === 6) {
        const dashboardBackend: DashboardBackend[] = Object.assign ([], Main.Instance.DatabaseDataState.dashboardBackend)
        if (id === 3) { dashboardBackend[index].data.withinUnit = NewUnitMHDM } else if (id === 4) { dashboardBackend[index].data.beforeCoolOffSpanUnit = NewUnitMHDM } else if (id === 5) { dashboardBackend[index].data.gamingTimeUnit = NewUnitMHDM } else if (id === 6) { dashboardBackend[index].data.gamingDayMinSessionLengthUnit = NewUnitMH }
        Logic.Type.New(Main.Instance.DatabaseDataState, {dashboardBackend})
    } else if (id === 7) {
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
        netLimitLossIncreased[index].data.settingTakesPlaceUnit = NewUnitMHDM
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})    
    } else if (id === 10) {
        const temporaryExclude: TemporaryExclude[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExclude)
        temporaryExclude[index].data.minimalExclusionTimeUnit = NewUnitMHDM
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExclude})
    }
/*
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    else if (id === 1) custom.responsibleGaming.permanentExclude.autoDurationUnit = NewUnitMHDM
    else if (id === 2) custom.responsibleGaming.permanentExclude.banCanBeLiftedThresholdUnit = NewUnitMHDM

    else if (id === 8) custom.responsibleGaming.selfAssessment.settings.waitPeriodUnit = NewUnitMHDM
    else if (id === 9) custom.responsibleGaming.selfAssessment.settings.depositRequirementWithinUnit = NewUnitMHDM
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
*/
}
function toggleTimePeriods(value: string, index: number, id: number) {
    if (id === 0) {
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
        netLimitLossIncreased[index].data.timePeriod = toggleTimePeriod(value)
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})    
    } else if (id === 1) {
        const netLimitLossRegistration: NetLimitLossRegistration[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossRegistration)
        netLimitLossRegistration[index].data.timePeriod = toggleTimePeriod(value)
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossRegistration})   
    }
}

function toggleCurrency(value: string, index: number, id: number) {
    const NewValue: 'N/A'|'CHF'|'EUR'|'USD' = toggleCurr(value)

    if (id === 0) {
/*
    } else if (id === 1) {
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
        netLimitLossIncreased[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})
*/
    } else if (id === 2) {
        const netLimitLossRegistration: NetLimitLossRegistration[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossRegistration)
        netLimitLossRegistration[index].data.currency = NewValue
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossRegistration})
    }

/*
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 0) custom.responsibleGaming.dashboardBackend.rules[index].currency = NewValue
    else if (id === 1) custom.responsibleGaming.limitNetLoss[index].currency = NewValue
    else if (id === 2) custom.responsibleGaming.limitNetLossRegistration[index].currency = NewValue
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
*/
}
/*
function toggleCommunications (value: string, index: number, id: number) {
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    let newValue: string = ''
    let position: number = -1
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.communications)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.communications.length; i++) {
        if (value === Main.Instance.DatabaseDataState.custom.general.communications[i]) {
            position = i
            break
        }
    }

    if (position === Main.Instance.DatabaseDataState.custom.general.communications.length - 1) {
        newValue = 'N/A'
    } else if (position === -1) {
        newValue = Main.Instance.DatabaseDataState.custom.general.communications[0]
    } else newValue = Main.Instance.DatabaseDataState.custom.general.communications[position + 1]
    const NewValue: 'sms'|'e-mail'|'app' = newValue as 'sms'|'e-mail'|'app'

    if (id === 0) custom.responsibleGaming.selfAssessment.settings.actionTaken = NewValue

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/

function handleSwitch(value: boolean, index: number, id: number) {
    if (id === 0 || id === 1 || id === 2 || id === 3 || id === 4) {
        const temporaryExclude: TemporaryExclude[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExclude)

        if (id === 0) { temporaryExclude[index].data.allowPlay = !value } else if (id === 1) { temporaryExclude[index].data.allowDeposit = !value } else if (id === 2) { temporaryExclude[index].data.allowWithdraw = !value } else if (id === 3) { temporaryExclude[index].data.categoriesSelected = !value } else if (id === 4) { temporaryExclude[index].data.manualExclusionFinish = !value }
        
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExclude})
//     else if (id === 6) custom.responsibleGaming.dashboardBackend.rules[index].gamingDayMustHaveSession = !value
    } else if (id === 6) {
        const dashboardBackend: DashboardBackend[] = Object.assign ([], Main.Instance.DatabaseDataState.dashboardBackend)
        dashboardBackend[index].data.gamingDayMustHaveSession = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {dashboardBackend})
    } else if (id === 7) {
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
        if (value === false) {
            for (const item of netLimitLossIncreased) {
                if (item.data.settingActive && netLimitLossIncreased[index].data.timePeriod === item.data.timePeriod) { 
                    item.data.settingActive = false
                }
            }
        }
        netLimitLossIncreased[index].data.settingActive = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})
    } else if (id === 8) {
        const netLimitLossRegistration: NetLimitLossRegistration[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossRegistration)
        if (value === false) {
            for (const item of netLimitLossRegistration) {
                if (item.data.isDefault) { 
                    item.data.isDefault = false
                }
            }
        }

        netLimitLossRegistration[index].data.isDefault = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossRegistration})
    } else if (id === 9) {
        const saGifts: SAGifts[] = Object.assign ([], Main.Instance.DatabaseDataState.saGifts)
        saGifts[index].data.active = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saGifts})
    } else if (id === 10) {
        const saQuestions: SAQuestions[] = Object.assign ([], Main.Instance.DatabaseDataState.saQuestions)
        saQuestions[index].data.active = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saQuestions})
    } else if (id === 11) {
        const saCriteria: SACriteria[] = Object.assign ([], Main.Instance.DatabaseDataState.saCriteria)
        saCriteria[index].data.resolvedAutomatically = !value
        Logic.Type.New(Main.Instance.DatabaseDataState, {saCriteria})

    }

/*
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 5) custom.responsibleGaming.dashboardBackend.settings.checkNightJob = !value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    else if (id === 7) {
        if (value === false) {
            for (const item of custom.responsibleGaming.limitNetLoss) {
                if (item.settingActive && custom.responsibleGaming.limitNetLoss[index].timePeriod === item.timePeriod) { 
                    item.settingActive = false
                }
            }
        }
        custom.responsibleGaming.limitNetLoss[index].settingActive = !value
    } else if (id === 8) {
        if (value === false) {
            for (const item of custom.responsibleGaming.limitNetLossRegistration) {
                if (item.isDefault) { 
                    item.isDefault = false
                }
            }
        }
        custom.responsibleGaming.limitNetLossRegistration[index].isDefault = !value
    else if (id === 11) custom.responsibleGaming.CSCallbackButton.list[index].resolved = !value
    else if (id === 12) custom.responsibleGaming.CSCallbackButton.settings.active = !value

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
*/
}

function handleText(text: string, i: number, which: number, checkPattern?: boolean) {
    if (which === 0) {
        const saGifts: SAGifts[] = Object.assign ([], Main.Instance.DatabaseDataState.saGifts)
        saGifts[i].data.title = text
        Logic.Type.New(Main.Instance.DatabaseDataState, {saGifts})
    } else if (which === 1) {
        const saQuestions: SAQuestions[] = Object.assign ([], Main.Instance.DatabaseDataState.saQuestions)
        saQuestions[i].data.text[Main.Instance.DatabaseDataState.custom.general.languages[Main.Instance.RiskManagementState.language].short] = text
        Logic.Type.New(Main.Instance.DatabaseDataState, {saQuestions})
    } else if (which === 2 || which === 4) {
        const saCriteria: SACriteria[] = Object.assign ([], Main.Instance.DatabaseDataState.saCriteria)
        if (which === 2) { saCriteria[i].data.comment = text } else if (which === 4) { saCriteria[i].data.shownPlayer[Main.Instance.DatabaseDataState.custom.general.languages[Main.Instance.RiskManagementState.language].short] = text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {saCriteria})
    } else if (which === 3) {
        const selfExclusion: SelfExclusion[] = Object.assign ([], Main.Instance.DatabaseDataState.selfExclusion)
        if (checkPattern && !dateValidator(text)) {
            selfExclusion[i].data.date = '9999-12-31'
        } else {
            selfExclusion[i].data.date = text
        }
        // selfExclusion[i].data.date = text
        Logic.Type.New(Main.Instance.DatabaseDataState, {selfExclusion})

    }

/*
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)

    else if (which === 1) custom.responsibleGaming.selfAssessment.questionnaire.questions[i].text[Main.Instance.RiskManagementState.language] = text
    else if (which === 2) custom.responsibleGaming.selfAssessment.assessment[i].comment = text
    else if (which === 3) custom.responsibleGaming.CSCallbackButton.list[i].resolvedTimestamp = text
    else if (which === 4) {
        if (dateValidator(text)) {
            custom.responsibleGaming.CSCallbackButton.list[i].resolvedTimestamp = text
        } else {custom.responsibleGaming.CSCallbackButton.list[i].resolvedTimestamp = Assets_.dateDisplayFormat}
    } else if (which === 5) custom.responsibleGaming.CSCallbackButton.list[i].comments.push({timestamp: new Date().toISOString(), userId: +Main.Instance.LoginState.id, comment: text})
    else if (which === 6) custom.responsibleGaming.CSCallbackButton.settings.CSEmail = text

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
*/
}

export function handleNumber(text: string, i: number, which: number/*, nonNegative: boolean*/) {
    // if (nonNegative && parseInt(text) < 0) return
    if (text === undefined || text === '0' || text.length === 0) { text = '1' } else if (text.length > 1 && text.charAt(0) === '0') { text.slice(0, 1) }

    if (which === 0) {
        const temporaryExcludeOptions: TemporaryExcludeOptions[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExcludeOptions)
        temporaryExcludeOptions[i].data.duration = +text
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExcludeOptions})
    } else if (which === 1 || which === 2) {
        const selfExclusion: SelfExclusion[] = Object.assign ([], Main.Instance.DatabaseDataState.selfExclusion)
        if (which === 1) { selfExclusion[i].data.autoDuration = +text } else if (which === 2) { selfExclusion[i].data.banCanBeLiftedThreshold = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {selfExclusion})

    } else if (which > 2 && which < 16) {
        const dashboardBackend: DashboardBackend[] = Object.assign ([], Main.Instance.DatabaseDataState.dashboardBackend)
        if (which === 3) { dashboardBackend[i].data.depositMin = +text } else if (which === 4) { dashboardBackend[i].data.depositMethodsMin = +text } else if (which === 5) { dashboardBackend[i].data.within = +text } else if (which === 6) { dashboardBackend[i].data.afterCoolOffPercentageHigher = +text } else if (which === 7) { dashboardBackend[i].data.beforeCoolOffSpan = +text } else if (which === 8) { dashboardBackend[i].data.withdrawalRequestsCancelledThreshold = +text } else if (which === 9) { dashboardBackend[i].data.gamingTime = +text } else if (which === 10) { dashboardBackend[i].data.gamingTimePercentageOver = +text } else if (which === 11) { dashboardBackend[i].data.gamingDayMinSessionLength = +text } else if (which === 12) { dashboardBackend[i].data.netLossPerHour = +text } else if (which === 13) { dashboardBackend[i].data.totalNetLoss = +text } else if (which === 14) { dashboardBackend[i].data.coolOffsInitiatedThreshold = +text } else if (which === 15) { dashboardBackend[i].data.netLossLimitIncreasedThreshold = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {dashboardBackend})
    } else if (/*which === 16 || */which === 17) {
    // settingTakesPlace
        const netLimitLossIncreased: NetLimitLossIncreased[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossIncreased)
/*
        if (which === 16) netLimitLossIncreased[i].data.highNetLossThreshold = +text
        else */
        if (which === 17) { netLimitLossIncreased[i].data.settingTakesPlace = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossIncreased})
    } else if (which === 18) {
        const netLimitLossRegistration: NetLimitLossRegistration[] = Object.assign ([], Main.Instance.DatabaseDataState.netLimitLossRegistration)
        netLimitLossRegistration[i].data.defaultAmount = +text
        Logic.Type.New(Main.Instance.DatabaseDataState, {netLimitLossRegistration})  
/*
    } else if (which === 23 || which === 24) {
        // unused
*/
    } else if (which === 25) {
        const temporaryExclude: TemporaryExclude[] = Object.assign ([], Main.Instance.DatabaseDataState.temporaryExclude)
        temporaryExclude[i].data.minimalExclusionTime = +text
        Logic.Type.New(Main.Instance.DatabaseDataState, {temporaryExclude})
    } else if (which === 26 || which === 27) {
        const saCriteria: SACriteria[] = Object.assign ([], Main.Instance.DatabaseDataState.saCriteria)
        if (which === 26) { saCriteria[i].data.min = +text }
        if (which === 27) { saCriteria[i].data.max = +text }
        Logic.Type.New(Main.Instance.DatabaseDataState, {saCriteria})
    }
/*
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)

    else if (which === 1) custom.responsibleGaming.permanentExclude.autoDuration = +text
    else if (which === 2) custom.responsibleGaming.permanentExclude.banCanBeLiftedThreshold = +text
    else if (which === 3) custom.responsibleGaming.dashboardBackend.rules[i].depositMin = +text
    else if (which === 4) custom.responsibleGaming.dashboardBackend.rules[i].depositMethodsMin = +text
    else if (which === 5) custom.responsibleGaming.dashboardBackend.rules[i].within = +text
    else if (which === 6) custom.responsibleGaming.dashboardBackend.rules[i].afterCoolOffPercentageHigher = +text
    else if (which === 7) custom.responsibleGaming.dashboardBackend.rules[i].withdrawalRequestsCancelledThreshold = +text
    else if (which === 8) custom.responsibleGaming.dashboardBackend.rules[i].gamingTime = +text
    else if (which === 9) custom.responsibleGaming.dashboardBackend.rules[i].gamingTimePercentageOver = +text
    else if (which === 10) custom.responsibleGaming.dashboardBackend.rules[i].gamingTimePercentageOver = +text
    else if (which === 11) custom.responsibleGaming.dashboardBackend.rules[i].gamingDayMinSessionLength = +text
    else if (which === 12) custom.responsibleGaming.dashboardBackend.rules[i].netLossPerHour = +text
    else if (which === 13) custom.responsibleGaming.dashboardBackend.rules[i].totalNetLoss = +text
    else if (which === 14) custom.responsibleGaming.dashboardBackend.rules[i].coolOffsInitiatedThreshold = +text
    else if (which === 15) custom.responsibleGaming.dashboardBackend.rules[i].netLossLimitIncreasedThreshold = +text
    
    // else if (which === 19) custom.responsibleGaming.selfAssessment.gifts[i].id = +text
    else if (which === 20) custom.responsibleGaming.selfAssessment.settings.waitPeriod = +text
    else if (which === 21) custom.responsibleGaming.selfAssessment.settings.depositRequirementWithin = +text

    else if (which === 25) custom.responsibleGaming.temporaryExclude.settings[i].minimalExclusionTime = +text

    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
*/
}
/*
function handleInputSelfExclusion(text: string, checkPattern: boolean) {
    let custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (checkPattern && !dateValidator(text)) {
        custom.responsibleGaming.permanentExclude.date = '9999-12-31'
    } else {
        custom.responsibleGaming.permanentExclude.date = text
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/
function AddTempExcludeSetting() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddTempExclude: true})
}

function AddTempExcludeOptions() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddTempExclude: true})
}

function AddNetLossLimit() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddNetLimitLoss: true})
}
/*
function AddSelfExclusion () {
    Logic.Type.New(Main.Instance.RiskManagementState, {showSelfExclusion: true})
}
*/
function AddNetLossLimitRegistration() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddNetLimitLossReg: true})
}

function AddDashboardBackIndicators(ruleType: DashboardRuleType) {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddDashboardBackend: true})
}

function AddSelfAssessmentGifts() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddSelfAssessment: true})
}

function AddSelfAssessmentQuestions() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddSelfAssessment: true})
}

function AddSelfAssessmentSettings() {
    Logic.Type.New(Main.Instance.RiskManagementState, {showAddSelfAssessment: true})
}

function handleDeleteTempExclude(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amTemporaryExcludeOptions, row.original.id)
    }
}
function handleDeleteTempExcludeSetting(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amTemporaryExclude, row.original.id)
    }
}
function handleDeleteSelfAssessmentGifts(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.gift + ' "' + row.original.title + '" ' + Assets.withGiftID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amSelfAssessmentGifts, row.original.id)
    }
}
function handleDeleteSelfAssessmentQuestions(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.question + ' "' + Assets.withID + '" ' + Assets.withID + ' "' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amSelfAssessmentQuestions, row.original.id)
    }
}

function handleDeleteNetLossLimit(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' "' + Assets.withID + '" ' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amNetLimitLossIncreased, row.original.id)
    }
}

function handleDeleteNetLossLimitRegistration(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' "' + Assets.withID + '" ' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amNetLimitLossRegistration, row.original.id)
    }
}

function handleDeleteDashboardIndicators(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' "' + Assets.withID + '" ' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amDashboardBackend, row.original.id)
    }
}

function handleDeleteSelfAssessmentCriteria(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' "' + Assets.withID + '" ' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amSelfAssessmentCriteria, row.original.id)
    }
}
/*
function handleDeleteSelfExclusion (row: any) {

    if (confirm(Assets.sureToDelete + ' ' + Assets.setting + ' "' + Assets.withID + '" ' + row.original.id + '"?')) {    
        DbRoutes.wsBasicDelete(Config.amSelfExclusion, row.original.id)
    }
}
*/
function dashboardTable(ruleType: DashboardRuleType) {
    // let tableData: any[] = []
    let index: number = 0

    if (Main.Instance.DatabaseDataState.dashboardBackend
/*
        Main.Instance.DatabaseDataState.custom &&
        Main.Instance.DatabaseDataState.custom.responsibleGaming && 
        Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend &&
        Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.rules
*/
    ) {
        // tableData = Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.rules
        for (const rule of Main.Instance.DatabaseDataState.dashboardBackend) {
            if (rule.data.ruleType === ruleType) { break } else { index ++ }
        }
        // console.log (tableData)
    }
    return(
        Main.Instance.DatabaseDataState.dashboardBackend ?
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.dashboardBackend}
            // filterable = {true}
            // defaultFilterMethod = {(filter, row) => row.data.ruleType === ruleType}
            filtered = {[{id: 'data.ruleType', value: ruleType}]}
            columns = {[
                {
                    columns: [
                        {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.value}</div>},
                    ],
                },
                {
                    Header: Assets.Conditions,
                    columns: [
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.depositMin !== undefined,
                        width: 130, Header: <div title = {Assets.DepositMinimum}>{Assets.DepositMinimum}</div>, accessor: 'data.depositMin', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 3)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.depositMethodsMin !== undefined,
                        width: 200, Header: <div title = {Assets.DepositMethodsMinimum}>{Assets.DepositMethodsMinimum}</div>, accessor: 'data.depositMethodsMin', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 4)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.within !== undefined,
                        width: 130, Header: <div title = {Assets.Within}>{Assets.Within}</div>, accessor: 'data.within', headerClassName: 'th-left', Cell: (row) => <div>
                        <input style={{width: '55%'}} min = "1" className = "table-input-text pr0 mr5px" onChange = {(e) => handleNumber (e.target.value, row.index, 5)} type = "number" value = {row.value}/>
                        <button style={{width: '40%'}} onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.withinUnit, row.index, 3)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.withinUnit)}>
                            {Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.withinUnit? Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.withinUnit: 'N/A'}
                        </button>
                        </div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.afterCoolOffPercentageHigher !== undefined,
                        width: 250, Header: <div title = {Assets.AfterCoolOffPercentageHigher}>{Assets.AfterCoolOffPercentageHigher}</div>, accessor: 'data.afterCoolOffPercentageHigher', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 6)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.beforeCoolOffSpan !== undefined,
                        width: 160, Header: <div title = {Assets.BeforeCoolOffSpan}>{Assets.BeforeCoolOffSpan}</div>, accessor: 'data.beforeCoolOffSpan', headerClassName: 'th-left', Cell: (row) => <div>
                        <input style={{width: '55%'}} min = "1" className = "table-input-text pr0 mr5px" onChange = {(e) => handleNumber (e.target.value, row.index, 7)} type = "number" value = {row.value}/>
                        <button style={{width: '40%'}} onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.beforeCoolOffSpanUnit, row.index, 4)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.beforeCoolOffSpanUnit)}>
                            {Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.beforeCoolOffSpanUnit? Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.beforeCoolOffSpanUnit: 'N/A'}
                        </button>
                        </div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.withdrawalRequestsCancelledThreshold !== undefined,
                        width: 300, Header: <div title = {Assets.WithdrawalRequestsCancelledThreshold}>{Assets.WithdrawalRequestsCancelledThreshold}</div>, accessor: 'data.withdrawalRequestsCancelledThreshold', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 8)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.gamingTime !== undefined,
                        width: 130, Header: <div title = {Assets.GamingTime}>{Assets.GamingTime}</div>, accessor: 'data.gamingTime', headerClassName: 'th-left', Cell: (row) => <div>
                        <input style={{width: '55%'}} min = "1" className = "table-input-text pr0 mr5px" onChange = {(e) => handleNumber (e.target.value, row.index, 9)} type = "number" value = {row.value}/>
                        <button style={{width: '40%'}} onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingTimeUnit, row.index, 5)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingTimeUnit)}>
                            {Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingTimeUnit? Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingTimeUnit: 'N/A'}
                        </button>
                        </div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.gamingTimePercentageOver !== undefined,
                        width: 220, Header: <div title = {Assets.GamingTimePercentageOver}>{Assets.GamingTimePercentageOver}</div>, accessor: 'data.gamingTimePercentageOver', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 10)} type = "number" value = {row.value}/></div>},
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.gamingDayMustHaveSession !== undefined,
                        width: 230, Header: <div title = {Assets.GamingDayMustHaveSession}>{Assets.GamingDayMustHaveSession}</div>, accessor: 'data.gamingDayMustHaveSession', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                        <Switch className = "mt4" onClick = {() => handleSwitch(row.value, row.index, 6)} on = {row.value} /></div>},
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.gamingDayMinSessionLength !== undefined,
                        width: 230, Header: <div title = {Assets.GamingDayMinSessionLength}>{Assets.GamingDayMinSessionLength}</div>, accessor: 'data.gamingDayMinSessionLength', headerClassName: 'th-left', Cell: (row) => <div>
                        <input style={{width: '55%'}} min = "1" className = "table-input-text pr0 mr5px" onChange = {(e) => handleNumber (e.target.value, row.index, 11)} type = "number" value = {row.value}/>
                        <button style={{width: '40%', maxWidth: '50px'}} onClick = {() => toggleTimeUnits(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingDayMinSessionLengthUnit, row.index, 6)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingDayMinSessionLengthUnit)}>
                            {Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingDayMinSessionLengthUnit? Main.Instance.DatabaseDataState.dashboardBackend[row.index].data.gamingDayMinSessionLengthUnit: 'N/A'}
                        </button>
                        </div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.netLossPerHour !== undefined  || Main.Instance.DatabaseDataState.dashboardBackend[index].data.totalNetLoss !== undefined,
                        width: 150, Header: <div title = {Assets.NetLossPerHour}>{Assets.NetLossPerHour}</div>, accessor: 'data.netLossPerHour', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 12)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.netLossPerHour !== undefined || Main.Instance.DatabaseDataState.dashboardBackend[index].data.totalNetLoss !== undefined,
                        width: 120, Header: <div title = {Assets.TotalNetLoss}>{Assets.TotalNetLoss}</div>, accessor: 'data.totalNetLoss', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 13)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.coolOffsInitiatedThreshold !== undefined,
                        width: 210, Header: <div title = {Assets.CoolOffsInitiatedThreshold}>{Assets.CoolOffsInitiatedThreshold}</div>, accessor: 'data.coolOffsInitiatedThreshold', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 14)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.netLossLimitIncreasedThreshold !== undefined,
                        width: 250, Header: <div title = {Assets.NetLossLimitIncreasedThreshold}>{Assets.NetLossLimitIncreasedThreshold}</div>, accessor: 'data.netLossLimitIncreasedThreshold', headerClassName: 'th-left', Cell: (row) => <div>
                        <input  min = "1" className = "table-input-text pr0" onChange = {(e) => handleNumber (e.target.value, row.index, 15)} type = "number" value = {row.value}/></div>,
                        },
        
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.currency !== undefined,
                        width: 80, Header: <div title = {Assets.Currency}>{Assets.Currency}</div>, accessor: 'data.currency', headerClassName: 'th-left', Cell: (row) => <div>
                        <button onClick = {() => toggleCurrency(row.value, row.index, 0)} className = {'btn btn-default btn-xs btn-value ' + buttonValue(row.value)}>{row.value? row.value: 'N/A'}</button></div>,
                        },
                    ],
                    headerClassName: 'risk-condition',
                },
                {
                    Header: Assets.Consequences,
                    columns: [
                        {
                        show: Main.Instance.DatabaseDataState.dashboardBackend[index].data.riskLevel !== undefined ? true : false,
                        width: 120, Header: Assets.RiskLevel, accessor: 'data.riskLevel', headerClassName: 'th-center', Cell: (row) => <div>
                        <button /*onClick = {() => handleRiskLevel(2, row.index, row.value)}*/ className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}>
                            {RiskLevel[row.value]}
                            {riskLevelSlider(2, row.index, row.value)}
                        </button>
                        </div>,
                        },
                    ],
                    headerClassName: 'risk-consequence',
                },
                {
                    columns: [
                        {show: true, width: 0, Header: Assets.RuleType, accessor: 'data.ruleType', headerClassName: 'th-left', Cell: (row) => <div style = {{display: 'none'}}>{row.value}</div>},
                        {show: isUserAdmin(), width: 50, Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddDashboardBackIndicators(Main.Instance.DatabaseDataState.dashboardBackend[index].data.ruleType)}>{Assets.Add}</button>,
                        accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteDashboardIndicators)},
                        {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 16)},
                    ],
                },
            ]}
            showPaginationTop = {true}
            showPaginationBottom = {false}
            defaultPageSize={10}
        /> : <div/>
    )
}

export function getIssueType(issueType: number, riskLevel: RiskLevel): JSX.Element|string {
    const type: string = IssueTypes[issueType]
    // console.log (type)
    if (type) {
        const Type: string[] = type.match(/[A-Z][a-z]+/g)
        let s: string = ''
        let nv: boolean = false
        let cat: string
        for (let i: number = 0; i < Type.length; i++) {
            if (i > 0) { s += ' ' }
            if (i === Type.length - 1 && (type === IssueTypes[IssueTypes.AccountClosedActive] || type === IssueTypes[IssueTypes.AccountClosedProvisional])) {
                if (type === IssueTypes[IssueTypes.AccountClosedActive]) {
                    cat = Assets.ActiveShort + '.'
                } else if (type === IssueTypes[IssueTypes.AccountClosedProvisional]) {
                    cat = Assets.ProvisionalShort + '.'
                }
                s += ' ' + cat/*.charAt(0)*/ // + ')'
            } else { s += Type[i] }

            if (i === Type.length - 1 && (type === IssueTypes[IssueTypes.LargeDeposit] || type === IssueTypes[IssueTypes.LargeWithdrawal]) && riskLevel >= RiskLevel.High) {
                s += ' ' + Assets.NeedsVerificationAbbrev // + ' )'
                nv = true
            }
        }
/*
        const split: JSX.Element[] = []
        for (let i: number = 0; i < Type.length; i++) {
            split.push (<div className = "lh14" key = {i.toString()}>{Type[i]}</div>)
        }
*/
        return <div title = {nv ? Assets.NeedsVerification : cat ? cat : ''}>{s.length === 0 ? '-' : s}</div>
    }
    return <div>-</div>
}

export function getRiskManager(id: number): string {
    if (Main.Instance.LogState.distinctUserId) {
    for (const item of Main.Instance.LogState.distinctUserId) {
        if (item && item.user_id === id) {
            return item.username
/*                
                <span>
                    <span>{item.username}</span>
                    &nbsp;
                    <span title = {Assets.shortAdminRoles[item.role % 10]} className = "label label-default label-xs">
                        {Assets.shortestAdminRoles[item.role % 10]}
                    </span>
                </span>
*/
        }
    }
    }
    
    return ''
}

export function getRiskManagers(ids: number[]): string {
    let rms: string = ''
    for (const id of ids) {
        if (rms.length > 0) {rms += ', '}
        if (Main.Instance.LogState.distinctUserId) {
        for (const item of Main.Instance.LogState.distinctUserId) {
            if (item && item.user_id === id) {
                rms += item.username
                break
            }
        }
        }
    }
    return rms
}

export function getManual (history: IIssueHistory[], riskManagerId: number) {
    // console.log ('get-manual')
    // console.log (history, riskManagerId)
    // history.length - 1 POSSIBLE BUG
    if ((history === undefined || history.length === 0) && riskManagerId !== 0 || history.length > 0 && history[0].riskManagerId !== 0) {
        return <FontAwesomeIcon title = {Assets.ThisIsAManualIssue + Assets._by_ + getRiskManager(history === undefined || history.length === 0 ? riskManagerId : history.length > 0 && history[0].riskManagerId)} style = {{color: '#5cb85c'}} icon = {faCheck}/>
    }
    return <FontAwesomeIcon title = {Assets.ThisIsNotAManualIssue} style = {{color: '#d9534f'}} icon = {faTimes}/>
}

// {row.original.username}, {row.original.firstName} {row.original.lastName}
export function getNames(playerId: string): string {
    if (Main.Instance.DatabaseDataState.playerCards) {
        for (const item of Main.Instance.DatabaseDataState.playerCards) {
            if (item.playerId === playerId) {
                return (item.username + (isUserGDPR() ? ', ' + item.firstName + ' ' + item.lastName : ''))
            }
        }
    }
    return '-'
}
/*
function getIssueSource(which: string): TimelineEntrySubType|undefined {
    // console.log (which)
    if (which === 'Risk') {
        return TimelineEntrySubType.RiskManagement
    } else if (which === 'RG') {
        return TimelineEntrySubType.ResponsibleGaming
    } else if (which === 'KYC') {
        return TimelineEntrySubType.KnowYourCustomer
    } else if (which === 'GM') {
        return TimelineEntrySubType.Gamification
    }
    return undefined
}
*/
export function getIssuesTable(history: boolean, issues: Array<Partial<IIssue>> | Array<Partial<IIssueHistory>>, subType?: TimelineEntrySubType|undefined, justUnresolved: boolean = false, noFilters?: boolean, selectedIssueType?: number, which?: string): JSX.Element {
    // console.log (history)
    // console.log (JSON.stringify(issues))
    // console.log (subType)
    // console.log (issues)
    // console.log ('subType:' + subType)
    const nav: PlayerCardButtons = subType === TimelineEntrySubType.KnowYourCustomer ? PlayerCardButtons.KnowYourCustomer : TimelineEntrySubType.RiskManagement ? PlayerCardButtons.RiskManagement : TimelineEntrySubType.ResponsibleGaming ? PlayerCardButtons.ResponsibleGaming : TimelineEntrySubType.Gamification ? PlayerCardButtons.Gamification : undefined
    // console.log (issues)
    const filtered: Array<{id: string, value: any}> = noFilters ? [] : subType ?
        Main.Instance.RiskManagementState.filterResolved === undefined || history === true || history === undefined ?
        [/*{id: 'entrySubType', value: subType}*/] :
        [/*{id: 'entrySubType', value: subType}, */{id: 'resolved', value: justUnresolved ? false : Main.Instance.RiskManagementState.filterResolved}] :
        Main.Instance.RiskManagementState.filterResolved === undefined || history === true || history === undefined ?
        [] :
        [{id: 'resolved', value: justUnresolved ? false : Main.Instance.RiskManagementState.filterResolved}]

    let value: number = 0
    if (history === false && subType /* && !noFilters*/) {

        if (subType === TimelineEntrySubType.RiskManagement && Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM > 0) {
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM
        } else if (subType === TimelineEntrySubType.ResponsibleGaming && Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG > 0) {
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeRG
        } else if (subType === TimelineEntrySubType.KnowYourCustomer && Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC > 0) {
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeKYC
        } else if (subType === TimelineEntrySubType.Gamification && Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM > 0) {
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeGM
        } else if (subType === TimelineEntrySubType.DashboardBackend && Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB > 0) {
            value = Main.Instance.DatabaseDataState.issuesFilterIssueTypeDB
        }
/* 
        if (value !== 999) {
            filtered.push ({id: 'issueType', value})
        }
*/
        // console.log (subType)
        // console.log (Main.Instance.DatabaseDataState.issuesFilterIssueTypeRM)
    }

    // console.log (filtered)
    
    // console.log (filtered)
/*
    if (issues) {
        // console.log (typeof issues)
        for (const item of issues) {
            if (item['id'] && item['id'] === 2424 || item['id'] === 510) {console.log (item)}
        }
    }
*/
    // console.log (selectedIssueType)
    // console.log (subType)
    // const issueSource: TimelineEntrySubType = subType ? subType : getIssueSource(which)
    return (
        <ReactTable
            className = "rt-no-white-space"
            data = {issues ? issues : []}
            filtered = {filtered}
            columns = {[
                {
                    Header: Assets.EventType,
                    headerClassName: 'risk-default',
                    columns: [
                        // {width: 0, accessor: 'entrySubType', show: true},
                        {width: 70, Header: history === true ? Assets.Index : Assets.Issue + ' ' + Assets.ID, accessor: 'entrySubType', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}><div className = "td-left fs16px">{history === true ? (issues.length - row.index): row.original.id}</div></div>},
                        {width: 320, Header: Assets.PlayerID + (Main.Instance.DatabaseDataState.issuesShowNames ? ', ' + Assets.Username + (isUserGDPR() ? ', ' + Assets.FirstName + ' ' + Assets.LastName : '') : ''), accessor: 'playerId', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) =>
                        <div className = {getBackground(row.original.resolved)}>
                            {getPlayerData(row.value,nav/*, row.original.username*/)}
                            {Main.Instance.DatabaseDataState.issuesShowNames ? <div>{getNames(row.value)}</div> : void 0}
                        </div>},
                        {show: history || value === 999 || (selectedIssueType ? (selectedIssueType === 999 || selectedIssueType === 777) : false) || (Main.Instance.DashboardState.selectedIssueSubCategoryUnresolved !== -1 && (value === 9999 || disabledIssueType(value))), width: 150, Header: Assets.IssueClassification, accessor: 'issueType', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{getIssueType(row.value, row.original.riskLevel)}</div>},

                        // {width: 90, Header: 'Date (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                        // {width: 100, Header: 'Time (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length - 1) : '-'}

                    ],
                },
                {
                    Header: Assets.Timestamp,
                    headerClassName: 'risk-default-light',
                    columns: [
                        {width: 90, Header: <span>{Assets.Issued}&nbsp;<small>{Assets_.UTC}</small></span>, accessor: 'created', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.value ? 
                        <div><div>{row.value.substring(0, 10)}</div><div>{row.value.substring(11, row.value.length)}</div></div> : '-'}</div>},
                    ],
                },
                {
                    Header: Assets.IssueControlPanel,
                    // getBackground
                    headerClassName: 'risk-default',
                    columns: [
                        {show: Main.Instance.DatabaseDataState.issuesShowAssignedTo, width: 100, Header: <span>{Assets.AssignedTo}</span>, accessor: 'assignTo', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.value && row.value.length > 0 ? <span><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManagers(row.value)}</span> : '-'}</div>},
                        {show: !history, width: 25, Header: <FontAwesomeIcon title = {Assets.Manual + ' ' + Assets.Issue.toLowerCase()} icon = {faUser}/>, accessor: 'history', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{getManual(row.value, row.original.riskManagerId)}</div>},
                        {width: 161, Header: <span>{Assets.LastIssueUpdate}</span>, accessor: 'riskManagerId', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.value !== undefined ? row.value === 0 ? <span title = {Assets.ThisIssueWasBroughtUpAutomatically}><FontAwesomeIcon icon = {faLaptopCode}/>&nbsp;&nbsp;{Assets.AutomaticIssue}</span> : <span><FontAwesomeIcon icon = {faUser} size = "lg"/>&nbsp;&nbsp;{getRiskManager(row.value)}</span> : '-'}</div>},
                        {show: Main.Instance.DatabaseDataState.issuesShowRiskLevel, width: 110, Header: <span>{Assets.RiskLevel}</span>, accessor: 'riskLevel', headerClassName: 'th-center', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.value ?
                        <button 
                            className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value]}
                        >
                            {RiskLevel[row.value]}
                            {RiskLevelSlider(10, row.index, row.value, true)}
                        </button>                                            
                        : '-'}</div>},
                        {Header: Assets.Comment, accessor: 'comment', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{/*row.value ? row.value : '-'*/}<p title = {row.value ? row.value : ''}>{row.value ? row.value : '-'}</p></div>},
                        {Header: Assets.Alert + ' ' + Assets.Code.toLowerCase(), accessor: 'alertCode', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{/*row.value ? row.value : '-'*/}<p title = {row.value ? row.value : ''}>{row.value ? row.value : '-'}</p></div>},
                        // {width: 65, Header: Assets.Alert + ' ' + Assets.ID, accessor: 'alertInstanceId', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{/*row.value ? row.value : '-'*/}<p title = {row.value ? row.value : ''}>{row.value ? row.value : '-'}</p></div>},
                        /*{width: 80, Header: Assets.Image, accessor: 'data.image', headerClassName: 'th-center', Cell: (row) => row.value === undefined || row.original.entryType !== TimelineEntryType.Issue ? '' : <img className = "img img-responsive" width = {70} src = {'data:image/png;base64,' + row.value}/>},*/
                        {show: Main.Instance.DatabaseDataState.issuesShowDetails, width: 150, Header: Assets.Details, accessor: 'data', headerClassName: 'th-center', className: 'no-white-space2',Cell: (row) => <div className = {getBackground(row.original.resolved)}><p title = {row.value ? filterDataByRights(row.value) : ''}>{row.value ? filterDataByRights(row.value) : '-'}</p></div>},
                        {width: 50, Header: Assets.New, accessor: 'history', headerClassName: 'th-center', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{history ? '' : TBN((row.value === undefined || row.value.length === 0) && row.original.resolved !== undefined && row.original.resolved === false ? true : false)}</div>},
                        {show: history === false, width: 60, Header: Assets.History, accessor: 'history', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => <div className = {getBackground(row.original.resolved)}>{row.value ? <button onClick = {() => {Logic.Type.New(Main.Instance.RiskManagementState, {mode: true, rowId: row.index, history: row.value});gotoTop()}} title = {(row.value === undefined || row.value.length === 0) && row.original.resolved !== undefined && row.original.resolved === false ? Assets.NoHistory : ''} disabled = {row.value === undefined || row.value.length === 0} type = "button" className = "btn btn-info btn-xs mr0">{Assets.View}</button> : '-'}</div>},
                        {width: history === undefined || history === true ? 90 : 80, Header: Assets.Action, accessor: 'resolved', headerClassName: 'th-left', className: 'no-white-space2', Cell: (row) => 
                        <div className = {getBackground(row.original.resolved)}>
                            {
                            row.value !== undefined ? 
                            row.value ? 
                            history === true  ? 
                            <span>
                                {Assets.Resolved}
                            </span> :
                            <button 
                                title = {Assets.ThisIssueWasAlreadyResolved} 
                                onClick = {() => {
                                    Logic.Type.New(Main.Instance.RiskManagementState, {playerId: row.original.playerId, mode: Main.Instance.Location.includes('player-card') ? false : true, rowId: Main.Instance.RiskManagementState.history && Main.Instance.RiskManagementState.history.length > 0 ? Main.Instance.RiskManagementState.rowId : row.index, issueSource: subType, showAddCSCallbackModal: true});
                                    gotoTop()
                                }} 
                                disabled = {isUserProhibitedToResolveIssue(row)} 
                                type = "button" 
                                className = "btn btn-danger btn-xs mr0">
                                    {Assets.Reopen}
                            </button> : 
                            history === true ? 
                            <span>
                                {Assets.Unresolved}
                            </span> : 
                            <button 
                                onClick = {() => {
                                    Logic.Type.New(Main.Instance.RiskManagementState, {playerId: row.original.playerId, mode: Main.Instance.Location.includes('player-card') ? false : true, rowId: Main.Instance.RiskManagementState.history && Main.Instance.RiskManagementState.history.length > 0 ? Main.Instance.RiskManagementState.rowId : row.index, issueSource: subType, showAddCSCallbackModal: true});
                                    gotoTop()
                                }} 
                                disabled = {isUserProhibitedToResolveIssue(row)} 
                                type = "button" 
                                className = "btn btn-warning btn-xs mr0"
                            >
                                {Assets.Resolve}
                            </button> : '-'}
                        </div>},

/*
                        {width: 500, show: Main.Instance.RiskManagementState.filterResolved, Header: 'Comment', accessor: 'comment', headerClassName: 'th-left'},
                        {width: 80, Header: 'Resolved', accessor: 'resolved', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                            {!Main.Instance.RiskManagementState.filterResolved || isUserAdmin() || isUserRFSpecialist()?
                            <button  className = "btn btn-warning btn-xs" onClick = {() => Logic.Type.New(Main.Instance.RiskManagementState, {rowId: row.index, showAddCSCallbackModal: true})}>
                                {Main.Instance.RiskManagementState.filterResolved ? 'Add a comment' : 'Resolve'}
                            </button>
                            : <div>Resolved</div>
                            }
                        </div>
                        }
*/
                    ],
                },
            ]}
            showPaginationTop = {history ? true : false /*selectedIssueType ? true : false*/ /*history === undefined || history === true ? true : false*/} 
            showPaginationBottom = {false}
            defaultPageSize={history === false ? 10 : undefined}
            pageSize = {history === undefined ? 1 : history === true ? issues.length : issues ? issues.length : undefined}
        />
    )

}

export function filterDataByRights(data): string {
    const filteredObject = Object.assign({}, data)
    if (!isUserGDPR()) {
        removeProp(filteredObject, 'email')
        removeProp(filteredObject, 'birthDate')
        removeProp(filteredObject, 'streetName')
        removeProp(filteredObject, 'streetNumber')
        removeProp(filteredObject, 'city')
        removeProp(filteredObject, 'firstName')
        removeProp(filteredObject, 'lastName')
        removeProp(filteredObject, 'gender')
        removeProp(filteredObject, 'nationality')
        removeProp(filteredObject, 'phoneNumber')
        removeProp(filteredObject, 'postcode')
        removeProp(filteredObject, 'beneficiaryName')
        removeProp(filteredObject, 'iban')
        removeProp(filteredObject, 'bic')
        removeProp(filteredObject, 'address')
        removeProp(filteredObject, 'postalCode')
        removeProp(filteredObject, 'birthday')
        removeProp(filteredObject, 'name')
    } 
    return JSON.stringify(filteredObject)
}


function removeProp(obj, propToDelete) {
    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == 'object') {
                removeProp(obj[property], propToDelete);
            } else {
                if (property === propToDelete) {
                delete obj[property];
                }
            }
        }
    }
}

/*
export function getCSCallbackTable(justUnresolved: boolean = false) {
    return (
        <ReactTable 
            data = {
                Main.Instance.DatabaseDataState.custom &&
                Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                Main.Instance.DatabaseDataState.custom.responsibleGaming.CSCallbackButton &&
                Main.Instance.DatabaseDataState.custom.responsibleGaming.CSCallbackButton.list?
                Main.Instance.DatabaseDataState.custom.responsibleGaming.CSCallbackButton.list: []
            }
            filtered = {Main.Instance.RiskManagementState.filterResolved === undefined ? undefined : [{id: 'resolved', value: justUnresolved? false : Main.Instance.RiskManagementState.filterResolved}]}
            columns = {[
                {width: 50, Header: Assets.ID, accessor: '', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">{row.index + 1}</div>},

                {
                    Header: 'Callback requested',
                    headerClassName: 'callback-requested',
                    columns: [
                        // {width: 50, Header: Assets.PlayerIDAbbrev, accessor: 'playerId', headerClassName: 'th-left', Cell: (row) => <button onClick = {() => SelectPlayer(row.value)} className = "btn btn-xs btn-sm">{row.value}</button>},
                        {width: 180, Header: 'Player data', accessor: 'playerId', headerClassName: 'th-left', Cell: (row) => getPlayerData(row.value, PlayerCardButtons.ResponsibleGaming)},
                        {width: 150, Header: Assets.PhoneNumber, accessor: 'phoneNumber', headerClassName: 'th-left', Cell: (row) => <div>{row.value}</div>},
                        {width: 90, Header: 'Date (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                        {width: 100, Header: 'Time (' + Assets_.UTC + ')', accessor: 'requestedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length - 1) : '-'}
                    ]
                },
                
                {
                    Header: 'Callback resolved',
                    headerClassName: 'callback-resolved',
                    columns: [
                        {width: 90, Header: 'Date (' + Assets_.UTC + ')', accessor: 'resolvedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(0, 10) : '-'},
                        {width: 100, Header: 'Time (' + Assets_.UTC + ')', accessor: 'resolvedTimestamp', headerClassName: 'th-left', Cell: (row) => row.value ? row.value.substring(11, row.value.length - 1) : '-'},
                        {width: 450, show: Main.Instance.RiskManagementState.filterResolved, Header: 'Comments', accessor: 'comments', headerClassName: 'th-left', Cell: (row) => <div>
                            {getComments(row.value)}
                        </div>
                        },
                        {width: 100, Header: 'Resolved', accessor: 'resolved', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">
                            {!Main.Instance.RiskManagementState.filterResolved || isUserAdmin() || isUserRFSpecialist()?
                            <button  className = "btn btn-warning btn-xs" onClick = {() => Logic.Type.New(Main.Instance.RiskManagementState, {rowId: row.index, showAddCSCallbackModal: true})}>
                                {Main.Instance.RiskManagementState.filterResolved ? 'Add a comment' : 'Resolve'}
                            </button>
                            : <div>Resolved</div>
                            }
                        </div>
                        }
                    ]
                }
                
            ]}
            showPaginationTop = {true}
            showPaginationBottom = {false}
            defaultPageSize={10}
        />
    )
}
*/

export function getBackground(value: boolean): string {
    // console.log ('GETBackground:' + value)
    // return value ? 'background-light-green' : 'background-light-red'
    return value ? 'bg-light' : 'bg-light-grey2'
}