import * as React from 'react'
import * as Assets_ from '../Assets_'

export class Modal extends React.Component<{isOpen:boolean, marginTop?:string,title?:string, 
subtitle?:string,height?:string,width?:string, left?:string, close?: () => void,
right?:string, top?:string, bottom?:string, modalTop_1?:boolean, background?:string, marginLeft?:string}, {}> {

    public modalStyle = { left:'auto', right:'auto', top:'auto', bottom:'auto',
                   height:'auto', marginTop:'0px', marginLeft:'0px', width:'100%', 
                   position:'absolute' as 'absolute', background:'#f0f0f0', zIndex:9999, 
                   borderRadius:'10px', padding:'20px', border: '1px solid #999',
    }

    public mainDivStyle= {
        height:'99%',width:'99%',backgroundColor:'transparent',zIndex:9998,position:'absolute' as 'absolute', left: '5px',
    } 
/*
    constructor() {
        super()
    }
*/
/*
    example(){
        Logic.Type.New(Logic.Main.Instance.Modal,{Modal1:!Logic.Main.Instance.Modal.Modal1})
    }
*/
    public render() {

    if (this.props.modalTop_1) {
        this.modalStyle.zIndex = 9997
        this.mainDivStyle.zIndex = 9996
    }

    if(this.props.isOpen == false) {
        return null
    } else {
        if(this.props.height) {this.mainDivStyle.height = this.props.height}
        if(this.props.width) {this.mainDivStyle.width = this.props.width}
/*
        if(this.props.left) {this.modalStyle.left = this.props.left}
        if(this.props.right) {this.modalStyle.right = this.props.right}
        if(this.props.top) {this.modalStyle.top = this.props.top}
        if(this.props.bottom) {this.modalStyle.bottom = this.props.bottom}
        if(this.props.marginTop) {this.modalStyle.marginTop = this.props.marginTop}
        if(this.props.marginLeft) {this.modalStyle.marginLeft = this.props.marginLeft}

        if(this.props.background) {
            this.modalStyle.background = this.props.background
        }
*/
        return(
            <div style={this.mainDivStyle}>
                <div style={this.modalStyle}>
                    {this.props.title && this.props.title.length > 0 ? 
                    <h3>{this.props.title}{this.props.subtitle ?
                        <small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.subtitle}</small>
                    : void 0}
                    </h3> 
                    : void 0}
                    {this.props.children ? this.props.children : null}
                    <div className = "clearfix"/>
                </div>
                {this.props.close ?
                <button
                    // className = "btn btn-default" 
                    onClick = {this.props.close}
                    style = {{
                        position: 'absolute',
                        right: '5px',
                        top: '5px',
                        background: 'transparent',
                        padding: '0px',
                        border: '0px',
                        color: '#333',
                        marginRight: '0px',
                        zIndex: 9999,
                    }}
                >
                    <i className = {Assets_.faCancelClass}/>
                </button> : void 0}
            </div>
        )}
    }
}

