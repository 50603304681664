import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet, isUserAdmin} from '../../../Logic/Utils' 
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getLanguagesCount() {

    if (Main.Instance.LanguageState.countCalled === false) {
        Logic.Type.New(Main.Instance.LanguageState, {countCalled:true})
        
        // DbRoutes.Initialize()
        DbRoutes.GetLanguages()
        DbRoutes.GetAllLanguages()
    }
}
/*
function getMainLanguageCode(): string {
    if (Main.Instance.DatabaseDataState.languagesStream)
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.languagesStream.length; i++) {
        if (Main.Instance.DatabaseDataState.languagesStream[i]['main'] &&
            Main.Instance.DatabaseDataState.languagesStream[i]['main'].toString() === 'true') {
            return Main.Instance.DatabaseDataState.languagesStream[i]['code'].toString()
        }
    }
    return 'en'
}
*/
/*
function getCode(): string {
    if (Main.Instance.DatabaseDataState.languagesStream)
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.languagesStream.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.languagesStream[i]['id'].toString().trim()) === 
        parseInt(Main.Instance.LanguageState.code)) {
            return Main.Instance.DatabaseDataState.languagesStream[i]['code'].toString().trim()
        }
    }
    return ''
}
*/
/*
function getName(): string {
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.languagesStream.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.languagesStream[i]['id'].toString().trim()) === 
        parseInt(Main.Instance.LanguageState.code)) {
            return Main.Instance.DatabaseDataState.languagesStream[i]['code'].toString().trim()
        }
    }
    return ''
}
*/
function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {site_id: stream[0]['id']})
        DbRoutes.GetLanguages()
        FirstPage()
    }
}

function handleDelete(row: any) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Language + ' ' + Assets.withName + ' "' + row.original.name + '" ' + Assets.andPath + ' "' + row.original.path + '"?')) {

        if (Main.Instance.LanguageState.pNum === 
        (Math.trunc((Main.Instance.LanguageState.count - 2) / 
        Main.Instance.LanguageState.pSize) + 1)) {
            DbRoutes.RemoveLanguage (row.original.id.toString(), DeleteLastPage)
        } else {
            DbRoutes.RemoveLanguage (row.original.id.toString(), DeleteNotLastPage)
        }
    }
}

function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.currencies SET active = ', id.toString(), value, getLanguagesCount) }
}

function toggleMain(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.currencies SET main = ', id.toString(), value, getLanguagesCount) }
}

function handleEdit(row: any) { 
    gotoTop()
    // console.log (row)
    let active: string
    let main: string
    active = row.original.active.toString() === 'true' ? '1' : '2'
    main = row.original.main.toString() === 'true' ? '1' : '2'

    Logic.Type.New(Main.Instance.LanguageState, {
        mode: true,
        id: row.original.id.toString(), 
        code_id: row.original.code_id,
        code_id_saved: row.original.code_id,
        code: row.original.code,
        path: row.original.path,
        path_saved: row.original.path,
        name: row.original.name,
        native: row.original.native,
        direction: row.original.direction,
        flag_id: row.original.flag.toString(),
        flag_id_saved: row.original.flag.toString(),
        active,
        active_saved: active,
        main,
        main_saved: main,
    })
}
/*
function removeImage () {
    if (Main.Instance.LanguageState.id !== undefined && parseInt(Main.Instance.LanguageState.id) !== 0
    && confirm (Assets.confirmRemoveImage)) {
        Logic.Type.New (Main.Instance.LanguageState, {flag: Assets_.zeroPng})
        DbRoutes.Action('.languages SET flag = ' + Assets_.zeroPng, Main.Instance.LanguageState.id,
                        getLanguagesCount)
    }
}
*/
function handleInsertUpdate() {

    const languageItem = {
        id : !Main.Instance.LanguageState.id ? '' : Main.Instance.LanguageState.id,
        flag : !Main.Instance.LanguageState.path ? '' : Main.Instance.LanguageState.path.replace('\\','').replace('/',''),
        code_id : !Main.Instance.LanguageState.code_id ? '0' : Main.Instance.LanguageState.code_id,
        flag_id:Main.Instance.LanguageState.flag_id?Main.Instance.LanguageState.flag_id:'0',
        site_id: Main.Instance.FrontendStoreState.site_id,
        active : !Main.Instance.LanguageState.active ? '1' : Main.Instance.LanguageState.active,
        main : !Main.Instance.LanguageState.main ? '2' : Main.Instance.LanguageState.main,
    }

    if (parseInt(languageItem.active) === 1) { languageItem.active = 'true' }
    if (parseInt(languageItem.active) === 2) { languageItem.active = 'false' }

    if (parseInt(languageItem.main) === 1) { languageItem.main = 'true' }
    if (parseInt(languageItem.main) === 2) { languageItem.main = 'false' }

    if (parseInt(Main.Instance.LanguageState.code) === 0) {
        alert (Assets.compulsoryLanguageCode)
        return       
    } else if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
        return
    } else if (Main.Instance.LanguageState.mode === false) {
        let proceed: boolean = true

        for (const item of Main.Instance.DatabaseDataState.languagesAllStream) {
            if (languageItem['code_id'] === item['name']) {
                proceed = false
                break
            }
        }
        if (!proceed) {
            alert (Assets.LanguageAlreadyInDb)
        } else { DbRoutes.insertLanguage (languageItem, InsertLastPage) }
    } else {
        DbRoutes.updateLanguage (languageItem, Update)
    }
}

function ApplyFilterActive(text:string) {
    
    Logic.Type.New(Main.Instance.LanguageState, {filter_active:text})
    FirstPage()
}

function getLanguage() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {languagesStream : undefined})
}

function  clearFields() {

    Logic.Type.New(Main.Instance.LanguageState, {
        code_id : '0',
        code: '',
        direction: '',
        name: '',
        path: '',
        native: '',
        mode : false,
        active: '1',
        main: '2',
        flag_id: '0',
        // flag: Assets_.zeroPng
    })
}

function gotoInsertMode() {

    if (
        parseInt(Main.Instance.LanguageState.code_id) === parseInt(Main.Instance.LanguageState.code_id_saved)
        && parseInt(Main.Instance.LanguageState.flag_id) === parseInt(Main.Instance.LanguageState.flag_id_saved)
        && Main.Instance.LanguageState.path === Main.Instance.LanguageState.path_saved
        && parseInt(Main.Instance.LanguageState.active) === parseInt(Main.Instance.LanguageState.active_saved)
        && parseInt(Main.Instance.LanguageState.main) === parseInt(Main.Instance.LanguageState.main_saved)
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {

    Logic.Type.New(Main.Instance.LanguageState,{offset:(0).toString()})

    getLanguage()
    Logic.Type.New(Main.Instance.LanguageState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.LanguageState.pNum > 0) {

        Logic.Type.New(Main.Instance.LanguageState, {
            offset:((Main.Instance.LanguageState.pNum - 1) * 
            Main.Instance.LanguageState.pSize).toString()})
        getLanguage()

        Logic.Type.New(Main.Instance.LanguageState,{pNum: Main.Instance.LanguageState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.LanguageState.pNum + 1) * Main.Instance.LanguageState.pSize < 
    Main.Instance.LanguageState.count) {

        Logic.Type.New(Main.Instance.LanguageState, {
            offset: ((Main.Instance.LanguageState.pNum + 1) * 
            Main.Instance.LanguageState.pSize).toString()})
        getLanguage()
        
        Logic.Type.New(Main.Instance.LanguageState,{pNum: Main.Instance.LanguageState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.LanguageState.count <= Main.Instance.LanguageState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.LanguageState.count === 0) {

            Logic.Type.New(Main.Instance.LanguageState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.LanguageState.count - 1) / 
                    Main.Instance.LanguageState.pSize)
            Logic.Type.New(Main.Instance.LanguageState, {
                offset:(npNum * Main.Instance.LanguageState.pSize).toString()})
        }

        getLanguage()

        Logic.Type.New(Main.Instance.LanguageState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.LanguageState, {count:Main.Instance.LanguageState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.LanguageState,{count:Main.Instance.LanguageState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.LanguageState,{count:Main.Instance.LanguageState.count --})
    getLanguage()
}

function Update() {
    clearFields()
    getLanguage()
}

function RecordsPerPageChanged(text:string) {

    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.LanguageState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    // console.log (Main.Instance.LanguageState.holding_id)

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream: undefined, sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,      
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getLanguagesCount()
    }
}

function ApplyLanguageSelect(text:string) {
    let code: string
    let name: string
    let native: string
    let direction: string
    let count: number = 1
    for (const language of Main.Instance.DatabaseDataState.languagesAllStream) {
      if (count === parseInt(text)) {
        // console.log (language['code'])
        // console.log (language['name'])
        code = language['code']
        name = language['name']
        native = language['native']
        direction = language['direction']
        break
      }
      count++
    }    
    Logic.Type.New(Main.Instance.LanguageState, {code_id:text, code, name, native, direction})
}

function ApplyFlagSelect(text:string) {
    // console.log (text)
    Logic.Type.New(Main.Instance.LanguageState, {flag_id:text})
}

export function Languages() {
    if (!Main.Instance.DatabaseDataState.languagesAllStream) {
        DbRoutes.GetAllLanguages()
    }

    if (Main.Instance.FrontendStoreState.first_time) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {
                first_time: false,
            })
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId),
                                                SelectSite)
            } /*else if (isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId))
            }*/
    }

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    // console.log (Main.Instance.DatabaseDataState.FlagsStreamAll)
    return (
    <div>

    <div className = "clearfix">

        <h3 className = "level fl">
            {isUserAdmin() ? Assets.aeLanguage : Assets.vLanguage}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => {ApplyMasterSelect(text)}}
            icon = {Assets_.faMasterClass}
        /> : null}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => {ApplyOperatorSelect(text)}}
            icon = {Assets_.faCompanyClass}
        /> : null
        }

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => {ApplySiteSelect(text)}}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0
        }

    </div>
{/*
    <div className = "clearfix">

        <h3 className = "level fl">
            <small>
            {Assets.SetDefaultImage}
            </small>
        </h3> 

        {!Main.Instance.FrontendStoreState.shopItemDefaultFilename || 
        Main.Instance.FrontendStoreState.shopItemDefaultFilename.length === 0 ?
        <span 
            style = {{
                float:'left',
                display:'block',
                width:'10%',
                marginTop:'18px',
                marginRight:'2%',
                height: '30px',
                fontSize:'20px'
            }}
            className = "label label-warning"
        >
            {Assets.NoImage}
        </span> 
        :
        <div 
            style = {{
                position: 'relative',
                height:'50px',
                maxWidth:'18%',
                minWidth:'100px',
                marginTop:'10px',
                marginRight:'2%',
                float:'left',
                display: 'block',
                border:'1px solid #999'
            }}
        >
            <img 
                src = {Main.Instance.FrontendStoreState.shopItemDefaultFilename} 
                style = {{
                    height:'100%',
                    width:'auto',
                    position:'absolute',
                    zIndex:9998,
                    left:'0px',
                    right:'0px',
                    top:'0px',
                    bottom: '0px',
                    margin: 'auto'
                }}
            />
        </div>
        }
        <input
            accept = "image/png, image/jpg, image/jpeg, image/gif"
            id = "shopitem-defaultinput" 
            className = "uploadShopItemWrapper"
            style = {{marginTop:'20px'}}
            type = "file" 
            onChange = {(event) => { 
                // let clonefile = Main.Instance.FrontendStoreState.logo
                let cloneFile:File
                Object.keys(event.target.files).forEach(key => {
                if (!event.target.files[key].name.includes(Assets._jpg)) {
                        return alert(Assets.OnlyJpg)
                }
                cloneFile = event.target.files[key]

                // console.log (event.target.files[key].name)
                })
                DbRoutes.uploadShopItemDefaultImage(cloneFile)
                // Logic.Type.New(Main.Instance.FrontendStoreState, {
                        // shopItemDefaultUploadedFilename: cloneFile[0].name})
            }}
            onClick = {(event) => {event.target = null}}
        />

    </div>
    <br/>
*/}
    <div className = "clearfix"/>
    <div style = {{position: 'relative'}} className = "row admin-users-container clearfix">
        <br/>
        {IsBuildScandibet() && Main.Instance.LanguageState.mode || !IsBuildScandibet() ?
        <div 
            className = {Main.Instance.LanguageState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
        >
            <div className = "create-input">
                <div className = "row admin-users-row">
                    <h4 className = "fl">
                        {Main.Instance.LanguageState.mode ?
                        parseInt(Main.Instance.LanguageState.main) === 1 ?
                        Assets.editMainLanguage + ': ' +
                        Main.Instance.LanguageState.name
                        :
                        Assets.editLanguage + ': ' +
                        Main.Instance.LanguageState.name
                        :
                        Assets.addLanguage}
                    </h4>

                    {Main.Instance.LanguageState.mode ? 
                    <button
                        style = {{position:'absolute', right: '-7px', top: '-3px', background: 'transparent', border: '0px'}}
                        title = {Assets.discardChanges} 
                        onClick = {() => gotoInsertMode()} 
                        className = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                    >
                        <i className = {Assets_.faCancelButtonClass}/>
                    </button> : void 0}
                </div>

                <div className = "row admin-users-row">
                    <FormSelect
                        wide = {true}
                        value = {!Main.Instance.LanguageState.code_id ? '0' :
                                Main.Instance.LanguageState.code_id}
                        data = {Main.Instance.DatabaseDataState.languagesAllStream}
                        ClassName = "form-control"
                        o1 = {Assets.SelectLanguage}
                        o2 = {Assets.SelectLanguage}
                        onChange = {(text) => ApplyLanguageSelect(text)}
                        icon = {Assets_.faLanguageClass}
                    />
                    <div className = "fl">&nbsp;&nbsp;&nbsp;</div>
                    <FormInput 
                        narrow = {true}
                        value = {!Main.Instance.LanguageState.path ? '' :
                                Main.Instance.LanguageState.path} 
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => Logic.Type.New(Main.Instance.LanguageState, {path:text})} 
                        placeholder = {Assets.Path}
                        // icon = {Assets_.faLinkClass}
                        iconText = "/"
                        iconTextSize = "14px"
                    />
                    
                    {Main.Instance.LanguageState.code && Main.Instance.LanguageState.code.length > 0 ?
                    <span
                        title = {Assets.LanguageCode}
                        className = "label label-default"
                        style = {{
                            fontSize:'16px',
                            padding:'4px 6px',
                            fontWeight: 700,
                            display:'block',
                            float:'left',
                            marginTop:'3px',
                            marginLeft:'1%',
                        }}
                    >{Main.Instance.LanguageState.code}
                    </span> : void 0}

                    {/*Main.Instance.LanguageState.path && Main.Instance.LanguageState.path.length > 0 ?
                    <span
                        title = "Language path"
                        className = "label label-default"
                        style = {{
                            fontSize:'16px',
                            padding:'4px 6px',
                            fontWeight: 700,
                            display:'block',
                            float:'left',
                            marginTop:'3px',
                            marginLeft:'1%'
                        }}
                    >{Main.Instance.LanguageState.path}
                    </span> : void 0*/}

                    {Main.Instance.LanguageState.native && Main.Instance.LanguageState.native.length > 0 ?
                    <span
                        title = {Assets.LanguageNative}
                        className = "label label-default"
                        style = {{
                            fontSize:'16px',
                            padding:'4px 6px',
                            fontWeight: 700,
                            display:'block',
                            float:'left',
                            marginTop:'3px',
                            marginLeft:'1%',
                        }}
                    >{Main.Instance.LanguageState.native}
                    </span> : void 0}
                    
                    {Main.Instance.LanguageState.direction && Main.Instance.LanguageState.direction.length > 0 ?
                    <span
                        title = {Assets.LanguageDirection}
                        className = "label label-default"
                        style = {{
                            fontSize:'16px',
                            padding:'4px 6px',
                            fontWeight: 700,
                            display:'block',
                            float:'left',
                            marginTop:'3px',
                            marginLeft:'1%',
                        }}
                    >{Main.Instance.LanguageState.direction}
                    </span> : void 0}

                    <RadioButton2
                        value = {!Main.Instance.LanguageState.active ? '1' :
                                Main.Instance.LanguageState.active} 
                        text = {Assets.Activity}
                        ClassName = "input-group-radiobutton2 margin-bottom-sm fr ml" 
                        title = {Assets.ActivityDesc}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => {
                            Logic.Type.New(Main.Instance.LanguageState, {active:text})}} 
                    />

                    <FormSelect
                        wide = {true}
                        floatRight = {true}
                        value = {!Main.Instance.LanguageState.flag_id ? '0' :
                                Main.Instance.LanguageState.flag_id}
                        data = {Main.Instance.DatabaseDataState.FlagsStreamAll}
                        ClassName = "form-control"
                        o1 = {Assets.SelectFlag}
                        o2 = {Assets.SelectFlag}
                        onChange = {(text) => ApplyFlagSelect(text)}
                        icon = {Assets_.faCountrylgClass}
                    />

                    {Main.Instance.DatabaseDataState.FlagsStreamAllOrder &&
                    parseInt(Main.Instance.LanguageState.flag_id) > 0 && 
                    Main.Instance.DatabaseDataState.FlagsStreamAllOrder[
                    parseInt(Main.Instance.LanguageState.flag_id) - 1]
                    ?
                        <img 
                            src = {Main.Instance.DatabaseDataState.FlagsStreamAllOrder[
                                parseInt(Main.Instance.LanguageState.flag_id) - 1]['image']}
                            style = {{
                                float: 'right',
                                display: 'block',
                                marginTop: '5px',
                                height:'20px',
                                width:'auto',
                                zIndex:9998,
                                marginRight:'1%',
                                border:'1px solid #999',
                            }}
                        />
                    :
                        <span 
                            style = {{ 
                                float: 'right',
                                display: 'block',
                                // width:'10%',
                                marginRight: '1%',
                                height: '30px',
                                fontSize:'16px',
                                paddingLeft:'12px',
                                paddingRight:'12px',
                                paddingTop:'7px',
                            }}
                            className = "label label-warning"
                        >
                            {Assets.NoFlag}
                        </span>
                    }
{/*
                    <input  
                        accept = "image/png, image/jpg, image/jpeg, image/gif"
                        id = "language-input" 
                        className = "UploadShopItemWrapper" 
                        type = "file" 
                        style = {{float: 'right'}}
                        onChange = {(event) => { 
                            let cloneFile: File = event.target.files[0]
                            let reader = new FileReader()
                            reader.onload = (e) => {
                                Logic.Type.New (Main.Instance.LanguageState, {flag: reader.result})
                            }
                            reader.readAsDataURL(cloneFile);
                        }}
                        onClick = {(event) => {event.target = null}}
                    />*/}
                    {/*Main.Instance.LanguageState.flag_id !== 0 ?
                    <div 
                        style = {{
                            // background:'#fff',
                            position: 'relative',
                            height:'20px',
                            maxWidth:'18%',
                            width:'auto',
                            marginTop:'5px', 
                            marginRight:'2%',
                            float:'right',
                            display: 'block'
                        }}
                    >
                        <img 
                            src = {Main.Instance.LanguageState.flag} 
                            style = {{
                                height:'100%',
                                width:'auto',
                                zIndex:9998,
                                display:'block', border:'1px solid #999'
                            }}
                        />
                        <button 
                            className = "btn btn-xs btn-nothing"
                            title = {Assets.RemoveImage}
                            style = {{position:'absolute',zIndex:9999, right:'-7px', top:'-5px', marginRight:'0px'}}
                            onClick = {() => removeImage()}
                        >
                            <i className = {Assets_.faCancelClass}/>
                        </button>
                    </div>
                    :
                    <span 
                        style = {{ 
                            float:'right',
                            display:'block',
                            // width:'10%',
                            marginRight:'1%',
                            height: '30px',
                            fontSize:'16px',
                            paddingLeft:'12px',
                            paddingRight:'12px',
                            paddingTop:'7px',
                        }}
                        className = "label label-warning"
                    >
                        {Assets.NoImage}
                    </span>*/}
                </div>
                {isUserAdmin() ?
                <div className = "row admin-users-container clearfix">
                    <ButtonIcon 
                        description = {!Main.Instance.LanguageState.mode ? 
                                        Assets.saveChanges : 
                                        Assets.updateChanges} 
                        click = {() => handleInsertUpdate()}
                        ClassName = {Main.Instance.LanguageState.mode ? 
                                    'btn btn-warning btn-sm btn-plus fr mt0' :
                                    'btn btn-primary btn-sm btn-plus fr mt0'}
                        Icon = {faSave as IconDefinition}
                        text = {Assets.Save}
                    />
                </div> : void 0}
            </div>
        </div> : void 0}

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.LanguageState.pSize ? '' : 
                Main.Instance.LanguageState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
{/*
            <RadioButton3

                value = {!Main.Instance.LanguageState.showActive ? false : 
                        Main.Instance.LanguageState.showActive}
                ClassName = "input-group-radiobutton3c margin-bottom-sm" // "mr" option
                text = {Assets.ShowJustActive}
                title = {Assets.ShowJustActiveDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => {Logic.Type.New (Main.Instance.LanguageState, {
                           showActive: !Main.Instance.LanguageState.showActive})
                           getLanguagesCount()}}/>

            <RadioButton3

                value = {!Main.Instance.LanguageState.showFreeRound ? false : 
                        Main.Instance.LanguageState.showFreeRound}
                ClassName = "input-group-radiobutton3c margin-bottom-sm" // "mr" option
                text = {Assets.ShowJustFreeRound}
                title = {Assets.ShowJustFreeRoundDesc}
                i1 = {Assets_.active}
                i2 = {Assets_.inactive}
                onClick = {() => {Logic.Type.New (Main.Instance.LanguageState, {
                           showFreeRound: !Main.Instance.LanguageState.showFreeRound})
                           getLanguagesCount()}}/>
*/}

            <FormSelect 
                span = {true}
                value = {!Main.Instance.LanguageState.filter_active ? '0' : 
                        Main.Instance.LanguageState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {(text) => ApplyFilterActive(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.LanguageState.count ? Main.Instance.LanguageState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.LanguageState.count ? Main.Instance.LanguageState.pNum * 
                Main.Instance.LanguageState.pSize + 1 : 0}{' - '}
                {Main.Instance.LanguageState.count ? 
                ((Main.Instance.LanguageState.pNum + 1) * 
                Main.Instance.LanguageState.pSize > Main.Instance.LanguageState.count ? 
                Main.Instance.LanguageState.count : 
                (Main.Instance.LanguageState.pNum + 1) * Main.Instance.LanguageState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.LanguageState.count ? 
                Main.Instance.LanguageState.pNum + 1 :0}{' / '}
                {Main.Instance.LanguageState.count ? 
                Math.trunc((Main.Instance.LanguageState.count - 1) /
                Main.Instance.LanguageState.pSize + 1) : 0}
            </span>
        </div>
        <h3>{Assets.tLanguage}</h3>
        {Main.Instance.DatabaseDataState.languagesStream ?
        IsBuildScandibet() ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.languagesStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {Header: Assets.Code, accessor: 'code', headerClassName: 'th-left'},
                {Header: Assets.Path, accessor: 'path', headerClassName: 'th-left'},
                {Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {Header: Assets.Native, accessor: 'native', headerClassName: 'th-left'},
                {Header: Assets.Direction, accessor: 'direction', headerClassName: 'th-left'},
                {Header: Assets.Flag, accessor: 'flag', Cell: (row) => TableUtils.Flag(row.value)},
                {minWidth: 50, Header: Assets.Main, accessor: 'main', Cell: (row) => TableUtils.TB(row, toggleMain)},
                {minWidth: 70, Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> :
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.languagesStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {Header: Assets.Code, accessor: 'code', headerClassName: 'th-left'},
                {Header: Assets.Path, accessor: 'path', headerClassName: 'th-left'},
                {Header: Assets.Name, accessor: 'name', headerClassName: 'th-left'},
                {Header: Assets.Native, accessor: 'native', headerClassName: 'th-left'},
                {Header: Assets.Direction, accessor: 'direction', headerClassName: 'th-left'},
                {Header: Assets.Flag, accessor: 'flag', Cell: (row) => TableUtils.Flag(row.value)},
                {Header: Assets.Main, accessor: 'main', Cell: (row) => TableUtils.TB(row, toggleMain)},
                {Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />
    : getLanguagesCount()}

        </div>
    </div>)
}