import * as React from 'react';
import {DatamapSwitzerlandSmall} from './DatamapSwitzerlandSmall';
import {Example} from './Example';
import * as d3geo from 'd3-geo'
import {Region} from '../../../Logic/Database/DatabaseData'
import { Main } from '../../../Logic/Main'
// import {numberWithCommas} from '../../../Logic/Utils'
// import {SwitzerlandCantonsData, SwitzerlandBiggestCities, GCBBaden, MapCity} from './SwitzerlandData'
import {switzerlandColor, switzerlandCantonsBorderColor/*, SwitzerlandCantonsColors*/} from './SwitzerlandResources'
// import {getColorByCanton} from '../MainDashboard/Legend'
export default class ZoomSwitzerlandSmall extends React.Component<{regions: Region[], region: boolean},{}> {
    public setProjection(element) {
        const projection = d3geo.geoMercator()  // .geoEquirectangular()
            .center([8.28, 46.82]) // 12.6, 46.79
            .rotate([0, 0]) // [4.4, 0]
            .scale(2500)
            .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
        const path = d3geo.geoPath()
            .projection(projection);

        return { path, projection }
    }
/*
    getFillKey(canton: number): string {
        return canton > -1 ? getColorByCanton(canton) : switzerlandColor
    }
*/

    // ok current function just displays which cantos are selected. which is fine, no change should be seen here
    public getFills(): any {
        const fills = {}
        fills['defaultFill'] = switzerlandColor
        fills['WHITE'] = 'white'
        fills['TEXT'] = 'black'
        // fills['CITY'] = 'red'
        if (this.props.region) {
            for (let i: number = 0; i < this.props.regions.length; i++) {
                fills[(this.props.regions[i].country ? this.props.regions[i].country.toUpperCase() : '') + '.' + (this.props.regions[i].code ? this.props.regions[i].code.toUpperCase() : '')] = Main.Instance.PlayerInfo.regions[i] ? switzerlandColor : 'white' // this.getFillKey(item.id - 1)
            }
        }

        return fills
    }

    public getCantonAbbrev(name: any): string {
        for (const item of this.props.regions) {
            if (item.name === name) { return item.country ? item.country + '-' + item.code : item.code }
        }
        return '?'
    }

    public getCanton(name: any): string {
        return name + ' (' + this.getCantonAbbrev(name) +')'        
    }

    public render() {
/*
        const Bubbles: MapCity[] = Object.assign ([], SwitzerlandBiggestCities)
        Bubbles.push (GCBBaden)

        const Data: any = {}
        for (const item of SwitzerlandCantonsData) {
            Data[item.code] = ({fillKey: item.code})
        }
*/
        const Data: object = {}
        for (const item of this.props.regions) {
            Data[(item.country ? item.country.toUpperCase() : '') + '.' + (item.code ? item.code.toUpperCase() : '')] = {fillKey: (item.country ? item.country.toUpperCase() : '') + '.' + (item.code ? item.code.toUpperCase() : '')}
        }

        return (
            <Example label = "Switzerland">
                <DatamapSwitzerlandSmall
                    scope = "che" // cantons
                    setProjection = {this.setProjection}
                    geographyConfig= { {
                        // data: Data,
                        // dataUrl: './switzerland.json',
                        // dataJson: Data,
                        popupOnHover: true,
                        hideAntarctica: true,
                        highlightOnHover: false,
                        borderColor: switzerlandCantonsBorderColor,                   
                        borderWidth: 0.5,
                        // highlightFillColor: () => switzerlandColor,
                        // highlightFillColor: Main.Instance.DatabaseDataState.selected,
                        // this function should just return a string

                        popupTemplate: (geography, data) => {
/*
                            let area, population: number
                            for (const item of SwitzerlandCantonsData) {
                                if (geography.id === item.code) {
                                    area = item.area
                                    population = item.population
                                    break
                                }
                            }
                            
*/
                            // const name = 
                            return (
                                `<div class = "geography-properties-box">
                                    <div class = "geography-properties-title">` + this.getCanton(geography.properties.name) + `</div>
                                </div>`)
/*                                
                            return (
                                `<div class = "geography-properties-box">
                                    <div class = "geography-properties-title">` + geography.properties.name + `</div>
                                    <div> Population: ` + (population ? numberWithCommas(population) : 0) + `</div>
                                    <div> Area: ` + (area ? numberWithCommas(area) : 0) + ` km<sup>2</sup></div>
                                    <div> Density: ` + (population && area ? numberWithCommas((population/area).toFixed(1)) : 'unknown') + `/km<sup>2</sup></div>
                                </div>`)
*/
                        },
                    }}

                    data = {Data}
                    fills = {this.getFills()}
                    // bubbles = {Bubbles}
                    bubbleOptions={{borderWidth: 0}}
/*                    
                    popupTemplate: (geo, data) =>
                    `<div class="hoverinfo">Bubble for ${data.name}`
*/
                />
            </Example>
        )
    }
}