import {Logic} from '../Type'
import {IGeneral} from '../Database/DatabaseData'

export enum GameCategories {
    Slots = 'Slots',
    VideoPoker = 'Video Poker',
    Roulette = 'Roulette',
    BlackJack = 'Black Jack',
    LiveCasino = 'Live Casino',
}

export interface IGameCategoryContributions {
    // category: string
    contribution: number
    games?: number[] // IGameCategoryContributionGame[]
}
/*
export interface IGameCategoryContributionGame {
    id: number
    name: string
}
*/
export interface ICategory {
    // lowerThreshold?: number
    lowerInclusive?: boolean
    // upperThreshold?: number
    upperInclusive?: boolean
    minMaxBets: ICurrencies[]
}

export interface ICategories {
    active: boolean
    categories: ICategory[]
    }

export interface ISignupBonus {
    rewardId?: number
    active?: boolean
}

export interface ICurrencies {
    code: string
    minBet?: string
    maxBet?: string
}
/*
export interface IMissionMinimalBets {
    active?: boolean
    minimalBets?: ICurrencies[]
}
*/
interface IAmountMinMaxConstraint {
    code: string
    minValue?: number
    maxValue?: number
}

interface IMissionAmountConstraints {
    active: boolean
    minAmounts: IAmountMinMaxConstraint[]
}

export interface IGlobalObjectiveConfiguration {
    betsConfiguration?: IMissionAmountConstraints,
    depositsConfiguration?: IMissionAmountConstraints,
    withdrawalsConfiguration?: IMissionAmountConstraints,
}

export interface ICurrencyContributions {
    active: boolean
    contributions: IContributions[]
}

interface IContributions {
    code: string
    value?: string
}

export interface IGlobalRacesConfiguration {
    durationMinutes?: number,
    startMinutes?: number,
}

export class GeneralState extends Logic.Type {
    public category: number = -2
    public general: IGeneral
    public general_: string
    public selectedCategory: number = -1
    public selectedTable: number = -1
    public selectedGameId: string  = '-1'
    public selectedGameName: string  = '-1'
    public filtered: [] = [] 
}

export class CurrencyState extends Logic.Type {
    public countCalled: boolean = false
    public pNum: number = 0
    public pSize: number = 100
    /*titleSize: number = 30*/
    public count: number = 0
    public mode: boolean = false
    public offset: string = '0'
    public id:string
    public code:string = '0'
    public code_saved:string
    public Code:string
    // Code_saved:string
    public active:string = '1'
    public active_saved:string
    public main:string = '2'
    public main_saved:string
    public frmc:string = '2'
    public frmc_saved:string
    public rate: string = '1'
    public rate_saved: string
    public filter_active: string = '0'
    // sort:number[] = []
    // Sort:string[] = []    
}