import * as React from 'react'
import { Main } from 'src/Logic/Main'
import { Logic } from 'src/Logic/Type'
import axios from 'axios'
import * as Config from 'src/Logic/Config'
import ReactTable from 'react-table'
// import * as Assets_ from '../../Assets_'
import * as Assets from '../../Assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faFilter} from '@fortawesome/free-solid-svg-icons'

enum Search {
    Username, FirstName, LastName, MainEntity, OtherEntities
}

export function getOptions(all: boolean): JSX.Element[] {
    const o: JSX.Element[] = []
    if (all) {
        o.push(<option value = "" key = "0">Select other entity</option>)
    } else {
        o.push(<option value = "" key = "0">Select main entity</option>)
    }
    for (const item of (all ? Main.Instance.DatabaseDataState.cps : Main.Instance.DatabaseDataState.cp)) {
        o.push(<option value = {item.id} key = {item.id}>{item.contractualpartner}</option>)
    }
    return o
}
/*
function ToggleEntity(i: number, found: boolean) {
    const entities = Object.assign([], Main.Instance.DatabaseDataState.entities)
    if (found) {
        entities[i] = false
    } else {
        entities[i] = true
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {entities})
}
*/
function setEntities(e: number, value: string) {
    const search: string[]  = Object.assign ([], Main.Instance.DatabaseDataState.search)
    search[e] = value
    Logic.Type.New (Main.Instance.DatabaseDataState, {search})
}

function isIn(values: number[], value: number) {
    // console.log (values)
    // console.log (value)
    for (const item of values) {
        if (item === value) {
            return true
        }
    }
    return false
}

export function AssignAdminUsers(props: React.Props<any>): JSX.Element {
    if (Main.Instance.DatabaseDataState.users === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {users: null})
        axios.get(Config.asNewUser, { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
        .then((response) => {
            axios.patch(Config.asCP, {}, { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } })
            .then((Response) => {
                const cps: any[] = [{id: '0', contractualpartner: 'Gamanza'}]
                for (const item of Response.data) {
                    if (+item.id < 16 && +item.id !== 8) {
                        continue
                    }
                    cps.push(item)
                }
                Logic.Type.New(Main.Instance.DatabaseDataState, {users: response.data, cps, cp: Response.data})
            })
        })
    }
    // custom.environmentsAllowed
    // console.log (Main.Instance.DatabaseDataState.users)
/*
    const oe: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.cps) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.cps.length; i++) {
            const found: boolean = Main.Instance.DatabaseDataState.entities[i]
            oe.push(<button onClick = {() => ToggleEntity(i, found)} style = {{paddingTop: '2px', paddingBottom: '1px'}} key = {Main.Instance.DatabaseDataState.cps[i].contractualpartner} className = {found ? 'btn btn-warning btn-sm' : 'btn btn-default btn-sm'}>{Main.Instance.DatabaseDataState.cps[i].contractualpartner}</button>)
        }
    }
*/
    const filtered = []
    if (Main.Instance.DatabaseDataState.search[Search.Username].length > 0) {
        filtered.push({id: 'username', value: Main.Instance.DatabaseDataState.search[Search.Username]})
    }

    if (Main.Instance.DatabaseDataState.search[Search.FirstName].length > 0) {
        filtered.push({id: 'firstname', value: Main.Instance.DatabaseDataState.search[Search.FirstName]})
    }

    if (Main.Instance.DatabaseDataState.search[Search.LastName].length > 0) {
        filtered.push({id: 'lastname', value: Main.Instance.DatabaseDataState.search[Search.LastName]})
    }

    if (Main.Instance.DatabaseDataState.search[Search.MainEntity].length > 0) {
        filtered.push({id: 'entity_id', value: Main.Instance.DatabaseDataState.search[Search.MainEntity]})
    }

    if (Main.Instance.DatabaseDataState.search[Search.OtherEntities].length > 0) {
        filtered.push({id: 'entityid', value: Main.Instance.DatabaseDataState.search[Search.OtherEntities]})
    }

    return (<div>
        <div><h3>Assign Admin Users</h3></div>
        {Main.Instance.DatabaseDataState.users ?
        <div className = "pr">
            <div style = {{position: 'absolute', top: '-30px', right: '0px'}}>
                {/*oe*/}
                <input onChange = {(e) => setEntities(Search.Username, e.target.value)} type = "text" style = {{width: '150px', height: '22px', marginRight: '5px', paddingLeft: '20px'}} placeholder = "Username" value = {Main.Instance.DatabaseDataState.search[Search.Username]}/>
                <input onChange = {(e) => setEntities(Search.FirstName, e.target.value)} type = "text" style = {{width: '150px', height: '22px', marginRight: '5px', paddingLeft: '20px'}} placeholder = "First name" value = {Main.Instance.DatabaseDataState.search[Search.FirstName]}/>
                <input onChange = {(e) => setEntities(Search.LastName, e.target.value)} type = "text" style = {{width: '150px', height: '22px', marginRight: '5px', paddingLeft: '20px'}} placeholder = "Last name" value = {Main.Instance.DatabaseDataState.search[Search.LastName]}/>
                <select onChange = {(e) => setEntities(Search.MainEntity, e.target.value)} style = {{background: '#fff', width: '200px', height: '22px', marginRight: '5px', paddingLeft: '20px'}} placeholder = "Main entity" value = {Main.Instance.DatabaseDataState.search[Search.MainEntity]}>{getOptions(false)}</select>
                <select onChange = {(e) => setEntities(Search.OtherEntities, e.target.value)} style = {{background: '#fff', width: '200px', height: '22px', marginRight: '5px', paddingLeft: '20px'}} placeholder = "Other entities" value = {Main.Instance.DatabaseDataState.search[Search.OtherEntities]}>{getOptions(true)}</select>
            </div>
            <FontAwesomeIcon style = {{ position: 'absolute', top: '-26px', right: '186px' }} icon = {faFilter}/>
            <FontAwesomeIcon style = {{ position: 'absolute', top: '-26px', right: '391px' }} icon = {faFilter}/>
            <FontAwesomeIcon style = {{ position: 'absolute', top: '-26px', right: '546px' }} icon = {faSearch}/>
            <FontAwesomeIcon style = {{ position: 'absolute', top: '-26px', right: '701px' }} icon = {faSearch}/>
            <FontAwesomeIcon style = {{ position: 'absolute', top: '-26px', right: '856px' }} icon = {faSearch}/>

            <ReactTable
                defaultFilterMethod = {(filter, row) => filter.id === 'entity_id' ? row[filter.id].toString() === filter.value.toString() :
                    filter.id === 'entityid' ? isIn(row[filter.id], +filter.value) :
                    row[filter.id] && row[filter.id].toString().toLowerCase().indexOf(filter.value.toLowerCase().toString()) !== -1}
                filtered = {filtered}
                data = {Main.Instance.DatabaseDataState.users}
                columns = {[
                    {accessor: 'id', Header: <div className = "pr">{Assets.ID}<span style = {{position: 'absolute', top: '0px', right: '0px'}}>env</span></div>, width: 60, className: 'normal-row pr', headerClassName: 'th-left nopaddingright', Cell: (row) => 
                    <div>
                        <div style = {{position: 'absolute', right: '13px', top: '0px'}}>
                            {getEnvironmentButtons(row.original.custom.environmentsAllowed, true)}
                        </div>
                        <div style = {{position: 'absolute', right: '0px', top: '0px'}}>
                            {getEnvironmentButtons(row.original.custom.environmentsAllowed, false)}
                        </div>
                        {row.value}
                    </div>
                },
                    {accessor: 'username', Header: Assets.Username, width: 150, className: 'normal-row', headerClassName: 'th-left'},
                    {accessor: 'firstname', Header: Assets.FirstName, width: 150, className: 'normal-row', headerClassName: 'th-left'},
                    {accessor: 'lastname', Header: Assets.LastName, width: 150, className: 'normal-row', headerClassName: 'th-left'},
                    {accessor: 'entity_id', Header: Assets.Main + ' ' + Assets.Entity.toLowerCase(), width: 200, className: 'normal-row', headerClassName: 'th-left', Cell: (row) => getMainEntity(row.value)},
                    {accessor: 'entityid', Header: 'Enable other entities', className: 'normal-row', headerClassName: 'th-left', Cell: (row) => getOtherEntities(row.index, row.value, row.original.entity_id)},
                ]}
                showPaginationTop = {true}
                showPaginationBottom = {false}
                pageSizeOptions = {[5, 10, 20, 25, 50, 100, 200, 500]}
                defaultPageSize={25}
                className="-striped -highlight"
            />
        </div>
        : void 0}
    </div>)
}

function toggleEntity (index: number, entity: number, found: boolean, entities: number[]) {
    // console.log (index, entity, found)
    const users: any[] = Object.assign([], Main.Instance.DatabaseDataState.users)
    if (!found) {
        users[index].entityid.push(entity)
    } else {
        let pos = -1
        for (let i: number = 0; i < entities.length; i++) {
            if (entities[i] === entity) {
                pos = i
                break
            }
        }
        if (pos !== -1) {
            users[index].entityid.splice(pos,1)
        }
    }
    axios.put(Config.asNewUserEntities, {id: users[index].id, entityid: users[index].entityid}, { headers: { 'x-api-key': Main.Instance.LoginState.token, 'x-api-user': Main.Instance.LoginState.entityId } }).then((response) => {
        Logic.Type.New (Main.Instance.DatabaseDataState, {users})
    })

}

function getOtherEntities(index: number, entities: number[], entity: number): JSX.Element {
    const oe: JSX.Element[] = []
    for (const item of Main.Instance.DatabaseDataState.cps) {
        let found: boolean = false
        let main: boolean = false
        for (const Item of entities) {
            if (Item === +item.id) {
                found = true
                if (Item === entity) {main = true}
                break
            }
        }

        oe.push(<button onClick = {() => toggleEntity(index, +item.id, found, entities)} disabled = {main || +item.id === 0} style = {{paddingTop: '2px', paddingBottom: '1px'}} key = {item.contractualpartner} className = {found ? main ? 'btn btn-primary btn-sm' : 'btn btn-info btn-sm' : 'btn btn-default btn-sm'}>{item.contractualpartner}</button>)
    }

    return <div>
        {oe}
    </div>
}

function getMainEntity(entityid: number):JSX.Element {
    let entity: string = 'Gamanza'
    // console.log (Main.Instance.DatabaseDataState.cp)
    for (const item of Main.Instance.DatabaseDataState.cp) {
        if (+item.id === entityid) {
            entity = item.contractualpartner
            break
        }
    }
    return <div>
        <span style = {{fontSize: '14px'}} className = "label label-primary label-sm">{entity}</span>
    </div>
}

const icons: string[] = ['D', 'E', 'S', 'P']
const iconsDesc: string[] = ['Dev', 'devEdge', 'Stage', 'Production']

function getEnvironmentButtons(e: boolean[], what: boolean): JSX.Element[] {
    // console.log (e)
    const eb: JSX.Element [] = []
    for (let i: number = (what ? 0 : 2); i < (what ? 2 :icons.length); i++) {
        eb.push(
            <div
                style = {{width: '12px', padding: '1px', textAlign: 'center', fontSize: '12px', lineHeight: '14px'}}
                key = {i.toString()}
                title = {iconsDesc[i] + ' ' + Assets.Environment}
                className = {e && e[i] ? 'label label-success label-sm dbl br0' : 'label label-danger label-sm dbl br0'}
            >
                {icons[i]}
            </div>
        )
    }
    return (eb)
}