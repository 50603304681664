import {Logic} from '../Type'
import * as moment from 'moment'
import { CustomSecurityRights } from '../UserManagement/UserManagement';
import {IPlayerInfoFiltered, IMissionLeaderboard} from '../Database/DatabaseData'

export enum Stat {Population, PlayerRegistration, PlayerRegistrationByPopulation}

export enum StatType {Cantons, Nationality, Age, Gender, AccountStatus, Hourly, Daily, InWeek, Monthly, Quarterly, Annual}

export class PlayerInfo extends Logic.Type {
    public nationality: boolean
    public nationalities: boolean[]

    public country: boolean
    public countries: boolean[]

    public region: boolean
    public regions: boolean[]

    public age: boolean
    public ages: boolean[]

    public gender: boolean
    public genders: boolean[]
    
    public accountStatus: boolean
    public accountStatuses: boolean[]

    public registered: boolean     // by month
    public registereds: boolean[]  // by month
    public Registereds: boolean[]  // by year
    public RegistereDs: boolean[]  // by day
    public RegistereQs: boolean[]  // by quarter
    public RegistereWs: boolean[]  // by day in week
    public RegistereMs: boolean[]  // by day in month
    // is it possible to do also by hour of day

    public showRegisteredQuarter: boolean = false
    public showRegisteredMonth: boolean = false
    public showRegisteredDate: boolean = false
    public showRegisteredDayInWeek: boolean = false
    public showRegisteredDayInMonth: boolean = false
    public showRegisteredCustomDate: boolean = false
    public showRegisteredCustomTime: boolean = false
    
    public registeredStart: moment.Moment = null
    public registeredEnd: moment.Moment = null
    public registeredStartTime: string
    public registeredEndTime: string
    public focusedInputStart: any = null
    public focusedInputEnd: any = null
    public statType: StatType = StatType.Cantons
    public stat: Stat = Stat.PlayerRegistration
    public c: boolean = false
}

export class GGRRevenueState extends Logic.Type {
    public dateFrom: number = -1
    public dateTo: number = -1
    public yearFrom: number = -1
    public yearTo: number = -1
    public monthFrom: number = -1
    public monthTo: number = -1
    public monthFromPart: number = -1
    public monthToPart: number = -1
    public initial: boolean = true
    public lastYear: number = undefined
    public longReport: boolean = true
    public gameId: number = -1
    public gameName: string = '0'
    public playerId: string = ''
    // public playerName: string = '0'
}

export class LogState extends Logic.Type {
    public count: number = 0
    public pNum: number = 0
    public pSize: number = 10
    public offset: string = '0'
    public distinctUserIdSorted: Array<{user_id: number, username: string, custom: CustomSecurityRights}>
    public distinctUserId: Array<{user_id: number, username: string, custom: CustomSecurityRights}>
    public distinctUserIdValue: string = '0'
    // distinctUserNameValue: string = '0'
    public distinctUserRoleValue: string = '0'
    // distinctEntityId: {entity_id: number, name: string}[]
    // distinctEntityIdValue: string = '0'
    // distinctEntityNameValue: string = '0'
    public distinctType: any[]
    public distinctTypeValue: string = '0'
    public distinctSubtype: any[]
    public distinctSubtypeValue: string = '0'
    public distinctDate: any[]
    public distinctDateValueStart: string = '0'
    public distinctDateValueEnd: string = '0'

    public currentId: string = '0'
    public currentName: string = '0'
    public toggle: boolean = false
}

export class ReportState extends Logic.Type {
    /* selection of timeframe - dropdown */
    public selectionTimeFrame:number = 0
    // this should always be zero and it is used to copy the value to the above
    public selectionTimeFrameInitial:number = 0

    /* selection of timeframe - dropdown */
    public selectionTimeFrameTime:number = 0
    // this should always be zero and it is used to copy the value to the above
    public selectionTimeFrameInitialTime:number = 0

    /* selection of report - dropdown */
    public selectionReport:number = 1
    // this should always be zero and it is used to copy the value to the above
    public selectionReportInitial:number = 0

    /* selection of report category - dropdown */
    public selectionReportCategory:number = 2
    // this should always be zero and it is used to copy the value to the above
    public selectionReportCategoryInitial:number = 0

    public selectionReportType:number = 1

    public holding_id : string = '0'
    public operator_id : string = '0'
    public site_id : string = '0'

    public pre_existing:boolean = true
    public rewarded:boolean = true
    public played:boolean = true
    public forfeited:boolean = true
    public remaining:boolean = true
    public pct_played_rewarded:boolean = true

    public nrp:boolean = true
    public nfr:boolean = true
    public val:boolean = true

    public currency : string = '0'
    public from_time:string = ''
    public to_time:string = ''
    public from_date:moment.Moment = null
    public to_date:moment.Moment = null

    public focused:boolean = false
    public focusedInput:any = null

    public pieCreator:boolean = false
}

export class ReportLevelsSublevelsState extends Logic.Type {
    public show: boolean[] = []
    public countCalled: boolean = false
    // pNum: number = 0
    // pSize: number = 100
    public count: number = 0
    public sublevel_min: number = 0
    public sublevel_max: number = 0
    public sublevels: number[]
    public data: any[]
    public colors: string[]
    public Colors: string[]
    public Sublevels: number = 0
    public filter_level: string = '0'
    public levelNames:string[] = []
    public finalLevel: boolean[] = []
}

export class ReportPlayersPointsState extends Logic.Type {
    public countCalled: boolean = false
    public filter_level: string = '0'
    public filter_processed: string = '0'
    public filter_type: string = '0'
    public count: number = 0
    public count1: number = 0
    public count2: number = 0
    public Count: number = 0
    public data: any[]
    public data1: any[]
    public data1Copy: any[]
    public data2: any[]
    public data2Copy: any[]
    public Data: any[]
    public Data1: any[]
    public Data2: any[]
    public pNum: number = 0
    public pNum1: number = 0
    public pNum2: number = 0
    public pSize: number = 100 // was 10
    public pSize1: number = 20
    public pSize2: number = 20
    public offset: string = '0'
    public offset1: string = '0'
    public offset2: string = '0'
    public sort: number[] = []
    public Sort: string[] = []
    public footer: string[] = []
    public footer1: string[] = []
    public footer2: string[] = []
    public Sublevels: number = 0
    public sublevel_min: number = 0
    public sublevel_max: number = 0
    public sublevels: number[] = []
    public specificQuery: boolean = false
    public from_time: string = ''
    public to_time: string = ''
    public fromDate: string = ''
    public toDate: string = ''
    public fromDate2: string = ''
    public toDate2: string = ''
    public fromTime: string = ''
    public toTime: string = ''
    public from_date: moment.Moment = null
    public to_date: moment.Moment = null
    public from_date2: moment.Moment = null
    public to_date2: moment.Moment = null
    // focused: boolean = false
    public focusedInput: any = null
    // focused2: boolean = false
    public focusedInput2: any = null    
    public compareDate: boolean = false
    public footerBottom: boolean = false
    // playerStats: boolean = false
    public player: string
    public playerLastEventDate: string
    public playerCurrency: string
    public playerPointsShowDetails: boolean = true
    public playerPoints: number
}

export class ReportLevelsPointsState1 extends Logic.Type {
    public sort: number[] = []
    public Sort: string[] = []
}

export class ReportLevelsPointsState2 extends Logic.Type {
    public sort: number[] = []
    public Sort: string[] = []
}

export class ReportLevelsPointsState3 extends Logic.Type {
    public sort: number[] = []
    public Sort: string[] = []
}

export class StorePurchasesState extends Logic.Type {
    public pSize: number = 10
    public pNum: number = 1
    public playerFound: boolean = false
    public id: string
}

export class UsersInfoState extends Logic.Type {
    public pSize: number = 15
    public pNum: number = 1
    public id: string
    public showStats: boolean = false
    public minLevel: number 
    public maxLevel: number
    public selectedPosition: number = -1
    public selectedPage: number = 0
    public search_string: string
    public username: boolean = true
    public playerId: boolean = true
    public filteredData: IPlayerInfoFiltered[]
    public searchChanged: boolean = false
}

export class UserInfoState extends Logic.Type {
    public navigation: number = 0
    public selectedMission: number = 0
    public selectedRewardName: string = ''
    public selectedRewardId: string = ''
    public selectedTournamentName: string = ''
    public selectedTournamentId: string = ''
    public selectedTournamentType: string = 'Race'
    public rewardFromFilter: string = ''
    public filtered: any[] = []
    public selectedFromDate: moment.Moment = null
    public selectedToDate: moment.Moment = null
    public focusedFromDate: boolean
    public focusedToDate: boolean
}

export class MissionLeaderboardState extends Logic.Type {
    // public selectedPosition: number = -1
    public selectedName: string = '-1'
    public selectedId: string = '-1'
    public selectedPage: number = 0
    public year: number = new Date().getFullYear()
    public month: number = new Date().getMonth()
    public pageSize: number = 15
    public filteredData: IMissionLeaderboard[]
}