import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormInput, RadioButton2,/* RadioButton3,*/ FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {gotoTop, isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, /*isUserOperator, isUserSiteOwner,*/ isUserAdmin} from '../../../Logic/Utils'
import * as TableUtils from '../../Reusables/TableUtils'
import ReactTable from 'react-table' 
// import * as Config from '../../../Logic/Config'
import {faSave, faMoneyBillWaveAlt} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
export function getCurrenciesCount() {
    if (Main.Instance.CurrencyState.countCalled === false) {
        Logic.Type.New(Main.Instance.CurrencyState, {countCalled:true})
        // console.log (Main.Instance.DatabaseDataState.currencyStream ? 'date:' + getDate('EUR') : 'date unknown')
        // console.log (JSON.stringify(Main.Instance.CurrencyRatesState.code))
        // console.log ('getCurrenciesCount')
        DbRoutes.getCurrencyRates()
        DbRoutes.GetCurrencyAll()
        DbRoutes.GetCurrency()
        DbRoutes.GetAllLanguages()
        DbRoutes.GetBanner()
        DbRoutes.getRewardShop()
        DbRoutes.GetFlags()
/*        
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)
        }
*/  
        // currencyStream
        // DbRoutes.GetCurrencyAll()
    }
}

function getMainCurrencyCode(): string {
    if (Main.Instance.DatabaseDataState.currenciesStream) {
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.currenciesStream.length; i++) {
        if (/*Main.Instance.DatabaseDataState.currenciesStream[i]['main'] &&
            Main.Instance.DatabaseDataState.currenciesStream[i]['code'] &&*/ 
            Main.Instance.DatabaseDataState.currenciesStream[i]['main'].toString() === 'true') {
            // console.log (Main.Instance.DatabaseDataState.currenciesStream[i]['code'].toString())
            return Main.Instance.DatabaseDataState.currenciesStream[i]['code'].toString()
        }
    }
    }
    return 'EUR'
}

function getMainCurrencyRate(): string {
    if (Main.Instance.DatabaseDataState.currenciesStream) {
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.currenciesStream.length; i++) {
        if (/*Main.Instance.DatabaseDataState.currenciesStream[i]['main'] &&
            Main.Instance.DatabaseDataState.currenciesStream[i]['code'] &&*/ 
            Main.Instance.DatabaseDataState.currenciesStream[i]['main'].toString() === 'true') {
            // console.log (Main.Instance.DatabaseDataState.currenciesStream[i]['code'].toString())
            return Main.Instance.DatabaseDataState.currenciesStream[i]['xrate'].toString()
        }
    }
    }
    return '1'
}

function getCode():string {
    if (Main.Instance.DatabaseDataState.currencyStream) {
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.currencyStream.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.currencyStream[i]['id'].toString().trim()) === 
        parseInt(Main.Instance.CurrencyState.code)) {
            return Main.Instance.DatabaseDataState.currencyStream[i]['code'].toString().trim()
        }
    }
    }
    return ''
}

function getName():string {
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.currencyStream.length; i++) {
        if (parseInt(Main.Instance.DatabaseDataState.currencyStream[i]['id'].toString().trim()) === 
        parseInt(Main.Instance.CurrencyState.code)) {
            return Main.Instance.DatabaseDataState.currencyStream[i]['name'].toString().trim()
        }
    }
    return ''
}

function getDate(code:string): string {
    // console.log ('code:' + code)
    // console.log (JSON.stringify(Main.Instance.CurrencyRatesState.code))
    if (Main.Instance.CurrencyRatesState.code) {
    for (let i:number = 0; i < Main.Instance.CurrencyRatesState.code.length; i++) {
        if (Main.Instance.CurrencyRatesState.code[i] === code) {
            return Main.Instance.CurrencyRatesState.date[i].toString().trim()
        }
    }
    }
    return ''
}

function getRate(code:string):string {
    if (Main.Instance.CurrencyRatesState.code) {
    for (let i:number = 0; i < Main.Instance.CurrencyRatesState.code.length; i++) {
        if (Main.Instance.CurrencyRatesState.code[i] === code) {
            return Main.Instance.CurrencyRatesState.rate[i].toString().trim()
        }
    }
    }
    return ''
}
/*
function SelectSite(stream:any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
                site_id: stream[0]['id'],
                // shopItemDefaultFilename:Config.localServer + '/logo/' + stream[0]['id'] + '/shop.jpg'
        })
        // console.log ('here')
        // DbRoutes.getRewardShopItemsCategory(stream[0]['id'])
        DbRoutes.getCurrencyNames()
        DbRoutes.GetCurrency()
        // DbRoutes.GetCurrencyAll()
        FirstPage()
    }
}
*/
function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Currency + ' ' + 
            Assets.withName + ' "' + row.original.currencydescription + '"?')) {

            if (Main.Instance.CurrencyState.pNum === 
            (Math.trunc((Main.Instance.CurrencyState.count - 2) / 
            Main.Instance.CurrencyState.pSize) + 1)) {
                DbRoutes.RemoveCurrency (row.original.id.toString(), DeleteLastPage)
            } else {
                DbRoutes.RemoveCurrency (row.original.id.toString(), DeleteNotLastPage)
            }
        }
    }
}

function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.currencies SET active = ', id.toString(), value, getCurrenciesCount) }
}

function toggleMain(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.currencies SET main = ', id.toString(), value, getCurrenciesCount) }
}

function toggleFixed(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.currencies SET frmc = ', id.toString(), value, getCurrenciesCount) }
}

function handleEdit(row: any) {
    gotoTop()

    let active: string
    let main: string
    let frmc: string
    active = row.original.active.toString() === 'true' ? '1' : '2'
    main = row.original.main.toString() === 'true' ? '1' : '2'
    frmc = row.original.fixed.toString() === 'true' ? '1' : '2'

    if (row) {
        Logic.Type.New(Main.Instance.CurrencyState, {
            mode: true,
            id: row.original.id.toString(), 
            code: row.original.code_id,
            code_saved: row.original.code_id,
            Code: row.original.currencydescription,
            rate: row.original.xrate,
            rate_saved: row.original.xrate,
            active,
            active_saved: active,
            main,
            main_saved: main,
            frmc,
            frmc_saved: frmc,
        })
    }
}

function handleInsertUpdate() {

    const currencyItem = {
        id : !Main.Instance.CurrencyState.id ? '' : Main.Instance.CurrencyState.id,
        code_id : !Main.Instance.CurrencyState.code ? '0' : Main.Instance.CurrencyState.code,
        site_id: Main.Instance.FrontendStoreState.site_id,
        code: getCode(),
        active : !Main.Instance.CurrencyState.active ? '1' : Main.Instance.CurrencyState.active,
        main : !Main.Instance.CurrencyState.main ? '2' : Main.Instance.CurrencyState.main,
        frmc : !Main.Instance.CurrencyState.frmc ? '2' : Main.Instance.CurrencyState.frmc,
        fixed : !Main.Instance.CurrencyState.rate ? '1' : Main.Instance.CurrencyState.rate.toString(),
        desc: getName(),
    }

    if (parseInt(currencyItem.active) === 1) { currencyItem.active = 'true' }
    if (parseInt(currencyItem.active) === 2) { currencyItem.active = 'false' }

    if (parseInt(currencyItem.main) === 1) { currencyItem.main = 'true' }
    if (parseInt(currencyItem.main) === 2) { currencyItem.main = 'false' }

    if (parseInt(currencyItem.frmc) === 1) { currencyItem.frmc = 'true' }
    if (parseInt(currencyItem.frmc) === 2) { currencyItem.frmc = 'false' }

    if (parseInt(Main.Instance.CurrencyState.code) === 0) {
        alert (Assets.compulsoryCurrencyCode)
        return       
    } else if (parseInt(Main.Instance.FrontendStoreState.site_id) === 0) {
        alert (Assets.compulsorySite)
        return
    } else if (Main.Instance.CurrencyState.mode === false) {
        let proceed: boolean = true

        for (const item of Main.Instance.DatabaseDataState.currenciesAllStream) {
            if (currencyItem.code === item['name']) {
                proceed = false
                break
            }
        }
        if (!proceed) {
            alert (Assets.CurrencyAlreadyInDb)
        } else { DbRoutes.insertCurrency (currencyItem, InsertLastPage) }
    } else {
        DbRoutes.updateCurrency (currencyItem, Update)
    }
}

function ApplyFilterActive(text:string) {
    
    Logic.Type.New(Main.Instance.CurrencyState, {filter_active:text})
    FirstPage()
}

function getCurrency() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {currenciesStream : undefined})
}

function  clearFields() {

    Logic.Type.New(Main.Instance.CurrencyState, {
        code : '0', 
        mode : false,
        active: '1',
        main: '2',
        frmc: '2',
        rate: '1',
    })
}

function gotoInsertMode() {
    if (
        (parseInt(Main.Instance.CurrencyState.code) === parseInt(Main.Instance.CurrencyState.code_saved))
        && Main.Instance.CurrencyState.rate === Main.Instance.CurrencyState.rate_saved
        && parseInt(Main.Instance.CurrencyState.active) === parseInt(Main.Instance.CurrencyState.active_saved)
        && parseInt(Main.Instance.CurrencyState.main) === parseInt(Main.Instance.CurrencyState.main_saved)
        && parseInt(Main.Instance.CurrencyState.frmc) === parseInt(Main.Instance.CurrencyState.frmc_saved)
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }        
}

function FirstPage() {
    Logic.Type.New(Main.Instance.CurrencyState,{offset:(0).toString()})

    getCurrency()
    Logic.Type.New(Main.Instance.CurrencyState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.CurrencyState.pNum > 0) {

        Logic.Type.New(Main.Instance.CurrencyState, {
            offset:((Main.Instance.CurrencyState.pNum - 1) * 
            Main.Instance.CurrencyState.pSize).toString()})
        getCurrency()

        Logic.Type.New(Main.Instance.CurrencyState,{pNum: Main.Instance.CurrencyState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.CurrencyState.pNum + 1) * Main.Instance.CurrencyState.pSize < 
    Main.Instance.CurrencyState.count) {

        Logic.Type.New(Main.Instance.CurrencyState, {
            offset: ((Main.Instance.CurrencyState.pNum + 1) * 
            Main.Instance.CurrencyState.pSize).toString()})
        getCurrency()
        
        Logic.Type.New(Main.Instance.CurrencyState,{pNum: Main.Instance.CurrencyState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.CurrencyState.count <= Main.Instance.CurrencyState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CurrencyState.count === 0) {

            Logic.Type.New(Main.Instance.CurrencyState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.CurrencyState.count - 1) / 
                    Main.Instance.CurrencyState.pSize)
            Logic.Type.New(Main.Instance.CurrencyState, {
                offset:(npNum * Main.Instance.CurrencyState.pSize).toString()})
        }

        getCurrency()

        Logic.Type.New(Main.Instance.CurrencyState,{pNum: npNum})
    }
}

function InsertLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CurrencyState, {count:Main.Instance.CurrencyState.count ++})
    LastPage()
}

function DeleteLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CurrencyState,{count:Main.Instance.CurrencyState.count --})
    LastPage()
}

function DeleteNotLastPage() {
    clearFields()
    Logic.Type.New(Main.Instance.CurrencyState,{count:Main.Instance.CurrencyState.count --})
    getCurrency()
}

function Update() {
    clearFields()
    getCurrency()
}

function RecordsPerPageChanged(text:string) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.CurrencyState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function RateChanged(text:string) {
    
    if (!isNaN(text as any)) {
        Logic.Type.New(Main.Instance.CurrencyState, {rate: text})
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream: undefined, sitesOperatorAllStream: undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
        // DbRoutes.getAllSitesByBrandOwner(text)
    }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.FrontendStoreState, {
        site_id:text,      
    })

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getCurrenciesCount()
    }
}

function ApplyCurrencySelect(text:string) {
    Logic.Type.New(Main.Instance.CurrencyState, {code:text})
}

export function Currencies() {
/*
    if ( Main.Instance.DatabaseDataState.FlagsStreamAllOrder === undefined) {
        Logic.Type.New (Main.Instance.DatabaseDataState, {FlagsStreamAllOrder: []})
        DbRoutes.GetFlags()
    }
*/
    // console.log (Main.Instance.DatabaseDataState.FlagsStreamAllOrder)
/*
    if (Main.Instance.FrontendStoreState.first_time) {
            Logic.Type.New (Main.Instance.FrontendStoreState, {
                first_time: false,
            })
            if (isUserGameProvider()) {
                DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
            } else if (isUserSiteOwner() || isUserOperator()) {
                DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                                parseInt(Main.Instance.LoginState.entityId),
                                                SelectSite)
            }
            // else if (isUserOperator()) {
                // DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId))
            //}
    }
*/
    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    return (
    <div>

    <div className = "clearfix">
        <h3 className = "level fl">
            {isUserAdmin() ? Assets.aeCurrency : Assets.vCurrency}
        </h3> 
        {isUserSystem() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                    Main.Instance.FrontendStoreState.holding_id}
            data = {Main.Instance.DatabaseDataState.masterAllStream}
            ClassName = "form-control"

            o1 = {Assets.SelectMaster}
            o2 = {Assets.MasterQ}
            onChange = {(text) => ApplyMasterSelect(text)}
            icon = {Assets_.faMasterClass}
        /> : null}

        {isUserSystem() || isUserMaster() || isUserGameProvider() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                    Main.Instance.FrontendStoreState.operator_id}
            data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
            data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
            label1 = {Assets.OP}
            label2 = {Assets.BO}
            
            ClassName = "form-control"

            o1 = {selectOperator}
            o2 = {operatorQ}
            onChange = {(text) => ApplyOperatorSelect(text)}
            icon = {Assets_.faCompanyClass}
        /> : null
        }

        {!isUserSubEntity() ?
        <FormSelect
            level = {true}
            value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                    Main.Instance.FrontendStoreState.site_id}
            data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
            ClassName = "form-control"
            o1 = {selectSite}
            o2 = {siteQ}
            onChange = {(text) => ApplySiteSelect(text)}
            icon = {Assets_.faSubEntityClass}
        />
        : void 0
        }

    </div>

    <div className = "clearfix"/>
    <div style = {{position: 'relative'}} className = "row admin-users-container clearfix">
        <br/>
        <div 
            className = {Main.Instance.CurrencyState.mode ? 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit mb20' : 
                            'col-md-12 col-sm-12 col-xs-12 admin-users-panel mb20'}
        >        
            <div className = "create-input">
                <div className = "row admin-users-row">
                    <h4 className = "fl width25">
                        {Main.Instance.CurrencyState.mode ?
                        parseInt(Main.Instance.CurrencyState.main) === 1 ?
                        Assets.editMainCurrency + ': ' +
                        Main.Instance.CurrencyState.Code
                        : isUserAdmin() ? 
                        Assets.editCurrency + ': ' + Main.Instance.CurrencyState.Code : Assets.ViewCurrency + ' : ' + Main.Instance.CurrencyState.Code
                        : isUserAdmin() ? Assets.addCurrency : Assets.ViewCurrency}
                    </h4>

                    <span
                        style = {{
                            fontWeight: 700,
                            display:'block',
                            float:'left',
                        }}
                    >{/*', ' + */Assets.LastDateCurrency_}
                    </span>

                    <span
                        className = "label label-info"
                        style = {{
                            marginLeft: '10px',
                            marginTop: '-2px',
                            fontWeight: 700,
                            fontSize:'16px',
                            display:'block',
                            float:'left',
                            }}
                    >
                        {/*Main.Instance.DatabaseDataState.currencyStream ?*/ 
                        getDate('EUR'/*getMainCurrencyCode()*/)/*: ''*/}
                    </span>

                    {Main.Instance.CurrencyState.mode ? 
                        <button
                            style = {{position:'absolute', right: '-7px', top: '-3px', background: 'transparent', border: '0px'}}
                            title = {Assets.discardChanges} 
                            onClick = {() => gotoInsertMode()} 
                            className = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                        >
                            <i className = {Assets_.faCancelButtonClass}/>
                        </button> : void 0}
                </div>

                <div className = "row admin-users-row" style = {{position: 'relative'}}>
                    <div style = {{position: 'absolute', top: '4px', left: '3px', zIndex: 9999, height: '20px'}}>
                        {/*Main.Instance.LanguageState.code*/}
                        {TableUtils.FlagByCurrencyCode(getCode(), true)}
                    </div>
                    <FormSelect
                        wide = {true}
                        value = {!Main.Instance.CurrencyState.code ? '0' : Main.Instance.CurrencyState.code}
                        data = {Main.Instance.DatabaseDataState.currencyStream}
                        ClassName = "form-control"
                        o1 = {Assets.SelectCurrency}
                        o2 = {Assets.CurrencyQ}
                        onChange = {(text) => ApplyCurrencySelect(text)}
                        icon = {parseInt(Main.Instance.CurrencyState.code) === 0 ? Assets_.faPaymentClass : ''}
                    />

                    {Main.Instance.CurrencyState.code && parseInt(Main.Instance.CurrencyState.code) > 0 
                    && getCode() !== getMainCurrencyCode()/* && Main.Instance.CurrencyState.frmc === '2'*/ ?
                    <span 
                        style = {{
                            fontWeight: 700,
                            // fontSize:'20px',
                            display:'block',
                            float:'left',
                            marginTop:'6px',
                            marginLeft:'2.5%',
                        }}
                    >{'1'}&nbsp;{getMainCurrencyCode()}&nbsp;{'='}&nbsp;
                    </span>
                    : void 0}

                    {Main.Instance.CurrencyState.code && parseInt(Main.Instance.CurrencyState.code) > 0 
                    && getCode() !== getMainCurrencyCode() && Main.Instance.CurrencyState.frmc === '1' ?

                    <FormInput
                        narrow = {true}
                        value = {!Main.Instance.CurrencyState.rate ? '' :
                        Main.Instance.CurrencyState.rate} 
                        ClassName = "form-control register-input ml" 
                        type = "text" 
                        onChange = {(text) => RateChanged(text)}
                        placeholder = {Assets.pName} 
                        Icon = {faMoneyBillWaveAlt}
                    />
                    : void 0}

                    {Main.Instance.CurrencyState.code && parseInt(Main.Instance.CurrencyState.code) > 0
                    && getCode() !== getMainCurrencyCode()&& Main.Instance.CurrencyState.frmc === '2' ?
                    <span 
                        className = "label label-info"
                        style = {{
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '4px',
                            fontWeight: 700,
                            fontSize:'16px',
                            display:'block',
                            float:'left',
                            }}
                    >
                        {Main.Instance.DatabaseDataState.currencyStream ? 
                        (parseFloat(getRate(getCode())) / parseFloat(getMainCurrencyRate())).toFixed(4) : 
                        ''}&nbsp;
                    </span>
                    : void 0}
                    
                    {Main.Instance.CurrencyState.code && parseInt(Main.Instance.CurrencyState.code) > 0
                    && getCode() !== getMainCurrencyCode()/* && Main.Instance.CurrencyState.frmc === '2'*/ ?
                    <span 
                        style = {{
                            fontWeight: 700,
                            // fontSize:'20px',
                            display:'block',
                            marginTop:'6px',
                            float:'left',
                            }}
                    >
                        {Main.Instance.DatabaseDataState.currencyStream ? getCode() : ''}
                    </span>
                    : void 0}

                    {/*
                    <span>{Main.Instance.DatabaseDataState.currencyStream ? getName() : ''}</span>
                    */}

                    <RadioButton2
                        value = {!Main.Instance.CurrencyState.active ? '1' :
                                Main.Instance.CurrencyState.active} 
                        text = {Assets.Activity}
                        ClassName = "input-group-radiobutton2 margin-bottom-sm fr" 
                        title = {Assets.ActivityDesc}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => Logic.Type.New(Main.Instance.CurrencyState, {active:text})}
                    />

                    {Main.Instance.CurrencyState.code && getCode() !== getMainCurrencyCode() ?                    
                    <RadioButton2
                        value = {!Main.Instance.CurrencyState.frmc ? '2' :
                                Main.Instance.CurrencyState.frmc} 
                        text = {Assets.frmc}
                        ClassName = "input-group-radiobutton2 margin-bottom-sm fr mr" 
                        title = {Assets.frmcDesc}
                        i1 = {Assets_.active}
                        i2 = {Assets_.inactive}
                        onClick = {(text) => Logic.Type.New(Main.Instance.CurrencyState, {frmc:text})}
                    />
                    : void 0}

                </div>

                <div className = "row admin-users-container clearfix">
                    <ButtonIcon
                        disabled = {!isUserAdmin()}
                        description = {!Main.Instance.CurrencyState.mode ? 
                                        Assets.saveChanges : 
                                        Assets.updateChanges} 
                        click = {() => handleInsertUpdate()}
                        ClassName = {Main.Instance.CurrencyState.mode ? 
                                    'btn btn-warning btn-sm btn-plus fr mt0' :
                                    'btn btn-primary btn-sm btn-plus fr mt0'}
                        Icon = {faSave as IconDefinition} 
                        text = {Assets.Save}
                    />
                </div>
            </div>
        </div>

        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CurrencyState.pSize ? '' : 
                Main.Instance.CurrencyState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />
            <button 
                className = "ml20 btn btn-primary btn-xs" 
                title = {Assets.ListMoveTop}
                onClick = {() => FirstPage()}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                title = {Assets.ListMoveLeft}
                onClick = {() => PrevPage()}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveRight}
                onClick = {() => NextPage()}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs"
                title = {Assets.ListMoveBottom}
                onClick = {() => LastPage()}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CurrencyState.filter_active ? '0' : 
                        Main.Instance.CurrencyState.filter_active}
                data = {[0]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.Any} 
                o2 = {Assets.Active}
                o3 = {Assets.Inactive}
                onChange = {(text) => ApplyFilterActive(text)} 
            />

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CurrencyState.count ? Main.Instance.CurrencyState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CurrencyState.count ? Main.Instance.CurrencyState.pNum * 
                Main.Instance.CurrencyState.pSize + 1 : 0}{' - '}
                {Main.Instance.CurrencyState.count ? 
                ((Main.Instance.CurrencyState.pNum + 1) * 
                Main.Instance.CurrencyState.pSize > Main.Instance.CurrencyState.count ? 
                Main.Instance.CurrencyState.count : 
                (Main.Instance.CurrencyState.pNum + 1) * Main.Instance.CurrencyState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.CurrencyState.count ? 
                Main.Instance.CurrencyState.pNum + 1 :0}{' / '}
                {Main.Instance.CurrencyState.count ? 
                Math.trunc((Main.Instance.CurrencyState.count - 1) /
                Main.Instance.CurrencyState.pSize + 1) : 0}
            </span>
        </div>
        <h3>{Assets.tCurrency}</h3>
        {/*console.log (Main.Instance.DatabaseDataState.currenciesStream)*/}
        {Main.Instance.DatabaseDataState.currenciesStream ?
            <ReactTable 
                data = {Main.Instance.DatabaseDataState.currenciesStream}
                columns = {[
                    {show: isUserAdmin(), width: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                    {Header: Assets.Code, accessor: 'code', Cell: (row) => <div className = "td-center">{row.value}</div>},
                    {Header: Assets.Name, accessor: 'currencydescription', headerClassName: 'th-left'},
                    {show: Main.Instance.DatabaseDataState.FlagsStreamAllOrder !== undefined, Header: Assets.Flag, accessor: 'code', Cell: (row) => TableUtils.FlagByCurrencyCode(row.value)},
                    {Header: Assets.ExchangeRate, accessor: 'xrate', headerClassName: 'th-right', Cell: (row) => (<div className = "td-right">{row.value}</div>)},
                    {Header: Assets.Fixed, accessor: 'fixed', Cell: (row) => row.original.code === 'EUR' ? <div className = "td-center disabled">{Assets_.NA}</div> : TableUtils.TB(row, toggleFixed)},
                    {Header: Assets.Main, accessor: 'main', Cell: (row) => TableUtils.TB(row, toggleMain)},
                    {Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                    {show: isUserAdmin(), width: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
                ]}
                showPaginationBottom = {false}
                defaultPageSize={Main.Instance.DatabaseDataState.currenciesStream.length < 100 ? Main.Instance.DatabaseDataState.currenciesStream.length : 100}
            />
            : getCurrenciesCount()}
        </div>
    </div>)
}