import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {RiskLevel, SelectedRiskComplianceSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
import Switch from 'react-toggle-switch'
import {isUserAdmin, isUserRFSpecialist, isUserRGSpecialist, IsBuildPasino} from '../../../Logic/Utils'
import {faSave, faUserCircle, faQuestionCircle, faCheckSquare, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import ReactTable from 'react-table'
// import * as TableUtils from '../../Reusables/TableUtils'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
import {/*InsideTitle, */handleText, Update/*, NotifyButton, AddNotify, handleDeleteNotify*/,  riskLevelSlider as RiskLevelSlider} from '../RiskComplianceStore/RiskManagement'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Custom, CounselingCentre, AccountStatus} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
import {getItems} from './GenericSettings'
import {ResponsibleGaming, riskLevelSlider} from '../RiskComplianceStore/ResponsibleGaming'
import {RiskManagement} from '../RiskComplianceStore/RiskManagement'
import {KnowYourCustomer} from '../RiskComplianceStore/KnowYourCustomer'
// import {Gamification} from '../RiskComplianceStore/Gamification'
import {ToggleSwitch} from './GenericSettings'
import * as Assets_ from '../../Assets_'

// import {SwitzerlandCantonsData} from '../Reports/SwitzerlandData'

function handleNumber(id: number, text: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) { custom.responsibleGaming.selfAssessment.answersScale.min = +text 
    } else if (id === 2) { custom.responsibleGaming.selfAssessment.answersScale.max = +text }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function selectNavigation(selectedRiskComplianceSetting: SelectedRiskComplianceSetting) {
    if (selectedRiskComplianceSetting === SelectedRiskComplianceSetting.ResponsibleGaming) {
        DbRoutes.wsBasicGet(Config.amCounselingCentres)
    }
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedRiskComplianceSetting})
}

function injectSpaces(source: string): string {
    const Type: string[] = source.match(/[A-Z][a-z]+/g)
    let text: string = ''
    for (let j: number = 0; j < Type.length; j++) {
        if (j > 0) { text += ' ' }
        text += Type[j]
    }
    return text
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
    for (let i: number = 0; i < Object.keys(SelectedRiskComplianceSetting).length / 2; i++) {
        navigation.push(
            <button
                title = {i === SelectedRiskComplianceSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedRiskComplianceSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                disabled = {i === SelectedRiskComplianceSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedRiskComplianceSetting.ResponsibleGaming && !isUserRGSpecialist()}
                onClick = {() => selectNavigation(i)}
                type = "button"
                key = {i.toString()}
                style = {{
                    marginRight: i === Object.keys(SelectedRiskComplianceSetting).length / 2 - 1 ? '0px' : '',
                    display: 'block',
                    float: 'left',
                }}
                className = {i === Main.Instance.RiskManagementState.selectedRiskComplianceSetting ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'}
            >
                <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedRiskComplianceSettingIcons[i]}/>
                {injectSpaces(SelectedRiskComplianceSetting[i])}
            </button>)
    }
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}
/*
function toggleNotifyDefault(i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.notify.riskLevelDefaults[i] = !custom.notify.riskLevelDefaults[i]
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getNotifyDefaults(): JSX.Element[] {
    const notifyDefaults: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.notify && Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults.length; i++) {
        notifyDefaults.push (
            <button
                key = {i.toString()}
                onClick = {() => toggleNotifyDefault(i)}
                className = {Main.Instance.DatabaseDataState.custom.notify.riskLevelDefaults[i] ? 'btn btn-xs risk risk-' + RiskLevel[i + 1] : 'btn btn-default btn-xs'}
            >
                {RiskLevel[i + 1]}
            </button>,
        )
    }
    }
    return notifyDefaults
}
*/
export function RiskComplianceSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    if (Main.Instance.DatabaseDataState.custom === undefined) {return <div/>}
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.ComplianceSettings}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {/*InsideTitle(undefined, injectSpaces(SelectedRiskComplianceSetting[Main.Instance.RiskManagementState.selectedRiskComplianceSetting]), Assets.SelectedRiskComplianceSettingIcons[Main.Instance.RiskManagementState.selectedRiskComplianceSetting])*/}
                        {/*<br/>*/}
                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Notifications ?
                        <>                        
                        <span
                            style = {{position: 'absolute', top: '15px', left: '170px', fontSize: '14px'}}
                            className = "label label-default label-sm"
                        >
                            {Assets.ShowDefaultRiskValues}
                        </span>
                        <div style = {{position: 'absolute', top: '15px', left: '370px'}}>{getNotifyDefaults()}</div>
                        <br/>
                        <ReactTable
                            data = {Main.Instance.DatabaseDataState.notify ? Main.Instance.DatabaseDataState.notify : []}

                            columns = {[
                                {width: 50, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center', Cell: (row) => <div className = "td-center fs16px">
                                    {(row.value)}</div>},
                                {
                                Header: <span>{Assets.PersonalData}</span>,
                                headerClassName: 'risk-default',
                                columns: [
                                    {width: 250, Header: Assets.Email, accessor: 'data.email', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {(e) => handleText (e.target.value, row.index, 0)}
                                            type = "text"
                                            defaultValue = {row.value}
                                        />
                                    </div>},
                                    {width: 150, Header: Assets.FirstName, accessor: 'data.firstName', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {(e) => handleText (e.target.value, row.index, 1)}
                                            type = "text"
                                            defaultValue = {row.value}
                                        />
                                    </div>},
                                    {width: 150, Header: Assets.LastName, accessor: 'data.lastName', headerClassName: 'th-left', Cell: (row) =>
                                    <div className = "td-left">
                                        <input
                                            // disabled = {Main.Instance.RiskManagementState.filteredData !== undefined}
                                            className = "table-input-text"
                                            onChange = {(e) => handleText (e.target.value, row.index, 2)}
                                            type = "text"
                                            defaultValue = {row.value}
                                        />
                                    </div>},
                                ]},
                                {
                                    Header: <span>{Assets.RiskLevel}</span>,
                                    headerClassName: 'risk-default-light',
                                    columns: [
                                        {width: 110, Header: Assets.LowRisk, accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Low, row)}</div>},
                                        {width: 110, Header: Assets.NormalRisk, accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Normal, row)}</div>},
                                        {width: 110, Header: Assets.MediumRisk, accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Medium, row)}</div>},
                                        {width: 110, Header: Assets.HighRisk, accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.High, row)}</div>},
                                        {width: 110, Header: Assets.SevereRisk, accessor: 'data.riskLevel', headerClassName: 'th-left', Cell: (row) =>
                                            <div className = "td-left">{NotifyButton(RiskLevel.Severe, row)}</div>},
                                    ],
                                },
                                {
                                Header: <span>{Assets.Actions}</span>,
                                show: isUserAdmin(),
                                headerClassName: 'risk-default',
                                columns: [
                                    {show: isUserAdmin() , width: 50,
                                        Header: <button className = "btn btn-primary btn-xs" onClick = {() => AddNotify()}>{Assets.Add}</button>,
                                    accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteNotify)},
                                    {show: isUserAdmin(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 8)},
                                ]},
                            ]}
                            showPaginationTop = {true}
                            showPaginationBottom = {false}
                            defaultPageSize={10}
                        />
                        </> : void 0*/}
                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Languages ?
                        <>
                            <div>
                                {getItems(1, [], Main.Instance.DatabaseDataState.custom.general.languages)}
                            </div>
                        </> : void 0*/}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Cooloffs ?
                        <>
                            <div>
                                {getItems(2, /*Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.timeUnits && Main.Instance.DatabaseDataState.custom.general.timeUnits.cooloffs ? */Main.Instance.DatabaseDataState.custom.general.timeUnits.cooloffs /*: []*/)}
                            </div>
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Limits ?
                        <>
                              <div>
                                <h4>{Assets.LimitTypes}</h4>
                                {getItems(6, Main.Instance.DatabaseDataState.custom.general.limitTypes)}
                                {getItems(3, Main.Instance.DatabaseDataState.custom.general.timePeriods.limits)}
                            </div>
                        </> : void 0}
                        
                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Currencies ?
                        <>
                            <div>
                                {getItems(4, Main.Instance.DatabaseDataState.custom.general.currencies)}
                            </div>
                        </> : void 0*/}
                        
                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.RiskManagement ?
                        <>
                            <div className = "clearfix">
                                <span className = "fl dbl mt3 mr5px">{Assets.CheckNightJob}</span>
                                <Switch
                                    className = "dbl fl"
                                    onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.riskManagement.showOnlyElevatedRisk, 0, 7)}
                                    on = {Main.Instance.DatabaseDataState.custom.riskManagement.showOnlyElevatedRisk}
                                />
                            </div>
                            {RiskManagement()}
                        </> : void 0}
                        
                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.ResponsibleGaming ?
                        <>
                        {ResponsibleGaming(true)}
                        {Main.Instance.RiskManagementState.sectionButtonRG === 99 ?
                        <div>
                            {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend ?
                            <>
                                <h4>{Assets.DashboardBackend}</h4>
                                <div className = "clearfix">
                                    <span className = "fl dbl mt3 mr5px">{Assets.CheckNightJob}</span>
                                    <Switch
                                        className = "dbl fl"
                                        onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.checkNightJob, 0, 5)}
                                        on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.dashboardBackend.checkNightJob}
                                    />
                                </div>
                            </> : void 0}

                            <div>
                                <br/>
                                <h4>{Assets.Questionnaire}</h4>
                                <div>
                                    <span className = "mr2">{Assets.AnswersScale}</span>
                                    <span className = "mr5px">{Assets.min}</span>
                                    <input
                                        disabled = {true}
                                        type = "number"
                                        min = "1"
                                        size = {5}
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.min}
                                        onChange = {(e) => handleNumber(1, e.target.value)}
                                        className = "mr2 pr0"
                                        style = {{width: '50px'}}
                                    />
                                    <span className = "mr5px">{Assets.max}</span>
                                    <input
                                        disabled = {true}
                                        type = "number"
                                        min = "1"
                                        size = {5}
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.selfAssessment.answersScale.max}
                                        onChange = {(e) => handleNumber(2, e.target.value)}
                                        className = "pr0"
                                        style = {{width: '50px'}}
                                    />
                                </div>
                            </div>

                            {Main.Instance.DatabaseDataState.custom &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming &&
                            Main.Instance.DatabaseDataState.custom.responsibleGaming.issues ?
                            <>
                                <div className = "clearfix mt20">
                                    <h4>{Assets.CallbackList}</h4>
                                    <span className = "fl dbl mt3 mr5px">{Assets.CallbackOptionActive}</span>
                                    <Switch
                                        className = "dbl fl"
                                        onClick = {() => HandleSwitch(Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active, 0, 12)}
                                        on = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.active}
                                    />
                                </div>
                                <div className = "mt5">
                                    <span>{Assets.CustomerSupportEmail}</span>
                                    <input
                                        type = "text"
                                        value = {Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.CSEmail}
                                        onChange = {(e) => handleText(e.target.value, 0, 6)}
                                        className = "pr0"
                                        style = {{width: '300px'}}
                                    />
                                </div>
                                <div className = "mt5">
                                    <span>{Assets.SetRiskLevelCallback}</span>
                                    <button className = {'btn btn-xs mr0 risk risk-' + RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}>
                                        {RiskLevel[Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel]}
                                        {riskLevelSlider(6, 0, Main.Instance.DatabaseDataState.custom.responsibleGaming.issues.riskLevel)}
                                    </button>
                                </div>
                            </> : void 0}
                            {Main.Instance.DatabaseDataState.counselingCentres ?
                            <div className = "clearfix mt20">
                                <h4>{Assets.CounselingCentres_ByCanton}</h4>                                
                                <div className = "mt5">
                                    <ReactTable
                                        data = {Main.Instance.DatabaseDataState.counselingCentres ? Main.Instance.DatabaseDataState.counselingCentres : []}
                                        columns = {[
                                            {width: 30, Header: Assets.ID, accessor: 'id', headerClassName: 'th-center'},
                                            {width: 35, Header: <span title = {Assets.CantonCode}>{Assets.CantonCodeAbbrev}</span>, accessor: 'data.code', headerClassName: 'th-left', Cell: (row) => row.value},
                                            {width: 175, Header: Assets.CantonName, accessor: 'data.canton', headerClassName: 'th-left'/*, Cell: (row) => getCanton(row.value)*/},
                                            {width: 200, Header: Assets.CounselingCentreName, accessor: 'data.name', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'name', e.target.value)}/>},
                                            {width: 150, Header: Assets.PhoneNumber, accessor: 'data.phone', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'phone', e.target.value)}/>},
                                            {width: 200, Header: Assets.Address, accessor: 'data.address', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'address', e.target.value)}/>},
                                            {width: 70, Header: <span title = {Assets.PostalCode}>{Assets.PostalC_}</span>, accessor: 'data.plz', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'plz', e.target.value)}/>},
                                            {width: 150, Header: Assets.City, accessor: 'data.city', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'city', e.target.value)}/>},
                                            {Header: Assets.Description, accessor: 'data.description', headerClassName: 'th-left', Cell: (row) => <input style = {{height: '24px', width: '100%'}} defaultValue = {row.value} onChange={(e) => update(row.index, 'description', e.target.value)}/>},
                                            {show: isUserAdmin() || isUserRGSpecialist(), width: 70, Header: Assets.Update, accessor: '', Cell: (row) => Update(row, 18)},

                                        ]}
                                        showPaginationTop = {false}
                                        showPaginationBottom = {false}
                                        defaultPageSize={26}
                                    />
                                </div>
                            </div> : void 0}
                        </div> : void 0}
                        </> : void 0}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.KnowYourCustomer ?
                        <>
                            {KnowYourCustomer(true)}
                            {Main.Instance.RiskManagementState.sectionButtonKYC === 99 ?
                            <>
                            <h4>{Assets.RequestedDocumentsForAccountVerification}</h4>
                            <div className = "fl mr100">
                                <h5>{Assets.Identification}</h5>
                                {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC && Main.Instance.DatabaseDataState.custom.KYC.identification?
                                <>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.identification[0].key,*/ 0, Main.Instance.DatabaseDataState.custom.KYC.identification[0].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.identification[0].documentsFormat, 0)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.identification[1].key,*/ 1, Main.Instance.DatabaseDataState.custom.KYC.identification[1].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.identification[1].documentsFormat, 1)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.identification[2].key,*/ 2, Main.Instance.DatabaseDataState.custom.KYC.identification[2].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.identification[2].documentsFormat, 2)}
                                </>
                                : void 0
                                }
                            </div>
                            <div className = "fl mr100">
                                <h5>{Assets.ProofOfAddress}</h5>
                                {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC && Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress ?
                                <>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[0].key,*/ 3, Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[0].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[0].documentsFormat, 3)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[1].key,*/ 4, Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[1].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[1].documentsFormat, 4)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[2].key,*/ 5, Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[2].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[2].documentsFormat, 5)}
                                </>
                                : void 0
                                }
                            </div>
                            <div className = "fl mr100">
                                <h5>{Assets.ProofOfPayment}</h5>
                                    {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.KYC &&
                                        Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment ?
                                <>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[0].key,*/ 6, Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[0].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[0].documentsFormat, 6)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[1].key,*/ 7, Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[1].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[1].documentsFormat, 7)}
                                    <br/>
                                    {Switches(/*Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[2].key,*/ 8, Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[2].label)}
                                    {DocumentsFormat(Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[2].documentsFormat, 8)}
                                </>
                                : void 0
                                }
                            </div>
                            </> : void 0}
                        </> : void 0}
                        
                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Descriptions ?
                        <>
                            <div>
                                <h2>{Assets.Notifications}</h2>
                                {getDescriptions('settings')}
                                <h2>{Assets.RiskManagement}</h2>
                                {getDescriptions('riskManagement')}
                                <h2>{Assets.ResponsibleGaming}</h2>
                                {getDescriptions('responsibleGaming')}
                            </div>
                        </> : void 0*/}
                        
                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Communications ?
                        <>
                            <div>
                                {getItems(5, Main.Instance.DatabaseDataState.custom.general.communications)}
                            </div>
                        </> : void 0*/}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.AccountStatus ?
                        <div>
                            <div className="mt5">
                                <h4>{Assets.AccountStatusCharacteristics}</h4>
                                {getAccountStatus()}
                            </div>
                        </div>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.FraudAlerts ?
                        <div>
                            <h4>{Assets.Deposit + ' ' + Assets.FraudAlerts}</h4>
                            {GetFraudAlerts(false)}
                            <h4>{Assets.Withdrawal + ' ' + Assets.FraudAlerts}</h4>
                            {GetFraudAlerts(true)}
                        </div>
                        : void 0}

                        {Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.Other ?
                        <div>
                            <div>
                                <span>{Assets.DurationOfPasswordResetEmail}:</span>
                                &nbsp;&nbsp;
                                <input onChange = {(e) => {
                                    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
                                    if (custom.general === undefined) {
                                        custom.general = {}
                                    }

                                    custom.general.durationPasswordResetEmailSeconds = +e.target.value
                                    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
                                }} style = {{width: '100px', paddingRight: '0px'}} type = "number" value = {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.durationPasswordResetEmailSeconds ? Main.Instance.DatabaseDataState.custom.general.durationPasswordResetEmailSeconds.toString() : 0}/>
                            </div>
                        </div>
                        : void 0}

                        {/*Main.Instance.RiskManagementState.selectedRiskComplianceSetting === SelectedRiskComplianceSetting.LimitTypes ?
                        <>
                            <div>
                                {getItems(6, Main.Instance.DatabaseDataState.custom.general.limitTypes)}
                            </div>
                        </> : void 0*/}

                    </div>
                </div>
            </div>            
        </div>
    )
}

/*
{show: !Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 2}>{Assets.Code.charAt(0) + 2}</div>, accessor: 'code', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
{show: Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 1}>{Assets.Code.charAt(0) + 1}</div>, accessor: 'c', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
{Header: <div style = {{marginLeft: '3px'}}>{regionName ? capitalizeFirstLetter(regionName) : Assets.Region}</div>, accessor: 'name', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}} title = {row.value}>{row.value}</div>},
{width: Width > threshold ? 70 : 28, Header: <div style = {{marginLeft: '1px'}} title = {Width > threshold ? undefined : Assets.Country}>{Width > threshold ? Assets.Country : <FontAwesomeIcon icon = {faGlobe}/>}</div>, accessor: 'country', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {Width > threshold ? undefined : row.original.Country} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'center'}}>{Width > threshold ? row.original.Country : row.value}</div>},
{width: 100, Header: <div style = {{marginLeft: '3px'}}>{Main.Instance.PlayerInfo.stat === Stat.Population ? Assets.Value + ' (000\'s)' : Assets.Value}</div>, accessor: 'id', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}}>{getValue(row.original.code, row.original.country)}</div>},
*/

export function GetFraudAlerts(withdrawals?: boolean): JSX.Element {
    if (Main.Instance.DatabaseDataState.custom === undefined || Main.Instance.DatabaseDataState.custom.riskManagement === undefined || Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts === undefined) {
        return <div/>
    }
    const filtered = []
    let pageSize: number = 0
    
    if (withdrawals !== undefined) {
        if (withdrawals) {
            for (const item of Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts) {
                if (item.type === 'Withdrawal') { pageSize++ }
            }
            filtered.push ({id: 'type', value: 'Withdrawal'})
        } else {
            for (const item of Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts) {
                if (item.type === 'Deposit') { pageSize++ }
            }            
            filtered.push ({id: 'type', value: 'Deposit'})
        }
    } else {
        pageSize = Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts.length
    }
    
    
    

    if (Main.Instance.Location.startsWith('/account-store/')) {
        return (
            <div style = {{width: '750px'}}>
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts}
                    columns = {[
                        {width: 20, Header: <FontAwesomeIcon title = {Assets.Code} icon = {faQuestionCircle}/>, accessor: 'shortCode', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div style = {{width: '20px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                        {show: filtered.length === 0, width: 70, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '70px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                        {width: 20, Header: <FontAwesomeIcon title = {Assets.Implemented} icon = {faCheckSquare}/>, accessor: 'implemented', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '20px', fontSize: '12px', lineHeight: '12px', height: '12px', color: row.value ? '#28a745' : '#dc3545'}}><FontAwesomeIcon icon = {row.value ? faCheck : faTimes}/></div>},
                        {Header: Assets.Description, accessor: 'description', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                        {width: 90, Header: <span>{Assets.RiskLevel}</span>, accessor: 'riskLevel', headerClassName: 'th-center data-regions', className: 'no-white-space2 data-regions', Cell: (row) => <div style = {{fontSize: '12px', lineHeight: '12px', height: '12px'}}>
                            <button 
                                className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value !== undefined ? row.value : RiskLevel.Undefined]}
                                style = {{fontSize: '11px', lineHeight: '12px', height: '11px', width: '100%', paddingTop: '0px'}}
                            >
                                {RiskLevel[row.value !== undefined ? row.value : RiskLevel.Undefined]}
                                {/* RiskLevelSlider(12, row.index, row.value !== undefined ? row.value : RiskLevel.Undefined, true) */}
                            </button>                                            
                            </div>},
                    ]}
                    showPaginationBottom = {false}
                    pageSize = {pageSize}
                    className = "-striped -highlight"
                    
                    
                />
            </div>
        )
    } else {
        return (
            <div>
                <ReactTable
                    filtered = {filtered}
                    data = {Main.Instance.DatabaseDataState.custom.riskManagement.fraudAlerts}
                    columns = {[
                        {width: 30, Header: <FontAwesomeIcon title = {Assets.Code} icon = {faQuestionCircle}/>, accessor: 'shortCode', headerClassName: 'th-center', className: 'td-center', Cell: (row) => <div>{row.value}</div>},
                        {show: filtered.length === 0, width: 70, Header: Assets.Type, accessor: 'type', headerClassName: 'th-left ', className: 'td-left ', Cell: (row) => <div>{row.value}</div>},
                        {width: 110, Header: Assets.Implemented, accessor: 'implemented', headerClassName: 'th-center', className: 'td-center', Cell: (row) => <div style = {{color: row.value ? '#28a745' : '#dc3545'}}>
                            <button 
                            className = "btn btn-xs mr0" 
                            onClick = {() => changeImplementedStatus(row.index)}
                            style = {{border: 'none', background: 'none'}}
                            value = {row.value}>
                                <FontAwesomeIcon icon = {row.value ? faCheck : faTimes}/>
                            </button>
                            </div>},
                        {Header: Assets.Description, accessor: 'description', headerClassName: 'th-left', className: 'td-left', Cell: (row) => <div>{row.value}</div>},
                        {width: 110, Header: <span>{Assets.RiskLevel}</span>, accessor: 'riskLevel', headerClassName: 'th-center', className: 'no-white-space2', Cell: (row) => <div>
                            <button 
                                className = {'btn btn-xs mr0 risk risk-' + RiskLevel[row.value !== undefined ? row.value : RiskLevel.Undefined]}
                            >
                                {RiskLevel[row.value !== undefined ? row.value : RiskLevel.Undefined]}
                                {RiskLevelSlider(12, row.index, row.value !== undefined ? row.value : RiskLevel.Undefined, false)}
                            </button>                                            
                            </div>},
                    ]}
                    showPaginationBottom = {false}
                    pageSize = {pageSize}
                    className="-striped -highlight"
                />
            </div>
        )
    }
}

function changeImplementedStatus(i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.riskManagement.fraudAlerts[i].implemented = !custom.riskManagement.fraudAlerts[i].implemented
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}



/*
function add(id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) custom.general.languages.push({locale: Main.Instance.RiskManagementState.addLanguageLocale, name: Main.Instance.RiskManagementState.addLanguage,short: Main.Instance.RiskManagementState.addLanguageShort})
    // else if (id === 2) custom.general.timeUnits.cooloffs.push({span: Main.Instance.RiskManagementState.addTimeUnit, active: true})
    // else if (id === 3) custom.general.timePeriods.limits.push({span: Main.Instance.RiskManagementState.addTimePeriod, active: true})
    // else if (id === 4) custom.general.currencies.push({shortLocaleMain.Instance.RiskManagementState.addCurrency})
    else if (id === 5) custom.general.communications.push(Main.Instance.RiskManagementState.addCommunication)
    // else if (id === 6) custom.general.limitTypes.push({limitType: Main.Instance.RiskManagementState.addCommunication, active: true})
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})

    if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguage: '', addLanguageLocale: '', addLanguageShort: ''})
    else if (id === 2) Logic.Type.New(Main.Instance.RiskManagementState, {addTimeUnit: ''})
    else if (id === 3) Logic.Type.New(Main.Instance.RiskManagementState, {addTimePeriod: ''})
    else if (id === 4) Logic.Type.New(Main.Instance.RiskManagementState, {addCurrency: ''})
    else if (id === 5) Logic.Type.New(Main.Instance.RiskManagementState, {addCommunication: ''})
    else if (id === 6) Logic.Type.New(Main.Instance.RiskManagementState, {addLimitType: ''})
}

function edit (id: number, value: string) {
    if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguage: value})
    else if (id === 2) Logic.Type.New(Main.Instance.RiskManagementState, {addTimeUnit: value})
    else if (id === 3) Logic.Type.New(Main.Instance.RiskManagementState, {addTimePeriod: value})
    else if (id === 4) Logic.Type.New(Main.Instance.RiskManagementState, {addCurrency: value})
    else if (id === 5) Logic.Type.New(Main.Instance.RiskManagementState, {addCommunication: value})
    else if (id === 6) Logic.Type.New(Main.Instance.RiskManagementState, {addLimitType: value})
}

// function edit2 (id: number, value: string) {if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguageLocale: value})}
// function edit3 (id: number, value: string) {if (id === 1) Logic.Type.New(Main.Instance.RiskManagementState, {addLanguageShort: value})}

function del(id: number, i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    // if (id === 1) custom.general.languages.splice (i, 1)
    // else if (id === 2) custom.general.timeUnits.cooloffs.splice (i, 1)
    // else if (id === 3) custom.general.timePeriods.limits.splice (i, 1)
    // else if (id === 4) custom.general.currencies.splice (i, 1)
    if (id === 5) custom.general.communications.splice (i, 1)
    // else if (id === 6) custom.general.limitTypes.splice (i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setDefaultLocale(defaultLocale: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.defaultLocale = defaultLocale
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setDefaultCurrency(defaultCurrency: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.defaultCurrency = defaultCurrency
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setLocale(found) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.languages.push({locale: found.code, name: found.name, short: found.name.substring(0,2)})
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function setCurrency(found) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.currencies.push(found.code)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function removeLocale(code: string) {
    let found: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.languages.length; i++) {
        if (Main.Instance.DatabaseDataState.custom.general.languages[i].locale === code) {
            found = i
            break
        }
    }
    if (found !== -1) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.general.languages.splice(found, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function removeCurrency(code: string) {
    let found: number = -1
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.general.currencies.length; i++) {
        if (Main.Instance.DatabaseDataState.custom.general.currencies[i] === code) {
            found = i
            break
        }
    }
    if (found !== -1) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.general.currencies.splice(found, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

function toggle(id: number, key: string) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    if (id === 2) custom.general.timeUnits.cooloffs[key] = !custom.general.timeUnits.cooloffs[key]
    else if (id === 3) custom.general.timePeriods.limits[key] = !custom.general.timePeriods.limits[key]
    else if (id === 6) custom.general.limitTypes[key] = !custom.general.limitTypes[key]
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getItems (id: number, array: string[]|any, array2?: {locale: string, name: string, short: string}[], array3?:{span: string, active: boolean}[], array4?:{limitType: string, active: boolean}[]): JSX.Element[] { 
    const items: JSX.Element[] = []
    if (id === 1 || id === 4 || id === 5) {
        if (id === 1) {
            // console.log (Main.Instance.DatabaseDataState.Languages)
            let firstLetter: string = '0'
            if (Main.Instance.DatabaseDataState.Languages)
            for (const item of Main.Instance.DatabaseDataState.Languages) {
                const currentFirstLetter: string = item.data.name.charAt(0)
                if (currentFirstLetter !== firstLetter) {
                    firstLetter = currentFirstLetter
                    items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                }
                let found: boolean = false

                // let Found
                for (const Item of array2) {
                    if (Item.locale === item.data.code) {
                        found = true
                        // Found = item
                        break
                    }
                }
                items.push (
                    <span className = "mr" key = {item.data.code}>
                        <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeLocale(item.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.code}</button>
                        <button onClick = {() => found ? setDefaultLocale(item.data.code) : setLocale(item.data)} title = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'This is the default language.' : 'Click to set the language as the default language.' : 'Include this language.'} className = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultLocale ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.name}</button>
                    </span>
                )
            }
        } else if (id === 4) {
            // console.log (Main.Instance.DatabaseDataState.currencies)
            let firstLetter: string = '0'

            if (Main.Instance.DatabaseDataState.currencies)
            for (let i: number = 3; i < Main.Instance.DatabaseDataState.currencies.length; i++) { // START WITH 3, because there are some special cases
                const item = Main.Instance.DatabaseDataState.currencies[i]

                const currentFirstLetter: string = item.data.code.charAt(0)
                if (currentFirstLetter !== firstLetter) {
                    firstLetter = currentFirstLetter
                    items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                }
                let found: boolean = false

                // let Found
                for (const Item of array) {
                    if (Item === item.data.code) {
                        found = true
                        // Found = item
                        break
                    }
                }

                items.push (
                    <span className = "mr" key = {item.data.code}>
                        <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(item.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.code}</button>
                        <button onClick = {() => found ? setDefaultCurrency(item.data.code) : setCurrency(item.data)} title = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'This is the default currency.' : 'Click to set the currency as the default currency.' : 'Include this currency.'} className = {found ? item.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{item.data.name}</button>
                    </span>
                )

                if (i === 24) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[1]
                    found = false
                    const CurrentFirstLetter: string = ITEM.data.code.charAt(0)
                    if (CurrentFirstLetter !== firstLetter) {
                        firstLetter = CurrentFirstLetter
                        items.push (<h4 key = {firstLetter}>{firstLetter}</h4>)
                    }
    
                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }
    
                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'This is the default currency.' : 'Click to set the currency as the default currency.' : 'Include this currency.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.name}</button>
                        </span>
                    )
                } else if (i === 40) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[2]
                    found = false

                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }

                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'This is the default currency.' : 'Click to set the currency as the default currency.' : 'Include this currency.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.name}</button>
                        </span>
                    )
                } else if (i === 126) {
                    const ITEM = Main.Instance.DatabaseDataState.currencies[0]
                    found = false

                    // let Found
                    for (const Item of array) {
                        if (Item === ITEM.data.code) {
                            found = true
                            // Found = item
                            break
                        }
                    }
                    items.push (
                        <span className = "mr" key = {ITEM.data.code}>
                            <button style = {{cursor: found ? '' : 'default'}} onClick = {() => found ? removeCurrency(ITEM.data.code) : void 0} className = {found ? 'btn btn-danger btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.code}</button>
                            <button onClick = {() => found ? setDefaultCurrency(ITEM.data.code) : setCurrency(ITEM.data)} title = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'This is the default currency.' : 'Click to set the currency as the default currency.' : 'Include this currency.'} className = {found ? ITEM.data.code === Main.Instance.DatabaseDataState.custom.general.defaultCurrency ? 'btn btn-success btn-xs br0 mr0' : 'btn btn-primary btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{ITEM.data.name}</button>
                        </span>
                    )
                }

            }
        } else if (id === 5) {
            for (let i: number = 0; i < (array.length === 0 ? array2.length : array.length); i++) {
                items.push (<button onClick = {() => del(id, i)} style = {{cursor: 'default'}} key = {i.toString() + '__'} className = "btn btn-success btn-xs br0 mr0">{array[i]}</button>)
                items.push (<button style = {{cursor: 'default', marginLeft: '-1px'}} disabled = {!isUserAdmin()} key = {i.toString() + '_'} className = "btn btn-danger btn-xs br0"><FontAwesomeIcon icon = {faTrashAlt}/></button>)
            }
        }
        if (id === 5) {
            // if (id === 1) items.push (<input disabled = {!isUserAdmin()} key = "-4" onChange = {(e) => edit2 (id, e.target.value)} style = {{height: '22px', width: '70px'}} type = "text" maxLength = {7} value = {Main.Instance.RiskManagementState.addLanguageLocale}/>)
            items.push (<input disabled = {!isUserAdmin()} key = "-2" onChange = {(e) => edit (id, e.target.value)} style = {{height: '22px'}} type = "text" value = {id === 5 ? Main.Instance.RiskManagementState.addCommunication : Main.Instance.RiskManagementState.addLimitType}/>)
            // if (id === 1) items.push (<input disabled = {!isUserAdmin()} key = "-3" onChange = {(e) => edit3 (id, e.target.value)} style = {{height: '22px', width: '50px'}} type = "text" maxLength = {3} value = {Main.Instance.RiskManagementState.addLanguageShort}/>)
            items.push (<button disabled = {!isUserAdmin()} key = "-1" onClick = {() => add(id)} className = "btn btn-primary btn-xs mr0 br0"><FontAwesomeIcon icon = {faPlus}/></button>)
        }
    } else {
        items.push (<h4 key = {id === 2 ? 'Cool-offs' : id === 3 ? 'Limits' : ''}>{id === 2 ? 'Cool-offs' : id === 3 ? 'Limits' : ''}</h4>)

        for (const key in array) {
            // console.log (key + ': ' + array[key])
            // items.push (<button style = {{cursor: 'default'}} key = {key} className = {array[key] ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].locale}</button>)
            items.push (<button onClick = {() => toggle(id, key)} key = {key} className = {array[key] ? 'btn btn-success btn-xs' : 'btn btn-secondary btn-xs'}>{key}</button>)
            // items.push (<button style = {{cursor: 'default'}} key = {key} className = {array[key] ? 'btn btn-dark btn-xs br0 mr0' : 'btn btn-default btn-xs br0 mr0'}>{array2[i].short}</button>)

        }
    }

    return items
}
// Main.Instance.DatabaseDataState.custom.general.descriptions.settings

function set(a: string, key: string, value: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.general.descriptions[a][key] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function getDescriptions (a: string): JSX.Element[] {
    const descriptions: JSX.Element[] = []
    for (const key in Main.Instance.DatabaseDataState.custom.general.descriptions[a]) {
        descriptions.push(<div className = "clearfix mt3" key = {a + '_' + key} style = {{width: '100%'}}><div className = "fl" style = {{width: '12%', fontWeight: 700, lineHeight: '26px'}}>{key === 'settings' ? 'notify' : key}:</div><input className = "fl dbl" style = {{width: '88%'}} onChange = {(e) => set(a, key, e.target.value)} type = "text" value = {Main.Instance.DatabaseDataState.custom.general.descriptions[a][key]}/></div>)
    }
    return descriptions
}
*/
function handleCheckbox(id: number, format: 'pdf'|'jpg'|'png'|'gif') {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id < 3) { custom.KYC.identification[id % 3].documentsFormat.indexOf(format) === -1 ? custom.KYC.identification[id % 3].documentsFormat.push(format) : custom.KYC.identification[id % 3].documentsFormat.splice(custom.KYC.identification[id % 3].documentsFormat.indexOf(format), 1) } else if (id < 6) { custom.KYC.proofOfAddress[id % 3].documentsFormat.indexOf(format) === -1 ? custom.KYC.proofOfAddress[id % 3].documentsFormat.push(format) : custom.KYC.proofOfAddress[id % 3].documentsFormat.splice(custom.KYC.proofOfAddress[id % 3].documentsFormat.indexOf(format), 1) } else { custom.KYC.proofOfPayment[id % 3].documentsFormat.indexOf(format) === -1 ? custom.KYC.proofOfPayment[id % 3].documentsFormat.push(format) : custom.KYC.proofOfPayment[id % 3].documentsFormat.splice(custom.KYC.proofOfPayment[id % 3].documentsFormat.indexOf(format), 1) }

/*
    if (id === 0) custom.KYC.identification.documentsFormat.indexOf(format) === -1? custom.KYC.identification.documentsFormat.push(format) : custom.KYC.identification.documentsFormat.splice(custom.KYC.identification.documentsFormat.indexOf(format), 1)
    else if (id === 1) custom.KYC.proofOfAddress.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfAddress.documentsFormat.push(format) : custom.KYC.proofOfAddress.documentsFormat.splice(custom.KYC.proofOfAddress.documentsFormat.indexOf(format), 1)
    else if (id === 2) custom.KYC.proofOfPayment.documentsFormat.indexOf(format) === -1? custom.KYC.proofOfPayment.documentsFormat.push(format) : custom.KYC.proofOfPayment.documentsFormat.splice(custom.KYC.proofOfPayment.documentsFormat.indexOf(format), 1)
*/
/*
    if (id < 3) custom.KYC.identification[id % 3].active = !custom.KYC.identification[id % 3].active
    else if (id < 6) custom.KYC.proofOfAddress[id % 3].active = !custom.KYC.proofOfAddress[id % 3].active
    else custom.KYC.proofOfPayment[id % 3].active = !custom.KYC.proofOfPayment[id % 3].active
*/
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function Switches(/*key: string, */id: number, text: string): JSX.Element {
    return (
        <div className = "clearfix mb">
            <Switch
                className = "dbl fl mr"
                onClick = {() => handleSwitch(id)}
                on = {id < 3 ? Main.Instance.DatabaseDataState.custom.KYC.identification[id % 3].active : id < 6 ? Main.Instance.DatabaseDataState.custom.KYC.proofOfAddress[id % 3].active : Main.Instance.DatabaseDataState.custom.KYC.proofOfPayment[id % 3].active}
            />
            <span className = "fl dbl mt3">{text}</span>
        </div>
    )
}

function DocumentsFormat(array: Array<'pdf'|'jpg'|'png'|'gif'>, id: number/*, text: string*/): JSX.Element {
    return (
        <div className = "clearfix">
            <h5>{Assets.AllowedDocumentsFormats}:</h5>
            <button 
                className = {array.indexOf('pdf') === -1? 'btn btn-xs btn-default fl': 'btn btn-xs btn-success fl'}
                name = "pdf"
                onClick = {() => handleCheckbox(id, 'pdf')}
            >
            {Assets_.pdf}
            </button>
            <button 
                className = {array.indexOf('jpg') === -1? 'btn btn-xs btn-default fl': 'btn btn-xs btn-success fl'}
                name = "jpg"
                onClick = {() => handleCheckbox(id, 'jpg')}
            >
            {Assets_.jpg}
            </button>
            <button 
                className = {array.indexOf('png') === -1? 'btn btn-xs btn-default': 'btn btn-xs btn-success'}
                name = "png"
                onClick = {() => handleCheckbox(id, 'png')}
            >
            {Assets_.png}
            </button>
            <button 
                className = {array.indexOf('gif') === -1? 'btn btn-xs btn-default': 'btn btn-xs btn-success'}
                name = "gif"
                onClick = {() => handleCheckbox(id, 'gif')}
            >
            {Assets_.gif}
            </button>
        </div>
    )
}

function HandleSwitch(value: boolean, index: number, id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 5) { custom.responsibleGaming.dashboardBackend.checkNightJob = !value } else if (id === 7) { custom.riskManagement.showOnlyElevatedRisk = !value } else if (id === 12) { custom.responsibleGaming.issues.active = !value }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}

function handleSwitch(id: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id < 3) { custom.KYC.identification[id % 3].active = !custom.KYC.identification[id % 3].active } else if (id < 6) { custom.KYC.proofOfAddress[id % 3].active = !custom.KYC.proofOfAddress[id % 3].active } else { custom.KYC.proofOfPayment[id % 3].active = !custom.KYC.proofOfPayment[id % 3].active }
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
/*
function getCanton(code: string) {
    for (const item of SwitzerlandCantonsData) {
        if (item.code.substring(3,5).toLowerCase() === code.toLowerCase()) return item.name
    }
    return '?'
}
*/
function update(index: number, property: string, value: string) {
    const counselingCentres: CounselingCentre[] = Object.assign([], Main.Instance.DatabaseDataState.counselingCentres)
    counselingCentres[index].data[property] = value
    Logic.Type.New(Main.Instance.DatabaseDataState, {counselingCentres})
}

function getAccountStatus(): JSX.Element[] {
    const ac: JSX.Element[] = []
    const firstLine: JSX.Element[] = []
    firstLine.push(<button key = "-1" disabled={true} style={{ width: '103px', height: '22px', position: 'absolute', top: '69px', left: '-41px', paddingLeft: '4px', transform: 'rotate(-90deg)'}} className="btn btn-danger btn-xs" title = {Assets.NormalAccountStatus}>{Assets.NormalAccount}</button>)
    // firstLine.push(<button key = "-2" disabled={true} style={{ width: '103px', height: '22px', position: 'absolute', top: '184px', left: '-41px', paddingLeft: '4px', transform: 'rotate(-90deg)'}} className="btn btn-danger btn-xs" title = {Assets.SpecialAccountStatus}>{Assets.SpecialAccount}</button>)
    // firstLine.push(<button key = "-2" disabled={true} style={{ width: '133px', height: '22px', position: 'absolute', top: '198px', left: '-56px', paddingLeft: '4px', transform: 'rotate(-90deg)'}} className="btn btn-danger btn-xs" title = {Assets.SpecialAccountStatus}>{Assets.SpecialAccount}</button>)
    firstLine.push(<button key = "-2" disabled={true} style={{ width: '160px', height: '22px', position: 'absolute', top: '211px', left: '-69px', paddingLeft: '4px', transform: 'rotate(-90deg)'}} className="btn btn-danger btn-xs" title = {Assets.SpecialAccountStatus}>{Assets.SpecialAccount}</button>)
    firstLine.push(<FontAwesomeIcon style = {{position: 'absolute', top: '1px', left: '1px', fontSize: '20px'}} icon = {faUserCircle}/>)
    firstLine.push(<button key = "0" disabled={true} style={{ width: '120px', marginLeft: '30px' }} className="btn btn-danger btn-xs">{Assets.AccountStatus}</button>)
    firstLine.push(<button key = "1" disabled={true} style={{ width: '470px' }} className="btn btn-danger btn-xs">{Assets.AllowedActionsForEachAccountStatus}</button>)
    firstLine.push(<button key = "2" disabled={true} style={{ width: '680px' }} className="btn btn-danger btn-xs">{Assets.VerifiedItemsForEachAccountStatus}</button>)
    ac.push (<div key = "-1" className = "mt5 pr">{firstLine}</div>)
    
    for (let i: number = AccountStatus.Initial; i < Main.Instance.DatabaseDataState.custom.general.players.accountStatus.length; i++) {
        if (i === 9 && IsBuildPasino()) {
            i++
        }
        const a: JSX.Element[] = []
        a.push(
            <button disabled={true} style={{ width: '120px', marginLeft: '30px'  }} key={i.toString()} className="btn btn-dark btn-xs">
                {Assets.accountStatus[i]}
            </button>,
        )
/*
        if (i === 10) {

            a.push(
                <button disabled = {true} style={{ width: '100px' }} onClick={() => ToggleSwitch2(i, 1)} key={i.toString() + '_5'} className={true ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                    {Assets.AllowToLogin.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '100px' }} onClick={() => ToggleSwitch2(i, 2)} key={i.toString() + '_6'} className={false ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                    {Assets.AllowToPlay.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch2(i, 3)} key={i.toString() + '_7'} className={false ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                    {Assets.AllowToDeposit.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch2(i, 4)} key={i.toString() + '_8'} className={false ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                    {Assets.AllowToWithdraw.toLowerCase()}
                </button>,
            )

        } else {
*/
        a.push(
            <button disabled = {true} style={{ width: '100px' }} onClick={() => ToggleSwitch2(i, 1)} key={i.toString() + '_5'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.login ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                {Assets.AllowToLogin.toLowerCase()}
            </button>,
        )

        a.push(
            <button disabled = {true} style={{ width: '100px' }} onClick={() => ToggleSwitch2(i, 2)} key={i.toString() + '_6'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.play ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                {Assets.AllowToPlay.toLowerCase()}
            </button>,
        )

        a.push(
            <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch2(i, 3)} key={i.toString() + '_7'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.deposit ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                {Assets.AllowToDeposit.toLowerCase()}
            </button>,
        )

        a.push(
            <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch2(i, 4)} key={i.toString() + '_8'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.withdraw ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}>
                {Assets.AllowToWithdraw.toLowerCase()}
            </button>,
        )

        if (Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.temporary) {
            a.push(
                <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch2(i, 5)} key={i.toString() + '_8'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].allow.temporary ? 'btn btn-primary btn-xs' : 'btn btn-default btn-xs'}>
                    {Assets.ProvisionalAccountStatus.toLowerCase()}
                </button>,
            )
        }
        if (Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements) {
            a.push(
                <button disabled = {true} style={{ width: '120px' }} onClick={() => ToggleSwitch(i, 1)} key={i.toString() + '_1'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isEmailVerified ? 'btn btn-success btn-xs teal' : 'btn btn-default btn-xs'}>
                    {Assets.IsEmailVerified.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '150px' }} onClick={() => ToggleSwitch(i, 2)} key={i.toString() + '_2'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isDocumentVerified ? 'btn btn-success btn-xs teal' : 'btn btn-default btn-xs'}>
                    {Assets.IsDocumentVerified.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '250px' }} onClick={() => ToggleSwitch(i, 3)} key={i.toString() + '_3'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isUtilityBillVerified ? 'btn btn-success btn-xs teal' : 'btn btn-default btn-xs'}>
                    {Assets.IsUtilityBillVerified.toLowerCase() + ' / ' + Assets.IsAddressVerified.toLowerCase()}
                </button>,
            )

            a.push(
                <button disabled = {true} style={{ width: '130px' }} onClick={() => ToggleSwitch(i, 4)} key={i.toString() + '_4'} className={Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements && Main.Instance.DatabaseDataState.custom.general.players.accountStatus[i].requirements.isSuperVerified ? 'btn btn-success btn-xs teal' : 'btn btn-default btn-xs'}>
                    {Assets.IsFullyIdentified.toLowerCase()}
                </button>,
            )
        }
        // }
        if (i === AccountStatus.BlockedFull) {
            ac.push(<hr style={{ marginTop: '5px', marginBottom: '5px' }} key="hr" />)
        }
        ac.push(<div key={i.toString()} className="mt5">{a}</div>)
    }
    return ac
}

function ToggleSwitch2(i: number, j: number) {

    const what: string = j === 1 ? 'login' : j === 2 ? 'play' : j === 3 ? 'deposit' : j === 4 ? 'withdraw' : 'temporary'

    if (j <= 5) {
        const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
        if (custom.general.players.accountStatus[i].allow && custom.general.players.accountStatus[i].allow[what]) {
            delete (custom.general.players.accountStatus[i].allow[what])
        } else {
            if (custom.general.players.accountStatus[i].allow === undefined) {
                custom.general.players.accountStatus[i].allow = {}
            }
            custom.general.players.accountStatus[i].allow[what] = true
        }
        Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
    }
}