import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {/*isUserOperator, 
        isUserGamingSite, 
        isUserSystem,*/ 
        isUserAdmin,
        GetCategoryName,
        gotoTop/*
        isContractualPartnerOperator*/} from '../../../Logic/Utils'
import {FormInput, FormSelect, FormInputNumber/*,RadioButton3*/} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

function getCategoryCount() {

    if (Main.Instance.CategoryDetailState.countCalled === false) {

        Logic.Type.New(Main.Instance.CategoryDetailState, {countCalled:true})

        // DbRoutes.countCategoryDetail()
        DbRoutes.GetPointTypes()
        DbRoutes.getPointTypes(0)
    }
} 

function getTypeCount(category_id?:string) {

    if (Main.Instance.CategoryDetailState.CountCalled === false) {

        Logic.Type.New(Main.Instance.CategoryDetailState, {CountCalled:true})

        // DbRoutes.countCategoryDetail()
        if (category_id) {
            DbRoutes.countSubPointTypesByPointType(category_id)
        } else { DbRoutes.countSubPointTypesByPointType(Main.Instance.CategoryDetailState.category_id) }
    }
} 

function getCategory() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {pointTypeStream : undefined})
} 

function getType() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {subPointTypeStream : undefined})
} 

function gotoInsertMode() {

    if (Main.Instance.CategoryDetailState.category_id === Main.Instance.CategoryDetailState.category_id_saved
    && Main.Instance.CategoryDetailState.category_name === Main.Instance.CategoryDetailState.category_name_saved
    ) {

        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }
}

function gotoInsertMode2() {

    if (Main.Instance.CategoryDetailState.detail_id === Main.Instance.CategoryDetailState.detail_id_saved
    && Main.Instance.CategoryDetailState.detail_name === Main.Instance.CategoryDetailState.detail_name_saved
    && Main.Instance.CategoryDetailState.detail_odds_type === Main.Instance.CategoryDetailState.detail_odds_type_saved
    && Main.Instance.CategoryDetailState.detail_deposit_option === 
       Main.Instance.CategoryDetailState.detail_deposit_option_saved       
    && Main.Instance.CategoryDetailState.detail_login_option === 
       Main.Instance.CategoryDetailState.detail_login_option_saved
    && Main.Instance.CategoryDetailState.detail_registration_option === 
       Main.Instance.CategoryDetailState.detail_registration_option_saved
    && Main.Instance.CategoryDetailState.detail_withdrawal_option === 
       Main.Instance.CategoryDetailState.detail_withdrawal_option_saved
    && Main.Instance.CategoryDetailState.detail_slots === Main.Instance.CategoryDetailState.detail_slots_saved
    && Main.Instance.CategoryDetailState.detail_table_game === Main.Instance.CategoryDetailState.detail_table_game_saved
) {

        ClearFields()

    } else {

        if (confirm(Assets.UnsavedChanges)) {
            ClearFields() 
        }                
    }
}

function clearFields() {

    Logic.Type.New(Main.Instance.CategoryDetailState, {
        mode: false,
//        selected: false,
/*
        detail_id:'0',
        detail_name: '',
        detail_category_id:'0',
*/
        category_id:'0',
        category_name: '',
    })
}

function ClearFields() {

    Logic.Type.New(Main.Instance.CategoryDetailState, {
        Mode: false,
//        selected: true,
        detail_id:'0',
        detail_name: '',
        detail_table_game:'0',
        // detail_id: '0',
        // detail_name: '',
        // detail_category_id: '0',
        detail_id_saved: '0',
        detail_name_saved: '',
        // detail_table_game: '0',
        detail_table_game_saved: '',
        detail_deposit_option: '0',
        detail_deposit_option_saved: '',
        detail_odds_type: '0',
        detail_odds_type_saved: '',
        detail_slots: '0',
        detail_slots_saved: '',
        detail_login_option: '0',
        detail_login_option_saved: '',
        detail_registration_option: '0',
        detail_registration_option_saved: '',
        detail_withdrawal_option: '0',
        detail_withdrawal_option_saved: '',
    })
}


interface Item {
    category_name:string
    category_id:string
    type_name:string
    type_id:string
    attr_table_game:boolean
    attr_table_game_all:boolean
    attr_deposit_option:boolean
    attr_deposit_option_all:boolean
    attr_odds_type:boolean
    attr_odds_type_all:boolean
    attr_slots:boolean
    attr_slots_all:boolean
    attr_registration_option:boolean
    attr_registration_option_all:boolean
    attr_login_option:boolean
    attr_login_option_all:boolean
    attr_withdrawal_option:boolean
    attr_withdrawal_option_all:boolean
}

function handleInsertUpdate() {

    const items:Item = { 
        category_name: !Main.Instance.CategoryDetailState.category_name ? '' : 
        Main.Instance.CategoryDetailState.category_name,
        category_id: !Main.Instance.CategoryDetailState.category_id ? '' : 
        Main.Instance.CategoryDetailState.category_id,
        type_name: !Main.Instance.CategoryDetailState.detail_name ? '' : Main.Instance.CategoryDetailState.detail_name,
        type_id: !Main.Instance.CategoryDetailState.detail_id ? '' : Main.Instance.CategoryDetailState.detail_id,
        attr_table_game: false,
        attr_table_game_all: false,
        attr_deposit_option: false,
        attr_deposit_option_all: false,
        attr_odds_type: false,
        attr_odds_type_all: false,
        attr_slots: false,
        attr_slots_all: false,        
        attr_login_option: false,
        attr_login_option_all: false,
        attr_registration_option: false,
        attr_registration_option_all: false,
        attr_withdrawal_option: false,
        attr_withdrawal_option_all: false,
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_table_game) === 1) {
        items.attr_table_game = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_table_game) === 2) {
        items.attr_table_game_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_deposit_option) === 1) {
        items.attr_deposit_option = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_deposit_option) === 2) {
        items.attr_deposit_option_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_odds_type) === 1) {
        items.attr_odds_type = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_odds_type) === 2) {
        items.attr_odds_type_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_slots) === 1) {
        items.attr_slots = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_slots) === 2) {
        items.attr_slots_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_login_option) === 1) {
        items.attr_login_option = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_login_option) === 2) {
        items.attr_login_option_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_registration_option) === 1) {
        items.attr_registration_option = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_registration_option) === 2) {
        items.attr_registration_option_all = true
    }

    if (parseInt(Main.Instance.CategoryDetailState.detail_withdrawal_option) === 1) {
        items.attr_withdrawal_option = true
    } else if (parseInt(Main.Instance.CategoryDetailState.detail_withdrawal_option) === 2) {
        items.attr_withdrawal_option_all = true
    }
    
    handleInsertUpdate2 (items)

}

function handleInsertUpdate2(items:Item) {

    if (!Main.Instance.CategoryDetailState.selected && items.category_name.length === 0) {
        alert (Assets.compulsoryCategoryName)
        return
        }

    if (Main.Instance.CategoryDetailState.selected && items.type_name.length === 0) {
        alert (Assets.compulsoryTypeName)
        return
        }
    if (!Main.Instance.CategoryDetailState.selected) {
        if (Main.Instance.CategoryDetailState.mode === false) {
            // INSERT MODE
            // console.log (items)
            DbRoutes.insertCategory(items, InsertLastPage)
        } else {
            // UPDATE MODE
            DbRoutes.updateCategory(items, Update)
        }
    } else {
        if (Main.Instance.CategoryDetailState.Mode === false) {
            // INSERT MODE
            // console.log (items)
            DbRoutes.insertType(items, InsertLastPage)
        } else {
            // UPDATE MODE
            DbRoutes.updateType(items, Update)
        }        
    }
}

function InsertLastPage() {
    if (!Main.Instance.CategoryDetailState.selected) {
        clearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {count:Main.Instance.CategoryDetailState.count++ })
        LastPage()
    } else {
        ClearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {Count:Main.Instance.CategoryDetailState.Count++ })
        LastPageType()        
    }
}

function DeleteLastPage() {
    if (!Main.Instance.CategoryDetailState.selected) {
        clearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {count:Main.Instance.CategoryDetailState.count--})
        LastPage()
    } else {
        ClearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {Count:Main.Instance.CategoryDetailState.Count--})
        LastPageType()
    }
}

function DeleteNotLastPage() {

    if (!Main.Instance.CategoryDetailState.selected) {
        clearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {count:Main.Instance.CategoryDetailState.count-- })
        getCategory()
    } else {
        ClearFields()
        Logic.Type.New(Main.Instance.CategoryDetailState, {Count:Main.Instance.CategoryDetailState.Count-- })
        getType()        
    }
}

function Update() {
    if (!Main.Instance.CategoryDetailState.selected) {
        clearFields()
        getCategory()
    } else {
        ClearFields()
        getType()        
    }
}

/*
function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.loyalty_free_rounds 
        SET recurring = ', rowData[0], rowData[13], getCategoryDetailCount)
    }
}
*/

function handleEdit(row: any) {
    gotoTop()

    const stateCopy = Object.assign({}, Main.Instance.CategoryDetailState)
    stateCopy.mode = true
    stateCopy.Mode = false
    stateCopy.selected = false
    
    stateCopy.category_id = row.original.id
    stateCopy.category_id_saved = row.original.id
    stateCopy.category_name = row.original.category
    stateCopy.category_name_saved = row.original.category

    Logic.Type.New(Main.Instance.CategoryDetailState, stateCopy)

    getTypeCount(row.original.id)
}

function handleDelete(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Category + ' "' + row.original.category + '"?')) {

            if (Main.Instance.CategoryDetailState.pNum === 
                (Math.trunc((Main.Instance.CategoryDetailState.count - 2) / 
                Main.Instance.CategoryDetailState.pSize) + 1)) {
            // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
            // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                
                DbRoutes.removeCategory(row.original.id, DeleteLastPage)
            } else {

                DbRoutes.removeCategory(row.original.id, DeleteNotLastPage)
            }
        }
    }
}

function handleEditType(row: any) {
    // console.log (row)
    const stateCopy = Object.assign({}, Main.Instance.CategoryDetailState)

    if (row.original.attr_table_game_all.toString() === 'true') {
        stateCopy.detail_table_game = '2'
    } else if (row.original.attr_table_game.toString() === 'true') {
        stateCopy.detail_table_game = '1'
    } else { stateCopy.detail_table_game = '0' }
    stateCopy.detail_table_game_saved = stateCopy.detail_table_game
    
    if (row.original.attr_deposit_option_all.toString() === 'true') {
        stateCopy.detail_deposit_option = '2'
    } else if (row.original.attr_deposit_option.toString() === 'true') {
        stateCopy.detail_deposit_option = '1'
    } else { stateCopy.detail_deposit_option = '0' }
    stateCopy.detail_deposit_option_saved = stateCopy.detail_deposit_option

    if (row.original.attr_odds_type_all.toString() === 'true') {
        stateCopy.detail_odds_type = '2'
    } else if (row.original.attr_odds_type.toString() === 'true') {
        stateCopy.detail_odds_type = '1'
    } else { stateCopy.detail_odds_type = '0' }
    stateCopy.detail_odds_type_saved = stateCopy.detail_odds_type

    if (row.original.attr_slots_all.toString() === 'true') {
        stateCopy.detail_slots = '2'
    } else if (row.original.attr_slots.toString() === 'true') {
        stateCopy.detail_slots = '1'
    } else { stateCopy.detail_slots = '0' }
    stateCopy.detail_slots_saved = stateCopy.detail_slots

    if (row.original.attr_login_option_all.toString() === 'true') {
        stateCopy.detail_login_option = '2'
    } else if (row.original.attr_login_option.toString() === 'true') {
        stateCopy.detail_login_option = '1'
    } else { stateCopy.detail_login_option = '0' }
    stateCopy.detail_login_option_saved = stateCopy.detail_login_option

    if (row.original.attr_registration_option_all.toString() === 'true') {
        stateCopy.detail_registration_option = '2'
    } else if (row.original.attr_registration_option.toString() === 'true') {
        stateCopy.detail_registration_option = '1'
    } else { stateCopy.detail_registration_option = '0' }
    stateCopy.detail_registration_option_saved = stateCopy.detail_registration_option

    if (row.original.attr_withdrawal_option_all.toString() === 'true') {
        stateCopy.detail_withdrawal_option = '2'
    } else if (row.original.attr_withdrawal_option.toString() === 'true') {
        stateCopy.detail_withdrawal_option = '1'
    } else { stateCopy.detail_withdrawal_option = '0' }
    stateCopy.detail_withdrawal_option_saved = stateCopy.detail_withdrawal_option

    stateCopy.mode = false
    stateCopy.Mode = true
    stateCopy.selected = true
    stateCopy.detail_id = row.original.id
    stateCopy.detail_name = row.original.type
    stateCopy.detail_id_saved = row.original.id
    stateCopy.detail_name_saved = row.original.type
    stateCopy.category_id = row.original.category_id

    Logic.Type.New(Main.Instance.CategoryDetailState, stateCopy)
}
/*
 function handleToggle (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('UPDATE admin.reward_shop SET active = ', rowData[0], rowData[6], getRewardShopCount)
    }
}
*/

export function handleAttributeType(column:number, value:number, row: any) {
    if (row) {
        value++
        if (value === 3) {
            value = 0
        }
        let one:boolean = false
        let all:boolean = false

        if (value === 1) {
            one = true
        } else if (value === 2) {
            all = true
        }

        if (column === 4) {
            DbRoutes.Action('.point_types SET attr_table_game = ' + one.toString() + 
                            ', attr_table_game_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 6) {
            DbRoutes.Action('.point_types SET attr_deposit_option = ' + one.toString() + 
                            ', attr_deposit_option_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 8) {
            DbRoutes.Action('.point_types SET attr_odds_type = ' + one.toString() + 
                            ', attr_odds_type_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 10) {
            DbRoutes.Action('.point_types SET attr_slots = ' + one.toString() + 
                            ', attr_slots_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 12) {
            DbRoutes.Action('.point_types SET attr_login_option = ' + one.toString() + 
                            ', attr_login_option_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 14) {
            DbRoutes.Action('.point_types SET attr_registration_option = ' + one.toString() + 
                            ', attr_registration_option_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        } else if (column === 16) {
            DbRoutes.Action('.point_types SET attr_withdrawal_option = ' + one.toString() + 
                            ', attr_withdrawal_option_all = ' + all.toString(), 
                            row.original.id, getTypeCount)
        }
    }
}

function handleDeleteType(row: any) {
    if (row) {
        if (confirm(Assets.sureToDelete + ' ' + Assets.Type + ' "' + row.original.type + '"?')) {

            if (Main.Instance.CategoryDetailState.pNum === 
                (Math.trunc((Main.Instance.CategoryDetailState.count - 2) / 
                Main.Instance.CategoryDetailState.pSize) + 1)) {
            // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
            // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                
                DbRoutes.removeType(row.original._id, DeleteLastPage)
            } else {

                DbRoutes.removeType(row.original.id, DeleteNotLastPage)
            }
        }
    }
}

function FirstPageType() {
    // console.log ('firstpage')
    Logic.Type.New(Main.Instance.CategoryDetailState, {Offset:(0).toString()})
    getType()
    Logic.Type.New(Main.Instance.CategoryDetailState, {PNum: 0})
}

function PrevPageType() {

    if (Main.Instance.CategoryDetailState.PNum > 0) {
        
        Logic.Type.New(Main.Instance.CategoryDetailState, {
            Offset: ((Main.Instance.CategoryDetailState.PNum - 1) *  
        Main.Instance.CategoryDetailState.PSize).toString()})
        getCategory()
        Logic.Type.New(Main.Instance.CategoryDetailState,{PNum: Main.Instance.CategoryDetailState.PNum - 1})
    } else {
        LastPageType()
    }
}

function NextPageType() {

    if ((Main.Instance.CategoryDetailState.PNum + 1) * Main.Instance.CategoryDetailState.PSize < 
        Main.Instance.CategoryDetailState.Count) {
        Logic.Type.New(Main.Instance.CategoryDetailState, {
            Offset:((Main.Instance.CategoryDetailState.PNum + 1) * 
            Main.Instance.CategoryDetailState.PSize).toString()})
        getCategory()
        Logic.Type.New(Main.Instance.CategoryDetailState, {PNum: Main.Instance.CategoryDetailState.PNum + 1})
    } else {
        FirstPageType()
    }
}

function LastPageType() {
    if (Main.Instance.CategoryDetailState.Count <= Main.Instance.CategoryDetailState.PSize) {
        FirstPageType()
    } else {
        let nPNum: number = 0;
        if (Main.Instance.CategoryDetailState.Count === 0) {

            Logic.Type.New(Main.Instance.CategoryDetailState, {Offset: (0).toString()})
        } else {

            nPNum = Math.trunc((Main.Instance.CategoryDetailState.Count - 1) / 
                    Main.Instance.CategoryDetailState.PSize)
            Logic.Type.New(Main.Instance.CategoryDetailState, {
                Offset: (nPNum * Main.Instance.CategoryDetailState.PSize).toString()})
        }                             

        getType()
        Logic.Type.New(Main.Instance.CategoryDetailState, {PNum: nPNum})        
    }
}

function FirstPage() {
    Logic.Type.New(Main.Instance.CategoryDetailState, {offset: (0).toString()})
    getCategory()
    Logic.Type.New(Main.Instance.CategoryDetailState, {pNum: 0})
}

function PrevPage() {
    if (Main.Instance.CategoryDetailState.pNum > 0) {
        
        Logic.Type.New(Main.Instance.CategoryDetailState, {
            offset:((Main.Instance.CategoryDetailState.pNum - 1) * Main.Instance.CategoryDetailState.pSize).toString()})
        getCategory()
        Logic.Type.New(Main.Instance.CategoryDetailState,{pNum: Main.Instance.CategoryDetailState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.CategoryDetailState.pNum + 1) * Main.Instance.CategoryDetailState.pSize < 
        Main.Instance.CategoryDetailState.count) {
        Logic.Type.New(Main.Instance.CategoryDetailState, {
            offset:((Main.Instance.CategoryDetailState.pNum + 1) * Main.Instance.CategoryDetailState.pSize).toString()})
        getCategory()
        Logic.Type.New(Main.Instance.CategoryDetailState, {pNum: Main.Instance.CategoryDetailState.pNum + 1})
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.CategoryDetailState.count <= Main.Instance.CategoryDetailState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;
        if (Main.Instance.CategoryDetailState.count === 0) {

            Logic.Type.New(Main.Instance.CategoryDetailState, {offset: (0).toString()})
        } else {

            npNum = Math.trunc((Main.Instance.CategoryDetailState.count - 1) / 
                    Main.Instance.CategoryDetailState.pSize)
            Logic.Type.New(Main.Instance.CategoryDetailState, {
                offset: (npNum * Main.Instance.CategoryDetailState.pSize).toString()})
        }
        getCategory()
        Logic.Type.New(Main.Instance.CategoryDetailState, {pNum: npNum})        
    }
}

function RecordsPerPageChanged(text:string) {

    if (parseInt(text) > 0) {
            Logic.Type.New(Main.Instance.CategoryDetailState, {pSize:parseInt(text)})
            FirstPage()
    }
}

function RecordsPerPageChangedType(text:string) {

    if (parseInt(text) > 0) {
            Logic.Type.New(Main.Instance.CategoryDetailState, {PSize:parseInt(text)})
            FirstPageType()
    }
}
/*
function ChangeCategoryFilter (text:string) {

    Logic.Type.New(Main.Instance.CategoryDetailState, {category_filter:text})
    DbRoutes.getSubPointTypesByPointType(text, true)
    FirstPage()
}
*/
function handleSelect(row: any) {
    if (row) {
        Logic.Type.New (Main.Instance.CategoryDetailState, {selected: true, category_id: row.original.id, detail_name: ''})
        getTypeCount()
    }
}

function ApplyTableGameSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_table_game: text})    
}

function ApplyDepositOptionSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_deposit_option: text})    
}

function ApplyOddsTypeSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_odds_type: text})    
}

function ApplySlotsSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_slots: text})    
}

function ApplyLoginOptionSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_login_option: text})    
}

function ApplyRegistrationOptionSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_registration_option: text})    
}

function ApplyWithdrawalOptionSelect(text:string) {
    Logic.Type.New (Main.Instance.CategoryDetailState, {detail_withdrawal_option: text})    
}

function handleSelectItem(text:string) {
    const selected:boolean = parseInt(text) === 0 ? false : true 

    Logic.Type.New (Main.Instance.CategoryDetailState, {selected, category_id: text})
    getTypeCount()
}

export function CategoryDetail() {

    let title:string = ''

    if (isUserAdmin()) {
        if (!Main.Instance.CategoryDetailState.selected) {
            if (Main.Instance.CategoryDetailState.mode) {
                title = Assets.EditCategory + ': ' + Main.Instance.CategoryDetailState.category_name_saved
            } else {
                title = Assets.AddCategory
            }
        } else {
            if (Main.Instance.CategoryDetailState.Mode) {
                title = Assets.EditType + ': ' + Main.Instance.CategoryDetailState.detail_name_saved + 
                Assets._for_ 
                + Assets.Category + ' ' + GetCategoryName(Main.Instance.CategoryDetailState.category_id)
            } else {
                title = Assets.AddType + Assets._for_ + Assets.Category + ' ' + 
                GetCategoryName(Main.Instance.CategoryDetailState.category_id)
            }
        }
        
    } else {

    }

    return (
    <div>

        {/*!Main.Instance.CategoryDetailState.selected  ? isUserAdmin() ?
        <h3 className = "level-wide fl">{Assets.cCategory}</h3> : 
        <h3 className = "level fl">{Assets.vCategory}</h3> : isUserAdmin() ?
        <h3 className = "level-wide fl">{Assets.cType}</h3> : 
        <h3 className = "level fl">{Assets.vType}</h3>*/}

        <h3 className = "level-wide fl">{Assets.SelectCategory}</h3> 
        
        <FormSelect 
            w50 = {true}
            floatRight = {true}
            titleFixTop = {true}
            // level225 = {true}
            value = {!Main.Instance.CategoryDetailState.category_id ? '0' : 
                    Main.Instance.CategoryDetailState.category_id}
            data = {Main.Instance.DatabaseDataState.pointTypeStreamAll}
            ClassName = "form-control select-menu"
            o1 = {Assets.SelectCategory}
            o2 = {Assets.CategoryQ}
            onChange = {(text) => handleSelectItem(text)}
            icon = {Assets_.faCircleLgClass}
            icon0 = {Assets_.faCancelLgClass}
        />

        <div className = "clearfix"/>
        <br/>

        {/*isUserAdmin() ? */}
        <div className = "row admin-users-container">
{/*
            <div className = {Main.Instance.CategoryDetailState.selected ?
                             Main.Instance.CategoryDetailState.Mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-select' :

                             Main.Instance.CategoryDetailState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel'
            }>
*/}            
            <div 
                className = {Main.Instance.CategoryDetailState.Mode || Main.Instance.CategoryDetailState.mode ? 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                             'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
            >
                <div className = "register-input">

                    <div className = "row admin-users-row">
                    
                    <h4>{title}</h4>
                    {Main.Instance.CategoryDetailState.selected && !Main.Instance.CategoryDetailState.Mode ? 
                    <ButtonIcon 
                        description = {Assets.discardChanges} 
                        click = {() => {
                            Logic.Type.New (Main.Instance.CategoryDetailState, {selected:false,category_id:'0'})}}
                        ClassName = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : void 0}

                    {Main.Instance.CategoryDetailState.selected && Main.Instance.CategoryDetailState.Mode ? 
                    <ButtonIcon 
                        description = {Assets.discardChanges} 
                        click = {() => {gotoInsertMode2()}} 
                        ClassName = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : void 0}

                    {!Main.Instance.CategoryDetailState.selected && Main.Instance.CategoryDetailState.mode ? 
                    <ButtonIcon 
                        description = {Assets.discardChanges} 
                        click = {() => {gotoInsertMode()}} 
                        ClassName = "btn btn-default btn-sm btn-plus fr mr0 mt-5" 
                        icon = {Assets_.faCancelButtonClass}
                    /> : void 0}
                    </div>

                    <div className = "row admin-users-row">

                    {Main.Instance.CategoryDetailState.selected ?
                    <FormInput 
                        value = {!Main.Instance.CategoryDetailState.detail_name ? '' : 
                                Main.Instance.CategoryDetailState.detail_name}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.CategoryDetailState, {detail_name:text})}} 
                        placeholder = {Assets.TypeName} 
                        icon = {Assets_.faCircleOClass}
                    />
                    :
                    <FormInput 
                        value = {!Main.Instance.CategoryDetailState.category_name ? '' : 
                                Main.Instance.CategoryDetailState.category_name}
                        ClassName = "form-control register-input" 
                        type = "text" 
                        onChange = {(text) => {
                            Logic.Type.New(Main.Instance.CategoryDetailState, {category_name:text})}} 
                        placeholder = {Assets.CategoryName} 
                        icon = {Assets_.faCircleClass}
                    />
                    }

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'casino'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'live casino'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_table_game ? '0' : 
                                Main.Instance.CategoryDetailState.detail_table_game}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrTableGame}
                        o2 = {Assets.AttrTableGameSelect}
                        o3 = {Assets.AttrTableGameAny}
                        onChange = {(text) => {ApplyTableGameSelect(text)}}
                        bigspan = {true}
                        iconText = {Assets_.clubs}
                        // icon = {Assets_.faGamingClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'casino'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'live casino'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_slots ? '0' : 
                                Main.Instance.CategoryDetailState.detail_slots}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrSlots}
                        o2 = {Assets.AttrSlotsSelect}
                        o3 = {Assets.AttrSlotsAny}
                        onChange = {(text) => {ApplySlotsSelect(text)}}
                        bigspan = {true}
                        iconText = {Assets_.spades}
                        // icon = {Assets_.faGamingClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'deposits'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'deposit'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_deposit_option ? '0' : 
                                Main.Instance.CategoryDetailState.detail_deposit_option}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrDepositOption}
                        o2 = {Assets.AttrDepositOptionSelect}
                        o3 = {Assets.AttrDepositOptionAny}
                        onChange = {(text) => {ApplyDepositOptionSelect(text)}}
                        icon = {Assets_.faCreditCardClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'logins'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'login'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_login_option ? '0' : 
                                Main.Instance.CategoryDetailState.detail_login_option}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrLoginOption}
                        o2 = {Assets.AttrLoginOptionSelect}
                        o3 = {Assets.AttrLoginOptionAny}
                        onChange = {(text) => {ApplyLoginOptionSelect(text)}}
                        icon = {Assets_.faCreditCardClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'registrations'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'registration'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_registration_option ? '0' : 
                                Main.Instance.CategoryDetailState.detail_registration_option}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrRegistrationOption}
                        o2 = {Assets.AttrRegistrationOptionSelect}
                        o3 = {Assets.AttrRegistrationOptionAny}
                        onChange = {(text) => {ApplyRegistrationOptionSelect(text)}}
                        icon = {Assets_.faCreditCardClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'withdrawals'
                    || GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'withdrawal'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_withdrawal_option ? '0' : 
                                Main.Instance.CategoryDetailState.detail_withdrawal_option}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrWithdrawalOption}
                        o2 = {Assets.AttrWithdrawalOptionSelect}
                        o3 = {Assets.AttrWithdrawalOptionAny}
                        onChange = {(text) => {ApplyWithdrawalOptionSelect(text)}}
                        icon = {Assets_.faCreditCardClass}
                    />
                    :
                    void 0}

                    {Main.Instance.CategoryDetailState.selected && ( 
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'fixed odds' ||
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'live odds' ||
                    GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase() === 'fixed + live odds'
                    )
                    ?
                    <FormSelect 
                        level20 = {true}
                        value = {!Main.Instance.CategoryDetailState.detail_odds_type ? '0' : 
                                Main.Instance.CategoryDetailState.detail_odds_type}
                        data = {[0]}
                        ClassName = "form-control"
                        o1 = {Assets.AttrOddsType}
                        o2 = {Assets.AttrOddsTypeSelect}
                        o3 = {Assets.AttrOddsTypeAny}
                        onChange = {(text) => {ApplyOddsTypeSelect(text)}}
                        icon = {Assets_.faFootballClass}
                    />
                    :
                    void 0}
                    </div>

                    <div className = "row admin-users-row">

                    <ButtonIcon 
                        description =   {Main.Instance.CategoryDetailState.mode || 
                                        Main.Instance.CategoryDetailState.Mode ? 
                                        Assets.saveChanges : 
                                        Assets.cCategory} 
                        click = {() => {handleInsertUpdate()}}
                        ClassName = {Main.Instance.CategoryDetailState.mode || Main.Instance.CategoryDetailState.Mode ? 
                        'btn btn-default btn-sm btn-plus fr mr0' :
                        Main.Instance.CategoryDetailState.selected ?
                        'btn btn-info btn-sm btn-plus fr mr0'
                        :
                        'btn btn-primary btn-sm btn-plus fr mr0'} 
                        Icon = {faSave as IconDefinition}
                        text = {Assets.Save}
                    />
                    </div>
                </div>
            </div>
        </div>
        <div style = {{float:'left', display:'block', width:'49%',marginRight:'2%'}}>

        <div className = "control-panel">

            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CategoryDetailState.pSize ? '' : 
                Main.Instance.CategoryDetailState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChanged(text)}
            />

            <button 
                onClick = {() => FirstPage()}
                title = {Assets.ListMoveTop}
                style = {{marginTop:'3px'}}
                className = "btn btn-primary btn-xs"
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                onClick = {() => PrevPage()}
                title = {Assets.ListMoveLeft} 
                style = {{marginTop:'3px'}}
                className = "btn btn-primary btn-xs"
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                onClick = {() => NextPage()}
                title = {Assets.ListMoveRight} 
                style = {{marginTop:'3px'}}
                className = "btn btn-primary btn-xs"
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                onClick = {() => LastPage()}
                title = {Assets.ListMoveBottom} 
                style = {{marginTop:'3px'}}
                className = "btn btn-primary btn-xs"
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label">
                {Assets.records}
                {Main.Instance.CategoryDetailState.count ? Main.Instance.CategoryDetailState.count : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.showingShort}
                {Main.Instance.CategoryDetailState.count ? Main.Instance.CategoryDetailState.pNum * 
                Main.Instance.CategoryDetailState.pSize + 1 : 0}{' - '}
                {Main.Instance.CategoryDetailState.count ? ((Main.Instance.CategoryDetailState.pNum + 1) * 
                Main.Instance.CategoryDetailState.pSize > Main.Instance.CategoryDetailState.count ? 
                Main.Instance.CategoryDetailState.count : (Main.Instance.CategoryDetailState.pNum + 1) * 
                Main.Instance.CategoryDetailState.pSize) : 0}
            </span>
            <span className = "label label-success table-label">
                {Assets.page}
                {Main.Instance.CategoryDetailState.count ? Main.Instance.CategoryDetailState.pNum + 1 :0}{' / '}
                {Main.Instance.CategoryDetailState.count ? 
                Math.trunc((Main.Instance.CategoryDetailState.count - 1) /
                Main.Instance.CategoryDetailState.pSize + 1) : 0}
            </span>
        </div>

        <h3>{Assets.tCategory}</h3>
        {Main.Instance.DatabaseDataState.pointTypeStream ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.pointTypeStream}
            columns = {[
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEdit)},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.SelectButton(row, handleSelect)},
                {Header: Assets.Category, accessor: 'category', headerClassName: 'th-left'},
                {maxWidth: 50, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDelete)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : getCategoryCount()}

        {/*Main.Instance.DatabaseDataState.pointTypeStream ? 
        StremingTable(Main.Instance.DatabaseDataState.pointTypeStream, {
            title: Assets.tCategory,
            mode: Main.Instance.CategoryDetailState.mode,
            selected: Main.Instance.CategoryDetailState.selected,
            selectButton:(data) => handleSelect(data),
            // rs_category: true,
            editingId: Main.Instance.CategoryDetailState.category_id,
            // category_skip: true,
            delButton:(data) => handleDelete(data), 
        editButton:(data) => handleEdit(data)}) : void 0*/}
        </div>
        <div className = "clearfix" style = {{width:'49%', display:'block', float: 'right'}}>

        <div className = "control-panel">

            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CategoryDetailState.PSize ? '' : 
                Main.Instance.CategoryDetailState.PSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChangedType(text)}
            />

            <button 
                onClick = {() => FirstPageType()}
                title = {Assets.ListMoveTop} 
                className = "btn btn-info btn-xs"
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                onClick = {() => PrevPageType()}
                title = {Assets.ListMoveLeft} 
                className = "btn btn-info btn-xs"
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                onClick = {() => NextPageType()}
                title = {Assets.ListMoveRight} 
                className = "btn btn-info btn-xs"
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                onClick = {() => LastPageType()}
                title = {Assets.ListMoveBottom} 
                className = "btn btn-info btn-xs"
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>

            <span className = "label label-success table-label tbinfo">
                {Assets.records}
                {Main.Instance.CategoryDetailState.Count ? Main.Instance.CategoryDetailState.Count : 0}
            </span>
            <span className = "label label-success table-label tbinfo">
                {Assets.showingShort}
                {Main.Instance.CategoryDetailState.Count ? Main.Instance.CategoryDetailState.PNum * 
                Main.Instance.CategoryDetailState.PSize + 1 : 0}{' - '}
                {Main.Instance.CategoryDetailState.Count ? ((Main.Instance.CategoryDetailState.PNum + 1) * 
                Main.Instance.CategoryDetailState.PSize > Main.Instance.CategoryDetailState.Count ? 
                Main.Instance.CategoryDetailState.Count : (Main.Instance.CategoryDetailState.PNum + 1) * 
                Main.Instance.CategoryDetailState.PSize) : 0}
            </span>
            <span className = "label label-success table-label tbinfo">
                {Assets.page}
                {Main.Instance.CategoryDetailState.Count ? Main.Instance.CategoryDetailState.PNum + 1 :0}{' / '}
                {Main.Instance.CategoryDetailState.Count ? 
                Math.trunc((Main.Instance.CategoryDetailState.Count - 1) /
                Main.Instance.CategoryDetailState.PSize + 1) : 0}
            </span>
        </div>
        {console.log (Main.Instance.DatabaseDataState.subPointTypeStream)}
        <h3>{Assets.tType}&nbsp;<small>{Assets._for_ + Assets.Category + ' ' + GetCategoryName(Main.Instance.CategoryDetailState.category_id)}</small></h3>
        {Main.Instance.DatabaseDataState.subPointTypeStream ? Main.Instance.DatabaseDataState.subPointTypeStream.length > 0 ?
        <ReactTable
            data = {Main.Instance.DatabaseDataState.subPointTypeStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEditType)},
                {Header: Assets.Type, accessor: 'type', headerClassName: 'th-left'},
                /*{header: 'Table Game', accessor: '', Cell: (row) => TableUtils.AttrButton(4, row, handleAttributeType)},*/
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.DeleteButton(row, handleDeleteType)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        /> : void 0 : getTypeCount()}
        {/*console.log (Main.Instance.DatabaseDataState.subPointTypeStream)*/}
        {/*Main.Instance.DatabaseDataState.subPointTypeStream ?
        Main.Instance.DatabaseDataState.subPointTypeStream.length > 0 ?
        StremingTable(Main.Instance.DatabaseDataState.subPointTypeStream, {
            title: Assets.tType,
            small_title: Assets._for_ + Assets.Category + ' ' + GetCategoryName(Main.Instance.CategoryDetailState.category_id),
            mode: Main.Instance.CategoryDetailState.Mode,
            editingId: Main.Instance.CategoryDetailState.detail_id,
            attrButton:(a, b, data) => {handleAttributeType(a,b,data)},
            categoryName: GetCategoryName(Main.Instance.CategoryDetailState.category_id).toLowerCase(),
            delButton:(data) => {handleDeleteType(data)}, 
        editButton:(data) => {handleEditType(data)}}) : void 0 : void 0*/}
        </div>
        <div className = "clearfix"/>
        </div>
    )
}
