import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import {SelectedFrontEndSetting} from '../../../Logic/RiskComplianceStore/RiskManagement'
// import Switch from 'react-toggle-switch'
import {/*isUserAdmin, */injectSpaces/*, isUserRFSpecialist, isUserRGSpecialist*/} from '../../../Logic/Utils'
import {faSave, faPlus/*, faDesktop, faMobileAlt, faTabletAlt, faTrashAlt*/} from '@fortawesome/free-solid-svg-icons'
// import ReactTable from 'react-table'
// import * as TableUtils from '../../Reusables/TableUtils'
import {GetLegend} from '../RiskComplianceStore/RiskManagement'
// import {InsideTitle} from '../RiskComplianceStore/RiskManagement'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Custom, GameCategories/*, GameCategory/*, GameLabel*/, Tag/*, SCategory*/} from '../../../Logic/Database/DatabaseData'
import * as Config from '../../../Logic/Config'
// import {/*getStaticItems*/getAllowedString, getAllowedNumber, setOption, getOptions, addOption} from './PlayerSettings'
import {getCategoryName} from '../PlayerManagement/PlayerCard'
// import {Icon, InlineIcon } from '@iconify/react'
// import strapiIcon from '@iconify/react/logos/strapi'
// import {riskLevelSlider} from '../RiskComplianceStore/ResponsibleGaming'
/*
function handleNumber(id: number, text: string) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    if (id === 1) custom.responsibleGaming.selfAssessment.answersScale.min = +text
    else if (id === 2) custom.responsibleGaming.selfAssessment.answersScale.max = +text
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
}
*/
function selectNavigation(selectedFrontEndSetting: SelectedFrontEndSetting) {
    Logic.Type.New (Main.Instance.RiskManagementState, {selectedFrontEndSetting})
}

function getNavigation(): JSX.Element[] {
    const navigation: JSX.Element[] = []
    if (Main.Instance.LoginState !== null && Main.Instance.LoginState !== undefined) {
    for (let i: number = 0; i < Object.keys(SelectedFrontEndSetting).length / 2; i++) {
        navigation.push(
            <button
                // title = {i === SelectedFrontEndSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedFrontEndSetting.ResponsibleGaming && !isUserRGSpecialist() ? Assets.pleaseUpgradeUserSecurityRightsForAccess : ''}
                // disabled = {i === SelectedFrontEndSetting.RiskManagement && !isUserRFSpecialist() || i === SelectedFrontEndSetting.ResponsibleGaming && !isUserRGSpecialist()}
                onClick = {() => selectNavigation(i)}
                type = "button"
                key = {i.toString()}
                style = {{
                    marginRight: i === Object.keys(SelectedFrontEndSetting).length / 2 - 1 ? '0px' : '',
                    display: 'block',
                    float: 'left',
                }}
                className = {i === Main.Instance.RiskManagementState.selectedFrontEndSetting ? 'btn btn-primary btn-sm pr' : 'btn btn-default btn-sm pr'}
            >
                {/*i === SelectedFrontEndSetting.GameLabelsStrapiGameStore ?
                    <>{Assets_.strapi(Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.GameLabelsStrapiGameStore ? '#fff' : '#333', 19,  5, 9)}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></> :
                    <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedFrontEndSettingIcons[i]}/>
                */}
                <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {Assets_.SelectedFrontEndSettingIcons[i]}/>
                {injectSpaces(SelectedFrontEndSetting[i])}
            </button>)
    }
    }

    navigation.push(
        <button 
            onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)}
            type = "button"
            key = "-1"
            style = {{
                marginRight: '0px',
                display: 'block',
                float: 'right',
            }}
            className = "btn btn-warning btn-sm"
        >
            <FontAwesomeIcon style = {{marginRight: '7px', float: 'left', fontSize: '17px'}} icon = {faSave}/>
            {Assets.Update}
        </button>)

    return navigation
}

export function FrontEndSettings(props: React.Props<any>): JSX.Element {
    DbRoutes.wsReinitialize()
    if (Main.Instance.DatabaseDataState.gameLabel_ === undefined && Main.Instance.DatabaseDataState.tags) {
        const gameLabel_: string = JSON.stringify(Main.Instance.DatabaseDataState.tags)
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel_})
    }

    // if (Main.Instance.DatabaseDataState.gameCategory_ === undefined && Main.Instance.DatabaseDataState.scategories) {
    //     const gameCategory_: string = JSON.stringify(Main.Instance.DatabaseDataState.scategories)
    //     Logic.Type.New (Main.Instance.DatabaseDataState, {gameCategory_})
    // }

    if (Main.Instance.DatabaseDataState.category_ === undefined && Main.Instance.DatabaseDataState.gamesListCategories !== undefined) {
        const category_: string[] = []
        for (const {} of Main.Instance.DatabaseDataState.gamesListCategories) {
            category_.push(undefined)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
     }
    return (
        <div>
            <div className = "clearfix">
                <h2 className = "fl">{Assets.SiteSettings}{/*<InlineIcon icon={strapiIcon}/>{Assets_.strapi}*/}</h2>
                {GetLegend()}
            </div>
            <div>{getNavigation()}</div>
            <br/>
            <br/>
            {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general ?
            <div>
                {/*<h3>{Assets.Settings}</h3>*/}
                <div className =  "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style = {{padding: '10px', position: 'relative', minHeight: '50px'}}>
                    <div 
                        className = "clearfix test-site-data2 light_grey" 
                        style = {{marginTop:'0px !important', paddingTop:'10px', paddingBottom:'10px'}}
                    >
                        {/*InsideTitle(undefined, injectSpaces(SelectedFrontEndSetting[Main.Instance.RiskManagementState.selectedFrontEndSetting]), Assets.SelectedFrontEndSettingIcons[Main.Instance.RiskManagementState.selectedFrontEndSetting])*/}
                        {/*<br/>*/}

                        {Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.GameCategories ?
                        <>
                            <div>
                                <h4>{Assets.GamesCanBeCategorizedInTheFollowingCategories}:</h4>
                                {getCategoriesClient()}
                            </div>
                        </> : void 0}

                        {/*Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.GameLabels ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeGivenOneOrMoreLabelsBelow}:</h4>
                                {getLabelsCentral()}
                            </div>
                        </> : void 0*/}


                        {Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.GameLabels ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeGivenOneOrMoreLabelsBelow}:</h4>
                                {getLabelsClient()}
                            </div>
                        </> : void 0}

                        {/* Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.Jurisdictions ?
                        <>
                            {
                            <h4>{Assets.AllowedJurisdictions}</h4>
                            <div className="clearfix">{getStaticItems(3, Main.Instance.DatabaseDataState.jurisdictions, true)}</div>
                            }
                                <h4>{Assets.SelectJurisdictions}</h4>
                                <div className="mt5">
                                    <strong>{Assets.EnableJurisdiction}:</strong>&nbsp;
                                        {getAllowedString(3, Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsAllowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(3, e.target.value)} value={Main.Instance.RiskManagementState.allowedJurisdiction}>
                                        {getOptions(3)}
                                    </select>
                                    <button onClick={() => addOption(3)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedJurisdiction === '0'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                                <br />
                                <h4>{Assets.SelectJurisdictionTiers}</h4>
                                <div className="mt5">
                                    <strong>{Assets.EnableJurisdictionTier}:</strong>&nbsp;
                                        {getAllowedNumber(4, Main.Instance.DatabaseDataState.custom.general.players.jurisdictionsTiersAllowed)}
                                    <select style={{ height: '22px' }} onChange={(e) => setOption(4, e.target.value)} value={Main.Instance.RiskManagementState.allowedJurisdictionTier}>
                                        {getOptions(4)}
                                    </select>
                                    <button onClick={() => addOption(4)} className="btn btn-primary btn-xs mr0 ml" disabled={Main.Instance.RiskManagementState.allowedJurisdictionTier === '-1'}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                        </> : void 0 */}

                        {/*Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.SupportedDevices ?
                        <>
                            <div>
                                <h4>{Assets.EachGameCanBeSupportedonOneOrMoreDevicesBelow}:</h4>
                                {getDevices()}
                            </div>
                        </> : void 0*/}

                        {/*Main.Instance.RiskManagementState.selectedFrontEndSetting === SelectedFrontEndSetting.LimitTypes ?
                        <>
                              <div>
                                {getItems(6, Main.Instance.DatabaseDataState.custom.general.limitTypes)}
                            </div>
                        </> : void 0*/}
                    </div>
                </div>
            </div> : void 0}            
        </div>
    )
}

export function ToggleSwitch(i: number, j: number) {
    const custom: Custom = Object.assign({}, Main.Instance.DatabaseDataState.custom)
    const what: string = j === 1 ? 'isEmailVerified' : j === 2 ? 'isDocumentVerified' : j === 3 ? 'isUtilityBillVerified' : j === 4 ? 'isSuperVerified' :
        j === 5 ? 'sentUser' : j === 6 ? 'viewUser' : j === 7 ? 'modifyUser' : j === 8 ? 'viewAdmin' : j === 9 ? 'modifyAdmin' : ''

    if (j < 5) {
        if (custom.general.players.accountStatus[i].requirements && custom.general.players.accountStatus[i].requirements[what]) {
            delete (custom.general.players.accountStatus[i].requirements[what])
        } else {
            if (custom.general.players.accountStatus[i].requirements === undefined) {
                custom.general.players.accountStatus[i].requirements = {}
            }
            custom.general.players.accountStatus[i].requirements[what] = true
        }
    } else {
        if (custom.general.players.playerCard[i].flags && custom.general.players.playerCard[i].flags[what]) {
            delete (custom.general.players.playerCard[i].flags[what])
        } else {
            if (custom.general.players.playerCard[i].flags === undefined) {
                custom.general.players.playerCard[i].flags = {}
            }
            custom.general.players.playerCard[i].flags[what] = true
        }
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, { custom })
}
/*
function getDevices(): JSX.Element[] {
    const devices: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.custom.games.devices) {
        // console.log (Main.Instance.DatabaseDataState.custom.games.labels)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.custom.games.devices.length; i++) {
            devices.push(<button style = {{cursor: 'default', marginBottom: '2px'}} key = {i.toString()} title = {Main.Instance.DatabaseDataState.custom.games.devices[i] === Assets.VideoLotteryTerminalAbbrev ? Assets.VideoLotteryTerminal : ''} className = "btn btn-xs btn-info">{Main.Instance.DatabaseDataState.custom.games.devices[i] === 'mobile' || Main.Instance.DatabaseDataState.custom.games.devices[i] === 'desktop' || Main.Instance.DatabaseDataState.custom.games.devices[i] === 'tablet' ? <FontAwesomeIcon style = {{marginRight: '5px'}} icon = {Main.Instance.DatabaseDataState.custom.games.devices[i] === 'mobile' ? faMobileAlt : Main.Instance.DatabaseDataState.custom.games.devices[i] === 'desktop' ? faDesktop : Main.Instance.DatabaseDataState.custom.games.devices[i] === 'tablet' ? faTabletAlt : faTabletAlt}/> : void 0}{Main.Instance.DatabaseDataState.custom.games.devices[i]}</button>)
        }
    }
    // devices.push(<input key = "-2" style = {{height: '22px',  marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState ? Main.Instance.DatabaseDataState.gameLabel : ''}/>)
    // devices.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabel === undefined || Main.Instance.DatabaseDataState.gameLabel.length === 0 || itemExists(Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels ? Main.Instance.DatabaseDataState.custom.games.labels : [], Main.Instance.DatabaseDataState.gameLabel)} onClick = {() => addLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // devices.push(<button disabled = {Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.custom.games.labels)} onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return devices
}
*/
function getLabelsClient(): JSX.Element[] {
    const labels: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.tags) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.tags.length; i++) {
            labels.push(<button title = {Assets.RemoveLabel + ' ' + Main.Instance.DatabaseDataState.tags[i].name} onClick = {() => removeLabel(Main.Instance.DatabaseDataState.tags[i].name)} style = {{marginBottom: '2px'}} key = {i.toString()} className = "btn btn-xs btn-primary">{Main.Instance.DatabaseDataState.tags[i].name}</button>)
        }
    }
    labels.push(<input key = "-2" style = {{height: '22px', marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState.gameLabel ? Main.Instance.DatabaseDataState.gameLabel : ''}/>)
    labels.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabel === undefined || Main.Instance.DatabaseDataState.gameLabel.length === 0 || itemExists(Main.Instance.DatabaseDataState.tags ? Main.Instance.DatabaseDataState.tags : [], Main.Instance.DatabaseDataState.gameLabel)} onClick = {() => addLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // labels.push(<button disabled = {Main.Instance.DatabaseDataState.tags && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.tags)} onClick = {() => DbRoutes.wsBasicUpdate(Config.amSettings, 1, Main.Instance.DatabaseDataState.custom)} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return labels
}
/*
function getCategoriesClient(): JSX.Element[] {
    const categories: JSX.Element[] = []
    // console.log (Main.Instance.DatabaseDataState.scategories)
    if (Main.Instance.DatabaseDataState.scategories) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.scategories.length; i++) {
            categories.push(<button title = {Assets.RemoveCategory + ' ' + Main.Instance.DatabaseDataState.scategories[i].name} onClick = {() => removeCategory(Main.Instance.DatabaseDataState.scategories[i].name)} style = {{marginBottom: '2px'}} key = {i.toString()} className = "btn btn-xs btn-default btn-dark">{Main.Instance.DatabaseDataState.scategories[i].categoryId}.&nbsp;{Main.Instance.DatabaseDataState.scategories[i].name}</button>)
        }
    }
    categories.push(<input key = "-2" style = {{height: '22px', marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameCategory: e.target.value})} value = {Main.Instance.DatabaseDataState.gameCategory ? Main.Instance.DatabaseDataState.gameCategory : ''}/>)
    categories.push(<button disabled = {Main.Instance.DatabaseDataState.gameCategory === undefined || Main.Instance.DatabaseDataState.gameCategory.length === 0 || ItemExists(Main.Instance.DatabaseDataState.scategories ? Main.Instance.DatabaseDataState.scategories : [], Main.Instance.DatabaseDataState.gameCategory)} onClick = {() => addCategory()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)

    return categories
}
*/

function getCategoriesClient(): JSX.Element[] {
    const categories: JSX.Element[] = []
    // console.log (Main.Instance.DatabaseDataState.scategories)
/*
    if (Main.Instance.DatabaseDataState.scategories) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.scategories.length; i++) {
            categories.push(<span style = {{marginBottom: '2px', fontSize: '13px', marginLeft: i === 0 ? '0px' : '10px'}} key = {i.toString()} title = {Main.Instance.DatabaseDataState.scategories[i].categoryId === 0 ? Assets.Invisible : Assets.Visible} className = {Main.Instance.DatabaseDataState.scategories[i].categoryId === 0 ? 'label label-sm label-default' : 'label label-sm label-primary'}>{Main.Instance.DatabaseDataState.scategories[i].categoryId}.&nbsp;{Main.Instance.DatabaseDataState.scategories[i].name}</span>)
        }
    }
*/
    for (let i: number = 0; i < Object.keys(GameCategories).length / 2; i++) {
        categories.push(<span style = {{marginBottom: '2px', fontSize: '13px', marginLeft: i === 0 ? '0px' : '10px'}} key = {i.toString()} title = {i === 0 ? Assets.Invisible : Assets.Visible} className = {i === 0 ? 'label label-sm label-default' : 'label label-sm label-primary'}>{i}.&nbsp;{getCategoryName(i)}</span>)
    }



/*
    categories.push(<input key = "-2" style = {{height: '22px', marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {gameCategory: e.target.value})} value = {Main.Instance.DatabaseDataState.gameCategory ? Main.Instance.DatabaseDataState.gameCategory : ''}/>)
    categories.push(<button disabled = {Main.Instance.DatabaseDataState.gameCategory === undefined || Main.Instance.DatabaseDataState.gameCategory.length === 0 || ItemExists(Main.Instance.DatabaseDataState.scategories ? Main.Instance.DatabaseDataState.scategories : [], Main.Instance.DatabaseDataState.gameCategory)} onClick = {() => addCategory()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
*/
    return categories
}


/*
function getLabelsCentral(): JSX.Element[] {
    const labels: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.gameLabels) {
        // console.log (Main.Instance.DatabaseDataState.gameLabel)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.gameLabels.length; i++) {
            labels.push(<button title = {Assets.RemoveLabel + ' ' + Main.Instance.DatabaseDataState.gameLabels[i].name} onClick = {() => RemoveLabel(Main.Instance.DatabaseDataState.gameLabels[i].id, i)} style = {{marginBottom: '2px'}} key = {i.toString()} className = "btn btn-xs btn-primary">{Main.Instance.DatabaseDataState.gameLabels[i].name}</button>)
        }
    }
    labels.push(<input key = "-2" style = {{height: '22px',  marginBottom: '2px'}} onChange = {(e) => Logic.Type.New (Main.Instance.DatabaseDataState, {GameLabel: e.target.value})} value = {Main.Instance.DatabaseDataState.GameLabel ? Main.Instance.DatabaseDataState.GameLabel : ''}/>)
    labels.push(<button disabled = {Main.Instance.DatabaseDataState.GameLabel === undefined || Main.Instance.DatabaseDataState.GameLabel.length === 0 || ItemExists(Main.Instance.DatabaseDataState.gameLabels ? Main.Instance.DatabaseDataState.gameLabels : [], Main.Instance.DatabaseDataState.GameLabel)} onClick = {() => AddLabel()} style = {{marginBottom: '2px'}} key = "-1" className = "btn btn-xs btn-primary br0"><FontAwesomeIcon icon = {faPlus}/></button>)
    // labels.push(<button disabled = {Main.Instance.DatabaseDataState.gameLabels && Main.Instance.DatabaseDataState.gameLabel_ === JSON.stringify (Main.Instance.DatabaseDataState.gameLabels)} onClick = {() => void 0} style = {{marginBottom: '2px'}} key = "-3" className = "btn btn-xs btn-warning mr0 br0"><FontAwesomeIcon icon = {faSave}/>&nbsp;&nbsp;{Assets.Update}</button>)
    return labels
}
*/
/*
function removeLabel (i: number) {
    const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
    custom.games.labels.splice(i, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {custom, gameLabel: ''})
}
*/
function itemExists(array: Tag[], item: string): boolean {
    for (const Item of array) { if (Item.name === item) { return true } }
    return false
}
/*
function ItemExists(array: SCategory[], item: string): boolean {
    for (const Item of array) { if (Item.name === item) { return true } }
    return false
}
*/
// DbRoutes.updateGameTags(string[])

function addLabel() {
    let found: boolean = false
    
    if (Main.Instance.DatabaseDataState.tags) {
        for (const item of Main.Instance.DatabaseDataState.tags) {
            if (item.name === Main.Instance.DatabaseDataState.gameLabel) {
                found = true
                break
            }
        }
    }
    
    if (!found) {
        const newTags: string[] =  []
        const tags: Tag[] = Object.assign([], Main.Instance.DatabaseDataState.tags)
        if (Main.Instance.DatabaseDataState.tags) {
        for (const item of Main.Instance.DatabaseDataState.tags) {
            newTags.push(item.name)
        }
        }
        tags.push({name: Main.Instance.DatabaseDataState.gameLabel})
        newTags.push(Main.Instance.DatabaseDataState.gameLabel)
        DbRoutes.updateGameTags(newTags)
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameLabel: '', tags, gameLabel_: JSON.stringify(tags)})
    }
}

function removeLabel(tag: string) {
    const newTags: string[] = []
    const tags: Tag[] = []
    if (Main.Instance.DatabaseDataState.tags) {
    for (const item of Main.Instance.DatabaseDataState.tags) {
        if (item.name !== tag) {
            tags.push({name: item.name})
            newTags.push(item.name)
        }
    }
    }
    Logic.Type.New (Main.Instance.DatabaseDataState, {tags, gameLabel_: JSON.stringify(tags)})
    DbRoutes.updateGameTags(newTags)
}
/*
function removeCategory(category: string) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.Category.toLowerCase() + ' ' + category + '?')) {
        const newCategories: string[] = []
        const scategories: SCategory[] = []
        if (Main.Instance.DatabaseDataState.scategories) {
        for (const item of Main.Instance.DatabaseDataState.scategories) {
            if (item.name !== category) {
                scategories.push(item)
                newCategories.push(item.name)
            }
        }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {scategories, gameCategory_: JSON.stringify(scategories)})
        DbRoutes.updateGameCategories(newCategories)
    }
}

function addCategory() {
    let found: boolean = false
    
    if (Main.Instance.DatabaseDataState.scategories) {
        for (const item of Main.Instance.DatabaseDataState.scategories) {
            if (item.name === Main.Instance.DatabaseDataState.gameCategory) {
                found = true
                break
            }
        }
    }
    
    if (!found) {
        const newCategories: string[] =  []
        const scategories: SCategory[] = Object.assign([], Main.Instance.DatabaseDataState.scategories)
        let categoryId: number = 0
        if (Main.Instance.DatabaseDataState.scategories) {
        for (const item of Main.Instance.DatabaseDataState.scategories) {
            newCategories.push(item.name)
            if (item.categoryId > categoryId) { categoryId = item.categoryId }
        }
        }
        scategories.push({name: Main.Instance.DatabaseDataState.gameCategory, categoryId: categoryId + 1})
        newCategories.push(Main.Instance.DatabaseDataState.gameLabel)
        DbRoutes.updateGameCategories(newCategories)
        Logic.Type.New (Main.Instance.DatabaseDataState, {gameCategory: '', scategories, gameCategory_: JSON.stringify(scategories)})
    }
}
*/
/*
function addLabel() {
    let found: boolean = false
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.games && Main.Instance.DatabaseDataState.custom.games.labels)
    for (const item of Main.Instance.DatabaseDataState.custom.games.labels) {
        if (item === Main.Instance.DatabaseDataState.gameLabel) {
            found = true
            break
        }
    }

    if (!found) {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        if (custom.games === undefined) custom.games = {}
        if (custom.games.labels === undefined) custom.games.labels = []
        custom.games.labels.push(Main.Instance.DatabaseDataState.gameLabel)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom, gameLabel: ''})
    }
}
*/
/*
function AddLabel() {
    let found: boolean = false
    for (const item of Main.Instance.DatabaseDataState.gameLabels) {
        if (item.name === Main.Instance.DatabaseDataState.GameLabel) {
            found = true
            break
        }
    }

    if (!found) DbRoutes.insertLabel(Main.Instance.DatabaseDataState.GameLabel)
    Logic.Type.New (Main.Instance.DatabaseDataState, {GameLabel: ''})
}

function RemoveLabel (i: number, index: number) {
    DbRoutes.deleteLabel(i)
    const gameLabels: GameLabel[] = Object.assign([], Main.Instance.DatabaseDataState.gameLabels)
    gameLabels.splice (index, 1)
    Logic.Type.New(Main.Instance.DatabaseDataState, {gameLabels})
}
*/

/*
function updateName(i: number, value: string) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: GameCategory[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        // if (j === undefined) {
        categories[i].name = value
        // } else categories[i].data.subs[j].name = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined})
    }
}
*/
/*
function UpdateName(i: number, value: string) {
    if (Main.Instance.DatabaseDataState.categories) {
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[i] = value
        Logic.Type.New (Main.Instance.DatabaseDataState, {category_})
    }
}


function updateAllCategoriesDisabled(): boolean {
    let disabled: boolean = true
    if (Main.Instance.DatabaseDataState.categories)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if (JSON.stringify(Main.Instance.DatabaseDataState.categories[i]) !== Main.Instance.DatabaseDataState.categories_[i]) {
            disabled = false
            break
        }
    }
    return disabled    
}

function updateAllCategories() {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.categories.length; i++) {
        if ()
        DbRoutes.wsBasicUpdate(Config.amCategories, Main.Instance.DatabaseDataState.categories[i].id, Main.Instance.DatabaseDataState.categories[i].data)
    }
}
*/
/*
function addCategory() {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: GameCategory[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        let greatestID: number = 0
        for (const item of categories) {
            if (item.id > greatestID) {
                greatestID = item.id
            }
        }
        greatestID++
        categories.push({id: greatestID, name: Main.Instance.DatabaseDataState.category})
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined})
    }
}
*/
/*
function addSubCategory(category: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories: Category2[] = Object.assign([], Main.Instance.DatabaseDataState.categories)
        
        let greatestID: number = 0
        if (categories[category].data.subs) {
            for (const item of categories[category].data.subs) {
                if (item.id > greatestID) {
                    greatestID = item.id
                }
            }
        } else {
            categories[category].data.subs = []
        }
        greatestID++

        categories[category].data.subs.push({id: greatestID, name: Main.Instance.DatabaseDataState.category_[category], active: true})
        const category_: string[] = Object.assign([], Main.Instance.DatabaseDataState.category_)
        category_[category] = undefined
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories, category: undefined, category_})
    }
}
*/
/*
function toggleCategory(i: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.active = !categories[i].data.active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}
*/
/*
function toggleSubcategory(i: number, j: number) {
    if (Main.Instance.DatabaseDataState.categories) {
        const categories = Object.assign([], Main.Instance.DatabaseDataState.categories)
        categories[i].data.subs[j].active = !categories[i].data.subs[j].active
        Logic.Type.New (Main.Instance.DatabaseDataState, {categories})
    }
}
*/