import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'

export function DataStoreWrapper(props: React.Props<any> ) {
    dataStoreRouter()
    return (<div>{props.children}</div>)
}

function dataStoreRouter() {
    if (RouterObjects['cRM'] && Main.Instance.Location === RouterObjects['cRM'].path) {
        Logic.Type.New(Main.Instance, {Location: RouterObjects['cRM'].path + RouterObjects['cRM'].children['Player_Groups'].path})
    }
}
