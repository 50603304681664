import * as React from 'react'
import { Main } from '../Logic/Main'
import * as Assets from './Assets'
import {timeoutMessage} from '../Logic/Config'
import { Logic } from 'src/Logic/Type';
import {PlayerCardButtons} from 'src/Logic/RiskComplianceStore/RiskManagement'
export class Messages extends React.Component <{}, {}> {
    constructor(props:any) {
        super(props)

        // this.openingPlayerCardMessagesCallback = this.openingPlayerCardMessagesCallback.bind(this);
        // DbRoutes.wsReinitialize()
    }
    public allAccountsMessages() {
        if (Main.Instance.DatabaseDataState.allAccountsData && Main.Instance.DatabaseDataState.allAccountsPage - 1 === Main.Instance.DatabaseDataState.allAccountsData.length / Main.Instance.DatabaseDataState.allAccountsLimit) {
            return (
            <>
            <div style = {{marginTop: '10px'}}>
                {Assets.Downloaded} {Main.Instance.DatabaseDataState.allAccountsData.length} {Assets.Accounts.toLowerCase()}
            </div>
            </>
            )
        } 
    }

    public expiringAccountsMessages() {
        if (Main.Instance.DatabaseDataState.expiringAccountsData && Main.Instance.DatabaseDataState.expiringAccountsPage - 1 === Main.Instance.DatabaseDataState.expiringAccountsData.length / Main.Instance.DatabaseDataState.expiringAccountsLimit) {
            return (
            <>
            <div style = {{marginTop: '10px'}}>
                {Assets.Downloaded} {Main.Instance.DatabaseDataState.expiringAccountsData.length} {Assets.Accounts.toLowerCase()}
            </div>
            </>
            )
        } 
    }
/*
    public openingPlayerCardMessagesCallback() {
        console.log('openingPlayerCardMessagesCallback')
        Logic.Type.New (Main.Instance.DatabaseDataState, {openingPlayerCard: false})
    }
*/
    public openingPlayerCardMessages() {
        // let length = 5
        // console.log(Main.Instance.DatabaseDataState.openingPlayerCard)
        // console.log(Main.Instance.DatabaseDataState.openedPlayerCard)
        if (Main.Instance.DatabaseDataState.openingPlayerCard) {
            if (Main.Instance.DatabaseDataState.openedPlayerCard) {
                // console.log('openingPlayerCardMessagesCallback0')
                // console.log(new Date())
                Logic.Type.New (Main.Instance.DatabaseDataState, {openedPlayerCard: false})
                window.setTimeout(() => Logic.Type.New (Main.Instance.DatabaseDataState, {openingPlayerCard: false}), timeoutMessage * 1000)
            } 
            return (
                <>
                <div>{Assets.OpeningThePlayercard}</div>
                </>
            )
        } else {
            return void 0
        }
    }

    public render() {
        return (
            <div>
                {Main.Instance.DatabaseDataState.openingPlayerCard
                || Main.Instance.DatabaseDataState.expiringAccountsStarted
                || Main.Instance.DatabaseDataState.allAccountsStarted
                || Main.Instance.PlayerCardState.gettingGameSessions
                || Main.Instance.PlayerCardState.selectingGameSessionId
                || ((Main.Instance.Location === '/game-store/gamingenius-game-list' 
                || Main.Instance.Location === '/game-store/approved-games-store' 
                || Main.Instance.Location === '/front-end-store/export-to-cms') && (
                    Main.Instance.DatabaseDataState.loadingGames
                    || Main.Instance.DatabaseDataState.loadingGames2
                    /*|| Main.Instance.DatabaseDataState.loadingGames3
                    || Main.Instance.DatabaseDataState.loadingGames4 */
                ))
                // || Main.Instance.DatabaseDataState.WithdrawalRequest1
                // || Main.Instance.DatabaseDataState.WithdrawalRequest2
                ?
                <div className = "centered message">
                    {this.openingPlayerCardMessages()}
                    {Main.Instance.DatabaseDataState.expiringAccountsStarted ?
                        <>
                        <div>{Assets.GettingDataForProvisionalAccountsCSVExport}</div>
                        <div>{Assets.PleaseWaitThisOperationWillTakeSomeTime}</div>
                        </>
                    : void 0}

                    {this.expiringAccountsMessages()}
                    
                    {Main.Instance.DatabaseDataState.allAccountsStarted ?
                        <>
                        <div>{Assets.GettingDataForAllAccountsCSVExport}</div>
                        <div>{Assets.PleaseWaitThisOperationWillTakeSomeTime}</div>
                        </>
                    : void 0}

                    {this.allAccountsMessages()}

                    {Main.Instance.PlayerCardState.selectingGameSessionId ?
                        <>
                        <div>{Assets.PleaseWaitSelectionOfGameSessionInProgress}</div>
                        </>
                    : void 0}

                    {Main.Instance.PlayerCardState.gettingGameSessions && Main.Instance.PlayerCardState.nav === PlayerCardButtons.GameSessions ?
                        <>
                        <div>{Assets.PleaseWaitGettingGameSessionsInProgress}</div>
                        </>
                    : void 0}

                    {/*Main.Instance.DatabaseDataState.WithdrawalRequest1 ?
                        <>
                        <div>{Assets.PleaseWaitGettingPendingWithdrawalRequests}</div>
                        {Main.Instance.DatabaseDataState.withdrawalRequest1.length ?
                        <div>{Assets.Number + Assets._of_ + Assets.WithdrawalRequests.toLowerCase()}: {Main.Instance.DatabaseDataState.withdrawalRequest1.length}</div>
                        : void 0}
                        </>
                    : void 0*/}

                    {/*Main.Instance.DatabaseDataState.WithdrawalRequest2 ?
                        <>
                        <div>{Assets.PleaseWaitGettingProcessedWithdrawalRequests}</div>
                        {Main.Instance.DatabaseDataState.withdrawalRequest2.length ?
                        <div>{Assets.Number + Assets._of_ + Assets.WithdrawalRequests.toLowerCase()}: {Main.Instance.DatabaseDataState.withdrawalRequest2.length}</div>
                        : void 0}
                        </>
                    : void 0*/}

                    {(Main.Instance.Location === '/game-store/gamingenius-game-list' || Main.Instance.Location === '/game-store/approved-games-store' || Main.Instance.Location === '/front-end-store/export-to-cms') && (Main.Instance.DatabaseDataState.loadingGames || Main.Instance.DatabaseDataState.loadingGames2 /*|| Main.Instance.DatabaseDataState.loadingGames3 || Main.Instance.DatabaseDataState.loadingGames4*/) ?
                        <>
                        <div>{Assets.LoadingGameStore}</div>
                        </>
                    : void 0}
                </div> : void 0}
            </div>
        )
    }

}