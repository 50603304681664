import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import { Main } from '../../../Logic/Main'
import ReactTable from 'react-table'
import * as Assets from '../../Assets'
import {IMissionLeaderboard} from '../../../Logic/Database/DatabaseData'
import { months } from '../../../Logic/DataStore/DataStore'
// import Switch from 'react-toggle-switch'
import {FormInputNumber,} from '../../Reusables/Inputs'
import * as Assets_ from '../../Assets_'
// import { Logic } from '../../../Logic/Type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { Logic } from 'src/Logic/Type'

function RecordsPerPageChanged(text) {
    if (parseInt(text) > 0) {
        Logic.Type.New(Main.Instance.MissionLeaderboardState, {pageSize: parseInt(text)})
        getChancesData()
    }
}

function  FirstPage() {
    Logic.Type.New(Main.Instance.MissionLeaderboardState, {selectedPage: 0})
    getChancesData()
}

function PrevPage() {
    if (Main.Instance.MissionLeaderboardState.selectedPage > 0) {
        Logic.Type.New(Main.Instance.MissionLeaderboardState, {selectedPage: Main.Instance.MissionLeaderboardState.selectedPage - 1})
        getChancesData()
    } else {
        LastPage()
    }
}

function NextPage() {
    if ((Main.Instance.MissionLeaderboardState.selectedPage + 1) * Main.Instance.MissionLeaderboardState.pageSize < Main.Instance.DatabaseDataState.missionLeaderboardCount) {
        Logic.Type.New(Main.Instance.MissionLeaderboardState, {selectedPage: Main.Instance.MissionLeaderboardState.selectedPage + 1})
        getChancesData()
    } else {
        FirstPage()
    }
}

function LastPage() {
    if (Main.Instance.DatabaseDataState.missionLeaderboardCount <= Main.Instance.MissionLeaderboardState.pageSize) {
        FirstPage()
    } else {
        let npNum: number = 1;
        if (Main.Instance.DatabaseDataState.missionLeaderboardCount === 0) {
        } else {
            npNum = Math.trunc((Main.Instance.DatabaseDataState.missionLeaderboardCount - 1) /
            Main.Instance.MissionLeaderboardState.pageSize)
        }
        Logic.Type.New(Main.Instance.MissionLeaderboardState, {selectedPage: npNum})
        getChancesData()
    }
}


function getMonthOptions(): JSX.Element[] {
    const monthOptions: JSX.Element[] = []
    // monthOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Month}</option>)
    for (let i: number = 0; i < months.length; i++) {
        monthOptions.push (<option key = {i.toString() + 'month'} value = {i}>{months[i]}</option>)
    }
    return monthOptions
}

function getYearOptions(): JSX.Element[] {
    const yearOptions: JSX.Element[] = []
    // yearOptions.push (<option key = "-1" value = {-1}>{Assets.Select + ' ' + Assets.Year}</option>)
    for (let i: number = 2019; i < 2030; i++) {
        yearOptions.push (<option key = {i.toString() + 'year'} value = {i}>{i}</option>)
    }
    return yearOptions
}

function getDateFromTo(month: number, year: number) {
    let dateFrom: Date
    let dateTo: Date

    const Month = month ? month : Main.Instance.MissionLeaderboardState.month
    const Year = year ? year : Main.Instance.MissionLeaderboardState.year

    dateFrom = new Date(Date.UTC(Year, Month, 1, 0, 0, 0))
    dateTo = new Date(Date.UTC(Month === 11 ? (Year + 1) : Year, Month === 11 ? 0 : Month + 1, 1, 0, 0, 0))

    if (dateFrom && dateTo && (dateTo.getTime() / 1000).toFixed(0) < (new Date().getTime() / 1000).toFixed(0)) {
        DbRoutes.getChancesLeaderboard((dateFrom.getTime() / 1000).toFixed(0), (dateTo.getTime() / 1000).toFixed(0))
    } else {
        DbRoutes.getChancesLeaderboard(undefined, undefined)
    }

}

function downloadCSVList() {

    const array: IMissionLeaderboard[] = Object.assign([], Main.Instance.DatabaseDataState.missionLeaderboard)
    let s: string = 'Position,Player Id,Player Username,Tasks Completed\n'
    for (let i: number = 0; i < array.length; i++) {
        s += array[i].position + ',' + array[i].playerId + ',' + array[i].username + ',' + array[i].tasksCompleted
        if (i < array.length - 1) {
            s += '\n'
        }
    }
    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = Assets.Leaderboard + Assets._for_ + months[Main.Instance.MissionLeaderboardState.month] + ' ' + Main.Instance.MissionLeaderboardState.year + Assets_._csv
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

export function TournamentLeaderboard(): JSX.Element {

    DbRoutes.wsReinitialize()

    if (!Main.Instance.MissionLeaderboardState.filteredData || Main.Instance.DatabaseDataState.missionLeaderboardUpdate) {
        getChancesData()
        Logic.Type.New(Main.Instance.DatabaseDataState, {missionLeaderboardUpdate: false})
    }

    return (
        <div>
            <div style = {{marginBottom: '20px'}} >
            <h3>{Assets.Chances + ' ' + Assets.Leaderboard} 
                <button
                    onClick = {() => downloadCSVList()}
                    title = {Assets.Download + ' ' + Assets_.csv.toUpperCase() + ' ' + Assets.Leaderboard.toLowerCase() + Assets._for_ + months[Main.Instance.MissionLeaderboardState.month] + ' ' + Main.Instance.MissionLeaderboardState.year}
                    key = {'csv'}
                    style = {{/*color: '#6f9c67', */background: 'transparent', border: '0px', fontSize: '25px', padding: '0px'}}
                    className = "btn btn-default btn-sm mr0 fr"
                >
                    <FontAwesomeIcon icon = {faFileCsv}/>
                </button>
                <select
                    disabled = {Main.Instance.DatabaseDataState.missionLeaderboardGet}
                    style = {{width: 'auto', height: '32px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Month}
                    className = "fr dbl mr form-control"
                    value = {Main.Instance.MissionLeaderboardState.month}
                    key = {'month'}
                    onChange = {(e) => {
                        Logic.Type.New(Main.Instance.MissionLeaderboardState, {month: +e.target.value, selectedName: '-1', selectedId: '-1', /*selectedPosition: -1,*/ selectedPage: 0})
                        getDateFromTo(+e.target.value, undefined)
                        getChancesData()
                    }}
                >
                    {getMonthOptions()}
                </select>
                <select
                    disabled = {Main.Instance.DatabaseDataState.missionLeaderboardGet}
                    style = {{width: 'auto', height: '32px', paddingTop: '0px', paddingBottom: '0px'}}
                    title = {Assets.Select + ' ' + Assets.Year}
                    className = "fr dbl mr form-control"
                    value = {Main.Instance.MissionLeaderboardState.year}
                    key = {'year'}
                    onChange = {(e) => {
                        Logic.Type.New(Main.Instance.MissionLeaderboardState, {year: +e.target.value, selectedName: '-1', selectedId: '-1', /*selectedPosition: -1,*/ selectedPage: 0})
                        getDateFromTo(undefined, +e.target.value)
                        getChancesData()
                    }}
                >
                    {getYearOptions()}
                </select>
            </h3>
            </div>

            <div className = "control-panel mb20">
                <FormInputNumber
                    description = {Assets.recordsPerPageDesc}
                    outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                    ClassName = "h26 w60"
                    value = {!Main.Instance.MissionLeaderboardState.pageSize ? '' :
                    Main.Instance.MissionLeaderboardState.pageSize.toString()}
                    type = "number"
                    min = "1"
                    key = {'psize'}
                    icon = {Assets_.faHamburgerClass}
                    onChange = {(text) => RecordsPerPageChanged(text)}
                />
                <button
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveTop}
                    onClick = {() => FirstPage()}
                    key = {'first'}
                >
                    <i className = {Assets_.faFirstButtonClass}/>
                </button>
                <button
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveLeft}
                    onClick = {() => PrevPage()}
                    key = {'prev'}
                >
                    <i className = {Assets_.faPrevButtonClass}/>
                </button>
                <button
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveRight}
                    onClick = {() => NextPage()}
                    key = {'next'}
                >
                    <i className = {Assets_.faNextButtonClass}/>
                </button>
                <button
                    className = "btn btn-primary btn-xs"
                    title = {Assets.ListMoveBottom}
                    onClick = {() => LastPage()}
                    key = {'last'}
                >
                    <i className = {Assets_.faLastButtonClass}/>
                </button>
                <span className = "label label-success table-label">
                    {Assets.records}
                    {Main.Instance.DatabaseDataState.missionLeaderboardCount ? Main.Instance.DatabaseDataState.missionLeaderboardCount : 0}
                </span>
                <span className = "label label-success table-label">
                {Assets.showing}
                    {Main.Instance.DatabaseDataState.missionLeaderboardCount ?
                    Main.Instance.MissionLeaderboardState.selectedPage * Main.Instance.MissionLeaderboardState.pageSize + 1 : 0}{' - '}
                    {Main.Instance.DatabaseDataState.missionLeaderboardCount ?
                    ((Main.Instance.MissionLeaderboardState.selectedPage + 1) *
                    Main.Instance.MissionLeaderboardState.pageSize > Main.Instance.DatabaseDataState.missionLeaderboardCount ?
                    Main.Instance.DatabaseDataState.missionLeaderboardCount :
                    (Main.Instance.MissionLeaderboardState.selectedPage + 1) * Main.Instance.MissionLeaderboardState.pageSize) : 0}
                </span>
                <span className = "label label-success table-label">
                    {Assets.page}
                    {Main.Instance.DatabaseDataState.missionLeaderboardCount ?
                    Main.Instance.MissionLeaderboardState.selectedPage + 1 : 0}{' / '}
                    {Main.Instance.DatabaseDataState.missionLeaderboardCount ? Math.trunc((Main.Instance.DatabaseDataState.missionLeaderboardCount - 1) /
                    Main.Instance.MissionLeaderboardState.pageSize + 1) : 0}
                </span>
            </div>
            <div className = "row admin-users-container mt ">

                {/* console.log(Main.Instance.MissionLeaderboardState.selectedPosition) */}

                <ReactTable
                    data={Main.Instance.MissionLeaderboardState.filteredData}
                    columns={[
                        {width: 70, className: 'btn-nopadding', headerClassName: 'risk-default-light', Header: Assets.Position, accessor: 'position', Cell: (row) => <div /*style = {{background: row.index === Main.Instance.MissionLeaderboardState.selectedPosition ? '#C0D9AF' : ''}}*/>&nbsp;&nbsp;{row.value}</div>},
                        {width: 250, className: 'btn-nopadding', headerClassName: 'risk-default', Header: Assets.PlayerId, accessor: 'playerId', Cell: (row) => <div /*style = {{background: row.index === Main.Instance.MissionLeaderboardState.selectedPosition ? '#C0D9AF' : ''}}*/>&nbsp;&nbsp;{row.value}</div>},
                        {className: 'btn-nopadding', headerClassName: 'risk-default-light', Header: Assets.PlayerUsername, accessor: 'username', Cell: (row) => <div /*style = {{background: row.index === Main.Instance.MissionLeaderboardState.selectedPosition ? '#C0D9AF' : ''}}*/>&nbsp;&nbsp;{row.value}</div>},
                        {width: 150, className: 'btn-nopadding', headerClassName: 'risk-default', Header: Assets.Tasks + ' ' + Assets.Completed, accessor: 'tasksCompleted', Cell: (row) => <div /*style = {{background: row.index === Main.Instance.MissionLeaderboardState.selectedPosition ? '#C0D9AF' : ''}}*/>&nbsp;&nbsp;{row.value}</div>},
                    ]}
                    // defaultPageSize={10}
                    showPaginationBottom={false}
                    // showPaginationTop={true}
                    // getTrProps={(state, rowInfo, column) => {
                    //     return {
                    //         style: {
                    //             background: rowInfo && rowInfo.index && rowInfo.index === Main.Instance.MissionLeaderboardState.selectedPosition ? '#C0D9AF' : ''
                    //         }
                    //     }
                    //   }}
                    pageSize = {Main.Instance.MissionLeaderboardState.pageSize}
                    sortable={false}
                    page={Main.Instance.MissionLeaderboardState.selectedPage}
                    onPageChange={(pageIndex) => {
                        Logic.Type.New(Main.Instance.MissionLeaderboardState, {
                        selectedPage: pageIndex
                        });
                    }}
                    onPageSizeChange={(pageSize, pageIndex) => {
                        if (pageSize !== Main.Instance.MissionLeaderboardState.pageSize) {
                            Logic.Type.New(Main.Instance.MissionLeaderboardState, {pageSize})
                        }
                    }}

                />
            </div>
        </div>
    )

    
}

export function getChancesData() {
    const data = Object.assign([], Main.Instance.DatabaseDataState.missionLeaderboard)
    const FilteredData = []

    for (let i: number = 0; i < data.length; i++) {
        let found: boolean = false

        if (Main.Instance.UsersInfoState.search_string && Main.Instance.UsersInfoState.search_string.length >= Main.Instance.SearchState.minLengthToSearch) {
            const positionUsername: number = data[i].username.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
            const positionId: number = data[i].playerId.toLowerCase().indexOf(Main.Instance.UsersInfoState.search_string.toLowerCase())
            if (Main.Instance.UsersInfoState.username && Main.Instance.UsersInfoState.playerId) {

                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionUsername === 0 || positionId === 0) {
                        found = true

                    }
                } else {
                    if (positionUsername !== -1 || positionId !== -1) {
                        found = true

                    }
                }
            } else if (Main.Instance.UsersInfoState.username) {
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionUsername === 0) {
                        found = true

                    }
                } else {
                    if (positionUsername !== -1) {
                        found = true

                    }
                }
            } else if (Main.Instance.UsersInfoState.playerId) {
                if (Main.Instance.SearchState.searchOnlyAtTheStartOfPhrase) {
                    if (positionId === 0) {
                        found = true

                    }
                } else {
                    if (positionId !== -1) {
                        found = true

                    }
                }
            }

        } else {
            found = true

        }
        if (found) {
            FilteredData.push(data[i])
        }
    }
    Logic.Type.New(Main.Instance.DatabaseDataState, {missionLeaderboardCount: FilteredData.length})
    FilteredData.slice((Main.Instance.MissionLeaderboardState.selectedPage) * Main.Instance.MissionLeaderboardState.pageSize, (Main.Instance.MissionLeaderboardState.selectedPage) * Main.Instance.MissionLeaderboardState.pageSize + Main.Instance.MissionLeaderboardState.pageSize)
    Logic.Type.New(Main.Instance.MissionLeaderboardState, {filteredData: FilteredData})
    
}