import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import {FormSelect, FormInputNumber} from '../../Reusables/Inputs'
// import {RadioButton3, FormInputNumber, FormSelect} from '../../Reusables/Inputs'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {isUserSystem, isUserMaster, isUserSubEntity, isUserGameProvider, isUserOperator, isUserSiteOwner, IsBuildScandibet} from '../../../Logic/Utils'
// import * as Chart from '../../Reusables/Chart'
// import {DateRangePicker} from 'react-dates'
// import * as moment from 'moment'
import * as base64 from 'base-64'
import {Modal} from '../../Reusables/Modal'
import {PlayersSelection, CountPlayersSelected} from './PlayersSelection'
// import {getSelected} from './GamesSelection'

function TogglePS() {
    Logic.Type.New(Main.Instance.Modal, {ModalOperatorSites: !Main.Instance.Modal.ModalOperatorSites})
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            levelsAllStream:undefined})

        DbRoutes.getAllOperators(Assets.GG_RM)
        DbRoutes.getAllBrandOwners(Assets.GG_RM) 
        
        DbRoutes.getExtendedSitesAll(Assets.GG_RM)        

    } else {

        DbRoutes.getAllOperatorsByMaster(text, Assets.GG_RM)
        DbRoutes.getAllBrandOwnersByMaster(text, Assets.GG_RM)

        DbRoutes.getExtendedSitesAllByMaster(text, Assets.GG_RM)
    }
}

function ApplyOperatorSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id:text})
    if (parseInt(text) === 0) {

        Logic.Type.New(Main.Instance.DatabaseDataState,{levelsAllStream:undefined,sitesOperatorAllStream:undefined})

        if (parseInt(Main.Instance.FrontendStoreState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll(Assets.GG_RM)
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.FrontendStoreState.holding_id,
                                                 Assets.GG_RM)
        }        
    }  else {
        DbRoutes.getAllSitesByOperator(text)
    }
}

function ApplySiteSelect(text:string) {
    Logic.Type.New(Main.Instance.FrontendStoreState, {site_id:text})
/*
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {rstStream : undefined})
    } else {
        getRSTransactionsCount()
    }
*/
}


function SelectSite(stream:any[]) {

    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {
            operator_id: Main.Instance.LoginState.entityId,
            site_id: stream[0]['id'],
        })
        DbRoutes.loadData()
        // FirstPage()
    }
}

function GiveCoins() {
    DbRoutes.GiveCoins (Main.Instance.RewardShopState.category_idx)
}

function RemoveCoins() {
    DbRoutes.RemoveCoins (Main.Instance.RewardShopState.category_idx)
}

function CategoryIdxChanged(text: string) {
    if (parseInt(text) >= 0) { Logic.Type.New(Main.Instance.RewardShopState, {category_idx: +text}) }
}

export function RewardShopGiveCoins() {

    if (IsBuildScandibet() && Main.Instance.FrontendStoreState.custom !== undefined 
        && Main.Instance.FrontendStoreState.custom.coinsGivenOnRegistration !== undefined && 
        Main.Instance.RewardShopState.coinsGivenOnRegistration !== Main.Instance.FrontendStoreState.custom.coinsGivenOnRegistration) {
        Logic.Type.New (Main.Instance.RewardShopState, {coinsGivenOnRegistration: Main.Instance.FrontendStoreState.custom.coinsGivenOnRegistration})
    }

    if (IsBuildScandibet() && Main.Instance.RewardShopState.category_idx === -1) { Logic.Type.New(Main.Instance.RewardShopState, {category_idx: 0}) }
    if (IsBuildScandibet() && Main.Instance.RSTState.filter_date2 === '0') { Logic.Type.New(Main.Instance.RSTState, {filter_date2: ''}) }
    if (Main.Instance.FrontendStoreState.first_time) {
        Logic.Type.New (Main.Instance.FrontendStoreState, {first_time: false})
        if (isUserGameProvider()) {
            DbRoutes.getContractualPartnerMaster (Main.Instance.LoginState.entityId, true)
        } else if (isUserSiteOwner() || isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId),
                                            SelectSite)            
        } /*else if (isUserOperator()) {
            DbRoutes.getSubEntityByTypeAll (Assets_.OPERATOR, 
                                            parseInt(Main.Instance.LoginState.entityId))            
        }*/
    }
    
    const Selected: number = CountPlayersSelected()

    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
    } else if ( Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
                Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ
    }

    return (<div>
    {<Modal isOpen = {Main.Instance.Modal.ModalOperatorSites} close = {() => TogglePS()}>{PlayersSelection()}</Modal>}
    <div className = "clearfix" style = {{position: 'relative'}}>

    <h3 className = "level fl" /*style = {{width: '50%'}}*/>
        {Assets.RewardShopTransferCoins}
    </h3>

    {isUserSystem() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.holding_id ? '0' : 
                Main.Instance.FrontendStoreState.holding_id}
        data = {Main.Instance.DatabaseDataState.masterAllStream}
        ClassName = "form-control"

        o1 = {Assets.SelectMaster}
        o2 = {Assets.MasterQ}
        onChange = {(text) => ApplyMasterSelect(text)}
        icon = {Assets_.faMasterClass}
    /> : null}

    {isUserSystem() || isUserMaster() || isUserGameProvider() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.operator_id ? '0' :
                Main.Instance.FrontendStoreState.operator_id}
        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
        data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
        label1 = {Assets.OP}
        label2 = {Assets.BO}
        
        ClassName = "form-control"

        o1 = {selectOperator}
        o2 = {operatorQ}
        onChange = {(text) => ApplyOperatorSelect(text)}
        icon = {Assets_.faCompanyClass}
    />
    : null
    }

    {!isUserSubEntity() ?
    <FormSelect
        level = {true}
        value = {!Main.Instance.FrontendStoreState.site_id ? '0' :
                Main.Instance.FrontendStoreState.site_id}
        data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
        ClassName = "form-control"

        o1 = {selectSite}
        o2 = {siteQ}
        onChange = {(text) => ApplySiteSelect(text)}
        icon = {Assets_.faSubEntityClass}
    />
    : null
    }

    </div>
    <div className = "clearfix"/>
        <div className = "clearfix">
            <div style = {{float: 'left', marginRight: '10px'}}>
                <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[0]}</div>
                <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[1]}</div>
            </div>

            <input
                accept = "text/csv, text/plain" 
                id = "players--csv" 
                className = "UploadShopItemWrapper" 
                type = "file" 
                style = {{float: 'left', marginRight: '10px'}}
                onChange = {(event) => { 
                    const cloneFile: File = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        const decodedData: string = base64.decode((reader.result as string).replace('data:text/csv;base64,',''))
                        const data:string[] = decodedData.split('\n')
                        const playersStreamSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.playersStreamSelected)
                        for (let i: number = 0; i < playersStreamSelected.length; i++) {
                            playersStreamSelected[i] = false
                            for (const Data of data) { if (Data.trim().length > 0 && +Main.Instance.DatabaseDataState.playersStream[i]['player'] === parseInt(Data.trim())) {
                                playersStreamSelected[i] = true
                                break
                            }
                            }
                        }
                        
                        Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
                    }
                    reader.readAsDataURL(cloneFile);
                }}
                onClick = {(event) => {event.target = null}}
            />

            {Main.Instance.DatabaseDataState.playersStream ?
            <button
                className = "btn btn-default btn-sm fl mr0"
                style = {{display: 'block'}}
                onClick = {() => TogglePS()}
            >
                {Assets.PlayersSelection}
            </button> : void 0}

            {Main.Instance.DatabaseDataState.playersStream ?
            <span
                className="label label-success table-label2 mt2"
                style = {{background: '#5cb85c', display: 'block'}}
            >
                {Assets.selected}
                {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined  ?
                Selected : 0}
            </span> : void 0}

            {Main.Instance.DatabaseDataState.playersStream ?
            <span
                className = "label label-success table-label2 mt2"
                style = {{background:'#5cb85c', display: 'block'}}
            >
                {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined ?
                (100 * Selected /
                Main.Instance.DatabaseDataState.playersStream.length).toFixed(3) : 0.0}
                %
            </span> : void 0}

            {Main.Instance.DatabaseDataState.playersStream ?
            <span
                className = "label label-success table-label2 mt2"
                style = {{float: 'left', display: 'block'}}
            >
                {'players: '}
                {Main.Instance.DatabaseDataState.playersStream.length ?
                Main.Instance.DatabaseDataState.playersStream.length : 0}
            </span> : void 0}

            <button title = {Assets.GiveCoinsToTheSelectedPlayers_} onClick = {() => GiveCoins()} className = "btn btn-warning btn-sm ml">{Assets.GiveCoins}</button>
            <button title = {Assets.RemoveCoinsFromTheSelectedPlayers_} onClick = {() => RemoveCoins()} className = "btn btn-danger btn-sm mr0">{Assets.RemoveCoins}</button>

            <FormInputNumber 
                description = {Assets.AmountOfCoinsToGiveToSelectedPlayers_}
                outerDiv = "rows-per-table input-group fl mr0 ml pl0"
                ClassName = "h30 w60"
                value = {!Main.Instance.RewardShopState.category_idx ? '0' : Main.Instance.RewardShopState.category_idx.toString()}
                type = "number"
                min = "0"
                iconText = {Assets.Coins}
                iconTextS = "14px"
                noIconTextFix = {true}
                onChange = {(text) => CategoryIdxChanged(text)}
            />
        </div>
        <div>
        <div className = "mt bold">{Assets.DescribeTheReasonForGivingOut_RemovingCoins}...</div>
        <textarea
            style = {{height: '50px', paddingLeft: '5px', paddingRight: '5px', width: '100%'}}
            value = {Main.Instance.RSTState.filter_date2}
            onChange = {(e) => Logic.Type.New (Main.Instance.RSTState, {filter_date2: e.target.value})}
        />
        <div className = "clearfix">
            <FormInputNumber 
                description = {Assets.AmountOfCoinsToGiveOnRegistration}
                outerDiv = "rows-per-table input-group fl mr0 pl0"
                ClassName = "h30 w60"
                value = {!Main.Instance.RewardShopState.coinsGivenOnRegistration ? '0' : Main.Instance.RewardShopState.coinsGivenOnRegistration.toString()}
                type = "number"
                min = "0"
                iconText = {Assets.CoinsGivenOnRegistration}
                iconTextS = "14px"
                noIconTextFix = {true}
                onChange = {(text) => CoinsOnRegistrationChanged(text)}
            />
            
        </div>
        </div>
    </div>)
}

function CoinsOnRegistrationChanged(text: string) {
    if (+text >= 0) {
        Logic.Type.New (Main.Instance.RewardShopState, {coinsGivenOnRegistration: +text})
        DbRoutes.CoinsOnRegistrationChanged(+text)
    }
}