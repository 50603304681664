import * as React from 'react'
import * as Assets from '../../Assets'
import { Main } from '../../../Logic/Main';
import {Custom} from '../../../Logic/Database/DatabaseData'
import { Logic } from '../../../Logic/Type';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faPlus} from '@fortawesome/free-solid-svg-icons'


export function PlayerTags(): JSX.Element {
    return (
        <div>
            <h2>{Assets.PlayerTags}</h2>
            <br/>
            <div className = "clearfix mt5 mb">
                <div className = "fl mt2 mr">{Assets.AddNewTag}</div>
                <AddNewTag/>
            </div>
            <br/>
            <div className = "tags-alphabet-groups">
                {getListOfTags()}
            </div>
        </div>
    )
}

export function getListOfTags(): JSX.Element[] {
    const tags: string[] = Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.playerTags? Main.Instance.DatabaseDataState.custom.playerTags: []
    const alphabetGroups: JSX.Element[] = []
    const group: JSX.Element[] = []
    let char: string = 'a'
    let lastIndex: number = 0
    for (let index = 0; index < tags.length; index++) {
        const tag: string = tags[index]
        if (tag.charAt(0) === char) {
            group.push(htmlTag(tag, index))
        } else {
            if (group.length > 0) {
                alphabetGroups.push (
                    <div className = "alphabet-group">
                        <div className = "alphabet-char">{char.toUpperCase()}</div>
                        <ul className = "clearfix player-tags-list">
                            {group.slice(lastIndex, index)}
                        </ul>
                    </div>,
                )
                
                
            }
            lastIndex = index
            char = tag.charAt(0)
            group.push(htmlTag(tag, index))
        }
        if (index === tags.length - 1 && group.length > 0) {
            alphabetGroups.push (
                <div className = "alphabet-group">
                    <div className = "alphabet-char">{char.toUpperCase()}</div>
                    <ul className = "clearfix player-tags-list">
                        {group.slice(lastIndex, index + 1)}
                    </ul>
                </div>,
            )
        }

    }

    return alphabetGroups
}
function htmlTag(tag: string, index: number): JSX.Element {
    return(
        <li key = {tag}>
            <button 
                className = "btn btn-xs btn-default mr5px"
                onClick = {() => deleteTag(tag, index)}
            >
                {tag}
                {<FontAwesomeIcon icon = {faTimes} style = {{fontSize: '0.8em', marginLeft: '5px'}}/>}
            </button>
        </li>
    )
}

function deleteTag(item: string, index: number) {
    if (confirm(Assets.sureToDelete + ' ' + Assets.playerTag + ' ' + ' "' + (item) + '"?')) {    
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        custom.playerTags.splice(index, 1)
        Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
    }
}

class AddNewTag extends React.Component<{}, {newTag: string}> {
    constructor(props) {
        super(props)
        this.state = {
            newTag: '',
        }
    }
    public addTag() {
        const custom: Custom = Object.assign ({}, Main.Instance.DatabaseDataState.custom)
        if (custom.playerTags.indexOf(this.state.newTag) === -1) {
            custom.playerTags.push(this.state.newTag)
            custom.playerTags.sort()
            Logic.Type.New(Main.Instance.DatabaseDataState, {custom})
        }
        this.setState({newTag: ''})
    }
    public render() {
        let matching: boolean = false
        if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.playerTags) {
            matching = Main.Instance.DatabaseDataState.custom.playerTags.indexOf(this.state.newTag) !== -1
        }
        return (
            <>
                <input
                    className = "input-popup pr0 mr5px"
                    style = {matching? {borderColor: 'red', backgroundColor: 'rgba(255, 0, 0, 0.2)'}: {}}
                    onChange = {(e) => this.setState({newTag: e.target.value})}
                    type = "text"
                    value = {this.state.newTag}
                />
                {/* <button
                    disabled = {matching}
                    className = "btn btn-xs btn-basic"
                    onClick = {() => this.state.newTag.length > 0? this.addTag(): ''}
                >
                    {Assets.Add}
                </button> */}
                <button
                    disabled = {matching}
                    type = "button"
                    className = "btn btn-primary btn-xs mr0"
                    onClick = {() => this.state.newTag.length > 0? this.addTag(): ''}
                >
                    <FontAwesomeIcon icon = {faPlus}/>
                </button>
            </>
        )
    }
}