import WS from './Socket'
// import {adminModuleServer, environment, Environment, amMychanceStage, amMychanceProduction, environmentGameStoreServer, environmentGameStoreServerWss} from '../Logic/Config'
import {/*IsBuildOdds, */IsBuildDefault, IsBuildMyChance, IsBuildJackpotsCH, IsBuildStanleyBet, IsBuildOddyzzey, IsBuildBackend, IsBuildPasino} from '../Logic/Utils'
import * as Assets from '../Views/Assets'
import * as Config from '../Logic/Config'

function getServerNotLocalhost(server: string): string {
    const host: string = window.location.host
    if(host.startsWith('localhost')) {
        return server
    } else {
        const serverDot = server.indexOf('.')
        const subdomain = server.substring(0, serverDot)
        const hostDot = host.indexOf('.')
        const domain = host.substring(hostDot + 1, host.length)
        return subdomain + '.' + domain
    }
}

function getWebsocketConnectionObject(server: string) : {host:string, port?: number, wss: boolean} {
    let wco: {host:string, port?: number, wss: boolean} = {host:'', wss: true}
    if (server.startsWith('localhost')) {
        wco = {
            host: server.split(':')[0],
            port: parseInt(server.split(':')[1]),
            wss: false,
        }
    } else {
        wco = {
            host: getServerNotLocalhost(server),
            wss: true,
        }
    }
    return wco
}

// function isWss(): boolean {return Config.environment < Config.Environment.PET ? true : false}

function isWss(Server: string): boolean {
    // return true
    // console.log(Config.adminModuleServer)
    return (Config.adminModuleServer.indexOf('ngrok') === -1 && Config.adminModuleServer.indexOf('localhost') === -1 && Server !== '35.246.191.76')
}
/*
const stagingServer: string = IsBuildStanleyBet() ? 'stb.gamingenius.net' : IsBuildOddyzzey() ? 'oddyzzey-stage.gamingenius.net' : 
                              IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'gamification-demo.gamingenius.net'
const productionServer: string = 'oddyzzey.gamingenius.net'
const Server: string = Config.STAGE ? stagingServer : productionServer
console.log (new Date().toISOString())
console.log ('token: ' + Main.Instance.LoginState.token)
console.log ('Server: ' + Server)
// console.log ('isWss: ' + isWss())

let Socket1: WS
if (IsBuildOddyzzey() || Config.Build === Config.BUILD.BACKEND || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildStanleyBet()) {
    if (IsBuildStanleyBet()) {
        Socket1 = new WS({host: 'stb.gamingenius.net', wss: true})
    } else if (IsBuildJackpotsCH() || IsBuildPasino()) {
        Socket1 = new WS({host: Server + '?token=' + (Main.Instance.LoginState.token ? Main.Instance.LoginState.token : 'nema'), wss: isWss()})
    } else {
        Socket1 = new WS(getWebsocketConnectionObject(Server))
    }
}
*/
// let Socket1: WS
class Empty {
    public socket: WS
    constructor() {
        // this.reConnect();
    }
    public reConnect(token: string) {
        const stagingServer: string = IsBuildStanleyBet() ? 'stb.gamingenius.net' : IsBuildOddyzzey() ? 'oddyzzey-stage.gamingenius.net' : 
        IsBuildMyChance() ? /*'34.89.53.235'*/ Config.amMychanceStage : IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'gamification-demo.gamingenius.net'
        const productionServer: string = IsBuildDefault() ? Config.environmentGameStoreServer : IsBuildMyChance() ? Config.amMychanceProduction : IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'oddyzzey.gamingenius.net'
        const Server: string = Config.environment === Config.Environment.STAGE ? stagingServer : productionServer

        if (/* IsBuildDefault() ||*/ IsBuildOddyzzey() || IsBuildBackend() || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildStanleyBet() || IsBuildMyChance()) {
/*
            if (IsBuildOdds()) {
                this.socket = new WS({host: Config.oddsServer + '?token=' + (token ? token : Assets.Unknown), wss: Config.oddsServerWss})
            } else
*/
            if (IsBuildStanleyBet()) {
                this.socket = new WS({host: 'stb.gamingenius.net', wss: true})
            } else if (IsBuildJackpotsCH() || IsBuildPasino() || IsBuildMyChance() || IsBuildDefault()) {
                if (IsBuildDefault()) {
                    this.socket = new WS({host: Server + '?token=' + (token ? token : Assets.Unknown), wss: Config.environmentGameStoreServerWss})
                } else if (IsBuildJackpotsCH() || IsBuildPasino() || IsBuildMyChance()) {
                    // console.log (isWss(Server))
                    this.socket = new WS({host: Server + '?token=' + (token ? token : Assets.Unknown), wss: isWss(Server)/*, port: 8080*/}) // BEWARE
                }
            } else {
                this.socket = new WS(getWebsocketConnectionObject(Server))
            }
        }

    }
}
const Socket1 = new Empty();
/*+ '?token=' + (token ? token : 'nema')*/
/*

if (Main.Instance.LoginState && Main.Instance.LoginState.token) {
    const stagingServer: string = IsBuildStanleyBet() ? 'stb.gamingenius.net' : IsBuildOddyzzey() ? 'oddyzzey-stage.gamingenius.net' : 
        IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'gamification-demo.gamingenius.net'
    const productionServer: string = 'oddyzzey.gamingenius.net'
    const Server: string = Config.STAGE ? stagingServer : productionServer
    console.log (new Date().toISOString())
    console.log ('token: ' + Main.Instance.LoginState.token)
    console.log ('Server: ' + Server)
    // console.log ('isWss: ' + isWss())

    if (IsBuildOddyzzey() || Config.Build === Config.BUILD.BACKEND || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildStanleyBet()) {
    if (IsBuildStanleyBet()) {
        Socket1 = new WS({host: 'stb.gamingenius.net', wss: true})
    } else if (IsBuildJackpotsCH() || IsBuildPasino()) {
        Socket1 = new WS({host: Server, wss: isWss()})
        } else {
        Socket1 = new WS(getWebsocketConnectionObject(Server))
        }
    }
    console.log (Socket1)
}



export function reConnect(token: string) {
    // console.log ('here')
    const stagingServer: string = IsBuildStanleyBet() ? 'stb.gamingenius.net' : IsBuildOddyzzey() ? 'oddyzzey-stage.gamingenius.net' : 
        IsBuildJackpotsCH() || IsBuildPasino() ? Config.adminModuleServer : 'gamification-demo.gamingenius.net'
    const productionServer: string = 'oddyzzey.gamingenius.net'
    const Server: string = Config.STAGE ? stagingServer : productionServer
    console.log (new Date().toISOString())
    console.log ('token: ' + token)
    console.log ('Server: ' + Server)
    // console.log ('isWss: ' + isWss())

    if (IsBuildOddyzzey() || Config.Build === Config.BUILD.BACKEND || IsBuildJackpotsCH() || IsBuildPasino() || IsBuildStanleyBet()) {
    if (IsBuildStanleyBet()) {
        Socket1 = new WS({host: 'stb.gamingenius.net', wss: true})
    } else if (IsBuildJackpotsCH() || IsBuildPasino()) {
        Socket1 = new WS({host: Server, wss: isWss()})
        } else {
        Socket1 = new WS(getWebsocketConnectionObject(Server))
        }
    }
    console.log (Socket1)
}
*/
/*+ '?token=' + (token ? token : 'nema')*/

export default Socket1
