import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {Logic} from '../../../Logic/Type'
import {/*FormInputNumber,*/ FormSelect} from '../../Reusables/Inputs'
import * as Assets from '../../Assets'
import * as Chart from '../../Reusables/Chart'
import * as Assets_ from '../../Assets_'
import {isUserOperator, isUserSiteOwner} from '../../../Logic/Utils'

function SelectSite(stream: any[]) {
    if (stream.length === 1) {
        Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id: Main.Instance.LoginState.entityId, site_id: stream[0]['id']})
        DbRoutes.getPlayersAllTEST2(0)
        DbRoutes.loadData()
    }
}

function getReportLevelsPlayersCount() {

    if (Main.Instance.ReportLSState.countCalled === false) {
        Logic.Type.New (Main.Instance.ReportLSState, {countCalled: true})
        Logic.Type.New(Main.Instance.FrontendStoreState, {operator_id: Main.Instance.LoginState.entityId})
        
        if (isUserOperator() || isUserSiteOwner()) {        
            DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR, parseInt(Main.Instance.LoginState.entityId), SelectSite)
        }
    }
}
/*
function SublevelMinChanged(text: string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= Main.Instance.ReportLSState.Sublevels) {
        Logic.Type.New(Main.Instance.ReportLSState, {sublevel_min: parseInt(text)})
        getReportLevelsPlayersCount()
    }
}
    
function SublevelMaxChanged(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= Main.Instance.ReportLSState.Sublevels) {
        Logic.Type.New(Main.Instance.ReportLSState, {sublevel_max: parseInt(text)})
        getReportLevelsPlayersCount()
    }
}
*/
function ApplyFilter(text:string) {
    let sublevels:number = 0
    let sublevel_min:number = Main.Instance.ReportLSState.sublevel_min
    let sublevel_max:number = Main.Instance.ReportLSState.sublevel_max
    
    if (Main.Instance.ReportLSState.sublevels.length > 0) {
        if (Main.Instance.ReportLSState.sublevels[parseInt(text) - 1]) {
            sublevels = Main.Instance.ReportLSState.sublevels[parseInt(text) - 1]
        }
    }

    if (sublevel_min > sublevels) { sublevel_min = sublevels }
    if (sublevel_max > sublevels) { sublevel_max = sublevels }
    
    Logic.Type.New (Main.Instance.ReportLSState, {
        filter_level: text, 
        Sublevels: sublevels,
        sublevel_min,
        sublevel_max,
    })
    getReportLevelsPlayersCount()
}

function TogglePlayerView(i: number) {
    const show: boolean[] = Object.assign([], Main.Instance.ReportLSState.show)
    show[i] = !show[i]
    Logic.Type.New (Main.Instance.ReportLSState, {show})
}

function TogglePlayerView_(i: number) {
    const data: any[] = Object.assign([], Main.Instance.ReportLSState.data)
    data[i]['show'] = !data[i]['show']
    Logic.Type.New (Main.Instance.ReportLSState, {data})
}

export function LevelsSublevels() {

    const report_by_level: JSX.Element[] = []

    if (Main.Instance.ReportLSState.data &&
        Main.Instance.ReportLSState.data.length > 0 && 
        Main.Instance.ReportLSState.count > 0) {
        // console.log (JSON.stringify(Main.Instance.ReportLSState.data))
        let points: number = 0
        let cumul: number = 0
        if (parseInt(Main.Instance.ReportLSState.filter_level) === 0) {
            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportLSState.data.length + 3).toString()}
                    style = {{width: '100%', fontWeight: 600, fontSize: '14px'}}
                    className = "clearfix"
                >
                    <span className = "fl dbl w20px"><i className = {Assets_.faEyeClass}/></span>
                    <span className = "fl dbl w50px">{Assets.Level}</span>
                    <span className = "fl dbl w100px">{Assets.LevelName}</span>
                    <span className = "fl dbl w50px">{Assets.NoShort}</span>
                    <span className = "fl dbl w80px">{Assets.TotalP}</span>
                    <span title = {Assets.Cumulative} className = "fl dbl w60px">{Assets.CumulativeShort}</span>
                    <span title = {Assets.CumulativeP} className = "fl dbl w60px">{Assets.CumulativePShort}</span>
                    <span title = {Assets.TPM} className = "fl dbl w108px">
                        {Assets.TPMean}
                    </span>
                    <span title = {Assets.SLM} className = "fl dbl w108px">
                        {Assets.SLMean}
                    </span>
                    <span title = "Export" className = "fl dbl w60px">Export</span>
                </p>,
            )
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportLSState.data.length + 2).toString()}
                />)

            // let offset:number = 0

            for (let i: number = 0; i < Main.Instance.ReportLSState.data.length; i++) {
                if (Main.Instance.ReportLSState.data[i].data === 0) { continue }
                cumul += Main.Instance.ReportLSState.data[i].data
                points += Main.Instance.ReportLSState.data[i].avgTP
                report_by_level.push(
                    <p
                        key = {i.toString()}
                        style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}
                        className = "clearfix"
                    >
                        <button style = {{background: 'transparent', padding: '0px', border: '0px', margin: '1px 3px 0px -3px'}} className = "fl dbl w20px" onClick = {() => TogglePlayerView_(i)}>
                            <i 
                                style = {Main.Instance.ReportLSState.data[i].show ?
                                    {border: '2px solid #5cb85c', fontSize: '14px', background: '#5cb85c', color: '#fff'} : 
                                    {border: '2px solid #d9534f', fontSize: '14px', background: '#d9534f', color: '#fff'}
                                }
                                className = {Main.Instance.ReportLSState.data[i].show ? Assets_.faMinusSquareClass : Assets_.faPlusSquareClass}
                            />
                        </button>                    
                        <span className = "fl dbl w50px">{Main.Instance.ReportLSState.data[i].level}</span>
                        <span
                            style = {{
                                textAlign:'center',
                                fontWeight: 600,
                                marginRight: '10px',
                                background: Main.Instance.ReportLSState.colors[i],
                                color: Main.Instance.ReportLSState.Colors[i],
                            }}

                            className = "fl dbl w90px"
                        >
                            {Main.Instance.ReportLSState.data[i].name}
                        </span>
                        <span className = "fl dbl w50px">{Main.Instance.ReportLSState.data[i].data}</span>
                        <span className = "fl dbl w80px">
                        {(Main.Instance.ReportLSState.data[i].data / Main.Instance.ReportLSState.count * 100).toFixed(2)}%
                        </span>
                        <span className = "fl dbl w60px">{cumul}</span>
                        <span className = "fl dbl w60px">
                        {(cumul / Main.Instance.ReportLSState.count * 100).toFixed(2)}%
                        </span>
                        <span className = "fl dbl w108px">{Main.Instance.ReportLSState.data[i].avgTP}</span>
                        <span 
                            className = "fl dbl w108px"
                            style = {Main.Instance.ReportLSState.data[i].avgSL > 0 ? {} : {color:'#bbb'}}    
                        >
                            {Main.Instance.ReportLSState.data[i].avgSL > 0 ?
                            Main.Instance.ReportLSState.data[i].avgSL : Assets_.NA}
                        </span>
                        <button onClick = {() => ExportCSV0(Main.Instance.ReportLSState.data[i])} className = "btn btn-warning btn-xs">{Assets_.CSV}</button>
                    </p>,
                )

                if (Main.Instance.ReportLSState.data[i].show) {
                    let players: string = ''
                    if (Main.Instance.ReportLSState.data[i].players) {
                    for (const item of Main.Instance.ReportLSState.data[i].players) {
                        if (players.length > 0) { players += ', ' }
                        players += item
                    }
                    }

                    report_by_level.push(<p key = {i.toString() + '_'} style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}>{players}</p>)
                }                
            }
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportLSState.data.length).toString()}
                />)

            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportLSState.data.length + 1).toString()}
                    style = {{width:'100%', fontWeight:400, fontSize:'14px'}}
                >
                    <span className = "fl dbl w20px">&nbsp;</span>
                    <span className = "fl dbl w150px">{Assets.Total + ': '}</span>
                    <span className = "fl dbl w50px">{Main.Instance.ReportLSState.count}</span>
                    <span className = "fl dbl w80px">100.00%</span>
                    <span className = "fl dbl w60px">{Main.Instance.ReportLSState.count}</span>
                    <span className = "fl dbl w60px">100.00%</span>
                    <span className = "fl dbl w108px">{Math.round(points / Main.Instance.ReportLSState.count)}</span>
                    <div className = "clearfix"/>
                </p>,
            )
        } else {
            report_by_level.push(
                <p
                    className = "clearfix"
                    key = {(Main.Instance.ReportLSState.data.length + 3).toString()}
                    style = {{width: '100%', fontWeight: 600, fontSize: '14px'}}
                >
                    <span className = "fl dbl w20px"><i className = {Assets_.faEyeClass}/></span>
                    <span className = "fl dbl w150px">{Assets.SubLevelName}</span>
                    <span className = "fl dbl w50px">{Assets.NoShort}</span>
                    <span className = "fl dbl w80px">{Assets.TotalP}</span>
                    <span title = {Assets.Cumulative} className = "fl dbl w60px">{Assets.CumulativeShort}</span>
                    <span title = {Assets.CumulativeP} className = "fl dbl w60px">{Assets.CumulativePShort}</span>
                    <span title = {Assets.Export} className = "fl dbl w60px">{Assets.Export}</span>
                </p>,
            )
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportLSState.data.length + 2).toString()}
                />)

            let total:number = 0
            for (let i:number = 0; i < Main.Instance.ReportLSState.data.length; i++) {
                total += Main.Instance.ReportLSState.data[i].N
            }
            if (total > 0) {
            for (let i:number = 0; i < Main.Instance.ReportLSState.data.length; i++) {
                if (Main.Instance.ReportLSState.data[i].N === 0) { continue }
                cumul += Main.Instance.ReportLSState.data[i].N
                // points += Main.Instance.ReportLSState.data[i].avgTP

                report_by_level.push(
                    <p
                        key = {i.toString()}
                        style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}
                        className = "clearfix"
                    >
                        <button style = {{background: 'transparent', padding: '0px', border: '0px', margin: '1px 3px 0px -3px'}} className = "fl dbl w20px" onClick = {() => TogglePlayerView(i)}>
                            <i 
                                style = {Main.Instance.ReportLSState.show[i] ?
                                    {border: '2px solid #5cb85c', fontSize: '14px', background: '#5cb85c', color: '#fff'} : 
                                    {border: '2px solid #d9534f', fontSize: '14px', background: '#d9534f', color: '#fff'}
                                }
                                className = {Main.Instance.ReportLSState.show[i] ? Assets_.faMinusSquareClass : Assets_.faPlusSquareClass}
                            />
                        </button>
                        <span
                            style = {{
                                textAlign:'center',
                                fontWeight: 600,
                                marginRight: '10px',
                                background: '#AF601A',
                                color: '#fff',
                            }}

                            className = "fl dbl w140px"
                        >
                    {!Main.Instance.ReportLSState.finalLevel[parseInt(Main.Instance.ReportLSState.filter_level) - 1] ?
                    Main.Instance.ReportLSState.levelNames[parseInt(Main.Instance.ReportLSState.filter_level) - 1] + 
                    ' ' + Main.Instance.ReportLSState.data[i].name :
                    Main.Instance.ReportLSState.levelNames[parseInt(Main.Instance.ReportLSState.filter_level) - 1]}
                        </span>
                        <span className = "fl dbl w50px">{Main.Instance.ReportLSState.data[i].N}</span>
                        <span className = "fl dbl w80px">
                            {(Main.Instance.ReportLSState.data[i].N / total * 100).toFixed(2) + '%'}
                        </span>
                        <span className = "fl dbl w60px">{cumul}</span>
                        <span className = "fl dbl w60px">
                            {(cumul / total * 100).toFixed(2) + ' %'}
                        </span>
                        <button onClick = {() => ExportCSV(Main.Instance.ReportLSState.data[i])} className = "btn btn-warning btn-xs">{Assets_.CSV}</button>
                    </p>,
                )

                if (Main.Instance.ReportLSState.show[i]) {
                    let players: string = ''
                    if (Main.Instance.ReportLSState.data[i].players) {
                    for (const item of Main.Instance.ReportLSState.data[i].players) {
                        if (players.length > 0) { players += ', ' }
                        players += item
                    }
                    }

                    report_by_level.push(<p key = {i.toString() + '_'} style = {{width: '100%', fontWeight: 400, fontSize: '14px'}}>{players}</p>)
                }
            }
            }
            report_by_level.push(
                <hr 
                    style = {{marginTop: '10px', marginBottom: '10px'}}
                    key = {(Main.Instance.ReportLSState.data.length).toString()}
                />)

            report_by_level.push(
                <p
                    key = {(Main.Instance.ReportLSState.data.length + 1).toString()}
                    style = {{width:'100%', fontWeight: 400, fontSize:'14px'}}
                >
                    <span className = "fl dbl w20px">&nbsp;</span>
                    <span className = "fl dbl w150px">{Assets.Total + ': '}</span>
                    <span className = "fl dbl w50px">{total}</span>
                    <span className = "fl dbl w80px">100.00%</span>
                    <span className = "fl dbl w60px">{total}</span>
                    <span className = "fl dbl w60px">100.00%</span>
                    <div className = "clearfix"/>
                </p>,
            )            
        }
    }

    let nodata: boolean = true
    if (Main.Instance.ReportLSState.data) {
    for (const item of Main.Instance.ReportLSState.data) {
        if (item.data && item.data > 0) { nodata = false }
        if (item.N && item.N > 0) { nodata = false }        
    }
    }

    // console.log (Main.Instance.ReportLSState.data)
    return (
        <div>
            <span>{Assets.SelectLevel}</span>
            <FormSelect 
                span = {true}
                verynarrow = {true}
                value = {!Main.Instance.ReportLSState.filter_level ? '0' : 
                        Main.Instance.ReportLSState.filter_level}
                data = {Main.Instance.DatabaseDataState.levelsAllDetailsStream}
                ClassName = "control-panel-select-narrow mr"
                o1 = {Assets.AnyLevel} 
                o2 = {Assets.AnyLevel}
                onChange = {(text) => ApplyFilter(text)}
            />

            {/*parseInt(Main.Instance.ReportLSState.filter_level) > 0 && Main.Instance.ReportLSState.Sublevels > 0 ?
            <>
            <span style = {{marginRight:'10px'}}>{Assets.SelectSubLevel}</span>
            <FormInputNumber
                description = {Assets.subLevelMinDesc}
                ClassName = "rows-per-table mr"
                value = {Main.Instance.ReportLSState.sublevel_min === undefined ? '' : 
                Main.Instance.ReportLSState.sublevel_min.toString()}  
                type = "number" 
                min = "0"
                onChange = {text => SublevelMinChanged(text)}
            />
            <span>&ndash;</span>
            <FormInputNumber
                description = {Assets.subLevelMaxDesc}
                ClassName = "rows-per-table ml"
                value = {Main.Instance.ReportLSState.sublevel_max === undefined ? '' :
                Main.Instance.ReportLSState.sublevel_max.toString()}  
                type = "number" 
                min = "0"
                onChange = {text => SublevelMaxChanged(text)}
            />

            : void 0*/}

            {parseInt(Main.Instance.ReportLSState.filter_level) > 0 && Main.Instance.ReportLSState.Sublevels === 0 ?
            <span
                style = {{fontSize:'14px'}}
                className = "label label-primary"
            >
                {Assets.finalLevel.toUpperCase()}
            </span>
            : void 0}

            {parseInt(Main.Instance.ReportLSState.filter_level) > 0 && Main.Instance.ReportLSState.data
            && (Main.Instance.ReportLSState.data.length === 0 || nodata) ?
            <span
                style = {{float:'right', display:'block', fontSize:'16px'}}
                className = "label label-danger"
            >
                {Assets.NoData}
            </span>
            : void 0}

            {Main.Instance.ReportLSState.data ?
            <div>
            <div style = {{width:'100%'}}>
            {Main.Instance.ReportLSState.count > 0 ?
            parseInt(Main.Instance.ReportLSState.filter_level) === 0 ?
            <div
                style = {{width: '38%', textAlign: 'center', fontWeight: 500, fontSize: '18px', marginTop: '40px'}}
            >
                {Assets.PlayerDistributionByLevelChart}
            </div>
            :
            (Main.Instance.ReportLSState.data.length > 0 && !nodata) && 
            !Main.Instance.ReportLSState.finalLevel[parseInt(Main.Instance.ReportLSState.filter_level) - 1] ?
            <div
                style = {{width: '58%', textAlign: 'center', fontWeight: 500, fontSize: '18px', marginTop: '40px', marginLeft: '-2%'}}
            >
                {Assets.PlayerDistributionBySubLevelChart}
            </div>
            : void 0
            : void 0}

            {Main.Instance.ReportLSState.count > 0 ?
            parseInt(Main.Instance.ReportLSState.filter_level) === 0 ?
            <div 
                style = {{float: 'left', width: '38%', textAlign: 'center', marginRight: '2%'}}
            >
            {Main.Instance.LoginState && Main.Instance.ReportLSState.data && Main.Instance.ReportLSState.data.length > 0 ?
                <Chart.SimplePieChartPlayersLevels
                    data = {Main.Instance.ReportLSState.data}
                    entity = {Main.Instance.LoginState.entity}
                    colors = {Main.Instance.ReportLSState.colors}
                /> : void 0}
            </div>
            : 
            <div
                style = {{float: 'left', width: '55%', textAlign: 'center', marginRight: '2%', marginLeft: '-2%'}}
            >
            {Main.Instance.ReportLSState.data && 
            (Main.Instance.ReportLSState.data.length > 0 && !nodata) &&
            !Main.Instance.ReportLSState.finalLevel[parseInt(Main.Instance.ReportLSState.filter_level) - 1] ?
                <Chart.SimpleBarChartPlayersSubLevels
                    data = {Main.Instance.ReportLSState.data}
                /> : void 0}
            </div>
            : void 0}
            
            {Main.Instance.ReportLSState.data && 
            (Main.Instance.ReportLSState.data.length > 0 && !nodata) ?
            <div 
                style = {parseInt(Main.Instance.ReportLSState.filter_level) === 0 ? {
                    float: 'left',
                    width: '60%',
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '20px',
                    marginTop: '30px',
                } : {
                    marginLeft: '5%',
                    float: 'left',
                    width: '40%',
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '20px',
                    marginTop: '0px',
                }}
            >
                <div
                    style = {{           
                        width:'100%',
                        border: '2px solid #eee',
                        borderRadius: '5px',
                        padding:'10px',
                        boxSizing:'border-box',
                    }}
                >
                    <p style = {{width:'100%'}}>
                        {parseInt(Main.Instance.ReportLSState.filter_level) === 0 ? 
                        Assets.PlayerDistributionByLevel :
                        Assets.PlayerDistributionBySubLevel}
                    </p>
                    {report_by_level}
                </div>
            </div>
            : void 0}
            </div>
        </div> : getReportLevelsPlayersCount()}
        </div>
    )
}

function ExportCSV0(d: {N: number, name: string, players: number[], total: number[], available: number[], sublevel: number[]}) {
    let s: string = Assets.ID + ',' + Assets.Sublevel + ',' + Assets.TotalPoints + ',' + Assets.AvailablePoints + '\n' 
    // 'ID,SubLevel,TotalPoints,AvailablePoints\n'
    for (let i: number = 0; i < d.players.length; i++) {
        s += d.players[i] + ',' + d.sublevel[i] + ',' + d.total[i] + ',' + d.available[i] + '\n'
    }

    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = 'gamanza-report.csv'
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}

function ExportCSV(d: {N: number, name: string, players: number[], total: number[], available: number[]}) {
    let s: string = Assets.ID + ',' + Assets.TotalPoints + ',' + Assets.AvailablePoints + '\n' 
    // 'ID,TotalPoints,AvailablePoints\n'
    for (let i: number = 0; i < d.players.length; i++) {
        s += d.players[i] + ',' + d.total[i] + ',' + d.available[i] + '\n'
    }

    const element = document.createElement('a')
    const file = new Blob([s], {type: 'text/csv'})
    element.href = URL.createObjectURL(file)
    element.download = 'gamanza-report.csv'
    // element.click()
    element.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
}