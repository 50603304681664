import * as React from 'react'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import { Main } from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'
import { CountryData, Region, NationalityData, AgeGroup, AccountStatus } from '../../../Logic/Database/DatabaseData'
import { Stat, StatType } from '../../../Logic/Reports/Reports'
import ZoomSwitzerlandSmall from '../Reports/ZoomSwitzerlandSmall'
import ZoomSwitzerlandStats from '../Reports/ZoomSwitzerlandStats'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faUsers, faGlobe} from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { DataChart, PieDataChart, BarDistribution } from '../../Reusables/Chart'
import { isUserReports, getHeader, getFooter/*, isUserGamanzaMagicHand*/, capitalizeFirstLetter, numberWithCommas, injectSpaces, isLocalhost} from '../../../Logic/Utils'
import { SingleDatePicker } from 'react-dates'
import * as moment from 'moment'
import ReactTable from 'react-table'
import { densityPallette, jackpotsPopulationStatisticsYear } from '../Reports/SwitzerlandResources'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
// import * as Config from '../../../Logic/Config'
import * as jsPDF from 'jspdf'
// import 'jspdf-autotable'
import html2canvas from 'html2canvas'
// fa-file-pdf-o fa-users fa-globe
// const faFilePdf: string = 'fa fa-file-pdf-o'
const faUsers: string = 'fa fa-users'
const faGlobe: string = 'fa fa-globe'

function numberSum(a: number[]): number {
    let s: number = 0
    for (const item of a) { s += item }
    return s
}

function getMonth(m: string): string {
    return Assets.Months[parseInt(m.charAt(0)) * 10 + parseInt(m.charAt(1)) + 1]
}

function getQuarter(q: string): string {
    return Assets_.QuartersMonthly[parseInt(q.charAt(5)) * 10 + parseInt(q.charAt(6)) - 1] + ' ' + q.substring(0, 4)
    // const ret: string = Assets_.QuartersMonthly[parseInt(q.charAt(5)) * 10 + parseInt(q.charAt(6)) - 1] + ' ' + q.substring(0, 4)
    // console.log (q, ret)
    // return ret
}

function getDayInWeek(d: string): string {
    return Assets.DaysInWeek[(new Date(d)).getUTCDay()]
}

function getDayInMonth(d: string): string {
    return (new Date(d)).getUTCDate().toString()
}

function CreatePDFDocument() {
    window['html2canvas'] = html2canvas
    const el: HTMLElement = document.getElementById('Exported1')
    if (el) {
        const img = new Image();
        img.src = Assets_.jackpotsLogo
        img.onload = () => {
            const img2 = new Image()
            img2.src = Assets_.badenLogo

            img2.onload = () => {
                const HTML_Width: number = el.clientWidth
                const HTML_Height: number = el.clientHeight
                html2canvas(el, {
                    allowTaint: true,
                    // useCORS: true,
                    onclone: (document) => {
                        el.style.borderStyle = 'none'
                        el.style.background = '#fff'
                    }
                }).then((canvas) => {
                    canvas.getContext('2d')
                    const imgData = canvas.toDataURL('image/jpeg', 1.0);
                    const pdf = new jsPDF('landscape', 'mm', 'a4');
                    pdf.addImage(
                        imgData,
                        'JPG',
                        10,
                        20,
                        297 - 20,
                        (297 - 20) / HTML_Width * HTML_Height
                    );

                    getHeader('PLY', pdf, img, img2, 1)
                    getFooter('PLY', pdf)


                    /*
                                        for (let i = 1; i <= totalPDFPages; i++) {
                                            pdf.addPage(PDF_Width, PDF_Height);
                                            pdf.addImage(
                                                imgData,
                                                'JPG',
                                                top_left_margin,
                                                -(PDF_Height * i) + top_left_margin * 4,
                                                canvas_image_width,
                                                canvas_image_height
                                            );
                                        }
                    */
                    pdf.save(Assets.Player_ + ' ' + Assets.Registration + Assets._at_ + (new Date()).toISOString().substring(0, 19).replace(':', '-').replace(':', '-').replace('T', ' ') + '.pdf')
                });
            }
        }
    }
};

/*
function CreatePDFDocument() {
    window['html2canvas'] = html2canvas
    const doc = new jsPDF('landscape', 'mm', 'a4')
    const img = new Image();
    img.src = Main.Instance.LoginState.entity === Config.keyEntity ? Assets_.jackpotsLogo : ''
    img.onload = () => {
        const img2 = new Image()
        img2.src = Main.Instance.LoginState.entity === Config.keyEntity ? Assets_.badenLogo : ''
        
        img2.onload = () => {
            getHeader('PLY', doc, img, img2, 1)
            getFooter('PLY', doc)

            const el = document.getElementById('Width')
            console.log (el)
            if (el) {
                console.log (doc)
                doc.fromHTML(el, {
                    callback: (doc1) => {
                        doc1.save(Assets.Player + ' ' + Assets.Registration + Assets._at_ + (new Date()).toISOString().substring(0,19).replace(':','-').replace(':','-').replace('T',' ') + '.pdf')
                    }
                })
            }
        }
    }
}
*/
export function PlayersReport() {
    DbRoutes.wsReinitialize()

    if (!Main.Instance.DatabaseDataState.playersInfo || Main.Instance.DatabaseDataState.nationalities === undefined || Main.Instance.DatabaseDataState.countries === undefined) {
        return <div>{Assets.LoadingData}</div>
    }

    const PlayersSelected: number[] = []
    let playersSelected: number = 0 // number of players that get selected, default is everybody if there are no filters
    const countries: CountryData[] = []
    const countriesNumber: number[] = []

    let regions: Region[] = []
    const regionsNumber: number[] = []
    let REGION = false
    let regionName: string = Assets.Region
    const nationalities: NationalityData[] = []
    const nationalitiesNumber: number[] = []
    const mainNationalities: NationalityData[] = []

    const ageGroups: AgeGroup[] = []
    const ageGroupsNumber: number[] = []

    for (const item of Assets_.ageGroup) {
        ageGroups.push(item)
        ageGroupsNumber.push(0)
    }

    const genders: Array<{ value: 'm' | 'f', text: 'male' | 'female' }> = []
    const gendersNumber: number[] = []

    genders.push({ value: 'm', text: 'male' })
    gendersNumber.push(0)
    genders.push({ value: 'f', text: 'female' })
    gendersNumber.push(0)

    const accountStatuses: Array<{ value: AccountStatus, /*text: string,*/ color: string }> = []
    const accountStatusesNumber: number[] = []

    for (let i: number = 0; i < Object.keys(AccountStatus).length / 2; i++) {
        accountStatuses.push({ value: i, /*text: AccountStatus[i],*/ color: Assets_.jackpotsAccountStatusColor[i] })
        accountStatusesNumber.push(0)
    }
    // console.log (accountStatuses)
    // console.log (accountStatusesNumber)

    const registereds: string[] = []
    const registereds2: string[] = []
    const registeredsNumber: number[] = []
    const Registereds: string[] = []
    const RegisteredsNumber: number[] = []
    const RegistereDs: string[] = []
    const RegistereDsNumber: number[] = []
    const RegistereQs: string[] = []
    const RegistereQsNumber: number[] = []
    let RegistereWs: string[] = []
    const RegistereWsNumber: number[] = []
    let RegistereMs: string[] = []
    const RegistereMsNumber: number[] = []

    for (const item of Main.Instance.DatabaseDataState.nationalities) {
        
        if (item.data.nationality === 'Swiss' || 
        item.data.nationality === 'Austrian' || 
        item.data.nationality === 'French' || 
        item.data.nationality === 'German' || 
        item.data.nationality === 'Italian' || 
        item.data.nationality === 'Portuguese' || 
        item.data.nationality === 'Turkish') {
            mainNationalities.push(item.data)
        }
    }

    const barDistribution: Array<{ key: string, value: number, key2?: string }> = []
    if (Main.Instance.PlayerInfo.statType === StatType.Nationality) {
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            if (nationalityAllowed(item.data.code3)) {
                for (const Item of mainNationalities) {
                    if (item.data.code3 === Item.code3) {
                        barDistribution.push({ key: item.data.nationality, value: 0, key2: item.data.code3 })
                    }
                }
            }
            // if (nationalityAllowed(item.data.code3)) {
            // barDistribution.push({key: item.data.nationality, value: 0, key2: item.data.code3})
            // }
        }
        barDistribution.push({ key: Assets.Other, value: 0, key2: Assets.Other })
    } else if (Main.Instance.PlayerInfo.statType === StatType.Age) {
        for (let i: number = 0; i < ageGroups.length; i++) {
            barDistribution.push({ key: ageGroups[i].text, value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.Gender) {
        for (let i: number = 0; i < 2; i++) {
            barDistribution.push({ key: i === 0 ? Assets.male : Assets.female, value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.AccountStatus) {
        for (let i: number = 0; i < Object.keys(AccountStatus).length / 2; i++) {
            barDistribution.push({ key: injectSpaces(AccountStatus[i]), value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.Hourly) {
        for (let i: number = 0; i < 24; i++) {
            barDistribution.push({ key: i < 10 ? '0' + i.toString() : i.toString(), value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.InWeek) {
        for (let i: number = 1; i < 7; i++) {
            barDistribution.push({ key: i === 3 ? Assets.WednesdayShort + '.' : Assets.DaysInWeek[i], value: 0 })
        }
        barDistribution.push({ key: Assets.DaysInWeek[0], value: 0 })
    } else if (Main.Instance.PlayerInfo.statType === StatType.Daily) {
        for (let i: number = 1; i < 32; i++) {
            barDistribution.push({ key: i.toString(), value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.Monthly) {
        for (let i: number = 2; i < Assets.Months.length; i++) {
            barDistribution.push({ key: Assets.Months[i].substring(0, 3), value: 0 })
        }
    } else if (Main.Instance.PlayerInfo.statType === StatType.Quarterly) {
        const now: Date = new Date()
        const nowMonth: number = now.getUTCMonth()
        const nowQuarter: number = nowMonth < 3 ? 0 : nowMonth < 6 ? 1 : nowMonth < 9 ? 2 : 3
        const nowYear: number = now.getUTCFullYear()
        for (let i: number = Assets_.StatisticsYearStart; i <= nowYear; i++) {
            for (let j: number = (i === Assets_.StatisticsYearStart ? Assets_.StatisticsQuarterStart : 0); j < (i === nowYear ? nowQuarter + 1 : 4); j++) {
                barDistribution.push({ key: Assets_.QuartersSingular[j] + ' ' + i, value: 0 })
            }
        }

        if (barDistribution.length > 6) {
            for (const item of barDistribution) {
                item.key = item.key.substring(0, 3) + '\'' + item.key.substring(5, 7)
            }
        }
        // console.log (barDistribution)
    } else if (Main.Instance.PlayerInfo.statType === StatType.Annual) {
        const now: Date = new Date()
        const nowYear: number = now.getUTCFullYear()

        for (let i: number = Assets_.StatisticsYearStart; i <= nowYear; i++) {
            barDistribution.push({ key: i.toString(), value: 0 })
        }
    }
    // let registeredCustom: number = 0
    let registeredCustomStart: string
    let registeredCustomEnd: string
    if (Main.Instance.PlayerInfo.registeredStart !== null) {
        registeredCustomStart = moment.default(Main.Instance.PlayerInfo.registeredStart).format(Assets_.dateDisplayFormat).trim()
        // console.log (registeredCustomStart)
    }
    if (Main.Instance.PlayerInfo.registeredEnd !== null) {
        registeredCustomEnd = moment.default(Main.Instance.PlayerInfo.registeredEnd).format(Assets_.dateDisplayFormat).trim()
        // console.log (registeredCustomEnd)
    }

    for (const item of Main.Instance.DatabaseDataState.playersInfo) {
        const registered: string = typeof item.registered === 'string' ? item.registered : (new Date(item.registered)).toISOString().substring(0, 19).replace('T', ' ')
        if (parseInt(registered.substring(0, 4)) < Assets_.StatisticsYearStart) { continue }
        let found2: boolean = false
        for (const Item of RegistereQs) {
            if (getQuarter(registered.substring(0, 7)) === Item) {
                found2 = true
                break
            }
        }
        if (!found2) {
            RegistereQs.push(getQuarter(registered.substring(0, 7)))
            RegistereQsNumber.push(0)
        }

        let found: boolean = false
        for (const Item of registereds) {
            if (registered.substring(0, 7) === Item) {
                found = true
                break
            }
        }
        if (!found) {
            registereds.push(registered.substring(0, 7))
            registereds2.push(getMonth(registered.substring(5, 7)) + ' ' + registered.substring(0, 4))
            registeredsNumber.push(0)
        }

        let Found: boolean = false
        for (const Item of Registereds) {
            if (registered.substring(0, 4) === Item) {
                Found = true
                break
            }
        }
        if (!Found) {
            Registereds.push(registered.substring(0, 4))
            RegisteredsNumber.push(0)
        }

        let FounD: boolean = false
        for (const Item of RegistereDs) {
            if (registered.substring(0, 10) === Item) {
                FounD = true
                break
            }
        }
        if (!FounD) {
            RegistereDs.push(registered.substring(0, 10))
            RegistereDsNumber.push(0)
        }

        let found3: boolean = false
        for (const Item of RegistereWs) {
            if (getDayInWeek(registered.substring(0, 10)) === Item) {
                found3 = true
                break
            }
        }
        if (!found3) {
            RegistereWs.push(getDayInWeek(registered.substring(0, 10)))
            RegistereWsNumber.push(0)
        }

        let found5: boolean = false
        for (const Item of RegistereMs) {
            if (getDayInMonth(registered.substring(0, 10)) === Item) {
                found5 = true
                break
            }
        }
        if (!found5) {
            RegistereMs.push(getDayInMonth(registered.substring(0, 10)))
            RegistereMsNumber.push(0)
        }
    }

    // console.log (RegistereWs)
    const registerews: string[] = []
    const found4: boolean[] = []
    for (const { } of RegistereWs) { found4.push(false) }
    for (let j: number = 1; j < Assets.DaysInWeek.length; j++) {
        for (let k: number = 0; k < RegistereWs.length; k++) {
            if (found4[k]) { continue }
            if (RegistereWs[k] === Assets.DaysInWeek[j]) {
                found4[k] = true
                registerews.push(Assets.DaysInWeek[j])
                break
            }
        }
    }
    for (let k: number = 0; k < RegistereWs.length; k++) {
        if (found4[k]) { continue }
        if (RegistereWs[k] === Assets.DaysInWeek[0]) {
            found4[k] = true
            registerews.push(Assets.DaysInWeek[0])
            break
        }
    }
    RegistereWs = Object.assign([], registerews)
    // console.log (RegistereWs)

    // console.log (RegistereMs)
    const registerems: string[] = []
    const found6: boolean[] = []
    for (const { } of RegistereMs) { found6.push(false) }
    for (let j: number = 1; j <= 31; j++) {
        for (let k: number = 0; k < RegistereMs.length; k++) {
            if (found6[k]) { continue }
            if (parseInt(RegistereMs[k]) === j) {
                found6[k] = true
                registerems.push(RegistereMs[k])
                break
            }
        }
    }

    RegistereMs = Object.assign([], registerems)
    // console.log (RegistereMs)    

    // console.log (RegistereDs)
    // console.log (RegistereDsNumber)

    if (Main.Instance.DatabaseDataState.nationalities) {
        for (const item of Main.Instance.DatabaseDataState.nationalities) {
            if (nationalityAllowed(item.data.code3)) {
                nationalities.push(item.data)
                nationalitiesNumber.push(0)
            }
        }
    }

    if (Main.Instance.DatabaseDataState.countries) {
        for (const item of Main.Instance.DatabaseDataState.countries) {
            if (countryAllowed(item.data.code)) {
                countries.push(item.data)
                countriesNumber.push(0)
                if (item.data.region) {
                    regionName = item.data.region
                }
                if (item.data.regions) {
                    for (const Item of item.data.regions) {
                        Item.country = item.data.code
                        Item.Country = item.data.name
                        regions.push(Item)
                        regionsNumber.push(0)
                    }
                }
            }
        }
    }
    // console.log (RegistereQs)
    // console.log (regions)
    regions = regions.sort((a, b) => a.name > b.name ? 1 : -1)
    // console.log (regionName)
    // initialization

    if (Main.Instance.PlayerInfo.registereds === undefined) {
        const registereds_: boolean[] = []
        for (const { } of registereds) {
            registereds_.push(false)
        }
        // console.log (8)
        Logic.Type.New(Main.Instance.PlayerInfo, { registereds: registereds_ })
    }

    if (Main.Instance.PlayerInfo.Registereds === undefined) {
        const Registereds_: boolean[] = []
        for (const { } of Registereds) {
            Registereds_.push(false)
        }
        // console.log (7)
        Logic.Type.New(Main.Instance.PlayerInfo, { Registereds: Registereds_ })
    }

    if (Main.Instance.PlayerInfo.RegistereDs === undefined) {
        const RegistereDs_: boolean[] = []
        for (const { } of RegistereDs) {
            RegistereDs_.push(false)
        }
        // console.log (9)
        Logic.Type.New(Main.Instance.PlayerInfo, { RegistereDs: RegistereDs_ })
    }

    if (Main.Instance.PlayerInfo.RegistereWs === undefined) {
        const RegistereWs_: boolean[] = []
        for (const { } of RegistereWs) {
            RegistereWs_.push(false)
        }
        // console.log (10)
        Logic.Type.New(Main.Instance.PlayerInfo, { RegistereWs: RegistereWs_ })
    }

    if (Main.Instance.PlayerInfo.RegistereMs === undefined) {
        const RegistereMs_: boolean[] = []
        for (const { } of RegistereMs) {
            RegistereMs_.push(false)
        }
        // console.log (11)
        Logic.Type.New(Main.Instance.PlayerInfo, { RegistereMs: RegistereMs_ })
    }

    if (Main.Instance.PlayerInfo.RegistereQs === undefined) {
        const RegistereQs_: boolean[] = []
        for (const { } of RegistereQs) {
            RegistereQs_.push(false)
        }
        // console.log (9)
        Logic.Type.New(Main.Instance.PlayerInfo, { RegistereQs: RegistereQs_ })
    }

    if (Main.Instance.PlayerInfo.accountStatuses === undefined) {
        const AccountStatuses: boolean[] = []
        for (const { } of accountStatuses) {
            AccountStatuses.push(false)
        }
        // console.log (6)
        Logic.Type.New(Main.Instance.PlayerInfo, { accountStatuses: AccountStatuses })
    }

    if (Main.Instance.PlayerInfo.genders === undefined) {
        const Genders: boolean[] = []
        for (const { } of genders) {
            Genders.push(false)
        }
        // console.log (5)
        Logic.Type.New(Main.Instance.PlayerInfo, { genders: Genders })
    }

    if (Main.Instance.PlayerInfo.ages === undefined) {
        const Ages: boolean[] = []
        for (const { } of ageGroups) {
            Ages.push(false)
        }
        // console.log (4)
        Logic.Type.New(Main.Instance.PlayerInfo, { ages: Ages })
    }

    for (const { } of regions) {
        PlayersSelected.push(0)
    }

    if (Main.Instance.PlayerInfo.regions === undefined) {
        const Regions: boolean[] = []
        for (const { } of regions) {
            Regions.push(false)
        }
        // console.log (3)
        Logic.Type.New(Main.Instance.PlayerInfo, { regions: Regions })
    }

    if (Main.Instance.PlayerInfo.countries === undefined) {
        const Countries: boolean[] = []
        for (const { } of countries) {
            Countries.push(false)
        }
        // console.log (2)
        Logic.Type.New(Main.Instance.PlayerInfo, { countries: Countries })
    }

    if (Main.Instance.PlayerInfo.nationalities === undefined) {
        const Nationalities: boolean[] = []
        for (const { } of nationalities) {
            Nationalities.push(false)
        }
        // console.log (1)
        Logic.Type.New(Main.Instance.PlayerInfo, { nationalities: Nationalities })
    }
    // console.log (regions)
    // filters applied
    for (const item of Main.Instance.DatabaseDataState.playersInfo) {
        const registered: string = typeof item.registered === 'string' ? item.registered : (new Date(item.registered)).toISOString().substring(0, 19).replace('T', ' ')
        if (parseInt(registered.substring(0, 4)) < Assets_.StatisticsYearStart) { continue }
        // just to count numbers which should not change based on selection
        for (let i: number = 0; i < registereds.length; i++) {
            if (registereds[i] === registered.substring(0, 7)) {
                registeredsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < RegistereDs.length; i++) {
            if (RegistereDs[i] === registered.substring(0, 10)) {
                RegistereDsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < RegistereWs.length; i++) {
            if (RegistereWs[i] === getDayInWeek(registered.substring(0, 10))) {
                RegistereWsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < RegistereMs.length; i++) {
            if (RegistereMs[i] === getDayInMonth(registered.substring(0, 10))) {
                RegistereMsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < Registereds.length; i++) {
            if (Registereds[i] === registered.substring(0, 4)) {
                RegisteredsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < RegistereQs.length; i++) {
            if (RegistereQs[i] === getQuarter(registered.substring(0, 7))) {
                RegistereQsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < accountStatuses.length; i++) {
            if (accountStatuses[i].value === item.accountStatus) {
                accountStatusesNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < regions.length; i++) {
            if (regions[i].code === item.canton) {
                regionsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < nationalities.length; i++) {
            if (nationalities[i].code3 === item.nationality) {
                nationalitiesNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < countries.length; i++) {
            if (countries[i].code === item.country) {
                countriesNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < ageGroups.length; i++) {
            if ((ageGroups[i].low === undefined || ageGroups[i].low <= item.age) && (ageGroups[i].high === undefined || ageGroups[i].high >= item.age)) {
                // console.log ('age ' + item.age + ' group ' + ageGroups[i].text)
                ageGroupsNumber[i]++
                break
            }
        }

        for (let i: number = 0; i < genders.length; i++) {
            if (genders[i].value === item.gender) {
                gendersNumber[i]++
                break
            }
        }

        // first lets exclude items that are not compatible to the filters
        if (Main.Instance.PlayerInfo.gender) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.genders) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.genders.length; i++) {
                    if (Main.Instance.PlayerInfo.genders[i] === true && genders[i].value === item.gender) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.country) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.countries) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.countries.length; i++) {
                    if (Main.Instance.PlayerInfo.countries[i] === true && countries[i].code === item.country) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.nationality) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.nationalities) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.nationalities.length; i++) {
                    if (Main.Instance.PlayerInfo.nationalities[i] === true && nationalities[i].code3 === item.nationality) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.age) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.ages) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.ages.length; i++) {
                    if (Main.Instance.PlayerInfo.ages[i] === true && ((ageGroups[i].low === undefined || ageGroups[i].low <= item.age) && (ageGroups[i].high === undefined || ageGroups[i].high >= item.age))) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.accountStatus) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.accountStatuses) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.accountStatuses.length; i++) {
                    if (Main.Instance.PlayerInfo.accountStatuses[i] === true && accountStatuses[i].value === item.accountStatus) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.registereds) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.registereds.length; i++) {
                    if (Main.Instance.PlayerInfo.registereds[i] === true && registereds[i] === registered.substring(0, 7)) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDate) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.RegistereDs) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.RegistereDs.length; i++) {
                    if (Main.Instance.PlayerInfo.RegistereDs[i] === true && RegistereDs[i] === registered.substring(0, 10)) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDayInWeek) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.RegistereWs) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.RegistereWs.length; i++) {
                    if (Main.Instance.PlayerInfo.RegistereWs[i] === true && RegistereWs[i] === getDayInWeek(registered.substring(0, 10))) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDayInMonth) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.RegistereMs) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.RegistereMs.length; i++) {
                    if (Main.Instance.PlayerInfo.RegistereMs[i] === true && RegistereMs[i] === getDayInMonth(registered.substring(0, 10))) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredCustomTime) {
            let Continue = false
            if (Main.Instance.PlayerInfo.registeredStartTime && Main.Instance.PlayerInfo.registeredStartTime.length > 0) {
                Continue = true
                if (Main.Instance.PlayerInfo.registeredStartTime <= registered.substring(11, 19)) {
                    Continue = false
                }
            }
            let Continue2 = false
            if (Main.Instance.PlayerInfo.registeredEndTime && Main.Instance.PlayerInfo.registeredEndTime.length > 0) {
                Continue2 = true
                if (Main.Instance.PlayerInfo.registeredEndTime >= registered.substring(11, 19)) {
                    Continue2 = false
                }
            }
            if (Continue || Continue2) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredCustomDate) {
            let Continue = false
            if (registeredCustomStart) {
                Continue = true
                if (registeredCustomStart <= registered.substring(0, 10)) {
                    Continue = false
                }
            }
            let Continue2 = false
            if (registeredCustomEnd) {
                Continue2 = true
                if (registeredCustomEnd >= registered.substring(0, 10)) {
                    Continue2 = false
                }
            }
            if (Continue || Continue2) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredQuarter) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.RegistereQs) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.RegistereQs.length; i++) {
                    if (Main.Instance.PlayerInfo.RegistereQs[i] === true && RegistereQs[i] === getQuarter(registered.substring(0, 7))) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        if (Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredMonth) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.Registereds) {
                if (Item === true) {
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.Registereds.length; i++) {
                    if (Main.Instance.PlayerInfo.Registereds[i] === true && Registereds[i] === registered.substring(0, 4)) {
                        Continue = false
                        break
                    }
                }
            }
            if (Continue) { continue }
        }

        let REGION_: number = -1

        if (Main.Instance.PlayerInfo.region) {
            let found: boolean = false
            for (const Item of Main.Instance.PlayerInfo.regions) {
                if (Item === true) {
                    REGION = true
                    found = true
                    break
                }
            }
            let Continue: boolean = false
            if (found) {
                Continue = true
                for (let i: number = 0; i < Main.Instance.PlayerInfo.regions.length; i++) {
                    if (regions[i].code === item.canton) {
                        REGION_ = i
                        if (Main.Instance.PlayerInfo.regions[i] === true) {
                            Continue = false
                        }
                        break
                    }
                }
            } else {
                for (let i: number = 0; i < Main.Instance.PlayerInfo.regions.length; i++) {
                    if (regions[i].code === item.canton) {
                        REGION_ = i
                        break
                    }
                }
            }
            if (Continue) { continue }
        } else {
            for (let i: number = 0; i < Main.Instance.PlayerInfo.regions.length; i++) {
                if (regions[i].code === item.canton) {
                    REGION_ = i
                    break
                }
            }
        }

        if (Main.Instance.PlayerInfo.statType === StatType.Nationality) {
            /* for (let i: number = 0; i < nationalities.length; i++) {
                if (nationalities[i].code3 === item.nationality) {
                    barDistribution[i].value++
                    break
                }
            } */
            let found: boolean = false
            for (let i: number = 0; i < mainNationalities.length; i++) {
                if (mainNationalities[i].code3 === item.nationality) {
                    barDistribution[i].value++
                    found = true
                    break
                }
            }
            if (!found) {
                barDistribution[mainNationalities.length].value++
            }
        } else if (Main.Instance.PlayerInfo.statType === StatType.Age) {
            for (let i: number = 0; i < ageGroups.length; i++) {
                if ((ageGroups[i].low === undefined || ageGroups[i].low <= item.age) && (ageGroups[i].high === undefined || ageGroups[i].high >= item.age)) {
                    // console.log ('age ' + item.age + ' group ' + ageGroups[i].text)
                    barDistribution[i].value++
                    break
                }
            }
        } else if (Main.Instance.PlayerInfo.statType === StatType.Gender) {
            if (item.gender === 'm') {
                barDistribution[0].value++
            } else if (item.gender === 'f') {
                barDistribution[1].value++
            }
        } else if (Main.Instance.PlayerInfo.statType === StatType.AccountStatus) {
            for (let i: number = 0; i < accountStatuses.length; i++) {
                if (item.accountStatus === i) {
                    barDistribution[i].value++
                }
            }
        } else if (Main.Instance.PlayerInfo.statType === StatType.Hourly) {
            barDistribution[parseInt(registered.substring(11, 13))].value++
        } else if (Main.Instance.PlayerInfo.statType === StatType.InWeek) {
            const day: number = (new Date(registered)).getUTCDay()
            barDistribution[day === 0 ? 6 : day - 1].value++
        } else if (Main.Instance.PlayerInfo.statType === StatType.Daily) {
            barDistribution[parseInt(registered.substring(8, 10)) - 1].value++
        } else if (Main.Instance.PlayerInfo.statType === StatType.Monthly) {
            barDistribution[parseInt(registered.substring(5, 7)) - 1].value++
        } else if (Main.Instance.PlayerInfo.statType === StatType.Quarterly) {
            // const now: Date = new (Date)
            const month: number = parseInt(registered.substring(5, 7)) - 1
            const quarter: number = month < 3 ? 0 : month < 6 ? 1 : month < 9 ? 2 : 3
            const year: number = parseInt(registered.substring(0, 4))
            if (year >= Assets_.StatisticsYearStart) {
                // bug
                if (barDistribution[(year - Assets_.StatisticsYearStart) * 4 + quarter - Assets_.StatisticsQuarterStart]) {
                    barDistribution[(year - Assets_.StatisticsYearStart) * 4 + quarter - Assets_.StatisticsQuarterStart].value++
                } else {

                }
            }
        } else if (Main.Instance.PlayerInfo.statType === StatType.Annual) {
            const year: number = parseInt(registered.substring(0, 4))
            const maxYear: number = parseInt((new Date()).toISOString().substring(0, 4))
            if (year >= Assets_.StatisticsYearStart && year <= maxYear) {
                // if (barDistribution[parseInt(registered.substring(0,4)) - Assets_.StatisticsYearStart]) {
                // console.log ('registered ' + registered)
                barDistribution[parseInt(registered.substring(0, 4)) - Assets_.StatisticsYearStart].value++
                // }
            }
        }

        if (REGION_ > -1) {
            PlayersSelected[REGION_]++
        }
        playersSelected++
    }
    if (Main.Instance.PlayerInfo.statType === StatType.Monthly) {
        let monthlyValues: number = 0
        for (const item of barDistribution) {
            if (item.value > 0) { monthlyValues++ }
        }
        if (monthlyValues < 6) {
            for (let i: number = 0; i < barDistribution.length; i++) {
                barDistribution[i].key = Assets.Months[i + 2]
            }
            for (let i: number = 0; i < barDistribution.length;) {
                if (barDistribution[i].value === 0) {
                    barDistribution.splice(i, 1)
                } else {
                    i++
                }
            }
        }
    }

    // console.log ()
    // console.log (PlayersSelected)
    let Width: number = 0
    const el = document.getElementById('Width')
    if (el) { Width = el.clientWidth }
    // console.log ('Width ' + Width)
/*
    if (Main.Instance.LoginState.unused3 === false && isLocalhost() === false) {
        return (
        <div>
            <h2>{Assets.YouDontHaveSecurityRightsToAccessData}</h2>
        </div>
        )
    } else {
*/
    return (
        <div id="Exported1" className="col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix" style={{ padding: '10px', position: 'relative', minHeight: '50px' }}>
            <div
                className="clearfix test-site-data2 pr light-grey"
                style={{ marginTop: '0px !important'/*, paddingTop:'30px', paddingBottom:'10px'*/ }}
            >
                <div className="clearfix">
                    <div className="fl">
                        <div>
                            <button
                                // disabled = {true}
                                style={{ cursor: 'default' }}
                                onClick={() => { }}
                                className="btn btn-danger btn-xs"
                            >
                                {/*<FontAwesomeIcon icon = {faUsers}/>*/}<i className={faUsers} />&nbsp;
                                <span className="badge badge-light">{Main.Instance.DatabaseDataState.playersInfo.length}</span>
                            </button>

                            <button
                                // disabled = {true}
                                style={{ cursor: 'default' }}
                                onClick={() => { }}
                                className="btn btn-warning btn-xs"
                            >
                                {Assets.Selected.toLowerCase()}&nbsp;
                                {/*<FontAwesomeIcon icon = {faUsers}/>*/}<i className={faUsers} />&nbsp;
                                <span className="badge badge-light">{playersSelected}</span>
                            </button>

                            <button
                                disabled={countries.length <= 1}
                                className={countries.length === 0 ? 'btn btn-danger btn-xs' : Main.Instance.PlayerInfo.country ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { country: !Main.Instance.PlayerInfo.country })}
                            >
                                {countries.length === 1 ? Assets.Country.toLowerCase() + ': ' + countries[0].name : Assets.Country.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{countries.length === 1 ? countriesNumber[0] : numberSum(countriesNumber)}</span>
                            </button>

                            <button
                                disabled={regions.length <= 1}
                                className={regions.length === 0 ? 'btn btn-danger btn-xs' : Main.Instance.PlayerInfo.region ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { region: !Main.Instance.PlayerInfo.region })}
                            >
                                {countries.length === 1 ? regionName.toLowerCase() : Assets.Region.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(regionsNumber)}</span>
                            </button>

                            <button
                                disabled={nationalities.length <= 1}
                                className={nationalities.length === 0 ? 'btn btn-danger btn-xs' : Main.Instance.PlayerInfo.nationality ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { nationality: !Main.Instance.PlayerInfo.nationality })}
                            >
                                {Assets.Nationality.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(nationalitiesNumber)}</span>
                            </button>

                            <button
                                className={Main.Instance.PlayerInfo.age ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { age: !Main.Instance.PlayerInfo.age })}
                            >
                                {Assets.Age.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(ageGroupsNumber)}</span>
                            </button>

                            <button
                                className={Main.Instance.PlayerInfo.gender ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { gender: !Main.Instance.PlayerInfo.gender })}
                            >
                                {Assets.Gender.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(gendersNumber)}</span>
                            </button>

                            <button
                                className={Main.Instance.PlayerInfo.accountStatus ? 'btn btn-success btn-xs' : 'btn btn-default btn-xs'}
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { accountStatus: !Main.Instance.PlayerInfo.accountStatus })}
                            >
                                {Assets.AccountStatus.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(accountStatusesNumber)}</span>
                            </button>

                            <button
                                className={Main.Instance.PlayerInfo.registered ? 'btn btn-success btn-xs mr0' : 'btn btn-default btn-xs mr0'} // this is the last item
                                onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { registered: !Main.Instance.PlayerInfo.registered/*, Registered: !Main.Instance.PlayerInfo.Registered, RegistereD: !Main.Instance.PlayerInfo.RegistereD*/ })}
                            >
                                {Assets.Registered.toLowerCase()}&nbsp;
                                <span className="badge badge-light">{numberSum(registeredsNumber)}</span>
                            </button>
                        </div>
                        <div>
                            {Main.Instance.PlayerInfo.country && countries.length > 0 ? <div className="mt5">{getCountries(countries, countriesNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.region && regions.length > 0 ? <div>{getRegions(regions, regionsNumber, regionName, countries)}</div> : void 0}
                            {Main.Instance.PlayerInfo.nationality && nationalities.length > 0 ? <div className="mt5">{getNationalities(nationalities, nationalitiesNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.age && ageGroups.length > 0 ? <div className="mt5">{getAgeGroups(ageGroups, ageGroupsNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.gender && genders.length > 0 ? <div className="mt5">{getGenders(genders, gendersNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.accountStatus && accountStatuses.length > 0 ? <div className="mt5">{getAccountStatuses(accountStatuses, accountStatusesNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Registereds.length > 0 ? <div className="mt5 clearfix" id="datadate">{getRegistereds(Registereds, RegisteredsNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredQuarter && RegistereQs.length > 0 ? <div className="mt5">{getRegistereQs(RegistereQs, RegistereQsNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredMonth && registereds.length > 0 ? <div className="mt5">{getregistereds(registereds, registeredsNumber, registereds2)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDate && RegistereDs.length > 0 ? <div className="mt5">{getRegistereDs(RegistereDs, RegistereDsNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDayInWeek && RegistereWs.length > 0 ? <div className="mt5">{getRegistereWs(RegistereWs, RegistereWsNumber)}</div> : void 0}
                            {Main.Instance.PlayerInfo.registered && Main.Instance.PlayerInfo.showRegisteredDayInMonth && RegistereMs.length > 0 ? <div className="mt5">{getRegistereMs(RegistereMs, RegistereMsNumber)}</div> : void 0}
                        </div>
                    </div>
                    {/*<div style = {{float: 'left', marginTop: '-20px', marginLeft: '-100px', marginRight: '-100px'}}>*/}
                    {Main.Instance.PlayerInfo.region && regions.length > 0 ?
                        <div style={{ position: 'absolute', top: '-26px', left: '1020px' }}>
                            {<ZoomSwitzerlandSmall regions={regions} region={REGION} />}
                        </div>
                        : void 0}
                </div>
                <div className="mt clearfix">
                    <button onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { stat: Stat.Population })} type="button" className={Main.Instance.PlayerInfo.stat === Stat.Population ? 'fr btn btn-info btn-xs br0 mr0' : 'fr btn btn-default btn-xs br0 mr0'}>
                        {(Assets.Population).toLowerCase() + ' (' + jackpotsPopulationStatisticsYear + ')'}
                    </button>
                    <button onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { stat: Stat.PlayerRegistration })} type="button" className={Main.Instance.PlayerInfo.stat === Stat.PlayerRegistration ? 'fl btn btn-primary btn-xs br0 mr0' : 'fl btn btn-default btn-xs br0 mr0'}>
                        {injectSpaces(Stat[Stat.PlayerRegistration]).toLowerCase()}
                    </button>
                    <button onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { stat: Stat.PlayerRegistrationByPopulation })} type="button" className={Main.Instance.PlayerInfo.stat === Stat.PlayerRegistrationByPopulation ? 'fl btn btn-primary btn-xs br0 mr0' : 'fl btn btn-default btn-xs br0 mr0'}>
                        {injectSpaces(Stat[Stat.PlayerRegistrationByPopulation]).toLowerCase()}
                    </button>

                </div>
                <div id="Width" style={{ border: '1px solid #bbb', background: '#f3f3f3', position: 'relative', height: '620px', marginTop: '-1px' }}>
                    <div style={{ width: '950px', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0px', right: '0px' }}>
                        <ZoomSwitzerlandStats regions={regions} region={REGION} PlayersSelected={PlayersSelected} />
                    </div>
                    <div style={{ position: 'absolute', top: '10px', left: '62%', zIndex: 9998 }}>
                        <button onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { c: true })} className={Main.Instance.PlayerInfo.c ? 'btn btn-xs btn-default btn-dark mr0 br0' : 'btn btn-xs btn-default mr0 br0'}>
                            {Assets.Code + 1}
                        </button>
                        <button style={{ marginLeft: '-1px' }} onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { c: false })} className={Main.Instance.PlayerInfo.c ? 'btn btn-xs btn-default br0' : 'btn btn-xs btn-default btn-dark br0'}>
                            {Assets.Code + 2}
                        </button>
                    </div>
                    {GetAbbrev(Width, regions, REGION, regionName)}
                    {GetLegend(Width)}
                    {GetTitle(playersSelected, regions, REGION)}
                    {GetChart(Width, regionName)}
                    {GetChart2(Width, Main.Instance.PlayerInfo.stat === Stat.Population ? StatType.Cantons : Main.Instance.PlayerInfo.statType, barDistribution/*, regionName*/)}
                    {Main.Instance.PlayerInfo.stat === Stat.Population ? void 0 : <div style={{ position: 'absolute', top: '30px', left: '5px', zIndex: 9998 }}>{GetStatType()}</div>}
                    {Main.Instance.PlayerInfo.stat !== Stat.Population && Main.Instance.PlayerInfo.statType >= StatType.Hourly ? GetReactTable(Width, barDistribution) : void 0}
                </div>
                <button disabled = {!isUserReports() && !isLocalhost()} data-html2canvas-ignore="true" title={Assets.Download + ' ' + Assets_.PDF} className="btn btn-danger btn-sm mr0" onClick={() => CreatePDFDocument()} style={{ padding: '0px', color: '#d9534f', fontSize: '20px', border: '0px', background: 'transparent', display: 'block', position: 'absolute', top: '-4px', right: '0px' }}>
                    <FontAwesomeIcon icon={faFilePdf} />
                    {/*<i className = {faFilePdf}/>*/}
                </button>
            </div>
        </div>
    )
    // }
}

function GetStatType(): JSX.Element[] {
    const st: JSX.Element[] = []

    for (let i: number = 0; i < Object.keys(StatType).length / 2; i++) {
        st.push(
            <button onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { statType: i })} type="button" className={i === Main.Instance.PlayerInfo.statType ? 'fl btn btn-warning btn-xs br0 mr0' : 'fl btn btn-default btn-xs br0 mr0'}>
                {injectSpaces(StatType[i])}
            </button>,
        )
    }
    return st
}

function toggleRegistereM(i: number) {
    const RegistereMs: boolean[] = Object.assign([], Main.Instance.PlayerInfo.RegistereMs)
    RegistereMs[i] = !RegistereMs[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { RegistereMs })
}

function getRegistereMs(registereds: string[], registeredsNumber: number[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            // onClick = {() => Logic.Type.New (Main.Instance.PlayerInfo, {registered: false, Registered: false, RegistereD: false})}
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDayInMonth: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byDayInMonth + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleRegistereM(i)}
                className={Main.Instance.PlayerInfo.RegistereMs[i] ? 'btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {registereds[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }
    return Registered
}

function toggleRegistereW(i: number) {
    const RegistereWs: boolean[] = Object.assign([], Main.Instance.PlayerInfo.RegistereWs)
    RegistereWs[i] = !RegistereWs[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { RegistereWs })
}

function getRegistereWs(registereds: string[], registeredsNumber: number[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            // onClick = {() => Logic.Type.New (Main.Instance.PlayerInfo, {registered: false, Registered: false, RegistereD: false})}
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDayInWeek: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byDayInWeek + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleRegistereW(i)}
                className={Main.Instance.PlayerInfo.RegistereWs[i] ? 'btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {registereds[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }
    return Registered
}

function toggleRegistereD(i: number) {
    const RegistereDs: boolean[] = Object.assign([], Main.Instance.PlayerInfo.RegistereDs)
    RegistereDs[i] = !RegistereDs[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { RegistereDs })
}

function getRegistereDs(registereds: string[], registeredsNumber: number[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            // onClick = {() => Logic.Type.New (Main.Instance.PlayerInfo, {registered: false, Registered: false, RegistereD: false})}
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDate: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byDate + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleRegistereD(i)}
                className={Main.Instance.PlayerInfo.RegistereDs[i] ? 'btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {registereds[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }
    return Registered
}

function toggleRegistereQ(i: number) {
    const RegistereQs: boolean[] = Object.assign([], Main.Instance.PlayerInfo.RegistereQs)
    RegistereQs[i] = !RegistereQs[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { RegistereQs })
}

function getRegistereQs(registereds: string[], registeredsNumber: number[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            // onClick = {() => Logic.Type.New (Main.Instance.PlayerInfo, {registered: false, Registered: false, RegistereD: false})}
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredQuarter: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byQuarter + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleRegistereQ(i)}
                className={Main.Instance.PlayerInfo.RegistereDs[i] ? 'btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {registereds[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }
    return Registered
}

function GetChart2(Width: number, statType: StatType, barDistribution: Array<{ key: string, value: number, key2?: string }>/*, regionName: string*/): JSX.Element {
/*
    if (statType === StatType.Cantons) {
        console.log(barDistribution)
        console.log(statType)
    }
*/   

    return (
        <div className="datachart" style={{ position: 'absolute', left: '0px', top: '0px' }}>
            {statType < StatType.Hourly ? <PieDataChart statType={statType} Width={Width} barDistribution={barDistribution} /> : <BarDistribution Width={Width} barDistribution={barDistribution} />}
            {/*statType === StatType.Hourly || statType === StatType.Daily || statType === StatType.InWeek || statType === StatType.Monthly || statType === StatType.Quarterly || statType === StatType.Annual ? <BarDistribution Width = {Width} barDistribution = {barDistribution}/> : void 0*/}
        </div>
    )
}

function GetChart(Width: number, regionName: string): JSX.Element {
    return (
        <div className="datachart" style={{ position: 'absolute', right: '0px', top: '0px' }}>
            <DataChart Width={Width} title={regionName} />
        </div>
    )
}

function toggleregistered(i: number) {
    const registereds: boolean[] = Object.assign([], Main.Instance.PlayerInfo.registereds)
    registereds[i] = !registereds[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { registereds })
}

function getregistereds(registereds: string[], registeredsNumber: number[], registereds2: string[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            // onClick = {() => Logic.Type.New (Main.Instance.PlayerInfo, {registered: false, Registered: false, RegistereD: false})}
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredMonth: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byMonth + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleregistered(i)}
                className={Main.Instance.PlayerInfo.registereds[i] ? 'btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {registereds2[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }
    return Registered
}

function toggleRegistered(i: number) {
    const Registereds: boolean[] = Object.assign([], Main.Instance.PlayerInfo.Registereds)
    Registereds[i] = !Registereds[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { Registereds })
}

function getRegistereds(registereds: string[], registeredsNumber: number[]): JSX.Element[] {
    const Registered: JSX.Element[] = []
    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { registered: false/*, Registered: false, RegistereD: false*/ })}
            key="-1"
            className="fl dbl btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.RegisteredPlayer_s + ' (' + Assets.byYear + ')').toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < registereds.length; i++) {
        Registered.push(
            <button
                key={i.toString()}
                disabled={registeredsNumber[i] === 0}
                onClick={() => toggleRegistered(i)}
                className={Main.Instance.PlayerInfo.Registereds[i] ? 'fl dbl btn btn-primary btn-xs mt3' : registeredsNumber[i] ? 'fl dbl btn btn-default btn-light btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
            >
                {registereds[i]}&nbsp;
                <span className="badge badge-light">{registeredsNumber[i]}</span>
            </button>,
        )
    }

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredQuarter: !Main.Instance.PlayerInfo.showRegisteredQuarter })}
            key="-2"
            className={Main.Instance.PlayerInfo.showRegisteredQuarter ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.byQuarter).toLowerCase()}
        </button>,
    )

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredMonth: !Main.Instance.PlayerInfo.showRegisteredMonth })}
            key="-3"
            className={Main.Instance.PlayerInfo.showRegisteredMonth ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.byMonth).toLowerCase()}
        </button>,
    )

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDate: !Main.Instance.PlayerInfo.showRegisteredDate })}
            key="-4"
            className={Main.Instance.PlayerInfo.showRegisteredDate ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.byDate).toLowerCase()}
        </button>,
    )

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDayInWeek: !Main.Instance.PlayerInfo.showRegisteredDayInWeek })}
            key="-5"
            className={Main.Instance.PlayerInfo.showRegisteredDayInWeek ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.byDayInWeek).toLowerCase()}
        </button>,
    )

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredDayInMonth: !Main.Instance.PlayerInfo.showRegisteredDayInMonth })}
            key="-6"
            className={Main.Instance.PlayerInfo.showRegisteredDayInMonth ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.byDayInMonth).toLowerCase()}
        </button>,
    )

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredCustomDate: !Main.Instance.PlayerInfo.showRegisteredCustomDate })}
            key="-7"
            className={Main.Instance.PlayerInfo.showRegisteredCustomDate ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.Custom + ' ' + Assets.Date).toLowerCase()}
        </button>,
    )

    if (Main.Instance.PlayerInfo.showRegisteredCustomDate) {
        Registered.push(
            <div key="-8" className="fl dbl" style={{ marginTop: '1px' }}>
                <SingleDatePicker
                    placeholder={(Assets.Start + ' ' + Assets.Date/* + ' ' + Assets.includingShort*/).toLowerCase()}
                    id="datadate1"
                    date={Main.Instance.PlayerInfo.registeredStart}
                    onDateChange={(date) => Logic.Type.New(Main.Instance.PlayerInfo, { registeredStart: date })}
                    focused={Main.Instance.PlayerInfo.focusedInputStart}
                    onFocusChange={(focused) => Logic.Type.New(Main.Instance.PlayerInfo, { focusedInputStart: focused.focused })}
                    showDefaultInputIcon={true}
                    showClearDate={true}
                    isOutsideRange={() => false}
                    displayFormat={Assets_.dateDisplayFormatdmy}
                    customCloseIcon={Assets_.customCloseIcon}
                    numberOfMonths={1}
                />
            </div>,
        )
        Registered.push(
            <div key="-10" className="fl dbl" style={{ marginTop: '2px' }}>&nbsp;-&nbsp;</div>,
        )
        Registered.push(
            <div key="-9" className="fl dbl" style={{ marginTop: '1px' }}>
                <SingleDatePicker
                    placeholder={(Assets.End + ' ' + Assets.Date/* + ' ' + Assets.includingShort*/).toLowerCase()}
                    id="datadate2"
                    date={Main.Instance.PlayerInfo.registeredEnd}
                    onDateChange={(date) => Logic.Type.New(Main.Instance.PlayerInfo, { registeredEnd: date })}
                    focused={Main.Instance.PlayerInfo.focusedInputEnd}
                    onFocusChange={(focused) => Logic.Type.New(Main.Instance.PlayerInfo, { focusedInputEnd: focused.focused })}
                    showDefaultInputIcon={true}
                    showClearDate={true}
                    isOutsideRange={() => false}
                    displayFormat={Assets_.dateDisplayFormatdmy}
                    customCloseIcon={Assets_.customCloseIcon}
                    numberOfMonths={1}
                />
            </div>,
        )

        Registered.push(
            <div key="-11" className="fl dbl">&nbsp;&nbsp;&nbsp;</div>,
        )
    }

    Registered.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { showRegisteredCustomTime: !Main.Instance.PlayerInfo.showRegisteredCustomTime })}
            key="-12"
            className={Main.Instance.PlayerInfo.showRegisteredCustomTime ? 'fl dbl btn btn-success btn-xs mt3' : 'fl dbl btn btn-default btn-xs mt3'}
        >
            {(Assets.Custom + ' ' + Assets.Time).toLowerCase()}
        </button>,
    )

    if (Main.Instance.PlayerInfo.showRegisteredCustomTime) {
        Registered.push(
            <input placeholder={(Assets.Start + ' ' + Assets.Time).toLowerCase()} className="fl dbl" key="-13" type="text" maxLength={8} value={Main.Instance.PlayerInfo.registeredStartTime ? Main.Instance.PlayerInfo.registeredStartTime : ''} onChange={(e) => Logic.Type.New(Main.Instance.PlayerInfo, { registeredStartTime: e.target.value })} style={{ marginTop: '3px', width: '75px', height: '22px', paddingLeft: '5px', paddingRight: '0px' }} />,
        )
        Registered.push(
            <div key="-14" className="fl dbl" style={{ marginTop: '2px' }}>&nbsp;-&nbsp;</div>,
        )
        Registered.push(
            <input placeholder={(Assets.End + ' ' + Assets.Time).toLowerCase()} className="fl dbl" key="-15" type="text" maxLength={8} value={Main.Instance.PlayerInfo.registeredEndTime ? Main.Instance.PlayerInfo.registeredEndTime : ''} onChange={(e) => Logic.Type.New(Main.Instance.PlayerInfo, { registeredEndTime: e.target.value })} style={{ marginTop: '3px', width: '75px', height: '22px', paddingLeft: '5px', paddingRight: '0px' }} />,
        )
    }
    return Registered
}

function toggleAccountStatus(i: number) {
    const accountStatuses: boolean[] = Object.assign([], Main.Instance.PlayerInfo.accountStatuses)
    accountStatuses[i] = !accountStatuses[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { accountStatuses })
}

function getAccountStatuses(accountStatuses: Array<{ value: AccountStatus, /*text: string,*/ color: string }>, accountStatusesNumber: number[]): JSX.Element[] {
    const AccountStatuS: JSX.Element[] = []
    AccountStatuS.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { accountStatus: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.AccountStatus_s).toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < accountStatuses.length; i++) {
        AccountStatuS.push(
            <button
                key={i.toString()}
                disabled={accountStatusesNumber[i] === 0}
                onClick={() => toggleAccountStatus(i)}
                className={Main.Instance.PlayerInfo.accountStatuses[i] ? 'btn btn-primary btn-xs mt3' : accountStatusesNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {Assets.accountStatus[i]}&nbsp;
                <span className="badge badge-light">{accountStatusesNumber[i]}</span>
            </button>,
        )
    }
    return AccountStatuS
}

function toggleGender(i: number) {
    const genders: boolean[] = Object.assign([], Main.Instance.PlayerInfo.genders)
    genders[i] = !genders[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { genders })
}

function getGenders(genders: Array<{ value: 'm' | 'f', text: 'male' | 'female' }>, gendersNumber: number[]): JSX.Element[] {
    const Genders: JSX.Element[] = []
    Genders.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { gender: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.Gender_s).toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < genders.length; i++) {
        Genders.push(
            <button
                key={i.toString()}
                disabled={gendersNumber[i] === 0}
                onClick={() => toggleGender(i)}
                className={Main.Instance.PlayerInfo.genders[i] ? 'btn btn-primary btn-xs mt3' : gendersNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {genders[i].text}&nbsp;
                <span className="badge badge-light">{gendersNumber[i]}</span>
            </button>,
        )
    }
    return Genders
}

function toggleAgeGroup(i: number) {
    const ages: boolean[] = Object.assign([], Main.Instance.PlayerInfo.ages)
    ages[i] = !ages[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { ages })
}

function getAgeGroups(ageGroups: AgeGroup[], ageGroupNumber: number[]): JSX.Element[] {
    const AgeGroups: JSX.Element[] = []
    AgeGroups.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { age: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.AgeGroup_s).toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < ageGroups.length; i++) {
        AgeGroups.push(
            <button
                disabled={ageGroupNumber[i] === 0}
                key={i.toString()}
                onClick={() => toggleAgeGroup(i)}
                className={Main.Instance.PlayerInfo.ages[i] ? 'btn btn-primary btn-xs mt3' : ageGroupNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {ageGroups[i].text}&nbsp;
                <span className="badge badge-light">{ageGroupNumber[i]}</span>
            </button>,
        )
    }
    return AgeGroups
}

function toggleRegion(i: number) {
    const regions: boolean[] = Object.assign([], Main.Instance.PlayerInfo.regions)
    regions[i] = !regions[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { regions })
}

function selectAllRegions(regionsNumber: number[]) {
    // Main.Instance.PlayerInfo.stat !== Stat.Population && regionsNumber[i] === 0
    const regions: boolean[] = Object.assign([], Main.Instance.PlayerInfo.regions)
    for (let i: number = 0; i < regions.length; i++) {
        if (Main.Instance.PlayerInfo.stat !== Stat.Population && regionsNumber[i] === 0) { regions[i] = false } else { regions[i] = true }
    }

    Logic.Type.New(Main.Instance.PlayerInfo, { regions })
}

function deselectAllRegions() {
    const regions: boolean[] = Object.assign([], Main.Instance.PlayerInfo.regions)
    for (let i: number = 0; i < regions.length; i++) {
        regions[i] = false
    }
    Logic.Type.New(Main.Instance.PlayerInfo, { regions })
}

function getRegions(regions: Region[], regionsNumber: number[], regionName: string, countries: CountryData[]): JSX.Element[] {
    const Regions: JSX.Element[] = []
    const RegionsPart: JSX.Element[][] = []
    const Ceil: number = Math.ceil(regions.length / 10)
    for (let i: number = 0; i < Ceil; i++) {
        RegionsPart.push([])
    }
    RegionsPart[0].push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { region: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + (countries.length === 1 ? regionName : Assets.Region) + Assets._plural_).toLowerCase()}
        </button>,
    )

    RegionsPart[0].push(
        <button
            onClick={() => selectAllRegions(regionsNumber)}
            key="-2"
            className="btn btn-danger btn-xs mt3"
        >
            {Assets.All}
        </button>,
    )

    RegionsPart[0].push(
        <button
            onClick={() => deselectAllRegions()}
            key="-3"
            className="btn btn-danger btn-xs mt3"
        >
            {Assets.None}
        </button>,
    )

    const remainder: number = regions.length % 10

    for (let i: number = 0; i < regions.length; i++) {
        let position = -1
        for (let j: number = 0; j < Ceil; j++) {
            if (i < remainder + j * 10) {
                position = j
                break
            }
        }
        if (position > -1) {
            RegionsPart[position].push(
                <button
                    title={regions[i].country + '-' + regions[i].code}
                    disabled={Main.Instance.PlayerInfo.stat !== Stat.Population && regionsNumber[i] === 0}
                    key={i.toString()}
                    onClick={() => toggleRegion(i)}
                    className={Main.Instance.PlayerInfo.regions[i] ? 'btn btn-primary btn-xs mt3' : regionsNumber[i] || Main.Instance.PlayerInfo.stat === Stat.Population ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
                >
                    {regions[i].name}&nbsp;
                    <span className="badge badge-light">{regionsNumber[i]}</span>
                </button>,
            )
        }
    }
    for (let i: number = 0; i < RegionsPart.length; i++) {
        Regions.push(<div className={i == 0 ? 'mt5' : 'mt3'}>{RegionsPart[i]}</div>)
    }
    return Regions
}

function toggleNationality(i: number) {
    const nationalities: boolean[] = Object.assign([], Main.Instance.PlayerInfo.nationalities)
    nationalities[i] = !nationalities[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { nationalities })
}

function getNationalities(nationalities: NationalityData[], nationalitiesNumber: number[]): JSX.Element[] {
    const Nationalities: JSX.Element[] = []
    Nationalities.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { nationality: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.Nationality_s).toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < nationalities.length; i++) {
        Nationalities.push(
            <button
                key={i.toString()}
                disabled={nationalitiesNumber[i] === 0}
                onClick={() => toggleNationality(i)}
                className={Main.Instance.PlayerInfo.nationalities[i] ? 'btn btn-primary btn-xs mt3' : nationalitiesNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {nationalities[i].nationality}&nbsp;
                <span className="badge badge-light">{nationalitiesNumber[i]}</span>
            </button>,
        )
    }
    return Nationalities
}

function toggleCountry(i: number) {
    const countries: boolean[] = Object.assign([], Main.Instance.PlayerInfo.countries)
    countries[i] = !countries[i]
    Logic.Type.New(Main.Instance.PlayerInfo, { countries })
}

function getCountries(countries: CountryData[], countriesNumber: number[]): JSX.Element[] {
    const Countries: JSX.Element[] = []
    Countries.push(
        <button
            onClick={() => Logic.Type.New(Main.Instance.PlayerInfo, { country: false })}
            key="-1"
            className="btn btn-success btn-xs mt3"
        >
            {(Assets.Select + ' ' + Assets.Country_s).toLowerCase()}
        </button>,
    )
    for (let i: number = 0; i < countries.length; i++) {
        Countries.push(
            <button
                disabled={countriesNumber[i] === 0}
                key={i.toString()}
                onClick={() => toggleCountry(i)}
                className={Main.Instance.PlayerInfo.countries[i] ? 'btn btn-primary btn-xs mt3' : countriesNumber[i] ? 'btn btn-default btn-light btn-xs mt3' : 'btn btn-default btn-xs mt3'}
            >
                {countries[i].name}&nbsp;
                <span className="badge badge-light">{countriesNumber[i]}</span>
            </button>,
        )
    }
    return Countries
}

function GetAbbrev(Width: number, regions: Region[], region: boolean, regionName: string): JSX.Element {
    let Regions: Region[] = JSON.parse(JSON.stringify(regions))
    if (Main.Instance.PlayerInfo.c) {
        Regions = Regions.sort((a, b) => a.c > b.c ? 1 : -1)
    }
    const threshold: number = 1500
    return (
        <div className="data-abbrev" style={{ position: 'absolute', width: (80 + (Width - 1108) / 2) + 'px', right: '0px', top: '210px', overflow: 'hidden' }}>
            <ReactTable
                data={Main.Instance.PlayerInfo.c ? Regions : regions}
                // style = {{width: '100%'}}
                // <FontAwesomeIcon icon = {faGlobe}/>
                columns={[
                    { show: !Main.Instance.PlayerInfo.c, width: 28, Header: <div title={Assets.Code + 2}>{Assets.Code.charAt(0) + 2}</div>, accessor: 'code', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title={row.original.name} style={{ width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px' }}>{row.value}</div> },
                    { show: Main.Instance.PlayerInfo.c, width: 28, Header: <div title={Assets.Code + 1}>{Assets.Code.charAt(0) + 1}</div>, accessor: 'c', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title={row.original.name} style={{ width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px' }}>{row.value}</div> },
                    { Header: <div style={{ marginLeft: '3px' }}>{regionName ? capitalizeFirstLetter(regionName) : Assets.Region}</div>, accessor: 'name', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style={{ fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px' }} title={row.value}>{row.value}</div> },
                    { width: Width > threshold ? 70 : 28, Header: <div style={{ marginLeft: '1px' }} title={Width > threshold ? undefined : Assets.Country}>{Width > threshold ? Assets.Country : <i className={faGlobe} />}</div>, accessor: 'country', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title={Width > threshold ? undefined : row.original.Country} style={{ width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'center' }}>{Width > threshold ? row.original.Country : row.value}</div> },
                    { width: 100, Header: <div style={{ marginLeft: '3px' }}>{Main.Instance.PlayerInfo.stat === Stat.Population ? Assets.Value + ' (000\'s)' : Assets.Value}</div>, accessor: 'id', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style={{ width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px' }}>{getValue(row.original.code, row.original.country)}</div> },
                ]}
                showPaginationBottom={false}
                pageSize={regions.length}
                className="-striped -highlight"
            />
        </div>
    )
}

function GetReactTable(Width: number, barDistribution: Array<{ key: string, value: number, key2?: string }>): JSX.Element {
    // const threshold: number = 1500
    return (
        <>
            <div className="data-abbrev" style={{ position: 'absolute', width: '200px', left: '10px', top: '285px', overflow: 'hidden' }}>
                <ReactTable
                    data={barDistribution}
                    // style = {{width: '100%'}}
                    /*
                                    {show: !Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 2}>{Assets.Code.charAt(0) + 2}</div>, accessor: 'code', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                                    {show: Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 1}>{Assets.Code.charAt(0) + 1}</div>, accessor: 'c', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                                    {Header: <div style = {{marginLeft: '3px'}}>{regionName ? capitalizeFirstLetter(regionName) : Assets.Region}</div>, accessor: 'name', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}} title = {row.value}>{row.value}</div>},
                                    {width: Width > threshold ? 70 : 28, Header: <div style = {{marginLeft: '1px'}} title = {Width > threshold ? undefined : Assets.Country}>{Width > threshold ? Assets.Country : <FontAwesomeIcon icon = {faGlobe}/>}</div>, accessor: 'country', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {Width > threshold ? undefined : row.original.Country} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'center'}}>{Width > threshold ? row.original.Country : row.value}</div>},
                                    {width: 100, Header: <div style = {{marginLeft: '3px'}}>{Main.Instance.PlayerInfo.stat === Stat.Population ? Assets.Value + ' (000\'s)' : Assets.Value}</div>, accessor: 'id', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}}>{getValue(row.original.code, row.original.country)}</div>},
                    */
                    columns={[
                        { width: 100, Header: <div style={{ marginLeft: '5px' }}>{Assets.Key}</div>, accessor: 'key', headerClassName: 'th-left data-regions', className: 'td-center data-regions', Cell: (row) => <div style={{ width: '100px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'left', marginLeft: '5px' }}>{row.value}</div> },
                        { width: 100, Header: <div style={{ marginLeft: '5px' }}>{Assets.Value}</div>, accessor: 'value', headerClassName: 'th-left data-regions', className: 'td-center data-regions', Cell: (row) => <div style={{ width: '100px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'left', marginLeft: '5px' }}>{row.value}</div> },
                    ]}
                    showPaginationBottom={false}
                    pageSize={barDistribution.length > 24 ? 24 : barDistribution.length}
                    className="-striped -highlight"
                />
            </div>
            {barDistribution.length > 24 ?
                <div className="data-abbrev" style={{ position: 'absolute', width: '200px', left: '220px', top: '285px', overflow: 'hidden' }}>
                    <ReactTable
                        data={barDistribution.slice(24, barDistribution.length)}
                        // style = {{width: '100%'}}
                        /*
                                    {show: !Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 2}>{Assets.Code.charAt(0) + 2}</div>, accessor: 'code', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                                    {show: Main.Instance.PlayerInfo.c, width: 28, Header: <div title = {Assets.Code + 1}>{Assets.Code.charAt(0) + 1}</div>, accessor: 'c', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {row.original.name} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px'}}>{row.value}</div>},
                                    {Header: <div style = {{marginLeft: '3px'}}>{regionName ? capitalizeFirstLetter(regionName) : Assets.Region}</div>, accessor: 'name', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}} title = {row.value}>{row.value}</div>},
                                    {width: Width > threshold ? 70 : 28, Header: <div style = {{marginLeft: '1px'}} title = {Width > threshold ? undefined : Assets.Country}>{Width > threshold ? Assets.Country : <FontAwesomeIcon icon = {faGlobe}/>}</div>, accessor: 'country', headerClassName: 'th-center data-regions', className: 'td-center data-regions', Cell: (row) => <div title = {Width > threshold ? undefined : row.original.Country} style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'center'}}>{Width > threshold ? row.original.Country : row.value}</div>},
                                    {width: 100, Header: <div style = {{marginLeft: '3px'}}>{Main.Instance.PlayerInfo.stat === Stat.Population ? Assets.Value + ' (000\'s)' : Assets.Value}</div>, accessor: 'id', headerClassName: 'th-left data-regions', className: 'td-left data-regions', Cell: (row) => <div style = {{width: '28px', fontSize: '12px', lineHeight: '12px', height: '12px', marginLeft: '3px'}}>{getValue(row.original.code, row.original.country)}</div>},
                        */
                        columns={[
                            { width: 100, Header: <div style={{ marginLeft: '5px' }}>{Assets.Key}</div>, accessor: 'key', headerClassName: 'th-left data-regions', className: 'td-center data-regions', Cell: (row) => <div style={{ width: '100px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'left', marginLeft: '5px' }}>{row.value}</div> },
                            { width: 100, Header: <div style={{ marginLeft: '5px' }}>{Assets.Value}</div>, accessor: 'value', headerClassName: 'th-left data-regions', className: 'td-center data-regions', Cell: (row) => <div style={{ width: '100px', fontSize: '12px', lineHeight: '12px', height: '12px', textAlign: 'left', marginLeft: '5px' }}>{row.value}</div> },
                        ]}
                        showPaginationBottom={false}
                        pageSize={barDistribution.length - 24}
                        className="-striped -highlight"
                    />
                </div>
                : void 0}
        </>
    )
}

function getValue(region: string, country: string): string {
    // console.log (region)
    // console.log (country)
    // console.log (Main.Instance.DatabaseDataState.orderedStats)
    for (const item of Main.Instance.DatabaseDataState.orderedStats) {
        if (item.region === region && item.country === country) {
            return Main.Instance.PlayerInfo.stat === Stat.Population ? numberWithCommas(item.value / 1000) : numberWithCommas(item.value)
        }
    }
    return '0'
}


function getTitle(): string {
    return (
        Main.Instance.PlayerInfo.stat === Stat.Population ? Assets.Population + ' (' + jackpotsPopulationStatisticsYear + ')'
            : Main.Instance.PlayerInfo.stat === Stat.PlayerRegistration ? injectSpaces(Stat[Stat.PlayerRegistration])
                : Main.Instance.PlayerInfo.stat === Stat.PlayerRegistrationByPopulation ? injectSpaces(Stat[Stat.PlayerRegistrationByPopulation])
                    : '?'
    )
}

function getPopulation(regions: Region[], region: boolean): any {
    // Main.Instance.PlayerInfo.regions
    let n: number = 0
    for (let i: number = 0; i < regions.length; i++) {
        if (!region || Main.Instance.PlayerInfo.regions[i]) {
            n += regions[i].population
        }
    }
    return numberWithCommas(n)
}

function getN(playersSelected: number, regions: Region[], region: boolean): string {
    return (Main.Instance.PlayerInfo.stat === Stat.Population ? getPopulation(regions, region)
        : Main.Instance.PlayerInfo.stat === Stat.PlayerRegistration ? numberWithCommas(playersSelected)
            : Main.Instance.PlayerInfo.stat === Stat.PlayerRegistrationByPopulation ? numberWithCommas(playersSelected)

                : 0).toString()
}

function GetTitle(playersSelected: number, regions: Region[], region: boolean): JSX.Element {
    return (
        <div style={{ position: 'absolute', left: '5px', top: '0px', fontSize: '20px', fontWeight: 900 }}>
            {getTitle() + ' (' + getN(playersSelected, regions, region) + ')'}
        </div>
    )
}

function GetLegend(Width: number): JSX.Element {
    const l: JSX.Element[] = []
    const t: JSX.Element[] = []
    const b: JSX.Element[] = []
    if (Main.Instance.DatabaseDataState.classStatsLow && Main.Instance.DatabaseDataState.classStatsHigh) {
        for (let i: number = 0; i < densityPallette.length; i++) {
            if (Main.Instance.DatabaseDataState.classStatsLow[i] && Main.Instance.DatabaseDataState.classStatsHigh[i]) {
                if (Main.Instance.PlayerInfo.stat !== Stat.PlayerRegistrationByPopulation) { t.push(<div title={Main.Instance.DatabaseDataState.classStatsTitle[i]} key={densityPallette[i] + '_'} className="fl" style={{ fontSize: '10px', width: '41px', textAlign: 'center' }}>{numberWithCommas(Main.Instance.DatabaseDataState.classStatsLow[i])}</div>) }
                l.push(<div title={Main.Instance.DatabaseDataState.classStatsTitle[i]} key={densityPallette[i]} className="fl" style={{ background: densityPallette[i], height: '20px', width: '41px' }}>&nbsp;</div>)
                if (Main.Instance.PlayerInfo.stat !== Stat.PlayerRegistrationByPopulation) { b.push(<div title={Main.Instance.DatabaseDataState.classStatsTitle[i]} key={densityPallette[i] + '__'} className="fl" style={{ fontSize: '10px', width: '41px', textAlign: 'center' }}>{numberWithCommas(Main.Instance.DatabaseDataState.classStatsHigh[i])}</div>) }
            }
        }
    }

    return (
        <div style={{ position: 'absolute', right: '5px', bottom: Main.Instance.PlayerInfo.stat !== Stat.PlayerRegistrationByPopulation ? '0px' : '10px' }}>
            <div className="clearfix">{t}</div>
            <div className="clearfix">{l}</div>
            <div className="clearfix">{b}</div>
        </div>
    )
}

function countryAllowed(code: string): boolean {
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isCountriesAllowed) {
            if (Main.Instance.DatabaseDataState.custom.general.players.countriesAllowed) {
                for (const item of Main.Instance.DatabaseDataState.custom.general.players.countriesAllowed) {
                    if (item === code) { return true }
                }
            }
        } else {
            if (Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed) {
                for (const item of Main.Instance.DatabaseDataState.custom.general.players.countriesDisallowed) {
                    if (item === code) { return false }
                }
                return true
            }
        }
    }

    return false
}

function nationalityAllowed(code: string): boolean {
    if (Main.Instance.DatabaseDataState.custom && Main.Instance.DatabaseDataState.custom.general && Main.Instance.DatabaseDataState.custom.general.players) {
        if (Main.Instance.DatabaseDataState.custom.general.players.isNationalitiesAllowed) {
            if (Main.Instance.DatabaseDataState.custom.general.players.nationalitiesAllowed) {
                for (const item of Main.Instance.DatabaseDataState.custom.general.players.nationalitiesAllowed) {
                    if (item === code) { return true }
                }
            }
        } else {
            if (Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed) {
                for (const item of Main.Instance.DatabaseDataState.custom.general.players.nationalitiesDisallowed) {
                    if (item === code) { return false }
                }
                return true
            }
        }
    }

    return false
}