import {Logic} from '../Type'

export const SOCCER: number = 1
export const ICE_HOCKEY: number = 2
export const BASKETBALL: number = 3
export const TENNIS: number = 4
export const AMERICAN_FOOTBALL: number = 6
export const BASEBALL: number = 11
export const BOXING: number = 17
export const DARTS: number = 22
export const HORSE_RACING: number = 31
export const NASCAR: number = 33
export const MMA: number = 44
export const SNOOKER: number = 39
export const WATERPOLO: number = 42
export const CALL_OF_DUTY: number = 74
export const CSGO: number = 75
export const DOTA2: number = 76
export const LOL: number = 77
export const HOTS: number = 79
export const OVERWATCH: number = 80
export const STARCRAFT2: number = 83
export const ROCKET_LEAGUE: number = 157
export const KING_OF_GLORY: number = 158
export const WARCRAFT3: number = 169
export const BEACH_VOLLEY: number = 555
export const HANDBALL: number = 7
export const RUGBY_UNION: number = 8
export const RUGBY_LEAGUE: number = 19
export const VOLLEYBALL: number = 12
export const FLOORBALL: number = 9
export const BANDY: number = 10
export const CRICKET: number = 13
export const FUTSAL: number = 5
export const AUSSIE_RULES: number = 18
export const BADMINTON: number = 21
export const PESAPALLO: number = 30
export const BEACH_SOCCER: number = 26
export const GOLF: number = 27
export const GREYHOUND_RACING: number = 137

interface CurrentLeague {
    xtid: string
    name?: string
    link?: string
    sport?: string
    region?: string
    league?: string
}

interface CurrentCountry {
    id: number
    name: string
    url: string
    code: string
    code2char: string
    countryTypeId: number
}

export enum MatchStatus {
    GetResults,
    Ongoing,
    NotStarted
}

export interface IOddsData {
    name: string
    odds: number
    outcome: number
    iat?: number
}

export interface Odds {
    odds: IOddsData
}

export interface Odd {
    betid: number
    odds: Odds[]
}

export enum Import {
    Undefined,
    OddsChecker,
    OddsCheckerGolf,
    OddsCheckerHorsesGreyhounds,
    Oddsportal,
/*
    OddsportalHistory,
    OddsportalResults,
    OddsportalTest
*/
}

export interface Match {
    id: number
    sportid: number
    sport: string
    categoryid: number
    country: string
    tournamentid: number
    tournament: string
    homeid: number
    home: string
    awayid: number
    away: string
    date: string
    Date: string
    results: any
    result?: string
    round: number
    count: number
}

export interface Bookmaker {
    id: number
    name: string
    robin_choice: boolean
    isBookmaker: boolean
    isBettingExchange: boolean
    preferredCountryID: boolean
}

export interface Bet {
    id: number
    sportid: number
    name: string
    data: any[]
    oid?: number
    special_value?: number
}

export interface Sport {
    id: number
    name: string
    scope: number
}

export interface LeagueData {
    url?: string
    link?: string
    section?: string
    league?: string

    Sport?: string
    sportid?: number,
    oddstype?: number[],
    period?: number,
    sport?: string
    lastGroupMatchDate?: string
    groups?: string[]
    teams?: string[]
    home?: string
    away?: string
    date?: string
    time?: string
}

export interface League {
    id: number
    data: LeagueData
    disabledResults?: boolean
}

export interface Country {
    id: number
    name: string
    url: string
    code: string
    code2?: string
    countrytype: string
    flag?: string
}

export interface CountryTypes {
    name: string
    count: number
}

export class OddsState extends Logic.Type {
    public bookmakers: Bookmaker[]
    public matches: Match[]
    public matchesPage: number = 1
    public matchesPageSize: number = 1000000
    public bet: Bet[]
    public sport: Sport[]
    public league: League[]
    public countries: Country[]
    public countriesTypes: CountryTypes[]

    public distinctSport: Array<{sport: string}>
    public distinctRegion: Array<{sport: string, region: string}>
    public distinctRegion2: Array<{region: string}>
    public distinctLeague: Array<{sport: string, region: string, league: string}>
    public distinctLeague2: Array<{league: string}>

    public DistinctSport: Array<{sport: string}>
    public DistinctRegion: Array<{sport: string, region: string}>
    public DistinctRegion2: Array<{region: string}>
    public DistinctLeague: Array<{sport: string, region: string, league: string}>
    public DistinctLeague2: Array<{league: string}>
    
    public currentLeague: CurrentLeague[]
    public topLeague: CurrentLeague[]
    public currentCountry: CurrentCountry[]
    
    public clDistinctSport: Array<{sport: string}>
    public clDistinctRegion: Array<{sport: string, region: string}>
    public clDistinctRegion2: Array<{region: string}>
    public clDistinctLeague: Array<{sport: string, region: string, league: string}>
    public clDistinctLeague2: Array<{league: string}>
}