import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets from '../../Assets'
import {Main} from '../../../Logic/Main'
import { Logic } from '../../../Logic/Type'

interface State {
    mode?: boolean
}

export class SelectPlayer extends React.Component <{}, State> {
    constructor(props:any) {
        super(props)
        this.state = {
            // mode: false,
        }
        // this.handleEdit = this.handleEdit.bind(this);
        DbRoutes.wsReinitialize()
    }

    public render() {
        return (
            <div className = "clearfix">
                <h4 className = "fl mr">{Assets.PlayerId}</h4>
                <input
                    value = {Main.Instance.GGRRevenueState.playerId}
                    type = "text"
                    className="form-control"
                    style = {{width: '20%', height: '28px', marginTop: '6px'}}
                    onChange = {(e) => Logic.Type.New (Main.Instance.GGRRevenueState, {playerId: e.target.value})}
                    placeholder = {Assets.PlayerId}
                />
            </div>
        ) 
    }
}