import * as React from 'react'
import {GamesSelection} from './GamesSelection'
import {PlayersSelection} from './PlayersSelection'
import {Modal} from '../../Reusables/Modal'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import * as Assets_ from '../../Assets_'
import {Logic} from '../../../Logic/Type'
import ReactTable from 'react-table'
import * as TableUtils from '../../Reusables/TableUtils'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {/*GetPlayerList,*/
        isUserMaster,
        isUserContractualPartner,
        isUserOperator,
        isUserSiteOwner,
        isUserSystem,
        isUserAdmin,
        isContractualPartnerOperator,
        GetCategoryName,
        GetTypeName,
        GetTypeAttribute,
        FormatSQLArray,
        GetGames,
        IsBuildScandibet,
        utilsTimeValidEmptyNotValid,
        gotoTop
} from '../../../Logic/Utils'
import {FormSelect,
        FormInput,
        FormInputNumber,
        FormInputArea,
        RadioButton3} from '../../Reusables/Inputs'
import {ButtonIcon} from '../../Reusables/Button'

import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import {/*DateRangePicker,*/ SingleDatePicker} from 'react-dates'
import * as moment from 'moment'
import {SimpleLineChart0, SimpleLineChart1, SimpleLineChart2} from '../../Reusables/Chart'
import * as base64 from 'base-64'
// import * as parse from 'csv-parse'
/*
import * as csv-generate from 'csv-generate'
import * as csv-parse from 'csv-parse'
*/
import {Point} from '../../../Logic/Database/DatabaseData'
function updateTime(which: number, text: string) {
    if (text.length > 8) { return }
    for (let i: number = 0; i < text.length; i++) {
    if (text.charAt(i) !== '0' && text.charAt(i) !== '1' && text.charAt(i) !== '2' && text.charAt(i) !== '3' && text.charAt(i) !== '4' && 
        text.charAt(i) !== '5' && text.charAt(i) !== '6' && text.charAt(i) !== '7' && text.charAt(i) !== '8' && text.charAt(i) !== '9' && text.charAt(i) !== ':') {
        return
    }
    }
    if (which === 0) { Logic.Type.New (Main.Instance.CRMPointState, {from_time: text}) } else if (which === 1) { Logic.Type.New (Main.Instance.CRMPointState, {to_time: text}) }
}


function CheckOdds(text:string): boolean {
    let proceed: boolean = true
    let dot: boolean = false
    for (let i: number = 0; proceed && i < text.length; i++) {
        if (text.charAt(i) !== '0' 
        && text.charAt(i) !== '1'
        && text.charAt(i) !== '2'
        && text.charAt(i) !== '3'
        && text.charAt(i) !== '4'
        && text.charAt(i) !== '5'
        && text.charAt(i) !== '6'
        && text.charAt(i) !== '7'
        && text.charAt(i) !== '8'
        && text.charAt(i) !== '9' 
        && text.charAt(i) !== '.') { 
            proceed = false
        } else if (text.charAt(i) === '.' && i === 0) { proceed = false } else if (text.charAt(i) === '.' && dot === true) { proceed = false } else if (text.charAt(i) === '.' && dot === false) { dot = true } else if (i === 0 && text.charAt(i) === '0') { proceed = false }
    }

    return proceed
}

function UpdateOdds(text: string) {

    // console.log (proceed)
    if (CheckOdds(text)) { Logic.Type.New(Main.Instance.CRMPointState, {various:text}) }
}

function SelectNLP(num:number) {
    if (Main.Instance.CRMPointState.nlp === num) {
        Logic.Type.New (Main.Instance.CRMPointState, {nlp: 0})
    } else {
        Logic.Type.New (Main.Instance.CRMPointState, {nlp: num})        
    }
}

function getAchievementsCount() {
    if (Main.Instance.CRMAchievementsState.countCalled === false) {

        if (isUserSystem()) {
            Logic.Type.New(Main.Instance.CRMAchievementsState, {
                countCalled: true, site_id: '0', holding_id: '0', operator_id: '0'})

            DbRoutes.getAchievements()
            DbRoutes.getAchievementsAll()
        } else if (isUserMaster()) {

        } else if (isUserContractualPartner()) {

            Logic.Type.New(Main.Instance.CRMAchievementsState, {countCalled: true, site_id: '0', holding_id: '0'})
            Logic.Type.New(Main.Instance.DatabaseDataState, {sitesOperatorAllStream:[], levelsAllStream:[]})

            if (isUserOperator() || isUserSiteOwner()) {

                DbRoutes.getSubEntityByTypeAll(Assets_.OPERATOR,
                                               parseInt(Main.Instance.LoginState.entityId),
                                               SelectSite)
            }

            // currencyRatesStream
            DbRoutes.getCurrencyRates()

            // currencyStream
            DbRoutes.getCurrencyNames()

            // gamesAll
            // DbRoutes.getGamesAll(Assets.WAGER_PLAN)

            // DbRoutes.countAchievementsByOperator()
/*
            if (parseInt(Main.Instance.CRMAchievementsState.site_id) === 0) {
                DbRoutes.getAchievementsByOperator()
            } else {
                DbRoutes.getAchievementsBySite(Main.Instance.CRMAchievementsState.site_id)
            }
*/
        }
    }
}


function getAchievements() {
    Logic.Type.New (Main.Instance.DatabaseDataState, {achievementsStream: undefined})
}

function clearFields() {

    Logic.Type.New(Main.Instance.CRMAchievementsState, { /*point_type: '0', sub_point_type: '0', points_pcu:1,*/
        name : '', description : '', id : '', mode : false, operator_id:'0',holding_id:'0',site_id:'0',target:'0',
        sublevel_id:'0',level_id:'0', wagering:'100', losses:'0'/*,tournaments_only:false*//*, Mode: false*/})
}

function InsertLastPage() {

    clearFields()
    Logic.Type.New(Main.Instance.CRMAchievementsState, {count:Main.Instance.CRMAchievementsState.count++ })
    LastPage()
}
/*
function DeleteLastPage () {

    clearFields()
    Logic.Type.New(Main.Instance.CRMAchievementsState, {count:Main.Instance.CRMAchievementsState.count-- })
    LastPage()
}

function DeleteNotLastPage () {

    clearFields()
    Logic.Type.New(Main.Instance.CRMAchievementsState, {count:Main.Instance.CRMAchievementsState.count-- })

    getAchievements()
}
*/
function Update() {

    clearFields()

    getAchievements()
}


function gotoInsertMode() {

    if (
    Main.Instance.CRMAchievementsState.name === Main.Instance.CRMAchievementsState.name_saved &&
    Main.Instance.CRMAchievementsState.description === Main.Instance.CRMAchievementsState.description_saved
    // parseInt(Main.Instance.CRMAchievementsState.wagering) === parseInt(Main.Instance.CRMAchievementsState.wagering_saved) &&
    // parseInt(Main.Instance.CRMAchievementsState.target) === parseInt(Main.Instance.CRMAchievementsState.target_saved) &&
    // parseInt(Main.Instance.CRMAchievementsState.losses) === parseInt(Main.Instance.CRMAchievementsState.losses_saved) &&
    // parseInt(Main.Instance.CRMAchievementsState.level_id) === parseInt(Main.Instance.CRMAchievementsState.level_id_saved) &&
    // parseInt(Main.Instance.CRMAchievementsState.sublevel_id) === parseInt(Main.Instance.CRMAchievementsState.sublevel_id_saved)
    // Main.Instance.CRMAchievementsState.custom === Main.Instance.CRMAchievementsState.custom_saved
    ) {
        clearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            clearFields() 
        }                
    }
}

interface Item {
    name:string
    description:string
    id:string
    // custom:string
    operator_id:string
    // currency:any,
    site_id:string,
    holding_id:string
    level_id:string
    sublevel_id:string
    // points_pcu:number
    // bet_size_c:number
    wagering:string
    losses:string
    target:string,
}

function handleInsertUpdate() {
    let items:Item = { name: '', description:'', id:'', /*custom:'{}',*/ operator_id:'', site_id:'',
                     holding_id:'',/*currency:"{}",*/level_id:'',sublevel_id:'', wagering:'', losses:'', target: '',
                     /*,bet_size_c:1,tournaments_only:'false',point_type:'', sub_point_type:'', points_pcu:1*/}

    items = {

        name:       !Main.Instance.CRMAchievementsState.name        ? '' : Main.Instance.CRMAchievementsState.name,
        description:!Main.Instance.CRMAchievementsState.description ? '' : 
                    Main.Instance.CRMAchievementsState.description,
        id:         !Main.Instance.CRMAchievementsState.id          ? '' : Main.Instance.CRMAchievementsState.id,
        wagering:         !Main.Instance.CRMAchievementsState.wagering    ? '100' : 
        Main.Instance.CRMAchievementsState.wagering,
        losses:         !Main.Instance.CRMAchievementsState.losses    ? '0' : Main.Instance.CRMAchievementsState.losses,
        target:         !Main.Instance.CRMAchievementsState.target    ? '0' : Main.Instance.CRMAchievementsState.target,
        operator_id :'',
        site_id :'',
        holding_id :'',
        // currency: "{}",
        // custom:    "{}",
        level_id :'',
        sublevel_id :'',
    }

    if (Main.Instance.CRMAchievementsState.mode === false && 
    parseInt(Main.Instance.CRMAchievementsState.site_id) === 0) {
        alert (Assets.pleaseSelectGamingSite)
        return
        }

    if (isUserSystem()) { 
    } else if (isUserContractualPartner()) {
        items.operator_id = Main.Instance.LoginState.entityId
    }

    items.level_id = Main.Instance.CRMAchievementsState.level_id
    items.sublevel_id = Main.Instance.CRMAchievementsState.sublevel_id

    if (Main.Instance.CRMAchievementsState.mode === false) {
        items.site_id = Main.Instance.CRMAchievementsState.site_id
        DbRoutes.getParentsFromSite(items.site_id,handleInsertUpdate2,items)
    } else {
        handleInsertUpdate2 (items)
    }
}

function handleInsertUpdate2(items:Item) {
    if (Main.Instance.CRMAchievementsState.mode === false) {    
        items.operator_id = Main.Instance.ReusableState.operator_id
        items.holding_id = Main.Instance.ReusableState.holding_id
    }

    if (items.name.length === 0) {

        alert (Assets.compulsoryAchievement)
        return
        }

    if (Main.Instance.CRMAchievementsState.mode === false) {
    // INSERT MODE

        DbRoutes.insertAchievement(items, InsertLastPage)
    } else {
        // UPDATE MODE
        DbRoutes.updateAchievement (items, Update)                
    }
}
/*
function  handleSelect(rowData:string[]) {
    if (rowData) {
        Logic.Type.New (Main.Instance.CRMAchievementsState, {point_program_id: rowData[0]})
    }
}

function  handleDelete(rowData:string[]) {
    if (rowData) {
        if (confirm(Assets.sureToDelete + ' ' + 
            Assets.achievement + ' "' + rowData[2] + '"' + Assets._for_ + 
            Assets.site + ' "' + rowData[1] + '"?')) {

            if (Main.Instance.CRMAchievementsState.pNum === 
                (Math.trunc((Main.Instance.CRMAchievementsState.count - 2) / 
                Main.Instance.CRMAchievementsState.pSize) + 1)) {
                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                
                DbRoutes.removeAchievement (rowData[0].toString(), DeleteLastPage)
            } else {
                DbRoutes.removeAchievement (rowData[0].toString(), DeleteNotLastPage)
            }
        }
    }
}

function handleToggle (rowData:string[]) {
    if (rowData) {
        let field:number
        if (isUserContractualPartner()) {
            field = 6
        } else if (isUserSystem()) {
            field = 11
        }
        if (field) {
            DbRoutes.Toggle('.achievements SET tournaments_only = ', rowData[0], rowData[field], getAchievementsCount)
        }
    }
}

function handleToggleActive (rowData:string[]) {
    if (rowData) {
        if (IsBuildScandibet())
        DbRoutes.Toggle('.point_rules SET active = ', rowData[0], rowData[13], getPointsCount)        
        else DbRoutes.Toggle('.point_rules SET active = ', rowData[0], rowData[20], getPointsCount)
    }
}

function handleToggleGS (rowData:string[]) {
    if (rowData) {
        DbRoutes.Toggle('.point_rules SET change_gs = ', rowData[0], rowData[20], getPointsCount)
    }
}
*/

export function toggleTournamentsOnly(rowData:string[]) {
    if (rowData) {
        let field:number
        if (isUserContractualPartner()) {
            field = 6
        } else if (isUserSystem()) {
            field = 11
        }
        if (field) {
            DbRoutes.Toggle('.achievements SET tournaments_only = ', rowData[0], rowData[field], getAchievementsCount)
        }
    }
}

function toggleActive(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.point_rules SET active = ', id.toString(), value, getPointsCount) }
}

export function toggleGS(id: number, value: string) {
    if (id) { DbRoutes.Toggle('.point_rules SET change_gs = ', id.toString(), value, getPointsCount) }
}

function getSelected(array: boolean[]): number {
    // console.log (array)
    let s: number = 0
    if (array) { for (const item of array) { if (item) { s++ } } }
    return s
}
/*
async function handleEdit(rowData:string[]) {
    if (rowData) {
        // console.log (JSON.stringify(rowData))
        let stateCopy = Object.assign({}, Main.Instance.CRMAchievementsState)
        stateCopy.mode = true
        
        if (isUserContractualPartner()) {
            // console.log (JSON.stringify(rowData))
            stateCopy.id = rowData[0]
            stateCopy.name = rowData[1]
            stateCopy.name_saved = rowData[1]
            stateCopy.description = rowData[2]
            stateCopy.description_saved = rowData[2]
            stateCopy.site_name = rowData[3]
            stateCopy.level_id = rowData[4]
            stateCopy.level_id_saved = rowData[4]
            if (rowData[5]) {
                stateCopy.sublevel_id = rowData[5]
                stateCopy.sublevel_id_saved = rowData[5]
            } else {
                stateCopy.sublevel_id = '0'
                stateCopy.sublevel_id_saved = '0'
            }
            stateCopy.wagering = rowData[6]
            stateCopy.wagering_saved = rowData[6]
            stateCopy.losses = rowData[7]
            stateCopy.losses_saved = rowData[7]
            stateCopy.target = rowData[8]
            stateCopy.target_saved = rowData[8]
            await DbRoutes.getSubLevelsAllByLevel(rowData[4])
        } else if (isUserSystem()) {
            stateCopy.id = rowData[0]
            stateCopy.name = rowData[1]
            stateCopy.name_saved = rowData[1]
            stateCopy.description = rowData[2]
            stateCopy.description_saved = rowData[2]
            stateCopy.site_name = rowData[3]
            stateCopy.level_id = rowData[9]
            stateCopy.level_id_saved = rowData[9]
            if (rowData[10]) {
                stateCopy.sublevel_id = rowData[10]
                stateCopy.sublevel_id_saved = rowData[10]
            } else {
                stateCopy.sublevel_id = '0'
                stateCopy.sublevel_id_saved = '0'        
            }

            stateCopy.wagering = rowData[11]
            stateCopy.wagering_saved = rowData[11]
            stateCopy.losses = rowData[12]
            stateCopy.losses_saved = rowData[12]
            stateCopy.target = rowData[13]
            stateCopy.target_saved = rowData[13]

            await DbRoutes.getLevelsAllBySite(1, rowData[7], false)
            await DbRoutes.getSubLevelsAllByLevel(rowData[9])
        }

        Logic.Type.New(Main.Instance.CRMAchievementsState, stateCopy)
    }
}
*/
function  FirstPage() {
    Logic.Type.New(Main.Instance.CRMAchievementsState,{offset:(0).toString()})

    getAchievements()
    Logic.Type.New(Main.Instance.CRMAchievementsState,{pNum: 0})
}

function PrevPage() {

    if (Main.Instance.CRMAchievementsState.pNum > 0) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {
            offset:((Main.Instance.CRMAchievementsState.pNum - 1) * 
            Main.Instance.CRMAchievementsState.pSize).toString()})
        getAchievements()

        Logic.Type.New(Main.Instance.CRMAchievementsState,{pNum: Main.Instance.CRMAchievementsState.pNum - 1})
    } else {
        LastPage()
    }
}

function NextPage() {

    if ((Main.Instance.CRMAchievementsState.pNum + 1) * Main.Instance.CRMAchievementsState.pSize < 
         Main.Instance.CRMAchievementsState.count) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {
            offset: ((Main.Instance.CRMAchievementsState.pNum + 1) * 
            Main.Instance.CRMAchievementsState.pSize).toString().toString()})
        getAchievements()
        Logic.Type.New(Main.Instance.CRMAchievementsState, {
            pNum: Main.Instance.CRMAchievementsState.pNum + 1})                
    } else {
        FirstPage()
    }
}

function LastPage() {

    if (Main.Instance.CRMAchievementsState.count <= Main.Instance.CRMAchievementsState.pSize) {
        FirstPage()
    } else {
        let npNum: number = 0;

        if (Main.Instance.CRMAchievementsState.count === 0) {
            Logic.Type.New(Main.Instance.CRMAchievementsState,{offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.CRMAchievementsState.count - 1) / 
            Main.Instance.CRMAchievementsState.pSize)
            Logic.Type.New(Main.Instance.CRMAchievementsState, {
                offset:(npNum * Main.Instance.CRMAchievementsState.pSize).toString()})
        }

        getAchievements()
        Logic.Type.New(Main.Instance.CRMAchievementsState,{pNum: npNum})
    }
}

function LossesChangedFixed(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {
            losses:text, wagering:(100-parseInt(text)).toString()})
    }
}

function WageringChangedFixed(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {
            wagering:text, losses:(100-parseInt(text)).toString()})
    }
}

function LossesChanged(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {losses:text})
    }

    DbRoutes.ActionSite('.achievement SET losses = ' + text, Main.Instance.FrontendStoreState.site_id)
}

function WageringChanged(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {wagering:text})
    }
    DbRoutes.ActionSite('.achievement SET wagering = ' + text, Main.Instance.FrontendStoreState.site_id)
}

function TargetChanged(text:string) {
    
//    if (parseInt(text) > -1 && parseInt(text) <= 100) {
    Logic.Type.New(Main.Instance.CRMAchievementsState, {target:text})
//    }
    // console.log ('target:' + text)
    DbRoutes.ActionSite('.achievement SET target = \'' + text + '\'', 
                        Main.Instance.FrontendStoreState.site_id)
}

function targetChanged(text:string) {
    Logic.Type.New(Main.Instance.CRMPointState, {target:text})
}

function lossesChanged(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMPointState, {losses:parseInt(text)})
    }
}

function wageringChanged(text:string) {
    
    if (parseInt(text) > -1 && parseInt(text) <= 100) {
        Logic.Type.New(Main.Instance.CRMPointState, {wagering:parseInt(text)})
    }
}

function RecordsPerPageChanged(text:string) {

    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMAchievementsState, {pSize:parseInt(text)})
        FirstPage()
    }
}

function ApplyMasterSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMAchievementsState,{holding_id:text})

    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState,{
            contractualPartnersAllStream:undefined,
            contractualPartnersAllStream2:undefined,
            sitesOperatorAllStream:undefined,
            /*achievementsAllStream:undefined,*/
            levelsAllStream:undefined,
            levelsAllDetailsStream:undefined,
            sublevelsAllStream:undefined})

        DbRoutes.getAllOperators()
        DbRoutes.getAllBrandOwners() 
        DbRoutes.getExtendedSitesAll()
    } else {
        DbRoutes.getAllOperatorsByMaster(text)
        DbRoutes.getAllBrandOwnersByMaster(text)
        DbRoutes.getExtendedSitesAllByMaster(text)        
    }
}

function ApplyOperatorSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMAchievementsState, {operator_id:text})
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {
            sitesOperatorAllStream:undefined,
            /*achievementsAllStream:undefined,*/
            levelsAllStream:undefined,
            levelsAllDetailsStream:undefined,
            sublevelsAllStream:undefined})

        if (parseInt(Main.Instance.CRMAchievementsState.holding_id) === 0) {
            DbRoutes.getExtendedSitesAll()
        } else {
            DbRoutes.getExtendedSitesAllByMaster(Main.Instance.CRMAchievementsState.holding_id)
        }
    } else {
        if (isContractualPartnerOperator(text)) {
            DbRoutes.getAllSitesByOperator(text)
        } else {
            DbRoutes.getAllSitesBySiteOwner(text)
        }
    }
}

function SelectSite(stream:any[]) {

        if (stream.length === 1) {
            Logic.Type.New(Main.Instance.CRMAchievementsState, {
                operator_id: Main.Instance.LoginState.entityId,
                site_id: stream[0]['id'],
                wagering : '0', 
                losses: '0',
                target: '0',
            })

            DbRoutes.getLevelsAllBySite(1, stream[0]['id'], false)
            DbRoutes.getAchievementsBySite(stream[0]['id'])
            DbRoutes.getAchievementsAllBySite(stream[0]['id'])
            DbRoutes.getLevelsAllDetailsBySite(stream[0]['id'])
            DbRoutes.loadData()
            FirstPage()
        } else {
            DbRoutes.getAchievementsByOperator()
            DbRoutes.getAchievementsAllByOperator()
        }
}

function ApplySiteSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMAchievementsState, {site_id:text})
    if (parseInt(text) === 0) {
        DbRoutes.getAchievementsByOperator()
        DbRoutes.getAchievementsAllByOperator()

    } else {
        DbRoutes.getLevelsAllBySite(1, text,false)
        DbRoutes.getAchievementsBySite(text)
        DbRoutes.getAchievementsAllBySite(text)
        DbRoutes.getLevelsAllDetailsBySite(text)
    }
}

function ApplyLevelSelect(text:string) {

    Logic.Type.New(Main.Instance.CRMAchievementsState, {level_id:text})
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {sublevelsAllStream:undefined})
    } else {
        DbRoutes.getSubLevelsAllByLevel(text)
    }
}

function ApplySubLevelSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMAchievementsState, {sublevel_id:text})
}

function ApplyPointAverageSelect(text:string) {
    
    Logic.Type.New(Main.Instance.CRMPointState, {average_id:text})
}

function ApplyPointBetSizeSelect(text:string) {
    
    Logic.Type.New(Main.Instance.CRMPointState, {bet_size_id:text})
}

function ApplyPointMultiplierSelect(text:string) {
    Logic.Type.New(Main.Instance.CRMPointState, {multiplier_id:text})
    ChangeExample()
}

function ApplyPercentageSelect(percentage:number) {
    Logic.Type.New(Main.Instance.CRMPointState, {percentage})
}

function ApplyPointCategorySelect(text:string) {

    Logic.Type.New(Main.Instance.CRMPointState, {type_id:text})
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {subPointTypeStreamAll:undefined})
    } else {
        ChangeExample()
        DbRoutes.getSubPointTypesByPointType(text)
    }
}

function ApplyPointCategorySelectScandibet(text:string) {
    Logic.Type.New(Main.Instance.CRMPointState, {type_id: text})

    if (Main.Instance.CRMPointState.name !== Assets.DEFAULT) {
        const GamesStreamAllSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        for (let i: number = 0; i < GamesStreamAllSelected.length; i++) {
            GamesStreamAllSelected[i] = false
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})
    }
}

/*
function ApplyPointCategorySelectScandibetNoClear (text:string) {
    Logic.Type.New(Main.Instance.CRMPointState, {type_id: text})
}
*/
/*
function ApplyPointCategorySelectScandibet (text:string) {

    Logic.Type.New(Main.Instance.CRMPointState, {type_id: text})
    if (parseInt(text) === 0) {
        Logic.Type.New(Main.Instance.DatabaseDataState, {subPointTypeStreamAll: undefined})
    } else {
        let spts: {id:string, name: string}[] = []
        if (+text === 3100) {
            spts.push ({id: '3101', name: 'Slots'})
            spts.push ({id: '3102', name: 'Special Slots'})
            spts.push ({id: '3103', name: 'Jackpot'})
            spts.push ({id: '3104', name: 'Videopoker'})
            spts.push ({id: '3105', name: 'Table Games'})
            spts.push ({id: '3106', name: 'Live Casino'})
        } else if (+text === 3200) {
            spts.push ({id: '3201', name: '< 1.1'})
            spts.push ({id: '3202', name: '>= 1.1, < 1.5'})
            spts.push ({id: '3203', name: '>= 1.5, < 2.0'})
            spts.push ({id: '3204', name: '>= 2.0, < 5.0'})
            spts.push ({id: '3205', name: '>= 5.0'})
        }

        if (Main.Instance.CRMPointState.name !== Assets.DEFAULT) {
            let GamesStreamAllSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
            for (let i: number = 0; i < GamesStreamAllSelected.length; i++) {
                GamesStreamAllSelected[i] = false
            }
            Logic.Type.New (Main.Instance.DatabaseDataState, {subPointTypeStreamAll: spts, GamesStreamAllSelected})
            // console.log (Main.Instance.DatabaseDataState.GamesStreamAllSelected)
        } else Logic.Type.New (Main.Instance.DatabaseDataState, {subPointTypeStreamAll: spts})
    }
}
*/
function ApplyPointDetailSelect(text: string) {
    if (IsBuildScandibet()) {
        if (Main.Instance.CRMPointState.name !== Assets.DEFAULT && Math.floor (+text / 100) === 31) {
            // console.log ('came here')
            const GamesStreamAllSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.GamesStreamAllSelected)
            // console.log (Main.Instance.DatabaseDataState.pointTypeStreamDefault)
            let selected: number[] = []
            for (const item of Main.Instance.DatabaseDataState.pointTypeStreamDefault) {
                if (item['detail_id'] === +text) {
                    selected = item['included_games']
                    break
                }
            }

            if (Main.Instance.CRMPointState.auto_include) {
                for (let i: number = 0; i < GamesStreamAllSelected.length; i++) {
                    GamesStreamAllSelected[i] = false
                    for (const Selected of selected) { if (Main.Instance.DatabaseDataState.GamesStreamAll[i]['Id'] === Selected) {
                        GamesStreamAllSelected[i] = true
                        break
                    }
                    }
                }
            } else {
                for (let i: number = 0; i < GamesStreamAllSelected.length; i++) {
                    GamesStreamAllSelected[i] = false
                }
            }

            Logic.Type.New(Main.Instance.CRMPointState, {type2_id: text})
            Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected})

        } else { Logic.Type.New(Main.Instance.CRMPointState, {type2_id: text}) }

    } else { Logic.Type.New(Main.Instance.CRMPointState, {type2_id: text}) }
}

function LevelMinChanged() {
    if(Main.Instance.CRMPointState.level_min === Main.Instance.DatabaseDataState.levelsAllDetailsStream.length) {
        Logic.Type.New(Main.Instance.CRMPointState, {level_min: 0})
    } else if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.CRMPointState.level_min][
        'final_level'].toString() === 'true') {
        Logic.Type.New(Main.Instance.CRMPointState, {
            level_min: Main.Instance.CRMPointState.level_min + 1, sublevel_min: 0})
    } else { Logic.Type.New(Main.Instance.CRMPointState, {level_min: Main.Instance.CRMPointState.level_min + 1}) }
}

function isLevelFinalLevel(level: number): boolean {
    if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[level - 1]['final_level'].toString() === 'true') {
        return true
    } else { return false }
}

function LevelMaxChanged() {
    if(Main.Instance.CRMPointState.level_max === Main.Instance.DatabaseDataState.levelsAllDetailsStream.length) {
        Logic.Type.New(Main.Instance.CRMPointState, {level_max: 0})
    } else if (Main.Instance.DatabaseDataState.levelsAllDetailsStream[
        Main.Instance.CRMPointState.level_max]['final_level'].toString() === 'true') {
        Logic.Type.New(Main.Instance.CRMPointState, {
            level_max: Main.Instance.CRMPointState.level_max + 1, sublevel_max: 0})
    } else { Logic.Type.New(Main.Instance.CRMPointState, {level_max: Main.Instance.CRMPointState.level_max + 1}) }
}

function GetLevelIconText(level:number):string {
    if (level === 0) { return '0' }
    return Main.Instance.DatabaseDataState.levelsAllDetailsStream[level - 1]['name'].charAt(0)
}


function SubLevelMinChanged(num: number) {
    if (num > -1 && num <= 100 && Main.Instance.CRMPointState.level_min !== 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {sublevel_min:num})  
    }
}

function SubLevelMaxChanged(num: number) {
    if (num > -1 && num <= 100 && Main.Instance.CRMPointState.level_max !== 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {sublevel_max:num})
    }
}

function PointsPCUChanged(num: number) {

    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {points_pcu:num})
        ChangeExample()        
    }
}

function PointsBetMinChanged(num: number) {
    
    if (num > -1) {
        Logic.Type.New(Main.Instance.CRMPointState, {bet_min:num})  
    }
}
    
function PointsBetMaxChanged(num: number) {
    
    if (num > -1) {
        Logic.Type.New(Main.Instance.CRMPointState, {bet_max:num})  
    }
}

function PointsBetSizeCChanged(num: number) {
    
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {bet_size_c:num})
        ChangeExample()
    }
}

function AverageCChanged(num: number) {
    
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {average_c:num})
        ChangeExample()
    }
}

function Chart1ValueChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart1_value:num})
        ChangeExample()
    }
}

function Chart2ValueChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart2_value:num})
        ChangeExample()
    }
}

function Chart1StartChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart1_start:num})
    }
}

function Chart1StepChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart1_step:num})
    }
}

function Chart1EndChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart1_stop:num})
    }
}

function Chart2StartChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart2_start:num})
    }
}

function Chart2StepChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart2_step:num})
    }
}

function Chart2EndChanged(num: number) {
    if (num > 0) {
        Logic.Type.New(Main.Instance.CRMPointState, {chart2_stop:num})
    }
}

function ChangeExample() {

    const log10Value1:number = Math.log10(Main.Instance.CRMPointState.chart1_value)
    
    const value1:number = (Main.Instance.CRMPointState.bet_size_c / 1000) ** (log10Value1 + 1)

    const log10Value2 = Math.log10(Main.Instance.CRMPointState.chart2_value)
    
    const value2:number = (Main.Instance.CRMPointState.average_c / 1000) ** (log10Value2 + 1)
    
    const linear_multiplier:number =  parseInt(Main.Instance.CRMPointState.multiplier_id) === 1 ?
                                    Main.Instance.CRMPointState.points_pcu/1000 : 1

    const Value1:string = (Main.Instance.CRMPointState.chart1_value * value1 * linear_multiplier).toFixed(4)
    const Value2:string = (Main.Instance.CRMPointState.chart2_value * value2 * linear_multiplier).toFixed(4)

    Logic.Type.New(Main.Instance.CRMPointState, {
        Value1,
        Value2,
        Value1P: ((parseFloat(Value1) - Main.Instance.CRMPointState.chart1_value)
        *100 / Main.Instance.CRMPointState.chart1_value).toFixed(2),
        Value2P: ((parseFloat(Value2) - Main.Instance.CRMPointState.chart2_value)
        *100 / Main.Instance.CRMPointState.chart2_value).toFixed(2),
    })
}
/*
function DatesChange (startDate:moment.Moment, endDate:moment.Moment) {
    Logic.Type.New(Main.Instance.CRMPointState, {from_date: startDate, to_date: endDate })
}
*/
function DateFromChange(date:moment.Moment) {
    Logic.Type.New(Main.Instance.CRMPointState, {from_date: date})
}

function DateToChange(date:moment.Moment) {
    Logic.Type.New(Main.Instance.CRMPointState, {to_date: date})
}

function IsNewRuleAcceptable(duplicate: boolean): boolean {

    let currency: string = Assets.Any
    let proceed: boolean = true
    const id:number = parseInt(Main.Instance.CRMPointState.currency_id)
    
    if (id !== 0) {
        for (let i:number = 0; i < Main.Instance.DatabaseDataState.currenciesAllStream.length; i++) {
            if (parseInt(Main.Instance.DatabaseDataState.currenciesAllStream[i]['id']) === id) {
                currency = Main.Instance.DatabaseDataState.currenciesAllStream[i]['name'].toString().trim()
            }
        }
    }

    for (const rule of Main.Instance.DatabaseDataState.pointProgramStreamAll) {
        // 1 first we need to filter out the rules that we do not need to examine
        // rules that are not active are excluded
        if (rule['active'].toString() === 'false') { continue }
        // rules that don't have the same category are excluded
        if (parseInt(rule['category_id']) !== parseInt(Main.Instance.CRMPointState.type_id)) { continue } 
        // rules that don't have the same type are excluded.... IN REALITY WE WILL HAVE TO BE VERY CAREFUL HERE
        // if (parseInt(rule['detail_id']) !== parseInt(Main.Instance.CRMPointState.type2_id)) continue
        // rules that don't have the same currency are excluded

        // 2 now we see the partially problematic rules. so if all these are problematic, the rule isn't ok.
        let strikeGeneral: number = 0
        let strike: number = 0
        let STRIKE: number = 0
        let STRIKEGENERAL: number = 4 // date, amount, level/sublevel, currency. target is an exception and is added
        // CURRENCY
        if (rule['currency'] === currency) {
            strikeGeneral++
            // console.log ('strike currency')
        }
        // rules that don't have the same target are excluded
        
        // TARGET
        if (rule['change_gs'].toString() === 'true') {
            STRIKEGENERAL++
            if(parseInt(rule['target_']) === parseInt(Main.Instance.CRMPointState.target)) { strikeGeneral++ }
        }
        // 3 GENERAL we have to compare also DATE and AMOUNT.
        // 3a DATE

        const dateFromOld: string = rule['from_date'].trim()
        const dateToOld: string = rule['to_date'].trim()
        const dateFromNew: string = Main.Instance.CRMPointState.from_date ? 
                                  moment.default(Main.Instance.CRMPointState.from_date).format(Assets_.dateDisplayFormat).trim() : ''
        const dateToNew: string = Main.Instance.CRMPointState.to_date ? 
                                moment.default(Main.Instance.CRMPointState.to_date).format(Assets_.dateDisplayFormat).trim() : ''
            
        // console.log (dateFromOld)
        // console.log (dateToOld)
        // console.log (dateFromNew)
        // console.log (dateToNew)

        if (dateFromOld.length > 0 && dateToOld.length > 0 && dateFromNew.length > 0 && dateToNew.length > 0) {
            if (dateFromNew === dateFromOld && dateToNew === dateToOld) {
                strikeGeneral++ 
                // console.log ('0')
            } else if (dateFromNew < dateFromOld && dateToNew < dateToOld) {
                strikeGeneral++ 
                // console.log ('1')
            } else if (dateFromNew > dateFromOld && dateToNew > dateToOld) {
                strikeGeneral++
                // console.log ('2')
            }

            // if (dateFromNew.length === 0) {strikeGeneral++; console.log ('date from new should exist_')}
            // else if (dateToNew.length === 0) {strikeGeneral++; console.log ('date to new should exist_')}  
        } else if ( dateFromOld.length === 0 && dateFromNew.length === 0 && 
                    dateToOld.length === 0 && dateToNew.length === 0) {
            strikeGeneral++
            // console.log ('3')
        } else if (dateFromOld.length === 0 && dateFromNew.length === 0) {
            if (dateToOld === dateToNew) {
                strikeGeneral++
                // console.log ('4')
            }
        } else if (dateToOld.length === 0 && dateToNew.length === 0) {
            if (dateToOld === dateToNew) {
                strikeGeneral++
                // console.log ('5')
            }
        }

        // 3b AMOUNT
        const amountFromOld: number = parseInt(rule['bet_min'], 10)
        let amountToOld: number = parseInt(rule['bet_max'], 10)
        const amountFromNew: number = Main.Instance.CRMPointState.bet_min
        let amountToNew: number = Main.Instance.CRMPointState.bet_max
        if (amountToOld === 0) { amountToOld = Number.MAX_SAFE_INTEGER }
        if (amountToNew === 0) { amountToNew = Number.MAX_SAFE_INTEGER }
        
        // 3c LEVEL/SUBLEVEL

        const levelMinOld:number = parseInt (rule['level_min'], 10)
        const sublevelMinOld:number = parseInt (rule['sublevel_min'], 10)
        const levelMaxOld:number = parseInt (rule['level_max'], 10)
        const sublevelMaxOld:number = parseInt (rule['sublevel_max'], 10)
        
        const levelMinNew:number = Main.Instance.CRMPointState.level_min
        const sublevelMinNew:number = Main.Instance.CRMPointState.sublevel_min
        const levelMaxNew:number = Main.Instance.CRMPointState.level_max
        const sublevelMaxNew:number = Main.Instance.CRMPointState.sublevel_max
        
        if (levelMinNew === levelMinOld && levelMaxNew === levelMaxOld && 
            sublevelMinNew === sublevelMinOld && sublevelMaxNew === sublevelMaxOld) {
            strikeGeneral++
        } else if (sublevelMinNew < sublevelMinOld && sublevelMaxNew < sublevelMaxOld && 
                   levelMinNew === levelMinOld && levelMaxNew === levelMaxOld) {
            strikeGeneral++
        } else if (sublevelMinNew > sublevelMinOld && sublevelMaxNew > sublevelMaxOld && 
                   levelMinNew === levelMinOld && levelMaxNew === levelMaxOld) {
            strikeGeneral++
        } else if (levelMinNew < levelMinOld && levelMaxNew < levelMaxOld) {
            strikeGeneral++
        } else if (levelMinNew > levelMinOld && levelMaxNew > levelMaxOld) {
            strikeGeneral++
        }

        if (amountFromNew === amountFromOld && amountToNew === amountToOld) {
            strikeGeneral++
            // console.log ('0a')
        } else if (amountFromNew < amountFromOld && amountToNew < amountToOld) {
            strikeGeneral++
            // console.log ('1a')
        } else if (amountFromNew > amountFromOld && amountToNew > amountToOld) {
            strikeGeneral++
            // console.log ('2a')
        }

        // 4 now with special cases...with sports betting the various field (odds) should not be the same
        if (GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
        || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'        
        || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds') {
            STRIKE = 1
            // console.log ('1:' + parseInt(rule['detail_id']))
            // console.log ('2:' + parseInt(Main.Instance.CRMPointState.type2_id))
            if (parseInt(rule['detail_id']) === parseInt(Main.Instance.CRMPointState.type2_id)) {
                // type is the same...we just have to make sure that the odds are not the same
                // if the odds are not the same, then the rule is de facto a new rule and it makes sense to have it
                if (rule['various'].trim() === Main.Instance.CRMPointState.various.trim()) {
                    strike++
                    // console.log ('b')
                }
            } else {
                // type is not the same...now it gets a little more trickier

                const oldType: number = getTypeIdSportsbook(rule['pts_title'])
                const newType: number = getTypeIdSportsbook(GetTypeName(Main.Instance.CRMPointState.type2_id))

                const oddsFromNew: number = getOddsFrom (newType, Main.Instance.CRMPointState.various.trim())
                const oddsFromOld: number = getOddsFrom (oldType, rule['various'].trim())
                const oddsToNew: number = getOddsTo (newType, Main.Instance.CRMPointState.various.trim())
                const oddsToOld: number = getOddsTo (oldType, rule['various'].trim())

                if (oddsFromNew === oddsFromOld && oddsToNew === oddsToOld) {
                    strike++
                    // console.log ('0b')
                } else if (oddsFromNew <= oddsFromOld && oddsToNew <= oddsToOld && oddsToNew >= oddsFromOld) {
                    strike++; 
                    // console.log ('1b')
                } else if (oddsFromNew >= oddsFromOld && oddsToNew >= oddsToOld && oddsFromNew <= oddsToOld) {
                    strike++; 
                    // console.log ('2b')
                }
            }
        }

        // console.log ('strike:' + strike)
        // console.log ('strikeGeneral:' + strikeGeneral)
        if (strike + strikeGeneral < STRIKE + STRIKEGENERAL) { continue }
        // if we still get to this point, the rule is in conflict
        // console.log ('id: ' + Main.Instance.CRMPointState.id)
        if (parseInt(Main.Instance.CRMPointState.id, 10) === parseInt(rule['id'], 10)) {
            // console.log ('item with all the characteristics already exists')
            if (duplicate === false && Main.Instance.CRMPointState.mode) {
                // console.log ('since we are not duplicating and not inserting (we are editing), we do not care!')
                continue
            }
        }
        proceed = false
        // console.log (JSON.stringify(rule))
        // console.log ('i am sorry there was a problem')
        alert (Assets.ProblemWithRule)
        break
    }
    return proceed
}

const MIN_VALUE:number = 0.000001

function getOddsFrom(type: number, Odds: string): number {
    const odds = parseFloat(Odds)
    if (type === 1) { return odds + /*Number.*/MIN_VALUE } else if (type === 2 || type === 3) { return odds } else if (type === 4 || type === 5 || type === 0) { return 1 }
}

function getOddsTo(type: number, Odds: string): number {
    const odds = parseFloat(Odds)
    if (type === 5) { return odds - /*Number.*/MIN_VALUE } else if (type === 4 || type === 3) { return odds } else if (type === 2 || type === 1 || type === 0) { return Number.MAX_SAFE_INTEGER }

}

function getTypeIdSportsbook(type: string) {

    if (type === Assets.GreaterThan) { return Assets.GREATER_THAN } else if (type === Assets.GreaterThanEqual) { return Assets.GREATER_THAN_OR_EQUAL_TO } else if (type === Assets.Equal) { return Assets.EQUAL_TO } else if (type === Assets.LessThanEqual) { return Assets.LESS_THAN_OR_EQUAL_TO } else if (type === Assets.LessThan) { return Assets.LESS_THAN }

    return 0
}

function DuplicatePointRule() {
    // we have to check if inserting the rule is ok
    // check odds first,
    // has to have only number and '.' 
    // has to be >= 1 rule['various'].trim(), 
    // if (CheckOdds (Main.Instance.CRMPointState.various.trim()))
    DbRoutes.getAllPointsByAchievement(IsNewRuleAcceptable, InsertUpdatePoint, true)
    // else alert ({Assets.OddsWrongFormat})
}

function ToggleGS() {
    Logic.Type.New(Main.Instance.Modal,{ModalWagerPlanGames:!Main.Instance.Modal.ModalWagerPlanGames})
    Logic.Type.New(Main.Instance.GamesState, {categorize: false})
}

function TogglePS() {
    Logic.Type.New(Main.Instance.Modal, {ModalOperatorSites: !Main.Instance.Modal.ModalOperatorSites})
}

function CloseChart(num:number) {
    if (num === 0) { Logic.Type.New (Main.Instance.CRMPointState, {chart1:false, chart2:false}) } else if (num === 1) { Logic.Type.New (Main.Instance.CRMPointState, {chart1:false}) } else if (num === 2) { Logic.Type.New (Main.Instance.CRMPointState, {chart2:false}) }
}

export function Achievements() {

    if (Main.Instance.DatabaseDataState.GamesStreamAll && Main.Instance.DatabaseDataState.GamesStreamAllSelected === undefined) {
        const GamesStreamAllSelected: boolean[] = []
        const GamesStreamCategorySelected: number[] = []
        for (let i = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) {
            GamesStreamAllSelected.push (false)
            GamesStreamCategorySelected.push (0)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {GamesStreamAllSelected, GamesStreamCategorySelected})
    }

    if (Main.Instance.DatabaseDataState.playersStream && Main.Instance.DatabaseDataState.playersStreamSelected === undefined) {
        // console.log ('here')
        const playersStreamSelected: boolean[] = []
        // let GamesStreamCategorySelected: number[] = []
        for (let i = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
            playersStreamSelected.push (false)
            // GamesStreamCategorySelected.push (0)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
    }
    // console.log (Main.Instance.DatabaseDataState.playersStreamSelected)

    // if (Main.Instance.DatabaseDataState.GamesStreamAllSelected) console.log (Main.Instance.DatabaseDataState.GamesStreamAllSelected)
/*
    if (!Main.Instance.CRMPointState.dataPlayerListsSorted) {
        let byName = dataPlayerLists.slice(0);
        byName.sort((a,b) => {
            var x = a['name'].toLowerCase();
            var y = b['name'].toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        Logic.Type.New (Main.Instance.CRMPointState, {dataPlayerListsSorted: byName})
    }
*/
    const data0:any[] = []
    const data1:any[] = []
    const data2:any[] = []

    // let Points:number[] = [1, 4, 7, 10, 13, 16, 19, 22, 25]
    let start:number = Main.Instance.CRMPointState.chart1_start
    const step:number = Main.Instance.CRMPointState.chart1_step
    let start2:number = Main.Instance.CRMPointState.chart2_start
    const step2:number = Main.Instance.CRMPointState.chart2_step
    const Points:number[] = []

    if (!Main.Instance.CRMPointState.chart1) {
        while (start2 <= Main.Instance.CRMPointState.chart2_stop) {
            Points.push(start2)
            start2 += step2
        }
    } else {
        while (start <= Main.Instance.CRMPointState.chart1_stop) {
            Points.push(start)
            start += step
        }
    }

    for (let i:number = 0; i < Points.length; i++) {

        const log10:number = Math.log10(Points[i])
        const linear_multiplier:number =  parseInt(Main.Instance.CRMPointState.multiplier_id) === 1 ?Main.Instance.CRMPointState.points_pcu / 1000 : 1

        const C1:number = (Main.Instance.CRMPointState.bet_size_c/1000) ** (log10 + 1)
        const C2:number = (Main.Instance.CRMPointState.average_c/1000) ** (log10 + 1)

        const c1:number = parseFloat((Points[i] * C1 * linear_multiplier).toFixed(4))
        const c2:number = parseFloat((Points[i] * C2 * linear_multiplier).toFixed(4))

        const p1:number = parseFloat(((c1-Points[i])*100 / Points[i]).toFixed(2))
        const p2:number = parseFloat(((c2-Points[i])*100 / Points[i]).toFixed(2))
        
        data0.push ({
            name:Points[i].toString(),
            BetSize:c1,
            PlayerAverageBetSize:c2,
        })

        data1.push ({
            name:Points[i].toString(),
            BetSize:c1,
            BetSizePercent:p1,
        })

        data2.push ({
            name:Points[i].toString(),
            PlayerAverageBetSize:c2,
            PlayerAverageBetSizePercent:p2,
        })        
    }


    let selectOperator:string = Assets.SelectOperatorBrandOwner
    let operatorQ:string = Assets.OperatorBrandOwnerQ

    const selectSite:string = Assets.SelectSiteGamingSite
    const siteQ:string = Assets.SiteGamingSiteQ

    if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0 &&
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2 && 
        Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {

    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream && 
               Main.Instance.DatabaseDataState.contractualPartnersAllStream.length > 0) {
        selectOperator = Assets.SelectOperator
        operatorQ = Assets.OperatorQ
        // selectSite = Assets.SelectSite_
        // siteQ = Assets.SiteQ_
    } else if (Main.Instance.DatabaseDataState.contractualPartnersAllStream2 &&
               Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length > 0) {
        selectOperator = Assets.SelectBrandOwner
        operatorQ = Assets.BrandOwnerQ    
        // selectSite = Assets.SelectSite
        // siteQ = Assets.SiteQ
    }

    if (Main.Instance.DatabaseDataState.sitesOperatorAllStream && 
    parseInt(Main.Instance.CRMAchievementsState.site_id) > 0) {

        Main.Instance.DatabaseDataState.sitesOperatorAllStream.forEach((item:{id:string, name:string}) => {
            if (parseInt(item.id) === parseInt(Main.Instance.CRMAchievementsState.site_id)) {
                if (Main.Instance.CRMAchievementsState.site_name_filter !== item.name) {
                    Logic.Type.New (Main.Instance.CRMAchievementsState,{site_name_filter:item.name})
                }
            }
        })
    }

//    let customArrowIcon:JSX.Element =  <i className = {Assets_.faNextButtonClass + ' ' + Assets.reactDatesGreyColor}/>
    // let customCloseIcon:JSX.Element = <i className = {Assets_.faCancelButtonClass + ' ' + Assets_.reactDatesGreyColor}/>
/*
    if (!Main.Instance.DatabaseDataState.pointTypeStreamAll) {
        getPointsCount()
        return (<div/>)
    }
*/
    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.CRMPointState.from_time)
    const validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.CRMPointState.to_time)

    return (
<div>
    {<Modal isOpen = {Main.Instance.Modal.ModalWagerPlanGames} close = {() => ToggleGS()}>{GamesSelection()}</Modal>}
    {<Modal isOpen = {Main.Instance.Modal.ModalOperatorSites} close = {() => TogglePS()}>{PlayersSelection()}</Modal>}
    
    {/*
    <Modal  isOpen = {Main.Instance.Modal.ModalPointGames}
            right = "0px"
            top = "0px"
            width = "96%"
            background = {Assets.lightBlue}
            marginTop = "1%">

        <div>
            <div className = "clearfix">
                <h3 className = "fl">
                    {Assets.AssignGames}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <small>{games_selected.toString() + Assets._of_ + games_all.toString() + 
                           Assets.games_selected}
                    </small>
                </h3> 

                <RadioButton3   value = {!Main.Instance.CRMPointState.auto_include ? false : 
                                        Main.Instance.CRMPointState.auto_include}
                                ClassName = "input-group-radiobutton3 margin-bottom-sm fl ml5 mr mt25"
                                text = {Assets.AutomaticInclusion} 
                                title = {Assets.AutomaticInclusionDesc}
                                i1 = {Assets_.active}
                                i2 = {Assets_.inactive}
                                onClick = {text=>SetAutoInclude(text)} 
                />

                <a  className ="fr" 
                    onClick = {()=>hideModalGames()}>
                    <i className = {Assets_.faCancelClass}/>
                </a>
            </div>

            <div className = "clearfix">{gamesInput}</div> 

            <div className = "clearfix"/>
                <div className = "mt">
                    <ButtonIcon description = {Assets.SelectAll}
                                click = {() => {selectAllGamesInCategory()}}
                                ClassName = "btn btn-primary btn-sm btn-plus fl mt20"
                                icon = {Assets_.faPlusButtonClass}
                                text = {Assets.SelectAll}
                    />

                    <ButtonIcon description = {Assets.DeselectAll} 
                                click = {() => {deselectAllGamesInCategory()}}
                                ClassName = "btn btn-primary btn-sm btn-plus fl mt20"
                                icon = {Assets_.faMinusButtonClass}
                                text = {Assets.DeselectAll}
                    />
                </div>
                <div className = "clearfix"/>
            </div>
    </Modal>

    <Modal  isOpen = {Main.Instance.Modal.ModalPointCurrency} 
            right = "0px" 
            top = "0px" 
            width = "96%" 
            height = "50%" 
            background = {Assets.lightBlue} 
            marginTop = "1%">
        <div>
            <div>
                <h3 className = "fl">{Assets.AssignPoints}</h3>                
                <a className ="fr" onClick = {()=>hideModalCurrency()}>
                    <i className = {Assets_.faCancelClass}/>
                </a>
            </div>
            <br/>
            
            <div className = "clearfix">

                <AreCurrenciesSet codes = {Main.Instance.CurrencyRatesState.code}
                                  default = {Main.Instance.CRMPointState.main_currency}
                                  validity_array = {Main.Instance.CRMPointState.isNormalInteger ? 
                                                    Main.Instance.CRMPointState.isNormalInteger : 
                                                    [false,false,false,false,false,false,false,false,false,false,false,
                                                    false,false,false,false,false,false,false,false,false,false,false,
                                                    false,false,false,false,false,false,false,false,false,false,false]}
                />
            </div>            

            <div className = "default-currency clearfix" 
                 key = "1001">
                <FormSelect wide = {true} 
                            float = {true}
                            value = {!Main.Instance.CRMPointState.main_currency ? '0' : 
                                    Main.Instance.CRMPointState.main_currency}
                            data = {Main.Instance.DatabaseDataState.currencyStream}
                            code = {true}
                            ClassName = "form-control"
                            o1 = {Assets.EmptyDatabase}
                            o2 = {Assets.SelectDefaultCurrency}
                            onChange = {text => {defaultCurrencySelected(text)}}
                            icon = {Assets_.faCogsClass}/>
                <span className = "fl ml mt5">{Assets.definitionCurrency}</span>
            </div>
            <div className = "clearfix"/>


            <div className="clearfix">{currenciesInput}</div>

            <div className="clearfix"/>
        </div>
    </Modal>
*/}


{/* Start */}


    {/*isUserContractualPartner() ? isUserAdmin() ?
    <h3 className = "level fl">
        {Assets.aeAchievement}
        <span className = "label label-primary label-table fr">
            {Main.Instance.LoginState.entity}
        </span>
    </h3> : 
    <h3 className = "level fl">
        {Assets.vAchievement}
        <span className = "label label-primary label-table fr">
            {Main.Instance.LoginState.entity}
        </span>
    </h3> 
    : null*/}

    {isUserSystem() || isUserMaster() || isUserContractualPartner() ? isUserAdmin() ?
    <h3 className = "level fl" style = {{width: '20%'}}>
        {Assets.cRule}
    </h3> : 
    <h3 className = "level fl" style = {{width: '20%'}}>
        {Assets.vRule}
    </h3>
    : null}

    {isUserSystem() ?
    <FormSelect 
        level = {true}
        value = {!Main.Instance.CRMAchievementsState.holding_id ? '0' : 
                Main.Instance.CRMAchievementsState.holding_id}
        data = {Main.Instance.DatabaseDataState.masterAllStream}
        ClassName = "form-control"
        o1 = {Assets.SelectMaster}
        o2 = {Assets.MasterQ}
        onChange = {(text) => ApplyMasterSelect(text)}
        icon = {Assets_.faMasterClass}
    /> : null }

    {isUserSystem() || isUserMaster() ?
    !Main.Instance.DatabaseDataState.contractualPartnersAllStream ||
    Main.Instance.DatabaseDataState.contractualPartnersAllStream.length === 0 ?
    <FormSelect 
        level = {true}
        value = {!Main.Instance.CRMAchievementsState.operator_id ? '0' :
                Main.Instance.CRMAchievementsState.operator_id}
        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
        ClassName = "form-control"
        o1 = {selectOperator}
        o2 = {operatorQ}
        onChange = {(text) => ApplyOperatorSelect(text)}
        icon = {Assets_.faCompanyClass}
    />
    :
    !Main.Instance.DatabaseDataState.contractualPartnersAllStream2 ||
    Main.Instance.DatabaseDataState.contractualPartnersAllStream2.length === 0 ?
    <FormSelect 
        level = {true}
        value = {!Main.Instance.CRMAchievementsState.operator_id ? '0' :
                Main.Instance.CRMAchievementsState.operator_id}
        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
        ClassName = "form-control"
        o1 = {selectOperator}
        o2 = {operatorQ}
        onChange = {(text) => ApplyOperatorSelect(text)}
        icon = {Assets_.faCompanyClass}
    />
    :
    <FormSelect 
        level = {true}
        value = {!Main.Instance.CRMAchievementsState.operator_id ? '0' :
                Main.Instance.CRMAchievementsState.operator_id}
        data = {Main.Instance.DatabaseDataState.contractualPartnersAllStream}
        data2 = {Main.Instance.DatabaseDataState.contractualPartnersAllStream2}
        label1 = {Assets.OP}
        label2 = {Assets.BO}
        ClassName = "form-control"
        o1 = {selectOperator}
        o2 = {operatorQ}
        onChange = {(text) => ApplyOperatorSelect(text)}
        icon = {Assets_.faCompanyClass}
    />
    : null}

    {isUserContractualPartner() ?
    <span style = {{marginTop:'20px', paddingTop:'7.5px'}} className = "label label-info fl label-table mr">
        {Main.Instance.LoginState.entity}
    </span>         
    : void 0}

    {isUserSystem() || isUserMaster() || isUserContractualPartner() ?
    <FormSelect 
        level = {true}
        value = {!Main.Instance.CRMAchievementsState.site_id ? '0' :
                Main.Instance.CRMAchievementsState.site_id}
        data = {Main.Instance.DatabaseDataState.sitesOperatorAllStream}
        ClassName = "form-control"
        o1 = {selectSite}
        o2 = {siteQ}
        onChange = {(text) => ApplySiteSelect(text)}
        icon = {Assets_.faSubEntityClass}
    />
     : null}

    {Main.Instance.CRMAchievementsState.target && 
    parseInt(Main.Instance.CRMAchievementsState.target) === 1 ?
    void 0 :
    IsBuildScandibet() ? void 0 :
    <FormInputNumber
        description = {Assets.LossesDesc}
        outerDiv = "rows-per-table input-group fr mt20 mr"
        ClassName = "h30 w60"
        value = {Main.Instance.CRMAchievementsState.losses === undefined ? '0' : 
        Main.Instance.CRMAchievementsState.losses}  
        type = "number" 
        min = "0"
        icon = {Assets_.faLastButtonClass}
        onChange = {(text) => LossesChanged(text)}
    />}

    {Main.Instance.CRMAchievementsState.target && 
    parseInt(Main.Instance.CRMAchievementsState.target) === 2 ?
    void 0 :
    IsBuildScandibet() ? void 0 :
    <FormInputNumber
        description = {Assets.WageringDesc}
        outerDiv = "rows-per-table input-group fr mt20 mr"
        ClassName = "h30 w60"
        value = {Main.Instance.CRMAchievementsState.wagering === undefined ? '0' : 
        Main.Instance.CRMAchievementsState.wagering}  
        type = "number" 
        min = "0"
        icon = {Assets_.faNextButtonClass}
        onChange = {(text) => WageringChanged(text)}
    />}

    {IsBuildScandibet() ? void 0 :
    <FormSelect
        level20 = {true}
        titleFixTop = {true}
        floatRight = {true}
        value = {!Main.Instance.CRMAchievementsState.target ? '0' :
                Main.Instance.CRMAchievementsState.target}
        data = {[0]}
        ClassName = "form-control"
        o1 = {Assets.AnyTarget}
        o2 = {Assets.WageringOnly}
        o3 = {Assets.LossesOnly}
        onChange = {(text) => TargetChanged(text)}
        icon = {Assets_.faTargetClass}
    />}

    {IsBuildScandibet() ? void 0 :
    <span
        title = {Assets.GlobalSettings}
        style = {{
            display:'block', 
            float:'right', 
            marginTop:'25px',
            marginRight:'7px',
        }}
    >
        <i style = {{marginTop:'-5px'}} className = {Assets_.faCountryLgClass}/>
    </span>}


    {/* THIS IS DISABLED BECAUSE WE DO NOT WANT TO GO THE MAIN POINT PROGRAM PAGE IN ANY CASE
    <FormSelect 
        floatRight = {true}
        level225 = {true}
        value = {!Main.Instance.CRMAchievementsState.point_program_id ? '0' : 
                Main.Instance.CRMAchievementsState.point_program_id}
        data = {Main.Instance.DatabaseDataState.achievementsAllStream}
        ClassName = "form-control"
        o1 = {Assets.SelectPointProgram}
        o2 = {Assets.PointProgramQ}
        onChange = {text => {ApplyPointProgramSelect(text)}}
        icon = {Assets_.faAchievementClass}
    />
    */}
    <div className = "clearfix"/>
    {/*<br/>*/}
    {parseInt(Main.Instance.CRMAchievementsState.point_program_id) === 0 ?
    <div className = "row admin-users-container" style = {{position: 'relative'}}>
        <div 
            className = {Main.Instance.CRMAchievementsState.mode ? 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel-edit' : 
                        'col-md-12 col-sm-12 col-xs-12 admin-users-panel'}
        >

            <div className = "register-input">
                <div className = "row admin-users-row">
                    <h4>
                        {isUserAdmin() && Main.Instance.CRMAchievementsState.mode ? 
                        Assets.EditAchievement + ': ' + Main.Instance.CRMAchievementsState.name_saved + 
                        Assets._for_ + Main.Instance.CRMAchievementsState.site_name : null}
                        {isUserAdmin() && !Main.Instance.CRMAchievementsState.mode ? 
                        parseInt(Main.Instance.CRMAchievementsState.site_id) > 0 ? 
                        Assets.AddAchievement + Assets._for_ + 
                        Main.Instance.CRMAchievementsState.site_name_filter :
                        Assets.AddAchievement : 
                        null}
                        {!isUserAdmin() ? Assets.aeAchievement : null}
                    </h4>
                    
                    {Main.Instance.CRMAchievementsState.mode ? 
                    <button
                        title = {Assets.discardChanges}
                        onClick = {() => gotoInsertMode()}
                        className = "btn btn-default btn-sm btn-plus fr mr0 mt-5"
                        style = {{position: 'absolute', right : '-9px', top : '-10px'}}
                    >
                        <i className = {Assets_.faCancelButtonClass}/>
                    </button> 
                    : 
                    <span/>}

                </div>

                <div className = "row admin-users-row">
                    <FormInput  
                        value = {!Main.Instance.CRMAchievementsState.name ? '' : 
                                Main.Instance.CRMAchievementsState.name}
                        ClassName = "form-control register-input"
                        type = "text" 
                        onChange = {(text) => {Logic.Type.New(Main.Instance.CRMAchievementsState, {name:text})}}
                        placeholder = {Assets.AchievementName}
                        icon = {Assets_.faAchievementClass}
                    />

                    {isUserSystem() || isUserMaster() || isUserContractualPartner() ?
                    <FormSelect
                        narrow = {true}
                        value = {!Main.Instance.CRMAchievementsState.level_id ? '0' :
                                Main.Instance.CRMAchievementsState.level_id}
                        data = {Main.Instance.DatabaseDataState.levelsAllStream}
                        ClassName = "form-control"
                        o1 = {Assets.SelectLevel}
                        o2 = {Assets.LevelRequirementQ}
                        onChange = {(text) => ApplyLevelSelect(text)}
                        icon = {Assets_.faCogClass}
                    /> : void 0}

                    {(isUserSystem() || isUserMaster() || isUserContractualPartner()) &&
                    Main.Instance.CRMAchievementsState.level_id && 
                    parseInt(Main.Instance.CRMAchievementsState.level_id) > 0
                    ?
                    <FormSelect
                            narrow = {true}
                            value = {!Main.Instance.CRMAchievementsState.sublevel_id ? '0' :
                                    Main.Instance.CRMAchievementsState.sublevel_id}
                            data = {Main.Instance.DatabaseDataState.sublevelsAllStream}
                            ClassName = "form-control"
                            o1 = {Assets.SelectSubLevel}
                            o2 = {Assets.SubLevelRequirementQ}
                            onChange = {(text) => ApplySubLevelSelect(text)}
                            icon = {Assets_.faCogsClass}
                    /> : null}

                    <FormInputNumber
                        description = {Assets.WageringDesc}
                        outerDiv = "rows-per-table input-group fl mr"
                        ClassName = "h30 w60"
                        value = {Main.Instance.CRMAchievementsState.wagering === undefined ? '' : 
                        Main.Instance.CRMAchievementsState.wagering}  
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faPercentageFwClass}
                        onChange = {(text) => WageringChangedFixed(text)}
                    />

                    <FormInputNumber
                        description = {Assets.LossesDesc}
                        outerDiv = "rows-per-table input-group fl"
                        ClassName = "h30 w60"
                        value = {Main.Instance.CRMAchievementsState.losses === undefined ? '' : 
                        Main.Instance.CRMAchievementsState.losses}  
                        type = "number" 
                        min = "0"
                        icon = {Assets_.faPercentageFwClass}
                        onChange = {(text) => LossesChangedFixed(text)}
                    />
                    {/*
                    <RadioButton1   value = {!Main.Instance.CRMAchievementsState.tournaments_only ? false :
                                            Main.Instance.CRMAchievementsState.tournaments_only} 

                                    ClassName = "input-group-radiobutton1 margin-bottom-sm"
                                    text = {Assets.tournamentsOnly} 
                                    title = {Assets.TournamentsOnlyDesc}
                                    i1 = {Assets_.active}
                                    i2 = {Assets_.inactive}
                                    onClick = {text => SetTournamentsOnly(text)}
                    />
                    */}
                    {/*Main.Instance.CRMAchievementsState.mode && !Main.Instance.CRMAchievementsState.tournaments_only ?

                    <ButtonIcon description = {Assets.PointsDesc}
                                click = {() => {
                                    //showModal()
                                    let initialMode = Main.Instance.CRMAchievementsState.Mode
                                    Logic.Type.New(Main.Instance.CRMAchievementsState, {Mode:!initialMode})
                                    //FirstPagePoint()
                                    if (!initialMode) {
                                        getPointsCount()
                                    }
                                }}
                                ClassName = {
                                             Main.Instance.CRMAchievementsState.Mode ?
                                            'btn btn-success btn-sm btn-plus fr mr0' :
                                            'btn btn-default btn-sm btn-plus fr mr0'
                                            }
                                icon = {Assets_.faCreditCardClass} 
                                text = {Assets.PointsProgram}/> : null*/}

                </div>
{/*
                <div className = "row admin-users-row">
                    <FormSelect
                            narrow = {true}
                            value = {!Main.Instance.CRMAchievementsState.point_type ? '0' :
                                    Main.Instance.CRMAchievementsState.point_type}
                            data = {Main.Instance.DatabaseDataState.pointTypeStreamAll}
                            ClassName = "form-control"
                            o1 = {Assets.SelectPointType}
                            o2 = {Assets.PointTypeQ}
                            onChange = {text => {ApplyPointTypeSelect(text)}}
                            icon = {Assets_.faListOlClass}/>

                    <FormSelect
                            narrow = {true}
                            value = {!Main.Instance.CRMAchievementsState.sub_point_type ? '0' :
                                    Main.Instance.CRMAchievementsState.sub_point_type}
                            data = {Main.Instance.DatabaseDataState.subPointTypeStreamAll}
                            ClassName = "form-control"
                            o1 = {Assets.SelectSubPointType}
                            o2 = {Assets.SubPointTypeQ}
                            onChange = {text => {ApplySubPointTypeSelect(text)}}
                            icon = {Assets_.faListOlClass}/>                    
                </div>
*/}
                <div className = "row admin-users-row-area">
                    <div className = "register-input textarea">
                        <FormInputArea 
                            value = {!Main.Instance.CRMAchievementsState.description ? '' :
                                    Main.Instance.CRMAchievementsState.description}
                            ClassName = "form-control register-input"
                            onChange = {(text) => {
                                Logic.Type.New(Main.Instance.CRMAchievementsState, {description:text})}}
                            placeholder = {Assets.AchievementDescriptionOptional} 
                            icon = {Assets_.faCommentClass}
                        />
                    </div>
                </div>

            <div className = "row admin-users-row">
                <ButtonIcon
                    disabled = {!isUserAdmin()}
                    description = {Main.Instance.CRMAchievementsState.mode ? 
                                    Assets.saveChanges : Assets.cAchievement}
                    click = {() => handleInsertUpdate()}
                    ClassName = {Main.Instance.CRMAchievementsState.mode ? 
                                'btn btn-default btn-sm btn-plus fr' : 
                                'btn btn-primary btn-sm btn-plus fr'}
                    Icon = {faSave as IconDefinition}
                    // Icon = {faSave} 
                    text = {Assets.Save}
                />
            </div>
        </div>
        </div>
</div> : void 0}


{parseInt(Main.Instance.CRMAchievementsState.point_program_id) > 0 ?
        <div style = {{padding:'15px'}}>
            <div 
                className = {Main.Instance.CRMPointState.mode ? 
                            'light_green stand_out clearfix' : 
                            'light_grey stand_out clearfix'}
            >
            <div className = "register-input">
                <div className = "row admin-users-row" style = {{position: 'relative'}}>
                <h4 className = "fl">
                    {/*!Main.Instance.CRMPointState.mode ? 
                    Assets.AddPoint + Assets._for_ + Main.Instance.CRMAchievementsState.name :
                    Assets.EditPoint + ': ' + Main.Instance.CRMPointState.name_saved + 
                    Assets._for_ + Main.Instance.CRMAchievementsState.name*/}
                    {!isUserAdmin () ? !Main.Instance.CRMPointState.mode ? 'View Rule' : 'View Rule ' + Main.Instance.CRMPointState.name : 
                    !Main.Instance.CRMPointState.mode ? Assets.AddRule : Assets.EditRule + ' ' + Main.Instance.CRMPointState.name}
                </h4>
                {IsBuildScandibet() ? void 0 :
                <RadioButton3
                    value = {Main.Instance.CRMPointState.active === undefined ? false :
                            Main.Instance.CRMPointState.active}
                    text = ""
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fl width4 mr0" 
                    title = {Assets.ActivityDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {() => Logic.Type.New(Main.Instance.CRMPointState, {active:!Main.Instance.CRMPointState.active})} 
                />}

                {IsBuildScandibet() ? void 0 :               
                <i 
                    style = {{
                        marginRight:'5px', 
                        float: 'left', 
                        marginTop:'9px', 
                        display:'block'}} 
                    className = {Assets_.faCountrylgClass}
                />}
                {IsBuildScandibet() ? void 0 :
                <RadioButton3
                    value = {Main.Instance.CRMPointState.change_gs === undefined ? false :
                            Main.Instance.CRMPointState.change_gs}
                    text = {Assets.changeGS}
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fl" 
                    title = {Assets.ChangeGSDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {() => Logic.Type.New(Main.Instance.CRMPointState, {change_gs:!Main.Instance.CRMPointState.change_gs})}
                />}
                {IsBuildScandibet() ? void 0 :
                Main.Instance.CRMPointState.change_gs !== undefined && Main.Instance.CRMPointState.change_gs === true ?
                <FormSelect 
                    level20 = {true}
                    value = {!Main.Instance.CRMPointState.target ? '0' :
                            Main.Instance.CRMPointState.target}
                    data = {[0]}
                    ClassName = "form-control"
                    o1 = {Assets.AnyTarget}
                    o2 = {Assets.WageringOnly}
                    o3 = {Assets.LossesOnly}
                    onChange = {(text) => targetChanged(text)}
                    icon = {Assets_.faTargetClass}
                />
                : void 0}

                {IsBuildScandibet() ? void 0 :
                Main.Instance.CRMPointState.change_gs !== undefined && Main.Instance.CRMPointState.change_gs === true&&
                (!Main.Instance.CRMPointState.target || 
                Main.Instance.CRMPointState.target && parseInt(Main.Instance.CRMPointState.target) !== 2) ?
                <FormInputNumber      
                    description = {Assets.WageringDesc}
                    outerDiv = "rows-per-table input-group fl mr"
                    ClassName = "h30 w60"
                    value = {Main.Instance.CRMPointState.wagering === undefined ? '0' :
                    Main.Instance.CRMPointState.wagering.toString()}
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faNextButtonClass}
                    onChange = {(text) => wageringChanged(text)}
                /> : void 0}

                {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.change_gs !== undefined && Main.Instance.CRMPointState.change_gs === true&&
                (!Main.Instance.CRMPointState.target ||
                Main.Instance.CRMPointState.target && parseInt(Main.Instance.CRMPointState.target) !== 1) ?
                <FormInputNumber     
                    description = {Assets.LossesDesc}
                    outerDiv = "rows-per-table input-group fl mr"
                    ClassName = "h30 w60"
                    value = {Main.Instance.CRMPointState.losses === undefined ? '0' : 
                    Main.Instance.CRMPointState.losses.toString()}
                    type = "number" 
                    min = "0"
                    icon = {Assets_.faLastButtonClass}
                    onChange = {(text) => lossesChanged(text)}
                /> : void 0}
                
                {/* THIS IS DISABLED BECAUSE WE DO NOT WANT TO GO THE MAIN POINT PROGRAM PAGE IN ANY CASE
                !Main.Instance.CRMAchievementsState.mode && !Main.Instance.CRMPointState.mode ?
                <a 
                    className = "fr" 
                    onClick={() => Logic.Type.New (Main.Instance.CRMAchievementsState, {point_program_id:'0'})}
                >
                    <i className = {Assets_.faCancelClass}/>
                </a> : void 0*/}
                {Main.Instance.CRMPointState.mode ?
                <button
                    style = {{position: 'absolute', top: '-10px', right: '-9px', background: 'transparent', marginRight: '0px', padding: '0px', border: '0px'}}
                    title = {Assets.CloseEditModeDesc} 
                    onClick = {() => PointsInsertMode()} 
                    className = "btn btn-default btn-sm"
                    // icon = {Assets_.faCancelClass} 
                    // text = {Assets.CloseEditMode}
                >
                <i className = {Assets_.faCancelClass}/>
                </button>
                : IsBuildScandibet() ?
                <button
                    style = {{position: 'absolute', top: '0px', right: '0px', marginRight: '0px', padding: '3px 5px'}}
                    title = {Assets.CopyGamesSelectionDesc}
                    onClick = {() => Logic.Type.New (Main.Instance.CRMPointState, {auto_include: !Main.Instance.CRMPointState.auto_include})} 
                    className = {Main.Instance.CRMPointState.auto_include ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'}
                >
                    {Assets.CopyGamesSelection}
                </button>
                : void 0}

                {!IsBuildScandibet() && Main.Instance.CRMPointState.type_id && parseInt(Main.Instance.CRMPointState.type_id) > 0 &&
                Main.Instance.CRMPointState.mode ?
                <button 
                    className = {Main.Instance.CRMPointState.mode ? 'btn btn-default mr' : 'btn btn-default mr0'}
                    style = {{paddingTop:'4px',paddingBottom:'4px', display:'block', float:'right'}}
                    onClick = {() => DuplicatePointRule()}
                >
                    <i className = {Assets_.faCloneClass}/>
                    &nbsp;&nbsp;
                    {Assets.DuplicateRule}
                </button>
                : void 0}

                </div>
                <div className = "row admin-users-row">
                {IsBuildScandibet() ?
                Main.Instance.CRMPointState.name === Assets.DEFAULT ?
                <div className = " fl label label-danger" style = {{fontSize: '16px', padding: '7px', marginRight: '10px'}}>{Assets.DEFAULT}</div>
                :
                <FormInput
                    narrow = {true}
                    value = {!Main.Instance.CRMPointState.name ? '' : 
                            Main.Instance.CRMPointState.name}
                    ClassName = "form-control register-input"
                    type = "text" 
                    onChange = {(text) => text !== Assets.DEFAULT ? Logic.Type.New(Main.Instance.CRMPointState, {name: text}): void 0}
                    placeholder = {Assets.RuleName}
                    icon = {Assets_.faCheckButtonClass}
                /> : void 0}

                {IsBuildScandibet() ?
                <FormSelect
                    value = {!Main.Instance.CRMPointState.type_id ? '0' :
                            Main.Instance.CRMPointState.type_id}
                    data = {[{id: '3100', name: 'Casino'}, {id: '3200', name: 'Sports'}]}
                    ClassName = "form-control"
                    o1 = {Assets.SelectCategory}
                    o2 = {Assets.SelectCategory}
                    onChange = {(text) => ApplyPointCategorySelectScandibet(text)}
                    icon = {Assets_.faCircleClass}
                />
                :
                <FormSelect
                    value = {!Main.Instance.CRMPointState.type_id ? '0' :
                            Main.Instance.CRMPointState.type_id}
                    data = {Main.Instance.DatabaseDataState.pointTypeStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectCategory}
                    o2 = {Assets.CategoryQ}
                    onChange = {(text) => ApplyPointCategorySelect(text)}
                    icon = {Assets_.faCircleClass}
                />}

                {/*
                <div className = "fl mr">
                <DateRangePicker
                    startDate = {Main.Instance.CRMPointState.from_date} // momentPropTypes.momentObj or null,
                    endDate = {Main.Instance.CRMPointState.to_date} // momentPropTypes.momentObj or null,
                    onDatesChange = {({startDate, endDate}) => DatesChange(startDate, endDate)}
                    focusedInput = {Main.Instance.CRMPointState.focusedInput}
                    onFocusChange = {
                        focusedInput => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput:focusedInput})}
                    showDefaultInputIcon = {true}
                    showClearDates = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets.dateDisplayFormatdmy}
                    customArrowIcon = {Assets_.customArrowIcon}
                    customCloseIcon = {Assets_.customCloseIcon}
                    minimumNights = {0}
                />
                </div>
                */}

                {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.type_id && parseInt(Main.Instance.CRMPointState.type_id) > 0 ?
                <>
                <div className = "fl mr">
                <SingleDatePicker
                    id = "datedate1"
                    date = {Main.Instance.CRMPointState.from_date} // momentPropTypes.momentObj or null,
                    onDateChange = {(date) => DateFromChange(date)}
                    focused = {Main.Instance.CRMPointState.focusedInput}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>
                <FormInput
                    validation = {validationFromTime}
                    date = {true}
                    value = {Main.Instance.CRMPointState.from_time}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(0, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
                <div className = "fl mr">
                <SingleDatePicker
                    id = "datedate2"
                    date = {Main.Instance.CRMPointState.to_date} // momentPropTypes.momentObj or null,
                    onDateChange = {(date) => DateToChange(date)}
                    focused = {Main.Instance.CRMPointState.focusedInput2}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput2: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>
                <FormInput
                    validation = {validationToTime}
                    date = {true}
                    value = {Main.Instance.CRMPointState.to_time}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(1, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />                
                </>
                : void 0}

                {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormInputNumber
                    description = {Assets.SublevelMinDesc}
                    // placeholder = {Assets.LevelMin}
                    outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.sublevel_min ? '0' :
                            Main.Instance.CRMPointState.sublevel_min.toString()}
                    type = "number" 
                    min = "0"
                    max = "100" // this is dynamic actually
                    iconTextC = "#fff"
                    background = {Main.Instance.CRMPointState.level_min > 0 ?
                        isLevelFinalLevel(Main.Instance.CRMPointState.level_min) ? '#337ab7' : '#5cb85c' : '#d9534f'}
                    iconText = {GetLevelIconText(Main.Instance.CRMPointState.level_min)}
                    // Main.Instance.CRMPointState.level_min.toString()
                    iconDescription = {Main.Instance.CRMPointState.level_min === 0 ? Assets.MinLevelNotSet :
                                       Assets.Level + ': ' + 
Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.CRMPointState.level_min - 1]['name'] + 
' (' + Main.Instance.CRMPointState.level_min + ')' +
(Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.CRMPointState.level_min - 1]['final_level'] ?
' - ' + Assets.finalLevel: '')}
                    // iconFixedWidth = {true}
                    readOnly = {Main.Instance.DatabaseDataState.levelsAllDetailsStream && 
                                Main.Instance.CRMPointState.level_min === 
                                Main.Instance.DatabaseDataState.levelsAllDetailsStream.length}
                    handleClick = {() => LevelMinChanged()}
                    onChange = {(text) => SubLevelMinChanged(parseInt(text))}
                />
                : void 0}
                {IsBuildScandibet() ? void 0 :
                Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <span style={{display: 'block', float: 'left', marginRight: '10px', fontSize: '20px'}}>-</span>: void 0}
                {IsBuildScandibet() ? void 0 :
                Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormInputNumber
                    description = {Assets.SublevelMaxDesc}
                    // placeholder = {Assets.LevelMax}
                    outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.sublevel_max ? '0' : 
                            Main.Instance.CRMPointState.sublevel_max.toString()}
                    type = "number" 
                    min = "0"
                    max = "100" // this is dynamic actually
                    iconTextC = "#fff"
                    background = {Main.Instance.CRMPointState.level_max > 0 ?
                    isLevelFinalLevel(Main.Instance.CRMPointState.level_max) ? '#337ab7' : '#5cb85c' : '#d9534f'}
                    iconText = {GetLevelIconText(Main.Instance.CRMPointState.level_max)}
                    // iconText = {Main.Instance.CRMPointState.level_max.toString()}
                    iconDescription = {Main.Instance.CRMPointState.level_max === 0 ? Assets.MaxLevelNotSet :
                                       Assets.Level + ': ' + 
Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.CRMPointState.level_max - 1]['name'] + 
' (' + Main.Instance.CRMPointState.level_max + ')' + 
(Main.Instance.DatabaseDataState.levelsAllDetailsStream[Main.Instance.CRMPointState.level_max - 1]['final_level'] ?
' - ' + Assets.finalLevel: '')}
                    readOnly = {Main.Instance.DatabaseDataState.levelsAllDetailsStream &&
                                Main.Instance.CRMPointState.level_max === 
                                Main.Instance.DatabaseDataState.levelsAllDetailsStream.length}
                    handleClick = {() => LevelMaxChanged()}
                    onChange = {(text) => SubLevelMaxChanged(parseInt(text))}
                />
                : void 0}

                {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormSelect
                    narrow = {true}
                    // verynarrow = {true}
                    value = {!Main.Instance.CRMPointState.multiplier_id ? '0' :
                            Main.Instance.CRMPointState.multiplier_id}
                    data = {[0]}
                    ClassName = "form-control"
                    o1 = {Assets.LinearMultiplierQ}
                    o2 = {Assets.ApplyMultiplier}
                    // o3 = {Assets.Mean}
                    onChange = {(text) => ApplyPointMultiplierSelect(text)}
                    icon = {Assets_.faPercentageFwClass}
                />
                : void 0}

                {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0
                && parseInt(Main.Instance.CRMPointState.multiplier_id) !== 0 ?
                <FormInputNumber
                    description = {Assets.LinearDesc} 
                    outerDiv = "rows-per-table input-group fl ml0 pl0"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.points_pcu ? '1000' : 
                            Main.Instance.CRMPointState.points_pcu.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faSelectFwClass}
                    onChange={(text) => PointsPCUChanged(parseInt(text))}
                />
                : void 0}

                {IsBuildScandibet() && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                    <FormSelect
                        // narrow = {true}
                        value = {!Main.Instance.CRMPointState.type2_id ? '0' :
                                Main.Instance.CRMPointState.type2_id}
                        data = {parseInt(Main.Instance.CRMPointState.type_id) === 3100 ? 
                            [{id: '3101', name: Assets.Slots}, {id: '3102', name: Assets.SpecialSlots}, {id: '3103', name: Assets.Jackpot}, 
                            {id: '3104', name: Assets.Videopoker}, {id: '3105', name: Assets.TableGames}, {id: '3106', name: Assets.LiveCasino}]
                            :
                            [{id: '3201', name: '< 1.2'}, {id: '3202', name: '>= 1.2, < 1.5'}, {id: '3203', name: '	>= 1.5, < 2.0'}, 
                            {id: '3204', name: '>= 2.0, < 3.0'}, {id: '3205', name: '>= 3.0, < 5.0'}, {id: '3206', name: '>= 5.0, < 8.0'}, {id: '3207', name: '>= 8.0'}]
                        }
                        ClassName = "form-control"
                        o1 = {Assets.SelectType}
                        o2 = {Assets.TypeQ}
                        onChange = {(text) => ApplyPointDetailSelect(text)}
                        icon = {Assets_.faCircleClass}
                    />
                : void 0}

                {IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type2_id) !== 0 ?
                <FormInputNumber
                    description = "Points percentage"
                    // placeholder = {Assets.BetMin}
                    outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                    ClassName = "h30 w90px"
                    value = {Main.Instance.CRMPointState.percentage === undefined ? '0' : 
                            Main.Instance.CRMPointState.percentage.toString()}
                    type = "number" 
                    min = "0"
                    iconText = "%"
                    onChange = {(text) => ApplyPercentageSelect(parseInt(text))}
                /> : void 0}

                {IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type_id) === 3100 /*&& (parseInt(Main.Instance.CRMPointState.type2_id) === 3101
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3102 || parseInt(Main.Instance.CRMPointState.type2_id) === 3103 || parseInt(Main.Instance.CRMPointState.type2_id) === 3104
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3105 || parseInt(Main.Instance.CRMPointState.type2_id) === 3106)*/ ?
                <button
                    className = "btn btn-default btn-sm fl mr0"
                    style = {{display: 'block'}}
                    onClick = {() => ToggleGS()}
                >
                    {Assets.GamesSelection}
                </button> : void 0}

                {IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type_id) === 3100 /*&& (parseInt(Main.Instance.CRMPointState.type2_id) === 3101
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3102 || parseInt(Main.Instance.CRMPointState.type2_id) === 3103 || parseInt(Main.Instance.CRMPointState.type2_id) === 3104
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3105 || parseInt(Main.Instance.CRMPointState.type2_id) === 3106)*/ ?
                <span
                    className="label label-success table-label2 mt2"
                    style = {{background: '#5cb85c', display: 'block'}}
                >
                    {/*Assets.selected*/}
                    {Main.Instance.GamesState.selected !== undefined ? getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected) : 0}
                </span> : void 0}

                {/*IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type_id) === 3100 ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{background:'#5cb85c', display: 'block'}}
                >
                    {Main.Instance.GamesState.count ?
                    (100 * getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected) /
                    Main.Instance.GamesState.count).toFixed(1) : 0}
                    %
                    </span> : void 0*/}

                {IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type_id) === 3100 /*&& (parseInt(Main.Instance.CRMPointState.type2_id) === 3101
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3102 || parseInt(Main.Instance.CRMPointState.type2_id) === 3103 || parseInt(Main.Instance.CRMPointState.type2_id) === 3104
                || parseInt(Main.Instance.CRMPointState.type2_id) === 3105 || parseInt(Main.Instance.CRMPointState.type2_id) === 3106)*/ ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{float: 'left', display: 'block'}}
                >
                    {Assets.Games.toLowerCase() + ': '}
                    {Main.Instance.GamesState.count ? Main.Instance.GamesState.count : 0}
                </span> : void 0}

                {IsBuildScandibet() && parseInt(Main.Instance.CRMPointState.type_id) > 0 ?
                <RadioButton3
                    value = {Main.Instance.CRMPointState.active === undefined ? false : Main.Instance.CRMPointState.active}
                    text = "active"
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr0" 
                    title = {Assets.ActivityDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {(text) => { Logic.Type.New(Main.Instance.CRMPointState, {active:!Main.Instance.CRMPointState.active})}} 
                />: void 0}
                <div className = "clearfix"/>
            </div>

            {IsBuildScandibet() && Main.Instance.CRMPointState.type2_id && parseInt(Main.Instance.CRMPointState.type2_id) > 0 && Main.Instance.CRMPointState.name !== Assets.DEFAULT ?
                <div className = "row admin-users-row">
    
                <div style = {{float: 'left', marginRight: '10px'}}>
                    <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[0]}</div>
                    <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[1]}</div>
                </div>

                <input
                    accept = "text/csv, text/plain" 
                    id = "players-csv" 
                    className = "UploadShopItemWrapper" 
                    type = "file" 
                    style = {{float: 'left', marginRight: '10px'}}
                    onChange = {(event) => { 
                        const cloneFile: File = event.target.files[0]
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            const decodedData: string = base64.decode((reader.result as string).replace('data:text/csv;base64,',''))
                            const data:string[] = decodedData.split('\n')
                            const playersStreamSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.playersStreamSelected)
                            for (let i: number = 0; i < playersStreamSelected.length; i++) {
                                playersStreamSelected[i] = false
                                for (const Data of data) { if (Data.trim().length > 0 && +Main.Instance.DatabaseDataState.playersStream[i]['player'] === parseInt(Data.trim())) {
                                    playersStreamSelected[i] = true
                                    break
                                }
                                }
                            }
                            
                            Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
                        }
                        reader.readAsDataURL(cloneFile);
                    }}
                    onClick = {(event) => {event.target = null}}
                />

                {Main.Instance.DatabaseDataState.playersStream ?
                <button
                    className = "btn btn-default btn-sm fl mr0"
                    style = {{display: 'block'}}
                    onClick = {() => TogglePS()}
                >
                    {Assets.PlayersSelection}
                </button> : void 0}

                {Main.Instance.DatabaseDataState.playersStream ?
                <span
                    className="label label-success table-label2 mt2"
                    style = {{background: '#5cb85c', display: 'block'}}
                >
                    {Assets.selected}
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined  ?
                    getSelected(Main.Instance.DatabaseDataState.playersStreamSelected) : 0}
                </span> : void 0}

                {Main.Instance.DatabaseDataState.playersStream ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{background:'#5cb85c', display: 'block'}}
                >
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined ?
                    (100 * getSelected(Main.Instance.DatabaseDataState.playersStreamSelected) /
                    Main.Instance.DatabaseDataState.playersStream.length).toFixed(3) : 0.0}
                    %
                </span> : void 0}

                {Main.Instance.DatabaseDataState.playersStream ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{float: 'left', display: 'block'}}
                >
                    {Assets.Players.toLowerCase() + ': '}
                    {Main.Instance.DatabaseDataState.playersStream.length ?
                    Main.Instance.DatabaseDataState.playersStream.length : 0}
                </span> : void 0}

                <div className = "clearfix"/>
            </div> : void 0}

            {IsBuildScandibet() && Main.Instance.CRMPointState.type2_id && parseInt(Main.Instance.CRMPointState.type2_id) > 0 && Main.Instance.CRMPointState.name !== Assets.DEFAULT ?
            <div className = "row admin-users-row">
                <div className = "fl mr">
                <SingleDatePicker
                    id = "datedate1"
                    date = {Main.Instance.CRMPointState.from_date} // momentPropTypes.momentObj or null,
                    onDateChange = {(date) => DateFromChange(date)}
                    focused = {Main.Instance.CRMPointState.focusedInput}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>

                <FormInput
                    narrow = {true}
                    validation = {validationFromTime}
                    date = {true}
                    value = {Main.Instance.CRMPointState.from_time}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(0, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />

                <div className = "fl mr">
                <SingleDatePicker
                    id = "datedate2"
                    date = {Main.Instance.CRMPointState.to_date} // momentPropTypes.momentObj or null,
                    onDateChange = {(date) => DateToChange(date)}
                    focused = {Main.Instance.CRMPointState.focusedInput2}
                    onFocusChange = {(focused) => Logic.Type.New(Main.Instance.CRMPointState, {focusedInput2: focused.focused})}
                    showDefaultInputIcon = {true}
                    showClearDate = {true}
                    isOutsideRange = {() => false}
                    displayFormat = {Assets_.dateDisplayFormatdmy}
                    customCloseIcon = {Assets_.customCloseIcon}
                    numberOfMonths = {1}
                />
                </div>

                <FormInput
                    narrow = {true}
                    validation = {validationToTime}
                    date = {true}
                    value = {Main.Instance.CRMPointState.to_time}
                    ClassName = "form-control register-input" 
                    type = "text" 
                    onChange = {(text) => updateTime(1, text)}
                    placeholder = {Assets.Time}
                    icon = {Assets_.faTimeNarrowClass}
                />
            </div> : void 0}

            <div className = "row admin-users-row">
            {IsBuildScandibet() ? void 0 : Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormSelect
                    // narrow = {true}
                    value = {!Main.Instance.CRMPointState.type2_id ? '0' :
                            Main.Instance.CRMPointState.type2_id}
                    data = {Main.Instance.DatabaseDataState.subPointTypeStreamAll}
                    ClassName = "form-control"
                    o1 = {Assets.SelectType}
                    o2 = {Assets.TypeQ}
                    onChange = {(text) => ApplyPointDetailSelect(text)}
                    icon = {Assets_.faCircleOClass}
                />
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0
            // && getTypeIdSportsbook(GetTypeName(Main.Instance.CRMPointState.type2_id)) > 0
            && GetTypeAttribute(Main.Instance.CRMPointState.type2_id, Assets_.ATTR.ODDS_TYPE, false)
            ?
            <FormInput  
                value = {!Main.Instance.CRMPointState.various ? '' : 
                        Main.Instance.CRMPointState.various}
                ClassName = "form-control register-input"
                type = "text"
                veryNarrow = {true}
                onChange = {(text) => UpdateOdds(text)}
                placeholder = {Assets.Odds} 
                description = {Assets.OddsDesc}
                icon = {Assets_.faFootballClass}
            />
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0 ? 
                <FormSelect
                    verynarrow = {true}
                    value = {!Main.Instance.CRMPointState.currency_id ? '0' : 
                            Main.Instance.CRMPointState.currency_id}
                    data = {Main.Instance.DatabaseDataState.currenciesAllStream}
                    ClassName = "form-control"
                    o1 = {Assets.AnyCurrency}
                    o2 = {Assets.AnyCurrency}
                    onChange = {(text) => Logic.Type.New (Main.Instance.CRMPointState, {currency_id:text})}
                    icon = {Assets_.faPaymentClass}
                />
                : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0 ?
            <FormInputNumber
                description = {Assets.BetMinDesc}
                // placeholder = {Assets.BetMin}
                outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                ClassName = "h30 w70"
                value = {!Main.Instance.CRMPointState.bet_min ? '0' : 
                        Main.Instance.CRMPointState.bet_min.toString()}
                type = "number" 
                min = "0"
                iconText = ">"
                onChange={(text) => PointsBetMinChanged(parseInt(text))}
            />
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0 ?
            <FormInputNumber
                description = {Assets.BetMaxDesc}
                // placeholder = {Assets.BetMax}
                outerDiv = "rows-per-table input-group fl ml0 pl0 mr ml"
                ClassName = "h30 w70"
                value = {!Main.Instance.CRMPointState.bet_max ? '0' : 
                        Main.Instance.CRMPointState.bet_max.toString()}
                type = "number" 
                min = "0"
                iconText = "<"
                onChange = {(text) => PointsBetMaxChanged(parseInt(text))}
            />
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0 ? 
            <span
                style = {{
                    float:'left',
                    display:'block',
                    fontWeight: 700,
                    marginTop: '5px',
                    marginRight:'5px',
                }}
            >
                {Assets.mainCurrency + ':'}
            </span>
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino'
            ) && parseInt(Main.Instance.CRMPointState.type2_id) > 0 ? 
            <span 
                className = "label label-info"
                style = {{
                    float: 'left',
                    display: 'block',
                    marginTop: '2px',
                    fontWeight: 700,
                    fontSize: '16px',
                    paddingTop: '6px',
                }}
            >
                {Main.Instance.DatabaseDataState.mainCurrency}
            </span>
            : void 0}
            </div>

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino') ?
            <div className = "row admin-users-row">
            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormSelect
                    // narrow = {true}
                    handleClick = {() => SelectNLP(1)}
                    iconTextC = "#fff"
                    Background = {Main.Instance.CRMPointState.nlp === 1 ? '#5cb85c' : '#d9534f'}
                    value = {!Main.Instance.CRMPointState.bet_size_id ? '0' :
                            Main.Instance.CRMPointState.bet_size_id}
                    data = {[0]}
                    ClassName = "form-control"
                    o1 = {Assets.BetSizeMultiplierQ}
                    o2 = {Assets.ApplyBSM}
                    // o3 = {Assets.Mean}
                    onChange = {(text) => ApplyPointBetSizeSelect(text)}
                    icon = {Assets_.faPercentageFwClass}
                />
                : void 0}
                
            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
            && parseInt(Main.Instance.CRMPointState.bet_size_id) !== 0 ?
                <FormInputNumber
                    description = {Assets.BetSizeMultiplierDesc} 
                    outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.bet_size_c ? '1000' : 
                            Main.Instance.CRMPointState.bet_size_c.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faSelectFwClass}
                    onChange = {(text) => PointsBetSizeCChanged(parseInt(text))}
                />
                : void 0}

            {Main.Instance.CRMPointState.chart1 ?
            <FormInputNumber
                description = {''} 
                outerDiv = "rows-per-table input-group fr ml0 pl0"
                ClassName = "h30 w60"
                value = {!Main.Instance.CRMPointState.chart1_stop ? '100' : 
                        Main.Instance.CRMPointState.chart1_stop.toString()}
                type = "number" 
                min = "1"
                icon = {Assets_.faStopClass}
                onChange = {(text) => Chart1EndChanged(parseInt(text))}
            />
            : void 0}

            {Main.Instance.CRMPointState.chart1 ?
            <FormInputNumber
                description = {''} 
                outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                ClassName = "h30 w60"
                value = {!Main.Instance.CRMPointState.chart1_step ? '3' : 
                        Main.Instance.CRMPointState.chart1_step.toString()}
                type = "number" 
                min = "1"
                icon = {Assets_.faStepClass}
                onChange = {(text) => Chart1StepChanged(parseInt(text))}
            />
            : void 0}

            {Main.Instance.CRMPointState.chart1 ?
            <FormInputNumber
                description = {''} 
                outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                ClassName = "h30 w60"
                value = {!Main.Instance.CRMPointState.chart1_start ? '1' : 
                        Main.Instance.CRMPointState.chart1_start.toString()}
                type = "number" 
                min = "1"
                icon = {Assets_.faStartClass}
                onChange = {(text) => Chart1StartChanged(parseInt(text))}
            />
            : void 0}

            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
            && Main.Instance.CRMPointState.chart1_value !== 0 && 
            parseInt(Main.Instance.CRMPointState.bet_size_id) !== 0 ?
            <button 
                className = {Main.Instance.CRMPointState.chart1 ? 'btn btn-success btn-sm fr mr' : 
                'btn btn-default btn-sm fr mr'}
                onClick = { () => {
                    const element = document.getElementById('width')
                    const width = element ? element.offsetWidth : 1200
                    Logic.Type.New (Main.Instance.CRMPointState, {
                        chartWidth: width,
                        chart1:!Main.Instance.CRMPointState.chart1})
                    }
                }
            >
                <i className = {Assets_.faLineClass}/>
            </button>
            : void 0}

            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
                && Main.Instance.CRMPointState.chart1_value !== 0 && 
                parseInt(Main.Instance.CRMPointState.bet_size_id) !== 0 ?
                <FormInput
                    veryNarrow = {true}
                    floatRight = {true}
                    value = {!Main.Instance.CRMPointState.Value1P ? '' : 
                            Main.Instance.CRMPointState.Value1P + ' %'}
                    ClassName = "form-control register-input"
                    type = "text"
                    readOnly = {true}
                    onChange = {(text) => {Logic.Type.New(Main.Instance.CRMPointState, {Value1P:text})}}
                    placeholder = ""
                    icon = {Assets_.faPercentageFwClass}
                />
                : void 0}

            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
            && Main.Instance.CRMPointState.chart1_value !== 0 && 
            parseInt(Main.Instance.CRMPointState.bet_size_id) !== 0 ?
                <FormInput
                    veryNarrow = {true}
                    floatRight = {true}
                    value = {!Main.Instance.CRMPointState.Value1 ? '' : 
                            Main.Instance.CRMPointState.Value1}
                    ClassName = "form-control register-input"
                    type = "text" 
                    readOnly = {true}
                    onChange = {(text) => {Logic.Type.New(Main.Instance.CRMPointState, {Value1:text})}}
                    placeholder = ""
                    icon = {Assets_.faNextButtonClass}
                />
                : void 0}

            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0
            && parseInt(Main.Instance.CRMPointState.bet_size_id) !== 0 ?
                <FormInputNumber
                    outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.chart1_value ? '10' : 
                            Main.Instance.CRMPointState.chart1_value.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faPaymentClass}
                    onChange = {(text) => Chart1ValueChanged(parseInt(text))}
                />
                : void 0}
            </div>
            : void 0}

            {(GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'fixed + live odds'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'casino'
            || GetCategoryName(Main.Instance.CRMPointState.type_id).toLowerCase() === 'live casino') ?
            <div className = "row admin-users-row">
            {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 ?
                <FormSelect
                    // narrow = {true}
                    handleClick = {() => SelectNLP(2)}
                    iconTextC = "#fff"
                    Background = {Main.Instance.CRMPointState.nlp === 2 ? '#5cb85c' : '#d9534f'}
                    value = {!Main.Instance.CRMPointState.average_id ? '0' :
                            Main.Instance.CRMPointState.average_id}
                    data = {[0]}
                    ClassName = "form-control"
                    o1 = {Assets.SelectAverageType}
                    o2 = {Assets._Mean}
                    o3 = {Assets._Median}
                    onChange = {(text) => {ApplyPointAverageSelect(text)}}
                    icon = {Assets_.faPercentageFwClass}
                />
                : void 0}

                {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 && 
                parseInt(Main.Instance.CRMPointState.average_id) !== 0 ?
                <FormInputNumber
                    description = {Assets.AverageMultiplierDesc} 
                    outerDiv = "rows-per-table input-group fl ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.average_c ? '1000' : 
                            Main.Instance.CRMPointState.average_c.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faSelectFwClass}
                    onChange={(text) => AverageCChanged(parseInt(text))}
                />
                : void 0}

                {Main.Instance.CRMPointState.chart2 ?
                <FormInputNumber
                    description = "" 
                    outerDiv = "rows-per-table input-group fr ml0 pl0"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.CRMPointState.chart2_stop ? '100' : 
                            Main.Instance.CRMPointState.chart2_stop.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faStopClass}
                    onChange = {(text) => Chart2EndChanged(parseInt(text))}
                />
                : void 0}

                {Main.Instance.CRMPointState.chart2 ?
                <FormInputNumber
                    description = "" 
                    outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.CRMPointState.chart2_step ? '3' : 
                            Main.Instance.CRMPointState.chart2_step.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faStepClass}
                    onChange = {(text) => Chart2StepChanged(parseInt(text))}
                />
                : void 0}

                {Main.Instance.CRMPointState.chart2 ?
                <FormInputNumber
                    description = ""
                    outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                    ClassName = "h30 w60"
                    value = {!Main.Instance.CRMPointState.chart2_start ? '1' : 
                            Main.Instance.CRMPointState.chart2_start.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faStartClass}
                    onChange = {(text) => Chart2StartChanged(parseInt(text))}
                />
                : void 0}

                {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
                && Main.Instance.CRMPointState.chart2_value !== 0 && 
                parseInt(Main.Instance.CRMPointState.average_id) !== 0 ?
                <button 
                    className = {Main.Instance.CRMPointState.chart2 ? 'btn btn-success btn-sm fr' : 
                    'btn btn-default btn-sm fr'}
                    onClick = { () => {
                        const element = document.getElementById('width')
                        const width = element ? element.offsetWidth : 1200
                        Logic.Type.New (Main.Instance.CRMPointState, {
                            chartWidth: width,
                            chart2:!Main.Instance.CRMPointState.chart2})
                        }
                    }
                >
                    <i className = {Assets_.faLineClass}/>
                </button>
                : void 0}

                {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
                && Main.Instance.CRMPointState.chart2_value !== 0
                && parseInt(Main.Instance.CRMPointState.average_id) !== 0
                ?
                <FormInput
                    veryNarrow = {true}
                    floatRight = {true}
                    value = {!Main.Instance.CRMPointState.Value2P ? '' : 
                            Main.Instance.CRMPointState.Value2P + ' %'}
                    ClassName = "form-control register-input"
                    type = "text" 
                    readOnly = {true}
                    onChange = {(text) => {Logic.Type.New(Main.Instance.CRMPointState, {Value2P:text})}}
                    placeholder = ""
                    icon = {Assets_.faPercentageFwClass}
                />
                : void 0}

                {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
            && Main.Instance.CRMPointState.chart2_value !== 0
            && parseInt(Main.Instance.CRMPointState.average_id) !== 0
            ?
                <FormInput
                    veryNarrow = {true}
                    floatRight = {true}
                    value = {!Main.Instance.CRMPointState.Value2 ? '' : 
                            Main.Instance.CRMPointState.Value2}
                    ClassName = "form-control register-input"
                    type = "text" 
                    readOnly = {true}
                    onChange = {(text) => {Logic.Type.New(Main.Instance.CRMPointState, {Value2:text})}}
                    placeholder = ""
                    icon = {Assets_.faNextButtonClass}
                />
                : void 0}

                {Main.Instance.CRMPointState.type_id && parseInt (Main.Instance.CRMPointState.type_id) > 0 
                && parseInt(Main.Instance.CRMPointState.average_id) !== 0 ?

                <FormInputNumber
                    outerDiv = "rows-per-table input-group fr ml0 pl0 mr"
                    ClassName = "h30 w70"
                    value = {!Main.Instance.CRMPointState.chart2_value ? '10' : 
                            Main.Instance.CRMPointState.chart2_value.toString()}
                    type = "number" 
                    min = "1"
                    icon = {Assets_.faPaymentClass}
                    onChange = {(text) => Chart2ValueChanged(parseInt(text))}
                />                
                : void 0}
            </div>
            : void 0}

            {/*IsBuildScandibet() && Main.Instance.CRMPointState.type_id && parseInt(Main.Instance.CRMPointState.type_id) > 0 ?
            <div className = "row admin-users-row">
                <RadioButton3
                    value = {Main.Instance.CRMPointState.player_list === undefined ? false :
                            Main.Instance.CRMPointState.player_list}
                    text = "players selection"
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr0" // fl mr
                    title = {Assets.playerList}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {text => { Logic.Type.New(Main.Instance.CRMPointState, {
                        player_list:!Main.Instance.CRMPointState.player_list})}}
                />

                {Main.Instance.CRMPointState.player_list ?
                <button
                    className = "btn btn-default btn-sm fl mr0"
                    style = {{display: 'block'}}
                    onClick = {() => TogglePS()}
                >
                    {Assets.PlayersSelection}
                </button>
                : void 0}

                {Main.Instance.CRMPointState.player_list ?
                <span
                    className="label label-success table-label2 mt2"
                    style = {{background: '#5cb85c', display: 'block'}}
                >
                    {Assets.selected}
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined  ?
                    getSelected(Main.Instance.DatabaseDataState.playersStreamSelected) : 0}
                </span>
                : void 0}

                {Main.Instance.CRMPointState.player_list ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{background:'#5cb85c', display: 'block'}}
                >
                    {Main.Instance.DatabaseDataState.playersStreamSelected !== undefined ?
                    (100 * getSelected(Main.Instance.DatabaseDataState.playersStreamSelected) /
                    Main.Instance.DatabaseDataState.playersStream.length).toFixed(1) : 0.0}
                    %
                </span>
                : void 0}

                {Main.Instance.CRMPointState.player_list ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{float: 'left', display: 'block'}}
                >
                    {Assets.records}
                    {Main.Instance.DatabaseDataState.playersStream.length ?
                    Main.Instance.DatabaseDataState.playersStream.length : 0}
                </span>
                : void 0}
 
            </div>
            : void 0*/}

            {/*IsBuildScandibet()
            && +Main.Instance.CRMPointState.type_id === 1 && (+Main.Instance.CRMPointState.type2_id === 3101 || +Main.Instance.CRMPointState.type2_id === 3102
            || +Main.Instance.CRMPointState.type2_id === 3103 || +Main.Instance.CRMPointState.type2_id === 3104)
            ?
            <div className = "row admin-users-row">
                <RadioButton3
                    value = {Main.Instance.CRMPointState.include_games === undefined ? false :
                            Main.Instance.CRMPointState.include_games}
                    text = "games selection"
                    ClassName = "input-group-radiobutton3a margin-bottom-sm fr mr0" // fl mr
                    title = {Assets.includeGamesDesc}
                    i1 = {Assets_.active}
                    i2 = {Assets_.inactive}
                    onClick = {text => { Logic.Type.New(Main.Instance.CRMPointState, {
                        exclude_games: false,
                        include_games:!Main.Instance.CRMPointState.include_games})}} 
                />
                {Main.Instance.CRMPointState.include_games ?
                <button
                    className = "btn btn-default btn-sm fl mr0"
                    style = {{display: 'block'}}
                    onClick = {() => ToggleGS()}
                >
                    {Assets.GamesSelection}
                </button>
                : void 0}

                {Main.Instance.CRMPointState.include_games ?
                <span
                    className="label label-success table-label2 mt2"
                    style = {{background: '#5cb85c', display: 'block'}}
                >
                    {Assets.selected}
                    {Main.Instance.GamesState.selected !== undefined ?
                    getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected) : 0}
                </span>
                : void 0}

                {Main.Instance.CRMPointState.include_games ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{background:'#5cb85c', display: 'block'}}
                >
                    {Main.Instance.GamesState.count ?
                    (100 * getSelected(Main.Instance.DatabaseDataState.GamesStreamAllSelected) /
                    Main.Instance.GamesState.count).toFixed(1) : 0}
                    %
                </span>
                : void 0}

                {Main.Instance.CRMPointState.include_games ?
                <span
                    className = "label label-success table-label2 mt2"
                    style = {{float: 'left', display: 'block'}}
                >
                    {Assets.records}
                    {Main.Instance.GamesState.count ? Main.Instance.GamesState.count : 0}
                </span>
                : void 0}
            </div>
            : void 0*/}


            <div className = "row admin-users-row">
            {IsBuildScandibet() && Main.Instance.CRMPointState.name === Assets.DEFAULT ?
            <span style = {{fontSize: '14px', lineHeight: '32px'}} className = "label label-danger">{Assets.DefaultRuleRule}</span>: void 0}
            <ButtonIcon 
                description = {Main.Instance.CRMPointState.mode ? Assets.EditPointDesc :
                              Assets.AddPointDesc}
                click = {() => InsertUpdatePoint()}
                    /*DbRoutes.getAllPointsByAchievement(IsNewRuleAcceptable, InsertUpdatePoint, false)}}*/
                ClassName = {Main.Instance.CRMPointState.mode ? 
                            'btn btn-default btn-sm btn-plus fr mr0' : 
                            'btn btn-primary btn-sm btn-plus fr mr0'}
                Icon = {faSave as IconDefinition}
                text = {Assets.Save}
            />
            </div>
            </div>
            </div>
        <br/>
        <div className = "clearfix"/>
        <div/>
        <div className = "clearfix"/>

        {!Main.Instance.CRMPointState.chart1 && !Main.Instance.CRMPointState.chart2 ?
        <div className = "control-panel">
            <FormInputNumber
                description = {Assets.recordsPerPageDesc}
                outerDiv = "rows-per-table input-group fl mr ml0 pl0"
                ClassName = "h26 w60"
                value = {!Main.Instance.CRMPointState.pSize ? '' : 
                Main.Instance.CRMPointState.pSize.toString()}  
                type = "number" 
                min = "1"
                icon = {Assets_.faHamburgerClass}
                onChange = {(text) => RecordsPerPageChangedPoint(text)}
            />

            <button
                className = "ml20 btn btn-primary btn-xs" 
                onClick = {() => FirstPagePoint()}
                title = {Assets.ListMoveTop}
            >
                <i className = {Assets_.faFirstButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                onClick = {() => PrevPagePoint()}
                title = {Assets.ListMoveLeft}
            >
                <i className = {Assets_.faPrevButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                onClick = {() => NextPagePoint()}
                title = {Assets.ListMoveRight}
            >
                <i className = {Assets_.faNextButtonClass}/>
            </button>
            <button 
                className = "btn btn-primary btn-xs" 
                onClick = {() => LastPagePoint()}
                title = {Assets.ListMoveBottom}
            >
                <i className = {Assets_.faLastButtonClass}/>
            </button>
            {IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CRMPointState.type_id_filter ? '0' :
                        Main.Instance.CRMPointState.type_id_filter}
                data = {[{id: '3100', name: 'Casino'}, {id: '3200', name: 'Sports'}]}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.SelectCategory}
                o2 = {Assets.CategoryQ}
                onChange = {(text) => ChangeTypeFilterIDPoint(text)}
            />
            : 
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CRMPointState.type_id_filter ? '0' :
                        Main.Instance.CRMPointState.type_id_filter}
                data = {Main.Instance.DatabaseDataState.pointTypeStreamAll}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.SelectCategory}
                o2 = {Assets.CategoryQ}
                onChange = {(text) => ChangeTypeFilterIDPoint(text)}
            />
            }

            {Main.Instance.CRMPointState.type_id_filter && parseInt(Main.Instance.CRMPointState.type_id_filter) > 0 ?
            IsBuildScandibet() ?
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CRMPointState.type2_id_filter ? '0' :
                        Main.Instance.CRMPointState.type2_id_filter}
                data = {parseInt(Main.Instance.CRMPointState.type_id_filter) === 3100 ? 
                        [{id: '3101', name: Assets.Slots}, {id: '3102', name: Assets.SpecialSlots}, {id: '3103', name: Assets.Jackpot}, 
                        {id: '3104', name: Assets.Videopoker}, {id: '3105', name: Assets.TableGames}, {id: '3106', name: Assets.LiveCasino}]
                        :
                        [{id: '3201', name: '< 1.2'}, {id: '3202', name: '>= 1.2, < 1.5'}, {id: '3203', name: '	>= 1.5, < 2.0'}, 
                        {id: '3204', name: '>= 2.0, < 3.0'}, {id: '3205', name: '>= 3.0, < 5.0'}, {id: '3206', name: '>= 5.0, < 8.0'}, {id: '3207', name: '>= 8.0'}]
            }
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.SelectType}
                o2 = {Assets.TypeQ}
                onChange = {(text) => ChangeType2FilterIDPoint(text)}
            />
            : 
            <FormSelect 
                span = {true}
                value = {!Main.Instance.CRMPointState.type2_id_filter ? '0' :
                        Main.Instance.CRMPointState.type2_id_filter}
                data = {Main.Instance.DatabaseDataState.subPointFilterTypeStreamAll}
                ClassName = "control-panel-select-narrow"
                o1 = {Assets.SelectType}
                o2 = {Assets.TypeQ}
                onChange = {(text) => ChangeType2FilterIDPoint(text)}
            />
            : void 0}

            <span className = "label label-success table-label mt5">
                {Assets.records}
                {Main.Instance.CRMPointState.count ? Main.Instance.CRMPointState.count : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.showing}
                {Main.Instance.CRMPointState.count ? Main.Instance.CRMPointState.pNum * 
                Main.Instance.CRMPointState.pSize + 1 : 0}
                {' - '}
                {Main.Instance.CRMPointState.count ? ((Main.Instance.CRMPointState.pNum + 1) * 
                Main.Instance.CRMPointState.pSize > Main.Instance.CRMPointState.count ? 
                Main.Instance.CRMPointState.count : (Main.Instance.CRMPointState.pNum + 1) * 
                Main.Instance.CRMPointState.pSize) : 0}
            </span>
            <span className = "label label-success table-label mt5">
                {Assets.page}
                {Main.Instance.CRMPointState.count ? Main.Instance.CRMPointState.pNum + 1 :0}{' / '}
                {Main.Instance.CRMPointState.count ? Math.trunc((Main.Instance.CRMPointState.count - 1) /
                Main.Instance.CRMPointState.pSize+1) : 0}
            </span>
        </div> : void 0}
    <br/>
    {Main.Instance.CRMPointState.chart1 && !Main.Instance.CRMPointState.chart2 ?
    <div className = "fr" style = {{marginTop:'-25px'}}>
        <button
            style = {{border:'0px'}}
            onClick = {() => CloseChart(1)} 
            className = "btn btn-default"
        ><i className = {Assets_.faCancelButtonClass}/>
        </button>
    </div>
    : void 0}
    {Main.Instance.CRMPointState.chart2 && !Main.Instance.CRMPointState.chart1 ?
    <div className = "fr" style = {{marginTop:'-25px'}}>
        <button
            style = {{border:'0px'}}
            onClick = {() => CloseChart(2)} 
            className = "btn btn-default"
        ><i className = {Assets_.faCancelButtonClass}/>
        </button>
    </div>
    : void 0}

    {Main.Instance.CRMPointState.chart2 && Main.Instance.CRMPointState.chart1 ?
    <div 
        className = "fr"
        style = {{marginTop:'-25px'}}
    >
        <button
            style = {{border:'0px'}}
            onClick = {() => CloseChart(0)} 
            className = "btn btn-default"
        ><i className = {Assets_.faCancelButtonClass}/>
        </button>
    </div>
    : void 0}

    {Main.Instance.CRMPointState.chart1 || Main.Instance.CRMPointState.chart2 ?
    <span className = "fl">{Assets.pointsAssignedByAmount}</span>
    : void 0}
    {Main.Instance.CRMPointState.chart1 !== Main.Instance.CRMPointState.chart2 ?
    <span className = "fr">{Assets.pointsAssignedByPercentage}</span>
    : void 0}
    {Main.Instance.CRMPointState.chart1 || Main.Instance.CRMPointState.chart2 ?
    <div id = "width" className = "clearfix"/>
    : void 0}
    {Main.Instance.CRMPointState.chart1 && Main.Instance.CRMPointState.chart2 ?
        <SimpleLineChart0 data = {data0} width = {Main.Instance.CRMPointState.chartWidth}/>
    : void 0}
    {Main.Instance.CRMPointState.chart1 && !Main.Instance.CRMPointState.chart2 ?
        <SimpleLineChart1 data = {data1} width = {Main.Instance.CRMPointState.chartWidth}/>
    : void 0}
    {Main.Instance.CRMPointState.chart2 && !Main.Instance.CRMPointState.chart1 ?
        <SimpleLineChart2 data = {data2} width = {Main.Instance.CRMPointState.chartWidth}/>
    : void 0}
    {!Main.Instance.CRMPointState.chart1 && !Main.Instance.CRMPointState.chart2 ? 
    isUserSystem() || isUserMaster() || isUserContractualPartner() ? 
    Main.Instance.DatabaseDataState.pointProgramStream ?
    <>
        <h3>{Assets.tRules}</h3>
        <ReactTable 
            data = {Main.Instance.DatabaseDataState.pointProgramStream}
            columns = {[
                {minWidth: 30, Header: '', accessor: '', Cell: (row) => TableUtils.EditButton(row, handleEditPoint)},
                {Header: Assets.Title, accessor: 'title', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">{row.value}</div>},
                {Header: Assets.Category, accessor: 'category', headerClassName: 'th-left', Cell: (row) => <div className = "td-left">{row.value}</div>},
                {Header: Assets.Type, accessor: 'type', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},
                {minWidth: 50, Header: '%', accessor: '%', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},
                {Header: Assets.SelectedGames, accessor: 'Selected Games', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},
                {Header: Assets.Sel_Players, accessor: 'Selected Players', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},
                {Header: Assets.FromDate, accessor: 'From Date', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},
                {Header: Assets.ToDate, accessor: 'To Date', headerClassName: 'th-left', Cell: (row) => (<div className = "td-left">{row.value}</div>)},            
                {minWidth: 70, Header: Assets.Active, accessor: 'active', Cell: (row) => TableUtils.TB(row, toggleActive)},
                {minWidth: 30, Header: '', accessor: '', 
                Cell: (row) => TableUtils.DeleteButton(row, handleDeletePoint, row.original.title === Assets.DEFAULT, Assets.DEFAULTRulesRule)},
            ]}
            showPaginationBottom = {false}
            defaultPageSize={100}
        />

    </>

    : getPointsCount() : void 0  : void 0}
    </div>
:
<div>
<div className = "control-panel">
    <FormInputNumber
        description = {Assets.recordsPerPageDesc}
        outerDiv = "rows-per-table input-group fl mr ml0 pl0"
        ClassName = "h26 w60"
        value = {!Main.Instance.CRMAchievementsState.pSize ? '' : 
        Main.Instance.CRMAchievementsState.pSize.toString()}  
        type = "number" 
        min = "1"
        icon = {Assets_.faHamburgerClass}
        onChange = {(text) => RecordsPerPageChanged(text)}
    />

    <button 
        onClick = {() => FirstPage()}
        title = {Assets.ListMoveTop} 
        className = "ml20 btn btn-primary btn-xs"
    >
        <i className = {Assets_.faFirstButtonClass}/>
    </button>
    <button 
        onClick = {() => PrevPage()}
        title = {Assets.ListMoveLeft}
        className = "btn btn-primary btn-xs"
    >
        <i className = {Assets_.faPrevButtonClass}/>
    </button>
    <button 
        onClick = {() => NextPage()}
        title = {Assets.ListMoveRight}
        className = "btn btn-primary btn-xs"
    >
        <i className = {Assets_.faNextButtonClass}/>
    </button>
    <button 
        onClick = {() => LastPage()}
        title = {Assets.ListMoveBottom} 
        className = "btn btn-primary btn-xs"
    >
        <i className = {Assets_.faLastButtonClass}/>
    </button>

    <span className = "label label-success table-label mt5">
        {Assets.records}
        {Main.Instance.CRMAchievementsState.count ? Main.Instance.CRMAchievementsState.count : 0}
    </span>
    <span className = "label label-success table-label mt5">
        {Assets.showing}
        {Main.Instance.CRMAchievementsState.count ? Main.Instance.CRMAchievementsState.pNum * 
        Main.Instance.CRMAchievementsState.pSize + 1 : 0}
        {' - '}
        {Main.Instance.CRMAchievementsState.count ? ((Main.Instance.CRMAchievementsState.pNum + 1) * 
        Main.Instance.CRMAchievementsState.pSize > Main.Instance.CRMAchievementsState.count ? 
        Main.Instance.CRMAchievementsState.count : (Main.Instance.CRMAchievementsState.pNum + 1) * 
        Main.Instance.CRMAchievementsState.pSize) : 0}
    </span>
    <span className = "label label-success table-label mt5">
        {Assets.page}
        {Main.Instance.CRMAchievementsState.count ? Main.Instance.CRMAchievementsState.pNum + 1 :0}{' / '}
        {Main.Instance.CRMAchievementsState.count ? Math.trunc((Main.Instance.CRMAchievementsState.count - 1) /
        Main.Instance.CRMAchievementsState.pSize+1) : 0}
    </span>
</div>
</div>}
</div>
    
)
}

function  FirstPagePoint() {
    Logic.Type.New(Main.Instance.CRMPointState,{offset:(0).toString()})
    getPoints()
    Logic.Type.New(Main.Instance.CRMPointState,{pNum: 0})
}

function PrevPagePoint() {

    if (Main.Instance.CRMPointState.pNum > 0) {

        Logic.Type.New(Main.Instance.CRMPointState,{offset:((Main.Instance.CRMPointState.pNum - 1) * 
                       Main.Instance.CRMPointState.pSize).toString()})
        getPoints()

        Logic.Type.New(Main.Instance.CRMPointState,{pNum: Main.Instance.CRMPointState.pNum - 1})
    } else {
        LastPagePoint()
    }
}

function  NextPagePoint() {

    if ((Main.Instance.CRMPointState.pNum + 1) * Main.Instance.CRMPointState.pSize < 
         Main.Instance.CRMPointState.count) {
        Logic.Type.New(Main.Instance.CRMPointState, {
            offset: ((Main.Instance.CRMPointState.pNum + 1) * 
            Main.Instance.CRMPointState.pSize).toString().toString()})
        getPoints()
        Logic.Type.New(Main.Instance.CRMPointState, {
            pNum: Main.Instance.CRMPointState.pNum + 1})                
    } else {
        FirstPagePoint()      
    }
}

function LastPagePoint() {

    if (Main.Instance.CRMPointState.count <= Main.Instance.CRMPointState.pSize) {
        FirstPagePoint()
    } else {
        let npNum: number = 0

        if (Main.Instance.CRMPointState.count === 0) {

            Logic.Type.New(Main.Instance.CRMPointState,{offset: (0).toString()})
        } else {
            npNum = Math.trunc((Main.Instance.CRMPointState.count - 1) / Main.Instance.CRMPointState.pSize)
            Logic.Type.New(Main.Instance.CRMPointState, {
                offset:(npNum * Main.Instance.CRMPointState.pSize).toString()})
        }
        getPoints()
        Logic.Type.New(Main.Instance.CRMPointState,{pNum: npNum})
    }
}

function handleEditPoint(row: any) {
    if (row) {
            gotoTop()
        //  await DbRoutes.getGamesAllByCategory(rowData[4])
        //  await DbRoutes.getGamesAll()
    
        //  let stateCopyAch = Object.assign({}, Main.Instance.CRMAchievementsState);
            const stateCopy = Object.assign({}, Main.Instance.CRMPointState)
            stateCopy.mode = true
            // stateCopy.Mode = false
            if (!IsBuildScandibet()) {
    /*
            stateCopy.id = rowData[0]
            stateCopy.name = rowData[1].toString()
            stateCopy.name_saved = rowData[1].toString()
    
            stateCopy.type_id = rowData[2].toString()
            stateCopy.type_id_saved = rowData[2].toString()
            DbRoutes.getSubPointTypesByPointType(rowData[1])
            stateCopy.type2_id = rowData[3].toString()
            stateCopy.type2_id_saved = rowData[3].toString()
            stateCopy.various = rowData[6].toString()
            stateCopy.various_saved = rowData[6].toString()
            stateCopy.bet_min = parseInt(rowData[8])
            stateCopy.bet_min_saved = parseInt(rowData[8])
            stateCopy.bet_max = parseInt(rowData[9])
            stateCopy.bet_max_saved = parseInt(rowData[9])
            stateCopy.multiplier_id = rowData[10].toString()
            stateCopy.multiplier_id_saved = rowData[10].toString()
            stateCopy.points_pcu = parseInt(rowData[11])
            stateCopy.points_pcu_saved = parseInt(rowData[11])
            stateCopy.nlp = parseInt(rowData[12])
            stateCopy.nlp_saved = parseInt(rowData[12])
            stateCopy.bet_size_id = rowData[13].toString()
            stateCopy.bet_size_id_saved = rowData[13].toString()
            stateCopy.bet_size_c = parseInt(rowData[14])
            stateCopy.bet_size_c_saved = parseInt(rowData[14])
            stateCopy.average_id = rowData[15].toString()
            stateCopy.average_id_saved = rowData[15].toString()
            stateCopy.average_c = parseInt(rowData[16])
            stateCopy.average_c_saved = parseInt(rowData[16])
            stateCopy.from_date = rowData[17] && rowData[17].length > 0 ? moment.default(rowData[17], Assets_.dateDisplayFormat) : null
            stateCopy.from_date_saved = stateCopy.from_date
            stateCopy.to_date = rowData[18] && rowData[18].length > 0 ? moment.default(rowData[18], Assets_.dateDisplayFormat) : null
            stateCopy.to_date_saved = stateCopy.to_date
            let code = rowData[19].toString().trim()
            stateCopy.active = rowData[20].toString() === 'true' ? true : false 
            stateCopy.active_saved = rowData[20].toString() === 'true' ? true : false 
            stateCopy.change_gs = rowData[21].toString() === 'true' ? true : false 
            stateCopy.change_gs_saved = rowData[21].toString() === 'true' ? true : false 
            stateCopy.target = rowData[22].toString()
            stateCopy.target_saved = rowData[22].toString()
            stateCopy.wagering = parseInt(rowData[23])
            stateCopy.wagering_saved = parseInt(rowData[23])
            stateCopy.losses = parseInt(rowData[24])
            stateCopy.losses_saved = parseInt(rowData[24])
            stateCopy.chart1_start = parseInt(rowData[25])
            stateCopy.chart1_start_saved = parseInt(rowData[25])
            stateCopy.chart1_step = parseInt(rowData[26])
            stateCopy.chart1_step_saved = parseInt(rowData[26])
            stateCopy.chart1_stop = parseInt(rowData[27])
            stateCopy.chart1_stop_saved = parseInt(rowData[27])
            stateCopy.chart2_start = parseInt(rowData[28])
            stateCopy.chart2_start_saved = parseInt(rowData[28])
            stateCopy.chart2_step = parseInt(rowData[29])
            stateCopy.chart2_step_saved = parseInt(rowData[29])
            stateCopy.chart2_stop = parseInt(rowData[30])
            stateCopy.chart2_stop_saved = parseInt(rowData[30])
            stateCopy.chart1_value = parseInt(rowData[31])
            stateCopy.chart1_value_saved = parseInt(rowData[31])
            stateCopy.chart2_value = parseInt(rowData[32])
            stateCopy.chart2_value_saved = parseInt(rowData[32])
            stateCopy.level_min = parseInt(rowData[33])
            stateCopy.level_min_saved = parseInt(rowData[33])
            stateCopy.level_max = parseInt(rowData[34])
            stateCopy.level_max_saved = parseInt(rowData[34])
            stateCopy.sublevel_min = parseInt(rowData[35])
            stateCopy.sublevel_min_saved = parseInt(rowData[35])
            stateCopy.sublevel_max = parseInt(rowData[36])
            stateCopy.sublevel_max_saved = parseInt(rowData[36])
    
            // stateCopy.exclude_games = rowData[36].toString() === 'true' ? true : false 
            // stateCopy.exclude_games_saved = rowData[36].toString() === 'true' ? true : false
            // stateCopy.excluded_games = JSON.parse(JSON.stringify(rowData[37]))
            // stateCopy.excluded_games_saved = JSON.parse(JSON.stringify(rowData[37]))
            stateCopy.player_list = rowData[39].toString() === 'true' ? true : false 
            stateCopy.player_list_saved = rowData[39].toString() === 'true' ? true : false 
            // stateCopy.player_list_id = rowData[39]
            // stateCopy.player_list_id_saved = rowData[39]
            // stateCopy.excludedgames = JSON.parse(JSON.stringify(rowData[40]))
            // stateCopy.excludedgames_saved = JSON.parse(JSON.stringify(rowData[40]))
            // console.log (JSON.stringify(stateCopy.excludedgames))
            // stateCopy.excluded_games_list =
            // GetGamesList(stateCopy.excluded_games, Main.Instance.DatabaseDataState.GamesStreamAll, true)
    
            stateCopy.include_games = rowData[42].toString() === 'true' ? true : false 
            stateCopy.include_games_saved = rowData[42].toString() === 'true' ? true : false
            stateCopy.included_games = JSON.parse(JSON.stringify(rowData[43]))
            stateCopy.includedgames = JSON.parse(JSON.stringify(rowData[44]))
            
            // stateCopy.included_games_list =
            // GetGamesList(stateCopy.included_games, Main.Instance.DatabaseDataState.GamesStreamAll, true)
    
            let playersStreamSelected: boolean[] = []
            stateCopy.playerlist = JSON.parse(JSON.stringify(rowData[45]))
            if (stateCopy.player_list) {
                if (Main.Instance.DatabaseDataState.playersStream)
                for (const item of Main.Instance.DatabaseDataState.playersStream) {
                    let selected: boolean = false
                    for (let Item1 of stateCopy.playerlist) {
                        if (Item1 === item['player']) {
                            selected = true
                            break
                        }
                    }
                    playersStreamSelected.push (selected)
                }
            } else {
                if (Main.Instance.DatabaseDataState.playersStream)
                for (let i = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) playersStreamSelected.push (false)
            }
    
            let GamesStreamAllSelected: boolean[] = []
            if (stateCopy.include_games) {
                if (Main.Instance.DatabaseDataState.GamesStreamAll)
                for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                    let selected: boolean = false
                    for (let Item2 of stateCopy.included_games) {
                        if (Item2 === item['Id']) {
                            selected = true
                            break
                        }
                    }
                    GamesStreamAllSelected.push (selected)
                }
            } else {
                if (Main.Instance.DatabaseDataState.GamesStreamAll)
                for (let i = 0; i < Main.Instance.DatabaseDataState.GamesStreamAll.length; i++) GamesStreamAllSelected.push (false)
            }
    
            let id:string = '0'
            if (code !== Assets.Any)
            for (let i:number = 0; i < Main.Instance.DatabaseDataState.currenciesAllStream.length; i++) {
                if (Main.Instance.DatabaseDataState.currenciesAllStream[i]['name'].toString().trim() === code) {
                    id = Main.Instance.DatabaseDataState.currenciesAllStream[i]['id'].toString().trim()
                }
            }
            stateCopy.currency_id = id
            stateCopy.currency_id_saved = id
    
            Logic.Type.New(Main.Instance.DatabaseDataState, {playersStreamSelected, GamesStreamAllSelected})
    */
            } else {
    /*
    0 point_rules.id,
    1 point_rules.category_id,
    2 CASE WHEN (point_rules.category_id = 3100) THEN \'Casino\' WHEN (point_rules.category_id = \'3200\') THEN \'Sports\' ELSE \'Unknown\' END AS category,
    3 point_rules.detail_id, 
    4 CASE  WHEN (point_rules.detail_id = 3101) THEN \'Slots\' 
            WHEN (point_rules.detail_id = 3102) THEN \'Special Slots\' 
            WHEN (point_rules.detail_id = 3103) THEN \'Jackpot\' 
            WHEN (point_rules.detail_id = 3104) THEN \'Videopoker\' 
            WHEN (point_rules.detail_id = 3105) THEN \'Table Games\' 
            WHEN (point_rules.detail_id = 3106) THEN \'Live Casino\' 
            WHEN (point_rules.detail_id = 3201) THEN \'< 1.1\' 
            WHEN (point_rules.detail_id = 3202) THEN \'>= 1.1, < 1.5\' 
            WHEN (point_rules.detail_id = 3203) THEN \'>= 1.5, < 2.0\' 
            WHEN (point_rules.detail_id = 3204) THEN \'>= 2.0, < 5.0\' 
            WHEN (point_rules.detail_id = 3205) THEN \'>= 5.0\' 
            ELSE \'Unknown\' 
      END AS type,
    5 point_rules.various AS "%",
    6 CASE WHEN (point_rules.detail_id/100 = 31) THEN CARDINALITY(point_rules.included_games) || \' games\' WHEN (point_rules.detail_id/100 = 32) THEN \'Assets_.NA\' END AS "Selected Games",
    7 CASE WHEN (point_rules.detail_id/100 = 31) THEN point_rules.included_games WHEN (point_rules.detail_id/100 = 32) THEN \'{}\' END AS includedgames, 
    8 CASE WHEN (CARDINALITY(point_rules.playerlist) = 0) THEN \'All players\' WHEN 
      (CARDINALITY(point_rules.playerlist) = 1) THEN \'1 player\' ELSE CARDINALITY(point_rules.playerlist) ||  \' players\' END AS "Selected Players",
    9 point_rules.playerlist, 
    10 point_rules.from_date AS "From Date", 
    11 point_rules.to_date AS "To Date", 
    12 point_rules.active
    */
/*
   && Main.Instance.CRMPointState.active === Main.Instance.CRMPointState.active_saved

   && Main.Instance.CRMPointState.from_date === Main.Instance.CRMPointState.from_date_saved
   && Main.Instance.CRMPointState.to_date === Main.Instance.CRMPointState.to_date_saved
   && Main.Instance.CRMPointState.from_time === Main.Instance.CRMPointState.from_time_saved
   && Main.Instance.CRMPointState.to_time === Main.Instance.CRMPointState.to_time_saved
*/

            // DbRoutes.getSubPointTypesByPointType(rowData[1])
            stateCopy.id = row.original.id.toString()

            stateCopy.name = row.original.title
            stateCopy.name_saved = row.original.title.toString()        
            
            stateCopy.type_id = row.original.category_id.toString()
            stateCopy.type_id_saved = row.original.category_id.toString()
            
            stateCopy.type2_id = row.original.detail_id.toString()
            stateCopy.type2_id_saved = row.original.detail_id.toString()

            stateCopy.percentage = +row.original['%']
            stateCopy.percentage_saved = +row.original['%']

            stateCopy.from_date = row.original['From Date'] && row.original['From Date'].length > 0 ? moment.default(row.original['From Date'], Assets_.dateDisplayFormat) : null
            stateCopy.from_date_saved = row.original['From Date'] && row.original['From Date'].length > 0 ? moment.default(row.original['From Date'], Assets_.dateDisplayFormat) : null

            stateCopy.to_date = row.original['To Date'] && row.original['To Date'].length > 0 ? moment.default(row.original['To Date'], Assets_.dateDisplayFormat) : null
            stateCopy.to_date_saved = row.original['To Date'] && row.original['To Date'].length > 0 ? moment.default(row.original['To Date'], Assets_.dateDisplayFormat) : null

            stateCopy.from_time = row.original['From Date'] && row.original['From Date'].length >= 16 ? row.original['From Date'].substring (11, row.original['From Date'].length) : ''
            stateCopy.from_time_saved = row.original['From Date'] && row.original['From Date'].length >= 16 ? row.original['From Date'].substring (11, row.original['From Date'].length) : ''

            stateCopy.to_time = row.original['To Date'] && row.original['To Date'].length >= 16 ? row.original['To Date'].substring (11, row.original['To Date'].length) : ''
            stateCopy.to_time_saved = row.original['To Date'] && row.original['To Date'].length >= 16 ? row.original['To Date'].substring (11, row.original['To Date'].length) : ''

            stateCopy.included_games = JSON.parse(JSON.stringify(row.original.includedgames))
            // stateCopy.included_games_saved = JSON.parse(JSON.stringify(rowData[7]))
            stateCopy.playerlist = JSON.parse(JSON.stringify(row.original.playerlist))
            // stateCopy.playerlist_saved = JSON.parse(JSON.stringify(rowData[9]))
            stateCopy.active = row.original.active.toString() === 'true' ? true : false 
            stateCopy.active_saved = row.original.active.toString() === 'true' ? true : false 
    
            // console.log (Main.Instance.DatabaseDataState.playersStream)
            const playersStreamSelected: boolean[] = []
            if (stateCopy.playerlist.length > 0) {
                if (Main.Instance.DatabaseDataState.playersStream) {
                for (const item of Main.Instance.DatabaseDataState.playersStream) {
                    // console.log (item['player'])
                    let selected: boolean = false
                    for (const Item1 of stateCopy.playerlist) {
                        // console.log (Item1)
                        if (parseInt(Item1) === parseInt(item['player'])) {
                            // console.log ('FOUND')
                            selected = true
                            break
                        }
                    }
                    playersStreamSelected.push (selected)
                }
                }
            } else {
                if (Main.Instance.DatabaseDataState.playersStream) {
                for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) { playersStreamSelected.push (false) }
                }
            }
    
            const GamesStreamAllSelected: boolean[] = []
            if (Main.Instance.DatabaseDataState.GamesStreamAll) {
            for (const item of Main.Instance.DatabaseDataState.GamesStreamAll) {
                let selected: boolean = false
                for (const Item2 of stateCopy.included_games) {
                    if (Item2 === item['Id']) {
                        selected = true
                        break
                    }
                }
                GamesStreamAllSelected.push (selected)
            }
            }
    
            Logic.Type.New(Main.Instance.CRMPointState, stateCopy)
            Logic.Type.New(Main.Instance.DatabaseDataState, {GamesStreamAllSelected, playersStreamSelected})
    
            // console.log (Main.Instance.DatabaseDataState.playersStreamSelected)
            }
            
            // console.log (Main.Instance.DatabaseDataState.playersStreamSelected)
            if (!IsBuildScandibet()) { Logic.Type.New(Main.Instance.CRMPointState, stateCopy) }
            ChangeExample()        
        }
    }  
    
/*
function handleDeletePoint(rowData:string[]) {
    if (rowData) {
        if (rowData[1] === Assets.DEFAULT) {
            alert ('DEFAULT Rules cannot be deleted, they can be only modified.')
        } else if (confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' "' + rowData[1] + '" (' + rowData[3] + ' - ' + rowData[5] + ')?')) {
            if  (Main.Instance.CRMPointState.pNum === 
                (Math.trunc((Main.Instance.CRMPointState.count - 2) /
                Main.Instance.CRMPointState.pSize) + 1)) {
                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                // primer. pNum = 0, count = 6, pSize = 5. trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                DbRoutes.removePoint (rowData[0].toString(), DeleteLastPagePoint)
            } else {
                DbRoutes.removePoint (rowData[0].toString(), DeleteNotLastPagePoint)
            }
        }
    }
}
*/
/*
function handleDelete(rowData:string[]) {
    if (rowData) {
        if (confirm(Assets.sureToDelete + ' ' + 
            Assets.achievement + ' "' + rowData[2] + '"' + Assets._for_ + 
            Assets.site + ' "' + rowData[1] + '"?')) {

            if (Main.Instance.CRMAchievementsState.pNum === 
                (Math.trunc((Main.Instance.CRMAchievementsState.count - 2) / 
                Main.Instance.CRMAchievementsState.pSize) + 1)) {
                // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
                // primer. pNum = 0, count = 6, pSize = 5....trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
                
                DbRoutes.removeAchievement (rowData[0].toString(), DeleteLastPage)
            } else {
                DbRoutes.removeAchievement (rowData[0].toString(), DeleteNotLastPage)
            }
        }
    }
}
*/
function handleDeletePoint(row: any) {
    /*
        if (row.original.title === Assets.DEFAULT) {
            alert ('DEFAULT Rules cannot be deleted, they can be only modified.')
        } else 
    */  

    if (row && confirm(Assets.sureToDelete + ' ' + Assets.Rule + ' "' + row.original.title + '" (' + row.original.category + ' - ' + row.original.type + ')?')) {
        if  (Main.Instance.CRMPointState.pNum === 
            (Math.trunc((Main.Instance.CRMPointState.count - 2) /
            Main.Instance.CRMPointState.pSize) + 1)) {
            // to pomeni, da smo na zadnji strani in je samo 1 record se na njej
            // primer. pNum = 0, count = 6, pSize = 5. trunc((7-2)/5) bi bilo se 1, trunc((6-2)/5) pa je 0
            DbRoutes.removePoint (row.original.id.toString(), DeleteLastPagePoint)
        } else {
            DbRoutes.removePoint (row.original.id.toString(), DeleteNotLastPagePoint)
        }
    }
}

function DeleteLastPagePoint() {
    ClearFields()
    Logic.Type.New(Main.Instance.CRMPointState,{count:Main.Instance.CRMPointState.count - 1})
    LastPage()
}

function DeleteNotLastPagePoint() {
    ClearFields()
    Logic.Type.New(Main.Instance.CRMPointState,{count:Main.Instance.CRMPointState.count - 1})
    getPoints()
}

export function getPointsCount() {
    // console.log ('getPointsCount')
    if (Main.Instance.CRMPointState.countCalled === false) {
        Logic.Type.New(Main.Instance.CRMPointState, {countCalled:true})

        // if (!Main.Instance.DatabaseDataState.pointTypeStreamAll) DbRoutes.GetPointTypesAll()
        // if (!Main.Instance.DatabaseDataState.subpointTypeStreamAll) getPointTypes(0)

        DbRoutes.getPointsByAchievement()
        DbRoutes.getPointTypes(1)
        DbRoutes.loadData()
        // if (!Main.Instance.DatabaseDataState.GamesStreamAll) {DbRoutes.getGamesAleacc(Main.Instance.FrontendStoreState.skin_id, Main.Instance.FrontendStoreState.jwt)}
    }
}

function getPoints() {

    Logic.Type.New(Main.Instance.DatabaseDataState, {pointProgramStream: undefined})
}

function RecordsPerPageChangedPoint(text:string) {

    if (parseInt(text) > -1) {
        Logic.Type.New(Main.Instance.CRMPointState,{pSize:parseInt(text)})
        FirstPagePoint()
    }
}

function ClearFields() {

    const isNormalInteger:boolean[] = []
/*
    let currency:string[] = []

    for (let i:number = 0; i < Main.Instance.CurrencyRatesState.code.length; i++) {
        isNormalInteger.push(false)
        currency.push('')
    }
*/
    const game_ids_selected_empty:boolean[] = []
    if (Main.Instance.DatabaseDataState.gamesAllStream) {
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.gamesAllStream.length; i++) {
        game_ids_selected_empty.push(false)
    }
    }

    Logic.Type.New (Main.Instance.CRMPointState, {
        // main_currency:'0',
        name:'',
        type_id:'0',
        type2_id:'0',
        losses:0,
        wagering:0,
        chart1_start:1,
        chart1_step:3,
        chart1_stop:100,
        chart2_start:1,
        chart2_step:3,
        chart2_stop:100,
        chart1_value:10,
        chart2_value:10,
        level_min:0,
        level_max:0,
        sublevel_min:0,
        sublevel_max:0,
        excluded_games:[],
        excludedgames:[],
        excluded_games_list:'',
        exclude_games:false,
        exclude_games_id:'0',
        included_games:[],
        includedgames:[],
        playerlist: [],
        included_games_list:'',
        include_games:false,
        include_games_id:'0',
        player_list_id:'0',
        player_list_names:'',
        change_gs: false,
        points_pcu: 1000,
        bet_size_c: 1000,
        average_c: 1000,
        average_id:'0',
        bet_size_id:'0',
        nlp:0,
        multiplier_id:'0',
        bet_min:0,
        bet_max:0,
        chart1:false,
        chart2:false,
        auto_include:false,
        from_date:null,
        to_date:null,
        from_time: '',
        to_time: '',
        active:true,
        id:'0', 
        // currency:currency, 
        isNormalInteger,
        game_ids_selected:game_ids_selected_empty,
        mode:false,
        // Mode:false,
        // modeBetSlip:false,
        game_ids_raw:'[]',
        currency_id:'0',
        sort:[],
        Sort:[],
        various:'',/*,
        odds_type_id:'0'*/})
}

function InsertPointCallback() {
    ClearFields ()
    Logic.Type.New(Main.Instance.CRMAchievementsState, {count: Main.Instance.CRMAchievementsState.count++})
    LastPagePoint()
    // DbRoutes.getPointAllByAchievement(Main.Instance.CRMAchievementsState.id)
}

function UpdatePointCallback() {
    ClearFields ()
    getPoints()
    // DbRoutes.getPointAllByAchievement(Main.Instance.CRMAchievementsState.id)
}

function GetIncludedGames(which: number, object: any[], bool: boolean[]): string {
    // console.log (which)
    // console.log (object[0])
    if (!IsBuildScandibet()) { return '{}' }
    let n: string = ''
    if (which === 1) { n = 'Id' } else if (which === 2) { n = Assets.Name } else if (which === 3) { n = 'player' }
    // console.log (n)
    let r: string = '{'
    for (let i: number = 0; i < object.length; i++) {
        if (bool[i]) {
            if (r.length > 1) { r += ',' }
            if (which !== 1) { r += '"' }
            r += object[i][n]
            if (which !== 1) { r += '"' }
        }
    }
    r += '}'
    // console.log (r)
    return r
}

function InsertUpdatePoint(duplicate?: boolean) {
    const validationFromTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.CRMPointState.from_time)
    const validationToTime: boolean = utilsTimeValidEmptyNotValid (Main.Instance.CRMPointState.to_time)
    if (Main.Instance.CRMPointState.name !== Assets.DEFAULT && Main.Instance.CRMPointState.from_date !== null && Main.Instance.CRMPointState.from_date !== undefined && validationFromTime === false) {
        alert (Assets.PleaseDefineStartTime + '!')
        return
    }
    if (Main.Instance.CRMPointState.name !== Assets.DEFAULT && Main.Instance.CRMPointState.to_date !== null && Main.Instance.CRMPointState.to_date !== undefined && validationToTime === false) {
        alert (Assets.PleaseDefineEndTime + '!')
        return
    }
    const items: Point = {
        namespace:'',
        title: Main.Instance.CRMPointState.name === undefined ? '' : Main.Instance.CRMPointState.name,
        id: Main.Instance.CRMPointState.id === null || Main.Instance.CRMPointState.id === undefined ?
            0 : parseInt(Main.Instance.CRMPointState.id),
        category_id:!Main.Instance.CRMPointState.type_id ? 0 : parseInt(Main.Instance.CRMPointState.type_id),
        detail_id:!Main.Instance.CRMPointState.type2_id ? 0 : parseInt(Main.Instance.CRMPointState.type2_id),
        points_pcu:!Main.Instance.CRMPointState.points_pcu ? 1000 : Main.Instance.CRMPointState.points_pcu,
        bet_size_c:!Main.Instance.CRMPointState.bet_size_c ? 1000 : Main.Instance.CRMPointState.bet_size_c,
        average_c:!Main.Instance.CRMPointState.average_c ? 1000 : Main.Instance.CRMPointState.average_c,
        average_id:!Main.Instance.CRMPointState.average_id ? 0 : parseInt(Main.Instance.CRMPointState.average_id),
        bet_size_id:!Main.Instance.CRMPointState.bet_size_id ? 0 : parseInt(Main.Instance.CRMPointState.bet_size_id),
        multiplier_id:!Main.Instance.CRMPointState.multiplier_id?0:parseInt(Main.Instance.CRMPointState.multiplier_id),
        point_program_id:!Main.Instance.CRMAchievementsState.point_program_id ? 0 : 
        parseInt(Main.Instance.CRMAchievementsState.point_program_id),
        from_date:!Main.Instance.CRMPointState.from_date  ? '' : 
        moment.default(Main.Instance.CRMPointState.from_date).format(Assets_.dateDisplayFormat) + ' ' + Main.Instance.CRMPointState.from_time,
        to_date:!Main.Instance.CRMPointState.to_date ? '' : 
        moment.default(Main.Instance.CRMPointState.to_date).format(Assets_.dateDisplayFormat) + ' ' + Main.Instance.CRMPointState.to_time,
        various: IsBuildScandibet() ? Main.Instance.CRMPointState.percentage.toString() : !Main.Instance.CRMPointState.various ? '' : Main.Instance.CRMPointState.various,
        currency:Assets.Any,
        active:!Main.Instance.CRMPointState.active ? 'false' : Main.Instance.CRMPointState.active.toString(),
        change_gs:!Main.Instance.CRMPointState.change_gs ? 'false' : Main.Instance.CRMPointState.change_gs.toString(),
        wagering:!Main.Instance.CRMPointState.wagering ? 0 : Main.Instance.CRMPointState.wagering,
        losses:!Main.Instance.CRMPointState.losses ? 0 : Main.Instance.CRMPointState.losses,
        bet_min:!Main.Instance.CRMPointState.bet_min ? 0 : Main.Instance.CRMPointState.bet_min,
        bet_max:!Main.Instance.CRMPointState.bet_max ? 0 : Main.Instance.CRMPointState.bet_max,
        chart1_start:!Main.Instance.CRMPointState.chart1_start ? 1 : Main.Instance.CRMPointState.chart1_start,
        chart1_step:!Main.Instance.CRMPointState.chart1_step ? 3 : Main.Instance.CRMPointState.chart1_step,
        chart1_stop:!Main.Instance.CRMPointState.chart1_stop ? 100 : Main.Instance.CRMPointState.chart1_stop,
        chart2_start:!Main.Instance.CRMPointState.chart2_start ? 1 : Main.Instance.CRMPointState.chart2_start,
        chart2_step:!Main.Instance.CRMPointState.chart2_step ? 3 : Main.Instance.CRMPointState.chart2_step,
        chart2_stop:!Main.Instance.CRMPointState.chart2_stop ? 100 : Main.Instance.CRMPointState.chart2_stop,
        chart1_value:!Main.Instance.CRMPointState.chart1_value ? 10 : Main.Instance.CRMPointState.chart1_value,
        chart2_value:!Main.Instance.CRMPointState.chart2_value ? 10 : Main.Instance.CRMPointState.chart2_value,
        target:!Main.Instance.CRMPointState.target ? '0' : Main.Instance.CRMPointState.target,
        nlp:!Main.Instance.CRMPointState.nlp ? 0 : Main.Instance.CRMPointState.nlp,
        level_min: !Main.Instance.CRMPointState.level_min ? 0 : Main.Instance.CRMPointState.level_min,
        level_max: !Main.Instance.CRMPointState.level_max ? 0 : Main.Instance.CRMPointState.level_max,
        sublevel_min: !Main.Instance.CRMPointState.sublevel_min ? 0 : Main.Instance.CRMPointState.sublevel_min,
        sublevel_max: !Main.Instance.CRMPointState.sublevel_max ? 0 : Main.Instance.CRMPointState.sublevel_max,
        player_list: !Main.Instance.CRMPointState.player_list ? 'false' : 
        Main.Instance.CRMPointState.player_list.toString(),
        player_list_id: parseInt(Main.Instance.CRMPointState.player_list_id), // NOT USED
 
        exclude_games: !Main.Instance.CRMPointState.exclude_games ? 'false' :  // NOT USED
        Main.Instance.CRMPointState.exclude_games.toString(),  // NOT USED
        excluded_games: FormatSQLArray(JSON.stringify(Main.Instance.CRMPointState.excluded_games)),  // NOT USED
        excludedgames: GetGames(Main.Instance.CRMPointState.excluded_games,
                                Main.Instance.DatabaseDataState.GamesStreamAll), // NOT USED
        include_games: !Main.Instance.CRMPointState.include_games ? 'false' : 
        Main.Instance.CRMPointState.include_games.toString(),
        // included_games: FormatSQLArray(JSON.stringify(Main.Instance.CRMPointState.included_games)),
        included_games: GetIncludedGames(1, Main.Instance.DatabaseDataState.GamesStreamAll, 
                                         Main.Instance.DatabaseDataState.GamesStreamAllSelected),
        includedgames: GetIncludedGames(2, Main.Instance.DatabaseDataState.GamesStreamAll, 
                                        Main.Instance.DatabaseDataState.GamesStreamAllSelected),
        // includedgames:
        // GetGames(Main.Instance.CRMPointState.included_games, Main.Instance.DatabaseDataState.GamesStreamAll),
// playerlist:GetPlayerList(Main.Instance.CRMPointState.dataPlayerListsSorted,Main.Instance.CRMPointState.player_list_id
        playerlist: GetIncludedGames(3, Main.Instance.DatabaseDataState.playersStream,
                                     Main.Instance.DatabaseDataState.playersStreamSelected),
    }

    // console.log ('id:' + items.id)
    const id: number = parseInt(Main.Instance.CRMPointState.currency_id)

    if (id !== 0) {
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.currenciesAllStream.length; i++) {
            if (parseInt(Main.Instance.DatabaseDataState.currenciesAllStream[i]['id']) === id) {
                items.currency = Main.Instance.DatabaseDataState.currenciesAllStream[i]['name'].toString().trim()
            }
        }
    }


    // SET currency
/*
    let curr:any[] = []

    for (let i = 1; Main.Instance.CRMPointState.currency && i < Assets.maxNumberCurrencies; i++) {

        let item = {

            id: i.toString(),
            value: Main.Instance.CRMPointState.currency ? Main.Instance.CRMPointState.currency[i] : '0',
            code: Main.Instance.CurrencyRatesState.code[i]
            // default: i === parseInt(Main.Instance.CRMPointState.main_currency) ? true : false
        }

        curr.push(item);
    }
*/
/*
    items.currency = JSON.stringify(curr);
    items.game_ids = '['
    let temp:number = 0
    for (let i:number = 0; i < Main.Instance.DatabaseDataState.gamesAllStream.length;i++) {
        if (Main.Instance.CRMPointState.game_ids_selected[i] == true) {
            if (items.game_ids.length > 1) {
                items.game_ids += ','
            }
            items.game_ids += Main.Instance.CRMPointState.game_ids[i]
            temp++
        }
    }

    items.game_ids_n = temp.toString()
    items.game_ids += ']'

    if (Main.Instance.CRMPointState.auto_include == true) {
        items.automatic_inclusion = 'true'
    }
*/
/*
    if (items.name.length === 0) {
        alert (Assets.compulsoryRule)
        return        
    }
*/

    let proceed: boolean = true

    if (items.level_min > 0 && items.level_max > 0) {
        if (items.level_max < items.level_min) {
            proceed = false
            alert (Assets.LevelMaxOverLevelMin)
        } else if (items.sublevel_max < items.sublevel_min) {
            proceed = false
            alert (Assets.SubLevelMaxOverSubLevelMin)
        }
    } 

    if (Main.Instance.CRMPointState.excluded_games && Main.Instance.DatabaseDataState.GamesStreamAll &&
        Main.Instance.CRMPointState.excluded_games.length === Main.Instance.DatabaseDataState.GamesStreamAll.length) {
        proceed = false
        alert (Assets.AllGamesAreExcluded)    
    }

    let playerListFull: boolean = true

    for (const item of Main.Instance.DatabaseDataState.playersStreamSelected) {
        if (item === false) {
            playerListFull = false
            break
        }
    }

    if (playerListFull) {
        items.playerlist = '{}'
    }

    // console.log (items)

    if (proceed) {
        if (!Main.Instance.CRMPointState.mode || duplicate) {
            // INSERT
            // console.log('just before insert')
            DbRoutes.insertPoint(items, InsertPointCallback)
        } else  {
            // UPDATE
            // console.log('just before update')
            DbRoutes.updatePoint(items, UpdatePointCallback)
        }
    }

}

function PointsInsertMode() {

    if (!IsBuildScandibet()
        && Main.Instance.CRMPointState.points_pcu === Main.Instance.CRMPointState.points_pcu_saved
        && Main.Instance.CRMPointState.active === Main.Instance.CRMPointState.active_saved
        && Main.Instance.CRMPointState.change_gs === Main.Instance.CRMPointState.change_gs_saved
        && parseInt(Main.Instance.CRMPointState.target) === parseInt(Main.Instance.CRMPointState.target_saved)
        && Main.Instance.CRMPointState.wagering === Main.Instance.CRMPointState.wagering_saved
        && Main.Instance.CRMPointState.losses === Main.Instance.CRMPointState.losses_saved
        && Main.Instance.CRMPointState.bet_size_c === Main.Instance.CRMPointState.bet_size_c_saved
        && Main.Instance.CRMPointState.average_c === Main.Instance.CRMPointState.average_c_saved
        && parseInt(Main.Instance.CRMPointState.average_id) === parseInt(Main.Instance.CRMPointState.average_id_saved)
        && parseInt(Main.Instance.CRMPointState.bet_size_id) === parseInt(Main.Instance.CRMPointState.bet_size_id_saved)
        && Main.Instance.CRMPointState.nlp === Main.Instance.CRMPointState.nlp_saved
        && Main.Instance.CRMPointState.bet_min === Main.Instance.CRMPointState.bet_min_saved
        && Main.Instance.CRMPointState.bet_max === Main.Instance.CRMPointState.bet_max_saved
        && parseInt(Main.Instance.CRMPointState.multiplier_id) === 
        parseInt(Main.Instance.CRMPointState.multiplier_id_saved)
        && parseInt(Main.Instance.CRMPointState.type_id) === parseInt(Main.Instance.CRMPointState.type_id_saved)
        && parseInt(Main.Instance.CRMPointState.type2_id) === parseInt(Main.Instance.CRMPointState.type2_id_saved)
        && Main.Instance.CRMPointState.various === Main.Instance.CRMPointState.various_saved
        && Main.Instance.CRMPointState.from_date === Main.Instance.CRMPointState.from_date_saved
        && Main.Instance.CRMPointState.to_date === Main.Instance.CRMPointState.to_date_saved
        && parseInt(Main.Instance.CRMPointState.currency_id) === parseInt(Main.Instance.CRMPointState.currency_id_saved)
        && Main.Instance.CRMPointState.chart1_start === Main.Instance.CRMPointState.chart1_start_saved
        && Main.Instance.CRMPointState.chart1_step === Main.Instance.CRMPointState.chart1_step_saved
        && Main.Instance.CRMPointState.chart1_stop === Main.Instance.CRMPointState.chart1_stop_saved
        && Main.Instance.CRMPointState.chart2_start === Main.Instance.CRMPointState.chart2_start_saved
        && Main.Instance.CRMPointState.chart2_step === Main.Instance.CRMPointState.chart2_step_saved
        && Main.Instance.CRMPointState.chart2_stop === Main.Instance.CRMPointState.chart2_stop_saved
        && Main.Instance.CRMPointState.chart1_value === Main.Instance.CRMPointState.chart1_value_saved
        && Main.Instance.CRMPointState.chart2_value === Main.Instance.CRMPointState.chart2_value_saved
        && Main.Instance.CRMPointState.level_min === Main.Instance.CRMPointState.level_min_saved
        && Main.Instance.CRMPointState.level_max === Main.Instance.CRMPointState.level_max_saved
        && Main.Instance.CRMPointState.sublevel_min === Main.Instance.CRMPointState.sublevel_min_saved
        && Main.Instance.CRMPointState.sublevel_max === Main.Instance.CRMPointState.sublevel_max_saved
        && Main.Instance.CRMPointState.exclude_games === Main.Instance.CRMPointState.exclude_games_saved
        && Main.Instance.CRMPointState.include_games === Main.Instance.CRMPointState.include_games_saved
        && Main.Instance.CRMPointState.player_list === Main.Instance.CRMPointState.player_list_saved
        && parseInt(Main.Instance.CRMPointState.player_list_id) === parseInt(Main.Instance.CRMPointState.player_list_id_saved)
        && Main.Instance.CRMPointState.name === Main.Instance.CRMPointState.name_saved
        ||
        IsBuildScandibet()

        && Main.Instance.CRMPointState.active === Main.Instance.CRMPointState.active_saved
        && parseInt(Main.Instance.CRMPointState.type_id) === parseInt(Main.Instance.CRMPointState.type_id_saved)
        && parseInt(Main.Instance.CRMPointState.type2_id) === parseInt(Main.Instance.CRMPointState.type2_id_saved)
        && Main.Instance.CRMPointState.from_date === Main.Instance.CRMPointState.from_date_saved
        && Main.Instance.CRMPointState.to_date === Main.Instance.CRMPointState.to_date_saved
        && Main.Instance.CRMPointState.from_time === Main.Instance.CRMPointState.from_time_saved
        && Main.Instance.CRMPointState.to_time === Main.Instance.CRMPointState.to_time_saved
        && Main.Instance.CRMPointState.percentage === Main.Instance.CRMPointState.percentage_saved
        && Main.Instance.CRMPointState.name === Main.Instance.CRMPointState.name_saved
        // && Main.Instance.CRMPointState.playerlist === Main.Instance.CRMPointState.playerlist_saved
        // && Main.Instance.CRMPointState.included_games === Main.Instance.CRMPointState.included_games_saved
        
    ) {
        ClearFields()
    } else {

        if (confirm(Assets.UnsavedChanges)) {
            ClearFields() 
        }                
    }
}

function ChangeTypeFilterIDPoint(text:string) {

    Logic.Type.New(Main.Instance.CRMPointState, {type_id_filter:text})
    DbRoutes.getSubPointTypesByPointType(text, true)
    FirstPagePoint()
}

function ChangeType2FilterIDPoint(text:string) {

    Logic.Type.New(Main.Instance.CRMPointState, {type2_id_filter:text})
    FirstPagePoint()
}

/*
function ChangeOddsTypeIDPoint (text:string) {
    Logic.Type.New(Main.Instance.CRMPointState, {odds_type_id:text})
}

function ChangeTypeIDPoint (text:string) {
    // DbRoutes.getGamesAllByCategory(text)
    Logic.Type.New(Main.Instance.CRMPointState, {type_id:text})
}

function showModalCurrency() {

    Logic.Type.New(Main.Instance.Modal, {ModalPointCurrency:true})
}

function showModalGames() {
    Logic.Type.New(Main.Instance.Modal, {ModalPointGames:true})
}

type AreCurrenciesSetProps = {

    validity_array:boolean[],
    hover?:JSX.Element,
    default?:string,
    codes?:string[]
}

type AreCurrenciesSetState = {

}

class AreCurrenciesSet extends React.Component <AreCurrenciesSetProps, AreCurrenciesSetState> {
    state:AreCurrenciesSetState

    isValid: boolean

    render () {

        this.isValid = false

        if (this.props.codes && this.props.default && parseInt(this.props.default) > 0) {

            let defaultID :number = parseInt(this.props.default)
            let defaultCode :string = this.props.codes[defaultID]

            this.isValid = this.props.validity_array[defaultID]

            return(
                this.isValid ?
                <span className = "label label-success label-table-success fr display-on-hover mt20">
                    {defaultCode}
                    {this.props.hover ? this.props.hover : void 0}
                </span> : 
                <span className = "label label-danger label-table-success fr display-on-hover mt20">
                    {defaultCode}
                    {this.props.hover ? this.props.hover : void 0}
                </span>            
                )
        } else {

            this.isValid = false

            for (let i:number = 0; i < this.props.validity_array.length; i++) { 
            
                if (this.props.validity_array[i] === true)
                    this.isValid = true
            }

            return(
                this.isValid ?
                <span className = "label label-success label-table-success fr mt20 display-on-hover">
                    {Assets.currenciesSet}
                    {this.props.hover ? this.props.hover : void 0}
                </span> : 
                <span className = "label label-danger label-table-success fr mt20 display-on-hover">
                    {Assets.currenciesNotSet}
                    {this.props.hover ? this.props.hover : void 0}
                </span>            
                )
        }
    }
}

function defaultCurrencySelected(text:string) {

    // let index:number = parseInt(text) - 1
    let selected_id:number = parseInt(text)

    // alert (selected_id)
    // alert (Main.Instance.CRMPointState.currency_code[selected_id])
    
    if (selected_id !== undefined && Main.Instance.CRMPointState.currency) {

        Logic.Type.New(Main.Instance.CRMPointState, {main_currency:text})

        if (parseInt(text) === 0) {

            for (let id:number = 1; id <= Assets.maxNumberCurrencies; id++) {
                Main.Instance.CRMPointState.isNormalInteger[id] = false
                Main.Instance.CRMPointState.currency[id] = ''
            }

            return
        }
    
        let pivot: number = 0
        let pivotValue: number = 0

        for (let id:number = 1; id <= Main.Instance.CurrencyRatesState.rate.length; id++) {   

            // let id:number = parseInt(Main.Instance.CRMPointState.currency_code_id[i]) - 1
            // let id:number = i
            
            // Main.Instance.CRMPointState.currency_rates_codes[id] = item.code


            if (pivot === 0 && id === selected_id && Main.Instance.CRMPointState.currency && 
                Main.Instance.CRMPointState.currency[id]) {

                pivot = parseFloat(Main.Instance.CurrencyRatesState.rate[id])
                pivotValue = parseFloat(Main.Instance.CRMPointState.currency[id])

                // alert ('pivot:'+pivot)
                // alert ('pivotValue:'+pivotValue)
            }

        }

        // console.log ('pivot:'+pivot)
        // console.log ('pivotValue:'+pivotValue)

        if (pivot === 0) {
            alert(Assets.ValueCannotBe0)
            return
        }
        
        if (pivotValue === 0) {
            alert(Assets.ValueCannotBe0)
            return
        }

        // v tabeli currencyRates je vse izrazeno skozi EUR, kar ni problem, ker nas zanimajo samo relativne vrednosti
        // vrednost ki je izbrana pod default mora ostati nedotaknjena

        // Main.Instance.CRMPointState.currency[index] se ne tika

        for (let id:number = 1; id <= Assets.maxNumberCurrencies; id++) {

            // let id:number = parseInt(Main.Instance.CRMPointState.currency_code_id[i]) - 1
            if (id === selected_id) { 

                if (Main.Instance.CRMPointState.isNormalInteger !== undefined && 
                    Main.Instance.CRMPointState.isNormalInteger[id] !== undefined) {
                    Main.Instance.CRMPointState.isNormalInteger[id] = true
                } 
                continue
            }

            Main.Instance.CRMPointState.currency[id] =
            ((pivotValue/parseFloat(Main.Instance.CurrencyRatesState.rate[id])/(pivot)).toFixed(4)).toString()

            // Main.Instance.CRMPointState.currency[id] = Main.Instance.CRMPointState.currency_rates[id]

            if (Main.Instance.CRMPointState.isNormalInteger !== undefined && 
            Main.Instance.CRMPointState.isNormalInteger[id] !== undefined) { 
                Main.Instance.CRMPointState.isNormalInteger[id] = false
            }
        }


    // alert (Main.Instance.CRMPointState.currency[index])
    // automatic conversion to other currencies
    // alert (Main.Instance.CRMPointState.currency[parseInt[text]])
    // mora dat se value
    }
}

function hideModalGames() {
    Logic.Type.New(Main.Instance.Modal, {ModalPointGames:false})
}

function hideModalCurrency() {

    Logic.Type.New(Main.Instance.Modal, {ModalPointCurrency:false})
}

function SetAutoInclude ( text:string) {
    if (text == 'true') {
        Logic.Type.New(Main.Instance.CRMPointState, {auto_include:true})
    } else {
        Logic.Type.New(Main.Instance.CRMPointState, {auto_include:false})
    }
}

function selectAllGamesInCategory() {

   let stateCopy = Object.assign({}, Main.Instance.CRMPointState);
 
   for (let i:number = 0; i < Main.Instance.DatabaseDataState.gamesAllStream.length;i++) {
       if (parseInt(Main.Instance.CRMPointState.game_ids_category[i]) === 
           parseInt(Main.Instance.CRMPointState.type_id)) {
           stateCopy.game_ids_selected[i] = true
       } else {
           stateCopy.game_ids_selected[i] = false           
       }
   }

   Logic.Type.New (Main.Instance.CRMPointState, stateCopy)
}

function deselectAllGamesInCategory() {

   let stateCopy = Object.assign({}, Main.Instance.CRMPointState);
 
   for (let i:number = 0; i < Main.Instance.DatabaseDataState.gamesAllStream.length;i++) {
       if (parseInt(Main.Instance.CRMPointState.game_ids_category[i]) === 
           parseInt(Main.Instance.CRMPointState.type_id)) {
           stateCopy.game_ids_selected[i] = false
       } else {
           stateCopy.game_ids_selected[i] = false           
       }
   }
   Logic.Type.New (Main.Instance.CRMPointState, stateCopy)
}
*/
