import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as React from 'react'
import * as Assets_ from '../../Assets_'
import * as Assets from '../../Assets'
import ReactTable from 'react-table'
import { Logic } from '../../../Logic/Type'
import { Main } from '../../../Logic/Main'





export class GamanzaErrorMessages extends React.Component <{}> {
    constructor(props:any) {
        super(props)
        
        DbRoutes.wsReinitialize()
    }

    public getStatusOptions(): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.Status.toLowerCase()}</option>)
        
        for (let i: number = 0; i < Assets_.gamanzaGameIntegrationErrors.length; i++) {
            options.push (<option key = {i.toString()} value = {Assets_.gamanzaGameIntegrationErrors[i].status}>{Assets_.gamanzaGameIntegrationErrors[i].status}</option>)
        }
    
        return options
    }

    public compare( a, b ) {
        if ( a.message < b.message ) {
          return -1;
        }
        if ( a.message > b.message ) {
          return 1;
        }
        return 0;
      }

    public getMessageOptions(sort?: boolean): JSX.Element[] {
        const options: JSX.Element[] = []
        options.push (<option key = "-1" value = "-1">{Assets.Any} {Assets.Message.toLowerCase()}</option>)
        const assets: Array<{status: number, message: string}> = Object.assign([], Assets_.gamanzaGameIntegrationErrors)

        const messageAlphabetized = sort ? assets.sort(this.compare) : Assets_.gamanzaGameIntegrationErrors
        for (let i: number = 0; i < messageAlphabetized.length; i++) {
            options.push (<option key = {i.toString()} value = {messageAlphabetized[i].message}>{messageAlphabetized[i].message}</option>)
        }
        
        return options
    }

    public filterErrors(status?: number, message?: string) {
        const errors: object[] = []
        
        if (status === -1 || parseInt(message) === -1) {
            for (const item of Assets_.gamanzaGameIntegrationErrors) {
                errors.push(item)
            }
        } else {
            for (const item of Assets_.gamanzaGameIntegrationErrors) {
                if (item.status === status || item.message === message) {
                    errors.push(item)
                }
            }
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {selectedErrors: errors})
    }

    public getValue(which: number): string {
        if (which === 0) {
            for (const item of Assets_.gamanzaGameIntegrationErrors) {
                if (item.message === Main.Instance.DatabaseDataState.selectedMessage) {
                    return item.status.toString()
                }
            }
        } else if (which === 1) {
            for (const item of Assets_.gamanzaGameIntegrationErrors) {
                if (item.status === parseInt(Main.Instance.DatabaseDataState.selectedStatus)) {
                    return item.message
                }
            }
        }
        
    }

    public getAutocomplete() {
        const options: JSX.Element[] = []
        const assets: Array<{status: number, message: string}> = Object.assign([], Assets_.gamanzaGameIntegrationErrors)
        const messageAlphabetized = assets.sort(this.compare)
        for (const item of messageAlphabetized) {
            options.push(<option key = {item.status}>{item.message}</option>)
        }
        return (
            <datalist id = "messages">
                {options}
            </datalist>
        )
    }

    public render() {
        return (
            <div>
                <h3>{Assets.GamanzaErrorMessages}</h3>
                <br/>
                <div className = "col-md-12 col-sm-12 col-xs-12 admin-users-panel clearfix">

                    <select
                        style = {{height: '24px'}}
                        className = "mr"
                        value = {Main.Instance.DatabaseDataState.selectedMessage !== '-1' ? this.getValue(0) : Main.Instance.DatabaseDataState.selectedStatus}
                        onChange = {(e) => {
                            this.filterErrors(+e.target.value, undefined)  
                            Logic.Type.New (Main.Instance.DatabaseDataState, {selectedStatus: e.target.value, selectedMessage: '-1'})
                        }}
                    >
                        {this.getStatusOptions()}
                    </select>
                    
                    <select
                        style = {{height: '24px'}}
                        className = "mr"
                        value = {Main.Instance.DatabaseDataState.selectedStatus !== '-1' ? this.getValue(1) : Main.Instance.DatabaseDataState.selectedMessage}
                        onChange = {(e) => {
                            this.filterErrors(undefined, e.target.value)  
                            Logic.Type.New (Main.Instance.DatabaseDataState, {selectedStatus: '-1', selectedMessage: e.target.value})
                        }}
                    >
                        {this.getMessageOptions(true)}
                    </select>
                    <input 
                        style = {{height: '22px', width: '150px', paddingLeft: '5px', paddingRight: '5px'}} 
                        placeholder = {Assets.Filter + Assets._by_ + Assets.Status} 
                        className = "mr" 
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.DatabaseDataState, {selectedStatus: e.target.value, selectedMessage: '-1'})
                            this.filterErrors(+e.target.value, undefined) 
                        }} 
                        type = "number" 
                        value = {Main.Instance.DatabaseDataState.selectedStatus !== '-1' ? Main.Instance.DatabaseDataState.selectedStatus : ''}
                    />
                    {this.getAutocomplete()}
                    <input 
                        style = {{height: '22px', width: '200px', paddingLeft: '5px', paddingRight: '5px'}} 
                        placeholder = {Assets.Filter + Assets._by_ + Assets.Message} 
                        className = "mr" 
                        onChange = {(e) => {
                            Logic.Type.New (Main.Instance.DatabaseDataState, {selectedStatus: '-1', selectedMessage: e.target.value})
                            this.filterErrors(undefined, e.target.value)  
                        }} 
                        list = "messages"
                        type = "text" 
                        value = {Main.Instance.DatabaseDataState.selectedMessage !== '-1' ? Main.Instance.DatabaseDataState.selectedMessage : ''}
                    />
                </div>
                <br/>
                <ReactTable
                    data = {Main.Instance.DatabaseDataState.selectedErrors ? Main.Instance.DatabaseDataState.selectedErrors : Assets_.gamanzaGameIntegrationErrors}
                    columns = {[
                        {width: 150, headerClassName: 'th-left', Header: Assets.Status, accessor: 'status'},
                        {headerClassName: 'th-left', Header: Assets.GamanzaErrorMessages, accessor: 'message'},
                    ]}
                    showPaginationTop = {true}
                    showPaginationBottom = {false}
                    pageSizeOptions = {[5, 10, 15, 20, 25, 50]}
                    defaultPageSize = {15}
                />
                
            </div>
        ) 
    }
}