import * as React from 'react'
import {Main} from '../../../Logic/Main'
import * as Assets from '../../Assets'
import {Logic} from '../../../Logic/Type'
import * as base64 from 'base-64'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faChartBar} from '@fortawesome/free-solid-svg-icons'
function getLetters(): JSX.Element[] {
    
    if (Main.Instance.DatabaseDataState.playersStream === undefined || 
        Main.Instance.DatabaseDataState.currenciesStream === undefined) { return [<div key = "0"/>] }
    // console.log (Main.Instance.DatabaseDataState.currenciesStream)
    const l: JSX.Element[] = []
/*
    let letters: string[] = []
    for (const item of Main.Instance.DatabaseDataState.currenciesStream) if (item['active'] === true) letters.push (item['code'])

    if (!Main.Instance.PlayersState.filter_letter) {
        let filter_letter: boolean[] = []
        let filterLetter: string[] = []
        filter_letter.push(false)
        filterLetter.push('')
        filter_letter.push(true)
        filterLetter.push('')
        if (letters && letters.length > 0) for (let i: number = 1; i <= letters.length; i++) {filter_letter.push(false); filterLetter.push(letters[i - 1])}
        Logic.Type.New (Main.Instance.PlayersState, {filter_letter, filterLetter})
        SelectPlayers()
    }

    l.push (
        <button
            onClick = {() => ToggleLetter(0)}
            className = {Main.Instance.PlayersState.filter_letter[0] === true ? 'btn btn-success btn-sm' : 'btn btn-default btn-sm'}
            key = "0"
            style = {{padding: '3px 8px 2px', margin: '10px 0px 0px 0px', borderRadius: '0px'}}
        >{Assets.All}
        </button>
    )
    l.push (
        <button
            onClick = {() => ToggleLetter(1)}
            className = {Main.Instance.PlayersState.filter_letter[1] === true ? 'btn btn-success btn-sm' : 'btn btn-default btn-sm'}
            key = "1"
            style = {{padding: '3px 8px 2px', margin: '10px 0px 0px 0px', borderRadius: '0px'}}
        >{Assets.None}
        </button>
    )
*/
    l.push (
        <button
            onClick = {() => ToggleSearch()}
            className = "btn btn-primary btn-sm fl dbl"
            style = {{padding: '3px 8px 2px', margin: '10px 0px 0px 10px'}}
            key = "2"
        >
            Apply Search
        </button>,
    )
    l.push (
        <div key = "1" className = "fl dbl" style = {{marginRight: '10px', marginLeft: '10px', marginTop: '5px'}}>
            <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[0]}</div>
            <div style = {{lineHeight: '16px'}}>{Assets.ImportFilePlayersSelection[1]}</div>
        </div>,
    )

    l.push (
        <input
            key = "0"
            accept = "text/csv, text/csv" 
            id = "players-csv1" 
            className = "UploadShopItemWrapper" 
            type = "file"
            style = {{marginTop: '10px'}}
            onChange = {(event) => { 
                const cloneFile: File = event.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const decodedData: string = base64.decode((reader.result as string).replace('data:text/csv;base64,',''))
                    const data: string[] = decodedData.split('\n')
                    // console.log (data)
                    const playersStreamSelected: boolean[] = Object.assign([], Main.Instance.DatabaseDataState.playersStreamSelected)
                    for (let i: number = 0; i < playersStreamSelected.length; i++) {
                        playersStreamSelected[i] = false
                    }
                    // console.log (Main.Instance.DatabaseDataState.playersStream.length)
                    for (let j: number = 0; j < data.length; j++) {
                        let low: number = 0
                        let high: number = Main.Instance.DatabaseDataState.playersStream.length - 1
                        let current: number
                        if (data[j].trim().length === 6 || data[j].trim().length === 7) {
                            // console.log (Data.trim() + '|' + Data.trim().length)
                            while (true) {
                                current = (low + Math.floor((high - low)/2))
                                // console.log (low, high, current)
                                /*
                                if (Main.Instance.DatabaseDataState.playersStream[current] === undefined) {
                                    console.log (current)
                                }
                                */
                                const difference: number = Main.Instance.DatabaseDataState.playersStream[current]['player'] - parseInt(data[j].trim())
                                if (difference === 0) { break }
                                if (high - low === 1 && current === low) {
                                    current = high
                                    break
                                    /*
                                    const Difference = Main.Instance.DatabaseDataState.playersStream[current]['player'] - parseInt(Data.trim())
                                    if (Difference === 0) break
                                    */
                                }
/*
                                if (high - low === 1 && current === high) {
                                    current = low
                                    break
                                }
*/
                                if (difference < 0) { low = current } else { high = current }
                            }
                            // console.log ('current:' + current)
                            // console.log (j, current)
                            playersStreamSelected[current] = true
                        }
                    }
                    /*                    
                    for (const Data of data) {
                        for (let i: number = 0; i < playersStreamSelected.length; i++) {
                            if (Data.trim().length > 0 && +Main.Instance.DatabaseDataState.playersStream[i]['player'] === parseInt(Data.trim())) {
                                playersStreamSelected[i] = true
                                break
                            }
                        }
                    }
                    */
/*
                    for (let i: number = 0; i < playersStreamSelected.length; i++) {
                        playersStreamSelected[i] = false
                        for (const Data of data) {
                            if (Data.trim().length > 0 && +Main.Instance.DatabaseDataState.playersStream[i]['player'] === parseInt(Data.trim())) {
                                playersStreamSelected[i] = true
                                break
                            }
                        }
                    }
*/
                    // console.log ('here')
                    Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
                }
                reader.readAsDataURL(cloneFile);
            }}
            onClick = {(event) => {event.target = null}}
        />)

/*
    for (let i: number = 1; i <= letters.length; i++) {
        l.push (
            <button
                onClick = {() => ToggleLetter(i + 1)}
                className = {Main.Instance.PlayersState.filter_letter[i + 1] === true ? 'btn btn-success btn-sm' : 'btn btn-default btn-sm'}
                key = {(i + 2).toString()}
                style = {{padding: '3px 8px 2px', margin: '10px 0px 0px 10px', borderRadius: '0px', fontWeight: 700}}
            >
                {letters[i - 1]}
            </button>
        )
    }
*/
/*
    for (let i: number = 1; i <= letters.length; i++) {
        l.push (
            <button
                onClick = {() => ToggleLetter(i)}
                className = {Main.Instance.PlayersState.filter_letter[i] === true ? 'btn btn-success':'btn btn-default'}
                key = {i.toString()}
                style = {{padding: '3px 3px 2px', margin: '20px 0px 0px 3px', borderRadius: '0px'}}
            >
                {letters[i - 1]}
            </button>)
    }
*/
    return l
}

function ToggleSearch() {
    // console.log (Main.Instance.SearchState.search_string)
    if (isNaN(Main.Instance.SearchState.search_string as any)) { alert (Assets.PleaseEnterANumberToToggleSearch) } else if (Main.Instance.SearchState.search_string.indexOf('.') !== -1) { alert (Assets.PleaseRemoveDotsFromTheSearchString) } else {
        const searchString: number = parseInt (Main.Instance.SearchState.search_string)
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
            if (Main.Instance.DatabaseDataState.playersStream[i]['player'] === searchString) {TogglePlayer(i); break}
        }
    }
}
/*
function ToggleLetter (which: number) {
    let filter_letter: boolean[] = Object.assign ([], Main.Instance.PlayersState.filter_letter)
    filter_letter[which] = !Main.Instance.PlayersState.filter_letter[which]
    if (which === 1 && filter_letter[0] === true) filter_letter[0] = false
    if (which === 0 && filter_letter[1] === true) filter_letter[1] = false
    if (which > 1 && filter_letter[which] === true) filter_letter[1] = false

    if (which === 0 && !Main.Instance.PlayersState.filter_letter[0]) for (let i: number = 1; i < Main.Instance.PlayersState.filter_letter.length; i++) filter_letter[i] = false
    else if (which !== 0 && !Main.Instance.PlayersState.filter_letter[which]) filter_letter[0] = false

    Logic.Type.New (Main.Instance.PlayersState, {filter_letter})
    SelectPlayers()
}
*/
function TogglePlayer(which: number) {
    const playersStreamSelected: boolean[] = Object.assign ([], Main.Instance.DatabaseDataState.playersStreamSelected)
    playersStreamSelected[which] = !Main.Instance.DatabaseDataState.playersStreamSelected[which]
    Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
    const selected: number = CountPlayersSelected()

    const filter_letter: boolean[] = Object.assign ([], Main.Instance.PlayersState.filter_letter)
    if (selected === 0) {
        filter_letter[0] = false; filter_letter[1] = true
    } else if (selected === Main.Instance.DatabaseDataState.playersStreamSelected.length) {
        filter_letter[1] = false; filter_letter[0] = true
    } else {
        filter_letter[1] = false; filter_letter[0] = false
    } 

    Logic.Type.New (Main.Instance.PlayersState, {filter_letter}) 

}
/*
function SelectPlayers() {
    let playersStreamSelected: boolean[] = []
    if (Main.Instance.DatabaseDataState.playersStream)
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
        let selected: boolean = false
        // letter - Name
        if (Main.Instance.PlayersState.filter_letter[0] === true) selected = true
        else if (Main.Instance.PlayersState.filter_letter[1] === true) selected = false

        else for (let j: number = 1; j < Main.Instance.PlayersState.filter_letter.length; j++) if (Main.Instance.PlayersState.filter_letter[j])
             if (Main.Instance.DatabaseDataState.playersStream[i]['currency'] === Main.Instance.PlayersState.filterLetter[j]) {selected = true; break}
        playersStreamSelected.push (selected)
    }

    Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
}
*/
function getPlayers(): JSX.Element[] {
    if (!Main.Instance.DatabaseDataState.playersStream || Main.Instance.DatabaseDataState.playersStream.length === 0) { return [<div key = "0"/>] }
    const g: JSX.Element[] = []
    if (!Main.Instance.DatabaseDataState.playersStreamSelected && Main.Instance.DatabaseDataState.playersStream.length > 0) {
        const playersStreamSelected: boolean[] = []
        for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
            playersStreamSelected.push(false)
        }
        Logic.Type.New (Main.Instance.DatabaseDataState, {playersStreamSelected})
    }
    // console.log (Main.Instance.DatabaseDataState.playersStream.length)
    // console.log (Main.Instance.DatabaseDataState.countriesDataStream.length)
    // console.log (Main.Instance.DatabaseDataState.playersStreamSelected.length)
    // console.log (Main.Instance.DatabaseDataState.playersStream[0])
    let a: string = ''
    for (let i: number = 0; i < Main.Instance.DatabaseDataState.playersStream.length; i++) {
    if (Main.Instance.DatabaseDataState.playersStreamSelected[i]) {
        if (a.length > 0) { a += ', ' }
        a += Main.Instance.DatabaseDataState.playersStream[i]['player']
        
    }
    }
    g.push (<div>{a}</div>)
/*
    if (Main.Instance.DatabaseDataState.playersStreamSelected[i]) {

        let stats: boolean = false
        for (let item of Main.Instance.DatabaseDataState.countriesDataStream)
        if (Main.Instance.DatabaseDataState.playersStream[i]['player'] === item['player']) {
            stats = true
            break
        }

        g.push (
            <div
                title = {stats === true ? 'Stats for this player exist in the database.' : 'Stats for this player do not exist in the database.'}
                onClick = {() => TogglePlayer(i)}
                key = {i.toString()}
                style = {{
                    cursor: 'pointer',
                    position: 'relative',
                    width: '9.1%',
                    maxWidth: '100px',
                    height: '40px',
                    float: 'left',
                    // clear: i % 10 === 0 ? 'both': 'none',
                    // margin: (i + 1) % 10 === 0 ? '0px 0% 10px 0px': '0px 1% 10px 0px',
                    margin: '0px 0.88% 10px 0px',
                    textAlign: 'center'
                }}
                className = {Main.Instance.DatabaseDataState.playersStreamSelected === undefined ? 'label-danger' : 
                            Main.Instance.DatabaseDataState.playersStreamSelected[i] ? 'label-success' : 'label-danger'}
            >
                <div
                    style = {{
                        fontFamily: 'Open Sans',
                        fontWeight: 800,
                        fontSize: '20px',
                        position: 'relative',
                        marginLeft: '5%',
                        marginTop: '5%',
                        height: '75%',
                        width: '90%',
                        padding: '5px 7px',
                        background: '#fff',
                        display: 'table'
                    }}
                >
                    <span
                        className = {Main.Instance.DatabaseDataState.playersStreamSelected[i] ? 'label-success' : 'label-danger'}
                        style = {{
                            fontSize: '14px',
                            position: 'absolute',
                            top: '-5px',
                            bottom: '18px',
                            left: '60%',
                            right: '-5px',
                            marginTop: '0px',
                            padding: '3px',
                            border: '0px solid #333',
                            color: '#fff',
                            lineHeight: '11px'
                        }}
                    >
                        {Main.Instance.DatabaseDataState.playersStream[i]['currency'] ? Main.Instance.DatabaseDataState.playersStream[i]['currency'] : ''}
                    </span>
                    {stats === true ? 
                    <span
                        className = {Main.Instance.DatabaseDataState.playersStreamSelected[i] ? 'label-success' : 'label-danger'}
                        style = {{
                            fontSize: '14px',
                            position: 'absolute',
                            top: '-5px',
                            bottom: '18px',
                            right: '80%',
                            left: '-5px',
                            marginTop: '0px',
                            padding: '3px',
                            border: '0px solid #333',
                            color: '#fff',
                            lineHeight: '11px'
                        }}
                    >
                        <FontAwesomeIcon icon = {faChartBar}/>
                    </span>
                    : void 0}
                    <span style = {{position: 'absolute', top: '7px', bottom: '0px', left: '0px', right: '0px', marginTop: '0px'}}>
                        {Main.Instance.DatabaseDataState.playersStream[i]['player'] ? Main.Instance.DatabaseDataState.playersStream[i]['player'] : ''}
                    </span>
                </div>
            </div>
        )
        
    }
*/
    return g
}

export function CountPlayersSelected(): number {
    let selected: number = 0
    if (Main.Instance.DatabaseDataState.playersStreamSelected) {
    for (const Selected of Main.Instance.DatabaseDataState.playersStreamSelected) {
        if (Selected === true) { selected++ }
    }
    }
    return selected
}

export function PlayersSelection(dashboard?: boolean) {
    const selected: number = CountPlayersSelected()
    return (
    <div>
`       <div className = "clearfix">
            <h3 style = {{marginRight: '10px'}} className = "fl mt">
                {dashboard !== undefined && dashboard === true ? 'Players Info' : 'Players Selection'}
            </h3>
            {getLetters()}
            <span className = "label label-success table-label fr mt " style = {{display: 'block'/*, background: '#5cb85c'*/}}>
                {Main.Instance.DatabaseDataState.playersStream ? Main.Instance.DatabaseDataState.playersStream.length : 0}
            </span>
            <span className = "label label-success table-label mt" style = {{display: 'block', background: '#5cb85c'}}>
                {Main.Instance.DatabaseDataState.playersStream && Main.Instance.DatabaseDataState.playersStream.length ?
                (100 * selected / Main.Instance.DatabaseDataState.playersStream.length).toFixed(2) : 0}%
            </span>

            <span className = "label label-success table-label fr mt" style = {{display:'block',background:'#5cb85c'}}>
                {selected.toString()}
            </span>
        </div>
        <div>
            <div style = {{marginTop: '10px'}} className = "clearfix">{getPlayers()}</div>
        </div>
    </div>)
}