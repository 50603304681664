
import * as ReactDOM from 'react-dom';
import { Main } from './Logic/Main'
import { Logic } from './Logic/Type'
// import * as DbRoutes from './Logic/Database/DbRoutes'
import { Navigated } from './Logic/Router/Router'
// import * as Assets_ from './Views/Assets_'
import * as base64 from 'base-64'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import './CSS/style.css'
import './CSS/Games.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-toggle-switch/dist/css/switch.min.css'
import 'react-table/react-table.css'
// import './Views/Main/Reports/userInfoStyle.css'
// import 'react-pdf/dist/Page/AnnotationLayer.css'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import {IsBuildMyChance} from './Logic/Utils'
import Socket1 from './Socket/socket-init'
import Socket2 from './Socket/socket-init2'
import Cookies from 'js-cookie'

function setCookies() {
    
    let loginState
    if (Cookies.get('loginData')) {
        const loginData1 = base64.decode(Cookies.get('loginData'))
        loginState = JSON.parse(loginData1)
        // console.log(loginState.token)
    }

    let location
    if (Cookies.get('location')) {
        location=Cookies.get('location')
    }
    
    // readCookie('loginData')
    // DbRoutes.getLoadedResources('Cookie - Get', 'loginData')
    // DbRoutes.getLoadedResources('Cookie - Get', 'location')
    // console.log ('location ', location)
    if(!Main.Instance.Location) {Main.Instance.Location = location}
/*

    if(playercard !== null && playercard !== undefined && Main.Instance.DatabaseDataState.playerCard.playerId !== playercard && Socket1 !== undefined && Socket1 !== null && JSON.stringify(Socket1) !== '{}') {
        // Main.Instance.DatabaseDataState = {playerCard: {}}
        DbRoutes.wsGetPlayerCard(playercard, true)
    }
*/

    // Logic.Type.New (Main.Instance.LoginState, JSON.parse(loginData1))
    // Main.Instance.LoginState = JSON.parse(loginData1)

    // console.log ('index', loginState.token)
    Socket1.reConnect(loginState.token)
    if (IsBuildMyChance() /*|| IsBuildJackpotsCH() || IsBuildPasino() */) {
        Socket2.reConnect(loginState.token)
    }

    // loginState.init = true
    // if (Main.Instance.LoginState.init === false) {

    if (loginState.model) {
        delete (loginState.model)
    }
    
    // Logic.Type.New (Main.Instance.LoginState, {init: true})
    if (Main.Instance.LoginState /*&& !Main.Instance.LoginState.logout*/) {
        // console.log ('*') 
        // BUGLOGOUT
        Logic.Type.New (Main.Instance.LoginState, loginState)
    }
/*
    Logic.Type.New (Main.Instance.LoginState, {
        init: true
        Verified: loginState.Verified,

    })
*/
    // }
    // console.log (Main.Instance.LoginState)
}
/*
function readCookie(name:string) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for(const item of ca) {
        let c = item
        while (c.charAt(0) == ' ') {c = c.substring(1, c.length)}
        if (c.indexOf(nameEQ) == 0) {return c.substring(nameEQ.length, c.length)}
    }
    return null
}
*/
// tslint:disable-next-line: new-parens
Main.Instance = new Main(new class {
    public Update(state: Main): void {
        Main.Instance = state
        // const loginData1=base64.decode(readCookie('loginData'))
        // const loc1=readCookie('location')
        // if(loc1 && loginData1 && (Main.Instance.LoginState === null || Main.Instance.LoginState === undefined || Main.Instance.LoginState.Verified)) {setCookies()}
        // if(loc1 && loginData1) {setCookies()}
        window.history.pushState(Main.Instance.Location, Main.Instance.Location, Main.Instance.Location)
        ReactDOM.render(Navigated(Main.Instance), document.getElementById('root'))
    }
})

Main.Instance.Location = window.location.pathname
let loginData
if (Cookies.get('loginData')) {
    loginData = base64.decode(Cookies.get('loginData'))
}
let loc
if (Cookies.get('location')) {
    loc=Cookies.get('location')
}
if(loginData && loc) {setCookies()}
ReactDOM.render(Navigated(Main.Instance), document.getElementById('root')) 
