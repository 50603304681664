import * as React from 'react'
import {Main} from '../../../Logic/Main'
import {Logic} from '../../../Logic/Type'
import {RouterObjects} from '../../../Logic/RouterObjects'
import {isUserMaster, 
        isUserContractualPartner, 
        isUserSystem,
        isUserSiteOwner,
        isUserGamanzaAdmin,
        } from '../../../Logic/Utils'
import * as DbRoutes from '../../../Logic/Database/DbRoutes'
import * as Config from '../../../Logic/Config'

export function UserManagementWrapper(props: React.Props<any>) {
    
    if (RouterObjects['user_Management'] && Main.Instance.Location == RouterObjects['user_Management'].path && Main.Instance.Back === false) {
        let path: string
        let Path: string
        if (isUserGamanzaAdmin() && isUserSystem()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Masters'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' + 
            RouterObjects['user_Management'].children['Manage_Masters'].path.substring(1, RouterObjects['user_Management'].children['Manage_Masters'].path.length)
        } else if (isUserGamanzaAdmin() && isUserMaster()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path.substring(1, RouterObjects['user_Management'].children['Manage_Contractual_Partners'].path.length)
        } else if (isUserGamanzaAdmin() && isUserContractualPartner() && !isUserSiteOwner()) {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Assign_Admin_Users'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Assign_Admin_Users'].path.substring(1, RouterObjects['user_Management'].children['Assign_Admin_Users'].path.length)
        } else /*if (isUserSubEntity() || isUserSiteOwner())*/ {
            path = RouterObjects['user_Management'].path + RouterObjects['user_Management'].children['Manage_Admin_Users'].path
            Path = RouterObjects['user_Management'].path.substring (1, RouterObjects['user_Management'].path.length) + ' -> ' +
            RouterObjects['user_Management'].children['Manage_Admin_Users'].path.substring(1, RouterObjects['user_Management'].children['Manage_Admin_Users'].path.length)
        }

        Path = Path.replace(/____/g, '.').replace(/___/g, '-').replace(/__/g, '/').replace(/_/g, ' ')
        
        DbRoutes.insertAdminLog (
            Config.asAdminLogView,
            Path,
            {},
        )
        Logic.Type.New(Main.Instance, {Location: path})
    }
    return(<div>{props.children}</div>)
}